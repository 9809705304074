import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  targetAchievement: ({ width }) => ({
    "&::after": {
      transition: "0.7s",
      content: "''",
      width: "2px",
      height: "25px",
      top: "-5px",
      left: width,
      position: "absolute",
      backgroundColor: "#000",
    },
    display: "flex",
    position: "relative",
    width: "100%",
    height: "25px",
  }),
}));

const MeasurementLevelsBar = (props) => {
  const { width = 0 } = props;

  const classes = useStyles({ width });

  return (
    <div className={classes.targetAchievement}>
      <div className="pointer-level" style={{ left: width }}></div>
      <div
        className="levelBarRed"
        style={{
          width: "80px",
          height: "15px",
          backgroundColor: "#f54e4e",
        }}
      />
      <div
        className="levelBarYellow"
        style={{
          width: "80px",
          height: "15px",
          backgroundColor: "#f3ce37",
        }}
      />
      <div
        className="levelBarGreen"
        style={{
          width: "80px",
          height: "15px",
          backgroundColor: "#15ad15",
        }}
      />
      <div
        className="levelBarBlue"
        style={{
          width: "80px",
          height: "15px",
          backgroundColor: "#1a76d2",
        }}
      />
    </div>
  );
};

export default MeasurementLevelsBar;
