import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";

import { fetchActionPlans } from "../../../../../../../actions/toolActionPlansActions";
import { updateOnlyAction } from "../../../../../../../actions/actionsPlan";
import { getCompanyMembersAndParticipants } from "../../../../../../customMapStates";

import ResponsibleView from "../ResponsibleView";
import ToolView from "../ToolView";

import { ContainerTabs } from "./styles";
import { checkAccess } from "../../../../../../../utils/accessLevels";

const Container = ({
  fetchActionPlans,
  toolActions,
  currentUser = {},
  responsibleActions,
  selectedWorkspace,
  updateOnlyAction,
  companyMembersAndParticipants,
}) => {
  const [activeTab, setActiveTab] = useState("TOOL");
  const [filteredEntities, setFilteredEntities] = useState({
    toolActions: [],
    responsibleActions: [],
  });

  const refetchData = useCallback(() => {
    fetchActionPlans(selectedWorkspace.id, activeTab);
  }, [activeTab, fetchActionPlans, selectedWorkspace.id]);

  useEffect(() => {
    if (selectedWorkspace.id) refetchData();
  }, [selectedWorkspace, fetchActionPlans, activeTab, refetchData]);

  const fullAccess = checkAccess(["OWNER", "ADMIN", "MODERATOR", "MANAGER"]);

  useEffect(() => {
    if (toolActions?.length === 0) return;

    if (fullAccess) setFilteredEntities({ ...filteredEntities, toolActions });
    else {
      let filtered = [];
      let filteredActions = [];

      toolActions.forEach((toolInfo) => {
        filteredActions = [];
        const { actions = [] } = toolInfo || {};

        actions.forEach((action) => {
          const { responsible = {} } = action || {};

          if (responsible?.id === currentUser?.id) {
            filteredActions = [...filteredActions, action];
          }
        });

        if (filteredActions?.length > 0) {
          filtered = [...filtered, { ...toolInfo, actions: filteredActions }];
        }
      });

      setFilteredEntities({ ...filteredEntities, toolActions: [...filtered] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id, fullAccess, toolActions]);

  useEffect(() => {
    if (responsibleActions?.length === 0) return;

    if (fullAccess)
      setFilteredEntities({ ...filteredEntities, responsibleActions });
    else {
      const filtered = responsibleActions.filter(
        ({ id: respId }) => respId === currentUser?.id,
      );
      setFilteredEntities({
        ...filteredEntities,
        responsibleActions: filtered,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id, fullAccess, responsibleActions]);

  const handleTabChange = useCallback((key) => {
    setActiveTab(key);
  }, []);

  const {
    toolActions: filteredToolActions = [],
    responsibleActions: filteredResponsibleActions = [],
  } = filteredEntities || {};

  return (
    <ContainerTabs className="custom-primary-tabs">
      <Tabs
        id="actionPlans"
        activeKey={activeTab}
        onSelect={(key) => handleTabChange(key)}
      >
        <Tab eventKey="TOOL" title="Visão geral">
          {activeTab === "TOOL" && (
            <ToolView
              actionPlans={filteredToolActions}
              updateOnlyAction={updateOnlyAction}
              companyMembersAndParticipants={companyMembersAndParticipants}
              refetchData={refetchData}
            />
          )}
        </Tab>
        <Tab eventKey="RESPONSIBLE" title="Responsáveis">
          {activeTab === "RESPONSIBLE" && (
            <ResponsibleView
              actionPlans={filteredResponsibleActions}
              updateOnlyAction={updateOnlyAction}
              companyMembersAndParticipants={companyMembersAndParticipants}
              refetchData={refetchData}
            />
          )}
        </Tab>
      </Tabs>
    </ContainerTabs>
  );
};

const mapStateToProps = (state) => {
  const {
    workspacesData = {},
    allActionPlans: toolActionPlanReducer = {},
    auth = {},
  } = state;
  const { user: currentUser = {} } = auth;
  const { selectedWorkspace = {} } = workspacesData;
  const { responsibleActions = {}, toolActions = {} } = toolActionPlanReducer;
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);

  return {
    currentUser,
    toolActions,
    selectedWorkspace,
    responsibleActions,
    companyMembersAndParticipants,
  };
};

const mapDispatchToProps = {
  fetchActionPlans,
  updateOnlyAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);

Container.propTypes = {
  fetchActionPlans: PropTypes.func.isRequired,
  selectedWorkspace: PropTypes.object.isRequired,
  toolActions: PropTypes.array.isRequired,
  responsibleActions: PropTypes.array.isRequired,
};
