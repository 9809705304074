const pt = {
  default_comment: "Seja o primeiro a comentar",
};

const es = {
  default_comment: "Sea el primer a comentar ",
};

const en = {
  default_comment: "Be the first to comment",
};

const translations = { en, es, pt };

export default translations;
