import React from "react";
import styled from "styled-components";
import { defineMessages } from "react-intl";
import { connect } from "react-redux";
import { Avatar } from "@material-ui/core";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import { orderArrayByObjAttr, getObjectInfoById } from "./ArrayUtils";

import EditableComment from "../components/Common/EditableComment";
import { getGoalValidationStatusOptions } from "../constants/goalsAndBonus";
import {
  fetchGetGroupedComments,
  getInsightComments,
} from "../actions/insightTimelineActions";
import {
  filterSelectedSkillsAssessmentComments,
  skillAssessmentCommentTag,
} from "./skillsAssessment/filter";
import { SkillAssessmentCommentBadge } from "./skillsAssessment/display";
import { IndicatorCommentBadge, indicatorCommentTag } from "./kpis/filter";

import { deleteCommentAttachment } from "../actions/attachmentActions";
import { doDownload } from "./attachmentsUtils";
import { getSelectedToolStates } from "../components/customMapStates";
import { translatedText } from "./translationUtils";
import { SKILLS_ASSESSMENT } from "../constants/tools.constants";

const AvatarStyled = styled(Avatar)`
  img {
    border-radius: 50% !important;
    width: 30px !important;
    height: 30px !important;
  }
`;

const messages = defineMessages({
  noComments: { id: "no_comments" },
  breadComment: { id: "bread_comment" },
  editedOn: { id: "global.editedOn" },
});

const translation = (id, values) => translatedText(id, messages, values);

export const getGroupedComments = (insightsIdList, allComments = []) => {
  const groupedComments = [];

  if (insightsIdList && allComments) {
    Object.keys(allComments).forEach((insightId) => {
      const insightComments = allComments[insightId] || [];

      if (
        insightsIdList.indexOf(parseInt(insightId, 10)) > -1 &&
        insightComments.length > 0
      ) {
        insightComments.forEach((commentInfo) =>
          groupedComments.push({ ...commentInfo, insightId }),
        );
      }
    });
  }

  return orderArrayByObjAttr(groupedComments, "date");
};

export const getTimelineRelationDisplay = (userId, comments = []) => {
  let addedTimelines = -1;
  const timelineComments = [];

  const reorderedTimeline = comments.sort((firstItem, secondItem) => {
    return new Date(secondItem.date) - new Date(firstItem.date);
  });

  if (reorderedTimeline && reorderedTimeline.length > 0) {
    reorderedTimeline.forEach((comment, index) => {
      const prevIndex = parseInt(index, 10) - 1;
      const nextIndex = parseInt(index, 10) + 1;
      const prevComment = reorderedTimeline[prevIndex] || null;
      const nextComment = reorderedTimeline[nextIndex] || null;
      const selfComment = comment.owner?.id === userId;

      if (!prevComment || prevComment.owner?.id !== comment.owner?.id) {
        addedTimelines += 1;
        timelineComments.push({
          owner: comment.owner,
          selfComment,
          comments: [],
        });
      }

      timelineComments[addedTimelines].comments.push({
        ...comment,
        nextComment,
        prevComment,
      });
    });
  }

  return timelineComments;
};

export const buildCommentsBreadcrumb = (comments = [], insights) => {
  const finalComments = [];

  if (Array.isArray(comments)) {
    comments.forEach((commentInfo) => {
      const { insightId } = commentInfo;

      const insightInfo = getObjectInfoById(Number(insightId), insights, "id");
      const questionInfo = insightInfo?.question;
      const themeInfo = questionInfo?.theme;
      const scenarieInfo = themeInfo?.cenary;

      finalComments.push({
        ...commentInfo,
        breadcrumb: {
          scenarieName: scenarieInfo?.name,
          themeName: themeInfo?.name,
          questionName: questionInfo?.text,
          insightName: insightInfo?.text,
        },
      });
    });
  }

  return finalComments;
};

export const buildTimelineComments = async (insight, loggedUser) => {
  const { data = [] } = await getInsightComments(insight.id);

  const newComments = buildCommentsBreadcrumb(
    [
      ...data.map((comment) => {
        return { ...comment, insightId: insight.id };
      }),
    ],
    [insight],
  );

  return getTimelineRelationDisplay(loggedUser, newComments);
};

export const buildGroupedComments = async ({
  insightsIdList,
  loggedUser,
  insight,
}) => {
  const data = await fetchGetGroupedComments(insightsIdList);

  const newComments = buildCommentsBreadcrumb(
    [
      ...data.map((comment) => {
        return { ...comment, insightId: insight.id };
      }),
    ],
    [insight],
  );

  return getTimelineRelationDisplay(loggedUser, newComments);
};

const EditedCommentLabels = (props) => {
  const { comment = {} } = props;

  if (comment.edited) {
    return (
      <div>
        <p>
          {translation("editedOn", {
            date: moment(comment.lastUpdate).calendar(),
          })}
        </p>
      </div>
    );
  }

  return null;
};

export const ClassificationBadge = ({
  text = "",
  backgroundColor = "#ddd",
  textColor = "#fff",
}) => {
  return (
    <div
      className="comment-badge"
      style={{ backgroundColor, color: textColor }}
    >
      {text}
    </div>
  );
};

const getBadgeByClassification = (slug, classifications) => {
  if (
    slug === "WK_CREATING" ||
    slug === "WK_APPROVAL" ||
    slug === "WK_APPROVED" ||
    slug === "WK_NOT_APPROVED" ||
    slug === "WK_AUDIT" ||
    slug === "WK_VALIDATED"
  ) {
    const realStatus = slug.slice(3);
    const goalValidationStatusOptions = getGoalValidationStatusOptions();
    const validationStatusInfo = getObjectInfoById(
      realStatus,
      goalValidationStatusOptions,
      "value",
    );

    return (
      <ClassificationBadge
        text={validationStatusInfo.label}
        backgroundColor={validationStatusInfo.color}
        textColor={validationStatusInfo.contrast}
      />
    );
  }

  if (skillAssessmentCommentTag(slug)) {
    return <SkillAssessmentCommentBadge tag={slug} />;
  }

  if (indicatorCommentTag(slug))
    return (
      <IndicatorCommentBadge tag={slug} classifications={classifications} />
    );
};

const CommentClassificationBadges = (props) => {
  const { classifications = [] } = props;
  const badges = [];

  if (classifications.length > 0) {
    classifications.forEach((slug) => {
      const badgeJsx = getBadgeByClassification(slug, classifications);
      if (badgeJsx) badges.push(badgeJsx);
    });
  }

  return (
    <div className="comment-classifications">
      {badges.map((badgeJsx, index) => {
        return <React.Fragment key={index}>{badgeJsx}</React.Fragment>;
      })}
    </div>
  );
};

let RenderBoxUser = (props) => {
  const {
    owner,
    ownerName,
    commentInfo = "",
    timelineDisplay,
    hideTimeline,
  } = props;

  const nowrapStyles = {
    whiteSpace: "nowrap",
    maxWidth: "150px",
    textOverflow: "ellipsis",
    overflow: "hidden",
  };

  const TitledBread = ({ title, style }) => {
    return (
      <span style={{ ...nowrapStyles, ...style }} title={title}>
        {title}
      </span>
    );
  };

  const avatarUrl = owner.userData.avatarBlobId
    ? `https://splace-profile-images.s3-sa-east-1.amazonaws.com/${owner.userData.avatarBlobId}`
    : "";

  return (
    <div
      className={`flex display-user-timeline ${
        hideTimeline ? "hide-display-user-timeline" : ""
      }`}
    >
      <i className="fas fa-comments timeline-icon" />
      <div
        className={timelineDisplay ? "" : "profile"}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <AvatarStyled src={avatarUrl} name="" />
        {!timelineDisplay && <span className="username">{owner.name}</span>}
      </div>
      <div className="flex-column box-user-timeline">
        {timelineDisplay && (
          <div>
            <span
              className={timelineDisplay ? "username-timeline" : "username"}
            >
              {ownerName}
            </span>
          </div>
        )}
        {commentInfo && commentInfo.breadcrumb && (
          <div
            style={{
              display: "flex",
              lineHeight: "12.5px",
              fontWeight: "500",
              gap: "8px",
              flexFlow: "row wrap",
              fontSize: "10px",
              color: "#777",
            }}
          >
            <span>{translation("breadComment")}</span>
            <TitledBread title={commentInfo.breadcrumb.scenarieName} />
            <i className="fas fa-chevron-right" style={{ fontSize: "12px" }} />
            <TitledBread title={commentInfo.breadcrumb.themeName} />
            <i className="fas fa-chevron-right" style={{ fontSize: "12px" }} />
            <TitledBread title={commentInfo.breadcrumb.questionName} />
            <i className="fas fa-chevron-right" style={{ fontSize: "12px" }} />
            <TitledBread
              title={commentInfo.breadcrumb.insightName}
              style={{ maxWidth: "auto" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const fileIcons = {
  PDF: "fa-file-pdf",
  AUDIO: "fa-file-audio",
  VIDEO: "fa-file-video",
  IMAGE: "fa-file-image",
  MS_WORD: "fa-file-word",
  MS_PROJECT: "fa-file",
  MS_POWERPOINT: "fa-file-powerpoint",
  MS_EXCEL: "fa-file-excel",
  ODF: "fa-file",
  ODP: "fa-file",
  ODS: "fa-file",
  ODT: "fa-file",
  TEXT: "fa-file-alt",
};

export const BoxFiles = styled.div`
  display: flex;
  flex-wrap: wrap;

  width: 100%;
`;

export const IconDelete = styled.i`
  position: absolute;
  width: 20px;
  height: 20px;

  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  background: #eee;
  color: #888;
  top: -7px;
  right: -7px;
  cursor: pointer;

  &:hover {
    background: #ccc;
    color: #e23262;
  }
`;

export const File = styled.div`
  display: flex;

  position: relative;

  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
`;

export const IconPreview = styled.i`
  font-size: 36px;
  margin-right: 5px;
  margin-top: 3px;
`;

export const RenderContainerComments = connect(null, {
  deleteCommentAttachment,
})((props) => {
  const {
    commentInfo,
    owner,
    selfComment,
    saveEditedComment,
    timelineDisplay,
    hideTimeline,
    hideGroupedInfo,
    avatar,
    deleteCommentAttachment,
    mentionedPeople,
  } = props;

  return (
    <>
      <div className="comment-text">
        {!hideGroupedInfo && (
          <RenderBoxUser
            owner={owner}
            avatar={avatar}
            ownerName={owner.name}
            timelineDisplay={timelineDisplay}
            hideTimeline={hideTimeline}
            commentInfo={commentInfo}
          />
        )}
        <div className="comment-timeline">
          <CommentClassificationBadges
            classifications={commentInfo.classifications}
          />
          <div
            className="box-comment-timeline"
            style={
              !timelineDisplay
                ? {
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: "column",
                  }
                : {}
            }
          >
            <EditableComment
              id={commentInfo.id}
              isEditable={!!selfComment && !!saveEditedComment}
              selfComment={selfComment}
              insightId={commentInfo.insightId}
              actionButtons
              maxLength={400}
              text={commentInfo.text ?? ""}
              onSave={(edited) => saveEditedComment(edited, commentInfo)}
              mentionedPeople={mentionedPeople}
            />
            {!!commentInfo.attachments?.length && (
              <BoxFiles>
                {commentInfo.attachments.map((file) => (
                  <Tooltip title={file.fileName}>
                    <File onClick={() => doDownload(file)}>
                      <IconDelete
                        className="fas fa-times"
                        onClick={() =>
                          deleteCommentAttachment(
                            file.id,
                            commentInfo.insightId,
                          )
                        }
                      />
                      <IconPreview
                        className={`far ${
                          fileIcons[file?.fileType] || "fa-file"
                        }`}
                      />
                    </File>
                  </Tooltip>
                ))}
              </BoxFiles>
            )}
            <span
              className={
                timelineDisplay
                  ? "text-muted text-moment date"
                  : "text-muted date"
              }
              style={{
                whiteSpace: "nowrap",
                color: "#b2b2b2",
              }}
            >
              <i className="far fa-clock" style={{ padding: "0 5px 0 0" }} />
              {moment(commentInfo.date).calendar()}
              <EditedCommentLabels comment={commentInfo} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
});

export const filterCommentsByToolMiddleware = (
  timelineComments,
  toolTypeId,
  selectedToolID,
) => {
  if (toolTypeId === SKILLS_ASSESSMENT)
    return filterSelectedSkillsAssessmentComments(
      selectedToolID,
      timelineComments,
    );

  return timelineComments;
};

export const DisplayBoxComments = (props) => {
  const {
    groupedComments = {},
    timelineDisplay,
    hideTimeline,
    saveEditedComment,
    mentionedPeople,
  } = props;
  const { owner, selfComment, comments = [] } = groupedComments;

  const getCommentBoxStyles = (
    blockMargin = false,
    timelineDisplay = false,
  ) => {
    return {
      boxShadow: "0 2px 4px 0 rgba(131, 129, 134, 0.5)",
      width: timelineDisplay ? "80%" : "100%",
      margin: !blockMargin ? "30px 0px 0px 0px" : "0px",
    };
  };

  return (
    <>
      {comments?.map((commentInfo = {}, subIndex) => {
        const prevComment = commentInfo.prevComment || {};

        const diffThanPrev =
          parseInt(prevComment.insightId, 10) !==
          parseInt(commentInfo.insightId, 10);

        const firstComment = subIndex === 0 || diffThanPrev;

        return (
          <div
            className="box-comment"
            style={getCommentBoxStyles(!firstComment, timelineDisplay)}
            key={commentInfo.id}
          >
            <RenderContainerComments
              commentInfo={commentInfo}
              selfComment={selfComment}
              owner={owner}
              avatar={commentInfo.avatar ? commentInfo.avatar : ""}
              saveEditedComment={saveEditedComment}
              timelineDisplay={timelineDisplay}
              hideTimeline={hideTimeline}
              hideGroupedInfo={!firstComment}
              mentionedPeople={mentionedPeople}
            />
          </div>
        );
      })}
    </>
  );
};

export const DisplayComments = connect((state) => {
  const { selectedToolID, toolTypeId } = getSelectedToolStates(state);
  return { selectedToolID, toolTypeId };
})(
  ({
    timelineComments = [],
    saveEditedComment = null,
    timelineDisplay = false,
    hideTimeline = false,
    mentionedPeople,
    toolTypeId,
    selectedToolID,
  }) => {
    const filteredComments = filterCommentsByToolMiddleware(
      timelineComments,
      toolTypeId,
      selectedToolID,
    );

    return (
      <div className="comments-display">
        {filteredComments.length > 0 ? (
          filteredComments?.map((groupedComments, index) => {
            const appendProps = {
              timelineDisplay,
              groupedComments,
              saveEditedComment,
              hideTimeline,
              mentionedPeople,
            };

            return <DisplayBoxComments key={index} {...appendProps} />;
          })
        ) : (
          <div
            style={{
              boxShadow: "0 2px 4px 0 rgba(131, 129, 134, 0.5)",
              width: "80%",
              height: "71px",
              padding: "10px",
              marginTop: "30px",
              lineHeight: "61px",
              textAlign: "center",
              display: timelineDisplay ? "" : "none",
            }}
          >
            {translation("noComments")}
          </div>
        )}
      </div>
    );
  },
);

export function getOnlyOwnerComments(timelineComments, loggedUser) {
  return timelineComments.filter(({ owner }) => owner.id === loggedUser.id);
}

export const ShowInsightComments = ({
  timelineComments = [],
  saveEditedComment = null,
  timelineDisplay = false,
  hideTimeline = false,
  mentionedPeople,
  loggedUser,
}) => {
  const filteredComments = getOnlyOwnerComments(timelineComments, loggedUser);

  return (
    <div className="comments-display">
      {filteredComments.length > 0 ? (
        filteredComments?.map((groupedComments, index) => {
          const appendProps = {
            timelineDisplay,
            groupedComments,
            saveEditedComment,
            hideTimeline,
            mentionedPeople,
          };

          return <DisplayBoxComments key={index} {...appendProps} />;
        })
      ) : (
        <div
          style={{
            boxShadow: "0 2px 4px 0 rgba(131, 129, 134, 0.5)",
            width: "80%",
            height: "71px",
            padding: "10px",
            marginTop: "30px",
            lineHeight: "61px",
            textAlign: "center",
            display: timelineDisplay ? "" : "none",
          }}
        >
          {translation("noComments")}
        </div>
      )}
    </div>
  );
};
