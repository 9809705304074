import axios from "axios";
import {
  UPDATE_ALL_PROJECT_PRIORITIZATION,
  UPDATE_CENARY_THEME,
  UPDATE_THEME_QUESTION,
  //DELETE_CRITERIA,
  //DELETE_PROJECT_GROUP,
  //DELETE_CRITERIA_GROUP,
  //DELETE_PROJECT_TYPE
} from "./types";

import { putTheme, fetchAllSceneryThemes } from "./themesActions";
import { putQuestion, fetchAllThemesQuestions } from "./questionsActions";

export const getProjectPrioritizations = (selectedToolId) => {
  return axios
    .get(
      `/api/selected-tools/${selectedToolId}/projectPriorizationConfiguration`,
    )
    .then((res) => res);
};

export const putProjectPrioritization = (body) => {
  return axios
    .put(`/api/project-priorization-configurations`, body)
    .then((res) => res);
};

export const fetchProjectPrioritizations = (selectedToolId) => {
  return async (dispatch) => {
    if (selectedToolId) {
      const projectPrioritizations = await getProjectPrioritizations(
        selectedToolId,
      );

      if (projectPrioritizations.data) {
        dispatch({
          type: UPDATE_ALL_PROJECT_PRIORITIZATION,
          payload: { ...projectPrioritizations.data, selectedToolId },
        });
      }
    }
  };
};

export const postProjectPrioritizations = (selectedToolId, body) => {
  return axios
    .post(
      `/api/selected-tools/${selectedToolId}/projectPriorizationConfiguration`,
      body,
    )
    .then((res) => res);
};

export const updateProjectPrioritization = (selectedToolId, body) => {
  return async (dispatch) => {
    const sendDispatch = (type, payload, loading = false, error) => {
      dispatch({ type, payload, loading, error });
    };

    if (selectedToolId) {
      try {
        const request = await getProjectPrioritizations(selectedToolId);

        if (request.data) {
          try {
            await putProjectPrioritization(body);

            const updatedProjectPrioritization = await getProjectPrioritizations(
              selectedToolId,
            );

            sendDispatch(UPDATE_ALL_PROJECT_PRIORITIZATION, {
              ...updatedProjectPrioritization.data,
              selectedToolId,
            });
          } catch (err) {
            sendDispatch(UPDATE_ALL_PROJECT_PRIORITIZATION, null, false, err);
          }
        }
      } catch (err) {
        try {
          const newProjectPrioritization = await postProjectPrioritizations(
            selectedToolId,
            body,
          );

          sendDispatch(UPDATE_ALL_PROJECT_PRIORITIZATION, {
            ...newProjectPrioritization,
            selectedToolId,
          });
        } catch (err) {
          sendDispatch(UPDATE_ALL_PROJECT_PRIORITIZATION, null, false, err);
        }
      }
    }
  };
};

export const postInsightProjectPrioritization = (insightId, prioritization) => {
  return axios
    .post(`/api/project-priorization-evaluation/${insightId}/`, prioritization)
    .then((res) => res);
};

export const fetchAllInsightPrioritizations = (selectedToolId) => {
  return async (dispatch) => {
    const fetchedRes = await axios.get(
      `/api/project-priorization-evaluations/selectedTool/${selectedToolId}`,
    );

    if (fetchedRes.data) {
      dispatch({
        type: "INSIGHT_PROJECT_PRIORITIZATION",
        payload: fetchedRes.data,
      });
    }
  };
};

export const handleDeleteCriteria = (criteriaID, selectedToolId) => {
  return async (dispatch) => {
    await deleteCriteria(criteriaID);
    dispatch(fetchProjectPrioritizations(selectedToolId));
  };
};

export const deleteCriteria = (criteriaID) => {
  return axios
    .delete(`/api/project-priorization-configuration-criteria/${criteriaID}`)
    .then((res) => {
      return res;
    });
};

export const handleDeleteCriteriaGroup = (criteriaGroupID, selectedToolId) => {
  return async (dispatch) => {
    await deleteCriteriaGroup(criteriaGroupID);
    dispatch(fetchProjectPrioritizations(selectedToolId));
  };
};

export const deleteCriteriaGroup = (criteriaGroupID) => {
  return axios
    .delete(
      `/api/project-priorization-configuration-criteria-group/${criteriaGroupID}`,
    )
    .then((res) => {
      return res;
    });
};

export const handleDeleteProjectGroup = (projectGroupID, selectedToolId) => {
  return async (dispatch) => {
    await deleteProjectGroup(projectGroupID);
    dispatch(fetchProjectPrioritizations(selectedToolId));
  };
};

export const deleteProjectGroup = (projectGroupID) => {
  return axios
    .delete(`/api/project-priorization-configuration-group/${projectGroupID}`)
    .then((res) => {
      return res;
    });
};

export const handleDeleteProjectType = (projectTypeID, selectedToolId) => {
  return async (dispatch) => {
    await deleteProjectType(projectTypeID);
    dispatch(fetchProjectPrioritizations(selectedToolId));
  };
};

export const deleteProjectType = (projectTypeID) => {
  return axios
    .delete(`/api/project-priorization-configuration-type/${projectTypeID}`)
    .then((res) => {
      return res;
    });
};

export const updateInsightProjectPrioritization = (
  insightId,
  prioritization,
  selectedToolId,
) => {
  return async (dispatch) => {
    if (insightId && prioritization) {
      await postInsightProjectPrioritization(insightId, prioritization);

      if (selectedToolId)
        dispatch(fetchAllInsightPrioritizations(selectedToolId));
    }
  };
};

export const postProjectPrioritizationGroup = (themeId, projectGroupId) => {
  return axios
    .post(
      `/api/project-priorization-configurations/theme/${themeId}/project-group/${projectGroupId}`,
    )
    .then((res) => {
      return res;
    });
};

export const createProjectPrioritizationGroupTheme = (
  cenaryBody = {},
  groupInfo = {},
) => {
  return async (dispatch) => {
    if (cenaryBody.id && groupInfo.id) {
      try {
        const newTheme = await putTheme({
          id: null,
          type: "theme",
          cenary: cenaryBody,
          name: groupInfo.title,
        });

        dispatch({
          type: UPDATE_CENARY_THEME,
          payload: {
            cenaryId: cenaryBody.id,
            theme: {
              ...newTheme.data,
              isNew: true,
            },
          },
        });

        if (newTheme.data.id) {
          await postProjectPrioritizationGroup(newTheme.data.id, groupInfo.id);

          if (cenaryBody.id) {
            dispatch(fetchAllSceneryThemes([cenaryBody.id]));
          }
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };
};

export const postProjectPrioritizationType = (questionId, projectTypeId) => {
  return axios
    .post(
      `/api/project-priorization-configurations/question/${questionId}/project-type/${projectTypeId}`,
    )
    .then((res) => res);
};

export const createProjectTypeQuestion = (
  themeBody = {},
  projectTypeInfo = {},
) => {
  return async (dispatch) => {
    if (themeBody.id && projectTypeInfo.id) {
      try {
        const newQuestion = await putQuestion({
          id: null,
          type: "question",
          theme: themeBody,
          text: projectTypeInfo.title,
        });

        dispatch({
          type: UPDATE_THEME_QUESTION,
          payload: {
            themeId: themeBody.id,
            question: {
              ...newQuestion.data,
              isNew: true,
            },
          },
        });

        if (newQuestion.data.id) {
          await postProjectPrioritizationType(
            newQuestion.data.id,
            projectTypeInfo.id,
          );

          if (themeBody && themeBody.id) {
            dispatch(fetchAllThemesQuestions([themeBody.id]));
          }
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };
};
