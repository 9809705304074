import React from "react";
import PropTypes from "prop-types";

import { Tooltip } from "@material-ui/core";
import { DATE_WITH_HOUR_FORMAT, formatDate } from "../../../utils/DateUtils";
import { getMaxStringLength } from "../../../utils/StringUtils";

import {
  Container,
  FileInfo,
  IconCheck,
  IconAlert,
  IconDownload,
  IconPreview,
} from "./styles";

const fileIcons = {
  PDF: "fa-file-pdf",
  AUDIO: "fa-file-audio",
  VIDEO: "fa-file-video",
  IMAGE: "fa-file-image",
  MS_WORD: "fa-file-word",
  MS_PROJECT: "fa-file",
  MS_POWERPOINT: "fa-file-powerpoint",
  MS_EXCEL: "fa-file-excel",
  ODF: "fa-file",
  ODP: "fa-file",
  ODS: "fa-file",
  ODT: "fa-file",
  TEXT: "fa-file-alt",
};

const FileList = ({ files, onDelete, onDownload }) => {
  return (
    <Container>
      {files.map((file) => {
        return (
          !!file && (
            <li key={file.id} style={{ overflow: "hidden" }}>
              <FileInfo>
                <IconPreview
                  className={`far ${fileIcons[file?.fileType] || "fa-file"}`}
                />
                <div>
                  <Tooltip title={file.fileName}>
                    <strong onClick={() => onDownload(file)}>
                      {getMaxStringLength({
                        string: file.fileName,
                        beginIndex: 0,
                        endIndex: 60,
                        additional: "...",
                      })}
                    </strong>
                  </Tooltip>
                  <span>
                    {formatDate(file.date, DATE_WITH_HOUR_FORMAT)}
                    <button
                      onClick={!isNaN(file.id) ? () => onDelete(file) : null}
                    >
                      Excluir
                    </button>
                  </span>
                </div>
              </FileInfo>

              <div>
                {!isNaN(file.id) && (
                  <IconDownload
                    className="fas fa-file-download"
                    onClick={() => onDownload(file)}
                  />
                )}

                {file.id ? (
                  <IconCheck className="fas fa-check" />
                ) : (
                  <IconAlert className="fas fa-info" />
                )}
              </div>
            </li>
          )
        );
      })}
    </Container>
  );
};

export default FileList;

FileList.propTypes = {
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fileName: PropTypes.string.isRequired,
      fileType: PropTypes.string,
      date: PropTypes.string,
    }),
  ),
};

FileList.defaultProps = {
  onDelete: null,
  onDownload: null,
  files: [],
};
