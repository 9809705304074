import _ from "lodash";
import { updateAllList } from "../utils/reducerUtils";

const INITIAL_STATE = {
  allPositions: {},
};

const companyPositionsReducer = (state = INITIAL_STATE, action) => {
  const payload = action.payload || {};
  const { companyId = null, isLoading = false } = payload;

  function updateCompanyPositions(newPositionStructure) {
    if (companyId && newPositionStructure) {
      const currentAllPositions = _.cloneDeep(state.allPositions);
      const currentCompanyPositions = currentAllPositions[companyId] || [];

      return {
        ...state,
        isLoading: false,
        allPositions: {
          ...state.allPositions,
          [companyId]: updateAllList(
            currentCompanyPositions,
            [newPositionStructure],
            "id"
          ),
        },
      };
    } else {
      return state;
    }
  }

  switch (action.type) {
    case "COMPANY_POSITIONS_LOADING": {
      return { ...state, isLoading };
    }
    case "FETCHED_COMPANY_POSITIONS": {
      return {
        ...state,
        allPositions: {
          ...state.allPositions,
          [companyId]: payload.structure,
        },
        isLoading: false,
      };
    }
    case "UPDATE_COMPANY_POSITIONS_STRUCTURE": {
      return updateCompanyPositions(payload.structure);
    }
    case "COMPANY_POSITIONS_MASS_UPDATE": {
      return {
        ...state,
        allPositions: {
          ...state.allPositions,
          [companyId]: payload.structure,
        },
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default companyPositionsReducer;
