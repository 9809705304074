import { getValidId, getValidEntityConfigs } from "../validations";

export const buildAreaEntity = (area = {}, config) => {
  const { stringValue, idValue, arrayValue } = getValidEntityConfigs(config);

  return {
    id: getValidId(area.id, idValue),
    parentAreaId: getValidId(area.parentAreaId, idValue),
    title: area.title || stringValue,
    startDate: area.startDate || stringValue,
    endDate: area.endDate || stringValue,
    subAreas: area.subAreas || arrayValue,
    active: area.active === false ? false : true,
    responsible: area.responsible || null,
  };
};

export const buildRestAreaEntity = (area = {}) => {
  return buildAreaEntity(area, {
    stringValue: null,
    arrayValue: [],
    idValue: null,
    active: true,
  });
};

export const buildFormAreaEntity = (area = {}) => {
  return buildAreaEntity(area, {
    stringValue: "",
    arrayValue: [],
    idValue: null,
    active: true,
  });
};
