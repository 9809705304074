import React from "react";
import SimpleBarChart from "../../../../../Common/SimpleBarChart";
import SimpleHorizontalBarChart from "../../../../../Common/SimpleHorizontalBarChart";
import SimplePieChart from "../../../../../Common/SimplePieChart";
import { calculateAge } from "../../../../../../utils/DateUtils";
import utils from "../../../../../../utils/toolUtils";

const { getLabelBySlug } = utils;

const ProfileField = (props) => {
  const {
    filteredEvaluations,
    genderOptions,
    educationOptions,
    intl,
    messages,
  } = props;

  const displayPeopleGenderGraph = () => {
    let m = 0;
    let f = 0;
    let nSelected = 0;

    filteredEvaluations.forEach(({ peopleEvaluation }) => {
      const { professionalProfile } = peopleEvaluation;
      if (professionalProfile.gender === "MALE") m++;
      if (professionalProfile.gender === "FEMALE") f++;
    });

    nSelected = filteredEvaluations.length - (m + f);

    let graphData = [
      {
        name: getLabelBySlug("MALE", genderOptions),
        value: m,
        color: "#8884d8",
      },
      {
        name: getLabelBySlug("FEMALE", genderOptions),
        value: f,
        color: "#fc7e7e",
      },
    ];

    if (nSelected > 0) {
      graphData = [
        {
          name: intl.formatMessage(messages.global_notSelected),
          value: nSelected,
          color: "#ccc",
        },
        ...graphData,
      ];
    }

    return <SimplePieChart data={graphData} containerHeight={220} />;
  };

  const displayPeopleAgeGraph = () => {
    let nSelected = 0;
    let up25 = 0;
    let up30 = 0;
    let up35 = 0;
    let up40 = 0;
    let moreThan40 = 0;

    filteredEvaluations.forEach(({ peopleEvaluation }) => {
      const { professionalProfile } = peopleEvaluation;
      const personAge = calculateAge(professionalProfile.birthDate);

      if (personAge) {
        if (personAge <= 25) up25++;
        if (personAge > 25 && personAge <= 30) up30++;
        if (personAge > 30 && personAge <= 35) up35++;
        if (personAge > 35 && personAge <= 40) up40++;
        if (personAge > 40) moreThan40++;
      } else {
        nSelected++;
      }
    });

    let graphData = [
      { name: `${intl.formatMessage(messages.labels_up_to)} 25`, value: up25 },
      { name: `${intl.formatMessage(messages.labels_up_to)} 30`, value: up30 },
      { name: `${intl.formatMessage(messages.labels_up_to)} 35`, value: up35 },
      { name: `${intl.formatMessage(messages.labels_up_to)} 40`, value: up40 },
      { name: "> 40", value: moreThan40 },
    ];

    if (nSelected > 0) {
      graphData = [{ name: "Undefined", value: nSelected }, ...graphData];
    }

    return (
      <SimpleBarChart
        containerHeight={180}
        hideTooltipLabelName
        data={graphData}
      />
    );
  };

  const displayHouseTimeGraph = () => {
    let nSelected = 0;
    let up1 = 0;
    let up2 = 0;
    let up3 = 0;
    let up4 = 0;
    let up5 = 0;
    let up10 = 0;
    let moreThant10 = 0;

    filteredEvaluations.forEach(({ peopleEvaluation }) => {
      const { professionalProfile } = peopleEvaluation;
      const { entryDate } = professionalProfile;
      if (entryDate) {
        const houseTime = calculateAge(entryDate);

        if (houseTime <= 1) up1++;
        if (houseTime > 1 && houseTime <= 2) up2++;
        if (houseTime > 2 && houseTime <= 3) up3++;
        if (houseTime > 3 && houseTime <= 4) up4++;
        if (houseTime > 4 && houseTime <= 5) up5++;
        if (houseTime > 5 && houseTime <= 10) up10++;
        if (houseTime > 10) moreThant10++;
      } else {
        nSelected++;
      }
    });

    let graphData = [
      { name: `${intl.formatMessage(messages.labels_up_to)} 1`, value: up1 },
      { name: `${intl.formatMessage(messages.labels_up_to)} 2`, value: up2 },
      { name: `${intl.formatMessage(messages.labels_up_to)} 3`, value: up3 },
      { name: `${intl.formatMessage(messages.labels_up_to)} 4`, value: up4 },
      { name: `${intl.formatMessage(messages.labels_up_to)} 5`, value: up5 },
      { name: `${intl.formatMessage(messages.labels_up_to)} 10`, value: up10 },
      { name: "> 10", value: moreThant10 },
    ];

    if (nSelected > 0) {
      graphData = [
        {
          name: intl.formatMessage(messages.global_undefined),
          value: nSelected,
        },
        ...graphData,
      ];
    }

    return (
      <SimpleHorizontalBarChart
        containerHeight={240}
        hideTooltipLabelName
        data={graphData}
      />
    );
  };

  const displayEducationGraph = () => {
    let nSelected = 0;
    let elementary = 0;
    let technical = 0;
    let graduation = 0;
    let postgraduate = 0;
    let masters = 0;
    let doctorate = 0;

    filteredEvaluations.forEach(({ peopleEvaluation }) => {
      const { professionalProfile = {} } = peopleEvaluation;
      const { education = {} } = professionalProfile;
      if (education.level) {
        if (education.level === "ELEMENTARY") elementary++;
        if (education.level === "TECHNICAL") technical++;
        if (education.level === "GRADUATION") graduation++;
        if (education.level === "POSTGRADUATE") postgraduate++;
        if (education.level === "MASTERS") masters++;
        if (education.level === "DOCTORATE") doctorate++;
      } else {
        nSelected++;
      }
    });

    let graphData = [
      {
        name: getLabelBySlug("ELEMENTARY", educationOptions),
        value: elementary,
      },
      { name: getLabelBySlug("TECHNICAL", educationOptions), value: technical },
      {
        name: getLabelBySlug("GRADUATION", educationOptions),
        value: graduation,
      },
      {
        name: getLabelBySlug("POSTGRADUATE", educationOptions),
        value: postgraduate,
      },
      { name: getLabelBySlug("MASTERS", educationOptions), value: masters },
      { name: getLabelBySlug("DOCTORATE", educationOptions), value: doctorate },
    ];

    if (nSelected > 0) {
      graphData = [
        {
          name: intl.formatMessage(messages.global_undefined),
          value: nSelected,
        },
        ...graphData,
      ];
    }

    return (
      <SimpleHorizontalBarChart
        containerHeight={240}
        hideTooltipLabelName
        data={graphData}
      />
    );
  };

  return (
    <div className="evaluation-card">
      <div className="card-header">
        <div className="card-title">
          <h4>{intl.formatMessage(messages.titles_cards_profile)}</h4>
        </div>
      </div>
      <div
        className="card-body"
        style={{ maxHeight: "560px", overflowX: "auto", paddingBottom: "0px" }}
      >
        <div className="row">
          <div className="col-xs-6">
            <h5 align="center">{intl.formatMessage(messages.labels_gender)}</h5>
            <div align="center">{displayPeopleGenderGraph()}</div>
          </div>
          <div className="col-xs-6">
            <h5 align="center">
              {intl.formatMessage(messages.labels_age_group)}
            </h5>
            <div align="center">{displayPeopleAgeGraph()}</div>
          </div>
        </div>
        <div className="row padding-row">
          <div className="col-xs-6">
            <h5 align="center">
              {intl.formatMessage(messages.labels_working_time)}
            </h5>
            <div align="center">{displayHouseTimeGraph()}</div>
          </div>
          <div className="col-xs-6">
            <h5 align="center">
              {intl.formatMessage(messages.labels_education)}
            </h5>
            <div align="center">{displayEducationGraph()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileField;
