import React, { useState } from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import utils from "../../../../../../../utils/toolUtils";
import { deleteGoal } from "../../../../../../../actions/goalsAndBonusActions";
import { archiveInsightsList } from "../../../../../../../actions/insightActions";
import {
  getEmptyTableRow,
  getDefaultAddTableLine,
  getDefaultEditColumn,
} from "../../../../../../../utils/tableUtils";
import { getDefaultRemoveColumn } from "../../../../../../../utils/okrUtils";
import { displayTransformValue } from "../../../../../../../utils/kpis/display";
import MaterialSingleSelect from "../../../../../../Common/MaterialSingleSelect";
import LinkedIcon from "../../../../../../Common/GoalsAndBonus/includes/LinkedIcon";

const {
  getUpdatedDeleteArrayByIndex,
  orderArrayByObjAttr,
  getObjectInfoById,
  getOnlySublevelArrays,
  getOnlySlugValues,
} = utils;

const messages = defineMessages({
  gb_table_text_goal_upper: { id: "gb_table_text_goal_upper" },
  gb_table_text_no_goal: { id: "gb_table_text_no_goal" },
  gb_table_text_relationship: { id: "gb_table_text_relationship" },
  gb_table_text_status: { id: "gb_table_text_status" },
  gb_table_text_weight: { id: "gb_table_text_weight" },
  gb_confirm_delete_goal: { id: "gb_confirm_delete_goal" },
  gb_warning_connected_goal_insight: {
    id: "gb_warning_connected_goal_insight",
  },
  gb_warning_insights_will_delete: { id: "gb_warning_insights_will_delete" },
  gb_confirm_archive_all: { id: "gb_confirm_archive_all" },
});

const defaultFilter = {
  relationshipType: false,
  workflowStatus: false,
};

const GoalsTable = (props) => {
  const [filterBy, setFilterBy] = useState(defaultFilter);

  const {
    goalBonusAdministration,
    setGoalBonusAdministration,
    getDefaultTargetCols,
    toggleOpenFormDialogs,
    setConfirmationDialog,
    resetConfirmDialog,
    handleSetEditingForm,
    relationshipTypeOptions,
    goalValidationStatusOptions,
    allAnswers = {},
    intl,
    archiveInsightsList,
  } = props;

  const updateFilter = (filterSlug, newValue) => {
    setFilterBy({ ...filterBy, [filterSlug]: newValue });
  };

  const handleArchiveInsights = (allRelatedInsights) => {
    const archiveIdsList = getOnlySlugValues(allRelatedInsights, "id");

    archiveInsightsList(archiveIdsList);

    resetConfirmDialog();
  };

  const handleDeleteGoal = (index) => {
    const deletingGoalInfo = goalBonusAdministration.goals[index];

    if (deletingGoalInfo.id) {
      props.deleteGoal(deletingGoalInfo.id, goalBonusAdministration.id);

      const updatedGoals = getUpdatedDeleteArrayByIndex(
        goalBonusAdministration.goals,
        index,
      );

      setGoalBonusAdministration({
        ...goalBonusAdministration,
        goals: updatedGoals,
      });
    }

    resetConfirmDialog();
  };

  const openDeleteGoal = (index) => {
    const deletingGoal = goalBonusAdministration.goals[index] || {};

    function getRelatedInsights(goalsBonusProgramId, source = []) {
      if (source && source.length > 0) {
        return source.filter(
          (insightInfo) =>
            insightInfo.goalsBonusProgramGoalId === goalsBonusProgramId,
        );
      }

      return [];
    }

    if (deletingGoal.id) {
      const onlyInsights = getOnlySublevelArrays(allAnswers);

      const allRelatedInsights = getRelatedInsights(
        deletingGoal.id,
        onlyInsights,
      );

      const allowedToDelete = allRelatedInsights.length === 0;

      const confirmationFunction = allowedToDelete
        ? () => handleDeleteGoal(index)
        : () => handleArchiveInsights(allRelatedInsights);

      setConfirmationDialog({
        title: allowedToDelete
          ? intl.formatMessage(messages.gb_confirm_delete_goal)
          : intl.formatMessage(messages.gb_warning_connected_goal_insight),
        description: allowedToDelete ? null : (
          <span>
            {intl.formatMessage(messages.gb_warning_insights_will_delete)}:
            {allRelatedInsights.map((insightInfo) => {
              const { question = {} } = insightInfo;

              return (
                <div style={{ paddingTop: "10px" }} key={insightInfo.id}>
                  {question.id && `${question.text} -> `}
                  <b>{insightInfo.text}</b>
                </div>
              );
            })}
          </span>
        ),
        onConfirm: confirmationFunction,
        confirmText: !allowedToDelete
          ? intl.formatMessage(messages.gb_confirm_archive_all)
          : null,
        onCancel: resetConfirmDialog,
        open: true,
      });
    }
  };

  const filterDisabled = !filterBy.relationshipType && !filterBy.workflowStatus;

  const getFilterCleaner = () => {
    return (
      !filterDisabled && (
        <div className="col-xs-6">
          <a
            onClick={() => setFilterBy(defaultFilter)}
            style={{ cursor: "pointer" }}
          >
            Limpar filtro
          </a>
        </div>
      )
    );
  };

  const { goals = [] } = goalBonusAdministration;
  const filtered = !filterDisabled
    ? [...goals].filter(({ relationshipType, workflowStatus }) => {
        if (
          filterBy.relationshipType &&
          relationshipType !== filterBy.relationshipType
        )
          return false;
        if (
          filterBy.workflowStatus &&
          workflowStatus !== filterBy.workflowStatus
        )
          return false;
        return true;
      })
    : goals;
  const reorderedGoals = orderArrayByObjAttr(filtered, "title");

  return (
    <div>
      <div className="row" style={{ display: "flex", alignItems: "center" }}>
        <div
          className="col-xs-2"
          style={{ padding: "0px 15px 0px 0px", backgroundColor: "#fff" }}
        >
          <MaterialSingleSelect
            id="filterRelationshipType"
            label="Filtro por Relacionamento"
            options={[
              { value: false, label: "Todas" },
              ...relationshipTypeOptions,
            ]}
            value={filterBy.relationshipType}
            onChange={(e) => updateFilter("relationshipType", e.target.value)}
          />
        </div>
        <div
          className="col-xs-2"
          style={{ padding: "0px 15px 0px 0px", backgroundColor: "#fff" }}
        >
          <MaterialSingleSelect
            id="filterStatus"
            label="Filtro por Status"
            options={[
              { value: false, label: "Todos" },
              ...goalValidationStatusOptions,
            ]}
            value={filterBy.workflowStatus}
            onChange={(e) => updateFilter("workflowStatus", e.target.value)}
          />
        </div>
        {getFilterCleaner()}
      </div>
      <table className="simple-table table-striped">
        <thead>
          {getDefaultAddTableLine(() =>
            toggleOpenFormDialogs("newGoalMiddleware"),
          )}
        </thead>
        <tbody>
          <tr className="thead">
            <td>{intl.formatMessage(messages.gb_table_text_goal_upper)}</td>
            <td width="150">
              {intl.formatMessage(messages.gb_table_text_relationship)}
            </td>
            <td width="150">
              {intl.formatMessage(messages.gb_table_text_status)}
            </td>
            <td width="150">
              {intl.formatMessage(messages.gb_table_text_weight)}
            </td>
            {getDefaultTargetCols()}
            <td colSpan="2" />
          </tr>
          {reorderedGoals?.map((goalInfo, index) => {
            const haveLinkedIndicator = goalInfo?.linkedIndicator?.id > 0;

            return (
              <tr className="block-hover" key={index}>
                <td>
                  {haveLinkedIndicator && <LinkedIcon text={goalInfo.title} />}
                  {!haveLinkedIndicator && goalInfo.title}
                </td>
                <td>
                  {
                    getObjectInfoById(
                      goalInfo.relationshipType,
                      relationshipTypeOptions,
                      "value",
                    ).label
                  }
                </td>
                <td>
                  {
                    getObjectInfoById(
                      goalInfo.workflowStatus,
                      goalValidationStatusOptions,
                      "value",
                    ).label
                  }
                </td>
                <td align="right">{goalInfo.weight}%</td>
                <td align="right" width="140">
                  {displayTransformValue(
                    goalInfo.target.gate,
                    goalInfo.measurementUnity,
                  )}
                </td>
                <td align="right" width="140">
                  {displayTransformValue(
                    goalInfo.target.appropriated,
                    goalInfo.measurementUnity,
                  )}
                </td>
                <td align="right" width="140">
                  {displayTransformValue(
                    goalInfo.target.exceeded,
                    goalInfo.measurementUnity,
                  )}
                </td>
                {getDefaultEditColumn(() =>
                  handleSetEditingForm("newGoal", {
                    goalId: goalInfo.id,
                  }),
                )}
                {getDefaultRemoveColumn(() => openDeleteGoal(index))}
              </tr>
            );
          })}
          {getEmptyTableRow(
            reorderedGoals,
            filterDisabled
              ? intl.formatMessage(messages.gb_table_text_no_goal)
              : "Nenhum encontrado",
          )}
        </tbody>
      </table>
    </div>
  );
};

export default injectIntl(
  connect(null, { deleteGoal, archiveInsightsList })(GoalsTable),
);
