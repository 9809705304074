import scenarioTranslations from "./scenarioTranslations";
import themeTranslations from "./themeTranslations";
import questionTranslations from "./questionTranslations";
import insightTranslations from "./insightTranslations";

const structureTranslations = [
  scenarioTranslations,
  themeTranslations,
  questionTranslations,
  insightTranslations,
];

const finalTranslations = [...structureTranslations];

export default finalTranslations;
