import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useCompanyAreasTree } from "../../../../../../../utils/customHooks";
import SelectTreeModal from "../../../../../../Common/SelectTreeModal";
import {
  getSelectedCompanyStates,
  getCompanyStructureStates,
} from "../../../../../../customMapStates";

const SelectParticipantArea = ({
  open,
  onConfirm,
  onCancel,
  selectedCompany,
  allAreaStructures,
}) => {
  const { companyAreasTree } = useCompanyAreasTree({
    selectedCompany,
    allAreaStructures,
  });

  return (
    <>
      <SelectTreeModal
        open={open}
        title="Selecione a Área"
        treeList={companyAreasTree.treeList}
        treeOrder={companyAreasTree.treeOrder}
        onConfirm={(selected) => onConfirm(selected[0])}
        onCancel={onCancel}
        singleSelect
        canSelectAnyLevel
        dialogClassName="select-participant-area-dialog"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { selectedCompany } = getSelectedCompanyStates(state);
  const { allAreaStructures } = getCompanyStructureStates(state);

  return { selectedCompany, allAreaStructures };
};

SelectParticipantArea.propTypes = {
  open: PropTypes.bool,
  selectedCompany: PropTypes.object.isRequired,
  allAreaStructures: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

SelectParticipantArea.defaultProps = {
  open: false,
};

export default connect(mapStateToProps)(SelectParticipantArea);
