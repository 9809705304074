import React from "react";
import ActionsPlanEvaluations from "./includes/ActionsPlanEvaluations";
import ActionsPlanInsightsGroup from "./includes/ActionsPlanInsightsGroup";
import { getOnlySlugValues } from "../../../utils/ArrayUtils";
import { getFilteredActionsPlanByInsights } from "../../../utils/actionsPlan/filter";

const ActionsPlanOverview = (props) => {
  const {
    allActionsPlans = [],
    filteredInsights = [],
    overviewTableTitle = "",
    overviewTableInsightsLabel = "",
    insightsTableTitle = "",
    insightsTableInsightsLabel = "",
    selectedTool = {},
    selectAndOpenInsight,
    totalCount,
  } = props;

  const insightIds = getOnlySlugValues(filteredInsights, "id");

  const appendProps = {
    actionsPlan: getFilteredActionsPlanByInsights(insightIds, allActionsPlans),
    insights: filteredInsights,
  };

  return (
    <div className="row">
      <div className="col-xs-12" style={{ padding: "0px" }}>
        <div className="row">
          <ActionsPlanEvaluations
            {...appendProps}
            overviewTableTitle={overviewTableTitle}
            overviewTableInsightsLabel={overviewTableInsightsLabel}
            totalCount={totalCount}
          />
        </div>
      </div>
      <div className="col-xs-12" style={{ padding: "0px" }}>
        <div className="row">
          <ActionsPlanInsightsGroup
            {...appendProps}
            insightsTableTitle={insightsTableTitle}
            insightsTableInsightsLabel={insightsTableInsightsLabel}
            selectedTool={selectedTool}
            selectAndOpenInsight={selectAndOpenInsight}
          />
        </div>
      </div>
    </div>
  );
};

export default ActionsPlanOverview;
