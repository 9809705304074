import * as actions from "./_actions";
import send from "../dispatcher";

const observeAccessLevels = ({ stompClient, dispatch }) => {
  stompClient.subscribe("/domains/addDomainUserEntityRole", (response) =>
    send(actions.addAccess, response, dispatch)
  );

  stompClient.subscribe("/domains/addDomainUserEntityRoles", (response) =>
    send(actions.addAccesses, response, dispatch)
  );

  stompClient.subscribe("/domain-users/deleteAccess", (response) =>
    send(actions.deleteAccess, response, dispatch)
  );

  stompClient.subscribe("/domain-users/deleteAccesses", (response) =>
    send(actions.deleteAccesses, response, dispatch)
  );

  return stompClient;
};

export default observeAccessLevels;
