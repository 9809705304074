import { useMemo } from "react";

import { Avatar, Tooltip } from "@material-ui/core";
import { getNameInitials } from "../../utils/StringUtils";

const UserAvatar = ({
  name = "",
  avatar = "",
  src = "",
  style,
  className,
  timestamp,
  crossOrigin = false,
}) => {
  const userName = useMemo(() => {
    return getNameInitials(name);
  }, [name]);
  const profileImageS3 = useMemo(() => {
    if (!src) return "";

    return `https://splace-profile-images.s3-sa-east-1.amazonaws.com/${src}?v=no-cache${
      timestamp || ""
    }`;
  }, [src, timestamp]);

  const imgProps = useMemo(() => {
    if (crossOrigin) {
      return { crossOrigin };
    }
    return {};
  }, [crossOrigin]);

  return (
    <Tooltip title={name} placement="bottom">
      <Avatar
        src={src ? profileImageS3 : avatar}
        style={style}
        className={className}
        imgProps={imgProps}
      >
        {userName || <i className="fas fa-user" />}
      </Avatar>
    </Tooltip>
  );
};

export default UserAvatar;
