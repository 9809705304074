import React from "react";
import PropTypes from "prop-types";

import { AlertCustom } from "./styles";

function MaterialAlert({ message, severity }) {
  return <AlertCustom severity={severity}>{message}</AlertCustom>;
}

export default MaterialAlert;

MaterialAlert.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.oneOf(["error", "warning", "info", "success"]).isRequired,
};

MaterialAlert.defaultProps = {
  message: "",
};
