import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { injectIntl, defineMessages } from "react-intl";
import _ from "lodash";

import FormDialog from "../../../../../Common/FormDialog";
import MaterialSingleSelect from "../../../../../Common/MaterialSingleSelect";
import ActivitiesTable from "../../ActivitiesTable";

import { activitiesTable } from "../../translations";
import {
  cloneChecklist,
  fetchTemplatesMilestones,
} from "../../../../../../actions/projectEvaluationActions";
import { setTemplate } from "../../../../../../actions/cenaryActions";
import MaterialAlert from "../../../../../Common/MaterialAlert";
import { getCompanyMembersAndParticipants } from "../../../../../customMapStates";

const messages = defineMessages(activitiesTable);

function ActivitiesTableContainer({
  intl,
  weightAutomation,
  effortAutomation,
  activitiesType,
  setProjectEvaluation,
  selectedInsightID,
  handleUpdateActivitiesTypeDefault,
  activities,
  setActivities,
  postChecklist,
  saveButton,
  saveButtonJSX,
  workspaceId,
  domainId,
  saveTemplateActivities,
  insightId,
  companyMembersAndParticipants,
  onConfirmEdit,
  onConfirmSave,
  onConfirmDelete,
  onChangeStatus,
}) {
  const [openModalTemplates, setOpenModalTemplates] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({ id: null });
  const [optionsTemplates, setOptionsTemplates] = useState([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    if (openModalTemplates && domainId && workspaceId && !isLoadingTemplates) {
      setIsLoadingTemplates(true);

      fetchTemplatesMilestones({ domainId, workspaceId }).then((data) => {
        setOptionsTemplates(
          data.map((template) => ({
            value: template.id,
            label: template.name,
          })),
        );
        setTemplates(data);
        setIsLoadingTemplates(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalTemplates, domainId, workspaceId]);

  const handleCloseModalTemplates = () => {
    setOpenModalTemplates(false);
    setTemplate([]);
    setSelectedTemplate({ id: null });
  };

  const handleSelectTemplate = useCallback(
    (event) => {
      setSelectedTemplate(
        templates.find((template) => template.id === event.target.value),
      );
    },
    [templates],
  );

  async function getTransformedActivities() {
    const activitiesWithNewIdChecklists = await Promise.all(
      selectedTemplate?.activities.map(async (activity) => {
        const current_activity_id = uuidv4();
        const cloneActivity = _.cloneDeep(activity);
        delete cloneActivity.id;

        await cloneChecklist({
          activity_id: activity.idChecklist,
          insight_id: insightId,
          current_activity_id,
        });

        return {
          ...cloneActivity,
          idChecklist: current_activity_id,
        };
      }),
    );

    saveTemplateActivities(
      activitiesWithNewIdChecklists,
      selectedTemplate.activitiesOptions,
    );
    handleCloseModalTemplates();
  }

  function renameActivitiesOptions() {
    return {
      ...selectedTemplate.activitiesOptions,
      activitiesType:
        selectedTemplate?.activitiesOptions?.activitiesType === "HOURS"
          ? "Horas"
          : "Tarefas",
    };
  }

  const selectedTemplateInfo = renameActivitiesOptions();

  return (
    <div className="box box-primary">
      <div
        id="header-insight-evaluation"
        className="box-header with-border"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <div style={{ width: "100%" }}>
          <i className="fas fa-chess-knight" />
          <h3 className="box-title">
            {intl.formatMessage(messages.pport_table_milestone)}
          </h3>
        </div>
        {saveButton && saveButtonJSX()}
      </div>
      <ActivitiesTable
        activities={activities}
        setActivities={setActivities}
        postChecklist={postChecklist}
        weightAutomation={weightAutomation}
        effortAutomation={effortAutomation}
        activitiesType={activitiesType}
        setProjectEvaluation={setProjectEvaluation}
        selectedInsightID={selectedInsightID}
        handleUpdateActivitiesTypeDefault={handleUpdateActivitiesTypeDefault}
        onClickSelectTemplate={() => setOpenModalTemplates(true)}
        isSelectTemplate
        onConfirmEdit={onConfirmEdit}
        onConfirmSave={onConfirmSave}
        onConfirmDelete={onConfirmDelete}
        onChangeStatus={onChangeStatus}
      />
      {openModalTemplates && (
        <FormDialog
          open
          onCancel={handleCloseModalTemplates}
          onConfirm={getTransformedActivities}
          title="Templates"
          dialogClassName="modal-templates"
          blockConfirm={!selectedTemplate.id}
        >
          {!isLoadingTemplates && (
            <MaterialSingleSelect
              label="Selecione um template"
              value={selectedTemplate?.id}
              options={optionsTemplates}
              onChange={handleSelectTemplate}
            />
          )}
          <div style={{ margin: "10px 0" }}>
            {selectedTemplate.activities?.length ? (
              <ActivitiesTable
                companyMembersAndParticipants={companyMembersAndParticipants}
                activities={selectedTemplate.activities}
                isTemplate
                blockEdit
                activitiesType={selectedTemplateInfo.activitiesType}
                weightAutomation={selectedTemplateInfo.weightAutomation}
                effortAutomation={selectedTemplateInfo.effortAutomation}
              />
            ) : (
              <MaterialAlert
                message={
                  optionsTemplates.length
                    ? "Selecione o template"
                    : "Nenhum template cadastrado, acesse a adminitração para cadastrar"
                }
                severity="info"
              />
            )}
          </div>
        </FormDialog>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { workspacesData, access, user, selectedInsight } = state;

  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);

  return {
    companyMembersAndParticipants,
    workspaceId: workspacesData?.selectedWorkspace?.id,
    domainId: access?.currentDomain?.id,
    user,
    insightId: selectedInsight?.selectedInsight?.id,
  };
};

ActivitiesTableContainer.propTypes = {
  saveButton: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  activities: PropTypes.array.isRequired,
  companyMembersAndParticipants: PropTypes.array.isRequired,
  saveButtonJSX: PropTypes.func,
  postChecklist: PropTypes.func,
  onConfirmEdit: PropTypes.func,
  onConfirmSave: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onChangeStatus: PropTypes.func,
  saveTemplateActivities: PropTypes.func,
  workspaceId: PropTypes.number.isRequired,
  domainId: PropTypes.number.isRequired,
  insightId: PropTypes.number.isRequired,
};

ActivitiesTableContainer.defaultProps = {
  saveButton: false,
  saveButtonJSX: () => {},
  onConfirmEdit: () => {},
  postChecklist: () => {},
  onConfirmSave: () => {},
  onConfirmDelete: () => {},
  onChangeStatus: () => {},
  saveTemplateActivities: () => {},
};

function areEqual(prevProps, nextProps) {
  if (!_.isEqual(prevProps.weightAutomation, nextProps.weightAutomation))
    return false;
  if (!_.isEqual(prevProps.effortAutomation, nextProps.effortAutomation))
    return false;
  if (!_.isEqual(prevProps.activitiesType, nextProps.activitiesType))
    return false;
  if (!_.isEqual(prevProps.activities, nextProps.activities)) return false;
  if (!_.isEqual(prevProps.saveButton, nextProps.saveButton)) return false;
  if (!_.isEqual(prevProps.saveButtonJSX, nextProps.saveButtonJSX))
    return false;
  return true;
}

export default connect(mapStateToProps)(
  injectIntl(React.memo(ActivitiesTableContainer, areEqual)),
);
