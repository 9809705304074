import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import NewParticipantDialog from "./NewParticipantDialog";
import FormDialog from "../../FormDialog";
import MaterialSingleSelect from "../../MaterialSingleSelect";
import { getParticipantPeriodOptions } from "../../../../constants/goalsAndBonus";
import utils from "../../../../utils/toolUtils";
import { getSelectedToolStates } from "../../../customMapStates";
import { getGoalBonusConfiguration } from "../../../../actions/goalsAndBonusActions";
import { buildGoalsAdministrationFinalBody } from "../../../../utils/goalsBonus/entity";
import { getValidFunc } from "../../../../utils/validations";

const { getErrorMessage } = utils;

const useStyles = makeStyles(() =>
  createStyles({
    addButton: {
      fontSize: "12px",
    },
  }),
);

const messages = defineMessages({
  gb_add_new_question: { id: "gb_add_new_question" },
  btn_modal_register_new: { id: "btn_modal_register_new" },
  gb_error_participant_already_added: {
    id: "gb_error_participant_already_added",
  },
  gb_form_select_participant: { id: "gb_form_select_participant" },
  gb_table_text_no_participant: { id: "gb_table_text_no_participant" },
});

const initialForm = {
  participantId: "",
};

const QuestionParticipantSelectDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const [newParticipantModal, setNewParticipantModal] = useState(false);
  const {
    open = false,
    afterCancel,
    afterConfirm,
    goalBonusAdministration,
    setGoalBonusAdministration,
    companyMembersAndParticipants,
    goalsBonusProgramRoleBonusId,
    addedParticipants = [],
    selectedToolID,
    intl,
  } = props;

  const alternativeSaveParticipantCallback = getValidFunc(
    props.alternativeSaveParticipantCallback,
  );

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  const getToolConfig = async () => {
    if (selectedToolID) {
      const { data } = await getGoalBonusConfiguration(selectedToolID);

      if (data && data.id) {
        const fetchedConfiguration = await buildGoalsAdministrationFinalBody(
          data,
        );

        return fetchedConfiguration;
      }
    }
  };

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleConfirm = () => {
    if (typeof afterConfirm === "function") afterConfirm(form);
  };

  const handleCancel = () => {
    if (typeof afterCancel === "function") afterCancel();
  };

  const handleUpdateForm = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const getFormErrors = () => {
    let errors = [];

    if (
      (!form.participantId || form.participantId === "empty") &&
      form.participantId !== 0
    ) {
      errors.push({
        slug: "participantNotSelected",
      });
    }

    if (addedParticipants.indexOf(form.participantId) > -1) {
      errors.push({
        slug: "duplicatedParticipant",
        message: intl.formatMessage(
          messages.gb_error_participant_already_added,
        ),
      });
    }

    return errors;
  };

  const buildParticipantOptions = (participants, filterRoleBonusId = null) => {
    let finalOptions = [];

    if (participants?.length > 0) {
      participants.forEach((participantInfo) => {
        if (participantInfo?.id) {
          const { memberParticipant = {}, roleBonus = {} } =
            participantInfo || {};

          if (
            memberParticipant?.name &&
            (!filterRoleBonusId || roleBonus?.id === filterRoleBonusId)
          ) {
            finalOptions.push({
              value: participantInfo.id,
              label: memberParticipant.name,
            });
          }
        }
      });
    }

    return finalOptions;
  };

  const alternativeSave = (submitGoalsBonusAdm) => {
    alternativeSaveParticipantCallback(submitGoalsBonusAdm);
    toggleNewParticipantModal(false);
  };

  const toggleNewParticipantModal = (toggleTo = true) => {
    setNewParticipantModal(toggleTo);
  };

  const participantOptions = buildParticipantOptions(
    goalBonusAdministration.participants,
    goalsBonusProgramRoleBonusId,
  );
  const participantPeriodOptions = getParticipantPeriodOptions();

  const classes = useStyles();
  const formErrors = getFormErrors();

  return (
    <>
      <NewParticipantDialog
        open={newParticipantModal}
        goalBonusAdministration={goalBonusAdministration}
        setGoalBonusAdministration={setGoalBonusAdministration}
        afterConfirm={(submitGoalsBonusAdm) =>
          alternativeSave(submitGoalsBonusAdm)
        }
        afterCancel={() => toggleNewParticipantModal(false)}
        participantPeriodOptions={participantPeriodOptions}
        companyMembersAndParticipants={companyMembersAndParticipants}
        goalsBonusProgramRoleBonusId={goalsBonusProgramRoleBonusId}
        getToolConfig={getToolConfig}
      />
      <FormDialog
        open={open}
        title={intl.formatMessage(messages.gb_add_new_question)}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        blockConfirm={formErrors.length > 0}
      >
        <div className="row">
          <div className="col-xs-12">
            {participantOptions.length > 0 && (
              <MaterialSingleSelect
                id="participantSelect"
                label={intl.formatMessage(messages.gb_form_select_participant)}
                value={form.participantId}
                options={participantOptions}
                onChange={(e) =>
                  handleUpdateForm("participantId", e.target.value)
                }
              />
            )}
            {participantOptions.length === 0 && (
              <p>{intl.formatMessage(messages.gb_table_text_no_participant)}</p>
            )}
            {getErrorMessage(["duplicatedParticipant"], formErrors, "left", {
              height: "22px",
            })}
            <Button
              variant="outlined"
              className={classes.addButton}
              onClick={() =>
                toggleNewParticipantModal(!newParticipantModal.open)
              }
            >
              <AddIcon /> {intl.formatMessage(messages.btn_modal_register_new)}
            </Button>
          </div>
        </div>
      </FormDialog>
    </>
  );
};

export default injectIntl(
  connect((state) => {
    const { selectedToolID } = getSelectedToolStates(state);
    return { selectedToolID };
  })(QuestionParticipantSelectDialog),
);
