import {
  UPDATE_QUESTION_ANSWER,
  DELETE_QUESTION_ANSWER,
  UPDATE_THEME_QUESTION,
  DELETE_THEME_QUESTION,
  UPDATE_CENARY_THEME,
  DELETE_THEME,
  UPDATE_CENARY,
  DELETE_CENARIE,
} from "../../../../types";

import {
  checkScenerySelectedTool,
  checkThemeSelectedTool,
  checkQuestionSelectedTool,
  checkInsightSelectedTool,
} from "./_validations";

import { appRefreshSelectedTool } from "../../../../appActions";
import { checkSameUser } from "../../../utils/entity_validations";

const DEBUG = false;
function debug(arg1, ...optional) {
  if (DEBUG) console.log("WebSockets: ", arg1, ...optional);
}

/* CENARIOS */
export const updateScenary = (cenary, userId) => {
  if (cenary && cenary.id) {
    if (!checkScenerySelectedTool(cenary) || checkSameUser(userId)) return;
    /* ADICIONAR VALIDAÇÃO ADICIONAL */
    /* O TRIGGER DE SELEÇÃO DO CENÁRIO DEVE ACONTECER APENAS PARA O OWNER QUE CRIOU */
    return (dispatch) => {
      debug("UPDATE_CENARY", { cenary });
      dispatch({
        type: UPDATE_CENARY,
        payload: { ...cenary, type: "cenary" },
      });
    };
  }
};

export const postScenery = (data) => {
  const { content: cenary, details = {} } = data;
  const { userId = null } = details;

  return updateScenary(cenary, userId);
};

export const putScenery = (cenary) => {
  return updateScenary(cenary, null);
};

export const archiveUnarchiveCenary = (cenary) => putScenery(cenary);

export const deleteCenary = (cenaryId) => {
  if (cenaryId) {
    return (dispatch) => {
      debug("DELETE_CENARIE", { cenaryId });
      dispatch({
        type: DELETE_CENARIE,
        payload: { cenaryId },
      });
    };
  }
};

/* TEMAS */
export const updateTheme = (theme, userId) => {
  if (theme && theme?.id && theme?.cenary) {
    if (!checkThemeSelectedTool(theme) || checkSameUser(userId)) return;

    return async (dispatch) => {
      debug("UPDATE_CENARY_THEME", { theme });
      dispatch({
        type: UPDATE_CENARY_THEME,
        payload: {
          cenaryId: theme.cenary.id,
          theme: {
            ...theme,
            type: "theme",
            isNew: true,
          },
        },
      });
    };
  }
};

export const postTheme = (data) => {
  const { content: theme, details = {} } = data;
  const { userId = null } = details;

  return updateTheme(theme, userId);
};

export const putTheme = (theme) => {
  return updateTheme(theme, null);
};

export const archiveUnarchiveTheme = (theme) => updateTheme(theme);

export const deleteTheme = (deleteInfo) => {
  const { content: theme } = deleteInfo;

  if (theme && theme.cenary) {
    return (dispatch) => {
      debug("DELETE_THEME", { theme });
      dispatch({
        type: DELETE_THEME,
        payload: { theme, cenaryId: theme.cenary.id },
      });
    };
  }
};

/* QUESTÕES */
export const updateQuestion = (question = {}, userId) => {
  if (question && question.id && question.theme && question.theme.id) {
    if (!checkQuestionSelectedTool(question) || checkSameUser(userId)) return;

    return async (dispatch) => {
      debug("UPDATE_THEME_QUESTION", { question });
      dispatch({
        type: UPDATE_THEME_QUESTION,
        payload: {
          question: {
            ...question,
            type: "question",
            isNew: true,
          },
          themeId: question.theme.id,
        },
      });
    };
  }
};

export const postQuestion = (data) => {
  const { content: question, details = {} } = data;
  const { userId = null } = details;

  return updateQuestion(question, userId);
};

export const putQuestion = (question) => {
  return updateQuestion(question, null);
};

export const archiveUnarchiveQuestion = (question) => updateQuestion(question);

export const deleteQuestion = (deleteInfo = {}) => {
  const { content: question } = deleteInfo;

  if (question && question.id && question.theme.id) {
    return (dispatch) => {
      debug("DELETE_THEME_QUESTION", { question });

      dispatch({
        type: DELETE_THEME_QUESTION,
        payload: {
          themeId: question.theme.id,
          question,
        },
      });
    };
  }
};

export const updateInsight = (insight, userId, isNew = true) => {
  if (insight && insight.id && insight.question.id) {
    if (!checkInsightSelectedTool(insight) || checkSameUser(userId)) return;

    return (dispatch) => {
      debug("UPDATE_QUESTION_ANSWER", { insight });

      dispatch({
        type: UPDATE_QUESTION_ANSWER,
        payload: {
          questionId: insight.question.id,
          answer: {
            ...insight,
            type: "insight",
            questionId: insight.question.id,
            isNew,
          },
        },
      });
    };
  }
};

export const postInsight = (data) => {
  const { content: insight, details = {} } = data;
  const { userId = null } = details;

  return updateInsight(insight, userId);
};

export const putInsight = (insight) => {
  return updateInsight(insight, null);
};

export const archiveUnarchiveManyInsights = (archivedInsights) => {
  if (Array.isArray(archivedInsights)) {
    if (archivedInsights[0] && !checkInsightSelectedTool(archivedInsights[0]))
      return;

    return (dispatch) => {
      debug("ARCHIVE_INSIGHTS", { archivedInsights });
      dispatch(appRefreshSelectedTool(false));
    };
  }
};

export const deleteInsight = (deleteInfo) => {
  const { content: insight = {} } = deleteInfo;

  if (insight && insight.id) {
    return (dispatch) => {
      debug("DELETE_QUESTION_ANSWER", { insight });
      dispatch({ type: DELETE_QUESTION_ANSWER, payload: { answer: insight } });
    };
  }
};
