const pt = {
  select_image_area: "Selecione a área da imagem",
};

const en = {
  select_image_area: "Select image area",
};

const es = {
  select_image_area: "Seleccionar área de imagen",
};

const translations = { pt, en, es };

export default translations;
