import React from "react";
import _ from "lodash";
import {
  orderArrayByObjAttr,
  getObjectInfoById,
  getUpdatedObjectByArrayIndex,
} from "./ArrayUtils";
import { getRelativePercentage } from "./MathUtils";

export const buildTransformPrioritization = (
  projectPrioritizationConfig,
  defaultString = "",
  defaultArray = [],
  defaultIdValue = null,
  defaultIndexValue = 0,
  transformTo = "form",
) => {
  const prioritizationAdmin = projectPrioritizationConfig
    ? _.cloneDeep(projectPrioritizationConfig)
    : {};

  let {
    id,
    selectedToolId = defaultIdValue,
    criteriaConfigurations = defaultArray,
    criteriaGroups = defaultArray,
    projectGroups = defaultArray,
  } = prioritizationAdmin;

  id = id === "" || id === null || !id ? defaultIdValue : id;

  const formatSingleCriteriaConfig = (criteria_config) => {
    criteria_config.id = criteria_config.id || defaultIdValue;
    criteria_config.index = criteria_config.index || defaultIndexValue;
    criteria_config.title = criteria_config.title || defaultString;
    criteria_config.description = criteria_config.description || defaultString;
    criteria_config.active = criteria_config.active === false ? false : true;

    return criteria_config;
  };

  const formatCriteriaConfigurations = (criteria_configurations) => {
    for (let key in criteria_configurations) {
      criteria_configurations[key] = formatSingleCriteriaConfig(
        criteria_configurations[key],
      );
    }

    return criteria_configurations;
  };

  const formatLabels = (criteria_labels, transformTo = "form") => {
    const labelsNumber = 6;
    criteria_labels = criteria_labels || [];

    if (transformTo === "form") {
      if (criteria_labels.length === 0) {
        for (let index = 0; index < labelsNumber; index++) {
          criteria_labels.push("");
        }
      } else {
        let orderedLabels = orderArrayByObjAttr(
          criteria_labels,
          "index",
          false,
          true,
        );
        let newLabels = [];

        if (orderedLabels) {
          orderedLabels.forEach((labelInfo) => {
            let appendLabel = "";

            if (labelInfo && labelInfo.label) {
              appendLabel = labelInfo.label || defaultString;
            }

            newLabels.push(appendLabel);
          });
        }

        criteria_labels = newLabels;
      }
    } else {
      let newLabels = [];

      if (criteria_labels.length === 0) {
        for (let index = 0; index < labelsNumber; index++) {
          criteria_labels.push({ index, label: "" });
        }
      } else {
        criteria_labels.forEach((label, index) => {
          label = label || defaultString;
          index = !index && index !== 0 ? defaultIndexValue : index;

          newLabels.push({ label, index });
        });

        criteria_labels = newLabels;
      }
    }

    return criteria_labels;
  };

  const formatCriteriaGroups = (criteria_groups, transformTo = "form") => {
    for (let key in criteria_groups) {
      criteria_groups[key].id = criteria_groups[key].id || defaultIdValue;
      criteria_groups[key].index =
        criteria_groups[key].index || defaultIndexValue;
      criteria_groups[key].title = criteria_groups[key].title || defaultString;
      criteria_groups[key].description =
        criteria_groups[key].description || defaultString;

      let criterias = criteria_groups[key].criterias || [];

      for (let secondKey in criterias) {
        criterias[secondKey].id = criterias[secondKey].id || defaultIdValue;
        criterias[secondKey].labels = formatLabels(
          criterias[secondKey].labels,
          transformTo,
        );
        criterias[secondKey].criteria = formatSingleCriteriaConfig(
          criterias[secondKey].criteria,
        );
      }

      criteria_groups[key].criterias = criterias;
    }

    return criteria_groups;
  };

  const formatProjectTypes = (project_types) => {
    for (let key in project_types) {
      project_types[key].id = project_types[key].id || defaultIdValue;
      project_types[key].title = project_types[key].title || defaultString;
      project_types[key].description =
        project_types[key].description || defaultString;

      const singleCriteria = project_types[key].criteriaGroup || {};
      const defaultCriteriasBody = formatCriteriaGroups(
        [singleCriteria],
        transformTo,
      );

      project_types[key].criteriaGroup = defaultCriteriasBody[0];
    }

    return project_types;
  };

  criteriaConfigurations = formatCriteriaConfigurations(criteriaConfigurations);
  criteriaGroups = formatCriteriaGroups(criteriaGroups, transformTo);

  for (const key in projectGroups) {
    projectGroups[key].id = projectGroups[key].id || defaultIdValue;
    projectGroups[key].title = projectGroups[key].title || defaultString;
    const projectTypes = projectGroups[key].projectTypes || [];

    projectGroups[key].projectTypes = formatProjectTypes(projectTypes);
  }

  return {
    id,
    selectedToolId,
    criteriaConfigurations,
    criteriaGroups,
    projectGroups,
  };
};

export const buildFormPrioritizationBody = (projectPrioritizationConfig) => {
  return buildTransformPrioritization(projectPrioritizationConfig, "", []);
};

export const buildRestPrioritizationBody = (projectPrioritizationConfig) => {
  return buildTransformPrioritization(
    projectPrioritizationConfig,
    null,
    [],
    null,
    1,
    "rest",
  );
};

export const getPatternErrors = (form, field) => {
  let errors = [];

  const addError = (slug, message) => {
    errors.push({ slug, message });
  };

  if (form[field].length < 1)
    addError("invalidTitle", "O campo titulo não pode estar vazio!");

  return errors;
};

export const transformToolPrioritizationToCompare = (administrationObj) => {
  administrationObj = buildFormPrioritizationBody({
    ...administrationObj,
  });

  let { criteriaGroups = [], projectGroups = [] } = administrationObj;
  criteriaGroups = orderArrayByObjAttr(criteriaGroups, "title");

  for (const key in criteriaGroups) {
    const { criterias } = criteriaGroups[key];

    criteriaGroups[key].criteriaIds = [];
    criteriaGroups[key].allLabels = [];

    criterias.forEach(({ id = null, criteria = {} }) => {
      const { labels = [] } = criteria;

      if (id) criteriaGroups[key].criteriaIds.push(id);

      labels.forEach((text) => {
        criteriaGroups[key].allLabels.push(text);
      });
    });

    delete criteriaGroups[key].criterias;
  }

  for (const key in projectGroups) {
    const { projectTypes } = projectGroups[key];

    for (const index in projectTypes) {
      if (projectTypes[index].criteriaGroup.criterias)
        delete projectTypes[index].criteriaGroup.criterias;
    }

    projectGroups[key].projectTypes = projectTypes;
  }

  return { ...administrationObj, criteriaGroups, projectGroups };
};

export const renderProjectPrioritizationLabel = (intl, globalMessages) => {
  return (
    <>
      <th style={{ textAlign: "left" }}>Área</th>
      <th style={{ textAlign: "left" }}>Grupo</th>
      <th style={{ textAlign: "left" }}>Tipo</th>
      <th style={{ textAlign: "left" }}>Projeto</th>
    </>
  );
};

export const buildPrioritizationBody = (values) => {
  let maturityBody = {
    capex: "",
    vol: "",
    gm: "",
    sgt: "",
    nr: "",
  };

  if (values) {
    Object.keys(values).forEach((valueKey) => {
      Object.keys(maturityBody).forEach((bodyKey) => {
        if (valueKey === bodyKey) {
          maturityBody[bodyKey] = values[valueKey];
        }
      });
    });
  }

  return maturityBody;
};

export const getSumEvaluation = (prioritizationInfo, filterOnly = null) => {
  let sum = 0;

  if (prioritizationInfo && Object.keys(prioritizationInfo).length > 0) {
    Object.keys(prioritizationInfo).forEach((key) => {
      const criteriaEvaluation = prioritizationInfo[key];

      if (!filterOnly || key === filterOnly) {
        sum = parseFloat(sum) + parseFloat(criteriaEvaluation);
      }
    });
  }

  return sum;
};

export const getPrioritizationSum = (maturityLIST) => {
  let listSum = 0;
  if (maturityLIST.length > 0) {
    maturityLIST.forEach((prioritizationInfo) => {
      const dataAverage = getSumEvaluation(prioritizationInfo);

      if (dataAverage > 0) {
        listSum = listSum + dataAverage;
      }
    });
  }

  return listSum;
};

export const getPrioritizationLabel = (evaluation) => {
  let evaluationLabel = "NOT_APPLICABLE";
  evaluation = parseFloat(evaluation);

  if (evaluation > 0) evaluationLabel = "VERY_LOW";
  if (evaluation > 1.5) evaluationLabel = "LOW";
  if (evaluation > 2.5) evaluationLabel = "PARTIAL";
  if (evaluation > 3.5) evaluationLabel = "HIGH";
  if (evaluation > 4.5) evaluationLabel = "VERY_HIGH";

  return evaluationLabel;
};

export const getSinglePrioritization = (
  prioritizations = [],
  compareInsightId,
) => {
  let prioritization = null;

  prioritizations.forEach((info) => {
    if (info.insightId === compareInsightId) prioritization = info;
  });

  return prioritization;
};

export const getProjectPrioritizationById = (
  insightId,
  allPrioritizationData = [],
) => {
  let projectPrioritization = {};

  for (const index in allPrioritizationData) {
    const prioritizationInfo = allPrioritizationData[index];
    const { prioritizations } = prioritizationInfo;

    const prioritizationFound = getSinglePrioritization(
      prioritizations,
      insightId,
    );

    if (prioritizationFound) {
      projectPrioritization = prioritizationFound;
      break;
    }
  }

  return projectPrioritization;
};

export const getPathwayBySum = (totalSum) => {
  if (totalSum <= 9) return "LITE";
  if (totalSum >= 10 && totalSum <= 15) return "STANDARD";
  if (totalSum >= 16 && totalSum <= 20) return "AGILE";
  if (totalSum > 20) return "PRIORITY";

  return totalSum;
};

export const getToolProjectAdministration = (
  selectedToolId,
  allProjectPrioritization,
) => {
  let toolProjectAdministration = {};

  if (selectedToolId && allProjectPrioritization) {
    toolProjectAdministration = getObjectInfoById(
      selectedToolId,
      allProjectPrioritization,
      "selectedToolId",
    );
  }

  return toolProjectAdministration;
};

export const getOnlyProjectTypes = (projectGroups) => {
  let onlyProjectTypes = [];

  if (projectGroups) {
    projectGroups.forEach(({ projectTypes }) => {
      if (projectTypes && projectTypes.length > 0) {
        projectTypes.forEach((projectType) => {
          onlyProjectTypes.push(projectType);
        });
      }
    });
  }

  return onlyProjectTypes;
};

export const getProjectTypeByConfigurationId = (
  projectPriorizationConfigurationTypeId,
  onlyProjectTypes,
) => {
  let projectType = {};

  if (projectPriorizationConfigurationTypeId && onlyProjectTypes) {
    projectType =
      getObjectInfoById(
        projectPriorizationConfigurationTypeId,
        onlyProjectTypes,
        "id",
      ) || {};
  }

  return projectType;
};

export const getInsightProjectPrioritization = (
  insightId,
  allInsightPrioritizations,
  multiplier = 5,
) => {
  let projectPriorizationEvaluation = {};

  if (insightId && allInsightPrioritizations) {
    projectPriorizationEvaluation = getObjectInfoById(
      insightId,
      allInsightPrioritizations,
      "insightId",
    );

    projectPriorizationEvaluation =
      projectPriorizationEvaluation.projectPriorizationEvaluation || {};
  }

  const maxCriteriaSum = 5 * multiplier;

  const sumEvaluation = getSumEvaluation(projectPriorizationEvaluation);
  const sumPercentage = getRelativePercentage(sumEvaluation, maxCriteriaSum);
  const pathway = getPathwayBySum(sumEvaluation, maxCriteriaSum);

  return {
    projectPriorizationEvaluation,
    maxCriteriaSum,
    sumPercentage,
    sumEvaluation,
    pathway,
  };
};

export const getOnlyCriterias = (criterias, ordered = true) => {
  let onlyCriterias = [];

  if (criterias && criterias.length > 0) {
    onlyCriterias = [
      ...criterias.map((criteriaInfo) => {
        return {
          ...criteriaInfo,
          title: criteriaInfo.criteria.title,
        };
      }),
    ];
  }

  if (ordered) onlyCriterias = orderArrayByObjAttr(onlyCriterias, "title");

  return onlyCriterias;
};

export const getAllInsightEvaluationInfo = (
  insight,
  selectedToolId,
  allProjectPrioritization,
  allInsightPrioritizations,
) => {
  const insightId = insight.id ? insight.id : null;
  const insightQuestion = insight && insight.question ? insight.question : {};
  const { projectPriorizationConfigurationTypeId } = insightQuestion;

  const toolProjectAdministration = getToolProjectAdministration(
    selectedToolId,
    allProjectPrioritization,
  );

  let onlyProjectTypes = getOnlyProjectTypes(
    toolProjectAdministration.projectGroups,
  );

  const projectType = getProjectTypeByConfigurationId(
    projectPriorizationConfigurationTypeId,
    onlyProjectTypes,
  );
  const projectTypeGroup = projectType.criteriaGroup || {};
  const criterias = projectTypeGroup.criterias || [];

  const onlyCriterias = getOnlyCriterias(criterias);

  const {
    projectPriorizationEvaluation,
    maxCriteriaSum,
    sumEvaluation,
    sumPercentage,
    pathway,
  } = getInsightProjectPrioritization(
    insightId,
    allInsightPrioritizations,
    onlyCriterias.length,
  );

  return {
    projectPriorizationConfigurationTypeId,
    projectPriorizationEvaluation,
    criterias,
    onlyCriterias,
    projectType,
    projectTypeGroup,
    maxCriteriaSum,
    sumEvaluation,
    sumPercentage,
    pathway,
  };
};

export const updatePriorizationByGroup = (
  projectPriorization,
  groupToUpdate,
) => {
  let updatedProjectPriorization = { ...projectPriorization };
  const updatedGroup = groupToUpdate ? groupToUpdate : null;

  const criteriaGroups =
    updatedProjectPriorization && updatedProjectPriorization.criteriaGroups
      ? updatedProjectPriorization.criteriaGroups
      : null;

  const groupIndex =
    updatedGroup && updatedGroup.id
      ? getObjectInfoById(updatedGroup.id, criteriaGroups, "id", true)
      : null;

  const newCriteriaGroups =
    criteriaGroups && groupIndex
      ? getUpdatedObjectByArrayIndex(criteriaGroups, updatedGroup, groupIndex)
      : null;

  if (criteriaGroups) {
    updatedProjectPriorization.criteriaGroups = newCriteriaGroups;
  }

  return updatedGroup && criteriaGroups
    ? updatedProjectPriorization
    : projectPriorization;
};

export const getReoderedGroupsAndCriterias = (criteriaGroups = []) => {
  let reorderedGroups = [];

  for (let groupIndex in criteriaGroups) {
    let criteriaGroupInfo = {
      ...criteriaGroups[groupIndex],
    };
    criteriaGroupInfo.realIndex = groupIndex;

    let reorderedCriterias = [
      ...criteriaGroupInfo.criterias.map(({ criteria, labels }, index) => {
        return {
          criteria,
          labels,
          title: criteria.title,
          realIndex: index,
        };
      }),
    ];
    criteriaGroupInfo.criterias = orderArrayByObjAttr(
      reorderedCriterias,
      "title",
    );

    reorderedGroups.push(criteriaGroupInfo);
  }

  return orderArrayByObjAttr(reorderedGroups, "title");
};
