/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import _ from "lodash";
import { buildSelectAreasTree } from "./filter";

export function useCompanyAreasTree({
  selectedCompany = {},
  allAreaStructures = {},
  areasFilter = null,
}) {
  const [companyAreasTree, setCompanyAreasTree] = useState({});
  const selectedCompanyId = selectedCompany.id ? selectedCompany.id : null;

  useEffect(() => {
    if (selectedCompanyId) {
      const restCompanyAreas = allAreaStructures[selectedCompanyId] || [];
      const newAreasTree = buildSelectAreasTree(restCompanyAreas, areasFilter);

      if (!_.isEqual(companyAreasTree, newAreasTree))
        setCompanyAreasTree(newAreasTree);
    }
  }, [allAreaStructures, selectedCompanyId]);

  return { companyAreasTree };
}
