import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  saveLinkBoardTheme,
  createKpiThemeGroup,
} from "../../../../actions/kpiActions";
import ThemeGroupSelectDialog from "../../../Common/Kpi/forms/ThemeGroupSelectDialog";
import { getSelectedToolStates, getKpiStates } from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";
import kpiUtils from "../../../../utils/kpis";

const { getOnlySublevelArrays } = utils;

const {
  getKpiConfigBySelectedToolId,
  buildFormAdmConfigBody,
  buildRestAdmConfigBody,
  getGroupInfoById,
} = kpiUtils;

const defaultKpiConfig = buildFormAdmConfigBody({});

const Kpi = (props) => {
  const [kpiAdministration, setKpiAdministration] = useState(defaultKpiConfig);
  const [addedGroups, setAddedGroups] = useState([]);

  const {
    type,
    clearViewCallback,
    selectedTool = {},
    isLoading = false,
    allKpiAdministration = [],
    saveLinkBoardTheme,
    createKpiThemeGroup,
    params,
    allThemes,
  } = props;

  const onlyThemes = getOnlySublevelArrays(allThemes);

  useEffect(() => {
    const newAdded = [];

    onlyThemes.forEach(({ kpiConfigurationGroupId }) => {
      if (kpiConfigurationGroupId) newAdded.push(kpiConfigurationGroupId);
    });

    if (!_.isEqual(newAdded, addedGroups)) setAddedGroups(newAdded);
  }, [addedGroups, onlyThemes]);

  useEffect(() => {
    let toolKpiConfig = {};

    if (allKpiAdministration) {
      toolKpiConfig = getKpiConfigBySelectedToolId(
        selectedTool.id,
        allKpiAdministration,
      );

      toolKpiConfig = buildFormAdmConfigBody(toolKpiConfig);
    }

    if (!isLoading && !_.isEqual(toolKpiConfig, kpiAdministration)) {
      setKpiAdministration(toolKpiConfig);
    }
  }, [allKpiAdministration, kpiAdministration, isLoading, selectedTool.id]);

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
  };

  const handleUpdateKpiConfig = (newKpiConfig) => {
    if (!_.isEqual(kpiAdministration, newKpiConfig)) {
      const restBody = buildRestAdmConfigBody(newKpiConfig);

      saveLinkBoardTheme(
        selectedTool,
        params.themeScenerie,
        restBody,
        params.order,
      );
    }

    clearDialogControll();
  };

  const handleSaveTheme = ({ groups }) => {
    if (selectedTool.id && Array.isArray(groups) && params.themeScenerie) {
      groups.forEach((groupId) => {
        const groupInfo = getGroupInfoById(groupId, kpiAdministration.groups);

        createKpiThemeGroup(groupInfo, params.themeScenerie, params.order);
      });
    }

    clearDialogControll();
  };

  return (
    <ThemeGroupSelectDialog
      open
      afterConfirm={handleSaveTheme}
      afterCancel={clearDialogControll}
      kpiAdministration={kpiAdministration}
      setKpiAdministration={setKpiAdministration}
      alternativeSaveCallback={handleUpdateKpiConfig}
      addedGroups={addedGroups}
    />
  );
};

const mapStateToProps = (state) => {
  const { selectedTool, allThemes } = getSelectedToolStates(state);
  const { allKpiAdministration, isLoading } = getKpiStates(state);

  const { user } = state;

  return {
    userProfile: user,
    selectedTool,
    allKpiAdministration,
    allThemes,
    isLoading,
  };
};

export default connect(mapStateToProps, {
  saveLinkBoardTheme,
  createKpiThemeGroup,
})(Kpi);
