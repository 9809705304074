import {
  checkPostAdministrationSelectedTool,
  toolUpdateNotifier,
} from "./_validations";

import {
  checkThemeSelectedTool,
  checkQuestionSelectedTool,
  checkInsightSelectedTool,
} from "../board/_validations";

import { postTheme, postQuestion, postInsight } from "../board/_actions";

import {
  getFromDetails,
  checkToolIdInState,
  checkSameUser,
} from "../../../utils/entity_validations";

import { buildFormSkillsAssessment } from "../../../../../utils/skillsAssessment/entity";

const DEBUG = false;
function debug(arg1, ...optional) {
  if (DEBUG) console.log("WebSockets: ", arg1, ...optional);
}

export const putToolConfiguration = (newAdministrationConfig = {}) => {
  const { content: administrationData, details = {} } = newAdministrationConfig;
  const selectedToolId = getFromDetails(details, "selectedToolId", true);

  if (checkSameUser(details.userId)) return;

  debug("putToolConfiguration", {
    administrationData,
    details,
    selectedToolId,
  });

  if (
    administrationData &&
    selectedToolId &&
    checkPostAdministrationSelectedTool(selectedToolId)
  ) {
    return async (dispatch, getState) => {
      const payload = { ...administrationData, selectedToolId };
      const updatedConfiguration = await buildFormSkillsAssessment(
        payload,
        "FORM",
      );

      debug("UPDATE_SKILL_ASSESSMENT", updatedConfiguration);

      if (checkToolIdInState({ id: selectedToolId }))
        dispatch(toolUpdateNotifier());

      const storeState = getState();

      dispatch({
        type: "UPDATE_SKILL_ASSESSMENT",
        payload: updatedConfiguration,
        loading: false,
        storeState,
        lastFetch: Date.now(),
      });
    };
  }
};

export const postBoardThemeLink = (themeLinkData) => {
  const { content: updatedTheme = {}, details } = themeLinkData;

  if (updatedTheme && updatedTheme.id) {
    return (dispatch) => {
      if (checkThemeSelectedTool(updatedTheme)) dispatch(toolUpdateNotifier());

      return dispatch(postTheme(updatedTheme, details.userId));
    };
  }

  return null;
};

export const postBoardQuestionLink = (questionLinkData) => {
  const { content: updatedQuestion = {}, details } = questionLinkData;

  if (updatedQuestion && updatedQuestion.id) {
    return (dispatch) => {
      if (checkQuestionSelectedTool(updatedQuestion))
        dispatch(toolUpdateNotifier());

      return dispatch(postQuestion(updatedQuestion, details.userId));
    };
  }

  return null;
};

export const postBoardInsightLink = (insightLinkData) => {
  const { content: updatedInsight = {}, details = {} } = insightLinkData;
  const skillsAssessmentParticipantId = getFromDetails(
    details,
    "skillsAssessmentParticipantId",
    true,
  );

  if (updatedInsight && updatedInsight.id && skillsAssessmentParticipantId) {
    return (dispatch) => {
      if (checkInsightSelectedTool(updatedInsight))
        dispatch(toolUpdateNotifier());

      return dispatch(
        postInsight(
          { ...updatedInsight, skillsAssessmentParticipantId },
          details.userId,
        ),
      );
    };
  }

  return null;
};
