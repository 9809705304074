import React from "react";
import ContentLoader from "react-content-loader";

const AppLoader = (props) => {
  return (
    <ContentLoader
      animate={false}
      title=""
      gradientRatio={0}
      backgroundColor="#333"
      foregroundColor="#333"
      preserveAspectRatio="xMidYMid meet"
      aria-hidden="true"
      viewBox="0 0 900 435"
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="25" />
      <rect x="0" y="0" rx="0" ry="0" width="115" height="100%" />
    </ContentLoader>
  );
};

export default AppLoader;
