import React from "react";

import SelectFilter from "../../Common/SelectFilter";

import { DisplayComments } from "../../../utils/commentUtils";

const ToolTimelineView = (props) => {
  const { selectList, captureSelectFilter, comments } = props;

  return (
    <div style={{ padding: "15px" }}>
      <div>
        <SelectFilter
          showResponsibleSelect={false}
          selectList={selectList}
          captureSelectsData={(values, fromRef) =>
            captureSelectFilter(values, fromRef)
          }
        />
      </div>
      <div>
        <DisplayComments
          timelineComments={comments}
          saveEditedComment={null}
          timelineDisplay
        />
      </div>
    </div>
  );
};

export default ToolTimelineView;
