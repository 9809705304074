import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  saveLinkBoardIndicator,
  createKpiInsightIndicator,
} from "../../../../actions/kpiActions";
import InsightIndicatorSelectDialog from "../../../Common/Kpi/forms/InsightIndicatorSelectDialog";
import { getSelectedToolStates, getKpiStates } from "../../../customMapStates";
import kpiUtils from "../../../../utils/kpis";
import { useSimpleToolAdministration } from "../../../../utils/kpis/hooks";

const { buildRestAdmConfigBody, getGoalIndicatorInfoById } = kpiUtils;

const Kpi = ({
  type,
  selectedTool,
  isLoading,
  allKpiAdministration,
  allAnswers,
  params,
  clearViewCallback,
  saveLinkBoardIndicator,
  createKpiInsightIndicator,
}) => {
  const { kpiAdministration, setAdministration } = useSimpleToolAdministration({
    selectedTool,
    allKpiAdministration,
    isLoading,
  });
  const [addedGoals, setAddedGoals] = useState([]);

  const { insightQuestion = {} } = params;
  const questionInsights = allAnswers[insightQuestion.id];

  useEffect(() => {
    const newAddedGoals = [];

    if (questionInsights && questionInsights.length > 0) {
      questionInsights.forEach(({ kpiConfigurationGoalId }) => {
        if (kpiConfigurationGoalId) newAddedGoals.push(kpiConfigurationGoalId);
      });
    }

    if (!_.isEqual(newAddedGoals, addedGoals)) setAddedGoals(newAddedGoals);
  }, [addedGoals, questionInsights]);

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
  };

  const handleUpdateKpiConfig = (newKpiConfig) => {
    if (!_.isEqual(kpiAdministration, newKpiConfig)) {
      const restBody = buildRestAdmConfigBody(newKpiConfig);

      saveLinkBoardIndicator(
        selectedTool,
        insightQuestion,
        restBody,
        params.order,
      );
    }

    clearDialogControll();
  };

  const handleSaveInsight = ({ goals }) => {
    if (insightQuestion && Array.isArray(goals)) {
      goals.forEach((goalId) => {
        const goalInfo = getGoalIndicatorInfoById(
          goalId,
          kpiAdministration.goals,
        );

        createKpiInsightIndicator(
          selectedTool.id,
          insightQuestion.id,
          goalInfo,
          params.order,
        );
      });
    }

    clearDialogControll();
  };

  return (
    !isLoading && (
      <InsightIndicatorSelectDialog
        open
        afterConfirm={handleSaveInsight}
        afterCancel={clearDialogControll}
        kpiAdministration={kpiAdministration}
        setKpiAdministration={setAdministration}
        alternativeSaveCallback={handleUpdateKpiConfig}
        addedGoals={addedGoals}
      />
    )
  );
};

const mapStateToProps = (state) => {
  const { selectedTool, allAnswers } = getSelectedToolStates(state);
  const { allKpiAdministration, isLoading } = getKpiStates(state);

  const { user } = state;

  return {
    userProfile: user,
    selectedTool,
    allKpiAdministration,
    allAnswers,
    isLoading,
  };
};

Kpi.propTypes = {
  type: PropTypes.string.isRequired,
  selectedTool: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  allKpiAdministration: PropTypes.array.isRequired,
  allAnswers: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  clearViewCallback: PropTypes.func.isRequired,
  saveLinkBoardIndicator: PropTypes.func.isRequired,
  createKpiInsightIndicator: PropTypes.func.isRequired,
};

Kpi.defaultProps = {
  isLoading: false,
};

export default connect(mapStateToProps, {
  saveLinkBoardIndicator,
  createKpiInsightIndicator,
})(Kpi);
