import React from "react";
import PropTypes from "prop-types";

import ObjectForm from "./forms/ObjectForm";
import KeyForm from "./forms/KeyForm";

const DisplayObjKeyForm = ({
  modalSlug,
  handleOpenModal,
  createOrUpdateObjective,
  getAllOptionsObjective,
  editingForm,
  objectives,
}) => {
  return (
    <>
      <ObjectForm
        open={modalSlug.indexOf("objectModal") > -1}
        onCancel={handleOpenModal}
        onConfirm={createOrUpdateObjective}
        isEditing={editingForm}
        objectives={objectives}
      />
      <KeyForm
        open={modalSlug.indexOf("keyModal") > -1}
        onCancel={handleOpenModal}
        isEditing={editingForm}
        objectives={objectives}
        getAllOptionsObjective={getAllOptionsObjective()}
      />
    </>
  );
};

export default DisplayObjKeyForm;

DisplayObjKeyForm.propTypes = {
  modalSlug: PropTypes.array.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  createOrUpdateObjective: PropTypes.func.isRequired,
  getAllOptionsObjective: PropTypes.func.isRequired,
  editingForm: PropTypes.object.isRequired,
  objectives: PropTypes.array.isRequired,
};
