import { defineMessages } from "react-intl";
import { getSingleIndicatorResults, filterGoalByPeriod } from "../kpis/calc2";
import { displayTransformValue } from "../kpis/display";
import { translatedText } from "../translationUtils";

const messages = defineMessages({
  kpi_options_mu_monetary_value: { id: "kpi_options_mu_monetary_value" },
  kpi_options_mu_thousand_monetary_value: {
    id: "kpi_options_mu_thousand_monetary_value",
  },
  kpi_options_mu_million_monetary_value: {
    id: "kpi_options_mu_million_monetary_value",
  },
  kpi_options_mu_quantity: { id: "kpi_options_mu_quantity" },
  kpi_options_mu_percentage: { id: "kpi_options_mu_percentage" },
  kpi_options_mu_index: { id: "kpi_options_mu_index" },
  gb_options_mu_kg: { id: "gb_options_mu_kg" },
  gb_options_mu_tonne: { id: "gb_options_mu_tonne" },
  gb_options_mu_km: { id: "gb_options_mu_km" },
  gb_options_mu_meters: { id: "gb_options_mu_meters" },
  gb_options_mu_cmeters: { id: "gb_options_mu_cmeters" },
  gb_options_mu_liters: { id: "gb_options_mu_liters" },
  gb_options_mu_other: { id: "gb_options_mu_other" },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

export const getUnityOptions = () => {
  return [
    {
      value: "MONETARY_VALUE",
      label: translation("kpi_options_mu_monetary_value"),
    },
    {
      value: "THOUSAND_MONETARY_VALUE",
      label: translation("kpi_options_mu_thousand_monetary_value"),
    },
    {
      value: "MILLION_MONETARY_VALUE",
      label: translation("kpi_options_mu_million_monetary_value"),
    },
    { value: "QUANTITY", label: translation("kpi_options_mu_quantity") },
    {
      value: "PERCENTAGE",
      label: translation("kpi_options_mu_percentage"),
    },
    { value: "INDEX", label: translation("kpi_options_mu_index") },
    { value: "KG", label: translation("gb_options_mu_kg") },
    { value: "TONNE", label: translation("gb_options_mu_tonne") },
    { value: "KM", label: translation("gb_options_mu_km") },
    { value: "METERS", label: translation("gb_options_mu_meters") },
    { value: "CMETERS", label: translation("gb_options_mu_cmeters") },
    { value: "LITERS", label: translation("gb_options_mu_liters") },
  ];
};

export const operationOptions = [
  { value: null, label: null, string: "" },
  { value: "SUM", label: "+ (soma)", string: "+" },
  { value: "DIVISION", label: "/ (divisão)", string: "/" },
  { value: "MULTIPLICATION", label: "* (multiplicação)", string: "*" },
  { value: "SUBSTRACTION", label: "- (subtração)", string: "-" },
];

export function executeOperation(value1, value2, operation) {
  switch (operation) {
    case "SUM":
      return value1 + value2;
    case "DIVISION":
      if (value2 === 0) return value1;
      return value1 / value2;
    case "MULTIPLICATION":
      return value1 * value2;
    case "SUBSTRACTION":
      return value1 - value2;
    default:
      return value1;
  }
}

export function getCalculatedIndicators(kpiAdministration, options) {
  const { filter = false, periodFilter = false } = options || {};
  return kpiAdministration.goals.map((goal) => {
    const fGoal = filter ? filterGoalByPeriod(goal, periodFilter) : goal;
    const result = getSingleIndicatorResults(
      fGoal,
      kpiAdministration.goalTargets,
      kpiAdministration.zeroWhenNegative,
    );
    const courseTargetsDivision = filter
      ? fGoal.target
      : result.courseTargetsDivision;

    const target = {
      actual: result.goalPeriodTotalEvaluation,
      gate: courseTargetsDivision.gate,
      appropriated: courseTargetsDivision.appropriated,
      exceeded: courseTargetsDivision.exceeded,
    };

    return { goal: fGoal, result, target };
  });
}

export function operationByTargetField({
  indicatorMetrics,
  calculatedIndicators,
  targetField,
}) {
  return indicatorMetrics
    .sort((a, b) => a.order - b.order)
    .reduce((acc, indicatorMetric, index) => {
      const calculatedIndicator = calculatedIndicators.find(
        (calculatedIndicator) =>
          calculatedIndicator.goal.id === indicatorMetric.goalId,
      );

      if (!calculatedIndicator?.target[targetField]) return acc;
      if (index === 0) return calculatedIndicator.target[targetField];

      const previousOperation = indicatorMetrics[index - 1].operation;
      return executeOperation(
        acc,
        calculatedIndicator.target[targetField],
        previousOperation,
      );
    }, 0);
}

export function getTargetIndicatorMetrics({
  indicatorMetrics,
  calculatedIndicators,
  unity,
  toFix = 2,
}) {
  const byActual = operationByTargetField({
    indicatorMetrics,
    calculatedIndicators,
    targetField: "actual",
  });

  const byGate = operationByTargetField({
    indicatorMetrics,
    calculatedIndicators,
    targetField: "gate",
  });

  const byAppropriated = operationByTargetField({
    indicatorMetrics,
    calculatedIndicators,
    targetField: "appropriated",
  });

  const byExceeded = operationByTargetField({
    indicatorMetrics,
    calculatedIndicators,
    targetField: "exceeded",
  });

  return {
    actual: {
      value: byActual,
      formatted: displayTransformValue(byActual, unity, toFix),
    },
    gate: {
      value: byGate,
      formatted: displayTransformValue(byGate, unity, toFix),
    },
    appropriated: {
      value: byAppropriated,
      formatted: displayTransformValue(byAppropriated, unity, toFix),
    },
    exceeded: {
      value: byExceeded,
      formatted: displayTransformValue(byExceeded, unity, toFix),
    },
  };
}

export function getMathOperationsAsStringPreview({
  calculatedIndicators,
  indicatorMetrics,
  maxPerLine = 3,
  splitSymbol = "###",
}) {
  return indicatorMetrics
    .sort((a, b) => a.order - b.order)
    .reduce((acc, indicatorMetric, index) => {
      const calculatedIndicator = calculatedIndicators.find(
        (calculatedIndicator) =>
          calculatedIndicator.goal.id === indicatorMetric.goalId,
      );

      if (!calculatedIndicator?.goal) return acc;

      const formatted = displayTransformValue(
        calculatedIndicator?.result.goalPeriodTotalEvaluation,
        calculatedIndicator.goal.measurementUnity,
      );

      const formatedAcc =
        index % maxPerLine === 0 ? `${acc} ${splitSymbol}` : acc;

      if (index === indicatorMetrics.length - 1)
        return `${formatedAcc} ${formatted}`;

      const operationOption =
        operationOptions.find(
          (operation) => operation.value === indicatorMetric.operation,
        ) || "";

      return `${formatedAcc} ${formatted} ${operationOption?.string || ""}`;
    }, "")
    .split(splitSymbol);
}
