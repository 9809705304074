import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import _ from "lodash";

import {
  getSubtitleColorBySlug,
  getReadinessOptions,
  getInterestOptions,
  getGenderOptions,
  getPerfomanceEvaluationOptions,
  getRoleOptions,
  getSalaryModificationOptions,
  getMarketEvaluationOptions,
  getEducationOptions,
} from "../../../../../constants/peopleEvaluation";
import { getProfessionalInfo } from "../../../../../utils/peopleEvaluationUtils";
import {
  getSelectedCompanyStates,
  getActionsPlanStates,
  getSelectedToolStates,
} from "../../../../customMapStates";

import { getAllUsersCompanyProfiles } from "../../../../../actions/userActions";

import ActionsPlanOverview from "../../../../Common/ActionPlan/ActionsPlanOverview";
import ButtonGeneratePdf from "../../../../Common/ButtonGeneratePdf";

import AscentionField from "./fields/AscentionField";
import EvaluationResultField from "./fields/EvaluationResultField";
import InterestField from "./fields/InterestField";
import LeadingField from "./fields/LeadingField";
import PerformanceField from "./fields/PerformanceField";
import ProfileField from "./fields/ProfileField";
import ReadinessField from "./fields/ReadinessField";
import TrajectoryHistoryField from "./fields/TrajectoryHistoryField";
import WageRecognitionField from "./fields/WageRecognitionField";
import MapDevelopField from "./fields/MapDevelopField";
import MapKeepField from "./fields/MapKeepField";
import MapMediumTermField from "./fields/MapMediumTermField";
import MapReadyField from "./fields/MapReadyField";
import MapReassessField from "./fields/MapReassessField";
import MapShortTermField from "./fields/MapShortTermField";
import LeadershipMap from "./fields/LeadershipMap";

const rowStyle = {
  padding: "10px 0px 20px 0px",
};

const messages = defineMessages({
  global_undefined: {
    id: "global.undefined",
  },
  global_notSelected: {
    id: "global.notSelected",
  },
  global_total: {
    id: "global.total",
  },
  titles_knowledge: {
    id: "pb_titles_knowledge",
  },
  titles_development: {
    id: "pb_titles_development",
  },
  titles_potential: {
    id: "pb_titles_potential",
  },
  titles_tabs_people_map: {
    id: "pb_titles_tabs_people_map",
  },
  titles_tabs_career_map: {
    id: "pb_titles_tabs_career_map",
  },
  titles_tabs_leadership_map: {
    id: "pb_titles_tabs_leadership_map",
  },
  titles_cards_profile: {
    id: "pb_titles_cards_profile",
  },
  titles_cards_career: {
    id: "pb_titles_cards_career",
  },
  titles_cards_interests: {
    id: "pb_titles_cards_interests",
  },
  titles_cards_performance: {
    id: "pb_titles_cards_performance",
  },
  titles_cards_leading: {
    id: "pb_titles_cards_leading",
  },
  titles_cards_evaluation_result: {
    id: "pb_titles_cards_evaluation_result",
  },
  titles_cards_wage_recognition: {
    id: "pb_titles_cards_wage_recognition",
  },
  titles_cards_ascension: {
    id: "pb_titles_cards_ascension",
  },
  titles_cards_readiness: {
    id: "pb_titles_cards_readiness",
  },
  labels_gender: {
    id: "pb_labels_gender",
  },
  labels_age_group: {
    id: "pb_labels_age_group",
  },
  labels_working_time: {
    id: "pb_labels_working_time",
  },
  labels_education: {
    id: "pb_labels_education",
  },
  labels_up_to: {
    id: "pb_labels_up_to",
  },
  labels_professionals: {
    id: "pb_labels_professionals",
  },
  labels_time_years: {
    id: "pb_labels_time_years",
  },
  labels_potential: {
    id: "pb_labels_potential",
  },
  labels_performance: {
    id: "pb_labels_performance",
  },
  labels_current_year: {
    id: "pb_labels_current_year",
  },
  labels_last_year: {
    id: "pb_labels_last_year",
  },
  labels_variation: {
    id: "pb_labels_variation",
  },
  labels_readjust_history: {
    id: "pb_labels_readjust_history",
  },
  labels_market_comparison: {
    id: "pb_labels_market_comparison",
  },
  labels_salary: {
    id: "pb_labels_salary",
  },
  labels_role: {
    id: "pb_labels_role",
  },
  labels_ready: {
    id: "pb_labels_ready",
  },
  labels_partial: {
    id: "pb_labels_partial",
  },
  labels_not: {
    id: "pb_labels_not",
  },
  career_job: {
    id: "pb_career_job",
  },
  career_trail: {
    id: "pb_career_trail",
  },
  acp_main_title: { id: "acp_main_title" },
  acp_onp_insight_label: { id: "acp_onp_insight_label" },
  acp_onp_tool_table_title: { id: "acp_onp_tool_table_title" },
  acp_onp_tool_table_head_insight_label: {
    id: "acp_onp_tool_table_head_insight_label",
  },
});

const PeopleBigPicture = (props) => {
  const [activeTab, setActiveTab] = useState("knowledgeTab");
  const [filteredEvaluations, setFilteredEvaluations] = useState([]);
  const [filteredProfessionals, setFilteredProfessionals] = useState([]);
  const [usersCompanyProfiles, setUsersCompanyProfiles] = useState([]);

  // STATES TO CHILD COMPs.
  const [translatedOptions, setTranslatedOptions] = useState({
    genderOptions: [],
    readinessOptions: [],
    interestOptions: [],
    performanceEvaluationOptions: [],
    roleOptions: [],
    salaryModificationOptions: [],
    marketEvaluationOptions: [],
    educationOptions: [],
  });
  // END STATES TO CHILD COMPs.

  const {
    selectedCompany = {},
    selectedTool = {},
    filterData,
    filteredInsights: filteredInsightsData,
    allPeopleEvaluation,
    allActionsPlans = [],
    allQuestions,
    allAnswers,
    intl,
    selectAndOpenInsight,
  } = props;

  const selectedCompanyId = selectedCompany.id ? selectedCompany.id : null;

  useEffect(() => {
    async function fetchProfiles() {
      const restUsersCompanyProfiles = await getAllUsersCompanyProfiles(
        selectedCompanyId,
      );

      if (
        restUsersCompanyProfiles.data &&
        !_.isEqual(restUsersCompanyProfiles.data, usersCompanyProfiles)
      )
        setUsersCompanyProfiles(restUsersCompanyProfiles.data);
    }

    if (Array.isArray(filteredEvaluations) && selectedCompanyId) {
      const newProfessionals = [];

      filteredEvaluations.forEach(({ peopleEvaluation = {} }) => {
        const { professionalProfile = {} } = peopleEvaluation;
        const { professional = {} } = professionalProfile;

        const profileMemberInfo = getProfessionalInfo(professional);

        if (profileMemberInfo) newProfessionals.push(profileMemberInfo);
      });

      if (!_.isEqual(newProfessionals, filteredProfessionals)) {
        fetchProfiles();
        setFilteredProfessionals(newProfessionals);
      }
    }
  }, [selectedCompanyId, filteredEvaluations]);

  useEffect(() => {
    setTranslatedOptions({
      genderOptions: getGenderOptions(intl),
      readinessOptions: getReadinessOptions(intl),
      interestOptions: getInterestOptions(intl, true),
      performanceEvaluationOptions: getPerfomanceEvaluationOptions(intl),
      roleOptions: getRoleOptions(intl),
      salaryModificationOptions: getSalaryModificationOptions(intl),
      marketEvaluationOptions: getMarketEvaluationOptions(intl),
      educationOptions: getEducationOptions(intl),
    });
  }, [intl]);

  const getEvaluationsToDisplay = () => {
    let finalList = [];

    if (filterData.themes) {
      const filteredInsights = filteredInsightsData.map(
        (insight) => insight.id,
      );

      allPeopleEvaluation.forEach((evaluation) => {
        if (
          filterData.themes.indexOf(evaluation.themeId) > -1 &&
          filteredInsights.indexOf(evaluation.insightId) > -1 &&
          filterData.questions?.includes(evaluation.questionId) &&
          allAnswers[evaluation.questionId]?.find(
            ({ id }) => id === evaluation.insightId,
          )
        ) {
          finalList = [...finalList, evaluation];
        }
      });
    }

    return finalList;
  };

  useEffect(() => {
    const evaluationsToDisplay = getEvaluationsToDisplay();

    if (!_.isEqual(evaluationsToDisplay, filteredEvaluations)) {
      setFilteredEvaluations(evaluationsToDisplay);
    }
  }, [
    allPeopleEvaluation,
    filteredInsightsData,
    filterData,
    filteredEvaluations,
  ]);

  const getFilteredQuestionsAndInsights = () => {
    const filteredQuestions = [];
    const filteredInsights = [];

    if (allQuestions && filterData.themes) {
      Object.keys(allQuestions).forEach((themeId) => {
        const themeQuestions = allQuestions[themeId];

        if (
          filterData.themes.indexOf(Number(themeId)) > -1 &&
          Array.isArray(themeQuestions)
        ) {
          themeQuestions.forEach((questionInfo) => {
            const questionInsights = getQuestionInsights(questionInfo.id);

            if (filterData.questions?.includes(questionInfo.id)) {
              filteredQuestions.push(questionInfo);
              if (Array.isArray(questionInsights))
                questionInsights.forEach((insightInfo) =>
                  filteredInsights.push(insightInfo),
                );
            }
          });
        }
      });
    }

    return { filteredQuestions, filteredInsights };
  };

  const getQuestionInsights = (questionID) => {
    return allAnswers[questionID] ? allAnswers[questionID] : [];
  };

  const peopleBigMapTitle = (
    <div>
      <i className="fas fa-users" />
      &nbsp;&nbsp;
      {intl.formatMessage(messages.titles_tabs_people_map)}
    </div>
  );

  const carreerMapTitle = (
    <div>
      <i className="fas fa-map-signs" />
      &nbsp;&nbsp;
      {intl.formatMessage(messages.titles_tabs_career_map)}
    </div>
  );

  const leadershipMapTitle = (
    <div>
      <i className="fa fa-th" />
      &nbsp;&nbsp;
      {intl.formatMessage(messages.titles_tabs_leadership_map)}
    </div>
  );

  const essencialProps = {
    filteredEvaluations,
    filteredProfessionals,
    usersCompanyProfiles,
    intl,
    messages,
    selectAndOpenInsight,
    ...translatedOptions,
  };

  const displayKnowledgeRow = () => {
    return (
      <div className="row" style={rowStyle}>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <ProfileField {...essencialProps} />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <TrajectoryHistoryField {...essencialProps} />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <InterestField {...essencialProps} />
        </div>
      </div>
    );
  };

  const displayDevelopmentRow = () => {
    return (
      <div className="row" style={rowStyle}>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <PerformanceField {...essencialProps} />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <EvaluationResultField {...essencialProps} />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <LeadingField {...essencialProps} />
        </div>
      </div>
    );
  };

  const displayPotencialRow = () => {
    return (
      <div className="row" style={rowStyle}>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <WageRecognitionField {...essencialProps} />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <AscentionField {...essencialProps} />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <ReadinessField {...essencialProps} />
        </div>
      </div>
    );
  };

  const displayTrailJobMap = () => {
    return (
      <div className="row" style={rowStyle}>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <MapReadyField
              {...essencialProps}
              subtitleColor={getSubtitleColorBySlug("READY", intl)}
            />
          </div>
          <div className="col-xs-12 col-md-4">
            <MapShortTermField
              {...essencialProps}
              subtitleColor={getSubtitleColorBySlug("SHORT_TERM_READY", intl)}
            />
          </div>
          <div className="col-xs-12 col-md-4">
            <MapMediumTermField
              {...essencialProps}
              subtitleColor={getSubtitleColorBySlug("MEDIUM_TERM_READY", intl)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <MapDevelopField
              {...essencialProps}
              subtitleColor={getSubtitleColorBySlug("DEVELOP", intl)}
            />
          </div>
          <div className="col-xs-12 col-md-4">
            <MapKeepField
              {...essencialProps}
              subtitleColor={getSubtitleColorBySlug("KEEP", intl)}
            />
          </div>
          <div className="col-xs-12 col-md-4">
            <MapReassessField
              {...essencialProps}
              subtitleColor={getSubtitleColorBySlug("REASSESS", intl)}
            />
          </div>
        </div>
      </div>
    );
  };

  const displayLeadershipMap = () => {
    return (
      <div className="row" style={rowStyle}>
        <LeadershipMap {...essencialProps} />
      </div>
    );
  };

  const actionsPlanOverviewTitle = (
    <div>
      <i className="fas fa-list-ol" />
      &nbsp;&nbsp;
      {intl.formatMessage(messages.acp_main_title)}
    </div>
  );

  const knowledgeTabTitle = (
    <div>
      <i className="fas fa-address-card" />
      <span style={{ marginLeft: "7px" }}>
        {intl.formatMessage(messages.titles_knowledge)}
      </span>
    </div>
  );

  const developmentTabTitle = (
    <div>
      <i className="fas fa-chart-line" />
      <span style={{ marginLeft: "7px" }}>
        {intl.formatMessage(messages.titles_development)}
      </span>
    </div>
  );

  const potencialTabTitle = (
    <div>
      <i className="fas fa-child" />
      <span style={{ marginLeft: "7px" }}>
        {intl.formatMessage(messages.titles_potential)}
      </span>
    </div>
  );

  const { filteredInsights } = getFilteredQuestionsAndInsights();

  return (
    <div className="custom-primary-tabs">
      <ButtonGeneratePdf />
      <Tabs
        id="peopleManagement"
        activeKey={activeTab}
        onSelect={(key) => setActiveTab(key)}
      >
        <Tab eventKey="knowledgeTab" title={knowledgeTabTitle}>
          <div className="name-tab-pdf">
            <i className="fas fa-address-card" />
            <span style={{ marginLeft: "7px" }}>
              {intl.formatMessage(messages.titles_knowledge)}
            </span>
          </div>
          {displayKnowledgeRow()}
        </Tab>
        <Tab eventKey="developmentTab" title={developmentTabTitle}>
          <div className="name-tab-pdf">
            <i className="fas fa-chart-line" />
            <span style={{ marginLeft: "7px" }}>
              {intl.formatMessage(messages.titles_development)}
            </span>
          </div>
          {displayDevelopmentRow()}
        </Tab>
        <Tab eventKey="potencialTab" title={potencialTabTitle}>
          <div className="name-tab-pdf">
            <i className="fas fa-child" />
            <span style={{ marginLeft: "7px" }}>
              {intl.formatMessage(messages.titles_potential)}
            </span>
          </div>
          {displayPotencialRow()}
        </Tab>

        <Tab eventKey="trailMap" title={carreerMapTitle}>
          <div className="name-tab-pdf">
            <i className="fas fa-map-signs" />
            &nbsp;&nbsp;
            {intl.formatMessage(messages.titles_tabs_career_map)}
          </div>
          <div className="tab-content" style={{ marginTop: "10px" }}>
            {displayTrailJobMap()}
          </div>
        </Tab>
        <Tab eventKey="leadershipMap" title={leadershipMapTitle}>
          <div className="name-tab-pdf">
            <i className="fa fa-th" />
            &nbsp;&nbsp;
            {intl.formatMessage(messages.titles_tabs_leadership_map)}
          </div>
          <div className="tab-content" style={{ marginTop: "10px" }}>
            {displayLeadershipMap()}
          </div>
        </Tab>
        <Tab eventKey="actionsPlanOverview" title={actionsPlanOverviewTitle}>
          <div className="name-tab-pdf">
            <i className="fas fa-list-ol" />
            &nbsp;&nbsp;
            {intl.formatMessage(messages.acp_main_title)}
          </div>
          <div className="tab-content" style={{ marginTop: "10px" }}>
            <ActionsPlanOverview
              allActionsPlans={allActionsPlans}
              filteredInsights={filteredInsights}
              overviewTableTitle={intl
                .formatMessage(messages.acp_main_title)
                .toUpperCase()}
              overviewTableInsightsLabel={intl
                .formatMessage(messages.acp_onp_insight_label)
                .toUpperCase()}
              insightsTableTitle={intl
                .formatMessage(messages.acp_onp_tool_table_title)
                .toUpperCase()}
              insightsTableInsightsLabel={intl
                .formatMessage(messages.acp_onp_tool_table_head_insight_label)
                .toUpperCase()}
              selectedTool={selectedTool}
              selectAndOpenInsight={selectAndOpenInsight}
            />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { toolsConfigurations, peopleEvaluation } = state;

  const { selectedCompany = {} } = getSelectedCompanyStates(state);
  const allPeopleEvaluation =
    peopleEvaluation && peopleEvaluation.allPeopleEvaluation
      ? peopleEvaluation.allPeopleEvaluation
      : [];

  const { selectedTool = {} } = getSelectedToolStates(state);

  const { allActionsPlans = [] } = getActionsPlanStates(state);

  return {
    allPeopleEvaluation,
    selectedCompany,
    allActionsPlans,
    selectedTool,
  };
};

export default injectIntl(connect(mapStateToProps)(PeopleBigPicture));
