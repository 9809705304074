import _ from "lodash";
import {
  FETCH_OKR_CONFIG,
  FETCH_ALL_OKR_CONFIGS,
  GET_ALL_CYCLES_BASES,
  POST_PLAN,
  PUT_PLAN,
  POST_OBJECTIVE,
  RESET_OKR,
  DELETE_OBJECTIVE,
  PUT_OBJECTIVE,
  POST_KEY,
  DELETE_PLAN,
  DELETE_KEY,
  PUT_KEY,
  POST_CYCLE_BASE,
  GET_CYCLE_BASE,
  POST_PATTERN_BASE,
  PUT_PATTERN_BASE,
  DELETE_BASE_PATTERN,
} from "../constants/okr";

const INITIAL_STATE = {
  id: null,
  okrPlans: [],
  objectives: [],
  cycleBase: {},
};

export default function okrReducer(state = INITIAL_STATE, action) {
  const updateArray = (stateArray, payload) => {
    return stateArray.map((stateObject) =>
      stateObject.id === payload.id
        ? { ...stateObject, ...payload }
        : stateObject,
    );
  };

  const updateObjectivesIfObjectiveIdIsDifferent = (
    stateObjectives,
    payload,
  ) => {
    const { newObjectiveId, key } = payload;

    const findObjectiveByNewObjectiveId = (objective) =>
      objective.id === newObjectiveId;

    const findObjectiveByKeyObjectiveId = (objective) =>
      objective.id === key.objectiveId;

    const findAlreadyExistKeyById = (objectivekeys, keyId) => {
      return objectivekeys.findIndex((key) => key.id === keyId) > -1;
    };

    const removeOldObjective = (objective) => objective.id !== key.objectiveId;

    const findedNewObjective = stateObjectives.find(
      findObjectiveByNewObjectiveId,
    );

    const filteredStateObjectives = stateObjectives.filter(removeOldObjective);

    const findedOldObjective = stateObjectives.find(
      findObjectiveByKeyObjectiveId,
    );

    const findedIndexFromOldKeyInObjective = findedOldObjective.keys.findIndex(
      (objectiveKey) => objectiveKey.id === key.id,
    );

    if (findedIndexFromOldKeyInObjective > -1) {
      findedOldObjective.keys.splice(findedIndexFromOldKeyInObjective, 1);
    }

    const updateObjectivesWithoutOldObjectiveKey = [
      ...filteredStateObjectives,
      findedOldObjective,
    ];

    const alreadyExistKeyById = findAlreadyExistKeyById(
      findedNewObjective.keys,
      key.id,
    );

    if (!alreadyExistKeyById) {
      findedNewObjective.keys.push({ ...key, objectiveId: newObjectiveId });
    }

    const updatedObjectives = updateObjectivesWithoutOldObjectiveKey.map(
      (objective) => {
        return findObjectiveByNewObjectiveId(objective)
          ? { ...findedNewObjective }
          : { ...objective };
      },
    );

    return updatedObjectives;
  };

  const updateObjectiveKey = (stateArray, payload) => {
    if (payload.newObjectiveId !== payload.key.objectiveId) {
      return updateObjectivesIfObjectiveIdIsDifferent(stateArray, payload);
    }

    const newObjective = stateArray.map((objective) => {
      if (objective.id === payload.objectiveId) {
        return {
          ...objective,
          keys: objective.keys.map((keyInfo) => {
            return keyInfo.id === payload.key.id
              ? { ...keyInfo, ...payload.key }
              : { ...keyInfo };
          }),
        };
      }
      return {
        ...objective,
      };
    });

    return newObjective;
  };

  const updatePatternsCycles = (patterns, payload) => {
    return patterns.map((patternInfo) => {
      if (patternInfo.id === payload.patternId) {
        return {
          ...patternInfo,
          title: payload.pattern.title,
          cycles: payload.patternCycles,
        };
      }
      return { ...patternInfo };
    });
  };

  const updateObjective = (stateArray, payload) => {
    return stateArray.map((objective) => {
      if (objective.id === payload.objectiveId) {
        return { ...objective, keys: [...objective.keys, payload.key] };
      }
      return { ...objective };
    });
  };

  const updateDeleteState = (stateArray, payload) => {
    return stateArray.filter((item) => item.id !== payload);
  };

  const updateDeleteKey = (objective, payload) => {
    return objective.map((objectiveInfo) => {
      if (objectiveInfo.id === payload.objectiveId) {
        return {
          ...objectiveInfo,
          keys: objectiveInfo.keys.filter(
            (keyInfo) => keyInfo.id !== payload.keyId,
          ),
        };
      }
      return { ...objectiveInfo };
    });
  };

  if (action.payload) {
    const clonedPlans = _.cloneDeep([...state.okrPlans]);
    const clonedObjectives = _.cloneDeep([...state.objectives]);
    const clonedCycleBase = _.cloneDeep({ ...state.cycleBase });

    switch (action.type) {
      case FETCH_OKR_CONFIG:
        return {
          ...state,
          id: action.payload.id,
          okrPlans: action.payload.plans || [],
          objectives: action.payload.objectives || [],
        };

      case FETCH_ALL_OKR_CONFIGS:
        const { payload: allOkrConfigurations = {} } = action || {};

        return {
          ...state,
          allOkrConfigurations,
        };
      case POST_PLAN:
        return {
          ...state,
          okrPlans: clonedPlans.concat(action.payload),
        };

      case GET_CYCLE_BASE:
        return {
          ...state,
          cycleBase: action.payload || {},
        };

      case GET_ALL_CYCLES_BASES:
        const { payload: allCyclesBases = {} } = action || {};

        return {
          ...state,
          allCyclesBases,
        };

      case PUT_PATTERN_BASE:
        return {
          ...state,
          cycleBase: {
            ...clonedCycleBase,
            patterns: updatePatternsCycles(
              clonedCycleBase.patterns,
              action.payload,
            ),
          },
        };

      case DELETE_BASE_PATTERN:
        return {
          ...state,
          cycleBase: {
            ...clonedCycleBase,
            patterns: updateDeleteState(
              clonedCycleBase.patterns,
              action.payload,
            ),
          },
        };
      case POST_CYCLE_BASE:
        return {
          ...state,
          cycleBase: action.payload,
        };
      case POST_PATTERN_BASE:
        return {
          ...state,
          cycleBase: {
            ...clonedCycleBase,
            patterns: clonedCycleBase.patterns.concat(action.payload),
          },
        };
      case DELETE_PLAN:
        return {
          ...state,
          okrPlans: updateDeleteState(clonedPlans, action.payload),
        };
      case PUT_PLAN:
        return {
          ...state,
          okrPlans: updateArray(clonedPlans, action.payload),
        };
      case POST_OBJECTIVE:
        return {
          ...state,
          objectives: clonedObjectives.concat(action.payload),
        };
      case POST_KEY:
        return {
          ...state,
          objectives: updateObjective(clonedObjectives, action.payload),
        };
      case PUT_KEY:
        return {
          ...state,
          objectives: updateObjectiveKey(clonedObjectives, action.payload),
        };

      case PUT_OBJECTIVE:
        return {
          ...state,
          objectives: updateArray(clonedObjectives, action.payload),
        };
      case DELETE_OBJECTIVE:
        return {
          ...state,
          objectives: updateDeleteState(clonedObjectives, action.payload),
        };
      case DELETE_KEY:
        return {
          ...state,
          objectives: updateDeleteKey(clonedObjectives, action.payload),
        };
      case RESET_OKR:
        return INITIAL_STATE;
      default:
        return state;
    }
  }
  return state;
}
