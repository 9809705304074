export const updateClientLoading = ({ loading = false, message }) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_CLIENT_LOADING",
      payload: {
        loading,
        message,
      },
    });
  };
};
