import React from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import utils from "../../../../../../../utils/toolUtils";
import { getCountAdminRolesOnAdministration } from "../../../../../../../utils/goalsBonus/validation";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import { deleteAdministrativeRole } from "../../../../../../../actions/goalsAndBonusActions";
import {
  getEmptyTableRow,
  getDefaultAddTableLine,
} from "../../../../../../../utils/tableUtils";
import { getDefaultRemoveColumn } from "../../../../../../../utils/okrUtils";

const { getUpdatedDeleteArrayByIndex, orderArrayByObjAttr } = utils;

const messages = defineMessages({
  gb_table_text_main_roles: { id: "gb_table_text_main_roles" },
  gb_table_text_role: { id: "gb_table_text_role" },
  gb_table_text_no_role: { id: "gb_table_text_no_role" },
  gb_table_text_accesses: { id: "gb_table_text_accesses" },
  gb_table_text_involved: { id: "gb_table_text_involved" },
  gb_warning_need_min_administrator: {
    id: "gb_warning_need_min_administrator",
  },
  gb_confirm_delete_role: { id: "gb_confirm_delete_role" },
  global_selectedOnes: { id: "global.selectedOnes" },
  global_selectSome: { id: "global.selectSome" },
});

const AdministrativeRolesTable = (props) => {
  const {
    setConfirmationDialog,
    goalBonusAdministration,
    setGoalBonusAdministration,
    resetConfirmDialog,
    toggleOpenFormDialogs,
    pageAccessRulesOptions,
    handleSetEditingForm,
    intl,
    deleteAdministrativeRole,
  } = props;

  const handleDeleteAdministrativeRole = (index) => {
    const deletingObjInfo = goalBonusAdministration.administrativeRoles[index];

    if (deletingObjInfo.id) {
      deleteAdministrativeRole(deletingObjInfo.id, goalBonusAdministration.id);

      const updatedAdministrativeRoles = getUpdatedDeleteArrayByIndex(
        goalBonusAdministration.administrativeRoles,
        index,
      );

      setGoalBonusAdministration({
        ...goalBonusAdministration,
        administrativeRoles: updatedAdministrativeRoles,
      });
    }

    resetConfirmDialog();
  };

  const openDeleteAdminstrativeRole = (index) => {
    const simulatedDeletedRoles = getUpdatedDeleteArrayByIndex(
      goalBonusAdministration.administrativeRoles,
      index,
    );
    const isLastAdministrativeRole = simulatedDeletedRoles === 0;
    const adminRolesCount = getCountAdminRolesOnAdministration(
      simulatedDeletedRoles,
    );

    const allowedToDelete = !isLastAdministrativeRole && adminRolesCount > 0;

    setConfirmationDialog({
      title: !allowedToDelete
        ? intl.formatMessage(messages.gb_warning_need_min_administrator)
        : intl.formatMessage(messages.gb_confirm_delete_role),
      onConfirm: allowedToDelete
        ? () => handleDeleteAdministrativeRole(index)
        : resetConfirmDialog,
      onCancel: resetConfirmDialog,
      confirmText: !allowedToDelete ? "OK" : null,
      hideCancel: !allowedToDelete ? true : false,
      open: true,
    });
  };

  const getMembersDisplayValue = (list) => {
    return list && list.length > 0
      ? `${list.length} ${intl.formatMessage(messages.global_selectedOnes)}`
      : intl.formatMessage(messages.global_selectSome);
  };

  const getAccessDisplayValue = (list) => {
    let displayAccess = [];

    if (list && list.length > 0) {
      pageAccessRulesOptions.forEach((optionInfo) => {
        if (list.indexOf(optionInfo.value) > -1)
          displayAccess.push(optionInfo.label);
      });
    }

    return displayAccess.length > 0
      ? displayAccess.join(", ")
      : intl.formatMessage(messages.global_selectSome);
  };

  let reorderedAdmRoles = orderArrayByObjAttr(
    goalBonusAdministration.administrativeRoles,
    "role",
  );

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="5">
            {intl.formatMessage(messages.gb_table_text_main_roles)}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr className="block-white-space border-bottom-division">
          <td colSpan="2">{intl.formatMessage(messages.gb_table_text_role)}</td>
          <td>{intl.formatMessage(messages.gb_table_text_accesses)}</td>
          <td style={{ width: "150px" }}>
            {intl.formatMessage(messages.gb_table_text_involved)}
          </td>
          <td />
        </tr>
        {reorderedAdmRoles.map((adminRoleInfo, index) => {
          return (
            <tr className="block-hover" key={index}>
              <td align="center" style={{ width: "40px" }}>
                {index + 1}
              </td>
              <SimpleExcelCollumn
                id="administrativeRole"
                label={intl.formatMessage(messages.gb_table_text_role)}
                type="text"
                value={adminRoleInfo.role}
                onClick={() =>
                  handleSetEditingForm("newAdministrativeRole", {
                    adminRoleId: adminRoleInfo.id,
                  })
                }
                inputStyle={{ cursor: "pointer" }}
              />
              <SimpleExcelCollumn
                id="access"
                label={intl.formatMessage(messages.gb_table_text_accesses)}
                type="text"
                value={getAccessDisplayValue(adminRoleInfo.access)}
                onClick={() =>
                  handleSetEditingForm("newAdministrativeRole", {
                    adminRoleId: adminRoleInfo.id,
                  })
                }
                inputStyle={{ cursor: "pointer" }}
                title={`${adminRoleInfo.access.length} ${intl.formatMessage(
                  messages.global_selectedOnes,
                )}`}
              />
              <SimpleExcelCollumn
                id="involved"
                label={intl.formatMessage(messages.gb_table_text_involved)}
                type="text"
                value={getMembersDisplayValue(adminRoleInfo.members)}
                onClick={() =>
                  handleSetEditingForm("newAdministrativeRole", {
                    adminRoleId: adminRoleInfo.id,
                  })
                }
                inputStyle={{ cursor: "pointer" }}
                title={`${adminRoleInfo.members.length} ${intl.formatMessage(
                  messages.global_selectedOnes,
                )}`}
              />
              {getDefaultRemoveColumn(() => openDeleteAdminstrativeRole(index))}
            </tr>
          );
        })}
        {getEmptyTableRow(
          goalBonusAdministration.administrativeRoles,
          intl.formatMessage(messages.gb_table_text_no_role),
        )}
        {getDefaultAddTableLine(() =>
          toggleOpenFormDialogs("newAdministrativeRole"),
        )}
      </tbody>
    </table>
  );
};

export default injectIntl(
  connect(null, { deleteAdministrativeRole })(AdministrativeRolesTable),
);
