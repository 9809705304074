import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import FormDialog from "../../FormDialog";
import MaterialTextField from "../../MaterialTextField";
import SimpleCheckbox from "../../SimpleCheckbox";
import SelectGoalModal from "../../SelectGoalModal";
import gbUtils from "../../../../utils/goalsBonus";
import { getRelationshipTypeOptions } from "../../../../constants/goalsAndBonus";

const { getSelectGoalSecondaryText } = gbUtils;

const initialForm = {
  selectedGoals: [],
  comment: "",
  sendEmails: false,
  sendToParticipants: false,
};

const messages = defineMessages({
  global_selectSome: { id: "global.selectSome" },
  gb_dialog_title_approval: { id: "gb_dialog_title_approval" },
  gb_form_select_goals: { id: "gb_form_select_goals" },
  gb_form_update_comment: { id: "gb_form_update_comment" },
  gb_form_send_email_approvers: { id: "gb_form_send_email_approvers" },
  gb_form_send_email_participants: { id: "gb_form_send_email_participants" },
  gb_error_no_goal_selected: { id: "gb_error_no_goal_selected" },
  gb_error_invalid_comment: { id: "gb_error_invalid_comment" },
});

const UpdateParticipantWorkflowDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const [selectGoalModal, setSelectGoalModal] = useState(false);

  const {
    open = false,
    goals = [],
    blockGoals = [],
    afterConfirm,
    afterCancel,
    intl,
  } = props;

  const relationshipTypeOptions = getRelationshipTypeOptions();

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleConfirm = () => {
    if (typeof afterConfirm === "function") afterConfirm(form);
  };

  const handleCancel = () => {
    if (typeof afterCancel === "function") afterCancel();
  };

  const handleUpdateForm = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const updateSelectedGoals = (selectedGoals) => {
    handleUpdateForm("selectedGoals", selectedGoals);

    toggleSelectGoalModal(false);
  };

  const toggleSelectGoalModal = (toggleTo = true) => {
    setSelectGoalModal(toggleTo);
  };

  const getSelectedGoalDisplay = (list) => {
    let displayGoals = [];

    list.forEach((goalInfo) => {
      displayGoals.push(goalInfo.title);
    });

    return displayGoals.length > 0
      ? displayGoals.join(", ")
      : intl.formatMessage(messages.global_selectSome);
  };

  const getFormErrors = () => {
    let errors = [];

    const addError = (slug, message) => {
      errors.push({ slug, message });
    };

    if (form.selectedGoals.length === 0)
      addError(
        "noGoalSelected",
        intl.formatMessage(messages.gb_error_no_goal_selected),
      );

    if (form.comment.length < 3)
      addError(
        "invalidComment",
        intl.formatMessage(messages.gb_error_invalid_comment),
      );

    return errors;
  };

  const getFilteredDisplayGoals = () => {
    let filtered = [];

    filtered = goals.map((goalInfo) => {
      return {
        ...goalInfo,
        filterValue: goalInfo.relationshipType,
        secondaryText: getSelectGoalSecondaryText(goalInfo),
      };
    }); // Adicionar filterValue e secondaryText para SelectGoalModal

    return filtered;
  };

  const formErrors = getFormErrors();
  const filteredGoals = getFilteredDisplayGoals();

  return (
    <>
      <SelectGoalModal
        open={selectGoalModal}
        goalsList={filteredGoals || []}
        filterOptions={relationshipTypeOptions}
        onCancel={() => toggleSelectGoalModal(false)}
        onConfirm={(ids, selectedGoals) => updateSelectedGoals(selectedGoals)}
        title={intl.formatMessage(messages.gb_form_select_goals)}
        blockList={blockGoals}
      />

      <FormDialog
        open={open}
        title={intl.formatMessage(messages.gb_dialog_title_approval)}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        blockConfirm={formErrors.length > 0}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialTextField
              id="selectGoals"
              label={intl.formatMessage(messages.gb_form_select_goals)}
              value={getSelectedGoalDisplay(form.selectedGoals)}
              onClick={() => toggleSelectGoalModal(true)}
              inputStyle={{ cursor: "pointer" }}
            />
          </div>
          <div className="col-xs-12">
            <MaterialTextField
              id="comment"
              label={intl.formatMessage(messages.gb_form_update_comment)}
              value={form.comment}
              onChange={(e) => handleUpdateForm("comment", e.target.value)}
              multiline
              rows={4}
            />
          </div>
          <div className="col-xs-6">
            <SimpleCheckbox
              checked={form.sendEmails}
              label={intl.formatMessage(messages.gb_form_send_email_approvers)}
              name="sendEmails"
              onChange={(e) => setForm({ ...form, sendEmails: !e.checked })}
            />
          </div>
          <div className="col-xs-6">
            <SimpleCheckbox
              checked={form.sendToParticipants}
              label={intl.formatMessage(
                messages.gb_form_send_email_participants,
              )}
              name="sendToParticipants"
              onChange={(e) =>
                setForm({ ...form, sendToParticipants: !e.checked })
              }
            />
          </div>
        </div>
      </FormDialog>
    </>
  );
};

export default injectIntl(UpdateParticipantWorkflowDialog);
