import React from "react";

const NextStep = ({ text, onClick, disabled }) => {
  return (
    <div
      className="col-xs-12"
      style={{
        display: "flex",
        justifyContent: "flex-end",
        margin: "10px 0px",
      }}
    >
      <button
        className="btn btn-primary"
        onClick={disabled ? () => {} : onClick}
        disabled={disabled}
      >
        {text}
      </button>
    </div>
  );
};

export default NextStep;
