import { checkAccess } from "../accessLevels";

export const domainConditions = (
  { requireDomain = false },
  { buildedAccess = false, currentDomain = {} },
) => {
  const { id: currentDomainId = false } = currentDomain || {};

  while (buildedAccess !== true) return true;

  if (!requireDomain) return true;

  if (requireDomain && currentDomainId) return true;

  return false;
};

export const roleConditions = ({ requiredRoles = false }) => {
  if (!requiredRoles) return true;

  const checkRole = checkAccess([...requiredRoles]);

  if (checkRole) return true;

  return false;
};

export const authenticationConditions = (
  { requireAuthorization = false },
  isAuthorized = false,
) => {
  if (!requireAuthorization || isAuthorized) return true;

  return false;
};

export const selectedCompanyConditions = (
  { requireSelectedCompany = false },
  selectedCompany,
) => {
  if (!requireSelectedCompany || (requireSelectedCompany && selectedCompany))
    return true;

  return false;
};
