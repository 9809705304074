import { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import utils from "../../../../utils/toolUtils";
import okrUtils from "../../../../utils/okr";
import { updateInsightName } from "../../../../actions/insightActions";

const {
  getObjectDifference,
  getObjectInfoById,
  getOnlySlugValues,
  removeObjSlug,
} = utils;

const { biuldAdmRestBody, getAllKeysFromObjectives } = okrUtils;

const defaultAllKeys = [];

const OkrWatcher = (props) => {
  const [okrKeys, setOkrKeys] = useState(defaultAllKeys);
  const [firstValidation, setFirstValidation] = useState(false);

  const {
    originOkrObjectives = [],
    selectedTool = {},
    allAnswers = {},
    updateInsightName,
  } = props;

  useEffect(() => {
    const restOkrAdministration = biuldAdmRestBody({
      objectives: originOkrObjectives,
    });
    const restOkrObjectivesKeys = getAllKeysFromObjectives(
      restOkrAdministration.objectives,
    );
    const isAdmObjDiff = !_.isEqual(restOkrObjectivesKeys, okrKeys);

    if (restOkrObjectivesKeys && Object.keys(allAnswers).length > 0) {
      if (isAdmObjDiff || !firstValidation) {
        const compareBase = removeObjSlug(okrKeys, "id");

        const diff = getObjectDifference(restOkrObjectivesKeys, compareBase);

        if (diff) {
          if (!firstValidation) setFirstValidation(true);

          const watchKeys = getOnlySlugValues(diff, "id");
          if (watchKeys.length > 0) {
            Object.keys(allAnswers).forEach((questionId) => {
              const questionInsights = allAnswers[questionId];

              questionInsights.forEach((insightInfo) => {
                const { okrConfigurationKeyId } = insightInfo;

                const relatedAdmKeyId =
                  watchKeys[watchKeys.indexOf(okrConfigurationKeyId)] || null;

                if (relatedAdmKeyId && insightInfo.text) {
                  const admKeyInfo = getObjectInfoById(
                    relatedAdmKeyId,
                    restOkrObjectivesKeys,
                    "id",
                  );

                  if (admKeyInfo && admKeyInfo.title !== insightInfo.text) {
                    updateInsightName(
                      questionId,
                      insightInfo.id,
                      admKeyInfo.title,
                    );
                  }
                }
              });
            });
          }
        }

        if (isAdmObjDiff) setOkrKeys(restOkrObjectivesKeys);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originOkrObjectives, allAnswers, firstValidation, selectedTool.id]);

  return null;
};

const mapStateToProps = (state) => {
  const { okr } = state;

  return { originOkrObjectives: okr.objectives };
};

export default connect(mapStateToProps, { updateInsightName })(OkrWatcher);
