import React, { useContext, useEffect } from "react";
import PropType from "prop-types";

import { PopoverContext } from "../../../../../Common/SimpleMenu";

import { Ullist, ListMenu } from "./styles";

const MenuChecklist = ({ options, id, index, getOptionsPopover }) => {
  const { closePopover, isOpenPopover } = useContext(PopoverContext);

  useEffect(() => {
    getOptionsPopover(isOpenPopover, index);

    return () => {
      getOptionsPopover(false);
    };
  }, [getOptionsPopover, isOpenPopover, index]);

  return (
    <Ullist>
      {options.map(({ onClick, text, icon }) => (
        <ListMenu onClick={() => onClick(id, index, closePopover)} key={text}>
          <i className={icon} />
          <li>{text}</li>
        </ListMenu>
      ))}
    </Ullist>
  );
};

export default MenuChecklist;

MenuChecklist.propTypes = {
  options: PropType.array.isRequired,
  getOptionsPopover: PropType.func.isRequired,
  id: PropType.string,
  index: PropType.number.isRequired,
};

MenuChecklist.defaultProps = {
  id: "",
};
