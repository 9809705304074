import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import _ from "lodash";
import utils from "../../../../utils/toolUtils";
import gbUtils from "../../../../utils/goalsBonus";

import FormDialog from "../../FormDialog";
import MaterialTextField from "../../MaterialTextField";
import MaterialMultiSelect from "../../MaterialMultiSelect";
import SelectMemberModal from "../../SelectMemberModal";

const { getErrorMessage } = utils;

const { getAdministrativeRoleInfoById, getAdmRolesErrors } = gbUtils;

const messages = defineMessages({
  gb_dialog_title_adm_roles: { id: "gb_dialog_title_adm_roles" },
  gb_dialog_title_select_involved: { id: "gb_dialog_title_select_involved" },
  gb_form_role: { id: "gb_form_role" },
  gb_form_involved: { id: "gb_form_involved" },
  gb_form_accesses: { id: "gb_form_accesses" },
  gb_form_add_role: { id: "gb_form_add_role" },
  gb_form_change_role: { id: "gb_form_change_role" },
  global_selectedOnes: { id: "global.selectedOnes" },
  global_selectSome: { id: "global.selectSome" },
  global_goBack: { id: "global.goBack" },
});

const initialForm = {
  id: null,
  role: "",
  members: [],
  access: [],
};

const NewAdministrativeRoleDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const [memberModalOpen, setMemberModalOpen] = useState(false);
  const [replaceSelectedMembers, setReplaceSelectedMembers] = useState(false);
  const {
    open = false,
    afterCancel,
    afterConfirm,
    pageAccessRulesOptions,
    goalBonusAdministration,
    setGoalBonusAdministration,
    companyMembersAndParticipants,
    editingForm,
    intl,
  } = props;

  const isEditing = editingForm.type === "newAdministrativeRole";

  useEffect(() => {
    if (editingForm && isEditing && goalBonusAdministration) {
      const adminRoleIndex = getAdministrativeRoleInfoById(
        editingForm.params.adminRoleId,
        goalBonusAdministration.administrativeRoles,
        true,
      );
      const adminRoleInfo =
        goalBonusAdministration.administrativeRoles[adminRoleIndex];

      if (adminRoleInfo) {
        setForm({ ...adminRoleInfo });
        setReplaceSelectedMembers(true);
      }
    }
  }, [editingForm, goalBonusAdministration, isEditing]);

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleSave = () => {
    let submitGoalsBonusAdm = goalBonusAdministration;

    if (!isEditing) {
      submitGoalsBonusAdm = {
        ...goalBonusAdministration,
        administrativeRoles: [
          ...goalBonusAdministration.administrativeRoles,
          { ...form },
        ],
      };
    } else {
      const adminRoleIndex = getAdministrativeRoleInfoById(
        editingForm.params.adminRoleId,
        goalBonusAdministration.administrativeRoles,
        true,
      );
      let newUpdatedAdminRoles = [
        ...goalBonusAdministration.administrativeRoles,
      ];

      newUpdatedAdminRoles[adminRoleIndex] = { ...form };

      submitGoalsBonusAdm = {
        ...goalBonusAdministration,
        administrativeRoles: newUpdatedAdminRoles,
      };
    }

    if (!_.isEqual(submitGoalsBonusAdm, goalBonusAdministration)) {
      setGoalBonusAdministration(submitGoalsBonusAdm);
    }

    resetForm();

    if (typeof afterConfirm === "function") afterConfirm(submitGoalsBonusAdm);
  };

  const handleCancel = () => {
    resetForm();

    if (typeof afterCancel === "function") afterCancel();
  };

  const handleUpdateSelectedMembers = (members) => {
    setMemberModalOpen(false);

    setForm({ ...form, members });
  };

  const handleOpenMemberModal = () => {
    setMemberModalOpen(true);

    if (form.members.length > 0) {
      setReplaceSelectedMembers(true);
    }
  };

  const getMembersDisplayValue = (list) => {
    let displayNames = [];

    companyMembersAndParticipants.forEach((memberInfo) => {
      if (list.indexOf(memberInfo.id) > -1) displayNames.push(memberInfo.name);
    });

    return displayNames.length > 0
      ? displayNames.join(", ")
      : intl.formatMessage(messages.global_selectSome);
  };

  const formTitle = !isEditing
    ? intl.formatMessage(messages.gb_form_add_role)
    : intl.formatMessage(messages.gb_form_change_role);

  const formErrors = getAdmRolesErrors(
    form,
    isEditing,
    goalBonusAdministration.administrativeRoles,
    editingForm.params.adminRoleId,
  );

  return (
    <>
      {memberModalOpen && (
        <SelectMemberModal
          title={intl.formatMessage(messages.gb_dialog_title_select_involved)}
          membersList={companyMembersAndParticipants || []}
          onConfirm={(selectedMembers) =>
            handleUpdateSelectedMembers(selectedMembers)
          }
          onCancel={() => setMemberModalOpen(false)}
          open={memberModalOpen}
          cancelText={intl.formatMessage(messages.global_goBack)}
          initialSelected={form.members}
          replaceSelected={replaceSelectedMembers}
          replaceCallback={() => setReplaceSelectedMembers(false)}
        />
      )}
      <FormDialog
        open={open}
        title={formTitle}
        onConfirm={handleSave}
        onCancel={handleCancel}
        bodyStyle={{ paddingTop: "5px" }}
        blockConfirm={formErrors.length > 0}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialTextField
              id="role"
              label={intl.formatMessage(messages.gb_form_role)}
              value={form.role}
              onChange={(e) => setForm({ ...form, role: e.target.value })}
            />
          </div>
          <div className="col-xs-6">
            <MaterialTextField
              id="involvedPeople"
              label={intl.formatMessage(messages.gb_form_involved)}
              value={getMembersDisplayValue(form.members)}
              inputStyle={{ cursor: "pointer" }}
              onClick={() => handleOpenMemberModal()}
            />
            {form.members.length > 0 && (
              <h5 style={{ marginTop: "0px" }}>
                {form.members.length}{" "}
                {intl.formatMessage(messages.global_selectedOnes)}
              </h5>
            )}
          </div>
          <div className="col-xs-6">
            <MaterialMultiSelect
              id="grantedAccess"
              label={intl.formatMessage(messages.gb_form_accesses)}
              options={[
                ...pageAccessRulesOptions.map(({ value, label }) => {
                  return { value, text: label };
                }),
              ]}
              getData={(selectedAccess) => {
                setForm({ ...form, access: selectedAccess });
              }}
              setDefaultValue={true}
              initialOptions={form.access}
            />
          </div>
        </div>
        <div className="col-xs-12">
          {getErrorMessage(["noAdminSelected"], formErrors, "left", {
            height: "22px",
          })}
        </div>
      </FormDialog>
    </>
  );
};

export default injectIntl(NewAdministrativeRoleDialog);
