import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import _ from "lodash";
import FormDialog from "../../FormDialog";
import MaterialTextField from "../../MaterialTextField";
import kpiUtils from "../../../../utils/kpis";

const { getGroupInfoById } = kpiUtils;

const initialForm = {
  id: null,
  title: "",
  description: "",
};

const messages = defineMessages({
  kpi_dialog_title_new_theme: { id: "kpi_dialog_title_new_theme" },
  kpi_dialog_title_edit_theme: { id: "kpi_dialog_title_edit_theme" },
  kpi_form_title: { id: "kpi_form_title" },
  kpi_form_description: { id: "kpi_form_description" },
});

const NewGroupDialog = (props) => {
  const [form, setForm] = useState(initialForm);

  const {
    open = false,
    afterCancel,
    afterConfirm,
    kpiAdministration,
    editingForm = {},
    getKpiConfig,
    intl,
  } = props;

  const isEditing = editingForm.type === "newGroup";

  useEffect(() => {
    if (editingForm && isEditing && kpiAdministration) {
      const editingGroupIndex = getEditingIndex(kpiAdministration.groups);
      const groupInfo = kpiAdministration.groups[editingGroupIndex];

      if (groupInfo) {
        setForm({ ...groupInfo });
      }
    }
  }, [editingForm, kpiAdministration, isEditing]);

  const updateFormField = (field, newVal) => {
    setForm({ ...form, [field]: newVal });
  };

  const resetForm = () => {
    setForm(initialForm);
  };

  const getEditingIndex = (list) => {
    return getGroupInfoById(editingForm.params.groupId, list, true);
  };

  const saveAdministration = (newAdministration) => {
    if (typeof afterConfirm === "function") afterConfirm(newAdministration);
  };

  const saveNewGroup = async () => {
    const data = await getKpiConfig();

    if (data && data.id)
      saveAdministration({
        ...data,
        groups: [...data.groups, { ...form }],
      });

    resetForm();
  };

  const editSaveGroup = async () => {
    const data = await getKpiConfig();

    if (data && data.id) {
      const editingGroupIndex = getEditingIndex(data.groups);
      const updatedGroups = [...data.groups];
      updatedGroups[editingGroupIndex] = form;

      saveAdministration({
        ...data,
        groups: updatedGroups,
      });
    }

    resetForm();
  };

  const handleConfirm = () => {
    if (!isEditing) saveNewGroup();
    if (isEditing) editSaveGroup();
  };

  const handleCancel = () => {
    resetForm();

    if (typeof afterCancel === "function") afterCancel();
  };

  const isValidForm = () => {
    if (form.title < 2) return false;

    return true;
  };

  const formTitle = !isEditing
    ? intl.formatMessage(messages.kpi_dialog_title_new_theme)
    : intl.formatMessage(messages.kpi_dialog_title_edit_theme);

  return (
    <FormDialog
      open={open}
      title={formTitle}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      blockConfirm={!isValidForm()}
    >
      <div className="row">
        <div className="col-xs-12">
          <MaterialTextField
            id="title"
            label={formTitle}
            value={form.title}
            onChange={(e) => updateFormField("title", e.target.value)}
          />
        </div>
        <div className="col-xs-12">
          <MaterialTextField
            id="description"
            label={intl.formatMessage(messages.kpi_form_description)}
            value={form.description}
            onChange={(e) => updateFormField("description", e.target.value)}
            multiline
          />
        </div>
      </div>
    </FormDialog>
  );
};

export default injectIntl(NewGroupDialog);
