const getInitialStates = (insight) => {
  const evaluationSkills = {
    skillsAssessmentEvaluationLeading: insight.skillsAssessmentEvaluationLeading
      ? insight.skillsAssessmentEvaluationLeading
      : {
          current: " ",
          details: " ",
        },
  };

  const evaluationPerformance = {
    skillsAssessmentEvaluationPerformance:
      insight.skillsAssessmentEvaluationPerformance
        ? insight.skillsAssessmentEvaluationPerformance
        : {
            current: " ",
            details: " ",
          },
  };

  const evaluationTargetDetails = {
    skillsAssessmentEvaluationDetails: insight.skillsAssessmentEvaluationDetails
      ? insight.skillsAssessmentEvaluationDetails
      : "",
  };

  const evaluationGapDetails = {
    skillsAssessmentGapDetails: insight.skillsAssessmentGapDetails
      ? insight.skillsAssessmentGapDetails
      : "",
  };

  return {
    evaluationSkills,
    evaluationPerformance,
    evaluationTargetDetails,
    evaluationGapDetails,
  };
};

export { getInitialStates };
