import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Avatar from "../../../../../../Common/Avatar";
import MaterialSingleSelect from "../../../../../../Common/MaterialSingleSelect";
import SimplePercentageLine from "../../../../../../Common/SimplePercentageLine";
import { ruleOfThree } from "../../../../../../../utils/MathUtils";
import Registration from "../../../../../../Common/ActionPlan/Registration";

import utils from "../../../../../../../utils/toolUtils";
import actionsPlanUtils from "../../../../../../../utils/actionsPlan";
import {
  getEvaluationStatusOptions,
  getActionStatusOptions,
} from "../../../../../../../constants/actionsPlan";

import {
  Container,
  BoxResponsibleInfo,
  ContentReponsibleInfo,
  Table,
  TdStyled,
  TdTracebility,
  TdTool,
  Icon,
  BoxSelect,
} from "../styles";
import {
  customCenaryName,
  translateQuestionName,
} from "../../../../../../../utils/cpAnalysis/display";
import { getToolCustomLabels } from "../../../../../../../utils/actionsPlan/display";

const { getObjectInfoById } = utils;
const { getActionPerformance, getActionsByStatus } = actionsPlanUtils;

const defaultDialogOptions = {
  open: false,
  actionPlan: {},
  action: {},
};

const ResponsibleView = ({
  actionPlans = {},
  updateOnlyAction,
  companyMembersAndParticipants,
  refetchData,
}) => {
  const [dialogOptions, setDialogOptions] = useState(defaultDialogOptions);
  const [orderedActionPlans, setOrderedActionPlans] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [selectedId, setSelectedId] = useState(true);
  const [filteredActions, setFilteredActions] = useState("ALL");
  const [hiddenBoxes, setHiddenBoxes] = useState(() => {
    const getHiddenBoxes = JSON.parse(localStorage.getItem("@hidden_boxes"));

    if (getHiddenBoxes) {
      return getHiddenBoxes;
    }

    return [];
  });

  const transformActions = useCallback((actions) => {
    return actions.map((action) => ({
      ...action,
      avatarBlobId: action.responsible?.avatarBlobId,
      responsibleName: action.responsible?.name,
      insightId: action.insight?.id,
      insightName: action.insight?.name,
      endDate: action.dates?.end,
      actionName: action.action,
      dateId: action.dates?.id,
      startDate: action.dates?.start,
      conclusion: action.dates?.conclusion,
      rescheduledDate: action.dates?.rescheduled,
      toolName: action.tool?.name,
    }));
  }, []);

  useEffect(() => {
    const newActionPlans = actionPlans?.map((actionPlan) => ({
      ...actionPlan,
      name: actionPlan.name,
      avatarBlobId: actionPlan.avatarBlobId,
      allActions: actionPlan.actions,
      actions: transformActions(actionPlan.actions),
    }));

    const ord1 = order === "asc" ? 1 : -1;
    const ord2 = order === "asc" ? -1 : 1;

    const orderedActionsPlans = newActionPlans.map((actionPlan) => {
      return {
        ...actionPlan,
        actions: actionPlan.actions.sort((a, b) => {
          if (orderBy && actionPlan.id === selectedId) {
            return a[orderBy] > b[orderBy] ? ord1 : ord2;
          }

          return 0;
        }),
      };
    });

    const filteredActionPlans = orderedActionsPlans.map((actionPlan) => {
      if (filteredActions && filteredActions !== "ALL") {
        return {
          ...actionPlan,
          actions: actionPlan.actions.filter(
            (action) => getActionPerformance(action).status === filteredActions,
          ),
        };
      }
      return { ...actionPlan };
    });

    setOrderedActionPlans(filteredActionPlans);
  }, [
    actionPlans,
    orderBy,
    order,
    selectedId,
    filteredActions,
    transformActions,
  ]);

  useEffect(() => {
    localStorage.setItem("@hidden_boxes", JSON.stringify(hiddenBoxes));
  }, [hiddenBoxes]);

  const handleChangeOrderBy = useCallback(
    (name, id) => {
      const isAsc = orderBy === name && order === "asc";

      setOrder(isAsc ? "desc" : "asc");
      setSelectedId(id);
      setOrderBy(name);
    },
    [order, orderBy],
  );

  const transformDate = useCallback((date) => {
    return date ? moment(date).format("DD/MM/YYYY") : "-";
  }, []);

  const toggleEditDialog = useCallback(
    (action, actionPlan, insightId) => {
      setDialogOptions({
        open: !dialogOptions.open,
        action,
        actionPlan,
        insightId,
      });
    },
    [dialogOptions.open],
  );

  const handleChangeFiltered = useCallback((event) => {
    const { value } = event.target;

    setFilteredActions(value);
  }, []);

  const handleHiddenBox = (index) => {
    if (!hiddenBoxes.includes(index)) {
      setHiddenBoxes((state) => [...state, index]);
    } else if (hiddenBoxes.includes(index)) {
      setHiddenBoxes(hiddenBoxes.filter((hiddenBox) => hiddenBox !== index));
    }
  };

  const updateActions = async (actions, files, actionId, insightId) => {
    if (insightId) {
      const [updatedAction] = actions.filter(({ id }) => actionId === id);

      if (updatedAction?.id)
        await updateOnlyAction(
          "WORKSPACE_TOOLS",
          insightId,
          updatedAction,
          files,
        );
    }

    setDialogOptions(defaultDialogOptions);
    refetchData();
  };

  const evaluationStatusOptions = getEvaluationStatusOptions();
  const actionStatusOption = getActionStatusOptions();

  return (
    <>
      <BoxSelect>
        <MaterialSingleSelect
          id="filterActionsResponsible"
          label="Selecione o prazo"
          value={filteredActions}
          defaultOption={false}
          options={[
            { label: "Todos", value: "ALL" },
            { label: "Dentro", value: "WITHIN_DEADLINE" },
            { label: "Fora", value: "OUT" },
          ]}
          onChange={handleChangeFiltered}
        />
      </BoxSelect>
      <Registration
        open={dialogOptions.open}
        cancelRegistration={toggleEditDialog}
        actions={dialogOptions.actionPlan?.allActions}
        insightId={dialogOptions.insightId}
        updateActions={updateActions}
        companyMembersAndParticipants={companyMembersAndParticipants}
        editingForm={{
          isEditing: true,
          params: { actionId: dialogOptions.action?.id },
        }}
        toolCustomLabels={getToolCustomLabels()}
      />
      {orderedActionPlans.map((actionPlan, index) => {
        const { id, name, avatarBlobId, actions } = actionPlan;

        const { COMPLETE = [] } = getActionsByStatus(actions);

        const calcPercentageActions = ruleOfThree(
          actions.length,
          COMPLETE.length,
        );

        return (
          <Container key={id}>
            <BoxResponsibleInfo>
              <Avatar
                src={avatarBlobId}
                name={name}
                style={{ width: "60px", height: "60px" }}
              />
              <span>{actions.length}</span>
              <ContentReponsibleInfo>
                <span style={{ fontWeight: 500 }}>{name}</span>
                <SimplePercentageLine
                  percentage={calcPercentageActions}
                  backgroundColor="#797789"
                  overrideColor="#6a68d5"
                />
              </ContentReponsibleInfo>
              <i
                className="fas fa-angle-up"
                onClick={() => handleHiddenBox(index)}
                style={
                  hiddenBoxes.includes(index)
                    ? {
                        transform: "rotate(180deg)",
                      }
                    : {}
                }
              />
            </BoxResponsibleInfo>
            {!hiddenBoxes.includes(index) && (
              <Table className="simple-table">
                <thead>
                  <tr>
                    <td width="280">
                      <Icon
                        className="fas fa-arrow-up"
                        onClick={() => handleChangeOrderBy("insightName", id)}
                        isDesc={
                          order === "desc" &&
                          orderBy === "insightName" &&
                          selectedId === id
                        }
                      />
                      Insight
                    </td>
                    <td width="280">
                      <Icon
                        className="fas fa-arrow-up"
                        onClick={() => handleChangeOrderBy("actionName", id)}
                        isDesc={
                          order === "desc" &&
                          orderBy === "actionName" &&
                          selectedId === id
                        }
                      />
                      Plano de ação
                    </td>
                    <td>Ferramenta</td>
                    <td width="260">Rastreabilidade</td>
                    <td width="100">Ínicio</td>
                    <td width="100">Fim</td>
                    <td width="100">Conclusão</td>
                    <td width="120">
                      <Icon
                        className="fas fa-arrow-up"
                        onClick={() =>
                          handleChangeOrderBy("rescheduledDate", id)
                        }
                        isDesc={
                          order === "desc" &&
                          orderBy === "rescheduledDate" &&
                          selectedId === id
                        }
                      />
                      Nova Data
                    </td>
                    <td width="125">
                      <Icon
                        className="fas fa-arrow-up"
                        onClick={() => handleChangeOrderBy("status", id)}
                        isDesc={
                          order === "desc" &&
                          orderBy === "status" &&
                          selectedId === id
                        }
                      />
                      Status
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {actions.map((action) => {
                    const { insightId } = action;
                    const actionStartDate = transformDate(action.startDate);
                    const actionEnDate = transformDate(action.endDate);
                    const actionRescheduled = transformDate(
                      action.rescheduledDate,
                    );
                    const actionConclusion = transformDate(action.conclusion);

                    const actionPerformance = getActionPerformance(action);

                    const colorStatusActions = getObjectInfoById(
                      actionPerformance.status,
                      evaluationStatusOptions,
                      "value",
                    ).color;

                    const { traceability } = action || {};
                    const { cenary = {}, question = {} } = traceability || {};
                    const { name: scenerieName = "" } = cenary || {};
                    const { name: questionName = "" } = question || {};

                    const translatedScenerieName = customCenaryName(
                      scenerieName,
                    );
                    const translatedQuestionName = translateQuestionName(
                      questionName,
                    );

                    return (
                      <tr
                        key={action.id}
                        onClick={() =>
                          toggleEditDialog(action, actionPlan, insightId)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <td>{action.insightName}</td>
                        <td
                          style={{
                            borderLeft: `4px solid ${colorStatusActions}`,
                          }}
                        >
                          {action.actionName}
                        </td>
                        <TdTool>
                          <div>
                            <i className={`fas ${action.icon}`} />
                            {action.toolName}
                          </div>
                        </TdTool>
                        <TdTracebility>{`${translatedScenerieName} > ${translatedQuestionName} > ${action.traceability?.theme?.name}`}</TdTracebility>
                        <TdStyled>{actionStartDate}</TdStyled>
                        <TdStyled>{actionEnDate}</TdStyled>
                        <TdStyled>{actionConclusion}</TdStyled>
                        <TdStyled>{actionRescheduled}</TdStyled>
                        <td align="center">
                          {
                            getObjectInfoById(
                              action.status,
                              actionStatusOption,
                              "value",
                            ).label
                          }
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Container>
        );
      })}
    </>
  );
};

export default ResponsibleView;

ResponsibleView.propTypes = {
  actionPlans: PropTypes.array.isRequired,
};
