import { FETCH_TRANSLATIONS } from "./types";

import enData from "../i18n/en.json";
import esData from "../i18n/es.json";
import ptData from "../i18n/pt.json";
import aditionalTranslations from "../i18n";

export const readTranslations = (lang) => {
  let msgs = enData;

  let enFinalTranslations = { ...enData };
  let esFinalTranslations = { ...esData };
  let ptFinalTranslations = { ...ptData };

  aditionalTranslations.forEach((translationPack) => {
    translationPack.forEach((messages) => {
      if (messages.en)
        enFinalTranslations = { ...enFinalTranslations, ...messages.en };
      if (messages.es)
        esFinalTranslations = { ...esFinalTranslations, ...messages.es };
      if (messages.pt)
        ptFinalTranslations = { ...ptFinalTranslations, ...messages.pt };
    });
  });

  if (lang) {
    const languageWithoutRegionCode = lang.toLowerCase().split(/[_-]+/)[0];
    switch (languageWithoutRegionCode) {
      case "en":
        msgs = enFinalTranslations;
        break;
      case "es":
        msgs = esFinalTranslations;
        break;
      case "pt":
        msgs = ptFinalTranslations;
        break;
      case "pt-BR":
        msgs = ptFinalTranslations;
        break;
      default:
        msgs = enFinalTranslations;
        break;
    }
  }

  return msgs;
};

export const fetchTranslations = (lang) => {
  return { type: FETCH_TRANSLATIONS, payload: readTranslations(lang) };
};
