import React from "react";
import SimpleExcelCollumn from "./SimpleExcelCollumn";
import NumberFormatCustom from "./NumberFormatCustom";
import { getValidFunc } from "../../utils/validations";

const ExcelNumberFormat = props => {
  let {
    id,
    value,
    align = "right",
    textEndAdorment = null,
    tdStyle = {},
    inputStyle = {},
    title = null,
    colSpan = "1",
    primaryInputProps = {},
    label = "",
    formatOptions = {}
  } = props;

  const onChange = getValidFunc(props.onChange);
  const onClick = getValidFunc(props.onClick);

  return (
    <SimpleExcelCollumn
      id={id}
      type="text"
      title={title}
      value={value}
      align={align}
      textEndAdorment={textEndAdorment}
      tdStyle={tdStyle}
      inputStyle={inputStyle}
      label={label}
      colSpan={colSpan}
      primaryInputProps={{
        ...primaryInputProps,
        inputComponent: NumberFormatCustom
      }}
      secondaryInputProps={formatOptions}
      onChange={onChange}
      onClick={onClick}
    />
  );
};

export default ExcelNumberFormat;
