import React from "react";
import PropTypes from "prop-types";
import SimpleCheckbox from "../../../../../../Common/SimpleCheckbox";

const TableEvaluationConfig = ({
  evaluationConfig,
  updateEvaluationConfig,
}) => {
  const { keySeat, succession, performance } = evaluationConfig;

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="100">CONFIGURAÇÕES COMPLEMENTARES DA AVALIAÇÃO</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td width="35">
            <SimpleCheckbox
              checked={keySeat || false}
              onChange={() => updateEvaluationConfig("keySeat", !keySeat)}
            />
          </td>
          <td>Avaliação da Criticidade da Função e Posição</td>
        </tr>
        <tr>
          <td width="35">
            <SimpleCheckbox
              checked={succession || false}
              onChange={() => updateEvaluationConfig("succession", !succession)}
            />
          </td>
          <td>Avaliação de Sucessão</td>
        </tr>
        <tr>
          <td width="35">
            <SimpleCheckbox
              checked={performance || false}
              onChange={() =>
                updateEvaluationConfig("performance", !performance)
              }
            />
          </td>
          <td>Avaliação de Desempenho</td>
        </tr>
      </tbody>
    </table>
  );
};

TableEvaluationConfig.propTypes = {
  evaluationConfig: PropTypes.object.isRequired,
  updateEvaluationConfig: PropTypes.func.isRequired,
};

export default TableEvaluationConfig;
