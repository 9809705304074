import React, { useState, useEffect, useCallback } from "react";
import { defineMessages } from "react-intl";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormDialog from "../FormDialog";
import MaterialTextField from "../MaterialTextField";
import MaterialSingleSelect from "../MaterialSingleSelect";
import SelectMemberModal from "../SelectMemberModal";
import {
  getActionStatusOptions,
  getEvaluationStatusOptions,
} from "../../../constants/actionsPlan";

import {
  deleteAttachment,
  getAttachments,
} from "../../../actions/attachmentActions";

import utils from "../../../utils/toolUtils";
import actionsPlanUtils from "../../../utils/actionsPlan";
import Upload from "../Upload";
import { getFormActionsPlan } from "../../../utils/actionsPlan/entity";

const { getActionPerformance } = actionsPlanUtils;

const {
  getValidFunc,
  translatedText,
  getObjectInfoById,
  getMemberInfoById,
  getErrorMessage,
} = utils;

const messages = defineMessages({
  global_selectSome: { id: "global.selectSome" },
  acp_error_invalid_generic_field: { id: "acp_error_invalid_generic_field" },
  acp_error_invalid_responsible: { id: "acp_error_invalid_responsible" },
  acp_error_invalid_start: { id: "acp_error_invalid_start" },
  acp_error_invalid_end: { id: "acp_error_invalid_end" },
  acp_error_invalid_conclusion: { id: "acp_error_invalid_conclusion" },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

const defaultPlans = getFormActionsPlan({ actions: [{}] });
const defaultForm = defaultPlans.actions[0];

const Registration = (props) => {
  const [form, setForm] = useState(defaultForm);
  const [errors, setErrors] = useState([]);
  const [memberModal, setMemberModal] = useState(false);
  const [replaceSelectedMembers, setReplaceSelectedMembers] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [savedFiles, setSavedFiles] = useState([]);

  const {
    actions = [],
    editingForm = {},
    open = false,
    companyMembersAndParticipants = [],
    toolCustomLabels = {},
    insightId,
    deleteAttachment,
  } = props;

  const updateActions = getValidFunc(props.updateActions);
  const handleRegistration = getValidFunc(props.handleRegistration);
  const cancelRegistration = getValidFunc(props.cancelRegistration);

  const { isEditing } = editingForm;
  const responsableId = form?.responsible?.id || null;

  useEffect(() => {
    if (!open) setForm(defaultForm);
  }, [open]);

  const getEditingIndex = useCallback(() => {
    return getObjectInfoById(editingForm.params.actionId, actions, "id", true);
  }, [actions, editingForm]);

  useEffect(() => {
    if (actions && isEditing) {
      const editingActionIndex = getEditingIndex();
      const { actions: newActions = [] } = getFormActionsPlan({
        actions: [actions[editingActionIndex]],
      });

      if (newActions[0] && newActions[0].id) {
        setForm({ ...newActions[0], insightId });
        setReplaceSelectedMembers(true);
      }
    }
  }, [actions, editingForm, isEditing, getEditingIndex]);

  useEffect(() => {
    if (editingForm.params && editingForm.params.actionId) {
      getAttachments({
        entityId: editingForm.params.actionId,
        entityType: "ACTION_PLAN",
      }).then((attachments) => {
        setAttachments(attachments);
      });
    }
  }, [editingForm]);

  const getFormErrors = () => {
    const errors = [];

    const addError = (slug, message) => {
      errors.push({ slug, message });
    };

    if (form.period.length < 2) {
      addError(
        "invalidPeriod",
        translation("acp_error_invalid_generic_field", {
          field: toolCustomLabels.acp_table_text_period,
        }),
      );
    }

    if (form.deviation.length < 2) {
      addError(
        "invalidDeviation",
        translation("acp_error_invalid_generic_field", {
          field: toolCustomLabels.acp_table_text_deviation,
        }),
      );
    }

    if (form.cause.length < 2) {
      addError(
        "invalidCause",
        translation("acp_error_invalid_generic_field", {
          field: toolCustomLabels.acp_table_text_cause,
        }),
      );
    }

    if (form.action.length < 2) {
      addError(
        "invalidAction",
        translation("acp_error_invalid_generic_field", {
          field: toolCustomLabels.acp_table_text_action,
        }),
      );
    }

    if (!responsableId) {
      addError(
        "invalidResponsible",
        translation("acp_error_invalid_responsible"),
      );
    }

    if (!form.dates.start) {
      addError("invalidStart", translation("acp_error_invalid_start"));
    }

    if (!form.dates.end) {
      addError("invalidEnd", translation("acp_error_invalid_end"));
    }

    if (form.status === "COMPLETE" && !form.dates.conclusion) {
      addError(
        "invalidConclusion",
        translation("acp_error_invalid_conclusion"),
      );
    }

    setErrors(errors);
    return errors;
  };

  const saveNew = () => {
    const haveActions = actions.length > 0;

    const saveAction = {
      ...form,
      order: haveActions ? actions.length - 1 : 0,
    };

    handleRegistration(saveAction, savedFiles, insightId);
    setSavedFiles([]);
  };

  const closeModal = () => {
    setSavedFiles([]);
    cancelRegistration();
  };

  const editSave = () => {
    const editingActionIndex = getEditingIndex();
    const updatedActions = [...actions];
    updatedActions[editingActionIndex] = form;

    updateActions(updatedActions, savedFiles, form.id, insightId);
    setSavedFiles([]);
  };

  const handleSave = () => {
    const formErrors = getFormErrors();

    if (!formErrors.length) {
      if (!isEditing) saveNew();
      if (isEditing) editSave();
    }
  };

  const handleUpdateForm = (fieldSlug, newValue) => {
    setForm({ ...form, [fieldSlug]: newValue });

    if (errors.length) {
      setErrors([]);
    }
  };

  const handleUpdateSubField = (parentSlug, childSlug, newValue) => {
    const parentField = form[parentSlug];

    setForm({
      ...form,
      [parentSlug]: {
        ...parentField,
        [childSlug]: newValue,
      },
    });
  };

  const handleUpdateResponsible = (newValue) => {
    setForm({ ...form, responsible: newValue, responsibleType: newValue.type });
    setMemberModal(false);

    if (errors.length) {
      setErrors([]);
    }
  };

  const handleUpdateDates = (dateSlug, newValue) => {
    handleUpdateSubField("dates", dateSlug, newValue);
  };

  const handleOpenMemberModal = () => {
    setMemberModal(true);
    setSavedFiles([]);

    if (responsableId > -1) setReplaceSelectedMembers(true);
  };

  const getMemberName = (memberId) => {
    const memberInfo = getMemberInfoById(
      memberId,
      companyMembersAndParticipants,
    );

    return memberInfo && memberInfo.name
      ? memberInfo.name
      : translation("global_selectSome");
  };

  const onAddAttachments = useCallback((bodyAttachments, files) => {
    setSavedFiles(files);
  }, []);

  const onDeleteAttachments = useCallback(
    (fileId) => {
      setSavedFiles(savedFiles.filter((file) => file.id !== fileId));

      if (!isNaN(fileId)) {
        deleteAttachment(fileId, insightId);
      }
    },
    [deleteAttachment, savedFiles, insightId],
  );

  const actionStatusOptions = getActionStatusOptions();
  const evaluationStatusOptions = getEvaluationStatusOptions(true);
  const actionPerformance = getActionPerformance(form);

  const formTitle = !isEditing ? "Cadastrar nova Ação" : "Editar ação";

  return (
    <>
      {memberModal && (
        <SelectMemberModal
          open={memberModal}
          title="Selecione um Responsável"
          membersList={companyMembersAndParticipants}
          onConfirm={(selectedIds, list) => handleUpdateResponsible(list[0])}
          onCancel={() => setMemberModal(false)}
          singleSelect
          initialSelected={responsableId ? [responsableId] : []}
          replaceSelected={replaceSelectedMembers}
          replaceCallback={() => setReplaceSelectedMembers(false)}
        />
      )}
      <FormDialog
        title={formTitle}
        open={open}
        onConfirm={handleSave}
        onCancel={closeModal}
        blockConfirm={errors.length > 0}
      >
        <div className="row">
          <div className="col-xs-6">
            <MaterialTextField
              id="period"
              label={toolCustomLabels.acp_table_text_period}
              value={form.period}
              onChange={(e) => handleUpdateForm("period", e.target.value)}
            />
            {getErrorMessage(["invalidPeriod"], errors)}
          </div>
          <div className="col-xs-6">
            <MaterialTextField
              id="deviation"
              label={toolCustomLabels.acp_table_text_deviation}
              value={form.deviation}
              onChange={(e) => handleUpdateForm("deviation", e.target.value)}
            />
            {getErrorMessage(["invalidDeviation"], errors)}
          </div>
          <div className="col-xs-12">
            <MaterialTextField
              id="cause"
              label={toolCustomLabels.acp_table_text_cause}
              value={form.cause}
              onChange={(e) => handleUpdateForm("cause", e.target.value)}
            />
            {getErrorMessage(["invalidCause"], errors)}
          </div>
          <div className="col-xs-12">
            <MaterialTextField
              id="action"
              label={toolCustomLabels.acp_table_text_action}
              value={form.action}
              onChange={(e) => handleUpdateForm("action", e.target.value)}
              primaryInputProps={{ maxLength: 255 }}
              multiline
              rows={3}
            />
            {getErrorMessage(["invalidAction"], errors)}
          </div>
          <div className="col-xs-12">
            <MaterialTextField
              id="responsible"
              label={toolCustomLabels.acp_table_text_responsible}
              value={getMemberName(responsableId)}
              inputStyle={{ cursor: "pointer" }}
              onClick={() => handleOpenMemberModal()}
            />
            {getErrorMessage(["invalidResponsible"], errors)}
          </div>
          <div className="col-xs-4">
            <MaterialTextField
              id="startDate"
              label={toolCustomLabels.acp_table_text_start}
              type="date"
              value={form.dates.start}
              onChange={(e) => handleUpdateDates("start", e.target.value)}
              inputStyle={{ fontSize: "14px" }}
            />
          </div>
          <div className="col-xs-4">
            <MaterialTextField
              id="endDate"
              label={toolCustomLabels.acp_table_text_end}
              type="date"
              value={form.dates.end}
              onChange={(e) => handleUpdateDates("end", e.target.value)}
              inputStyle={{ fontSize: "14px" }}
            />
          </div>
          <div className="col-xs-4">
            <MaterialTextField
              id="rescheduledDate"
              label={toolCustomLabels.acp_table_text_new_date}
              type="date"
              value={form.dates.rescheduled}
              onChange={(e) => handleUpdateDates("rescheduled", e.target.value)}
              inputStyle={{ fontSize: "14px" }}
            />
          </div>
          <div className="col-xs-12">
            {getErrorMessage(["invalidStart"], errors, "left", {}, true)}
            {getErrorMessage(["invalidEnd"], errors, "left", {}, true)}
          </div>
          <div className="col-xs-4">
            <MaterialTextField
              id="conclusionDate"
              label={toolCustomLabels.acp_table_text_conclusion}
              type="date"
              value={form.dates.conclusion}
              onChange={(e) => handleUpdateDates("conclusion", e.target.value)}
              inputStyle={{ fontSize: "14px" }}
            />
          </div>
          <div className="col-xs-4">
            <MaterialSingleSelect
              id="status"
              label={toolCustomLabels.acp_table_text_status}
              options={actionStatusOptions}
              value={form.status}
              onChange={(e) => handleUpdateForm("status", e.target.value)}
              defaultOption={false}
            />
          </div>
          <div className="col-xs-4">
            <b style={{ lineHeight: "64px" }}>
              {
                getObjectInfoById(
                  actionPerformance.status,
                  evaluationStatusOptions,
                  "value",
                ).label
              }
            </b>
          </div>
          <div className="col-xs-12">
            {getErrorMessage(["invalidConclusion"], errors)}
          </div>
          <div className="col-xs-12">
            <MaterialTextField
              id="followUp"
              label={toolCustomLabels.acp_table_text_monitoring}
              value={form.followUp}
              primaryInputProps={{ maxLength: 255 }}
              onChange={(e) => handleUpdateForm("followUp", e.target.value)}
              multiline
              rows={3}
            />
            {getErrorMessage(["invalidCause"], errors)}
          </div>
          <div className="col-xs-12">
            <div
              style={{
                border: "1px solid #ccc",
                padding: "10.5px 70px",
                borderRadius: "3px",
              }}
            >
              <Upload
                onAdd={onAddAttachments}
                onDelete={onDeleteAttachments}
                attachments={attachments}
              />
            </div>
          </div>
        </div>
      </FormDialog>
    </>
  );
};

export default connect(null, { deleteAttachment })(Registration);

Registration.propTypes = {
  open: PropTypes.bool.isRequired,
  cancelRegistration: PropTypes.func,
};

Registration.defaultProps = {
  cancelRegistration: () => null,
};
