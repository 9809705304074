const en = {
  acp_main_title: "Actions Plan",
  acp_main_title_upper: "ACTIONS PLAN",
  acp_status_in_progress: "IN PROGRESS",
  acp_status_concluded: "CONCLUDED",
  acp_status_late: "LATE",
  acp_status_on_time: "ON TIME",
  acp_status_out_of_time: "OUT OF TIME",
  acp_status_within_deadline: "WITHIN THE DEADLINE",
  acp_table_text_plan: "PLAN",
  acp_table_text_progress: "IN PROGRESS",
  acp_table_text_concluded: "CONCLUDED",
  acp_table_text_canceled: "CANCELED",
  acp_table_text_kpis: "KPIs",
  acp_table_text_actions: "Actions",
  acp_table_text_action: "Action",
  acp_table_text_period: "Period",
  acp_table_text_deviation: "Fact",
  acp_table_text_cause: "Cause",
  acp_table_text_time_limit: "Time limit",
  acp_table_text_status: "Status",
  acp_table_text_start: "Start",
  acp_table_text_end: "End",
  acp_table_text_new_date: "New date",
  acp_table_text_conclusion: "Conclusion",
  acp_table_text_responsible: "Responsible",
  acp_table_text_traceability: "Traceability",
  acp_table_text_monitoring: "Monitoring",
  acp_text_show_details: "Show details",
  acp_text_hide_details: "Hide",
  acp_text_show_actions_plan: "Show Actions Plan",
  acp_filter_status_all: "ALL",
  acp_filter_status_within_deadline: "WITHIN DEADLINE",
  acp_filter_status_out: "OUT",
  acp_options_not_started: "Not started",
  acp_options_in_progress: "In progress",
  acp_options_complete: "Complete",
  acp_options_canceled: "Canceled",
  acp_options_short_out: "Out",
  acp_options_long_out: "Out of time",
  acp_options_short_within: "Within",
  acp_options_long_within: "Within the deadline",
  acp_confirm_delete_action: "Are you sure you want to delete this action?",
  acp_error_invalid_generic_field:
    "The {field} field must contain more characters",
  acp_error_invalid_period: "The Period field must contain more characters",
  acp_error_invalid_deviation: "The Fact field must contain more characters",
  acp_error_invalid_cause: "The Cause field must contain more characters",
  acp_error_invalid_action: "The Action field must contain more characters",
  acp_error_invalid_responsible: "Select a responsible",
  acp_error_invalid_start: "Add a Start Date",
  acp_error_invalid_end: "Add an End Date",
  acp_error_invalid_conclusion: "Add a Conclusion Date",
  acp_bp_tool_table_title: "Actions by Activity",
  acp_bp_tool_table_head_insight_label: "Activity",
  acp_bp_table_text_period: "Analysis Period",
  acp_bp_table_text_deviation: "Maturity Criteria",
  acp_bp_table_text_action: "Improvement Action",
  acp_swot_insight_label: "Evaluations",
  acp_swot_tool_table_title: "Actions per Evaluations",
  acp_swot_tool_table_head_insight_label: "Evaluation",
  acp_okr_insight_label: "Key Results",
  acp_okr_tool_table_title: "Actions per Key Result",
  acp_okr_tool_table_head_insight_label: "Key Result",
  acp_onp_insight_label: "Professionals",
  acp_onp_tool_table_title: "Actions per Professionals",
  acp_onp_tool_table_head_insight_label: "Professional",
  acp_onp_table_text_period: "Evaluation Period",
  acp_onp_table_text_deviation: "Theme",
  acp_onp_table_text_action: "PDP - Action",
};

const es = {
  acp_main_title: "Plan de Acciones",
  acp_main_title_upper: "PLAN DE ACCIONES",
  acp_status_in_progress: "EN PROCESO",
  acp_status_concluded: "HECHO",
  acp_status_late: "EN RETRASO",
  acp_status_on_time: "EN EL PLAZO",
  acp_status_out_of_time: "FUERA DE TIEMPO",
  acp_status_within_deadline: "DENTRO DEL TIEMPO",
  acp_table_text_plan: "PLAN",
  acp_table_text_progress: "EN PROCESO",
  acp_table_text_concluded: "HECHO",
  acp_table_text_canceled: "CANCELADO",
  acp_table_text_kpis: "KPIs",
  acp_table_text_actions: "Acciones",
  acp_table_text_action: "Acción",
  acp_table_text_period: "Período",
  acp_table_text_deviation: "Hecho",
  acp_table_text_cause: "Causa",
  acp_table_text_time_limit: "Límite de tiempo",
  acp_table_text_status: "Estado",
  acp_table_text_start: "Inicio",
  acp_table_text_end: "Final",
  acp_table_text_new_date: "Nueva fecha",
  acp_table_text_conclusion: "Conclusión",
  acp_table_text_responsible: "Responsable",
  acp_table_text_traceability: "Trazabilidad",
  acp_table_text_monitoring: "Supervisión",
  acp_text_show_details: "Mostrar detalles",
  acp_text_hide_details: "Esconder",
  acp_text_show_actions_plan: "Mostrar Plan de Acciones",
  acp_filter_status_all: "TODOS",
  acp_filter_status_within_deadline: "DENTRO",
  acp_filter_status_out: "FUERA",
  acp_options_not_started: "No iniciado",
  acp_options_in_progress: "En progresso",
  acp_options_complete: "Completo",
  acp_options_canceled: "Cancelado",
  acp_options_short_out: "Fuera",
  acp_options_long_out: "Fuera de tiempo",
  acp_options_short_within: "Dentro",
  acp_options_long_within: "Dentro del tiempo",
  acp_confirm_delete_action:
    "¿Estás seguro de que deseas eliminar esta acción?",
  acp_error_invalid_generic_field: "El campo {} debe contener más caracteres",
  acp_error_invalid_period: "El campo Período debe contener más caracteres",
  acp_error_invalid_deviation: "El campo Hecho debe contener más caracteres",
  acp_error_invalid_cause: "El campo Causa debe contener más caracteres",
  acp_error_invalid_action: "El campo Acción debe contener más caracteres",
  acp_error_invalid_responsible: "Seleccione un responsable",
  acp_error_invalid_start: "Agregar una fecha de inicio",
  acp_error_invalid_end: "Agregar una fecha de finalización",
  acp_error_invalid_conclusion: "Agregar una fecha de conclusión",
  acp_bp_tool_table_title: "Acciones de Atividad",
  acp_bp_tool_table_head_insight_label: "Atividad",
  acp_bp_table_text_period: "Período de Análisis",
  acp_bp_table_text_deviation: "Criterio de Madurez",
  acp_bp_table_text_action: "Acción de mejora",
  acp_swot_insight_label: "Evaluaciones",
  acp_swot_tool_table_title: "Acciones por Evaluación",
  acp_swot_tool_table_head_insight_label: "Evaluación",
  acp_okr_insight_label: "Resultados clave",
  acp_okr_tool_table_title: "Acciones por Resultado clave",
  acp_okr_tool_table_head_insight_label: "Resultado clave",
  acp_onp_insight_label: "Profesionales",
  acp_onp_tool_table_title: "Acciones por Profesionales",
  acp_onp_tool_table_head_insight_label: "Profesional",
  acp_onp_table_text_period: "Período de Evaluación",
  acp_onp_table_text_deviation: "Tema",
  acp_onp_table_text_action: "PDP - Acción",
};

const pt = {
  acp_main_title: "Central de Ações",
  acp_main_title_upper: "PLANO DE AÇÕES",
  acp_status_in_progress: "EM ANDAMENTO",
  acp_status_concluded: "CONCLUÍDO",
  acp_status_late: "EM ATRASO",
  acp_status_on_time: "NO PRAZO",
  acp_status_out_of_time: "FORA DO PRAZO",
  acp_status_within_deadline: "DENTRO DO PRAZO",
  acp_table_text_plan: "PLANO",
  acp_table_text_progress: "ANDAMENTO",
  acp_table_text_concluded: "CONCLUÍDO",
  acp_table_text_canceled: "CANCELADO",
  acp_table_text_kpis: "KPIs",
  acp_table_text_actions: "Ações",
  acp_table_text_action: "Ação",
  acp_table_text_period: "Período",
  acp_table_text_deviation: "Fato",
  acp_table_text_cause: "Causa",
  acp_table_text_time_limit: "Prazo",
  acp_table_text_status: "Status",
  acp_table_text_start: "Início",
  acp_table_text_end: "Fim",
  acp_table_text_new_date: "Nova data",
  acp_table_text_conclusion: "Conclusão",
  acp_table_text_responsible: "Responsável",
  acp_table_text_traceability: "Rastreabilidade",
  acp_table_text_monitoring: "Acompanhamento",
  acp_text_show_details: "Mostrar detalhes",
  acp_text_hide_details: "Esconder",
  acp_text_show_actions_plan: "Mostrar Plano de Ações",
  acp_filter_status_all: "TODOS",
  acp_filter_status_within_deadline: "DENTRO",
  acp_filter_status_out: "FORA",
  acp_options_not_started: "Não iniciado",
  acp_options_in_progress: "Em progresso",
  acp_options_complete: "Completo",
  acp_options_canceled: "Cancelado",
  acp_options_short_out: "Fora",
  acp_options_long_out: "Fora do prazo",
  acp_options_short_within: "Dentro",
  acp_options_long_within: "Dentro do prazo",
  acp_confirm_delete_action: "Tem certeza que deseja excluir esta ação?",
  acp_error_invalid_generic_field:
    "O campo {field} deve conter mais caracteres",
  acp_error_invalid_period: "O campo Período deve conter mais caracteres",
  acp_error_invalid_deviation: "O campo Fato deve conter mais caracteres",
  acp_error_invalid_cause: "O campo Causa deve conter mais caracteres",
  acp_error_invalid_action: "O campo Ação deve conter mais caracteres",
  acp_error_invalid_responsible: "Selecione um responsável",
  acp_error_invalid_start: "Adicione uma Data de Início",
  acp_error_invalid_end: "Adicione uma Data de Fim",
  acp_error_invalid_conclusion: "Adicione uma Data de Conclusão",
  acp_bp_tool_table_title: "Ações por Atividade",
  acp_bp_tool_table_head_insight_label: "Atividade",
  acp_bp_table_text_period: "Período Análise",
  acp_bp_table_text_deviation: "Critério de Maturidade",
  acp_bp_table_text_action: "Ação de melhoria",
  acp_swot_insight_label: "Avaliações",
  acp_swot_tool_table_title: "Ações por Avaliação",
  acp_swot_tool_table_head_insight_label: "Avaliação",
  acp_okr_insight_label: "Resultados Chave",
  acp_okr_tool_table_title: "Ações por Resultado Chave",
  acp_okr_tool_table_head_insight_label: "Resultado Chave",
  acp_onp_insight_label: "Profissionais",
  acp_onp_tool_table_title: "Ações por Profissional",
  acp_onp_tool_table_head_insight_label: "Profissional",
  acp_onp_table_text_period: "Período Avaliação",
  acp_onp_table_text_deviation: "Tema",
  acp_onp_table_text_action: "PDI - Ação",
};

const translations = { en, es, pt };

export default translations;
