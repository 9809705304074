import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Row, Col, Button } from "react-bootstrap";
import { defineMessages } from "react-intl";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import MaterialTextField from "../MaterialTextField";
import SelectMemberModal from "../SelectMemberModal";
import { translatedText } from "../../../utils/translationUtils";
import { getHeaderSlugs } from "../../../utils/accessLevels";
import FormDialog from "../FormDialog";
import { emailValidation } from "../../../utils/formValidation";

import { AlertError } from "./styles";
import {
  getAllUsersFromDomain,
  sendEnviteEmailFrom,
} from "../../../actions/accessLevelsActions";
import { simpleNotifier } from "../../../actions/notifierActions";

const messages = defineMessages({
  accessLevels_actions: {
    id: "accessLevels_actions",
  },
  accessLevels_search: {
    id: "accessLevels_search",
  },
  accessLevels_include: {
    id: "accessLevels_include",
  },
  accessLevels_new_owner: {
    id: "accessLevels_new_owner",
  },
  noDataToDisplay: {
    id: "global.noData",
  },
});

const translation = (id, values) => translatedText(id, messages, values);

const INewUserProps = {
  name: "",
  email: "",
};

const GenericTable = (props) => {
  const {
    headers,
    data,
    onAdd,
    onDelete,
    onSearch,
    memberModalTitle,
    memberModalData = [],
    searchUsersAction,
    customActions,
    showActionButtons = true,
    showTopHeader = true,
    title,
    emptyDataMessage = false,
    customActionsFirst = false,
    isAddNewUser,
    domainId,
    getAllUsersFromDomain,
    simpleNotifier,
  } = props;

  const [showNewMemberModal, setShowNewMemberModal] = useState(false);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [search, setSearch] = useState("");
  const [newUser, setNewUser] = useState(INewUserProps);
  const [fieldErrors, setFieldErrors] = useState([]);

  const slugs = getHeaderSlugs(headers);

  const displayActionHeader = () => {
    return (
      <TableCell className="actionsTableCell" align="center">
        <h5>
          <strong>{translation("accessLevels_actions")}</strong>
        </h5>
      </TableCell>
    );
  };

  const displayCustomActionHeader = () => {
    return customActions.map(
      ({
        title,
        customClassName = null,
        headStyles = { textAlign: "center" },
      }) => {
        const customClass = customClassName || "actionsTableCell";

        return (
          <TableCell key={uuidv4()} className={customClass} style={headStyles}>
            <h5>
              <strong>{title}</strong>
            </h5>
          </TableCell>
        );
      },
    );
  };

  const renderActionButtons = (line) => {
    return (
      <>
        <Button onClick={() => onDelete(line)}>
          <i className="fas fa-trash-alt" />
        </Button>
      </>
    );
  };

  const displayActionButtons = (line = {}) => {
    return (
      <TableCell className="actionsTableCell" align="center" key={uuidv4()}>
        <div>{renderActionButtons(line)}</div>
      </TableCell>
    );
  };

  const displayCustomActions = (line = {}) => {
    return customActions.map(
      ({
        actions,
        customClassName = null,
        bodyStyles = { textAlign: "center" },
      }) => {
        const customClass = customClassName || "actionsTableCell";

        return (
          <TableCell className={customClass} key={uuidv4()} style={bodyStyles}>
            {actions(line)}
          </TableCell>
        );
      },
    );
  };

  const handleConfirm = (selectedMemberIds = []) => {
    if (onAdd) onAdd(selectedMemberIds);

    setShowNewMemberModal(false);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);

    if (onSearch) onSearch(e.target.value);
  };

  const handleCustomOpenModal = () => {
    if (onAdd) return setShowNewMemberModal(true);

    return setShowNewUserModal(true);
  };

  const displayTableOptions = () => {
    return (
      <div className="ownerTableOptions">
        {(onAdd || isAddNewUser) && (
          <Button
            onClick={handleCustomOpenModal}
            style={{ marginRight: "5px" }}
          >
            <i className="fas fa-user-plus" />
          </Button>
        )}
        {title && title}
        <div>
          <MaterialTextField
            id="searchTextField"
            label={translation("accessLevels_search")}
            value={search}
            onChange={(e) => handleSearch(e)}
            className="genericTableSearchField"
          />
        </div>
      </div>
    );
  };

  const displayTableHeader = () => {
    return (
      <TableHead style={{ borderTop: "1px solid #e1e1e1" }}>
        <TableRow className="customGenericRow">
          {customActionsFirst && customActions && displayCustomActionHeader()}
          {Object.keys(headers).map((header) => {
            return (
              <TableCell
                className="ownerTableCell"
                align="center"
                key={uuidv4()}
              >
                <h5>
                  <strong>{headers[header]}</strong>
                </h5>
              </TableCell>
            );
          })}
          {showActionButtons && displayActionHeader()}
          {!customActionsFirst && customActions && displayCustomActionHeader()}
        </TableRow>
      </TableHead>
    );
  };

  const handleEmptyTable = () => {
    const patternMessage = translation("noDataToDisplay");

    return (
      <TableRow>
        <TableCell align="center">
          <h5>{emptyDataMessage || patternMessage}</h5>
        </TableCell>
      </TableRow>
    );
  };

  const displayData = () => {
    return data.map((row, index) => (
      <TableRow key={uuidv4()} className="customGenericRow">
        {customActionsFirst &&
          customActions &&
          displayCustomActions(data[index])}
        {slugs.map((slug) => {
          return (
            <TableCell className="ownerTableCell" align="center" key={uuidv4()}>
              <h5>{row[slug]}</h5>
            </TableCell>
          );
        })}
        {showActionButtons && displayActionButtons(data[index])}
        {!customActionsFirst &&
          customActions &&
          displayCustomActions(data[index])}
      </TableRow>
    ));
  };

  const emptyTable = !(data.length > 0);

  const displayTableBody = () => {
    return (
      <TableBody>{!emptyTable ? displayData() : handleEmptyTable()}</TableBody>
    );
  };

  const handleNewUser = useCallback((event) => {
    const { name, value } = event.target;

    setNewUser((oldUser) => ({ ...oldUser, [name]: value }));
    setFieldErrors([]);
  }, []);

  const clearFields = useCallback(() => {
    setNewUser(INewUserProps);
    setFieldErrors([]);
  }, []);

  const handleCloseModalNewUser = useCallback(() => {
    setShowNewUserModal(false);

    clearFields();
  }, [clearFields]);

  const validFieldsNewUser = useCallback(() => {
    const fieldErrors = [];
    const isValidEmail = emailValidation(newUser.email);

    if (!newUser.firstName.length && newUser.firstName.length < 3) {
      fieldErrors.push("Digite um nome valido");
    }

    if (!isValidEmail) {
      fieldErrors.push("E-mail inválido");
    }

    if (!fieldErrors.length) return true;

    setFieldErrors(fieldErrors);
    return false;
  }, [newUser]);

  const handleConfirmCreateNewUser = useCallback(() => {
    const isValidFields = validFieldsNewUser();

    const formatedUser = {
      ...newUser,
      fullName: `${newUser.firstName} ${newUser.lastName}`,
    };

    if (isValidFields) {
      handleCloseModalNewUser();
      sendEnviteEmailFrom([formatedUser]);
      simpleNotifier("O convite foi enviado para o email", "success");
      getAllUsersFromDomain(domainId);
    }
  }, [
    handleCloseModalNewUser,
    simpleNotifier,
    validFieldsNewUser,
    newUser,
    domainId,
    getAllUsersFromDomain,
  ]);

  return (
    <>
      <Row style={{ height: "100%" }}>
        <Col md={12} className="fullHeight">
          {showTopHeader && displayTableOptions()}

          <TableContainer className="fullHeight" component={Paper}>
            <Table className="table table-hover" aria-label="simple table">
              {displayTableHeader()}
              {displayTableBody()}
            </Table>
          </TableContainer>
        </Col>
      </Row>
      {showNewMemberModal && (
        <SelectMemberModal
          open={showNewMemberModal}
          title={memberModalTitle}
          onConfirm={(selectedMembers) => handleConfirm(selectedMembers)}
          onCancel={() => setShowNewMemberModal(false)}
          membersList={memberModalData}
          searchUsersAction={searchUsersAction}
        />
      )}
      <FormDialog
        title="Adicione um usuário"
        open={showNewUserModal}
        onCancel={handleCloseModalNewUser}
        onConfirm={handleConfirmCreateNewUser}
      >
        {!!fieldErrors.length && (
          <AlertError severity="error">
            {fieldErrors.map((error) => error).join(", ")}
          </AlertError>
        )}
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, marginRight: "5px" }}>
            <MaterialTextField
              id="name"
              name="firstName"
              label="Nome"
              onChange={handleNewUser}
              value={newUser.firstName}
            />
          </div>
          <div style={{ flex: 1 }}>
            <MaterialTextField
              id="lastname"
              name="lastName"
              label="Sobrenome"
              onChange={handleNewUser}
              value={newUser.lastName}
            />
          </div>
        </div>
        <MaterialTextField
          id="email"
          name="email"
          label="E-mail"
          onChange={handleNewUser}
          value={newUser.email}
        />
      </FormDialog>
    </>
  );
};

export default connect(null, { getAllUsersFromDomain, simpleNotifier })(
  GenericTable,
);

GenericTable.propTypes = {
  headers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  memberModalTitle: PropTypes.string,
  memberModalData: PropTypes.array,
  onAdd: PropTypes.bool,
  isAddNewUser: PropTypes.bool,
  showActionButtons: PropTypes.bool,
  showTopHeader: PropTypes.bool,
  customActionsFirst: PropTypes.bool,
  emptyDataMessage: PropTypes.bool,
  customActions: PropTypes.array.isRequired,
  searchUsersAction: PropTypes.func,
  title: PropTypes.node,
  onSearch: PropTypes.func,
  onDelete: PropTypes.func,
};

GenericTable.defaultProps = {
  onAdd: false,
  isAddNewUser: false,
  showActionButtons: true,
  showTopHeader: true,
  emptyDataMessage: false,
  customActionsFirst: false,
  title: "",
  onDelete: () => null,
  onSearch: null,
  searchUsersAction: null,
  memberModalTitle: translation("accessLevels_new_owner"),
  memberModalData: [],
};
