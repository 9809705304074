import React from "react";
import SimplePercentageLine from "./SimplePercentageLine";
import { ruleOfThree } from "../../utils/MathUtils";

const SimplePercentageTable = (props) => {
  const {
    title = "",
    primaryText = "",
    secondaryText,
    primaryCount = 0,
    secondaryCount = 0,
  } = props;

  const totalCount = primaryCount + secondaryCount;
  const isNULL = totalCount === 0;

  const diffPercentage = ruleOfThree(totalCount, primaryCount);

  return (
    <div>
      <h5 style={{ marginBottom: "0px" }} align="center">
        {`${title} (${totalCount})`}
      </h5>
      <div style={{ width: "100%" }}>
        <table
          className="simple-table actions-plan-evaluation"
          style={{
            border: "1px solid #ccc",
            marginTop: "5px",
            fontSize: "8px",
          }}
        >
          <tbody>
            <tr className="block-hover no-border-td">
              <td>{primaryText}</td>
              <td>{secondaryText}</td>
            </tr>
            <tr className="block-hover no-border-td">
              <td className="evaluation-col">{primaryCount}</td>
              <td className="evaluation-col">{secondaryCount}</td>
            </tr>
            <tr>
              <td colSpan="2" style={{ padding: "0px" }}>
                <SimplePercentageLine
                  percentage={diffPercentage}
                  backgroundColor="#ff6347"
                  overrideColor="#44cf76"
                  isNULL={isNULL}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SimplePercentageTable;
