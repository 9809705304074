/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import { defineMessages } from "react-intl";

import { translatedText } from "../../../../../utils/translationUtils";
import { useWindowSize } from "../../../../../utils/customHooks";
import { getLastPeriodDeviation } from "../../../../../utils/kpis/calc";
import {
  getOrderedTargets,
  getPeriodTargetsByDistribution,
} from "../../../../../utils/kpis/calc2";
import {
  getActualDistribution,
  getPeriodPercentage,
  getPeriodValueColl,
  getApprDistribution,
  getTargetCollumn,
} from "./displayUtils";
import SimpleCommentSection from "../../../SimpleCommentSection";
import { getCommentsByPeriod } from "../../../../../utils/kpis/filter";
import { getPeriodTags } from "../../../../../utils/kpis/defaults";
import { ruleOfThree } from "../../../../../utils/MathUtils";
import {
  displayTargets,
  getPeriodDivisionTitle,
  getPeriodFullInfo,
} from "../../../../../utils/kpis/display";

const messages = defineMessages({
  kpi_table_text_lp_deviation_expn: {
    id: "kpi_table_text_lp_deviation_expn",
  },
  kpi_table_text_current_deviation_expn: {
    id: "kpi_table_text_current_deviation_expn",
  },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

const defaultApprDistr = {
  open: false,
  index: -1,
  value: 0,
  newValue: 0,
  distribution: {},
  newDistribution: {},
};

export const DisplayPeriodEvaluations = ({
  insight,
  goalDescriptions,
  goalPercentage,
  targetsConfig,
  handleUpdatePeriodTarget,
  handleUpdateDistribution,
  handleUpdateLastPeriod,
  blockAllChanges,
  hideDistribution,
  hideLastPeriod,
  hideCurrentDevation,
  IS_CALCULATED,
}) => {
  const [showComments, setShowComments] = useState([]);
  const [apprDistribution, setApprDistr] = useState(defaultApprDistr);
  const [editing, setEditing] = useState({
    actual: false,
    lastPeriod: false,
  });

  const windowSize = useWindowSize();
  const screenWidth = windowSize[0];
  const fontSize = screenWidth < 1400 ? "10px" : "12px";

  const periodsDisplay = [];

  const { target, measurementUnity } = goalDescriptions;

  const {
    actual: actualTargetValues,
    distribution: actualTargetDistributions,
    lastPeriod: lastPeriodValues,
  } = getOrderedTargets(goalDescriptions);
  const lastActual = actualTargetValues[actualTargetValues.length - 1];
  const periodsLen = actualTargetValues.length;
  const insightId = insight?.id || 0;

  const blockIsCalculated = IS_CALCULATED || blockAllChanges;

  function toggleEditing(slug, index) {
    setEditing((currentEditing) => {
      const alreadyEditing = currentEditing[slug] === index;

      return {
        ...currentEditing,
        [slug]: alreadyEditing ? false : index,
      };
    });
  }

  function resetApprDistr() {
    setApprDistr(defaultApprDistr);
  }

  function toggleApprDistrChange(index, value, currentDistr) {
    if (apprDistribution.open && apprDistribution.index === index) {
      return;
    }

    setApprDistr({
      open: true,
      index,
      value,
      newValue: value,
      distribution: currentDistr,
      newDistribution: currentDistr,
    });
  }

  function handleUpdateApprDistr(value) {
    const newValue = Number(value);
    const relativeDistribution = ruleOfThree(
      apprDistribution.value,
      newValue,
      apprDistribution.distribution.value,
    );

    setApprDistr({
      ...apprDistribution,
      newValue,
      newDistribution: {
        ...apprDistribution.distribution,
        value: relativeDistribution,
      },
    });
  }

  function saveNewDistribution() {
    handleUpdateDistribution(
      apprDistribution.distribution.id,
      apprDistribution.newDistribution.value,
      apprDistribution.index,
    );

    resetApprDistr();
  }

  function checkCommentOpen(periodIndex) {
    return showComments.indexOf(periodIndex);
  }

  function openComment(periodIndex) {
    const commentIndex = checkCommentOpen(periodIndex);

    if (commentIndex > -1) {
      const newComments = [...showComments];
      newComments.splice(commentIndex, 1);

      setShowComments(newComments);
      return;
    }

    setShowComments([...showComments, periodIndex]);
  }

  actualTargetValues.forEach(
    ({ id, value: actualPeriodValue, index }, fakeIndex) => {
      const periodTargetsDivision = getPeriodTargetsByDistribution(
        index,
        goalDescriptions,
      );

      const {
        isPeriodOnFullCourse,
        isPeriodOnTraveledCourse,
        isTraveledAndValid,
        hitTargetInfo: periodHitInfo,
      } = getPeriodFullInfo(index, actualPeriodValue, goalDescriptions);

      const periodDistribution = actualTargetDistributions[fakeIndex] || {};
      const periodDistributionValue = periodDistribution?.value;
      const lastPeriodTarget = lastPeriodValues[fakeIndex] || {};
      const lastPeriodTargetValue = lastPeriodTarget?.value;
      const isDisabled = blockIsCalculated || !isPeriodOnTraveledCourse;

      const dynamicClass = !isPeriodOnFullCourse ? "disabled-row" : "";
      const targetTdStyles = {
        backgroundColor: "#fff",
        textAlign: "right",
        width: "120px",
        padding: "0px 10px",
        fontSize,
      };

      const tdStyles = {
        transition: "0.3s",
        fontWeight: "500",
        whiteSpace: "nowrap",
        fontSize,
      };

      const periodTdStyles = isTraveledAndValid
        ? {
            ...tdStyles,
            borderLeft: "3px solid #6b42a9",
          }
        : {
            ...tdStyles,
            borderLeft: isPeriodOnTraveledCourse
              ? "3px solid #999"
              : "1px solid #ccc",
          };

      const isBalanceAndLastIndex =
        goalDescriptions.measurement === "BALANCE" &&
        index === lastActual.index;

      const blockDistributionChange =
        blockAllChanges ||
        goalDescriptions.frequency === "YEARLY" ||
        isBalanceAndLastIndex ||
        (!isPeriodOnFullCourse && goalDescriptions.measurementUnity !== "DATE");

      const { displayDeviation: lastPeriodDeviation, color: lpDeviationColor } =
        getLastPeriodDeviation(
          lastPeriodTargetValue,
          actualPeriodValue,
          target,
        );

      const { displayDeviation: periodDeviation, color: deviationColor } =
        getLastPeriodDeviation(
          periodTargetsDivision.appropriated,
          actualPeriodValue,
          target,
        );

      const commOpen = checkCommentOpen(index) > -1;

      const periodPercentage = getPeriodPercentage(
        goalDescriptions,
        goalPercentage,
        { index, value: actualPeriodValue },
        targetsConfig,
        isTraveledAndValid,
      );

      const apprDistrOpen =
        apprDistribution.open && apprDistribution.index === index;

      periodsDisplay.push(
        <tr
          className={`block-hover small-padding ${dynamicClass}`}
          key={index}
          style={{ fontSize: "12px !important" }}
        >
          <td style={{ ...periodTdStyles, fontSize: "8px" }}>
            {getPeriodDivisionTitle(index, fakeIndex, goalDescriptions)}
          </td>
          {!hideLastPeriod && (
            <>
              <td
                style={{
                  backgroundColor: "#f1f1f1",
                  padding: "0px",
                }}
              >
                {getPeriodValueColl(
                  `lastPeriod${index}`,
                  lastPeriodTargetValue,
                  (newLastPeriod) =>
                    handleUpdateLastPeriod(
                      lastPeriodTarget.id,
                      newLastPeriod,
                      index,
                    ),
                  editing.lastPeriod === index,
                  blockAllChanges,
                  index + 1,
                  measurementUnity,
                  () => toggleEditing("lastPeriod", index),
                  fontSize,
                )}
              </td>
              <td
                style={{
                  color: lpDeviationColor,
                  fontWeight: "500",
                  textAlign: "right",
                }}
                title={translation("kpi_table_text_lp_deviation_expn")}
              >
                {lastPeriodDeviation}
              </td>
            </>
          )}
          <td style={{ ...targetTdStyles }}>
            {displayTargets(
              periodTargetsDivision.gate,
              goalDescriptions.measurementUnity,
              !isPeriodOnFullCourse,
            )}
          </td>
          <td
            style={
              !apprDistrOpen
                ? {
                    ...targetTdStyles,
                    cursor: "pointer",
                    backgroundColor: "#f1f1f1",
                  }
                : {
                    ...targetTdStyles,
                    padding: "0px",
                    backgroundColor: "#f1f1f1",
                  }
            }
            onClick={
              blockDistributionChange
                ? () => {}
                : () =>
                    toggleApprDistrChange(
                      index,
                      periodTargetsDivision.appropriated,
                      periodDistribution,
                    )
            }
            title="Você pode alterar a distribuição dos valores editando o campo Planejado de cada período."
          >
            {!apprDistrOpen &&
              displayTargets(
                periodTargetsDivision.appropriated,
                goalDescriptions.measurementUnity,
                !isPeriodOnFullCourse,
              )}
            {apprDistrOpen &&
              getApprDistribution(
                `apprDistr${index}`,
                apprDistribution.newValue,
                (newVal) => handleUpdateApprDistr(newVal),
                saveNewDistribution,
                resetApprDistr,
                measurementUnity,
                fontSize,
              )}
          </td>
          <td
            style={{
              ...targetTdStyles,
              borderRight: "1px solid #c4c4c4",
            }}
          >
            {displayTargets(
              periodTargetsDivision.exceeded,
              goalDescriptions.measurementUnity,
              !isPeriodOnFullCourse,
            )}
          </td>
          {!hideDistribution && (
            <td
              style={{
                backgroundColor: "#f1f1f1",
                padding: "0px",
              }}
            >
              {getActualDistribution(
                index,
                periodDistributionValue,
                goalDescriptions.measurementUnity,
                (newDistribution) =>
                  handleUpdateDistribution(
                    periodDistribution.id,
                    newDistribution,
                    index,
                  ),
                blockDistributionChange,
                periodsLen + index + 1,
                fontSize,
              )}
            </td>
          )}
          <td
            style={{
              backgroundColor: "#f1f1f1",
              padding: "0px",
            }}
          >
            {getPeriodValueColl(
              `actual${index}`,
              actualPeriodValue,
              (newVal) => handleUpdatePeriodTarget(id, newVal, index),
              editing.actual === index,
              isDisabled,
              periodsLen * 2 + index + 1,
              measurementUnity,
              () => toggleEditing("actual", index),
              fontSize,
            )}
          </td>
          {!hideCurrentDevation && (
            <td
              style={{
                color: deviationColor,
                fontWeight: "500",
                textAlign: "right",
              }}
              title={translation("kpi_table_text_current_deviation_expn")}
            >
              {periodDeviation}
            </td>
          )}
          <td
            style={{
              textAlign: "right",
              borderColor: "#ccc",
              fontSize: "10px",
            }}
          >
            {periodPercentage}
          </td>
          {getTargetCollumn(periodHitInfo, isTraveledAndValid)}
          {insightId > 0 && (
            <td
              title="Mostrar comentários do Período."
              style={{ cursor: "pointer" }}
              onClick={() => openComment(index)}
            >
              <i
                className={`fas ${
                  commOpen ? "fa-caret-down" : "fa-caret-right"
                }`}
                style={{ color: "#c0c0c0" }}
              />
            </td>
          )}
        </tr>,
      );

      if (commOpen && insight) {
        const { all } = getPeriodTags(
          index,
          periodHitInfo.targetInfo.value,
          periodPercentage,
          goalDescriptions.id,
          goalDescriptions.frequency,
          goalDescriptions.period,
        );

        periodsDisplay.push(
          <tr>
            <td colSpan="100">
              <SimpleCommentSection
                insight={insight}
                tags={all}
                filter={(newComments) =>
                  getCommentsByPeriod(newComments, index)
                }
              />
            </td>
          </tr>,
        );
      }
    },
  );

  return periodsDisplay.map((item, index) => (
    <React.Fragment key={index}>{item}</React.Fragment>
  ));
};

export default DisplayPeriodEvaluations;
