import React, { useState } from "react";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";
import { connect } from "react-redux";
import { getCompanyMembersAndParticipants } from "../customMapStates";
import SelectMemberModal from "../Common/SelectMemberModal";
import { translatedText } from "../../utils/translationUtils";
import { getAllExcept } from "../../utils/ArrayUtils";

const messages = defineMessages({
  global_goBack: { id: "global.goBack" },
});

const translation = (id) => translatedText(id, messages);

const SelectCompanyMember = ({
  open,
  title,
  selected,
  onConfirm,
  onCancel,
  companyMembersAndParticipants,
  singleSelect,
  canSaveUnselected,
  exeptions,
}) => {
  const [replaceSelectedMembers, setReplaceSelectedMembers] = useState(false);

  const handleConfirm = (selectedList) => {
    onConfirm(selectedList);
  };

  const handleCancel = () => {
    onCancel();
    setReplaceSelectedMembers(true);
  };

  const filter = () => {
    const filtered = getAllExcept(
      exeptions,
      companyMembersAndParticipants,
      "id",
    );

    return filtered;
  };

  return (
    <SelectMemberModal
      open={open}
      title={title}
      onConfirm={(selected, selectedList) => handleConfirm(selectedList)}
      onCancel={handleCancel}
      membersList={filter()}
      replaceSelected={replaceSelectedMembers}
      initialSelected={selected}
      replaceCallback={() => setReplaceSelectedMembers(false)}
      cancelText={translation("global_goBack")}
      singleSelect={singleSelect}
      canSaveUnselected={canSaveUnselected}
    />
  );
};

const mapStateToProps = (state) => {
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);

  return { companyMembersAndParticipants };
};

SelectCompanyMember.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  selected: PropTypes.array,
  singleSelect: PropTypes.bool,
  exeptions: PropTypes.arrayOf(PropTypes.number),
  companyMembersAndParticipants: PropTypes.array,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

SelectCompanyMember.defaultProps = {
  open: false,
  title: "Selecione o Participante",
  selected: [],
  singleSelect: false,
  companyMembersAndParticipants: [],
  exeptions: [],
};

export default connect(mapStateToProps)(SelectCompanyMember);
