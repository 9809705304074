import React from "react";
import styled from "styled-components";
import SimpleLoader from "../SimpleLoader";

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DialogBox = styled.div`
  width: 300px;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

const LoadingDialog = ({ open, message }) => {
  return open ? (
    <LoadingOverlay>
      <DialogBox>
        <SimpleLoader color="#6b42a9" />
        <div>{message}</div>
      </DialogBox>
    </LoadingOverlay>
  ) : (
    ""
  );
};

export default LoadingDialog;
