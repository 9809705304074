import moment from "moment";
import "moment/locale/pt-br";
import "moment/locale/es";

export const EXTENDED_DATE_TIME_FORMAT = "LLLL";
export const SMALL_DATE_FORMAT = "DD/MM/YYYY";
export const DATE_WITH_HOUR_FORMAT = "DD/MM/YYYY HH:mm:ss";
export const HOUR_FORMAT = "HH:mm";

export const formatDate = (date, format, locale) => {
  const momentDate = moment(date);
  const _locale = locale || "pt-BR";
  momentDate.locale(_locale);
  return momentDate.format(format);
};

// s is format y-m-d
// Returns a date object for 00:00:00 local time
// on the specified date

export const getDateByLanguage = (date, inputType = false) => {
  const momentDate = moment(date);
  momentDate.locale(moment.locale() ? moment.locale() : "pt-BR");

  const newDate = inputType
    ? momentDate.format("L").replace("/", "-").replace("/", "-")
    : null;

  return newDate ? formatDate(newDate, "YYYY-MM-DD") : momentDate;
};

export const parseDate = (s) => {
  if (typeof s === "string") {
    const b = s.split(/\D/);
    return new Date(b[0], --b[1], b[2]);
  }
  return s;
};

export const simpleDateFormat = (date) => {
  return formatDate(date, "YYYY-MM-DD");
};

export const calculateAge = (birthDate) => {
  if (birthDate) {
    birthDate = new Date(birthDate);
    const diff_ms = Date.now() - birthDate.getTime();
    const age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }
  return null;
};

export const monthIndexTo = (index, to = "QUARTER") => {
  if (index !== null && to) {
    index += 1; // 0 => 1

    if (to === "QUARTERLY") {
      const quarterDivision = index / 3;

      if (quarterDivision <= 1) return 0;
      if (quarterDivision <= 2) return 1;
      if (quarterDivision <= 3) return 2;
      if (quarterDivision <= 4) return 3;
    }

    if (to === "FOUR_MONTHS") {
      const fourMonthsDivision = index / 4;

      if (fourMonthsDivision <= 1) return 0;
      if (fourMonthsDivision <= 2) return 1;
      if (fourMonthsDivision <= 3) return 2;
    }

    if (to === "SEMIANNUAL") {
      const semiannualDivision = index / 6;

      if (semiannualDivision <= 1) return 0;
      if (semiannualDivision <= 2) return 1;
    }

    if (to === "YEARLY") return 0;
  }

  return null;
};

export const getMomentDate = (year, monthIndex) => {
  if (year && year.toString().length > 3 && monthIndex >= 0) {
    const formattedMonth = moment().month(monthIndex).format("MM");
    const yearAndMonthString = `${year}-${formattedMonth}`;

    const startMoment = moment(`${yearAndMonthString}-01`);
    const daysInMonth = startMoment.daysInMonth();
    const endMoment = moment(`${yearAndMonthString}-${daysInMonth}`);

    return { start: startMoment, end: endMoment };
  }

  return { start: null, end: null };
};

export const getValidDate = (checkDate, replace = "") => {
  return moment(checkDate).isValid() ? checkDate : replace;
};

export const transformToDays = (dateValue, refDate = null) => {
  dateValue = dateValue ? dateValue.toString() : null;
  refDate = refDate ? refDate.toString() : null;

  const offset = 100;

  function short(ms) {
    return ms / (1000 * 60 * 60 * 24);
  }

  const hours = Number(moment(dateValue).format("x"));
  const refHours =
    refDate && moment(refDate).isValid()
      ? Number(moment(refDate).format("x"))
      : 0;

  return dateValue ? short(hours) - short(refHours) + offset : 0 + offset;
};

export const changeDateToWordMonth = (date = "") => {
  const newDate = new Date(date);
  const dateMonth = moment(date).month();
  const monthFormat = moment().month(dateMonth).format("MMMM");

  return monthFormat && moment.isDate(newDate)
    ? monthFormat.toString()
    : date.toString();
};
