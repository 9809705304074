import observeAccessLevels from "./observers/accessLevels/_observer";

import observeCompany from "./observers/company/_observer";

import observeWorkspace from "./observers/workspace/_observer";

import observeBoard from "./observers/tool/board/_observer";
import observeOKR from "./observers/tool/okr/_observer";
import observeGoalsAndBonus from "./observers/tool/goalsAndBonus/_observer";
import observeKpis from "./observers/tool/kpis/_observer";
import observeSkillsAssessment from "./observers/tool/skillsAssessment/_observer";
import observeComments from "./observers/tool/comments/_observer";
import observeAttachments from "./observers/tool/attachment/_observer";
import observeCompetitiveAnalysis from "./observers/tool/competitiveAnalysis/_observer";

export const subscribeController = (store, connection, stompClient) => {
  if (store && connection) {
    const emitEvents = {
      stompClient,
      dispatch: store.dispatch,
    };

    connection.then(() => {
      observeOKR(emitEvents);
      observeKpis(emitEvents);
      observeSkillsAssessment(emitEvents);
      observeCompetitiveAnalysis(emitEvents);
      observeBoard(emitEvents);
      observeCompany(emitEvents);
      observeAccessLevels(emitEvents);
      observeGoalsAndBonus(emitEvents);
      observeWorkspace(emitEvents);
      observeComments(emitEvents);
      observeAttachments(emitEvents);

      /**
       * Quando a conexão ao websocket ocorrer com sucesso,
       * inscrever todos os observe que deseja obter as atualizações
       * vindas do backend
       **/
      /*
       * Funções observe escutam alterações de sockets para ser emitidas e
       * enviadas para o reducer, também tem o intuito de desacoplar o codigo
       */
    });
  } else {
    console.log({ store, connection, stompClient });
  }
};
