import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getGoalsBonusStates } from "../../../customMapStates";
import _ from "lodash";
import utils from "../../../../utils/toolUtils";
import gbUtils from "../../../../utils/goalsBonus";
import { updateQuestion } from "../../../../actions/questionsActions";

const {
  getObjectDifference,
  getObjectInfoById,
  getOnlySlugValues,
  removeObjSlug
} = utils;

const { buildFormAdmConfigBody, getGoalsBonusBySelectedToolId } = gbUtils;

const defaultFormInitialGoalBonus = buildFormAdmConfigBody({});

const GoalsBonusWatcher = props => {
  const [goalBonusAdministration, setGoalBonusAdministration] = useState(
    defaultFormInitialGoalBonus
  );
  const [firstValidation, setFirstValidation] = useState(false);

  const {
    allGoalBonusAdministration = [],
    selectedTool = {},
    allQuestions = {},
    updateQuestion
  } = props;

  useEffect(() => {
    let toolGoalsBonusAdm = getGoalsBonusBySelectedToolId(
      selectedTool.id,
      allGoalBonusAdministration
    );
    toolGoalsBonusAdm = buildFormAdmConfigBody(toolGoalsBonusAdm);

    const isAdmObjDiff = !_.isEqual(toolGoalsBonusAdm, goalBonusAdministration);

    if (
      toolGoalsBonusAdm &&
      toolGoalsBonusAdm.participants &&
      Object.keys(allQuestions).length > 0
    ) {
      if (isAdmObjDiff || !firstValidation) {
        const compareBase = removeObjSlug(
          goalBonusAdministration.participants,
          "id"
        );

        const diff = getObjectDifference(
          toolGoalsBonusAdm.participants,
          compareBase
        );

        if (diff) {
          if (!firstValidation) setFirstValidation(true);

          const watchParticipants = getOnlySlugValues(diff, "id");

          if (watchParticipants.length > 0) {
            Object.keys(allQuestions).forEach(themeId => {
              const themeQuestions = allQuestions[themeId];

              themeQuestions.forEach(questionInfo => {
                const { goalsBonusProgramParticipantId } = questionInfo;
                const relatedParticipantId =
                  watchParticipants[
                    watchParticipants.indexOf(goalsBonusProgramParticipantId)
                  ] || null;

                if (relatedParticipantId && questionInfo.text) {
                  const participantInfo = getObjectInfoById(
                    relatedParticipantId,
                    toolGoalsBonusAdm.participants,
                    "id"
                  );

                  if (participantInfo) {
                    const { memberParticipant } = participantInfo;

                    if (memberParticipant.name !== questionInfo.text) {
                      updateQuestion(
                        { ...questionInfo, text: memberParticipant.name },
                        questionInfo.theme.id
                      );
                    }
                  }
                }
              });
            });
          }
        }

        if (isAdmObjDiff) setGoalBonusAdministration(toolGoalsBonusAdm);
      }
    }
  }, [
    allGoalBonusAdministration,
    allQuestions,
    firstValidation,
    goalBonusAdministration,
    selectedTool.id,
    updateQuestion
  ]);

  return null;
};

const mapStateToProps = state => {
  const { allGoalBonusAdministration } = getGoalsBonusStates(state);

  return { allGoalBonusAdministration };
};

export default connect(mapStateToProps, { updateQuestion })(GoalsBonusWatcher);
