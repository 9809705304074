const en = {
  ca_main_title: "Competitive Analysis",
};

const es = {
  ca_main_title: "Análisis competitivo",
};

const pt = {
  ca_main_title: "Análise Competitiva",
};

const translations = { en, es, pt };

export default translations;
