/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import { buildSelectPositions } from "./filter";
import { useState, useEffect } from "react";

export function useCompanyPositionsTree({
  selectedCompany = {},
  allPositions = {},
}) {
  const [companyPositionsTree, setCompanyPositionsTree] = useState({});
  const selectedCompanyId = selectedCompany.id ? selectedCompany.id : null;

  useEffect(() => {
    if (selectedCompanyId) {
      const restPositions = allPositions[selectedCompanyId] || [];
      const newPositionsTree = buildSelectPositions(restPositions);

      if (!_.isEqual(companyPositionsTree, newPositionsTree))
        setCompanyPositionsTree(newPositionsTree);
    }
  }, [allPositions, selectedCompanyId]);

  return { companyPositionsTree };
}
