import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

class ActionButton extends Component {
  render() {
    const tooltip = (
      <Tooltip id={`tooltip_${this.props.id}`}>{this.props.tooltip}</Tooltip>
    );

    return (
      <OverlayTrigger placement={this.props.tooltipPosition} overlay={tooltip}>
        <Button
          className={this.props.classStyleActionButton}
          bsStyle={this.props.bsStyle}
          bsSize={this.props.bsSize}
          onClick={this.props.action}
        >
          <i className={this.props.iconClass} />
        </Button>
      </OverlayTrigger>
    );
  }
}

ActionButton.propTypes = {
  id: PropTypes.string.isRequired,
  tooltip: PropTypes.node.isRequired,
  iconClass: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired
};

ActionButton.defaultProps = {
  tooltipPosition: "top"
};

export default ActionButton;
