import { getValidId, getValidEntityConfigs } from "../validations";
import { floatNumber } from "../MathUtils";
import { DEFAULT_ACTION_STATUS } from "../../constants/actionsPlan";
import { getUpdatedDeleteArrayByIndex, getObjectInfoById } from "../ArrayUtils";

export const getActionDatesEntity = (dates = {}, config) => {
  const { stringValue, idValue } = getValidEntityConfigs(config);

  return {
    id: dates.id || idValue,
    start: dates.start || stringValue,
    end: dates.end || stringValue,
    rescheduled: dates.rescheduled || stringValue,
    conclusion: dates.conclusion || stringValue,
  };
};

export const getActionResponsibleEntity = (
  responsible,
  config,
  responsibleType,
) => {
  config = getValidEntityConfigs(config);

  return {
    id: getValidId(responsible?.id, config.idValue),
    type: responsibleType || "MEMBER",
    ...responsible,
  };
};

export const getActionsEntity = (actions = [], config) => {
  const {
    stringValue,
    arrayValue,
    idValue,
    objectValue,
  } = getValidEntityConfigs(config);

  let vActions = [];
  if (Array.isArray(actions) && actions.length > 0) {
    actions.forEach((actionInfo = {}, key) => {
      vActions[key] = {};
      vActions[key].id = getValidId(actionInfo.id, idValue);
      vActions[key].order = floatNumber(actionInfo.order, stringValue);
      vActions[key].insight = actionInfo.insight || objectValue;
      vActions[key].insights = actionInfo.insights || arrayValue;
      vActions[key].deviation = actionInfo.deviation || stringValue;
      vActions[key].period = actionInfo.period || stringValue;
      vActions[key].cause = actionInfo.cause || stringValue;
      vActions[key].followUp = actionInfo.followUp || stringValue;
      vActions[key].action = actionInfo.action || stringValue;
      vActions[key].dates = getActionDatesEntity(actionInfo.dates, config);
      vActions[key].responsibleType = actionInfo.responsibleType || "MEMBER";
      vActions[key].responsible = getActionResponsibleEntity(
        actionInfo.responsible,
        config,
        vActions[key].responsibleType,
      );
      vActions[key].responsibleParticipantId = getValidId(
        actionInfo.responsibleParticipantId,
      );
      vActions[key].responsibleMemberId = getValidId(
        actionInfo.responsibleMemberId,
      );
      vActions[key].status = actionInfo.status || DEFAULT_ACTION_STATUS;
      vActions[key].relatedActionPlans =
        actionInfo.relatedActionPlans || arrayValue;
    });
  }

  return vActions;
};

export const getActionsPlanEntity = (actionPlan, config) => {
  config = getValidEntityConfigs(config);

  return {
    id: getValidId(actionPlan.id, config.idValue),
    actions: getActionsEntity(actionPlan.actions, config),
  };
};

export const getFormActionsPlan = (actionPlan) => {
  return getActionsPlanEntity(actionPlan, {
    stringValue: "",
    arrayValue: [],
    idValue: null,
  });
};

export const getRestActionsPlan = (actionPlan) => {
  return getActionsPlanEntity(actionPlan, {
    stringValue: null,
    arrayValue: [],
    idValue: null,
  });
};

export const updateActionByResponsibleType = (actionInfo) => {
  const { responsible } = actionInfo;
  const { type, responsibleType } = responsible;
  const checkType = !type ? responsibleType : type;

  const saveUser = { id: responsible.id };

  const updated = {
    ...actionInfo,
    responsibleMemberId: checkType === "MEMBER" ? saveUser.id : null,
    responsibleParticipantId: checkType === "PARTICIPANT" ? saveUser.id : null,
    responsibleType: checkType,
  };

  delete updated.responsibleMember;
  delete updated.responsibleParticipant;

  return updated;
};

export const getSaveRestByResponsibleType = (actionPlan) => {
  const restPlan = getRestActionsPlan(actionPlan);

  const newActions = [];

  restPlan?.actions?.forEach((actionInfo) => {
    newActions.push(updateActionByResponsibleType(actionInfo));
  });

  return {
    ...restPlan,
    actions: newActions,
  };
};

export const deleteRowOnPlan = (
  currentPlan,
  parentName = "",
  itemId,
  searchForSlug = "id",
) => {
  const parentArray = currentPlan[parentName];

  if (currentPlan.id && Array.isArray(parentArray)) {
    const itemIndex = getObjectInfoById(
      itemId,
      parentArray,
      searchForSlug,
      true,
    );

    const updatedParent = getUpdatedDeleteArrayByIndex(parentArray, itemIndex);

    return {
      ...currentPlan,
      [parentName]: updatedParent,
    };
  }

  return currentPlan;
};

export function deleteActionOnPlan(currentActionPlan = {}, actionId) {
  const onlyActionPlan = currentActionPlan.actionPlan || {};

  return {
    ...currentActionPlan,
    actionPlan: deleteRowOnPlan(onlyActionPlan, "actions", actionId, "id"),
  };
}
