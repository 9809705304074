import React from "react";
import { changelog } from "../../constants/changelog";

const ChangelogDisplay = () => {
  return changelog.map((versionData) => {
    return (
      <div style={{ marginBottom: "20px" }}>
        <h4 style={{ fontWeight: "500" }}>{versionData.version}</h4>
        <div>
          {versionData.changes.map((changeData) => {
            return (
              <div>
                <h5
                  style={{
                    marginTop: "13px",
                    marginBottom: "2px",
                    fontWeight: "500",
                  }}
                >
                  {changeData.title}
                </h5>
                <ul>
                  {changeData.list.map((itemData) => {
                    return (
                      <li style={{ lineHeight: "24px" }}>
                        {itemData.description}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    );
  });
};

export default ChangelogDisplay;
