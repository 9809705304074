import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Grid, Button } from "@material-ui/core";
import { AlertStyle } from "../../css/OkrCss";

import FormDialog from "../../../../../../Common/FormDialog";
import MaterialTextField from "../../../../../../Common/MaterialTextField";
import ConfirmationDialog from "../../../../../../Common/ConfirmationDialog";

import CicleRegister from "../CicleRegister";
import SelectCycleBaseDialog from "./SelectCycleBaseDialog";
import {
  configCicleGroupsBody,
  configCycleBody,
  getClonnedPatternCycles,
} from "../../../../../../../utils/okr/entity";
import { deleteCycle } from "../../../../../../../actions/okrActions";
import { getObjectInfoById } from "../../../../../../../utils/ArrayUtils";
import { getValidFunc } from "../../../../../../../utils/validations";

const initialCycleBody = configCycleBody({});
const initialCycleGroups = configCicleGroupsBody({});

const defaultConfirmDialog = {
  title: "",
  onConfirm: null,
  onCancel: null,
  open: false,
  params: {},
};

const CicleForm = (props) => {
  const [cycleGroups, setCycleGroups] = useState(initialCycleGroups);
  const [formErrors, setFormErrors] = useState({
    yearError: "",
    cycleError: "",
  });
  const [openCyclesModal, setOpenCyclesModal] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({
    ...defaultConfirmDialog,
  });

  const {
    open,
    createGroups,
    isEditing = {},
    intl,
    allThemes,
    currentCycleGroups,
    cycleBase,
  } = props;

  const cyclePatterns =
    cycleBase && cycleBase.patterns ? cycleBase.patterns : [];

  const editingCycleGroupId =
    isEditing && isEditing.params && isEditing.params.cycleGroup
      ? isEditing.params.cycleGroup.id
      : null;

  const onCancel = getValidFunc(props.onCancel);
  const openFormDelete = getValidFunc(props.openFormDelete);
  const getDefaultRemoveColumn = getValidFunc(props.getDefaultRemoveColumn);
  const getDefaultAddTableLine = getValidFunc(props.getDefaultAddTableLine);

  useEffect(() => {
    if (isEditing && isEditing.params && isEditing.params.cycleGroup) {
      const editingCycleGroup = isEditing.params.cycleGroup;

      if (editingCycleGroup) {
        const editingGroupCycles = editingCycleGroup.cycles;
        const reorderedCycles = [...editingGroupCycles].sort((a, b) =>
          a.title > b.title ? 1 : -1,
        );

        setCycleGroups({ ...editingCycleGroup, cycles: reorderedCycles });
      }
    }
  }, [isEditing]);

  const resetConfirmDialog = () => {
    setConfirmationDialog({ ...defaultConfirmDialog });
  };

  function handleCycleGroups(name, value) {
    setCycleGroups({ ...cycleGroups, [name]: value });
  }

  function handleAddCycles(index) {
    const newCycles = [];

    newCycles.push(...cycleGroups.cycles, {
      ...initialCycleBody,
      index,
    });

    setCycleGroups({ ...cycleGroups, cycles: newCycles });
  }

  function validForm() {
    let yearError = "";
    let cycleError = "";

    if (cycleGroups.year === "") {
      yearError = intl.formatMessage({ id: "fill_date_alert" });
    }

    if (currentCycleGroups && cycleGroups.year > -1) {
      currentCycleGroups.forEach((savedCycleGroupInfo) => {
        let count = 0;
        let indexCount = 0;

        const sameYear = savedCycleGroupInfo.year === cycleGroups.year;

        if (savedCycleGroupInfo.id !== editingCycleGroupId && sameYear) count++;
        if (cycleGroups.index !== savedCycleGroupInfo.index && sameYear)
          indexCount++;

        const isEditingAndDuplicated = editingCycleGroupId > -1 && count >= 1;
        const isNewAndDuplicated = !cycleGroups.index && count >= 1;
        const isEditingNewAndDuplicated = indexCount >= 1;

        if (
          isEditingAndDuplicated ||
          isNewAndDuplicated ||
          isEditingNewAndDuplicated
        ) {
          yearError = `Já existe um Grupo de Ciclos com o ano ${cycleGroups.year} cadastrado`;
        }
      });
    }

    if (cycleGroups.cycles.length > 0) {
      cycleGroups.cycles.forEach(({ title, startDate, endDate, checkDate }) => {
        if (
          title === "" ||
          title.length < 3 ||
          startDate === "" ||
          endDate === "" ||
          checkDate === ""
        ) {
          cycleError = intl.formatMessage({ id: "all_inputs_alert" });
        }
      });
    }

    if (yearError || cycleError) {
      setFormErrors({ yearError, cycleError });

      return false;
    }

    return true;
  }

  function handleDeleteCicle(index, relatedTheme, cicleId) {
    function getCycleGroups() {
      const cycles = cycleGroups.cycles.filter(
        (cycle) => cycle.index !== index,
      );
      setCycleGroups({ ...cycleGroups, cycles });
    }

    if (!relatedTheme.id && !cicleId) {
      getCycleGroups();
    } else {
      deleteCycle(cicleId);
      getCycleGroups();
    }
  }

  function handleCicle(index, name, value, id) {
    let updatedCycles = [];

    if (!id) {
      updatedCycles = cycleGroups.cycles.map((cycle) => {
        return cycle.index === index
          ? { ...cycle, [name]: value }
          : { ...cycle };
      });
    } else {
      updatedCycles = cycleGroups.cycles.map((cycle) => {
        return cycle.id === id ? { ...cycle, [name]: value } : { ...cycle };
      });
    }

    setCycleGroups({ ...cycleGroups, cycles: updatedCycles });
  }

  function cancelModal() {
    setFormErrors({
      yearError: "",
      cycleError: "",
    });
    setCycleGroups(initialCycleGroups);
    onCancel("cicleModal", false);
  }

  function createCicleGroup() {
    if (validForm()) {
      createGroups(cycleGroups);
      cancelModal();
    }
  }

  function getOnlyTheNewCycles(currentCycles = [], toBeAddedCycles = []) {
    const onlyNew = [];

    if (Array.isArray(currentCycles) && Array.isArray(toBeAddedCycles)) {
      for (let i = currentCycles.length; i < toBeAddedCycles.length; i++) {
        const toAppendCycle = toBeAddedCycles[i];
        onlyNew.push(toAppendCycle);
      }
    }

    return onlyNew;
  }

  function finishUpdateGroupCycles(updatedCycles) {
    if (updatedCycles)
      setCycleGroups({
        ...cycleGroups,
        cycles: updatedCycles,
      });

    resetConfirmDialog();
    setOpenCyclesModal(false);
  }

  function handleAddSelectedCicleBase(selectedBase) {
    const cicleBaseId = selectedBase[0];

    const resetClose = () => {
      resetConfirmDialog();
      setOpenCyclesModal(false);
    };

    if (cicleBaseId) {
      const selectedPattern = getObjectInfoById(
        cicleBaseId,
        cyclePatterns,
        "id",
      );

      const patternCycles = getClonnedPatternCycles(selectedPattern.cycles);
      const currentCycles = [...cycleGroups.cycles];

      if (currentCycles.length > 0) {
        if (patternCycles.length <= currentCycles.length) {
          setConfirmationDialog({
            open: true,
            title: intl.formatMessage({ id: "not_possible_add_pattern" }),
            description: intl.formatMessage({ id: "small_number_cycles" }),
            onCancel: resetClose,
            onConfirm: resetClose,
            confirmText: "OK",
          });

          return false;
        }
        const onlyToBeAdded = getOnlyTheNewCycles(currentCycles, patternCycles);

        const doConfirmSave = () =>
          finishUpdateGroupCycles([...currentCycles, ...onlyToBeAdded]);

        setConfirmationDialog({
          open: true,
          title: intl.formatMessage({ id: "already_cycles_registered" }),
          description: (
            <span>
              {intl.formatMessage(
                { id: "number_of_cycles_registered" },
                { cycleLength: currentCycles.length },
              )}
              :
              {onlyToBeAdded.map((cycleInfo) => {
                return (
                  <div style={{ paddingTop: "10px" }}>
                    =&gt; <b>{cycleInfo.title}</b>
                  </div>
                );
              })}
            </span>
          ),
          onCancel: resetClose,
          onConfirm: doConfirmSave,
          confirmText: "OK",
        });

        return false;
      }

      if (currentCycles.length === 0) {
        finishUpdateGroupCycles(patternCycles);
      }
    }

    return false;
  }

  return (
    <>
      {confirmationDialog.open && confirmationDialog.title && (
        <ConfirmationDialog
          open={confirmationDialog.open}
          title={confirmationDialog.title}
          description={confirmationDialog.description}
          onConfirm={confirmationDialog.onConfirm}
          confirmText={confirmationDialog.confirmText}
          onCancel={confirmationDialog.onCancel}
          hideCancel={confirmationDialog.hideCancel}
          confirmColor="#ff3151"
        />
      )}
      <SelectCycleBaseDialog
        open={openCyclesModal}
        intl={intl}
        cyclePatterns={cyclePatterns}
        onConfirm={(selectedBase) => handleAddSelectedCicleBase(selectedBase)}
        onCancel={() => setOpenCyclesModal(false)}
      />
      <FormDialog
        title={intl.formatMessage({ id: "add_cycle" })}
        open={open}
        onCancel={() => cancelModal()}
        onConfirm={() => createCicleGroup()}
      >
        {(formErrors.yearError || formErrors.cycleError) && (
          <AlertStyle severity="error">
            {<span>{formErrors.yearError}</span>}
            {<span>{formErrors.cycleError}</span>}
          </AlertStyle>
        )}
        <Grid container alignItems="center">
          <Grid item>
            <MaterialTextField
              inputStyle={{ width: "200px" }}
              id="yearCicle"
              type="number"
              fullWidth={false}
              label={intl.formatMessage({ id: "cycle_year" })}
              placeholder="Ex: 2020"
              size="small"
              value={cycleGroups.year}
              onChange={(e) => handleCycleGroups("year", e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              style={{ fontSize: "12px", marginLeft: "15px" }}
              onClick={() => setOpenCyclesModal(true)}
            >
              {intl.formatMessage({ id: "select_cycles" })}
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <CicleRegister
            cycles={cycleGroups.cycles}
            allThemes={allThemes}
            editCicle
            handleDeleteCicle={handleDeleteCicle}
            handleCicle={handleCicle}
            removeCollumn={getDefaultRemoveColumn}
            openFormDelete={openFormDelete}
            addTableLine={getDefaultAddTableLine}
            addCicle={handleAddCycles}
          />
        </Grid>
      </FormDialog>
    </>
  );
};

export default injectIntl(CicleForm);

CicleForm.propTypes = {
  open: PropTypes.bool.isRequired,
  createGroups: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  getDefaultRemoveColumn: PropTypes.func.isRequired,
  getDefaultAddTableLine: PropTypes.func.isRequired,
  openFormDelete: PropTypes.func.isRequired,
  isEditing: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  allThemes: PropTypes.object.isRequired,
  cycleBase: PropTypes.object.isRequired,
  currentCycleGroups: PropTypes.array.isRequired,
};
