import React from "react";
import { defineMessages } from "react-intl";
import { getSingleIndicatorResults } from "../../../../utils/kpis/calc2";
import actionsPlanUtils from "../../../../utils/actionsPlan";
import Avatar from "../../Avatar";
import { getTotalActionsByInsights } from "../../../../utils/actionsPlan/filter";
import { translatedText } from "../../../../utils/translationUtils";
import { getPerformanceCol, getWidthTd } from "../../../../utils/tableUtils";
import {
  displayTransformValue,
  getDefaultTargetCols,
  getHitTargetInfo,
} from "../../../../utils/kpis/display";
import {
  getObjectInfoById,
  orderArrayByObjAttr,
} from "../../../../utils/ArrayUtils";
import { getDisplayTargets } from "../../../../utils/kpis/calc";

const { getFilteredActionsPlanByInsights } = actionsPlanUtils;

const messages = defineMessages({
  kpi_table_text_goal_upper: { id: "kpi_table_text_goal_upper" },
  kpi_form_type: { id: "kpi_form_type" },
  kpi_form_unity: { id: "kpi_form_unity" },
  kpi_table_text_actual: { id: "kpi_table_text_actual" },
  kpi_table_text_no_goal_found: { id: "kpi_table_text_no_goal_found" },
  kpi_options_rt_shared: { id: "kpi_options_rt_shared" },
});

const translation = (id) => translatedText(id, messages);
const TD = (props) => getWidthTd(props);

const ProjectOptionsTable = (props) => {
  const {
    optionsAndProjectGoals = [],
    kpiAdministration,
    isPeriodFilterActive: filter,
    goalTypeOptions,
    actionsPlan,
    selectAndOpenInsight,
  } = props;

  const oveflowTextStyles = {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "pre-wrap",
    maxWidth: "250px",
    cursor: "pointer",
  };

  return (
    <table className="simple-table horizontal-scroll">
      <thead>
        <tr>
          <TD width="200px" text="Projetos/Opções" />
          <TD width="50px" text={translation("kpi_form_type")} />
          {getDefaultTargetCols({ width: "100px", fontSize: "10px" })}
          <TD width="100px" text={translation("kpi_table_text_actual")} />
          <TD width="45px" text="Alcance" />
          <TD width="450px" text="Observações/Justificativa" />
          <TD width="50px" text="Ações" />
        </tr>
      </thead>
      <tbody style={{ fontWeight: "bold" }}>
        {orderArrayByObjAttr(optionsAndProjectGoals, "title").map(
          (goalInfo) => {
            const { relatedInsights = [] } = goalInfo;

            const mainRelatedInsightId = relatedInsights[0] || null;

            const {
              goalPercentage,
              notStarted,
              courseTargetsDivision,
              goalPeriodTotalEvaluation,
              accumulated,
            } = getSingleIndicatorResults(
              goalInfo,
              kpiAdministration.goalTargets,
              kpiAdministration.zeroWhenNegative,
            );

            const displayPercentage = filter
              ? accumulated.goalPercentage
              : goalPercentage;
            const displayTargets = filter
              ? accumulated.target
              : courseTargetsDivision;

            const hitTargetInfo = getHitTargetInfo(
              displayPercentage,
              kpiAdministration.goalTargets,
              notStarted,
            );

            const filteredInsightActionsPlan = getFilteredActionsPlanByInsights(
              [mainRelatedInsightId],
              actionsPlan,
            );

            const { displayTotal, displayCourseTargets } = getDisplayTargets(
              goalInfo,
              goalPeriodTotalEvaluation,
              displayTargets,
            );

            const isConsolidated = goalInfo.relationshipType === "SHARED";

            return (
              <tr className="block-white-space" key={goalInfo.id}>
                <td
                  style={{ backgroundColor: "#f6f6f6" }}
                  title={goalInfo.title}
                >
                  <div
                    style={oveflowTextStyles}
                    onClick={() => selectAndOpenInsight(goalInfo)}
                  >
                    <Avatar
                      src={goalInfo.avatarId}
                      name={goalInfo.responsibleName}
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "5px",
                      }}
                    />
                    <span style={isConsolidated ? { fontWeight: "500" } : {}}>
                      {isConsolidated && (
                        <i
                          className="fas fa-stream"
                          style={{
                            color: "#ffc875",
                            fontSize: "17px",
                            paddingRight: "7px",
                            position: "relative",
                            top: "1px",
                          }}
                          title={translation("kpi_options_rt_shared")}
                        />
                      )}
                      {goalInfo.title}
                    </span>
                  </div>
                </td>
                <td align="center" style={{ whiteSpace: "nowrap" }}>
                  {
                    getObjectInfoById(goalInfo.type, goalTypeOptions, "value")
                      .label
                  }
                </td>
                <td align="right">
                  {displayTransformValue(
                    displayCourseTargets?.gate,
                    goalInfo.measurementUnity,
                    2,
                  )}
                </td>
                <td align="right">
                  {displayTransformValue(
                    displayCourseTargets?.appropriated,
                    goalInfo.measurementUnity,
                    2,
                  )}
                </td>
                <td align="right">
                  {displayTransformValue(
                    displayCourseTargets?.exceeded,
                    goalInfo.measurementUnity,
                    2,
                  )}
                </td>
                <td
                  align="right"
                  style={{
                    backgroundColor: "#f6f6f6",
                    color: notStarted ? "#ddd" : "#333",
                  }}
                >
                  <b>
                    {displayTransformValue(
                      displayTotal,
                      goalInfo.measurementUnity,
                      2,
                    )}
                  </b>
                </td>
                {getPerformanceCol(
                  displayPercentage,
                  {
                    color: hitTargetInfo.color,
                    notStarted,
                  },
                  1,
                )}
                <TD
                  width="515px"
                  text={goalInfo.generalDetails || "-"}
                  styles={{ whiteSpace: "normal" }}
                />
                <td align="center">
                  {
                    getTotalActionsByInsights(
                      [mainRelatedInsightId],
                      filteredInsightActionsPlan,
                    ).length
                  }
                </td>
              </tr>
            );
          },
        )}
        {optionsAndProjectGoals.length === 0 && (
          <tr>
            <td colSpan="100">{translation("kpi_table_text_no_goal_found")}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default ProjectOptionsTable;
