import React from "react";
import { getValidFunc } from "../../../utils/validations";

const DuplicateColumn = (props) => {
  const handleDuplicate = getValidFunc(props.handleDuplicate);

  return (
    <td
      style={{ width: "40px", textAlign: "center", cursor: "pointer" }}
      className="action-column"
      onClick={handleDuplicate}
    >
      <i className="fas fa-copy" style={{ color: "#666" }} />
    </td>
  );
};

export default DuplicateColumn;
