const pt = {
  equal_insight_name_alert: "Já existe um insight com esse nome, tente outro",
  equal_question_name_alert:
    "Já existe uma question com esse nome, tente outro",
  equal_theme_name_alert: "Já existe um tema com esse nome, tente outro",
  equal_scenario_name_alert: "Já existe um cenário com esse nome, tente outro",
};

const en = {
  equal_insight_name_alert:
    "An insight with this name already exists, try another",
  equal_question_name_alert:
    "An question with this name already exists, try another",
  equal_theme_name_alert: "An theme with this name already exists, try another",
  equal_scenario_name_alert:
    "An scenario with this name already exists, try another",
};

const es = {
  equal_insight_name_alert:
    "Ya hay uno insight con ese nombre, prueba con otro",
  equal_question_name_alert:
    "Ya hay una question con ese nombre, prueba con otro",
  equal_theme_name_alert: "Ya hay uno tema con ese nombre, prueba con otro",
  equal_scenario_name_alert:
    "Ya hay uno scenerio con ese nombre, prueba con otro",
};

const translations = { pt, en, es };

export default translations;
