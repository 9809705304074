import { createTheme } from "@material-ui/core/styles";
import { primaryColor } from "./colors";

const themeProvider = createTheme({
  palette: {
    primary: {
      light: primaryColor.light,
      main: primaryColor.main,
      dark: primaryColor.dark,
      contrastText: primaryColor.contrast,
    },
  },
  typography: {
    useNextVariants: true,
  },
});

export default themeProvider;
