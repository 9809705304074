import "react-quill/dist/quill.snow.css";

import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
} from "react";
import ReactQuill from "react-quill";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";

import { Picker } from "emoji-mart";

import {
  Container,
  ToolBar,
  BoxFiles,
  File,
  IconPreview,
  IconDelete,
  MaxLength,
} from "./styles";

const CustomButton = () => (
  <span className="octicone">
    <i className="fas fa-at" />
  </span>
);

const CustomAttachmentButton = () => (
  <span className="octicone">
    <i className="fas fa-paperclip" />
  </span>
);

const CustomSendButton = () => (
  <span className="sendicone">
    <i className="fas fa-paper-plane" />
  </span>
);

const CustomEmojuButton = () => (
  <span className="emoji">
    <i className="fas fa-smile" />
  </span>
);

export const CustomToolbar = ({
  getFiles,
  onClickButton,
  openEmojiSelector,
  emojiSelector,
  onClickButtonSend,
}) => (
  <ToolBar id="toolbar">
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>

    <div className="box-right">
      <button className="ql-link" />
      <button onClick={() => onClickButton("@")}>
        <CustomButton />
      </button>
      <div className="insertAttachment">
        <button onClick={() => document.getElementById("file-input").click()}>
          <CustomAttachmentButton />
        </button>
        <input
          id="file-input"
          type="file"
          onChange={(e) => getFiles(e.target.files)}
          name="name"
          style={{ display: "none" }}
          multiple
        />
      </div>
      <div style={{ position: "relative" }}>
        <button onClick={openEmojiSelector}>
          <CustomEmojuButton />
        </button>
        {!!emojiSelector && (
          <div
            style={{
              position: "absolute",
              top: "34px",
              left: "-290px",
              zIndex: 10000,
            }}
          >
            <Picker onSelect={(e) => onClickButton(e.native)} />
          </div>
        )}
      </div>
      <div>
        <button onClick={onClickButtonSend} className="button-send">
          <CustomSendButton />
        </button>
      </div>
    </div>
  </ToolBar>
);

export const CustomEditToolbar = ({
  onClickButton,
  openEmojiSelector,
  emojiSelector,
}) => (
  <ToolBar id="editToolbar">
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>

    <div className="box-right">
      <button onClick={() => onClickButton("@")}>
        <CustomButton />
      </button>
      <div style={{ position: "relative" }}>
        <button onClick={openEmojiSelector}>
          <CustomEmojuButton />
        </button>
        {!!emojiSelector && (
          <div
            style={{
              position: "absolute",
              top: "34px",
              left: "-290px",
              zIndex: 10000,
            }}
          >
            <Picker onSelect={(e) => onClickButton(e.native)} />
          </div>
        )}
      </div>
    </div>
  </ToolBar>
);

const fileIcons = {
  PDF: "fa-file-pdf",
  AUDIO: "fa-file-audio",
  VIDEO: "fa-file-video",
  IMAGE: "fa-file-image",
  MS_WORD: "fa-file-word",
  MS_PROJECT: "fa-file",
  MS_POWERPOINT: "fa-file-powerpoint",
  MS_EXCEL: "fa-file-excel",
  ODF: "fa-file",
  ODP: "fa-file",
  ODS: "fa-file",
  ODT: "fa-file",
  TEXT: "fa-file-alt",
};

const Editor = ({
  onSave,
  editing,
  container,
  text,
  mentions,
  maxLength,
  onChange,
}) => {
  const [value, setValue] = useState(() => {
    if (text.length) {
      return text;
    }

    return "";
  });
  const [files, setFiles] = useState([]);
  const [emojiSelector, setEmojiSelector] = useState(false);
  const [allMentioned, setAllMentioned] = useState("");
  const [commentLength, setCommentLenght] = useState(0);

  const refQuillEditor = useRef(null);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  const getTextInHTML = useCallback((html) => {
    const newDiv = document.createElement("div");
    newDiv.innerHTML = html;

    const textWithoutHtml = newDiv.textContent || newDiv.innerText || "";

    return textWithoutHtml;
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        container,
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@", "#"],
        isolateCharacter: true,
        dataAttributes: ["id", "value", "email"],
        defaultMenuOrientation: "bottom",
        onSelect: (item, insertItem) => {
          insertItem(item, true);
          setAllMentioned((oldState) => [...oldState, item.email]);
        },
        source(searchTerm, renderList, mentionChar) {
          let values;

          if (mentionChar === "@") {
            values = mentions;
          } else {
            values = mentions;
          }

          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
          } else if (values) {
            const matches = values.filter(
              ({ value }) =>
                !value.toLowerCase().search(searchTerm.toLowerCase()),
            );

            renderList(matches, searchTerm);
          }
        },
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
    }),
    // eslint-disable-next-line
    [],
  );

  const getFiles = useCallback(
    (selectedFiles) => {
      const arrayFiles = Object.keys(selectedFiles).map(
        (index) => selectedFiles[index],
      );

      const concatedFiles = files.concat(arrayFiles);

      setFiles(concatedFiles);
    },
    [files],
  );

  const attachQuillRefs = useCallback(() => {
    if (refQuillEditor.current) {
      if (typeof refQuillEditor.current.getEditor !== "function") {
        return undefined;
      }

      return refQuillEditor.current.getEditor();
    }

    return null;
  }, []);

  const setTextAndMaxLength = (value) => {
    const valueLenght = getTextInHTML(value).length;

    const quill = attachQuillRefs();

    if (quill) {
      quill.on("text-change", () => {
        if (quill.getLength() > maxLength) {
          quill.deleteText(maxLength, quill.getLength());
        }
      });
    }

    setValue(value);
    setCommentLenght(valueLenght);
  };

  const onClickButton = (selectValue) => {
    const replacedValue = value.replace("<br>", "");
    if (value.length) {
      const newValue =
        replacedValue.toString().slice(0, replacedValue.length - 4) +
        selectValue +
        replacedValue
          .toString()
          .slice(replacedValue.length - 4, replacedValue.length);

      setValue(newValue);
    } else {
      setValue(selectValue);
    }
  };

  const onClickButtonSend = () => {
    if (getTextInHTML(value).length > 3 || files.length) {
      setValue("");
      setFiles([]);

      onSave(value, files, allMentioned);
      setAllMentioned([]);
    }
  };

  const openEmojiSelector = () => {
    setEmojiSelector(!emojiSelector);
  };

  const removeFile = useCallback(
    (selectedFile) => {
      const filteredFiles = files.filter((file, index) => {
        return `${file.name}-${index}` !== selectedFile;
      });

      setFiles(filteredFiles);
    },
    [files],
  );

  const isBlocked = useMemo(() => {
    return commentLength === maxLength;
  }, [commentLength, maxLength]);

  return (
    <Container
      isBlocked={isBlocked}
      activeSend={files.length || getTextInHTML(value).length > 3}
    >
      {!!maxLength && maxLength > 0 && (
        <MaxLength
          isBlocked={isBlocked}
        >{`${commentLength}/${maxLength}`}</MaxLength>
      )}
      <ReactQuill
        id="quill-comment"
        theme="snow"
        value={value}
        modules={modules}
        ref={refQuillEditor}
        onChange={setTextAndMaxLength}
      />
      {!!files.length && (
        <BoxFiles>
          {files.map((file, index) => (
            <File key={`${file.name}-${index}`}>
              <IconDelete
                className="fas fa-times"
                onClick={() => removeFile(`${file.name}-${index}`)}
              />
              <IconPreview
                className={`far ${fileIcons[file?.fileType] || "fa-file"}`}
              />
              <span>{file.name}</span>
            </File>
          ))}
        </BoxFiles>
      )}
      {editing ? (
        <CustomEditToolbar
          onClickButton={onClickButton}
          openEmojiSelector={openEmojiSelector}
          emojiSelector={emojiSelector}
        />
      ) : (
        <CustomToolbar
          getFiles={getFiles}
          onClickButton={onClickButton}
          openEmojiSelector={openEmojiSelector}
          emojiSelector={emojiSelector}
          onClickButtonSend={onClickButtonSend}
        />
      )}
    </Container>
  );
};

export default Editor;
