import React from "react";
import FormDialog from "../../Common/FormDialog";
import SimpleExcelCollumn from "../../Common/SimpleExcelCollumn";
import SimpleCheckbox from "../../Common/SimpleCheckbox";
import SimplePagination from "../../SimplePagination";
import { useWindowSize } from "../../../utils/customHooks";
import SelectCompanyMember from "../../SelectCompanyMember";
import useParticipantRoleChanges from "./useParticipantRoleChanges";

export default function ExchangeRolesTable({
  open,
  handleSaveExchangeRoles,
  onCancel,
  toolAdministration,
  enableSecondEvaluator,
  enableThirdEvaluator,
  members,
}) {
  const {
    selectModal,
    onlyWithChanges,
    page,
    setPage,
    pageItems,
    totalPages,
    handleConfirm,
    resetParticipantChanges,
    handleOpenSelectMember,
    selectedIds,
    toggleSelectedId,
    toggleSelectAll,
    allPageItemsSelected,
    handleOpenFilterByField,
    clearFilters,
    hasFilters,
    sumFiltersLength,
    filters,
    toggleShowOnlyWithChanges,
    toggleShowOnlyMissingEvaluators,
  } = useParticipantRoleChanges({
    toolAdministration,
    handleSaveExchangeRoles,
    members,
  });

  const windowSize = useWindowSize();
  const maxDialogSizeToScroll = windowSize[1] - 320;

  return (
    <FormDialog
      title="Movimentar Avaliadores"
      dialogClassName="exchange-roles-table-1100"
      open={open}
      onConfirm={handleConfirm}
      onCancel={onCancel}
      confirmText={`Salvar (${onlyWithChanges.length})`}
      cancelText="Fechar"
      blockConfirm={onlyWithChanges.length === 0}
      bodyStyle={{ padding: 0 }}
      bottomLeft={
        <SimplePagination
          page={page}
          setPage={setPage}
          pageItems={pageItems}
          totalPages={totalPages}
          menuPlacement="top"
        />
      }
    >
      <div className="row">
        <SelectCompanyMember
          open={selectModal.type === "select_member"}
          title={selectModal.title}
          onConfirm={selectModal.onConfirm}
          onCancel={selectModal.onCancel}
          selected={selectModal.selected}
          singleSelect={selectModal.singleSelect}
          canSaveUnselected={selectModal.canSaveUnselected}
        />
        <div className="row" style={{ display: "flex", alignItems: "center" }}>
          <div
            className="col-xs-12"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px",
            }}
          >
            <div style={{ display: "flex", gap: 10 }}>
              <button
                className="btn btn-purple"
                disabled={selectedIds.length === 0}
                onClick={() =>
                  handleOpenSelectMember({ multiple: true, field: "managerId" })
                }
              >
                Mudar gestor ({selectedIds.length})
              </button>
              {enableSecondEvaluator && (
                <button
                  className="btn btn-purple"
                  disabled={selectedIds.length === 0}
                  onClick={() =>
                    handleOpenSelectMember({
                      multiple: true,
                      field: "secondEvaluatorId",
                    })
                  }
                >
                  Mudar segundo avaliador ({selectedIds.length})
                </button>
              )}
              {enableThirdEvaluator && (
                <button
                  className="btn btn-purple"
                  disabled={selectedIds.length === 0}
                  onClick={() =>
                    handleOpenSelectMember({
                      multiple: true,
                      field: "thirdEvaluatorId",
                    })
                  }
                >
                  Mudar terceiro avaliador ({selectedIds.length})
                </button>
              )}
            </div>
            <div>
              <button
                className={`btn ${
                  filters.showOnlyMissingEvaluators ? "btn-danger" : "btn-gray"
                }`}
                onClick={toggleShowOnlyMissingEvaluators}
              >
                <i className="fa fa-user-times" /> Sem avaliador
              </button>
              <button
                className="btn btn-gray"
                onClick={toggleShowOnlyWithChanges}
              >
                {filters.showOnlyWithChanges ? (
                  <div>
                    <i className="fas fa-eye" /> Mostrar todos
                  </div>
                ) : (
                  <div>
                    <i className="fas fa-eye-slash" /> Apenas alterações
                  </div>
                )}
              </button>
              <button
                className="btn btn-gray"
                onClick={clearFilters}
                disabled={!hasFilters}
              >
                <i className="fas fa-eraser" /> Limpar filtros (
                {sumFiltersLength})
              </button>
            </div>
          </div>
        </div>
        <div className="col-xs-12">
          <div style={{ marginBottom: 10 }}>
            <SimplePagination
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          </div>
          <div
            style={{
              height: maxDialogSizeToScroll,
              maxHeight: windowSize[1] < 1000 ? "75hv" : maxDialogSizeToScroll,
              overflowX: "auto",
            }}
          >
            <table className="simple-table" style={{ margin: 0 }}>
              <thead>
                <tr>
                  <td style={{ padding: 0 }}>
                    <SimpleCheckbox
                      checked={allPageItemsSelected}
                      onChange={toggleSelectAll}
                      styles={{ padding: 10 }}
                    />
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => handleOpenFilterByField("participantIds")}
                  >
                    Participante <i className="fas fa-filter	" />
                  </td>
                  <td
                    width="250"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleOpenFilterByField("managerIds")}
                  >
                    Gestor imediato <i className="fas fa-filter	" />
                  </td>
                  {enableSecondEvaluator && (
                    <td
                      width="250"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleOpenFilterByField("secondEvaluatorIds")
                      }
                    >
                      Segundo Avaliador <i className="fas fa-filter	" />
                    </td>
                  )}
                  {enableThirdEvaluator && (
                    <td
                      width="250"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleOpenFilterByField("thirdEvaluatorIds")
                      }
                    >
                      Terceiro Avaliador <i className="fas fa-filter	" />
                    </td>
                  )}
                  <td width="30">Reverter</td>
                </tr>
              </thead>
              <tbody>
                {pageItems?.length === 0 && (
                  <td colSpan="100" align="center">
                    Nenhum resultado
                  </td>
                )}
                {pageItems.map(
                  ({
                    participant,
                    hasChanges,
                    manager,
                    secondEvaluator,
                    thirdEvaluator,
                    diff,
                  }) => {
                    return (
                      <tr key={participant.id}>
                        <td width="40px" style={{ padding: 0 }}>
                          <SimpleCheckbox
                            checked={selectedIds?.includes(participant.id)}
                            onChange={() => toggleSelectedId(participant.id)}
                            styles={{ padding: 10 }}
                          />
                        </td>
                        <td>
                          {participant?.memberParticipant?.name}
                          {hasChanges && (
                            <i
                              className="fas fa-save"
                              style={{
                                marginLeft: 10,
                                color: "#999",
                              }}
                              title="Alterações realizadas"
                            />
                          )}
                        </td>
                        <SimpleExcelCollumn
                          id={`manager${participant.id}`}
                          label="Gestor imediato"
                          type="text"
                          value={manager?.name || ""}
                          onClick={() =>
                            handleOpenSelectMember({
                              participant,
                              field: "managerId",
                            })
                          }
                          inputStyle={{
                            fontSize: "10px",
                            cursor: "pointer",
                            borderLeft: diff.managerId ? "2px solid green" : "",
                          }}
                          singleSelect
                        />
                        {enableSecondEvaluator && (
                          <SimpleExcelCollumn
                            id={`secondEvaluator${participant.id}`}
                            label="Cliente"
                            type="text"
                            value={secondEvaluator?.name || "- Selecione -"}
                            onClick={() =>
                              handleOpenSelectMember({
                                participant,
                                field: "secondEvaluatorId",
                              })
                            }
                            inputStyle={{
                              fontSize: "10px",
                              cursor: "pointer",
                              borderLeft: diff.secondEvaluatorId
                                ? "2px solid green"
                                : "",
                            }}
                            singleSelect
                          />
                        )}
                        {enableThirdEvaluator && (
                          <SimpleExcelCollumn
                            id={`thirdEvaluator${participant.id}`}
                            label="Fornecedor"
                            type="text"
                            value={thirdEvaluator?.name || "- Selecione -"}
                            onClick={() =>
                              handleOpenSelectMember({
                                participant,
                                field: "thirdEvaluatorId",
                              })
                            }
                            inputStyle={{
                              fontSize: "10px",
                              cursor: "pointer",
                              borderLeft: diff.thirdEvaluatorId
                                ? "2px solid green"
                                : "",
                            }}
                            singleSelect
                          />
                        )}
                        <td
                          align="center"
                          onClick={
                            hasChanges
                              ? () => resetParticipantChanges(participant.id)
                              : () => {}
                          }
                          style={
                            hasChanges
                              ? { cursor: "pointer", color: "tomato" }
                              : { opacity: 0.5 }
                          }
                        >
                          <i className="fas fa-history text-danger" />
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </FormDialog>
  );
}
