const en = {
  no_scenario_added: "No scenarios have been added",
  scenario_main_title: "Scenario",
  add_new_scenario: "Add scenario",
};

const es = {
  no_scenario_added: "No se han agregado escenarios",
  scenario_main_title: "Escenarios",
  add_new_scenario: "Agregar escenario",
};

const pt = {
  no_scenario_added: "Nenhum cenário foi adicionado",
  scenario_main_title: "Cenário",
  add_new_scenario: "Adicionar cenário",
};

const translations = { en, es, pt };

export default translations;
