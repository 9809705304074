import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import utils from "../../../../utils/toolUtils";
import gbUtils from "../../../../utils/goalsBonus";
import UpdateParticipantWorkflowDialog from "../../../Common/GoalsAndBonus/forms/UpdateParticipantWorkflowDialog";
import { allowedSendWorkflowApproval } from "../../../../constants/goalsAndBonus";
import {
  updateGoalBonusAdministration,
  addWorkflowComment,
} from "../../../../actions/goalsAndBonusActions";
import {
  getSelectedToolStates,
  getCompanyMembersAndParticipants,
} from "../../../customMapStates";

const { getOnlySlugValues } = utils;

const {
  buildRestAdmConfigBody,
  getGoalsRelationTree,
  getAdminApproversEmails,
  getGoalWorkflowParticipantEmails,
  getApproversEmails,
} = gbUtils;

const UpdateParticipantWorkflow = (props) => {
  const {
    params = {},
    type,
    clearViewCallback,
    userId = null,
    userName = null,
    updateGoalBonusAdministration,
    addWorkflowComment,
    selectedTool,
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
    allMembers,
  } = props;

  const {
    goalBonusAdministration = {},
    onlyGoals = [],
    newStatus = "APPROVAL",
    toolId = null,
  } = params;

  const filteredGoals = [];
  const blockGoals = [];

  goalBonusAdministration.goals.forEach((goalInfo) => {
    if (onlyGoals.indexOf(goalInfo.id) > -1) {
      filteredGoals.push(goalInfo);
      if (allowedSendWorkflowApproval.indexOf(goalInfo.workflowStatus) === -1)
        blockGoals.push(goalInfo.id);
    }
  });

  const getRelationTree = () => {
    return getGoalsRelationTree(
      selectedTool.id,
      allSceneries,
      allThemes,
      allQuestions,
      allAnswers,
    );
  };

  const updateGoalsOnList = (goalsList, updateGoals, workflowStatus) => {
    const list = [...goalsList];

    list?.forEach((goalInfo, index) => {
      if (goalInfo && goalInfo.id && updateGoals.indexOf(goalInfo.id) > -1)
        list[index] = { ...goalInfo, workflowStatus };
    });

    return list;
  };

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
  };

  const handleConfirm = (form) => {
    const goalsRelationTree = getRelationTree();

    let newGoalBonusAdmin = _.cloneDeep(goalBonusAdministration);

    const sharedGoals = [];
    const individualGoals = [];

    let sharedEmails = [];
    const sharedParticipantEmails = [];
    let individualEmails = [];
    const individualParticipantEmails = [];

    function addParticipantEmails(
      emails,
      appendTo = "sharedParticipantEmails",
    ) {
      if (appendTo === "individualParticipantEmails") {
        emails.forEach((email) => {
          if (individualParticipantEmails.indexOf(email) === -1)
            individualParticipantEmails.push(email);
        });
      }

      if (appendTo === "sharedParticipantEmails") {
        emails.forEach((email) => {
          if (sharedParticipantEmails.indexOf(email) === -1)
            sharedParticipantEmails.push(email);
        });
      }
    }

    form.selectedGoals.forEach((goalInfo) => {
      if (goalInfo.relationshipType === "INDIVIDUAL")
        individualGoals.push(goalInfo);
      if (goalInfo.relationshipType !== "INDIVIDUAL")
        sharedGoals.push(goalInfo);
    });

    if (form.sendEmails) {
      const singleIndividualGoal = individualGoals[0] || {};

      individualEmails = getApproversEmails(
        singleIndividualGoal,
        goalBonusAdministration,
        goalsRelationTree,
        allMembers,
      );

      sharedEmails = getAdminApproversEmails(
        goalBonusAdministration.administrativeRoles,
        allMembers,
      );
    }

    if (form.sendToParticipants) {
      individualGoals.forEach((goalInfo) => {
        const participantEmails = getGoalWorkflowParticipantEmails(
          goalBonusAdministration,
          goalsRelationTree,
          goalInfo.id,
        );

        addParticipantEmails(participantEmails, "individualParticipantEmails");
      });

      sharedGoals.forEach((goalInfo) => {
        const participantEmails = getGoalWorkflowParticipantEmails(
          goalBonusAdministration,
          goalsRelationTree,
          goalInfo.id,
        );

        addParticipantEmails(participantEmails, "sharedParticipantEmails");
      });
    }

    function updateGoalsOnAdmin(currentGoals, updateGoalsId = []) {
      return updateGoalsOnList(currentGoals, updateGoalsId, newStatus);
    }

    function updateGoals(currentAdministration, updateGoalsIds = []) {
      return {
        ...currentAdministration,
        goals: updateGoalsOnAdmin(currentAdministration.goals, updateGoalsIds),
      };
    }

    function saveComment(goals = [], emails = []) {
      addWorkflowComment(
        goals,
        form.comment,
        userId,
        newStatus,
        emails,
        userName,
      );
    }

    const validInformations = form.comment && toolId && userId;

    if (validInformations && sharedGoals.length > 0) {
      newGoalBonusAdmin = updateGoals(
        newGoalBonusAdmin,
        getOnlySlugValues(sharedGoals, "id"),
      );

      saveComment(sharedGoals, {
        approvers: sharedEmails,
        participants: sharedParticipantEmails,
      });
    }

    if (validInformations && individualGoals.length > 0) {
      newGoalBonusAdmin = updateGoals(
        newGoalBonusAdmin,
        getOnlySlugValues(individualGoals, "id"),
      );

      saveComment(individualGoals, {
        approvers: individualEmails,
        participants: individualParticipantEmails,
      });
    }

    if (!_.isEqual(goalBonusAdministration, newGoalBonusAdmin)) {
      newGoalBonusAdmin = buildRestAdmConfigBody(newGoalBonusAdmin);

      updateGoalBonusAdministration(toolId, newGoalBonusAdmin);
    }

    clearDialogControll();
  };

  const handleCancel = () => {
    clearDialogControll();
  };

  return (
    <UpdateParticipantWorkflowDialog
      open
      goals={filteredGoals}
      blockGoals={blockGoals}
      afterConfirm={(form) => handleConfirm(form)}
      afterCancel={handleCancel}
    />
  );
};

const mapStateToProps = (state) => {
  const { selectedTool, allSceneries, allThemes, allQuestions, allAnswers } =
    getSelectedToolStates(state);
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);
  const { user = {} } = state;

  return {
    userId: user.id || null,
    userName: `${user.firstName} ${user.lastName}`,
    selectedTool,
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
    allMembers: companyMembersAndParticipants,
  };
};

export default connect(mapStateToProps, {
  updateGoalBonusAdministration,
  addWorkflowComment,
})(UpdateParticipantWorkflow);
