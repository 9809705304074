import React, { useState } from "react";
import { injectIntl, defineMessages } from "react-intl";
import _ from "lodash";
import FormDialog from "../../FormDialog";
import MaterialTextField from "../../MaterialTextField";

const initialForm = {
  id: null,
  title: "",
  description: "",
};

const messages = defineMessages({
  gb_dialog_title_trigger: { id: "gb_dialog_title_trigger" },
  gb_form_title: { id: "gb_form_title" },
  gb_form_description: { id: "gb_form_description" },
});

const NewTriggerDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const {
    open = false,
    afterCancel,
    afterConfirm,
    goalBonusAdministration,
    setGoalBonusAdministration,
    intl,
  } = props;

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleConfirm = () => {
    let submitGoalsBonusAdm = goalBonusAdministration;

    submitGoalsBonusAdm = {
      ...goalBonusAdministration,
      triggers: [...goalBonusAdministration.triggers, { ...form }],
    };

    if (!_.isEqual(submitGoalsBonusAdm, goalBonusAdministration))
      setGoalBonusAdministration(submitGoalsBonusAdm);

    resetForm();

    if (typeof afterConfirm === "function") afterConfirm(submitGoalsBonusAdm);
  };

  const handleCancel = () => {
    resetForm();

    if (typeof afterCancel === "function") afterCancel();
  };

  const isValidForm = () => {
    if (form.title < 2) return false;

    return true;
  };

  return (
    <FormDialog
      open={open}
      title={intl.formatMessage(messages.gb_dialog_title_trigger)}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      blockConfirm={!isValidForm()}
    >
      <div className="row">
        <div className="col-xs-12">
          <MaterialTextField
            id="title"
            label={intl.formatMessage(messages.gb_form_title)}
            value={form.title}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
          />
        </div>
        <div className="col-xs-12">
          <MaterialTextField
            id="description"
            label={intl.formatMessage(messages.gb_form_description)}
            value={form.description}
            onChange={(e) => setForm({ ...form, description: e.target.value })}
            multiline
          />
        </div>
      </div>
    </FormDialog>
  );
};

export default injectIntl(NewTriggerDialog);
