import React from "react";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import AuthSelector from "./components/Security/AuthSelector";

const IntlWraper = (props) => {
  const { language, translations } = props;

  return (
    <IntlProvider locale={language} messages={translations}>
      <AuthSelector />
    </IntlProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
    translations: state.translations,
  };
};

export default connect(mapStateToProps, null)(IntlWraper);
