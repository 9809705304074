/* eslint-disable react/prop-types */
import React from "react";
import { defineMessages } from "react-intl";
import {
  getSingleIndicatorResults,
  getPeriodTargetsDivision,
  getFrequencyPeriodsInfo,
  getOrderedTargets,
} from "../../../../utils/kpis/calc2";
import Avatar from "../../Avatar";
import ComposedPeriodsChart from "./ComposedPeriodsChart";
import PeriodsEvaluationColumns from "./PeriodsEvaluationColumns";
import {
  getDisplayTargets,
  getLastPeriodDeviation,
} from "../../../../utils/kpis/calc";
import {
  displayTransformValue,
  getDefaultTargetCols,
  getHitTargetInfo,
  PeriodColumns,
} from "../../../../utils/kpis/display";
import {
  getFilteredActionsPlanByInsights,
  getTotalActionsByInsights,
} from "../../../../utils/actionsPlan/filter";
import { translatedText } from "../../../../utils/translationUtils";
import {
  getPerformanceCol,
  GetTargetCol,
  getWidthTd,
} from "../../../../utils/tableUtils";
import {
  getObjectInfoById,
  orderArrayByObjAttr,
} from "../../../../utils/ArrayUtils";

const messages = defineMessages({
  kpi_table_text_goal_upper: { id: "kpi_table_text_goal_upper" },
  kpi_table_text_prev_period: { id: "kpi_table_text_prev_period" },
  kpi_table_text_lp_deviation_expn: {
    id: "kpi_table_text_lp_deviation_expn",
  },
  kpi_table_text_actions: { id: "kpi_table_text_actions" },
  kpi_table_text_period: { id: "kpi_table_text_period" },
  kpi_form_unity: { id: "kpi_form_unity" },
  kpi_table_text_actual: { id: "kpi_table_text_actual" },
  kpi_table_text_current_deviation_expn: {
    id: "kpi_table_text_current_deviation_expn",
  },
  kpi_table_text_no_goal_found: { id: "kpi_table_text_no_goal_found" },
  kpi_options_rt_shared: { id: "kpi_options_rt_shared" },
});

const translation = (id) => translatedText(id, messages);

const TD = (props) => getWidthTd(props);

const IndicatorsTable = ({
  kpiAdministration,
  isPeriodFilterActive: filter,
  indicators = [],
  actionsPlan,
  allAnswers,
  selectAndOpenInsight,
  measurementUnityOptions,
}) => {
  const oveflowTextStyles = {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "pre-wrap",
    maxWidth: "250px",
    cursor: "pointer",
  };

  return (
    <table className="simple-table horizontal-scroll">
      <thead>
        <tr>
          <TD width="200px" text={translation("kpi_table_text_goal_upper")} />
          <TD width="50px" text={translation("kpi_form_unity")} />
          {getDefaultTargetCols({ width: "100px", fontSize: "10px" })}
          <TD width="100px" text={translation("kpi_table_text_prev_period")} />
          <TD
            width="45px"
            text="Δ"
            title={translation("kpi_table_text_lp_deviation_expn")}
          />
          <TD width="100px" text={translation("kpi_table_text_actual")} />
          <TD
            width="45px"
            text="Δ"
            title={translation("kpi_table_text_current_deviation_expn")}
          />
          <TD width="90px" text="Gráfico" />
          <PeriodColumns periodCycle={kpiAdministration?.periodCycle} />
          <TD width="45px" text="Score" style={{ fontSize: "10px" }} />
          <TD width="50px" text={translation("kpi_table_text_actions")} />
        </tr>
      </thead>
      <tbody style={{ fontWeight: "bold" }}>
        {orderArrayByObjAttr(indicators, "title").map((goalInfo) => {
          const {
            relatedInsights = [],
            target = {},
            original: originalGoal,
          } = goalInfo;

          const mainRelatedInsightId = relatedInsights[0] || null;

          const {
            goalPercentage,
            notStarted,
            courseTargetsDivision,
            goalPeriodTotalEvaluation,
            lastYearCourseEvaluation,
            accumulated,
          } = getSingleIndicatorResults(
            goalInfo,
            kpiAdministration.goalTargets,
            kpiAdministration.zeroWhenNegative,
          );

          const displayPercentage = filter
            ? accumulated.goalPercentage
            : goalPercentage;
          const displayTargets = filter
            ? accumulated.target
            : courseTargetsDivision;

          const hitTargetInfo = getHitTargetInfo(
            displayPercentage,
            kpiAdministration.goalTargets,
            notStarted,
          );

          const { periods } = getFrequencyPeriodsInfo(goalInfo.frequency);

          const totalPeriodsMultiplier = 12 / periods;

          const filteredInsightActionsPlan = getFilteredActionsPlanByInsights(
            [mainRelatedInsightId],
            actionsPlan,
          );

          const periodTargetsDivision = getPeriodTargetsDivision(goalInfo);

          const { displayCourseTargets, displayTotal } = getDisplayTargets(
            goalInfo,
            goalPeriodTotalEvaluation,
            displayTargets,
          );

          const transformedAcumulated = displayTransformValue(
            displayTotal,
            goalInfo.measurementUnity,
            2,
          );

          const isConsolidated = goalInfo.relationshipType === "SHARED";

          const { displayDeviation: goalDeviation, color: goalDeviationColor } =
            getLastPeriodDeviation(
              displayTargets?.appropriated,
              goalPeriodTotalEvaluation,
              target,
              notStarted,
            );

          const {
            displayDeviation: lastPeriodDeviation,
            color: lastYearDeviationColor,
          } = getLastPeriodDeviation(
            lastYearCourseEvaluation,
            goalPeriodTotalEvaluation,
            target,
            notStarted,
          );

          const { actual: actualTargetValues } = getOrderedTargets(goalInfo);

          return (
            <tr key={goalInfo.id} className="block-white-space">
              <td style={{ backgroundColor: "#f6f6f6" }} title={goalInfo.title}>
                <div
                  style={oveflowTextStyles}
                  onClick={() => selectAndOpenInsight(goalInfo)}
                >
                  <Avatar
                    src={goalInfo.avatarId}
                    name={goalInfo.responsibleName}
                    style={{
                      width: "30px",
                      height: "30px",
                      marginRight: "5px",
                    }}
                  />
                  <span style={isConsolidated ? { fontWeight: "500" } : {}}>
                    {isConsolidated && (
                      <i
                        className="fas fa-stream"
                        style={{
                          color: "#ffc875",
                          fontSize: "17px",
                          paddingRight: "7px",
                          position: "relative",
                          top: "1px",
                        }}
                      />
                    )}
                    {goalInfo.title}
                  </span>
                </div>
              </td>
              <td align="center" style={{ whiteSpace: "nowrap" }}>
                {
                  getObjectInfoById(
                    goalInfo.measurementUnity,
                    measurementUnityOptions,
                    "value",
                  ).label
                }
                {goalInfo.measurementUnity === "OTHER" &&
                  ` (${goalInfo.otherMeasurement})`}
              </td>
              <td align="right">
                {displayTransformValue(
                  displayCourseTargets?.gate,
                  goalInfo.measurementUnity,
                  2,
                )}
              </td>
              <td align="right">
                {displayTransformValue(
                  displayCourseTargets?.appropriated,
                  goalInfo.measurementUnity,
                  2,
                )}
              </td>
              <td align="right">
                {displayTransformValue(
                  displayCourseTargets?.exceeded,
                  goalInfo.measurementUnity,
                  2,
                )}
              </td>
              <td align="right">
                {displayTransformValue(
                  lastYearCourseEvaluation,
                  goalInfo.measurementUnity,
                  2,
                  <span style={{ color: "rgba(204,204,204)" }}>N/A</span>,
                )}
              </td>
              <td
                align="right"
                style={{ color: lastYearDeviationColor, width: "45px" }}
                title={translation("kpi_table_text_lp_deviation_expn")}
              >
                {lastPeriodDeviation}
              </td>
              <GetTargetCol
                text={transformedAcumulated}
                options={{
                  color: hitTargetInfo.color,
                  notStarted,
                }}
              />
              <td
                align="right"
                style={{ color: goalDeviationColor }}
                title={translation("kpi_table_text_current_deviation_expn")}
              >
                {goalDeviation}
              </td>
              <td>
                <ComposedPeriodsChart
                  goalInfo={goalInfo}
                  periodTargetsDivision={periodTargetsDivision}
                  actualTargetValues={actualTargetValues}
                  goalTargetConfigurations={kpiAdministration.goalTargets}
                  zeroWhenNegative={kpiAdministration.zeroWhenNegative}
                  allAnswers={allAnswers}
                  kpiAdministration={kpiAdministration}
                />
              </td>
              <PeriodsEvaluationColumns
                goalInfo={{ ...goalInfo, target: originalGoal.target }}
                actualTargetValues={actualTargetValues}
                periodTargetsDivision={periodTargetsDivision}
                goalTargets={kpiAdministration.goalTargets}
                zeroWhenNegative={kpiAdministration.zeroWhenNegative}
                totalPeriodsMultiplier={totalPeriodsMultiplier}
              />
              {getPerformanceCol(
                displayPercentage,
                {
                  color: hitTargetInfo.color,
                  notStarted,
                },
                1,
              )}
              <td align="center">
                {
                  getTotalActionsByInsights(
                    [mainRelatedInsightId],
                    filteredInsightActionsPlan,
                  ).length
                }
              </td>
            </tr>
          );
        })}
        {indicators.length === 0 && (
          <tr>
            <td colSpan="100">{translation("kpi_table_text_no_goal_found")}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default IndicatorsTable;
