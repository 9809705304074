export const evaluationTypeErrorSlugs = () => {
  return ["invalid_weights"];
};

export const dateErrorSlugs = () => {
  const possibleErrors = [];

  const slugs = [
    "validation",
    "evaluation",
    "evaluators",
    "closure",
    "pdf",
    "feedback",
  ];

  const errors = ["invalid_dates", "invalid_dates_start_end"];

  errors.forEach((err) => {
    slugs.forEach((slug) => {
      possibleErrors.push(`${err}_${slug}`);
    });
  });

  return possibleErrors;
};

export const evaluationScaleErrorSlugs = () => {
  return ["evaluation_scale_invalid_value", "evaluation_scale_invalid_title"];
};

export const qualificationScaleErrorSlugs = () => {
  return [
    "qualification_scale_invalid_value",
    "qualification_scale_invalid_title",
  ];
};

export const potentialScaleErrorSlugs = () => {
  return ["potential_scale_invalid_value", "potential_scale_invalid_title"];
};

export const keySeatScaleErrorSlugs = () => {
  return ["key_seat_scale_invalid_value", "key_seat_scale_invalid_title"];
};

export const keyseatEvaluationsErrorSlugs = () => {
  return [
    "invalid_key_seat_complementary_evaluation",
    "invalid_key_seat_complementary_evaluation_title",
  ];
};

export const performanceScaleErrorSlugs = () => {
  return ["performance_scale_invalid_value", "performance_scale_invalid_title"];
};

export const performanceEvaluationsErrorSlugs = () => {
  return [
    "invalid_performance_complementary_evaluation",
    "invalid_performance_complementary_evaluation_title",
  ];
};

export const profileErrorSlugs = () => {
  return ["invalid_profile", "invalid_profile_title"];
};

export const participantErrorSlugs = () => {
  return [
    "invalid_participant",
    "invalid_participant_member",
    "invalid_participant_area",
    "invalid_participant_role",
    "invalid_participant_profile",
    "invalid_participant_manager",
    "invalid_participant_second_evaluator",
    "invalid_participant_third_evaluator",
  ];
};

export const competenceAreaErrorSlugs = () => {
  return [
    "invalid_competence_area",
    "invalid_competence_area_title",
    "invalid_competence_area_profiles",
  ];
};

export const skillErrorSlugs = () => {
  return [
    "invalid_skill",
    "invalid_skill_title",
    "invalid_skill_competenceArea",
  ];
};

export const workflowErrorSlugs = () => {
  return [
    "validation_not_started",
    "validationEnded",
    "no_manager",
    "invalid_workflow_values_selfEvaluation",
    "invalid_workflow_values_managerEvaluation",
    "invalid_workflow_values_potential",
    "invalid_workflow_values_secondEvaluator",
    "invalid_workflow_values_thirdEvaluator",
    "invalid_workflow_values_complementary",
    "no_second_evaluator",
  ];
};

export const tabGeneralConfigErrorSlugs = () => {
  return [...evaluationTypeErrorSlugs(), ...dateErrorSlugs()];
};

export const tabScalesErrorSlugs = () => {
  return [
    ...evaluationScaleErrorSlugs(),
    ...qualificationScaleErrorSlugs(),
    ...potentialScaleErrorSlugs(),
  ];
};

export const tabPublicErrorSlugs = () => {
  return [...profileErrorSlugs(), ...participantErrorSlugs()];
};

export const tabEvaluationErrorSlugs = () => {
  return [...competenceAreaErrorSlugs(), ...skillErrorSlugs()];
};

export const tabComplementaryEvaluationsErrorSlugs = () => {
  return [
    ...keySeatScaleErrorSlugs(),
    ...keyseatEvaluationsErrorSlugs(),
    ...performanceScaleErrorSlugs(),
    ...performanceEvaluationsErrorSlugs(),
  ];
};

export const getTabErrorSlugs = (tabKey) => {
  if (tabKey === "configTab") return tabGeneralConfigErrorSlugs();
  if (tabKey === "scalesTab") return tabScalesErrorSlugs();
  if (tabKey === "publicTab") return tabPublicErrorSlugs();
  if (tabKey === "evaluationTab") return tabEvaluationErrorSlugs();
  if (tabKey === "complementaryEvaluations")
    return tabComplementaryEvaluationsErrorSlugs();

  return [];
};
