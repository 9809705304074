import axios from "axios";

import { fetchDomainCompanies } from "./accessLevelsActions";

import {
  USER_ID,
  SELECTED_COMPANY,
  SET_SELECTED_COMPANY,
  FETCH_USER_OWNER_COMPANIES,
  FETCH_USER_MEMBER_COMPANIES,
  CREATE_COMPANY,
  UPDATE_COMPANY,
  FETCH_COMPANY_TEAMS,
  FETCH_COMPANY_MEMBERS,
  FETCH_MEMBER_WITH_AREA,
  FETCH_COMPANY_PARTICIPANTS,
  FETCH_ALL_COMPANY_WORKSPACES,
  AVAILABLE_NAMESPACE,
  FETCH_ALL_COMPANY_TOOLS_TYPE,
  FETCH_MINIFIED_COMPANY_WORKSPACES,
  SELECTED_TOOL_ID,
  SELECTED_WORKSPACE,
  SET_SELECTED_WORKSPACE,
} from "./types";

import {
  unsetSelectedWorkspace,
  getAllCompanyWorkspaces,
  getMinifiedCompanyWorkspaces,
} from "./workspaceActions";
import { addMemberToCompany } from "./userActions";

export const fetchAllCompanyWorkspaces = (companyId) => {
  if (!companyId) return;

  return async (dispatch) => {
    const fetchedWorkspaces = await getAllCompanyWorkspaces(companyId, true);

    if (fetchedWorkspaces.data) {
      const companyWorkspaces = fetchedWorkspaces.data.map((workspaceInfo) => {
        return { ...workspaceInfo, companyId };
      });

      dispatch({
        type: FETCH_ALL_COMPANY_WORKSPACES,
        payload: companyWorkspaces,
      });
    }
  };
};

export const fetchMinifiedCompanyWorkspaces = (companyId) => {
  if (!companyId) return;

  return async (dispatch) => {
    const minifiedWorkspaces = await getMinifiedCompanyWorkspaces(companyId);

    if (!minifiedWorkspaces) return;

    dispatch({
      type: FETCH_MINIFIED_COMPANY_WORKSPACES,
      payload: minifiedWorkspaces,
    });
  };
};

export const setDefaultLogo = async (companyId, defaultValue) => {
  const response = await axios.put(
    `/api/companies/${companyId}/updateLogoDefault/${defaultValue}`,
  );

  return (dispatch) => {
    dispatch({ type: SET_SELECTED_COMPANY, payload: response.data });
  };
};

export const fetchUserOwnerCompanies = (userId) => {
  return (dispatch) => {
    return axios.get(`/api/companies/owner/${userId}`).then((res) => {
      const companies = res.data;
      dispatch({ type: FETCH_USER_OWNER_COMPANIES, payload: companies });
    });
  };
};

export const fetchUserMemberCompanies = (userId) => {
  return (dispatch) => {
    return axios.get(`/api/companies/member/${userId}`).then((res) => {
      const companies = res.data;

      dispatch({ type: FETCH_USER_MEMBER_COMPANIES, payload: companies });
    });
  };
};

export const fetchCompanyMembers = (companyID) => {
  return async (dispatch) => {
    const fetchedMembers = await axios.get(
      `/api/companies/${companyID}/members`,
    );

    return dispatch({
      type: FETCH_COMPANY_MEMBERS,
      payload: fetchedMembers.data,
    });
  };
};

export const fetchCompanyMembersWithPosition = (companyID) => {
  return async (dispatch) => {
    const fetchedMembers = await axios.get(
      `/api/companies/${companyID}/membersWithPositionAndArea`,
    );

    return dispatch({
      type: FETCH_MEMBER_WITH_AREA,
      payload: fetchedMembers.data,
    });
  };
};

export const fetchCompanyParticipants = (companyID) => {
  return async (dispatch) => {
    const fetchedParticipants = await axios.get(
      `/api/companies/${companyID}/participants`,
    );

    return dispatch({
      type: FETCH_COMPANY_PARTICIPANTS,
      payload: fetchedParticipants.data,
    });
  };
};

export const fetchCompanieTeams = (companyID) => {
  return async (dispatch) => {
    const companieTeams = await axios.get(`/api/companies/${companyID}/teams`);
    return dispatch({ type: FETCH_COMPANY_TEAMS, payload: companieTeams.data });
  };
};

export const getAllCompanyToolsByType = (companyId, toolId) => {
  return axios
    .get(`/api/company/${companyId}/selectedTools/${toolId}`)
    .then((res) => res);
};

export const fetchAllCompanyToolsByType = (companyId, toolTypes) => {
  return async (dispatch) => {
    const buildToolsByType = async () => {
      const finalFilteredTools = {};

      if (toolTypes && toolTypes.length > 0) {
        const filteredTools = await getAllCompanyToolsByTypes(
          companyId,
          toolTypes,
        ).then((res) => res.data);

        filteredTools.forEach((itemInfo) => {
          const { toolInfo = {} } = itemInfo || {};
          const { id: toolId = 0 } = toolInfo || {};

          const currentByType = finalFilteredTools[toolId]
            ? finalFilteredTools[toolId]
            : [];

          finalFilteredTools[toolId] = [...currentByType, itemInfo];
        });
      }

      dispatch({
        type: FETCH_ALL_COMPANY_TOOLS_TYPE,
        payload: finalFilteredTools,
      });

      dispatch({
        type: "DASH_REQUESTS_LOADING",
        payload: { loading: false, time: new Date() },
      });
    };

    dispatch({
      type: "DASH_REQUESTS_LOADING",
      payload: { loading: true, time: new Date() },
    });

    buildToolsByType();
  };
};

export const getAllCompanyToolsByTypes = (companyId = null, types = []) => {
  return axios
    .post(`/api/company/${companyId}/selectedTools/`, types)
    .then((res) => res);
};

export const setSelectedCompany = (company) => {
  return async (dispatch) => {
    localStorage.setItem(SELECTED_COMPANY, company.id);
    await dispatch({ type: SET_SELECTED_COMPANY, payload: company });
  };
};

export const setCompanyLogo = (image, companyId) => {
  return (dispatch) => {
    axios
      .post(`/api/s3/upload-file?s3bucket=${"COMPANY_LOGO"}`, image)
      .then((file) => {
        axios
          .put(`/api/companies/${companyId}/updateLogo/${file.data}`)
          .then((company) => {
            dispatch({
              type: SET_SELECTED_COMPANY,
              payload: company.data,
            });
            dispatch(setDefaultLogo(companyId, false));
          });
      });
  };
};

export const getSelectedCompany = () => {
  return (dispatch) => {
    const selectedCompanyId = localStorage.getItem(SELECTED_COMPANY);
    const userId = localStorage.getItem(USER_ID);

    if (selectedCompanyId) {
      return axios
        .get(`/api/companies/${selectedCompanyId}`)
        .then(({ data: selectedCompany }) => {
          dispatch(setSelectedCompany(selectedCompany, userId));
        });
    }
  };
};

export const getCompany = (companyId) => {
  /*
  const cachedCompany = localStorage.getItem(String(`company${companyId}`));

  if (cachedCompany) return cachedCompany[0];
*/

  if (!companyId) return {};

  return axios.get(`/api/companies/${companyId}`).then((res) => {
    localStorage.setItem(String(`company${companyId}`), [res.data]);
    return res.data;
  });
};

export const resetSelectedEntityStorage = () => {
  return (dispatch) => {
    localStorage.removeItem(SELECTED_COMPANY);
    localStorage.removeItem(SELECTED_TOOL_ID);
    localStorage.removeItem(SELECTED_WORKSPACE);
    dispatch({ type: SET_SELECTED_WORKSPACE, payload: null });
    dispatch({ type: SET_SELECTED_COMPANY, payload: null });
  };
};

export const isAvailableSpace = (namespace) => {
  return (dispatch) => {
    dispatch({ type: AVAILABLE_NAMESPACE, payload: true });
  };
};

export const createCompany = (company = {}, domainId = 0) => {
  return async (dispatch) => {
    axios
      .post(`/api/companies/domain/${domainId}`, company)
      .then(async (res) => {
        const { grantAdminAccess } = await import("./accessControllerActions");

        const { id: companyId = 0 } = res.data;
        const { owner = {} } = company;

        const { email: ownerEmail } = owner;

        await dispatch({
          type: CREATE_COMPANY,
          payload: { ...res.data, ownerName: company.ownerName },
        });

        await dispatch(grantAdminAccess(companyId));
        await dispatch(fetchDomainCompanies());
        await dispatch(addMemberToCompany(companyId, ownerEmail));
      });
  };
};

export const updateCompany = (company) => {
  return (dispatch) => {
    return axios.put("/api/companies", company).then((res) => {
      dispatch({ type: UPDATE_COMPANY, payload: res.data });
    });
  };
};

export const deleteCompany = (companyId) => {
  return (dispatch) => {
    return axios.delete(`/api/companies/${companyId}`).then((res) => {
      dispatch(fetchDomainCompanies());
    });
  };
};
