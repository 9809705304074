const en = {
  bs_main_title: "Balanced Scorecard",
};

const es = {
  bs_main_title: "Balanced Scorecard",
};

const pt = {
  bs_main_title: "Balanced Scorecard",
};

const translations = { en, es, pt };

export default translations;
