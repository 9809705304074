import React from "react";
import { Row } from "react-bootstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { OverlayTrigger } from "react-bootstrap";
import { Popover } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

const PreviewTable = (props) => {
  const { headers = {}, data = [], handleErrorMessage, intl = {} } = props;

  const getHeaderSlugs = () => {
    let slugs = [];

    Object.keys(headers).forEach((header) => {
      slugs = [...slugs, header];
    });

    return slugs;
  };

  const slugs = getHeaderSlugs();

  const handleErrorField = (errorType = "", isError = false) => {
    if (!isError) return errorType;

    return (
      <a style={{ cursor: "pointer" }}>
        <i
          className="fas fa-times-circle"
          style={{ fontSize: "18px", color: "#ff4d4d" }}
        />
      </a>
    );
  };

  const handleCustomErrorCSS = (row = {}, slug = "") => {
    const errorType = row.errorType || "empty";

    if (String(errorType) === String(slug))
      return "previewTableCell errorBackground";

    return "previewTableCell";
  };

  return (
    <TableContainer component={Paper}>
      <Row>
        <Table
          className="table table-striped table-hover"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow className="customGenericRow">
              {Object.keys(headers).map((header) => {
                return (
                  <TableCell align="center" key={header}>
                    <h6 style={{ width: "105px" }}>{headers[header]}</h6>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        </Table>
      </Row>
      <Row className="customTableTeste">
        <Table
          className="table table-striped table-hover"
          aria-label="simple table"
        >
          <TableBody>
            {data.map((row) => (
              <TableRow key={uuidv4()} className="customGenericRow">
                {slugs.map((slug) => {
                  const errorField = slug === "errorType";
                  const customErrorCSS = handleCustomErrorCSS(row, slug);

                  return (
                    <TableCell
                      align="center"
                      key={slug}
                      className={customErrorCSS}
                    >
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Popover
                            id="popoverInformation"
                            style={{ zIndex: "99999" }}
                          >
                            {errorField
                              ? handleErrorMessage &&
                                intl &&
                                handleErrorMessage(row[slug], intl)
                              : row[slug]}
                          </Popover>
                        }
                      >
                        <h6 className="previewTableCell">
                          {handleErrorField(row[slug], errorField)}
                        </h6>
                      </OverlayTrigger>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Row>
    </TableContainer>
  );
};

export default PreviewTable;
