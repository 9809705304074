import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import QuestionContainer from "../Question/QuestionContainer";
import MaterialCustomMenu from "../../Common/MaterialCustomMenu";
import MaterialExpansionPanel from "../../Common/MaterialExpansionPanel";
import {
  PanelSummary,
  PanelDetails,
} from "../../Common/MaterialExpansionPanelSummary";
import EditableTextArea from "../../Common/EditableTextArea";
import EvaluationBadge from "../EvaluationBadge/EvaluationBadge";
import utils from "../../../utils/toolUtils";
import { checkAccess } from "../../../utils/accessLevels";

const { getValidFunc, getEntityMenuActions } = utils;

const messages = defineMessages({
  globalFiled: {
    id: "global.filed",
  },
});

const ThemeView = (props) => {
  const {
    theme = {},
    themeQuestions = [],
    themesConfig = {},
    expanded = false,
    isNew = false,
    editing = false,
    toggleInsightModal,
    showHiddenQuestions,
    showHiddenAnswers,
    toolsConfigurations,
    workspacesData,
    selectedTool = {},
    intl,
    customLabel,
    allAnswers,
    replaceConfig = {},
    questionRegistrations,
    openUnarchiveDialog,
    reorderEntity,
    editModeOn,
    toggleBoardEditMode,
  } = props;

  const { cenary = {} } = theme || {};

  const { id: scenaryId = 0 } = cenary || {};

  const show = props.show === true;

  const saveUpdateTheme = getValidFunc(props.saveUpdateTheme);
  const cancelUpdate = getValidFunc(props.cancelUpdate);
  const handleExpand = !editing ? getValidFunc(props.handleExpand) : () => {};
  const handleEditTheme = getValidFunc(props.handleEditTheme);
  const openArchiveDialog = getValidFunc(props.openArchiveDialog);
  const openDeleteDialog = getValidFunc(props.openDeleteDialog);

  const appendProps = {
    toolsConfigurations,
    toggleInsightModal,
    showHiddenQuestions,
    showHiddenAnswers,
    themeInfo: theme,
    allAnswers,
    replaceConfig,
    questionRegistrations,
    reorderEntity,
    editModeOn,
    toggleBoardEditMode,
  };

  return (
    <div
      className="flex-transition-container"
      style={show ? {} : { height: "0px", overflow: "hidden" }}
    >
      <MaterialExpansionPanel square expanded={expanded} onChange={() => {}}>
        <PanelSummary
          bgcolor="#f4ffba"
          aria-controls={`${theme.id}-theme`}
          id={`${theme.id}-theme`}
          expandIcon={!isNew ? <ExpandMoreIcon /> : null}
          IconButtonProps={{ onClick: () => handleExpand(theme.id) }}
          cursor="default !important"
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "12px",
            }}
          >
            <div
              className="clickable-row"
              style={{
                cursor: "pointer",
                whiteSpace: "nowrap",
              }}
              onClick={() => handleExpand(theme.id)}
              title={
                theme.filed ? intl.formatMessage(messages.globalFiled) : null
              }
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {theme.filed && (
                  <i className="fas fa-archive" style={{ color: "#888" }} />
                )}
                <EditableTextArea
                  id={theme.id || "newTheme"}
                  text={theme.name}
                  actionIcons
                  margin="none"
                  onSave={(newName) =>
                    saveUpdateTheme({ ...theme, name: newName })
                  }
                  onCancel={cancelUpdate}
                  editing={editing}
                />
              </div>
            </div>
            {customLabel && customLabel()}
            <div className="evaluation-row">
              <EvaluationBadge
                from="theme"
                info={{
                  questions: themeQuestions,
                  toolsConfigurations,
                  tool: selectedTool,
                  themeID: theme.id,
                }}
                workspacesData={workspacesData}
              />
              {!isNew &&
                checkAccess(
                  ["MANAGER", "LEADER", "MODERATOR", "ADMIN", "OWNER"],
                  null,
                  scenaryId,
                ) && (
                  <div style={{ padding: "0px 17px 0px 10px" }}>
                    <MaterialCustomMenu
                      menuActions={getEntityMenuActions(
                        handleEditTheme,
                        openArchiveDialog,
                        openDeleteDialog,
                        theme.filed,
                        themesConfig,
                        intl,
                        openUnarchiveDialog,
                      )}
                    />
                  </div>
                )}
            </div>
          </div>
        </PanelSummary>
        {!isNew && expanded && (
          <PanelDetails>
            <QuestionContainer
              themeInfo={theme}
              questions={themeQuestions}
              {...appendProps}
            />
          </PanelDetails>
        )}
      </MaterialExpansionPanel>
    </div>
  );
};

export default injectIntl(ThemeView);
