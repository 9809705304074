import axios from "axios";

import { S3_BUCKETS } from "../utils/global";
import { uploadFile } from "./s3FilesActions";

import { fetchInsightComments } from "./insightTimelineActions";

export const getAttachments = async ({ entityId, entityType }) => {
  const response = await axios.get(
    `/api/attachments/entityId/${entityId}/entityType/${entityType}`,
  );

  return response.data;
};

export const getInsightAttachments = (insightId) => {
  return async (dispatch) => {
    const { data: attachments = [] } = await axios.get(
      `/api/attachments/insight/${insightId}`,
    );

    const centralizedAttachments = {
      ACTION_PLAN: [],
      INSIGHT: [],
      COMMENT: [],
    };

    if (attachments && Array.isArray(attachments)) {
      (attachments || []).forEach((attachment) => {
        centralizedAttachments[attachment.entity].push(attachment);
      });
    }
    dispatch({
      type: "CENTRALIZED_ATTACHMENTS",
      payload: centralizedAttachments,
    });

    return { centralizedAttachments, attachments };
  };
};

export const addAttachment = (attachment) => {
  return async (dispatch) => {
    const response = await axios.post("/api/attachments/add", attachment);
    if (attachment.relatedInsightId) {
      dispatch(getInsightAttachments(attachment.relatedInsightId));
    }
    return response.data;
  };
};

function tranformSavedFiles(attachments, bodyAttachment) {
  return attachments.map((file) =>
    Object.assign(file, {
      ...bodyAttachment,
    }),
  );
}

export const addManyAttachment = (attachments, bodyAttachment, insightId) => {
  return async (dispatch) => {
    if (Array.isArray(attachments) && attachments.length > 0) {
      const formData = new FormData();

      const newAttachments = await Promise.all(
        attachments.map(async (file) => {
          formData.append("file", file);
          const fileName = await uploadFile(formData, S3_BUCKETS.ATTACHMENTS);

          return {
            ...file,
            fileName,
          };
        }),
      );

      const response = await axios.post(
        "/api/attachments/addMany",
        tranformSavedFiles(newAttachments, bodyAttachment),
      );

      if (insightId) {
        dispatch(getInsightAttachments(insightId));
      }

      return response.data;
    }
  };
};

export const deleteAttachment = (id, insightId) => {
  return async (dispatch) => {
    await axios.delete(`/api/attachments/${id}`);

    if (insightId) {
      dispatch(getInsightAttachments(insightId));
    }
  };
};

export const deleteCommentAttachment = (id, insightId) => {
  return async (dispatch) => {
    await axios.delete(`/api/attachments/${id}`);

    if (insightId) {
      dispatch(getInsightAttachments(insightId));
      dispatch(fetchInsightComments(insightId));
    }
  };
};

export const createAttachment = (attachment) => {
  return axios.post("/api/attachments", attachment).then((res) => {
    return res.data;
  });
};
