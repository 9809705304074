import React from "react";
import AnalysisAmbient from "../fields/Swot/AnalysisAmbient";
import AnalysisGroups from "../fields/Swot/AnalysisGroups";
import EvaluationCriterias from "../fields/Swot/EvaluationCriterias";
import Scales from "../fields/Swot/Scales";
import Factors from "../fields/Swot/Factors";
import {
  showFieldCaption,
  getSwotFieldDescription,
} from "../../../../../../utils/cpAnalysis/display";

const SwotTabView = (props) => {
  const {
    editable_no_max_length,
    not_editable,
    editable_with_max_length,
    editable_with_pre_length,
  } = getSwotFieldDescription();

  return (
    <div className="tab-content" style={{ margin: "0px" }}>
      <div className="row">
        <div className="col-xs-12 col-lg-6">
          <div className="flex pestalTabViewDiv" title={editable_no_max_length}>
            <h4>Ambiente da Análise</h4>
            <div>{showFieldCaption(true, "-")}</div>
          </div>
          <AnalysisAmbient {...props} />
        </div>
        <div className="col-xs-12 col-lg-6">
          <div className="flex pestalTabViewDiv" title={not_editable}>
            <h4>Fatores</h4>
            <div>{showFieldCaption(false, 4, false)}</div>
          </div>
          <Factors {...props} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-lg-6">
          <div className="flex pestalTabViewDiv" title={editable_no_max_length}>
            <h4>Grupo de Análise</h4>
            <div>{showFieldCaption(true, "-")}</div>
          </div>
          <AnalysisGroups {...props} />
        </div>
        <div className="col-xs-12 col-lg-6">
          <div
            className="flex pestalTabViewDiv"
            title={editable_with_max_length}
          >
            <h4>Critério de Avaliação</h4>
            <div>{showFieldCaption(true, 3)}</div>
          </div>

          <EvaluationCriterias {...props} />
        </div>
      </div>
      <div className="row margin20">
        <div className="col-xs-12 col-lg-6">
          <div
            className="flex pestalTabViewDiv"
            title={editable_with_pre_length}
          >
            <h4>Escala</h4>
            <div>{showFieldCaption(true, 5, false)}</div>
          </div>
          <Scales {...props} />
        </div>
      </div>
    </div>
  );
};

export default SwotTabView;
