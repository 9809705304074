export const projectEvaluation = {
  new: {
    id: "global.new",
  },
  plannings: {
    id: "tool.project.planning(s)",
  },
  reset: {
    id: "tool.project.reset",
  },
  goBack: {
    id: "tool.project.goBack",
  },
  finish: {
    id: "tool.project.finish",
  },
  accept: {
    id: "tool.project.accept",
  },
  confirmConclusionDate: {
    id: "tool.project.confirmConclusionDate",
  },
  confirmToSave: {
    id: "tool.project.confirmToSaveChanges",
  },
  save: {
    id: "global.save",
  },
  includeNewPlannings: {
    id: "tool.project.includeNewPlannings",
  },
  investment: {
    id: "tool.project.investment",
  },
  effort: {
    id: "tool.project.effort",
  },
  forceConclusionWarning: {
    id: "tool.project.forceConclusionWarning",
  },
  projectToolName: {
    id: "tool.project.name",
  },
  rookau_activity: { id: "global.new_activity_rookau" },
  newMilestoneResponsible: { id: "new.milestone.responsible" },
};

export const activitiesTable = {
  activity: {
    id: "global.activity",
  },
  pport_table_milestone: {
    id: "pport_table_milestone",
  },
  pport_form_milestone: { id: "pport_form_milestone" },
  pport_form_activities: { id: "pport_form_activities" },
  pport_form_effort_tasks: { id: "pport_form_effort_tasks" },
  pport_form_effort_hours: { id: "pport_form_effort_hours" },
  pport_form_effort_days: { id: "pport_form_effort_days" },
  pport_form_activities_expected: { id: "pport_form_activities_expected" },
  pport_form_activities_achieved: { id: "pport_form_activities_achieved" },
  pport_form_effort_foreseen: { id: "pport_form_effort_foreseen" },
  pport_form_effort_achieved: { id: "pport_form_effort_achieved" },
  pport_form_delivery_date: { id: "pport_form_delivery_date" },
  pport_form_responsible: { id: "pport_form_responsible" },

  weight: {
    id: "global.weight",
  },
  accumulatedPhysicalProgress: {
    id: "tool.project.accumulated_physical_progress",
  },
  physicalProgress: {
    id: "tool.project.physical_progress",
  },
  new: {
    id: "global.new",
  },
  edit: {
    id: "global.edit",
  },
  cancel: {
    id: "global.cancel",
  },
  delete: {
    id: "global.delete",
  },
  maxValueError: {
    id: "tool.project.maxValue",
  },
  maxValueIs100: {
    id: "tool.project.activities_maxValue100",
  },
  areYouSure: {
    id: "tool.project.areYouSure",
  },
  weightExceeded: {
    id: "tool.project.activities_weightExceeded",
  },
  pport_form_new_milestone: {
    id: "pport_form_new_milestone",
  },
  pport_form_edit_milestone: {
    id: "pport_form_edit_milestone",
  },
  deleteActivityConfirmation: {
    id: "tool.project.deleteActivityConfirmation",
  },
  maxDescriptionLength: {
    id: "tool.project.maxDescriptionLength",
  },
  deliveryDateMustBeInputed: {
    id: "tool.project.deliveryDateMustBeInputed",
  },
  global_noData: { id: "global.noData" },
  global_goBack: { id: "global.goBack" },
  global_selectSome: { id: "global.selectSome" },
  effortType: {
    id: "tool.project.effortType",
  },
  tasks: {
    id: "global.tasks",
  },
  hours: {
    id: "global.hours",
  },
  expectedDelivery: {
    id: "global.expectedDelivery",
  },
  stage: {
    id: "tool.project.stage",
  },
  status: {
    id: "global.status",
  },
  done: {
    id: "tool.project.phase.done",
  },
  ongoing: {
    id: "circleProgress.status.percent50",
  },
  notStarted: {
    id: "circleProgress.status.percent0",
  },
  cancel_activity: {
    id: "tool.project.cancelActivity",
  },
  reactivate: {
    id: "project.reactivate",
  },
  note: {
    id: "global.note",
  },
  invalidDeliveryDate: {
    id: "tool.project.invalidDeliveryDate",
  },
  invalidExpectedDate: {
    id: "tool.project.invalidExpectedDate",
  },
};
