import styled from "styled-components";
import { Tabs } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const H2 = styled.h2`
  color: rgb(107, 66, 169);
  padding: 0 0 10px 15px;
`;

const TabsStyle = styled(Tabs)`
  margin: 15px 0 0 0;
`;

const TdRegister = styled.td`
  width: ${(props) => props.width};
  cursor: ${(props) => props.cursor};
  background: ${(props) =>
    props.background ? props.background : "rgb(246, 246, 246)"};
  padding: 12px !important;
  font-weight: 500;
  text-align: justify;
`;

const GridContainer = styled(Grid)`
  padding: 0 15px;
`;

const AlertStyle = styled(Alert)`
  font-size: 16px !important;
`;

const TrEdit = styled.tr``;

const TdEdit = styled.td`
  border: none !important;
  visibility: hidden;
  background: #fff !important;

  & > i {
    color: #999;
  }

  ${TrEdit}:hover & {
    visibility: visible;
  }
`;

export { H2, TabsStyle, TdRegister, GridContainer, AlertStyle, TdEdit, TrEdit };
