import axios from "axios";
import {
  // DELETE_SWOT_QUESTION,
  UPDATE_SWOT_EVALUATION,
  FETCH_ALL_SWOT_EVALUATION,
} from "./types";
import { makeObjLevelAnArray } from "../utils/reducerUtils";

export const getEvaluation = (insightID) => {
  return axios.get(`/api/insight/${insightID}/swotAnalysis`).then((res) => res);
};

export const addEvaluation = (insightID, body) => {
  return axios
    .post(`/api/insights/${insightID}/swotAnalysis`, body)
    .then((res) => res);
};

export const updateEvaluation = (body) => {
  return axios.put(`/api/s-wot-analyses`, body).then((res) => res);
};

export const addSwotEvaluation = (insightID, body) => {
  return async (dispatch) => {
    const checkEvaluation = await getEvaluation(insightID);
    if (!checkEvaluation.data) {
      const newEvaluation = await addEvaluation(insightID, body);
      dispatch({ type: UPDATE_SWOT_EVALUATION, payload: newEvaluation.data });
    } else {
      const updatedEvaluation = await updateEvaluation(body);
      dispatch({
        type: UPDATE_SWOT_EVALUATION,
        payload: updatedEvaluation.data,
      });
    }
  };
};
// const deleteEvaluation = (insightID, swotAnalysisId) => {
//   return dispatch => {
//     return axios
//       .delete(` /api/insights/${insightID}/swotAnalysis/${swotAnalysisId}`)
//       .then(res => {
//         dispatch({ type: DELETE_SWOT_QUESTION, payload: res.data });
//       });
//   };
// };

export const fetchAllSwotEvaluations = (selectedToolIds = []) => {
  return async (dispatch) => {
    const swotEvaluations = await axios
      .get(`/api/selected-tools/allInsightSwotAnalysis/${selectedToolIds}`)
      .then((res) => res.data);

    return dispatch({
      type: FETCH_ALL_SWOT_EVALUATION,
      payload: makeObjLevelAnArray(swotEvaluations),
    });
  };
};

export const fetchAllSwotEvaluation = (selectedToolId) => {
  return async (dispatch) => {
    const fetchedRes = await axios.get(
      `/api/selected-tools/${selectedToolId}/insightSwotAnalysis`,
    );

    return dispatch({
      type: FETCH_ALL_SWOT_EVALUATION,
      payload: fetchedRes.data,
    });
  };
};
