import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import QuestionView from "./QuestionView";
import {
  getSelectedToolStates,
  getGoalsBonusStates,
  getCompAnalysisStates,
} from "../../customMapStates";
import { useQuestionStyles } from "../../../jcss/questionStyles";
import { customAddQuestion } from "../../../actions/structureActions";
import {
  updateQuestion,
  archiveQuestion,
  deleteQuestion,
} from "../../../actions/questionsActions";
import ArchiveDialog from "../../Common/ArchiveDialog";
import UnarchiveDialog from "../../Common/UnarchiveDialog";
import DeleteDialog from "../../Common/DeleteDialog";
import DragnDropContainer from "../../Common/DragnDropContainer";
import utils from "../../../utils/toolUtils";
import { checkAccess } from "../../../utils/accessLevels";
import { middlewareQuestionStructure } from "../../../utils/membersParticipantsUtils";
import { getCompetitiveAnalysisConfig } from "../../../utils/entityUtils";
import { getCustomQuestionNames } from "../../../utils/cpAnalysis/display";
import { COMPETITIVE_ANALYSIS } from "../../../constants/tools.constants";

const {
  getEntityConfigurations,
  getAddEntityLabel,
  getEntityLabel,
  getOnlySlugValues,
  iterationSearch,
  getCustomQuestionsOrder,
} = utils;

const messages = defineMessages({
  question_main_title: {
    id: "question_main_title",
  },
  alertMessage: {
    id: "equal_question_name_alert",
  },
  minLength: {
    id: "form_min_length",
  },
});

const defaultQuestionForm = {
  id: null,
  type: "question",
  theme: {},
  text: "",
};

const defaultQuestionDialog = {
  type: "",
  onConfirm: () => {},
};

const QuestionContainer = (props) => {
  const [expanded, setExpanded] = useState([]);
  const [questionForm, setQuestionForm] = useState(defaultQuestionForm);
  const [questionDialog, setQuestionDialog] = useState(defaultQuestionDialog);
  const [orderedQuestions, setOrderedQuestions] = useState([]);

  const classes = useQuestionStyles();

  const {
    themeInfo = {},
    allAnswers = {},
    selectedTool = {},
    selectedWorkspace = {},
    toggleInsightModal,
    showHiddenAnswers,
    intl,
    replaceConfig = {},
    questions = [],
    questionRegistrations = ["ANY"],
    allGoalBonusAdministration,
    currentToolType = "",
    reorderEntity,
    editModeOn,
    toggleBoardEditMode,
  } = props;

  const toolId =
    selectedTool.tool && selectedTool.tool.id ? selectedTool.tool.id : null;

  const questionsConfig = getEntityConfigurations(
    "question",
    toolId,
    replaceConfig,
  );

  const competitiveAnalysisConfig =
    toolId === COMPETITIVE_ANALYSIS
      ? getCompetitiveAnalysisConfig("question", currentToolType)
      : questionsConfig;

  useEffect(() => {
    if (questions && questions.length > 0) {
      const newOrdered = getCustomQuestionsOrder(questions, toolId);

      const nameCustomization = getCustomQuestionNames(newOrdered, toolId);

      if (!_.isEqual(nameCustomization, orderedQuestions))
        setOrderedQuestions(nameCustomization);
    } else if (orderedQuestions.length > 0) {
      setOrderedQuestions([]);
    }
  }, [questions, intl, toolId]);

  const handleReorder = (reorderedItems) => {
    if (Array.isArray(reorderedItems)) {
      if (!editModeOn) toggleBoardEditMode();
      const reorderedQuestions = [];

      reorderedItems.forEach(({ content, order }) => {
        if (content.props && content.props.question)
          reorderedQuestions.push({
            ...content.props.question,
            order,
          });
      });

      setOrderedQuestions(reorderedQuestions);
      reorderEntity("questions", reorderedQuestions, themeInfo.id);
    }
  };

  const resetQuestionForm = () => {
    setQuestionForm(defaultQuestionForm);
  };

  const resetQuestionDialog = () => {
    setQuestionDialog(defaultQuestionForm);
  };

  const handleExpand = (panelSlug) => {
    const updatedExpanded = [...expanded];

    const panelIndex = expanded.indexOf(panelSlug);

    if (panelIndex > -1) {
      updatedExpanded.splice(panelIndex, 1);
    } else {
      updatedExpanded.push(panelSlug);
    }

    setExpanded(updatedExpanded);
  };

  function handleMiddlewareCustomization() {
    let custom = false;

    const paramProps = {
      questionTheme: themeInfo,
      order: questions.length + 1,
    };

    if (questionsConfig.registrationMiddleware) {
      custom = () => props.customAddQuestion(toolId, paramProps);
    }

    return custom;
  }

  const validNameQuestions = (questionInfo) => {
    const questionsValidation = [...questions].filter(
      (quest) => quest.id !== questionInfo.id,
    );
    const questionsIterations = getOnlySlugValues(questionsValidation, "text");

    const isDuplicated = iterationSearch(
      questionInfo.text,
      questionsIterations,
    );

    if (questionsIterations.length === 0) {
      return false;
    }
    return isDuplicated;
  };

  const handleAddNewQuestion = async () => {
    const middlewareCustomization = handleMiddlewareCustomization();
    const isDuplicated = await validNameQuestions(questionForm);

    if (typeof middlewareCustomization === "function") {
      middlewareCustomization();
    } else if (!isDuplicated) {
      setQuestionForm({
        ...defaultQuestionForm,
        editing: true,
        new: true,
        theme: themeInfo,
      });
    } else if (
      isDuplicated &&
      questionForm.text.trim().replace(/\s/g, "").length > 3
    ) {
      alert(intl.formatMessage(messages.alertMessage));
    } else {
      alert(intl.formatMessage(messages.minLength));
    }
  };

  const saveUpdateQuestion = (newQuestion) => {
    const isDuplicated = validNameQuestions(newQuestion);

    if (
      !isDuplicated &&
      newQuestion.text.trim().replace(/\s/g, "").length > 3
    ) {
      props.updateQuestion(
        { ...questionForm, text: newQuestion.text, order: questions.length },
        themeInfo.id,
      );
    } else if (
      isDuplicated &&
      newQuestion.text.trim().replace(/\s/g, "").length > 3
    ) {
      alert(intl.formatMessage(messages.alertMessage));
    } else {
      alert(intl.formatMessage(messages.minLength));
    }

    resetQuestionForm();
  };

  function handleEditQuestion(questionInfo = {}) {
    if (questionInfo.id) setQuestionForm({ ...questionInfo, editing: true });
  }

  function handleArchiveQuestion(questionInfo) {
    if (questionInfo && questionInfo.id) props.archiveQuestion(questionInfo);
  }

  function handleDeleteQuestion(questionInfo) {
    if (questionInfo && questionInfo.id)
      props.deleteQuestion(questionInfo, themeInfo.id);
  }

  function openArchiveDialog(questionInfo) {
    setQuestionDialog({
      type: "ARCHIVE_QUESTION",
      onConfirm: () => handleArchiveQuestion(questionInfo),
    });
  }

  function openUnarchiveDialog(questionInfo) {
    setQuestionDialog({
      type: "UNARCHIVE_QUESTION",
      question: questionInfo,
      onConfirm: () => handleArchiveQuestion(questionInfo),
    });
  }

  function openDeleteDialog(questionInfo) {
    setQuestionDialog({
      type: "DELETE_QUESTION",
      onConfirm: () => handleDeleteQuestion(questionInfo),
    });
  }

  function closeDialogAndConfirm() {
    if (questionDialog.onConfirm) questionDialog.onConfirm();

    resetQuestionDialog();
  }

  const isAddingNewQuestion = questionForm.editing && questionForm.new;

  const appendProps = {
    showHiddenAnswers,
    toggleInsightModal,
    selectedTool,
    selectedWorkspace,
    themeInfo,
    questionsConfig,
    replaceConfig,
    reorderEntity,
    editModeOn,
    toggleBoardEditMode,
  };

  const questionLabel = getEntityLabel("question", toolId, intl);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ backgroundColor: "#f3f3f3" }}>
        <DragnDropContainer
          droppableId={`questionsDropabbleListTID-${themeInfo.id}`}
          handleReorder={handleReorder}
          items={orderedQuestions.map((questionInfo, index) => {
            const question = middlewareQuestionStructure(
              allGoalBonusAdministration,
              questionInfo,
              toolId,
            );
            const questionInsights = allAnswers[question.id] || [];
            const isEditingQuestion =
              questionForm.editing && questionForm.id === question.id;
            const registrationAccess =
              questionRegistrations.indexOf("ANY") > -1 ||
              questionRegistrations.indexOf(question.id) > -1;

            return {
              id: questionInfo.id,
              order: questionInfo.order,
              content: (
                <QuestionView
                  key={index}
                  show
                  expanded={expanded.indexOf(question.id) > -1}
                  editing={isEditingQuestion}
                  question={question}
                  questionInsights={questionInsights}
                  handleExpand={handleExpand}
                  saveUpdateQuestion={({ text }) =>
                    saveUpdateQuestion({ ...question, text })
                  }
                  handleEditQuestion={() => handleEditQuestion(question)}
                  openArchiveDialog={() => openArchiveDialog(question)}
                  openDeleteDialog={() => openDeleteDialog(question)}
                  cancelUpdate={() => resetQuestionForm()}
                  registrationAccess={registrationAccess}
                  openUnarchiveDialog={() => openUnarchiveDialog(question)}
                  {...appendProps}
                />
              ),
            };
          })}
        />
      </div>
      <QuestionView
        show={isAddingNewQuestion}
        question={{ text: questionForm.text }}
        isNew
        editing={isAddingNewQuestion}
        saveUpdateQuestion={(newQuestion) => saveUpdateQuestion(newQuestion)}
        cancelUpdate={() => resetQuestionForm()}
      />
      {questionsConfig.registrations &&
        competitiveAnalysisConfig.registrations &&
        !editModeOn &&
        checkAccess(["MANAGER", "LEADER", "MODERATOR", "ADMIN", "OWNER"]) && (
          <div style={{ padding: "15px 0px 0px 0px" }}>
            <Button
              size="small"
              color="primary"
              variant="contained"
              className={classes.addButton}
              onClick={handleAddNewQuestion}
            >
              <AddIcon /> {getAddEntityLabel("question", toolId, intl)}
            </Button>
          </div>
        )}
      <ArchiveDialog
        entityName={questionLabel}
        show={questionDialog.type === "ARCHIVE_QUESTION"}
        onConfirm={closeDialogAndConfirm}
        onCancel={resetQuestionDialog}
      />
      <UnarchiveDialog
        entityName={questionLabel}
        show={questionDialog.type === "UNARCHIVE_QUESTION"}
        action={intl.formatMessage({ id: "global.unArchive" })}
        onConfirm={closeDialogAndConfirm}
        onCancel={resetQuestionDialog}
      />
      <DeleteDialog
        entityName={questionLabel}
        show={questionDialog.type === "DELETE_QUESTION"}
        onConfirm={closeDialogAndConfirm}
        onCancel={resetQuestionDialog}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { workspacesData } = state;
  const { selectedTool, allQuestions } = getSelectedToolStates(state);
  const { allGoalBonusAdministration } = getGoalsBonusStates(state);

  const { currentToolType = "" } = getCompAnalysisStates(state);

  return {
    allQuestions,
    selectedTool,
    selectedWorkspace: workspacesData.selectedWorkspace,
    allGoalBonusAdministration,
    currentToolType,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    customAddQuestion,
    updateQuestion,
    archiveQuestion,
    deleteQuestion,
  })(QuestionContainer),
);
