import React from "react";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import DeleteColumn from "../../../../../../Common/Tables/DeleteColumn";
import {
  getEmptyTableRow,
  getDefaultAddTableLine,
} from "../../../../../../../utils/tableUtils";
import { translatedText } from "../../../../../../../utils/translationUtils";
import {
  orderScale,
  simpleEditArrayOnAdministration,
} from "../../../../../../../utils/skillsAssessment/manipulation";

const messages = defineMessages({
  global_noneRegistered: { id: "global.noneRegistered" },
});

const translation = (id) => translatedText(id, messages);

const TableQualificationScale = ({
  toolAdministration = {},
  updateAdministration,
  handleAdd,
  openDeleteDialog,
}) => {
  const { qualificationScale = [] } = toolAdministration;

  const handleEditScale = (scaleInfo, editingField = "value", newValue) => {
    if (scaleInfo.id) {
      const updatedScale = {
        ...scaleInfo,
        [editingField]:
          editingField === "value" ? parseFloat(newValue) : newValue,
      };

      const newAdministration = simpleEditArrayOnAdministration(
        toolAdministration,
        "qualificationScale",
        scaleInfo.id,
        updatedScale,
        "id",
      );

      updateAdministration(newAdministration);
    }
  };

  const orderedScale = orderScale(qualificationScale);

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="100">CRITÉRIO DE QUALIFICAÇÃO</td>
        </tr>
      </thead>
      <tbody>
        {orderedScale?.map((scaleInfo, index) => {
          return (
            <tr key={scaleInfo.id}>
              <SimpleExcelCollumn
                id={`qualificationValue${index}`}
                label={`qualificationValue${index}`}
                type="number"
                value={scaleInfo.value}
                tdStyle={{ width: "50px" }}
                align="center"
                onChange={(e) =>
                  handleEditScale(scaleInfo, "value", e.target.value)
                }
              />
              <SimpleExcelCollumn
                id={`qualificationTitle${index}`}
                label={scaleInfo.title}
                type="text"
                value={scaleInfo.title}
                onChange={(e) =>
                  handleEditScale(scaleInfo, "title", e.target.value)
                }
              />
              <DeleteColumn
                handleDelete={() =>
                  openDeleteDialog("qualificationScale", scaleInfo.id)
                }
              />
            </tr>
          );
        })}
        {getEmptyTableRow(
          qualificationScale,
          translation("global_noneRegistered"),
        )}
      </tbody>
      <tfoot>{getDefaultAddTableLine(handleAdd)}</tfoot>
    </table>
  );
};

TableQualificationScale.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
  updateAdministration: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  openDeleteDialog: PropTypes.func.isRequired,
};

export default TableQualificationScale;
