import axios from "axios";
import { FETCH_INSIGHT_TIMELINE, FETCH_INSIGHTS_TIMELINE } from "./types";
import { sendCommentNotification } from "./notifications/controllerActions";
import { addManyAttachment } from "./attachmentActions";

import { getAllUserAvatars } from "./profileActions";

export const getInsightComments = (insightId) => {
  return axios.get(`/api/insights/${insightId}/timeline`).then((res) => res);
};

async function mountCommentWithAvatar(comments) {
  const blobInfo = [];

  const avatars = await getAllUserAvatars(blobInfo);

  return comments.map((comment) => {
    const { owner = {} } = comment;
    const { userData = {} } = owner;

    const commentIndex = avatars.findIndex(
      (avatar) => avatar.avatarBlobId === userData.avatarBlobId,
    );

    if (commentIndex > -1) {
      return {
        ...comment,
        avatar: `data:image/png;base64,${avatars[commentIndex].base64}`,
      };
    }
    return { ...comment };
  });
}

export const fetchInsightComments = (insightId) => {
  return async (dispatch) => {
    if (insightId) {
      const insightComments = await getInsightComments(insightId);
      const commentsWithAvatar = await mountCommentWithAvatar(
        insightComments.data,
      );

      if (insightComments.data) {
        dispatch({
          type: FETCH_INSIGHT_TIMELINE,
          payload: { insightId, comments: commentsWithAvatar },
        });
      }
    }
  };
};

export const fetchGetGroupedComments = async (insightsIdList) => {
  if (insightsIdList?.length > 0) {
    const res = await axios.get(
      `/api/insights-timeline?insightsIds=${insightsIdList}`,
    );

    const newComments = await Promise.all(
      res.data.map(async (timelineInfo) => {
        const newTimeLine = await mountCommentWithAvatar(timelineInfo.timeline);

        return {
          ...timelineInfo,
          timeline: newTimeLine,
        };
      }),
    );

    return newComments;
  }

  return [];
};

export const fetchGroupedComments = (insightsIdList = []) => {
  return async (dispatch) => {
    const newComments = await fetchGetGroupedComments(insightsIdList);

    dispatch({
      type: FETCH_INSIGHTS_TIMELINE,
      payload: newComments,
    });
  };
};

export const addInsightTimeline = (
  insightId,
  text,
  userId,
  classifications = [],
  files,
) => {
  return async (dispatch) => {
    const response = await axios.post(`/api/insights/${insightId}/timeline`, {
      type: "timeline_item",
      text,
      classifications,
      owner: { id: userId },
      date: new Date(),
    });

    const bodyAttachment = {
      entityId: response.data.id,
      entityType: "COMMENT",
      relatedInsightId: insightId,
    };

    await dispatch(addManyAttachment(files, bodyAttachment, insightId));

    await dispatch(fetchInsightComments(insightId));
    dispatch(sendCommentNotification(insightId));
  };
};

export const editCommentaryOnInsightTimeline = (comment, insight_id) => {
  return axios.put(`/api/insights/${insight_id}/editTimeline`, comment);
};

export const deleteComment = (commentId, insightId) => {
  return axios.delete(`/api/insights/${insightId}/timelineItems/${commentId}`);
};
