import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import _ from "lodash";
import utils from "../../../../utils/toolUtils";
import gbUtils from "../../../../utils/goalsBonus";
import GoalListItem from "../../GoalListItem";
import Avatar from "../../Avatar";

const { orderArrayByObjAttr } = utils;

const { exceededTargetMaxValue, getTotalParticipantBonusInfo } = gbUtils;

const messages = defineMessages({
  noDataToDisplay: {
    id: "global.noDataTable",
  },
  gb_table_text_participants_upper: {
    id: "gb_table_text_participants_upper",
  },
});

const ParticipantEvaluations = (props) => {
  const {
    goalTargets = {},
    clearBonusGateMin,
    displayParticipants = [],
    calcType,
    isPeriodFilterActive,
    intl = {},
  } = props;

  let reorderedParticipants = [
    ...displayParticipants.map((participantRelation) => {
      const { participantInfo } = participantRelation;
      const { memberParticipant = {} } = participantInfo;

      return {
        ...participantRelation,
        memberParticipant,
      };
    }),
  ];

  reorderedParticipants = orderArrayByObjAttr(
    reorderedParticipants,
    "memberParticipant",
    "name",
  );

  return (
    <div className="evaluation-card">
      <div className="card-header">
        <div className="card-title">
          <h4>
            {intl.formatMessage(messages.gb_table_text_participants_upper)}
          </h4>
        </div>
      </div>
      <div style={{ padding: "20px 0px 20px 15px" }}>
        <div className="card-body max-height-800" style={{ padding: "0px" }}>
          <div className="right-spacing">
            {reorderedParticipants.map((participantRelation) => {
              const {
                participantInfo,
                participantGoals,
                memberParticipant = {},
              } = participantRelation;
              const { roleBonus = {} } = participantInfo;

              const { getHitInfoByCalc } = getTotalParticipantBonusInfo(
                participantInfo,
                participantGoals,
                goalTargets,
                clearBonusGateMin,
                isPeriodFilterActive,
              );
              const { percentage, hitLevel } = getHitInfoByCalc(calcType);

              return (
                <GoalListItem
                  title={memberParticipant.name}
                  description={roleBonus.role}
                  currentPercentage={percentage}
                  maxPercentage={exceededTargetMaxValue(goalTargets.exceeded)}
                  progressColor={hitLevel.color}
                  barTotalWidth={100}
                  key={memberParticipant.id}
                  avatar={
                    <Avatar
                      src={memberParticipant.avatarBlobId}
                      name={memberParticipant.name}
                      style={{
                        width: "30px",
                        height: "30px",
                        marginLeft: "10px",
                      }}
                    />
                  }
                />
              );
            })}
            {reorderedParticipants.length === 0 && (
              <h5 align="center">
                {intl.formatMessage(messages.noDataToDisplay)}
              </h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function areEqual(prev, next) {
  if (prev.calcType !== next.calcType) return false;
  if (!_.isEqual(prev.periodFilter, next.periodFilter)) return false;
  if (!_.isEqual(prev.displayParticipants, next.displayParticipants))
    return false;

  return true;
}

export default injectIntl(React.memo(ParticipantEvaluations, areEqual));
