const en = {
  cpyguid_guidelines: "Guidelines",
  cpyguid_guidelines_structure: "Structure of Company Guidelines",
  cpyguid_guidelines_add: "Add guidelines",
  cpyguid_company_subguidelines_add: "Add guidelines",
  cpyguid_form_guideline_title: "Guideline Name",
  cpyguid_form_simple_title: "Title",
  cpyguid_form_simple_active: "Active",
  cpyguid_dialog_delete_title:
    "Are you sure that you want to remove this guideline?",
  cpyguid_dialog_delete_description: "All guidelines below will be removed.",
  cpyguid_form_editing_guideline_info_title: "Edit guidelines information",
  cpyguid_text_aditional_info: "Additional information",
  cpyguid_text_guidelines_title: "Guidelines",
  cpyguid_text_no_guideline: "No guideliness have been registered",
  cpyguid_text_no_subguideline: "No sub guideliness have been registered",
  cpyguid_text_no_item_selected: "No items selected",
  cpyguid_text_selected_item: "Selecte item",
  cpyguid_text_select_some_guideline: "Select an Guideline",
  cpyguid_text_select_the_guideline: "Select an Guideline",
  cpyguid_option_active_on: "Active",
  cpyguid_option_active_off: "Inactive",
  cpyguid_error_guideline_exists:
    "There is already an guidelines with that title registered",
  cpyguid_error_subguideline_exists:
    "There is already an sub guidelines with that title registered",
};

const es = {
  cpyguid_guidelines: "Directrices",
  cpyguid_guidelines_structure: "Estructura de las Directrices de la Empresa",
  cpyguid_guidelines_add: "Agregar directriz",
  cpyguid_company_subguidelines_add: "Agregar directrices",
  cpyguid_form_guideline_title: "Nombre del directriz",
  cpyguid_form_simple_title: "Título",
  cpyguid_form_simple_active: "Activo",
  cpyguid_dialog_delete_title:
    "¿Está seguro de que desea eliminar esta directriz?",
  cpyguid_dialog_delete_description:
    "Se eliminarán todas las directrizes siguientes.",
  cpyguid_form_editing_guideline_info_title: "Editar información del directriz",
  cpyguid_text_aditional_info: "Información adicional",
  cpyguid_text_guidelines_title: "Directrices",
  cpyguid_text_no_guideline: "No se han registrado directriz",
  cpyguid_text_no_subguideline: "No se han registrado directrices",
  cpyguid_text_no_item_selected: "No hay elementos seleccionados",
  cpyguid_text_selected_item: "Elemento seleccionado",
  cpyguid_text_select_some_guideline: "Seleccione un Directriz",
  cpyguid_text_select_the_guideline: "Seleccione el Directriz",
  cpyguid_option_active_on: "Activo",
  cpyguid_option_active_off: "Inactivo",
  cpyguid_error_guideline_exists:
    "Ya hay una directriz con ese título registrada",
  cpyguid_error_subguideline_exists:
    "Ya hay una directriz con ese título registrada",
};

const pt = {
  cpyguid_guidelines: "Diretrizes",
  cpyguid_guidelines_structure: "Estrutura de Diretrizes da Empresa",
  cpyguid_guidelines_add: "Adicionar diretriz",
  cpyguid_company_subguidelines_add: "Adicionar diretrizes",
  cpyguid_form_guideline_title: "Nome da Diretriz",
  cpyguid_form_simple_title: "Título",
  cpyguid_form_simple_active: "Ativo",
  cpyguid_dialog_delete_title:
    "Você tem certeza que deseja remover essa diretriz?",
  cpyguid_dialog_delete_description:
    "Todas as diretrizes abaixo serão removidas.",
  cpyguid_form_editing_guideline_info_title: "Editar informações da diretriz",
  cpyguid_text_aditional_info: "Informações adicionais",
  cpyguid_text_guidelines_title: "Diretrizes",
  cpyguid_text_no_guideline: "Nenhuma diretriz foi registrada",
  cpyguid_text_no_subguideline: "Nenhuma diretriz foi registrada",
  cpyguid_text_no_item_selected: "Nenhum ítem selecionado",
  cpyguid_text_selected_item: "Item selecionado",
  cpyguid_text_select_some_guideline: "Selecione uma diretriz",
  cpyguid_text_select_the_guideline: "Selecione a diretriz",
  cpyguid_option_active_on: "Ativo",
  cpyguid_option_active_off: "Inativo",
  cpyguid_error_guideline_exists:
    "Já existe uma diretriz com esse título cadastrada",
  cpyguid_error_subguideline_exists:
    "Já existe uma diretriz com esse título cadastrada",
};

const translations = { en, es, pt };

export default translations;
