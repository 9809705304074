import _ from "lodash";

import {
  FETCH_CENARY_THEMES,
  UPDATE_CENARY_THEME,
  SWAP_ORDER,
  FETCH_ALL_SCENERIES_THEMES,
  DELETE_THEME,
} from "../actions/types";

import { ENTITY_TYPE } from "../actions/orderedEntityActions";

const INITIAL_STATE = {
  cenariesThemesReport: [],
  allThemes: {},
};

const cenaryThemesReducer = (state = INITIAL_STATE, action) => {
  const allThemes = state.allThemes ? state.allThemes : {};
  const { payload = {} } = action;

  const updateAllThemes = (
    sceneryId,
    updatedNewTheme = {},
    removeAction = false,
  ) => {
    let isNewAddingTheme = true;

    let finalThemes = _.cloneDeep(allThemes);
    finalThemes[sceneryId] = [];

    const stateSceneryThemes = allThemes[sceneryId] || [];
    stateSceneryThemes.forEach((stateTheme) => {
      const isTheSame = stateTheme.id === updatedNewTheme.id;

      if (stateTheme.id === updatedNewTheme.id) {
        stateTheme = updatedNewTheme;
        isNewAddingTheme = false;
      }

      if (!isTheSame || (isTheSame && !removeAction))
        finalThemes[sceneryId] = [...finalThemes[sceneryId], stateTheme];
    });

    if (isNewAddingTheme && !removeAction)
      finalThemes[sceneryId] = [...finalThemes[sceneryId], updatedNewTheme];

    return finalThemes;
  };

  const { theme = null, cenaryId = null } = payload || {};

  switch (action.type) {
    case FETCH_CENARY_THEMES:
      return { ...state, [cenaryId]: action.payload.themes };
    case "FETCH_REPORT_OKR_FOLLOWUP_CENARIES_THEMES":
      return { ...state, cenariesThemesReport: action.payload };
    case FETCH_ALL_SCENERIES_THEMES:
      return {
        ...state,
        allThemes: { ...allThemes, ...action.payload },
      };
    case UPDATE_CENARY_THEME:
      if (theme && cenaryId) {
        return {
          ...state,
          allThemes: updateAllThemes(cenaryId, theme),
        };
      } else {
        return state;
      }
    case DELETE_THEME:
      if (theme && cenaryId) {
        return {
          ...state,
          allThemes: updateAllThemes(cenaryId, theme, true),
        };
      }

      return state;
    case SWAP_ORDER:
      if (action.payload.entityType === ENTITY_TYPE.theme) {
        return {
          ...state,
          allThemes: updateAllThemes(action.payload.id, action.payload.list),
        };
      } else {
        return state;
      }
    case "RESET_THEMES":
      return {
        ...state,
        allThemes: {},
      };
    default:
      return state;
  }
};

export default cenaryThemesReducer;
