import * as calc from "./calc";
import * as filter from "./filter";
import * as entity from "./entity";
import * as display from "./display";
import * as validation from "./validation";
import * as constants from "../../constants/competitiveAnalysis";

const cpAnalysisUtils = {
  ...calc,
  ...display,
  ...filter,
  ...validation,
  ...entity,
  ...constants,
};

export default cpAnalysisUtils;
