import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import _ from "lodash";

import {
  getProjectStates,
  getSelectedToolStates,
  getInsightStates,
} from "../../../customMapStates";

import { getInitialStates } from "../../../../utils/projectEvaluation";
import { getProjectConfiguration } from "../../../../utils/projectConfiguration/entity";
import ProjectEvaluation from "./ProjectEvaluation";

const initialStates = getInitialStates();
const { projectEvaluation: initPEvaluation = {} } = initialStates;

const ProjectEvaluationContainer = ({
  selectedInsightID,
  selectedToolID,
  allProjectConfigurations,
  allProjectEvaluation,
  updateResponsible,
}) => {
  const [projectEvaluation, setProjectEvaluation] = useState(initPEvaluation);
  const [historyEvaluation, setHistoryEvaluation] = useState(initPEvaluation);
  const [projectConfiguration, setProjectConfiguration] = useState(false);

  useEffect(() => {
    const newConfiguration = getProjectConfiguration(
      allProjectConfigurations,
      selectedToolID,
    );

    setProjectConfiguration(newConfiguration);
  }, [selectedToolID, allProjectConfigurations]);

  useEffect(() => {
    if (selectedInsightID) {
      const filteredEvaluation = allProjectEvaluation.find(
        ({ insightId }) => insightId === selectedInsightID,
      );

      if (filteredEvaluation?.insightId) {
        setProjectEvaluation(filteredEvaluation?.projectEvaluation);
        setHistoryEvaluation(filteredEvaluation?.projectEvaluation);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInsightID, allProjectEvaluation]);

  function handleUpdateSingleField(field, newValue) {
    setProjectEvaluation((current) => {
      return {
        ...current,
        [field]: newValue,
      };
    });
  }

  return (
    <ProjectEvaluation
      projectConfiguration={projectConfiguration}
      historyEvaluation={historyEvaluation}
      projectEvaluation={projectEvaluation}
      setProjectEvaluation={setProjectEvaluation}
      handleUpdateSingleField={handleUpdateSingleField}
      updateResponsible={updateResponsible}
    />
  );
};

const mapStateToProps = (state) => {
  const {
    allProjectEvaluation,
    allProjectConfigurations = {},
  } = getProjectStates(state);
  const { selectedToolID } = getSelectedToolStates(state);
  const { selectedInsightID = false } = getInsightStates(state);

  return {
    allProjectEvaluation,
    allProjectConfigurations,
    selectedToolID,
    selectedInsightID,
  };
};

function areEqual(prevProps, nextProps) {
  if (
    !_.isEqual(prevProps.allProjectEvaluation, nextProps.allProjectEvaluation)
  )
    return false;
  if (
    !_.isEqual(
      prevProps.allProjectConfigurations,
      nextProps.allProjectConfigurations,
    )
  )
    return false;
  if (!_.isEqual(prevProps.selectedInsightID, nextProps.selectedInsightID))
    return false;
  return true;
}

export default connect(mapStateToProps)(
  React.memo(ProjectEvaluationContainer, areEqual),
);
