import {
  FETCH_ALL_SWOT_EVALUATION,
  UPDATE_SWOT_EVALUATION,
} from "../actions/types";

import { updateAllList } from "../utils/reducerUtils";

const swotEvaluationReducer = (state = {}, action) => {
  const payload = action.payload;
  const currentAllEvaluation = state.allSwotEvaluation
    ? state.allSwotEvaluation
    : [];

  const updateEvaluation = (newBody) => {
    let finalEvaluation = [];
    let didUpdate = false;
    currentAllEvaluation.forEach((itemBody) => {
      if (itemBody.insightId === newBody?.insightId) {
        didUpdate = true;
      }

      finalEvaluation = [...finalEvaluation, newBody];
    });

    if (!didUpdate) finalEvaluation = [...finalEvaluation, newBody];

    return finalEvaluation;
  };

  const newUpdatedAll = updateEvaluation(payload);

  switch (action.type) {
    case FETCH_ALL_SWOT_EVALUATION:
      return {
        ...state,
        allSwotEvaluation: updateAllList(
          currentAllEvaluation,
          payload,
          "insightId",
        ),
      };
    case UPDATE_SWOT_EVALUATION:
      return {
        ...state,
        allSwotEvaluation: newUpdatedAll,
      };
    default:
      return state;
  }
};

export default swotEvaluationReducer;
