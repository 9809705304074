import React from "react";
import { defineMessages } from "react-intl";
import { connect } from "react-redux";
import utils from "../../../../utils/toolUtils";
import kpiUtils from "../../../../utils/kpis";
import { getIndividualIndicatorsResult } from "../../../../utils/kpis/calc";
import actionsPlanUtils from "../../../../utils/actionsPlan";
import {
  getKpiStates,
  getSelectedToolStates,
  getActionsPlanStates,
} from "../../../customMapStates";
import TargetColorBadge from "../../../Common/TargetColorBadgeKpi";
import ActionsCounterBadge from "../../../Common/ActionPlan/evaluations/ActionsCounterBadge";
import { useSimpleToolAdministration } from "../../../../utils/kpis/hooks";

const { translatedText, getOnlySlugValues } = utils;

const { getGoalIndicatorInfoById, getHitTargetInfo } = kpiUtils;

const { getTotalActionsByInsights } = actionsPlanUtils;

const messages = defineMessages({
  kpi_table_text_goals: { id: "kpi_table_text_goals" },
  kpi_options_rt_shared: { id: "kpi_options_rt_shared" },
});

const translation = (id, values) => translatedText(id, messages, values);

const Kpi = ({
  insightsToCalc,
  allKpiAdministration,
  allActionsPlans,
  selectedTool,
  from,
}) => {
  const { kpiAdministration } = useSimpleToolAdministration({
    selectedTool,
    allKpiAdministration,
  });

  const { goalTargets, zeroWhenNegative } = kpiAdministration || {};

  const onlyInsightIds = getOnlySlugValues(insightsToCalc, "id");

  const AgrupedEvaluation = ({ hideTitle = false }) => {
    const goals = [];
    const added = [];

    if (insightsToCalc && insightsToCalc.length > 0) {
      insightsToCalc.forEach((insightInfo) => {
        const { kpiConfigurationGoalId = null } = insightInfo;

        const goalEvaluation = getGoalIndicatorInfoById(
          kpiConfigurationGoalId,
          kpiAdministration?.goals,
        );

        if (goalEvaluation.id && added.indexOf(kpiConfigurationGoalId) === -1) {
          goals.push(goalEvaluation);
          added.push(kpiConfigurationGoalId);
        }
      });
    }

    const enableConsolidatedCalculation =
      ["answer", "score", "overview"].indexOf(from) > -1;
    const singleConsolidated =
      goals[0] && goals[0].relationshipType === "SHARED";
    const showConsolidated =
      enableConsolidatedCalculation && singleConsolidated;

    const { realGoalsReachSum, evaluatedGoals } = getIndividualIndicatorsResult(
      goals,
      goalTargets,
      enableConsolidatedCalculation,
      false,
      zeroWhenNegative,
    );

    const hitTargetInfo = getHitTargetInfo(
      realGoalsReachSum,
      goalTargets,
      evaluatedGoals.length === 0,
    );

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            marginLeft: "15px",
            marginRight: "10px",
            textAlign: "center",
            lineHeight: "18px",
          }}
        >
          {!hideTitle && (
            <font style={{ fontSize: "12px" }}>
              {translation("kpi_table_text_goals")}:
            </font>
          )}
          <div
            style={{
              display: "flex",
              height: "24px",
              alignItems: "center",
            }}
          >
            <div
              title={translation("kpi_options_rt_shared")}
              style={{ visibility: showConsolidated ? "visible" : "hidden" }}
            >
              <i
                className="fas fa-stream"
                style={{ color: "#ffc875", fontSize: "17px", marginTop: "2px" }}
              />
            </div>
            <div title={translation("kpi_table_text_goals")}>
              <TargetColorBadge
                hitTargetInfo={hitTargetInfo}
                goalPercentage={realGoalsReachSum}
              />
            </div>
          </div>
        </div>
        <ActionsCounterBadge
          actions={
            getTotalActionsByInsights(onlyInsightIds, allActionsPlans).length
          }
          hideTitle={hideTitle}
        />
      </div>
    );
  };

  const handleDisplayEvaluation = (from) => {
    const hideWhen = ["cenary"];
    const hideTitleFrom = ["question", "answer", "score", "overview"];
    const hideTitle = hideTitleFrom.indexOf(from) > -1;

    return (
      hideWhen.indexOf(from) === -1 && (
        <AgrupedEvaluation hideTitle={hideTitle} />
      )
    );
  };

  return (
    <div style={{ fontFamily: "montserrat" }}>
      {handleDisplayEvaluation(from)}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { selectedTool = {} } = getSelectedToolStates(state);
  const { allKpiAdministration = [] } = getKpiStates(state);
  const { allActionsPlans = [] } = getActionsPlanStates(state);

  return {
    allKpiAdministration,
    allActionsPlans,
    selectedTool,
  };
};

export default connect(mapStateToProps)(Kpi);
