/* eslint-disable class-methods-use-this */
export class Errors {
  constructor() {
    this.errors = [];
  }

  add(slug, message) {
    if (!this.isDuplicated(slug))
      this.errors = [...this.errors, { slug, message }];
  }

  addMultiple(newErrors) {
    if (Array.isArray(newErrors)) {
      newErrors.forEach(({ slug, message }) => this.add(slug, message));
    }
  }

  getErrors() {
    return this.errors;
  }

  hasErrors() {
    return this.errors.length > 0;
  }

  checkBySlug(sourceSlugs, checkSlug) {
    return sourceSlugs.indexOf(checkSlug) > -1;
  }

  isDuplicated(checkSlug) {
    const slugs = [...this.errors.map(({ slug }) => slug)];

    return this.checkBySlug(slugs, checkSlug);
  }

  filterBySlugs(sourceSlugs) {
    const filtered = [];

    this.errors.forEach((error) => {
      if (this.checkBySlug(sourceSlugs, error.slug)) {
        filtered.push(error);
      }
    });

    return { errors: filtered, hasErrors: filtered.length > 0 };
  }
}

export const getValidFunc = (verifyingFunc, defaultRes = () => {}) => {
  return typeof verifyingFunc === "function" ? verifyingFunc : defaultRes;
};

export const getValidId = (id, defaultIdValue = null) => {
  return id === "" || id === null || !id ? defaultIdValue : id;
};

export const getValidEntityConfigs = (config = {}) => {
  const { stringValue = "", arrayValue, idValue = null, objectValue } = config;
  return { stringValue, arrayValue, idValue, objectValue };
};

export const validateResposible = ({
  responsible = {},
  key = "",
  alterValue = "",
}) => {
  return !responsible ? responsible[key] : alterValue;
};
