import React from "react";
import PropTypes from "prop-types";
import SearchInput from "react-search-input";
import { injectIntl } from "react-intl";
import Spinner from "react-spinkit";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { globalMessages } from "../../utils/global";
import DeleteDialog from "../Common/DeleteDialog";
import { getToolIntlSlug } from "../../utils/translationUtils";
import { getGoalEvaluationByID } from "../../utils/okrUtils";
import EvaluationBadge from "../Tool/EvaluationBadge/EvaluationBadge";
import {
  SWOT,
  PROCESSES_BIG_PICTURE,
  PEOPLE_BIG_PICTURE,
  GOALS_AND_BONUS,
  KPIS,
  OKR_ID,
} from "../../constants/tools.constants";
import {
  justTranslateCenaryName,
  justTranslateQuestionName,
} from "../../utils/cpAnalysis/display";

const getToolBadgeWidth = (toolTypeId) => {
  if (toolTypeId === SWOT) return "230px";
  if (toolTypeId === PROCESSES_BIG_PICTURE) return "100px";
  if (toolTypeId === PEOPLE_BIG_PICTURE) return "300px";
  if (toolTypeId === GOALS_AND_BONUS) return "100px";
  if (toolTypeId === KPIS) return "150px";
  if (toolTypeId === OKR_ID) return "250px";

  return "250px";
};

const InsightItem = (props) => {
  const {
    selectedWorkspace,
    currentSelectedTool,
    allInsightGoals = [],
    internalConnections,
    insight,
    onClick,
    onShowDelete,
    search,
    traceabilityId,
    intl,
  } = props;

  const {
    id,
    name,
    score,
    tool,
    cenary,
    theme,
    question,
    insightInfo = {},
  } = insight;

  const insightQuestion = insightInfo.question;
  const insightTheme = insightQuestion.theme;

  const RenderBadge = () => {
    const finalGoal = getGoalEvaluationByID(insight.id, allInsightGoals);
    const width = getToolBadgeWidth(currentSelectedTool.tool.id);

    return (
      <div
        style={{
          width,
          display: "flex",
          alignItems: "center",
          height: "45px",
        }}
      >
        <EvaluationBadge
          from="answer"
          info={{
            answer: {
              ...insightInfo,
              insightGoal: finalGoal.insightGoal,
            },
            workspace: selectedWorkspace,
            tool: currentSelectedTool,
            themeID: insightTheme.id,
          }}
        />
      </div>
    );
  };

  const deleteTooltip = (
    <Tooltip id={`tooltip_delete_${id}`}>
      {intl.formatMessage(globalMessages.delete)}
    </Tooltip>
  );

  const trackingInfo = `${tool} | ${justTranslateCenaryName(
    cenary,
  )} | ${theme} | ${justTranslateQuestionName(question)}`;

  return (
    <li onClick={() => onClick(id)}>
      <div className="col-xs-11" style={{ padding: "0px" }}>
        <div className="container">
          <div className="badge-field">
            <span className="badge bg-purple">{score}</span>
          </div>
          <div className="product-info">
            <a className="product-title" title={name}>
              {name}
            </a>
            <div style={{ display: "flex" }}>
              <span className="product-description" title={trackingInfo}>
                {trackingInfo}
              </span>
            </div>
          </div>
          {internalConnections && <RenderBadge />}
        </div>
      </div>
      {!search && (
        <div className="col-xs-1">
          <OverlayTrigger placement="top" overlay={deleteTooltip}>
            <button
              onClick={() => onShowDelete(traceabilityId)}
              type="button"
              className="btn"
            >
              <i className="far fa-trash-alt" style={{ color: "red" }} />
            </button>
          </OverlayTrigger>
        </div>
      )}
    </li>
  );
};

const controlKeyDown = (evt, onCancelSearch) => {
  if (evt.keyCode === 27) {
    onCancelSearch();
  }
};

const Traceability = (props) => {
  const {
    intl,
    allInsightGoals,
    selectedWorkspace,
    currentSelectedTool,
    internalConnections,
    insightId,
    search,
    searchInsightsResult,
    searchInsights,
    searching,
    handleAddTraceability,
    onCancelSearch,
    onShowDelete,
    traceabilityId,
  } = props;

  if (search === true) {
    return (
      <>
        <div className="row">
          <div className="col-xs-9 col-sm-10">
            <SearchInput
              className="search-input"
              placeholder={intl.formatMessage(globalMessages.search)}
              autoFocus
              onChange={searchInsights}
              onKeyDown={(evt) => controlKeyDown(evt, onCancelSearch)}
            />
          </div>
          <div className="col-xs-3 col-sm-2">
            <button className="btn btn-danger" onClick={onCancelSearch}>
              <i className="fa fa-times" />
              &nbsp;
              <span className="hidden-xs">
                {intl.formatMessage(globalMessages.cancel)}
              </span>
            </button>
          </div>
        </div>
        <div className="row">
          {searching && (
            <div className="col-xs-9 col-sm-10">
              <br />
              <Spinner name="circle" />
            </div>
          )}
          <ul className="col-sm-12 products-list product-list-in-box">
            {(!searchInsightsResult || searchInsightsResult.length === 0) && (
              <li className="item">
                {intl.formatMessage(globalMessages.empty)}
              </li>
            )}
            {searchInsightsResult &&
              searchInsightsResult.length > 0 &&
              searchInsightsResult.map((insight) => {
                if (insightId !== insight.id) {
                  return (
                    <InsightItem
                      key={insight.id}
                      insight={insight}
                      onClick={handleAddTraceability}
                      search={search}
                      intl={intl}
                    />
                  );
                }
                return null;
              })}
          </ul>
        </div>
      </>
    );
  }
  return (
    <InsightItem
      selectedWorkspace={selectedWorkspace}
      allInsightGoals={allInsightGoals}
      currentSelectedTool={currentSelectedTool}
      internalConnections={internalConnections}
      traceabilityId={traceabilityId}
      insight={props.traceability}
      onClick={() => {}}
      onShowDelete={onShowDelete}
      search={search}
      intl={intl}
    />
  );
};

Traceability.propTypes = {
  traceability: PropTypes.object,
  search: PropTypes.bool.isRequired,
};

Traceability.defaultProps = {
  search: false,
};

const renderNewTraceability = (
  insightId,
  addTraceability,
  searchInsightsResult,
  searchInsights,
  searching,
  handleAddTraceability,
  intl,
  onCancelSearch,
) => (
  <Traceability
    intl={intl}
    insightId={insightId}
    search={addTraceability}
    searchInsightsResult={searchInsightsResult}
    searchInsights={searchInsights}
    handleAddTraceability={handleAddTraceability}
    searching={searching}
    onCancelSearch={onCancelSearch}
  />
);

const InsightTraceabilitiesView = (props) => {
  const {
    intl,
    internalConnections = false,
    insightId,
    selectedWorkspace = {},
    selectedTool: currentSelectedTool = {},
    allInsightGoals = [],
    traceabilities = [],
    addTraceability,
    searchInsightsResult,
    searchInsights,
    searching,
    onAddTraceability,
    onCancelSearch,
    onShowDelete,
    showDeleteDialog,
    onConfirmDelete,
    onCancelDelete,
  } = props;

  const entityName = intl.formatMessage(globalMessages.traceability);

  return (
    <>
      {addTraceability &&
        renderNewTraceability(
          insightId,
          addTraceability,
          searchInsightsResult,
          searchInsights,
          searching,
          onAddTraceability,
          intl,
          onCancelSearch,
        )}
      {!addTraceability && (
        <ul className="products-list product-list-in-box">
          {(!traceabilities || traceabilities.length === 0) && (
            <li className="item">{intl.formatMessage(globalMessages.empty)}</li>
          )}
          {traceabilities.length > 0 &&
            traceabilities.map((traceability) => {
              const insight = traceability.insight || {};
              const { question = {} } = insight;
              const { theme = {} } = question;
              const { cenary = {} } = theme;
              const { selectedTool = {} } = cenary;
              const { tool = {} } = selectedTool;
              const toolTranslationSlug = getToolIntlSlug(tool.id);

              const _traceability = {
                id: insight.id,
                name: insight.text,
                score: insight.score,
                tool: intl.formatMessage({ id: toolTranslationSlug }),
                cenary: cenary.name,
                theme: theme.name,
                question: question.text,
                insightInfo: insight,
              };

              return (
                <Traceability
                  internalConnections={internalConnections}
                  selectedWorkspace={selectedWorkspace}
                  currentSelectedTool={currentSelectedTool}
                  allInsightGoals={allInsightGoals}
                  key={traceability.id}
                  traceabilityId={traceability.id}
                  traceability={_traceability}
                  searchInsightsResult={searchInsightsResult}
                  searchInsights={searchInsights}
                  onShowDelete={onShowDelete}
                  insightId={insightId}
                  intl={intl}
                />
              );
            })}
        </ul>
      )}
      <DeleteDialog
        onConfirm={onConfirmDelete}
        entityName={entityName}
        show={showDeleteDialog}
        onCancel={onCancelDelete}
      />
    </>
  );
};

InsightTraceabilitiesView.propTypes = {
  /** Lista das rastreabilidades a serem renderizadas */
  traceabilities: PropTypes.array.isRequired,

  /** Define se o componente estará em modo de inserção */
  addTraceability: PropTypes.bool.isRequired,

  /** Lista dos insights encontrados */
  searchInsightsResult: PropTypes.array,

  /** Função que é executada para efetuar a pesquisa dos insights */
  searchInsights: PropTypes.func,

  /** Define se o componente está executando a função searchInsights */
  searching: PropTypes.bool.isRequired,

  /** Função que será chamada ao selecionar uma nova rastreabilidade */
  onAddTraceability: PropTypes.func,

  /** Função que será chamada para iniciar a confirmação de exclusão */
  onShowDelete: PropTypes.func,

  /** ID do Insight que vai receber as rastreabilidades */
  insightId: PropTypes.number.isRequired,

  /** Função executada ao cancelar a pesquisa */
  onCancelSearch: PropTypes.func.isRequired,

  /** Define se deve ser aberta a dialog de confirmação de exclusão */
  showDeleteDialog: PropTypes.bool.isRequired,

  /** Função executada quando o usuário confirma a exclusão */
  onConfirmDelete: PropTypes.func.isRequired,

  /** Função executada quando o usuário não confirma a exclusão */
  onCancelDelete: PropTypes.func.isRequired,
};

InsightTraceabilitiesView.defaultProps = {
  throttle: 1500,
};

export default injectIntl(InsightTraceabilitiesView);
