import React, { useState } from "react";
import { injectIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";
import MaterialCustomMenu from "../../Common/MaterialCustomMenu";
import EditableTextArea from "../../Common/EditableTextArea";
import EvaluationBadge from "../EvaluationBadge/EvaluationBadge";
import Avatar from "../../Common/Avatar";
import {
  insightsGoalState,
  getCompAnalysisStates,
} from "../../customMapStates";
import utils from "../../../utils/toolUtils";
import { checkAccess } from "../../../utils/accessLevels";
import { SKILLS_ASSESSMENT } from "../../../constants/tools.constants";
import MoveSkillsAssessmentInsight from "./SkillsAssessment/MoveInsightDialog";

const { getGoalEvaluationByID, getValidFunc, getEntityMenuActions } = utils;

const messages = defineMessages({
  globalFiled: {
    id: "global.filed",
  },
});

const InsightBox = (props) => {
  const [openDialogs, setOpenDialogs] = useState({ moveInsight: false });

  const {
    insight,
    intl,
    insightsConfig = {},
    editing = false,
    themeInfo = {},
    selectedTool = {},
    selectedWorkspace = {},
    avatarPermission,
    boardPoints,
    currentToolType,
    currentAdministration,
    editModeOn,
  } = props;

  const { cenary = {} } = themeInfo || {};
  const { id: scenaryId = 0 } = cenary || {};
  const toolTypeId = selectedTool.tool.id;

  const selectInsight = getValidFunc(props.selectInsight);
  const saveUpdateInsight = getValidFunc(props.saveUpdateInsight);
  const cancelUpdate = getValidFunc(props.cancelUpdate);
  const handleEditInsight = getValidFunc(props.handleEditInsight);
  const openArchiveDialog = getValidFunc(props.openArchiveDialog);
  const openUnarchiveDialog = getValidFunc(props.openUnarchiveDialog);
  const openDeleteDialog = getValidFunc(props.openDeleteDialog);

  const RenderBadge = () => {
    const { allInsightGoals } = props;
    const finalGoal = getGoalEvaluationByID(insight.id, allInsightGoals);

    return (
      <>
        <EvaluationBadge
          from="answer"
          info={{
            answer: {
              ...insight,
              insightGoal: finalGoal.insightGoal,
            },
            workspace: selectedWorkspace,
            tool: selectedTool,
            themeID: themeInfo.id,
          }}
        />
      </>
    );
  };

  const displayAnalysisGroup = () => {
    const limbo = <></>;

    if (currentToolType !== "swot") return limbo;

    const { analysisGroups = [] } =
      currentAdministration[currentToolType] || {};

    const swotBoardPoints = boardPoints[currentToolType.toUpperCase()] || [];

    const { analysisGroupId = false } =
      swotBoardPoints.filter((info) => info.insightId === insight.id)[0] || {};

    if (!analysisGroupId) return limbo;

    const { title = "" } =
      analysisGroups.filter((group) => group.id === analysisGroupId)[0] || {};

    return (
      <span>
        <strong>{title}</strong> -
      </span>
    );
  };

  function handleBlockedInsight() {
    if (editModeOn)
      alert(
        "Não é possível selecionar o insight enquanto o modo de edição estiver ativo.",
      );
  }

  function handleMoveInsightDialog() {
    if (toolTypeId === SKILLS_ASSESSMENT) {
      setOpenDialogs((current) => {
        return {
          ...current,
          moveInsight: true,
        };
      });
    }
  }

  function closeMoveInsightDialog() {
    setOpenDialogs((current) => {
      return {
        ...current,
        moveInsight: false,
      };
    });
  }

  return (
    <div
      className="insight-box"
      style={{ fontSize: "12px", paddingRight: "10px" }}
    >
      <div
        className="clickable-row"
        onClick={
          !editing && !editModeOn
            ? () => selectInsight(insight)
            : handleBlockedInsight
        }
        style={{
          whiteSpace: "nowrap",
          fontFamily: "Montserrat",
          fontWeight: "500",
        }}
        title={insight.filed ? intl.formatMessage(messages.globalFiled) : null}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {insight && insight.avatar && avatarPermission ? (
            <Avatar
              name={insight.name}
              style={{ width: "30px", height: "30px" }}
              avatar={insight.avatar}
            />
          ) : (
            avatarPermission && (
              <Avatar
                name={
                  insight.responsibleMember
                    ? insight.responsibleMember.name
                    : ""
                }
                style={{ width: "30px", height: "30px" }}
                src={
                  insight.responsibleMember
                    ? insight.responsibleMember.userData.avatarBlobId
                    : ""
                }
              />
            )
          )}
          {insight.filed && (
            <i
              className="fas fa-archive"
              style={{ color: "#888", marginLeft: "12px" }}
            />
          )}
          {displayAnalysisGroup()}
          <EditableTextArea
            id={(insight && insight.id) || "newInsight"}
            text={insight.text || ""}
            onSave={(newName) =>
              saveUpdateInsight({ ...insight, text: newName })
            }
            onCancel={cancelUpdate}
            editing={editing}
            actionIcons
            width="300px"
            margin="none"
          />
        </div>
      </div>
      <div className="evaluation-row">
        <RenderBadge />
        {checkAccess(
          ["MANAGER", "LEADER", "MODERATOR", "ADMIN", "OWNER"],
          null,
          scenaryId,
        ) && (
          <div style={{ padding: "0px 0px 0px 9px" }}>
            <MaterialCustomMenu
              menuActions={getEntityMenuActions(
                handleEditInsight,
                openArchiveDialog,
                openDeleteDialog,
                insight.filed,
                insightsConfig,
                intl,
                openUnarchiveDialog,
                false,
                handleMoveInsightDialog,
              )}
            />
          </div>
        )}
      </div>
      {openDialogs.moveInsight && toolTypeId === SKILLS_ASSESSMENT && (
        <MoveSkillsAssessmentInsight
          open={openDialogs.moveInsight}
          insight={insight}
          closeDialog={() => closeMoveInsightDialog()}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { allInsightGoals } = insightsGoalState(state);
  const {
    boardPoints = {},
    currentToolType = "",
    currentAdministration = {},
  } = getCompAnalysisStates(state);

  const { selectedInsight, toolsConfigurations, workspacesData } = state;

  return {
    selectedInsight: selectedInsight.selectedInsight,
    toolsConfigurations,
    currentToolType,
    selectedWorkspace: workspacesData.selectedWorkspace,
    allInsightGoals,
    currentAdministration,
    boardPoints,
  };
};

export default injectIntl(connect(mapStateToProps)(InsightBox));
