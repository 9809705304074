import React from "react";
import { Row, Col } from "react-bootstrap";
import { patternStyles, responsibleCardStyles } from "../jcss";
import BadgeProgressBar from "../../../../../../../Common/ReportCards/components/BadgeProgressBar";

const ResponsibleChart = ({ macroOverviewData = {} }) => {
  const {
    leftAlign = "",
    strongFont = "",
    fixedScroll = "",
    centerAlign = "",
    cardHeaderStyle = "",
    responsibleStyle = "",
  } = patternStyles();

  const {
    valueFontStyle = "",
    percentageHeader = "",
    percentageFontStyle = "",
  } = responsibleCardStyles();

  const { responsible = [] } = macroOverviewData;

  const getBarColor = (width = 0) => {
    if (width > 100) return "kanbanRed";

    return "kanbanGreen";
  };

  const getLine = (
    { name = "", projects = 0, scheduleAdvance = 0, width = "" },
    index = 0,
  ) => {
    const widthLimited = scheduleAdvance >= 100 ? 100 : scheduleAdvance;

    return (
      <Row style={{ padding: "5px 0 5px 0" }} key={index}>
        <Col md={5} sm={6} className={leftAlign}>
          <div
            title={name}
            style={{
              fontSize: "13px",
              overflow: "hidden",
              whiteSpace: "noWrap",
            }}
          >
            {name}
          </div>
        </Col>
        <Col md={1} sm={1} className={centerAlign}>
          <div className={valueFontStyle}>{projects}</div>
        </Col>
        <Col md={3} sm={3}>
          <div style={{ position: "relative" }}>
            <BadgeProgressBar
              widthBar={width}
              heightBar="18px"
              leftText={100}
              borderRadius="4px"
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 999,
                fontWeight: "bold",
                marginLeft: "5%",
              }}
            >
              {width}
            </div>
          </div>
        </Col>
        <Col md={3} sm={3}>
          <div style={{ position: "relative" }}>
            <BadgeProgressBar
              widthBar={`${widthLimited}%`}
              heightBar="18px"
              leftText={100}
              borderRadius="4px"
              barColor={getBarColor(scheduleAdvance)}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 999,
                fontWeight: "bold",
                marginLeft: "10%",
              }}
            >
              {scheduleAdvance}%
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const orderedResponsibles = responsible?.sort((a, b) =>
    a?.name?.localeCompare(b.name),
  );

  return (
    <Col md={4} sm={12} className={responsibleStyle}>
      <Row className={cardHeaderStyle}>
        <strong>Responsável</strong>
      </Row>
      <Row style={{ paddingTop: "8px" }}>
        <Col md={12} sm={12} style={{ padding: "0px" }}>
          <Row style={{ backgroundColor: "#F7F7F7" }}>
            <Col md={5} sm={5} className={leftAlign}>
              <div className={strongFont} style={{ fontSize: "15px" }}>
                Nome
              </div>
            </Col>
            <Col md={1} sm={1} className={centerAlign}>
              <div className={strongFont} style={{ fontSize: "15px" }}>
                Nº
              </div>
            </Col>
            <Col md={2} sm={2} className={leftAlign}>
              <div className={percentageHeader} style={{ fontSize: "15px" }}>
                Físico
              </div>
            </Col>
            <Col md={4} sm={4} className={leftAlign}>
              <div className={percentageHeader} style={{ fontSize: "15px" }}>
                Cronograma
              </div>
            </Col>
          </Row>
          <Row className={fixedScroll} style={{ maxHeight: "220px" }}>
            {orderedResponsibles.map((line, index) => getLine(line, index))}
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default ResponsibleChart;
