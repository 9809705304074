import React from "react";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import {
  getUpdatedDeleteArrayByIndex,
  getUpdatedObjectInArray,
  getOnlySublevelArrays,
} from "../../../../../../../utils/ArrayUtils";

const AnalysisAmbient = (props) => {
  const {
    cpAnalysisAdministration = {},
    setCpAnalysisAdministration,
    intl,
    getEmptyTableRow,
    getDefaultRemoveColumn,
    getDefaultAddTableLine,
    setConfirmationDialog,
    resetConfirmDialog,
    toggleOpenFormDialogs,
    allThemes,
    deleteAnalysisAmbient,
    selectedTool,
  } = props;

  const { swot = [] } = cpAnalysisAdministration || {};
  const { analysisAmbient = [] } = swot || {};

  const updateSwotField = (field = false, value = "") => {
    if (!field) return;

    const swotConfig = cpAnalysisAdministration.swot || {};

    setCpAnalysisAdministration({
      ...cpAnalysisAdministration,
      swot: {
        ...swotConfig,
        [field]: [...value],
      },
    });
  };

  const handleUpdateAnalysisAmbient = (
    value = "",
    field = "",
    index = null,
  ) => {
    const updated = getUpdatedObjectInArray(
      value,
      analysisAmbient,
      field,
      index,
    );

    updateSwotField("analysisAmbient", updated);
  };

  const handleDeleteAmbient = (index) => {
    const { id = "" } = analysisAmbient[index];

    deleteAnalysisAmbient(id, "swot", selectedTool.id);

    resetConfirmDialog();
  };

  const getBoardConnection = (referedAmbient = {}) => {
    const onlyThemes = getOnlySublevelArrays(allThemes);

    const filtered =
      onlyThemes.filter(
        (theme) => theme.competitiveAnalysisAmbientId === referedAmbient.id,
      )[0] || false;

    return filtered ? true : filtered;
  };

  const openDeleteAnalysisAmbient = (index) => {
    const referedAmbient = analysisAmbient[index];

    const { title } = referedAmbient;

    const checkBoardConnection = getBoardConnection(referedAmbient);

    if (checkBoardConnection)
      return setConfirmationDialog({
        title:
          "Você precisa apagar o tema " +
          title +
          " para continuar com a exclusão.",
        onConfirm: () => resetConfirmDialog(),
        onCancel: () => resetConfirmDialog(),
        open: true,
      });

    setConfirmationDialog({
      title: "Você tem certeza?",
      onConfirm: () => handleDeleteAmbient(index),
      onCancel: () => resetConfirmDialog(),
      open: true,
    });
  };

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="2">Título</td>
          <td />
        </tr>
      </thead>
      <tbody>
        {analysisAmbient.map((ambientInfo, index) => {
          const { title } = ambientInfo || {};

          return (
            <tr className="block-hover" key={index}>
              <td align="center" style={{ width: "40px" }}>
                {index + 1}
              </td>
              <SimpleExcelCollumn
                id="ambientName"
                label="Titulo"
                type="text"
                value={title}
                onChange={(e) =>
                  handleUpdateAnalysisAmbient(e.target.value, "title", index)
                }
              />
              {getDefaultRemoveColumn(() => openDeleteAnalysisAmbient(index))}
            </tr>
          );
        })}
        {getEmptyTableRow(analysisAmbient, "Nada para mostrar!")}
        {getDefaultAddTableLine(() =>
          toggleOpenFormDialogs("newSwotAnalysisAmbient"),
        )}
      </tbody>
    </table>
  );
};

export default AnalysisAmbient;
