import axios from "axios";
import {
  FETCH_INSIGHT_TRACEABILITIES,
  UPDATE_ALL_INSIGHT_TRACEABILITIES,
  FETCH_INSIGHTS_TRACEABILITIES,
} from "./types";
import { updateInsightScore, getInsight } from "./insightActions";
import { sendConnectionNotification } from "./notifications/controllerActions";

const getTraceability = (insightID) => {
  return axios
    .get(`/api/insight-traceabilities/insight/${insightID}`)
    .then((res) => res);
};

const putTraceability = (insightID, traceabilityBody) => {
  return axios
    .put(`/api/insight-traceabilities/${insightID}`, traceabilityBody)
    .then((res) => res);
};

export const getInsightTraceabilities = (insight_id) => {
  return async (dispatch) => {
    const res = await getTraceability(insight_id);

    dispatch({
      type: FETCH_INSIGHT_TRACEABILITIES,
      payload: { insightId: insight_id, traceability: res.data },
    });
  };
};

export const getTraceabilitiesList = (insightsList) => {
  return (dispatch) => {
    axios
      .get(`/api/insights-traceabilities?insightsList=${insightsList}`)
      .then((res) => {
        dispatch({
          type: FETCH_INSIGHTS_TRACEABILITIES,
          payload: { traceabilities: res.data },
        });
      });
  };
};

export const deleteTraceability = (traceabilityId, insight_id) => {
  return (dispatch) => {
    return axios
      .delete(`/api/insights/${insight_id}/traceabilities/${traceabilityId}`)
      .then((res) => {
        dispatch(updateInsightScore(insight_id));
        dispatch(getInsightTraceabilities(insight_id));
      });
  };
};

export const postTraceability = (traceability, insight_id) => {
  return (dispatch) => {
    putTraceability(insight_id, traceability).then((res) => {
      if (res.data) {
        dispatch(updateInsightScore(insight_id));
        dispatch(getInsightTraceabilities(insight_id));
      }
    });
  };
};

export const updateInsightTraceabilityData = (insight, insight_id) => {
  return (dispatch) => {
    return axios
      .put(`/api/insight-traceabilities/${insight_id}`, insight)
      .then((res) => {
        dispatch(getInsightTraceabilities(insight_id));
      });
  };
};

export const saveInsightMultiConnections = (
  connectedInsightId,
  insightsList,
) => {
  return (dispatch) => {
    const finalSave = async (connections) => {
      let savedConnections = [];

      await Promise.all(
        connections.map(async (insightBody) => {
          const originInsight = await getInsight(connectedInsightId);

          const traceability1 = {
            type: "insight_traceability",
            insight: insightBody,
          };
          const traceability2 = {
            type: "insight_traceability",
            insight: originInsight,
          };

          const { data: conn1 = {} } = await putTraceability(
            connectedInsightId,
            traceability1,
          );
          const { data: conn2 = {} } = await putTraceability(
            insightBody.id,
            traceability2,
          );

          if (conn1) savedConnections.push(conn1);
          //console.log({ conn1, conn2 });
        }),
      );

      dispatch({
        type: UPDATE_ALL_INSIGHT_TRACEABILITIES,
        payload: {
          insightId: connectedInsightId,
          traceability: savedConnections,
        },
      });

      dispatch(sendConnectionNotification(connectedInsightId));
    };

    const buildInsights = async () => {
      let insights = [];

      await Promise.all(
        insightsList.map(async (insightID) => {
          if (insightID !== connectedInsightId) {
            await getInsight(insightID).then((insightBody) => {
              if (insightBody) insights = [...insights, insightBody];
            });
          }
        }),
      );

      finalSave(insights);
    };

    buildInsights();
  };
};
