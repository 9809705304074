import LeadershipMap from "./includes/LeadershipMap/LeadershipMap";

function LeadershipMapOverview({ toolAdministration, selectAndOpenInsight }) {
  return (
    <>
      <LeadershipMap
        toolAdministration={toolAdministration}
        selectAndOpenInsight={selectAndOpenInsight}
      />
    </>
  );
}

export default LeadershipMapOverview;
