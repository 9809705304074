import React from "react";

import NewIndicatorDialog from "../../../../../Common/Kpi/forms/NewIndicatorDialog";
import NewScenarioDialog from "../../../../../Common/Kpi/forms/NewScenarioDialog";
import NewGroupDialog from "../../../../../Common/Kpi/forms/NewGroupDialog";
import NewProcessDialog from "../../../../../Common/Kpi/forms/NewProcessDialog";

const DisplayOpenForms = (props) => {
  const { essencialProps } = props;

  const {
    openFormDialogs,
    resetEditingDialog,
    closeFormAndCallback,
    handleAutoSave,
  } = essencialProps;

  const resetFormAndSave = (newKpiConfig, callbacks) => {
    resetEditingDialog();

    handleAutoSave(newKpiConfig, callbacks);
  };

  return (
    <>
      {openFormDialogs.indexOf("newTheme") > -1 && (
        <NewScenarioDialog
          open
          afterConfirm={(newKpiConfig) =>
            closeFormAndCallback("newTheme", () =>
              resetFormAndSave(newKpiConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback("newTheme", resetEditingDialog)
          }
          {...essencialProps}
        />
      )}
      {openFormDialogs.indexOf("newGroup") > -1 && (
        <NewGroupDialog
          open
          afterConfirm={(newKpiConfig) =>
            closeFormAndCallback("newGroup", () =>
              resetFormAndSave(newKpiConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback("newGroup", resetEditingDialog)
          }
          {...essencialProps}
        />
      )}
      {openFormDialogs.indexOf("newProcess") > -1 && (
        <NewProcessDialog
          open
          afterConfirm={(newKpiConfig) =>
            closeFormAndCallback("newProcess", () =>
              resetFormAndSave(newKpiConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback("newProcess", resetEditingDialog)
          }
          {...essencialProps}
        />
      )}
      {openFormDialogs.indexOf("newIndicator") > -1 && (
        <NewIndicatorDialog
          open
          afterConfirm={(newKpiConfig, callbacks) =>
            closeFormAndCallback("newIndicator", () =>
              resetFormAndSave(newKpiConfig, callbacks),
            )
          }
          afterCancel={() =>
            closeFormAndCallback("newIndicator", resetEditingDialog)
          }
          {...essencialProps}
        />
      )}
    </>
  );
};

export default DisplayOpenForms;
