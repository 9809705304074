import { getObjectInfoById, getOnlySublevelArrays } from "../ArrayUtils";

function addRole(user, newRoles = [], role = "COLLABORATOR", access = "EDIT") {
  const { jhiUserId: id, insightId = "" } = user;

  if (id) newRoles.push({ id, role, access, insightId });

  return newRoles;
}

export const getSkillAssessmentCollaborators = (
  allAnswers = {},
  participants = [],
  onlyToolAccess = false,
) => {
  const insights = getOnlySublevelArrays(allAnswers);

  if (Array.isArray(insights) && insights.length > 0) {
    let usersWithAccess = [];

    insights.forEach((insight) => {
      const {
        id: insightId = "",
        skillsAssessmentParticipantId: pID,
        owner = {},
        filed = null,
      } = insight;

      if (filed === true) {
        usersWithAccess.push({ insight, users: [] });
        return;
      }

      const participant = getObjectInfoById(pID, participants, "id");

      if (participant?.id) {
        const users = [];

        const add = (u, r, access = "EDIT") =>
          addRole({ ...u, insightId }, users, r, access);

        if (!onlyToolAccess) {
          add(owner, "CREATOR");
          add(participant.memberParticipant, "RESPONSIBLE");
          add(participant.manager, "COLLABORATOR");
          add(participant.secondEvaluator, "VIEWER", "RESTRICT");
          add(participant.thirdEvaluator, "VIEWER", "RESTRICT");

          usersWithAccess.push({ insight, users });
        }

        if (onlyToolAccess) {
          add(owner, "VIEWER", "RESTRICT");
          add(participant.memberParticipant, "VIEWER", "RESTRICT");
          add(participant.manager, "VIEWER", "RESTRICT");
          add(participant.secondEvaluator, "VIEWER", "RESTRICT");
          add(participant.thirdEvaluator, "VIEWER", "RESTRICT");

          usersWithAccess = [...usersWithAccess, ...users];
        }
      }
    });

    return usersWithAccess;
  }

  return [];
};
