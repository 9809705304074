import React from "react";
import PeriodsConfig from "./PeriodsConfig";
import { KPIS } from "../../../constants/tools.constants";

const PeriodConfigurationView = ({
  selectedWorkspaceID,
  selectedDomainId,
  toolTypeId,
  loggedUser,
  workspaceTools,
  selectedTool,
}) => {
  const groupedToolsByType = workspaceTools.filter(
    ({ tool }) => tool.id === toolTypeId,
  );

  function isSupportedToolType() {
    return toolTypeId === KPIS;
  }

  return (
    isSupportedToolType() && (
      <div className="col-xs-12 col-md-8">
        <h2>Configuração de Períodos</h2>
        <PeriodsConfig
          selectedWorkspaceID={selectedWorkspaceID}
          selectedDomainId={selectedDomainId}
          selectedTool={selectedTool}
          toolTypeId={toolTypeId}
          workspaceTools={groupedToolsByType}
          loggedUser={loggedUser}
        />
      </div>
    )
  );
};

export default PeriodConfigurationView;
