import { apiNode } from "../api";
import { KPIS, SWOT } from "../constants/tools.constants";
import { groupBy } from "../utils/ArrayUtils";
import { getKpiConfiguration } from "./kpiActions";
import { getSkillAssessmentConfig } from "./skillAssessmentActions";
import { createSelectedWorkspaceTool } from "./toolActions";

const DEBUG = false;
function debug(arg1, ...optionals) {
  if (DEBUG) console.log("Custom Periods: ", arg1, ...optionals);
}

export function getSelectedToolCustomPeriod(selectedToolId) {
  return apiNode.get(`/api/v2/custom-periods/selected-tool/${selectedToolId}`);
}

export function getCloneServiceStatus(toolTypeId) {
  return apiNode.get(
    `/api/v2/custom-periods/selected-tool/clone-service-status/${toolTypeId}`
  );
}

export function postCustomPeriod(newCustomPeriod) {
  return apiNode.post("/api/v2/custom-periods", newCustomPeriod);
}

export function putCustomPeriod(updated_custom_period) {
  return apiNode.put("/api/v2/custom-periods", { updated_custom_period });
}

export async function getDefaultPeriodByWorkspaceAndToolType({
  workspace_id,
  tool_type_id,
}) {
  return apiNode.get(
    `/api/v2/custom-periods/workspace-tools/${workspace_id}/${tool_type_id}`
  );
}

export async function filterDisplayWorkspaceTools(
  selectedWorkspaceId,
  workspaceTools
) {
  const filtered = workspaceTools.filter(
    ({ workspaceId, tool }) =>
      workspaceId === selectedWorkspaceId && tool.id !== SWOT
  );
  const groupedTools = groupBy(
    filtered.map((toolInfo) => {
      return {
        ...toolInfo,
        toolTypeId: toolInfo.tool.id,
      };
    }),
    "toolTypeId"
  );

  return Promise.all(
    groupedTools
      .filter((tools) => tools?.length > 0)
      .map(async (tools) => {
        const { data: defaultPeriod } =
          await getDefaultPeriodByWorkspaceAndToolType({
            workspace_id: selectedWorkspaceId,
            tool_type_id: tools[0]?.tool.id,
          });
        debug({ defaultPeriod });
        return defaultPeriod?.tool_id
          ? tools.find(({ id }) => id === defaultPeriod?.tool_id)
          : tools[0];
      })
  );
}

export async function getCustomPeriodWorkspaceTools(toolsGroupedByType = []) {
  const periods = [];

  await Promise.all(
    toolsGroupedByType.map(async (toolInfo) => {
      const request = await getSelectedToolCustomPeriod(toolInfo.id);
      if (request?.data?.id) periods.push(request.data);
    })
  );

  return periods;
}

export async function importSkillsAssessment(
  selectedToolId,
  newToolId,
  import_config
) {
  const { data: templateAdministration } = await getSkillAssessmentConfig(
    selectedToolId
  );

  if (templateAdministration?.id) {
    await apiNode.post("api/v2/entity-connections/connect/skillsAssessment", {
      import_data: templateAdministration,
    });

    const clonedData = await apiNode.post(
      "api/v2/entity-connections/clone/skillsAssessment",
      {
        selectedToolId: newToolId,
        import_data: templateAdministration,
        import_config,
      }
    );
    debug({ clonedData, templateAdministration });
  }
}

export async function importKPIs(
  domain_id,
  workspaceId,
  selectedToolId,
  newTool,
  import_config,
  group_hash
) {
  const { data: templateAdministration } = await getKpiConfiguration(
    selectedToolId
  );

  if (selectedToolId && templateAdministration?.id) {
    await apiNode.post("api/v2/entity-connections/connect/kpis", {
      import_data: templateAdministration,
      group_hash,
    });

    const clonedData = await apiNode.post(
      "api/v2/entity-connections/clone/kpis",
      {
        domain_id,
        workspaceId,
        parentSelectedToolId: selectedToolId,
        selectedTool: newTool,
        import_data: templateAdministration,
        import_config,
        group_hash,
      }
    );
    debug({ clonedData, templateAdministration });
  }
}

export async function postNewToolAndPeriod({
  customPeriod,
  workspaceId,
  loggedUser,
  importConfig,
  templateConfig,
  callback,
}) {
  return async (dispatch) => {
    if (templateConfig?.tool_type_id) {
      dispatch(
        createSelectedWorkspaceTool(
          templateConfig.tool_type_id,
          workspaceId,
          loggedUser.id,
          false,
          "MANAGER",
          async (addedTool) => {
            const { data } = await postCustomPeriod({
              ...customPeriod,
              tool_id: addedTool.id,
              parent_custom_period_id: templateConfig?.custom_period_id,
            });

            if (importConfig?.doImport && templateConfig?.tool_id) {
              if (templateConfig.tool_type_id === KPIS)
                await importKPIs(
                  templateConfig?.domain_id,
                  workspaceId,
                  templateConfig?.tool_id,
                  addedTool,
                  importConfig,
                  data.id
                );
            }

            if (typeof callback === "function") callback(addedTool);
          }
        )
      );
    }
  };
}
