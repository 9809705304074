import {
  SWAP_ORDER,
  SELECT_INSIGHT,
  UNSELECT_INSIGHT,
  OPEN_INSIGHT_MODAL,
  UPDATE_INSIGHT_DATA,
  CHANGE_STATUS_ANSWER,
  UPDATE_QUESTION_ANSWER,
  DELETE_QUESTION_ANSWER,
  FETCH_INSIGHT_RESPONSABLE,
  FETCH_ALL_QUESTIONS_ANSWERS,
  UPDATE_SINGLE_INSIGHT,
} from "../actions/types";
import { ENTITY_TYPE } from "../actions/orderedEntityActions";

const INITIAL_STATE = {
  allAnswers: [],
  selectedInsight: null,
  openInsightModal: false,
  insightToSelect: {},
};

const answersReducer = (state = INITIAL_STATE, action) => {
  const {
    allAnswers = {},
    selectedInsight: currentSelectedInsight = null,
    openInsightModal = false,
  } = state || {};

  const { payload = {} } = action || {};
  const { answer = null, questionId = null, insightID = null } = payload || {};

  const deleteInsight = (answer = {}) => {
    const { question = {}, id: deleteInsightId = false } = answer || {};
    const { id: questionId = false } = question || {};

    if (!deleteInsightId || !questionId) return state;

    const currentQInsights = allAnswers[questionId] || [];

    const filtered =
      currentQInsights.filter((insight) => insight.id !== deleteInsightId) ||
      [];

    return { ...allAnswers, [questionId]: filtered };
  };

  const updateAnswer = (newInsight, qID, isDeleting = false) => {
    const currentQuestionInsights =
      allAnswers && allAnswers[qID] ? allAnswers[qID] : [];

    if (currentQuestionInsights.length > 0) {
      let updatedSelectedInsight = currentSelectedInsight;
      const updatedQuestionInsights = [];
      let isNew = true;

      currentQuestionInsights.forEach((currentInsight) => {
        const sameInsight = currentInsight.id === newInsight.id;
        const sameSelectedInsight =
          currentSelectedInsight && currentSelectedInsight.id === newInsight.id;
        const updatedFinalInsight = sameInsight ? newInsight : currentInsight;

        const append = !(sameInsight && isDeleting);

        if (sameInsight) {
          isNew = false;
          if (sameSelectedInsight)
            updatedSelectedInsight = { ...updatedFinalInsight };
        }

        if (append) updatedQuestionInsights.push({ ...updatedFinalInsight });
      });

      if (isNew && !isDeleting) updatedQuestionInsights.push(newInsight);

      return {
        ...state,
        allAnswers: { ...allAnswers, [qID]: updatedQuestionInsights },
        selectedInsight: updatedSelectedInsight,
      };
    }
    const updatedQuestionInsights = isDeleting ? [] : [newInsight];

    return {
      ...state,
      allAnswers: { ...allAnswers, [qID]: updatedQuestionInsights },
    };
  };

  const getInsightInfoByID = (insightID) => {
    let finalInfo = {};

    Object.keys(allAnswers).forEach((itemQuestionId) => {
      const questionAnswers = allAnswers[itemQuestionId];

      if (Array.isArray(questionAnswers)) {
        questionAnswers.forEach((answerBody) => {
          if (answerBody.id === insightID)
            finalInfo = {
              body: answerBody,
              questionID: itemQuestionId,
            };
        });
      }
    });

    return finalInfo;
  };

  switch (action.type) {
    case FETCH_ALL_QUESTIONS_ANSWERS: {
      const stateAnswers = state.allAnswers ? state.allAnswers : {};
      const finalAnswers = { ...stateAnswers, ...action.payload };

      return {
        ...state,
        allAnswers: {
          ...state.allAnswers,
          ...finalAnswers,
        },
      };
    }
    case CHANGE_STATUS_ANSWER:
      return updateAnswer(answer, questionId);
    case UPDATE_QUESTION_ANSWER:
      return updateAnswer(answer, questionId);
    case DELETE_QUESTION_ANSWER: {
      const filteredAllAnswers = deleteInsight(answer);

      return {
        ...state,
        allAnswers: { ...filteredAllAnswers },
      };
    }
    case SWAP_ORDER:
      if (action.payload.entityType === ENTITY_TYPE.insight) {
        const questionID = action.payload.parentId;

        return {
          ...state,
          allAnswers: { ...allAnswers, [questionID]: action.payload.list },
        };
      }
      return state;

    case SELECT_INSIGHT: {
      const insightInfo = insightID ? getInsightInfoByID(insightID) : null;

      return {
        ...state,
        selectedInsight:
          insightInfo && insightInfo.body ? insightInfo.body : null,
      };
    }
    case UNSELECT_INSIGHT:
      return {
        ...state,
        selectedInsight: null,
      };
    case UPDATE_INSIGHT_DATA:
    case FETCH_INSIGHT_RESPONSABLE:
      if (action.payload && action.payload.id && action.payload.question) {
        return updateAnswer(action.payload, action.payload.question.id, false);
      }

      return state;
    case "ARCHIVE_MANY_INSIGHTS":
      /*
      if (action.payload.insights) {
        const { insights = [] } = action.payload.insights;
      }
      */
      return state;
    case "RESET_INSIGHTS":
      if (openInsightModal) return { ...state, allAnswers: {} };

      return {
        ...state,
        allAnswers: {},
        selectedInsight: null,
      };
    case OPEN_INSIGHT_MODAL:
      return {
        ...state,
        openInsightModal: true,
        selectedInsight: payload,
      };
    case UPDATE_SINGLE_INSIGHT:
      const insight = action.payload;
      return {
        ...state,
        allAnswers: {
          ...allAnswers,
          [insight.questionId]: [{ ...insight }],
        },
      };
    default:
      return state;
  }
};

export default answersReducer;
