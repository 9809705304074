import { apiNode } from "../../../api";

export const searchActivities = ({ insight_id }) => {
  return apiNode.get(
    `/api/v2/entity-documents/rookau_tool_processes_activity/search-activities/${insight_id}`,
  );
};

export const searchAllInsightActivities = ({ insight_ids }) => {
  return apiNode.post(
    "/api/v2/entity-documents/rookau_tool_processes_activity/search-all-insight-activities",
    { insight_ids },
  );
};

export const createActivity = ({ insight_id, document_data }) => {
  return apiNode.post(
    "/api/v2/entity-documents/rookau_tool_processes_activity/create-activity",
    { insight_id, document_data },
  );
};

export const updateActivity = ({ insight_id, document_id, document_data }) => {
  return apiNode.put(
    "/api/v2/entity-documents/rookau_tool_processes_activity/update-activity",
    { insight_id, document_id, document_data },
  );
};

export const deleteActivity = ({ insight_id, document_id, collaborators }) => {
  return apiNode.delete(
    `/api/v2/entity-documents/rookau_tool_processes_activity/delete-activity/${insight_id}/${document_id}`,
    {
      data: {
        collaborators,
      },
    },
  );
};

export const createChecklistItem = ({ insight_id, document_id, item }) => {
  return apiNode.post(
    "/api/v2/entity-documents/rookau_tool_processes_activity/create-activity-checklist-item",
    { insight_id, document_id, item },
  );
};

export const updateChecklistItem = ({ insight_id, document_id, item }) => {
  return apiNode.put(
    "/api/v2/entity-documents/rookau_tool_processes_activity/update-activity-checklist-item",
    { insight_id, document_id, item },
  );
};

export const deleteChecklistItem = ({
  insight_id,
  document_id,
  checklist_id,
}) => {
  return apiNode.delete(
    `/api/v2/entity-documents/rookau_tool_processes_activity/delete-activity-checklist-item/${insight_id}/${document_id}/${checklist_id}`,
  );
};
