import React from "react";
import styled from "styled-components";

import { getPercentage } from "../../../../../../utils/MathUtils";

const VariationValue = styled.div`
  color: ${(props) => (props.color ? props.color : "")};

  @media print {
    color: ${(props) => (props.color ? props.color : "")} !important;
  }
`;

const IconCaret = styled.i`
  color: ${(props) => (props.color ? props.color : "")};

  @media print {
    color: ${(props) => (props.color ? props.color : "")} !important;
  }
`;

const EvaluationResultField = (props) => {
  const { filteredEvaluations, messages, intl } = props;

  const displayEvaluationResult = () => {
    const total = {
      current: 0,
      previous: 0,
    };
    let evaluation = {
      current: 0,
      previous: 0,
    };

    filteredEvaluations.forEach(({ peopleEvaluation }) => {
      const { evaluationTarget } = peopleEvaluation;
      const { lastEvaluations } = evaluationTarget;
      const { average } = lastEvaluations;

      if (average.current) {
        evaluation.current += average.current;
        total.current++;
      }
      if (average.previous) {
        evaluation.previous += average.previous;
        total.previous++;
      }
    });

    evaluation = {
      current:
        evaluation.current > 0
          ? (evaluation.current / total.current).toFixed(1)
          : 0,
      previous:
        evaluation.previous > 0
          ? (evaluation.previous / total.previous).toFixed(1)
          : 0,
    };

    const caretBadge =
      evaluation.current >= evaluation.previous ? (
        <IconCaret className="fas fa-caret-up" color="#0b8b34" />
      ) : (
        <IconCaret className="fas fa-caret-down" color="#f85b5b" />
      );

    const variation = getPercentage(evaluation.current, evaluation.previous);

    return (
      <div className="simple-average-evaluation row">
        <div className="evaluations col-xs-12">
          <div className="evaluation">
            <h6 className="titles">
              {intl.formatMessage(messages.labels_current_year)}
            </h6>
            <div className="average-div">
              {!isNaN(variation) && (
                <div className="caret-badge">{caretBadge}</div>
              )}
              {evaluation.current}
            </div>
          </div>
          <div className="evaluation">
            <h6 className="titles">
              {intl.formatMessage(messages.labels_last_year)}
            </h6>
            <div className="average-div">{evaluation.previous}</div>
          </div>
        </div>
        <div className="information col-xs-12">
          <h6 className="titles">
            {intl.formatMessage(messages.labels_variation)}
          </h6>
          <VariationValue
            className="average-div"
            color={
              evaluation.current > evaluation.previous ? "#0b8b34" : "#f85b5b"
            }
          >
            {!isNaN(variation) ? `${variation.toFixed(1)}%` : "0%"}
          </VariationValue>
        </div>
      </div>
    );
  };

  return (
    <div className="evaluation-card">
      <div className="card-header">
        <div className="card-title">
          <h4>{intl.formatMessage(messages.titles_cards_evaluation_result)}</h4>
        </div>
      </div>
      <div className="card-body" style={{ height: "325px" }}>
        {displayEvaluationResult()}
      </div>
    </div>
  );
};

export default EvaluationResultField;
