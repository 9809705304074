import axios from "axios";
import { configPlansBody } from "../utils/okr/entity";
import { putTheme, fetchAllSceneryThemes } from "./themesActions";
import { UPDATE_CENARY_THEME } from "./types";
import {
  getAlreadyAddedCycleGroupsIds,
  getFilteredCycleGroupFromArray,
  getFilteredNewCyclesOnGroup,
} from "../utils/okr/filter";

import { PUT_PLAN } from "../constants/okr";
import { getOnlySlugValues } from "../utils/ArrayUtils";

export const getOkrConfiguration = async (selectedToolId) => {
  return axios
    .get(`/api/selected-tools/${selectedToolId}/okrConfiguration`)
    .then((res) => res.data)
    .catch((e) => console.log(e));
};

export const getPlanOnly = async (planId) => {
  return axios.get(`/api/okr-configuration-plans/${planId}`).then((res) => res);
};

export const putPlanOnly = async (updatedPlan) => {
  return axios
    .put("/api/okr-configuration-plans", updatedPlan)
    .then((res) => res);
};

export const postThemeCycle = (themeId, cycleId) => {
  return axios
    .post(`/api/okr-configurations/theme/${themeId}/cycle/${cycleId}`)
    .then((res) => res);
};

export const createTheme = (cenaryBody, cycleTitle) => {
  return putTheme({
    id: null,
    type: "theme",
    cenary: cenaryBody,
    name: cycleTitle,
  });
};

export const dispatchTheme = (cenaryId, newTheme) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CENARY_THEME,
      payload: {
        cenaryId,
        theme: {
          ...newTheme,
          isNew: true,
        },
      },
    });
  };
};

export const createCycleTheme = (cenaryBody = {}, newCycleInfo = {}) => {
  return async (dispatch) => {
    if (cenaryBody.id && newCycleInfo.id) {
      try {
        const newTheme = await createTheme(cenaryBody, newCycleInfo.title);

        if (newTheme.data.id && newCycleInfo.id) {
          await postThemeCycle(newTheme.data.id, newCycleInfo.id);

          dispatch(dispatchTheme(cenaryBody.id, newTheme.data));
          dispatch(fetchAllSceneryThemes([cenaryBody.id]));
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };
};

export const addOnlyCyclesToBoard = (cenaryBody, newAddedCycles) => {
  return (dispatch) => {
    if (cenaryBody && newAddedCycles && Array.isArray(newAddedCycles)) {
      newAddedCycles.forEach((newCycleInfo, index) => {
        dispatch(createCycleTheme(cenaryBody, { ...newCycleInfo }));
      });
    }
  };
};

export const addNewCycleGroupToCenary = (
  newUpdatedPlan,
  cenaryBody,
  scenerieThemes,
  doUpdatePlan = true,
) => {
  return async (dispatch) => {
    const parsedNewUpdatedPlan = configPlansBody(newUpdatedPlan);

    const excludeOldIds = getAlreadyAddedCycleGroupsIds(
      parsedNewUpdatedPlan.cycleGroups,
    );

    const alreadyOnBoardThemeCycles = getOnlySlugValues(
      scenerieThemes,
      "okrConfigurationCycleId",
    );

    if (doUpdatePlan) await putPlanOnly(parsedNewUpdatedPlan);
    const savedUpdatedPlan = await getPlanOnly(parsedNewUpdatedPlan.id);

    if (savedUpdatedPlan.data && savedUpdatedPlan.data.id) {
      const restUpdatedPlan = savedUpdatedPlan.data;

      dispatch({ type: PUT_PLAN, payload: restUpdatedPlan });

      const filteredNewCycleGroups = getFilteredCycleGroupFromArray(
        restUpdatedPlan.cycleGroups,
        excludeOldIds,
      );

      if (filteredNewCycleGroups[0]) {
        filteredNewCycleGroups.forEach((newCycleGroupInfo) => {
          const newAddedCycles = newCycleGroupInfo.cycles;

          dispatch(addOnlyCyclesToBoard(cenaryBody, newAddedCycles));
        });
      }

      if (restUpdatedPlan.cycleGroups) {
        // Reconhecer apenas os Novos Ciclos adicionados aos Grupos Já Existentes
        restUpdatedPlan.cycleGroups.forEach((addedCycleGroup) => {
          if (excludeOldIds.indexOf(addedCycleGroup.id) > -1) {
            const onlyNewCycles = getFilteredNewCyclesOnGroup(
              addedCycleGroup.cycles,
              alreadyOnBoardThemeCycles,
            );

            dispatch(addOnlyCyclesToBoard(cenaryBody, onlyNewCycles));
          }
        });
      }
    }
  };
};
