import { apiJava } from "../../api";

// Indicator Metrics

export const createIndicatorMetric = (indicatorMetricData) => {
  return apiJava.post("/api/indicator-metrics", indicatorMetricData);
};

export const updateIndicatorMetric = (
  indicatorMetricId,
  indicatorMetricData,
) => {
  return apiJava.put(
    `/api/indicator-metrics/${indicatorMetricId}`,
    indicatorMetricData,
  );
};

export const partialUpdateIndicatorMetric = (
  indicatorMetricId,
  indicatorMetricData,
) => {
  return apiJava.patch(
    `/api/indicator-metrics/${indicatorMetricId}`,
    indicatorMetricData,
  );
};

export const getAllIndicatorMetrics = () => {
  return apiJava.get("/api/indicator-metrics");
};

export const getIndicatorMetricById = (indicatorMetricId) => {
  return apiJava.get(`/api/indicator-metrics/${indicatorMetricId}`);
};

export const deleteIndicatorMetric = (indicatorMetricId) => {
  return apiJava.delete(`/api/indicator-metrics/${indicatorMetricId}`);
};

export const getIndicatorMetricsByToolId = (selectedToolId) => {
  return apiJava.get(`/api/indicator-metrics/selected-tool/${selectedToolId}`);
};
