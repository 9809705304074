import { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import utils from "../../../../utils/toolUtils";
import { updateQuestion } from "../../../../actions/questionsActions";

const {
  buildFormPrioritizationBody,
  getObjectDifference,
  getObjectInfoById,
  getOnlySlugValues,
  removeObjSlug,
} = utils;

const defaultInitialForm = buildFormPrioritizationBody({});

const ProjectPrioritization = (props) => {
  const [projectPrioritizationAdmin, setProjectPrioritizationAdmin] = useState(
    defaultInitialForm,
  );
  const [firstValidation, setFirstValidation] = useState(false);

  const {
    allProjectPrioritization = [],
    selectedTool = {},
    allQuestions = {},
    updateQuestion,
  } = props;

  const toolProjectPrioritization = getObjectInfoById(
    selectedTool.id,
    allProjectPrioritization,
    "selectedToolId",
  );

  useEffect(() => {
    const updateByReducer = !_.isEqual(
      toolProjectPrioritization,
      projectPrioritizationAdmin,
    );

    if (updateByReducer) {
      if (toolProjectPrioritization.projectGroups) {
        let stateProjectTypes = [];
        const reducerProjectTypes = [];

        if (projectPrioritizationAdmin.projectGroups) {
          projectPrioritizationAdmin.projectGroups.forEach(
            ({ projectTypes }) => {
              projectTypes.forEach((projectTypeInfo) => {
                stateProjectTypes.push(projectTypeInfo);
              });
            },
          );
        }
        stateProjectTypes = removeObjSlug(stateProjectTypes, "id");

        toolProjectPrioritization.projectGroups.forEach(({ projectTypes }) => {
          projectTypes.forEach((projectTypeInfo) => {
            reducerProjectTypes.push(projectTypeInfo);
          });
        });

        const diff = getObjectDifference(
          reducerProjectTypes,
          stateProjectTypes,
        );

        if (diff) {
          if (!firstValidation) setFirstValidation(true);

          const watchProjectTypes = getOnlySlugValues(diff, "id");

          if (watchProjectTypes.length > 0) {
            Object.keys(allQuestions).forEach((themeId) => {
              const themeQuestions = allQuestions[themeId];

              themeQuestions.forEach((questionInfo) => {
                const { projectPriorizationConfigurationTypeId } = questionInfo;
                const relatedProjectTypeId =
                  watchProjectTypes[
                    watchProjectTypes.indexOf(
                      projectPriorizationConfigurationTypeId,
                    )
                  ] || null;

                if (relatedProjectTypeId && questionInfo.text) {
                  const projectTypeInfo = getObjectInfoById(
                    relatedProjectTypeId,
                    reducerProjectTypes,
                    "id",
                  );

                  if (projectTypeInfo.title !== questionInfo.text) {
                    updateQuestion(
                      { ...questionInfo, text: projectTypeInfo.title },
                      questionInfo.theme.id,
                    );
                  }
                }
              });
            });
          }
        }
      }
      setProjectPrioritizationAdmin(toolProjectPrioritization);
    }
  }, [
    allQuestions,
    firstValidation,
    projectPrioritizationAdmin,
    toolProjectPrioritization,
    updateQuestion,
  ]);

  return null;
};

const mapStateToProps = (state) => {
  const { projectPrioritization } = state;
  const allProjectPrioritization = projectPrioritization.all
    ? projectPrioritization.all
    : [];

  return { allProjectPrioritization };
};

export default connect(mapStateToProps, { updateQuestion })(
  ProjectPrioritization,
);
