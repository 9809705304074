import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";

import FormDialog from "../../FormDialog";
import MaterialTextField from "../../MaterialTextField";
import NumberFormatCustom from "../../NumberFormatCustom";
import MaterialSingleSelect from "../../MaterialSingleSelect";
import SelectMemberModal from "../../SelectMemberModal";
import { getErrorMessage } from "../../../../utils/formValidation";

const messages = defineMessages({
  gb_form_job_role: { id: "gb_form_job_role" },
  gb_form_member: { id: "gb_form_member" },
  gb_form_salary_base: { id: "gb_form_salary_base" },
  gb_table_text_period: { id: "gb_table_text_period" },
  gb_dialog_title_new_participant: { id: "gb_dialog_title_new_participant" },
  gb_dialog_title_select_member: { id: "gb_dialog_title_select_member" },
  gb_error_participant_already_added: {
    id: "gb_error_participant_already_added",
  },
  gb_error_invalid_salary_base: { id: "gb_error_invalid_salary_base" },
  gb_error_invalid_period: { id: "gb_error_invalid_period" },
  gb_error_select_role: { id: "gb_error_select_role" },
  global_selectSome: { id: "global.selectSome" },
  global_goBack: { id: "global.goBack" },
});

const initialForm = {
  id: null,
  base: 0,
  period: "MONTH_PERIOD_12",
  roleBonus: {
    id: "",
  },
  memberParticipant: {
    id: "",
    type: "MEMBER",
  },
};

const NewParticipantDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const [replaceSelectedMembers, setReplaceSelectedMembers] = useState(false);
  const [memberModalOpen, setMemberModalOpen] = useState(false);

  const {
    open = false,
    afterCancel,
    afterConfirm,
    companyMembersAndParticipants = [],
    goalBonusAdministration = {},
    setGoalBonusAdministration,
    participantPeriodOptions = [],
    goalsBonusProgramRoleBonusId = null,
    getToolConfig,
    intl,
  } = props;

  const { participants = [], roleBonuses = [] } = goalBonusAdministration;

  const allAddedMemberParticipants = [
    ...participants.map(({ memberParticipant = {} }) => memberParticipant.id),
  ];

  useEffect(() => {
    if (open && goalsBonusProgramRoleBonusId) {
      setForm((prevForm) => {
        return { ...prevForm, roleBonus: { id: goalsBonusProgramRoleBonusId } };
      });
    }
  }, [open, goalsBonusProgramRoleBonusId]);

  const resetForm = () => {
    setForm(initialForm);
    setReplaceSelectedMembers(true);
  };

  const handleSave = async () => {
    const data = await getToolConfig();

    if (data && data.id) {
      const newConfig = {
        ...data,
        participants: [...data.participants, { ...form }],
      };

      setGoalBonusAdministration(newConfig);
      if (typeof afterConfirm === "function") afterConfirm(newConfig);
    }

    resetForm();
  };

  const handleCancel = () => {
    resetForm();

    if (typeof afterCancel === "function") afterCancel();
  };

  const updateSelectedMember = (selectedList) => {
    let newSelectedMember = null;

    if (selectedList && selectedList[0]) newSelectedMember = selectedList[0];

    setForm({
      ...form,
      memberParticipant: {
        id: newSelectedMember.id,
        type: newSelectedMember.type,
      },
    });
    setMemberModalOpen(false);
  };

  const getMemberDisplayName = (memberId) => {
    let displayName = intl.formatMessage(messages.global_selectSome);

    if (memberId) {
      companyMembersAndParticipants.forEach((memberInfo) => {
        if (memberId === memberInfo.id) displayName = memberInfo.name;
      });
    }

    return displayName;
  };

  const getFormErrors = () => {
    const errors = [];

    const alreadyAdded =
      allAddedMemberParticipants.indexOf(form.memberParticipant.id) > -1;

    if (!form.memberParticipant.id) errors.push({ slug: "memberNotSelected" });

    if (alreadyAdded)
      errors.push({
        slug: "cloningParticipant",
        message: intl.formatMessage(
          messages.gb_error_participant_already_added,
        ),
      });

    if (!form.roleBonus.id || form.roleBonus.id === "empty")
      errors.push({
        slug: "goalTargetsNotSelected",
        message: intl.formatMessage(messages.gb_error_select_role),
      });

    if (!form.period || form.period === "empty")
      errors.push({
        slug: "invalidPeriod",
        message: intl.formatMessage(messages.gb_error_invalid_period),
      });

    return errors;
  };

  const accessRoleOptions = roleBonuses.map(({ id, role }) => {
    return { value: id, label: role };
  });

  const formErrors = getFormErrors();
  const currentSelectedMember = form.memberParticipant.id
    ? [form.memberParticipant.id]
    : [];

  return (
    <>
      <SelectMemberModal
        title={intl.formatMessage(messages.gb_dialog_title_select_member)}
        membersList={companyMembersAndParticipants || []}
        onConfirm={(selected, selectedList) =>
          updateSelectedMember(selectedList)
        }
        onCancel={() => setMemberModalOpen(false)}
        open={memberModalOpen}
        cancelText={intl.formatMessage(messages.global_goBack)}
        replaceSelected={replaceSelectedMembers}
        initialSelected={currentSelectedMember}
        replaceCallback={() => setReplaceSelectedMembers(false)}
        singleSelect
      />
      <FormDialog
        open={open}
        title={intl.formatMessage(messages.gb_dialog_title_new_participant)}
        onConfirm={handleSave}
        onCancel={handleCancel}
        blockConfirm={formErrors.length > 0}
      >
        <div className="row">
          <div className="col-xs-8">
            <MaterialTextField
              id="participantMember"
              label={intl.formatMessage(messages.gb_form_member)}
              value={getMemberDisplayName(form.memberParticipant.id)}
              inputStyle={{ cursor: "pointer" }}
              onClick={() => setMemberModalOpen(true)}
            />
            {getErrorMessage(
              ["memberNotSelected", "cloningParticipant"],
              formErrors,
            )}
          </div>
          <div className="col-xs-4">
            <MaterialSingleSelect
              id="period"
              label={intl.formatMessage(messages.gb_table_text_period)}
              options={participantPeriodOptions}
              onChange={(e) => setForm({ ...form, period: e.target.value })}
              value={form.period}
            />
          </div>
          <div className="col-xs-6">
            <MaterialSingleSelect
              id="role"
              label={intl.formatMessage(messages.gb_form_job_role)}
              options={accessRoleOptions}
              onChange={(e) =>
                setForm({
                  ...form,
                  roleBonus: { ...form.roleBonus, id: e.target.value },
                })
              }
              value={form.roleBonus.id || ""}
            />
          </div>
          <div className="col-xs-6">
            <MaterialTextField
              id="participantSalaryBase"
              type="text"
              label={intl.formatMessage(messages.gb_form_salary_base)}
              value={form.base}
              onChange={(e) => setForm({ ...form, base: e.target.value })}
              primaryInputProps={{ inputComponent: NumberFormatCustom }}
            />
          </div>
        </div>
      </FormDialog>
    </>
  );
};

export default injectIntl(NewParticipantDialog);
