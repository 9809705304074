const en = {
  ACTIVE: "Active",
  CANCELED: "Canceled",
  EXPIRED: "Expired",
  INACTIVE: "Inactive",
  RENEWAL: "Renewal",
  CONTRACT: "Contract",
  POC: "POC",
  TEST: "Test",
};

const es = {
  ACTIVE: "Activo",
  CANCELED: "Cancelado",
  EXPIRED: "Caducado",
  INACTIVE: "Inactivo",
  RENEWAL: "Renovación",
  CONTRACT: "Contrato",
  POC: "POC",
  TEST: "Test",
};

const pt = {
  ACTIVE: "Ativo",
  CANCELED: "Cancelado",
  EXPIRED: "Expirado",
  INACTIVE: "Inativo",
  RENEWAL: "Renovado",
  CONTRACT: "Contrato",
  POC: "POC",
  TEST: "Teste",
};

const translations = { en, es, pt };

export default translations;
