import React from "react";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import { getPestalFactorTranslation } from "../../../../../../../utils/cpAnalysis/display";

const Factors = (props) => {
  const { cpAnalysisAdministration = {} } = props;

  const { pestal = [] } = cpAnalysisAdministration || {};
  const { factors = [] } = pestal || {};

  let orderedFactors = [...factors].sort((a, b) => (a.id > b.id ? 1 : -1));

  return (
    <div>
      <table className="simple-table">
        <thead>
          <tr>
            <td colSpan="2">Título</td>
          </tr>
        </thead>
        <tbody>
          {orderedFactors.map((factorInfo, index) => {
            const { type } = factorInfo || {};
            const translatedFactor = getPestalFactorTranslation(type);

            return (
              <tr className="block-hover" key={index}>
                <td align="center" style={{ width: "40px" }}>
                  {index + 1}
                </td>
                <SimpleExcelCollumn
                  id="factorName"
                  label="Titulo"
                  type="text"
                  value={translatedFactor}
                  tdStyle={{ pointerEvents: "none" }}
                />
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Factors;
