import React, { useEffect, useState } from "react";
import FormDialog from "./FormDialog";
import MaterialTextField from "./MaterialTextField";

const EditTextModal = ({ open, title, text, label, onConfirm, onCancel, maxCharacters = 1000 }) => {
  const [newText, setNewText] = useState("");

  useEffect(() => {
    setNewText(text);
  }, [text]);

  function handleConfirm() {
    onConfirm(newText);
  }

  return (
    <FormDialog
      open={open}
      title={title}
      onConfirm={handleConfirm}
      onCancel={onCancel}
    >
      <MaterialTextField
        label={label}
        value={newText}
        onChange={(e) => setNewText(e.target.value)}
        primaryInputProps={{ maxLength: maxCharacters }}
        multiline
      />
    </FormDialog>
  );
};

export default EditTextModal;
