import moment from "moment";

export const defaultComposition = "SIMPLE";
export const defaultEvaluationType = "TYPE_180";

export const getDefaultEvaluationStage = (
  enableEvaluatorsSelectionWorkflow,
) => {
  let defaultEvaluationStage = "VALIDATION";

  if (enableEvaluatorsSelectionWorkflow) {
    defaultEvaluationStage = "SELECTION_EVALUATORS";
  }

  return defaultEvaluationStage;
};

export const defaultEvaluationWeights = {
  selfEvaluation: 0,
  manager: 33.4,
  secondEvaluator: 33.3,
  thirdEvaluator: 33.3,
};

export const defaultCompositionConfig = {
  enablePotential: false,
  enableQualification: false,
  enableDesiredEvaluation: false,
};

export const defaultEvaluationTypeConfig = {
  enableSecondEvaluator: false,
  enableThirdEvaluator: false,
  enableWeights: false,
  enableWorkflowStageUpdate: false,
};

export const defaultEnabledWorkflowFields = {
  selfEvaluation: { hide: false, disabled: true },
  managerEvaluation: { hide: true, disabled: true },
  potential: { hide: true, disabled: true },
  secondEvaluator: { hide: true, disabled: true },
  thirdEvaluator: { hide: true, disabled: true },
  averageEvaluation: { hide: true, disabled: true },
  finalEvaluation: { hide: true, disabled: true },
};

export const defaultEnabledWorkflowActions = {
  enableManagerUpdate: false,
  enableSecEvaluatorUpdate: false,
  enableTrdEvaluatorUpdate: false,
  enableFinalEvaluation: false,
  nextStage: false,
  prevStage: false,
};

export const defaultManagerConfig = {
  selfEvaluationAccess: true,
  secondEvaluatorAccess: true,
  thirdEvaluatorAccess: true,
};

export const defaultEvaluationConfig = {
  keySeat: true,
  performance: true,
  succession: true,
};

export const defaultSubtypesOrder = [
  "KEY_SEAT_CRITICALITY",
  "KEY_SEAT_LOSING_RISK",
  "KEY_SEAT_LOSING_GRAVITY",
  "NONE",
];

export const defaultEvaluatedWorkflowStages = ["APPROVAL", "CONCLUDED"];

export const defaultParticipantStages = [
  "SELECTION_EVALUATORS",
  "VALIDATION",
  "SELF_EVALUATION",
];

export const defaultAnonymousUser = {
  email: "",
  firstName: "Anônimo",
  name: "Anônimo",
  fullName: "Anônimo",
  lastName: "",
  userData: {
    avatarBlodId: "",
  },
};

export const getDefaultEvaluationsAverage = (replaceValue) => {
  return {
    selfEvaluation: replaceValue,
    managerEvaluation: replaceValue,
    potential: replaceValue,
    secondEvaluator: replaceValue,
    thirdEvaluator: replaceValue,
    desiredEvaluation: replaceValue,
    averageEvaluation: replaceValue,
  };
};

export const getInitialEvaluationScale = () => {
  return [
    { id: null, value: 0, title: "Não Conhece" },
    { id: null, value: 1, title: "Baixo Conhecimento" },
    { id: null, value: 2, title: "Conhecimento Parcial" },
    { id: null, value: 3, title: "Alto Conhecimento" },
    { id: null, value: 4, title: "Experto Multiplicador" },
  ];
};

export const getInitialQualificationScale = () => {
  return [
    { id: null, value: 1, title: "Básico" },
    { id: null, value: 2, title: "Intermediário" },
    { id: null, value: 3, title: "Avançado" },
    { id: null, value: 4, title: "Expert" },
  ];
};

export const getInitialPotentialScale = () => {
  return [
    { id: null, value: 1, title: "Baixo" },
    { id: null, value: 2, title: "Parcial" },
    { id: null, value: 3, title: "Alto" },
  ];
};

export const getInitialDates = () => {
  const fullYear = new Date().getFullYear();
  const startDate = moment(new Date(fullYear, 0, 1)).format("YYYY-MM-DD");
  const endDate = moment(new Date(fullYear, 11, 31)).format("YYYY-MM-DD");

  return {
    startValidation: startDate,
    endValidation: endDate,
    startSelfEvaluation: startDate,
    endSelfEvaluation: endDate,
    startEvaluators: startDate,
    endEvaluators: endDate,
    startClosure: startDate,
    endClosure: endDate,
    startPdi: startDate,
    endPdi: endDate,
    startFeedback: startDate,
    endFeedback: endDate,
  };
};

export const getInitialKeySeatEvaluation = () => {
  return [
    {
      id: null,
      title: "Impacto importante no resultado do negócio",
      description:
        "Esta função tem um impacto importante no resultado do negócio.",
      type: "KEY_SEAT",
      subType: "KEY_SEAT_CRITICALITY",
    },
    {
      id: null,
      title: "Difícil encontrar esse perfil no mercado",
      description: "É dificil encontrar esse perifl no mercado? (Escassez)",
      type: "KEY_SEAT",
      subType: "KEY_SEAT_CRITICALITY",
    },
    {
      id: null,
      title: "Possui responsabilidade por processos complexos",
      description:
        "Está função possui responsabilidade por processos complexos?",
      type: "KEY_SEAT",
      subType: "KEY_SEAT_CRITICALITY",
    },
    {
      id: null,
      title: "Aquecimento do mercado de trabalho",
      description:
        "O mercado está em busca de profissionais com os conhecimentos que este colaborador possui.",
      type: "KEY_SEAT",
      subType: "KEY_SEAT_LOSING_RISK",
    },
    {
      id: null,
      title: "Falta de engajamento atual",
      description:
        "O colaborador não se identifica ou não se adaptou com a cultura da empresa. Demonstra frustração com regras e políticas.",
      type: "KEY_SEAT",
      subType: "KEY_SEAT_LOSING_RISK",
    },
    {
      id: null,
      title: "Falta de motivação e interesse",
      description:
        "Motivação pessoal: o colaborador expressou que possui outro projeto ou motivação pessoal.",
      type: "KEY_SEAT",
      subType: "KEY_SEAT_LOSING_RISK",
    },
    {
      id: null,
      title: "Tem responsabilidade por resultados na organização",
      description:
        "Atuação direta em atividades, serviços que trazem retorno direto para organização.",
      type: "KEY_SEAT",
      subType: "KEY_SEAT_LOSING_GRAVITY",
    },
    {
      id: null,
      title: "Possui atuação diferenciada é altamente qualificado",
      description:
        "Possui atuação diferenciada (entregas consistentes e diferenciadas), tem conhecimento especializado e se destaca facilmente no mercado.",
      type: "KEY_SEAT",
      subType: "KEY_SEAT_LOSING_GRAVITY",
    },
    {
      id: null,
      title: "Complexidade de dar continuidade nas atividades",
      description:
        "Nível de complexidade e conhecimento de executar as atividades desempenhadas.",
      type: "KEY_SEAT",
      subType: "KEY_SEAT_LOSING_GRAVITY",
    },
  ];
};

export const getInitialPerformanceEvaluation = () => {
  return [
    {
      id: null,
      title: "Nível de Qualificação de Desempenho e Performance",
      description: "Nivel geral de desepenho do colaborador na empresa.",
      type: "PERFORMANCE",
      subType: "NONE",
    },
    {
      id: null,
      title: "Nível de Atingimento das Metas Estabelecidas",
      description:
        "Planeja objetivos e ações para atender as metas estabelicidas.",
      type: "PERFORMANCE",
      subType: "NONE",
    },
    {
      id: null,
      title: "Iniciativas que trazem resultados",
      description: "Desenvolvimento de iniciativas que trazem resultados.",
      type: "PERFORMANCE",
      subType: "NONE",
    },
  ];
};

export const getInitialSuccessionEvaluation = () => {
  return [
    {
      id: null,
      title: "SUCCESSION_QUESTION_1",
      description: null,
      type: "SUCCESSION",
      subType: "NONE",
    },
    {
      id: null,
      title: "SUCCESSION_QUESTION_2",
      description: null,
      type: "SUCCESSION",
      subType: "NONE",
    },
    {
      id: null,
      title: "SUCCESSION_QUESTION_3",
      description: null,
      type: "SUCCESSION",
      subType: "NONE",
    },
    {
      id: null,
      title: "SUCCESSION_QUESTION_4",
      description: null,
      type: "SUCCESSION",
      subType: "NONE",
    },
  ];
};

export const getInitialComplementaryScales = (type = "KEY_SEAT") => {
  return [
    { id: null, title: "NÃO SE APLICA", value: 0, type },
    { id: null, title: "BAIXO", value: 1, type },
    { id: null, title: "MÉDIO", value: 2, type },
    { id: null, title: "ALTO", value: 3, type },
  ];
};

export const getDefaultSuccessionBoolOptions = () => {
  return [
    { value: true, label: "Sim" },
    { value: false, label: "Não" },
  ];
};

export const getDefaultReadinessOptions = () => {
  return [
    { value: "NO", label: "Não" },
    { value: "READY", label: "Pronto" },
    { value: "SHORT_TERM", label: "Curto Prazo" },
    { value: "MEDIUM_TERM", label: "Médio Prazo" },
    { value: "LONG_TERM", label: "Longo Prazo" },
  ];
};

export const workflowRequiredExceptions = [
  "SUCCESSION_QUESTION_2",
  "SUCCESSION_QUESTION_3",
  "SUCCESSION_QUESTION_4",
];
