/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import _ from "lodash";
import { getObjectInfoById } from "../../ArrayUtils";
import { buildFormSkillsAssessment } from "../entity";
import { removeDuplicatedRelations } from "../entityAutomation";
import { getParticipantsByInsightAccess } from "../filter";

const defaultAdministration = buildFormSkillsAssessment({});

export function useSimpleToolAdministration({
  selectedTool = {},
  allSkillAssessment = [],
  isLoading = false,
}) {
  const [toolAdministration, setAdministration] = useState(
    defaultAdministration,
  );

  useEffect(() => {
    if (allSkillAssessment) {
      const restConfig = removeDuplicatedRelations(
        getObjectInfoById(
          selectedTool.id,
          allSkillAssessment,
          "selectedToolId",
        ),
      );

      if (!isLoading && !_.isEqual(restConfig, toolAdministration)) {
        setAdministration(restConfig);
      }
    }
  }, [allSkillAssessment, isLoading, selectedTool.id]);

  return { toolAdministration };
}

export function useHistoryToolAdministration({
  selectedTool = {},
  allSkillAssessment = [],
  isLoading = false,
}) {
  const [toolAdministration, setAdministration] = useState(
    defaultAdministration,
  );
  const [toolHistory, setToolHistory] = useState(defaultAdministration);

  useEffect(() => {
    if (allSkillAssessment) {
      const restConfig = removeDuplicatedRelations(
        getObjectInfoById(
          selectedTool.id,
          allSkillAssessment,
          "selectedToolId",
        ),
      );
      const formConfig = buildFormSkillsAssessment(restConfig);

      if (formConfig && !_.isEqual(formConfig, toolHistory)) {
        setToolHistory(formConfig);

        if (!isLoading && !_.isEqual(formConfig, toolAdministration)) {
          setAdministration(formConfig);
        }
      }
    }
  }, [allSkillAssessment, isLoading, selectedTool.id]);

  return {
    toolAdministration,
    setAdministration,
    toolHistory,
    setToolHistory,
  };
}

export function useInsightParticipants({
  insights = [],
  participants: participantsToFilter = [],
  loggedUser = {},
}) {
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    const newParticipants = getParticipantsByInsightAccess(
      insights,
      participantsToFilter,
      loggedUser,
    );

    if (!_.isEqual(newParticipants, participants))
      setParticipants(newParticipants);
  }, [insights, participantsToFilter]);

  return { participants, setParticipants };
}
