import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const polarityOptions = [undefined, "", "POSITIVE", "NEGATIVE"];

class Polarity extends Component {
  handleChange() {
    const { onChangeValue, value = "" } = this.props;

    const newPolarity =
      value === "" ? "POSITIVE" : value === "POSITIVE" ? "NEGATIVE" : "";

    if (onChangeValue) onChangeValue(newPolarity);
  }

  render() {
    const { disabled, value = "", pointer } = this.props;

    return (
      <a
        className={classnames(
          { disabled: disabled === true },
          `${!pointer ? "" : "pointer"}`,
          "text-center",
          "link-font-12"
        )}
        style={{
          fontSize: this.props.cardPolarity ? "10px" : "14px",
          color:
            value === "NEGATIVE"
              ? "#8C2410"
              : value === "POSITIVE"
              ? "#7faf00"
              : "#888",
        }}
        onClick={e => this.handleChange(e)}
      >
        <span className="fa-stack fa-1x">
          <i className="far fa-circle fa-stack-2x" />
          <i
            className={classnames(
              "fas fa-2x",
              { "fa-angle-up": value === "POSITIVE" },
              { "fa-angle-down": value === "NEGATIVE" },
              { "fa-slash": value === undefined || value === "" }
            )}
          />
        </span>
      </a>
    );
  }
}

Polarity.propTypes = {
  value: PropTypes.oneOf(polarityOptions),
  onChangeValue: PropTypes.func,
  disabled: PropTypes.bool,
};

Polarity.defaultProps = {
  value: "",
  disabled: false,
};

export default Polarity;
