/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { defineMessages } from "react-intl";
import SelectParticipantArea from "../forms/SelectParticipantArea";
import SelectParticipantRole from "../forms/SelectParticipantRole";
import ParticipantBoardRelation from "../forms/ParticipantBoardRelation";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import MaterialTextField from "../../../../../../Common/MaterialTextField";
import DataFlowMenu from "../../../../../../Common/DataFlowMenu";
import ExchangeRolesTable from "../../../../../../SkillsAssessment/ExchangeRolesTable";
import SelectCompanyMember from "../../../../../../SelectCompanyMember";
import SimpleLoader from "../../../../../../SimpleLoader";
import SimpleSelectExcelCollumn from "../../../../../../Common/SimpleSelectExcelCollumn";
import DeleteColumn from "../../../../../../Common/Tables/DeleteColumn";
import SimplePagination from "../../../../../../SimplePagination";
import {
  updateParticipantsProfileByCSV,
  fetchSkillAssessmentConfig,
  putUpdateParticipantWithPersistence,
  updateParticipantsWithBoardRelation,
  updateParticipantsEvaluatorRoles,
} from "../../../../../../../actions/skillAssessmentActions";
import { updateClientLoading } from "../../../../../../../actions/loadingActions";
import { simpleNotifier } from "../../../../../../../actions/notifierActions";
import {
  getEmptyTableRow,
  getDefaultAddTableLine,
} from "../../../../../../../utils/tableUtils";
import {
  getObjectInfoById,
  orderArrayByObjAttr,
  getOnlySublevelArrays,
  getObjectChangedKeys,
} from "../../../../../../../utils/ArrayUtils";
import { translatedText } from "../../../../../../../utils/translationUtils";
import {
  buildProfileOptions,
  getExportParticipantsPack,
  getParticipantsExportFakeHeader,
  validateImportLines,
  filterParticipantsByNameOrEmail,
  getItemsByPage,
  getInsightsByParticipant,
} from "../../../../../../../utils/skillsAssessment/filter";
import { getEvaluationConfigByType } from "../../../../../../../utils/skillsAssessment/constants";
import { persistOrderedListByIds } from "../../../../../../../utils/skillsAssessment/entityAutomation";
import SimpleLockButton from "../../../../../../Common/SimpleLockButton";
import { simpleSaveAsExcel } from "../../../../../../Common/Export/ExportCSV";
import ImportController from "../../../../../../Company/Areas&Positions/utils/modal/ImportController";

const messages = defineMessages({
  global_noneRegistered: { id: "global.noneRegistered" },
  global_selectSome: { id: "global.selectSome" },
  global_export: { id: "global.export" },
  global_import: { id: "global.import" },
});

const translation = (id) => translatedText(id, messages);

const defaultImportedFile = { data: [] };
const defaultRelationTableConfig = {
  open: false,
  participants: [],
};
const defaultExchangeRolesTableConfig = {
  open: false,
};
const PARTICIPANTS_PER_PAGE = 15;

const TableEvaluatedPeople = ({
  toolAdministration = {},
  updateAdministrationSingleField,
  handleAdd,
  openDeleteParticipant,
  openArchiveParticipant,
  openUnarchiveParticipant,
  companyMembersAndParticipants: members,
  selectedTool,
  simpleNotifier,
  updateClientLoading,
  fetchSkillAssessmentConfig,
  putUpdateParticipantWithPersistence,
  allSceneries,
  allThemes,
  allQuestions,
  allAnswers,
  isLoading,
  setSaveLoading,
  updateParticipantsWithBoardRelation,
}) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState("");
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [selectModal, setSelectModal] = useState({
    type: null,
    onConfirm: () => {},
    onCancel: () => {},
  });
  const [callImportController, setCallImportController] = useState(false);
  const [importedFile, setImportedFile] = useState(defaultImportedFile);
  const [missingBoardRelation, setMissingBoardRelation] = useState(false);
  const [relationTableConfig, setRelationTableConfig] = useState(
    defaultRelationTableConfig,
  );
  const [exchangeRolesTableConfig, setExchangeRolesTableConfig] = useState(
    defaultExchangeRolesTableConfig,
  );
  const [showFiled, setShowFiled] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const {
    participants = [],
    profiles = [],
    evaluationType,
    managerConfig,
  } = toolAdministration;

  const onlyInsights = getOnlySublevelArrays(allAnswers);

  const { enableThirdEvaluator, enableSecondEvaluator } =
    getEvaluationConfigByType(evaluationType);

  function getValidationParticipants(participants) {
    const selectedPageParticipants = getItemsByPage(
      participants,
      page,
      PARTICIPANTS_PER_PAGE,
    );
    const orderedParticipants = orderArrayByObjAttr(
      selectedPageParticipants,
      "memberParticipant",
      "name",
    );
    return orderedParticipants.filter(
      (participant) =>
        participant.companyPositionId === null ||
        participant.companyAreaId === null ||
        participant.profileId === null ||
        participant.managerId === null ||
        (enableSecondEvaluator && participant.secondEvaluatorId === null) ||
        (enableThirdEvaluator && participant.thirdEvaluatorId === null),
    );
  }

  function handleFilter() {
    const filtered = filter
      ? filterParticipantsByNameOrEmail(filter, participants)
      : participants;

    const newFiltered = filtered
      .map((participant) => {
        const linkedInsights = getInsightsByParticipant(
          participant.id,
          allAnswers,
        );
        const isFiledInsight =
          linkedInsights?.filter(({ filed }) => filed === true).length > 0;

        return {
          ...participant,
          linkedInsights,
          isFiledInsight,
        };
      })
      .filter(({ isFiledInsight }) => {
        return showFiled || !isFiledInsight;
      });

    const orderedParticipants = orderArrayByObjAttr(
      newFiltered,
      "memberParticipant",
      "name",
    );

    setShowErrors(false);
    setTotalPages(
      newFiltered.length > 0
        ? Math.ceil(newFiltered.length / PARTICIPANTS_PER_PAGE)
        : 0,
    );
    const indexOfLastParticipant = page * PARTICIPANTS_PER_PAGE;
    const indexOfFirstParticipant =
      indexOfLastParticipant - PARTICIPANTS_PER_PAGE;

    const currentParticipant = orderedParticipants.slice(
      indexOfFirstParticipant,
      indexOfLastParticipant,
    );
    setFilteredParticipants(currentParticipant);
  }

  const handleErrorParticipant = () => {
    const filtered = filter
      ? filterParticipantsByNameOrEmail(filter, participants)
      : participants;

    const newFiltered = filtered
      .map((participant) => {
        const linkedInsights = getInsightsByParticipant(
          participant.id,
          allAnswers,
        );
        const isFiledInsight =
          linkedInsights?.filter(({ filed }) => filed === true).length > 0;

        return {
          ...participant,
          linkedInsights,
          isFiledInsight,
        };
      })
      .filter(({ isFiledInsight }) => {
        return showFiled || !isFiledInsight;
      });

    const validationParticipants = getValidationParticipants(newFiltered);

    setFilteredParticipants(validationParticipants);
    setTotalPages(
      newFiltered.length > 0
        ? Math.ceil(newFiltered.length / PARTICIPANTS_PER_PAGE)
        : 0,
    );
  };

  const handleFilterAndErrors = () => {
    setShowErrors(!showErrors);
    if (!showErrors) {
      handleErrorParticipant();
    } else {
      handleFilter();
    }
  };

  function handleInitialRelationConfig() {
    const participantsWithoutLink = participants.filter(
      (participant) =>
        onlyInsights.findIndex(
          (insight) => insight.skillsAssessmentParticipantId === participant.id,
        ) === -1,
    );

    if (participantsWithoutLink.length > 0) {
      setMissingBoardRelation(true);
      setRelationTableConfig((current) => {
        return {
          ...current,
          participants: participantsWithoutLink,
        };
      });
    } else {
      setMissingBoardRelation(false);
      setRelationTableConfig(defaultRelationTableConfig);
    }
  }

  useEffect(() => {
    handleInitialRelationConfig();
  }, [allSceneries, allAnswers, participants]);

  useEffect(() => {
    if (!showErrors) handleFilter();
    else handleErrorParticipant();
  }, [page, filter, participants, showFiled, showErrors, allAnswers]);

  const closeSelectModal = () => {
    setSelectModal({ type: null, onConfirm: () => {}, onCancel: () => {} });
  };

  const saveUpdatedParticipant = async (
    currentParticipant,
    updatedParticipant,
  ) => {
    if (updatedParticipant?.id) {
      updateClientLoading({
        loading: true,
        message: "Aguarde enquanto as atualizações são carregadas",
      });
      putUpdateParticipantWithPersistence({
        selectedToolId: selectedTool.id,
        currentParticipant,
        updatedParticipant,
      });
      fetchSkillAssessmentConfig(selectedTool.id);
      updateClientLoading({ loading: false });
      handleFilter();
    }
  };

  const handleUpdateParticipantProfile = (participantInfo, newProfileId) => {
    if (newProfileId) {
      const newProfile = getObjectInfoById(newProfileId, profiles, "id");
      const updatedParticipant = {
        ...participantInfo,
        profile: newProfile,
        profileId: newProfile.id,
      };
      saveUpdatedParticipant(participantInfo, updatedParticipant);
      closeSelectModal();
    }
  };

  const updateParticipantArea = (participantInfo, selectedArea) => {
    const updatedParticipant = {
      ...participantInfo,
      companyArea: selectedArea,
      companyAreaId: selectedArea?.id,
    };

    saveUpdatedParticipant(participantInfo, updatedParticipant);
    closeSelectModal();
  };

  const updateParticipantRole = (participantInfo, selectedRole) => {
    const updatedParticipant = {
      ...participantInfo,
      companyPosition: selectedRole,
      companyPositionId: selectedRole.id,
    };

    saveUpdatedParticipant(participantInfo, updatedParticipant);
    closeSelectModal();
  };

  const updateParticipantEvaluators = (
    participantInfo,
    selectedMember,
    editingField,
  ) => {
    const updatedParticipant = {
      ...participantInfo,
      [editingField]: selectedMember,
      [`${editingField}Id`]: selectedMember.id,
    };

    saveUpdatedParticipant(participantInfo, updatedParticipant);
    closeSelectModal();
  };

  const openSelectArea = (participantInfo) => {
    setSelectModal({
      type: "select_area",
      onConfirm: (selectedArea) =>
        updateParticipantArea(participantInfo, selectedArea),
      onCancel: closeSelectModal,
    });
  };

  const openSelectRole = (participantInfo) => {
    setSelectModal({
      type: "select_role",
      onConfirm: (selectedRole) =>
        updateParticipantRole(participantInfo, selectedRole),
      onCancel: closeSelectModal,
    });
  };

  const openSelectMember = (participantInfo, editingField) => {
    if (editingField) {
      setSelectModal({
        type: "select_member",
        onConfirm: (selectedMember) =>
          updateParticipantEvaluators(
            participantInfo,
            selectedMember[0],
            editingField,
          ),
        onCancel: closeSelectModal,
      });
    }
  };

  const toggleManagerConfig = (field) => {
    updateAdministrationSingleField("managerConfig", {
      ...managerConfig,
      [field]: !managerConfig[field],
    });
  };

  function handleMissingBoardRelation() {
    setRelationTableConfig((current) => {
      return {
        ...current,
        open: true,
      };
    });
  }

  function handleExchangeRoles() {
    setExchangeRolesTableConfig((current) => {
      return {
        ...current,
        open: true,
      };
    });
  }

  async function handleSaveBoardRelation(selectedRelations) {
    setSaveLoading(true);
    updateClientLoading({
      loading: true,
      message: "Aguarde enquanto as atualizações são carregadas",
    });
    setRelationTableConfig(defaultRelationTableConfig);

    const updatedRelations = selectedRelations.map(
      ({ participantId, cenaryId }) => {
        const participant = participants.find(
          (participant) => participant.id === participantId,
        );

        const updatedParticipant = {
          ...participant,
        };

        const selectedTheme = allThemes[cenaryId]?.find(
          (theme) => theme.companyAreaId === updatedParticipant.companyAreaId,
        );

        const selectedQuestion = allQuestions[selectedTheme?.id]?.find(
          (question) =>
            question.skillsAssessmentProfileId === updatedParticipant.profileId,
        );

        return {
          participant: updatedParticipant,
          cenary: allSceneries.find((c) => c.id === cenaryId),
          cenaryId,
          theme: selectedTheme || null,
          question: selectedQuestion || null,
        };
      },
    );

    await updateParticipantsWithBoardRelation({
      selected_tool_id: selectedTool.id,
      updatedRelations,
      toolAdministration,
    });

    setSaveLoading(false);
    updateClientLoading({ loading: false });
    handleInitialRelationConfig();
  }

  async function handleSaveExchangeRoles(
    administrationWithChanges,
    clearChanges,
  ) {
    setSaveLoading(true);
    updateClientLoading({
      loading: true,
      message: "Aguarde enquanto as atualizações são carregadas",
    });

    try {
      const changedKeys = getObjectChangedKeys(
        persistOrderedListByIds(administrationWithChanges),
        persistOrderedListByIds(toolAdministration),
      );
      const { data: response } = await updateParticipantsEvaluatorRoles({
        selected_tool_id: selectedTool.id,
        changedKeys,
      });

      if (response.updated) {
        clearChanges();
        fetchSkillAssessmentConfig(selectedTool.id);
      }
    } catch (err) {
      console.error(err);
    }

    setSaveLoading(false);
    updateClientLoading({ loading: false });
  }

  function handleExport() {
    const { dataToExport, headers, fakeHeader, filename } =
      getExportParticipantsPack(participants, {
        enableThirdEvaluator,
        enableSecondEvaluator,
      });

    simpleSaveAsExcel(dataToExport, headers, fakeHeader, filename, [
      { line: 2, fill: "eeeeee" },
    ]);
  }

  async function handleMassUpdate() {
    const { data: updateResponse } = await updateParticipantsProfileByCSV({
      selected_tool_id: selectedTool.id,
      imported_table: importedFile.data,
    });

    if (updateResponse?.updated) {
      simpleNotifier("A Estrutura de Áreas e Cargos foi atualizada", "success");
    } else {
      simpleNotifier("Ocorreu um erro ao tentar importar os dados", "error");
    }

    fetchSkillAssessmentConfig(selectedTool.id);
  }

  const handleFileChange = (file = {}, selectedFile = {}) => {
    const { data: fileData = [] } = selectedFile || {};
    const { data: sheetData } = fileData.sheet0;

    setImportedFile({ data: sheetData, file });
    setCallImportController(true);
  };

  function changeFilter(str) {
    setFilter(str);
    if (page !== 1) setPage(1);
  }

  function getPagination(menuPlacement) {
    return (
      <SimplePagination
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        menuPlacement={menuPlacement}
      />
    );
  }

  const profileOptions = buildProfileOptions(profiles);

  return (
    <div
      style={
        isLoading
          ? {
              opacity: "0.5",
              cursor: "loading",
              position: "relative",
              zIndex: "-1",
            }
          : {}
      }
    >
      <SelectParticipantArea
        open={selectModal.type === "select_area"}
        onConfirm={selectModal.onConfirm}
        onCancel={selectModal.onCancel}
      />
      <SelectParticipantRole
        open={selectModal.type === "select_role"}
        onConfirm={selectModal.onConfirm}
        onCancel={selectModal.onCancel}
      />
      <SelectCompanyMember
        open={selectModal.type === "select_member"}
        title={selectModal.title}
        onConfirm={selectModal.onConfirm}
        onCancel={selectModal.onCancel}
        selected={selectModal.selected}
        singleSelect
      />
      <ImportController
        title="Importando Perfil e Gestores"
        callImportController={callImportController}
        setCallImportController={setCallImportController}
        fakeHeader={getParticipantsExportFakeHeader()}
        fileInfo={{ fileData: importedFile }}
        handleValidation={() =>
          validateImportLines({
            toolAdministration,
            importedTable: importedFile.data,
            members,
          })
        }
        handleMassUpdate={handleMassUpdate}
        resetFileInfo={() => setImportedFile(defaultImportedFile)}
      />
      <ParticipantBoardRelation
        {...relationTableConfig}
        onConfirm={handleSaveBoardRelation}
        onCancel={() =>
          setRelationTableConfig({ ...relationTableConfig, open: false })
        }
        allSceneries={allSceneries}
        openSelectArea={openSelectArea}
        openSelectRole={openSelectRole}
        profileOptions={profileOptions}
        handleUpdateParticipantProfile={handleUpdateParticipantProfile}
      />
      {exchangeRolesTableConfig.open && (
        <ExchangeRolesTable
          {...exchangeRolesTableConfig}
          handleSaveExchangeRoles={handleSaveExchangeRoles}
          onCancel={() =>
            setExchangeRolesTableConfig({
              ...exchangeRolesTableConfig,
              open: false,
            })
          }
          openSelectMember={openSelectMember}
          toolAdministration={toolAdministration}
          members={members}
          enableSecondEvaluator={enableSecondEvaluator}
          enableThirdEvaluator={enableThirdEvaluator}
        />
      )}
      <div className="row">
        <div
          className="col-xs-12"
          style={{
            padding: "0px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="col-xs-6" style={{ padding: "0px" }}>
            <MaterialTextField
              label="Pesquisar Participante"
              value={filter}
              onChange={(e) => changeFilter(e.target.value)}
            />
          </div>
          <a
            onClick={() => changeFilter("")}
            style={{ cursor: "pointer", display: "block", padding: "5px" }}
          >
            Limpar <i className="fas fa-times" />
          </a>
        </div>
      </div>
      {getPagination()}
      <table className="simple-table">
        <thead>
          {getDefaultAddTableLine(
            handleAdd,
            {},
            "CADASTROS DOS PARTICIPANTES",
            <div style={{ display: "flex" }}>
              <DataFlowMenu
                handleExport={handleExport}
                handleFileChange={handleFileChange}
              />
              <Button
                disabled={!missingBoardRelation}
                title={
                  !missingBoardRelation
                    ? "Todos os participantes já foram adicionados ao quadro"
                    : ""
                }
                onClick={handleMissingBoardRelation}
              >
                <i className="fas fa-sitemap icon" />
                {" Quadro"}
              </Button>
              <Button onClick={handleExchangeRoles}>
                <i className="fas fa-arrows-alt-h icon" />
                {" Movimentações"}
              </Button>

              <Button
                disabled={!missingBoardRelation}
                title={
                  !missingBoardRelation
                    ? "Todos os participantes já foram adicionados ao quadro"
                    : ""
                }
                onClick={() => setShowFiled(!showFiled)}
              >
                {!showFiled ? (
                  <i className="fas fa-archive icon" />
                ) : (
                  <i className="fas fa-eye-slash icon" />
                )}{" "}
                Arquivados
              </Button>
              <Button
                disabled={!missingBoardRelation}
                onClick={() => handleFilterAndErrors()}
              >
                {showErrors ? (
                  <>
                    <i className="fas fa-list icon" /> Mostrar todos
                  </>
                ) : (
                  <>
                    <i className="fas fa-exclamation-triangle icon" /> Mostrar
                    erros
                  </>
                )}
              </Button>
            </div>,
          )}
        </thead>
        <tbody>
          <tr className="block-white-space border-bottom-division">
            <td>Participante Avaliado</td>
            <td>Área</td>
            <td>Cargo</td>
            <td>Perfil</td>
            <td>
              Gestor Imediado{" "}
              <SimpleLockButton
                locked={!managerConfig.selfEvaluationAccess}
                onToggle={() => toggleManagerConfig("selfEvaluationAccess")}
                titles={{
                  on: "Somente administradores podem atualizar",
                  off: "O participante também pode atualizar",
                }}
              />
            </td>
            {enableSecondEvaluator && (
              <td>
                Cliente{" "}
                <SimpleLockButton
                  locked={!managerConfig.secondEvaluatorAccess}
                  onToggle={() => toggleManagerConfig("secondEvaluatorAccess")}
                  titles={{
                    on: "Somente administradores podem atualizar",
                    off: "O participante também pode atualizar",
                  }}
                />
              </td>
            )}
            {enableThirdEvaluator && (
              <td>
                Fornecedor{" "}
                <SimpleLockButton
                  locked={!managerConfig.thirdEvaluatorAccess}
                  onToggle={() => toggleManagerConfig("thirdEvaluatorAccess")}
                  titles={{
                    on: "Somente administradores podem atualizar",
                    off: "O participante também pode atualizar",
                  }}
                />
              </td>
            )}
            <td />
            <td />
          </tr>
          {isLoading && (
            <tr>
              <td colSpan="100">
                <center>
                  <SimpleLoader color="#6b42a9" />
                </center>
              </td>
            </tr>
          )}
          {filteredParticipants?.map((participantInfo, index) => {
            const {
              companyArea = {},
              companyPosition = {},
              memberParticipant = {},
              profile = {},
              manager = {},
              secondEvaluator = {},
              thirdEvaluator = {},
              isFiledInsight,
            } = participantInfo;
            const haveCompanyArea = companyArea && companyArea.id;
            const haveCompanyRole = companyPosition && companyPosition.id;
            const haveProfile = profile && profile.id;
            const haveManager = manager && manager.id;
            const haveSecondEvaluator = secondEvaluator && secondEvaluator.id;
            const haveThirdEvaluator = thirdEvaluator && thirdEvaluator.id;

            return (
              <tr
                key={participantInfo.id}
                style={{ opacity: isFiledInsight ? 0.8 : 1 }}
              >
                <SimpleExcelCollumn
                  id={`participantName${index}`}
                  label={memberParticipant.name}
                  type="text"
                  value={memberParticipant.name}
                  title={memberParticipant.name}
                  inputStyle={{ fontSize: "10px" }}
                  icon={
                    isFiledInsight ? (
                      <i
                        className="fas fa-archive icon"
                        style={{ paddingLeft: "10px", color: "#888" }}
                      />
                    ) : (
                      ""
                    )
                  }
                />
                <SimpleExcelCollumn
                  id={`participantArea${index}`}
                  label="Área"
                  type="text"
                  value={
                    haveCompanyArea
                      ? companyArea.title
                      : translation("global_selectSome")
                  }
                  title={haveCompanyArea && companyArea.title}
                  onClick={() => openSelectArea(participantInfo)}
                  inputStyle={{
                    border: haveCompanyArea ? "none" : "1px solid red",
                  }}
                />
                <SimpleExcelCollumn
                  id={`participantRole${index}`}
                  label="Cargo"
                  type="text"
                  value={
                    haveCompanyRole
                      ? companyPosition.title
                      : translation("global_selectSome")
                  }
                  title={haveCompanyRole && companyPosition.title}
                  onClick={() => openSelectRole(participantInfo)}
                  inputStyle={{
                    border: haveCompanyRole ? "none" : "1px solid red",
                  }}
                />
                {profileOptions.length > 0 && (
                  <SimpleSelectExcelCollumn
                    id={`participantProfile${index}`}
                    label="Perfil"
                    value={haveProfile ? profile.id : ""}
                    options={profileOptions}
                    onChange={(e) =>
                      handleUpdateParticipantProfile(
                        participantInfo,
                        e.target.value,
                      )
                    }
                    customStyles={{
                      backgroundColor: "#ddd",
                      fontSize: "10px",
                      border: haveProfile ? "none" : "1px solid red",
                    }}
                    defaultOption={false}
                  />
                )}
                {profileOptions.length === 0 && <td>Nenhum cadastrado</td>}
                <SimpleExcelCollumn
                  id={`managerName${index}`}
                  label="Manager"
                  type="text"
                  value={
                    haveManager
                      ? manager.name
                      : translation("global_selectSome")
                  }
                  title={haveManager && manager.name}
                  onClick={() => openSelectMember(participantInfo, "manager")}
                  inputStyle={{
                    border: haveManager ? "none" : "1px solid red",
                  }}
                />
                {enableSecondEvaluator && (
                  <SimpleExcelCollumn
                    id={`secondEvaluatorName${index}`}
                    label="Cliente"
                    type="text"
                    value={
                      haveSecondEvaluator
                        ? secondEvaluator.name
                        : translation("global_selectSome")
                    }
                    title={haveSecondEvaluator && secondEvaluator.name}
                    onClick={() =>
                      openSelectMember(participantInfo, "secondEvaluator")
                    }
                    inputStyle={{
                      border: haveSecondEvaluator ? "none" : "1px solid red",
                    }}
                  />
                )}
                {enableThirdEvaluator && (
                  <SimpleExcelCollumn
                    id={`thirdEvaluatorName${index}`}
                    label="Fornecedor"
                    type="text"
                    value={
                      haveThirdEvaluator
                        ? thirdEvaluator.name
                        : translation("global_selectSome")
                    }
                    title={haveThirdEvaluator && thirdEvaluator.name}
                    onClick={() =>
                      openSelectMember(participantInfo, "thirdEvaluator")
                    }
                    inputStyle={{
                      border: haveThirdEvaluator ? "none" : "1px solid red",
                    }}
                  />
                )}
                {!isFiledInsight ? (
                  <td />
                ) : (
                  <DeleteColumn
                    handleDelete={() =>
                      openDeleteParticipant(participantInfo.id)
                    }
                  />
                )}
                <DeleteColumn
                  icon="fas fa-archive"
                  handleDelete={() =>
                    isFiledInsight
                      ? openUnarchiveParticipant(participantInfo.id)
                      : openArchiveParticipant(participantInfo.id)
                  }
                  color="#888"
                />
              </tr>
            );
          })}
          {getEmptyTableRow(participants, translation("global_noneRegistered"))}
        </tbody>
      </table>
      {getPagination("top")}
    </div>
  );
};

TableEvaluatedPeople.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
  updateAdministrationSingleField: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  openDeleteParticipant: PropTypes.func.isRequired,
  openArchiveParticipant: PropTypes.func.isRequired,
  openUnarchiveParticipant: PropTypes.func.isRequired,
};

export default connect(null, {
  simpleNotifier,
  updateClientLoading,
  fetchSkillAssessmentConfig,
  putUpdateParticipantWithPersistence,
  updateParticipantsWithBoardRelation,
})(TableEvaluatedPeople);
