import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import { getErrorMessage } from "../../../../../../../utils/formValidation";

const messages = defineMessages({
  gb_table_text_dates_period: { id: "gb_table_text_dates_period" },
  gb_table_text_date: { id: "gb_table_text_date" },
  gb_table_text_year: { id: "gb_table_text_year" },
  gb_table_text_start_registrations: {
    id: "gb_table_text_start_registrations",
  },
  gb_table_text_end_registrations: { id: "gb_table_text_end_registrations" },
  gb_table_text_start_approval: { id: "gb_table_text_start_approval" },
  gb_table_text_end_approval: { id: "gb_table_text_end_approval" },
  gb_table_text_start_audit: { id: "gb_table_text_start_audit" },
  gb_table_text_end_audit: { id: "gb_table_text_end_audit" },
});

const ProgramDatesTable = (props) => {
  const {
    goalBonusAdministration,
    setGoalBonusAdministration,
    administrationErrors = [],
    intl,
  } = props;

  const { dates } = goalBonusAdministration;

  const handleUpdateDates = (newDate, fieldSlug = "startRegistrations") => {
    setGoalBonusAdministration({
      ...goalBonusAdministration,
      dates: {
        ...dates,
        [fieldSlug]: newDate,
      },
    });
  };

  return (
    <div>
      <table className="simple-table">
        <thead>
          <tr>
            <td>{intl.formatMessage(messages.gb_table_text_dates_period)}</td>
            <td>{intl.formatMessage(messages.gb_table_text_date)}</td>
          </tr>
        </thead>
        <tbody>
          <tr className="block-hover">
            <td>{intl.formatMessage(messages.gb_table_text_year)}</td>
            <SimpleExcelCollumn
              id="year"
              label={intl.formatMessage(messages.gb_table_text_year)}
              type="number"
              value={dates.year}
              onChange={(e) => handleUpdateDates(e.target.value, "year")}
            />
          </tr>
          <tr className="block-hover">
            <td>
              {intl.formatMessage(messages.gb_table_text_start_registrations)}
            </td>
            <SimpleExcelCollumn
              id="startRegistrations"
              label={intl.formatMessage(messages.gb_table_text_date)}
              type="date"
              value={dates.startRegistrations}
              onChange={(e) =>
                handleUpdateDates(e.target.value, "startRegistrations")
              }
            />
          </tr>
          <tr className="block-hover">
            <td>
              {intl.formatMessage(messages.gb_table_text_end_registrations)}
            </td>
            <SimpleExcelCollumn
              id="endRegistrations"
              label={intl.formatMessage(messages.gb_table_text_date)}
              type="date"
              value={dates.endRegistrations}
              onChange={(e) =>
                handleUpdateDates(e.target.value, "endRegistrations")
              }
            />
          </tr>
          <tr className="block-hover">
            <td>{intl.formatMessage(messages.gb_table_text_start_approval)}</td>
            <SimpleExcelCollumn
              id="startApproval"
              label={intl.formatMessage(messages.gb_table_text_date)}
              type="date"
              value={dates.startApproval}
              onChange={(e) =>
                handleUpdateDates(e.target.value, "startApproval")
              }
            />
          </tr>
          <tr className="block-hover">
            <td>{intl.formatMessage(messages.gb_table_text_end_approval)}</td>
            <SimpleExcelCollumn
              id="endApproval"
              label={intl.formatMessage(messages.gb_table_text_date)}
              type="date"
              value={dates.endApproval}
              onChange={(e) => handleUpdateDates(e.target.value, "endApproval")}
            />
          </tr>
          <tr className="block-hover">
            <td>{intl.formatMessage(messages.gb_table_text_start_audit)}</td>
            <SimpleExcelCollumn
              id="startAudit"
              label={intl.formatMessage(messages.gb_table_text_date)}
              type="date"
              value={dates.startAudit}
              onChange={(e) => handleUpdateDates(e.target.value, "startAudit")}
            />
          </tr>
          <tr className="block-hover">
            <td>{intl.formatMessage(messages.gb_table_text_end_audit)}</td>
            <SimpleExcelCollumn
              id="endAudit"
              label={intl.formatMessage(messages.gb_table_text_date)}
              type="date"
              value={dates.endAudit}
              onChange={(e) => handleUpdateDates(e.target.value, "endAudit")}
            />
          </tr>
        </tbody>
      </table>
      <div className="col-xs-12">
        {getErrorMessage(
          [
            "invalidYear",
            "invalidStartRegistrations",
            "invalidEndRegistrations",
            "dateConflictRegistrations",
            "invalidStartApproval",
            "invalidEndApproval",
            "dateConflictApproval",
            "invalidStartAudit",
            "invalidEndAudit",
            "dateConflictAudit",
          ],
          administrationErrors,
        )}
      </div>
    </div>
  );
};

export default injectIntl(ProgramDatesTable);
