import React, { useEffect } from "react";

import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import {
  getAnswerOkrTotal,
  getKeyResult,
} from "../../../../../../utils/okrUtils";
import ButtonGeneratePdf from "../../../../../Common/ButtonGeneratePdf";
import CycleVision from "./CycleVision";
import { ScenaryView } from "./ScenaryView";

import KeyResults from "../../../CustomTablesOkr/KeyResults";
import Objectives from "../../../CustomTablesOkr/Objectives";

import { getSelectedToolStates } from "../../../../../customMapStates";

import { fetchAllInsightsGoals } from "../../../../../../actions/insightActions";
import {
  filteredSceneriesByScenaryIds,
  getFilteredThemesByFilters,
  getFilteredQuestionsByFilters,
} from "../../../../../../utils/toolFilters";

import { Wrapper } from "./styles";

const OverallPerformance = (props) => {
  const {
    allAnswers,
    allQuestions,
    allSceneries,
    allThemes,
    filterData,
    intl,
    fetchAllInsightsGoals,
    selectedToolID,
    selectAndOpenInsight,
    showHiddenExpectation,
  } = props;

  useEffect(() => {
    fetchAllInsightsGoals(selectedToolID);
  }, [selectedToolID, fetchAllInsightsGoals]);

  const filterDataScenaryIds = filterData.sceneries ?? [];
  const filterDataThemeIds = filterData.themes ?? [];
  const filterDataQuestionIds = filterData.questions ?? [];

  const filteredScenaries = filteredSceneriesByScenaryIds(
    allSceneries,
    filterDataScenaryIds,
  );
  const filteredThemes = getFilteredThemesByFilters(
    allThemes,
    filterDataScenaryIds,
    filterDataThemeIds,
  );
  const filteredQuestions = getFilteredQuestionsByFilters(
    allQuestions,
    filterDataThemeIds,
    filterDataQuestionIds,
  );

  function transformObjectBoardInArray(objectBoard) {
    return Object.keys(objectBoard).reduce(
      (accum, current) => accum.concat(objectBoard[current]),
      [],
    );
  }

  function getFilteredAnswersByQuestionId(answers, questionId) {
    const answersFound = answers[questionId];

    if (answersFound) return answersFound.map((answer) => answer);

    return [];
  }

  function getAnswersByEntity(answers, entity, callback) {
    return entity.reduce(
      (filteredAnswers, entityItem) =>
        filteredAnswers.concat(callback(answers, entityItem.id)),
      [],
    );
  }

  function getAllAnswers() {
    const transformedObjectQuestionsInArray = transformObjectBoardInArray(
      filteredQuestions,
    );

    return getAnswersByEntity(
      allAnswers,
      transformedObjectQuestionsInArray,
      getFilteredAnswersByQuestionId,
    );
  }

  const getAnswersByQuestionId = () => {
    let questionAnswers = [];

    const newStatus = !showHiddenExpectation ? "status" : "expectationStatus";

    function findQuestionEqual(questionId) {
      return questionAnswers.some((answer) => answer.questionId === questionId);
    }

    function pushEvaluation(goalInfo) {
      questionAnswers.push(goalInfo);
    }

    function includeInsightInQuestion(questionId, answer) {
      questionAnswers = questionAnswers.map((questionAnswer) => {
        if (
          filterDataQuestionIds.includes(questionId) &&
          questionAnswer.questionId === questionId
        ) {
          return {
            ...questionAnswer,
            answers: [...questionAnswer.answers, answer],
          };
        }
        return { ...questionAnswer };
      });
    }

    function mountEvaluationByStatus(answer) {
      if (
        findQuestionEqual(answer.question.id) &&
        filterDataQuestionIds.includes(answer.question.id) &&
        answer.goal[newStatus] !== "NAO_INICIADA"
      ) {
        includeInsightInQuestion(answer.question.id, answer);
      } else if (
        answer.goal[newStatus] !== "NAO_INICIADA" &&
        filterDataQuestionIds.includes(answer.question.id)
      ) {
        pushEvaluation({
          questionId: answer.question.id,
          questionText: answer.question.text,
          answers: [answer],
        });
      }
    }

    Object.keys(allAnswers).forEach((questionId) => {
      allAnswers[questionId].forEach((answer) => {
        if (answer.goal) {
          mountEvaluationByStatus(answer);
        }
      });
    });

    return questionAnswers;
  };

  function getAnswersEvaluationGroupedByQuestions() {
    const transformedObjectQuestionsInArray = transformObjectBoardInArray(
      filteredQuestions,
    );

    const questions = transformedObjectQuestionsInArray.reduce(
      (evaluations, questionCurrent) => {
        const findAnswer = allAnswers[questionCurrent.id];

        const answerEvaluation = {
          question: questionCurrent,
          evaluation: getAnswerOkrTotal(findAnswer),
          keyResults: findAnswer,
        };

        if (findAnswer) return evaluations.concat(answerEvaluation);

        return evaluations;
      },
      [],
    );

    return questions;
  }

  function getAnswersByThemeId(themeId) {
    const questionsFoud = filteredQuestions[themeId];

    if (questionsFoud) {
      return getAnswersByEntity(
        allAnswers,
        questionsFoud,
        getFilteredAnswersByQuestionId,
      );
    }

    return [];
  }

  function getAllInsights(scenerieId) {
    const themeBySceneryId = filteredThemes[scenerieId];

    if (themeBySceneryId) {
      return themeBySceneryId.reduce((answers, theme) => {
        return answers.concat(getAnswersByThemeId(theme.id));
      }, []);
    }

    return [];
  }

  function getAllSceneriesInsights() {
    const insightsGroupedByScenerie = filteredScenaries.map((scenerie) => {
      return {
        insights: getAllInsights(scenerie.id),
        scenerie,
      };
    });

    return insightsGroupedByScenerie;
  }

  function getAllThemesInsights() {
    const transformedObjectThemesInArray = transformObjectBoardInArray(
      filteredThemes,
    );

    const themeInsights = transformedObjectThemesInArray.reduce(
      (themeInsights, theme) => {
        if (getAnswersByThemeId(theme.id).length) {
          return themeInsights.concat({
            insights: getAnswersByThemeId(theme.id),
            theme,
          });
        }

        return themeInsights;
      },
      [],
    );

    return themeInsights;
  }

  const okrValue = !showHiddenExpectation ? "reached" : "expectationValue";
  const status = !showHiddenExpectation ? "status" : "expectationStatus";

  const options = {
    okrValue,
    status,
  };

  const objectivesQuestions = getAnswersEvaluationGroupedByQuestions();
  const plansKeyResults = getAllSceneriesInsights();
  const cycleKeyResults = getAllThemesInsights();
  const keyResultsAnswers = getAllAnswers();

  return (
    <Wrapper>
      <ButtonGeneratePdf />
      <CycleVision
        plansKeyResults={cycleKeyResults}
        options={options}
        intl={intl}
      />
      <ScenaryView
        plansKeyResults={plansKeyResults}
        options={options}
        intl={intl}
      />
      <Objectives
        objectives={objectivesQuestions}
        getKeyResult={getKeyResult}
        options={options}
        intl={intl}
      />
      <KeyResults
        keyResults={keyResultsAnswers}
        options={options}
        getKeyResult={getKeyResult}
        selectAndOpenInsight={selectAndOpenInsight}
        intl={intl}
        questionAnswers={getAnswersByQuestionId()}
      />
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  const { toolsConfigurations } = state;

  const {
    allQuestions,
    allAnswers,
    allSceneries,
    allThemes,
    selectedToolID,
  } = getSelectedToolStates(state);

  return {
    allAnswers,
    allQuestions,
    allSceneries,
    allThemes,
    selectedToolID,
    showHiddenExpectation: toolsConfigurations.showHiddenExpectation,
  };
};

const mapDispatchToProps = {
  fetchAllInsightsGoals,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(OverallPerformance));
