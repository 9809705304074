import axios from "axios";
import { buildRestPositionEntity } from "../utils/companyPositions/entity";
import { fetchUserCompanyProfile } from "./userActions";

export const toggleLoading = () => (dispatch, isLoading = true) =>
  dispatch({
    type: "POSITIONS_STRUCTURE_LOADING",
    payload: { isLoading },
  });

export const getCheckPositionExists = (companyPositionId) => {
  return axios
    .get(`/api/company-areas/${companyPositionId}/exists`)
    .then((res) => {
      return { data: true };
    });
};

export const getCompanyNestedPositions = (companyId) => {
  return axios
    .get(`/api/companies/${companyId}/positionsWithChildPositions`)
    .then((res) => {
      return res;
    });
};

export const getPositionChildPositions = (companyPositionId) => {
  return axios
    .get(`/api/company-positions/${companyPositionId}/child-positions`)
    .then((res) => {
      return res;
    });
};

export const postCompanyPositionStructure = (
  companyId,
  newPositionStructure,
) => {
  return axios
    .post(`/api/companies/${companyId}/position`, newPositionStructure)
    .then((res) => {
      return res;
    });
};

export const postCompanyChildPosition = (
  companyPositionId,
  newChildPosition,
) => {
  return axios
    .post(
      `/api/company-positions/${companyPositionId}/child-position`,
      newChildPosition,
    )
    .then((res) => {
      return res;
    });
};

export const putCompanyPositionStructure = (newPositionStructure) => {
  return axios
    .put("/api/company-positions", newPositionStructure)
    .then((res) => {
      return res;
    });
};

export const fetchCompanyNestedPositions = (companyId) => {
  return async (dispatch) => {
    if (companyId) {
      dispatch(toggleLoading(true));

      const companyNestedPositions = await getCompanyNestedPositions(companyId);
      const companyNestedPositionsData = companyNestedPositions.data;

      if (companyNestedPositionsData) {
        dispatch({
          type: "FETCHED_COMPANY_POSITIONS",
          payload: { structure: companyNestedPositionsData, companyId },
        });

        return companyNestedPositionsData;
      }

      dispatch(toggleLoading(false));
    }
  };
};

export const saveUpdateCompanyPositionStructure = (
  companyId,
  newPositionStructure = {},
) => {
  return async (dispatch) => {
    dispatch(toggleLoading(true));

    const restSavePositionStructure = buildRestPositionEntity(
      newPositionStructure,
    );

    if (companyId && restSavePositionStructure.id) {
      const requestPositionStructureExists = await getCheckPositionExists(
        restSavePositionStructure.id,
      );

      if (requestPositionStructureExists.data) {
        const updatedPositionStructure = await putCompanyPositionStructure(
          restSavePositionStructure,
        );

        if (updatedPositionStructure.data)
          dispatch({
            type: "UPDATE_COMPANY_POSITIONS_STRUCTURE",
            payload: { structure: updatedPositionStructure.data, companyId },
          });
      }
    } else if (companyId) {
      const savedPositionStructure = await postCompanyPositionStructure(
        companyId,
        restSavePositionStructure,
      );

      if (savedPositionStructure && savedPositionStructure.data)
        dispatch({
          type: "UPDATE_COMPANY_POSITIONS_STRUCTURE",
          payload: { structure: savedPositionStructure.data, companyId },
        });
    }
  };
};

export const saveUpdateChildPositions = (
  companyId,
  companyPositionId,
  newChildPosition = {},
) => {
  return async (dispatch) => {
    dispatch(toggleLoading(true));

    const restSaveChildPosition = buildRestPositionEntity(newChildPosition);

    if (companyId && companyPositionId && restSaveChildPosition.id) {
      const requestChildPositionExists = await getCheckPositionExists(
        restSaveChildPosition.id,
      );

      if (requestChildPositionExists.data === true) {
        const updatedChildPosition = await putCompanyPositionStructure(
          restSaveChildPosition,
        );
        if (updatedChildPosition.data)
          dispatch(fetchCompanyNestedPositions(companyId));
      }
    } else if (companyId && companyPositionId) {
      const savedChildPosition = await postCompanyChildPosition(
        companyPositionId,
        restSaveChildPosition,
      );

      if (savedChildPosition && savedChildPosition.data) {
        dispatch(fetchCompanyNestedPositions(companyId));
      }
    }
  };
};

export const postLinkUserToCompanyPosition = (
  companyId,
  positionId,
  jhiUserId,
) => {
  return axios
    .post(
      `/api/companies/${companyId}/user/${jhiUserId}/position/${positionId}`,
    )
    .then((res) => {
      return res;
    });
};

export const addUserToCompanyPosition = (companyId, positionId, jhiUserId) => {
  return async (dispatch) => {
    if (companyId && positionId && jhiUserId) {
      const linkedUserToPosition = await postLinkUserToCompanyPosition(
        companyId,
        positionId,
        jhiUserId,
      );

      if (linkedUserToPosition.data && linkedUserToPosition.data.id) {
        dispatch(fetchUserCompanyProfile(jhiUserId, companyId));
      }
    }
  };
};

export const removeLinkUserOfCompanyPosition = (
  companyId,
  positionId,
  jhiUserId,
) => {
  return axios
    .delete(
      `/api/companies/${companyId}/user/${jhiUserId}/position/${positionId}`,
    )
    .then((res) => {
      return res;
    });
};

export const removeUserOfCompanyPosition = (
  companyId,
  positionId,
  jhiUserId,
) => {
  return async (dispatch) => {
    if (companyId && positionId && jhiUserId) {
      const removedPositionLink = await removeLinkUserOfCompanyPosition(
        companyId,
        positionId,
        jhiUserId,
      );

      if (removedPositionLink.data) {
        dispatch(fetchUserCompanyProfile(jhiUserId, companyId));
      }
    }
  };
};

export const deleteCompanyPosition = (companyPositionId) => {
  return axios
    .delete(`/api/company-positions/${companyPositionId}`)
    .then((res) => res);
};

export const fetchDeleteCompanyPosition = (companyId, companyPositionId) => {
  return async (dispatch) => {
    if (companyId && companyPositionId) {
      const deletedArea = await deleteCompanyPosition(companyPositionId);
      if (
        deletedArea &&
        (deletedArea.status === 200 || deletedArea.status === 204)
      )
        dispatch(fetchCompanyNestedPositions(companyId));
    }
  };
};

export const positionsMassUpdate = (data = [], companyId = null) => {
  if (!companyId) return;
  return;

  return async (dispatch) => {
    return axios
      .post(`/api/company-positions/${companyId}/updatePositions`, data)
      .then((res) => {
        const { data: structure = [] } = res;

        dispatch({
          type: "COMPANY_POSITIONS_MASS_UPDATE",
          payload: { structure, companyId },
        });
        dispatch(fetchCompanyNestedPositions(companyId));
      });
  };
};
