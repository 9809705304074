import axios from "axios";

import {
  FETCH_DOMAIN_COMPANIES,
  SET_SELECTED_DOMAIN_ID,
  FETCH_DOMAIN_USERS,
  FETCH_USER_DOMAINS,
  GET_USER_ACCESS,
  SET_CURRENT_DOMAIN,
  BUILD_ACCESS,
  USER_ROLE_ACCESS,
  LOCKDOWN,
  ADD_ACCESS,
  ADD_ACCESSES,
  DELETE_ACCESS,
  DELETE_ACCESSES,
  SMART_LINK_LOADING,
} from "./types";
import { apiNode } from "../api";

const BUILDING = "building";

export const changeLockdown = () => {
  return async (dispatch) => {
    await dispatch({ type: LOCKDOWN });
  };
};

export const buildAccess = (userId = "") => {
  return async (dispatch) => {
    await dispatch({ type: BUILD_ACCESS, payload: BUILDING });
    await dispatch({ type: LOCKDOWN, payload: true });

    const allUserDomains = await fetchAllUserDomains(userId);

    if (allUserDomains.length > 0)
      await dispatch(buildCurrentDomain(allUserDomains, userId));

    await dispatch({ type: BUILD_ACCESS, payload: true });
  };
};

const buildCurrentDomain = (allUserDomains = [], userId = 0) => {
  return async (dispatch) => {
    const { handleCustomDomain } = await import("../utils/accessLevels");

    const currentDomain = handleCustomDomain(allUserDomains) || null;

    await dispatch(setAllUserDomains(allUserDomains));
    await dispatch(setCurrentDomain(currentDomain));
    await dispatch(fetchDomainCompanies());

    const domainUsers = await fetchDomainUsers(currentDomain.id);

    const currentDomainUserId = getCurrentDomainUserId(domainUsers, userId);
    await dispatch(fetchEntityUsersWithRole(currentDomain.id, "DOMAIN"));

    await dispatch(setDomainUsers(domainUsers));
    await dispatch(getUserAccess(currentDomainUserId));
  };
};

export const setSelectedDomainId = (domainId = null) => {
  return (dispatch) => {
    localStorage.setItem("SELECTED_DOMAIN_ID", domainId);

    dispatch({
      type: SET_SELECTED_DOMAIN_ID,
      payload: domainId,
    });
  };
};

export async function fetchAllUserDomains(userId = 0) {
  const allUserDomains = await axios
    .get(`/api/domains/user/${userId}`)
    .then((res) => {
      return res.data;
    });

  return allUserDomains;
}

export const setAllUserDomains = (allUserDomains = []) => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_USER_DOMAINS,
      payload: allUserDomains,
    });
  };
};

export const getDomainByNamespace = (namespace = "") => {
  return axios.get(`/api/domains/namepace/${namespace}`).then((res) => {
    return res.data;
  });
};

export const fetchDomainCompanies = () => {
  return async (dispatch) => {
    const { getCurrentDomain } = await import("../utils/accessLevels");

    const { id: domainId = null } = getCurrentDomain() || {};

    const allDomainCompanies = await axios
      .get(`/api/domains/${domainId}/companies`)
      .then((res) => {
        return res?.data;
      });

    dispatch({
      type: FETCH_DOMAIN_COMPANIES,
      payload: allDomainCompanies,
    });
  };
};

const setDomainUsers = (domainUsers = []) => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_DOMAIN_USERS,
      payload: domainUsers,
    });
  };
};

const transformUserIds = (users = []) => {
  if (Array.isArray(users)) {
    return users.map((user) => {
      user.domainUserId = user.id;
      user.id = user.userId;
      delete user.userId;

      return user;
    });
  }

  return [];
};

export const transformUserEntityAccessIds = (users = []) => {
  if (Array.isArray(users)) {
    return users.map((user) => {
      user.id = user.userId;
      delete user.userId;

      return user;
    });
  }

  return [];
};

export const transformUserAccessIds = (allUserAcces = {}) => {
  const updatedUserAccess = { ...allUserAcces };

  Object.keys(allUserAcces).forEach((entityType) => {
    if (entityType === "domain") return;

    updatedUserAccess[entityType] = [
      ...getPatternEntityIds(allUserAcces[entityType]),
    ];
  });

  return updatedUserAccess;
};

const getPatternEntityIds = (entitys = []) => {
  return entitys.map((entity) => {
    entity.id = entity.entityId;
    delete entity.entityId;

    return entity;
  });
};

async function fetchDomainUsers(domainId = "") {
  return await axios.get(`/api/domains/${domainId}/users`).then((res) => {
    const domainUsers = transformUserIds(res.data);

    return domainUsers;
  });
}

export async function getAllUsersFromDomain(domainId = "") {
  return async (dispatch) => {
    const response = await axios.get(`/api/domains/${domainId}/users`);
    const domainUsers = transformUserIds(response.data);

    await dispatch(setDomainUsers(domainUsers));
  };
}

export async function sendEnviteEmailFrom(users) {
  const response = await axios.post("/api/users/invitation", users);

  return response.data;
}

export function setInvitedUser(user) {
  return (dispatch) => {
    dispatch({ type: "INVITED_USER", payload: user });
  };
}

const getCurrentDomainUserId = (domainUsers = [], currentUserId = 0) => {
  const { domainUserId = 0 } =
    domainUsers.filter(({ id }) => id === currentUserId)[0] || {};

  return domainUserId;
};

export const fetchEntityUsersWithRoleWithReturn = async (
  entityId = 0,
  entityType = "",
) => {
  return await axios
    .get(`/api/entity-roles/${entityId}/${entityType}/users`)
    .then((res) => {
      const newEntityAccess = transformUserEntityAccessIds(res.data);
      return newEntityAccess;
    });
};

export const fetchEntityUsersWithRole = (entityId = 0, entityType = "") => {
  return async (dispatch) => {
    const usersWithRole = await axios
      .get(`/api/entity-roles/${entityId}/${entityType}/users`)
      .then((res) => {
        const newEntityAccess = transformUserEntityAccessIds(res?.data);

        return newEntityAccess;
      });

    dispatch({
      type: USER_ROLE_ACCESS,
      payload: { type: entityType.toLowerCase(), data: [...usersWithRole] },
    });
  };
};

export const getUserAccessWithReturn = async (domainUserId = "") => {
  return await axios
    .get(`/api/domain-users/${domainUserId}/accesses`)
    .then((res) => {
      const userAccessPaternFormat = transformUserAccessIds(res.data);

      return userAccessPaternFormat;
    });
};

export const getUserAccess = (domainUserId = "") => {
  return async (dispatch) => {
    const userAccess = await axios
      .get(`/api/domain-users/${domainUserId}/accesses`)
      .then((res) => {
        return res.data;
      });

    const userAccessPaternFormat = transformUserAccessIds(userAccess);
    dispatch({ type: GET_USER_ACCESS, payload: userAccessPaternFormat });
  };
};

export const updateUserRoleAccess = (
  body = {},
  { domainUserId = 0, newUserAccess = "" },
) => {
  return async (dispatch) => {
    await axios.post(
      `/api/domain-users/${domainUserId}/revokeAllAccessesAndAddNew/${newUserAccess}`,
      body,
    );
    await dispatch(fetchEntityUsersWithRole(body.entityId, body.entityType));
  };
};

export const updateUserPhone = (domainUserId, newPhone) => {
  return axios
    .post(`/api/domain-users/${domainUserId}/changePhone/${newPhone}`)
    .then((res) => {
      return res.data;
    });
};

export const updatedUserCpf = (domainUserId, newCpf) => {
  return axios
    .post(`/api/domain-users/${domainUserId}/changeCpf/${newCpf}`)
    .then((res) => {
      return res.data;
    });
};

export const handleUpdateEntityWithRole = (entitysInfo = []) => {
  return (dispatch) => {
    entitysInfo.forEach(async (entity) => {
      const { entityId = 0, entityType = "" } = entity;

      await dispatch(fetchEntityUsersWithRole(entityId, entityType));
    });
  };
};

export const removeAccess = (body, domainUserId = 0) => {
  return async (dispatch) => {
    try {
      const { updateUserAccess } = await import("./accessControllerActions");

      const { data: payload = false } = await axios.delete(
        `/api/domain-users/${domainUserId}/deleteAccess`,
        {
          data: body,
        },
      );

      if (payload) dispatch({ type: DELETE_ACCESS, payload });

      dispatch(updateUserAccess(domainUserId));
    } catch (err) {
      console.error("Erro ao remover acesso", err);
    }
  };
};

export const removeAccesses = (allBodys = [], domainUserId = 0) => {
  const { entityId = null } = allBodys[0] || {};

  return async (dispatch) => {
    try {
      if (entityId) {
        const { updateUserAccess } = await import("./accessControllerActions");

        const { data: payload = false } = await axios.delete(
          `/api/domain-users/${domainUserId}/deleteAccesses`,
          {
            data: allBodys,
          },
        );

        if (payload) dispatch({ type: DELETE_ACCESSES, payload });

        dispatch(updateUserAccess(domainUserId));
      }
    } catch (err) {
      console.error("Erro ao remover multiplos acessos", err);
    }
  };
};
//Adiciona um acesso para um determinado usuário, em uma determinada entity de um domínio

export const addAccess = (entityId = 0, entityType = "", userId = null) => {
  return async (dispatch) => {
    const { getAccessBody } = await import("../utils/accessLevels");

    const body = { entityId, ...getAccessBody(entityType, userId) };

    const addedAccess = await axios.post(
      "/api/domains/addDomainUserEntityRole",
      body,
    );

    const data = transformUserEntityAccessIds(addedAccess.data);
    dispatch({
      type: ADD_ACCESS,
      payload: data,
    });
  };
};

export const addCascadeAccess = async (entitysInfo = []) => {
  return async (dispatch) => {
    const response = await axios.post(
      "/api/domains/addDomainUserEntityRoles",
      entitysInfo,
    );

    if (response?.data) {
      const data = transformUserEntityAccessIds(response?.data);

      dispatch({
        type: ADD_ACCESSES,
        payload: data,
      });
    }
  };
};

export const setCurrentDomain = (domain = {}) => {
  const { id: selectedDomainId = false } = domain || {};
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_DOMAIN, payload: domain });
    dispatch(setSelectedDomainId(selectedDomainId));
    localStorage.setItem("SELECTED_DOMAIN_ID", selectedDomainId);
  };
};

export const configureDomain = () => {
  axios.get("/api/domains/configure");
};

export const updateUserIsActive = async (userId) => {
  await axios.post(`/api/users/${userId}/activate/true`);
};

export const userInactivationValidation = ({
  user_id,
  domain_user_id,
  domain_id,
}) => {
  return apiNode.post("/api/v2/domain/user-inactivation-validation", {
    user_id,
    domain_user_id,
    domain_id,
  });
};

export const userInactivation = ({ user_id, domain_user_id, domain_id }) => {
  return apiNode.put("/api/v2/domain/user-inactivation", {
    user_id,
    domain_user_id,
    domain_id,
  });
};

export const replaceRolesWithUserInactivation = ({
  user_id,
  domain_user_id,
  domain_id,
  replacements,
}) => {
  return apiNode.put("/api/v2/domain/replace-roles-with-user-inactivation", {
    user_id,
    domain_user_id,
    domain_id,
    replacements,
  });
};

export const updateUserManagementPosition = async (userId, active) => {
  await axios.post(`/api/users/${userId}/managementPosition/${active}`);
};

export const setSmartLinkLoading = (loading = false) => {
  return (dispatch) => dispatch({ type: SMART_LINK_LOADING, payload: loading });
};

export function syncDomainUsersAccess(domainId) {
  return apiNode.post("/api/v2/rookau-roles/sync-users-access/", { domainId });
}

export function getAllowedUsersByEntityRole(entityId, entityType, role) {
  return axios.get(`/api/entity-roles/${entityId}/${entityType}/${role}/users`);
}

export async function getFilteredUsersByEntityRole(entityId, entityType, role) {
  const { data: allowedUsers = [] } = await getAllowedUsersByEntityRole(
    entityId,
    entityType,
    role,
  );

  return allowedUsers
    ?.filter((user) => user?.domainUserId)
    .filter(
      (user, index, self) =>
        index === self.findIndex((u) => u.domainUserId === user.domainUserId),
    );
}

export function addInsightCollaborators(toolId, cenaryId, insightId, usersIds) {
  return apiNode.post("/api/v2/rookau-roles/add-insight-collaborators/", {
    toolId,
    cenaryId,
    insightId,
    usersIds,
  });
}
