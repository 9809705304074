import React from "react";
import _ from "lodash";
import ColumnsEvaluations from "./includes/ColumnsEvaluations";
import GoalEvaluations from "./includes/GoalEvaluations";
import ParticipantEvaluations from "./includes/ParticipantEvaluations";
import TeamGoalEvaluations from "./includes/TeamGoalEvaluations";

const AnaltyticsEvaluations = ({
  goalBonusAdministration,
  displayParticipants,
  filteredGoals,
  periodFilter = {},
  selectAndOpenInsight,
  isPeriodFilterActive,
  calcType,
}) => {
  const essencial = {
    goals: filteredGoals,
    clearBonusGateMin: goalBonusAdministration.clearBonusGateMin,
    displayParticipants,
    calcType,
    periodFilter,
    isPeriodFilterActive,
    goalTargets: goalBonusAdministration.goalTargets,
    periodCycle: goalBonusAdministration.periodCycle,
  };

  return (
    <div className="row">
      <div
        className="col-xs-12 col-md-6 col-xl-3"
        style={{ paddingLeft: "0px" }}
      >
        <div className="row">
          <ColumnsEvaluations {...essencial} />
        </div>
        <div className="row">
          <TeamGoalEvaluations
            {...essencial}
            teams={goalBonusAdministration.teams}
          />
        </div>
      </div>
      <div
        className="col-xs-12 col-md-6 col-xl-3"
        style={{ paddingLeft: "0px" }}
      >
        <div className="row">
          <ParticipantEvaluations {...essencial} />
        </div>
        <div className="row">
          <GoalEvaluations
            {...essencial}
            selectAndOpenInsight={selectAndOpenInsight}
          />
        </div>
      </div>
    </div>
  );
};

function areEqual(prev, next) {
  if (prev.calcType !== next.calcType) return false;
  if (_.isEqual(prev.periodFilter, next.periodFilter)) return false;
  if (_.isEqual(prev.filteredGoals, next.filteredGoals)) return false;
  if (_.isEqual(prev.displayParticipants, next.displayParticipants))
    return false;
  if (_.isEqual(prev.goalBonusAdministration, next.goalBonusAdministration))
    return false;

  return true;
}

export default AnaltyticsEvaluations;
