import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Tooltip } from "@material-ui/core";
import utils from "../../../../../../utils/toolUtils";

import { TdRegister } from "../css/OkrCss";

const { getMaxStringLength } = utils;

const defaultFilter = {
  attr: "title",
  order: "ASC",
};

const KeysRegister = (props) => {
  const [orderedKeys, setOrderedKeys] = useState([]);
  const [filter, setFilter] = useState(defaultFilter);

  const {
    getDefaultAddTableLine,
    getDefaultRemoveColumn,
    handleOpenModal,
    openFormDelete,
    getDialogDeleteKey,
    filteredkeyResults,
    intl,
  } = props;

  useEffect(() => {
    const ord1 = filter.order === "ASC" ? 1 : -1;
    const ord2 = filter.order === "ASC" ? -1 : 1;
    let newOrdered = filteredkeyResults;

    if (filter.attr === "title") {
      newOrdered = [...filteredkeyResults].sort((a, b) =>
        a.key.title > b.key.title ? ord1 : ord2,
      );
    }

    setOrderedKeys(newOrdered);
  }, [filteredkeyResults, filter]);

  const getNewOrder = (currentOrder) => {
    if (currentOrder === "ASC") return "DESC";
    return "ASC";
  };

  const handleFilter = (attr) => {
    let updatedFilter = defaultFilter;

    if (attr === filter.attr) {
      updatedFilter = {
        attr,
        order: getNewOrder(filter.order),
      };
    } else {
      updatedFilter = { ...defaultFilter, attr };
    }

    setFilter(updatedFilter);
  };

  const orderButton = ({ attr }) => {
    return (
      <button
        className="btn btn-data-sort grow"
        onClick={() => handleFilter(attr)}
        style={{ padding: "0px 5px", margin: "0px 0px 0px 10px" }}
      >
        <i className="fas fa-caret-up" />
        <i className="fas fa-caret-down" />
      </button>
    );
  };

  function onClick(key) {
    handleOpenModal("keyModal", true, {
      type: "keyModal",
      params: {
        keyResult: key,
        objectiveId: key.objectiveId,
      },
    });
  }

  return (
    <div>
      <h4>{intl.formatMessage({ id: "key_results_delivered" })}</h4>
      <table className="simple-table" style={{ margin: "15px 0 0 0" }}>
        <thead>
          <tr>
            <td style={{ width: "50% !important" }} colSpan={2}>
              {intl.formatMessage({ id: "key_results" })}
              {orderButton({ attr: "title" })}
            </td>
          </tr>
        </thead>
      </table>
      <div style={{ overflow: "auto", maxHeight: "441px" }}>
        <table className="simple-table" style={{ margin: "0" }}>
          <tbody>
            {orderedKeys && orderedKeys.length ? (
              orderedKeys.map((orderedKeysInfo) => {
                const paramsFormatText = {
                  string: orderedKeysInfo.key.title,
                  beginIndex: 0,
                  endIndex: 95,
                  additional: "...",
                };

                const keyTitle = getMaxStringLength(paramsFormatText);

                return (
                  <tr
                    key={orderedKeysInfo.key?.id}
                    style={{ cursor: "pointer" }}
                  >
                    <TdRegister
                      onClick={() => onClick(orderedKeysInfo.key)}
                      width="100%"
                    >
                      <Tooltip
                        title={orderedKeysInfo.key.title}
                        placement="bottom-start"
                      >
                        <span>{keyTitle}</span>
                      </Tooltip>
                    </TdRegister>

                    {getDefaultRemoveColumn(() =>
                      openFormDelete(
                        orderedKeysInfo.key.id,
                        getDialogDeleteKey(
                          orderedKeysInfo.key.id,
                          orderedKeysInfo.objectiveId,
                        ),
                      ),
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <TdRegister style={{ textAlign: "center" }}>
                  {intl.formatMessage({ id: "text_results_found" })}
                </TdRegister>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <table className="simple-table" style={{ marginTop: "0" }}>
        <tbody>
          {getDefaultAddTableLine(() => handleOpenModal("keyModal", true))}
        </tbody>
      </table>
    </div>
  );
};

export default injectIntl(KeysRegister);

KeysRegister.propTypes = {
  getDefaultAddTableLine: PropTypes.func.isRequired,
  getDefaultRemoveColumn: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  openFormDelete: PropTypes.func.isRequired,
  getDialogDeleteKey: PropTypes.func.isRequired,
  filteredkeyResults: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
};
