/* eslint-disable react-hooks/exhaustive-deps */
import { getInitialStates } from "constants/skillsAssessment";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { checkAccess } from "utils/accessLevels";
import {
  getSelectedToolStates,
  getSkillAssessmentStates,
} from "../../../customMapStates";
import InsightActionPlan from "../../../Common/ActionPlan/InsightActionPlan";
import { useSimpleToolAdministration } from "../../../../utils/skillsAssessment/hooks/administration";
import { getObjectInfoById } from "../../../../utils/ArrayUtils";
import WorkflowHeader from "../../../Tool/ToolTabs/CustomFields/SkillAssessment/tabs/workflow/WorkflowHeader";
import WorkflowDataTable from "../../../Tool/ToolTabs/CustomFields/SkillAssessment/tabs/workflow/WorkflowDataTable";
import { useWorkflowFields } from "../../../Tool/ToolTabs/CustomFields/SkillAssessment/tabs/workflow/customHooks/useWorkflowFields";
import {
  getMaximumScale,
  getMinimumScale,
  getParticipantCalculatedWorkflow,
  getParticipantsWorkflowAverage,
} from "../../../../utils/skillsAssessment/calc";
import {
  checkUserRoleForParticipant,
  buildWorkflowScaleOptions,
} from "../../../../utils/skillsAssessment/filter";
import EvaluationTargetField from "./EvaluationTargetField";

const InsightCustomField = ({
  selectedTool,
  insight,
  allSkillAssessment,
  loggedUser,
}) => {
  const { toolAdministration } = useSimpleToolAdministration({
    selectedTool,
    allSkillAssessment,
  });

  const {
    evaluationSkills,
    evaluationPerformance,
    evaluationTargetDetails,
    evaluationGapDetails,
  } = getInitialStates(insight);

  const {
    participants = [],
    evaluationType,
    managerConfig,
    composition,
    evaluationScale,
    potentialScale,
    transparency,
  } = toolAdministration;

  const [selectedParticipant, setSelectedParticipant] = useState({});
  const [participantWorkflow, setParticipantWorkflow] = useState([]);
  const { enabledFields } = useWorkflowFields({
    selectedParticipant,
    filterStage: "SELF_EVALUATION",
    evaluationType,
    managerConfig,
    composition,
    loggedUser,
    transparency,
  });

  const [evaluationTarget, setEvaluationTarget] = useState(evaluationSkills);
  const [evaluationPerformanceTarget, setEvaluationPerformanceTarget] =
    useState(evaluationPerformance);
  const [evaluationTargetDetailsTarget, setEvaluationTargetDetailsTarget] =
    useState(evaluationTargetDetails);
  const [evaluationGapDetailsTarget, setEvaluationGapDetailsTarget] =
    useState(evaluationGapDetails);

  const { skillsAssessmentParticipantId } = insight;

  useEffect(() => {
    const participantInfo = getObjectInfoById(
      skillsAssessmentParticipantId,
      participants,
      "id"
    );

    const newParticipantWorkflow = getParticipantCalculatedWorkflow(
      toolAdministration,
      participantInfo
    );

    if (
      !_.isEqual(participantInfo, selectedParticipant) ||
      !_.isEqual(newParticipantWorkflow, participantWorkflow)
    ) {
      setSelectedParticipant(participantInfo);
      setParticipantWorkflow(newParticipantWorkflow);
    }
  }, [skillsAssessmentParticipantId, toolAdministration, participants]);

  const maxScale = getMaximumScale(evaluationScale);
  const maxPotential = getMaximumScale(potentialScale);
  const minScale = getMinimumScale(evaluationScale);
  const minPotential = getMinimumScale(potentialScale);
  const haveRelatedRole = checkUserRoleForParticipant(
    loggedUser,
    selectedParticipant
  );
  const { participantsAverage } = getParticipantsWorkflowAverage(
    toolAdministration,
    [selectedParticipant]
  );

  const [average] = participantsAverage;

  let workflowAverage;
  if (average) {
    ({ workflowAverage } = average);
  }
  const displayCompetenceAverage =
    isNaN(workflowAverage?.averageEvaluation) ||
    workflowAverage?.averageEvaluation === null
      ? "Não realizada"
      : workflowAverage?.averageEvaluation.toFixed(2);
  const displayGapAverage = isNaN(workflowAverage?.gap)
    ? "Não realizada"
    : Number(workflowAverage.gap).toFixed(2);

  return (
    <div className="row">
      <div
        className="row workflow-container"
        style={{ paddingTop: "15px", marginTop: "15px" }}
      >
        <div className="col-xs-12">
          <WorkflowHeader participant={selectedParticipant} />
        </div>
        <div className="col-xs-12" style={{ paddingTop: "0px" }}>
          {haveRelatedRole && (
            <WorkflowDataTable
              selectedToolId={selectedTool.id}
              workflow={participantWorkflow}
              participant={{ ...selectedParticipant, insight }}
              loggedUser={loggedUser}
              enabledFields={{
                ...enabledFields,
                potential: { hide: true },
                averageEvaluation: { hide: false },
                managerEvaluation: { hide: true },
              }}
              toolAdministration={toolAdministration}
              maxScale={maxScale}
              minScale={minScale}
              maxPotential={maxPotential}
              minPotential={minPotential}
              scaleOptions={{
                potentialScale: buildWorkflowScaleOptions(
                  toolAdministration,
                  "potentialScale"
                ),
                evaluationScale: buildWorkflowScaleOptions(
                  toolAdministration,
                  "evaluationScale"
                ),
              }}
            />
          )}
          {!haveRelatedRole && <h4 align="center">Não pode visualizar.</h4>}
        </div>
      </div>
      <div className="row">
        {(loggedUser?.id === selectedParticipant?.manager?.id ||
          checkAccess(["MANAGER", "TOOLVIEWER"])) && (
          <EvaluationTargetField
            insight={insight}
            evaluationTarget={evaluationTarget}
            setEvaluationTarget={setEvaluationTarget}
            evaluationPerformanceTarget={evaluationPerformanceTarget}
            setEvaluationPerformanceTarget={setEvaluationPerformanceTarget}
            evaluationTargetDetailsTarget={evaluationTargetDetailsTarget}
            setEvaluationTargetDetailsTarget={setEvaluationTargetDetailsTarget}
            evaluationGapDetailsTarget={evaluationGapDetailsTarget}
            setEvaluationGapDetailsTarget={setEvaluationGapDetailsTarget}
            competenceAverage={displayCompetenceAverage}
            toolAdministration={toolAdministration}
            loggedUser={loggedUser}
            selectedToolId={selectedTool.id}
            selectedParticipantId={selectedParticipant?.id}
            managerId={selectedParticipant?.manager?.id}
            gapAverage={displayGapAverage}
            average={workflowAverage}
          />
        )}
      </div>
      <div className="row">
        <InsightActionPlan
          insight={insight}
          selectedTool={selectedTool}
          customTitle="PDI"
          dragabble
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { allSkillAssessment, isLoading } = getSkillAssessmentStates(state);
  const { selectedTool } = getSelectedToolStates(state);
  const { user } = state;

  return {
    allSkillAssessment,
    selectedTool,
    isLoading,
    loggedUser: user,
  };
};

InsightCustomField.propTypes = {
  selectedTool: PropTypes.object.isRequired,
  insight: PropTypes.object.isRequired,
  allSkillAssessment: PropTypes.array.isRequired,
  loggedUser: PropTypes.object.isRequired,
};

const SkillAssessment = connect(mapStateToProps)(InsightCustomField);

export { SkillAssessment };
