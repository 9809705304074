import React from "react";
import { defineMessages } from "react-intl";
import { simpleArrayUnNester } from "../ArrayUtils";
import { translatedText } from "../translationUtils";

const messages = defineMessages({
  cpystr_text_areas_title: { id: "cpystr_text_areas_title" },
});

const translation = (id) => translatedText(id, messages);

export const buildSelectAreasTree = (companyAreas, areasFilter = null) => {
  const treeList = {};
  const treeOrder = [];
  let all = [];

  const enableFilter = areasFilter && Array.isArray(areasFilter);

  if (Array.isArray(companyAreas)) {
    const unNestedAreas = simpleArrayUnNester(companyAreas, "subAreas");

    if (Array.isArray(unNestedAreas)) {
      unNestedAreas.forEach((levelItems, levelIndex) => {
        const levelOrderName = `areaLevel${levelIndex}`;
        const addList = [];

        treeOrder.push(levelOrderName);

        if (Array.isArray(levelItems)) {
          levelItems.forEach((itemInfo) => {
            if (
              !enableFilter ||
              (enableFilter && areasFilter.indexOf(itemInfo.id) === -1)
            )
              addList.push({
                id: itemInfo.id,
                title: itemInfo.title,
                parentId: itemInfo.parentAreaId,
              });
          });
        }

        treeList[levelOrderName] = {
          label: translation("cpystr_text_areas_title"),
          list: addList,
          countText: translation("cpystr_text_areas_title").toLowerCase(),
          color: "#333",
          icon: <i className="fas fa-building" />,
        };

        all = [...all, ...addList];
      });
    }
  }

  return { treeList, treeOrder, all };
};

export const getSimpleAreaTree = (companyAreas) => {
  const list = [];

  if (Array.isArray(companyAreas)) {
    companyAreas.forEach((areaInfo) => {
      list.push({ id: areaInfo?.id, title: areaInfo?.title, parentId: null });
    });
  }

  return {
    treeList: {
      simple: {
        label: "Áreas de Participantes",
        color: "#333",
        icon: <i className="fas fa-building" />,
        countText: translation("cpystr_text_positions_count").toLowerCase(),
        list,
      },
    },
    treeOrder: ["simple"],
    all: list,
  };
};
