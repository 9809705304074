import axios from "axios";

import { fetchEntityUsersWithRole } from "./accessLevelsActions";

import {
  SET_SELECTED_WORKSPACE,
  FETCH_USER_WORKSPACES,
  FETCH_WORKSPACE_OVERVIEW,
  FETCH_WORKSPACE_OVERVIEW_INSIGHT_COUNTER,
  FETCH_WORKSPACE_MEMBERS,
  FETCH_USER_OWNER_COMPANY_WORKSPACES,
  FETCH_USER_MEMBER_COMPANY_WORKSPACES,
  FETCH_USER_MEMBER_COMPANY_TEAM_WORKSPACES,
  CREATE_WORKSPACE,
  SELECTED_WORKSPACE,
  FETCH_WORKSPACE_PARTICIPANTS,
  UPDATE_WORKSPACES,
  GET_WORKSPACE_TEAM,
} from "./types";

import { fetchWorkspaceSelectedTools } from "./toolActions";
import { fetchMinifiedCompanyWorkspaces } from "./companyActions";
import { sendNewWorkspaceNotification } from "./notifications/controllerActions";

export const getMinifiedCompanyWorkspaces = (companyId) => {
  return axios
    .get(`/api/company/${companyId}/all-workspaces`)
    .then((res) => res.data);
};

export const getAllCompanyWorkspaces = (companyID, fetchFiled = false) => {
  return axios
    .get(
      `/api/company/${companyID}/workspaces/fetch-file-option?fetchFiled=${fetchFiled}`,
    )
    .then((res) => res);
};

export const fetchUserWorkspaces = (userId) => {
  return (dispatch) => {
    return axios.get(`/api/workspaces/user/${userId}`).then((res) => {
      const workspaces = res.data;

      dispatch({ type: FETCH_USER_WORKSPACES, payload: workspaces });
    });
  };
};

export const fetchUserOwnerCompanyWorkspaces = (userId, companyId) => {
  return (dispatch) => {
    return axios
      .get(`/api/workspaces/owner/${userId}/company/${companyId}`)
      .then((res) => {
        const workspaces = res.data;
        dispatch({
          type: FETCH_USER_OWNER_COMPANY_WORKSPACES,
          payload: workspaces,
        });
      });
  };
};

export const fetchUserMemberCompanyWorkspaces = (userId, companyId) => {
  return (dispatch) => {
    if (userId && companyId) {
      return axios
        .get(`/api/workspaces/member/${userId}/company/${companyId}`)
        .then((res) => {
          const workspaces = res.data;
          dispatch({
            type: FETCH_USER_MEMBER_COMPANY_WORKSPACES,
            payload: workspaces,
          });
        });
    }
  };
};

export const fetchUserMemberCompanyTeamWorkspaces = (userId, companyId) => {
  return (dispatch) => {
    return axios
      .get(`/api/workspaces/member/${userId}/team/${companyId}`)
      .then((res) => {
        const workspaces = res.data;
        dispatch({
          type: FETCH_USER_MEMBER_COMPANY_TEAM_WORKSPACES,
          payload: workspaces,
        });
      });
  };
};

export const fetchWorkspaceMembers = (workspaceId) => {
  return (dispatch) => {
    return axios.get(`/api/workspaces/${workspaceId}/members`).then((res) => {
      dispatch({ type: FETCH_WORKSPACE_MEMBERS, payload: res.data });
    });
  };
};

export const fetchWorkspaceParticipants = (workspaceId) => {
  return (dispatch) => {
    return axios
      .get(`/api/workspaces/${workspaceId}/participants`)
      .then((res) => {
        dispatch({ type: FETCH_WORKSPACE_PARTICIPANTS, payload: res.data });
      });
  };
};

export const createWorkspace = (workspace = {}) => {
  const { company = {} } = workspace || {};
  const { id: selectedCompanyId = null } = company || {};

  return (dispatch) => {
    return axios
      .post("/api/workspaces", { ...workspace, type: "workspace" })
      .then(async (res) => {
        const { grantModeratorAccess } = await import(
          "./accessControllerActions"
        );

        const { id: workspaceId = 0, company = {} } = res.data;
        const { id: companyId = 0 } = company;

        await dispatch({ type: CREATE_WORKSPACE, payload: res.data });
        await dispatch(grantModeratorAccess(workspaceId));
        await dispatch(fetchMinifiedCompanyWorkspaces(companyId));
        await dispatch(sendNewWorkspaceNotification(companyId));
      });
  };
};

export const setSelectedWorkspace = (workspace) => {
  return async (dispatch) => {
    await dispatch(fetchEntityUsersWithRole(workspace.id, "WORKSPACE"));
    localStorage.setItem(SELECTED_WORKSPACE, workspace.id);
    dispatch({ type: SET_SELECTED_WORKSPACE, payload: workspace });
    dispatch(fetchWorkspaceSelectedTools(workspace.id));
  };
};

export const unsetSelectedWorkspace = () => {
  return (dispatch) => {
    localStorage.removeItem(SELECTED_WORKSPACE);
    dispatch({ type: SET_SELECTED_WORKSPACE, payload: undefined });
  };
};

export const getSelectedWorkspace = () => {
  return (dispatch) => {
    const selectedWorkspaceId = localStorage.getItem(SELECTED_WORKSPACE);

    if (selectedWorkspaceId) {
      return axios
        .get(`/api/workspaces/${selectedWorkspaceId}`)
        .then(async (res) => {
          if (!res) return;

          const selectedWorkspace = res.data;

          await dispatch(setSelectedWorkspace(selectedWorkspace));
        });
    }
  };
};

export const getWorkspace = (workspaceId) => {
  /*
  const cachedWorkspace = localStorage.getItem(
    String("workspace" + workspaceId),
  );

  if (cachedWorkspace) return cachedWorkspace[0];
*/

  if (!workspaceId) return {};

  return axios.get(`/api/workspaces/${workspaceId}`).then((res) => {
    localStorage.setItem(String(`workspace${workspaceId}`), [res.data]);
    return res.data;
  });
};

export const updateWorkspace = (workspace) => {
  return async (dispatch) => {
    return await axios.put("/api/workspaces", workspace).then(async (res) => {
      await dispatch({ type: UPDATE_WORKSPACES, payload: res.data });
      const { companyId = 0 } = res.data || {};

      await dispatch(fetchMinifiedCompanyWorkspaces(companyId));
    });
  };
};

export const getWorkspaceTeams = (workspaceId) => {
  return (dispatch) => {
    return axios.get(`/api/workspaces/${workspaceId}/teams`).then((res) => {
      dispatch({ type: GET_WORKSPACE_TEAM, payload: res.data });
    });
  };
};

export const insertTeamWorkspace = (workspaceId, teamId) => {
  return (dispatch) => {
    return axios
      .post(`/api/workspaces/${workspaceId}/teams/${teamId}`)
      .then(() => {
        dispatch(getWorkspaceTeams(workspaceId));
      });
  };
};

export const deleteTeamWorkspace = (workspaceId, teamId) => {
  return (dispatch) => {
    return axios
      .delete(`/api/workspaces/${workspaceId}/teams/${teamId}`)
      .then(() => {
        dispatch(getWorkspaceTeams(workspaceId));
      });
  };
};

export const fetchForInsertCompanyMemberForWorkspace = (
  member,
  company_ID,
  workspace_ID,
) => {
  return (dispatch) => {
    return axios.get(`/api/users/email/${member.email}/`).then((res) => {
      const member_ID = res.data.id;
      dispatch(
        insertCompanyMemberForWorkspace(member_ID, workspace_ID, company_ID),
      );
    });
  };
};

export const insertWorkspaceMember = (workspaceId, memberId) => {
  return (dispatch) => {
    return axios
      .post(`/api/workspaces/${workspaceId}/members/${memberId} `)
      .then(() => {
        dispatch(fetchWorkspaceMembers(workspaceId));
      });
  };
};

export const insertCompanyMemberForWorkspace = (member, workspace, company) => {
  return (dispatch) => {
    return axios
      .post(`/api/companies/${company}/members/${member}`)
      .then(() => {
        dispatch(insertWorkspaceMember({ workspace, member }));
      });
  };
};

export const insertArrayMembers = (workspaceId, arrayMembers) => {
  return (dispatch) => {
    const insertMembers = async () => {
      await Promise.all(
        arrayMembers.map((membersId) =>
          dispatch(insertWorkspaceMember(workspaceId, membersId)),
        ),
      );
    };

    insertMembers();
  };
};

export const insertWorkspaceParticipant = (workspaceId, participantId) => {
  return (dispatch) => {
    return axios
      .post(`/api/workspaces/${workspaceId}/participants/${participantId} `)
      .then(() => {
        dispatch(fetchWorkspaceParticipants(workspaceId));
      });
  };
};

export const insertArrayParticipants = (workspaceId, arrayParticipants) => {
  return (dispatch) => {
    const insertParticipants = async () => {
      await Promise.all(
        arrayParticipants.map((participantsId) =>
          dispatch(insertWorkspaceParticipant(workspaceId, participantsId)),
        ),
      );
    };

    insertParticipants();
  };
};

export const deleteWorkspaceMember = (workspaceId, memberId) => {
  return (dispatch) => {
    return axios
      .delete(`/api/workspaces/${workspaceId}/members/${memberId} `)
      .then(() => {
        dispatch(fetchWorkspaceMembers(workspaceId));
      });
  };
};

export const deleteWorkspaceParticipant = (idWorkspace, participantID) => {
  return (dispatch) => {
    return axios
      .delete(`/api/workspaces/${idWorkspace}/participants/${participantID} `)
      .then(() => {
        dispatch(fetchWorkspaceParticipants(idWorkspace));
      });
  };
};

export const fetchWorkspaceOverview = (workspace_ID, filter) => {
  return (dispatch) => {
    return axios
      .get(`/api/workspaces/${workspace_ID}/overview/${filter}`)
      .then((res) => {
        const workspaces = res.data;
        dispatch({ type: FETCH_WORKSPACE_OVERVIEW, payload: workspaces });
      });
  };
};

export const fetchWorkspaceOverviewInsightCounter = (workspace_ID) => {
  return (dispatch) => {
    return axios
      .get(`/api/workspaces/${workspace_ID}/overview/insightsCounter`)
      .then((res) => {
        const workspaces = res.data;
        dispatch({
          type: FETCH_WORKSPACE_OVERVIEW_INSIGHT_COUNTER,
          payload: workspaces,
        });
      });
  };
};
