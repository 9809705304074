import connectionTranslations from "./connection";
import buttonsTranslations from "./buttons";
import commentsTranslations from "./comments";
import deletesTranslations from "./deletes";
import generalModalTranslations from "./../general/index";
import selectModalTranslations from "./selectModal";
import registerModalTranslations from "./registerModal";
import updateModalTranslations from "./updateModal";
import alertTranslations from "./alerts";
import internetConnTrasnlations from "./internet";

const additionalTranslations = [...generalModalTranslations];

const dialogTranslations = [
  connectionTranslations,
  buttonsTranslations,
  commentsTranslations,
  deletesTranslations,
  selectModalTranslations,
  registerModalTranslations,
  updateModalTranslations,
  alertTranslations,
  internetConnTrasnlations,
];

const finalTranslations = [...dialogTranslations, ...additionalTranslations];

export default finalTranslations;
