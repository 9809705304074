export const stageModalForm = {
  open: false,
  openDelete: false,
  params: {
    id: null,
    description: "",
    parentId: null,
    parent: null,
    projectConfigurationId: null,
  },
};

export const deleteDialogForm = {
  params: {},
  open: false,
  openCantDelete: false,
};
