import { connect } from "react-redux";
import {
  fetchToolGoalBonusConfiguration,
  postUpdateGoals,
} from "../../../../actions/goalsAndBonusActions";
import { getRelationshipTypeOptions } from "../../../../constants/kpis";
import { getNewGoalDefaultOptions } from "../../../../utils/goalsBonus/entity";
import { useSimpleToolAdministration } from "../../../../utils/goalsBonus/hooks";
import { getSelectGoalSecondaryText } from "../../../../utils/kpis/display";
import { useWorkspaceIndicators } from "../../../../utils/kpis/hooks";
import {
  getGoalsBonusStates,
  getSelectedToolStates,
  getWorkspacesStates,
} from "../../../customMapStates";
import SelectGoalModal from "../../SelectGoalModal";

const NewLinkedIndicator = ({
  open,
  afterConfirm,
  afterCancel,
  workspaceTools,
  selectedTool,
  allGoalBonusAdministration,
  fetchToolGoalBonusConfiguration,
}) => {
  const { goalBonusAdministration } = useSimpleToolAdministration({
    selectedTool,
    allGoalBonusAdministration,
  });
  const { indicators } = useWorkspaceIndicators({ workspaceTools });

  const relationshipTypeOptions = getRelationshipTypeOptions();
  const defaultNewGoalForm = getNewGoalDefaultOptions();

  const getFilteredDisplayGoals = () => {
    return indicators.map((goalInfo) => {
      return {
        ...goalInfo,
        filterValue: goalInfo.relationshipType,
        secondaryText: getSelectGoalSecondaryText(goalInfo),
      };
    });
  };

  const handleConfirm = async (selected, selectedList) => {
    const [selectedIndicator] = selectedList || [];
    const { goals: currentGoals } = goalBonusAdministration;

    const {
      frequency,
      title,
      description,
      measurement,
      measurementUnity,
      relationshipType,
    } = selectedIndicator;

    if (selectedIndicator) {
      const newGoal = {
        ...defaultNewGoalForm,
        measurement,
        measurementUnity,
        relationshipType,
        frequency,
        title,
        description,
        linkedIndicator: selectedIndicator,
      };

      const saveGoals = [...currentGoals, newGoal];
      await postUpdateGoals(goalBonusAdministration.id, saveGoals);

      fetchToolGoalBonusConfiguration(selectedTool.id);
    }

    afterConfirm();
  };

  return (
    <SelectGoalModal
      open={open}
      singleSelect
      title="Selecione o indicador para fazer a conexão com a meta"
      description={
        <p style={{ padding: "15px" }}>
          OBS: Metas conectadas só podem ser editadas através da ferramenta
          Indicadores.
        </p>
      }
      onConfirm={handleConfirm}
      onCancel={afterCancel}
      goalsList={getFilteredDisplayGoals()}
      filterOptions={relationshipTypeOptions}
    />
  );
};

function mapStateToProps(state) {
  const { workspaceTools } = getWorkspacesStates(state);
  const { allGoalBonusAdministration } = getGoalsBonusStates(state);
  const { selectedTool } = getSelectedToolStates(state);

  return { workspaceTools, selectedTool, allGoalBonusAdministration };
}

export default connect(mapStateToProps, { fetchToolGoalBonusConfiguration })(
  NewLinkedIndicator
);
