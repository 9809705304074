import React from "react";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";
import { getEmptyTableRow } from "../../../../../../../utils/tableUtils";
import { translatedText } from "../../../../../../../utils/translationUtils";
import { getDefaultSuccessionScale } from "../../../../../../../utils/skillsAssessment/constants";

const messages = defineMessages({
  global_noneRegistered: { id: "global.noneRegistered" },
});

const translation = (id) => translatedText(id, messages);

const TableComplementaryScale = ({ successionEvaluations = {} }) => {
  const successionScale = getDefaultSuccessionScale(successionEvaluations);

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="100">ESCALA FECHADA</td>
        </tr>
      </thead>
      <tbody>
        {successionScale.map((scaleInfo) => {
          return (
            <tr key={scaleInfo.evaluationId}>
              <td align="center">{scaleInfo.label}</td>
            </tr>
          );
        })}
        {getEmptyTableRow(
          successionScale,
          translation("global_noneRegistered"),
        )}
      </tbody>
    </table>
  );
};

TableComplementaryScale.propTypes = {
  successionEvaluations: PropTypes.array.isRequired,
};

export default TableComplementaryScale;
