import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  saveLinkBoardScenario,
  createKpiScenerieTheme,
} from "../../../../actions/kpiActions";
import ScenerieThemeSelectDialog from "../../../Common/Kpi/forms/ScenerieThemeSelectDialog";
import { getSelectedToolStates, getKpiStates } from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";
import kpiUtils from "../../../../utils/kpis";

const { getValidFunc } = utils;

const {
  getKpiConfigBySelectedToolId,
  buildFormAdmConfigBody,
  buildRestAdmConfigBody,
  getThemeInfoById,
} = kpiUtils;

const defaultFormKpiConfig = buildFormAdmConfigBody({});

const Kpi = (props) => {
  const [kpiAdministration, setKpiAdministration] = useState(
    defaultFormKpiConfig,
  );
  const [addedThemes, setAddedThemes] = useState([]);

  const {
    type,
    clearViewCallback,
    saveLinkBoardScenario,
    createKpiScenerieTheme,
    selectedTool = {},
    isLoading = false,
    allKpiAdministration = [],
    userProfile,
    allSceneries = [],
    params = {},
  } = props;

  const cancelCallback = getValidFunc(params.cancelCallback);

  useEffect(() => {
    let toolThemes = [];

    if (allSceneries && allSceneries.length) {
      allSceneries.forEach((scenerieInfo) => {
        if (
          scenerieInfo.selectedTool.id === selectedTool.id &&
          scenerieInfo.kpiConfigurationThemeId
        )
          toolThemes.push(scenerieInfo.kpiConfigurationThemeId);
      });
    }

    if (!_.isEqual(toolThemes, addedThemes)) setAddedThemes(toolThemes);
  }, [addedThemes, allSceneries, selectedTool.id]);

  useEffect(() => {
    if (allKpiAdministration) {
      let toolKpiConfig = getKpiConfigBySelectedToolId(
        selectedTool.id,
        allKpiAdministration,
      );

      toolKpiConfig = buildFormAdmConfigBody(toolKpiConfig);

      if (!isLoading && !_.isEqual(toolKpiConfig, kpiAdministration)) {
        setKpiAdministration(toolKpiConfig);
      }
    }
  }, [allKpiAdministration, kpiAdministration, isLoading, selectedTool.id]);

  const handleUpdateKpiConfig = (newKpiConfig) => {
    if (!_.isEqual(kpiAdministration, newKpiConfig)) {
      const newKpiAdministrationREST = buildRestAdmConfigBody(newKpiConfig);

      saveLinkBoardScenario(
        selectedTool,
        newKpiAdministrationREST,
        userProfile,
        params.nextScenerieOrder,
      );

      clearDialogControll();
    }
  };

  const handleSaveScenerie = ({ themeId }) => {
    if (selectedTool.id && themeId) {
      const teamInfo = getThemeInfoById(themeId, kpiAdministration.themes);

      createKpiScenerieTheme(
        selectedTool,
        teamInfo,
        userProfile,
        params.nextScenerieOrder,
      );
    }

    clearDialogControll();
  };

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
    cancelCallback();
  };

  return (
    <ScenerieThemeSelectDialog
      open={true}
      afterConfirm={handleSaveScenerie}
      afterCancel={clearDialogControll}
      kpiAdministration={kpiAdministration}
      setKpiAdministration={() => {}}
      alternativeSaveCallback={handleUpdateKpiConfig}
      addedThemes={addedThemes}
    />
  );
};

const mapStateToProps = (state) => {
  const { selectedTool, allSceneries } = getSelectedToolStates(state);
  const { allKpiAdministration, isLoading } = getKpiStates(state);

  const { user } = state;

  return {
    userProfile: user,
    selectedTool,
    allKpiAdministration,
    isLoading,
    allSceneries,
  };
};

export default connect(mapStateToProps, {
  saveLinkBoardScenario,
  createKpiScenerieTheme,
})(Kpi);
