import React from "react";
import styled from "styled-components";

const Box = styled.div`
  i {
    background-color: #c4e16c;
    color: #111;
    width: 20px;
    height: 20px;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    margin-right: 5px;
  }
`;

const LinkedIcon = ({ text }) => {
  return (
    <Box title="Esta meta está conectada com a ferramenta indicadores deste workspace">
      <i className="fas fa-code-branch" />
      {text}
    </Box>
  );
};

export default LinkedIcon;
