import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { getSelectedToolStates, getWorkspacesStates } from "../customMapStates";
import { getCustomPeriodWorkspaceTools } from "../../actions/customPeriodActions";
import { orderArrayByObjAttr } from "../../utils/ArrayUtils";
import { setSelectedTool } from "../../actions/toolActions";
import { cleanAllLocalStorageData } from "../../actions/toolsConfigurationsActions";
import FormDialog from "../Common/FormDialog";

const FixedFooter = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;
const SelectCustomPeriodButton = styled.button`
  padding: 5px 15px;
  background-color: #fff;
  color: #6b42a9;
  border: 1px solid #6b42a9;
  border-radius: 5px;
  font-weight: bold;
  transition: 0.3s;

  &:hover {
    background-color: #6b42a9;
    color: #fff;
  }
`;
const ListSelectButton = styled.div`
  padding: 15px;
  background-color: #f1f1f1;
  border: ${({ isSelected = false }) =>
    isSelected ? "1px solid #6b42a9" : "1px solid #ccc"};
  cursor: pointer;
  &:hover {
    background-color: #ccc;
  }

  font-weight: bold;
  .date {
    font-size: 11px;
    font-weight: normal;
  }
`;

const SelectCustomPeriodFooter = ({
  selectedTool,
  workspaceTools,
  setSelectedTool,
  cleanAllLocalStorageData,
}) => {
  const [openSelectPeriod, setOpenSelectPeriod] = useState(false);
  const [toolPeriods, setCustomPeriods] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function fetch() {
      const filteredTools = workspaceTools.filter(
        ({ tool }) => tool.id === selectedTool.tool.id,
      );
      const newPeriods = await getCustomPeriodWorkspaceTools(filteredTools);
      setCustomPeriods(newPeriods);
    }
    if (selectedTool?.id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceTools, selectedTool]);

  function handleSelectTool(tool_id) {
    const newSelectedTool = workspaceTools.find(({ id }) => id === tool_id);
    cleanAllLocalStorageData();
    setSelectedTool(newSelectedTool);
    history.push(`/tool/${newSelectedTool.id}`);
    setOpenSelectPeriod(false);
  }

  const selectedPeriod = toolPeriods.find(
    ({ tool_id }) => tool_id === selectedTool.id,
  );

  const ordered = orderArrayByObjAttr(toolPeriods, "title");

  return (
    <FixedFooter>
      {selectedPeriod?.id && (
        <SelectCustomPeriodButton onClick={() => setOpenSelectPeriod(true)}>
          <i className="fas fa-calendar-alt" /> {selectedPeriod?.title}
        </SelectCustomPeriodButton>
      )}
      <FormDialog
        open={openSelectPeriod}
        hideConfirm
        onCancel={() => {
          setOpenSelectPeriod(false);
        }}
      >
        {ordered.map((periodInfo) => {
          const isSelected = selectedPeriod?.id === periodInfo.id;
          return (
            <ListSelectButton
              key={periodInfo.id}
              isSelected={isSelected}
              onClick={() => handleSelectTool(periodInfo.tool_id)}
            >
              <div>{periodInfo.title}</div>
              <font className="date">
                {moment(periodInfo.start_date).format("L")}
                {" - "}
                {moment(periodInfo.end_date).format("L")}
              </font>
            </ListSelectButton>
          );
        })}
      </FormDialog>
    </FixedFooter>
  );
};

function mapStateToProps(state) {
  const { selectedTool } = getSelectedToolStates(state);
  const { workspaceTools } = getWorkspacesStates(state);

  return {
    selectedTool,
    workspaceTools,
  };
}

function areEqual(prevProps, nextProps) {
  if (!_.isEqual(prevProps.selectedTool, nextProps.selectedTool)) {
    return false;
  }
  if (!_.isEqual(prevProps.workspaceTools, nextProps.workspaceTools)) {
    return false;
  }
  return true;
}

export default connect(mapStateToProps, {
  setSelectedTool,
  cleanAllLocalStorageData,
})(React.memo(SelectCustomPeriodFooter, areEqual));
