/* eslint-disable no-nested-ternary */
import { getObjectInfoById } from "../ArrayUtils";
import {
  defaultCompositionConfig,
  defaultEnabledWorkflowActions,
  defaultEvaluationTypeConfig,
} from "./defaults";
import { getComplementaryEvaluationsByType } from "./filter";

export const minimumRoleSkillRelationFactor = 0.85;

export const getCompetenceCompositionOptions = () => {
  return [
    { value: "SIMPLE", label: "Competência Simplificada" },
    { value: "POTENTIAL", label: "Competência e Potencial" },
    { value: "QUALIFICATION", label: "Competência x Critério da Qualificação" },
  ];
};

export const getEvaluationTypeOptions = () => {
  return [
    { value: "TYPE_180", label: "180°" },
    { value: "TYPE_360", label: "360°" },
  ];
};

export const getCompetenceConfigByComposition = (
  evaluationComposition = "SIMPLE"
) => {
  if (evaluationComposition === "SIMPLE") return defaultCompositionConfig;
  if (evaluationComposition === "POTENTIAL")
    return {
      enablePotential: true,
      enableQualification: false,
      enableDesiredEvaluation: false,
    };
  if (evaluationComposition === "QUALIFICATION")
    return {
      enablePotential: true,
      enableQualification: true,
      enableDesiredEvaluation: true,
    };

  return defaultCompositionConfig;
};

export const getEvaluationConfigByType = (evaluationType = "TYPE_180") => {
  if (evaluationType === "TYPE_180")
    return { ...defaultEvaluationTypeConfig, enableWorkflowStageUpdate: true };
  if (evaluationType === "TYPE_360")
    return {
      enableSecondEvaluator: true,
      enableThirdEvaluator: true,
      enableWeights: true,
      enableWorkflowStageUpdate: true,
    };

  return defaultEvaluationTypeConfig;
};

export const getAccessRelation = (roleRelation, loggedUser, participant) => {
  const isSecondEvaluator =
    participant?.secondEvaluator?.id === loggedUser?.id ? true : false;
  const isThrirdEvaluator =
    participant?.thirdEvaluator?.id === loggedUser?.id ? true : false;
  return {
    isAdmin: roleRelation === "ADMIN",
    isParticipant: roleRelation === "PARTICIPANT",
    isManager: roleRelation === "MANAGER",
    isSecEval: roleRelation === "SECOND_EVALUATOR" || isSecondEvaluator,
    isThrEval: roleRelation === "THIRD_EVALUATOR" || isThrirdEvaluator,
  };
};

export const canManagersUpdate = (evaluationStage) => {
  return ["SELF_EVALUATION", "MANAGERS"].includes(evaluationStage);
};

export const checkComplementaryWorkflowByStage = (
  workflowStage,
  loggedUserRole,
  loggedUser,
  participant
) => {
  const { isAdmin, isManager } = getAccessRelation(
    loggedUserRole,
    loggedUser,
    participant
  );
  return {
    enableComplementaryEvaluation: isAdmin || isManager,
  };
};

export const getEvaluationStagesOptions = (
  evaluationType,
  evaluationConfig,
  allEvaluators = false,
  selectEvaluators = false
) => {
  const optionalOptions = [];

  if (evaluationType === "TYPE_360")
    optionalOptions.push({ value: "EVALUATORS", label: "Avaliadores" });

  if (allEvaluators)
    optionalOptions.push({
      value: "ALL_EVALUATORS",
      label: "Gestores/Avaliadores",
    });

  const mainOptions = [
    { value: "VALIDATION", label: "Validação" },
    { value: "SELF_EVALUATION", label: "Autoavaliação" },
    { value: "MANAGERS", label: "Gestores" },
    ...optionalOptions,
    { value: "APPROVAL", label: "Aprovação" },
    { value: "CONCLUDED", label: "Concluído" },
  ];

  if (selectEvaluators) {
    mainOptions.unshift({
      value: "SELECTION_EVALUATORS",
      label: "Seleção de Avaliadores",
    });
  }

  return [...mainOptions];
};

export const getWorkflowEvaluationFields = () => {
  return [
    { value: "selfEvaluation", label: "AUTOAVALIAÇÃO" },
    { value: "managerEvaluation", label: "AVALIAÇÃO GESTOR" },
    { value: "potential", label: "POTENCIAL" },
    { value: "secondEvaluator", label: "CLIENTE" },
    { value: "thirdEvaluator", label: "FORNECEDOR" },
    { value: "averageEvaluation", label: "MÉDIA GERAL", type: "float" },
    { value: "finalEvaluation", label: "AVALIAÇÃO FINAL", type: "float" },
  ];
};

export const evaluationColors = {
  selfEvaluation: {
    color: "#8db6c7",
    gradient:
      "linear-gradient(90deg, rgba(141,182,199,1) 0%, rgba(141,182,199,0.3) 100%)",
    background: "#dfdfdf",
  },
  managerEvaluation: {
    color: "#3f647e",
    gradient:
      "linear-gradient(90deg, rgba(8,32,128,1) 0%, rgba(8,32,128,0.3) 100%)",
    background: "#dfdfdf",
  },
  potential: {
    color: "#999900",
    gradient:
      "linear-gradient(90deg, rgba(153,153,0,1) 0%, rgba(153,153,0,0.3) 100%)",
    background: "#dfdfdf",
  },
  secondEvaluator: {
    color: "#74a18e",
    gradient:
      "linear-gradient(90deg, rgba(116,161,142,1) 0%, rgba(116,161,142,0.3) 100%)",
    background: "#dfdfdf",
  },
  thirdEvaluator: {
    color: "#df4c4c",
    gradient:
      "linear-gradient(90deg, rgba(223,76,76,1) 0%, rgba(223,76,76,0.3) 100%)",
    background: "#dfdfdf",
  },
  desiredEvaluation: {
    color: "#e4de64",
    gradient:
      "linear-gradient(90deg, rgba(228,222,100,1) 0%, rgba(228,222,100,0.3) 100%)",
    background: "#dfdfdf",
  },
  averageEvaluation: {
    color: "#13a755",
    gradient:
      "linear-gradient(90deg, rgba(43,114,75,1) 0%, rgba(43,114,75,0.3) 100%)",
    background: "#dfdfdf",
  },
  finalEvaluation: {
    color: "#859eff",
    gradient:
      "linear-gradient(90deg, rgba(133,158,255,1) 0%, rgba(133,158,255,0.3) 100%)",
    background: "#dfdfdf",
  },
  gap: {
    color: "#ff6347",
    gradient:
      "linear-gradient(90deg, rgba(255,99,71,1) 0%, rgba(255,99,71,0.3) 100%)",
    background: "#dfdfdf",
  },
  invertedGap: {
    color: "#4bf397",
    gradient:
      "linear-gradient(90deg, rgba(75,243,151,1) 0%, rgba(75,243,151,0.3) 100%)",
    background: "#dfdfdf",
  },
  complementaryWorkflow: {
    color: "#00b050",
    gradient:
      "linear-gradient(90deg, rgba(0,176,80,1) 0%, rgba(0,176,80,0.3) 100%)",
    background: "#dfdfdf",
  },
};

export const getFilterStageInfo = (
  evaluationStage,
  evaluationType,
  evaluationConfig
) => {
  const evaluationStageOptions = getEvaluationStagesOptions(
    evaluationType,
    evaluationConfig,
    false,
    true
  );
  return getObjectInfoById(evaluationStage, evaluationStageOptions, "value");
};

export const getRelativeEvaluationStage = (
  evaluationStage,
  evaluationType,
  evaluationConfig,
  enableEvaluatorsSelectionWorkflow
) => {
  if (
    enableEvaluatorsSelectionWorkflow &&
    evaluationStage === "SELECTION_EVALUATORS"
  ) {
    return {
      next: getFilterStageInfo("VALIDATION", evaluationType, evaluationConfig),
      previous: false,
    };
  }

  if (evaluationStage === "VALIDATION")
    return {
      next: getFilterStageInfo(
        "SELF_EVALUATION",
        evaluationType,
        evaluationConfig
      ),
      previous: enableEvaluatorsSelectionWorkflow
        ? getFilterStageInfo(
            "SELECTION_EVALUATORS",
            evaluationType,
            evaluationConfig
          )
        : false,
    };

  if (evaluationStage === "SELF_EVALUATION")
    return {
      next: getFilterStageInfo("MANAGERS", evaluationType, evaluationConfig),
      previous: getFilterStageInfo(
        "VALIDATION",
        evaluationType,
        evaluationConfig
      ),
    };

  if (evaluationStage === "MANAGERS")
    return {
      next:
        evaluationType === "TYPE_360"
          ? getFilterStageInfo("EVALUATORS", evaluationType, evaluationConfig)
          : getFilterStageInfo("APPROVAL", evaluationType, evaluationConfig),
      previous: getFilterStageInfo(
        "SELF_EVALUATION",
        evaluationType,
        evaluationConfig
      ),
    };

  if (evaluationStage === "EVALUATORS")
    return {
      next: getFilterStageInfo("APPROVAL", evaluationType, evaluationConfig),
      previous: getFilterStageInfo(
        "MANAGERS",
        evaluationType,
        evaluationConfig
      ),
    };

  if (evaluationStage === "APPROVAL")
    return {
      next: getFilterStageInfo("CONCLUDED", evaluationType, evaluationConfig),
      previous: getFilterStageInfo(
        "MANAGERS",
        evaluationType,
        evaluationConfig
      ),
    };

  if (evaluationStage === "CONCLUDED") {
    return {
      next: null,
      previous: getFilterStageInfo(
        "APPROVAL",
        evaluationType,
        evaluationConfig
      ),
    };
  }

  return { next: false, previous: false };
};

export const checkParticipantCanSeeEvaluatorsNote = (transparency) => {
  return transparency;
};

export const enabledFieldsOnWorkflow = (
  evaluationStage,
  roleRelation,
  enableSecondEvaluator,
  enableThirdEvaluator,
  enablePotential,
  transparency,
  loggedUser,
  participant
) => {
  const blockAndDisabled = {
    disabled: true,
    alwaysBlock: true,
  };

  const { isAdmin, isParticipant, isManager, isSecEval, isThrEval } =
    getAccessRelation(roleRelation, loggedUser, participant);

  const canUserSeeEvaluatorsNote =
    isManager ||
    isAdmin ||
    (isParticipant && checkParticipantCanSeeEvaluatorsNote(transparency));

  const managerOrAdmOrParticipant = isManager || isAdmin || isParticipant;

  const hideSelfEval = !managerOrAdmOrParticipant;

  const cantEditSelfEval =
    hideSelfEval || evaluationStage !== "SELF_EVALUATION" || isManager;

  const hideManagerEval = !canUserSeeEvaluatorsNote;

  const cantEditManagerEval =
    hideManagerEval || !canManagersUpdate(evaluationStage);

  const hidePotential = !enablePotential || !canUserSeeEvaluatorsNote;

  const cantEditPotential =
    hidePotential || !canManagersUpdate(evaluationStage);

  const hideSecond =
    !enableSecondEvaluator || (!canUserSeeEvaluatorsNote && !isSecEval);

  const cantEditSecond =
    hideSecond || !isSecEval || evaluationStage !== "EVALUATORS";

  const hideThird =
    !enableThirdEvaluator || (!canUserSeeEvaluatorsNote && !isThrEval);

  const cantEditThird =
    hideThird || !isThrEval || evaluationStage !== "EVALUATORS";

  const isAverageStage =
    ["APPROVAL", "CONCLUDED"].indexOf(evaluationStage) > -1;

  const hideAverage = !managerOrAdmOrParticipant || !isAverageStage;

  const hideFinalEvaluation = true;

  return {
    selfEvaluation: { hide: hideSelfEval, disabled: cantEditSelfEval },
    managerEvaluation: { hide: hideManagerEval, disabled: cantEditManagerEval },
    potential: { hide: hidePotential, disabled: cantEditPotential },
    secondEvaluator: { hide: hideSecond, disabled: cantEditSecond },
    thirdEvaluator: { hide: hideThird, disabled: cantEditThird },
    averageEvaluation: { hide: hideAverage, ...blockAndDisabled },
    finalEvaluation: { hide: hideFinalEvaluation, ...blockAndDisabled },
  };
};

export const getEvaluatorActionsOnWorkflowStage = (
  evaluationStage,
  managerConfig,
  roleRelation,
  loggedUser,
  participant
) => {
  const { isAdmin, isParticipant, isManager, isSecEval, isThrEval } =
    getAccessRelation(roleRelation, loggedUser, participant);

  const { selfEvaluationAccess, secondEvaluatorAccess, thirdEvaluatorAccess } =
    managerConfig;

  const isManagerOrAdm = isAdmin || isManager;

  if (
    evaluationStage === "SELECTION_EVALUATORS" ||
    evaluationStage === "VALIDATION" ||
    evaluationStage === "SELF_EVALUATION"
  ) {
    return {
      enableManagerUpdate: isAdmin || (isParticipant && selfEvaluationAccess),
      enableSecEvaluatorUpdate:
        isAdmin || (isParticipant && secondEvaluatorAccess),
      enableTrdEvaluatorUpdate:
        isAdmin || (isParticipant && thirdEvaluatorAccess),
      nextStage: isManagerOrAdm || isParticipant,
      prevStage: isManagerOrAdm || isParticipant,
    };
  }

  if (evaluationStage === "MANAGERS") {
    return {
      enableManagerUpdate: isAdmin || (isManager && selfEvaluationAccess),
      enableSecEvaluatorUpdate: isAdmin || (isManager && secondEvaluatorAccess),
      enableTrdEvaluatorUpdate: isAdmin || (isManager && thirdEvaluatorAccess),
      nextStage: isManagerOrAdm,
      prevStage: isManagerOrAdm,
    };
  }

  if (evaluationStage === "EVALUATORS") {
    return {
      enableManagerUpdate: false,
      enableSecEvaluatorUpdate: false,
      enableTrdEvaluatorUpdate: false,
      nextStage: isSecEval || isThrEval || isAdmin,
      prevStage: isSecEval || isThrEval || isAdmin,
    };
  }

  if (evaluationStage === "APPROVAL") {
    return {
      enableManagerUpdate: false,
      enableSecEvaluatorUpdate: false,
      enableTrdEvaluatorUpdate: false,
      nextStage: isManagerOrAdm,
      prevStage: isManagerOrAdm,
    };
  }

  if (["CONCLUDED"].indexOf(evaluationStage) > -1) {
    return {
      enableManagerUpdate: false,
      enableSecEvaluatorUpdate: false,
      enableTrdEvaluatorUpdate: false,
      nextStage: isManagerOrAdm,
      prevStage: isManagerOrAdm,
    };
  }

  return defaultEnabledWorkflowActions;
};

export const getValidationFieldsForWorkflowStage = (
  evaluationStage,
  evaluationType
) => {
  const { enableSecondEvaluator, enableThirdEvaluator } =
    getEvaluationConfigByType(evaluationType);

  const enableBoth = enableSecondEvaluator && enableThirdEvaluator;

  if (evaluationStage === "SELF_EVALUATION")
    return { workflow: ["selfEvaluation"] };

  if (evaluationStage === "MANAGERS")
    return { workflow: ["managerEvaluation", "potential"] };

  if (evaluationStage === "EVALUATORS" && enableBoth)
    return { workflow: ["secondEvaluator", "thirdEvaluator"] };

  if (evaluationStage === "EVALUATORS" && enableSecondEvaluator)
    return { workflow: ["secondEvaluator"] };

  return [];
};

export const workflowCommentTags = (skill) => {
  const tag = `WK_SKILL_${skill.id}`;

  if (skill) return { all: [tag], tag, slice: 8 };

  return { all: [], tag: null };
};

export const complementaryWorkflowCommentTags = (workflowInfo) => {
  const tag = `WK_COMPLEMENTARY_${workflowInfo.id}`;
  if (workflowInfo) return { all: [tag], tag, slice: 16 };

  return { all: [], tag: null };
};

export const getSuccessionEvaluationInfo = (complementaryEvaluation) => {
  const { title } = complementaryEvaluation;

  if (title === "SUCCESSION_QUESTION_1")
    return {
      displayTitle:
        "Você considera necessário ter um sucessor desenvolvido para esta posição?",
      workflowValueSlug: "alternativeValueBool",
      scaleLabel: "Escolha: SIM/NÃO",
    };
  if (title === "SUCCESSION_QUESTION_2")
    return {
      displayTitle: "Já existe sucessor identificado para esta posição?",
      workflowValueSlug: "alternativeValueBool",
      scaleLabel: "Escolha: SIM/NÃO",
    };
  if (title === "SUCCESSION_QUESTION_3")
    return {
      displayTitle:
        "Quem você identifica que pode ser o sucessor desta posição?",
      workflowValueSlug: "alternativeValueUser",
      scaleLabel: "Campo Aberto: Lista de Usuários",
    };
  if (title === "SUCCESSION_QUESTION_4")
    return {
      displayTitle: "Tempo de Prontidão",
      workflowValueSlug: "alternativeValueString",
      scaleLabel: "Escolha: PRONTO/ CURTO PRAZO/ MÉDIO PRAZO/ LONGO PRAZO",
    };

  return { displayTitle: "", workflowValueSlug: "" };
};

export const getComplementaryEvaluationTitleByType = (
  complementaryEvaluation,
  type
) => {
  const { title } = complementaryEvaluation;
  if (type !== "SUCCESSION") return title;

  return getSuccessionEvaluationInfo(complementaryEvaluation).displayTitle;
};

export const getDefaultSuccessionScale = (complementaryEvaluations) => {
  const scale = [];

  const successionQuestions = getComplementaryEvaluationsByType(
    complementaryEvaluations,
    "SUCCESSION"
  );
  successionQuestions.forEach((complementaryEvaluation) => {
    const { scaleLabel } = getSuccessionEvaluationInfo(complementaryEvaluation);
    scale.push({ label: scaleLabel, evaluationId: complementaryEvaluation.id });
  });

  return scale;
};

export const getComplementaryEvaluationTypeByConfigSlug = (slug) => {
  if (slug === "performance" || slug === "keySeat" || slug === "succession")
    return "MANAGERS";
  return null;
};
