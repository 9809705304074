import React, { useState, useEffect } from "react";
import { defineMessages } from "react-intl";
import { OverlayTrigger, Row, Col } from "react-bootstrap";
import { Tooltip } from "@material-ui/core";
import _ from "lodash";
import { ruleOfThree } from "../../../../../../../utils/MathUtils";
import MultipleLineChart from "./Utils/MultipleLineChart";
import BarChart from "./Utils/BarChart";
import FormDialog from "../../../../../../Common/FormDialog";
import Avatar from "../../../../../../Common/Avatar";
import {
  getPopoverByProject,
  getPopoverLineChart,
  getPopoverBarChart,
  getStatusReportPopover,
  getStatusQuoPopover,
  getStageParentInfo,
} from "../../../../../../../utils/projectUtils";

import {
  getSchedulePercentage,
  getStatusProjectByClassName,
} from "../../../../../../../utils/projectEvaluation";
import MaterialMultiSelect from "../../../../../../Common/MaterialMultiSelect";

const messages = defineMessages({
  milestones: {
    id: "pport_table_milestone",
  },
  effort: {
    id: "tool.project.effort",
  },
  schedule: {
    id: "tool.project.date_schedule",
  },
  financial: {
    id: "tool.project.financial",
  },
  monthlyPerformance: {
    id: "tool.project.monthlyPerformance",
  },
  projects: {
    id: "tool.project.projects",
  },
  planned: {
    id: "tool.project.reportPlanned",
  },
  real: {
    id: "tool.project.reportReal",
  },
  financialAdvance: {
    id: "tool.project.financial_progress",
  },
  physicialAdvance: {
    id: "tool.project.physical_progress",
  },
  spent: {
    id: "tool.project.spent",
  },
  budget: {
    id: "tool.project.budget",
  },
  advance: {
    id: "tool.project.advance",
  },
  replannedProject: {
    id: "tool.project.replannedProject",
  },
  status: {
    id: "global.status",
  },
  scope: {
    id: "tool.project.scope",
  },
  cost: {
    id: "global.cost",
  },
  delivery: {
    id: "tool.project.delivery",
  },
  exceeded: {
    id: "tool.project.exceeded",
  },
  balance: {
    id: "tool.project.balance",
  },
  stage: {
    id: "tool.project.stage",
  },
  selectResponsible: {
    id: "select.responsible",
  },
});

const ReportMap = ({
  intl,
  reportMapData,
  reportSubstages,
  reportResponsibles,
  selectAndOpenInsight,
}) => {
  const [data, setData] = useState([]);
  const [openZoomChartModal, setOpenZoomChartModal] = useState(false);
  const [zoomChartModal, setZoomChartModal] = useState({
    advance: "",
    isBarChart: false,
    callModal: false,
  });

  useEffect(() => {
    const equalStates = _.isEqual(data, reportMapData);
    if (!equalStates) setData(reportMapData);
  }, [reportMapData]);

  useEffect(() => {
    if (zoomChartModal.callModal) {
      setOpenZoomChartModal(true);
    }
  }, [zoomChartModal]);

  const handleHideModal = () => {
    setZoomChartModal({
      ...zoomChartModal,
      advance: "",
      callModal: false,
    });
    setOpenZoomChartModal(false);
  };

  const displayZoomChartModal = () => {
    const isBarChart =
      zoomChartModal && zoomChartModal.isBarChart
        ? zoomChartModal.isBarChart
        : null;
    const advance =
      zoomChartModal && zoomChartModal.advance ? zoomChartModal.advance : null;

    const modalTitle = isBarChart
      ? intl.formatMessage(messages.physicialAdvance)
      : intl.formatMessage(messages.financialAdvance);

    return (
      <FormDialog
        open={openZoomChartModal}
        title={modalTitle}
        onCancel={() => handleHideModal()}
        onConfirm={() => handleHideModal()}
        bodyStyle={{
          padding: "15px 15px 15px 15px",
        }}
        hideConfirm
      >
        <div>
          {isBarChart ? (
            <BarChart modalDisplay barChartData={advance} />
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>
                  <span className="blueDot" />{" "}
                  {intl.formatMessage(messages.spent)}
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <span className="redDot" />{" "}
                  {intl.formatMessage(messages.budget)}
                </div>
              </div>
              <MultipleLineChart modalDisplay lineChartData={advance} />
            </>
          )}
        </div>
      </FormDialog>
    );
  };

  const mountSubHeaders = (customClass, type = "", isSchedule = false) => {
    return (
      <>
        {isSchedule && (
          <td align="center" className="reportMapSecondHeader">
            <div className={customClass}>Baseline</div>
          </td>
        )}

        <td align="center" className="reportMapSecondHeader">
          <div className={customClass}>
            {!isSchedule ? intl.formatMessage(messages.planned) : "Deadline"}
          </div>
        </td>
        <td align="center" className="reportMapSecondHeader">
          <div className={customClass}>{intl.formatMessage(messages.real)}</div>
        </td>
        {type === "financeiro" && (
          <>
            <td align="center" className="reportMapSecondHeader ">
              <div className={customClass}>
                {intl.formatMessage(messages.exceeded)}
              </div>
            </td>
            <td
              align="center"
              className={`reportMapSecondHeader greyBackground ${customClass}`}
            >
              <div className={customClass}>
                {intl.formatMessage(messages.balance)}
              </div>
            </td>
          </>
        )}

        <td align="center" className="reportMapSecondHeader">
          <div className={customClass}>
            {intl.formatMessage(messages.advance)}
          </div>
        </td>
      </>
    );
  };

  const renderTableHead = () => {
    return (
      <thead>
        <tr>
          <td align="center" className="reportMapHeader" colSpan="1">
            <strong>Info</strong>
          </td>
          <td align="center" className="reportMapHeader" colSpan="4">
            <OverlayTrigger
              placement="right"
              overlay={getStatusQuoPopover(intl)}
            >
              <div>
                <strong>{intl.formatMessage(messages.status)}</strong>
              </div>
            </OverlayTrigger>
          </td>
          <td align="center" className="reportMapHeader" colSpan="3">
            <strong>{intl.formatMessage(messages.milestones)}</strong>
          </td>
          <td align="center" colSpan="4" className="reportMapHeader">
            <strong>{intl.formatMessage(messages.schedule)}</strong>
          </td>
          <td align="center" colSpan="5" className="reportMapHeader">
            <strong>{intl.formatMessage(messages.financial)}</strong>
          </td>
        </tr>
      </thead>
    );
  };

  const getPercentage = (planned = 0, real = 0) => {
    return Math.round(ruleOfThree(planned, real));
  };

  const handleShowFullChart = (project, isBarChart) => {
    const advance = isBarChart
      ? project.physicalAdvance
      : project.financialAdvance;

    setZoomChartModal({
      ...zoomChartModal,
      advance,
      isBarChart,
      callModal: true,
    });
  };

  const showPhysicalProgress = (physicalProgress = 0) => {
    let realProgress = physicalProgress;
    if (!physicalProgress || physicalProgress === null) realProgress = 0;

    const customColorClass = {
      class: "physicalColor progress-bar progress-bar-animated",
    };

    return (
      <div className="physicalProgressCol2">
        <div className="progress progressProject2">
          <div
            className={customColorClass.class}
            role="progressbar"
            style={{
              width: `${realProgress.toString()}%`,
            }}
            aria-valuenow={realProgress}
            aria-valuemin="0"
            aria-valuemax="100"
          />
          <div align="right" className="insideDivProgress fix-13pxFont">
            {`${realProgress}%`}
          </div>
        </div>
      </div>
    );
  };

  const renderTableBody = () => {
    return (
      <tbody>
        <tr className="border-bottom-division">
          <td align="center" className="greyBackground">
            <div className="fix-250">
              {intl.formatMessage(messages.projects)}
            </div>
          </td>
          <td align="center" className="greyBackground">
            <div className="fix-70">{intl.formatMessage(messages.stage)}</div>
          </td>
          <td align="center" className="greyBackground">
            <Tooltip title="Escopo" placement="bottom">
              <div className="fix-20">
                <i className="fas fa-list" />
              </div>
            </Tooltip>
          </td>
          <td align="center" className="greyBackground">
            <Tooltip title="Prazo">
              <div className="fix-20">
                <i className="fas fa-clock" />
              </div>
            </Tooltip>
          </td>
          <td align="center" className="greyBackground">
            <Tooltip title="Custo" placement="bottom">
              <div className="fix-20">
                <i className="fas fa-dollar-sign" />
              </div>
            </Tooltip>
          </td>
          {mountSubHeaders("fix-70", "milestones")}
          {mountSubHeaders("fix-70", null, true)}
          {mountSubHeaders("fix-70", "financeiro")}
        </tr>
        {data.map((project, index) => {
          const schedule = project.schedule || {};
          const {
            scopeDotClass = "",
            statusClass = "",
            financialStatusDot = "",
            exceeded,
            balance,
            physicalProgress = 0,
            projectStage = false,
            evaluation = {},
            statusReport,
          } = project || {};

          const { projectEvaluation = {} } = evaluation || {};
          const { answer = {} } = projectEvaluation || {};
          const { projectStageRelation = {} } = projectEvaluation || {};

          const { parentDescription = false, sonDescription = false } =
            getStageParentInfo(projectStageRelation);

          return (
            <tr key={index} className="noPadding executiveReportLines">
              <td align="center" className="projectNameTd">
                <OverlayTrigger
                  placement="top"
                  overlay={getStatusReportPopover(statusReport, intl)}
                >
                  <div
                    className="projectNameStyle"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Avatar
                      src={projectEvaluation.avatar}
                      name={projectEvaluation.responsibleName}
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "5px",
                      }}
                    />
                    {project.replanned && (
                      <OverlayTrigger
                        placement="right"
                        overlay={getPopoverByProject(
                          project.evaluation,
                          null,
                          intl,
                        )}
                      >
                        <div style={{ marginRight: "5px" }}>
                          <i className="fas fa-flag" />
                        </div>
                      </OverlayTrigger>
                    )}
                    <div
                      className="fix-13pxFont"
                      style={{ cursor: "pointer" }}
                      onClick={() => selectAndOpenInsight(answer.id)}
                    >
                      {project.projectName}
                    </div>
                  </div>
                </OverlayTrigger>
              </td>
              <td align="center" className="fix-13pxFont">
                <Row className="fix-13pxFont">
                  {projectStage || parentDescription || sonDescription}
                </Row>
                {parentDescription && (
                  <Row className="fix-11pxFont" title={sonDescription}>
                    {sonDescription}
                  </Row>
                )}
              </td>
              <td align="center">
                <div className="advanceColumnConfig">
                  <Tooltip title={getStatusProjectByClassName(scopeDotClass)}>
                    <span className={scopeDotClass} />
                  </Tooltip>
                </div>{" "}
              </td>
              <td align="center">
                <div className="advanceColumnConfig">
                  <Tooltip title={getStatusProjectByClassName(statusClass)}>
                    <span className={statusClass} />
                  </Tooltip>
                </div>{" "}
              </td>
              <td align="center">
                <div className="advanceColumnConfig">
                  <Tooltip
                    title={getStatusProjectByClassName(financialStatusDot)}
                  >
                    <span className={financialStatusDot} />
                  </Tooltip>
                </div>{" "}
              </td>
              <td align="center">
                {project.activities && project.activities.planned
                  ? project.activities.planned
                  : ""}
              </td>
              <td align="center">
                {project.activities && project.activities.realized
                  ? project.activities.realized
                  : ""}
              </td>
              <td
                align="center"
                style={{
                  backgroundColor: "#efefef",
                }}
                className="cursorPointer zeroPadding"
                onClick={() => handleShowFullChart(project, true)}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={getPopoverBarChart(project, intl)}
                >
                  <div className="advanceColumnConfig">
                    {showPhysicalProgress(physicalProgress)}
                  </div>
                </OverlayTrigger>
              </td>
              <td align="center">
                {schedule && schedule.baseline ? (
                  <div
                    className="scheduleEllipsis fix-13pxFont"
                    title={schedule.baseline}
                  >
                    {schedule.baseline}
                  </div>
                ) : null}
              </td>
              <td align="center">
                {schedule && schedule.deadline ? (
                  <div
                    className="scheduleEllipsis fix-13pxFont"
                    title={schedule.deadline}
                  >
                    {schedule.deadline}
                  </div>
                ) : null}
              </td>
              <td align="center">
                {project.schedule && project.schedule.realized ? (
                  <div
                    className="scheduleEllipsis fix-13pxFont"
                    title={project.schedule.realized}
                  >
                    {project.schedule.realized}
                  </div>
                ) : (
                  ""
                )}
              </td>
              <td
                align="center"
                className="fix-13pxFont"
                style={{
                  backgroundColor: "#efefef",
                }}
              >
                {getSchedulePercentage(project.schedule)}%
              </td>
              <td
                align="center"
                className="fix-13pxFont"
                style={{ borderLeft: "1px solid #ccc" }}
              >
                {project.investment && project.investment.planned
                  ? project.investment.planned
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  : ""}
              </td>
              <td align="center" className="fix-13pxFont">
                {project.investment && project.investment.realized
                  ? project.investment.realized
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  : ""}
              </td>
              <td align="center" className="fix-13pxFont">
                {exceeded
                  ? exceeded
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  : ""}
              </td>
              <td align="center" className="fix-13pxFont">
                {balance
                  ? balance.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  : ""}
              </td>
              <td
                align="center"
                style={{
                  backgroundColor: "#efefef",
                }}
                className="cursorPointer"
                onClick={() => handleShowFullChart(project, false)}
              >
                <OverlayTrigger
                  placement="left"
                  overlay={getPopoverLineChart(project, intl)}
                >
                  <div className="advanceColumnConfig fix-13pxFont">
                    {getPercentage(
                      project &&
                        project.investment &&
                        project.investment.planned
                        ? project.investment.planned
                        : "",
                      project &&
                        project.investment &&
                        project.investment.realized
                        ? project.investment.realized
                        : "",
                    )}
                    %
                  </div>
                </OverlayTrigger>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  const responsibleOptions = reportResponsibles.map(
    ({ id, responsibleName }) => {
      return { value: id, text: responsibleName };
    },
  );

  const responsibleInitialOptions = [
    ...responsibleOptions.map(({ value }) => value),
  ];

  const substageOptions = reportSubstages.map(({ id, substageName }) => {
    return { value: id, text: substageName };
  });

  const substageInitialOptions = [...substageOptions.map(({ value }) => value)];

  const handleResponsibles = (selectedOptions = []) => {
    const filteredData = reportMapData.filter(({ evaluation = {} }) => {
      const { projectEvaluation = {} } = evaluation || {};
      const { answer = {} } = projectEvaluation || {};
      const { responsibleMember = {} } = answer || {};
      const { id = false } = responsibleMember || {};

      if (selectedOptions.indexOf(id) > -1) return true;
    });

    setData(filteredData);
  };

  const essentialInfo = ({
    projectStageRelation = {},
    projectStage = false,
  }) => {
    const { description = false, parent = {} } = projectStageRelation || {};

    const { description: parentDescription = false } = parent || {};

    const name = description || projectStage || false;

    if (!name) return {};

    const parentName = parentDescription
      ? parentDescription?.toUpperCase()
      : false;

    return { name: name?.toUpperCase(), parentName };
  };

  const handleSubstages = (selectedOptions = []) => {
    if (selectedOptions.length === 0) return setData(reportMapData);

    const filteredData = reportMapData.filter(({ evaluation = {} }) => {
      const { projectEvaluation = {} } = evaluation || {};

      const { name = false, parentName = false } =
        essentialInfo(projectEvaluation);

      if (!name) return false;

      let include = false;

      selectedOptions.forEach((option) => {
        const splittedOption = option.split("[separator]");

        const newName = splittedOption[0] || "0";
        const newParentName = splittedOption[1] || "0";

        if (newParentName !== "0") {
          if (newName === name) include = true;
          return;
        }

        if (
          newName === name ||
          newName === parentName ||
          newParentName === name ||
          newParentName === parentName
        )
          include = true;
      });

      return include;
    });

    setData(filteredData);
  };

  return (
    <div>
      <Row>
        <Col md={4} className="projectResponsibleSelect">
          <MaterialMultiSelect
            allButton
            selectAll
            setDefaultValue
            id="responsiblesSelector"
            options={responsibleOptions}
            initialOptions={responsibleInitialOptions}
            label={intl.formatMessage(messages.selectResponsible)}
            getData={(selectedOptions, teste) =>
              handleResponsibles(selectedOptions, teste)
            }
          />
        </Col>
        <Col md={4} className="projectSubstageSelect">
          <MaterialMultiSelect
            allButton
            selectAll
            setDefaultValue
            id="substageSelector"
            options={substageOptions}
            initialOptions={substageInitialOptions}
            label="Selecione um subestágio"
            getData={(selectedOptions) => handleSubstages(selectedOptions)}
          />
        </Col>
      </Row>
      <div className="executiveReportTablesContainer">
        <div className="reportMapfixedScroll">
          <table
            style={{ border: "1px solid #ccc" }}
            className="table table-striped simple-table"
          >
            {renderTableHead()}
            {renderTableBody()}
          </table>
        </div>
      </div>
      {displayZoomChartModal()}
    </div>
  );
};

export default ReportMap;
