import { useState, useEffect } from "react";
import _ from "lodash";
import { getParticipantComplementaryWorkflow } from "../../../../../../../../utils/skillsAssessment/filter";
import { getParticipantCalculatedWorkflow } from "../../../../../../../../utils/skillsAssessment/calc";
import {
  orderArrayByObjAttr,
  getObjectDifference,
} from "../../../../../../../../utils/ArrayUtils";

export function useWorkflowChanges({ workflow = [], historyWorkflow = [] }) {
  const [changes, setChanges] = useState([]);

  useEffect(() => {
    const newChanges = [];
    const diff = getObjectDifference(workflow, historyWorkflow);

    Object.keys(diff).forEach((objKey) => newChanges.push(objKey));

    if (!_.isEqual(newChanges, changes)) setChanges(newChanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow, historyWorkflow]);

  return { changes, haveChanges: changes.length > 0 };
}

export const useSelectedWorkflowParticipant = ({
  toolAdministration,
  selectedParticipantId,
}) => {
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [participantWorkflow, setParticipantWorkflow] = useState([]);
  const [historyWorkflow, setHistoryWorkflow] = useState([]);
  const [
    participantComplementaryWorkflow,
    setParticipantComplementaryWorkflow,
  ] = useState([]);
  const [historyComplementaryWorkflow, setHistoryComplementaryWorkflow] =
    useState([]);
  const { haveChanges } = useWorkflowChanges({
    workflow: participantWorkflow,
    historyWorkflow,
  });
  const { haveChanges: haveComplementaryChanges } = useWorkflowChanges({
    workflow: participantComplementaryWorkflow,
    historyWorkflow: historyComplementaryWorkflow,
  });

  function resetSelectedParticipant() {
    setSelectedParticipant(null);
    setParticipantWorkflow([]);
    setHistoryWorkflow([]);
    setParticipantComplementaryWorkflow([]);
    setHistoryComplementaryWorkflow([]);
  }

  const handleParticipantWorkflow = (participant) => {
    const calculatedWorkflow = getParticipantCalculatedWorkflow(
      toolAdministration,
      participant,
    );
    const displayWorkflow = calculatedWorkflow.map((workflowInfo) => {
      const { skill } = workflowInfo;
      const { competenceArea } = skill;

      return {
        ...workflowInfo,
        competenceArea,
      };
    });

    const filteredComplementaryWorkflow = getParticipantComplementaryWorkflow(
      toolAdministration.complementaryWorkflow,
      participant,
    );

    const orderedWorkflow = orderArrayByObjAttr(
      displayWorkflow,
      "competenceArea",
      "title",
    );

    setParticipantWorkflow(orderedWorkflow);
    setHistoryWorkflow(orderedWorkflow);
    setParticipantComplementaryWorkflow(filteredComplementaryWorkflow);
    setHistoryComplementaryWorkflow(filteredComplementaryWorkflow);
  };

  function handleSelectParticipant(participant) {
    if (participant) {
      setSelectedParticipant(participant);
      handleParticipantWorkflow(participant);
    } else {
      resetSelectedParticipant();
    }
  }

  useEffect(() => {
    if (selectedParticipantId) {
      const participant = toolAdministration.participants.find(
        (participant) => participant.id === selectedParticipantId,
      );
      handleSelectParticipant(participant);
    } else {
      handleSelectParticipant(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParticipantId]);

  function syncHistory() {
    setHistoryWorkflow(participantWorkflow);
    setHistoryComplementaryWorkflow(participantComplementaryWorkflow);
  }

  return {
    selectedParticipant,
    participantWorkflow,
    setParticipantWorkflow,
    historyWorkflow,
    participantComplementaryWorkflow,
    setParticipantComplementaryWorkflow,
    historyComplementaryWorkflow,
    handleSelectParticipant,
    haveChanges: haveChanges || haveComplementaryChanges,
    syncHistory,
  };
};
