import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import {
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  Checkbox,
  Select,
  OutlinedInput,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import _ from "lodash";
import { getValidFunc } from "../../utils/validations";
import { usePrevious } from "../../utils/customHooks";

const styles = (theme) => ({
  inputLabel: {
    fontSize: "16px",
  },
  inputSelect: {
    fontSize: "16px",
    "& > div > div": {
      height: "22px",
      lineHeight: "30px",
    },
  },
  menuItem: {
    paddingLeft: "10px",
    paddingRight: "10px",
    "& > div > span": {
      fontSize: "14px",
    },
  },
});

const messages = defineMessages({
  selectAll: {
    id: "global.selectAll",
  },
  unselectAll: {
    id: "global.unselectAll",
  },
  globalSelect: {
    id: "global.select",
  },
});

const MaterialMultiSelect = (props) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [displayValues, setDisplayValues] = useState([]);
  const [labelWidth, setLabelWidth] = useState(0);
  const [firstMount, setFirstMount] = useState(false);

  const prevProps = usePrevious(props);
  const isDiffProps = useCallback(
    (slug) => {
      if ((!prevProps && props) || (prevProps && !props)) return true;
      if (
        !prevProps[slug] ||
        !props[slug] ||
        !_.isEqual(prevProps[slug], props[slug])
      ) {
        return true;
      }

      return false;
    },
    [props, prevProps],
  );

  const {
    initialOptions = [],
    options = [],
    selectAll,
    setDefaultValue,
    label,
    id,
    fromRef,
    classes,
    allButton,
    intl,
    getDefaultWorkspace,
    size,
  } = props;

  const getData = getValidFunc(props.getData);

  useEffect(() => {
    const anyChanged =
      isDiffProps("options") ||
      isDiffProps("initialOptions") ||
      isDiffProps("selectAll") ||
      isDiffProps("setDefaultValue");

    if (anyChanged) {
      let finalSelectedValues = !firstMount ? selectedValues : [];

      const restartInitialOptions =
        !_.isEmpty(initialOptions) && isDiffProps("initialOptions");

      const updateSelectedOptions =
        !restartInitialOptions && (selectAll || setDefaultValue);

      if (restartInitialOptions) {
        finalSelectedValues = initialOptions;
        setFirstMount(true);
      }

      if (updateSelectedOptions) {
        finalSelectedValues = getDefaultOption(options, selectAll);
      }

      if (
        !_.isEqual(finalSelectedValues, selectedValues) &&
        (restartInitialOptions ||
          (updateSelectedOptions && isDiffProps("options")))
      ) {
        setSelectedValues(finalSelectedValues);

        const textList = buildRenderOptionsText(finalSelectedValues, options);
        if (!_.isEqual(textList, displayValues)) setDisplayValues(textList);

        getData(finalSelectedValues, fromRef);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, initialOptions, selectAll, setDefaultValue]);

  useLayoutEffect(() => {
    if (label) {
      const multiplier = 8.5;
      const labelElement = document.getElementById(`multiSelectLabel${id}`);
      const newWidth = label.length * multiplier;

      if (labelElement) setLabelWidth(newWidth);
    }
  }, [id, label]);

  const getDefaultOption = (opts, getAll) => {
    let defaultVal = [];

    function setDefaultVal(value) {
      defaultVal = [...defaultVal, value];
    }

    function setByComparison(compare = false, value) {
      return compare ? setDefaultVal(value) : false;
    }

    opts.forEach(({ value }, index) => {
      if (getDefaultWorkspace) {
        setByComparison(getDefaultWorkspace === value, value);
      } else {
        setByComparison(index === 0 || getAll, value);
      }
    });

    return defaultVal;
  };

  const buildRenderOptionsText = (selected, newOptions = options) => {
    let displayValues = [];

    // Adiciona texto correspondente com itens selecionados para ser renderizado
    newOptions.forEach(({ value, text }) => {
      if (selected.indexOf(value) > -1)
        displayValues = [...displayValues, text];
    });

    return displayValues;
  };

  const isAllOptionsSelected = () => {
    return selectedValues.length === options.length;
  };

  const handleFilterChange = (event) => {
    let doSelectAll = false;
    let eventSelectedOptions = event.target.value;

    for (const eventOpt of eventSelectedOptions) {
      if (eventOpt === "doSelectAll") doSelectAll = true;
    }

    if (doSelectAll && !isAllOptionsSelected()) {
      eventSelectedOptions = [...options.map(({ value }) => value)];
    } else if (doSelectAll) {
      eventSelectedOptions = [];
    }

    let displayValues = buildRenderOptionsText(eventSelectedOptions);

    getData(eventSelectedOptions, fromRef); // Callback to Parent Component
    setSelectedValues(eventSelectedOptions);
    setDisplayValues(displayValues);
  };

  const ITEM_HEIGHT = 54;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    disableEnforceFocus: true,
  };

  const selectAllButton = options.length > 0 && allButton && (
    <MenuItem
      key="doSelectAlll"
      value="doSelectAll"
      className={classes.menuItem}
    >
      <Checkbox
        checked={selectedValues.length === options.length}
        color="primary"
      />
      <ListItemText
        primary={
          isAllOptionsSelected()
            ? intl.formatMessage(messages.unselectAll)
            : intl.formatMessage(messages.selectAll)
        }
      />
    </MenuItem>
  );

  return (
    <FormControl
      fullWidth
      className={classes.formControl}
      disabled={!options.length || options.length === 0}
      margin="normal"
      variant="outlined"
      size={size}
    >
      <InputLabel
        htmlFor={id}
        className={classes.inputLabel}
        id={`multiSelectLabel${id}`}
      >
        {label}
      </InputLabel>
      <Select
        multiple
        value={selectedValues}
        onChange={handleFilterChange}
        input={<OutlinedInput id={id} labelWidth={labelWidth} />}
        renderValue={() =>
          displayValues.length > 0
            ? displayValues.join(", ")
            : `- ${intl.formatMessage(messages.globalSelect)} -`
        }
        MenuProps={MenuProps}
        className={classes.inputSelect}
      >
        {selectAllButton}
        {options.map(({ value, text }, index) => (
          <MenuItem key={index} value={value} className={classes.menuItem}>
            <Checkbox
              checked={selectedValues.indexOf(value) > -1}
              color="primary"
            />
            <ListItemText primary={text} />
          </MenuItem>
        ))}
        {options.length > 5 && selectAllButton}
      </Select>
    </FormControl>
  );
};

MaterialMultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setDefaultValue: PropTypes.bool,
  selectAll: PropTypes.bool,
  getData: PropTypes.func.isRequired,
  fromRef: PropTypes.string,
  allButton: PropTypes.bool,
  initialOptions: PropTypes.array,
  size: PropTypes.oneOf(["small", "medium"]),
};

MaterialMultiSelect.defaultProps = {
  options: [],
  initialOptions: [],
  allButton: true,
  setDefaultValue: false,
  selectAll: false,
  fromRef: "",
  size: "small",
};

export default injectIntl(withStyles(styles)(MaterialMultiSelect));
