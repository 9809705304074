import React from "react";
import moment from "moment";
import { defineMessages } from "react-intl";
import { Popover } from "react-bootstrap";
import { addError, isHeadersValidated, getOnlyLines } from "../../utils";
import { translatedText } from "../../../../../utils/translationUtils";
import { displayTargets } from "../../../../../utils/kpis/display";
import {
  getOnlySlugValues,
  aggregationByAtt,
  getObjectInfoById,
} from "../../../../../utils/ArrayUtils";
import { getMeasurementUnityOptions } from "../../../../../constants/goalsAndBonus";
import {
  getOrderedTargets,
  getPeriodTargetsByDistribution,
} from "../../../../../utils/kpis/calc2";
import kpiUtils from "../../../../../utils/kpis";
import { orderByIndexes } from "../../../../../utils/kpis/filter";

const { getEvaluationDivision, getPeriodDivisionTitle } = kpiUtils;

const messages = defineMessages({
  measu_editable: {
    id: "measu_editable",
  },
  measu_example_format: {
    id: "measu_example_format",
  },
  measu_no: {
    id: "measu_no",
  },
  measu_yes: {
    id: "measu_yes",
  },
  measu_invalid_doc: {
    id: "measu_invalid_doc",
  },
  measu_invalid_year: {
    id: "measu_invalid_year",
  },
  measu_invalid_frequency: {
    id: "measu_invalid_frequency",
  },
  measu_invalid_actual_value: {
    id: "measu_invalid_actual_value",
  },
  measu_invalid_last_period_value: {
    id: "measu_invalid_last_period_value",
  },
  year: {
    id: "global.year",
  },
  indicator: {
    id: "global.indicator",
  },
  measurement: {
    id: "global.measurement",
  },
  period: {
    id: "kpi_table_text_period",
  },
  lastPeriod: {
    id: "kpi_table_text_prev_period",
  },
  gate: {
    id: "kpi_table_text_gate",
  },
  appropriated: {
    id: "kpi_table_text_appropriated",
  },
  exceeded: {
    id: "kpi_table_text_exceeded",
  },
  actual_value: {
    id: "global.actualValue",
  },
  rule: {
    id: "global.rule",
  },
});

const translation = (id) => translatedText(id, messages);

export const getHeaders = () => {
  return [
    { header: "goalId", key: "goalId", hidden: true },
    { header: "periodIndex", key: "periodIndex", hidden: true },
    { header: "indicator", key: "indicator", width: 32 },
    { header: "measurementUnity", key: "measurementUnity", width: 15 },
    { header: "year", key: "year", width: 10 },
    { header: "frequency", key: "frequency", width: 25 },
    { header: "gate", key: "gate", width: 20 },
    { header: "appropriated", key: "appropriated", width: 15 },
    { header: "exceeded", key: "exceeded", width: 20 },
    { header: "lastPeriod", key: "lastPeriod", width: 20 },
    { header: "realValue", key: "realValue", width: 20 },
  ];
};

export const getFakeHeader = () => {
  return {
    indicator: translation("indicator"),
    measurementUnity: translation("measurement"),
    year: translation("year"),
    frequency: translation("period"),
    gate: translation("gate"),
    appropriated: translation("appropriated"),
    exceeded: translation("exceeded"),
    lastPeriod: translation("lastPeriod"),
    realValue: translation("actual_value"),
  };
};

export const getStaticHeader = () => {
  return {
    goalId: "",
    periodIndex: "",
    custom: translation("rule"),
    ...getFakeHeader(),
  };
};

export const getExampleBody = () => {
  return [
    {
      custom: translation("measu_editable"),
      indicator: translation("measu_no"),
      measurementUnity: translation("measu_no"),
      year: translation("measu_no"),
      frequency: translation("measu_no"),
      gate: translation("measu_no"),
      appropriated: translation("measu_no"),
      exceeded: translation("measu_no"),
      lastPeriod: translation("measu_yes"),
      realValue: translation("measu_yes"),
    },
    {
      custom: translation("measu_example_format"),
      indicator: "",
      measurementUnity: "",
      year: "",
      frequency: "",
      gate: "",
      appropriated: "",
      exceeded: "",
      lastPeriod: ["MM/DD/AAAA", "50"],
      realValue: ["MM/DD/AAAA", "50"],
    },
  ];
};

export const handleErrorMessage = (errorType = "") => {
  let customBody = "";

  switch (errorType) {
    case "header":
      customBody = translation("measu_invalid_doc");
      break;
    case "goalId":
      customBody = translation("measu_invalid_doc");
      break;
    case "periodIndex":
      customBody = translation("measu_invalid_doc");
      break;
    case "year":
      customBody = translation("measu_invalid_year");
      break;
    case "frequency":
      customBody = translation("measu_invalid_frequency");
      break;
    case "lastPeriod":
      customBody = translation("measu_invalid_actual_value");
      break;
    case "realValue":
      customBody = translation("measu_invalid_last_period_value");
      break;
    default:
      break;
  }

  return (
    <Popover
      id="popoverInformation"
      title="Descrição"
      style={{ zIndex: "99999" }}
    >
      {customBody}
    </Popover>
  );
};

const measurementUnityOptions = getMeasurementUnityOptions();
const measurementStructure = getOnlySlugValues(
  measurementUnityOptions,
  "value",
);

export const updateGoal = (dataBaseGoal = {}, importedGoalValues = []) => {
  const updatedGoal = dataBaseGoal;

  importedGoalValues.forEach(
    ({
      measurementUnity = "",
      realValue = "",
      lastPeriod = "",
      periodIndex,
    }) => {
      let actualValue = realValue;
      let lastPeriodValue = lastPeriod;

      if (measurementUnity === "DATE") {
        actualValue = moment(realValue).format("YYYYMMDD");
        lastPeriodValue = moment(lastPeriod).format("YYYYMMDD");
      }

      if (measurementUnity === "DATE")
        actualValue = moment(realValue).format("YYYYMMDD");

      updatedGoal.target = dataBaseGoal.target;
      updatedGoal.target.lastPeriod[periodIndex] = {
        id: updatedGoal.target.lastPeriod[periodIndex].id,
        index: periodIndex,
        value: String(lastPeriodValue) || null,
      };
      updatedGoal.target.actual[periodIndex] = {
        id: updatedGoal.target.actual[periodIndex].id,
        index: periodIndex,
        value: String(actualValue) || null,
      };
    },
  );
  return updatedGoal;
};

const handleEvaluationMassUpdate = (
  updateData = [],
  { selectedToolId, administration },
) => {
  const aggregatedData = aggregationByAtt("goalId", updateData);

  if (administration?.selectedToolId === selectedToolId && aggregatedData) {
    const { id: administrationId = "", goals = [] } = administration || {};

    const newGoals = orderByIndexes(goals);

    Object.keys(aggregatedData).forEach((goalId) => {
      const indexOf = getObjectInfoById(Number(goalId), newGoals, "id", true);
      if (indexOf > -1)
        newGoals[indexOf] = updateGoal(
          newGoals[indexOf],
          aggregatedData[goalId],
        );
    });

    return { administrationId, selectedToolId, goals: newGoals };
  }

  return {};
};

export const handleMassUpdate = (
  updateData = {},
  { selectedToolId, administration },
) => {
  const { sheet0 = [] } = updateData || {};

  return handleEvaluationMassUpdate(sheet0, {
    selectedToolId,
    administration,
  });
};

const validateRealValue = (measurementUnity = "", realValue = "") => {
  const stringRealVal = String(realValue);
  const dateformat = /^(0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])[/-]\d{4}$/;

  if (
    measurementUnity === "DATE" &&
    stringRealVal.match(dateformat) &&
    stringRealVal.match(dateformat)[0]
  )
    return true;

  if (measurementStructure.indexOf(measurementUnity) > -1 && !isNaN(realValue))
    return true;

  return false;
};

const validationLineByLine = (data = []) => {
  let validatedData = [];
  let nonValidatedData = [];

  data.forEach((line) => {
    const { measurementUnity = "", realValue = "", periodIndex } = line || {};

    if (!validateRealValue(measurementUnity, realValue)) {
      nonValidatedData = addError(nonValidatedData, "realValue", line);
      return;
    }

    if (periodIndex !== 0 && !periodIndex > 0) {
      nonValidatedData = addError(nonValidatedData, "frequency", line);
      return;
    }

    validatedData = [...validatedData, { ...line }];
  });

  return { validatedData, nonValidatedData };
};

export const overallValidation = (imported = [], administration = {}) => {
  let validatedData = [];
  let nonValidatedData = [];

  const { goals = [] } = administration;
  const orderedGoals = orderByIndexes(goals);

  Object.keys(imported).forEach((goalId) => {
    //Retorna o id, ano, frequencia
    const originalGoal = getObjectInfoById(Number(goalId), orderedGoals, "id");
    const importedGoalData = imported[goalId];

    // O ID da meta existe no banco?
    if (originalGoal?.id) {
      const lines = getOnlyLines(importedGoalData);

      lines.forEach((line) => {
        if (!line?.goalId)
          nonValidatedData = addError(nonValidatedData, "goalId", line);
      });

      Object.keys(importedGoalData).forEach((year) => {
        // O Ano existe no banco?
        if (importedGoalData[year]) {
          const arrayToValidate = importedGoalData[year];

          // Validação Frequencia e tipagem da Meta
          const {
            validatedData: validatedLines,
            nonValidatedData: nonValidatedLines,
          } = validationLineByLine(arrayToValidate);

          validatedData = [...validatedData, ...validatedLines];
          nonValidatedData = [...nonValidatedData, ...nonValidatedLines];
          return;
        }

        nonValidatedData = addError(nonValidatedData, "year");
      });
      return;
    }

    nonValidatedData = addError(nonValidatedData, "goalId");
  });

  return { validatedData, nonValidatedData };
};

export const handleEvaluationValidations = (
  imported = [],
  administration = {},
) => {
  // Validações Macro -  Todas as Ferramentas
  // Validação de Header
  if (!isHeadersValidated(imported, getStaticHeader()))
    return { nonValidatedData: [{ errorType: "header" }], validatedData: [] };

  // Agregação
  const aggregatedData = aggregationByAtt("goalId", imported);

  Object.keys(aggregatedData).forEach((goalId) => {
    aggregatedData[goalId] = aggregationByAtt("year", aggregatedData[goalId]);
  });

  const { validatedData = [], nonValidatedData = [] } = overallValidation(
    aggregatedData,
    administration,
  );

  return {
    nonValidatedData,
    validatedData,
  };
};

export const handleValidation = (
  { data: sheetsInfo = {} },
  { administration = {} },
) => {
  const { sheet0 = {} } = sheetsInfo || {};
  const { data: imported = [] } = sheet0;
  const evaluationInfo = handleEvaluationValidations(imported, administration);

  return {
    validated: {
      sheet0: [...evaluationInfo.validatedData],
    },
    nonValidated: {
      sheet0: [...evaluationInfo.nonValidatedData],
    },
  };
};

const getFormattedByFrequency = (
  goalDescriptions = {},
  value = "",
  isRealValue = false,
) => {
  const { measurementUnity = "" } = goalDescriptions;

  let newValue = getEvaluationDivision(value, goalDescriptions);

  if (measurementUnity === "DATE") {
    if (isRealValue) {
      if (moment(value).isValid())
        return moment(value.toString()).format("MM/DD/YYYY");

      return "";
    }

    return moment(newValue.toString()).format("MM/DD/YYYY");
  }

  if (isRealValue) newValue = value;

  return newValue;
};

export const getCSVDataFormat = (allAdministration = []) => {
  let data = [];

  allAdministration.forEach(({ goals }) => {
    const orderedGoals = orderByIndexes(goals);

    orderedGoals.forEach((goalDescriptions) => {
      const {
        id: goalId,
        title: indicator,
        measurementUnity,
        period,
        target,
        relatedInsights,
      } = goalDescriptions;

      if (relatedInsights.length > 0) {
        const { lastPeriod = [], distribution = [] } = target || {};
        const { year } = period || {};

        const { actual: actualTargetValues } =
          getOrderedTargets(goalDescriptions);

        actualTargetValues.forEach(({ value, index }, fakeIndex) => {
          const { value: lastPeriodV } = lastPeriod[fakeIndex];
          const { value: distributionV } = distribution[fakeIndex];

          const periodTargetsDivision = getPeriodTargetsByDistribution(
            index,
            goalDescriptions,
          );

          const gate = displayTargets(
            periodTargetsDivision.gate,
            goalDescriptions.measurementUnity,
          );

          const appropriated = displayTargets(
            periodTargetsDivision.appropriated,
            goalDescriptions.measurementUnity,
          );

          const exceeded = displayTargets(
            periodTargetsDivision.exceeded,
            goalDescriptions.measurementUnity,
          );

          data = [
            ...data,
            {
              goalId,
              periodIndex: index,
              indicator,
              measurementUnity,
              year,
              frequency: getPeriodDivisionTitle(
                index,
                fakeIndex,
                goalDescriptions,
              ),
              gate,
              appropriated,
              exceeded,
              distribution: getFormattedByFrequency(
                goalDescriptions,
                distributionV,
                true,
              ),
              lastPeriod: getFormattedByFrequency(
                goalDescriptions,
                lastPeriodV,
                true,
              ),
              realValue: getFormattedByFrequency(goalDescriptions, value, true),
            },
          ];
        });
      }
    });
  });

  return data;
};

export const filterConfigBySelectedToolId = (
  goalsAndBonusAdm = [],
  selectedToolId = "",
) => {
  if (Array.isArray(goalsAndBonusAdm)) {
    return goalsAndBonusAdm.filter(
      ({ selectedToolId: currentToolId }) => currentToolId === selectedToolId,
    );
  }

  return [];
};

export const getKPIsDependencies = (
  allAdministration = [],
  selectedToolId = "",
) => {
  let dataToExport = {};
  let exampleBody = {};

  const filteredKPIs = filterConfigBySelectedToolId(
    allAdministration,
    selectedToolId,
  );

  const rowsConfig = [{ line: 2, fill: "eeeeee" }];

  const staticHeader = getStaticHeader();

  dataToExport = {
    sheet0: {
      rowsConfig,
      staticHeader,
      headers: getHeaders(),
      fakeHeader: getFakeHeader(),
      data: getCSVDataFormat(filteredKPIs),
    },
  };

  const dateTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");

  exampleBody = {
    sheet0: {
      title: "",
      headers: staticHeader,
      data: getExampleBody(),
    },
  };

  return {
    dataToExport,
    headers: getHeaders(),
    fakeHeader: getFakeHeader(),
    filename: `KPIs-${dateTime}`,
    additionalData: {
      administration: filteredKPIs[0],
      selectedToolId,
    },
    staticHeader: getStaticHeader(),
    exampleBody,
    handleValidation,
    handleMassUpdate,
    handleErrorMessage,
  };
};
