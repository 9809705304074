import React from "react";
import { Fab, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useButtonStyles = makeStyles(theme => ({
  fab: {
    width: ({ widths }) => widths,
    height: ({ widths }) => widths,
    cursor: ({ cursor }) => cursor,
    color: ({ contrast = "#222" }) => `${contrast} !important`,
    backgroundColor: ({ color = "#ddd" }) => `${color} !important`,
    border: ({ borderColor = "#ccc" }) => `1px solid ${borderColor} !important`,
    boxShadow: "none",
    "& > span > i": {
      color: ({ contrast = "#222" }) => `${contrast} !important`,
      fontSize: ({ textSize = "16px " }) => `${textSize} !important`
    },
    "& > span > svg": {
      color: ({ contrast = "#222" }) => `${contrast} !important`,
      fontSize: ({ textSize = "16px " }) => `${textSize} !important`
    },
    "&:hover": {
      backgroundColor: ({ blockHover, color, hoverColor = "#ccc" }) =>
        blockHover ? `${color} !important` : `${hoverColor} !important`,
      color: ({ blockHover, contrast, hoverContrast = "#222" }) =>
        blockHover ? `${contrast} !important` : `${hoverContrast} !important`,
      "& > span > svg": {
        color: ({ hoverContrast }) => `${hoverContrast} !important`
      },
      border: ({ color = "#ccc" }) => `1px solid ${color} !important`
    }
  },
  tooltip: {
    fontSize: "14px !important",
    fontWeight: "bold",
    whiteSpace: "no-wrap"
  },
  popper: {
    display: ({ hideTooltip }) => (hideTooltip ? "none" : "block")
  }
}));

const FabActionButton = props => {
  const { text, label = null, size = "small", variant = "round" } = props;

  const buttonDefaultSize =
    size === "small" ? "40px" : size === "large" ? "56px" : "48px";

  const classes = useButtonStyles({
    color: props.color,
    contrast: props.contrast,
    textSize: props.textSize,
    hoverColor: props.hoverColor,
    hoverContrast: props.hoverContrast,
    borderColor: props.borderColor,
    blockHover: props.blockHover,
    hideTooltip: props.label ? false : true,
    widths: props.widths || buttonDefaultSize,
    cursor: props.cursor || "pointer"
  });
  let { onClick } = props;

  onClick = typeof onClick === "function" ? onClick : () => {};

  return (
    <Tooltip
      title={label || ""}
      classes={{ tooltip: classes.tooltip, popper: classes.popper }}
    >
      <Fab
        size={size}
        className={classes.fab}
        onClick={onClick}
        variant={variant}
      >
        {text}
      </Fab>
    </Tooltip>
  );
};

export default FabActionButton;
