import _ from "lodash";
import { getValidId } from "../validations";
import {
  getCpAnalysisToolSlugs,
  defaultCompanyDetails,
} from "../../constants/competitiveAnalysis";
import {
  getPestalFactorTranslation,
  getSwotFactorTranslation,
} from "./display";
import { removeItemInArray } from "../ArrayUtils";
import { ruleOfThree } from "../MathUtils";

export const verifyScaleSlugs = (scales = []) => {
  return scales.filter(({ title }) => title !== "").length === 5;
};

export const getEmptyConfiguration = (
  evaluationCriterias = [],
  scales = [],
) => {
  const scaleConfiguration = verifyScaleSlugs(scales);

  if (evaluationCriterias.length > 0 && scaleConfiguration) return false;

  return true;
};

export const getInsightStructure = (evaluationSubLevel = []) => {
  let syncEvaluation = {};

  evaluationSubLevel.forEach((evaluation) => {
    const { evaluationCriteriaId: criteriaId = "", scaleId = "" } = evaluation;

    syncEvaluation = { ...syncEvaluation, [criteriaId]: scaleId };
  });

  return syncEvaluation;
};

export const getPestalEvaluations = (insightEvaluation = {}) => {
  const { pestal = [] } = insightEvaluation;

  return getInsightStructure(pestal);
};

export const getSwotEvaluations = (insightEvaluation = {}) => {
  const { swot = [], competitiveAnalysisGroup = {} } = insightEvaluation || {};
  const { id: analysisGroupId = "" } = competitiveAnalysisGroup || {};

  return {
    criteria: getInsightStructure(swot),
    analysisGroupId,
  };
};

export const getPrioritizationEvaluations = (insightEvaluation = {}) => {
  const { prioritization = [] } = insightEvaluation;

  return getInsightStructure(prioritization);
};

export const getConcurrenceEvaluations = (insightEvaluation = {}) => {
  const { concurrence = [] } = insightEvaluation;

  return getInsightStructure(concurrence);
};

export const getCompanyDetails = (insightEvaluation = {}) => {
  const { companyDetails = false } = insightEvaluation;

  if (companyDetails) return companyDetails;

  return defaultCompanyDetails;
};

export const getAnalysisCriterias = (
  administration = {},
  currentToolType = "",
) => {
  const { evaluationCriterias = [] } = administration[currentToolType] || {};

  return evaluationCriterias;
};

export const getAnalysisScales = (
  administration = {},
  currentToolType = "",
) => {
  const { scales = [] } = administration[currentToolType] || {};

  return scales;
};

export const getAnalysisGroups = (
  administration = {},
  currentToolType = "",
) => {
  const { analysisGroups = [] } = administration[currentToolType] || {};

  return analysisGroups;
};

export const getPestalEvaluationFields = (
  administration = {},
  currentToolType = "",
) => {
  return {
    evaluationCriterias: getAnalysisCriterias(administration, currentToolType),
    scales: getAnalysisScales(administration, currentToolType),
  };
};

export const getPrioritizationEvaluationFields = (
  administration = {},
  currentToolType = "",
) => {
  return {
    evaluationCriterias: getAnalysisCriterias(administration, currentToolType),
    scales: getAnalysisScales(administration, currentToolType),
  };
};

export const getConcurrenceEvaluationFields = (
  administration = {},
  currentToolType = "",
) => {
  return {
    evaluationCriterias: getAnalysisCriterias(administration, currentToolType),
    scales: getAnalysisScales(administration, currentToolType),
  };
};

export const getSwotEvaluationFields = (
  administration = {},
  currentToolType = "",
) => {
  return {
    evaluationCriterias: getAnalysisCriterias(administration, currentToolType),
    scales: getAnalysisScales(administration, currentToolType),
    analysisGroups: getAnalysisGroups(administration, currentToolType),
  };
};

export const getCurrentAnalysisTool = (
  cpAnalysisAdministration = {},
  competitiveAnalysisId = "",
) => {
  let currentAnalysisTool = {};

  const competitiveAnalysisToolNames = getCpAnalysisToolSlugs();

  competitiveAnalysisToolNames.forEach((toolSlug) => {
    const currentCompetitiveTool = cpAnalysisAdministration[toolSlug] || {};

    const { id = false } = currentCompetitiveTool;

    if (id === competitiveAnalysisId)
      currentAnalysisTool = currentCompetitiveTool;
  });

  return { structure: currentAnalysisTool };
};

export const getPatternFactors = (factors = []) => {
  return factors.map((factor) => {
    return { type: factor };
  });
};

export const getPatternCriterias = (criterias = []) => {
  return criterias.map((criteria) => {
    return { title: criteria };
  });
};

export const getPestalPatternCriterias = (pestal = {}) => {
  const { evaluationCriterias = [], id = false } = pestal;

  if (id || evaluationCriterias.length > 0) return evaluationCriterias;

  return getPatternCriterias(["IMPACT", "IMPORTANCE", "RELIABILITY"]);
};

export const getSwotPatternCriterias = (swot = {}) => {
  const { evaluationCriterias = [], id = false } = swot;

  if (id || evaluationCriterias.length > 0) return evaluationCriterias;

  return getPatternCriterias(["IMPACT", "IMPORTANCE", "RELIABILITY"]);
};

export const getPrioritizationPatternCriterias = (prioritization = {}) => {
  const { evaluationCriterias = [], id = false } = prioritization;

  if (id || evaluationCriterias.length > 0) return evaluationCriterias;

  return getPatternCriterias(["GRAVITY", "URGENCY", "TREND"]);
};

export const getConcurrencePatternCriterias = (concurrence = {}) => {
  const { evaluationCriterias = [], id = false } = concurrence;

  if (id || evaluationCriterias.length > 0) return evaluationCriterias;

  return getPatternCriterias([
    "PRICE",
    "SERVICES",
    "COVERAGE",
    "TECHNOLOGY",
    "INVESTMENT",
  ]);
};

export const getPestalPatternFactors = (pestal = {}) => {
  const { factors = [], id = false } = pestal;

  if (id || factors.length > 0) return factors;

  return getPatternFactors([
    "POLITICAL",
    "ECONOMICAL",
    "SOCIAL",
    "TECHNOLOGICAL",
    "AMBIENTAL",
    "LEGAL",
  ]);
};

export const getSwotPatternFactors = (swot = {}) => {
  const { factors = [], id = false } = swot;

  if (id || factors.length > 0) return factors;

  return getPatternFactors([
    "STRONG_POINTS",
    "WEAK_POINTS",
    "OPPORTUNITIES",
    "THREATS",
  ]);
};

export const getPestalScaleStructure = (pestal = {}) => {
  return getScaleStructure(pestal, [
    "VERY_LOW",
    "LOW",
    "MEDIUM",
    "HIGH",
    "VERY_HIGH",
  ]);
};

export const getSwotScaleStructure = (swot = {}) => {
  return getScaleStructure(swot, [
    "VERY_LOW",
    "LOW",
    "MEDIUM",
    "HIGH",
    "VERY_HIGH",
  ]);
};

export const getPrioritizationScaleStructure = (prioritization = {}) => {
  return getScaleStructure(prioritization, [
    "VERY_LOW",
    "LOW",
    "MEDIUM",
    "HIGH",
    "VERY_HIGH",
  ]);
};

export const getConcurrenceScaleStructure = (concurrence = {}) => {
  return getScaleStructure(concurrence, [
    "NO_COMPETITIVE",
    "LOW_COMPETITIVENESS",
    "MEDIUM_COMPETITIVENESS",
    "COMPETITIVE",
    "DIFFERENTIATED_AND_COMPETITIVE",
    "LEADER",
  ]);
};

export const getScaleStructure = (field = {}, structure = []) => {
  const { scales = [] } = field;

  if (scales.length > 0) {
    const newScales = [...scales].sort((a, b) => {
      if (a.id) return Number(a.id) > Number(b.id) ? 1 : -1;

      return Number(a.value) > Number(b.value) ? 1 : -1;
    });

    return newScales.map((scale, index) => {
      return { ...scale, value: index + 1 };
    });
  }

  const info = structure?.map((title, index) => {
    return { title, value: index + 1 };
  });

  return info;
};

export const buildFormAdmConfigBody = (restData) => {
  return cpAnalysisAdmBuilder(_.cloneDeep(restData), "", []);
};

export const buildRestAdmConfigBody = (administrationData) => {
  return cpAnalysisAdmBuilder(_.cloneDeep(administrationData), null, []);
};

export const cpAnalysisFieldBuilder = (
  field = {},
  defaultStringValue = "",
  defaultArrayValue = [],
  defaultIdValue = null,
  autoType = false,
) => {
  field = field || {};

  let {
    id = "",
    type = "",
    analysisAmbient = defaultArrayValue,
    analysisGroups = defaultArrayValue,
    analysisAreas = defaultArrayValue,
    evaluationCriterias = defaultArrayValue,
    scales = defaultArrayValue,
    factors = defaultArrayValue,
  } = field;

  id = getValidId(id, defaultIdValue);

  type = type || autoType || defaultStringValue;

  for (const key in analysisAmbient) {
    analysisAmbient[key].id = getValidId(analysisAmbient[key].id);
    analysisAmbient[key].title =
      analysisAmbient[key].title || defaultStringValue;
  }

  for (const key in analysisGroups) {
    analysisGroups[key].id = getValidId(analysisGroups[key].id);
    analysisGroups[key].title = analysisGroups[key].title || defaultStringValue;
  }

  for (const key in analysisAreas) {
    analysisAreas[key].id = getValidId(analysisAreas[key].id);
    analysisAreas[key].title = analysisAreas[key].title || defaultStringValue;
  }

  for (const key in evaluationCriterias) {
    evaluationCriterias[key].id = getValidId(evaluationCriterias[key].id);
    evaluationCriterias[key].title =
      evaluationCriterias[key].title || defaultStringValue;
  }

  for (const key in scales) {
    scales[key].id = getValidId(scales[key].id);
    scales[key].title = scales[key].title || defaultStringValue;
    scales[key].value = scales[key].value || defaultStringValue;
  }

  for (const key in factors) {
    factors[key].id = getValidId(factors[key].id);
    factors[key].type = factors[key].type || defaultStringValue;
  }

  return {
    id,
    type,
    analysisAmbient,
    analysisGroups,
    analysisAreas,
    evaluationCriterias,
    scales,
    factors,
  };
};

export const getPestalPatternStructures = (pestal = {}) => {
  pestal.factors = getPestalPatternFactors(pestal);
  pestal.scales = getPestalScaleStructure(pestal);
  pestal.evaluationCriterias = getPestalPatternCriterias(pestal);

  return pestal;
};

export const getSwotPatternStructures = (swot = {}) => {
  swot.scales = getSwotScaleStructure(swot);
  swot.factors = getSwotPatternFactors(swot);
  swot.evaluationCriterias = getSwotPatternCriterias(swot);

  return swot;
};

export const getPrioritizationPatternStructures = (prioritization = {}) => {
  prioritization.scales = getPrioritizationScaleStructure(prioritization);
  prioritization.evaluationCriterias = getPrioritizationPatternCriterias(
    prioritization,
  );

  return prioritization;
};

export const getConcurrencePatternStructures = (concurrence = {}) => {
  concurrence.scales = getConcurrenceScaleStructure(concurrence);
  concurrence.evaluationCriterias = getConcurrencePatternCriterias(concurrence);

  return concurrence;
};

export const cpAnalysisAdmBuilder = (
  cpAnalysisAdministration,
  defaultStringValue = "",
  defaultArrayValue = [],
  defaultIdValue = null,
) => {
  cpAnalysisAdministration = cpAnalysisAdministration || {};

  let {
    id,
    selectedToolId,
    pestal = {},
    swot = {},
    prioritization = {},
    concurrence = {},
  } = cpAnalysisAdministration;

  id = getValidId(id, defaultIdValue);
  selectedToolId = getValidId(selectedToolId, defaultIdValue);

  swot = getSwotPatternStructures(swot);
  pestal = getPestalPatternStructures(pestal);
  prioritization = getPrioritizationPatternStructures(prioritization);
  concurrence = getConcurrencePatternStructures(concurrence);

  pestal = cpAnalysisFieldBuilder(
    pestal,
    defaultStringValue,
    defaultArrayValue,
    defaultIdValue,
    "PESTAL",
  );

  swot = cpAnalysisFieldBuilder(
    swot,
    defaultStringValue,
    defaultArrayValue,
    defaultIdValue,
    "SWOT",
  );

  prioritization = cpAnalysisFieldBuilder(
    prioritization,
    defaultStringValue,
    defaultArrayValue,
    defaultIdValue,
    "PRIORITIZATION",
  );

  concurrence = cpAnalysisFieldBuilder(
    concurrence,
    defaultStringValue,
    defaultArrayValue,
    defaultIdValue,
    "CONCURRENCE",
  );

  return {
    id,
    selectedToolId,
    pestal,
    swot,
    prioritization,
    concurrence,
  };
};

export const buildPatternTreeList = (all = {}) => {
  const list = [];

  Object.keys(all).map((id) => {
    all[id].map((entity) => {
      const { name = false, text = "" } = entity;

      list.push({
        id: entity.id,
        title: name || text,
        parentId: Number(id),
      });
    });
  });

  return list;
};

export const buildQuestionsTreeList = (
  allSceneries = [],
  allQuestions = {},
  allThemes = {},
) => {
  const list = [];

  const getScenerie = (scenerieId) => {
    return allSceneries.filter(
      (scenerie) => Number(scenerie.id) === Number(scenerieId),
    )[0];
  };

  Object.keys(allQuestions).map((id) => {
    let translationFunction = getPestalFactorTranslation;

    let scenerieId = null;

    const themesList = buildPatternTreeList(allThemes);

    themesList.forEach((theme) => {
      if (Number(theme.id) === Number(id)) scenerieId = theme.parentId;
    });

    const { name: currentCenaryName = "" } = getScenerie(scenerieId) || {};

    if (currentCenaryName === "SWOT")
      translationFunction = getSwotFactorTranslation;

    allQuestions[id].map((entity) => {
      list.push({
        id: entity.id,
        title: translationFunction(entity.text),
        parentId: Number(id),
      });
    });
  });

  return list;
};

export const getPestalFactorTypes = () => {
  return [
    "POLITICAL",
    "ECONOMICAL",
    "SOCIAL",
    "TECHNOLOGICAL",
    "AMBIENTAL",
    "LEGAL",
  ];
};

export const getSwotFactorTypes = () => {
  return ["STRONG_POINTS", "WEAK_POINTS", "OPPORTUNITIES", "THREATS"];
};

export const getPestalStructure = (pestalData = {}) => {
  if (Object.keys(pestalData).length === 0) return {};

  const factorTypes = getPestalFactorTypes();

  const { evaluationConfig: headers = {}, factors = {} } = pestalData || {};

  const politicalFactors =
    (factors[factorTypes[0]] && factors[factorTypes[0]][0]) || {};
  const economicalFactors =
    (factors[factorTypes[1]] && factors[factorTypes[1]][0]) || {};
  const socialFactors =
    (factors[factorTypes[2]] && factors[factorTypes[2]][0]) || {};
  const technologicalFactors =
    (factors[factorTypes[3]] && factors[factorTypes[3]][0]) || {};
  const ambientalFactors =
    (factors[factorTypes[4]] && factors[factorTypes[4]][0]) || {};
  const legalFactors =
    (factors[factorTypes[5]] && factors[factorTypes[5]][0]) || {};

  const allFactors = {
    politicalFactors:
      {
        ...politicalFactors,
        colorClass: "politicalFactorColor",
      } || {},
    economicalFactors:
      {
        ...economicalFactors,
        colorClass: "economicalFactorColor",
      } || {},
    socialFactors:
      {
        ...socialFactors,
        colorClass: "socialFactorColor",
      } || {},
    technologicalFactors:
      {
        ...technologicalFactors,
        colorClass: "technologicalFactorColor",
      } || {},
    ambientalFactors:
      {
        ...ambientalFactors,
        colorClass: "ambientalFactorColor",
      } || {},
    legalFactors:
      {
        ...legalFactors,
        colorClass: "legalFactorColor",
      } || {},
  };

  const factorsStructure = Object.keys(allFactors).map((factor) => {
    const {
      type: title = "",
      evaluations = [],
      result = 0,
      overallEvaluations = [],
      colorClass = "",
    } = allFactors[factor] || {};

    return {
      title,
      insights: evaluations.length || 0,
      result,
      overallEvaluations,
      colorClass,
    };
  });

  return {
    ...allFactors,
    headers,
    factorsStructure,
  };
};

export const getSwotStructure = (swotData = []) => {
  if (Object.keys(swotData).length === 0) return {};

  const factorTypes = getSwotFactorTypes();

  const { evaluationConfig: headers = {}, factors = [] } = swotData || {};

  const strongPoints =
    (factors[factorTypes[0]] && factors[factorTypes[0]][0]) || {};
  const weakPoints =
    (factors[factorTypes[1]] && factors[factorTypes[1]][0]) || {};
  const opportunities =
    (factors[factorTypes[2]] && factors[factorTypes[2]][0]) || {};
  const threats = (factors[factorTypes[3]] && factors[factorTypes[3]][0]) || {};

  const allFactors = {
    strongPoints,
    weakPoints,
    opportunities,
    threats,
  };

  return {
    ...allFactors,
    headers,
  };
};

export const getPrioritizationStructure = (prioritizationData = {}) => {
  const { evaluationConfig: headers = [], analysisAreas = [] } =
    prioritizationData || {};

  const areasStructure = analysisAreas.map((area) => {
    const {
      title = "",
      evaluations = [],
      result = 0,
      overallEvaluations = [],
    } = area || {};

    return {
      title,
      insights: evaluations.length || 0,
      result,
      overallEvaluations,
    };
  });

  return {
    headers,
    areasStructure,
    analysisArea: analysisAreas,
  };
};

export const removeAnalysisAmbientByInfo = (
  currentAdministration = {},
  payload = {},
) => {
  const { analysisAmbientId = "", currentToolType = "" } = payload;

  const updated = { ...currentAdministration };

  const { analysisAmbient = [] } = currentAdministration[currentToolType];

  updated[currentToolType].analysisAmbient = removeItemInArray(
    analysisAmbient,
    analysisAmbientId,
  );

  return updated;
};

export const removeAnalysisGroupByInfo = (
  currentAdministration = {},
  payload = {},
) => {
  const { analysisGroupId = "", currentToolType = "" } = payload;

  const updated = { ...currentAdministration };

  const { analysisGroups = [] } = currentAdministration[currentToolType];

  updated[currentToolType].analysisGroups = removeItemInArray(
    analysisGroups,
    analysisGroupId,
  );

  return updated;
};

export const removeAnalysisAreaByInfo = (
  currentAdministration = {},
  payload = {},
) => {
  const { analysisAreaId = "", currentToolType = "" } = payload;

  const updated = { ...currentAdministration };

  const { analysisAreas = [] } = currentAdministration[currentToolType];

  updated[currentToolType].analysisAreas = removeItemInArray(
    analysisAreas,
    analysisAreaId,
  );

  return updated;
};

export const removeEvaluationCriteriaByInfo = (
  currentAdministration = {},
  payload = {},
) => {
  const { evaluationCriteriaId = "", currentToolType = "" } = payload;

  const updated = { ...currentAdministration };

  const { evaluationCriterias = [] } = currentAdministration[currentToolType];

  updated[currentToolType].evaluationCriterias = removeItemInArray(
    evaluationCriterias,
    evaluationCriteriaId,
  );

  return updated;
};

const findCurrentToolType = (evaluation = {}) => {
  const {
    swot = [],
    pestal = [],
    prioritization = [],
    concurrence = [],
  } = evaluation;

  if (pestal.length > 0) return "pestal";
  if (prioritization.length > 0) return "prioritization";
  if (concurrence.length > 0) return "concurrence";
  if (swot.length > 0) return "swot";

  return false;
};

export const getMaxValue = (evaluations = []) => {
  let maxValue = 0;

  evaluations.forEach(({ totalPoints = 0 }) => {
    if (totalPoints > maxValue) maxValue = totalPoints;
  });

  return maxValue;
};

export const getConcurrenceMaxValue = (questionInsights = []) => {
  const filteredQuestionInsights = questionInsights.filter(
    ({ type = "" }) => type === "concurrence",
  );

  return getMaxValue(filteredQuestionInsights);
};

export const getPestalMaxValue = (questionInsights = []) => {
  const filteredQuestionInsights = questionInsights.filter(
    ({ type = "" }) => type === "pestal",
  );

  return getMaxValue(filteredQuestionInsights);
};

export const getSwotMaxValue = (questionInsights = []) => {
  const filteredQuestionInsights = questionInsights.filter(
    ({ type = "" }) => type === "swot",
  );

  return getMaxValue(filteredQuestionInsights);
};
export const getPrioritizationMaxValue = (questionInsights = []) => {
  const filteredQuestionInsights = questionInsights.filter(
    ({ type = "" }) => type === "prioritization",
  );

  return getMaxValue(filteredQuestionInsights);
};

export const handlePointsByType = ({
  evaluations,
  currentToolType,
  concurrenceMaxValue,
  pestalMaxValue,
  swotMaxValue,
  prioritizationMaxValue,
}) => {
  if (currentToolType === "concurrence")
    return ruleOfThree(concurrenceMaxValue, evaluations);
  if (currentToolType === "pestal")
    return ruleOfThree(pestalMaxValue, evaluations);
  if (currentToolType === "swot") return ruleOfThree(swotMaxValue, evaluations);
  if (currentToolType === "prioritization")
    return ruleOfThree(prioritizationMaxValue, evaluations);
};

export const getMaxValues = (essentialInfo = {}) => {
  const questionInsightsArray = [];

  Object.keys(essentialInfo).forEach((questionName) => {
    const questionInsightsInfo = essentialInfo[questionName] || {};

    questionInsightsArray.push(questionInsightsInfo);
  });

  const concurrenceMaxValue = getConcurrenceMaxValue(questionInsightsArray);
  const pestalMaxValue = getPestalMaxValue(questionInsightsArray);
  const swotMaxValue = getSwotMaxValue(questionInsightsArray);
  const prioritizationMaxValue = getPrioritizationMaxValue(
    questionInsightsArray,
  );

  return {
    concurrenceMaxValue,
    pestalMaxValue,
    swotMaxValue,
    prioritizationMaxValue,
  };
};

export const handleCompetitiveCard = (allEvaluations = []) => {
  let newSwot = {};
  let newPestal = {};
  let newPriori = [];
  let newConcurrence = [];

  let swotBiggerValue = 0;
  let pestalBiggerValue = 0;
  let prioriBiggerValue = 0;
  let concurrenceBiggerValue = 0;

  allEvaluations.map((evaluation) => {
    const {
      pestal = [],
      swot = [],
      prioritization = [],
      concurrence = {},
    } = evaluation;

    const { evaluations = [] } = concurrence || {};

    swot.forEach((line) => {
      const { points, questionName = "", questionInsights } = line || {};

      const lowerQuestionName =
        typeof questionName === "string" ? questionName.toLowerCase() : "";

      const { points: currentPoints = 0, questionInsights: currentQI = 0 } =
        newSwot[lowerQuestionName] || {};

      const pointsSum = currentPoints + points;

      if (swotBiggerValue < pointsSum) swotBiggerValue = pointsSum;

      newSwot = {
        ...newSwot,
        [lowerQuestionName]: {
          questionName,
          points: pointsSum,
          questionInsights: currentQI + questionInsights,
        },
      };
    });

    pestal.forEach((line) => {
      const { points, questionName, questionInsights } = line || {};

      if (typeof questionName !== "string") return;
      const lowerQuestionName =
        typeof questionName === "string" ? questionName.toLowerCase() : "";

      const { points: currentPoints = 0, questionInsights: currentQI = 0 } =
        newPestal[lowerQuestionName] || {};

      const pointsSum = currentPoints + points;

      if (pestalBiggerValue < pointsSum) pestalBiggerValue = pointsSum;

      newPestal = {
        ...newPestal,
        [lowerQuestionName]: {
          questionName,
          points: pointsSum,
          questionInsights: currentQI + questionInsights,
        },
      };
    });

    prioritization.forEach((line) => {
      const { points, questionName, questionInsights } = line || {};

      if (prioriBiggerValue < points) prioriBiggerValue = points;

      newPriori = {
        ...newPriori,
        [questionName]: {
          points,
          questionName,
          questionInsights,
        },
      };
    });

    evaluations.forEach((line) => {
      const { points, insightName } = line || {};

      if (concurrenceBiggerValue < points) concurrenceBiggerValue = points;

      newConcurrence = {
        ...newConcurrence,
        [insightName]: {
          points,
          insightName,
        },
      };
    });
  });

  const {
    threats: newThreats = {},
    weak_points: newWeakPoints = {},
    strong_points: newStrongPoints = {},
    opportunities: newOpportunities = {},
  } = newSwot || {};

  const {
    legal: newLegal = {},
    social: newSocial = {},
    ambiental: newAmbiental = {},
    political: newPolitical = {},
    economical: newEconomical = {},
    technological: newTechnological = {},
  } = newPestal || {};

  return {
    analysisInfo: {
      swot: {
        strong_points: {
          ...(newStrongPoints || {}),
        },
        weak_points: {
          ...(newWeakPoints || {}),
        },
        opportunities: {
          ...(newOpportunities || {}),
        },
        threats: {
          ...(newThreats || {}),
        },
      },
      pestal: {
        political: { ...(newPolitical || {}) },
        economical: { ...(newEconomical || {}) },
        social: { ...(newSocial || {}) },
        technological: { ...(newTechnological || {}) },
        ambiental: { ...(newAmbiental || {}) },
        legal: { ...(newLegal || {}) },
      },
      prioritization: { ...(newPriori || {}) },
      concurrence: { ...(newConcurrence || {}) },
    },
    biggerValues: {
      swot: swotBiggerValue,
      pestal: pestalBiggerValue,
      prioritization: prioriBiggerValue,
      concurrence: concurrenceBiggerValue,
    },
  };
};
