import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import CountColorBadge from "./CountColorBadge";
import { getRelativePercentage, simpleSum } from "../../utils/MathUtils";

const TargetColumns = (props) => {
  const {
    list,
    showPercentage,
    height,
    lineHeight,
    fontSize,
    margin,
    boxWidth = "25%",
  } = props;

  const styles = { height, lineHeight, fontSize, margin };

  let total = 0;
  Object.keys(list).forEach((keyName) => {
    const keyInfo = list[keyName];

    total = simpleSum(total, keyInfo.count);
  });

  return (
    <div style={{ display: "flex" }}>
      {Object.keys(list).map((keyName) => {
        const keyInfo = list[keyName];

        const relativePercentage =
          total > 0
            ? `${getRelativePercentage(keyInfo.count, total, 2)}%`
            : "0%";

        const appendProps = {
          value: keyInfo.count || 0,
          borderColor: keyInfo.color,
          bottomInfo: showPercentage ? relativePercentage : null,
          width: "100%",
          ...styles,
        };

        const targetLabel = `${keyInfo.label} (${relativePercentage})`;

        return keyInfo.popover ? (
          <OverlayTrigger
            key={uuidv4()}
            id={`triggerTargetInfo${uuidv4()}`}
            placement="bottom"
            overlay={keyInfo.popover}
          >
            <div style={{ width: boxWidth, margin: "8px" }} title={targetLabel}>
              <CountColorBadge {...appendProps} />
            </div>
          </OverlayTrigger>
        ) : (
          <div
            key={uuidv4()}
            style={{ width: boxWidth, margin: "8px" }}
            title={targetLabel}
          >
            <CountColorBadge {...appendProps} />
          </div>
        );
      })}
    </div>
  );
};

TargetColumns.propTypes = {
  list: PropTypes.objectOf(
    PropTypes.shape({
      count: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      popover: PropTypes.node,
    }),
  ),
  showPercentage: PropTypes.bool,
  height: PropTypes.any,
  lineHeight: PropTypes.any,
  fontSize: PropTypes.any,
  margin: PropTypes.any,
};

TargetColumns.defaultProps = {
  list: [],
  showPercentage: false,
  height: "60px",
  lineHeight: "57px",
  fontSize: "26px",
  margin: "0px",
};

export default TargetColumns;
