import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";
import Chip from "@material-ui/core/Chip";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";

import moment from "moment";
import { saveInsightMultiConnections } from "../../actions/insightTraceabilitiesActions";
import { fetchGroupedComments } from "../../actions/insightTimelineActions";
import {
  getFilteredUsersByEntityRole,
  addInsightCollaborators,
} from "../../actions/accessLevelsActions";

import InsightTimelineContainer from "../Tool/InsightTimeline/InsightTimelineContainer";
import MaterialSingleSelect from "../Common/MaterialSingleSelect";
import SelectTraceabilityModal from "../Common/SelectTraceabilityModal";
import SelectMemberModal from "../Common/SelectMemberModal";
import Avatar from "../Common/Avatar";
import InsightFollowUpTable from "./InsightFollowUpTable";
import InsightGoalContainer from "./InsightGoalContainer";
import InsightTraceabilitiesContainer from "./InsightTraceabilitiesContainer";
import InsightAttachmentsContainer from "./InsightAttachmentsContainer";

import { formatDate, EXTENDED_DATE_TIME_FORMAT } from "../../utils/DateUtils";
import { globalMessages } from "../../utils/global";
import utils from "../../utils/toolUtils";

// CONSTANTS
import {
  OKR_ID,
  PROCESSES_BIG_PICTURE,
  PEOPLE_BIG_PICTURE,
  SWOT,
  PROJECT_PORTFOLIO,
  GOALS_AND_BONUS,
  PROJECT_PRIORITIZATION,
  KPIS,
  COMPETITIVE_ANALYSIS,
  SKILLS_ASSESSMENT,
} from "../../constants/tools.constants";

// CUSTOM FIELDS
import { ProcessBigPicture } from "./CustomFields/ProcessBigPicture/ProcessBigPicture";
import PeopleEvaluation from "./CustomFields/PeopleEvaluation/PeopleEvaluation";
import { SwotEvaluation } from "./CustomFields/SwotEvaluation/SwotEvaluation";
import ProjectEvaluationContainer from "./CustomFields/ProjectEvaluation/ProjectEvaluationContainer";
import { GoalsAndBonus } from "./CustomFields/GoalsAndBonus/GoalsAndBonus";
import { Kpi } from "./CustomFields/Kpi/Kpi";
import { ProjectPrioritization } from "./CustomFields/ProjectPrioritization/ProjectPrioritization";
import { OkrFields } from "./CustomFields/OKR/OkrFields";
import { CompetitiveAnalysis } from "./CustomFields/CompetitiveAnalysis/CompetitiveAnalysis";
import { SkillAssessment } from "./CustomFields/SkillAssessment/SkillAssessment";
import { getSelectedCompanyStates } from "../customMapStates";
import { checkAccess } from "../../utils/accessLevels";
import { translatedText } from "../../utils/translationUtils";
import { useWindowSize } from "../../utils/customHooks";
import { getConnectedInsights } from "./middleware";

const { getGoalEvaluationByID } = utils;

const messages = defineMessages({
  paramLevel: {
    id: "insight.paramLevel",
  },
  connMainTile: {
    id: "connect_main_title",
  },
  modalTextCancel: {
    id: "btn_modal_cancel",
  },
  modalTextConfirm: {
    id: "btn_modal_confirm",
  },
  insightPoints: {
    id: "global.points",
  },
  insightFiled: {
    id: "insight.filed",
  },
  save: {
    id: "global.save",
  },
});

const translation = (id, values) =>
  translatedText(id, { ...messages, ...globalMessages }, values);

const useStyles = makeStyles(() => ({
  alertFiled: {
    "& .MuiAlert-message": {
      fontSize: "15px !important",
    },
  },
}));

const InsightView = (props) => {
  const {
    insight = {},
    onUpdateEvaluations,
    selectedTool,
    language,
    fetchGroupedComments,
    insightTraceabilities,
    onDeleteTraceability,
    onAddNewResponsible,
    deleteButtonFollowUp,
    onUpdateInsightFollowup,
    showFollowUpForm,
    toggleFollowUpForm,
    onSubmitFollowUpForm,
    followUpLabelDescription,
    followUpLabelBeginDate,
    followUpLabelEndDate,
    followUpStatusOriginal,
    followUpStatusReprogrammed,
    followUpLabelReason,
    followUpStatusNoDate,
    allInsightGoals,
    allInsightFollowUps,
    companyMembersAndParticipants,
    connectedInsights = {},
    selectedCompany,
  } = props;
  const [isConnectionsModalOpen, setIsConnectionsModalOpen] = useState(false);
  const [showResponsibleModal, setShowResponsibleModal] = useState(false);
  const [showCollaboratorModal, setShowCollaboratorModal] = useState(false);
  const [isEditingGoal, setIsEditingGoal] = useState(false);
  const [openModalAttachment, setOpenModalAttachment] = useState(false);
  const windowSize = useWindowSize();

  const { comments: insightsIdList } = getConnectedInsights(
    insight,
    selectedTool.tool.id,
    selectedTool.id,
  );
  const [collaborators, setCollborators] = useState([]);
  const connectedComments =
    insightsIdList.length > 0
      ? insightsIdList
      : connectedInsights.comments || [];

  const classes = useStyles();

  async function fetchCollaborators() {
    const allowedUsers = await getFilteredUsersByEntityRole(
      insight.id,
      "INSIGHT",
      "COLLABORATOR",
    );

    setCollborators(allowedUsers);
  }

  async function handleUpdateCollaborators(selectedUsersIds) {
    setShowCollaboratorModal(false);

    const { question } = insight;
    const { theme } = question;
    const { cenary } = theme;

    await addInsightCollaborators(
      props.selectedToolId,
      cenary.id,
      insight.id,
      selectedUsersIds,
    );
    fetchCollaborators();
  }

  const handleFetchCollaborators = () => {
    fetchCollaborators();
  };

  useEffect(() => {
    if (insight.id) {
      fetchCollaborators();

      if (insightsIdList.length > 0) fetchGroupedComments(insightsIdList);
    }
    // eslint-disable-next-line
  }, [insight?.id]);

  const insightDate = insight.date || new Date();
  const insightOwner = insight.owner || {};
  const insightResponsable =
    insight.responsibleMember && insight.responsibleMember.name
      ? insight.responsibleMember.name
      : "";

  const insightObj = insight.id
    ? {
        ...insight,
        ownerName: insightOwner.name || "",
        date: insightDate,
        responsable: insightResponsable,
        tool:
          selectedTool.tool && selectedTool.tool.description
            ? selectedTool.tool.description
            : "",
      }
    : {};

  const editInsightFollowup = checkAccess([
    "CREATOR",
    "RESPONSIBLE",
    "COLLABORATOR",
    "MANAGER",
    "LEADER",
    "MODERATOR",
    "ADMIN",
    "OWNER",
  ]) && {
    mode: "click",
    blurToSave: true,
    afterSaveCell: onUpdateInsightFollowup,
  };

  const getEvaluations = () => {
    return insightObj && insightObj.evaluations ? insightObj.evaluations : [];
  };

  const getEvaluation = (evaluations) => {
    const evaluation =
      evaluations && evaluations.length > 0
        ? evaluations[0]
        : {
            type: "insight_evaluation",
            priority: undefined,
            impactLevel: undefined,
          };
    evaluation.date = new Date();
    return evaluation;
  };

  const doEvaluationsUpdate = (evaluations) => {
    onUpdateEvaluations(insightObj, evaluations);
  };

  const handleChangeImpact = (e) => {
    const targetValue = e.target.value;
    const finalValue =
      targetValue && targetValue !== "empty" ? targetValue : undefined;
    const evaluations = getEvaluations();
    const evaluation = getEvaluation(evaluations);
    evaluation.impactLevel = finalValue;
    evaluations[0] = evaluation;
    doEvaluationsUpdate(evaluations);
  };

  const handleInputFile = () => {
    setOpenModalAttachment(true);

    setTimeout(() => {
      setOpenModalAttachment(false);
    }, 1000);
  };

  const handleChangePriority = (e) => {
    const targetValue = e.target.value;
    const finalValue =
      targetValue && targetValue !== "empty" ? targetValue : undefined;
    const evaluations = getEvaluations();
    const evaluation = getEvaluation(evaluations);
    evaluation.priority = finalValue;
    evaluations[0] = evaluation;
    doEvaluationsUpdate(evaluations);
  };

  const buildOptions = (list) => {
    let finalOptions = [];

    list.forEach(({ label, key }) => {
      finalOptions = [...finalOptions, { label, value: key }];
    });

    return finalOptions;
  };

  const getDefaultEvaluationFields = (impactOptions, priorityOptions) => {
    const currentEvaluation =
      insight && insight.evaluations && insight.evaluations.length > 0
        ? insight.evaluations[0]
        : {};

    return (
      <div className="box box-primary">
        <div id="header-insight-evaluation" className="box-header with-border">
          <i className="fas fa-tasks" />
          &nbsp;&nbsp;
          <h3 className="box-title">{translation("evaluation")}</h3>
          &nbsp;&nbsp;
          <div className="box-tools pull-right">
            <span className="badge bg-purple">
              {currentEvaluation?.score?.toString()}
            </span>
          </div>
          <br />
          {currentEvaluation && (
            <small>
              {formatDate(
                currentEvaluation.date,
                EXTENDED_DATE_TIME_FORMAT,
                language,
              )}
            </small>
          )}
        </div>
        <div className="box-body">
          <div className="col-xs-12 col-sm-6">
            <MaterialSingleSelect
              id="selectImpactOnResults"
              label={translation("paramLevel", {
                param: translation("impact"),
              })}
              value={currentEvaluation?.impactLevel || ""}
              options={impactOptions}
              onChange={(e) =>
                checkAccess([
                  "CREATOR",
                  "RESPONSIBLE",
                  "COLLABORATOR",
                  "MANAGER",
                  "LEADER",
                  "MODERATOR",
                  "ADMIN",
                  "OWNER",
                ]) && handleChangeImpact(e)
              }
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <MaterialSingleSelect
              id="selectPriority"
              label={translation("paramLevel", {
                param: translation("priority"),
              })}
              value={
                currentEvaluation.priority ? currentEvaluation.priority : ""
              }
              options={priorityOptions}
              onChange={(e) =>
                checkAccess([
                  "CREATOR",
                  "RESPONSIBLE",
                  "COLLABORATOR",
                  "MANAGER",
                  "LEADER",
                  "MODERATOR",
                  "ADMIN",
                  "OWNER",
                ]) && handleChangePriority(e)
              }
            />
          </div>
        </div>
      </div>
    );
  };

  const impactEnum = [
    {
      key: "NO_SIGNFICANT_IMPACT",
      value: 0,
      label: `1 - ${translatedText(
        "NO_SIGNFICANT_IMPACT",
        globalMessages.impact,
      )}`,
    },
    {
      key: "IMPACT_LITTLE_ON_RESULTS",
      value: 1,
      label: `2 - ${translatedText(
        "IMPACT_LITTLE_ON_RESULTS",
        globalMessages.impact,
      )}`,
    },
    {
      key: "IMPACT_ON_RESULTS_LONG_TERM",
      value: 2,
      label: `3 - ${translatedText(
        "IMPACT_ON_RESULTS_LONG_TERM",
        globalMessages.impact,
      )}`,
    },
    {
      key: "IMPACT_ON_RESULTS_MEDIUM_TERM",
      value: 3,
      label: `4 - ${translatedText(
        "IMPACT_ON_RESULTS_MEDIUM_TERM",
        globalMessages.impact,
      )}`,
    },
    {
      key: "IMPACT_ON_RESULTS_SHORT_TERM",
      value: 4,
      label: `5 - ${translatedText(
        "IMPACT_ON_RESULTS_SHORT_TERM",
        globalMessages.impact,
      )}`,
    },
  ];

  const priorityEnum = [
    {
      key: "UNDEFINED",
      value: 0,
      label: `1 - ${translatedText("UNDEFINED", globalMessages.priority)}`,
    },
    {
      key: "LOW",
      value: 1,
      label: `2 - ${translatedText("LOW", globalMessages.priority)}`,
    },
    {
      key: "MEDIUM",
      value: 2,
      label: `3 - ${translatedText("MEDIUM", globalMessages.priority)}`,
    },
    {
      key: "HIGH",
      value: 3,
      label: `4 - ${translatedText("HIGH", globalMessages.priority)}`,
    },
    {
      key: "URGENT",
      value: 4,
      label: `5 - ${translatedText("URGENT", globalMessages.priority)}`,
    },
  ];

  const calcTraceabilitiesScore = () => {
    let score = 0;

    if (insightTraceabilities) {
      insightTraceabilities.forEach(({ insight = {} }) => {
        const { score: currentScore = 0 } = insight || {};

        score += Number(currentScore);
      });
    }

    return score;
  };

  const getEvaluationField = () => {
    const impactOptions = buildOptions(impactEnum);
    const priorityOptions = buildOptions(priorityEnum);

    return <>{getDefaultEvaluationFields(impactOptions, priorityOptions)}</>;
  };

  const handleSaveConnections = (selectedConnections) => {
    const { saveInsightMultiConnections } = props;

    setIsConnectionsModalOpen(false);
    if (selectedConnections.length > 0)
      saveInsightMultiConnections(insight.id, selectedConnections);
  };

  const traceabilities = [];

  insightTraceabilities.forEach(({ insight = {} }) => {
    if (insight && insight.id) traceabilities.push(insight.id);
  });

  const treeFilter = {
    insights: [(insight && insight.id) || 0, ...traceabilities],
  };

  const getTraceabilityField = () => {
    return (
      <div className="box box-primary">
        {isConnectionsModalOpen && (
          <SelectTraceabilityModal
            open={isConnectionsModalOpen}
            title={translation("connMainTile")}
            modalTextCancel={translation("modalTextCancel")}
            modalTextConfirm={translation("modalTextConfirm")}
            treeFilter={treeFilter}
            treeOrder={[
              "workspaces",
              "tools",
              "sceneries",
              "themes",
              "questions",
              "insights",
            ]}
            onCancel={() => setIsConnectionsModalOpen(false)}
            onConfirm={(selectedConnections) =>
              handleSaveConnections(selectedConnections)
            }
            selectedCompany={selectedCompany}
          />
        )}
        <div
          id="header-insight-traceability"
          className="box-header with-border"
        >
          <i className="fas fa-route" />
          &nbsp;&nbsp;
          <h3 className="box-title">{translation("traceability")}</h3>
          {checkAccess(
            [
              "CREATOR",
              "RESPONSIBLE",
              "COLLABORATOR",
              "MANAGER",
              "LEADER",
              "MODERATOR",
              "ADMIN",
              "OWNER",
            ],
            <div className="box-tools pull-right">
              <span className="badge bg-purple">
                {calcTraceabilitiesScore()}
              </span>
              <button
                type="button"
                className="btn btn-box-tool"
                onClick={() => setIsConnectionsModalOpen(true)}
              >
                <i className="fa fa-plus" />
              </button>
            </div>,
          )}
        </div>
        <div className="box-body">
          <div className="col-xs-12">
            <InsightTraceabilitiesContainer
              insightId={insight.id}
              traceabilities={insightTraceabilities}
              onDeleteTraceability={onDeleteTraceability}
            />
          </div>
        </div>
      </div>
    );
  };

  const getFollowUpField = () => {
    return (
      <div className="box box-primary">
        <div id="header-insight-follow-up" className="box-header with-border">
          <i className="fas fa-share-square" />
          &nbsp;&nbsp;
          <h3 className="box-title">{translation("followup")}</h3>
          {checkAccess(
            [
              "CREATOR",
              "RESPONSIBLE",
              "COLLABORATOR",
              "MANAGER",
              "LEADER",
              "MODERATOR",
              "ADMIN",
              "OWNER",
            ],
            <div className="box-tools pull-right">
              <button
                type="button"
                className="btn btn-box-tool"
                onClick={() => toggleFollowUpForm(true)}
              >
                <i className="fa fa-plus" />
              </button>
            </div>,
          )}
        </div>
        <div className="box-body">
          <InsightFollowUpTable
            language={language}
            onSubmitFollowUpForm={onSubmitFollowUpForm}
            toggleFollowUpForm={toggleFollowUpForm}
            deleteButtonFollowUp={deleteButtonFollowUp}
            showFollowUpForm={showFollowUpForm}
            cellEditProp={editInsightFollowup}
            insightFollowUp={{}}
            followUpLabelDescription={followUpLabelDescription}
            followUpLabelBeginDate={followUpLabelBeginDate}
            followUpLabelEndDate={followUpLabelEndDate}
            followUpStatusOriginal={followUpStatusOriginal}
            followUpStatusReprogrammed={followUpStatusReprogrammed}
            followUpStatusNoDate={followUpStatusNoDate}
            followUpLabelReason={followUpLabelReason}
            insightFollowUpData={allInsightFollowUps[insight.id] || []}
          />
        </div>
      </div>
    );
  };

  const getOkrFields = () => {
    const insightGoal = getGoalEvaluationByID(insight.id, allInsightGoals);

    return (
      <div>
        <div className="box box-primary">
          <div className="box-header with-border">
            <i className="fas fa-bullseye" />
            &nbsp;&nbsp;
            <h3 className="box-title">{translation("goals")}</h3>
            {checkAccess(
              [
                "CREATOR",
                "RESPONSIBLE",
                "COLLABORATOR",
                "MANAGER",
                "LEADER",
                "MODERATOR",
                "ADMIN",
                "OWNER",
              ],
              <div className="box-tools pull-right">
                {!isEditingGoal && (
                  <button
                    type="button"
                    className="btn btn-box-tool"
                    onClick={() => setIsEditingGoal(true)}
                  >
                    {!insightGoal || !insightGoal.insightGoal ? (
                      <i className="fa fa-plus" />
                    ) : (
                      <i className="fa fa-edit" />
                    )}
                  </button>
                )}
              </div>,
            )}
          </div>
          <div className="box-body">
            <InsightGoalContainer
              isEditingGoal={isEditingGoal}
              question={insight.questionId}
              insightId={insight.id}
              handleHideEditingGoal={() => setIsEditingGoal(false)}
            />
          </div>
        </div>
        <OkrFields insight={insight} />
      </div>
    );
  };

  const getAttachmentField = () => {
    return (
      <div className="box box-primary">
        <div id="header-insight-attachments" className="box-header with-border">
          <i className="fas fa-paperclip" />
          &nbsp;&nbsp;
          <h3 className="box-title">{translation("attachments")}</h3>
          {checkAccess(
            [
              "CREATOR",
              "RESPONSIBLE",
              "COLLABORATOR",
              "MANAGER",
              "LEADER",
              "MODERATOR",
              "ADMIN",
              "OWNER",
            ],
            <div className="box-tools pull-right">
              <button
                type="button"
                className="btn btn-box-tool"
                onClick={handleInputFile}
              >
                <i className="fa fa-plus" />
              </button>
            </div>,
          )}
        </div>
        <div className="box-body">
          <InsightAttachmentsContainer
            insightId={insight.id}
            insightAttachments={props.attachments}
            openModalAttachment={openModalAttachment}
          />
        </div>
      </div>
    );
  };

  const getDisplayebleFields = () => {
    const ToolID = props.selectedTool.tool ? props.selectedTool.tool.id : null;
    // Estes são os Campos padrão
    let displayFIelds = {
      evaluation: true,
      traceablity: true,
      attachment: true,
    };

    // Campos adicionais e específicos de cada ferramenta devem ser adicionados somente no switch abaixo
    // Quando esse Campo específico não for necessário, a validação no "Render()/return()" será suficiente para EXIBI-LO ou NÃO
    switch (ToolID) {
      case COMPETITIVE_ANALYSIS:
        displayFIelds = {
          ...displayFIelds,
          competitiveAnalysis: true,
        };
        break;
      case OKR_ID:
        displayFIelds = {
          ...displayFIelds,
          okrFields: true,
          followUp: true,
        };
        break;
      case GOALS_AND_BONUS:
        displayFIelds = {
          ...displayFIelds,
          goalsAndBonus: true,
        };
        break;
      case KPIS:
        displayFIelds = {
          ...displayFIelds,
          kpi: true,
        };
        break;
      case PROCESSES_BIG_PICTURE:
        displayFIelds = {
          ...displayFIelds,
          processBigPicture: true,
        };
        break;
      case PEOPLE_BIG_PICTURE:
        displayFIelds = {
          ...displayFIelds,
          peopleEvaluation: true,
        };
        break;
      case SWOT:
        displayFIelds = {
          ...displayFIelds,
          swotEvaluation: true,
        };
        break;
      case PROJECT_PORTFOLIO:
        displayFIelds = {
          ...displayFIelds,
          projectEvaluation: true,
        };
        break;
      case PROJECT_PRIORITIZATION:
        displayFIelds = {
          ...displayFIelds,
          projectPrioritization: true,
        };
        break;
      case SKILLS_ASSESSMENT:
        displayFIelds = {
          ...displayFIelds,
          skillAssessment: true,
        };
        break;
      default:
        return { ...displayFIelds };
    }

    return { ...displayFIelds };
  };

  const appendProps = {
    insight,
  };

  const updateResponsible = (newResponsible) => {
    onAddNewResponsible(newResponsible);
    setShowResponsibleModal(false);
  };

  const handleCustomFields = (fields) => {
    return (
      <>
        {fields.processBigPicture && (
          <ProcessBigPicture
            {...appendProps}
            onAddCollaborator={handleFetchCollaborators}
          />
        )}
        {fields.peopleEvaluation && <PeopleEvaluation {...appendProps} />}
        {fields.swotEvaluation && <SwotEvaluation {...appendProps} />}
        {fields.projectEvaluation && (
          <ProjectEvaluationContainer
            {...appendProps}
            updateResponsible={updateResponsible}
          />
        )}
        {fields.goalsAndBonus && <GoalsAndBonus {...appendProps} />}
        {fields.kpi && <Kpi {...appendProps} />}
        {fields.projectPrioritization && (
          <ProjectPrioritization {...appendProps} />
        )}
        {fields.competitiveAnalysis && <CompetitiveAnalysis {...appendProps} />}
        {fields.skillAssessment && <SkillAssessment {...appendProps} />}
      </>
    );
  };

  const displaybleFields = getDisplayebleFields();

  const handleChangeResponsible = () => {
    setShowResponsibleModal(true);
  };

  const handleChangeCollaborators = () => {
    setShowCollaboratorModal(true);
  };

  const getMembersList = () => {
    const filteredMembers = companyMembersAndParticipants.filter(
      (people) => people.type === "MEMBER",
    );

    return filteredMembers;
  };

  const getValidConnectedComments = (insightId, connectedOnes = []) => {
    return connectedOnes.indexOf(insightId) > -1
      ? connectedOnes
      : [...connectedOnes, insightId];
  };

  const userCanChange = checkAccess([
    "CREATOR",
    "RESPONSIBLE",
    "COLLABORATOR",
    "MANAGER",
    "LEADER",
    "MODERATOR",
    "ADMIN",
    "OWNER",
  ]);

  const [firstCollaborator] = collaborators;
  const collaboratorsText =
    collaborators.length > 1
      ? `${firstCollaborator?.nome} +${collaborators.length - 1}`
      : firstCollaborator?.nome || translation("select");

  return (
    <div>
      {insightObj && (
        <div
          className="insight-view"
          style={{ height: windowSize[1] > 720 ? "76vh" : "100vh" }}
        >
          <div className="insight-header-data">
            <div className="justify-between header-insight-dialog">
              <div style={{ display: "flex" }}>
                <div
                  className="insightPoints"
                  style={{ display: "flex", marginRight: "20px" }}
                >
                  <div
                    className="label_avatar"
                    style={{
                      marginTop: "7px",
                      marginRight: "7px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {translation("insightPoints")}
                  </div>
                  <div>
                    <Chip
                      label={insightObj.score && insightObj.score.toString()}
                      style={{ fontSize: "15px" }}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div
                  className="createBy"
                  style={{ display: "flex", marginRight: "20px" }}
                >
                  <div
                    className="label_avatar"
                    style={{
                      marginTop: "7px",
                      marginRight: "5px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {translation("createdBy")}
                  </div>
                  <div>
                    <Chip
                      avatar={
                        <Avatar
                          src={insight?.owner?.userData?.avatarBlobId}
                          name={insight?.owner?.name}
                        />
                      }
                      label={insight?.owner?.name}
                      style={{ fontSize: "15px" }}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div
                  className="creationDate"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "20px",
                  }}
                >
                  <div style={{ marginRight: "5px" }}>
                    {translation("date")}
                  </div>
                  <div>
                    <Chip
                      size="medium"
                      label={
                        insightObj && insightObj.date
                          ? moment(insightObj.date).format("L")
                          : ""
                      }
                      variant="outlined"
                      style={{ fontSize: "15px" }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                  <div
                    className="responsibleField"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      className="label_avatar"
                      style={{ marginRight: "5px" }}
                    >
                      {translation("responsable")}
                    </div>
                    <div>
                      <Chip
                        avatar={
                          <Avatar
                            src={
                              insight?.responsibleMember?.userData?.avatarBlobId
                            }
                            name={insight?.responsibleMember?.name}
                          />
                        }
                        label={insight?.responsibleMember?.name}
                        style={{ fontSize: "15px" }}
                        variant="outlined"
                        onClick={() =>
                          userCanChange && handleChangeResponsible()
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="insight-collaborators"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      className="label_avatar"
                      style={{ marginRight: "5px" }}
                    >
                      {translation("collaborators")}
                    </div>
                    <div
                      title={collaborators?.map(({ nome }) => nome).join(", ")}
                    >
                      <Chip
                        avatar={
                          <Avatar
                            src={firstCollaborator?.avatarBlobId}
                            name={collaboratorsText}
                          />
                        }
                        label={collaboratorsText}
                        style={{ fontSize: "15px" }}
                        variant="outlined"
                        onClick={() =>
                          userCanChange && handleChangeCollaborators()
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="insight-scroll-container">
            {insightObj.filed && (
              <div
                className="col-md-12"
                style={{ margin: "10px 0px", padding: "0px" }}
              >
                <Alert severity="warning" className={classes.alertFiled}>
                  {translation("insightFiled")}
                </Alert>
              </div>
            )}
            <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
              {displaybleFields.okrFields && getOkrFields()}
              {handleCustomFields(displaybleFields)}
              {displaybleFields.followUp && getFollowUpField()}
              {displaybleFields.evaluation && getEvaluationField()}
              {displaybleFields.traceablity && getTraceabilityField()}
              {displaybleFields.attachment && getAttachmentField()}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <div className="box box-primary">
                <InsightTimelineContainer
                  useBox={false}
                  selectedInsight={insight}
                  connectedComments={getValidConnectedComments(
                    insight.id,
                    connectedComments,
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showResponsibleModal && (
        <SelectMemberModal
          open={showResponsibleModal}
          title="Select a responsible"
          onConfirm={(selected) => updateResponsible(selected[0])}
          onCancel={() => setShowResponsibleModal(false)}
          membersList={getMembersList()}
          singleSelect
        />
      )}
      {showCollaboratorModal && (
        <SelectMemberModal
          open={showCollaboratorModal}
          title={`${translation("select")} ${translation("collaborators")}`}
          onConfirm={(selected) => handleUpdateCollaborators(selected)}
          onCancel={() => setShowCollaboratorModal(false)}
          membersList={getMembersList()}
          initialSelected={collaborators.map((c) => c.userId)}
          canSaveUnselected
          replaceSelected
          hideMemberTypeFilters
          confirmText={translation("save")}
        />
      )}
    </div>
  );
};

InsightView.propTypes = {
  insightTraceabilities: PropTypes.array.isRequired,
  allInsightFollowUps: PropTypes.object,
  allInsightGoals: PropTypes.array,
  onUpdateInsight: PropTypes.func,
  onUpdateEvaluations: PropTypes.func,
  onUpdateInsightFollowup: PropTypes.func,
  language: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { selectedTool, insightAttachments } = state;
  const { selectedCompany = {} } = getSelectedCompanyStates(state);

  return {
    selectedCompany,
    profile: state.profile,
    selectedTool,
    toolId: selectedTool.tool.id,
    selectedToolId: selectedTool.id,
    attachments: insightAttachments,
  };
};

export default connect(mapStateToProps, {
  saveInsightMultiConnections,
  fetchGroupedComments,
})(InsightView);
