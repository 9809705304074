const en = {
  ma_main_title: "Market Analysis",
};

const es = {
  ma_main_title: "Análisis de Mercado",
};

const pt = {
  ma_main_title: "Análise de Mercado",
};

const translations = { en, es, pt };

export default translations;
