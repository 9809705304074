import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";
import { connect } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import _ from "lodash";

import {
  getWorkspacesStates,
  getActionsPlanStates,
  getCompanyMembersAndParticipants,
} from "../../customMapStates";
import { getDefaultAddTableLine } from "../../../utils/tableUtils";

import ConfirmationDialog from "../ConfirmationDialog";
import Registrations from "./Registration";
import ActionsTableLines from "./includes/ActionsTableLines";

import {
  updateInsightActionsPlan,
  updateOnlyAction,
  addSingleAction,
  deleteActionFromPlan,
} from "../../../actions/actionsPlan";
import { translatedText } from "../../../utils/translationUtils";
import actionsPlanUtils from "../../../utils/actionsPlan";
import { getToolCustomLabels } from "../../../utils/actionsPlan/display";
import { useWindowSize } from "../../../utils/customHooks";
import {
  getFilteredActionsPlanByInsights,
  getGroupedRelatedActionPlans,
} from "../../../utils/actionsPlan/filter";
import { ACTION_PLAN_ID } from "../../../constants/tools.constants";
import { createSelectedWorkspaceTool } from "../../../actions/toolActions";

const { getFormActionsPlan } = actionsPlanUtils;

const messages = defineMessages({
  global_noDataTable: {
    id: "global.noDataTable",
  },
  acp_confirm_delete_action: {
    id: "acp_confirm_delete_action",
  },
});

const translation = (id) => translatedText(id, messages);

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from([...list]);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((action, index) => {
    return { ...action, order: index };
  });
};

const getListStyle = () => ({
  background: "white",
  padding: 8,
});

const defaultActionsPlan = getFormActionsPlan({});
const defaultForm = { open: false };
const defaultDelete = {
  open: false,
  onConfirm: () => {},
};

const Table = (props) => {
  const [actionsPlan, setActionsPlan] = useState(defaultActionsPlan);
  const [connectedActions, setConnectedActions] = useState([]);
  const [openForm, setOpenForm] = useState(defaultForm);
  const [deleteForm, setDeleteForm] = useState(defaultDelete);
  const windowSize = useWindowSize();
  const screenWidth = windowSize[0];
  const applyScroll = screenWidth <= 1200 || props.overflowScroll;

  const {
    insight = {},
    companyMembersAndParticipants = [],
    allActionsPlans,
    blockAllChanges = false,
    hideActions = false,
    dragabble = true,
    selectedTool = {},
    updateOnlyAction,
    allUsersWorkspaceTools = [],
    selectedWorkspaceID,
    createSelectedWorkspaceTool,
  } = props;

  const insightId = insight.id || null;
  const actionPlanId = insight.actionPlanId || null;
  const { tool = {} } = selectedTool;
  const toolTypeId = tool && tool.id ? tool.id : null;

  const toolCustomLabels = getToolCustomLabels(toolTypeId);

  const workspaceHasActionPlanTool = () => {
    let actionPlanToolInfo = {};

    allUsersWorkspaceTools.forEach((tool) => {
      const { id: toolId = 0, tool: toolType = 0 } = tool || {};

      if (toolType?.id === ACTION_PLAN_ID) {
        actionPlanToolInfo = { toolId };
      }
    });

    return actionPlanToolInfo;
  };

  const updateStateActionsPlan = (newActionsPlan) => {
    const { actions = [], relatedActionPlans = [] } = newActionsPlan;
    const grouped = getGroupedRelatedActionPlans(actions, relatedActionPlans);

    if (!_.isEqual(actionsPlan, newActionsPlan)) {
      setActionsPlan(newActionsPlan);
      setConnectedActions(grouped);
    }
  };

  const getCurrentActionsPlan = () => {
    const filtered = getFilteredActionsPlanByInsights(
      [insightId],
      allActionsPlans,
    );

    if (filtered && filtered[0]) return filtered[0].actionPlan;

    return defaultActionsPlan;
  };

  useEffect(() => {
    if (insightId && allActionsPlans) {
      const currentActionsPlan = getCurrentActionsPlan();
      if (currentActionsPlan) updateStateActionsPlan(currentActionsPlan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightId, allActionsPlans]);

  const headLabels = [
    { label: "", colSpan: 1 },
    { label: toolCustomLabels.acp_table_text_period, colSpan: 1 },
    { label: toolCustomLabels.acp_table_text_deviation, colSpan: 1 },
    { label: toolCustomLabels.acp_table_text_cause, colSpan: 1 },
    { label: toolCustomLabels.acp_table_text_action, colSpan: 1 },
    { label: toolCustomLabels.acp_table_text_time_limit, colSpan: 1 },
    { label: toolCustomLabels.acp_table_text_status, colSpan: 1 },
    { label: "", hide: hideActions, colSpan: 1 },
    { label: "", hide: hideActions, colSpan: 1 },
  ];

  const resetOpenForm = () => {
    setOpenForm(defaultForm);
  };

  const resetDeleteForm = () => {
    setDeleteForm(defaultDelete);
  };

  const handleAddAction = () => {
    setOpenForm({ open: true });
  };

  const handleEditAction = (actionId) => {
    setOpenForm({ open: true, isEditing: true, params: { actionId } });
  };

  const handleDeleteAction = (actionId) => {
    const finishDelete = () => {
      props.deleteActionFromPlan(selectedTool?.id, actionId, actionPlanId);
      resetDeleteForm();
    };

    setDeleteForm({
      open: true,
      onConfirm: finishDelete,
    });
  };

  const handleRegistration = (saveAction, saveFiles) => {
    const { responsible = {} } = saveAction || {};
    const { id: responsibleId = {} } = responsible || {};
    const { toolId = false } = workspaceHasActionPlanTool();

    props.addSingleAction(
      selectedTool?.id,
      insightId,
      actionPlanId,
      saveAction,
      saveFiles,
    );

    if (!toolId) {
      createSelectedWorkspaceTool(
        ACTION_PLAN_ID,
        selectedWorkspaceID,
        responsibleId,
        false,
        "VIEWER",
      );
    }

    resetOpenForm();
  };

  const handleSaveActionsPlan = (saveActionsPlan, savedFiles, actionId) => {
    const currentActionsPlan = getCurrentActionsPlan();

    const isDifferent = !_.isEqual(saveActionsPlan, currentActionsPlan);
    const doChangeActionPlan = insightId && isDifferent;

    if (doChangeActionPlan || savedFiles?.length)
      props.updateInsightActionsPlan(
        insightId,
        saveActionsPlan,
        savedFiles,
        actionId,
      );
  };

  const updateActions = async (updatedActions, files, actionId, insightId) => {
    resetOpenForm();

    if (selectedTool?.id && insightId) {
      const [updatedAction] = updatedActions.filter(
        ({ id }) => actionId === id,
      );

      if (updatedAction?.id) {
        await updateOnlyAction(
          selectedTool.id,
          insightId,
          updatedAction,
          files,
        );
      }
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const orderedActions = reorder(
      actionsPlan.actions,
      result.source.index,
      result.destination.index,
    );

    const updatedActionsPlan = { ...actionsPlan, actions: orderedActions };

    handleSaveActionsPlan(updatedActionsPlan);
  };

  const orderedActions = [...connectedActions].sort((a, b) =>
    a.order > b.order ? 1 : -1,
  );

  const appendProps = {
    actions: orderedActions,
    displayActions: orderedActions,
    updateActions,
    handleRegistration,
    editingForm: openForm,
    cancelRegistration: resetOpenForm,
    companyMembersAndParticipants,
    handleEditAction,
    handleDeleteAction,
    hideActions,
    dragabble,
    toolCustomLabels,
    insightId,
  };

  return (
    <div>
      <Registrations open={openForm.open} {...appendProps} />
      <ConfirmationDialog
        open={deleteForm.open}
        title={translation("acp_confirm_delete_action")}
        onConfirm={deleteForm.onConfirm}
        onCancel={resetDeleteForm}
      />
      <DragDropContext onDragEnd={handleDragEnd}>
        <table
          className={`simple-table ${applyScroll && "horizontal-scroll"}`}
          style={{ tableLayout: "auto", position: "relative" }}
        >
          <thead>
            <tr>
              {headLabels.map(
                ({ label, hide, colSpan }, index) =>
                  !hide && (
                    <td key={index} colSpan={colSpan}>
                      {label}
                    </td>
                  ),
              )}
            </tr>
          </thead>
          <Droppable droppableId="droppable">
            {(droppableProvided, { isDraggingOver }) => (
              <tbody
                ref={droppableProvided.innerRef}
                style={getListStyle(isDraggingOver)}
              >
                <ActionsTableLines
                  {...appendProps}
                  showEmptyCols={!hideActions}
                />
                {orderedActions.length === 0 && (
                  <tr>
                    <td colSpan="100" align="center">
                      {translation("global_noDataTable")}
                    </td>
                  </tr>
                )}
                {!blockAllChanges &&
                  getDefaultAddTableLine(() => handleAddAction(), {
                    visibility: isDraggingOver ? "hidden" : "visible",
                  })}
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { companyMembersAndParticipants = [] } =
    getCompanyMembersAndParticipants(state);

  const { allActionsPlans = [] } = getActionsPlanStates(state);
  const { allUsersWorkspaceTools = [], selectedWorkspaceID } =
    getWorkspacesStates(state);

  return {
    companyMembersAndParticipants,
    allActionsPlans,
    allUsersWorkspaceTools,
    selectedWorkspaceID,
  };
};

Table.propTypes = {
  insight: PropTypes.shape({
    id: PropTypes.number.isRequired,
    actionPlanId: PropTypes.any,
  }).isRequired,
};

export default connect(mapStateToProps, {
  addSingleAction,
  updateOnlyAction,
  deleteActionFromPlan,
  updateInsightActionsPlan,
  createSelectedWorkspaceTool,
})(Table);
