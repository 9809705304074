export function getOverallWeight(activities, getTotalWeight) {
  let totalWeight = 0;
  let availableWeight = 0;

  if (activities.length) {
    activities.forEach((activity) => {
      totalWeight = Number(totalWeight) + Number(activity.weight);
    });
  }

  if (getTotalWeight) {
    return totalWeight.toFixed(1);
  }

  availableWeight = 100 - Number(totalWeight);
  return availableWeight.toFixed(1);
}

export function verifyDate(date = "") {
  if (!date) return true;

  const splitted = date.split("-");
  const year = splitted[0] || "0000";
  const day = splitted[1] || "00";
  const month = splitted[2] || "00";

  if (year.length !== 4 || Number(year) < 1900) return false;
  if (day.length !== 2) return false;
  if (month.length !== 2) return false;

  return true;
}
