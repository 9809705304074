import { getParticipantsEntity } from "./entity";
import {
  orderArrayByObjAttr,
  getObjectInfoById,
  getOnlySlugValues,
} from "../ArrayUtils";
import { getUserCompanyProfile } from "../companyProfiles/filter";
import { getCompanyArea } from "../../actions/companyStructureActions";

export const simpleAddToAdministration = (
  originalAdministration,
  entitySlug,
  newValue,
) => {
  const originalEntity = originalAdministration[entitySlug];

  if (originalEntity) {
    return {
      ...originalAdministration,
      [entitySlug]: [...originalEntity, newValue],
    };
  }

  return originalAdministration;
};

export const simpleEditArray = (originalArray, itemId, newValue, compareBy) => {
  if (originalArray && itemId) {
    const itemIndex = getObjectInfoById(itemId, originalArray, compareBy, true);

    if (itemIndex > -1) {
      const updatedArray = [...originalArray];
      updatedArray[itemIndex] = newValue;

      return updatedArray;
    }
  }

  return originalArray;
};

export const simpleEditArrayOnAdministration = (
  originalAdministration,
  editingEntity,
  itemId,
  newValue,
  compareBy = "id",
) => {
  if (
    originalAdministration &&
    originalAdministration[editingEntity] &&
    itemId
  ) {
    const updatedEntity = simpleEditArray(
      originalAdministration[editingEntity],
      itemId,
      newValue,
      compareBy,
    );

    const updatedAdministration = {
      ...originalAdministration,
      [editingEntity]: updatedEntity,
    };

    return updatedAdministration;
  }

  return originalAdministration;
};

export const orderScale = (scale) => {
  if (!scale || scale?.length === 0) return [];
  return orderArrayByObjAttr(scale, "value", false, true);
};

export const addScaleToAdministration = (
  originalAdministration,
  entitySlug,
  newAddingScale,
) => {
  const savingNewScale = {
    ...newAddingScale,
  };

  const updatedAdministration = simpleAddToAdministration(
    originalAdministration,
    entitySlug,
    savingNewScale,
  );

  const scale = updatedAdministration[entitySlug];

  return {
    ...updatedAdministration,
    [entitySlug]: orderScale(scale),
  };
};

export const addProfileToAdministration = (
  originalAdministration,
  newAddingScale,
) => {
  const savingNewProfile = {
    ...newAddingScale,
  };

  return simpleAddToAdministration(
    originalAdministration,
    "profiles",
    savingNewProfile,
  );
};

export const addParticipantToAdministration = (
  originalAdministration,
  newAddingScale,
) => {
  const savingNewParticipant = {
    ...newAddingScale,
  };

  return simpleAddToAdministration(
    originalAdministration,
    "participants",
    savingNewParticipant,
  );
};

export const addCompetenceAreaToAdministration = (
  originalAdministration,
  newAddingCompetenceArea,
) => {
  const savingNewCompetenceArea = {
    ...newAddingCompetenceArea,
  };

  return simpleAddToAdministration(
    originalAdministration,
    "competenceAreas",
    savingNewCompetenceArea,
  );
};

export const addSkillToAdministration = (
  originalAdministration,
  newAddingSkill,
) => {
  const savingNewSkill = {
    ...newAddingSkill,
  };

  return simpleAddToAdministration(
    originalAdministration,
    "skills",
    savingNewSkill,
  );
};

export const addNewEvaluationScale = (
  originalAdministration,
  newEvaluationScale,
) => {
  return addScaleToAdministration(
    originalAdministration,
    "evaluationScale",
    newEvaluationScale,
  );
};

export const addNewPotentialScale = (
  originalAdministration,
  newPotentialScale,
) => {
  return addScaleToAdministration(
    originalAdministration,
    "potentialScale",
    newPotentialScale,
  );
};

export const addNewQualificationScale = (
  originalAdministration,
  newQualificationScale,
) => {
  return addScaleToAdministration(
    originalAdministration,
    "qualificationScale",
    newQualificationScale,
  );
};

export const addNewComplementaryScale = (
  originalAdministration,
  newComplementaryScale,
) => {
  return addScaleToAdministration(
    originalAdministration,
    "complementaryScales",
    newComplementaryScale,
  );
};

export const addNewComplementaryEvaluation = (
  originalAdministration,
  newComplementaryEvaluation,
) => {
  const savingNewComplementaryEvaluatio = {
    ...newComplementaryEvaluation,
  };

  return simpleAddToAdministration(
    originalAdministration,
    "complementaryEvaluations",
    savingNewComplementaryEvaluatio,
  );
};

export const addNewWorkflow = (originalAdministration, newWorkflow) => {
  const savingNewWorkflow = {
    ...newWorkflow,
  };

  return simpleAddToAdministration(
    originalAdministration,
    "workflow",
    savingNewWorkflow,
  );
};

export const addNewProfile = (originalAdministration, newProfile) => {
  return addProfileToAdministration(originalAdministration, newProfile);
};

export const addNewParticipants = async (
  originalAdministration,
  newParticipants,
  professionalProfiles,
  enableEvaluatorsSelectionWorkflow,
) => {
  let updatedAdministration = originalAdministration;

  if (Array.isArray(newParticipants)) {
    const defaultParticipant = getParticipantsEntity(
      [{}],
      {
        canInsertNewId: true,
      },
      enableEvaluatorsSelectionWorkflow,
    )[0];

    await Promise.all(
      newParticipants.map(async (newParticipantInfo) => {
        const userId = newParticipantInfo.id;
        const { companyArea = null, companyPosition = null } =
          getUserCompanyProfile(userId, professionalProfiles);

        let areaResponsible = companyArea?.responsible ?? null;

        if (userId === areaResponsible?.id) {
          try {
            if (companyArea?.parentAreaId) {
              const { data: parentArea } = await getCompanyArea(
                companyArea.parentAreaId,
              );

              areaResponsible = parentArea?.responsible ?? null;
            }
          } catch (err) {
            // console.error(err);
          }
        }

        const saveParticipant = {
          ...defaultParticipant,
          memberParticipantId: newParticipantInfo.id,
          companyAreaId: companyArea?.id ?? null,
          companyPositionId: companyPosition?.id ?? null,
          managerId: areaResponsible?.id ?? null,
        };

        updatedAdministration = addParticipantToAdministration(
          updatedAdministration,
          saveParticipant,
        );
      }),
    );
  }

  return updatedAdministration;
};

export const addNewCompetenceArea = (
  originalAdministration,
  nweCompetenceArea,
) => {
  return addCompetenceAreaToAdministration(
    originalAdministration,
    nweCompetenceArea,
  );
};

export const addNewSkill = (originalAdministration, newSkill) => {
  return addSkillToAdministration(originalAdministration, newSkill);
};

export const updateParticipantsEvaluationStage = (
  participants,
  filteredParticipants,
  newStage,
) => {
  const updated = [];
  const ids = filteredParticipants.map(({ id }) => id);

  if (Array.isArray(participants) && newStage) {
    participants.forEach((participantInfo) => {
      const evaluationStage =
        ids.indexOf(participantInfo.id) > -1
          ? newStage
          : participantInfo.evaluationStage;
      updated.push({ ...participantInfo, evaluationStage });
    });

    return updated;
  }

  return participants;
};

export const getUpdatedWorkflow = (
  participantId,
  originalWorkflow,
  newWorkflow,
) => {
  const updatedWorkflow = [];

  if (
    participantId &&
    Array.isArray(originalWorkflow) &&
    Array.isArray(newWorkflow)
  ) {
    const updateIds = getOnlySlugValues(newWorkflow, "id");

    originalWorkflow.forEach((workflowInfo) => {
      let addItem = workflowInfo;

      if (updateIds.indexOf(workflowInfo.id) > -1)
        addItem = getObjectInfoById(workflowInfo.id, newWorkflow, "id");

      updatedWorkflow.push(addItem);
    });

    return updatedWorkflow;
  }

  return originalWorkflow;
};

export const updateParticipantWorkflow = (
  originalAdministration,
  participantId,
  newWorkflow,
) => {
  return {
    ...originalAdministration,
    workflow: getUpdatedWorkflow(
      participantId,
      originalAdministration.workflow,
      newWorkflow,
    ),
  };
};

export const updateParticipantComplementaryWorkflow = (
  originalAdministration,
  participantId,
  newWorkflow,
) => {
  return {
    ...originalAdministration,
    complementaryWorkflow: getUpdatedWorkflow(
      participantId,
      originalAdministration.complementaryWorkflow,
      newWorkflow,
    ),
  };
};
