import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { injectIntl, defineMessages } from "react-intl";
import { Radio } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import MaterialTextField from "../../../Common/MaterialTextField";
import FormDialog from "../../../Common/FormDialog";

import {
  getLabelByType,
  changeSchedulePlanTrashStatus,
} from "../../../../utils/projectEvaluation";
import {
  getPatternDateFormat,
  getCustomDeliveryDate,
} from "../../../../utils/projectUtils";
import { globalMessages } from "../../../../utils/global";
import ConfirmationDialog from "../../../Common/ConfirmationDialog";
import MaterialCustomMenu from "../../../Common/MaterialCustomMenu";

const messages = defineMessages({
  reason: {
    id: "tool.project.reason",
  },
  areYouSure: {
    id: "tool.project.areYouSure",
  },
  confirmInformation: {
    id: "tool.project.confirmInformation",
  },
  newDeliveryDate: {
    id: "tool.project.new_delivery_date",
  },
  invalidDates: {
    id: "tool.project.invalidDates",
  },
  invalidNewDate: {
    id: "tool.project.invalidNewDate",
  },
  archive: {
    id: "global.archive",
  },
  unArquive: {
    id: "global.unArchive",
  },
  showArchived: {
    id: "global.showArchived",
  },
  showUnArchived: {
    id: "global.showUnArchived",
  },
});

const deleteScheduleDefault = {
  item: false,
  show: false,
};

const newDateChangeDefault = {
  isLastSchedule: false,
  newDeliveryDate: {},
};

const ScheduleTable = ({
  schedule,
  setSchedule,
  intl,
  saveButtonJSX,
  saveButton,
  updateSingleScheduleField,
}) => {
  const [datesValidation, setValidation] = useState({
    error: false,
    type: "",
    message: "",
  });

  const [deleteSchedule, setDeleteSchedule] = useState(deleteScheduleDefault);
  const [newDeliveryDateChange, setNewDeliveryDateChange] = useState(
    newDateChangeDefault,
  );

  const [newDeliveryDateConfig, setNewDeliveryDateConfig] = useState({
    showNewDeliveryDate: false,
    data: "",
  });
  const [openDeliveryDateModal, setOpenDeliveryDateModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [filterByInTrash, setFilterByInTrash] = useState(false);

  const labels = getLabelByType("Schedule", intl);
  const reasonTitle = intl.formatMessage(messages.reason);

  const resetDeliveryDateChange = () =>
    setNewDeliveryDateChange(newDateChangeDefault);

  useEffect(() => {
    const { isLastSchedule = false, newDeliveryDate = {} } =
      newDeliveryDateChange || {};

    if (isLastSchedule) {
      setSchedule((current) => {
        return {
          ...current,
          didUpdate: true,
          deliveryDateSet: true,
          finalDate: newDeliveryDate,
          falseFinalDate: null,
        };
      });

      resetDeliveryDateChange();
      setFilterByInTrash(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDeliveryDateChange]);

  useEffect(() => {
    if (datesValidation.error && datesValidation.message === "") {
      const { type } = datesValidation;
      let errorMessage = null;

      if (type === "invalidDates") {
        errorMessage = intl.formatMessage(messages.invalidDates);
      } else if (type === "emptySchedule") {
        errorMessage = intl.formatMessage(messages.emptySchedule);
      } else if (type === "invalidNewDate") {
        errorMessage = intl.formatMessage(messages.invalidNewDate);
      }

      setValidation({
        ...datesValidation,
        message: errorMessage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datesValidation]);

  const setScheduleValue = (slug = "", value = "") => {
    setSchedule((current) => {
      return {
        ...current,
        [slug]: value,
      };
    });
  };

  const cleanUpdateStates = () => {
    setSchedule((current) => {
      return {
        ...current,
        newDeliveryReason: "",
        didUpdate: true,
      };
    });

    setValidation({
      ...datesValidation,
      error: false,
    });
  };

  const handleConfirmDate = () => {
    const fieldsValidated = moment(schedule.finalDate).isAfter(
      moment(schedule.initialDate),
    );

    if (fieldsValidated) {
      cleanUpdateStates();

      const beginDate = moment(schedule.initialDate);
      const endDate = moment(schedule.finalDate);

      const yearsDiff = endDate.diff(beginDate, "years");

      if (yearsDiff < 30) setOpenConfirmModal(true);

      setShowConfirmationModal(false);

      return;
    }

    setValidation({
      ...datesValidation,
      error: true,
      message: "",
      type: "invalidDates",
    });

    setScheduleValue("falseFinalDate", null);

    setShowConfirmationModal(false);
  };

  const handleDeliveryDate = (currentValue = "") => {
    setValidation({
      ...datesValidation,
      error: false,
      message: "",
    });

    setScheduleValue("deliveryDate", currentValue);

    setOpenDeliveryDateModal(true);

    setNewDeliveryDateConfig((current) => {
      return {
        ...current,
        showNewDeliveryDate: false,
      };
    });
  };

  useEffect(() => {
    const { showNewDeliveryDate, data } = newDeliveryDateConfig;

    if (showConfirmationModal) handleConfirmDate();

    if (showNewDeliveryDate) handleDeliveryDate(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showConfirmationModal, newDeliveryDateConfig]);

  const buildDefaultPlannings = () => {
    setSchedule((current) => {
      return { ...current, firstDateSet: true, didUpdate: false };
    });
  };

  useEffect(() => {
    if (
      schedule &&
      schedule.deliveryDateSet &&
      schedule.initialDate &&
      (schedule.didUpdate || !schedule.firstDateSet)
    ) {
      buildDefaultPlannings();
    } else if (schedule && schedule.didUpdate) {
      setSchedule((current) => {
        return {
          ...current,
          deliveryDate: "",
          newDeliveryReason: "",
          didUpdate: false,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule]);

  const updateReschedulesDeadline = (currentReschedules = [], deliveryDate) => {
    const updatedReschedules = [];

    currentReschedules.forEach((reschedule, index) => {
      updatedReschedules[index] = {
        ...reschedule,
        currentDeadline: reschedule.deliveryDate === deliveryDate,
      };
    });

    return updatedReschedules;
  };

  const handleDeadlineChange = (deliveryDate) => {
    const currentReschedules =
      schedule && schedule.reschedules
        ? _.cloneDeep(schedule.reschedules)
        : null;

    if (currentReschedules) {
      setSchedule((current) => {
        return {
          ...current,
          reschedules: updateReschedulesDeadline(
            currentReschedules,
            deliveryDate,
          ),
        };
      });
    }
  };

  const resetDeleteScheduleInfo = () =>
    setDeleteSchedule(deleteScheduleDefault);

  const filterByTrashStatus = (
    trashStatus = false,
    comingReschedules = null,
  ) => {
    const { reschedules = [] } = schedule || {};

    const reschedulesToCheck = comingReschedules
      ? [...comingReschedules]
      : [...reschedules];

    return reschedulesToCheck.filter(({ inTrash }) => {
      if (!trashStatus) return !inTrash;
      return inTrash;
    });
  };

  const handleSchedulePlanToTrash = () => {
    let newDeliveryDateChange = null;

    const { item = {} } = deleteSchedule || {};
    const { reschedules = [], finalDate = "" } = schedule || {};

    const newReschedules = changeSchedulePlanTrashStatus(reschedules, item);

    setSchedule((current) => {
      return {
        ...current,
        reschedules: [...newReschedules],
      };
    });

    resetDeleteScheduleInfo();

    const filteredReschedules = filterByTrashStatus(false, newReschedules);
    const newDeliveryDate = getCustomDeliveryDate(filteredReschedules, true);

    if (finalDate === newDeliveryDate) return;

    newDeliveryDateChange = {
      isLastSchedule: true,
      newDeliveryDate: getCustomDeliveryDate(filteredReschedules, true),
    };

    setNewDeliveryDateChange(newDeliveryDateChange);
  };

  const displayNewDateTableBody = () => {
    const reschedulesData = filterByTrashStatus(filterByInTrash);

    reschedulesData.sort((a, b) => {
      const a_attr = a.deliveryDate;
      const b_attr = b.deliveryDate;
      const secondResult = b_attr > a_attr ? -1 : 0;

      return a_attr > b_attr ? 1 : secondResult;
    });

    const archiveOp = intl.formatMessage(messages.archive);
    const unArchiveOp = intl.formatMessage(messages.unArquive);

    const dinamicLabel = !filterByInTrash ? archiveOp : unArchiveOp;

    return reschedulesData.map((item, index) => {
      return (
        <tr key={index}>
          <td align="center" className="forecastCell">
            {getPatternDateFormat(item.deliveryDate)}
          </td>
          <td className="reasonCell" align="center">
            <div className="reasonCell2" title={item.reason}>
              {item.reason}
            </div>
          </td>
          {!filterByInTrash && (
            <td
              align="center"
              className="currentDeadlineCell"
              onClick={() => handleDeadlineChange(item.deliveryDate)}
            >
              <Radio
                value={item.deliveryDate ? item.deliveryDate : false}
                checked={item.currentDeadline ? item.currentDeadline : false}
              />
            </td>
          )}

          <td align="center" className="deleteSchedulePlanTD">
            <MaterialCustomMenu
              menuActions={[
                {
                  icon: <i className="fas fa-archive" />,
                  text: dinamicLabel,
                  action: () => setDeleteSchedule({ item, show: true }),
                },
              ]}
            />
          </td>
        </tr>
      );
    });
  };

  const displayInvertedInTrashView = () => {
    setFilterByInTrash(!filterByInTrash);
  };

  const renderHistoryTable = () => {
    const dinamicLabel = !filterByInTrash
      ? intl.formatMessage(messages.showArchived)
      : intl.formatMessage(messages.showUnArchived);

    return (
      <>
        <table className="simple-table" style={{ marginBottom: "0px" }}>
          <thead>
            <tr>
              <td className="forecastCell">{labels.newFirstBox}</td>
              <td className="reasonCell">{reasonTitle}</td>
              <>
                {!filterByInTrash && (
                  <td className="currentDeadlineCell">Deadline</td>
                )}
                <td align="center" className="deleteSchedulePlanTD">
                  <MaterialCustomMenu
                    menuActions={[
                      {
                        icon: <i className="fas fa-archive" />,
                        text: dinamicLabel,
                        action: () => displayInvertedInTrashView(),
                      },
                    ]}
                  />
                </td>
              </>
            </tr>
          </thead>
        </table>

        <div className="fixedScroll deliveryDateFixedScroll">
          <table className="simple-table" style={{ marginTop: "0px" }}>
            <tbody>{displayNewDateTableBody()}</tbody>
          </table>
        </div>
      </>
    );
  };

  const showError = () => {
    if (datesValidation.error) {
      return <div style={{ marginTop: "5px" }}>{datesValidation.message}</div>;
    }

    return null;
  };

  const openNewDeliveryModal = (option) => {
    setOpenDeliveryDateModal(option);
  };

  const openScheduleConfirmModal = (option) => {
    setOpenConfirmModal(option);
  };

  const handleScheduleConfirmation = () => {
    setSchedule((current) => {
      return {
        ...current,
        deliveryDateSet: true,
        falseFinalDate: null,
        reschedules: [
          ...current.reschedules,
          {
            deliveryDate: current.finalDate,
            reason: "Baseline",
          },
        ],
      };
    });

    openScheduleConfirmModal(false);
  };

  const handleScheduleCancelation = () => {
    setSchedule((current) => {
      return {
        ...current,
        finalDate: null,
        falseFinalDate: null,
      };
    });

    openScheduleConfirmModal(false);
  };

  const displayConfirmScheduleModal = () => {
    const modalTitle = intl.formatMessage(messages.areYouSure);

    return (
      <FormDialog
        open={openConfirmModal}
        title={modalTitle}
        onConfirm={handleScheduleConfirmation}
        onCancel={handleScheduleCancelation}
        bodyStyle={{ padding: "0 15px 15px 15px" }}
      >
        <div>
          <Row style={{ marginTop: "10px" }}>
            {intl.formatMessage(messages.confirmInformation)}
          </Row>
          <Row>
            <Col md={4}>
              <MaterialTextField
                id="firstBox"
                type="date"
                label={labels.firstBox}
                value={
                  schedule && schedule.initialDate ? schedule.initialDate : ""
                }
                variant="standard"
                disabled
              />
            </Col>
            <Col md={4} style={{ paddingLeft: "0px" }}>
              <MaterialTextField
                id="secondBox"
                type="date"
                label={labels.secondBox}
                value={schedule && schedule.finalDate ? schedule.finalDate : ""}
                disabled
                variant="standard"
              />
            </Col>
          </Row>
        </div>
      </FormDialog>
    );
  };

  const handleCancelChange = () => {
    setSchedule((current) => {
      return {
        ...current,
        newDeliveryReason: "",
        falseFinalDate: null,
      };
    });

    setNewDeliveryDateConfig((current) => {
      return {
        ...current,
        showNewDeliveryDate: false,
      };
    });

    openNewDeliveryModal(false);
  };

  const handleConfirmNewDate = () => {
    setSchedule((current) => {
      return {
        ...current,
        finalDate: current.deliveryDate,
        falseFinalDate: null,
        newDeliveryReason: "",
        reschedules: [
          ...current.reschedules,
          {
            deliveryDate: current.deliveryDate,
            reason: current.newDeliveryReason,
            inTrash: false,
          },
        ],
        didUpdate: true,
      };
    });

    setValidation({
      ...datesValidation,
      error: null,
      type: null,
      message: null,
    });

    openNewDeliveryModal(false);
  };

  const displayNewDeliveryDateModal = () => {
    const modalTitle = intl.formatMessage(messages.newDeliveryDate);

    return (
      <FormDialog
        open={openDeliveryDateModal}
        title={modalTitle}
        onConfirm={handleConfirmNewDate}
        onCancel={() => handleCancelChange()}
        bodyStyle={{ padding: "0 15px 15px 15px" }}
      >
        <div>
          <Row>
            <Col md={4}>
              <MaterialTextField
                id="currenteDate"
                type="date"
                label="Current Delivery Date"
                value={schedule.finalDate ? schedule.finalDate : ""}
                variant="standard"
                disabled
              />
            </Col>
            <Col md={4}>
              <MaterialTextField
                id="newDeliveryBox"
                type="date"
                label="New Delivery Date"
                value={newDeliveryDateConfig?.data || ""}
                variant="standard"
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <MaterialTextField
                id="newDeliveryReason"
                label="Reason"
                value={
                  schedule.newDeliveryReason ? schedule.newDeliveryReason : ""
                }
                multiline
                variant="standard"
                onChange={(e) =>
                  updateSingleScheduleField("newDeliveryReason", e.target.value)
                }
              />
            </Col>
          </Row>
        </div>
      </FormDialog>
    );
  };

  const validatedInitialDate = (e) => {
    let currentValue = e.target.value;

    const year = moment(currentValue).get("year");

    if (!schedule.deliveryDateSet) if (year > 3000) currentValue = null;

    setScheduleValue("initialDate", currentValue);
  };

  const allReschedulesDisabed = () => {
    const { reschedules = [] } = schedule || {};

    const activeReschedules = reschedules.filter(
      (reschedule) => reschedule.inTrash !== true,
    );

    if (activeReschedules.length > 0) return false;

    return true;
  };

  const validateFinalDate = (e) => {
    // verificar tipagem "initial Date" ou "final Date"
    // Initial Date
    // Só muda uma vez e depois não muda mais
    // Final Date
    // Se não tiver nada setado -> seta
    // Se tiver algo setado -> valida -> chama modal de replanejamento de cronograma

    const currentValue = e.target.value;

    setScheduleValue("falseFinalDate", currentValue);

    const year = moment(currentValue).get("year");

    if (!schedule.deliveryDateSet) {
      if (year > 999) {
        setSchedule((current) => {
          return {
            ...current,
            falseFinalDate: currentValue,
            finalDate: currentValue,
          };
        });

        setShowConfirmationModal(true);
      }

      return;
    }

    if (year > 999) {
      const isAfterInitialDate = moment(currentValue).isAfter(
        moment(schedule.initialDate),
      );

      if (isAfterInitialDate) {
        setNewDeliveryDateConfig((current) => {
          return {
            ...current,
            showNewDeliveryDate: true,
            data: currentValue,
          };
        });

        return;
      }

      setValidation({
        ...datesValidation,
        error: true,
        message: "",
        type: "invalidDates",
      });

      setScheduleValue("falseFinalDate", null);
    }
  };

  const handleDisableFinalDate = () => {
    const year = moment(schedule.initialDate).get("year");

    if (year > 999) return false;

    return true;
  };

  const displayScheduleLayout = () => {
    return (
      <div>
        <Row className="simpleTableBody topBox">
          <Col md={5}>
            <MaterialTextField
              id="firstBox"
              type="date"
              label={labels.firstBox}
              value={schedule.initialDate || ""}
              variant="standard"
              onChange={(e) => validatedInitialDate(e)}
              disabled={!allReschedulesDisabed()}
            />
          </Col>
          <Col md={5}>
            <MaterialTextField
              id="secondBox"
              type="date"
              label={labels.newFirstBox}
              value={schedule.falseFinalDate || ""}
              variant="standard"
              onChange={(e) => validateFinalDate(e)}
              disabled={handleDisableFinalDate()}
            />
          </Col>
          {!allReschedulesDisabed() && (
            <Col md={2} align="center">
              <i className="fas fa-check-circle" style={{ fontSize: "23px" }} />
            </Col>
          )}
        </Row>
        <Row>
          <Col md={12}>{datesValidation.error && showError()}</Col>
        </Row>
        <Row>
          <Col md={12}>{renderHistoryTable()}</Col>
        </Row>
      </div>
    );
  };

  const unOrAchiveDialogConfirmation = filterByInTrash
    ? "unArchiveConfirmation"
    : "archiveConfirmation";
  const unOrAchiveDialog = filterByInTrash ? "unArchive" : "archive";
  return (
    <div className="box box-primary">
      <div id="header-insight-evaluation" className="box-header with-border">
        <i className={labels.iconClass} />
        <h3 className="box-title"> {labels.titleLabel}</h3>
        {saveButton && saveButtonJSX()}
        <br />
      </div>
      <div className="box-body simpleTableEffort">
        {displayScheduleLayout()}
      </div>
      {displayNewDeliveryDateModal()}
      {displayConfirmScheduleModal()}

      <ConfirmationDialog
        open={deleteSchedule.show}
        title={intl.formatMessage(globalMessages.entityConfirmationAction, {
          action: intl
            .formatMessage(globalMessages[unOrAchiveDialog])
            .toLowerCase(),
          entity: intl.formatMessage(globalMessages.date),
        })}
        description={intl.formatMessage(
          globalMessages[unOrAchiveDialogConfirmation],
        )}
        onConfirm={() => handleSchedulePlanToTrash()}
        onCancel={() => setDeleteSchedule(deleteScheduleDefault)}
        cancelText={intl.formatMessage(globalMessages.cancel)}
      />
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  if (!_.isEqual(prevProps.schedule, nextProps.schedule)) return false;
  if (!_.isEqual(prevProps.saveButton, nextProps.saveButton)) return false;
  if (!_.isEqual(prevProps.saveButtonJSX, nextProps.saveButtonJSX))
    return false;
  return true;
}

export default React.memo(injectIntl(ScheduleTable), areEqual);
