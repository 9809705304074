import moment from "moment";
import React, { useState } from "react";
import { defineMessages, injectIntl } from "react-intl";
import styled from "styled-components";
import {
  defaultGoalsDisplayOrder,
  getParticipantPeriodOptions,
} from "../../../constants/goalsAndBonus";
import {
  formatBRL,
  getBonusGoalsExportData,
  getDetailedBonusGoalsExportData,
} from "../../../utils/goalsBonus/display";
import utils from "../../../utils/toolUtils";
import Avatar from "../Avatar";
import { simpleSaveAsExcel } from "../Export/ExportCSV";
import MeasurementLevelsBar from "../MeasurementLevelsBar";
import GoalsTableList from "./includes/GoalsTableList";

const { getObjectInfoById, getToFixed, reorderObjectBySlugs } = utils;

const messages = defineMessages({
  gb_table_text_participant: { id: "gb_table_text_participant" },
  gb_table_text_goals_upper: { id: "gb_table_text_goals_upper" },
  gb_table_text_period_upper: { id: "gb_table_text_period_upper" },
  global_export: { id: "global.export" },
  gb_table_text_reach_upper: { id: "gb_table_text_reach_upper" },
  gb_table_text_limit_bonus: { id: "gb_table_text_limit_bonus" },
  gb_table_text_limit_bonus_total: { id: "gb_table_text_limit_bonus_total" },
  gb_table_text_no_participant_show: {
    id: "gb_table_text_no_participant_show",
  },
  gb_table_text_salaries_quantity_short: {
    id: "gb_table_text_salaries_quantity_short",
  },
  gb_table_text_total_bonus: { id: "gb_table_text_total_bonus" },
  gb_table_text_no_goals_evaluation: {
    id: "gb_table_text_no_goals_evaluation",
  },
  gb_table_text_salary_base_ext_upper: {
    id: "gb_table_text_salary_base_ext_upper",
  },
});

const ExportButtons = styled.div`
  a {
    padding: 10px;
  }

  i {
    font-size: 11px;
  }
`;

const BonusManagementTable = ({
  goalBonusAdministration,
  periodFilter = {},
  isPeriodFilterActive,
  calcType,
  displayParticipants,
  allTotalBonus,
  totalLimitBonus,
  userAccess = {},
  isAdmin,
  selectAndOpenInsight,
  allThemes,
  intl,
}) => {
  const [showGoalDetails, setShowGoalDetails] = useState([]);

  const participantPeriodOptions = getParticipantPeriodOptions();

  const blockPrivateValues =
    (userAccess.accesses.indexOf("OWN_GOALS") > -1 ||
      userAccess.accesses.indexOf("AUDITOR") > -1) &&
    !isAdmin;

  const toggleShowDetails = (goalId) => {
    if (showGoalDetails.indexOf(goalId) === -1) {
      setShowGoalDetails((oldState) => [...oldState, goalId]);
      return;
    }

    setShowGoalDetails(
      showGoalDetails.filter((goalIdState) => goalId !== goalIdState)
    );
  };

  function percentage(val) {
    return `${getToFixed(val)}%`;
  }

  const tableHeadNames = [
    {
      label: intl.formatMessage(messages.gb_table_text_participant),
      colspan: 9,
      width: "auto",
    },
    {
      label: intl.formatMessage(messages.gb_table_text_no_goals_evaluation),
      colspan: 1,
      width: "320px",
    },
    {
      label: `% ${intl.formatMessage(messages.gb_table_text_goals_upper)}`,
      colspan: 1,
      width: "120px",
    },
    {
      label: `% ${intl.formatMessage(messages.gb_table_text_reach_upper)}`,
      colspan: 1,
      width: "120px",
    },
    {
      label: intl.formatMessage(messages.gb_table_text_salaries_quantity_short),
      colspan: 1,
      width: "120px",
      block: blockPrivateValues,
    },
    {
      label: intl.formatMessage(messages.gb_table_text_period_upper),
      colspan: 1,
      width: "120px",
      block: blockPrivateValues,
    },
    {
      label: intl.formatMessage(messages.gb_table_text_salary_base_ext_upper),
      colspan: 1,
      width: "120px",
      block: blockPrivateValues,
    },
    {
      label: intl.formatMessage(messages.gb_table_text_total_bonus),
      colspan: 1,
      width: "120px",
      block: blockPrivateValues,
    },
    {
      label: intl.formatMessage(messages.gb_table_text_limit_bonus),
      colspan: 1,
      width: "120px",
      block: blockPrivateValues,
    },
  ];

  const filteredCollumns = [];

  tableHeadNames.forEach((headInfo) => {
    if (!headInfo.block) filteredCollumns.push(headInfo);
  });

  const getGroupedExport = () => {
    return getBonusGoalsExportData({
      allThemes,
      participants: displayParticipants,
    });
  };

  const getDetailedExport = () => {
    return getDetailedBonusGoalsExportData({
      allThemes,
      goalBonusAdministration,
      participants: displayParticipants,
      periodFilterActive: isPeriodFilterActive,
    });
  };

  const handleExport = (type) => {
    const exportInfo =
      type === "detailed" ? getDetailedExport() : getGroupedExport();

    const timeStampString = moment().toJSON();

    simpleSaveAsExcel(
      exportInfo.data,
      exportInfo.headers,
      null,
      `report-${type}-${timeStampString}`,
      [{ line: 1, fill: "eeeeee" }]
    );
  };

  return (
    <div>
      {isAdmin && (
        <ExportButtons className="hidden-print">
          <a
            onClick={() => handleExport("grouped")}
            style={{ cursor: "pointer" }}
          >
            <i className="fas fa-download" />
            {" Relatório bônus"}
          </a>
          <a
            onClick={() => handleExport("detailed")}
            style={{ cursor: "pointer" }}
          >
            <i className="fas fa-download" />
            {" Relatório detalhado"}
          </a>
        </ExportButtons>
      )}
      <table className="simple-table horizontal-scroll">
        <thead style={{ fontSize: "12px" }}>
          <tr className="small-font block-white-space">
            {filteredCollumns.map((tableHead) => (
              <td
                colSpan={tableHead.colspan}
                key={filteredCollumns.label}
                style={{ width: tableHead.width || "auto" }}
              >
                {tableHead.label}
              </td>
            ))}
          </tr>
        </thead>
        <tbody style={{ fontSize: "16px", fontWeight: "500" }}>
          {displayParticipants.map(
            ({
              roleInfo,
              participantInfo,
              participantGoals,
              bonusInfo = {},
            }) => {
              if (!participantInfo.id || !participantInfo.id === -1) {
                return null;
              }
              const showParticipantGoals =
                showGoalDetails.indexOf(participantInfo.id) > -1;
              const { memberParticipant = {} } = participantInfo;
              const {
                realGoalsReachSum,
                reachBonusWeightSum,
                aditionalSalaries,
                totalBonus,
                limitBonus,
                barMeasurement,
                barBonusMeasurement,
              } = bonusInfo;

              const divMaxHeight = (participantGoals.length + 2) * 40;

              const finalBarMeasuremnt =
                calcType === "bonus" ? barBonusMeasurement : barMeasurement;

              const displayParticipantGoals = reorderObjectBySlugs(
                defaultGoalsDisplayOrder,
                participantGoals,
                "relationshipType"
              );

              return (
                <React.Fragment key={`${memberParticipant.id}`}>
                  <tr
                    style={{
                      cursor: "pointer",
                      userSelect: "none",
                      transition: "0.3s",
                    }}
                    onClick={() => toggleShowDetails(participantInfo.id)}
                    className="small-font block-white-space"
                  >
                    <td
                      colSpan="9"
                      style={{
                        borderLeft: showParticipantGoals
                          ? "3px solid #6b42a9"
                          : "1px solid #ccc",
                      }}
                    >
                      <div className="row">
                        <div
                          className="col-xs-12"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            style={{
                              width: "60px",
                              height: "60px",
                              lineHeight: "60px",
                              backgroundColor: "#eee",
                              textAlign: "center",
                              borderRadius: "50%",
                              display: "block",
                            }}
                            name={memberParticipant.name}
                            src={memberParticipant.avatarBlobId}
                          />

                          <div className="col" style={{ paddingLeft: "25px" }}>
                            <h5
                              style={{
                                marginTop: "8px",
                              }}
                            >
                              {memberParticipant.name}
                            </h5>
                            <h6>{roleInfo.role}</h6>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <MeasurementLevelsBar width={`${finalBarMeasuremnt}px`} />
                    </td>
                    <td align="right">{percentage(realGoalsReachSum, 1)}</td>
                    <td align="right">{percentage(reachBonusWeightSum, 1)}</td>
                    {!blockPrivateValues && (
                      <td align="right">{getToFixed(aditionalSalaries, 2)}</td>
                    )}
                    {!blockPrivateValues && (
                      <td align="right">
                        {
                          getObjectInfoById(
                            participantInfo.period,
                            participantPeriodOptions,
                            "value"
                          ).label
                        }
                      </td>
                    )}
                    {!blockPrivateValues && (
                      <td align="right">{formatBRL(participantInfo.base)}</td>
                    )}
                    {!blockPrivateValues && (
                      <td align="right">{formatBRL(totalBonus)}</td>
                    )}
                    {!blockPrivateValues && (
                      <td align="right">{formatBRL(limitBonus)}</td>
                    )}
                  </tr>
                  <tr
                    style={
                      showParticipantGoals
                        ? {
                            display: "table-row",
                            borderLeft: "5px solid #6b42a9",
                          }
                        : { display: "none" }
                    }
                    className="block-hover"
                  >
                    <td style={{ padding: "15px" }} colSpan="100">
                      <div
                        className="custom-transition"
                        style={{
                          maxHeight: showParticipantGoals
                            ? divMaxHeight
                            : "1px",
                          opacity: showParticipantGoals ? "1" : "0",
                        }}
                      >
                        <GoalsTableList
                          goalBonusAdministration={goalBonusAdministration}
                          periodFilterActive={isPeriodFilterActive}
                          displayGoals={displayParticipantGoals}
                          selectAndOpenInsight={selectAndOpenInsight}
                          periodFilter={periodFilter}
                          participantInfo={participantInfo}
                        />
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              );
            }
          )}
          {displayParticipants.length === 0 && (
            <tr>
              <td colSpan="100">
                {intl.formatMessage(messages.gb_table_text_no_participant_show)}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {displayParticipants.length > 0 && !blockPrivateValues && (
        <div style={{ padding: "0px 5px" }}>
          <h5 align="left">
            <font style={{ fontWeight: "normal" }}>
              {`${intl.formatMessage(messages.gb_table_text_total_bonus)}: `}
            </font>
            {formatBRL(allTotalBonus)}
          </h5>
        </div>
      )}
      {displayParticipants.length > 0 && !blockPrivateValues && (
        <div style={{ padding: "0px 5px" }}>
          <h5 align="left">
            <font style={{ fontWeight: "normal" }}>
              {`${intl.formatMessage(
                messages.gb_table_text_limit_bonus_total
              )}: `}
            </font>
            {formatBRL(totalLimitBonus)}
          </h5>
        </div>
      )}
    </div>
  );
};

export default injectIntl(BonusManagementTable);
