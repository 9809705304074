import React from "react";
import FilterEvaluationCard from "../../../../../Common/FilterEvaluationCard";
import { getRelativePercentage } from "../../../../../../utils/MathUtils";
import utils from "../../../../../../utils/toolUtils";

const { getFullReadinessValues, filterPeopleByReadiness, getLabelBySlug } =
  utils;

const MapMediumTermField = (props) => {
  const {
    filteredEvaluations,
    readinessOptions,
    subtitleColor,
    messages,
    intl,
    selectAndOpenInsight,
  } = props;

  let people = [];
  let evaluations = {
    JOB: [],
    TRAIL: [],
  };

  filteredEvaluations.forEach(({ peopleEvaluation, insightId }) => {
    const { professionalProfile, evaluationTarget } = peopleEvaluation;
    const { professional } = professionalProfile;
    const { carrearPath } = evaluationTarget;
    const { mainTrail } = carrearPath;

    if (mainTrail) {
      evaluations =
        mainTrail.path === "JOB"
          ? { ...evaluations, JOB: [...evaluations.JOB, mainTrail.readiness] }
          : {
              ...evaluations,
              TRAIL: [...evaluations.TRAIL, mainTrail.readiness],
            };

      people = [...people, { ...professional, mainTrail, insightId }];
    }
  });

  people = filterPeopleByReadiness(people, "MEDIUM_TERM_READY");

  const MEDIUM_TERM_READY = {
    JOB: getFullReadinessValues(evaluations.JOB).MEDIUM_TERM_READY,
    TRAIL: getFullReadinessValues(evaluations.TRAIL).MEDIUM_TERM_READY,
  };

  const totalEvaluations = MEDIUM_TERM_READY.JOB + MEDIUM_TERM_READY.TRAIL;

  const headerFilters = [
    {
      label: intl.formatMessage(messages.global_total),
      value: totalEvaluations,
      slug: "all",
      bgColor: "#ddd",
    },
    {
      label: intl.formatMessage(messages.career_job),
      value: MEDIUM_TERM_READY.JOB,
      bottomLabel: `${getRelativePercentage(
        MEDIUM_TERM_READY.JOB,
        totalEvaluations,
      )}%`,
      slug: "job",
    },
    {
      label: intl.formatMessage(messages.career_trail),
      value: MEDIUM_TERM_READY.TRAIL,
      bottomLabel: `${getRelativePercentage(
        MEDIUM_TERM_READY.TRAIL,
        totalEvaluations,
      )}%`,
      slug: "trail",
    },
  ];

  const bodyTable = {
    title: intl.formatMessage(messages.labels_professionals),
    values: [
      ...people.map(({ insightId, name, mainTrail, avatarBlobId }) => {
        return {
          label: name,
          avatarBlobId,
          clickFunction: () => selectAndOpenInsight(insightId),
          iconLetter: mainTrail.path === "JOB" ? "J" : "T",
          slugs: mainTrail.path === "JOB" ? ["job", "all"] : ["trail", "all"],
        };
      }),
    ],
  };

  return (
    <FilterEvaluationCard
      cardTitle={getLabelBySlug(
        "MEDIUM_TERM_READY",
        readinessOptions,
      ).toUpperCase()}
      headerFilters={headerFilters}
      bodyTable={bodyTable}
      fillColor={subtitleColor}
      undefinedMessage={intl.formatMessage(messages.global_undefined)}
    />
  );
};

export default MapMediumTermField;
