import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { createKeyInsight } from "../../../../actions/okrActions";
import InsightKeyDialog from "../../../Common/Okr/forms/InsightKeyDialog";
import { getSelectedToolStates } from "../../../customMapStates";
import { getQuestionInfoById } from "../../../../utils/okr/filter";
import { orderArrayByObjAttr } from "../../../../utils/ArrayUtils";

const Okr = (props) => {
  const [addedKeys, setAddedKeys] = useState([]);

  const {
    type,
    clearViewCallback,
    selectedTool = {},
    allAnswers = [],
    createKeyInsight,
    params = {},
    okr,
  } = props;

  const { insightQuestion = {} } = params;

  useEffect(() => {
    const newAddedKeys = [];

    if (allAnswers[insightQuestion.id]) {
      allAnswers[insightQuestion.id].forEach(({ okrConfigurationKeyId }) => {
        if (okrConfigurationKeyId) newAddedKeys.push(okrConfigurationKeyId);
      });
    }

    if (!_.isEqual(newAddedKeys, addedKeys)) setAddedKeys(newAddedKeys);
  }, [addedKeys, allAnswers, insightQuestion.id]);

  const getKeyResults = () => {
    const keys = [];

    okr.objectives.forEach((objective) => {
      objective.keys.forEach((key) => {
        keys.push(key);
      });
    });

    return keys;
  };

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
  };

  const handleSaveInsight = ({ id }) => {
    if (insightQuestion && id) {
      const newKeyResult = getQuestionInfoById(id, getKeyResults());

      createKeyInsight(newKeyResult, insightQuestion.id, params.order);
    }

    clearDialogControll();
  };

  const saveInsightKeyResult = (keyResult) => {
    if (insightQuestion && keyResult.id) {
      createKeyInsight(keyResult, insightQuestion.id, params.order);
    }

    clearDialogControll();
  };

  return (
    <InsightKeyDialog
      open
      afterConfirm={handleSaveInsight}
      saveInsightKeyResult={saveInsightKeyResult}
      afterCancel={clearDialogControll}
      okr={okr}
      selectedToolId={selectedTool.id}
      keyResults={orderArrayByObjAttr(getKeyResults(), "title")}
      insightQuestionId={params.insightQuestion.okrConfigurationObjectiveId}
      addedKeys={addedKeys}
    />
  );
};

const mapStateToProps = (state) => {
  const { selectedTool, allAnswers } = getSelectedToolStates(state);

  const { user, okr } = state;

  return {
    userProfile: user,
    selectedTool,
    allAnswers,
    okr,
  };
};

export default connect(mapStateToProps, {
  createKeyInsight,
})(Okr);

Okr.propTypes = {
  clearViewCallback: PropTypes.func.isRequired,
  createKeyInsight: PropTypes.func.isRequired,
  selectedTool: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  allAnswers: PropTypes.object.isRequired,
};
