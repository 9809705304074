import { defineMessages } from "react-intl";
import { goalEntityBuild } from "./entity";
import { translatedText } from "../translationUtils";
import { getObjectDifference } from "../ArrayUtils";
import { allowedEditingRelationType } from "../../constants/kpis";
import { getDateTransformation, getPeriodsDistributionInfo } from "./calc2";
import { floatNumber } from "../MathUtils";

const messages = defineMessages({
  kpi_error_small_title: { id: "kpi_error_small_title" },
  kpi_error_repeated_title: { id: "kpi_error_repeated_title" },
  kpi_error_small_description: { id: "kpi_error_small_description" },
  kpi_error_block_relationship: { id: "kpi_error_block_relationship" },
  kpi_error_repeated_target: { id: "kpi_error_repeated_target" },
  kpi_error_invalid_target: { id: "kpi_error_invalid_target" },
  kpi_error_invalid_date: { id: "kpi_error_invalid_date" },
  kpi_error_invalid_same_month: { id: "kpi_error_invalid_same_month" },
  kpi_error_invalid_distribution: { id: "kpi_error_invalid_distribution" },
  kpi_error_years_limit: { id: "kpi_error_years_limit" },
  kpi_error_years_invalid: { id: "kpi_error_years_invalid" },
  kpi_warning_need_min_administrator: {
    id: "kpi_warning_need_min_administrator",
  },
});

const translation = (id, values) => translatedText(id, messages, values);

export const getNewIndicatorErrors = (form, currentGoals) => {
  const errors = [];

  const compareEqualList = [
    form.target.gate,
    form.target.appropriated,
    form.target.exceeded,
  ];

  const addError = (slug, message) => {
    errors.push({ slug, message });
  };

  function sameTitle(title1, title2) {
    return title1.toLowerCase() === title2.toLowerCase();
  }

  if (form.title.length < 2) {
    addError("invalidTitle", translation("kpi_error_small_title"));
  }

  if (
    currentGoals?.find(
      (goal) => sameTitle(goal.title, form.title) && goal.id !== form.id,
    )?.id
  ) {
    addError("invalidTitle", translation("kpi_error_repeated_title"));
  }

  for (const v1 of compareEqualList) {
    let repeated = 0;

    compareEqualList.forEach((val) => {
      if (Number(val) === Number(v1)) repeated++;
    });

    if (repeated > 1) {
      addError("repeatedTarget", translation("kpi_error_repeated_target"));
      break;
    }
  }

  const targets = {
    gate: Number(form.target.gate),
    appropriated: Number(form.target.appropriated),
    exceeded: Number(form.target.exceeded),
  };

  const isGreaterSeqRight =
    targets.gate < targets.appropriated &&
    targets.appropriated < targets.exceeded;

  const isSmallerSeqRight =
    targets.gate > targets.appropriated &&
    targets.appropriated > targets.exceeded;

  if (!isGreaterSeqRight && !isSmallerSeqRight) {
    addError("invalidTarget", translation("kpi_error_invalid_target"));
  }

  const { period = {} } = form;
  const haveValidMonths = period.start >= 0 && period.end >= 0;

  if (!haveValidMonths) {
    addError("startDateError", translation("kpi_error_invalid_date"));
  }

  if (period.start === period.end) {
    addError("sameMonthError", translation("kpi_error_invalid_same_month"));
  }

  const { isValid: isDistValid } = getPeriodsDistributionInfo(form);

  if (!isDistValid) {
    addError(
      "distributionError",
      translation("kpi_error_invalid_distribution"),
    );
  }

  return errors;
};

export const checkIsIndicatorEditable = (
  goalId,
  relationshipType = "",
  admin = false,
) => {
  const haveEditableRelationship =
    allowedEditingRelationType.indexOf(relationshipType) > -1;

  return !goalId || haveEditableRelationship || admin;
};

export const formTransformValue = (
  formValue,
  measurementUnity,
  calculable = false,
) => {
  let finalReturn = formValue;

  if (measurementUnity === "DATE" && Number(formValue) !== 0) {
    formValue = formValue.toString();
    finalReturn = getDateTransformation(formValue, calculable);
  } else if (measurementUnity === "DATE") return "";

  return finalReturn;
};

export const getKpiTargetsCorrection = (target = {}) => {
  return {
    ...target,
    gate: floatNumber(target.gate),
    appropriated: floatNumber(target.appropriated),
    exceeded: floatNumber(target.exceeded),
  };
};

export const getGoalsTargetsCorrection = (target = {}) => {
  return {
    ...target,
    actual: target.actual,
    distribution: target.distribution,
    gate: floatNumber(target.gate),
    appropriated: floatNumber(target.appropriated),
    exceeded: floatNumber(target.exceeded),
  };
};

export const getKpiIndicatorChanges = (base, compare) => {
  const changes = [];

  const cloneBase = goalEntityBuild(base);
  const cloneCompare = goalEntityBuild(compare);

  const diff = getObjectDifference(cloneBase, cloneCompare);

  const blockDiff = ["id", "relatedInsights"];

  Object.keys(diff).forEach((key) => {
    if (blockDiff.indexOf(key) === -1) changes.push(key);
  });

  return changes;
};

export const MONETARY_TYPES = [
  "MONETARY_VALUE",
  "THOUSAND_MONETARY_VALUE",
  "MILLION_MONETARY_VALUE",
];

export function checkIsMonetaryNumber(measurementUnity) {
  return MONETARY_TYPES.indexOf(measurementUnity) > -1;
}
