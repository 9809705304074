import React from "react";
import { getValidFunc } from "../../../utils/validations";

const DeleteColumn = (props) => {
  const handleDelete = getValidFunc(props.handleDelete);

  const icon = props.icon || "fas fa-trash-alt";
  const color = props.color || "#ff3131";

  return (
    <td
      style={{ width: "40px", textAlign: "center", cursor: "pointer" }}
      className="action-column"
      onClick={handleDelete}
    >
      <i className={icon} style={{ color }} />
    </td>
  );
};

export default DeleteColumn;
