import { defineMessages } from "react-intl";
import { translatedText } from "../utils/translationUtils";

const messages = defineMessages({
  tcategory_strategy: { id: "tcategory_strategy" },
  tcategory_processes: { id: "tcategory_processes" },
  tcategory_projects: { id: "tcategory_projects" },
  tcategory_people: { id: "tcategory_people" },
  tcategory_dev: { id: "tcategory_dev" },
});

export const BUSINESS_MODEL_ID = 1;
export const COMPETITIVE_ANALYSIS = 2;
export const OKR_ID = 3;
export const MAPA_DE_PROCESSO_ID = 4;
export const BALANCED_SCORECARD_ID = 5;
export const KPIS = 6;
export const SWOT = 7;
export const ANALISE_CURVA_DE_VALOR_ID = 8;
export const PROJECTS_INITIATIVES = 9;
export const GESTAO_REUNIOES_ID = 10;
export const MARKET_ANALYSIS = 11;
export const PROCESSES_BIG_PICTURE = 12;
export const PEOPLE_BIG_PICTURE = 13;
export const STRATEGIC_FOUNDATIONS = 14;
export const PORTIFOLIO_ANALYSIS = 15;
export const EXCELLENCE_MANAGEMENT = 16;
export const PROJECT_PORTFOLIO = 17;
export const GOALS_AND_BONUS = 18;
export const PROJECT_PRIORITIZATION = 19;
export const ACTION_PLAN_ID = 20;
export const SKILLS_ASSESSMENT = 21;

const dbDrawingTools = [
  BUSINESS_MODEL_ID,
  BALANCED_SCORECARD_ID,
  PROCESSES_BIG_PICTURE,
  PEOPLE_BIG_PICTURE,
  EXCELLENCE_MANAGEMENT,
];

const dbAnalysisTools = [
  COMPETITIVE_ANALYSIS,
  SWOT,
  ANALISE_CURVA_DE_VALOR_ID,
  STRATEGIC_FOUNDATIONS,
  PORTIFOLIO_ANALYSIS,
  MARKET_ANALYSIS,
  SKILLS_ASSESSMENT,
];

const dbManagementTools = [
  OKR_ID,
  MAPA_DE_PROCESSO_ID,
  KPIS,
  PROJECTS_INITIATIVES,
  GESTAO_REUNIOES_ID,
  PROJECT_PORTFOLIO,
  GOALS_AND_BONUS,
  ACTION_PLAN_ID,
];

export const DEV_TOOLS = [PROJECT_PRIORITIZATION];

const devCategories = () => {
  const translation = (id) => translatedText(id, messages);

  return [
    {
      value: "DEV",
      label: translation("tcategory_dev"),
      color: "#ccc",
      icon: <i className="fas fa-code" />,
      tools: DEV_TOOLS,
    },
  ];
};

export const getToolCategories = () => {
  const translation = (id) => translatedText(id, messages);
  return [
    {
      value: "STRATEGY",
      label: translation("tcategory_strategy"),
      color: "#819A83",
      icon: <i className="fas fa-chess" />,
      tools: [OKR_ID, SWOT, COMPETITIVE_ANALYSIS],
    },
    {
      value: "PROCESSES",
      label: translation("tcategory_processes"),
      color: "#BF5343",
      icon: <i className="fas fa-stream" />,
      tools: [PROCESSES_BIG_PICTURE, KPIS],
    },
    {
      value: "PROJECTS",
      label: translation("tcategory_projects"),
      color: "#8DB2DF",
      icon: <i className="fas fa-project-diagram" />,
      tools: [PROJECT_PORTFOLIO, ACTION_PLAN_ID],
    },
    {
      value: "PEOPLE",
      label: translation("tcategory_people"),
      color: "#e9cc00",
      icon: <i className="fas fa-users" />,
      tools: [GOALS_AND_BONUS, PEOPLE_BIG_PICTURE, SKILLS_ASSESSMENT],
    },
    ...devCategories(),
  ];
};

export const getOriginalDatabaseToolCategory = (toolTypeId) => {
  if (dbDrawingTools.indexOf(toolTypeId) > -1) return "DRAWING";
  if (dbAnalysisTools.indexOf(toolTypeId) > -1) return "ANALYSYS";
  if (dbManagementTools.indexOf(toolTypeId) > -1) return "MANAGEMENT";
  return "DEV";
};

export const toolInsightsOrder = {
  default: [
    BUSINESS_MODEL_ID,
    BALANCED_SCORECARD_ID,
    PROCESSES_BIG_PICTURE,
    EXCELLENCE_MANAGEMENT,
    COMPETITIVE_ANALYSIS,
    SWOT,
    ANALISE_CURVA_DE_VALOR_ID,
    STRATEGIC_FOUNDATIONS,
    PORTIFOLIO_ANALYSIS,
    MARKET_ANALYSIS,
    MAPA_DE_PROCESSO_ID,
    KPIS,
    PROJECTS_INITIATIVES,
    GESTAO_REUNIOES_ID,
    GOALS_AND_BONUS,
    OKR_ID,
    PROJECT_PORTFOLIO,
    SKILLS_ASSESSMENT,
  ],
  title: [],
};

export const templateTools = [];
