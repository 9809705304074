import {
  CREATE_TEAM_MEMBER,
  CREATE_CENARY_MEMBER,
  DELETE_CENARY_MEMBER,
} from "../actions/types";

const INITIAL_STATE = {
  teamMembers: [],
  cenaryMembers: [],
};

const membersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_TEAM_MEMBER:
      return {
        ...state,
        teamMembers: [...state.teamMembers, action.payload],
      };
    case CREATE_CENARY_MEMBER:
      return {
        ...state,
        cenaryMembers: [...state.cenaryMembers, action.payload],
      };
    case DELETE_CENARY_MEMBER:
    default:
      return state;
  }
};

export default membersReducer;
