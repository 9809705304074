import React from "react";
import styled from "styled-components";
import FormDialog from "../../FormDialog";

const Box = styled.div`
  display: flex;
  padding: 15px;
  gap: 20px;
`;
const Select = styled.div`
  width: 50%;
  padding: 10px 0px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  color: #333;
  font-weight: bold;
  background-color: #eee;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #ddd;
  }
`;

const NewGoalMiddlwareDialog = ({ closeDialog, toggleOpenFormDialogs }) => {
  const handleClick = (slug) => {
    toggleOpenFormDialogs(slug);
  };

  return (
    <FormDialog
      open
      title="Nova meta"
      description="Selecione como serão preenchidos os dados da meta"
      hideConfirm
      onCancel={closeDialog}
    >
      <Box>
        <Select onClick={() => handleClick("newGoal")}>Manual</Select>
        <Select onClick={() => handleClick("newLinkedGoal")}>
          Conexão com indicadores
        </Select>
      </Box>
    </FormDialog>
  );
};

export default NewGoalMiddlwareDialog;
