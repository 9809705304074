import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  YAxis,
  XAxis,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import CustomGraphTooltip from "./CustomGraphTooltip";

const SimpleHorizontalBarChart = ({
  data,
  containerWidth,
  containerHeight,
  tickSize,
  legendLabel,
  showLegend,
  fillColor,
  labelConstrast,
  hideTooltipLabelName,
}) => {
  return (
    <div
      style={{
        width: containerWidth,
        height: containerHeight,
      }}
    >
      <ResponsiveContainer width={containerWidth} height={containerHeight}>
        <BarChart data={data} layout="vertical" barCategoryGap="20%">
          <YAxis dataKey="name" type="category" width={tickSize} />
          <XAxis dataKey="value" type="number" tick={false} axisLine={false} />
          <Tooltip
            content={
              <CustomGraphTooltip hideTooltipLabelName={hideTooltipLabelName} />
            }
          />
          {showLegend && <Legend />}
          <Bar
            dataKey="value"
            fill={fillColor}
            legendType="circle"
            name={legendLabel ? legendLabel : null}
          >
            <LabelList
              dataKey="value"
              position="insideRight"
              fill={labelConstrast}
              formatter={(val) => (val === 0 ? "" : val)}
              fontSize="12px"
            />
            {data.map((entry, index) => (
              <Cell fill={entry.color ? entry.color : fillColor} key={index} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

SimpleHorizontalBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      name: PropTypes.any.isRequired,
      color: PropTypes.string,
    }),
  ).isRequired,
  containerWidth: PropTypes.any,
  containerHeight: PropTypes.any,
  showLegend: PropTypes.bool,
  legendLabel: PropTypes.string,
  fillColor: PropTypes.string,
  tickSize: PropTypes.number,
  labelConstrast: PropTypes.string,
  hideTooltipLabelName: PropTypes.bool,
};

SimpleHorizontalBarChart.defaultProps = {
  data: [],
  containerWidth: "100%",
  containerHeight: 300,
  showLegend: false,
  legendLabel: null,
  tickSize: 90,
  fillColor: "#8884d8",
  labelConstrast: "#fff",
  hideTooltipLabelName: false,
};

export default injectIntl(SimpleHorizontalBarChart);
