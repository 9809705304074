import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import ToolManagementView from "./ToolManagementView";

const ToolManagementContainer = (props) => {
  const {
    selectAndOpenInsight,
    entities,
    open = false,
    filterData,
    setFilterData,
    captureSelectsData,
  } = props;
  const { allAnswers } = entities;

  function getAnswersByGoalStatus() {
    const evaluations = {
      NAO_INICIADA: [],
      NAO_ATINGIDA: [],
      PARCIALMENTE_ATINGIDA: [],
      ATINGIDA: [],
      SUPERADA: [],
      CANCELADA: [],
    };

    function pushEvaluation(slug, goalInfo) {
      evaluations[slug].push(goalInfo);
    }

    function findQuestionEqual(slug, questionId) {
      return evaluations[slug].some(
        (evaluation) => evaluation.questionId === questionId,
      );
    }

    function includeInsightInQuestion(slug, questionId, answer) {
      const selectedEvaluation = evaluations[slug];

      evaluations[slug] = selectedEvaluation.map((evaluation) => {
        if (evaluation.questionId === questionId) {
          return { ...evaluation, answers: [...evaluation.answers, answer] };
        }
        return { ...evaluation };
      });
    }

    const newStatus = !props.showHiddenExpectation
      ? "status"
      : "expectationStatus";

    function mountEvaluationByStatus(status, answer) {
      if (
        findQuestionEqual(status, answer.question.id) &&
        answer.goal[newStatus] === status
      ) {
        includeInsightInQuestion(status, answer.question.id, answer);
      } else if (answer.goal[newStatus] === status) {
        pushEvaluation(status, {
          questionId: answer.question.id,
          questionText: answer.question.text,
          answers: [answer],
        });
      }
    }

    Object.keys(allAnswers).forEach((questionId) => {
      allAnswers[questionId].forEach((answer) => {
        const { goal } = answer;

        if (goal) {
          switch (goal[newStatus]) {
            case "NAO_INICIADA":
              mountEvaluationByStatus("NAO_INICIADA", answer);
              break;
            case "NAO_ATINGIDA":
              mountEvaluationByStatus("NAO_ATINGIDA", answer);
              break;
            case "PARCIALMENTE_ATINGIDA":
              mountEvaluationByStatus("PARCIALMENTE_ATINGIDA", answer);
              break;
            case "ATINGIDA":
              mountEvaluationByStatus("ATINGIDA", answer);
              break;
            case "SUPERADA":
              mountEvaluationByStatus("SUPERADA", answer);
              break;
            case "CANCELADA":
              mountEvaluationByStatus("CANCELADA", answer);
              break;
            default:
              break;
          }
        }
      });
    });

    return evaluations;
  }

  return (
    open && (
      <ToolManagementView
        toolID={props.toolID}
        entities={entities}
        evaluations={getAnswersByGoalStatus()}
        selectAndOpenInsight={selectAndOpenInsight}
        filterData={filterData}
        setFilterData={setFilterData}
        captureSelectsData={captureSelectsData}
      />
    )
  );
};

const mapStateToProps = (state) => {
  const { toolsConfigurations } = state;

  return {
    workspace: state.workspacesData.selectedWorkspace,
    toolsConfigurations,
    showHiddenExpectation: toolsConfigurations.showHiddenExpectation,
  };
};

export default injectIntl(connect(mapStateToProps)(ToolManagementContainer));
