import * as actions from "./_actions";
import send from "../../dispatcher";

function observeGoalsBonus({ stompClient, dispatch }) {
  stompClient.subscribe("/selected-tools/addGoalsBonusProgram", (response) => {
    send(actions.putGoalsBonusConfiguration, response, dispatch);
  });

  stompClient.subscribe("/goals-bonus-programs/update", (response) => {
    send(actions.putGoalsBonusConfiguration, response, dispatch);
  });

  stompClient.subscribe("/goals-bonus-programs/cenary", (response) => {
    send(actions.postBoardCenaryLink, response, dispatch);
  });

  stompClient.subscribe("/goals-bonus-programs/theme", (response) => {
    send(actions.postBoardThemeLink, response, dispatch);
  });

  stompClient.subscribe("/goals-bonus-programs/question", (response) => {
    send(actions.postBoardQuestionLink, response, dispatch);
  });

  stompClient.subscribe("/goals-bonus-programs/insight", (response) => {
    send(actions.postBoardInsightLink, response, dispatch);
  });
}

export default observeGoalsBonus;
