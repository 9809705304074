import {
  checkAccess,
  filterAllAnswersByAccess,
  filterCompanyDataByAccess,
  filterScenariosByAccess,
  filterToolsByAccess,
  filterWorkspacesByAccess,
} from "../../utils/accessLevels";
import { getFilteredEntityByFiledStatus } from "../../utils/ArrayUtils";
import { filterSelectedToolEntities } from "../../utils/entityUtils";
import { buildMemberNames } from "../../utils/peopleEvaluationUtils";

export const insightsGoalState = (state) => {
  const { insightGoal } = state;
  const allInsightGoals =
    insightGoal && insightGoal.allInsightGoals
      ? insightGoal.allInsightGoals
      : {};

  return {
    allInsightGoals,
    insightGoal,
  };
};

export const getCompAnalysisStates = (state) => {
  const {
    competitiveAnalysis = {},
    selectedTool = {},
    insightData = {},
  } = state;
  const { selectedInsight = {} } = insightData || {};
  const { id: insightId = 0 } = selectedInsight || {};

  const { id: selectedToolId = 0 } = selectedTool;

  const {
    all: allCpAnalysisAdministration = [],
    currentToolType = "",
    loading: isLoading = false,
    allEvaluations = [],
    managementData = [],
    boardPoints,
    overviewData,
  } = competitiveAnalysis || {};

  const currentAdministration =
    allCpAnalysisAdministration.filter(
      (adm) => adm.selectedToolId === selectedToolId
    )[0] || {};

  const currentInsightEvaluation =
    allEvaluations.filter(
      (eva) => Number(eva.insightId) === Number(insightId)
    )[0] || false;

  return {
    isLoading,
    boardPoints,
    managementData,
    allEvaluations,
    currentToolType,
    currentAdministration,
    currentInsightEvaluation,
    allCpAnalysisAdministration,
    overviewData,
  };
};

export const getSwotStates = (state) => {
  const { swotEvaluation } = state;
  const allSwotEvaluation = swotEvaluation.allSwotEvaluation || [];

  return { allSwotEvaluation };
};

export const getProjectStates = (state) => {
  const { projectEvaluation = {} } = state || {};

  const {
    allProjectEvaluation = [],
    projectEvaluation: currentProjectEvaluation = {},
    allProjectConfigurations = [],
    isLoading,
  } = projectEvaluation || {};

  return {
    allProjectEvaluation,
    currentProjectEvaluation,
    allProjectConfigurations,
    isLoading,
  };
};

export const getTeamStates = (state) => {
  const { userTeams } = state;

  const selectedTeamMembers = userTeams.teamMembers || [];
  const selectedTeamParticipants = userTeams.teamParticipants || [];

  return { selectedTeamMembers, selectedTeamParticipants };
};

export const getBigProcessesStates = (state) => {
  const { insightMaturity, activityInformation } = state;
  const allMaturityData = (insightMaturity && insightMaturity.all) || {};
  const allActivityInformation =
    activityInformation && activityInformation.all
      ? activityInformation.all
      : [];
  return { allMaturityData, allActivityInformation };
};

const buildMemberAndParticipants = (members = [], participants = []) => {
  const finalMemberAndParticipants = [];

  const addToArray = (list, type) => {
    list.forEach((itemBody) => {
      finalMemberAndParticipants.push({ ...itemBody, type });
    });
  };

  addToArray(members, "MEMBER");
  addToArray(participants, "PARTICIPANT");

  return finalMemberAndParticipants;
};

export const getSelectedCompanyStates = (state) => {
  const { companyData } = state;
  const selectedCompany = companyData?.selectedCompany || {};
  const allCompanyWorkspaces = companyData?.allCompanyWorkspaces || [];
  const allCompanyToolsType = companyData?.allCompanyToolsType || {};
  const allCompanyTeams = companyData?.teams || [];
  const companyOwner = selectedCompany?.owner || {};
  const filteredCompanyData = filterCompanyDataByAccess(companyData);
  return {
    selectedCompany,
    selectedCompanyId: selectedCompany?.id,
    companyData: filteredCompanyData,
    allCompanyWorkspaces,
    allCompanyToolsType,
    allCompanyTeams,
    companyOwner,
    loadingInfo: companyData.loadingInfo,
  };
};

export const getCompanyMembersAndParticipants = ({ companyData }) => {
  const companyMembers = buildMemberAndParticipants(companyData.members) || [];
  const companyParticipants =
    companyData && companyData.participants ? companyData.participants : [];
  const companyMembersAndParticipants = buildMemberAndParticipants(
    companyMembers,
    companyParticipants
  );

  return { companyMembers, companyParticipants, companyMembersAndParticipants };
};

export const getBigPeopleStates = (state) => {
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);

  const { peopleEvaluation = {} } = state;
  const { allPeopleEvaluation: all = [], isLoading = false } = peopleEvaluation;

  const allPeopleEvaluation = buildMemberNames(
    all,
    companyMembersAndParticipants
  );

  return { allPeopleEvaluation, companyMembersAndParticipants, isLoading };
};

export const getAlternateToolStates = (state) => {
  const { selectedTool } = state;

  const selectedToolID = (selectedTool && selectedTool.id) || null;

  return {
    selectedToolID,
  };
};

const handleFiledConfiguration = (
  allEntitys = {},
  filedConfigurations = {}
) => {
  const {
    sceneries = {},
    themes = {},
    questions = {},
    answers = {},
  } = allEntitys || {};

  const showHiddenSceneries = filedConfigurations.sceneries || false;
  const showHiddenThemes = filedConfigurations.themes || false;
  const showHiddenQuestions = filedConfigurations.questions || false;
  const showHiddenAnswers = filedConfigurations.answers || false;

  const filteredByFiledCenarys = getFilteredEntityByFiledStatus(
    sceneries,
    showHiddenSceneries,
    true
  );

  const filteredByFiledThemes = getFilteredEntityByFiledStatus(
    themes || {},
    showHiddenThemes
  );

  const filteredByFiledQuestions = getFilteredEntityByFiledStatus(
    questions || {},
    showHiddenQuestions
  );

  const filteredByFiledAnswers = getFilteredEntityByFiledStatus(
    answers || {},
    showHiddenAnswers
  );

  return {
    filteredByFiledAnswers,
    filteredByFiledCenarys,
    filteredByFiledThemes,
    filteredByFiledQuestions,
  };
};

const filterEntitysByAccess = (
  allCenarys = [],
  allThemes = {},
  allQuestions = {},
  allAnswers = {},
  accessFilteredSceneries = [],
  userAccess
) => {
  const filteredSceneries = [...accessFilteredSceneries];
  const filteredAllThemes = { ...allThemes };
  const filteredAllQuestions = { ...allQuestions };
  const filteredAnswers = { ...allAnswers };
  const onlyQuestionsThemeIds = [];
  let filteredQuestions = {};
  let filteredThemes = {};

  const onlyAnswersQuestionIds = Object.keys(filteredAnswers).map(
    (questionId) => Number(questionId)
  );

  if (typeof filteredAllQuestions === "object") {
    Object.keys(filteredAllQuestions).forEach((themeId) => {
      const filteredQuestionsArray = [];

      if (Array.isArray(filteredAllQuestions[themeId])) {
        filteredAllQuestions[themeId].forEach((question) => {
          if (onlyAnswersQuestionIds.indexOf(Number(question.id)) !== -1) {
            filteredQuestionsArray.push(question);
            onlyQuestionsThemeIds.push(Number(themeId));
          }
        });

        if (filteredQuestionsArray.length > 0) {
          filteredQuestions = {
            ...filteredQuestions,
            [themeId]: [...filteredQuestionsArray],
          };
        }
      }
    });
  }

  if (typeof filteredAllQuestions === "object") {
    Object.keys(filteredAllThemes).forEach((cenaryId) => {
      let filteredThemesArray = [];

      if (Array.isArray(filteredAllThemes[cenaryId])) {
        filteredAllThemes[cenaryId].forEach((theme) => {
          if (onlyQuestionsThemeIds.indexOf(Number(theme.id)) !== -1)
            filteredThemesArray = [...filteredThemesArray, theme];
        });

        if (filteredThemesArray.length > 0) {
          filteredThemes = {
            ...filteredThemes,
            [cenaryId]: [...filteredThemesArray],
          };
        }
      }
    });
  }

  const { scenarios: cenaryAccess = [] } = userAccess || {};

  const cenaryViewAccess = cenaryAccess.filter(
    (item) => ["LEADER", "VIEWER"].includes(item.role) && item.access === "EDIT"
  );

  cenaryViewAccess?.forEach(({ id: cenaryId }) => {
    const cenaryThemes = allThemes[cenaryId] || [];

    cenaryThemes.forEach((themeInfo) => {
      const alreadyFiltered = filteredThemes[cenaryId]?.find(
        ({ id }) => id === themeInfo.id
      );

      if (!alreadyFiltered) {
        if (!filteredThemes[cenaryId]) filteredThemes[cenaryId] = [];
        filteredThemes[cenaryId].push(themeInfo);
      }

      const themeQuestions = allQuestions[themeInfo.id] || [];

      themeQuestions.forEach((questionInfo) => {
        const alreadyFiltered = filteredQuestions[themeInfo.id]?.find(
          ({ id }) => id === questionInfo.id
        );

        if (!alreadyFiltered) {
          if (!filteredQuestions[themeInfo.id])
            filteredQuestions[themeInfo.id] = [];
          filteredQuestions[themeInfo.id].push(questionInfo);
        }
      });
    });
  });

  Object.keys(filteredAnswers).forEach((questionId) => {
    const answers = filteredAnswers[questionId];
    if (answers?.length > 0) {
      const [firstInsight] = answers;
      const currentCenary = allCenarys.find(
        ({ id }) => id === Number(firstInsight?.question?.theme?.cenary?.id)
      );
      if (
        currentCenary?.id &&
        filteredSceneries.findIndex(({ id }) => id === currentCenary.id) === -1
      ) {
        filteredSceneries.push(currentCenary);
      }
    }
  });

  return {
    filteredSceneries,
    filteredThemes,
    filteredQuestions,
    filteredAnswers,
  };
};

export const getAccessStates = ({ access = {} }) => {
  const {
    userDomains = [],
    userAccess = {},
    domainCompanies = [],
    domainUsers = [],
    currentDomain = {},
    buildedAccess = false,
    entityAccess = {},
    lockdown = false,
    selectedDomainId = false,
  } = access;

  return {
    access,
    userDomains,
    userAccess,
    domainCompanies,
    domainUsers,
    currentDomain,
    buildedAccess,
    entityAccess,
    lockdown,
    selectedDomainId,
  };
};

export const getAccessFilteredStates = (
  state = {},
  filedConfigurations = {},
  filter = true
) => {
  const { cenaryThemes, questionsAnswers, questions, cenaries, selectedTool } =
    state;
  const { userAccess } = getAccessStates(state) || {};

  const { id: selectedToolID = 0 } = selectedTool || {};
  const { cenaries: allCenarys = {} } = cenaries || {};
  const { allThemes = {} } = cenaryThemes || {};
  const { allAnswers = {} } = questionsAnswers || {};

  const allEntitys = {
    answers: allAnswers,
    sceneries: allCenarys,
    themes: allThemes,
    questions,
  };

  const {
    filteredByFiledCenarys = {},
    filteredByFiledThemes = {},
    filteredByFiledQuestions = {},
    filteredByFiledAnswers = {},
  } = handleFiledConfiguration(allEntitys, filedConfigurations);

  const filteredOnlyByFiled = {
    filteredThemes: filteredByFiledThemes,
    filteredQuestions: filteredByFiledQuestions,
    filteredAnswers: filteredByFiledAnswers,
    filteredSceneries: filteredByFiledCenarys,
  };

  if (
    !filter ||
    checkAccess(["OWNER", "ADMIN", "MODERATOR", "MANAGER", "TOOLVIEWER"])
  )
    return filteredOnlyByFiled;

  const accessFilteredSceneries = filterScenariosByAccess(
    filteredByFiledCenarys,
    selectedToolID
  );

  const accessFilteredAnswers = filterAllAnswersByAccess(
    filteredByFiledAnswers
  );

  const {
    filteredSceneries,
    filteredThemes,
    filteredQuestions,
    filteredAnswers,
  } = filterEntitysByAccess(
    filteredByFiledCenarys,
    filteredByFiledThemes,
    filteredByFiledQuestions,
    accessFilteredAnswers,
    accessFilteredSceneries,
    userAccess
  );

  return {
    filteredThemes,
    filteredQuestions,
    filteredAnswers,
    filteredSceneries,
  };
};

const buildInsightGoals = (allAnswers, allInsightGoals) => {
  const insightsGoals =
    allInsightGoals && allInsightGoals.length ? allInsightGoals : [];

  const updatedAnswers = allAnswers;

  Object.keys(updatedAnswers).forEach((answerQuestionId) => {
    insightsGoals.forEach((insightBody) => {
      if (insightBody && insightBody.questionId) {
        const insightGoalQuestionId = insightBody.questionId.toString();
        if (insightGoalQuestionId === answerQuestionId) {
          updatedAnswers[answerQuestionId].forEach((insight, index) => {
            if (insight?.id === insightBody.insightId && insight.goal) {
              updatedAnswers[answerQuestionId][index] = {
                ...insight,
                goal: insightBody.insightGoal,
              };
            }
          });
        }
      }
    });
  });

  return updatedAnswers;
};

export const getSelectedToolStates = (
  state,
  filedConfigurations = {},
  filterBySelectedTool = false
) => {
  const { selectedTool, cenaries, cenaryThemes, questionsAnswers, questions } =
    state;

  const { allAnswers: qAnswers = {} } = questionsAnswers || {};

  const { allInsightGoals } = insightsGoalState(state);
  const selectedToolID = selectedTool.id || null;
  const sceneries = cenaries.cenaries || [];
  const sceneriesThemes = cenaryThemes || {};

  const { allThemes: themes } = sceneriesThemes;
  const answersResource = { ...qAnswers };

  const answers = allInsightGoals
    ? buildInsightGoals(answersResource, allInsightGoals)
    : answersResource;

  const entities = {
    sceneries,
    themes,
    questions,
    answers,
  };
  const allEntitys = filterBySelectedTool
    ? filterSelectedToolEntities(selectedToolID, entities)
    : entities;

  const {
    filteredByFiledCenarys = {},
    filteredByFiledThemes = {},
    filteredByFiledQuestions = {},
    filteredByFiledAnswers = {},
  } = handleFiledConfiguration(allEntitys, filedConfigurations);

  const allSceneries = filterScenariosByAccess(
    filteredByFiledCenarys,
    selectedToolID
  );

  const allAnswers = filterAllAnswersByAccess(filteredByFiledAnswers);

  const toolTypeId = selectedTool?.tool?.id;

  return {
    allSceneries,
    dirtyAllSceneries: filteredByFiledCenarys,
    allThemes: filteredByFiledThemes,
    allQuestions: filteredByFiledQuestions,
    allAnswers,
    dirtyAllAnswers: filteredByFiledAnswers,
    sceneriesThemes,
    selectedTool,
    selectedToolID,
    toolTypeId,
  };
};

export const getAuthStates = (state = {}) => {
  const { auth = {} } = state;

  return auth;
};

export const getInsightStates = (state = {}) => {
  const { insightData = {} } = state;

  const {
    selectedInsight = {},
    allAnswers: allInsights = {},
    openInsightModal = false,
  } = insightData;
  const { id: selectedInsightID = 0 } = selectedInsight || {};

  return { selectedInsight, selectedInsightID, allInsights, openInsightModal };
};

export const getSceneryStates = (state = {}) => {
  const { selectedCenary = {} } = state;

  const { id: selectedSceneryID = 0 } = selectedCenary || {};

  return { selectedCenary, selectedSceneryID };
};

export const getWorkspacesStates = (state = {}) => {
  const {
    workspacesData = {},
    companyData = {},
    workspaceSelectedTools,
  } = state || {};

  const { selectedWorkspace = {} } = workspacesData || {};

  const { minifiedCompanyWorkspaces: allWorkspaces = [] } = companyData || {};

  const { id: selectedWorkspaceID = 0 } = selectedWorkspace || {};

  const minifiedCompanyWorkspaces = [
    ...filterWorkspacesByAccess(allWorkspaces),
  ];

  const workspaceTools = [...filterToolsByAccess(workspaceSelectedTools)];

  return {
    minifiedCompanyWorkspaces,
    dirtyAllCompanyWorkspaces: allWorkspaces,
    workspaceTools,
    allUsersWorkspaceTools: state.workspaceSelectedTools,
    workspacesData: state.workspacesData,
    selectedWorkspace,
    selectedWorkspaceID,
  };
};

export const getCompanyStructureStates = (state) => {
  const {
    companyStructure = {},
    companyPositions = {},
    companyGuidelines = {},
  } = state;
  const { allAreaStructures = {}, isLoading = false } = companyStructure;
  const { allGuidelineStructures = {} } = companyGuidelines;
  const { allPositions = {}, isLoading: isPositionsLoading = false } =
    companyPositions;

  return {
    allAreaStructures,
    allGuidelineStructures,
    isLoading,
    allPositions,
    isPositionsLoading,
  };
};

export const getGoalsBonusStates = (state) => {
  const { goalsBonus = {} } = state;

  const allGoalBonusAdministration = goalsBonus.all || [];
  const isLoading = goalsBonus.loading || false;

  return {
    allGoalBonusAdministration,
    isLoading,
  };
};

export const getKpiStates = (state) => {
  const { kpi = {} } = state;

  const allKpiAdministration = kpi.all || [];
  const isLoading = kpi.loading || false;

  return {
    allKpiAdministration,
    isLoading,
  };
};

export const getSkillAssessmentStates = (state) => {
  const { skillAssessment = {} } = state;

  const allSkillAssessment = skillAssessment.all || [];
  const isLoading = skillAssessment.loading || false;
  const lastFetch = skillAssessment.lastFetch;

  return {
    allSkillAssessment,
    isLoading,
    lastFetch,
  };
};

export const getActionsPlanStates = (state) => {
  const { actionsPlan = {} } = state;

  const allActionsPlans = actionsPlan.all;

  return { allActionsPlans };
};

export const getUserProfileStates = (state) => {
  const { companyData = {}, user = {} } = state;
  const { selectedCompanyProfessionalProfiles = {}, allCompanyHierarchy } =
    companyData;

  return { selectedCompanyProfessionalProfiles, user, allCompanyHierarchy };
};

export const getCommentStates = (state) => {
  const { insightsTimeline } = state;

  return { allComments: insightsTimeline.allComments };
};
