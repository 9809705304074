import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { FormControlLabel } from "@material-ui/core";
import FormDialog from "../../../../../../../Common/FormDialog";
import MaterialTextField from "../../../../../../../Common/MaterialTextField";
import ActivitiesTable from "../../../../../../../Insight/CustomFields/ProjectEvaluation/ActivitiesTable";
import { postChecklist } from "../../../../../../../../actions/projectEvaluationActions";
import { ButtonDelete, SwithCustom } from "./styles";
import { getCompanyMembersAndParticipants } from "../../../../../../../customMapStates";

const ITemplateFields = {
  name: "",
  description: "",
  isPublic: true,
};

const defaultActivitiesOptions = {
  activitiesType: null,
  weightAutomation: false,
  effortAutomation: false,
};

function ModalCreateTemplate({
  open,
  onCancel,
  onConfirm,
  onDeleteTemplate,
  selectedTemplate,
  companyMembersAndParticipants,
  user,
}) {
  const [activities, setActivities] = useState([]);
  const [templateFields, setTemplateFields] = useState(ITemplateFields);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [projectEvaluation, setProjectEvaluation] = useState(
    defaultActivitiesOptions,
  );

  useEffect(() => {
    if (selectedTemplate && selectedTemplate.activities) {
      setActivities(selectedTemplate.activities);
      setTemplateFields(selectedTemplate);
      setProjectEvaluation(
        selectedTemplate.activitiesOptions || defaultActivitiesOptions,
      );
    }
  }, [selectedTemplate]);

  function saveActivities(activities) {
    setActivities((prevActivities) => [...prevActivities, activities]);
  }

  function updateAcitivities(activities) {
    setActivities(activities);
  }

  function handleTemplateFields(e) {
    const { name, value } = e.target;

    setTemplateFields((prevState) => ({ ...prevState, [name]: value }));
  }

  function handleChangeIsPublic(e) {
    const { name } = e.target;

    setTemplateFields((prevState) => ({
      ...prevState,
      [name]: !prevState.isPublic,
    }));
  }

  function handleOpenModalDelete() {
    setIsOpenModalDelete(!isOpenModalDelete);
  }

  function deleteActivity(selectedIndex) {
    setActivities((prevActivities) =>
      prevActivities.filter((_, index) => selectedIndex !== index),
    );
  }

  function renameActivitiesOptions() {
    let activitiesType = projectEvaluation.activitiesType;

    if (projectEvaluation.activitiesType === "HOURS") activitiesType = "Horas";
    if (projectEvaluation.activitiesType === "TASKS")
      activitiesType = "Tarefas";

    return { ...projectEvaluation, activitiesType };
  }

  function changeStatusActivity(selectedIndex, status) {
    setActivities((prevActivities) =>
      prevActivities.map((activity, index) => {
        if (selectedIndex === index) {
          return {
            ...activity,
            active: status,
          };
        }

        return activity;
      }),
    );
  }

  function handleCancel() {
    onCancel();
    setActivities([]);
    setTemplateFields({});
    setProjectEvaluation({});
  }

  function confirmDeleteTemplate() {
    handleOpenModalDelete();
    onDeleteTemplate();
    handleCancel();
  }

  function clearStatesAndConfirm() {
    onConfirm(templateFields, activities, projectEvaluation);

    setActivities([]);
    setTemplateFields({});
    setProjectEvaluation({});
  }

  return (
    <>
      <FormDialog
        open={open}
        onCancel={handleCancel}
        onConfirm={clearStatesAndConfirm}
        dialogClassName="modal-templates"
        title="Criar template"
        blockConfirm={templateFields.name?.length < 3}
      >
        <MaterialTextField
          onChange={handleTemplateFields}
          label="Nome"
          name="name"
          value={templateFields.name}
        />
        <MaterialTextField
          onChange={handleTemplateFields}
          label="Descrição"
          name="description"
          value={templateFields.description}
        />
        {user.id === selectedTemplate?.createdBy?.id && (
          <FormControlLabel
            control={
              <SwithCustom
                checked={templateFields.isPublic}
                onChange={handleChangeIsPublic}
                name="isPublic"
                inputProps={{ "aria-label": "checkbox" }}
              />
            }
            label={templateFields.isPublic ? "Público" : "Privado"}
          />
        )}

        <div style={{ margin: "10px 0" }}>
          <ActivitiesTable
            activities={activities}
            onConfirmEdit={saveActivities}
            postChecklist={postChecklist}
            onConfirmDelete={deleteActivity}
            onChangeStatus={changeStatusActivity}
            onConfirmSave={updateAcitivities}
            setActivities={setActivities}
            companyMembersAndParticipants={companyMembersAndParticipants}
            setProjectEvaluation={setProjectEvaluation}
            projectEvaluation={renameActivitiesOptions()}
            isTemplate
          />
        </div>
        {templateFields.id && (
          <ButtonDelete className="btn" onClick={handleOpenModalDelete}>
            Deletar
          </ButtonDelete>
        )}
      </FormDialog>
      <FormDialog
        open={isOpenModalDelete}
        title="Deseja deletar esse template?"
        description="O template excluído não será deletado dos insights, mas
        você não terá mais acesso a este template. Deseja confirmar?"
        onCancel={handleOpenModalDelete}
        onConfirm={confirmDeleteTemplate}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  const { user } = state;
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);

  return {
    companyMembersAndParticipants,
    user,
  };
};

export default connect(mapStateToProps, {})(ModalCreateTemplate);

ModalCreateTemplate.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  companyMembersAndParticipants: PropTypes.array.isRequired,
  onDeleteTemplate: PropTypes.func,
  onConfirm: PropTypes.func,
  selectedTemplate: PropTypes.object,
  user: PropTypes.object.isRequired,
};

ModalCreateTemplate.defaultProps = {
  onDeleteTemplate: () => {},
  onConfirm: () => {},
  selectedTemplate: {},
};
