import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import SimplePieChart from "../../../../../../Common/SimplePieChart";
import { getToFixed, ruleOfThree } from "../../../../../../../utils/MathUtils";
import { evaluationColors } from "../../../../../../../utils/skillsAssessment/constants";
import EvaluationLine from "./EvaluationLine";

const StatusChart = ({ title, reached, total, endDate, selected, onClick }) => {
  const gapColor = evaluationColors.gap;
  const desiredColor = evaluationColors.averageEvaluation;

  const missing = total - reached;

  const chartData = [
    {
      value: missing,
      title: "Pendente",
      color: gapColor.color,
    },
    { value: reached, title: "Atingido", color: desiredColor.color },
  ];

  const displayPercentage = getToFixed(ruleOfThree(total, missing), 0);

  const chart = (data) => {
    return (
      <>
        <div className="print-hide">
          <SimplePieChart
            data={data}
            containerHeight={160}
            showLegend={false}
            showActiveShape={false}
          />
        </div>
        <div className="only-print">
          <EvaluationLine
            evaluation={ruleOfThree(total, reached)}
            colorInfo={{ ...desiredColor, background: gapColor.color }}
            maxValue={100}
            append="%"
            fontSize="12px"
          />
        </div>
      </>
    );
  };

  return (
    <div
      className={`chart-col chart-filter ${selected && "chart-selected"}`}
      onClick={onClick}
    >
      <h5 align="center" style={{ marginBottom: "0px", height: "25px" }}>
        {title}
      </h5>
      {endDate && (
        <h6 align="center" style={{ marginTop: "0px", color: "#666" }}>
          {moment(endDate).format("L")}
        </h6>
      )}
      <div className="chart">
        {chart(chartData)}
        <div className="total-count print-hide">{reached?.toString()}</div>
      </div>
      <table className="bottom-table">
        <tbody>
          <tr>
            <td align="left">Pendentes</td>
            <td align="right">
              {missing === 0 ? (
                "0 (0%)"
              ) : (
                <b>
                  {missing} ({displayPercentage}%)
                </b>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

StatusChart.propTypes = {
  title: PropTypes.string.isRequired,
  reached: PropTypes.number,
  total: PropTypes.number,
  endDate: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

StatusChart.defaultProps = {
  reached: 0,
  total: 0,
  endDate: "",
  selected: false,
  onClick: () => {},
};

export default StatusChart;
