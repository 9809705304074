import React from "react";
import PropTypes from "prop-types";

const SimpleRelativeController = ({ onConfirm, onCancel }) => {
  return (
    <div className="simple-relative-controller">
      {onCancel && (
        <div className="button cancel" onClick={onCancel}>
          <i className="fas fa-times" />
        </div>
      )}
      <div className="button save">
        <i className="far fa-save" onClick={onConfirm} />
      </div>
    </div>
  );
};

SimpleRelativeController.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default SimpleRelativeController;
