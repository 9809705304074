import PropTypes from "prop-types";
import { Popover, Tooltip } from "react-bootstrap";
import { defineMessages } from "react-intl";
import { getState } from "../../actions/websockets/utils/store";
import {
  getSelectedToolStates,
  getSkillAssessmentStates,
} from "../../components/customMapStates";
import { getObjectInfoById } from "../ArrayUtils";
import { ClassificationBadge } from "../commentUtils";
import { translatedText } from "../translationUtils";
import {
  evaluationColors,
  getAccessRelation,
  getEvaluationStagesOptions,
  workflowCommentTags,
} from "./constants";
import { defaultParticipantStages } from "./defaults";
import { useSimpleToolAdministration } from "./hooks/administration";

const messages = defineMessages({
  global_selectSome: { id: "global.selectSome" },
});

const translation = (id) => translatedText(id, messages);

export const getDisplayNames = (source = [], selected = [], displaySlug) => {
  const displayNames = [];

  if (Array.isArray(selected)) {
    selected.forEach((itemInfo) => {
      if (itemInfo && itemInfo[displaySlug]) {
        displayNames.push(itemInfo[displaySlug]);
      } else {
        displayNames.push("Undefined");
      }
    });
  }

  if (displayNames.length === source.length && source.length !== 1)
    return source.length > 0 ? "TODOS" : "Nenhum cadastrado";

  return displayNames.length > 0
    ? displayNames.join(", ")
    : translation("global_selectSome");
};

export const getDisplayMembers = (
  companyMembers = [],
  selectedMembers = [],
  displaySlug = "name"
) => {
  return getDisplayNames(companyMembers, selectedMembers, displaySlug);
};

export const getDisplayProfiles = (
  profiles = [],
  selectedProfiles = [],
  displaySlug = "title"
) => {
  return getDisplayNames(profiles, selectedProfiles, displaySlug);
};

export const getDisplayCompetenceAreas = (
  competenceAreas = [],
  selectedCompetenceAreas = [],
  displaySlug = "title"
) => {
  return getDisplayNames(competenceAreas, selectedCompetenceAreas, displaySlug);
};

export const renderSkillAssessmentLabels = () => {
  return (
    <>
      <th style={{ textAlign: "left" }}>Cenário</th>
      <th style={{ textAlign: "left" }}>Área</th>
      <th style={{ textAlign: "left" }}>Perfil</th>
      <th style={{ textAlign: "left" }}>Participante</th>
    </>
  );
};

export const getScaleToolTip = (scaleOptions) => (
  <Tooltip id="scaleTooltip">
    <div>
      {scaleOptions.map(({ label }) => {
        return (
          <h5 align="left" key={label}>
            {label}
          </h5>
        );
      })}
    </div>
  </Tooltip>
);

export const getDescriptionTooltip = (jsxNode) => (
  <Popover id="simpleDescriptionTooltip" style={{ maxWidth: "600px" }}>
    <div>{jsxNode}</div>
  </Popover>
);

export const getComplementaryTopicByTypes = (type, subType) => {
  if (type === "PERFORMANCE")
    return { title: "AVALIAÇÃO DE DESEMPENHO", subtitle: "Desempenho" };
  if (type === "SUCCESSION")
    return { title: "AVALIAÇÃO DE SUCESSÃO", subtitle: "Sucessão" };
  if (type === "KEY_SEAT") {
    const title = "AVALIAÇÃO DE FUNÇÃO E POSIÇÃO";
    if (subType === "KEY_SEAT_CRITICALITY")
      return { title, subtitle: "Criticidade das Funções" };
    if (subType === "KEY_SEAT_LOSING_RISK")
      return { title, subtitle: "Risco de Perder Posição" };
    if (subType === "KEY_SEAT_LOSING_GRAVITY")
      return { title, subtitle: "Gravidade de Perder" };

    return { title, subtitle: "" };
  }

  return { title: "", subtitle: "" };
};

export const displayConflictedParticipants = (filtered, statusTitle) => {
  return (
    <div>
      <h5>
        O Estágio de Avaliação no Workflow de todos os usuários abaixo, será
        alterado para &quot;{statusTitle}&quot;:
      </h5>
      <div>
        {filtered.map((participantInfo, index) => {
          const { memberParticipant } = participantInfo;

          return (
            <div key={memberParticipant.id}>
              {index + 1}. <b>{memberParticipant.name}</b>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Message = ({ children }) => {
  return (
    <div
      className="col-xs-12"
      align="center"
      style={{ padding: "15px", backgroundColor: "#ecfcad" }}
    >
      {children}
    </div>
  );
};

export const WorkflowStageMessage = ({
  loggedUserRole,
  workflowStage,
  evaluationType,
  evaluationConfig,
  errorSlugs,
  haveChanges,
  loggedUser,
  participant,
}) => {
  const notParticipantStage =
    defaultParticipantStages.indexOf(workflowStage) === -1;

  const stageOptions = getEvaluationStagesOptions(
    evaluationType,
    evaluationConfig
  );
  const stage = getObjectInfoById(workflowStage, stageOptions, "value");

  const { isParticipant, isSecEval, isThrEval } = getAccessRelation(
    loggedUserRole,
    loggedUser,
    participant
  );
  const showParticipantMessage = isParticipant && notParticipantStage;

  let message = null;

  if (workflowStage === "EVALUATORS") {
    const secEvaluatorNotCompleted =
      errorSlugs.indexOf("invalid_workflow_values_secondEvaluator") > -1;
    const thrEvaluatorNotCompleted =
      errorSlugs.indexOf("invalid_workflow_values_thirdEvaluator") > -1;

    const showEvaluatorMessage =
      (isSecEval && thrEvaluatorNotCompleted && !secEvaluatorNotCompleted) ||
      (isThrEval && secEvaluatorNotCompleted && !thrEvaluatorNotCompleted);

    if (showEvaluatorMessage && !haveChanges)
      message = (
        <Message>
          <b>Sua avaliação já está completa, aguardando outros avaliadores.</b>
        </Message>
      );

    if (
      !secEvaluatorNotCompleted &&
      !thrEvaluatorNotCompleted &&
      !haveChanges
    ) {
      message = (
        <Message>
          <b>Avaliação pronta para enviar para Aprovação.</b>
        </Message>
      );
    }
  }

  if (showParticipantMessage) {
    message = (
      <Message>
        <b>{`Sua avaliação já foi enviada para ${stage.label}`}.</b>
      </Message>
    );
  }

  return message;
};

WorkflowStageMessage.propTypes = {
  loggedUser: PropTypes.object.isRequired,
  participant: PropTypes.object.isRequired,
  workflowStage: PropTypes.string.isRequired,
  evaluationType: PropTypes.string.isRequired,
  evaluationConfig: PropTypes.string.isRequired,
};

export const SkillAssessmentCommentBadge = ({ tag }) => {
  const state = getState();

  const { selectedTool } = getSelectedToolStates(state);
  const { allSkillAssessment } = getSkillAssessmentStates(state);
  const { toolAdministration } = useSimpleToolAdministration({
    selectedTool,
    allSkillAssessment,
  });

  let title = "";

  const { tag: checkTag } = workflowCommentTags({ id: "REPLACE" });
  if (tag.substr(0, 16) === "WK_COMPLEMENTARY") {
    title = "Avaliação Complementar";
  } else {
    const skillId = Number(tag.replace(checkTag.replace("REPLACE", ""), ""));
    const skillInfo = getObjectInfoById(skillId, toolAdministration.skills);
    title = skillInfo?.title;
  }

  const { selfEvaluation } = evaluationColors;

  if (title)
    return (
      <ClassificationBadge
        text={title}
        backgroundColor={selfEvaluation.color}
        textColor="#333"
      />
    );

  return null;
};

SkillAssessmentCommentBadge.propTypes = {
  tag: PropTypes.string.isRequired,
};
