import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import { Row, Col, Button } from "react-bootstrap";
import StepLabel from "@material-ui/core/StepLabel";
import { injectIntl, defineMessages } from "react-intl";
import React, { useState, useEffect } from "react";

import { projectEvaluation } from "./translations";
import FormDialog from "../../../Common/FormDialog";
import { useCustomModal } from "../../hooks/CustomModalSave";
import MaterialTextField from "../../../Common/MaterialTextField";
import { getObjectDifference } from "../../../../utils/ArrayUtils";
import { postGenericEmail } from "../../../../actions/emailActions";
import { getStageParentInfoById } from "../../../../utils/projectUtils";
import { milestoneResponsibleTemplate } from "../../../../actions/emails/milestoneResponsibleTemplate";
import { projectConcludedTemplate } from "../../../../actions/emails/projectConcludedTemplate";

import {
  getSelectedCompanyStates,
  getWorkspacesStates,
  getProjectStates,
  getSelectedToolStates,
  getInsightStates,
  getAccessStates,
} from "../../../customMapStates";
import {
  checkAccess,
  getUserById,
  getCustomUrlLink,
} from "../../../../utils/accessLevels";

import {
  calcIndicators,
  getInitialStates,
  getInitialIndicators,
  buildProjectEvaluationRest,
  mountCurrentEvaluation,
  buildStateEvaluation,
  mountLoadEvaluation,
  whoChanged,
  getLabelByType,
  getPlanTotalValues,
  getLastReplanningByYear,
  confirmPlan,
  changeNewPlanReason,
  mountPotentialPlans,
  getPhysicalProgress,
  handleFirstPlan,
  handleAlteredPlans,
  getAvailableSchedule,
  mountPlannings,
  getInsightProjectEvaluation,
  cleanAndBuildStateEvaluation,
  cleanAndBuildCurrentEvaluation,
} from "../../../../utils/projectEvaluation";
import {
  addIfNotPresent,
  getUpdatedDeleteArrayByIndex,
} from "../../../../utils/ArrayUtils";
import {
  addProjectEvaluation,
  getEvaluation,
  postChecklist,
  deleteUnusedChecklist,
  getChecklistByInsightId,
} from "../../../../actions/projectEvaluationActions";

import TeamTable from "./TeamTable";
import Description from "./Description";
import InvestmentDisplayTable from "./InvestmentDisplayTable";
import ScheduleTable from "./ScheduleTable";
import ActivitiesTable from "./components/ActivitiesTable";
import IndicatorsTable from "./IndicatorsTable";
import PhysicalProgressTable from "./PhysicalProgressTable";

const defaultAcceptPlan = {
  year: null,
};

const defaultProjectDone = {
  displayModal: false,
  doneDate: null,
  format: null,
  conclusionDate: null,
};

const defaultPendingPlans = {
  pendingEvaluations: {},
  displayModal: false,
  typeToDisplay: null,
};

const defaultReasons = { reasons: [] };
const messages = defineMessages(projectEvaluation);

const ProjectEvaluation = ({
  projectConfiguration,
  projectEvaluation,
  setProjectEvaluation,
  historyEvaluation: onStateEvaluation,
  handleUpdateSingleField,
  intl,
  insight,
  entityAccess,
  getEvaluation,
  selectedCompany,
  updateResponsible,
  selectedWorkspace,
  selectedInsightID,
  addProjectEvaluation,
  allProjectEvaluation,
  currentProjectEvaluation,
}) => {
  const initialStates = getInitialStates();
  const { handleSetConfigsModal } = useCustomModal();
  const initialIndicators = getInitialIndicators(intl);
  const { projectEvaluation: initPEvaluation = {} } = initialStates;

  const {
    activities: initActivities = {},
    projectEffort: initProjectEffort = {},
    peopleInvolved: initPeopleInvolved = {},
    physicalAdvance: initPhysicalAdvance = {},
    projectInvestment: initProjectInvestment = {},
  } = initPEvaluation;

  const [activeStep, setActiveStep] = useState(0);
  const [firstMount, setFirstMount] = useState(true);
  const [effort, setEffort] = useState(initProjectEffort);
  const [automaticSave, setAutomaticSave] = useState(false);
  const [schedule, setSchedule] = useState(initPEvaluation);
  const [activities, setActivities] = useState(initActivities);
  const [alreadyCompared, setAlreadyCompared] = useState(false);
  const [acceptPlan, setAcceptPlan] = useState(defaultAcceptPlan);
  const [indicators, setIndicators] = useState(initialIndicators);
  const [sometingWentWrong, setSometingWentWrong] = useState(false);
  const [displaySaveButton, setDisplaySaveButton] = useState(false);
  const [modalBlockSave, setModalBlockSave] = useState(false);
  const [isBlockSave, setIsBlockSave] = useState(false);
  const [projectDone, setProjectDone] = useState(defaultProjectDone);
  const [projectTeam, setProjectTeam] = useState(initPeopleInvolved);
  const [buildedActivivites, setBuildedActivivites] = useState(null);
  const [milestonesEmailBodys, setMilestonesEmailBodys] = useState([]);
  const [investment, setInvestment] = useState(initProjectInvestment);
  const [newPlanReasons, setNewPlanReasons] = useState(defaultReasons);
  const [pendingPlans, setPendingPlans] = useState(defaultPendingPlans);
  const [physicalAdvance, setPhysicalAdvance] = useState(initPhysicalAdvance);

  function updateSingleScheduleField(field, value) {
    setSchedule((current) => {
      return {
        ...current,
        [field]: value,
      };
    });
  }

  const calcBudgetSpentBalance = (plan) => {
    const totalValues = getPlanTotalValues(plan);
    const {
      totalBudget: newBudget = null,
      totalSpentHours = false,
      totalSpent = 0,
    } = totalValues;

    const newSpent = totalSpentHours || totalSpent;
    const newBalance = newBudget - newSpent;

    return { newBudget, newSpent, newBalance };
  };

  const mountAcceptPlan = (pending) => {
    const { pendingEvaluations: plansToAccept = [] } = pending || {};
    const currentPlan = plansToAccept[activeStep] || null;
    const { plan = {} } = currentPlan || {};
    const { year = null } = plan || {};

    setAcceptPlan({
      plan,
      year,
    });
  };

  const getAllidChecklistFromActivities = async () => {
    if (buildedActivivites) {
      const checklistByInsightId = await getChecklistByInsightId(
        selectedInsightID,
      );

      const allIdChecklist = buildedActivivites
        .map((activity) => activity.idChecklist)
        .filter((acitivityId) => acitivityId !== null);

      const unusedChecklists =
        Array.isArray(checklistByInsightId) &&
        checklistByInsightId
          .filter(
            (checklist) => !allIdChecklist.includes(checklist.activity_id),
          )
          .map((checklist) => checklist.id);

      return unusedChecklists;
    }

    return null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const mountRestEvaluation = () => {
    const {
      id: evaluationId = null,
      projectInvestment = {},
      projectEffort = {},
    } = onStateEvaluation || {};
    const { id: projectInvestmentId = null } = projectInvestment || {};
    const { id: projectEffortId = null } = projectEffort || {};

    const clonedInvestment = _.cloneDeep(investment);
    const clonedEffort = _.cloneDeep(effort);

    const saveRestEvaluation = buildProjectEvaluationRest(
      projectEvaluation,
      schedule,
      clonedInvestment,
      activities,
      clonedEffort,
      projectTeam,
      physicalAdvance,
      evaluationId,
      projectInvestmentId,
      projectEffortId,
    );

    return saveRestEvaluation;
  };

  const mountCurrentPlannings = () => {
    const { plannings: investmentPlannings = {} } = investment;
    const updatedIPlannings = mountPlannings(investmentPlannings);

    const { plannings: effortPlannings = {} } = effort;
    const updatedEPlannings = mountPlannings(effortPlannings);

    setInvestment((current) => {
      return { ...current, plannings: updatedIPlannings };
    });
    setEffort((current) => {
      return { ...current, plannings: updatedEPlannings };
    });
  };

  const mountRestData = () => {
    const saveRestEvaluation = mountRestEvaluation();

    mountCurrentPlannings();

    return saveRestEvaluation;
  };

  const getIndicators = (dinamicLoad) => {
    let currentEvaluation = null;

    if (dinamicLoad) {
      const clone = onStateEvaluation;
      const buildedStateEvaluation = clone ? buildStateEvaluation(clone) : null;

      currentEvaluation = mountLoadEvaluation(buildedStateEvaluation);
    } else {
      currentEvaluation = mountCurrentEvaluation(
        projectEvaluation,
        schedule,
        investment,
        activities,
        effort,
        projectTeam,
        physicalAdvance,
      );
    }

    const evaluation = currentEvaluation.projectEvaluation || currentEvaluation;

    const updateStatus = projectEvaluation.updateStatus || false;

    const calculatedIndicators = calcIndicators(
      { ...evaluation, updateStatus },
      intl,
    );

    setIndicators(calculatedIndicators);
  };

  const saveProjectEvaluation = async () => {
    getIndicators();

    const questiondID =
      insight && insight.question && insight.question.id
        ? insight.question.id
        : 0;

    const callBack = (info = {}) => {
      const { status = "" } = info || {};

      if (status !== 200) setSometingWentWrong(true);
    };

    if (addProjectEvaluation) {
      const saveRestEvaluation = mountRestData();

      await addProjectEvaluation(
        selectedInsightID,
        questiondID,
        saveRestEvaluation,
        callBack,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const addNewReplanning = (plan, type) => {
    const dateTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
    const currentPlan = _.cloneDeep(plan);

    delete currentPlan.monthEvaluations.id;

    const object = {
      id: null,
      monthEvaluations: currentPlan.monthEvaluations,
      year: currentPlan.year,
      reason: "Baseline",
      dateTime,
    };

    const newObject = mountPlannings([object]);

    if (type === "Investment") {
      const { replannings: currentReplannings = [] } = investment || {};

      setInvestment((current) => {
        return {
          ...current,
          replannings: [...currentReplannings, newObject[0]],
        };
      });
    }
  };

  const cleanInfo = (info, type) => {
    if (!info) return {};

    const alteredInfo = { ...info };

    if (alteredInfo.changed === true) {
      const alteredPlans =
        alteredInfo && alteredInfo.alteredPlans ? alteredInfo.alteredPlans : [];

      alteredPlans.forEach((alteredPlan, index) => {
        const currentPlan =
          alteredPlan && alteredPlan.plan ? alteredPlan.plan : null;

        if (!alteredPlan.oldPlan) {
          addNewReplanning(currentPlan, type);
          alteredPlans.splice(index, 1);
        }
      });
      alteredInfo.alteredPlans = _.cloneDeep(alteredPlans);

      if (alteredInfo.alteredPlans.length === 0) {
        alteredInfo.changed = false;
      }
    }

    return alteredInfo;
  };

  function saveTemplateActivities(activities, activitiesOptions) {
    const renamedActivitiesOptions = {
      ...activitiesOptions,
      activitiesType:
        activitiesOptions?.activitiesType === "HOURS" ? "Horas" : "Tarefas",
    };

    const emails = activities.reduce((responsibles, activity) => {
      if (
        activity.responsible &&
        activity.responsible.id &&
        !responsibles.find(
          (responsible) => responsible.id === activity.responsible.id,
        )
      ) {
        return responsibles.concat(activity.responsible);
      }

      return responsibles;
    }, []);

    setActivities(activities);
    setProjectEvaluation((current) => {
      return { ...current, ...renamedActivitiesOptions };
    });
    setMilestonesEmailBodys(emails);
  }

  const getTraceability = () => {
    const { name: insightName = "", question = {} } = insight || {};
    const { theme = {} } = question || {};
    const { cenary = {} } = theme || {};
    const { name: cenaryName = "" } = cenary || {};
    const projectToolName = intl.formatMessage(messages.projectToolName);
    const { name: workspaceName = "" } = selectedWorkspace || {};
    const { name: companyName = "" } = selectedCompany || {};

    return {
      companyName: companyName.toUpperCase(),
      workspaceName: workspaceName.toUpperCase(),
      projectToolName: projectToolName.toUpperCase(),
      cenaryName: cenaryName.toUpperCase(),
      insightName: insightName.toUpperCase(),
    };
  };

  const getTraceabilityInfo = () => {
    const {
      cenaryName = "",
      insightName = "",
      companyName = "",
      workspaceName = "",
      projectToolName = "",
    } = getTraceability();

    return {
      message: `${companyName} > ${workspaceName} > ${projectToolName} > ${cenaryName} > ${insightName}`,
      insightName,
    };
  };

  const sendMilestoneResponsibleEmails = async () => {
    const { message = "", insightName = "" } = getTraceabilityInfo();

    const description =
      intl.formatMessage(messages.newMilestoneResponsible) + insightName;

    const domainLink = await getCustomUrlLink("INSIGHT");

    const htmlBody = milestoneResponsibleTemplate(
      description,
      message,
      domainLink,
    );

    const emails = milestonesEmailBodys.map((user) => {
      const { email = "" } = getUserById(user);

      return email;
    });

    setMilestonesEmailBodys([]);

    postGenericEmail(
      intl.formatMessage(messages.rookau_activity),
      htmlBody,
      emails,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getWhoChanged = () => {
    const info = whoChanged(investment);

    const investmentInfo = cleanInfo(info?.investmentInfo, "Investment");

    return investmentInfo;
  };

  const handlePlans = ({
    newInvReplannings,
    newReplannings,
    investmentInfo,
  }) => {
    if (newInvReplannings && !investmentInfo.isFirstPlan) {
      return handleAlteredPlans(investment, newReplannings);
    }

    return handleFirstPlan(investment);
  };

  const isNewInvReplan = (newReplannings = []) => {
    return (
      newReplannings?.filter(({ type }) => type === "Investment").length > 0
    );
  };

  const handleInvestmentUpdate = (updatedInvestment = {}) => {
    if (
      !_.isEqual(updatedInvestment.replannings, investment.replannings) ||
      !_.isEqual(updatedInvestment.plannings, investment.plannings)
    ) {
      setInvestment((current) => {
        return {
          ...current,
          ...updatedInvestment,
        };
      });
    }
  };

  const handlePendingPlans = (investmentInfo) => {
    const pendingEvaluations = investmentInfo?.alteredPlans || [];

    const reasons = mountPotentialPlans(pendingEvaluations);

    setNewPlanReasons({ reasons });
    setPendingPlans((current) => {
      const updatedPending = {
        ...current,
        displayModal: true,
        pendingEvaluations,
      };
      mountAcceptPlan(updatedPending);

      return updatedPending;
    });
  };

  const handleReplanFlow = (newReplannings, forceSave) => {
    const investmentInfo = getWhoChanged();
    const { alteredPlans } = investmentInfo;

    const newInvReplannings = newReplannings
      ? isNewInvReplan(newReplannings)
      : [];

    const planParams = {
      investmentInfo,
      newReplannings,
      newInvReplannings,
    };

    if (newReplannings.length > 0) {
      const { updatedInvestment } = handlePlans(planParams);

      handleInvestmentUpdate(updatedInvestment);
    }

    if (investmentInfo.changed && !forceSave)
      handlePendingPlans(investmentInfo);

    return !forceSave && alteredPlans?.length > 0;
  };

  const handleSave = (forceSave = false) => {
    const callInvestmentReplanningModal = handleReplanFlow(
      newPlanReasons.reasons,
      forceSave,
    );

    const {
      projectStage = "",
      projectStageId = false,
      projectConclusion = null,
    } = projectEvaluation || {};

    const { sonDescription = false, parentDescription = false } =
      getStageParentInfoById(projectStageId, projectConfiguration);

    const stage = parentDescription || sonDescription || projectStage;

    const currentPhysicalProgress = getPhysicalProgress(activities);

    const emptyProjectConclusionStatus = !moment(projectConclusion).isValid();

    let callConfirmationModal = false;
    let completePhysicalProgress = false;

    if (emptyProjectConclusionStatus) {
      let isProjectStageDone = false;

      if (currentPhysicalProgress === 100) completePhysicalProgress = true;
      if (stage === "CONCLUIDO") isProjectStageDone = true;

      if (
        completePhysicalProgress ||
        (!completePhysicalProgress && isProjectStageDone)
      )
        callConfirmationModal = true;
    } else if (currentPhysicalProgress < 100) {
      setProjectEvaluation((current) => {
        return {
          ...current,
          projectConclusion: null,
        };
      });
    }

    if (callConfirmationModal) {
      const format = "DD-MM-YYYY";

      setProjectDone((current) => {
        return {
          ...current,
          displayModal: true,
          format,
          completePhysicalProgress,
        };
      });
    } else if (!callInvestmentReplanningModal) {
      setAutomaticSave(true);
      sendMilestoneResponsibleEmails();
    }
  };

  useEffect(() => {
    if (!selectedInsightID) return;

    getEvaluation(selectedInsightID, {
      onSuccess: (data) => {
        if (!data?.id) {
          saveProjectEvaluation();
        }
      },
      onError: (err) => {
        console.error(err);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInsightID]);

  useEffect(() => {
    if (!alreadyCompared) {
      const clone = _.cloneDeep(onStateEvaluation);
      const cleanBuildedStateEvaluation = cleanAndBuildStateEvaluation(
        clone,
        projectConfiguration,
      );

      const currentEvaluation = mountCurrentEvaluation(
        projectEvaluation,
        schedule,
        investment,
        activities,
        _.cloneDeep(effort),
        projectTeam,
        physicalAdvance,
        null,
        null,
        null,
        true,
      );

      const cleanBuildedCurrentEvaluation = cleanAndBuildCurrentEvaluation(
        _.cloneDeep(currentEvaluation),
      );

      let diff = {};

      if (cleanBuildedStateEvaluation && cleanBuildedCurrentEvaluation) {
        diff = getObjectDifference(
          _.cloneDeep(cleanBuildedCurrentEvaluation),
          _.cloneDeep(cleanBuildedStateEvaluation),
        );

        delete diff.projectName;
        delete diff.physicalProgress;

        const { ignoreDiff = false } = investment || {};

        if (ignoreDiff) delete diff.projectInvestment;
      }

      const changesLength = Object.keys(diff).length;

      const invalidActivities = activities.filter(
        (activity) => !activity.responsible?.id || !activity.expectedDate,
      );
      setIsBlockSave(invalidActivities.length > 0); // Block save if there are activities without responsible or expected date

      if (changesLength > 0 || !cleanBuildedStateEvaluation) {
        setDisplaySaveButton(true);
      } else {
        setDisplaySaveButton(false);
      }
    }

    if (alreadyCompared) setAlreadyCompared(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onStateEvaluation,
    projectEvaluation,
    schedule,
    investment,
    activities,
    effort,
    projectTeam,
    physicalAdvance,
  ]);

  useEffect(() => {
    if (sometingWentWrong && onStateEvaluation) {
      const clone = _.cloneDeep(onStateEvaluation);

      const buildedStateEvaluation = buildStateEvaluation(clone);

      const {
        projectEvaluation,
        projectTeam,
        schedule,
        projectInvestment,
        activities,
        projectEffort,
        physicalAdvance,
      } = mountLoadEvaluation(buildedStateEvaluation);

      getIndicators(true);

      setPhysicalAdvance(physicalAdvance || initialStates.physicalAdvance);

      setProjectEvaluation(
        projectEvaluation || initialStates.projectEvaluation,
      );
      setProjectTeam(
        projectTeam || initialStates.projectEvaluation.peopleInvolved,
      );
      setSchedule(schedule || initialStates.projectEvaluation);
      setInvestment(
        projectInvestment || initialStates.projectEvaluation.projectInvestment,
      );
      setActivities(activities || initialStates.projectEvaluation.activities);
      setBuildedActivivites(activities);

      setEffort(projectEffort || initialStates.projectEvaluation.projectEffort);

      setDisplaySaveButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sometingWentWrong]);

  useEffect(() => {
    getAllidChecklistFromActivities().then((response) => {
      if (response && response.length) {
        deleteUnusedChecklist({
          activityIds: response,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (automaticSave) {
      saveProjectEvaluation();
      setAutomaticSave(false);
      setDisplaySaveButton(false);
      setAlreadyCompared(true);

      getIndicators();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [automaticSave]);

  useEffect(() => {
    const currentEvaluationId = currentProjectEvaluation?.id;

    if (allProjectEvaluation.length === 0 || !currentEvaluationId) {
      if (firstMount) setFirstMount(false);
      return;
    }

    const currentEvaluation = getInsightProjectEvaluation(
      selectedInsightID,
      allProjectEvaluation,
    );

    const { projectEvaluation = {} } = currentEvaluation || {};

    const projectId = projectEvaluation?.id;
    const onStateId = onStateEvaluation?.id;

    let diff = {};

    if (onStateEvaluation && currentProjectEvaluation) {
      diff = getObjectDifference(onStateEvaluation, currentProjectEvaluation);
    }

    const changesLength = Object.keys(diff).length;

    if (
      (projectId === currentEvaluationId &&
        currentEvaluationId !== onStateId) ||
      changesLength > 0
    ) {
      if (!firstMount) setFirstMount(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectEvaluation, allProjectEvaluation]);

  useEffect(() => {
    if (onStateEvaluation) {
      const buildedStateEvaluation = onStateEvaluation
        ? buildStateEvaluation(onStateEvaluation)
        : null;

      const {
        projectEvaluation,
        projectTeam,
        schedule,
        projectInvestment,
        activities,
        projectEffort,
        physicalAdvance,
      } = mountLoadEvaluation(buildedStateEvaluation);

      getIndicators(true);

      setPhysicalAdvance(physicalAdvance || initialStates.physicalAdvance);

      setProjectEvaluation(
        projectEvaluation || initialStates.projectEvaluation,
      );
      setProjectTeam(
        projectTeam || initialStates.projectEvaluation.peopleInvolved,
      );
      setSchedule(schedule || initialStates.projectEvaluation);
      setInvestment(
        projectInvestment || initialStates.projectEvaluation.projectInvestment,
      );
      setActivities(activities || initialStates.projectEvaluation.activities);
      setBuildedActivivites(activities);

      setEffort(projectEffort || initialStates.projectEvaluation.projectEffort);

      if (firstMount) setFirstMount(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onStateEvaluation, selectedInsightID]);

  const sendProjectConclusionEmails = async () => {
    const { insight = [] } = entityAccess || {};
    const { message = "", insightName = "" } = getTraceabilityInfo();

    let accesses = [];

    const description = `O Projeto ${insightName} foi concluído!`;

    insight.forEach((access) => {
      const updatedEmailReceivers = addIfNotPresent(accesses, access);

      accesses = [...updatedEmailReceivers];
    });

    const emails = accesses.map(({ email }) => email);

    const domainLink = await getCustomUrlLink("INSIGHT");

    const htmlBody = projectConcludedTemplate(description, message, domainLink);

    postGenericEmail(
      intl.formatMessage(messages.rookau_activity),
      htmlBody,
      emails,
    );
  };

  const getInitialYear = () => {
    const { initialDate, finalDate } = schedule;

    if (
      !initialDate ||
      !finalDate ||
      initialDate === null ||
      finalDate === null
    )
      return { defaultAvailableYear: "", defaultYearOptions: "" };

    const { availableYears, yearOptions } = getAvailableSchedule(schedule);

    return {
      defaultAvailableYear: availableYears[0],
      defaultYearOptions: yearOptions,
    };
  };

  const mountOldestPlan = () => {
    const plansToAccept =
      pendingPlans && pendingPlans.pendingEvaluations
        ? pendingPlans.pendingEvaluations
        : [];

    const currentPlan = plansToAccept[activeStep]
      ? plansToAccept[activeStep]
      : null;

    const plan = currentPlan && currentPlan.plan ? currentPlan.plan : null;
    const currentType =
      currentPlan && currentPlan.type ? currentPlan.type : null;
    const year = plan && plan.year ? plan.year : null;
    let replannings = null;
    let oldBudget = null;
    let oldSpent = null;

    if (currentType === "Investment") {
      replannings = investment.replannings ? investment.replannings : [];
    } else if (currentType === "Efforts") {
      replannings = effort.replannings ? effort.replannings : [];
    }

    const lastReplanning = getLastReplanningByYear(replannings, year);
    const totalValues = getPlanTotalValues(lastReplanning);

    oldBudget =
      totalValues && totalValues.totalBudget ? totalValues.totalBudget : 0;

    oldSpent = totalValues?.totalSpentHours || totalValues?.totalSpent || 0;

    return {
      oldBudget,
      oldSpent,
      oldBalance: oldBudget - oldSpent,
    };
  };

  useEffect(() => {
    const configs = {
      show: displaySaveButton || isBlockSave,
      next: () => handleSave(),
      isBlockSave,
      title: isBlockSave
        ? "Existem atividades sem responsáveis ou entrega prevista, as alterações não serão salvas."
        : "Existem alterações que não foram salvas, se sair sem salvar você perderá as alterações.",
    };
    handleSetConfigsModal(configs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlockSave, displaySaveButton]);

  const handleUpdateActivitiesTypeDefault = (newActivitiesTypeDefault) => {
    if (projectEvaluation.activitiesTypeDefault !== newActivitiesTypeDefault)
      setProjectEvaluation((current) => {
        return {
          ...current,
          activitiesTypeDefault: newActivitiesTypeDefault,
        };
      });
  };

  const handleCancelNewBalances = () => {
    setActiveStep(0);
    setPendingPlans((current) => {
      return { ...current, displayModal: false };
    });
  };

  const getCurrentType = (getTranslatedType = false) => {
    const plansToAccept =
      pendingPlans && pendingPlans.pendingEvaluations
        ? pendingPlans.pendingEvaluations
        : [];

    const currentPlan = plansToAccept[activeStep]
      ? plansToAccept[activeStep]
      : null;

    const plan = currentPlan && currentPlan.plan ? currentPlan.plan : null;
    let currentType = currentPlan && currentPlan.type ? currentPlan.type : null;

    if (getTranslatedType) {
      if (currentType === "Investment") {
        currentType = intl.formatMessage(messages.investment);
      } else if (currentType === "Efforts") {
        currentType = intl.formatMessage(messages.effort);
      }
    }

    return { currentType, plan };
  };

  const displayAcceptPlannings = () => {
    const oldestPlan = mountOldestPlan();
    const { currentType } = getCurrentType();
    const labels = getLabelByType(currentType, intl);
    const { pendingEvaluations } = pendingPlans;
    const { plan: activeStepPlan } = pendingEvaluations[activeStep];
    const { newBudget, newBalance } = calcBudgetSpentBalance(activeStepPlan);

    return (
      <div>
        <Row className="centralized">
          <Col md={5}>
            <MaterialTextField
              id="budget"
              label={labels.firstBox ? labels.firstBox : ""}
              value={
                oldestPlan && oldestPlan.oldBudget ? oldestPlan.oldBudget : ""
              }
              variant="outlined"
              disabled
              textAlign="right"
            />
          </Col>
          <Col md={1} className="centralized verticallyCentralized">
            <i
              className="fas fa-long-arrow-alt-right"
              style={{ fontSize: "30px", color: "#555" }}
            />
          </Col>
          <Col md={5}>
            <MaterialTextField
              id="newBudget"
              label={labels.newFirstBox ? labels.newFirstBox : ""}
              value={newBudget}
              variant="outlined"
              disabled
              textAlign="right"
            />
          </Col>
        </Row>
        <Row className="centralized">
          <Col md={5}>
            <MaterialTextField
              id="Balance"
              label={labels.thirdBox ? labels.thirdBox : ""}
              value={oldestPlan.oldBalance ? oldestPlan.oldBalance : ""}
              variant="outlined"
              disabled
              textAlign="right"
            />
          </Col>
          <Col md={1} className="centralized verticallyCentralized">
            <i
              className="fas fa-long-arrow-alt-right"
              style={{ fontSize: "30px", color: "#555" }}
            />
          </Col>
          <Col md={5}>
            <MaterialTextField
              id="newBalance"
              label={labels.newThirdBox ? labels.newThirdBox : ""}
              value={newBalance}
              variant="outlined"
              disabled
              textAlign="right"
            />
          </Col>
        </Row>
        <Row className="centralized" style={{ marginBottom: "20px" }}>
          <Col md={11}>
            <MaterialTextField
              id="newAcceptReason"
              label="Reason"
              value={
                newPlanReasons &&
                newPlanReasons.reasons &&
                newPlanReasons.reasons[activeStep] &&
                newPlanReasons.reasons[activeStep].reason
                  ? newPlanReasons.reasons[activeStep].reason
                  : ""
              }
              multiline
              variant="outlined"
              onChange={(e) =>
                setNewPlanReasons((current) => {
                  return {
                    ...current,
                    reasons: changeNewPlanReason(
                      current.reasons,
                      currentType,
                      pendingEvaluations[activeStep]?.plan?.year,
                      e.target.value,
                    ),
                  };
                })
              }
            />
          </Col>
        </Row>
      </div>
    );
  };

  const confirmNewPlanning = (action) => {
    const boolAction = action === "confirm";

    if (acceptPlan.year) {
      setActiveStep((current) => {
        const newStep = current + 1;

        setNewPlanReasons((current) => {
          return {
            ...current,
            reasons: confirmPlan(
              newPlanReasons.reasons,
              newStep,
              boolAction,
              acceptPlan,
            ),
          };
        });

        return newStep;
      });
    }
  };

  function getSteps() {
    const pendingEvaluations =
      pendingPlans && pendingPlans.pendingEvaluations
        ? pendingPlans.pendingEvaluations
        : [];
    const pendingTitles = [];
    let yearTitle = "";

    pendingEvaluations.forEach((evaluation, index) => {
      yearTitle = evaluation && evaluation.plan.year ? evaluation.plan.year : 0;

      pendingTitles[index] = yearTitle.toString();
    });
    return pendingTitles;
  }

  const handleResetAllPlans = () => {
    const reasons =
      newPlanReasons && newPlanReasons.reasons ? newPlanReasons.reasons : [];

    const newReasons = [];
    reasons.forEach((reason, index) => {
      newReasons[index] = reason;
      newReasons[index].confirmPlan = false;
      newReasons[index].reason = "";
    });

    setNewPlanReasons({
      reasons: newReasons,
    });
  };

  const handleConfirmNewBalances = () => {
    setPendingPlans((current) => {
      handleSave(true);
      setActiveStep(0);

      return { ...current, displayModal: false };
    });
  };

  const displayNewBalancesModal = () => {
    const { currentType } = getCurrentType(true);
    const modalTitle = currentType
      ? intl.formatMessage(messages.includeNewPlannings) + currentType
      : intl.formatMessage(messages.confirmToSave);

    const steps = getSteps();

    function handleReset() {
      handleResetAllPlans();
      setActiveStep(0);
    }

    return (
      <FormDialog
        open={pendingPlans.displayModal}
        title={modalTitle}
        onConfirm={handleConfirmNewBalances}
        blockConfirm={activeStep !== steps.length}
        onCancel={() => handleCancelNewBalances()}
        bodyStyle={{ padding: "0 15px 15px 15px" }}
      >
        <div>
          <div>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>
                    <span style={{ fontSize: "17px" }}>{label}</span>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {currentType && displayAcceptPlannings()}
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Button onClick={handleReset}>
                    {intl.formatMessage(messages.reset)}
                  </Button>
                </div>
              ) : (
                <div>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={() => confirmNewPlanning("disconfirm")}
                    >
                      <i className="fas fa-angle-left" />{" "}
                      {intl.formatMessage(messages.goBack)}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => confirmNewPlanning("confirm")}
                    >
                      <i className="fas fa-check" />{" "}
                      {activeStep === steps.length - 1
                        ? intl.formatMessage(messages.finish)
                        : intl.formatMessage(messages.accept)}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </FormDialog>
    );
  };

  async function handleConfirmConclusionDate() {
    const { stages = [] } = projectConfiguration;

    const { id = false } =
      stages.filter((stage) => stage.description === "CONCLUIDO")[0] || {};

    setProjectEvaluation((current) => {
      return {
        ...current,
        projectConclusion: projectDone.conclusionDate,
        projectStageId: id,
        projectStage: null,
        updateStatus: true,
      };
    });

    sendProjectConclusionEmails();

    setProjectDone((current) => {
      return {
        ...current,
        displayModal: false,
        format: null,
        projectConclusion: null,
        conclusionDate: null,
      };
    });

    setAutomaticSave(true);
  }

  const handleBlockConclusionConfirm = () => {
    const { conclusionDate = "" } = projectDone;

    if (moment(conclusionDate).isValid()) return false;

    return true;
  };

  const handleChangeProjectConclusion = (e) => {
    const currentProjectConclusion = e.target.value;

    const currentYear = moment(currentProjectConclusion).get("year");

    if (currentYear > 9999) {
      setProjectDone((current) => {
        return { ...current, conclusionDate: "" };
      });

      return;
    }

    setProjectDone((current) => {
      return {
        ...current,
        conclusionDate: currentProjectConclusion,
      };
    });
  };

  const displayConclusionDateModal = () => {
    const modalTitle = intl.formatMessage(messages.confirmConclusionDate);

    const forceConclusionWarning = intl.formatMessage(
      messages.forceConclusionWarning,
    );

    const { completePhysicalProgress = false } = projectDone;

    return (
      <FormDialog
        open={projectDone.displayModal}
        title={modalTitle}
        onConfirm={handleConfirmConclusionDate}
        dialogClassName="conclusionDateStyle"
        onCancel={() =>
          setProjectDone((current) => {
            return {
              ...current,
              displayModal: false,
              format: null,
              completePhysicalProgress: false,
            };
          })
        }
        blockConfirm={handleBlockConclusionConfirm()}
        bodyStyle={{ padding: "0 15px 15px 15px" }}
      >
        <div>
          {!completePhysicalProgress && (
            <Row>
              <Col
                md={12}
                className="measurementRequires conclusionModalWarning"
              >
                {forceConclusionWarning}
              </Col>
            </Row>
          )}
          <Row>
            <Col md={6}>
              <MaterialTextField
                id="firstBox"
                type="date"
                label="Date"
                value={
                  projectDone && projectDone.conclusionDate
                    ? projectDone.conclusionDate
                    : ""
                }
                onChange={(e) => handleChangeProjectConclusion(e)}
                variant="standard"
              />
            </Col>
          </Row>
        </div>
      </FormDialog>
    );
  };

  const handleConfirmSomethingWrong = () => setSometingWentWrong(false);

  const displaySometingWentWrongModal = () => {
    const modalTitle = "Não é possível salvar o projeto!";

    return (
      <FormDialog
        hideCancel
        title={modalTitle}
        open={sometingWentWrong}
        onCancel={handleConfirmSomethingWrong}
        onConfirm={handleConfirmSomethingWrong}
        bodyStyle={{ padding: "15px 15px 15px 15px" }}
      >
        <div style={{ fontWeight: "500" }}>
          <Row>
            As informações do Projeto serão restauradas para antes do erro.
          </Row>
          <Row>Esse acontecimento foi reportado para o suporte.</Row>
        </div>
      </FormDialog>
    );
  };

  const buttonSave = (
    <Button
      className="btn btn-purple pull-right"
      onClick={
        !isBlockSave ? () => handleSave() : () => setModalBlockSave(true)
      }
      style={{ display: displaySaveButton ? "block" : "none" }}
    >
      {intl.formatMessage(messages.save)}
    </Button>
  );

  const sendMilestoneEmailBody = (milestoneResponsible) => {
    const { type = "" } = milestoneResponsible;

    if (type.toUpperCase() === "MEMBER") {
      const updatedEmailReceivers = addIfNotPresent(
        milestonesEmailBodys,
        milestoneResponsible,
      );

      setMilestonesEmailBodys([...updatedEmailReceivers]);
    }
  };

  const getEmailReceiver = (
    { responsible: oldResponsible = {} },
    { responsible: newResponsible = {} },
  ) => {
    const { id: oldResponsibleId = false } = oldResponsible || {};
    const { id: newResponsibleId = false } = newResponsible || {};

    if (!newResponsibleId || oldResponsibleId === newResponsibleId)
      return false;

    return newResponsible;
  };

  const saveEditedActivity = (editedActivity) => {
    setActivities((prevActivities) => [...prevActivities, editedActivity]);

    const milestoneResponsible = getEmailReceiver({}, editedActivity);

    sendMilestoneEmailBody(milestoneResponsible);
  };

  const saveNewActitivy = (newActivities, indexOrder) => {
    setActivities(newActivities);

    const newActivity = newActivities[indexOrder] || {};

    const milestoneResponsible = getEmailReceiver(
      activities[indexOrder],
      newActivity,
    );

    sendMilestoneEmailBody(milestoneResponsible);
  };

  const handleConfirmDelete = (actionIndex) => {
    const updatedActivites = getUpdatedDeleteArrayByIndex(
      activities,
      actionIndex,
    );

    setActivities(updatedActivites);
  };

  const changeStatusActivitiesByIndex = (
    activities,
    acitivityIndex,
    active,
  ) => {
    return activities.map((activity, index) => {
      if (acitivityIndex === index) return { ...activity, active };
      return activity;
    });
  };

  const handleChangeStatusActivities = (actionIndex, active) => {
    setActivities(
      changeStatusActivitiesByIndex(activities, actionIndex, active),
    );
  };

  const saveButtonJSX = () => {
    return checkAccess(
      [
        "CREATOR",
        "RESPONSIBLE",
        "COLLABORATOR",
        "MANAGER",
        "LEADER",
        "MODERATOR",
        "ADMIN",
        "OWNER",
      ],
      buttonSave,
    );
  };

  //if (firstMount) return <LoadingFallback open showCircularProgress />;

  return (
    <div className="projectEvaluation">
      <FormDialog
        title="Campos requeridos"
        hideConfirm
        cancelText="Fechar"
        description="Existem atividades sem responsáveis ou entrega prevista, as alterações não serão salvas."
        open={modalBlockSave}
        onCancel={() => setModalBlockSave(false)}
      />
      <Row>
        <Col md={6} className="paddingLeft0">
          <Description
            projectConfiguration={projectConfiguration}
            projectEvaluation={projectEvaluation}
            handleUpdateSingleField={handleUpdateSingleField}
            saveButton={displaySaveButton}
            saveButtonJSX={saveButtonJSX}
          />
        </Col>
        <Col md={6}>
          <TeamTable
            updateResponsible={updateResponsible}
            projectTeam={_.cloneDeep(projectTeam)}
            setProjectTeam={setProjectTeam}
            saveButton={displaySaveButton}
            saveButtonJSX={saveButtonJSX}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} className="paddingLeft0">
          <ScheduleTable
            schedule={_.cloneDeep(schedule)}
            setSchedule={setSchedule}
            updateSingleScheduleField={updateSingleScheduleField}
            saveButton={displaySaveButton}
            saveButtonJSX={saveButtonJSX}
          />
        </Col>
        <Col md={6}>
          <InvestmentDisplayTable
            investment={_.cloneDeep(investment)}
            effort={_.cloneDeep(effort)}
            schedule={_.cloneDeep(schedule)}
            setInvestment={setInvestment}
            setEffort={setEffort}
            saveButton={displaySaveButton}
            saveButtonJSX={saveButtonJSX}
            getInitialYear={getInitialYear}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={9} sm={12} className="paddingLeft0">
          <ActivitiesTable
            activities={activities}
            setActivities={setActivities}
            postChecklist={postChecklist}
            weightAutomation={_.cloneDeep(projectEvaluation.weightAutomation)}
            effortAutomation={_.cloneDeep(projectEvaluation.effortAutomation)}
            activitiesType={_.cloneDeep(projectEvaluation.activitiesType)}
            setProjectEvaluation={setProjectEvaluation}
            selectedInsightID={selectedInsightID}
            handleUpdateActivitiesTypeDefault={
              handleUpdateActivitiesTypeDefault
            }
            onConfirmEdit={saveEditedActivity}
            onConfirmSave={saveNewActitivy}
            onConfirmDelete={handleConfirmDelete}
            onChangeStatus={handleChangeStatusActivities}
            saveButton={displaySaveButton}
            saveButtonJSX={saveButtonJSX}
            saveTemplateActivities={saveTemplateActivities}
          />
        </Col>
        <Col xl={3} sm={6} className="paddingLeft0">
          <PhysicalProgressTable
            physicalAdvancePlannings={
              _.cloneDeep(physicalAdvance.plannings) || []
            }
            setPhysicalAdvance={setPhysicalAdvance}
            schedule={_.cloneDeep(schedule)}
            saveButton={displaySaveButton}
            saveButtonJSX={saveButtonJSX}
            getInitialYear={getInitialYear}
          />
        </Col>
        <Col md={6} className="paddingLeft0">
          <IndicatorsTable
            indicators={_.cloneDeep(indicators)}
            saveButton={displaySaveButton}
            saveButtonJSX={saveButtonJSX}
          />
        </Col>
      </Row>
      {pendingPlans.displayModal && displayNewBalancesModal()}
      {projectDone.displayModal && displayConclusionDateModal()}
      {sometingWentWrong && displaySometingWentWrongModal()}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { allProjectEvaluation, currentProjectEvaluation } =
    getProjectStates(state);
  const { entityAccess = {} } = getAccessStates(state);
  const { selectedCompany } = getSelectedCompanyStates(state);
  const { selectedToolID } = getSelectedToolStates(state);
  const { selectedWorkspace = {} } = getWorkspacesStates(state);
  const { selectedInsightID = false } = getInsightStates(state);

  return {
    entityAccess,
    allProjectEvaluation,
    selectedCompany,
    selectedWorkspace,
    selectedInsightID,
    currentProjectEvaluation,
    selectedToolID,
  };
};

function areEqual(prevProps, nextProps) {
  if (
    !_.isEqual(
      prevProps.currentProjectEvaluation,
      nextProps.currentProjectEvaluation,
    )
  )
    return false;
  if (
    !_.isEqual(prevProps.projectConfiguration, nextProps.projectConfiguration)
  )
    return false;
  if (!_.isEqual(prevProps.projectEvaluation, nextProps.projectEvaluation))
    return false;
  if (!_.isEqual(prevProps.historyEvaluation, nextProps.historyEvaluation))
    return false;
  return true;
}

export default connect(mapStateToProps, {
  addProjectEvaluation,
  getEvaluation,
})(injectIntl(React.memo(ProjectEvaluation, areEqual)));
