import React, { Component } from "react";
import { injectIntl, defineMessages } from "react-intl";
import AverageBadge from "./AverageBadge";
import {
  getTitleByIntl,
  generateImportancePoints,
  generateImpactPoints,
  getCustomColorClass,
  swotEvaluationTotalScore,
  generateReliabilityPoints,
} from "../../constants/swotCustom";

const messages = defineMessages({
  impact: { id: "global.impact" },
  importance: { id: "global.importance" },
  reliability: { id: "global.reliability" },
  global_noData: { id: "global.noData" },
});

class TableEvaluationSwot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title,
      fields: props.fields,
      fieldValues: props.values,
      options: props.options,
      expanded: true,
    };

    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded });
  }

  UNSAFE_componentWillReceiveProps({ title, fields, values, options }) {
    this.setState({ title, fields, tableValues: values, options });
  }

  generateLine(answer, index, customColorClass) {
    let impactScore = 0;
    let importanceScore = 0;
    let reliabilityScore = 0;

    impactScore = generateImpactPoints(
      answer.swotAnalysis && answer.swotAnalysis.impactOnResults !== null
        ? answer.swotAnalysis.impactOnResults
        : 0,
    );

    importanceScore = generateImportancePoints(
      answer.swotAnalysis && answer.swotAnalysis.importanceLevel !== null
        ? answer.swotAnalysis.importanceLevel
        : 0,
    );

    reliabilityScore = generateReliabilityPoints(
      answer.swotAnalysis && answer.swotAnalysis.reliabilityLevel !== null
        ? answer.swotAnalysis.reliabilityLevel
        : 0,
    );

    let totalScore =
      answer.swotAnalysis && answer.swotAnalysis.score
        ? answer.swotAnalysis.score
        : 0;
    totalScore /= 1.25;

    const { selectAndOpenInsight } = this.props;

    return (
      <div
        className="row"
        style={{ margin: "0 auto", display: "flex", alignItems: "center" }}
        key={index}
      >
        <div
          style={{
            width: "auto",
            flex: 1,
            padding: "15px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontWeight: "500",
            cursor: "pointer",
          }}
          title={answer.text}
          onClick={() => selectAndOpenInsight(answer.id)}
        >
          {answer.text}
        </div>
        <div style={{ width: "100px", padding: "0px 10px" }}>
          <AverageBadge
            fontSize="18px"
            padding="10px"
            lineHeight="12px"
            fontWeight="bold"
            maxValue={5}
            displayMaxValue
            hideColor
            borderedBadge
            average={impactScore}
          />
        </div>
        <div style={{ width: "100px", padding: "0px 10px" }}>
          <AverageBadge
            fontSize="18px"
            padding="10px"
            lineHeight="12px"
            fontWeight="bold"
            maxValue={5}
            displayMaxValue
            hideColor
            borderedBadge
            average={importanceScore}
          />
        </div>
        <div style={{ width: "100px", padding: "0px 10px" }}>
          <AverageBadge
            fontSize="18px"
            padding="10px"
            lineHeight="12px"
            fontWeight="bold"
            maxValue={5}
            displayMaxValue
            hideColor
            borderedBadge
            average={reliabilityScore}
          />
        </div>
        <div
          style={{ width: "130px", padding: "0px 10px", textAlign: "center" }}
        >
          <AverageBadge
            fontSize="18px"
            padding="10px"
            lineHeight="12px"
            fontWeight="bold"
            maxValue={swotEvaluationTotalScore}
            displayMaxValue
            hideColor
            borderedBadge
            average={
              answer.swotAnalysis && answer.swotAnalysis.score
                ? answer.swotAnalysis.score
                : 0
            }
          />
        </div>
        <div
          style={{ width: "150px", display: "flex", justifyContent: "center" }}
        >
          <div
            className="progress-management progress"
            style={{
              width: "100px",
              height: "20px",
              textAlign: "center",
            }}
          >
            <div
              role="progressbar"
              className={customColorClass.class}
              style={{ width: `${totalScore}%` }}
            />
          </div>
        </div>
      </div>
    );
  }

  getTotalScore(answers) {
    let totalScore = 0;
    if (answers) {
      Object.keys(answers).forEach((answerID) => {
        totalScore +=
          answers[answerID].swotAnalysis &&
          answers[answerID].swotAnalysis.score !== undefined
            ? answers[answerID].swotAnalysis.score
            : 0;
      });
    }
    return totalScore || 0;
  }

  render() {
    const { title, answers, intl } = this.props;
    const { expanded } = this.state;
    const customColorClass = getCustomColorClass(title);

    return (
      <div className="evaluation-card">
        <div
          className="card-header card-header-hover row"
          style={{ justifyContent: "space-between", cursor: "pointer" }}
          onClick={this.toggleExpanded}
        >
          <div
            className="card-title title-wrapper"
            style={{
              justifyContent: "flex-start",
              padding: "0px",
              alignItems: "center",
              flex: 1,
            }}
          >
            <i
              className={customColorClass.customIcon}
              style={{ color: "#666" }}
            />
            <h4 className="table-title" style={{ color: "#444" }}>
              {getTitleByIntl(title, intl)}
            </h4>
          </div>
          <div
            style={{
              width: "158px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ width: "80px", marginRight: "15px" }}>
              <AverageBadge
                average={this.getTotalScore(answers)}
                customColor={customColorClass.color}
                fontSize="35"
                fontWeight="bold"
                fontColor="#fff"
              />
            </div>
          </div>
        </div>
        <div
          className="card-body"
          style={{ padding: "0px", display: expanded ? "block" : "none" }}
        >
          <div
            style={{
              display: "flex",
              fontSize: "12px",
              textAlign: "center",
              backgroundColor: "#eee",
              padding: "4px 0px",
              borderTop: "1px solid #e1e1e1",
              borderBottom: "1px solid #e1e1e1",
            }}
          >
            <div style={{ width: "auto", flex: 1 }} />
            <div style={{ width: "100px", padding: "0px 10px" }}>
              {intl.formatMessage(messages.impact)}
            </div>
            <div style={{ width: "100px", padding: "0px 10px" }}>
              {intl.formatMessage(messages.importance)}
            </div>
            <div style={{ width: "100px", padding: "0px 10px" }}>
              {intl.formatMessage(messages.reliability)}
            </div>
            <div
              style={{
                width: "130px",
                padding: "0px 10px",
                textAlign: "center",
              }}
            >
              Total
            </div>
            <div style={{ width: "158px" }} />
          </div>
          <div className="fixedScroll" style={{ minWidth: "255px !important" }}>
            {answers &&
              Object.keys(answers).map((answerID, index) =>
                this.generateLine(answers[answerID], index, customColorClass),
              )}
            {!answers || answers.length === 0 ? (
              <div style={{ padding: "15px 0px 0px 0px", textAlign: "center" }}>
                {intl.formatMessage(messages.global_noData)}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
export default injectIntl(TableEvaluationSwot);
