import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ConfirmationDialog from "../../ConfirmationDialog";
import TeamForm from "../../../Tool/ToolTabs/CustomFields/Okr/tabs/forms/TeamForm";
import { putPlan, postPlan, deletePlan } from "../../../../actions/okrActions";
import FormDialog from "../../FormDialog";
import MaterialSingleSelect from "../../MaterialSingleSelect";

import utils from "../../../../utils/toolUtils";

const { getErrorMessage } = utils;

const useStyles = makeStyles(() =>
  createStyles({
    addButton: {
      fontSize: "12px",
    },
  }),
);

const defaultParams = {
  type: "",
  params: {},
};

const defaultConfirmDialog = {
  title: "",
  onConfirm: null,
  onCancel: null,
  open: false,
  params: {},
};

const ScenerieSelectDialog = (props) => {
  const [modalSlug, setModalSlug] = useState([]);
  const [editingForm, setEditingForm] = useState(defaultParams);
  const [confirmDialog, setConfirmDialog] = useState(defaultConfirmDialog);
  const [modalNewScenary, setModalNewScenary] = useState(false);
  const [selectedScenaryId, setSelectedScenaryId] = useState(null);

  const classes = useStyles();

  const {
    open = false,
    afterCancel,
    allThemes,
    okrPlans,
    cycleBase,
    createOrUpdatePlan,
    getDefaultAddTableLine,
    getDefaultRemoveColumn,
    addPlanCyclesAndThemesToBoard,
    addedSceneries,
  } = props;

  const resetForm = () => {
    setSelectedScenaryId(0);
  };

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  function resetEditingForm() {
    setEditingForm(defaultParams);
  }

  function handleOpenModal(slug, open = false, params = {}) {
    const newSlugs = [...modalSlug];
    const indexOfSlug = newSlugs.indexOf(slug);

    if (!open && indexOfSlug > -1) {
      newSlugs.splice(newSlugs.indexOf(slug), 1);
      resetEditingForm();
    } else if (open && indexOfSlug === -1) {
      newSlugs.push(slug);
    }

    if (Object.keys(params).length > 0) {
      setEditingForm(params);
    }

    setModalSlug(newSlugs);
  }

  function resetDialog() {
    setConfirmDialog(defaultConfirmDialog);
  }

  function mountDialog(index, params) {
    const { handleDelete, getOpen, getTitle } = params;

    function resetDelete(index) {
      handleDelete(index);
      resetDialog();
    }

    return {
      title: getTitle(),
      open: getOpen(),
      onCancel: () => resetDialog(),
      onConfirm: () => resetDelete(index),
    };
  }

  function openFormDelete(index, getParams) {
    setConfirmDialog(mountDialog(index, getParams));
  }

  const handleSelectScenary = useCallback((e) => {
    setSelectedScenaryId(e.target.value);
  }, []);

  const getSelectedScenary = useCallback(() => {
    return okrPlans.find((okrPlan) => okrPlan.id === selectedScenaryId);
  }, [okrPlans, selectedScenaryId]);

  const handleConfirmAddOkrPlan = useCallback(() => {
    const selectedScenary = getSelectedScenary();

    addPlanCyclesAndThemesToBoard(selectedScenary);
    afterCancel();
  }, [addPlanCyclesAndThemesToBoard, getSelectedScenary, afterCancel]);

  function okrPlansOptions() {
    return okrPlans.map((okrPlan) => ({
      value: okrPlan.id,
      label: okrPlan.organization.title,
    }));
  }

  const handleCloseModalNewScenary = useCallback(() => {
    setModalNewScenary(false);
  }, []);

  const getFormErrors = () => {
    const errors = [];

    if (
      (!selectedScenaryId || selectedScenaryId === "empty") &&
      selectedScenaryId !== 0
    ) {
      errors.push({ slug: "scenaryNotSelected" });
    }

    if (addedSceneries.indexOf(selectedScenaryId) > -1) {
      errors.push({
        slug: "scenaryDuplicated",
        message: "Este cenário já esta cadastrado nesse quadro!",
      });
    }

    return errors;
  };

  const formErrors = getFormErrors();

  const essencialProps = {
    allThemes,
    getDefaultAddTableLine,
    getDefaultRemoveColumn,
    modalSlug,
    isEditing: editingForm,
    okrPlans,
    cycleBase,
  };

  return (
    <>
      <ConfirmationDialog
        open={confirmDialog.open}
        title={confirmDialog.title}
        description={confirmDialog.description}
        onConfirm={confirmDialog.onConfirm}
        confirmText={confirmDialog.confirmText}
        onCancel={confirmDialog.onCancel}
        hideCancel={confirmDialog.hideCancel}
        confirmColor="#ff3151"
      />
      <TeamForm
        open={modalNewScenary}
        plans={okrPlans}
        essencialProps={essencialProps}
        createOrUpdatePlan={createOrUpdatePlan}
        afterConfirm={afterCancel}
        openCicleForm={handleOpenModal}
        onCancel={handleCloseModalNewScenary}
        openFormDelete={openFormDelete}
        cycleBase={cycleBase}
      />

      <FormDialog
        open={open}
        title="Selecione um cenário"
        onConfirm={handleConfirmAddOkrPlan}
        onCancel={afterCancel}
        blockConfirm={formErrors.length > 0}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialSingleSelect
              id="Scenerie"
              label="Selecione uma opção"
              value={selectedScenaryId}
              options={okrPlansOptions()}
              onChange={handleSelectScenary}
            />
            {getErrorMessage(["scenaryDuplicated"], formErrors, "left", {
              height: "22px",
            })}
            <Button
              variant="outlined"
              className={classes.addButton}
              onClick={() => setModalNewScenary(!modalNewScenary)}
            >
              <AddIcon /> Cadastre um novo
            </Button>
          </div>
        </div>
      </FormDialog>
    </>
  );
};

const mapDispatchToProps = {
  putPlan,
  postPlan,
  deletePlan,
};

export default connect(null, mapDispatchToProps)(ScenerieSelectDialog);

ScenerieSelectDialog.propTypes = {
  open: PropTypes.bool,
  afterCancel: PropTypes.func,
  allThemes: PropTypes.object.isRequired,
  okrPlans: PropTypes.array.isRequired,
  addedSceneries: PropTypes.array.isRequired,
  createOrUpdatePlan: PropTypes.func.isRequired,
  getDefaultAddTableLine: PropTypes.func.isRequired,
  getDefaultRemoveColumn: PropTypes.func.isRequired,
  addPlanCyclesAndThemesToBoard: PropTypes.func,
  cycleBase: PropTypes.object.isRequired,
};

ScenerieSelectDialog.defaultProps = {
  open: false,
  afterCancel: () => {},
  addPlanCyclesAndThemesToBoard: () => {},
};
