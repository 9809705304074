import axios from "axios";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import React, { useCallback, useEffect } from "react";
import { loadProgressBar } from "axios-progress-bar";
import axiosRequestProfiler from "axios-api-profiler";

import App from "../../App";
import env from "../../../utils/environmentUtils";
import { getSelectedTool } from "../../../actions/toolActions";
import { buildAccess } from "../../../actions/accessLevelsActions";
import { getSelectedCompany } from "../../../actions/companyActions";
import { getAccessStates, getAuthStates } from "../../customMapStates";
import { getSelectedWorkspace } from "../../../actions/workspaceActions";
import { accessLevelsEssentials } from "../../../actions/accessControllerActions";
import { apiNode } from "../../../api";

const DomainConfigurator = ({
  intl = {},
  buildAccess,
  access = {},
  workspaceId = 0,
  getSelectedTool,
  currentUser = {},
  isLoading = true,
  authProvider = "",
  getSelectedCompany,
  getSelectedWorkspace,
  accessLevelsEssentials,
  isAuthenticated = false,
  selectedDomainId = false,
  selectedCompanyId = false,
}) => {
  const { currentDomain = {} } = access;
  const { id: currentDomainId = null } = currentDomain || {};

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_JAVA;
    if (env.isDev()) {
      axiosRequestProfiler(axios);
    }

    console.log("Configurações de API:", {
      _client: `Ambiente=${env.getAmbiente()}`,
      api: `baseURL=${axios.defaults.baseURL}`,
      nodeApi: `baseURL=${env.getBaseURLNodeApi()}`,
    });

    loadProgressBar();
  }, []);

  useEffect(() => {
    const commonHeaders = {
      "workspace-id": workspaceId,
      "domain-id": selectedDomainId,
      "auth-provider": authProvider,
      "company-id": selectedCompanyId,
      "user-id": currentUser?.id || null,
    };

    axios.defaults.headers.common = {
      ...axios.defaults.headers.common,
      ...commonHeaders,
    };
    apiNode.defaults.headers.common = {
      ...apiNode.defaults.headers.common,
      ...commonHeaders,
    };

    localStorage.setItem(
      "@rookau:commonDomainHeaders",
      JSON.stringify(commonHeaders),
    );
  }, [
    currentUser,
    workspaceId,
    authProvider,
    selectedDomainId,
    selectedCompanyId,
  ]);

  useEffect(() => {
    if (isLoading || !isAuthenticated) return;

    getSelectedCompany();
    getSelectedWorkspace();
    getSelectedTool();
  }, [
    getSelectedCompany,
    getSelectedTool,
    getSelectedWorkspace,
    isLoading,
    isAuthenticated,
  ]);

  // Realiza build de requisições necessárias para o controle de níveis de acesso
  useEffect(() => {
    if (access.buildedAccess === true) accessLevelsEssentials();
  }, [access, accessLevelsEssentials]);

  const selectedDomainChanged = useCallback(() => {
    if (Number(currentDomainId) !== Number(selectedDomainId)) return true;
    return false;
  }, [currentDomainId, selectedDomainId]);

  // Realiza build de requisições necessárias para o controle de níveis de acesso
  useEffect(() => {
    const { id: userId = null } = currentUser || {};
    const { currentDomain = {}, buildedAccess = false } = access || {};

    const domainChanged = selectedDomainChanged();
    const domainConditions = domainChanged && selectedDomainId && currentDomain;

    if (userId && (!buildedAccess || domainConditions)) buildAccess(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, selectedDomainId]);

  return <App intl={intl} isLoading={isLoading} />;
};

const mapStateToProps = (state) => {
  const { auth = {}, companyData = {}, workspacesData = {} } = state;

  const { selectedCompany = {} } = companyData || {};
  const { id: selectedCompanyId } = selectedCompany || {};
  const { access = {}, selectedDomainId = false } = getAccessStates(state);
  const { authProvider = "", isAuthenticated = false } = getAuthStates(state);

  return {
    access,
    authProvider,
    isAuthenticated,
    selectedDomainId,
    currentUser: auth.user,
    selectedCompanyId,
    workspaceId: workspacesData.selectedWorkspace?.id,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    buildAccess,
    getSelectedTool,
    getSelectedCompany,
    getSelectedWorkspace,
    accessLevelsEssentials,
  })(DomainConfigurator),
);
