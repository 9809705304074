import moment from "moment";
import { defineMessages } from "react-intl";
import { searchAllInsightActivities } from "../../../actions/entity-documents/rookau_tool_processes_activity";
import { simpleSaveAsExcel } from "../../../components/Common/Export/ExportCSV";
import { translatedText } from "../../translationUtils";
import {
  formatFrequency,
  formatPeriodicity,
  formatPeriodDemandGrowth,
  formatChecklistIndicator,
} from "./format";

const messages = defineMessages({
  cenary: { id: "global.cenary" },
  theme: { id: "global.theme" },
  objective: { id: "global.objective" },
  keyResult: { id: "global.keyResult" },
  responsible: { id: "global.responsible" },
  traceabilityConnections: { id: "global.traceabilityConnections" },
  insightPoints: { id: "global.insightPoints" },
  status: { id: "global.status" },
  reach_performance_okr: { id: "reach_performance_okr" },
  acp_main_title: { id: "acp_main_title" },
});

const translation = (id) => translatedText(id, messages);

export async function exportProcessesReport({
  list,
  companyMembersWithProfile,
}) {
  if (list && list.length > 0) {
    const headers = [
      { header: "ID", key: "id", width: 10 },
      {
        header: translation("cenary"),
        key: "cenary",
        width: 30,
      },
      {
        header: translation("theme"),
        key: "theme",
        width: 30,
      },
      {
        header: translation("objective"),
        key: "objective",
        width: 60,
      },
      {
        header: "Processo",
        key: "process",
        width: 60,
      },
      {
        header: "Atividade",
        key: "activityTitle",
        width: 60,
      },
      {
        header: "Responsável",
        key: "activityResponsible",
        width: 30,
      },
      {
        header: "Cargo",
        key: "activityResponsiblePosition",
        width: 20,
      },
      {
        header: "Função",
        key: "activityResponsibleRole",
        width: 25,
      },
      {
        header: "Relevância",
        key: "activityRelevance",
        width: 15,
      },
      {
        header: "Complexidade",
        key: "activityComplexity",
        width: 15,
      },
      {
        header: "Frequência",
        key: "activityFrequency",
        width: 15,
      },
      {
        header: "Periodicidade",
        key: "activityPeriodicity",
        width: 15,
      },
      {
        header: "Tempo (min.)",
        key: "activityTimeMin",
        width: 15,
      },
      {
        header: "Tempo (med.)",
        key: "activityTimeMed",
        width: 15,
      },
      {
        header: "Tempo (max.)",
        key: "activityTimeMax",
        width: 15,
      },
      {
        header: "Tempo Total",
        key: "activityTotalTime",
        width: 15,
      },
      {
        header: "Demanda (min.)",
        key: "activityDemandMin",
        width: 15,
      },
      {
        header: "Demanda (med.)",
        key: "activityDemandMed",
        width: 15,
      },
      {
        header: "Demanda (max.)",
        key: "activityDemandMax",
        width: 15,
      },
      {
        header: "Demanda Total",
        key: "activityTotalDemand",
        width: 15,
      },
      {
        header: "Crescimento da demanda",
        key: "activityPeriodDemandGrowth",
        width: 15,
      },
      {
        header: "Tarefas",
        key: "activityChecklistIndicator",
        width: 10,
      },
    ];

    const insight_ids = list.map((insight) => insight.id);
    const { data: activities } = await searchAllInsightActivities({
      insight_ids,
    });

    const data = [];

    const formatTime = (timeInMinutes) => {
      const hours = Math.floor(timeInMinutes / 60);
      const minutes = timeInMinutes % 60;
      const total = `${String(hours).padStart(2, "0")}:${String(
        minutes,
      ).padStart(2, "0")}`;
      return total;
    };

    activities.forEach(({ entity_id, result }) => {
      const insightInfo = list.find((insight) => insight.id === entity_id);

      if (result.length > 0) {
        result.forEach(({ document_data: activity, id }) => {
          const responsible = companyMembersWithProfile.find(
            (member) => member.id === activity.responsibleId,
          );

          const totalTime = ["min", "med", "max"].reduce((acc, key) => {
            const time = activity?.extimatedTime?.[key];
            if (time) {
              const [hours, minutes] = time.split(":").map(Number);
              const totalExtimatedTime = hours * 60 + minutes;
              return acc + totalExtimatedTime;
            }
            return acc;
          }, 0);

          const activityDemandMin = activity?.demand?.min
            ? parseFloat(activity?.demand?.min)
            : 0;
          const activityDemandMed = activity?.demand?.med
            ? parseFloat(activity?.demand?.med)
            : 0;
          const activityDemandMax = activity?.demand?.max
            ? parseFloat(activity?.demand?.max)
            : 0;

          const totalDemand =
            activityDemandMin + activityDemandMed + activityDemandMax;

          data.push({
            id,
            cenary: insightInfo.cenaryName,
            theme: insightInfo.themeName,
            objective: insightInfo.questionName,
            process: insightInfo.text,
            activityTitle: activity.title,
            activityResponsible: responsible?.name,
            activityResponsiblePosition:
              responsible?.profile?.companyPosition?.title,
            activityResponsibleRole: activity?.responsibleRole,
            activityRelevance: activity?.relevance,
            activityComplexity: activity?.complexity,
            activityFrequency: formatFrequency(activity?.frequency),
            activityPeriodicity: formatPeriodicity(activity?.periodicity),
            activityTimeMin: activity?.extimatedTime?.min,
            activityTimeMed: activity?.extimatedTime?.med,
            activityTimeMax: activity?.extimatedTime?.max,
            activityTotalTime: formatTime(totalTime),
            activityDemandMin: activity?.demand?.min
              ? parseFloat(activity?.demand?.min)
              : " ",
            activityDemandMed: activity?.demand?.med
              ? parseFloat(activity?.demand?.med)
              : " ",
            activityDemandMax: activity?.demand?.max
              ? parseFloat(activity?.demand?.max)
              : " ",
            activityTotalDemand: totalDemand,
            activityPeriodDemandGrowth: formatPeriodDemandGrowth(
              activity?.periodDemandGrowth,
            ),
            activityChecklistIndicator: formatChecklistIndicator(
              activity?.checklist,
            ),
          });
        });
      } else {
        data.push({
          cenary: insightInfo.cenaryName,
          theme: insightInfo.themeName,
          objective: insightInfo.questionName,
          process: insightInfo.text,
        });
      }
    });

    const timeStampString = moment().toJSON();

    simpleSaveAsExcel(
      data,
      headers,
      null,
      `process-big-picture-report-${timeStampString}`,
      [{ line: 1, fill: "eeeeee" }],
    );
  }
}
