import React from "react";
import SingleTable from "../SingleTable";
import OverallTable from "../OverallTable";
import { getPestalStructure } from "../../../../../../../utils/cpAnalysis/entity";

const PestalManagement = ({ pestalData = [], intl, selectAndOpenInsight }) => {
  const {
    politicalFactors = {},
    economicalFactors = {},
    socialFactors = {},
    technologicalFactors = {},
    ambientalFactors = {},
    legalFactors = {},
    headers = [],
    factorsStructure = [],
  } = getPestalStructure(pestalData);

  const essentialProps = { intl, headers, selectAndOpenInsight };

  return (
    <div>
      <div className="row overallFactors">
        <div className="col-xs-12 col-lg-12" style={{ padding: "0px" }}>
          <OverallTable
            {...essentialProps}
            data={factorsStructure}
            minimalism
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-lg-12" style={{ padding: "0px" }}>
          <SingleTable
            title="Fatores Politicos"
            {...essentialProps}
            data={politicalFactors.evaluations}
            result={politicalFactors.result}
            colorClass="politicalFactorColor"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-lg-12" style={{ padding: "0px" }}>
          <SingleTable
            title="Fatores Economicos"
            {...essentialProps}
            data={economicalFactors.evaluations}
            result={economicalFactors.result}
            colorClass="economicalFactorColor"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-lg-12" style={{ padding: "0px" }}>
          <SingleTable
            title="Fatores Sociais"
            {...essentialProps}
            data={socialFactors.evaluations}
            result={socialFactors.result}
            colorClass="socialFactorColor"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-lg-12" style={{ padding: "0px" }}>
          <SingleTable
            title="Fatores Tecnológicos"
            {...essentialProps}
            data={technologicalFactors.evaluations}
            result={technologicalFactors.result}
            colorClass="technologicalFactorColor"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-lg-12" style={{ padding: "0px" }}>
          <SingleTable
            title="Fatores Ambientais"
            {...essentialProps}
            data={ambientalFactors.evaluations}
            result={ambientalFactors.result}
            colorClass="ambientalFactorColor"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-lg-12" style={{ padding: "0px" }}>
          <SingleTable
            title="Fatores Legais"
            {...essentialProps}
            data={legalFactors.evaluations}
            result={legalFactors.result}
            colorClass="legalFactorColor"
          />
        </div>
      </div>
    </div>
  );
};

export default PestalManagement;
