import React from "react";

import GoalListItem from "../../../../../Common/GoalListItem";

import {
  getFilteredAllAnswerOkrs,
  groupedInsightsEvaluations,
  getKeyResult,
} from "../../../../../../utils/okrUtils";
import { getToFixed } from "../../../../../../utils/MathUtils";
import { getMaxStringLength } from "../../../../../../utils/StringUtils";

import { Display, Title, AlertStyle } from "../../../CustomTablesOkr/OkrStyle";

const CycleVision = ({ plansKeyResults, options, intl }) => {
  function getPercentageByCycle() {
    let sum = 0;
    let sumReached = 0;
    let totalSum = 0;

    plansKeyResults.forEach((planKeyResult) => {
      const {
        REACHED,
        EXCEEDED,
        PARTIALLY_REACHED,
        CANCELED,
        NOT_REACHED,
      } = getFilteredAllAnswerOkrs(planKeyResult.insights, options.status);

      if (
        REACHED.length ||
        EXCEEDED.length ||
        PARTIALLY_REACHED.length ||
        NOT_REACHED.length ||
        CANCELED.length
      ) {
        sumReached += REACHED.length + EXCEEDED.length;
        sum += PARTIALLY_REACHED.length + NOT_REACHED.length - CANCELED.length;
        totalSum = sum + sumReached;
      }
    });

    return getToFixed((sumReached / totalSum) * 100);
  }

  const orderedKeyResults = plansKeyResults.sort((a, b) => {
    if (a.theme.name > b.theme.name) {
      return 1;
    }
    if (a.theme.name < b.theme.name) {
      return -1;
    }

    return 0;
  });

  return (
    <Display>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title>{intl.formatMessage({ id: "title_okr_deliveries" })}</Title>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <span
            style={{
              display: "flex",
              width: "150px",
              flexDirection: "column",
              alignItems: "center",
              fontSize: "10px",
            }}
          >
            {intl.formatMessage({ id: "reach_performance_okr" })}{" "}
            <b style={{ fontSize: "18px" }}>
              {groupedInsightsEvaluations(
                plansKeyResults,
                options.okrValue,
                "insights",
              )}
              %
            </b>
          </span>
          <span
            style={{
              display: "flex",
              width: "150px",
              flexDirection: "column",
              alignItems: "center",
              fontSize: "10px",
            }}
          >
            {intl.formatMessage({ id: "delivery_performance_okr" })}{" "}
            <b style={{ fontSize: "18px" }}>{getPercentageByCycle()}%</b>
          </span>
        </div>
      </div>
      {orderedKeyResults.length ? (
        orderedKeyResults.map((planKeyResult, index) => {
          const { REACHED, EXCEEDED, CANCELED } = getFilteredAllAnswerOkrs(
            planKeyResult.insights,
            options.status,
          );

          const calculatePlan =
            ((REACHED.length + EXCEEDED.length - CANCELED.length) * 100) /
            planKeyResult.insights.length;

          const goalsReachPercentage = groupedInsightsEvaluations(
            [planKeyResult],
            options.okrValue,
          );

          function getTitleCycle() {
            return (
              <span>
                {getMaxStringLength({
                  string: planKeyResult.theme.name,
                  beginIndex: 0,
                  endIndex: 84,
                  additional: "...",
                })}{" "}
                -
                <span style={{ color: "#666666" }}>
                  {` ${intl.formatMessage({ id: "global.cenary" })} ${
                    planKeyResult.theme.cenary.name
                  }`}
                </span>
              </span>
            );
          }

          const badge = (
            <div style={{ width: "120px", textAlign: "center" }}>
              <div
                className="badge answer bg-purple"
                style={{ height: "20px" }}
              >{`${goalsReachPercentage}%`}</div>
            </div>
          );

          return (
            <GoalListItem
              key={index}
              title={getTitleCycle()}
              description={`${
                planKeyResult.insights.length
              } ${intl.formatMessage({ id: "key_results_counter" })}`}
              currentPercentage={calculatePlan}
              appendBeforePercentage={badge}
              fixed={0}
              progressColor="#7ea6c7"
              barTotalWidth={80}
              popover={getKeyResult(planKeyResult.insights, options)}
            />
          );
        })
      ) : (
        <AlertStyle severity="info">
          {intl.formatMessage({ id: "info_cycles" })}
        </AlertStyle>
      )}
    </Display>
  );
};

export default CycleVision;
