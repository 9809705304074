import React, { useState, useEffect } from "react";

import _ from "lodash";
import { connect } from "react-redux";
import { Col, Row, Button } from "react-bootstrap";
import { injectIntl, defineMessages } from "react-intl";
import { getProjectStates, getSelectedToolStates } from "../../customMapStates";
import {
  sendProjectStage,
  deleteProjectStage,
  deleteStage,
  dispatchDeleteStage,
} from "../../../actions/projectEvaluationActions";
import {
  CardContainer,
  CardHeader,
  CardList,
  Container,
  ListContainer,
  ListContent,
  AnswerText,
  ListFooter,
  FooterProgress,
  EvaluationText,
  FooterBox,
  Bar,
  Progress,
  ListHeader,
  List,
  ListTextStatic,
  ListObjectiveTitle,
} from "../../Common/TemplateKanban/kanbanStyle";
import MaterialTextField from "../../Common/MaterialTextField";
import FormDialog from "../../Common/FormDialog";
import {
  translateProjectStage,
  sortArrayByAttribute,
} from "../../../utils/projectEvaluation";
import ConfirmationDialog from "../../Common/ConfirmationDialog";
import {
  stageModalForm,
  deleteDialogForm,
} from "../../../utils/projectConfiguration/defaults";
import {
  getProjectConfiguration,
  getStagesConfiguration,
} from "../../../utils/projectConfiguration/entity";
import { isValidSimpleForm } from "../../../utils/projectConfiguration/validation";
import ContainerHeader from "../../Layouts/ContainerHeader";

const StageConfigurationCards = ({
  intl,
  sendProjectStage,
  dispatchDeleteStage,
  deleteProjectStage,
  selectedToolID = "",
  allProjectConfigurations = [],
}) => {
  const [stageModal, setStageModal] = useState(stageModalForm);
  const [projectConfiguration, setProjectConfiguration] = useState({});
  const [deleteDialogInfo, setDeleteDialogInfo] = useState(deleteDialogForm);

  useEffect(() => {
    const newConfiguration = getProjectConfiguration(
      allProjectConfigurations,
      selectedToolID,
    );

    const equalStates = _.isEqual(newConfiguration, projectConfiguration);

    if (!equalStates) setProjectConfiguration(newConfiguration);
  }, [selectedToolID, allProjectConfigurations]);

  const resetAndToggleStageModal = () => {
    setStageModal(stageModalForm);
  };

  const addNewStage = () => {
    const { id: projectConfigurationId = "" } = projectConfiguration || {};

    const { params = {} } = stageModal || {};

    const { id = false, description = "", parentId = "", parent = null } =
      params || {};

    sendProjectStage({
      parentId,
      description,
      id: id || null,
      projectConfigurationId,
      parent,
    });

    resetAndToggleStageModal();
  };

  const handleNewStage = (parentId = "") => {
    setStageModal({
      ...stageModal,
      open: true,
      params: { ...stageModal.params, parentId },
    });
  };

  const handleEditStage = (stage = {}) => {
    setStageModal({ open: true, params: { ...stage } });
  };

  const handleDeleteStage = (stageId = "", description = "") => {
    setDeleteDialogInfo({
      openDelete: true,
      params: { stageId, description },
    });
  };

  const doDeleteStage = async () => {
    const { id: projectConfigurationId = "" } = projectConfiguration || {};
    const { params = {} } = deleteDialogInfo || {};
    const { stageId } = params || {};

    try {
      await deleteStage(stageId);
      dispatchDeleteStage(stageId, projectConfigurationId);
      resetDeleteStageInfo();
    } catch (err) {
      setDeleteDialogInfo({ openCantDelete: true });
    }
  };

  const resetDeleteStageInfo = () => {
    setDeleteDialogInfo(deleteDialogForm);
  };

  const updateStageDescription = (description = "") => {
    setStageModal({
      ...stageModal,
      params: { ...stageModal.params, description },
    });
  };

  const descriptionInUse = () => {
    let inUse = false;

    const { stages = [] } = projectConfiguration || {};
    const { params = {} } = stageModal || {};
    const { description: text = {} } = params || {};

    stages.forEach(({ description = "" }) => {
      if (description === text) inUse = true;
    });

    return inUse;
  };

  const stageValidation = () => {
    const { params = {} } = stageModal;

    if (!isValidSimpleForm(params) || descriptionInUse()) return true;

    return false;
  };

  const stageDialog = () => {
    const { params = {} } = stageModal;

    return (
      <FormDialog
        open={stageModal.open}
        title="Informações do estágio"
        onConfirm={addNewStage}
        onCancel={resetAndToggleStageModal}
        blockConfirm={stageValidation()}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialTextField
              id="title"
              label="Título"
              value={params.description}
              onChange={(e) => updateStageDescription(e.target.value)}
            />
          </div>
        </div>
      </FormDialog>
    );
  };

  const deleteStageDialog = () => {
    const { params = "" } = deleteDialogInfo || {};

    return (
      <ConfirmationDialog
        open={deleteDialogInfo.openDelete}
        title="Excluindo Subestágio"
        description={`Tem certeza que deseja excluir o estágio ${params.description} ?`}
        onConfirm={doDeleteStage}
        onCancel={resetDeleteStageInfo}
      />
    );
  };

  const cantDeleteStageDialog = () => {
    return (
      <ConfirmationDialog
        open={deleteDialogInfo.openCantDelete}
        title="Estágio em Uso!!"
        description="Remova o(s) vinculo(s) no(s) insight(s) antes de excluir!"
        hideCancel
        onConfirm={resetDeleteStageInfo}
        confirmText="Ok"
      />
    );
  };

  const displayCardHeader = (stage = {}) => {
    const { id = "", description = "", sons = [] } = stage;

    const parentName = translateProjectStage(description, intl);
    const familyLength = sons.length || 0;

    return (
      <CardHeader>
        <div>{parentName}</div>
        <div>{familyLength}</div>
        <div>
          <Button
            onClick={() => handleNewStage(id)}
            className="project-configuration-card-header"
          >
            +
          </Button>
        </div>
      </CardHeader>
    );
  };

  const displayCardContent = (stage = {}) => {
    const { sons = [] } = stage;

    return sons.map((son, index) => {
      const { id = false, description = "" } = son || {};

      const realIndex = index + 1;

      return (
        <div className="project-configuration-card-content" key={index}>
          <ListContainer key={index}>
            <ListContent>
              <div className="container-projects">
                <div
                  onClick={() => handleEditStage(son)}
                  className="container-title-projects"
                >
                  {`${realIndex} - ${description}`}
                </div>
                <div
                  onClick={() => handleDeleteStage(id, description)}
                  className="container-trash-projects"
                >
                  <i className="fas fa-trash-alt" />
                </div>
              </div>
            </ListContent>
          </ListContainer>
          <ListFooter>
            <FooterBox />
          </ListFooter>
        </div>
      );
    });
  };

  const displayCards = () => {
    const stagesConfiguration = getStagesConfiguration(projectConfiguration);

    return stagesConfiguration.map((stage, index) => {
      return (
        <CardContainer className="flex-card" key={index}>
          {displayCardHeader(stage)}
          <CardList>
            <List key={index}>{displayCardContent(stage)}</List>
          </CardList>
        </CardContainer>
      );
    });
  };

  return (
    <div style={{ marginTop: "30px" }}>
      <div style={{ display: "flex", marginTop: "30px" }}>
        <Container>{displayCards()}</Container>
        {stageDialog()}
        {deleteStageDialog()}
        {cantDeleteStageDialog()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { allProjectConfigurations = [] } = getProjectStates(state);

  const { selectedToolID } = getSelectedToolStates(state);

  return { allProjectConfigurations, selectedToolID };
};

export default connect(mapStateToProps, {
  sendProjectStage,
  deleteProjectStage,
  dispatchDeleteStage,
})(injectIntl(StageConfigurationCards));
