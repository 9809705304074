import React from "react";
import styled from "styled-components";

const StatusBar = styled.div`
  background-color: ${({ background }) => background || ""};

  @media print {
    background-color: ${({ background }) => background || ""} !important;
    -webkit-print-color-adjust: exact;
  }
`;

const HitTarget = ({
  lineHeight,
  bgColor,
  color,
  padding,
  notStarted,
  text,
  barWidth = "15px",
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "auto",
        padding,
        textAlign: "right",
        position: "relative",
        lineHeight,
        backgroundColor: bgColor,
        paddingRight: barWidth,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <b
        style={
          notStarted
            ? { fontWeight: "normal", color: "#ddd", marginRight: "5px" }
            : { marginRight: "5px" }
        }
      >
        {text}
      </b>
      <StatusBar
        background={color}
        style={{
          width: barWidth,
          height: barWidth,
        }}
      />
    </div>
  );
};

export default HitTarget;
