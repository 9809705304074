import React, { useState } from "react";
import PropTypes from "prop-types";

import { Tabs, Tab } from "react-bootstrap";

import TabGeneralConfig from "./administration/TabGeneralConfig";
import TabScales from "./administration/TabScales";
import TabPublic from "./administration/TabPublic";
import TabEvaluation from "./administration/TabEvaluation";
import TabRoleSkillRelation from "./administration/TabRoleSkillRelation";
import TabComplementaryEvaluations from "./administration/TabComplementaryEvaluations";
import SimpleLoader from "../../../../../SimpleLoader";
import { getAdministrationTabErrors } from "../../../../../../utils/skillsAssessment/validation";

const TabsContainer = ({ mainProps }) => {
  const [activeTab, setActiveTab] = useState("configTab");

  const { errors, isLoading } = mainProps;

  const renderTitle = (title, icon, hasErrors, displayErrors) => {
    return (
      <div className="tab-title">
        {hasErrors && (
          <div className="tab-error-count" title="erros encontrados">
            {displayErrors.length}
          </div>
        )}
        <i className={icon} /> {title}
      </div>
    );
  };

  const tab = (tabKey, title, icon, Content) => {
    const { errors: tabErros, hasErrors } = getAdministrationTabErrors(
      tabKey,
      errors,
    );

    return (
      <Tab
        eventKey={tabKey}
        title={renderTitle(title, icon, hasErrors, tabErros)}
      >
        {activeTab === tabKey && <Content {...mainProps} />}
      </Tab>
    );
  };

  return (
    <div className="row">
      <div
        className="col-xs-12"
        style={{ display: "flex", alignItems: "center", gap: "15px" }}
      >
        <h2 style={{ color: "#6b42a9", paddingBottom: "10px" }}>
          ADMINISTRAÇÃO DO PROGRAMA
        </h2>
        {isLoading && <SimpleLoader color="#6b42a9" />}
      </div>
      <div className="col-xs-12">
        <div className="custom-primary-tabs">
          <Tabs
            id="skillsAssessmentAdministration"
            activeKey={activeTab}
            onSelect={(key) => setActiveTab(key)}
          >
            {tab(
              "configTab",
              "Configuração Geral",
              "fas fa-cogs",
              TabGeneralConfig,
            )}
            {tab("scalesTab", "Escalas", "fas fa-balance-scale", TabScales)}
            {tab("publicTab", "Público", "fas fa-users", TabPublic)}
            {tab(
              "evaluationTab",
              "Avaliação de Pessoas",
              "fas fa-list",
              TabEvaluation,
            )}
            {tab(
              "roleSkillRelation",
              "Relação (Cargos x Competências)",
              "fas fa-poll-h",
              TabRoleSkillRelation,
            )}
            {tab(
              "complementaryEvaluations",
              "Avaliações Complementares",
              "fas fa-list",
              TabComplementaryEvaluations,
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

TabsContainer.propTypes = {
  mainProps: PropTypes.object.isRequired,
};

export default TabsContainer;
