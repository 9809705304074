import { getParticipantsWorkflowAverage } from "utils/skillsAssessment/calc";
import Avatar from "../../../../../../../Common/Avatar";
import { Tooltip } from "@material-ui/core";

export default function LeadershipCard({
  label,
  color,
  evaluation,
  percentage,
  onClick,
  toolAdministration,
}) {
  function handleClick(item) {
    onClick(item);
  }

  const { participantsAverage } = getParticipantsWorkflowAverage(
    toolAdministration,
    evaluation,
  );
  const [average] = participantsAverage;

  let workflowAverage;
  if (average) {
    ({ workflowAverage } = average);
  }

  return (
    <div className="evaluation-card" style={{ width: "30%" }}>
      <div
        className="card-header"
        style={{
          borderTop: `8px solid ${color}`,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h5>{label}</h5>
        <h6>
          Total: {evaluation?.length || 0} (
          {percentage ? `${percentage.toFixed(1)}%` : "0%"})
        </h6>
      </div>
      <div className="card-body">
        <div className="simple-lines-table col-xs-12">
          <h5 className="card-title">Profissionais</h5>
          {evaluation?.map((item) => {
            return (
              <div
                className="simple-line"
                key={item.id}
                onClick={() => handleClick(item)}
                style={{ cursor: "pointer" }}
              >
                <div
                  className="title"
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      src={item?.avatarBlobId}
                      style={{
                        width: "25px",
                        height: "25px",
                        marginRight: "5px",
                      }}
                    />
                    <span>{item?.insight?.text}</span>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title="Competência" arrow>
                      <span style={{ color: "#13a755" }}>{workflowAverage?.averageEvaluation.toFixed(1)}</span>
                    </Tooltip>
                    <Tooltip title="Gap" arrow>
                      <span style={{ marginLeft: "10px", color: "red" }}>{workflowAverage?.gap.toFixed(1)}</span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
