import { CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { defineMessages, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import Container from "../Layouts/Container";

import ToolConfigurationsView from "./ToolTabs/ToolConfigurationsView";
import ToolManagementContainer from "./ToolTabs/ToolManagementContainer";
import ToolScoreView from "./ToolTabs/ToolScoreView";
import ToolTimelineContainer from "./ToolTabs/ToolTimelineContainer";
import {
  getInitialTab,
  getToolCustomTabs,
  getToolTabsConfig,
} from "./ToolTabs/customTabs";

import InsightModal from "../Insight/InsightModal";
import SceneriesContainer from "./Cenary/SceneriesContainer";
import Permissions from "./ToolTabs/Permissions";

import {
  ACTION_PLAN_ID,
  GOALS_AND_BONUS,
} from "../../constants/tools.constants";
import { checkAccess } from "../../utils/accessLevels";
import { globalMessages } from "../../utils/global";
import utils from "../../utils/toolUtils";

import InsightProvider from "../Insight/hooks";

import { selectInsight } from "../../actions/insightActions";
import { useFilteredEntities } from "../../utils/entities/hooks";
import SelectCustomPeriodFooter from "../SelectCustomPeriodFooter";
import {
  getAccessFilteredStates,
  getInsightStates,
  getSelectedToolStates,
} from "../customMapStates";

const { getToolIntlSlug } = utils;

const messages = defineMessages({
  board: { id: "global.board" },
  dashboards: { id: "global.dashboards" },
});

const Tool = (props) => {
  const [activeTab, setActiveTab] = useState("management");
  const [filterData, setFilterData] = useState({
    sceneries: null,
    themes: null,
    questions: null,
  });
  const [filterNames, setFilterNames] = useState({});
  const history = useHistory();

  const {
    route,
    onSelectTab,
    selectedTabTools,
    onClickMenu,
    showMenu,
    intl,
    showHiddenCenarys,
    showHiddenThemes,
    showHiddenQuestions,
    showHiddenAnswers,
    showInsightModal,
    toggleInsightModal,
    toolPoints,
    isCollapsed,
    showHiddenExpectation,
    selectedToolID,
    selectedTool,
    openInsightModal,
    selectedInsight,
    selectInsight,
  } = props;

  const { entities } = useFilteredEntities({
    selectedTool,
    allSceneries: props.allSceneries,
    allThemes: props.allThemes,
    allQuestions: props.allQuestions,
    allAnswers: props.allAnswers,
  });

  const toolID = selectedTool?.tool?.id || null;
  const toolIcon = selectedTool.tool?.icon || null;
  const tabsConfig = getToolTabsConfig(toolID);
  const haveAccess = checkAccess(["MANAGER", "MODERATOR", "ADMIN", "OWNER"]);

  useEffect(() => {
    setActiveTab(
      getInitialTab({ toolID, history, selectedToolID: selectedTool.id })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolID]);

  useEffect(() => {
    const { id: selectedInsightId = false } = selectedInsight || {};

    if (openInsightModal && selectedInsightId && activeTab !== "tool") {
      toggleInsightModal(true);
      setActiveTab("tool");
    }
  }, [openInsightModal, selectedInsight, toggleInsightModal, activeTab]);

  const captureSelectsData = (data, ref) => {
    const names = {};

    if (typeof data === "string") {
      const teste = names[ref] || [];

      names[ref] = [...teste, data];

      setFilterNames({
        ...filterNames,
        names,
      });
    } else {
      setFilterData({
        ...filterData,
        [ref]: data,
      });
    }
  };

  const selectAndOpenInsight = (insightId) => {
    if (insightId) {
      selectInsight(insightId);
      toggleInsightModal(true);
    }
  };

  const appendProps = {
    toggleInsightModal,
    selectAndOpenInsight,
    tool: selectedTool,
    toolID,
    toolPoints,
    showHiddenCenarys,
    showHiddenThemes,
    showHiddenQuestions,
    showHiddenAnswers,
    showHiddenExpectation,
    activeTab,
    selectedToolID,
    entities,
    ...entities,
  };

  const toolCustomTabs = getToolCustomTabs(toolID, appendProps);

  const showPermissionTab = toolID !== GOALS_AND_BONUS && haveAccess;

  const permissionsTab = showPermissionTab
    ? {
        id: "permissions",
        label: intl.formatMessage(globalMessages.permissions),
        component: <Permissions />,
      }
    : null;

  const configurationsTab = haveAccess
    ? {
        id: "configurations",
        label: intl.formatMessage(globalMessages.configurations),
        component:
          activeTab === "configurations" ? (
            <ToolConfigurationsView
              open
              showHiddenCenarys={showHiddenCenarys}
              showHiddenThemes={showHiddenThemes}
              showHiddenQuestions={showHiddenQuestions}
              showHiddenAnswers={showHiddenAnswers}
              showHiddenExpectation={showHiddenExpectation}
              tool={selectedTool}
            />
          ) : null,
      }
    : null;

  let defaultTabs = [
    {
      id: "management",
      label: intl.formatMessage(messages.dashboards),
      component:
        activeTab === "management" ? (
          <ToolManagementContainer
            open
            entities={entities}
            showHiddenExpectation={showHiddenExpectation}
            toolID={toolID}
            {...entities}
            selectAndOpenInsight={selectAndOpenInsight}
            filterData={filterData}
            setFilterData={setFilterData}
            captureSelectsData={captureSelectsData}
          />
        ) : null,
      hide: tabsConfig.management === false,
    },
    {
      id: "tool",
      label: intl.formatMessage(messages.board),
      component:
        activeTab === "tool" ? (
          <SceneriesContainer
            open
            {...entities}
            toggleInsightModal={toggleInsightModal}
            showHiddenCenarys={showHiddenCenarys}
            showHiddenThemes={showHiddenThemes}
            showHiddenQuestions={showHiddenQuestions}
            showHiddenAnswers={showHiddenAnswers}
            showHiddenExpectation={showHiddenExpectation}
          />
        ) : null,
      hide: tabsConfig.tool === false,
    },
    ...toolCustomTabs,
    {
      id: "score",
      label: intl.formatMessage(globalMessages.score),
      component:
        activeTab === "score" ? (
          <ToolScoreView
            open
            {...entities}
            tool={selectedTool}
            toolPoints={toolPoints}
            selectAndOpenInsight={selectAndOpenInsight}
            filterData={filterData}
            captureSelectsData={captureSelectsData}
          />
        ) : null,
      hide: tabsConfig.score === false,
    },
    {
      id: "timeline",
      label: intl.formatMessage(globalMessages.timeline),
      component:
        activeTab === "timeline" ? (
          <ToolTimelineContainer open {...entities} />
        ) : null,
      hide: tabsConfig.timeline === false,
    },
    permissionsTab,
    configurationsTab,
  ];

  defaultTabs = defaultTabs.filter((tab) => {
    return tab && !tab?.hide;
  });

  useEffect(() => {
    const onlyTabIds = [...defaultTabs.map(({ id }) => id)];

    if (onlyTabIds.indexOf(activeTab) === -1) {
      setActiveTab("tool");
    }
  }, [defaultTabs, activeTab]);

  const displayTabs = (tabs, hiddenTabs = false) => {
    if (tabs && tabs.length > 0) {
      return tabs.map(({ id, label }) => (
        <React.Fragment key={id}>
          {!hiddenTabs && (
            <li
              onClick={() => setActiveTab(id)}
              className={activeTab === id ? "active" : ""}
              style={{ textAlign: "center" }}
            >
              <a
                id={id}
                href={`#${id}`}
                data-toggle="tab"
                aria-expanded={activeTab === id ? "true" : "false"}
                onClick={() => onSelectTab(label)}
              >
                {label}
              </a>
            </li>
          )}
        </React.Fragment>
      ));
    }

    return null;
  };

  const displayTabContents = (tabs) => {
    if (tabs && tabs.length > 0) {
      return tabs.map(({ id, component }, index) => {
        const isCurrentTabActive = activeTab === id;
        const tabPaneClass = isCurrentTabActive
          ? "tab-pane active"
          : "tab-pane";

        return (
          isCurrentTabActive && (
            <div className={tabPaneClass} id={id} key={index}>
              {component}
            </div>
          )
        );
      });
    }

    return null;
  };

  const navBarClasses = isCollapsed
    ? "nav-tabs-custom nav-tabs-custom-collapsed"
    : "nav-tabs-custom";

  const InsightModalFunc = () => {
    if (showInsightModal) {
      return (
        <InsightProvider>
          <InsightModal
            show={showInsightModal}
            onHide={() => toggleInsightModal(false)}
          />
        </InsightProvider>
      );
    }
    return null;
  };

  const showContent = toolID;

  return (
    <>
      {InsightModalFunc()}
      <Container hideHeader route={route}>
        {!showContent && (
          <div style={{ textAlign: "center", padding: "100px" }}>
            <CircularProgress />
          </div>
        )}
        {showContent && (
          <div className="tool-container">
            <div className="tool-header hidden-print">
              <div className={`${navBarClasses} full-height tools`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h3 className="tool-title">
                    <i className={toolIcon} />
                    {"  "}
                    {intl.formatMessage({ id: getToolIntlSlug(toolID) })}
                  </h3>
                  <SelectCustomPeriodFooter />
                </div>
                <ul className="nav nav-tabs pull-right ui-sortable-handle toolTabs">
                  {displayTabs(
                    defaultTabs.reverse(),
                    toolID === ACTION_PLAN_ID
                  )}
                </ul>
                <div className="container-fluid menu_mob_tools">
                  <Row>
                    <Col md={12}>
                      <h2>{selectedTabTools}</h2>
                    </Col>
                    <Col md={1} mdOffset={6}>
                      <button
                        className="btn btn-default btn-sm"
                        onClick={onClickMenu}
                        type="button"
                      >
                        <i className="fas fa-bars" />
                        <ul
                          className="nav_menu_mob_tools"
                          style={
                            showMenu
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          {displayTabs(defaultTabs)}
                        </ul>
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <h3 className="tool-title-pdf">
              <i className={toolIcon} />{" "}
              {intl.formatMessage({ id: getToolIntlSlug(toolID) })}
            </h3>
            <div className="tab-content">{displayTabContents(defaultTabs)}</div>
          </div>
        )}
      </Container>
    </>
  );
};

Tool.propTypes = {
  route: PropTypes.string.isRequired,
  showHiddenCenarys: PropTypes.bool.isRequired,
  showHiddenThemes: PropTypes.bool.isRequired,
  showHiddenQuestions: PropTypes.bool.isRequired,
  showHiddenAnswers: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { toolsConfigurations } = state;
  const { openInsightModal = false, selectedInsight = null } =
    getInsightStates(state);
  const { selectedTool = {}, selectedToolID } = getSelectedToolStates(state);
  const isGoalsAndBonus = selectedTool?.tool?.id === GOALS_AND_BONUS;

  const {
    filteredSceneries: allSceneries = [],
    filteredThemes: allThemes = {},
    filteredQuestions: allQuestions = {},
    filteredAnswers: allAnswers = {},
  } = getAccessFilteredStates(
    state,
    {
      sceneries: toolsConfigurations.showHiddenCenarys || false,
      themes: toolsConfigurations.showHiddenThemes || false,
      questions: toolsConfigurations.showHiddenQuestions || false,
      answers: toolsConfigurations.showHiddenAnswers || false,
    },
    !isGoalsAndBonus
  );

  return {
    openInsightModal,
    selectedInsight,
    selectedToolID,
    selectedTool,
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
    ...toolsConfigurations,
  };
};

export default React.memo(
  connect(mapStateToProps, {
    selectInsight,
  })(injectIntl(Tool))
);
