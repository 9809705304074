import React, { useState } from "react";
import { defineMessages } from "react-intl";
import AverageBadge from "../../../../../Common/AverageBadge";
import { getAllCriteriaTranslation } from "../../../../../../utils/cpAnalysis/display";

const messages = defineMessages({
  impact: { id: "global.impact" },
  importance: { id: "global.importance" },
  reliability: { id: "global.reliability" },
  global_noData: { id: "global.noData" },
});

const SingleTable = ({
  title = "",
  headers = {},
  data = [],
  intl,
  colorClass = "",
  result = "",
  selectAndOpenInsight,
}) => {
  const [expanded, setExpanded] = useState(true);

  const handleEmpty = (data = []) => {
    if (data.length > 0) return;

    return (
      <div style={{ padding: "15px 0px 0px 0px", textAlign: "center" }}>
        {intl.formatMessage(messages.global_noData)}
      </div>
    );
  };

  const renderHeaders = () => {
    let realHeaders = headers || [];

    return realHeaders.map((header, index) => {
      const { type = "" } = header;

      const title = getAllCriteriaTranslation(type).toUpperCase();

      return (
        <div key={index} className="header-cell" title={title}>
          {title}
        </div>
      );
    });
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const generateLine = (evaluation, index) => {
    const { title = "", evaluations = [], insightId = "", analysisGroup = {} } =
      evaluation || {};

    const { title: analysisGroupTitle = false } = analysisGroup || {};

    const getTotalPoints = () => {
      let totalPoints = 1;

      evaluations.forEach((evaluation) => {
        const { value = "" } = evaluation || {};

        totalPoints *= value;
      });

      return totalPoints;
    };

    const totalPercentageScore = getTotalPoints() / 1.25;

    const getEvaluationByConfigId = (id = "") => {
      return (
        evaluations.filter(
          ({ evaluationConfigId = 0 }) => id === evaluationConfigId,
        )[0] || {}
      );
    };

    const concatenedTitle = (
      <>
        <strong>{analysisGroupTitle}</strong> - {title}
      </>
    );

    return (
      <div
        className="row"
        style={{ margin: "0 auto", display: "flex", alignItems: "center" }}
        key={index}
      >
        <div
          style={{
            width: "auto",
            flex: 1,
            padding: "15px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontWeight: "500",
            cursor: "pointer",
          }}
          title={title}
          onClick={() => selectAndOpenInsight(insightId)}
        >
          {analysisGroupTitle ? <div>{concatenedTitle}</div> : title}
        </div>
        {headers.map((header, index) => {
          const { id: evaluationConfigId = 0 } = header || {};

          const evaluation = getEvaluationByConfigId(evaluationConfigId);

          const { value = 0 } = evaluation;

          return (
            <div key={index} style={{ width: "100px", padding: "0px 10px" }}>
              <AverageBadge
                fontSize="18px"
                padding="10px"
                lineHeight="12px"
                fontWeight="bold"
                maxValue={5}
                displayMaxValue
                hideColor
                borderedBadge
                average={value}
              />
            </div>
          );
        })}
        <div
          style={{ width: "130px", padding: "0px 10px", textAlign: "center" }}
        >
          <AverageBadge
            fontSize="18px"
            padding="10px"
            lineHeight="12px"
            fontWeight="bold"
            maxValue={125}
            displayMaxValue
            hideColor
            borderedBadge
            average={getTotalPoints()}
          />
        </div>
        <div
          style={{ width: "150px", display: "flex", justifyContent: "center" }}
        >
          <div
            className="progress-management progress"
            style={{
              width: "100px",
              height: "20px",
              textAlign: "center",
            }}
          >
            <div
              role="progressbar"
              className={"progress-bar " + colorClass}
              style={{ width: totalPercentageScore + "%" }}
            />
          </div>
        </div>
      </div>
    );
  };

  const insightsTotal = data.length;

  return (
    <div className="evaluation-card">
      <div
        className="card-header card-header-hover row singleTable"
        onClick={toggleExpanded}
      >
        <div className="card-title singleTable-title-wrapper" title={title}>
          <h4
            className="table-title"
            style={{
              color: "#444",
              width: "400px",
              textAlign: "left",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </h4>
          <h4 style={{ color: "#444" }}>{insightsTotal}</h4>
        </div>
      </div>
      <div
        className="card-body singleTable-body"
        style={{ display: expanded ? "block" : "none" }}
      >
        <div className="header">
          <div className="first-header-cell" />
          {renderHeaders()}
          <div style={{ width: "100px", fontSize: "18px" }}>
            <strong>{result}</strong>
          </div>
          <div style={{ width: "180px" }}></div>
        </div>
        <div className="fixedScroll SingleTableFixedScroll">
          {data.map((evaluation, index) => generateLine(evaluation, index))}
          {handleEmpty(data)}
        </div>
      </div>
    </div>
  );
};

export default SingleTable;
