import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { createSceneriePlan } from "../../../../actions/okrActions";
import { addNewCycleGroupToCenary } from "../../../../actions/tempActions";
import ThemeGroupSelectDialog from "../../../Common/Okr/forms/ThemeGroupSelectDialog";
import { getSelectedToolStates, getKpiStates } from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";
import { configPlansBody } from "../../../../utils/okr/entity";
import { formatDateCycles } from "../../../../utils/okr/valid";
import { getObjectInfoById } from "../../../../utils/ArrayUtils";

const { getValidFunc, getDefaultAddTableLine, getDefaultRemoveColumn } = utils;

const Okr = (props) => {
  const [addedSceneries, setAddedSceneries] = useState([]);

  const {
    type,
    clearViewCallback,
    createSceneriePlan,
    selectedTool = {},
    userProfile,
    params = {},
    allSceneries = [],
    allThemes,
    okrPlans,
    cycleBase,
    okrConfigurationId,
    addNewCycleGroupToCenary,
  } = props;

  const { themeScenerie = {} } = params;

  useEffect(() => {
    const toolSceneries = [];

    if (allSceneries && allSceneries.length) {
      allSceneries.forEach((scenerieInfo) => {
        if (
          scenerieInfo.selectedTool.id === selectedTool.id &&
          scenerieInfo.okrConfigurationPlanId
        )
          toolSceneries.push(scenerieInfo.okrConfigurationPlanId);
      });
    }

    if (!_.isEqual(toolSceneries, addedSceneries))
      setAddedSceneries(toolSceneries);
  }, [addedSceneries, allSceneries, selectedTool.id]);

  const cancelCallback = getValidFunc(params.cancelCallback);

  function addPlanToBoard(updatedPlan, callback) {
    const newScenerieOrder = allSceneries.length || 0;

    createSceneriePlan(
      selectedTool,
      updatedPlan,
      userProfile,
      newScenerieOrder,
      callback,
    );
  }

  const finishSaveCyclesAndThemes = (
    newUpdatedPlan,
    relatedScenerie,
    doUpdatePlan = true,
  ) => {
    const scenerieThemes = allThemes[relatedScenerie.id];

    addNewCycleGroupToCenary(
      newUpdatedPlan,
      relatedScenerie,
      scenerieThemes,
      doUpdatePlan,
    );
  };

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
    cancelCallback();
  };

  async function createOrUpdatePlan(newSavePlan, cycles) {
    const updatedCycles = formatDateCycles(cycles);

    const newPlan = {
      ...newSavePlan,
      cycleGroups: updatedCycles,
    };

    const newUpdatedPlan = configPlansBody(newPlan);

    if (newUpdatedPlan.id !== null) {
      const relatedScenerie = getObjectInfoById(
        newUpdatedPlan.id,
        allSceneries,
        "okrConfigurationPlanId",
      );

      if (relatedScenerie.id) {
        finishSaveCyclesAndThemes(newUpdatedPlan, relatedScenerie, true);
      } else {
        addPlanToBoard(newUpdatedPlan, (savedScenerieData) =>
          finishSaveCyclesAndThemes(
            newUpdatedPlan,
            savedScenerieData.data,
            true,
          ),
        );
      }
    }
  }

  const appendProps = {
    getDefaultAddTableLine,
    getDefaultRemoveColumn,
  };

  const selectedPlan = getObjectInfoById(
    themeScenerie.okrConfigurationPlanId,
    okrPlans,
    "id",
  );

  return (
    <ThemeGroupSelectDialog
      open
      createOrUpdatePlan={createOrUpdatePlan}
      afterCancel={clearDialogControll}
      okrPlans={okrPlans}
      cycleBase={cycleBase}
      okrConfigurationId={okrConfigurationId}
      allThemes={allThemes}
      allSceneries={allSceneries}
      addedSceneries={addedSceneries}
      selectedPlan={selectedPlan}
      {...appendProps}
    />
  );
};

const mapStateToProps = (state) => {
  const { selectedTool, allSceneries, allThemes } = getSelectedToolStates(
    state,
  );
  const { isLoading } = getKpiStates(state);

  const { okr, user } = state;

  return {
    userProfile: user,
    selectedTool,
    isLoading,
    allSceneries,
    allThemes,
    okrConfigurationId: okr.id,
    okrPlans: okr.okrPlans,
    cycleBase: okr.cycleBase,
  };
};

export default connect(mapStateToProps, {
  createSceneriePlan,
  addNewCycleGroupToCenary,
})(Okr);
