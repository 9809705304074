/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
import {
  Bar,
  Cell,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getObjectInfoById } from "../../../../../utils/ArrayUtils";
import {
  getOrderedTargets,
  getPeriodTargetsByDistribution,
} from "../../../../../utils/kpis/calc2";
import {
  displayTransformValue,
  getPeriodDivisionTitle,
  getPeriodFullInfo,
} from "../../../../../utils/kpis/display";

const CustomTooltip = ({
  active,
  payload,
  label,
  gateLabel,
  appropriatedLabel,
  exceededLabel,
  measurementUnity,
}) => {
  if (active && payload && payload.length) {
    const { value, payload: data } = payload[0];
    const { gate, appropriated, exceeded } = data;

    return (
      <div className="goal-period-tooltip">
        <div className="label">
          {`${label}: ${displayTransformValue(value, measurementUnity, 2)}`}
        </div>
        <div>
          {`${gateLabel}: ${displayTransformValue(gate, measurementUnity, 2)}`}
        </div>
        <div>
          {`${appropriatedLabel}: ${displayTransformValue(
            appropriated,
            measurementUnity,
            2
          )}`}
        </div>
        <div>
          {`${exceededLabel}: ${displayTransformValue(
            exceeded,
            measurementUnity,
            2
          )}`}
        </div>
      </div>
    );
  }

  return null;
};

const Chart = (props) => {
  const {
    detailed = false,
    animation = true,
    showLines,
    goalInfo,
    actualTargetValues,
    targetOptions,
  } = props;
  const { target, measurementUnity } = goalInfo;

  const gateInfo = getObjectInfoById("GATE", targetOptions, "value");
  const appropriatedInfo = getObjectInfoById(
    "APPROPRIATED",
    targetOptions,
    "value"
  );
  const exceededInfo = getObjectInfoById("EXCEEDED", targetOptions, "value");

  const isYearlyFrequency = goalInfo?.frequency === "YEARLY";

  const buildData = () => {
    const data = [];

    const { lastPeriod: lastPeriodValues } = getOrderedTargets(goalInfo);

    actualTargetValues.forEach(
      ({ value: actualPeriodValue, index }, fakeIndex) => {
        const { gate, appropriated, exceeded } = getPeriodTargetsByDistribution(
          index,
          goalInfo
        );

        data.push({
          index,
          name: index,
          period: getPeriodDivisionTitle(index, fakeIndex, goalInfo),
          periodValue: actualPeriodValue !== "" ? actualPeriodValue : 0,
          gate,
          appropriated,
          exceeded,
          lastPeriod: lastPeriodValues[fakeIndex].value,
        });
      }
    );

    return data;
  };

  const sizes = detailed
    ? { width: 800, height: 250, margin: "0 auto" }
    : { width: 160, height: 60 };

  const appendProps = { ...sizes };

  const data = buildData();

  if (!target) {
    console.log("Target is undefined. GoalInfo=", goalInfo);
  }

  const invertedPole = target?.gate > target?.exceeded;

  const orderedByGate = [...data].sort((a, b) => {
    return a.gate > b.gate ? 1 : -1;
  });
  const orderedByExceeded = [...orderedByGate].reverse();

  const greater = orderedByExceeded[0].periodValue;
  const smaller = orderedByGate[0].periodValue;

  const minValue = !invertedPole ? smaller : greater;
  const maxValue = !invertedPole ? greater : smaller - 1;

  return (
    <div style={sizes}>
      <ResponsiveContainer>
        <ComposedChart
          {...appendProps}
          data={data}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5,
          }}
        >
          <YAxis
            hide={!detailed}
            allowDataOverflow={false}
            domain={isYearlyFrequency ? [0, maxValue] : [minValue, maxValue]}
          />
          <XAxis
            dataKey="period"
            hide={!detailed}
            interval="preserveStartEnd"
          />
          {detailed && (
            <Tooltip
              content={
                <CustomTooltip
                  gateLabel={gateInfo.label}
                  appropriatedLabel={appropriatedInfo.label}
                  exceededLabel={exceededInfo.label}
                  measurementUnity={measurementUnity}
                />
              }
            />
          )}
          <Bar
            dataKey="periodValue"
            fill="#9c99d6"
            isAnimationActive={animation}
            {...sizes}
            label={
              detailed
                ? {
                    fill: "#000",
                    fontSize: 12,
                    position: "insideBottom",
                    fontWeight: "500",
                  }
                : false
            }
          >
            {detailed &&
              data.map((dataEntry) => {
                const { periodValue } = dataEntry;
                const { isTraveledAndValid, hitTargetInfo = {} } =
                  getPeriodFullInfo(dataEntry.index, periodValue, goalInfo);

                return (
                  <Cell
                    key={`cell-${dataEntry.index}`}
                    fill={isTraveledAndValid ? hitTargetInfo.color : "#ddd"}
                  />
                );
              })}
          </Bar>
          {showLines?.EXCEEDED && isYearlyFrequency && (
            <ReferenceLine
              y={data[0].exceeded || 0}
              stroke={exceededInfo.color}
              strokeDasharray="5 5"
              strokeWidth="2"
            />
          )}
          {showLines?.APPROPRIATED && isYearlyFrequency && (
            <ReferenceLine
              y={data[0].appropriated || 0}
              stroke={appropriatedInfo.color}
              strokeDasharray="5 5"
              strokeWidth="2"
            />
          )}
          {showLines?.GATE && isYearlyFrequency && (
            <ReferenceLine
              y={data[0].gate || 0}
              stroke={gateInfo.color}
              strokeDasharray="5 5"
              strokeWidth="2"
            />
          )}
          {showLines?.GATE && (
            <Line
              type="monotone"
              dataKey="gate"
              stroke={gateInfo.color}
              strokeDasharray="5 5"
              strokeWidth="2"
              dot={false}
            />
          )}
          {showLines?.APPROPRIATED && (
            <Line
              type="monotone"
              dataKey="appropriated"
              stroke={appropriatedInfo.color}
              strokeDasharray="5 5"
              strokeWidth="2"
              dot={false}
            />
          )}
          {showLines?.EXCEEDED && (
            <Line
              type="monotone"
              dataKey="exceeded"
              stroke={exceededInfo.color}
              strokeDasharray="5 5"
              strokeWidth="2"
              dot={false}
            />
          )}
          {showLines?.LAST_PERIOD && (
            <Line
              type="monotone"
              dataKey="lastPeriod"
              strokeWidth="2"
              stroke="rgba(176,158,98, 1)"
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
