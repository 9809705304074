import React from "react";
import _ from "lodash";
import moment from "moment";
import { getAreaOptions, getRoleOptions } from "../constants/peopleEvaluation";
import { getMemberInfoById } from "./memberUtils";

export const peopleToolUsers = (evaluation = {}) => {
  const addLabeledUser = (id = null, role = "") => {
    if (id) labeledUsers = [...labeledUsers, { id, role }];
  };

  let labeledUsers = [];

  const { professionalProfile = {} } = evaluation || {};

  const { professional = {} } = professionalProfile || {};

  const { member = null } = professional || {};

  if (member) addLabeledUser(member.id, "COLLABORATOR");

  return labeledUsers;
};

const sortHistoryByDate = (history, objSlug) => {
  if (history) {
    history.sort((a, b) => {
      const dA = new Date(a[objSlug]);
      const dB = new Date(b[objSlug]);

      return dA > dB ? -1 : 1;
    });

    return history;
  }

  return [];
};

const renderPeopleBPColumnLabel = (intl, globalMessages) => {
  return (
    <>
      <th style={{ textAlign: "left" }}>
        {intl.formatMessage(globalMessages.unit)}
      </th>
      <th style={{ textAlign: "left" }}>
        {intl.formatMessage(globalMessages.area)}
      </th>
      <th style={{ textAlign: "left" }}>
        {intl.formatMessage(globalMessages.subArea)}
      </th>
      <th style={{ textAlign: "left" }}>
        {intl.formatMessage(globalMessages.professional)}
      </th>
    </>
  );
};

const getInsightPeopleEvaluation = (insightID, allPeopleEvaluation) => {
  const allEvaluationCopy = _.cloneDeep(allPeopleEvaluation);
  let evaluation = null;

  if (!_.isEmpty(allEvaluationCopy)) {
    allEvaluationCopy.forEach((evalInfo) => {
      if (parseInt(evalInfo.insightId, 10) === parseInt(insightID, 10))
        evaluation = evalInfo;
    });
  }

  return evaluation;
};

const getEvaluationsById = (insightID, allPeopleEvaluation) => {
  const evaluationInfo = getInsightPeopleEvaluation(
    insightID,
    allPeopleEvaluation,
  );
  const evaluationTarget =
    evaluationInfo &&
    evaluationInfo.peopleEvaluation &&
    evaluationInfo.peopleEvaluation.evaluationTarget
      ? evaluationInfo.peopleEvaluation.evaluationTarget
      : {};

  return evaluationTarget &&
    evaluationTarget.lastEvaluations &&
    evaluationTarget.lastEvaluations.average
    ? evaluationTarget.lastEvaluations.average
    : {};
};

const getPeopleAverageList = (insights, allPeopleEvaluation) => {
  let averageList = [];

  if (insights && insights.length) {
    insights.forEach(({ id }) => {
      averageList = [
        ...averageList,
        {
          insightId: id,
          average: getEvaluationsById(id, allPeopleEvaluation),
        },
      ];
    });
  }

  return averageList;
};

const buildDataBody = (
  peopleEvaluation,
  defaultStringValue = "",
  defaulttArrayValue = [],
) => {
  // FIRST LEVEL OBJECT
  let stateProfessionalProfile = peopleEvaluation.professionalProfile || {};
  let stateSalaryTrajectory = peopleEvaluation.salaryTrajectory || {};
  let stateEvaluationTarget = peopleEvaluation.evaluationTarget || {};

  // SECOND LEVEL OBJECT
  stateProfessionalProfile.id =
    stateProfessionalProfile.id || defaultStringValue;
  stateProfessionalProfile.area = stateProfessionalProfile.area || {};
  stateProfessionalProfile.role = stateProfessionalProfile.role || {};
  stateProfessionalProfile.education = stateProfessionalProfile.education || {};

  stateEvaluationTarget.lastEvaluations =
    stateEvaluationTarget.lastEvaluations || {};
  stateEvaluationTarget.carrearPath = stateEvaluationTarget.carrearPath || {};
  stateEvaluationTarget.salaryEvaluation =
    stateEvaluationTarget.salaryEvaluation || {};
  stateEvaluationTarget.roleEvaluation =
    stateEvaluationTarget.roleEvaluation || {};

  // THIRD LEVEL OBJECT (RELATION CONFIG)
  stateProfessionalProfile.id = stateProfessionalProfile.id || null;

  stateSalaryTrajectory.id = stateSalaryTrajectory.id || null;

  stateEvaluationTarget.id = stateEvaluationTarget.id || null;
  stateEvaluationTarget.lastEvaluations.id =
    stateEvaluationTarget.lastEvaluations.id || null;
  stateEvaluationTarget.lastEvaluations.average =
    stateEvaluationTarget.lastEvaluations.average || {};
  stateEvaluationTarget.lastEvaluations.interest =
    stateEvaluationTarget.lastEvaluations.interest || {};
  stateEvaluationTarget.lastEvaluations.leading =
    stateEvaluationTarget.lastEvaluations.leading || {};
  stateEvaluationTarget.lastEvaluations.performance =
    stateEvaluationTarget.lastEvaluations.performance || {};

  // DEEPER LEVELS
  stateProfessionalProfile = {
    ...stateProfessionalProfile,
    professional: stateProfessionalProfile.professional || defaultStringValue,
    name: stateProfessionalProfile.name || defaultStringValue,
    birthDate: stateProfessionalProfile.birthDate || defaultStringValue,
    area: {
      id: stateProfessionalProfile.area.id || null,
      value: stateProfessionalProfile.area.value || defaultStringValue,
      details: stateProfessionalProfile.area.details || defaultStringValue,
    },
    gender: stateProfessionalProfile.gender || defaultStringValue,
    role: {
      id: stateProfessionalProfile.role.id || null,
      value: stateProfessionalProfile.role.value || defaultStringValue,
      details: stateProfessionalProfile.role.details || defaultStringValue,
    },
    education: {
      id: stateProfessionalProfile.education.id || null,
      level: stateProfessionalProfile.education.level || defaultStringValue,
      details: stateProfessionalProfile.education.details || defaultStringValue,
    },
    entryDate: stateProfessionalProfile.entryDate || defaultStringValue,
    fluentLanguages:
      stateProfessionalProfile.fluentLanguages || defaulttArrayValue,
  };

  delete stateProfessionalProfile.otherLanguages;

  stateSalaryTrajectory = {
    ...stateSalaryTrajectory,
    remuneration: stateSalaryTrajectory.remuneration || defaultStringValue,
    marketEvaluation:
      stateSalaryTrajectory.marketEvaluation || defaultStringValue,
    averageMarketEvaluation:
      stateSalaryTrajectory.averageMarketEvaluation || defaultStringValue,
    remunerationHistories:
      stateSalaryTrajectory.remunerationHistories || defaulttArrayValue,
    professionalAscentions:
      stateSalaryTrajectory.professionalAscentions || defaulttArrayValue,
  };

  if (
    stateSalaryTrajectory.remunerationHistories &&
    stateSalaryTrajectory.remunerationHistories.length > 0
  ) {
    const newRemunerationHistory = sortHistoryByDate(
      stateSalaryTrajectory.remunerationHistories,
      "date",
    );

    stateSalaryTrajectory.remunerationHistories = newRemunerationHistory;
  }

  if (
    stateSalaryTrajectory.professionalAscentions &&
    stateSalaryTrajectory.professionalAscentions.length > 0
  ) {
    const newProfessionalAscention = sortHistoryByDate(
      stateSalaryTrajectory.professionalAscentions,
      "fromDate",
    );

    newProfessionalAscention.forEach((info, index) => {
      newProfessionalAscention[index].fromDate =
        info.fromDate || defaultStringValue;
      newProfessionalAscention[index].toDate =
        info.toDate || defaultStringValue;

      newProfessionalAscention[index].fromRole =
        info.fromRole || defaultStringValue;
      newProfessionalAscention[index].toRole =
        info.toRole || defaultStringValue;

      newProfessionalAscention[index].fromArea =
        info.fromArea || defaultStringValue;
      newProfessionalAscention[index].toArea =
        info.toArea || defaultStringValue;

      newProfessionalAscention[index].professionalLeader =
        info.professionalLeader || defaultStringValue;

      newProfessionalAscention[index].fromRoleText =
        info.fromRoleText || defaultStringValue;
      newProfessionalAscention[index].toRoleText =
        info.toRoleText || defaultStringValue;

      newProfessionalAscention[index].fromAreaText =
        info.fromAreaText || defaultStringValue;
      newProfessionalAscention[index].toAreaText =
        info.toAreaText || defaultStringValue;
    });

    stateSalaryTrajectory.professionalAscentions = newProfessionalAscention;
  }

  stateEvaluationTarget = {
    ...stateEvaluationTarget,
    lastEvaluations: {
      id: stateEvaluationTarget.lastEvaluations.id || null,
      average: {
        id: stateEvaluationTarget.lastEvaluations.average.id || null,
        current:
          stateEvaluationTarget.lastEvaluations.average.current ||
          defaultStringValue,
        previous:
          stateEvaluationTarget.lastEvaluations.average.previous ||
          defaultStringValue,
        details:
          stateEvaluationTarget.lastEvaluations.average.details ||
          defaultStringValue,
      },
      performance: {
        id: stateEvaluationTarget.lastEvaluations.performance.id || null,
        current:
          stateEvaluationTarget.lastEvaluations.performance.current ||
          defaultStringValue,
        previous:
          stateEvaluationTarget.lastEvaluations.performance.previous ||
          defaultStringValue,
        details:
          stateEvaluationTarget.lastEvaluations.performance.details ||
          defaultStringValue,
      },
      leading: {
        id: stateEvaluationTarget.lastEvaluations.leading.id || null,
        current:
          stateEvaluationTarget.lastEvaluations.leading.current ||
          defaultStringValue,
        previous:
          stateEvaluationTarget.lastEvaluations.leading.previous ||
          defaultStringValue,
        details:
          stateEvaluationTarget.lastEvaluations.leading.details ||
          defaultStringValue,
      },
      interest: {
        id: stateEvaluationTarget.lastEvaluations.interest.id || null,
        current:
          stateEvaluationTarget.lastEvaluations.interest.current ||
          defaultStringValue,
        previous:
          stateEvaluationTarget.lastEvaluations.interest.previous ||
          defaultStringValue,
        details:
          stateEvaluationTarget.lastEvaluations.interest.details ||
          defaultStringValue,
      },
    },
    carrearPath: {
      id: stateEvaluationTarget.carrearPath.id || null,
      mainTrail:
        stateEvaluationTarget.carrearPath.mainTrail || defaultStringValue,
      secondTrail:
        stateEvaluationTarget.carrearPath.secondTrail || defaultStringValue,
      thirdTrail:
        stateEvaluationTarget.carrearPath.thirdTrail || defaultStringValue,
      fourthTrail:
        stateEvaluationTarget.carrearPath.fourthTrail || defaultStringValue,
      fifthTrail:
        stateEvaluationTarget.carrearPath.fifthTrail || defaultStringValue,
    },
    salaryEvaluation: {
      id: stateEvaluationTarget.salaryEvaluation.id || null,
      value: stateEvaluationTarget.salaryEvaluation.value || defaultStringValue,
      details:
        stateEvaluationTarget.salaryEvaluation.details || defaultStringValue,
    },
    roleEvaluation: {
      id: stateEvaluationTarget.roleEvaluation.id || null,
      value: stateEvaluationTarget.roleEvaluation.value || defaultStringValue,
      details:
        stateEvaluationTarget.roleEvaluation.details || defaultStringValue,
    },
  };

  return {
    id: peopleEvaluation.id || null,
    professionalProfile: stateProfessionalProfile,
    salaryTrajectory: stateSalaryTrajectory,
    evaluationTarget: stateEvaluationTarget,
  };
};

const removeAllBodyIds = ({
  evaluationTarget,
  professionalProfile,
  salaryTrajectory,
}) => {
  if (evaluationTarget) {
    delete evaluationTarget.id;

    if (evaluationTarget.carrearPath) {
      delete evaluationTarget.carrearPath.id;
      delete evaluationTarget.carrearPath.mainTrail.id;
      delete evaluationTarget.carrearPath.secondTrail.id;
      delete evaluationTarget.carrearPath.thirdTrail.id;
      delete evaluationTarget.carrearPath.fourthTrail.id;
      delete evaluationTarget.carrearPath.fifthTrail.id;
    }

    if (evaluationTarget.lastEvaluations) {
      delete evaluationTarget.lastEvaluations.id;

      if (evaluationTarget.lastEvaluations.average)
        delete evaluationTarget.lastEvaluations.average.id;
      if (evaluationTarget.lastEvaluations.interest)
        delete evaluationTarget.lastEvaluations.interest.id;
      if (evaluationTarget.lastEvaluations.leading)
        delete evaluationTarget.lastEvaluations.leading.id;
      if (evaluationTarget.lastEvaluations.performance)
        delete evaluationTarget.lastEvaluations.performance.id;
    }

    if (evaluationTarget.roleEvaluation)
      delete evaluationTarget.roleEvaluation.id;
    if (evaluationTarget.salaryEvaluation)
      delete evaluationTarget.salaryEvaluation.id;
  }

  if (professionalProfile) {
    delete professionalProfile.id;

    if (professionalProfile.area) delete professionalProfile.area.id;
    if (professionalProfile.education) delete professionalProfile.education.id;
    if (professionalProfile.role) delete professionalProfile.role.id;
  }

  if (salaryTrajectory) {
    delete salaryTrajectory.id;

    if (salaryTrajectory.professionalAscentions)
      delete salaryTrajectory.professionalAscentions.id;
    if (salaryTrajectory.remunerationHistories)
      delete salaryTrajectory.remunerationHistories.id;
  }

  return { evaluationTarget, professionalProfile, salaryTrajectory };
};

const buildFormEvaluationBody = (peopleEvaluation) => {
  const sendEvaluation = _.cloneDeep(peopleEvaluation);
  const body = buildDataBody(sendEvaluation, "", []);

  return body;
};

const buildRestEvaluationBody = (peopleEvaluation) => {
  const sendEvaluation = _.cloneDeep(peopleEvaluation);
  const body = buildDataBody(sendEvaluation, null, null);

  return body;
};

const getShortReadinessValues = (evaluations) => {
  let READY = 0;
  let DEVELOP = 0;
  let KEEP = 0;
  let REASSESS = 0;

  evaluations.forEach((value) => {
    if (value === "READY" || value === "SHORT_TERM_READY") READY++;
    if (value === "MEDIUM_TERM_READY" || value === "DEVELOP") DEVELOP++;
    if (value === "KEEP") KEEP++;
    if (value === "REASSESS") REASSESS++;
  });

  return { READY, DEVELOP, KEEP, REASSESS };
};

const getFullReadinessValues = (evaluations) => {
  let READY = 0;
  let SHORT_TERM_READY = 0;
  let MEDIUM_TERM_READY = 0;
  let DEVELOP = 0;
  let KEEP = 0;
  let REASSESS = 0;

  evaluations.forEach((value) => {
    if (value === "READY") READY++;
    if (value === "SHORT_TERM_READY") SHORT_TERM_READY++;
    if (value === "MEDIUM_TERM_READY") MEDIUM_TERM_READY++;
    if (value === "DEVELOP") DEVELOP++;
    if (value === "KEEP") KEEP++;
    if (value === "REASSESS") REASSESS++;
  });

  return {
    READY,
    SHORT_TERM_READY,
    MEDIUM_TERM_READY,
    DEVELOP,
    KEEP,
    REASSESS,
  };
};

const getAscentionValues = (evaluations) => {
  let READY = 0;
  let PARCIAL = 0;
  let ABOVE = 0;

  evaluations.forEach((value) => {
    if (value === "IMMEDIATE") READY++;
    if (value === "SHORT_TERM" || value === "LONG_TERM") PARCIAL++;
    if (value === "KEEP") ABOVE++;
  });

  return { READY, PARCIAL, ABOVE };
};

const filterPeopleByReadiness = (evaluations = [], target = "READY") => {
  let finalPeople = [];

  evaluations.forEach((evaluationInfo) => {
    if (evaluationInfo.mainTrail.readiness === target)
      finalPeople = [...finalPeople, evaluationInfo];
  });

  return finalPeople;
};

const getLabelBySlug = (slugValue, options) => {
  let finalLabel = "";

  options.forEach(({ value, label }) => {
    if (value === slugValue) finalLabel = label;
  });

  return finalLabel;
};

const getRoleLabelBySlug = (slugValue, intl) => {
  const roleOptions = getRoleOptions(intl);

  return getLabelBySlug(slugValue, roleOptions);
};

const getAreaLabelBySlug = (slugValue, intl) => {
  const areaOptions = getAreaOptions(intl);

  return getLabelBySlug(slugValue, areaOptions);
};

const getUpdatedHistory = (
  index,
  replaceBody = {},
  histories = [],
  order = "fromDate",
) => {
  histories[index] = {
    ...histories[index],
    ...replaceBody,
  };

  return sortHistoryByDate(histories, order);
};

const updateLastHistory = (replaceBody = {}, ascentions = []) => {
  if (ascentions.length > 0) {
    ascentions[0] = {
      ...ascentions[0],
      ...replaceBody,
    };
  }

  return ascentions;
};

const removeUpdateHistory = (index, histories = [], order = "fromDate") => {
  histories.splice(index, 1);
  return sortHistoryByDate(histories, order);
};

const getHistoryInfoByIndex = (index, histories = [], order = "fromDate") => {
  const orderedHistories = sortHistoryByDate(histories, order);

  if (orderedHistories.length > index) {
    return orderedHistories[index];
  }
  return {};
};

const getLastAreaFromHistory = (histories = []) => {
  let lastArea = null;

  for (const index in histories) {
    if (histories[index].toArea && histories[index].changedArea) {
      lastArea = histories[index].toArea;
      break;
    }
  }

  return lastArea;
};

const buildMemberNames = (allPeopleEvaluation = {}, allMembers = []) => {
  const finalPeopleEvaluation = [...allPeopleEvaluation];

  if (allMembers && allMembers.length > 0) {
    finalPeopleEvaluation.forEach((evalInfo, index) => {
      const { peopleEvaluation = {} } = evalInfo;
      const { professionalProfile = {} } = peopleEvaluation;
      const { professional = {} } = professionalProfile;

      if (professional) {
        const memberParticipantInfo = getProfessionalInfo(professional);

        if (memberParticipantInfo && memberParticipantInfo.id) {
          const memberInfo = getMemberInfoById(
            memberParticipantInfo.id,
            allMembers,
          );
          evalInfo.peopleEvaluation.professionalProfile.name = memberInfo.name
            ? memberInfo.name
            : "";
          evalInfo.peopleEvaluation.professionalProfile.memberId =
            memberInfo.id || null;
        }
      }

      finalPeopleEvaluation[index] = evalInfo;
    });
  }

  return finalPeopleEvaluation;
};

const getProfessionalInfo = (profileProfessional = {}) => {
  let profileMemberInfo = {};

  if (
    profileProfessional &&
    (profileProfessional.member || profileProfessional.type === "MEMBER")
  ) {
    profileMemberInfo = { ...profileProfessional, type: "MEMBER" };
  } else if (
    profileProfessional &&
    (profileProfessional.participant ||
      profileProfessional.type === "PARTICIPANT")
  ) {
    profileMemberInfo = {
      ...profileProfessional,
      type: "PARTICIPANT",
    };
  }

  return profileMemberInfo;
};

const getRelativeNextAndLastDates = (
  compareDate,
  ascentions = [],
  order = "asc",
) => {
  const greaterDates = [];
  const equalDates = [];
  const smallerDates = [];

  ascentions.forEach((ascBody) => {
    const daysDiff = moment(ascBody.fromDate).diff(compareDate, "days");
    const appendInfo = { ascention: ascBody, diff: daysDiff };

    if (daysDiff === 0) {
      equalDates.push(appendInfo);
    } else if (daysDiff > 0) {
      greaterDates.push(appendInfo);
    } else {
      smallerDates.push(appendInfo);
    }
  });

  const diffReturn1 = order === "asc" ? 1 : -1;
  const diffReturn2 = order === "asc" ? -1 : 1;

  return {
    greaterDates: greaterDates.sort((d1, d2) =>
      d1.diff > d2.diff ? diffReturn1 : diffReturn2,
    ),
    equalDates: equalDates.sort((d1, d2) =>
      d1.diff > d2.diff ? diffReturn1 : diffReturn2,
    ),
    smallerDates: smallerDates.sort((d1, d2) =>
      d1.diff > d2.diff ? diffReturn1 : diffReturn2,
    ),
  };
};

export {
  buildFormEvaluationBody,
  buildRestEvaluationBody,
  removeAllBodyIds,
  sortHistoryByDate,
  getHistoryInfoByIndex,
  getLastAreaFromHistory,
  getShortReadinessValues,
  getFullReadinessValues,
  getAscentionValues,
  getInsightPeopleEvaluation,
  getPeopleAverageList,
  filterPeopleByReadiness,
  getRoleLabelBySlug,
  getAreaLabelBySlug,
  getEvaluationsById,
  renderPeopleBPColumnLabel,
  getUpdatedHistory,
  updateLastHistory,
  removeUpdateHistory,
  buildMemberNames,
  getProfessionalInfo,
  getRelativeNextAndLastDates,
  getLabelBySlug,
};
