/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import SimplePieChart from "../../../../../../Common/SimplePieChart";

import {
  evaluationColors,
  getCompetenceConfigByComposition,
} from "../../../../../../../utils/skillsAssessment/constants";
import { getToFixed } from "../../../../../../../utils/MathUtils";
import { checkIsAdmin } from "../../../../../../../utils/skillsAssessment/filter";

const GeneralEvaluationCharts = ({
  maxScale,
  maxPotential,
  composition,
  totalAverage,
  replaceValue,
  generalOptions,
}) => {
  const chart = (data) => {
    return (
      <SimplePieChart
        data={data}
        containerHeight={160}
        showLegend={false}
        showActiveShape={false}
      />
    );
  };

  const getChart = (title, slug, baseValue, displayAppend = "") => {
    let colorInfo = evaluationColors[slug] || {};

    const slugValue = totalAverage[slug];

    const haveValue = !isNaN(slugValue) && isFinite(slugValue);
    const reachedValue = haveValue ? totalAverage[slug] : 0;
    const missingGap = baseValue - Math.abs(reachedValue);

    if (slug === "gap" && reachedValue > 0)
      colorInfo = evaluationColors.invertedGap;

    const chartData = [
      { value: missingGap, title: "Gap", color: "#eee" },
      {
        value: Math.abs(reachedValue),
        title: "Valor atingido",
        color: colorInfo.color,
      },
    ];

    const displayAverage = haveValue
      ? getToFixed(reachedValue, 1)
      : replaceValue;

    return (
      <div className="chart-col">
        <h5 align="center" style={{ marginBottom: "0px", height: "30px" }}>
          {title}
        </h5>
        <div className="chart">
          {chart(chartData)}
          <div className="total-count">{`${displayAverage}${displayAppend}`}</div>
        </div>
      </div>
    );
  };

  const { enableDesiredEvaluation, enablePotential, enableQualification } =
    getCompetenceConfigByComposition(composition);

  const { restrictViewAccess } = generalOptions || {};
  const notRestrict = checkIsAdmin() || !restrictViewAccess;

  return (
    <div
      className="row status-overview-charts"
      style={{ border: "1px solid #ccc" }}
    >
      <div className="title-field">
        <h4 align="center">AVALIAÇÃO GERAL</h4>
      </div>
      <div className="chart-field">
        {getChart("AUTOAVALIAÇÃO", "selfEvaluation", maxScale)}
        {notRestrict &&
          getChart("AVALIAÇÃO GERAL", "averageEvaluation", maxScale)}
        {notRestrict &&
          enableDesiredEvaluation &&
          getChart("AVALIAÇÃO DESEJADA", "desiredEvaluation", maxScale)}
        {notRestrict &&
          enablePotential &&
          getChart("POTENCIAL", "potential", maxPotential)}
        {notRestrict && enableQualification && getChart("GAP", "gap", 100, "%")}
      </div>
    </div>
  );
};

GeneralEvaluationCharts.propTypes = {
  maxScale: PropTypes.number.isRequired,
  maxPotential: PropTypes.number.isRequired,
  composition: PropTypes.string.isRequired,
  totalAverage: PropTypes.object.isRequired,
  generalOptions: PropTypes.object.isRequired,
  replaceValue: PropTypes.node,
};

GeneralEvaluationCharts.defaultProps = {
  replaceValue: "N/A",
};

export default GeneralEvaluationCharts;
