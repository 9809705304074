import React, { Component } from "react";

class ContainerHeader extends Component {
  render() {
    return (
      <section className="content-header" style={{ maxWidth: "1920px" }}>
        {this.props.children}
      </section>
    );
  }
}

export default ContainerHeader;
