import styled, { css } from "styled-components";

export const ContentPopover = styled.div`
  width: 250px;
  padding: 16px;
`;

export const HeaderPopover = styled.div`
  display: none;

  ${(props) =>
    props.hasTitle &&
    css`
      display: block;
      padding: 0 0 10px 0;
      border-bottom: 1px solid #dedede;
      margin: 0 0 14px 0px;
      text-align: center;

      span {
        font-weight: 500;
        color: #555;
      }
    `}
`;

export const InputCard = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 10px;

  span {
    font-size: 13px;
    font-weight: 500;
  }
`;

export const DescriptionCard = styled.div`
  margin-bottom: 10px;

  span {
    font-size: 13px;
    font-weight: 500;
  }
`;
