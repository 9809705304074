const en = {
  question_main_title: "Question",
  add_new_question: "Add question",
};

const es = {
  question_main_title: "Pregunta",
  add_new_question: "Agregar pregunta",
};

const pt = {
  question_main_title: "Questão",
  add_new_question: "Adicionar questão",
};

const translations = { en, es, pt };

export default translations;
