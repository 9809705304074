import React from "react";
import Chart from "react-google-charts";
import { Tooltip } from "@material-ui/core";
import { defineMessages } from "react-intl";

const messages = defineMessages({
  physicalAdvance: {
    id: "tool.project.physical_progress",
  },
});

const graphDefaultStyles = {
  width: "100%",
};

const GanttMap = ({ intl, ganttMapData }) => {
  const halfMapData = ganttMapData.length / 2;
  const calcHeightChart = 40.99 * halfMapData + 50;
  const heightChart = `${calcHeightChart.toFixed(2)}px`;

  return (
    <div className="tab-content">
      <div className="legendBox">
        <Tooltip title="Azul acinzentado claro">
          <div>
            <span className="baselineDot" /> Baseline
          </div>
        </Tooltip>
        <Tooltip title="Azul acinzentado escuro">
          <div>
            <span className="deadlineDot" /> Deadline
          </div>
        </Tooltip>
        <Tooltip title="Azul">
          <div>
            <span className="physicalAdvanceDot" />{" "}
            {intl.formatMessage(messages.physicalAdvance)}
          </div>
        </Tooltip>
      </div>

      <Chart
        width={graphDefaultStyles.width}
        height={heightChart}
        chartType="Timeline"
        data={[
          [
            { type: "string", id: "Project" },
            { type: "string", id: "Name" },
            { type: "string", role: "tooltip", p: { html: true } },
            { type: "date", id: "Start" },
            { type: "date", id: "End" },
          ],
          ...ganttMapData,
        ]}
        options={{
          is3D: true,
          colors: ["#b8cfe7", "#668fba", "#499DF5"],
          allowHtml: true,
        }}
      />
    </div>
  );
};

export default GanttMap;
