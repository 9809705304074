/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import { Tabs, Tab } from "react-bootstrap";

import { getDefaultEvaluationStage } from "utils/skillsAssessment/defaults";
import ConfirmationDialog from "../../../../../Common/ConfirmationDialog";
import SimpleOptionsModal from "../../../../../Common/SimpleOptionsModal";
import { simpleSaveAsExcel } from "../../../../../Common/Export/ExportCSV";
import SimpleLoader from "../../../../../SimpleLoader";
import ParticipantsWorkflowTable from "./workflow/ParticipantsWorkflowTable";
import SelectedWorkflowDialog from "./workflow/SelectedWorkflowDialog";

import {
  getSelectedCompanyStates,
  getSelectedToolStates,
  getSkillAssessmentStates,
  getCompanyMembersAndParticipants,
} from "../../../../../customMapStates";
import {
  getEvaluationStagesOptions,
  getRelativeEvaluationStage,
  getEvaluationConfigByType,
} from "../../../../../../utils/skillsAssessment/constants";
import {
  checkIsAdmin,
  getComplementaryWorkflowReportData,
  getGeneralWorkflowReportData,
  getUserPeopleToEvaluateByStage,
  getItemsByPage,
  filterParticipantsByNameOrEmail,
  getSelectionEvaluatorsParticipants,
} from "../../../../../../utils/skillsAssessment/filter";
import { getMaximumScale } from "../../../../../../utils/skillsAssessment/calc";
import {
  useInsightParticipants,
  useSimpleToolAdministration,
} from "../../../../../../utils/skillsAssessment/hooks/administration";
import {
  updateToolConfig,
  fetchSkillAssessmentConfig,
  sendInviteToParticipants,
  sendSelectEvaluators,
  sendManagersNotification,
  updateParticipantsNextWorkflowStage,
  sendManagersNotificationSelectionEvaluators,
} from "../../../../../../actions/skillAssessmentActions";
import { simpleNotifier } from "../../../../../../actions/notifierActions";
import { defaultConfirmDialog } from "./defaults";
import { useWorkflowFields } from "./workflow/customHooks/useWorkflowFields";
import { useParticipantsWorkflowPage } from "./workflow/customHooks/useParticipantsWorkflowPage";
import { useFilteredInsights } from "../../../../../../utils/hooks/insightHooks";

const PARTICIPANTS_PER_PAGE = 10;

const Workflow = ({
  selectedTool,
  allSkillAssessment,
  isLoading,
  loggedUser,
  fetchSkillAssessmentConfig,
  allAnswers,
  companyMembersAndParticipants,
}) => {
  const { toolAdministration } = useSimpleToolAdministration({
    selectedTool,
    allSkillAssessment,
    isLoading,
  });
  const { filteredInsights } = useFilteredInsights({ allAnswers });
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const { participants: filteredParticipants } = useInsightParticipants({
    insights: filteredInsights,
    participants: toolAdministration.participants,
    loggedUser,
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState("");
  const [selectedPageParticipants, setSelectedPageParticipants] = useState([]);

  const filteredAdministration = {
    ...toolAdministration,
    participants: filteredParticipants,
  };

  const isAdmin = checkIsAdmin();

  useEffect(() => {
    fetchSkillAssessmentConfig(selectedTool.id);
  }, []);

  const {
    evaluationType,
    evaluationConfig,
    managerConfig,
    composition,
    evaluationScale,
    potentialScale,
    dates,
    complementaryWorkflow,
    complementaryScales,
    transparency,
    enableEvaluatorsSelectionWorkflow,
  } = filteredAdministration;

  const [filterStage, setFilterStage] = useState();
  const [confirmDialog, setConfirmDialog] = useState(defaultConfirmDialog);
  const [exportDialog, setExportDialog] = useState({ open: false });

  const stageParticipants = filteredParticipants.filter(
    ({ evaluationStage }) => evaluationStage === filterStage,
  );
  useEffect(() => {
    const defaultStage = getDefaultEvaluationStage(
      enableEvaluatorsSelectionWorkflow,
    );
    setFilterStage(defaultStage);
  }, [enableEvaluatorsSelectionWorkflow]);
  useEffect(() => {
    const filtered = filter
      ? filterParticipantsByNameOrEmail(filter, stageParticipants)
      : stageParticipants;
    const pageParticipants = getItemsByPage(
      filtered,
      page,
      PARTICIPANTS_PER_PAGE,
    );
    setTotalPages(
      filtered.length > 0
        ? Math.ceil(filtered.length / PARTICIPANTS_PER_PAGE)
        : 0,
    );

    setSelectedPageParticipants(pageParticipants);
  }, [page, filter, filteredParticipants, filterStage]);

  const { participantsWorkflow, participantsComplementaryWorkflow } =
    useParticipantsWorkflowPage({
      toolAdministration: filteredAdministration,
      filterStage,
      loggedUser,
      isAdmin,
    });

  const { enabledFields } = useWorkflowFields({
    selectedParticipant,
    filterStage,
    evaluationType,
    managerConfig,
    composition,
    loggedUser,
    transparency,
  });

  const { next: nextStage } = getRelativeEvaluationStage(
    filterStage,
    evaluationType,
    evaluationConfig,
    enableEvaluatorsSelectionWorkflow,
  );

  const { enableSecondEvaluator: enableSEv, enableThirdEvaluator: enableTEv } =
    getEvaluationConfigByType(evaluationType);

  const blockAdditionalEvaluators = !enableSEv && !enableTEv;

  const changeFilterStage = (newStage) => {
    setFilterStage(newStage);
    setPage(1);
  };

  const resetConfirmDialog = () => {
    setConfirmDialog(defaultConfirmDialog);
  };

  function getConfirmParticipantsList(selectedParticipantIds) {
    const MAX = 20;
    const maxDisplayIds = selectedParticipantIds.slice(0, MAX);

    return (
      <div style={{ padding: "10px 0px", fontWeight: "bold" }}>
        {maxDisplayIds
          .map(
            (id) =>
              filteredParticipants.find((p) => p.id === id)?.memberParticipant
                ?.name,
          )
          .join(", ")}
        {selectedParticipantIds.length > MAX &&
          ` e mais ${selectedParticipantIds.length - MAX}`}
        {selectedParticipantIds.length < stageParticipants.length && (
          <div className="row">
            <button
              className="btn btn-success"
              onClick={() =>
                handleSendInvites(stageParticipants.map(({ id }) => id))
              }
            >
              Quer enviar para todos os pendentes?
            </button>
          </div>
        )}
      </div>
    );
  }

  function getConfirmManagersList(managerIds, selectedParticipantIds) {
    const MAX = 20;
    const maxDisplayIds = managerIds.slice(0, MAX);

    return (
      <div style={{ padding: "10px 0px", fontWeight: "bold" }}>
        {maxDisplayIds
          .map(
            (id) =>
              companyMembersAndParticipants.find((user) => user.id === id)
                ?.name,
          )
          .join(", ")}
        {managerIds.length > MAX && ` e mais ${managerIds.length - MAX}`}
        {selectedParticipantIds.length < stageParticipants.length && (
          <div className="row">
            <button
              className="btn btn-success"
              onClick={() =>
                handleNotifyManagers(stageParticipants.map(({ id }) => id))
              }
            >
              Quer enviar para todos os pendentes?
            </button>
          </div>
        )}
      </div>
    );
  }

  const handleSendInvites = (selectedParticipantIds) => {
    const title =
      "Tem certeza que deseja enviar a pesquisa de avaliação para os Participantes em Autoavaliação?";

    setConfirmDialog({
      open: true,
      title,
      description: (
        <div>
          Os participantes receberão um e-mail com o link de sua Avaliação
          Individual. Ao confirmarem suas respostas o workflow será enviado ao
          próximo estágio para avaliação dos Gestores Imediatos.
          {getConfirmParticipantsList(selectedParticipantIds)}
        </div>
      ),
      onConfirm: async () => {
        setConfirmDialog((current) => {
          return {
            ...current,
            description: "Enviando pesquisa aos participantes...",
            onConfirm: () => {},
            onCancel: () => {},
          };
        });

        const { data } = await sendInviteToParticipants({
          selected_tool_id: selectedTool.id,
          participantIds: selectedParticipantIds,
        });
        if (data?.success) resetConfirmDialog();
        if (!data?.success)
          setConfirmDialog((current) => {
            return {
              ...current,
              title: "Ocorreu um erro ao enviar os emails",
              description: "Não foi possível finalizar a operação",
              onConfirm: resetConfirmDialog,
              onCancel: resetConfirmDialog,
            };
          });
      },
      onCancel: resetConfirmDialog,
    });
  };
  const handleSendSelectionEvaluatorsInvites = (selectedParticipantIds) => {
    const title =
      "Tem certeza que deseja enviar seleção de avaliadores para os Participantes ?";

    setConfirmDialog({
      open: true,
      title,
      description: (
        <div>
          Os participantes receberão um e-mail com o link para indicarem seus
          avaliadores. Ao confirmarem suas respostas o workflow será enviado ao
          próximo estágio para validação.
          {getConfirmParticipantsList(selectedParticipantIds)}
        </div>
      ),
      onConfirm: async () => {
        setConfirmDialog((current) => {
          return {
            ...current,
            description: "Enviando email aos participantes...",
            onConfirm: () => {},
            onCancel: () => {},
          };
        });

        const { data } = await sendSelectEvaluators({
          selected_tool_id: selectedTool.id,
          participantIds: selectedParticipantIds,
        });
        if (data?.success) resetConfirmDialog();
        if (!data?.success)
          setConfirmDialog((current) => {
            return {
              ...current,
              title: "Ocorreu um erro ao enviar os emails",
              description: "Não foi possível finalizar a operação",
              onConfirm: resetConfirmDialog,
              onCancel: resetConfirmDialog,
            };
          });
      },
      onCancel: resetConfirmDialog,
    });
  };

  const handleNotifyManagers = (selectedParticipantIds) => {
    const title =
      "Tem certeza que deseja enviar uma notificação para os gestores imediatos?";

    const managerIds = Array.from(
      new Set(
        selectedParticipantIds.map((pID) => {
          const participant = toolAdministration?.participants?.find(
            (p) => p.id === pID,
          );
          return participant.manager?.id;
        }),
      ),
    );

    setConfirmDialog({
      open: true,
      title,
      description: (
        <div>
          Os gestores receberão um e-mail com o link para avaliação dos
          participantes selecionados.
          {getConfirmManagersList(managerIds, selectedParticipantIds)}
        </div>
      ),
      onConfirm: async () => {
        setConfirmDialog((current) => {
          return {
            ...current,
            description: "Enviando notificação aos gestores...",
            onConfirm: () => {},
            onCancel: () => {},
          };
        });

        try {
          await sendManagersNotification({
            selected_tool_id: selectedTool.id,
            managerIds,
          });
          resetConfirmDialog();
        } catch (err) {
          setConfirmDialog((current) => {
            return {
              ...current,
              title: "Ocorreu um erro ao enviar os emails",
              description: "Não foi possível finalizar a operação",
              onConfirm: resetConfirmDialog,
              onCancel: resetConfirmDialog,
            };
          });
        }
      },
      onCancel: resetConfirmDialog,
    });
  };

  const handleNotifyManagersSelectionEvaluator = () => {
    const title =
      "Tem certeza que deseja enviar uma notificação para os gestores imediatos?";

    setConfirmDialog({
      open: true,
      title,
      description: (
        <div>
          Os gestores receberão um e-mail com o link para aprovação da seleção
          dos avaliadores dos participantes.
        </div>
      ),
      onConfirm: async () => {
        setConfirmDialog((current) => {
          return {
            ...current,
            description: "Enviando notificação aos gestores...",
            onConfirm: () => {},
            onCancel: () => {},
          };
        });

        try {
          await sendManagersNotificationSelectionEvaluators();
          resetConfirmDialog();
        } catch (err) {
          setConfirmDialog((current) => {
            return {
              ...current,
              title: "Ocorreu um erro ao enviar os emails",
              description: "Não foi possível finalizar a operação",
              onConfirm: resetConfirmDialog,
              onCancel: resetConfirmDialog,
            };
          });
        }
      },
      onCancel: resetConfirmDialog,
    });
  };

  const handleSendNextStage = (selectedParticipantIds) => {
    const title =
      "Tem certeza que deseja atualizar o estágio dos participantes selecionados?";

    setConfirmDialog({
      open: true,
      title,
      description: (
        <div>
          Os participantes selecionados serão enviados para o próximo estágio do
          Workflow.
          {getConfirmParticipantsList(selectedParticipantIds)}
        </div>
      ),
      onConfirm: async () => {
        setConfirmDialog((current) => {
          return {
            ...current,
            description: "Atualizando workflow...",
            onConfirm: () => {},
            onCancel: () => {},
          };
        });

        const { data } = await updateParticipantsNextWorkflowStage({
          selected_tool_id: selectedTool.id,
          participantIds: selectedParticipantIds,
          nextEvaluationStage: nextStage.value,
        });
        if (data?.success) {
          resetConfirmDialog();
          fetchSkillAssessmentConfig(selectedTool.id);
        }
        if (!data?.success)
          setConfirmDialog((current) => {
            return {
              ...current,
              title: "Ocorreu um erro ao atualizar o workflow",
              description: "Não foi possível finalizar a operação",
              onConfirm: resetConfirmDialog,
              onCancel: resetConfirmDialog,
            };
          });
      },
      onCancel: resetConfirmDialog,
    });
  };

  const exportSelectionEvaluatorsReport = async () => {
    const { data, headers } = await getSelectionEvaluatorsParticipants({
      filteredParticipants,
    });

    const timeStampString = moment().toJSON();

    simpleSaveAsExcel(
      data(),
      headers,
      null,
      `selection-evaluators-${timeStampString}`,
      [{ line: 1, fill: "eeeeee" }],
    );
  };

  const exportGeneralWorkflowReport = async () => {
    const { data, headers } = await getGeneralWorkflowReportData({
      filteredParticipants,
      toolAdministration,
    });

    const timeStampString = moment().toJSON();

    simpleSaveAsExcel(
      data,
      headers,
      null,
      `competence-evaluation-report-${timeStampString}`,
      [{ line: 1, fill: "eeeeee" }],
    );
  };

  const exportComplementaryWorkflowReport = async (filterType) => {
    const { data, headers } = await getComplementaryWorkflowReportData({
      filteredParticipants,
      complementaryWorkflow,
      complementaryScales,
      filterType,
    });

    const timeStampString = moment().toJSON();

    simpleSaveAsExcel(
      data,
      headers,
      null,
      `${filterType.toLowerCase()}-report-${timeStampString}`,
      [{ line: 1, fill: "eeeeee" }],
    );
  };

  function handleSelectParticipant(participant) {
    if (participant?.id) {
      setSelectedParticipant(participant);
    }
  }

  const renderTitle = (title, icon, count = 0) => {
    return (
      <>
        <i className={icon} /> {title} ({count})
      </>
    );
  };

  const getTab = (key, title, icon) => {
    const filtered = getUserPeopleToEvaluateByStage(
      loggedUser,
      filteredParticipants,
      key,
    );

    if (key === "EVALUATORS" && blockAdditionalEvaluators)
      return <React.Fragment key={key} />;

    return (
      <Tab
        eventKey={key}
        title={renderTitle(title, icon, filtered.length)}
        key={key}
      >
        &nbsp;
      </Tab>
    );
  };

  const getConfirmDialog = () => {
    return (
      <ConfirmationDialog
        open={confirmDialog.open}
        title={confirmDialog.title}
        description={confirmDialog.description}
        onConfirm={confirmDialog.onConfirm}
        onCancel={confirmDialog.onCancel}
        confirmText={confirmDialog.confirmText}
        hideCancel={confirmDialog.hideCancel}
      />
    );
  };

  const getReportsExport = () => {
    return (
      <SimpleOptionsModal
        open={exportDialog.open}
        onCancel={() => setExportDialog({ open: false })}
        title="Selecione o relatório para exportar"
        options={[
          {
            title: "Seleção de Avaliadores",
            icon: <i className="fas fa-user-check" />,
            onClick: () => exportSelectionEvaluatorsReport(),
          },
          {
            title: "Avaliação Geral",
            icon: <i className="fas fa-users" />,
            onClick: () => exportGeneralWorkflowReport(),
          },
          {
            title: "Avaliação de Desempenho",
            icon: <i className="fas fa-stopwatch" />,
            onClick: () => exportComplementaryWorkflowReport("PERFORMANCE"),
          },
          {
            title: "Avaliação de Função e Posição",
            icon: <i className="fas fa-paste" />,
            onClick: () => exportComplementaryWorkflowReport("KEY_SEAT"),
          },
          {
            title: "Avaliação de Sucessão",
            icon: <i className="fas fa-exchange-alt" />,
            onClick: () => exportComplementaryWorkflowReport("SUCCESSION"),
          },
        ]}
      />
    );
  };

  const evaluationStages = getEvaluationStagesOptions(
    evaluationType,
    evaluationConfig,
    false,
    enableEvaluatorsSelectionWorkflow,
  );
  const maxScale = getMaximumScale(evaluationScale);
  const maxPotential = getMaximumScale(potentialScale);

  return (
    <div>
      <div
        className="col-xs-12"
        style={{ display: "flex", alignItems: "center", gap: "15px" }}
      >
        <h2 style={{ color: "#6b42a9", paddingBottom: "10px" }}>WORKFLOW</h2>
        {isLoading && <SimpleLoader color="#6b42a9" />}
      </div>
      {getConfirmDialog()}
      {getReportsExport()}
      <div className="col-xs-12">
        <div className="custom-primary-tabs">
          <div className="col-xs-10">
            <Tabs
              id="skillsAssessmentAdministration"
              activeKey={filterStage}
              onSelect={(key) => changeFilterStage(key)}
            >
              {evaluationStages.map(({ value, label }) => {
                return getTab(value, label);
              })}
            </Tabs>
          </div>
          {isAdmin && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => setExportDialog({ open: true })}
              >
                <i className="fas fa-file-csv" /> Relatórios
              </button>
            </div>
          )}
          <ParticipantsWorkflowTable
            toolAdministration={toolAdministration}
            participantsWorkflow={participantsWorkflow}
            participantsComplementaryWorkflow={
              participantsComplementaryWorkflow
            }
            participants={selectedPageParticipants}
            enabledFields={enabledFields}
            handleSelectParticipant={handleSelectParticipant}
            handleSendInvites={handleSendInvites}
            handleSendSelectionEvaluatorsInvites={
              handleSendSelectionEvaluatorsInvites
            }
            handleNotifyManagers={handleNotifyManagers}
            handleNotifyManagersSelectionEvaluator={
              handleNotifyManagersSelectionEvaluator
            }
            handleSendNextStage={handleSendNextStage}
            enableSecondEvaluator={enableSEv}
            enableThirdEvaluator={enableTEv}
            dates={dates}
            composition={composition}
            maxScale={maxScale}
            maxPotential={maxPotential}
            filterStage={filterStage}
            isAdmin={isAdmin}
            hideNextStage={!nextStage}
            totalPages={totalPages}
            page={page}
            setPage={setPage}
            filter={filter}
            setFilter={setFilter}
            loggedUser={loggedUser}
          />
          {selectedParticipant?.id && (
            <SelectedWorkflowDialog
              selectedParticipantId={selectedParticipant.id}
              selectedToolId={selectedTool.id}
              toolAdministration={toolAdministration}
              filteredAdministration={filteredAdministration}
              isLoading={isLoading}
              loggedUser={loggedUser}
              companyMembersAndParticipants={companyMembersAndParticipants}
              filterStage={filterStage}
              handleClose={() => setSelectedParticipant(null)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { selectedTool = {} } = getSelectedToolStates(state);
  const { allSkillAssessment, isLoading } = getSkillAssessmentStates(state);
  const { selectedCompanyId } = getSelectedCompanyStates(state);
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);

  const { user } = state;

  return {
    selectedTool,
    allSkillAssessment,
    isLoading,
    loggedUser: user,
    selectedCompanyId,
    companyMembersAndParticipants,
  };
};

Workflow.propTypes = {
  selectedTool: PropTypes.object.isRequired,
  allSkillAssessment: PropTypes.array.isRequired,
  loggedUser: PropTypes.object.isRequired,
  allAnswers: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

Workflow.defaultProps = {
  isLoading: false,
};

export default connect(mapStateToProps, {
  updateToolConfig,
  fetchSkillAssessmentConfig,
  simpleNotifier,
})(Workflow);
