import React from "react";
import { calculateAge } from "../../../../../../utils/DateUtils";
import utils from "../../../../../../utils/toolUtils";
import SimpleHorizontalBarChart from "../../../../../Common/SimpleHorizontalBarChart";
import SimplePieChart from "../../../../../Common/SimplePieChart";

const { getLabelBySlug } = utils;

const WageRecognitionField = (props) => {
  const {
    filteredEvaluations,
    salaryModificationOptions,
    marketEvaluationOptions,
    messages,
    intl,
  } = props;

  const displayAdjustHistoryGraph = () => {
    let up1 = 0;
    let up2 = 0;
    let up3 = 0;
    let moreThan3 = 0;

    filteredEvaluations.forEach(({ peopleEvaluation }) => {
      const { salaryTrajectory } = peopleEvaluation;
      const { remunerationHistories } = salaryTrajectory;
      if (remunerationHistories && remunerationHistories.length > 0) {
        const lastRemuneration = calculateAge(
          remunerationHistories[remunerationHistories.length - 1].date,
        );

        if (lastRemuneration <= 1) up1++;
        if (lastRemuneration > 1 && lastRemuneration <= 2) up2++;
        if (lastRemuneration > 2 && lastRemuneration <= 3) up3++;
        if (lastRemuneration >= 3) moreThan3++;
      }
    });

    return (
      <SimpleHorizontalBarChart
        containerHeight={240}
        legendLabel={intl.formatMessage(messages.labels_professionals)}
        data={[
          {
            name: getLabelBySlug(
              "MORE_THAN_3_YEARS",
              salaryModificationOptions,
            ),
            value: moreThan3,
          },
          {
            name: getLabelBySlug("THREE_YEARS", salaryModificationOptions),
            value: up3,
          },
          {
            name: getLabelBySlug("TWO_YEARS", salaryModificationOptions),
            value: up2,
          },
          {
            name: getLabelBySlug("ONE_YEAR", salaryModificationOptions),
            value: up1,
          },
        ]}
      />
    );
  };

  const displayMarketGraph = () => {
    let BELOW = 0;
    let ACCORDING = 0;
    let ABOVE = 0;

    filteredEvaluations.forEach(({ peopleEvaluation }) => {
      const { salaryTrajectory } = peopleEvaluation;
      const { marketEvaluation } = salaryTrajectory;

      if (marketEvaluation === "ABOVE") ABOVE++;
      if (marketEvaluation === "ACCORDING") ACCORDING++;
      if (marketEvaluation === "BELOW") BELOW++;
    });

    return (
      <SimplePieChart
        data={[
          {
            name: getLabelBySlug("ABOVE", marketEvaluationOptions),
            value: ABOVE,
            color: "#24da7b",
          },
          {
            name: getLabelBySlug("ACCORDING", marketEvaluationOptions),
            value: ACCORDING,
            color: "#f0f579",
          },
          {
            name: getLabelBySlug("BELOW", marketEvaluationOptions),
            value: BELOW,
            color: "#8f7070",
          },
        ]}
        containerHeight={220}
      />
    );
  };

  return (
    <div className="evaluation-card">
      <div className="card-header">
        <div className="card-title">
          <h4>{intl.formatMessage(messages.titles_cards_wage_recognition)}</h4>
        </div>
      </div>
      <div className="card-body" style={{ height: "315px" }}>
        <div className="row">
          <div className="col-xs-6">
            <h5 align="center">
              {intl.formatMessage(messages.labels_readjust_history)}
            </h5>
            {displayAdjustHistoryGraph()}
          </div>
          <div className="col-xs-6">
            <h5 align="center">
              {intl.formatMessage(messages.labels_market_comparison)}
            </h5>
            {displayMarketGraph()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WageRecognitionField;
