import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import { Tabs, Tab } from "react-bootstrap";

import utils from "../../../../../utils/toolUtils";
import { globalMessages } from "../../../../../utils/global";
import { getObjectInfoById } from "../../../../../utils/ArrayUtils";
import {
  getSelectedToolStates,
  getActionsPlanStates,
} from "../../../../customMapStates";

import ActionsPlanOverview from "../../../../Common/ActionPlan/ActionsPlanOverview";
import TableEvaluationSwot from "../../../../Common/TableEvaluationSwot";
import ButtonGeneratePdf from "../../../../Common/ButtonGeneratePdf";

const messages = defineMessages({
  acp_main_title: { id: "acp_main_title" },
  acp_swot_insight_label: { id: "acp_swot_insight_label" },
  acp_swot_tool_table_title: { id: "acp_swot_tool_table_title" },
  acp_swot_tool_table_head_insight_label: {
    id: "acp_swot_tool_table_head_insight_label",
  },
});

const { getSwotEvaluationByID } = utils;

class SwotManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCenary: this.props.filterData.scenaries,
      themes: this.props.cenaryThemes,
      questions: this.props.questions,
      answers: this.props.answers,
      allSwotEvaluation: [],
      activeTab: "swot",
    };
  }

  handleTabChange = (key) => {
    this.setState({ activeTab: key });
  };

  renderNoDataFilter() {
    const { intl } = this.props;
    return (
      <div align="center">
        <h4>{intl.formatMessage(globalMessages.noData)}</h4>
      </div>
    );
  }

  getSelectedTheme(themeID) {
    const { cenaryThemes } = this.props;
    let themeName = null;
    Object.keys(cenaryThemes.allThemes).forEach((scenerieThemeID) => {
      cenaryThemes.allThemes[scenerieThemeID].forEach((theme) => {
        const themeTransform = themeID;
        const themeToString = themeTransform.toString();
        if (theme.id.toString() === themeToString) {
          themeName = theme.name;
        }
      });
    });
    return themeName;
  }

  filterQuestions(filterData, questions, cenaryThemes) {
    const filterQuestions = { questions: null };
    let themeName = null;
    filterData.sceneries.forEach((sceneryId) => {
      if (cenaryThemes.allThemes) {
        Object.keys(cenaryThemes.allThemes).forEach((scenerieThemeID) => {
          if (sceneryId.toString() === scenerieThemeID.toString()) {
            filterQuestions[sceneryId] = { questions: [] };
            cenaryThemes.allThemes[sceneryId].forEach((theme) => {
              if (filterData.themes) {
                if (filterData.themes.length > 1) {
                  questions &&
                    questions[theme.id] &&
                    questions[theme.id].forEach((question) => {
                      filterQuestions[sceneryId] = {
                        ...filterQuestions[sceneryId],
                        questions: [
                          ...filterQuestions[sceneryId].questions,
                          question,
                        ],
                        title: theme.cenary.name,
                      };
                    });
                } else if (filterData.themes[0]) {
                  themeName = this.getSelectedTheme(filterData.themes[0]);
                  if (themeName === theme.name) {
                    if (questions[theme.id]) {
                      questions[theme.id].forEach((question) => {
                        filterQuestions[sceneryId] = {
                          ...filterQuestions[sceneryId],
                          questions: [
                            ...filterQuestions[sceneryId].questions,
                            question,
                          ],
                          title: theme.cenary.name,
                        };
                      });
                    }
                  }
                }
              }
            });
          }
        });
      }
    });
    return filterQuestions;
  }

  getQuestionInsights = (questionID) => {
    const { answers = {} } = this.props;

    return answers[questionID] ? answers[questionID] : [];
  };

  getFilteredQuestionsAndInsights = () => {
    const { allQuestions, filterData } = this.props;

    const filteredQuestions = [];
    const filteredInsights = [];

    if (allQuestions && filterData.themes) {
      Object.keys(allQuestions).forEach((themeId) => {
        const themeQuestions = allQuestions[themeId];

        if (
          filterData.themes.indexOf(Number(themeId)) > -1 &&
          Array.isArray(themeQuestions)
        ) {
          themeQuestions.forEach((questionInfo) => {
            const questionInsights = this.getQuestionInsights(questionInfo.id);

            if (filterData.questions?.includes(questionInfo.id)) {
              filteredQuestions.push(questionInfo);
              if (Array.isArray(questionInsights))
                questionInsights.forEach((insightInfo) =>
                  filteredInsights.push(insightInfo),
                );
            }
          });
        }
      });
    }

    return { filteredQuestions, filteredInsights };
  };

  filterAnswers(allAnswers, newQuestions) {
    const filterAnswers = [];
    Object.keys(newQuestions).forEach((sceneryId) => {
      newQuestions[sceneryId] &&
        newQuestions[sceneryId].questions.forEach((question) => {
          if (allAnswers) {
            Object.keys(allAnswers).forEach((themeAnswers) => {
              const sortedAnswers = allAnswers[themeAnswers];
              sortedAnswers.sort((a, b) => {
                const bScore = a.swotAnalysis ? a.swotAnalysis.score : 0;
                const aScore = b.swotAnalysis ? b.swotAnalysis.score : 0;

                return aScore > bScore ? 1 : -1;
              });
              const questionID = question.id.toString();
              const answerID = themeAnswers.toString();
              if (questionID === answerID) {
                filterAnswers[themeAnswers] = sortedAnswers;
              }
            });
          }
        });
    });
    return filterAnswers;
  }

  getAnswerAvaliations(answers) {
    const { allSwotEvaluation } = this.state;

    answers.forEach((answer) => {
      const analysis = getSwotEvaluationByID(answer.id, allSwotEvaluation);
      if (analysis) {
        answer.swotAnalysis = analysis.swotAnalysis;
      } else {
        answer.swotAnalysis = null;
      }
    });

    return answers;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let newQuestions = { questions: null };
    let newAnswers = [];
    const { filterData, questions, cenaryThemes, answers } = newProps;

    if (_.isEqual(newProps, this.props) === false) {
      if (filterData.sceneries && filterData.sceneries.length > 0) {
        newQuestions = this.filterQuestions(
          filterData,
          questions,
          cenaryThemes,
        );

        newAnswers = this.filterAnswers(answers, newQuestions);
      }
      this.setState({
        questions: newQuestions,
        answers: newAnswers,
      });
    }
    if (!_.isEqual(this.state.allSwotEvaluation, newProps.allSwotEvaluation)) {
      this.setState({
        allSwotEvaluation: newProps.allSwotEvaluation,
      });
    }
  }

  generateTable(title, answers = [], index) {
    const { selectAndOpenInsight } = this.props;
    const newAnswers = this.getAnswerAvaliations(answers);

    return (
      <div className="col-xs-12" key={index}>
        <TableEvaluationSwot
          title={title}
          answers={newAnswers}
          selectAndOpenInsight={selectAndOpenInsight}
        />
      </div>
    );
  }

  render() {
    const { questions, answers, activeTab } = this.state;
    const {
      filterData,
      selectedTool = {},
      allSceneries,
      allActionsPlans = [],
      intl,
      selectAndOpenInsight,
    } = this.props;
    const { filteredInsights = [] } = this.getFilteredQuestionsAndInsights();

    const swotTabTitle = (
      <div>
        <i className="fas fa-border-all" /> SWOT
      </div>
    );

    const actionsPlanOverviewTitle = (
      <div>
        <i className="fas fa-list-ol" />{" "}
        {intl.formatMessage(messages.acp_main_title)}
      </div>
    );

    return (
      <div className="custom-primary-tabs row">
        <ButtonGeneratePdf />
        <Tabs
          id="processManagement"
          activeKey={activeTab}
          onSelect={(key) => this.handleTabChange(key)}
        >
          <Tab eventKey="swot" title={swotTabTitle}>
            <div className="name-tab-pdf">
              <i className="fas fa-border-all" /> SWOT
            </div>
            <div className="tab-content" style={{ marginTop: "0px" }}>
              {filterData.sceneries && questions
                ? Object.keys(questions).map((sceneryId, index) => {
                    questions[sceneryId] = questions[sceneryId]
                      ? questions[sceneryId]
                      : {};
                    const filteredScenerieInfo = questions[sceneryId].title
                      ? questions[sceneryId]
                      : {};
                    const scenarioId = Number(sceneryId);
                    const onlyQuestions = filteredScenerieInfo.questions || [];

                    const sceneryInfo = scenarioId
                      ? getObjectInfoById(scenarioId, allSceneries, "id")
                      : null;

                    return (
                      sceneryInfo && (
                        <div className="swot-responsible-table" key={index}>
                          <h1
                            align="left"
                            style={{ marginBottom: "0px", padding: "0px 15px" }}
                          >
                            {filteredScenerieInfo && sceneryInfo.name}
                          </h1>
                          <div
                            className="row"
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              flexFlow: "row wrap",
                            }}
                          >
                            {onlyQuestions.map((question, index) => {
                              return filterData.questions?.includes(question.id)
                                ? this.generateTable(
                                    question.text,
                                    answers[question.id],
                                    index,
                                  )
                                : "";
                            })}
                          </div>
                        </div>
                      )
                    );
                  })
                : this.renderNoDataFilter()}
            </div>
          </Tab>
          <Tab eventKey="actionsPlanOverview" title={actionsPlanOverviewTitle}>
            <div className="name-tab-pdf">
              <i className="fas fa-list-ol" />{" "}
              {intl.formatMessage(messages.acp_main_title)}
            </div>
            <div className="tab-content" style={{ marginTop: "10px" }}>
              <ActionsPlanOverview
                allActionsPlans={allActionsPlans}
                filteredInsights={filteredInsights}
                overviewTableTitle={intl
                  .formatMessage(messages.acp_main_title)
                  .toUpperCase()}
                overviewTableInsightsLabel={intl
                  .formatMessage(messages.acp_swot_insight_label)
                  .toUpperCase()}
                insightsTableTitle={intl
                  .formatMessage(messages.acp_swot_tool_table_title)
                  .toUpperCase()}
                insightsTableInsightsLabel={intl
                  .formatMessage(
                    messages.acp_swot_tool_table_head_insight_label,
                  )
                  .toUpperCase()}
                selectedTool={selectedTool}
                selectAndOpenInsight={selectAndOpenInsight}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { swotEvaluation, toolsConfigurations } = state;
  const allSwotEvaluation =
    swotEvaluation && swotEvaluation.allSwotEvaluation
      ? swotEvaluation.allSwotEvaluation
      : [];

  const {
    selectedTool = {},
    allSceneries = [],
    allAnswers = {},
    allQuestions = {},
  } = getSelectedToolStates(state, {
    answers: toolsConfigurations.showHiddenAnswers,
    questions: toolsConfigurations.showHiddenQuestions,
  });

  const { allActionsPlans = [] } = getActionsPlanStates(state);

  return {
    selectedTool,
    cenaryThemes: state.cenaryThemes,
    questions: state.questions,
    allSceneries,
    allQuestions,
    answers: allAnswers,
    allSwotEvaluation,
    showHiddenAnswers: toolsConfigurations.showHiddenAnswers,
    showHiddenQuestions: toolsConfigurations.showHiddenQuestions,
    allActionsPlans,
  };
};
export default injectIntl(connect(mapStateToProps)(SwotManagement));
