import React from "react";
import InsightCommentSection from "../../../../../../Common/InsightCommentSection";
import { SKILLS_ASSESSMENT } from "../../../../../../../constants/tools.constants";

const WorkflowComments = ({
  selectedToolId,
  insight,
  loggedUser,
  tags,
  filter,
  showOnlyOwnerComments,
}) => {
  return (
    <InsightCommentSection
      insight={insight}
      loggedUser={loggedUser}
      tags={tags}
      filter={filter}
      selectedToolId={selectedToolId}
      selectedToolTypeId={SKILLS_ASSESSMENT}
      showOnlyOwnerComments={showOnlyOwnerComments}
    />
  );
};

export default WorkflowComments;
