export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const enqueueSnackbar = (key, notification = {}) => {
  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
      notification,
    },
  };
};

export const closeSnackbar = (key) => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = (key) => ({
  type: REMOVE_SNACKBAR,
  key,
});

export const simpleNotifier = (
  message,
  variant = "default",
  expansionContent = null,
  customKeyId = null,
) => {
  return (dispatch) => {
    dispatch(
      enqueueSnackbar(customKeyId || new Date().getTime() + Math.random(), {
        message,
        options: {
          closeSnackbar: (key) => dispatch(closeSnackbar(key)),
          variant,
          expansionContent,
        },
      }),
    );
  };
};
