import {
  UPDATE_ACTIONS_PLANS,
  UPDATE_SINGLE_ACTION_PLAN,
  DELETE_ACTION_FROM_PLAN,
} from "../actions/types";
import { updateAllList } from "../utils/reducerUtils";
import { getObjectInfoById } from "../utils/ArrayUtils";
import { deleteActionOnPlan } from "../utils/actionsPlan/entity";

const INITIAL_STATE = {
  all: [],
  loading: false,
};

const actionsPlanReducer = (state = INITIAL_STATE, action = {}) => {
  const { payload = {} } = action;
  const insightId = payload && payload.insightId ? payload.insightId : null;
  const actionId = payload && payload.actionId ? payload.actionId : null;

  function getCurrentPlan(insightId) {
    return getObjectInfoById(insightId, state.all, "insightId");
  }

  function updateSingleActionPlan(newActionPlan) {
    if (newActionPlan.insightId) {
      return updateAllList(state.all, [newActionPlan], "insightId");
    }

    return state.all;
  }

  function finishUpdate(newActionsPlans) {
    return {
      ...state,
      all: updateAllList(state.all, newActionsPlans, "insightId"),
      loading: false,
    };
  }

  const newActionsPlans = action.payload;

  switch (action.type) {
    case UPDATE_SINGLE_ACTION_PLAN:
      return {
        ...state,
        all: updateSingleActionPlan(action.payload),
      };
    case UPDATE_ACTIONS_PLANS:
      return finishUpdate(newActionsPlans);
    case DELETE_ACTION_FROM_PLAN:
      const currentPlan = getCurrentPlan(insightId);
      const updatedPlan = deleteActionOnPlan(currentPlan, actionId);

      return finishUpdate([updatedPlan]);
    default:
      return state;
  }
};

export default actionsPlanReducer;
