/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import _ from "lodash";
import moment from "moment";

import {
  getGoalsBonusStates,
  getSelectedToolStates,
} from "../../components/customMapStates";
import store from "../../Store";
import { getObjectInfoById, getUpdatedDeleteArrayByIndex } from "../ArrayUtils";
import { getValidDate } from "../DateUtils";
import {
  getDefaultFrequencyArray,
  getNewKpiDefaultOptions,
  goalEntityBuild,
  transformValuesArray,
} from "../kpis/entity";
import { floatNumber } from "../MathUtils";
import { getValidId } from "../validations";
import {
  getParticipantsLimitBonus,
  getTotalParticipantBonusInfo,
} from "./calc";
import { getGoalsBonusBySelectedToolId } from "./filter";

const currentTodayDate = new Date();
const currentFullYear = currentTodayDate.getFullYear();

export const getFormatedGoal = (goalInfo) => {
  const result = goalInfo;
  const { linkedIndicator } = goalInfo;

  if (linkedIndicator?.id && goalInfo?.id) {
    result.title = linkedIndicator.title;
    result.description = linkedIndicator.description;
    result.sourceDescription = linkedIndicator.sourceDescription;
    result.generalDetails = linkedIndicator.generalDetails;
    result.measurementUnity = linkedIndicator.measurementUnity;
    result.frequency = linkedIndicator.frequency;
    result.measurement = linkedIndicator.measurement;
    result.period = goalInfo.period || {};
    result.period.year = linkedIndicator.period.year;
    result.period.start = linkedIndicator.period.start;
    result.period.end = linkedIndicator.period.end;
    result.target = goalInfo.target || {};
    result.target.gate = linkedIndicator.target.gate;
    result.target.appropriated = linkedIndicator.target.appropriated;
    result.target.exceeded = linkedIndicator.target.exceeded;
    result.target.actual = transformValuesArray(
      linkedIndicator.target.actual,
      linkedIndicator.frequency
    );
    result.target.distribution = transformValuesArray(
      linkedIndicator.target.distribution,
      linkedIndicator.frequency
    );
    result.target.lastPeriod = transformValuesArray(
      linkedIndicator.target.lastPeriod,
      linkedIndicator.frequency
    );
    result.target.otherMeasurement = linkedIndicator.target.otherMeasurement;
  }

  return result;
};

export const buildAdmConfigBody = (
  goalBonusAdministration,
  defaultStringValue = "",
  defaultArrayValue = [],
  defaultIdValue = null
) => {
  const adminitration = goalBonusAdministration || {};

  let {
    id,
    dates = {},
    limitBonus,
    selectedToolId,
    periodCycle = {},
  } = adminitration;

  const {
    administrativeRoles = defaultArrayValue,
    roleBonuses = defaultArrayValue,
    goalTargets = {},
    participants = defaultArrayValue,
    goals = defaultArrayValue,
    teams = defaultArrayValue,
    triggers = defaultArrayValue,
    clearBonusGateMin = "RESET",
  } = adminitration;

  id = getValidId(id, defaultIdValue);
  selectedToolId = getValidId(selectedToolId, defaultIdValue);
  limitBonus = limitBonus > -1 ? limitBonus : defaultStringValue;

  goalTargets.gate = goalTargets.gate || defaultStringValue;
  goalTargets.appropriated = goalTargets.appropriated || defaultStringValue;
  goalTargets.exceeded = goalTargets.exceeded || defaultStringValue;

  dates = dates || {};
  dates.id = getValidDate(dates.id, defaultIdValue);
  dates.year = dates.year || defaultStringValue;
  dates.startRegistrations = getValidDate(
    dates.startRegistrations,
    defaultStringValue
  );
  dates.endRegistrations = getValidDate(
    dates.endRegistrations,
    defaultStringValue
  );
  dates.startApproval = getValidDate(dates.startApproval, defaultStringValue);
  dates.endApproval = getValidDate(dates.endApproval, defaultStringValue);
  dates.startAudit = getValidDate(dates.startAudit, defaultStringValue);
  dates.endAudit = getValidDate(dates.endAudit, defaultStringValue);

  periodCycle = periodCycle || {};
  periodCycle.start = periodCycle.start || 0;
  periodCycle.end = periodCycle.end || 11;

  for (const key in administrativeRoles) {
    administrativeRoles[key].id = getValidId(administrativeRoles[key].id);
    administrativeRoles[key].access =
      administrativeRoles[key].access || defaultArrayValue;
    administrativeRoles[key].members =
      administrativeRoles[key].members || defaultArrayValue;
    administrativeRoles[key].role =
      administrativeRoles[key].role || defaultArrayValue;
  }

  for (const key in roleBonuses) {
    roleBonuses[key].id = getValidId(roleBonuses[key].id);
    roleBonuses[key].gate = floatNumber(
      roleBonuses[key].gate,
      defaultStringValue
    );
    roleBonuses[key].appropriated = floatNumber(
      roleBonuses[key].appropriated,
      defaultStringValue
    );
    roleBonuses[key].exceeded = floatNumber(
      roleBonuses[key].exceeded,
      defaultStringValue
    );
    roleBonuses[key].role = roleBonuses[key].role || defaultStringValue;
    roleBonuses[key].roleAccess =
      roleBonuses[key].roleAccess || defaultArrayValue;
    roleBonuses[key].companyPosition = roleBonuses[key].companyPosition || null;
    roleBonuses[key].companyPositionId =
      roleBonuses[key].companyPositionId || defaultIdValue;
  }

  for (const key in participants) {
    participants[key].id = getValidId(participants[key].id);
    participants[key].memberParticipant =
      participants[key].memberParticipant || {};
    participants[key].memberParticipant.id =
      participants[key].memberParticipant.id || defaultIdValue;
    participants[key].memberParticipant.type =
      participants[key].memberParticipant.type || defaultStringValue;
    participants[key].base = participants[key].base || defaultStringValue;
    participants[key].period = participants[key].period || defaultStringValue;
    participants[key].roleBonus = participants[key].roleBonus || {};

    if (participants[key].roleBonus.id) {
      participants[key].roleBonus.id =
        participants[key].roleBonus.id || defaultIdValue;
      participants[key].roleBonus.role =
        participants[key].roleBonus.role || defaultStringValue;
      participants[key].roleBonus.roleAccess =
        participants[key].roleBonus.roleAccess || defaultArrayValue;
      participants[key].roleBonus.roleViewAccess =
        participants[key].roleBonus.roleViewAccess || defaultArrayValue;
    } else {
      participants[key].roleBonus = null;
    }
  }

  for (const key in triggers) {
    triggers[key].id = getValidId(triggers[key].id);
    triggers[key].title = triggers[key].title || defaultStringValue;
    triggers[key].description = triggers[key].description || defaultStringValue;
  }

  for (const key in goals) {
    const formatedGoal = getFormatedGoal(goals[key]);

    goals[key] = goalEntityBuild(
      formatedGoal,
      defaultStringValue,
      defaultArrayValue
    );
    goals[key].workflowStatus = formatedGoal.workflowStatus || "CREATING";
  }

  for (const key in teams) {
    teams[key].id = getValidId(teams[key].id);
    teams[key].name = teams[key].name || defaultStringValue;
  }

  return {
    id,
    administrativeRoles,
    roleBonuses,
    goalTargets,
    participants,
    teams,
    triggers,
    dates,
    periodCycle,
    goals,
    clearBonusGateMin,
    limitBonus,
    selectedToolId,
  };
};

export const buildFormAdmConfigBody = (goalBonusAdministration) => {
  return buildAdmConfigBody(goalBonusAdministration, "", []);
};

export const buildRestAdmConfigBody = (goalBonusAdministration) => {
  return buildAdmConfigBody(goalBonusAdministration, null, []);
};

export const getGoalsBonusBySelectedTool = (
  selectedToolId,
  allGoalBonusAdministration = []
) => {
  let finalReturn = {};

  allGoalBonusAdministration.forEach((goalsBonusAdmConfig) => {
    if (goalsBonusAdmConfig.selectedToolId === selectedToolId)
      finalReturn = buildFormAdmConfigBody(goalsBonusAdmConfig);
  });

  return finalReturn;
};

export const buildRoleBonusesOptions = (roleBonuses = []) => {
  const options = [];

  if (roleBonuses && roleBonuses.length > 0) {
    roleBonuses.forEach((goalTargetsInfo) => {
      options.push({ value: goalTargetsInfo.id, label: goalTargetsInfo.role });
    });
  }

  return options;
};

export async function buildGoalsAdministrationFinalBody(
  goalBonusAdministration,
  defaultArrayValue = []
) {
  const administrationEntity = buildFormAdmConfigBody(goalBonusAdministration);
  const { goals = defaultArrayValue } = administrationEntity;

  for (const key in goals) {
    goals[key].target.actual = goals[key].target.actual || defaultArrayValue;
    goals[key].target.actual =
      goals[key].target.actual.length > 0
        ? transformValuesArray(goals[key].target.actual, goals[key].frequency)
        : getDefaultFrequencyArray(goals[key].frequency);
    goals[key].target.distribution = transformValuesArray(
      goals[key].target.distribution,
      goals[key].frequency
    );
    goals[key].target.lastPeriod = transformValuesArray(
      goals[key].target.lastPeriod,
      goals[key].frequency
    );
  }

  return {
    ...administrationEntity,
    goals,
  };
}

export const buildGoalsAdministrationDefaultSave = () => {
  const defaultInitialRestSave = buildRestAdmConfigBody({});

  const startDate = moment(new Date(currentFullYear, 0, 1)).format(
    "YYYY-MM-DD"
  );
  const endDate = moment(new Date(currentFullYear, 11, 31)).format(
    "YYYY-MM-DD"
  );

  return {
    ...defaultInitialRestSave,
    goalTargets: {
      ...defaultInitialRestSave.goalTargets,
      gate: 70,
      appropriated: 100,
      exceeded: 130,
    },
    dates: {
      ...defaultInitialRestSave.dates,
      startRegistrations: startDate,
      endRegistrations: endDate,
      startApproval: startDate,
      endApproval: endDate,
      startAudit: startDate,
      endAudit: endDate,
      year: currentFullYear,
    },
  };
};

export const inputAdminUserOnGoalsAdministration = (
  defaultInitialRestSave,
  loggedUserId
) => {
  return {
    ...defaultInitialRestSave,
    administrativeRoles: [
      {
        id: null,
        role: "ADMIN",
        members: [loggedUserId],
        access: ["ADMIN"],
      },
    ],
  };
};

export const getNewGoalDefaultOptions = (periodCycle = {}) => {
  const indicatorDefault = getNewKpiDefaultOptions(periodCycle);
  return { ...indicatorDefault, workflowStatus: "CREATING" };
};

export const buildParticipantsBonusInfo = (
  goalBonusAdministration,
  participantsRelation = [],
  periodFilterActive
) => {
  const buildingRelation = [];
  let allTotalBonus = 0;

  if (participantsRelation.length > 0) {
    participantsRelation.forEach((relationInfo) => {
      const { participantInfo, participantGoals } = relationInfo;

      const {
        reachBonusWeightSum,
        realGoalsReachSum,
        aditionalSalaries,
        totalBonus,
        barMeasurement,
        barBonusMeasurement,
      } = getTotalParticipantBonusInfo(
        participantInfo,
        participantGoals,
        goalBonusAdministration.goalTargets,
        goalBonusAdministration.clearBonusGateMin,
        periodFilterActive
      );

      allTotalBonus += parseFloat(totalBonus);

      buildingRelation.push({
        ...relationInfo,
        bonusInfo: {
          reachBonusWeightSum,
          realGoalsReachSum,
          aditionalSalaries,
          totalBonus,
          barMeasurement,
          barBonusMeasurement,
        },
      });
    });
  }

  const limitBonusCompleteInfo = getParticipantsLimitBonus(
    goalBonusAdministration.limitBonus,
    allTotalBonus,
    buildingRelation
  );

  const finalRelation = limitBonusCompleteInfo.participants;

  return {
    allTotalBonus,
    participants: finalRelation,
    totalLimitBonus: limitBonusCompleteInfo.totalLimitBonus,
  };
};

export const getCurrentToolConfig = () => {
  const state = store.getState();

  const { user } = state;

  const { allGoalBonusAdministration } = getGoalsBonusStates(state);
  const { selectedTool = {} } = getSelectedToolStates(state);

  let toolGoalsBonusAdm = getGoalsBonusBySelectedToolId(
    selectedTool.id,
    allGoalBonusAdministration
  );
  toolGoalsBonusAdm = buildFormAdmConfigBody(toolGoalsBonusAdm);

  return { user, toolGoalsBonusAdm, allGoalBonusAdministration, selectedTool };
};

export const deleteRowOnAdministration = (
  currentAdministration,
  parentName = "",
  itemId,
  searchForSlug = "id"
) => {
  const validAdministration = _.cloneDeep(currentAdministration);
  const parentArray = currentAdministration[parentName];

  if (validAdministration.id && Array.isArray(parentArray)) {
    const itemIndex = getObjectInfoById(
      itemId,
      parentArray,
      searchForSlug,
      true
    );

    const updatedParent = getUpdatedDeleteArrayByIndex(parentArray, itemIndex);

    return {
      ...validAdministration,
      [parentName]: updatedParent,
    };
  }

  return validAdministration;
};

export const deleteGoalOnAdministration = (currentAdministration, goalId) => {
  return deleteRowOnAdministration(currentAdministration, "goals", goalId);
};

export const deleteTriggerOnAdministration = (
  currentAdministration,
  triggerId
) => {
  return deleteRowOnAdministration(
    currentAdministration,
    "triggers",
    triggerId
  );
};

export const deleteAdministrativeRoleOnAdministration = (
  currentAdministration,
  administrativeRoleId
) => {
  return deleteRowOnAdministration(
    currentAdministration,
    "administrativeRoles",
    administrativeRoleId
  );
};

export const deleteRoleBonusOnAdministration = (
  currentAdministration,
  roleBonusId
) => {
  return deleteRowOnAdministration(
    currentAdministration,
    "roleBonuses",
    roleBonusId
  );
};

export const deleteParticipantOnAdministration = (
  currentAdministration,
  participantId
) => {
  return deleteRowOnAdministration(
    currentAdministration,
    "participants",
    participantId
  );
};

export const deleteTeamOnAdministration = (currentAdministration, teamId) => {
  return deleteRowOnAdministration(currentAdministration, "teams", teamId);
};
