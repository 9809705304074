//import * as actions from "./_actions";
import send from "../../dispatcher";
import * as actions from "./_actions";

function observeCompetitiveAnalysis({ stompClient, dispatch }) {
  stompClient.subscribe(
    "/insights/addCompetitiveAnalysisEvaluation",
    (response) => {
      send(actions.postToolEvaluation, response, dispatch);
    },
  );

  stompClient.subscribe(
    "/competitive-analysis-evaluation/update",
    (response) => {
      send(actions.putToolEvaluation, response, dispatch);
    },
  );

  stompClient.subscribe(
    "/competitive-analysis-evaluations/addCompanyDetails",
    (response) => {
      send(actions.postCompanyDetails, response, dispatch);
    },
  );

  stompClient.subscribe("/company-details/update", (response) => {
    send(actions.putCompanyDetails, response, dispatch);
  });

  stompClient.subscribe("/competitive-analysis/cenary", (response) => {
    send(actions.postBoardCenaryLink, response, dispatch);
  });

  stompClient.subscribe("/competitive-analysis/theme", (response) => {
    send(actions.postBoardThemeLink, response, dispatch);
  });

  stompClient.subscribe(
    "/competitive-analysis/createQuestionsForFactors",
    (response) => {
      send(actions.createFactorsQuestions, response, dispatch);
    },
  );

  stompClient.subscribe("/competitive-analysis/question", (response) => {
    send(actions.postBoardQuestionLink, response, dispatch);
  });

  stompClient.subscribe(
    "/selected-tools/addCompetitiveAnalysisConfiguration",
    (response) => {
      send(actions.postToolConfiguration, response, dispatch);
    },
  );

  stompClient.subscribe(
    "/competitive-analysis-configurations/update",
    (response) => {
      send(actions.putToolConfiguration, response, dispatch);
    },
  );

  stompClient.subscribe("/competitive-analysis/insight", (response) => {
    send(actions.postInsightGroupLink, response, dispatch);
  });
}

export default observeCompetitiveAnalysis;
