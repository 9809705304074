import React from "react";
import AnalysisAmbient from "../fields/Concurrence/AnalysisAmbient";
import EvaluationCriterias from "../fields/Concurrence/EvaluationCriterias";
import AnalysisAreas from "../fields/Concurrence/AnalysisAreas";
import Scales from "../fields/Concurrence/Scales";
import {
  showFieldCaption,
  getConcurrenceFieldDescription,
} from "../../../../../../utils/cpAnalysis/display";

const ConcurrenceTabView = (props) => {
  const {
    editable_no_max_length,
    editable_with_max_length,
    editable_with_pre_length,
  } = getConcurrenceFieldDescription();

  return (
    <div className="tab-content" style={{ margin: "0px" }}>
      <div className="row">
        <div className="col-xs-12 col-lg-6">
          <div className="flex pestalTabViewDiv" title={editable_no_max_length}>
            <h4>Ambiente de Análise</h4>
            <div>{showFieldCaption(true, "-")}</div>
          </div>
          <AnalysisAmbient {...props} />
        </div>
        <div className="col-xs-12 col-lg-6">
          <div className="flex pestalTabViewDiv" title={editable_no_max_length}>
            <h4>Área da Análise</h4>
            <div>{showFieldCaption(true, "-")}</div>
          </div>
          <AnalysisAreas {...props} />
        </div>
      </div>
      <div className="row margin20">
        <div className="col-xs-12 col-lg-6">
          <div
            className="flex pestalTabViewDiv"
            title={editable_with_max_length}
          >
            <h4>Atributos de Avaliação</h4>
            <div>{showFieldCaption(true, 10)}</div>
          </div>
          <EvaluationCriterias {...props} />
        </div>
        <div className="col-xs-12 col-lg-6">
          <div
            className="flex pestalTabViewDiv"
            title={editable_with_pre_length}
          >
            <h4>Escala</h4>
            <div>{showFieldCaption(true, 6, false)}</div>
          </div>
          <Scales {...props} />
        </div>
      </div>
    </div>
  );
};

export default ConcurrenceTabView;
