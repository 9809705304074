import axios from "axios";

import {
  FETCH_ACTION_PLANS,
  UPDATE_ACTION_DATE,
  FETCH_ALL_ACTION_PLANS,
  UPDATE_ACTION_DATE_RESPONSIBLE,
  FETCH_ACTION_PLANS_RESPONSIBLES,
} from "../constants/toolActionPlans";

import utils from "../utils/toolUtils";
import actionsPlanUtils from "../utils/actionsPlan";

const { getToolCategoryUpdatedInfo } = utils;
const { getActionPerformance } = actionsPlanUtils;

const transformActionPlansByTools = (actionPlansTools) => {
  const finalActionPlans = [];

  Object.keys(actionPlansTools).forEach((toolId) => {
    const finalActions = [];
    let tool = {};
    let actionPlanId = null;

    actionPlansTools[toolId].forEach((actionPlanTool) => {
      finalActions.push({
        ...actionPlanTool.action,
        insight: { ...actionPlanTool.insight },
        responsible: { ...actionPlanTool.responsible },
        traceability: { ...actionPlanTool.traceability },
      });

      tool = {
        ...actionPlanTool.tool,
        ...getToolCategoryUpdatedInfo(actionPlanTool.tool),
      };
      actionPlanId = actionPlanTool.actionPlanId;
    });

    finalActionPlans.push({
      ...tool,
      actions: finalActions,
      actionPlanId,
    });
  });

  return finalActionPlans;
};

const transformActionPlansByResponsible = (actionPlansResponsible) => {
  const finalActionPlans = [];

  Object.keys(actionPlansResponsible).forEach((responsibleId) => {
    const finalActions = [];
    let responsible = {};
    let actionPlanId = null;

    actionPlansResponsible[responsibleId].forEach((actionPlanTool) => {
      finalActions.push({
        ...actionPlanTool.action,
        insight: { ...actionPlanTool.insight },
        tool: {
          ...actionPlanTool.tool,
          ...getToolCategoryUpdatedInfo(actionPlanTool.tool),
        },
        traceability: { ...actionPlanTool.traceability },
        responsible: actionPlanTool.responsible,
      });

      responsible = actionPlanTool.responsible;
      actionPlanId = actionPlanTool.actionPlanId;
    });

    finalActionPlans.push({
      ...responsible,
      actionPlanId,
      actions: finalActions,
    });
  });

  return finalActionPlans;
};

export const fetchActionPlans = (workspaceId, groupBy) => {
  return async (dispatch) => {
    const response = await axios.get(
      `/api/action-plans/workspace/${workspaceId}/${groupBy}`,
    );

    if (groupBy === "TOOL") {
      return dispatch({
        type: FETCH_ACTION_PLANS,
        payload: transformActionPlansByTools(response.data),
      });
    }

    return dispatch({
      type: FETCH_ACTION_PLANS_RESPONSIBLES,
      payload: transformActionPlansByResponsible(response.data),
    });
  };
};

export const updateActionDate = (actionDates, groupBy) => {
  return async (dispatch) => {
    const response = await axios.put(
      "/api/action-plan-actions/updateStatusAndDates",
      actionDates,
    );

    if (groupBy === "TOOL") {
      return dispatch({ type: UPDATE_ACTION_DATE, payload: response.data });
    }

    if (groupBy === "RESPONSIBLE") {
      return dispatch({
        type: UPDATE_ACTION_DATE_RESPONSIBLE,
        payload: response.data,
      });
    }

    return undefined;
  };
};

export const fetchAllActionPlansByCompanyId = async (companyId) => {
  return async (dispatch) => {
    if (companyId) {
      const response = await axios.get(
        `/api/action-plans/${companyId}/overview`,
      );

      return dispatch({
        type: FETCH_ALL_ACTION_PLANS,
        payload: response?.data,
      });
    }
  };
};

export const getOverviewActionPlans = (data) => {
  const someActions = (acumm, actual) => {
    if (actual.actions.length) {
      return acumm + actual.actions.length;
    }

    return acumm;
  };

  const somedActions = data.reduce(someActions, 0);

  const toolsActionsSomed = {};

  data.forEach((action) => {
    if (toolsActionsSomed[action.tool.id]) {
      toolsActionsSomed[action.tool.id] = {
        completed:
          toolsActionsSomed[action.tool.id].completed +
          action.actions.reduce((acum, actual) => {
            if (actual.status === "COMPLETE") {
              return acum + 1;
            }

            return acum;
          }, 0),
        totalActions:
          toolsActionsSomed[action.tool.id].totalActions +
          action.actions.length,
      };
    } else {
      toolsActionsSomed[action.tool.id] = {
        completed: action.actions.reduce((acum, actual) => {
          if (actual.status === "COMPLETE") {
            return acum + 1;
          }

          return acum;
        }, 0),
        totalActions: action.actions.length,
      };
    }
  });

  const actionByProgress = {
    out: [],
    within: [],
  };

  const actionByComplete = {
    out: [],
    within: [],
  };

  data.forEach((plans) => {
    plans.actions.forEach((action) => {
      if (action.status === "IN_PROGRESS") {
        const actionPerformance = getActionPerformance(action);

        if (actionPerformance.status === "OUT") {
          actionByProgress.out.push(actionPerformance.status);
        }

        if (actionPerformance.status === "WITHIN_DEADLINE") {
          actionByProgress.within.push(actionPerformance.status);
        }
      }

      if (action.status === "COMPLETE") {
        const actionPerformance = getActionPerformance(action);

        if (actionPerformance.status === "OUT") {
          actionByComplete.out.push(actionPerformance.status);
        }

        if (actionPerformance.status === "WITHIN_DEADLINE") {
          actionByComplete.within.push(actionPerformance.status);
        }
      }
    });
  });

  const dataOverview = {
    numberActions: somedActions,
    toolsActionsSomed,
    deadline: {
      actionByProgress,
      actionByComplete,
    },
  };

  return dataOverview;
};
