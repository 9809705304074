import moment from "moment";

export const apiErrorInterceptor = (instances) => {
  instances.forEach((instance) => {
    instance.interceptors.response.use(undefined, (error) => {
      const stackTrace = `${error} - ${error?.config?.method} ${error?.config?.url}
        - ${error?.config?.baseURL}`;
      console.log("API ERROR: ", { stackTrace, dateTime: moment() });
    });
  });
};

export function setInterceptorInInstanceAxios(instances) {
  const token = localStorage.getItem("splace-authenticationToken");

  if (token) {
    apiErrorInterceptor(instances);
  }
}

export const set401InterceptorInAxiosInstances = (instances) => {
  instances.forEach((instance) => {
    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise(() => {
          if (
            err &&
            err.response &&
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            window.location.replace("/#/login");
            window.location.reload();
          }
          if (err.response && err.response.data) {
            const { status, message } = err.response.data;
            throw {
              status,
              message,
            };
          } else {
            throw err;
          }
        });
      }
    );
  });
};
