import axios from "axios";
import {
  FETCH_ALL_ACTIVITY_INFORMATION,
  UPDATE_ACTIVITY_INFORMATION,
} from "./types";
import { makeObjLevelAnArray } from "../utils/reducerUtils";

const getInsightActivity = (insightID) => {
  return axios
    .get(`/api/insight/${insightID}/activityInformation`)
    .then((res) => res);
};

const updateInsightAcitivity = (body) => {
  return axios.put("/api/activity-informations", body).then((res) => res);
};

export const updateActivityInformation = (insightID, body) => {
  return (dispatch) => {
    const addAcitivity = () => {
      axios
        .post(`/api/insights/${insightID}/activityInformation`, body)
        .then((res) => {
          dispatch({
            type: UPDATE_ACTIVITY_INFORMATION,
            payload: { activityInformation: res.data, insightID },
          });
        });
    };

    getInsightActivity(insightID).then((res) => {
      if (res.data) {
        body = {
          ...body,
          id: res.data.id,
        };

        updateInsightAcitivity(body).then((res) => {
          dispatch({
            type: UPDATE_ACTIVITY_INFORMATION,
            payload: { activityInformation: res.data, insightID },
          });
        });
      } else {
        addAcitivity();
      }
    });
  };
};

export const fetchAllActivityInformations = (selectedToolIds = []) => {
  return async (dispatch) => {
    const activityInformations = await axios
      .get(
        `/api/selected-tools/allInsightsActivitiesInformations/${selectedToolIds}`,
      )
      .then((res) => res.data);

    dispatch({
      type: FETCH_ALL_ACTIVITY_INFORMATION,
      payload: makeObjLevelAnArray(activityInformations),
    });
  };
};

export const fetchAllActivityInformation = (selectedToolID) => {
  return async (dispatch) => {
    const fechedRes = await axios.get(
      `/api/selected-tools/${selectedToolID}/insightsActivitiesInformations`,
    );

    dispatch({ type: FETCH_ALL_ACTIVITY_INFORMATION, payload: fechedRes.data });
  };
};
