import React from "react";
import PropTypes from "prop-types";

const SimpleLockButton = ({ locked, onToggle, styles, titles }) => {
  return (
    <span
      onClick={onToggle}
      style={{ cursor: "pointer", ...styles }}
      title={locked ? titles.on : titles.off}
    >
      {locked && <i className="fas fa-lock" />}
      {!locked && <i className="fas fa-lock-open" />}
    </span>
  );
};

SimpleLockButton.propTypes = {
  locked: PropTypes.bool,
  onToggle: PropTypes.func,
  styles: PropTypes.object,
  titles: PropTypes.object,
};

SimpleLockButton.defaultProps = {
  locked: true,
  onToggle: () => {},
  styles: {},
  titles: {
    on: null,
    off: null,
  },
};

export default SimpleLockButton;
