import React, { useCallback } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { TextField, InputAdornment } from "@material-ui/core";

const StyledTextField = styled(TextField)`
  background-color: inherit;
  .MuiOutlinedInput-root {
    fieldset {
      border-color: ${(props) => props.fildsetdefaultborder || "#ccc"};
    }
    &:hover fieldset {
      border-color: ${(props) => props.fildsetHoverBorder || "#999"};
    }
    &.Mui-focused fieldset {
      border-color: ${(props) => props.fildsetFocusedBorder || "#6b42a9"};
    }
  }
`;

const TextMaxLength = styled.span`
  font-weight: 500;
  font-size: 13px;
  background-color: #fff;

  ${(props) =>
    props.isMaxLength &&
    css`
      color: red;
    `}
`;

const MaterialTextField = (props) => {
  let { secondaryInputProps, primaryInputProps } = props;

  const {
    name,
    endAdorment = {},
    inputStyle,
    margin,
    readOnly,
    autoComp = "on",
    selectText,
    placeholder,
    size,
    value,
    onChange,
    autoFocus,
    labelProps = {},
    error = false,
    helperText,
  } = props;

  let inputLabelProps = {
    style: { fontSize: "16px", backgroundColor: "inherit", ...labelProps },
  };

  secondaryInputProps = {
    ...secondaryInputProps,
    style: {
      fontSize: "16px",
      lineHeight: "18px",
      minHeight: "18px",
      textAlign: props.textAlign,
      ...inputStyle,
    },
    onClick: (e) => {
      if (selectText) e.target.select();
    },
  };

  if (
    props.type === "date" ||
    props.type === "time" ||
    (props.value && props.value.length > 0)
  ) {
    /// Label Fixo
    inputLabelProps = {
      ...inputLabelProps,
      shrink: true,
    };
  }

  const domField = document.getElementById(props.id);
  if (domField && props.multiline) {
    secondaryInputProps = {
      ...secondaryInputProps,
      style: {
        ...secondaryInputProps.style,
        height: domField.scrollHeight,
      },
    };
  }

  if (endAdorment.text) {
    primaryInputProps = {
      ...primaryInputProps,
      endAdornment: (
        <InputAdornment
          position={endAdorment.position || "end"}
          disableTypography
        >
          <span style={{ fontSize: endAdorment.fontSize || "16px" }}>
            {endAdorment.text}
          </span>
        </InputAdornment>
      ),
    };
  }

  const additionalActionProps = {
    onBlur: props.onBlur,
    onClick: props.onClick,
    onKeyDown: props.onKeyDown,
  };

  const countMaxString = useCallback((string, maxLength) => {
    const stringLength = string.length;

    if (stringLength && maxLength) {
      return `${stringLength}/${maxLength}`;
    }

    return "";
  }, []);

  const limitExceededString = useCallback(
    (e, maxLength) => {
      const { value } = e.target;

      if (value.length >= maxLength) {
        e.target.value = value.substring(0, maxLength);
      }

      onChange(e);
    },
    [onChange]
  );

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "column",
        alignItems: "flex-end",
        backgroundColor: "inherit",
      }}
    >
      <StyledTextField
        id={props.id}
        label={!props.hideLabel ? props.label : null}
        value={props.value}
        readOnly={readOnly}
        margin={margin}
        autoComplete={autoComp}
        name={name}
        variant={props.variant}
        fullWidth={props.fullWidth}
        type={props.type}
        size={size}
        select={props.select}
        placeholder={placeholder || ""}
        InputLabelProps={{
          ...inputLabelProps,
        }}
        InputProps={{
          inputProps: secondaryInputProps,
          ...primaryInputProps,
        }}
        className={props.className}
        multiline={props.multiline}
        minRows={props.rows}
        disabled={props.disabled}
        required={props.required}
        onChange={(e) =>
          primaryInputProps?.maxLength
            ? limitExceededString(e, primaryInputProps?.maxLength)
            : onChange(e)
        }
        {...additionalActionProps}
        {...props.styled}
        autoFocus={autoFocus}
        error={error}
        helperText={helperText}
      >
        {props.children ? { ...props.children } : null}
      </StyledTextField>
      {!!primaryInputProps?.maxLength && value && (
        <TextMaxLength
          isMaxLength={value.length >= primaryInputProps?.maxLength}
        >
          {countMaxString(value, primaryInputProps?.maxLength)}
        </TextMaxLength>
      )}
    </div>
  );
};

MaterialTextField.propTypes = {
  id: PropTypes.any,
  margin: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.any,
  type: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  select: PropTypes.bool,
  size: PropTypes.string,
  multiline: PropTypes.bool,
  hideLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  textAlign: PropTypes.string,
  rows: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autoComp: PropTypes.string,
  children: PropTypes.node,
  inputStyle: PropTypes.object,
  styled: PropTypes.object,
  primaryInputProps: PropTypes.shape({
    maxLength: PropTypes.number,
  }),
  secondaryInputProps: PropTypes.object,
  endAdorment: PropTypes.shape({
    text: PropTypes.string,
    position: PropTypes.oneOf(["end", "start"]),
    fontSize: PropTypes.string,
  }),
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  selectText: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  labelProps: PropTypes.object,
};

MaterialTextField.defaultProps = {
  id: "",
  value: "",
  label: undefined,
  name: "",
  children: null,
  placeholder: "",
  readOnly: false,
  autoFocus: false,
  autoComp: "on",
  variant: "outlined",
  margin: "normal",
  fullWidth: true,
  size: "small",
  type: "text",
  select: false,
  multiline: false,
  rows: 0,
  onChange: () => {},
  onBlur: () => {},
  onClick: () => {},
  onKeyDown: () => {},
  hideLabel: false,
  textAlign: "left",
  required: false,
  disabled: false,
  inputStyle: {},
  styled: {},
  primaryInputProps: {},
  secondaryInputProps: {},
  endAdorment: {
    text: null,
    position: "end",
    fontSize: "16px",
  },
  className: "",
  selectText: false,
  error: false,
  helperText: "",
  labelProps: {},
};

export default MaterialTextField;
