import styled from "styled-components";

export const DisplayTextView = styled.div`
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: flex-start;

  .iconEdit {
    visibility: hidden;
    margin: 3px 0 0 5px;
    cursor: pointer;
  }

  &:hover .iconEdit {
    visibility: visible;
  }
`;

export const TextView = styled.span`
  width: 100%;
  word-break: break-all;
  margin: 0 5px;
`;

export const WrapperEditable = styled.div`
  width: 100%;

  .MuiOutlinedInput-inputMarginDense {
    padding: 5px;
  }
`;

export const DisplayButtons = styled.div`
  width: 100%;
  margin: 5px 0 10px 0px;

  display: flex;
  justify-content: flex-start;

  button {
    border: 1px solid #dedede;
    font-weight: 600;
    padding: 5px;
    border-radius: 4px;
    transition: 0.2s;
    cursor: pointer;
    padding: 5px 10px;
  }
`;

export const CancelButton = styled.button`
  background: #fff;
  margin-right: 5px;

  &:hover {
    background: #dae0e5;
  }
`;

export const SaveButton = styled.button`
  background: #28a745;
  color: #fff;

  &:hover {
    background: #1e7e34;
  }
`;
