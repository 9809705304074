import _ from "lodash";
import { updateAllList } from "../utils/reducerUtils";
import { getObjectInfoById } from "../utils/ArrayUtils";
import gbUtils from "../utils/goalsBonus";
import {
  UPDATE_GOALS_BONUS_CONFIGURATION,
  UPDATE_ALL_GOALS_BONUS_CONFIGURATIONS,
  DELETE_GOAL,
  DELETE_TRIGGER,
  DELETE_ADMINISTRATIVE_ROLE,
  DELETE_ROLE_BONUS,
  DELETE_PARTICIPANT,
  DELETE_TEAM,
} from "../actions/types";

const {
  deleteGoalOnAdministration,
  deleteTriggerOnAdministration,
  deleteAdministrativeRoleOnAdministration,
  deleteRoleBonusOnAdministration,
  deleteParticipantOnAdministration,
  deleteTeamOnAdministration,
} = gbUtils;

const INITIAL_STATE = {
  all: [],
  loading: false,
};

const goalsBonusReducer = (state = INITIAL_STATE, action) => {
  const isLoading =
    action.loading === true || action.loading === false
      ? action.loading
      : state.loading;

  const { payload = {} } = action;

  const administrationId =
    payload && payload.administrationId ? payload.administrationId : null;
  let currentAdministration = null;

  function updateAdministration(newPayload) {
    if (newPayload.selectedToolId) {
      return updateAllList(state.all, [newPayload], "selectedToolId");
    }

    return state.all;
  }

  function finishDelete(newPayload) {
    if (newPayload && newPayload.selectedToolId) {
      const updatedAll = updateAdministration(newPayload);
      return {
        ...state,
        all: updatedAll,
        loading: false,
      };
    }

    return state;
  }

  function getCurrentAdministration(administrationId) {
    const clonedAllState = _.cloneDeep([...state.all]);

    return getObjectInfoById(administrationId, clonedAllState, "id");
  }

  switch (action.type) {
    case UPDATE_GOALS_BONUS_CONFIGURATION:
      if (action.payload) {
        const newPayload = _.cloneDeep(action.payload);
        const updatedAll = updateAdministration(newPayload);
        return {
          ...state,
          all: updatedAll,
          loading: isLoading,
        };
      }

      return {
        ...state,
        loading: isLoading,
      };
    case UPDATE_ALL_GOALS_BONUS_CONFIGURATIONS:
      const newData = _.cloneDeep(action.payload);
      const updatedAll = updateAllList(state.all, newData, "selectedToolId");

      return {
        ...state,
        all: updatedAll,
        loading: isLoading,
      };
    case DELETE_GOAL:
      const { goalId } = action.payload;
      currentAdministration = getCurrentAdministration(administrationId);

      return finishDelete(
        deleteGoalOnAdministration(currentAdministration, goalId),
      );
    case DELETE_TRIGGER:
      const { triggerId } = action.payload;
      currentAdministration = getCurrentAdministration(administrationId);

      return finishDelete(
        deleteTriggerOnAdministration(currentAdministration, triggerId),
      );
    case DELETE_ADMINISTRATIVE_ROLE:
      const { administrativeRoleId } = action.payload;
      currentAdministration = getCurrentAdministration(administrationId);

      return finishDelete(
        deleteAdministrativeRoleOnAdministration(
          currentAdministration,
          administrativeRoleId,
        ),
      );
    case DELETE_ROLE_BONUS:
      const { roleBonusId } = action.payload;
      currentAdministration = getCurrentAdministration(administrationId);

      return finishDelete(
        deleteRoleBonusOnAdministration(currentAdministration, roleBonusId),
      );
    case DELETE_PARTICIPANT:
      const { participantId } = action.payload;
      currentAdministration = getCurrentAdministration(administrationId);

      return finishDelete(
        deleteParticipantOnAdministration(currentAdministration, participantId),
      );
    case DELETE_TEAM:
      const { teamId } = action.payload;
      currentAdministration = getCurrentAdministration(administrationId);

      return finishDelete(
        deleteTeamOnAdministration(currentAdministration, teamId),
      );
    default:
      return state;
  }
};

export default goalsBonusReducer;
