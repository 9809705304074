const INITITAL_STATE = null;

const redirect = (state = INITITAL_STATE, action) => {
  switch (action.type) {
    case "REDIRECT":
      return action.payload;
    default:
      return state;
  }
};

export default redirect;
