const en = {
  home_card_one_title: "Don't have an account yet?",
  home_card_two_title: "New to the platform?",
  home_card_three_title: "Do you already have an account?",
  home_card_one_description:
    "Create your account here and start using the tools we created for you and your team today.",
  home_card_two_description:
    "Know how our system works and all the powerful tools that will improve your company's performance and strategy.",
  home_card_three_description:
    "Log in with your team to start your day of high productivity and teamwork.",
  home_card_one_btn: "Create new account",
  home_card_two_btn: "I want to know more",
  home_card_three_btn: "Sign in",
  title_org_chart: "Organizational Chart",
};

const es = {
  home_card_one_title: "¿Todavía no tienes una cuenta?",
  home_card_two_title: "¿Nuevo en la plataforma?",
  home_card_three_title: "¿Ya tienes una cuenta?",
  home_card_one_description:
    "Cree su cuenta aquí y comience a usar las herramientas que creamos para usted y su equipo hoy",
  home_card_two_description:
    "Sepa cómo funciona nuestro sistema y todas las herramientas poderosas que mejorarán el rendimiento y la estrategia de su empresa",
  home_card_three_description:
    "Inicie sesión con su equipo para comenzar su día de alta productividad y trabajo en equipo",
  home_card_one_btn: "Crear nueva cuenta",
  home_card_two_btn: "Quiero saber más",
  home_card_three_btn: "Iniciar sesión",
  title_org_chart: "Organigrama",
};

const pt = {
  home_card_one_title: "Ainda não tem conta?",
  home_card_two_title: "Novo na plataforma?",
  home_card_three_title: "Já tem conta?",
  home_card_one_description:
    "Crie aqui sua conta e começe hoje mesmo a utilizar as ferramentas que criamos para você e sua equipe.",
  home_card_two_description:
    "Conheça como funciona nosso sistema e todas as poderosas ferramentas que irão melhorar o desempenho e estratégia da sua empresa.",
  home_card_three_description:
    "Faça login para junto de sua equipe iniciar seu dia de alta produtividade e trabalho em equipe.",
  home_card_one_btn: "Criar nova conta",
  home_card_two_btn: "Quero conhecer mais",
  home_card_three_btn: "Fazer login",
  title_org_chart: "Organograma",
};

const translations = { en, es, pt };

export default translations;
