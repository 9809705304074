import _ from "lodash";
import {
  UPDATE_KPI_CONFIGURATION,
  UPDATE_ALL_KPI_CONFIGURATIONS,
  KPI_DELETE_SCENARIO,
  KPI_DELETE_GROUP,
  KPI_DELETE_PROCESS,
  KPI_DELETE_GOAL,
  KPI_MASS_UPDATE,
  KPI_SET_SELECTED_GOAL,
} from "../actions/types";
import { getObjectInfoById } from "../utils/ArrayUtils";
import { updateAllList } from "../utils/reducerUtils";
import kpiUtils from "../utils/kpis";

const {
  buildKpiAdministrationFinalBody,
  buildFormAdmConfigBody,
  deleteScenarioOnAdminitration,
  deleteThemeOnAdminitration,
  deleteSubthemeOnAdminitration,
  deleteGoalOnAdministration,
} = kpiUtils;

const INITIAL_STATE = {
  all: [],
  loading: false,
  selectedGoal: {},
};

const kpiReducer = (state = INITIAL_STATE, action) => {
  const isLoading =
    action.loading === true || action.loading === false
      ? action.loading
      : state.loading;

  const administrationId =
    action.payload && action.payload.administrationId
      ? action.payload.administrationId
      : null;

  let currentAdministration = null;

  function getCurrentAdministration(administrationId) {
    const clonedAllState = _.cloneDeep([...state.all]);

    return getObjectInfoById(administrationId, clonedAllState, "id");
  }

  function updateAdministration(newPayload) {
    if (newPayload.selectedToolId) {
      return updateAllList(state.all, [newPayload], "selectedToolId");
    }

    return state.all;
  }

  function finishDelete(newPayload) {
    if (newPayload && newPayload.selectedToolId) {
      return {
        ...state,
        all: updateAdministration(newPayload),
        loading: false,
      };
    }

    return state;
  }

  switch (action.type) {
    case UPDATE_KPI_CONFIGURATION:
      if (action.payload) {
        const newPayload = _.cloneDeep(action.payload);
        const updatedAll = updateAdministration(newPayload);

        return {
          ...state,
          all: updatedAll,
          loading: isLoading,
        };
      }

      return {
        ...state,
        loading: isLoading,
      };
    case UPDATE_ALL_KPI_CONFIGURATIONS:
      const { payload: kpiConfigurations = {} } = action || {};

      const finalKpiForm = kpiConfigurations.map((line) => {
        const transformedPayload = buildKpiAdministrationFinalBody(line);

        const validatedAdministration =
          buildFormAdmConfigBody(transformedPayload);

        return validatedAdministration;
      });

      return {
        ...state,
        all: updateAllList(state.all, finalKpiForm, "selectedToolId"),
        loading: false,
      };

    case KPI_DELETE_SCENARIO:
      const { themeId } = action.payload;
      currentAdministration = getCurrentAdministration(administrationId);

      if (administrationId && currentAdministration) {
        return finishDelete(
          deleteScenarioOnAdminitration(currentAdministration, themeId),
        );
      }

      return state;
    case KPI_DELETE_GROUP:
      const { groupId } = action.payload;
      currentAdministration = getCurrentAdministration(administrationId);

      if (administrationId && currentAdministration) {
        return finishDelete(
          deleteThemeOnAdminitration(currentAdministration, groupId),
        );
      }

      return state;
    case KPI_DELETE_PROCESS:
      const { processId } = action.payload;
      currentAdministration = getCurrentAdministration(administrationId);

      if (administrationId && currentAdministration) {
        return finishDelete(
          deleteSubthemeOnAdminitration(currentAdministration, processId),
        );
      }

      return state;
    case KPI_DELETE_GOAL:
      const { goalId } = action.payload;
      currentAdministration = getCurrentAdministration(administrationId);

      if (administrationId && currentAdministration) {
        return finishDelete(
          deleteGoalOnAdministration(currentAdministration, goalId),
        );
      }

      return state;
    case KPI_MASS_UPDATE:
      const newPayload = _.cloneDeep(action.payload);

      return {
        ...state,
        all: updateAdministration(newPayload),
        loading: false,
      };

    case KPI_SET_SELECTED_GOAL:
      return {
        ...state,
        selectedGoal: action.payload,
      };

    default:
      return state;
  }
};

export default kpiReducer;
