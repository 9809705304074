import { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getKpiStates } from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";
import kpiUtils from "../../../../utils/kpis";
import { updateTheme } from "../../../../actions/themesActions";

const {
  getObjectDifference,
  getObjectInfoById,
  getOnlySlugValues,
  removeObjSlug,
} = utils;

const { buildFormAdmConfigBody, getKpiConfigBySelectedToolId } = kpiUtils;

const defaultForm = buildFormAdmConfigBody({});

const KpiWatcher = (props) => {
  const [kpiAdministration, setKpiAdministration] = useState(defaultForm);
  const [firstValidation, setFirstValidation] = useState(false);

  const {
    allKpiAdministration = [],
    selectedTool = {},
    allThemes = {},
    updateTheme,
  } = props;

  useEffect(() => {
    let toolAdministration = getKpiConfigBySelectedToolId(
      selectedTool.id,
      allKpiAdministration,
    );
    toolAdministration = buildFormAdmConfigBody(toolAdministration);

    const isAdmObjDiff = !_.isEqual(toolAdministration, kpiAdministration);

    if (
      toolAdministration &&
      toolAdministration.groups &&
      Object.keys(allThemes).length > 0
    ) {
      if (isAdmObjDiff || !firstValidation) {
        const compareBase = removeObjSlug(kpiAdministration.groups, "id");

        const diff = getObjectDifference(
          toolAdministration.groups,
          compareBase,
        );

        if (diff) {
          if (!firstValidation) setFirstValidation(true);

          const watchGroups = getOnlySlugValues(diff, "id");

          if (watchGroups.length > 0) {
            Object.keys(allThemes).forEach((scenaryId) => {
              const cenaryThemes = allThemes[scenaryId];

              cenaryThemes.forEach((themeInfo) => {
                const { kpiConfigurationGroupId } = themeInfo;
                const relatedGroupId =
                  watchGroups[watchGroups.indexOf(kpiConfigurationGroupId)] ||
                  null;

                if (relatedGroupId && themeInfo.name) {
                  const groupInfo = getObjectInfoById(
                    relatedGroupId,
                    toolAdministration.groups,
                    "id",
                  );

                  if (groupInfo.title !== themeInfo.name) {
                    updateTheme(
                      { ...themeInfo, name: groupInfo.title },
                      themeInfo.cenary.id,
                    );
                  }
                }
              });
            });
          }
        }

        if (isAdmObjDiff) setKpiAdministration(toolAdministration);
      }
    }
  }, [
    allKpiAdministration,
    allThemes,
    firstValidation,
    kpiAdministration,
    selectedTool.id,
    updateTheme,
  ]);

  return null;
};

const mapStateToProps = (state) => {
  const { allKpiAdministration } = getKpiStates(state);

  return { allKpiAdministration };
};

export default connect(mapStateToProps, { updateTheme })(KpiWatcher);
