import PropTypes from "prop-types";
import Avatar from "../../../../../../Common/Avatar";
import EvaluationLine from "./EvaluationLine";

import { orderArrayByObjAttr } from "../../../../../../../utils/ArrayUtils";
import {
  evaluationColors,
  getCompetenceConfigByComposition,
} from "../../../../../../../utils/skillsAssessment/constants";
import { checkIsAdmin } from "../../../../../../../utils/skillsAssessment/filter";

const EvaluationWorkflowTable = ({
  workflowAverage,
  maxScale,
  maxPotential,
  listTitle,
  composition,
  colSize,
  gapColSize,
  onClickItem,
  cursor,
  generalOptions,
  hideAllSelfEvaluation,
  enableInsight,
  onOpenInsight,
}) => {
  const getValuationLine = (
    evaluation,
    colorInfo,
    maxValue,
    toFixed = 1,
    append,
    fontSize = "16px"
  ) => {
    return (
      <EvaluationLine
        evaluation={evaluation}
        colorInfo={colorInfo}
        maxValue={maxValue}
        append={append}
        fontSize={fontSize}
        toFixed={toFixed}
      />
    );
  };

  const getWorkflowValuationLine = (workflowAverage, evaluationSlug, max) => {
    const evaluation = workflowAverage[evaluationSlug];
    const colorInfo = evaluationColors[evaluationSlug];
    return getValuationLine(evaluation, colorInfo, max, 2);
  };

  const getGapValuationLine = (gapPercentage, evaluated) => {
    const colorInfo =
      gapPercentage < 0 ? evaluationColors.gap : evaluationColors.invertedGap;
    const displayGap = evaluated ? gapPercentage : "N/A";
    const displayColor = evaluated
      ? colorInfo
      : { color: "#efefef", background: "#efefef" };

    return getValuationLine(displayGap, displayColor, 100, 1, "%", "13px");
  };

  const { enableDesiredEvaluation, enablePotential } =
    getCompetenceConfigByComposition(composition);

  const orderedWorkflowList = orderArrayByObjAttr(workflowAverage, "title");

  const { restrictViewAccess } = generalOptions || {};
  const notRestrict = checkIsAdmin() || !restrictViewAccess;

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td>{listTitle}</td>
          {enableInsight && <td width={100}>Detalhes</td>}
          {!hideAllSelfEvaluation && <td width={colSize}>AUTOAVALIAÇÃO</td>}
          {notRestrict && <td width={colSize}>AVALIAÇÃO GERAL</td>}
          {notRestrict && enableDesiredEvaluation && (
            <td width={colSize}>AVALIAÇÃO DESEJADA</td>
          )}
          {notRestrict && enablePotential && <td width={colSize}>POTENCIAL</td>}
          {notRestrict && enableDesiredEvaluation && (
            <td width={gapColSize}>GAP</td>
          )}
        </tr>
      </thead>
      <tbody>
        {orderedWorkflowList?.map((workflow, index) => {
          const {
            email,
            title,
            avatar,
            evaluated,
            workflowAverage: workflowInfo,
            hideSelfEvaluation,
          } = workflow;

          const onClickDetails = onClickItem
            ? () => onClickItem(workflow)
            : () => {};
          const onClickInsight = onOpenInsight
            ? () => onOpenInsight(workflow)
            : () => {};

          return (
            evaluated && (
              <tr key={index} style={{ cursor, height: "40px" }}>
                <td onClick={onClickDetails}>
                  {avatar && (
                    <Avatar
                      src={avatar}
                      name={title}
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "15px",
                      }}
                    />
                  )}
                  {title}
                  {email && (
                    <div style={{ fontSize: "10px", color: "#999" }}>
                      {email}
                    </div>
                  )}
                </td>
                {enableInsight && (
                  <td onClick={onClickInsight} align="center">
                    <a>PDI</a>
                  </td>
                )}
                {!hideAllSelfEvaluation && (
                  <td onClick={onClickDetails}>
                    {!hideSelfEvaluation &&
                      getWorkflowValuationLine(
                        workflowInfo,
                        "selfEvaluation",
                        maxScale
                      )}
                    {hideSelfEvaluation && (
                      <center style={{ color: "#666" }}>...</center>
                    )}
                  </td>
                )}
                {notRestrict && (
                  <td onClick={onClickDetails}>
                    {getWorkflowValuationLine(
                      workflowInfo,
                      "averageEvaluation",
                      maxScale
                    )}
                  </td>
                )}
                {notRestrict && enableDesiredEvaluation && (
                  <td onClick={onClickDetails}>
                    {getWorkflowValuationLine(
                      workflowInfo,
                      "desiredEvaluation",
                      maxScale
                    )}
                  </td>
                )}
                {notRestrict && enablePotential && (
                  <td onClick={onClickDetails}>
                    {getWorkflowValuationLine(
                      workflowInfo,
                      "potential",
                      maxPotential
                    )}
                  </td>
                )}
                {notRestrict && enableDesiredEvaluation && (
                  <td onClick={onClickDetails}>
                    {getGapValuationLine(workflowInfo.gap, evaluated)}
                  </td>
                )}
              </tr>
            )
          );
        })}
      </tbody>
    </table>
  );
};

EvaluationWorkflowTable.propTypes = {
  workflowAverage: PropTypes.array.isRequired,
  maxScale: PropTypes.number.isRequired,
  maxPotential: PropTypes.number.isRequired,
  composition: PropTypes.string.isRequired,
  listTitle: PropTypes.string.isRequired,
  generalOptions: PropTypes.object.isRequired,
  colSize: PropTypes.string,
  gapColSize: PropTypes.string,
  onClickItem: PropTypes.func,
  cursor: PropTypes.string,
};

EvaluationWorkflowTable.defaultProps = {
  onClickItem: () => {},
  colSize: "175",
  gapColSize: "125",
  cursor: "normal",
};

export default EvaluationWorkflowTable;
