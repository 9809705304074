import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import utils from "../../../../../../utils/toolUtils";

import { TdRegister } from "../css/OkrCss";

import { deleteBasePattern } from "../../../../../../actions/okrActions";

const { changeDateToWordMonth } = utils;

const onlyNumber = (title) => title.replace(/\D/g, "");

function CycleBaseTable(props) {
  const {
    intl,
    getDefaultAddTableLine,
    handleOpenModal,
    cycleBase,
    deleteBasePattern,
    openFormDelete,
  } = props;

  const cycleBaseId = cycleBase && cycleBase.id;

  const getDialogDeleteCicle = (patternId) => {
    const setDeleteCicle = {
      handleDelete() {
        deleteBasePattern(patternId);
      },

      getOpen() {
        return true;
      },

      getDescription() {
        return "";
      },

      getTitle() {
        return intl.formatMessage({ id: "delete_pattern_cycle" });
      },
    };

    return setDeleteCicle;
  };

  return (
    <div>
      <h4 style={{ textTransform: "uppercase" }}>
        {intl.formatMessage({ id: "pattern_of_cycles" })}
      </h4>
      <table className="simple-table">
        <thead>
          <tr>
            <td style={{ textAlign: "left" }}>
              {intl.formatMessage({ id: "cycle_name" })}
            </td>
            <td style={{ width: "100px" }}>
              {intl.formatMessage({ id: "start_date" })}
            </td>
            <td style={{ width: "100px" }}>
              {intl.formatMessage({ id: "end_date" })}
            </td>
            <td style={{ width: "100px" }}>
              {intl.formatMessage({ id: "check_date" })}
            </td>
          </tr>
        </thead>
        {cycleBase.patterns &&
          cycleBase.patterns.map((patternInfo) => {
            const patternCycles = patternInfo.cycles || [];

            const reorderedCycles = [...patternCycles].sort((a, b) => {
              return Number(onlyNumber(a.title)) > Number(onlyNumber(b.title))
                ? 1
                : -1;
            });

            return (
              <React.Fragment key={patternInfo.id}>
                <thead>
                  <tr>
                    <TdRegister
                      background="#eeeeee"
                      colSpan="4"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ width: "100%" }}>
                          {patternInfo.title}
                        </span>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "300px",
                          }}
                        >
                          <i
                            className="fas fa-pen"
                            style={{ cursor: "pointer", width: "40px" }}
                            onClick={() =>
                              handleOpenModal("baseModal", true, {
                                params: { patternInfo },
                              })
                            }
                          />
                          <i
                            className="fas fa-trash-alt"
                            style={{
                              cursor: "pointer",
                              width: "40px",
                              color: "rgb(255, 49, 49)",
                            }}
                            onClick={() =>
                              openFormDelete(
                                patternInfo.id,
                                getDialogDeleteCicle(patternInfo.id),
                              )
                            }
                          />
                        </div>
                      </div>
                    </TdRegister>
                  </tr>
                </thead>
                <tbody>
                  {reorderedCycles.map((cycleInfo) => {
                    const monthCycleStartDate = changeDateToWordMonth(
                      cycleInfo.startDate,
                    );
                    const monthCycleEndDate = changeDateToWordMonth(
                      cycleInfo.endDate,
                    );
                    const monthCycleCheckDate = changeDateToWordMonth(
                      cycleInfo.checkDate,
                    );

                    return (
                      <tr key={cycleInfo.id}>
                        <TdRegister>{cycleInfo.title}</TdRegister>
                        <TdRegister style={{ textAlign: "center" }}>
                          {monthCycleStartDate}
                        </TdRegister>
                        <TdRegister style={{ textAlign: "center" }}>
                          {monthCycleEndDate}
                        </TdRegister>
                        <TdRegister style={{ textAlign: "center" }}>
                          {monthCycleCheckDate}
                        </TdRegister>
                      </tr>
                    );
                  })}
                </tbody>
              </React.Fragment>
            );
          })}
        <tbody>
          {getDefaultAddTableLine(() =>
            handleOpenModal("baseModal", true, { params: cycleBaseId }),
          )}
        </tbody>
      </table>
    </div>
  );
}

const mapDispatchToProps = {
  deleteBasePattern,
};

export default connect(null, mapDispatchToProps)(injectIntl(CycleBaseTable));

CycleBaseTable.propTypes = {
  intl: PropTypes.object.isRequired,
  cycleBase: PropTypes.object.isRequired,
  getDefaultAddTableLine: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  deleteBasePattern: PropTypes.func.isRequired,
  openFormDelete: PropTypes.func.isRequired,
};
