import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import { Typography, Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { useCustomModal } from "./hooks/CustomModalSave";

import { globalMessages } from "../../utils/global";
import InsightContainer from "./InsightContainer";

import FormDialog from "../Common/FormDialog";
import { getSelectedToolStates } from "../customMapStates";
import { getToolIntlSlug } from "../../utils/translationUtils";
import {
  justTranslateCenaryName,
  justTranslateQuestionName,
} from "../../utils/cpAnalysis/display";

const InsightModal = (props) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { configsModal } = useCustomModal();

  const { intl, show, onHide, selectedInsight = {}, selectedTool = {} } = props;
  const { id: toolId } = selectedTool.tool;
  const { question = {} } = selectedInsight || {};
  const { theme = {} } = question;
  const { cenary = {} } = theme;

  const handleCloseModal = useCallback(() => {
    configsModal.onClose();
    if (configsModal?.show) {
      setShowConfirmModal(configsModal?.show);
    } else if (!configsModal?.show) {
      onHide();
    }
  }, [configsModal, onHide]);

  const handleSaveAndCloseModal = useCallback(async () => {
    if (typeof configsModal.next === "function") {
      await configsModal?.next();
    }

    setShowConfirmModal(false);
    if (configsModal.hideAfter) onHide();
  }, [configsModal, onHide]);

  const handleCancelModal = useCallback(() => {
    setShowConfirmModal(false);
    onHide();
  }, [onHide]);

  function getFixedLength(word, maxLength = 40, variant = "h5") {
    const text =
      word.length > maxLength ? `${word.substring(0, maxLength)}...` : word;
    return (
      <Typography variant={variant} title={word}>
        {text}
      </Typography>
    );
  }

  const handleShowBreadcrumb = () => {
    return (
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="breadcrumb-insight-view"
      >
        {getFixedLength(
          intl.formatMessage({ id: getToolIntlSlug(toolId) }),
          40,
        )}
        {getFixedLength(justTranslateCenaryName(cenary.name), 40)}
        {getFixedLength(theme.name, 40)}
        {getFixedLength(justTranslateQuestionName(question.text), 40)}
        {getFixedLength(selectedInsight?.text, 40)}
      </Breadcrumbs>
    );
  };

  return (
    <>
      <FormDialog
        title="Realmente deseja sair?"
        description={configsModal.title}
        open={showConfirmModal}
        cancelText="Sair sem salvar"
        confirmText={
          configsModal.isBlockSave ? "Continuar" : configsModal.onSaveText
        }
        toggleOpen={() => setShowConfirmModal(false)}
        onConfirm={
          !configsModal.isBlockSave
            ? handleSaveAndCloseModal
            : () => setShowConfirmModal(false)
        }
        onCancel={handleCancelModal}
      />
      <Modal
        dialogClassName="modal-insight-details"
        show={show}
        onHide={handleCloseModal}
        bsSize="lg"
        backdrop="static"
      >
        <Modal.Header closeButton className="bg-insight-modal">
          <Modal.Title id="insight-modal-title">
            <div>
              <div style={{ padding: "5px 0px" }}>
                {getFixedLength(selectedInsight?.name, 180, "h4")}
              </div>
              <span style={{ display: "inline-flex", paddingBottom: "5px" }}>
                {handleShowBreadcrumb()}
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-insight">
          <InsightContainer />
        </Modal.Body>
        <Modal.Footer
          style={{
            borderTop: "2px solid #ccc",
            paddingTop: "10px",
            paddingBottom: "8px",
          }}
        >
          <Button onClick={handleCloseModal}>
            <i className="fas fa-times" />{" "}
            <b>{intl.formatMessage(globalMessages.close)}</b>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

InsightModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { selectedTool } = getSelectedToolStates(state);
  const { selectedInsight } = state;

  return {
    selectedInsight: selectedInsight.selectedInsight || {},
    selectedTool,
  };
};

export default injectIntl(connect(mapStateToProps)(InsightModal));
