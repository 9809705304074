import store from "../../../../../Store";

export const selectedInsightVerification = (insightId = "") => {
  const state = store.getState();

  const { selectedInsight = {} } = state || {};
  const { selectedInsight: insightEntity = {} } = selectedInsight || {};
  const { id: selectedInsightId = {} } = insightEntity || {};

  if (selectedInsightId === Number(insightId)) return true;

  return false;
};
