import React from "react";
import PropTypes from "prop-types";
import { getSimpleAreaTree } from "../../../utils/companyStructure/filter";
import SelectTreeModal from "../SelectTreeModal";

const NewTheme = ({ open, onConfirm, onCancel, companyAreas }) => {
  const areasTree = getSimpleAreaTree(companyAreas);

  return (
    <SelectTreeModal
      open={open}
      title="Selecione as Áreas"
      treeList={areasTree.treeList}
      treeOrder={areasTree.treeOrder}
      onConfirm={(selected) => onConfirm(selected)}
      onCancel={onCancel}
      canSelectAnyLevel
      singleSelect={false}
      dialogClassName="select-theme-dialog"
      bottomMessage={
        <div style={{ padding: "15px" }}>
          Serão adicionadas apenas as Áreas que não foram vinculadas ao Cenário
          selecionado.
        </div>
      }
    />
  );
};

NewTheme.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  companyAreas: PropTypes.array.isRequired,
};

NewTheme.defaultProps = {
  open: false,
};

export default NewTheme;
