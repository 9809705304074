import React from "react";
import { defineMessages } from "react-intl";
import Administration from "./Administration";

import Measurement from "../../../../../Measurement";
import { checkAccess } from "../../../../../../utils/accessLevels";
import { translatedText } from "../../../../../../utils/translationUtils";

const messages = defineMessages({
  global_administration: { id: "global.administration" },
  global_measurement: { id: "global.measurement" },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

const getTabs = (appendProps = {}) => {
  const administrativeAccess = checkAccess([
    "OWNER",
    "ADMIN",
    "MODERATOR",
    "MANAGER",
  ]);

  let filteredTabs = [];

  const addTab = (id, label, Component) => {
    filteredTabs = [
      ...filteredTabs,
      {
        id,
        label,
        component: <Component {...appendProps} />,
      },
    ];
  };

  if (administrativeAccess) {
    addTab(
      "administration",
      translation("global_administration"),
      Administration,
    );
    addTab("measurement", translation("global_measurement"), Measurement);
  }

  return filteredTabs;
};

export default getTabs;
