import React, { useState } from "react";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";
import SelectProfile from "../forms/SelectProfile";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import DeleteColumn from "../../../../../../Common/Tables/DeleteColumn";
import { translatedText } from "../../../../../../../utils/translationUtils";
import { getDisplayProfiles } from "../../../../../../../utils/skillsAssessment/display";
import { simpleEditArrayOnAdministration } from "../../../../../../../utils/skillsAssessment/manipulation";
import {
  getEmptyTableRow,
  getDefaultAddTableLine,
} from "../../../../../../../utils/tableUtils";
import EditTextModal from "../../../../../../Common/EditTextModal";

const messages = defineMessages({
  global_noneRegistered: { id: "global.noneRegistered" },
});

const translation = (id) => translatedText(id, messages);

const TableCompetenceAreas = ({
  toolAdministration,
  updateAdministration,
  handleAutoSave,
  handleAdd,
  openDeleteCompetenceArea,
  orderedCompetenceAreas,
}) => {
  const [selectModal, setSelectModal] = useState({
    type: null,
    onConfirm: () => {},
    onCancel: () => {},
  });

  const { profiles = [] } = toolAdministration;

  const closeSelectModal = () => {
    setSelectModal({ type: null, onConfirm: () => {}, onCancel: () => {} });
  };

  const handleUpdateCompetenceAreas = (
    competenceAreaInfo,
    editingField = "",
    newValue,
  ) => {
    if (competenceAreaInfo && editingField) {
      const updatedCompetenceArea = {
        ...competenceAreaInfo,
        [editingField]: newValue,
      };

      const newAdministration = simpleEditArrayOnAdministration(
        toolAdministration,
        "competenceAreas",
        competenceAreaInfo.id,
        updatedCompetenceArea,
        "id",
      );

      updateAdministration(newAdministration);
      handleAutoSave(newAdministration);
    }
  };

  const handleUpdateCompetenceAreaProfile = (
    competenceAreaInfo,
    newProfiles,
  ) => {
    if (newProfiles) {
      handleUpdateCompetenceAreas(competenceAreaInfo, "profiles", newProfiles);
    }

    closeSelectModal();
  };

  const openSelectProfile = (competenceAreaInfo) => {
    setSelectModal({
      type: "select_profile",
      onConfirm: (selected) =>
        handleUpdateCompetenceAreaProfile(competenceAreaInfo, selected),
      onCancel: closeSelectModal,
      initialSelected: competenceAreaInfo.profiles || [],
    });
  };

  const openEditText = (field, competenceAreaInfo) => {
    if (typeof competenceAreaInfo[field] !== "undefined") {
      setSelectModal({
        type: "edit_text",
        onConfirm: (newText) => {
          handleUpdateCompetenceAreas(competenceAreaInfo, field, newText);
          closeSelectModal();
        },
        onCancel: closeSelectModal,
        title: "Editando Área de Competência",
        text: competenceAreaInfo[field],
        label: field === "title" ? "Título" : "Descrição",
      });
    }
  };

  return (
    <div>
      <SelectProfile
        open={selectModal.type === "select_profile"}
        profiles={profiles}
        initialSelected={selectModal.initialSelected}
        onConfirm={selectModal.onConfirm}
        onCancel={selectModal.onCancel}
      />
      {selectModal.type === "edit_text" && (
        <EditTextModal
          open
          title={selectModal.title}
          text={selectModal.text}
          label={selectModal.label}
          onConfirm={selectModal.onConfirm}
          onCancel={selectModal.onCancel}
        />
      )}
      <table className="simple-table">
        <thead>
          {getDefaultAddTableLine(handleAdd, {}, "ÁREAS DE COMPETÊNCIAS")}
        </thead>
        <tbody>
          <tr className="block-white-space border-bottom-division">
            <td width="25%">Área de Competência</td>
            <td width="25%">Perfil</td>
            <td>Descrição</td>
            <td />
          </tr>
          {orderedCompetenceAreas?.map((competenceAreaInfo, index) => {
            const displayProfiles = getDisplayProfiles(
              profiles,
              competenceAreaInfo.profiles,
            );

            return (
              <tr key={competenceAreaInfo.id}>
                <SimpleExcelCollumn
                  id={`competenceAreaTitle${index}`}
                  label={competenceAreaInfo.title}
                  type="text"
                  value={competenceAreaInfo.title}
                  title={competenceAreaInfo.title}
                  onClick={() => openEditText("title", competenceAreaInfo)}
                  inputStyle={{ height: "50px" }}
                />
                <SimpleExcelCollumn
                  id={`profileOption${index}`}
                  type="text"
                  title={displayProfiles}
                  value={displayProfiles}
                  label={competenceAreaInfo.title}
                  onClick={() => openSelectProfile(competenceAreaInfo)}
                  inputStyle={{ height: "50px" }}
                />
                <SimpleExcelCollumn
                  id={`competenceAreaDescription${index}`}
                  label={competenceAreaInfo.description}
                  type="text"
                  value={competenceAreaInfo.description}
                  title={competenceAreaInfo.description}
                  onClick={() =>
                    openEditText("description", competenceAreaInfo)
                  }
                  inputStyle={{ fontSize: "11px", maxHeight: "50px" }}
                  multiline
                  primaryInputProps={{ maxLength: 1000 }}
                />
                <DeleteColumn
                  handleDelete={() =>
                    openDeleteCompetenceArea(competenceAreaInfo.id)
                  }
                />
              </tr>
            );
          })}
          {getEmptyTableRow(
            orderedCompetenceAreas,
            translation("global_noneRegistered"),
          )}
        </tbody>
      </table>
    </div>
  );
};

TableCompetenceAreas.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
  updateAdministration: PropTypes.func.isRequired,
  handleAutoSave: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  openDeleteCompetenceArea: PropTypes.func.isRequired,
};

export default TableCompetenceAreas;
