const INITIAL_STATE = {
  action: {
    type: null,
    params: {},
  },
};

const structureReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Avisar o componente controller de views => StructureController.js
    case "STRUCTURE_ACTION":
      return { ...state, action: action.structureAction };
    default:
      return state;
  }
};

export default structureReducer;
