import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TableCompetenceAreas from "../tables/TableCompetenceAreas";
import TableSkills from "../tables/TableSkills";
import {
  deleteCompetenceArea,
  deleteSkill,
} from "../../../../../../../actions/skillAssessmentActions";
import { getStyledErrorMessage } from "../../../../../../../utils/formValidation";
import {
  competenceAreaErrorSlugs,
  skillErrorSlugs,
} from "../../../../../../../utils/skillsAssessment/errorConstants";
import {
  getCompetenceAreasDeleteDialogInfo,
  getSkillsDeleteDialogInfo,
} from "../../../../../../../utils/skillsAssessment/deletes";
import { orderArrayByObjAttr } from "../../../../../../../utils/ArrayUtils";

const TabEvaluation = (props) => {
  const {
    toolAdministration,
    updateAdministration,
    handleAutoSave,
    toggleOpenForms,
    setConfirmationDialog,
    resetConfirmDialog,
    errors,
    selectedTool,
    deleteCompetenceArea,
    deleteSkill,
  } = props;

  const handleDeleteCompetenceArea = (competenceAreaId) => {
    if (competenceAreaId) {
      deleteCompetenceArea(selectedTool.id, competenceAreaId);
    }
  };

  const handleDeleteSkill = (skillId) => {
    if (skillId) {
      deleteSkill(selectedTool.id, skillId);
    }
  };

  const deleteConfirmation = (
    title,
    description,
    deleteCallback = () => {},
    confirmText,
    hideCancel,
  ) => {
    setConfirmationDialog({
      open: true,
      title,
      description,
      onConfirm: () => {
        deleteCallback();
        resetConfirmDialog();
      },
      confirmText,
      hideCancel,
      onCancel: resetConfirmDialog,
    });
  };

  const openDeleteCompetenceArea = (competenceAreaId) => {
    const {
      title,
      description,
      confirmText,
      hideCancel,
      disableDelete,
    } = getCompetenceAreasDeleteDialogInfo(
      toolAdministration,
      competenceAreaId,
    );

    deleteConfirmation(
      title,
      description,
      !disableDelete
        ? () => handleDeleteCompetenceArea(competenceAreaId)
        : () => {},
      confirmText,
      hideCancel,
    );
  };

  const openDeleteSkill = (skillId) => {
    const {
      title,
      description,
      confirmText,
      hideCancel,
      disableDelete,
    } = getSkillsDeleteDialogInfo(toolAdministration, skillId);

    deleteConfirmation(
      title,
      description,
      !disableDelete ? () => handleDeleteSkill(skillId) : () => {},
      confirmText,
      hideCancel,
    );
  };

  const { competenceAreas } = toolAdministration;
  const orderedCompetenceAreas = orderArrayByObjAttr(competenceAreas, "title");

  return (
    <div className="tab-content" style={{ margin: "0px" }}>
      <div className="row">
        <div className="col-xs-12">
          <h4>Cadastro da Avaliação</h4>
        </div>
        <div className="col-xs-12">
          <div className="row">
            <TableCompetenceAreas
              toolAdministration={toolAdministration}
              updateAdministration={updateAdministration}
              handleAutoSave={handleAutoSave}
              orderedCompetenceAreas={orderedCompetenceAreas}
              handleAdd={() => toggleOpenForms("newCompetenceArea")}
              openDeleteCompetenceArea={openDeleteCompetenceArea}
            />
            {getStyledErrorMessage(competenceAreaErrorSlugs(), errors)}
          </div>
        </div>
        <div className="col-xs-12">
          <div className="row">
            <TableSkills
              toolAdministration={toolAdministration}
              updateAdministration={updateAdministration}
              handleAutoSave={handleAutoSave}
              orderedCompetenceAreas={orderedCompetenceAreas}
              handleAdd={() => toggleOpenForms("newSkill")}
              openDeleteSkill={openDeleteSkill}
            />
            {getStyledErrorMessage(skillErrorSlugs(), errors)}
          </div>
        </div>
      </div>
    </div>
  );
};

TabEvaluation.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
  selectedTool: PropTypes.object.isRequired,
  updateAdministration: PropTypes.func.isRequired,
  handleAutoSave: PropTypes.func.isRequired,
  toggleOpenForms: PropTypes.func.isRequired,
  setConfirmationDialog: PropTypes.func.isRequired,
  resetConfirmDialog: PropTypes.func.isRequired,
  deleteCompetenceArea: PropTypes.func.isRequired,
  deleteSkill: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
};

export default connect(null, { deleteCompetenceArea, deleteSkill })(
  TabEvaluation,
);
