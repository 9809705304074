import {
  GET_USER_ACCESS,
  FETCH_USER_DOMAINS,
  FETCH_DOMAIN_COMPANIES,
  FETCH_DOMAIN_USERS,
  SET_CURRENT_DOMAIN,
  BUILD_ACCESS,
  USER_ROLE_ACCESS,
  SET_SELECTED_DOMAIN_ID,
  LOCKDOWN,
  ADD_ACCESS,
  ADD_ACCESSES,
  DELETE_ACCESS,
  DELETE_ACCESSES,
  SMART_LINK_LOADING,
} from "../actions/types";

import { transformUserEntityAccessIds } from "../actions/accessLevelsActions";

const INITIAL_STATES = {
  buildedAccess: false,
  currentDomain: null,
  smartLinkLoading: false,
};

const accessLevelsReducer = (state = INITIAL_STATES, action) => {
  const payload = action.payload;

  const updateEntityAccess = () => {
    const { type = "", data = [] } = payload;
    const { entityAccess = {} } = state;

    return { ...entityAccess, [type]: [...data] };
  };

  const transformRemovePayloads = (payloads = []) => {
    let finalUpdated = [];

    if (Array.isArray(payloads)) {
      payloads.forEach((deletingAccessInfo) => {
        const { domainUser = {}, entityRoles = [] } = deletingAccessInfo;
        const { id: domainUserId = "" } = domainUser || {};

        finalUpdated.push({
          ...deletingAccessInfo,
          domainUserId,
          entityRole: entityRoles[0],
        });
      });
    }

    return finalUpdated;
  };

  const updateEntityAccessByType = (
    currentEntitiesAccess = {},
    updatedUserAccess,
    isDeleting = false,
  ) => {
    if (
      updatedUserAccess &&
      updatedUserAccess.entityRole &&
      updatedUserAccess.entityRole.type
    ) {
      const entityType = updatedUserAccess.entityRole.type.toLowerCase();
      const editingEntityAccess = currentEntitiesAccess[entityType] || [];

      let finalUpdatedEntityAccess = [];
      let foundToUpdate = false;

      editingEntityAccess.forEach((currentAccess) => {
        const isTheSame =
          currentAccess.domainUserId === updatedUserAccess.domainUserId &&
          currentAccess.entityRole.role === updatedUserAccess.entityRole.role;

        let addAccess = currentAccess;

        if (isTheSame) {
          addAccess = updatedUserAccess;
          foundToUpdate = true;
        }

        if (!isTheSame || (isTheSame && !isDeleting))
          finalUpdatedEntityAccess.push(addAccess);
      });

      if (!foundToUpdate && !isDeleting) {
        finalUpdatedEntityAccess.push({
          ...updatedUserAccess,
        });
      }

      return {
        ...currentEntitiesAccess,
        [entityType]: finalUpdatedEntityAccess,
      };
    }

    return currentEntitiesAccess;
  };

  const updateMultipleAccessByTypes = (payloads = [], isDeleting = false) => {
    const { entityAccess = {} } = state;

    let updatedEntityAccess = { ...entityAccess };

    if (Array.isArray(payloads)) {
      payloads.forEach((updatedAccessInfo) => {
        updatedEntityAccess = updateEntityAccessByType(
          updatedEntityAccess,
          updatedAccessInfo,
          isDeleting,
        );
      });
    }

    return {
      ...state,
      entityAccess: updatedEntityAccess,
    };
  };

  const updateMultipleEntityAccess = (allBodys = []) => {
    return updateMultipleAccessByTypes(allBodys, false);
  };

  const updateSingleEntityAccess = (updatedObj) => {
    const formattedObj = transformUserEntityAccessIds([updatedObj])[0] || {};

    return updateMultipleEntityAccess([formattedObj], false);
  };

  const removeMultipleEntityAccess = (allBodys = []) => {
    return updateMultipleAccessByTypes(transformRemovePayloads(allBodys), true);
  };

  const removeSingleEntityAccess = (deletingAccess) => {
    return removeMultipleEntityAccess([deletingAccess], true);
  };

  const updateDomainCompanies = (domainCompanies, payload) => {
    return domainCompanies.map((company) => {
      if (company.id === payload.id) {
        return { ...company, ...payload };
      } else {
        return { ...company };
      }
    });
  };

  switch (action.type) {
    case GET_USER_ACCESS:
      return { ...state, userAccess: { ...payload } };
    case FETCH_USER_DOMAINS:
      return { ...state, userDomains: [...payload] };
    case FETCH_DOMAIN_COMPANIES:
      return { ...state, domainCompanies: [...payload] };
    case "UPDATE_DOMAIN_COMPANY":
      return {
        ...state,
        domainCompanies: updateDomainCompanies(state.domainCompanies, payload),
      };
    case SET_SELECTED_DOMAIN_ID:
      return { ...state, selectedDomainId: payload };
    case FETCH_DOMAIN_USERS:
      return { ...state, domainUsers: [...payload] };
    case SET_CURRENT_DOMAIN:
      const { id: selectedDomainId = false } = payload || {};
      return { ...state, currentDomain: payload, selectedDomainId };
    case BUILD_ACCESS:
      return { ...state, buildedAccess: payload };
    case USER_ROLE_ACCESS:
      return { ...state, entityAccess: updateEntityAccess() };
    case ADD_ACCESS:
      return updateSingleEntityAccess(payload);
    case ADD_ACCESSES:
      return updateMultipleEntityAccess(payload);
    case DELETE_ACCESS:
      return removeSingleEntityAccess(payload[0] || {});
    case DELETE_ACCESSES:
      return removeMultipleEntityAccess(payload);
    case LOCKDOWN:
      if (payload) return { ...state, lockdown: payload };

      return { ...state, lockdown: !state.lockdown };
    case SMART_LINK_LOADING:
      return { ...state, smartLinkLoading: payload };
    default:
      return state;
  }
};

export default accessLevelsReducer;
