import React from "react";

import { Display, Title, AlertStyle } from "../../../CustomTablesOkr/OkrStyle";
import { RowTable, SimpleTableCustom, TdStatus } from "./styles";

import { getFilteredAllAnswerOkrs } from "../../../../../../utils/okrUtils";

const ScenaryView = ({ plansKeyResults, options, intl }) => {
  const orderedKeyResults = plansKeyResults.sort((a, b) => {
    if (a.scenerie.name > b.scenerie.name) {
      return 1;
    }

    if (a.scenerie.name < b.scenerie.name) {
      return -1;
    }

    return 0;
  });

  return (
    <Display>
      <Title>{intl.formatMessage({ id: "title_teams_monitoring" })}</Title>
      {orderedKeyResults.length ? (
        <SimpleTableCustom className="simple-table" border={0}>
          <thead>
            <tr>
              <TdStatus>{intl.formatMessage({ id: "global.cenary" })}</TdStatus>
              <TdStatus color="#f54e4e">
                {intl.formatMessage({ id: "insight.goals.status.notAchieved" })}
              </TdStatus>
              <TdStatus color="#ffc107">Parcial Atingido</TdStatus>
              <TdStatus color="#0c9247">
                {intl.formatMessage({ id: "insight.goals.status.achieved" })}
              </TdStatus>
              <TdStatus color="#6495ed">
                {intl.formatMessage({ id: "insight.goals.status.exceeded" })}
              </TdStatus>
              <TdStatus color="#333">
                {intl.formatMessage({ id: "insight.goals.status.canceled" })}
              </TdStatus>
              <TdStatus>Total</TdStatus>
              <TdStatus>
                {intl.formatMessage({ id: "global.percentage" })}
              </TdStatus>
            </tr>
          </thead>
          <tbody>
            {orderedKeyResults.map((planKeyResult) => {
              const {
                NOT_REACHED,
                PARTIALLY_REACHED,
                REACHED,
                EXCEEDED,
                CANCELED,
              } = getFilteredAllAnswerOkrs(
                planKeyResult.insights,
                options.status,
              );

              const total =
                NOT_REACHED.length +
                PARTIALLY_REACHED.length +
                REACHED.length +
                EXCEEDED.length +
                CANCELED.length;

              const calculatePlan =
                ((REACHED.length + EXCEEDED.length - CANCELED.length) * 100) /
                planKeyResult.insights.length;

              return (
                <>
                  {total > 0 ? (
                    <tr key={planKeyResult.scenerie?.id}>
                      <td>{planKeyResult.scenerie.name}</td>
                      <RowTable>{NOT_REACHED.length}</RowTable>
                      <RowTable>{PARTIALLY_REACHED.length}</RowTable>
                      <RowTable>{REACHED.length}</RowTable>
                      <RowTable>{EXCEEDED.length}</RowTable>
                      <RowTable>{CANCELED.length}</RowTable>
                      <RowTable>{total}</RowTable>
                      <RowTable>
                        {calculatePlan ? calculatePlan.toFixed() : 0}%
                      </RowTable>
                    </tr>
                  ) : null}
                </>
              );
            })}
          </tbody>
        </SimpleTableCustom>
      ) : (
        <AlertStyle severity="info">
          {intl.formatMessage({ id: "info_cycles" })}
        </AlertStyle>
      )}
    </Display>
  );
};

export { ScenaryView };
