import React from "react";
import { connect } from "react-redux";
import InsightActionPlan from "../../../Common/ActionPlan/InsightActionPlan";
import { getSelectedToolStates } from "../../../customMapStates";

let OkrFields = (props) => {
  const { insight = {}, selectedTool = {} } = props;

  return (
    <div>
      <div className="row">
        <InsightActionPlan
          insight={insight}
          selectedTool={selectedTool}
          dragabble
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { selectedTool = {} } = getSelectedToolStates(state);

  return {
    selectedTool,
  };
};

OkrFields = connect(mapStateToProps)(OkrFields);

export { OkrFields };
