import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import { globalMessages } from "../../utils/global";
import ConfirmationDialog from "./ConfirmationDialog";

const DeleteDialog = props => {
  const { entityName, intl, onCancel, onConfirm, show } = props;

  const actionText = intl.formatMessage(globalMessages.delete);

  return (
    <ConfirmationDialog
      open={show}
      title={intl.formatMessage(globalMessages.entityConfirmationAction, {
        action: actionText,
        entity: entityName
      })}
      description={intl.formatMessage(globalMessages.deleteConfirmation)}
      onConfirm={onConfirm}
      onCancel={onCancel}
      cancelText={intl.formatMessage(globalMessages.cancel)}
    />
  );
};

DeleteDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func
};

export default injectIntl(DeleteDialog);
