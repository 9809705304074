import React from "react";
import PropTypes from "prop-types";
import FormDialog from "./FormDialog";
import ChangelogDisplay from "./ChangelogDisplay";

const ChangelogDialog = ({ open, onConfirm, onCancel }) => {
  return (
    <FormDialog
      open={open}
      title="Últimas Atualizações da Plataforma"
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmText="Voltar"
      hideCancel
    >
      <div className="changelog-dialog">
        <ChangelogDisplay />
      </div>
    </FormDialog>
  );
};

ChangelogDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ChangelogDialog;
