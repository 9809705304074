import React from "react";
import { defineMessages } from "react-intl";
import { connect } from "react-redux";
import utils from "../../../../utils/toolUtils";
import { openParticipantWorkflowDialog } from "../../../../actions/structureActions";
import { getGoalValidationStatusOptions } from "../../../../constants/goalsAndBonus";
import {
  getGoalsBonusStates,
  getSelectedToolStates,
} from "../../../customMapStates";
import ActionButton from "../../../Common/FabActionButton";
import TargetColorBadge from "../../../Common/TargetColorBadge";
import { getObjectInfoById } from "../../../../utils/ArrayUtils";
import {
  getSingleIndicatorResults,
  multipleIndicatorsResult,
} from "../../../../utils/kpis/calc2";
import { getHitTargetInfo } from "../../../../utils/kpis/display";
import { getGoalInfoById } from "../../../../utils/goalsBonus/filter";
import {
  useSelectedGoal,
  useSimpleToolAdministration,
} from "../../../../utils/goalsBonus/hooks";
import LinkedIcon from "../../../Common/GoalsAndBonus/includes/LinkedIcon";

const { translatedText } = utils;

const messages = defineMessages({
  gb_table_text_goals: { id: "gb_table_text_goals" },
  gb_table_text_weight: { id: "gb_table_text_weight" },
  gb_warning_invalid_goals_total_weight: {
    id: "gb_warning_invalid_goals_total_weight",
  },
  gb_message_goals_already_approval: {
    id: "gb_message_goals_already_approval",
  },
});

const translation = (id, values) => translatedText(id, messages, values);

const GoalsAndBonus = (props) => {
  const {
    insightsToCalc,
    selectedTool,
    allGoalBonusAdministration,
    isLoading,
    openParticipantWorkflowDialog,
  } = props;

  const selectedInsight = insightsToCalc[0] || {};
  const { goalsBonusProgramGoalId = null } = selectedInsight;

  const { goalBonusAdministration } = useSimpleToolAdministration({
    selectedTool,
    allGoalBonusAdministration,
    isLoading,
  });
  const { goalDescriptions } = useSelectedGoal({
    goals: goalBonusAdministration.goals,
    goalsBonusProgramGoalId,
    isLoading,
  });

  const selectedToolId =
    props.from === "overview" ? props.selectedToolId : selectedTool.id;

  const goalValidationStatusOptions = getGoalValidationStatusOptions();

  const SingleInsight = () => {
    const { goalPercentage, notStarted } = getSingleIndicatorResults(
      goalDescriptions,
      goalBonusAdministration.goalTargets,
      goalBonusAdministration.zeroWhenNegative,
    );

    const hitTargetInfo = getHitTargetInfo(
      goalPercentage,
      goalBonusAdministration.goalTargets,
      notStarted,
    );

    const haveLinkedIndicator = goalDescriptions?.linkedIndicator?.id > 0;

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {haveLinkedIndicator && <LinkedIcon />}
        <TargetColorBadge
          hitTargetInfo={hitTargetInfo}
          goalPercentage={goalPercentage}
        />
      </div>
    );
  };

  const QuestionEval = () => {
    let totalWeight = 0;
    const goals = [];

    if (insightsToCalc && insightsToCalc.length > 0) {
      insightsToCalc.forEach((insightInfo) => {
        const { goalsBonusProgramGoalId = null } = insightInfo;

        const goalEvaluation = getGoalInfoById(
          goalsBonusProgramGoalId,
          goalBonusAdministration.goals,
        );

        if (goalEvaluation.weight) {
          totalWeight += parseFloat(goalEvaluation.weight);
          goals.push(goalEvaluation);
        }
      });
    }

    const getWorkflowUpdateParams = () => {
      const questionInsights = [];
      const onlyGoals = [];

      insightsToCalc.forEach((insightInfo) => {
        const { goalsBonusProgramGoalId } = insightInfo;

        if (goalsBonusProgramGoalId) {
          questionInsights.push(insightInfo);
          onlyGoals.push(goalsBonusProgramGoalId);
        }
      });

      return {
        questionInsights,
        onlyGoals,
        goalBonusAdministration,
        selectedToolId,
      };
    };

    const handleUpdateGoalsWorkflow = () => {
      const {
        questionInsights,
        onlyGoals,
        goalBonusAdministration,
        selectedToolId,
      } = getWorkflowUpdateParams();

      openParticipantWorkflowDialog(
        selectedToolId,
        goalBonusAdministration,
        questionInsights,
        onlyGoals,
      );
    };

    const { realGoalsReachSum, evaluatedGoals } = multipleIndicatorsResult(
      goals,
      goalBonusAdministration.goalTargets,
    );

    const hitTargetInfo = getHitTargetInfo(
      realGoalsReachSum,
      goalBonusAdministration.goalTargets,
      evaluatedGoals.length === 0,
    );

    const approvalStatusInfo = getObjectInfoById(
      "APPROVAL",
      goalValidationStatusOptions,
      "value",
    );

    const invalidTotalWeight = totalWeight > 100 || totalWeight < 100;

    const alreadyOnWorkflow = [];

    goals.forEach((goalInfo) => {
      if (goalInfo.workflowStatus !== "CREATING")
        alreadyOnWorkflow.push(goalInfo.id);
    });

    const isEveryGoalOnWorkflow = alreadyOnWorkflow.length === goals.length;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <div>
          {invalidTotalWeight && (
            <h5 style={{ fontSize: "12px" }}>
              {translation("gb_warning_invalid_goals_total_weight")}
            </h5>
          )}

          <span
            title={
              isEveryGoalOnWorkflow
                ? translation("gb_message_goals_already_approval")
                : null
            }
          >
            {totalWeight === 100 && (
              <ActionButton
                size="small"
                text={approvalStatusInfo.icon}
                color="#eee"
                contrast={isEveryGoalOnWorkflow ? "#57802b" : "#c1c1c1"}
                borderColor={isEveryGoalOnWorkflow ? "#57802b" : "#c1c1c1"}
                hoverColor={
                  isEveryGoalOnWorkflow ? "#eee" : approvalStatusInfo.color
                }
                hoverContrast={
                  isEveryGoalOnWorkflow
                    ? "#c1c1c1"
                    : approvalStatusInfo.contrast
                }
                blockHover={false}
                onClick={() => handleUpdateGoalsWorkflow()}
              />
            )}
          </span>
        </div>
        <div
          style={{
            marginLeft: "30px",
            textAlign: "center",
            lineHeight: "18px",
          }}
          className={invalidTotalWeight ? "text-danger" : ""}
        >
          <font style={{ fontSize: "12px", fontWeight: "500" }}>
            {translation("gb_table_text_weight")}:
          </font>{" "}
          <br />
          <div
            style={{ height: "24px", lineHeight: "24px", fontWeight: "bold" }}
          >
            {totalWeight}%
          </div>
        </div>
        <div
          style={{
            marginLeft: "15px",
            textAlign: "center",
            lineHeight: "18px",
          }}
        >
          <font style={{ fontSize: "12px" }}>
            {translation("gb_table_text_goals")}::
          </font>
          <div
            style={{ display: "flex", height: "24px", alignItems: "center" }}
          >
            <TargetColorBadge
              hitTargetInfo={hitTargetInfo}
              goalPercentage={realGoalsReachSum}
            />
          </div>
        </div>
      </div>
    );
  };

  const handleDisplayEvaluation = (from) => {
    if (from === "answer" || from === "score" || from === "overview")
      return <SingleInsight />;

    if (from === "question") return <QuestionEval />;

    return null;
  };

  return (
    <div style={{ fontFamily: "montserrat" }}>
      {!isLoading && handleDisplayEvaluation(props.from)}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { selectedTool } = getSelectedToolStates(state);
  const { allGoalBonusAdministration, isLoading } = getGoalsBonusStates(state);

  return {
    allGoalBonusAdministration,
    isLoading,
    selectedTool,
  };
};

export default connect(mapStateToProps, { openParticipantWorkflowDialog })(
  GoalsAndBonus,
);
