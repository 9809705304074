import React, { lazy } from "react";
import { lazyImport } from "../utils/lazyUtils";
import ErrorMessage from "./ErrorBoundaries/ErrorMessage";
import SmartLinkController from "./SmartLinkController";
import ToolContainer from "./Tool/ToolContainer";
import SelectionEvaluatorsReply from "rookau-tools/skills-assessment/pages/SelectionEvaluatorsReply";
import ParticipantWorkflowReply from "rookau-tools/skills-assessment/pages/ParticipantWorkflowReply";

const DomainSelection = lazy(() =>
  lazyImport(() => import("./Domain/DomainSelection")),
);
const LogoutPage = lazy(() => lazyImport(() => import("./Logout")));
const InvalidDomain = lazy(() =>
  lazyImport(() => import("./Domain/InvalidDomain")),
);
const DomainConfig = lazy(() =>
  lazyImport(() => import("./Domain/DomainConfig")),
);
const DomainLogs = lazy(() => lazyImport(() => import("./Domain/DomainLogs")));

const CustomBreadcrumb = lazy(() =>
  lazyImport(() => import("./BreadcrumbMenu/CustomBreadcrumb")),
);
const BreadcrumbMenuItem = lazy(() =>
  lazyImport(() => import("./BreadcrumbMenu/BreadcrumbMenuItem")),
);
const InvitePage = lazy(() => lazyImport(() => import("./Invite/InvitePage")));
const AccountReactivation = lazy(() =>
  lazyImport(() => import("./AccountActivation/AccountReactivation")),
);
const ForgotPassword = lazy(() =>
  lazyImport(() => import("./ForgotPassword/ForgotPassword")),
);
const ForgotPasswordCpf = lazy(() =>
  lazyImport(() => import("./ForgotPasswordCpf/ForgotPasswordCpf")),
);
const Workspace = lazy(() => lazyImport(() => import("./Workspace/Workspace")));
const WorkspacesPage = lazy(() =>
  lazyImport(() => import("./Workspace/WorkspacesPage")),
);
const ProfilePage = lazy(() =>
  lazyImport(() => import("./Profile/ProfilePage")),
);
const Company = lazy(() => lazyImport(() => import("./Company/Company")));
const PasswordReset = lazy(() =>
  lazyImport(() => import("./PasswordReset/PasswordReset")),
);
const PasswordCpfReset = lazy(() =>
  lazyImport(() => import("./PasswordCpfReset/PasswordCpfReset")),
);
const AccountActivation = lazy(() =>
  lazyImport(() => import("./AccountActivation/AccountActivation")),
);
const Companies = lazy(() => lazyImport(() => import("./Company/Companies")));
const LoginPage = lazy(() => lazyImport(() => import("./Login/LoginPage")));
const VerifyIdentity = lazy(() =>
  lazyImport(() => import("./Login/VerifyIdentity")),
);
const SignupPage = lazy(() => lazyImport(() => import("./Signup/SignupPage")));
const NotAuthenticated = lazy(() =>
  lazyImport(() => import("./Home/NotAuthenticatedDefault")),
);
const Home = lazy(() => lazyImport(() => import("./Home/Home")));
const OtpVerifierAuth = lazy(() =>
  lazyImport(() => import("./OtpVerifier/OtpVerifierAuth")),
);
const OtpForgotPassword = lazy(() =>
  lazyImport(() => import("./OtpVerifier/OtpForgotPassword")),
);

export const appRoutes = (routes) => {
  return [
    {
      path: "/verifyIdentity",
      Component: VerifyIdentity,
      requireLogin: false,
      requireAuthorization: false,
      requireDomain: false,
    },
    {
      path: "/logout",
      Component: LogoutPage,
      exact: true,
      requireAuthorization: false,
      requireDomain: false,
      requireLogin: false,
    },
    {
      path: "/",
      Component: Home,
      exact: true,
      requireAuthorization: false,
      requireDomain: true,
      requireLogin: true,
      breadcrumb: () => (
        <BreadcrumbMenuItem title="Home" path="/" showPipe={false} />
      ),
    },
    {
      path: "/login",
      Component: LoginPage,
      requireLogin: false,
      requireAuthorization: false,
      requireDomain: false,
    },
    { path: "/error", Component: ErrorMessage, requireLogin: false },
    {
      path: "/signup",
      Component: SignupPage,
      exact: true,
      requireAuthorization: false,
      requireLogin: false,
    },
    {
      path: "/otpVerifierAuth",
      Component: OtpVerifierAuth,
      requireLogin: false,
      requireAuthorization: false,
      requireDomain: false,
    },
    {
      path: "/otpForgotPassword",
      Component: OtpForgotPassword,
      requireLogin: false,
      requireAuthorization: false,
      requireDomain: false,
    },
    {
      path: "/companies",
      Component: Companies,
      requireAuthorization: false,
      requireDomain: true,
      requireSelectedCompany: false,
      requireLogin: true,
      breadcrumb: () => (
        <BreadcrumbMenuItem title="Companies" path="/companies" showPipe />
      ),
    },
    {
      path: "/activate/:key",
      Component: AccountActivation,
      requireLogin: false,
    },
    {
      path: "/reset/finish",
      Component: PasswordReset,
      requireLogin: false,
    },
    {
      path: "/reset/finishcpf",
      Component: PasswordCpfReset,
      requireLogin: false,
    },
    {
      path: "/company",
      Component: Company,
      requireSelectedCompany: true,
      requireDomain: true,
      requireLogin: true,
      breadcrumb: () => (
        <BreadcrumbMenuItem title="Company" path="/company" showPipe />
      ),
    },
    {
      path: "/profile",
      Component: ProfilePage,
      requireLogin: true,
      breadcrumb: () => (
        <BreadcrumbMenuItem title="Profile" path="/profile" showPipe />
      ),
    },
    {
      path: "/workspaces",
      Component: WorkspacesPage,
      requireSelectedCompany: true,
      requireDomain: true,
      requireLogin: true,
      breadcrumb: () => (
        <BreadcrumbMenuItem title="Workspaces" path="/workspaces" showPipe />
      ),
    },
    {
      path: "/workspace",
      Component: Workspace,
      requireSelectedCompany: true,
      requireDomain: true,
      requireLogin: true,
      breadcrumb: () => <CustomBreadcrumb routes={routes.routeWorkspace()} />,
    },
    {
      path: "/tool/:toolId?",
      Component: ToolContainer,
      requireSelectedCompany: true,
      requireDomain: true,
      requireLogin: true,
      breadcrumb: () => <CustomBreadcrumb routes={routes.routeTool()} />,
    },
    { path: "PasswordReset", Component: InvitePage, requireLogin: false },
    {
      path: "/reactivation",
      Component: AccountReactivation,
      requireLogin: false,
    },
    { path: "/forgotpassword", Component: ForgotPassword, requireLogin: false },
    {
      path: "/forgotpasswordcpf",
      Component: ForgotPasswordCpf,
      requireLogin: false,
    },
    {
      path: "/invalidDomain",
      Component: InvalidDomain,
      requireAuthorization: false,
      requireDomain: false,
      requireLogin: true,
    },
    {
      path: "/domainconfig",
      Component: DomainConfig,
      requireAuthorization: false,
      requireDomain: true,
      requireLogin: true,
      breadcrumb: () => (
        <BreadcrumbMenuItem title="Domain" path="/domainconfig" showPipe />
      ),
    },
    {
      path: "/logs",
      Component: DomainLogs,
      requireAuthorization: false,
      requireDomain: true,
      requiredRoles: ["OWNER"],
      requireLogin: true,
      breadcrumb: () => (
        <BreadcrumbMenuItem title="Logs" path="/logs" showPipe />
      ),
    },
    {
      path: "/domainselection",
      Component: DomainSelection,
      requireAuthorization: false,
      requireLogin: true,
      requireDomain: false,
      breadcrumb: () => (
        <BreadcrumbMenuItem
          title="Domain Selection"
          path="/domainselection"
          showPipe
        />
      ),
    },
    {
      path: "/redirect",
      Component: SmartLinkController,
      requireAuthorization: false,
      requireDomain: false,
      requireLogin: true,
    },
    {
      path: "/skills-assessment/:toolId?",
      Component: ParticipantWorkflowReply,
      requireAuthorization: false,
      requireDomain: false,
      requireLogin: true,
    },
    {
      path: "/skills-assessment-evaluators/:toolId?",
      Component: SelectionEvaluatorsReply,
      requireAuthorization: false,
      requireDomain: false,
      requireLogin: true,
    },
  ];
};

export const DefaultLoginComponent = Home;
export const DefaultNotAuthorized = NotAuthenticated;
export const InvalidDomainRoute = InvalidDomain;
