import React from "react";
import { injectIntl } from "react-intl";
import SimpleBubbleChart from "../../../../../Common/SimpleBubbleChart";
import { calculateAge } from "../../../../../../utils/DateUtils";
import utils from "../../../../../../utils/toolUtils";
import { getObjectInfoById } from "../../../../../../utils/ArrayUtils";
import { getProfessionalInfo } from "../../../../../../utils/peopleEvaluationUtils";

const { sortHistoryByDate } = utils;

const TrajectoryHistoryField = (props) => {
  const {
    filteredEvaluations = [],
    usersCompanyProfiles = [],
    messages,
    intl,
  } = props;

  const displayCarrearGraph = () => {
    const getLabelByHouseTime = (time) => {
      if (time <= 1) return "oneY";
      if (time > 1 && time <= 3) return "threeY";
      if (time > 3 && time <= 5) return "fiveY";
      if (time > 5 && time <= 10) return "tenY";
      if (time > 10 && time <= 15) return "fithteenY";
      if (time > 15) return "mFithteenY";
    };

    const getValuesArray = (valuesObj) => {
      let vals = [];

      Object.keys(valuesObj).forEach((slug) => {
        vals = [...vals, { ...valuesObj[slug] }];
      });

      return vals;
    };

    const defaultBody = () => {
      return {
        oneY: { name: "1", value: 0 },
        threeY: { name: "3", value: 0 },
        fiveY: { name: "5", value: 0 },
        tenY: { name: "10", value: 0 },
        fithteenY: { name: "15", value: 0 },
        mFithteenY: { name: "> 15", value: 0 },
      };
    };

    const graphPlotRoles = {};

    function updateGraphPlotRoles(toPositionText, timeLabel) {
      const positionPlotData = graphPlotRoles[toPositionText];

      graphPlotRoles[toPositionText] =
        positionPlotData && positionPlotData.values
          ? positionPlotData
          : {
              mainLabel: toPositionText,
              legendLabel: "",
              values: defaultBody(),
            };

      graphPlotRoles[toPositionText].values[timeLabel].value += 1;
    }

    filteredEvaluations.forEach(({ peopleEvaluation }) => {
      const { professionalProfile, salaryTrajectory } = peopleEvaluation;
      const { professional = {} } = professionalProfile;
      const { entryDate } = professionalProfile;

      const profileMemberInfo = getProfessionalInfo(professional);
      const orderedHistory = sortHistoryByDate(
        salaryTrajectory.professionalAscentions,
        "fromDate",
      );

      const { companyPosition = {} } = getObjectInfoById(
        profileMemberInfo.jhiUserId,
        usersCompanyProfiles,
        "jhiUserId",
      );

      let finalRole =
        companyPosition && companyPosition.title ? companyPosition.title : "";
      let houseTime = calculateAge(entryDate);

      // eslint-disable-next-line no-restricted-syntax
      for (const index in orderedHistory) {
        if (orderedHistory[index].currentRole) {
          houseTime = calculateAge(orderedHistory[0].fromDate);
          finalRole = orderedHistory[0].toPositionText;
          break;
        }
      }

      if (finalRole) {
        const timeLabel = getLabelByHouseTime(houseTime);
        updateGraphPlotRoles(finalRole, timeLabel);
      }
    });

    const finalDataArray = [];

    Object.keys(graphPlotRoles).forEach((roleKey) => {
      const { mainLabel, legendLabel, values } = graphPlotRoles[roleKey];

      finalDataArray.push({
        mainLabel,
        legendLabel,
        values: getValuesArray(values),
      });
    });

    finalDataArray.reverse();

    return finalDataArray.length > 0 ? (
      <div
        style={{
          position: "relative",
          paddingBottom: "50px",
          paddingTop: "15px",
        }}
      >
        <div
          style={{
            maxHeight: "480px",
            paddingRight: "20px",
            overflowX: "auto",
          }}
        >
          <SimpleBubbleChart
            data={finalDataArray}
            tooptipLegend={intl.formatMessage(messages.labels_professionals)}
            tickSize={50}
            hideDataTick
          />
        </div>
        <div
          style={{
            position: "absolute",
            left: "0",
            right: "0",
            bottom: "5px",
            backgroundColor: "#fff",
            borderTop: "1px solid #ccc",
            paddingRight: "20px",
          }}
        >
          <SimpleBubbleChart
            data={[
              {
                mainLabel: "",
                legendLabel: intl.formatMessage(messages.labels_time_years),
                values: [
                  { name: "1", value: "" },
                  { name: "3", value: "" },
                  { name: "5", value: "" },
                  { name: "10", value: "" },
                  { name: "15", value: "" },
                  { name: "> 15", value: "" },
                ],
              },
            ]}
            containerHeight={50}
            hideBubblesWhenLast
            tickSize={50}
            showXLine={false}
            showTick={false}
          />
        </div>
      </div>
    ) : (
      <h5 style={{ width: "100%", textAlign: "center" }}>
        Nenhum histórico profissional foi registrado
      </h5>
    );
  };

  return (
    <div className="evaluation-card">
      <div className="card-header">
        <div className="card-title">
          <h4>{intl.formatMessage(messages.titles_cards_career)}</h4>
        </div>
      </div>
      <div className="card-body">{displayCarrearGraph()}</div>
    </div>
  );
};

export default injectIntl(TrajectoryHistoryField);
