import axios from "axios";

import { addAccess, fetchEntityUsersWithRole } from "./accessLevelsActions";

import {
  UPDATE_CENARY,
  FETCH_CENARY_MEMBERS,
  FETCH_CENARIES,
  CLEAR_CENARIES,
  SELECT_CENARY,
  FETCH_CENARY_PARTICIPANTS,
  DELETE_CENARIE,
} from "./types";
import {
  getObjectInfoById,
  getOnlySlugValues,
  getOnlySublevelArrays,
} from "../utils/ArrayUtils";

import { swapOrder } from "./orderedEntityActions";
import { appRefreshSelectedTool } from "./appActions";
import { fetchAllSceneryThemes } from "./themesActions";
import { fetchAllThemesQuestions } from "./questionsActions";

const getReportFollowupCenaries = (idTools) => {
  return axios.get(`/api/cenaries/selectedTool/${idTools}`).then((res) => res);
};

export const fetchFollowupOkrCenaries = (idTools) => {
  return (dispatch) => {
    async function getReportFollowupData() {
      let followupDataCenaries = {};

      if (idTools && idTools.length) {
        await Promise.all(
          idTools.map(async (id) => {
            const reportFollowup = await getReportFollowupCenaries(id);

            if (reportFollowup && reportFollowup.data) {
              followupDataCenaries = {
                ...followupDataCenaries,
                [id]: reportFollowup.data,
              };
            }
          }),
        );
        dispatch({
          type: "FETCH_REPORT_OKR_FOLLOWUP_CENARIES",
          payload: followupDataCenaries,
        });
      }
    }
    getReportFollowupData();
  };
};

export const reorderSceneries = async (newScenerie, selectedToolId) => {
  return async (dispatch) => {
    const fetchedSceneries = await onlyFetchCenarys(selectedToolId);
    const toolSceneries = fetchedSceneries.data;

    if (toolSceneries) {
      const newScenerieIndex = getObjectInfoById(
        newScenerie.id,
        toolSceneries,
        "id",
        true,
      );

      dispatch(
        swapOrder(
          toolSceneries,
          newScenerieIndex,
          newScenerie.order,
          "cenary",
          selectedToolId,
        ),
      );
    }
  };
};

export const onlyPutScenery = async (sceneryBody) => {
  const newScenery = { ...sceneryBody };
  delete newScenery.responsible;

  const { data } = await axios.put("/api/cenaries", newScenery);

  return { data };
};

export const updateCenary = (
  cenary,
  selectedToolId,
  doReorder = false,
  callback,
) => {
  const { id: cenaryId } = cenary || {};

  return async (dispatch) => {
    try {
      const response = await dispatch(
        putScenery(cenary, selectedToolId, doReorder),
      );

      if (typeof callback === "function") callback(response);
    } catch (err) {
      console.log("Erro ao criar cenário: ", err);
    }
  };
};

export const updateCenaryResponsible = (cenary, member) => {
  const updatedCenary = {
    ...cenary,
    responsible: member,
  };
  return (dispatch) => {
    axios.post(`/api/cenaries/${cenary.id}/responsible/${member.id}`);
    return dispatch({ type: SELECT_CENARY, payload: updatedCenary });
  };
};

export const fetchCenaryMembers = (cenaryId) => {
  return (dispatch) => {
    return axios.get(`/api/cenaries/${cenaryId}/members`).then((res) => {
      const cenaryMembers = res.data;
      dispatch({ type: FETCH_CENARY_MEMBERS, payload: cenaryMembers });
    });
  };
};

export const fetchCenaryParticipants = (cenaryId) => {
  return (dispatch) => {
    return axios.get(`/api/cenaries/${cenaryId}/participants`).then((res) => {
      const cenaryParticipants = res.data;
      dispatch({
        type: FETCH_CENARY_PARTICIPANTS,
        payload: cenaryParticipants,
      });
    });
  };
};

export const fetchSceneryCreationTree = (sceneryId) => {
  return async (dispatch) => {
    const themes = await dispatch(fetchAllSceneryThemes([sceneryId]));

    const onlyThemes = getOnlySublevelArrays(themes);

    if (Array.isArray(onlyThemes)) {
      await dispatch(
        fetchAllThemesQuestions(getOnlySlugValues(onlyThemes, "id")),
      );
    }
  };
};

export const selectCenary = (id) => {
  return async (dispatch) => {
    if (id === null) {
      return dispatch({ type: SELECT_CENARY, payload: null });
    }
    return axios.get(`/api/cenaries/${id}`).then(async (res) => {
      await dispatch({ type: SELECT_CENARY, payload: res.data });
      await dispatch(fetchEntityUsersWithRole(id, "CENARY"));
    });
  };
};

export const putScenery = (sceneryBody, selectedToolId, doReorder = false) => {
  return async (dispatch) => {
    const { data } = await onlyPutScenery(sceneryBody);
    const { id: sceneryId } = data || {};

    if (doReorder) dispatch(reorderSceneries(data, selectedToolId));

    dispatch({
      type: UPDATE_CENARY,
      payload: data,
    });

    await dispatch(selectCenary(sceneryId));
    await dispatch(fetchSceneryCreationTree(sceneryId));

    return { data, isNew: true };
  };
};

export const getScenery = (sceneryId = "") => {
  const cachedScenery = localStorage.getItem(String(`scenery${sceneryId}`));

  if (cachedScenery) return cachedScenery[0];

  return axios.get(`/api/cenaries/${sceneryId}`).then((res) => {
    localStorage.setItem(String(`scenery${sceneryId}`), [res.data]);
    return res.data;
  });
};

export const onlyFetchCenarys = (selectedToolId) => {
  return axios
    .get(`/api/cenaries/selectedTool/${selectedToolId}`)
    .then((res) => res);
};

export const fetchCenarys = (selectedToolId) => {
  return async (dispatch) => {
    const fetchedSceneries = await onlyFetchCenarys(selectedToolId);

    const { data: allSceneries = [] } = fetchedSceneries || {};

    await dispatch(autoCenarySelection(allSceneries));
    dispatch({ type: FETCH_CENARIES, payload: allSceneries });

    return allSceneries;
  };
};

export const autoCenarySelection = (allSceneries = []) => {
  const cenaryToSelect =
    allSceneries.filter((cenary) => cenary.order === 0)[0] || {};

  const { id: cenaryToSelectId = null } = cenaryToSelect || {};

  if (!cenaryToSelectId) return;

  return async (dispatch) => {
    await dispatch(selectCenary(cenaryToSelectId));
  };
};

export const fetchCenarysWithReturn = (selectedToolId) => {
  return (dispatch) => {
    return axios
      .get(`/api/cenaries/selectedTool/${selectedToolId}`)
      .then((res) => {
        const selectedCenarys = res.data;
        return selectedCenarys;
      });
  };
};

export const deleteCenarys = (cenary) => {
  const cenary_id = cenary.id;
  const selectedToolId = cenary.selectedTool.id;
  return (dispatch) => {
    return axios.delete(`/api/cenaries/${cenary_id}`).then((res) => {
      if (res.data) {
        dispatch({
          type: DELETE_CENARIE,
          payload: { cenaryId: res.data },
        });
      }
    });
  };
};

export const archiveCenary = (cenary) => {
  return (dispatch) => {
    return axios.post("/api/archiveUnarchive", cenary).then(() => {
      dispatch(appRefreshSelectedTool(false));
    });
  };
};

export const clearCenaries = () => {
  return (dispatch) => {
    return dispatch({ type: CLEAR_CENARIES });
  };
};

export const setTemplate = (selectedToolId) => {
  return (dispatch) => {
    return axios
      .post(`/api/cenaries/createFromTemplate/${selectedToolId}`)
      .then((res) => {
        const { id: cenaryId = 0 } = res.data;

        dispatch(addAccess(cenaryId, "CENARY"));
        return res;
      });
  };
};
