import { withStyles } from "@material-ui/core/styles";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionActions";

export const PanelSummary = withStyles({
  root: {
    backgroundColor: ({ bgcolor = "#eee" }) => bgcolor,
    borderBottom: ({ borderBottom = "1px solid #ccc" }) => borderBottom,
    marginBottom: -1,
    height: 40,
    "&$expanded": {
      minHeight: "50px !important",
    },
    "&.Mui-focused": {
      backgroundColor: ({ bgcolor = "inherit" }) => bgcolor,
    },
    padding: "0px 10px",
    flexDirection: "row-reverse",
    cursor: ({ cursor = "pointer" }) => cursor,
  },
  content: {
    fontFamily: "Montserrat !important",
    fontWeight: "500",
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expandIcon: {
    padding: "0px",
    width: "40px",
    height: "40px",
    lineHeight: "45px",
    fontSize: "18px",
    marginRight: "5px",
    transition: ".3s",
    "&:hover": {
      color: "#111",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    "& > span ": {
      height: "100%",
      display: "block",
    },
    "& > span > div > svg": {
      fontSize: "18px",
    },
  },
  expanded: {},
})(AccordionSummary);

export const PanelDetails = withStyles((theme) => ({
  root: {
    padding: ({ padding = theme.spacing(2) }) => padding,
    display: "block",
    boxSizing: "none",
  },
}))(AccordionDetails);
