import React from "react";

const getSwotEvaluationByID = (insightID, allSwotEvaluation) => {
  let finalEvaluation = null;
  if (allSwotEvaluation && allSwotEvaluation.length > 0) {
    allSwotEvaluation.forEach((evaluation) => {
      if (evaluation.insightId === insightID) {
        finalEvaluation = evaluation;
      }
    });
  }

  return finalEvaluation;
};

function buildSwotEvaluationTotalSum(allSwotEvaluation) {
  const finalSlugsEvaluations = {
    strongPoints: [],
    weaknesses: [],
    opportunity: [],
    threats: [],
  };

  const addToEvaluation = (slug, value) => {
    finalSlugsEvaluations[slug].push(value);
  };

  allSwotEvaluation.forEach((evaluationInfo) => {
    if (evaluationInfo.questionName === "PONTOS FORTES") {
      addToEvaluation("strongPoints", evaluationInfo.swotAnalysis.score);
    }
    if (evaluationInfo.questionName === "PONTOS FRACOS") {
      addToEvaluation("weaknesses", evaluationInfo.swotAnalysis.score);
    }
    if (evaluationInfo.questionName === "OPORTUNIDADES") {
      addToEvaluation("opportunity", evaluationInfo.swotAnalysis.score);
    }
    if (evaluationInfo.questionName === "AMEAÇAS") {
      addToEvaluation("threats", evaluationInfo.swotAnalysis.score);
    }
  });

  return finalSlugsEvaluations;
}

function getCountEvaluationsBySlug(evaluations, evaluationSlug) {
  return evaluations[evaluationSlug] ? evaluations[evaluationSlug].length : [];
}

function getMaxEvaluationBySlug(
  evaluations,
  evaluationSlug,
  maxSwotPoints = 125,
) {
  return maxSwotPoints * getCountEvaluationsBySlug(evaluations, evaluationSlug);
}

function getRealEvaluationBySlug(evaluations, evaluationSlug) {
  let finalSum = 0;

  if (evaluations[evaluationSlug] && evaluations[evaluationSlug].length > 0) {
    evaluations[evaluationSlug].forEach((val) => {
      finalSum += val;
    });
  }

  return finalSum;
}

function getSwotTotalProgress(evaluationValue, maxTotalValue) {
  return (evaluationValue * 100) / maxTotalValue;
}

function buildAllEvaluationInfo(allSwotEvaluation) {
  const totalSwotEvaluation = buildSwotEvaluationTotalSum(allSwotEvaluation);
  const totalBarProgress = {};
  const totalMaxEvaluation = {};
  const totalRealEvaluation = {};

  Object.keys(totalSwotEvaluation).forEach((key) => {
    totalRealEvaluation[key] = getRealEvaluationBySlug(
      totalSwotEvaluation,
      key,
    );
    totalMaxEvaluation[key] = getMaxEvaluationBySlug(totalSwotEvaluation, key);

    totalBarProgress[key] = getSwotTotalProgress(
      totalRealEvaluation[key],
      totalMaxEvaluation[key],
    ).toFixed(1);
  });

  return {
    totalSwotEvaluation,
    totalBarProgress,
    totalMaxEvaluation,
    totalRealEvaluation,
  };
}

const renderSwotColumnLabel = (intl, globalMessages) => {
  return (
    <>
      <th style={{ textAlign: "left" }}>
        {intl.formatMessage(globalMessages.cenary)}
      </th>
      <th style={{ textAlign: "left" }}>
        {intl.formatMessage(globalMessages.environment)}
      </th>
      <th style={{ textAlign: "left" }}>
        {intl.formatMessage(globalMessages.swot)}
      </th>
      <th style={{ textAlign: "left" }}>
        {intl.formatMessage(globalMessages.insight)}
      </th>
    </>
  );
};
export {
  getSwotEvaluationByID,
  renderSwotColumnLabel,
  buildSwotEvaluationTotalSum,
  getCountEvaluationsBySlug,
  getMaxEvaluationBySlug,
  getRealEvaluationBySlug,
  getSwotTotalProgress,
  buildAllEvaluationInfo,
};
