import axios from "axios";
import {
  grantCreatorAccess,
  grantResponsibleAccess,
  removeInsightAccess,
} from "./accessControllerActions";
import { appRefreshSelectedTool } from "./appActions";
import { getInsightTraceabilities } from "./insightTraceabilitiesActions";
import {
  sendPrioritizationNotification,
  sendResponsibleNotification,
} from "./notifications/controllerActions";
import { getAllUserAvatars } from "./profileActions";
import {
  DELETE_INSIGHT_GOAL,
  FETCH_ALL_INSIGHT_GOALS,
  FETCH_ALL_TOOLS_INSIGHT_GOALS,
  FETCH_INSIGHT_ATTACHMENTS,
  FETCH_INSIGHT_FOLLOWUP,
  FETCH_INSIGHT_RESPONSABLE,
  SELECT_INSIGHT,
  UNSELECT_INSIGHT,
  UPDATE_INSIGHT_DATA,
  UPDATE_QUESTION_ANSWER,
  OPEN_INSIGHT_MODAL,
} from "./types";

async function getAvatar(responsibleMember = {}) {
  if (responsibleMember) {
    const { userData, id: userId } = responsibleMember;
    const { avatarBlobId = null } = userData || {};

    const blobInfo = [
      {
        userId,
        avatarBlobId,
      },
    ];

    const avatars = await getAllUserAvatars(blobInfo);

    const selectedAvatar = avatars.find(
      (avatar) => avatar.avatarBlobId === userData.avatarBlobId,
    );
    const validAvatar =
      selectedAvatar && selectedAvatar.base64
        ? `data:image/png;base64,${selectedAvatar.base64}`
        : "";

    return {
      avatar: validAvatar,
    };
  }
  return {
    avatar: "",
  };
}

export const postInsight = (insightBody, questionId) => {
  return axios
    .post(`/api/insights/question/${questionId}`, insightBody)
    .then((res) => res);
};

export const addNewInsight = (insightBody, questionId) => {
  return async (dispatch) => {
    if (insightBody && questionId) {
      const newInsight = await postInsight(insightBody, questionId);

      if (newInsight.data) {
        const { data: insightData = {} } = newInsight || {};

        const { id: insightId = null, owner = {} } = insightData;

        const { id: ownerId = null } = owner;

        dispatch(grantCreatorAccess(ownerId, insightId));

        dispatch({
          type: UPDATE_QUESTION_ANSWER,
          payload: {
            questionId,
            answer: { ...newInsight.data, questionId, isNew: true },
          },
        });
      }
    }
  };
};

export const fetchAllInsightsGoals = (selectedToolId) => {
  return async (dispatch) => {
    const fetchedRes = await axios.get(
      `/api/selected-tools/${selectedToolId}/insightsGoals`,
    );

    dispatch({ type: FETCH_ALL_INSIGHT_GOALS, payload: fetchedRes.data });
  };
};

export const fetchToolsInsightsGoals = (selectedToolIds = []) => {
  return async (dispatch) => {
    const allInsightsGoals = await axios
      .get(`/api/selected-tools/allInsightsGoals/${selectedToolIds}`)
      .then((res) => res.data);

    dispatch({
      type: FETCH_ALL_TOOLS_INSIGHT_GOALS,
      payload: allInsightsGoals,
    });

    // dispatch({ type: FETCH_ALL_INSIGHT_GOALS, payload: fetchedRes.data });
  };
};

export const refetchInsight = (insightID) => {
  return async (dispatch) => {
    const updatedInsight = await getInsight(insightID);

    const selectedAvatar = await getAvatar(updatedInsight.responsibleMember);

    const newUpdatedInsight = {
      ...updatedInsight,
      avatar: selectedAvatar.avatar,
    };

    dispatch({ type: UPDATE_INSIGHT_DATA, payload: newUpdatedInsight });
  };
};

export const addInsightGoal = (insightId, goal, selectedToolId) => {
  return (dispatch) => {
    axios.post(`/api/insights/${insightId}/goal`, goal).then((res) => {
      dispatch(refetchInsight(insightId));
      dispatch(fetchAllInsightsGoals(selectedToolId));
    });
  };
};

export const updateInsightGoal = (insightId, goal, selectedToolId) => {
  return (dispatch) => {
    axios.put("/api/insight-goals/", goal).then(() => {
      dispatch(refetchInsight(insightId));
      dispatch(fetchAllInsightsGoals(selectedToolId));
    });
  };
};

export const deleteInsightGoal = (insightID, goal) => {
  return (dispatch) => {
    return axios
      .delete(`/api/insights/${insightID}/goal/${goal.id}`)
      .then(() => {
        dispatch({ type: DELETE_INSIGHT_GOAL, payload: goal });
      });
  };
};

export const fetchFollowupOkr = (idTools) => {
  return (dispatch) => {
    return axios
      .get(`/api/selected-tools/${idTools}/insightFollowups`)
      .then((res) => res);
  };
};
export const fetchInsightFollowUp = (insightId) => {
  return (dispatch) => {
    return axios
      .get(`/api/insight-followups/insight/${insightId}`)
      .then((res) => {
        dispatch({
          type: FETCH_INSIGHT_FOLLOWUP,
          payload: { followUps: res.data, insightId },
        });
      });
  };
};

export const addInsightFollowUp = (insightId, followup) => {
  return (dispatch) => {
    axios
      .post(`/api/insight/${insightId}/add-followup`, followup)
      .then((res) => {
        return dispatch(fetchInsightFollowUp(insightId));
      });
  };
};

export const updateInsightFollowUp = (insightId, followupBody) => {
  return (dispatch) => {
    axios.put("/api/insight-followups/", followupBody).then((res) => {
      dispatch(fetchInsightFollowUp(insightId));
    });
  };
};

export const deleteInsightFollowUp = (insightId, followup) => {
  return (dispatch) => {
    return axios
      .delete(`/api/insights/${insightId}/followups/${followup.id}`)
      .then(() => {
        dispatch(fetchInsightFollowUp(insightId));
      });
  };
};

export const updateInsightResponsible = (
  originalInsight,
  memberId,
  keepInsightAvatar,
) => {
  return (dispatch) => {
    axios
      .post(
        `/api/answers/${
          originalInsight.id
        }/responsible/${memberId}/?responsibleType=${"MEMBER"}`,
      )
      .then(async (res) => {
        const { responsibleMember = {}, id: insightId } = res.data;

        const insightWithAvatar = await getAvatar(responsibleMember);
        let newInsight = {};

        if (!keepInsightAvatar) {
          newInsight = {
            ...res.data,
            ...insightWithAvatar,
          };
        } else {
          newInsight = {
            ...res.data,
            avatar: originalInsight.avatar,
          };
        }

        await dispatch({
          type: UPDATE_INSIGHT_DATA,
          payload: { ...originalInsight, ...newInsight },
        });

        await dispatch(grantResponsibleAccess(memberId, insightId));

        if (
          originalInsight?.responsibleMember &&
          originalInsight?.responsibleMember?.id !== responsibleMember.id
        ) {
          await dispatch(
            removeInsightAccess({
              ...originalInsight?.responsibleMember,
              entityId: insightId,
              role: "RESPONSIBLE",
            }),
          );
        }

        dispatch(sendResponsibleNotification(insightId));
      });

    // memberIds, entityId, entityType,
  };
};

export const updateInsightScore = (insightID, insight = {}) => {
  const avatar = insight && insight.avatar;

  return (dispatch) => {
    axios.post(`/api/insight/${insightID}/updateScore`).then((res) => {
      dispatch({
        type: UPDATE_INSIGHT_DATA,
        payload: { ...res.data, avatar },
      });
    });
  };
};

export const selectInsight = (insightID, insight = {}) => {
  return async (dispatch) => {
    await dispatch({ type: SELECT_INSIGHT, payload: { insightID } });
    dispatch(fetchInsightResponsible(insightID));
    dispatch(getInsightTraceabilities(insightID));
  };
};

export const convertToInsight = (insightId) => {
  return axios
    .post(`/api/answers/${insightId}/convertToInsight`)
    .then((res) => {
      return res.data;
    });
};

export const convertAnswerInsight = (
  answerBody,
  question,
  enableSelectInsight = true,
) => {
  const answerType = answerBody ? answerBody.type : {};

  return async function action(dispatch) {
    if (answerBody.id) {
      if (answerType !== "insight") {
        const insightData = await convertToInsight(answerBody.id);

        const { owner = {}, responsible } = insightData || {};
        const { id: ownerId = null } = owner;

        if (enableSelectInsight)
          await dispatch(await selectInsight(answerBody.id, answerBody));

        const data = {
          questionId: question.id,
          answer: insightData,
        };
        await dispatch(grantCreatorAccess(ownerId));

        if (!responsible && !responsible.id)
          await dispatch(updateInsightResponsible(insightData, ownerId));

        dispatch({ type: UPDATE_QUESTION_ANSWER, payload: data });
      } else {
        const { owner = {}, responsibleMember = {} } = answerBody || {};
        const { id: ownerId = null } = owner || {};
        const { id: responsibleId = null } = responsibleMember || {};

        if (enableSelectInsight)
          await dispatch(await selectInsight(answerBody.id, answerBody));

        const responsibleOwnerId = responsibleId || ownerId;

        if (!responsibleMember || responsibleMember.id)
          dispatch(
            updateInsightResponsible(
              { ...answerBody, isNew: false },
              responsibleOwnerId,
            ),
          );
      }
    }
  };
};

export const unselectInsight = () => {
  return (dispatch) => {
    dispatch({ type: UNSELECT_INSIGHT, payload: null });
  };
};

export const getInsight = (insightID) => {
  if (!insightID) return {};

  return axios.get(`/api/insights/${insightID}`).then((res) => {
    return res.data;
  });
};

export const fetchInsightResponsible = (insightID) => {
  return async (dispatch) => {
    const res = await axios.get(`/api/insights/${insightID}/responsible`);

    dispatch({ type: FETCH_INSIGHT_RESPONSABLE, payload: res.data });
  };
};

export const updateInsightData = (insight) => {
  return (dispatch) => {
    return axios.put("/api/insights/", insight).then((res) => {
      const { data } = res;
      dispatch({ type: UPDATE_INSIGHT_DATA, payload: data });
    });
  };
};

export const updateEvaluations = (insight, evaluations) => {
  const { id: insightId = "" } = insight || {};

  return (dispatch) => {
    return axios
      .post(`/api/insights/${insightId}/evaluations`, evaluations)
      .then((res) => {
        const { data } = res;
        dispatch({ type: UPDATE_INSIGHT_DATA, payload: data });
        dispatch(sendPrioritizationNotification(insightId));
      });
  };
};

export const searchInsights = (workspaceId, insightName) => {
  return axios
    .get(
      `/api/searchInsights/?workspaceId=${workspaceId}&insightName=${insightName}`,
    )
    .then((res) => {
      return res.data;
    });
};

export const addAttachment = (insightId, attachmentId) => {
  return axios.post(`/api/insight/${insightId}/add-attachment/${attachmentId}`);
};

export const fetchAttachments = (insightId) => {
  return (dispatch) => {
    return axios.get(`/api/insight/${insightId}/attachments`).then((res) => {
      const attachments = res.data;
      dispatch({ type: FETCH_INSIGHT_ATTACHMENTS, payload: attachments });
    });
  };
};

export const deleteAttachment = (insightId, attachment) => {
  return axios.delete(
    `/api/insights/${insightId}/attachments/${attachment.id}`,
  );
};

export const updateInsightName = (questionId, insightId, newName) => {
  return async (dispatch) => {
    const response = await axios.post(`/api/insights/${insightId}/changeName`, {
      name: newName,
    });

    const { responsibleMember } = response.data;

    const avatarInsight = await getAvatar(responsibleMember);

    const insightWithAvatar = {
      ...response.data,
      avatar: avatarInsight.avatar,
    };

    const data = {
      questionId,
      answer: insightWithAvatar,
    };

    dispatch({ type: UPDATE_QUESTION_ANSWER, payload: data });
  };
};

export const archiveInsights = (insightsIdList, callbacks) => {
  const { onSuccess, onError } = callbacks || {};

  return async (dispatch) => {
    try {
      await axios
        .post("/api/insights/archiveUnarchive", insightsIdList)
        .then((res) => {
          dispatch(appRefreshSelectedTool(false));
          if (onSuccess) onSuccess(res);
        });

      return true;
    } catch (err) {
      onError(err);
    }

    return false;
  };
};

export const archiveInsightsList = (insightsIdList = []) => {
  return async (dispatch) => {
    if (Array.isArray(insightsIdList) && insightsIdList.length > 0) {
      await dispatch(archiveInsights(insightsIdList));
      dispatch(appRefreshSelectedTool(false));
    }
  };
};

export const openInsightModal = (insight = false) => {
  const { id: selectedInsightId = false } = insight || {};

  if (!selectedInsightId) return;

  return (dispatch) => {
    dispatch({ type: OPEN_INSIGHT_MODAL, payload: insight });
  };
};

export const moveInsightToQuestionId = (insightId, newQuestionId) => {
  return axios.post(
    `/api/insights/${insightId}/changeQuestion/${newQuestionId}`,
  );
};

export const formatEmptyValue = (value) => {
  if (value === " ") {
    return "empty";
  }
  return value;
};

export const saveEvaluations = (
  insightId,
  currentLeading,
  currentPerformance,
  assessmentDetails,
  gapDetails,
) => {
  return axios.post(`/api/insights/${insightId}/skillsAssessmentEvaluations`, {
    leading: {
      current: formatEmptyValue(currentLeading.current),
      details: currentLeading.details,
    },
    performance: {
      current: formatEmptyValue(currentPerformance.current),
      details: currentPerformance.details,
    },
    details: assessmentDetails,
    gapDetails,
  });
};
