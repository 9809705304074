import axios from "axios";
import axiosRequestProfiler from "axios-api-profiler";
import { loadProgressBar } from "axios-progress-bar";

import env from "../utils/environmentUtils";

const commonDomainHeaders =
  localStorage.getItem("@rookau:commonDomainHeaders") || null;
const parsedHeader = commonDomainHeaders ? JSON.parse(commonDomainHeaders) : {};

const token = localStorage.getItem("splace-authenticationToken");
const Authorization = token ? `Bearer ${token}` : null;

const headers = {
  common: { ...parsedHeader, Authorization },
};

const configNode = {
  baseURL: env.getBaseURLNodeApi(),
  headers,
};

const configApiJava = {
  baseURL: env.getBaseURLJavaApi(),
  headers,
};

const apiNode = axios.create(configNode);
const apiJava = axios.create(configApiJava);

if (env.getAmbiente() === "desenvolvimento") {
  axiosRequestProfiler(apiNode);
}

loadProgressBar(null, apiNode);

export { apiNode, apiJava };
