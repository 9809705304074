import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import kpiUtils from "../../../../utils/kpis";
import TargetColumns from "../../TargetsColumns";
import { getObjectInfoById } from "../../../../utils/ArrayUtils";

const {
  getCountGoalStatus,
  getCountProcessesGoalStatus,
  getThemePopover,
  getProcessPopover,
} = kpiUtils;

const messages = defineMessages({
  summary: { id: "summary" },
  kpi_table_text_indicators_upper: { id: "kpi_table_text_indicators_upper" },
  kpi_table_text_subthemes_upper: { id: "kpi_table_text_subthemes_upper" },
});

const ColumnsEvaluations = (props) => {
  const {
    goals = [],
    kpiAdministration = {},
    goalTargetOptions = [],
    displayProcesses = [],
    intl,
    isPeriodFilterActive: filter,
  } = props;

  const processStatus = getCountProcessesGoalStatus(
    displayProcesses,
    kpiAdministration.goalTargets,
    filter,
    kpiAdministration.zeroWhenNegative,
  );

  const goalStatus = getCountGoalStatus(
    goals,
    kpiAdministration.goalTargets,
    filter,
    kpiAdministration.zeroWhenNegative,
  );

  const processStatusProps = {};
  const goalsStatusProps = {};

  Object.keys(processStatus).forEach((statusKey) => {
    const statusInfo = getObjectInfoById(statusKey, goalTargetOptions, "value");
    const goals = processStatus[statusKey];
    const calculateAll =
      goals.filter(({ relationshipType }) => relationshipType === "INDIVIDUAL")
        .length === 0;

    processStatusProps[statusKey] = {
      count: goals.length,
      popover: getThemePopover(
        {},
        goals,
        kpiAdministration.goalTargets,
        filter,
        kpiAdministration.zeroWhenNegative,
        calculateAll,
      ),
      color: statusInfo.color,
      label: statusInfo.label,
    };
  });

  Object.keys(goalStatus).forEach((statusKey) => {
    const statusInfo = getObjectInfoById(statusKey, goalTargetOptions, "value");

    goalsStatusProps[statusKey] = {
      count: goalStatus[statusKey].length,
      popover: getProcessPopover(
        {
          processGoals: goalStatus[statusKey],
        },
        kpiAdministration.goalTargets,
        kpiAdministration.zeroWhenNegative,
      ),
      color: statusInfo.color,
      label: statusInfo.label,
    };
  });

  return (
    <div className="evaluation-card">
      <div className="card-header">
        <div className="card-title">
          <h4>{intl.formatMessage(messages.summary).toUpperCase()}</h4>
        </div>
      </div>
      <div style={{ padding: "5px 0px 15px 0px" }}>
        <div className="card-body max-height-800" style={{ padding: "0px" }}>
          <div className="row">
            <h5 style={{ margin: "15px 15px 0px 15px" }}>
              {intl.formatMessage(messages.kpi_table_text_subthemes_upper)} #
              {displayProcesses.length}
            </h5>
            <TargetColumns
              total={displayProcesses.length}
              list={processStatusProps}
              margin="0px"
              showPercentage
            />
          </div>
          <div className="row">
            <h5 style={{ margin: "15px 15px 0px 15px" }}>
              {intl.formatMessage(messages.kpi_table_text_indicators_upper)} #
              {goals.length}
            </h5>
            <TargetColumns
              total={goals.length}
              list={goalsStatusProps}
              margin="0px"
              showPercentage
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ColumnsEvaluations);
