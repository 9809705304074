import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";

const MaterialExpansionPanel = withStyles({
  root: {
    width: "100%",
    border: "1px solid #ccc",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
    display: " block",
  },
  expanded: {},
})(Accordion);

export default MaterialExpansionPanel;
