import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import _ from "lodash";
import MaterialTextField from "../../MaterialTextField";
import MaterialSingleSelect from "../../MaterialSingleSelect";
import utils from "../../../../utils/toolUtils";

import {
  getMeasurementUnityOptions,
  getGoalTypeOptions,
  getFrequencyOptions,
  getMeasurementOptions,
  getRelationshipTypeOptions,
  getGoalValidationStatusOptions,
} from "../../../../constants/goalsAndBonus";

import SelectMemberModal from "../../SelectMemberModal";
import {
  buildParticipantsOnBoardOptions,
  buildRelatedParticipants,
} from "../../../../utils/goalsBonus/filter";
import { getAllExcept } from "../../../../utils/ArrayUtils";
import { getAdormentByMeasurement } from "../../../../utils/kpis/display";
import SimpleExcelCollumn from "../../SimpleExcelCollumn";
import { getTargetInputProps } from "../../Kpi/insight/includes/displayUtils";
import {
  getGoalDistributionByPeriods,
  getDefaultFrequencyArray,
  getDefaultFrequencyDistribution,
} from "../../../../utils/kpis/entity";
import { formTransformValue } from "../../../../utils/kpis/validation";
import { checkIsGoalEditable } from "../../../../utils/goalsBonus/validation";
import LinkedMessage from "../includes/LinkedMessage";

const { getErrorMessage } = utils;

const messages = defineMessages({
  gb_table_text_description: { id: "gb_table_text_description" },
  gb_table_text_relationship: { id: "gb_table_text_relationship" },
  gb_table_text_gate: { id: "gb_table_text_gate" },
  gb_table_text_appropriated: { id: "gb_table_text_appropriated" },
  gb_table_text_exceeded: { id: "gb_table_text_exceeded" },
  gb_table_text_goal_targets: { id: "gb_table_text_goal_targets" },
  gb_table_text_weight: { id: "gb_table_text_weight" },
  gb_table_text_participants: { id: "gb_table_text_participants" },
  gb_subtitle_goal_description: { id: "gb_subtitle_goal_description" },
  gb_form_warning_cant_change_approved: {
    id: "gb_form_warning_cant_change_approved",
  },
  gb_form_unity_ext: { id: "gb_form_unity_ext" },
  gb_form_type: { id: "gb_form_type" },
  gb_form_frequency: { id: "gb_form_frequency" },
  gb_form_description: { id: "gb_form_description" },
  gb_form_measurement: { id: "gb_form_measurement" },
  gb_form_workflow_status: { id: "gb_form_workflow_status" },
  gb_options_mu_other: { id: "gb_options_mu_other" },
  gb_message_show_more_participants: {
    id: "gb_message_show_more_participants",
  },
  gb_message_show_less_participants: {
    id: "gb_message_show_less_participants",
  },
  gb_dialog_title_select_member: {
    id: "gb_dialog_title_select_member",
  },
  global_goBack: {
    id: "global.goBack",
  },
});

const Form = (props) => {
  const [relatedParticipants, setRelatedParticipants] = useState([]);
  const [participantsOnBoard, setParticipantsOnBoard] = useState([]);
  const [showParticipants, setShowParticipants] = useState(false);
  const [selectNewMembers, setSelectNewMembers] = useState(false);
  const [replaceSelectedMembers, setReplaceSelectedMembers] = useState(false);

  const {
    goalBonusAdministration,
    goalDescriptions,
    setGoalDescriptions,
    formErrors = [],
    isAdmin = false,
    blockAllChanges = false,
    intl,
    allAnswers,
    allQuestions,
    addToQuestions,
    handleAddToQuestions,
    enableNewParticipants = false,
    showLinkedMessage = false,
    haveLinkedIndicator,
  } = props;

  useEffect(() => {
    const onlyRelatedParticipants = buildRelatedParticipants(
      allAnswers,
      goalDescriptions.relatedInsights,
    );

    if (!_.isEqual(relatedParticipants, onlyRelatedParticipants)) {
      setRelatedParticipants(onlyRelatedParticipants);
    }
  }, [goalDescriptions.relatedInsights, allAnswers, relatedParticipants]);

  useEffect(() => {
    if (goalBonusAdministration) {
      const newParticipantsOnBoard = buildParticipantsOnBoardOptions(
        allQuestions,
        goalBonusAdministration.participants,
      );

      const excludeAlreadyOnGoal = getAllExcept(
        [...relatedParticipants.map(({ id }) => id)],
        newParticipantsOnBoard,
      );

      if (!_.isEqual(excludeAlreadyOnGoal, participantsOnBoard)) {
        setParticipantsOnBoard(excludeAlreadyOnGoal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allQuestions, relatedParticipants]);

  const isDateMeasurement = goalDescriptions.measurementUnity === "DATE";
  const targetInputType = isDateMeasurement ? "date" : "text";

  const measurementUnityOptions = getMeasurementUnityOptions();
  const goalTypeOptions = getGoalTypeOptions();
  const frequencyOptions = getFrequencyOptions();
  const measurementOptions = getMeasurementOptions();
  const relationshipTypeOptions = getRelationshipTypeOptions();
  const goalValidationOptions = getGoalValidationStatusOptions();

  const handleUpdateGoalDescriptions = (fieldSlug, newValue) => {
    setGoalDescriptions({
      ...goalDescriptions,
      [fieldSlug]: newValue,
    });
  };

  const handleUpdateRelationshipType = (newRelationship) => {
    setGoalDescriptions({
      ...goalDescriptions,
      relationshipType: newRelationship,
    });
  };

  const handleUpdateTarget = (targetSlug, value) => {
    setGoalDescriptions({
      ...goalDescriptions,
      target: {
        ...goalDescriptions.target,
        [targetSlug]: formTransformValue(
          value,
          goalDescriptions.measurementUnity,
          true,
        ),
      },
    });
  };

  const handleUpdateFrequency = (newFrequency) => {
    if (!isDateMeasurement) {
      const newActualTargets = getDefaultFrequencyArray(newFrequency);
      const tempUpdatedGoal = {
        ...goalDescriptions,
        frequency: newFrequency,
        target: {
          ...goalDescriptions.target,
          actual: newActualTargets,
          lastPeriod: newActualTargets,
        },
      };

      const { goalDistribution } = getGoalDistributionByPeriods(
        tempUpdatedGoal,
      );

      setGoalDescriptions({
        ...tempUpdatedGoal,
        target: {
          ...tempUpdatedGoal.target,
          distribution: goalDistribution,
        },
      });
    }
  };

  const handleUpdateMeasurementType = (newMeasurementRule) => {
    const tempUpdatedGoal = {
      ...goalDescriptions,
      measurement: newMeasurementRule,
    };

    const { goalDistribution } = getGoalDistributionByPeriods(tempUpdatedGoal);

    setGoalDescriptions({
      ...tempUpdatedGoal,
      target: {
        ...goalDescriptions.target,
        distribution: goalDistribution,
      },
    });
  };

  const handleUpdateMeasurementUnity = (newUnity) => {
    const tempUpdatedGoal = {
      ...goalDescriptions,
      measurementUnity: newUnity,
    };

    if (newUnity === "DATE") {
      const goalTarget =
        newUnity === "DATE"
          ? { gate: 0, appropriated: 0, exceeded: 0 }
          : goalDescriptions.target;

      setGoalDescriptions({
        ...tempUpdatedGoal,
        frequency: "YEARLY",
        target: {
          ...goalTarget,
          actual: getDefaultFrequencyArray("YEARLY"),
          distribution: getDefaultFrequencyDistribution("YEARLY", "SUM"),
        },
      });
    } else {
      setGoalDescriptions(tempUpdatedGoal);
    }
  };

  const updateAddToQuestions = (selectedList) => {
    setSelectNewMembers(false);
    handleAddToQuestions(selectedList);
  };

  const displayParticipantsName = (participants) => {
    if (Array.isArray(participants)) {
      return participants.map((questionInfo, index) => {
        const isLast = index + 1 === participants.length;
        const showDiv = isLast ? "." : ",";

        return `${questionInfo.text}${showDiv} `;
      });
    }

    return null;
  };

  const toggleShowParticipants = () => {
    setShowParticipants(!showParticipants);
  };

  const adormentObjInfo = {
    text: getAdormentByMeasurement(
      goalDescriptions.measurementUnity,
      goalDescriptions.otherMeasurement,
    ),
    position: "end",
    fontSize: "12px",
  };

  const { target = {} } = goalDescriptions;

  const editableGoal = checkIsGoalEditable(
    goalDescriptions.id,
    goalDescriptions.workflowStatus,
    goalDescriptions.relationshipType,
    isAdmin,
  );

  const isEditingAllowed =
    editableGoal && !blockAllChanges && !haveLinkedIndicator;
  const blockOrNotEditable = !editableGoal || blockAllChanges;

  const notAllowedCss = !isEditingAllowed ? { cursor: "not-allowed" } : {};
  const notAllowedCssAdmin =
    !isAdmin || !isEditingAllowed ? { cursor: "not-allowed" } : {};
  const linkedStyles =
    haveLinkedIndicator && !blockOrNotEditable
      ? { backgroundColor: "#c4e16c", color: "#111" }
      : {};

  const individualGoal = goalDescriptions.relationshipType === "INDIVIDUAL";
  const haveRelatedParticipants = relatedParticipants.length > 0;
  const blockNewParticipants =
    !addToQuestions || (individualGoal && haveRelatedParticipants);

  return (
    <>
      {showLinkedMessage && <LinkedMessage cantEdit={blockOrNotEditable} />}
      <div className="col-xs-6">
        <MaterialTextField
          id="description"
          label={intl.formatMessage(messages.gb_form_description)}
          value={goalDescriptions.description}
          primaryInputProps={{ maxLength: 255 }}
          onChange={(e) =>
            isEditingAllowed
              ? handleUpdateGoalDescriptions("description", e.target.value)
              : () => {}
          }
          multiline
          rows={5}
          inputStyle={!isEditingAllowed ? { cursor: "not-allowed" } : {}}
        />
        {enableNewParticipants && (
          <div className="row">
            <h4 style={{ marginBottom: "0px" }}>
              {intl.formatMessage(messages.gb_table_text_participants)}
            </h4>
            {haveRelatedParticipants && (
              <div>
                <a
                  onClick={toggleShowParticipants}
                  style={{ cursor: "pointer" }}
                >
                  {!showParticipants &&
                    intl.formatMessage(
                      messages.gb_message_show_more_participants,
                    )}
                  {showParticipants &&
                    intl.formatMessage(
                      messages.gb_message_show_less_participants,
                    )}
                </a>
                {showParticipants && (
                  <div style={{ paddingTop: "5px", fontWeight: "500" }}>
                    {displayParticipantsName(relatedParticipants)}
                  </div>
                )}
              </div>
            )}
            {!blockNewParticipants && (
              <div>
                <MaterialTextField
                  id="selectedParticipants"
                  type="text"
                  label="Participantes Selecionados"
                  inputStyle={
                    blockOrNotEditable
                      ? {
                          fontSize: "14px",
                          ...notAllowedCss,
                        }
                      : { fontSize: "14px" }
                  }
                  labelProps={linkedStyles}
                  value={`${addToQuestions.length} novos participantes selecionados`}
                  onClick={() => setSelectNewMembers(true)}
                />
              </div>
            )}
            {selectNewMembers && (
              <SelectMemberModal
                open
                title={intl.formatMessage(
                  messages.gb_dialog_title_select_member,
                )}
                membersList={participantsOnBoard || []}
                onConfirm={(selected, selectedList) =>
                  updateAddToQuestions(selectedList)
                }
                onCancel={() => setSelectNewMembers(false)}
                cancelText={intl.formatMessage(messages.global_goBack)}
                replaceSelected={replaceSelectedMembers}
                initialSelected={addToQuestions}
                replaceCallback={() => setReplaceSelectedMembers(false)}
                singleSelect={!!individualGoal}
              />
            )}
          </div>
        )}
      </div>
      <div className="col-xs-6">
        {isAdmin && !editableGoal && (
          <h5 className="text-warning">
            <i className="fas fa-exclamation-triangle" />
            {intl.formatMessage(messages.gb_form_warning_cant_change_approved)}
          </h5>
        )}
        <table className="simple-table">
          <tbody>
            <tr style={{ ...notAllowedCss }} className="small-font">
              <td className="titled">
                {intl.formatMessage(messages.gb_form_unity_ext)}
              </td>
              <td style={{ padding: "0px" }}>
                <MaterialSingleSelect
                  id="unityOfMesurement"
                  value={goalDescriptions.measurementUnity}
                  variant="standard"
                  label={intl.formatMessage(messages.gb_form_unity_ext)}
                  options={measurementUnityOptions}
                  margin="none"
                  hideLabel
                  onChange={(e) =>
                    isEditingAllowed
                      ? handleUpdateMeasurementUnity(e.target.value)
                      : () => {}
                  }
                  customStyles={{
                    padding: "0px 10px",
                    height: "40px",
                    fontSize: "14px",
                  }}
                  defaultOption={false}
                  notAllowed={!isEditingAllowed}
                />
              </td>
            </tr>
            {goalDescriptions.measurementUnity === "OTHER" && (
              <tr style={{ ...notAllowedCss }} className="small-font">
                <td className="titled">
                  {`${intl.formatMessage(messages.gb_options_mu_other)}: `}
                  {intl.formatMessage(messages.gb_form_unity_ext)}
                </td>
                <SimpleExcelCollumn
                  id="otherMeasurement"
                  value={goalDescriptions.otherMeasurement}
                  tdStyle={{
                    padding: "0px 10px",
                    borderBottom: "1px solid #ccc",
                  }}
                  inputStyle={{
                    height: "40px",
                    padding: "0px",
                    cursor: !isEditingAllowed ? "not-allowed" : "auto",
                  }}
                  onChange={(e) =>
                    isEditingAllowed
                      ? handleUpdateGoalDescriptions(
                          "otherMeasurement",
                          e.target.value,
                        )
                      : () => {}
                  }
                />
              </tr>
            )}
            <tr style={{ ...notAllowedCss }} className="small-font">
              <td className="titled">
                {intl.formatMessage(messages.gb_form_type)}
              </td>
              <td style={{ padding: "0px" }}>
                <MaterialSingleSelect
                  id="goalType"
                  value={goalDescriptions.type}
                  variant="standard"
                  label={intl.formatMessage(messages.gb_form_type)}
                  options={goalTypeOptions}
                  margin="none"
                  hideLabel
                  onChange={(e) =>
                    isEditingAllowed
                      ? handleUpdateGoalDescriptions("type", e.target.value)
                      : () => {}
                  }
                  customStyles={{
                    padding: "0px 10px",
                    height: "40px",
                    fontSize: "14px",
                  }}
                  defaultOption={false}
                  notAllowed={!isEditingAllowed}
                />
              </td>
            </tr>
            <tr style={{ ...notAllowedCss }} className="small-font">
              <td className="titled">
                {intl.formatMessage(messages.gb_form_frequency)}
              </td>
              <td style={{ padding: "0px" }}>
                <MaterialSingleSelect
                  id="goalFrequency"
                  value={goalDescriptions.frequency}
                  variant="standard"
                  label={intl.formatMessage(messages.gb_form_frequency)}
                  options={frequencyOptions}
                  margin="none"
                  hideLabel
                  onChange={(e) =>
                    isEditingAllowed && !isDateMeasurement
                      ? handleUpdateFrequency(e.target.value)
                      : () => {}
                  }
                  customStyles={{
                    padding: "0px 10px",
                    height: "40px",
                    fontSize: "14px",
                  }}
                  defaultOption={false}
                  notAllowed={!isEditingAllowed || isDateMeasurement}
                />
              </td>
            </tr>
            <tr style={{ ...notAllowedCss }} className="small-font">
              <td className="titled">
                {intl.formatMessage(messages.gb_form_measurement)}
              </td>
              <td style={{ padding: "0px" }}>
                <MaterialSingleSelect
                  id="goalMeasument"
                  value={goalDescriptions.measurement}
                  variant="standard"
                  label={intl.formatMessage(messages.gb_form_measurement)}
                  options={measurementOptions}
                  margin="none"
                  hideLabel
                  onChange={(e) =>
                    isEditingAllowed && !isDateMeasurement
                      ? handleUpdateMeasurementType(e.target.value)
                      : () => {}
                  }
                  customStyles={{
                    padding: "0px 10px",
                    height: "40px",
                    fontSize: "14px",
                  }}
                  defaultOption={false}
                  notAllowed={!isEditingAllowed || isDateMeasurement}
                />
              </td>
            </tr>
            <tr style={{ ...notAllowedCss }} className="small-font">
              <td className="titled" style={linkedStyles}>
                {intl.formatMessage(messages.gb_table_text_relationship)}
              </td>
              <td style={{ padding: "0px" }}>
                <MaterialSingleSelect
                  id="relationshipType"
                  value={goalDescriptions.relationshipType}
                  variant="standard"
                  label={intl.formatMessage(
                    messages.gb_table_text_relationship,
                  )}
                  options={relationshipTypeOptions}
                  margin="none"
                  hideLabel
                  onChange={(e) =>
                    isAdmin
                      ? handleUpdateRelationshipType(e.target.value)
                      : () => {}
                  }
                  customStyles={{
                    padding: "0px 10px",
                    height: "40px",
                    fontSize: "14px",
                    ...notAllowedCssAdmin,
                  }}
                  notAllowed={false}
                  defaultOption={false}
                />
              </td>
            </tr>
            {isAdmin && (
              <tr style={{ ...notAllowedCss }} className="small-font">
                <td className="titled">
                  {intl.formatMessage(messages.gb_form_workflow_status)}
                </td>
                <td style={{ padding: "0px" }}>
                  <MaterialSingleSelect
                    id="workflowStatus"
                    value={goalDescriptions.workflowStatus}
                    variant="standard"
                    label={intl.formatMessage(messages.gb_form_workflow_status)}
                    options={goalValidationOptions}
                    margin="none"
                    hideLabel
                    onChange={(e) =>
                      isAdmin
                        ? handleUpdateGoalDescriptions(
                            "workflowStatus",
                            e.target.value,
                          )
                        : () => {}
                    }
                    customStyles={{
                      padding: "0px 10px",
                      height: "40px",
                      fontSize: "14px",
                      ...notAllowedCssAdmin,
                    }}
                    notAllowed={false}
                    defaultOption={false}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="col-xs-12">
        {getErrorMessage(
          ["individualNotAllowed"],
          formErrors,
          "left",
          {
            height: "30px",
          },
          true,
        )}
      </div>

      <div className="row">
        <div className="col-xs-12" style={{ paddingBottom: "0px" }}>
          <h4 style={{ marginBottom: "0px" }}>
            {intl.formatMessage(messages.gb_table_text_goal_targets)}
          </h4>
        </div>
        <div className="col-xs-12">
          {getErrorMessage(
            ["repeatedTarget", "invalidTarget"],
            formErrors,
            "left",
            {},
            true,
          )}
          {getErrorMessage(["invalidWeight"], formErrors, "left", {}, true)}
        </div>
      </div>

      <div className="col-xs-3" style={notAllowedCss}>
        <MaterialTextField
          {...getTargetInputProps(targetInputType, "101")}
          id="gate"
          type={targetInputType}
          label={intl.formatMessage(messages.gb_table_text_gate)}
          inputStyle={{
            fontSize: "14px",
            padding: "15px 0px",
            textAlign: "right",
            ...notAllowedCss,
          }}
          value={formTransformValue(
            target.gate,
            goalDescriptions.measurementUnity,
            false,
          )}
          endAdorment={isDateMeasurement ? {} : adormentObjInfo}
          onChange={(e) =>
            isEditingAllowed
              ? handleUpdateTarget("gate", e.target.value)
              : () => {}
          }
        />
      </div>
      <div className="col-xs-3" style={notAllowedCss}>
        <MaterialTextField
          {...getTargetInputProps(targetInputType, "102")}
          id="appropriated"
          type={targetInputType}
          label={intl.formatMessage(messages.gb_table_text_appropriated)}
          inputStyle={{
            fontSize: "14px",
            padding: "15px 0px",
            textAlign: "right",
            ...notAllowedCss,
          }}
          value={formTransformValue(
            target.appropriated,
            goalDescriptions.measurementUnity,
            false,
          )}
          endAdorment={isDateMeasurement ? {} : adormentObjInfo}
          onChange={(e) =>
            isEditingAllowed
              ? handleUpdateTarget("appropriated", e.target.value)
              : () => {}
          }
        />
      </div>
      <div className="col-xs-3" style={notAllowedCss}>
        <MaterialTextField
          {...getTargetInputProps(targetInputType, "103")}
          id="exceeded"
          type={targetInputType}
          label={intl.formatMessage(messages.gb_table_text_exceeded)}
          inputStyle={{
            fontSize: "14px",
            padding: "15px 0px",
            textAlign: "right",
            ...notAllowedCss,
          }}
          value={formTransformValue(
            target.exceeded,
            goalDescriptions.measurementUnity,
            false,
          )}
          endAdorment={isDateMeasurement ? {} : adormentObjInfo}
          onChange={(e) =>
            isEditingAllowed
              ? handleUpdateTarget("exceeded", e.target.value)
              : () => {}
          }
        />
      </div>
      <div className="col-xs-3" style={notAllowedCss}>
        <MaterialTextField
          id="goalWeight"
          type="number"
          label={intl.formatMessage(messages.gb_table_text_weight)}
          value={goalDescriptions.weight}
          endAdorment={{
            text: "%",
            position: "end",
            fontSize: "14px",
          }}
          inputStyle={
            blockOrNotEditable
              ? {
                  fontSize: "14px",
                  padding: "15px 0px",
                  textAlign: "right",
                  ...notAllowedCss,
                }
              : {
                  fontSize: "14px",
                  padding: "15px 0px",
                  textAlign: "right",
                }
          }
          labelProps={linkedStyles}
          onChange={(e) =>
            !blockOrNotEditable
              ? handleUpdateGoalDescriptions("weight", e.target.value)
              : () => {}
          }
        />
      </div>
      <div className="col-xs-12" style={{ paddingTop: "15px" }}>
        {getErrorMessage(["repeatedTarget", "invalidTarget"], formErrors)}
      </div>
    </>
  );
};

const GoalDescription = (props) => {
  const { onlyForm = false, SaveButton = false, intl } = props;

  return !onlyForm ? (
    <div className="box box-primary">
      <div className="box-header with-border">
        <h3 className="box-title">
          <i className="fas fa-align-center" />
          {intl.formatMessage(messages.gb_subtitle_goal_description)}
        </h3>
        {SaveButton && SaveButton}
      </div>
      <div className="box-body">
        <Form {...props} />
      </div>
    </div>
  ) : (
    <Form {...props} />
  );
};

export default injectIntl(GoalDescription);
