import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import FormDialog from "../../FormDialog";
import MaterialTextField from "../../MaterialTextField";
import SelectItemModal from "../../SelectItemModal";
import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import utils from "../../../../utils/toolUtils";
import { getAnalysisToolDialogs } from "../../../../utils/cpAnalysis/display";

const { getValidFunc } = utils;

const useStyles = makeStyles(() =>
  createStyles({
    addButton: {
      fontSize: "12px",
    },
  }),
);

const messages = defineMessages({
  kpi_add_new_question: { id: "kpi_add_new_question" },
  btn_modal_register_new: { id: "btn_modal_register_new" },
  kpi_error_subtheme_already_added: {
    id: "kpi_error_subtheme_already_added",
  },
  kpi_form_select_subtheme: { id: "kpi_form_select_subtheme" },
  kpi_table_text_no_subthemes: { id: "kpi_table_text_no_subthemes" },
  global_select: { id: "global.select" },
});

const initialForm = {
  analysisAreas: [],
};

const ThemeCreationAndLinkDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const [registerModal, setRegisterModal] = useState(false);
  const [selectAreaModal, setSelectAreaModal] = useState(false);

  const {
    open = false,
    afterCancel,
    afterConfirm,
    currentToolType,
    cpAnalysisAdministration,
    setCpAnalysisAdministration,
    addedAreas,
    intl,
  } = props;

  const alternativeSaveCallback = getValidFunc(props.alternativeSaveCallback);

  const { NewAnalysisAreaDialog } = getAnalysisToolDialogs(currentToolType);

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleConfirm = () => {
    if (typeof afterConfirm === "function") afterConfirm(form);
  };

  const handleCancel = () => {
    if (typeof afterCancel === "function") afterCancel();
  };

  const handleUpdateForm = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const buildAreasOptions = (areas) => {
    let finalOptions = [];

    if (areas && areas.length > 0) {
      areas.forEach(
        ({ id, title }) =>
          addedAreas.indexOf(id) === -1 && finalOptions.push({ id, title }),
      );
    }

    return finalOptions;
  };

  const updateSelected = (selected) => {
    handleUpdateForm("analysisAreas", selected);

    toggleSelectAreaModal(false);
  };

  const alternativeSave = (submitKpiConfig) => {
    toggleRegisterModal(false);
    alternativeSaveCallback(submitKpiConfig);
  };

  const toggleRegisterModal = (toggleTo = true) => {
    setRegisterModal(toggleTo);
  };

  const toggleSelectAreaModal = (toggleTo = true) => {
    setSelectAreaModal(toggleTo);
  };

  const getSelectedDisplay = (list) => {
    let displayTitles = [];

    const { analysisAreas = [] } =
      cpAnalysisAdministration[currentToolType] || {};

    analysisAreas.forEach((areaInfo) => {
      if (list.indexOf(areaInfo.id) > -1) displayTitles.push(areaInfo.title);
    });

    return displayTitles.length > 0
      ? displayTitles.join(", ")
      : intl.formatMessage(messages.global_select);
  };

  const { analysisAreas = [] } =
    cpAnalysisAdministration[currentToolType] || {};

  const areasOptions = buildAreasOptions(analysisAreas);

  const classes = useStyles();

  return (
    <>
      <NewAnalysisAreaDialog
        open={registerModal}
        cpAnalysisAdministration={cpAnalysisAdministration}
        setCpAnalysisAdministration={setCpAnalysisAdministration}
        afterConfirm={(submitKpiConfig) => alternativeSave(submitKpiConfig)}
        afterCancel={() => toggleRegisterModal(false)}
      />
      <SelectItemModal
        open={selectAreaModal}
        itemsList={areasOptions || []}
        onCancel={() => toggleSelectAreaModal(false)}
        onConfirm={(selected) => {
          updateSelected(selected);
        }}
        title={intl.formatMessage(messages.kpi_form_select_subtheme)}
        initialSelected={form.analysisAreas}
        replaceSelected
      />
      <FormDialog
        open={open}
        title={intl.formatMessage(messages.kpi_add_new_question)}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialTextField
              id="areasSelect"
              label={intl.formatMessage(messages.kpi_form_select_subtheme)}
              value={getSelectedDisplay(form.analysisAreas)}
              onClick={() => toggleSelectAreaModal(true)}
              inputStyle={{ cursor: "pointer" }}
            />
            <Button
              variant="outlined"
              className={classes.addButton}
              onClick={() => toggleRegisterModal(!registerModal.open)}
            >
              <AddIcon /> {intl.formatMessage(messages.btn_modal_register_new)}
            </Button>
          </div>
        </div>
      </FormDialog>
    </>
  );
};

export default injectIntl(ThemeCreationAndLinkDialog);
