import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import NewGoalDialog from "./NewGoalDialog";
import FormDialog from "../../FormDialog";
import MaterialTextField from "../../MaterialTextField";
import SelectGoalModal from "../../SelectGoalModal";
import { getSelectedToolStates } from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";
import gbUtils from "../../../../utils/goalsBonus";
import { getRelationshipTypeOptions } from "../../../../constants/goalsAndBonus";
import { getGoalBonusConfiguration } from "../../../../actions/goalsAndBonusActions";
import { buildGoalsAdministrationFinalBody } from "../../../../utils/goalsBonus/entity";

const { getErrorMessage } = utils;

const {
  getGoalInfoById,
  getTopLevelUserAccess,
  getFilteredGoals,
  getSelectGoalSecondaryText,
} = gbUtils;

const useStyles = makeStyles(() =>
  createStyles({
    addButton: {
      fontSize: "12px",
    },
  }),
);

const messages = defineMessages({
  gb_form_select_goal: { id: "gb_form_select_goal" },
  gb_add_new_insight: { id: "gb_add_new_insight" },
  gb_error_goal_already_added: { id: "gb_error_goal_already_added" },
  gb_error_individual_goal_added: {
    id: "gb_error_individual_goal_added",
  },
  btn_modal_register_new: { id: "btn_modal_register_new" },
  global_select: { id: "global.select" },
});

const initialForm = {
  goals: [],
};

const InsightGoalSelectDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const [newGoalModal, setNewGoalModal] = useState(false);
  const [selectGoalModal, setSelectGoalModal] = useState(false);
  const {
    open = false,
    afterCancel,
    afterConfirm,
    goalBonusAdministration,
    setGoalBonusAdministration,
    addedGoals,
    selectedToolID,
    intl,
  } = props;

  const relationshipTypeOptions = getRelationshipTypeOptions();

  const userAccess = getTopLevelUserAccess();

  const onlyParticipantAccess =
    userAccess.accesses.indexOf("OWN_GOALS") > -1 &&
    userAccess.accesses.indexOf("ADMIN") === -1;

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  const getToolConfig = async () => {
    if (selectedToolID) {
      const { data } = await getGoalBonusConfiguration(selectedToolID);

      if (data && data.id) {
        const fetchedConfiguration = await buildGoalsAdministrationFinalBody(
          data,
        );

        return fetchedConfiguration;
      }
    }
  };

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleConfirm = () => {
    if (typeof afterConfirm === "function") afterConfirm(form);
  };

  const handleCancel = () => {
    if (typeof afterCancel === "function") afterCancel();
  };

  const handleUpdateForm = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const updateSelectedGoal = (selectedGoals) => {
    handleUpdateForm("goals", selectedGoals);

    toggleSelectGoalModal(false);
  };

  const getFormErrors = () => {
    let errors = [];

    if (form.goals.length === 0) {
      errors.push({ slug: "goalNotSelected" });
    } else {
      form.goals.forEach((goalId) => {
        const goalInfo = getGoalInfoById(goalId, goalBonusAdministration.goals);
        let alreadyAddedToParticipant = false;

        if (addedGoals.indexOf(goalId) > -1) {
          errors.push({
            slug: `duplicatedGoal${goalId}`,
            message: intl.formatMessage(messages.gb_error_goal_already_added, {
              title: goalInfo.title,
            }),
          });
          alreadyAddedToParticipant = true;
        }

        if (
          goalInfo.id &&
          goalInfo.relationshipType === "INDIVIDUAL" &&
          goalInfo.relatedInsights > 0 &&
          !alreadyAddedToParticipant
        ) {
          errors.push({
            slug: `individualGoal${goalId}`,
            message: intl.formatMessage(
              messages.gb_error_individual_goal_added,
              { title: goalInfo.title },
            ),
          });
        }
      });
    }

    return errors;
  };

  const toggleSelectGoalModal = (toggleTo = true) => {
    setSelectGoalModal(toggleTo);
  };

  const toggleNewGoalModal = (toggleTo = true) => {
    setNewGoalModal(toggleTo);
  };

  const getSelectedGoalsDisplay = (list) => {
    let displayTitles = [];

    goalBonusAdministration.goals.forEach((goalInfo) => {
      if (list.indexOf(goalInfo.id) > -1) displayTitles.push(goalInfo.title);
    });

    return displayTitles.length > 0
      ? displayTitles.join(", ")
      : intl.formatMessage(messages.global_select);
  };

  const getFilteredDisplayGoals = () => {
    let filtered = onlyParticipantAccess
      ? getFilteredGoals(
          "INDIVIDUAL",
          goalBonusAdministration.goals,
          null,
          "relationshipType",
        )
      : goalBonusAdministration.goals;

    filtered = filtered.map((goalInfo) => {
      return {
        ...goalInfo,
        filterValue: goalInfo.relationshipType,
        secondaryText: getSelectGoalSecondaryText(goalInfo),
      };
    }); // Adicionar filterValue e secondaryText para SelectGoalModal

    return filtered;
  };

  const getGoalDisplayErrors = (slug = "duplicatedGoal", errors = []) => {
    return form.goals.map((goalId, index) => {
      let haveError = null;

      errors.forEach((iteratingErr) => {
        if (iteratingErr.slug === `${slug}${goalId}`) haveError = true;
      });

      return haveError ? (
        <div style={{ height: "40px" }} key={index}>
          {getErrorMessage([`${slug}${goalId}`], errors, "left", {
            height: "0px",
          })}
        </div>
      ) : null;
    });
  };

  const classes = useStyles();
  const formErrors = getFormErrors();
  const filteredGoals = getFilteredDisplayGoals();

  return (
    <>
      <SelectGoalModal
        open={selectGoalModal}
        goalsList={filteredGoals || []}
        filterOptions={relationshipTypeOptions}
        onCancel={() => toggleSelectGoalModal(false)}
        onConfirm={(selectedGoals) => {
          updateSelectedGoal(selectedGoals);
        }}
        title={intl.formatMessage(messages.gb_form_select_goal)}
        initialSelected={form.goals}
        replaceSelected
      />
      <NewGoalDialog
        open={newGoalModal}
        goalBonusAdministration={goalBonusAdministration}
        setGoalBonusAdministration={setGoalBonusAdministration}
        afterCancel={() => toggleNewGoalModal(false)}
        getToolConfig={getToolConfig}
      />
      <FormDialog
        open={open}
        title={intl.formatMessage(messages.gb_add_new_insight)}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        blockConfirm={formErrors.length > 0}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialTextField
              id="goalSelect"
              label={intl.formatMessage(messages.gb_form_select_goal)}
              value={getSelectedGoalsDisplay(form.goals)}
              onClick={() => toggleSelectGoalModal(true)}
              inputStyle={{ cursor: "pointer" }}
            />
            {getGoalDisplayErrors("duplicatedGoal", formErrors)}
            {getGoalDisplayErrors("individualGoal", formErrors)}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Button
              variant="outlined"
              className={classes.addButton}
              onClick={() => toggleNewGoalModal(!newGoalModal.open)}
            >
              <AddIcon /> {intl.formatMessage(messages.btn_modal_register_new)}
            </Button>
          </div>
        </div>
      </FormDialog>
    </>
  );
};

export default injectIntl(
  connect((state) => {
    const { selectedToolID } = getSelectedToolStates(state);
    return { selectedToolID };
  })(InsightGoalSelectDialog),
);
