import React, { useState, createContext } from "react";
import PropTypes from "prop-types";

import Popover from "@material-ui/core/Popover";

import { Icon } from "./styles";

const PopoverContext = createContext();

const SimpleMenu = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  return (
    <PopoverContext.Provider value={{ closePopover, isOpenPopover: open }}>
      <Icon className="fas fa-caret-square-down" onClick={handleClick} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {children}
      </Popover>
    </PopoverContext.Provider>
  );
};

export { SimpleMenu, PopoverContext };

SimpleMenu.propTypes = {
  children: PropTypes.node.isRequired,
};
