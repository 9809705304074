import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InsightContainer from "../Insight/InsightContainer";
import MaterialCustomMenu from "../../Common/MaterialCustomMenu";
import MaterialExpansionPanel from "../../Common/MaterialExpansionPanel";
import {
  PanelSummary,
  PanelDetails,
} from "../../Common/MaterialExpansionPanelSummary";
import EditableTextArea from "../../Common/EditableTextArea";
import Avatar from "../../Common/Avatar";
import EvaluationBadge from "../EvaluationBadge/EvaluationBadge";
import { checkAccess } from "../../../utils/accessLevels";
import utils from "../../../utils/toolUtils";

const { getValidFunc, getEntityMenuActions } = utils;

const messages = defineMessages({
  globalFiled: {
    id: "global.filed",
  },
});

const QuestionView = (props) => {
  const {
    question = {},
    questionInsights = [],
    questionsConfig = {},
    expanded = false,
    isNew = false,
    editing = false,
    toggleInsightModal,
    showHiddenAnswers,
    toolsConfigurations,
    themeInfo = {},
    selectedTool = {},
    selectedWorkspace,
    intl,
    replaceConfig = {},
    registrationAccess = true,
    openUnarchiveDialog,
    reorderEntity,
    editModeOn,
    toggleBoardEditMode,
  } = props;

  const { cenary = {} } = themeInfo || {};

  const { id: scenaryId = 0 } = cenary || {};

  const show = props.show === true;

  const saveUpdateQuestion = getValidFunc(props.saveUpdateQuestion);
  const cancelUpdate = getValidFunc(props.cancelUpdate);
  const handleExpand = !editing ? getValidFunc(props.handleExpand) : () => {};
  const handleEditQuestion = getValidFunc(props.handleEditQuestion);
  const openArchiveDialog = getValidFunc(props.openArchiveDialog);
  const openDeleteDialog = getValidFunc(props.openDeleteDialog);

  const appendProps = {
    showHiddenAnswers,
    toggleInsightModal,
    selectedWorkspace,
    themeInfo,
    replaceConfig,
    addButton: registrationAccess,
    reorderEntity,
    editModeOn,
    toggleBoardEditMode,
  };

  return (
    <div
      className="flex-transition-container"
      style={show ? {} : { height: "0px" }}
    >
      <MaterialExpansionPanel square expanded={expanded} onChange={() => {}}>
        <PanelSummary
          aria-controls={`${question.id}-question`}
          id={`${question.id}-question`}
          expandIcon={!isNew ? <ExpandMoreIcon /> : null}
          IconButtonProps={{ onClick: () => handleExpand(question.id) }}
          cursor="default !important"
          bgcolor="#f2f2f2"
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "12px",
              height: "50px",
              lineHeight: "50px",
            }}
          >
            <div
              className="clickable-row"
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                height: "100%",
                flex: "auto",
                whiteSpace: "nowrap",
              }}
              onClick={() => handleExpand(question.id)}
              title={
                question.filed ? intl.formatMessage(messages.globalFiled) : null
              }
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {question.filed && (
                  <i
                    className="fas fa-archive"
                    style={{ color: "#888", marginRight: "15px" }}
                  />
                )}
                {question.avatar && (
                  <Avatar
                    name={question.text}
                    src={question.avatar}
                    style={{ width: "30px", height: "30px" }}
                  />
                )}
                <EditableTextArea
                  onSave={(newName) =>
                    saveUpdateQuestion({ ...question, text: newName })
                  }
                  width="300px"
                  actionIcons
                  onCancel={cancelUpdate}
                  margin="none"
                  id={question.id || "newQuestion"}
                  editing={editing}
                  text={question.text}
                />
              </div>
            </div>
            <div className="evaluation-row">
              <EvaluationBadge
                from="question"
                info={{
                  toolsConfigurations,
                  tool: selectedTool,
                  themeID: themeInfo.id,
                  questionID: question.id,
                }}
                workspace={selectedWorkspace}
              />
              {!isNew &&
                checkAccess(
                  ["MANAGER", "LEADER", "MODERATOR", "ADMIN", "OWNER"],
                  null,
                  scenaryId,
                ) && (
                  <div style={{ padding: "0px 0px 0px 10px" }}>
                    <MaterialCustomMenu
                      menuActions={getEntityMenuActions(
                        handleEditQuestion,
                        openArchiveDialog,
                        openDeleteDialog,
                        question.filed,
                        questionsConfig,
                        intl,
                        openUnarchiveDialog,
                      )}
                    />
                  </div>
                )}
            </div>
          </div>
        </PanelSummary>
        {!isNew && expanded && (
          <PanelDetails padding="0px">
            <InsightContainer
              questionInfo={question}
              insights={questionInsights}
              {...appendProps}
            />
          </PanelDetails>
        )}
      </MaterialExpansionPanel>
    </div>
  );
};

export default injectIntl(QuestionView);
