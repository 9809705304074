import {
  FETCH_INSIGHT_TIMELINE,
  FETCH_INSIGHTS_TIMELINE,
  ADD_INSIGHT_COMMENT,
  EDIT_INSIGHT_COMMENT,
} from "../actions/types";

const INITIAL_STATE = {
  allComments: {},
};

const insightTimelineReducer = (state = INITIAL_STATE, action) => {
  const { payload = {} } = action;

  let newAllComments = { ...state.allComments };

  switch (action.type) {
    case FETCH_INSIGHT_TIMELINE:
      const { insightId, comments = [] } = action.payload;

      if (insightId && comments) {
        newAllComments[insightId] = comments;
      }

      return {
        ...state,
        allComments: newAllComments,
      };
    case FETCH_INSIGHTS_TIMELINE:
      action.payload.forEach((insightTimelineData) => {
        const { insightId, timeline } = insightTimelineData;
        if (insightId && timeline) {
          newAllComments[insightId] = timeline;
        }
      });

      return {
        ...state,
        allComments: newAllComments,
      };
    case ADD_INSIGHT_COMMENT:
      const { insightId: commentInsightId } = payload;

      const insightComments = newAllComments[commentInsightId] || [];

      return {
        ...state,
        allComments: {
          ...newAllComments,
          [commentInsightId]: [...insightComments, { ...payload }],
        },
      };
    case EDIT_INSIGHT_COMMENT:
      const { insightId: entityId = "", id: commentId } = payload;

      delete payload.insightId;

      const update = (array = []) => {
        return [...array.filter(({ id }) => id !== commentId), { ...payload }];
      };

      return {
        ...state,
        allComments: {
          ...newAllComments,
          [entityId]: [...update(newAllComments[entityId])],
        },
      };
    default:
      return state;
  }
};

export default insightTimelineReducer;
