import axios from "axios";
import {
  UPDATE_ACTIONS_PLANS,
  UPDATE_SINGLE_ACTION_PLAN,
  DELETE_ACTION_FROM_PLAN,
} from "./types";
import { refetchInsight } from "./insightActions";
import actionsPlanUtils from "../utils/actionsPlan";
import { makeObjLevelAnArray } from "../utils/reducerUtils";
import { addManyAttachment } from "./attachmentActions";
import {
  grantCollaboratorAccess,
  removeInsightAccess,
} from "./accessControllerActions";
import {
  getActionsPlanEntity,
  updateActionByResponsibleType,
} from "../utils/actionsPlan/entity";

const { getSaveRestByResponsibleType } = actionsPlanUtils;

const DEBUG = false;

function debug(arg1, ...optionals) {
  if (DEBUG) console.log("Actions Plan:", arg1, ...optionals);
}

export const getActionsPlan = (actionPlanId) => {
  return axios.get(`/api/action-plans/${actionPlanId}`).then((res) => res);
};

export const postActionsPlan = (newActionPlan) => {
  debug("postActionsPlan", { newActionPlan });
  return axios.post("/api/action-plans", newActionPlan).then((res) => res);
};

export const putActionsPlan = (updatedActionsPlan) => {
  debug("putActionsPlan", { updatedActionsPlan });
  return axios.put("/api/action-plans", updatedActionsPlan).then((res) => res);
};

export const getOnlyAction = (actionId) => {
  return axios.get(`/api/action-plan-actions/${actionId}`).then((res) => res);
};

export const putOnlyAction = (updatedAction) => {
  return axios
    .put("/api/action-plan-actions", updatedAction)
    .then((res) => res);
};

export const deletePlanAction = (actionId) => {
  return axios
    .delete(`/api/action-plan-actions/${actionId}`)
    .then((res) => res);
};

export const getInsightsActionsPlanByTool = (selectedToolId) => {
  debug("getInsightsActionsPlanByTool::", "fetching actionsPlans for:", {
    selectedToolId,
  });

  return axios
    .get(`/api/selected-tools/${selectedToolId}/insightsActionPlan`)
    .then((res) => res);
};

export const getInsightsActionsPlans = (selectedToolIds) => {
  return axios
    .get(`/api/selected-tools/insightsActionPlans/${selectedToolIds}`)
    .then((res) => res.data);
};

export const addActionAttachment = (insightId, actionId, files) => {
  return async (dispatch) => {
    const bodyAttachment = {
      entityType: "ACTION_PLAN",
      entityId: actionId,
      relatedInsightId: insightId,
    };

    if (insightId && actionId && files) {
      await dispatch(addManyAttachment(files, bodyAttachment, insightId));
    }
  };
};

export const updateSaveActionPlan = async (
  actionPlan = {},
  savedFiles,
  insightId,
  dispatch,
  actionId,
) => {
  const restActionsPlan = getSaveRestByResponsibleType(actionPlan);
  const checkPlan = restActionsPlan.id
    ? await getActionsPlan(restActionsPlan.id)
    : {};

  debug({ restActionsPlan });
  debug({ checkPlan });

  const bodyAttachment = {
    entityType: "ACTION_PLAN",
    entityId: actionId,
    relatedInsightId: insightId,
  };

  if (checkPlan.data && checkPlan.data.id) {
    // Já existente
    debug("UPDATING PLAN: ", restActionsPlan);
    const updatedPlan = await putActionsPlan(restActionsPlan);

    dispatch(addActionAttachment(insightId, actionId, savedFiles));

    debug("updatedPlan", updatedPlan);

    return updatedPlan.data;
  }
  // Criar novo
  debug("CREATING NEW PLAN:", restActionsPlan);
  const newActionPlan = await postActionsPlan(restActionsPlan);

  const savedActionPlan = newActionPlan.data;

  await dispatch(addManyAttachment(savedFiles, bodyAttachment, insightId));

  debug("newActionPlan", newActionPlan);
  return savedActionPlan;
};

export const linkInsightToActionPlan = (insightId, actionPlanId) => {
  return axios
    .post(`/api/action-plans/${actionPlanId}/insight/${insightId}`)
    .then((res) => res);
};

export const updateInsightAccess = (
  insightId,
  originalAction,
  updatedAction,
) => {
  return async (dispatch) => {
    if (updatedAction?.responsible?.id) {
      dispatch(
        grantCollaboratorAccess(updatedAction.responsible.id, insightId),
      );
    }

    if (
      originalAction?.responsible?.id &&
      originalAction?.responsible?.id !== updatedAction?.responsible?.id
    ) {
      dispatch(
        removeInsightAccess({
          ...originalAction?.responsible,
          entityId: insightId,
          role: "COLLABORATOR",
        }),
      );
    }
  };
};

export const updateInsightActionsPlan = (
  insightId,
  newActionsPlan,
  savedFiles,
  actionId,
) => {
  return async (dispatch) => {
    const needCreateLink = !(newActionsPlan.id > 0);

    debug("receivedActionPlan => ", newActionsPlan);

    const savedActionPlan = await updateSaveActionPlan(
      newActionsPlan,
      savedFiles,
      insightId,
      dispatch,
      actionId,
    );

    debug({ savedActionPlan, needCreateLink });

    if (savedActionPlan?.id && needCreateLink) {
      const insightLink = await linkInsightToActionPlan(
        insightId,
        savedActionPlan.id,
      );
      debug({ insightLink });
    }

    if (savedActionPlan?.id)
      await dispatch({
        type: UPDATE_SINGLE_ACTION_PLAN,
        payload: {
          insightId,
          actionPlanId: savedActionPlan.id,
          actionPlan: savedActionPlan,
        },
      });

    await dispatch(refetchInsight(insightId));
  };
};

export const fetchSelectedToolActionsPlans = (selectedToolId) => {
  return async (dispatch) => {
    const actionsPlans = await getInsightsActionsPlanByTool(selectedToolId);

    debug("fetchSelectedToolActionsPlans: ", { actionsPlans });

    if (actionsPlans.data && actionsPlans.data.length > 0) {
      dispatch({ type: UPDATE_ACTIONS_PLANS, payload: actionsPlans.data });
    }
  };
};

export const addSingleAction = (
  selectedToolId,
  insightId,
  actionPlanId,
  newAction,
  saveFiles,
) => {
  return async (dispatch) => {
    if (!actionPlanId) {
      const newActionsPlan = getActionsPlanEntity({ actions: [newAction] });
      await dispatch(
        updateInsightActionsPlan(insightId, newActionsPlan, saveFiles),
      );
      dispatch(fetchSelectedToolActionsPlans(selectedToolId));
      return;
    }

    if (actionPlanId && insightId) {
      const { data: currentActionPlan } = await getActionsPlan(actionPlanId);
      const { actions: currentActions = [] } = currentActionPlan;

      if (currentActionPlan && currentActions) {
        const saveNewAction = updateActionByResponsibleType(newAction);

        await updateSaveActionPlan(
          {
            ...currentActionPlan,
            actions: [...currentActions, saveNewAction],
          },
          saveFiles,
          insightId,
          dispatch,
        );

        if (saveNewAction?.responsible?.id) {
          dispatch(
            grantCollaboratorAccess(saveNewAction.responsible.id, insightId),
          );
        }

        await dispatch(fetchSelectedToolActionsPlans(selectedToolId));
        await dispatch(refetchInsight(insightId));
      }
    }
  };
};

export const updateOnlyAction = (
  selectedToolId,
  insightId,
  updatedAction,
  savedFiles,
) => {
  return async (dispatch) => {
    if (selectedToolId && insightId && updatedAction?.id) {
      const { data: originalAction } = await getOnlyAction(updatedAction.id);
      const persisted = updateActionByResponsibleType(updatedAction);

      debug("updateOnlyAction: ", {
        selectedToolId,
        insightId,
        updatedAction: persisted,
        savedFiles,
      });

      const bodyAttachment = {
        entityType: "ACTION_PLAN",
        entityId: persisted.id,
        relatedInsightId: insightId,
      };

      if (savedFiles.length > 0)
        await dispatch(
          addManyAttachment(savedFiles, bodyAttachment, insightId),
        );

      const { data: savedAction } = await putOnlyAction(persisted);
      dispatch(updateInsightAccess(insightId, originalAction, savedAction));
      await dispatch(addManyAttachment(savedFiles, bodyAttachment, insightId));
      if (selectedToolId !== "WORKSPACE_TOOLS")
        await dispatch(fetchSelectedToolActionsPlans(selectedToolId));
      await dispatch(refetchInsight(insightId));
    }
  };
};

export const fetchAllInsightsActionsPlans = (selectedToolIds) => {
  return async (dispatch) => {
    const actionsPlans = await getInsightsActionsPlans(selectedToolIds);

    const payload = makeObjLevelAnArray(actionsPlans);

    dispatch({ type: UPDATE_ACTIONS_PLANS, payload });
  };
};

export const deleteActionFromPlan = (
  selectedToolID,
  actionId,
  actionPlanId,
) => {
  return async (dispatch) => {
    debug("DELETING ACTION:", { actionId });
    const deletedAction = await deletePlanAction(actionId);

    debug({ deletedAction });

    dispatch({
      type: DELETE_ACTION_FROM_PLAN,
      payload: { actionId, actionPlanId },
    });

    dispatch(fetchSelectedToolActionsPlans(selectedToolID));
  };
};
