import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import fixLayout from "../../utils/adminLTE";
import ContainerHeader from "./ContainerHeader";

const styles = {
  content: {
    position: "relative",
    zIndex: "10",
    maxWidth: "1920px",
    paddingTop: "5px",
  },
  contentNotAuthenticated: {
    position: "absolute",
    width: "100%",
    zIndex: "10",
    top: "51px",
    left: "0",
  },
};

class Container extends Component {
  constructor(props) {
    super(props);
    this.doFixLayout = this.doFixLayout.bind(this);
  }

  componentDidMount() {
    fixLayout();

    window.addEventListener("resize", this.doFixLayout, true);

    const mainVideo = document.getElementById("mainVideo");
    if (mainVideo) mainVideo.playbackRate = 0.75;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.doFixLayout, true);
  }

  doFixLayout() {
    fixLayout();
  }

  render() {
    const {
      backgroundVideo,
      noSidebar,
      hideHeader,
      isAuthenticated,
    } = this.props;
    const header = (
      <ContainerHeader>
        <h1>
          {this.props.title}
          &nbsp;
          <small>{this.props.subtitle}</small>
        </h1>
      </ContainerHeader>
    );

    return (
      <div
        className={classnames("content-wrapper", {
          "content-wrapper-loggedoff": !isAuthenticated,
        })}
      >
        {backgroundVideo && (
          <div className="home-banner-container">
            <div className="home-banner-opacity" />
            <div className="home-banner-video">
              <video autoPlay muted loop id="mainVideo">
                <source src={backgroundVideo} type="video/mp4" />
              </video>
            </div>
          </div>
        )}
        {!hideHeader && this.props.headerContent
          ? this.props.headerContent
          : !hideHeader
          ? header
          : null}
        <section
          style={noSidebar ? styles.contentNotAuthenticated : styles.content}
        >
          {this.props.children}
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps)(Container);
