import React from "react";
import { Row, Col } from "react-bootstrap";
import { patternStyles } from "../jcss";
import BadgeProgressBar from "../../../../../../../Common/ReportCards/components/BadgeProgressBar";

const PhysicalScheduleChart = ({
  macroOverviewData = {},
  selectAndOpenInsight,
}) => {
  const {
    strongFont = "",
    physicalScheduleStyle = "",
    cardHeaderStyle = "",
    fixedScroll = "",
    leftAlign = "",
    centerAlign = "",
  } = patternStyles();

  const { physicalSchedule = [] } = macroOverviewData;

  const getBarColor = (schedule = "") => {
    if (Number(schedule) > 100) return "kanbanRed";

    return "kanbanGreen";
  };

  return (
    <Col md={4} sm={12} className={physicalScheduleStyle}>
      <Row className={cardHeaderStyle}>
        <strong>Avanço Físico x Cronograma</strong>
      </Row>
      <Row style={{ paddingTop: "8px" }}>
        <Row style={{ backgroundColor: "#F7F7F7", display: "flex" }}>
          <Col
            style={{ width: "40%", paddingLeft: "6px" }}
            className={leftAlign}
          >
            <div className={strongFont} style={{ fontSize: "15px" }}>
              Projeto
            </div>
          </Col>
          <Col style={{ width: "30%" }} className={centerAlign}>
            <div className={strongFont} style={{ fontSize: "15px" }}>
              Físico
            </div>
          </Col>
          <Col style={{ width: "30%" }} className={centerAlign}>
            <div className={strongFont} style={{ fontSize: "15px" }}>
              Cronograma
            </div>
          </Col>
        </Row>
        <Row className={fixedScroll} style={{ maxHeight: "220px" }}>
          {physicalSchedule.map(
            (
              {
                name = "",
                physical = 0,
                schedule = 0,
                insightId = 0,
                physicalWidth = "",
                scheduleWidth = "",
              },
              index,
            ) => {
              return (
                <Row
                  style={{ padding: "6px", display: "flex" }}
                  onClick={() => selectAndOpenInsight(insightId)}
                  key={index}
                >
                  <Col style={{ width: "40%" }} className={leftAlign}>
                    <div
                      title={name}
                      style={{
                        fontSize: "14px",
                        overflow: "hidden",
                        marginRight: "15px",
                        whiteSpace: "noWrap",
                      }}
                    >
                      {name}
                    </div>
                  </Col>
                  <Col className={centerAlign} style={{ width: "25%" }}>
                    <div className="itmHolder">
                      <div
                        style={{
                          zIndex: "999",
                          fontSize: "14px",
                          fontWeight: "700",
                          marginLeft: "20%",
                        }}
                        className="sub"
                      >
                        {physical}%
                      </div>
                      <div className="sub">
                        <BadgeProgressBar
                          widthBar={physicalWidth}
                          heightBar="18px"
                          leftText={100}
                          borderRadius="4px"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col className={centerAlign} style={{ width: "35%" }}>
                    <div className="itmHolder">
                      <div
                        style={{
                          zIndex: "999",
                          fontSize: "14px",
                          fontWeight: "700",
                          marginLeft: "20%",
                        }}
                        className="sub"
                      >
                        {schedule}%
                      </div>
                      <div className="sub">
                        <BadgeProgressBar
                          widthBar={scheduleWidth}
                          heightBar="18px"
                          barColor={getBarColor(schedule)}
                          leftText={100}
                          borderRadius="4px"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            },
          )}
        </Row>
      </Row>
    </Col>
  );
};

export default PhysicalScheduleChart;
