/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  GOALS_AND_BONUS,
  PROJECT_PRIORITIZATION,
  KPIS,
  OKR_ID,
  COMPETITIVE_ANALYSIS,
  SKILLS_ASSESSMENT,
} from "../../../../constants/tools.constants";
import GoalsAndBonus from "./GoalsAndBonus";
import Kpi from "./Kpi";
import Okr from "./Okr";
import ProjectPrioritization from "./ProjectPrioritization";
import CompetitiveAnalysis from "./CompetitiveAnalysis";
import SkillAssessment from "./SkillAssessment";

const NewQuestionDialog = (props) => {
  const { params = {} } = props;
  const { toolId } = params;

  const getCustomToolDialog = () => {
    switch (toolId) {
      case GOALS_AND_BONUS:
        return <GoalsAndBonus {...props} />;
      case KPIS:
        return <Kpi {...props} />;
      case OKR_ID:
        return <Okr {...props} />;
      case PROJECT_PRIORITIZATION:
        return <ProjectPrioritization {...props} />;
      case COMPETITIVE_ANALYSIS:
        return <CompetitiveAnalysis {...props} />;
      case SKILLS_ASSESSMENT:
        return <SkillAssessment {...props} />;
      default:
        return null;
    }
  };

  return getCustomToolDialog();
};

export default NewQuestionDialog;
