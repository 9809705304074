/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import { useState, useEffect } from "react";
import { getObjectDifference } from "../ArrayUtils";

export function useCompareChanges({
  compare = {},
  base = {},
  ignoreKeys = [],
  isLoading = false,
}) {
  const [changes, setChanges] = useState([]);

  useEffect(() => {
    const diff = getObjectDifference(compare, base);

    const anyChanges = [];

    Object.keys(diff).forEach((objKey) => {
      if (ignoreKeys.includes(objKey)) return;
      anyChanges.push(objKey);
    });

    const newChanges = !isLoading ? anyChanges : [];

    if (!_.isEqual(newChanges, changes)) setChanges(newChanges);
  }, [compare, base, isLoading]);

  return { changes, haveChanges: changes.length > 0 };
}
