import { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getKpiStates } from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";
import kpiUtils from "../../../../utils/kpis";
import { updateCenary } from "../../../../actions/cenaryActions";

const {
  getObjectDifference,
  getObjectInfoById,
  getOnlySlugValues,
  removeObjSlug,
} = utils;

const { buildFormAdmConfigBody, getKpiConfigBySelectedToolId } = kpiUtils;

const defaultForm = buildFormAdmConfigBody({});

const KpiWatcher = (props) => {
  const [kpiAdministration, setKpiAdministration] = useState(defaultForm);
  const [firstValidation, setFirstValidation] = useState(false);

  const {
    allKpiAdministration = [],
    selectedTool = {},
    allSceneries = [],
    updateCenary,
  } = props;

  useEffect(() => {
    let toolAdministration = getKpiConfigBySelectedToolId(
      selectedTool.id,
      allKpiAdministration,
    );
    toolAdministration = buildFormAdmConfigBody(toolAdministration);

    const isAdmObjDiff = !_.isEqual(toolAdministration, kpiAdministration);

    if (
      toolAdministration &&
      toolAdministration.themes &&
      allSceneries.length > 0
    ) {
      if (isAdmObjDiff || !firstValidation) {
        const compareBase = removeObjSlug(kpiAdministration.themes, "id");

        const diff = getObjectDifference(
          toolAdministration.themes,
          compareBase,
        );

        if (diff) {
          if (!firstValidation) setFirstValidation(true);

          const watchThemes = getOnlySlugValues(diff, "id");

          if (watchThemes.length > 0) {
            allSceneries.forEach((scenaryInfo) => {
              const { kpiConfigurationThemeId } = scenaryInfo;
              const relatedTheme =
                watchThemes[watchThemes.indexOf(kpiConfigurationThemeId)] ||
                null;

              if (relatedTheme && scenaryInfo.name) {
                const themeInfo = getObjectInfoById(
                  relatedTheme,
                  toolAdministration.themes,
                  "id",
                );

                if (themeInfo.title !== scenaryInfo.name) {
                  updateCenary(
                    { ...scenaryInfo, name: themeInfo.title },
                    selectedTool.id,
                  );
                }
              }
            });
          }
        }

        if (isAdmObjDiff) setKpiAdministration(toolAdministration);
      }
    }
  }, [
    allKpiAdministration,
    allSceneries,
    firstValidation,
    kpiAdministration,
    selectedTool.id,
    updateCenary,
  ]);

  return null;
};

const mapStateToProps = (state) => {
  const { allKpiAdministration } = getKpiStates(state);

  return { allKpiAdministration };
};

export default connect(mapStateToProps, { updateCenary })(KpiWatcher);
