import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  authenticateUserWithOTP,
  setAuthInfo,
  setHideSidebar,
} from "../../../../actions/authActions";
import { getDecryptedObjectBy } from "../../../../actions/appActions";
import LoginOTPConfirmation from "../../../../components/Login/LoginOTPConfirmation";
import setAuthorizationToken from "../../../../utils/setAuthorizationToken";
import ParticipantWorkflowTable from "./elements/ParticipantWorkflowTable";

const ParticipantWorkflowReply = ({ setAuthInfo, setHideSidebar }) => {
  const [email, setEmail] = useState("");
  const [toolId, setToolId] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setHideSidebar(true);

    async function decrypt() {
      setIsLoading(true);

      const urlCode = location.search.split("c=").pop().split("#")[0];
      const inviteBody = await getDecryptedObjectBy({
        token: urlCode,
      });

      setIsLoading(false);

      if (inviteBody?.email) setEmail(inviteBody.email);
      if (inviteBody?.otpCode) setOtpCode(inviteBody.otpCode);
      if (inviteBody?.selected_tool_id) setToolId(inviteBody.selected_tool_id);
    }

    decrypt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleLogin() {
    if (otpCode) {
      setError(false);
      setIsLoading(true);

      const authResponse = await authenticateUserWithOTP(otpCode);
      const { status, id_token } = authResponse || {};

      if (!authResponse || status === 401 || !id_token) {
        setError(true);
        setIsLoading(false);
      } else {
        setAuthorizationToken(id_token);
        await setAuthInfo({ isAuthenticated: true, email }, () => {
          setIsAuthenticated(true);
          setIsLoading(false);
        });
      }
    }
  }

  if (!isAuthenticated)
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h3>Login</h3>
        {!toolId && !isLoading && (
          <h4 className="text-danger">
            Não foi possível encontrar sua autoavaliação
          </h4>
        )}
        <div style={{ width: "280px" }}>
          <LoginOTPConfirmation
            email={email}
            otpCode={otpCode}
            isLoading={isLoading}
            onConfirm={handleLogin}
            error={error}
          />
        </div>
      </div>
    );

  return <ParticipantWorkflowTable toolId={toolId} email={email} />;
};

export default connect(null, { setAuthInfo, setHideSidebar })(
  ParticipantWorkflowReply,
);
