import _ from "lodash";
import { updateAllList } from "../utils/reducerUtils";

const INITIAL_STATE = {
  allGuidelineStructures: {},
};

const companyGuidelineReducer = (state = INITIAL_STATE, action) => {
  const payload = action.payload || {};
  const { companyId = null, isLoading = false } = payload;

  function updatedCompanyGuideline(newGuidelineStructure) {
    if (companyId && newGuidelineStructure) {
      const currentAllGuidelines = _.cloneDeep(state.allGuidelineStructures);
      const currentCompanyGuideline = currentAllGuidelines[companyId] || [];

      return {
        ...state,
        isLoading: false,
        allGuidelineStructures: {
          ...state.allGuidelineStructures,
          [companyId]: updateAllList(
            currentCompanyGuideline,
            [newGuidelineStructure],
            "id"
          ),
        },
      };
    } else {
      return state;
    }
  }

  switch (action.type) {
    case "COMPANY_GUIDELINE_LOADING": {
      return { ...state, isLoading };
    }
    case "FETCHED_COMPANY_GUIDELINES": {
      return {
        ...state,
        allGuidelineStructures: {
          ...state.allGuidelineStructures,
          [companyId]: payload.structure,
        },
        isLoading: false,
      };
    }
    case "UPDATE_COMPANY_GUIDELINES_STRUCTURE": {
      return updatedCompanyGuideline(payload.structure);
    }
    default: {
      return state;
    }
  }
};

export default companyGuidelineReducer;
