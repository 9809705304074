import React, { Component } from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import _ from "lodash";
import { injectIntl, defineMessages } from "react-intl";
import { Popover, OverlayTrigger } from "react-bootstrap";
import moment from "moment";

import logoRokau from "../../../assets/img/logo-rookau-gray.png";

import { globalMessages } from "../../../utils/global";
import { getSingleIndicatorResults } from "../../../utils/kpis/calc2";
import { displayTransformValue } from "../../../utils/kpis/display";
import {
  getKpi_MU_options,
  getMeasurementOptions,
} from "../../../constants/kpis";
import { getTotalActionsByInsights } from "../../../utils/actionsPlan/filter";
import { exportProcessesReport } from "../../../utils/rookauToolProcesses/activities/excel";

import { getTraceabilitiesList } from "../../../actions/insightTraceabilitiesActions";
import { searchAllInsightActivities } from "../../../actions/entity-documents/rookau_tool_processes_activity";
import {
  formatFrequency,
  formatPeriodDemandGrowth,
  formatPeriodicity,
  formatChecklistIndicator,
} from "../../../utils/rookauToolProcesses/activities/format";
import SelectFilter from "../../Common/SelectFilter";

import { simpleSaveAsExcel } from "../../Common/Export/ExportCSV";

// customizations
import ProcessesBigPicture from "../EvaluationBadge/customizations/ProcessesBigPicture";
import ProjectCustomization from "../EvaluationBadge/customizations/ProjectCustomization";
import ProjectPrioritization from "../EvaluationBadge/customizations/ProjectPrioritization";
import GoalsAndBonus from "../EvaluationBadge/customizations/GoalsAndBonus";
import Kpi from "../EvaluationBadge/customizations/Kpi";
import SwotTheme from "../EvaluationBadge/customizations/SwotCustomization";
import PeopleBigPicture from "../EvaluationBadge/customizations/PeopleBigPicture";
import OkrCustomization, {
  TextOkrCustomization,
} from "../EvaluationBadge/customizations/OkrCustomization";
import SkillAssessment from "../EvaluationBadge/customizations/SkillAssessment";
import CompetitiveAnalysis from "../EvaluationBadge/customizations/CompetitiveAnalysis";
import Avatar from "../../Common/Avatar";

import {
  PROCESSES_BIG_PICTURE,
  SWOT,
  PEOPLE_BIG_PICTURE,
  OKR_ID,
  PROJECT_PORTFOLIO,
  PROJECT_PRIORITIZATION,
  GOALS_AND_BONUS,
  KPIS,
  SKILLS_ASSESSMENT,
  COMPETITIVE_ANALYSIS,
} from "../../../constants/tools.constants";

import {
  getSwotStates,
  getBigProcessesStates,
  getBigPeopleStates,
  getSelectedCompanyStates,
  getSelectedToolStates,
  getProjectStates,
  insightsGoalState,
  getKpiStates,
  getSkillAssessmentStates,
  getGoalsBonusStates,
  getCompAnalysisStates,
  getActionsPlanStates,
  getUserProfileStates,
  getCompanyMembersAndParticipants,
} from "../../customMapStates";

import utils, { getToolEntityText } from "../../../utils/toolUtils";
import { getSkillAssessmentScoreViewOrdenation } from "../../../utils/skillsAssessment/calc";
import { renderSkillAssessmentLabels } from "../../../utils/skillsAssessment/display";
import { getObjectInfoById } from "../../../utils/ArrayUtils";
import { middlewareQuestionStructure } from "../../../utils/membersParticipantsUtils";
import { fetchCompetitiveAnalysisPoints } from "../../../actions/compAnalysisActions";
import kpiUtils from "../../../utils/kpis";
import gbUtils from "../../../utils/goalsBonus";
import { translatedText } from "../../../utils/translationUtils";
import ButtonGeneratePdf from "../../Common/ButtonGeneratePdf";
import ButtonExportExcel from "../../Common/ButtonExportExcel";

const {
  checkCustomization,
  orderByTotalPoints,
  orderByEvaluation,
  orderInsights,
  getMaturityAverageList,
  getPeopleAverageList,
  renderSwotColumnLabel,
  renderPeopleBPColumnLabel,
  renderProcessBPColumnLabel,
  renderProjectPrioritizationLabel,
  renderProjectPortfolioLabel,
  renderOkrColumnLabel,
  renderDefaultColumnLabel,
  getSelectsList,
  getOnlySublevelArrays,
  getToolCategoryUpdatedInfo,
  getInsightGoalsWithPercentage,
} = utils;

const HeaderPdf = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px 0px 20px;
  display: none;
  margin-bottom: 10px;

  @media print {
    display: block;
    -webkit-print-color-adjust: exact;
    padding: 0px;
    margin: 0px;
    margin-top: 50px;
  }
`;

const { renderKpiLabels, getSelectedKpiIndicatorsPercentages } = kpiUtils;
const { getSelectedGoalsBonusGoalsPercentages } = gbUtils;

const possibleOrdenation = ["up", "down"];

const messages = defineMessages({
  cenary: { id: "global.cenary" },
  theme: { id: "global.theme" },
  objective: { id: "global.objective" },
  keyResult: { id: "global.keyResult" },
  responsible: { id: "global.responsible" },
  traceabilityConnections: { id: "global.traceabilityConnections" },
  insightPoints: { id: "global.insightPoints" },
  status: { id: "global.status" },
  reach_performance_okr: { id: "reach_performance_okr" },
  acp_main_title: { id: "acp_main_title" },
});

const translation = (id) => translatedText(id, messages);

function exportOkrReport({
  list,
  handleStatusCustomization,
  getTraceabilitiesByInsightId,
}) {
  if (list && list.length > 0) {
    const headers = [
      {
        header: translation("cenary"),
        key: "cenary",
        width: 30,
      },
      {
        header: translation("theme"),
        key: "theme",
        width: 30,
      },
      {
        header: translation("objective"),
        key: "objective",
        width: 60,
      },
      {
        header: translation("keyResult"),
        key: "keyresult",
        width: 60,
      },
      {
        header: translation("responsible"),
        key: "responsible",
        width: 20,
      },
      {
        header: translation("traceabilityConnections"),
        key: "connections",
        width: 15,
      },
      {
        header: translation("insightPoints"),
        key: "points",
        width: 15,
      },
      {
        header: translation("status"),
        key: "status",
        width: 20,
      },
      {
        header: `${translation("reach_performance_okr")} (%)`,
        key: "reached",
        width: 15,
      },
      {
        header: translation("acp_main_title"),
        key: "actions",
        width: 15,
      },
    ];

    const data = list.map((insightInfo) => {
      const insightConnections = getTraceabilitiesByInsightId(insightInfo.id);

      const { responsibleMember } = insightInfo;

      const otherDetails = handleStatusCustomization(
        insightInfo.id,
        OKR_ID,
        true,
      );

      const { status, reached, actions } = otherDetails;

      return {
        cenary: insightInfo.cenaryName,
        theme: insightInfo.themeName,
        objective: insightInfo.questionName,
        keyresult: insightInfo.text,
        responsible: responsibleMember ? responsibleMember.name : "",
        connections: insightConnections ? insightConnections.length : "",
        points: insightInfo.points,
        status,
        reached,
        actions,
      };
    });

    const timeStampString = moment().toJSON();

    simpleSaveAsExcel(data, headers, null, `report-${timeStampString}`, [
      { line: 1, fill: "eeeeee" },
    ]);
  }
}

function exportKpisReport({
  selectedToolId,
  list,
  getTraceabilitiesByInsightId,
  allKpiAdministration,
  allActionsPlans,
}) {
  if (list && list.length > 0) {
    const unityOptions = getKpi_MU_options();
    const measurementOptions = getMeasurementOptions();

    const headers = [
      {
        header: translation("cenary"),
        key: "cenary",
        width: 30,
      },
      {
        header: translation("theme"),
        key: "theme",
        width: 30,
      },
      {
        header: translation("objective"),
        key: "objective",
        width: 60,
      },
      {
        header: "Indicador",
        key: "indicator",
        width: 60,
      },
      {
        header: "Unidade",
        key: "measurementUnity",
        width: 20,
      },
      {
        header: "Medição",
        key: "measurement",
        width: 10,
      },
      {
        header: "Mínimo",
        key: "gate",
        width: 20,
      },
      {
        header: "Planejado",
        key: "appropriated",
        width: 20,
      },
      {
        header: "Superado",
        key: "exceeded",
        width: 20,
      },
      {
        header: "Ano anterior",
        key: "lastPeriod",
        width: 20,
      },
      {
        header: "Atual",
        key: "actual",
        width: 20,
      },
      {
        header: `Score (%)`,
        key: "score",
        width: 15,
      },
      {
        header: translation("responsible"),
        key: "responsible",
        width: 25,
      },
      {
        header: translation("traceabilityConnections"),
        key: "connections",
        width: 15,
      },
      {
        header: translation("insightPoints"),
        key: "points",
        width: 15,
      },
      {
        header: translation("acp_main_title"),
        key: "actions",
        width: 15,
      },
    ];

    const kpiConfiguration = allKpiAdministration.find(
      (kpi) => kpi.selectedToolId === selectedToolId,
    );
    const { goals = [] } = kpiConfiguration;

    const data = list.map((insightInfo) => {
      const insightConnections = getTraceabilitiesByInsightId(insightInfo.id);
      const { responsibleMember } = insightInfo;
      const goalInfo = goals.find(
        (goal) => goal.id === insightInfo.kpiConfigurationGoalId,
      );
      const result = getSingleIndicatorResults(
        goalInfo,
        kpiConfiguration.goalTargets,
      );
      const actions = getTotalActionsByInsights(
        [insightInfo.id],
        allActionsPlans,
      );

      const isDate = goalInfo.measurementUnity === "DATE";
      const gate = isDate
        ? displayTransformValue(goalInfo.target.gate, "DATE")
        : goalInfo.target.gate;
      const appropriated = isDate
        ? displayTransformValue(goalInfo.target.appropriated, "DATE")
        : goalInfo.target.appropriated;
      const exceeded = isDate
        ? displayTransformValue(goalInfo.target.exceeded, "DATE")
        : goalInfo.target.exceeded;
      const lastPeriod = isDate
        ? displayTransformValue(result.dateTargets?.lastPeriod?.value, "DATE")
        : result.lastYearCourseEvaluation;
      const actual = isDate
        ? displayTransformValue(result.dateTargets?.actual?.value, "DATE")
        : result.goalPeriodTotalEvaluation;

      return {
        cenary: insightInfo.cenaryName,
        theme: insightInfo.themeName,
        objective: insightInfo.questionName,
        indicator: insightInfo.text,
        measurementUnity: unityOptions.find(
          ({ value }) => value === goalInfo.measurementUnity,
        )?.label,
        measurement: measurementOptions.find(
          ({ value }) => value === goalInfo.measurement,
        )?.label,
        gate,
        appropriated,
        exceeded,
        lastPeriod,
        actual,
        score: result.goalPercentage,
        responsible: responsibleMember ? responsibleMember.name : "",
        connections: insightConnections ? insightConnections.length : "",
        points: insightInfo.points,
        actions: actions.length,
      };
    });

    const timeStampString = moment().toJSON();

    simpleSaveAsExcel(
      data,
      headers,
      null,
      `indicators-report-${timeStampString}`,
      [{ line: 1, fill: "eeeeee" }],
    );
  }
}

function getExportFunction(tool_type_id) {
  if (tool_type_id === OKR_ID) return exportOkrReport;
  if (tool_type_id === KPIS) return exportKpisReport;
  if (tool_type_id === PROCESSES_BIG_PICTURE) return exportProcessesReport;

  return () => {};
}

function checkHasCustomExcelExport(tool_type_id) {
  const hasCustomExcelExport = [OKR_ID, KPIS, PROCESSES_BIG_PICTURE].includes(
    tool_type_id,
  );

  return {
    hasCustomExcelExport,
    exportFunction: getExportFunction(tool_type_id),
  };
}

class ScoreView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countClick: 1,
      filterStatus: "down",
      dataColumn: "evaluation",
      toolPoints: null,
      connectionsDisplay: [],
      selectedTool: null,
      firstFetch: false,
    };

    this.handleDataSort = this.handleDataSort.bind(this);
    this.renderEvaluationArrows = this.renderEvaluationArrows.bind(this);
    this.renderTotalArrows = this.renderTotalArrows.bind(this);
    this.dataSort = this.dataSort.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { toolPoints, selectedTool } = this.state;

    if (!_.isEqual(newProps.toolPoints, toolPoints)) {
      this.setState({
        toolPoints: newProps.toolPoints,
      });
    }

    if (!_.isEqual(newProps.selectedTool, selectedTool)) {
      this.setState({
        selectedTool: newProps.selectedTool,
        connectionsDisplay: [],
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const pointsHaveChanged = !_.isEqual(
      prevState.toolPoints,
      this.state.toolPoints,
    );
    const selectedToolChanged = !_.isEqual(
      prevState.selectedTool,
      this.state.selectedTool,
    );

    const insightsChanged = !_.isEqual(
      prevProps.allAnswers,
      this.props.allAnswers,
    );

    const { tool = {}, fetchFiledOption = false } = this.props || {};

    const toolId = tool.tool.id;
    const selectedToolId = tool.id;

    const isCompetitiveAnalysis = toolId === COMPETITIVE_ANALYSIS;

    if (
      pointsHaveChanged ||
      selectedToolChanged ||
      !this.state.firstFetch ||
      insightsChanged
    ) {
      let finalInsightsList = [];

      const filteredInsights = this.getFilteredInsightsList();

      if (filteredInsights.length > 0) {
        filteredInsights.forEach((insightInfo) => {
          finalInsightsList = [...finalInsightsList, insightInfo.id];
        });

        this.props.getTraceabilitiesList(finalInsightsList);
        if (isCompetitiveAnalysis)
          this.props.fetchCompetitiveAnalysisPoints(
            selectedToolId,
            fetchFiledOption,
          );

        if (!this.state.firstFetch) {
          this.setState({ firstFetch: true });
        }
      }
    }
  }

  getTraceabilitiesByInsightId = (insightID) => {
    const { insightTraceabilities } = this.props;

    return insightTraceabilities && insightTraceabilities[insightID]
      ? insightTraceabilities[insightID]
      : [];
  };

  getOnlyFilteredQuestions = () => {
    const { allQuestions = {}, filterData } = this.props;

    const filteredOnes = [];

    const onlyQuestions = getOnlySublevelArrays(allQuestions);

    onlyQuestions.forEach((questionInfo = {}) => {
      const questionTheme = questionInfo.theme || {};

      if (
        filterData.themes &&
        filterData.themes.indexOf(questionTheme.id) > -1
      ) {
        filteredOnes.push(questionInfo);
      }
    });

    return filteredOnes;
  };

  getInsightBody = (insightID) => {
    let finalBody = {};

    this.getInsightsList().forEach((insightBody) => {
      if (insightBody.id === insightID) finalBody = insightBody;
    });

    return finalBody;
  };

  getOrdenationByClickCount = (click) => {
    const index = click % possibleOrdenation.length;

    return possibleOrdenation[index];
  };

  orderGoals(orderList, points, goalIdSlug = "goalsBonusProgramGoalId") {
    const ordered = [];
    const addedInsights = [];

    if (Array.isArray(points) && orderList) {
      orderList.forEach((goalInfo) => {
        if (goalInfo.id) {
          points.forEach((insightInfo) => {
            if (
              insightInfo[goalIdSlug] &&
              insightInfo[goalIdSlug] === goalInfo.id &&
              addedInsights.indexOf(insightInfo.id) === -1
            ) {
              ordered.push(insightInfo);
              addedInsights.push(insightInfo.id);
            }
          });
        }
      });
    }

    return ordered;
  }

  dataSort() {
    const {
      tool = {},
      allGoalBonusAdministration = [],
      allKpiAdministration = [],
      allSkillAssessment = [],
      selectedTool = {},
      boardPoints = [],
    } = this.props;

    const { dataColumn, filterStatus } = this.state;

    this.renderTotalArrows();
    this.renderEvaluationArrows();

    const Points = this.getFilteredInsightsList();
    let toolID = null;
    let isCustomized = false;

    if (this.props.tool.tool) {
      toolID = tool.tool.id;
      isCustomized = checkCustomization(toolID);
    }

    let finalOrdenation = [];
    let orderedList = [];
    let processesEvaluations = [];

    const order1 = filterStatus === "down" ? 1 : -1;
    const order2 = filterStatus === "down" ? -1 : 1;

    if (dataColumn === "totalPoints") {
      finalOrdenation = orderByTotalPoints(Points, filterStatus);
    } else if (dataColumn === "evaluation" && isCustomized) {
      switch (toolID) {
        case PROCESSES_BIG_PICTURE:
          processesEvaluations = getMaturityAverageList(
            Points,
            this.props.allMaturityData,
          );
          orderedList = orderByEvaluation(processesEvaluations);
          finalOrdenation = orderInsights(orderedList, Points);

          break;
        case PEOPLE_BIG_PICTURE:
          const evaluations = getPeopleAverageList(
            Points,
            this.props.allPeopleEvaluation,
          );
          orderedList = orderByEvaluation(evaluations);
          finalOrdenation = orderInsights(orderedList, Points);
          break;
        case SWOT:
          orderedList = orderByEvaluation(this.props.allSwotEvaluation);

          finalOrdenation = orderInsights(orderedList, Points);
          break;
        case PROJECT_PORTFOLIO:
          const { allProjectEvaluation = [] } = this.props;

          orderedList = orderByEvaluation(allProjectEvaluation);

          finalOrdenation = orderInsights(orderedList, Points);
          break;
        case OKR_ID:
          const { allInsightGoals = [] } = this.props;

          const okrGoalsEvaluations =
            getInsightGoalsWithPercentage(allInsightGoals);

          orderedList = orderByEvaluation(okrGoalsEvaluations);

          finalOrdenation = orderInsights(orderedList, Points);
          break;
        case KPIS:
          const kpisEvaluations = getSelectedKpiIndicatorsPercentages(
            selectedTool.id,
            allKpiAdministration,
          );

          orderedList = [...kpisEvaluations].sort((a, b) =>
            Number(a.percentage) > Number(b.percentage) ? order1 : order2,
          );

          finalOrdenation = this.orderGoals(
            orderedList,
            Points,
            "kpiConfigurationGoalId",
          );

          break;
        case GOALS_AND_BONUS:
          const goalsAndBonus = getSelectedGoalsBonusGoalsPercentages(
            selectedTool.id,
            allGoalBonusAdministration,
          );

          orderedList = [...goalsAndBonus].sort((a, b) =>
            Number(a.percentage) > Number(b.percentage) ? order1 : order2,
          );

          finalOrdenation = this.orderGoals(
            orderedList,
            Points,
            "goalsBonusProgramGoalId",
          );

          break;
        case SKILLS_ASSESSMENT:
          const averagesByInsights = getSkillAssessmentScoreViewOrdenation(
            selectedTool.id,
            allSkillAssessment,
          );

          orderedList = [...averagesByInsights].sort((a, b) =>
            Number(a.average) > Number(b.average) ? order1 : order2,
          );

          finalOrdenation = this.orderGoals(
            orderedList,
            Points,
            "skillsAssessmentParticipantId",
          );

          break;
        case COMPETITIVE_ANALYSIS:
          const boardPointsArray = getOnlySublevelArrays(boardPoints);

          const updatedPoints = Points.map((line) => {
            const { id: insightId = "" } = line;

            const currentPoints =
              boardPointsArray.filter(
                (point) => point.insightId === insightId,
              )[0] || false;

            if (!currentPoints) return { ...line, evaluationPoints: 0 };

            return { ...line, evaluationPoints: currentPoints.points };
          });

          orderedList = [...updatedPoints].sort((a, b) =>
            Number(a.evaluationPoints) > Number(b.evaluationPoints)
              ? order1
              : order2,
          );

          finalOrdenation = orderedList;
          break;
        default:
          finalOrdenation = Points;
      }
    } else {
      finalOrdenation = Points;
    }

    return finalOrdenation;
  }

  renderTotalArrows() {
    const { filterStatus, dataColumn } = this.state;
    let dataSortButton;
    if (filterStatus === "noSort") {
      dataSortButton = (
        <>
          <i className="fas fa-caret-up" />
          <i className="fas fa-caret-down" />
        </>
      );
    }
    if (dataColumn === "totalPoints") {
      if (filterStatus === "up") {
        dataSortButton = <i className="fas fa-caret-up " />;
      } else if (filterStatus === "down") {
        dataSortButton = <i className="fas fa-caret-down" />;
      }
    } else {
      dataSortButton = (
        <>
          <i className="fas fa-caret-up" />
          <i className="fas fa-caret-down" />
        </>
      );
    }
    return dataSortButton;
  }

  renderEvaluationArrows() {
    const { filterStatus, dataColumn } = this.state;
    let dataSortButton = null;
    if (filterStatus === "noSort") {
      dataSortButton = (
        <>
          <i className="fas fa-caret-up" />
          <i className="fas fa-caret-down" />
        </>
      );
    }
    if (dataColumn === "evaluation") {
      if (filterStatus === "up") {
        dataSortButton = <i className="fas fa-caret-up" />;
      } else if (filterStatus === "down") {
        dataSortButton = <i className="fas fa-caret-down" />;
      }
    } else {
      dataSortButton = (
        <>
          <i className="fas fa-caret-up" />
          <i className="fas fa-caret-down" />
        </>
      );
    }
    return dataSortButton;
  }

  getSelectedToolPoints = () => {
    const { toolPoints, tool } = this.props;
    const toolID = tool && tool.id ? tool.id : 0;

    return toolPoints[toolID] ? toolPoints[toolID] : {};
  };

  getAllInsightsList = (onlyPoints = []) => {
    const finalInsights = [];

    const { allAnswers = {} } = this.props;
    const onlyFilteredQuestions = this.getOnlyFilteredQuestions();

    if (onlyFilteredQuestions && onlyFilteredQuestions.length > 0) {
      onlyFilteredQuestions.forEach((questionInfo) => {
        const questionInsights = allAnswers[questionInfo.id];

        if (questionInsights && questionInsights.length > 0) {
          questionInsights.forEach((insightInfo) => {
            const insightPoints = getObjectInfoById(
              insightInfo.id,
              onlyPoints,
              "id",
            );

            finalInsights.push({ ...insightInfo, ...insightPoints });
          });
        }
      });
    }

    return finalInsights;
  };

  getInsightsList = () => {
    const selectedToolPoints = this.getSelectedToolPoints();

    const onlyPoints =
      selectedToolPoints && selectedToolPoints.points
        ? selectedToolPoints.points
        : [];

    const newInsightsList = this.getAllInsightsList(onlyPoints);

    return newInsightsList;
  };

  getFilteredInsightsList = () => {
    const { allSceneries, allThemes, allQuestions, allAnswers, filterData } =
      this.props;

    const finalFiltered = [];

    const onlyQuestions = getOnlySublevelArrays(allQuestions);
    const onlyThemes = getOnlySublevelArrays(allThemes);

    Object.keys(allAnswers).forEach((questionId) => {
      allAnswers[questionId].forEach((insightInfo) => {
        if (insightInfo) {
          const { question = {} } = insightInfo;
          const { theme = {} } = question;
          const { cenary = {} } = theme;

          const questionInfo = getObjectInfoById(
            question.id,
            onlyQuestions,
            "id",
          );

          const themeInfo = getObjectInfoById(theme.id, onlyThemes, "id");

          const scenerieInfo = getObjectInfoById(cenary.id, allSceneries, "id");

          const { selectedTool = {} } = scenerieInfo || {};
          const { tool = {} } = selectedTool || {};
          const { id: toolId = {} } = tool || {};

          const insightPoints =
            (insightInfo.evaluations &&
              insightInfo.evaluations[0] &&
              insightInfo.evaluations[0].score) ||
            0;
          const insightTraceabilitiesPoints =
            insightInfo.totalTraceabilitiesPoints;

          const appendInsight = {
            ...insightInfo,
            questionId: question.id,
            questionName: getToolEntityText(
              toolId,
              "question",
              questionInfo.text,
            ),
            themeId: theme.id,
            themeName: themeInfo.name,
            cenaryId: cenary.id,
            cenaryName: getToolEntityText(toolId, "cenary", scenerieInfo.name),
            traceabilitys:
              (insightInfo.traceabilitys && insightInfo.traceabilitys.length) ||
              0,
            points: insightPoints,
            traceabilityPoints: insightTraceabilitiesPoints || 0,
            totalPoints: insightPoints + insightTraceabilitiesPoints || 0,
          };
          if (
            filterData.themes &&
            filterData.themes.indexOf(theme.id) > -1 &&
            filterData.questions &&
            filterData.questions.indexOf(question.id) > -1
          ) {
            finalFiltered.push(appendInsight);
          }
        }
      });
    });

    return finalFiltered;
  };

  handleToggleConnectionsDisplay = (insightID) => {
    const { connectionsDisplay } = this.state;
    const newSelectedConnection =
      connectionsDisplay.indexOf(insightID) > -1 ? [] : [insightID];

    this.setState({
      connectionsDisplay: newSelectedConnection,
    });
  };

  handleDataSort(columnName) {
    const nextCount = this.state.countClick + 1;
    const newOrdenation = this.getOrdenationByClickCount(nextCount);

    this.setState({
      countClick: nextCount,
      filterStatus: newOrdenation,
      dataColumn: columnName,
    });
  }

  handleNameCustomization = (toolID) => {
    const { intl } = this.props;

    switch (toolID) {
      case PROCESSES_BIG_PICTURE:
        return intl.formatMessage(globalMessages.maturity);
      case PEOPLE_BIG_PICTURE:
        return intl.formatMessage(globalMessages.careerMap);
      case SWOT:
        return intl.formatMessage(globalMessages.swotEvaluation);
      case PROJECT_PRIORITIZATION:
        return "PATHWAY";
      case PROJECT_PORTFOLIO:
        return intl.formatMessage(globalMessages.projectStatus);
      case OKR_ID:
        return "Status";
      case GOALS_AND_BONUS:
        return "META";
      case KPIS:
        return "SCORE";
      case COMPETITIVE_ANALYSIS:
        return intl.formatMessage(globalMessages.points);
      default:
        return false;
    }
  };

  handleStatusCustomization = (insightID, toolID, textMode = false) => {
    const insightBody = this.getInsightBody(insightID);

    const { question = {} } = insightBody;
    const { theme = {} } = question;
    const { cenary = {} } = theme;
    const { name = {} } = cenary;

    const { showHiddenExpectation, allInsightGoals, allActionsPlans } =
      this.props;

    const appendProps = {
      insightsToCalc: [insightBody],
      from: "score",
      customToolType: name,
      showHiddenExpectation,
      allInsightGoals,
      allActionsPlans,
    };

    if (textMode === true) {
      const res = TextOkrCustomization({
        textMode,
        intl: this.props.intl,
        ...appendProps,
      });
      return { ...res };
    }

    switch (toolID) {
      case OKR_ID:
        return <OkrCustomization {...appendProps} />;
      case PROCESSES_BIG_PICTURE:
        return <ProcessesBigPicture {...appendProps} />;
      case PEOPLE_BIG_PICTURE:
        return (
          <PeopleBigPicture
            {...appendProps}
            pullClass="pull-middle"
            displayAverage
          />
        );
      case SWOT:
        return (
          <SwotTheme
            getScorePoints
            showProgressEvaluation={false}
            {...appendProps}
          />
        );
      case PROJECT_PORTFOLIO:
        return <ProjectCustomization {...appendProps} />;
      case PROJECT_PRIORITIZATION:
        return <ProjectPrioritization {...appendProps} showPathway />;
      case GOALS_AND_BONUS:
        return <GoalsAndBonus {...appendProps} />;
      case KPIS:
        return <Kpi {...appendProps} />;
      case SKILLS_ASSESSMENT:
        return <SkillAssessment {...appendProps} />;
      case COMPETITIVE_ANALYSIS:
        return <CompetitiveAnalysis {...appendProps} />;
      default:
        return null;
    }
  };

  renderOrdinationButton(field) {
    return (
      <button
        className="btn btn-data-sort grow"
        style={{ paddingRight: "0px" }}
      >
        {field === "totalPoints" && this.renderTotalArrows()}
        {field === "evaluation" && this.renderEvaluationArrows()}
      </button>
    );
  }

  renderCustomizedColumnLabel() {
    const { tool, intl } = this.props;

    let toolID = null;

    if (this.props.tool.tool) {
      toolID = tool.tool.id;
    }

    switch (toolID) {
      case PROCESSES_BIG_PICTURE:
        return renderProcessBPColumnLabel(intl, globalMessages);
      case PEOPLE_BIG_PICTURE:
        return renderPeopleBPColumnLabel(intl, globalMessages);
      case SWOT:
        return renderSwotColumnLabel(intl, globalMessages);
      case OKR_ID:
        return renderOkrColumnLabel(intl, globalMessages);
      case PROJECT_PRIORITIZATION:
        return renderProjectPrioritizationLabel(intl, globalMessages);
      case PROJECT_PORTFOLIO:
        return renderProjectPortfolioLabel(intl, globalMessages);
      case KPIS:
        return renderKpiLabels();
      case SKILLS_ASSESSMENT:
        return renderSkillAssessmentLabels();
      default:
        return renderDefaultColumnLabel(intl, globalMessages);
    }
  }

  displayStatusColumn = (insightBody) => {
    const { tool } = this.props;
    const toolID = tool && tool.tool && tool.tool.id ? tool.tool.id : null;

    return <>{this.handleStatusCustomization(insightBody.id, toolID)}</>;
  };

  generateEvaluationLabel = () => {
    const { tool, intl } = this.props;
    let finalName = intl.formatMessage(globalMessages.status);
    const toolID = tool && tool.tool && tool.tool.id ? tool.tool.id : null;
    const isCustomized = checkCustomization(toolID);

    if (isCustomized) finalName = this.handleNameCustomization(toolID);

    return finalName;
  };

  getFiltersNames = (filterData, options) => {
    if (filterData && options) {
      return options.map((option) => {
        let text = "";

        if (filterData.includes(option.value)) {
          text = option.text;
        }

        return text;
      });
    }

    return [];
  };

  createrows = (list) => {
    const { connectionsDisplay } = this.state;
    const { selectAndOpenInsight, allGoalBonusAdministration, selectedTool } =
      this.props;
    const { tool } = selectedTool;

    if (list.length > 0) {
      return list.map((insightInfo, index) => {
        const isSelectedConnection =
          connectionsDisplay.indexOf(insightInfo.id) > -1;
        const insightConnections = this.getTraceabilitiesByInsightId(
          insightInfo.id,
        );

        const question = middlewareQuestionStructure(
          allGoalBonusAdministration,
          insightInfo.question,
          tool.id,
        );

        const { responsibleMember } = insightInfo;

        return (
          <React.Fragment key={index}>
            <tr
              className="parent-row"
              onClick={() =>
                this.handleToggleConnectionsDisplay(insightInfo.id)
              }
              style={
                connectionsDisplay.length > 0 && !isSelectedConnection
                  ? { opacity: 0.5 }
                  : {}
              }
            >
              <td
                align="left"
                style={
                  isSelectedConnection
                    ? {
                        borderLeft: "8px solid #6b42a9",
                      }
                    : {}
                }
              >
                {insightInfo.cenaryName}
              </td>
              <td align="left">{insightInfo.themeName}</td>
              <td align="left">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {tool.id === GOALS_AND_BONUS && (
                    <Avatar
                      name={question.questionName}
                      src={question.avatar}
                      style={{
                        width: "25px",
                        height: "25px",
                        marginRight: "5px",
                      }}
                    />
                  )}
                  <span>{insightInfo.questionName}</span>
                </div>
              </td>
              <td align="left">
                <div
                  className="select-insight"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => selectAndOpenInsight(insightInfo.id)}
                >
                  {tool.id !== GOALS_AND_BONUS && tool.id !== SWOT && (
                    <Avatar
                      name={responsibleMember?.fullName}
                      src={responsibleMember?.userData?.avatarBlobId}
                      style={{
                        width: "25px",
                        height: "25px",
                        marginRight: "5px",
                      }}
                    />
                  )}
                  <span>{insightInfo.text}</span>
                </div>
              </td>
              <td align="center">{insightConnections.length}</td>
              <td align="center">{insightInfo.points}</td>
              <td
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0px",
                }}
                className="scorePoints"
              >
                {this.displayStatusColumn(insightInfo)}
              </td>
            </tr>
            {insightConnections.length > 0 &&
              insightConnections.map((connectionInfo = {}, index) => {
                const cTraceabilityInsight = connectionInfo.insight || {};
                const cConnectionPoints =
                  cTraceabilityInsight.totalTraceabilitiesPoints;
                const isLastConnection =
                  insightConnections.length === index + 1;
                const cInsightInfo = connectionInfo.insight || {};
                const cInsightTraceabilitiesCount =
                  cInsightInfo.traceabilitiesCount || 0;
                const cQuestionInfo = cInsightInfo.question || {};
                const cThemeInfo = cQuestionInfo.theme || {};
                const cCenaryInfo = cThemeInfo.cenary || {};
                const cSelectedToolInfo = cCenaryInfo.selectedTool || {};
                const cSelectedToolDesc = getToolCategoryUpdatedInfo(
                  cSelectedToolInfo.tool,
                ).description;
                const cWorkspaceInfo = connectionInfo.workspace || {};
                const cToolId = cSelectedToolInfo.tool.id;

                const Arrow = () => (
                  <i
                    className="fas fa-chevron-right"
                    style={{ padding: "0px 10px", color: "#ccc" }}
                  />
                );

                const displayBreadcrumb = [];
                displayBreadcrumb.push(cWorkspaceInfo.name);
                displayBreadcrumb.push(<Arrow />);
                displayBreadcrumb.push(cSelectedToolDesc);
                displayBreadcrumb.push(<Arrow />);
                displayBreadcrumb.push(
                  getToolEntityText(cToolId, "cenary", cCenaryInfo.name),
                );
                displayBreadcrumb.push(<Arrow />);
                displayBreadcrumb.push(
                  getToolEntityText(cToolId, "question", cQuestionInfo.text),
                );

                const connectionLocationBreadcrumb = displayBreadcrumb.map(
                  (element, index) => (
                    <React.Fragment key={index}>{element}</React.Fragment>
                  ),
                );

                const breadPopover = () => (
                  <Popover
                    id="breadcrumbPopover"
                    style={{ maxWidth: "1000px" }}
                  >
                    {connectionLocationBreadcrumb}
                  </Popover>
                );

                return (
                  <tr
                    className="child-row"
                    style={isSelectedConnection ? {} : { display: "none" }}
                    key={index}
                  >
                    {/* START INSIGHT BREADCRUMB */}
                    <td
                      colSpan="3"
                      style={{
                        borderLeft: "4px solid #6bd33a",
                        borderBottomLeftRadius: isLastConnection
                          ? "4px"
                          : "0px",
                      }}
                    >
                      <OverlayTrigger
                        placement="bottom"
                        overlay={breadPopover()}
                      >
                        <div
                          style={{
                            maxWidth: "600px",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          <i
                            className="fas fa-route"
                            style={{ padding: "0 15px 0 0" }}
                          />
                          {connectionLocationBreadcrumb}
                        </div>
                      </OverlayTrigger>
                    </td>
                    {/* END INSIGHT BREADCRUMB */}
                    <td>{cInsightInfo.text}</td>
                    <td align="center">{cInsightTraceabilitiesCount}</td>
                    <td align="center">
                      {cInsightInfo.score - cConnectionPoints}
                    </td>
                    <td align="center">...</td>
                  </tr>
                );
              })}
          </React.Fragment>
        );
      });
    }
    return (
      <tr>
        <td colSpan="10">
          <h4 align="center">
            {this.props.intl.formatMessage({ id: "global.noDataTable" })}
          </h4>
        </td>
      </tr>
    );
  };

  render() {
    const {
      intl,
      showHiddenCenarys,
      showHiddenThemes,
      showHiddenQuestions,
      showHiddenAnswers,
      allSceneries,
      allThemes,
      allQuestions,
      allAnswers,
      filterData,
      captureSelectsData,
      allKpiAdministration,
      allActionsPlans,
    } = this.props;
    const showHidden = {
      showHiddenCenarys,
      showHiddenThemes,
      showHiddenQuestions,
      showHiddenAnswers,
    };

    const selectedToolId = this.props.selectedTool?.id;
    const tool_type_id = this.props.selectedTool?.tool?.id;
    const customConfig = {
      isOkr: tool_type_id === OKR_ID,
    };

    const { hasCustomExcelExport, exportFunction } =
      checkHasCustomExcelExport(tool_type_id);

    const selectList = getSelectsList(
      allSceneries,
      allThemes,
      filterData,
      showHidden,
      customConfig,
      allAnswers,
      allQuestions,
    );
    const list = this.dataSort();

    return (
      <div className="box-body">
        <SelectFilter
          selectList={selectList}
          captureSelectsData={captureSelectsData}
          showSubthemeSelect
        />

        <div style={{ position: "relative", zIndex: 10000, margin: "20px 0" }}>
          <ButtonGeneratePdf />
          {hasCustomExcelExport && (
            <ButtonExportExcel
              doExport={() =>
                exportFunction({
                  list,
                  handleStatusCustomization: this.handleStatusCustomization,
                  getTraceabilitiesByInsightId:
                    this.getTraceabilitiesByInsightId,
                  allKpiAdministration,
                  allActionsPlans,
                  selectedToolId,
                  companyMembersWithProfile:
                    this.props.companyMembersWithProfile,
                })
              }
            />
          )}
        </div>
        <HeaderPdf>
          <img
            src={logoRokau}
            alt="Rookau"
            style={{ width: "200px", marginBottom: "10px" }}
          />
          <div>
            <span style={{ fontWeight: "500" }}>Workspace</span>
            {this.props.selectedWorkspace &&
              ` ${this.props.selectedWorkspace.name}`}
          </div>
          <div>
            <span style={{ fontWeight: "500" }}>Exibindo cenários</span>
            {this.getFiltersNames(
              filterData.sceneries,
              selectList.sceneries,
            ).map((cenary) => (
              <span key={`${cenary}-${uuidv4()}`}> {`${cenary}","`}</span>
            ))}
          </div>
          <div>
            <span style={{ fontWeight: "500" }}>Exibindo temas</span>
            {this.getFiltersNames(filterData.themes, selectList.themes).map(
              (theme) => {
                return (
                  <span key={`${theme}-${uuidv4()}`}> {`${theme}","`}</span>
                );
              },
            )}
          </div>
        </HeaderPdf>
        <div className="col-xs-12 react-bs-container-header table-header-wrapper table-score table-responsive">
          <table
            className="table table-bordered table-hover table-dropdown"
            style={{ borderCollapse: "separate", marginTop: "20px" }}
          >
            <thead>
              <tr>
                {this.renderCustomizedColumnLabel()}
                <th style={{ textAlign: "center" }}>
                  <i className="fas fa-route" />
                  {"  "}
                  {intl.formatMessage(globalMessages.traceabilityConnections)}
                </th>
                <th style={{ textAlign: "center" }}>
                  {intl.formatMessage(globalMessages.insightPoints)}
                </th>
                <th
                  className="th-ordenation"
                  onClick={() => this.handleDataSort("evaluation")}
                >
                  {this.generateEvaluationLabel()}
                  {this.renderOrdinationButton("evaluation")}
                </th>
              </tr>
            </thead>
            <tbody>{this.createrows(list)}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { toolsConfigurations } = state;

  const { showHiddenAnswers: fetchFiledOption = false } = toolsConfigurations;

  const { allSwotEvaluation } = getSwotStates(state);
  const { allMaturityData } = getBigProcessesStates(state);
  const { allPeopleEvaluation } = getBigPeopleStates(state);
  const { allProjectEvaluation } = getProjectStates(state);
  const { allInsightGoals } = insightsGoalState(state);
  const { allKpiAdministration } = getKpiStates(state);
  const { allSkillAssessment } = getSkillAssessmentStates(state);
  const { allGoalBonusAdministration } = getGoalsBonusStates(state);
  const { companyData } = getSelectedCompanyStates(state);
  const { selectedTool } = getSelectedToolStates(state);
  const { boardPoints = [] } = getCompAnalysisStates(state);
  const { allActionsPlans = [] } = getActionsPlanStates(state);
  const { allCompanyHierarchy = {} } = getUserProfileStates(state);

  const { companyMembers } = getCompanyMembersAndParticipants(state);

  const companyMembersWithProfile = companyMembers.map((companyMember) => {
    const { profile = {} } =
      allCompanyHierarchy?.find(
        ({ member }) => companyMember.id === member?.id,
      ) || {};

    return {
      ...companyMember,
      profile,
    };
  });

  return {
    selectedWorkspace: state.workspacesData.selectedWorkspace,
    fetchFiledOption,
    toolPoints: state.toolPoints,
    workspace: state.workspacesData.selectedWorkspace,
    allSwotEvaluation,
    allMaturityData,
    allPeopleEvaluation,
    allProjectEvaluation,
    allKpiAdministration,
    allGoalBonusAdministration,
    allSkillAssessment,
    allInsightGoals,
    insightTraceabilities: state.insightTraceabilities
      ? state.insightTraceabilities
      : [],
    selectedCompany:
      companyData && companyData.selectedCompany
        ? companyData.selectedCompany
        : {},
    selectedTool,
    boardPoints,
    allActionsPlans,
    showHiddenExpectation: toolsConfigurations.showHiddenExpectation,
    companyMembersWithProfile,
  };
}

const ToolScoreView = (props) => {
  if (props.open) {
    return <ScoreView {...props} />;
  }
  return null;
};

export default injectIntl(
  connect(mapStateToProps, {
    getTraceabilitiesList,
    fetchCompetitiveAnalysisPoints,
  })(ToolScoreView),
);
