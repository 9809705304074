import moment from "moment";
import { defineMessages } from "react-intl";
import {
  checkEmailSender,
  checkParamValidations,
  getNotificationReceivers,
} from "../../utils/notificationUtils";
import {
  INSIGHT_RESPONSIBLE_CHANGE,
  NEW_INSIGHT_COMMENT,
  INSIGHT_PRIORITIZATION_CHANGE,
  INSIGHT_CONNECTION_CHANGE,
  INSIGHT_ATTACHMENT_CHANGE,
  COMPANY_NEW_ADMIN,
  INSIGHT_NEW_MENTION,
  COMPANY_NEW_WORKSPACE,
  WORKSPACE_NEW_MODERATOR,
  WORKSPACE_NEW_TOOL,
  TOOL_NEW_MANAGER,
  TOOL_NEW_VIEWER,
  SCENERY_NEW_LEADER,
  SCENERY_NEW_VIEWER,
} from "./types";
import { getCurrentUserInfo, getCustomUrlLink } from "../../utils/accessLevels";
import { createNotification } from "./actions";
import { postGenericEmail } from "../emailActions";
import { notificationTemplate } from "./notificationTemplate";
import { translatedText } from "../../utils/translationUtils";
import { getUserEmailStatus } from "../userActions";

const messages = defineMessages({
  rookau_activity: { id: "global.new_activity_rookau" },
});

const translation = (id) => translatedText(id, messages);

export const sendNotificationEmail = (
  emailDescription = "",
  emails = [],
  entityType = false,
) => {
  getCustomUrlLink(entityType).then((urlLink) => {
    const htmlBody = notificationTemplate(emailDescription, urlLink);

    postGenericEmail(translation("rookau_activity"), htmlBody, emails);
  });
};

export const sendInsightMentionNotification = (
  insightId = "",
  mentionedUserId = "",
) => {
  return async (dispatch) => {
    const sendEmailStatus = await getUserEmailStatus(mentionedUserId);

    const { id: loggedUser } = getCurrentUserInfo();

    const notificationBody = {
      entityId: insightId,
      entityType: "INSIGHT",
      sendEmail: sendEmailStatus,
      whoReceives: [mentionedUserId],
      senderId: loggedUser,
      description: INSIGHT_NEW_MENTION,
    };

    dispatch(registerNotifications([notificationBody]));
  };
};

export const sendNewAdminNotification = (companyId = "", newAdminId = "") => {
  const { id: loggedUser } = getCurrentUserInfo();

  const notificationBody = {
    entityId: companyId,
    entityType: "COMPANY",
    sendEmail: false,
    whoReceives: [newAdminId],
    senderId: loggedUser,
    description: COMPANY_NEW_ADMIN,
  };

  return (dispatch) => dispatch(registerNotifications([notificationBody]));
};

export const sendNewWorkspaceNotification = (companyId = "") => {
  const { id: loggedUser } = getCurrentUserInfo();

  const notificationBody = {
    entityId: companyId,
    entityType: "COMPANY",
    sendEmail: false,
    whoReceives: ["OWNER", "ADMIN"],
    senderId: loggedUser,
    description: COMPANY_NEW_WORKSPACE,
  };

  return (dispatch) => dispatch(registerNotifications([notificationBody]));
};

export const sendNewModeratorNotification = (
  workspaceId = "",
  newModeratorId = "",
) => {
  const { id: loggedUser } = getCurrentUserInfo();

  const notificationBody = {
    entityId: workspaceId,
    entityType: "WORKSPACE",
    sendEmail: false,
    whoReceives: [newModeratorId],
    senderId: loggedUser,
    description: WORKSPACE_NEW_MODERATOR,
  };

  return (dispatch) => dispatch(registerNotifications([notificationBody]));
};

export const sendNewToolNotification = (workspaceId = "") => {
  const { id: loggedUser } = getCurrentUserInfo();

  const notificationBody = {
    entityId: workspaceId,
    entityType: "WORKSPACE",
    sendEmail: false,
    whoReceives: ["OWNER", "ADMIN", "MODERATOR"],
    senderId: loggedUser,
    description: WORKSPACE_NEW_TOOL,
  };

  return (dispatch) => dispatch(registerNotifications([notificationBody]));
};

export const sendNewManagerNotification = (
  selectedToolId = "",
  newManagerId = "",
) => {
  const { id: loggedUser } = getCurrentUserInfo();

  const notificationBody = {
    entityId: selectedToolId,
    entityType: "SELECTED_TOOL",
    sendEmail: false,
    whoReceives: [newManagerId],
    senderId: loggedUser,
    description: TOOL_NEW_MANAGER,
  };

  return (dispatch) => dispatch(registerNotifications([notificationBody]));
};

export const sendNewToolViewerNotification = (
  selectedToolId = "",
  newViewerId = "",
) => {
  const { id: loggedUser } = getCurrentUserInfo();

  const notificationBody = {
    entityId: selectedToolId,
    entityType: "SELECTED_TOOL",
    sendEmail: false,
    whoReceives: [newViewerId],
    senderId: loggedUser,
    description: TOOL_NEW_VIEWER,
  };

  return (dispatch) => dispatch(registerNotifications([notificationBody]));
};

export const sendNewLeaderNotification = (sceneryId = "", newLeaderId = "") => {
  const { id: loggedUser } = getCurrentUserInfo();

  const notificationBody = {
    entityId: sceneryId,
    entityType: "CENARY",
    sendEmail: false,
    whoReceives: [newLeaderId],
    senderId: loggedUser,
    description: SCENERY_NEW_LEADER,
  };

  return (dispatch) => dispatch(registerNotifications([notificationBody]));
};

export const sendNewSceneryViewerNotification = (
  sceneryId = "",
  newViewerId = "",
) => {
  const { id: loggedUser } = getCurrentUserInfo();

  const notificationBody = {
    entityId: sceneryId,
    entityType: "CENARY",
    sendEmail: false,
    whoReceives: [newViewerId],
    senderId: loggedUser,
    description: SCENERY_NEW_VIEWER,
  };

  return (dispatch) => dispatch(registerNotifications([notificationBody]));
};

export const sendResponsibleNotification = (insightId = "") => {
  const { id: loggedUser } = getCurrentUserInfo();

  const notificationBody = {
    entityId: insightId,
    entityType: "INSIGHT",
    sendEmail: false,
    whoReceives: ["CREATOR", "RESPONSIBLE", "COLLABORATOR"],
    senderId: loggedUser,
    description: INSIGHT_RESPONSIBLE_CHANGE,
  };

  return (dispatch) => dispatch(registerNotifications([notificationBody]));
};

export const sendCommentNotification = (insightId = "") => {
  const { id: loggedUser = false } = getCurrentUserInfo();

  const notificationBody = {
    entityId: insightId,
    entityType: "INSIGHT",
    sendEmail: false,
    whoReceives: ["CREATOR", "RESPONSIBLE", "COLLABORATOR"],
    senderId: loggedUser,
    description: NEW_INSIGHT_COMMENT,
  };

  return (dispatch) => dispatch(registerNotifications([notificationBody]));
};

export const sendPrioritizationNotification = (insightId = "") => {
  const { id: loggedUser } = getCurrentUserInfo();

  const notificationBody = {
    entityId: insightId,
    entityType: "INSIGHT",
    sendEmail: false,
    whoReceives: ["CREATOR", "RESPONSIBLE", "COLLABORATOR"],
    senderId: loggedUser,
    description: INSIGHT_PRIORITIZATION_CHANGE,
  };

  return (dispatch) => dispatch(registerNotifications([notificationBody]));
};

export const sendConnectionNotification = (insightId = "") => {
  const { id: loggedUser } = getCurrentUserInfo();

  const notificationBody = {
    entityId: insightId,
    entityType: "INSIGHT",
    sendEmail: false,
    whoReceives: ["CREATOR", "RESPONSIBLE", "COLLABORATOR"],
    senderId: loggedUser,
    description: INSIGHT_CONNECTION_CHANGE,
  };

  return (dispatch) => dispatch(registerNotifications([notificationBody]));
};

export const sendAttachmentNotification = (insightId = "") => {
  const { id: loggedUser } = getCurrentUserInfo();

  const notificationBody = {
    entityId: insightId,
    entityType: "INSIGHT",
    sendEmail: false,
    whoReceives: ["CREATOR", "RESPONSIBLE", "COLLABORATOR"],
    senderId: loggedUser,
    description: INSIGHT_ATTACHMENT_CHANGE,
  };

  return (dispatch) => dispatch(registerNotifications([notificationBody]));
};

export const sendRegistration = async (register = {}, dispatch) => {
  const { sendEmail = false } = register || {};

  // Valida se todos os parametros estão OK
  const paramsValidation = await checkParamValidations(register);

  if (!paramsValidation) return;

  // Busca usuários que devem receber a notificação retornando os ids
  const notificationReceivers = getNotificationReceivers(register);

  const membersIds = [...notificationReceivers.map((info) => info.id)];
  const receiversEmails = [...notificationReceivers.map((info) => info.email)];

  if (!notificationReceivers || membersIds.length === 0) return;

  const { entityId, entityType, senderId, description } = register || {};

  // Checa parametros de email, se necessário retorna body de email
  const emailDescription = await checkEmailSender(register);

  if (emailDescription && sendEmail)
    dispatch(
      sendNotificationEmail(emailDescription, receiversEmails, entityType),
    );

  return {
    membersIds,
    notification: {
      entityId,
      senderId,
      entityType,
      description,
      type: "INFO",
      sendDate: moment().format("YYYY-MM-DD"),
    },
  };
};

const validateBodys = (bodys = []) => {
  return bodys.filter(({ senderId = false }) => {
    if (!senderId || senderId === null) return false;

    return true;
  });
};

export const registerNotifications = async (registerBodys = []) => {
  const bodys = validateBodys(registerBodys);

  return async (dispatch) => {
    Promise.all(
      bodys.map(async (register) => {
        return await sendRegistration(register, dispatch);
      }),
    ).then((values) => dispatch(createNotification(values)));
  };
};
