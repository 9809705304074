import { getTableHeadValues } from "../constants/insightMaturity";

export const renderProcessBPColumnLabel = (intl, globalMessages) => {
  return (
    <>
      <th style={{ textAlign: "left" }}>
        {intl.formatMessage(globalMessages.area)}
      </th>
      <th style={{ textAlign: "left" }}>
        {intl.formatMessage(globalMessages.macroProcess)}
      </th>
      <th style={{ textAlign: "left" }}>
        {intl.formatMessage(globalMessages.process)}
      </th>
      <th style={{ textAlign: "left" }}>
        {intl.formatMessage(globalMessages.activity)}
      </th>
    </>
  );
};

export const buildMaturityBody = (values) => {
  let maturityBody = {
    activityDeliveryDeadline: "",
    automationLevel: "",
    knowledgeLevel: "",
    levelOfManagementAndControl: "",
    qualityLevel: "",
  };

  if (values) {
    Object.keys(values).forEach((valueKey) => {
      Object.keys(maturityBody).forEach((bodyKey) => {
        if (valueKey === bodyKey) {
          maturityBody[bodyKey] = values[valueKey];
        }
      });
    });
  }

  return maturityBody;
};

export const getAverageEvaluation = (maturityInfo, filterOnly = null) => {
  let sum = 0;
  let totalAppliable = 0;
  let final = 0;
  const maturityTableValues = getTableHeadValues();
  const matEvaluationBody = buildMaturityBody(maturityInfo);

  Object.keys(matEvaluationBody).forEach((key) => {
    if (!filterOnly || key === filterOnly) {
      if (matEvaluationBody[key] && matEvaluationBody[key] !== "NOT_APPLICABLE")
        totalAppliable++;

      maturityTableValues.forEach(({ value, valueNumber }) => {
        if (matEvaluationBody[key] === value)
          sum = parseFloat(sum) + parseFloat(valueNumber);
      });
    }
  });

  final = parseFloat(sum / totalAppliable).toFixed(1);
  final = final >= 0 ? final : 0;

  return parseFloat(final);
};

export const getSumEvaluation = (maturityInfo, filterOnly = null) => {
  let sum = 0;
  const maturityTableValues = getTableHeadValues();
  const matEvaluationBody = buildMaturityBody(maturityInfo);

  Object.keys(matEvaluationBody).forEach((key) => {
    if (!filterOnly || key === filterOnly) {
      maturityTableValues.forEach(({ value, valueNumber }) => {
        if (matEvaluationBody[key] === value)
          sum = parseFloat(sum) + parseFloat(valueNumber);
      });
    }
  });

  return sum;
};

export const getFilteredArrayEvaluation = (maturityLIST, filterSlug = null) => {
  let sum = 0;
  let totalAppliable = 0;
  const maturityTableValues = getTableHeadValues();

  maturityLIST.forEach((maturityInfo) => {
    const maturityEvaluation = buildMaturityBody(maturityInfo);
    if (
      filterSlug &&
      maturityEvaluation &&
      maturityEvaluation[filterSlug] &&
      maturityEvaluation[filterSlug] !== "NOT_APPLICABLE"
    ) {
      totalAppliable++;

      maturityTableValues.forEach(({ value, valueNumber }) => {
        if (value === maturityEvaluation[filterSlug]) sum += valueNumber;
      });
    }
  });

  return {
    average: sum / totalAppliable,
    totalAppliable,
  };
};

export const getMaturityArrayEvaluation = (maturityLIST) => {
  let listSum = 0;
  let totalAppliable = 0;
  if (maturityLIST.length > 0) {
    maturityLIST.forEach((maturityInfo) => {
      const dataAverage = getAverageEvaluation(maturityInfo);

      if (dataAverage > 0) {
        listSum = listSum + dataAverage;
        totalAppliable++;
      }
    });
  }

  let listAverage = (parseFloat(listSum) / totalAppliable).toFixed(1);
  listAverage = listAverage > 0 ? listAverage : 0;

  return listAverage;
};

export const getMaturityArraySum = (maturityLIST) => {
  let listSum = 0;
  if (maturityLIST.length > 0) {
    maturityLIST.forEach((maturityInfo) => {
      const dataAverage = getSumEvaluation(maturityInfo);

      if (dataAverage > 0) {
        listSum = listSum + dataAverage;
      }
    });
  }

  return listSum;
};

export const getEvaluationLabel = (evaluation) => {
  let evaluationLabel = "NOT_APPLICABLE";
  evaluation = parseFloat(evaluation);

  if (evaluation > 0) evaluationLabel = "VERY_LOW";
  if (evaluation > 1.5) evaluationLabel = "LOW";
  if (evaluation > 2.5) evaluationLabel = "PARTIAL";
  if (evaluation > 3.5) evaluationLabel = "HIGH";
  if (evaluation > 4.5) evaluationLabel = "VERY_HIGH";

  return evaluationLabel;
};

export const getInsightMaturityById = (insightID, allMaturityData) => {
  let insightMaturity = {};

  if (allMaturityData && allMaturityData.length > 0) {
    allMaturityData.forEach((maturityInfo) => {
      if (maturityInfo.insightId === insightID)
        insightMaturity = maturityInfo.maturityEvaluation;
    });
  }

  return insightMaturity;
};

export const getInsightInformationById = (
  insightID,
  allActivityInformation
) => {
  let activityInformation = {};

  if (allActivityInformation && allActivityInformation.length > 0) {
    allActivityInformation.forEach((activityInfo) => {
      if (activityInfo.insightId === insightID)
        activityInformation = activityInfo.activityInformation;
    });
  }

  return activityInformation;
};

export const getMaturityAverageList = (insightsList, allMaturityData) => {
  let allPoints = "";
  insightsList.forEach(({ id }) => {
    const maturityEvaluation = getInsightMaturityById(id, allMaturityData);
    const questionAverage = getAverageEvaluation(maturityEvaluation);
    allPoints = [...allPoints, { insightId: id, queAverage: questionAverage }];
  });

  return allPoints;
};

export const getOnlyMaturityEvaluation = (allMaturityData = []) => {
  let finalOnlyMaturity = [];

  if (allMaturityData && allMaturityData.length > 0) {
    allMaturityData.forEach(({ maturityEvaluation }) =>
      finalOnlyMaturity.push(maturityEvaluation)
    );
  }

  return finalOnlyMaturity;
};

export const finalMaturityAverage = (maturityArray, doNotReplaceBody) => {
  let listSum = 0;
  if (maturityArray.length > 0) {
    maturityArray.forEach((maturityData) => {
      const body = !doNotReplaceBody
        ? buildMaturityBody(maturityData)
        : maturityData;
      listSum = parseFloat(listSum) + parseFloat(getAverageEvaluation(body));
    });
  }

  let listAverage = (parseFloat(listSum) / maturityArray.length).toFixed(1);
  listAverage = listAverage > 0 ? listAverage : 0;

  return listAverage;
};

export function mountInsightWithAvatar(
  avatars,
  insights,
  setState,
  permission
) {
  const newinsights = insights.map((insight) => {
    if (insight && insight.responsibleMember) {
      const { userData } = insight.responsibleMember;
      let newInsight = {};
      if (avatars) {
        avatars.forEach((avatar) => {
          if (userData.avatarBlobId === avatar.avatarBlobId && permission) {
            newInsight = {
              ...insight,
              avatar: "data:image/png;base64," + avatar.base64,
            };
          } else {
            newInsight = { ...insight, avatar: "" };
          }
        });
      }
      return newInsight;
    } else {
      return insight;
    }
  });

  setState(newinsights);
}

export async function getInsightAvatar(insights, setState, permission = true) {
  if (insights) {
    let blobInfo = [];

    const insightAvatars = insights.map((insight) => {
      if (insight && insight.responsibleMember) {
        const { responsibleMember = {} } = insight || {};
        const { userData, id: userId } = responsibleMember;
        const { avatarBlobId = null } = userData || {};

        if (userData.avatarBlobId) {
          blobInfo.push({
            userId,
            avatarBlobId,
          });

          return userData.avatarBlobId;
        }
      }
    });

    mountInsightWithAvatar(insightAvatars, insights, setState, permission);
  }
}
