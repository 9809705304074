import update from "immutability-helper";
import {
  FETCH_CENARIES,
  FETCH_CENARY_MEMBERS,
  UPDATE_CENARY,
  CLEAR_CENARIES,
  SWAP_ORDER,
  FETCH_CENARY_PARTICIPANTS,
  DELETE_CENARIE,
} from "../actions/types";
import { ENTITY_TYPE } from "../actions/orderedEntityActions";
import { getObjectInfoById } from "../utils/ArrayUtils";

const INITIAL_STATE = {
  cenariesReport: [],
  cenaries: [],
  cenaryMembers: [],
  cenaryParticipants: [],
};

function removeCenaryFromArray(stateCenaries, removeCenaryId) {
  return stateCenaries.filter(
    (scenaryInfo) => scenaryInfo.id !== removeCenaryId,
  );
}

const cenaryReducer = (state = INITIAL_STATE, action) => {
  const cenary = action.payload;
  switch (action.type) {
    case CLEAR_CENARIES:
      return INITIAL_STATE;
    case "FETCH_REPORT_OKR_FOLLOWUP_CENARIES":
      return { ...state, cenariesReport: action.payload };
    case FETCH_CENARIES:
      return {
        ...state,
        cenaries: action.payload,
      };
    case FETCH_CENARY_MEMBERS:
      return {
        ...state,
        cenaryMembers: action.payload,
      };
    case FETCH_CENARY_PARTICIPANTS:
      return {
        ...state,
        cenaryParticipants: action.payload,
      };
    case UPDATE_CENARY:
      if (cenary && cenary.id) {
        const cenaryIndex = getObjectInfoById(
          cenary.id,
          state.cenaries,
          "id",
          true,
        );

        if (cenaryIndex > -1) {
          const updatedCenaries = [...state.cenaries];
          updatedCenaries[cenaryIndex] = cenary;

          return {
            ...state,
            cenaries: updatedCenaries,
          };
        }

        return {
          ...state,
          cenaries: [...state.cenaries, cenary],
        };
      }

      return state;
    case DELETE_CENARIE:
      if (action.payload && action.payload.cenaryId) {
        return {
          ...state,
          cenaries: removeCenaryFromArray(
            state.cenaries,
            action.payload.cenaryId,
          ),
        };
      }

      return state;
    case SWAP_ORDER:
      if (action.payload.entityType === ENTITY_TYPE.cenary) {
        return update(state, {
          cenaries: { $set: action.payload.list },
        });
      }

      return state;
    case "RESET_CENARIES":
      return {
        ...state,
        cenaries: [],
      };
    default:
      return state;
  }
};

export default cenaryReducer;
