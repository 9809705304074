import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import FormDialog from "../../FormDialog";
import MaterialSingleSelect from "../../MaterialSingleSelect";
import { getCpAnalysisToolSlugs } from "../../../../constants/competitiveAnalysis";
import {
  getCompetitiveAnalysisTranslations,
  getCustomTranslation,
} from "../../../../utils/cpAnalysis/display";

const messages = defineMessages({
  kpi_form_select_scenario: { id: "kpi_form_select_scenario" },
  kpi_add_new_scenario: { id: "kpi_add_new_scenario" },
});

const initialForm = {
  entityId: "",
  entityName: "",
};

const ScenerieBoardSelection = (props) => {
  const [form, setForm] = useState(initialForm);

  const {
    open = false,
    afterCancel,
    afterConfirm,
    cpAdministration,
    allSceneries,
    intl,
  } = props;

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleConfirm = () => {
    if (typeof afterConfirm === "function") afterConfirm(form);
  };

  const handleCancel = () => {
    if (typeof afterCancel === "function") afterCancel();
  };

  const handleUpdateForm = (entityId, scenerieOptions) => {
    const entityName = scenerieOptions.filter(
      (option) => option.value === entityId,
    )[0].type;

    setForm({ ...form, entityId, entityName });
  };

  const buildScenerieOptions = () => {
    const addedSceneries = allSceneries.map(({ name }) => {
      return name.toUpperCase();
    });

    const types = getCpAnalysisToolSlugs();

    let finalList = [];

    types.forEach((slug) => {
      const upperSlug = slug.toUpperCase();

      if (addedSceneries.indexOf(upperSlug) > -1) return;

      const { id = "", type = "" } = cpAdministration[slug] || {};

      const cpTranslations = getCompetitiveAnalysisTranslations();

      const translatedTitle = getCustomTranslation(cpTranslations, type);

      finalList = [
        ...finalList,
        {
          value: id,
          label: translatedTitle,
          type,
        },
      ];
    });

    return finalList;
  };

  const scenerieOptions = buildScenerieOptions();

  return (
    <>
      <FormDialog
        open={open}
        title={intl.formatMessage(messages.kpi_add_new_scenario)}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        blockConfirm={buildScenerieOptions().length === 0}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialSingleSelect
              id="scenerieSelect"
              label={intl.formatMessage(messages.kpi_form_select_scenario)}
              value={form.entityId || ""}
              options={scenerieOptions}
              onChange={(e) =>
                handleUpdateForm(e.target.value, scenerieOptions)
              }
            />
          </div>
        </div>
      </FormDialog>
    </>
  );
};

export default injectIntl(ScenerieBoardSelection);
