const pt = {
  text_register_participant: "Cadastrar novo participante",
  text_register_team: "Cadastrar novo time",
  text_info_access_link:
    "O link de acesso será enviado no e-mail do participante",
  text_register_member: "Cadastre um membro",
};

const en = {
  text_register_participant: "Register new participant",
  text_register_team: "Register new team",
  text_info_access_link:
    "The access link will be sent to the participant email",
  text_register_member: "Register a member",
};

const es = {
  text_register_participant: "Registrar nuevo participante",
  text_register_team: "Registrar nuevo equipo",
  text_info_access_link:
    "Se enviará un enlace de acceso al correo electrónico del participante",
  text_register_member: "Registrar un miembro",
};

const translations = { pt, en, es };

export default translations;
