import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Tab } from "@material-ui/core";

const MaterialCustomTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    color: "#666",
    fontSize: "16px",
    fontWeight: "bold",
    border: "1px solid #eee",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    display: ({ hidetab }) => (hidetab === "true" ? "none" : "block"),
    "&:focus": {
      opacity: 1
    },
    "&.Mui-selected": {
      backgroundColor: "#f9f9f9",
      fontWeight: "bold"
    },
    fontFamily: "Montserrat"
  }
}))(props => {
  const disableRipple = props.disableRipple === false ? false : true;

  return <Tab disableRipple={disableRipple} {...props} />;
});

export default MaterialCustomTab;
