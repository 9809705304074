const en = {
  pb_professional_profile: "Professional Profile",
  pb_salary_history: "Salary history",
  pb_evaluation_targeting: "Evaluation and Targeting",
  pb_form_full_name: "Full Name",
  pb_form_birth_date: "Birth Date",
  pb_form_gender: "Gender",
  pb_form_entry_date: "Company Entry Date",
  pb_form_area: "Area",
  pb_form_no_area: "Area not configured",
  pb_form_area_details: "Area Details",
  pb_form_role: "Position",
  pb_form_no_role: "Position not configured",
  pb_form_role_details: "Position Details",
  pb_form_education: "Education",
  pb_form_education_details: "Education Details",
  pb_form_fluent_languages: "Fluent Languages",
  pb_form_select_member: "Select member",
  pb_form_change_member: "Change member",
  pb_change_career_history: "Click to change the career history",
  pb_select_member_participant: "Select a member or participant",
  pb_career_trajectory: "Career Trajectory",
  pb_professional_history: "Professional History",
  pb_start_date: "Start date",
  pb_end_date: "End date",
  pb_current_position: "Current position",
  pb_first_position: "Starting position",
  pb_position: "Position",
  pb_professional_leader: "Professional Leader",
  pb_table_edit: "Edit",
  pb_table_remove: "Remove",
  pb_table_reason: "Reason",
  pb_table_modification_date: "Modification date",
  pb_table_adjusted_value: "Adjusted value",
  pb_table_adjusted_percent: "Adjusted %",
  pb_leader_not_selected: "Not selected",
  pb_no_recorded_history: "No recorded history",
  pb_confirm_remove_history: "Are you sure you want to delete this history?",
  pb_description_update_current_history:
    "The current Position and Area of ​​this professional will also be updated",
  pb_dialog_change_salary_history: "Change salary history",
  pb_dialog_add_salary_history: "Add salary history",
  pb_dialog_change_professional_history: "Change professional history",
  pb_dialog_first_professional_history:
    "Set the current position of this professional",
  pb_dialog_add_professional_history: "Add professional history",
  pb_dialog_change_professional_leader: "Change Professional Leader",
  pb_dialog_select_professional_leader: "Select Professional Leader",
  pb_dialog_config_area: "Set up area of activity",
  pb_dialog_min_date_alert: "Start date must be greater than",
  pb_dialog_equal_date: "Start date and end date cannot be the same",
  pb_dialog_date_conflict: "Professional History Conflict",
  pb_dialog_start_date_greater: "Start date can not be greater than end date",
  pb_dialog_from_role: "Position",
  pb_dialog_initial_role: "Initial position",
  pb_dialog_from_area: "Area",
  pb_dialog_initial_area: "Initial area",
  pb_form_gross_remuneration: "Gross remuneration",
  pb_form_market_evaluation: "Market evaluation",
  pb_form_average_market_evaluation: "Average market evaluation",
  pb_add_new: "Add new",
  pb_click_to_edit: "Click to edit",
  pb_table_last_evaluations: "Last evaluations",
  pb_table_evaluation: "Evaluation",
  pb_table_current: "Current",
  pb_table_previous: "Previous",
  pb_table_details: "Details",
  pb_table_career_map: "Career map",
  pb_table_career: "Career",
  pb_table_readiness: "Readiness",
  pb_table_path: "Path",
  pb_salary_evaluation: "Salary evaluation",
  pb_role_evaluation: "Position evaluation",
  pb_area_financial: "Financial",
  pb_area_administrative: "Administrative",
  pb_area_human_resources: "Human Resourses",
  pb_area_information_technology: "Information Technology",
  pb_area_planning: "Planning",
  pb_area_marketing: "Marketing",
  pb_area_commercial: "Commercial",
  pb_area_support: "Support",
  pb_area_operational: "Operational",
  pb_area_production: "Production",
  pb_area_services: "Services",
  pb_gender_male: "Male",
  pb_gender_female: "Female",
  pb_role_trainee: "Trainee",
  pb_role_assistant: "Assistant",
  pb_role_analyst: "Analyst",
  pb_role_technician: "Technician",
  pb_role_coordination: "Coordination",
  pb_role_management: "Management",
  pb_role_board_of_directors: "Board of Directors",
  pb_role_executive: "Executive",
  pb_market_evaluation_below: "Below",
  pb_market_evaluation_according: "According",
  pb_market_evaluation_above: "Above",
  pb_salary_up_3k: "Up 3k",
  pb_salary_up_4k: "Up 4k",
  pb_salary_up_5k: "Up 5k",
  pb_salary_up_10k: "Up 10k",
  pb_salary_more_10k: "More than 10k",
  pb_education_elementary: "Elementary",
  pb_education_technical: "Technical",
  pb_education_graduation: "Graduation",
  pb_education_postgraduate: "Postgraduate",
  pb_education_masters: "Masters",
  pb_education_doctorate: "Doctorate",
  pb_languages_portuguese: "Portuguese",
  pb_languages_english: "English",
  pb_languages_spanish: "Spanish",
  pb_languages_others: "Others",
  pb_salary_one_year: "1 year",
  pb_salary_two_years: "2 years",
  pb_salary_three_years: "3 years",
  pb_salary_more_three_years: "> 3 years",
  pb_performance_above: "Above",
  pb_performance_according: "According",
  pb_performance_below: "Below",
  pb_leading_1: "9 - High potential",
  pb_leading_2: "8 - High performance",
  pb_leading_3: "6 - Good potential",
  pb_leading_4: "7 - Medium performance",
  pb_leading_5: "5 - Growing",
  pb_leading_6: "3 - Effective",
  pb_leading_7: "4 - New",
  pb_leading_8: "2 - Low performance",
  pb_leading_9: "1 - Output position",
  pb_interest_change_area_project: "Change area or project",
  pb_interest_short_change_area_project: "Change",
  pb_interest_develop_more_in_area: "Develop more in area",
  pb_interest_short_develop_more_in_area: "Develop",
  pb_interest_expand_in_area: "Expand in area",
  pb_interest_short_expand_in_area: "Grow",
  pb_readiness_ready: "Ready",
  pb_readiness_short_term_ready: "Short term ready",
  pb_readiness_medium_term_ready: "Medium term ready",
  pb_readiness_develop: "Develop",
  pb_readiness_keep: "Keep",
  pb_readiness_reassess: "Reassess",
  pb_career_trail: "Trail",
  pb_career_job: "Job",
  pb_evaluations_immediate: "Immediate",
  pb_evaluations_short_term: "Short term",
  pb_evaluations_long_term: "Long term",
  pb_evaluations_keep: "Keep",
  pb_table_lb_evaluations_average: "Performance score",
  pb_table_lb_evaluations_performance: "Performance status",
  pb_table_lb_evaluations_leading: "Leading",
  pb_table_lb_evaluations_interest: "Interest",
  pb_career_main: "Main career",
  pb_career_option: "Career option",
  pb_titles_knowledge: "Knowledge",
  pb_titles_development: "Developtment",
  pb_titles_potential: "Potential",
  pb_titles_tabs_people_map: "Career",
  pb_titles_tabs_career_map: "Career Map",
  pb_titles_tabs_leadership_map: "Leadership Map",
  pb_titles_cards_profile: "PROFILE",
  pb_titles_cards_career: "CAREER",
  pb_titles_cards_interests: "INTERESTS",
  pb_titles_cards_performance: "PERFORMANCE",
  pb_titles_cards_leading: "LEADING",
  pb_titles_cards_evaluation_result: "RESULT OF THE EVALUATION",
  pb_titles_cards_wage_recognition: "WAGE RECOGNITION",
  pb_titles_cards_ascension: "ASCENSION",
  pb_titles_cards_readiness: "READINESS",
  pb_labels_gender: "Gender",
  pb_labels_age_group: "Age group",
  pb_labels_working_time: "Working time",
  pb_labels_education: "Education",
  pb_labels_up_to: "Up to",
  pb_labels_professionals: "Professionals",
  pb_labels_time_years: "Time (years)",
  pb_labels_potential: "POTENTIAL",
  pb_labels_performance: "PERFORMANCE",
  pb_labels_current_year: "CURRENT YEAR",
  pb_labels_last_year: "LAST YEAR",
  pb_labels_variation: "VARIATION",
  pb_labels_readjust_history: "Readjust History",
  pb_labels_market_comparison: "Market Comparison",
  pb_labels_salary: "Salary",
  pb_labels_role: "Position",
  pb_labels_ready: "Ready",
  pb_labels_partial: "Partial",
  pb_labels_not: "Not",
  pb_count_people: "Nº People",
  pb_evaluated_people: "Evaluated people",
  pb_current_area: "Current Area",
  pb_position_start_date: "Position Start Date",
  pb_error_duplicated_member: "User ''{name}'' has already been added",
  pb_warning_change_member_profile_at:
    "You can change this member's Position and Area at",
  pb_warning_complement_company_details: "Company Details",
  pb_warning_cant_change_area_or_position:
    "Only an Administrator can change the Area and Position of a member of the Company",
  pb_warning_different_history:
    "Recorded history does not match this member's current Area/Position",
};

const es = {
  pb_professional_profile: "Perfil profesional",
  pb_salary_history: "Historial salarial",
  pb_evaluation_targeting: "Evaluación y Focalización",
  pb_form_full_name: "Nombre Completo",
  pb_form_birth_date: "Fecha de nacimiento",
  pb_form_gender: "Género",
  pb_form_entry_date: "Fecha de Entrada",
  pb_form_area: "Área",
  pb_form_no_area: "Área no configurada",
  pb_form_area_details: "Detalles del Area",
  pb_form_role: "Rol",
  pb_form_no_role: "Rol no configurada",
  pb_form_role_details: "Detalles del Rol",
  pb_form_education: "Educación",
  pb_form_education_details: "Detalles del Educación",
  pb_form_fluent_languages: "Idiomas Fluidos",
  pb_form_select_member: "Seleccionar miembro",
  pb_form_change_member: "Cambiar miembro",
  pb_change_career_history: "Haga clic para cambiar el historial de carrera",
  pb_select_member_participant: "Seleccione un miembro o participante",
  pb_career_trajectory: "Trayectoria de Carrera",
  pb_professional_history: "Historia Profesional",
  pb_start_date: "Fecha de inicio",
  pb_end_date: "Fecha final",
  pb_current_position: "Posición actual",
  pb_first_position: "Posición inicial",
  pb_position: "Posición",
  pb_professional_leader: "Líder Profesional",
  pb_table_edit: "Editar",
  pb_table_remove: "Borrar",
  pb_table_reason: "Razón",
  pb_table_modification_date: "Fecha de modificación",
  pb_table_adjusted_value: "Valor ajustado",
  pb_table_adjusted_percent: "% ajustado",
  pb_leader_not_selected: "No seleccionado",
  pb_no_recorded_history: "Historia no registrada",
  pb_confirm_remove_history: "¿Seguro que quieres borrar este historial?",
  pb_description_update_current_history:
    "También se actualizará el Rol y Área actual de este profesional",
  pb_dialog_change_salary_history: "Cambiar el historial salarial",
  pb_dialog_add_salary_history: "Añadir nuevo historial salarial",
  pb_dialog_change_professional_history: "Cambiar historia profesional",
  pb_dialog_first_professional_history:
    "Establecer la posición actual de este profesional",
  pb_dialog_add_professional_history: "Añadir historia profesional",
  pb_dialog_change_professional_leader: "Cambiar Profesional Líder",
  pb_dialog_select_professional_leader: "Seleccione Profesional Lider",
  pb_dialog_config_area: "Establecer área de actividad",
  pb_dialog_min_date_alert: "La fecha de inicio debe ser mayor que",
  pb_dialog_equal_date:
    "La fecha de inicio y la fecha de finalización no pueden ser iguales",
  pb_dialog_date_conflict: "Conflicto de Historia Profesional",
  pb_dialog_start_date_greater: "Start date can not be greater than end date",
  pb_dialog_from_role: "Rol",
  pb_dialog_initial_role: "Rol inicial",
  pb_dialog_from_area: "Área",
  pb_dialog_initial_area: "Área inicial",
  pb_form_gross_remuneration: "Remuneración bruta",
  pb_form_market_evaluation: "Evaluación de mercado",
  pb_form_average_market_evaluation: "Evaluación promedio del mercado",
  pb_add_new: "Añadir nuevo",
  pb_click_to_edit: "Haz click para editar",
  pb_table_last_evaluations: "Ultimas evaluaciones",
  pb_table_evaluation: "Evaluacion",
  pb_table_current: "Corriente",
  pb_table_previous: "Anterior",
  pb_table_details: "Detalles",
  pb_table_career_map: "Mapa de carrera",
  pb_table_career: "Carrera",
  pb_table_readiness: "Preparación",
  pb_table_path: "Camino",
  pb_salary_evaluation: "Evaluación de salario",
  pb_role_evaluation: "Evaluación de la función",
  pb_area_financial: "Financiera",
  pb_area_administrative: "Administrativa",
  pb_area_human_resources: "Recursos Humanos",
  pb_area_information_technology: "Tecnologías de la Información",
  pb_area_planning: "Planificación",
  pb_area_marketing: "Márketing",
  pb_area_commercial: "Comercial",
  pb_area_support: "Apoyo",
  pb_area_operational: "Operacional",
  pb_area_production: "Producción",
  pb_area_services: "Servicios",
  pb_gender_male: "Masculino",
  pb_gender_female: "Hembra",
  pb_role_trainee: "Aprendiz",
  pb_role_assistant: "Asistente",
  pb_role_analyst: "Analista",
  pb_role_technician: "Técnico",
  pb_role_coordination: "Coordinación",
  pb_role_management: "Administración",
  pb_role_board_of_directors: "Junta Directiva",
  pb_role_executive: "Ejecutivo",
  pb_market_evaluation_below: "Abajo",
  pb_market_evaluation_according: "Conforme",
  pb_market_evaluation_above: "Encima",
  pb_salary_up_3k: "Hasta 3k",
  pb_salary_up_4k: "Hasta 4k",
  pb_salary_up_5k: "Hasta 5k",
  pb_salary_up_10k: "Hasta 10k",
  pb_salary_more_10k: "Más de 10k",
  pb_education_elementary: "Primaria",
  pb_education_technical: "Técnica",
  pb_education_graduation: "Graduación",
  pb_education_postgraduate: "Postgrado",
  pb_education_masters: "Maestría",
  pb_education_doctorate: "Doctorado",
  pb_languages_portuguese: "Portugués",
  pb_languages_english: "Inglés",
  pb_languages_spanish: "Español",
  pb_languages_others: "Otras",
  pb_salary_one_year: "1 año",
  pb_salary_two_years: "2 anõs",
  pb_salary_three_years: "3 años",
  pb_salary_more_three_years: "> 3 años",
  pb_performance_above: "Encima",
  pb_performance_according: "Conforme",
  pb_performance_below: "Abajo",
  pb_leading_1: "9 - Alto potencial",
  pb_leading_2: "8 - Alto rendimiento",
  pb_leading_3: "6 - Buen potencial",
  pb_leading_4: "7 - Rendimiento medio",
  pb_leading_5: "5 - Creciente",
  pb_leading_6: "3 - Eficaz",
  pb_leading_7: "4 - Nuevo",
  pb_leading_8: "2 - Bajo rendimiento",
  pb_leading_9: "1 - Posición de salida",
  pb_interest_change_area_project: "Cambiar área o proyecto",
  pb_interest_short_change_area_project: "Cambiar",
  pb_interest_develop_more_in_area: "Desarrollar mas en el area",
  pb_interest_short_develop_more_in_area: "Desarrollar",
  pb_interest_expand_in_area: "Expandir en el área",
  pb_interest_short_expand_in_area: "Crecer",
  pb_readiness_ready: "Listo",
  pb_readiness_short_term_ready: "Plazo corto listo",
  pb_readiness_medium_term_ready: "Medio plazo listo",
  pb_readiness_develop: "Desarrollar",
  pb_readiness_keep: "Mantener",
  pb_readiness_reassess: "Revalorar",
  pb_career_trail: "Trail",
  pb_career_job: "Job",
  pb_evaluations_immediate: "inmediato",
  pb_evaluations_short_term: "Término corto",
  pb_evaluations_long_term: "Largo plazo",
  pb_evaluations_keep: "Mantener",
  pb_table_lb_evaluations_average: "Puntaje de rendimiento",
  pb_table_lb_evaluations_performance: "Estado de rendimiento",
  pb_table_lb_evaluations_leading: "Líder",
  pb_table_lb_evaluations_interest: "Intereses",
  pb_career_main: "Carrera principal",
  pb_career_option: "Opción de carrera",
  pb_titles_knowledge: "Conocimiento",
  pb_titles_development: "Desarrollo",
  pb_titles_potential: "Potencial",
  pb_titles_tabs_people_map: "Carrera",
  pb_titles_tabs_career_map: "Mapa de carrera",
  pb_titles_tabs_leadership_map: "Mapa de liderazgo",
  pb_titles_cards_profile: "PERFIL",
  pb_titles_cards_career: "CARRERA",
  pb_titles_cards_interests: "INTERESES",
  pb_titles_cards_performance: "ACTUACIÓN",
  pb_titles_cards_leading: "LÍDER",
  pb_titles_cards_evaluation_result: "RESULTADO DE LA EVALUACIÓN",
  pb_titles_cards_wage_recognition: "RECONOCIMIENTO DE SALARIO",
  pb_titles_cards_ascension: "ASCENSIÓN",
  pb_titles_cards_readiness: "PREPARACIÓN",
  pb_labels_gender: "Género",
  pb_labels_age_group: "Grupo de edad",
  pb_labels_working_time: "Tiempo de trabajo",
  pb_labels_education: "Educación",
  pb_labels_up_to: "Hasta",
  pb_labels_professionals: "Profesionales",
  pb_labels_time_years: "Tiempo (años)",
  pb_labels_potential: "POTENCIAL",
  pb_labels_performance: "ACTUACIÓN",
  pb_labels_current_year: "AÑO CORRIENTE",
  pb_labels_last_year: "EL AÑO PASADO",
  pb_labels_variation: "VARIACIÓN",
  pb_labels_readjust_history: "Histórico de Reajuste",
  pb_labels_market_comparison: "Comparacion de Mercado",
  pb_labels_salary: "Salario",
  pb_labels_role: "Rol",
  pb_labels_ready: "Listo",
  pb_labels_partial: "Parcial",
  pb_labels_not: "No",
  pb_count_people: "Nº Personas",
  pb_evaluated_people: "Personas evaluadas",
  pb_current_area: "Área Actual",
  pb_position_start_date: "Position Initial Date",
  pb_error_duplicated_member: "El usuario ''{name}'' ya se agregado",
  pb_warning_change_member_profile_at:
    "Puede cambiar la Posición y el Área de este miembro en",
  pb_warning_complement_company_details: "Detalles de la Compañía",
  pb_warning_cant_change_area_or_position:
    "Solo un Administrador puede cambiar el Área y la Posición de un miembro de la empresa",
  pb_warning_different_history:
    "El historial registrado no corresponde al Área/Posición actual de este miembro",
};

const pt = {
  pb_professional_profile: "Perfil Profissional",
  pb_salary_history: "Histórico salarial",
  pb_evaluation_targeting: "Avaliação e Direcionamento",
  pb_form_full_name: "Nome Completo",
  pb_form_birth_date: "Data de Nascimento",
  pb_form_gender: "Gênero",
  pb_form_entry_date: "Data de Entrada na Empresa",
  pb_form_area: "Área de Atuação",
  pb_form_no_area: "Área não configurada",
  pb_form_area_details: "Detalhes da Area",
  pb_form_role: "Cargo",
  pb_form_no_role: "Cargo não configurado",
  pb_form_role_details: "Detalhes do cargo",
  pb_form_education: "Formação",
  pb_form_education_details: "Detalhes da Formação",
  pb_form_fluent_languages: "Idiomas Fluentes",
  pb_form_select_member: "Selecionar membro",
  pb_form_change_member: "Alterar membro",
  pb_change_career_history: "Clique para alterar o histórico de carreira",
  pb_select_member_participant: "Selecione um membro ou participante",
  pb_career_trajectory: "Trajetória de Carreira",
  pb_professional_history: "Histórico Profissional",
  pb_start_date: "Data de início",
  pb_end_date: "Data de término",
  pb_current_position: "Posição atual",
  pb_first_position: "Posição inicial",
  pb_position: "Posição",
  pb_professional_leader: "Líder Profissional",
  pb_table_edit: "Editar",
  pb_table_remove: "Remover",
  pb_table_reason: "Razão",
  pb_table_modification_date: "Data de modificação",
  pb_table_adjusted_value: "Valor ajustado",
  pb_table_adjusted_percent: "% ajustado",
  pb_leader_not_selected: "Não selecionado",
  pb_no_recorded_history: "Nenhum histórico cadastrado",
  pb_confirm_remove_history: "Tem certeza que deseja excluir esse histórico?",
  pb_description_update_current_history:
    "O Cargo e Área atuais deste profissional também serão atualizadas",
  pb_dialog_change_salary_history: "Alterar histórico salarial",
  pb_dialog_add_salary_history: "Adicionar histórico salarial",
  pb_dialog_change_professional_history: "Alterar histórico profissional",
  pb_dialog_first_professional_history:
    "Configure a posição atual deste profissional",
  pb_dialog_add_professional_history: "Adicionar histórico profissional",
  pb_dialog_change_professional_leader: "Alterar líder do profissional",
  pb_dialog_select_professional_leader: "Selecionar líder do profissional",
  pb_dialog_config_area: "Configurar área de atuação",
  pb_dialog_min_date_alert: "A data de início precisa ser maior que",
  pb_dialog_equal_date:
    "A data de início e data de término não podem ser iguais",
  pb_dialog_date_conflict: "Conflito de histórico profissional",
  pb_dialog_start_date_greater:
    "A data de início não pode ser maior que a data final",
  pb_dialog_from_role: "Cargo",
  pb_dialog_initial_role: "Cargo inicial",
  pb_dialog_from_area: "Área",
  pb_dialog_initial_area: "Área inicial",
  pb_form_gross_remuneration: "Remuneração bruta",
  pb_form_market_evaluation: "Avaliação do mercado",
  pb_form_average_market_evaluation: "Avaliação média do mercado",
  pb_add_new: "Cadastrar novo",
  pb_click_to_edit: "Clique para editar",
  pb_table_last_evaluations: "Últimas avaliações",
  pb_table_evaluation: "Avaliação",
  pb_table_current: "Atual",
  pb_table_previous: "Anterior",
  pb_table_details: "Detalhes",
  pb_table_career_map: "Mapa de carreira",
  pb_table_career: "Carreira",
  pb_table_readiness: "Prontidão",
  pb_table_path: "Caminho",
  pb_salary_evaluation: "Avaliação de Salário",
  pb_role_evaluation: "Avaliação de Cargo",
  pb_area_financial: "Finanças",
  pb_area_administrative: "Administrativa",
  pb_area_human_resources: "Recursos Humanos",
  pb_area_information_technology: "Tecnologia da Informação",
  pb_area_planning: "Planejamento",
  pb_area_marketing: "Marketing",
  pb_area_commercial: "Comercial",
  pb_area_support: "Suporte",
  pb_area_operational: "Operacional",
  pb_area_production: "Produção",
  pb_area_services: "Serviços",
  pb_gender_male: "Masculino",
  pb_gender_female: "Feminino",
  pb_role_trainee: "Estagiário",
  pb_role_assistant: "Assistente",
  pb_role_analyst: "Analista",
  pb_role_technician: "Técnico",
  pb_role_coordination: "Coordenação",
  pb_role_management: "Gerência",
  pb_role_board_of_directors: "Diretoria",
  pb_role_executive: "Executiva",
  pb_market_evaluation_below: "Abaixo",
  pb_market_evaluation_according: "Conforme",
  pb_market_evaluation_above: "Acima",
  pb_salary_up_3k: "Até 3k",
  pb_salary_up_4k: "Até 4k",
  pb_salary_up_5k: "Até 5k",
  pb_salary_up_10k: "Até 10k",
  pb_salary_more_10k: "Mais de 10k",
  pb_education_elementary: "Fundamental",
  pb_education_technical: "Técnica",
  pb_education_graduation: "Graduação",
  pb_education_postgraduate: "Pós-graduação",
  pb_education_masters: "Mestrado",
  pb_education_doctorate: "Doutorado",
  pb_languages_portuguese: "Português",
  pb_languages_english: "Inglês",
  pb_languages_spanish: "Espanhol",
  pb_languages_others: "Outras",
  pb_salary_one_year: "1 ano",
  pb_salary_two_years: "2 anos",
  pb_salary_three_years: "3 anos",
  pb_salary_more_three_years: "> 3 anos",
  pb_performance_above: "Acima",
  pb_performance_according: "Conforme",
  pb_performance_below: "Abaixo",
  pb_leading_1: "9 - Alto potencial",
  pb_leading_2: "8 - Alto desempenho",
  pb_leading_3: "6 - Bom potencial",
  pb_leading_4: "7 - Média performance",
  pb_leading_5: "5 - Crescendo",
  pb_leading_6: "3 - Eficaz",
  pb_leading_7: "4 - Novo",
  pb_leading_8: "2 - Baixa performance",
  pb_leading_9: "1 - Posição de saída",
  pb_interest_change_area_project: "Mudança de área ou projeto",
  pb_interest_short_change_area_project: "Mudar",
  pb_interest_develop_more_in_area: "Desenvolver mais na área",
  pb_interest_short_develop_more_in_area: "Desenvolver",
  pb_interest_expand_in_area: "Expandir na área",
  pb_interest_short_expand_in_area: "Crescer",
  pb_readiness_ready: "Pronto",
  pb_readiness_short_term_ready: "Pronto em curto prazo",
  pb_readiness_medium_term_ready: "Pronto em médio prazo",
  pb_readiness_develop: "Desenvolver",
  pb_readiness_keep: "Manter",
  pb_readiness_reassess: "Reavaliar",
  pb_career_trail: "Trilha",
  pb_career_job: "Job",
  pb_evaluations_immediate: "Imediato",
  pb_evaluations_short_term: "Curto prazo",
  pb_evaluations_long_term: "Longo prazo",
  pb_evaluations_keep: "Manter",
  pb_table_lb_evaluations_average: "Nota de desempenho",
  pb_table_lb_evaluations_performance: "Status de desempenho",
  pb_table_lb_evaluations_leading: "Liderança",
  pb_table_lb_evaluations_interest: "Interesse",
  pb_career_main: "Carreira principal",
  pb_career_option: "Carreira opção",
  pb_titles_knowledge: "Conhecimento",
  pb_titles_development: "Desenvolvimento",
  pb_titles_potential: "Potencial",
  pb_titles_tabs_people_map: "Carreira",
  pb_titles_tabs_career_map: "Mapa de Carreira",
  pb_titles_tabs_leadership_map: "Mapa de Liderança",
  pb_titles_cards_profile: "PERFIL",
  pb_titles_cards_career: "CARREIRA",
  pb_titles_cards_interests: "INTERESSES",
  pb_titles_cards_performance: "DESEMPENHO",
  pb_titles_cards_leading: "LIDERANÇA",
  pb_titles_cards_evaluation_result: "RESULTADO DA AVALIAÇÃO",
  pb_titles_cards_wage_recognition: "RECONHECIMENTO SALARIAL",
  pb_titles_cards_ascension: "ASCENSÃO",
  pb_titles_cards_readiness: "PRONTIDÃO",
  pb_labels_gender: "Gênero",
  pb_labels_age_group: "Faixa etária",
  pb_labels_working_time: "Tempo de casa",
  pb_labels_education: "Formação",
  pb_labels_up_to: "Até",
  pb_labels_professionals: "Profissionais",
  pb_labels_time_years: "Tempo (anos)",
  pb_labels_potential: "POTENCIAL",
  pb_labels_performance: "DESEMPENHO",
  pb_labels_current_year: "ANO ATUAL",
  pb_labels_last_year: "ANO ANTERIOR",
  pb_labels_variation: "VARIAÇÃO",
  pb_labels_readjust_history: "Histórico de Reajuste",
  pb_labels_market_comparison: "Comparação Mercado",
  pb_labels_salary: "Salarial",
  pb_labels_role: "Cargo",
  pb_labels_ready: "Pronto",
  pb_labels_partial: "Parcial",
  pb_labels_not: "Não",
  pb_count_people: "Nº Pessoas",
  pb_evaluated_people: "Pessoas avaliadas",
  pb_current_area: "Área Atual",
  pb_position_start_date: "Data Inicio Posição",
  pb_error_duplicated_member: "O usuário ''{name}' já foi adicionado'",
  pb_warning_change_member_profile_at:
    "Você pode alterar o Cargo e Área deste membro em",
  pb_warning_complement_company_details: "Detalhes da Empresa",
  pb_warning_cant_change_area_or_position:
    "Apenas um Administrador pode alterar Área e Cargo de um membro da Empresa",
  pb_warning_different_history:
    "O Histórico registrado não corresponde com a Área/Cargo atuais deste colaborador",
};

const translations = { en, es, pt };

export default translations;
