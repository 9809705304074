import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useWindowSize } from "../../utils/customHooks";

const MainSideBarItem = ({
  onClick,
  tooltipText,
  iconClass,
  label,
  active,
  children,
  path,
  open,
  child,
  lastChild,
  isSelectedParent,
  showTooltip = false,
}) => {
  const windowSize = useWindowSize();
  const [isMobile, setMobile] = useState(false);

  function resize() {
    const screenWidth = windowSize[0];
    setMobile(screenWidth <= 425);
  }

  useEffect(() => {
    resize();
  }, [windowSize]);

  function handleChangeRoute() {
    if (typeof onClick === "function") onClick();
  }

  const hasChindren = children;
  const idError = path ? path.replace("/", "") : null;
  const idFix = idError ? idError.replace("/", "") : null;

  const dataToggle = isMobile ? "push-menu" : "";
  const arrowClass = open ? "fas fa-chevron-down" : "fas fa-chevron-right";
  const tooltipMenuItem = (
    <Tooltip id={`tooltip_Menu_item_${Math.random}`}>{tooltipText}</Tooltip>
  );

  const linkContent = (
    <a data-toggle={dataToggle} onClick={handleChangeRoute}>
      <i className={`${iconClass} icon`} />{" "}
      <span className="label">{label}</span>
      {hasChindren ? (
        <div className="sidebar-arrow">
          <i className={arrowClass} />
        </div>
      ) : null}
    </a>
  );

  const childrenMenuClass = open ? "treeview-menu open" : "treeview-menu";

  return (
    <li
      id={idFix}
      className={classnames(
        { treeview: hasChindren },
        { active },
        { childRelated: child },
        { lastChild },
        { isSelectedParent }
      )}
    >
      {tooltipText && showTooltip ? (
        <OverlayTrigger placement="bottom" overlay={tooltipMenuItem}>
          {linkContent}
        </OverlayTrigger>
      ) : (
        linkContent
      )}
      {open === true && hasChindren ? (
        <ul className="treeview-menu open">{children}</ul>
      ) : null}
      {hasChindren && <ul className={childrenMenuClass} />}
      {isSelectedParent && <div className="selectedTip" />}
    </li>
  );
};

MainSideBarItem.propTypes = {
  tooltipText: PropTypes.string,
  open: PropTypes.bool,
};

MainSideBarItem.defaultProps = {
  open: true,
};

export default MainSideBarItem;
