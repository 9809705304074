import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import FormDialog from "../../FormDialog";
import SimpleLoader from "../../../SimpleLoader";
import MaterialTextField from "../../MaterialTextField";
import GoalDescription from "../insight/GoalDescription";
import GoalEvaluation from "../insight/GoalEvaluation";
import { setSelectedGoal } from "../../../../actions/kpiActions";

import kpiUtils from "../../../../utils/kpis";
import { updateKpiAdministration } from "../../../../actions/kpiActions";
import { getValidFunc } from "../../../../utils/validations";
import { checkAccess } from "../../../../utils/accessLevels";
import { useCompareChanges } from "../../../../utils/hooks/useCompareChanges";
import { getErrorMessage } from "../../../../utils/formValidation";
import ConfirmationDialog from "../../ConfirmationDialog";

const {
  getNewKpiDefaultOptions,
  getNewIndicatorErrors,
  checkIsIndicatorEditable,
} = kpiUtils;

const messages = defineMessages({
  kpi_dialog_title_new_goal: { id: "kpi_dialog_title_new_goal" },
  kpi_dialog_title_edit_goal: { id: "kpi_dialog_title_edit_goal" },
  kpi_form_title: { id: "kpi_form_title" },
  kpi_table_text_registrations: { id: "kpi_table_text_registrations" },
  kpi_warning_period_between_blocked: {
    id: "kpi_warning_period_between_blocked",
  },
  global_update: { id: "global.update" },
  global_save: { id: "global.save" },
  global_goBack: { id: "global.goBack" },
});

const defaultConfirmDialog = {
  title: "",
  onConfirm: null,
  onCancel: null,
  open: false,
};

const NewIndicatorDialog = ({
  open = false,
  kpiAdministration,
  editingForm = {},
  intl,
  getKpiConfig,
  updateKpiAdministration,
  setSelectedGoal,
  selectedToolId,
  handleAutoSave,
  closeFormAndCallback,
  ...props
}) => {
  const defaultForm = getNewKpiDefaultOptions(kpiAdministration?.periodCycle);
  const [form, setForm] = useState({ ...defaultForm });
  const [formHistory, setFormHistory] = useState({ ...defaultForm });
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({
    ...defaultConfirmDialog,
  });

  const { haveChanges } = useCompareChanges({
    compare: form,
    base: formHistory,
    isLoading,
  });

  const afterCancel = getValidFunc(props.afterCancel);
  const administrativeAccess = checkAccess([
    "OWNER",
    "ADMIN",
    "MODERATOR",
    "MANAGER",
  ]);

  const isEditing = editingForm.type === "newIndicator";
  const formErrors = getNewIndicatorErrors(form, kpiAdministration?.goals);
  const duplicatedTitle =
    formErrors.filter(({ slug }) => ["invalidTitle"].includes(slug)).length > 0;
  const cantCreateIndicator = duplicatedTitle || isLoading;

  useEffect(() => {
    if (editingForm && isEditing && kpiAdministration) {
      const goalInfo = kpiAdministration?.goals.find(
        (goal) => goal.id === editingForm.params.goalId
      );

      if (goalInfo) {
        setForm({ ...goalInfo });
        setFormHistory({ ...goalInfo });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingForm, isEditing, kpiAdministration]);

  const resetForm = () => {
    setForm(defaultForm);
    setFormHistory(defaultForm);
  };

  const saveNewIndicator = async () => {
    if (cantCreateIndicator) return;

    setIsLoading(true);

    const data = await getKpiConfig();

    if (data && data.id) {
      // FIXME: criar rota exclusiva para criar indicadore, sem atualizar toda a KPI admin
      updateKpiAdministration(
        kpiAdministration?.selectedToolId,
        {
          ...data,
          goals: [...data.goals, { ...form }],
        },
        {
          onSuccess: (updatedAdministration) => {
            const createdGoal = updatedAdministration.goals.find(
              ({ title }) => title === form.title
            );
            if (createdGoal) {
              setForm(createdGoal);
              setFormHistory(createdGoal);
              setSelectedGoal({ payload: createdGoal });
            }

            setIsLoading(false);
          },
          onError: () => {
            setIsLoading(false);
          },
        }
      );
    }
  };

  const handleSaveGoal = async (updatedGoal, callbacks) => {
    const data = await getKpiConfig();

    if (data && data.id) {
      const updatedIndicators = data.goals.map((goal) => {
        if (goal.id === updatedGoal.id) return updatedGoal;
        return goal;
      });

      handleAutoSave(
        {
          ...data,
          goals: updatedIndicators,
        },
        callbacks
      );
      setFormHistory(updatedGoal);
    }
  };

  const editSaveIndicator = async (callbacks) => {
    await handleSaveGoal(form, callbacks);
  };

  const handleConfirm = () => {
    if (isLoading) return;
    if (!isEditing) saveNewIndicator();
    if (isEditing && !isLoading) {
      setIsLoading(true);

      editSaveIndicator({
        onSuccess: () => {
          setIsLoading(false);
        },
        onError: () => {
          setIsLoading(false);
        },
      });
    }
  };

  function resetDialog() {
    resetForm();
    if (typeof afterCancel === "function") afterCancel();
  }

  const resetConfirmDialog = () => {
    setConfirmationDialog({ ...defaultConfirmDialog });
  };

  function handleSaveChanges() {
    setConfirmationDialog({
      open: true,
      title: "Tem certeza que deseja sair sem salvar as alterações?",
      description: "Todas as alterações serão descartadas ao sair.",
      cancelText: "Sair sem salvar",
      confirmText: "Voltar a edição",
      onConfirm: () => {
        resetConfirmDialog();
      },
      onCancel: () => {
        resetDialog();
      },
    });
  }

  function handleCancel() {
    if (haveChanges) {
      handleSaveChanges();
    } else {
      resetDialog();
    }
  }

  const handleUpdateForm = (fieldSlug, newValue) => {
    setForm({ ...form, [fieldSlug]: newValue });
  };

  const isAdmin = administrativeAccess;
  const isEditingAllowed = checkIsIndicatorEditable(
    form.id,
    form.relationshipType,
    isAdmin
  );

  const appendProps = {
    setGoalDescriptions: setForm,
    setGoalHistory: setFormHistory,
    formErrors,
    kpiAdministration,
    goalTargetConfigurations: kpiAdministration?.goalTargets,
    zeroWhenNegative: kpiAdministration?.zeroWhenNegative,
    isAdmin,
    onlyForm: true,
    selectedToolId,
    handleSaveGoal,
    isLoading,
    setIsLoading,
    setConfirmationDialog,
    resetConfirmDialog,
  };

  const title = !isEditing
    ? intl.formatMessage(messages.kpi_dialog_title_new_goal)
    : intl.formatMessage(messages.kpi_dialog_title_edit_goal);

  const formTitle = (
    <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
      {`${title} `}
      {isLoading && <SimpleLoader color="#6b42a9" />}
    </div>
  );

  return (
    <FormDialog
      open={open}
      title={formTitle}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmText={intl.formatMessage(messages.global_update)}
      cancelText={intl.formatMessage(messages.global_goBack)}
      dialogClassName="new-goal-dialog"
      blockConfirm={!haveChanges || isLoading || !form?.id || duplicatedTitle}
      blockCancel={isLoading}
    >
      {confirmationDialog.open && (
        <ConfirmationDialog
          open={confirmationDialog.open}
          title={confirmationDialog.title}
          description={confirmationDialog.description}
          onConfirm={confirmationDialog.onConfirm}
          confirmText={confirmationDialog.confirmText}
          cancelText={confirmationDialog.cancelText}
          onCancel={confirmationDialog.onCancel}
          hideCancel={confirmationDialog.hideCancel}
          confirmColor={confirmationDialog.confirmColor}
        />
      )}
      <div
        className="row"
        style={{
          opacity: isLoading ? 0.7 : 1,
          maxHeight: "75vh",
          overflowX: "auto",
        }}
      >
        <div className="col-xs-12">
          <MaterialTextField
            id="goalTitle"
            label={intl.formatMessage(messages.kpi_form_title)}
            value={form.title}
            onChange={
              isEditingAllowed
                ? (e) => handleUpdateForm("title", e.target.value)
                : () => {}
            }
            inputStyle={!isEditingAllowed ? { cursor: "not-allowed" } : {}}
            primaryInputProps={{ maxLength: 255 }}
          />
          {!isLoading &&
            getErrorMessage(
              ["invalidTitle"],
              formErrors,
              "left",
              {
                height: "30px",
              },
              true
            )}
          {!form.id && (
            <button
              className="btn btn-sm btn-primary"
              disabled={cantCreateIndicator}
              onClick={saveNewIndicator}
            >
              {intl.formatMessage(messages.global_save)}
              {isLoading ? "..." : ""}
            </button>
          )}
        </div>
        {form.id && (
          <div className="row" style={isLoading ? { opacity: 0.7 } : {}}>
            <GoalDescription {...appendProps} goalDescriptions={form} />
            <br />
            <GoalEvaluation {...appendProps} goalDescriptions={form} />
          </div>
        )}
      </div>
    </FormDialog>
  );
};

NewIndicatorDialog.propTypes = {
  afterConfirm: PropTypes.func.isRequired,
  afterCancel: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default connect(null, { updateKpiAdministration, setSelectedGoal })(
  injectIntl(NewIndicatorDialog)
);
