import { useHistory, withRouter } from "react-router-dom";
import { compose } from "redux";
import { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import {
  getDecryptedObjectBy,
  setCustomLinkStates,
} from "../actions/appActions";
import { getTool } from "../actions/toolActions";
import { getInsight } from "../actions/insightActions";
import { getCompany } from "../actions/companyActions";
import { getWorkspace } from "../actions/workspaceActions";
import {
  setInvitedUser,
  setSmartLinkLoading,
  setSelectedDomainId,
} from "../actions/accessLevelsActions";
import {
  DOMAIN,
  COMPANY,
  WORKSPACE,
  TOOL,
  INSIGHT,
  USER_PROFILE,
  TOOL_WORKFLOW
} from "../actions/types";

const SmartLinkController = ({
  user,
  isAuthenticated,
  setCustomLinkStates,
  setSmartLinkLoading,
  setInvitedUser,
  setSelectedDomainId,
}) => {
  const history = useHistory();

  const setCode = (code) => localStorage.setItem("customUrlCode", code);

  const getInfo = () => {
    const host = window.location.href;
    const urlCode = host.split("c=").pop().split("#")[0];

    return {
      includes: host.includes("c="),
      urlCode,
      storedCode: localStorage.getItem("customUrlCode") || null,
    };
  };

  const handleRedirectByType = useCallback(
    ({ target, selectedToolId }) => {
      if (!selectedToolId) return history.push("/");

      switch (target) {
        case DOMAIN:
          history.push("/domainConfig");
          break;
        case COMPANY:
          history.push("/company");
          break;
        case WORKSPACE:
          history.push("/workspace");
          break;
        case TOOL:
          history.push(`/tool/${selectedToolId}`);
          break;
        case INSIGHT:
          history.push(`/tool/${selectedToolId}`);
          break;
        case USER_PROFILE:
          history.push("/profile");
          break;
        case TOOL_WORKFLOW:
          history.push(`/tool/${selectedToolId}/workflow`);
          break
        default:
          history.push("/");
          break;
      }
    },
    [history],
  );

  useEffect(() => {
    const { storedCode, urlCode, includes } = getInfo();

    const linkConditions = () => {
      if (!includes && !storedCode) return false;
      if (includes) setCode(urlCode);

      return true;
    };

    if (!linkConditions()) return;

    setSmartLinkLoading(true);

    getDecryptedObjectBy({ token: getInfo().storedCode }).then(
      async (decryptedData) => {
        const { domainId: selectedDomainId } = decryptedData || {};

        if (selectedDomainId) setSelectedDomainId(selectedDomainId);
        if (!isAuthenticated) history.push("/login?redirect");

        if (!decryptedData || !selectedDomainId) {
          localStorage.removeItem("smartRedirect");
          setSmartLinkLoading(false);
          return history.push("/");
        }

        const { selectedToolId, insightId, companyId, workspaceId } =
          decryptedData;

        const selectedTool = await getTool(selectedToolId);
        const insight = await getInsight(insightId);
        const company = await getCompany(companyId);
        const workspace = await getWorkspace(workspaceId);

        setCustomLinkStates({
          company,
          insight,
          workspace,
          selectedTool,
          selectedDomainId,
        });

        handleRedirectByType({ ...decryptedData, selectedToolId });

        if (decryptedData.target === "INVITATION") {
          setInvitedUser(decryptedData.details.invitedUser);
          history.push("/signup/");
        }

        localStorage.removeItem("smartRedirect");
        return setSmartLinkLoading(false);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAuthenticated]);

  return null;
};

const mapStateToProps = (state) => {
  const { selectedTool, user, access = {} } = state;

  const { selectedDomainId = null, userAccess = {} } = access || {};

  return {
    user,
    access,
    userAccess,
    selectedTool,
    selectedDomainId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    setCustomLinkStates,
    setSmartLinkLoading,
    setInvitedUser,
    setSelectedDomainId,
  }),
)(SmartLinkController);
