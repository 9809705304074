import { makeStyles } from "@material-ui/styles";

export const makeSpaceBetween = (textArray = []) => {
  return textArray.map((text) => " " + text);
};

export const patternStyles = makeStyles((theme) => ({
  cardHeaderStyle: {
    height: "15%",
    display: "flex",
    fontSize: "16px",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F7F7F7",
    borderRadius: "5px",
  },
  generalStyle: {
    width: "32%",
    margin: "5px",
    padding: "0px",
    height: "300px",
    cursor: "pointer",
    marginLeft: "0px",
    backgroundColor: "#F7F7F7",
    borderRadius: "5px",

  },
  responsibleStyle: {
    width: "32%",
    margin: "5px",
    padding: "0px",
    height: "300px",
    cursor: "pointer",
    backgroundColor: "#F7F7F7",
    borderRadius: "5px",

  },
  physicalScheduleStyle: {
    width: "34%",
    margin: "5px",
    padding: "0px",
    height: "300px",
    cursor: "pointer",
    marginRight: "0px",
    backgroundColor: "#F7F7F7",
    borderRadius: "5px",

  },
  qualityStyle: {
    width: "32%",
    margin: "5px",
    padding: "0px",
    height: "300px",
    cursor: "pointer",
    marginLeft: "0px",
    backgroundColor: "#F7F7F7",
    borderRadius: "5px",

  },
  deadlineStyle: {
    width: "32%",
    margin: "5px",
    padding: "0px",
    height: "300px",
    cursor: "pointer",
    backgroundColor: "#F7F7F7",
    borderRadius: "5px",

  },
  costStyle: {
    width: "34%",
    margin: "5px",
    padding: "0px",
    height: "300px",
    cursor: "pointer",
    marginRight: "0px",
    backgroundColor: "#F7F7F7",
    borderRadius: "5px",

  },
  centerAlign: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  leftAlign: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
  },
  fixedScroll: {
    width: "100%",
    overflowX: "auto",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  strongFont: {
    fontSize: "18px",
    fontWeight: "700",
  },
  fullWidth: {
    width: "100%",
  },
}));

export const generalCardStyles = makeStyles((theme) => ({
  cardBody: {
    height: "85%",
  },
  cardLine: {
    height: "45px",
    display: "flex",
    fontSize: "12px",
    justifyContent: "spaceBetween",
  },
  cardLineValue: {
    fontSize: "18px",
    fontWeight: "800",
    marginTop: "-4px",
    marginLeft: "10px",
  },
  boxInfoHeader: {
    width: "100px",
    display: "flex",
    fontWeight: "500",
    alignItems: "center",
    justifyContent: "center",
  },
  boxInfoContent: {
    width: "100px",
    height: "80px",
    display: "flex",
    fontSize: "35px",
    fontWeight: "700",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#D7D7D7",
  },
  generalInfoContent:{
    position:'absolute',
    top:'-15px',
    left:'133px',
    width: "100px",
    height: "30px",
    display: "flex",
    fontSize: "20px",
    fontWeight: "700",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#D7D7D7",
    borderRadius:'10px',
  }
}));

export const responsibleCardStyles = makeStyles((theme) => ({
  percentageHeader: {
    fontSize: "16px",
    fontWeight: "700",
    marginLeft: "15px",
  },
  valueFontStyle: {
    fontSize: "14px",
    fontWeight: "700",
  },
  percentageFontStyle: {
    fontSize: "13px",
    fontWeight: "500",
  },
}));
