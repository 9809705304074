import React, { useState, useContext } from "react";
import { defineMessages } from "react-intl";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ExampleModal from "./ExampleModal";
import MeasurementContext from "../MeasurementContext";
import { translatedText } from "../../../utils/translationUtils";

const translation = (id, values) => translatedText(id, messages, values);

const messages = defineMessages({
  measu_see_options: {
    id: "measu_see_options",
  },
});

const ExampleTable = () => {
  const { exampleBody = {} } = useContext(MeasurementContext);

  const [exampleModalConfig, setCallExampleModalConfig] = useState({
    callModal: false,
    options: [],
  });

  const getHeaderSlugs = (headers = []) => {
    let slugs = [];

    Object.keys(headers).map((header) => {
      slugs = [...slugs, header];
    });

    return slugs;
  };

  const callExampleTable = (values = []) => {
    setCallExampleModalConfig({ callModal: true, options: [...values] });
  };

  const handleMultipleValues = (values = []) => {
    return (
      <a
        style={{ cursor: "pointer", whiteSpace: "nowrap" }}
        onClick={() => callExampleTable(values)}
      >
        {translation("measu_see_options")}
      </a>
    );
  };

  const displayTable = () => {
    return Object.keys(exampleBody).map((sheetName) => {
      const { data = [], headers = [], title = "" } =
        exampleBody[sheetName] || {};

      delete headers.id;
      delete headers.projectEvaluationId;

      const slugs = getHeaderSlugs(headers);

      return (
        <div align="center" style={{ height: "350px" }} key={sheetName}>
          <h3 style={{ marginTop: "40px" }}>{title}</h3>
          <TableContainer
            component={Paper}
            style={{ marginTop: "40px" }}
            key={sheetName}
          >
            <Table
              className="table table-striped table-hover"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {Object.keys(headers).map((header, index) => {
                    return (
                      <TableCell
                        key={index}
                        align="center"
                        style={{ width: "90px" }}
                      >
                        <h6 style={{ width: "90px" }}>{headers[header]}</h6>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    {slugs.map((slug) => {
                      const multipleValues = Array.isArray(row[slug])
                        ? true
                        : false;

                      return (
                        <TableCell
                          key={slug}
                          align="center"
                          style={{ width: "90px" }}
                        >
                          <h6 style={{ width: "90px" }}>
                            {multipleValues
                              ? handleMultipleValues(row[slug])
                              : row[slug]}
                          </h6>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    });
  };

  return (
    <>
      {displayTable()}
      <ExampleModal
        exampleModalConfig={exampleModalConfig}
        setCallExampleModalConfig={setCallExampleModalConfig}
      />
    </>
  );
};

export default ExampleTable;
