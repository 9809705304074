import React from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import utils from "../../../../../../../utils/toolUtils";
import gbUtils from "../../../../../../../utils/goalsBonus";
import ExcelNumberFormat from "../../../../../../Common/ExcelNumberFormat";
import SimpleSelectExcelCollumn from "../../../../../../Common/SimpleSelectExcelCollumn";
import { deleteParticipant } from "../../../../../../../actions/goalsAndBonusActions";
import { getErrorMessage } from "../../../../../../../utils/formValidation";
import {
  getEmptyTableRow,
  getDefaultAddTableLine,
} from "../../../../../../../utils/tableUtils";
import { getDefaultRemoveColumn } from "../../../../../../../utils/okrUtils";
import { getSelectedToolStates } from "../../../../../../customMapStates";

const {
  getUpdatedObjectInArray,
  getUpdatedDeleteArrayByIndex,
  orderArrayByObjAttr,
  getObjectInfoById,
  getOnlySublevelArrays,
} = utils;

const { getAllParticipantInfo, buildRoleBonusesOptions } = gbUtils;

const messages = defineMessages({
  gb_table_text_participant: { id: "gb_table_text_participant" },
  gb_table_text_no_participant: { id: "gb_table_text_no_participant" },
  gb_table_text_job_role: { id: "gb_table_text_job_role" },
  gb_table_text_salary_base: { id: "gb_table_text_salary_base" },
  gb_table_text_period: { id: "gb_table_text_period" },
  gb_warning_connected_participant_question: {
    id: "gb_warning_connected_participant_question",
  },
  gb_warning_need_arquive_question: { id: "gb_warning_need_arquive_question" },
  gb_warning_cant_change_role: { id: "gb_warning_cant_change_role" },
  gb_confirm_delete_participant: { id: "gb_confirm_delete_participant" },
});

const ParticipantsTable = (props) => {
  const {
    setConfirmationDialog,
    goalBonusAdministration,
    setGoalBonusAdministration,
    selectedTool,
    resetConfirmDialog,
    participantPeriodOptions,
    toggleOpenFormDialogs,
    companyMembersAndParticipants,
    allQuestions = {},
    intl,
    deleteParticipant,
    administrationErrors,
  } = props;

  const handleUpdateParticipant = (
    newInfo,
    fieldSlug = "base",
    compareIndex,
  ) => {
    const newUpdatedParticipants = getUpdatedObjectInArray(
      newInfo,
      goalBonusAdministration.participants,
      fieldSlug,
      compareIndex,
    );

    setGoalBonusAdministration({
      ...goalBonusAdministration,
      participants: newUpdatedParticipants,
    });
  };

  const handleUpdateRoleBonus = (newRoleBonusId, compareIndex) => {
    if (newRoleBonusId) {
      const newUpdatedParticipants = getUpdatedObjectInArray(
        { id: newRoleBonusId },
        goalBonusAdministration.participants,
        "roleBonus",
        compareIndex,
      );

      setGoalBonusAdministration({
        ...goalBonusAdministration,
        participants: newUpdatedParticipants,
      });
    }
  };

  const handleDeleteParticipant = (index) => {
    const deletingObjInfo = goalBonusAdministration.participants[index];

    if (deletingObjInfo.id) {
      deleteParticipant(deletingObjInfo.id, selectedTool.id);

      const updatedParticipants = getUpdatedDeleteArrayByIndex(
        goalBonusAdministration.participants,
        index,
      );

      setGoalBonusAdministration({
        ...goalBonusAdministration,
        participants: updatedParticipants,
      });
    }

    resetConfirmDialog();
  };

  const openDeleteParticipant = (index) => {
    const deletingRoleBonus = goalBonusAdministration.participants[index] || {};
    let allowedToDelete = true;

    const onlyQuestions = getOnlySublevelArrays(allQuestions);

    if (deletingRoleBonus.id) {
      const relatedQuestion = getObjectInfoById(
        deletingRoleBonus.id,
        onlyQuestions,
        "goalsBonusProgramParticipantId",
      );

      allowedToDelete = relatedQuestion && relatedQuestion.id ? false : true;

      setConfirmationDialog({
        title: allowedToDelete
          ? intl.formatMessage(messages.gb_confirm_delete_participant)
          : intl.formatMessage(
              messages.gb_warning_connected_participant_question,
            ),
        description: allowedToDelete
          ? null
          : intl.formatMessage(messages.gb_warning_need_arquive_question, {
              questionName: relatedQuestion.text,
            }),
        onConfirm: allowedToDelete
          ? () => handleDeleteParticipant(index)
          : resetConfirmDialog,
        confirmText: !allowedToDelete ? "OK" : null,
        hideCancel: !allowedToDelete ? true : false,
        onCancel: resetConfirmDialog,
        open: true,
      });
    }
  };

  const cantChangeParticipantRole = () => {
    setConfirmationDialog({
      title: intl.formatMessage(messages.gb_warning_cant_change_role),
      description: null,
      onConfirm: resetConfirmDialog,
      confirmText: "OK",
      hideCancel: true,
      onCancel: resetConfirmDialog,
      open: true,
    });
  };

  const roleBonusesOptions = buildRoleBonusesOptions(
    goalBonusAdministration.roleBonuses,
  );

  let reorderedParticipants = orderArrayByObjAttr(
    goalBonusAdministration.participants,
    "memberParticipant",
    "firstName",
  );

  const onlyQuestions = getOnlySublevelArrays(allQuestions);
  const alreadyRelatedParticipants = [
    ...onlyQuestions.map(
      ({ goalsBonusProgramParticipantId }) => goalsBonusProgramParticipantId,
    ),
  ];

  const displayErrors = () => {
    return (
      <div className="row">
        {getErrorMessage(
          ["noRoleSelected"],
          administrationErrors,
          "left",
          {
            backgroundColor: "#ff6347",
            color: "#fff",
            padding: "5px",
            height: "24px",
          },
          true,
        )}
      </div>
    );
  };

  return (
    <div>
      {displayErrors()}
      <table className="simple-table">
        <thead>
          {getDefaultAddTableLine(() =>
            toggleOpenFormDialogs("newParticipant"),
          )}
        </thead>
        <tbody>
          <tr className="thead">
            <td colSpan="2">
              {intl.formatMessage(messages.gb_table_text_participant)}
            </td>
            <td>{intl.formatMessage(messages.gb_table_text_job_role)}</td>
            <td style={{ width: "145px" }}>
              {intl.formatMessage(messages.gb_table_text_salary_base)}
            </td>
            <td style={{ width: "120px" }}>
              {intl.formatMessage(messages.gb_table_text_period)}
            </td>
            <td />
          </tr>
          {reorderedParticipants.map((participantInfo, index) => {
            const participantRoleBonus = participantInfo.roleBonus || {};
            const fullParticipantInfo = getAllParticipantInfo(
              participantInfo.memberParticipant.id,
              companyMembersAndParticipants,
              participantRoleBonus.id,
              goalBonusAdministration.roleBonuses,
            );

            const isParticipantAlreadyAdded =
              alreadyRelatedParticipants.indexOf(participantInfo.id) > -1;

            return (
              <tr
                className="block-hover"
                key={`participantTable${
                  participantInfo.name + String(participantInfo.id) + index
                }`}
              >
                <td
                  align="center"
                  style={
                    participantInfo.id
                      ? { width: "40px" }
                      : { width: "40px", borderLeft: "3px solid #1abd3b" }
                  }
                >
                  {index + 1}
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {fullParticipantInfo.profile &&
                    fullParticipantInfo.profile.name}
                </td>
                {roleBonusesOptions.length > 0 && (
                  <SimpleSelectExcelCollumn
                    id="participantPeriod"
                    label="roleBonusConfig"
                    value={participantInfo.roleBonus?.id}
                    options={roleBonusesOptions}
                    onChange={(e) =>
                      !isParticipantAlreadyAdded
                        ? handleUpdateRoleBonus(e.target.value, index)
                        : cantChangeParticipantRole()
                    }
                    customStyles={
                      isParticipantAlreadyAdded
                        ? { backgroundColor: "#ddd" }
                        : {}
                    }
                    defaultOption={false}
                  />
                )}
                {roleBonusesOptions.length === 0 && <td>...</td>}
                <ExcelNumberFormat
                  id="wageBaseConfig"
                  label="wageBaseConfig"
                  value={participantInfo.base}
                  align="right"
                  onChange={(e) =>
                    handleUpdateParticipant(e.target.value, "base", index)
                  }
                  textEndAdorment="$"
                />
                <SimpleSelectExcelCollumn
                  id="participantPeriod"
                  label="participantPeriod"
                  value={participantInfo.period}
                  options={participantPeriodOptions}
                  onChange={(e) =>
                    handleUpdateParticipant(e.target.value, "period", index)
                  }
                  defaultOption={false}
                />
                {getDefaultRemoveColumn(() => openDeleteParticipant(index))}
              </tr>
            );
          })}
          {getEmptyTableRow(
            goalBonusAdministration.participants,
            intl.formatMessage(messages.gb_table_text_no_participant),
          )}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { selectedTool } = getSelectedToolStates(state);

  return { selectedTool };
};

export default injectIntl(
  connect(mapStateToProps, { deleteParticipant })(ParticipantsTable),
);
