import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getValidFunc } from "../../utils/validations";

const reorder = (list, startIndex, endIndex) => {
  const toOrder = Array.from(list);
  const finalOrdered = [];

  const [removed] = toOrder.splice(startIndex, 1);
  toOrder.splice(endIndex, 0, removed);

  if (Array.isArray(toOrder)) {
    toOrder.forEach((itemInfo, index) => {
      finalOrdered.push({ ...itemInfo, order: index + 1 });
    });
  }

  return finalOrdered;
};

function DragItem({ itemInfo, index, isDragDisabled }) {
  return (
    <Draggable
      draggableId={`item_id_${itemInfo.id}`}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {itemInfo.content}
        </div>
      )}
    </Draggable>
  );
}

const ItemList = React.memo(function ItemList({ items, isDragDisabled }) {
  if (Array.isArray(items)) {
    return items.map((itemInfo, index) => (
      <DragItem
        itemInfo={itemInfo}
        isDragDisabled={isDragDisabled}
        index={index}
        key={`item_id_${itemInfo.id}`}
      />
    ));
  }

  return null;
});

function DragnDropContainer(props) {
  const handleReorder = getValidFunc(props.handleReorder);

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedItems = reorder(
      props.items,
      result.source.index,
      result.destination.index,
    );

    handleReorder(reorderedItems);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list" isDropDisabled={props.isDropDisabled}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <ItemList
              items={props.items}
              isDragDisabled={props.isDropDisabled}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

DragnDropContainer.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      order: PropTypes.number.isRequired,
      content: PropTypes.node.isRequired,
    }),
  ),
  handleReorder: PropTypes.func,
  isDropDisabled: PropTypes.bool,
};

DragnDropContainer.defaultProps = {
  items: [],
  handleReorder: () => {},
  isDropDisabled: false,
};

export default DragnDropContainer;
