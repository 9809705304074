/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import { Tabs, Tab } from "react-bootstrap";
import { injectIntl, defineMessages } from "react-intl";
import SelectFilter from "../../Common/SelectFilter";
import ToolManagementCardList from "./ToolManagementCardList";
import ActionsPlanOverview from "../../Common/ActionPlan/ActionsPlanOverview";
import ButtonGeneratePdf from "../../Common/ButtonGeneratePdf";
import logoRokau from "../../../assets/img/logo-rookau-gray.png";

import OverallPerformance from "./CustomFields/Okr/Overview/OverallPerformance";
import OkrResponsable from "./OkrResponsable";

import {
  PROCESSES_BIG_PICTURE,
  SWOT,
  PEOPLE_BIG_PICTURE,
  GOALS_AND_BONUS,
  PROJECT_PORTFOLIO,
  PROJECT_PRIORITIZATION,
  SKILLS_ASSESSMENT,
  KPIS,
  OKR_ID,
  COMPETITIVE_ANALYSIS,
  ACTION_PLAN_ID,
} from "../../../constants/tools.constants";

import ProcessesBigPicture from "./CustomFields/ProcessesBigPicture/ProcessesBigPicture";
import SwotManagement from "./CustomFields/SwotManagement/SwotManagement";
import PeopleBigPicture from "./CustomFields/PeopleBigPicture/PeopleBigPicture";
import GoalsAndBonus from "./CustomFields/GoalsAndBonus/GoalsAndBonus";
import KPI from "./CustomFields/KPI/KPI";
import ProjectManagement from "./CustomFields/ProjectManagement/ProjectManagement";
import ProjectPrioritization from "./CustomFields/ProjectPrioritization/ProjectPrioritization";
import CompAnalysisManagement from "./CustomFields/CompAnalysis/management";
import SkillsAssessment from "./CustomFields/SkillAssessment/SkillsAssessment";
import ActionPlan from "./CustomFields/ActionPlan";

import utils from "../../../utils/toolUtils";
import {
  getActionsPlanStates,
  getCompAnalysisStates,
  getSelectedToolStates,
} from "../../customMapStates";

const { getSelectsList } = utils;

const messages = defineMessages({
  circleProgressGoalPercent0: {
    id: "circleProgress.goal.percent0",
  },
  circleProgressGoalPercent25: {
    id: "circleProgress.goal.percent25",
  },
  circleProgressGoalPercent50: {
    id: "circleProgress.goal.percent50",
  },
  circleProgressGoalPercent75: {
    id: "circleProgress.goal.percent75",
  },
  circleProgressGoalPercent100: {
    id: "circleProgress.goal.percent100",
  },
  circleProgressGoalCanceled: {
    id: "circleProgress.goal.canceled",
  },
  selectCenaryLabel: {
    id: "manage.selectCenary.label",
  },
  selectPeriodLabel: {
    id: "manage.selectTheme.label",
  },
  memberOkr: {
    id: "manage.memberOkr",
  },
  priorizationPoints: {
    id: "manage.priorizationPoints",
  },
  acp_main_title: { id: "acp_main_title" },
  acp_okr_tool_table_title: { id: "acp_okr_tool_table_title" },
  acp_okr_insight_label: { id: "acp_okr_insight_label" },
  acp_okr_tool_table_head_insight_label: {
    id: "acp_okr_tool_table_head_insight_label",
  },
});

const HeaderPdf = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px 0px 20px;
  display: none;
  margin-bottom: 10px;

  @media print {
    display: block;
    -webkit-print-color-adjust: exact;
    padding: 0px;
    margin: 0px;
    margin-top: 50px;
  }
`;

const ContainerCards = styled.div`
  display: flex;
  width: 1800px;
  padding: 20px 0 0px 5px;

  &::-webkit-scrollbar {
    width: 20px !important;
    height: 20px !important;
    scroll-behavior: smooth !important;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dfe1e6;
    border-radius: 10px !important;
  }
`;

function ToolManagementView({
  intl,
  selectAndOpenInsight,
  currentToolType,
  evaluations,
  selectedTool = {},
  toolID,
  allActionsPlans,
  toolsConfigurations,
  entities,
  filterData,
  captureSelectsData,
  ...props
}) {
  const [activeTab, setActiveTab] = useState("performance");
  const { allSceneries, allThemes, allQuestions, allAnswers } = entities || {};

  const filterBySelectFilter = (evaluations) => {
    const filteredThemes =
      filterData && filterData.themes ? filterData.themes : [];

    const filteredQuestions =
      filterData && filterData.questions ? filterData.questions : [];

    let finalFiltered = [];

    function add(evaluation) {
      const filter = finalFiltered.find(
        (e) => e.questionId === evaluation.questionId,
      );
      if (!filter?.questionId) finalFiltered.push(evaluation);
    }

    if (filteredThemes && evaluations) {
      evaluations.forEach((evaluation) => {
        evaluation.answers.forEach((answer) => {
          if (
            filteredThemes.indexOf(answer.question.theme.id) > -1 &&
            filteredQuestions.includes(answer.question.id)
          ) {
            add(evaluation);
          }
        });
      });
    } else {
      finalFiltered = evaluations;
    }

    return finalFiltered;
  };

  const getFilteredQuestionsAndInsights = () => {
    const filteredQuestions = [];
    const filteredInsights = [];

    if (allQuestions && filterData.themes) {
      Object.keys(allQuestions).forEach((themeId) => {
        const themeQuestions = allQuestions[themeId];

        if (
          filterData.themes.indexOf(Number(themeId)) > -1 &&
          Array.isArray(themeQuestions)
        ) {
          themeQuestions.forEach((questionInfo) => {
            const questionInsights = getQuestionInsights(questionInfo.id);

            if (filterData.questions?.includes(questionInfo.id)) {
              filteredQuestions.push(questionInfo);
              if (Array.isArray(questionInsights))
                questionInsights.forEach((insightInfo) =>
                  filteredInsights.push(insightInfo),
                );
            }
          });
        }
      });
    }

    return { filteredQuestions, filteredInsights };
  };

  const getQuestionInsights = (questionID) => {
    return allAnswers[questionID] ? allAnswers[questionID] : [];
  };

  const getDefaulTabView = () => {
    const { filteredInsights = [] } = getFilteredQuestionsAndInsights();

    const {
      NAO_INICIADA,
      NAO_ATINGIDA,
      PARCIALMENTE_ATINGIDA,
      ATINGIDA,
      SUPERADA,
      CANCELADA,
    } = evaluations;

    const actionsPlanOverviewTitle = (
      <div>
        <i className="fas fa-list-ol" />
        &nbsp;&nbsp;
        {intl.formatMessage(messages.acp_main_title)}
      </div>
    );

    return (
      <div className="custom-primary-tabs">
        <Tabs
          id="okrOverview"
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(key)}
        >
          <Tab
            eventKey="performance"
            title={
              <div>
                <i className="fas fa-chart-pie" />{" "}
                {intl.formatMessage({ id: "overallperformance" })}
              </div>
            }
          >
            <div className="name-tab-pdf">
              <i className="fas fa-chart-pie" />{" "}
              {intl.formatMessage({ id: "overallperformance" })}
            </div>
            <OverallPerformance
              filterData={filterData}
              selectAndOpenInsight={selectAndOpenInsight}
            />
          </Tab>
          <Tab
            eventKey="responsable"
            title={
              <div>
                <i className="fas fa-users" />{" "}
                {intl.formatMessage({ id: "performance_responsable" })}
              </div>
            }
          >
            <div className="name-tab-pdf">
              <i className="fas fa-users" />{" "}
              {intl.formatMessage({ id: "performance_responsable" })}
            </div>
            <OkrResponsable filterData={filterData} />
          </Tab>
          <Tab
            eventKey="kanban"
            title={
              <div>
                <i className="fas fa-clipboard-list" /> Kanban
              </div>
            }
          >
            <div className="name-tab-pdf">
              <i className="fas fa-clipboard-list" /> Kanban
            </div>
            <ButtonGeneratePdf />
            <ContainerCards style={{ width: "100%", overflow: "auto" }}>
              <div
                style={{
                  display: "flex",
                  width: "2100px",
                  marginBottom: "20px",
                }}
              >
                <ToolManagementCardList
                  title={intl.formatMessage(
                    messages.circleProgressGoalPercent0,
                  )}
                  priorizationPointsTitle={intl.formatMessage(
                    messages.priorizationPoints,
                  )}
                  evaluations={filterBySelectFilter(NAO_INICIADA)}
                  className="nao_iniciado"
                  selectAndOpenInsight={selectAndOpenInsight}
                />
                <ToolManagementCardList
                  title={intl.formatMessage(
                    messages.circleProgressGoalPercent25,
                  )}
                  priorizationPointsTitle={intl.formatMessage(
                    messages.priorizationPoints,
                  )}
                  evaluations={filterBySelectFilter(NAO_ATINGIDA)}
                  className="nao_atingida"
                  selectAndOpenInsight={selectAndOpenInsight}
                />
                <ToolManagementCardList
                  title={intl.formatMessage(
                    messages.circleProgressGoalPercent50,
                  )}
                  priorizationPointsTitle={intl.formatMessage(
                    messages.priorizationPoints,
                  )}
                  evaluations={filterBySelectFilter(PARCIALMENTE_ATINGIDA)}
                  className="parcialmente-atingida"
                  selectAndOpenInsight={selectAndOpenInsight}
                />
                <ToolManagementCardList
                  title={intl.formatMessage(
                    messages.circleProgressGoalPercent75,
                  )}
                  priorizationPointsTitle={intl.formatMessage(
                    messages.priorizationPoints,
                  )}
                  evaluations={filterBySelectFilter(ATINGIDA)}
                  className="atingida"
                  selectAndOpenInsight={selectAndOpenInsight}
                />
                <ToolManagementCardList
                  title={intl.formatMessage(
                    messages.circleProgressGoalPercent100,
                  )}
                  priorizationPointsTitle={intl.formatMessage(
                    messages.priorizationPoints,
                  )}
                  evaluations={filterBySelectFilter(SUPERADA)}
                  className="superada"
                  selectAndOpenInsight={selectAndOpenInsight}
                />
                <ToolManagementCardList
                  title={intl.formatMessage(
                    messages.circleProgressGoalCanceled,
                  )}
                  priorizationPointsTitle={intl.formatMessage(
                    messages.priorizationPoints,
                  )}
                  evaluations={filterBySelectFilter(CANCELADA)}
                  className="cancelada"
                  selectAndOpenInsight={selectAndOpenInsight}
                />
              </div>
            </ContainerCards>
          </Tab>
          <Tab eventKey="actionsPlanOverview" title={actionsPlanOverviewTitle}>
            <ButtonGeneratePdf />
            <div className="name-tab-pdf">
              <i className="fas fa-list-ol" />
              &nbsp;&nbsp;
              {intl.formatMessage(messages.acp_main_title)}
            </div>
            <div className="tab-content" style={{ marginTop: "10px" }}>
              <ActionsPlanOverview
                allActionsPlans={allActionsPlans}
                filteredInsights={filteredInsights}
                overviewTableTitle={intl
                  .formatMessage(messages.acp_main_title)
                  .toUpperCase()}
                overviewTableInsightsLabel={intl
                  .formatMessage(messages.acp_okr_insight_label)
                  .toUpperCase()}
                insightsTableTitle={intl
                  .formatMessage(messages.acp_okr_tool_table_title)
                  .toUpperCase()}
                insightsTableInsightsLabel={intl
                  .formatMessage(messages.acp_okr_tool_table_head_insight_label)
                  .toUpperCase()}
                selectedTool={selectedTool}
                selectAndOpenInsight={selectAndOpenInsight}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    );
  };

  const handleManagementTabView = () => {
    const { filteredInsights = [] } = getFilteredQuestionsAndInsights();

    const defaultView = toolID === OKR_ID ? getDefaulTabView() : null;
    const selectedConfigs = {
      toolID,
      filterData,
      filteredInsights,
      selectAndOpenInsight,
      ...entities,
    };

    let finalView;

    switch (toolID) {
      case PROCESSES_BIG_PICTURE:
        finalView = <ProcessesBigPicture {...selectedConfigs} />;
        break;
      case PEOPLE_BIG_PICTURE:
        finalView = <PeopleBigPicture {...selectedConfigs} />;
        break;
      case SWOT:
        finalView = <SwotManagement {...selectedConfigs} />;
        break;
      case GOALS_AND_BONUS:
        finalView = <GoalsAndBonus {...selectedConfigs} />;
        break;
      case KPIS:
        finalView = <KPI {...selectedConfigs} />;
        break;
      case PROJECT_PORTFOLIO:
        finalView = <ProjectManagement {...selectedConfigs} />;
        break;
      case PROJECT_PRIORITIZATION:
        finalView = <ProjectPrioritization {...selectedConfigs} />;
        break;
      case COMPETITIVE_ANALYSIS:
        finalView = <CompAnalysisManagement {...selectedConfigs} />;
        break;
      case SKILLS_ASSESSMENT:
        finalView = <SkillsAssessment {...selectedConfigs} />;
        break;
      case ACTION_PLAN_ID:
        finalView = <ActionPlan {...selectedConfigs} />;
        break;
      default:
        finalView = defaultView;
    }

    return finalView;
  };

  const getFiltersNames = (filterData, options) => {
    if (filterData && options) {
      return options.map((option) => {
        let text = "";

        if (filterData.includes(option.value)) {
          text = option.text;
        }

        return text;
      });
    }

    return [];
  };

  const showHidden = {
    showHiddenCenarys: toolsConfigurations.showHiddenCenarys,
    showHiddenThemes: toolsConfigurations.showHiddenThemes,
    showHiddenQuestions: toolsConfigurations.showHiddenQuestions,
    showHiddenAnswers: toolsConfigurations.showHiddenAnswers,
  };

  const customConfig = {
    isOkr: toolID === OKR_ID,
    isCompetitiveAnalysis: toolID === COMPETITIVE_ANALYSIS,
    currentToolType,
  };

  let customFilterData = false;
  let showSceneriesFilter = true;
  let showSubthemeFilter = true;

  if (customConfig.isCompetitiveAnalysis) {
    showSceneriesFilter = false;
    showSubthemeFilter = false;

    customFilterData = {
      ...filterData,
      sceneries: [...allSceneries.map(({ id }) => id)],
    };
  }

  const selectList = getSelectsList(
    allSceneries,
    allThemes,
    customFilterData || filterData,
    showHidden,
    customConfig,
    allAnswers,
    allQuestions,
  );

  return (
    <div className="box-body">
      <HeaderPdf>
        <img
          src={logoRokau}
          alt="Rookau"
          style={{ width: "200px", marginBottom: "10px" }}
        />
        <div>
          <span style={{ fontWeight: "500" }}>Workspace</span>
          {props.selectedWorkspace && ` ${props.selectedWorkspace.name}`}
        </div>
        <div>
          <span style={{ fontWeight: "500" }}>Exibindo cenários</span>
          {getFiltersNames(filterData.sceneries, selectList.sceneries).map(
            (cenary) => (
              <span key={`${cenary}-${uuidv4()}`}> {`${cenary}","`}</span>
            ),
          )}
        </div>
        <div>
          <span style={{ fontWeight: "500" }}>Exibindo temas</span>
          {getFiltersNames(filterData.themes, selectList.themes).map(
            (theme) => {
              return <span key={`${theme}-${uuidv4()}`}> {`${theme}","`}</span>;
            },
          )}
        </div>
      </HeaderPdf>
      <SelectFilter
        hiddenFilter={toolID === ACTION_PLAN_ID}
        selectList={selectList}
        showSceneriesSelect={showSceneriesFilter}
        showSubthemeSelect={showSubthemeFilter}
        captureSelectsData={captureSelectsData}
        filterData={filterData}
      />
      {handleManagementTabView()}
    </div>
  );
}

function areEqual(prevProps, nextProps) {
  if (prevProps.open !== nextProps.open) return false;
  if (prevProps.showHiddenExpectation !== nextProps.showHiddenExpectation)
    return false;
  if (!_.isEqual(prevProps.allActionsPlans, nextProps.allActionsPlans))
    return false;
  if (!_.isEqual(prevProps.entities, nextProps.entities)) return false;
  if (!_.isEqual(prevProps.filterData, nextProps.filterData)) return false;

  return true;
}

const mapStateToProps = (state) => {
  const { toolsConfigurations = {} } = state;
  const { currentToolType = "" } = getCompAnalysisStates(state);
  const { selectedTool = {} } = getSelectedToolStates(state);
  const { allActionsPlans = [] } = getActionsPlanStates(state);

  return {
    selectedWorkspace: state.workspacesData.selectedWorkspace,
    currentToolType,
    toolsConfigurations,
    selectedTool,
    allActionsPlans,
  };
};

export default injectIntl(
  React.memo(connect(mapStateToProps)(ToolManagementView), areEqual),
);
