import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import _ from "lodash";

import FormDialog from "../../FormDialog";
import MaterialTextField from "../../MaterialTextField";
import SelectMemberModal from "../../SelectMemberModal";
import {
  getUserProfileStates,
  getSelectedCompanyStates,
} from "../../../customMapStates";
import { fetchAllUsersCompanyProfiles } from "../../../../actions/userActions";
import {
  getObjectInfoById,
  getOnlySlugValues,
} from "../../../../utils/ArrayUtils";
import { getParticipantOptions } from "../../../../utils/goalsBonus/filter";

const messages = defineMessages({
  gb_table_text_participants: { id: "gb_table_text_participants" },
  gb_dialog_title_new_participant: { id: "gb_dialog_title_new_participant" },
  gb_dialog_title_select_member: { id: "gb_dialog_title_select_member" },
  global_selectSome: { id: "global.selectSome" },
  global_goBack: { id: "global.goBack" },
});

const defaultParticipant = {
  id: null,
  base: 0,
  period: "MONTH_PERIOD_12",
  roleBonus: {
    id: null,
  },
  memberParticipant: {
    id: null,
    type: "MEMBER",
  },
};

const NewMultipleParticipants = (props) => {
  const [replaceSelectedMembers, setReplaceSelectedMembers] = useState(false);
  const [memberModalOpen, setMemberModalOpen] = useState(false);
  const [selectMemberOptions, setSelectMemberOptions] = useState([]);
  const [newParticipants, setNewParticipants] = useState([]);

  const {
    open = false,
    afterCancel,
    afterConfirm,
    companyMembersAndParticipants = [],
    allQuestions = {},
    goalBonusAdministration = {},
    selectedCompanyProfessionalProfiles = [],
    selectedCompany = {},
    getToolConfig,
    intl,
  } = props;

  const selectedCompanyId = selectedCompany.id ? selectedCompany.id : null;
  const { participants = [], roleBonuses = [] } = goalBonusAdministration;

  useEffect(() => {
    if (selectedCompanyId) {
      props.fetchAllUsersCompanyProfiles(selectedCompanyId);
    }
  }, [selectedCompanyId]);

  useEffect(() => {
    if (allQuestions && Array.isArray(companyMembersAndParticipants)) {
      const participantOptions = getParticipantOptions(participants);
      const onlyAddedMembersId = getOnlySlugValues(
        participantOptions,
        "memberId",
      );

      const newSelectMemberOptions = [];

      companyMembersAndParticipants.forEach((memberInfo) => {
        if (onlyAddedMembersId.indexOf(memberInfo.id) === -1)
          newSelectMemberOptions.push(memberInfo);
      });

      if (!_.isEqual(newSelectMemberOptions, selectMemberOptions))
        setSelectMemberOptions(newSelectMemberOptions);
    }
  }, [allQuestions, companyMembersAndParticipants, participants]);

  const resetForm = () => {
    setReplaceSelectedMembers(true);
  };

  const handleAddParticipants = (currentParticipants, selectedParticipants) => {
    let addParticipants = [];

    if (Array.isArray(selectedParticipants)) {
      selectedParticipants.forEach((participantInfo) => {
        const {
          companyPositionId = null,
        } = getUserRoleBonusFromCompanyPosition(participantInfo);
        const appendRole = getRoleBonusByCompanyPosition(companyPositionId);

        addParticipants.push({
          ...defaultParticipant,
          memberParticipant: {
            id: participantInfo.id,
            type: participantInfo.type,
          },
          ...appendRole,
        });
      });
    }

    return [...currentParticipants, ...addParticipants];
  };

  const handleSave = async () => {
    const data = await getToolConfig();

    if (data && data.id && newParticipants.length > 0) {
      const submitGoalsBonusAdm = {
        ...data,
        participants: handleAddParticipants(data.participants, newParticipants),
      };

      if (typeof afterConfirm === "function") afterConfirm(submitGoalsBonusAdm);
    }

    resetForm();
  };

  const handleCancel = () => {
    resetForm();

    if (typeof afterCancel === "function") afterCancel();
  };

  const getUserRoleBonusFromCompanyPosition = (newSelectedMember = {}) => {
    let foundRoleBonus = {};

    const { companyPosition: userCompanyPosition = {} } = getObjectInfoById(
      newSelectedMember.id,
      selectedCompanyProfessionalProfiles,
      "jhiUserId",
    );

    if (Array.isArray(roleBonuses)) {
      roleBonuses.forEach((roleBonusInfo) => {
        if (
          roleBonusInfo.companyPositionId &&
          userCompanyPosition &&
          userCompanyPosition.id === roleBonusInfo.companyPositionId
        ) {
          foundRoleBonus = roleBonusInfo;
        }
      });
    }

    return foundRoleBonus;
  };

  const getRoleBonusByCompanyPosition = (companyPositionId) => {
    let returnRoleBonus = {};

    if (companyPositionId) {
      for (const roleBonusInfo of roleBonuses) {
        if (roleBonusInfo.companyPositionId === companyPositionId) {
          returnRoleBonus = { roleBonus: { id: roleBonusInfo.id } };
          break;
        }
      }
    }

    return returnRoleBonus;
  };

  const updateSelectedMembers = (selectedList) => {
    setNewParticipants(selectedList);
    setMemberModalOpen(false);
  };

  const getMembersDisplayName = (participants) => {
    let displayTitles = [];

    participants.forEach((userInfo) => {
      if (userInfo.name) displayTitles.push(userInfo.name);
    });

    return displayTitles.length > 0
      ? displayTitles.join(", ")
      : intl.formatMessage(messages.global_selectSome);
  };

  return (
    <>
      {memberModalOpen && (
        <SelectMemberModal
          title={intl.formatMessage(messages.gb_dialog_title_select_member)}
          membersList={selectMemberOptions}
          onConfirm={(selected, selectedList) =>
            updateSelectedMembers(selectedList)
          }
          onCancel={() => setMemberModalOpen(false)}
          open={memberModalOpen}
          cancelText={intl.formatMessage(messages.global_goBack)}
          replaceSelected={replaceSelectedMembers}
          initialSelected={newParticipants}
          replaceCallback={() => setReplaceSelectedMembers(false)}
        />
      )}
      <FormDialog
        open={open}
        title={intl.formatMessage(messages.gb_dialog_title_new_participant)}
        onConfirm={handleSave}
        onCancel={handleCancel}
        blockConfirm={newParticipants.length === 0}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialTextField
              id="participantMember"
              label={intl.formatMessage(messages.gb_table_text_participants)}
              value={getMembersDisplayName(newParticipants)}
              inputStyle={{ cursor: "pointer" }}
              onClick={() => setMemberModalOpen(true)}
            />
          </div>
        </div>
      </FormDialog>
    </>
  );
};

const mapStateToProps = (state) => {
  const { selectedCompany = {} } = getSelectedCompanyStates(state);
  const { selectedCompanyProfessionalProfiles = {} } = getUserProfileStates(
    state,
  );

  return {
    selectedCompany,
    selectedCompanyProfessionalProfiles,
  };
};

export default injectIntl(
  connect(mapStateToProps, { fetchAllUsersCompanyProfiles })(
    NewMultipleParticipants,
  ),
);
