/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";

import { Fab, CircularProgress } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

import { defaultConfirmDialog, defaultEditingForm } from "./defaults";
import TabsContainer from "./TabsContainer";
import DisplayOpenForms from "./administration/DisplayOpenForms";
import ToolConfirmationDialog from "./forms/ToolConfirmationDialog";

import {
  getSelectedToolStates,
  getSkillAssessmentStates,
} from "../../../../../customMapStates";
import {
  saveAdministrationChangesWithPersistence,
  fetchSkillAssessmentConfig,
} from "../../../../../../actions/skillAssessmentActions";
import { toggleSlugOnArray } from "../../../../../../utils/ArrayUtils";
import {
  persistToolAdministration,
  filterOutNestedEntities,
  getAdministrationChangedKeys,
} from "../../../../../../utils/skillsAssessment/entityAutomation";
import { getGeneralValidations } from "../../../../../../utils/skillsAssessment/validation";

import { useAdministrationStyles } from "../../../../../../utils/administration/styles";
import {
  useCompareChanges,
  useErrors,
} from "../../../../../../utils/administration/hooks";
import { useHistoryToolAdministration } from "../../../../../../utils/skillsAssessment/hooks/administration";

const CACHE_SECONDS = 30;

const Administration = ({
  selectedTool,
  allSkillAssessment,
  lastFetch,
  isLoading,
  fetchSkillAssessmentConfig,
  saveAdministrationChangesWithPersistence,
  ...props
}) => {
  const { toolHistory, toolAdministration, setAdministration } =
    useHistoryToolAdministration({
      selectedTool,
      allSkillAssessment,
      isLoading,
    });
  const [editingForm, setEditingForm] = useState(defaultEditingForm);
  const [confirmDialog, setConfirmDialog] = useState(defaultConfirmDialog);
  const [openFormDialogs, setOpenFormDialogs] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);

  const { haveChanges } = useCompareChanges({
    toolAdministration,
    toolHistory,
    isLoading,
  });

  const { errors, haveErrors } = useErrors({
    toolAdministration,
    validate: getGeneralValidations,
  });

  useEffect(() => {
    const ignoreCache =
      !lastFetch ||
      moment(Date.now()).diff(lastFetch, "seconds") >= CACHE_SECONDS;

    if (selectedTool.id && ignoreCache)
      fetchSkillAssessmentConfig(selectedTool.id);
  }, []);

  const handleSubmitAdminConfig = async (newAdministration) => {
    if (selectedTool && selectedTool.id && !isLoading && !saveLoading) {
      try {
        setSaveLoading(true);

        const saveRestAdministration =
          persistToolAdministration(newAdministration);

        await saveAdministrationChangesWithPersistence({
          selected_tool_id: selectedTool.id,
          changedKeys: getAdministrationChangedKeys(
            saveRestAdministration,
            filterOutNestedEntities(toolHistory),
          ),
        });
      } catch (err) {
        console.error(err);
      }

      fetchSkillAssessmentConfig(selectedTool.id);

      setSaveLoading(false);
    }
  };

  const updateAdministration = (newAdministration) => {
    if (newAdministration) {
      setAdministration(newAdministration);
    }
  };

  const updateAdministrationSingleField = (fieldSlug, newValue) => {
    if (fieldSlug)
      updateAdministration({ ...toolAdministration, [fieldSlug]: newValue });
  };

  const handleAutoSave = (newAdministration) => {
    if (newAdministration) handleSubmitAdminConfig(newAdministration, true);
  };

  const resetConfirmDialog = () => {
    setConfirmDialog({ ...defaultConfirmDialog });
  };

  const updateConfirmationDialog = (newDialogConfig) => {
    if (!_.isEqual(confirmDialog, newDialogConfig)) {
      const nDialogConfig = !newDialogConfig.onCancel
        ? { ...newDialogConfig, onCancel: resetConfirmDialog }
        : newDialogConfig;

      setConfirmDialog(nDialogConfig);
    }
  };

  const resetEditingDialog = () => {
    setEditingForm({ ...defaultEditingForm });
  };

  const showErrorsWarning = () => {
    updateConfirmationDialog({
      open: true,
      title:
        "Você precisa corrigir todos os erros antes de salvar as alterações.",
      confirmText: "Vou corrigir",
      onConfirm: resetConfirmDialog,
      hideCancel: true,
    });
  };

  const toggleOpenForms = (slug, toggleTo = null) => {
    const updated = toggleSlugOnArray(openFormDialogs, slug, toggleTo);

    if (!_.isEqual(updated, openFormDialogs)) setOpenFormDialogs(updated);
  };

  const closeFormAndCallback = (slug, callback) => {
    toggleOpenForms(slug, false);

    if (typeof callback === "function") callback();
  };

  const handleSetEditingForm = (type, params = {}) => {
    setEditingForm({
      type,
      params,
    });

    toggleOpenForms(type);
  };

  const { allSceneries, allThemes, allQuestions, allAnswers } = props;

  const loading = isLoading || saveLoading;

  const mainProps = {
    toolAdministration,
    updateAdministration,
    updateAdministrationSingleField,
    toggleOpenForms,
    setConfirmationDialog: updateConfirmationDialog,
    resetConfirmDialog,
    handleSetEditingForm,
    resetEditingDialog,
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
    errors,
    selectedTool,
    handleAutoSave,
    isLoading: loading,
    setSaveLoading,
  };

  const formProps = {
    openFormDialogs,
    closeFormAndCallback,
    editingForm,
  };

  const classes = useAdministrationStyles();

  return (
    <div className="row" style={{ paddingBottom: "100px" }}>
      <Fab
        className={!haveErrors ? classes.saveButton : classes.errorButton}
        disabled={!haveChanges || loading}
        onClick={
          !haveErrors
            ? () => handleSubmitAdminConfig(toolAdministration)
            : () => showErrorsWarning()
        }
      >
        {loading && <CircularProgress />}
        {!loading && <CheckIcon />}
      </Fab>
      <ToolConfirmationDialog confirmDialog={confirmDialog} />
      <DisplayOpenForms {...mainProps} {...formProps} />
      <TabsContainer mainProps={mainProps} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    selectedTool,
    allSceneries = [],
    allThemes = {},
    allQuestions = {},
    allAnswers = {},
  } = getSelectedToolStates(state, {
    sceneries: false,
    themes: false,
    questions: false,
    answers: true,
  });
  const { allSkillAssessment, isLoading, lastFetch } =
    getSkillAssessmentStates(state);

  return {
    selectedTool,
    isLoading,
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
    allSkillAssessment,
    lastFetch,
  };
};

Administration.propTypes = {
  selectedTool: PropTypes.object.isRequired,
  allSkillAssessment: PropTypes.array,
  saveAdministrationChangesWithPersistence: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  allSceneries: PropTypes.array,
  allThemes: PropTypes.object,
  allQuestions: PropTypes.object,
  allAnswers: PropTypes.object,
};

Administration.defaultProps = {
  allSkillAssessment: [],
  isLoading: false,
  allSceneries: [],
  allThemes: {},
  allQuestions: {},
  allAnswers: {},
};

export default connect(mapStateToProps, {
  fetchSkillAssessmentConfig,
  saveAdministrationChangesWithPersistence,
})(Administration);
