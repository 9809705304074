import {
  CHANGE_SHOW_HIDDEN_ANSWERS,
  CHANGE_SHOW_HIDDEN_THEMES,
  CHANGE_SHOW_HIDDEN_CENARYS,
  CHANGE_SHOW_INSIGHT_RESUME,
  CHANGE_SHOW_HIDDEN_QUESTIONS,
  REAL_INSIGHT_SCORE,
  CHANGE_STATUS_TEMPLATE,
  CLEAN_ALL_LOCAL_STORAGE,
  CLEAN_HIDDEN_STORAGE,
  CHANGE_STATUS_INSIGHT,
} from "../actions/types";

const TRUE_STRING = "true";
const SHOW_HIDDEN_CENARYS = "SHOW_HIDDEN_CENARYS";
const SHOW_HIDDEN_THEMES = "SHOW_HIDDEN_THEMES";
const SHOW_HIDDEN_QUESTIONS = "SHOW_HIDDEN_QUESTIONS";
const SHOW_HIDDEN_ANSWERS = "SHOW_HIDDEN_ANSWERS";
const SHOW_HIDDEN_EXPECTATION = "SHOW_HIDDEN_EXPECTATION";

const showHiddenCenarys = localStorage.getItem(SHOW_HIDDEN_CENARYS);
const showHiddenThemes = localStorage.getItem(SHOW_HIDDEN_THEMES);
const showHiddenQuestions = localStorage.getItem(SHOW_HIDDEN_QUESTIONS);
const showHiddenAnswers = localStorage.getItem(SHOW_HIDDEN_ANSWERS);
const realInsightScore = localStorage.getItem(REAL_INSIGHT_SCORE);
const statusTemplate = localStorage.getItem(CHANGE_STATUS_TEMPLATE);
const showHiddenExpectation = localStorage.getItem(SHOW_HIDDEN_EXPECTATION);

const INITIAL_STATE = {
  showHiddenCenarys: showHiddenCenarys
    ? showHiddenCenarys === TRUE_STRING
    : false,
  showHiddenThemes: showHiddenThemes ? showHiddenThemes === TRUE_STRING : false,
  showHiddenAnswers: showHiddenAnswers
    ? showHiddenAnswers === TRUE_STRING
    : false,
  showHiddenQuestions: showHiddenQuestions
    ? showHiddenQuestions === TRUE_STRING
    : false,
  realInsightScore: realInsightScore ? realInsightScore === TRUE_STRING : false,
  statusTemplate,
  showHiddenExpectation: showHiddenExpectation
    ? showHiddenExpectation === TRUE_STRING
    : false,
};

const resetHiddenStorage = () => {
  localStorage.setItem(SHOW_HIDDEN_ANSWERS, false);
  localStorage.setItem(SHOW_HIDDEN_QUESTIONS, false);
  localStorage.setItem(SHOW_HIDDEN_THEMES, false);
  localStorage.setItem(SHOW_HIDDEN_CENARYS, false);
};

const resetLocalStorage = () => {
  localStorage.setItem(CHANGE_SHOW_INSIGHT_RESUME, false);
  localStorage.setItem(REAL_INSIGHT_SCORE, false);
  localStorage.setItem(CHANGE_STATUS_TEMPLATE, false);
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_SHOW_HIDDEN_ANSWERS:
      localStorage.setItem(SHOW_HIDDEN_ANSWERS, action.payload);
      return {
        ...state,
        showHiddenAnswers: action.payload,
      };
    case CHANGE_SHOW_HIDDEN_QUESTIONS:
      localStorage.setItem(SHOW_HIDDEN_QUESTIONS, action.payload);
      return {
        ...state,
        showHiddenQuestions: action.payload,
      };
    case CHANGE_SHOW_HIDDEN_THEMES:
      localStorage.setItem(SHOW_HIDDEN_THEMES, action.payload);
      return {
        ...state,
        showHiddenThemes: action.payload,
      };
    case CHANGE_SHOW_HIDDEN_CENARYS:
      localStorage.setItem(SHOW_HIDDEN_CENARYS, action.payload);
      return {
        ...state,
        showHiddenCenarys: action.payload,
      };
    case CHANGE_SHOW_INSIGHT_RESUME:
      localStorage.setItem(CHANGE_SHOW_INSIGHT_RESUME, action.payload);
      return {
        ...state,
        showInsightResume: action.payload,
      };
    case REAL_INSIGHT_SCORE:
      localStorage.setItem(REAL_INSIGHT_SCORE, action.payload);
      return {
        ...state,
        realInsightScore: action.payload,
      };

    case CHANGE_STATUS_TEMPLATE:
      localStorage.setItem(CHANGE_STATUS_TEMPLATE, action.payload);
      return {
        ...state,
        statusTemplate: action.payload,
      };
    case CLEAN_ALL_LOCAL_STORAGE:
      resetLocalStorage();
      return {
        ...state,
        showInsightResume: false,
        realInsightScore: false,
        statusTemplate: null,
      };
    case CLEAN_HIDDEN_STORAGE:
      resetHiddenStorage();
      return {
        ...state,
        showHiddenAnswers: false,
        showHiddenQuestions: false,
        showHiddenThemes: false,
        showHiddenCenarys: false,
      };
    case CHANGE_STATUS_INSIGHT:
      localStorage.setItem(SHOW_HIDDEN_EXPECTATION, action.payload);
      return {
        ...state,
        showHiddenExpectation: action.payload,
      };
    default:
      return state;
  }
};
