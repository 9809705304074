import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";

import { Link } from "react-router-dom";
import MaterialTextField from "../../../Common/MaterialTextField";
import MaterialSingleSelect from "../../../Common/MaterialSingleSelect";
import MaterialMultiSelect from "../../../Common/MaterialMultiSelect";
import SelectMemberModal from "../../../Common/SelectMemberModal";

import {
  getGenderOptions,
  getLanguageOptions,
  getEducationOptions,
} from "../../../../constants/peopleEvaluation";

import utils from "../../../../utils/toolUtils";
import { checkAccess } from "../../../../utils/accessLevels";
import { getUserCompanyProfiles } from "../../../../actions/userActions";

const { getProfessionalInfo } = utils;

const messages = defineMessages({
  globalSave: {
    id: "global.save",
  },
  prof_profile: {
    id: "pb_professional_profile",
  },
  form_full_name: {
    id: "pb_form_full_name",
  },
  form_birth_date: {
    id: "pb_form_birth_date",
  },
  form_gender: {
    id: "pb_form_gender",
  },
  form_entry_date: {
    id: "pb_form_entry_date",
  },
  form_area: {
    id: "pb_form_area",
  },
  form_no_area: {
    id: "pb_form_no_area",
  },
  form_area_details: {
    id: "pb_form_area_details",
  },
  form_role: {
    id: "pb_form_role",
  },
  form_no_role: {
    id: "pb_form_no_role",
  },
  form_role_details: {
    id: "pb_form_role_details",
  },
  form_education: {
    id: "pb_form_education",
  },
  form_education_details: {
    id: "pb_form_education_details",
  },
  form_fluent_languages: {
    id: "pb_form_fluent_languages",
  },
  form_select_member: {
    id: "pb_form_select_member",
  },
  form_change_member: {
    id: "pb_form_change_member",
  },
  change_career_history: {
    id: "pb_change_career_history",
  },
  select_member_participant: {
    id: "pb_select_member_participant",
  },
  pb_warning_change_member_profile_at: {
    id: "pb_warning_change_member_profile_at",
  },
  pb_warning_complement_company_details: {
    id: "pb_warning_complement_company_details",
  },
  pb_warning_cant_change_area_or_position: {
    id: "pb_warning_cant_change_area_or_position",
  },
});

const ProfessionalProfileField = ({
  professionalProfile,
  setProfessionalProfile,
  userProfile,
  setUserProfile,
  saveButton,
  onSave,
  companyMembersAndParticipants,
  setMemberId,
  selectedCompany,
  intl,
}) => {
  const [memberModalOpen, setMemberModalOpen] = useState(false);

  const { professional } = professionalProfile;
  const { companyArea = null, companyPosition = null } = userProfile;

  const profileMemberInfo = getProfessionalInfo(professional);
  const profileMemberId =
    profileMemberInfo && profileMemberInfo.id ? profileMemberInfo.id : null;

  // Select options constants
  const genderOptions = getGenderOptions(intl);
  const languageOptions = getLanguageOptions(intl);
  const educationOptions = getEducationOptions(intl);
  // End Select options contants

  useEffect(() => {
    async function getUserProfile() {
      const restUserProfile = await getUserCompanyProfiles(
        profileMemberId,
        selectedCompany.id,
      );

      if (restUserProfile && restUserProfile.data && restUserProfile.data.id)
        setUserProfile(restUserProfile.data);
    }

    if (profileMemberId && selectedCompany.id) getUserProfile();
  }, [profileMemberId, selectedCompany.id, setUserProfile]);

  const buildLanguageSelectOptions = () => {
    let finalList = [];

    languageOptions.forEach((info) => {
      finalList = [...finalList, { value: info.value, text: info.label }];
    });

    return finalList;
  };

  const handleSelectMember = (memberID) => {
    setMemberModalOpen(false);

    if (memberID) setMemberId(memberID);
  };

  const languageOptionsMultiSelect = buildLanguageSelectOptions();

  return (
    <div className="box box-primary">
      <div className="box-header with-border">
        <h3 className="box-title">
          <i className="far fa-address-card" />
          {`  ${intl.formatMessage(messages.prof_profile)}`}
        </h3>
        {saveButton && (
          <div className="pull-right">
            <button className="btn btn-purple" onClick={(e) => onSave(e)}>
              {intl.formatMessage(messages.globalSave)}
            </button>
          </div>
        )}
      </div>
      <div className="box-body">
        <SelectMemberModal
          open={memberModalOpen}
          title={intl.formatMessage(messages.select_member_participant)}
          toggleOpen={() => setMemberModalOpen(!memberModalOpen)}
          onConfirm={(selected) => handleSelectMember(selected[0])}
          membersList={companyMembersAndParticipants || []}
          initialSelected={profileMemberInfo.id ? [profileMemberInfo.id] : []}
          singleSelect
        />
        <div className="col-xs-12" style={{ padding: "0px" }}>
          <div className="row">
            <div className="col-xl-4 col-lg-12">
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  checkAccess([
                    "CREATOR",
                    "RESPONSIBLE",
                    "COLLABORATOR",
                    "MANAGER",
                    "LEADER",
                    "MODERATOR",
                    "ADMIN",
                    "OWNER",
                  ]) && setMemberModalOpen(true)
                }
              >
                <MaterialTextField
                  id="name"
                  label={intl.formatMessage(messages.form_full_name)}
                  value={professionalProfile.name}
                />
              </div>
              <div align="right">
                <a
                  onClick={() => setMemberModalOpen(true)}
                  style={{ cursor: "pointer" }}
                >
                  {profileMemberInfo.id
                    ? intl.formatMessage(messages.form_change_member)
                    : intl.formatMessage(messages.form_select_member)}
                </a>
              </div>
            </div>
            <div className=" col-xl-8 col-lg-12">
              <div className="row">
                <div
                  className="col-md-4 col-sm-12 box-profile-field"
                  style={{ paddingLeft: "0px" }}
                >
                  <MaterialTextField
                    id="birthDate"
                    label={intl.formatMessage(messages.form_birth_date)}
                    value={professionalProfile.birthDate}
                    type="date"
                    onChange={(e) =>
                      setProfessionalProfile({
                        ...professionalProfile,
                        birthDate: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-md-4 col-sm-12 box-profile-field">
                  <MaterialSingleSelect
                    id="gender"
                    label={intl.formatMessage(messages.form_gender)}
                    value={professionalProfile.gender}
                    onChange={(e) =>
                      setProfessionalProfile({
                        ...professionalProfile,
                        gender: e.target.value,
                      })
                    }
                    options={genderOptions}
                  />
                </div>
                <div
                  className="col-md-4 col-sm-12 box-profile-field"
                  style={{ paddingRight: "0px" }}
                >
                  <MaterialTextField
                    id="entryDate"
                    label={intl.formatMessage(messages.form_entry_date)}
                    type="date"
                    value={professionalProfile.entryDate}
                    onChange={(e) =>
                      setProfessionalProfile({
                        ...professionalProfile,
                        entryDate: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6" style={{ cursor: "pointer" }}>
              <MaterialTextField
                id="area"
                label={intl.formatMessage(messages.form_area)}
                value={
                  companyArea
                    ? companyArea.title
                    : intl.formatMessage(messages.form_no_area)
                }
                onChange={() => {}}
                inputStyle={{ backgroundColor: "#f1f1f1" }}
              />
            </div>
            <div className="col-xs-6">
              <MaterialTextField
                id="role"
                label={intl.formatMessage(messages.form_role)}
                value={
                  companyPosition
                    ? companyPosition.title
                    : intl.formatMessage(messages.form_no_role)
                }
                onChange={() => {}}
                inputStyle={{ backgroundColor: "#f1f1f1" }}
              />
            </div>
            {checkAccess(["MANAGER", "LEADER"]) && !checkAccess(["ADMIN"]) && (
              <div align="center">
                {intl.formatMessage(
                  messages.pb_warning_cant_change_area_or_position,
                )}
              </div>
            )}
            {checkAccess(["ADMIN"]) && (
              <div align="center">
                {intl.formatMessage(
                  messages.pb_warning_change_member_profile_at,
                )}{" "}
                <Link to="/company" target="_blank">
                  {intl.formatMessage(
                    messages.pb_warning_complement_company_details,
                  )}
                </Link>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-xs-6">
              <MaterialSingleSelect
                id="education"
                label={intl.formatMessage(messages.form_education)}
                value={professionalProfile.education.level}
                onChange={(e) =>
                  setProfessionalProfile({
                    ...professionalProfile,
                    education: {
                      ...professionalProfile.education,
                      level: e.target.value,
                    },
                  })
                }
                options={educationOptions}
              />
            </div>
            <div className="col-xs-6">
              <MaterialTextField
                id="educationDetails"
                label={intl.formatMessage(messages.form_education_details)}
                multiline
                value={professionalProfile.education.details}
                onChange={(e) =>
                  setProfessionalProfile({
                    ...professionalProfile,
                    education: {
                      ...professionalProfile.education,
                      details: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6">
              <MaterialMultiSelect
                id="fluentLanguages"
                label={intl.formatMessage(messages.form_fluent_languages)}
                options={languageOptionsMultiSelect}
                initialOptions={professionalProfile.fluentLanguages}
                getData={(languages) =>
                  setProfessionalProfile({
                    ...professionalProfile,
                    fluentLanguages: languages,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProfessionalProfileField.propTypes = {
  saveButton: PropTypes.bool,
  onSave: PropTypes.func,
};

export default injectIntl(ProfessionalProfileField);
