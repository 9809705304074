import React from "react";
import PropTypes from "prop-types";
import TableRoleSkillRelation from "../tables/TableRoleSkillRelation";
import { getStyledErrorMessage } from "../../../../../../../utils/formValidation";

const TabRoleSkillRelation = (props) => {
  const { toolAdministration, updateAdministration, errors } = props;

  return (
    <div className="tab-content" style={{ margin: "0px" }}>
      <div className="row">
        <div className="col-xs-12">
          <h4>Relação de Avaliação Desejada (Cargos x Competências)</h4>
        </div>
        <div className="col-xs-12 col-lg-8">
          <div className="row">
            <TableRoleSkillRelation
              toolAdministration={toolAdministration}
              updateAdministration={updateAdministration}
            />
            {getStyledErrorMessage(
              ["adicionar_erros_de_automatizacao"],
              errors,
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

TabRoleSkillRelation.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
  updateAdministration: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
};

export default TabRoleSkillRelation;
