import accents from "remove-accents";

export const hasWhiteSpaces = (str) => {
  return /\s/g.test(str);
};

export const stringToSlug = (str, separator = "-") => {
  if (!str) return "";

  let displayStr = str.replace(/^\s+|\s+$/g, ""); // trim
  displayStr = displayStr.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to = "aaaaaaeeeeiiiioooouuuunc------";

  for (let i = 0, l = from.length; i < l; i++) {
    displayStr = displayStr.replace(
      new RegExp(from.charAt(i), "g"),
      to.charAt(i),
    );
  }

  displayStr = displayStr
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, separator) // collapse whitespace and replace by -
    .replace(/-+/g, separator) // collapse dashes
    .replace(/^-+/, "") // trim - from start of text
    .replace(/-+$/, ""); // trim - from end of text

  return displayStr;
};

export const getComparableQuery = (
  str,
  slugify = false,
  replaceSpaces = true,
) => {
  const cleanedString = accents.remove(str).toUpperCase();
  const cleanedStringPt2 = cleanedString.replace("º", "");
  const cleanedStringPt3 = replaceSpaces
    ? cleanedStringPt2.replace(/ /g, "")
    : cleanedStringPt2;

  if (str && cleanedStringPt3)
    return slugify ? stringToSlug(cleanedStringPt3) : cleanedStringPt3;

  return "";
};

export const countDecimals = (value = 0, splitBy = ".") => {
  if (Math.floor(value) === value || !value) return 0;

  const splitPiece = value.toString().split(splitBy);

  return splitPiece[1] ? splitPiece[1].length : 0;
};

export const monetaryValue = (
  stringValue = 0,
  style = "currency" || "decimal",
  locale = "pt-br",
  currency = "BRL",
  fix = 2 || "auto",
  maxFix = 5,
) => {
  const decimals = countDecimals(stringValue);

  const maxDecimals = decimals > maxFix ? maxFix : decimals;
  const vFix = fix === "auto" ? maxDecimals : fix;

  if (stringValue || stringValue === 0 || typeof stringValue === "number") {
    const numValue = Number(stringValue);

    return numValue.toLocaleString(locale, {
      minimumFractionDigits: vFix,
      maximumFractionDigits: maxFix,
      style,
      currency,
    });
  }

  return "";
};

export const formatWithDecimals = (
  stringValue = 0,
  fix = "auto",
  maxFix = 5,
  minFix = false,
) => {
  const decimals = countDecimals(stringValue);
  const displayStr = Number(stringValue);

  const maxDecimals = decimals > maxFix ? maxFix : decimals;

  let vFix = fix === "auto" ? maxDecimals : fix;
  vFix = vFix < minFix ? minFix : vFix;

  return displayStr.toFixed(vFix);
};

export const getMaxStringLength = ({
  string = "",
  beginIndex = 0,
  endIndex = 100,
  additional = "",
}) => {
  if (string && string.length) {
    const formatedString = string.substring(beginIndex, endIndex);
    const customString =
      string.length > endIndex ? formatedString + additional : formatedString;

    return customString;
  }
  return "";
};

export const getNameInitials = (fullName, endIndex = 2, beginIndex = 0) => {
  const string = fullName
    ?.split(" ")
    .map((n) => n[0])
    .join("");

  const objectName = {
    string,
    beginIndex,
    endIndex,
  };

  return getMaxStringLength(objectName).toUpperCase();
};

export const stringShorter = (string, maxLength) => {
  return string?.length > maxLength
    ? `${string.substr(0, maxLength)}...`
    : string;
};
