import React from "react";
import { injectIntl, defineMessages } from "react-intl";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import utils from "../../../utils/toolUtils";
import { getAvatar } from "../../../actions/profileActions";

import { globalMessages } from "../../../utils/global";

import CommentEditor from "../../Common/CommentEditor";

const { DisplayComments, getTimelineRelationDisplay } = utils;

const messages = defineMessages({
  commentTextareaPlaceholder: {
    id: "timeline.comment.textarea.placeholder",
  },
  commentSubmit: {
    id: "timeline.comment.submit",
  },
  defaultComment: {
    id: "default_comment",
  },
});

const InsightTimelineView = (props) => {
  const {
    content = [],
    useBox,
    addComment,
    intl,
    userId,
    editCommentaryOnInsightTimeline,
    mentionedPeople,
  } = props;

  const saveEditedComment = (newCommentText, commentContent) => {
    if (commentContent.insightId && newCommentText)
      editCommentaryOnInsightTimeline(
        { ...commentContent, text: newCommentText, type: "timeline_item" },
        commentContent.insightId,
      );
  };

  const modules = {
    toolbar: {
      container: "#toolbar",
      handlers: {},
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const createComments = (content = []) => {
    const timelineComments = getTimelineRelationDisplay(userId, content);

    return (
      <DisplayComments
        timelineComments={timelineComments}
        saveEditedComment={saveEditedComment}
        timelineDisplay={false}
      />
    );
  };

  if (useBox) {
    return (
      <div className="box box-primary box-timeline">
        <div className="box-header with-border">
          <i className="far fa-comments" />
          <h3 className="box-title">
            {intl.formatMessage(globalMessages.comments)}
          </h3>
          <div className="box-tools pull-right">
            <span className="badge">{content && content.length}</span>
          </div>
        </div>
        <div>
          <CommentEditor
            onSave={addComment}
            modules={modules}
            text=""
            container="#toolbar"
            maxLength={400}
          />
        </div>
        <div className="box-comments">{createComments(content)}</div>
      </div>
    );
  }

  return (
    <div style={{ padding: "5px", display: "block" }}>
      <i className="fas fa-comments" style={{ marginRight: "5px" }} />
      <span style={{ fontWeight: 500 }}>
        {intl.formatMessage(globalMessages.comments)}
      </span>
      <div className="tab-content">
        <div className="tab-pane active" id="comments">
          <CommentEditor
            onSave={addComment}
            modules={modules}
            text=""
            container="#toolbar"
            mentions={mentionedPeople}
            maxLength={400}
          />
          <div>
            {createComments(content)}
            {!content.length && intl.formatMessage(messages.defaultComment)}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { user } = state;

  return {
    userId: user && user.id ? user.id : null,
  };
};

export default injectIntl(
  withRouter(
    connect(mapStateToProps, {
      getAvatar,
    })(InsightTimelineView),
  ),
);
