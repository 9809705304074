import moment from "moment";
import { getToFixed } from "../MathUtils";
import { formatWithDecimals } from "../StringUtils";
import { checkIsMonetaryNumber } from "./validation";

export const FORMAT = {
  monetary: {
    min: 2,
    max: 2,
    lower: 5,
  },
  percentage: {
    min: 1,
  },
  quantity: {
    min: 2,
    lower: 5,
  },
  null_replace: "N/A",
  monetary_append: "$ ",
};

export function formatGoalByDate(dateValue, nullReplace = FORMAT.null_replace) {
  const vDateValue = dateValue?.toString();
  if (vDateValue?.length >= 8) return moment(vDateValue).format("L");

  return vDateValue
    ? moment().localeData().longDateFormat("L").toLowerCase()
    : nullReplace;
}

export function formatGoalByMonetaryValue(
  monetaryValue,
  decimals = FORMAT.monetary,
  disableAppend = false,
) {
  const append = disableAppend ? "" : FORMAT.monetary_append;
  const max = monetaryValue > 1 ? decimals.max : decimals.lower;

  return (
    append +
    Number(monetaryValue).toLocaleString("en", {
      minimumFractionDigits: decimals.min,
      maximumFractionDigits: max,
    })
  );
}

export function formatGoalByPercentage(
  percentageValue,
  decimals = FORMAT.percentage,
) {
  return `${getToFixed(percentageValue, decimals.min)}%`;
}

export function formatGoalByQuantity(
  quantityValue,
  decimals = FORMAT.quantity,
) {
  if (quantityValue > 1)
    return getToFixed(quantityValue, decimals.min)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return formatWithDecimals(
    quantityValue,
    "auto",
    decimals.lower,
    decimals.min,
  );
}

export function formatGoalValueByMeasurement(
  goalValue,
  measurementUnity,
  options = {
    nullReplace: FORMAT.null_replace,
    disableAppend: false,
    decimals: undefined,
  },
) {
  if (isNaN(goalValue) || goalValue === "") return options.nullReplace;
  if (measurementUnity === "DATE")
    return formatGoalByDate(goalValue, options.nullReplace);
  if (checkIsMonetaryNumber(measurementUnity))
    return formatGoalByMonetaryValue(
      goalValue,
      options.decimals,
      options.disableAppend,
    );
  if (measurementUnity === "PERCENTAGE")
    return formatGoalByPercentage(goalValue);

  return formatGoalByQuantity(goalValue);
}
