import {
  getInitialComplementaryScales,
  getInitialKeySeatEvaluation,
  getInitialPerformanceEvaluation,
  getInitialSuccessionEvaluation,
} from "./defaults";
import {
  getComplementaryScalesByType,
  getComplementaryEvaluationsByType,
} from "./filter";

export const checkImportComplementaryEvaluation = (
  complementaryEvaluations,
  filterType,
) => {
  const filtered = getComplementaryEvaluationsByType(
    complementaryEvaluations,
    filterType,
  );
  return filtered.length === 0;
};

export const getComplementaryEvaluationsMigrationConfig = (
  toolAdministration,
) => {
  const { complementaryEvaluations } = toolAdministration;

  return {
    enableImportKeySeat: checkImportComplementaryEvaluation(
      complementaryEvaluations,
      "KEY_SEAT",
    ),
    enableImportPerformance: checkImportComplementaryEvaluation(
      complementaryEvaluations,
      "PERFORMANCE",
    ),
    enableImportSuccession: checkImportComplementaryEvaluation(
      complementaryEvaluations,
      "SUCCESSION",
    ),
  };
};

export const importComplementaryEvaluation = (
  toolAdministration,
  newEvaluations,
  type,
) => {
  let newScales = [];

  const { complementaryEvaluations, complementaryScales } = toolAdministration;

  const filtered = getComplementaryScalesByType(complementaryScales, type);
  if (filtered.length === 0) newScales = getInitialComplementaryScales(type);

  return {
    ...toolAdministration,
    complementaryEvaluations: [...complementaryEvaluations, ...newEvaluations],
    complementaryScales: [...complementaryScales, ...newScales],
  };
};

export const importDefaultKeySeatEvaluation = (toolAdministration) => {
  const newEvaluations = getInitialKeySeatEvaluation();
  return importComplementaryEvaluation(
    toolAdministration,
    newEvaluations,
    "KEY_SEAT",
  );
};

export const importDefaultPerformanceEvaluation = (toolAdministration) => {
  const newEvaluations = getInitialPerformanceEvaluation();
  return importComplementaryEvaluation(
    toolAdministration,
    newEvaluations,
    "PERFORMANCE",
  );
};

export const importDefaultSuccessionEvaluation = (toolAdministration) => {
  const { complementaryEvaluations } = toolAdministration;
  const newEvaluations = getInitialSuccessionEvaluation();
  return {
    ...toolAdministration,
    complementaryEvaluations: [...complementaryEvaluations, ...newEvaluations],
  };
};
