import React from "react";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import { getSwotFactorTranslation } from "../../../../../../../utils/cpAnalysis/display";

const Factors = (props) => {
  const { cpAnalysisAdministration = {} } = props;

  const { swot = [] } = cpAnalysisAdministration || {};
  const { factors = [] } = swot || {};

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="2">Título</td>
        </tr>
      </thead>
      <tbody>
        {factors.map((factorInfo, index) => {
          const { type } = factorInfo || {};
          const translatedFactor = getSwotFactorTranslation(type);

          return (
            <tr className="block-hover" key={index}>
              <td align="center" style={{ width: "40px" }}>
                {index + 1}
              </td>
              <SimpleExcelCollumn
                id="factorName"
                label="Titulo"
                type="text"
                value={translatedFactor}
                tdStyle={{ pointerEvents: "none" }}
              />
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Factors;
