import React from "react";
import moment from "moment";
import _ from "lodash";
import { defineMessages } from "react-intl";
import { orderArrayByObjAttr } from "../../../../../utils/ArrayUtils";
import { orderPhysicalEvolutions } from "../../../../../utils/projectEvaluation";
import { aggregationByAtt } from "../../../../../utils/ArrayUtils";
import { checkPeriodIndexInCourse } from "../../../../../utils/projectUtils";
import {
  addError,
  isHeadersValidated,
  customFilter,
  getLabeledInfo,
  getOnlyLines,
} from "../../utils";
import { translatedText } from "../../../../../utils/translationUtils";

const messages = defineMessages({
  measu_editable: {
    id: "measu_editable",
  },
  measu_limit_value: {
    id: "measu_limit_value",
  },
  measu_invalid_year: {
    id: "measu_invalid_year",
  },
  measu_invalid_month: {
    id: "measu_invalid_month",
  },
  measu_invalid_physical_progress: {
    id: "measu_invalid_physical_progress",
  },
  measu_invalid_investment: {
    id: "measu_invalid_investment",
  },
  measu_invalid_effort: {
    id: "measu_invalid_effort",
  },
  measu_import_done_in: {
    id: "measu_import_done_in",
  },
  measu_no: {
    id: "measu_no",
  },
  measu_yes: {
    id: "measu_yes",
  },
  measu_example_format: {
    id: "measu_example_format",
  },
  measu_invalid_doc: {
    id: "measu_invalid_doc",
  },
  company: {
    id: "global.company",
  },
  workspace: {
    id: "global.workspace",
  },
  scenery: {
    id: "global.cenary",
  },
  theme: {
    id: "global.theme",
  },
  question: {
    id: "global.question",
  },
  projects: {
    id: "tool.project.projects",
  },
  year: {
    id: "global.year",
  },
  month: {
    id: "global.month",
  },
  accumulated_physical_progress: {
    id: "tool.project.accumulated_physical_progress",
  },
  investment_budget: {
    id: "tool.project.investment_budget",
  },
  investment_spent: {
    id: "tool.project.investment_spent",
  },
  effort_budget: {
    id: "tool.project.effort_budget",
  },
  effort_spent: {
    id: "tool.project.effort_spent",
  },
  rule: {
    id: "global.rule",
  },
  measu_pre_requirements: {
    id: "measu_pre_requirements",
  },
  measu_responsible_project: {
    id: "measu_responsible_project",
  },
  measu_planned_projects: {
    id: "measu_planned_projects",
  },
  weight: {
    id: "global.weight",
  },
  delivery_date: {
    id: "tool.project.deliveryDate",
  },
  responsible: {
    id: "global.responsible",
  },
  note: {
    id: "global.note",
  },
  expectedDelivery: {
    id: "global.expectedDelivery",
  },
  physical_progress: {
    id: "tool.project.physical_progress",
  },
  milestones: {
    id: "tool.project.key_milestones",
  },
});

const translation = (id, values) => translatedText(id, messages, values);

const getHeaders = () => {
  return [
    { header: "id", key: "id", hidden: true },
    { header: "companyName", key: "companyName", width: 32 },
    { header: "workspaceName", key: "workspaceName", width: 25 },
    { header: "sceneryName", key: "sceneryName", width: 25 },
    { header: "themeName", key: "themeName", width: 25 },
    { header: "questionName", key: "questionName", width: 25 },
    { header: "projectName", key: "projectName", width: 32 },
    { header: "year", key: "year" },
    { header: "month", key: "month" },
    { header: "physicalProgress", key: "physicalProgress", width: 20 },
    { header: "investmentBudget", key: "investmentBudget", width: 20 },
    { header: "investmentSpent", key: "investmentSpent", width: 20 },
  ];
};

const getMilestoneHeaders = () => {
  return [
    { header: "id", key: "id", hidden: true },
    { header: "projectEvaluationId", key: "projectEvaluationId", hidden: true },
    { header: "companyName", key: "companyName", width: 32 },
    { header: "workspaceName", key: "workspaceName", width: 25 },
    { header: "sceneryName", key: "sceneryName", width: 25 },
    { header: "themeName", key: "themeName", width: 25 },
    { header: "questionName", key: "questionName", width: 25 },
    { header: "projectName", key: "projectName", width: 32 },
    { header: "description", key: "description", width: 25 },
    { header: "responsibleName", key: "responsibleName", width: 30 },
    { header: "weight", key: "weight" },
    { header: "progress", key: "progress", width: 20 },
    { header: "foreseen", key: "foreseen", width: 20 },
    { header: "achieved", key: "achieved", width: 20 },
    { header: "expectedDate", key: "expectedDate", width: 20 },
    { header: "deliveryDate", key: "deliveryDate", width: 20 },
    { header: "note", key: "note", width: 20 },
  ];
};

const getFakeHeader = (intl) => {
  return {
    companyName: intl && intl.formatMessage(messages.company),
    workspaceName: intl && intl.formatMessage(messages.workspace),
    sceneryName: intl && intl.formatMessage(messages.scenery),
    themeName: intl && intl.formatMessage(messages.theme),
    questionName: intl && intl.formatMessage(messages.question),
    projectName: intl && intl.formatMessage(messages.projects),
    year: intl && intl.formatMessage(messages.year),
    month: intl && intl.formatMessage(messages.month),
    physicalProgress:
      intl && intl.formatMessage(messages.accumulated_physical_progress),
    investmentBudget: intl && intl.formatMessage(messages.investment_budget),
    investmentSpent: intl && intl.formatMessage(messages.investment_spent),
  };
};

const getMilestonesFakeHeader = (intl) => {
  return {
    companyName: intl && intl.formatMessage(messages.company),
    workspaceName: intl && intl.formatMessage(messages.workspace),
    sceneryName: intl && intl.formatMessage(messages.scenery),
    themeName: intl && intl.formatMessage(messages.theme),
    questionName: intl && intl.formatMessage(messages.question),
    projectName: intl && intl.formatMessage(messages.projects),
    description: "Milestone",
    responsibleName: intl && intl.formatMessage(messages.responsible),
    weight: intl && intl.formatMessage(messages.weight),
    progress: intl && intl.formatMessage(messages.physical_progress),
    foreseen: "Previsto",
    achieved: "Realizado",
    expectedDate: intl && intl.formatMessage(messages.expectedDelivery),
    deliveryDate: intl && intl.formatMessage(messages.delivery_date),
    note: intl && intl.formatMessage(messages.note),
  };
};

const getExampleBody = () => {
  return [
    {
      custom: translation("measu_editable"),
      companyName: translation("measu_no"),
      workspaceName: translation("measu_no"),
      sceneryName: translation("measu_no"),
      themeName: translation("measu_no"),
      questionName: translation("measu_no"),
      projectName: translation("measu_no"),
      year: translation("measu_no"),
      month: translation("measu_no"),
      physicalProgress: translation("measu_yes"),
      investmentBudget: translation("measu_yes"),
      investmentSpent: translation("measu_yes"),
    },
    {
      custom: translation("measu_example_format"),
      companyName: "",
      workspaceName: "",
      sceneryName: "",
      themeName: "",
      questionName: "",
      projectName: "",
      year: "",
      month: "",
      physicalProgress: "50",
      investmentBudget: "2500",
      investmentSpent: "2500",
    },
    {
      custom: translation("measu_limit_value"),
      companyName: "",
      workspaceName: "",
      sceneryName: "",
      themeName: "",
      questionName: "",
      projectName: "",
      year: "",
      month: "",
      physicalProgress: "100",
      investmentBudget: "",
      investmentSpent: "",
    },
  ];
};

const getMilestoneExampleBody = () => {
  return [
    {
      custom: translation("measu_editable"),
      companyName: translation("measu_no"),
      workspaceName: translation("measu_no"),
      sceneryName: translation("measu_no"),
      themeName: translation("measu_no"),
      questionName: translation("measu_no"),
      projectName: translation("measu_no"),
      description: translation("measu_yes"),
      responsibleName: translation("measu_no"),
      weight: translation("measu_yes"),
      progress: translation("measu_yes"),
      foreseen: translation("measu_yes"),
      achieved: translation("measu_yes"),
      expectedDate: translation("measu_yes"),
      deliveryDate: translation("measu_yes"),
      note: translation("measu_yes"),
    },
    {
      custom: translation("measu_example_format"),
      companyName: "",
      workspaceName: "",
      sceneryName: "",
      themeName: "",
      questionName: "",
      projectName: "",
      description: "",
      responsibleName: "",
      weight: "50",
      progress: "100",
      foreseen: "150",
      achieved: "200",
      expectedDate: "0000-00-00",
      deliveryDate: "0000-00-00",
      note: "",
    },
    {
      custom: translation("measu_limit_value"),
      companyName: "",
      workspaceName: "",
      sceneryName: "",
      themeName: "",
      questionName: "",
      projectName: "",
      description: "",
      responsibleName: "",
      weight: "100",
      progress: "100",
      foreseen: "",
      achieved: "",
      expectedDate: "",
      deliveryDate: "",
      note: "",
    },
  ];
};

const getStaticHeader = (intl) => {
  return {
    id: "",
    custom: intl && intl.formatMessage(messages.rule),
    ...getFakeHeader(intl),
  };
};

const getMilestonesStaticHeader = (intl) => {
  return {
    id: "",
    projectEvaluationId: "",
    custom: intl && intl.formatMessage(messages.rule),
    ...getMilestonesFakeHeader(intl),
  };
};

const handleErrorMessage = (errorType = "") => {
  let customBody = "";

  switch (errorType) {
    case "header":
      customBody = translation("measu_invalid_doc");
      break;
    case "year":
      customBody = translation("measu_invalid_year");
      break;
    case "month":
      customBody = translation("measu_invalid_month");
      break;
    case "physicalProgress":
      customBody = translation("measu_invalid_physical_progress");
      break;
    case "investmentBudget":
    case "investmentSpent":
      customBody = translation("measu_invalid_investment");
      break;
    default:
      break;
  }

  return <div>{customBody}</div>;
};

const orderPlanningsByYear = (evaluation = {}) => {
  const { plannings: investmentPlannings = [] } =
    evaluation.projectInvestment || {};

  return {
    investmentPlannings: orderArrayByObjAttr(
      investmentPlannings,
      "year",
      false,
      true,
    ),
  };
};

const getInvestmentBudgetAndSpent = (object = {}) => {
  const { budget: investmentBudget = "", spent: investmentSpent = "" } =
    object || {};

  return { investmentBudget, investmentSpent };
};

const getEvaluationByResponsible = (allProjectEvaluation = [], user = {}) => {
  let filteredEvaluations = [];

  allProjectEvaluation.forEach((evaluation) => {
    const { responsibleMember } =
      evaluation.projectEvaluation.peopleInvolved || {};

    const { email: memberEmail } = responsibleMember || {};

    const { responsibleParticipant } =
      evaluation.projectEvaluation.peopleInvolved || {};

    const { email: participantEmail } = responsibleParticipant || {};

    if (memberEmail === user.email || participantEmail === user.email)
      filteredEvaluations = [...filteredEvaluations, evaluation];
  });

  return filteredEvaluations;
};

const getEvolutionByMonthIndex = (months = [], monthIndex = 0) => {
  return months.filter(({ month }) => month === monthIndex)[0] || {};
};

const getFullYearLines = (
  currentEvaluation = {},
  plan = {},
  orderedPhysicalProgressEvolutions,
  index,
) => {
  let data = [];

  const { monthEvaluations: investmentMonths = {}, year = "" } = plan;
  delete investmentMonths.id;

  const {
    projectEvaluation: evaluation,
    companyName = "",
    workspaceName = "",
    sceneryName = "",
    themeName = "",
    questionName = "",
  } = currentEvaluation;

  Object.keys(investmentMonths).forEach((month, monthIndex) => {
    if (investmentMonths[month]) {
      const id = evaluation.id;
      const projectName = evaluation.projectName;

      const { months = [] } = orderedPhysicalProgressEvolutions[index] || {};

      const { value: physicalProgress = 0 } =
        getEvolutionByMonthIndex(months, monthIndex + 1) || {};

      data = [
        ...data,
        {
          id,
          companyName,
          workspaceName,
          sceneryName,
          themeName,
          questionName,
          projectName,
          year,
          month: moment().month(monthIndex).lang("en").format("MMM"),
          physicalProgress,
          ...getInvestmentBudgetAndSpent(investmentMonths[month]),
        },
      ];
    }
  });

  return data;
};

const getCSVMilestoneDataFormat = (evaluations = [], domainUsers = []) => {
  let formattedData = [];

  evaluations.forEach((currentEvaluation = {}) => {
    const {
      themeName = "",
      sceneryName = "",
      companyName = "",
      questionName = "",
      workspaceName = "",
      projectEvaluation: evaluation = {},
    } = currentEvaluation || {};

    const {
      id: projectEvaluationId = false,
      activities = [],
      projectName = "",
    } = evaluation || {};

    activities.forEach((activity) => {
      const { responsible = {}, effort = {} } = activity || {};

      const { achieved = 0, foreseen = 0 } = effort || {};

      const { id: responsibleId = false } = responsible || {};

      const { name: responsibleName = "" } =
        domainUsers.filter((user) => user.id === responsibleId)[0] || false;

      formattedData = [
        ...formattedData,
        {
          companyName,
          workspaceName,
          sceneryName,
          themeName,
          questionName,
          responsibleName,
          projectName,
          projectEvaluationId,
          achieved,
          foreseen,
          ...activity,
        },
      ];
    });
  });

  return formattedData;
};

const getCSVDataFormat = (filteredEvaluations = []) => {
  let formattedData = [];

  filteredEvaluations.forEach((currentEvaluation = {}) => {
    const { projectEvaluation: evaluation } = currentEvaluation;

    const { physicalProgressEvolutions = [] } = evaluation;

    // Ordenação de Planejamentos + Avanço Fisico
    const { investmentPlannings = [] } = orderPlanningsByYear(evaluation);

    const orderedPhysicalProgressEvolutions = orderPhysicalEvolutions(
      physicalProgressEvolutions,
    );

    investmentPlannings.forEach((plan, index) => {
      const fullYearLines = getFullYearLines(
        currentEvaluation,
        plan,
        orderedPhysicalProgressEvolutions,
        index,
      );

      formattedData = [...formattedData, ...fullYearLines];
    });
  });

  return formattedData;
};

const getProject = (key = "", allProjectEvaluation = []) => {
  return (
    allProjectEvaluation.filter(({ projectEvaluation }) => {
      const { id = "" } = projectEvaluation || {};
      return Number(id) === Number(key);
    })[0] || false
  );
};

const getMilestone = (key = "", allProjectEvaluation = []) => {
  return (
    allProjectEvaluation.filter(({ projectEvaluation }) => {
      const { activities = [] } = projectEvaluation || {};
      return activities.filter(
        ({ id: activityId }) => activityId === Number(key),
      );
    })[0] || false
  );
};

//Validação por id dos projetos
const firstLevelValidation = (
  data = [],
  key = null,
  allProjectEvaluation = [],
) => {
  const isValidated = true;
  let nonValidatedData = [];

  const searchProject = getProject(key, allProjectEvaluation);

  //O id desse projeto existe no banco?
  if (!searchProject) {
    const lines = getOnlyLines(data[key]);

    lines.forEach((line) => {
      nonValidatedData = addError(nonValidatedData, "projectID", line);
    });

    return { isValidated: false, nonValidatedData };
  }

  const { projectEvaluation = {} } = searchProject || {};

  const { initialDate = "0000-00-00", finalDate = "0000-00-00" } =
    projectEvaluation || {};

  const projectStartDate = {
    year: moment(initialDate).year(),
    month: moment(initialDate).month(),
  };
  const projectEndDate = {
    year: moment(finalDate).year(),
    month: moment(finalDate).month(),
  };

  return { isValidated, nonValidatedData, projectStartDate, projectEndDate };
};

const validationLineByLine = (data = [], projectStartDate, projectEndDate) => {
  let validatedData = [];
  let nonValidatedData = [];

  data.forEach((line) => {
    const {
      year = "",
      month = "",
      physicalProgress = 0,
      investmentBudget = "",
      investmentSpent = "",
    } = line || {};

    moment.locale("en");

    const currentMonth = moment(month, "MMM");

    const monthNumber = currentMonth.format("M");

    // O mes existe no planejamento desse ano?
    const check = checkPeriodIndexInCourse(monthNumber - 1, year, {
      start: projectStartDate,
      end: projectEndDate,
    });

    if (!check) {
      nonValidatedData = addError(nonValidatedData, "month", line);

      return null;
    }

    // o tipo do avanço fisico é numero? é menor que 100? é maior que 0?
    if (
      !Number.isInteger(physicalProgress || 0) ||
      (physicalProgress || 0) < 0 ||
      (physicalProgress || 0) > 100
    ) {
      nonValidatedData = addError(nonValidatedData, "physicalProgress", line);

      return null;
    }

    //o tipo do investment budget é numero? é maior que 0?
    if (!Number.isInteger(investmentBudget || 0) || investmentBudget < 0) {
      nonValidatedData = addError(nonValidatedData, "investmentBudget", line);

      return null;
    }

    //o tipo do investment spent é numero? é maior que 0?
    if (!Number.isInteger(investmentSpent || 0) || investmentSpent < 0) {
      nonValidatedData = addError(nonValidatedData, "investmentSpent", line);

      return null;
    }

    validatedData = [...validatedData, { ...line }];
  });

  return { validatedData, nonValidatedData };
};

const overallValidation = (data = [], allProjectEvaluation = []) => {
  const clonnedAllProjectEvaluation = _.cloneDeep(allProjectEvaluation);

  let validatedData = [];
  let nonValidatedData = [];

  Object.keys(data).forEach((key) => {
    const clonnedDeepKey = _.cloneDeep(key);
    const clonnedDeepData = _.cloneDeep(data);

    const {
      isValidated = false,
      nonValidatedData: firstLevelNVData = [],
      projectStartDate = { year: "0000", month: "00", day: "00" },
      projectEndDate = { year: "0000", month: "00", day: "00" },
    } = firstLevelValidation(
      clonnedDeepData,
      clonnedDeepKey,
      clonnedAllProjectEvaluation,
    );

    nonValidatedData = [...nonValidatedData, ...firstLevelNVData];

    if (!isValidated) return null;

    Object.keys(clonnedDeepData[clonnedDeepKey]).forEach((year) => {
      //Esse ano existe no planejamento do projeto?
      if (
        Number(year) < projectStartDate.year ||
        Number(year) > projectEndDate.year
      ) {
        const nonValidatedLines = clonnedDeepData[clonnedDeepKey][year];

        nonValidatedLines.forEach((line) => {
          nonValidatedData = addError(nonValidatedData, "year", line);
        });

        return null;
      }

      const clonnedData = _.cloneDeep(clonnedDeepData[clonnedDeepKey][year]);

      // Validação de Mês, Avanço Fisico, Investimento e Esforço
      const {
        validatedData: validatedLines,
        nonValidatedData: nonValidatedLines,
      } = validationLineByLine(clonnedData, projectStartDate, projectEndDate);

      validatedData = [...validatedData, ...validatedLines];
      nonValidatedData = [...nonValidatedData, ...nonValidatedLines];
    });
  });

  return { validatedData, nonValidatedData };
};

const projectEvaluationValidation = (info = {}) => {
  let nonValidatedData = [];

  const { data = [], projectEvaluationId = false, all } = info || {};

  const currentProject = getProject(projectEvaluationId, all);

  if (!currentProject) {
    const lines = getOnlyLines(data[projectEvaluationId]);

    lines.forEach((line) => {
      nonValidatedData = addError(
        nonValidatedData,
        "projectEvaluationId",
        line,
      );
    });

    return { isValidated: false, nonValidatedData };
  }

  return { isValidated: true, nonValidatedData };
};

const projectActivityValidation = (info = {}) => {
  let nonValidatedData = [];

  const { data = [], projectActivityId = false, all } = info || {};

  const currentMilestone = getMilestone(projectActivityId, all);

  if (!currentMilestone) {
    const lines = data[projectActivityId];

    lines.forEach((line) => {
      nonValidatedData = addError(nonValidatedData, "projectActivityId", line);
    });

    return { isValidated: false, nonValidatedData };
  }

  return { isValidated: true, nonValidatedData };
};

const idValidation = (info = {}, type = "") => {
  switch (type) {
    case "projectEvaluationId":
      return projectEvaluationValidation(info);
    case "projectActivityId":
      return projectActivityValidation(info);
    default:
      break;
  }
};

const datesValidation = ({ expectedDate = false, deliveryDate = false }) => {
  const addLocalError = (textError = "") => {
    return { dateValidated: false, textError };
  };

  if (expectedDate && !moment(expectedDate).isValid())
    return addLocalError("expectedDate");
  if (deliveryDate && !moment(deliveryDate).isValid())
    return addLocalError("deliveryDate");

  return { dateValidated: true };
};

const textValidation = ({ description, note }) => {
  const addLocalError = (textError = "") => {
    return { textValidated: false, textError };
  };

  if (description && !typeof description === "string")
    return addLocalError("description");
  if (note && !typeof note === "string") return addLocalError("note");

  return { textValidated: true };
};
const numbersValidation = ({ achieved, foreseen, progress, weight }) => {
  const addLocalError = (textError = "") => {
    return { numberValidated: false, textError };
  };

  if (achieved && achieved < 0) return addLocalError("achieved");
  if (foreseen && foreseen < 0) return addLocalError("foreseen");
  if (progress && (progress < 0 || progress > 100))
    return addLocalError("progress");
  if (weight && (weight < 0 || weight > 100)) return addLocalError("weight");

  return { numberValidated: true };
};

const milestoneValidationLineByLine = (lines = []) => {
  let validatedData = [];
  let nonValidatedData = [];

  const addToArray = (array = [], line = {}) => {
    return [...array, line];
  };

  lines.forEach((line) => {
    const { dateValidated = false, dateError = "" } = datesValidation(line);
    const { textValidated = false, textError = "" } = textValidation(line);
    const { numberValidated = false, numberError = "" } = numbersValidation(
      line,
    );

    const validated = dateValidated && textValidated && numberValidated;

    const addErrors = (error = "", line = {}) => {
      nonValidatedData = addError(nonValidatedData, error, line);
    };

    if (!validated) {
      !dateValidated && addErrors(dateError, line);
      !textValidated && dateValidated && addErrors(textError, line);
      dateValidated && textValidated && addErrors(numberError, line);

      return;
    }

    validatedData = addToArray(validatedData, line);
  });

  return { validatedData, nonValidatedData };
};

const overallMilestonesValidation = (data = {}, lines = [], all = []) => {
  let validatedData = [];
  let nonValidatedData = [];

  Object.keys(data).forEach((projectEvaluationId) => {
    const {
      isValidated: validatedProjectId = false,
      nonValidatedData: firstLevelNonValidated = [],
    } = idValidation(
      {
        projectEvaluationId,
        all,
      },
      "projectEvaluationId",
    );

    if (!validatedProjectId)
      return { nonValidatedData: firstLevelNonValidated };

    Object.keys(data[projectEvaluationId]).forEach((projectActivityId) => {
      const {
        isValidated: validatedActivityId = false,
        nonValidatedData: secondLevelNonValidated = [],
      } = idValidation(
        {
          projectActivityId,
          all,
        },
        "projectActivityId",
      );

      nonValidatedData = [
        ...nonValidatedData,
        ...firstLevelNonValidated,
        ...secondLevelNonValidated,
      ];

      if (!validatedActivityId)
        return { nonValidatedData: secondLevelNonValidated };
    });
  });

  const {
    validatedData: validatedLines,
    nonValidatedData: nonValidatedLines,
  } = milestoneValidationLineByLine(lines);

  validatedData = [...validatedData, ...validatedLines];
  nonValidatedData = [...nonValidatedData, ...nonValidatedLines];

  return { validatedData, nonValidatedData };
};

const handleMilestonesValidations = (data = [], allProjectEvaluation = []) => {
  const all = _.cloneDeep(allProjectEvaluation);

  // Validações Macro - Todas as Ferramentas
  // Validação de Header
  if (!isHeadersValidated(data, getMilestonesStaticHeader()))
    return {
      nonValidatedData: addError([], "header", {}),
      validatedData: [],
    };

  // Agregação
  const aggregatedData = aggregationByAtt("projectEvaluationId", data);

  Object.keys(aggregatedData).forEach((projectID) => {
    aggregatedData[projectID] = aggregationByAtt(
      "id",
      aggregatedData[projectID],
    );
  });

  const validatedInfo = overallMilestonesValidation(aggregatedData, data, all);

  return { ...validatedInfo };
};

const handleEvaluationValidations = (data = [], allProjectEvaluation = []) => {
  const all = _.cloneDeep(allProjectEvaluation);

  // Validações Macro - Todas as Ferramentas
  // Validação de Header
  if (!isHeadersValidated(data, getStaticHeader()))
    return {
      nonValidatedData: addError([], "header", {}),
      validatedData: [],
    };

  // Agregação
  const aggregatedData = aggregationByAtt("id", data);

  Object.keys(aggregatedData).forEach((projectID) => {
    aggregatedData[projectID] = aggregationByAtt(
      "year",
      aggregatedData[projectID],
    );
  });

  const { validatedData = [], nonValidatedData = [] } = overallValidation(
    aggregatedData,
    all,
  );

  return { validatedData, nonValidatedData };
};

const handleValidation = (
  { data: sheetsInfo = {} },
  { allProjectEvaluation = [] },
) => {
  const { sheet0 = {}, sheet1 = {} } = sheetsInfo || {};
  const { data: evaluationData = [] } = sheet0;
  const { data: milestoneData = [] } = sheet1;

  const evaluationInfo = handleEvaluationValidations(
    evaluationData,
    allProjectEvaluation,
  );

  const milestoneInfo = handleMilestonesValidations(
    milestoneData,
    allProjectEvaluation,
  );

  return {
    validated: {
      sheet0: [...evaluationInfo.validatedData],
      sheet1: [...milestoneInfo.validatedData],
    },
    nonValidated: {
      sheet0: [...evaluationInfo.nonValidatedData],
      sheet1: [...milestoneInfo.nonValidatedData],
    },
  };
};

const getFilteredObject = (allProjectEvaluation = [], projectID = "") => {
  const { insightId = 0, cenaryId = 0, projectEvaluation = {} } =
    allProjectEvaluation.filter(({ projectEvaluation }) => {
      const { id = "" } = projectEvaluation || {};

      return Number(id) === Number(projectID);
    })[0] || {};

  const updatedObject = {
    insightId,
    cenaryId,
    projectEvaluation: {
      id: projectID,
      ...projectEvaluation,
    },
  };

  return updatedObject;
};

const getReplanningConfig = (entity = {}, year = "") => {
  const { replannings = [] } = entity || {};

  const filteredReplan =
    replannings.filter((replan) => Number(replan.year) === Number(year))[0] ||
    null;

  if (!filteredReplan) return true;

  return false;
};

const handleReplanning = (plan = {}, entity = {}) => {
  const { year = "", monthEvaluations = {} } = plan;

  const isBaselineReplan = getReplanningConfig(entity, year);

  const dateTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
  const customDateTime = moment().format("YYYY-MM-DD HH:mm:ss");

  const customMessage = isBaselineReplan
    ? "Baseline"
    : translation("measu_import_done_in") + customDateTime;

  const updatedEntity = { ...entity };

  delete monthEvaluations.id;

  updatedEntity.replannings = [
    ...updatedEntity.replannings,
    {
      id: null,
      monthEvaluations,
      reason: customMessage,
      year,
      dateTime,
    },
  ];

  return updatedEntity;
};

const updateGenericFields = (
  field = {},
  line = {},
  budget = "",
  spent = "",
) => {
  const { year = "", month = "" } = line;
  const { plannings = [] } = field;

  let updatedField = { ...field };

  const { filteredObject: filteredPlan, indexToUpdate } = customFilter(
    plannings,
    "year",
    year,
  );

  const { monthEvaluations = {} } = filteredPlan;

  const monthsToUpdate = { ...monthEvaluations };

  const monthName = moment(month, "MMM")
    .lang("en")
    .format("MMMM")
    .toLowerCase();

  const { budget: monthBudget = "" } = monthEvaluations[monthName] || {};

  if (budget !== monthBudget) {
    if (!filteredPlan.alreadyReplanned) {
      updatedField = handleReplanning(filteredPlan, _.cloneDeep(updatedField));
      filteredPlan.alreadyReplanned = true;
    }

    monthsToUpdate[monthName].budget = budget;
  }

  monthsToUpdate[monthName].spent = spent;

  filteredPlan.monthEvaluations = { ...monthsToUpdate };

  updatedField.plannings[indexToUpdate] = filteredPlan;

  return updatedField;
};

const updatePhysicalProgress = (progressEvolutions = {}, line = {}) => {
  const updatedProgressEvolutions = [...progressEvolutions];
  const { physicalProgress = "", year = "", month = "" } = line;

  const monthNumber = Number(moment(month, "MMM").format("M"));

  // Acha evaluation com base no ano
  const { filteredObject: filteredProgress, indexToUpdate } = customFilter(
    progressEvolutions,
    "year",
    year,
  );

  // Atualiza valor baseado no mês
  filteredProgress.months.forEach(({ month }, index) => {
    if (month === monthNumber) {
      filteredProgress.months[index].value = physicalProgress;
      return true;
    }
  });

  updatedProgressEvolutions[indexToUpdate] = filteredProgress;

  return updatedProgressEvolutions;
};

const handleUpdateProject = (importedProject = [], filteredObject = {}) => {
  const updatedObject = { ...filteredObject };

  importedProject.forEach((line) => {
    const { projectInvestment = {}, physicalProgressEvolutions = [] } =
      updatedObject.projectEvaluation || {};

    const { investmentSpent = "", investmentBudget = "" } = line;

    // Atualiza valores de acordo com o mês
    const alteredProjectInvestment = updateGenericFields(
      _.cloneDeep(projectInvestment),
      line,
      investmentBudget,
      investmentSpent,
    );

    const alteredPhysicalProgressEvolutions = updatePhysicalProgress(
      _.cloneDeep(physicalProgressEvolutions),
      line,
    );

    updatedObject.projectEvaluation.projectInvestment = {
      ...alteredProjectInvestment,
    };
    updatedObject.projectEvaluation.physicalProgressEvolutions = [
      ...alteredPhysicalProgressEvolutions,
    ];
  });

  return updatedObject;
};

const handleUpdateMilestones = (
  importedMilestones = [],
  { projectEvaluation = {} } = {},
) => {
  const { activities = [] } = projectEvaluation;

  const milestonesArray = [...activities];

  activities.forEach((activity, index) => {
    const importedMilestone =
      importedMilestones.filter((miles) => miles.id === activity.id)[0] ||
      false;

    if (!importedMilestone) return;

    const {
      achieved = false,
      deliveryDate = false,
      description = false,
      expectedDate = false,
      foreseen = false,
      note = false,
      progress = false,
      projectEvaluationId = false,
      weight = false,
    } = importedMilestone || {};

    const {
      effort = {},
      deliveryDate: backupDeliveryDate = false,
      description: backupDescription = false,
      expectedDate: backupExpectedDate = false,
      note: backupNote = false,
      progress: backupProgress = false,
      id: projectActivityId = false,
      weight: backupWeight = false,
    } = activity || {};

    const {
      achieved: backupAchieved = false,
      foreseen: backupForeseen = false,
    } = effort || {};

    milestonesArray[index] = {
      achieved: achieved || backupAchieved,
      deliveryDate: deliveryDate || backupDeliveryDate,
      description: description || backupDescription,
      expectedDate: expectedDate || backupExpectedDate,
      foreseen: foreseen || backupForeseen,
      note: note || backupNote,
      progress: progress || backupProgress,
      projectActivityId,
      projectEvaluationId,
      weight: weight || backupWeight,
    };
  });

  return milestonesArray;
};

const handleEvaluationMassUpdate = (
  updateData = [],
  allProjectEvaluation = [],
) => {
  let updatedEvaluations = [];

  // Agregação
  const aggregatedData = aggregationByAtt("id", updateData);

  Object.keys(aggregatedData).forEach((projectID) => {
    //Filtra apenas campos que serão atualizados
    const filteredObject = getFilteredObject(allProjectEvaluation, projectID);

    const updatedProject = handleUpdateProject(
      _.cloneDeep(aggregatedData[projectID]),
      _.cloneDeep(filteredObject),
    );

    updatedEvaluations = [...updatedEvaluations, updatedProject];
  });

  return updatedEvaluations;
};

const handleMilestonesMassUpdate = (
  updateData = [],
  allProjectEvaluation = [],
) => {
  let updatedEvaluations = [];

  const aggregatedData = aggregationByAtt("projectEvaluationId", updateData);

  Object.keys(aggregatedData).forEach((projectID) => {
    //Filtra apenas campos que serão atualizados
    const filteredObject = getFilteredObject(allProjectEvaluation, projectID);

    const updatedMilestone = handleUpdateMilestones(
      _.cloneDeep(aggregatedData[projectID]),
      _.cloneDeep(filteredObject),
    );

    updatedEvaluations = [...updatedEvaluations, ...updatedMilestone];
  });

  return updatedEvaluations;
};

const handleMassUpdate = (updateData = {}, { allProjectEvaluation }) => {
  const { sheet0 = [], sheet1 = [] } = updateData || {};

  const evaluationInfo = handleEvaluationMassUpdate(
    sheet0,
    allProjectEvaluation,
  );
  const milestoneInfo = handleMilestonesMassUpdate(
    sheet1,
    allProjectEvaluation,
  );

  return { evaluationInfo, milestoneInfo };
};

const getFilteredEvaluations = (
  allProjectEvaluation = [],
  user = {},
  selectedToolStates = {},
) => {
  let updatedList = [];

  const { workspaceId: selectedWorkspaceId } = selectedToolStates;

  const filteredByWorkspaceId = allProjectEvaluation.filter((evaluation) => {
    return evaluation.workspaceId === selectedWorkspaceId;
  });

  const {
    labeledSceneries = {},
    labeledThemes = {},
    labeledQuestions = {},
    workspaceName = "",
    companyName = "",
  } = getLabeledInfo(filteredByWorkspaceId, selectedToolStates);

  filteredByWorkspaceId.forEach((evaluation) => {
    const sceneryName = labeledSceneries[evaluation.cenaryId];
    const themeName = labeledThemes[evaluation.themeId];
    const questionName = labeledQuestions[evaluation.questionId];

    updatedList = [
      ...updatedList,
      {
        ...evaluation,
        sceneryName,
        themeName,
        questionName,
        workspaceName,
        companyName,
      },
    ];
  });

  return updatedList;
};

const preRequeriments = () => {
  return (
    <div className="measurementRequires">
      <div>
        <strong> {translation("measu_pre_requirements")} </strong>
      </div>
      <div>
        <i className="fas fa-check" />{" "}
        {translation("measu_responsible_project")}
      </div>
      <div>
        <i className="fas fa-check" /> {translation("measu_planned_projects")}
      </div>
    </div>
  );
};

const getPortfolioDependencies = (params = {}) => {
  const {
    allProjectEvaluation = [],
    user = {},
    intl,
    selectedToolStates = {},
    domainUsers = [],
  } = params || {};

  let dataToExport = {};
  let exampleBody = {};

  const { selectedToolID: selectedToolId = "" } = selectedToolStates || {};

  const filteredProjectEvaluation = getFilteredEvaluations(
    allProjectEvaluation,
    user,
    selectedToolStates,
  );

  const rowsConfig = [{ line: 2, fill: "eeeeee" }];

  const staticHeader = getStaticHeader(intl);
  const milestonesStaticHeader = getMilestonesStaticHeader(intl);

  delete staticHeader.id;

  dataToExport = {
    sheet0: {
      title: intl.formatMessage(messages.projects),
      rowsConfig,
      staticHeader,
      headers: getHeaders(),
      fakeHeader: getFakeHeader(intl),
      data: getCSVDataFormat(filteredProjectEvaluation),
    },
    sheet1: {
      title: intl.formatMessage(messages.milestones),
      rowsConfig,
      headers: getMilestoneHeaders(),
      staticHeader: milestonesStaticHeader,
      fakeHeader: getMilestonesFakeHeader(intl),
      data: getCSVMilestoneDataFormat(filteredProjectEvaluation, domainUsers),
    },
  };

  exampleBody = {
    sheet0: {
      title: intl.formatMessage(messages.projects),
      headers: staticHeader,
      data: getExampleBody(),
    },
    sheet1: {
      title: intl.formatMessage(messages.milestones),
      headers: milestonesStaticHeader,
      data: getMilestoneExampleBody(),
    },
  };

  return {
    dataToExport,
    filename: `Project-Portfolio-${moment().toJSON()}`,
    handleValidation,
    handleMassUpdate,
    additionalData: { allProjectEvaluation, selectedToolId },
    exampleBody,
    staticHeader,
    handleErrorMessage,
    preRequeriments,
  };
};

export { getHeaders, getPortfolioDependencies, handleValidation };
