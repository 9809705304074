import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";

const ButtonExcel = styled(Button)`
  position: absolute !important;
  top: -35px !important;
  right: ${(props) =>
    props.styleRight ? props.styleRight : "85px  !important"};
  font-size: 13px !important;
  border: 1px solid #ccc !important;
`;

const ButtonExportExcel = (props) => {
  const { doExport, styleRight } = props;
  return (
    <ButtonExcel
      styleRight={styleRight}
      onClick={doExport}
      className="hidden-print"
      disabled={props.disabled}
    >
      <i className="far fa-file-excel" style={{ marginRight: "5px" }} />
      Excel
    </ButtonExcel>
  );
};

export default ButtonExportExcel;
