import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import FormDialog from "../../../../../../Common/FormDialog";
import MaterialTextField from "../../../../../../Common/MaterialTextField";

import { AlertStyle } from "../../css/OkrCss";
import { configObjectivesBody } from "../../../../../../../utils/okr/entity";
import utils from "../../../../../../../utils/toolUtils";

const { iterationSearch } = utils;
const initialObjectives = configObjectivesBody({});

const ObjectForm = ({
  open,
  onCancel,
  onConfirm,
  isEditing,
  objectives,
  intl,
}) => {
  const [objective, setObjective] = useState(initialObjectives);

  useEffect(() => {
    if (Object.keys(isEditing.params).length > 0) {
      setObjective(isEditing.params);
    }
  }, [isEditing]);

  function validForm() {
    let titleError = "";

    if (objective.title === "" || objective.title.length < 3) {
      titleError = intl.formatMessage({ id: "min_caractere" });
    }

    const objectiveTitles =
      objectives.length &&
      objectives.map((objective) =>
        objective.title.toString().toUpperCase().replace(/\s/g, "").trim(),
      );
    const isEqualObjectiveTitle = iterationSearch(
      objective.title,
      objectiveTitles,
    );

    const filterObjectiveById = objectives.filter((objectiveInfo) => {
      return objectiveInfo.id !== objective.id;
    });

    const objectiveTitleEquals = filterObjectiveById.filter((objectiveInfo) => {
      return (
        objectiveInfo.title
          .toString()
          .toUpperCase()
          .replace(/\s/g, "")
          .trim() ===
        objective.title.toString().toUpperCase().replace(/\s/g, "").trim()
      );
    });

    if (isEqualObjectiveTitle && !objective.id) {
      titleError = intl.formatMessage({ id: "objective_repeat" });
    }

    if (objective.id && objectiveTitleEquals.length >= 1) {
      titleError = intl.formatMessage({ id: "objective_repeat" });
    }

    if (titleError) {
      setObjective({ ...objective, titleError });

      return false;
    }

    return true;
  }

  function resetCloseModal() {
    setObjective(initialObjectives);

    onCancel("objectModal", false);
  }

  function handleConfirm() {
    if (validForm()) {
      onConfirm(objective);

      resetCloseModal();
    }
  }

  return (
    <FormDialog
      open={open}
      onCancel={resetCloseModal}
      onConfirm={handleConfirm}
      title={intl.formatMessage({ id: "add_objective" })}
    >
      {objective.titleError && (
        <AlertStyle severity="error">
          <span>{objective.titleError}</span>
        </AlertStyle>
      )}
      <MaterialTextField
        id="objectiveName"
        value={objective.title || ""}
        label={intl.formatMessage({ id: "objective_name" })}
        onChange={(e) => setObjective({ ...objective, title: e.target.value })}
      />
    </FormDialog>
  );
};

export default injectIntl(ObjectForm);

ObjectForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  objectives: PropTypes.array.isRequired,
  isEditing: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};
