import React from "react";
import { defineMessages } from "react-intl";
import { getMonthOptions } from "../../../../../../../constants/monthsConstants";
import { translatedText } from "../../../../../../../utils/translationUtils";
import MaterialSingleSelect from "../../../../../../Common/MaterialSingleSelect";

const messages = defineMessages({
  kpi_form_initial_month: { id: "kpi_form_initial_month" },
  kpi_form_last_month: { id: "kpi_form_last_month" },
});

const translation = (id) => translatedText(id, messages);

const PeriodConfigTable = (props) => {
  const {
    kpiAdministration,
    setKpiAdministration,
    setConfirmationDialog,
    resetConfirmDialog,
    handleAutoSave,
    title,
    description,
    confirmText,
  } = props;
  const { periodCycle = {}, goals = [] } = kpiAdministration;

  const monthOptions = getMonthOptions();

  const updateGoalsPeriod = (value, target) => {
    const updatedGoals = [];

    goals.forEach((goalInfo) => {
      updatedGoals.push({
        ...goalInfo,
        period: {
          ...goalInfo.period,
          ...periodCycle,
          [target]: value,
          year: goalInfo.period.year,
        },
      });
    });

    const newAdministration = {
      ...kpiAdministration,
      periodCycle: {
        ...periodCycle,
        [target]: value,
      },
      goals: updatedGoals,
    };

    handleAutoSave(newAdministration);
    resetConfirmDialog();
  };

  const getNewCycle = (value, target) => {
    return { ...periodCycle, [target]: value };
  };

  const updateCycle = (value, target) => {
    setKpiAdministration({
      ...kpiAdministration,
      periodCycle: getNewCycle(value, target),
    });
  };

  const updatePeriodConfig = (value, target) => {
    const newCycle = getNewCycle(value, target);

    if (goals.length > 0 && newCycle.start !== newCycle.end) {
      setConfirmationDialog({
        open: true,
        title,
        description,
        onConfirm: () => updateGoalsPeriod(value, target),
        onCancel: () => {
          updateCycle(value, target);
          resetConfirmDialog();
        },
        confirmText,
        cancelText: "Atualizar Apenas o Período",
      });
    } else {
      updateCycle(value, target);
    }
  };

  return (
    <div>
      <MaterialSingleSelect
        id="startPeriod"
        value={periodCycle.start}
        label={translation("kpi_form_initial_month")}
        options={monthOptions}
        onChange={(e) => updatePeriodConfig(e.target.value, "start")}
        defaultOption={false}
      />
      <MaterialSingleSelect
        id="endPeriod"
        value={periodCycle.end}
        label={translation("kpi_form_last_month")}
        options={monthOptions}
        onChange={(e) => updatePeriodConfig(e.target.value, "end")}
        defaultOption={false}
      />
    </div>
  );
};

export default PeriodConfigTable;
