import React from "react";
import AvatarEditor from "react-avatar-editor";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";

const PrettoSlider = withStyles({
  root: {
    color: "#52af77",
    height: 8
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
})(Slider);

const CropImage = ({ imageSrc, setEditorRef, scaleValue }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div>
        <AvatarEditor
          image={imageSrc}
          ref={setEditorRef}
          color={[240, 240, 240, 0.6]}
          border={60}
          width={212}
          height={212}
          borderRadius={100}
          scale={scaleValue}
          rotate={0}
        />
      </div>
    </div>
  );
};

export default CropImage;
