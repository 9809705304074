import * as actions from "./_actions";
import send from "../dispatcher";

function observeWorkspace({ stompClient, dispatch }) {
  stompClient.subscribe("/selected-tools/createSelectedTool", (response) => {
    send(actions.postNewTool, response, dispatch);
  });

  return stompClient;
}

export default observeWorkspace;
