import React from "react";
import { withRouter } from "react-router-dom";

import ErrorSvg from "../../assets/img/error.svg";
import { ErrorAlert } from "./styles";

function ErrorMessage({ history }) {
  function refreshPage() {
    history.replace("/");
    window.location.reload();
  }

  return (
    <ErrorAlert>
      <img src={ErrorSvg} alt="Alert Error" />
      <button className="btn btn-purple" onClick={refreshPage}>
        Atualizar página
      </button>
    </ErrorAlert>
  );
}

export default withRouter(ErrorMessage);
