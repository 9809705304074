/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
    fontSize: "18px",
  },
});

function SimpleOptionsModal({ open, title, onCancel, options }) {
  const classes = useStyles();

  const handleClose = () => {
    onCancel();
  };

  const handleListItemClick = (callback) => {
    callback();
    onCancel();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <h5 id="simple-dialog-title" style={{ padding: "15px 15px 5px 15px" }}>
        {title}
      </h5>
      <List>
        {options.map(({ onClick, icon, title }, index) => (
          <ListItem
            button
            onClick={() => handleListItemClick(onClick)}
            key={index}
          >
            {icon && (
              <ListItemAvatar>
                <Avatar className={classes.avatar}>{icon}</Avatar>
              </ListItemAvatar>
            )}
            <h6>{title}</h6>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

SimpleOptionsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  onCancel: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      onClick: PropTypes.func.isRequired,
      icon: PropTypes.node,
    }),
  ).isRequired,
};

export default SimpleOptionsModal;
