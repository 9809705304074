import axios from "axios";
import { DELETE_MEMBER } from "./types";
import { fetchCompanyMembers } from "./companyActions";
import { fetchTeamMembers, fetchTeamParticipants } from "./teamsActions";
import { fetchCenaryMembers, fetchCenaryParticipants } from "./cenaryActions";

export const insertCenaryMember = (member_ID, cenary_ID) => {
  return (dispatch) => {
    return axios
      .post(`/api/cenaries/${cenary_ID}/members/${member_ID}`)
      .then(() => {
        dispatch(fetchCenaryMembers(cenary_ID));
      });
  };
};

export const deleteCenaryMember = (member_ID, cenary_ID) => {
  return (dispatch) => {
    return axios
      .delete(`/api/cenaries/${cenary_ID}/members/${member_ID}`)
      .then(() => {
        dispatch(fetchCenaryMembers(cenary_ID));
      });
  };
};

export const insertCenaryParticipant = (participant_ID, cenary_ID) => {
  return (dispatch) => {
    return axios
      .post(`/api/cenaries/${cenary_ID}/participants/${participant_ID}`)
      .then(() => {
        dispatch(fetchCenaryParticipants(cenary_ID));
      });
  };
};

export const deleteCenaryParticipant = (participant_ID, cenary_ID) => {
  return (dispatch) => {
    return axios
      .delete(`/api/cenaries/${cenary_ID}/participants/${participant_ID}`)
      .then(() => {
        dispatch(fetchCenaryParticipants(cenary_ID));
      });
  };
};

export const insertCompanyMember = (companyId, addMembersId) => {
  return async (dispatch) => {
    const promises = addMembersId.map(async (memberId) => {
      const postMember = await axios
        .post(`/api/companies/${companyId}/members/${memberId}`)
        .then(() => {});

      return postMember;
    });

    await Promise.all(promises);
    dispatch(fetchCompanyMembers(companyId));
  };
};

export const postTeamMember = (teamId, memberId) => {
  return axios.post(`/api/teams/${teamId}/members/${memberId}`).then((res) => {
    return res;
  });
};

export const insertTeamMember = (teamId, selectedMember) => {
  return (dispatch) => {
    return postTeamMember(teamId, selectedMember).then(() => {
      dispatch(fetchTeamMembers(teamId));
    });
  };
};

export const insertCompanyMemberForTeamMember = (
  member_ID,
  selectedTeam,
  selectedCompany,
) => {
  return (dispatch) => {
    return axios
      .post(`/api/companies/${selectedCompany}/members/${member_ID}`)
      .then(() => {
        const newMember = {
          teams_id: selectedTeam,
          members_id: member_ID,
        };
        dispatch(insertTeamMember(newMember));
      });
  };
};

export const postTeamParticipant = (teamId, participantId) => {
  return axios
    .post(`/api/teams/${teamId}/participants/${participantId}`)
    .then((res) => {
      return res;
    });
};

export const addMembersToTeam = (members = [], teamId) => {
  return (dispatch) => {
    const saveMembers = async () => {
      await Promise.all(
        members.map(async (memberId) => {
          const response = await postTeamMember(teamId, memberId);
          return response;
        }),
      );

      dispatch(fetchTeamMembers(teamId));
    };

    saveMembers();
  };
};

export const addParticipantsToTeam = (participants = [], teamId) => {
  return (dispatch) => {
    const saveParticipants = async () => {
      await Promise.all(
        participants.map(async (participantId) => {
          const response = await postTeamParticipant(teamId, participantId);
          return response;
        }),
      );

      dispatch(fetchTeamParticipants(teamId));
    };

    saveParticipants();
  };
};

export const deleteMember = (memberID) => {
  return (dispatch) => {
    return axios.delete(`/api/members/${memberID}`).then((res) => {
      dispatch({ type: DELETE_MEMBER, payload: res.data });
    });
  };
};

export const deleteTeamMember = (memberID, teamID) => {
  return (dispatch) => {
    return axios.delete(`/api/teams/${teamID}/members/${memberID}`).then(() => {
      dispatch(fetchTeamMembers(teamID));
    });
  };
};

export const deleteCompanyMember = (companyID, memberID) => {
  return (dispatch) => {
    return axios
      .delete(`/api/companies/${companyID}/members/${memberID}`)
      .then(() => {
        dispatch(fetchCompanyMembers(companyID));
      });
  };
};

export const totalWorkspacesOfMember = (memberID) => {
  return axios.get(`/api/users/${memberID}/totalWorkspaces`).then((res) => {
    return res.data;
  });
};

export const totalTeamsOfMember = (memberID) => {
  return axios.get(`/api/users/${memberID}/totalTeams`).then((res) => {
    return res.data;
  });
};
