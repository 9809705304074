import React from "react";
import _ from "lodash";
import { defineMessages } from "react-intl";
import GoalListItem from "../../GoalListItem";
import {
  getRelationshipTypeOptions,
  defaultGoalsDisplayOrder,
} from "../../../../constants/goalsAndBonus";
import { translatedText } from "../../../../utils/translationUtils";
import utils from "../../../../utils/toolUtils";
import gbUtils from "../../../../utils/goalsBonus";
import { getHitTargetInfo } from "../../../../utils/kpis/display";
import { multipleIndicatorsWithBonus } from "../../../../utils/goalsBonus/calc";

const { getObjectInfoById, reorderObjectBySlugs } = utils;
const { exceededTargetMaxValue } = gbUtils;

const messages = defineMessages({
  noDataToDisplay: {
    id: "global.noDataTable",
  },
  gb_table_text_goals_upper: {
    id: "gb_table_text_goals_upper",
  },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

const GoalEvaluations = ({
  goals = [],
  clearBonusGateMin,
  goalTargets = {},
  selectAndOpenInsight,
  isPeriodFilterActive,
}) => {
  const relationshipTypeOptions = getRelationshipTypeOptions();

  const reorderedGoals = reorderObjectBySlugs(
    defaultGoalsDisplayOrder,
    goals,
    "relationshipType",
  );

  return (
    <div className="evaluation-card">
      <div className="card-header">
        <div className="card-title">
          <h4>{translation("gb_table_text_goals_upper")}</h4>
        </div>
      </div>
      <div style={{ padding: "20px 0px 20px 15px" }}>
        <div className="card-body max-height-800" style={{ padding: "0px" }}>
          <div className="right-spacing">
            {reorderedGoals.map((goalInfo) => {
              const {
                realGoalsReachSum,
                evaluatedGoals,
              } = multipleIndicatorsWithBonus(
                [goalInfo],
                goalTargets,
                clearBonusGateMin,
                isPeriodFilterActive,
              );

              const notStarted = evaluatedGoals.length === 0;

              const totalHitTargetInfo = getHitTargetInfo(
                realGoalsReachSum,
                goalTargets,
                notStarted,
              );

              const { label = null } = getObjectInfoById(
                goalInfo.relationshipType,
                relationshipTypeOptions,
                "value",
              );
              const { relatedInsights = [] } = goalInfo;

              return (
                <GoalListItem
                  key={goalInfo.id}
                  title={goalInfo.title}
                  description={label}
                  currentPercentage={realGoalsReachSum}
                  notStarted={notStarted}
                  maxPercentage={exceededTargetMaxValue(goalTargets.exceeded)}
                  progressColor={totalHitTargetInfo.color}
                  barTotalWidth={100}
                  openInsight={() => selectAndOpenInsight(relatedInsights[0])}
                />
              );
            })}
            {reorderedGoals.length === 0 && (
              <h5 align="center">{translation("noDataToDisplay")}</h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function areEqual(prev, next) {
  if (prev.calcType !== next.calcType) return false;
  if (!_.isEqual(prev.periodFilter, next.periodFilter)) return false;
  if (!_.isEqual(prev.goals, next.goals)) return false;
  if (!_.isEqual(prev.displayParticipants, next.displayParticipants))
    return false;

  return true;
}

export default React.memo(GoalEvaluations, areEqual);
