import { getValidId, getValidEntityConfigs } from "../validations";

export const buildPositionEntity = (position = {}, config) => {
  const { stringValue, idValue, arrayValue } = getValidEntityConfigs(config);

  return {
    id: getValidId(position.id, idValue),
    parentPositionId: getValidId(position.parentPositionId, idValue),
    title: position.title || stringValue,
    description: position.description || stringValue,
    startDate: position.startDate || stringValue,
    endDate: position.endDate || stringValue,
    childPositions: position.childPositions || arrayValue,
    active: position.active === false ? false : true,
  };
};

export const buildRestPositionEntity = (position = {}) => {
  return buildPositionEntity(position, {
    stringValue: null,
    arrayValue: [],
    idValue: null,
    active: true,
  });
};

export const buildFormPositionEntity = (position = {}) => {
  return buildPositionEntity(position, {
    stringValue: "",
    arrayValue: [],
    idValue: null,
    active: true,
  });
};
