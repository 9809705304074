import { useState, useEffect } from "react";
import { getItemsByPage } from "../ArrayUtils";

export const usePaginationList = ({ list, page, pageSize = 10 }) => {
  const [totalPages, setTotalPages] = useState(0);
  const [pageItems, setPageItems] = useState([]);

  useEffect(() => {
    const newPageItems = getItemsByPage(list, page, pageSize);
    const newTotalPages =
      list.length > 0 ? Math.ceil(list.length / pageSize) : 0;

    setPageItems(newPageItems);
    setTotalPages(newTotalPages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, page, pageSize]);

  return {
    totalPages,
    pageItems,
  };
};
