import React, { useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import SimpleCheckbox from "../../../../../../Common/SimpleCheckbox";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import MaterialSingleSelect from "../../../../../../Common/MaterialSingleSelect";
import { orderArrayByObjAttr } from "../../../../../../../utils/ArrayUtils";

const Wrapper = styled.div`
  padding: 15px;
`;

const Customization = ({
  configuration,
  importConfig,
  initImportConfig,
  updateSingleImportConfig,
  customPeriods,
}) => {
  useEffect(() => {
    initImportConfig({
      planningConfig: true,
      indicators: true,
      board: true,
      actionPlans: true,
      moveIndicatorsToYear: moment(configuration.start_date).year() || "",
      custom_period_id: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CheckBoxLine = ({ field, label, disabled }) => {
    return (
      <tr>
        <td width="35">
          <SimpleCheckbox
            checked={disabled ? false : importConfig[field]}
            onChange={() => {
              updateSingleImportConfig(field, !importConfig[field]);
            }}
          />
        </td>
        <td>{label}</td>
      </tr>
    );
  };

  const notCompleteToBoardImport =
    !importConfig.indicators || !importConfig.planningConfig;
  const cantImportBoard = notCompleteToBoardImport || !importConfig.board;

  return (
    <Wrapper>
      <h4>Selecione o período para importação</h4>
      <MaterialSingleSelect
        label="Período"
        value={importConfig.custom_period_id}
        options={orderArrayByObjAttr(customPeriods, "title").map(
          (periodInfo) => {
            return {
              value: periodInfo.id,
              label: periodInfo.title,
            };
          },
        )}
        onChange={(e) =>
          updateSingleImportConfig("custom_period_id", e.target.value)
        }
      />
      <table className="simple-table" style={{ marginBottom: "5px" }}>
        <thead>
          <tr>
            <td colSpan="100">ADMINISTRAÇÃO</td>
          </tr>
        </thead>
        <tbody>
          <CheckBoxLine
            field="planningConfig"
            label="Importar Configurações de Planejamento"
          />
          <CheckBoxLine field="indicators" label="Importar Indicadores" />
        </tbody>
      </table>
      <table className="simple-table" style={{ margin: "0px" }}>
        <tbody>
          <tr style={{ opacity: !importConfig.indicators ? "0.5" : "1" }}>
            <td>Novos indicadores serão salvos para o ano:</td>
            <SimpleExcelCollumn
              tdStyle={{
                padding: "0px",
                borderBottom: "1px solid #ccc",
              }}
              value={importConfig.moveIndicatorsToYear}
            />
          </tr>
        </tbody>
      </table>
      <div style={{ padding: "5px 0px" }}>
        <b>OBS:</b> O resultado do ano anterior sera preenchido automaticamente
        nesta importação.
      </div>
      <table
        className="simple-table"
        style={{
          marginBottom: "5px",
          opacity: notCompleteToBoardImport ? "0.5" : "1",
        }}
      >
        <thead>
          <tr>
            <td colSpan="100">QUADRO</td>
          </tr>
        </thead>
        <tbody>
          <CheckBoxLine
            field="board"
            label="Importar estrutura do quadro"
            disabled={cantImportBoard}
          />
        </tbody>
      </table>
      <div style={{ padding: "5px 0px" }}>
        <b>OBS:</b> Você poderá remover ou editar a estrutura do quadro depois
        de importar.
      </div>
      <table
        className="simple-table"
        style={{
          marginBottom: "5px",
          opacity: cantImportBoard ? "0.5" : "1",
        }}
      >
        <thead>
          <tr>
            <td colSpan="100">PLANOS DE AÇÃO</td>
          </tr>
        </thead>
        <tbody>
          <CheckBoxLine
            field="actionPlans"
            label="Importar todas as ações que não foram completadas ou canceladas"
            disabled={cantImportBoard}
          />
        </tbody>
      </table>
    </Wrapper>
  );
};

export default Customization;
