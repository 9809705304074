import { getParticipantPeriodOptions } from "../../constants/goalsAndBonus";
import { getObjectInfoById } from "../ArrayUtils";
import { ruleOfThree } from "../MathUtils";

export const getSalariesConfig = (roleBonus, workingTime) => {
  const salariesConfig = {
    gate: roleBonus.gate,
    appropriated: roleBonus.appropriated,
    exceeded: roleBonus.exceeded,
  };

  const monthPeriodOptions = getParticipantPeriodOptions();
  const workingTimePeriod = getObjectInfoById(
    workingTime,
    monthPeriodOptions,
    "value",
  ).month;

  salariesConfig.gate = ruleOfThree(12, workingTimePeriod, roleBonus.gate);
  salariesConfig.appropriated = ruleOfThree(
    12,
    workingTimePeriod,
    roleBonus.appropriated,
  );
  salariesConfig.exceeded = ruleOfThree(
    12,
    workingTimePeriod,
    roleBonus.exceeded,
  );

  return salariesConfig;
};
