import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormDialog from "../../../../../../Common/FormDialog";
import MaterialTextField from "../../../../../../Common/MaterialTextField";
import { addNewParticipants } from "../../../../../../../utils/skillsAssessment/manipulation";
import { fetchAllUsersCompanyProfiles } from "../../../../../../../actions/userActions";
import {
  getSelectedCompanyStates,
  getUserProfileStates,
  getCompanyMembersAndParticipants,
} from "../../../../../../customMapStates";
import { getDisplayMembers } from "../../../../../../../utils/skillsAssessment/display";
import SelectCompanyMember from "../../../../../../SelectCompanyMember";
import { Errors } from "../../../../../../../utils/validations";

const NewParticipant = ({
  open,
  afterConfirm,
  afterCancel,
  toolAdministration,
  selectedCompany,
  companyMembersAndParticipants,
  fetchAllUsersCompanyProfiles,
  professionalProfiles,
  enableEvaluatorsSelectionWorkflow,
}) => {
  const [form, setForm] = useState([]);
  const [memberModalOpen, setMemberModalOpen] = useState(false);

  const { participants = [] } = toolAdministration;
  const selectedCompanyId = selectedCompany.id ? selectedCompany.id : null;

  const allAddedMemberParticipants = [
    ...participants.map(({ memberParticipant = {} }) => memberParticipant.id),
  ];

  useEffect(() => {
    if (selectedCompanyId) {
      fetchAllUsersCompanyProfiles(selectedCompanyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanyId]);

  const resetForm = () => {
    setForm([]);
  };

  const handleConfirm = async () => {
    const newAdministration = await addNewParticipants(
      toolAdministration,
      form,
      professionalProfiles,
      enableEvaluatorsSelectionWorkflow,
    );

    afterConfirm(newAdministration);
    resetForm();
  };

  const handleCancel = () => {
    afterCancel();
    resetForm();
  };

  const validateForm = () => {
    const errors = new Errors();

    if (form.length === 0) {
      errors.add("select_some");
    }

    return { isValid: !errors.hasErrors(), errors: errors.getErrors() };
  };

  const handleSelectedParticipants = (selected) => {
    setForm(selected);
    setMemberModalOpen(false);
  };

  const formTitle = "Adicionar Participantes para Avaliação";
  const { isValid } = validateForm();

  const displayMembers = getDisplayMembers(
    companyMembersAndParticipants,
    form,
    "name",
  );

  return (
    <FormDialog
      open={open}
      title={formTitle}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      blockConfirm={!isValid}
    >
      <SelectCompanyMember
        open={memberModalOpen}
        title={formTitle}
        onConfirm={(selectedList) => handleSelectedParticipants(selectedList)}
        onCancel={() => setMemberModalOpen(false)}
        selected={form}
        exeptions={allAddedMemberParticipants}
      />
      <div className="row">
        <div className="col-xs-12">
          <MaterialTextField
            id="newParticipantsSelect"
            label="Participantes selecionados"
            value={displayMembers}
            title={displayMembers}
            onClick={() => setMemberModalOpen(true)}
          />
          <h5 className="text-danger">
            Filtrando apenas para membros que ainda não foram adicionados à
            ferramenta.
          </h5>
        </div>
      </div>
    </FormDialog>
  );
};

const mapStateToProps = (state) => {
  const { selectedCompany } = getSelectedCompanyStates(state);
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);
  const { selectedCompanyProfessionalProfiles } = getUserProfileStates(state);
  const { enableEvaluatorsSelectionWorkflow } = state.skillAssessment.all[0];

  return {
    selectedCompany,
    companyMembersAndParticipants,
    professionalProfiles: selectedCompanyProfessionalProfiles,
    enableEvaluatorsSelectionWorkflow,
  };
};

NewParticipant.propTypes = {
  open: PropTypes.bool,
  afterConfirm: PropTypes.func.isRequired,
  afterCancel: PropTypes.func.isRequired,
  toolAdministration: PropTypes.object.isRequired,
  selectedCompany: PropTypes.object.isRequired,
  fetchAllUsersCompanyProfiles: PropTypes.func.isRequired,
  companyMembersAndParticipants: PropTypes.array.isRequired,
  professionalProfiles: PropTypes.array.isRequired,
};

NewParticipant.defaultProps = {
  open: false,
};

export default connect(mapStateToProps, { fetchAllUsersCompanyProfiles })(
  NewParticipant,
);
