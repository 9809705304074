import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import MaterialSingleSelect from "./MaterialSingleSelect";

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      "& > div:before": {
        borderBottom: "none !important",
      },
      "& > div > div.MuiInputAdornment-positionEnd": {
        position: "relative",
        left: "-12px",
      },
    },
  }),
);

const SimpleSelectExcelCollumn = (props) => {
  const {
    id,
    label,
    value,
    options,
    onChange,
    defaultOption = true,
    customStyles = {},
    fullWidth = true,
    colSpan = "1",
    tdClass = "",
    notAllowed = false,
    isRequired = false,
    disabled = false,
  } = props;
  const classes = useStyles();

  const tdStyles = props.tdStyles || {};

  return (
    <td
      style={{ padding: "0px", backgroundColor: "#f6f6f6", ...tdStyles }}
      colSpan={colSpan}
      className={tdClass}
    >
      <MaterialSingleSelect
        id={id}
        label={label}
        value={value}
        onChange={(e) => onChange(e)}
        options={options}
        variant="standard"
        customStyles={{
          padding: "0px 10px",
          height: "40px",
          ...customStyles,
        }}
        className={classes.textField}
        hideLabel
        margin="none"
        defaultOption={defaultOption}
        fullWidth={fullWidth}
        notAllowed={notAllowed}
        isRequired={isRequired}
        disabled={disabled}
      />
    </td>
  );
};

export default SimpleSelectExcelCollumn;
