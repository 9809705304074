import {
  addCascadeAccess,
  getUserAccess,
  addAccess,
  updateUserRoleAccess,
  removeAccesses,
} from "./accessLevelsActions";
import {
  getCascadeUpInfo,
  getUsersWithActionTypes,
  getGbInsightUsersWithActionTypes,
  getGbAdmUsersWithActionTypes,
  getSkillsAdmUsersWithActionTypes,
  getCurrentDomainUserId,
  getCurrentUserInfo,
  canUserAccessWorkspace,
  canUserAccessCompany,
  getToolID,
  getCascadeUpAccesses,
  getCascadeIDs,
  getCustomUsersWithActionsTypes,
  getUserById,
  getSelectedInsight,
} from "../utils/accessLevels";
import { unsetSelectedWorkspace } from "./workspaceActions";
import { resetSelectedEntityStorage } from "./companyActions";
import { GOALS_AND_BONUS } from "../constants/tools.constants";
import {
  sendNewAdminNotification,
  sendNewModeratorNotification,
  sendNewLeaderNotification,
  sendNewToolViewerNotification,
  sendNewManagerNotification,
  sendNewSceneryViewerNotification,
} from "./notifications/controllerActions";

export const handleUserId = (userId = null) => {
  return userId || getCurrentUserInfo().id;
};

export const accessLevelsEssentials = () => {
  const userCanAccessSelectedWorkspace = canUserAccessWorkspace();
  const userCanAccessSelectedCompany = canUserAccessCompany();

  return (dispatch) => {
    if (!userCanAccessSelectedWorkspace) dispatch(unsetSelectedWorkspace());
    if (!userCanAccessSelectedCompany) dispatch(resetSelectedEntityStorage());
  };
};

export const grantCompanyRestrictAccess = (companyId = 0, userId = 0) => {
  const currentUserId = handleUserId(userId);

  return async (dispatch) => {
    await dispatch(addAccess(companyId, "COMPANY_RESTRICT", currentUserId));
    await dispatch(updateUserAccess(currentUserId));
  };
};

export const grantNewRoleAndAccess = (body = {}) => {
  const { userId = null, newUserAccess = "" } = body;

  const domainUserId = getCurrentDomainUserId(userId);

  //const isRoleUser = userHaveRole(userId, body.entityId, "tool");

  delete body.userId;
  delete body.newUserAccess;

  return async (dispatch) => {
    await dispatch(updateUserRoleAccess(body, { domainUserId, newUserAccess }));
    await dispatch(updateUserAccess(userId));
  };
};

export const grantOwnerAccess = (domainId = "", userId = null) => {
  const currentUserId = handleUserId(userId);

  return async (dispatch) => {
    await dispatch(addAccess(domainId, "DOMAIN", currentUserId));
    await dispatch(updateUserAccess(currentUserId));
  };
};

export const grantModeratorAccess = (workspaceId = 0, userId = null) => {
  const currentUserId = handleUserId(userId);

  return async (dispatch) => {
    await dispatch(addAccess(workspaceId, "WORKSPACE", currentUserId));
    await dispatch(updateUserAccess(currentUserId));
    userId &&
      (await dispatch(sendNewModeratorNotification(workspaceId, userId)));
  };
};

export const grantAdminAccess = (selectedCompanyId = 0, userId = null) => {
  const currentUserId = handleUserId(userId);

  return async (dispatch) => {
    await dispatch(addAccess(selectedCompanyId, "COMPANY", currentUserId));
    await dispatch(updateUserAccess(currentUserId));
    userId &&
      (await dispatch(sendNewAdminNotification(selectedCompanyId, userId)));
  };
};

export const grantCreatorAccess = (userId = null, insightId = null) => {
  const currentUserId = handleUserId(userId);

  const cascadeAccessBody = [
    ...getCascadeUpInfo(currentUserId, 0, {
      passEntityId: insightId,
      passEntityRole: "CREATOR",
      passEntityAccess: "EDIT",
    }),
  ];

  return async (dispatch) => {
    await dispatch(addCascadeAccess(cascadeAccessBody));
    await dispatch(updateUserAccess(currentUserId));
  };
};

export const grantResponsibleAccess = (userId = null, insightId = null) => {
  const currentUserId = handleUserId(userId);

  const cascadeAccessBody = [
    ...getCascadeUpInfo(currentUserId, 0, {
      passEntityId: insightId,
      passEntityRole: "RESPONSIBLE",
      passEntityAccess: "EDIT",
    }),
  ];

  return async (dispatch) => {
    await dispatch(addCascadeAccess(cascadeAccessBody));
    await dispatch(updateUserAccess(currentUserId));
  };
};

export const grantCollaboratorAccess = (userId = null, insightId = null) => {
  const currentUserId = handleUserId(userId);

  const cascadeAccessBody = [
    ...getCascadeUpInfo(currentUserId, 0, {
      passEntityId: insightId,
      passEntityRole: "COLLABORATOR",
      passEntityAccess: "RESTRICT",
    }),
  ];

  return async (dispatch) => {
    await dispatch(addCascadeAccess(cascadeAccessBody));
    await dispatch(updateUserAccess(currentUserId));
  };
};

export const grantInsightAccess = ({ id = 0, entityId = false, role = "" }) => {
  return async (dispatch) => {
    const cascadeAccessBody = [
      ...getCascadeUpInfo(id, 0, {
        passEntityRole: role,
        passEntityId: entityId,
        passEntityAccess: "EDIT",
      }),
    ];

    await dispatch(addCascadeAccess(cascadeAccessBody));
    await dispatch(updateUserAccess(id));
  };
};

export const removeInsightAccess = (userToRemoveAccess) => {
  const { id = 0, entityId = 0, role = "" } = userToRemoveAccess;

  return async (dispatch) => {
    const domainUserId = getCurrentDomainUserId(id);
    const insightEntity = {
      entityId,
      entityType: "INSIGHT",
      role: role.toUpperCase(),
    };

    await dispatch(await removeAccesses([insightEntity], domainUserId));
    await dispatch(await updateUserAccess(id));
  };
};

export const removeToolAccess = ({
  id = 0,
  entityId = 0,
  role = "",
  accessType = null,
}) => {
  return async (dispatch) => {
    const cascadeUpInfo = await getCascadeUpAccesses(id, 2, {
      entityId,
      entityType: "SELECTED_TOOL",
      role: role.toUpperCase(),
      accessType,
    });

    const domainUserId = getCurrentDomainUserId(id);

    await dispatch(removeAccesses(cascadeUpInfo, domainUserId));
    await dispatch(updateUserAccess(id));
  };
};

export const removeSceneryAccess = ({
  id = 0,
  entityId = 0,
  role = "",
  accessType = "",
}) => {
  return async (dispatch) => {
    const cascadeUpInfo = await getCascadeUpAccesses(id, 1, {
      entityId,
      entityType: "CENARY",
      role: role.toUpperCase(),
      accessType,
    });
    const domainUserId = getCurrentDomainUserId(id);

    await dispatch(removeAccesses(cascadeUpInfo, domainUserId));
    await dispatch(updateUserAccess(id));
  };
};

export const checkToolSwitchedAccesses = async (gbConfig = {}) => {
  return async (dispatch) => {
    const toolID = getToolID();

    if (toolID === GOALS_AND_BONUS)
      return dispatch(checkGbSwitchedAccesses(gbConfig));

    const selectedInsight = getSelectedInsight();
    const allUsers = await getUsersWithActionTypes(selectedInsight);

    if (!allUsers || !selectedInsight) return;

    await Promise.all(
      allUsers.map(async (user = {}) => {
        const { id = null, entityId = null } = user;

        const { type = false } = getUserById(user) || {};

        if (!id || !type || type === "PARTICIPANT") return;

        if (!entityId) return await dispatch(await grantInsightAccess(user));

        await dispatch(await removeInsightAccess(user));
      }),
    );
  };
};

export const checkEntitySwitchedAccesses = (insightIds = false) => {
  return async (dispatch) => {
    let allUsers = [];

    allUsers = await getCustomUsersWithActionsTypes(insightIds);

    if (!allUsers) return;

    allUsers.map(async (user = {}) => {
      const { id = null, entityId = null, insightId = "" } = user;

      const { type = false } = getUserById(user) || {};

      if (!id || !type || type === "PARTICIPANT") return;

      if (!entityId)
        return dispatch(grantInsightAccess({ ...user, entityId: insightId }));

      dispatch(removeInsightAccess(user));
    });
  };
};

export const checkSkillAssessmentAdmAccesses = () => {
  return async (dispatch) => {
    const allUsers = await getSkillsAdmUsersWithActionTypes();

    if (!allUsers) return;

    allUsers.forEach(async (user = {}) => {
      const { id = null, role = null, access = null, entityId = null } = user;

      if (!id) return;

      if (!entityId) return dispatch(grantToolAccess(id, role, access));

      dispatch(removeToolAccess(user));
    });
  };
};

export const checkGbSwitchedAccesses = (config = {}) => {
  const { insightConfiguration = false } = config;

  return async (dispatch) => {
    let allUsers = [];

    if (insightConfiguration) {
      allUsers = await getGbInsightUsersWithActionTypes();
    } else {
      allUsers = await getGbAdmUsersWithActionTypes();
    }

    if (!allUsers) return;

    allUsers.forEach(async (user = {}) => {
      const {
        id = null,
        role = null,
        access = null,
        entityId = null,
        typeInsight = false,
      } = user;

      if (!id) return;

      if (typeInsight) return dispatch(removeInsightAccess(user));

      if (!entityId) return dispatch(grantToolAccess(id, role, access));

      dispatch(removeToolAccess(user));
    });
  };
};

export const updateUserAccess = (userId = 0) => {
  const currentUserId = getCurrentDomainUserId();
  const selectedUserId = getCurrentDomainUserId(userId);

  if (selectedUserId === currentUserId || currentUserId === userId) {
    return async (dispatch) => {
      await dispatch(getUserAccess(currentUserId));
    };
  }
};

export const grantToolAccess = (
  userId = null,
  role = "",
  access = "",
  toolId = false,
) => {
  const currentUserId = handleUserId(userId);

  return async (dispatch) => {
    const cascadeAccessBody = [
      ...getCascadeUpInfo(currentUserId, 2, {
        passEntityId: toolId,
        passEntityRole: role,
        passEntityAccess: access,
      }),
    ];

    await dispatch(addCascadeAccess(cascadeAccessBody));
    await dispatch(updateUserAccess(currentUserId));

    if (!userId) return;

    const { selectedToolID = 0 } = getCascadeIDs();

    if (role === "VIEWER")
      await dispatch(
        sendNewToolViewerNotification(toolId || selectedToolID, userId),
      );
    if (role === "MANAGER")
      await dispatch(
        sendNewManagerNotification(toolId || selectedToolID, userId),
      );
  };
};

export const grantSceneryAccess = (userId = null, role = "", access = "") => {
  const currentUserId = handleUserId(userId);

  return async (dispatch) => {
    const cascadeAccessBody = [
      ...getCascadeUpInfo(currentUserId, 1, {
        passEntityRole: role,
        passEntityAccess: access,
      }),
    ];

    await dispatch(addCascadeAccess(cascadeAccessBody));
    await dispatch(updateUserAccess(currentUserId));

    if (!userId) return;

    const { selectedSceneryID: sceneryId = 0 } = getCascadeIDs();

    if (role === "LEADER")
      await dispatch(sendNewLeaderNotification(sceneryId, userId));
    if (role === "VIEWER")
      await dispatch(sendNewSceneryViewerNotification(sceneryId, userId));
  };
};
