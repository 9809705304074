import React, { useState } from "react";
import PropTypes from "prop-types";
import FormDialog from "../../../../../../Common/FormDialog";
import MaterialTextField from "../../../../../../Common/MaterialTextField";
import { getCompetenceAreasEntity } from "../../../../../../../utils/skillsAssessment/entity";
import { addNewCompetenceArea } from "../../../../../../../utils/skillsAssessment/manipulation";
import { getDisplayProfiles } from "../../../../../../../utils/skillsAssessment/display";
import SelectProfile from "./SelectProfile";
import {
  validateCompetenceAreas,
  validateNewCompetenceArea,
} from "../../../../../../../utils/skillsAssessment/validation";
import { getStyledErrorMessage } from "../../../../../../../utils/formValidation";
import { Errors } from "../../../../../../../utils/validations";

const defaultForm = getCompetenceAreasEntity([{}], {
  canInsertNewId: true,
})[0];

const NewCompetenceArea = ({
  open,
  afterConfirm,
  afterCancel,
  toolAdministration,
}) => {
  const [selectModal, setSelectModal] = useState({
    type: null,
    onConfirm: () => {},
    onCancel: () => {},
  });
  const [form, setForm] = useState(defaultForm);

  const { profiles, competenceAreas } = toolAdministration;

  const handleConfirm = () => {
    const newAdministration = addNewCompetenceArea(toolAdministration, form);

    afterConfirm(newAdministration);
  };

  const handleCancel = () => {
    afterCancel();
  };

  const closeSelectModal = () => {
    setSelectModal({ type: null, onConfirm: () => {}, onCancel: () => {} });
  };

  const updateField = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const selectProfiles = (selected) => {
    setForm({ ...form, profiles: selected });
    closeSelectModal();
  };

  const openSelectProfile = () => {
    setSelectModal({
      type: "select_profile",
      onConfirm: (selected) => selectProfiles(selected),
      onCancel: closeSelectModal,
    });
  };

  const validateForm = () => {
    const errors = new Errors();

    errors.addMultiple(validateNewCompetenceArea(form, competenceAreas));
    errors.addMultiple(validateCompetenceAreas([form]));

    return { isValid: !errors.hasErrors(), errors: errors.getErrors() };
  };

  const formTitle = "Adicionar Área de Competência";
  const { isValid, errors } = validateForm();

  const displayProfiles = getDisplayProfiles(profiles, form.profiles);

  return (
    <FormDialog
      open={open}
      title={formTitle}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      blockConfirm={!isValid}
    >
      <SelectProfile
        open={selectModal.type === "select_profile"}
        profiles={profiles}
        onConfirm={selectModal.onConfirm}
        onCancel={selectModal.onCancel}
        initialSelected={form.profiles}
      />
      <div className="row">
        <div className="col-xs-12">
          <MaterialTextField
            id="competenceAreaTitle"
            label="Título"
            value={form.title}
            onChange={(e) => updateField("title", e.target.value)}
          />
          <MaterialTextField
            id="competenceAreaTitle"
            label="Descrição"
            value={form.description}
            onChange={(e) => updateField("description", e.target.value)}
            multiline
            rows={4}
            primaryInputProps={{ maxLength: 1000 }}
          />
        </div>
        <div className="col-xs-12">
          <MaterialTextField
            id="competenceAreaTitle"
            label="Perfís"
            value={displayProfiles}
            onClick={openSelectProfile}
          />
        </div>
        <div className="col-xs-12">
          {getStyledErrorMessage(["duplicated_title"], errors)}
        </div>
      </div>
    </FormDialog>
  );
};

NewCompetenceArea.propTypes = {
  open: PropTypes.bool,
  afterConfirm: PropTypes.func.isRequired,
  afterCancel: PropTypes.func.isRequired,
  toolAdministration: PropTypes.object.isRequired,
};

NewCompetenceArea.defaultProps = {
  open: false,
};

export default NewCompetenceArea;
