import React from "react";
import PropTypes from "prop-types";

const SubtitleBadge = ({ caption, title, color }) => {
  const styles = {
    color: "#333",
    backgroundColor: color,
    minWidth: "25px",
    height: "25px",
    padding: "5px",
    textAlign: "center",
    border: "1px solid #444",
    lineHeight: "15px",
  };

  return (
    <div title={title} style={styles}>
      {caption}
    </div>
  );
};

SubtitleBadge.propTypes = {
  caption: PropTypes.node.isRequired,
  color: PropTypes.string,
};

SubtitleBadge.defaultProps = {
  color: "#ccc",
};

export default SubtitleBadge;
