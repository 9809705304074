import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";

import { getSelectedToolStates } from "../../../../customMapStates";
import utils from "../../../../../utils/toolUtils";

const {
  getToolProjectAdministration,
  getInsightProjectPrioritization,
  getProjectTypeByConfigurationId,
  getOnlyCriterias,
  getOnlyProjectTypes,
  orderArrayByObjAttr,
  sumArrays,
} = utils;

const ProjectPrioritization = (props) => {
  const [activeTab, setActiveTab] = useState("tables");
  const [typeFilter, setTypeFilter] = useState(null);
  const [pathwayFilter, setPathwayFilter] = useState(null);

  const {
    allQuestions,
    allAnswers,
    allProjectPrioritization,
    allInsightPrioritizations,
    filterData,
    selectedTool,
  } = props;

  const toolProjectAdministration = getToolProjectAdministration(
    selectedTool.id,
    allProjectPrioritization,
  );

  const onlyProjectTypes = getOnlyProjectTypes(
    toolProjectAdministration.projectGroups,
  );

  let { criteriaConfigurations = [] } = toolProjectAdministration;
  criteriaConfigurations = orderArrayByObjAttr(criteriaConfigurations, "title");

  const handleSetFilter = (questionId) => {
    if (typeFilter !== questionId) {
      setTypeFilter(questionId);
    } else {
      setTypeFilter(null);
    }
  };

  const getQuestionInsights = (questionID) => {
    return allAnswers[questionID] ? allAnswers[questionID] : [];
  };

  const getSingleProjectType = (themeID) => {
    let processes = [];
    if (allQuestions && allQuestions[themeID]) {
      allQuestions[themeID].forEach((questionBody) => {
        let haveInsights = false;
        const questionAnswers = getQuestionInsights(questionBody.id);
        if (questionAnswers.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const index in questionAnswers) {
            if (questionAnswers[index].type === "insight") {
              haveInsights = true;
              break;
            }
          }
        }
        if (haveInsights) processes = [...processes, questionBody];
      });
    }

    return processes;
  };

  const getFilteredProjectTypes = () => {
    let finalProjects = [];
    if (filterData.themes) {
      Object.keys(allQuestions).forEach((themeID) => {
        themeID = parseInt(themeID, 10);
        const inArray = filterData.themes.indexOf(themeID) > -1;
        if (inArray) {
          const projectTypes = getSingleProjectType(themeID);
          finalProjects = [...finalProjects, ...projectTypes];
        }
      });
    }

    return finalProjects;
  };

  const filterProjects = (projects) => {
    let finalProjects = [];

    if (typeFilter) {
      projects.forEach((projectInfo) => {
        if (projectInfo.questionId === typeFilter)
          finalProjects.push(projectInfo);
      });
    } else {
      finalProjects = projects;
    }

    return finalProjects;
  };

  const handlePathwayFilterChange = (pathway) => {
    if (pathwayFilter === pathway) {
      setPathwayFilter(null);
    } else {
      setPathwayFilter(pathway);
    }
  };

  const displayProjectPrioritizationTable = () => {
    const projectTypes = getFilteredProjectTypes();

    const projectTypesDisplay = [
      ...projectTypes.map((questionBody) => {
        const questionInsights = getQuestionInsights(questionBody.id);
        const { projectPriorizationConfigurationTypeId = null } = questionBody;

        const projectType = getProjectTypeByConfigurationId(
          projectPriorizationConfigurationTypeId,
          onlyProjectTypes,
        );
        const projectTypeGroup = projectType.criteriaGroup || {};
        const onlyCriterias = getOnlyCriterias(projectTypeGroup.criterias);

        const evaluations = [];

        questionInsights.forEach((insightInfo) => {
          const {
            projectPriorizationEvaluation,
          } = getInsightProjectPrioritization(
            insightInfo.id,
            allInsightPrioritizations,
            onlyCriterias.length,
          );

          const projectEvaluation = [];

          criteriaConfigurations.forEach((criteriaConfigInfo) => {
            onlyCriterias.forEach((projectCriteriaInfo) => {
              if (criteriaConfigInfo.id === projectCriteriaInfo.criteria.id) {
                projectEvaluation.push(
                  projectPriorizationEvaluation[projectCriteriaInfo.id],
                );
              }
            });
          });

          evaluations.push(projectEvaluation);
        });

        const totalEvaluation = sumArrays(evaluations);

        const totalSum = totalEvaluation.reduce((a, b) => a + b, 0);

        return {
          type: questionBody.text,
          projects: questionInsights.length,
          totalEvaluation,
          totalSum,
          question: questionBody,
        };
      }),
    ];

    const projects = [];

    Object.keys(projectTypes).forEach((index) => {
      const questionBody = projectTypes[index];
      const questionInsights = getQuestionInsights(questionBody.id);
      const { projectPriorizationConfigurationTypeId = null } = questionBody;

      const projectType = getProjectTypeByConfigurationId(
        projectPriorizationConfigurationTypeId,
        onlyProjectTypes,
      );
      const projectTypeGroup = projectType.criteriaGroup || {};
      const onlyCriterias = getOnlyCriterias(projectTypeGroup.criterias);

      questionInsights.forEach((insightInfo) => {
        const {
          projectPriorizationEvaluation,
          sumEvaluation,
          pathway,
        } = getInsightProjectPrioritization(
          insightInfo.id,
          allInsightPrioritizations,
          onlyCriterias.length,
        );

        const projectEvaluation = [];

        criteriaConfigurations.forEach((criteriaConfigInfo) => {
          onlyCriterias.forEach((projectCriteriaInfo) => {
            if (criteriaConfigInfo.id === projectCriteriaInfo.criteria.id) {
              projectEvaluation.push(
                projectPriorizationEvaluation[projectCriteriaInfo.id],
              );
            }
          });
        });

        projects.push({
          title: insightInfo.text,
          projectEvaluation,
          sum: sumEvaluation,
          pathway,
          questionId: questionBody.id,
        });
      });
    });

    let filteredProjects = filterProjects(projects);

    filteredProjects = pathwayFilter
      ? filteredProjects.filter((project) => {
          return project.pathway === pathwayFilter;
        })
      : filteredProjects;

    return (
      <div className="row" style={{ marginBottom: "30px" }}>
        <div className="col-xs-12">
          <table className="simple-table">
            <thead>
              <tr>
                <td style={{ width: "200px" }}>PROJECT TYPE</td>
                {criteriaConfigurations.map(({ title }, index) => (
                  <td style={{ width: "100px" }} key={index}>
                    {title}
                  </td>
                ))}
                <td style={{ width: "100px" }}>PROJECTS</td>
                <td style={{ width: "100px" }}>SUM</td>
              </tr>
            </thead>
            <tbody>
              {projectTypesDisplay.map((typeInfo, index) => {
                const { totalEvaluation } = typeInfo;

                if (totalEvaluation.length < criteriaConfigurations.length) {
                  for (
                    let i = totalEvaluation.length;
                    i < criteriaConfigurations.length;
                    i++
                  ) {
                    totalEvaluation.push(0);
                  }
                }

                return (
                  <tr
                    key={index}
                    onClick={() => handleSetFilter(typeInfo.question.id)}
                  >
                    <td
                      style={
                        typeFilter === typeInfo.question.id
                          ? { borderLeft: "4px solid #6b42a9" }
                          : {}
                      }
                    >
                      {typeInfo.type}
                    </td>
                    {totalEvaluation.map((value, index) => (
                      <td align="center" key={index}>
                        {!isNaN(value) ? value : ""}
                      </td>
                    ))}
                    <td align="center">{typeInfo.projects}</td>
                    <td align="center">
                      {!isNaN(typeInfo.totalSum) ? typeInfo.totalSum : 0}
                    </td>
                  </tr>
                );
              })}
              {projectTypesDisplay.length === 0 && (
                <tr>
                  <td colSpan="100">No data to show</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="col-xs-12">
          <table className="simple-table">
            <thead>
              <tr>
                <td style={{ width: "200px" }}>Projeto</td>
                {criteriaConfigurations.map(({ title }, index) => (
                  <td style={{ width: "100px" }} key={index}>
                    {title}
                  </td>
                ))}
                <td style={{ width: "100px" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ marginRight: "5px" }}>PATHWAY</div>
                  </div>
                </td>
                <td style={{ width: "100px" }}>SOMA</td>
              </tr>
            </thead>
            <tbody>
              {filteredProjects.map((projectInfo, index) => {
                const { projectEvaluation = [] } = projectInfo;

                if (projectEvaluation.length < criteriaConfigurations.length) {
                  for (
                    let i = projectEvaluation.length;
                    i < criteriaConfigurations.length;
                    i++
                  ) {
                    projectEvaluation.push(0);
                  }
                }

                return (
                  <tr key={index}>
                    <td>{projectInfo.title}</td>
                    {projectEvaluation.map((evaluationValue, index) => {
                      return (
                        <td align="center" key={index}>
                          {evaluationValue}
                        </td>
                      );
                    })}

                    <td
                      align="center"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handlePathwayFilterChange(projectInfo.pathway)
                      }
                      className={
                        pathwayFilter === projectInfo.pathway
                          ? "filteredBackground"
                          : null
                      }
                    >
                      {projectInfo.pathway}
                    </td>
                    <td align="center">{projectInfo.sum}</td>
                  </tr>
                );
              })}
              {filteredProjects.length === 0 && (
                <tr>
                  <td colSpan="100">No data to show</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const tablesTabTitle = (
    <div>
      <i className="fas fa-table" />
      &nbsp;&nbsp; Overview de Projetos
    </div>
  );

  return (
    <div className="custom-primary-tabs">
      <Tabs
        id="projectPrioritization"
        activeKey={activeTab}
        onSelect={(key) => setActiveTab(key)}
        style={{ marginTop: "15px" }}
      >
        <Tab eventKey="tables" title={tablesTabTitle}>
          <div className="tab-content" style={{ marginTop: "10px" }}>
            {displayProjectPrioritizationTable()}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { projectPrioritization = {}, toolsConfigurations } = state;

  const { allQuestions, allAnswers, selectedTool } = getSelectedToolStates(
    state,
    {
      questions: toolsConfigurations
        ? toolsConfigurations.showHiddenQuestions
        : false,
      answers: toolsConfigurations
        ? toolsConfigurations.showHiddenAnswers
        : false,
    },
  );

  const allInsightPrioritizations = projectPrioritization.allInsightPrioritizations
    ? projectPrioritization.allInsightPrioritizations
    : [];

  const allProjectPrioritization = projectPrioritization.all
    ? projectPrioritization.all
    : [];

  return {
    selectedTool,
    allQuestions,
    allAnswers,
    allProjectPrioritization,
    allInsightPrioritizations,
  };
};

export default injectIntl(connect(mapStateToProps)(ProjectPrioritization));
