import styled from "styled-components";

export const ErrorAlert = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  z-index: 10000;
  top: 10%;
  width: 500px;
  left: 40%;
`;
