const en = {
  pa_main_title: "Portifolio Analysis",
};

const es = {
  pa_main_title: "Análisis de cartera",
};

const pt = {
  pa_main_title: "Analise de portfólio",
};

const translations = { en, es, pt };

export default translations;
