import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import NewScenarioDialog from "./NewScenarioDialog";
import FormDialog from "../../FormDialog";
import MaterialSingleSelect from "../../MaterialSingleSelect";
import utils from "../../../../utils/toolUtils";
import { getSelectedToolStates } from "../../../customMapStates";
import { getKpiConfiguration } from "../../../../actions/kpiActions";
import { buildKpiAdministrationFinalBody } from "../../../../utils/kpis/entity";

const { getErrorMessage, getValidFunc } = utils;

const useStyles = makeStyles(() =>
  createStyles({
    addButton: {
      fontSize: "12px",
    },
  }),
);

const messages = defineMessages({
  kpi_error_scenario_already_added: { id: "kpi_error_scenario_already_added" },
  kpi_add_new_scenario: { id: "kpi_add_new_scenario" },
  kpi_form_select_scenario: { id: "kpi_form_select_scenario" },
  btn_modal_register_new: { id: "btn_modal_register_new" },
});

const initialForm = {
  themeId: "",
};

const ScenerieThemeSelectDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const [newThemeModal, setNewThemeModal] = useState(false);

  const {
    open = false,
    afterCancel,
    afterConfirm,
    kpiAdministration,
    setKpiAdministration,
    addedThemes = [],
    selectedToolID,
    intl,
  } = props;

  const alternativeSaveCallback = getValidFunc(props.alternativeSaveCallback);

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  const getKpiConfig = async () => {
    if (selectedToolID) {
      const { data } = await getKpiConfiguration(selectedToolID);
      return buildKpiAdministrationFinalBody(data);
    }
  };

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleConfirm = () => {
    if (typeof afterConfirm === "function") afterConfirm(form);
  };

  const handleCancel = () => {
    if (typeof afterCancel === "function") afterCancel();
  };

  const handleUpdateForm = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const getFormErrors = () => {
    let errors = [];

    if ((!form.themeId || form.themeId === "empty") && form.themeId !== 0) {
      errors.push({ slug: "themeNotSelected" });
    }

    if (addedThemes.indexOf(form.themeId) > -1) {
      errors.push({
        slug: "duplicatedTheme",
        message: intl.formatMessage(messages.kpi_error_scenario_already_added),
      });
    }

    return errors;
  };

  const buildThemeOptions = (themes) => {
    let finalOptions = [];

    if (themes && themes.length > 0) {
      themes.forEach(({ id, title }) =>
        finalOptions.push({ value: id, label: title }),
      );
    }

    return finalOptions;
  };

  const alternativeSave = (submitKpiConfig) => {
    toggleNewThemeModal(false);
    alternativeSaveCallback(submitKpiConfig);
  };

  const toggleNewThemeModal = (toggleTo = true) => {
    setNewThemeModal(toggleTo);
  };

  const themeOptions = buildThemeOptions(kpiAdministration.themes);
  const classes = useStyles();
  const formErrors = getFormErrors();

  return (
    <>
      <NewScenarioDialog
        open={newThemeModal}
        kpiAdministration={kpiAdministration}
        setKpiAdministration={setKpiAdministration}
        afterConfirm={(submitKpiConfig) => alternativeSave(submitKpiConfig)}
        afterCancel={() => toggleNewThemeModal(false)}
        getKpiConfig={getKpiConfig}
      />
      <FormDialog
        open={open}
        title={intl.formatMessage(messages.kpi_add_new_scenario)}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        blockConfirm={formErrors.length > 0}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialSingleSelect
              id="themeSelect"
              label={intl.formatMessage(messages.kpi_form_select_scenario)}
              value={form.themeId}
              options={themeOptions}
              onChange={(e) => handleUpdateForm("themeId", e.target.value)}
            />
            {getErrorMessage(["duplicatedTheme"], formErrors, "left", {
              height: "22px",
            })}
            <Button
              variant="outlined"
              className={classes.addButton}
              onClick={() => toggleNewThemeModal(!newThemeModal.open)}
            >
              <AddIcon /> {intl.formatMessage(messages.btn_modal_register_new)}
            </Button>
          </div>
        </div>
      </FormDialog>
    </>
  );
};

export default injectIntl(
  connect((state) => {
    const { selectedToolID } = getSelectedToolStates(state);
    return { selectedToolID };
  })(ScenerieThemeSelectDialog),
);
