import React from "react";
import { Row, Col } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { makeStyles, createStyles } from "@material-ui/styles";
import NumberFormat from "react-number-format";
import moment from "moment";
import {
  updatePlanningByYear,
  getLabels,
  getLabelByType,
  getTotalValues,
  transformEffortMonthEvaluations,
} from "../../../../utils/projectEvaluation";
import MaterialTextField from "../../../Common/MaterialTextField";
import ExcelNumberFormat from "../../../Common/ExcelNumberFormat";

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      "& > div:before": {
        borderBottom: "none !important",
      },
      "& > div > div.MuiInputAdornment-positionEnd": {
        position: "relative",
        left: "-12px",
      },
    },
  }),
);

const DetailsTableEvaluation = (props) => {
  const classes = useStyles();
  const {
    selectedPlan,
    investment,
    setInvestment,
    effort,
    setEffort,
    type,
    renderType,
    intl,
  } = props;

  const updateEvaluation = (value, selectedYear, month, type) => {
    if (renderType === "Investment") {
      setInvestment((current) => {
        return {
          ...current,
          plannings: updatePlanningByYear(
            current.plannings,
            selectedYear,
            month || null,
            { name: type, value },
            renderType,
          ),
        };
      });
    } else if (renderType === "Efforts") {
      setEffort((current) => {
        return {
          ...current,
          plannings: updatePlanningByYear(
            current.plannings,
            selectedYear,
            month || null,
            { name: type, value },
            renderType,
          ),
        };
      });
    }
  };

  const handleSpentMinutes = (
    value = "",
    year = "",
    monthName = "",
    spentMinutesLabel = "",
  ) => {
    if (value < 60) {
      updateEvaluation(value, year, monthName, spentMinutesLabel);
    }
  };

  const getYearsTableBody = () => {
    let plannings = [];

    if (renderType === "Efforts") {
      plannings = effort && effort.plannings ? effort.plannings : [];
    } else if (renderType === "Investment") {
      plannings =
        investment && investment.plannings ? investment.plannings : [];
    }

    return plannings.map((plan, index) => {
      const totalValues = getTotalValues(plannings, plan.year, false);

      return (
        <tr key={index}>
          <td className="monthLabel" align="center">
            {plan.year}
          </td>
          <td align="center" className="monthValue">
            {renderType === "Investment" ? (
              <NumberFormat
                allowedDecimalSeparators={[","]}
                thousandSeparator="."
                decimalSeparator=","
                id={`${plan.year}Budget`}
                label={plan.year}
                value={
                  totalValues.totalBudget && totalValues.totalBudget !== 0
                    ? totalValues.totalBudget
                    : ""
                }
                variant="standard"
                hideLabel
                textAlign="center"
                customInput={MaterialTextField}
                disabled
                className={classes.textField}
                autoComp="off"
                endAdorment={{ text: "$ ", position: "end", fontSize: "16px" }}
              />
            ) : (
              <MaterialTextField
                variant="standard"
                id={`${plan.year}Budget`}
                label={plan.year}
                hideLabel
                textAlign="center"
                disabled
                autoComp="off"
                className={classes.textField}
                value={
                  totalValues.totalBudget && totalValues.totalBudget !== 0
                    ? totalValues.totalBudget
                    : ""
                }
              />
            )}
          </td>
          <td align="center" className="monthValue">
            {renderType === "Investment" ? (
              <NumberFormat
                allowedDecimalSeparators={[","]}
                thousandSeparator="."
                decimalSeparator=","
                id={`${plan.year}Spent`}
                label={plan.year}
                value={
                  totalValues.totalSpent && totalValues.totalSpent !== 0
                    ? totalValues.totalSpent
                    : ""
                }
                variant="standard"
                hideLabel
                textAlign="center"
                autoComp="off"
                customInput={MaterialTextField}
                disabled
                className={classes.textField}
                endAdorment={{ text: "$ ", position: "end", fontSize: "16px" }}
              />
            ) : (
              <MaterialTextField
                variant="standard"
                id={`${plan.year}Spent`}
                label={plan.year}
                hideLabel
                autoComp="off"
                textAlign="center"
                disabled
                className={classes.textField}
                value={
                  totalValues.totalSpent && totalValues.totalSpentTime
                    ? totalValues.totalSpentTime
                    : ""
                }
              />
            )}
          </td>
        </tr>
      );
    });
  };

  const getMonths = () => {
    const labels = getLabels();
    const allMonths = [];
    let monthEvaluations =
      selectedPlan && selectedPlan.monthEvaluations
        ? selectedPlan.monthEvaluations
        : {};

    if (renderType === "Efforts") {
      monthEvaluations = transformEffortMonthEvaluations(monthEvaluations);
    }

    Object.keys(monthEvaluations).forEach((monthName, index) => {
      if (monthEvaluations[monthName] && monthName !== "id") {
        const currentBudget = monthEvaluations[monthName].budget
          ? monthEvaluations[monthName].budget
          : null;

        const currentSpent = monthEvaluations[monthName].spent
          ? monthEvaluations[monthName].spent
          : null;

        const currentSpentHours = monthEvaluations[monthName].spentHours
          ? monthEvaluations[monthName].spentHours
          : "";

        const currentSpentMinutes = monthEvaluations[monthName].spentMinutes
          ? monthEvaluations[monthName].spentMinutes
          : "";

        const abbreviatedMonthName = moment().month(index).format("MMM");

        allMonths.push(
          <tr key={index}>
            <td className="monthLabel" align="center">
              {abbreviatedMonthName}
            </td>

            {renderType === "Investment" ? (
              <ExcelNumberFormat
                colSpan="1"
                id={`${abbreviatedMonthName}Budget`}
                value={currentBudget || ""}
                onChange={(e) =>
                  updateEvaluation(
                    e.target.value,
                    selectedPlan.year,
                    monthName,
                    labels.budget,
                  )
                }
                align="right"
                textEndAdorment="$"
              />
            ) : (
              <td align="center" className="monthValue formattedTD">
                <MaterialTextField
                  variant="standard"
                  id={`${abbreviatedMonthName}Budget`}
                  autoComp="off"
                  label={abbreviatedMonthName}
                  textAlign="right"
                  hideLabel
                  value={currentBudget || ""}
                  className={classes.textField}
                  inputStyle={{
                    textAlign: "right",
                    padding: "0px 12px",
                    height: "40px",
                  }}
                  onChange={(e) =>
                    updateEvaluation(
                      e.target.value,
                      selectedPlan.year,
                      monthName,
                      labels.budget,
                    )
                  }
                />
              </td>
            )}
            {renderType === "Investment" ? (
              <ExcelNumberFormat
                colSpan="1"
                id={`${abbreviatedMonthName}Spent`}
                value={currentSpent || ""}
                onChange={(e) =>
                  updateEvaluation(
                    e.target.value,
                    selectedPlan.year,
                    monthName,
                    labels.spent,
                  )
                }
                align="right"
                textEndAdorment="$"
              />
            ) : (
              <td align="center" className="monthValue formattedTD">
                <Row>
                  <Col md={6} style={{ paddingRight: "0px" }}>
                    <MaterialTextField
                      variant="standard"
                      textAlign="right"
                      autoComp="off"
                      type="number"
                      id={`${abbreviatedMonthName}SpentHours`}
                      label={abbreviatedMonthName}
                      hideLabel
                      className={classes.textField}
                      inputStyle={{
                        textAlign: "right",
                        padding: "0px 12px",
                        height: "40px",
                      }}
                      value={currentSpentHours || ""}
                      onChange={(e) =>
                        updateEvaluation(
                          e.target.value,
                          selectedPlan.year,
                          monthName,
                          labels.spentHours,
                        )
                      }
                      endAdorment={{
                        text: ":",
                        position: "end",
                        fontSize: "20px",
                      }}
                    />
                  </Col>
                  <Col md={6} style={{ paddingLeft: "0px" }}>
                    <MaterialTextField
                      variant="standard"
                      id={`${abbreviatedMonthName}SpentMinutes`}
                      label={abbreviatedMonthName}
                      textAlign="right"
                      autoComp="off"
                      type="number"
                      className={classes.textField}
                      inputStyle={{
                        textAlign: "right",
                        padding: "0px 12px",
                        height: "40px",
                      }}
                      hideLabel
                      value={currentSpentMinutes || ""}
                      onChange={(e) =>
                        handleSpentMinutes(
                          e.target.value,
                          selectedPlan.year,
                          monthName,
                          labels.spentMinutes,
                        )
                      }
                    />
                  </Col>
                </Row>
              </td>
            )}
          </tr>,
        );
      }
    });

    return allMonths;
  };

  const getCustomScrollStyle = (type) => {
    if (type === "Efforts") {
      return "effortFixedScroll";
    }

    return "";
  };

  const getMonthsTableBody = () => {
    const tableBody = getMonths();

    return tableBody.map((monthLineJSX) => {
      return monthLineJSX;
    });
  };

  const labelsByType = getLabelByType(renderType, intl);

  return (
    <Row>
      <Col md={12}>
        <table className="simple-month-table ">
          <thead>
            <tr>
              <td className="defaultTd" />
              <td align="center" className="tabletitleTd">
                {/* <i className="fas fa-chess" /> */}
                {labelsByType.firstBox}
              </td>
              <td align="center" className="tabletitleTd">
                {/* <i className="fas fa-gavel" />{" "} */}
                {labelsByType.secondBox}
              </td>
            </tr>
          </thead>
        </table>

        <div className={`fixedScroll ${getCustomScrollStyle(renderType)}`}>
          <table className="vertical-simple-table">
            <tbody>
              {type === "year" ? (
                <>{selectedPlan && getMonthsTableBody()}</>
              ) : (
                <>{getYearsTableBody()}</>
              )}
            </tbody>
          </table>
        </div>
      </Col>
    </Row>
  );
};

export default injectIntl(DetailsTableEvaluation);
