import * as actions from "./_actions";
import send from "../../dispatcher";

function observeSkillsAssessment({ stompClient, dispatch }) {
  stompClient.subscribe("/selected-tools/addSkillsAssessment", (response) => {
    send(actions.putToolConfiguration, response, dispatch);
  });

  stompClient.subscribe("/skills-assessments/update", (response) => {
    send(actions.putToolConfiguration, response, dispatch);
  });

  stompClient.subscribe("/skills-assessments/theme", (response) => {
    send(actions.postBoardThemeLink, response, dispatch);
  });

  stompClient.subscribe("/skills-assessments/question", (response) => {
    send(actions.postBoardQuestionLink, response, dispatch);
  });

  stompClient.subscribe("/skills-assessments/insight", (response) => {
    send(actions.postBoardInsightLink, response, dispatch);
  });
}

export default observeSkillsAssessment;
