import update from "immutability-helper";

import { SELECT_TOOLS_TIMELINE, ADD_TOOL_TIMELINE } from "../actions/types";

const INITIAL_STATE = [];

const toolTimelineReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_TOOLS_TIMELINE:
      return action.payload.reverse();
    case ADD_TOOL_TIMELINE:
      return update(state, {
        $unshift: [action.payload]
      });
    default:
      return state;
  }
};

export default toolTimelineReducer;
