import { apiNode } from "../api";

export function syncRookauAuthRoles(selectedToolId, toolTypeId) {
  return apiNode
    .post("/api/v2/rookau-roles/sync-rookau-roles", {
      selectedToolId,
      toolTypeId,
    })
    .then((res) => res);
}
