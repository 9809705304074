import React from "react";
import ScenaryWatcher from "./ScenaryWatcher";
import ThemeWatcher from "./ThemeWatcher";
import QuestionWatcher from "./QuestionWatcher";
import InsightWatcher from "./InsightWatcher";

const StructureWatcher = (props) => {
  return (
    <>
      <ScenaryWatcher />
      <ThemeWatcher />
      <QuestionWatcher />
      <InsightWatcher />
    </>
  );
};

export default StructureWatcher;
