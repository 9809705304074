import React from "react";

const CountColorBadge = props => {
  let {
    bgColor = "#eee",
    borderColor = "#333",
    value,
    width = "50px",
    margin = "15px",
    height = "60px",
    lineHeight = "57px",
    fontSize = "26px",
    bottomInfo = null,
  } = props;
  const borderStyles = {
    borderTop: `6px solid ${borderColor}`,
  };

  value = value.toString();

  return (
    <div
      style={{
        position: "relative",
        width,
        margin,
        height,
        lineHeight,
        fontSize,
        fontWeight: "500",
        backgroundColor: bgColor,
        ...borderStyles,
        textAlign: "center",
      }}
    >
      {value}
      {bottomInfo && (
        <span
          className="label"
          style={{
            position: "absolute",
            right: "-5px",
            bottom: "-5px",
            color: "#333",
            fontSize: "10px",
            padding: "5px",
            backgroundColor: "#eee",
            border: "1px solid #ccc",
          }}
        >
          {bottomInfo}
        </span>
      )}
    </div>
  );
};

export default CountColorBadge;
