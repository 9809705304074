import React from "react";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";

const TableEvaluationDates = ({ dates = {}, updateDateField }) => {
  const handleUpdateDates = (newValue, slug) => {
    updateDateField(slug, newValue);
  };

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="100">PERÍODO DE AVALIAÇÃO</td>
        </tr>
      </thead>
      <tbody>
        <tr className="block-white-space border-bottom-division">
          <td></td>
          <td>INÍCIO</td>
          <td>FIM</td>
        </tr>
        <tr>
          <td>Validação</td>
          <SimpleExcelCollumn
            id="startValidation"
            label="startValidation"
            type="date"
            value={dates.startValidation}
            onChange={(e) =>
              handleUpdateDates(e.target.value, "startValidation")
            }
          />
          <SimpleExcelCollumn
            id="endValidation"
            label="endValidation"
            type="date"
            value={dates.endValidation}
            onChange={(e) => handleUpdateDates(e.target.value, "endValidation")}
          />
        </tr>
        <tr>
          <td>Autoavaliação</td>
          <SimpleExcelCollumn
            id="startSelfEvaluation"
            label="startSelfEvaluation"
            type="date"
            value={dates.startSelfEvaluation}
            onChange={(e) =>
              handleUpdateDates(e.target.value, "startSelfEvaluation")
            }
          />
          <SimpleExcelCollumn
            id="endSelfEvaluation"
            label="endSelfEvaluation"
            type="date"
            value={dates.endSelfEvaluation}
            onChange={(e) =>
              handleUpdateDates(e.target.value, "endSelfEvaluation")
            }
          />
        </tr>
        <tr>
          <td>Avaliadores</td>
          <SimpleExcelCollumn
            id="startEvaluators"
            label="startEvaluators"
            type="date"
            value={dates.startEvaluators}
            onChange={(e) =>
              handleUpdateDates(e.target.value, "startEvaluators")
            }
          />
          <SimpleExcelCollumn
            id="endEvaluators"
            label="endEvaluators"
            type="date"
            value={dates.endEvaluators}
            onChange={(e) => handleUpdateDates(e.target.value, "endEvaluators")}
          />
        </tr>
        <tr>
          <td>Fechamento</td>
          <SimpleExcelCollumn
            id="startClosure"
            label="startClosure"
            type="date"
            value={dates.startClosure}
            onChange={(e) => handleUpdateDates(e.target.value, "startClosure")}
          />
          <SimpleExcelCollumn
            id="endClosure"
            label="endClosure"
            type="date"
            value={dates.endClosure}
            onChange={(e) => handleUpdateDates(e.target.value, "endClosure")}
          />
        </tr>
        <tr>
          <td>PDI</td>
          <SimpleExcelCollumn
            id="startPdi"
            label="startPdi"
            type="date"
            value={dates.startPdi}
            onChange={(e) => handleUpdateDates(e.target.value, "startPdi")}
          />
          <SimpleExcelCollumn
            id="endPdi"
            label="endPdi"
            type="date"
            value={dates.endPdi}
            onChange={(e) => handleUpdateDates(e.target.value, "endPdi")}
          />
        </tr>
        <tr>
          <td>Feedback</td>
          <SimpleExcelCollumn
            id="startFeedback"
            label="startFeedback"
            type="date"
            value={dates.startFeedback}
            onChange={(e) => handleUpdateDates(e.target.value, "startFeedback")}
          />
          <SimpleExcelCollumn
            id="endFeedback"
            label="endFeedback"
            type="date"
            value={dates.endFeedback}
            onChange={(e) => handleUpdateDates(e.target.value, "endFeedback")}
          />
        </tr>
      </tbody>
    </table>
  );
};

export default TableEvaluationDates;
