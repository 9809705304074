import styled from "styled-components";

export const ContainerUpload = styled.section`
  outline: none;

  .dropzone {
    outline: none;
  }
`;

export const UploadMessage = styled.div`
  border: 2px dashed #eee;
  height: auto;
  min-height: 50px;
  border-radius: 5px;
  background-color: #fafafa;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #bdbdbd;
    text-align: center;
  }

  &:hover {
    border-color: #2196f3;
  }
`;
