const en = {
  pport_main_title: "Projects",
  pport_ph_team_table_choose: "Choose a Team",
  pport_ph_team_table_selected: "Selected a Team",
  pport_ph_projects_title_upper: "Projects",
  pport_table_milestone: "Activities",
  pport_form_milestone: "Activitie",
  pport_form_new_milestone: "New activitie",
  pport_form_edit_milestone: "Edit activitie",
  pport_form_activities_expected: "Expected",
  pport_form_activities_achieved: "achieved",
  pport_form_effort_foreseen: "Foreseen",
  pport_form_effort_achieved: "achieved",
  pport_form_delivery_date: "Delivery date",
  pport_form_responsible: "Responsible",
  pport_form_activities: "Activities",
  pport_form_effort_hours: "Effort (hours)",
  pport_form_effort_tasks: "Effort (tasks)",
  pport_form_effort_days: "Effort (days)",
  no_status: "No status in project",
};

const es = {
  pport_main_title: "Proyectos",
  pport_ph_team_table_choose: "Elige un equipo",
  pport_ph_team_table_selected: "Seleccionó un equipo",
  pport_ph_projects_title_upper: "Proyectos",
  pport_table_milestone: "Actividades",
  pport_form_milestone: "Actividade",
  pport_form_new_milestone: "Nueva actividade",
  pport_form_edit_milestone: "Editar actividade",
  pport_form_activities_expected: "Esperado",
  pport_form_activities_achieved: "Alcanzado",
  pport_form_effort_foreseen: "Previsto",
  pport_form_effort_achieved: "Alcanzado",
  pport_form_delivery_date: "Fecha de entrega",
  pport_form_responsible: "Responsable",
  pport_form_activities: "Actividades",
  pport_form_effort_hours: "Esfuerzo (horas)",
  pport_form_effort_tasks: "Esfuerzo (tareas)",
  pport_form_effort_days: "Esfuerzo (dias)",
  no_status: "Sin estado en el proyecto",
};

const pt = {
  pport_main_title: "Projetos",
  pport_ph_team_table_choose: "Escolha um Time",
  pport_ph_team_table_selected: "Selecione um Time",
  pport_ph_projects_title_upper: "Projetos",
  pport_table_milestone: "Atividades",
  pport_form_milestone: "Atividade",
  pport_form_new_milestone: "Nova atividade",
  pport_form_edit_milestone: "Editar atividade",
  pport_form_activities_expected: "Previstas",
  pport_form_activities_achieved: "Realizadas",
  pport_form_effort_foreseen: "Previsto",
  pport_form_effort_achieved: "Realizado",
  pport_form_delivery_date: "Data de entrega",
  pport_form_responsible: "Responsável",
  pport_form_activities: "Atividades",
  pport_form_effort_hours: "Esforço (horas)",
  pport_form_effort_tasks: "Esforço (tarefas)",
  pport_form_effort_days: "Esforço (dias)",
  no_status: "Sem status no projeto",
};

const translations = { en, es, pt };
export default translations;
