import store from "../Store";

import {
  getGoalsBonusStates,
  getKpiStates,
} from "../components/customMapStates";
import { getObjectInfoById } from "./ArrayUtils";
import { getGoalsBonusBySelectedToolId } from "./goalsBonus/filter";
import { getKpiConfigBySelectedToolId } from "./kpis/filter";

const defaultInsightConnections = {
  comments: [],
};

export const getGoalBonusInsightConnections = (
  insightInfo = {},
  selectedToolId,
) => {
  const state = store.getState();
  const finalConnections = defaultInsightConnections;

  const { goalsBonusProgramGoalId = null } = insightInfo;

  if (goalsBonusProgramGoalId) {
    const { allGoalBonusAdministration = [] } = getGoalsBonusStates(state);

    const toolGoalsBonusAdm = getGoalsBonusBySelectedToolId(
      selectedToolId,
      allGoalBonusAdministration,
    );

    if (toolGoalsBonusAdm.goals) {
      const goalInfo = getObjectInfoById(
        goalsBonusProgramGoalId,
        toolGoalsBonusAdm.goals,
        "id",
      );

      if (goalInfo.relatedInsights) {
        finalConnections.comments = goalInfo.relatedInsights;
      }
    }
  }

  return finalConnections;
};

export const getKpiInsightConnections = (insightInfo = {}, selectedToolId) => {
  const state = store.getState();
  const finalConnections = defaultInsightConnections;

  const { kpiConfigurationGoalId = null } = insightInfo;

  if (kpiConfigurationGoalId) {
    const { allKpiAdministration = [] } = getKpiStates(state);

    const kpiConfiguration = getKpiConfigBySelectedToolId(
      selectedToolId,
      allKpiAdministration,
    );

    if (kpiConfiguration?.goals) {
      const goalInfo = getObjectInfoById(
        kpiConfigurationGoalId,
        kpiConfiguration.goals,
        "id",
      );

      if (goalInfo.relatedInsights) {
        finalConnections.comments = goalInfo.relatedInsights;
      }
    }
  }

  return finalConnections;
};
