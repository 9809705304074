export const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "csv", "ods"]
  .map(function(x) {
    return "." + x;
  })
  .join(",");

/*"txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm"*/
