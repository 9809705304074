export const getProjectHeadValues = () => {
  return [
    {
      value: "NOT_APPLICABLE",
      valueNumber: 0,
      color: "#ccc",
      constrast: "#222",
    },
    {
      value: "VERY_LOW",
      valueNumber: 1,
      color: "#ff0000",
      constrast: "#fff",
    },
    {
      value: "LOW",
      valueNumber: 2,
      color: "#fd5d5d",
      constrast: "#fff",
    },
    {
      value: "PARTIAL",
      valueNumber: 3,
      color: "#ffc000",
      constrast: "#222",
    },
    {
      value: "HIGH",
      valueNumber: 4,
      color: "#44cf76",
      constrast: "#222",
    },
    {
      value: "VERY_HIGH",
      valueNumber: 5,
      color: "#00b050",
      constrast: "#222",
    },
  ];
};

export const getPrioritizationValues = () => {
  return [
    {
      value: "NOT_APPLICABLE",
      valueNumber: 0,
    },
    {
      value: "VERY_LOW",
      valueNumber: 1,
    },
    {
      value: "LOW",
      valueNumber: 2,
    },
    {
      value: "PARTIAL",
      valueNumber: 3,
    },
    {
      value: "HIGH",
      valueNumber: 4,
    },
    {
      value: "VERY_HIGH",
      valueNumber: 5,
    },
  ];
};

export const projectGroupOptions = [
  { label: "Beyond Core Innovation", value: 1 },
  { label: "Core Innovation", value: 2 },
  {
    label: "Base Renovation",
    value: 3,
  },
  {
    label: "Consumer Promotion",
    value: 4,
  },
  {
    label: "Supply Chain",
    value: 5,
  },
  {
    label: "Licensing",
    value: 6,
  },
  {
    label: "Delist",
    value: 7,
  },
];

export const projectTypeOptions = [
  {
    label: "Transformational",
    value: 1,
  },
  {
    label: "Adjacency",
    value: 2,
  },
  { label: "Close-in", value: 3 },
  {
    label: "Geographic Expansion",
    value: 4,
  },
  {
    label: "Line Extensions",
    value: 5,
  },
  {
    label: "PPA",
    value: 6,
  },
  {
    label: "eCommerce",
    value: 7,
  },
  {
    label: "Limited Edition",
    value: 8,
  },
  {
    label: "Seasonal",
    value: 9,
  },
  {
    label: "Productivity",
    value: 10,
  },
  {
    label: "QI",
    value: 11,
  },
  {
    label: "Full graphic redesign",
    value: 12,
  },
  {
    label: "Text / Legal changes",
    value: 13,
  },
  {
    label: "Added Value",
    value: 14,
  },
  {
    label: "Banded Packs",
    value: 15,
  },
  {
    label: "Mixed Displays",
    value: 16,
  },
  {
    label: "Premiuns",
    value: 17,
  },
  {
    label: "Capacity Expansion",
    value: 18,
  },
  {
    label: "Sourcing Changes",
    value: 19,
  },
  {
    label: "Licensing",
    value: 20,
  },
  {
    label: "Delist",
    value: 21,
  },
];

export const pathwayOptions = [
  {
    label: "Agile",
    value: "AGILE",
  },
  {
    label: "Priority",
    value: "PRIORITY",
  },
  { label: "Standard", value: "STANDARD" },
  { label: "Lite", value: "LITE" },
];
