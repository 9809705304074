import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import WorkflowDataTable from "./WorkflowDataTable";
import WorkflowComplementaryGroup from "./WorkflowComplementaryGroup";
import WorkflowHeader from "./WorkflowHeader";
import { buildWorkflowScaleOptions } from "../../../../../../../utils/skillsAssessment/filter";
import { useWindowSize } from "../../../../../../../utils/customHooks";

const WorkflowManager = ({
  selectedToolId,
  participant,
  workflow,
  complementaryWorkflow,
  toolAdministration,
  filterStage,
  nextStage,
  prevStage,
  handleEvaluatorsUpdate,
  handleWorkflowUpdate,
  handleComplementaryWorkflowUpdate,
  enabledFields,
  enabledActions,
  enableSecondEvaluator,
  enableThirdEvaluator,
  enableComplementaryEvaluation,
  maxScale,
  minScale,
  maxPotential,
  minPotential,
  openSelectSuccessor,
  managerConfig,
  stageMessage,
  workflowErrors,
  disableSaveWithErrors,
  companyMembersAndParticipants,
  loggedUser,
  loggedUserRole,
}) => {
  const windowSize = useWindowSize();
  const fixedHeight = 380;
  const fixedHeightResponsive = 200;
  const tableHeight =
    windowSize[1] >= 720
      ? windowSize[1] - fixedHeight
      : windowSize[1] - fixedHeightResponsive;

  const isEmpty = !participant || !participant.id;
  const haveNoWorkflow = !workflow || workflow.length === 0;
  const noData = isEmpty || haveNoWorkflow;

  const blockNextStage = workflowErrors.length > 0 && disableSaveWithErrors;

  const getTopActions = () => {
    const enableNextStageForSelfEvaluation =
      filterStage === "SELF_EVALUATION" &&
      ["ADMIN", "MANAGER"].includes(loggedUserRole);
    const showActions =
      (!noData && !blockNextStage) || enableNextStageForSelfEvaluation;
    const showNextStage = showActions && nextStage.label;

    return (
      <div className="workflow-top-actions">
        {showNextStage && (
          <button
            className="btn next-button"
            type="button"
            onClick={nextStage.sendTo}
          >{`Enviar para ${nextStage.label}`}</button>
        )}
        {prevStage.label && (
          <button
            className="btn"
            type="button"
            onClick={prevStage.sendTo}
          >{`Voltar para ${prevStage.label}`}</button>
        )}
      </div>
    );
  };

  const {
    enableManagerUpdate,
    enableSecEvaluatorUpdate,
    enableTrdEvaluatorUpdate,
  } = enabledActions;

  const { insight } = participant;

  return (
    <div className="row workflow-container">
      {stageMessage}
      {getTopActions()}
      {!isEmpty && (
        <>
          <div
            className="col-xs-12"
            style={{ paddingTop: "15px", borderBottom: "1px solid #ccc" }}
          >
            <WorkflowHeader
              participant={participant}
              workflowErrors={workflowErrors}
              handleEvaluatorsUpdate={handleEvaluatorsUpdate}
              enableSecondEvaluator={enableSecondEvaluator}
              enableThirdEvaluator={enableThirdEvaluator}
              enableSecEvaluatorUpdate={enableSecEvaluatorUpdate}
              enableTrdEvaluatorUpdate={enableTrdEvaluatorUpdate}
              enableManagerUpdate={enableManagerUpdate}
              managerConfig={managerConfig}
              loggedUser={loggedUser}
              loggedUserRole={loggedUserRole}
              companyMembersAndParticipants={companyMembersAndParticipants}
            />
          </div>
          <div
            className="col-xs-12"
            style={{ height: tableHeight, overflowX: "auto", paddingTop: 0 }}
          >
            <WorkflowDataTable
              selectedToolId={selectedToolId}
              workflow={workflow}
              participant={participant}
              enabledFields={enabledFields}
              toolAdministration={toolAdministration}
              handleWorkflowUpdate={handleWorkflowUpdate}
              maxScale={maxScale}
              minScale={minScale}
              maxPotential={maxPotential}
              minPotential={minPotential}
              scaleOptions={{
                potentialScale: buildWorkflowScaleOptions(
                  toolAdministration,
                  "potentialScale"
                ),
                evaluationScale: buildWorkflowScaleOptions(
                  toolAdministration,
                  "evaluationScale"
                ),
              }}
              loggedUser={loggedUser}
            />

            {enableComplementaryEvaluation && (
              <WorkflowComplementaryGroup
                selectedToolId={selectedToolId}
                toolAdministration={toolAdministration}
                complementaryWorkflow={complementaryWorkflow}
                insight={insight}
                loggedUser={loggedUser}
                handleUpdate={handleComplementaryWorkflowUpdate}
                openSelectSuccessor={openSelectSuccessor}
                managerId={participant.manager.id}
              />
            )}
          </div>
        </>
      )}
      {isEmpty && (
        <div className="col-xs-12">
          <h5 align="center" style={{ color: "#666" }}>
            Nenhum para mostrar.
          </h5>
        </div>
      )}
    </div>
  );
};

WorkflowManager.propTypes = {
  loggedUserRole: PropTypes.string.isRequired,
  handleEvaluatorsUpdate: PropTypes.func.isRequired,
  handleWorkflowUpdate: PropTypes.func.isRequired,
  handleComplementaryWorkflowUpdate: PropTypes.func.isRequired,
  participant: PropTypes.object,
  managerConfig: PropTypes.object,
  toolAdministration: PropTypes.object,
  workflow: PropTypes.array,
  complementaryWorkflow: PropTypes.array,
  maxScale: PropTypes.number,
  maxPotential: PropTypes.number,
  nextStage: PropTypes.object,
  prevStage: PropTypes.object,
  enabledFields: PropTypes.object.isRequired,
  enabledActions: PropTypes.object.isRequired,
  enableSecondEvaluator: PropTypes.bool,
  enableThirdEvaluator: PropTypes.bool,
  enableComplementaryEvaluation: PropTypes.bool,
  openSelectSuccessor: PropTypes.func.isRequired,
  stageMessage: PropTypes.node,
  workflowErrors: PropTypes.array,
  disableSaveWithErrors: PropTypes.bool,
  companyMembersAndParticipants: PropTypes.array,
};

WorkflowManager.defaultProps = {
  participant: {},
  managerConfig: {},
  workflow: [],
  complementaryWorkflow: [],
  toolAdministration: {},
  maxScale: 0,
  maxPotential: 0,
  nextStage: {},
  prevStage: {},
  enableSecondEvaluator: false,
  enableThirdEvaluator: false,
  enableComplementaryEvaluation: false,
  stageMessage: null,
  workflowErrors: [],
  companyMembersAndParticipants: [],
};

function areEqual(prevProps, nextProps) {
  if (!_.isEqual(prevProps.participant, nextProps.participant)) return false;
  if (!_.isEqual(prevProps.nextStage, nextProps.nextStage)) return false;
  if (!_.isEqual(prevProps.prevStage, nextProps.prevStage)) return false;
  if (!_.isEqual(prevProps.workflow, nextProps.workflow)) return false;
  if (
    !_.isEqual(prevProps.complementaryWorkflow, nextProps.complementaryWorkflow)
  )
    return false;
  return true;
}

export default React.memo(WorkflowManager, areEqual);
