import styled from "styled-components";
import Alert from "@material-ui/lab/Alert";

export const Display = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.fullWidth ? "100%" : "49.5%")};
  border: 1px solid #ccc !important;
  border-radius: 3px;
  margin: 0px 0px 15px 0px;
  padding: 20px;
`;

export const AlertStyle = styled(Alert)`
  font-size: 16px !important;
`;

export const Title = styled.h4`
  text-transform: uppercase;
`;

export const ObjectiveContainer = styled.div`
  background-color: #dfe1e6 !important;
  padding: 5px;
  border-radius: 3px;

  & + div {
    margin-top: 10px;
  }

  .title-objective {
    display: flex;
    margin-bottom: 5px;

    span {
      color: #626f87 !important;
      font-size: 13px;
      font-weight: 400;
      margin-right: 5px;
    }

    p {
      font-weight: 400;
      margin: 0;
    }
  }

  ul {
    display: flex;
    justify-content: center;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-radius: 5px;
      background-color: #fff !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 5px;

      & + li {
        margin-top: 5px;
      }

      .answer-info {
        display: flex;
        align-items: center;
        max-width: 440px;

        p {
          margin: 0 0 0 5px;
        }
      }
    }
  }
`;

export const DisplayEvaluation = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr) 160px;
  align-items: flex-end;
  gap: 5px;

  div {
    display: flex;
    flex-direction: column;
    text-align: center;

    p {
      margin: 0;
      font-size: 11px;
      color: #626f87;
    }

    span {
      font-size: 13px;
    }
  }

  .goal-list-item {
    margin: 0px;
    border: none;
    height: 18px;
  }

  .goal-list-item {
    display: flex;
    flex-direction: row;
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  background: #ddd !important;
  height: 10px !important;
  margin: 0;

  .progress {
    height: 10px !important;
    width: ${(props) => props.widthProgress ?? ""}%;
    background-color: ${(props) => props.colorStatus ?? ""} !important;
  }
`;
