import {
  ADD_INSIGHT_ATTACHMENT,
  DELETE_INSIGHT_ATTACHMENT
} from "../../../../types";
import { selectedInsightVerification } from "../comments/_validations";

export const postAddAttachment = ({ details = {}, content = {} }) => {
  const { insightId = "" } = details;

  if (!selectedInsightVerification(insightId)) return;

  return dispatch => {
    dispatch({
      type: ADD_INSIGHT_ATTACHMENT,
      payload: { ...content }
    });
  };
};

export const deleteAttachment = ({ details = {}, content = {} }) => {
  const { insightId = "" } = details;

  if (!selectedInsightVerification(insightId)) return;

  return dispatch => {
    dispatch({
      type: DELETE_INSIGHT_ATTACHMENT,
      payload: { ...details }
    });
  };
};
