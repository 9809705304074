import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Row, Col } from "react-bootstrap";
import _ from "lodash";
import ImportController from "./ImportController";
import ExampleTable from "./ExampleTable";
import MeasurementContext from "./MeasurementContext";
import DataFlowMenu from "../Common/DataFlowMenu";
import { saveAsExcel } from "../Common/Export/ExportCSV";

import {
  PROJECT_PORTFOLIO,
  GOALS_AND_BONUS,
  PEOPLE_BIG_PICTURE,
  KPIS,
} from "../../constants/tools.constants";

import { getValidFunc } from "../../utils/validations";

import { getPortfolioDependencies } from "./CustomFields/ProjectPortfolio/utils";
import { getOnePageDependencies } from "./CustomFields/OnePage/utils";
import { getGoalsAndBonusDependencies } from "./CustomFields/GoalsAndBonus/utils";
import { getKPIsDependencies } from "./CustomFields/KPIs/utils";

import {
  getProjectStates,
  getBigPeopleStates,
  getGoalsBonusStates,
  getSelectedToolStates,
  getSelectedCompanyStates,
  getKpiStates,
  getAccessStates,
} from "../customMapStates";

import { goalsMassUpdate } from "../../actions/goalsAndBonusActions";
import { kpisMassUpdate } from "../../actions/kpiActions";
import { peopleSheetsMassUpdate } from "../../actions/peopleEvaluationActions";
import { projectSheetsMassUpdate } from "../../actions/projectEvaluationActions";

const fileInitialState = {
  file: {},
  fileData: {},
};

const Measurement = (props) => {
  const [toolDependencies, setToolDependencies] = useState({});
  const [fileInfo, setFileInfo] = useState(fileInitialState);
  const [callImportController, setCallImportController] = useState(false);
  const [massUpdateAction, setMassUpdateAction] = useState();

  const {
    toolID,
    intl,
    allProjectEvaluation = [],
    allPeopleEvaluation = [],
    allGoalBonusAdministration = [],
    allKpiAdministration = [],
    user = {},
    selectedToolId = "",
    kpisMassUpdate,
    projectSheetsMassUpdate,
    goalsMassUpdate,
    peopleSheetsMassUpdate,
    domainUsers,
    selectedToolStates = {},
    activeTab,
  } = props;

  const { preRequeriments = getValidFunc() } = toolDependencies;

  useEffect(() => {
    if (toolID && activeTab === "measurement") {
      // COLOCAR USECALLBACK
      let newDependencies = {};

      switch (toolID) {
        case PROJECT_PORTFOLIO:
          const clonnedProjectEvaluation = _.cloneDeep(allProjectEvaluation);

          setMassUpdateAction(() => projectSheetsMassUpdate);

          newDependencies = getPortfolioDependencies({
            intl,
            user,
            domainUsers,
            selectedToolStates,
            allProjectEvaluation: clonnedProjectEvaluation,
          });

          break;
        case GOALS_AND_BONUS:
          const clonnedGoalsAndBonus = _.cloneDeep(allGoalBonusAdministration);

          setMassUpdateAction(() => goalsMassUpdate);

          newDependencies = getGoalsAndBonusDependencies(
            clonnedGoalsAndBonus,
            selectedToolId,
          );
          break;
        case KPIS:
          const clonnedKPIs = _.cloneDeep(allKpiAdministration);

          setMassUpdateAction(() => kpisMassUpdate);

          newDependencies = getKPIsDependencies(clonnedKPIs, selectedToolId);
          break;
        case PEOPLE_BIG_PICTURE:
          const clonnedPeopleEvaluation = _.cloneDeep(allPeopleEvaluation);

          setMassUpdateAction(() => peopleSheetsMassUpdate);

          newDependencies = getOnePageDependencies(
            clonnedPeopleEvaluation,
            intl,
            selectedToolStates,
          );
          break;
        default:
          newDependencies = {};
      }

      if (!_.isEqual(newDependencies, toolDependencies))
        setToolDependencies(newDependencies);
    }
  }, [
    activeTab,
    toolID,
    allProjectEvaluation,
    allGoalBonusAdministration,
    allKpiAdministration,
    allPeopleEvaluation,
  ]);

  const handleFileChange = (file = {}, fileData = {}) => {
    setFileInfo({ file, fileData });
    setCallImportController(true);
  };

  const handleExport = () => {
    saveAsExcel({ ...toolDependencies });
  };

  const resetFileInfo = () => {
    setFileInfo(fileInitialState);
  };

  const appendContext = {
    //Import Controller Context
    ...toolDependencies,
    fileInfo,
    callImportController,
    setCallImportController,
    massUpdateAction,
    intl,
    resetFileInfo,
  };

  const appendProps = {
    handleExport,
    handleFileChange,
    showImport: true,
    showExport: true,
  };

  return (
    <div style={{ padding: "15px", paddingTop: 0 }}>
      {activeTab === "measurement" && (
        <MeasurementContext.Provider value={{ ...appendContext }}>
          <DataFlowMenu {...appendProps} />
          {preRequeriments()}
          <Row>
            <Col md={12} style={{ padding: "0" }}>
              <ExampleTable />
            </Col>
          </Row>
          {/* Implementar Export Controller later*/}
          <ImportController />
        </MeasurementContext.Provider>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { user = {} } = state;

  const { domainUsers = [] } = getAccessStates(state);
  const { allProjectEvaluation } = getProjectStates(state);
  const { allPeopleEvaluation } = getBigPeopleStates(state);
  const { allGoalBonusAdministration } = getGoalsBonusStates(state);
  const { allKpiAdministration } = getKpiStates(state);
  const { selectedCompany } = getSelectedCompanyStates(state);
  const { selectedWorkspace = {} } = state.workspacesData || {};

  const selectedToolStates = getSelectedToolStates(state);
  selectedToolStates.companyName = selectedCompany.name || "";
  selectedToolStates.workspaceName = selectedWorkspace.name || "";
  selectedToolStates.workspaceId = selectedWorkspace.id || "";

  return {
    selectedToolId: selectedToolStates.selectedToolID,
    allGoalBonusAdministration,
    allKpiAdministration,
    allProjectEvaluation,
    allPeopleEvaluation,
    selectedToolStates,
    domainUsers,
    user,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    projectSheetsMassUpdate,
    goalsMassUpdate,
    kpisMassUpdate,
    peopleSheetsMassUpdate,
  })(Measurement),
);
