import React from "react";
import { Row, Col } from "react-bootstrap";
import { defineMessages, injectIntl } from "react-intl";
import { patternStyles, generalCardStyles, makeSpaceBetween } from "../jcss";
import BadgeProgressBar from "../../../../../../../Common/ReportCards/components/BadgeProgressBar";
import { Tooltip } from "@material-ui/core";

const GeneralChart = ({ intl, macroOverviewData = {} }) => {
  const {
    cardBody = {},
    cardLine = {},
    cardLineValue = {},
    generalInfoContent = {},
  } = generalCardStyles();

  const {
    generalStyle = {},
    centerAlign = {},
    cardHeaderStyle = {},
  } = patternStyles();

  const { totalProjects = 0, general = [] } = macroOverviewData;

  const numberProjects = defineMessages({
    title: {
      id: "tool.project.numberProjects",
    },
  });

  const getLine = ({ name = "", value = "", width = "" }, index = 0) => {
    return (
      <Row key={index} className={cardLine} style={{ width: "100%" }}>
        <div style={{ width: "38%" }}>{name}</div>
        <div
          style={{
            borderLeft: "1px solid #D7D7D7",
            display: "flex",
            width: "62%",
          }}
        >
          <BadgeProgressBar
            widthBar={width}
            heightBar="18px"
            leftText={100}
            borderRadius="3px"
          />
          <div className={cardLineValue}>{value}</div>
        </div>
      </Row>
    );
  };

  const cardBodyStyle = makeSpaceBetween([cardBody, centerAlign]);

  return (
    <Col md={4} sm={12} className={generalStyle}>
      <Row className={cardHeaderStyle}>
        <strong style={{ marginLeft: "150px" }}>Projetos</strong>
        <Col md={4}>
          <div>
            <Tooltip title={intl.formatMessage(numberProjects.title)} arrow>
              <div className={generalInfoContent}>{totalProjects}</div>
            </Tooltip>
          </div>
        </Col>
      </Row>
      <Row className={cardBodyStyle} style={{ overflow: "hidden" }}>
        <Col md={8} className={centerAlign} style={{ width: "100%" }}>
          <div style={{ display: "inline", width: "100%" }}>
            {general.map((line, index) => getLine(line, index))}
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default injectIntl(GeneralChart);
