import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  updateGoalBonusAdministration,
  createGoalsBonusScenerieTeam,
} from "../../../../actions/goalsAndBonusActions";
import ScenerieTeamSelectDialog from "../../../Common/GoalsAndBonus/forms/ScenerieTeamSelectDialog";
import {
  getSelectedToolStates,
  getGoalsBonusStates,
} from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";
import gbUtils from "../../../../utils/goalsBonus";

const { getValidFunc } = utils;

const {
  getGoalsBonusBySelectedToolId,
  buildFormAdmConfigBody,
  buildRestAdmConfigBody,
  getTeamInfoById,
} = gbUtils;

const defaultFormInitialGoalBonus = buildFormAdmConfigBody({});

const GoalsAndBonus = (props) => {
  const [goalBonusAdministration, setGoalBonusAdministration] = useState(
    defaultFormInitialGoalBonus,
  );
  const [addedTeams, setAddedTeams] = useState([]);

  const {
    type,
    clearViewCallback,
    updateGoalBonusAdministration,
    createGoalsBonusScenerieTeam,
    selectedTool = {},
    isLoading = false,
    allGoalBonusAdministration = [],
    userProfile,
    allSceneries = [],
    params = {},
  } = props;

  const cancelCallback = getValidFunc(params.cancelCallback);

  useEffect(() => {
    let toolTeams = [];

    if (allSceneries && allSceneries.length) {
      allSceneries.forEach((scenerieInfo) => {
        if (
          scenerieInfo.selectedTool.id === selectedTool.id &&
          scenerieInfo.goalsBonusProgramTeamId
        )
          toolTeams.push(scenerieInfo.goalsBonusProgramTeamId);
      });
    }

    if (!_.isEqual(toolTeams, addedTeams)) setAddedTeams(toolTeams);
  }, [addedTeams, allSceneries, selectedTool.id]);

  useEffect(() => {
    if (allGoalBonusAdministration) {
      let toolGoalsBonusAdm = getGoalsBonusBySelectedToolId(
        selectedTool.id,
        allGoalBonusAdministration,
      );

      toolGoalsBonusAdm = buildFormAdmConfigBody(toolGoalsBonusAdm);

      if (
        !isLoading &&
        !_.isEqual(toolGoalsBonusAdm, goalBonusAdministration)
      ) {
        setGoalBonusAdministration(toolGoalsBonusAdm);
      }
    }
  }, [
    allGoalBonusAdministration,
    goalBonusAdministration,
    isLoading,
    selectedTool.id,
  ]);

  const handleUpdateGoalsBonus = (newGoalsBonusAdm) => {
    if (!_.isEqual(goalBonusAdministration, newGoalsBonusAdm)) {
      const restBody = buildRestAdmConfigBody(newGoalsBonusAdm);
      updateGoalBonusAdministration(selectedTool.id, restBody);
    }
  };

  const handleSaveScenerie = ({ teamId }) => {
    if (selectedTool.id && teamId) {
      const teamInfo = getTeamInfoById(teamId, goalBonusAdministration.teams);
      createGoalsBonusScenerieTeam(
        selectedTool,
        teamInfo,
        userProfile,
        params.nextScenerieOrder,
      );
    }

    clearDialogControll();
  };

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
    cancelCallback();
  };

  return (
    <ScenerieTeamSelectDialog
      open={true}
      afterConfirm={handleSaveScenerie}
      afterCancel={clearDialogControll}
      goalBonusAdministration={goalBonusAdministration}
      setGoalBonusAdministration={() => {}}
      alternativeSaveTeamCallback={handleUpdateGoalsBonus}
      addedTeams={addedTeams}
    />
  );
};

const mapStateToProps = (state) => {
  const { selectedTool, allSceneries } = getSelectedToolStates(state);
  const { allGoalBonusAdministration, isLoading } = getGoalsBonusStates(state);

  const { user } = state;

  return {
    userProfile: user,
    selectedTool,
    allGoalBonusAdministration,
    isLoading,
    allSceneries,
  };
};

export default connect(mapStateToProps, {
  updateGoalBonusAdministration,
  createGoalsBonusScenerieTeam,
})(GoalsAndBonus);
