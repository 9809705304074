import * as actions from "./_actions";
import send from "../../dispatcher";

function observeBoard({ stompClient, dispatch }) {
  // Cenários
  stompClient.subscribe("/cenaries/create", (response) => {
    send(actions.postScenery, response, dispatch);
  });

  stompClient.subscribe("/cenaries/update", (response) => {
    send(actions.putScenery, response, dispatch);
  });

  stompClient.subscribe("/cenaries/archiveUnarchive", (response) => {
    send(actions.archiveUnarchiveCenary, response, dispatch);
  });

  stompClient.subscribe("/cenaries/delete", (response) => {
    send(actions.deleteCenary, response, dispatch);
  });

  // Temas
  stompClient.subscribe("/themes/create", (response) => {
    send(actions.postTheme, response, dispatch);
  });

  stompClient.subscribe("/themes/update", (response) => {
    send(actions.putTheme, response, dispatch);
  });

  stompClient.subscribe("/themes/archiveUnarchive", (response) => {
    send(actions.archiveUnarchiveTheme, response, dispatch);
  });

  stompClient.subscribe("/themes/delete", (response) => {
    send(actions.deleteTheme, response, dispatch);
  });

  // Questões
  stompClient.subscribe("/questions/create", (response) => {
    send(actions.postQuestion, response, dispatch);
  });

  stompClient.subscribe("/questions/update", (response) => {
    send(actions.putQuestion, response, dispatch);
  });

  stompClient.subscribe("/questions/archiveUnarchive", (response) => {
    send(actions.archiveUnarchiveQuestion, response, dispatch);
  });

  stompClient.subscribe("/questions/delete", (response) => {
    send(actions.deleteQuestion, response, dispatch);
  });

  // Insights
  stompClient.subscribe("/insights/create", (response) => {
    send(actions.postInsight, response, dispatch);
  });

  stompClient.subscribe("/insights/update", (response) => {
    send(actions.putInsight, response, dispatch);
  });

  stompClient.subscribe("/insights/changeInsightName", (response) => {
    send(actions.postInsight, response, dispatch);
  });

  stompClient.subscribe("/insights/archiveUnarchiveMany", (response) => {
    send(actions.archiveUnarchiveManyInsights, response, dispatch);
  });

  stompClient.subscribe("/insights/delete", (response) => {
    send(actions.deleteInsight, response, dispatch);
  });

  return stompClient;
}

export default observeBoard;
