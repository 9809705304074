import { defineMessages } from "react-intl";
import { getDomainLink } from "../../utils/accessLevels";
import { translatedText } from "../../utils/translationUtils";

const messages = defineMessages({
  new_notification: { id: "global.new_notification" },
  forMoreInfo: { id: "global.forMoreInfo" },
});

const translation = (id) => translatedText(id, messages);

export const projectConcludedTemplate = (
  description = "",
  message = "",
  domainLink = "",
) => {
  const new_notification_title = translation("new_notification");
  const for_more_info = translation("forMoreInfo");

  const domainUrl = getDomainLink();

  let descriptionField = description
    ? `<div style="padding-bottom: 15px; padding-left:10px; color: #666;">${description}</div>`
    : "";

  const traceabilityMessage = `<div style="padding-bottom: 15px; padding-left:10px; color: #666;">${message}</div>`;
  const forMoreInformation = `<div style="padding-bottom: 15px; padding-left:10px; color: #666;">${for_more_info}</div>`;
  const appRookauCom = `<div style="padding-bottom: 15px; padding-left:10px; color: #666;"><a href=${domainLink}>${domainUrl}</a></div>`;

  return (
    '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">' +
    "<html>" +
    "  <head>" +
    '    <meta charset="UTF-8" />' +
    '    <meta content="width=device-width, initial-scale=1" name="viewport" />' +
    '    <meta name="x-apple-disable-message-reformatting" />' +
    '    <meta http-equiv="X-UA-Compatible" content="IE=edge" />' +
    '    <meta content="telephone=no" name="format-detection" />' +
    "    <title></title>" +
    '    <style type="text/css">' +
    "      @import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');" +
    "      body {" +
    "        font-family: 'Montserrat', sans-serif !important;" +
    "      }" +
    "      d, span, a, div, p, font {" +
    "        font-family: 'Montserrat', sans-serif !important;" +
    "      }" +
    "    </style>" +
    "  </head>" +
    "" +
    '  <body style="margin: 20px;">' +
    '    <div class="es-wrapper-color">' +
    "      <!--[if gte mso 9]>" +
    '        <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">' +
    '          <v:fill type="tile" color="#f6f6f6"></v:fill>' +
    "        </v:background>" +
    "      <![endif]-->" +
    "      <div" +
    '        style="width: 600px; margin: 0 auto; background-color: #fcfcfc; border-radius: 4px; border: 1px solid #eee;"' +
    "      >" +
    '       <h2 style="margin: 10px 0px 10px 10px;">' +
    new_notification_title +
    "       </h2>" +
    descriptionField +
    "       <strong>" +
    traceabilityMessage +
    "       </strong>" +
    forMoreInformation +
    appRookauCom +
    '       <div style="width: 600px; height: auto; padding: 2px 20px 0px 0px;">' +
    '         <a href="https://www.rookau.com" target="_blank">' +
    "            <img width:100%" +
    '            src="https://splace-images.s3-sa-east-1.amazonaws.com/cabecalho-Rookau.png"' +
    "         /></a>" +
    "      </div>" +
    "      </div>" +
    "    </div>" +
    "  </body>" +
    "</html>"
  );
};
