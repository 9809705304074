import * as entity from "./entity";
import * as calc from "./calc";
import * as filter from "./filter";
import * as display from "./display";

const actionsPlanUtils = {
  ...entity,
  ...calc,
  ...filter,
  ...display,
};

export default actionsPlanUtils;
