import {
  FETCH_INSIGHT_FOLLOWUP,
  UPDATE_INSIGHT_FOLLOWUP,
} from "../actions/types";

const INITIAL_STATE = {
  allInsightFollowUps: {},
};

const insightFollowUpReducer = (state = INITIAL_STATE, action) => {
  const currentAllFollowUps = state.allInsightFollowUps || {};

  const updateAllInsightFollowUps = (insightId, followUps) => {
    return { ...currentAllFollowUps, [insightId]: followUps };
  };

  switch (action.type) {
    case FETCH_INSIGHT_FOLLOWUP:
    case UPDATE_INSIGHT_FOLLOWUP:
      return {
        ...state,
        allInsightFollowUps: updateAllInsightFollowUps(
          action.payload.insightId,
          action.payload.followUps,
        ),
      };
    case "FETCH_ALL_FOLLOWUP":
      return {
        ...state,
        allInsightFollowUps: action.payload,
      };
    default:
      return state;
  }
};

export default insightFollowUpReducer;
