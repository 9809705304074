import axios from "axios";
import PropTypes from "prop-types";
import { lazy } from "react";
import { Col } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";

import { PROCESSES_BIG_PICTURE } from "constants/tools.constants";
import {
  changeShowHiddenAnswers,
  changeShowHiddenCenarys,
  changeShowHiddenQuestions,
  changeShowHiddenThemes,
  changeStatusInsight,
} from "../../../actions/toolsConfigurationsActions";
import { globalMessages } from "../../../utils/global";
import utils from "../../../utils/toolUtils";
import { SimpleMenu } from "../../Common/SimpleMenu";
import { getWorkspacesStates } from "../../customMapStates";
import PeriodConfigurationView from "./PeriodConfigurationView";
import CopyBoardCard from "./components/CopyBoardCard";

const ToolProcessConfigurationsContainer = lazy(() =>
  import("./ProcessTool/ToolProcessConfigurationsContainer")
);

const { hasToolTemplate } = utils;

const SwitchButton = styled.div`
  .configName {
    margin: 0px;
  }
`;

const ToolConfigurationsView = (props) => {
  const {
    showHiddenCenarys,
    showHiddenThemes,
    showHiddenQuestions,
    showHiddenAnswers,
    showHiddenExpectation,
    intl,
    tool,
    changeStatusInsight,
    selectedWorkspaceID,
    selectedDomainId,
    workspaceTools,
    loggedUser,
  } = props;

  const toolTypeId = tool?.tool?.id || null;

  function getName(name) {
    if (tool.id) {
      axios.post(
        `/api/tool-templates/newFromSelectedTool/${tool.id}/description/${name}`
      );
    }
  }

  function onChangeShowHiddenCenarys(value) {
    props.changeShowHiddenCenarys(value);
  }

  function onChangeShowHiddenThemes(value) {
    props.changeShowHiddenThemes(value);
  }

  function onChangeShowHiddenQuestions(value) {
    props.changeShowHiddenQuestions(value);
  }

  function onChangeShowHiddenAnswers(value) {
    props.changeShowHiddenAnswers(value);
  }

  const createSwitchButton = (text, changeShowState, value) => {
    return (
      <SwitchButton className="theSwitch">
        <Col md={9}>
          <h2 className="configName">{text}</h2>
        </Col>
        <Col md={3}>
          <div id="switcher">
            <label className="switcherLabel">
              <input
                type="checkbox"
                readOnly
                checked={value}
                id="backgroundCheckbox"
                onClick={() => changeShowState(!value)}
              />
              <div className="background">
                <p className="state" />
              </div>
            </label>
          </div>
        </Col>
      </SwitchButton>
    );
  };

  return (
    <div className="col-xs-12">
      <div className="col-xs-12 col-md-4">
        <h2>Configurações do Quadro</h2>
        {createSwitchButton(
          intl.formatMessage(globalMessages.showArchivedCenaries),
          onChangeShowHiddenCenarys,
          showHiddenCenarys
        )}
        {createSwitchButton(
          intl.formatMessage(globalMessages.showArchivedThemes),
          onChangeShowHiddenThemes,
          showHiddenThemes
        )}
        {createSwitchButton(
          intl.formatMessage(globalMessages.showArchivedQuestions),
          onChangeShowHiddenQuestions,
          showHiddenQuestions
        )}
        {createSwitchButton(
          intl.formatMessage(globalMessages.showArchivedAnswers),
          onChangeShowHiddenAnswers,
          showHiddenAnswers
        )}
        {toolTypeId === 3 && (
          <>
            <hr />
            {createSwitchButton(
              "Mostrar status dos insights por expectativa",
              changeStatusInsight,
              showHiddenExpectation
            )}
          </>
        )}

        {hasToolTemplate(toolTypeId) && (
          <div style={{ margin: "24px 0 0 24px" }}>
            <SimpleMenu buttonText="Criar modelo do quadro">
              <CopyBoardCard
                title="Copiar Quadro"
                description="O modelo salvo estará disponível no cadastro de ferramentas"
                onSave={getName}
              />
            </SimpleMenu>
          </div>
        )}
      </div>
      {toolTypeId === PROCESSES_BIG_PICTURE && (
        <ToolProcessConfigurationsContainer
          selectedToolId={tool.id}
          loggedUserId={loggedUser.id}
        />
      )}
      <PeriodConfigurationView
        selectedWorkspaceID={selectedWorkspaceID}
        selectedDomainId={selectedDomainId}
        selectedTool={tool}
        toolTypeId={toolTypeId}
        workspaceTools={workspaceTools}
        loggedUser={loggedUser}
      />
    </div>
  );
};

ToolConfigurationsView.propTypes = {
  showHiddenCenarys: PropTypes.bool.isRequired,
  showHiddenThemes: PropTypes.bool.isRequired,
  showHiddenQuestions: PropTypes.bool.isRequired,
  showHiddenAnswers: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { selectedWorkspaceID, workspaceTools = [] } =
    getWorkspacesStates(state);
  const { user, access } = state;
  const { selectedDomainId = null } = access || {};

  return {
    workspaceTools,
    selectedWorkspaceID,
    selectedDomainId,
    loggedUser: user,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    changeShowHiddenCenarys,
    changeShowHiddenThemes,
    changeShowHiddenQuestions,
    changeShowHiddenAnswers,
    changeStatusInsight,
  })(ToolConfigurationsView)
);
