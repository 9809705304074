import moment from "moment";
import _ from "lodash";
import {
  getHeaders,
  getFakeHeader,
  getStaticHeader,
  getCSVDataFormat,
  handleValidation,
  handleErrorMessage,
  getExampleBody,
  handleMassUpdate,
  filterConfigBySelectedToolId,
} from "../../KPIs/utils/index";
import { Controls } from "reactour";

export const getGoalsAndBonusDependencies = (
  allGoalBonusAdministration = [],
  selectedToolId = "",
) => {
  let dataToExport = {};
  let exampleBody = {};

  const filteredGoalsBonus = filterConfigBySelectedToolId(
    allGoalBonusAdministration,
    selectedToolId,
  );

  const rowsConfig = [{ line: 2, fill: "eeeeee" }];

  const dateTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");

  const staticHeader = getStaticHeader();

  dataToExport = {
    sheet0: {
      rowsConfig,
      staticHeader,
      headers: getHeaders(),
      fakeHeader: getFakeHeader(),
      data: getCSVDataFormat(filteredGoalsBonus),
    },
  };

  exampleBody = {
    sheet0: {
      title: "",
      headers: staticHeader,
      data: getExampleBody(),
    },
  };

  return {
    dataToExport,
    filename: "Goals-Bonus-" + dateTime,
    handleValidation,
    handleMassUpdate,
    additionalData: {
      administration: filteredGoalsBonus[0],
      selectedToolId,
    },
    staticHeader,
    exampleBody,
    handleErrorMessage,
  };
};
