import axios from "axios";
import { fetchCompanyParticipants } from "./companyActions";
import { fetchTeamParticipants } from "./teamsActions";

export const insertParticipantToCompany = (companyId, addParticipantIds) => {
  return async (dispatch) => {
    const promises = addParticipantIds.map(async (participantId) => {
      const postParticipant = await axios
        .post(`/api/companies/${companyId}/participants/${participantId}`)
        .then(() => {});

      return postParticipant;
    });

    await Promise.all(promises);
    dispatch(fetchCompanyParticipants(companyId));
  };
};

export function createNewParticipant(newParticipant, companyId) {
  return (dispatch) => {
    return axios
      .post("/api/participants", newParticipant)
      .then((resp) => {
        const participant = resp.data;

        dispatch({
          type: "CREATE_NEW_PARTICIPANT",
          payload: participant,
        });

        if (companyId)
          dispatch(insertParticipantToCompany(companyId, [participant.id]));

        return participant;
      })
      .catch((error) => console.log(error));
  };
}

export function getAllTheParticipants() {
  const request = axios.get("/api/participants");
  return {
    type: "GET_ALL_THE_PARTICIPANTS",
    payload: request,
  };
}

const postParticipantsTeam = (teamId, participantId) => {
  return axios
    .post(`/api/teams/${teamId}/participants/${participantId}`)
    .then((res) => res);
};

export const insertParticipantToTeams = (teamId, participantIDs) => {
  return (dispatch) => {
    const saveParticipants = async () => {
      await Promise.all(
        participantIDs.map(async (id) => {
          const response = await postParticipantsTeam(teamId, id);
          return response;
        }),
      );

      dispatch(fetchTeamParticipants(teamId));
    };

    saveParticipants();
  };
};

export const deleteTeamParticipant = (teamID, participantId) => {
  return (dispatch) => {
    return axios
      .delete(`/api/teams/${teamID}/participants/${participantId}`)
      .then(() => {
        dispatch(fetchTeamParticipants(teamID));
      });
  };
};

export const deleteCompanyParticipant = (companyID, participant_ID) => {
  const url = `/api/companies/${companyID}/participants/${participant_ID}`;
  return (dispatch) => {
    return axios.delete(url).then(() => {
      dispatch(fetchCompanyParticipants(companyID));
    });
  };
};

export const totalWorkspacesOfParticipantes = (participant_ID) => {
  return () => {
    return axios
      .get(`/api/participants/${participant_ID}/totalWorkspaces`)
      .then((res) => {
        return res.data;
      });
  };
};

export const isEmailInUse = (email = "") => {
  return () => {
    return axios.get(`/api/isEmailInUse?email=${email}`).then((res) => {
      return res.data;
    });
  };
};

export const totalTeamsOfParticipantes = (participant_ID) => {
  return () => {
    return axios
      .get(`/api/participants/${participant_ID}/totalTeams`)
      .then((res) => {
        return res.data;
      });
  };
};
