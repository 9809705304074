const INITIAL_STATE = {
  type: null,
  payload: {}
};

const appActionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "APP_ACTION":
      if (action.payload) {
        return action.payload;
      }

      return state;
    default:
      return state;
  }
};

export default appActionsReducer;
