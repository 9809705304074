import companyTranslations from "./company";
import profilePageTranslations from "./profilePage";
import learnMoreTranslations from "./learnMore";
import timelineTranslations from "./timeline";
import domainTranslations from "./domain";

const generalTranslations = [
  companyTranslations,
  profilePageTranslations,
  learnMoreTranslations,
  timelineTranslations,
  domainTranslations,
];

const finalTranslations = [...generalTranslations];

export default finalTranslations;
