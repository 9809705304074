import { UPDATE_ALL_PROJECT_PRIORITIZATION } from "../actions/types";

import { updateAllList } from "../utils/reducerUtils";

import _ from "lodash";

const INITIAL_STATE = {
  all: [],
  loading: false,
  allInsightPrioritizations: []
};

const projectPrioritizationReducer = (state = INITIAL_STATE, action) => {
  const isLoading =
    action.loading === true || action.loading === false
      ? action.loading
      : state.loading;

  switch (action.type) {
    case UPDATE_ALL_PROJECT_PRIORITIZATION:
      if (action.payload) {
        const newPayload = _.cloneDeep(action.payload);

        return {
          ...state,
          all: updateAllList(state.all, [newPayload], "selectedToolId"),
          loading: isLoading
        };
      }

      return { ...state, loading: isLoading };
    case "INSIGHT_PROJECT_PRIORITIZATION":
      const { payload } = action;

      if (payload) {
        return {
          ...state,
          allInsightPrioritizations: updateAllList(
            state.allInsightPrioritizations,
            payload,
            "insightId"
          )
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default projectPrioritizationReducer;
