import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TableEvaluationScale from "../tables/TableEvaluationScale";
import TableQualificationScale from "../tables/TableQualificationScale";
import TablePotentialScale from "../tables/TablePotentialScale";
import { deleteScale } from "../../../../../../../actions/skillAssessmentActions";
import { getCompetenceConfigByComposition } from "../../../../../../../utils/skillsAssessment/constants";
import { getStyledErrorMessage } from "../../../../../../../utils/formValidation";
import {
  evaluationScaleErrorSlugs,
  qualificationScaleErrorSlugs,
  potentialScaleErrorSlugs,
} from "../../../../../../../utils/skillsAssessment/errorConstants";
import { getScalesDeleteDialogInfo } from "../../../../../../../utils/skillsAssessment/deletes";

const TabScales = (props) => {
  const {
    toolAdministration = {},
    toggleOpenForms,
    updateAdministration,
    setConfirmationDialog,
    resetConfirmDialog,
    errors,
    deleteScale,
    selectedTool,
  } = props;

  const {
    enableQualification,
    enablePotential,
  } = getCompetenceConfigByComposition(toolAdministration.composition);

  const handleDeleteScale = (scaleSlug, scaleId) => {
    if (toolAdministration[scaleSlug] && scaleId) {
      deleteScale(selectedTool.id, scaleSlug, scaleId);
    }
  };

  const deleteConfirmation = (
    title,
    description,
    deleteCallback = () => {},
  ) => {
    setConfirmationDialog({
      open: true,
      title,
      description,
      onConfirm: () => {
        deleteCallback();
        resetConfirmDialog();
      },
      onCancel: resetConfirmDialog,
    });
  };

  const openDeleteScale = (scaleSlug, scaleId) => {
    const { title, description } = getScalesDeleteDialogInfo(scaleSlug);

    deleteConfirmation(title, description, () =>
      handleDeleteScale(scaleSlug, scaleId),
    );
  };

  return (
    <div className="tab-content" style={{ margin: "0px" }}>
      <div className="row">
        <div className="col-xs-12">
          <h4>Escalas de Avaliação</h4>
        </div>
        <div className="col-xs-12 col-lg-4">
          <div className="row">
            <TableEvaluationScale
              toolAdministration={toolAdministration}
              updateAdministration={updateAdministration}
              handleAdd={() => toggleOpenForms("newEvaluationScale")}
              openDeleteDialog={openDeleteScale}
            />
            {getStyledErrorMessage(evaluationScaleErrorSlugs(), errors)}
          </div>
        </div>
        {enablePotential && (
          <div className="col-xs-12 col-lg-4">
            <div className="row">
              <TablePotentialScale
                toolAdministration={toolAdministration}
                updateAdministration={updateAdministration}
                handleAdd={() => toggleOpenForms("newPotentialScale")}
                openDeleteDialog={openDeleteScale}
              />
              {getStyledErrorMessage(potentialScaleErrorSlugs(), errors)}
            </div>
          </div>
        )}
        {enableQualification && (
          <div className="col-xs-12 col-lg-4">
            <div className="row">
              <TableQualificationScale
                toolAdministration={toolAdministration}
                updateAdministration={updateAdministration}
                handleAdd={() => toggleOpenForms("newQualificationScale")}
                openDeleteDialog={openDeleteScale}
              />
              {getStyledErrorMessage(qualificationScaleErrorSlugs(), errors)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

TabScales.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
  selectedTool: PropTypes.object.isRequired,
  toggleOpenForms: PropTypes.func.isRequired,
  updateAdministration: PropTypes.func.isRequired,
  setConfirmationDialog: PropTypes.func.isRequired,
  resetConfirmDialog: PropTypes.func.isRequired,
  deleteScale: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
};

export default connect(null, { deleteScale })(TabScales);
