import React from "react";
import Measurement from "../../../../../Measurement";
import { checkAccess } from "../../../../../../utils/accessLevels";
import { ProjectAdministration } from "./Administration";

const tabs = (appendProps = {}) => {
  let filteredTabs = [];

  const addTab = (id, label, Component) => {
    filteredTabs = [
      ...filteredTabs,
      {
        id,
        label,
        component: <Component {...appendProps} />,
      },
    ];
  };

  if (checkAccess(["MANAGER", "MODERATOR", "ADMIN", "OWNER"])) {
    addTab("measurement", "Medição", Measurement);
    addTab("administration", "Administração", ProjectAdministration);
  }

  return filteredTabs;
};

export default tabs;
