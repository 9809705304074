import * as Excel from "exceljs/dist/exceljs";
import * as csvUtils from "./utils";

const {
  addLines,
  addFakeHeader,
  applyRowsConfig,
  createBuffer,
  exportSaveFile,
} = csvUtils;

export async function saveAsExcel(dependencies = {}) {
  const { dataToExport = {}, filename = "" } = dependencies || {};

  const wb = new Excel.Workbook();

  Object.keys(dataToExport).forEach((sheetName, index) => {
    const { data = [], headers = [], fakeHeader = {}, rowsConfig = [] } =
      dataToExport[sheetName] || {};

    let ws = wb.addWorksheet();

    ws.columns = [...headers];
    ws.name = "sheet" + index;

    fakeHeader && addFakeHeader(ws, fakeHeader);
    data && addLines(ws, data);
    rowsConfig && applyRowsConfig(ws, rowsConfig);
  });

  const buffer = await createBuffer(wb);
  exportSaveFile(buffer, filename, ".xlsx");
}

export function simpleSaveAsExcel(
  dataToExport = [],
  headers = [],
  fakeHeader = {},
  filename = "default_file_name",
  rowsConfig = [],
) {
  return saveAsExcel({
    dataToExport: {
      sheet: { data: dataToExport, headers, fakeHeader, rowsConfig },
    },
    filename,
  });
}

export { csvUtils };
