import PropTypes from "prop-types";
import { useState } from "react";
import { defineMessages } from "react-intl";
import { translatedText } from "../../utils/translationUtils";
import ChangelogDialog from "../Common/ChangelogDialog";

import logoFooter from "../../assets/img/logo_novo.svg";

const messages = defineMessages({
  global_copyright: { id: "global.copyright" },
  global_version: { id: "global.version" },
  global_see_changelog: { id: "global.see_changelog" },
});

const translation = (id) => translatedText(id, messages);

const Footer = ({ isAuthenticated, company, version, copyrightYear, site }) => {
  const [changelogOpen, setChangelogOpen] = useState(false);

  const toggleChangelogOpen = () => {
    setChangelogOpen(!changelogOpen);
  };

  const additionalStyles = !isAuthenticated
    ? { margin: "0px", opacity: "0.6" }
    : {};

  return (
    <>
      <ChangelogDialog
        open={changelogOpen}
        onConfirm={toggleChangelogOpen}
        onCancel={toggleChangelogOpen}
      />
      <footer
        className="main-footer text-center"
        style={{
          width: !isAuthenticated ? "100%" : "88%",
          ...additionalStyles,
        }}
      >
        <div className="col-sm-3 pull-left">
          <img
            src={logoFooter}
            alt="logo footer"
            className="img-responsive"
            style={{ height: "30px" }}
          />
        </div>
        <div className="col-sm-6">
          <strong>
            Copyright &copy; {copyrightYear}
            &nbsp;
            <a href={site} target="_blank" rel="noreferrer">
              {company}
            </a>
          </strong>
          .&nbsp;<span>{translation("global_copyright")}.</span>
        </div>
        <div className="hidden-xs col-sm-3">
          <span className="pull-right">
            <b>{translation("global_version")}:&nbsp;</b>
            <a
              style={{ cursor: "pointer", fontWeight: "bold" }}
              onClick={toggleChangelogOpen}
            >{`${version} - ${translation("global_see_changelog")}`}</a>
          </span>
        </div>
      </footer>
    </>
  );
};

Footer.propTypes = {
  version: PropTypes.string.isRequired,
  copyrightYear: PropTypes.string.isRequired,
  site: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool,
};

Footer.defaultProps = {
  isAuthenticated: false,
};

export default Footer;
