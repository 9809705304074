import React, { useState, useMemo } from "react";
import FormDialog from "../../../../Common/FormDialog";
import MaterialTextField from "../../../../Common/MaterialTextField";
import { useCompareChanges } from "../../../../../utils/hooks/useCompareChanges";

const defaultChecklist = {
  title: "",
  completed: false,
};

export default function ChecklistModal({
  open,
  onConfirm,
  onCancel,
  editingChecklist,
  title,
  confirmText,
  cancelText,
  handleDeleteChecklistItem,
  isLoading,
}) {
  const [checklist, setChecklist] = useState(
    editingChecklist?.item || defaultChecklist,
  );

  const { haveChanges } = useCompareChanges({
    base: editingChecklist?.item || defaultChecklist,
    compare: checklist,
    isLoading,
  });

  const blockSubmit = useMemo(() => {
    if (!checklist.title) return true;
    return false;
  }, [checklist]);

  function updateChecklist(field, value) {
    setChecklist({ ...checklist, [field]: value });
  }

  function handleConfirm() {
    onConfirm(checklist);
  }

  return (
    <FormDialog
      open={open}
      title={title}
      onConfirm={handleConfirm}
      confirmText={confirmText}
      cancelText={cancelText}
      onCancel={() => onCancel(haveChanges)}
      blockConfirm={blockSubmit || isLoading || !haveChanges}
      isLoading={isLoading}
      bottomLeft={
        <div style={{ display: "flex", flexDirection: "flex-start" }}>
          {editingChecklist?.item?.id && (
            <button
              className="btn btn-danger"
              onClick={handleDeleteChecklistItem}
              disabled={isLoading}
            >
              Excluir
            </button>
          )}
        </div>
      }
    >
      <div
        style={{
          opacity: isLoading ? 0.5 : 1,
        }}
      >
        <MaterialTextField
          label="Título"
          value={checklist.title}
          onChange={(e) => updateChecklist("title", e.target.value)}
          primaryInputProps={{ maxLength: 90 }}
        />
      </div>
    </FormDialog>
  );
}
