import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import MaterialTextField from "./MaterialTextField";
import { getValidFunc } from "../../utils/validations";

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      "& > div:before": {
        borderBottom: "none !important",
      },
      "& > div > div.MuiInputAdornment-positionEnd": {
        position: "relative",
        left: "-12px",
      },
    },
  }),
);

const SimpleExcelCollumn = (props) => {
  const {
    id,
    label,
    value,
    type = "text",
    align = "left",
    textEndAdorment = null,
    tdStyle = {},
    inputStyle = {},
    title = null,
    colSpan = "1",
    primaryInputProps = {},
    secondaryInputProps = {},
    tdClass = "",
    autoFocus = false,
    multiline,
    rows,
    handleOnRemove,
    icon,
  } = props;

  const onChange = getValidFunc(props.onChange);
  const onClick = getValidFunc(props.onClick);

  const classes = useStyles();

  const haveValue = value || value === 0;

  return (
    <td
      style={{ padding: "0px", backgroundColor: "#f6f6f6", ...tdStyle }}
      title={title}
      colSpan={colSpan}
      className={tdClass}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {icon && icon}
        <MaterialTextField
          id={id}
          label={label}
          value={haveValue ? value : ""}
          variant="standard"
          margin="none"
          type={type}
          hideLabel
          autoFocus={autoFocus}
          inputStyle={{
            textAlign: align,
            padding: "0px 12px",
            height: "40px",
            fontSize: "10px",
            ...inputStyle,
          }}
          className={classes.textField}
          endAdorment={
            textEndAdorment
              ? { text: textEndAdorment, position: "end", fontSize: "12px" }
              : {}
          }
          onChange={(e) => onChange(e)}
          onClick={(e) => onClick(e)}
          primaryInputProps={primaryInputProps}
          secondaryInputProps={secondaryInputProps}
          multiline={multiline}
          rows={rows}
        />
        {typeof handleOnRemove === "function" && (
          <div
            style={{ padding: 5, cursor: "pointer" }}
            onClick={handleOnRemove}
          >
            <i className="fas fa-times" />
          </div>
        )}
      </div>
    </td>
  );
};

export default SimpleExcelCollumn;
