import _ from "lodash";
import { buildParticipantsBonusInfo } from "./entity";
import { filterGoalByPeriod, getSingleIndicatorResults } from "../kpis/calc2";
import {
  getObjectInfoById,
  getOnlySlugValues,
  getOnlySublevelArrays,
  getFilteredObjectsList,
} from "../ArrayUtils";
import { getMemberInfoById } from "../memberUtils";
import { administrativeRoleWorkflowAcces } from "../../constants/goalsAndBonus";
import { getSelectedToolStates } from "../../components/customMapStates";
import { getCustomInsightsOrder } from "../entityUtils";
import store from "../../Store";

export const getRoleInfoById = (roleId, roleBonuses) => {
  const vRoleId = Number(roleId);

  return getObjectInfoById(vRoleId, roleBonuses, "id");
};

export const getTeamInfoById = (teamId, teams, onlyIndex = false) => {
  const vTeamId = Number(teamId);

  return getObjectInfoById(vTeamId, teams, "id", onlyIndex);
};

export const getRoleBonusInfoById = (
  roleBonusId,
  roleBonuses,
  onlyIndex = false,
) => {
  const vRoleBonusId = Number(roleBonusId);

  return getObjectInfoById(vRoleBonusId, roleBonuses, "id", onlyIndex);
};

export const getAdministrativeRoleInfoById = (
  administrativeRoleId,
  administrativeRoles,
  onlyIndex = false,
) => {
  const vAdministrativeRoleId = Number(administrativeRoleId);

  return getObjectInfoById(
    vAdministrativeRoleId,
    administrativeRoles,
    "id",
    onlyIndex,
  );
};

export const getParticipantInfoByMemberId = (
  memberId = null,
  participants = [],
) => {
  const vMemberId = Number(memberId);
  let participantInfo = null;

  if (vMemberId && participants.length > 0) {
    participants.forEach((pInfo) => {
      const { memberParticipant } = pInfo;

      if (memberParticipant.id === vMemberId) {
        participantInfo = pInfo;
      }
    });
  }

  return participantInfo;
};

export const getParticipantInfoById = (
  goalsBonusParticipantId,
  participants,
  onlyIndex = false,
) => {
  const vGoalsBonusParticipantId = Number(goalsBonusParticipantId);

  return getObjectInfoById(
    vGoalsBonusParticipantId,
    participants,
    "id",
    onlyIndex,
  );
};

export const getGoalInfoById = (goalId, goals, onlyIndex = false) => {
  const vGoalId = Number(goalId);

  return getObjectInfoById(vGoalId, goals, "id", onlyIndex);
};

export const getAllParticipantInfo = (
  memberId,
  allMembers = [],
  roleBonusId = "",
  roleBonuses = [],
  showProfile = true,
) => {
  const vMemberId = Number(memberId);

  const allMemberInfo = {};
  allMemberInfo.goalTargets = getRoleBonusInfoById(roleBonusId, roleBonuses);

  if (showProfile)
    allMemberInfo.profile = getMemberInfoById(vMemberId, allMembers);

  return allMemberInfo;
};

export const getParticipantsByRoleId = (roleId, participants = []) => {
  const finalParticipants = [];

  if (participants.length > 0) {
    participants.forEach((pInfo) => {
      const { roleBonus = {} } = pInfo;

      if (roleBonus.id === roleId) finalParticipants.push(pInfo);
    });
  }

  return finalParticipants;
};

export const getFilteredGoals = (
  filterValue = "APPROVAL",
  goals = [],
  insightsFilter = null,
  filterBy = "workflowStatus",
) => {
  const finalFiltered = [];

  const checkInsightFilter = (goalInfo) => {
    let display = true;

    if (insightsFilter && Array.isArray(insightsFilter)) {
      display = false;

      if (goalInfo.relatedInsights) {
        goalInfo.relatedInsights.forEach((compareInsightId) => {
          if (insightsFilter.indexOf(compareInsightId) > -1) display = true;
        });
      }
    }

    return display;
  };

  if (goals && goals.length > 0) {
    goals.forEach((goalInfo) => {
      if (
        goalInfo[filterBy] &&
        goalInfo[filterBy] === filterValue &&
        checkInsightFilter(goalInfo)
      )
        finalFiltered.push(goalInfo);
    });
  }

  return finalFiltered;
};

export const getGoalsBonusBySelectedToolId = (
  selectedToolId,
  allGoalsBonusConfiguration = [],
) => {
  let finalReturn = null;

  if (allGoalsBonusConfiguration.length > 0) {
    allGoalsBonusConfiguration.forEach((toolInfo) => {
      if (toolInfo?.selectedToolId === selectedToolId) {
        finalReturn = toolInfo;
      }
    });
  }

  return finalReturn;
};

export const getGoalsRelationTree = (
  selectedToolId,
  allSceneries = [],
  allThemes = {},
  allQuestions = {},
  allInsights = {},
) => {
  const buildTree = {};

  if (
    selectedToolId &&
    allSceneries.length > 0 &&
    Object.keys(allThemes).length > 0 &&
    Object.keys(allQuestions).length > 0 &&
    Object.keys(allInsights).length > 0
  ) {
    allSceneries.forEach((scenerieInfo) => {
      if (scenerieInfo.selectedTool.id === selectedToolId) {
        const { goalsBonusProgramTeamId } = scenerieInfo;
        const scenerieThemes = allThemes[scenerieInfo.id];
        if (scenerieThemes?.length > 0 && goalsBonusProgramTeamId) {
          scenerieThemes.forEach((themeInfo) => {
            const { goalsBonusProgramRoleBonusId } = themeInfo;
            const themeQuestions = allQuestions[themeInfo.id];

            if (themeQuestions?.length > 0 && goalsBonusProgramRoleBonusId) {
              themeQuestions.forEach((questionInfo) => {
                const { goalsBonusProgramParticipantId } = questionInfo;
                const questionInsights = allInsights[questionInfo.id];

                if (
                  questionInsights &&
                  questionInsights.length > 0 &&
                  goalsBonusProgramParticipantId
                ) {
                  const orderedQuestionInsights = getCustomInsightsOrder(
                    questionInsights,
                  );

                  orderedQuestionInsights.forEach((insightInfo) => {
                    const { goalsBonusProgramGoalId } = insightInfo;

                    if (goalsBonusProgramGoalId) {
                      const currentScenerieBuild =
                        buildTree[goalsBonusProgramTeamId] || {};
                      const currentRoles = currentScenerieBuild.roles || {};
                      const currentTeamRoleRelation =
                        currentRoles[goalsBonusProgramRoleBonusId] || {};
                      const currentParticipants =
                        currentTeamRoleRelation.participants || {};
                      const currentParticipantGoalRelation =
                        currentParticipants[goalsBonusProgramParticipantId] ||
                        {};
                      const currentGoals =
                        currentParticipantGoalRelation.goals || [];

                      buildTree[goalsBonusProgramTeamId] = {
                        sceneryId: scenerieInfo.id,
                        roles: {
                          ...currentRoles,
                          [goalsBonusProgramRoleBonusId]: {
                            themeId: themeInfo.id,
                            participants: {
                              ...currentParticipants,
                              [goalsBonusProgramParticipantId]: {
                                questionId: questionInfo.id,
                                goals: [
                                  ...currentGoals,
                                  {
                                    avatar: insightInfo.avatar,
                                    responsibleName: insightInfo.responsibleMember
                                      ? insightInfo.responsibleMember.name
                                      : "",
                                    insightId: insightInfo.id,
                                    goalsBonusProgramGoalId,
                                  },
                                ],
                              },
                            },
                          },
                        },
                      };
                    }
                  });
                }
              });
            }
          });
        }
      }
    });
  }

  return buildTree;
};

export const getFilteredGoalsRelationTree = (
  goalBonusAdministration,
  goalsRelationTree,
  filters = {},
  statusFilter = "ANY",
) => {
  const finalFilteredRelationTree = [];

  const isOnFilter = (slug, compareId) => {
    return (
      !filters[slug] ||
      filters[slug].length === 0 ||
      filters[slug]?.indexOf(compareId) > -1
    );
  };

  if (Object.keys(goalsRelationTree).length > 0 && goalBonusAdministration) {
    Object.keys(goalsRelationTree).forEach((goalsBonusProgramTeamId) => {
      const teamRelation = goalsRelationTree[goalsBonusProgramTeamId];

      if (isOnFilter("sceneries", teamRelation.sceneryId)) {
        const teamInfo = getTeamInfoById(
          goalsBonusProgramTeamId,
          goalBonusAdministration.teams,
        );

        Object.keys(teamRelation.roles).forEach(
          (goalsBonusProgramRoleBonusId) => {
            const roleRelation =
              teamRelation.roles[goalsBonusProgramRoleBonusId];
            const roleInfo = getRoleInfoById(
              goalsBonusProgramRoleBonusId,
              goalBonusAdministration.roleBonuses,
            );

            if (isOnFilter("themes", roleRelation.themeId)) {
              Object.keys(roleRelation.participants).forEach(
                (goalsBonusProgramParticipantId) => {
                  const participantRelation =
                    roleRelation.participants[goalsBonusProgramParticipantId];
                  const participantInfo = getParticipantInfoById(
                    goalsBonusProgramParticipantId,
                    goalBonusAdministration.participants,
                  );

                  if (isOnFilter("questions", participantRelation.questionId)) {
                    const participantGoals = [];
                    let participantAvatar = "";
                    let anyOnStatusFilter = false;

                    participantRelation.goals?.forEach(
                      ({ goalsBonusProgramGoalId, avatar }) => {
                        const goalInfo = getGoalInfoById(
                          goalsBonusProgramGoalId,
                          goalBonusAdministration.goals,
                        );
                        participantGoals.push(goalInfo);
                        participantAvatar = avatar;

                        if (
                          goalInfo.workflowStatus === statusFilter ||
                          statusFilter === "ANY"
                        )
                          anyOnStatusFilter = true;
                      },
                    );

                    if (anyOnStatusFilter) {
                      finalFilteredRelationTree.push({
                        teamInfo,
                        roleInfo,
                        participantInfo: {
                          ...participantInfo,
                          participantAvatar,
                        },
                        participantGoals,
                        questionId: participantRelation.questionId,
                      });
                    }
                  }
                },
              );
            }
          },
        );
      }
    });
  }

  return finalFilteredRelationTree;
};

export const getStructuredGoalsBonusRelationships = (goalsRelationships) => {
  const structured = {
    allSceneries: [],
    allThemes: {},
    allQuestions: {},
    allAnswers: {},
  };

  if (goalsRelationships.cenaries) {
    goalsRelationships.cenaries.forEach((cenaryInfo) => {
      structured.allSceneries.push({
        selectedTool: { id: cenaryInfo.selectedToolId },
        ...cenaryInfo,
      });
    });
  }

  if (goalsRelationships.themes) {
    goalsRelationships.themes.forEach((themeInfo) => {
      const parentId = themeInfo.cenaryId;

      if (parentId) {
        if (!structured.allThemes[parentId])
          structured.allThemes[parentId] = [];

        structured.allThemes[parentId].push({
          ...themeInfo,
        });
      }
    });
  }

  if (goalsRelationships.questions) {
    goalsRelationships.questions.forEach((questionInfo) => {
      const parentId = questionInfo.themeId;

      if (parentId) {
        if (!structured.allQuestions[parentId])
          structured.allQuestions[parentId] = [];

        structured.allQuestions[parentId].push({
          ...questionInfo,
        });
      }
    });
  }

  if (goalsRelationships.insights) {
    goalsRelationships.insights.forEach((insightInfo) => {
      const parentId = insightInfo.questionId;

      if (parentId) {
        if (!structured.allAnswers[parentId])
          structured.allAnswers[parentId] = [];

        structured.allAnswers[parentId].push({
          ...insightInfo,
        });
      }
    });
  }

  return structured;
};

export const filterOnlyGoalsOnTree = (goalsRelationTree) => {
  const finalFilteredGoals = [];

  goalsRelationTree.forEach(
    ({ participantGoals = [], participantInfo = {} }) => {
      if (participantGoals && participantGoals.length > 0) {
        participantGoals.forEach((goalInfo) => {
          const addedGoals = [...finalFilteredGoals.map(({ id }) => id)];
          const addedGoalIndex = addedGoals.indexOf(goalInfo.id);

          if (addedGoalIndex === -1) {
            finalFilteredGoals.push({
              ...goalInfo,
              participants: [participantInfo],
            });
          } else {
            const currentGoal = finalFilteredGoals[addedGoalIndex];
            const currentGoalParticipants = currentGoal.participants || [];
            const participantsId = [
              ...currentGoalParticipants.map(({ id }) => id),
            ];

            if (participantsId.indexOf(participantInfo.id) === -1) {
              finalFilteredGoals[addedGoalIndex].participants = [
                ...currentGoal.participants,
                participantInfo,
              ];
            }
          }
        });
      }
    },
  );

  return finalFilteredGoals;
};

export const getGoalsRelatedParticipants = (
  goalBonusAdministration = {},
  goalsRelationTree = {},
) => {
  const filteredGoalsRelation = getFilteredGoalsRelationTree(
    goalBonusAdministration,
    goalsRelationTree,
    {},
    "ANY",
  );
  const fGoals = filterOnlyGoalsOnTree(filteredGoalsRelation);

  return fGoals;
};

export const getAllParticipantsManagementInfo = (
  goalBonusAdministration,
  goalsRelationTree,
  filters = {},
  secondaryFilter,
) => {
  const { periodCycle, goals: originalGoals } = goalBonusAdministration;
  const periodFilter = secondaryFilter || periodCycle;
  const filterActive = !_.isEqual(periodFilter, periodCycle);

  const filteredGoals = [];

  if (Array.isArray(originalGoals))
    originalGoals.forEach((goalInfo) => {
      const filtered = filterActive
        ? filterGoalByPeriod(goalInfo, periodFilter)
        : goalInfo;
      filteredGoals.push(filtered);
    });

  const filteredConfig = {
    ...goalBonusAdministration,
    goals: filteredGoals,
  };

  const filteredGoalsRelation = getFilteredGoalsRelationTree(
    filteredConfig,
    goalsRelationTree,
    filters,
    "ANY",
  );

  const allBonusInfo = buildParticipantsBonusInfo(
    filteredConfig,
    filteredGoalsRelation,
    filterActive,
  );

  const { allTotalBonus, totalLimitBonus } = allBonusInfo;

  return {
    participants: allBonusInfo.participants,
    allTotalBonus,
    totalLimitBonus,
  };
};

export const filterGoalsByTeams = (participantsRelation = []) => {
  const teams = {};
  const info = {};

  const participantAdded = (teamId, participantId) => {
    let found = false;

    if (
      info[teamId] &&
      info[teamId].participants &&
      Array.isArray(info[teamId].participants)
    ) {
      info[teamId].participants.forEach(({ id }) => {
        if (participantId === id) found = true;
      });
    }

    return found;
  };

  participantsRelation.forEach((participantRelation) => {
    const { teamInfo, participantGoals, participantInfo } = participantRelation;

    if (teamInfo.id) {
      const currentTeamInfo = info[teamInfo.id] || [];

      if (!participantAdded(teamInfo.id, participantInfo.id)) {
        const currentParticipants = currentTeamInfo.participants || [];

        info[teamInfo.id] = {
          ...currentTeamInfo,
          participants: [...currentParticipants, participantRelation],
        };
      }

      if (Array.isArray(participantGoals)) {
        participantGoals.forEach((goalInfo) => {
          const currentTeamGoals = teams[teamInfo.id] || [];
          const onlyTeamGoalsId = [...currentTeamGoals.map(({ id }) => id)];

          if (onlyTeamGoalsId.indexOf(goalInfo.id) === -1)
            teams[teamInfo.id] = [...currentTeamGoals, goalInfo];
        });
      }
    }
  });

  return { teams, info };
};

export const getQuestionByParticipantId = (
  participantId,
  allQuestions = {},
) => {
  let questionInfo = null;

  if (Object.keys(allQuestions).length > 0) {
    Object.keys(allQuestions).forEach((themeId) => {
      const themeQuestions = allQuestions[themeId];

      if (Array.isArray(themeQuestions)) {
        themeQuestions.forEach((qInfo) => {
          const { goalsBonusProgramParticipantId } = qInfo;

          if (goalsBonusProgramParticipantId === participantId)
            questionInfo = qInfo;
        });
      }
    });
  }

  return questionInfo;
};

export const getRoleAccesHierarchyById = (
  roleId,
  roleBonuses = [],
  accessType = "WORKFLOW",
) => {
  const foundHierarchy = [];

  roleBonuses.forEach((roleInfo) => {
    const access =
      accessType === "WORKFLOW" ? roleInfo.roleAccess : roleInfo.roleViewAccess;

    if (access.indexOf(roleId) > -1) foundHierarchy.push(roleInfo);
  });

  return foundHierarchy;
};

export const getParticipantByRolesList = (
  findRoles = [],
  participants = [],
) => {
  const foundParticipants = [];

  participants.forEach((participantInfo) => {
    const { roleBonus = {} } = participantInfo;

    if (findRoles.indexOf(roleBonus.id) > -1)
      foundParticipants.push(participantInfo);
  });

  return foundParticipants;
};

export const getAdminApprovers = (administrativeRoles, allMembers) => {
  const foundMembers = [];

  const addMembers = (members) => {
    members.forEach((memberId) => {
      const memberInfo = getObjectInfoById(memberId, allMembers, "id");

      if (memberInfo && memberInfo.id) foundMembers.push(memberInfo);
    });
  };

  administrativeRoles.forEach((admRoleInfo) => {
    const { access = [], members = [] } = admRoleInfo;

    access.forEach((accessLevel) => {
      if (administrativeRoleWorkflowAcces.indexOf(accessLevel) > -1)
        addMembers(members);
    });
  });

  return foundMembers;
};

export const getAdminApproversEmails = (administrativeRoles, allMembers) => {
  return getOnlySlugValues(
    getAdminApprovers(administrativeRoles, allMembers),
    "email",
  );
};

export const getWorkflowApproversByRole = (
  roleId = [],
  roleBonuses = [],
  participants = [],
  administrativeRoles = [],
  allMembers = [],
) => {
  const roleHierarchyAcces = getRoleAccesHierarchyById(
    roleId,
    roleBonuses,
    "WORKFLOW",
  );
  const participantApprovers = getParticipantByRolesList(
    getOnlySlugValues(roleHierarchyAcces, "id"),
    participants,
  );
  const participantApproversEmail = getOnlySlugValues(
    getOnlySlugValues(participantApprovers, "memberParticipant"),
    "email",
  );
  const adminApprovers = getAdminApproversEmails(
    administrativeRoles,
    allMembers,
  );

  const finalEmails = [];
  [...participantApproversEmail, ...adminApprovers].forEach((email) => {
    if (finalEmails.indexOf(email) === -1) finalEmails.push(email);
  }); // Removendo emails repetidos

  return finalEmails;
};

export const getApproversEmails = (
  editingGoalInfo = {},
  goalBonusAdministration,
  goalsRelationTree,
  allMembers = [],
) => {
  let emailsToSend = [];

  const {
    roleBonuses = [],
    participants = [],
    administrativeRoles = [],
  } = goalBonusAdministration;

  if (editingGoalInfo.relationshipType === "INDIVIDUAL") {
    const goalRelatedParticipants = getGoalsRelatedParticipants(
      goalBonusAdministration,
      goalsRelationTree,
    );

    const onlySelectedGoal =
      getObjectInfoById(editingGoalInfo.id, goalRelatedParticipants) || {};

    const workflowSelectedParticipant =
      onlySelectedGoal.participants && onlySelectedGoal.participants[0]
        ? onlySelectedGoal.participants[0]
        : {};
    const participantRole = workflowSelectedParticipant.roleBonus || {};

    emailsToSend = getWorkflowApproversByRole(
      participantRole.id,
      roleBonuses,
      participants,
      administrativeRoles,
      allMembers,
    );
  } else {
    emailsToSend = getAdminApproversEmails(administrativeRoles, allMembers);
  }

  return emailsToSend;
};

export const getParticipantRelationById = (
  participantId,
  participantsRelations = [],
) => {
  let finalPartRelation = null;

  if (Array.isArray(participantsRelations)) {
    participantsRelations.forEach((pRelation) => {
      const { participantInfo = {} } = pRelation;

      if (participantInfo.id === participantId) {
        finalPartRelation = pRelation;
      }
    });
  }

  return finalPartRelation;
};

export const getParticipantsEmailsByIds = (participantIds, participants) => {
  const emails = [];

  participants.forEach((participantInfo) => {
    if (participantIds.indexOf(participantInfo.id) > -1) {
      const { memberParticipant = {} } = participantInfo;
      const { email = null } = memberParticipant;

      if (email) emails.push(email);
    }
  });

  return emails;
};

export const getGoalWorkflowParticipantEmails = (
  goalBonusAdministration,
  goalsRelationTree,
  workflowGoalId,
) => {
  const goalsRelatedParticipants = getGoalsRelatedParticipants(
    goalBonusAdministration,
    goalsRelationTree,
  );
  const relatedParticipantsGoalInfo = getObjectInfoById(
    workflowGoalId,
    goalsRelatedParticipants,
    "id",
  );
  const relatedParticipants = relatedParticipantsGoalInfo.participants || [];
  const participantIds = getOnlySlugValues(relatedParticipants, "id");
  const participantEmails = getParticipantsEmailsByIds(
    participantIds,
    relatedParticipants,
  );

  return participantEmails;
};

export const getSelectedGoalsBonusGoalsPercentages = (
  selectedToolId,
  allGoalsBonusConfiguration,
) => {
  const foundGoals = [];

  const selectedGoalsBonusTool = getGoalsBonusBySelectedToolId(
    selectedToolId,
    allGoalsBonusConfiguration,
  );

  if (selectedGoalsBonusTool && selectedGoalsBonusTool.goals) {
    const { goalTargets, goals, zeroWhenNegative } = selectedGoalsBonusTool;

    goals.forEach((goalInfo) => {
      const { goalPercentage } = getSingleIndicatorResults(
        goalInfo,
        goalTargets,
        zeroWhenNegative,
      );

      foundGoals.push({
        ...goalInfo,
        percentage: Number(goalPercentage),
      });
    });
  }

  return foundGoals;
};

export const buildRelatedParticipants = (
  allAnswers = [],
  relatedInsights = [],
) => {
  const onlyInsights = getOnlySublevelArrays(allAnswers);
  const onlyRelatedInsights = getFilteredObjectsList(
    relatedInsights,
    onlyInsights,
    "id",
  );

  return getOnlySlugValues(onlyRelatedInsights, "question");
};

export const getParticipantOptions = (participants = []) => {
  const participantOptions = [];

  participants.forEach((participantInfo) => {
    const memberParticipant = participantInfo.memberParticipant || {};

    participantOptions.push({
      participantId: participantInfo.id,
      name: memberParticipant.name,
      email: memberParticipant.email,
      memberId: memberParticipant.id,
      type: memberParticipant.type,
      memberParticipant,
      goalsBonusProgramParticipantId: participantInfo.id,
    });
  });

  return participantOptions;
};

export const buildParticipantsOnBoardOptions = (
  allQuestions = [],
  participants = [],
) => {
  const newParticipantsOnBoard = [];

  const onlyQuestions = getOnlySublevelArrays(allQuestions);
  const participantIds = getOnlySlugValues(participants, "id");
  const participantOptions = getParticipantOptions(participants);
  const filteredParticipantsOnBoard = getFilteredObjectsList(
    participantIds,
    onlyQuestions,
    "goalsBonusProgramParticipantId",
  );

  filteredParticipantsOnBoard.forEach((questionInfo) => {
    const participantInfo = getObjectInfoById(
      questionInfo.goalsBonusProgramParticipantId,
      participantOptions,
      "participantId",
    );

    newParticipantsOnBoard.push({
      id: questionInfo.id,
      questionId: questionInfo.id,
      ...participantInfo,
    });
  });

  return newParticipantsOnBoard;
};

export const getAlreadyRegisteredGoals = (goals = []) => {
  const registered = [];

  if (goals && Array.isArray(goals)) {
    goals.forEach((goalInfo) => {
      if (goalInfo.id) registered.push(goalInfo.id);
    });
  }

  return registered;
};

export function getRelatedInsightByParticipant(goalInfo, participantInfo) {
  const state = store.getState();
  const { allQuestions, allAnswers } = getSelectedToolStates(state);
  const onlyQuestions = getOnlySublevelArrays(allQuestions);
  const filtered = onlyQuestions.find(
    ({ goalsBonusProgramParticipantId }) =>
      goalsBonusProgramParticipantId === participantInfo?.id,
  );
  if (filtered?.id) {
    const filteredInsight = allAnswers[filtered.id]?.find(
      ({ goalsBonusProgramGoalId }) => goalsBonusProgramGoalId === goalInfo?.id,
    );
    if (filteredInsight?.id) return filteredInsight.id;
  }

  return null;
}
