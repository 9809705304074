import _ from "lodash";
import { getComparableQuery } from "./StringUtils";

export const isObj = (possibleObj) => {
  return typeof possibleObj === "object" && possibleObj !== null;
};

export const deepFind = (obj, path) => {
  const paths = path.split(".");
  let current = obj;

  for (let i = 0; i < paths.length; ++i) {
    if (current[paths[i]] === undefined) {
      return undefined;
    }
    current = current[paths[i]];
  }

  return current;
};

export const objSimplify = (
  obj,
  config = {
    path: "firstLevel.secondLevel.thirdLevel.andMore",
    newName: "newSimplifiedObjName",
    defaultValue: null,
  },
) => {
  let simplified;
  const newName = config.newName || "newSimplifiedObj";
  const defaultValue = config.defaultValue || null;

  if (isObj(obj) && config.path) simplified = deepFind(obj, config.path);

  return {
    ...obj,
    [newName]: simplified || defaultValue,
  };
};

export const clone = (array) => {
  return array.slice(0);
};

export const hasElement = (array, element) => {
  let result = false;
  array.forEach((elementToCompare) => {
    if (elementToCompare.id === element.id) {
      result = true;
    }
  });
  return result;
};

export const getEntitysNotFiled = (arrayEntitys) => {
  if (Array.isArray(arrayEntitys)) {
    return arrayEntitys.filter((entity) => !entity.filed);
  }

  return [];
};

export const addIfNotPresent = (array, elementToAdd) => {
  const result = array;
  if (array.length === 0) {
    result.push(elementToAdd);
  } else {
    array.forEach((elementToCompare) => {
      if (hasElement(result, elementToAdd) === false) {
        result.push(elementToAdd);
      }
    });
  }
  return result;
};

export const addAllThatNotPresent = (array, arrayToAdd) => {
  let result = array;
  arrayToAdd.forEach((elementToAdd) => {
    result = addIfNotPresent(result, elementToAdd);
  });
  return result;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = _.cloneDeep(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  for (let index = 0; index < result.length; index++) {
    if (result[index]) {
      result[index].order = index;
    }
  }

  return result;
};

export const findIndexById = (array, element) => {
  let result = -1;
  array.forEach((elementToCompare, index) => {
    if (elementToCompare.id === element.id) {
      result = index;
    }
  });
  return result;
};

export const groupBy = (array, group) => {
  return array.reduce((groups, item) => {
    const val = item[group];
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, []);
};

export const getObjectDifference = (object, base) => {
  function changes(object = {}, base = {}) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
};

export const getObjectChangedKeys = (updated, base) => {
  function changes(object = {}, base = {}) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = {
          changes:
            _.isObject(value) && _.isObject(base[key])
              ? changes(value, base[key])
              : value,
          base: base[key],
        };
      }
    });
  }

  return changes(updated, base);
};

export const getUpdatedObjectInArray = (
  newValue,
  array,
  fieldSlug,
  compareIndex,
) => {
  const newUpdatedArray = [...array];

  if (compareIndex !== null && newUpdatedArray[compareIndex]) {
    newValue = newValue || "";

    newUpdatedArray[compareIndex] = {
      ...newUpdatedArray[compareIndex],
      [fieldSlug]: newValue,
    };

    return newUpdatedArray;
  }

  return array;
};

export const getUpdatedObjectByArrayIndex = (array, newObject, index) => {
  const newUpdatedArray = [...array];
  index = Number(index);

  if (index !== null && newUpdatedArray[index]) {
    newObject = newObject || {};

    newUpdatedArray[index] = { ...newObject };

    return newUpdatedArray;
  }

  return array;
};

export const getUpdatedDeleteArrayByIndex = (array, index) => {
  const finalUpdatedArray =
    array && array.length > 0 ? _.cloneDeep([...array]) : [];
  index = Number(index);

  if (finalUpdatedArray.length > 0 && finalUpdatedArray[index]) {
    finalUpdatedArray.splice(index, 1);
  }

  return finalUpdatedArray;
};

export const getObjectInfoById = (
  compareId,
  sourceObj,
  compareBy = "id",
  onlyIndex = false,
  isNumber = false,
) => {
  let finalReturn = !onlyIndex ? {} : -1;

  const transform = (val) => (isNumber ? val.toString() : val);

  if (sourceObj && sourceObj.length > 0) {
    for (const index in sourceObj) {
      const iteratingObjectInfo = sourceObj[index];

      if (
        iteratingObjectInfo &&
        transform(iteratingObjectInfo[compareBy]) === transform(compareId)
      ) {
        finalReturn = !onlyIndex ? iteratingObjectInfo : Number(index);
        break;
      }
    }
  }

  return finalReturn;
};

export const getFilterByObjValues = (
  selectedOnes = [],
  sourceObj,
  compareBy = "id",
  selectionType = "include",
) => {
  const finalFiltered = [];

  function validateBySelectionType(indexOf) {
    if (selectionType === "include") return indexOf > -1;
    if (selectionType === "exclude") return indexOf === -1;

    return indexOf > -1;
  }

  if (sourceObj && sourceObj.length > 0) {
    for (const iteratingObjectInfo of sourceObj) {
      if (
        validateBySelectionType(
          selectedOnes.indexOf(iteratingObjectInfo[compareBy]),
          selectionType,
        )
      ) {
        finalFiltered.push(iteratingObjectInfo);
      }
    }
  }

  return finalFiltered;
};

export const getFilteredObjectsList = (
  selectedOnes = [],
  sourceObj,
  compareBy = "id",
) => {
  return getFilterByObjValues(selectedOnes, sourceObj, compareBy, "include");
};

export const getAllExcept = (
  exceptions = [],
  sourceObj = [],
  compareBy = "id",
) => {
  return getFilterByObjValues(exceptions, sourceObj, compareBy, "exclude");
};

export const reorderObjectBySlugs = (
  slugsOrder = [],
  data = [],
  fieldSlug = "",
) => {
  const addToArray = (array, newObj) => {
    array = array && array.length > 0 ? array : [];

    if (newObj) array.push(newObj);

    return array;
  };

  const reordered = [];
  const notReordered = [];
  const newClassifications = [];

  if (data && data.length > 0) {
    data.forEach((objInfo) => {
      if (objInfo[fieldSlug]) {
        const indexOrder = slugsOrder.indexOf(objInfo[fieldSlug]);
        const indexNewClassifications = newClassifications.indexOf(
          objInfo[fieldSlug],
        );
        let replaceIndex =
          indexOrder > -1
            ? indexOrder
            : indexNewClassifications + slugsOrder.length;

        if (indexOrder === -1 && indexNewClassifications === -1) {
          newClassifications.push(objInfo[fieldSlug]);
          replaceIndex = slugsOrder.length + newClassifications.length;
        }

        reordered[replaceIndex] = addToArray(reordered[replaceIndex], objInfo);
      } else {
        notReordered.push(objInfo);
      }
    });
  }

  const finalReordered = [];
  for (const index in reordered) {
    const list = reordered[index];

    list.forEach((obj) => finalReordered.push(obj));
  }

  return [...finalReordered, ...notReordered];
};

export const orderArrayByObjAttr = (
  array,
  attrSlug,
  subAttrSlug = false,
  onlyNumbers = false,
) => {
  const transform = (attr) => {
    return onlyNumbers ? Number(attr) : attr.toUpperCase();
  };

  if (array && array.length > 0) {
    if (attrSlug && !subAttrSlug) {
      return array.sort((a, b) => {
        if (a[attrSlug] && b[attrSlug]) {
          const a_attr = transform(a[attrSlug]);
          const b_attr = transform(b[attrSlug]);

          return a_attr > b_attr ? 1 : b_attr > a_attr ? -1 : 0;
        }
        return a[attrSlug] ? 1 : b[attrSlug] ? -1 : 0;
      });
    }
    if (attrSlug && subAttrSlug) {
      return array.sort((a, b) => {
        if (
          a[attrSlug] &&
          a[attrSlug][subAttrSlug] &&
          b[attrSlug] &&
          b[attrSlug][subAttrSlug]
        ) {
          const a_attr = transform(a[attrSlug][subAttrSlug]);
          const b_attr = transform(b[attrSlug][subAttrSlug]);

          return a_attr > b_attr ? 1 : b_attr > a_attr ? -1 : 0;
        }
        return a[attrSlug] && a[attrSlug][subAttrSlug]
          ? 1
          : b[attrSlug] && b[attrSlug][subAttrSlug]
          ? -1
          : 0;
      });
    }
  }

  return array;
};

export const getSlugifyArray = (
  array = [],
  attr = "",
  sep1 = "-",
  sep2 = "",
  slugify = true,
  notFilteredSlug = null,
) => {
  const finalFiltered = [];
  const filteredOnes = [];
  const notFiltered = [];
  array = Array.isArray(array) ? array : [];

  array.forEach((objInfo) => {
    const comparableAttr = getComparableQuery(objInfo[attr], slugify);

    if (comparableAttr) {
      filteredOnes.push(comparableAttr);

      const clonned = filteredOnes.filter(
        (filterObjInfo) => filterObjInfo === comparableAttr,
      );

      finalFiltered.push({
        ...objInfo,
        slug:
          clonned.length === 1
            ? objInfo[attr]
            : comparableAttr + sep1 + clonned.length + sep2,
      });
    } else {
      notFiltered.push({ objInfo, slug: notFilteredSlug });
    }
  });

  return [...finalFiltered, ...notFiltered];
};

export const getFilterChildRelatedByAttr = (
  compareValue,
  array,
  attrSlug = "id",
  subAttrSlug = false,
) => {
  const filteredArray = [];

  if (array && array.length > 0) {
    array.forEach((objInfo) => {
      const compareAttr = !subAttrSlug
        ? objInfo[attrSlug]
        : objInfo[attrSlug]
        ? objInfo[attrSlug][subAttrSlug]
        : null;

      if (compareAttr && compareAttr === compareValue)
        filteredArray.push(objInfo);
    });
  }

  return filteredArray;
};

const getFilteredArray = (
  entity = [],
  showArchived = false,
  archiveSlugAttr = "filed",
) => {
  const filteredArray = [];

  if (!showArchived && entity.length > 0) {
    entity.forEach((entityInfo) => {
      if (!entityInfo[archiveSlugAttr]) filteredArray.push(entityInfo);
    });
  }

  return filteredArray;
};

const getFilteredObject = (
  entity = {},
  showArchived = false,
  archiveSlugAttr = "filed",
) => {
  const filteredObject = {};

  if (!showArchived && Object.keys(entity).length > 0) {
    Object.keys(entity).forEach((entityId) => {
      if (Array.isArray(entity[entityId])) {
        entity[entityId].forEach((entityInfo) => {
          if (!entityInfo[archiveSlugAttr]) {
            const currentFiltered = filteredObject[entityId] || [];

            filteredObject[entityId] = [...currentFiltered, entityInfo];
          }
        });
      }
    });
  }

  return filteredObject;
};

export const getFilteredEntityByFiledStatus = (
  entity = [],
  showArchived = false,
  isArray = false,
  archiveSlugAttr = "filed",
) => {
  let filteredEntity = [];

  filteredEntity = isArray
    ? getFilteredArray(entity, showArchived, archiveSlugAttr)
    : getFilteredObject(entity, showArchived, archiveSlugAttr);

  return !showArchived ? filteredEntity : entity;
};

export const removeObjSlug = (array, removeSlug = "id") => {
  const updatedArray = [];

  if (array && array.length > 0) {
    array.forEach((info) => {
      if (info && info[removeSlug]) delete info[removeSlug];

      updatedArray.push(info);
    });
  }

  return updatedArray;
};

export const removeItemInArray = (
  array = [],
  removeValue = "",
  removeSlug = "id",
) => {
  let updatedArray = [];

  if (array?.length === 0) return [];

  array.forEach((info) => {
    if (info[removeSlug] !== removeValue) updatedArray.push(info);
  });

  return updatedArray;
};

export const getOnlySlugValues = (
  list,
  findSlug = "id",
  allowRepeated = false,
) => {
  const final = [];

  const pushSlugVals = (a) => {
    a.forEach((itemInfo) => {
      if (itemInfo) {
        const haveSlug = itemInfo[findSlug];
        const indexFound = haveSlug && final.indexOf(itemInfo[findSlug]) > -1;

        if (haveSlug && (allowRepeated || (!allowRepeated && !indexFound)))
          final.push(itemInfo[findSlug]);
      }
    });
  };

  if (list && Array.isArray(list)) {
    pushSlugVals(list);
  }

  if (list && typeof list === "object" && Object.keys(list).length > 0) {
    Object.keys(list).forEach((key) => {
      const array = list[key];

      if (Array.isArray(array)) {
        pushSlugVals(array);
      }
    });
  }

  return final;
};

export const getSublevelsValuesByKey = (array, key) => {
  const concatenatedArrayValues = getOnlySlugValues(array, key).reduce(
    (acc, item) => {
      return acc.concat(item);
    },
    [],
  );

  return concatenatedArrayValues;
};

export const getOnlySublevelArrays = (parentObj) => {
  const child = [];

  if (parentObj && Object.keys(parentObj).length > 0) {
    Object.keys(parentObj).forEach((key) => {
      const array = parentObj[key];

      if (Array.isArray(array)) {
        array.forEach((info) => {
          child.push(info);
        });
      }
    });
  }

  return child;
};

export const getOnlyObjKeys = (obj) => {
  return Object.keys(obj).map((key) => key);
};

export function iterationSearch(searchFor = null, sourceList = []) {
  let found = false;

  if (searchFor && Array.isArray(sourceList)) {
    for (const item of sourceList) {
      const validItem = item.toString().toUpperCase().replace(/\s/g, "").trim();

      const validSearchFor = searchFor
        .toString()
        .toUpperCase()
        .replace(/\s/g, "")
        .trim();

      if (validItem === validSearchFor) {
        found = true;

        break;
      }
    }
  }

  return found;
}

export function getArrayDiff(base, compareDiff) {
  return base.filter((i) => !compareDiff.includes(i));
}

export const aggregationByAtt = (att = null, sourceList = []) => {
  let filteredData = [];

  if (att && Array.isArray(sourceList)) {
    sourceList.forEach((element) => {
      filteredData = {
        ...filteredData,
        [element[att]]: [...(filteredData[element[att]] || []), element],
      };
    });
  }

  return filteredData;
};

export const getOnlyChildArrayValues = (mainArray) => {
  const final = [];

  mainArray.forEach((childArray) => {
    if (Array.isArray(childArray)) {
      childArray.forEach((value) => {
        if (final.indexOf(value) === -1) final.push(value);
      });
    }
  });

  return final;
};

export const compareStrings = (
  filterStr1,
  completeStr2,
  compareBy = "like",
) => {
  const compareStr1 = getComparableQuery(filterStr1 || "");
  const compareStr2 = getComparableQuery(completeStr2 || "");

  if (compareBy === "like") {
    const likeMatch = compareStr2.match(compareStr1);
    return !!(likeMatch && likeMatch[0]);
  }
  if (compareBy === "equal") return compareStr1 === compareStr2;

  return false;
};

export const getGenericSimpleStringSearch = (
  checkItems,
  checkFieldName = "title",
  filterString = "",
  searchType = "like",
) => {
  const filtered = [];

  if (Array.isArray(checkItems)) {
    checkItems.forEach((itemInfo) => {
      if (itemInfo[checkFieldName]) {
        const attrFound = compareStrings(
          filterString,
          itemInfo[checkFieldName],
          searchType,
        );

        if (attrFound) filtered.push(itemInfo);
      }
    });
  }

  return filtered;
};

export const arrayLevelBrancher = (
  arrayOfObjects,
  findSubArrayName = "subArray",
  finalArray,
  level = 0,
) => {
  if (!finalArray[level]) finalArray[level] = [];

  if (Array.isArray(arrayOfObjects)) {
    arrayOfObjects.forEach((arrayItemInfo) => {
      finalArray[level].push(arrayItemInfo);

      if (
        Array.isArray(arrayItemInfo[findSubArrayName]) &&
        arrayItemInfo[findSubArrayName].length > 0
      )
        arrayLevelBrancher(
          arrayItemInfo[findSubArrayName],
          findSubArrayName,
          finalArray,
          level + 1,
        );
    });
  }

  return [...finalArray];
};

export const simpleArrayUnNester = (
  arrayOfObjects = [],
  findSubArrayName = "subArray",
) => {
  // Essa função só irá funcionar corretamente, se todos os subArrays "infinitos" e subsequentes tenham o mesmo "titulo" ex: "subArray", do contrário será impossível encontrar todos os níveis abaixo dinamicamente... Se o objeto fosse preparado anteriormente seria possível (como na função "arraysNester"), porém, se o objeto foi preparado, significa que, de alguma forma, essa função não seria necessária e existem outras soluções mais viáveis.
  return arrayLevelBrancher(arrayOfObjects, findSubArrayName, [], 0);
};

export const toggleSlugOnArray = (arrayOfSlugs, toggleSlug, forceToggleTo) => {
  let updatedArray = [...arrayOfSlugs];

  if (Array.isArray(arrayOfSlugs) && toggleSlug) {
    const slugIndex = updatedArray.indexOf(toggleSlug);

    if (slugIndex > -1 && forceToggleTo !== true) {
      updatedArray = getUpdatedDeleteArrayByIndex(updatedArray, slugIndex);
    } else if (slugIndex === -1 && forceToggleTo !== false) {
      updatedArray.push(toggleSlug);
    }
  }

  return updatedArray;
};

export function getItemsByPage(items, page, ITEMS_PER_PAGE = 20) {
  return items.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);
}
