import { useState, useMemo } from "react";
import { usePaginationList } from "../../../utils/hooks/usePaginationList";
import {
  orderArrayByObjAttr,
  getObjectDifference,
} from "../../../utils/ArrayUtils";

const defaultSelectModal = {
  type: null,
  onConfirm: () => {},
  onCancel: () => {},
};

const defaultFilters = {
  participantIds: [],
  managerIds: [],
  secondEvaluatorIds: [],
  thirdEvaluatorIds: [],
  showOnlyWithChanges: false,
  showOnlyMissingEvaluators: false,
};

export default function useParticipantRoleChanges({
  toolAdministration,
  members,
  handleSaveExchangeRoles,
  enableSecondEvaluator,
  enableThirdEvaluator,
}) {
  const [selectedRelations, setSelectedRelations] = useState([]);
  const [page, setPage] = useState(1);
  const [selectModal, setSelectModal] = useState(defaultSelectModal);
  const [selectedIds, setSelectedIds] = useState([]);
  const [filters, setFilters] = useState(defaultFilters);

  const { participants } = toolAdministration;

  function canBeFiltered({
    memberId,
    hasChanges,
    hasMissingEvaluators,
    manager,
    secondEvaluator,
    thirdEvaluator,
  }) {
    if (filters.showOnlyWithChanges && !hasChanges) return false;
    if (filters.showOnlyMissingEvaluators && !hasMissingEvaluators)
      return false;
    if (
      filters.participantIds.length > 0 &&
      !filters.participantIds.includes(memberId)
    )
      return false;
    if (
      filters.managerIds.length > 0 &&
      !filters.managerIds.includes(manager?.id)
    )
      return false;
    if (
      filters.secondEvaluatorIds.length > 0 &&
      !filters.secondEvaluatorIds.includes(secondEvaluator?.id)
    )
      return false;
    if (
      filters.thirdEvaluatorIds.length > 0 &&
      !filters.thirdEvaluatorIds.includes(thirdEvaluator?.id)
    )
      return false;

    return true;
  }

  const participantsUpdatedList = useMemo(() => {
    return orderArrayByObjAttr(
      participants
        .map((participant) => {
          const updatedRelation =
            selectedRelations.find(
              (relation) => relation.participantId === participant.id,
            ) || {};

          const managerId = updatedRelation.managerId || participant.managerId;
          const manager = members.find((m) => m.id === managerId);
          const secondEvaluatorId =
            updatedRelation.secondEvaluatorId || participant.secondEvaluatorId;
          const secondEvaluator = members.find(
            (m) => m.id === secondEvaluatorId,
          );
          const thirdEvaluatorId =
            updatedRelation.thirdEvaluatorId || participant.thirdEvaluatorId;
          const thirdEvaluator = members.find((m) => m.id === thirdEvaluatorId);

          const participantWithChanges = {
            ...participant,
            managerId,
            secondEvaluatorId,
            thirdEvaluatorId,
          };

          const diff = getObjectDifference(participant, participantWithChanges);
          const hasChanges = Object.keys(diff)?.length > 0;
          const isMissing2Evaluator = enableSecondEvaluator && !secondEvaluator;
          const isMissing3Evaluator = enableThirdEvaluator && !thirdEvaluator;
          const hasMissingEvaluators =
            !manager || isMissing2Evaluator || isMissing3Evaluator;

          return {
            name: participant.memberParticipant.name,
            memberId: participant.memberParticipant.id,
            participant,
            participantWithChanges,
            diff,
            hasChanges,
            manager,
            secondEvaluator,
            thirdEvaluator,
            hasMissingEvaluators,
          };
        })
        .filter((p) => canBeFiltered(p)),
      "name",
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants, selectedRelations, filters]);

  const onlyWithChanges = participantsUpdatedList.filter((p) => p.hasChanges);

  const { pageItems, totalPages } = usePaginationList({
    list: participantsUpdatedList,
    page,
    pageSize: 25,
  });

  const pageItemsSelected = pageItems.filter(({ participant }) =>
    selectedIds.includes(participant.id),
  );
  const allPageItemsSelected =
    pageItems.length > 0 && pageItems.length === pageItemsSelected.length;

  function getUpdatedParticipantsEntity(updatedList) {
    return updatedList.map((p) => p.participantWithChanges);
  }

  function clearFilters() {
    setFilters(defaultFilters);
  }

  function resetAfterSaving() {
    setSelectedRelations([]);
    clearFilters();
  }

  function handleConfirm() {
    const administrationWithChanges = {
      ...toolAdministration,
      participants: getUpdatedParticipantsEntity(participantsUpdatedList),
    };

    handleSaveExchangeRoles(administrationWithChanges, resetAfterSaving);
  }

  function resetSelectModal() {
    setSelectModal(defaultSelectModal);
  }

  function getUpdatedParticipantRelation(
    participantId,
    field,
    newValue,
    relations,
  ) {
    if (
      relations.find((relation) => relation.participantId === participantId)
    ) {
      // Update existing if found
      const updated = relations.map((relation) => {
        if (relation.participantId === participantId) {
          return {
            ...relation,
            [field]: newValue,
          };
        }

        return relation;
      });

      return updated;
    }

    // Create new if not found
    const newRelation = {
      participantId,
      [field]: newValue,
    };

    return [...relations, newRelation];
  }

  function setSingleParticipantRelation(participantId, field, newValue) {
    const updatedRelation = getUpdatedParticipantRelation(
      participantId,
      field,
      newValue,
      selectedRelations,
    );
    setSelectedRelations(updatedRelation);
  }

  function setMultipleParticipantsRelation(ids, field, newValue) {
    const allUpdated = ids.reduce(
      (acc, id) => {
        const updatedRelation = getUpdatedParticipantRelation(
          id,
          field,
          newValue,
          acc,
        );

        return updatedRelation;
      },
      [...selectedRelations],
    );

    setSelectedRelations(allUpdated);
    setSelectedIds([]);
  }

  function resetParticipantChanges(participantId) {
    setSelectedRelations((current) =>
      current.filter((relation) => relation.participantId !== participantId),
    );
  }

  function handleOpenSelectMember({ multiple = false, participant, field }) {
    setSelectModal({
      type: "select_member",
      singleSelect: true,
      onConfirm: (selected) => {
        if (multiple) {
          setMultipleParticipantsRelation(selectedIds, field, selected[0].id);
        } else {
          setSingleParticipantRelation(participant.id, field, selected[0].id);
        }
        resetSelectModal();
      },
      onCancel: () => resetSelectModal(),
    });
  }

  function toggleSelectedId(participantId) {
    if (selectedIds.includes(participantId)) {
      setSelectedIds((current) => current.filter((id) => id !== participantId));
    } else {
      setSelectedIds((current) => [...current, participantId]);
    }
  }

  function toggleSelectAll() {
    if (allPageItemsSelected) {
      setSelectedIds([]);
    } else {
      const added = pageItems.map(({ participant }) => participant.id);
      const combined = [...new Set([...selectedIds, ...added])];
      setSelectedIds(combined);
    }
  }

  function updateFiltersByField(field, allSelected) {
    const updatedFilters = { ...filters, [field]: allSelected };
    setFilters(updatedFilters);
  }

  function handleOpenFilterByField(field) {
    setSelectModal({
      type: "select_member",
      title: "Selecione os membros",
      singleSelect: false,
      selected: filters[field],
      canSaveUnselected: true,
      onConfirm: (selected) => {
        const selectedIds = selected.map((s) => s.id);
        updateFiltersByField(field, selectedIds);
        resetSelectModal();
      },
      onCancel: () => resetSelectModal(),
    });
  }

  function toggleShowOnlyWithChanges() {
    setFilters((current) => {
      return {
        ...current,
        showOnlyWithChanges: !current.showOnlyWithChanges,
      };
    });
  }

  function toggleShowOnlyMissingEvaluators() {
    setFilters((current) => {
      return {
        ...current,
        showOnlyMissingEvaluators: !current.showOnlyMissingEvaluators,
      };
    });
  }

  const sumFiltersLength = useMemo(() => {
    return Object.keys(filters)
      .map((key) => {
        if (
          ["showOnlyWithChanges", "showOnlyMissingEvaluators"].includes(key)
        ) {
          return filters[key] === true ? 1 : 0;
        }
        return filters[key].length;
      })
      .reduce((a, b) => a + b, 0);
  }, [filters]);

  return {
    selectModal,
    onlyWithChanges,
    page,
    setPage,
    pageItems,
    totalPages,
    handleConfirm,
    resetParticipantChanges,
    handleOpenSelectMember,
    selectedIds,
    toggleSelectedId,
    toggleSelectAll,
    allPageItemsSelected,
    handleOpenFilterByField,
    filters,
    clearFilters,
    toggleShowOnlyWithChanges,
    toggleShowOnlyMissingEvaluators,
    hasFilters: sumFiltersLength > 0,
    sumFiltersLength,
  };
}
