import React from "react";
import ConfirmationDialog from "../../../../../../Common/ConfirmationDialog";

const ToolConfirmationDialog = ({ confirmDialog }) => {
  return (
    <ConfirmationDialog
      open={confirmDialog.open}
      title={confirmDialog.title}
      description={confirmDialog.description}
      onConfirm={confirmDialog.onConfirm}
      confirmText={confirmDialog.confirmText}
      onCancel={confirmDialog.onCancel}
      hideCancel={confirmDialog.hideCancel}
      confirmColor="#ff6347"
    />
  );
};

export default ToolConfirmationDialog;
