import React from "react";

export const answersAverage = (themes, questions, answers, config = {}) => {
  let calcThemes = [];
  let calcQuestions = [];
  let calcAnswers = [];
  let totalAnswers = 0;
  let totalSum = 0;
  let average = 0;

  if (themes) {
    if (config.showHiddenThemes === true) {
      calcThemes = themes;
    } else {
      themes.forEach((theme) => {
        if (theme.filed === null || theme.filed === false) {
          calcThemes.push(theme);
        }
      });
    }

    calcThemes.forEach((t) => {
      if (questions[t.id]) {
        if (config.showHiddenQuestions === true) {
          calcQuestions = questions[t.id];
        } else {
          questions[t.id].forEach((question) => {
            if (question.filed === null || question.filed === false) {
              calcQuestions.push(question);
            }
          });
        }

        calcQuestions.forEach((q) => {
          if (answers[q.id]) {
            if (config.showHiddenAnswers === true) {
              calcAnswers = answers[q.id];
            } else {
              answers[q.id].forEach((answer) => {
                if (
                  answer &&
                  (answer.filed === null || answer.filed === false)
                ) {
                  calcAnswers.push(answer);
                }
              });
            }

            calcAnswers.forEach((a) => {
              switch (a.status) {
                case "NÃO_INICIADO":
                  totalAnswers++;
                  break;
                case "INICIADO":
                  totalAnswers++;
                  totalSum += 0.25;
                  break;
                case "EM_ANDAMENTO":
                  totalAnswers++;
                  totalSum += 0.5;
                  break;
                case "PARCIALMENTE_REALIZADO":
                  totalAnswers++;
                  totalSum += 0.75;
                  break;
                case "REALIZADO":
                  totalAnswers++;
                  totalSum++;
                  break;
                case "CANCELADO":
                  break;
                default:
                  totalAnswers++;
                  break;
              }
            });
          }
        });
      }
    });
  }

  if (totalAnswers > 0) {
    average = (totalSum / totalAnswers) * 100;
  } else {
    average = 0;
  }

  return (
    <div className="average_tool pull-right">
      <span className="badge bg-purple">{average.toFixed(0)}%</span>
    </div>
  );
};
