import { apiJava as axios } from "../api";

import {
  FETCH_TOOLS,
  FETCH_WORKSPACE_SELECTED_TOOLS,
  CREATE_SELECTED_WORKSPACE_TOOL,
  SELECTED_TOOL_ID,
  SET_SELECTED_TOOL,
  UPDATE_SELECTED_TOOL,
  UPDATE_WORKSPACE_SELECTED_TOOLS,
  SELECT_CENARY,
  FETCH_TOOL_POINTS,
  FETCH_TOOL_POINTS_COUNTER,
} from "./types";

import {
  clearCenaries,
  fetchCenarys,
  fetchCenarysWithReturn,
  selectCenary,
} from "./cenaryActions";

import { getAllCompanyWorkspaces } from "./workspaceActions";
import {
  getOriginalDatabaseToolCategory,
  PEOPLE_BIG_PICTURE,
} from "../constants/tools.constants";

import { fetchEntityUsersWithRole } from "./accessLevelsActions";
import { sendNewToolNotification } from "./notifications/controllerActions";
import { getOnlySlugValues, getOnlySublevelArrays } from "../utils/ArrayUtils";
import { fetchAllSceneryThemes } from "./themesActions";
import { fetchAllQuestionAnswers } from "./answersActions";
import { fetchAllThemesQuestions } from "./questionsActions";
import { fetchAllPeopleEvaluation } from "./peopleEvaluationActions";

const getWorkspaceTools = (workspaceID) => {
  return axios
    .get(`/api/selected-tools/workspace/${workspaceID}`)
    .then((res) => res);
};

const getToolPoints = (toolID) => {
  return {};
  //return axios.get(`/api/selected-tools/${toolID}/points/`).then(res => res);
};

export const getAllCompanyToolPoints = (companyID) => {
  return (dispatch) => {
    const buildAllPoints = async () => {
      const companyWorkspaces = await getAllCompanyWorkspaces(companyID);

      let toolsList = [];

      if (companyWorkspaces.data) {
        companyWorkspaces.data.forEach(({ tools }) => {
          if (tools && tools.length > 0) {
            tools.forEach((toolInfo) => {
              toolsList = [...toolsList, toolInfo.id];
            });
          }
        });
      }

      await Promise.all(
        toolsList.map(async (toolID) => {
          const toolPoints = await getToolPoints(toolID);

          dispatch({
            type: FETCH_TOOL_POINTS,
            payload: { selectedToolId: toolID, points: toolPoints.data },
          });
        }),
      );
    };

    buildAllPoints();
  };
};

export const fetchTools = () => {
  return axios.get("/api/tools");
};

export const fetchToolPoints = (selectedToolId, sceneryId = "") => {
  return async (dispatch) => {
    if (selectedToolId) {
      const fetchedRes = await axios.get(
        `/api/selected-tools/${selectedToolId}/points/${sceneryId}`,
      );

      dispatch({
        type: FETCH_TOOL_POINTS,
        payload: { selectedToolId, points: fetchedRes.data },
      });
    }
  };
};

export const fetchToolPointsCounter = (selectedToolId) => {
  if (selectedToolId) {
    return (dispatch) => {
      return axios
        .get(`/api/selected-tools/${selectedToolId}/points/counter`)
        .then((res) => {
          dispatch({
            type: FETCH_TOOL_POINTS_COUNTER,
            payload: { selectedToolId, score: res.data },
          });
        });
    };
  }
};

export const fetchWorkspaceSelectedTools = (idWorkspace) => {
  return (dispatch) => {
    getWorkspaceTools(idWorkspace).then((res) => {
      const selectedTools = res.data;
      dispatch({
        type: FETCH_WORKSPACE_SELECTED_TOOLS,
        payload: selectedTools.map((tool) => ({
          ...tool,
          workspaceId: idWorkspace,
        })),
      });
      dispatch(fetchToolsInfos(selectedTools));
    });
  };
};

export const fetchToolsInfos = (selectedTools) => {
  return (dispatch) => {
    selectedTools.map((selectedTool) => {
      // return axios
      //   .get(`/api/selected-tools/${selectedTool.id}/details`)
      //   .then(res => {
      //     dispatch({
      //       type: UPDATE_WORKSPACE_SELECTED_TOOLS,
      //       payload: { ...selectedTool, ...res.data }
      //     });
      //   });

      return dispatch({
        type: UPDATE_WORKSPACE_SELECTED_TOOLS,
        payload: { ...selectedTool },
      });
    });
  };
};

export const fetchWorkspaceSelectedToolsWithReturn = (idWorkspace) => {
  return (dispatch) => {
    return axios
      .get(`/api/selected-tools/workspace/${idWorkspace}`)
      .then((res) => {
        const selectedTools = res.data;
        return selectedTools;
      });
  };
};

export const updateWorkspaceSelectedTools = (tool) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_WORKSPACE_SELECTED_TOOLS, payload: tool });
  };
};

const handleAddAccessToCenary = (selectedToolId = 0) => {
  return async (dispatch) => {
    const allSceneries = await dispatch(fetchCenarysWithReturn(selectedToolId));

    const { grantSceneryAccess } = await import("./accessControllerActions");

    allSceneries.forEach(async (scenerie) => {
      await dispatch(await selectCenary(scenerie.id));
      await dispatch(grantSceneryAccess(null, "LEADER", "EDIT"));
    });
  };
};

export const createSelectedWorkspaceTool = (
  toolId,
  selectedWorkspaceId,
  managerId = false,
  goToTool = true,
  role = "MANAGER",
  next,
) => {
  return async (dispatch) => {
    const { grantToolAccess } = await import("./accessControllerActions");

    return axios
      .post(
        `/api/selected-tools/tool/${toolId}/workspace/${selectedWorkspaceId}`,
      )
      .then(async (res) => {
        const { id: selectedToolId = 0 } = res.data;

        if (typeof next === "function") next(res.data);

        dispatch({
          type: CREATE_SELECTED_WORKSPACE_TOOL,
          payload: res.data,
        });

        if (goToTool) await dispatch(setSelectedTool(res.data));

        await dispatch(handleAddAccessToCenary(selectedToolId));
        await dispatch(
          grantToolAccess(managerId || null, role, "EDIT", selectedToolId),
        );
        await dispatch(sendNewToolNotification(selectedWorkspaceId));
        await dispatch(fetchWorkspaceSelectedTools(selectedWorkspaceId));
      });
  };
};

export const createToolInWorkspaceWithTemplate = async (
  selectedWorkspaceId,
  toolId,
) => {
  return (dispatch) => {
    axios
      .post(
        `/api/selected-tools/workspace/${selectedWorkspaceId}/template/${toolId}`,
      )
      .then((response) => {
        dispatch({
          type: CREATE_SELECTED_WORKSPACE_TOOL,
          payload: response.data,
        });
      });
  };
};

export const getSelectedTool = (newSelectedToolId) => {
  return (dispatch) => {
    const selectedToolId =
      newSelectedToolId || localStorage.getItem(SELECTED_TOOL_ID);
    if (selectedToolId) {
      return axios.get(`/api/selected-tools/${selectedToolId}`).then((res) => {
        if (!res) return;

        const selectedTool = res.data;
        dispatch(setSelectedTool(selectedTool));
      });
    }
  };
};

export const getTool = (toolId) => {
  /*
  const cachedTool = localStorage.getItem(String(`tool${toolId}`));

  if (cachedTool) return cachedTool[0];
*/
  if (!toolId) return {};

  return axios.get(`/api/selected-tools/${toolId}`).then((res) => {
    localStorage.setItem(String(`tool${toolId}`), [res.data]);
    return res.data;
  });
};

export const setSelectedTool = (selectedTool) => {
  return async (dispatch) => {
    dispatch(unsetSelectedTool());
    dispatch(clearCenaries());
    await dispatch({ type: SELECT_CENARY, payload: null });

    const validSelectedTool = {
      ...selectedTool,
      tool: {
        ...selectedTool.tool,
        type: getOriginalDatabaseToolCategory(selectedTool.tool.id),
      },
    };

    localStorage.setItem(SELECTED_TOOL_ID, validSelectedTool.id);

    await dispatch({ type: SET_SELECTED_TOOL, payload: validSelectedTool });
    await dispatch(
      fetchEntityUsersWithRole(validSelectedTool.id, "SELECTED_TOOL"),
    );
  };
};

export const unsetSelectedTool = () => {
  return (dispatch) => {
    localStorage.removeItem(SELECTED_TOOL_ID);
    dispatch({ type: SET_SELECTED_TOOL, payload: {} });
  };
};

export const addTag = (selectedToolId, tag) => {
  return (dispatch) => {
    return axios
      .post(`/api/selected-tools/${selectedToolId}/tags/${tag}`)
      .then((res) => {
        dispatch({ type: UPDATE_SELECTED_TOOL, payload: res.data });
        dispatch(updateWorkspaceSelectedTools(res.data));
      });
  };
};

export const deleteTag = (selectedToolId, tagId) => {
  return (dispatch) => {
    return axios
      .delete(`/api/selected-tools/${selectedToolId}/tags/${tagId}`)
      .then((res) => {
        dispatch({ type: UPDATE_SELECTED_TOOL, payload: res.data });
        dispatch(updateWorkspaceSelectedTools(res.data));
      });
  };
};

export const clearToolRelatedEntities = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_CENARIES" });
    dispatch({ type: "RESET_THEMES" });
    dispatch({ type: "RESET_QUESTIONS" });
    dispatch({ type: "RESET_INSIGHTS" });
  };
};

export const getRelatedData = (selectedToolId, toolTypeId) => {
  return async (dispatch) => {
    if (toolTypeId === PEOPLE_BIG_PICTURE) {
      return {
        toolIdentifier: "CAREER",
        data: await dispatch(fetchAllPeopleEvaluation(selectedToolId)),
      };
    }

    return null;
  };
};

export const fetchToolTree = (selectedToolId, toolTypeId, resetAll = false) => {
  return async (dispatch) => {
    if (selectedToolId) {
      const relatedData = dispatch(getRelatedData(selectedToolId, toolTypeId));

      if (resetAll) dispatch(clearToolRelatedEntities());

      const sceneries = await dispatch(fetchCenarys(selectedToolId));

      if (Array.isArray(sceneries)) {
        const themes = await dispatch(
          fetchAllSceneryThemes(getOnlySlugValues(sceneries, "id")),
        );

        const onlyThemes = getOnlySublevelArrays(themes);

        if (Array.isArray(onlyThemes)) {
          const questions = await dispatch(
            fetchAllThemesQuestions(getOnlySlugValues(onlyThemes, "id")),
          );

          const onlyQuestions = getOnlySublevelArrays(questions);

          const answers = await dispatch(
            fetchAllQuestionAnswers(
              getOnlySlugValues(onlyQuestions, "id"),
              relatedData,
            ),
          );
        }
      }
    }
  };
};
