import React from "react";
import { defineMessages } from "react-intl";
import FormDialog from "../../../Common/FormDialog";
import MaterialTextField from "../../../Common/MaterialTextField";
import { translatedText } from "../../../../utils/translationUtils";

const translation = (id, values) => translatedText(id, messages, values);

const messages = defineMessages({
  measu_values: {
    id: "measu_values"
  },
  measu_option: {
    id: "measu_option"
  }
});

const ExampleModal = ({ exampleModalConfig, setCallExampleModalConfig }) => {
  const hideModal = () => {
    setCallExampleModalConfig({ ...exampleModalConfig, callModal: false });
  };

  const { options = [] } = exampleModalConfig;

  return (
    <FormDialog
      open={exampleModalConfig.callModal}
      title={translation("measu_values")}
      onConfirm={() => hideModal()}
      onCancel={() => hideModal()}
      onExit={() => hideModal()}
      bodyStyle={{ padding: "0 15px 15px 15px" }}
    >
      {options.map((option, index) => {
        return (
          <MaterialTextField
            key={index}
            id={option}
            label={translation("measu_option") + index}
            value={option}
          />
        );
      })}
    </FormDialog>
  );
};

export default ExampleModal;
