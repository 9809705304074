export const pt = {
  description: "Descrição",
  maturityCriteria: "Critérios de maturidade",
  maturityLevels: "Níveis de maturidade",
  automation: "Automação",
  quality: "Qualidade",
  knowledge: "Conhecimento",
  activityDeliveryDeadline: "Prazo de entrega da atividade",
  managementAndControlLevel: "Nível de gestão e controle",
  level1: "Nível 1",
  level2: "Nível 2",
  level3: "Nível 3",
  level4: "Nível 4",
  level5: "Nível 5",
};
