const pt = {
  title_what_strategyplace: "O que é ROOKAU?",
  what_strategyplace:
    "É uma plataforma que reúne diversas ferramentas de gestão que possibilita seus usuários organizarem todas as suas informações em um único lugar, possibilitando maior eficiência e eficácia na tomada de decisão.",
  title_what_overview: "O que é Overview?",
  what_overview:
    "É o resumo de introdução da plataforma no qual você tem uma visão geral do que está acontecendo nas Atividades e na Timeline, conforme os membros vão atualizando e modificando o software.",
  title_what_workspace: "O que é Workspace?",
  what_workspace:
    "É sua área de trabalho! <br /><br />Esse objeto é utilizado para criar e identificar uma seção de trabalho, facilitando sua organização e navegação dentro do sistema. <br /><br /> Você pode criar vários Workspaces com diferentes ferramentas dentro de cada um. <br /><br />Outra característica importante é que você pode vincular um Workspace a sua empresa, de forma que os Workspaces vinculados são compartilhados com outros membros e o não vinculado trata-se de seu Workspace privado.",
  title_what_tools: "O que são ferramentas?",
  what_tools:
    "São ferramentas de gestão utilizadas principalmente para controlar um processo. As ferramentas podem ser selecionadas na plataforma e preenchidas conforme sua necessidade de uso.",
  title_what_okr: "O que é OKR?",
  what_okr:
    "OKR (Objectives and Key Results) é uma metodologia de gestão onde você pode definir Objetivos (O), os quais são qualitativos e devem ser ambiciosos, porém, atingíveis. <br/><br/>Depois disso, define-se os Resultados Chave (KR) (normalmente de 2 a 5), os quais são quantitativos e mensuráveis. <br/><br/>Usualmente são definidos novos OKRs trimestralmente. Exemplo: <br/><br/>O: Oferecer uma experiência excelente aos clientes. <br/><br/>KR1: Manter o NPS acima de 75% por um ano; <br/><br/>KR2: Aumentar a Taxa de Recompra de 35% para 50% até 31/06.",
  title_what_process_map: "O que é Mapa de Processo?",
  what_process_map:
    "É uma técnica que visa representar graficamente de maneira clara e simples as entradas, saídas e ações de uma empresa, o que facilita o entendimento dos processos e a correção de erros e melhorias a serem executadas.",
  title_what_swot: "O que é Análise SWOT?",
  what_swot:
    "Também conhecida como Análise FOFA, trata-se de uma matriz que permite você refletir e anotar quais são suas Forças, Oportunidades, Fraquezas e Ameaças para que crie planos de ação e aja sobre cada uma das partes.",
  title_what_themes: "O que são Temas?",
  what_themes:
    "São processos que você deseja realizar, esse campo pode ser preenchido com um título referente ao nome ou período para separar e organizar sua ferramenta.",
  title_what_questions: "O que é Questão?",
  what_questions:
    "É o objetivo a ser atingido, normalmente uma meta audaciosa para que o time engaje e trabalhe para atingir.",
  title_what_results: "O que são Resultados?",
  what_results:
    "São as ações que devem acontecer para se atingir o objetivo propostona questão.",
  title_what_informations: "O que são Informações?",
  what_informations:
    "São objetivos dos resultados que você deseja atingir para cumprir seuobjetivo ou meta. Eles só serão configurados com maior profundidadequando você “der vida” a eles, transformando-os em Insights.",
  title_what_insight: "O que é Insight?",
  what_insight:
    "É uma informação “com vida” que será detalhado por você:- Quanto ao nível do impacto e nível de prioridade;- Poderá ser rastreado e conectado com outros Insights da ferramenta;- Possibilitará o acompanhamento por meio da descrição da ação, data inicial e data final; - Criar metas atualizando o valor de expectativa e o valor atingido, além de acompanha-las depois;- Colocar anexos e comentários relevantes para seu Insight.",
  title_what_member: "O que é um Membro?",
  what_member: "É o colaborador da sua empresa cadastrado na plataforma.",
  title_what_teams: "O que é um Time?",
  what_teams:
    "É a união de membros que poderá ser vinculado às suas Empresas para que todos do time tenham acesso às ferramentas de gestão.",
};

const en = {
  title_what_strategyplace: "What is ROOKAU?",
  what_strategyplace:
    "It is a platform that brings together various management tools that enable its users to organize all their information in one place, enabling greater efficiency and effectiveness in decision making.",
  title_what_overview: "What is Overview",
  what_overview:
    "This is the introduction summary of the platform where you get an overview of what is happening in Activities and Timeline as members update and modify the software.",
  title_what_workspace: "What is Workspace?",
  what_workspace:
    "It's your desktop! <br/><br/>This object is used to create and identify a work section, facilitating its organization and navigation within the system. <br/><br/>You can create multiple Workspaces with different tools within each. <br/><br/>Another important feature is that you can link a Workspace to your company, so linked Workspaces are shared with other members and the unlinked is your private Workspace.",
  title_what_tools: "What are tools ?",
  what_tools:
    "These are management tools used primarily to control a process. Tools can be selected from the platform and filled in as needed.",
  title_what_okr: "What is OKR?",
  what_okr:
    "OKR (Objectives and Key Results) is a management methodology where you can define Objectives (O), which are qualitative and must be ambitious but attainable. <br/><br/>After that, Key Results (KR) (usually from 2 to 5) which are quantitative and measurable. <br/><br/>New OKRs are usually set quarterly. Example: <br/><br/>O: Providing an excellent customer experience. <br/><br/>KR1: Keeping NPS above 75% for one year; <br/><br/>KR2: Increasing Repurchase Rate from 35% to 50% by 6/31.",
  title_what_process_map: "What is Map Process ?",
  what_process_map:
    "It is a technique that aims to clearly and simply graph the inputs, outputs and actions of a company, which facilitates the understanding of processes and the correction of errors and improvements to be performed.",
  title_what_swot: "What is SWOT Analysis?",
  what_swot:
    "Also known as FOFA Analysis, this is a matrix that allows you to reflect and write down your Forces, Opportunities, Weaknesses and Threats so you can create action plans and act on each party.",
  title_what_themes: "What are Themes?",
  what_themes:
    "These are processes you want to perform, this field can be filled with a title referring to the name or period to separate and organize your tool.",
  title_what_questions: "What is question?",
  what_questions:
    "It is the goal to be achieved, usually a bold goal for the team to engage and work towards.",
  title_what_results: "What is Results?",
  what_results:
    "These are the actions that must happen to reach the objective proposed in the question.",
  title_what_informations: "What are Informations?",
  what_informations:
    "They are goals of the results you want to achieve to meet your goal or goal. They will only be configured deeper when you “bring them” to life, turning them into Insights.",
  title_what_insight: "What is Insight?",
  what_insight:
    "This is “living” information that will be detailed by you: - regarding impact level and priority level - can be tracked and connected with other tool insights - will enable follow-up through action description, start date and final date; - Create goals by updating the expectation value and achieved value, and track them later - Post attachments and comments relevant to your Insight.",
  title_what_member: "What is a Member?",
  what_member: "It is the employee of your company registered in the platform.",
  title_what_teams: "What is a Team?",
  what_teams:
    "It is a union of members that can be linked as companies so that everyone on the team has access to management tools.",
};

const es = {
  title_what_strategyplace: "¿Que es ROOKAU?",
  what_strategyplace:
    "Es una plataforma que reúne varias herramientas de gestión que permiten a sus usuarios organizar toda su información en un solo lugar, lo que permite una mayor eficiencia y eficacia en la toma de decisiones.",
  title_what_overview: "¿Que es Overview?",
  what_overview:
    "Este es el resumen de la introducción de la plataforma donde obtiene una visión general de lo que sucede en Actividades y Cronología a medida que los miembros actualizan y modifican el software.",
  title_what_workspace: "¿Que es Workspace?",
  what_workspace:
    "¡Es tu escritorio!<br/><br/> Este objeto se utiliza para crear e identificar una sección de trabajo, facilitando su organización y navegación dentro del sistema.<br/><br/> Puede crear múltiples espacios de trabajo con diferentes herramientas dentro de cada uno. <br/><br/>Otra característica importante es que puede vincular un espacio de trabajo a su empresa, por lo que los espacios de trabajo vinculados se comparten con otros miembros y lo desvinculado es su espacio de trabajo privado.",
  title_what_tools: "¿Qué son las herramientas?",
  what_tools:
    "Estas son herramientas de gestión utilizadas principalmente para controlar un proceso. Las herramientas pueden seleccionarse desde la plataforma y rellenarse según sea necesario.",
  title_what_okr: "¿Que es OKR?",
  what_okr:
    "OKR (Objectives and Key Results) es una metodología de gestión en la que puede definir los Objetivos (O), que son cualitativos y deben ser ambiciosos pero alcanzables. <br/><br/>Después de eso, Resultados clave (KR) (generalmente de 2 a 5) que son cuantitativos y medibles. <br/><br/>Los nuevos OKR generalmente se establecen trimestralmente. Ejemplo: <br/><br/>O: proporcionar una excelente experiencia al cliente. <br/><br/>KR1: mantener NPS por encima del 75% durante un año; <br/><br/>KR2: Aumento de la tasa de recompra del 35% al ​​50% en 6/31.",
  title_what_process_map: "¿Que es Mapa de Proceso?",
  what_process_map:
    "Es una técnica que tiene como objetivo graficar clara y simplemente las entradas, salidas y acciones de una empresa, lo que facilita la comprensión de los procesos y la corrección de errores y mejoras a realizar.",
  title_what_swot: "¿Que es Anális SWOT?",
  what_swot:
    "También conocido como Análisis FOFA, esta es una matriz que le permite reflexionar y escribir sus Fuerzas, Oportunidades, Debilidades y Amenazas para que pueda crear planes de acción y actuar en cada parte.",
  title_what_themes: "¿Qué son los temas?",
  what_themes:
    "Estos son los procesos que desea realizar, este campo se puede completar con un título que hace referencia al nombre o período para separar y organizar su herramienta.",
  title_what_questions: "¿Que es Preguntas?",
  what_questions:
    "Es el objetivo a alcanzar, generalmente un objetivo audaz para que el equipo participe y trabaje.",
  title_what_results: "¿Qué son los Resultados?",
  what_results:
    "Estas son las acciones que deben ocurrir para alcanzar el objetivo propuesto en la pregunta.",
  title_what_informations: "¿Qué son los Informaciones?",
  what_informations:
    "Son metas de los resultados que desea lograr para alcanzar su meta o meta. Solo se configurarán más profundamente cuando los  “traigas” a la vida, convirtiéndolos en Insights.",
  title_what_insight: "¿Que es Informaciones?",
  what_insight:
    "Esta es información “viva” que usted detallará: - en relación con el nivel de impacto y el nivel de prioridad - puede rastrearse y conectarse con otras ideas de herramientas - permitirá el seguimiento a través de la descripción de la acción, fecha de inicio y fecha final; - Cree objetivos actualizando el valor esperado y el valor alcanzado, y realice un seguimiento más adelante - Publique archivos adjuntos y comentarios relevantes para su Insight.",
  title_what_member: "¿Que es un Miembro?",
  what_member: "Es el empleado de su empresa registrado en la plataforma.",
  title_what_teams: "¿Que es un Equipo?",
  what_teams:
    "Es una unión de miembros que pueden vincularse como compañías para que todos en el equipo tengan acceso a herramientas de administración.",
};

const translations = { pt, en, es };

export default translations;
