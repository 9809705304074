import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  getParticipantsWorkflowAverage,
  getMaximumScale,
  getSkillsWorkflowAverage,
  getCompetencesWorkflowAverage,
} from "../../../../../../utils/skillsAssessment/calc";
import EvaluationWorkflowTable from "./includes/EvaluationWorkflowTable";
import FormDialog from "../../../../../Common/FormDialog";
import SimplePagination from "../../../../../SimplePagination";
import WorkflowHeader from "../tabs/workflow/WorkflowHeader";
import MaterialTextField from "../../../../../Common/MaterialTextField";
import {
  checkIsAdmin,
  checkUserRoleForParticipant,
  filterParticipantsByNameOrEmail,
  getItemsByPage,
} from "../../../../../../utils/skillsAssessment/filter";
import { removedDuplicatedObjsFromArray } from "../../../../../../utils/skillsAssessment/entityAutomation";

const defaultDetailsModal = {
  open: false,
  participant: null,
  skillsAverage: [],
  competencesAverage: [],
};

const PARTICIPANTS_PER_PAGE = 15;

const PeopleOverview = ({
  toolAdministration,
  loggedUser,
  filteredInsights,
  selectAndOpenInsight,
}) => {
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [participantDetails, setParticipantDetails] =
    useState(defaultDetailsModal);
  const [participantsAverage, setParticipantsAverage] = useState([]);

  const {
    participants,
    evaluationScale,
    potentialScale,
    composition,
    generalOptions,
  } = toolAdministration || {};

  const maxScale = getMaximumScale(evaluationScale);
  const maxPotential = getMaximumScale(potentialScale);

  const { restrictViewAccess } = generalOptions || {};
  const notRestrict = checkIsAdmin() || !restrictViewAccess;

  useEffect(() => {
    const filtered = filter
      ? filterParticipantsByNameOrEmail(filter, participants)
      : participants;
    const ordered = filtered.sort((a, b) =>
      a.memberParticipant.name.localeCompare(b.memberParticipant.name),
    );

    const pageParticipants = getItemsByPage(
      ordered,
      page,
      PARTICIPANTS_PER_PAGE,
    );
    const newTotalPages =
      filtered.length > 0
        ? Math.ceil(filtered.length / PARTICIPANTS_PER_PAGE)
        : 0;
    const newParticipantsAverage = removedDuplicatedObjsFromArray(
      getParticipantsWorkflowAverage(toolAdministration, pageParticipants)
        .participantsAverage,
      "participantId",
    );

    setTotalPages(newTotalPages);
    setParticipantsAverage(newParticipantsAverage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter, participants]);

  function handleOpenParticipantInsight(selectedItem) {
    const { participant } = selectedItem;

    if (participant?.id) {
      const insight = filteredInsights.find(
        (insight) => insight.skillsAssessmentParticipantId === participant.id,
      );

      selectAndOpenInsight(insight.id);
    }
  }

  function updateFilter(newValue) {
    if (page !== 1) setPage(1);
    setFilter(newValue);
  }

  function resetParticipantDetails() {
    setParticipantDetails(defaultDetailsModal);
  }

  function openParticipantDetails(selectedParticipant, hideSelfEvaluation) {
    if (selectedParticipant?.id) {
      const filtered = {
        ...toolAdministration,
        participants: [selectedParticipant],
      };
      const { skillsAverage } = getSkillsWorkflowAverage(filtered);
      const { competencesAverage } = getCompetencesWorkflowAverage(filtered);

      setParticipantDetails({
        open: true,
        participant: selectedParticipant,
        skillsAverage,
        competencesAverage,
        hideSelfEvaluation,
      });
    }
  }

  function filterParticipantsFields() {
    return participantsAverage.map((info) => {
      const dontHide =
        notRestrict ||
        checkUserRoleForParticipant(loggedUser, info.participant) ===
          "PARTICIPANT";

      return {
        ...info,
        hideSelfEvaluation: dontHide === false,
      };
    });
  }

  function getPagination(menuPlacement) {
    return (
      <SimplePagination
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        menuPlacement={menuPlacement}
      />
    );
  }

  return (
    <div>
      <div className="row">
        <div
          className="col-xs-4"
          style={{ padding: "0px", backgroundColor: "#fff" }}
        >
          <MaterialTextField
            label="Pesquisar Participante"
            value={filter}
            onChange={(e) => updateFilter(e.target.value)}
          />
        </div>
      </div>
      {participantDetails.open && (
        <FormDialog
          open
          title="Detalhes do Participante"
          hideCancel
          confirmText="Voltar"
          onConfirm={resetParticipantDetails}
          onCancel={resetParticipantDetails}
          dialogClassName="participant-details-modal"
        >
          <WorkflowHeader
            participant={participantDetails.participant}
            noAdminPrivileges
          />
          <EvaluationWorkflowTable
            listTitle="ÁREA DE COMPETÊNCIA"
            workflowAverage={participantDetails.competencesAverage}
            maxScale={maxScale}
            maxPotential={maxPotential}
            composition={composition}
            colSize="100"
            gapColSize="100"
            generalOptions={generalOptions}
            hideAllSelfEvaluation={participantDetails.hideSelfEvaluation}
          />
          <EvaluationWorkflowTable
            listTitle="COMPETÊNCIA"
            workflowAverage={participantDetails.skillsAverage}
            maxScale={maxScale}
            maxPotential={maxPotential}
            composition={composition}
            colSize="100"
            gapColSize="100"
            generalOptions={generalOptions}
            hideAllSelfEvaluation={participantDetails.hideSelfEvaluation}
          />
        </FormDialog>
      )}
      {getPagination()}
      <EvaluationWorkflowTable
        listTitle="PROFISSIONAIS"
        workflowAverage={filterParticipantsFields()}
        maxScale={maxScale}
        maxPotential={maxPotential}
        composition={composition}
        onClickItem={({ participant, hideSelfEvaluation }) =>
          openParticipantDetails(participant, hideSelfEvaluation)
        }
        cursor="pointer"
        generalOptions={generalOptions}
        enableInsight
        onOpenInsight={handleOpenParticipantInsight}
      />
      {getPagination("top")}
    </div>
  );
};

PeopleOverview.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
};

export default PeopleOverview;
