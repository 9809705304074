export const PESTAL = "pestal";
export const SWOT = "swot";
export const PRIORITIZATION = "prioritization";
export const CONCURRENCE = "concurrence";

export const getCpAnalysisToolSlugs = () => {
  return [PESTAL, SWOT, PRIORITIZATION, CONCURRENCE];
};

export const defaultCompanyDetails = {
  name: "",
  site: "",
  foundationYear: "",
  employees: "",
  address: "",
  specializations: [],
};
