import axios from "axios";
import { defineMessages } from "react-intl";
import {
  getGoalValidationStatusOptions,
  getRelationshipTypeOptions,
} from "../constants/goalsAndBonus";
import { getCustomUrlLink, getDomainLink } from "../utils/accessLevels";
import { getAllExcept, getObjectInfoById } from "../utils/ArrayUtils";
import gbUtils from "../utils/goalsBonus";
import { buildRestAdmConfigBody } from "../utils/goalsBonus/entity";
import { getAlreadyRegisteredGoals } from "../utils/goalsBonus/filter";
import { translatedText } from "../utils/translationUtils";
import { checkGbSwitchedAccesses } from "./accessControllerActions";
import { appRefreshSelectedTool } from "./appActions";
import { putScenery } from "./cenaryActions";
import { postGenericEmail } from "./emailActions";
import { workflowUpdateTemplate } from "./emails/workflowUpdateTemplate";
import { postInsight, updateInsightResponsible } from "./insightActions";
import { addInsightTimeline } from "./insightTimelineActions";
import { putQuestion } from "./questionsActions";
import { putTheme } from "./themesActions";
import {
  DELETE_ADMINISTRATIVE_ROLE,
  DELETE_GOAL,
  DELETE_ROLE_BONUS,
  DELETE_TEAM,
  DELETE_TRIGGER,
  UPDATE_ALL_GOALS_BONUS_CONFIGURATIONS,
  UPDATE_CENARY,
  UPDATE_CENARY_THEME,
  UPDATE_GOALS_BONUS_CONFIGURATION,
  UPDATE_QUESTION_ANSWER,
  UPDATE_THEME_QUESTION,
} from "./types";

const {
  buildGoalsAdministrationFinalBody,
  buildGoalsAdministrationDefaultSave,
  inputAdminUserOnGoalsAdministration,
  getStructuredGoalsBonusRelationships,
} = gbUtils;

const messages = defineMessages({
  gb_email_workflow_update_title: { id: "gb_email_workflow_update_title" },
  gb_email_sended_goals_description: {
    id: "gb_email_sended_goals_description",
  },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

let defaultInitialRestSave = buildGoalsAdministrationDefaultSave();

export const getGoalBonusConfiguration = async (selectedToolId) => {
  return axios
    .get(`/api/selected-tools/${selectedToolId}/goalsBonusProgram`)
    .then((res) => res);
};

export const getGoalBonusConfigurations = (selectedToolIds = []) => {
  return axios
    .get(`/api/selected-tools/goalsBonusPrograms/${selectedToolIds}`)
    .then((res) => res.data);
};

export const postGoalBonusConfiguration = (selectedToolId, body) => {
  return axios
    .post(`/api/selected-tools/${selectedToolId}/goalsBonusProgram`, body)
    .then((res) => res);
};

export const putGoalBonusConfiguration = (body) => {
  return axios.put("/api/goals-bonus-programs", body).then((res) => res);
};

export const getGoalsBonusRelationships = (selectedToolId) => {
  return axios
    .get(`/api/selected-tools/${selectedToolId}/goalsBonusRelationships`)
    .then((res) => res);
};

export const postUpdateGoals = (administrationId, updatedGoals) => {
  return axios.post(
    `/api/goals-bonus-programs/${administrationId}/updateGoals`,
    updatedGoals
  );
};

export const getStructuredGoalsRelationships = async (selectedToolsFilter) => {
  const finalRelationships = {
    cenaries: [],
    themes: [],
    questions: [],
    insights: [],
  };

  await Promise.all(
    selectedToolsFilter.map(async (selectedToolId) => {
      const relationships = await getGoalsBonusRelationships(selectedToolId);
      const relationshipsData = relationships?.data;

      if (relationshipsData) {
        Object.keys(relationshipsData).forEach((keyRelation) => {
          const keyRelationships = relationshipsData[keyRelation];

          keyRelationships.forEach((relationItem) => {
            finalRelationships[keyRelation].push(relationItem);
          });
        });
      }
    })
  );

  return getStructuredGoalsBonusRelationships(finalRelationships);
};

export const fetchToolsGoalBonusConfigurations = (selectedToolIds = []) => {
  return async (dispatch) => {
    const goalBonusConfigurations = await getGoalBonusConfigurations(
      selectedToolIds
    );

    const finalGBConfigurations = [];

    await Promise.all(
      Object.keys(goalBonusConfigurations).map(async (selectedToolId) => {
        const updatedConfiguration = await buildGoalsAdministrationFinalBody(
          goalBonusConfigurations[selectedToolId]
        );

        const finalBody = {
          ...updatedConfiguration,
          selectedToolId: Number(selectedToolId),
        };

        finalGBConfigurations.push(finalBody);
      })
    );

    dispatch({
      type: UPDATE_ALL_GOALS_BONUS_CONFIGURATIONS,
      payload: finalGBConfigurations,
      loading: false,
    });
  };
};

export const postLinkCompanyPositionToRoleBonus = (
  companyPositionId,
  roleBonusId
) => {
  return axios.post(
    `/api/goals-bonus-programs/role-bonus/${roleBonusId}/company-position/${companyPositionId}`
  );
};

export const updateRoleBonusesCompanyPositionsLinks = async (
  originalRoles = [],
  beforeSaveUpdatedRoles = [],
  afterUpdatedRoles = []
) => {
  const finalUpdatedRoleBonuses = [];

  async function updateLink(
    companyPositionId,
    beforeSaveRoleInfo = {},
    afterUpdatedRoleInfo = {}
  ) {
    const originalRoleInfo = beforeSaveRoleInfo.id
      ? getObjectInfoById(beforeSaveRoleInfo.id, originalRoles, "id")
      : beforeSaveRoleInfo;
    const isDiffLink =
      originalRoleInfo.companyPositionId !==
      beforeSaveRoleInfo.companyPositionId;

    if (
      !originalRoleInfo.id ||
      !beforeSaveRoleInfo.id ||
      (originalRoleInfo.id &&
        (!originalRoleInfo.companyPositionId || isDiffLink))
    ) {
      const linkedRoleInfo = await postLinkCompanyPositionToRoleBonus(
        companyPositionId,
        afterUpdatedRoleInfo.id
      );

      if (linkedRoleInfo.status === 204 || linkedRoleInfo.status === 200)
        return { ...afterUpdatedRoleInfo, companyPositionId };
    }

    return { ...afterUpdatedRoleInfo, companyPositionId };
  }

  if (beforeSaveUpdatedRoles.length > 0) {
    beforeSaveUpdatedRoles.forEach((beforeSaveRoleInfo) => {
      const afterUpdatedRoleInfo = getObjectInfoById(
        beforeSaveRoleInfo.role,
        afterUpdatedRoles,
        "role"
      );

      const { companyPositionId = null } = beforeSaveRoleInfo;
      if (companyPositionId)
        updateLink(companyPositionId, beforeSaveRoleInfo, afterUpdatedRoleInfo);

      finalUpdatedRoleBonuses.push({
        ...afterUpdatedRoleInfo,
        companyPositionId,
      });
    });
  }

  return finalUpdatedRoleBonuses;
};

export const putOnlyAdministration = async (
  selectedToolId,
  newAdministration
) => {
  if (selectedToolId && newAdministration?.id) {
    const request = await getGoalBonusConfiguration(selectedToolId);
    const requestData = request.data;

    await putGoalBonusConfiguration(newAdministration);

    const updatedConfig = await getGoalBonusConfiguration(selectedToolId);
    const updatedConfigData = updatedConfig.data;

    const finalRequestData = {
      ...updatedConfigData,
      roleBonuses: await updateRoleBonusesCompanyPositionsLinks(
        requestData.roleBonuses,
        newAdministration.roleBonuses,
        updatedConfigData.roleBonuses
      ),
    };

    const updatedConfiguration = await buildGoalsAdministrationFinalBody(
      finalRequestData
    );

    return updatedConfiguration;
  }

  return {};
};

export const fetchToolGoalBonusConfiguration = async (
  selectedToolId,
  saveDefault = false,
  loggedUserId
) => {
  return async (dispatch) => {
    await dispatch({ type: UPDATE_GOALS_BONUS_CONFIGURATION, loading: true });

    async function postNewDefaultConfig() {
      if (saveDefault && selectedToolId && loggedUserId) {
        defaultInitialRestSave = inputAdminUserOnGoalsAdministration(
          defaultInitialRestSave,
          loggedUserId
        );

        await dispatch(
          updateGoalBonusAdministration(
            selectedToolId,
            defaultInitialRestSave,
            true
          )
        );
      }
    }

    try {
      const goalBonusConfiguration = await getGoalBonusConfiguration(
        selectedToolId
      );

      if (goalBonusConfiguration.data && goalBonusConfiguration.data.id) {
        const fetchedConfiguration = await buildGoalsAdministrationFinalBody(
          goalBonusConfiguration.data
        );
        await dispatch({
          type: UPDATE_GOALS_BONUS_CONFIGURATION,
          payload: { ...fetchedConfiguration, selectedToolId },
          loading: false,
        });
      } else {
        await postNewDefaultConfig();
      }
    } catch (err) {
      console.log("Request error fetchToolGoalBonusConfiguration", err);
    }
  };
};

export const updateGoalBonusAdministration = (
  selectedToolId,
  body,
  onlyPost = false
) => {
  return async (dispatch) => {
    const saveBody = await buildGoalsAdministrationFinalBody(body);

    const sendDispatch = (type, payload, loading = false, error) => {
      dispatch({ type, payload, loading, error });
    };

    sendDispatch(UPDATE_GOALS_BONUS_CONFIGURATION, null, true);

    async function saveNewConfiguration() {
      try {
        const newGoalConfig = await postGoalBonusConfiguration(
          selectedToolId,
          saveBody
        );
        const updatedConfiguration = await buildGoalsAdministrationFinalBody(
          newGoalConfig.data
        );
        await sendDispatch(UPDATE_GOALS_BONUS_CONFIGURATION, {
          ...updatedConfiguration,
          selectedToolId,
        });
      } catch (err) {
        await sendDispatch(UPDATE_GOALS_BONUS_CONFIGURATION, null, false, err);
      }
    }

    const updateAdministration = async () => {
      try {
        if (saveBody && saveBody.id && !onlyPost) {
          const updatedConfiguration = await putOnlyAdministration(
            selectedToolId,
            saveBody
          );

          try {
            await sendDispatch(UPDATE_GOALS_BONUS_CONFIGURATION, {
              ...updatedConfiguration,
              selectedToolId,
            });
          } catch (err) {
            await sendDispatch(
              UPDATE_GOALS_BONUS_CONFIGURATION,
              null,
              false,
              err
            );
          }
        } else {
          const goalBonusConfiguration = await getGoalBonusConfiguration(
            selectedToolId
          );

          if (
            !goalBonusConfiguration ||
            !goalBonusConfiguration.data ||
            !goalBonusConfiguration.data.id
          ) {
            await saveNewConfiguration();
          }
        }
      } catch (err) {
        await sendDispatch(UPDATE_GOALS_BONUS_CONFIGURATION, null, false, err);
      }
    };

    await updateAdministration();
    await dispatch(await fetchToolGoalBonusConfiguration(selectedToolId));
    dispatch(appRefreshSelectedTool());
    await dispatch(await checkGbSwitchedAccesses());
  };
};

export const dispatchInsight = (questionId, newInsight) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_QUESTION_ANSWER,
      payload: {
        questionId,
        answer: { ...newInsight, isNew: true },
      },
    });
  };
};

export const createGoalInsight = (
  goalTitle,
  questionId,
  order,
  responsibleMember
) => {
  const append =
    responsibleMember && responsibleMember.id ? { responsibleMember } : {};

  return postInsight(
    {
      id: null,
      text: goalTitle,
      name: goalTitle,
      type: "insight",
      order,
      ...append,
    },
    questionId
  );
};

export const postGoalsBonusGoal = (insightId, goalsBonusGoalId) => {
  return axios
    .post(
      `/api/goals-bonus-programs/insight/${insightId}/goals-bonus-goal/${goalsBonusGoalId}`
    )
    .then((res) => res);
};

export const createGoalsBonusInsightGoal = (
  goalInfo = {},
  selectedToolId,
  questionId,
  order,
  memberParticipant = {}
) => {
  return async (dispatch) => {
    if (questionId && goalInfo.id) {
      try {
        const newInsight = await createGoalInsight(
          goalInfo.title,
          questionId,
          order,
          memberParticipant
        );
        const newInsightData = newInsight.data;

        if (memberParticipant && memberParticipant.id)
          dispatch(
            updateInsightResponsible(newInsightData, memberParticipant.id)
          );

        if (newInsightData.id) {
          const postedLink = await postGoalsBonusGoal(
            newInsightData.id,
            goalInfo.id
          );

          if (postedLink.status === 204 || postedLink.status === 200) {
            const updatedInsightData = {
              ...newInsightData,
              goalsBonusProgramGoalId: goalInfo.id,
            };

            dispatch(dispatchInsight(questionId, updatedInsightData));
          }

          if (selectedToolId)
            dispatch(fetchToolGoalBonusConfiguration(selectedToolId));
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };
};

export const addGoalToQuestions = (
  selectedToolId,
  updatedGoal,
  questions,
  participants
) => {
  return (dispatch) => {
    if (
      selectedToolId &&
      updatedGoal &&
      updatedGoal.id &&
      Array.isArray(questions)
    ) {
      const haveRelatedInsights = updatedGoal.relatedInsights.length > 0;
      const individualGoal = updatedGoal.relationshipType === "INDIVIDUAL";

      if (!haveRelatedInsights || (haveRelatedInsights && !individualGoal)) {
        questions.forEach((questionInfo, index) => {
          if (!individualGoal || (individualGoal && index === 0)) {
            const { goalsBonusProgramParticipantId } = questionInfo;
            const participantInfo = getObjectInfoById(
              goalsBonusProgramParticipantId,
              participants,
              "id"
            );
            const { memberParticipant } = participantInfo;

            dispatch(
              createGoalsBonusInsightGoal(
                updatedGoal,
                selectedToolId,
                questionInfo.id,
                null,
                memberParticipant
              )
            );
          }
        });
      }
    }
  };
};

export const saveOrUpdateGoal = (
  selectedToolId,
  newAdministration,
  newGoal,
  addToQuestions = []
) => {
  return async (dispatch) => {
    const restSaveAdministration = buildRestAdmConfigBody(newAdministration);
    const saveAdministration = await buildGoalsAdministrationFinalBody(
      restSaveAdministration
    );

    dispatch({ type: UPDATE_GOALS_BONUS_CONFIGURATION, loading: true });

    if (selectedToolId && saveAdministration.id && newGoal) {
      let updatedGoalToBoard = null;

      const updatedAdministration = await putOnlyAdministration(
        selectedToolId,
        saveAdministration
      );

      dispatch(fetchToolGoalBonusConfiguration(selectedToolId));

      if (newGoal.id) {
        updatedGoalToBoard = getObjectInfoById(
          newGoal.id,
          updatedAdministration.goals,
          "id"
        );
      } else {
        const registeredInsights = getAlreadyRegisteredGoals(
          newAdministration.goals
        );
        const onlyNewGoals = getAllExcept(
          registeredInsights,
          updatedAdministration.goals,
          "id"
        );

        if (onlyNewGoals && onlyNewGoals[0])
          updatedGoalToBoard = onlyNewGoals[0];
      }

      if (updatedGoalToBoard?.id)
        dispatch(
          addGoalToQuestions(
            selectedToolId,
            updatedGoalToBoard,
            addToQuestions,
            updatedAdministration.participants
          )
        );
    }
  };
};

export const postGoalsBonusTeam = (sceneryId, teamId) => {
  return axios
    .post(`/api/goals-bonus-programs/cenary/${sceneryId}/team/${teamId}`)
    .then((res) => res);
};

export const createTeamCenary = (
  selectedTool,
  teamName,
  owner = {},
  order = 0
) => {
  return (dispatch) =>
    dispatch(
      putScenery(
        {
          id: null,
          name: teamName,
          fixed: false,
          selectedTool,
          editing: true,
          type: "cenary",
          owner,
          order,
        },
        selectedTool.id
      )
    );
};

export const dispatchCenary = (newScenery) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CENARY,
      payload: { ...newScenery, isNew: true },
    });
  };
};

export const createGoalsBonusScenerieTeam = (
  selectedTool = {},
  teamInfo = {},
  userProfile,
  order = 0
) => {
  return async (dispatch) => {
    if (selectedTool.id && teamInfo.id) {
      try {
        const newScenery = await dispatch(
          createTeamCenary(selectedTool, teamInfo.name, userProfile, order)
        );
        const newScenaryData = newScenery.data;

        if (newScenaryData.id) {
          const postedLink = await postGoalsBonusTeam(
            newScenaryData.id,
            teamInfo.id
          );

          if (postedLink.status === 204 || postedLink.status === 200) {
            const updatedScenaryData = {
              ...newScenaryData,
              goalsBonusProgramTeamId: teamInfo.id,
            };

            dispatchCenary(updatedScenaryData);
          }
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };
};

export const postGoalsBonusRoleBonus = (themeId, roleBonusId) => {
  return axios
    .post(
      `/api/goals-bonus-programs/theme/${themeId}/role-bonus/${roleBonusId}`
    )
    .then((res) => res);
};

export const createRoleBonusTheme = (cenaryBody, roleBonusTitle, order) => {
  return putTheme({
    id: null,
    type: "theme",
    cenary: cenaryBody,
    name: roleBonusTitle,
    order,
  });
};

export const dispatchTheme = (cenaryId, newTheme) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CENARY_THEME,
      payload: {
        cenaryId,
        theme: {
          ...newTheme,
          isNew: true,
        },
      },
    });
  };
};

export const createGoalsBonusThemeRoleBonus = (
  roleBonusInfo = {},
  cenaryBody = {},
  order = 0
) => {
  return async (dispatch) => {
    if (cenaryBody.id && roleBonusInfo.id) {
      try {
        const newTheme = await createRoleBonusTheme(
          cenaryBody,
          roleBonusInfo.role,
          order
        );
        const newThemeData = newTheme.data;

        if (newThemeData.id) {
          const postedLink = await postGoalsBonusRoleBonus(
            newThemeData.id,
            roleBonusInfo.id
          );

          if (postedLink.status === 204 || postedLink.status === 200) {
            const updatedThemeData = {
              ...newThemeData,
              goalsBonusProgramRoleBonusId: roleBonusInfo.id,
            };
            dispatch(dispatchTheme(cenaryBody.id, updatedThemeData));
          }
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };
};

export const postGoalsBonusParticipant = (
  questionId,
  goalsBonusParticipantId
) => {
  return axios
    .post(
      `/api/goals-bonus-programs/question/${questionId}/goals-bonus-participant/${goalsBonusParticipantId}`
    )
    .then((res) => res);
};

export const createParticipantQuestion = (themeBody, memberName, order) => {
  return putQuestion({
    id: null,
    type: "question",
    theme: themeBody,
    text: memberName,
    order,
  });
};

export const dispatchQuestion = (themeId, newQuestion) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_THEME_QUESTION,
      payload: {
        themeId,
        question: {
          ...newQuestion,
          isNew: true,
        },
      },
    });
  };
};

export const createGoalsBonusQuestionParticipant = (
  participantInfo = {},
  themeBody = {},
  order = 0
) => {
  return async (dispatch) => {
    if (themeBody.id && participantInfo.id) {
      try {
        const newQuestion = await createParticipantQuestion(
          themeBody,
          participantInfo.memberParticipant.name,
          order
        );
        const newQuestionData = newQuestion.data;

        if (newQuestionData.id) {
          const postedLink = await postGoalsBonusParticipant(
            newQuestionData.id,
            participantInfo.id
          );

          if (postedLink.status === 204 || postedLink.status === 200) {
            const updatedQuestionData = {
              ...newQuestionData,
              goalsBonusProgramParticipantId: participantInfo.id,
            };

            dispatch(dispatchQuestion(themeBody.id, updatedQuestionData));
          }
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };
};

export const sendWorkflowEmail = (
  emails,
  goalsTitles = [],
  newStatus = "APPROVAL",
  userName = "",
  comment = "",
  breadcrumb = null,
  { customUrlLink = "", domainUrl = "" }
) => {
  if (emails && emails.length > 0) {
    const workflowStatusOptions = getGoalValidationStatusOptions();

    const selectedStatus =
      getObjectInfoById(newStatus, workflowStatusOptions, "value") || {};

    const htmlBody = workflowUpdateTemplate(
      translation("gb_email_workflow_update_title"),
      breadcrumb,
      translation("gb_email_sended_goals_description", {
        name: userName,
      }),
      userName,
      comment,
      goalsTitles,
      selectedStatus.label,
      selectedStatus.color,
      { customUrlLink, domainUrl }
    );

    postGenericEmail(
      translation("gb_email_workflow_update_title"),
      htmlBody,
      emails
    );
  }
};

export const addWorkflowComment = (
  goalsList = [],
  comment = "",
  userId = null,
  newStatus = "APPROVED",
  emailsToSend = {
    approvers: [],
    participants: [],
  },
  userName = "",
  breadcrumb = null
) => {
  return async (dispatch) => {
    if (userId && comment && goalsList.length > 0) {
      const goalsTitleList = [];

      const relationshipTypeOptions = getRelationshipTypeOptions();

      goalsList.forEach((goalInfo) => {
        const { relatedInsights } = goalInfo;

        const relationshipTranslation = getObjectInfoById(
          goalInfo.relationshipType,
          relationshipTypeOptions,
          "value"
        );

        goalsTitleList.push(
          `${goalInfo.title} - ${relationshipTranslation.label}`
        );

        dispatch(
          addInsightTimeline(relatedInsights[0], comment, userId, [
            `WK_${newStatus}`,
          ])
        );
      });

      const customUrlLink = await getCustomUrlLink("SELECTED_TOOL");
      const domainUrl = getDomainLink();

      const sendEmails = (emails) => {
        sendWorkflowEmail(
          emails,
          goalsTitleList,
          newStatus,
          userName,
          comment,
          breadcrumb,
          { customUrlLink, domainUrl }
        );
      };

      sendEmails(emailsToSend.approvers);
      sendEmails(emailsToSend.participants);
    }
  };
};

export const deleteGoal = (goalId, administrationId) => {
  return async (dispatch) => {
    await axios.delete(`/api/goals-bonus-program-goals/${goalId}`);

    dispatch({ type: DELETE_GOAL, payload: { goalId, administrationId } });
    await dispatch(appRefreshSelectedTool(false));
  };
};

export const deleteTrigger = (triggerId, administrationId) => {
  return async (dispatch) => {
    await axios.delete(`/api/goals-bonus-program-triggers/${triggerId}`);

    dispatch({
      type: DELETE_TRIGGER,
      payload: { triggerId, administrationId },
    });
    await dispatch(appRefreshSelectedTool(false));
  };
};

export const deleteAdministrativeRole = (
  administrativeRoleId,
  administrationId
) => {
  return async (dispatch) => {
    await axios.delete(`/api/administrative-roles/${administrativeRoleId}`);

    await dispatch({
      type: DELETE_ADMINISTRATIVE_ROLE,
      payload: { administrativeRoleId, administrationId },
    });

    await dispatch(checkGbSwitchedAccesses());
    await dispatch(appRefreshSelectedTool(false));
  };
};

export const deleteRoleBonus = (roleBonusId, administrationId) => {
  return async (dispatch) => {
    await axios.delete(`/api/role-bonuses/${roleBonusId}`);

    dispatch({
      type: DELETE_ROLE_BONUS,
      payload: { roleBonusId, administrationId },
    });
    await dispatch(appRefreshSelectedTool(false));
  };
};

export const deleteParticipant = (participantId, selectedToolId) => {
  return async (dispatch) => {
    await axios.delete(
      `/api/goals-bonus-program-participants/${participantId}`
    );

    await dispatch(await fetchToolGoalBonusConfiguration(selectedToolId));
    await dispatch(checkGbSwitchedAccesses());
    await dispatch(appRefreshSelectedTool(false));
  };
};

export const deleteProgramTeam = (teamId, administrationId) => {
  return async (dispatch) => {
    await axios.delete(
      `/api/goals-bonus-programs/${administrationId}/teams/${teamId}`
    );

    dispatch({
      type: DELETE_TEAM,
      payload: { teamId, administrationId },
    });
    await dispatch(appRefreshSelectedTool(false));
  };
};

export const goalsMassUpdate = ({
  administrationId = 0,
  goals = [],
  selectedToolId,
}) => {
  return async (dispatch) => {
    if (administrationId && selectedToolId) {
      await axios.post(
        `/api/goals-bonus-programs/${administrationId}/updateGoals`,
        goals
      );

      dispatch(fetchToolGoalBonusConfiguration(selectedToolId));
    }
  };
};

export const linkGoalToIndicator = (selectedToolId, goalId, indicatorId) => {
  return (dispatch) => {
    dispatch(fetchToolGoalBonusConfiguration(selectedToolId));
  };
};
