import axios from "axios";
import { FETCH_NOTIFICATIONS } from "../types";
import { handleUserId } from "../accessControllerActions";

export const fetchUserNotifications = (userId) => {
  return (dispatch) => {
    return axios.get(`/api/notifications/user/${userId}`).then(({ data }) => {
      const filtered = data?.filter((line) => line.companyId !== null);

      dispatch({ type: FETCH_NOTIFICATIONS, payload: filtered });
    });
  };
};

export const createNotification = (body = {}) => {
  if (body && body.length > 0 && body[0] === undefined) return;

  return axios.post("/api/notifications/add", body).then((res) => res);
};

export const updateNotification = (body = {}) => {
  const currentUserId = handleUserId();

  return (dispatch) => {
    axios
      .put("/api/notifications", body)
      .then((res) => dispatch(fetchUserNotifications(currentUserId)));
  };
};

export const deleteNotification = (id) => {
  axios.delete(`/api/notifications/${id}`).then((res) => res);
};
