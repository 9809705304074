import {
  UPDATE_USER_PROFILE,
  FETCH_USER_PROFILE,
  CLOSE_ACCOUNT,
  UPLOAD_USER_AVATAR,
} from "../actions/types";

const INITIAL_STATE = {};

const profileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USER_PROFILE:
      return action.payload;
    case UPDATE_USER_PROFILE:
      return action.payload;
    case CLOSE_ACCOUNT:
      return action.payload;
    case UPLOAD_USER_AVATAR:
      return {
        ...state,
        avatarBlobId: action.payload,
      };
    default:
      return state;
  }
};

export default profileReducer;
