import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { OverlayTrigger } from "react-bootstrap";
import {
  getPeriodFullInfo,
  getPeriodPopover,
} from "../../../../utils/kpis/display";

const DotPeriod = styled.div`
  background: ${(props) => (props.background ? props.background : "")};

  @media print {
    background-color: ${(props) =>
      props.background ? props.background : ""} !important;
    -webkit-print-color-adjust: exact;
  }
`;

const PeriodsEvaluationColumns = ({
  goalInfo,
  actualTargetValues,
  totalPeriodsMultiplier = 1,
  goalTargets,
  zeroWhenNegative,
}) => {
  const periodPerformanceCol = (actualPeriodValue, index, fakeIndex) => {
    const { isTraveledAndValid, hitTargetInfo = {} } = getPeriodFullInfo(
      index,
      actualPeriodValue,
      goalInfo,
    );
    const { targetInfo = {}, color = "" } = hitTargetInfo;

    const periodPopover = getPeriodPopover(
      goalInfo,
      targetInfo,
      { index, value: actualPeriodValue, fakeIndex },
      goalTargets,
      isTraveledAndValid,
      zeroWhenNegative,
    );

    return (
      <OverlayTrigger placement="left" overlay={periodPopover}>
        <td
          align="center"
          style={{ padding: "3px" }}
          colSpan={totalPeriodsMultiplier}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <DotPeriod
              background={isTraveledAndValid ? color : "#ddd"}
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
              }}
            />
          </div>
        </td>
      </OverlayTrigger>
    );
  };

  return (
    <>
      {actualTargetValues.map(
        ({ value: actualPeriodValue, index }, fakeIndex) => {
          return (
            <React.Fragment key={index}>
              {periodPerformanceCol(actualPeriodValue, index, fakeIndex)}
            </React.Fragment>
          );
        },
      )}
    </>
  );
};

function areEqual(prev, next) {
  if (!_.isEqual(prev.goalInfo, next.goalInfo)) return false;
  if (!_.isEqual(prev.actualTargetValues, next.actualTargetValues))
    return false;
  return true;
}

export default React.memo(PeriodsEvaluationColumns, areEqual);
