function getFilteredObjectByFilterIds(object, filter) {
  return filter.reduce((accum, currentId) => {
    const findItem = object[currentId];

    if (!findItem) return accum;

    return Object.assign(accum, { [currentId]: findItem });
  }, {});
}

function getFilteredArrayOfObjectByFilterIds(object, filter) {
  return Object.keys(object).reduce((accum, currentId) => {
    const currentArray = object[currentId];

    const filteredCurrentArray = currentArray.filter((item) =>
      filter.includes(item.id),
    );

    return Object.assign(accum, { [currentId]: filteredCurrentArray });
  }, {});
}

export function filteredSceneriesByScenaryIds(scenaries, scenaryIds) {
  const filteredScenaries = scenaries.filter((scenerie) =>
    scenaryIds.includes(scenerie.id),
  );

  return filteredScenaries;
}

export function getFilteredThemesByFilters(
  themes,
  filterScenaryIds,
  filterThemeIds,
) {
  const themesByFilterSceneries = getFilteredObjectByFilterIds(
    themes,
    filterScenaryIds,
  );

  const themesByFilter = getFilteredArrayOfObjectByFilterIds(
    themesByFilterSceneries,
    filterThemeIds,
  );

  return themesByFilter;
}

export function getFilteredQuestionsByFilters(
  questions,
  filterThemeIds,
  filterQuestionIds,
) {
  const filteredQuestionsByThemeIds = getFilteredObjectByFilterIds(
    questions,
    filterThemeIds,
  );

  const filteredQuestionsByFilter = getFilteredArrayOfObjectByFilterIds(
    filteredQuestionsByThemeIds,
    filterQuestionIds,
  );

  return filteredQuestionsByFilter;
}
