import jwtDecode from "jwt-decode";
import { apiJava, apiNode } from "../api";
import { getNamespaceConfig } from "../utils/accessLevels";
import setAuthorizationToken from "../utils/setAuthorizationToken";
import { resetSelectedEntityStorage } from "./companyActions";
import {
  SET_AUTH_INFO,
  SET_CURRENT_DOMAIN,
  SET_CURRENT_USER,
  SET_DOMAIN_LOGO,
  USER_LOGOUT,
} from "./types";
import {
  fetchUser,
  getUserByCpf,
  getUserByEmail,
  setCurrentUser as setUser,
} from "./userActions";

export const setCurrentUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_USER,
      payload: user,
    });
  };
};

export const logout = () => {
  return (dispatch) => {
    const CONFIRMED_NEW_AUTH_KEY = "confirmedNewAuth";
    const confirmedNewAuth = localStorage.getItem(CONFIRMED_NEW_AUTH_KEY);
    localStorage.clear();
    localStorage.setItem(CONFIRMED_NEW_AUTH_KEY, confirmedNewAuth);
    setAuthorizationToken(false);
    dispatch({ type: USER_LOGOUT });
    dispatch(setUser({}));
    dispatch(setCurrentUser({}));
    dispatch(resetSelectedEntityStorage());
  };
};

export async function registerUser(user, domainId) {
  const response = await apiNode.post("api/v2/user/create", {
    user,
    domainId,
  });

  return response.data;
}

export async function importUserIntoDomain(user, domainId) {
  await apiNode.post("api/v2/user/import", {
    user: { ...user, type: "MEMBER" },
    domainId,
  });
}

export async function checkUserAlreadyRegistered({ email, domainId }) {
  const response = await apiNode.post("api/v2/user/import", {
    email,
    domainId,
  });

  return response.data;
}

export const login = (data) => {
  return (dispatch) => {
    return apiJava.post("/api/authenticate", data).then((res) => {
      const token = res.data.id_token;
      localStorage.setItem("jwtToken", token);
      setAuthorizationToken(token);
      const currentUser = jwtDecode(token);
      dispatch(setCurrentUser(currentUser));
      dispatch(fetchUser(currentUser.sub));
    });
  };
};

export const postDomainAuthInfo = (domainId = "", domainAuthInfo = {}) => {
  return apiJava
    .post(`/api/domains/${domainId}/auth`, domainAuthInfo)
    .then((res) => res.data);
};

export const sendDomainAuthInfo = (domainId = "", domainAuthInfo = {}) => {
  return async (dispatch) => {
    dispatch({
      type: SET_CURRENT_DOMAIN,
      payload: await postDomainAuthInfo(domainId, domainAuthInfo),
    });
  };
};

export const setAuthInfo = (payload, next) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUTH_INFO,
      payload,
    });

    if (payload?.email && payload?.isAuthenticated) {
      try {
        const { data: user } = await getUserByEmail(payload.email);
        if (user?.id) dispatch(setCurrentUser(user));
      } catch (err) {
        return err.response;
      }
    }

    if (payload?.cpf && payload?.isAuthenticated) {
      try {
        const { data: user } = await getUserByCpf(payload.cpf);
        dispatch(setCurrentUser(user));
      } catch (err) {
        return err.response;
      }
    }

    if (typeof next === "function") next();
  };
};

export const getDomainLogo = (host = false) => {
  return (dispatch) => {
    const { isAppHmlorDev = false, namespace = false } = getNamespaceConfig();
    const namespace_ = host || isAppHmlorDev ? "rookau" : namespace;
    return apiJava.get(`/api/domains/logo/${namespace_}`).then((res) => {
      const logo = res.data;
      dispatch({
        type: SET_DOMAIN_LOGO,
        payload: logo,
      });
    });
  };
};

export const getAuthenticationProvider = (namespace = "") => {
  return apiJava
    .get(`/api/domains/authInfo/${namespace}`)
    .then(({ data }) => data);
};

export const getProviderInfo = async (host = false) => {
  const { isAppHmlorDev = false, namespace = false } = getNamespaceConfig();
  const name = host || isAppHmlorDev ? "rookau" : namespace;
  return getAuthenticationProvider(name);
};

export const authenticateUser = async (authenticationInfo = {}) => {
  try {
    return await apiJava
      .post("/api/authenticate", authenticationInfo)
      .then((res) => res?.data || false);
  } catch (err) {
    return err.response;
  }
};

export const authenticateWithCpfInit = async ({ cpf, password }) => {
  const response = await apiNode.post("/api/v4/auth/cpf/init", {
    cpf,
    password,
  });
  return response.data;
};

export const authenticateWithCpfFinish = async ({ cpf, code }) => {
  const response = await apiNode.post("/api/v4/auth/cpf/finish", { cpf, code });
  return response.data;
};

export const resendOtpCode = async ({ cpf }) => {
  try {
    await apiNode.post("/api/v4/auth/cpf/requestCode", { cpf });
  } catch (err) {
    return err.response;
  }
};

export const changePassword = async ({ cpf, code, password }) => {
  try {
    await apiNode.post("/api/v4/auth/cpf/changePassword", {
      cpf,
      code,
      password,
    });
  } catch (err) {
    return err.response;
  }
};

export const authenticateUserWithOTP = async (otpCode) => {
  try {
    return await apiJava
      .post(`/api/otp-authenticate/${otpCode}`)
      .then((res) => res?.data || false);
  } catch (err) {
    return err.response;
  }
};

export const setHideSidebar = (hideDomainNavigation = false) => {
  return async (dispatch) => {
    dispatch({
      type: "HIDE_DOMAIN_NAVIGATION",
      payload: { hideDomainNavigation },
    });
  };
};

export const checkIsAuthenticated = async () => {
  try {
    return await apiJava
      .get("/api/is-authenticated")
      .then((res) => res?.data || false);
  } catch (err) {
    return false;
  }
};
