import PropTypes from "prop-types";
import React, { Component } from "react";

class BreadcrumbMenu extends Component {
  render() {
    return (
      <div
        className="navbar-bread-crumbs"
        style={{
          maxWidth: "80%",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

BreadcrumbMenu.propTypes = {
  children: PropTypes.array,
};

export default BreadcrumbMenu;
