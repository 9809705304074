import axios from "axios";
import { useEffect } from "react";
import { Provider } from "react-redux";

import { set401InterceptorInAxiosInstances } from "api/interceptor";
import { apiNode } from "./api";
import IntlWraper from "./IntlWraper";
import store from "./Store";
const Root = () => {
  useEffect(() => {
    set401InterceptorInAxiosInstances([axios, apiNode]);
  }, []);

  return (
    <Provider store={store}>
      <IntlWraper />
    </Provider>
  );
};

export default Root;
