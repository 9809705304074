import { Tooltip, styled } from "@material-ui/core";
import Help from "@material-ui/icons/Help";
import { defineMessages } from "react-intl";

const ActivityTooltip = ({ intl, title }) => {
  const relevanceMessages = defineMessages({
    relevance: {
      id: "tool.process.relevanceTitle",
    },
    relevance_description: {
      id: "tool.process.relevanceDescription",
    },
    relevance_veryLow: {
      id: "tool.process.relevanceVeryLow",
    },
    relevance_low: {
      id: "tool.process.relevanceLow",
    },
    relevance_medium: {
      id: "tool.process.relevanceMedium",
    },
    relevance_high: {
      id: "tool.process.relevanceHigh",
    },
    relevance_veryHigh: {
      id: "tool.process.relevanceVeryHigh",
    },
  });

  const complexityMessages = defineMessages({
    complexity: {
      id: "tool.process.complexityTitle",
    },
    complexity_description: {
      id: "tool.process.complexityDescription",
    },
    complexity_veryLow: {
      id: "tool.process.complexityVeryLow",
    },
    complexity_low: {
      id: "tool.process.complexityLow",
    },
    complexity_medium: {
      id: "tool.process.complexityMedium",
    },
    complexity_high: {
      id: "tool.process.complexityHigh",
    },
    complexity_veryHigh: {
      id: "tool.process.complexityVeryHigh",
    },
  });

  const periodDemandGrowthMessages = defineMessages({
    periodDemandGrowth: {
      id: "tool.process.periodDemandGrowthTitle",
    },
    periodDemandGrowth_description: {
      id: "tool.process.periodDemandGrowthDescription",
    },
  });

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    "& .MuiTooltip-tooltip": {
      maxWidth: 500,
    },
  });

  const ActivityTooltips = [
    {
      title: intl.formatMessage(relevanceMessages.relevance),
      content: (
        <>
          <p>{intl.formatMessage(relevanceMessages.relevance_description)}</p>
          <ol>
            <li>{intl.formatMessage(relevanceMessages.relevance_veryLow)}</li>
            <li>{intl.formatMessage(relevanceMessages.relevance_low)}</li>
            <li>{intl.formatMessage(relevanceMessages.relevance_medium)}</li>
            <li>{intl.formatMessage(relevanceMessages.relevance_high)}</li>
            <li>{intl.formatMessage(relevanceMessages.relevance_veryHigh)}</li>
          </ol>
        </>
      ),
    },
    {
      title: intl.formatMessage(complexityMessages.complexity),
      content: (
        <>
          <p>{intl.formatMessage(complexityMessages.complexity_description)}</p>
          <ul>
            <li>{intl.formatMessage(complexityMessages.complexity_veryLow)}</li>
            <li>{intl.formatMessage(complexityMessages.complexity_low)}</li>
            <li>{intl.formatMessage(complexityMessages.complexity_medium)}</li>
            <li>{intl.formatMessage(complexityMessages.complexity_high)}</li>
            <li>
              {intl.formatMessage(complexityMessages.complexity_veryHigh)}
            </li>
          </ul>
        </>
      ),
    },
    {
      title: intl.formatMessage(periodDemandGrowthMessages.periodDemandGrowth),
      content: (
        <>
          <p>
            {intl.formatMessage(
              periodDemandGrowthMessages.periodDemandGrowth_description,
            )}
          </p>
        </>
      ),
    },
  ];

  return (
    <>
      {title === "relevance" && (
        <label
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {ActivityTooltips[0].title}
          <CustomWidthTooltip title={ActivityTooltips[0].content}>
            <Help />
          </CustomWidthTooltip>
        </label>
      )}
      {title === "complexity" && (
        <label
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {ActivityTooltips[1].title}
          <CustomWidthTooltip title={ActivityTooltips[1].content}>
            <Help />
          </CustomWidthTooltip>
        </label>
      )}
      {title === "periodDemandGrowth" && (
        <label
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {ActivityTooltips[2].title}
          <CustomWidthTooltip title={ActivityTooltips[2].content}>
            <Help />
          </CustomWidthTooltip>
        </label>
      )}
    </>
  );
};

export default ActivityTooltip;
