import React from "react";

import GoalListItem from "../../../Common/GoalListItem";
import { Display, Title, AlertStyle } from "./OkrStyle";
import {
  getFilteredAllAnswerOkrs,
  groupedInsightsEvaluations,
} from "../../../../utils/okrUtils";

const Objectives = ({ objectives, intl, getKeyResult, options }) => {
  const orderedObjectives = objectives.sort((a, b) => {
    if (a.question.text > b.question.text) {
      return 1;
    }
    if (a.question.text < b.question.text) {
      return -1;
    }

    return 0;
  });

  return (
    <Display>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title>
          {intl.formatMessage({ id: "title_objectives_deliveries" })}
        </Title>
      </div>
      {orderedObjectives.length ? (
        orderedObjectives.map((objective, index) => {
          const { REACHED, EXCEEDED, CANCELED } = getFilteredAllAnswerOkrs(
            objective.keyResults,
            options.status,
          );

          const calculatePlan =
            ((REACHED.length + EXCEEDED.length - CANCELED.length) * 100) /
            objective.keyResults.length;

          const goalsReachPercentage = groupedInsightsEvaluations(
            [objective],
            options.okrValue,
            "keyResults",
          );

          const badge = (
            <div style={{ width: "120px", textAlign: "center" }}>
              <div
                className="badge answer bg-purple"
                style={{ height: "20px" }}
              >{`${goalsReachPercentage}%`}</div>
            </div>
          );

          return (
            <GoalListItem
              key={objective.question.id}
              index={index}
              title={objective.question.text}
              description={`${objective.keyResults.length} ${intl.formatMessage(
                { id: "key_results_counter" },
              )}`}
              currentPercentage={calculatePlan}
              appendBeforePercentage={badge}
              barTotalWidth={80}
              fixed={0}
              progressColor="#7ea6c7"
              popover={getKeyResult(objective.keyResults, options)}
            />
          );
        })
      ) : (
        <AlertStyle severity="info">
          {intl.formatMessage({ id: "info_objectives" })}
        </AlertStyle>
      )}
    </Display>
  );
};

export default Objectives;
