import { makeStyles, createStyles } from "@material-ui/core/styles";

const fixedBtnStyles = {
  position: "fixed !important",
  bottom: "40px",
  right: "40px",
  zIndex: "1000",
  color: "#fff !important",
  "& span svg": {
    fontSize: "24px",
  },
  "&:disabled": {
    backgroundColor: "#eee !important",
    opacity: "0.7 !important",
    color: "#333 !important",
  },
};

export const useAdministrationStyles = makeStyles(() =>
  createStyles({
    addButton: {
      fontSize: "12px",
    },
    saveButton: {
      backgroundColor: ({ saveBg = "#1abd3b" }) => `${saveBg} !important`,
      "&:hover": {
        backgroundColor: ({ hoverSaveBg = "#098341" }) =>
          `${hoverSaveBg} !important`,
      },
      ...fixedBtnStyles,
    },
    errorButton: {
      backgroundColor: ({ errorBg = "#ff6347" }) => `${errorBg} !important`,
      "&:hover": {
        backgroundColor: ({ hoverErrorBg = "#ff6347" }) =>
          `${hoverErrorBg} !important`,
      },
      ...fixedBtnStyles,
    },
  })
);
