const en = {
  app_lost_connection:
    "We lost the connection to the server. Make sure your internet is connected properly.",
  app_reconnect_button: "Reconnect",
};

const es = {
  app_lost_connection:
    "Perdimos la conexión con el servidor. Asegúrese de que está conectado a internet.",
  app_reconnect_button: "Reconectar",
};

const pt = {
  app_lost_connection:
    "Perdemos a conexão com o servidor. Certifique-se de que sua internet está conectada corretamente.",
  app_reconnect_button: "Reconectar",
};

const translations = { en, es, pt };

export default translations;
