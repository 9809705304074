import React from "react";
import { Popover } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { ruleOfThree, getToFixed, simpleSumArray } from "./MathUtils";
import GoalListItem from "../components/Common/GoalListItem";
import Avatar from "../components/Common/Avatar";

const getOkrTotalEvaluation = (
  base,
  compare,
  polarity,
  maxPercentageForSmallNumbers = 200,
) => {
  const isInvertedPolarity = polarity === "NEGATIVE";

  const validUnity = base !== 0 ? Math.abs(base) / 100 : 1;
  const validBase = base === 0 ? validUnity : base;
  const validCompare = compare === 0 ? validUnity : compare;
  const finalPolarity =
    isInvertedPolarity && validCompare < 0 && validBase > 0
      ? "POSITIVE"
      : polarity;
  const compareUnityMultiplier = Math.abs(validBase) / Math.abs(validCompare);
  const finalCompare =
    isInvertedPolarity && finalPolarity === "POSITIVE"
      ? Math.abs(validCompare) / compareUnityMultiplier
      : validCompare;

  const result = ruleOfThree(
    validBase,
    finalCompare,
    100,
    0,
    finalPolarity === "NEGATIVE",
  );

  const zeroComparison = base < 1 || compare < 1;
  const smallNumbers = base <= 1 && compare <= 1;
  const checkForSmallNumbers = zeroComparison || smallNumbers;

  if (result > maxPercentageForSmallNumbers && checkForSmallNumbers)
    return maxPercentageForSmallNumbers;
  return result;
};

export const getInsightGoalsWithPercentage = (insightGoals = []) => {
  const updatedInsightGoals = [...insightGoals];

  insightGoals.forEach((goal, index) => {
    const { insightGoal = {} } = goal;
    const { value, reached, polarity } = insightGoal;

    const percentage = Number(
      getToFixed(getOkrTotalEvaluation(value, reached, polarity)),
    );

    updatedInsightGoals[index].insightGoal = { ...insightGoal, percentage };
  });

  return updatedInsightGoals;
};

const renderOkrColumnLabel = (intl, globalMessages) => {
  return (
    <>
      <th style={{ textAlign: "left" }}>
        {intl.formatMessage(globalMessages.cenary)}
      </th>
      <th style={{ textAlign: "left" }}>
        {intl.formatMessage(globalMessages.theme)}
      </th>
      <th style={{ textAlign: "left" }}>
        {intl.formatMessage(globalMessages.objective)}
      </th>
      <th style={{ textAlign: "left" }}>
        {intl.formatMessage(globalMessages.keyResult)}
      </th>
    </>
  );
};

const getDefaultRemoveColumn = (handleOnDelete) => {
  return (
    <td
      style={{ width: "40px", textAlign: "center", cursor: "pointer" }}
      className="action-column"
      onClick={handleOnDelete}
    >
      <i className="fas fa-trash-alt" style={{ color: "#ff3131" }} />
    </td>
  );
};

const getGoalEvaluationByID = (insightID, allInsightGoals) => {
  let finalGoal = [];
  if (allInsightGoals && allInsightGoals.length > 0) {
    allInsightGoals.forEach((evaluation) => {
      if (
        evaluation &&
        evaluation.insightId &&
        evaluation.insightId === insightID
      ) {
        finalGoal = evaluation;
      }
    });
  }
  return finalGoal;
};

const getInsightIdByGoalID = (goalID, allInsightGoals) => {
  let finalId = [];
  if (allInsightGoals && allInsightGoals.length > 0) {
    allInsightGoals.forEach((evaluation) => {
      if (evaluation.insightGoal.id === goalID) {
        finalId = evaluation.insightId;
      }
    });
  }
  return finalId;
};

const getOkrListAverage = (insightArrayValues, fieldName) => {
  const goalsValuesArray = insightArrayValues.map((goalInfo) =>
    goalInfo.insightGoal && goalInfo.insightGoal[fieldName]
      ? goalInfo.insightGoal[fieldName]
      : 0,
  );

  let goalsValuesTotalSum = 0;
  goalsValuesTotalSum = goalsValuesArray.reduce(
    (goalsValuesTotalSum, singleValue) => {
      return goalsValuesTotalSum + singleValue;
    },
    0,
  );

  const totalGoalsAverage =
    goalsValuesArray.length > 0
      ? goalsValuesTotalSum / goalsValuesArray.length
      : 0;

  return totalGoalsAverage;
};

const getColorGoalByStatus = (status = "") => {
  let colorGoal = "";

  if (status === "NAO_INICIADA") colorGoal = "#808080";
  if (status === "NAO_ATINGIDA") colorGoal = "#f54e4e";
  if (status === "ATINGIDA") colorGoal = "#0c9247";
  if (status === "PARCIALMENTE_ATINGIDA") colorGoal = "#ffc107";
  if (status === "SUPERADA") colorGoal = "#6495ed";
  if (status === "CANCELADA") colorGoal = "#333";

  return colorGoal;
};

const getFilteredOkrs = (allInsightGoals) => {
  const NOT_REACHED = [];
  const REACHED = [];
  const PARTIALLY_REACHED = [];
  const EXCEEDED = [];

  if (allInsightGoals.length > 0) {
    allInsightGoals.forEach((insightInfo) => {
      const { insightGoal = {} } = insightInfo;
      const { status = null } = insightGoal;

      if (status === "NAO_ATINGIDA") NOT_REACHED.push(insightInfo);
      if (status === "ATINGIDA") REACHED.push(insightInfo);
      if (status === "PARCIALMENTE_ATINGIDA")
        PARTIALLY_REACHED.push(insightInfo);
      if (status === "SUPERADA") EXCEEDED.push(insightInfo);
    });
  }

  return { NOT_REACHED, REACHED, PARTIALLY_REACHED, EXCEEDED };
};

const getFilteredAllAnswerOkrs = (allInsightGoals, status) => {
  const NOT_REACHED = [];
  const REACHED = [];
  const PARTIALLY_REACHED = [];
  const EXCEEDED = [];
  const CANCELED = [];

  if (allInsightGoals.length > 0) {
    allInsightGoals.forEach((insightInfo) => {
      const { goal } = insightInfo;
      if (goal) {
        if (goal[status] === "NAO_ATINGIDA") NOT_REACHED.push(insightInfo);
        if (goal[status] === "ATINGIDA") REACHED.push(insightInfo);
        if (goal[status] === "PARCIALMENTE_ATINGIDA")
          PARTIALLY_REACHED.push(insightInfo);
        if (goal[status] === "SUPERADA") EXCEEDED.push(insightInfo);
        if (goal[status] === "CANCELADA") EXCEEDED.push(insightInfo);
      }
    });
  }

  return { NOT_REACHED, REACHED, PARTIALLY_REACHED, EXCEEDED, CANCELED };
};

const getQuestionOkrTotal = (insightsGoals = [], okrValue) => {
  let totalEvaluation = 0;

  insightsGoals.forEach((insightGoal) => {
    const goalEval = getOkrTotalEvaluation(
      insightGoal.value,
      insightGoal[okrValue],
      insightGoal.polarity,
    );

    if (goalEval) totalEvaluation += goalEval;
  });

  return getToFixed(totalEvaluation / insightsGoals.length);
};

const getAnswerOkrTotal = (insightsGoals = []) => {
  let totalEvaluation = 0;

  insightsGoals.forEach((answer) => {
    let goalEval;
    if (answer.goal) {
      getOkrTotalEvaluation(
        answer.goal.value,
        answer.goal.reached,
        answer.goal.polarity,
      );
    }

    if (goalEval) totalEvaluation += goalEval;
  });

  return getToFixed(totalEvaluation / insightsGoals.length);
};

export const groupedInsightsEvaluations = (
  aggroupationArray,
  okrValue,
  keyResultsFieldName = "insights",
) => {
  const goalReachs = [];

  if (Array.isArray(aggroupationArray)) {
    aggroupationArray.forEach((aggroupationInfo) => {
      const onlyKeyResults = aggroupationInfo[keyResultsFieldName];

      if (Array.isArray(onlyKeyResults)) {
        onlyKeyResults.forEach((insightInfo) => {
          if (insightInfo && insightInfo.goal && insightInfo.goal.id) {
            const goalReach = getOkrTotalEvaluation(
              insightInfo.goal.value,
              insightInfo.goal[okrValue],
              insightInfo.goal.polarity,
            );

            goalReachs.push(goalReach);
          }
        });
      }
    });
  }

  const arraySum = simpleSumArray(goalReachs);

  return arraySum > 0 ? getToFixed(arraySum / goalReachs.length, 1) : "0.0";
};

const getKeyResult = (keyResults, options) => {
  return (
    <Popover id="keyResults" style={{ maxWidth: "800px" }}>
      {keyResults.map((item) => {
        const colorStatus =
          item.goal && getColorGoalByStatus(item.goal[options.status]);
        const currentPercentage =
          item.goal &&
          getOkrTotalEvaluation(
            item.goal.value,
            item.goal[options.okrValue],
            item.goal.polarity,
          );

        return (
          <>
            <GoalListItem
              key={uuidv4()}
              title={item.name}
              avatar={
                <Avatar
                  src={
                    item.responsibleMember
                      ? item.responsibleMember.userData.avatarBlobId
                      : ""
                  }
                  name={
                    item.responsibleMember ? item.responsibleMember.name : ""
                  }
                  style={{
                    width: "30px",
                    height: "30px",
                    marginLeft: "10px",
                  }}
                />
              }
              currentPercentage={currentPercentage || 0}
              progressColor="#7ea6c7"
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "10px",
                  borderRadius: "4px",
                  background: colorStatus,
                }}
              />
            </GoalListItem>
          </>
        );
      })}
    </Popover>
  );
};

export {
  getKeyResult,
  getFilteredOkrs,
  renderOkrColumnLabel,
  getGoalEvaluationByID,
  getInsightIdByGoalID,
  getOkrListAverage,
  getOkrTotalEvaluation,
  getQuestionOkrTotal,
  getDefaultRemoveColumn,
  getColorGoalByStatus,
  getAnswerOkrTotal,
  getFilteredAllAnswerOkrs,
};
