import * as actions from "./_actions";
import send from "../../dispatcher";

function observeKpis({ stompClient, dispatch }) {
  stompClient.subscribe("/selected-tools/addKPIConfiguration", (response) => {
    send(actions.putKpiConfiguration, response, dispatch);
  });

  stompClient.subscribe("/kpi-configuration/update", (response) => {
    send(actions.putKpiConfiguration, response, dispatch);
  });

  stompClient.subscribe("/kpi-configuration/cenary", (response) => {
    send(actions.postBoardCenaryLink, response, dispatch);
  });

  stompClient.subscribe("/kpi-configuration/theme", (response) => {
    send(actions.postBoardThemeLink, response, dispatch);
  });

  stompClient.subscribe("/kpi-configuration/question", (response) => {
    send(actions.postBoardQuestionLink, response, dispatch);
  });

  stompClient.subscribe("/kpi-configuration/insight", (response) => {
    send(actions.postBoardInsightLink, response, dispatch);
  });
}

export default observeKpis;
