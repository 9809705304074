import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";

import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import FormDialog from "../../FormDialog";
import NewInsightDialog from "./NewInsightDialog";
import SelectTreeModal from "../../SelectTreeModal";
import MaterialTextField from "../../MaterialTextField";

const useStyles = makeStyles(() =>
  createStyles({
    addButton: {
      fontSize: "12px",
    },
  }),
);

const messages = defineMessages({
  btn_modal_register_new: { id: "btn_modal_register_new" },
  global_select: { id: "global.select" },
});

const initialForm = {
  insights: [],
};

const InsightSelectDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const [newInsightModal, setNewInsightModal] = useState(false);
  const [callSelectTreeModal, setCallSelectTreeModal] = useState(false);

  const {
    open = false,
    afterCancel,
    afterConfirm,
    saveNewInsight,
    treeList,
    treeOrder,
    intl,
  } = props;

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleConfirm = () => {
    if (typeof afterConfirm === "function") afterConfirm(form);
  };

  const handleCancel = () => {
    if (typeof afterCancel === "function") afterCancel();
  };

  const getSelectedInsights = (insights = []) => {
    let onlyTitles = [];

    insights.forEach((insight) => {
      onlyTitles.push(insight.title);
    });

    return onlyTitles.length > 0
      ? onlyTitles.join(", ")
      : intl.formatMessage(messages.global_select);
  };

  const toggleTreeModal = (value = false) => {
    setCallSelectTreeModal(value);
  };

  const toggleNewInsightModal = (value = false) => {
    setNewInsightModal(value);
  };

  const handleSelectedInsights = (insights = {}) => {
    setForm({ insights });

    toggleTreeModal();
  };

  const handleNewInsight = async (form = {}) => {
    await saveNewInsight(form);

    toggleNewInsightModal();
  };

  const classes = useStyles();

  return (
    <>
      <SelectTreeModal
        open={callSelectTreeModal}
        title={"Selecione o Insight"}
        modalTextCancel={"Cancelar"}
        modalTextConfirm={"Confirmar"}
        treeList={treeList}
        treeOrder={treeOrder}
        onConfirm={(selecteds) => handleSelectedInsights(selecteds)}
        onCancel={() => toggleTreeModal()}
        canSelectAnyLevel={false}
        dialogClassName="insight-select-dialog"
        bottomSelectionLabel={"Item Selecionado"}
        bottomNoSelectedItems={"Nenhum item selecionado"}
      />

      <NewInsightDialog
        open={newInsightModal}
        afterConfirm={(form) => handleNewInsight(form)}
        afterCancel={() => toggleNewInsightModal()}
      />

      <FormDialog
        open={open}
        title={"Adicionar Insight"}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialTextField
              id="roleSelect"
              label={"Selecionar Insight"}
              value={getSelectedInsights(form.insights)}
              onClick={() => toggleTreeModal(true)}
              inputStyle={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Button
              variant="outlined"
              className={classes.addButton}
              onClick={() => toggleNewInsightModal(true)}
            >
              <AddIcon /> {intl.formatMessage(messages.btn_modal_register_new)}
            </Button>
          </div>
        </div>
      </FormDialog>
    </>
  );
};

export default injectIntl(InsightSelectDialog);
