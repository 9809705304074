import { checkGreaterThan } from "../MathUtils";
import { getValidEntityConfigs, getValidId } from "../validations";
import { getEvaluationStagesOptions } from "./constants";
import {
  defaultComposition,
  defaultEvaluationConfig,
  defaultEvaluationType,
  defaultEvaluationWeights,
  defaultManagerConfig,
  getDefaultEvaluationStage,
  getInitialDates,
  getInitialEvaluationScale,
  getInitialPotentialScale,
  getInitialQualificationScale,
} from "./defaults";
import { persistMigrateV2ConfigEntity } from "./entityAutomation";

const defaultEvaluationStageValues = getEvaluationStagesOptions(
  "TYPE_360",
  {},
  false,
  true
).map(({ value }) => value);

const isObjEntity = (entity) => {
  return entity && entity.id ? entity : false;
};

export const getCompositionEntity = (composition, config) => {
  const { stringValue } = getValidEntityConfigs(config);

  return composition || stringValue;
};

export const getEvaluationTypeEntity = (evaluationType, config) => {
  const { stringValue } = getValidEntityConfigs(config);

  return evaluationType || stringValue;
};

export const getEvaluationWeightsEntity = (evaluationWeights = {}, config) => {
  const { stringValue } = getValidEntityConfigs(config);

  return {
    selfEvaluation: evaluationWeights.selfEvaluation || stringValue,
    manager: evaluationWeights.manager || stringValue,
    secondEvaluator: evaluationWeights.secondEvaluator || stringValue,
    thirdEvaluator: evaluationWeights.thirdEvaluator || stringValue,
  };
};

export const getProfilesEntity = (profiles = [], config) => {
  const { idValue, stringValue } = getValidEntityConfigs(config);
  const vProfiles = [];

  if (Array.isArray(profiles)) {
    profiles.forEach((profileInfo = {}, index) => {
      vProfiles[index] = {};
      vProfiles[index].id = getValidId(profileInfo.id, idValue);
      vProfiles[index].title = profileInfo.title || stringValue;
    });
  }

  return vProfiles;
};

export const getEvaluationScaleEntity = (evaluationScale = [], config) => {
  const { idValue, stringValue } = getValidEntityConfigs(config);
  const vEvaluationScale = [];

  if (Array.isArray(evaluationScale)) {
    evaluationScale.forEach((scaleInfo = {}, index) => {
      vEvaluationScale[index] = {};
      vEvaluationScale[index].id = getValidId(scaleInfo.id, idValue);
      vEvaluationScale[index].title = scaleInfo.title || stringValue;
      vEvaluationScale[index].value = scaleInfo.value ?? stringValue;
    });
  }

  return vEvaluationScale;
};

export const getQualificationScaleEntity = (
  qualificationScale = [],
  config
) => {
  const { idValue, stringValue } = getValidEntityConfigs(config);
  const vQualificationScale = [];

  if (Array.isArray(qualificationScale)) {
    qualificationScale.forEach((scaleInfo = {}, index) => {
      vQualificationScale[index] = {};
      vQualificationScale[index].id = getValidId(scaleInfo.id, idValue);
      vQualificationScale[index].title = scaleInfo.title || stringValue;
      vQualificationScale[index].value = scaleInfo.value ?? stringValue;
    });
  }

  return vQualificationScale;
};

export const getPotentialScaleEntity = (potentialScale = [], config) => {
  const { idValue, stringValue } = getValidEntityConfigs(config);
  const vPotentialScale = [];

  if (Array.isArray(potentialScale)) {
    potentialScale.forEach((scaleInfo = {}, index) => {
      vPotentialScale[index] = {};
      vPotentialScale[index].id = getValidId(scaleInfo.id, idValue);
      vPotentialScale[index].title = scaleInfo.title || stringValue;
      vPotentialScale[index].value = scaleInfo.value ?? stringValue;
    });
  }

  return vPotentialScale;
};

export const getParticipantsEntity = (
  participants = [],
  config,
  enableEvaluatorsSelectionWorkflow
) => {
  const { idValue, objectValue, stringValue } = getValidEntityConfigs(config);
  const vParticipants = [];

  if (Array.isArray(participants)) {
    participants.forEach((participantInfo = {}, index) => {
      vParticipants[index] = {};
      vParticipants[index].id = getValidId(participantInfo.id, idValue);
      vParticipants[index].memberParticipant =
        isObjEntity(participantInfo.memberParticipant) || objectValue;
      vParticipants[index].memberParticipantId = getValidId(
        participantInfo.memberParticipantId,
        idValue
      );
      vParticipants[index].avatarBlobId =
        participantInfo.avatarBlobId || stringValue;
      vParticipants[index].companyArea =
        isObjEntity(participantInfo.companyArea) || objectValue;
      vParticipants[index].companyAreaId = getValidId(
        participantInfo.companyAreaId,
        idValue
      );
      vParticipants[index].companyPosition =
        isObjEntity(participantInfo.companyPosition) || objectValue;
      vParticipants[index].companyPositionId = getValidId(
        participantInfo.companyPositionId,
        idValue
      );
      vParticipants[index].profile =
        isObjEntity(participantInfo.profile) || objectValue;
      vParticipants[index].profile =
        vParticipants[index].profile && vParticipants[index].profile.id
          ? getProfilesEntity([vParticipants[index].profile, config])[0]
          : objectValue;
      vParticipants[index].profileId = getValidId(
        vParticipants[index].profile?.id
      );
      vParticipants[index].manager =
        isObjEntity(participantInfo.manager) || objectValue;
      vParticipants[index].managerId = getValidId(
        participantInfo.managerId,
        idValue
      );
      vParticipants[index].secondEvaluator =
        isObjEntity(participantInfo.secondEvaluator) || objectValue;
      vParticipants[index].secondEvaluatorId = getValidId(
        participantInfo.secondEvaluatorId,
        idValue
      );
      vParticipants[index].thirdEvaluator =
        isObjEntity(participantInfo.thirdEvaluator) || objectValue;
      vParticipants[index].thirdEvaluatorId = getValidId(
        participantInfo.thirdEvaluatorId,
        idValue
      );
      vParticipants[index].evaluationStage =
        defaultEvaluationStageValues.includes(participantInfo.evaluationStage)
          ? participantInfo.evaluationStage
          : getDefaultEvaluationStage(enableEvaluatorsSelectionWorkflow);
    });
  }

  return vParticipants.filter(
    ({ memberParticipantId }) => config?.canInsertNewId || memberParticipantId
  );
};

export const getCompetenceAreasEntity = (competenceAreas = [], config) => {
  const { idValue, stringValue } = getValidEntityConfigs(config);
  const vCompetenceAreas = [];

  if (Array.isArray(competenceAreas)) {
    competenceAreas.forEach((competenceAreaInfo = {}, index) => {
      vCompetenceAreas[index] = {};
      vCompetenceAreas[index].id = getValidId(competenceAreaInfo.id, idValue);
      vCompetenceAreas[index].title = competenceAreaInfo.title || stringValue;
      vCompetenceAreas[index].description =
        competenceAreaInfo.description || stringValue;
      vCompetenceAreas[index].profiles = getProfilesEntity(
        competenceAreaInfo.profiles
      );
    });
  }

  return vCompetenceAreas;
};

export const getSkillsEntity = (skills = [], config) => {
  const { idValue, stringValue } = getValidEntityConfigs(config);
  const vSkills = [];

  if (Array.isArray(skills)) {
    skills.forEach((skillInfo = {}, index) => {
      vSkills[index] = {};
      vSkills[index].id = getValidId(skillInfo.id, idValue);
      vSkills[index].title = skillInfo.title || stringValue;
      vSkills[index].description = skillInfo.description || stringValue;
      vSkills[index].competenceArea = skillInfo.competenceArea || {};
      vSkills[index].competenceArea = vSkills[index].competenceArea.id
        ? getCompetenceAreasEntity([vSkills[index].competenceArea, config])[0]
        : {};
    });
  }

  return vSkills;
};

export const getRoleSkillRelationEntity = (roleSkillRelation = [], config) => {
  const { idValue } = getValidEntityConfigs(config);
  const vRelation = [];

  if (Array.isArray(roleSkillRelation)) {
    roleSkillRelation.forEach((relationInfo = {}, index) => {
      vRelation[index] = {};
      vRelation[index].id = getValidId(relationInfo.id, idValue);
      vRelation[index].companyRole = relationInfo.companyRole || {};
      vRelation[index].companyRoleId = getValidId(
        relationInfo.companyRoleId,
        idValue
      );
      vRelation[index].skill = relationInfo.skill || {};
      vRelation[index].skill = vRelation[index].skill.id
        ? getSkillsEntity([vRelation[index].skill, config])[0]
        : {};
      vRelation[index].skillId = getValidId(relationInfo.skillId, idValue);
      vRelation[index].desiredEvaluation = relationInfo.desiredEvaluation ?? 0;
    });
  }

  return vRelation.filter(
    (relation) => config?.canInsertNewId || relation?.skillId
  );
};

export const getDatesEntity = (dates = {}, config) => {
  const { stringValue } = getValidEntityConfigs(config);

  return {
    startValidation: dates.startValidation || stringValue,
    endValidation: dates.endValidation || stringValue,
    startSelfEvaluation: dates.startSelfEvaluation || stringValue,
    endSelfEvaluation: dates.endSelfEvaluation || stringValue,
    startEvaluators: dates.startEvaluators || stringValue,
    endEvaluators: dates.endEvaluators || stringValue,
    startClosure: dates.startClosure || stringValue,
    endClosure: dates.endClosure || stringValue,
    startPdi: dates.startPdi || stringValue,
    endPdi: dates.endPdi || stringValue,
    startFeedback: dates.startFeedback || stringValue,
    endFeedback: dates.endFeedback || stringValue,
  };
};

export const getWorkflowEntity = (workflow = [], config) => {
  const { idValue, objectValue } = getValidEntityConfigs(config);
  const vWorkflow = [];

  function getScale(evaluation) {
    return checkGreaterThan(evaluation, -1) ? evaluation : null;
  }

  if (Array.isArray(workflow)) {
    workflow.forEach((workflowInfo = {}, index) => {
      vWorkflow[index] = {};
      vWorkflow[index].id = getValidId(workflowInfo.id, idValue);
      vWorkflow[index].participant = workflowInfo.participant
        ? getParticipantsEntity([workflowInfo.participant])[0]
        : objectValue;
      vWorkflow[index].participantId = getValidId(
        workflowInfo.participantId,
        idValue
      );
      vWorkflow[index].skill = workflowInfo.skill || {};
      vWorkflow[index].skill = vWorkflow[index].skill.id
        ? getSkillsEntity([vWorkflow[index].skill, config])[0]
        : objectValue;
      vWorkflow[index].skillId = getValidId(workflowInfo.skillId, idValue);
      vWorkflow[index].selfEvaluation = getScale(workflowInfo.selfEvaluation);
      vWorkflow[index].managerEvaluation = getScale(
        workflowInfo.managerEvaluation
      );
      vWorkflow[index].potential = getScale(workflowInfo.potential);
      vWorkflow[index].secondEvaluator = getScale(workflowInfo.secondEvaluator);
      vWorkflow[index].thirdEvaluator = getScale(workflowInfo.thirdEvaluator);
      vWorkflow[index].finalEvaluation = getScale(workflowInfo.finalEvaluation);
    });
  }

  return vWorkflow.filter(
    (workflow) => config?.canInsertNewId || workflow?.participantId
  );
};

export const getManagerConfigEntity = (managerConfig = {}) => {
  /* NEW */
  return {
    id: managerConfig?.id || null,
    selfEvaluationAccess: managerConfig?.selfEvaluationAccess,
    secondEvaluatorAccess: managerConfig?.secondEvaluatorAccess,
    thirdEvaluatorAccess: managerConfig?.thirdEvaluatorAccess,
  };
};

export const getEvaluationConfigEntity = (evaluationConfig = {}) => {
  /* NEW */
  return {
    id: evaluationConfig?.id || null,
    keySeat: evaluationConfig?.keySeat,
    succession: evaluationConfig?.succession,
    performance: evaluationConfig?.performance,
  };
};

export const getGeneralOptionsEntity = (generalOptions = {}) => {
  /* NEW */
  return {
    id: generalOptions?.id || null,
    restrictViewAccess: generalOptions?.restrictViewAccess,
    disableRequiredQuestionsValidation:
      generalOptions?.disableRequiredQuestionsValidation || false,
  };
};

export const getComplementaryScalesEntity = (
  complementaryScales = [],
  config
) => {
  /* NEW */
  const { idValue, stringValue } = getValidEntityConfigs(config);
  const vComplementaryScales = [];

  if (Array.isArray(complementaryScales)) {
    complementaryScales.forEach((evaluation = {}, index) => {
      vComplementaryScales[index] = {};
      vComplementaryScales[index].id = getValidId(evaluation.id, idValue);
      vComplementaryScales[index].title = evaluation.title || stringValue;
      vComplementaryScales[index].value = evaluation.value ?? stringValue;
      vComplementaryScales[index].type = evaluation.type || stringValue;
    });
  }

  return vComplementaryScales;
};

export const getComplementaryEvaluationsEntity = (
  complementaryEvaluations = [],
  config
) => {
  /* NEW */
  const { idValue, stringValue } = getValidEntityConfigs(config);
  const vComplementaryEvaluations = [];

  if (Array.isArray(complementaryEvaluations)) {
    complementaryEvaluations.forEach((evaluation = {}, index) => {
      vComplementaryEvaluations[index] = {};
      vComplementaryEvaluations[index].id = getValidId(evaluation.id, idValue);
      vComplementaryEvaluations[index].title = evaluation.title || stringValue;
      vComplementaryEvaluations[index].description =
        evaluation.description || stringValue;
      vComplementaryEvaluations[index].type = evaluation.type || stringValue;
      vComplementaryEvaluations[index].subType =
        evaluation.subType || stringValue;
    });
  }

  return vComplementaryEvaluations;
};

export const getComplementaryWorkflowEntity = (
  complementaryWorkflow = [],
  config
) => {
  /* NEW */
  const { idValue, stringValue, objectValue } = getValidEntityConfigs(config);
  const vComplementaryWorkflow = [];

  if (Array.isArray(complementaryWorkflow)) {
    complementaryWorkflow.forEach((evaluation = {}, index) => {
      vComplementaryWorkflow[index] = {};
      vComplementaryWorkflow[index].id = getValidId(evaluation.id, idValue);
      vComplementaryWorkflow[index].participant = evaluation.participant
        ? getParticipantsEntity([evaluation.participant])[0]
        : objectValue;
      vComplementaryWorkflow[index].participantId = getValidId(
        evaluation.participantId,
        idValue
      );
      vComplementaryWorkflow[index].complementaryEvaluation = evaluation
        .complementaryEvaluation?.id
        ? getComplementaryEvaluationsEntity([
            evaluation.complementaryEvaluation,
            config,
          ])[0]
        : objectValue;
      vComplementaryWorkflow[index].complementaryEvaluationId = getValidId(
        evaluation.complementaryEvaluationId,
        idValue
      );
      vComplementaryWorkflow[index].value = evaluation.value ?? stringValue;
      vComplementaryWorkflow[index].alternativeValueBool =
        evaluation.alternativeValueBool ?? stringValue;
      vComplementaryWorkflow[index].alternativeValueString =
        evaluation.alternativeValueString ?? stringValue;
      vComplementaryWorkflow[index].alternativeValueUser =
        evaluation.alternativeValueUser ?? objectValue;
    });
  }

  return vComplementaryWorkflow;
};

export const getSkillsAssessmentEntity = (administration = {}, config) => {
  const entityConfig = getValidEntityConfigs(config);

  return {
    id: getValidId(administration.id, entityConfig.idValue),
    selectedToolId: getValidId(
      administration.selectedToolId,
      entityConfig.idValue
    ),
    composition: getCompositionEntity(administration.composition, entityConfig),
    evaluationType: getEvaluationTypeEntity(
      administration.evaluationType,
      entityConfig
    ),
    evaluationWeights: getEvaluationWeightsEntity(
      administration.evaluationWeights,
      entityConfig
    ),
    profiles: getProfilesEntity(administration.profiles, entityConfig),
    evaluationScale: getEvaluationScaleEntity(
      administration.evaluationScale,
      entityConfig
    ),
    qualificationScale: getQualificationScaleEntity(
      administration.qualificationScale,
      entityConfig
    ),
    potentialScale: getPotentialScaleEntity(
      administration.potentialScale,
      entityConfig
    ),
    participants: getParticipantsEntity(
      administration.participants,
      entityConfig
    ),
    competenceAreas: getCompetenceAreasEntity(
      administration.competenceAreas,
      entityConfig
    ),
    skills: getSkillsEntity(administration.skills, entityConfig),
    roleSkillRelation: getRoleSkillRelationEntity(
      administration.roleSkillRelation
    ),
    dates: getDatesEntity(administration.dates, entityConfig),
    workflow: getWorkflowEntity(administration.workflow, entityConfig),
    managerConfig: getManagerConfigEntity(administration.managerConfig),
    evaluationConfig: getEvaluationConfigEntity(
      administration.evaluationConfig
    ),
    complementaryScales: getComplementaryScalesEntity(
      administration.complementaryScales,
      entityConfig
    ),
    complementaryEvaluations: getComplementaryEvaluationsEntity(
      administration.complementaryEvaluations,
      entityConfig
    ),
    complementaryWorkflow: getComplementaryWorkflowEntity(
      administration.complementaryWorkflow,
      entityConfig
    ),
    generalOptions: getGeneralOptionsEntity(administration.generalOptions),
    transparency: administration.transparency || false,
    enableEvaluatorsSelectionWorkflow:
      administration.enableEvaluatorsSelectionWorkflow || false,
  };
};

export const buildFormSkillsAssessment = (administration) => {
  return getSkillsAssessmentEntity(administration, {
    stringValue: "",
    arrayValue: [],
    idValue: null,
  });
};

export const buildRestSkillsAssessment = (
  administration,
  objectValue = null
) => {
  return getSkillsAssessmentEntity(administration, {
    stringValue: null,
    arrayValue: [],
    idValue: null,
    objectValue,
  });
};

export const buildNewSKillsAssessment = () => {
  const initialAdministration = buildRestSkillsAssessment({});

  const newAdministration = {
    ...initialAdministration,
    composition: defaultComposition,
    evaluationType: defaultEvaluationType,
    evaluationWeights: defaultEvaluationWeights,
    profiles: [],
    evaluationScale: getInitialEvaluationScale(),
    qualificationScale: getInitialQualificationScale(),
    potentialScale: getInitialPotentialScale(),
    dates: getInitialDates(),
    participants: [],
    competenceAreas: [],
    skills: [],
    roleSkillRelation: [],
    workflow: [],
    managerConfig: defaultManagerConfig,
    evaluationConfig: defaultEvaluationConfig,
  };

  return persistMigrateV2ConfigEntity(newAdministration);
};
