import {
  CUSTOM_ADD_SCENERY,
  CUSTOM_ADD_THEME,
  CUSTOM_ADD_QUESTION,
  CUSTOM_ADD_INSIGHT,
  PARTICIPANT_WORKFLOW_UPDATE,
} from "./types";

export const clearViewCallback = (typeConstant) => {
  return (dispatch) => {
    if (typeConstant) {
      dispatch({
        type: "STRUCTURE_ACTION",
        structureAction: { type: typeConstant, remove: true },
      });
    }
  };
};

export const customAddScenerie = (toolId, params = {}) => {
  return (dispatch) => {
    if (toolId) {
      dispatch({
        type: "STRUCTURE_ACTION",
        structureAction: {
          type: CUSTOM_ADD_SCENERY,
          params: { toolId, ...params },
        },
      });
    }
  };
};

export const customAddTheme = (toolId, params = {}) => {
  return (dispatch) => {
    if (toolId) {
      dispatch({
        type: "STRUCTURE_ACTION",
        structureAction: {
          type: CUSTOM_ADD_THEME,
          params: { toolId, ...params },
        },
      });
    }
  };
};

export const customAddQuestion = (toolId, params = {}) => {
  return (dispatch) => {
    if (toolId) {
      dispatch({
        type: "STRUCTURE_ACTION",
        structureAction: {
          type: CUSTOM_ADD_QUESTION,
          params: { toolId, ...params },
        },
      });
    }
  };
};

export const customAddInsight = (toolId, params = {}) => {
  return (dispatch) => {
    if (toolId) {
      dispatch({
        type: "STRUCTURE_ACTION",
        structureAction: {
          type: CUSTOM_ADD_INSIGHT,
          params: { toolId, ...params },
        },
      });
    }
  };
};

export const openParticipantWorkflowDialog = (
  toolId,
  goalBonusAdministration = {},
  questionInsights = [],
  onlyGoals = [],
  newStatus = "APPROVAL",
) => {
  return (dispatch) => {
    const params = {
      toolId,
      goalBonusAdministration,
      questionInsights,
      onlyGoals,
      newStatus,
    };

    if (toolId && goalBonusAdministration.id && onlyGoals.length > 0) {
      dispatch({
        type: "STRUCTURE_ACTION",
        structureAction: {
          type: PARTICIPANT_WORKFLOW_UPDATE,
          params,
        },
      });
    }
  };
};
