import { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import utils from "../../../../utils/toolUtils";
import okrUtils from "../../../../utils/okr";
import { updateQuestion } from "../../../../actions/questionsActions";

const {
  getObjectDifference,
  getObjectInfoById,
  getOnlySlugValues,
  removeObjSlug,
} = utils;

const { biuldAdmRestBody } = okrUtils;

const defaultForm = biuldAdmRestBody({ objectives: [] });

const OkrWatcher = (props) => {
  const [okrObjectives, setOkrObjectives] = useState(defaultForm.objectives);
  const [firstValidation, setFirstValidation] = useState(false);

  const {
    originOkrObjectives = [],
    selectedTool = {},
    allQuestions = {},
    updateQuestion,
  } = props;

  useEffect(() => {
    const restOkrAdministration = biuldAdmRestBody({
      objectives: originOkrObjectives,
    });
    const restOkrObjectives = restOkrAdministration.objectives;
    const isAdmObjDiff = !_.isEqual(restOkrObjectives, okrObjectives);

    if (restOkrObjectives && Object.keys(allQuestions).length > 0) {
      if (isAdmObjDiff || !firstValidation) {
        const compareBase = removeObjSlug(okrObjectives, "id");

        const diff = getObjectDifference(restOkrObjectives, compareBase);

        if (diff) {
          if (!firstValidation) setFirstValidation(true);

          const watchObjectives = getOnlySlugValues(diff, "id");

          if (watchObjectives.length > 0) {
            Object.keys(allQuestions).forEach((themeId) => {
              const themeQuestions = allQuestions[themeId];

              themeQuestions.forEach((questionInfo) => {
                const { okrConfigurationObjectiveId } = questionInfo;
                const relatedAdmObjectiveId =
                  watchObjectives[
                    watchObjectives.indexOf(okrConfigurationObjectiveId)
                  ] || null;

                if (relatedAdmObjectiveId && questionInfo.text) {
                  const admObjectiveInfo = getObjectInfoById(
                    relatedAdmObjectiveId,
                    restOkrObjectives,
                    "id",
                  );

                  if (
                    admObjectiveInfo &&
                    admObjectiveInfo.title !== questionInfo.text
                  ) {
                    updateQuestion(
                      { ...questionInfo, text: admObjectiveInfo.title },
                      questionInfo.theme.id,
                    );
                  }
                }
              });
            });
          }
        }

        if (isAdmObjDiff) setOkrObjectives(restOkrObjectives);
      }
    }
  }, [originOkrObjectives, allQuestions, firstValidation, selectedTool.id]);

  return null;
};

const mapStateToProps = (state) => {
  const { okr } = state;

  return { originOkrObjectives: okr.objectives };
};

export default connect(mapStateToProps, { updateQuestion })(OkrWatcher);
