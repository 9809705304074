import React from "react";
import { connect } from "react-redux";
import { getSelectedToolStates } from "../../../customMapStates";
import {
  GOALS_AND_BONUS,
  KPIS,
  OKR_ID,
  COMPETITIVE_ANALYSIS,
} from "../../../../constants/tools.constants";
import GoalsBonusWatcher from "./GoalsBonusWatcher";
import KpiWatcher from "./KpiWatcher";
import OkrWatcher from "./OkrWatcher";
import CompetitiveAnalysisWatcher from "./CompetitiveAnalysisWatcher";

const ScenaryWatcher = (props) => {
  const { selectedTool = {}, allSceneries = [] } = props;

  const appendProps = {
    selectedTool,
    allSceneries,
  };

  function getToolWatcher() {
    if (selectedTool.tool && selectedTool.tool.id) {
      switch (selectedTool.tool.id) {
        case GOALS_AND_BONUS:
          return <GoalsBonusWatcher {...appendProps} />;
        case KPIS:
          return <KpiWatcher {...appendProps} />;
        case OKR_ID:
          return <OkrWatcher {...appendProps} />;
        case COMPETITIVE_ANALYSIS:
          return <CompetitiveAnalysisWatcher {...appendProps} />;
        default:
          return null;
      }
    }

    return null;
  }

  return getToolWatcher();
};

const mapStateToProps = (state) => {
  const { selectedTool, allSceneries } = getSelectedToolStates(state);

  return {
    selectedTool,
    allSceneries,
  };
};

export default connect(mapStateToProps)(ScenaryWatcher);
