import React from "react";
import { defineMessages } from "react-intl";
import ActionPlanTable from "./Table";
import { translatedText } from "../../../utils/translationUtils";
import { checkAccess } from "../../../utils/accessLevels";

const messages = defineMessages({
  acp_main_title: { id: "acp_main_title" },
});

const translation = (id, values) => translatedText(id, messages, values);

const InsightActionPlan = (props) => {
  let blockAllChanges = true;

  if (
    checkAccess([
      "ADMIN",
      "MODERATOR",
      "MANAGER",
      "LEADER",
      "RESPONSIBLE",
      "COLLABORATOR",
    ])
  )
    blockAllChanges = false;

  const {
    onlyForm = false,
    SaveButton = false,
    selectedTool = {},
    dragabble = true,
    customTitle,
  } = props;

  return !onlyForm ? (
    <div className="box box-primary">
      <div className="box-header with-border">
        <h3 className="box-title">
          <i className="fas fa-list-ol" />
          {customTitle || translation("acp_main_title")}
        </h3>
        {SaveButton && SaveButton}
      </div>
      <div className="box-body">
        <ActionPlanTable
          {...props}
          blockAllChanges={blockAllChanges}
          selectedTool={selectedTool}
          dragabble={dragabble}
        />
      </div>
    </div>
  ) : (
    <ActionPlanTable
      {...props}
      selectedTool={selectedTool}
      dragabble={dragabble}
    />
  );
};

export default InsightActionPlan;
