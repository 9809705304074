const DEBUG = false;

export default function dispatcher(
  action,
  response,
  dispatch,
  additionalProps,
) {
  const body = JSON.parse(response.body);

  if (DEBUG)
    console.log("WebSockets: ", {
      data: body,
      destination: response.headers.destination,
      additionalProps,
    });

  if (body) dispatch(action(body, additionalProps));
}
