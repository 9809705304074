import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import WorkflowComplementary from "./WorkflowComplementary";
import { checkAccess } from "utils/accessLevels";

const WorkflowComplementaryGroup = ({
  selectedToolId,
  complementaryWorkflow,
  toolAdministration,
  handleUpdate,
  openSelectSuccessor,
  insight,
  loggedUser,
  isInsight = false,
  managerId,
}) => {
  const { evaluationConfig } = toolAdministration;

  return (
    <div>
      {evaluationConfig.performance && ((loggedUser?.id === managerId) || checkAccess(["MANAGER"])) && (
        <WorkflowComplementary
          selectedToolId={selectedToolId}
          toolAdministration={toolAdministration}
          complementaryWorkflow={complementaryWorkflow}
          handleUpdate={handleUpdate}
          filterType="PERFORMANCE"
          openSelectSuccessor={openSelectSuccessor}
          insight={insight}
          loggedUser={loggedUser}
          isInsight={isInsight}
        />
      )}
      {evaluationConfig.keySeat && ((loggedUser?.id === managerId) || checkAccess(["MANAGER"])) && (
        <WorkflowComplementary
          selectedToolId={selectedToolId}
          toolAdministration={toolAdministration}
          complementaryWorkflow={complementaryWorkflow}
          handleUpdate={handleUpdate}
          filterType="KEY_SEAT"
          openSelectSuccessor={openSelectSuccessor}
          insight={insight}
          loggedUser={loggedUser}
          isInsight={isInsight}
        />
      )}
      {
        evaluationConfig.succession && ((loggedUser?.id === managerId) || checkAccess(["MANAGER"])) && (
          <WorkflowComplementary
            selectedToolId={selectedToolId}
            toolAdministration={toolAdministration}
            complementaryWorkflow={complementaryWorkflow}
            handleUpdate={handleUpdate}
            filterType="SUCCESSION"
            openSelectSuccessor={openSelectSuccessor}
            insight={insight}
            loggedUser={loggedUser}
            isInsight={isInsight}
          />
        )
      }
    </div>
  );
};

WorkflowComplementaryGroup.propTypes = {
  complementaryWorkflow: PropTypes.array.isRequired,
  toolAdministration: PropTypes.object.isRequired,
  insight: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func,
  openSelectSuccessor: PropTypes.func,
};

WorkflowComplementaryGroup.defaultProps = {
  handleUpdate: () => { },
  openSelectSuccessor: () => { },
};

function areEqual(prevProps, nextProps) {
  if (
    !_.isEqual(prevProps.complementaryWorkflow, nextProps.complementaryWorkflow)
  )
    return false;
  return true;
}

export default React.memo(WorkflowComplementaryGroup, areEqual);
