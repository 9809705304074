import React from "react";
import { defineMessages } from "react-intl";
import Administration from "./Administration";

import { translatedText } from "../../../../../utils/translationUtils";
import { checkAccess } from "../../../../../utils/accessLevels";

const messages = defineMessages({
  gb_tab_administration: { id: "gb_tab_administration" },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

function getTab(appendProps = {}) {
  let filteredTabs = [];

  if (checkAccess(["OWNER", "ADMIN", "MODERATOR", "MANAGER"])) {
    filteredTabs = [
      {
        id: "administration",
        label: translation("gb_tab_administration"),
        component: <Administration {...appendProps} />,
      },
    ];
  }

  return filteredTabs;
}

export default getTab;
