export const getFilteredQuestionsAndInsights = (
  themesId = [],
  allQuestions = {},
  allInsights = {},
) => {
  const newQuestions = [];
  const newQuestionsId = [];
  const newInsights = [];
  const newInsightsId = [];

  function pushInsights(insights) {
    if (Array.isArray(insights))
      insights.forEach((insightInfo) => {
        newInsights.push(insightInfo);
        newInsightsId.push(insightInfo.id);
      });

    return newInsights;
  }

  themesId.forEach((themeId) => {
    const themeQuestions = allQuestions[themeId];

    if (Array.isArray(themeQuestions)) {
      themeQuestions.forEach((questionInfo) => {
        newQuestions.push(questionInfo);
        newQuestionsId.push(questionInfo.id);
        pushInsights(allInsights[questionInfo.id]);
      });
    }
  });

  return {
    questions: newQuestions,
    questionsId: newQuestionsId,
    insights: newInsights,
    insightsId: newInsightsId,
  };
};

export const getInsightQuestions = (insights) => {
  const questions = [];
  const questionsId = [];

  insights.forEach((insightInfo = {}) => {
    const insightQuestion = insightInfo.question || {};

    if (insightQuestion.id && questionsId.indexOf(insightQuestion.id) === -1) {
      questions.push(insightQuestion);
      questionsId.push(insightQuestion.id);
    }
  });

  return { questions, questionsId };
};
