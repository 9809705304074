import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  h2 {
    font-size: 20px;
    width: 100%;
  }
`;

const Options = styled.div`
  margin: 15px 0px;
`;

const Option = styled.div`
  padding: 10px;
  margin: 10px 0px;
  background-color: #333;
  border: 2px solid transparent;
  color: #c4e16c;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;

  &:hover {
    border-color: #c4e16c;
  }
`;

const Step2 = ({ nextStep }) => {
  return (
    <Wrapper className="row">
      <div className="col-xs-12">
        <h2>Passo #2: Preferencias de template</h2>
        <Options>
          <Option onClick={() => nextStep("blank")}>
            <i className="far fa-file" /> Template em branco
          </Option>
          <Option onClick={() => nextStep("import")}>
            <i className="fas fa-file-import" /> Importar dados de um período
            anterior
          </Option>
        </Options>
      </div>
    </Wrapper>
  );
};

export default Step2;
