import React from "react";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import { connect } from "react-redux";

import { getSelectedToolStates } from "../../customMapStates";
import { Display, Title, AlertStyle } from "./CustomTablesOkr/OkrStyle";
import ButtonGeneratePdf from "../../Common/ButtonGeneratePdf";

import Avatar from "../../Common/Avatar";

import { getFilteredAllAnswerOkrs } from "../../../utils/okrUtils";
import { getToFixed, ruleOfThree } from "../../../utils/MathUtils";

const SimpleTableCustom = styled.table`
  & > tbody > tr > td {
    border: 6px solid #fff !important;
    background-color: #efefef !important;
    padding: 13px 7px !important;
  }
  & > thead > tr > td {
    border-left: 6px solid #fff !important;
    border-right: 6px solid #fff !important;
    border-bottom: 6px solid #fff !important;
    background-color: #dedede !important;
    padding: 13px 7px !important;
  }
`;

const TdStatus = styled.td`
  border-top: 6px solid ${(props) => props.color || "#ccc"} !important;
  font-size: 12px;
`;

const RowTable = styled.td`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

const MonitoringCycles = ({ ownersResults, intl, status }) => {
  return (
    <>
      <ButtonGeneratePdf />
      <Display fullWidth>
        <Title>{intl.formatMessage({ id: "title_responsible_okr" })}</Title>
        {ownersResults.length ? (
          <SimpleTableCustom className="simple-table" border={0}>
            <thead>
              <tr>
                <TdStatus style={{ width: "390px" }}>
                  {intl.formatMessage({ id: "td_responsible" })}
                </TdStatus>
                <TdStatus style={{ width: "150px" }} color="#f54e4e">
                  {intl.formatMessage({
                    id: "insight.goals.status.notAchieved",
                  })}
                </TdStatus>
                <TdStatus style={{ width: "150px" }} color="#ffc107">
                  {intl.formatMessage({
                    id: "insight.goals.status.partiallyAchieved",
                  })}
                </TdStatus>
                <TdStatus style={{ width: "150px" }} color="#0c9247">
                  {intl.formatMessage({ id: "insight.goals.status.achieved" })}
                </TdStatus>
                <TdStatus style={{ width: "150px" }} color="#6495ed">
                  {intl.formatMessage({ id: "insight.goals.status.exceeded" })}
                </TdStatus>
                <TdStatus style={{ width: "150px" }} color="#333">
                  {intl.formatMessage({ id: "insight.goals.status.canceled" })}
                </TdStatus>
                <TdStatus style={{ width: "150px" }}>Total</TdStatus>
                <TdStatus>
                  {intl.formatMessage({ id: "global.percentage" })}
                </TdStatus>
              </tr>
            </thead>
            <tbody>
              {ownersResults &&
                ownersResults.map((ownerResult) => {
                  const {
                    NOT_REACHED,
                    PARTIALLY_REACHED,
                    REACHED,
                    EXCEEDED,
                    CANCELED,
                  } = getFilteredAllAnswerOkrs(ownerResult.answers, status);

                  const total =
                    NOT_REACHED.length +
                    PARTIALLY_REACHED.length +
                    REACHED.length +
                    EXCEEDED.length +
                    CANCELED.length;
                  let sum;
                  let sumReached;

                  if (
                    REACHED.length ||
                    EXCEEDED.length ||
                    PARTIALLY_REACHED.length ||
                    NOT_REACHED.length ||
                    CANCELED.length
                  ) {
                    sumReached = REACHED.length + EXCEEDED.length;
                    sum =
                      sumReached +
                      PARTIALLY_REACHED.length +
                      NOT_REACHED.length -
                      CANCELED.length;
                  }
                  const calculatePlan = ruleOfThree(sum, sumReached);
                  const progressWidth = (100 * parseFloat(calculatePlan)) / 100;

                  return (
                    <tr key={ownerResult?.owner?.id}>
                      <td>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {ownerResult.owner && (
                            <>
                              <Avatar
                                src={ownerResult.owner.userData.avatarBlobId}
                                name={ownerResult.owner.name}
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  margin: "0 5px",
                                }}
                              />
                              <span>{ownerResult.owner.name}</span>
                            </>
                          )}
                        </div>
                      </td>
                      <RowTable>{NOT_REACHED.length}</RowTable>
                      <RowTable>{PARTIALLY_REACHED.length}</RowTable>
                      <RowTable>{REACHED.length}</RowTable>
                      <RowTable>{EXCEEDED.length}</RowTable>
                      <RowTable>{CANCELED.length}</RowTable>
                      <RowTable>{total}</RowTable>
                      <RowTable>
                        <div
                          style={{
                            margin: "0px",
                            border: "none",
                            height: "0px",
                          }}
                          className="goal-list-item"
                        >
                          <div
                            className="percentage"
                            style={{ color: "#333", marginRight: "10px" }}
                          >
                            <b>{getToFixed(calculatePlan, 2)}%</b>
                          </div>
                          <div
                            className="bar"
                            style={{ width: "100%", background: "#dedede" }}
                          >
                            <div
                              className="progress"
                              style={{
                                width: `${progressWidth}%`,
                                backgroundColor: "#7ea6c7",
                              }}
                            />
                          </div>
                        </div>
                      </RowTable>
                    </tr>
                  );
                })}
            </tbody>
          </SimpleTableCustom>
        ) : (
          <AlertStyle severity="info">
            {intl.formatMessage({ id: "info_insights_responsibles" })}
          </AlertStyle>
        )}
      </Display>
    </>
  );
};

function OkrResponsable({
  allAnswers,
  filterData,
  allQuestions,
  intl,
  showHiddenExpectation,
}) {
  let filteredThemes = filterData.themes || [];
  filteredThemes = filteredThemes.length > 0 ? filteredThemes : ["nothing"];
  let filteredQuestions = filterData.questions || [];
  filteredQuestions =
    filteredQuestions.length > 0 ? filteredQuestions : ["nothing"];
  const status = !showHiddenExpectation ? "status" : "expectationStatus";

  function getAllAnswersOwner() {
    const owners = [];

    filteredThemes.forEach((themeId) => {
      if (allQuestions[themeId]) {
        allQuestions[themeId].forEach((question) => {
          if (
            allAnswers[question.id] &&
            filteredQuestions.includes(question.id)
          ) {
            allAnswers[question.id].forEach((answer) => {
              if (answer.responsibleMember) {
                const ownerIndex = owners.findIndex(
                  (owner) => owner.id === answer.responsibleMember.id,
                );

                if (ownerIndex === -1) {
                  owners.push(answer.responsibleMember);
                }
              } else if (owners) {
                const ownerIndex = owners.findIndex(
                  (owner) => owner.id === answer.owner.id,
                );

                if (ownerIndex === -1) {
                  owners.push(answer.owner);
                }
              }
            });
          }
        });
      }
    });

    return owners;
  }

  function getAllAnswersByOwner() {
    const owners = getAllAnswersOwner();
    const ownersAnswers = [];

    function getAllAnswers(owner) {
      const answers = [];
      let avatar = {};

      filteredThemes.forEach((themeId) => {
        if (allQuestions[themeId]) {
          allQuestions[themeId].forEach((question) => {
            if (allAnswers[question.id]) {
              allAnswers[question.id].forEach((answer) => {
                if (answer.responsibleMember) {
                  if (owner.id === answer.responsibleMember.id) {
                    answers.push(answer);
                    avatar = answer.avatar;
                  }
                } else if (owner.id === answer.owner.id) {
                  answers.push(answer);
                  avatar = answer.avatar;
                }
              });
            }
          });
        }
      });

      return { answers, avatar };
    }

    owners.forEach((owner) => {
      ownersAnswers.push({
        owner,
        avatar: getAllAnswers(owner).avatar,
        answers: getAllAnswers(owner).answers,
      });
    });

    return ownersAnswers;
  }

  return (
    <MonitoringCycles
      ownersResults={getAllAnswersByOwner()}
      status={status}
      intl={intl}
    />
  );
}

const mapStateToProps = (state) => {
  const { toolsConfigurations } = state;
  const { allAnswers, allQuestions } = getSelectedToolStates(state);

  return {
    allAnswers,
    allQuestions,
    showHiddenExpectation: toolsConfigurations.showHiddenExpectation,
  };
};

export default connect(mapStateToProps, {})(injectIntl(OkrResponsable));
