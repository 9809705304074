import React from "react";
import styled from "styled-components";
import { OverlayTrigger } from "react-bootstrap";
import { getToFixed } from "../../utils/MathUtils";
import { useWindowSize } from "../../utils/customHooks";
import { getValidFunc } from "../../utils/validations";

const ProgressBar = styled.div`
  width: ${({ width }) => width};
  background-color: ${({ bg }) => bg};

  @media print {
    background-color: ${(props) =>
      props.background ? props.background : ""} !important;
    -webkit-print-color-adjust: exact;
  }
`;

const GoalListItem = (props) => {
  const {
    title = "",
    fixed = 2,
    description = null,
    currentPercentage = 0,
    maxPercentage = 100,
    progressColor = "#999",
    barTotalWidth = 150,
    children,
    avatar = null,
    popover = null,
    percentage: isPercentage = true,
    notStarted = false,
    opacity = {},
    appendBeforePercentage,
  } = props;

  const openInsight = getValidFunc(props.openInsight);

  const windowSize = useWindowSize();
  const screenWidth = windowSize[0];

  const vCurrentPercentage = !isNaN(currentPercentage) ? currentPercentage : 0;
  const vMaxPercentage = !isNaN(maxPercentage) ? maxPercentage : 100;
  const vBarTotalWidth = !isNaN(barTotalWidth) ? barTotalWidth : 150;

  let progressWidth =
    (parseFloat(vBarTotalWidth) * parseFloat(vCurrentPercentage)) /
    vMaxPercentage;
  progressWidth =
    progressWidth > vBarTotalWidth ? vBarTotalWidth : progressWidth;

  const displayWidth = progressWidth > 0 ? progressWidth : 0;

  const oveflowTextStyles = {
    display: "flex",
    alignItems: "center",
    textOverflow: "ellipsis",
    whiteSpace: "pre-wrap",
    maxWidth: screenWidth < 1400 ? "250px" : "420px",
  };

  const Infos = () => (
    <>
      <div className="title-goal-item" title={title} style={oveflowTextStyles}>
        <span>{title}</span>
      </div>
      {description && (
        <div className="description-goal-item " title={description}>
          {description}
        </div>
      )}
    </>
  );

  const MetaInfo = () => (
    <div className="info">
      {popover ? (
        <OverlayTrigger placement="bottom" overlay={popover}>
          <div style={{ cursor: "pointer" }}>
            <Infos />
          </div>
        </OverlayTrigger>
      ) : (
        <Infos />
      )}
    </div>
  );

  const Percentage = () => (
    <>
      {appendBeforePercentage && appendBeforePercentage}
      <div
        className="percentage"
        style={{ color: notStarted ? "#ccc" : "#333", textAlign: "right" }}
      >
        <b>
          {getToFixed(vCurrentPercentage, fixed)}
          {isPercentage && "%"}
        </b>
      </div>
    </>
  );

  const PercentageBar = () => (
    <div
      className="bar"
      style={{
        width: vBarTotalWidth,
      }}
    >
      <ProgressBar
        className="progress"
        width={`${displayWidth}px`}
        bg={progressColor}
      />
      <table style={{ width: displayWidth, display: "none" }} id="progress-bar">
        <tr>
          <td style={{ borderTop: `18px solid ${progressColor}` }} />
        </tr>
      </table>
    </div>
  );

  const PercentageInfo = () => {
    return popover ? (
      <OverlayTrigger placement="bottom" overlay={popover}>
        <div style={{ display: "flex" }}>
          <Percentage />
          <PercentageBar />
          {children}
        </div>
      </OverlayTrigger>
    ) : (
      <div style={{ display: "flex" }}>
        <Percentage />
        <PercentageBar />
        {children}
      </div>
    );
  };

  return (
    <div
      className="goal-list-item"
      style={{ opacity, cursor: "pointer" }}
      onClick={openInsight}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {avatar}
        <MetaInfo />
      </div>
      <PercentageInfo />
    </div>
  );
};

export default GoalListItem;
