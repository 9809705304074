import { defineMessages } from "react-intl";
import {
  PEOPLE_BIG_PICTURE,
  PROCESSES_BIG_PICTURE,
  SKILLS_ASSESSMENT,
} from "../../constants/tools.constants";
import { translatedText } from "../translationUtils";

const messages = defineMessages({
  acp_table_text_period: {
    id: "acp_table_text_period",
  },
  acp_table_text_deviation: {
    id: "acp_table_text_deviation",
  },
  acp_table_text_cause: {
    id: "acp_table_text_cause",
  },
  acp_table_text_action: {
    id: "acp_table_text_action",
  },
  acp_table_text_time_limit: {
    id: "acp_table_text_time_limit",
  },
  acp_table_text_status: {
    id: "acp_table_text_status",
  },
  acp_table_text_start: {
    id: "acp_table_text_start",
  },
  acp_table_text_end: {
    id: "acp_table_text_end",
  },
  acp_table_text_new_date: {
    id: "acp_table_text_new_date",
  },
  acp_table_text_conclusion: {
    id: "acp_table_text_conclusion",
  },
  acp_table_text_responsible: {
    id: "acp_table_text_responsible",
  },
  acp_table_text_monitoring: {
    id: "acp_table_text_monitoring",
  },
  acp_error_invalid_responsible: {
    id: "acp_error_invalid_responsible",
  },
  acp_error_invalid_start: {
    id: "acp_error_invalid_start",
  },
  acp_error_invalid_end: {
    id: "acp_error_invalid_end",
  },
  acp_error_invalid_conclusion: {
    id: "acp_error_invalid_conclusion",
  },
  acp_onp_table_text_period: {
    id: "acp_onp_table_text_period",
  },
  acp_onp_table_text_deviation: {
    id: "acp_onp_table_text_deviation",
  },
  acp_onp_table_text_action: {
    id: "acp_onp_table_text_action",
  },
  acp_bp_table_text_period: {
    id: "acp_bp_table_text_period",
  },
  acp_bp_table_text_deviation: {
    id: "acp_bp_table_text_deviation",
  },
  acp_bp_table_text_action: {
    id: "acp_bp_table_text_action",
  },
});

const translation = (id) => translatedText(id, messages);

export const getToolCustomLabels = (toolTypeId) => {
  const defautlLabels = {
    acp_table_text_period: translation("acp_table_text_period"),
    acp_table_text_deviation: translation("acp_table_text_deviation"),
    acp_table_text_cause: translation("acp_table_text_cause"),
    acp_table_text_action: translation("acp_table_text_action"),
    acp_table_text_time_limit: translation("acp_table_text_time_limit"),
    acp_table_text_status: translation("acp_table_text_status"),
    acp_table_text_start: translation("acp_table_text_start"),
    acp_table_text_end: translation("acp_table_text_end"),
    acp_table_text_new_date: translation("acp_table_text_new_date"),
    acp_table_text_conclusion: translation("acp_table_text_conclusion"),
    acp_table_text_responsible: translation("acp_table_text_responsible"),
    acp_table_text_monitoring: translation("acp_table_text_monitoring"),
    acp_error_invalid_responsible: translation("acp_error_invalid_responsible"),
    acp_error_invalid_start: translation("acp_error_invalid_start"),
    acp_error_invalid_end: translation("acp_error_invalid_end"),
    acp_error_invalid_conclusion: translation("acp_error_invalid_conclusion"),
  };

  switch (toolTypeId) {
    case PEOPLE_BIG_PICTURE:
      return {
        ...defautlLabels,
        acp_table_text_period: translation("acp_onp_table_text_period"),
        acp_table_text_deviation: translation("acp_onp_table_text_deviation"),
        acp_table_text_action: translation("acp_onp_table_text_action"),
      };
    case PROCESSES_BIG_PICTURE:
      return {
        ...defautlLabels,
        acp_table_text_period: translation("acp_bp_table_text_period"),
        acp_table_text_deviation: translation("acp_bp_table_text_deviation"),
        acp_table_text_action: translation("acp_bp_table_text_action"),
      };
    case SKILLS_ASSESSMENT:
      return {
        ...defautlLabels,
        acp_table_text_deviation: translation("Desvio/Competência"),
      };
    default:
      return defautlLabels;
  }
};
