/* eslint-disable react/prop-types */
import React from "react";
import { defineMessages } from "react-intl";
import moment from "moment";
import IndicatorDisplayTable from "./includes/IndicatorDisplayTable";
import MaterialTextField from "../../MaterialTextField";
import MaterialSingleSelect from "../../MaterialSingleSelect";
import SimpleLegend from "../../SimpleLegend";
import utils from "../../../../utils/toolUtils";
import kpiUtils from "../../../../utils/kpis";
import { getIndicatorTargetOptions } from "../../../../constants/kpis";
import { getMonthOptions } from "../../../../constants/monthsConstants";
import GoalMetric from "./GoalMetric";

const { getErrorMessage, translatedText } = utils;

const {
  getRelativeDateGoalMessage,
  checkIsIndicatorEditable,
  getGoalDistributionByPeriods,
} = kpiUtils;

const messages = defineMessages({
  kpi_subtitle_goal_measurement: { id: "kpi_subtitle_goal_measurement" },
  kpi_form_data_source: { id: "kpi_form_data_source" },
  kpi_form_general_observations: { id: "kpi_form_general_observations" },
  kpi_form_today: { id: "kpi_form_today" },
  kpi_form_year: { id: "kpi_form_year" },
  kpi_form_initial_month: { id: "kpi_form_initial_month" },
  kpi_form_last_month: { id: "kpi_form_last_month" },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

const Form = (props) => {
  const {
    insight = {},
    goalDescriptions,
    setGoalDescriptions,
    goalTargetConfigurations: targetsConfig,
    SaveButton,
    formErrors = [],
    isAdmin = false,
    blockAllChanges = false,
    exporting,
    zeroWhenNegative,
    kpiAdministration,
    isLoading,
    setIsLoading,
    selectedToolId,
    setConfirmationDialog,
    resetConfirmDialog,
  } = props;

  const today = new Date();

  const goalTargetOptions = getIndicatorTargetOptions();
  const monthOptions = getMonthOptions();

  const IS_CALCULATED = goalDescriptions.relationshipType === "CALCULATED";

  const handleUpdatedGoalPeriod = (value, periodSlug = "start") => {
    const tempUpdatedGoal = {
      ...goalDescriptions,
      period: {
        ...goalDescriptions.period,
        [periodSlug]: value,
      },
    };

    const { goalDistribution } = getGoalDistributionByPeriods(tempUpdatedGoal);

    setGoalDescriptions({
      ...tempUpdatedGoal,
      target: {
        ...goalDescriptions.target,
        distribution: goalDistribution,
      },
    });
  };

  const handleUpdateYear = (newYear) => {
    const newPeriod = {
      ...goalDescriptions.period,
      year: Number(newYear),
    };

    setGoalDescriptions({
      ...goalDescriptions,
      period: newPeriod,
    });
  };

  const isEditingAllowed =
    checkIsIndicatorEditable(
      goalDescriptions.id,
      goalDescriptions.relationshipType,
      isAdmin,
    ) && !blockAllChanges;

  const errorStyle = {
    backgroundColor: "#ff6347",
    color: "#fff",
    padding: "5px",
    height: "24px",
  };

  const labelProps = exporting
    ? { transform: "translate(0px, -9px) scale(1)", fontSize: "10px" }
    : {};

  const showDetailedPeriods =
    !IS_CALCULATED || (IS_CALCULATED && goalDescriptions.selectedToolMetricId);

  return (
    <div className="row">
      {IS_CALCULATED && (
        <GoalMetric
          selectedToolMetricId={goalDescriptions.selectedToolMetricId}
          measurementUnity={goalDescriptions.measurementUnity}
          frequency={goalDescriptions.frequency}
          selectedToolId={selectedToolId}
          kpiAdministration={kpiAdministration}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setConfirmationDialog={setConfirmationDialog}
          resetConfirmDialog={resetConfirmDialog}
        />
      )}
      {showDetailedPeriods && (
        <div>
          <div className="col-xs-12">
            <h6 style={{ marginTop: "0px" }}>
              {translation("kpi_form_today")}: {moment(today).format("LL")}
            </h6>
            {getRelativeDateGoalMessage(
              goalDescriptions.period.year,
              goalDescriptions.period.start,
              goalDescriptions.period.end,
            )}
          </div>
          <div className="col-xs-3">
            <MaterialTextField
              type="number"
              id="yearPeriod"
              value={goalDescriptions.period.year}
              label={translation("kpi_form_year")}
              onChange={(e) =>
                isEditingAllowed
                  ? handleUpdateYear(e.target.value, "year")
                  : () => {}
              }
              inputStyle={!isEditingAllowed ? { cursor: "not-allowed" } : {}}
              labelProps={labelProps}
            />
          </div>
          <div className="col-xs-3">
            <MaterialSingleSelect
              id="startMonth"
              value={goalDescriptions.period.start}
              label={translation("kpi_form_initial_month")}
              options={monthOptions}
              onChange={(e) =>
                isEditingAllowed
                  ? handleUpdatedGoalPeriod(e.target.value, "start")
                  : () => {}
              }
              defaultOption={false}
              notAllowed={!isEditingAllowed}
              labelProps={labelProps}
            />
          </div>
          <div className="col-xs-3">
            <MaterialSingleSelect
              id="endMonth"
              value={goalDescriptions.period.end}
              label={translation("kpi_form_last_month")}
              options={monthOptions}
              onChange={(e) =>
                isEditingAllowed
                  ? handleUpdatedGoalPeriod(e.target.value, "end")
                  : () => {}
              }
              defaultOption={false}
              notAllowed={!isEditingAllowed}
              labelProps={labelProps}
            />
          </div>
          <div className="col-xs-12" style={{ paddingTop: "0px" }}>
            {getErrorMessage(
              ["startDateError", "sameMonthError"],
              formErrors,
              "right",
            )}
          </div>
          <div className="col-xs-12" style={{ paddingTop: "0px" }}>
            {getErrorMessage(
              ["distributionError"],
              formErrors,
              "center",
              errorStyle,
              true,
            )}
          </div>
          <div className="col-xs-12">
            <IndicatorDisplayTable
              goalDescriptions={goalDescriptions}
              setGoalDescriptions={setGoalDescriptions}
              targetsConfig={targetsConfig}
              blockAllChanges={blockAllChanges}
              formErrors={formErrors}
              insight={insight}
              zeroWhenNegative={zeroWhenNegative}
              IS_CALCULATED={IS_CALCULATED}
            />
          </div>
          <div className="col-xs-12" style={{ paddingTop: "0px" }}>
            {getErrorMessage(
              ["distributionError"],
              formErrors,
              "center",
              errorStyle,
              true,
            )}
          </div>
          <div className="col-xs-12">
            {goalTargetOptions.map((targetOptionInfo) => {
              return (
                <SimpleLegend
                  key={targetOptionInfo.label}
                  label={targetOptionInfo.label}
                  fillColor={targetOptionInfo.color}
                />
              );
            })}
          </div>
          <div className="col-xs-12" style={{ paddingTop: "15px" }}>
            <MaterialTextField
              id="sourceDescription"
              multiline
              label={translation("kpi_form_data_source")}
              value={goalDescriptions.sourceDescription}
              labelProps={labelProps}
              onChange={(e) =>
                !blockAllChanges
                  ? setGoalDescriptions({
                      ...goalDescriptions,
                      sourceDescription: e.target.value,
                    })
                  : () => {}
              }
              inputStyle={{
                cursor: blockAllChanges ? "not-allowed" : "auto",
              }}
              primaryInputProps={{ maxLength: 255 }}
            />
          </div>
          <div className="col-xs-12" style={{ paddingTop: "15px" }}>
            <MaterialTextField
              id="generalDetails"
              multiline
              rows={6}
              label={translation("kpi_form_general_observations")}
              value={goalDescriptions.generalDetails}
              onChange={(e) =>
                !blockAllChanges
                  ? setGoalDescriptions({
                      ...goalDescriptions,
                      generalDetails: e.target.value,
                    })
                  : () => {}
              }
              inputStyle={{
                cursor: blockAllChanges ? "not-allowed" : "auto",
              }}
              primaryInputProps={{ maxLength: 1000 }}
              labelProps={labelProps}
            />
          </div>
          <div className="col-xs-12" style={{ paddingTop: "20px" }}>
            {SaveButton && SaveButton}
          </div>
        </div>
      )}
    </div>
  );
};

const GoalEvaluation = (props) => {
  const { onlyForm = false, SaveButton = false } = props;

  return !onlyForm ? (
    <div className="box box-primary">
      <div className="box-header with-border">
        <h3 className="box-title">
          <i className="fas fa-chart-area" />
          {translation("kpi_subtitle_goal_measurement")}
        </h3>
        {SaveButton && SaveButton}
      </div>
      <div className="box-body">
        <Form {...props} />
      </div>
    </div>
  ) : (
    <Form {...props} />
  );
};

export default GoalEvaluation;
