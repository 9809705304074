import update from "immutability-helper";

import {
  FETCH_THEME_QUESTIONS,
  FETCH_ALL_THEMES_QUESTIONS,
  UPDATE_THEME_QUESTION,
  SWAP_ORDER,
  DELETE_THEME_QUESTION,
} from "../actions/types";
import { ENTITY_TYPE } from "../actions/orderedEntityActions";

const INITIAL_STATE = {};

const questionsReducer = (state = INITIAL_STATE, action) => {
  const question =
    action.payload && action.payload.question ? action.payload.question : {};
  const themeId =
    action.payload && action.payload.themeId ? action.payload.themeId : 0;
  const currentStateThemeQuestions = state[themeId] ? state[themeId] : [];

  const updateThemeQuestions = (newQuestion, deleteAction = false) => {
    let finalThemeQuestions = [];
    let didUpdate = false;

    currentStateThemeQuestions.forEach((questionBody) => {
      const isTheSame = questionBody.id === newQuestion.id;

      if (isTheSame) {
        questionBody = newQuestion;
        didUpdate = true;
      }

      if (!isTheSame || (isTheSame && !deleteAction))
        finalThemeQuestions = [...finalThemeQuestions, questionBody];
    });

    if (!didUpdate && !deleteAction)
      finalThemeQuestions = [...finalThemeQuestions, newQuestion];

    return finalThemeQuestions;
  };

  switch (action.type) {
    case FETCH_THEME_QUESTIONS:
      return { ...state, [themeId]: action.payload.questions };
    case FETCH_ALL_THEMES_QUESTIONS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_THEME_QUESTION:
      return {
        ...state,
        [themeId]: updateThemeQuestions(question),
      };
    case SWAP_ORDER:
      if (action.payload.entityType === ENTITY_TYPE.question) {
        return update(state, {
          [action.payload.id]: {
            $set: action.payload.list,
          },
        });
      }

      return state;
    case DELETE_THEME_QUESTION:
      if (question && themeId && state[themeId]) {
        return {
          ...state,
          [themeId]: updateThemeQuestions(question, true),
        };
      }

      return state;
    case "RESET_QUESTIONS":
      return {
        ...state,
        allThemes: {},
      };
    default:
      return state;
  }
};

export default questionsReducer;
