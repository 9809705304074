import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  updateGoalBonusAdministration,
  createGoalsBonusQuestionParticipant,
} from "../../../../actions/goalsAndBonusActions";
import QuestionParticipantSelectDialog from "../../../Common/GoalsAndBonus/forms/QuestionParticipantSelectDialog";
import {
  getSelectedToolStates,
  getGoalsBonusStates,
  getCompanyMembersAndParticipants,
} from "../../../customMapStates";
import gbUtils from "../../../../utils/goalsBonus";
import { useSimpleToolAdministration } from "../../../../utils/goalsBonus/hooks";

const { buildRestAdmConfigBody, getParticipantInfoById } = gbUtils;

const GoalsAndBonus = (props) => {
  const {
    type,
    clearViewCallback,
    selectedTool = {},
    isLoading = false,
    allGoalBonusAdministration = [],
    updateGoalBonusAdministration,
    createGoalsBonusQuestionParticipant,
    allSceneries = [],
    allThemes = [],
    allQuestions = [],
    params = {},
  } = props;

  const { goalBonusAdministration, setGoalBonusAdministration } =
    useSimpleToolAdministration({
      selectedTool,
      allGoalBonusAdministration,
      isLoading,
    });

  const [addedParticipants, setAddedParticipants] = useState([]);

  const { questionTheme = {} } = params;
  const { goalsBonusProgramRoleBonusId = null } = questionTheme;

  useEffect(() => {
    const toolParticipants = [];

    allSceneries.forEach((scenerieInfo) => {
      if (scenerieInfo.selectedTool.id === selectedTool.id) {
        const scenerieThemes = allThemes[scenerieInfo.id] || [];

        scenerieThemes.forEach((themeInfo) => {
          const themeQuestions = allQuestions[themeInfo.id] || [];

          themeQuestions.forEach(({ goalsBonusProgramParticipantId }) => {
            toolParticipants.push(goalsBonusProgramParticipantId);
          });
        });
      }
    });

    if (!_.isEqual(toolParticipants, addedParticipants))
      setAddedParticipants(toolParticipants);
  }, [
    allSceneries,
    allThemes,
    allQuestions,
    selectedTool.id,
    addedParticipants,
  ]);

  const handleUpdateGoalsBonus = (newGoalsBonusAdm) => {
    if (!_.isEqual(goalBonusAdministration, newGoalsBonusAdm)) {
      const restBody = buildRestAdmConfigBody(newGoalsBonusAdm);
      updateGoalBonusAdministration(selectedTool.id, restBody);
    }
  };

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
  };

  const handleSaveQuestion = ({ participantId }) => {
    if (questionTheme && participantId) {
      const participantInfo = getParticipantInfoById(
        participantId,
        goalBonusAdministration.participants,
      );

      createGoalsBonusQuestionParticipant(
        participantInfo,
        questionTheme,
        params.order,
      );
    }
    clearDialogControll();
  };

  return (
    <QuestionParticipantSelectDialog
      open
      afterConfirm={handleSaveQuestion}
      afterCancel={clearDialogControll}
      goalBonusAdministration={goalBonusAdministration}
      setGoalBonusAdministration={setGoalBonusAdministration}
      companyMembersAndParticipants={props.companyMembersAndParticipants}
      alternativeSaveParticipantCallback={handleUpdateGoalsBonus}
      addedParticipants={addedParticipants}
      goalsBonusProgramRoleBonusId={goalsBonusProgramRoleBonusId}
    />
  );
};

const mapStateToProps = (state) => {
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);
  const { selectedTool, allSceneries, allThemes, allQuestions } =
    getSelectedToolStates(state);
  const { allGoalBonusAdministration, isLoading } = getGoalsBonusStates(state);

  const { user } = state;

  return {
    companyMembersAndParticipants,
    userProfile: user,
    selectedTool,
    isLoading,
    allGoalBonusAdministration,
    allSceneries,
    allThemes,
    allQuestions,
  };
};

export default connect(mapStateToProps, {
  updateGoalBonusAdministration,
  createGoalsBonusQuestionParticipant,
})(GoalsAndBonus);
