const en = {
  insight_responsible_change:
    "''{senderName}'' placed you as responsible for the insight ''{insightName}''",
  new_insight_comment:
    "''{senderName}'' commented on insight ''{insightName}''",
  insight_prioritization_change:
    "''{senderName}'' added a new prioritization to the insight ''{insightName}''",
  insight_connection_change:
    "''{senderName}'' added a new connection to the insight ''{insightName}''",
  insight_attachment_change:
    "''{senderName}'' added a attachment to the insight ''{insightName}''",

  company_new_admin:
    "''{senderName}'' placed you as administrator for the company ''{companyName}''",
  company_new_workspace:
    "''{senderName}'' added a new workspace to the company ''{companyName}''",

  workspace_new_moderator:
    "''{senderName}'' placed you as moderator for the workspace ''{workspaceName}''",
  workspace_new_tool:
    "''{senderName}'' added a new tool to the workspace ''{workspaceName}''",

  tool_new_manager:
    "''{senderName}'' placed you as manager for the tool ''{toolName}''",
  tool_new_viewer:
    "''{senderName}'' placed you as viewer for the tool ''{toolName}''",

  scenery_new_leader:
    "''{senderName}'' placed you as leader for the scenery ''{sceneryName}''",
  scenery_new_viewer:
    "''{senderName}'' placed you as viewer for the scenery ''{sceneryName}''",
  insight_new_mention:
    "''{senderName}'' mentioned you in the insight ''{insightName}''",
};

const es = {
  insight_responsible_change:
    "''{senderName}'' te colocó como responsable de lo insight ''{insightName}''",
  new_insight_comment:
    "''{senderName}'' comentó sobre lo insight ''{insightName}''",
  insight_prioritization_change:
    "''{senderName}'' agregó una nueva priorización a lo insight ''{insightName}''",
  insight_connection_change:
    "''{senderName}'' agregó una nueva conexión a lo insight ''{insightName}''",
  insight_attachment_change:
    "''{senderName}'' agregó un archivo adjunto a lo insight ''{insightName}''",

  company_new_admin:
    "''{senderName}'' te colocó como administrador de la empresa ''{companyName}''",
  company_new_workspace:
    "''{senderName}'' agregó un nuevo workspace a la empresa ''{companyName}''",

  workspace_new_moderator:
    "''{senderName}'' te colocó como moderador de lo workspace ''{workspaceName}''",
  workspace_new_tool:
    "''{senderName}'' agregó una nueva herramienta a lo workspace ''{workspaceName}''",

  tool_new_manager:
    "''{senderName}'' te colocó como moderador de la herramienta ''{toolName}''",
  tool_new_viewer:
    "''{senderName}'' te colocó como espectador de la herramienta ''{toolName}''",

  scenery_new_leader:
    "''{senderName}'' te colocó como líder de lo escenario ''{sceneryName}''",
  scenery_new_viewer:
    "''{senderName}'' te colocó como espectador de lo escenario ''{sceneryName}''",
  insight_new_mention:
    "''{senderName}'' te mencionó en el insight ''{insightName}''",
};

const pt = {
  insight_responsible_change:
    "''{senderName}'' nomeou você como responsável do insight ''{insightName}''",
  new_insight_comment: "''{senderName}'' comentou no insight ''{insightName}''",
  insight_prioritization_change:
    "''{senderName}'' adicionou uma nova priorização ao insight ''{insightName}''",
  insight_connection_change:
    "''{senderName}'' adicionou uma nova conexão no insight ''{insightName}''",
  insight_attachment_change:
    "''{senderName}'' adicionou um anexo no insight ''{insightName}''",

  company_new_admin:
    "''{senderName}'' nomeou você como administrador da empresa  ''{companyName}''",
  company_new_workspace:
    "''{senderName}'' adicionou um novo workspace na empresa ''{companyName}''",

  workspace_new_moderator:
    "''{senderName}'' nomeou você como moderador do workspace ''{workspaceName}''",
  workspace_new_tool:
    "''{senderName}'' adicionou uma nova ferramenta no workspace ''{workspaceName}''",

  tool_new_manager:
    "''{senderName}'' nomeou você como gestor da ferramenta ''{toolName}''",
  tool_new_viewer:
    "''{senderName}'' nomeou você como visualizador da ferramenta ''{toolName}''",

  scenery_new_leader:
    "''{senderName}'' nomeou você como líder do cenário ''{sceneryName}''",
  scenery_new_viewer:
    "''{senderName}'' nomeou você como visualizador do cenário ''{sceneryName}''",
  insight_new_mention:
    "''{senderName}'' mencionou você no insight ''{insightName}''",
};

const translations = { en, es, pt };

export default translations;
