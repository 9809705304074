import {
  FETCH_ALL_ACTIVITY_INFORMATION,
  UPDATE_ACTIVITY_INFORMATION,
} from "../actions/types";

import { updateAllList } from "../utils/reducerUtils";

const activityInformationReducer = (state = null, action) => {
  const { payload } = action;

  const actualAllList = state && state.all ? state.all : [];

  const updateAllEvaluation = (insightID) => {
    let finalAllList = [];
    let didUpdate = false;

    actualAllList.forEach((itemBody) => {
      if (itemBody.insightId === insightID) {
        didUpdate = true;
      }

      finalAllList = [
        ...finalAllList,
        { ...itemBody, activityInformation: payload.activityInformation },
      ];
    });

    if (!didUpdate)
      finalAllList = [
        ...finalAllList,
        {
          insightId: insightID,
          activityInformation: payload.activityInformation,
        },
      ];

    return finalAllList;
  };

  switch (action.type) {
    case FETCH_ALL_ACTIVITY_INFORMATION:
      return {
        ...state,
        all: updateAllList(actualAllList, action.payload, "insightId"),
      };
    case UPDATE_ACTIVITY_INFORMATION:
      return { ...state, all: updateAllEvaluation(payload.insightID) };
    default:
      return state;
  }
};

export default activityInformationReducer;
