import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import FormDialog from "../../../../../../Common/FormDialog";
import MaterialTextField from "../../../../../../Common/MaterialTextField";
import CicleRegister from "../CicleRegister";
import { AlertStyle } from "../../css/OkrCss";

import {
  configPatternCycle,
  configCycleBaseBody,
} from "../../../../../../../utils/okr/entity";
import {
  deleteCycleBasePatternCycle,
  postCycleBase,
  postPatterns,
  createPatternCycle,
  putPatterns,
} from "../../../../../../../actions/okrActions";

const defaultCycleBase = configCycleBaseBody();
const defaultCycle = configPatternCycle();
const defaultFormError = {
  titleError: "",
  cycleError: "",
  noCyclesError: "",
};

function BaseForm(props) {
  const [cycleBase, setCycleBase] = useState(defaultCycleBase);
  const [formErrors, setFormErrors] = useState(defaultFormError);

  const {
    intl,
    open,
    idConfig,
    getDefaultRemoveColumn,
    getDefaultAddTableLine,
    openFormDelete,
    onCancel,
    postCycleBase,
    isEditing,
    formatDateCycles,
    putPatterns,
    postPatterns,
    cycleBaseId,
  } = props;

  useEffect(() => {
    if (isEditing && isEditing.params && isEditing.params.patternInfo) {
      const editingBasePattern = isEditing.params.patternInfo;

      const onlyNumber = (title) => title.replace(/\D/g, "");

      if (editingBasePattern) {
        const editingPatternCycles = editingBasePattern.cycles;
        const reorderedCycles = [...editingPatternCycles].sort((a, b) => {
          return Number(onlyNumber(a.title)) > Number(onlyNumber(b.title))
            ? 1
            : -1;
        });

        setCycleBase({ ...editingBasePattern, cycles: reorderedCycles });
      }
    }
  }, [isEditing]);

  async function handleAddCycles(index) {
    function createNewCycles(cycle, removeIndex = false) {
      const newCycles = [];

      let cycleObject = {};

      if (removeIndex) {
        cycleObject = {
          ...defaultCycle,
          id: cycle.id,
        };
      } else {
        cycleObject = {
          ...defaultCycle,
          index,
        };
      }

      newCycles.push(...cycleBase.cycles, cycleObject);

      setCycleBase({ ...cycleBase, cycles: newCycles });
    }

    if (cycleBase.id) {
      const patternCycle = await createPatternCycle(defaultCycle, cycleBase.id);
      const removeIndex = true;

      createNewCycles(patternCycle, removeIndex);
    } else {
      createNewCycles(defaultCycle);
    }
  }

  function handleDeleteCicle(index, relatedTheme, patternCycleId) {
    function getAndDeleteCycleGroups(infoCompare, slugCompare = "") {
      const cycles = cycleBase.cycles.filter(
        (cycle) => cycle[slugCompare] !== infoCompare,
      );
      setCycleBase({ ...cycleBase, cycles });
    }

    if (!relatedTheme.id && !patternCycleId) {
      getAndDeleteCycleGroups(index, "index");
    } else {
      deleteCycleBasePatternCycle(patternCycleId);
      getAndDeleteCycleGroups(patternCycleId, "id");
    }
  }

  function handleCicle(index, name, value, id) {
    let updatedCycles = [];

    if (!id) {
      updatedCycles = cycleBase.cycles.map((cycle) => {
        return cycle.index === index
          ? { ...cycle, [name]: value }
          : { ...cycle };
      });
    } else {
      updatedCycles = cycleBase.cycles.map((cycle) => {
        return cycle.id === id ? { ...cycle, [name]: value } : { ...cycle };
      });
    }

    setCycleBase({ ...cycleBase, cycles: updatedCycles });
  }

  function filterEmptyCycle(next) {
    let functionResult = null;

    cycleBase.cycles.forEach(({ id, title, startDate, endDate, checkDate }) => {
      if (
        title === "" ||
        title.length < 3 ||
        startDate === "" ||
        endDate === "" ||
        checkDate === ""
      ) {
        functionResult = next(id);
      }
    });

    return functionResult;
  }

  function validForm() {
    let titleError = "";
    let cycleError = "";
    let noCyclesError = "";

    function getAlertMessage() {
      return intl.formatMessage({ id: "all_inputs_alert" });
    }

    if (cycleBase.title === "") {
      titleError = intl.formatMessage({ id: "all_inputs_alert" });
    }

    if (cycleBase.cycles.length) {
      cycleError = filterEmptyCycle(getAlertMessage);
    } else if (!cycleBase.id) {
      noCyclesError = intl.formatMessage({ id: "no_cycle_added" });
    }

    if (titleError || cycleError || noCyclesError) {
      setFormErrors({ titleError, cycleError, noCyclesError });

      return false;
    }

    return true;
  }

  function handleCycleGroups(name, value) {
    setCycleBase({ ...cycleBase, [name]: value });
  }

  function deleteCycleEmptyOnCancel() {
    filterEmptyCycle(deleteCycleBasePatternCycle);
  }

  function handleCancel() {
    deleteCycleEmptyOnCancel();
    setCycleBase(defaultCycleBase);
    onCancel("baseModal", false);
  }

  async function handleConfirm() {
    if (validForm()) {
      const formatedCycles = await formatDateCycles([cycleBase]);

      if (!cycleBaseId) {
        const pattern = {
          patterns: formatedCycles,
        };

        postCycleBase(idConfig, pattern);
      } else if (!cycleBase.id) {
        postPatterns(cycleBaseId, formatedCycles[0]);
      } else {
        putPatterns(formatedCycles[0].cycles, formatedCycles[0]);
      }

      handleCancel();
    }
  }

  return (
    <FormDialog
      title={
        !cycleBase.id
          ? intl.formatMessage({ id: "add_cycle" })
          : intl.formatMessage({ id: "edit_cycle" })
      }
      open={open}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    >
      {(formErrors.titleError ||
        formErrors.cycleError ||
        formErrors.noCyclesError) && (
        <AlertStyle severity="error">
          {<span>{formErrors.titleError}</span>}
          {<span>{formErrors.cycleError}</span>}
          {<span>{formErrors.noCyclesError}</span>}
        </AlertStyle>
      )}
      <MaterialTextField
        inputStyle={{ width: "200px" }}
        id="name-base"
        type="text"
        fullWidth={false}
        label={intl.formatMessage({ id: "pattern_name" })}
        size="small"
        value={cycleBase.title}
        onChange={(e) => handleCycleGroups("title", e.target.value)}
      />
      <CicleRegister
        cycles={cycleBase.cycles}
        addCicle={handleAddCycles}
        editCicle
        handleDeleteCicle={handleDeleteCicle}
        removeCollumn={getDefaultRemoveColumn}
        addTableLine={getDefaultAddTableLine}
        openFormDelete={openFormDelete}
        handleCicle={handleCicle}
      />
    </FormDialog>
  );
}

const mapDispatchToProps = {
  postCycleBase,
  postPatterns,
  putPatterns,
};

export default connect(null, mapDispatchToProps)(injectIntl(BaseForm));

BaseForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  idConfig: PropTypes.number.isRequired,
  cycleBaseId: PropTypes.number.isRequired,
  getDefaultRemoveColumn: PropTypes.func.isRequired,
  getDefaultAddTableLine: PropTypes.func.isRequired,
  openFormDelete: PropTypes.func.isRequired,
  postCycleBase: PropTypes.func.isRequired,
  formatDateCycles: PropTypes.func.isRequired,
  putPatterns: PropTypes.func.isRequired,
  postPatterns: PropTypes.func.isRequired,
  isEditing: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};
