import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import {
  deleteAttachment,
  getInsightAttachments,
  addManyAttachment,
} from "../../actions/attachmentActions";

import Upload from "../Common/Upload";
import FileList from "../Common/FileList";
import DeleteDialog from "../Common/DeleteDialog";

import { doDownload } from "../../utils/attachmentsUtils";
import { globalMessages } from "../../utils/global";

const defaultCentralizedAttachments = {
  ACTION_PLAN: [],
  INSIGHT: [],
  COMMENT: [],
};

const InsightAttachmentsContainer = ({
  insightId,
  openModalAttachment,
  insightAttachments,
  addManyAttachment,
  getInsightAttachments,
  intl,
  deleteAttachment,
}) => {
  const [centralizedAttachments, setCentralizedAttachments] = useState(
    defaultCentralizedAttachments,
  );
  const [selectedAttachment, setSelectedAttachment] = useState(false);

  useEffect(() => {
    if (insightId) {
      getInsightAttachments(insightId);
    }

    return () => {
      setCentralizedAttachments(defaultCentralizedAttachments);
    };
  }, [insightId, getInsightAttachments]);

  const entityName = intl.formatMessage(globalMessages.file);

  const doAdd = useCallback(
    async (bodyAttachments) => {
      const bodyAddAttachment = {
        entityId: insightId,
        entityType: "INSIGHT",
        relatedInsightId: insightId,
      };

      addManyAttachment(bodyAttachments, bodyAddAttachment, insightId);

      setCentralizedAttachments({
        ...centralizedAttachments,
        INSIGHT: [...centralizedAttachments.INSIGHT, ...bodyAttachments],
      });
    },
    [insightId, addManyAttachment, centralizedAttachments],
  );

  const getAttachment = (attachment) => {
    setSelectedAttachment(attachment);
  };

  const deleteSelectedAttachment = () => {
    setCentralizedAttachments({
      ...centralizedAttachments,
      [selectedAttachment.entity]: centralizedAttachments[
        selectedAttachment.entity
      ].filter((info) => selectedAttachment.id !== info.id),
    });

    deleteAttachment(selectedAttachment.id, insightId);
    setSelectedAttachment(null);
  };

  return (
    <div style={{ padding: "0 33px" }}>
      <DeleteDialog
        show={!!selectedAttachment?.id}
        onCancel={() => setSelectedAttachment(null)}
        entityName={entityName}
        onConfirm={deleteSelectedAttachment}
      />
      <Upload
        addNewAttachment={openModalAttachment}
        onAdd={doAdd}
        onDelete={deleteAttachment}
        title="Insight"
        attachments={insightAttachments.INSIGHT}
      />
      {!!insightAttachments.ACTION_PLAN?.length && (
        <div>
          <h4>Plano de ações</h4>
          <FileList
            files={insightAttachments.ACTION_PLAN}
            onDelete={getAttachment}
            onDownload={doDownload}
          />
        </div>
      )}
      {!!insightAttachments.COMMENT?.length && (
        <div>
          <h4>Comentários</h4>
          <FileList
            files={insightAttachments.COMMENT}
            onDelete={getAttachment}
            onDownload={doDownload}
          />
        </div>
      )}
    </div>
  );
};

InsightAttachmentsContainer.propTypes = {
  insightId: PropTypes.number.isRequired,
  openModalAttachment: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  addManyAttachment,
  getInsightAttachments,
  deleteAttachment,
};

export default connect(
  null,
  mapDispatchToProps,
)(injectIntl(InsightAttachmentsContainer));
