import React from "react";

import { lighten, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const ProgressBar = withStyles((theme) => ({
  root: {
    width: ({ widthBar = "80%" }) => widthBar,
    height: ({ heightBar = 7 }) => heightBar,
  },
  colorPrimary: {
    backgroundColor: ({ backgroundColor = theme.palette.primary }) =>
      `${lighten(backgroundColor, 0.5)} !important`,
  },
  barColorPrimary: {
    backgroundColor: ({ backgroundColor = theme.palette.primary }) =>
      `${backgroundColor} !important`,
  },
  bar: {
    borderRadius: "3px",
  },
}))((props) => {
  return <LinearProgress {...props} />;
});

const BadgeProgressBar = ({
  widthBar,
  heightBar,
  leftText,
  barColor,
  backgroundColor = "rgb(26, 118, 210)",
  borderRadius = false,
  leftBorderRadius = false,
  rightBorderRadius = false,
}) => {
  const colors = {
    red: "rgb(255, 0, 0)",
    green: "rgb(85,174,30)",
    grey: "rgb(215, 215, 215)",
    kanbanRed: "rgb(245, 78, 78)",
    kanbanGreen: "rgb(12, 146, 71)",
  };

  const badgeColor = colors[barColor] || backgroundColor;

  return (
    <ProgressBar
      variant="determinate"
      value={leftText}
      backgroundColor={badgeColor}
      heightBar={heightBar}
      widthBar={widthBar}
      borderRadius={borderRadius}
      leftBorderRadius={leftBorderRadius}
      rightBorderRadius={rightBorderRadius}
    />
  );
};

export default BadgeProgressBar;
