import React from "react";
import { defineMessages } from "react-intl";
import SimplePercentageTable from "../../SimplePercentageTable";
import utils from "../../../../utils/toolUtils";
import actionsPlanUtils from "../../../../utils/actionsPlan";

const { translatedText, getOnlySlugValues } = utils;
const {
  getActionsGroupedByStatus,
  getActionsGroupedByPerformance,
} = actionsPlanUtils;

const messages = defineMessages({
  noDataToDisplay: {
    id: "global.noDataTable",
  },
  acp_status_in_progress: {
    id: "acp_status_in_progress",
  },
  acp_status_concluded: {
    id: "acp_status_concluded",
  },
  acp_status_late: {
    id: "acp_status_late",
  },
  acp_status_on_time: {
    id: "acp_status_on_time",
  },
  acp_status_out_of_time: {
    id: "acp_status_out_of_time",
  },
  acp_status_within_deadline: {
    id: "acp_status_within_deadline",
  },
  acp_table_text_kpis: {
    id: "acp_table_text_kpis",
  },
  acp_table_text_progress: {
    id: "acp_table_text_progress",
  },
  acp_table_text_concluded: {
    id: "acp_table_text_concluded",
  },
  acp_table_text_canceled: {
    id: "acp_table_text_canceled",
  },
  acp_table_text_actions: {
    id: "acp_table_text_actions",
  },
});

const translation = (id) => translatedText(id, messages);

const ActionPlanEvaluations = (props) => {
  const {
    actionsPlan = [],
    overviewTableTitle,
    overviewTableInsightsLabel,
    totalCount = false,
  } = props;

  const displayCount = totalCount !== false ? totalCount : actionsPlan.length;

  const {
    IN_PROGRESS = [],
    COMPLETE = [],
    CANCELED = [],
  } = getActionsGroupedByStatus(actionsPlan);

  const inProgressActions = getOnlySlugValues(IN_PROGRESS, "action");
  const completeActions = getOnlySlugValues(COMPLETE, "action");

  const inProgressGroupedByPerformance = getActionsGroupedByPerformance(
    inProgressActions,
  );
  const completeGroupedByPerformance = getActionsGroupedByPerformance(
    completeActions,
  );

  const total = IN_PROGRESS.length + COMPLETE.length + CANCELED.length;

  return (
    <div className="evaluation-card">
      <div className="card-header">
        <div className="card-title">
          <h4>{overviewTableTitle}</h4>
        </div>
      </div>
      <div style={{ padding: "20px 15px 20px 15px" }} className="row">
        <div
          style={{ display: "flex" }}
          className="col-xs-12 col-md-6 col-lg-4"
        >
          <div style={{ width: "100%" }}>
            <h5 style={{ margin: "20px 0px 5px 0px" }} align="center">
              TOTAL ({translation("acp_table_text_actions")}: {total})
            </h5>
            <table
              className="simple-table actions-plan-evaluation"
              style={{ marginTop: "0px", fontSize: "8px" }}
            >
              <tbody>
                <tr className="block-hover">
                  <td width="25%">{overviewTableInsightsLabel}</td>
                  <td width="25%">{translation("acp_table_text_progress")}</td>
                  <td width="25%">{translation("acp_table_text_concluded")}</td>
                  <td width="25%">{translation("acp_table_text_canceled")}</td>
                </tr>
                <tr className="block-hover">
                  <td className="evaluation-col">{displayCount}</td>
                  <td className="evaluation-col">{IN_PROGRESS.length}</td>
                  <td className="evaluation-col">{COMPLETE.length}</td>
                  <td className="evaluation-col">{CANCELED.length}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          style={{ paddingTop: "10px" }}
          className="col-xs-12 col-md-6 col-lg-4"
        >
          <SimplePercentageTable
            title={translation("acp_status_in_progress")}
            primaryText={translation("acp_status_on_time")}
            primaryCount={inProgressGroupedByPerformance.WITHIN_DEADLINE.length}
            secondaryText={translation("acp_status_late")}
            secondaryCount={inProgressGroupedByPerformance.OUT.length}
          />
        </div>
        <div
          style={{ paddingTop: "10px" }}
          className="col-xs-12 col-md-6 col-lg-4"
        >
          <SimplePercentageTable
            title={translation("acp_status_concluded")}
            primaryText={translation("acp_status_within_deadline")}
            primaryCount={completeGroupedByPerformance.WITHIN_DEADLINE.length}
            secondaryText={translation("acp_status_out_of_time")}
            secondaryCount={completeGroupedByPerformance.OUT.length}
          />
        </div>
      </div>
    </div>
  );
};

export default ActionPlanEvaluations;
