import React from "react";

import NewAdministrativeRoleDialog from "../../../../../Common/GoalsAndBonus/forms/NewAdministrativeRoleDialog";
import NewGoalDialog from "../../../../../Common/GoalsAndBonus/forms/NewGoalDialog";
import NewLinkedIndicator from "../../../../../Common/GoalsAndBonus/forms/NewLinkedIndicator";
import NewGoalMiddlewareDialog from "../../../../../Common/GoalsAndBonus/forms/NewGoalMiddlewareDialog";
import NewGoalsBonusTeamDialog from "../../../../../Common/GoalsAndBonus/forms/NewGoalsBonusTeamDialog";
import NewMultipleParticipants from "../../../../../Common/GoalsAndBonus/forms/NewMultipleParticipants";
import NewMultipleRoles from "../../../../../Common/GoalsAndBonus/forms/NewMultipleRoles";
import NewRoleBonusDialog from "../../../../../Common/GoalsAndBonus/forms/NewRoleBonusDialog";
import NewTriggerDialog from "../../../../../Common/GoalsAndBonus/forms/NewTriggerDialog";

const DisplayOpenForms = (props) => {
  const { essencialProps } = props;

  const {
    openFormDialogs,
    resetEditingDialog,
    closeFormAndCallback,
    toggleOpenFormDialogs,
    handleAutoSave,
    closeAllDialogs,
  } = essencialProps;

  const resetFormAndSave = (newGoalsBonusConfig) => {
    resetEditingDialog();

    handleAutoSave(newGoalsBonusConfig);
  };

  return (
    <>
      {openFormDialogs.indexOf("newRoleBonus") > -1 && (
        <NewMultipleRoles
          open
          afterConfirm={(newGoalsBonusConfig) =>
            closeFormAndCallback("newRoleBonus", () =>
              resetFormAndSave(newGoalsBonusConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback("newRoleBonus", resetEditingDialog)
          }
          {...essencialProps}
        />
      )}
      {openFormDialogs.indexOf("editRoleBonus") > -1 && (
        <NewRoleBonusDialog
          open
          afterConfirm={(newGoalsBonusConfig) =>
            closeFormAndCallback("editRoleBonus", () =>
              resetFormAndSave(newGoalsBonusConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback("editRoleBonus", resetEditingDialog)
          }
          {...essencialProps}
        />
      )}
      {openFormDialogs.indexOf("newParticipant") > -1 && (
        <NewMultipleParticipants
          open
          afterConfirm={(newGoalsBonusConfig) =>
            closeFormAndCallback("newParticipant", () =>
              resetFormAndSave(newGoalsBonusConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback("newParticipant", resetEditingDialog)
          }
          {...essencialProps}
        />
      )}
      {openFormDialogs.indexOf("newAdministrativeRole") > -1 && (
        <NewAdministrativeRoleDialog
          open
          afterConfirm={(newGoalsBonusConfig) =>
            closeFormAndCallback("newAdministrativeRole", () =>
              resetFormAndSave(newGoalsBonusConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback("newAdministrativeRole", resetEditingDialog)
          }
          {...essencialProps}
        />
      )}
      {openFormDialogs.indexOf("newGoal") > -1 && (
        <NewGoalDialog
          open
          afterConfirm={() => closeAllDialogs()}
          afterCancel={() => closeAllDialogs()}
          enableNewParticipants
          {...essencialProps}
        />
      )}
      {openFormDialogs.indexOf("newLinkedGoal") > -1 && (
        <NewLinkedIndicator
          open
          afterConfirm={() => closeAllDialogs()}
          afterCancel={() => closeAllDialogs()}
          enableNewParticipants
          {...essencialProps}
        />
      )}
      {openFormDialogs.indexOf("newGoalMiddleware") > -1 && (
        <NewGoalMiddlewareDialog
          open
          toggleOpenFormDialogs={toggleOpenFormDialogs}
          closeDialog={() => {
            closeFormAndCallback("newGoalMiddleware");
          }}
          {...essencialProps}
        />
      )}
      {openFormDialogs.indexOf("newTrigger") > -1 && (
        <NewTriggerDialog
          open
          afterConfirm={(newGoalsBonusConfig) =>
            closeFormAndCallback("newTrigger", () =>
              resetFormAndSave(newGoalsBonusConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback("newTrigger", resetEditingDialog)
          }
          {...essencialProps}
        />
      )}
      {openFormDialogs.indexOf("newTeam") > -1 && (
        <NewGoalsBonusTeamDialog
          open
          afterConfirm={(newGoalsBonusConfig) =>
            closeFormAndCallback("newTeam", () =>
              resetFormAndSave(newGoalsBonusConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback("newTeam", resetEditingDialog)
          }
          {...essencialProps}
        />
      )}
    </>
  );
};

export default DisplayOpenForms;
