import moment from "moment";
import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { injectIntl, defineMessages } from "react-intl";
import Dialog from "react-bootstrap-dialog";

import {
  getSelectedCompanyStates,
  insightsGoalState,
  getSelectedToolStates,
  getCompanyMembersAndParticipants,
} from "../customMapStates";
import { PEOPLE_BIG_PICTURE } from "../../constants/tools.constants";

import InsightView from "./InsightView";

import {
  updateInsightData,
  updateEvaluations,
  updateInsightResponsible,
  fetchInsightFollowUp,
  addInsightFollowUp,
  updateInsightFollowUp,
  deleteInsightFollowUp,
} from "../../actions/insightActions";

import {
  fetchAnswerTags,
  onAddTag,
  onRemoveTag,
} from "../../actions/answersActions";
import { fetchCenaryMembers } from "../../actions/cenaryActions";

import { parseDate } from "../../utils/DateUtils";
import {
  postTraceability,
  updateInsightTraceabilityData,
  deleteTraceability,
} from "../../actions/insightTraceabilitiesActions";
import { globalMessages } from "../../utils/global";
import ActionButton from "../Common/ActionButtons/ActionButton";
import ActionButtons from "../Common/ActionButtons/ActionButtons";

class InsightContainer extends Component {
  constructor(props) {
    super(props);

    this.insightFollowUpMessages = defineMessages({
      labelDescription: {
        id: "insight.followUp.label.description",
      },
      labelBeginDate: {
        id: "insight.followUp.label.beginDate",
      },
      labelEndDate: {
        id: "insight.followUp.label.endDate",
      },
      labelStatus: {
        id: "insight.followUp.label.status",
      },
      statusOriginal: {
        id: "insight.followUp.status.original",
      },
      global_comments: {
        id: "global.comments",
      },
      statusReprogrammed: {
        id: "insight.followUp.status.reprogrammed",
      },
      statusNoDate: {
        id: "insight.followUp.status.noDate",
      },
    });

    this.state = {
      connectedInsights: [],
      showFollowUpForm: false,
    };
  }

  componentDidMount() {
    const { selectedInsight } = this.props;

    if (selectedInsight && selectedInsight.id) {
      /* ProjectRefactoring
      if (selectedInsight.question) {
        this.props.fetchCenaryMembers(selectedInsight.question.theme.cenary.id);
      }
      */
      this.props.fetchInsightFollowUp(selectedInsight.id);
      this.props.fetchAnswerTags(selectedInsight.id);
    }
  }

  handleAddNewResponsible(user) {
    const { selectedTool } = this.props;
    const { tool } = selectedTool;

    const keepInsightAvatar = tool.id === PEOPLE_BIG_PICTURE;

    this.props.updateInsightResponsible(
      this.props.selectedInsight,
      user,
      keepInsightAvatar,
    );
  }

  handleUpdateInsightFollowup(followup) {
    const updatedFollowup = { ...followup };

    if (updatedFollowup.beginDate) {
      updatedFollowup.beginDate = parseDate(updatedFollowup.beginDate);
    }
    if (updatedFollowup.endDate) {
      updatedFollowup.endDate = parseDate(updatedFollowup.endDate);
    }
    this.props.updateInsightFollowUp(
      this.props.selectedInsight.id,
      updatedFollowup,
    );
  }

  handleSubmitFollowUpForm(receivedFollowUp, insightFollowUps = []) {
    const newFollowUp = {
      ...receivedFollowUp,
      beginDate: moment(
        receivedFollowUp.beginDate,
        moment.HTML5_FMT.DATETIME_LOCAL_MS,
      ).format(),
      endDate: moment(
        receivedFollowUp.endDate,
        moment.HTML5_FMT.DATETIME_LOCAL_MS,
      ).format(),
      status: insightFollowUps.length === 0 ? "ORIGINAL" : "REPROGRAMADA",
    };
    this.props.addInsightFollowUp(this.props.selectedInsight.id, newFollowUp);
    this.toggleFollowUpForm(false);
  }

  onUpdateInsight(updatedInsight) {
    this.props.updateInsightData(updatedInsight);
  }

  onUpdateEvaluations(insight, evaluations) {
    this.props.updateEvaluations(insight, evaluations);
  }

  onConfirmFollowUpDelete(followUp) {
    this.props.deleteInsightFollowUp(this.props.selectedInsight.id, followUp);
  }

  showDeleteFollowUpDialog(followUp) {
    const { intl } = this.props;
    const actionText = intl.formatMessage(globalMessages.delete);
    const followUpText = intl.formatMessage(globalMessages.followup);
    const cancelLabel = intl.formatMessage(globalMessages.cancel);

    this.confirmDeleteFollowUpDialog.show({
      title: intl.formatMessage(globalMessages.entityConfirmationAction, {
        action: actionText,
        entity: followUpText,
      }),
      body: intl.formatMessage(globalMessages.deleteConfirmation),
      actions: [
        Dialog.OKAction(() => this.onConfirmFollowUpDelete(followUp)),
        Dialog.Action(cancelLabel, () => {}, "btn-default"),
      ],
      bsSize: "medium",
    });
  }

  deleteButtonFollowUp(cell, row, enumObject, rowIndex, rowLength) {
    const { intl } = this.props;

    return row.status !== "ORIGINAL" || rowLength === 1 ? (
      <ActionButtons>
        <ActionButton
          id="actionBtnExclude"
          bsStyle="danger"
          bsSize="xsmall"
          action={() => this.showDeleteFollowUpDialog(row)}
          tooltip={intl.formatMessage(globalMessages.exclude)}
          iconClass="fa fa-times"
        />
      </ActionButtons>
    ) : null;
  }

  toggleFollowUpForm(bool) {
    this.setState({
      showFollowUpForm: bool,
    });
  }

  doDeleteTraceability(traceabilityId, insightId) {
    this.props.deleteTraceability(traceabilityId, insightId);
  }

  render() {
    const { intl, language, allInsightGoals, insightTraceabilities } =
      this.props;

    const { showFollowUpForm, connectedInsights } = this.state;

    const selectedInsight = this.props.selectedInsight || {};
    selectedInsight.questionId =
      selectedInsight.question && selectedInsight.question.id
        ? selectedInsight.question.id
        : null;

    function filterInsightTraceabilities() {
      return insightTraceabilities &&
        selectedInsight &&
        insightTraceabilities[selectedInsight.id]
        ? insightTraceabilities[selectedInsight.id]
        : [];
    }

    return (
      <div>
        <Dialog
          ref={(ref) => {
            this.confirmDeleteFollowUpDialog = ref;
          }}
        />
        <InsightView
          companyMembersAndParticipants={
            this.props.companyMembersAndParticipants
          }
          getUserAvatar={this.props.getAvatar}
          insight={selectedInsight}
          onUpdateInsight={this.onUpdateInsight.bind(this)}
          onUpdateEvaluations={this.onUpdateEvaluations.bind(this)}
          allInsightFollowUps={this.props.allInsightFollowUps}
          toggleFollowUpForm={this.toggleFollowUpForm.bind(this)}
          showFollowUpForm={showFollowUpForm}
          onSubmitFollowUpForm={this.handleSubmitFollowUpForm.bind(this)}
          onUpdateInsightFollowup={this.handleUpdateInsightFollowup.bind(this)}
          deleteButtonFollowUp={this.deleteButtonFollowUp.bind(this)}
          followUpLabelDescription={intl.formatMessage(
            this.insightFollowUpMessages.labelDescription,
          )}
          followUpLabelBeginDate={intl.formatMessage(
            this.insightFollowUpMessages.labelBeginDate,
          )}
          followUpLabelEndDate={intl.formatMessage(
            this.insightFollowUpMessages.labelEndDate,
          )}
          followUpLabelReason={intl.formatMessage(
            this.insightFollowUpMessages.global_comments,
          )}
          followUpStatusOriginal={intl.formatMessage(
            this.insightFollowUpMessages.statusOriginal,
          )}
          followUpStatusReprogrammed={intl.formatMessage(
            this.insightFollowUpMessages.statusReprogrammed,
          )}
          followUpStatusNoDate={intl.formatMessage(
            this.insightFollowUpMessages.statusNoDate,
          )}
          insightTraceabilities={filterInsightTraceabilities()}
          onDeleteTraceability={this.doDeleteTraceability.bind(this)}
          onAddNewResponsible={this.handleAddNewResponsible
            .bind(this)
            .bind(this)}
          language={language}
          allInsightGoals={allInsightGoals}
          connectedInsights={connectedInsights}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { allCompanyWorkspaces } = getSelectedCompanyStates(state);
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);
  const { allInsightGoals } = insightsGoalState(state);
  const { allAnswers } = getSelectedToolStates(state, { answers: true });
  const {
    avatars,
    selectedTool,
    insightTraceabilities,
    selectedInsight,
    language,
    insightsTimeline,
    insightFollowUps,
    insightTags,
  } = state;
  const { cenaryMembers } = state.cenaries;

  return {
    selectedTool,
    insightTraceabilities,
    selectedInsight: selectedInsight.selectedInsight,
    cenaryMembers,
    language,
    allComments: insightsTimeline.allComments,
    allInsightFollowUps: insightFollowUps.allInsightFollowUps || [],
    allInsightGoals,
    tags: insightTags,
    avatars,
    allCompanyWorkspaces,
    allAnswers,
    companyMembersAndParticipants,
  };
};

export default injectIntl(
  withRouter(
    connect(mapStateToProps, {
      fetchCenaryMembers,
      postTraceability,
      updateInsightData,
      updateEvaluations,
      updateInsightTraceabilityData,
      updateInsightResponsible,
      deleteTraceability,
      fetchInsightFollowUp,
      addInsightFollowUp,
      updateInsightFollowUp,
      deleteInsightFollowUp,
      fetchAnswerTags,
      onAddTag,
      onRemoveTag,
    })(InsightContainer),
  ),
);
