import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import { getZeroWhenNegativeOptions } from "../../../../../../../constants/kpis";
import MaterialSingleSelect from "../../../../../../Common/MaterialSingleSelect";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";

const messages = defineMessages({
  kpi_table_text_target: { id: "kpi_table_text_target" },
  kpi_table_text_performance_scale: { id: "kpi_table_text_performance_scale" },
  kpi_table_text_gate: { id: "kpi_table_text_gate" },
  kpi_table_text_appropriated: { id: "kpi_table_text_appropriated" },
  kpi_table_text_exceeded: { id: "kpi_table_text_exceeded" },
});

const TargetAdministrationTable = (props) => {
  const {
    kpiAdministration,
    setKpiAdministration,
    getDefaultTargetCols,
    intl,
  } = props;

  const handleUpdateGoalsTargets = (value, target) => {
    setKpiAdministration({
      ...kpiAdministration,
      goalTargets: { ...kpiAdministration.goalTargets, [target]: value },
    });
  };

  const handleUpdateZeroWhenNegative = (newValue) => {
    const value = newValue === "ACTIVE";
    setKpiAdministration({
      ...kpiAdministration,
      zeroWhenNegative: value,
    });
  };

  const zeroWhenNegativeOptions = getZeroWhenNegativeOptions();

  return (
    <table className="simple-table" id="targetConfitTable">
      <thead>
        <tr>
          <td className="titled">
            {intl.formatMessage(messages.kpi_table_text_target)}
          </td>
          {getDefaultTargetCols({ width: "auto" })}
        </tr>
      </thead>
      <tbody>
        <tr className="block-hover">
          <td>
            {intl.formatMessage(messages.kpi_table_text_performance_scale)}
          </td>
          <SimpleExcelCollumn
            id="targetGateConfig"
            label={intl.formatMessage(messages.kpi_table_text_gate)}
            value={kpiAdministration.goalTargets.gate}
            type="number"
            onChange={(e) => handleUpdateGoalsTargets(e.target.value, "gate")}
            align="right"
            textEndAdorment="%"
          />
          <SimpleExcelCollumn
            id="targetAppropriatedConfig"
            label={intl.formatMessage(messages.kpi_table_text_appropriated)}
            value={kpiAdministration.goalTargets.appropriated}
            type="number"
            onChange={(e) =>
              handleUpdateGoalsTargets(e.target.value, "appropriated")
            }
            align="right"
            textEndAdorment="%"
          />
          <SimpleExcelCollumn
            id="targetExceededConfig"
            label={intl.formatMessage(messages.kpi_table_text_exceeded)}
            value={kpiAdministration.goalTargets.exceeded}
            type="number"
            onChange={(e) =>
              handleUpdateGoalsTargets(e.target.value, "exceeded")
            }
            align="right"
            textEndAdorment="%"
          />
        </tr>
        <tr>
          <td colSpan="3">Zerar atingimento quando resultado for negativo</td>
          <td style={{ padding: "0px" }}>
            <MaterialSingleSelect
              id="zeroWhenNegative"
              options={zeroWhenNegativeOptions}
              value={
                kpiAdministration.zeroWhenNegative ? "ACTIVE" : "NOT_ACTIVE"
              }
              onChange={(e) => {
                handleUpdateZeroWhenNegative(e.target.value);
              }}
              defaultOption={false}
              formControlStyles={{ margin: "0px" }}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default injectIntl(TargetAdministrationTable);
