import update from "immutability-helper";

import {
  FETCH_WORKSPACE_SELECTED_TOOLS,
  CREATE_SELECTED_WORKSPACE_TOOL,
  UPDATE_WORKSPACE_SELECTED_TOOLS,
  SWAP_ORDER,
} from "../actions/types";

import { ENTITY_TYPE } from "../actions/orderedEntityActions";
import { updateAllList } from "../utils/reducerUtils";

const INITIAL_STATE = [];

const workspaceToolsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_WORKSPACE_SELECTED_TOOLS:
      const index = state.findIndex((tool) => tool.id === action.payload.id);
      if (index === -1) {
        return update(state, { $push: action.payload });
      } else {
        return update(state, {
          [index]: { $merge: { ...action.payload } },
        });
      }
    case FETCH_WORKSPACE_SELECTED_TOOLS:
      return action.payload;
    case CREATE_SELECTED_WORKSPACE_TOOL:
      if (action.payload && action.payload.id) {
        return updateAllList(state, [action.payload], "id");
      }

      return state;
    case SWAP_ORDER:
      if (action.payload.entityType === ENTITY_TYPE.selectedTool) {
        return action.payload.list;
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default workspaceToolsReducer;
