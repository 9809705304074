import { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getGoalsBonusStates } from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";
import gbUtils from "../../../../utils/goalsBonus";
import { updateTheme } from "../../../../actions/themesActions";

const {
  getObjectDifference,
  getObjectInfoById,
  getOnlySlugValues,
  removeObjSlug,
} = utils;

const { buildFormAdmConfigBody, getGoalsBonusBySelectedToolId } = gbUtils;

const defaultFormInitialGoalBonus = buildFormAdmConfigBody({});

const GoalsBonusWatcher = (props) => {
  const [goalBonusAdministration, setGoalBonusAdministration] = useState(
    defaultFormInitialGoalBonus,
  );
  const [firstValidation, setFirstValidation] = useState(false);

  const {
    allGoalBonusAdministration = [],
    selectedTool = {},
    allThemes = {},
    updateTheme,
  } = props;

  useEffect(() => {
    let toolGoalsBonusAdm = getGoalsBonusBySelectedToolId(
      selectedTool.id,
      allGoalBonusAdministration,
    );
    toolGoalsBonusAdm = buildFormAdmConfigBody(toolGoalsBonusAdm);

    const isAdmObjDiff = !_.isEqual(toolGoalsBonusAdm, goalBonusAdministration);

    if (
      toolGoalsBonusAdm &&
      toolGoalsBonusAdm.roleBonuses &&
      Object.keys(allThemes).length > 0
    ) {
      if (isAdmObjDiff || !firstValidation) {
        const compareBase = removeObjSlug(
          goalBonusAdministration.roleBonuses,
          "id",
        );

        const diff = getObjectDifference(
          toolGoalsBonusAdm.roleBonuses,
          compareBase,
        );

        if (diff) {
          if (!firstValidation) setFirstValidation(true);

          const watchRoles = getOnlySlugValues(diff, "id");

          if (watchRoles.length > 0) {
            Object.keys(allThemes).forEach((scenaryId) => {
              const scenaryThemes = allThemes[scenaryId];

              scenaryThemes.forEach((themeInfo) => {
                const { goalsBonusProgramRoleBonusId } = themeInfo;
                const relatedRoleId =
                  watchRoles[
                    watchRoles.indexOf(goalsBonusProgramRoleBonusId)
                  ] || null;

                if (relatedRoleId && themeInfo.name) {
                  const roleInfo = getObjectInfoById(
                    relatedRoleId,
                    toolGoalsBonusAdm.roleBonuses,
                    "id",
                  );

                  if (roleInfo.role !== themeInfo.name) {
                    updateTheme(
                      { ...themeInfo, name: roleInfo.role },
                      themeInfo.cenary.id,
                    );
                  }
                }
              });
            });
          }
        }

        if (isAdmObjDiff) setGoalBonusAdministration(toolGoalsBonusAdm);
      }
    }
  }, [
    allGoalBonusAdministration,
    allThemes,
    firstValidation,
    goalBonusAdministration,
    selectedTool.id,
    updateTheme,
  ]);

  return null;
};

const mapStateToProps = (state) => {
  const { allGoalBonusAdministration } = getGoalsBonusStates(state);

  return { allGoalBonusAdministration };
};

export default connect(mapStateToProps, { updateTheme })(GoalsBonusWatcher);
