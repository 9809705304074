import accessLevelsTranslations from "./accessLevels";
import companyGuidelinesTranslations from "./companyGuidelines";
import companyStructureTranslations from "./companyStructure";
import dialogTranslations from "./dialogs";
import generalTranslations from "./general";
import homeTranslations from "./home";
import logsTranslations from "./logs";
import measurementTranslations from "./measurement";
import notificationTranslations from "./notification";
import structureTranslations from "./structure";
import toolTranslations from "./tools";

const allTranslations = [
  toolTranslations,
  structureTranslations,
  generalTranslations,
  dialogTranslations,
  homeTranslations,
  measurementTranslations,
  accessLevelsTranslations,
  companyStructureTranslations,
  companyGuidelinesTranslations,
  notificationTranslations,
  logsTranslations,
];

export default allTranslations;
