import React from "react";
import PropTypes from "prop-types";

import TeamForm from "./forms/TeamForm";
import BaseForm from "./forms/BaseForm";

const DisplayTeamCicleForms = ({ essencialProps }) => {
  const {
    modalSlug,
    handleOpenModal,
    editingForm,
    okrPlans,
    cycleBase,
  } = essencialProps;

  return (
    <>
      <TeamForm
        open={modalSlug.indexOf("planModal") > -1}
        onCancel={handleOpenModal}
        openCicleForm={handleOpenModal}
        essencialProps={essencialProps}
        {...essencialProps}
        isEditing={editingForm}
        plans={okrPlans}
      />
      <BaseForm
        open={modalSlug.indexOf("baseModal") > -1}
        onCancel={handleOpenModal}
        isEditing={editingForm}
        cycleBaseId={cycleBase.id}
        {...essencialProps}
      />
    </>
  );
};

export default DisplayTeamCicleForms;

DisplayTeamCicleForms.propTypes = {
  essencialProps: PropTypes.object.isRequired,
};
