import {
  FETCH_ACTION_PLANS,
  UPDATE_ACTION_DATE,
  FETCH_ALL_ACTION_PLANS,
  UPDATE_ACTION_DATE_RESPONSIBLE,
  FETCH_ACTION_PLANS_RESPONSIBLES,
} from "../constants/toolActionPlans";

const INITIAL_STATE = {
  toolActions: [],
  responsibleActions: [],
  allOverviewActionPlans: {},
};

const updateToolActions = (toolActions, state) => {
  return state.map((actionPlan) => {
    return {
      ...actionPlan,
      actions: actionPlan.actions.map((action) => {
        if (action.id === toolActions.id) {
          return { ...action, ...toolActions };
        }

        return action;
      }),
    };
  });
};

const toolActionPlansReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ACTION_PLANS:
      return { ...state, toolActions: action.payload };
    case FETCH_ACTION_PLANS_RESPONSIBLES:
      return { ...state, responsibleActions: action.payload };
    case UPDATE_ACTION_DATE:
      return {
        ...state,
        toolActions: updateToolActions(action.payload, state.toolActions),
      };
    case UPDATE_ACTION_DATE_RESPONSIBLE:
      return {
        ...state,
        responsibleActions: updateToolActions(
          action.payload,
          state.responsibleActions,
        ),
      };
    case FETCH_ALL_ACTION_PLANS:
      return {
        ...state,
        allOverviewActionPlans: action.payload,
      };
    default:
      return state;
  }
};

export default toolActionPlansReducer;
