/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { connect } from "react-redux";

import ConfirmationDialog from "../../../../../../Common/ConfirmationDialog";
import FormDialog from "../../../../../../Common/FormDialog";
import SelectCompanyMember from "../../../../../../SelectCompanyMember";
import WorkflowManager from "./WorkflowManager";

import { simpleNotifier } from "../../../../../../../actions/notifierActions";
import {
  fetchSkillAssessmentConfig,
  getSkillAssessmentConfig,
  grantInsightAccessByParticipant,
  putUpdateParticipant,
  putUpdateParticipantWorkflows,
  removeCollaboratorsInsightAccess,
} from "../../../../../../../actions/skillAssessmentActions";
import { getObjectInfoById } from "../../../../../../../utils/ArrayUtils";
import {
  getMaximumScale,
  getMinimumScale,
} from "../../../../../../../utils/skillsAssessment/calc";
import {
  checkComplementaryWorkflowByStage,
  getEvaluationConfigByType,
  getRelativeEvaluationStage,
} from "../../../../../../../utils/skillsAssessment/constants";
import { WorkflowStageMessage } from "../../../../../../../utils/skillsAssessment/display";
import { buildFormSkillsAssessment } from "../../../../../../../utils/skillsAssessment/entity";
import { checkUserRoleForParticipant } from "../../../../../../../utils/skillsAssessment/filter";
import { simpleEditArray } from "../../../../../../../utils/skillsAssessment/manipulation";
import { validateWorkflowActions } from "../../../../../../../utils/skillsAssessment/validation";
import { defaultConfirmDialog } from "../defaults";
import { useSelectedWorkflowParticipant } from "./customHooks/useSelectedWorkflowParticipant";
import { useWorkflowFields } from "./customHooks/useWorkflowFields";

const SelectedWorkflowDialog = ({
  selectedParticipantId,
  selectedToolId,
  toolAdministration,
  filteredAdministration,
  isLoading,
  loggedUser,
  filterStage,
  companyMembersAndParticipants,
  fetchSkillAssessmentConfig,
  grantInsightAccessByParticipant,
  removeCollaboratorsInsightAccess,
  simpleNotifier,
  handleClose,
  enableEvaluatorsSelectionWorkflow,
}) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(defaultConfirmDialog);
  const [successorDialog, setSuccessorDialog] = useState(defaultConfirmDialog);

  const {
    evaluationType,
    evaluationConfig,
    managerConfig,
    composition,
    evaluationScale,
    potentialScale,
    dates,
    transparency,
    generalOptions,
  } = filteredAdministration;

  const {
    changeStage,
    haveChanges,
    selectedParticipant,
    participantWorkflow,
    participantComplementaryWorkflow,
    setParticipantComplementaryWorkflow,
    handleSelectParticipant,
    setParticipantWorkflow,
    syncHistory,
  } = useSelectedWorkflowParticipant({
    toolAdministration: filteredAdministration,
    selectedParticipantId,
  });

  const { enabledFields, enabledActions } = useWorkflowFields({
    selectedParticipant,
    filterStage,
    evaluationType,
    managerConfig,
    composition,
    loggedUser,
    transparency,
  });

  if (!selectedParticipant?.id) return null;

  const { next: nextStage, previous: prevStage } = getRelativeEvaluationStage(
    filterStage,
    evaluationType,
    evaluationConfig,
    enableEvaluatorsSelectionWorkflow
  );

  const { enableSecondEvaluator: enableSEv, enableThirdEvaluator: enableTEv } =
    getEvaluationConfigByType(evaluationType);

  const loggedUserRole = checkUserRoleForParticipant(
    loggedUser,
    selectedParticipant
  );

  const { enableComplementaryEvaluation } = checkComplementaryWorkflowByStage(
    filterStage,
    loggedUserRole,
    loggedUser,
    selectedParticipant
  );

  const getCurrentAdministration = async () => {
    if (selectedToolId) {
      const { data = null } = await getSkillAssessmentConfig(selectedToolId);

      if (data) return buildFormSkillsAssessment(data);
    }

    return toolAdministration;
  };

  const getOriginalSelectedParticipant = async () => {
    const originalAdministration = await getCurrentAdministration();

    return getObjectInfoById(
      selectedParticipant.id,
      originalAdministration.participants
    );
  };

  const resetConfirmDialog = () => {
    setConfirmDialog(defaultConfirmDialog);
  };

  const resetSuccessorDialog = () => {
    setSuccessorDialog(defaultConfirmDialog);
  };

  function handleResetWorkflowDialogs() {
    if (haveChanges) {
      setConfirmDialog({
        open: true,
        title: "Tem certeza que deseja sair sem salvar as alterações",
        onConfirm: () => handleClose(),
        onCancel: resetConfirmDialog,
      });
    } else {
      handleClose();
    }
  }

  const saveCurrentWorkflow = async () => {
    if (selectedParticipant && participantWorkflow && !saveLoading) {
      setSaveLoading(true);

      try {
        await putUpdateParticipantWorkflows({
          selected_tool_id: selectedToolId,
          workflow: participantWorkflow,
          complementaryWorkflow: participantComplementaryWorkflow,
        });
        syncHistory();
        simpleNotifier("Avaliação atualizada", "success");
      } catch (err) {
        simpleNotifier("Erro ao atualizar a avaliação", "error");
      }

      setSaveLoading(false);
      fetchSkillAssessmentConfig(selectedToolId);
    }
  };

  const updateParticipant = async (updatedParticipant) => {
    try {
      await putUpdateParticipant({ updatedParticipant });
    } catch (error) {
      console.error(error);
      simpleNotifier("Erro ao atualizar participante", "error");
    }

    fetchSkillAssessmentConfig(selectedToolId, false);
  };

  const updateWorkflowStatus = async (sendingToStage) => {
    if (sendingToStage && sendingToStage.value && !haveChanges) {
      const originalParticipant = await getOriginalSelectedParticipant();
      const updatedParticipant = {
        ...originalParticipant,
        evaluationStage: sendingToStage.value,
      };

      await updateParticipant(updatedParticipant);
    }
  };

  const handleEvaluatorsUpdate = async (editingField, newValue) => {
    if (editingField) {
      const updatedParticipant = {
        ...selectedParticipant,
        [editingField]: newValue,
        [`${editingField}Id`]: newValue.id,
      };

      grantInsightAccessByParticipant(updatedParticipant);
      removeCollaboratorsInsightAccess(selectedParticipant, updatedParticipant);
      updateParticipant(updatedParticipant);
      handleSelectParticipant(updatedParticipant);
    }
  };

  const handleWorkflowUpdate = (workflowInfo, editingField, newValue) => {
    const { alwaysBlock } = enabledFields[editingField];

    if (
      !alwaysBlock &&
      selectedParticipant.id === workflowInfo.participantId &&
      workflowInfo.id
    ) {
      const updatedWorkflow = simpleEditArray(
        participantWorkflow,
        workflowInfo.id,
        { ...workflowInfo, [editingField]: newValue },
        "id"
      );

      if (Array.isArray(updatedWorkflow))
        setParticipantWorkflow(updatedWorkflow);
    }
  };

  const handleComplementaryWorkflowUpdate = (
    cWorkflowInfo,
    editingField,
    newValue
  ) => {
    if (
      selectedParticipant.id === cWorkflowInfo.participantId &&
      cWorkflowInfo.id
    ) {
      const validNewValue =
        newValue === "empty" || newValue === 0 ? "" : newValue;
      const updatedWorkflow = simpleEditArray(
        participantComplementaryWorkflow,
        cWorkflowInfo.id,
        { ...cWorkflowInfo, [editingField]: validNewValue },
        "id"
      );

      if (Array.isArray(updatedWorkflow))
        setParticipantComplementaryWorkflow(updatedWorkflow);
    }
  };

  const sendToNextStage = () => {
    if (enabledActions.nextStage)
      openUpdateStageConfirm(nextStage, () => updateWorkflowStatus(nextStage));
  };

  const sendToPrevStage = () => {
    if (enabledActions.prevStage)
      openUpdateStageConfirm(prevStage, () => updateWorkflowStatus(prevStage));
  };

  const openUpdateStageConfirm = (sendStage, onConfirm) => {
    if (haveChanges) {
      openSaveChangesConfirm();
      return;
    }

    const confirmAndReset = () => {
      onConfirm();
      resetConfirmDialog();
      handleResetWorkflowDialogs();
    };

    if (onConfirm)
      setConfirmDialog({
        open: true,
        title: `Tem certeza que deseja mudar o estágio de avaliação para ${sendStage.label}?`,
        onConfirm: confirmAndReset,
        onCancel: resetConfirmDialog,
      });
  };

  const openSaveChangesConfirm = () => {
    setConfirmDialog({
      open: true,
      title:
        "Você precisa salvar as alterações antes de alterar o estágio do Workflow.",
      onConfirm: resetConfirmDialog,
      confirmText: "OK",
      hideCancel: true,
    });
  };

  const openSelectSuccessor = (workflowInfo) => {
    setSuccessorDialog({
      open: true,
      title: "Selecione o sucessor",
      onConfirm: (selected) => {
        if (selected && selected[0]) {
          handleComplementaryWorkflowUpdate(
            workflowInfo,
            "alternativeValueUser",
            { ...selected[0], fullName: selected[0].name }
          );
        }
        resetSuccessorDialog();
      },
      onCancel: resetSuccessorDialog,
    });
  };

  const getConfirmDialog = () => {
    return (
      <ConfirmationDialog
        open={confirmDialog.open}
        title={confirmDialog.title}
        description={confirmDialog.description}
        onConfirm={confirmDialog.onConfirm}
        onCancel={confirmDialog.onCancel}
        confirmText={confirmDialog.confirmText}
        hideCancel={confirmDialog.hideCancel}
      />
    );
  };

  const getSuccessorDialog = () => {
    const { memberParticipant } = selectedParticipant;
    const { id: memberParticipantId } = memberParticipant || {};

    return (
      <SelectCompanyMember
        open={successorDialog.open}
        title={successorDialog.title}
        onConfirm={successorDialog.onConfirm}
        onCancel={successorDialog.onCancel}
        selected={successorDialog.current}
        exeptions={memberParticipantId ? [memberParticipantId] : []}
      />
    );
  };

  const maxScale = getMaximumScale(evaluationScale);
  const minScale = getMinimumScale(evaluationScale);
  const maxPotential = getMaximumScale(potentialScale);
  const minPotential = getMinimumScale(potentialScale);
  const workflowErrors = validateWorkflowActions(
    selectedParticipant,
    participantWorkflow,
    dates,
    enableSEv,
    enableTEv,
    composition,
    evaluationConfig,
    participantComplementaryWorkflow,
    loggedUserRole,
    loggedUser
  );

  const stageMessage = (
    <WorkflowStageMessage
      participant={selectedParticipant}
      workflowStage={filterStage}
      evaluationType={evaluationType}
      evaluationConfig={evaluationConfig}
      loggedUserRole={loggedUserRole}
      errorSlugs={workflowErrors.map(({ slug }) => slug)}
      haveChanges={haveChanges}
      loggedUser={loggedUser}
    />
  );

  const disableSaveWithErrors =
    workflowErrors.length > 0 &&
    !generalOptions?.disableRequiredQuestionsValidation;

  return (
    <div>
      <FormDialog
        open
        backdrop
        title="Avaliação de Pessoas"
        dialogClassName="participant-workflow-1100"
        onConfirm={saveCurrentWorkflow}
        onCancel={handleResetWorkflowDialogs}
        confirmText={
          disableSaveWithErrors
            ? "Corrija os erros antes de salvar"
            : "Salvar alterações"
        }
        cancelText="Fechar"
        blockConfirm={
          !haveChanges || isLoading || disableSaveWithErrors || saveLoading
        }
        bodyStyle={{ padding: "0px" }}
      >
        {getConfirmDialog()}
        {getSuccessorDialog()}
        <WorkflowManager
          selectedToolId={selectedToolId}
          loggedUser={loggedUser}
          loggedUserRole={loggedUserRole}
          participant={selectedParticipant}
          workflow={participantWorkflow}
          complementaryWorkflow={participantComplementaryWorkflow}
          toolAdministration={filteredAdministration}
          evaluationType={evaluationType}
          handleEvaluatorsUpdate={handleEvaluatorsUpdate}
          handleWorkflowUpdate={handleWorkflowUpdate}
          handleComplementaryWorkflowUpdate={handleComplementaryWorkflowUpdate}
          filterStage={filterStage}
          nextStage={{
            label: enabledActions.nextStage && nextStage && nextStage.label,
            sendTo: sendToNextStage,
          }}
          prevStage={{
            label: enabledActions.prevStage && prevStage && prevStage.label,
            sendTo: sendToPrevStage,
          }}
          changeStage={changeStage}
          maxScale={maxScale}
          minScale={minScale}
          maxPotential={maxPotential}
          minPotential={minPotential}
          enabledFields={enabledFields}
          enabledActions={enabledActions}
          enableSecondEvaluator={enableSEv}
          enableThirdEvaluator={enableTEv}
          enableComplementaryEvaluation={enableComplementaryEvaluation}
          dates={dates}
          openSelectSuccessor={openSelectSuccessor}
          managerConfig={managerConfig}
          stageMessage={stageMessage}
          workflowErrors={workflowErrors}
          disableSaveWithErrors={disableSaveWithErrors}
          companyMembersAndParticipants={companyMembersAndParticipants}
        />
      </FormDialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { enableEvaluatorsSelectionWorkflow } = state.skillAssessment.all[0];

  return {
    enableEvaluatorsSelectionWorkflow,
  };
};

export default connect(mapStateToProps, {
  fetchSkillAssessmentConfig,
  grantInsightAccessByParticipant,
  removeCollaboratorsInsightAccess,
  simpleNotifier,
})(SelectedWorkflowDialog);
