import React from "react";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import {
  getUpdatedDeleteArrayByIndex,
  getUpdatedObjectInArray,
  getOnlySublevelArrays,
} from "../../../../../../../utils/ArrayUtils";

const AnalysisAreas = (props) => {
  const {
    cpAnalysisAdministration = {},
    setCpAnalysisAdministration,
    intl,
    getEmptyTableRow,
    getDefaultRemoveColumn,
    getDefaultAddTableLine,
    setConfirmationDialog,
    resetConfirmDialog,
    toggleOpenFormDialogs,
    allQuestions,
    deleteAnalysisArea,
    selectedTool,
  } = props;

  const { concurrence = [] } = cpAnalysisAdministration || {};
  const { analysisAreas = [] } = concurrence || {};

  const updateConcurrenceField = (field = false, value = "") => {
    if (!field) return;

    const concurrenceConfig = cpAnalysisAdministration.concurrence || {};

    setCpAnalysisAdministration({
      ...cpAnalysisAdministration,
      concurrence: {
        ...concurrenceConfig,
        [field]: [...value],
      },
    });
  };

  const handleUpdateAnalysisAreas = (value = "", field = "", index = null) => {
    const updated = getUpdatedObjectInArray(value, analysisAreas, field, index);

    updateConcurrenceField("analysisAreas", updated);
  };

  const handleDeleteArea = (index) => {
    const { id } = analysisAreas[index];

    deleteAnalysisArea(id, "concurrence", selectedTool.id);

    resetConfirmDialog();
  };

  const getBoardConnection = (referedArea = {}) => {
    const onlyQuestions = getOnlySublevelArrays(allQuestions);

    const filtered =
      onlyQuestions.filter(
        (question) => question.competitiveAnalysisAreaId === referedArea.id,
      )[0] || false;

    return filtered ? true : filtered;
  };

  const openDeleteAnalysisAreas = (index) => {
    const referedArea = analysisAreas[index];

    const { title } = referedArea;

    const checkBoardConnection = getBoardConnection(referedArea);

    if (checkBoardConnection)
      return setConfirmationDialog({
        title:
          "Você precisa deletar a questão " +
          title +
          " para continuar com a exclusão.",
        onConfirm: () => resetConfirmDialog(),
        onCancel: () => resetConfirmDialog(),
        open: true,
      });

    setConfirmationDialog({
      title: "Você tem certeza?",
      onConfirm: () => handleDeleteArea(index),
      onCancel: () => resetConfirmDialog(),
      open: true,
    });
  };

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="2">Título</td>
          <td />
        </tr>
      </thead>
      <tbody>
        {analysisAreas.map((areaInfo, index) => {
          const { title } = areaInfo || {};

          return (
            <tr className="block-hover" key={index}>
              <td align="center" style={{ width: "40px" }}>
                {index + 1}
              </td>
              <SimpleExcelCollumn
                id="areaName"
                label="Titulo"
                type="text"
                value={title}
                onChange={(e) =>
                  handleUpdateAnalysisAreas(e.target.value, "title", index)
                }
              />
              {getDefaultRemoveColumn(() => openDeleteAnalysisAreas(index))}
            </tr>
          );
        })}
        {getEmptyTableRow(analysisAreas, "Nada para mostrar!")}
        {getDefaultAddTableLine(() =>
          toggleOpenFormDialogs("newConcurrenceAnalysisArea"),
        )}
      </tbody>
    </table>
  );
};

export default AnalysisAreas;
