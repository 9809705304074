/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import _ from "lodash";
import {
  getEntityConfigurations,
  getFilteredLevelsByAccess,
  getGrantedAccessLevels,
} from "../entityUtils";
import { defaultBoardEntities } from "./defaults";

export function useFilteredEntities({
  selectedTool = {},
  allSceneries = [],
  allThemes = {},
  allQuestions = {},
  allAnswers = {},
}) {
  const [entities, setEntities] = useState(defaultBoardEntities);
  const [entitiesConfig, setEntitiesConfig] = useState({});

  const toolId = selectedTool?.tool?.id || null;

  useEffect(() => {
    const grantedLevels = getGrantedAccessLevels(
      toolId,
      allSceneries,
      allThemes,
      allQuestions,
      allAnswers,
    );

    const newEntities = getFilteredLevelsByAccess(
      allSceneries,
      allThemes,
      allQuestions,
      allAnswers,
      grantedLevels.viewAccess,
    );

    if (!_.isEqual(entities, newEntities)) setEntities(newEntities);

    const newScenariosConfig = getEntityConfigurations(
      "cenary",
      toolId,
      grantedLevels.replaceConfig,
    );

    const { questionRegistrations = ["ANY"] } = grantedLevels;

    const newEntitiesConfig = {
      scenariosConfig: newScenariosConfig,
      replaceConfig: grantedLevels.replaceConfig,
      questionRegistrations,
    };

    if (!_.isEqual(newEntitiesConfig, entitiesConfig))
      setEntitiesConfig(newEntitiesConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolId, allSceneries, allThemes, allQuestions, allAnswers]);

  return { entities, setEntities, entitiesConfig, setEntitiesConfig };
}
