import React from "react";
import { defineMessages } from "react-intl";
import { translatedText } from "../utils/translationUtils";

const messages = defineMessages({
  global_month: { id: "global.month" },
  global_months: { id: "global.months" },
  gb_options_rt_shared: { id: "gb_options_rt_shared" },
  gb_options_rt_individual: { id: "gb_options_rt_individual" },
  gb_options_rt_corporate: { id: "gb_options_rt_corporate" },
  gb_options_mu_monetary_value: { id: "gb_options_mu_monetary_value" },
  gb_options_mu_thousand_monetary_value: {
    id: "gb_options_mu_thousand_monetary_value",
  },
  gb_options_mu_million_monetary_value: {
    id: "gb_options_mu_million_monetary_value",
  },
  gb_options_mu_quantity: { id: "gb_options_mu_quantity" },
  gb_options_mu_percentage: { id: "gb_options_mu_percentage" },
  gb_options_mu_date: { id: "gb_options_mu_date" },
  gb_options_mu_index: { id: "gb_options_mu_index" },
  gb_options_mu_kg: { id: "gb_options_mu_kg" },
  gb_options_mu_tonne: { id: "gb_options_mu_tonne" },
  gb_options_mu_km: { id: "gb_options_mu_km" },
  gb_options_mu_meters: { id: "gb_options_mu_meters" },
  gb_options_mu_cmeters: { id: "gb_options_mu_cmeters" },
  gb_options_mu_liters: { id: "gb_options_mu_liters" },
  gb_options_mu_other: { id: "gb_options_mu_other" },
  gb_options_gt_indicator: { id: "gb_options_gt_indicator" },
  gb_options_gt_project: { id: "gb_options_gt_project" },
  gb_options_gt_option: { id: "gb_options_gt_option" },
  gb_options_pl_greater: { id: "gb_options_pl_greater" },
  gb_options_pl_smaller: { id: "gb_options_pl_smaller" },
  gb_options_fq_monthly: { id: "gb_options_fq_monthly" },
  gb_options_fq_quarterly: { id: "gb_options_fq_quarterly" },
  gb_options_fq_four_months: { id: "gb_options_fq_four_months" },
  gb_options_fq_semiannual: { id: "gb_options_fq_semiannual" },
  gb_options_fq_yearly: { id: "gb_options_fq_yearly" },
  gb_options_mr_sum: { id: "gb_options_mr_sum" },
  gb_options_mr_average: { id: "gb_options_mr_average" },
  gb_options_mr_balance: { id: "gb_options_mr_balance" },
  gb_options_gt_not_hit: { id: "gb_options_gt_not_hit" },
  gb_options_gt_gate: { id: "gb_options_gt_gate" },
  gb_options_gt_appropriated: { id: "gb_options_gt_appropriated" },
  gb_options_gt_exceeded: { id: "gb_options_gt_exceeded" },
  gb_options_pa_admin: { id: "gb_options_pa_admin" },
  gb_options_pa_auditor: { id: "gb_options_pa_auditor" },
  gb_options_pa_registrations: { id: "gb_options_pa_registrations" },
  gb_options_pa_analytics: { id: "gb_options_pa_analytics" },
  gb_options_gvs_creating: { id: "gb_options_gvs_creating" },
  gb_options_gvs_approval: { id: "gb_options_gvs_approval" },
  gb_options_gvs_not_approved: { id: "gb_options_gvs_not_approved" },
  gb_options_gvs_approved: { id: "gb_options_gvs_approved" },
  gb_options_gvs_audit: { id: "gb_options_gvs_audit" },
  gb_options_gvs_validated: { id: "gb_options_gvs_validated" },
  gb_options_gvs_creating_status: { id: "gb_options_gvs_creating_status" },
  gb_options_gvs_approval_status: { id: "gb_options_gvs_approval_status" },
  gb_options_gvs_not_approved_status: {
    id: "gb_options_gvs_not_approved_status",
  },
  gb_options_gvs_approved_status: { id: "gb_options_gvs_approved_status" },
  gb_options_gvs_audit_status: { id: "gb_options_gvs_audit_status" },
  gb_options_gvs_validated_status: { id: "gb_options_gvs_validated_status" },
  gb_options_cb_reset: { id: "gb_options_cb_reset" },
  gb_options_cb_dont_reset: { id: "gb_options_cb_dont_reset" },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

export const getRelationshipTypeOptions = () => {
  return [
    {
      value: "INDIVIDUAL",
      label: translation("gb_options_rt_individual"),
      icon: <i className="fas fa-user" />,
      color: "#6b42a9",
    },
    {
      value: "SHARED",
      label: translation("gb_options_rt_shared"),
      icon: <i className="fas fa-users" />,
      color: "#6eac16",
    },
    {
      value: "CORPORATE",
      label: translation("gb_options_rt_corporate"),
      icon: <i className="fas fa-briefcase" />,
      color: "#a0d139",
    },
  ];
};

export const getMeasurementUnityOptions = () => {
  return [
    {
      value: "MONETARY_VALUE",
      label: translation("gb_options_mu_monetary_value"),
    },
    {
      value: "THOUSAND_MONETARY_VALUE",
      label: translation("gb_options_mu_thousand_monetary_value"),
    },
    {
      value: "MILLION_MONETARY_VALUE",
      label: translation("gb_options_mu_million_monetary_value"),
    },
    { value: "QUANTITY", label: translation("gb_options_mu_quantity") },
    {
      value: "PERCENTAGE",
      label: translation("gb_options_mu_percentage"),
    },
    { value: "DATE", label: translation("gb_options_mu_date") },
    { value: "INDEX", label: translation("gb_options_mu_index") },
    { value: "KG", label: translation("gb_options_mu_kg") },
    { value: "TONNE", label: translation("gb_options_mu_tonne") },
    { value: "KM", label: translation("gb_options_mu_km") },
    { value: "METERS", label: translation("gb_options_mu_meters") },
    { value: "CMETERS", label: translation("gb_options_mu_cmeters") },
    { value: "LITERS", label: translation("gb_options_mu_liters") },
    { value: "OTHER", label: translation("gb_options_mu_other") },
  ];
};

export const getGoalTypeOptions = () => {
  return [
    { value: "INDICATOR", label: translation("gb_options_gt_indicator") },
    { value: "PROJECT", label: translation("gb_options_gt_project") },
    { value: "OPTION", label: translation("gb_options_gt_option") },
  ];
};

export const getPolarityOptions = () => {
  return [
    { value: "GREATER", label: translation("gb_options_pl_greater") },
    { value: "SMALLER", label: translation("gb_options_pl_smaller") },
  ];
};

export const getFrequencyOptions = () => {
  return [
    { value: "MONTHLY", label: translation("gb_options_fq_monthly") },
    { value: "QUARTERLY", label: translation("gb_options_fq_quarterly") },
    {
      value: "FOUR_MONTHS",
      label: translation("gb_options_fq_four_months"),
    },
    {
      value: "SEMIANNUAL",
      label: translation("gb_options_fq_semiannual"),
    },
    { value: "YEARLY", label: translation("gb_options_fq_yearly") },
  ];
};

export const getMeasurementOptions = () => {
  return [
    { value: "SUM", label: translation("gb_options_mr_sum") },
    { value: "AVERAGE", label: translation("gb_options_mr_average") },
    { value: "BALANCE", label: translation("gb_options_mr_balance") },
  ];
};

export const getGoalTargetOptions = () => {
  return [
    {
      value: "NOT_HIT",
      label: translation("gb_options_gt_not_hit"),
      color: "#ff6347",
      hit: 0,
    },
    {
      value: "GATE",
      label: translation("gb_options_gt_gate"),
      color: "#ffc000",
      hit: 1,
    },
    {
      value: "APPROPRIATED",
      label: translation("gb_options_gt_appropriated"),
      color: "#44cf76",
      hit: 2,
    },
    {
      value: "EXCEEDED",
      label: translation("gb_options_gt_exceeded"),
      color: "#5487e4",
      hit: 3,
    },
  ];
};

export const getParticipantPeriodOptions = () => {
  let finalOptions = [];

  for (let month = 1; month <= 12; month++) {
    finalOptions.push({
      value: `MONTH_PERIOD_${month}`,
      label:
        month === 1 ? (
          <span>1 {translation("global_month")}</span>
        ) : (
          <span>
            {month} {translation("global_months")}
          </span>
        ),
      month,
    });
  }

  return finalOptions;
};

export const getPageAccessRulesOptions = () => {
  return [
    { value: "ADMIN", label: translation("gb_options_pa_admin") },
    { value: "AUDITOR", label: translation("gb_options_pa_auditor") },
    {
      value: "REGISTRATIONS",
      label: translation("gb_options_pa_registrations"),
    },
    { value: "ANALYTICS", label: translation("gb_options_pa_analytics") },
  ];
};

export const getGoalValidationStatusOptions = () => {
  return [
    {
      value: "CREATING",
      label: translation("gb_options_gvs_creating"),
      statusLabel: translation("gb_options_gvs_creating_status"),
      icon: <i className="far fa-edit" />,
      color: "#8673a3",
      contrast: "#fff",
    },
    {
      value: "APPROVAL",
      label: translation("gb_options_gvs_approval"),
      statusLabel: translation("gb_options_gvs_approval_status"),
      icon: <i className="far fa-share-square" />,
      color: "#ff4b7d",
      contrast: "#fff",
    },
    {
      value: "NOT_APPROVED",
      label: translation("gb_options_gvs_not_approved"),
      statusLabel: translation("gb_options_gvs_not_approved_status"),
      icon: <i className="far fa-thumbs-down" />,
      color: "#ff6347",
      contrast: "#fff",
    },
    {
      value: "APPROVED",
      label: translation("gb_options_gvs_approved"),
      statusLabel: translation("gb_options_gvs_approved_status"),
      icon: <i className="far fa-thumbs-up" />,
      color: "#92d050",
      contrast: "#fff",
    },
    {
      value: "AUDIT",
      label: translation("gb_options_gvs_audit"),
      statusLabel: translation("gb_options_gvs_audit_status"),
      icon: <i className="fas fa-file-contract" />,
      color: "#5487e4",
      contrast: "#fff",
    },
    {
      value: "VALIDATED",
      label: translation("gb_options_gvs_validated"),
      statusLabel: translation("gb_options_gvs_validated_status"),
      icon: <i className="fas fa-check-double" />,
      color: "#1d499b",
      contrast: "#fff",
    },
  ];
};

export const getClearBonusOptions = () => {
  return [
    { value: "RESET", label: translation("gb_options_cb_reset") },
    { value: "DONT_RESET", label: translation("gb_options_cb_dont_reset") },
  ];
};

export const allowedEditingStatus = ["CREATING", "NOT_APPROVED"];
export const allowedSendWorkflowApproval = ["CREATING", "NOT_APPROVED"];
export const allowedEditingRelationType = ["INDIVIDUAL"];
export const allowedOutsideApprovalPeriod = ["CREATING", "APPROVAL"];
export const allowedAuditStatus = ["AUDIT", "VALIDATED"];
export const blockedAuditorAccess = [
  "CREATING",
  "APPROVAL",
  "APPROVED",
  "NOT_APPROVED",
];

export const defaultGoalsDisplayOrder = ["CORPORATE", "SHARED", "INDIVIDUAL"];
export const onlyAuditorAdminWorkflowGoalAccess = ["CORPORATE", "SHARED"];

export const administrativeRoleWorkflowAcces = ["ADMIN", "AUDITOR"];

export const notStatedGoalReachDefault = {
  goalPercentage: 0,
  totalHitTargetInfo: {
    hitTarget: 0,
    color: "#eee",
  },
  notStarted: true,
};
