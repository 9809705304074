import React from "react";
import { patternStyles, generalCardStyles } from "../jcss";
import { Row, Col } from "react-bootstrap";
import { getDefaultPercentages } from "../../../../../../../../utils/projectUtils";
import BadgeProgressBar from "../../../../../../../Common/ReportCards/components/BadgeProgressBar";
import { nFormatter } from "../../../../../../../../utils/MathUtils";

const values = getDefaultPercentages(20);

const overallRowStyle = { margin: "20px" };
const overallRowStyle2 = { margin: "28px 20px 20px 20px" };
const adherenceRowStyle = { marginBottom: "10px", fontWeight: "500" };
const badgeRowStyle = { marginLeft: "20px", display: "flex", width: "90%" };

const CostChart = ({ macroOverviewData = {} }) => {
  const {
    centerAlign = "",
    costStyle = "",
    cardHeaderStyle = "",
  } = patternStyles();

  const { boxInfoHeader = "", boxInfoContent = "" } = generalCardStyles();

  const { cost = {} } = macroOverviewData;
  const { totalPlanned = 0, totalSpent = 0, totalPercentage = 0 } = cost;

  const getValueLine = (value = "", index = 0) => {
    return (
      <Col md={2} key={index} style={{ fontSize: "10px" }}>
        {value}
      </Col>
    );
  };

  const singleGreyBar = () => {
    return (
      <BadgeProgressBar
        widthBar={"100%"}
        heightBar="18px"
        leftText={100}
        barColor="grey"
        borderRadius="5px"
      />
    );
  };

  const getBadges = ({
    belowCostPercentage = "",
    aboveCostPercentage = "",
  }) => {
    let greenConfig = { leftBorderRadius: "5px" };
    let redConfig = { rightBorderRadius: "5px" };

    if (belowCostPercentage === "0%" && aboveCostPercentage === "0%")
      return singleGreyBar();
    if (belowCostPercentage === "0%") redConfig = { borderRadius: "5px" };
    if (aboveCostPercentage === "0%") greenConfig = { borderRadius: "5px" };

    return (
      <div
        title={
          "Abaixo do Custo: " +
          belowCostPercentage +
          " | " +
          "Acima do Custo: " +
          aboveCostPercentage
        }
        style={{ width: "100%", display: "flex" }}
      >
        <BadgeProgressBar
          widthBar={belowCostPercentage}
          heightBar="18px"
          leftText={100}
          barColor="kanbanGreen"
          {...greenConfig}
        />
        <BadgeProgressBar
          widthBar={aboveCostPercentage}
          heightBar="18px"
          leftText={100}
          barColor="kanbanRed"
          rightBorderRadius="5px"
          {...redConfig}
        />
      </div>
    );
  };

  const getPercentageColor = () => {
    const red = "rgb(245, 78, 78)";
    const green = "rgb(12, 146, 71)";

    if (Array.isArray(totalPercentage)) return green;

    const numberPercentage = totalPercentage || 0;

    if (numberPercentage > 100) return red;

    return green;
  };

  const percentageColor = getPercentageColor(totalPercentage);

  const borderStyle = `4px solid ${percentageColor}`;

  return (
    <Col md={4} sm={12} className={costStyle}>
      <Row className={cardHeaderStyle}>
        <strong>Custo (R$)</strong>
      </Row>
      <Row style={overallRowStyle}>
        <Col md={4}>
          <div>
            <div className={boxInfoHeader} style={{ width: "150px" }}>
              Total Previsto
            </div>
            <div
              className={boxInfoContent}
              style={{ fontSize: "20px", width: "150px" }}
            >
              {nFormatter(totalPlanned, 1)}
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div>
            <div className={boxInfoHeader} style={{ width: "150px" }}>
              Total Realizado
            </div>
            <div
              className={boxInfoContent}
              style={{ fontSize: "20px", width: "150px" }}
            >
              {nFormatter(totalSpent, 1)}
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div>
            <div className={boxInfoHeader} style={{ width: "150px" }}>
              %
            </div>
            <div
              className={boxInfoContent}
              style={{
                borderLeft: borderStyle,
                fontSize: "22px",
                width: "150px",
              }}
            >
              {totalPercentage}%
            </div>
          </div>
        </Col>
      </Row>
      <Row style={overallRowStyle2}>
        <Row style={adherenceRowStyle} className={centerAlign}>
          Nº Projetos em Conformidado com custo
        </Row>
        <Row>
          <Row>{values.map((value, index) => getValueLine(value, index))}</Row>
          <Row style={badgeRowStyle}>{getBadges(cost)}</Row>
        </Row>
      </Row>
    </Col>
  );
};

export default CostChart;
