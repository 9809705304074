import React, { useState } from "react";

import { FormControl, MenuItem, Select } from "@material-ui/core";
import PropTypes from "prop-types";

import { orderArrayByObjAttr } from "../../../../../../../utils/ArrayUtils";
import {
  getWorkflowEvaluationFields,
  workflowCommentTags,
} from "../../../../../../../utils/skillsAssessment/constants";
import WorkflowComments from "./WorkflowComments";

const Description = ({ title, description }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        padding: "10px",
        fontSize: "11px",
      }}
      title={description}
    >
      {title}
    </div>
  );
};

const WorkflowReplyTable = ({
  selectedToolId,
  insight,
  workflow,
  enabledFields,
  scaleOptions,
  handleWorkflowUpdate,
  blockUpdates,
  hideComments,
  loggedUser,
}) => {
  const [showComments, setShowComments] = useState(null);

  const defaultEvaluationFields = getWorkflowEvaluationFields();
  const haveNoWorkflow = !workflow || workflow.length === 0;

  const toggleComments = (workflowId) => {
    const newShowComments = showComments === workflowId ? null : workflowId;

    setShowComments(newShowComments);
  };

  const commentsCol = (workflowId) => {
    const open = showComments === workflowId;
    const iconClass = open ? "fas fa-caret-down" : "fas fa-caret-right";

    return (
      !hideComments && (
        <td
          onClick={() => toggleComments(workflowId)}
          align="center"
          style={{ cursor: "pointer" }}
          className="input-td"
        >
          <i className={iconClass} />
        </td>
      )
    );
  };

  const headCols = () => {
    return defaultEvaluationFields.map(({ value, label }) => {
      if (enabledFields[value] && !enabledFields[value].hide)
        return (
          <td width="110" key={value} style={{ fontSize: "10px" }}>
            {label}
          </td>
        );

      return null;
    });
  };

  const evaluationCols = (workflowInfo, field) => {
    const updateValue = (newValue) => {
      if (!blockUpdates) {
        const validValue = newValue !== "empty" ? newValue : "";
        handleWorkflowUpdate(workflowInfo, field, validValue);
      }
    };

    const currentValue =
      !workflowInfo[field] && workflowInfo[field] !== 0
        ? "empty"
        : workflowInfo[field];
    const { hide = true, disabled = true } = enabledFields[field] || {};

    return (
      !hide && (
        <td className="input-td">
          <FormControl fullWidth margin="none" className="flex-input-control">
            <Select
              id={`wfield-${workflowInfo.id}`}
              value={currentValue}
              onChange={(e) => updateValue(e.target.value)}
              disabled={disabled}
            >
              <MenuItem value="empty">
                <div style={{ padding: "5px", fontSize: "16px" }}>
                  - Selecione uma opção -
                </div>
              </MenuItem>
              {scaleOptions.map(({ label, value }) => {
                return (
                  <MenuItem key={value} value={value}>
                    <div style={{ padding: "5px", fontSize: "14px" }}>
                      {label}
                    </div>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </td>
      )
    );
  };

  const displayWorkflow = workflow.map((workflowInfo) => {
    const { skill } = workflowInfo;
    const { competenceArea } = skill;

    return {
      ...workflowInfo,
      competenceArea,
    };
  });

  const orderedWorkflow = orderArrayByObjAttr(
    displayWorkflow,
    "competenceArea",
    "title"
  );

  return (
    <table className="simple-table workflow-table">
      <thead>
        <tr className="small-font" style={{ fontWeight: "bold" }}>
          {!hideComments && (
            <td title="Comentários/Observações">
              <i className="far fa-comments" />
            </td>
          )}
          <td style={{ fontSize: "12px" }}>ÁREA DE COMPETÊNCIA</td>
          <td style={{ fontSize: "12px" }}>COMPETÊNCIA</td>
          {headCols()}
        </tr>
      </thead>
      <tbody>
        {orderedWorkflow?.map((workflowInfo) => {
          const { skill } = workflowInfo;

          const displayComments = showComments === workflowInfo.id;

          return (
            <React.Fragment key={workflowInfo.id}>
              <tr>
                {commentsCol(workflowInfo.id)}
                <td className="input-td">
                  <Description
                    title={skill?.competenceArea?.title}
                    description={skill?.competenceArea?.description}
                  />
                </td>
                <td className="input-td">
                  <Description
                    title={skill?.title}
                    description={skill?.description}
                  />
                </td>
                {defaultEvaluationFields.map(({ value }) => {
                  return (
                    <React.Fragment key={value}>
                      {evaluationCols(workflowInfo, value)}
                    </React.Fragment>
                  );
                })}
              </tr>
              {displayComments && !hideComments && (
                <tr className="block-hover">
                  <td colSpan="100">
                    <div style={{ padding: "15px" }}>
                      <h4>Comentários e Observações</h4>
                      <WorkflowComments
                        selectedToolId={selectedToolId}
                        insight={insight}
                        loggedUser={loggedUser}
                        tags={workflowCommentTags(skill).all}
                        filter={(newComments) => newComments}
                        showOnlyOwnerComments
                      />
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
        {haveNoWorkflow && (
          <tr>
            <td colSpan="100">
              <h5 align="center" style={{ color: "#666" }}>
                Nenhuma Competência foi adicionada para o Perfil deste
                Participante.
              </h5>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

WorkflowReplyTable.propTypes = {
  workflow: PropTypes.array.isRequired,
  enabledFields: PropTypes.object.isRequired,
  scaleOptions: PropTypes.array.isRequired,
  handleWorkflowUpdate: PropTypes.func,
  blockUpdates: PropTypes.bool,
  hideComments: PropTypes.bool,
};

WorkflowReplyTable.defaultProps = {
  handleWorkflowUpdate: () => {},
  blockUpdates: false,
  hideComments: false,
};

export default WorkflowReplyTable;
