const en = {
  btn_modal_cancel: "Cancel",
  btn_modal_confirm: "Confirm",
  btn_modal_register_new: "Register new",
  btn_modal_register: "Register",
  btn_modal_back: "Come back",
  btn_modal_select: "Select",
};

const es = {
  btn_modal_cancel: "Cancelar",
  btn_modal_confirm: "Confirmar",
  btn_modal_register_new: "Registar nuevo",
  btn_modal_register: "Registar",
  btn_modal_back: "Vuelve",
  btn_modal_select: "Seleccionar",
};

const pt = {
  btn_modal_cancel: "Cancelar",
  btn_modal_confirm: "Confirmar",
  btn_modal_register_new: "Cadastrar novo",
  btn_modal_register: "Cadastrar",
  btn_modal_back: "Voltar",
  btn_modal_select: "Selecionar",
};

const translations = { en, es, pt };

export default translations;
