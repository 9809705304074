import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const CardNineBox = styled.div`
  @media print {
    background-color: ${props =>
      props.backgroundColor ? props.backgroundColor : ""} !important;
  }
`;

const NineBoxEvaluation = ({
  data,
  legendRight,
  legendBottom,
  styles = {}
}) => {
  let total = 0;
  data.forEach(({ value }) => {
    total = total + value;
  });

  const getPercentage = val => {
    val = val > 0 ? val : 0;
    return ((val * 100) / total).toFixed(1);
  };

  return (
    <div className="nine-box-evaluation" style={{ ...styles }}>
      <div className="row">
        <div className="legend legend-right">
          <i className="fas fa-arrow-up" /> {legendRight}
        </div>
        <div className="cards">
          {data.map(({ label, value, color, contrast }, index) => {
            let cardStyles = {};
            cardStyles = color ? { backgroundColor: color } : {};
            cardStyles = contrast
              ? { ...cardStyles, color: contrast }
              : cardStyles;

            return (
              <CardNineBox
                key={index}
                className="card"
                backgroundColor={cardStyles.backgroundColor}
                style={cardStyles}
              >
                {value}
                {label && <span className="label">{label}</span>}
                <span className="label label-bottom">
                  {getPercentage(value)}%
                </span>
              </CardNineBox>
            );
          })}
        </div>
      </div>
      {legendBottom && (
        <div className="legend legend-bottom">
          {legendBottom + " "}
          <i className="fas fa-arrow-right" />
        </div>
      )}
    </div>
  );
};

NineBoxEvaluation.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number.isRequired,
      color: PropTypes.string,
      contrast: PropTypes.string
    })
  ),
  legendRight: PropTypes.string,
  legendBottom: PropTypes.string
};

NineBoxEvaluation.defaultProps = {
  data: [],
  legendRight: null,
  legendBottom: null
};

export default NineBoxEvaluation;
