import React from "react";
import { injectIntl } from "react-intl";
import { Row, Col } from "react-bootstrap";
import { defineMessages } from "react-intl";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MaterialSingleSelect from "../../../Common/MaterialSingleSelect";
import MaterialTextField from "../../../Common/MaterialTextField";

import {
  getScopeViewOptions,
  getLabelByType,
} from "../../../../utils/projectEvaluation";
import { getStagesConfiguration } from "../../../../utils/projectConfiguration/entity";

const messages = defineMessages({
  projectJustification: {
    id: "tool.project.project_justification",
  },
  projectScope: {
    id: "tool.project.scope",
  },
  description: {
    id: "global.description",
  },
  scopeView: {
    id: "tool.project.scopeView",
  },
  statusReport: {
    id: "global.statusReport",
  },
  selectOption: {
    id: "global.selectOption",
  },
});

const Description = ({
  intl,
  saveButton,
  saveButtonJSX,
  projectConfiguration,
  projectEvaluation,
  handleUpdateSingleField,
}) => {
  const justificationTitle = intl.formatMessage(messages.projectJustification);

  const scopeTitle = intl.formatMessage(messages.projectScope);
  const scopeView = intl.formatMessage(messages.scopeView);
  const reportTitle = intl.formatMessage(messages.statusReport);
  const descriptionTitle = intl.formatMessage(messages.description);
  const customTextStyle = { maxHeight: "75px" };
  const scopeViewOptions = getScopeViewOptions(intl);
  const labels = getLabelByType("Schedule", intl);

  const { projectStageId = null } = projectEvaluation || {};

  const parentConfiguration = getStagesConfiguration(projectConfiguration);

  const handleChangeStage = (value = "") => {
    handleUpdateSingleField("projectStageId", Number(value));
  };

  return (
    <div className="box box-primary">
      <div
        id="header-insight-evaluation verticallyCentralized"
        className="box-header with-border"
      >
        <i className="fas fa-pen" />
        <h3 className="box-title"> {descriptionTitle}</h3>
        {saveButton && saveButtonJSX()}
        <br />
      </div>
      <div className="box-body descriptionBoxBody">
        <Row className="topBox">
          <Col md={6} align="center">
            <FormControl className="formController">
              <InputLabel
                htmlFor="grouped-native-select"
                style={{ backgroundColor: "inherit" }}
              >
                {labels.fourthBox}
              </InputLabel>
              <Select
                native
                defaultValue={projectStageId}
                value={projectStageId}
                id="grouped-native-select"
                onChange={(e) => handleChangeStage(e.target.value)}
              >
                <option value={null}>
                  {intl.formatMessage(messages.selectOption)}
                </option>
                {parentConfiguration.map((config, index) => {
                  const { id = "", description = "", sons = [] } = config || {};

                  const sonsLength = sons.length || 0;

                  if (sonsLength === 0)
                    return (
                      <option value={id} key={index}>
                        {description}
                      </option>
                    );

                  return (
                    <optgroup label={description} key={index}>
                      {sons.map((son, key) => {
                        const { id = "", description = "" } = son;

                        return (
                          <option value={id} key={key}>
                            {description}
                          </option>
                        );
                      })}
                    </optgroup>
                  );
                })}
              </Select>
            </FormControl>
          </Col>
          <Col md={6} align="center">
            <MaterialSingleSelect
              id="projectScoreView"
              label={scopeView}
              value={projectEvaluation.scopeView || ""}
              options={scopeViewOptions}
              variant="standard"
              onChange={(e) =>
                handleUpdateSingleField("scopeView", e.target.value)
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <MaterialTextField
              id="justificationDetails"
              label={justificationTitle}
              multiline
              primaryInputProps={{ maxLength: 255 }}
              value={
                projectEvaluation.justification
                  ? projectEvaluation.justification
                  : ""
              }
              rows={10}
              inputStyle={customTextStyle}
              onChange={(e) =>
                handleUpdateSingleField("justification", e.target.value)
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <MaterialTextField
              id="scopeDetails"
              label={scopeTitle}
              multiline
              primaryInputProps={{ maxLength: 400 }}
              value={projectEvaluation.scope ? projectEvaluation.scope : ""}
              rows={10}
              inputStyle={customTextStyle}
              onChange={(e) => handleUpdateSingleField("scope", e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <MaterialTextField
              id="scopeView"
              label={reportTitle}
              primaryInputProps={{ maxLength: 255 }}
              multiline
              value={
                projectEvaluation.statusReport
                  ? projectEvaluation.statusReport
                  : ""
              }
              rows={10}
              inputStyle={customTextStyle}
              onChange={(e) =>
                handleUpdateSingleField("statusReport", e.target.value)
              }
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default injectIntl(Description);
