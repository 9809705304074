import React from "react";
import { defineMessages } from "react-intl";
import Workflow from "./Workflow";
import Administration from "./Administration";
import Measurement from "../../../../../Measurement";

import gbUtils from "../../../../../../utils/goalsBonus";
import { translatedText } from "../../../../../../utils/translationUtils";

const { getTopLevelUserAccess } = gbUtils;

const messages = defineMessages({
  global_administration: { id: "global.administration" },
  global_measurement: { id: "global.measurement" },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

const getTabs = (appendProps = {}) => {
  const userAccess = getTopLevelUserAccess();

  const { activeTab } = appendProps;
  const { accesses = [] } = userAccess;

  const adminAccess = accesses.indexOf("ADMIN") > -1;
  const workflowAccess =
    adminAccess ||
    accesses.indexOf("AUDITOR") > -1 ||
    accesses.indexOf("OWN_GOALS") > -1;

  let filteredTabs = [];

  const addTab = (id, label, Component) => {
    filteredTabs = [
      ...filteredTabs,
      {
        id,
        label,
        component: activeTab === id ? <Component {...appendProps} /> : null,
      },
    ];
  };

  if (adminAccess)
    addTab(
      "administration",
      translation("global_administration"),
      Administration,
    );
  if (workflowAccess) addTab("workflow", "Workflow", Workflow);
  if (adminAccess)
    addTab("measurement", translation("global_measurement"), Measurement);

  return filteredTabs;
};

export default getTabs;
