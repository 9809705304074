import React, { useState } from "react";
import PropTypes from "prop-types";
import FormDialog from "../../../../../../Common/FormDialog";
import MaterialTextField from "../../../../../../Common/MaterialTextField";
import { getProfilesEntity } from "../../../../../../../utils/skillsAssessment/entity";
import { addNewProfile } from "../../../../../../../utils/skillsAssessment/manipulation";
import {
  validateProfiles,
  validateNewProfile,
} from "../../../../../../../utils/skillsAssessment/validation";
import { Errors } from "../../../../../../../utils/validations";
import { getStyledErrorMessage } from "../../../../../../../utils/formValidation";

const defaultForm = getProfilesEntity([{}], {
  canInsertNewId: true,
})[0];

const NewProfile = ({
  open,
  afterConfirm,
  afterCancel,
  toolAdministration,
}) => {
  const { profiles = [] } = toolAdministration;

  const [form, setForm] = useState(defaultForm);

  const handleConfirm = () => {
    const newAdministration = addNewProfile(toolAdministration, form);

    afterConfirm(newAdministration);
  };

  const handleCancel = () => {
    afterCancel();
  };

  const validateForm = () => {
    const errors = new Errors();

    errors.addMultiple(validateNewProfile(form, profiles));
    errors.addMultiple(validateProfiles([form]));

    return { isValid: !errors.hasErrors(), errors: errors.getErrors() };
  };

  const updateField = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const formTitle = "Adicionar Perfil de Avaliação";
  const { isValid, errors } = validateForm();

  return (
    <FormDialog
      open={open}
      title={formTitle}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      blockConfirm={!isValid}
    >
      <div className="row">
        <div className="col-xs-12">
          <MaterialTextField
            id="profileTitle"
            label="Título"
            value={form.title}
            onChange={(e) => updateField("title", e.target.value)}
          />
        </div>
        <div className="col-xs-12">
          {getStyledErrorMessage(["duplicated_title"], errors)}
        </div>
      </div>
    </FormDialog>
  );
};

NewProfile.propTypes = {
  open: PropTypes.bool,
  afterConfirm: PropTypes.func.isRequired,
  afterCancel: PropTypes.func.isRequired,
  toolAdministration: PropTypes.object.isRequired,
};

NewProfile.defaultProps = {
  open: false,
};

export default NewProfile;
