import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import FormDialog from "../../FormDialog";
import MaterialTextField from "../../MaterialTextField";
import SimpleCheckbox from "../../SimpleCheckbox";
import utils from "../../../../utils/toolUtils";

const { getErrorMessage } = utils;

const messages = defineMessages({
  gb_dialog_title_change_workflow: { id: "gb_dialog_title_change_workflow" },
  gb_error_small_message: { id: "gb_error_small_message" },
  gb_form_message: { id: "gb_form_message" },
  gb_form_send_email_approvers: { id: "gb_form_send_email_approvers" },
  gb_form_send_email_participants: { id: "gb_form_send_email_participants" },
  gb_message_write_message: { id: "gb_message_write_message" },
});

const INITIAL_FORM = {
  message: "",
  sendEmails: false,
  sendToParticipants: false,
};

const ChangeWorkflowDialog = (props) => {
  const [form, setForm] = useState(INITIAL_FORM);
  const { open = false, intl } = props;

  useEffect(() => {
    if (!open) setForm(INITIAL_FORM);
  }, [open]);

  const onConfirm =
    typeof props.onConfirm === "function" ? props.onConfirm : () => {};
  const onCancel =
    typeof props.onCancel === "function" ? props.onCancel : () => {};

  const getFormErrors = () => {
    const errors = [];

    if (!form.message || form.message.length < 3) {
      errors.push({
        slug: "shortDescription",
        message: intl.formatMessage(messages.gb_error_small_message),
      });
    }

    return errors;
  };

  const formErrors = getFormErrors();

  return (
    <FormDialog
      open={open}
      title={intl.formatMessage(messages.gb_dialog_title_change_workflow)}
      onConfirm={() => onConfirm(form)}
      onCancel={onCancel}
      blockConfirm={formErrors.length > 0}
    >
      <div className="row">
        <div className="col-xs-6">
          <SimpleCheckbox
            checked={form.sendEmails}
            label={intl.formatMessage(messages.gb_form_send_email_approvers)}
            name="sendEmails"
            onChange={(e) => setForm({ ...form, sendEmails: !e.checked })}
          />
        </div>
        <div className="col-xs-6">
          <SimpleCheckbox
            checked={form.sendToParticipants}
            label={intl.formatMessage(messages.gb_form_send_email_participants)}
            name="sendToParticipants"
            onChange={(e) =>
              setForm({ ...form, sendToParticipants: !e.checked })
            }
          />
        </div>
        <div className="col-xs-12">
          <h4>{intl.formatMessage(messages.gb_message_write_message)}</h4>
          <MaterialTextField
            id="workflowMessage"
            label={intl.formatMessage(messages.gb_form_message)}
            value={form.message}
            onChange={(e) => setForm({ ...form, message: e.target.value })}
            multiline
            rows={4}
          />
          {getErrorMessage(["shortDescription"], formErrors)}
        </div>
      </div>
    </FormDialog>
  );
};

export default injectIntl(ChangeWorkflowDialog);
