import { createIntl } from "react-intl";
import { createIntlCache } from "react-intl/dist/utils";
import store from "../Store";
import {
  ACTION_PLAN_ID,
  ANALISE_CURVA_DE_VALOR_ID,
  BALANCED_SCORECARD_ID,
  BUSINESS_MODEL_ID,
  COMPETITIVE_ANALYSIS,
  EXCELLENCE_MANAGEMENT,
  GESTAO_REUNIOES_ID,
  GOALS_AND_BONUS,
  KPIS,
  MARKET_ANALYSIS,
  OKR_ID,
  PEOPLE_BIG_PICTURE,
  PORTIFOLIO_ANALYSIS,
  PROCESSES_BIG_PICTURE,
  PROJECTS_INITIATIVES,
  PROJECT_PORTFOLIO,
  PROJECT_PRIORITIZATION,
  SKILLS_ASSESSMENT,
  STRATEGIC_FOUNDATIONS,
  SWOT,
} from "../constants/tools.constants";

export function getToolIntlSlug(toolId) {
  switch (toolId) {
    case OKR_ID:
      return "okr_main_tile";
    case SWOT:
      return "swot_main_tile";
    case BUSINESS_MODEL_ID:
      return "bm_main_title";
    case COMPETITIVE_ANALYSIS:
      return "ca_main_title";
    case PROCESSES_BIG_PICTURE:
      return "pbp_main_title";
    case PEOPLE_BIG_PICTURE:
      return "pb_titles_tabs_people_map";
    case GOALS_AND_BONUS:
      return "gb_main_title";
    case PROJECT_PORTFOLIO:
      return "pport_main_title";
    case PROJECT_PRIORITIZATION:
      return "pp_main_title";
    case ANALISE_CURVA_DE_VALOR_ID:
      return "vca_main_title";
    case STRATEGIC_FOUNDATIONS:
      return "fc_main_title";
    case PORTIFOLIO_ANALYSIS:
      return "pa_main_title";
    case BALANCED_SCORECARD_ID:
      return "bs_main_title";
    case EXCELLENCE_MANAGEMENT:
      return "em_main_title";
    case KPIS:
      return "kpi_main_title";
    case PROJECTS_INITIATIVES:
      return "pi_main_title";
    case GESTAO_REUNIOES_ID:
      return "mm_main_title";
    case MARKET_ANALYSIS:
      return "ma_main_title";
    case SKILLS_ASSESSMENT:
      return "skas_main_title";
    case ACTION_PLAN_ID:
      return "acp_main_title";
    default:
      return "default_tool_name";
  }
}

export const translatedText = (id, messages = {}, values = {}) => {
  if (store) {
    const state = store.getState();

    const { language, translations } = state;

    const cache = createIntlCache();
    const intl = createIntl(
      { locale: language, messages: translations },
      cache
    );

    return messages && messages[id] && messages[id].id
      ? intl.formatMessage(messages[id], { ...values })
      : id || null;
  }

  return id || null;
};

export const translation = (id, messages, values = {}) =>
  translatedText(id, messages, values);
