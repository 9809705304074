import React, { useEffect, useState, useCallback } from "react";
import Chart from "react-google-charts";

const graphDefaultStyles = {
  width: "100px",
  height: "90px",
};

const MultipleLineChart = ({ modalDisplay = null, lineChartData }) => {
  return (
    <Chart
      width={"100%"}
      height={modalDisplay ? "300px" : graphDefaultStyles.height}
      chartType="LineChart"
      loader={<div>Loading Chart</div>}
      data={lineChartData}
      options={{
        chartArea: {
          width: modalDisplay ? "75%" : "100%",
          height: modalDisplay ? "80%" : "100%",
        },
        hAxis: {
          gridlines: {
            color: !modalDisplay && "transparent",
          },
          textPosition: modalDisplay ? "out" : "none",
        },
        vAxis: {
          gridlines: {
            color: !modalDisplay && "transparent",
          },
          textPosition: modalDisplay ? "out" : "none",
        },
        backgroundColor: { fill: "transparent" },
        legend: "none",
        series: {
          1: { curveType: "function" },
        },
        animation: {
          startup: modalDisplay ? true : false,
          easing: modalDisplay ? "linear" : "none",
          duration: modalDisplay ? 900 : 0,
        },
      }}
    />
  );
};

export default MultipleLineChart;
