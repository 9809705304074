import React, { Component } from "react";
import { injectIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Popover, OverlayTrigger } from "react-bootstrap";
import AverageBadge from "../../../Common/AverageBadge";
import {
  getCustomColorClass,
  swotEvaluationTotalScore,
  generateImportancePoints,
  generateImpactPoints,
  generateReliabilityPoints,
} from "../../../../constants/swotCustom";
import { getActionsPlanStates } from "../../../customMapStates";

import ActionsCounterBadge from "../../../Common/ActionPlan/evaluations/ActionsCounterBadge";

import { getSwotEvaluationByID } from "../../../../utils/swotUtils";
import actionsPlanUtils from "../../../../utils/actionsPlan";
import { getOnlySlugValues } from "../../../../utils/ArrayUtils";

const {
  getTotalActionsByInsights,
  getDefaultEvaluationBadgeConfig,
} = actionsPlanUtils;

const messages = defineMessages({
  impact: { id: "global.impact" },
  importance: { id: "global.importance" },
  reliability: { id: "global.reliability" },
});

class SwotCustomization extends Component {
  getInsight(getFirst) {
    const { insightsToCalc } = this.props;
    let finalInsight = null;

    if (this.props.from === "answer") {
      insightsToCalc.forEach((insight) => {
        finalInsight = insight;
      });
    } else if (getFirst) {
      finalInsight = insightsToCalc[0] ? insightsToCalc[0] : null;
    }

    return finalInsight;
  }

  getInsightScore(insightID) {
    const { allSwotEvaluation } = this.props;
    let finalScore = 0;

    if (insightID) {
      const insightEvaluation = getSwotEvaluationByID(
        insightID,
        allSwotEvaluation,
      );
      if (insightEvaluation) finalScore = insightEvaluation.swotAnalysis.score;
    }

    return finalScore;
  }

  getInsightsDetailedScore(insights = []) {
    let impactScore = 0;
    let importanceScore = 0;
    let reliabilityScore = 0;

    if (Array.isArray(insights)) {
      insights.forEach((insightInfo) => {
        if (insightInfo.swotAnalysis) {
          const { swotAnalysis = {} } = insightInfo;
          const {
            impactOnResults = 0,
            importanceLevel = 0,
            reliabilityLevel = 0,
          } = swotAnalysis;

          impactScore += generateImpactPoints(impactOnResults);
          importanceScore += generateImportancePoints(importanceLevel);
          reliabilityScore += generateReliabilityPoints(reliabilityLevel);
        }
      });
    }

    return { impactScore, importanceScore, reliabilityScore };
  }

  getTotalScore() {
    const { insightsToCalc } = this.props;
    let totalScore = 0;

    insightsToCalc.forEach((insight) => {
      totalScore += this.getInsightScore(insight.id);
    });

    return totalScore;
  }

  getPercentage(score) {
    return (score * 100) / swotEvaluationTotalScore;
  }

  getInsightPopover() {
    const { insightsToCalc, intl } = this.props;

    const {
      impactScore,
      importanceScore,
      reliabilityScore,
    } = this.getInsightsDetailedScore(insightsToCalc);

    return this.props.from === "answer" || this.props.from === "question" ? (
      <Popover
        id="popover-basic"
        style={{ whiteSpace: "nowrap", maxWidth: "1000px" }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "100px", padding: "0px 10px" }}>
            <font>{intl.formatMessage(messages.impact)}</font>
            <AverageBadge
              fontSize="18px"
              padding="10px"
              lineHeight="12px"
              fontWeight="bold"
              maxValue={5 * insightsToCalc.length}
              displayMaxValue
              hideColor
              borderedBadge
              average={impactScore}
            />
          </div>
          <div style={{ width: "100px", padding: "0px 10px" }}>
            <font>{intl.formatMessage(messages.importance)}</font>
            <AverageBadge
              fontSize="18px"
              padding="10px"
              lineHeight="12px"
              fontWeight="bold"
              maxValue={5 * insightsToCalc.length}
              displayMaxValue
              hideColor
              borderedBadge
              average={importanceScore}
            />
          </div>
          <div style={{ width: "100px", padding: "0px 10px" }}>
            <font>{intl.formatMessage(messages.reliability)}</font>
            <AverageBadge
              fontSize="18px"
              padding="10px"
              lineHeight="12px"
              fontWeight="bold"
              maxValue={5 * insightsToCalc.length}
              displayMaxValue
              hideColor
              borderedBadge
              average={reliabilityScore}
            />
          </div>
        </div>
      </Popover>
    ) : (
      <div />
    );
  }

  render() {
    const {
      getScorePoints,
      from,
      insightsToCalc,
      allActionsPlans,
    } = this.props;
    const firstInsight = this.getInsight(true);
    const themeName =
      firstInsight && !getScorePoints && firstInsight.question
        ? firstInsight.question.text
        : "";

    const score = firstInsight ? this.getInsightScore(firstInsight.id) : 0;
    const customColorClass =
      firstInsight && !getScorePoints && getCustomColorClass(themeName);
    const questionTotalScore = this.getTotalScore();
    const averageBadgeConfigs =
      from === "question"
        ? {
            fontWeight: "bold",
            fontSize: "17",
          }
        : {};

    let color = "#ccc";
    if (questionTotalScore !== null) color = "#000000";

    const {
      hideBadge: hideActionsCounter,
      hideTitle: hideActionsCounterTitle,
    } = getDefaultEvaluationBadgeConfig(from);

    const onlyInsightIds = getOnlySlugValues(insightsToCalc, "id");

    return (
      <div
        className="swot-evaluation-container"
        style={{ display: "flex", alignItems: "center" }}
      >
        {from !== "theme" && from !== "cenary" ? (
          <div
            className="badge_tool"
            style={{ display: "flex", alignItems: "center" }}
          >
            {this.props.showProgressEvaluation &&
              from !== "question" &&
              from !== "cenary" && (
                <div style={{ marginRight: "15px" }}>
                  <div className="progress" style={{ height: "20px" }}>
                    <div
                      role="progressbar"
                      className={customColorClass && customColorClass.class}
                      aria-valuenow={swotEvaluationTotalScore}
                      style={{
                        width: `${this.getPercentage(score)}%`,
                      }}
                    />
                  </div>
                </div>
              )}
            <OverlayTrigger overlay={this.getInsightPopover()} placement="top">
              <AverageBadge
                hideColor
                average={
                  questionTotalScore === null || getScorePoints
                    ? score
                    : questionTotalScore
                }
                defaultColor={color}
                maxValue={
                  questionTotalScore === null
                    ? swotEvaluationTotalScore
                    : questionTotalScore
                }
                borderedBadge
                fontSize={averageBadgeConfigs.fontSize}
                fontWeight={averageBadgeConfigs.fontWeight}
                width="60px"
              />
            </OverlayTrigger>
          </div>
        ) : null}
        {!hideActionsCounter && (
          <ActionsCounterBadge
            actions={
              getTotalActionsByInsights(onlyInsightIds, allActionsPlans).length
            }
            hideTitle={hideActionsCounterTitle}
            marginLeft="12px"
          />
        )}
      </div>
    );
  }
}
SwotCustomization.propTypes = {
  from: PropTypes.string.isRequired,
  insightsToCalc: PropTypes.array.isRequired,
  showProgressEvaluation: PropTypes.bool,
};
SwotCustomization.defaultProps = {
  showProgressEvaluation: true,
};

const mapStateToProps = (state) => {
  const { swotEvaluation } = state;
  const allSwotEvaluation =
    swotEvaluation && swotEvaluation.allSwotEvaluation
      ? swotEvaluation.allSwotEvaluation
      : [];

  const { allActionsPlans = [] } = getActionsPlanStates(state);

  return {
    allSwotEvaluation,
    allActionsPlans,
  };
};
export default injectIntl(connect(mapStateToProps)(SwotCustomization));
