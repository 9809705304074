import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import NextStep from "./NextStep";
import GoalsBonus from "./tools/GoalsBonus";
import KPIs from "./tools/KPIs";
import OKR from "./tools/OKR";
import SkillsAssessment from "./tools/SkillsAssessment";
import {
  GOALS_AND_BONUS,
  KPIS,
  OKR_ID,
  SKILLS_ASSESSMENT,
} from "../../../../../constants/tools.constants";

const Wrapper = styled.div`
  h2 {
    font-size: 20px;
    width: 100%;
  }
`;

const Step3 = ({
  configuration,
  importLoading,
  importConfig,
  initImportConfig,
  updateSingleImportConfig,
  handleImportTemplate,
  toolTypeId,
  customPeriods,
}) => {
  const append = {
    configuration,
    initImportConfig,
    updateSingleImportConfig,
    importConfig,
    customPeriods,
  };

  return (
    <Wrapper className="row">
      <div className="col-xs-12">
        {!importLoading && (
          <h2>Passo #3: Escolha os dados que serão importados</h2>
        )}
        {importLoading && (
          <center>
            <h2>Aguarde enquanto os dados são importados...</h2>
            <div>
              <CircularProgress />
            </div>
          </center>
        )}
        {!importLoading && (
          <div>
            {toolTypeId === GOALS_AND_BONUS && <GoalsBonus {...append} />}
            {toolTypeId === KPIS && <KPIs {...append} />}
            {toolTypeId === OKR_ID && <OKR {...append} />}
            {toolTypeId === SKILLS_ASSESSMENT && (
              <SkillsAssessment {...append} />
            )}
          </div>
        )}
      </div>
      {!importLoading && (
        <NextStep
          onClick={() => {
            handleImportTemplate();
          }}
          text="Importar dados"
          disabled={!importConfig.custom_period_id}
        />
      )}
    </Wrapper>
  );
};

export default Step3;
