import update from "immutability-helper";

import {
  SET_SELECTED_WORKSPACE,
  FETCH_USER_WORKSPACES,
  FETCH_WORKSPACE_OVERVIEW,
  FETCH_WORKSPACE_OVERVIEW_INSIGHT_COUNTER,
  FETCH_WORKSPACE_MEMBERS,
  FETCH_USER_OWNER_COMPANY_WORKSPACES,
  FETCH_USER_MEMBER_COMPANY_WORKSPACES,
  FETCH_USER_MEMBER_COMPANY_TEAM_WORKSPACES,
  CREATE_WORKSPACE,
  FETCH_WORKSPACE_PARTICIPANTS,
  UPDATE_WORKSPACES,
  GET_WORKSPACE_TEAM,
} from "../actions/types";

const INITIAL_STATE = {
  userWorkspaces: [],
  workspaceOverview: [],
  workspaceOverviewInsightCounter: [],
  workspaceMembers: [],
  workspaceParticipants: [],
  userOwnerCompanyWorkspaces: [],
  userMemberCompanyWorkspaces: [],
  userMemberTeamCompanyWorkspaces: [],
  workspaceTeams: [],
};

const workspacesReducer = (state = INITIAL_STATE, action) => {
  const updateStateUserWorkspaces = (stateUserWorkspaces, currentWorkspace) => {
    function checkIsEqualAndUpdate(workspace) {
      if (workspace.id === currentWorkspace.payload.id) {
        return currentWorkspace.payload;
      }
      return workspace;
    }

    const updatedStateUserWorkspaces = stateUserWorkspaces.map(
      checkIsEqualAndUpdate,
    );

    return updatedStateUserWorkspaces;
  };

  switch (action.type) {
    case SET_SELECTED_WORKSPACE:
      return update(state, {
        selectedWorkspace: { $set: action.payload },
      });
    case FETCH_USER_WORKSPACES:
      return update(state, {
        userWorkspaces: { $set: action.payload },
      });
    case FETCH_USER_OWNER_COMPANY_WORKSPACES:
      return update(state, {
        userOwnerCompanyWorkspaces: { $set: action.payload },
      });
    case FETCH_USER_MEMBER_COMPANY_WORKSPACES:
      return update(state, {
        userMemberCompanyWorkspaces: { $set: action.payload },
      });
    case FETCH_USER_MEMBER_COMPANY_TEAM_WORKSPACES:
      return update(state, {
        userMemberTeamCompanyWorkspaces: { $set: action.payload },
      });
    case FETCH_WORKSPACE_MEMBERS:
      return update(state, {
        workspaceMembers: { $set: action.payload },
      });
    case FETCH_WORKSPACE_PARTICIPANTS:
      return update(state, {
        workspaceParticipants: { $set: action.payload },
      });
    case FETCH_WORKSPACE_OVERVIEW:
      return update(state, {
        workspaceOverview: { $set: action.payload },
      });
    case FETCH_WORKSPACE_OVERVIEW_INSIGHT_COUNTER:
      return update(state, {
        workspaceOverviewInsightCounter: { $set: action.payload },
      });
    case UPDATE_WORKSPACES:
      return {
        ...state,
        userWorkspaces: updateStateUserWorkspaces(
          state.userWorkspaces,
          action.payload,
        ),
      };
    case CREATE_WORKSPACE:
      const workspace = action.payload;
      if (workspace.company !== null && workspace.company.id) {
        return update(state, {
          userOwnerCompanyWorkspaces: { $push: [action.payload] },
        });
      }
      return update(state, {
        userWorkspaces: { $push: [action.payload] },
      });

    case GET_WORKSPACE_TEAM:
      return { ...state, workspaceTeams: action.payload };
    default:
      return state;
  }
};

export default workspacesReducer;
