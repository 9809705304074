import React from "react";
import { defineMessages } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionActions";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PreviewTable from "../../PreviewTable";
import { translatedText } from "../../../../utils/translationUtils";

const messages = defineMessages({
  measu_error_description: {
    id: "measu_error_description",
  },
  measu_validated_data: {
    id: "measu_validated_data",
  },
  measu_non_validated_data: {
    id: "measu_non_validated_data",
  },
  measu_lines: {
    id: "measu_lines",
  },
});

const translation = (id, values) => translatedText(id, messages, values);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(25),
    color: theme.palette.text.secondary,
  },
}));

const ValidationInfo = ({
  validatedData = [],
  nonValidatedData = [],
  loading,
  headers = {},
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const nonValidatedHeader = { ...headers };
  nonValidatedHeader.errorType = translation("measu_error_description");

  return (
    <div className={classes.root}>
      {loading && <h4 align="center">Aguarde...</h4>}
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            {translation("measu_validated_data")}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {validatedData.length + translation("measu_lines")}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          {validatedData.length > 0 && (
            <PreviewTable headers={headers} data={validatedData} />
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>
            {translation("measu_non_validated_data")}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {nonValidatedData.length + translation("measu_lines")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {nonValidatedData.length > 0 && (
            <PreviewTable
              headers={nonValidatedHeader}
              data={nonValidatedData}
              handleErrorMessage={(value) => value}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ValidationInfo;
