import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import { Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import ActionButton from "../../../../../../Common/FabActionButton";
import ConfirmationDialog from "../../../../../../Common/ConfirmationDialog";
import utils from "../../../../../../../utils/toolUtils";
import { DisplayComments } from "../../../../../../../utils/commentUtils";
import { getMaxStringLength } from "../../../../../../../utils/StringUtils";
import { fetchGroupedComments } from "../../../../../../../actions/insightTimelineActions";
import {
  onlyAuditorAdminWorkflowGoalAccess,
  allowedOutsideApprovalPeriod,
  blockedAuditorAccess,
} from "../../../../../../../constants/goalsAndBonus";
import Avatar from "../../../../../../Common/Avatar";
import {
  getDefaultTargetCols,
  displayTransformValue,
} from "../../../../../../../utils/kpis/display";

const {
  getObjectInfoById,
  getGroupedComments,
  getTimelineRelationDisplay,
} = utils;

const messages = defineMessages({
  gb_table_text_goal_upper: { id: "gb_table_text_goal_upper" },
  gb_table_text_no_goal: { id: "gb_table_text_no_goal" },
  gb_table_text_relationship: { id: "gb_table_text_relationship" },
  gb_table_text_status: { id: "gb_table_text_status" },
  gb_table_text_weight: { id: "gb_table_text_weight" },
  gb_form_unity: { id: "gb_form_unity" },
  gb_form_validation: { id: "gb_form_validation" },
  gb_form_job_role: { id: "gb_form_job_role" },
  gb_form_job_team: { id: "gb_form_job_team" },
  gb_form_description: { id: "gb_form_description" },
  gb_form_comments: { id: "gb_form_comments" },
  gb_form_no_comments: { id: "gb_form_no_comments" },
  gb_workflow_next: { id: "gb_workflow_next" },
  gb_workflow_prev: { id: "gb_workflow_prev" },
  gb_workflow_warning_weight: { id: "gb_workflow_warning_weight" },
  gb_workflow_warning_own_goals: { id: "gb_workflow_warning_own_goals" },
  gb_workflow_warning_status_change: {
    id: "gb_workflow_warning_status_change",
  },
  gb_workflow_change_to: { id: "gb_workflow_change_to" },
  gb_warning_not_approved_to_audit: { id: "gb_warning_not_approved_to_audit" },
  gb_warning_goal_not_audited: {
    id: "gb_warning_goal_not_audited",
  },
  gb_warning_period_between_blocked: {
    id: "gb_warning_period_between_blocked",
  },
  gb_table_text_approval: { id: "gb_table_text_approval" },
  gb_table_text_audit: { id: "gb_table_text_audit" },
});

const defaultAlertDialog = { open: false, title: "" };

const WorkflowTable = (props) => {
  const [alertDialog, setAlertDialog] = useState(defaultAlertDialog);
  const [comments, setComments] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState({});

  const {
    metaInfo = {},
    goals = [],
    statusFilter = "ANY",
    goalValidationStatusOptions = [],
    relationshipTypeOptions = [],
    measurementUnityOptions = [],
    handleChangeWorkflowStatus,
    emptyMessage,
    isLoading,
    allComments,
    fetchGroupedComments,
    userId,
    haveWorkflowFullAccess,
    haveAuditorAcess,
    approvalAllowed = false,
    auditAllowed = false,
    administrationDates = {},
    intl,
  } = props;

  let { goToNext, goToPrevious } = props;

  const {
    startApproval,
    endApproval,
    startAudit,
    endAudit,
  } = administrationDates;

  const {
    participantInfo = {},
    questionId = null,
    roleInfo = {},
    teamInfo = {},
  } = metaInfo;

  const { memberParticipant = {} } = participantInfo;

  const onlyParticipantAccess =
    memberParticipant.id === userId && !haveWorkflowFullAccess;

  goToNext = typeof goToNext === "function" ? goToNext : null;
  goToPrevious = typeof goToPrevious === "function" ? goToPrevious : null;

  useEffect(() => {
    if (allComments && selectedGoal.id) {
      const insightsList = selectedGoal.relatedInsights || [];

      if (insightsList.length > 0) {
        const groupedComments = getGroupedComments(insightsList, allComments);
        const timelineRelationDisplay = getTimelineRelationDisplay(
          userId,
          groupedComments,
        );
        if (!_.isEqual(comments, timelineRelationDisplay)) {
          setComments(timelineRelationDisplay);
        }
      }
    }
  }, [allComments, comments, selectedGoal, userId]);

  const toggleSelectedGoal = (goalInfo = {}) => {
    if (selectedGoal.id === goalInfo.id) {
      setSelectedGoal({});
    } else if (goalInfo) {
      setSelectedGoal(goalInfo);

      if (goalInfo.relatedInsights && goalInfo.relatedInsights.length > 0)
        fetchGroupedComments(goalInfo.relatedInsights);
    }
  };

  const isInvalidTotalWeight = (weight) => {
    return weight > 100 || weight < 100;
  };

  const resetAlertDialog = () => {
    setAlertDialog(defaultAlertDialog);
    setSelectedGoal({});
  };

  const getWarningField = (msg) => {
    return (
      <h5 className="text-warning">
        <i className="fas fa-exclamation-triangle" /> {msg}
      </h5>
    );
  };

  const getWarningMessage = (
    mainTextId,
    itemDescriptionId,
    startDate,
    endDate,
  ) => {
    return getWarningField(
      intl.formatMessage(messages[mainTextId], {
        startDate: moment(startDate).format("L"),
        endDate: moment(endDate).format("L"),
        period: intl.formatMessage(messages[itemDescriptionId]),
      }),
    );
  };

  const alertWorkflowStatus = ({
    blockByAudit,
    blockByPeriodDates,
    notApprovedToAudit,
    notApprovedToValidated,
  }) => {
    if (blockByAudit) {
      setAlertDialog({
        open: true,
        title: getWarningMessage(
          "gb_warning_period_between_blocked",
          "gb_table_text_audit",
          startAudit,
          endAudit,
        ),
      });
    }

    if (blockByPeriodDates) {
      setAlertDialog({
        open: true,
        title: getWarningMessage(
          "gb_warning_period_between_blocked",
          "gb_table_text_approval",
          startApproval,
          endApproval,
        ),
      });
    }

    if (notApprovedToAudit) {
      setAlertDialog({
        open: true,
        title: getWarningField(
          intl.formatMessage(messages.gb_warning_not_approved_to_audit),
        ),
      });
    }

    if (notApprovedToValidated) {
      setAlertDialog({
        open: true,
        title: getWarningField(
          intl.formatMessage(messages.gb_warning_goal_not_audited),
        ),
      });
    }

    return null;
  };

  const tableStyle = {
    margin: "0px",
    padding: "0px 25px 25px 25px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px",
  };

  const titleStyle = {
    width: "50px",
    display: "inline-block",
    fontSize: "14px",
  };

  const arrowStyle = {
    color: "#666",
    fontSize: "14px",
    position: "relative",
    top: "-1px",
  };

  const buttonDivStyle = {
    fontSize: "16px",
    border: "1px solid #ccc",
    padding: "10px 20px",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    userSelect: "none",
  };

  const getArrows = (goToPrevious, goToNext) => {
    return (
      <div className="row">
        <ConfirmationDialog
          open={alertDialog.open}
          title={alertDialog.title}
          onConfirm={() => resetAlertDialog()}
          onCancel={() => resetAlertDialog()}
          confirmText="OK"
          hideCancel
        />
        <div
          className="col-xs-6 gray-hover"
          style={{
            ...buttonDivStyle,
            borderTopRightRadius: "0px",
            opacity: goToPrevious ? "1" : "0.3",
            cursor: goToPrevious ? "pointer" : "not-allowed",
          }}
          onClick={goToPrevious}
        >
          <i className="fas fa-chevron-left" style={arrowStyle} />{" "}
          {intl.formatMessage(messages.gb_workflow_prev)}
        </div>
        <div
          className="col-xs-6 gray-hover"
          align="right"
          style={{
            ...buttonDivStyle,
            borderTopLeftRadius: "0px",
            opacity: goToNext ? "1" : "0.3",
            cursor: goToNext ? "pointer" : "not-allowed",
          }}
          onClick={goToNext}
        >
          {intl.formatMessage(messages.gb_workflow_next)}{" "}
          <i className="fas fa-chevron-right" style={arrowStyle} />
        </div>
      </div>
    );
  };

  const emptyGoals = goals.length === 0;

  let processGoalsTotalWeight = 0;
  goals.forEach((goalInfo) => {
    processGoalsTotalWeight += parseFloat(goalInfo.weight);
  });

  const greaterOrLowerWeight =
    processGoalsTotalWeight > 100 ? "maior" : "menor";

  return (
    <div className="row workflow-container">
      {getArrows(goToPrevious, goToNext)}
      {!emptyGoals && !isLoading && (
        <div style={tableStyle}>
          <div className="col-xs-12" style={{ padding: "20px 0px" }}>
            <Avatar
              className="member-avatar"
              name={memberParticipant.name}
              src={memberParticipant.avatarBlobId}
            />
            <div className="col workflow-header">
              <h4
                style={{
                  marginTop: "0px",
                  position: "relative",
                  left: "-20px",
                }}
              >
                {memberParticipant.name}
              </h4>
              <h5 style={{ position: "relative", left: "-12px" }}>
                <font style={titleStyle}>
                  {intl.formatMessage(messages.gb_form_job_role)}:{" "}
                </font>
                {roleInfo && roleInfo.role}
              </h5>
              <h5 style={{ position: "relative", left: "-12px" }}>
                <font style={titleStyle}>
                  {intl.formatMessage(messages.gb_form_job_team)}:{" "}
                </font>
                {teamInfo && teamInfo.name}
              </h5>
            </div>
          </div>
          <table className="simple-table">
            <thead>
              <tr className="block-white-space small-font">
                <td>{intl.formatMessage(messages.gb_table_text_goal_upper)}</td>
                <td>
                  {intl.formatMessage(messages.gb_table_text_relationship)}
                </td>
                <td>{intl.formatMessage(messages.gb_table_text_weight)}</td>
                <td>{intl.formatMessage(messages.gb_form_unity)}</td>
                {getDefaultTargetCols()}
                <td>{intl.formatMessage(messages.gb_form_description)}</td>
                <td>{intl.formatMessage(messages.gb_form_validation)}</td>
                <td>{intl.formatMessage(messages.gb_form_comments)}</td>
              </tr>
            </thead>
            <tbody>
              {goals.length > 0
                ? goals.map((goalInfo, index) => {
                    const { workflowStatus = null, target = {} } = goalInfo;
                    const onSelectedFilter =
                      statusFilter === "ANY" || workflowStatus === statusFilter;

                    const isGoalSelected = selectedGoal.id === goalInfo.id;
                    const onlyAdministrativeChangeAccess =
                      onlyAuditorAdminWorkflowGoalAccess.indexOf(
                        goalInfo.relationshipType,
                      ) > -1;

                    const clickStyles = {
                      cursor: "pointer",
                      userSelect: "none",
                    };

                    return (
                      <React.Fragment key={`${index}${workflowStatus}`}>
                        <tr
                          style={!onSelectedFilter ? { opacity: "0.6" } : {}}
                          className="small-font"
                        >
                          <td
                            style={{
                              borderLeft: isGoalSelected
                                ? "3px solid #6b42a9"
                                : "1px solid #ccc",
                            }}
                            width="200"
                          >
                            <Tooltip title={goalInfo.title}>
                              <span>
                                {getMaxStringLength({
                                  string: goalInfo.title,
                                  beginIndex: 0,
                                  endIndex: 78,
                                  additional: "...",
                                })}
                              </span>
                            </Tooltip>
                          </td>
                          <td width="115" align="center">
                            {
                              getObjectInfoById(
                                goalInfo.relationshipType,
                                relationshipTypeOptions,
                                "value",
                              ).label
                            }
                          </td>
                          <td align="center" width="46">
                            {goalInfo.weight}%
                          </td>
                          <td align="center" width="87">
                            {
                              getObjectInfoById(
                                goalInfo.measurementUnity,
                                measurementUnityOptions,
                                "value",
                              ).label
                            }
                            {goalInfo.measurementUnity === "OTHER" &&
                              ` (${goalInfo.otherMeasurement})`}
                          </td>
                          <td align="right">
                            {displayTransformValue(
                              target.gate,
                              goalInfo.measurementUnity,
                            )}
                          </td>
                          <td align="right">
                            {displayTransformValue(
                              target.appropriated,
                              goalInfo.measurementUnity,
                            )}
                          </td>
                          <td align="right">
                            {displayTransformValue(
                              target.exceeded,
                              goalInfo.measurementUnity,
                            )}
                          </td>
                          <td width="200">
                            <Tooltip title={goalInfo.description}>
                              <span>
                                {getMaxStringLength({
                                  string: goalInfo.description,
                                  beginIndex: 0,
                                  endIndex: 78,
                                  additional: "...",
                                })}
                              </span>
                            </Tooltip>
                          </td>
                          <td style={{ width: "300px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flex: "auto",
                              }}
                            >
                              {goalValidationStatusOptions.map(
                                (
                                  { value, statusLabel, icon, color, contrast },
                                  index,
                                ) => {
                                  const isCurrentStatus =
                                    workflowStatus === value;
                                  const isCreatingStatus =
                                    workflowStatus === "CREATING";
                                  const isApprovalButton = value === "APPROVAL";

                                  const isOneOfSimpleStatus =
                                    allowedOutsideApprovalPeriod.indexOf(
                                      value,
                                    ) > -1;

                                  const isAuditStatus = value === "AUDIT";
                                  const isValidatedStatus =
                                    value === "VALIDATED";

                                  const anyAuditStatus =
                                    isAuditStatus || isValidatedStatus;

                                  const blockByAudit =
                                    isAuditStatus && !auditAllowed;

                                  const notApprovedToAudit =
                                    workflowStatus !== "APPROVED" &&
                                    isAuditStatus;

                                  const notApprovedToValidated =
                                    workflowStatus !== "AUDIT" &&
                                    isValidatedStatus;

                                  const blockByPeriodDates =
                                    !approvalAllowed &&
                                    !isOneOfSimpleStatus &&
                                    !anyAuditStatus;

                                  const blockByAccess =
                                    !haveWorkflowFullAccess &&
                                    onlyAdministrativeChangeAccess;

                                  const blockByAuditor =
                                    !haveWorkflowFullAccess &&
                                    haveAuditorAcess &&
                                    blockedAuditorAccess.indexOf(value) > -1;

                                  const blockByParticipant =
                                    onlyParticipantAccess &&
                                    (!isCreatingStatus ||
                                      !isApprovalButton ||
                                      isInvalidTotalWeight(
                                        processGoalsTotalWeight,
                                      ));

                                  const blockButtonChanges =
                                    isCurrentStatus ||
                                    blockByAccess ||
                                    blockByAuditor ||
                                    blockByParticipant ||
                                    blockByPeriodDates ||
                                    blockByAudit ||
                                    notApprovedToAudit ||
                                    notApprovedToValidated ||
                                    isCurrentStatus;

                                  const changeToStatusMsg = intl.formatMessage(
                                    messages.gb_workflow_change_to,
                                    { status: statusLabel },
                                  );

                                  return (
                                    <ActionButton
                                      key={`${index}${statusFilter}`}
                                      size="small"
                                      text={icon}
                                      label={
                                        blockButtonChanges
                                          ? ""
                                          : changeToStatusMsg
                                      }
                                      color={isCurrentStatus ? color : "#eee"}
                                      contrast={
                                        isCurrentStatus ? contrast : "#222"
                                      }
                                      blockHover={blockButtonChanges}
                                      cursor={
                                        blockButtonChanges
                                          ? "not-allowed"
                                          : null
                                      }
                                      onClick={
                                        onSelectedFilter && !blockButtonChanges
                                          ? () =>
                                              handleChangeWorkflowStatus(
                                                goalInfo.id,
                                                value,
                                                questionId,
                                                statusFilter,
                                              )
                                          : () =>
                                              alertWorkflowStatus({
                                                blockByAudit,
                                                blockByPeriodDates,
                                                notApprovedToAudit,
                                                notApprovedToValidated,
                                              })
                                      }
                                    />
                                  );
                                },
                              )}
                            </div>
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              ...clickStyles,
                            }}
                            width="93"
                            onClick={() => toggleSelectedGoal(goalInfo)}
                            title={intl.formatMessage(
                              messages.gb_form_comments,
                            )}
                          >
                            {!isGoalSelected && (
                              <i className="fas fa-angle-right" />
                            )}
                            {isGoalSelected && (
                              <i className="fas fa-angle-down" />
                            )}
                          </td>
                        </tr>
                        {isGoalSelected && comments.length > 0 && (
                          <tr className="block-hover">
                            <td colSpan="100">
                              <div
                                style={{
                                  maxHeight: "500px",
                                  overflowX: "auto",
                                  padding: "20px",
                                }}
                              >
                                <h5
                                  style={{
                                    marginTop: "0px",
                                    padding: "0px 5px",
                                  }}
                                >
                                  {intl.formatMessage(
                                    messages.gb_form_comments,
                                  )}
                                </h5>
                                <div
                                  className="comments-display"
                                  style={{
                                    width: "50%",
                                  }}
                                >
                                  <DisplayComments
                                    timelineComments={comments}
                                    saveEditedComment={null}
                                    timelineDisplay={false}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                        {isGoalSelected && comments.length === 0 && (
                          <tr>
                            <td colSpan="100">
                              <h6 align="center">
                                {intl.formatMessage(
                                  messages.gb_form_no_comments,
                                )}
                              </h6>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })
                : null}
            </tbody>
          </table>
          {!emptyGoals && isInvalidTotalWeight(processGoalsTotalWeight) && (
            <h6 className="text-danger well">
              {intl.formatMessage(messages.gb_workflow_warning_weight, {
                order: greaterOrLowerWeight,
              })}
            </h6>
          )}
          {onlyParticipantAccess && (
            <h6 className="well">
              {intl.formatMessage(messages.gb_workflow_warning_own_goals)}
            </h6>
          )}
          {!onlyParticipantAccess && !haveWorkflowFullAccess && (
            <h6 className="well">
              {intl.formatMessage(messages.gb_workflow_warning_status_change)}
            </h6>
          )}
        </div>
      )}
      {emptyGoals && emptyMessage && !isLoading && (
        <h6 align="center" style={{ padding: "100px 20px" }}>
          {emptyMessage}
        </h6>
      )}
    </div>
  );
};

export default injectIntl(
  connect(null, { fetchGroupedComments })(WorkflowTable),
);
