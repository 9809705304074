const pt = {
  ph_search: "Buscar",
  checkbox_only_members: "Apenas Membros",
  checkbox_only_participants: "Apenas Participantes",
  checkbox_select_all: "Selecionar todos",
  checkbox_only_selected: "Apenas selecionados",
  text_results_found: "Nenhum resultado encontrado",
  text_select_team: "Selecione um time",
};

const en = {
  ph_search: "Search",
  checkbox_only_members: "Only Members",
  checkbox_only_participants: "Only Participants",
  checkbox_select_all: "Select all",
  checkbox_only_selected: "Only selected",
  text_results_found: "No results found",
  text_select_team: "Select a team",
};

const es = {
  ph_search: "Buscar",
  checkbox_only_members: "Solo miembros",
  checkbox_only_participants: "Solo Participants",
  checkbox_select_all: "Todos",
  checkbox_only_selected: "Solo seleccionado",
  text_results_found: "No se han encontrado resultados",
  text_select_team: "Selecciona un equipo",
};

const translations = { pt, en, es };

export default translations;
