import { SELECTED_LANGUAGE, SET_LANGUAGE } from "./types";

export const getLanguage = () => {
  const selectedLang = localStorage.getItem(SELECTED_LANGUAGE);
  if (selectedLang) {
    return selectedLang;
  } else {
    const browserLang =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage;
    localStorage.setItem(SELECTED_LANGUAGE, browserLang);
    return browserLang;
  }
};

export const setLanguage = (lang) => {
  return { type: SET_LANGUAGE, payload: lang };
};
