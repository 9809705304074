/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import { useState, useEffect } from "react";
import { getOnlySublevelArrays } from "../ArrayUtils";
import { getFilteredQuestionsAndInsights, getInsightQuestions } from "./utils";

export function useFilterInsights({
  filter = [],
  allQuestions = {},
  allInsights = {},
}) {
  const [insights, setInsights] = useState([]);
  const [filteredInsights, setFilteredInsights] = useState([]);

  function reset() {
    if (insights.length > 0) setInsights([]);
    if (filteredInsights.length > 0) setFilteredInsights([]);
  }

  useEffect(() => {
    if (Array.isArray(filter.themes)) {
      const { insights: newInsights } = getFilteredQuestionsAndInsights(
        filter.themes,
        allQuestions,
        allInsights,
      );

      if (!_.isEqual(newInsights, insights)) {
        setInsights(newInsights);
      }
    } else {
      reset();
    }
  }, [filter.themes, allQuestions, allInsights]);

  useEffect(() => {
    const filteredQuestionsInsights = [];

    if (filter?.questions && insights) {
      filter?.questions?.forEach((data) => {
        insights?.forEach((item) => {
          if (data === item?.question?.id) {
            filteredQuestionsInsights.push(item);
          }
        });
      });
    }

    setFilteredInsights(filteredQuestionsInsights);
  }, [insights, filter?.questions]);

  return { filteredInsights };
}

export function useInsightQuestions({ insights = [] }) {
  const [questions, setQuestions] = useState([]);

  function reset() {
    if (questions.length > 0) {
      setQuestions([]);
    }
  }

  useEffect(() => {
    if (Array.isArray(insights)) {
      const { questions: newQuestions } = getInsightQuestions(insights);

      if (!_.isEqual(newQuestions, questions)) setQuestions(newQuestions);
    } else {
      reset();
    }
  }, [insights]);

  return { questions, setQuestions };
}

export function useFilteredInsights({ allAnswers = {} }) {
  const [filteredInsights, setFilteredInsights] = useState([]);
  useEffect(() => {
    const onlyInsights = getOnlySublevelArrays(allAnswers);

    if (!_.isEqual(onlyInsights, filteredInsights))
      setFilteredInsights(onlyInsights);
  }, [allAnswers]);

  return { filteredInsights, setFilteredInsights };
}
