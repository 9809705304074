import React from "react";
import { defineMessages } from "react-intl";
import { getValidFunc } from "./validations";
import { getOnlySlugValues } from "./ArrayUtils";
import { getGoalsBonusAccessLevels } from "./goalsBonus/validation";
import {
  PEOPLE_BIG_PICTURE,
  SWOT,
  PROJECT_PRIORITIZATION,
  GOALS_AND_BONUS,
  OKR_ID,
  KPIS,
  toolInsightsOrder,
  PROJECT_PORTFOLIO,
  COMPETITIVE_ANALYSIS,
  SKILLS_ASSESSMENT,
} from "../constants/tools.constants";
import {
  PESTAL,
  SWOT as SWOT_CONST,
  CONCURRENCE,
} from "../constants/competitiveAnalysis";

const messages = defineMessages({
  edit: { id: "global.edit" },
  archive: { id: "global.archive" },
  doDelete: { id: "global.delete" },
  permissions: { id: "global.permissions" },
});

export const getEntityMenuActions = (
  edit,
  archive,
  doDelete,
  isFiled = false,
  config = {},
  intl,
  unarchive,
  administration,
  onMoveEntityDialog,
) => {
  edit = getValidFunc(edit);
  archive = getValidFunc(archive);
  unarchive = getValidFunc(unarchive);
  doDelete = getValidFunc(doDelete);

  const actions = [];

  if (!config.blockEdit)
    actions.push({
      icon: <i className="far fa-edit" />,
      text: intl ? intl.formatMessage(messages.edit) : "Edit",
      action: () => edit(),
    });

  if (!isFiled && !config.blockArchive)
    actions.push({
      icon: <i className="fas fa-archive" />,
      text: intl ? intl.formatMessage(messages.archive) : "Archive",
      action: () => archive(),
    });

  if (isFiled && !config.blockDelete)
    actions.push({
      icon: <i className="fas fa-archive" />,
      text: intl ? "Desarquivar" : "Desarquivar",
      action: () => unarchive(),
    });

  if (isFiled && !config.blockDelete)
    actions.push({
      icon: <i className="far fa-trash-alt" />,
      text: intl ? intl.formatMessage(messages.doDelete) : "Delete",
      action: () => doDelete(),
    });

  if (!isFiled && administration)
    actions.push({
      icon: <i className="fas fa-user-cog" />,
      text: intl ? intl.formatMessage(messages.permissions) : "Administration",
      action: () => administration(),
    });

  if (!isFiled && onMoveEntityDialog && config.supportedEntityMove)
    actions.push({
      icon: <i className="fas fa-sitemap" />,
      text: "Mover",
      action: () => onMoveEntityDialog(),
    });

  return actions;
};

export const getCompetitiveAnalysisConfig = (
  type = "question",
  currentToolType = "",
) => {
  const configurations = {
    registrations: true,
    registrationMiddleware: true,
  };

  if (type === "question") {
    const blockRegistrations = [PESTAL, SWOT_CONST];

    if (blockRegistrations.indexOf(currentToolType) > -1) {
      configurations.registrations = false;
    }
  }

  if (type === "insight") {
    const blockMiddleware = [CONCURRENCE];

    if (blockMiddleware.indexOf(currentToolType) > -1) {
      configurations.registrationMiddleware = false;
    }
  }

  return configurations;
};

export const getEntityConfigurations = (
  type = "cenary",
  toolId,
  replaceConfig = {},
) => {
  let configurations = {
    customName: false,
    blockEdit: false,
    blockArchive: false,
    blockDelete: false,
    draggable: false,
    blockTemplate: false,
    registrations: true,
    registrationMiddleware: false,
    supportedEntityMove: false,
  };

  const check = (array) => {
    return Array.isArray(array) && array.indexOf(toolId) > -1;
  };

  const cenaryCustomNameTools = [
    GOALS_AND_BONUS,
    KPIS,
    OKR_ID,
    COMPETITIVE_ANALYSIS,
    SKILLS_ASSESSMENT,
  ];
  const cenaryRegistrationMiddleware = [
    GOALS_AND_BONUS,
    KPIS,
    OKR_ID,
    COMPETITIVE_ANALYSIS,
  ];
  const cenaryBlockEdit = [GOALS_AND_BONUS, KPIS, OKR_ID, COMPETITIVE_ANALYSIS];

  const cenaryBlockTemplate = [SKILLS_ASSESSMENT];

  if (type === "cenary") {
    configurations.customName = check(cenaryCustomNameTools)
      ? true
      : configurations.customName;

    configurations.registrationMiddleware = check(cenaryRegistrationMiddleware)
      ? true
      : configurations.registrationMiddleware;

    configurations.blockEdit = check(cenaryBlockEdit)
      ? true
      : configurations.blockEdit;
    configurations.blockTemplate = check(cenaryBlockTemplate)
      ? true
      : configurations.blockTemplate;
  }

  const themeCustomNameTools = [
    GOALS_AND_BONUS,
    KPIS,
    PROJECT_PRIORITIZATION,
    OKR_ID,
    COMPETITIVE_ANALYSIS,
    SKILLS_ASSESSMENT,
  ];
  const themeRegistrationMiddleware = [
    GOALS_AND_BONUS,
    KPIS,
    PROJECT_PRIORITIZATION,
    OKR_ID,
    COMPETITIVE_ANALYSIS,
    SKILLS_ASSESSMENT,
  ];
  const themeBlockEdit = [
    GOALS_AND_BONUS,
    KPIS,
    PROJECT_PRIORITIZATION,
    OKR_ID,
    SWOT,
    COMPETITIVE_ANALYSIS,
    SKILLS_ASSESSMENT,
  ];

  const themeBlockRegistrations = [SWOT];
  const themeBlockArchive = [SWOT];
  if (type === "theme") {
    configurations.customName = check(themeCustomNameTools)
      ? true
      : configurations.customName;

    configurations.registrationMiddleware = check(themeRegistrationMiddleware)
      ? true
      : configurations.registrationMiddleware;

    configurations.blockEdit = check(themeBlockEdit)
      ? true
      : configurations.blockEdit;
    configurations.registrations = !check(themeBlockRegistrations);
    configurations.blockArchive = !!check(themeBlockArchive);
  }

  const questionCustomNameTools = [
    GOALS_AND_BONUS,
    KPIS,
    PROJECT_PRIORITIZATION,
    OKR_ID,
    COMPETITIVE_ANALYSIS,
    SKILLS_ASSESSMENT,
  ];
  const questionRegistrationMiddleware = [
    GOALS_AND_BONUS,
    KPIS,
    PROJECT_PRIORITIZATION,
    OKR_ID,
    COMPETITIVE_ANALYSIS,
    SKILLS_ASSESSMENT,
  ];
  const questionBlockEdit = [
    GOALS_AND_BONUS,
    KPIS,
    PROJECT_PRIORITIZATION,
    OKR_ID,
    SWOT,
    COMPETITIVE_ANALYSIS,
    SKILLS_ASSESSMENT,
  ];
  const questionBlockRegistrations = [SWOT];
  const questionBlockArchive = [SWOT];

  if (type === "question") {
    configurations.customName = check(questionCustomNameTools)
      ? true
      : configurations.customName;

    configurations.registrationMiddleware = check(
      questionRegistrationMiddleware,
    )
      ? true
      : configurations.registrationMiddleware;

    configurations.blockEdit = check(questionBlockEdit)
      ? true
      : configurations.blockEdit;
    configurations.registrations = !check(questionBlockRegistrations);
    configurations.blockArchive = !!check(questionBlockArchive);
  }

  const insightCustomNameTools = [
    GOALS_AND_BONUS,
    KPIS,
    OKR_ID,
    PEOPLE_BIG_PICTURE,
    COMPETITIVE_ANALYSIS,
    SKILLS_ASSESSMENT,
  ];
  const insightRegistrationMiddleware = [
    GOALS_AND_BONUS,
    KPIS,
    OKR_ID,
    PEOPLE_BIG_PICTURE,
    COMPETITIVE_ANALYSIS,
    SKILLS_ASSESSMENT,
  ];

  const insightBlockEdit = [
    GOALS_AND_BONUS,
    KPIS,
    OKR_ID,
    PEOPLE_BIG_PICTURE,
    SKILLS_ASSESSMENT,
  ];

  const supportedInsightMoveEntity = [SKILLS_ASSESSMENT];

  if (type === "insight") {
    configurations.customName = check(insightCustomNameTools)
      ? true
      : configurations.customName;

    configurations.registrationMiddleware = check(insightRegistrationMiddleware)
      ? true
      : configurations.registrationMiddleware;

    configurations.blockEdit = check(insightBlockEdit)
      ? true
      : configurations.blockEdit;
    configurations.supportedEntityMove = check(supportedInsightMoveEntity)
      ? true
      : configurations.supportedEntityMove;
  }

  if (replaceConfig[type]) {
    configurations = {
      ...configurations,
      ...replaceConfig[type],
    };
  }

  return configurations;
};

export const getDefaultFullAccess = (
  allSceneries = [],
  allThemes = {},
  allQuestions = {},
  allAnswers = {},
) => {
  return {
    viewAccess: {
      scenaries: getOnlySlugValues(allSceneries),
      themes: getOnlySlugValues(allThemes),
      questions: getOnlySlugValues(allQuestions),
      insights: getOnlySlugValues(allAnswers),
    },
  };
};

export const getGrantedAccessLevels = (
  toolId = null,
  allSceneries = [],
  allThemes = {},
  allQuestions = {},
  allAnswers = {},
  appendInfo = {},
) => {
  let grantedLevels = getDefaultFullAccess(
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
    appendInfo,
  );

  if (toolId === GOALS_AND_BONUS) {
    grantedLevels = getGoalsBonusAccessLevels(
      allSceneries,
      allThemes,
      allQuestions,
      allAnswers,
      appendInfo,
    );
  }

  return grantedLevels;
};

export const getFilteredLevelsByAccess = (
  allSceneries = [],
  allThemes = {},
  allQuestions = {},
  allAnswers = {},
  grantedLevels = {},
) => {
  const fSceneries = [];
  const fThemes = {};
  const fQuestions = {};
  const fAnswers = {};

  grantedLevels.scenaries = grantedLevels.scenaries || [];
  grantedLevels.themes = grantedLevels.themes || [];
  grantedLevels.questions = grantedLevels.questions || [];
  grantedLevels.insights = grantedLevels.insights || [];

  function checkIndex(list, id) {
    if (list && Array.isArray(list)) return list.indexOf(id) > -1;

    return false;
  }

  if (Array.isArray(allSceneries)) {
    allSceneries.forEach((scenaryInfo) => {
      if (checkIndex(grantedLevels.scenaries, scenaryInfo.id))
        fSceneries.push(scenaryInfo);
    });
  }

  if (typeof allThemes === "object") {
    Object.keys(allThemes).forEach((scenaryThemeId) => {
      const filtered = [];

      if (Array.isArray(allThemes[scenaryThemeId])) {
        allThemes[scenaryThemeId].forEach((themeInfo) => {
          if (checkIndex(grantedLevels.themes, themeInfo.id))
            filtered.push(themeInfo);
        });

        fThemes[scenaryThemeId] = filtered;
      }
    });
  }

  if (typeof allQuestions === "object") {
    Object.keys(allQuestions).forEach((themeQuestionId) => {
      const filtered = [];

      if (Array.isArray(allQuestions[themeQuestionId])) {
        allQuestions[themeQuestionId].forEach((questionInfo) => {
          if (checkIndex(grantedLevels.questions, questionInfo.id))
            filtered.push(questionInfo);
        });

        fQuestions[themeQuestionId] = filtered;
      }
    });
  }

  if (typeof allAnswers === "object") {
    Object.keys(allAnswers).forEach((questionInsightId) => {
      const filtered = [];

      if (Array.isArray(allAnswers[questionInsightId])) {
        allAnswers[questionInsightId].forEach((insightInfo) => {
          if (checkIndex(grantedLevels.insights, insightInfo.id))
            filtered.push(insightInfo);
        });

        fAnswers[questionInsightId] = filtered;
      }
    });
  }

  return {
    allSceneries: fSceneries,
    allThemes: fThemes,
    allQuestions: fQuestions,
    allAnswers: fAnswers,
  };
};

export const getAddEntityLabel = (
  type = "cenary",
  toolId = null,
  intl = null,
) => {
  let intlId = "entity.translation";

  if (type === "cenary") {
    intlId = "add_new_scenario";

    if (toolId === GOALS_AND_BONUS) intlId = "gb_add_new_scenario";
    if (toolId === KPIS) intlId = "kpi_add_new_scenario";
  }

  if (type === "theme") {
    intlId = "add_new_theme";

    if (toolId === GOALS_AND_BONUS) intlId = "gb_add_new_theme";
    if (toolId === KPIS) intlId = "kpi_add_new_theme";
    if (toolId === PROJECT_PRIORITIZATION) intlId = "pp_add_new_theme";
    if (toolId === OKR_ID) intlId = "theme_add";
    if (toolId === SKILLS_ASSESSMENT) intlId = "skas_add_new_theme";
  }

  if (type === "question") {
    intlId = "add_new_question";

    if (toolId === GOALS_AND_BONUS) intlId = "gb_add_new_question";
    if (toolId === KPIS) intlId = "kpi_add_new_question";
    if (toolId === PROJECT_PRIORITIZATION) intlId = "pp_add_new_question";
    if (toolId === OKR_ID) intlId = "question_add";
    if (toolId === PROJECT_PORTFOLIO) intlId = "global.add_new_subTheme";
    if (toolId === SKILLS_ASSESSMENT) intlId = "skas_add_new_question";
  }

  if (type === "insight") {
    intlId = "add_new_insight";

    if (toolId === GOALS_AND_BONUS) intlId = "gb_add_new_insight";
    if (toolId === KPIS) intlId = "kpi_add_new_insight";
    if (toolId === PROJECT_PRIORITIZATION) intlId = "pp_add_new_insight";
    if (toolId === OKR_ID) intlId = "insight_add";
    if (toolId === PROJECT_PORTFOLIO) intlId = "global.add_new_project";
    if (toolId === SKILLS_ASSESSMENT) intlId = "skas_add_new_insight";
  }

  return intl ? intl.formatMessage({ id: intlId }) : intlId;
};

export const getEntityLabel = (
  type = "question",
  toolId = null,
  intl = null,
) => {
  let intlId = "entity.translation";

  if (type === "question") {
    intlId = "question_main_title";

    if (toolId === PROJECT_PORTFOLIO) intlId = "global.subTheme";
  }

  if (type === "insight") {
    intlId = "insight_main_title";

    if (toolId === PROJECT_PORTFOLIO) intlId = "global.project";
  }

  return intl ? intl.formatMessage({ id: intlId }) : intlId;
};

export const getOnlyIdsScenaryToInsights = (
  allSceneries,
  allThemes,
  allQuestions,
  allAnswers,
) => {
  let scenaries = [];

  function vObj(obj) {
    return obj && Object.keys(obj).length > 0;
  }

  function vArray(a) {
    return Array.isArray(a) && a.length > 0;
  }

  function getIds(obj) {
    let final = [];

    if (vObj(obj)) {
      Object.keys(obj).forEach((parentId) => {
        const items = obj[parentId];
        const itemIds = getOnlySlugValues(items, "id");

        final = [...final, ...itemIds];
      });
    }

    return final;
  }

  if (vArray(allSceneries)) {
    scenaries = getOnlySlugValues(allSceneries, "id");
  }

  return {
    scenaries,
    themes: getIds(allThemes),
    questions: getIds(allQuestions),
    answers: getIds(allAnswers),
  };
};

export const getCustomInsightsOrder = (insights, toolId) => {
  if (toolInsightsOrder.title.indexOf(toolId) > -1) {
    return [...insights].sort((a, b) => (a.text > b.text ? 1 : -1));
  }
  return [...insights].sort((a, b) => (a.order > b.order ? 1 : -1));
};

export const getCustomQuestionsOrder = (questions, toolId) => {
  return [...questions].sort((a, b) => (a.order > b.order ? 1 : -1));
};

export const getCustomThemesOrder = (themes, toolId) => {
  return [...themes].sort((a, b) => (a.order > b.order ? 1 : -1));
};

export const filterSelectedToolEntities = (
  selectedToolId,
  { sceneries, themes, questions, answers },
) => {
  const filtered = { sceneries: [], themes: {}, questions: {}, answers: {} };
  const onlyIds = { sceneries: [], themes: [], questions: [], answers: [] };

  if (Array.isArray(sceneries)) {
    sceneries.forEach((sceneryInfo) => {
      const { selectedTool } = sceneryInfo;

      if (selectedTool?.id === selectedToolId) {
        filtered.sceneries.push(sceneryInfo);
        onlyIds.sceneries.push(sceneryInfo.id);
      }
    });
  }

  if (themes && typeof themes === "object") {
    Object.keys(themes).forEach((cenaryId) => {
      if (
        onlyIds.sceneries.indexOf(Number(cenaryId)) > -1 &&
        Array.isArray(themes[cenaryId])
      ) {
        filtered.themes[cenaryId] = themes[cenaryId];

        themes[cenaryId].forEach((themeInfo) => {
          onlyIds.themes.push(themeInfo.id);
        });
      }
    });
  }

  if (questions && typeof questions === "object") {
    Object.keys(questions).forEach((themeId) => {
      if (
        onlyIds.themes.indexOf(Number(themeId)) > -1 &&
        Array.isArray(questions[themeId])
      ) {
        filtered.questions[themeId] = questions[themeId];

        questions[themeId].forEach((themeInfo) => {
          onlyIds.questions.push(themeInfo.id);
        });
      }
    });
  }

  if (answers && typeof answers === "object") {
    Object.keys(answers).forEach((questionId) => {
      if (
        onlyIds.questions.indexOf(Number(questionId)) > -1 &&
        Array.isArray(answers[questionId])
      ) {
        filtered.answers[questionId] = answers[questionId];

        answers[questionId].forEach((themeInfo) => {
          onlyIds.answers.push(themeInfo.id);
        });
      }
    });
  }

  return filtered;
};
