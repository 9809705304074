import { GET_USER_AVATAR } from "../actions/types";

const storageAvatars = JSON.parse(localStorage.getItem("avatars"))
const INITIAL_STATE = storageAvatars ? storageAvatars : [];

const avatarsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_AVATAR:
      const { payload } = action;
      const stateAvatars = state;
      let alreadyInState = false;
      if (stateAvatars && stateAvatars.length > 0) {
        for (const index in stateAvatars) {
          if (stateAvatars[index].blobID === payload.blobID) {
            alreadyInState = true;
            break;
          }
        }
      }
      let finalAvatars = stateAvatars;
      if (!alreadyInState) {
        finalAvatars = [...finalAvatars, payload];
      }
      return finalAvatars;
    default:
      return state;
  }
};

export default avatarsReducer;
