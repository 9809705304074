import { FETCH_TOOLS } from "../actions/types";

const INITIAL_STATE = [];

const toolsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TOOLS:
      return action?.payload || [];
    default:
      return state;
  }
};

export default toolsReducer;
