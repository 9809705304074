const en = {
  fc_main_title: "Strategic Fundamentals",
};

const es = {
  fc_main_title: "Fundamentos Estratégicos",
};

const pt = {
  fc_main_title: "Fundamentos Estratégicos",
};

const translations = { en, es, pt };

export default translations;
