/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { addNewPeopleInsights } from "../../../../actions/peopleEvaluationActions";
import InsightSelectPeople from "../../../Common/OnePage/forms/InsightSelectPeople";
import {
  getWorkspacesStates,
  getBigPeopleStates,
  getSelectedToolStates,
} from "../../../customMapStates";
import {
  getFilteredObjectsList,
  getOnlySlugValues,
  getOnlySublevelArrays,
} from "../../../../utils/ArrayUtils";

const OnePage = (props) => {
  const [addedUsers, setAddedUsers] = useState([]);

  const {
    type,
    clearViewCallback,
    allPeopleEvaluation = [],
    allAnswers = {},
    companyMembersAndParticipants,
    params = {},
    selectedWorkspace,
  } = props;

  const { insightQuestion = {} } = params;

  const workspaceEvaluations = getFilteredObjectsList(
    [selectedWorkspace.id],
    allPeopleEvaluation,
    "workspaceId",
  );

  useEffect(() => {
    const alreadyAddedUsers = [];
    const allInsights = getOnlySublevelArrays(allAnswers);
    const onlyIds = getOnlySlugValues(allInsights, "id");

    workspaceEvaluations.forEach((peopleEvaluationInfo) => {
      const { peopleEvaluation = {}, insightId } = peopleEvaluationInfo;
      const { professionalProfile = {} } = peopleEvaluation;
      const { memberId } = professionalProfile;

      if (onlyIds.indexOf(insightId) > -1) alreadyAddedUsers.push(memberId);
    });

    if (!_.isEqual(alreadyAddedUsers, addedUsers))
      setAddedUsers(alreadyAddedUsers);
  }, [allAnswers, workspaceEvaluations]);

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
  };

  const handleSaveNewEvaluation = ({ members }) => {
    let nextOrder = params.order || 0;

    if (insightQuestion && Array.isArray(members)) {
      members.forEach((memberInfo) => {
        props.addNewPeopleInsights(memberInfo, insightQuestion.id, nextOrder);
        nextOrder++;
      });
    }

    clearDialogControll();
  };

  return (
    <InsightSelectPeople
      open
      afterConfirm={handleSaveNewEvaluation}
      afterCancel={clearDialogControll}
      companyMembersAndParticipants={companyMembersAndParticipants}
      addedUsers={addedUsers}
    />
  );
};

const mapStateToProps = (state) => {
  const {
    companyMembersAndParticipants,
    allPeopleEvaluation,
  } = getBigPeopleStates(state);
  const { allAnswers } = getSelectedToolStates(state);

  const { selectedWorkspace = {} } = getWorkspacesStates(state);

  return {
    allPeopleEvaluation,
    allAnswers,
    companyMembersAndParticipants,
    selectedWorkspace,
  };
};

export default connect(mapStateToProps, {
  addNewPeopleInsights,
})(OnePage);
