const en = {
  pi_main_title: "Projects and Initiatives",
};

const es = {
  pi_main_title: "proyectos e iniciativas",
};

const pt = {
  pi_main_title: "Projetos e Iniciativas",
};

const translations = { en, es, pt };

export default translations;
