import React from "react";
import { getAscentionValues } from "../../../../../../utils/peopleEvaluationUtils";
import SimplePieChart from "../../../../../Common/SimplePieChart";

const AscentionField = (props) => {
  const { filteredEvaluations, messages, intl } = props;

  const displaySalaryAscetion = () => {
    let evaluations = [];

    filteredEvaluations.forEach(({ peopleEvaluation }) => {
      const { evaluationTarget } = peopleEvaluation;
      const { salaryEvaluation } = evaluationTarget;

      evaluations = [...evaluations, salaryEvaluation.value];
    });

    const { READY, PARCIAL, ABOVE } = getAscentionValues(evaluations);

    return (
      <SimplePieChart
        data={[
          {
            name: intl.formatMessage(messages.labels_ready),
            value: READY,
            color: "#24da7b",
          },
          {
            name: intl.formatMessage(messages.labels_partial),
            value: PARCIAL,
            color: "#f0f579",
          },
          {
            name: intl.formatMessage(messages.labels_not),
            value: ABOVE,
            color: "#8f7070",
          },
        ]}
        containerHeight={220}
      />
    );
  };

  const displayRoleAscetion = () => {
    let evaluations = [];

    filteredEvaluations.forEach(({ peopleEvaluation }) => {
      const { evaluationTarget } = peopleEvaluation;
      const { roleEvaluation } = evaluationTarget;

      evaluations = [...evaluations, roleEvaluation.value];
    });

    const { READY, PARCIAL, ABOVE } = getAscentionValues(evaluations);

    return (
      <SimplePieChart
        data={[
          {
            name: intl.formatMessage(messages.labels_ready),
            value: READY,
            color: "#24da7b",
          },
          {
            name: intl.formatMessage(messages.labels_partial),
            value: PARCIAL,
            color: "#f0f579",
          },
          {
            name: intl.formatMessage(messages.labels_not),
            value: ABOVE,
            color: "#8f7070",
          },
        ]}
        containerHeight={220}
      />
    );
  };

  return (
    <div className="evaluation-card">
      <div className="card-header">
        <div className="card-title">
          <h4>{intl.formatMessage(messages.titles_cards_ascension)}</h4>
        </div>
      </div>
      <div className="card-body" style={{ height: "315px" }}>
        <div className="row">
          <div className="col-xs-6">
            <h5 align="center">{intl.formatMessage(messages.labels_salary)}</h5>
            {displaySalaryAscetion()}
          </div>
          <div className="col-xs-6">
            <h5 align="center">{intl.formatMessage(messages.labels_role)}</h5>
            {displayRoleAscetion()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AscentionField;
