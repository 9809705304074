import { useRef, useEffect, useLayoutEffect, useState } from "react";
import { useCompanyAreasTree } from "./companyStructure/hooks";
import { useCompanyPositionsTree } from "./companyPositions/hooks";

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
}

export { useCompanyAreasTree, useCompanyPositionsTree };
