import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";

import {
  BoxTemplate,
  TitleTemplate,
  ContentTemplate,
  DescriptionTemplate,
  ResponsibleTemplate,
  NumberOfActivities,
} from "./styles";
import { getMaxStringLength } from "../../../../../../../../utils/StringUtils";

function CardTemplate({
  title,
  description,
  createdBy,
  onEdit,
  numberActivities,
  isPublic,
}) {
  return (
    <BoxTemplate>
      <div className="header-template">
        <Tooltip title={title}>
          <TitleTemplate>
            {getMaxStringLength({
              string: title,
              beginIndex: 0,
              endIndex: 20,
              additional: "...",
            })}
          </TitleTemplate>
        </Tooltip>
        <i className="fas fa-pen" onClick={onEdit} />
      </div>
      <ContentTemplate>
        <Tooltip title={description}>
          <DescriptionTemplate>
            {getMaxStringLength({
              string: description,
              beginIndex: 0,
              endIndex: 62,
              additional: "...",
            })}
          </DescriptionTemplate>
        </Tooltip>
        <div>
          <NumberOfActivities>
            Atividades: <span>{numberActivities}</span>
          </NumberOfActivities>
          <ResponsibleTemplate>
            Criado por: <span>{createdBy}</span>
          </ResponsibleTemplate>
          <ResponsibleTemplate>
            <span>{isPublic ? "Público" : "Privado"}</span>
          </ResponsibleTemplate>
        </div>
      </ContentTemplate>
    </BoxTemplate>
  );
}

export { CardTemplate };

CardTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  createdBy: PropTypes.string,
  onEdit: PropTypes.func,
  numberActivities: PropTypes.number,
  isPublic: PropTypes.bool,
};

CardTemplate.defaultProps = {
  title: "Sem título",
  description: "Sem descrição",
  createdBy: "Sem responsável",
  onEdit: () => {},
  numberActivities: 0,
  isPublic: false,
};
