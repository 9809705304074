import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { getStyledErrorMessage } from "../../../../../../../utils/formValidation";
import { getAccessRelation } from "../../../../../../../utils/skillsAssessment/constants";
import { workflowErrorSlugs } from "../../../../../../../utils/skillsAssessment/errorConstants";
import { checkIsAdmin } from "../../../../../../../utils/skillsAssessment/filter";
import Avatar from "../../../../../../Common/Avatar";
import MaterialTextField from "../../../../../../Common/MaterialTextField";
import SelectMemberModal from "../../../../../../Common/SelectMemberModal";

const WorkflowHeader = ({
  participant,
  workflowErrors,
  showManager,
  handleEvaluatorsUpdate,
  enableSecondEvaluator,
  enableThirdEvaluator,
  enableSecEvaluatorUpdate,
  enableTrdEvaluatorUpdate,
  enableManagerUpdate,
  blockUpdate,
  managerConfig,
  noAdminPrivileges,
  loggedUser,
  loggedUserRole,
  companyMembersAndParticipants,
  hideAreaAndPosition,
  noShow,
}) => {
  const [selectModal, setSelectModal] = useState({
    type: null,
    onConfirm: () => {},
    onCancel: () => {},
  });

  const { selfEvaluationAccess, secondEvaluatorAccess, thirdEvaluatorAccess } =
    managerConfig;

  const {
    memberParticipant = {},
    companyArea = {},
    companyPosition = {},
    profile = {},
    manager = {},
    secondEvaluator = {},
    thirdEvaluator = {},
  } = participant;

  const noErrors = workflowErrors.length === 0;

  const closeSelectModal = () => {
    setSelectModal({ type: null, onConfirm: () => {}, onCancel: () => {} });
  };

  const saveUpdateParticipantEvaluator = (editingField, selectedMemberId) => {
    if (!blockUpdate) {
      const selectedMember = companyMembersAndParticipants.find(
        ({ id }) => id === selectedMemberId
      );
      handleEvaluatorsUpdate(editingField, selectedMember);
      closeSelectModal();
    }
  };

  const openSelectMember = (editingField) => {
    if (editingField && !blockUpdate) {
      setSelectModal({
        type: "select_member",
        onConfirm: (selectedMember) =>
          saveUpdateParticipantEvaluator(editingField, selectedMember[0]),
        onCancel: closeSelectModal,
      });
    }
  };

  const isAdmin = !noAdminPrivileges && checkIsAdmin();
  const isAdminOrShowManager = isAdmin || selfEvaluationAccess;

  const { isSecEval, isThrEval } = getAccessRelation(
    loggedUserRole,
    loggedUser,
    participant
  );

  const isEvaluator = isSecEval || isThrEval;
  const filterFor = isSecEval
    ? "invalid_workflow_values_thirdEvaluator"
    : "invalid_workflow_values_secondEvaluator";

  const allErrorSlugs = workflowErrorSlugs();
  const filteredErrorSlugs = isEvaluator
    ? allErrorSlugs.filter((slug) => slug !== filterFor)
    : allErrorSlugs;

  const participantName = memberParticipant?.first_name
    ? `${memberParticipant.first_name} ${memberParticipant.last_name}`
    : memberParticipant?.name;

  const managerName = manager?.first_name
    ? `${manager.first_name} ${manager.last_name}`
    : manager?.name;

  return (
    <>
      {selectModal.type === "select_member" && (
        <SelectMemberModal
          open
          title="Selecione um membro"
          onConfirm={selectModal.onConfirm}
          onCancel={selectModal.onCancel}
          initialSelected={selectModal.selected}
          singleSelect
          membersList={companyMembersAndParticipants}
        />
      )}
      <div className="row workflow-header">
        <Avatar
          className="member-avatar"
          name={memberParticipant.name}
          src={memberParticipant.userData?.avatarBlobId}
        />
        <div className="col">
          <h4 className="member-name" title={memberParticipant.email}>
            {participantName || "Participant Name"}
          </h4>
          {!hideAreaAndPosition && (
            <h5 className="member-subtitles">
              <font className="title">Área e Cargo:</font>
              {` ${companyArea?.title} - ${companyPosition?.title}`}
            </h5>
          )}
          <h5 className="member-subtitles">
            <font className="title">Perfil:</font>
            {` ${profile?.title}`}
          </h5>
          {showManager && (
            <h5 className="member-subtitles">
              <font className="title">Gestor Imediato:</font>
              {` ${managerName}`}
            </h5>
          )}
        </div>
      </div>
      {noShow && (
        <div className="row">
          {isAdminOrShowManager && (
            <div className="col-xs-4 col-md-3">
              <MaterialTextField
                id="participantManager"
                label="Gestor Imediato"
                value={managerName || ""}
                onClick={
                  enableManagerUpdate
                    ? () => openSelectMember("manager")
                    : () => {}
                }
                disabled={!enableManagerUpdate}
                inputStyle={{
                  cursor: enableManagerUpdate ? "pointer" : "not-allowed",
                }}
              />
            </div>
          )}
          {enableSecondEvaluator && (secondEvaluatorAccess || isAdmin) && (
            <div className="col-xs-4 col-md-3">
              <MaterialTextField
                id="participantSecondEvaluator"
                label="Cliente"
                value={secondEvaluator?.name}
                onClick={
                  enableSecEvaluatorUpdate
                    ? () => openSelectMember("secondEvaluator")
                    : () => {}
                }
                disabled={!enableSecEvaluatorUpdate}
                inputStyle={
                  !enableSecEvaluatorUpdate ? { cursor: "not-allowed" } : {}
                }
              />
            </div>
          )}
          {enableThirdEvaluator && (thirdEvaluatorAccess || isAdmin) && (
            <div className="col-xs-4 col-md-3">
              <MaterialTextField
                id="participantThirdEvaluator"
                label="Fornecedor"
                value={thirdEvaluator?.name}
                onClick={
                  enableTrdEvaluatorUpdate
                    ? () => openSelectMember("thirdEvaluator")
                    : () => {}
                }
                disabled={!enableTrdEvaluatorUpdate}
                inputStyle={
                  !enableTrdEvaluatorUpdate ? { cursor: "not-allowed" } : {}
                }
              />
            </div>
          )}
        </div>
      )}
      {!noErrors && (
        <div className="col-xs-12">
          {getStyledErrorMessage(filteredErrorSlugs, workflowErrors)}
        </div>
      )}
    </>
  );
};

WorkflowHeader.propTypes = {
  participant: PropTypes.object,
  managerConfig: PropTypes.object,
  workflowErrors: PropTypes.array,
  handleEvaluatorsUpdate: PropTypes.func,
  blockUpdate: PropTypes.bool,
  enableSecondEvaluator: PropTypes.bool,
  enableThirdEvaluator: PropTypes.bool,
  enableSecEvaluatorUpdate: PropTypes.bool,
  enableTrdEvaluatorUpdate: PropTypes.bool,
  enableManagerUpdate: PropTypes.bool,
  noAdminPrivileges: PropTypes.bool,
  noShow: PropTypes.bool,
  showManager: PropTypes.bool,
};

WorkflowHeader.defaultProps = {
  participant: {},
  managerConfig: {},
  workflowErrors: [],
  handleEvaluatorsUpdate: () => {},
  blockUpdate: false,
  enableSecondEvaluator: false,
  enableThirdEvaluator: false,
  enableSecEvaluatorUpdate: false,
  enableTrdEvaluatorUpdate: false,
  enableManagerUpdate: false,
  noAdminPrivileges: false,
  noShow: true,
  showManager: false,
};

function areEqual(prevProps, nextProps) {
  if (!_.isEqual(prevProps.participant, nextProps.participant)) return false;
  if (!_.isEqual(prevProps.workflowErrors, nextProps.workflowErrors))
    return false;
  return true;
}

export default React.memo(WorkflowHeader, areEqual);
