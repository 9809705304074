import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { v4 as uuidv4 } from "uuid";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  XAxis,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import CustomGraphTooltip from "./CustomGraphTooltip";

const SimpleBarChart = ({
  data,
  containerWidth,
  containerHeight,
  showLegend,
  fillColor,
  secondaryFillColor,
  labelConstrast,
  displaySecondaryValues,
  legendLabel,
  secondaryLegendLabel,
  hideTooltipLabelName,
}) => {
  return (
    <div
      style={{
        width: containerWidth,
        height: containerHeight,
      }}
    >
      <ResponsiveContainer width={containerWidth} height={containerHeight}>
        <BarChart data={data} barCategoryGap="20%" height={60}>
          <XAxis dataKey="name" />
          <Tooltip
            content={
              <CustomGraphTooltip hideTooltipLabelName={hideTooltipLabelName} />
            }
          />
          {showLegend && <Legend />}
          <Bar
            dataKey="value"
            name={legendLabel || null}
            fill={fillColor}
            legendType="circle"
          >
            <LabelList
              dataKey="value"
              position="insideTop"
              fill={labelConstrast}
              formatter={(val) => (val === 0 ? "" : val)}
              fontSize="12px"
            />
            {data.map((entry) => (
              <Cell
                fill={entry.color ? entry.color : fillColor}
                key={uuidv4()}
              />
            ))}
          </Bar>
          {displaySecondaryValues && (
            <Bar
              dataKey="secondaryValue"
              name={secondaryLegendLabel || null}
              fill={secondaryFillColor}
              legendType="circle"
            >
              <LabelList
                dataKey="secondaryValue"
                position="insideTop"
                fill={labelConstrast}
                formatter={(val) => (val === 0 ? "" : val)}
                fontSize="12px"
              />
              {data.map((entry) => (
                <Cell
                  fill={
                    entry.secondaryColor
                      ? entry.secondaryColor
                      : secondaryFillColor
                  }
                  key={uuidv4()}
                />
              ))}
            </Bar>
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

SimpleBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.any.isRequired,
      value: PropTypes.number.isRequired,
      color: PropTypes.string,
      secondaryValue: PropTypes.number,
      secondaryColor: PropTypes.string,
    }),
  ).isRequired,
  containerWidth: PropTypes.any,
  containerHeight: PropTypes.any,
  showLegend: PropTypes.bool,
  fillColor: PropTypes.string,
  labelConstrast: PropTypes.string,
  displaySecondaryValues: PropTypes.bool,
  legendLabel: PropTypes.string,
  secondaryLegendLabel: PropTypes.string,
  hideTooltipLabelName: PropTypes.bool,
};

SimpleBarChart.defaultProps = {
  containerWidth: "100%",
  containerHeight: 300,
  showLegend: false,
  fillColor: "#8884d8",
  labelConstrast: "#fff",
  displaySecondaryValues: false,
  legendLabel: null,
  secondaryLegendLabel: null,
  hideTooltipLabelName: false,
};

export default injectIntl(SimpleBarChart);
