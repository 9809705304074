import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TableEvaluationComposition from "../tables/TableEvaluationComposition";
import TableEvaluationType from "../tables/TableEvaluationType";
import TableEvaluationDates from "../tables/TableEvaluationDates";
import TableEvaluationConfig from "../tables/TableEvaluationConfig";
import TableGeneralOptionsConfig from "../tables/TableGeneralOptionsConfig";
import { getStyledErrorMessage } from "../../../../../../../utils/formValidation";
import {
  dateErrorSlugs,
  evaluationTypeErrorSlugs,
} from "../../../../../../../utils/skillsAssessment/errorConstants";
import { updateParticipantsEvaluationStage } from "../../../../../../../utils/skillsAssessment/manipulation";
import {
  getComplementaryEvaluationTypeByConfigSlug,
  getRelativeEvaluationStage,
} from "../../../../../../../utils/skillsAssessment/constants";
import { filterParticipantsByStages } from "../../../../../../../utils/skillsAssessment/filter";
import { displayConflictedParticipants } from "../../../../../../../utils/skillsAssessment/display";

const TabGeneralConfig = (props) => {
  const {
    toolAdministration = {},
    updateAdministrationSingleField,
    handleAutoSave,
    setConfirmationDialog,
    resetConfirmDialog,
    errors,
  } = props;

  const {
    evaluationType,
    evaluationConfig,
    participants,
    complementaryWorkflow,
    generalOptions,
    enableEvaluatorsSelectionWorkflow,
  } = toolAdministration;

  const updateComposition = (newComposition) => {
    updateAdministrationSingleField("composition", newComposition);
  };

  const updateEvaluationType = (newEvaluationType) => {
    updateAdministrationSingleField("evaluationType", newEvaluationType);
  };

  const updateTransparencyConfig = (newValue) => {
    updateAdministrationSingleField("transparency", newValue);
  };

  const updatedEvaluatorsSelectionWorkflow = (newValue) => {
    updateAdministrationSingleField(
      "enableEvaluatorsSelectionWorkflow",
      newValue,
    );
  };

  const toggleGeneralOptions = (slug) => {
    if (slug)
      updateAdministrationSingleField("generalOptions", {
        ...generalOptions,
        [slug]: !generalOptions[slug],
      });
  };

  const updateEvaluationTypeAndWorkflowStage = (
    newEvaluationType,
    newStage,
    filteredParticipants,
  ) => {
    if (filteredParticipants?.length === 0) {
      updateEvaluationType(newEvaluationType);
      return;
    }

    const updatedParticipants = updateParticipantsEvaluationStage(
      participants,
      filteredParticipants,
      newStage,
    );

    const updatedAdministration = {
      ...toolAdministration,
      evaluationType: newEvaluationType,
      participants: updatedParticipants,
    };

    handleAutoSave(updatedAdministration);
  };

  const updateEvaluationWeights = (field, newWeight) => {
    const newWeights = {
      ...toolAdministration.evaluationWeights,
      [field]: parseFloat(newWeight),
    };

    updateAdministrationSingleField("evaluationWeights", newWeights);
  };

  const updateDateField = (dateSlug, newDate) => {
    const updatedDates = {
      ...toolAdministration.dates,
      [dateSlug]: newDate,
    };

    updateAdministrationSingleField("dates", updatedDates);
  };

  const updateEvaluationConfig = (configSlug, newConfig) => {
    const filterStage = getComplementaryEvaluationTypeByConfigSlug(configSlug);

    const updatedConfig = {
      ...evaluationConfig,
      [configSlug]: newConfig,
    };

    if (filterStage) {
      if (newConfig === false && complementaryWorkflow.length > 0) {
        const { previous } = getRelativeEvaluationStage(
          filterStage,
          evaluationType,
          evaluationConfig,
          enableEvaluatorsSelectionWorkflow,
        );

        const filteredParticipants = filterParticipantsByStages(participants);

        if (filteredParticipants.length > 0 && previous.value) {
          setConfirmationDialog({
            open: true,
            title:
              "Tem certeza que deseja atualizar as configurações de Avaliação Complementar?",
            description: displayConflictedParticipants(
              filteredParticipants,
              previous.label,
            ),
            onConfirm: () => {
              const updatedParticipants = updateParticipantsEvaluationStage(
                participants,
                filteredParticipants,
                previous.value,
              );

              handleAutoSave({
                ...toolAdministration,
                evaluationConfig: updatedConfig,
                participants: updatedParticipants,
              });
              resetConfirmDialog();
            },
            oncancel: resetConfirmDialog,
          });
          return;
        }
      }

      updateAdministrationSingleField("evaluationConfig", updatedConfig);
    }
  };

  return (
    <div className="tab-content" style={{ margin: "0px" }}>
      <div className="row">
        <div className="col-xs-12">
          <h4>Configuração Geral da Avaliação</h4>
        </div>
        <div className="col-xs-12 col-lg-6">
          <div className="row">
            <TableEvaluationComposition
              composition={toolAdministration.composition}
              updateComposition={updateComposition}
            />
          </div>
        </div>
        <div className="col-xs-12 col-lg-6">
          <div className="row">
            <TableEvaluationType
              toolAdministration={toolAdministration}
              updateEvaluationType={updateEvaluationType}
              updateEvaluationTypeAndWorkflowStage={
                updateEvaluationTypeAndWorkflowStage
              }
              updateEvaluationWeights={updateEvaluationWeights}
              setConfirmationDialog={setConfirmationDialog}
              resetConfirmDialog={resetConfirmDialog}
            />
            {getStyledErrorMessage(evaluationTypeErrorSlugs(), errors)}
          </div>
        </div>
        <div className="row" style={{ clear: "both" }}>
          <div className="col-xs-12 col-lg-6">
            <div className="row">
              <TableEvaluationDates
                dates={toolAdministration.dates}
                updateDateField={updateDateField}
              />
              {getStyledErrorMessage(dateErrorSlugs(), errors)}
            </div>
          </div>
          <div className="col-xs-12 col-lg-6">
            <div className="row">
              <TableEvaluationConfig
                evaluationConfig={toolAdministration.evaluationConfig}
                updateEvaluationConfig={updateEvaluationConfig}
              />
              {getStyledErrorMessage(dateErrorSlugs(), errors)}
            </div>
          </div>
          <div className="col-xs-12 col-lg-6">
            <div className="row">
              <TableGeneralOptionsConfig
                evaluationType={evaluationType}
                enableEvaluatorsSelectionWorkflow={
                  enableEvaluatorsSelectionWorkflow
                }
                transparency={toolAdministration.transparency}
                generalOptions={generalOptions}
                updateTransparencyConfig={updateTransparencyConfig}
                updatedEvaluatorsSelectionWorkflow={
                  updatedEvaluatorsSelectionWorkflow
                }
                toggleGeneralOptions={toggleGeneralOptions}
              />
              {getStyledErrorMessage(dateErrorSlugs(), errors)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TabGeneralConfig.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
  updateAdministrationSingleField: PropTypes.func.isRequired,
  handleAutoSave: PropTypes.func.isRequired,
  setConfirmationDialog: PropTypes.func.isRequired,
  resetConfirmDialog: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => {
  const { enableEvaluatorsSelectionWorkflow } = state.skillAssessment.all[0];

  return {
    enableEvaluatorsSelectionWorkflow,
  };
};

export default connect(mapStateToProps)(TabGeneralConfig);
