/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger } from "react-bootstrap";
import { getEmptyTableRow } from "../../../../../../../utils/tableUtils";
import { simpleEditArrayOnAdministration } from "../../../../../../../utils/skillsAssessment/manipulation";
import {
  getFilterLinkedRoleSkillRelation,
  buildSkillsOptions,
  buildWorkflowScaleOptions,
  getItemsByPage,
} from "../../../../../../../utils/skillsAssessment/filter";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import MaterialSingleSelect from "../../../../../../Common/MaterialSingleSelect";
import SimplePagination from "../../../../../../SimplePagination";
import { getCompetenceConfigByComposition } from "../../../../../../../utils/skillsAssessment/constants";
import { getMaximumScale } from "../../../../../../../utils/skillsAssessment/calc";
import { getScaleToolTip } from "../../../../../../../utils/skillsAssessment/display";

const RELATIONS_PER_PAGE = 20;

const TableRoleSkillRelation = ({
  toolAdministration = {},
  updateAdministration,
}) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filteredRelation, setFilteredRelation] = useState([]);
  const [filterBySkill, setFilterBySkill] = useState("");

  const { evaluationScale } = toolAdministration;

  useEffect(() => {
    if (toolAdministration.roleSkillRelation) {
      const newFilteredRelation = getFilterLinkedRoleSkillRelation(
        toolAdministration,
        filterBySkill,
      );

      const filteredByPage = getItemsByPage(
        newFilteredRelation,
        page,
        RELATIONS_PER_PAGE,
      );
      const newTotalPages =
        newFilteredRelation.length > 0
          ? Math.ceil(newFilteredRelation.length / RELATIONS_PER_PAGE)
          : 0;

      setFilteredRelation(filteredByPage);
      setTotalPages(newTotalPages);
    }
  }, [toolAdministration, filterBySkill, page]);

  useEffect(() => {
    if (page !== 1) setPage(1);
  }, [filterBySkill]);

  const handleEditRelation = (relationInfo, newValue) => {
    if (relationInfo.id) {
      const maxScale = getMaximumScale(evaluationScale);
      const validNewValue = newValue <= maxScale ? newValue : maxScale;

      const updatedRelation = {
        ...relationInfo,
        desiredEvaluation: validNewValue > -1 ? parseFloat(validNewValue) : 0,
      };

      const newAdministration = simpleEditArrayOnAdministration(
        toolAdministration,
        "roleSkillRelation",
        relationInfo.id,
        updatedRelation,
        "id",
      );

      updateAdministration(newAdministration);
    }
  };

  const getFilterCleaner = () => {
    return (
      filterBySkill && (
        <div className="col-xs-6">
          <a onClick={() => setFilterBySkill("")} style={{ cursor: "pointer" }}>
            Limpar filtro
          </a>
        </div>
      )
    );
  };

  const skillsOptions = buildSkillsOptions(toolAdministration.skills);
  const { enableDesiredEvaluation } = getCompetenceConfigByComposition(
    toolAdministration.composition,
  );

  const qualificationScaleOptions = buildWorkflowScaleOptions(
    toolAdministration,
    "qualificationScale",
  );

  return (
    <div className="row">
      {enableDesiredEvaluation && (
        <div className="row">
          <div
            className="row"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              className="col-xs-4"
              style={{ padding: "0px", backgroundColor: "#fff" }}
            >
              <MaterialSingleSelect
                id="filterSkills"
                label="Filtro de Competência"
                options={skillsOptions}
                value={filterBySkill}
                onChange={(e) => setFilterBySkill(e.target.value)}
              />
            </div>
            {getFilterCleaner()}
          </div>
          <SimplePagination
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
          <table className="simple-table">
            <thead>
              <tr>
                <td width="300">CARGO</td>
                <td width="300">COMPETÊNCIA</td>
                <td width="50">AVALIAÇÃO DESEJADA</td>
              </tr>
            </thead>
            <tbody>
              {filteredRelation?.map((relationInfo, index) => {
                return (
                  <tr key={relationInfo.id}>
                    <td>{relationInfo?.companyRole?.title}</td>
                    <td>{relationInfo?.skill?.title}</td>
                    <td style={{ padding: "0px", width: "100px" }}>
                      <OverlayTrigger
                        placement="top"
                        overlay={getScaleToolTip(qualificationScaleOptions)}
                      >
                        <table style={{ margin: "0px", width: "100%" }}>
                          <tr>
                            <SimpleExcelCollumn
                              id={`desiredEvaluation${index}`}
                              label={`desiredEvaluation${index}`}
                              type="number"
                              value={relationInfo.desiredEvaluation}
                              tdStyle={{ width: "100px" }}
                              align="center"
                              onChange={(e) =>
                                handleEditRelation(relationInfo, e.target.value)
                              }
                              inputStyle={{ backgroundColor: "#efefef" }}
                            />
                          </tr>
                        </table>
                      </OverlayTrigger>
                    </td>
                  </tr>
                );
              })}
              {getEmptyTableRow(filteredRelation, "Nenhum encontrado")}
            </tbody>
          </table>
          <SimplePagination
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        </div>
      )}
      {!enableDesiredEvaluation && (
        <div className="row" style={{ padding: "20px 0px" }}>
          <b>
            Este campo é habilitado somente quando a Composição da Avaliação
            selecionada é &quot;Competência x Critério da Qualificação&quot;.
          </b>
        </div>
      )}
    </div>
  );
};

TableRoleSkillRelation.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
  updateAdministration: PropTypes.func.isRequired,
};

export default TableRoleSkillRelation;
