import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import utils from "../../../utils/toolUtils";

import Avatar from "../../Common/Avatar";
import { Bar, Progress } from "../../Common/TemplateKanban/kanbanStyle";

const { getOkrTotalEvaluation } = utils;

const CardContainer = styled.div`
  width: 290px;
  border-radius: 4px;
  margin: 0 10px;
  background: #f4f5f7;

  @media print {
    background: #f4f5f7 !important;
    -webkit-print-color-adjust: exact;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-left: 3px solid ${(props) => (props.color ? props.color : "")};
  border-bottom: 3px solid #fff;
  border-radius: 4px;

  div {
    font-weight: 500;
    color: #172b4d;
  }
`;

const CardList = styled.div`
  height: 550px;
  overflow: auto;
  padding: 5px 5px 0 5px;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 0.5em !important;
    scroll-behavior: smooth !important;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dfe1e6;
    border-radius: 10px !important;
  }
`;

const List = styled.div`
  border-radius: 4px;
  background-color: #dfe1e6;
  margin-bottom: 5px;

  @media print {
    background: #dfe1e6 !important;
    -webkit-print-color-adjust: exact;
  }
`;

const ListTextStatic = styled.span`
  color: #626f87;
  margin-right: 5px;
`;

const ListObjectiveTitle = styled.span`
  color: #172b4d;
  font-weight: 400;
`;

const AnswerText = styled.span`
  font-size: 14px;
  color: #172b4d;
  font-weight: 500;
`;

const ListHeader = styled.div`
  display: flex;
  margin: 5px 5px 0 5px;
  font-size: 13px;
`;

// const ProgressBar = styled.div`
//   background-color: ${(props) => (props.background ? props.background : "")};

//   @media print {
//     background-color: ${(props) =>
//       props.background ? props.background : ""} !important;
//     -webkit-print-color-adjust: exact;
//   }
// `;

const ListContainer = styled.div`
  margin: 5px;
  padding: 10px 15px;
  border-radius: 4px;
  background: #fff;
  position: relative;

  .status-bar {
    position: absolute;
    width: 3px;
    height: auto;
    left: 5px;
    top: 5px;
    bottom: 5px;
    background: ${(props) => (props.color ? props.color : "")};
  }

  @media print {
    background: #fff !important;
    -webkit-print-color-adjust: exact;

    .status-bar {
      background-color: ${(props) =>
        props.color ? props.color : ""} !important;
      -webkit-print-color-adjust: exact;
    }
  }
`;

const ListContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ListFooter = styled.div`
  display: flex;
`;

const FooterBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  text-align: center;

  p {
    color: #626f87;
    font-size: 11px;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
`;

const EvaluationText = styled.div`
  font-size: 13px;
  font-weight: 500;
`;

const FooterProgress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
`;

const ToolManagementCardList = (props) => {
  const {
    title,
    evaluations,
    className,
    showHiddenExpectation,
    selectAndOpenInsight,
  } = props;

  const okrValue = !showHiddenExpectation ? "reached" : "expectationValue";

  function countInsights(evaluations) {
    const insights = [];

    if (evaluations) {
      evaluations.forEach((evaluation) => {
        evaluation.answers.forEach((answer) => {
          insights.push(answer);
        });
      });
    }

    return insights.length ? insights.length : 0;
  }

  function getCustomColor(className) {
    if (className === "nao_iniciado") {
      return "gray";
    }
    if (className === "nao_atingida") {
      return "#f54e4e";
    }
    if (className === "parcialmente-atingida") {
      return "#ffc107";
    }
    if (className === "atingida") {
      return "#0c9247";
    }
    if (className === "superada") {
      return "cornflowerblue";
    }
    if (className === "cancelada") {
      return "darkslategray";
    }

    return "";
  }

  return (
    <CardContainer>
      <CardHeader color={getCustomColor(className)}>
        <div>{title}</div>
        <div>{countInsights(evaluations)}</div>
      </CardHeader>
      <CardList>
        {evaluations &&
          evaluations.map((evaluation) => {
            const { answers = [] } = evaluation;

            return (
              <List color={getCustomColor(className)} key={uuidv4()}>
                <ListHeader>
                  <ListTextStatic>OBJETIVO</ListTextStatic>
                  <ListObjectiveTitle>
                    {evaluation.questionText}
                  </ListObjectiveTitle>
                </ListHeader>
                {answers &&
                  answers.map((answer = {}) => {
                    const responsibleMember = answer.responsibleMember || null;

                    const reachedPercentage = getOkrTotalEvaluation(
                      answer.goal.value,
                      answer.goal[okrValue],
                      answer.goal.polarity,
                    );
                    const formatReached =
                      reachedPercentage > 100 ? 100 : reachedPercentage;

                    return (
                      <ListContainer
                        color={getCustomColor(className)}
                        key={answer.id}
                      >
                        <div className="status-bar" />
                        {responsibleMember && (
                          <ListContent>
                            <Avatar
                              name={responsibleMember.name}
                              src={responsibleMember.userData.avatarBlobId}
                              style={{
                                width: "24px",
                                height: "24px",
                                marginRight: "5px",
                              }}
                            />
                            <AnswerText>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => selectAndOpenInsight(answer.id)}
                              >
                                {answer.text}
                              </div>
                            </AnswerText>
                          </ListContent>
                        )}
                        <ListFooter>
                          <FooterBox>
                            <p>Plano</p>
                            <EvaluationText>{answer.goal.value}</EvaluationText>
                          </FooterBox>
                          <FooterBox>
                            <p>
                              {!showHiddenExpectation
                                ? "Atingido"
                                : "Expectativa"}
                            </p>
                            <EvaluationText>
                              {answer.goal[okrValue]}
                            </EvaluationText>
                          </FooterBox>
                          <FooterProgress>
                            <div
                              style={{
                                margin: "0px",
                                border: "none",
                                height: "18px",
                              }}
                              className="goal-list-item"
                            >
                              <div
                                style={{ color: "#333", marginRight: "5px" }}
                              >
                                <EvaluationText>
                                  {reachedPercentage.toFixed()}%
                                </EvaluationText>
                              </div>
                              <Bar
                                className="bar"
                                style={{
                                  width: "100%",
                                  background: "#dedede",
                                  height: "10px",
                                  margin: 0,
                                }}
                              >
                                <Progress
                                  className="progress"
                                  color={getCustomColor(className)}
                                  style={{
                                    width: `${formatReached}%`,
                                    backgroundColor: getCustomColor(className),
                                    height: "10px",
                                  }}
                                />
                              </Bar>
                            </div>
                          </FooterProgress>
                        </ListFooter>
                      </ListContainer>
                    );
                  })}
              </List>
            );
          })}
      </CardList>
    </CardContainer>
  );
};

const mapStateToProps = (state) => {
  const { toolsConfigurations } = state;

  return {
    showHiddenExpectation: toolsConfigurations.showHiddenExpectation,
  };
};

export default connect(mapStateToProps)(ToolManagementCardList);
