import React, { useState } from "react";
import PropTypes from "prop-types";
import MaterialTextField from "components/Common/MaterialTextField";
import SelectCompanyMember from "components/SelectCompanyMember";
import {
  fetchSkillAssessmentConfig,
  putUpdateParticipantWithPersistence,
} from "actions/skillAssessmentActions";
import { updateClientLoading } from "actions/loadingActions";
import { connect } from "react-redux";

const Description = ({ title, description }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        padding: "10px",
        fontSize: "11px",
      }}
      title={description}
    >
      {title}
    </div>
  );
};

const WorkflowSelectionEvaluatorsTable = ({
  selectedToolId,
  participant,
  putUpdateParticipantWithPersistence,
}) => {
  const [selectModal, setSelectModal] = useState({
    type: null,
    onConfirm: () => {},
    onCancel: () => {},
  });
  const [evaluatorName, setManagerName] = useState(participant?.manager?.name);
  const [secondEvaluatorName, setSecondEvaluatorName] = useState(
    participant?.secondEvaluator?.name,
  );
  const [thirdEvaluatorName, setThirdEvaluatorName] = useState(
    participant?.thirdEvaluator?.name,
  );
  const [managerPositionTitle, setManagerPositionTitle] = useState("");
  const [secondEvaluatorPositionTitle, setSecondEvaluatorPositionTitle] =
    useState("");
  const [thirdEvaluatorPositionTitle, setThirdEvaluatorPositionTitle] =
    useState("");

  const closeSelectModal = () => {
    setSelectModal({ type: null, onConfirm: () => {}, onCancel: () => {} });
  };

  const saveUpdatedParticipant = async (
    currentParticipant,
    updatedParticipant,
  ) => {
    if (updatedParticipant?.id) {
      updateClientLoading({
        loading: true,
        message: "Aguarde enquanto as atualizações são carregadas",
      });
      putUpdateParticipantWithPersistence({
        selectedToolId: selectedToolId,
        currentParticipant,
        updatedParticipant,
      });
      fetchSkillAssessmentConfig(selectedToolId);
      updateClientLoading({ loading: false });
    }
  };

  const updateParticipantEvaluators = (
    participantInfo,
    selectedMember,
    editingField,
  ) => {
    const updatedParticipant = {
      ...participantInfo,
      [editingField]: selectedMember,
      [`${editingField}Id`]: selectedMember.id,
    };

    saveUpdatedParticipant(participantInfo, updatedParticipant);
    closeSelectModal();
  };

  const openSelectMember = (participantInfo, editingField) => {
    if (editingField) {
      const fieldToFunctionMap = {
        manager: setManagerName,
        secondEvaluator: setSecondEvaluatorName,
        thirdEvaluator: setThirdEvaluatorName,
      };

      const positionTitleFunctionMap = {
        manager: setManagerPositionTitle,
        secondEvaluator: setSecondEvaluatorPositionTitle,
        thirdEvaluator: setThirdEvaluatorPositionTitle,
      };

      setSelectModal({
        type: "select_member",
        onConfirm: (selectedMember) => {
          const selectedMemberName = selectedMember[0].name;
          const selectedCompanyPositionTitle =
            selectedMember[0].companyPosition.title;

          // Defina o estado aqui
          const setPositionTitleFunction =
            positionTitleFunctionMap[editingField];
          if (setPositionTitleFunction) {
            setPositionTitleFunction(selectedCompanyPositionTitle);
          }

          updateParticipantEvaluators(
            participantInfo,
            selectedMember[0],
            editingField,
          );

          const setFunction = fieldToFunctionMap[editingField];
          if (setFunction) {
            setFunction(selectedMemberName);
          }
        },
        onCancel: closeSelectModal,
      });
    }
  };

  return (
    <>
      <SelectCompanyMember
        open={selectModal.type === "select_member"}
        title={selectModal.title}
        onConfirm={selectModal.onConfirm}
        onCancel={selectModal.onCancel}
        selected={selectModal.selected}
        singleSelect
      />

      <table className="simple-table workflow-table">
        <thead>
          <tr className="small-font" style={{ fontWeight: "bold" }}>
            <td style={{ fontSize: "12px" }}>TIPO DO AVALIADOR</td>
            <td style={{ fontSize: "12px" }}>CARGO DO AVALIADOR</td>
            <td style={{ fontSize: "12px" }}>AVALIADOR</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="input-td">
              <Description
                title="Gestor Imediato"
                description="Gestor imediato"
              />
            </td>
            <td className="input-td">
              <Description
                title={
                  managerPositionTitle || "- selecione o gestor imediato -"
                }
                description="Cargo do gestor imediato"
              />
            </td>
            <td className="input-td">
              <MaterialTextField
                id="manager"
                value={evaluatorName || "- selecione -"}
                variant="standard"
                margin="none"
                type="text"
                hideLabel
                onClick={() => openSelectMember(participant, "manager")}
                inputStyle={{
                  textAlign: "left",
                  padding: "0px 12px",
                  height: "40px",
                  fontSize: "10px",
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="input-td">
              <Description title="Cliente" description="Cliente" />
            </td>
            <td className="input-td">
              <Description
                title={
                  secondEvaluatorPositionTitle || "- selecione o cliente -"
                }
                description="Cargo do Cliente"
              />
            </td>
            <td className="input-td">
              <MaterialTextField
                id="secondEvaluator"
                value={secondEvaluatorName || "- selecione -"}
                variant="standard"
                margin="none"
                type="text"
                hideLabel
                onClick={() => openSelectMember(participant, "secondEvaluator")}
                inputStyle={{
                  textAlign: "left",
                  padding: "0px 12px",
                  height: "40px",
                  fontSize: "10px",
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="input-td">
              <Description title="Fornecedor" description="Fornecedor" />
            </td>
            <td className="input-td">
              <Description
                title={
                  thirdEvaluatorPositionTitle || "- selecione o fornecedor-"
                }
                description="Cargo do fornecedor"
              />
            </td>
            <td className="input-td">
              <MaterialTextField
                id="thirdEvaluator"
                value={thirdEvaluatorName || "- selecione -"}
                variant="standard"
                margin="none"
                type="text"
                hideLabel
                onClick={() => openSelectMember(participant, "thirdEvaluator")}
                inputStyle={{
                  textAlign: "left",
                  padding: "0px 12px",
                  height: "40px",
                  fontSize: "10px",
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

WorkflowSelectionEvaluatorsTable.propTypes = {
  workflow: PropTypes.array.isRequired,
  enabledFields: PropTypes.object.isRequired,
  scaleOptions: PropTypes.array.isRequired,
  handleWorkflowUpdate: PropTypes.func,
  blockUpdates: PropTypes.bool,
  hideComments: PropTypes.bool,
};

WorkflowSelectionEvaluatorsTable.defaultProps = {
  handleWorkflowUpdate: () => {},
  blockUpdates: false,
  hideComments: false,
};

export default connect(null, { putUpdateParticipantWithPersistence })(
  WorkflowSelectionEvaluatorsTable,
);
