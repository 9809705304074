import React from "react";
import { defineMessages } from "react-intl";
import { translatedText } from "../../../../utils/translationUtils";

const messages = defineMessages({
  acp_table_text_actions: { id: "acp_table_text_actions" },
});

const translation = (id, values) => translatedText(id, messages, values);

export default (props) => {
  const { actions = 0, hideTitle = false, marginLeft = "0" } = props;

  return (
    <div
      style={{
        textAlign: "center",
        lineHeight: "18px",
        width: "40px",
        margin: "0 auto",
        marginLeft,
      }}
    >
      {!hideTitle && (
        <font
          style={{
            fontSize: "12px",
          }}
        >
          {translation("acp_table_text_actions")}:
        </font>
      )}
      <div
        style={{
          fontWeight: "bold",
          textAlign: "center",
          width: "24px",
          height: "24px",
          lineHeight: "24px",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          borderRadius: "50%",
          margin: "0 auto",
        }}
        title={translation("acp_table_text_actions")}
      >
        {actions}
      </div>
    </div>
  );
};
