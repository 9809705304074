import { apiJava } from "../../api";

// Selected Tool Metrics

export const createSelectedToolMetric = (selectedToolMetricData) => {
  return apiJava.post("/api/selected-tool-metrics", selectedToolMetricData);
};

export const updateSelectedToolMetric = (
  selectedToolMetricId,
  selectedToolMetricData,
) => {
  return apiJava.put(
    `/api/selected-tool-metrics/${selectedToolMetricId}`,
    selectedToolMetricData,
  );
};

export const partialUpdateSelectedToolMetric = (
  selectedToolMetricId,
  selectedToolMetricData,
) => {
  return apiJava.patch(
    `/api/selected-tool-metrics/${selectedToolMetricId}`,
    selectedToolMetricData,
  );
};

export const getAllSelectedToolMetrics = () => {
  return apiJava.get("/api/selected-tool-metrics");
};

export const getSelectedToolMetricById = (selectedToolMetricId) => {
  return apiJava.get(`/api/selected-tool-metrics/${selectedToolMetricId}`);
};

export const deleteSelectedToolMetric = (selectedToolMetricId) => {
  return apiJava.delete(`/api/selected-tool-metrics/${selectedToolMetricId}`);
};

export const postLinkSelectedToolMetricToKpiGoal = (
  selectedToolMetricId,
  kpiGoalId,
) => {
  return apiJava.post(
    `/api/kpi-configuration/selectedToolMetric/${selectedToolMetricId}/kpiGoal/${kpiGoalId}`,
  );
};
