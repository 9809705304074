const en = {
  accessLevels_name: "Name",
  accessLevels_actions: "Actions",
  accessLevels_search: "Search",
  accessLevels_include: "Include",
  accessLevels_new_owner: "New Owner(s)",
  accessLevels_new_administrator: "New Administrator(s)",
  accessLevels_new_moderator: "New Moderator(s)",
  accessLevels_new_access: "New Access",
  accessLevels_new_manager: "New Manager(s)",
  accessLevels_new_visualizador: "New Viewer(s)",
  accessLevels_new_leader: "New Leader(s)",
  accessLevels_manage_managers: "Manage Managers",
  accessLevels_manage_viewers: "Manage Viewers",
  accessLevels_manage_leaders: "Manage Leaders",
  accessLevels_manage_permissions: "Manage Permissions",
  accessLevels_manage_domain: "Manages the Domain",
  accessLevels_include_new_owners: "Includes new owner(s)",
  accessLevels_create_nominee_admins:
    "Creates companies and appoint administrators for them.",
  accessLevels_manage_companies: "Manages the companies",
  accessLevels_include_new_admins: "Includes new administrator(s)",
  accessLevels_create_nominee_moderators:
    "Creates workspaces and appoint moderators for them.",
  accessLevels_manage_workspaces: "Manages the workspace(s).",
  accessLevels_include_new_moderators: "Includes new moderator(s).",
  accessLevels_create_nominee_managers:
    "Creates tools and appoint Managers or Viewers for them.",
  accessLevels_manage_tools: "Manages the tool(s).",
  accessLevels_include_new_managers: "Includes new Manager(s) .",
  accessLevels_create_elements_board: "Creates elements in the board.",
  accessLevels_create_nominee_viewers_leaders:
    "Includes Viewers for the tool(s) and Scenario Leaders(s).",
  accessLevels_viewer_profile: "Only see information",
  accessLevels_leader_scenerie: "Manages the scenerie(s)",
  accessLevels_include_new_leaders: "Includes new Leader(s)",
  domainNotFound: "No Domain Found",
  contactSuport: "Contact support@rookau.com",
  companyNotFound: "No Company Found",
  contact: "Contact",
  manageDomainUsersPermissions: "Manage permissions from Domain users ",
  addAndManageUsersPermissions: "Add users and Manage Company permissions",
  manageModerators: "Manage Workspace Moderators",
  manageManagersAndViewers: "Manage Tool Managers and Viewers",
  manageInsightPermissions: "Manage Responsibles, Colaborators and Viewers",
  youCantDeleteLast: "You cannot remove the last ",
  removeAccessFrom: "You are removing the access from ",
  changePermissionFrom: "Change permission from ",
  manageLeadersAndViewers: "Manage Scenario Leaders and Viewers",
  manageInsight: "Insight Management",
  addCollaborators: "Add Collaborators",
  limitedInsightEdition: "Limited Insight Edition",
};

const es = {
  accessLevels_name: "Nombre",
  accessLevels_actions: "Acción",
  accessLevels_search: "Buscar",
  accessLevels_include: "Incluir",
  accessLevels_new_owner: "Nuevo Dueño",
  accessLevels_new_administrator: "Nuevos Administradores",
  accessLevels_new_moderator: "Nuevos Moderadores",
  accessLevels_new_manager: "Nuevos Gerentes",
  accessLevels_new_visualizador: "Nuevos Espectadores",
  accessLevels_new_leader: "Nuevos Líderes",
  accessLevels_new_access: "Nuevo Acceso",
  accessLevels_manage_managers: "Administrar Gerentes",
  accessLevels_manage_viewers: "Administrar Espectadores",
  accessLevels_manage_leaders: "Administrar Líderes",
  accessLevels_manage_permissions: "Administrar Permisos",

  accessLevels_manage_domain: "Administra el dominio",
  accessLevels_include_new_owners: "Incluye nuevos propietarios",
  accessLevels_create_nominee_admins:
    "Crea empresas y nombra administradores para ellas..",
  accessLevels_manage_companies: "Administra las empresas.",
  accessLevels_include_new_admins: "Incluye nuevos administradores",
  accessLevels_create_nominee_moderators:
    "Crea workspaces y nombra moderadores para ellos.",

  accessLevels_manage_workspaces: "Administra los workspaces.",
  accessLevels_include_new_moderators: "Incluye nuevos moderadores.",
  accessLevels_create_nominee_managers:
    "Crea herramientas y nombra gerentes o espectadores para ellos.",
  accessLevels_manage_tools: "Administra las herramientas.",
  accessLevels_include_new_managers: "Incluye nuevos gerentes.",
  accessLevels_create_elements_board: "Crea elementos en el tablero.",

  accessLevels_create_nominee_viewers_leaders:
    "Incluye espectadores para las herramientas y líderes de escenarios.",

  accessLevels_viewer_profile: "Solo ver información",

  accessLevels_leader_scenerie: "Administra los paisajes",
  accessLevels_include_new_leaders: "Incluye nuevos líderes",
  domainNotFound: "No se encontró dominio",
  contactSuport: "Contacta support@rookau.com",
  companyNotFound: "No se encontraron empresas",
  contact: "Contacto",
  manageDomainUsersPermissions: "Administrar permisos de usuarios de Dominio",
  addAndManageUsersPermissions:
    "Agregar usuarios y administrar los permisos de la empresa",
  manageModerators: "Administrar Moderadores del Workspace",
  manageManagersAndViewers:
    "Administrar Gerentes y Espectadores de herramienta",
  youCantDeleteLast: "No puedes eliminar el último",
  removeAccessFrom: "Estás eliminando el acceso de ",
  changePermissionFrom: "Cambiar permiso de ",
  manageLeadersAndViewers: "Gestionar líderes y espectadores de Escenarios",
  manageInsight: "Gestión de lo Insight",
  addCollaborators: "Agregar Colaboradores",
  limitedInsightEdition: "Edición Limitada de lo Insight",
};

const pt = {
  accessLevels_name: "Nome",
  accessLevels_actions: "Ações",
  accessLevels_search: "Pesquisar",
  accessLevels_include: "Incluir",
  accessLevels_new_owner: "Novo(s) Proprietário(s)",
  accessLevels_new_administrator: "Novo(s) Administrador(es)",
  accessLevels_new_moderator: "Novo(s) Moderador(es)",
  accessLevels_new_manager: "Novo(s) Gestor(es)",
  accessLevels_new_visualizador: "Novo(s) Visualizador(es)",
  accessLevels_new_access: "Novo Acesso",
  accessLevels_new_leader: "Novo(s) Líder(es)",
  accessLevels_manage_managers: "Gerenciar Gestores",
  accessLevels_manage_viewers: "Gerenciar Visualizadores",
  accessLevels_manage_leaders: "Gerenciar Líderes",
  accessLevels_manage_permissions: "Gerenciar Permissões",
  accessLevels_manage_domain: "Gerencia o Domínio",
  accessLevels_include_new_owners: "Inclui novo(s) proprietário(s)",
  accessLevels_create_nominee_admins:
    "Cria empresas e nomeia administradores para às mesmas.",
  accessLevels_manage_companies: "Gerencia a(s) Empresa(s)",
  accessLevels_include_new_admins: "Inclui novo(s) administradores(s)",
  accessLevels_create_nominee_moderators:
    "Cria workspaces e nomeia moderadores para os mesmos.",
  accessLevels_manage_workspaces: "Gerencia o(s) Workspace(s).",
  accessLevels_include_new_moderators: "Inclui novo(s) moderadores(s).",
  accessLevels_create_nominee_managers:
    "Cria ferramentas e nomeia Gestores ou Visualizadores para as mesmas.",
  accessLevels_manage_tools: "Gerencia a(s) Ferramentas(s).",
  accessLevels_include_new_managers: "Inclui novo(s) Gestores(s).",
  accessLevels_create_elements_board: "Cria elementos no Quadro.",
  accessLevels_create_nominee_viewers_leaders:
    "Nomeia Visualizadores da(s) ferramenta(s) e Líderes de Cenário(s).",
  accessLevels_viewer_profile:
    "Acesso limitado apenas a visualização das informações.",
  accessLevels_leader_scenerie: "Gerencia o(s) cenário(s).",
  accessLevels_include_new_leaders: "Inclui novo(s) Líder(es)",
  domainNotFound: "Nenhum domínio encontrado!",
  contactSuport: "Entre em contato com support@rookau.com",
  companyNotFound: "Nenhuma empresa encontrada!",
  contact: "Entre em contato com ",
  manageDomainUsersPermissions: "Gerencie permissões de usuários do Domínio",
  addAndManageUsersPermissions:
    "Adicione usuários e Gerencie permissões da Empresa",
  manageModerators: "Gerencie Moderadores do Workspace",
  manageLeadersAndViewers: "Gerencie Líderes e Visualizadores do Cenário",
  manageManagersAndViewers: "Gerencie Gestores e Visualizadores da Ferramenta",
  youCantDeleteLast: "Você não pode remover o último ",
  removeAccessFrom: "Você está removendo o acesso de ",
  changePermissionFrom: "Alterar permissão de ",
  manageInsight: "Gerencia o Insight",
  addCollaborators: "Adiciona colaboradores",
  limitedInsightEdition: "Edita de forma limitada o insight",
};

const translations = { en, es, pt };

export default translations;
