import {
  FETCH_INSIGHT_TRACEABILITIES,
  FETCH_ALL_INSIGHT_TRACEABILITIES,
  UPDATE_ALL_INSIGHT_TRACEABILITIES,
  FETCH_INSIGHTS_TRACEABILITIES,
} from "../actions/types";
import { getOnlySlugValues } from "../utils/ArrayUtils";

const INITIAL_STATE = {};

const insightTraceabilitiesReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  const updateInsightConnections = (
    newInsightConnections = [],
    currentInsightConnections = [],
  ) => {
    const currentList = getOnlySlugValues(currentInsightConnections, "id");

    const currentUpdated = [...currentInsightConnections];
    const addedConnections = [];

    newInsightConnections.forEach((newConnectionBody) => {
      const searchIndex = currentList.indexOf(newConnectionBody.id);

      if (searchIndex === -1) addedConnections.push(newConnectionBody);
      if (searchIndex > -1) currentUpdated[searchIndex] = newConnectionBody;
    });

    return [...currentUpdated, ...addedConnections];
  };

  switch (action.type) {
    case FETCH_ALL_INSIGHT_TRACEABILITIES:
      return action.payload;
    case FETCH_INSIGHT_TRACEABILITIES:
      return {
        ...state,
        [payload.insightId]: payload.traceability,
      };

    case FETCH_INSIGHTS_TRACEABILITIES:
      const fetchedInsightsConnections = payload.traceabilities;

      let updatedTraceabilities = { ...state };

      if (fetchedInsightsConnections) {
        fetchedInsightsConnections.forEach((newConnectionInfo) => {
          const { traceabilities = [], insightId } = newConnectionInfo;
          const currentConnections = state[insightId] || [];

          const updatedInsightConnections = updateInsightConnections(
            traceabilities,
            currentConnections,
          );

          updatedTraceabilities = {
            ...updatedTraceabilities,
            [insightId]: updatedInsightConnections,
          };
        });
      }

      return {
        ...state,
        ...updatedTraceabilities,
      };

    case UPDATE_ALL_INSIGHT_TRACEABILITIES:
      const currentConnections = state[payload.insightId] || [];
      const newTraceabilities = payload.traceability;

      return {
        ...state,
        [payload.insightId]: updateInsightConnections(
          newTraceabilities,
          currentConnections,
        ),
      };
    default:
      return state;
  }
};

export default insightTraceabilitiesReducer;
