import axios from "axios";

export const postGenericEmail = (
  subject = "Default subject",
  htmlContent = "<h3>Default body</h3>",
  recipientEmails = [],
) => {
  if (recipientEmails.length === 0) return;

  return axios
    .post("/api/mail-controller/send-generic-mail", {
      subject,
      htmlContent,
      recipients: recipientEmails,
    })
    .then((res) => {
      return res;
    });
};
