import React, { useContext, createContext, useState } from "react";
import PropTypes from "prop-types";

const IConfigsModal = {
  show: false,
  next: null,
  title: null,
  onSaveText: "Salvar",
  hideAfter: true,
  onClose: (callback) => callback && callback(),
};

const configsModal = {
  getConfigsModal: () => null,
  configsModal: IConfigsModal,
};

const CustomModalContext = createContext(configsModal);

const CustomModalProvider = ({ children }) => {
  const [configsModal, setConfigsModal] = useState(IConfigsModal);

  const handleSetConfigsModal = (configs) => {
    setConfigsModal({ ...configsModal, ...configs });
  };

  return (
    <CustomModalContext.Provider
      value={{ handleSetConfigsModal, configsModal }}
    >
      {children}
    </CustomModalContext.Provider>
  );
};

function useCustomModal() {
  const context = useContext(CustomModalContext);

  if (!context) {
    throw new Error("useCustomModal must be used within a ToastProvider");
  }

  return context;
}

export { CustomModalProvider, useCustomModal, CustomModalContext };

CustomModalProvider.propTypes = {
  children: PropTypes.object,
};

CustomModalProvider.defaultProps = {
  children: {},
};
