const en = {
  insight_main_title: "Insight",
  add_new_insight: "Add insight",
};

const es = {
  insight_main_title: "Información",
  add_new_insight: "Añadir información",
};

const pt = {
  insight_main_title: "Insight",
  add_new_insight: "Adicionar insight",
};

const translations = { en, es, pt };

export default translations;
