import * as actions from "./_actions";
import send from "../../dispatcher";

function obeserveOKR({ stompClient, dispatch }) {
  stompClient.subscribe("/okr-configurations/addObjective", (response) => {
    send(actions.postObjective, response, dispatch);
  });

  stompClient.subscribe("/okr-configuration-objectives/update", (response) => {
    send(actions.putObjective, response, dispatch);
  });

  stompClient.subscribe("/okr-configuration-objectives/delete", (response) => {
    send(actions.deleteObjective, response, dispatch);
  });

  stompClient.subscribe("/okr-configuration-objectives/addKey", (response) => {
    send(actions.postKey, response, dispatch);
  });

  stompClient.subscribe(
    "/okr-configuration-key/changeObjective",
    (response) => {
      send(actions.putKey, response, dispatch);
    },
  );

  stompClient.subscribe("/okr-configuration-key/delete", (response) => {
    send(actions.deleteKey, response, dispatch);
  });

  stompClient.subscribe("/okr-configurations/addCyclesBase", (response) => {
    send(actions.postCycleBase, response, dispatch);
  });

  stompClient.subscribe(
    "/cycles-base-pattern-cycles/updateListOfCycles",
    (response) => {
      send(actions.putPatterns, response, dispatch);
    },
  );

  stompClient.subscribe("/cycles-base-patterns/addPattern", (response) => {
    send(actions.postPatterns, response, dispatch);
  });

  stompClient.subscribe("/cycles-base-pattern-cycles/delete", (response) => {
    send(actions.deleteBasePattern, response, dispatch);
  });

  stompClient.subscribe("/okr-configurations/addPlan", (response) => {
    send(actions.postPlan, response, dispatch);
  });

  stompClient.subscribe("/okr-configuration-plans/update", (response) => {
    send(actions.putPlan, response, dispatch);
  });

  stompClient.subscribe("/okr-configuration-plans/delete", (response) => {
    send(actions.deletePlan, response, dispatch);
  });

  stompClient.subscribe("/okr-configurations/cenary", (response) => {
    send(actions.postBoardCenaryLink, response, dispatch);
  });

  stompClient.subscribe("/okr-configurations/theme", (response) => {
    send(actions.postBoardThemeLink, response, dispatch);
  });

  stompClient.subscribe("/okr-configurations/question", (response) => {
    send(actions.postBoardQuestionLink, response, dispatch);
  });

  stompClient.subscribe("/okr-configurations/insight", (response) => {
    send(actions.postBoardInsightLink, response, dispatch);
  });

  return stompClient;
}

export default obeserveOKR;
