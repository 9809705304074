import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Tooltip, Grid } from "@material-ui/core";
import { TdRegister, GridContainer } from "../css/OkrCss";

import { getSelectedToolStates } from "../../../../../customMapStates";
import MaterialTextField from "../../../../../Common/MaterialTextField";

import utils from "../../../../../../utils/toolUtils";

const { getMaxStringLength, getComparableQuery } = utils;

const defaultFilter = {
  attr: "key",
  order: "ASC",
};

function DisplayTracking(props) {
  const [filter, setFilter] = useState("");
  const [orderFilter, setOrderFilter] = useState(defaultFilter);
  const [orderedFilter, setOrderedFilter] = useState([]);

  const { intl, allSceneries, allThemes, allQuestions, allAnswers } = props;

  const getAllInsights = useCallback(() => {
    const tracking = [];

    if (allSceneries) {
      allSceneries.forEach((scenerieInfo) => {
        if (allThemes[scenerieInfo.id]) {
          allThemes[scenerieInfo.id].forEach((themeInfo) => {
            if (allQuestions[themeInfo.id]) {
              allQuestions[themeInfo.id].forEach((questionInfo) => {
                if (allAnswers[questionInfo.id]) {
                  allAnswers[questionInfo.id].forEach((insightInfo) => {
                    tracking.push({ insightInfo });
                  });
                }
              });
            }
          });
        }
      });
    }

    return tracking;
  }, [allSceneries, allThemes, allQuestions, allAnswers]);

  useEffect(() => {
    const ord1 = orderFilter.order === "ASC" ? 1 : -1;
    const ord2 = orderFilter.order === "ASC" ? -1 : 1;
    let newOrdered = [];

    if (orderFilter.attr === "key") {
      // FILTER BY TITLE
      newOrdered = [...getAllInsights()].sort((a, b) =>
        a.insightInfo.name > b.insightInfo.name ? ord1 : ord2,
      );
    }

    if (orderFilter.attr === "objective") {
      newOrdered = [...getAllInsights()].sort((a, b) => {
        const { insightInfo } = a;
        const { question } = insightInfo;
        const { insightInfo: insightInfoB } = b;
        const { question: questionB } = insightInfoB;

        return question.text > questionB.text ? ord1 : ord2;
      });
    }

    if (orderFilter.attr === "cycle") {
      newOrdered = [...getAllInsights()].sort((a, b) => {
        const { insightInfo } = a;
        const { question } = insightInfo;
        const { theme } = question;
        const { insightInfo: insightInfoB } = b;
        const { question: questionB } = insightInfoB;
        const { theme: themeB } = questionB;

        return theme.name > themeB.name ? ord1 : ord2;
      });
    }

    if (orderFilter.attr === "scenarie") {
      newOrdered = [...getAllInsights()].sort((a, b) => {
        const { insightInfo } = a;
        const { question } = insightInfo;
        const { theme } = question;
        const { cenary } = theme;
        const { insightInfo: insightInfoB } = b;
        const { question: questionB } = insightInfoB;
        const { theme: themeB } = questionB;
        const { cenary: cenaryB } = themeB;

        return cenary.name > cenaryB.name ? ord1 : ord2;
      });
    }

    if (!_.isEqual(newOrdered, orderedFilter)) {
      setOrderedFilter(newOrdered);
    }
  }, [getAllInsights, orderFilter, orderedFilter]);

  function getFilterTracking() {
    const compareString = getComparableQuery(filter);
    let tracking = [];

    tracking =
      orderedFilter &&
      orderedFilter.filter((trackingInfo) => {
        const { insightInfo } = trackingInfo;
        const { question } = insightInfo;

        if (getComparableQuery(insightInfo.name).match(compareString)) {
          return true;
        }
        if (getComparableQuery(question.text).match(compareString)) {
          return true;
        }
        return false;
      });

    return tracking;
  }

  const getNewOrder = (currentOrder) => {
    if (currentOrder === "ASC") return "DESC";
    return "ASC";
  };

  const handleFilter = (attr) => {
    let updatedFilter = defaultFilter;

    if (attr === orderFilter.attr) {
      updatedFilter = {
        attr,
        order: getNewOrder(orderFilter.order),
      };
    } else {
      updatedFilter = { ...defaultFilter, attr };
    }

    setOrderFilter(updatedFilter);
  };

  const orderButton = ({ attr }) => {
    return (
      <button
        className="btn btn-data-sort grow"
        onClick={() => handleFilter(attr)}
        style={{ padding: "0px 5px", margin: "0px 0px 0px 10px" }}
      >
        <i className="fas fa-caret-up" />
        <i className="fas fa-caret-down" />
      </button>
    );
  };

  function handleChangeFilter(e) {
    setFilter(e.target.value);
  }

  return (
    <>
      <Grid item md={4} style={{ marginLeft: "15px" }}>
        <MaterialTextField
          id="filter"
          label={intl.formatMessage({ id: "search_okr" })}
          name="filter"
          margin="normal"
          value={filter}
          onChange={handleChangeFilter}
        />
      </Grid>
      <GridContainer container spacing={4}>
        <Grid item>
          <h4>{intl.formatMessage({ id: "tracking_keyResults" })}</h4>
          <table className="simple-table">
            <thead>
              <tr>
                <td style={{ width: "405.5px" }}>
                  {intl.formatMessage({ id: "key_results" })}
                  {orderButton({ attr: "key" })}
                </td>
                <td style={{ width: "405.5px" }}>
                  {intl.formatMessage({ id: "objective_title" })}
                  {orderButton({ attr: "objective" })}
                </td>
                <td style={{ width: "405.5px" }}>
                  {intl.formatMessage({ id: "cycle_title" })}
                  {orderButton({ attr: "cycle" })}
                </td>
                <td style={{ width: "405.5px" }}>
                  {intl.formatMessage({ id: "global.sceneries" })}
                  {orderButton({ attr: "scenarie" })}
                </td>
              </tr>
            </thead>
            <tbody>
              {getFilterTracking() && getFilterTracking().length ? (
                getFilterTracking().map((trackingInfo) => {
                  const { insightInfo = {} } = trackingInfo;
                  const { question = {} } = insightInfo;
                  const { theme = {} } = question;
                  const { cenary = {} } = theme;

                  const paramsFormatText = {
                    beginIndex: 0,
                    endIndex: 50,
                    additional: "...",
                  };

                  const cenaryName = getMaxStringLength({
                    ...paramsFormatText,
                    string: cenary.name,
                  });
                  const themeName = getMaxStringLength({
                    ...paramsFormatText,
                    string: theme.name,
                  });
                  const questionName = getMaxStringLength({
                    ...paramsFormatText,
                    string: question.text,
                  });
                  const insightName = getMaxStringLength({
                    ...paramsFormatText,
                    string: insightInfo.name,
                  });

                  return (
                    <tr key={insightInfo.id}>
                      <TdRegister>
                        <Tooltip
                          title={insightInfo.name}
                          placement="bottom-start"
                        >
                          <span>{insightName}</span>
                        </Tooltip>
                      </TdRegister>
                      <TdRegister>
                        <Tooltip title={question.text} placement="bottom-start">
                          <span>{questionName}</span>
                        </Tooltip>
                      </TdRegister>
                      <TdRegister>
                        <Tooltip title={theme.name} placement="bottom-start">
                          <span>{themeName}</span>
                        </Tooltip>
                      </TdRegister>
                      <TdRegister>
                        <Tooltip title={cenary.name} placement="bottom-start">
                          <span>{cenaryName}</span>
                        </Tooltip>
                      </TdRegister>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <TdRegister colSpan="4" style={{ textAlign: "center" }}>
                    {intl.formatMessage({ id: "text_results_found" })}
                  </TdRegister>
                </tr>
              )}
            </tbody>
          </table>
        </Grid>
      </GridContainer>
    </>
  );
}

const mapStateToProps = (state) => {
  const {
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
  } = getSelectedToolStates(state);

  return {
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
  };
};

export default connect(mapStateToProps, null)(injectIntl(DisplayTracking));

DisplayTracking.propTypes = {
  intl: PropTypes.object.isRequired,
  allThemes: PropTypes.object.isRequired,
  allQuestions: PropTypes.object.isRequired,
  allAnswers: PropTypes.object.isRequired,
  allSceneries: PropTypes.array.isRequired,
};
