import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  deleteComplementaryScale,
  deleteComplementaryEvaluation,
} from "../../../../../../../actions/skillAssessmentActions";
import { getScalesDeleteDialogInfo } from "../../../../../../../utils/skillsAssessment/deletes";
import { getComplementaryEvaluationsMigrationConfig } from "../../../../../../../utils/skillsAssessment/import-defaults";
import { persistMigrateComplementaryEvaluation } from "../../../../../../../utils/skillsAssessment/entityAutomation";
import TableComplementaryEvaluationsRegister from "../tables/TableComplementaryEvaluationsRegister";

const TabComplementaryEvaluations = (props) => {
  const {
    toolAdministration = {},
    updateAdministration,
    handleAutoSave,
    setConfirmationDialog,
    resetConfirmDialog,
    errors,
    deleteComplementaryScale,
    deleteComplementaryEvaluation,
    selectedTool,
    handleSetEditingForm,
  } = props;

  const { evaluationConfig } = toolAdministration;
  const {
    enableImportKeySeat,
    enableImportPerformance,
    enableImportSuccession,
  } = getComplementaryEvaluationsMigrationConfig(toolAdministration);

  const handleDeleteScale = (scaleId) => {
    deleteComplementaryScale(selectedTool.id, scaleId);
  };

  const deleteConfirmation = (
    title,
    description,
    deleteCallback = () => {},
  ) => {
    setConfirmationDialog({
      open: true,
      title,
      description,
      onConfirm: () => {
        deleteCallback();
        resetConfirmDialog();
      },
      onCancel: resetConfirmDialog,
    });
  };

  const openDeleteScale = (scaleId) => {
    const { title, description } = getScalesDeleteDialogInfo(
      "complementaryScales",
    );

    deleteConfirmation(title, description, () => handleDeleteScale(scaleId));
  };

  const openDeleteEvaluation = (evaluationId) => {
    deleteConfirmation(
      "Tem certeza que deseja remover essa avaliação complementar?",
      "OBS: Esta avaliação será removida do Workflow e não poderá ser recuperada.",
      () => deleteComplementaryEvaluation(selectedTool.id, evaluationId),
    );
  };

  function addComplementaryEvaluation(type, subType) {
    handleSetEditingForm("newComplementaryEvaluation", { type, subType });
  }

  function addComplementaryScale(type) {
    handleSetEditingForm("newComplementaryScale", { type });
  }

  function handleImportAndSave() {
    const updatedAdministration =
      persistMigrateComplementaryEvaluation(toolAdministration);
    if (updatedAdministration?.id) handleAutoSave(updatedAdministration);
  }

  function importScalesAndEvaluations() {
    setConfirmationDialog({
      open: true,
      title: "Tem certeza que deseja importar a configuração padrão?",
      confirmText: "Importar",
      onConfirm: () => {
        handleImportAndSave();
        resetConfirmDialog();
      },
    });
  }

  return (
    <div className="tab-content" style={{ margin: "0px" }}>
      <TableComplementaryEvaluationsRegister
        title="CRITICIDADE DA FUNÇÃO E POSIÇÃO"
        type="KEY_SEAT"
        open={evaluationConfig.keySeat}
        toolAdministration={toolAdministration}
        updateAdministration={updateAdministration}
        enableImport={enableImportKeySeat}
        openDeleteScale={openDeleteScale}
        openDeleteEvaluation={openDeleteEvaluation}
        addComplementaryEvaluation={addComplementaryEvaluation}
        addComplementaryScale={addComplementaryScale}
        importScalesAndEvaluations={importScalesAndEvaluations}
        errors={errors}
      />
      <TableComplementaryEvaluationsRegister
        title="AVALIAÇÃO DE DESEMPENHO"
        type="PERFORMANCE"
        open={evaluationConfig.performance}
        toolAdministration={toolAdministration}
        updateAdministration={updateAdministration}
        enableImport={enableImportPerformance}
        openDeleteScale={openDeleteScale}
        openDeleteEvaluation={openDeleteEvaluation}
        addComplementaryEvaluation={addComplementaryEvaluation}
        addComplementaryScale={addComplementaryScale}
        importScalesAndEvaluations={importScalesAndEvaluations}
        errors={errors}
      />
      <TableComplementaryEvaluationsRegister
        title="AVALIAÇÃO DE SUCESSÃO"
        type="SUCCESSION"
        open={evaluationConfig.succession}
        toolAdministration={toolAdministration}
        updateAdministration={updateAdministration}
        enableImport={enableImportSuccession}
        openDeleteScale={openDeleteScale}
        openDeleteEvaluation={openDeleteEvaluation}
        addComplementaryEvaluation={addComplementaryEvaluation}
        addComplementaryScale={addComplementaryScale}
        importScalesAndEvaluations={importScalesAndEvaluations}
        errors={errors}
      />
    </div>
  );
};

TabComplementaryEvaluations.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
  selectedTool: PropTypes.object.isRequired,
  updateAdministration: PropTypes.func.isRequired,
  setConfirmationDialog: PropTypes.func.isRequired,
  handleSetEditingForm: PropTypes.func.isRequired,
  handleAutoSave: PropTypes.func.isRequired,
  resetConfirmDialog: PropTypes.func.isRequired,
  deleteComplementaryScale: PropTypes.func.isRequired,
  deleteComplementaryEvaluation: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
};

export default connect(null, {
  deleteComplementaryScale,
  deleteComplementaryEvaluation,
})(TabComplementaryEvaluations);
