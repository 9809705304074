import { SET_LANGUAGE } from "../actions/types";
import { getLanguage } from "../actions/languageActions";

const INITIAL_STATE = getLanguage();

const languageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      localStorage.setItem("splaceLanguage", action.payload);
      return action.payload;
    default:
      return state;
  }
};

export default languageReducer;
