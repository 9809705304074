/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NewTheme from "../../../Common/SkillAssessment/NewTheme";
import {
  getSkillAssessmentStates,
  getSelectedToolStates,
} from "../../../customMapStates";
import { addAreasToBoard } from "../../../../actions/skillAssessmentActions";
import { getParticipantsCompanyAreas } from "../../../../utils/skillsAssessment/filter";
import { useSimpleToolAdministration } from "../../../../utils/skillsAssessment/hooks/administration";
import { getOnlySlugValues } from "../../../../utils/ArrayUtils";

const SkillAssessment = ({
  type,
  clearViewCallback,
  cancelCallback,
  selectedTool,
  allSkillAssessment,
  allThemes,
  isLoading,
  params,
  addAreasToBoard,
}) => {
  const { toolAdministration } = useSimpleToolAdministration({
    selectedTool,
    allSkillAssessment,
    isLoading,
  });

  const { themeScenerie } = params;
  const themes = allThemes[themeScenerie.id] || [];

  const clearDialogControll = () => {
    clearViewCallback(type);
    cancelCallback();
  };

  const addAreas = (selected) => {
    const addOnly = [];

    selected.forEach((areaInfo) => {
      if (
        getOnlySlugValues(themes, "companyAreaId").indexOf(areaInfo.id) === -1
      )
        addOnly.push(areaInfo);
    });

    if (addOnly.length > 0) {
      addAreasToBoard(addOnly, themeScenerie, params.order);
    }

    clearDialogControll();
  };

  const { companyAreas } = getParticipantsCompanyAreas(
    toolAdministration.participants,
  );
  const filteredCompanyAreas = companyAreas?.filter(
    (area) =>
      themes.findIndex((theme) => theme?.companyAreaId === area?.id) === -1,
  );

  return (
    <NewTheme
      open
      onConfirm={addAreas}
      onCancel={clearDialogControll}
      companyAreas={filteredCompanyAreas}
    />
  );
};

SkillAssessment.propTypes = {
  type: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  selectedTool: PropTypes.object.isRequired,
  allSkillAssessment: PropTypes.array.isRequired,
  addAreasToBoard: PropTypes.func.isRequired,
  clearViewCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func,
  isLoading: PropTypes.bool,
};

SkillAssessment.defaultProps = {
  isLoading: false,
  cancelCallback: () => {},
};

const mapStateToProps = (state) => {
  const { selectedTool = {}, allThemes = {} } = getSelectedToolStates(state);
  const { allSkillAssessment = [], isLoading } =
    getSkillAssessmentStates(state);

  return {
    selectedTool,
    allSkillAssessment,
    allThemes,
    isLoading,
  };
};

export default connect(mapStateToProps, { addAreasToBoard })(SkillAssessment);
