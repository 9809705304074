const currentDate = new Date();
const currentYear = currentDate.getFullYear();

export const configPatternCycle = () => {
  const bodyCycle = {
    id: null,
    title: "",
    startDate: "",
    endDate: "",
    checkDate: "",
  };

  return bodyCycle;
};

export const configCycleBaseBody = () => {
  const bodyPattern = {
    id: null,
    title: "",
    cycles: [],
  };

  return bodyPattern;
};

export const configCycleBody = (
  cycle,
  defaultString = "",
  defaultId = null,
) => {
  let bodyCycle = cycle;

  bodyCycle = bodyCycle || {};
  bodyCycle.id = bodyCycle.id || defaultId;
  bodyCycle.idTeam = bodyCycle.idTeam || defaultId;
  bodyCycle.team = bodyCycle.team || {};
  bodyCycle.index = bodyCycle.index || defaultId;
  bodyCycle.checkDate = bodyCycle.checkDate || defaultString;
  bodyCycle.cycleType = bodyCycle.cycleType || "DEFAULT";
  bodyCycle.endDate = bodyCycle.endDate || defaultString;
  bodyCycle.startDate = bodyCycle.startDate || defaultString;
  bodyCycle.title = bodyCycle.title || defaultString;

  return { ...bodyCycle };
};

export const configCicleGroupsBody = (cycleGroups) => {
  let bodyCycleGroups = cycleGroups;

  bodyCycleGroups = bodyCycleGroups || {};
  bodyCycleGroups.id = bodyCycleGroups.id || null;
  bodyCycleGroups.year = bodyCycleGroups.year || currentYear;
  bodyCycleGroups.cycles = bodyCycleGroups.cycles || [];

  bodyCycleGroups.cycles.forEach((cycle) => {
    configCycleBody(cycle);
  });

  return { ...bodyCycleGroups };
};

export const configPlansBody = (plan, defaultString = "", defaultId = null) => {
  let bodyPlan = plan;

  bodyPlan = bodyPlan || {};
  bodyPlan.id = bodyPlan.id || defaultId;
  bodyPlan.organization = bodyPlan.organization || {};
  bodyPlan.organization.id = bodyPlan.organization.id || defaultId;
  bodyPlan.organization.title = bodyPlan.organization.title || defaultString;
  bodyPlan.organization.type = bodyPlan.organization.type || "TEAM";
  bodyPlan.proposal = bodyPlan.proposal || defaultString;
  bodyPlan.cycleGroups = bodyPlan.cycleGroups || [];

  let { cycleGroups } = bodyPlan;

  if (Array.isArray(cycleGroups)) {
    cycleGroups = cycleGroups.map((cycleGroup) =>
      configCicleGroupsBody(cycleGroup),
    );
  }

  return {
    ...plan,
    cycleGroups,
  };
};

export const configKeysBody = (keys, defaultString = "", defaultId = null) => {
  let bodyKeys = keys;

  bodyKeys = bodyKeys || {};
  bodyKeys.id = bodyKeys.id || defaultId;
  bodyKeys.title = bodyKeys.title || defaultString;

  return { ...bodyKeys };
};

export const configObjectivesBody = (
  objective,
  defaultString = "",
  defaultArray = [],
  defaultId = null,
) => {
  let bodyObjetive = objective;

  bodyObjetive = bodyObjetive || {};

  bodyObjetive.id = bodyObjetive.id || defaultId;
  bodyObjetive.title = bodyObjetive.title || defaultString;

  const { keys = defaultArray } = bodyObjetive;

  keys.forEach((key) => {
    configKeysBody(key);
  });

  return { ...objective };
};

const getBodyConfig = (
  body,
  callBack,
  defaultString = null,
  defaultArray = [],
) => {
  body.forEach((item) => {
    callBack(item, defaultString, defaultArray);
  });
};

const buildArraysRestBody = (restBody, slug) => {
  switch (slug) {
    case "PLANS":
      getBodyConfig(restBody, configPlansBody);
      break;
    case "OBJECTIVES":
      getBodyConfig(restBody, configObjectivesBody);
      break;
    default:
      break;
  }
};

export const biuldAdmRestBody = (admConfig) => {
  const { plans = [], objectives = [] } = admConfig;

  buildArraysRestBody(plans, "PLANS");
  buildArraysRestBody(objectives, "OBJECTIVES");

  return {
    plans,
    objectives,
  };
};

export const removeIdsFromNewCycleGroup = (newCycleGroup) => {
  const cycleGroupsNewCycles = [];

  if (newCycleGroup.cycles) {
    newCycleGroup.cycles.forEach((cycleInfo) => {
      cycleGroupsNewCycles.push({
        id: null,
        ...cycleInfo,
      });
    });
  }

  return { ...newCycleGroup, id: null, cycles: cycleGroupsNewCycles };
};

export const getClonnedPatternCycles = (patternCycles) => {
  const clonned = [];

  if (patternCycles && Array.isArray(patternCycles)) {
    patternCycles.forEach((patternCycleInfo, index) => {
      clonned.push({ ...patternCycleInfo, index, id: null });
    });
  }

  return clonned;
};
