import {
  GOALS_AND_BONUS,
  PROJECT_PORTFOLIO,
  PEOPLE_BIG_PICTURE,
  OKR_ID,
  KPIS,
  COMPETITIVE_ANALYSIS,
  SKILLS_ASSESSMENT,
  ACTION_PLAN_ID,
} from "../../../constants/tools.constants";
import getGoalsAndBonusTabs from "./CustomFields/GoalsAndBonus/tabs";
import getKpiTabs from "./CustomFields/KPI/tabs";
import getProjectPortfolioTabs from "./CustomFields/ProjectManagement/tabs";
import getPeopleBigPictureTabs from "./CustomFields/PeopleBigPicture/tabs";
import { getOnePageTabsConfig } from "./CustomFields/PeopleBigPicture/accessController";
import getSkillsAssessmentTabs from "./CustomFields/SkillAssessment/tabs";
import { getSkillsAssessmentTabsConfig } from "./CustomFields/SkillAssessment/accessController";
import getOkrTabs from "./CustomFields/Okr";
import getCompAnalysisTabs from "./CustomFields/CompAnalysis";
import getActionPlan from "./CustomFields/ActionPlan";

export const getToolCustomTabs = (toolId, appendProps) => {
  let customTabs = [];

  const addTabs = (newTabs = []) => {
    if (newTabs && newTabs.length > 0) {
      customTabs = [...customTabs, ...newTabs];
    }
  };

  switch (toolId) {
    case GOALS_AND_BONUS:
      addTabs(getGoalsAndBonusTabs(appendProps));
      break;
    case KPIS:
      addTabs(getKpiTabs(appendProps));
      break;
    case PROJECT_PORTFOLIO:
      addTabs(getProjectPortfolioTabs(appendProps));
      break;
    case PEOPLE_BIG_PICTURE:
      addTabs(getPeopleBigPictureTabs(appendProps));
      break;
    case OKR_ID:
      addTabs(getOkrTabs(appendProps));
      break;
    case COMPETITIVE_ANALYSIS:
      addTabs(getCompAnalysisTabs(appendProps));
      break;
    case SKILLS_ASSESSMENT:
      addTabs(getSkillsAssessmentTabs(appendProps));
      break;
    case ACTION_PLAN_ID:
      addTabs(getActionPlan(appendProps));
      break;
    default:
      return customTabs;
  }

  return customTabs;
};

export const getToolTabsConfig = (toolId) => {
  if (toolId === SKILLS_ASSESSMENT) return getSkillsAssessmentTabsConfig();
  if (toolId === PEOPLE_BIG_PICTURE) return getOnePageTabsConfig();

  return {
    management: true,
    tool: true,
    score: true,
    timeline: true,
  };
};

export const getInitialTab = ({ toolId, history, selectedToolID }) => {
  if (history.location.pathname === `/tool/${selectedToolID}/workflow`) {
    return "workflow";
  }

  if (toolId === SKILLS_ASSESSMENT) return "management";

  return "management";
};
