import React from "react";
import PropTypes from "prop-types";

import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import CustomGraphTooltip from "./CustomGraphTooltip";

const parseDomain = (data) => {
  let finalDomain = [];

  if (data && data.length) {
    data.forEach((item) => {
      finalDomain = [...finalDomain, ...item.map((entry) => entry.value)];
    });
  }

  return [0, Math.max.apply(null, finalDomain)];
};

const CustomLabel = ({ value, ...props }) => {
  return (
    <g>
      <rect x={props.viewBox.x} y={props.viewBox.y} />
      <text x={props.viewBox.x} y={props.viewBox.y} dy={30} dx={-15}>
        {value}
      </text>
    </g>
  );
};

const SimpleBubbleChart = ({
  containerWidth,
  containerHeight,
  data,
  fillColor,
  hideTooltipLabel,
  showXLine,
  showTick,
  hideTooltipLabelName,
  hideBubblesWhenLast,
  hideDataTick,
  tooptipLegend,
  tickSize,
}) => {
  const placeBodyDataIndex = (dataArray) => {
    let final = [];

    dataArray.forEach((item) => (final = [...final, { ...item, index: 1 }]));

    return final;
  };

  const buildOnlyValues = (data) => {
    let finalArray = [];

    data.forEach((item) => {
      let itemArray = item.values && item.values.length > 0 ? item.values : [];
      finalArray = [...finalArray, itemArray];
    });

    return finalArray;
  };

  const domain = parseDomain(buildOnlyValues(data));
  const range = [16, 225];

  return (
    <div
      style={{
        width: containerWidth,
        height: "auto",
      }}
    >
      <>
        {data.map((dataInfo, index) => {
          const graphData = placeBodyDataIndex(dataInfo.values);

          const isLastData = index === data.length - 1;
          const hideBubbles = isLastData && hideBubblesWhenLast;

          return (
            <ResponsiveContainer
              width={containerWidth}
              height={containerHeight}
              key={index}
            >
              <ScatterChart
                margin={{ top: 25, right: 0, bottom: 15, left: tickSize }}
              >
                <XAxis
                  type="category"
                  dataKey="name"
                  name={dataInfo.mainLabel}
                  interval={0}
                  tickLine={
                    showTick ? { transform: "translate(0, -6)" } : false
                  }
                  tickFormatter={(val) =>
                    isLastData && !hideDataTick ? val : ""
                  }
                  axisLine={showXLine}
                  label={
                    isLastData && dataInfo.legendLabel
                      ? {
                          value: dataInfo.legendLabel,
                          position: "bottom",
                          offset: 0,
                        }
                      : {}
                  }
                />
                {!hideBubbles && (
                  <YAxis
                    type="number"
                    dataKey="index"
                    tick={false}
                    tickLine={false}
                    axisLine={false}
                    padding={{ top: -10 }}
                    label={(props) => (
                      <CustomLabel {...props} value={dataInfo.mainLabel} />
                    )}
                  />
                )}
                {hideBubbles && (
                  <YAxis
                    type="category"
                    dataKey=""
                    tick={false}
                    tickLine={false}
                    axisLine={false}
                    padding={{ top: -40 }}
                    label={{
                      value: dataInfo.mainLabel,
                      position: "insideTop",
                      offset: 20,
                    }}
                  />
                )}
                <ZAxis
                  type="number"
                  dataKey="value"
                  name={tooptipLegend ? tooptipLegend : null}
                  domain={domain}
                  range={range}
                />
                <Tooltip
                  cursor={{ strokeDasharray: "3 3" }}
                  wrapperStyle={{ zIndex: 100 }}
                  content={(tooltipProps) => {
                    const newProps = { ...tooltipProps };
                    let finalPayload = [];

                    newProps.payload.forEach((item, index) => {
                      if (item.dataKey !== "index" && item.dataKey !== "name")
                        finalPayload = [...finalPayload, item];
                    });
                    newProps.payload = finalPayload;

                    return (
                      <CustomGraphTooltip
                        hideTooltipLabel={hideTooltipLabel}
                        hideTooltipLabelName={hideTooltipLabelName}
                        customLabel={dataInfo.mainLabel}
                        customFillColor={fillColor}
                        {...newProps}
                      />
                    );
                  }}
                />
                <Scatter data={graphData} fill={fillColor} />
              </ScatterChart>
            </ResponsiveContainer>
          );
        })}
      </>
    </div>
  );
};

SimpleBubbleChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      mainLabel: PropTypes.string.isRequired,
      legendLabel: PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.number.isRequired,
          name: PropTypes.any.isRequired,
        }),
      ).isRequired,
      color: PropTypes.string,
    }),
  ).isRequired,
  tooptipLegend: PropTypes.string,
  containerWidth: PropTypes.any,
  containerHeight: PropTypes.any,
  fillColor: PropTypes.string,
  hideTooltipLabel: PropTypes.bool,
  hideTooltipLabelName: PropTypes.bool,
  hideDataTick: PropTypes.bool,
  showXLine: PropTypes.bool,
  showTick: PropTypes.bool,
  hideBubblesWhenLast: PropTypes.bool,
  tickSize: PropTypes.number,
};

SimpleBubbleChart.defaultProps = {
  data: [],
  containerWidth: "100%",
  containerHeight: 80,
  fillColor: "#8884d8",
  hideTooltipLabel: false,
  hideTooltipLabelName: false,
  hideDataTick: false,
  showXLine: true,
  showTick: true,
  hideBubblesWhenLast: false,
  tickSize: 60,
};

export default SimpleBubbleChart;
