/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { groupAndCalcParticipantStages } from "../../../../../../utils/skillsAssessment/calc";
import { getStageGroupStatusData } from "../../../../../../utils/skillsAssessment/filter";
import StatusChart from "./includes/StatusChart";
import { getObjectInfoById } from "../../../../../../utils/ArrayUtils";
import {
  getEvaluationStagesOptions,
  getEvaluationConfigByType,
} from "../../../../../../utils/skillsAssessment/constants";
import ParticipantsTableStatus from "./includes/ParticipantsTableStatus";

const StatusOverview = ({ toolAdministration }) => {
  const [grouped, setGrouped] = useState({
    SELECTION_EVALUATORS: {},
    VALIDATION: {},
    SELF_EVALUATION: {},
    MANAGERS: {},
    EVALUATORS: {},
    APPROVAL: {},
    CONCLUDED: {},
    TOTAL: [],
  });
  const [filterStage, setFilterStage] = useState("CONCLUDED");

  const { participants, dates, evaluationType, evaluationConfig } =
    toolAdministration;
  const { endValidation, endSelfEvaluation, endEvaluators, endClosure } =
    dates || {};

  const stageOptions = getEvaluationStagesOptions(
    evaluationType,
    evaluationConfig,
    true,
  );

  useEffect(() => {
    const newGrouped = groupAndCalcParticipantStages(
      participants,
      toolAdministration,
    );

    if (!_.isEqual(newGrouped, grouped)) setGrouped(newGrouped);
  }, [participants]);

  function toggleFilter(newStage) {
    setFilterStage(filterStage === newStage ? "CONCLUDED" : newStage);
  }

  function len(a) {
    return Array.isArray(a) ? a.length : 0;
  }

  function getStageLabel(stage) {
    return getObjectInfoById(stage, stageOptions, "value")?.label;
  }

  const stageGroupData = getStageGroupStatusData(grouped);

  const {
    SELECTION_EVALUATORS,
    VALIDATION,
    SELF_EVALUATION,
    ALL_EVALUATORS,
    APPROVAL,
    CONCLUDED,
    total,
  } = stageGroupData;

  const { pending, reached } = filterStage
    ? stageGroupData[filterStage] || []
    : CONCLUDED;

  const filterStageLabel = getStageLabel(filterStage);

  const { enableThirdEvaluator, enableSecondEvaluator } =
    getEvaluationConfigByType(evaluationType);

  const showEvaluations = enableSecondEvaluator || enableThirdEvaluator;

  return (
    <div className="status-overview">
      <div
        className="row status-overview-charts"
        style={{ border: "1px solid #ccc" }}
      >
        <div className="title-field">
          <h4 align="center">STATUS GERAL</h4>
        </div>
        <div className="chart-field">
          <StatusChart
            title="SELEÇÃO DE AVALIADORES"
            reached={len(SELECTION_EVALUATORS.reached)}
            total={total?.length}
            endDate={endValidation}
            selected={filterStage === "SELECTION_EVALUATORS"}
            onClick={() => toggleFilter("SELECTION_EVALUATORS")}
          />
          <StatusChart
            title="VALIDAÇÃO"
            reached={len(VALIDATION.reached)}
            total={total?.length}
            endDate={endValidation}
            selected={filterStage === "VALIDATION"}
            onClick={() => toggleFilter("VALIDATION")}
          />
          <StatusChart
            title="AUTOAVALIAÇÃO"
            reached={len(SELF_EVALUATION.reached)}
            total={total?.length}
            endDate={endSelfEvaluation}
            selected={filterStage === "SELF_EVALUATION"}
            onClick={() => toggleFilter("SELF_EVALUATION")}
          />
          {showEvaluations && (
            <StatusChart
              title="AVALIADORES"
              reached={len(ALL_EVALUATORS.reached)}
              total={total?.length}
              endDate={endEvaluators}
              selected={filterStage === "ALL_EVALUATORS"}
              onClick={() => toggleFilter("ALL_EVALUATORS")}
            />
          )}
          <StatusChart
            title="APROVAÇÃO"
            reached={len(APPROVAL.reached)}
            total={total?.length}
            endDate={endClosure}
            selected={filterStage === "APPROVAL"}
            onClick={() => toggleFilter("APPROVAL")}
          />
          <StatusChart
            title="CONCLUÍDO"
            reached={len(CONCLUDED.reached)}
            total={total?.length}
            endDate={endClosure}
            selected={filterStage === "CONCLUDED"}
            onClick={() => toggleFilter("CONCLUDED")}
          />
        </div>
      </div>
      <div style={{ padding: "25px 0px" }}>
        <h3>Relatório</h3>
        <p>
          Filtrando pelo status de avaliação: <b>{filterStageLabel}</b>
        </p>
        <ParticipantsTableStatus
          title={
            <div style={{ fontSize: "18px" }}>
              <b>Pendentes:</b> Ainda não passaram para o estágio{" "}
              {filterStageLabel}.
            </div>
          }
          participants={pending}
          evaluationType={evaluationType}
          evaluationConfig={evaluationConfig}
        />
        <ParticipantsTableStatus
          title={
            <div style={{ fontSize: "18px" }}>
              <b>Concluídos:</b> Já alcançaram o estágio {filterStageLabel} ou
              superior.
            </div>
          }
          participants={reached}
          evaluationType={evaluationType}
          evaluationConfig={evaluationConfig}
        />
      </div>
    </div>
  );
};

StatusOverview.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
};

export default StatusOverview;
