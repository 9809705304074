import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";
import FormDialog from "../../FormDialog";
import MaterialSingleSelect from "../../MaterialSingleSelect";
import ObjectForm from "../../../Tool/ToolTabs/CustomFields/Okr/tabs/forms/ObjectForm";
import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import utils from "../../../../utils/toolUtils";
import { postObjective } from "../../../../actions/okrActions";

const { getErrorMessage } = utils;

const useStyles = makeStyles(() =>
  createStyles({
    addButton: {
      fontSize: "12px",
    },
  }),
);

const messages = defineMessages({
  dialog_title_new_theme: { id: "dialog_title_new_theme" },
  btn_modal_register_new: { id: "btn_modal_register_new" },
});

const initialForm = {
  processes: [],
};

const QuestionProcessSelectDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const [registerDialog, setRegisterDialog] = useState(false);

  const {
    open = false,
    afterCancel,
    afterConfirm,
    okrObjectives,
    intl,
    okrConfigurationId,
    postObjective,
    addedObjectives,
    questionTheme,
    handleSaveQuestionObjective,
  } = props;

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleConfirm = () => {
    if (typeof afterConfirm === "function") afterConfirm(form);
  };

  const handleCancel = () => {
    if (typeof afterCancel === "function") afterCancel();
  };

  const handleUpdateForm = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const getFormErrors = () => {
    let errors = [];

    const indexOfObjectiveId = addedObjectives.findIndex(
      ({ okrConfigurationObjectiveId, themeId }) =>
        okrConfigurationObjectiveId === form.id && themeId === questionTheme.id,
    );

    if (indexOfObjectiveId > -1) {
      errors.push({
        slug: "repeated",
        message: intl.formatMessage({ id: "dialog_error_question_repeat" }),
      });
    }

    return errors;
  };

  const toggleRegisterDialog = (toggleTo = true) => {
    setRegisterDialog(toggleTo);
  };

  const buildQuestionOptions = (questions) => {
    let finalOptions = [];

    if (questions && questions.length > 0) {
      questions.forEach(({ id, title }) =>
        finalOptions.push({ value: id, label: title }),
      );
    }

    return finalOptions;
  };

  async function createOrUpdateObjective(newObjective) {
    await postObjective(
      okrConfigurationId,
      newObjective,
      handleSaveQuestionObjective,
    );
  }

  const classes = useStyles();
  const formErrors = getFormErrors();

  return (
    <>
      <ObjectForm
        open={registerDialog}
        onCancel={() => toggleRegisterDialog(false)}
        onConfirm={createOrUpdateObjective}
        isEditing={{ params: {} }}
        objectives={okrObjectives}
      />
      <FormDialog
        open={open}
        title={intl.formatMessage(messages.dialog_title_new_theme)}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        blockConfirm={formErrors.length > 0}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialSingleSelect
              id="Scenerie"
              label={intl.formatMessage(messages.dialog_title_new_theme)}
              value={form.id}
              options={buildQuestionOptions(okrObjectives)}
              onChange={(e) => handleUpdateForm("id", e.target.value)}
            />
            {getErrorMessage([`repeated`], formErrors, "left", {
              height: "30px",
            })}
            <Button
              variant="outlined"
              className={classes.addButton}
              onClick={() => toggleRegisterDialog(!registerDialog.open)}
            >
              <AddIcon /> {intl.formatMessage(messages.btn_modal_register_new)}
            </Button>
          </div>
        </div>
      </FormDialog>
    </>
  );
};

const mapDispatchToProps = {
  postObjective,
};

export default connect(
  null,
  mapDispatchToProps,
)(injectIntl(QuestionProcessSelectDialog));
