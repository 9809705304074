const en = {
  mm_main_title: "Meeting Management",
};

const es = {
  mm_main_title: "Gestión de reuniones",
};

const pt = {
  mm_main_title: "Gestão de Reuniões",
};

const translations = { en, es, pt };

export default translations;
