import axios from "axios";
import {
  CLOSE_ACCOUNT,
  FETCH_USER,
  FETCH_USER_PROFILE,
  GET_USER_AVATAR,
  UPDATE_USER_PROFILE,
  UPLOAD_USER_AVATAR,
} from "./types";

export const fetchUserProfile = (userLogin) => {
  if (userLogin) {
    return async (dispatch) => {
      await axios
        .get(`/api/users/${encodeURIComponent(userLogin)}`)
        .then((res) => {
          const profile = res?.data;
          if (profile) dispatch({ type: FETCH_USER_PROFILE, payload: profile });
        });
    };
  }
};

export const concatInfos = (storageInfo = [], blobInfo = []) => {
  let updatedStorageInfo = [...storageInfo];

  storageInfo.forEach((info, index) => {
    const { avatarBlobId: avBlobId = null, userId: id = false } = info || {};

    if (id) return;

    const { userId = "" } =
      blobInfo.filter(
        ({ avatarBlobId = "" }) => avatarBlobId === avBlobId,
      )[0] || {};

    updatedStorageInfo[index] = { ...updatedStorageInfo[index], userId };
  });

  return updatedStorageInfo;
};

export const getAllUserAvatars = async (blobInfo = []) => {
  let storageAvatars = JSON.parse(localStorage.getItem("allAvatars")) || [];
  const storageAvatarIds = storageAvatars.map((avatar) => avatar.avatarBlobId);

  const arrayBlobIds = blobInfo.map((blob) => blob.avatarBlobId);

  const blobIds = arrayBlobIds.filter(
    (blobId) => !storageAvatarIds.includes(blobId) && blobId && blobId !== "",
  );

  if (blobIds.length) {
    const response = await axios.post("/api/s3/get-avatars", blobIds);

    const newResponse = concatInfos(
      [...storageAvatars, ...response.data],
      blobInfo,
    );

    if (newResponse.length < 50) {
      localStorage.setItem("allAvatars", JSON.stringify(newResponse));
    } else {
      localStorage.setItem("allAvatars", JSON.stringify(response.data));
    }

    return newResponse;
  } else {
    return storageAvatars;
  }
};

export const updateUserProfile = (profile) => {
  return (dispatch) => {
    return axios.post("/api/account/", profile).then((res) => {
      dispatch({ type: UPDATE_USER_PROFILE, payload: res.data });
      dispatch({ type: FETCH_USER, payload: res.data });
    });
  };
};

export const closeAccount = (id, history) => {
  return (dispatch) => {
    return axios.post("/api/account/close/" + id).then((res) => {
      dispatch({ type: CLOSE_ACCOUNT, payload: res.data });
      history.push("/login");
    });
  };
};

export const uploadAvatar = (file, s3bucket = "PROFILE_IMAGES") => {
  return (dispatch) => {
    return axios
      .post(`/api/s3/upload-file?s3bucket=${s3bucket}`, file)
      .then((res) => {
        dispatch({ type: UPLOAD_USER_AVATAR, payload: res.data });
      })
      .catch((err) => console.log("erro", err));
  };
};

export const getAvatar = (fileName, s3bucket = "PROFILE_IMAGES") => {
  return (dispatch) => {
    if (fileName) {
      const currentAvatars = localStorage.getItem("avatars");
      let storedAvatars = JSON.parse(currentAvatars);
      storedAvatars =
        storedAvatars && storedAvatars.length ? storedAvatars : [];

      let fetchNewAvatar = true;
      if (storedAvatars.length > 0) {
        storedAvatars.forEach((storedAvatar) => {
          if (storedAvatar.blobID === fileName) fetchNewAvatar = false;
        });
      }
      if (fetchNewAvatar) {
        return axios
          .get(`/api/s3/get-file?fileName=${fileName}&s3bucket=${s3bucket}`)
          .then((res) => {
            const payloadBody = { base64: res.data, blobID: fileName };

            let finalAvatars = [...storedAvatars, payloadBody];

            localStorage.setItem("avatars", JSON.stringify(finalAvatars));

            dispatch({
              type: GET_USER_AVATAR,
              payload: payloadBody,
            });
          });
      }
    }
  };
};

export const updatePhoneUser = (userId, newPhoneNumber) => {
  return axios
    .post(`/api/users/${userId}/changePhone/${newPhoneNumber}`)
    .then((res) => {
      return res.data;
    });
};

export const updateCpfUser = (userId, newCpf) => {
  return axios.post(`/api/users/${userId}/changeCpf/${newCpf}`).then((res) => {
    return res.data;
  });
};
