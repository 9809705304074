import React from "react";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import DeleteColumn from "../../../../../../Common/Tables/DeleteColumn";
import {
  getEmptyTableRow,
  getDefaultAddTableLine,
} from "../../../../../../../utils/tableUtils";
import { translatedText } from "../../../../../../../utils/translationUtils";
import {
  orderScale,
  simpleEditArrayOnAdministration,
} from "../../../../../../../utils/skillsAssessment/manipulation";
import { getComplementaryScalesByType } from "../../../../../../../utils/skillsAssessment/filter";

const messages = defineMessages({
  global_noneRegistered: { id: "global.noneRegistered" },
});

const translation = (id) => translatedText(id, messages);

const TableComplementaryScale = ({
  toolAdministration = {},
  updateAdministration,
  filterType,
  handleAdd,
  openDeleteScale,
}) => {
  const { complementaryScales = [] } = toolAdministration;
  const filteredScales = getComplementaryScalesByType(
    complementaryScales,
    filterType,
  );

  const handleEditScale = (scaleInfo, editingField = "value", newValue) => {
    if (scaleInfo.id) {
      const updatedScale = {
        ...scaleInfo,
        [editingField]:
          editingField === "value" ? parseFloat(newValue) : newValue,
      };

      const newAdministration = simpleEditArrayOnAdministration(
        toolAdministration,
        "complementaryScales",
        scaleInfo.id,
        updatedScale,
        "id",
      );

      updateAdministration(newAdministration);
    }
  };

  const orderedScale = orderScale(filteredScales);

  return (
    <table className="simple-table">
      <thead>{getDefaultAddTableLine(handleAdd, {}, "ESCALA")}</thead>
      <tbody>
        {orderedScale?.map((scaleInfo, index) => {
          return (
            <tr key={scaleInfo.id}>
              <SimpleExcelCollumn
                id={`evaluationValue${index}`}
                label={`evaluationValue${index}`}
                type="number"
                value={scaleInfo.value}
                tdStyle={{ width: "50px" }}
                align="center"
                onChange={(e) =>
                  handleEditScale(scaleInfo, "value", e.target.value)
                }
              />
              <SimpleExcelCollumn
                id={`evaluationTitle${index}`}
                label={scaleInfo.title}
                type="text"
                value={scaleInfo.title}
                onChange={(e) =>
                  handleEditScale(scaleInfo, "title", e.target.value)
                }
              />
              <DeleteColumn
                handleDelete={() => openDeleteScale(scaleInfo.id)}
              />
            </tr>
          );
        })}
        {getEmptyTableRow(
          complementaryScales,
          translation("global_noneRegistered"),
        )}
      </tbody>
    </table>
  );
};

TableComplementaryScale.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
  updateAdministration: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
  openDeleteScale: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
};

export default TableComplementaryScale;
