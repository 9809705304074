import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import InsightSaveButton from "../../../Common/InsightSaveButton";
import GoalDescription from "../../../Common/GoalsAndBonus/insight/GoalDescription";
import GoalEvaluation from "../../../Common/GoalsAndBonus/insight/GoalEvaluation";
import {
  getGoalBonusConfiguration,
  updateGoalBonusAdministration,
} from "../../../../actions/goalsAndBonusActions";
import {
  getGoalsBonusStates,
  getSelectedToolStates,
} from "../../../customMapStates";
import gbUtils from "../../../../utils/goalsBonus";
import InsightActionPlan from "../../../Common/ActionPlan/InsightActionPlan";
import {
  buildGoalsAdministrationFinalBody,
  buildRestAdmConfigBody,
} from "../../../../utils/goalsBonus/entity";
import {
  useSelectedGoal,
  useSimpleToolAdministration,
} from "../../../../utils/goalsBonus/hooks";

const { getGoalInfoById, getNewGoalErrors, getGoalsBonusAccessLevels } =
  gbUtils;

const InsightCustomField = ({
  allGoalBonusAdministration = [],
  selectedTool = {},
  insight = {},
  isLoading,
  allSceneries,
  allThemes,
  allQuestions,
  allAnswers,
  updateGoalBonusAdministration,
}) => {
  const { goalsBonusProgramGoalId = null } = insight;

  const { goalBonusAdministration } = useSimpleToolAdministration({
    selectedTool,
    allGoalBonusAdministration,
    isLoading,
  });

  const { goals: originalGoals = [], goalTargets: goalTargetConfigurations } =
    goalBonusAdministration;

  const { goalDescriptions, setGoalDescriptions, changes, setController } =
    useSelectedGoal({
      goals: originalGoals,
      goalsBonusProgramGoalId,
      isLoading,
    });

  const getToolConfig = async () => {
    if (selectedTool && selectedTool.id) {
      const { data } = await getGoalBonusConfiguration(selectedTool.id);

      if (data && data.id) {
        const fetchedConfiguration = await buildGoalsAdministrationFinalBody(
          data,
        );

        return fetchedConfiguration;
      }
    }

    return {};
  };

  const handleSubmitAdminConfig = async () => {
    const data = await getToolConfig();

    if (data && data.id) {
      const editingGoalIndex = getGoalInfoById(
        goalDescriptions.id,
        data.goals,
        true,
      );

      if (editingGoalIndex >= 0) {
        const newUpdatedGoals = [...data.goals];
        newUpdatedGoals[editingGoalIndex] = { ...goalDescriptions };

        if (selectedTool && selectedTool.id) {
          const restTrasformedBody = buildRestAdmConfigBody({
            ...data,
            goals: newUpdatedGoals,
          });

          updateGoalBonusAdministration(selectedTool.id, restTrasformedBody);
          setController((controller) => {
            return { ...controller, firstMount: false };
          });
        }
      }
    }
  };

  const formErrors = getNewGoalErrors(goalDescriptions, true);

  const accessLevels = getGoalsBonusAccessLevels(
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
  );

  const { userAccess = {}, participantInsights = [] } = accessLevels;

  const accesses = Array.isArray(userAccess.accesses)
    ? userAccess.accesses
    : [];

  const { registrationAccess = false } = accessLevels;

  const haveInsightAccess = participantInsights.indexOf(insight.id) > -1;
  const blockAllChanges =
    (!registrationAccess && !haveInsightAccess) || accesses.length === 0;

  const haveLinkedIndicator = goalDescriptions?.linkedIndicator?.id > 0;

  const appendProps = {
    insight,
    goalDescriptions,
    setGoalDescriptions,
    goalTargetConfigurations,
    SaveButton: (
      <InsightSaveButton
        show={changes.length > 0 && !isLoading && formErrors.length === 0}
        onSave={handleSubmitAdminConfig}
        loading={isLoading}
      />
    ),
    formErrors,
    blockAllChanges,
    haveLinkedIndicator,
    zeroWhenNegative: goalBonusAdministration.zeroWhenNegative,
    selectedToolId: selectedTool.id,
  };

  return (
    <div className="row">
      <div className="row">
        <GoalDescription
          {...appendProps}
          allAnswers={allAnswers}
          showLinkedMessage={haveLinkedIndicator}
        />
      </div>
      <div className="row">
        <GoalEvaluation {...appendProps} />
      </div>
      <div className="row">
        <InsightActionPlan
          insight={insight}
          selectedTool={selectedTool}
          dragabble
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { allGoalBonusAdministration, isLoading } = getGoalsBonusStates(state);
  const {
    selectedTool,
    allSceneries = [],
    allThemes = {},
    allQuestions = {},
    allAnswers = {},
  } = getSelectedToolStates(state);

  return {
    allGoalBonusAdministration,
    selectedTool,
    isLoading,
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
  };
};

const areEqual = (prev, next) => {
  if (prev.isLoading !== next.isLoading) return false;
  if (
    !_.isEqual(prev.allGoalBonusAdministration, next.allGoalBonusAdministration)
  )
    return false;
  if (!_.isEqual(prev.allAnswers, next.allAnswers)) return false;
  if (!_.isEqual(prev.insight, next.insight)) return false;
  return true;
};

const GoalsAndBonus = connect(mapStateToProps, {
  updateGoalBonusAdministration,
})(React.memo(InsightCustomField, areEqual));

export { GoalsAndBonus };
