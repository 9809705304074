import React from "react";
import {
  getObjectInfoById,
  getFilteredObjectsList,
  getOnlySlugValues,
  getAllExcept,
  orderArrayByObjAttr,
  getOnlySublevelArrays,
} from "../ArrayUtils";
import { getComparableQuery } from "../StringUtils";
import {
  enabledFieldsOnWorkflow,
  getEvaluationConfigByType,
  getEvaluatorActionsOnWorkflowStage,
  getEvaluationStagesOptions,
  workflowCommentTags,
  getCompetenceConfigByComposition,
  getSuccessionEvaluationInfo,
  getComplementaryEvaluationTitleByType,
  complementaryWorkflowCommentTags,
} from "./constants";
import { checkAccess } from "../accessLevels";
import { orderScale } from "./manipulation";
import SimpleBreadcrumb from "../../components/Common/SimpleBreadcrumb";
import { SKILLS_ASSESSMENT } from "../../constants/tools.constants";
import { defaultSubtypesOrder, defaultAnonymousUser } from "./defaults";
import { getComplementaryWorkflowDisplayValue } from "./calc";
import { getComplementaryTopicByTypes } from "./display";
import store from "../../Store";
import { getSkillAssessmentStates } from "../../components/customMapStates";
import { getInsightComments } from "../../actions/insightTimelineActions";

export const findOnArray = (itemId, array, compareBy = "id") => {
  if (itemId && array) {
    const index = getObjectInfoById(itemId, array, compareBy, true);

    return { index, info: array[index] };
  }

  return { index: -1, info: null };
};

export const buildProfileOptions = (profiles = []) => {
  const options = [];

  if (Array.isArray(profiles)) {
    profiles.forEach(({ id, title }) => {
      options.push({ value: id, label: title });
    });
  }

  return options;
};

export const buildCompetenceAreaOptions = (competenceAreas = []) => {
  const options = [];

  if (Array.isArray(competenceAreas)) {
    competenceAreas.forEach(({ id, title }) => {
      options.push({ value: id, label: title });
    });
  }

  return options;
};

export const buildSkillsOptions = (skills = []) => {
  const options = [];

  if (Array.isArray(skills)) {
    skills.forEach(({ id, title }) => {
      options.push({ value: id, label: title });
    });
  }

  return options;
};

export const buildScaleOptions = (scale) => {
  const options = [];

  if (Array.isArray(scale)) {
    const ordered = orderScale(scale);

    ordered.forEach(({ value, title }) => {
      options.push({ value, label: `${value}: ${title}` });
    });
  }

  return options;
};

export const buildQualificationScaleOptions = (
  evaluationScale,
  qualificationScale
) => {
  const options = [];

  if (Array.isArray(evaluationScale)) {
    const ordered = orderScale(evaluationScale);

    ordered.forEach(({ value, title }) => {
      const qualificationInfo = getObjectInfoById(
        value,
        qualificationScale,
        "value"
      );

      const displayTitle =
        qualificationInfo?.title || `- Sem texto - (${title})`;

      options.push({ value, label: `${value}: ${displayTitle}` });
    });
  }

  return options;
};

export const buildWorkflowScaleOptions = (toolAdministration, scaleSlug) => {
  if (toolAdministration) {
    const { evaluationScale, qualificationScale, potentialScale } =
      toolAdministration;

    if (scaleSlug === "evaluationScale")
      return buildScaleOptions(evaluationScale);

    if (scaleSlug === "qualificationScale")
      return buildQualificationScaleOptions(
        evaluationScale,
        qualificationScale
      );

    if (scaleSlug === "potentialScale")
      return buildScaleOptions(potentialScale);
  }

  return [];
};

export const buildComplementaryScales = (
  toolAdministration,
  filterType = "KEY_SEAT"
) => {
  if (toolAdministration && filterType) {
    const { complementaryScales = [] } = toolAdministration;

    const filtered = complementaryScales.filter(
      ({ type }) => type === filterType
    );
    return buildScaleOptions(filtered);
  }

  return [];
};

export const mountDatesGroup = (dates = {}) => {
  const {
    startValidation,
    endValidation,
    startSelfEvaluation,
    endSelfEvaluation,
    startEvaluators,
    endEvaluators,
    startClosure,
    endClosure,
    startPdi,
    endPdi,
    startFeedback,
    endFeedback,
  } = dates;

  return [
    {
      slug: "validation",
      label: "Validação",
      start: startValidation,
      end: endValidation,
    },
    {
      slug: "evaluation",
      label: "Autoavaliação",
      start: startSelfEvaluation,
      end: endSelfEvaluation,
    },
    {
      slug: "evaluators",
      label: "Avaliadores",
      start: startEvaluators,
      end: endEvaluators,
    },
    {
      slug: "closure",
      label: "Fechamento",
      start: startClosure,
      end: endClosure,
    },
    { slug: "pdi", label: "PDI", start: startPdi, end: endPdi },
    {
      slug: "feedback",
      label: "Feedback",
      start: startFeedback,
      end: endFeedback,
    },
  ];
};

export const filterParticipantsByStages = (participants, multipleStages) => {
  if (Array.isArray(participants) && multipleStages) {
    return getFilteredObjectsList(
      multipleStages,
      participants,
      "evaluationStage"
    );
  }

  return [];
};

export const getCompetenceAreasByProfile = (profile, competenceAreas) => {
  const filtered = [];

  if (profile && profile.id && Array.isArray(competenceAreas)) {
    competenceAreas.forEach((competenceAreaInfo) => {
      const { profiles } = competenceAreaInfo;
      const profileIds = getOnlySlugValues(profiles, "id");

      if (Array.isArray(profileIds) && profileIds.indexOf(profile.id) > -1)
        filtered.push(competenceAreaInfo);
    });
  }

  return filtered;
};

export const getSkillsByCompetenceAreas = (competenceAreas, skills) => {
  const filtered = [];

  if (Array.isArray(competenceAreas) && Array.isArray(skills)) {
    const competenceAreasIds = getOnlySlugValues(competenceAreas, "id");

    skills.forEach((skillInfo) => {
      const competenceAreaId =
        skillInfo.competenceArea && skillInfo.competenceArea.id;

      if (competenceAreaId && competenceAreasIds.indexOf(competenceAreaId) > -1)
        filtered.push(skillInfo);
    });
  }

  return filtered;
};

export const getProfileSkills = (profile, competenceAreas, skills) => {
  const profileCompetenceAreas = getCompetenceAreasByProfile(
    profile,
    competenceAreas
  );

  return getSkillsByCompetenceAreas(profileCompetenceAreas, skills);
};

export const getWorkflowBySkills = (workflow, skills) => {
  const filtered = [];

  if (Array.isArray(workflow) && Array.isArray(skills)) {
    const skillIds = getOnlySlugValues(skills, "id");

    workflow.forEach((workflowInfo) => {
      const workflowSKillId = workflowInfo.skill && workflowInfo.skill.id;

      if (workflowSKillId && skillIds && skillIds.indexOf(workflowSKillId) > -1)
        filtered.push(workflowInfo);
    });
  }

  return filtered;
};

export const getParticipantWorkflow = (toolAdministration, participant) => {
  const {
    skills = [],
    workflow = [],
    competenceAreas = [],
  } = toolAdministration;

  if (competenceAreas && skills && workflow && participant && participant.id) {
    const { profile } = participant;
    const participantSkills = getProfileSkills(
      profile,
      competenceAreas,
      skills
    );

    const participantOriginalWorkflow = getFilteredObjectsList(
      [participant.id],
      workflow,
      "participantId"
    );

    return getWorkflowBySkills(participantOriginalWorkflow, participantSkills);
  }

  return [];
};

export const getParticipantComplementaryWorkflow = (
  complementaryWorkflow,
  participant
) => {
  if (complementaryWorkflow && participant?.id) {
    return getFilteredObjectsList(
      [participant.id],
      complementaryWorkflow,
      "participantId"
    );
  }

  return [];
};

export const getParticipantComplementaryEvaluations = (
  complementaryWorkflow,
  participant
) => {
  const workflow = getParticipantComplementaryWorkflow(
    complementaryWorkflow,
    participant
  );

  return getOnlySlugValues(workflow, "complementaryEvaluation");
};

export const checkParticipantSkill = (
  participantInfo,
  skillInfo,
  competenceAreas
) => {
  if (participantInfo && competenceAreas && skillInfo) {
    const profileSkills = getProfileSkills(
      participantInfo.profile,
      competenceAreas,
      [skillInfo]
    );

    if (profileSkills.length > 0) return true;
  }

  return false;
};

export const checkProfileCompetenceArea = (profile, competenceArea) => {
  if (profile && competenceArea && competenceArea.profiles) {
    const competenceAreaProfiles = getOnlySlugValues(
      competenceArea.profiles,
      "id"
    );

    return competenceAreaProfiles.indexOf(profile.id) > -1;
  }

  return false;
};

export const groupWorkflowBySkills = (
  skills,
  competenceAreas,
  participants,
  workflow
) => {
  const grouped = [];

  if (Array.isArray(skills) && participants) {
    skills.forEach((skill) => {
      const skillWorkflow = getWorkflowBySkills(workflow, [skill]);
      const filteredWorkflow = [];

      skillWorkflow.forEach((workflowInfo) => {
        const { participantId } = workflowInfo;
        const participant = getObjectInfoById(participantId, participants);

        if (checkParticipantSkill(participant, skill, competenceAreas)) {
          filteredWorkflow.push(workflowInfo);
        }
      });

      grouped.push({ skill, title: skill.title, workflow: filteredWorkflow });
    });
  }

  return grouped;
};

export const groupWorkflowByCompetenceAreas = (
  skills,
  competenceAreas,
  participants,
  workflow
) => {
  const grouped = [];

  if (Array.isArray(competenceAreas) && participants) {
    competenceAreas.forEach((competenceAreaInfo) => {
      const competenceAreaSkills = getSkillsByCompetenceAreas(
        [competenceAreaInfo],
        skills
      );
      const skillWorkflow = getWorkflowBySkills(workflow, competenceAreaSkills);
      const filteredWorkflow = [];

      skillWorkflow.forEach((workflowInfo) => {
        const { participantId } = workflowInfo;
        const participant = getObjectInfoById(participantId, participants);

        if (
          checkProfileCompetenceArea(participant.profile, competenceAreaInfo)
        ) {
          filteredWorkflow.push(workflowInfo);
        }
      });

      grouped.push({
        competenceArea: competenceAreaInfo,
        title: competenceAreaInfo.title,
        workflow: filteredWorkflow,
      });
    });
  }

  return grouped;
};

export const groupWorkflowByProfiles = (
  skills,
  profiles,
  competenceAreas,
  participants,
  workflow
) => {
  const grouped = [];

  if (Array.isArray(competenceAreas) && participants) {
    profiles.forEach((profileInfo) => {
      const profileSkills = getProfileSkills(
        profileInfo,
        competenceAreas,
        skills
      );
      const skillWorkflow = getWorkflowBySkills(workflow, profileSkills);
      const filteredWorkflow = [];

      skillWorkflow.forEach((workflowInfo) => {
        const { participantId } = workflowInfo;
        const participant = getObjectInfoById(participantId, participants);
        const { profile: participantProfile = {} } = participant;

        if (participantProfile.id === profileInfo.id) {
          filteredWorkflow.push(workflowInfo);
        }
      });

      grouped.push({
        profile: profileInfo,
        title: profileInfo.title,
        workflow: filteredWorkflow,
      });
    });
  }

  return grouped;
};

export const groupWorkflowByParticipants = (
  toolAdministration,
  participants
) => {
  const grouped = [];

  if (Array.isArray(participants)) {
    participants.forEach((participantInfo) => {
      const participantWorkflow = getParticipantWorkflow(
        toolAdministration,
        participantInfo
      );

      const { memberParticipant = {} } = participantInfo;

      grouped.push({
        participant: participantInfo,
        participantId: participantInfo.id,
        title: memberParticipant.name,
        email: memberParticipant.email,
        avatar: memberParticipant.avatarBlobId,
        workflow: participantWorkflow,
      });
    });
  }

  return grouped;
};

export const checkIsAdmin = () => {
  return checkAccess(["MANAGER"]);
};

export const checkUserRoleForParticipant = (
  loggedUser = {},
  participantInfo = {}
) => {
  if (loggedUser && loggedUser.id) {
    const {
      memberParticipant = {},
      manager = {},
      secondEvaluator = {},
      thirdEvaluator = {},
    } = participantInfo;

    const sameUser = loggedUser.id === memberParticipant?.id;
    const isManager = loggedUser.id === manager?.id;
    const isSecEval = loggedUser.id === secondEvaluator?.id;
    const isThrEval = loggedUser.id === thirdEvaluator?.id;

    if (checkIsAdmin()) return "ADMIN";
    if (sameUser) return "PARTICIPANT";
    if (isManager) return "MANAGER";
    if (isSecEval) return "SECOND_EVALUATOR";
    if (isThrEval) return "THIRD_EVALUATOR";
  }

  return null;
};

export const getEnabledWorkflowByUser = (
  loggedUser,
  participantInfo,
  evaluationType,
  composition,
  evaluationStage,
  transparency
) => {
  const { enableSecondEvaluator, enableThirdEvaluator } =
    getEvaluationConfigByType(evaluationType);

  const roleRelation = checkUserRoleForParticipant(loggedUser, participantInfo);

  const { enablePotential } = getCompetenceConfigByComposition(composition);

  return enabledFieldsOnWorkflow(
    evaluationStage,
    roleRelation,
    enableSecondEvaluator,
    enableThirdEvaluator,
    enablePotential,
    transparency,
    loggedUser,
    participantInfo
  );
};

export const getUserRoleRelatedParticipant = (loggedUser, participant) => {
  if (participant?.id === loggedUser?.id) return participant;
  if (participant?.manager?.id === loggedUser?.id) return participant.manager;
  if (participant?.secondEvaluator?.id === loggedUser?.id)
    return participant.secondEvaluator;
  if (participant?.thirdEvaluator?.id === loggedUser?.id)
    return participant.thirdEvaluator;
  return null;
};

export const userRelatedParticipantsByRole = (
  loggedUser,
  participants,
  role
) => {
  return participants.filter(
    (participantInfo) => participantInfo[role].id === loggedUser.id
  );
};

export const getManagerPeopleToEvaluate = (loggedUser, participants) => {
  return userRelatedParticipantsByRole(loggedUser, participants, "manager");
};

export const getUserPeopleToEvaluate = (loggedUser, participants) => {
  return participants.filter(
    ({ manager = {} }) => manager.id === loggedUser.id
  );
};

export const getUserPeopleToEvaluateByStage = (
  loggedUser,
  participants,
  filterStage
) => {
  const filtered = [];

  if (Array.isArray(participants) && loggedUser) {
    participants.forEach((participantInfo) => {
      if (participantInfo.evaluationStage !== filterStage) return;
      const roleRelation = checkUserRoleForParticipant(
        loggedUser,
        participantInfo
      );

      if (!roleRelation) return;

      filtered.push(participantInfo);
    });
  }

  return filtered;
};

export const filterParticipantsByEvaluationStage = (
  participants,
  evaluationStage
) => {
  if (Array.isArray(participants) && evaluationStage) {
    return getFilteredObjectsList(
      [evaluationStage],
      participants,
      "evaluationStage"
    );
  }

  return [];
};

export const getWorkflowActionsByUser = (
  loggedUser,
  participantInfo,
  evaluationStage,
  managerConfig
) => {
  const roleRelation = checkUserRoleForParticipant(loggedUser, participantInfo);
  return getEvaluatorActionsOnWorkflowStage(
    evaluationStage,
    managerConfig,
    roleRelation,
    loggedUser,
    participantInfo
  );
};

export const getParticipantsRoles = (toolAdministration) => {
  const companyPositions = [];
  const companyPositionsWithSkills = [];
  const companyPositionsIds = [];

  const { participants, competenceAreas, skills } = toolAdministration;

  if (Array.isArray(participants)) {
    participants.forEach(({ companyPosition = {}, profile = {} }) => {
      const isValid = profile && profile?.id && companyPosition?.id;
      const index = companyPosition
        ? companyPositionsIds.indexOf(companyPosition.id)
        : -1;
      const isAdded = index > -1;

      const participantSkills = getProfileSkills(
        profile,
        competenceAreas,
        skills
      );

      if (isValid && !isAdded) {
        companyPositions.push(companyPosition);
        companyPositionsWithSkills.push({
          companyPosition,
          skills: participantSkills,
        });
        companyPositionsIds.push(companyPosition.id);
      } else if (index > -1) {
        companyPositionsWithSkills[index].skills = [
          ...companyPositionsWithSkills[index].skills,
          ...participantSkills,
        ];
      }
    });
  }

  return { companyPositions, companyPositionsWithSkills, companyPositionsIds };
};

export const getParticipantsCompanyAreas = (participants) => {
  const companyAreas = [];
  const companyAreasIds = [];

  if (Array.isArray(participants)) {
    participants.forEach(({ companyArea = {} }) => {
      if (companyAreasIds.indexOf(companyArea?.id) === -1) {
        companyAreas.push(companyArea);
        companyAreasIds.push(companyArea?.id);
      }
    });
  }

  return { companyAreas, companyAreasIds };
};

export const getAreaOptions = (companyAreas) => {
  const options = [];

  if (Array.isArray(companyAreas)) {
    companyAreas.forEach((areaInfo) => {
      options.push({ value: areaInfo?.id, text: areaInfo?.title });
    });
  }

  return options;
};

export const getCompanyRoleSkills = (companyPositionId, toolAdministration) => {
  const filtered = [];
  const filtered_id = [];

  const { participants, competenceAreas, skills } = toolAdministration;
  if (Array.isArray(participants)) {
    participants.forEach(({ companyPosition = {}, profile = {} }) => {
      if (companyPositionId === companyPosition.id) {
        const profileSkills = getProfileSkills(
          profile,
          competenceAreas,
          skills
        );

        profileSkills.forEach((skill) => {
          if (filtered_id.indexOf(skill.id) === -1) {
            filtered.push(skill);
            filtered_id.push(skill.id);
          }
        });
      }
    });
  }

  return { filtered, filtered_id };
};

export const getOnlyLinkedRoleSkillRelation = (toolAdministration) => {
  const filtered = [];

  const { roleSkillRelation } = toolAdministration;

  if (Array.isArray(roleSkillRelation)) {
    roleSkillRelation.forEach((relationInfo) => {
      const { companyRoleId, skillId } = relationInfo;

      const { filtered_id } = getCompanyRoleSkills(
        companyRoleId,
        toolAdministration
      );

      if (filtered_id.indexOf(skillId) > -1) filtered.push(relationInfo);
    });
  }

  return filtered;
};

export const getFilteredSkillRoleRelation = (
  skillRoleRelation,
  skillFilterId = null
) => {
  const order = (list) => {
    return orderArrayByObjAttr(list, "companyRole", "title", false);
  };

  if (Array.isArray(skillRoleRelation) && skillFilterId) {
    const filtered = [];

    skillRoleRelation.forEach((relationInfo) => {
      const { skillId } = relationInfo;

      if (skillId === skillFilterId) filtered.push(relationInfo);
    });

    return order(filtered);
  }

  return Array.isArray(skillRoleRelation) ? order(skillRoleRelation) : [];
};

export const getFilterLinkedRoleSkillRelation = (
  toolAdministration,
  skillFilterId
) => {
  const linkedRelation = getOnlyLinkedRoleSkillRelation(toolAdministration);
  const filteredRelation = getFilteredSkillRoleRelation(
    linkedRelation,
    skillFilterId
  );

  return filteredRelation;
};

export const checkHaveRoleSkillRelation = (
  companyRoleId,
  skillId,
  roleSkillRelation
) => {
  let found = false;

  roleSkillRelation.forEach(({ companyRole = {}, skill = {} }) => {
    if (
      companyRole.id &&
      companyRole.id === companyRoleId &&
      skill.id &&
      skill.id === skillId
    )
      found = true;
  });

  return found;
};

export const buildEvaluationStageGrouping = (evaluationConfig) => {
  const grouping = {};
  const values = [];

  const evaluationStageOptions = getEvaluationStagesOptions(
    "TYPE_360",
    evaluationConfig
  );

  evaluationStageOptions.forEach(({ value }) => {
    grouping[value] = [];
    values.push(value);
  });

  return { grouping, values };
};

export const groupParticipantsByEvaluationStage = (
  participants,
  evaluationConfig
) => {
  const { grouping: grouped, values } =
    buildEvaluationStageGrouping(evaluationConfig);

  const total = [];

  if (Array.isArray(participants)) {
    participants.forEach((participantInfo) => {
      const { evaluationStage } = participantInfo;

      if (values.indexOf(evaluationStage) > -1) {
        grouped[evaluationStage].push(participantInfo);
        total.push(participantInfo);
      }
    });
  }

  return { grouped, total };
};

export const getSimpleTree = (
  array,
  titleSlug = "title",
  mainTitle,
  icon,
  countText
) => {
  const list = [];

  if (Array.isArray(array)) {
    array.forEach((itemInfo) => {
      list.push({
        id: itemInfo.id,
        title: itemInfo[titleSlug],
        parentId: null,
      });
    });
  }

  return {
    treeList: {
      simple: {
        label: mainTitle,
        color: "#333",
        icon,
        countText,
        list,
      },
    },
    treeOrder: ["simple"],
    all: list,
  };
};

export const getSimpleProfilesTree = (profiles) => {
  return getSimpleTree(
    profiles,
    "title",
    "Perfis da Avaliação",
    <i className="fas fa-id-badge" />,
    "perfis"
  );
};

export const getSimpleParticipantsTree = (participants) => {
  const namedParticipants = [];

  if (Array.isArray(participants)) {
    participants.forEach(({ id, memberParticipant }) => {
      namedParticipants.push({ id, name: memberParticipant.name });
    });
  }

  return getSimpleTree(
    namedParticipants,
    "name",
    "Participantes da Avaliação",
    <i className="fas fa-user" />,
    "perfis"
  );
};

export const getScaleSplitedItems = (scale) => {
  const values = [];
  const titles = [];
  const queryTitles = [];

  scale.forEach(({ value, title }) => {
    values.push(Number(value));
    titles.push(title);
    queryTitles.push(getComparableQuery(title));
  });

  return { values, titles, queryTitles };
};

export const getProfileSplitedItems = (profiles) => {
  const ids = [];
  const titles = [];
  const queryTitles = [];

  profiles.forEach(({ id, title }) => {
    ids.push(id);
    titles.push(title);
    queryTitles.push(getComparableQuery(title));
  });

  return { ids, titles, queryTitles };
};

export const getCompetenceAreaSplitedItems = (competenceAreas) => {
  const ids = [];
  const titles = [];
  const queryTitles = [];

  competenceAreas.forEach(({ id, title }) => {
    ids.push(id);
    titles.push(title);
    queryTitles.push(getComparableQuery(title));
  });

  return { ids, titles, queryTitles };
};

export const getSkillSplitedItems = (skills) => {
  const ids = [];
  const titles = [];
  const queryTitles = [];

  skills.forEach(({ id, title }) => {
    ids.push(id);
    titles.push(title);
    queryTitles.push(getComparableQuery(title));
  });

  return { ids, titles, queryTitles };
};

export const getScaleRelativeValues = (scale = []) => {
  if (Array.isArray(scale) && scale.length > 0) {
    const lastValue = parseFloat(scale[scale.length - 1].value) || 0;

    return {
      lastValue,
      nextValue: lastValue > -1 ? lastValue + 1 : scale.length,
    };
  }

  return { lastValue: 0, nextValue: 1 };
};

export const getParticipantsBySelectModal = (
  selected = [],
  participants = []
) => {
  const selectedParticipants = [];

  selected.forEach(({ id, title }) => {
    const participantInfo = getObjectInfoById(id, participants, "id");

    selectedParticipants.push({ title, ...participantInfo });
  });

  return selectedParticipants;
};

export const getParticipantsByInsights = (insights = [], participants = []) => {
  const filtered = [];
  const filteredIds = [];

  if (
    Array.isArray(insights) &&
    insights.length > 0 &&
    participants.length > 0
  ) {
    insights.forEach((insightInfo = {}) => {
      const participantId = insightInfo.skillsAssessmentParticipantId;

      if (participantId && filteredIds.indexOf(participantId) === -1) {
        const participantInfo = getObjectInfoById(
          participantId,
          participants,
          "id"
        );

        if (participantInfo && participantInfo.id) {
          filtered.push({ ...participantInfo, insight: insightInfo });
          filteredIds.push(participantInfo.id);
        }
      }
    });
  }

  return filtered;
};

export const getParticipantsByInsightAccess = (
  insights = [],
  participants = [],
  loggedUser = {}
) => {
  const final = [];

  const filtered = getParticipantsByInsights(insights, participants);

  filtered.forEach((participantInfo) => {
    const haveRelatedRole = checkUserRoleForParticipant(
      loggedUser,
      participantInfo
    );

    if (haveRelatedRole) final.push(participantInfo);
  });

  return final;
};

export const getParticipantsByQuestions = (allQuestions, participants) => {
  const filtered = [];

  if (Array.isArray(allQuestions)) {
    allQuestions.forEach(({ skillsAssessmentProfileId }) => {
      participants.forEach((participantInfo) => {
        if (
          participantInfo.profile &&
          participantInfo.profile.id === skillsAssessmentProfileId
        ) {
          filtered.push(participantInfo);
        }
      });
    });
  }

  return filtered;
};

export const getQuestionsByProfile = (profileId, allQuestions) => {
  const linked = [];

  const onlyQuestions = getOnlySublevelArrays(allQuestions);

  if (Array.isArray(onlyQuestions))
    onlyQuestions.forEach((questionInfo) => {
      const { skillsAssessmentProfileId, text, theme } = questionInfo;
      const { cenary } = theme;

      if (skillsAssessmentProfileId === profileId)
        linked.push({
          ...questionInfo,
          displayPath: (
            <SimpleBreadcrumb
              items={[
                cenary.name,
                theme.name,
                <b key={questionInfo.id}>{text}</b>,
              ]}
            />
          ),
        });
    });

  return linked;
};

export const getInsightsByParticipant = (participantId, allInsights) => {
  const linked = [];

  const onlyInsights = getOnlySublevelArrays(allInsights);

  if (Array.isArray(onlyInsights))
    onlyInsights.forEach((insightInfo) => {
      const { skillsAssessmentParticipantId, name, question } = insightInfo;
      const { theme } = question;
      const { cenary } = theme;

      if (skillsAssessmentParticipantId === participantId)
        linked.push({
          ...insightInfo,
          displayPath: (
            <SimpleBreadcrumb
              items={[
                cenary.name,
                theme.name,
                question.text,
                <b key={insightInfo.id}>{name}</b>,
              ]}
            />
          ),
        });
    });

  return linked;
};

export const filterParticipantsToBoard = (
  insights = [],
  selectedParticipants = [],
  companyAreaId
) => {
  const added = getParticipantsByInsights(insights, selectedParticipants);
  const ids = getOnlySlugValues(added, "id");

  return {
    added,
    notAdded: getAllExcept(ids, selectedParticipants, "id").filter(
      ({ companyArea }) => {
        return companyArea && companyArea.id === companyAreaId;
      }
    ),
  };
};

export const getParticipantsFromStageGroup = (groupedStageArray) => {
  const filtered = [];

  if (Array.isArray(groupedStageArray))
    groupedStageArray.forEach((groupedStage) => {
      const stageParticipants = groupedStage?.participants || [];

      stageParticipants.forEach((participantInfo) => {
        filtered.push(participantInfo);
      });
    });

  return filtered;
};

export const getStageGroupStatusData = (
  grouped,
  filter = {
    selectionEvaluators: true,
    validation: true,
    selfEvaluation: true,
    managers: true,
    evaluators: true,
    allEvaluators: true,
    approval: true,
    concluded: true,
    complementaryStage: true,
  }
) => {
  const {
    SELECTION_EVALUATORS,
    VALIDATION,
    SELF_EVALUATION,
    MANAGERS,
    EVALUATORS,
    APPROVAL,
    CONCLUDED,
  } = grouped;

  const get = (slug, arry) =>
    filter[slug] ? getParticipantsFromStageGroup(arry) : [];

  return {
    ...grouped,
    SELECTION_EVALUATORS: {
      pending: get("selectionEvaluators", [SELECTION_EVALUATORS]),
      reached: get("selectionEvaluators", [
        VALIDATION,
        SELF_EVALUATION,
        MANAGERS,
        EVALUATORS,
        APPROVAL,
        CONCLUDED,
      ]),
    },
    VALIDATION: {
      pending: get("validation", [SELECTION_EVALUATORS, VALIDATION]),
      reached: get("validation", [
        SELF_EVALUATION,
        MANAGERS,
        EVALUATORS,
        APPROVAL,
        CONCLUDED,
      ]),
    },
    SELF_EVALUATION: {
      pending: get("selfEvaluation", [
        SELECTION_EVALUATORS,
        VALIDATION,
        SELF_EVALUATION,
      ]),
      reached: get("selfEvaluation", [
        MANAGERS,
        EVALUATORS,
        APPROVAL,
        CONCLUDED,
      ]),
    },
    MANAGERS: {
      pending: get("managers", [
        SELECTION_EVALUATORS,
        VALIDATION,
        SELF_EVALUATION,
        MANAGERS,
      ]),
      reached: get("managers", [EVALUATORS, APPROVAL, CONCLUDED]),
    },
    ALL_EVALUATORS: {
      pending: get("allEvaluators", [
        SELECTION_EVALUATORS,
        VALIDATION,
        SELF_EVALUATION,
        MANAGERS,
        EVALUATORS,
      ]),
      reached: get("allEvaluators", [APPROVAL, CONCLUDED]),
    },
    APPROVAL: {
      pending: get("approval", [
        SELECTION_EVALUATORS,
        VALIDATION,
        SELF_EVALUATION,
        MANAGERS,
        EVALUATORS,
      ]),
      reached: get("approval", [APPROVAL, CONCLUDED]),
    },
    CONCLUDED: {
      pending: get("concluded", [
        SELECTION_EVALUATORS,
        VALIDATION,
        SELF_EVALUATION,
        MANAGERS,
        EVALUATORS,
        APPROVAL,
      ]),
      reached: get("concluded", [CONCLUDED]),
    },
  };
};

export const getParticipantsByProfiles = (participants = [], profiles = []) => {
  const filtered = [];

  if (Array.isArray(participants) && Array.isArray(profiles)) {
    const profilesId = getOnlySlugValues(profiles, "id");

    participants.forEach((participantInfo) => {
      const { memberParticipant = {}, profile = {} } = participantInfo;

      if (profilesId.indexOf(profile.id) > -1)
        filtered.push({ ...participantInfo, name: memberParticipant.name });
    });
  }

  return filtered;
};

export const getParticipantsByArea = (participants = [], companyAreaId) => {
  const filtered = [];

  if (Array.isArray(participants) && companyAreaId) {
    participants.forEach((participantInfo) => {
      const { memberParticipant = {}, companyArea = {} } = participantInfo;

      if (companyArea?.id === companyAreaId)
        filtered.push({ ...participantInfo, name: memberParticipant.name });
    });
  }

  return filtered;
};

export const getParticipantsBySkill = (
  skillId,
  skills = [],
  participants = []
) => {
  let filtered = [];

  const skillInfo = getObjectInfoById(skillId, skills, "id");

  const { competenceArea = {} } = skillInfo;
  const { profiles = [] } = competenceArea || {};

  if (skillInfo && Array.isArray(profiles)) {
    const profileParticipants = getParticipantsByProfiles(
      participants,
      profiles
    );

    filtered = [...filtered, ...profileParticipants];
  }

  return filtered;
};

export const getGroupCommentsBySkillId = (insightComments, skillId) => {
  const commentsGroup = [];

  if (Array.isArray(insightComments) && skillId) {
    insightComments.forEach((userComments) => {
      const { comments } = userComments;

      const filtered = [];

      comments.forEach((commentInfo) => {
        const { classifications = [] } = commentInfo;
        const { tag } = workflowCommentTags({ id: skillId });

        if (classifications.indexOf(tag) > -1) {
          filtered.push(commentInfo);
        }
      });

      if (filtered.length > 0)
        commentsGroup.push({ ...userComments, comments: filtered });
    });
  }

  return commentsGroup;
};

export const getCommentsByComplementaryWorkflow = (
  insightComments,
  workflowId
) => {
  const commentsGroup = [];

  if (Array.isArray(insightComments) && workflowId) {
    insightComments.forEach((userComments) => {
      const { comments } = userComments;

      const filtered = [];

      comments.forEach((commentInfo) => {
        const { classifications = [] } = commentInfo;
        const { tag } = complementaryWorkflowCommentTags({
          id: workflowId,
        });

        if (classifications.indexOf(tag) > -1) {
          filtered.push(commentInfo);
        }
      });

      if (filtered.length > 0)
        commentsGroup.push({ ...userComments, comments: filtered });
    });
  }

  return commentsGroup;
};

export const checkComplementaryEvaluationComment = (tag) => {
  const { tag: checkTag, slice } = complementaryWorkflowCommentTags({
    id: "REPLACE",
  });
  if (checkTag.replace("_REPLACE", "") === tag.slice(0, slice)) return true;

  return false;
};

export const skillAssessmentCommentTag = (tag) => {
  const { tag: checkTag, slice } = workflowCommentTags({
    id: "REPLACE",
  });
  if (checkTag.replace("_REPLACE", "") === tag.slice(0, slice)) return true;

  return checkComplementaryEvaluationComment(tag);
};

export const filterSkillsAssessmentComments = ({
  toolAdministration,
  timelineComments,
  loggedUser,
}) => {
  const filtered = [];
  const { complementaryWorkflow, transparency } = toolAdministration;

  if (Array.isArray(timelineComments) && complementaryWorkflow) {
    timelineComments.forEach((grouped) => {
      const { comments, owner } = grouped;
      const notCommentOwner = owner?.id !== loggedUser?.id;
      const hideOwnerName = notCommentOwner && !transparency;

      let newGrouped = { ...grouped };
      if (hideOwnerName)
        newGrouped = {
          ...grouped,
          owner: { ...owner, ...defaultAnonymousUser },
        };

      if (Array.isArray(comments)) {
        const newComments = [];

        comments.forEach((comment) => {
          const { classifications } = comment;
          const tagFilter = classifications.filter((tag) =>
            checkComplementaryEvaluationComment(tag)
          );

          const cwTAG = tagFilter[0] || false;

          let replaceComment = { ...comment };

          if (hideOwnerName)
            replaceComment = {
              ...comment,
              owner: { ...owner, ...defaultAnonymousUser },
            };

          if (cwTAG) {
            const workflowId = Number(cwTAG.replace("WK_COMPLEMENTARY_", ""));
            const workflowInfo = getObjectInfoById(
              workflowId,
              complementaryWorkflow
            );
            const { participant } = workflowInfo;
            const userRole = checkUserRoleForParticipant(
              loggedUser,
              participant
            );
            if (userRole === "ADMIN" || userRole === "MANAGER")
              newComments.push(replaceComment);
            return;
          }

          newComments.push(replaceComment);
        });

        filtered.push({ ...newGrouped, comments: newComments });
      }
    });
  }

  return filtered;
};

export const filterSelectedSkillsAssessmentComments = (
  selectedToolId,
  timelineComments
) => {
  const state = store.getState();
  const { user: loggedUser } = state;

  const { allSkillAssessment } = getSkillAssessmentStates(state);
  const toolAdministration = getObjectInfoById(
    selectedToolId,
    allSkillAssessment,
    "selectedToolId"
  );

  return filterSkillsAssessmentComments({
    toolAdministration,
    timelineComments,
    loggedUser,
  });
};

export const getBoardProfilesByArea = (participants, companyAreaId) => {
  const profiles = [];
  const profilesId = [];

  const filtered = getParticipantsByArea(participants, companyAreaId);

  filtered.forEach((participant) => {
    const { profile = {} } = participant;

    if (profile.id && profilesId.indexOf(profile.id) === -1) {
      profiles.push(profile);
      profilesId.push(profile.id);
    }
  });

  return { profiles, profilesId };
};

export const getSkillsAssessmentTools = (selectedTools) => {
  const filtered = [];

  if (Array.isArray(selectedTools)) {
    selectedTools.forEach(({ selectedToolId, toolInfo }) => {
      const { id } = toolInfo;
      if (id === SKILLS_ASSESSMENT) filtered.push(selectedToolId);
    });
  }

  return filtered;
};

export const getComplementaryScalesByType = (
  complementaryScales,
  filterType
) => {
  if (Array.isArray(complementaryScales))
    return complementaryScales.filter(({ type }) => type === filterType);

  return [];
};

export const getComplementaryEvaluationsByType = (
  complementaryEvaluations,
  filterType
) => {
  if (Array.isArray(complementaryEvaluations))
    return complementaryEvaluations.filter(({ type }) => type === filterType);

  return [];
};

export const getComplementaryEvaluationsBySubtype = (
  complementaryEvaluations,
  filterSubtype
) => {
  if (Array.isArray(complementaryEvaluations))
    return complementaryEvaluations.filter(
      ({ subType }) => subType === filterSubtype
    );

  return [];
};

export const getFilteredWorkflowByType = (
  complementaryWorkflow,
  filterType
) => {
  return complementaryWorkflow.filter(({ complementaryEvaluation }) => {
    const { type } = complementaryEvaluation;
    return type === filterType;
  });
};

export const getWorkflowValueBySuccession = (workflowInfo) => {
  const { complementaryEvaluation } = workflowInfo;

  const { workflowValueSlug } = getSuccessionEvaluationInfo(
    complementaryEvaluation
  );
  if (workflowValueSlug) return workflowInfo[workflowValueSlug];

  return "";
};

export const getGroupedSubtypesWorkflow = (
  complementaryWorkflow,
  filterType
) => {
  const grouped = {};

  const filteredWorkflow = getFilteredWorkflowByType(
    complementaryWorkflow,
    filterType
  );

  filteredWorkflow.forEach((workflowInfo) => {
    const { complementaryEvaluation } = workflowInfo;
    const { subType } = complementaryEvaluation;

    const current = grouped[subType] || [];

    grouped[subType] = [...current, workflowInfo];
  });

  return grouped;
};

export const getReportDisplayComments = (commentsGroup) => {
  let displayComments = "";

  commentsGroup.forEach(({ comments }, groupIndex) => {
    comments.forEach((comment, index) => {
      const { text = "", owner } = comment;
      const { name = "" } = owner;
      const lastGroup = groupIndex + 1 === commentsGroup.length;
      const lastComment = lastGroup && index + 1 === comments.length;

      displayComments += `${name}: ${text.replace(/<[^>]*>/g, "")}${
        lastComment ? "" : "; "
      }`;
    });
  });

  return displayComments.trim();
};

export const getParticipantComplementaryReportData = ({
  participant,
  filteredWorkflow,
  filteredScales,
  filterType,
  comments,
}) => {
  const data = [];

  const { memberParticipant, companyPosition, companyArea } = participant;

  const participantWorkflow = getParticipantComplementaryWorkflow(
    filteredWorkflow,
    participant
  );

  const groupedSubtypes = getGroupedSubtypesWorkflow(
    participantWorkflow,
    filterType
  );

  defaultSubtypesOrder.forEach((subType) => {
    if (!groupedSubtypes[subType]) return;

    const orderedWorkflow = orderArrayByObjAttr(
      groupedSubtypes[subType],
      "complementaryEvaluation",
      "title"
    );

    const { subtitle: subTypeTitle } = getComplementaryTopicByTypes(
      filterType,
      subType
    );

    orderedWorkflow.forEach((workflowInfo, index) => {
      const { complementaryEvaluation } = workflowInfo;
      const workflowComments = getCommentsByComplementaryWorkflow(
        [{ comments }],
        workflowInfo.id
      );

      const title = getComplementaryEvaluationTitleByType(
        complementaryEvaluation,
        filterType
      );
      const evaluation = getComplementaryWorkflowDisplayValue(
        workflowInfo,
        filteredScales
      );

      data.push({
        index: index + 1,
        participant: memberParticipant?.name,
        email: memberParticipant?.email,
        area: companyArea?.title,
        role: companyPosition?.title,
        subTypeTitle,
        title,
        description: complementaryEvaluation?.description,
        evaluation,
        comments: getReportDisplayComments(workflowComments),
      });
    });
  });

  return data;
};

export const getOpenScalesComplementaryEvaluationData = async ({
  filteredParticipants: participants,
  complementaryWorkflow,
  complementaryScales,
  filterType,
}) => {
  const dataLines = [];

  if (Array.isArray(participants)) {
    const filteredWorkflow = getFilteredWorkflowByType(
      complementaryWorkflow,
      filterType
    );
    const filteredScales = getComplementaryScalesByType(
      complementaryScales,
      filterType
    );

    const promises = participants.map(async (participant) => {
      const { insight = {} } = participant;
      const { data: comments = [] } = await getInsightComments(insight.id);
      const participantData = getParticipantComplementaryReportData({
        participant,
        filteredWorkflow,
        filteredScales,
        filterType,
        comments,
      });

      return { lines: participantData };
    });
    const data = await Promise.all(promises);

    data.forEach(({ lines }) => {
      lines.forEach((lineInfo) => {
        dataLines.push(lineInfo);
      });
    });
  }

  return dataLines;
};

export const getComplementaryWorkflowReportData = async ({
  filteredParticipants,
  complementaryWorkflow,
  complementaryScales,
  filterType,
}) => {
  const successionHide = filterType === "SUCCESSION";

  return {
    headers: [
      {
        header: "Participante",
        key: "participant",
        width: 35,
      },
      { header: "Email", key: "email", width: 35 },
      {
        header: "Área",
        key: "area",
        width: 20,
      },
      {
        header: "Cargo",
        key: "role",
        width: 20,
      },
      {
        header: "Tipo Avaliação",
        key: "subTypeTitle",
        width: 40,
        hide: successionHide,
      },
      { header: "Nº", key: "index", width: 5 },
      { header: "Tema", key: "title", width: 40 },
      {
        header: "Descrição",
        key: "description",
        width: 40,
        hide: successionHide,
      },
      { header: "Avaliação", key: "evaluation", width: 30 },
      { header: "Comentários", key: "comments", width: 200 },
    ].filter(({ hide = false }) => hide === false),
    data: await getOpenScalesComplementaryEvaluationData({
      filteredParticipants,
      complementaryWorkflow,
      complementaryScales,
      filterType,
    }),
  };
};

export const orderWorkflowByCompetenceTitle = (workflow) => {
  if (!Array.isArray(workflow)) return [];

  return orderArrayByObjAttr(
    workflow.map((info) => {
      const { skill } = info;
      const { competenceArea = {} } = skill;
      return { ...info, competenceArea };
    }),
    "competenceArea",
    "title"
  );
};

export const getParticipantWorkflowData = ({
  participant,
  toolAdministration,
  comments,
}) => {
  const data = [];

  const { evaluationScale, potentialScale } = toolAdministration;
  const {
    memberParticipant,
    manager,
    companyPosition,
    companyArea,
    secondEvaluator: secEvaluator,
    thirdEvaluator: thrEvaluator,
  } = participant;

  const participantWorkflow = getParticipantWorkflow(
    toolAdministration,
    participant
  );

  const orderedWorkflow = orderWorkflowByCompetenceTitle(participantWorkflow);

  orderedWorkflow.forEach((workflowInfo, index) => {
    const {
      skill,
      selfEvaluation,
      managerEvaluation,
      potential,
      secondEvaluator,
      thirdEvaluator,
      averageEvaluation,
    } = workflowInfo;
    const { competenceArea } = skill;
    const workflowComments = getGroupCommentsBySkillId(
      [{ comments }],
      skill.id
    );

    data.push({
      index: index + 1,
      participant: memberParticipant?.name,
      email: memberParticipant?.email,
      area: companyArea?.title,
      role: companyPosition?.title,
      manager: manager?.name,
      secondEvaluator: secEvaluator?.name,
      thirdEvaluator: thrEvaluator?.name,
      competenceArea: competenceArea.title,
      skill: skill.title,
      selfEvaluation: `(${selfEvaluation}) ${
        getObjectInfoById(selfEvaluation, evaluationScale, "value").title || ""
      }`,
      managerEvaluation: `(${managerEvaluation}) ${
        getObjectInfoById(managerEvaluation, evaluationScale, "value").title ||
        ""
      }`,
      potential: `(${potential}) ${
        getObjectInfoById(potential, potentialScale, "value").title || ""
      }`,
      secondEvaluatorNote: `(${secondEvaluator}) ${
        getObjectInfoById(secondEvaluator, evaluationScale, "value").title || ""
      }`,
      thirdEvaluatorNote: `(${thirdEvaluator}) ${
        getObjectInfoById(thirdEvaluator, evaluationScale, "value").title || ""
      }`,
      averageEvaluation,
      comments: getReportDisplayComments(workflowComments),
    });
  });

  return data;
};

export const getWorkflowEvaluationData = async ({
  toolAdministration,
  filteredParticipants: participants,
}) => {
  const dataLines = [];

  if (Array.isArray(participants)) {
    const promises = participants.map(async (participant) => {
      const { insight = {} } = participant;
      const { data: comments = [] } = await getInsightComments(insight.id);
      const participantData = getParticipantWorkflowData({
        participant,
        toolAdministration,
        comments,
      });

      return { lines: participantData };
    });
    const data = await Promise.all(promises);

    data.forEach(({ lines }) => {
      lines.forEach((lineInfo) => {
        dataLines.push(lineInfo);
      });
    });
  }

  return dataLines;
};

export const getSelectionEvaluatorsParticipants = ({
  filteredParticipants,
}) => {
  const headers = [
    {
      header: "ID",
      key: "id",
      width: 15,
    },
    {
      header: "Participante",
      key: "participant",
      width: 30,
    },
    {
      header: "Cargo",
      key: "role",
      width: 50,
    },
    {
      header: "Área",
      key: "area",
      width: 50,
    },
    {
      header: "Email",
      key: "email",
      width: 40,
    },
    {
      header: "CPF",
      key: "cpf",
      width: 30,
    },
    {
      header: "Estágio da avaliação",
      key: "evaluationStage",
      width: 30,
    },
    {
      header: "Gestor imediato",
      key: "manager",
      width: 30,
    },
    {
      header: "Cliente",
      key: "secondEvaluator",
      width: 30,
    },
    {
      header: "Fornecedor",
      key: "thirdEvaluator",
      width: 30,
    },
  ];

  const data = () => {
    const actions = filteredParticipants
      .filter(
        (participant) => participant.evaluationStage === "SELECTION_EVALUATORS"
      )
      .map((participant) => {
        return {
          id: participant.id,
          participant: participant.memberParticipant.name,
          role: participant.companyPosition.title,
          area: participant.companyArea.title,
          email: participant.memberParticipant.email,
          cpf: participant.memberParticipant.cpf,
          evaluationStage: participant.evaluationStage,
          manager: participant.manager?.name,
          secondEvaluator: participant.secondEvaluator?.name,
          thirdEvaluator: participant.thirdEvaluator?.name,
        };
      });
    const data = actions.reduce((acc, item) => {
      return acc.concat(item);
    }, []);
    return data;
  };

  return { data, headers };
};

export const getGeneralWorkflowReportData = async ({
  toolAdministration,
  filteredParticipants,
}) => {
  const { evaluationType, evaluationComposition } = toolAdministration;

  const { enableSecondEvaluator, enableThirdEvaluator } =
    getEvaluationConfigByType(evaluationType);

  const { enablePotential } = getCompetenceConfigByComposition(
    evaluationComposition
  );

  return {
    headers: [
      {
        header: "Participante",
        key: "participant",
        width: 35,
      },
      { header: "Email", key: "email", width: 35 },
      {
        header: "Área",
        key: "area",
        width: 20,
      },
      {
        header: "Cargo",
        key: "role",
        width: 20,
      },
      {
        header: "Gestor Imediato",
        key: "manager",
        width: 20,
      },
      {
        header: "Cliente",
        key: "secondEvaluator",
        width: 20,
        hide: !enableSecondEvaluator,
      },
      {
        header: "Fornecedor",
        key: "thirdEvaluator",
        width: 20,
        hide: !enableThirdEvaluator,
      },
      { header: "Área de Competência", key: "competenceArea", width: 40 },
      {
        header: "Competência",
        key: "skill",
        width: 40,
      },
      { header: "Autoavaliação", key: "selfEvaluation", width: 22 },
      { header: "Av. Gestor", key: "managerEvaluation", width: 22 },
      {
        header: "Av. Potencial",
        key: "potential",
        width: 22,
        hide: !enablePotential,
      },
      {
        header: "Av. Cliente",
        key: "secondEvaluatorNote",
        width: 22,
        hide: !enableSecondEvaluator,
      },
      {
        header: "Av. Fornecedor",
        key: "thirdEvaluatorNote",
        width: 22,
        hide: !enableThirdEvaluator,
      },
      { header: "Média", key: "averageEvaluation", width: 10 },
      { header: "Comentários", key: "comments", width: 200 },
    ].filter(({ hide = false }) => hide === false),
    data: await getWorkflowEvaluationData({
      toolAdministration,
      filteredParticipants,
    }),
  };
};

export const orderSkillsByCompetenceArea = (skills, orderedCompetenceAreas) => {
  let finalOrdered = [];

  if (Array.isArray(orderedCompetenceAreas)) {
    orderedCompetenceAreas.forEach((competenceAreaInfo) => {
      const grouped = getSkillsByCompetenceAreas([competenceAreaInfo], skills);
      const orderedSkills = orderArrayByObjAttr(grouped, "title");

      finalOrdered = [...finalOrdered, ...orderedSkills];
    });
  }

  return finalOrdered;
};

export const filterParticipantsByNameOrEmail = (stringFilter, participants) => {
  if (Array.isArray(participants)) {
    const search = getComparableQuery(stringFilter);

    return participants.filter(
      ({ memberParticipant }) =>
        getComparableQuery(memberParticipant.name).match(search) ||
        stringFilter.includes(memberParticipant.email) ||
        memberParticipant.email?.includes(stringFilter)
    );
  }

  return [];
};

export const getActionPlanGroupedByIndicator = (allActionsPlans) => {
  return allActionsPlans;
};

export const getIndicatorsWithActions = (insights) => {
  const indicatorsId = [];

  if (Array.isArray(insights)) {
    insights.forEach(({ actionPlanId, kpiConfigurationGoalId }) => {
      if (actionPlanId && indicatorsId.indexOf(kpiConfigurationGoalId) === -1)
        indicatorsId.push(kpiConfigurationGoalId);
    });
  }

  return { count: indicatorsId.length, indicatorsId };
};

export const getParticipantsExportHeader = () => {
  return [
    { header: "id", key: "id", width: 15 },
    { header: "name", key: "name", width: 30 },
    { header: "email", key: "email", width: 30 },
    { header: "profile", key: "profile", width: 15 },
    { header: "managerEmail", key: "managerEmail", width: 30 },
    { header: "cpf", key: "cpf", width: 20 },
  ];
};

export const getParticipantsExportFakeHeader = () => {
  return {
    id: "ID",
    name: "Nome",
    email: "Email",
    profile: "Perfil",
    managerEmail: "Email do Gestor",
    cpf: "CPF",
  };
};

export const getParticipantsExportData = (participants) => {
  return participants?.map(({ memberParticipant, manager, profile }) => {
    const line = {
      id: memberParticipant.id,
      name: memberParticipant.name,
      email: memberParticipant.email,
      profile: profile?.title,
      managerEmail: manager?.email,
      cpf: memberParticipant.cpf,
    };

    return line;
  });
};

export const getExportParticipantsPack = (participants, options) => {
  return {
    dataToExport: getParticipantsExportData(participants, options),
    headers: getParticipantsExportHeader(),
    fakeHeader: getParticipantsExportFakeHeader(),
    filename: "skills-assessment-participants",
  };
};

export const validateImportLines = ({
  toolAdministration,
  importedTable,
  members,
}) => {
  const validatedData = [];
  const nonValidatedData = [];

  const { participants, profiles } = toolAdministration || {};

  const addError = (errorType, row) => {
    nonValidatedData.push({ ...row, errorType });
  };

  importedTable.forEach((importedRow) => {
    const { email: participantEmail, managerEmail, profile } = importedRow;

    const participantByEmail = participants.find(
      ({ memberParticipant }) => memberParticipant.email === participantEmail
    );
    const profileByTitle = profiles.find(({ title }) => title === profile);
    const managerByEmail = members.find(({ email }) => email === managerEmail);

    const errors = [];

    if (!participantByEmail) errors.push("participant not found");
    if (profile && !profileByTitle) errors.push("profile not found");
    if (managerEmail && !managerByEmail) errors.push("manager not found");

    if (errors.length === 0) {
      validatedData.push(importedRow);
    } else {
      addError(errors.join(", "), importedRow);
    }
  });

  return {
    validatedData,
    nonValidatedData,
  };
};

export function getItemsByPage(items, page, ITEMS_PER_PAGE = 20) {
  return items.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);
}
