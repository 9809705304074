import React from "react";

export const exampleValidationFunction = () => {
  const errors = [];

  const addError = (slug, message) => {
    errors.push({ slug, message });
  };

  // Se houveram campos com mais de 1 erro, a primeira validação será sempre a mais importante
  if (true) {
    addError(
      "trueErrorIdSlugMasterBlaster",
      "Essa mensagem será mostrada quando houver um erro indetificado na função getErrorMessage()",
    );
  }

  return errors;
};

export const checkError = (slugs = [], errors = []) => {
  let found = false;

  if (errors && errors.length > 0) {
    for (const index in errors) {
      const errInfo = errors[index];
      if (slugs.indexOf(errInfo.slug) > -1) found = true;
    }
  }

  return found;
};

export const getErrorMessage = (
  slugs = [],
  errors = [],
  align = "left",
  styles = {},
  hide = false,
) => {
  const messageStyle = {
    margin: "0px",
    padding: "0px",
    fontSize: "14px",
    display: "block",
    color: "#ff6347 !important",
    ...styles,
    height: "auto",
    minHeight: styles.height || "16px",
  };
  let finalJsxErrorMessage = (
    <span style={!hide ? messageStyle : { display: "none" }} />
  );

  // Em errors = [], o primeiro erro é o mais importante
  // break; irá identificar a primeira ocorrencia de erro e mostrar sua mensagem
  if (errors && errors.length > 0) {
    for (const index in errors) {
      const errInfo = errors[index];

      if (slugs.indexOf(errInfo.slug) > -1) {
        finalJsxErrorMessage = (
          <h6
            className="text-danger error-message"
            align={align}
            style={messageStyle}
          >
            {errInfo.message}
          </h6>
        );
        break;
      }
    }
  }

  return finalJsxErrorMessage;
};

export const getStyledErrorMessage = (slugs = [], errors = []) => {
  return getErrorMessage(
    slugs,
    errors,
    "left",
    {
      backgroundColor: "#ff6347",
      color: "#fff",
      padding: "5px",
      height: "24px",
    },
    true,
  );
};

export const emailValidation = (email = "") => {
  const regexValidateEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const formatedEmail = String(email).toLowerCase();

  if (!email.length) return false;

  return regexValidateEmail.test(formatedEmail);
};
