import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import _ from "lodash";

import utils from "../../../../utils/cpAnalysis";
import { checkAccess } from "../../../../utils/accessLevels";
import MaterialSingleSelect from "../../../Common/MaterialSingleSelect";
import {
  getPrioritizationScaleTranslation,
  getPrioritizationCriteriaTranslation,
} from "../../../../utils/cpAnalysis/display";

const {
  getPrioritizationEvaluationFields,
  getPrioritizationEvaluations,
  getEmptyConfiguration,
  handleEmptyConfiguration,
} = utils;

const PrioritizationEvaluation = ({
  insight,
  currentToolType = {},
  currentAdministration = {},
  currentInsightEvaluation = {},
  sendCompetitiveAnalysisEvaluation,
  selectedToolId,
}) => {
  const [evaluations, setEvaluations] = useState({});

  useEffect(() => {
    if (!currentInsightEvaluation?.id) return;

    const syncEvaluation = getPrioritizationEvaluations(
      currentInsightEvaluation,
    );

    setEvaluations(syncEvaluation);
  }, [currentInsightEvaluation]);

  const {
    evaluationCriterias = [],
    scales = [],
  } = getPrioritizationEvaluationFields(currentAdministration, currentToolType);

  const emptyConfiguration = getEmptyConfiguration(evaluationCriterias, scales);

  const scalesOptions = scales.map((scale) => {
    return {
      value: scale.id,
      label: getPrioritizationScaleTranslation(scale.title),
    };
  });

  const handleChangeEvaluation = ({ id }, { value }) => {
    setEvaluations({ ...evaluations, [id]: value });
  };

  const getFirstEvaluationBody = () => {
    return evaluationCriterias.map(({ id: evaluationCriteriaId }) => {
      const scale = Number(evaluations[evaluationCriteriaId]);

      return {
        evaluationCriteriaId,
        scaleId: scale === 0 ? null : scale,
        type: "PRIORITIZATION",
      };
    });
  };

  const searchEvaluation = (evaluationCriteriaId = 0) => {
    const { prioritization = [] } = currentInsightEvaluation;

    return (
      prioritization.filter(
        (evaluation) =>
          Number(evaluation.evaluationCriteriaId) ===
          Number(evaluationCriteriaId),
      )[0] || false
    );
  };

  const getRestEvaluationBody = () => {
    let restEvaluationBody = [];
    let reSendEvaluation = false;

    Object.keys(evaluations).forEach((criteriaId) => {
      const scaleId = evaluations[criteriaId];

      const { scaleId: currentScaleId, id = false } = searchEvaluation(
        criteriaId,
      );

      if (!id) reSendEvaluation = true;
      if (Number(currentScaleId) === Number(scaleId)) return;

      restEvaluationBody.push({
        evaluationCriteriaId: Number(criteriaId),
        scaleId,
        id,
        type: "PRIORITIZATION",
      });
    });

    return { restEvaluationBody, reSendEvaluation };
  };

  const handleSave = () => {
    const {
      restEvaluationBody = [],
      reSendEvaluation = false,
    } = getRestEvaluationBody();

    sendCompetitiveAnalysisEvaluation(
      insight.id,
      {
        type: "PRIORITIZATION",
        evaluations: reSendEvaluation
          ? getFirstEvaluationBody()
          : restEvaluationBody,
      },
      selectedToolId,
      reSendEvaluation ? "POST" : "PUT",
    );
  };

  const canSaveAppears = () => {
    if (emptyConfiguration) return false;

    const syncEvaluation = getPrioritizationEvaluations(
      currentInsightEvaluation,
    );

    if (_.isEqual(syncEvaluation, evaluations)) return false;

    return true;
  };

  const saveButtonHandler = () => {
    if (!canSaveAppears()) return null;

    return (
      <Button
        className="btn btn-purple pull-right"
        onClick={() => handleSave()}
      >
        Salvar
      </Button>
    );
  };

  const displayEvaluationFields = () => {
    return evaluationCriterias.map((criteria, index) => {
      const { id } = criteria;

      return (
        <Col md={6} key={index}>
          <MaterialSingleSelect
            id={criteria.title}
            label={getPrioritizationCriteriaTranslation(criteria.title)}
            value={evaluations[id] || ""}
            options={scalesOptions}
            onChange={(e) =>
              checkAccess([
                "CREATOR",
                "RESPONSIBLE",
                "COLLABORATOR",
                "MANAGER",
                "LEADER",
                "MODERATOR",
                "ADMIN",
                "OWNER",
              ]) && handleChangeEvaluation(criteria, e.target)
            }
          />
        </Col>
      );
    });
  };

  return (
    <div className="box box-primary">
      <div className="box-header with-border">
        <i className="fas fa-tasks" />
        <h3 className="box-title">Avaliação Priorização</h3>
        {saveButtonHandler()}
      </div>
      <div className="box-body">
        <Row>{displayEvaluationFields()}</Row>
        <Row>{handleEmptyConfiguration(emptyConfiguration)}</Row>
      </div>
    </div>
  );
};

export default PrioritizationEvaluation;
