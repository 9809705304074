const en = {
  dialog_delete_item: "Do you really want to delete this item ?",
};

const es = {
  dialog_delete_item: "Realmente quieres eliminar este ítem ?",
};

const pt = {
  dialog_delete_item: "Deseja realmente excluir esse item ?",
};

const translations = { en, es, pt };

export default translations;
