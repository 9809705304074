/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import NewQuestion from "../../../Common/SkillAssessment/NewQuestion";
import {
  getSkillAssessmentStates,
  getSelectedToolStates,
} from "../../../customMapStates";
import { addProfilesToBoard } from "../../../../actions/skillAssessmentActions";
import { useSimpleToolAdministration } from "../../../../utils/skillsAssessment/hooks/administration";
import { getBoardProfilesByArea } from "../../../../utils/skillsAssessment/filter";
import { getOnlySlugValues } from "../../../../utils/ArrayUtils";

const SkillAssessment = ({
  type,
  clearViewCallback,
  cancelCallback,
  selectedTool,
  allSkillAssessment,
  allQuestions,
  isLoading,
  params,
  addProfilesToBoard,
}) => {
  const { toolAdministration } = useSimpleToolAdministration({
    selectedTool,
    allSkillAssessment,
    isLoading,
  });
  const [filteredProfiles, setFilteredProfiles] = useState([]);

  const { participants } = toolAdministration;
  const { questionTheme, order } = params;

  const questions = allQuestions[questionTheme.id] || [];
  const alreadyAdded = getOnlySlugValues(
    questions,
    "skillsAssessmentProfileId",
  );

  useEffect(() => {
    if (questionTheme && questionTheme.companyAreaId) {
      const { companyAreaId } = questionTheme;
      const { profiles: newFiltered } = getBoardProfilesByArea(
        participants,
        companyAreaId,
      );

      if (!_.isEqual(newFiltered, filteredProfiles))
        setFilteredProfiles(newFiltered);
    }
  }, [questionTheme, participants]);

  const clearDialogControll = () => {
    clearViewCallback(type);
    cancelCallback();
  };

  const addProfiles = (selected) => {
    if (selected.length > 0) {
      addProfilesToBoard(selected, questionTheme, order);
    }

    clearDialogControll();
  };

  return (
    <NewQuestion
      open
      onConfirm={addProfiles}
      onCancel={clearDialogControll}
      profiles={filteredProfiles.filter(
        ({ id }) => alreadyAdded.indexOf(id) === -1,
      )}
      areaName={questionTheme.name}
    />
  );
};

SkillAssessment.propTypes = {
  type: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  selectedTool: PropTypes.object.isRequired,
  allSkillAssessment: PropTypes.array.isRequired,
  allQuestions: PropTypes.object.isRequired,
  addProfilesToBoard: PropTypes.func.isRequired,
  clearViewCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func,
  isLoading: PropTypes.bool,
};

SkillAssessment.defaultProps = {
  isLoading: false,
  cancelCallback: () => {},
};

const mapStateToProps = (state) => {
  const { selectedTool = {}, allQuestions } = getSelectedToolStates(state);
  const { allSkillAssessment = [], isLoading } =
    getSkillAssessmentStates(state);

  return {
    selectedTool,
    allSkillAssessment,
    allQuestions,
    isLoading,
  };
};

export default connect(mapStateToProps, { addProfilesToBoard })(
  SkillAssessment,
);
