import { makeStyles } from "@material-ui/core/styles";

export const useQuestionStyles = makeStyles(() => ({
  addButton: {
    fontSize: "12px",
    backgroundColor: "#f2f2f2",
    color: "#333",
    "&:hover": {
      backgroundColor: "#ebebeb",
    },
    textTransform: "none",
    "& > span > svg": {
      position: "relative",
      top: "-1px",
    },
  },
}));
