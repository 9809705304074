/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { CircularProgress } from "@material-ui/core";
import MaterialTextField from "./MaterialTextField";
import {
  buildGroupedComments,
  DisplayComments,
} from "../../utils/commentUtils";
import {
  addInsightTimeline,
  fetchGroupedComments,
} from "../../actions/insightTimelineActions";
import { getConnectedInsights } from "../Insight/middleware";
import { getSelectedToolStates } from "../customMapStates";
import { useConnectedComments } from "../../utils/comments/hooks";

const SimpleCommentSection = ({
  insight,
  tags,
  addInsightTimeline,
  loggedUser,
  filter,
  maxLength,
  selectedTool,
  allComments,
  fetchGroupedComments,
}) => {
  const [loading, setLoading] = useState(true);
  const [connectedInsights, setConnectedInsights] = useState([]);
  const [newComment, setNewComment] = useState("");

  useEffect(() => {
    if (connectedInsights.length > 0) fetchGroupedComments(connectedInsights);
  }, [connectedInsights]);

  const { timelineComments } = useConnectedComments({
    allComments,
    connectedInsights,
  });
  const filteredComments = filter ? filter(timelineComments) : timelineComments;

  const insightId = insight?.id || 0;

  async function refetchComments() {
    if (insight.id) {
      const { comments: insightsIdList } = getConnectedInsights(
        insight,
        selectedTool.tool.id,
        selectedTool.id,
      );

      if (!_.isEqual(insightsIdList, connectedInsights))
        setConnectedInsights(insightsIdList);

      await buildGroupedComments({
        insightsIdList,
        loggedUser,
        insight,
      });

      if (loading) setLoading(false);
    }
  }

  useEffect(() => {
    refetchComments();
  }, [insightId]);

  const isValid = () => {
    return newComment.length > 0;
  };

  const saveComment = async () => {
    if (isValid() && insightId && loggedUser.id) {
      await addInsightTimeline(insightId, newComment, loggedUser.id, tags);

      refetchComments();
      setNewComment("");
    }
  };

  return (
    <div style={{ backgroundColor: "#fff", paddingBottom: "15px" }}>
      {loading && (
        <div style={{ textAlign: "center", padding: "15px" }}>
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <DisplayComments
          timelineComments={filteredComments}
          timelineDisplay
          hideTimeline
        />
      )}
      <div style={{ width: "80%", margin: "0 auto" }}>
        <MaterialTextField
          id="postNewComment"
          label="Fazer comentário"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          inputStyle={{ backgroundColor: "#fff" }}
          primaryInputProps={{ maxLength }}
          multiline
          rows={2}
        />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            className="btn btn-primary"
            onClick={saveComment}
            disabled={!isValid()}
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
};

SimpleCommentSection.propTypes = {
  insight: PropTypes.object,
  filter: PropTypes.func,
  tags: PropTypes.array,
  addInsightTimeline: PropTypes.func.isRequired,
  loggedUser: PropTypes.object.isRequired,
  selectedTool: PropTypes.object.isRequired,
  maxLength: PropTypes.number,
};

SimpleCommentSection.defaultProps = {
  tags: [],
  insight: {},
  filter: (comments) => comments,
  maxLength: 400,
};

export default connect(
  (state) => {
    const { selectedTool } = getSelectedToolStates(state);
    const { insightsTimeline } = state;
    const { allComments = [] } = insightsTimeline;

    return { loggedUser: state.user, selectedTool, allComments };
  },
  { addInsightTimeline, fetchGroupedComments },
)(SimpleCommentSection);
