import React from "react";

import {
  CardContainer,
  CardHeader,
  CardList,
  ListContainer,
  AnswerText,
} from "../../../../../Common/TemplateKanban/kanbanStyle";
import Avatar from "../../../../../Common/Avatar";

const MaturityMap = ({ color, title, insights, selectAndOpenInsight }) => {
  return (
    <CardContainer key={"index"}>
      <CardHeader color={color}>
        <div>{title}</div>
        <div>{insights ? insights.length : 0}</div>
      </CardHeader>
      <CardList>
        {insights &&
          insights.map((insight, index) => {
            const responsibleMember = insight.responsibleMember || {};
            const userData = responsibleMember.userData || {};

            return (
              <ListContainer color={color} key={index}>
                <div className="status-bar"></div>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => selectAndOpenInsight(insight.id)}
                >
                  <Avatar
                    name={responsibleMember.name}
                    src={userData.avatarBlobId}
                    style={{
                      width: "30px",
                      height: "30px",
                      marginRight: "5px",
                    }}
                  />
                  <AnswerText>{insight.text}</AnswerText>
                </div>
              </ListContainer>
            );
          })}
      </CardList>
    </CardContainer>
  );
};

export default MaturityMap;
