import React from "react";
import PropTypes from "prop-types";
import NewEvaluationScale from "../forms/NewEvaluationScale";
import NewQualificationScale from "../forms/NewQualificationScale";
import NewPotentialScale from "../forms/NewPotentialScale";
import NewProfile from "../forms/NewProfile";
import NewParticipant from "../forms/NewParticipant";
import NewCompetenceArea from "../forms/NewCompetenceArea";
import NewSkill from "../forms/NewSkill";
import NewComplementaryEvaluation from "../forms/NewComplementaryEvaluation";
import NewComplementaryScale from "../forms/NewComplementaryScale";

const DisplayOpenForms = (props) => {
  const {
    openFormDialogs,
    resetEditingDialog,
    closeFormAndCallback,
    handleAutoSave,
    toolAdministration,
    updateAdministration,
    editingForm,
  } = props;

  const saveNewAdministration = (newAdministration) => {
    resetEditingDialog();
    handleAutoSave(newAdministration);
  };

  const completeSave = (slug, newAdministration) => {
    closeFormAndCallback(slug, () => saveNewAdministration(newAdministration));
  };

  const closeAndReset = (slug) => {
    closeFormAndCallback(slug, () => resetEditingDialog());
  };

  const check = (slug) => {
    return openFormDialogs.indexOf(slug) > -1;
  };

  return (
    <>
      {check("newEvaluationScale") && (
        <NewEvaluationScale
          open
          afterConfirm={(newAdm) => completeSave("newEvaluationScale", newAdm)}
          afterCancel={() => closeAndReset("newEvaluationScale")}
          toolAdministration={toolAdministration}
        />
      )}
      {check("newQualificationScale") && (
        <NewQualificationScale
          open
          afterConfirm={(newAdm) =>
            completeSave("newQualificationScale", newAdm)
          }
          afterCancel={() => closeAndReset("newQualificationScale")}
          toolAdministration={toolAdministration}
        />
      )}
      {check("newPotentialScale") && (
        <NewPotentialScale
          open
          afterConfirm={(newAdm) => completeSave("newPotentialScale", newAdm)}
          afterCancel={() => closeAndReset("newPotentialScale")}
          toolAdministration={toolAdministration}
        />
      )}
      {check("newProfile") && (
        <NewProfile
          open
          afterConfirm={(newAdm) => completeSave("newProfile", newAdm)}
          afterCancel={() => closeAndReset("newProfile")}
          toolAdministration={toolAdministration}
        />
      )}
      {check("newParticipant") && (
        <NewParticipant
          open
          afterConfirm={(newAdm) => completeSave("newParticipant", newAdm)}
          afterCancel={() => closeAndReset("newParticipant")}
          toolAdministration={toolAdministration}
        />
      )}
      {check("newCompetenceArea") && (
        <NewCompetenceArea
          open
          afterConfirm={(newAdm) => completeSave("newCompetenceArea", newAdm)}
          afterCancel={() => closeAndReset("newCompetenceArea")}
          toolAdministration={toolAdministration}
        />
      )}
      {check("newSkill") && (
        <NewSkill
          open
          afterConfirm={(newAdm) => completeSave("newSkill", newAdm)}
          afterCancel={() => closeAndReset("newSkill")}
          toolAdministration={toolAdministration}
        />
      )}
      {check("newComplementaryEvaluation") && (
        <NewComplementaryEvaluation
          open
          afterConfirm={(newAdm) =>
            completeSave("newComplementaryEvaluation", newAdm)
          }
          afterCancel={() => closeAndReset("newComplementaryEvaluation")}
          toolAdministration={toolAdministration}
          params={editingForm.params}
          updateAdministration={updateAdministration}
        />
      )}
      {check("newComplementaryScale") && (
        <NewComplementaryScale
          open
          afterConfirm={(newAdm) =>
            completeSave("newComplementaryScale", newAdm)
          }
          afterCancel={() => closeAndReset("newComplementaryScale")}
          toolAdministration={toolAdministration}
          params={editingForm.params}
          updateAdministration={updateAdministration}
        />
      )}
    </>
  );
};

DisplayOpenForms.propTypes = {
  openFormDialogs: PropTypes.array.isRequired,
  resetEditingDialog: PropTypes.func.isRequired,
  closeFormAndCallback: PropTypes.func.isRequired,
  handleAutoSave: PropTypes.func.isRequired,
  toolAdministration: PropTypes.object.isRequired,
  editingForm: PropTypes.object.isRequired,
};

export default DisplayOpenForms;
