import * as XLSX from "xlsx";
import moment from "moment";

const dateFormReview = (data = []) => {
  let newData = [];

  data.forEach((line) => {
    let newLine = false;

    Object.keys(line).forEach((slug) => {
      if (!moment.isDate(line[slug])) return;

      newLine = { ...line, [slug]: moment(line[slug]).format("YYYY-MM-DD") };
    });

    newData = [...newData, newLine || line];
  });

  if (Array.isArray(newData)) return newData;

  return [];
};

const readFile = (file = {}, setImportedData) => {
  let importedObj = { data: {} };

  /* Boilerplate to set up FileReader */
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;

  reader.onload = (e) => {
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, {
      type: rABS ? "binary" : "array",
      bookVBA: true,
      cellDates: true,
    });

    const { Sheets = {} } = wb;

    Object.keys(Sheets).forEach((sheetName) => {
      const ws = Sheets[sheetName];

      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {
        raw: true,
        dateNF: "YYYY-MM-DD",
      });

      const cols = make_cols(ws["!ref"]);

      data.shift();

      let newData = dateFormReview(data);

      importedObj = {
        data: {
          ...importedObj.data,
          [sheetName]: { data: newData, cols },
        },
      };
    });

    setImportedData({ fileData: { ...importedObj } });
  };

  if (rABS) {
    reader.readAsBinaryString(file);
  } else {
    reader.readAsArrayBuffer(file);
  }
};

/* generate an array of column objects */
const make_cols = (refstr) => {
  let o = [];
  let C = XLSX.utils.decode_range(refstr).e.c + 1;

  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};

export { readFile, make_cols };
