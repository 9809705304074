const en = {
  theme_main_title: "Theme",
  add_new_theme: "Add theme",
};

const es = {
  theme_main_title: "Tema",
  add_new_theme: "Agregar tema",
};

const pt = {
  theme_main_title: "Tema",
  add_new_theme: "Adicionar tema",
};

const translations = { en, es, pt };

export default translations;
