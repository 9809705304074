import { useEffect, useMemo, useState } from "react";
import { injectIntl } from "react-intl";
import Select from "react-select";
import { useCompareChanges } from "../../../../../utils/hooks/useCompareChanges";
import {
  periodicityOptions,
  rangeScaleOptions,
} from "../../../../../utils/rookauToolProcesses/activities/defaults";
import {
  getDefaultAddTableLine,
  getDefaultRemoveColumn,
} from "../../../../../utils/tableUtils";
import FormDialog from "../../../../Common/FormDialog";
import MaterialTextField from "../../../../Common/MaterialTextField";
import SelectMemberModal from "../../../../Common/SelectMemberModal";
import SimpleCheckbox from "../../../../Common/SimpleCheckbox";
import SimpleExcelCollumn from "../../../../Common/SimpleExcelCollumn";
import ActivityTooltip from "./ActivityTooltips";

const defaultActivity = {
  order: "",
  area: "",
  title: "",
  description: "",
  responsibleId: null,
  responsibleRole: "",
  relevance: 1,
  complexity: 1,
  extimatedTime: {
    min: "",
    med: "",
    max: "",
  },
  demand: {
    min: "",
    med: "",
    max: "",
  },
  checklist: [],
  frequency: 0,
  periodDemandGrowth: 0,
  periodicity: "",
};

const selectStyles = {
  container: (styles) => {
    return {
      ...styles,
    };
  },
};

const ActivityModal = ({
  open,
  onConfirm,
  onCancel,
  editingDocument,
  title,
  confirmText,
  cancelText,
  companyMembers,
  handleDeleteDocument,
  handleDuplicatedDocument,
  handleAddNewAcitivyChecklist,
  handleDeleteChecklistItem,
  isLoading,
  intl,
}) => {
  const [activity, setActivity] = useState(
    editingDocument?.document_data || defaultActivity
  );
  const [memberModal, setMemberModal] = useState({
    open: false,
    onConfirm: () => {},
  });

  const { haveChanges } = useCompareChanges({
    base: editingDocument?.document_data || defaultActivity,
    compare: activity,
    isLoading,
  });

  useEffect(() => {
    if (editingDocument?.document_data)
      setActivity(editingDocument.document_data);
  }, [editingDocument?.document_data]);

  const { blockSubmit, errors } = useMemo(() => {
    const errors = [];

    if (!activity.title) errors.push("Título é obrigatório");
    if (!activity.responsibleId) errors.push("Selecione um responsável");
    if (!activity.periodicity) errors.push("Selecione uma periodicidade");

    return {
      blockSubmit: errors.length > 0,
      errors,
    };
  }, [activity]);

  function updateActivity(field, value) {
    setActivity({ ...activity, [field]: value });
  }

  function updateExtimatedTime(field, value) {
    setActivity({
      ...activity,
      extimatedTime: { ...activity.extimatedTime, [field]: value },
    });
  }

  function updateDemand(field, value) {
    setActivity({
      ...activity,
      demand: { ...activity.demand, [field]: value },
    });
  }

  function updateChecklistItem(checklistId, field, value) {
    setActivity({
      ...activity,
      checklist: activity.checklist.map((item) =>
        item.id === checklistId ? { ...item, [field]: value } : item
      ),
    });
  }

  function handleSelectResponsible() {
    setMemberModal({
      ...memberModal,
      open: true,
      onConfirm: (ids, selected) => {
        setActivity({
          ...activity,
          responsibleId: selected[0].id,
        });

        setMemberModal({ ...memberModal, open: false });
      },
    });
  }

  function handleConfirm() {
    onConfirm(activity);
  }

  const {
    currentRelevance,
    currentComplexity,
    currentPeriodicity,
    responsible,
    checklistIndicator,
  } = useMemo(() => {
    const currentRelevance = rangeScaleOptions.find(
      (option) => option.value === activity.relevance
    );
    const currentComplexity = rangeScaleOptions.find(
      (option) => option.value === activity.complexity
    );
    const currentPeriodicity = periodicityOptions.find(
      (option) => option.value === activity.periodicity
    );

    const responsible = companyMembers.find(
      (member) => member.id === activity.responsibleId
    );

    const completedChecklist = activity.checklist.filter(
      ({ completed }) => completed === true
    );
    const checklistIndicator = `(${activity.checklist.length}/${completedChecklist.length})`;

    return {
      currentRelevance,
      currentComplexity,
      currentPeriodicity,
      responsible,
      checklistIndicator,
    };
  });

  return (
    <FormDialog
      open={open}
      title={title}
      onConfirm={handleConfirm}
      confirmText={confirmText}
      cancelText={cancelText}
      onCancel={() => onCancel(haveChanges)}
      blockConfirm={blockSubmit || isLoading || !haveChanges}
      isLoading={isLoading}
      bottomLeft={
        <div
          style={{
            display: "flex",
            gap: 10,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {editingDocument?.document_id && (
            <>
              <div>
                <button
                  className="btn btn-danger"
                  onClick={handleDeleteDocument}
                  disabled={isLoading}
                >
                  Excluir
                </button>
              </div>
              <div>
                <button
                  className="btn btn-info"
                  onClick={handleDuplicatedDocument}
                  disabled={isLoading}
                >
                  Duplicar
                </button>
              </div>
            </>
          )}
          {errors.length > 0 ? (
            <div style={{ whiteSpace: "nowrap" }} className="text-danger">
              {errors[0]}
            </div>
          ) : null}
        </div>
      }
    >
      <div
        style={{
          opacity: isLoading ? 0.5 : 1,
          maxHeight: "75vh",
          overflowX: "auto",
        }}
      >
        {memberModal.open && (
          <SelectMemberModal
            open
            title="Selecione um membro"
            toggleOpen={() => setMemberModal({ ...memberModal, open: false })}
            onConfirm={memberModal.onConfirm}
            membersList={companyMembers}
            initialSelected={memberModal?.initialSelected || []}
            singleSelect
          />
        )}
        <div
          className="col-xs-12"
          style={{ marginBottom: "15px", backgroundColor: "#fff" }}
        >
          <MaterialTextField
            label="Título"
            value={activity.title}
            onChange={(e) => updateActivity("title", e.target.value)}
            primaryInputProps={{ maxLength: 250 }}
            multiline
          />
          <MaterialTextField
            label="Descrição"
            value={activity.description}
            onChange={(e) => updateActivity("description", e.target.value)}
            primaryInputProps={{ maxLength: 1000 }}
            multiline
            rows={2}
          />
          <MaterialTextField
            className={"col-xs-12 col-md-9"}
            label="Ator"
            value={activity.area}
            onChange={(e) => updateActivity("area", e.target.value)}
            primaryInputProps={{ maxLength: 250 }}
            multiline
          />
          <MaterialTextField
            className={"col-xs-12 col-md-3"}
            label="Ordem"
            value={activity.order}
            onChange={(e) => updateActivity("order", e.target.value)}
            type="number"
          />
          <div>
            <MaterialTextField
              label="Responsável"
              value={responsible?.name || "- Selecione -"}
              onClick={handleSelectResponsible}
            />
            {responsible?.name && (
              <label
                style={{ fontWeight: "normal", marginBottom: "15px" }}
                className="col-xs-12"
              >
                Cargo:{" "}
                <b>{responsible?.profile?.companyPosition?.title || ""}</b>
              </label>
            )}
          </div>
          <MaterialTextField
            label="Função"
            value={activity.responsibleRole}
            onChange={(e) => updateActivity("responsibleRole", e.target.value)}
            primaryInputProps={{ maxLength: 60 }}
          />
        </div>
        <div className="row" style={{ padding: "15px 0px" }}>
          <div className="col-xs-6">
            <ActivityTooltip title="relevance" intl={intl} />
            <Select
              defaultValue={currentRelevance}
              value={currentRelevance}
              onChange={(option) => updateActivity("relevance", option.value)}
              options={rangeScaleOptions}
              menuShouldScrollIntoView={false}
              styles={selectStyles}
            />
          </div>
          <div className="col-xs-6">
            <ActivityTooltip title="complexity" intl={intl} />
            <Select
              defaultValue={currentComplexity}
              value={currentComplexity}
              onChange={(option) => updateActivity("complexity", option.value)}
              options={rangeScaleOptions}
              menuShouldScrollIntoView={false}
              styles={selectStyles}
            />
          </div>
        </div>
        <div className="row" style={{ margin: "15px 0" }}>
          <div className="col-xs-6" style={{ backgroundColor: "#fff" }}>
            <label>Frequência</label>
            <MaterialTextField
              endAdorment={{ text: "x" }}
              placeholder="0"
              textAlign="right"
              type="number"
              value={activity.frequency || ""}
              onChange={(e) => updateActivity("frequency", e.target.value)}
              margin="none"
            />
          </div>
          <div className="col-xs-6">
            <label>Periodicidade</label>
            <Select
              defaultValue={currentPeriodicity}
              value={currentPeriodicity}
              onChange={(option) => updateActivity("periodicity", option.value)}
              options={periodicityOptions}
              menuShouldScrollIntoView={false}
              styles={selectStyles}
              menuPlacement="top"
            />
          </div>
        </div>
        <div className="row">
          <label
            className="col-xs-12"
            style={{ paddingTop: "15px", margin: 0 }}
          >
            Tempo estimado (em horas)
          </label>
          <div className="col-xs-4" style={{ zIndex: 0 }}>
            <MaterialTextField
              label="Mín."
              type="time"
              value={activity.extimatedTime?.min || ""}
              onChange={(e) => updateExtimatedTime("min", e.target.value)}
            />
          </div>
          <div className="col-xs-4" style={{ zIndex: 0 }}>
            <MaterialTextField
              label="Med."
              type="time"
              value={activity.extimatedTime?.med || ""}
              onChange={(e) => updateExtimatedTime("med", e.target.value)}
            />
          </div>
          <div className="col-xs-4" style={{ zIndex: 0 }}>
            <MaterialTextField
              label="Max."
              type="time"
              value={activity.extimatedTime?.max || ""}
              onChange={(e) => updateExtimatedTime("max", e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <label
            className="col-xs-12"
            style={{ paddingTop: "15px", margin: 0 }}
          >
            Demanda (em quantidade)
          </label>
          <div className="col-xs-4">
            <MaterialTextField
              label="Mín."
              type="number"
              value={activity.demand?.min || ""}
              onChange={(e) => updateDemand("min", e.target.value)}
            />
          </div>
          <div className="col-xs-4">
            <MaterialTextField
              label="Med."
              type="number"
              value={activity.demand?.med || ""}
              onChange={(e) => updateDemand("med", e.target.value)}
            />
          </div>
          <div className="col-xs-4">
            <MaterialTextField
              label="Max."
              type="number"
              value={activity.demand?.max || ""}
              onChange={(e) => updateDemand("max", e.target.value)}
            />
          </div>
        </div>
        <div className="row" style={{ margin: "15px 0px" }}>
          <div className="col-xs-7" style={{ backgroundColor: "#fff" }}>
            <ActivityTooltip title="periodDemandGrowth" intl={intl} />
            <MaterialTextField
              label="% por período"
              placeholder="0"
              endAdorment={{ text: "%" }}
              textAlign="right"
              type="number"
              value={activity.periodDemandGrowth || ""}
              onChange={(e) =>
                updateActivity("periodDemandGrowth", e.target.value)
              }
            />
          </div>
        </div>

        {editingDocument?.document_id && (
          <div className="col-xs-12">
            <table className="simple-table" style={{ width: "100%" }}>
              <thead>
                {getDefaultAddTableLine(
                  () => handleAddNewAcitivyChecklist(activity.id),
                  {},
                  <h5>Tarefas (etapas) {checklistIndicator}</h5>
                )}
              </thead>
              <tbody>
                <tr className="thead">
                  <td width="40" />
                  <td>Título</td>
                  <td width="50">Remover</td>
                </tr>
                {activity.checklist
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .map((item) => {
                    return (
                      <tr key={item.id}>
                        <td style={{ padding: 0 }}>
                          <SimpleCheckbox
                            checked={item.completed}
                            onChange={(e) => {
                              updateChecklistItem(
                                item.id,
                                "completed",
                                !e.checked
                              );
                            }}
                            styles={{ padding: 10 }}
                          />
                        </td>
                        <SimpleExcelCollumn
                          value={item.title}
                          onChange={(e) =>
                            updateChecklistItem(
                              item.id,
                              "title",
                              e.target.value
                            )
                          }
                        />
                        {getDefaultRemoveColumn(() =>
                          handleDeleteChecklistItem(item.id)
                        )}
                      </tr>
                    );
                  })}
                {activity.checklist.length === 0 && (
                  <tr>
                    <td colSpan={3}>
                      <h5>Nenhum item adicionado</h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </FormDialog>
  );
};
export default injectIntl(ActivityModal);
