import React, { useState, useCallback } from "react";
import { Tabs, Tab } from "react-bootstrap";
import StageConfigurationCards from "../../../../StageConfigurationCards";

import Templates from "./components/Templates";

function ProjectAdministration() {
  const [activatedKey, setActivatedKey] = useState("template");

  const handleActivatedKey = useCallback((keyTab) => {
    setActivatedKey(keyTab);
  }, []);

  return (
    <div className="box-body">
      <div className="custom-primary-tabs" style={{ marginBottom: "10px" }}>
        <Tabs
          id="projectManagement"
          activeKey={activatedKey}
          onSelect={handleActivatedKey}
          unmountOnExit
        >
          <Tab eventKey="estages" title="Estágios">
            <StageConfigurationCards />
          </Tab>
          <Tab eventKey="template" title="Templates">
            <Templates />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export { ProjectAdministration };
