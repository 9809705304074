import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import SimpleExcelCollumn from "../../SimpleExcelCollumn";
import SelectGoalModal from "../../SelectGoalModal";
import SimpleLoader from "../../../SimpleLoader";
import { getErrorMessage } from "../../../../utils/formValidation";
import { getRelationshipTypeOptions } from "../../../../constants/kpis";
import { getDefaultAddTableLine } from "../../../../utils/tableUtils";
import { getDisplayTargets } from "../../../../utils/kpis/calc";
import { getSingleIndicatorResults } from "../../../../utils/kpis/calc2";
import {
  getSelectGoalSecondaryText,
  displayTransformValue,
} from "../../../../utils/kpis/display";
import {
  operationOptions,
  getTargetIndicatorMetrics,
  getCalculatedIndicators,
} from "../../../../utils/selected-tool-metrics/indicator-metrics";
import { getDefaultRemoveColumn } from "../../../../utils/tableUtils";
import {
  getIndicatorMetricsByToolId,
  createIndicatorMetric,
  updateIndicatorMetric,
  deleteIndicatorMetric,
} from "../../../../actions/selected-tool/indicator-metrics";

const defaultCalculatedMetric = {
  indicatorMetrics: [],
  selectedToolId: null,
  selectedToolMetric: null,
};

const getFilteredDisplayGoals = (kpiAdministration) => {
  return kpiAdministration?.goals
    ?.filter((goalInfo) => {
      const cantBeAdded =
        goalInfo.measurementUnity === "DATE" ||
        ["SHARED", "CALCULATED"].includes(goalInfo.relationshipType);

      return !cantBeAdded;
    })
    .map((goalInfo) => {
      return {
        ...goalInfo,
        filterValue: goalInfo.relationshipType,
        secondaryText: getSelectGoalSecondaryText(goalInfo),
      };
    });
};

const GoalMetric = ({
  selectedToolMetricId,
  measurementUnity,
  frequency,
  kpiAdministration,
  selectedToolId,
  isLoading,
  setIsLoading,
  setConfirmationDialog,
  resetConfirmDialog,
}) => {
  const [calculatedMetric, setCalculatedMetric] = useState(
    defaultCalculatedMetric,
  );
  const [openSelectGoalModal, setOpenSelectGoalModal] = useState(false);

  function syncFormState(newCalculatedMetric) {
    setCalculatedMetric({ ...newCalculatedMetric });
  }

  async function fetchMetric() {
    setIsLoading(true);

    // @IMRPOVEMENT: replace with a call to find by getIndicatorMetricsByToolId
    try {
      const { data: metrics } = await getIndicatorMetricsByToolId(
        selectedToolId,
      );

      const newCalculatedMetric = metrics.find(
        (calculatedMetric) =>
          calculatedMetric?.selectedToolMetric?.id === selectedToolMetricId,
      );
      syncFormState(newCalculatedMetric);
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (selectedToolMetricId) fetchMetric();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedToolMetricId, kpiAdministration]);

  const getFormErrors = () => {
    const errors = [];

    const addError = (slug, message) => {
      errors.push({ slug, message });
    };

    if (!selectedToolMetricId)
      addError("selectedToolMetricId", "Métrica inválida");

    if (frequency !== "YEARLY") {
      const differentFrequency = calculatedMetric.indicatorMetrics?.find(
        ({ goalId }) => {
          const goal = kpiAdministration.goals.find(({ id }) => id === goalId);
          return goal?.frequency !== frequency;
        },
      );

      if (differentFrequency?.id) {
        addError(
          "differentFrequency",
          "Frequência diferente da métrica selecionada.",
        );

        addError(
          "YEARLY",
          "Selecione a Frequência Anual para utilizar indicadores com períodos diferentes.",
        );
      }
    }

    return errors;
  };

  const handleOpenSelectGoal = () => {
    setOpenSelectGoalModal(true);
  };

  const updateIndicatorMetricById = async (
    fieldSlug,
    newValue,
    indicatorMetricId,
  ) => {
    setIsLoading(true);

    const currentIndicatorMetric = calculatedMetric.indicatorMetrics.find(
      ({ id }) => id === indicatorMetricId,
    );
    const updatedIndicatorMetric = {
      ...currentIndicatorMetric,
      [fieldSlug]: newValue,
    };

    await updateIndicatorMetric(indicatorMetricId, updatedIndicatorMetric);
    fetchMetric();
  };

  const confirmSelectIndicator = async (selected) => {
    setOpenSelectGoalModal(false);
    setIsLoading(true);

    const newIndicatorMetric = {
      id: null,
      selectedToolMetricId,
      goalId: selected[0],
      operation: null,
      order: calculatedMetric.indicatorMetrics.length,
    };

    const { data: savedMetric } = await createIndicatorMetric(
      newIndicatorMetric,
    );

    const updated = [...calculatedMetric.indicatorMetrics];
    updated.push(savedMetric);

    setCalculatedMetric((current) => {
      return {
        ...current,
        indicatorMetrics: updated,
      };
    });
    setIsLoading(false);
  };

  async function handleDeleteIndicatorMetric(indicatorMetricId) {
    setIsLoading(true);
    resetConfirmDialog();

    try {
      await deleteIndicatorMetric(indicatorMetricId);
      setCalculatedMetric((current) => {
        return {
          ...current,
          indicatorMetrics: current.indicatorMetrics?.filter(
            (metric) => metric.id !== indicatorMetricId,
          ),
        };
      });
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  }

  const formErrors = getFormErrors();

  const relationshipTypeOptions = getRelationshipTypeOptions();

  const { ordered, operationResult } = useMemo(() => {
    const calculatedIndicators = getCalculatedIndicators(kpiAdministration);
    const { indicatorMetrics } = calculatedMetric;

    return {
      ordered: indicatorMetrics.sort((a, b) => a.order - b.order),
      operationResult: getTargetIndicatorMetrics({
        indicatorMetrics,
        calculatedIndicators,
        unity: measurementUnity,
      }),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatedMetric.indicatorMetrics]);

  return (
    <div
      className="row"
      style={
        isLoading
          ? { opacity: "0.5", pointerEvents: "none", position: "relative" }
          : { opacity: "1", position: "relative" }
      }
    >
      {isLoading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SimpleLoader color="#6b42a9" />
        </div>
      )}

      {calculatedMetric?.selectedToolMetric?.id ? (
        <div className="col-xs-12">
          {openSelectGoalModal && (
            <SelectGoalModal
              open
              title="Selecione um indicador para gerar a métrica"
              singleSelect
              onConfirm={confirmSelectIndicator}
              onCancel={() => setOpenSelectGoalModal(false)}
              goalsList={getFilteredDisplayGoals(kpiAdministration)}
              filterOptions={relationshipTypeOptions}
            />
          )}
          <div className="row">
            <h4 style={{ marginBottom: "0px" }}>Métricas</h4>
            {formErrors.length > 0 && (
              <div>
                {getErrorMessage(
                  ["selectedToolMetricId", "differentFrequency"],
                  formErrors,
                  "right",
                )}
                {getErrorMessage(["YEARLY"], formErrors, "right")}
              </div>
            )}
          </div>
          <table className="simple-table table-striped">
            <thead>{getDefaultAddTableLine(handleOpenSelectGoal)}</thead>
            <tbody>
              <tr className="thead">
                <td width="60">
                  <b>Ordem</b>
                </td>
                <td>
                  <b>Indicador</b>
                </td>
                <td width="200">
                  <b>Operação</b>
                </td>
                <td style={{ maxWidth: "200px" }}>
                  <b>Valor</b>
                </td>
                <td width="35" />
              </tr>
              {ordered.map((indicator_metric, index) => {
                const goal = kpiAdministration.goals.find(
                  (g) => g.id === indicator_metric.goalId,
                );
                const isLast = index === ordered.length - 1;

                if (!goal?.id) return;

                const onDelete = () =>
                  setConfirmationDialog({
                    open: true,
                    title:
                      "Tem certeza que deseja remover da métrica este indicador?",
                    onConfirm: () => {
                      handleDeleteIndicatorMetric(indicator_metric.id);
                    },
                    onCancel: () => {
                      resetConfirmDialog();
                    },
                  });

                const currentOption = operationOptions.find((option) => {
                  return option.value === indicator_metric.operation;
                });

                const { goalPeriodTotalEvaluation, courseTargetsDivision } =
                  getSingleIndicatorResults(
                    goal,
                    kpiAdministration.goalTargets,
                    kpiAdministration.zeroWhenNegative,
                  );

                const { displayTotal } = getDisplayTargets(
                  goal,
                  goalPeriodTotalEvaluation,
                  courseTargetsDivision,
                );

                return (
                  <tr key={indicator_metric.id}>
                    <SimpleExcelCollumn
                      label="Ordem"
                      type="number"
                      value={indicator_metric?.order}
                      onChange={(e) =>
                        updateIndicatorMetricById(
                          "order",
                          e.target.value,
                          indicator_metric.id,
                        )
                      }
                    />
                    <td>{goal?.title}</td>
                    <td style={{ padding: 0, opacity: isLast ? "0.5" : 1 }}>
                      <Select
                        defaultValue={currentOption}
                        value={currentOption}
                        onChange={(option) =>
                          updateIndicatorMetricById(
                            "operation",
                            option.value,
                            indicator_metric.id,
                          )
                        }
                        options={operationOptions}
                        menuShouldScrollIntoView={false}
                      />
                    </td>
                    <td style={{ fontWeight: "500" }}>
                      {displayTransformValue(
                        displayTotal,
                        goal.measurementUnity,
                        2,
                      )}
                    </td>
                    {getDefaultRemoveColumn(onDelete)}
                  </tr>
                );
              })}

              <tr>
                <td colSpan="3">Resultado</td>
                <td
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {operationResult.actual.formatted}
                </td>
                <td />
              </tr>
              <tr onClick={handleOpenSelectGoal}>
                <td colSpan="100">
                  <div
                    className="text-center"
                    style={{ padding: "10px 0px", cursor: "pointer" }}
                  >
                    <div className="text-center">
                      <i>Adicionar + indicadores</i>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default GoalMetric;
