import React from "react";
import SimpleBarChart from "../../../../../Common/SimpleBarChart";
import _ from "lodash";
import utils from "../../../../../../utils/toolUtils";

const { getLabelBySlug } = utils;

const PerformanceField = (props) => {
  const {
    filteredEvaluations,
    performanceEvaluationOptions,
    genderOptions,
    messages,
    intl,
  } = props;

  const displayPerformanceGraph = () => {
    let defaultVal = {
      BELOW_EXPECTED: 0,
      AS_EXPECTED: 0,
      ABOVE_EXPECTED: 0,
    };

    let filteredPerformance = {
      MALE: _.cloneDeep(defaultVal),
      FEMALE: _.cloneDeep(defaultVal),
    };

    filteredEvaluations.forEach(({ peopleEvaluation }) => {
      const { evaluationTarget, professionalProfile } = peopleEvaluation;
      const { lastEvaluations } = evaluationTarget;
      const { performance } = lastEvaluations;
      const { current } = performance;
      const { gender } = professionalProfile;

      if (current && gender) {
        filteredPerformance[gender][current] =
          filteredPerformance[gender][current] + 1;
      }
    });

    let finalData = [
      {
        name: getLabelBySlug("BELOW_EXPECTED", performanceEvaluationOptions),
        value: filteredPerformance.MALE.BELOW_EXPECTED,
        secondaryValue: filteredPerformance.FEMALE.BELOW_EXPECTED,
      },
      {
        name: getLabelBySlug("AS_EXPECTED", performanceEvaluationOptions),
        value: filteredPerformance.MALE.AS_EXPECTED,
        secondaryValue: filteredPerformance.FEMALE.AS_EXPECTED,
      },
      {
        name: getLabelBySlug("ABOVE_EXPECTED", performanceEvaluationOptions),
        value: filteredPerformance.MALE.ABOVE_EXPECTED,
        secondaryValue: filteredPerformance.FEMALE.ABOVE_EXPECTED,
      },
    ];

    return (
      <SimpleBarChart
        data={finalData}
        displaySecondaryValues
        secondaryFillColor="#fc7e7e"
        showLegend
        legendLabel={getLabelBySlug("MALE", genderOptions)}
        secondaryLegendLabel={getLabelBySlug("FEMALE", genderOptions)}
      />
    );
  };

  return (
    <div className="evaluation-card">
      <div className="card-header">
        <div className="card-title">
          <h4>{intl.formatMessage(messages.titles_cards_performance)}</h4>
        </div>
      </div>
      <div className="card-body" style={{ height: "325px" }}>
        <div className="row">
          <div className="col-xs-12">{displayPerformanceGraph()}</div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceField;
