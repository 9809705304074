import React from "react";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import {
  getUpdatedDeleteArrayByIndex,
  getUpdatedObjectInArray,
  getOnlySublevelArrays,
} from "../../../../../../../utils/ArrayUtils";
import { getConcurrenceCriteriaTranslation } from "../../../../../../../utils/cpAnalysis/display";

const EvaluationCriterias = (props) => {
  const {
    cpAnalysisAdministration = {},
    setCpAnalysisAdministration,
    intl,
    getEmptyTableRow,
    getDefaultRemoveColumn,
    getDefaultAddTableLine,
    setConfirmationDialog,
    resetConfirmDialog,
    toggleOpenFormDialogs,
    deleteEvaluationCriteria,
    allAnswers,
    selectedTool,
  } = props;

  const { concurrence = [] } = cpAnalysisAdministration || {};
  const { evaluationCriterias = [] } = concurrence || {};

  const updateConcurrenceField = (field = false, value = "") => {
    if (!field) return;

    const concurrenceConfig = cpAnalysisAdministration.concurrence || {};

    setCpAnalysisAdministration({
      ...cpAnalysisAdministration,
      concurrence: {
        ...concurrenceConfig,
        [field]: [...value],
      },
    });
  };

  const handleUpdateCriteria = (value = "", field = "", index = null) => {
    const updated = getUpdatedObjectInArray(
      value,
      evaluationCriterias,
      field,
      index,
    );

    updateConcurrenceField("evaluationCriterias", updated);
  };

  const handleDeleteCriteria = (index) => {
    const { id = "" } = evaluationCriterias[index];

    deleteEvaluationCriteria(id, "concurrence", selectedTool.id);

    resetConfirmDialog();
  };

  const getBoardConnection = () => {
    let haveBoardConnection = false;

    const onlyAnswers = getOnlySublevelArrays(allAnswers);

    onlyAnswers.forEach((insight) => {
      const { question = {} } = insight;
      const { theme = {} } = question;
      const { cenary = {} } = theme;
      const { name = "" } = cenary;

      if (name.toUpperCase() === "CONCURRENCE") haveBoardConnection = true;
    });

    return haveBoardConnection;
  };

  const openDeleteCriteria = (index) => {
    const checkBoardConnection = getBoardConnection();

    if (checkBoardConnection)
      return setConfirmationDialog({
        title:
          "Você precisa apagar todos os insights do Cenário Concorrentes para continuar com a exclusão.",
        onConfirm: () => resetConfirmDialog(),
        onCancel: () => resetConfirmDialog(),
        open: true,
      });

    setConfirmationDialog({
      title: "Você tem certeza?",
      onConfirm: () => handleDeleteCriteria(index),
      onCancel: () => resetConfirmDialog(),
      open: true,
    });
  };

  const maxLength = evaluationCriterias.length > 9;

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="2">Título</td>
          <td />
        </tr>
      </thead>
      <tbody>
        {evaluationCriterias.map((criteriaInfo, index) => {
          const title = getConcurrenceCriteriaTranslation(
            criteriaInfo.title || "",
          );

          return (
            <tr className="block-hover" key={index}>
              <td align="center" style={{ width: "40px" }}>
                {index + 1}
              </td>
              <SimpleExcelCollumn
                id="criteriaTitle"
                label="Titulo"
                type="text"
                value={title}
                onChange={(e) =>
                  handleUpdateCriteria(e.target.value, "title", index)
                }
              />
              {getDefaultRemoveColumn(() => openDeleteCriteria(index))}
            </tr>
          );
        })}
        {getEmptyTableRow(evaluationCriterias, "Nada para mostrar!")}
        {!maxLength &&
          getDefaultAddTableLine(() =>
            toggleOpenFormDialogs("newConcurrenceCriteria"),
          )}
      </tbody>
    </table>
  );
};

export default EvaluationCriterias;
