import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { defineMessages } from "react-intl";
import moment from "moment";
import _ from "lodash";

import { Fab } from "@material-ui/core";
import { Tabs, Tab } from "react-bootstrap";
import CheckIcon from "@material-ui/icons/Check";
import ConfirmationDialog from "../../../../../Common/ConfirmationDialog";
import {
  getSelectedCompanyStates,
  getSelectedToolStates,
  getGoalsBonusStates,
  getCompanyMembersAndParticipants,
} from "../../../../../customMapStates";

import TeamsConfigTable from "./fields/TeamsConfigTable";
import BonusAdministrationTable from "./fields/BonusAdministrationTable";
import RoleBonusesTable from "./fields/RoleBonusesTable";
import ParticipantsTable from "./fields/ParticipantsTable";
import AdministrativeRolesTable from "./fields/AdministrativeRolesTable";
import GoalsTable from "./fields/GoalsTable";
import ProgramDatesTable from "./fields/ProgramDatesTable";
import ProgramTriggersTable from "./fields/ProgramTriggersTable";
import DisplayOpenForms from "./DisplayOpenForms";
import {
  getGoalBonusConfiguration,
  updateGoalBonusAdministration,
} from "../../../../../../actions/goalsAndBonusActions";

import {
  getParticipantPeriodOptions,
  getPageAccessRulesOptions,
  getRelationshipTypeOptions,
  getGoalValidationStatusOptions,
} from "../../../../../../constants/goalsAndBonus";
import { fetchAllUsersCompanyProfiles } from "../../../../../../actions/userActions";
import { fetchCompanyNestedAreas } from "../../../../../../actions/companyStructureActions";
import { fetchCompanyNestedPositions } from "../../../../../../actions/companyPositionActions";
import { useAdministrationStyles } from "../../../../../../utils/administration/styles";
import utils from "../../../../../../utils/toolUtils";
import gbUtils from "../../../../../../utils/goalsBonus";
import { buildGoalsAdministrationFinalBody } from "../../../../../../utils/goalsBonus/entity";
import { getDefaultTargetCols } from "../../../../../../utils/kpis/display";
import PeriodConfigTable from "../../KPI/tabs/fields/PeriodConfigTable";
import { getStyledErrorMessage } from "../../../../../../utils/formValidation";
import { useHistoryToolAdministration } from "../../../../../../utils/goalsBonus/hooks";
import { useCompareChanges } from "../../../../../../utils/administration/hooks";

const { getUpdatedDeleteArrayByIndex, translatedText } = utils;

const { buildFormAdmConfigBody, buildRestAdmConfigBody } = gbUtils;

const messages = defineMessages({
  gb_tab_administration_title: { id: "gb_tab_administration_title" },
  gb_sub_title_config: { id: "gb_sub_title_config" },
  gb_sub_title_dates: { id: "gb_sub_title_dates" },
  gb_sub_title_triggers: { id: "gb_sub_title_triggers" },
  gb_sub_title_guardians: { id: "gb_sub_title_guardians" },
  gb_sub_title_hierarchy: { id: "gb_sub_title_hierarchy" },
  gb_sub_title_participants: { id: "gb_sub_title_participants" },
  gb_sub_title_teams: { id: "gb_sub_title_teams" },
  gb_sub_title_goals: { id: "gb_sub_title_goals" },
  gb_tab_administration_config: { id: "gb_tab_administration_config" },
  gb_tab_administration_involved: { id: "gb_tab_administration_involved" },
  gb_tab_administration_goals: { id: "gb_tab_administration_goals" },
  global_add: { id: "global.add" },
  gb_table_text_year: { id: "gb_table_text_year" },
  gb_table_text_start_registrations: {
    id: "gb_table_text_start_registrations",
  },
  gb_table_text_end_registrations: { id: "gb_table_text_end_registrations" },
  gb_table_text_start_approval: { id: "gb_table_text_start_approval" },
  gb_table_text_end_approval: { id: "gb_table_text_end_approval" },
  gb_table_text_start_audit: { id: "gb_table_text_start_audit" },
  gb_table_text_end_audit: { id: "gb_table_text_end_audit" },
  gb_error_invalid_year: { id: "gb_error_invalid_year" },
  gb_error_dates_conflict: { id: "gb_error_dates_conflict" },
  gb_error_invalid_period_date: { id: "gb_error_invalid_period_date" },
  gb_error_no_role_selected: { id: "gb_error_no_role_selected" },
  kpi_sub_title_period_config: { id: "kpi_sub_title_period_config" },
  kpi_error_invalid_same_month: { id: "kpi_error_invalid_same_month" },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

const defaultConfirmDialog = {
  title: "",
  onConfirm: null,
  onCancel: null,
  open: false,
  params: {},
};

const defaultEditingForm = {
  type: "",
  params: {},
};

const ConfigurationsTabView = (props) => {
  return (
    <div className="tab-content" style={{ margin: "0px" }}>
      <div className="col-xs-12 col-lg-6">
        <h4 style={{ marginBottom: "0px" }}>
          {translation("gb_sub_title_config")}
        </h4>
        <div className="row">
          <BonusAdministrationTable {...props} />
        </div>
      </div>
      <div className="col-xs-12 col-lg-6">
        <h4 style={{ marginBottom: "0px" }}>
          {translation("kpi_sub_title_period_config")}
        </h4>
        <div className="row">
          <PeriodConfigTable
            {...props}
            kpiAdministration={props.goalBonusAdministration}
            setKpiAdministration={props.setGoalBonusAdministration}
            title="Atualizar o Período das Metas."
            description="Você quer atualizar o período de todas as metas? Se não quiser, poderá atualizar individualmente aqueles que forem necessários."
            confirmText="Atualizar Todas Metas"
          />
          {getStyledErrorMessage(
            ["sameMonthError"],
            props.administrationErrors,
          )}
        </div>
      </div>
      <div className="col-xs-12">
        <h4 style={{ marginBottom: "0px" }}>
          {translation("gb_sub_title_guardians")}
        </h4>
        <div className="row">
          <AdministrativeRolesTable {...props} />
        </div>
      </div>
      <div className="col-xs-12 col-lg-6">
        <h4 style={{ marginBottom: "0px" }}>
          {translation("gb_sub_title_dates")}
        </h4>
        <div className="row">
          <ProgramDatesTable {...props} />
        </div>
      </div>
      <div className="col-xs-12 col-lg-6">
        <h4 style={{ marginBottom: "0px" }}>
          {translation("gb_sub_title_triggers")}
        </h4>
        <div className="row">
          <ProgramTriggersTable {...props} />
        </div>
      </div>
    </div>
  );
};

const PeopleTabView = (props) => {
  return (
    <div className="tab-content" style={{ margin: "0px" }}>
      <div className="col-xs-12">
        <h4 style={{ marginBottom: "0px" }}>
          {translation("gb_sub_title_hierarchy")}
        </h4>
        <div className="row">
          <RoleBonusesTable {...props} />
        </div>
      </div>
      <div className="col-xs-12 col-lg-8">
        <h4 style={{ marginBottom: "0px" }}>
          {translation("gb_sub_title_participants")}
        </h4>
        <div className="row">
          <ParticipantsTable {...props} />
        </div>
      </div>
      <div className="col-xs-12 col-lg-4">
        <h4 style={{ marginBottom: "0px" }}>
          {translation("gb_sub_title_teams")}
        </h4>
        <div className="row">
          <TeamsConfigTable {...props} />
        </div>
      </div>
    </div>
  );
};

const GoalsTabView = (props) => {
  return (
    <div className="tab-content" style={{ margin: "0px" }}>
      <div className="col-xs-12">
        <h4 style={{ marginBottom: "0px" }}>
          {translation("gb_sub_title_goals")}
        </h4>
        <div className="row">
          <GoalsTable {...props} />
        </div>
      </div>
    </div>
  );
};

const Administration = (props) => {
  const {
    selectedTool = {},
    selectedCompany,
    updateGoalBonusAdministration,
    allGoalBonusAdministration,
    isLoading,
  } = props;

  const { toolHistory, goalBonusAdministration, setGoalBonusAdministration } =
    useHistoryToolAdministration({
      selectedTool,
      allGoalBonusAdministration,
      isLoading,
    });

  const { haveChanges } = useCompareChanges({
    toolAdministration: goalBonusAdministration,
    toolHistory,
    isLoading,
  });
  const [confirmationDialog, setConfirmationDialog] = useState({
    ...defaultConfirmDialog,
  });
  const [openFormDialogs, setOpenFormDialogs] = useState([]);
  const [editingForm, setEditingForm] = useState(defaultEditingForm);
  const [activeTab, setActiveTab] = useState("configTab");

  const { id: selectedCompanyId } = selectedCompany;
  const { periodCycle = {} } = goalBonusAdministration;

  useEffect(() => {
    if (selectedCompanyId) {
      props.fetchAllUsersCompanyProfiles(selectedCompanyId);
      props.fetchCompanyNestedAreas(selectedCompanyId);
      props.fetchCompanyNestedPositions(selectedCompanyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanyId]);

  const getToolConfig = async () => {
    if (selectedTool && selectedTool.id) {
      const { data } = await getGoalBonusConfiguration(selectedTool.id);

      if (data && data.id) {
        const fetchedConfiguration = await buildGoalsAdministrationFinalBody(
          data,
        );

        return fetchedConfiguration;
      }
    }

    return {};
  };

  const handleSubmitAdminConfig = (newGoalsBonusConfig) => {
    const validNewGoalsBonusConfig =
      newGoalsBonusConfig.id || newGoalsBonusConfig.id === null
        ? newGoalsBonusConfig
        : goalBonusAdministration;

    const saveGoalsBonusConfig = _.cloneDeep(validNewGoalsBonusConfig);

    if (selectedTool?.id) {
      const restTrasformedBody = buildRestAdmConfigBody(saveGoalsBonusConfig);
      const formTransformedBody = buildFormAdmConfigBody(saveGoalsBonusConfig);
      updateGoalBonusAdministration(selectedTool.id, restTrasformedBody);
      setGoalBonusAdministration(formTransformedBody);
    }
  };

  const saveCurrentConfig = () => {
    if (haveChanges) handleSubmitAdminConfig(goalBonusAdministration);
  };

  const validateAdministrationErrors = (newGoalsBonusConfig) => {
    const errors = [];

    const addError = (slug, message) => {
      errors.push({ slug, message });
    };

    const { dates = {} } = newGoalsBonusConfig;

    const programDatesTranslation = translation("gb_sub_title_dates");

    const getDateError = (translationId) =>
      translation("gb_error_invalid_period_date", {
        period: translation(translationId),
      });

    const getConflictError = (date1, date2) =>
      translation("gb_error_dates_conflict", {
        date1: translation(date1),
        date2: translation(date2),
      });

    if (dates.year < 1900) {
      addError(
        "invalidYear",
        `${programDatesTranslation} - ${translation("gb_error_invalid_year")}`,
      );
    }

    if (periodCycle.start === periodCycle.end)
      addError("sameMonthError", translation("kpi_error_invalid_same_month"));

    const registrationsErrorStart =
      !dates.startRegistrations || !moment(dates.startRegistrations).isValid();
    const registrationsErrorEnd =
      !dates.endRegistrations || !moment(dates.endRegistrations).isValid();

    if (registrationsErrorStart) {
      addError(
        "invalidStartRegistrations",
        `${programDatesTranslation} - ${getDateError(
          "gb_table_text_start_registrations",
        )}`,
      );
    }

    if (registrationsErrorEnd) {
      addError(
        "invalidEndRegistrations",
        `${programDatesTranslation} - ${getDateError(
          "gb_table_text_end_registrations",
        )}`,
      );
    }

    if (
      !registrationsErrorStart &&
      !registrationsErrorEnd &&
      moment(dates.startRegistrations).isSameOrAfter(dates.endRegistrations)
    ) {
      addError(
        "dateConflictRegistrations",
        `${programDatesTranslation} - ${getConflictError(
          "gb_table_text_start_registrations",
          "gb_table_text_end_registrations",
        )}`,
      );
    }

    const approvalErrorStart =
      !dates.startApproval || !moment(dates.startApproval).isValid();
    const approvalErrorEnd =
      !dates.endApproval || !moment(dates.endApproval).isValid();

    if (approvalErrorStart) {
      addError(
        "invalidStartApproval",
        `${programDatesTranslation} - ${getDateError(
          "gb_table_text_start_approval",
        )}`,
      );
    }

    if (approvalErrorEnd) {
      addError(
        "invalidEndApproval",
        `${programDatesTranslation} - ${getDateError(
          "gb_table_text_end_approval",
        )}`,
      );
    }

    if (
      !approvalErrorStart &&
      !approvalErrorEnd &&
      moment(dates.startApproval).isSameOrAfter(dates.endApproval)
    ) {
      addError(
        "dateConflictApproval",
        `${programDatesTranslation} - ${getConflictError(
          "gb_table_text_start_approval",
          "gb_table_text_end_approval",
        )}`,
      );
    }

    const auditErrorStart =
      !dates.startAudit || !moment(dates.startAudit).isValid();
    const auditErrorEnd = !dates.endAudit || !moment(dates.endAudit).isValid();

    if (auditErrorStart) {
      addError(
        "invalidStartAudit",
        `${programDatesTranslation} - ${getDateError(
          "gb_table_text_start_audit",
        )}`,
      );
    }

    if (auditErrorEnd) {
      addError(
        "invalidEndAudit",
        `${programDatesTranslation} - ${getDateError(
          "gb_table_text_end_audit",
        )}`,
      );
    }

    if (
      !auditErrorStart &&
      !auditErrorEnd &&
      moment(dates.startAudit).isSameOrAfter(dates.endAudit)
    ) {
      addError(
        "dateConflictAudit",
        `${programDatesTranslation} - ${getConflictError(
          "gb_table_text_start_audit",
          "gb_table_text_end_audit",
        )}`,
      );
    }

    if (Array.isArray(goalBonusAdministration.participants)) {
      let haveNoRoleSelected = false;

      goalBonusAdministration.participants.forEach((participantInfo) => {
        const noRole =
          !participantInfo.roleBonus || !participantInfo.roleBonus.id;

        if (!haveNoRoleSelected && noRole) haveNoRoleSelected = true;
      });

      if (haveNoRoleSelected)
        addError("noRoleSelected", translation("gb_error_no_role_selected"));
    }

    return errors;
  };

  const participantPeriodOptions = getParticipantPeriodOptions();
  const pageAccessRulesOptions = getPageAccessRulesOptions();
  const goalValidationStatusOptions = getGoalValidationStatusOptions();
  const relationshipTypeOptions = getRelationshipTypeOptions();

  const administrationErrors = validateAdministrationErrors(
    goalBonusAdministration,
  );

  const handleAutoSave = (newGoalsBonusConfig) => {
    if (newGoalsBonusConfig) handleSubmitAdminConfig(newGoalsBonusConfig);
  };

  const resetConfirmDialog = () => {
    setConfirmationDialog({ ...defaultConfirmDialog });
  };

  const resetEditingDialog = () => {
    setEditingForm({ ...defaultEditingForm });
  };

  const toggleOpenFormDialogs = (slug, toggleTo = null) => {
    if (slug) {
      const slugIndex = openFormDialogs.indexOf(slug);
      let updatedOpenFormDialogs = [...openFormDialogs];

      const closeOpenForm = (index) => {
        return getUpdatedDeleteArrayByIndex(openFormDialogs, index);
      };

      const open = () => {
        updatedOpenFormDialogs.push(slug);
        saveCurrentConfig();
      };

      if (slugIndex > -1 || (!toggleTo && slugIndex > -1)) {
        updatedOpenFormDialogs = closeOpenForm(slugIndex);
      } else {
        open();
      }

      setOpenFormDialogs(updatedOpenFormDialogs);
    }
  };

  const closeAllDialogs = () => {
    setOpenFormDialogs([]);
    resetConfirmDialog();
    resetEditingDialog();
  };

  const closeFormAndCallback = (slug, callback) => {
    toggleOpenFormDialogs(slug, false);

    if (typeof callback === "function") callback();
  };

  const handleSetEditingForm = (dialogSlug, params = {}) => {
    setEditingForm({
      type: dialogSlug,
      params,
    });

    toggleOpenFormDialogs(dialogSlug);
  };

  const checkSaveButton = () => {
    if (isLoading || !haveChanges || administrationErrors.length > 0) {
      return false;
    }

    return true;
  };

  const essencialProps = {
    goalBonusAdministration,
    setGoalBonusAdministration,
    administrationErrors,
    openFormDialogs,
    setOpenFormDialogs,
    setConfirmationDialog,
    resetConfirmDialog,
    getDefaultTargetCols,
    toggleOpenFormDialogs,
    participantPeriodOptions,
    pageAccessRulesOptions,
    goalValidationStatusOptions,
    relationshipTypeOptions,
    companyMembersAndParticipants: props.companyMembersAndParticipants,
    editingForm,
    handleSetEditingForm,
    resetEditingDialog,
    closeFormAndCallback,
    handleAutoSave,
    allSceneries: props.allSceneries,
    allThemes: props.allThemes,
    allQuestions: props.allQuestions,
    allAnswers: props.allAnswers,
    getToolConfig,
    closeAllDialogs,
  };

  const classes = useAdministrationStyles();
  const saveButtonEnabled = checkSaveButton() || false;

  return (
    <div className="row" style={{ paddingBottom: "100px" }}>
      <Fab
        className={classes.saveButton}
        disabled={!saveButtonEnabled}
        onClick={
          saveButtonEnabled
            ? () => handleSubmitAdminConfig(goalBonusAdministration)
            : () => {}
        }
      >
        <CheckIcon />
      </Fab>
      {confirmationDialog.open && confirmationDialog.title && (
        <ConfirmationDialog
          open={confirmationDialog.open}
          title={confirmationDialog.title}
          description={confirmationDialog.description}
          onConfirm={confirmationDialog.onConfirm}
          confirmText={confirmationDialog.confirmText}
          cancelText={confirmationDialog.cancelText}
          onCancel={confirmationDialog.onCancel}
          hideCancel={confirmationDialog.hideCancel}
          confirmColor="#ff3151"
        />
      )}
      <DisplayOpenForms essencialProps={essencialProps} />
      <div className="col-xs-12">
        <h2 style={{ color: "#6b42a9", paddingBottom: "10px" }}>
          {translation("gb_tab_administration_title")}
        </h2>
      </div>
      <div className="custom-primary-tabs-adm">
        <Tabs
          id="administrationTabs"
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(key)}
          style={{ marginTop: "15px" }}
        >
          <Tab
            eventKey="configTab"
            title={translation("gb_tab_administration_config")}
          >
            {activeTab === "configTab" && (
              <ConfigurationsTabView {...essencialProps} />
            )}
          </Tab>
          <Tab
            eventKey="rolesTab"
            title={translation("gb_tab_administration_involved")}
          >
            {activeTab === "rolesTab" && <PeopleTabView {...essencialProps} />}
          </Tab>
          <Tab
            eventKey="goalsTab"
            title={translation("gb_tab_administration_goals")}
          >
            {activeTab === "goalsTab" && <GoalsTabView {...essencialProps} />}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { selectedCompany } = getSelectedCompanyStates(state);
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);

  const {
    selectedTool,
    allSceneries = [],
    allThemes = {},
    allQuestions = {},
    allAnswers = {},
  } = getSelectedToolStates(state, {
    sceneries: false,
    themes: false,
    questions: false,
    answers: false,
  });
  const { allGoalBonusAdministration, isLoading } = getGoalsBonusStates(state);

  return {
    companyMembersAndParticipants,
    selectedCompany,
    selectedTool,
    allGoalBonusAdministration,
    isLoading,
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
  };
};

export default connect(mapStateToProps, {
  updateGoalBonusAdministration,
  fetchAllUsersCompanyProfiles,
  fetchCompanyNestedAreas,
  fetchCompanyNestedPositions,
})(Administration);
