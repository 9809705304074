import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";

const ButtonPdf = styled(Button)`
  position: absolute !important;
  top: -35px !important;
  right: 15px !important;
  font-size: 13px !important;
  border: 1px solid #ccc !important;
`;

const ButtonGeneratePdf = () => {
  function generatePdf() {
    window.print();
  }

  return (
    <ButtonPdf onClick={generatePdf} className="hidden-print">
      <i className="far fa-file-pdf" style={{ marginRight: "5px" }} />
      PDF
    </ButtonPdf>
  );
};

export default ButtonGeneratePdf;
