import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";
import { injectIntl } from "react-intl";

import { TdRegister } from "../css/OkrCss";
import utils from "../../../../../../utils/toolUtils";

const { getObjectInfoById } = utils;

const defaultFilter = {
  attr: "title",
  order: "ASC",
};

const PlanRegister = (props) => {
  const [orderedPlans, setOrderedPlans] = useState([]);
  const [filter, setFilter] = useState(defaultFilter);

  const {
    getDefaultAddTableLine,
    handleOpenModal,
    getDefaultRemoveColumn,
    openFormDelete,
    okrPlans,
    deletePlan,
    intl,
    allSceneries,
  } = props;

  useEffect(() => {
    const ord1 = filter.order === "ASC" ? 1 : -1;
    const ord2 = filter.order === "ASC" ? -1 : 1;

    let newOrdered = okrPlans;

    if (filter.attr === "title") {
      newOrdered = [...okrPlans].sort((a, b) => {
        const titleA = a.organization?.title || "";
        const titleB = b.organization?.title || "";

        const titleNumberA = titleA.match(/\d+/);
        const titleNumberb = titleB.match(/\d+/);

        if (titleNumberA && titleNumberb) {
          return titleNumberA[0] - titleNumberb[0];
        }

        if (titleA.toUpperCase() > titleB.toUpperCase()) {
          return ord1;
        }

        if (titleA.toUpperCase() < titleB.toUpperCase()) {
          return ord2;
        }

        return 0;
      });
    }

    setOrderedPlans(newOrdered);
  }, [okrPlans, filter]);

  const getNewOrder = (currentOrder) => {
    if (currentOrder === "ASC") return "DESC";
    return "ASC";
  };

  const handleFilter = (attr) => {
    let updatedFilter = defaultFilter;

    if (attr === filter.attr) {
      updatedFilter = {
        attr,
        order: getNewOrder(filter.order),
      };
    } else {
      updatedFilter = { ...defaultFilter, attr };
    }

    setFilter(updatedFilter);
  };

  const orderButton = ({ attr }) => {
    return (
      <button
        className="btn btn-data-sort grow"
        onClick={() => handleFilter(attr)}
        style={{ padding: "0px 5px", margin: "0px 0px 0px 10px" }}
      >
        <i className="fas fa-caret-up" />
        <i className="fas fa-caret-down" />
      </button>
    );
  };

  const getRelateScenerie = (planId) => {
    const objectPlan = getObjectInfoById(
      planId,
      allSceneries,
      "okrConfigurationPlanId",
    );

    const relatedScenerie = !!(objectPlan && objectPlan.id);

    const setDeleteArea = {
      handleDelete(id) {
        return !relatedScenerie ? deletePlan(id) : null;
      },

      getOpen() {
        return true;
      },

      getTitle() {
        return intl.formatMessage({ id: "okr_confirm_delete_cenary" });
      },

      getDescription() {
        return relatedScenerie
          ? intl.formatMessage(
              {
                id: "okr_warning_need_arquive_cenary",
              },
              { cenaryName: objectPlan.name },
            )
          : null;
      },
    };

    return setDeleteArea;
  };

  return (
    <div>
      <h4>{intl.formatMessage({ id: "team_table_title" })}</h4>
      <table className="simple-table">
        <thead>
          <tr>
            <td>
              {intl.formatMessage({ id: "global.sceneries" })}
              {orderButton({ attr: "title" })}
            </td>
            <td colSpan={3}>
              {intl.formatMessage({ id: "global.descriptions" })}
            </td>
          </tr>
        </thead>
        <tbody>
          {!!orderedPlans.length &&
            orderedPlans.map((plan) => {
              return (
                <tr key={plan.id}>
                  <TdRegister
                    width="50%"
                    cursor="pointer"
                    onClick={() =>
                      handleOpenModal("planModal", true, {
                        type: "planModal",
                        params: { plan },
                      })
                    }
                  >
                    <Tooltip
                      title={plan.organization ? plan.organization.title : ""}
                      placement="bottom-start"
                    >
                      <span>
                        {plan.organization && plan.organization.title}
                      </span>
                    </Tooltip>
                  </TdRegister>
                  <TdRegister
                    cursor="pointer"
                    onClick={() =>
                      handleOpenModal("planModal", true, {
                        type: "planModal",
                        params: { plan },
                      })
                    }
                  >
                    <Tooltip
                      title={plan.proposal || ""}
                      placement="bottom-start"
                    >
                      <span>{plan.proposal && plan.proposal}</span>
                    </Tooltip>
                  </TdRegister>
                  {getDefaultRemoveColumn &&
                    getDefaultRemoveColumn(() =>
                      openFormDelete(plan.id, getRelateScenerie(plan.id)),
                    )}
                </tr>
              );
            })}
          {getDefaultAddTableLine(() => handleOpenModal("planModal", true))}
        </tbody>
      </table>
    </div>
  );
};

export default injectIntl(PlanRegister);

PlanRegister.propTypes = {
  getDefaultAddTableLine: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  getDefaultRemoveColumn: PropTypes.func.isRequired,
  openFormDelete: PropTypes.func.isRequired,
  deletePlan: PropTypes.func.isRequired,
  allSceneries: PropTypes.array.isRequired,
  okrPlans: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
};
