import React, { useState, useEffect } from "react";
import _ from "lodash";
import { injectIntl, defineMessages } from "react-intl";
import utils from "../../../../utils/toolUtils";
import kpiUtils from "../../../../utils/kpis";
import GoalListItem from "../../GoalListItem";
import {
  getRelationshipTypeOptions,
  defaultGoalsDisplayOrder,
} from "../../../../constants/kpis";
import Avatar from "../../Avatar";
import { getSingleIndicatorResults } from "../../../../utils/kpis/calc2";

const { getObjectInfoById, reorderObjectBySlugs } = utils;

const {
  exceededTargetMaxValue,
  getHitTargetInfo,
  getIndicatorPopover,
} = kpiUtils;

const messages = defineMessages({
  noDataToDisplay: {
    id: "global.noDataTable",
  },
  kpi_table_text_indicators_upper: {
    id: "kpi_table_text_indicators_upper",
  },
  kpi_options_rt_shared: {
    id: "kpi_options_rt_shared",
  },
});

const IndicatorsEvaluations = (props) => {
  const [reorderedIndicators, setReorderedIndicators] = useState([]);

  const relationshipTypeOptions = getRelationshipTypeOptions();
  const {
    goals = [],
    displayProcesses = [],
    kpiAdministration = {},
    intl,
    selectAndOpenInsight,
    isPeriodFilterActive: filter,
  } = props;
  const { goalTargets = {}, zeroWhenNegative } = kpiAdministration;

  useEffect(() => {
    if (Array.isArray(goals)) {
      const newReordered = reorderObjectBySlugs(
        defaultGoalsDisplayOrder,
        goals,
        "relationshipType",
      );

      if (!_.isEqual(reorderedIndicators, newReordered))
        setReorderedIndicators(newReordered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goals]);

  return (
    <div className="evaluation-card">
      <div className="card-header">
        <div className="card-title">
          <h4>
            {intl.formatMessage(messages.kpi_table_text_indicators_upper)}
          </h4>
        </div>
      </div>
      <div style={{ padding: "20px 0px 20px 15px" }}>
        <div className="card-body max-height-800" style={{ padding: "0px" }}>
          <div className="right-spacing">
            {reorderedIndicators.map((goalInfo, index) => {
              const {
                goalPercentage,
                notStarted,
                accumulated,
              } = getSingleIndicatorResults(
                goalInfo,
                kpiAdministration.goalTargets,
                kpiAdministration.zeroWhenNegative,
              );

              const displayReach = filter
                ? accumulated.goalPercentage
                : goalPercentage;

              const hitTargetInfo = getHitTargetInfo(
                displayReach,
                kpiAdministration.goalTargets,
                notStarted,
              );

              const { label = null } = getObjectInfoById(
                goalInfo.relationshipType,
                relationshipTypeOptions,
                "value",
              );
              const { processes = [] } = goalInfo;

              const isConsolidated = goalInfo.relationshipType === "SHARED";

              const goalTitle = (
                <span style={isConsolidated ? { fontWeight: "500" } : {}}>
                  {isConsolidated && (
                    <i
                      className="fas fa-stream"
                      style={{
                        color: "#ffc875",
                        fontSize: "17px",
                        paddingRight: "7px",
                        position: "relative",
                        top: "1px",
                      }}
                      title={intl.formatMessage(messages.kpi_options_rt_shared)}
                    />
                  )}
                  {goalInfo.title}
                </span>
              );

              return (
                <GoalListItem
                  title={goalTitle}
                  isConsolidated
                  description={label}
                  currentPercentage={displayReach}
                  notStarted={notStarted}
                  maxPercentage={exceededTargetMaxValue(goalTargets.exceeded)}
                  progressColor={hitTargetInfo.color}
                  barTotalWidth={100}
                  popover={getIndicatorPopover(
                    goalInfo,
                    processes,
                    displayProcesses,
                    goalTargets,
                    zeroWhenNegative,
                  )}
                  openInsight={() => selectAndOpenInsight(goalInfo)}
                  key={index}
                  avatar={
                    <Avatar
                      src={goalInfo.avatarId}
                      name={goalInfo.responsibleName}
                      style={{
                        width: "30px",
                        height: "30px",
                        marginLeft: "10px",
                      }}
                    />
                  }
                />
              );
            })}
            {reorderedIndicators.length === 0 && (
              <h5 align="center">
                {intl.formatMessage(messages.noDataToDisplay)}
              </h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(IndicatorsEvaluations);
