const en = {
  vca_main_title: "Value Curve Analysis",
};

const es = {
  vca_main_title: "Análisis de curvas de valor",
};

const pt = {
  vca_main_title: "Análise da Curva de Valor",
};

const translations = { en, es, pt };

export default translations;
