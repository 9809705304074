import React from "react";
import NineBoxEvaluation from "../../../../../Common/NineBoxEvaluation";

const LeadingField = (props) => {
  const { filteredEvaluations, messages, intl } = props;

  const displayNineBoxEvaluation = () => {
    let evaluation = {
      HIGH_POTENTIAL: 0,
      HIGH_PERFORMANCE: 0,
      GOOD_POTENTIAL: 0,
      MEDIUM_PERFORMANCE: 0,
      GROWING: 0,
      EFFECTIVE: 0,
      NEW: 0,
      LOW_PERFORMANCE: 0,
      OUTPUT_POSITION: 0,
    };

    filteredEvaluations.forEach(({ peopleEvaluation }) => {
      const { evaluationTarget } = peopleEvaluation;
      const { lastEvaluations } = evaluationTarget;
      const { leading } = lastEvaluations;

      if (leading.current) {
        evaluation[leading.current] = evaluation[leading.current] + 1;
      }
    });

    const nineBox = [
      {
        label: "6",
        value: evaluation.EFFECTIVE,
        color: "#5df0a3",
      },
      {
        label: "3",
        value: evaluation.GOOD_POTENTIAL,
        color: "#3ab361",
      },
      {
        label: "1",
        value: evaluation.HIGH_POTENTIAL,
        color: "#0b8b34",
      },
      {
        label: "8",
        value: evaluation.LOW_PERFORMANCE,
        color: "#f0f579",
      },
      {
        label: "5",
        value: evaluation.GROWING,
        color: "#5df0a3",
      },
      {
        label: "2",
        value: evaluation.HIGH_PERFORMANCE,
        color: "#3ab361",
      },
      {
        label: "9",
        value: evaluation.OUTPUT_POSITION,
        color: "#ffc736",
      },
      { label: "7", value: evaluation.NEW, color: "#f0f579" },
      {
        label: "4",
        value: evaluation.MEDIUM_PERFORMANCE,
        color: "#5df0a3",
      },
    ];

    return (
      <NineBoxEvaluation
        data={nineBox}
        legendRight={intl.formatMessage(messages.labels_potential)}
        legendBottom={intl.formatMessage(messages.labels_performance)}
        styles={{ marginTop: "30px" }}
      />
    );
  };

  return (
    <div className="evaluation-card">
      <div className="card-header">
        <div className="card-title">
          <h4>{intl.formatMessage(messages.titles_cards_leading)}</h4>
        </div>
      </div>
      <div className="card-body" style={{ height: "325px" }}>
        {displayNineBoxEvaluation()}
      </div>
    </div>
  );
};

export default LeadingField;
