import { getFilteredObjectsList, getObjectInfoById } from "../ArrayUtils";
import { checkGreaterThan, getToFixed, ruleOfThree } from "../MathUtils";
import {
  getEvaluationConfigByType,
  getSuccessionEvaluationInfo,
  getValidationFieldsForWorkflowStage,
  minimumRoleSkillRelationFactor,
} from "./constants";
import {
  defaultEvaluatedWorkflowStages,
  getDefaultEvaluationsAverage,
  getDefaultReadinessOptions,
  getDefaultSuccessionBoolOptions,
} from "./defaults";
import {
  getComplementaryScalesByType,
  getFilteredSkillRoleRelation,
  getParticipantComplementaryWorkflow,
  getParticipantWorkflow,
  getWorkflowValueBySuccession,
  groupParticipantsByEvaluationStage,
  groupWorkflowByCompetenceAreas,
  groupWorkflowByParticipants,
  groupWorkflowByProfiles,
  groupWorkflowBySkills,
} from "./filter";
import { orderScale } from "./manipulation";
import { checkEvaluationValues } from "./validation";

export const getLastScaleValue = (orderedScale) => {
  if (Array.isArray(orderedScale) && orderedScale.length > 0) {
    return orderedScale[orderedScale.length - 1].value;
  }

  return 0;
};

export const getMaximumScale = (scale) => {
  const orderedScale = orderScale(scale);
  return getLastScaleValue(orderedScale);
};

export const getMinimumScale = (scale) => {
  const orderedScale = orderScale(scale).reverse();
  return getLastScaleValue(orderedScale);
};

export const getMaximumComplementaryScale = (complementaryScales, type) => {
  const filtered = getComplementaryScalesByType(complementaryScales, type);
  const orderedScale = orderScale(filtered);

  return getLastScaleValue(orderedScale);
};

export const getMinimumComplementaryScale = (complementaryScales, type) => {
  const filtered = getComplementaryScalesByType(complementaryScales, type);
  const orderedScale = orderScale(filtered).reverse();

  return getLastScaleValue(orderedScale);
};

export const getAverageSkillDesired = (
  skillId,
  roleSkillRelation,
  ignoreZeros = true
) => {
  let totalSum = 0;
  let count = 0;

  const skillRelations = getFilteredSkillRoleRelation(
    roleSkillRelation,
    skillId
  );

  if (Array.isArray(skillRelations)) {
    skillRelations.forEach(({ desiredEvaluation }) => {
      const ignoreNull =
        ignoreZeros && (desiredEvaluation < -1 || desiredEvaluation === 0);

      if (!ignoreNull) {
        totalSum += desiredEvaluation;
        count++;
      }
    });
  }

  return totalSum > 0 ? totalSum / count : 0;
};

export const getWeightsMultiplier = (evaluationWeights) => {
  const { manager, secondEvaluator, thirdEvaluator, selfEvaluation } =
    evaluationWeights || {};

  return {
    selfEvaluation: selfEvaluation * 0.01,
    manager: manager * 0.01,
    secondEvaluator: secondEvaluator * 0.01,
    thirdEvaluator: thirdEvaluator * 0.01,
  };
};

export const calcWorkflowAverageByWeights = (
  workflowInfo,
  evaluationType,
  evaluationWeights
) => {
  const { selfEvaluation, managerEvaluation, secondEvaluator, thirdEvaluator } =
    workflowInfo;

  const { enableWeights } = getEvaluationConfigByType(evaluationType);

  if (enableWeights) {
    const weights = getWeightsMultiplier(evaluationWeights);

    const sfEvaluation = selfEvaluation * weights.selfEvaluation;
    const mEvaluation = managerEvaluation * weights.manager;
    const secEvaluation = secondEvaluator * weights.secondEvaluator;
    const tEvaluation = thirdEvaluator * weights.thirdEvaluator;

    const finalAvarage =
      parseFloat(sfEvaluation) +
      parseFloat(mEvaluation) +
      parseFloat(secEvaluation) +
      parseFloat(tEvaluation);

    return getToFixed(finalAvarage, 1);
  }

  return managerEvaluation;
};

const getDesiredEvaluation = (skillId, roleSkillRelations, companyPosition) => {
  const filteredRoleSkillRelations = getFilteredSkillRoleRelation(
    roleSkillRelations,
    skillId
  );

  const roleSkillRelation = filteredRoleSkillRelations.find(
    ({ companyRole }) => companyRole.id === companyPosition.id
  );

  return roleSkillRelation?.desiredEvaluation;
};

export const getWorkflowAverage = (
  workflow,
  roleSkillRelation,
  evaluationType,
  evaluationWeights,
  replaceNull = "N/A",
  invalidDesired = true
) => {
  const averages = getDefaultEvaluationsAverage(null);
  const normalizedAverages = getDefaultEvaluationsAverage(null);
  const slugsCount = getDefaultEvaluationsAverage(0);

  let generalCount = 0;
  let desiredCount = 0;

  if (Array.isArray(workflow)) {
    workflow.forEach((workflowInfo) => {
      const { skill, participant } = workflowInfo;

      const desiredEvaluation = getDesiredEvaluation(
        skill.id,
        roleSkillRelation,
        participant.companyPosition
      );

      const updatedWorkflow = workflowInfo;

      updatedWorkflow.averageEvaluation = calcWorkflowAverageByWeights(
        workflowInfo,
        evaluationType,
        evaluationWeights
      );

      const notCompletedStage =
        defaultEvaluatedWorkflowStages.indexOf(participant.evaluationStage) ===
        -1;

      Object.keys(updatedWorkflow).forEach((slug) => {
        if (checkGreaterThan(updatedWorkflow[slug], -1)) {
          const nullEvaluation =
            slug === "averageEvaluation" && notCompletedStage;

          if (!updatedWorkflow[slug] || nullEvaluation) return;
          if (!averages[slug]) averages[slug] = 0;
          averages[slug] += parseFloat(updatedWorkflow[slug]);
          slugsCount[slug]++;
        }
      });

      generalCount++;

      if (
        (invalidDesired && checkGreaterThan(desiredEvaluation, 0)) ||
        !invalidDesired
      ) {
        const toZero =
          !invalidDesired && !checkGreaterThan(desiredEvaluation, 0);
        const validDesired = toZero ? 0 : desiredEvaluation;

        averages.desiredEvaluation += validDesired;
        desiredCount++;
      }
    });
  }

  Object.keys(averages).forEach((slug) => {
    if (slug !== "desiredEvaluation" && checkGreaterThan(averages[slug], -1)) {
      if (!averages[slug]) {
        normalizedAverages[slug] = replaceNull;
        return;
      }
      normalizedAverages[slug] = Number(
        getToFixed(averages[slug] / slugsCount[slug], 2)
      );
    }
  });

  normalizedAverages.desiredEvaluation = Number(
    getToFixed(averages.desiredEvaluation / desiredCount, 2)
  );

  return generalCount > 0
    ? { average: normalizedAverages, evaluated: true }
    : { average: getDefaultEvaluationsAverage(replaceNull), evaluated: false };
};

export const getAverageWithGap = (average) => {
  const { averageEvaluation = 0, desiredEvaluation = 0 } = average;

  if (!checkGreaterThan(averageEvaluation, -1))
    return { ...average, gap: "N/A" };

  const gap = averageEvaluation / desiredEvaluation - 1;

  return {
    ...average,
    gap: gap === 0 ? 0 : gap * 100,
  };
};

export const calcGroupedWorkflow = (
  groupedWorkflow = [],
  roleSkillRelation = [],
  evaluationType,
  evaluationWeights
) => {
  const groupedAverage = [];

  groupedWorkflow.forEach((groupedWorkflowInfo) => {
    const { average, evaluated } = getWorkflowAverage(
      groupedWorkflowInfo.workflow,
      roleSkillRelation,
      evaluationType,
      evaluationWeights
    );

    const averageWithGap = getAverageWithGap(average);

    groupedAverage.push({
      ...groupedWorkflowInfo,
      workflowAverage: averageWithGap,
      evaluated,
    });
  });

  return groupedAverage;
};

export const getSkillsWorkflowAverage = ({
  skills,
  competenceAreas,
  participants,
  workflow,
  roleSkillRelation,
  evaluationType,
  evaluationWeights,
}) => {
  const workflowBySkills = groupWorkflowBySkills(
    skills,
    competenceAreas,
    participants,
    workflow
  );

  const skillsAverage = calcGroupedWorkflow(
    workflowBySkills,
    roleSkillRelation,
    evaluationType,
    evaluationWeights
  );

  return { skillsAverage, workflowBySkills };
};

export const getCompetencesWorkflowAverage = ({
  skills = [],
  competenceAreas = [],
  participants = [],
  workflow = [],
  roleSkillRelation = [],
  evaluationType,
  evaluationWeights,
}) => {
  const workflowByCompetenceAreas = groupWorkflowByCompetenceAreas(
    skills,
    competenceAreas,
    participants,
    workflow
  );

  const competencesAverage = calcGroupedWorkflow(
    workflowByCompetenceAreas,
    roleSkillRelation,
    evaluationType,
    evaluationWeights
  );

  return { competencesAverage, workflowByCompetenceAreas };
};

export const getProfilesWorkflowAverage = ({
  skills = [],
  profiles = [],
  competenceAreas = [],
  participants = [],
  workflow = [],
  roleSkillRelation = [],
  evaluationType,
  evaluationWeights,
}) => {
  const workflowByProfiles = groupWorkflowByProfiles(
    skills,
    profiles,
    competenceAreas,
    participants,
    workflow
  );

  const profilesAverage = calcGroupedWorkflow(
    workflowByProfiles,
    roleSkillRelation,
    evaluationType,
    evaluationWeights
  );

  return { profilesAverage, workflowByProfiles };
};

export const getParticipantsWorkflowAverage = (
  toolAdministration,
  participants
) => {
  const { roleSkillRelation, evaluationType, evaluationWeights } =
    toolAdministration;

  const workflowByParticipants = groupWorkflowByParticipants(
    toolAdministration,
    participants
  );

  const participantsAverage = calcGroupedWorkflow(
    workflowByParticipants,
    roleSkillRelation,
    evaluationType,
    evaluationWeights
  );

  return { participantsAverage, workflowByParticipants };
};

export const countEvaluatedParticipants = (
  toolAdministration = {},
  participants = []
) => {
  let total = 0;
  let evaluated = 0;

  const { participantsAverage } = getParticipantsWorkflowAverage(
    toolAdministration,
    participants
  );

  if (Array.isArray(participantsAverage))
    participantsAverage.forEach(({ workflowAverage }) => {
      const { averageEvaluation } = workflowAverage;
      if (checkGreaterThan(averageEvaluation, 0)) evaluated++;
      total++;
    });

  return { evaluated, total };
};

export const getTotalWorkflowAverage = (
  workflowBySkills,
  roleSkillRelation,
  evaluationType,
  evaluationWeights
) => {
  const onlyWorkflows = [];

  if (Array.isArray(workflowBySkills)) {
    workflowBySkills.forEach(({ workflow }) => {
      workflow.forEach((workflowInfo) => onlyWorkflows.push(workflowInfo));
    });
  }

  const { average, evaluated } = getWorkflowAverage(
    onlyWorkflows,
    roleSkillRelation,
    evaluationType,
    evaluationWeights
  );

  return { average: getAverageWithGap(average), evaluated };
};

export const getRoleSkillMinimumRelations = (roleSkillRelation) => {
  const totalRelations = roleSkillRelation.length;
  const minimum =
    totalRelations > 0 ? totalRelations * minimumRoleSkillRelationFactor : 0;

  const notEvaluatedOnes = getFilteredObjectsList(
    [0],
    roleSkillRelation,
    "desiredEvaluation"
  );

  const totalEvaluated = totalRelations - notEvaluatedOnes.length;

  const evaluationPercentage = ruleOfThree(totalRelations, totalEvaluated, 100);

  const isValid = totalEvaluated >= minimum;

  return { minimum, percentage: evaluationPercentage, isValid };
};

export const groupAndCalcParticipantStages = (
  participants,
  toolAdministration
) => {
  const { grouped: groupedByStage, total } = groupParticipantsByEvaluationStage(
    participants,
    toolAdministration.evaluationConfig
  );
  const finalGrouped = {};

  Object.keys(groupedByStage).forEach((stageValue) => {
    const participantsOfStage = groupedByStage[stageValue];

    const { workflow: wFields, complementary: cwFields } =
      getValidationFieldsForWorkflowStage(
        stageValue,
        toolAdministration.evaluationType,
        toolAdministration.evaluationConfig
      );

    const addParticipants = [];
    let validCount = 0;
    let invalidCount = 0;

    participantsOfStage.forEach((participantInfo) => {
      const workflow = getParticipantWorkflow(
        toolAdministration,
        participantInfo
      );
      const complementaryWorkflow = getParticipantComplementaryWorkflow(
        toolAdministration.complementaryWorkflow,
        participantInfo
      );

      const errors = checkEvaluationValues(workflow, wFields);
      const errorsComplementary = checkEvaluationValues(
        complementaryWorkflow,
        cwFields
      );
      const isValid = [...errors, ...errorsComplementary].length === 0;

      addParticipants.push({ ...participantInfo, workflow, isValid });

      if (isValid) validCount++;
      if (isValid === false) invalidCount++;
    });

    finalGrouped[stageValue] = {
      participants: addParticipants,
      validCount,
      invalidCount,
    };
  });

  return { ...finalGrouped, total };
};

export const getParticipantCalculatedWorkflow = (
  toolAdministration,
  participant
) => {
  const calculatedWorkflow = [];

  const pureFilteredWorkflow = getParticipantWorkflow(
    toolAdministration,
    participant
  );

  if (Array.isArray(pureFilteredWorkflow)) {
    const { evaluationType, evaluationWeights } = toolAdministration;

    pureFilteredWorkflow.forEach((workflowInfo) => {
      calculatedWorkflow.push({
        ...workflowInfo,
        averageEvaluation: calcWorkflowAverageByWeights(
          workflowInfo,
          evaluationType,
          evaluationWeights
        ),
      });
    });
  }

  return calculatedWorkflow;
};

export const getSkillAssessmentScoreViewOrdenation = (
  selectedToolId,
  allSkillAssessment
) => {
  const toolAdministration = getObjectInfoById(
    selectedToolId,
    allSkillAssessment,
    "selectedToolId"
  );

  const { participants } = toolAdministration;

  const filtered = [];

  if (Array.isArray(participants)) {
    participants.forEach((participantInfo) => {
      const { workflowBySkills } = getSkillsWorkflowAverage({
        ...toolAdministration,
        participants: [participantInfo],
      });

      const { average } = getTotalWorkflowAverage(
        workflowBySkills,
        toolAdministration.roleSkillRelation,
        toolAdministration.evaluationType,
        toolAdministration.evaluationWeights
      );

      filtered.push({ ...participantInfo, average });
    });
  }

  return filtered;
};

export const getMultipleOverviewCalc = (allSkToolsData) => {
  const result = {
    averageEvaluation: 0,
    managerEvaluation: 0,
    selfEvaluation: 0,
    desiredEvaluation: 0,
    evaluatedPeople: 0,
    totalPeople: 0,
    maxScale: 4,
  };
  let toolsCount = 0;
  let finalMaxScale = result.maxScale;

  if (!Array.isArray(allSkToolsData) || !allSkToolsData.length > 0)
    return result;

  allSkToolsData.forEach((toolAdministration = {}) => {
    const max = getMaximumScale(toolAdministration.evaluationScale);
    if (max > finalMaxScale) finalMaxScale = max;
  });

  result.maxScale = finalMaxScale;

  allSkToolsData.forEach((toolAdministration = {}) => {
    const {
      roleSkillRelation,
      evaluationType,
      evaluationWeights,
      participants,
      evaluationScale,
    } = toolAdministration;

    const filteredConfig = {
      ...toolAdministration,
      participants: participants.filter(
        ({ evaluationStage }) =>
          defaultEvaluatedWorkflowStages.indexOf(evaluationStage) > -1
      ),
    };

    const maxScale = getMaximumScale(evaluationScale);

    const { workflowBySkills } = getSkillsWorkflowAverage(filteredConfig);
    const { average: totalAverage } = getTotalWorkflowAverage(
      workflowBySkills,
      roleSkillRelation,
      evaluationType,
      evaluationWeights
    );

    const { evaluated, total } = countEvaluatedParticipants(
      filteredConfig,
      participants
    );

    const validAverage =
      totalAverage.averageEvaluation !== "N/A" &&
      totalAverage.averageEvaluation > 0;

    if (!validAverage) return;

    toolsCount++;

    result.averageEvaluation += ruleOfThree(
      maxScale,
      totalAverage.averageEvaluation,
      finalMaxScale
    );

    if (totalAverage.managerEvaluation !== "N/A")
      result.managerEvaluation += ruleOfThree(
        maxScale,
        totalAverage.managerEvaluation,
        finalMaxScale
      );
    if (totalAverage.selfEvaluation !== "N/A")
      result.selfEvaluation += ruleOfThree(
        maxScale,
        totalAverage.selfEvaluation,
        finalMaxScale
      );

    const finalDesired =
      totalAverage.desiredEvaluation <= 0
        ? maxScale
        : totalAverage.desiredEvaluation;

    result.desiredEvaluation = ruleOfThree(
      maxScale,
      finalDesired,
      finalMaxScale
    );
    result.evaluatedPeople += evaluated;
    result.totalPeople += total;
  });

  const list = [
    "averageEvaluation",
    "managerEvaluation",
    "selfEvaluation",
    "desiredEvaluation",
  ];

  list.forEach((key) => {
    if (result[key] > 0) result[key] = parseFloat(result[key]) / toolsCount;
  });

  return result;
};

export const getComplementaryWorkflowValue = (workflowInfo) => {
  const { complementaryEvaluation, value = "" } = workflowInfo;
  const { type } = complementaryEvaluation;

  if (type === "KEY_SEAT" || type === "PERFORMANCE") return value;
  if (type === "SUCCESSION") return getWorkflowValueBySuccession(workflowInfo);

  return 0;
};

export const getComplementaryWorkflowDisplayValue = (
  workflowInfo,
  complementaryScales
) => {
  const { complementaryEvaluation } = workflowInfo;
  const { type } = complementaryEvaluation;

  const { workflowValueSlug } = getSuccessionEvaluationInfo(
    complementaryEvaluation
  );
  const value = getComplementaryWorkflowValue(workflowInfo);

  if (type !== "SUCCESSION") {
    const evaluation = getObjectInfoById(
      value,
      complementaryScales,
      "value"
    ).title;
    return evaluation ? `${value} (${evaluation})` : "N/A";
  }

  const boolOptions = getDefaultSuccessionBoolOptions();
  const readinessOptions = getDefaultReadinessOptions();

  const { fullName: successorMember } = workflowInfo.alternativeValueUser || {};

  if (workflowValueSlug === "alternativeValueBool")
    return getObjectInfoById(value, boolOptions, "value").label || "N/A";

  if (workflowValueSlug === "alternativeValueString")
    return getObjectInfoById(value, readinessOptions, "value").label || "N/A";

  if (workflowValueSlug === "alternativeValueUser")
    return successorMember || "N/A";

  return "";
};
