import React from "react";
import { getValidFunc } from "../../../utils/validations";

const EditColumn = (props) => {
  const handleEdit = getValidFunc(props.handleEdit);

  return (
    <td
      style={{ width: "40px", textAlign: "center", cursor: "pointer" }}
      className="action-column"
      onClick={handleEdit}
    >
      <i className="fas fa-pen" style={{ color: "#666" }} />
    </td>
  );
};

export default EditColumn;
