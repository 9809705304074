import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getValidFunc } from "../../utils/validations";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    flexGrow: 1,
    backgroundColor: ({ backgroundColor = "#222" }) => backgroundColor,
    [theme.breakpoints.up("sm")]: {
      flexGrow: "initial",
      minWidth: 344,
    },
    color: ({ fontColor = "#fff" }) => fontColor,
  },
  typography: {
    fontWeight: "500",
    fontSize: "16px",
    color: ({ fontColor = "#fff" }) => fontColor,
  },
  actionRoot: {
    padding: "8px 8px 8px 16px",
  },
  icons: {
    marginLeft: "auto",
  },
  materialIcon: {
    padding: "8px 8px",
    transform: "rotate(0deg)",
    color: ({ fontColor = "#fff" }) => fontColor,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  collapse: {
    padding: 16,
  },
  checkIcon: {
    fontSize: 20,
    color: "#b3b3b3",
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: "none",
  },
}));

function getVariantInfo(variant) {
  if (variant === "success")
    return {
      backgroundColor: "#43a047",
      fontColor: "#fff",
      icon: <i className="far fa-check-circle"></i>,
    };

  if (variant === "warning")
    return {
      backgroundColor: "#ff9800",
      fontColor: "#fff",
      icon: <i className="fas fa-exclamation-triangle"></i>,
    };

  if (variant === "info")
    return {
      backgroundColor: "#2196f3",
      fontColor: "#fff",
      icon: <i className="fas fa-info-circle"></i>,
    };

  if (variant === "error")
    return {
      backgroundColor: "#d32f2f",
      fontColor: "#fff",
      icon: <i className="fas fa-times-circle"></i>,
    };

  return { backgroundColor: "#222", icon: null, fontColor: "#fff" };
}

const SnackMessage = React.forwardRef((props, ref) => {
  const [expanded, setExpanded] = useState(false);

  const { notification = {}, key } = props;
  const { message = "", options = {} } = notification;
  const { expansionContent = null, variant = "default" } = options;

  const variantInfo = getVariantInfo(variant);

  const classes = useStyles({
    backgroundColor: variantInfo.backgroundColor,
    fontColor: variantInfo.fontColor,
  });

  const closeSnackbar = getValidFunc(options.closeSnackbar);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDismiss = () => {
    closeSnackbar(key);
  };

  return (
    <Card className={classes.card} ref={ref}>
      <CardActions classes={{ root: classes.actionRoot }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {variantInfo.icon && (
              <div style={{ fontSize: "16px", padding: "3px 10px 0px 0px" }}>
                {variantInfo.icon}
              </div>
            )}
            <Typography variant="subtitle2" className={classes.typography}>
              {message}
            </Typography>
          </div>
          <div className={classes.icons} style={{ width: "auto" }}>
            {expansionContent && (
              <IconButton
                aria-label="Show more"
                className={classnames(classes.materialIcon, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
              >
                <ExpandMoreIcon />
              </IconButton>
            )}
            <IconButton
              className={classes.materialIcon}
              onClick={handleDismiss}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </CardActions>
      {expansionContent && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper className={classes.collapse}>{expansionContent}</Paper>
        </Collapse>
      )}
    </Card>
  );
});

SnackMessage.propTypes = {
  key: PropTypes.number.isRequired,
};

export default SnackMessage;
