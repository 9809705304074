import React from "react";
import _ from "lodash";
import { injectIntl, defineMessages } from "react-intl";
import utils from "../../../../utils/toolUtils";
import gbUtils from "../../../../utils/goalsBonus";
import GoalListItem from "../../GoalListItem";
import { getHitTargetInfo } from "../../../../utils/kpis/display";

const { orderArrayByObjAttr } = utils;

const {
  exceededTargetMaxValue,
  filterGoalsByTeams,
  multipleIndicatorsWithBonus,
} = gbUtils;

const messages = defineMessages({
  noDataToDisplay: {
    id: "global.noDataTable",
  },
  gb_table_text_participants: {
    id: "gb_table_text_participants",
  },
  gb_table_text_areas_teams: {
    id: "gb_table_text_areas_teams",
  },
});

const TeamGoalEvaluations = (props) => {
  const {
    teams = [],
    goalTargets = {},
    clearBonusGateMin,
    displayParticipants = [],
    isPeriodFilterActive,
    intl = {},
  } = props;

  let { teams: filteredTeams, info } = filterGoalsByTeams(displayParticipants);
  const filteredTeamIds = [
    ...Object.keys(filteredTeams).map((teamId) => parseInt(teamId, 10)),
  ];

  let reorderedTeams = orderArrayByObjAttr(teams, "name");
  let totalGoals = 0;

  return (
    <div className="evaluation-card">
      <div className="card-header">
        <div className="card-title">
          <h4>{intl.formatMessage(messages.gb_table_text_areas_teams)}</h4>
        </div>
      </div>
      <div style={{ padding: "20px 0px 20px 15px" }}>
        <div className="card-body max-height-800" style={{ padding: "0px" }}>
          <div className="right-spacing">
            {reorderedTeams.map((teamInfo, index) => {
              if (filteredTeamIds.indexOf(teamInfo.id) > -1) {
                const teamGoals = filteredTeams[teamInfo.id] || [];
                const {
                  realGoalsReachSum,
                  evaluatedGoals,
                } = multipleIndicatorsWithBonus(
                  teamGoals,
                  goalTargets,
                  clearBonusGateMin,
                  isPeriodFilterActive,
                );

                const filteredTargetInfo = getHitTargetInfo(
                  realGoalsReachSum,
                  goalTargets,
                  evaluatedGoals.length === 0,
                );

                totalGoals += totalGoals.length;

                const teamAdditionalInfo = info[teamInfo.id] || {};
                const teamParticipants = teamAdditionalInfo.participants || [];

                const displayReach = realGoalsReachSum;
                const displayHit = filteredTargetInfo;

                return (
                  <GoalListItem
                    title={teamInfo.name}
                    description={`${
                      teamParticipants.length
                    } ${intl.formatMessage(
                      messages.gb_table_text_participants,
                    )}`}
                    currentPercentage={displayReach}
                    maxPercentage={exceededTargetMaxValue(goalTargets.exceeded)}
                    progressColor={displayHit.color}
                    barTotalWidth={100}
                    key={teamInfo.id}
                  />
                );
              }

              return null;
            })}
            {totalGoals === 0 && (
              <h5 align="center">
                {intl.formatMessage(messages.noDataToDisplay)}
              </h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function areEqual(prev, next) {
  if (!_.isEqual(prev.periodFilter, next.periodFilter)) return false;
  if (!_.isEqual(prev.displayParticipants, next.displayParticipants))
    return false;
  if (!_.isEqual(prev.teams, next.teams)) return false;

  return true;
}

export default injectIntl(React.memo(TeamGoalEvaluations, areEqual));
