import React from "react";
import Avatar from "../../../../../../../Common/Avatar";

export default function LeadershipCard({
  label,
  color,
  evaluation,
  percentage,
  onClick,
}) {
  function handleClick(item) {
    onClick(item);
  }

  return (
    <div className="evaluation-card" style={{ width: "30%" }}>
      <div
        className="card-header"
        style={{
          borderTop: `8px solid ${color}`,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h5>{label}</h5>
        <h6>
          Total: {evaluation?.length || 0} (
          {percentage ? `${percentage.toFixed(1)}%` : "0%"})
        </h6>
      </div>
      <div className="card-body">
        <div className="simple-lines-table col-xs-12">
          <h5 className="card-title">Profissionais</h5>
          {evaluation?.map((item) => {
            return (
              <div
                className="simple-line"
                key={item.peopleEvaluation.id}
                onClick={() => handleClick(item)}
                style={{ cursor: "pointer" }}
              >
                <div
                  className="title"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Avatar
                    src={
                      item?.peopleEvaluation?.professionalProfile.professional
                        .avatarBlobId
                    }
                    style={{
                      width: "25px",
                      height: "25px",
                      marginRight: "5px",
                    }}
                  />
                  <span>
                    {
                      item?.peopleEvaluation?.professionalProfile.professional
                        .name
                    }
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
