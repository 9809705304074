/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { defineMessages } from "react-intl";
import { withStyles } from "@material-ui/styles";
import NumberFormatCustom from "../../../NumberFormatCustom";
import { getPeriodGoalDistributionPercentage } from "../../../../../utils/kpis/calc";
import { formTransformValue } from "../../../../../utils/kpis/validation";
import { getValidFunc } from "../../../../../utils/validations";
import {
  countDecimals,
  getToFixed,
  removeUnecessaryDecimals,
} from "../../../../../utils/MathUtils";
import MaterialTextField from "../../../MaterialTextField";
import { translatedText } from "../../../../../utils/translationUtils";
import SimpleRelativeController from "../../../SimpleRelativeController";
import { notStatedGoalReachDefault } from "../../../../../constants/kpis";
import { displayTargets } from "../../../../../utils/kpis/display";

const messages = defineMessages({
  gb_table_text_actual: { id: "gb_table_text_actual" },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

const styles = {
  textField: {
    "& > div:before": {
      borderBottom: "none !important",
    },
  },
};

let TextField = (props) => {
  const { classes } = props;

  return <MaterialTextField {...props} className={classes.textField} />;
};
TextField = withStyles(styles)(TextField);

export const getPeriodPercentage = (
  goalInfo,
  totalPercentage,
  periodInfo = {},
  goalTargetConfigurations,
  validPeriod = true,
) => {
  if (validPeriod) {
    return `${getPeriodGoalDistributionPercentage(
      goalInfo,
      totalPercentage,
      periodInfo,
      goalTargetConfigurations,
    )}%`;
  }

  return "0.0%";
};

export const getTargetCollumn = (hitInfo, onCourse = true) => {
  const { totalHitTargetInfo } = notStatedGoalReachDefault;
  return (
    <td
      align="center"
      style={{
        backgroundColor: onCourse ? hitInfo.color : totalHitTargetInfo.color,
        color: "#333",
        transition: "0.3s",
        width: "20px",
        padding: "0px",
      }}
    />
  );
};

export const getTargetInputProps = (type, tabIndex = "0") => {
  const InputProps =
    type === "date" ? {} : { inputComponent: NumberFormatCustom };

  return {
    primaryInputProps: InputProps,
    secondaryInputProps: { tabIndex },
  };
};

export const getTextInput = (
  id,
  type = "number",
  label,
  value = "",
  measurementUnity,
  handleCallback = () => {},
  isEditing,
  isDisabled = false,
  tabIndex,
  fontSize,
  autoFocus = true,
  onKeyDown = () => {},
  editCallback = () => {},
  showClose = true,
  onlyNumbers = false,
) => {
  const autoCursor = isDisabled ? "not-allowed" : "auto";

  const styles = {
    textAlign: "right",
    padding: "0px 10px",
    height: "40px",
    lineHeight: "40px",
    fontSize,
    cursor: !isDisabled && !isEditing ? "pointer" : autoCursor,
  };

  const handleEdit = (newValue) => {
    return handleCallback(newValue);
  };
  const invalidDate = type === "date" && value === "";
  const invalidNumber = type === "number" && !Number.isFinite(value);
  const invalidValue = invalidDate || invalidNumber;

  const displayInput = !isDisabled && isEditing;

  return displayInput || type === "date" ? (
    <div style={{ position: "relative" }}>
      <TextField
        {...getTargetInputProps(type, tabIndex)}
        id={id}
        type={type}
        label={label}
        value={value}
        onChange={(e) => (!isDisabled ? handleEdit(e.target.value) : () => {})}
        inputStyle={styles}
        margin="none"
        variant="standard"
        hideLabel
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
      />
      {showClose && type !== "date" && (
        <SimpleRelativeController onConfirm={editCallback} />
      )}
    </div>
  ) : (
    <div style={styles} tabIndex={tabIndex} onClick={editCallback}>
      {!onlyNumbers &&
        displayTargets(value, measurementUnity, invalidValue, null)}
      {onlyNumbers && value}
    </div>
  );
};

export const getActualDistribution = (
  index,
  percentage,
  measurementUnity,
  callbackUpdate,
  blockChanges,
  tabIndex,
  fontSize,
) => {
  const decimals = countDecimals(Number(percentage));
  const maxDecimals = decimals > 6 ? 6 : decimals;
  const toFixed = decimals < 4 ? 4 : maxDecimals;
  const fixedPercentage =
    Number(percentage) > 0
      ? removeUnecessaryDecimals(getToFixed(percentage, toFixed))
      : "";

  const handleCallback = !blockChanges
    ? (newPercentage) => {
        callbackUpdate(Number(newPercentage));
      }
    : () => {};

  return getTextInput(
    `distPercentage${index}`,
    "text",
    "distribution",
    fixedPercentage,
    measurementUnity,
    handleCallback,
    true,
    blockChanges,
    tabIndex,
    fontSize,
    false,
    () => {},
    () => {},
    false,
    true,
  );
};

export const getPeriodValueColl = (
  id,
  actualValue = 0,
  callbackUpdate,
  isEditing,
  isDisabled,
  tabIndex,
  measurementUnity,
  editCallback,
  fontSize,
  onKeyDown,
  showClose = true,
  autoFocus = true,
) => {
  const vCallbackUpdate = getValidFunc(callbackUpdate);

  const handleCallback = (value) => {
    if (!isDisabled) {
      const finalValue = formTransformValue(value, measurementUnity, true);
      vCallbackUpdate(finalValue);
    }
  };

  const displayValue = formTransformValue(actualValue, measurementUnity, false);
  const actualInputType = measurementUnity === "DATE" ? "date" : "text";

  return getTextInput(
    id,
    actualInputType,
    translation("gb_table_text_actual"),
    displayValue,
    measurementUnity,
    handleCallback,
    isEditing,
    isDisabled,
    tabIndex,
    fontSize,
    autoFocus,
    onKeyDown,
    editCallback,
    showClose,
  );
};

export const getApprDistribution = (
  id,
  actualValue = 0,
  callbackUpdate,
  saveNewDistr,
  resetSaveNewDistr,
  measurementUnity,
  fontSize,
) => {
  return (
    <div style={{ position: "relative" }}>
      {getPeriodValueColl(
        id,
        actualValue,
        callbackUpdate,
        true,
        false,
        1000,
        measurementUnity,
        fontSize,
        true,
        (e) => {
          if (e.key === "Enter") saveNewDistr();
        },
        false,
      )}
      <SimpleRelativeController
        onConfirm={saveNewDistr}
        onCancel={resetSaveNewDistr}
      />
    </div>
  );
};
