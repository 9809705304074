import React from "react";
import styled from "styled-components";
import MaterialTextField from "../../../../Common/MaterialTextField";
import SimpleCheckbox from "../../../../Common/SimpleCheckbox";
import { formatDates } from "../../../../../utils/customPeriods/validations";
import NextStep from "./NextStep";

const Wrapper = styled.div`
  h2 {
    font-size: 20px;
    width: 100%;
  }
`;

const Example = styled.div`
  margin-bottom: 10px;
  font-size: 12px;
`;

const Step1 = ({ configuration, update, nextStep, blockConfirm }) => {
  const { title, start_date, end_date } = configuration;

  function inputChange(field, e) {
    update(field, e.target.value);
  }

  function dateChange(field, e) {
    update(field, `${e.target.value}T03:00:00.000Z`);
  }

  const displayDates = formatDates({ start_date, end_date });

  return (
    <Wrapper className="row">
      <div className="col-xs-12">
        <h2>Passo #1: Configure as datas e título</h2>
        <MaterialTextField
          type="text"
          label="Título do período"
          onChange={(e) => inputChange("title", e)}
          value={title}
        />
        <Example>Ex: Ano comercial 2022</Example>
      </div>
      <div className="col-xs-12 col-md-6">
        <MaterialTextField
          type="date"
          label="Data de início"
          onChange={(e) => dateChange("start_date", e)}
          value={displayDates.start_date}
        />
      </div>
      <div className="col-xs-12 col-md-6">
        <MaterialTextField
          type="date"
          label="Data final"
          onChange={(e) => dateChange("end_date", e)}
          value={displayDates.end_date}
        />
      </div>
      <div className="col-xs-12" style={{ paddingTop: "15px" }}>
        <SimpleCheckbox
          checked={configuration.is_default}
          onChange={() => {
            update("is_default", !configuration.is_default);
          }}
          label="Tornar período padrão"
        />
      </div>
      <NextStep
        onClick={() => nextStep("importPreference")}
        text="Continuar"
        disabled={blockConfirm}
      />
    </Wrapper>
  );
};

export default Step1;
