import styled, { css } from "styled-components";

export const Container = styled.div`
  max-width: 800px;
  width: 100%;
  margin-bottom: 5px;
  text-align: right;

  .quill {
    display: flex;
    flex-direction: column-reverse;

    height: 95px;
  }

  .quill .ql-toolbar.ql-snow .ql-snow {
    border-bottom: 0px;
    border-top: 0px;
    height: 100px;
  }

  .ql-snow.ql-toolbar:after,
  .ql-snow .ql-toolbar:after {
    content: none;
  }

  .ql-mention-list-container {
    max-height: 300px;
    overflow: auto;
  }

  #toolbar {
    button {
      border-radius: 5px;
      transition: 0.2s;

      &:hover {
        background-color: #ccc;
      }
    }

    .button-send {
      ${(props) =>
        props.activeSend &&
        css`
          background: rgb(85, 174, 30);
          color: #fff;

          &:hover {
            background: rgba(85, 174, 30, 0.7);
            color: #fff !important;
          }
        `}
    }
  }
`;

export const ToolBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 0;

  .box-right {
    display: flex;
    justify-content: flex-end;

    margin: 0 right;
  }
`;

export const BoxFiles = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  border: 1px solid #ccc;
  border-top: 0px;
  border-bottom: 0px;

  padding: 10px;
`;

export const IconDelete = styled.i`
  position: absolute;
  width: 20px;
  height: 20px;

  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  background: #eee;
  color: #888;
  top: -7px;
  right: -7px;
  cursor: pointer;

  &:hover {
    background: #ccc;
    color: #e23262;
  }
`;

export const File = styled.div`
  display: flex;
  width: 176px;

  position: relative;

  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
`;

export const IconPreview = styled.i`
  font-size: 36px;
  margin-right: 5px;
  margin-top: 3px;
`;

export const MaxLength = styled.span`
  background-color: #fff;
  font-size: 12px;

  color: ${(props) => (props && props.isBlocked ? "#f54e4e" : "#333")};
`;
