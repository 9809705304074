import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";

import Alert from "@material-ui/lab/Alert";
import MaterialTextField from "../../../../components/Common/MaterialTextField";
import MaterialSingleSelect from "../../../Common/MaterialSingleSelect";
import FormDialog from "../../../Common/FormDialog";
import ConfirmationDialog from "../../../Common/ConfirmationDialog";
import SelectMemberModal from "../../../Common/SelectMemberModal";
import SelectTreeModal from "../../../Common/SelectTreeModal";
import SimpleCheckbox from "../../../Common/SimpleCheckbox";
import SimpleLegend from "../../../Common/SimpleLegend";

import {
  getMarketEvaluationOptions,
  defaultProfessionalAscentionForm,
  defaultSalaryHistoryForm,
  defaultDeletingAction,
  defaultEditingModal,
} from "../../../../constants/peopleEvaluation";
import { getPercentage } from "../../../../utils/MathUtils";
import { translatedText } from "../../../../utils/translationUtils";
import { getObjectInfoById } from "../../../../utils/ArrayUtils";
import utils from "../../../../utils/toolUtils";

const messages = defineMessages({
  globalSave: {
    id: "global.save",
  },
  globalCancel: {
    id: "global.cancel",
  },
  globalExclude: {
    id: "global.exclude",
  },
  globalAdd: {
    id: "global.add",
  },
  globalChange: {
    id: "global.change",
  },
  buttonAddNew: {
    id: "pb_add_new",
  },
  clickToEdit: {
    id: "pb_click_to_edit",
  },
  form_area: {
    id: "pb_form_area",
  },
  form_role: {
    id: "pb_form_role",
  },
  career_trajectory: {
    id: "pb_career_trajectory",
  },
  salary_history: {
    id: "pb_salary_history",
  },
  table_professional_history: {
    id: "pb_professional_history",
  },
  table_start_date: {
    id: "pb_start_date",
  },
  table_end_date: {
    id: "pb_end_date",
  },
  table_current_position: {
    id: "pb_current_position",
  },
  table_first_position: {
    id: "pb_first_position",
  },
  table_position: {
    id: "pb_position",
  },
  table_professional_leader: {
    id: "pb_professional_leader",
  },
  table_edit: {
    id: "pb_table_edit",
  },
  table_remove: {
    id: "pb_table_remove",
  },
  table_modification_date: {
    id: "pb_table_modification_date",
  },
  table_adjusted_value: {
    id: "pb_table_adjusted_value",
  },
  table_adjusted_percent: {
    id: "pb_table_adjusted_percent",
  },
  table_reason: {
    id: "pb_table_reason",
  },
  leader_not_selected: {
    id: "pb_leader_not_selected",
  },
  no_recorded_history: {
    id: "pb_no_recorded_history",
  },
  confirm_remove_history: {
    id: "pb_confirm_remove_history",
  },
  dialog_change_salary_history: {
    id: "pb_dialog_change_salary_history",
  },
  dialog_add_salary_history: {
    id: "pb_dialog_add_salary_history",
  },
  dialog_change_professional_history: {
    id: "pb_dialog_change_professional_history",
  },
  dialog_first_professional_history: {
    id: "pb_dialog_first_professional_history",
  },
  dialog_add_professional_history: {
    id: "pb_dialog_add_professional_history",
  },
  dialog_change_professional_leader: {
    id: "pb_dialog_change_professional_leader",
  },
  dialog_select_professional_leader: {
    id: "pb_dialog_select_professional_leader",
  },
  dialog_config_area: {
    id: "pb_dialog_config_area",
  },
  dialog_min_date_alert: {
    id: "pb_dialog_min_date_alert",
  },
  dialog_equal_date_alert: {
    id: "pb_dialog_equal_date",
  },
  dialog_date_conflict_alert: {
    id: "pb_dialog_date_conflict",
  },
  dialog_start_date_greater: {
    id: "pb_dialog_start_date_greater",
  },
  dialog_from_role: {
    id: "pb_dialog_from_role",
  },
  dialog_initial_role: {
    id: "pb_dialog_initial_role",
  },
  dialog_from_area: {
    id: "pb_dialog_from_area",
  },
  dialog_initial_area: {
    id: "pb_dialog_initial_area",
  },
  form_gross_remuneration: {
    id: "pb_form_gross_remuneration",
  },
  form_market_evaluation: {
    id: "pb_form_market_evaluation",
  },
  form_average_market_evaluation: {
    id: "pb_form_average_market_evaluation",
  },
  modalTextCancel: {
    id: "btn_modal_cancel",
  },
  modalTextConfirm: {
    id: "btn_modal_confirm",
  },
  pb_warning_different_history: {
    id: "pb_warning_different_history",
  },
});

const translation = (id, values) => translatedText(id, messages, values);

const {
  getRoleLabelBySlug,
  getUpdatedHistory,
  updateLastHistory,
  removeUpdateHistory,
  getProfessionalInfo,
  getRelativeNextAndLastDates,
  getMemberInfoById,
} = utils;

const useStyles = makeStyles(() => ({
  alertFiled: {
    "& .MuiAlert-message": {
      fontSize: "15px !important",
    },
  },
}));

const ErrorMessage = (props) => {
  const { message } = props;
  return (
    <div className="col-xs-12 form-error">
      <h6 style={{ marginTop: "0px" }}>{message}</h6>
    </div>
  );
};

const SalaryTrajectoryField = ({
  salaryTrajectory,
  setSalaryTrajectory,
  saveButton,
  onSave,
  setProfHistoryModalConfig,
  profHistoryModalConfig,
  intl,
  companyMembers,
  companyAreasTree,
  companyPositionsTree,
  selectAreaModal,
  selectPositionModal,
  toggleSelectAreaModal,
  toggleSelectPositionModal,
  userProfile = {},
}) => {
  // Dialogs constants
  const [
    professionalLeaderModalOpen,
    setProfessionalLeaderModalOpen,
  ] = useState(false);
  const [showSalaryHistoryModal, setShowSalaryHistoryModal] = useState(false);
  const [profHistoryModal, setProfHistoryModalModal] = useState(
    defaultProfessionalAscentionForm,
  );
  const [salaryHistoryModal, setSalaryHistoryModal] = useState(
    defaultSalaryHistoryForm,
  );
  const [editingModal, setEditingModal] = useState(defaultEditingModal);
  const [deletingAction, setDeletingAction] = useState(defaultDeletingAction);
  const [selectPositionType, setSelectPositionType] = useState({ type: null });
  const [selectAreaType, setSelectAreaType] = useState({ type: null });
  const [customPositionText, setCustomPositionText] = useState("");
  const [customAreaText, setCustomAreaText] = useState("");
  // End Dialogs constants

  // Select options constants
  const marketEvaluationOptions = getMarketEvaluationOptions(intl);
  // End Select options contants

  const { professionalAscentions, remunerationHistories } = salaryTrajectory;
  const { companyArea = null, companyPosition = null } = userProfile;

  const classes = useStyles();

  useEffect(() => {
    if (editingModal.type === null && profHistoryModalConfig.show)
      resetProfHistoryModal(true);
  }, [profHistoryModalConfig.show, userProfile]);

  const confirmClearModals = () => {
    resetSalaryHistoryModal();
    resetProfHistoryModal();
    setShowSalaryHistoryModal(false);
    setProfHistoryModalConfig({ from: null, show: false });
  };

  const getProfHistoryEditBody = (index) => {
    const selectedProfHistoryBody = professionalAscentions[index]
      ? _.cloneDeep(professionalAscentions[index])
      : null;
    const selectedProfessionalLeaderInfo = getProfessionalInfo(
      selectedProfHistoryBody.professionalLeader,
    );

    const selectedProfHistoryModalBody = selectedProfHistoryBody
      ? {
          fromDate: selectedProfHistoryBody.fromDate || "",
          toDate: selectedProfHistoryBody.toDate || "",
          currentRole: selectedProfHistoryBody.currentRole || false,
          changedArea: selectedProfHistoryBody.changedArea || false,
          professionalLeader: selectedProfessionalLeaderInfo.id
            ? {
                id: selectedProfessionalLeaderInfo.id,
                type: selectedProfessionalLeaderInfo.type,
              }
            : null,
          toPositionText: selectedProfHistoryBody.toPositionText || "",
          toAreaText: selectedProfHistoryBody.toAreaText || "",
        }
      : null;

    return selectedProfHistoryModalBody;
  };

  const getSalaryHistoryEditModal = (index) => {
    const selectedSalHistoryBody = remunerationHistories[index]
      ? _.cloneDeep(remunerationHistories[index])
      : null;

    const selectedSalHistoryModalBody = selectedSalHistoryBody
      ? {
          date: selectedSalHistoryBody.date ? selectedSalHistoryBody.date : "",
          adjustedValue: selectedSalHistoryBody.newRemuneration
            ? selectedSalHistoryBody.newRemuneration
            : "",
          reason: selectedSalHistoryBody.reason
            ? selectedSalHistoryBody.reason
            : "",
        }
      : null;

    return selectedSalHistoryModalBody;
  };

  const resetSalaryHistoryModal = () => {
    setSalaryHistoryModal(defaultSalaryHistoryForm);
    setEditingModal(defaultEditingModal);
  };

  const resetProfHistoryModal = (checkCurrentRole = false) => {
    const defaultCurrentRole =
      checkCurrentRole && professionalAscentions.length === 0 ? true : false;

    const appendUserProfile = defaultCurrentRole
      ? {
          toAreaText: companyArea ? companyArea.title : "",
          toPositionText: companyArea ? companyPosition.title : "",
        }
      : {};

    setProfHistoryModalModal({
      ...defaultProfessionalAscentionForm,
      ...appendUserProfile,
      currentRole: defaultCurrentRole,
    });
    setEditingModal(defaultEditingModal);
  };

  const resetDeletingAction = () => {
    setDeletingAction(defaultDeletingAction);
  };

  const resetSelectPositionType = () => {
    setSelectPositionType({ type: null });
  };

  const resetSelectAreaType = () => {
    setSelectAreaType({ type: null });
  };

  const toggleSalaryHistoryModal = () => {
    setShowSalaryHistoryModal(!showSalaryHistoryModal);
    resetSalaryHistoryModal();
  };

  const getUpdatedPositionModalInfo = (isCurrentRole) => {
    return isCurrentRole &&
      !profHistoryModal.toAreaText &&
      !profHistoryModal.toPositionText
      ? {
          toAreaText: companyArea
            ? companyArea.title
            : profHistoryModal.toAreaText,
          toPositionText: companyArea
            ? companyPosition.title
            : profHistoryModal.toPositionText,
        }
      : {};
  };

  const changeCurrentRoleSelecion = (isChecked) => {
    const appendUserProfile = getUpdatedPositionModalInfo(isChecked);

    setProfHistoryModalModal({
      ...profHistoryModal,
      ...appendUserProfile,
      currentRole: isChecked,
    });
  };

  const openProfHistoryModal = () => {
    setProfHistoryModalConfig({
      from: null,
      show: true,
    });
  };

  const updateRemunerationHistory = (index, modalInfo) => {
    let newRemunerationHistories = _.cloneDeep(remunerationHistories);

    newRemunerationHistories = getUpdatedHistory(
      index,
      {
        date: modalInfo.date,
        newRemuneration: parseFloat(modalInfo.adjustedValue),
        reason: modalInfo.reason,
      },
      newRemunerationHistories,
      "date",
    );

    setSalaryTrajectory({
      ...salaryTrajectory,
      remunerationHistories: newRemunerationHistories,
    });
    confirmClearModals();
  };

  const updateLastHistories = (modalInfo, newAscentions) => {
    if (modalInfo.currentRole && newAscentions.length > 0) {
      // Update last history final date
      const lastHistoryInfo = newAscentions[0];
      if (!lastHistoryInfo.toDate) {
        newAscentions = updateLastHistory(
          {
            toDate: moment(modalInfo.fromDate).subtract(1, "days"),
          },
          newAscentions,
        );
      }

      newAscentions.forEach((ascentionInfo, index) => {
        newAscentions[index].currentRole = false;
      });
    }

    return newAscentions;
  };

  const updateProfessionalAscentions = (index, modalInfo) => {
    const newAscentions = updateLastHistories(
      modalInfo,
      _.cloneDeep(professionalAscentions),
    );

    const updatedAscentions = getUpdatedHistory(
      index,
      {
        fromDate: modalInfo.fromDate,
        toDate: modalInfo.toDate,
        currentRole: modalInfo.currentRole,
        changedArea: modalInfo.changedArea,
        professionalLeader: modalInfo.professionalLeader,
        toPositionText: modalInfo.toPositionText,
        toAreaText: modalInfo.toAreaText,
      },
      newAscentions,
      "fromDate",
    );

    setSalaryTrajectory({
      ...salaryTrajectory,
      professionalAscentions: updatedAscentions,
    });
    confirmClearModals();
  };

  const handleAddRemunerationHistory = (modalInfo) => {
    const newBody = {
      date: modalInfo.date,
      newRemuneration: parseFloat(modalInfo.adjustedValue),
      reason: modalInfo.reason,
    };

    setSalaryTrajectory({
      ...salaryTrajectory,
      remunerationHistories: getUpdatedHistory(
        remunerationHistories.length,
        newBody,
        _.cloneDeep(remunerationHistories),
        "date",
      ),
    });

    confirmClearModals();
  };

  const handleAddProfHistory = (modalInfo) => {
    const newAscentions = updateLastHistories(
      modalInfo,
      _.cloneDeep(professionalAscentions),
    );

    setSalaryTrajectory({
      ...salaryTrajectory,
      professionalAscentions: getUpdatedHistory(
        professionalAscentions.length,
        modalInfo,
        newAscentions,
        "fromDate",
      ),
    });

    confirmClearModals();
  };

  const handleCancelProAscModal = () => {
    confirmClearModals();
  };

  const handleCancelSalHistoryModal = () => {
    confirmClearModals();
  };

  const handleDeleteProfessionalCarrer = (index) => {
    const selectedToDelete = professionalAscentions[index];

    if (selectedToDelete) {
      setDeletingAction({
        type: "professionalCarrer",
        title: intl.formatMessage(messages.confirm_remove_history),
        onDelete: () => deleteProfessionalCarrer(index),
      });
    }
  };

  const handleDeleteSalaryHistory = (index) => {
    setDeletingAction({
      type: "salaryHistory",
      title: intl.formatMessage(messages.confirm_remove_history),
      onDelete: () => deleteSalaryHistory(index),
    });
  };

  const handleUpdateProfessionalLeader = (leaderID) => {
    const professionalLeaderInfo = getMemberInfoById(leaderID, companyMembers);

    setProfHistoryModalModal({
      ...profHistoryModal,
      professionalLeader: {
        id: leaderID,
        type: professionalLeaderInfo.type,
        member:
          professionalLeaderInfo.type === "MEMBER"
            ? { ...professionalLeaderInfo }
            : null,
        participant:
          professionalLeaderInfo.type === "PARTICIPANT"
            ? { ...professionalLeaderInfo }
            : null,
      },
    });

    setProfessionalLeaderModalOpen(false);
  };

  const deleteProfessionalCarrer = (index) => {
    const updatedAscentions = removeUpdateHistory(
      index,
      professionalAscentions,
      "fromDate",
    );

    setSalaryTrajectory({
      ...salaryTrajectory,
      professionalAscentions: updatedAscentions,
    });
    resetDeletingAction();
  };

  const deleteSalaryHistory = (index) => {
    setSalaryTrajectory({
      ...salaryTrajectory,
      remunerationHistories: removeUpdateHistory(
        index,
        remunerationHistories,
        "date",
      ),
    });
    resetDeletingAction();
  };

  const openEditSalaryHistory = (index) => {
    const newModalBody = getSalaryHistoryEditModal(index);
    setSalaryHistoryModal(newModalBody);

    setEditingModal({
      type: "salaryHistory",
      confirmFunction: (modalInfo) =>
        updateRemunerationHistory(index, modalInfo),
    });
  };

  const openEditProfessionalCarrer = (index) => {
    const newModalBody = getProfHistoryEditBody(index);
    setProfHistoryModalModal(newModalBody);

    setEditingModal({
      type: "professionalCarrer",
      confirmFunction: (modalInfo) =>
        updateProfessionalAscentions(index, modalInfo),
      lastHistoryIndex: index === 0 ? 1 : 0,
      selectedIndex: index,
    });
  };

  const isValidSalaryHistoryModal = () => {
    const { date, adjustedValue } = salaryHistoryModal;

    if (!date) return false;
    if (!adjustedValue) return false;

    return true;
  };

  const checkFirstPositionAdded = (type = "index", index) => {
    const lastHistoryLeft = professionalAscentions.length === 0;

    if (type === "index" && !lastHistoryLeft) {
      return index + 1 === professionalAscentions.length;
    }

    return lastHistoryLeft;
  };

  const getProfHistoryModalErrors = () => {
    const {
      fromDate,
      toDate,
      currentRole,
      changedArea,
      toPositionText,
      toAreaText,
    } = profHistoryModal;

    const isEditing = editingModal.type === "professionalCarrer";

    const compareFromDate = moment(fromDate).format("YYYY-MM-DD");
    const compareToDate = moment(toDate).format("YYYY-MM-DD");
    const newAreaSelected = !toAreaText ? false : true;

    let errors = [];
    let params = {};

    if (!fromDate) errors.push("fromDate");
    if (!currentRole && !toDate) errors.push("toDate");
    if (
      !currentRole &&
      fromDate &&
      toDate &&
      compareFromDate === compareToDate
    ) {
      errors.push("equalDate");
    }
    if (!currentRole && compareFromDate > compareToDate) {
      errors.push("invalidToDate");
    }
    if (!toPositionText) errors.push("toPositionText");
    if ((changedArea && !newAreaSelected) || (currentRole && !newAreaSelected))
      errors.push("toAreaText");

    // Validar a sobreposição de datas
    // (ultimaDataInicial/proximaDataInicial) e (ultimaDataFinal, proximaDataFinal) em relação a data de comparação
    if (
      errors.indexOf("fromDate") === -1 &&
      errors.indexOf("toDate") === -1 &&
      errors.indexOf("equalDate") === -1 &&
      errors.indexOf("invalidToDate") === -1
    ) {
      // Se não houver erros comuns de data, fazer validação de sobreposição de datas
      let filteredAscentions = [..._.cloneDeep(professionalAscentions)];
      if (isEditing) {
        filteredAscentions.splice(editingModal.selectedIndex, 1); // Remove the selected editing history
      }

      const relativeDates = getRelativeNextAndLastDates(
        compareFromDate,
        filteredAscentions,
        "asc",
      );
      relativeDates.smallerDates.reverse(); // ASC from Smaller Diff to Greater Diff

      const lastMinRelativeDate = relativeDates.smallerDates[0] || null;
      const nextEqualDate = relativeDates.equalDates[0] || null;

      if (
        currentRole &&
        (relativeDates.greaterDates.length > 0 ||
          relativeDates.equalDates.length > 0 ||
          relativeDates.smallerDates.length > 0)
      ) {
        const nextMaxRelativeDate =
          relativeDates.greaterDates[relativeDates.greaterDates.length - 1] ||
          null;

        if (nextMaxRelativeDate || nextEqualDate) {
          errors.push("currentRoleDate");
          params = {
            ...params,
            currentRoleDate: nextMaxRelativeDate
              ? moment(
                  nextMaxRelativeDate.ascention.toDate ||
                    nextMaxRelativeDate.ascention.fromDate,
                ).add(nextMaxRelativeDate.ascention.toDate ? 1 : 2, "days")
              : moment(
                  nextEqualDate.ascention.toDate ||
                    nextEqualDate.ascention.fromDate,
                ).add(nextEqualDate.ascention.toDate ? 1 : 2, "days"),
          };
        } else if (lastMinRelativeDate) {
          let finalDateValidation =
            lastMinRelativeDate.ascention.toDate ||
            lastMinRelativeDate.ascention.fromDate;

          finalDateValidation = moment(finalDateValidation)
            .add(lastMinRelativeDate.ascention.toDate ? 0 : 2, "days")
            .format("YYYY-MM-DD");

          if (finalDateValidation >= compareFromDate) {
            errors.push("currentRoleDate");
            params = {
              ...params,
              currentRoleDate: finalDateValidation,
            };
          }
        }
      }

      if (!currentRole && relativeDates.greaterDates.length > 0) {
        let nextMinRelativeDate =
          relativeDates.greaterDates[0].ascention || null;
        nextMinRelativeDate.toDate =
          nextMinRelativeDate.toDate || nextMinRelativeDate.fromDate;

        if (
          compareToDate >= nextMinRelativeDate.fromDate ||
          compareToDate >= nextMinRelativeDate.toDate
        ) {
          errors.push("nextRelativeDate");
          params = { ...params, nextRelativeDate: nextMinRelativeDate };
        }
      }

      if (
        !currentRole &&
        (relativeDates.smallerDates.length > 0 || nextEqualDate)
      ) {
        if (
          nextEqualDate ||
          compareFromDate <= lastMinRelativeDate.ascention.toDate ||
          compareToDate <= lastMinRelativeDate.ascention.toDate
        ) {
          errors.push("lastRelativeDate");
          params = {
            ...params,
            lastRelativeDate:
              (nextEqualDate && nextEqualDate.ascention) ||
              lastMinRelativeDate.ascention,
          };
        }
      }
    }

    return {
      errors,
      params,
    };
  };

  const cancelSelectPosition = () => {
    toggleSelectPositionModal();
    resetSelectPositionType();
    setCustomPositionText("");
  };

  const cancelSelectArea = () => {
    toggleSelectAreaModal();
    resetSelectAreaType();
    setCustomAreaText("");
  };

  const handleUpdateSelectedPosition = (selectedPosition = {}) => {
    if (selectedPosition.title)
      setProfHistoryModalModal({
        ...profHistoryModal,
        toPositionText: selectedPosition.title,
      });

    cancelSelectPosition();
  };

  const handleUpdateSelectedArea = (selectedArea = {}) => {
    if (selectedArea.title)
      setProfHistoryModalModal({
        ...profHistoryModal,
        toAreaText: selectedArea.title,
      });

    cancelSelectArea();
  };

  const getTypeButton = (title, next = () => {}) => {
    return (
      <div className="simple-selection-button" onClick={next}>
        {title}
      </div>
    );
  };

  const displaySelectPositionTypeModal = () => {
    return (
      <FormDialog
        open={selectPositionModal}
        title="Selecione um tipo"
        onConfirm={() => {}}
        onCancel={cancelSelectPosition}
        hideConfirm
      >
        <div className="row">
          <div className="col-xs-6">
            {getTypeButton("Estrutura de Cargos", () =>
              setSelectPositionType({ type: "COMPANY_POSITION" }),
            )}
          </div>
          <div className="col-xs-6">
            {getTypeButton("Outro", () =>
              setSelectPositionType({ type: "CUSTOM_POSITION" }),
            )}
          </div>
        </div>
      </FormDialog>
    );
  };

  const displaySelectAreaTypeModal = () => {
    return (
      <FormDialog
        open={selectAreaModal}
        title="Selecione um tipo"
        onConfirm={() => {}}
        onCancel={cancelSelectArea}
        hideConfirm
      >
        <div className="row">
          <div className="col-xs-6">
            {getTypeButton("Estrutura de Áreas", () =>
              setSelectAreaType({ type: "COMPANY_AREA" }),
            )}
          </div>
          <div className="col-xs-6">
            {getTypeButton("Outra", () =>
              setSelectAreaType({ type: "CUSTOM_AREA" }),
            )}
          </div>
        </div>
      </FormDialog>
    );
  };

  const displayCustomPositionModal = () => {
    return (
      <FormDialog
        open={selectPositionType.type === "CUSTOM_POSITION"}
        title="Cargo customizado"
        onConfirm={() =>
          handleUpdateSelectedPosition({ title: customPositionText })
        }
        onCancel={cancelSelectPosition}
        blockConfirm={!customPositionText}
      >
        <MaterialTextField
          id="customPositionModal"
          label={intl.formatMessage(messages.dialog_from_role)}
          value={customPositionText}
          onChange={(e) => setCustomPositionText(e.target.value)}
          required
        />
      </FormDialog>
    );
  };

  const displayCustomAreaModal = () => {
    return (
      <FormDialog
        open={selectAreaType.type === "CUSTOM_AREA"}
        title="Área customizada"
        onConfirm={() => handleUpdateSelectedArea({ title: customAreaText })}
        onCancel={cancelSelectArea}
        blockConfirm={!customAreaText}
      >
        <MaterialTextField
          id="customAreaModal"
          label={intl.formatMessage(messages.dialog_from_area)}
          value={customAreaText}
          onChange={(e) => setCustomAreaText(e.target.value)}
          required
        />
      </FormDialog>
    );
  };

  const displayProfHistoryModal = () => {
    const isEditing = editingModal.type === "professionalCarrer";

    const formTitle = isEditing
      ? intl.formatMessage(messages.dialog_change_professional_history)
      : intl.formatMessage(messages.dialog_add_professional_history);
    const confirmText = isEditing
      ? intl.formatMessage(messages.globalChange)
      : intl.formatMessage(messages.globalAdd);
    const confirmFunction = isEditing
      ? () => editingModal.confirmFunction(profHistoryModal)
      : () => handleAddProfHistory(profHistoryModal);

    const modalProfessionalLeaderID = profHistoryModal.professionalLeader
      ? profHistoryModal.professionalLeader.id
      : 0;
    const professionalMemberInfo = getMemberInfoById(
      modalProfessionalLeaderID,
      companyMembers,
    );
    const ascentionModalErrors = getProfHistoryModalErrors();

    const lastHistoryLeft =
      checkFirstPositionAdded("length") ||
      checkFirstPositionAdded("index", editingModal.selectedIndex);
    const configureArea =
      profHistoryModal.changedArea ||
      profHistoryModal.currentRole ||
      lastHistoryLeft;

    return (
      <>
        <SelectTreeModal
          open={selectAreaType.type === "COMPANY_AREA"}
          title="Selecione a Área"
          modalTextCancel={translation("modalTextCancel")}
          modalTextConfirm={translation("modalTextConfirm")}
          treeList={companyAreasTree.treeList}
          treeOrder={companyAreasTree.treeOrder}
          onConfirm={(selected) => handleUpdateSelectedArea(selected[0])}
          onCancel={cancelSelectArea}
          singleSelect={true}
          canSelectAnyLevel={true}
          dialogClassName="select-salary-traject-dialog"
        />
        <SelectTreeModal
          open={selectPositionType.type === "COMPANY_POSITION"}
          title="Selecione o Cargo"
          modalTextCancel={translation("modalTextCancel")}
          modalTextConfirm={translation("modalTextConfirm")}
          treeList={companyPositionsTree.treeList}
          treeOrder={companyPositionsTree.treeOrder}
          onConfirm={(selected) => handleUpdateSelectedPosition(selected[0])}
          onCancel={cancelSelectPosition}
          singleSelect={true}
          canSelectAnyLevel={true}
          dialogClassName="select-position-dialog"
        />
        {professionalLeaderModalOpen && (
          <SelectMemberModal
            open={professionalLeaderModalOpen}
            title={intl.formatMessage(
              messages.dialog_select_professional_leader,
            )}
            toggleOpen={() =>
              setProfessionalLeaderModalOpen(!professionalLeaderModalOpen)
            }
            onConfirm={(selected) =>
              handleUpdateProfessionalLeader(selected[0])
            }
            membersList={companyMembers ? companyMembers : []}
            initialSelected={
              modalProfessionalLeaderID ? [modalProfessionalLeaderID] : []
            }
            singleSelect
          />
        )}
        {displayCustomPositionModal()}
        {displayCustomAreaModal()}
        {displaySelectPositionTypeModal()}
        {displaySelectAreaTypeModal()}
        <FormDialog
          open={profHistoryModalConfig.show || isEditing}
          title={formTitle}
          onConfirm={confirmFunction}
          onCancel={handleCancelProAscModal}
          confirmText={confirmText}
          cancelText={intl.formatMessage(messages.globalCancel)}
          blockConfirm={ascentionModalErrors.errors.length > 0}
        >
          <div className="row">
            <div className="col-xs-6">
              <SimpleCheckbox
                label={intl.formatMessage(messages.table_current_position)}
                name="currentRole"
                checked={profHistoryModal.currentRole}
                onChange={({ checked }) => changeCurrentRoleSelecion(!checked)}
              />
            </div>
            <div className="col-xs-6">
              <SimpleCheckbox
                label={intl.formatMessage(messages.dialog_config_area)}
                name="changedArea"
                checked={configureArea}
                onChange={({ checked }) => {
                  setProfHistoryModalModal({
                    ...profHistoryModal,
                    changedArea: !checked,
                  });
                }}
                disabled={
                  (lastHistoryLeft && isEditing) || profHistoryModal.currentRole
                }
              />
            </div>
          </div>
          <div className="row">
            <div style={{ clear: "both" }}>
              <div className="col-xs-6">
                <MaterialTextField
                  id="fromDateModal"
                  label={intl.formatMessage(messages.table_start_date)}
                  type="date"
                  textAlign="center"
                  value={profHistoryModal.fromDate}
                  onChange={(e) =>
                    setProfHistoryModalModal({
                      ...profHistoryModal,
                      fromDate: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="col-xs-6">
                {!profHistoryModal.currentRole && (
                  <MaterialTextField
                    id="toDateModal"
                    label={intl.formatMessage(messages.table_end_date)}
                    type="date"
                    textAlign="center"
                    value={profHistoryModal.toDate}
                    onChange={(e) =>
                      setProfHistoryModalModal({
                        ...profHistoryModal,
                        toDate: e.target.value,
                      })
                    }
                    required
                  />
                )}
              </div>
              {ascentionModalErrors.errors.indexOf("invalidToDate") > -1 && (
                <ErrorMessage
                  message={intl.formatMessage(
                    messages.dialog_start_date_greater,
                  )}
                />
              )}
              {ascentionModalErrors.errors.indexOf("equalDate") > -1 && (
                <ErrorMessage
                  message={intl.formatMessage(messages.dialog_equal_date_alert)}
                />
              )}
              {ascentionModalErrors.errors.indexOf("currentRoleDate") > -1 && (
                <ErrorMessage
                  message={`${intl.formatMessage(
                    messages.dialog_min_date_alert,
                  )} ${moment(
                    ascentionModalErrors.params.currentRoleDate,
                  ).format("LL")}`}
                />
              )}
              {ascentionModalErrors.errors.indexOf("nextRelativeDate") > -1 && (
                <ErrorMessage
                  message={`${intl.formatMessage(
                    messages.dialog_date_conflict_alert,
                  )}: ${getRoleLabelBySlug(
                    ascentionModalErrors.params.nextRelativeDate.toPositionText,
                    intl,
                  )} | ${moment(
                    ascentionModalErrors.params.nextRelativeDate.fromDate,
                  ).format("LL")} - ${
                    ascentionModalErrors.params.nextRelativeDate.toDate
                      ? moment(
                          ascentionModalErrors.params.nextRelativeDate.toDate,
                        ).format("LL")
                      : null
                  }`}
                />
              )}
              {ascentionModalErrors.errors.indexOf("lastRelativeDate") > -1 && (
                <ErrorMessage
                  message={`${intl.formatMessage(
                    messages.dialog_date_conflict_alert,
                  )}: ${
                    ascentionModalErrors.params.lastRelativeDate.toPositionText
                  } | ${moment(
                    ascentionModalErrors.params.lastRelativeDate.fromDate,
                  ).format("LL")} - ${
                    ascentionModalErrors.params.lastRelativeDate.toDate
                      ? moment(
                          ascentionModalErrors.params.lastRelativeDate.toDate,
                        ).format("LL")
                      : null
                  }`}
                />
              )}
            </div>
            <div style={{ clear: "both" }}>
              {configureArea && (
                <div className="col-xs-6">
                  <MaterialTextField
                    id="toAreaTextModal"
                    label={intl.formatMessage(messages.dialog_from_area)}
                    type="text"
                    value={profHistoryModal.toAreaText}
                    onClick={toggleSelectAreaModal}
                    inputStyle={{ cursor: "pointer" }}
                    required
                  />
                </div>
              )}
              <div className="col-xs-6">
                <MaterialTextField
                  id="toPositionTextModal"
                  label={intl.formatMessage(messages.dialog_from_role)}
                  type="text"
                  value={profHistoryModal.toPositionText}
                  onClick={toggleSelectPositionModal}
                  inputStyle={{ cursor: "pointer" }}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setProfessionalLeaderModalOpen(true);
                }}
              >
                <MaterialTextField
                  id="professionalLeader"
                  label={intl.formatMessage(messages.table_professional_leader)}
                  value={
                    professionalMemberInfo.name
                      ? professionalMemberInfo.name
                      : ""
                  }
                />
              </div>
              <div align="right">
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setProfessionalLeaderModalOpen(true);
                  }}
                >
                  {modalProfessionalLeaderID
                    ? intl.formatMessage(
                        messages.dialog_change_professional_leader,
                      )
                    : intl.formatMessage(
                        messages.dialog_select_professional_leader,
                      )}
                </a>
              </div>
            </div>
          </div>
        </FormDialog>
      </>
    );
  };

  const displaySalaryHistoryModal = () => {
    let isEditing = editingModal.type === "salaryHistory";

    const formTitle = isEditing
      ? intl.formatMessage(messages.dialog_change_salary_history)
      : intl.formatMessage(messages.dialog_add_salary_history);

    const confirmFunction = isEditing
      ? () => editingModal.confirmFunction(salaryHistoryModal)
      : () => handleAddRemunerationHistory(salaryHistoryModal);

    const confirmText = isEditing
      ? intl.formatMessage(messages.globalChange)
      : intl.formatMessage(messages.globalAdd);

    return (
      <FormDialog
        open={showSalaryHistoryModal || isEditing}
        title={formTitle}
        onConfirm={confirmFunction}
        onCancel={handleCancelSalHistoryModal}
        confirmText={confirmText}
        cancelText={intl.formatMessage(messages.globalCancel)}
        blockConfirm={!isValidSalaryHistoryModal()}
      >
        <div className="row">
          <div className="col-xs-6">
            <MaterialTextField
              id="modificationDate"
              label={intl.formatMessage(messages.table_modification_date)}
              textAlign="center"
              type="date"
              value={salaryHistoryModal.date}
              onChange={(e) =>
                setSalaryHistoryModal({
                  ...salaryHistoryModal,
                  date: e.target.value,
                })
              }
              required
            />
          </div>
          <div className="col-xs-6">
            <MaterialTextField
              id="adjustedValue"
              label={intl.formatMessage(messages.table_adjusted_value)}
              type="number"
              value={salaryHistoryModal.adjustedValue}
              onChange={(e) =>
                setSalaryHistoryModal({
                  ...salaryHistoryModal,
                  adjustedValue: e.target.value,
                })
              }
              required
            />
          </div>
          <div className="col-xs-12">
            <MaterialTextField
              id="reasonDetails"
              label={intl.formatMessage(messages.table_reason)}
              value={salaryHistoryModal.reason}
              onChange={(e) =>
                setSalaryHistoryModal({
                  ...salaryHistoryModal,
                  reason: e.target.value,
                })
              }
            />
          </div>
        </div>
      </FormDialog>
    );
  };

  const displayDeleteModal = () => {
    return (
      <ConfirmationDialog
        open={deletingAction.type ? true : false}
        title={deletingAction.title}
        description={deletingAction.description}
        onConfirm={deletingAction.onDelete}
        onCancel={resetDeletingAction}
        confirmText={intl.formatMessage(messages.globalExclude)}
        confirmColor="#ad5656"
        cancelText={intl.formatMessage(messages.globalCancel)}
      />
    );
  };

  const checkDifferentUserProfile = () => {
    if (professionalAscentions.length > 0 && companyArea && companyPosition) {
      const currentAscention = getObjectInfoById(
        true,
        professionalAscentions,
        "currentRole",
      );

      if (currentAscention) {
        const { toAreaText = "", toPositionText = "" } = currentAscention;
        const diffArea = toAreaText !== companyArea.title;
        const diffPosition = toPositionText !== companyPosition.title;

        return diffArea || diffPosition ? (
          <div style={{ paddingTop: "15px" }}>
            <Alert severity="warning" className={classes.alertFiled}>
              {intl.formatMessage(messages.pb_warning_different_history)}
            </Alert>
          </div>
        ) : null;
      }
    }

    return null;
  };

  const roleLegendInfo = {
    label: intl.formatMessage(messages.form_role),
    fillColor: "#8884d8",
  };

  const areaLegendInfo = {
    label: intl.formatMessage(messages.form_area),
    fillColor: "#ffbb28",
  };

  const displayTrajectoryBox = () => {
    return (
      <div className="box box-primary">
        <div className="box-header with-border">
          <h3 className="box-title">
            <i className="fas fa-chart-line" />
            {` ${intl.formatMessage(messages.career_trajectory)}`}
          </h3>
          {saveButton && (
            <div className="pull-right">
              <button className="btn btn-purple" onClick={(e) => onSave(e)}>
                {intl.formatMessage(messages.globalSave)}
              </button>
            </div>
          )}
        </div>
        <div className="box-body">
          <div className="col-xs-12">
            <h5 style={{ marginBottom: "-10px" }}>
              {intl.formatMessage(messages.table_professional_history)}
            </h5>
            {checkDifferentUserProfile()}
            <table className="simple-table">
              <thead>
                <tr>
                  <td className="fixed-table-date">
                    {intl.formatMessage(messages.table_start_date)}
                  </td>
                  <td className="fixed-table-date">
                    {intl.formatMessage(messages.table_end_date)}
                  </td>
                  <td style={{ width: "500px" }}>
                    {intl.formatMessage(messages.table_position)}
                    <div>
                      <ul style={{ padding: "0px" }}>
                        <SimpleLegend
                          size={10}
                          label={roleLegendInfo.label}
                          fillColor={roleLegendInfo.fillColor}
                        />
                        <SimpleLegend
                          size={10}
                          label={areaLegendInfo.label}
                          fillColor={areaLegendInfo.fillColor}
                        />
                      </ul>
                    </div>
                  </td>
                  <td>
                    {intl.formatMessage(messages.table_professional_leader)}
                  </td>
                  <td align="center" className="fixed-table-action">
                    {intl.formatMessage(messages.table_edit)}
                  </td>
                  <td align="center" className="fixed-table-action">
                    {intl.formatMessage(messages.table_remove)}
                  </td>
                </tr>
              </thead>
              <tbody>
                {professionalAscentions &&
                  professionalAscentions.map(
                    (professionalAscentionInfo, index) => {
                      const {
                        fromDate,
                        toDate,
                        toPositionText,
                        toAreaText,
                        currentRole,
                        professionalLeader,
                      } = professionalAscentionInfo;

                      const professionalLeaderInfo = getProfessionalInfo(
                        professionalLeader,
                      );

                      const professionalMemberInfo = getMemberInfoById(
                        professionalLeaderInfo.id,
                        companyMembers,
                      );

                      return (
                        <tr key={index}>
                          <td align="center">
                            {moment(fromDate).format("LL")}
                          </td>
                          <td align="center">
                            {currentRole
                              ? intl.formatMessage(
                                  messages.table_current_position,
                                )
                              : moment(toDate).format("LL")}
                          </td>
                          <td align="center">
                            <h5>{toPositionText}</h5>
                            {toAreaText}
                          </td>
                          <td>
                            {professionalMemberInfo.name
                              ? professionalMemberInfo.name
                              : intl.formatMessage(
                                  messages.leader_not_selected,
                                )}
                          </td>
                          <td align="center">
                            <div
                              style={{ cursor: "pointer" }}
                              title={intl.formatMessage(messages.clickToEdit)}
                              onClick={() => openEditProfessionalCarrer(index)}
                            >
                              <i className="fas fa-pencil-alt" />
                            </div>
                          </td>
                          <td align="center">
                            {professionalAscentions.length !== index + 1 && (
                              <div
                                style={{ cursor: "pointer" }}
                                title={intl.formatMessage(
                                  messages.table_remove,
                                )}
                                onClick={() =>
                                  handleDeleteProfessionalCarrer(index)
                                }
                              >
                                <i
                                  className="far fa-trash-alt"
                                  style={{ color: "#ad5656" }}
                                />
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    },
                  )}
                {!professionalAscentions ||
                professionalAscentions.length === 0 ? (
                  <tr>
                    <td colSpan="6">
                      {intl.formatMessage(messages.no_recorded_history)}
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
            <div
              className="btn btn-gray"
              onClick={() => openProfHistoryModal()}
            >
              {intl.formatMessage(messages.buttonAddNew)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const displaySalaryBox = () => {
    return (
      <div className="box box-primary">
        <div className="box-header with-border">
          <h3 className="box-title">
            <i className="fas fa-clipboard-list" />
            {` ${intl.formatMessage(messages.salary_history)}`}
          </h3>
          {saveButton && (
            <div className="pull-right">
              <button className="btn btn-purple" onClick={(e) => onSave(e)}>
                {intl.formatMessage(messages.globalSave)}
              </button>
            </div>
          )}
        </div>
        <div className="box-body">
          <div className="col-xs-4">
            <MaterialTextField
              id="grossRemuneration"
              type="number"
              label={intl.formatMessage(messages.form_gross_remuneration)}
              value={salaryTrajectory.remuneration}
              onChange={(e) =>
                setSalaryTrajectory({
                  ...salaryTrajectory,
                  remuneration: e.target.value,
                })
              }
            />
          </div>
          <div className="col-xs-4">
            <MaterialSingleSelect
              id="marketEvaluation"
              label={intl.formatMessage(messages.form_market_evaluation)}
              value={salaryTrajectory.marketEvaluation}
              onChange={(e) =>
                setSalaryTrajectory({
                  ...salaryTrajectory,
                  marketEvaluation: e.target.value,
                })
              }
              options={marketEvaluationOptions}
            />
          </div>
          <div className="col-xs-4">
            <MaterialTextField
              id="averageMarketEvaluation"
              type="number"
              label={intl.formatMessage(
                messages.form_average_market_evaluation,
              )}
              value={salaryTrajectory.averageMarketEvaluation}
              onChange={(e) =>
                setSalaryTrajectory({
                  ...salaryTrajectory,
                  averageMarketEvaluation: e.target.value,
                })
              }
            />
          </div>
          <div className="col-xs-12">
            <table className="simple-table">
              <thead>
                <tr>
                  <td className="fixed-table-date" style={{ width: "100px" }}>
                    {intl.formatMessage(messages.table_modification_date)}
                  </td>
                  <td className="fixed-table-price" style={{ width: "100px" }}>
                    R$ {intl.formatMessage(messages.table_adjusted_value)}
                  </td>
                  <td
                    align="center"
                    className="fixed-table-price"
                    style={{ width: "100px" }}
                  >
                    {intl.formatMessage(messages.table_adjusted_percent)}
                  </td>
                  <td>{intl.formatMessage(messages.table_reason)}</td>
                  <td align="center" className="fixed-table-action">
                    {intl.formatMessage(messages.table_edit)}
                  </td>
                  <td align="center" className="fixed-table-action">
                    {intl.formatMessage(messages.table_remove)}
                  </td>
                </tr>
              </thead>
              <tbody>
                {remunerationHistories &&
                  remunerationHistories.map(
                    ({ date, newRemuneration, reason }, index) => {
                      const lastHistoryIndex = parseInt(index + 1, 10);
                      const lastRemuneration = remunerationHistories[
                        lastHistoryIndex
                      ]
                        ? remunerationHistories[lastHistoryIndex]
                            .newRemuneration
                        : null;
                      const percentageDiff = lastRemuneration
                        ? getPercentage(newRemuneration, lastRemuneration)
                        : 0;

                      return (
                        <tr key={index}>
                          <td align="center" className="fixed-table-date">
                            {moment(date).format("LL")}
                          </td>
                          <td align="right" className="fixed-table-price">
                            {newRemuneration.toFixed(2)}
                          </td>
                          <td align="center">{percentageDiff.toFixed(1)}%</td>
                          <td align="left">{reason}</td>
                          <td align="center">
                            <div
                              style={{ cursor: "pointer" }}
                              title={intl.formatMessage(messages.clickToEdit)}
                              onClick={() => openEditSalaryHistory(index)}
                            >
                              <i className="fas fa-pencil-alt" />
                            </div>
                          </td>
                          <td align="center">
                            <div
                              style={{ cursor: "pointer" }}
                              title={intl.formatMessage(messages.table_remove)}
                              onClick={() => handleDeleteSalaryHistory(index)}
                            >
                              <i
                                className="far fa-trash-alt"
                                style={{ color: "#ad5656" }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    },
                  )}
                {!remunerationHistories ||
                remunerationHistories.length === 0 ? (
                  <tr>
                    <td colSpan="6">
                      {intl.formatMessage(messages.no_recorded_history)}
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
            <div
              className="btn btn-gray"
              onClick={() => toggleSalaryHistoryModal()}
            >
              {intl.formatMessage(messages.buttonAddNew)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {displaySalaryHistoryModal()}
      {displayProfHistoryModal()}
      {displayDeleteModal()}
      {displayTrajectoryBox()}
      {displaySalaryBox()}
    </>
  );
};

SalaryTrajectoryField.propTypes = {
  saveButton: PropTypes.bool,
  onSave: PropTypes.func,
};

export default injectIntl(SalaryTrajectoryField);
