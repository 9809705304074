import React, { useState } from "react";
import { injectIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";

import Avatar from "./Avatar";

const messages = defineMessages({
  global_undefined: {
    id: "global.undefined",
  },
});

const FilterEvaluationCard = (props) => {
  const [filterSlug, setFilterSlug] = useState(null);

  const {
    cardTitle,
    headerFilters,
    bodyTable,
    fillColor,
    undefinedMessage,
    intl,
  } = props;

  const updateFilterSlug = (slug) => {
    setFilterSlug(slug !== filterSlug ? slug : null);
  };

  const renderTableLines = (values) => {
    let linesList = null;
    const defaultLineStyle = {
      opacity: "1",
      height: "30px",
      padding: "0 0 0 10px",
      margin: "5px 0 10px 0",
    };
    const hiddenLineStyle = {
      opacity: "0",
      height: "0px",
      padding: "0",
      margin: "0",
    };

    if (values && values.length > 0) {
      linesList = values.map((evaluation, index) => {
        return (
          <div
            key={index}
            className="simple-line"
            onClick={
              evaluation.clickFunction ? () => evaluation.clickFunction() : null
            }
            style={evaluation.show ? defaultLineStyle : hiddenLineStyle}
          >
            <div
              className="title"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Avatar
                src={evaluation.avatarBlobId}
                style={{ width: "25px", height: "25px", marginRight: "5px" }}
              />
              <span>
                {evaluation.label
                  ? evaluation.label
                  : undefinedMessage ||
                    intl.formatMessage(messages.global_undefined)}
              </span>
            </div>
            {evaluation.iconLetter && (
              <div className="right-label">{evaluation.iconLetter}</div>
            )}
          </div>
        );
      });
    }

    return <div className="simple-lines-table">{linesList}</div>;
  };

  const getFilteredBody = (slug) => {
    let finalValues = [];

    bodyTable.values.forEach((item) => {
      finalValues = [
        ...finalValues,
        { ...item, show: item.slugs.indexOf(slug) > -1 || !slug },
      ];
    });

    return finalValues;
  };

  const displayTable = () => {
    const bodyValues = getFilteredBody(filterSlug);

    return (
      <div className="simple-card-table">
        {headerFilters.map(
          ({ label, value, slug, bottomLabel, bgColor }, index) => {
            const defaultCardValueStyle = {
              backgroundColor: bgColor ? bgColor : "#eee",
              borderLeft: "5px solid transparent",
            };

            return (
              <div
                className="col-xs-4 filter-field"
                key={index}
                onClick={() => updateFilterSlug(slug)}
              >
                <h6 align="center" className="card-title">
                  {label}
                </h6>
                <div
                  className="card-value"
                  backgroundColor={defaultCardValueStyle.backgroundColor}
                  style={
                    slug === filterSlug
                      ? {
                          ...defaultCardValueStyle,
                          borderLeft: `5px solid ${fillColor}`,
                        }
                      : { ...defaultCardValueStyle }
                  }
                >
                  {value}
                  {bottomLabel && (
                    <div className="label label-bottom">{bottomLabel}</div>
                  )}
                </div>
              </div>
            );
          },
        )}
        <div className="col-xs-12" align="left">
          <h5 className="card-title" align="left">
            {bodyTable.title}
          </h5>
          {renderTableLines(bodyValues)}
        </div>
      </div>
    );
  };

  return (
    <div className="evaluation-card">
      <div
        className="card-header"
        style={{ borderTop: `8px solid ${fillColor}` }}
      >
        <div className="card-title">
          <h5>{cardTitle}</h5>
        </div>
      </div>
      <div className="card-body">
        <div className="row">{displayTable()}</div>
      </div>
    </div>
  );
};

FilterEvaluationCard.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  headerFilters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any,
      value: PropTypes.any.isRequired,
      slug: PropTypes.string.isRequired,
      bottomLabel: PropTypes.string,
      bgColor: PropTypes.string,
    }),
  ).isRequired,
  bodyTable: PropTypes.shape({
    title: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.any,
        slugs: PropTypes.arrayOf(PropTypes.string).isRequired,
        clickFunction: PropTypes.func,
        iconLetter: PropTypes.string,
      }),
    ).isRequired,
  }),
  fillColor: PropTypes.string,
  undefinedMessage: PropTypes.string,
};

FilterEvaluationCard.defaultProps = {
  cardTitle: "CARD TITLE",
  headerFilter: [],
  bodyTable: [],
  fillColor: "#ccc",
  undefinedMessage: null,
};

export default injectIntl(FilterEvaluationCard);
