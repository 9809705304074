const en = {
  pp_main_title: "Project Prioritization",
  title_group_priorization: "Group and Prioritization",
  text_select_criteria: "Select Criteria",
  pp_add_new_theme: "Add project group",
  pp_add_new_question: "Add project type",
  pp_add_new_insight: "Add project",
};

const es = {
  pp_main_title: "Priorización de Proyectos",
  title_group_priorization: "Grupo y priorización",
  text_select_criteria: "Seleccionar criterios",
  pp_add_new_theme: "Agregar grupo de proyectos",
  pp_add_new_question: "Agregar tipo de proyecto",
  pp_add_new_insight: "Agregar proyecto",
};

const pt = {
  pp_main_title: "Priorização de Projetos",
  title_group_priorization: "Grupo e Priorização",
  text_select_criteria: "Selecionar Críteria",
  pp_add_new_theme: "Adicionar grupo de projetos",
  pp_add_new_question: "Adicionar tipo de projeto",
  pp_add_new_insight: "Adicionar projeto",
};

const translations = { en, es, pt };

export default translations;
