import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import utils from "../../../../utils/cpAnalysis";
import { getSelectedToolStates } from "../../../customMapStates";
import { addNewInsight } from "../../../../actions/insightActions";
import InsightSelectDialog from "../../../Common/CompetitiveAnalysis/forms/InsightSelectDialog";

const {
  customCenaryName,
  buildPatternTreeList,
  buildQuestionsTreeList,
} = utils;

const CompetitiveAnalysis = (props) => {
  const [treeList, setTreeList] = useState({});

  const {
    allQuestions,
    allAnswers,
    allSceneries,
    allThemes,
    params = {},
    clearViewCallback,
    addNewInsight,
    toolId,
    type,
  } = props;

  useEffect(() => {
    let newTreeList = {
      Cenary: {
        color: "#333",
        countText: "Cenários",
        icon: <i className="fas fa-tools" />,
        label: "Cenários",
        list: [
          ...allSceneries.map((scenerie) => {
            return {
              id: scenerie.id,
              title: customCenaryName(scenerie.name, toolId),
              parentId: null,
            };
          }),
        ],
      },
      Theme: {
        color: "#333",
        countText: "Temas",
        icon: <i className="fas fa-bars" />,
        label: "Temas",
        list: [...buildPatternTreeList(allThemes)],
      },
      Question: {
        color: "#333",
        countText: "Questões",
        icon: <i className="fas fa-bars" />,
        label: "Questões",
        list: [
          ...buildQuestionsTreeList(allSceneries, allQuestions, allThemes),
        ],
      },
      Insight: {
        color: "#333",
        countText: "Insights",
        icon: <i className="fas fa-bars" />,
        label: "Insights",
        list: [...buildPatternTreeList(allAnswers)],
      },
    };

    if (!_.isEqual(treeList, newTreeList)) setTreeList(newTreeList);
  }, [allQuestions, allAnswers, allSceneries, allThemes]);

  const { insightQuestion = {} } = params;

  const { id: currentQuestionId = "" } = insightQuestion;

  let treeOrder = ["Cenary", "Theme", "Question", "Insight"];

  const addNewInsightWrapper = async (title = "") => {
    await addNewInsight(
      {
        id: null,
        text: title,
        name: title,
        type: "insight",
      },
      currentQuestionId,
    );
  };

  const handleSelectedInsight = ({ insights = [] }) => {
    insights.forEach(({ title = "" }) => addNewInsightWrapper(title));

    clearDialogControll();
  };

  const saveNewInsight = async ({ title }) => {
    await addNewInsightWrapper(title);

    clearDialogControll();
  };

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
  };

  const essentialProps = {
    afterConfirm: handleSelectedInsight,
    afterCancel: clearDialogControll,
    saveNewInsight,
    treeList,
    treeOrder,
    clearDialogControll,
  };

  return <InsightSelectDialog open={true} {...essentialProps} />;
};

const mapStateToProps = (state) => {
  const {
    selectedTool,
    allSceneries,
    allAnswers,
    allThemes,
    allQuestions,
  } = getSelectedToolStates(state);

  const { id: toolId = "" } = selectedTool.tool || {};

  return {
    allAnswers,
    allSceneries,
    allQuestions,
    allThemes,
    toolId,
  };
};

export default connect(mapStateToProps, { addNewInsight })(CompetitiveAnalysis);
