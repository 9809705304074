import React from "react";
import PropTypes from "prop-types";
import SimpleCheckbox from "../../../../../../Common/SimpleCheckbox";
import {
  getEvaluationTypeOptions,
  getEvaluationConfigByType,
} from "../../../../../../../utils/skillsAssessment/constants";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import { filterParticipantsByStages } from "../../../../../../../utils/skillsAssessment/filter";
import { displayConflictedParticipants } from "../../../../../../../utils/skillsAssessment/display";

const TableEvaluationType = ({
  toolAdministration,
  updateEvaluationType,
  updateEvaluationWeights,
  updateEvaluationTypeAndWorkflowStage,
  setConfirmationDialog,
  resetConfirmDialog,
}) => {
  const typeOptions = getEvaluationTypeOptions();

  const {
    evaluationType: currentEvaluationType,
    evaluationWeights,
    participants,
  } = toolAdministration;

  const {
    enableWeights,
    enableSecondEvaluator: enableSecEval,
    enableThirdEvaluator: enableThirdEval,
  } = getEvaluationConfigByType(currentEvaluationType);

  const handleEvaluationTypeUpdate = (newEvaluationType) => {
    const filtered = filterParticipantsByStages(participants, [
      "EVALUATORS",
      "APPROVAL",
      "CONCLUDED",
    ]);

    const { enableWorkflowStageUpdate } = getEvaluationConfigByType(
      currentEvaluationType,
    );

    if (filtered.length > 0 && enableWorkflowStageUpdate) {
      setConfirmationDialog({
        open: true,
        title: "Alterar o Tipo de Avaliação",
        description: displayConflictedParticipants(filtered, "Gestores"),
        onConfirm: () => {
          updateEvaluationTypeAndWorkflowStage(
            newEvaluationType,
            "MANAGERS",
            filtered,
          );
          resetConfirmDialog();
        },
      });
    } else {
      updateEvaluationType(newEvaluationType);
    }
  };

  const getWeightCol = (field) => {
    return (
      <SimpleExcelCollumn
        id={`${field}Weight`}
        label={`${field}Weight`}
        type="number"
        value={evaluationWeights[field] || 0}
        tdStyle={{ width: "80px" }}
        align="center"
        onChange={(e) => updateEvaluationWeights(field, e.target.value)}
        textEndAdorment="%"
      />
    );
  };

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="100">TIPO DA AVALIAÇÃO</td>
        </tr>
      </thead>
      <tbody>
        {typeOptions?.map(({ value, label }) => {
          const { enableSecondEvaluator, enableThirdEvaluator } =
            getEvaluationConfigByType(value);

          return (
            <tr key={value}>
              <td width="35px">
                <SimpleCheckbox
                  checked={value === currentEvaluationType}
                  onChange={() => handleEvaluationTypeUpdate(value)}
                />
              </td>
              <td align="center">{label}</td>
              <td align="center" width="100">
                Autoavaliação
              </td>
              <td align="center" width="100">
                Gestor
              </td>
              <td align="center" width="100">
                {enableSecondEvaluator && "Cliente"}
              </td>
              <td align="center" width="100">
                {enableThirdEvaluator && "Fornecedor"}
              </td>
            </tr>
          );
        })}
        {enableWeights && (
          <tr>
            <td colSpan="2">PESOS</td>
            {getWeightCol("selfEvaluation")}
            {getWeightCol("manager")}
            {enableSecEval && getWeightCol("secondEvaluator")}
            {enableThirdEval && getWeightCol("thirdEvaluator")}
          </tr>
        )}
      </tbody>
    </table>
  );
};

TableEvaluationType.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
  updateEvaluationType: PropTypes.func.isRequired,
  updateEvaluationWeights: PropTypes.func.isRequired,
  updateEvaluationTypeAndWorkflowStage: PropTypes.func.isRequired,
  setConfirmationDialog: PropTypes.func.isRequired,
  resetConfirmDialog: PropTypes.func.isRequired,
};

export default TableEvaluationType;
