import { GOALS_AND_BONUS, KPIS } from "../../../constants/tools.constants";
import {
  getGoalBonusInsightConnections,
  getKpiInsightConnections,
} from "../../../utils/insightMiddleware";

export const getConnectedInsights = (
  insightInfo = {},
  toolTypeId,
  selectedToolId,
) => {
  switch (toolTypeId) {
    case GOALS_AND_BONUS:
      return getGoalBonusInsightConnections(insightInfo, selectedToolId);
    case KPIS:
      return getKpiInsightConnections(insightInfo, selectedToolId);
    default:
      return { comments: [insightInfo?.id] };
  }
};
