import { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getKpiStates } from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";
import kpiUtils from "../../../../utils/kpis";
import { updateInsightName } from "../../../../actions/insightActions";

const {
  getObjectDifference,
  getObjectInfoById,
  getOnlySlugValues,
  removeObjSlug,
} = utils;

const { buildFormAdmConfigBody, getKpiConfigBySelectedToolId } = kpiUtils;

const defaultForm = buildFormAdmConfigBody({});

const KpiWatcher = (props) => {
  const [kpiAdministration, setKpiAdministration] = useState(defaultForm);
  const [firstValidation, setFirstValidation] = useState(false);

  const {
    allKpiAdministration = [],
    selectedTool = {},
    allAnswers = {},
    updateInsightName,
  } = props;

  useEffect(() => {
    let toolAdministration = getKpiConfigBySelectedToolId(
      selectedTool.id,
      allKpiAdministration,
    );
    toolAdministration = buildFormAdmConfigBody(toolAdministration);

    const isAdmObjDiff = !_.isEqual(toolAdministration, kpiAdministration);

    if (
      toolAdministration &&
      toolAdministration.goals &&
      Object.keys(allAnswers).length > 0
    ) {
      if (isAdmObjDiff || !firstValidation) {
        const compareBase = removeObjSlug(kpiAdministration.goals, "id");

        const diff = getObjectDifference(toolAdministration.goals, compareBase);

        if (diff) {
          if (!firstValidation) setFirstValidation(true);

          const watchGoals = getOnlySlugValues(diff, "id");

          if (watchGoals.length > 0) {
            Object.keys(allAnswers).forEach((questionId) => {
              const questionAnswers = allAnswers[questionId];

              questionAnswers.forEach((insightInfo) => {
                const { kpiConfigurationGoalId } = insightInfo;
                const relatedGoalId =
                  watchGoals[watchGoals.indexOf(kpiConfigurationGoalId)] ||
                  null;

                if (relatedGoalId && insightInfo.text) {
                  const goalInfo = getObjectInfoById(
                    relatedGoalId,
                    toolAdministration.goals,
                    "id",
                  );

                  if (goalInfo && goalInfo.title !== insightInfo.text) {
                    updateInsightName(
                      questionId,
                      insightInfo.id,
                      goalInfo.title,
                    );
                  }
                }
              });
            });
          }
        }

        if (isAdmObjDiff) setKpiAdministration(toolAdministration);
      }
    }
  }, [
    allKpiAdministration,
    allAnswers,
    firstValidation,
    kpiAdministration,
    selectedTool.id,
    updateInsightName,
  ]);

  return null;
};

const mapStateToProps = (state) => {
  const { allKpiAdministration } = getKpiStates(state);

  return { allKpiAdministration };
};

export default connect(mapStateToProps, { updateInsightName })(KpiWatcher);
