import React, { useState, useCallback, useRef, useEffect } from "react";
import PropType from "prop-types";

import { Row } from "react-bootstrap";
import { deleteChecklistTask } from "../../../../../../actions/projectEvaluationActions";

import MenuChecklist from "../MenuCheclist";
import { SimpleMenu } from "../../../../../Common/SimpleMenu";

import {
  Container,
  TitleChecklist,
  BoxHeader,
  ButtonAddTask,
  WrapperList,
  CheckBox,
  BoxList,
  DisplayList,
  BoxEditing,
  TextArea,
  TextAreaDescription,
  AlertStyled,
  MenuBox,
  InputTask,
  IconInsert,
} from "./styles";
import { ruleOfThree } from "../../../../../../utils/MathUtils";

const useOutsideToCloseEdit = (ref, next) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        next();
      }
    }

    document.addEventListener("mouseup", handleClickOutside);

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [ref, next]);
};

const defaultNewTask = {
  type: "",
  text: "",
  description: "",
  order: 1,
};

const Checklist = ({ onChangeChecklist, items }) => {
  const [editing, setEditing] = useState(false);
  const [editingDescription, setEditingDescription] = useState(null);
  const [optionsPopover, setOptionsPopover] = useState({
    isOpenPopover: false,
    index: null,
  });
  const [newTask, setNewTask] = useState(defaultNewTask);

  const inputEditingRef = useRef(null);
  const boxEditing = useRef(null);
  const textareaRef = useRef(null);
  const textareaDescriptionRef = useRef(null);

  const handleEditing = useCallback(() => {
    setEditing(!editing);
  }, [editing]);

  const handleCloseAndSaveTask = useCallback(() => {
    if (newTask.text.length > 0) {
      onChangeChecklist([...items, newTask]);
    }

    setNewTask(defaultNewTask);
    setEditing(false);
  }, [newTask, onChangeChecklist, items]);

  useOutsideToCloseEdit(boxEditing, handleCloseAndSaveTask);

  useEffect(() => {
    if (inputEditingRef.current) inputEditingRef.current.focus();
  }, [editing]);

  useEffect(() => {
    if (textareaDescriptionRef.current) {
      textareaDescriptionRef.current.focus();
    }
  }, [editingDescription]);

  const changeTypeCheckBox = useCallback((type) => {
    if (type === "") {
      return "checked";
    }
    if (type === "checked") {
      return "canceled";
    }

    return "";
  }, []);

  const handleTypeCheckBoxSelected = useCallback(
    (selectedIndex) => {
      const newList = items.map((item, index) => {
        if (index === selectedIndex) {
          return { ...item, type: changeTypeCheckBox(item.type) };
        }

        return item;
      });

      onChangeChecklist(newList);
    },
    [items, changeTypeCheckBox, onChangeChecklist],
  );

  const handleChangeTaskList = (e) => {
    const { value } = e.target;

    const order = items.length + 1;

    setNewTask({
      type: newTask.type,
      description: newTask.description,
      order,
      text: value,
    });
  };

  const handleChangeType = () => {
    setNewTask({
      ...newTask,
      type: changeTypeCheckBox(newTask.type),
    });
  };

  const handleKeyPress = useCallback(
    (e) => {
      if (editing && e.key === "Enter") {
        onChangeChecklist([...items, newTask]);
        setNewTask(defaultNewTask);
      }
    },
    [items, newTask, editing, onChangeChecklist],
  );

  const sendTaskOnClick = useCallback(() => {
    onChangeChecklist([...items, newTask]);
    setNewTask(defaultNewTask);
  }, [items, newTask, onChangeChecklist]);

  const handleList = useCallback(
    (e, index, type) => {
      const { value } = e.target;

      const cloneList = [...items];

      cloneList[index][type] = value;

      onChangeChecklist(cloneList);
    },
    [items, onChangeChecklist],
  );

  const openEditingDescriptionClosePopover = useCallback(
    (id, selectedIndex, next) => {
      setEditingDescription(selectedIndex);

      next();
    },
    [],
  );

  const removeSelectedTask = useCallback(
    (idTask, selectedIndex, next) => {
      const filteredList = items.filter(
        (item, index) => index !== selectedIndex,
      );

      onChangeChecklist(filteredList);

      deleteChecklistTask(idTask);

      next();
    },
    [items, onChangeChecklist],
  );

  const getOptionsPopover = useCallback((isOpenPopover, index) => {
    setOptionsPopover({ isOpenPopover, index });
  }, []);

  const optionsMenuChecklist = [
    {
      text: "Adicionar descrição",
      onClick: openEditingDescriptionClosePopover,
      icon: "fas fa-plus",
    },
    {
      text: "Remover",
      onClick: removeSelectedTask,
      icon: "fas fa-trash",
    },
  ];

  const countTypes = (listCheck) => {
    let checked = 0;
    let canceled = 0;

    listCheck.forEach(({ type }) => {
      if (type === "checked") checked++;
      if (type === "canceled") canceled++;
    });

    return { checked, canceled };
  };

  const getProgress = () => {
    const { checked = 0, canceled = 0 } = countTypes(items);

    const concludedMessage = `(${checked}/${items.length}) ${ruleOfThree(
      items.length,
      checked,
      100,
    ).toFixed(0)}% concluída.`;

    const canceledMessage = `(${canceled}/${items.length}) ${ruleOfThree(
      items.length,
      canceled,
      100,
    ).toFixed(0)}% cancelada.`;

    const showConcluded = checked > 0;
    const showCanceled = canceled > 0;

    return (
      <BoxHeader>
        {showConcluded && <Row>{concludedMessage}</Row>}
        {showCanceled && <Row>{canceledMessage}</Row>}
      </BoxHeader>
    );
  };

  const orderedItems = items.sort(
    (a, b) => a.order > b.order || a?.text.localeCompare(b?.text),
  );

  return (
    <Container>
      <TitleChecklist>Lista de tarefas</TitleChecklist>
      {items.length > 0 && getProgress()}
      <WrapperList onKeyPress={handleKeyPress}>
        {orderedItems.length
          ? orderedItems.map((item, index) => (
              <DisplayList key={item.id || item.order}>
                <CheckBox
                  onClick={() => handleTypeCheckBoxSelected(index)}
                  type={item.type}
                >
                  {item.type === "checked" && <i className="fas fa-check" />}
                  {item.type === "canceled" && <i className="fas fa-times" />}
                </CheckBox>
                <BoxList>
                  <TextArea
                    name="text"
                    rows={1}
                    value={item.text}
                    placeholder="Digite uma atividade..."
                    ref={textareaRef}
                    onChange={(e) => handleList(e, index, "text")}
                  >
                    {item.text}
                  </TextArea>
                  {(item.description || editingDescription === index) && (
                    <TextAreaDescription
                      name="description"
                      rows={1}
                      value={item.description}
                      ref={textareaDescriptionRef}
                      onBlur={() => setEditingDescription(null)}
                      placeholder="Digite uma observação..."
                      onChange={(e) => handleList(e, index, "description")}
                    >
                      {item.description}
                    </TextAreaDescription>
                  )}
                </BoxList>
                <MenuBox
                  isOpenPopover={
                    optionsPopover.isOpenPopover &&
                    optionsPopover.index === index
                  }
                >
                  <SimpleMenu>
                    <MenuChecklist
                      options={optionsMenuChecklist}
                      getOptionsPopover={getOptionsPopover}
                      id={item.id}
                      index={index}
                    />
                  </SimpleMenu>
                </MenuBox>
              </DisplayList>
            ))
          : !editing && (
              <AlertStyled icon={false} color="info">
                Nenhuma tarefa cadastrada
              </AlertStyled>
            )}
        {editing && (
          <BoxEditing ref={boxEditing}>
            <CheckBox onClick={handleChangeType}>
              {newTask.type === "checked" && <i className="fas fa-check" />}
              {newTask.type === "canceled" && <i className="fas fa-times" />}
            </CheckBox>
            <InputTask
              name="text"
              rows={1}
              placeholder="Digite uma atividade.."
              ref={inputEditingRef}
              onChange={handleChangeTaskList}
              value={newTask.text}
            />
            <IconInsert className="fas fa-check" onClick={sendTaskOnClick} />
          </BoxEditing>
        )}
      </WrapperList>
      <BoxHeader>
        <ButtonAddTask onClick={handleEditing}>Adicionar</ButtonAddTask>
      </BoxHeader>
    </Container>
  );
};

export default Checklist;

Checklist.propTypes = {
  onChangeChecklist: PropType.func.isRequired,
  items: PropType.array,
};

Checklist.defaultProps = {
  items: [],
};
