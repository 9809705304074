import React from "react";
import { useWindowSize } from "../../../../../utils/customHooks";
import {
  displayTransformValue,
  getHitTargetInfo,
} from "../../../../../utils/kpis/display";
import { getLastPeriodDeviation } from "../../../../../utils/kpis/calc";
import {
  getToFixed,
  removeUnecessaryDecimals,
} from "../../../../../utils/MathUtils";
import { getTargetCollumn } from "./displayUtils";

const DisplayTotalLine = ({
  goalDescriptions = {},
  targetsConfig = {},
  displayTargets = {},
  distribution = 0,
  totalTarget = 0,
  lastYearTotal = 0,
  lastYearTotalDisplay = 0,
  totalPercentage = 0,
  hideDistribution,
  hideLastPeriod,
  hideCurrentDevation,
  totalLabel = "",
  notStarted,
  invalidDistribution,
  showCommentCol,
}) => {
  const windowSize = useWindowSize();
  const screenWidth = windowSize[0];
  const fontSizes = screenWidth < 1400 ? "10px" : "12px";

  const tdStyles = {
    backgroundColor: "#fcfcfc",
    color: "#222",
    textAlign: "right",
    fontWeight: "500",
    fontSize: fontSizes,
    padding: "10px",
  };

  const hitTargetInfo = getHitTargetInfo(
    totalPercentage,
    targetsConfig,
    notStarted,
  );

  const {
    displayDeviation: lastPeriodDeviation,
    color: lpDeviationColor,
  } = getLastPeriodDeviation(
    lastYearTotal,
    totalTarget,
    goalDescriptions.target,
    notStarted,
  );

  const {
    displayDeviation: fullPeriodDeviation,
    color: fullDeviationColor,
  } = getLastPeriodDeviation(
    displayTargets.appropriated,
    totalTarget,
    goalDescriptions.target,
    notStarted,
  );

  return (
    <tr className="block-white-space block-hover border-top-division">
      <td
        className="titled"
        style={{
          fontWeight: "500",
          ...tdStyles,
          textAlign: "left",
        }}
      >
        {totalLabel}
      </td>
      {!hideLastPeriod && (
        <>
          <td
            style={{
              ...tdStyles,
              minWidth: "90px",
              maxWidth: "120px",
              textAlign:
                goalDescriptions.measurementUnity === "DATE"
                  ? "center"
                  : "right",
            }}
          >
            {displayTransformValue(
              lastYearTotalDisplay,
              goalDescriptions.measurementUnity,
            )}
          </td>
          <td
            style={{
              ...tdStyles,
              color: lpDeviationColor,
            }}
          >
            {lastPeriodDeviation}
          </td>
        </>
      )}
      <td style={tdStyles}>
        {displayTransformValue(
          displayTargets.gate,
          goalDescriptions.measurementUnity,
        )}
      </td>
      <td style={tdStyles}>
        {displayTransformValue(
          displayTargets.appropriated,
          goalDescriptions.measurementUnity,
        )}
      </td>
      <td style={tdStyles}>
        {displayTransformValue(
          displayTargets.exceeded,
          goalDescriptions.measurementUnity,
        )}
      </td>
      {!hideDistribution && (
        <td
          style={
            invalidDistribution ? { ...tdStyles, color: "#ff6347" } : tdStyles
          }
        >
          {`${removeUnecessaryDecimals(getToFixed(distribution, 2))}%`}
        </td>
      )}
      <td
        style={{
          ...tdStyles,
          textAlign:
            goalDescriptions.measurementUnity === "DATE" ? "center" : "right",
          minWidth: "90px",
        }}
      >
        {displayTransformValue(totalTarget, goalDescriptions.measurementUnity)}
      </td>
      {!hideCurrentDevation && (
        <td
          style={{
            ...tdStyles,
            color: fullDeviationColor,
          }}
        >
          {fullPeriodDeviation}
        </td>
      )}
      <td style={{ ...tdStyles, padding: "10px 14px" }}>
        {`${getToFixed(totalPercentage, 1)}%`}
      </td>
      {getTargetCollumn(hitTargetInfo)}
      {showCommentCol && <td />}
    </tr>
  );
};

export default DisplayTotalLine;
