import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import MaterialSingleSelect from "../../MaterialSingleSelect";
import FormDialog from "../../FormDialog";
import utils from "../../../../utils/toolUtils";
import KeyForm from "../../../Tool/ToolTabs/CustomFields/Okr/tabs/forms/KeyForm";
import { fetchOkrConfiguration } from "../../../../actions/okrActions";

const { getErrorMessage } = utils;

const useStyles = makeStyles(() =>
  createStyles({
    addButton: {
      fontSize: "12px",
    },
  }),
);

const messages = defineMessages({
  dialog_title_new_insight: { id: "dialog_title_new_insight" },
  btn_modal_register_new: { id: "btn_modal_register_new" },
  dialog_error_insight_repeat: { id: "dialog_error_insight_repeat" },
});

const initialForm = {
  id: "",
};

const InsightKeyDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const [newThemeModal, setNewThemeModal] = useState(false);

  const {
    open = false,
    afterCancel,
    afterConfirm,
    addedKeys = [],
    intl,
    keyResults,
    okr,
    insightQuestionId,
    saveInsightKeyResult,
    fetchOkrConfiguration,
    selectedTool,
  } = props;

  const resetForm = () => {
    setForm(initialForm);
  };

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  useEffect(() => {
    fetchOkrConfiguration(selectedTool.id);
  }, [fetchOkrConfiguration, selectedTool.id]);

  const handleConfirm = () => {
    if (typeof afterConfirm === "function") afterConfirm(form);
  };

  const handleCancel = () => {
    if (typeof afterCancel === "function") afterCancel();
  };

  const handleUpdateForm = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const getFormErrors = () => {
    const errors = [];

    if ((!form.id || form.id === "empty") && form.id !== 0) {
      errors.push({ slug: "themeNotSelected" });
    }

    if (addedKeys.indexOf(form.id) > -1) {
      errors.push({
        slug: "duplicatedInsight",
        message: intl.formatMessage(messages.dialog_error_insight_repeat),
      });
    }

    return errors;
  };

  const buildScenerieOptions = () => {
    const finalOptions = [];

    if (keyResults.length > 0) {
      keyResults.forEach(({ id, title }) =>
        finalOptions.push({ value: id, label: title }),
      );
    }

    return finalOptions;
  };

  function getAllOptionsObjective() {
    const optionsObjective = [];

    if (okr.objectives)
      okr.objectives.forEach(({ id, title }) => {
        optionsObjective.push({ value: id, label: title });
      });

    return optionsObjective;
  }

  const toggleNewThemeModal = (toggleTo = true) => {
    setNewThemeModal(toggleTo);
  };

  const classes = useStyles();
  const formErrors = getFormErrors();

  return (
    <>
      <KeyForm
        open={newThemeModal}
        saveInsightKeyResult={saveInsightKeyResult}
        onCancel={() => toggleNewThemeModal(false)}
        isEditing={{ params: {} }}
        objectives={okr.objectives}
        insightQuestionId={insightQuestionId}
        getAllOptionsObjective={getAllOptionsObjective()}
      />
      <FormDialog
        open={open}
        title={intl.formatMessage(messages.dialog_title_new_insight)}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        blockConfirm={formErrors.length > 0}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialSingleSelect
              id="Scenerie"
              label={intl.formatMessage(messages.dialog_title_new_insight)}
              value={form.id}
              options={buildScenerieOptions()}
              onChange={(e) => handleUpdateForm("id", e.target.value)}
            />
            {getErrorMessage(["duplicatedInsight"], formErrors, "left", {
              height: "22px",
            })}
            <Button
              variant="outlined"
              className={classes.addButton}
              onClick={() => toggleNewThemeModal(!newThemeModal.open)}
            >
              <AddIcon /> {intl.formatMessage(messages.btn_modal_register_new)}
            </Button>
          </div>
        </div>
      </FormDialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedTool: state.selectedTool,
  };
};

export default connect(mapStateToProps, { fetchOkrConfiguration })(
  injectIntl(InsightKeyDialog),
);
