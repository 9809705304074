import { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import utils from "../../../../utils/toolUtils";
import okrUtils from "../../../../utils/okr";
import { updateCenary } from "../../../../actions/cenaryActions";

const {
  getObjectDifference,
  getObjectInfoById,
  getOnlySlugValues,
  removeObjSlug,
} = utils;

const { biuldAdmRestBody } = okrUtils;

const defaultForm = biuldAdmRestBody({ plans: [] });

const OkrWatcher = (props) => {
  const [okrPlans, setOkrPlans] = useState(defaultForm.plans);
  const [firstValidation, setFirstValidation] = useState(false);

  const {
    originOkrPlans = [],
    selectedTool = {},
    allSceneries = [],
    updateCenary,
  } = props;

  useEffect(() => {
    const restOkrAdministration = biuldAdmRestBody({ plans: originOkrPlans });
    const restOkrPlans = restOkrAdministration.plans;
    const isAdmObjDiff = !_.isEqual(restOkrPlans, okrPlans);

    if (restOkrPlans && allSceneries.length > 0) {
      if (isAdmObjDiff || !firstValidation) {
        const compareBase = removeObjSlug(okrPlans, "id");

        const diff = getObjectDifference(restOkrPlans, compareBase);

        if (diff) {
          if (!firstValidation) setFirstValidation(true);

          const watchPlans = getOnlySlugValues(diff, "id");

          if (watchPlans.length > 0) {
            allSceneries.forEach((scenaryInfo) => {
              const { okrConfigurationPlanId } = scenaryInfo;
              const relatedAdmScenarioId =
                watchPlans[watchPlans.indexOf(okrConfigurationPlanId)] || null;

              if (relatedAdmScenarioId && scenaryInfo.name) {
                const admScenarioInfo = getObjectInfoById(
                  relatedAdmScenarioId,
                  restOkrPlans,
                  "id",
                );

                if (
                  admScenarioInfo.organization.title &&
                  admScenarioInfo.organization.title !== scenaryInfo.name
                ) {
                  updateCenary(
                    {
                      ...scenaryInfo,
                      name: admScenarioInfo.organization.title,
                    },
                    selectedTool.id,
                  );
                }
              }
            });
          }
        }

        if (isAdmObjDiff) setOkrPlans(restOkrPlans);
      }
    }
  }, [
    originOkrPlans,
    allSceneries,
    firstValidation,
    selectedTool.id,
    okrPlans,
    updateCenary,
  ]);

  return null;
};

const mapStateToProps = (state) => {
  const { okr } = state;

  return { originOkrPlans: okr.okrPlans };
};

export default connect(mapStateToProps, { updateCenary })(OkrWatcher);
