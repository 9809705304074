import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  fetchTemplatesMilestones,
  saveTemplateMilestone,
  deleteTemplate,
  updateTemplate,
} from "../../../../../../../../actions/projectEvaluationActions";

import { CardTemplate } from "./CardTemplate";
import ModalCreateTemplate from "./ModalCreateTemplate";
import { ContainerTemplates } from "./styles";
import MaterialAlert from "../../../../../../../Common/MaterialAlert";

function Templates({ companyId, domainId, workspaceId, selectedToolId }) {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if ((domainId, workspaceId)) {
      fetchTemplatesMilestones({ domainId, workspaceId }).then((data) => {
        setTemplates(data);
      });
    }
  }, [domainId, workspaceId]);

  function onEdit(template) {
    setModal(true);
    setSelectedTemplate(template);
  }

  function handleCancel() {
    setSelectedTemplate({});
    setModal(false);
  }

  function factoryTemplate(templateFields, activities, projectEvaluation) {
    const { name, description, isPublic, id, createdBy } = templateFields;
    let activitiesType = null;

    const removedObjectResponsibleWithTypeNull = activities.map((activity) => {
      if (!activity.responsible?.type) {
        return {
          ...activity,
          responsible: null,
        };
      }

      return activity;
    });

    if (projectEvaluation.activitiesType === "Horas") activitiesType = "HOURS";
    if (projectEvaluation.activitiesType === "Tarefas")
      activitiesType = "TASKS";

    const renamedProjectEvaluation = {
      ...projectEvaluation,
      activitiesType,
    };

    return {
      id,
      name,
      createdBy,
      description,
      activitiesOptions: renamedProjectEvaluation,
      activities: removedObjectResponsibleWithTypeNull,
      isPublic,
      companyId,
      workspaceId,
      selectedToolId,
      domainId,
    };
  }

  function saveTemplate(templateFields, activities, projectEvaluation) {
    const templateMilestoneBody = factoryTemplate(
      templateFields,
      activities,
      projectEvaluation,
    );

    saveTemplateMilestone(templateMilestoneBody).then((data) => {
      setTemplates((prevTemplates) => [...prevTemplates, data]);
    });
  }

  function updateSelectedTemplate(
    templateFields,
    activities,
    projectEvaluation,
  ) {
    const templateMilestoneBody = factoryTemplate(
      templateFields,
      activities,
      projectEvaluation,
    );

    setTemplates((prevTemplates) =>
      prevTemplates.map((template) => {
        if (template.id === selectedTemplate.id) {
          return templateMilestoneBody;
        }

        return template;
      }),
    );

    updateTemplate(templateMilestoneBody);
  }

  function onConfirmModalTemplate(
    templateFields,
    activities,
    projectEvaluation,
  ) {
    if (selectedTemplate.id) {
      updateSelectedTemplate(templateFields, activities, projectEvaluation);
    } else {
      saveTemplate(templateFields, activities, projectEvaluation);
    }

    setModal(false);
  }

  function removeSelectedTemplate() {
    if (selectedTemplate.id) {
      deleteTemplate(selectedTemplate.id);
      setTemplates((prevTemplates) =>
        prevTemplates.filter((template) => template.id !== selectedTemplate.id),
      );
      setModal(false);
    }
  }

  return (
    <>
      <button
        className="btn btn-purple"
        style={{ margin: "20px 0" }}
        onClick={onEdit}
      >
        Adicionar template
      </button>
      <ContainerTemplates>
        {templates.length ? (
          templates.map((template) => (
            <CardTemplate
              onEdit={() => onEdit(template)}
              title={template.name}
              description={template.description}
              numberActivities={template.activities?.length}
              isPublic={template.isPublic}
              createdBy={template.createdBy?.fullName}
            />
          ))
        ) : (
          <MaterialAlert message="Nenhum template cadastrado" severity="info" />
        )}
      </ContainerTemplates>
      <ModalCreateTemplate
        open={modal}
        onCancel={handleCancel}
        selectedTemplate={selectedTemplate}
        onDeleteTemplate={removeSelectedTemplate}
        onConfirm={onConfirmModalTemplate}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  const { companyData, access, workspacesData, selectedTool } = state;

  return {
    companyId: companyData?.selectedCompany.id,
    domainId: access?.currentDomain?.id,
    workspaceId: workspacesData?.selectedWorkspace?.id,
    selectedToolId: selectedTool?.id,
  };
};

export default connect(mapStateToProps, {})(Templates);

Templates.propTypes = {
  companyId: PropTypes.number.isRequired,
  domainId: PropTypes.number.isRequired,
  workspaceId: PropTypes.number.isRequired,
  selectedToolId: PropTypes.number.isRequired,
};
