import React from "react";
import PropTypes from "prop-types";

const SimpleManagementList = ({ items, handleRemove, handleEdit }) => {
  return items.map((itemInfo, index) => {
    return (
      <div
        key={index}
        className="col-xs-12"
        style={{
          display: "flex",
          borderTop: "1px solid #ccc",
          borderBottom: "1px solid #ccc",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "5px",
        }}
      >
        <div style={{ fontWeight: "bold" }}>{itemInfo.title}</div>
        <div style={{ display: "flex" }}>
          {handleEdit && (
            <div
              style={{
                padding: "5px",
                fontSize: "14px",
                backgroundColor: "#ff6347",
                color: "#fff",
                marginRight: "10px",
                width: "22px",
                height: "22px",
                borderRadius: "50%",
                textAlign: "center",
                cursor: "pointer",
              }}
              title="Editar"
              onClick={() => handleEdit(itemInfo, index)}
            >
              <i
                className="fas fa-edit"
                style={{ position: "relative", top: "-3px" }}
              ></i>
            </div>
          )}

          <div
            style={{
              padding: "5px",
              fontSize: "14px",
              backgroundColor: "#ff6347",
              color: "#fff",
              marginRight: "10px",
              width: "22px",
              height: "22px",
              borderRadius: "50%",
              textAlign: "center",
              cursor: "pointer",
            }}
            title="Remover"
            onClick={() => handleRemove(itemInfo)}
          >
            <i
              className="fas fa-times"
              style={{ position: "relative", top: "-3px" }}
            ></i>
          </div>
        </div>
      </div>
    );
  });
};

SimpleManagementList.defaultProps = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      title: PropTypes.node.isRequired,
    }).isRequired,
  ),
  handleRemove: PropTypes.func.isRequired,
};

export default SimpleManagementList;
