import { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import utils from "../../../../utils/toolUtils";
import { updateTheme } from "../../../../actions/themesActions";

const {
  buildFormPrioritizationBody,
  getObjectDifference,
  getObjectInfoById,
  getOnlySlugValues,
  removeObjSlug
} = utils;

const defaultInitialForm = buildFormPrioritizationBody({});

const ProjectPrioritization = props => {
  const [projectPrioritizationAdmin, setProjectPrioritizationAdmin] = useState(
    defaultInitialForm
  );
  const [firstValidation, setFirstValidation] = useState(false);

  const {
    allProjectPrioritization = [],
    selectedTool = {},
    allThemes = {},
    updateTheme
  } = props;

  const toolProjectPrioritization = getObjectInfoById(
    selectedTool.id,
    allProjectPrioritization,
    "selectedToolId"
  );

  useEffect(() => {
    const updateByReducer = !_.isEqual(
      toolProjectPrioritization,
      projectPrioritizationAdmin
    );

    if (updateByReducer) {
      if (toolProjectPrioritization.projectGroups) {
        const compareState = removeObjSlug(
          projectPrioritizationAdmin.projectGroups,
          "id"
        );

        const diff = getObjectDifference(
          toolProjectPrioritization.projectGroups,
          compareState
        );

        if (diff) {
          if (!firstValidation) setFirstValidation(true);

          const watchGroups = getOnlySlugValues(diff, "id");

          if (watchGroups.length > 0) {
            Object.keys(allThemes).forEach(scenaryId => {
              const scenaryTeams = allThemes[scenaryId];

              scenaryTeams.forEach(themeInfo => {
                const { projectPriorizationConfigurationGroupId } = themeInfo;
                const relatedGroupId =
                  watchGroups[
                    watchGroups.indexOf(projectPriorizationConfigurationGroupId)
                  ] || null;

                if (relatedGroupId && themeInfo.name) {
                  const projectGroupInfo = getObjectInfoById(
                    relatedGroupId,
                    toolProjectPrioritization.projectGroups,
                    "id"
                  );

                  if (projectGroupInfo.title !== themeInfo.name) {
                    updateTheme(
                      { ...themeInfo, name: projectGroupInfo.title },
                      themeInfo.cenary.id
                    );
                  }
                }
              });
            });
          }
        }
      }
      setProjectPrioritizationAdmin(toolProjectPrioritization);
    }
  }, [
    allThemes,
    firstValidation,
    projectPrioritizationAdmin,
    toolProjectPrioritization,
    updateTheme
  ]);

  return null;
};

const mapStateToProps = state => {
  const { projectPrioritization } = state;
  const allProjectPrioritization = projectPrioritization.all
    ? projectPrioritization.all
    : [];

  return { allProjectPrioritization };
};

export default connect(
  mapStateToProps,
  { updateTheme }
)(ProjectPrioritization);
