import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import GeneralChart from "./charts/GeneralChart";
import ResponsibleChart from "./charts/ResponsibleChart";
import PhysicalScheduleChart from "./charts/PhysicalScheduleChart";

import CostChart from "./charts/CostChart";
import QualityChart from "./charts/QualityChart";
import DeadlineChart from "./charts/DeadlineChart";
import { defineMessages } from "react-intl";
import MaterialMultiSelect from "../../../../../../Common/MaterialMultiSelect";
import { getMacroOverviewData } from "../../../../../../../utils/projectUtils";

const MacroOverview = (props) => {
  const {
    intl = {},
    reportSubstages,
    reportResponsibles,
    buildedEvaluations = [],
  } = props;

  const [data, setData] = useState([]);
  const [currentStageFilter, setCurrentStageFilter] = useState([]);
  const [currentResponsibleFilter, setCurrentResponsibleFilter] = useState([]);

  const filterByResponsible = (evaluations = [], filters = []) => {
    return evaluations.filter(({ projectEvaluation = {} }) => {
      const { answer = {} } = projectEvaluation || {};
      const { responsibleMember = {} } = answer || {};

      const { id = 0 } = responsibleMember || {};

      if (filters.indexOf(id) > -1) return true;
    });
  };

  const filterBySubstages = (evaluations = [], filters = []) => {
    return evaluations.filter(({ projectEvaluation = {} }) => {
      const { name = false, parentName = false } =
        essentialInfo(projectEvaluation);

      if (!name) return false;

      let include = false;

      filters.forEach((option) => {
        const splittedOption = option.split("[separator]");

        const newName = splittedOption[0] || "0";
        const newParentName = splittedOption[1] || "0";

        if (newParentName !== "0") {
          if (newName === name) include = true;
          return;
        }

        if (
          newName === name ||
          newName === parentName ||
          newParentName === name ||
          newParentName === parentName
        )
          include = true;
      });

      if (include) return true;
    });
  };

  useEffect(() => {
    let builded = buildedEvaluations;
    if (
      currentResponsibleFilter.length === 0 &&
      currentStageFilter.length === 0
    ) {
      const filtered = getMacroOverviewData([], builded);
      setData(filtered);
      return;
    }

    if (currentResponsibleFilter.length === 0) {
      const substageFiltered = filterBySubstages(
        buildedEvaluations,
        currentStageFilter,
      );
      const filtered = getMacroOverviewData(substageFiltered, builded);
      setData(filtered);
      return;
    }

    const responsibleFiltered = filterByResponsible(
      buildedEvaluations,
      currentResponsibleFilter,
    );

    if (currentStageFilter.length === 0) {
      const filtered = getMacroOverviewData(responsibleFiltered, builded);
      setData(filtered);
      return;
    }

    const substageFiltered = filterBySubstages(
      responsibleFiltered,
      currentStageFilter,
    );

    const filtered = getMacroOverviewData(substageFiltered, builded);
    setData(filtered);
  }, [currentStageFilter, currentResponsibleFilter]);

  const responsibleOptions = reportResponsibles
    .map(({ id, responsibleName }) => {
      return { value: id, text: responsibleName };
    })
    .sort((a, b) => a.text?.localeCompare(b.text));

  const responsibleInitialOptions = [
    ...responsibleOptions.map(({ value }) => value),
  ];

  const substageOptions = reportSubstages.map(({ id, substageName }) => {
    return { value: id, text: substageName };
  });

  const substageInitialOptions = [...substageOptions.map(({ value }) => value)];

  const messages = defineMessages({
    selectResponsible: {
      id: "select.responsible",
    },
  });

  const essentialInfo = ({
    projectStageRelation = {},
    projectStage = false,
  }) => {
    const { description = false, parent = {} } = projectStageRelation || {};

    const { description: parentDescription = false } = parent || {};

    const name = description || projectStage || false;

    if (!name) return {};

    const parentName = parentDescription
      ? parentDescription?.toUpperCase()
      : false;

    return { name: name?.toUpperCase(), parentName };
  };

  const handleResponsibles = (selectedOptions = []) =>
    setCurrentResponsibleFilter(selectedOptions);

  const handleSubstages = (selectedOptions = []) =>
    setCurrentStageFilter(selectedOptions);

  const essentialProps = {
    ...props,
    macroOverviewData: data,
  };

  return (
    <div className="tab-content" style={{ height: "700px" }}>
      <Row>
        <Col md={4} className="projectResponsibleSelect">
          <MaterialMultiSelect
            allButton
            selectAll
            setDefaultValue
            id="responsiblesSelector"
            options={responsibleOptions}
            initialOptions={responsibleInitialOptions}
            label={intl.formatMessage(messages.selectResponsible)}
            getData={(selectedOptions) => handleResponsibles(selectedOptions)}
          />
        </Col>
        <Col md={4} className="projectSubstageSelect">
          <MaterialMultiSelect
            allButton
            selectAll
            setDefaultValue
            id="substageSelect"
            options={substageOptions}
            initialOptions={substageInitialOptions}
            label="Selecione um subestágio"
            getData={(selectedOptions) => handleSubstages(selectedOptions)}
          />
        </Col>
      </Row>
      <Row>
        <GeneralChart {...essentialProps} />
        <ResponsibleChart {...essentialProps} />
        <PhysicalScheduleChart {...essentialProps} />
      </Row>
      <Row>
        <QualityChart {...essentialProps} />
        <DeadlineChart {...essentialProps} />
        <CostChart {...essentialProps} />
      </Row>
    </div>
  );
};

export default MacroOverview;
