import React, { useState, useEffect } from "react";
import FormDialog from "../../FormDialog";
import MaterialSingleSelect from "../../MaterialSingleSelect";

const initialForm = {
  groupId: "",
};

const ThemeProjectSelectDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const {
    open = false,
    afterCancel,
    afterConfirm,
    projectPrioritizationAdmin,
  } = props;

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleConfirm = () => {
    if (typeof afterConfirm === "function") afterConfirm(form);
  };

  const handleCancel = () => {
    if (typeof afterCancel === "function") afterCancel();
  };

  const handleUpdateForm = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const getFormErrors = () => {
    let errors = [];

    if ((!form.groupId || form.groupId === "empty") && form.groupId !== 0) {
      errors.push({
        slug: "groupNotSelected",
        message: "Selecione um grupo para continuar",
      });
    }

    return errors;
  };

  const buildProjectGroupOptions = (groups) => {
    let finalOptions = [];

    if (groups && groups.length > 0) {
      groups.forEach(({ id, title }) =>
        finalOptions.push({ value: id, label: title }),
      );
    }

    return finalOptions;
  };

  const groupOptions = buildProjectGroupOptions(
    projectPrioritizationAdmin.projectGroups,
  );
  const formErrors = getFormErrors();

  return (
    <>
      <FormDialog
        open={open}
        title="Adicionar grupo de projeto"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        blockConfirm={formErrors.length > 0}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialSingleSelect
              id="groupSelected"
              label="Selecione um grupo de projeto"
              value={form.groupId}
              options={groupOptions}
              onChange={(e) => handleUpdateForm("groupId", e.target.value)}
            />
          </div>
        </div>
      </FormDialog>
    </>
  );
};

export default ThemeProjectSelectDialog;
