import React from "react";
import { defineMessages } from "react-intl";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import SimpleSelectExcelCollumn from "../../../../../../Common/SimpleSelectExcelCollumn";
import ExcelNumberFormat from "../../../../../../Common/ExcelNumberFormat";
import { getClearBonusOptions } from "../../../../../../../constants/goalsAndBonus";
import { formatWithDecimals } from "../../../../../../../utils/StringUtils";
import { translatedText } from "../../../../../../../utils/translationUtils";

const messages = defineMessages({
  gb_table_text_target: { id: "gb_table_text_target" },
  gb_table_text_performance_scale: { id: "gb_table_text_performance_scale" },
  gb_table_text_gate: { id: "gb_table_text_gate" },
  gb_table_text_appropriated: { id: "gb_table_text_appropriated" },
  gb_table_text_exceeded: { id: "gb_table_text_exceeded" },
  gb_table_text_reset_bonus: { id: "gb_table_text_reset_bonus" },
  gb_table_text_total_limit_bonus: { id: "gb_table_text_total_limit_bonus" },
});

const translation = (id, values) => translatedText(id, messages, values);

const BonusAdministrationTable = ({
  goalBonusAdministration,
  setGoalBonusAdministration,
  getDefaultTargetCols,
}) => {
  const handleUpdateGoalsTargets = (value, target) => {
    setGoalBonusAdministration({
      ...goalBonusAdministration,
      goalTargets: { ...goalBonusAdministration.goalTargets, [target]: value },
    });
  };

  const handleUpdateClearBonus = (clearBonus) => {
    setGoalBonusAdministration({
      ...goalBonusAdministration,
      clearBonusGateMin: clearBonus,
    });
  };

  const handleUpdateLimitBonus = (newLimit) => {
    setGoalBonusAdministration({
      ...goalBonusAdministration,
      limitBonus: Number(newLimit),
    });
  };

  function transformValue(val = "") {
    return formatWithDecimals(val, "auto", 5, 2);
  }

  const clearBonusOptions = getClearBonusOptions();

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td className="titled">{translation("gb_table_text_target")}</td>
          {getDefaultTargetCols()}
        </tr>
      </thead>
      <tbody>
        <tr className="block-hover">
          <td>{translation("gb_table_text_performance_scale")}</td>
          <SimpleExcelCollumn
            id="targetGateConfig"
            label={translation("gb_table_text_gate")}
            value={goalBonusAdministration.goalTargets.gate}
            type="number"
            onChange={(e) => handleUpdateGoalsTargets(e.target.value, "gate")}
            align="right"
            textEndAdorment="%"
          />
          <SimpleExcelCollumn
            id="targetAppropriatedConfig"
            label={translation("gb_table_text_appropriated")}
            value={goalBonusAdministration.goalTargets.appropriated}
            type="number"
            onChange={(e) =>
              handleUpdateGoalsTargets(e.target.value, "appropriated")
            }
            align="right"
            textEndAdorment="%"
          />
          <SimpleExcelCollumn
            id="targetExceededConfig"
            label={translation("gb_table_text_exceeded")}
            value={goalBonusAdministration.goalTargets.exceeded}
            type="number"
            onChange={(e) =>
              handleUpdateGoalsTargets(e.target.value, "exceeded")
            }
            align="right"
            textEndAdorment="%"
          />
        </tr>
        <tr>
          <td colSpan="4"></td>
        </tr>
        <tr>
          <td colSpan="2">
            {translation("gb_table_text_reset_bonus", {
              percentage: goalBonusAdministration.goalTargets.gate,
            })}
          </td>
          <SimpleSelectExcelCollumn
            colSpan="2"
            id="clearBonusGateMin"
            label="clearBonusGateMin"
            value={goalBonusAdministration.clearBonusGateMin}
            options={clearBonusOptions}
            customStyles={{ height: "50px" }}
            onChange={(e) => handleUpdateClearBonus(e.target.value)}
            defaultOption={false}
          />
        </tr>
        <tr>
          <td colSpan="2">{translation("gb_table_text_total_limit_bonus")}</td>
          <ExcelNumberFormat
            colSpan="2"
            id="limitBonus"
            label="limitBonus"
            value={transformValue(goalBonusAdministration.limitBonus)}
            onChange={(e) => handleUpdateLimitBonus(e.target.value)}
            align="right"
            textEndAdorment="$"
          />
        </tr>
      </tbody>
    </table>
  );
};

export default BonusAdministrationTable;
