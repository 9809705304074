import styled, { css } from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import { Alert } from "@material-ui/lab";

export const Container = styled.div`
  position: relative;
  width: 100%;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const TitleChecklist = styled.span`
  position: absolute;
  font-size: 13px;
  color: #656565;
  top: -10px;
  left: 10px;
  background: #fff;
`;

export const BoxHeader = styled.div`
  padding: 15px 0px 5px 10px;
`;

export const BoxList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextArea = styled(TextareaAutosize)`
  width: 95%;
  resize: none;
  border: none;
  outline: none;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
  font-weight: 500;
`;

export const TextAreaDescription = styled(TextareaAutosize)`
  width: 95%;
  resize: none;
  border: none;
  outline: none;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
  font-size: 13px;
  color: #666;
`;

export const ButtonAddTask = styled.button`
  border: none;
  padding: 5px;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  background-color: #6b42a9;
`;

export const WrapperList = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0px 0 5px 0px;
  padding: 10px;
`;

export const MenuBox = styled.div`
  display: none;
  position: absolute;
  right: 0;

  ${(props) =>
    props.isOpenPopover &&
    css`
      display: block !important;
    `}
`;

export const DisplayList = styled.div`
  position: relative;
  display: flex;

  margin-top: 5px;

  &:hover ${MenuBox} {
    display: block;
  }
`;

export const CheckBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  min-width: 20px;
  max-width: 20px;
  height: 20px;
  border: 1px solid #333;
  border-radius: 3px;
  margin-right: 7px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);

    ${(props) =>
      props.type === "checked" &&
      css`
        background: #5487e4;
      `}
    ${(props) =>
      props.type === "canceled" &&
      css`
        background: #f54e4e;
      `};
  }

  .fa-check {
    color: #5487e4;
    z-index: 1000;
  }

  .fa-times {
    color: #f54e4e;
    z-index: 1000;
  }

  &:hover .fa-check,
  &:hover .fa-times {
    ${(props) =>
      props.type === "checked" &&
      css`
        color: #fff;
      `}
    ${(props) =>
      props.type === "canceled" &&
      css`
        color: #fff;
      `};
  }
`;

export const InputTask = styled.input`
  border: none;
  outline: none !important;
  font-weight: 500;
  width: 100%;
`;

export const BoxEditing = styled.div`
  display: flex;

  margin-top: 10px;
`;

export const AlertStyled = styled(Alert)`
  .MuiAlert-message {
    font-size: 16px;
  }

  .MuiAlert-root {
    display: flex;
    justify-content: center;
  }
`;

export const IconInsert = styled.i`
  margin-left: 5px;
  line-height: 15px;
  padding: 5px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background: #ccc;
  transition: 0.3s;

  &:hover {
    color: #0c9247;
  }
`;
