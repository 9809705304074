import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import _ from "lodash";
import gbUtils from "../../../../utils/goalsBonus";
import { getObjectInfoById } from "../../../../utils/ArrayUtils";
import TargetColumns from "../../TargetsColumns";
import { getGoalTargetOptions } from "../../../../constants/goalsAndBonus";

const { getCountGoalStatus, getCountParticipantsGoalStatus } = gbUtils;

const messages = defineMessages({
  gb_table_text_target_upper: { id: "gb_table_text_target_upper" },
  gb_table_text_goals_upper: { id: "gb_table_text_goals_upper" },
  gb_table_text_participants_upper: { id: "gb_table_text_participants_upper" },
});

const ColumnsEvaluations = ({
  goals = [],
  goalTargets = {},
  clearBonusGateMin,
  displayParticipants = [],
  calcType,
  isPeriodFilterActive,
  intl,
}) => {
  const participantStatus = getCountParticipantsGoalStatus(
    displayParticipants,
    goalTargets,
    clearBonusGateMin,
    calcType,
    isPeriodFilterActive,
  );

  const goalTargetOptions = getGoalTargetOptions();
  const goalStatus = getCountGoalStatus(
    goals,
    goalTargets,
    isPeriodFilterActive,
  );

  const participantStatusProps = {};
  const goalsStatusProps = {};

  Object.keys(participantStatus).forEach((statusKey) => {
    const statusInfo = getObjectInfoById(statusKey, goalTargetOptions, "value");

    participantStatusProps[statusKey] = {
      count: participantStatus[statusKey].length,
      color: statusInfo.color,
      label: statusInfo.label,
    };
  });

  Object.keys(goalStatus).forEach((statusKey) => {
    const statusInfo = getObjectInfoById(statusKey, goalTargetOptions, "value");

    goalsStatusProps[statusKey] = {
      count: goalStatus[statusKey].length,
      color: statusInfo.color,
      label: statusInfo.label,
    };
  });

  return (
    <div className="evaluation-card">
      <div className="card-header">
        <div className="card-title">
          <h4>ALVO</h4>
        </div>
      </div>
      <div style={{ padding: "5px 0px 15px 0px" }}>
        <div className="card-body max-height-800" style={{ padding: "0px" }}>
          <div className="row">
            <h5 style={{ margin: "15px 15px 0px 15px" }}>
              {intl.formatMessage(messages.gb_table_text_participants_upper)} #
              {displayParticipants.length}
            </h5>
            <TargetColumns
              total={displayParticipants.length}
              list={participantStatusProps}
              margin="0px"
              showPercentage
            />
          </div>
          <div className="row">
            <h5 style={{ margin: "15px 15px 0px 15px" }}>
              {intl.formatMessage(messages.gb_table_text_goals_upper)} #
              {goals.length}
            </h5>
            <TargetColumns
              total={goals.length}
              list={goalsStatusProps}
              margin="0px"
              showPercentage
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function areEqual(prev, next) {
  if (prev.calcType !== next.calcType) return false;
  if (!_.isEqual(prev.periodFilter, next.periodFilter)) return false;
  if (!_.isEqual(prev.goals, next.goals)) return false;
  if (!_.isEqual(prev.displayParticipants, next.displayParticipants))
    return false;

  return true;
}

export default injectIntl(React.memo(ColumnsEvaluations, areEqual));
