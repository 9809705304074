import React from "react";
import { Typography } from "@material-ui/core";

const SceneriePanel = (props) => {
  const { children, activeTab, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={activeTab !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      className="cenary-themes-container"
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
      style={{ border: "1px solid #ddd", padding: "25px" }}
    >
      {children}
    </Typography>
  );
};

export default SceneriePanel;
