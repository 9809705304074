import axios from "axios";
import { apiNode } from "../api";
import {
  FETCH_WORKSPACES_TOOL_REQUESTS,
  FETCH_HOME_REQUESTS,
  REFRESH_SELECTED_TOOL,
} from "../components/appControllerTypes";
import env from "../utils/environmentUtils";
import { setSelectedDomainId } from "./accessLevelsActions";
import { setSelectedCompany } from "./companyActions";
import { openInsightModal } from "./insightActions";
import { setSelectedTool } from "./toolActions";
import { setSelectedWorkspace } from "./workspaceActions";

export const appWorkspaceToolRequests = (companyId, toolTypes = []) => {
  return (dispatch) => {
    if (companyId && toolTypes && Array.isArray(toolTypes)) {
      dispatch({
        type: "APP_ACTION",
        payload: {
          type: FETCH_WORKSPACES_TOOL_REQUESTS,
          payload: { companyId, toolTypes },
        },
      });
    }
  };
};

export const appHomeRequests = (companyId) => {
  return (dispatch) => {
    if (companyId) {
      dispatch({
        type: "APP_ACTION",
        payload: {
          type: FETCH_HOME_REQUESTS,
          payload: { companyId },
        },
      });
    }
  };
};

export const appRefreshSelectedTool = (resetAll = false) => {
  return (dispatch) => {
    dispatch({
      type: "APP_ACTION",
      payload: {
        type: REFRESH_SELECTED_TOOL,
        resetAll,
      },
    });
  };
};

export const getEncryptedLinkBy = async (linkData) => {
  try {
    const { data } = await apiNode.post("/api/v2/link/encrypt", linkData);

    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getDecryptedObjectBy = async (tokenData) => {
  if (!tokenData || tokenData === null) return;

  try {
    const { data } = await apiNode.post("/api/v2/link/decrypt", tokenData);

    return data;
  } catch (err) {
    console.log(err);
  }
};

export const setCustomLinkStates = ({
  company,
  insight,
  workspace,
  selectedTool,
  selectedDomainId,
}) => {
  const { id: selectedInsightId } = insight || {};
  const { id: selectedCompanyId } = company || {};
  const { id: selectedToolId } = selectedTool || {};
  const { id: selectedWorkspaceId } = workspace || {};

  return (dispatch) => {
    dispatch(setSelectedDomainId(selectedDomainId));
    if (selectedCompanyId) dispatch(setSelectedCompany(company));
    if (selectedWorkspaceId) dispatch(setSelectedWorkspace(workspace));
    if (selectedToolId) dispatch(setSelectedTool(selectedTool));
    if (selectedInsightId) dispatch(openInsightModal(insight));
  };
};

export function postLogError(infos) {
  if (!env.isDev()) {
    axios.post("/api/error-logs", infos);
  } else {
    console.log("Error: ", infos);
  }
}
