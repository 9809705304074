import React from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import utils from "../../../../../../../utils/toolUtils";
import { deleteKpiTheme } from "../../../../../../../actions/kpiActions";
import {
  getEmptyTableRow,
  getDefaultAddTableLine,
  getDefaultEditColumn,
} from "../../../../../../../utils/tableUtils";
import { getDefaultRemoveColumn } from "../../../../../../../utils/okrUtils";

const {
  getUpdatedDeleteArrayByIndex,
  orderArrayByObjAttr,
  getObjectInfoById,
} = utils;

const messages = defineMessages({
  kpi_table_text_scenarios_upper: { id: "kpi_table_text_scenarios_upper" },
  kpi_warning_need_arquive_cenary: { id: "kpi_warning_need_arquive_cenary" },
  kpi_warning_connected_scenario_cenary: {
    id: "kpi_warning_connected_scenario_cenary",
  },
  kpi_confirm_delete_scenario: { id: "kpi_confirm_delete_scenario" },
  global_noneRegistered: { id: "global.noneRegistered" },
});

const ThemesConfigTable = (props) => {
  const {
    kpiAdministration,
    setKpiAdministration,
    setConfirmationDialog,
    resetConfirmDialog,
    toggleOpenFormDialogs,
    handleSetEditingForm,
    allSceneries,
    deleteKpiTheme,
    intl,
  } = props;

  const handleDeleteTheme = (index) => {
    const deletingObjInfo = kpiAdministration.themes[index];

    if (deletingObjInfo.id) {
      deleteKpiTheme(deletingObjInfo.id, kpiAdministration.id);

      const updatedThemes = getUpdatedDeleteArrayByIndex(
        kpiAdministration.themes,
        index,
      );

      setKpiAdministration({
        ...kpiAdministration,
        themes: updatedThemes,
      });
    }

    resetConfirmDialog();
  };

  const openDeleteThemeDialog = (index) => {
    const deletingThemeInfo = kpiAdministration.themes[index] || {};
    let allowedToDelete = true;

    if (deletingThemeInfo.id) {
      const relatedScenerie = getObjectInfoById(
        deletingThemeInfo.id,
        allSceneries,
        "kpiConfigurationThemeId",
      );

      allowedToDelete = relatedScenerie && relatedScenerie.id ? false : true;

      setConfirmationDialog({
        title: allowedToDelete
          ? intl.formatMessage(messages.kpi_confirm_delete_scenario)
          : intl.formatMessage(messages.kpi_warning_connected_scenario_cenary),
        description: allowedToDelete
          ? null
          : intl.formatMessage(messages.kpi_warning_need_arquive_cenary, {
              cenaryName: relatedScenerie.name,
            }),
        onConfirm: allowedToDelete
          ? () => handleDeleteTheme(index)
          : resetConfirmDialog,
        confirmText: !allowedToDelete ? "OK" : null,
        hideCancel: !allowedToDelete ? true : false,
        onCancel: resetConfirmDialog,
        open: true,
      });
    }
  };

  let reorderedThemes = orderArrayByObjAttr(kpiAdministration.themes, "title");

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="100">
            {intl.formatMessage(messages.kpi_table_text_scenarios_upper)}
          </td>
        </tr>
      </thead>
      <tbody>
        {reorderedThemes.map((themeInfo, index) => {
          return (
            <tr key={`themes_${index}`} className="block-hover">
              <td align="center" style={{ width: "40px" }}>
                {index + 1}
              </td>
              <td>{themeInfo.title}</td>
              {getDefaultEditColumn(() =>
                handleSetEditingForm("newTheme", { themeId: themeInfo.id }),
              )}
              {getDefaultRemoveColumn(() => openDeleteThemeDialog(index))}
            </tr>
          );
        })}
        {getEmptyTableRow(
          kpiAdministration.themes,
          intl.formatMessage(messages.global_noneRegistered),
        )}
        {getDefaultAddTableLine(() => toggleOpenFormDialogs("newTheme"))}
      </tbody>
    </table>
  );
};

export default injectIntl(connect(null, { deleteKpiTheme })(ThemesConfigTable));
