import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger } from "react-bootstrap";
import SimpleExcelCollumn from "./SimpleExcelCollumn";
import SimplePercentageLine from "./SimplePercentageLine";
import { checkGreaterThan, ruleOfThree } from "../../utils/MathUtils";
import { getScaleToolTip } from "../../utils/skillsAssessment/display";

const WorkflowEvaluationCol = ({
  field,
  value,
  updateValue,
  progressColor,
  maxValue,
  disabled,
  options,
  tabIndex,
}) => {
  return (
    <td
      className="input-td"
      style={{
        height: "40px !important",
        opacity: disabled ? "0.5" : "1",
      }}
    >
      <OverlayTrigger placement="top" overlay={getScaleToolTip(options)}>
        <table style={{ width: "100%", height: "100%", border: "none" }}>
          <tbody>
            <tr>
              <SimpleExcelCollumn
                id={field}
                type="number"
                value={value}
                align="center"
                tdClass="input-td"
                tdStyle={{ padding: "0px", border: "none" }}
                inputStyle={{
                  height: "40px",
                  width: "110px",
                  padding: "0px",
                  cursor: disabled ? "not-allowed" : "auto",
                }}
                secondaryInputProps={{ tabIndex }}
                onChange={(e) =>
                  !disabled ? updateValue(e.target.value) : () => {}
                }
              />
            </tr>
            <tr>
              <td className="input-td" style={{ height: "15px" }}>
                <SimplePercentageLine
                  backgroundColor="#efefef"
                  overrideColor={progressColor}
                  percentage={ruleOfThree(maxValue, value)}
                  isNull={!checkGreaterThan(value, 0, true)}
                  height="15px"
                  hidePercentages
                />
              </td>
            </tr>
          </tbody>
        </table>
      </OverlayTrigger>
    </td>
  );
};

WorkflowEvaluationCol.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.any,
  maxValue: PropTypes.number.isRequired,
  updateValue: PropTypes.func.isRequired,
  progressColor: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

WorkflowEvaluationCol.defaultProps = {
  value: "",
  disabled: false,
};

export default WorkflowEvaluationCol;
