import { periodicityOptions } from "./defaults";

export function formatFrequency(frequency) {
  return frequency ? `${frequency}x` : "";
}

export function formatPeriodDemandGrowth(periodDemandGrowth) {
  return periodDemandGrowth ? `${periodDemandGrowth}%` : "";
}

export function formatPeriodicity(periodicity) {
  return periodicityOptions.find((option) => option.value === periodicity)
    ?.label;
}

export function formatChecklistIndicator(checklist) {
  const completed = checklist?.filter((item) => item.completed === true);

  return `(${completed.length}/${checklist.length})`;
}
