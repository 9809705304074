import React, { useState, useCallback, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import MaterialTextField from "../MaterialTextField";

import {
  DisplayTextView,
  TextView,
  DisplayButtons,
  CancelButton,
  SaveButton,
  WrapperEditable,
} from "./styles";

const useOutsideToCloseEdit = (ref, next) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        next();
      }
    }

    document.addEventListener("mouseup", handleClickOutside);

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [ref, next]);
};

const EditableTextArea = ({
  text,
  onSave,
  isEditable,
  maxLength,
  id,
  rows,
  onCancel,
  multiline,
  label,
  actionButtons,
  actionIcons,
  margin,
  width = "100%",
  editing = false,
}) => {
  const [editableText, setEditableText] = useState(text);
  const [isEditing, setIsEditing] = useState(editing);

  useEffect(() => {
    setIsEditing(editing);
  }, [editing]);

  const wrapperRef = useRef();

  const handleTextArea = useCallback(() => {
    setIsEditing(!isEditing);
    setEditableText(text);

    if (onCancel) {
      onCancel();
    }
  }, [isEditing, onCancel, text]);

  useOutsideToCloseEdit(wrapperRef, handleTextArea);

  const handleChangeText = useCallback((e) => {
    const { value } = e.target;

    setEditableText(value);
  }, []);

  const handleSaveAndCloseText = useCallback(
    (text) => {
      onSave(text);

      handleTextArea();
    },
    [onSave, handleTextArea],
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (!e.shiftKey && e.key === "Enter") {
        onSave(editableText);

        handleTextArea();
      }
    },
    [onSave, handleTextArea, editableText],
  );

  const renderActionIcons = useCallback(() => {
    return (
      <div
        style={{ display: "flex", alignItems: "center", marginRight: "20px" }}
      >
        <i
          className="fas fa-times"
          onClick={handleTextArea}
          style={{ margin: "0 5px", fontSize: "18px", color: "#333" }}
        />
        <i
          className="fas fa-check"
          onClick={() => handleSaveAndCloseText(editableText)}
          style={{ margin: "0 5px", fontSize: "18px", color: "green" }}
        />
      </div>
    );
  }, [handleTextArea, handleSaveAndCloseText, editableText]);

  const renderActionButtons = useCallback(() => {
    return (
      <DisplayButtons>
        <CancelButton onClick={handleTextArea}>Cancelar</CancelButton>
        <SaveButton onClick={() => handleSaveAndCloseText(editableText)}>
          Salvar alterações
        </SaveButton>
      </DisplayButtons>
    );
  }, [handleTextArea, handleSaveAndCloseText, editableText]);

  const renderEditableView = useCallback(() => {
    return (
      <WrapperEditable ref={wrapperRef}>
        <div
          onKeyPress={handleKeyPress}
          role="button"
          tabIndex={0}
          style={{ display: "flex", width }}
        >
          <MaterialTextField
            id={id}
            margin={margin}
            label={label}
            multiline={multiline}
            primaryInputProps={{ maxLength }}
            value={editableText}
            rows={rows}
            onChange={handleChangeText}
          />
          {actionIcons && renderActionIcons()}
        </div>

        {actionButtons && renderActionButtons()}
      </WrapperEditable>
    );
  }, [
    id,
    label,
    multiline,
    maxLength,
    margin,
    width,
    rows,
    renderActionIcons,
    renderActionButtons,
    actionButtons,
    editableText,
    handleChangeText,
    handleKeyPress,
    actionIcons,
  ]);

  const renderTextView = useCallback(() => {
    return (
      <DisplayTextView>
        <TextView>{text}</TextView>
        {!!isEditable && (
          <i
            onClick={handleTextArea}
            className="fas fa-pen iconEdit"
            aria-hidden="true"
          />
        )}
      </DisplayTextView>
    );
  }, [text, handleTextArea, isEditable]);

  return isEditing ? renderEditableView() : renderTextView();
};

export default EditableTextArea;

EditableTextArea.propTypes = {
  id: PropTypes.any.isRequired,
  label: PropTypes.string,
  multine: PropTypes.bool,
  maxLength: PropTypes.number,
  rows: PropTypes.number,
  text: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
};

EditableTextArea.defaultProps = {
  isEditable: false,
  label: "",
};
