const INITIAL_STATE = {
  followupData: [],
};

const reportOrkFollowup = (state = INITIAL_STATE, action) => {
  const dados = [];

  switch (action.type) {
    case "FETCH_REPORT_OKR_FOLLOWUP":
      dados.push(action.payload);
      return { ...state, followupData: action.payload };
    default:
      return state;
  }
};

export default reportOrkFollowup;
