import _ from "lodash";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";

import {
  getProjectStates,
  getSelectedToolStates,
  getAccessFilteredStates,
  getCompanyMembersAndParticipants,
} from "../../../../customMapStates/index";
import {
  getSubstages,
  getManagementData,
  getKanbanSubstages,
  getKanbanResponsibles,
  getReportResponsibles,
  getActivitiesResponsibles,
  getProjectEvaluationsByTheme,
} from "../../../../../utils/projectUtils";
import Activities from "./tabs/Activities";
import GanttMap from "./tabs/GanttMap/GanttMap";
import MacroOverview from "./tabs/MacroOverview";
import KanbanMap from "./tabs/KanbanMap/KanbanMap";
import ReportMap from "./tabs/ReportMap/ReportMap";
import ButtonGeneratePdf from "../../../../Common/ButtonGeneratePdf";
import { GOALS_AND_BONUS } from "../../../../../constants/tools.constants";
import { fetchAllProjectEvaluations } from "../../../../../actions/projectEvaluationActions";

const messages = defineMessages({
  noDataToDisplay: {
    id: "global.empty",
  },
  overview: {
    id: "global.overview",
  },
  ganttMap: {
    id: "tool.project.ganttMap",
  },
  kanbanMap: {
    id: "tool.project.kanbanMap",
  },
  executiveReport: {
    id: "tool.project.executiveReport",
  },
});

const defaultActiveTab = { activeTab: "macroOverview" };

let ProjectManagement = ({
  intl,
  allAnswers,
  filterData,
  selectedToolID,
  selectAndOpenInsight,
  allProjectEvaluation,
  fetchAllProjectEvaluations,
  companyMembersAndParticipants,
  isLoading,
}) => {
  const [tabs, setTab] = useState(defaultActiveTab);
  const [kanbanSubstages, setKanbanSubstages] = useState([]);
  const [managementData, setManagementData] = useState(null);
  const [reportSubstages, setReportSubstages] = useState([]);
  const [reportResponsibles, setReportResponsibles] = useState([]);
  const [kanbanResponsibles, setKanbanResponsibles] = useState([]);
  const [activitiesSubstages, setActivitiesSubstages] = useState([]);
  const [activitiesResponsibles, setActivitiesResponsibles] = useState([]);

  useEffect(() => {
    if (!isLoading) fetchAllProjectEvaluations(selectedToolID);
  }, []);

  useEffect(() => {
    if (!filterData.themes) return;

    const filteredEvaluations = getProjectEvaluationsByTheme(
      allProjectEvaluation,
      filterData.themes,
      allAnswers,
      filterData.questions,
    );

    const newManagementData = getManagementData(filteredEvaluations || []);

    if (_.isEqual(managementData, newManagementData)) return;

    setManagementData(newManagementData);
    handleKanbanSubstages(newManagementData);
    handleReportSubstages(newManagementData);
    handleKanbanResponsibles(newManagementData);
    handleReportResponsibles(newManagementData);
    handleActivitiesSubstages(newManagementData);
    handleActivitiesResponsibles(newManagementData);
  }, [filterData, allProjectEvaluation, managementData, allAnswers]);

  const handleReportResponsibles = ({ reportMap = [] }) => {
    const responsibles = getReportResponsibles(reportMap);

    setReportResponsibles(responsibles);
  };

  const handleKanbanResponsibles = ({ kanbanMap = [] }) => {
    const responsibles = getKanbanResponsibles(kanbanMap);

    setKanbanResponsibles(responsibles);
  };

  const handleKanbanSubstages = ({ kanbanMap = [] }) => {
    const substages = getKanbanSubstages(kanbanMap);

    setKanbanSubstages(substages);
  };

  const handleActivitiesResponsibles = ({ reportMap = [] }) => {
    const responsibles = getActivitiesResponsibles(reportMap);

    setActivitiesResponsibles(responsibles);
  };

  const handleActivitiesSubstages = ({ reportMap = [] }) => {
    const substages = getSubstages(reportMap);

    setActivitiesSubstages(substages);
  };

  const handleReportSubstages = ({ reportMap = [] }) => {
    const substages = getSubstages(reportMap);

    setReportSubstages(substages);
  };

  const handleTabChange = (key) => setTab({ activeTab: key });

  const ganttText = intl.formatMessage(messages.ganttMap);
  const kanbanText = intl.formatMessage(messages.kanbanMap);
  const overviewText = "Performance Geral";
  const executiveReportText = intl.formatMessage(messages.executiveReport);

  const executiveReportTitle = (
    <div>
      <i className="fas fa-chart-line" /> {executiveReportText}
    </div>
  );

  const kanbanTitle = (
    <div>
      <i className="far fa-chart-bar" /> {kanbanText}
    </div>
  );

  const ganttTitle = (
    <div>
      <i className="fas fa-align-left" /> {ganttText}
    </div>
  );

  const activitiesTitle = (
    <div>
      <i className="fas fa-list-ul" /> Atividades
    </div>
  );

  const overviewTitle = (
    <div>
      <i className="fas fa-chart-area" /> {overviewText}
    </div>
  );

  const {
    buildedEvaluations = [],
    ganttMap: ganttMapData = [],
    kanbanMap: kanbanMapData = [],
    reportMap: reportMapData = [],
  } = managementData || {};

  const showGanttMap = ganttMapData.length > 0;
  const showReportMap = reportMapData.length > 0;

  const noData = intl.formatMessage(messages.noDataToDisplay);

  const essentialProps = {
    intl,
    allAnswers,
    ganttMapData,
    kanbanMapData,
    reportMapData,
    reportSubstages,
    kanbanSubstages,
    kanbanResponsibles,
    reportResponsibles,
    buildedEvaluations,
    activitiesSubstages,
    allProjectEvaluation,
    selectAndOpenInsight,
    activitiesResponsibles,
    companyMembersAndParticipants,
  };

  return (
    <div
      className="custom-primary-tabs col-xs-12"
      style={{ marginBottom: "50px" }}
    >
      <Tabs
        defaultActiveKey="macroOverview"
        id="projectManagement"
        activeKey={tabs.activeTab}
        onSelect={(key) => handleTabChange(key)}
        unmountOnExit
      >
        <ButtonGeneratePdf />
        <Tab eventKey="macroOverview" title={overviewTitle} unmountOnExit>
          <MacroOverview {...essentialProps} />
        </Tab>
        <Tab eventKey="kanbanMap" title={kanbanTitle} unmountOnExit>
          <KanbanMap {...essentialProps} />
        </Tab>
        <Tab eventKey="ganttMap" title={ganttTitle} unmountOnExit>
          {showGanttMap ? <GanttMap {...essentialProps} /> : noData}
        </Tab>
        <Tab eventKey="reportMap" title={executiveReportTitle} unmountOnExit>
          {showReportMap ? <ReportMap {...essentialProps} /> : noData}
        </Tab>
        <Tab eventKey="activities" title={activitiesTitle} unmountOnExit>
          <Activities {...essentialProps} />
        </Tab>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { toolsConfigurations } = state;
  const { allProjectEvaluation, isLoading } = getProjectStates(state);
  const { selectedTool, selectedToolID } = getSelectedToolStates(state);
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);

  const notGoalsAndBonus = !(selectedTool?.tool?.id === GOALS_AND_BONUS);

  const { filteredQuestions = {}, filteredAnswers = {} } =
    getAccessFilteredStates(
      state,
      {
        sceneries: toolsConfigurations.showHiddenCenarys || false,
        themes: toolsConfigurations.showHiddenThemes || false,
        questions: toolsConfigurations.showHiddenQuestions || false,
        answers: toolsConfigurations.showHiddenAnswers || false,
      },
      notGoalsAndBonus,
    );

  return {
    allProjectEvaluation,
    allAnswers: filteredAnswers,
    allQuestions: filteredQuestions,
    selectedToolID,
    companyMembersAndParticipants,
    isLoading,
  };
};

ProjectManagement = injectIntl(
  connect(mapStateToProps, { fetchAllProjectEvaluations })(ProjectManagement),
);

export default ProjectManagement;
