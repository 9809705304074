import React from "react";
import { Row, Col } from "react-bootstrap";
import { patternStyles, generalCardStyles } from "../jcss";
import BadgeProgressBar from "../../../../../../../Common/ReportCards/components/BadgeProgressBar";
import { getDefaultPercentages } from "../../../../../../../../utils/projectUtils";

const values = getDefaultPercentages(20);

const overallRowStyle = { margin: "20px" };
const overallRowStyle2 = { margin: "25px 20px 20px 20px" };
const badgeRowStyle = { marginLeft: "20px", display: "flex", width: "90%" };

const DeadlineChart = ({ macroOverviewData = {} }) => {
  const {
    centerAlign = "",
    deadlineStyle = "",
    cardHeaderStyle = "",
  } = patternStyles();

  const { boxInfoHeader = "", boxInfoContent = "" } = generalCardStyles();

  const { deadline = {} } = macroOverviewData;
  const { ongoing = {}, concluded = {} } = deadline;

  const getValueLine = (value = "", index = 0) => {
    return (
      <Col md={2} key={index} style={{ fontSize: "10px" }}>
        {value}
      </Col>
    );
  };

  const singleGreyBar = () => {
    return (
      <BadgeProgressBar
        widthBar={"100%"}
        heightBar="18px"
        leftText={100}
        barColor="grey"
        borderRadius="5px"
      />
    );
  };

  const getBadges = ({ inPercentage = "0%", outPercentage = "0%" }) => {
    let inConfig = { leftBorderRadius: "5px" };
    let outConfig = { rightBorderRadius: "5px" };

    if (inPercentage === "0%" && outPercentage === "0%") return singleGreyBar();
    if (inPercentage === "0%") outConfig = { borderRadius: "5px" };
    if (outPercentage === "0%") inConfig = { borderRadius: "5px" };

    return (
      <div
        title={`Dentro: ${inPercentage} | Fora: ${outPercentage}`}
        style={{ width: "100%", display: "flex" }}
      >
        <BadgeProgressBar
          widthBar={inPercentage}
          heightBar="18px"
          leftText={100}
          barColor="kanbanGreen"
          {...inConfig}
        />
        <BadgeProgressBar
          widthBar={outPercentage}
          heightBar="18px"
          leftText={100}
          barColor="kanbanRed"
          {...outConfig}
        />
      </div>
    );
  };

  return (
    <Col md={4} sm={12} className={deadlineStyle}>
      <Row className={cardHeaderStyle}>
        <strong>Prazo</strong>
      </Row>
      <Row style={overallRowStyle} className={centerAlign}>
        <Col md={2} className={centerAlign}>
          <div>
            <div className={boxInfoHeader}>Andamento</div>
            <div className={boxInfoContent}>{ongoing.value}</div>
          </div>
        </Col>
        <Col md={10}>
          <Row>{values.map((value, index) => getValueLine(value, index))}</Row>
          <Row style={badgeRowStyle}>{getBadges(ongoing)}</Row>
        </Col>
      </Row>
      <Row style={overallRowStyle2} className={centerAlign}>
        <Col md={2} className={centerAlign}>
          <div>
            <div className={boxInfoHeader}>Concluido</div>
            <div className={boxInfoContent}>{concluded.value}</div>
          </div>
        </Col>
        <Col md={10}>
          <Row>{values.map((value, index) => getValueLine(value, index))}</Row>
          <Row style={badgeRowStyle}>{getBadges(concluded)}</Row>
        </Col>
      </Row>
    </Col>
  );
};

export default DeadlineChart;
