import axios from "axios";
import { UPDATE_INSIGHT_MATURITY, FETCH_ALL_MATURITY_DATA } from "./types";
import { makeObjLevelAnArray } from "../utils/reducerUtils";

export const getInsightMaturity = (insightID) => {
  return axios
    .get(`/api/insight/${insightID}/maturityEvaluation`)
    .then((res) => res);
};

export const getThemeMaturity = (themeID) => {
  return axios
    .get(`/api/themes/${themeID}/insightsMaturities`)
    .then((res) => res);
};

export const addInsightMaturity = (insightID, body) => {
  return axios
    .post(`/api/insights/${insightID}/maturityEvaluation`, body)
    .then((res) => res);
};

export const getUpdateDispatchBody = (insightId, maturityEvaluation) => {
  return {
    type: UPDATE_INSIGHT_MATURITY,
    payload: { insightId, maturityEvaluation },
  };
};

export const fetchInsightMaturity = (insightID) => {
  return (dispatch) => {
    getInsightMaturity(insightID).then((fetchData) => {
      if (fetchData.status === 200) {
        dispatch({
          type: UPDATE_INSIGHT_MATURITY,
          payload: { maturityEvaluation: fetchData.data, insightId: insightID },
        });
      }
    });
  };
};

export const getAllMaturityEvaluations = (selectedToolId) => {
  return axios
    .get(`/api/selected-tools/${selectedToolId}/maturitiesEvaluations`)
    .then((res) => res);
};

export const fetchAllToolsMaturityData = (selectedToolIds = []) => {
  return async (dispatch) => {
    const maturityData = await axios
      .get(`/api/selected-tools/allMaturitiesEvaluations/${selectedToolIds}`)
      .then((res) => res.data);

    dispatch({
      type: FETCH_ALL_MATURITY_DATA,
      payload: makeObjLevelAnArray(maturityData),
    });
  };
};

export const fetchAllMaturityData = (selectedToolId) => {
  return async (dispatch) => {
    const fetchedRes = await getAllMaturityEvaluations(selectedToolId);
    if (fetchedRes.data) {
      dispatch({ type: FETCH_ALL_MATURITY_DATA, payload: fetchedRes.data });
    }
  };
};

export const updateInsightMaturity = (
  insightID,
  maturityBody,
  selectedToolId,
) => {
  return async (dispatch) => {
    const fetchData = await getInsightMaturity(insightID);
    if (!fetchData || !fetchData.data) {
      const addedMaturity = await addInsightMaturity(insightID, maturityBody);
      if (addedMaturity.data) {
        dispatch(fetchAllMaturityData(selectedToolId));
      }
    } else {
      maturityBody.id = fetchData.data.id;

      const updatedMaturity = await axios.put(
        `/api/maturity-evaluations`,
        maturityBody,
      );
      if (updatedMaturity.data) {
        dispatch(fetchAllMaturityData(selectedToolId));
      }
    }
  };
};

export const deleteInsightMaturity = (insightID, maturityID) => {
  return axios.delete(
    `/api/insights/${insightID}/maturityEvaluation/${maturityID}`,
  );
};
