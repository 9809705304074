import React from "react";

import NewPestalAmbientDialog from "../../../../../Common/CpAnalysis/forms/Pestal/NewAnalysisAmbientDialog";
import NewPestalAreasDialog from "../../../../../Common/CpAnalysis/forms/Pestal/NewAreasDialog";
import NewPestalCriteriasDialog from "../../../../../Common/CpAnalysis/forms/Pestal/NewCriteriasDialog";

import NewSwotAmbientDialog from "../../../../../Common/CpAnalysis/forms/Swot/NewAnalysisAmbientDialog";
import NewSwotGroupDialog from "../../../../../Common/CpAnalysis/forms/Swot/NewAnalysisGroupDialog";
import NewSwotCriteriasDialog from "../../../../../Common/CpAnalysis/forms/Swot/NewCriteriasDialog";

import NewPrioritizationAmbientDialog from "../../../../../Common/CpAnalysis/forms/Prioritization/NewAnalysisAmbientDialog";
import NewPrioritizationAreaDialog from "../../../../../Common/CpAnalysis/forms/Prioritization/NewAnalysisAreaDialog";
import NewPrioritizationCriteriasDialog from "../../../../../Common/CpAnalysis/forms/Prioritization/NewCriteriasDialog";

import NewConcurrenceAmbientDialog from "../../../../../Common/CpAnalysis/forms/Concurrence/NewAnalysisAmbientDialog";
import NewConcurrenceAreaDialog from "../../../../../Common/CpAnalysis/forms/Concurrence/NewAnalysisAreaDialog";
import NewConcurrenceCriteriaDialog from "../../../../../Common/CpAnalysis/forms/Concurrence/NewCriteriasDialog";
import NewConcurrenceScaleDialog from "../../../../../Common/CpAnalysis/forms/Concurrence/NewScaleDialog";

const DisplayOpenForms = (props) => {
  const {
    openFormDialogs,
    resetEditingDialog,
    closeFormAndCallback,
    setCpAnalysisAdministration,
  } = props;

  const resetFormAndSave = (newCpAnalysisConfig) => {
    resetEditingDialog();
    setCpAnalysisAdministration(newCpAnalysisConfig);
  };

  return (
    <>
      {openFormDialogs.indexOf("newPestalAnalysisAmbient") > -1 && (
        <NewPestalAmbientDialog
          open
          afterConfirm={(newCpAnalysisConfig) =>
            closeFormAndCallback("newPestalAnalysisAmbient", () =>
              resetFormAndSave(newCpAnalysisConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback("newPestalAnalysisAmbient", resetEditingDialog)
          }
          {...props}
        />
      )}

      {openFormDialogs.indexOf("newPestalArea") > -1 && (
        <NewPestalAreasDialog
          open
          afterConfirm={(newCpAnalysisConfig) =>
            closeFormAndCallback("newPestalArea", () =>
              resetFormAndSave(newCpAnalysisConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback("newPestalArea", resetEditingDialog)
          }
          {...props}
        />
      )}

      {openFormDialogs.indexOf("newPestalCriteria") > -1 && (
        <NewPestalCriteriasDialog
          open
          afterConfirm={(newCpAnalysisConfig) =>
            closeFormAndCallback("newPestalCriteria", () =>
              resetFormAndSave(newCpAnalysisConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback("newPestalCriteria", resetEditingDialog)
          }
          {...props}
        />
      )}

      {openFormDialogs.indexOf("newSwotAnalysisAmbient") > -1 && (
        <NewSwotAmbientDialog
          open
          afterConfirm={(newCpAnalysisConfig) =>
            closeFormAndCallback("newSwotAnalysisAmbient", () =>
              resetFormAndSave(newCpAnalysisConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback("newSwotAnalysisAmbient", resetEditingDialog)
          }
          {...props}
        />
      )}

      {openFormDialogs.indexOf("newSwotAnalysisGroup") > -1 && (
        <NewSwotGroupDialog
          open
          afterConfirm={(newCpAnalysisConfig) =>
            closeFormAndCallback("newSwotAnalysisGroup", () =>
              resetFormAndSave(newCpAnalysisConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback("newSwotAnalysisGroup", resetEditingDialog)
          }
          {...props}
        />
      )}

      {openFormDialogs.indexOf("newSwotCriteria") > -1 && (
        <NewSwotCriteriasDialog
          open
          afterConfirm={(newCpAnalysisConfig) =>
            closeFormAndCallback("newSwotCriteria", () =>
              resetFormAndSave(newCpAnalysisConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback("newSwotCriteria", resetEditingDialog)
          }
          {...props}
        />
      )}

      {openFormDialogs.indexOf("newPrioritizationAnalysisAmbient") > -1 && (
        <NewPrioritizationAmbientDialog
          open
          afterConfirm={(newCpAnalysisConfig) =>
            closeFormAndCallback("newPrioritizationAnalysisAmbient", () =>
              resetFormAndSave(newCpAnalysisConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback(
              "newPrioritizationAnalysisAmbient",
              resetEditingDialog,
            )
          }
          {...props}
        />
      )}

      {openFormDialogs.indexOf("newPrioritizationAnalysisArea") > -1 && (
        <NewPrioritizationAreaDialog
          open
          afterConfirm={(newCpAnalysisConfig) =>
            closeFormAndCallback("newPrioritizationAnalysisArea", () =>
              resetFormAndSave(newCpAnalysisConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback(
              "newPrioritizationAnalysisArea",
              resetEditingDialog,
            )
          }
          {...props}
        />
      )}

      {openFormDialogs.indexOf("newPrioritizationCriteria") > -1 && (
        <NewPrioritizationCriteriasDialog
          open
          afterConfirm={(newCpAnalysisConfig) =>
            closeFormAndCallback("newPrioritizationCriteria", () =>
              resetFormAndSave(newCpAnalysisConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback(
              "newPrioritizationCriteria",
              resetEditingDialog,
            )
          }
          {...props}
        />
      )}

      {openFormDialogs.indexOf("newConcurrenceAnalysisAmbient") > -1 && (
        <NewConcurrenceAmbientDialog
          open
          afterConfirm={(newCpAnalysisConfig) =>
            closeFormAndCallback("newConcurrenceAnalysisAmbient", () =>
              resetFormAndSave(newCpAnalysisConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback(
              "newConcurrenceAnalysisAmbient",
              resetEditingDialog,
            )
          }
          {...props}
        />
      )}

      {openFormDialogs.indexOf("newConcurrenceAnalysisArea") > -1 && (
        <NewConcurrenceAreaDialog
          open
          afterConfirm={(newCpAnalysisConfig) =>
            closeFormAndCallback("newConcurrenceAnalysisArea", () =>
              resetFormAndSave(newCpAnalysisConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback(
              "newConcurrenceAnalysisArea",
              resetEditingDialog,
            )
          }
          {...props}
        />
      )}

      {openFormDialogs.indexOf("newConcurrenceCriteria") > -1 && (
        <NewConcurrenceCriteriaDialog
          open
          afterConfirm={(newCpAnalysisConfig) =>
            closeFormAndCallback("newConcurrenceCriteria", () =>
              resetFormAndSave(newCpAnalysisConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback("newConcurrenceCriteria", resetEditingDialog)
          }
          {...props}
        />
      )}

      {openFormDialogs.indexOf("newConcurrenceScale") > -1 && (
        <NewConcurrenceScaleDialog
          open
          afterConfirm={(newCpAnalysisConfig) =>
            closeFormAndCallback("newConcurrenceScale", () =>
              resetFormAndSave(newCpAnalysisConfig),
            )
          }
          afterCancel={() =>
            closeFormAndCallback("newConcurrenceScale", resetEditingDialog)
          }
          {...props}
        />
      )}
    </>
  );
};

export default DisplayOpenForms;
