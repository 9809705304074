/* eslint-disable no-await-in-loop */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import axios from "axios";
import { archiveCenary } from "./cenaryActions";
import { moveInsightToQuestionId, postInsight } from "./insightActions";
import { archiveQuestion, putQuestion } from "./questionsActions";
import { archiveTheme, putTheme } from "./themesActions";

import { apiNode } from "../api";
import {
  getObjectChangedKeys,
  getOnlySublevelArrays,
  groupBy,
} from "../utils/ArrayUtils";
import env from "../utils/environmentUtils";
import { getMultipleOverviewCalc } from "../utils/skillsAssessment/calc";
import {
  buildFormSkillsAssessment,
  buildNewSKillsAssessment,
  buildRestSkillsAssessment,
} from "../utils/skillsAssessment/entity";
import {
  deleteAttributesFromParticipants,
  getAdministrationChangedKeys,
  persistOrderedListByIds,
  persistToolAdministration,
  preparePersistedAdministration,
} from "../utils/skillsAssessment/entityAutomation";
import {
  checkEntitySwitchedAccesses,
  grantCollaboratorAccess,
  grantResponsibleAccess,
} from "./accessControllerActions";
import { removeAccesses } from "./accessLevelsActions";
import {
  DELETE_QUESTION_ANSWER,
  UPDATE_CENARY_THEME,
  UPDATE_QUESTION_ANSWER,
  UPDATE_THEME_QUESTION,
} from "./types";

const defaultEntity = buildNewSKillsAssessment();

const DEBUG = env.isDev();

function debug(arg1, ...optionals) {
  if (DEBUG) console.log("Skills Assessment: ", arg1, ...optionals);
}

export const postSkillAssessmentConfig = (selectedToolId, body) => {
  return axios
    .post(`/api/selected-tools/${selectedToolId}/addSkillsAssessment`, body)
    .then((res) => res);
};

export const putSkillAssessmentConfig = (body) => {
  return axios.put("/api/skills-assessments", body).then((res) => res);
};

export const getSkillAssessmentConfig = (selectedToolId) => {
  return axios
    .get(`/api/selected-tools/${selectedToolId}/skillsAssessment`)
    .then((res) => res);
};

export const putPersistToolAutomation = async (selectedToolId, saveBody) => {
  const persistSave = persistToolAdministration(saveBody);
  await putSkillAssessmentConfig(persistSave);

  const { data } = await getSkillAssessmentConfig(selectedToolId);

  debug("PERSIST Automation: ", { data });

  return buildFormSkillsAssessment(data);
};

export const updateToolConfig = (
  selectedToolId,
  body,
  onlyPost = false,
  autoSave = false
) => {
  return async (dispatch, getState) => {
    const sendDispatch = (type, payload, loading = false, error) => {
      dispatch({ type, payload, loading, error });
    };

    sendDispatch("UPDATE_SKILL_ASSESSMENT", null, true);

    const saveBody = buildRestSkillsAssessment(body, null);
    debug("POST/PUT to Save: ", { received: body, saveBody });

    async function saveNewConfig() {
      try {
        debug("POST new: ", { saveBody });
        const { data: requested } = await getSkillAssessmentConfig(
          selectedToolId
        );
        debug("GET checking: ", { requested });

        const { data: newToolConfig } = await postSkillAssessmentConfig(
          selectedToolId,
          saveBody
        );

        const fetched = buildFormSkillsAssessment(newToolConfig);
        debug("POST result: ", { fetched });

        const storeState = getState();
        const payload = {
          ...fetched,
          selectedToolId,
        };
        dispatch({
          type: "UPDATE_SKILL_ASSESSMENT",
          payload,
          loading: false,
          storeState,
          lastFetch: Date.now(),
        });
      } catch (err) {
        sendDispatch("UPDATE_SKILL_ASSESSMENT", null, false, err);
      }
    }

    async function updateSaveConfig() {
      try {
        if (!saveBody) return;
        if (!saveBody.id) {
          saveNewConfig();
          return;
        }

        if (saveBody.id && !onlyPost) {
          try {
            debug("PUT update: ", { saveBody });

            await putSkillAssessmentConfig(saveBody);

            const { data } = await getSkillAssessmentConfig(selectedToolId);

            debug("PUT result: ", { data });

            const updated = autoSave
              ? await putPersistToolAutomation(selectedToolId, data)
              : buildFormSkillsAssessment(data);

            const storeState = getState();
            const payload = {
              ...updated,
              selectedToolId,
            };
            dispatch({
              type: "UPDATE_SKILL_ASSESSMENT",
              payload,
              loading: false,
              storeState,
              lastFetch: Date.now(),
            });
          } catch (err) {
            debug({ err });
            sendDispatch("UPDATE_SKILL_ASSESSMENT", null, false, err);
          }
        }
      } catch (err) {
        saveNewConfig();
      }
    }

    await updateSaveConfig();
  };
};

export const postInitialConfig = (selectedToolId, saveDefault) => {
  return (dispatch) => {
    if (selectedToolId && saveDefault) {
      debug("Save New Config: ", { selectedToolId, defaultEntity });
      dispatch(updateToolConfig(selectedToolId, defaultEntity, true));
    }
  };
};

export const fetchSkillAssessmentConfig = (
  selectedToolId,
  saveDefault = false
) => {
  return async (dispatch, getState) => {
    const storeState = getState();
    if (storeState.skillAssessment.loading) return;

    debug("Fetch config: ", { selectedToolId });
    dispatch({ type: "UPDATE_SKILL_ASSESSMENT", loading: true });

    const { data = null } = await getSkillAssessmentConfig(selectedToolId);
    debug("GET Result: ", { selectedToolId, data });

    const onDatabase = data && data.id;

    if (onDatabase) {
      const fetched = buildFormSkillsAssessment(data);

      dispatch({
        type: "UPDATE_SKILL_ASSESSMENT",
        payload: { ...fetched, selectedToolId },
        loading: false,
        storeState,
        lastFetch: Date.now(),
      });
    } else {
      debug({ selectedToolId }, "Not on Database");
      dispatch(postInitialConfig(selectedToolId, saveDefault));
    }
  };
};

export const getMultipleSkillsAssesment = async (selectedToolsId = []) => {
  const multipleTools = [];

  if (Array.isArray(selectedToolsId)) {
    await Promise.all(
      selectedToolsId.map(async (toolId) => {
        const { data } = await getSkillAssessmentConfig(toolId);
        if (data && data.id) multipleTools.push(data);
      })
    );
  }

  return multipleTools;
};

export const getSkillsAssessmentOverview = async (selectedToolsId = []) => {
  const toolsData = await getMultipleSkillsAssesment(selectedToolsId);

  return getMultipleOverviewCalc(toolsData);
};

/// ### LINKS DO QUADRO => TEMA
export const createBoardTheme = (cenaryBody, name, order) => {
  return putTheme({
    id: null,
    type: "theme",
    cenary: cenaryBody,
    name,
    order,
  });
};

export const dispatchTheme = (cenaryId, newTheme) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CENARY_THEME,
      payload: {
        cenaryId,
        theme: {
          ...newTheme,
          isNew: true,
        },
      },
    });
  };
};

export const postThemeLink = (themeId, companyAreaId) => {
  return axios
    .post(
      `/api/skills-assessments/theme/${themeId}/CompanyArea/${companyAreaId}`
    )
    .then((res) => res);
};

export const addSingleAreaToBoard = (areaInfo, cenaryBody, order) => {
  return async (dispatch) => {
    const { data: newTheme } = await createBoardTheme(
      cenaryBody,
      areaInfo.title,
      order
    );

    debug("New Theme: ", newTheme);

    if (newTheme.id) {
      const postedLink = await postThemeLink(newTheme.id, areaInfo.id);
      debug("New Theme Link: ", { postedLink });

      if (postedLink.status === 204 || postedLink.status === 200) {
        const updatedThemeData = {
          ...newTheme,
          companyAreaId: areaInfo.id,
        };

        dispatch(dispatchTheme(cenaryBody.id, updatedThemeData));

        return updatedThemeData;
      }
    }
  };
};

export const addAreasToBoard = (areas = [], cenaryBody = {}, order = 0) => {
  return async (dispatch) => {
    if (cenaryBody.id && Array.isArray(areas)) {
      let nextOrder = order;

      await Promise.all(
        areas.map(async (areaInfo) => {
          const added = await dispatch(
            addSingleAreaToBoard(areaInfo, cenaryBody, nextOrder)
          );

          debug("addAreasToBoard", { areaInfo, added });
          nextOrder++;
        })
      );
    }
  };
};
/// ### LINKS DO QUADRO => TEMA

/// ### LINKS DO QUADRO => QUESTÃO
export const createBoardQuestion = (themeBody, text, order) => {
  return putQuestion({
    id: null,
    type: "question",
    theme: themeBody,
    text,
    order,
  });
};

export const dispatchQuestion = (themeId, newQuestion) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_THEME_QUESTION,
      payload: {
        themeId,
        question: {
          ...newQuestion,
          isNew: true,
        },
      },
    });
  };
};

export const postQuestionLink = (questionId, profileId) => {
  return axios
    .post(
      `/api/skills-assessments/question/${questionId}/SkillsAssessmentProfile/${profileId}`
    )
    .then((res) => res);
};

export const addSingleProfileToBoard = (profileInfo, themeBody, order) => {
  return async (dispatch) => {
    const { data: newQuestion } = await createBoardQuestion(
      themeBody,
      profileInfo.title,
      order
    );

    debug("New Question: ", newQuestion);

    if (newQuestion.id) {
      const postedLink = await postQuestionLink(newQuestion.id, profileInfo.id);
      debug("New Question Link: ", { postedLink });

      if (postedLink.status === 204 || postedLink.status === 200) {
        const updatedQuestionData = {
          ...newQuestion,
          skillsAssessmentProfileId: profileInfo.id,
        };

        dispatch(dispatchQuestion(themeBody.id, updatedQuestionData));

        return updatedQuestionData;
      }
    }
  };
};

export const addProfilesToBoard = (
  profiles = [],
  themeBody = {},
  order = 0
) => {
  return async (dispatch) => {
    if (themeBody.id && Array.isArray(profiles)) {
      let nextOrder = order;

      await Promise.all(
        profiles.map(async (profileInfo) => {
          const added = await dispatch(
            addSingleProfileToBoard(profileInfo, themeBody, nextOrder)
          );

          debug("addProfilesToBoard", { profileInfo, added });
          nextOrder++;
        })
      );
    }
  };
};
/// ### LINKS DO QUADRO => QUESTÃO

/// ### LINKS DO QUADRO => INSIGHT
export const createBoardInsight = async (
  questionBody,
  text,
  responsible,
  order
) => {
  const { data: postedInsight } = await postInsight(
    {
      id: null,
      type: "insight",
      text,
      name: text,
      order,
      responsibleMember: { id: responsible.id },
    },
    questionBody.id
  );
  debug({ postedInsight });

  return postedInsight;
};

export const dispatchInsight = (questionId, newInsight) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_QUESTION_ANSWER,
      payload: {
        questionId,
        answer: { ...newInsight, isNew: true },
      },
    });
  };
};

export const postInsightLink = (insightId, participantId) => {
  return axios
    .post(
      `/api/skills-assessments/insight/${insightId}/SkillsAssessmentParticipant/${participantId}`
    )
    .then((res) => res);
};

export const grantInsightAccessByParticipant = (
  participantInfo,
  insightId = null
) => {
  return async (dispatch, getState) => {
    let validInsightId = insightId;

    if (!validInsightId) {
      const { insightData } = getState();

      const insights = getOnlySublevelArrays(insightData.allAnswers);
      const filteredInsight = insights.find(
        (insight) =>
          insight.skillsAssessmentParticipantId === participantInfo.id
      );
      validInsightId = filteredInsight?.id;
    }

    if (validInsightId) {
      dispatch(
        grantResponsibleAccess(
          participantInfo.memberParticipantId,
          validInsightId
        )
      );

      if (participantInfo.managerId)
        dispatch(
          grantCollaboratorAccess(participantInfo.managerId, validInsightId)
        );
      if (participantInfo.secondEvaluatorId)
        dispatch(
          grantCollaboratorAccess(
            participantInfo.secondEvaluatorId,
            validInsightId
          )
        );
      if (participantInfo.thirdEvaluatorId)
        dispatch(
          grantCollaboratorAccess(
            participantInfo.thirdEvaluatorId,
            validInsightId
          )
        );
    }
  };
};

export const removeCollaboratorsInsightAccess = (
  currentParticipant,
  updatedParticipant
) => {
  return async (dispatch, getState) => {
    const { access, insightData } = getState();

    const insights = getOnlySublevelArrays(insightData.allAnswers);
    const filteredInsight = insights.find(
      (insight) =>
        insight.skillsAssessmentParticipantId === currentParticipant.id
    );
    const insightId = filteredInsight?.id;
    if (!insightId) return;

    const { domainUsers } = access;

    const insightEntityAccess = {
      entityId: insightId,
      entityType: "INSIGHT",
      role: "COLLABORATOR",
      accessType: "RESTRICT",
    };

    function getDomainUserIdByUserId(id) {
      return domainUsers.find((member) => member?.id === id)?.domainUserId;
    }

    if (currentParticipant.managerId !== updatedParticipant.managerId) {
      const managerDomainId = getDomainUserIdByUserId(
        currentParticipant.managerId
      );
      dispatch(removeAccesses([insightEntityAccess], managerDomainId));
    }
    if (
      currentParticipant.secondEvaluatorId !==
      updatedParticipant.secondEvaluatorId
    ) {
      const secondEvaluatorDomainId = getDomainUserIdByUserId(
        currentParticipant.secondEvaluatorId
      );
      dispatch(removeAccesses([insightEntityAccess], secondEvaluatorDomainId));
    }

    if (
      currentParticipant.thirdEvaluatorId !==
      updatedParticipant.thirdEvaluatorId
    ) {
      const thirdEvaluatorDomainId = getDomainUserIdByUserId(
        currentParticipant.thirdEvaluatorId
      );
      dispatch(removeAccesses([insightEntityAccess], thirdEvaluatorDomainId));
    }
  };
};

export const addSingleParticipantToBoard = (
  participantInfo,
  questionBody,
  order
) => {
  return async (dispatch) => {
    const newInsight = await createBoardInsight(
      questionBody,
      participantInfo.memberParticipant.name,
      participantInfo.memberParticipant,
      order
    );

    debug("New Insight: ", newInsight);

    if (newInsight.id) {
      const postedLink = await postInsightLink(
        newInsight.id,
        participantInfo.id
      );
      debug("New Insight Link: ", { postedLink });

      if (postedLink.status === 204 || postedLink.status === 200) {
        const updatedInsightData = {
          ...newInsight,
          skillsAssessmentParticipantId: participantInfo.id,
        };

        debug({ updatedInsightData });

        dispatch(
          await grantInsightAccessByParticipant(participantInfo, newInsight.id)
        );
        dispatch(dispatchInsight(questionBody.id, updatedInsightData));
        dispatch(checkEntitySwitchedAccesses([newInsight.id]));

        return updatedInsightData;
      }
    }
  };
};

export const addParticipantsToBoard = (
  participants = [],
  questionBody = {},
  order = 0
) => {
  return async (dispatch) => {
    if (questionBody.id && Array.isArray(participants)) {
      let nextOrder = order;

      await Promise.all(
        participants.map(async (participantInfo) => {
          await dispatch(
            addSingleParticipantToBoard(
              participantInfo,
              questionBody,
              nextOrder
            )
          );

          debug("addParticipantsToBoard", { participantInfo });
          nextOrder++;
        })
      );
    }
  };
};
/// ### LINKS DO QUADRO => INSIGHT

/// ### DELETES DA ADMINISTRAÇÃO
export const deleteScale = (selectedToolId, scaleSlug, scaleId) => {
  return async (dispatch) => {
    if (selectedToolId && scaleSlug && scaleId) {
      const deleteData = await axios.delete(
        `/api/skills-assessment-scales/${scaleId}`
      );

      debug("deleteScale: ", {
        selectedToolId,
        scaleSlug,
        scaleId,
        deleteData,
      });

      dispatch(fetchSkillAssessmentConfig(selectedToolId));
    }
  };
};

export const deleteProfile = (selectedToolId, profileId) => {
  return async (dispatch) => {
    if (selectedToolId && profileId) {
      const deleteData = await axios.delete(
        `/api/skills-assessment-profiles/${profileId}`
      );

      debug("deleteProfile: ", { selectedToolId, profileId, deleteData });

      dispatch(fetchSkillAssessmentConfig(selectedToolId));
    }
  };
};

export const deleteParticipant = (selectedToolId, participantId, callback) => {
  const { onSuccess, onError } = callback || {};

  return async (dispatch) => {
    debug("deleteParticipant: ", {
      selectedToolId,
      participantId,
    });

    if (selectedToolId && participantId) {
      try {
        const deleteData = await axios.delete(
          `/api/skills-assessment-participants/${participantId}`
        );

        if (onSuccess) onSuccess();

        debug("deleteParticipant: ", {
          deleteData,
        });

        await dispatch(await fetchSkillAssessmentConfig(selectedToolId));
        dispatch(checkEntitySwitchedAccesses());
      } catch (err) {
        if (onError) onError(err);
      }
    } else {
      onError("Invalid paramenters");
    }
  };
};

export const deleteCompetenceArea = (selectedToolId, competenceAreaId) => {
  return async (dispatch) => {
    debug("deleteCompetenceArea: ", {
      selectedToolId,
      competenceAreaId,
    });

    if (selectedToolId && competenceAreaId) {
      const deleteData = await axios.delete(
        `/api/skills-assessment-competence-areas/${competenceAreaId}`
      );

      debug("deleteCompetenceArea: ", {
        deleteData,
      });

      dispatch(fetchSkillAssessmentConfig(selectedToolId));
    }
  };
};

export const deleteSkill = (selectedToolId, skillId) => {
  return async (dispatch) => {
    debug("deleteSkill: ", { selectedToolId, skillId });

    if (selectedToolId && skillId) {
      const deleteData = await axios.delete(
        `/api/skills-assessment-skills/${skillId}`
      );
      debug("deleteSkill: ", { deleteData });
      dispatch(fetchSkillAssessmentConfig(selectedToolId));
    }
  };
};

export const deleteComplementaryScale = (selectedToolId, scaleId) => {
  return async (dispatch) => {
    debug("deleteComplementaryScale: ", {
      selectedToolId,
      scaleId,
    });

    if (selectedToolId && scaleId) {
      const deleteData = await axios.delete(
        `/api/complementary-scales/${scaleId}`
      );
      debug("deleteComplementaryEvaluation: ", { deleteData });
      dispatch(fetchSkillAssessmentConfig(selectedToolId));
    }
  };
};

export const deleteComplementaryEvaluation = (selectedToolId, evaluationId) => {
  return async (dispatch) => {
    debug("deleteComplementaryEvaluation: ", {
      selectedToolId,
      evaluationId,
    });

    if (selectedToolId && evaluationId) {
      const deleteData = await axios.delete(
        `/api/complementary-evaluations/${evaluationId}`
      );
      debug("deleteComplementaryEvaluation: ", { deleteData });
      dispatch(fetchSkillAssessmentConfig(selectedToolId));
    }
  };
};
/// ### DELETES DA ADMINISTRAÇÃO

export async function getToolParticipantWorkflow({ selected_tool_id }) {
  return apiNode
    .get(`/api/v2/skills-assessment/self-evaluation-reply/${selected_tool_id}`)
    .then((res) => res);
}

export async function putParticipantSelfEvaluation({
  selected_tool_id,
  participantWorkflow,
}) {
  return apiNode
    .put("/api/v2/skills-assessment/participant-self-evaluation", {
      selected_tool_id,
      participantWorkflow,
    })
    .then((res) => res);
}

export async function putParticipantSelectEvaluators({
  selected_tool_id,
  participantWorkflow,
}) {
  return apiNode
    .put("/api/v2/skills-assessment/participant-select-evaluators", {
      selected_tool_id,
      participantWorkflow,
    })
    .then((res) => res);
}

export async function putUpdateParticipant({ updatedParticipant }) {
  const [saveParticipant] = deleteAttributesFromParticipants([
    updatedParticipant,
  ]);

  const { data: response } = await axios
    .put("/api/skills-assessment-participants", saveParticipant)
    .then((res) => res);

  return response;
}

export async function saveAdministrationChanges({
  selected_tool_id,
  changedKeys,
}) {
  return apiNode
    .put("/api/v2/skills-assessment/administration-changes", {
      selected_tool_id,
      changedKeys,
    })
    .then((res) => res);
}

export function putAdministrationChanges({ selected_tool_id, changedKeys }) {
  return apiNode
    .put("/api/v2/skills-assessment/administration-changes", {
      selected_tool_id,
      changedKeys,
    })
    .then((res) => res);
}

export async function saveAdministrationChangesWithPersistence({
  selected_tool_id,
  changedKeys,
}) {
  return async (dispatch, getState) => {
    debug("saveAdministrationChangesWithPersistence", { changedKeys });

    let { data } = await putAdministrationChanges({
      selected_tool_id,
      changedKeys,
    });

    debug("administration-changes result: ", { data });

    if (data?.updated) {
      const persistUpdatedResult = persistToolAdministration(data.updated);
      const newChangedKeys = getAdministrationChangedKeys(
        persistUpdatedResult,
        preparePersistedAdministration(data.updated)
      );
      debug("persist changes: ", { newChangedKeys });

      if (Object.keys(newChangedKeys).length > 0) {
        // Second call to persist changes
        const { data: persistedData } = await putAdministrationChanges({
          selected_tool_id,
          changedKeys: newChangedKeys,
        });

        data = persistedData;
      }

      const storeState = getState();
      dispatch({
        type: "UPDATE_SKILL_ASSESSMENT",
        payload: data?.updated,
        loading: false,
        storeState,
        lastFetch: Date.now(),
      });
    }
  };
}

export function putUpdateParticipantWithPersistence({
  selectedToolId,
  currentParticipant,
  updatedParticipant,
}) {
  return async (dispatch, getState) => {
    const sendDispatch = async (type, payload, loading = false, error) => {
      dispatch({ type, payload, loading, error });
    };

    await sendDispatch("UPDATE_SKILL_ASSESSMENT", null, true);

    const participant = await putUpdateParticipant({ updatedParticipant });

    const storeState = getState();

    dispatch(grantInsightAccessByParticipant(participant));
    dispatch(
      removeCollaboratorsInsightAccess(currentParticipant, updatedParticipant)
    );

    const { data: restAdministration } = await getSkillAssessmentConfig(
      selectedToolId
    );
    const toolAdministration = persistOrderedListByIds(restAdministration);

    if (participant?.id) {
      const participantIndex = toolAdministration.participants.findIndex(
        ({ id }) => id === participant.id
      );
      const updatedParticipants = [...toolAdministration.participants];
      updatedParticipants[participantIndex] = participant;

      const persistSave = persistToolAdministration({
        ...toolAdministration,
        participants: updatedParticipants,
      });

      const changedKeys = getObjectChangedKeys(persistSave, toolAdministration);
      debug({ changedKeys });

      if (Object.keys(changedKeys).length > 0) {
        const { data: response } = await saveAdministrationChanges({
          selected_tool_id: selectedToolId,
          changedKeys,
        });

        const payload = {
          ...response.updated,
          selectedToolId,
        };
        dispatch({
          type: "UPDATE_SKILL_ASSESSMENT",
          payload,
          loading: false,
          storeState,
          lastFetch: Date.now(),
        });
      } else {
        sendDispatch("UPDATE_SKILL_ASSESSMENT", null, false);
      }
    }
  };
}

export async function putUpdateParticipantWorkflows({
  selected_tool_id,
  workflow,
  complementaryWorkflow,
}) {
  return apiNode
    .put("/api/v2/skills-assessment/update-workflows", {
      selected_tool_id,
      workflow,
      complementaryWorkflow,
    })
    .then((res) => res);
}

export async function sendInviteToParticipants({
  selected_tool_id,
  participantIds,
}) {
  return apiNode
    .post("/api/v2/skills-assessment/send-participant-invites", {
      selected_tool_id,
      participantIds,
    })
    .then((res) => res);
}

export async function sendSelectEvaluators({
  selected_tool_id,
  participantIds,
}) {
  return apiNode
    .post("/api/v2/skills-assessment/send-select-evaluators", {
      selected_tool_id,
      participantIds,
    })
    .then((res) => {
      return res;
    });
}

export async function sendManagersNotification({
  selected_tool_id,
  managerIds,
}) {
  return apiNode
    .post("/api/v2/skills-assessment/send-manager-notification", {
      selected_tool_id,
      managerIds,
    })
    .then((res) => res);
}

export async function sendManagersNotificationSelectionEvaluators() {
  return apiNode
    .post("/api/v2/skills-assessment/participants-pending-approval-by-manager")
    .then((res) => res);
}

export async function updateParticipantsProfileByCSV({
  selected_tool_id,
  imported_table,
}) {
  return apiNode
    .put("/api/v2/skills-assessment/update-participants-profile", {
      selected_tool_id,
      imported_table,
    })
    .then((res) => res);
}

export async function updateParticipantsNextWorkflowStage({
  selected_tool_id,
  participantIds,
  nextEvaluationStage,
}) {
  return apiNode
    .put("/api/v2/skills-assessment/update-workflow-stage", {
      selected_tool_id,
      participantIds,
      nextEvaluationStage,
    })
    .then((res) => res);
}

export function getAdministrationParticipantsBoardChanges({
  toolAdministration,
  updatedRelations,
}) {
  return toolAdministration.participants.map((participant) => {
    const updatedRelation = updatedRelations.find(
      ({ participant: p }) => p?.id === participant?.id
    );

    if (!updatedRelation?.participant?.id) return participant;

    return {
      ...participant,
      ...updatedRelation.participant,
    };
  });
}

export async function updateParticipantsWithBoardRelation({
  selected_tool_id,
  updatedRelations,
  toolAdministration,
}) {
  return async (dispatch) => {
    if (!selected_tool_id || !toolAdministration?.id) return;

    // Step 1: administration change updates
    const administrationConfig =
      preparePersistedAdministration(toolAdministration);
    const participantsWithChanges = getAdministrationParticipantsBoardChanges({
      toolAdministration: administrationConfig,
      updatedRelations,
    });
    const administrationWithChanges = {
      ...administrationConfig,
      participants: participantsWithChanges,
    };
    const persistAdministrationUpdates = persistToolAdministration(
      administrationWithChanges
    );
    const changedKeys = getAdministrationChangedKeys(
      persistAdministrationUpdates,
      administrationConfig
    );

    if (Object.keys(changedKeys).length > 0) {
      await dispatch(
        saveAdministrationChangesWithPersistence({
          selected_tool_id,
          changedKeys,
        })
      );
    }
    // End: Step 1

    // Step 2: preparing update for participants with board relation changes
    const groupedByCenary = groupBy(updatedRelations, "cenaryId");
    const createdThemes = {};
    const createdQuestions = {};

    async function createTheme(cenary, companyArea) {
      if (!cenary?.id || !companyArea?.id) return null;

      if (!createdThemes[cenary.id]) createdThemes[cenary.id] = {};

      const currentTheme = createdThemes[cenary.id][companyArea.id];

      if (!currentTheme?.id) {
        createdThemes[cenary.id][companyArea.id] = await dispatch(
          addSingleAreaToBoard(companyArea, cenary)
        );
      }

      return createdThemes[cenary.id][companyArea.id];
    }

    async function createQuestion(theme, profile) {
      if (!theme?.id || !profile?.id) return null;

      if (!createdQuestions[theme.id]) createdQuestions[theme.id] = {};

      const currentQuestion = createdQuestions[theme.id][profile.id];

      if (!currentQuestion?.id) {
        createdQuestions[theme.id][profile.id] = await dispatch(
          addSingleProfileToBoard(profile, theme)
        );
      }

      return createdQuestions[theme.id][profile.id];
    }

    // End: Step 2

    // Step 3: create new themes and questions for each cenary and profile
    dispatch({
      type: "UPDATE_SKILL_ASSESSMENT",
      loading: true,
    });

    const addedParticipantIds = [];

    for (const cenaryId in groupedByCenary) {
      for (const itemIndex in groupedByCenary[cenaryId]) {
        const updatedRelation = groupedByCenary[cenaryId][itemIndex];

        const { participant, cenary, theme, question } = updatedRelation || {};
        const { id, companyArea, companyPosition, profile } = participant;

        if (addedParticipantIds.indexOf(id) > -1) return;
        addedParticipantIds.push(id);

        if (
          !id ||
          !cenary?.id ||
          !companyArea?.id ||
          !companyPosition?.id ||
          !profile?.id
        ) {
          debug("missing id relations");
          return;
        }

        if (!theme?.id) {
          // Create new theme, question and insight
          debug("Create new theme, question and insight");
          const theme = await createTheme(cenary, companyArea);
          debug({ theme });
          const newQuestion = await createQuestion(theme, profile);
          const insight = await dispatch(
            addSingleParticipantToBoard(participant, newQuestion)
          );
          debug({ theme, newQuestion, insight });
        } else if (!question?.id) {
          // Create new question and insight
          debug("Create new question and insight");
          const newQuestion = await createQuestion(theme, profile);
          const insight = await dispatch(
            addSingleParticipantToBoard(participant, newQuestion)
          );
          debug({ newQuestion, insight });
        } else {
          // Create new insight
          debug("Create new insight");
          const insight = await dispatch(
            addSingleParticipantToBoard(participant, question)
          );
          debug({ insight });
        }
      }
    }

    dispatch({
      type: "UPDATE_SKILL_ASSESSMENT",
      loading: false,
    });
    // End: Step 3
  };
}

export async function moveInsightToCenary({
  toolAdministration,
  insightId,
  selectedCenaryId,
  updatedRelation,
}) {
  return async (dispatch) => {
    if (
      !toolAdministration?.id ||
      !insightId ||
      !selectedCenaryId ||
      !updatedRelation?.participant
    )
      return null;

    const {
      participant,
      cenary,
      theme,
      question,
      currentRelation,
      validation,
    } = updatedRelation || {};
    const { id, companyArea, companyPosition, profile } = participant;

    if (
      !id ||
      !cenary?.id ||
      !companyArea?.id ||
      !companyPosition?.id ||
      !profile?.id
    ) {
      debug("missing id relations");
      return null;
    }

    function createTheme(cenary, companyArea) {
      if (!cenary?.id || !companyArea?.id) return null;

      return dispatch(addSingleAreaToBoard(companyArea, cenary));
    }

    function createQuestion(theme, profile) {
      if (!theme?.id || !profile?.id) return null;

      return dispatch(addSingleProfileToBoard(profile, theme));
    }

    async function moveInsight(newQuestionId) {
      const { data: updatedInsight } = await moveInsightToQuestionId(
        insightId,
        newQuestionId
      );
      dispatch({
        type: DELETE_QUESTION_ANSWER,
        payload: { answer: currentRelation.insight },
      });
      dispatch(await grantInsightAccessByParticipant(participant, insightId));
      dispatch(dispatchInsight(newQuestionId, updatedInsight));
      dispatch(checkEntitySwitchedAccesses([insightId]));
      return updatedInsight;
    }

    if (!theme?.id) {
      debug("Create new theme, question and move insight");
      const newTheme = await createTheme(cenary, companyArea);
      const newQuestion = await createQuestion(newTheme, profile);
      const movedInsight = await moveInsight(newQuestion.id);
      debug({ newTheme, newQuestion, movedInsight });
    } else if (!question?.id) {
      debug("Create new question and move insight");
      const newQuestion = await createQuestion(theme, profile);
      const movedInsight = await moveInsight(insightId, newQuestion.id);
      debug({ newQuestion, movedInsight });
    } else {
      debug("Move insight");
      const movedInsight = await moveInsight(question.id);
      debug({ movedInsight });
    }

    if (validation.archiveCenary) {
      dispatch(archiveCenary(currentRelation.cenary));
    } else if (validation.archiveTheme) {
      dispatch(archiveTheme(currentRelation.theme));
    } else if (validation.archiveQuestion) {
      dispatch(archiveQuestion(currentRelation.question));
    }
  };
}

export async function updateParticipantsEvaluatorRoles({
  selected_tool_id,
  changedKeys,
}) {
  return apiNode.put(
    "/api/v2/skills-assessment/update-participants-evaluator-roles",
    {
      selected_tool_id,
      changedKeys,
    }
  );
}
