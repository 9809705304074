import React from "react";
import { connect } from "react-redux";
import utils from "../../../../utils/toolUtils";
import AverageBadge from "../../../Common/AverageBadge";
import { getSelectedToolStates } from "../../../customMapStates";

const { getAllInsightEvaluationInfo } = utils;

const ProjectPrioritization = (props) => {
  const {
    insightsToCalc,
    allProjectPrioritization,
    allInsightPrioritizations,
    selectedTool,
  } = props;
  const selectedInsight = insightsToCalc[0] || {};

  const {
    maxCriteriaSum,
    sumEvaluation,
    pathway,
  } = getAllInsightEvaluationInfo(
    selectedInsight,
    selectedTool.id,
    allProjectPrioritization,
    allInsightPrioritizations,
  );

  return props.from === "answer" || props.from === "score" ? (
    <>
      <AverageBadge
        average={sumEvaluation || 0}
        maxValue={maxCriteriaSum}
        displayMaxValue
        multiplier={5}
      />
      {props.showPathway && (
        <h5
          style={{
            margin: "0px",
            padding: "0px 5px",
            height: "25px",
            lineHeight: "25px",
            border: "1px solid #ddd",
            backgroundColor: "#eee",
          }}
        >
          {pathway}
        </h5>
      )}
    </>
  ) : null;
};

const mapStateToProps = (state) => {
  const { projectPrioritization } = state;
  const { selectedTool } = getSelectedToolStates(state);

  const allInsightPrioritizations = projectPrioritization.allInsightPrioritizations
    ? projectPrioritization.allInsightPrioritizations
    : [];

  const allProjectPrioritization = projectPrioritization.all
    ? projectPrioritization.all
    : [];

  return {
    allInsightPrioritizations,
    allProjectPrioritization,
    selectedTool,
  };
};

export default connect(mapStateToProps)(ProjectPrioritization);
