import React from "react";
import PropTypes from "prop-types";
import { getSimpleParticipantsTree } from "../../../utils/skillsAssessment/filter";
import SelectTreeModal from "../SelectTreeModal";

const NewInsight = ({
  open,
  onConfirm,
  onCancel,
  participants,
  profileName,
}) => {
  const participantsTree = getSimpleParticipantsTree(participants);

  return (
    <SelectTreeModal
      open={open}
      title="Selecione os Participantes"
      treeList={participantsTree.treeList}
      treeOrder={participantsTree.treeOrder}
      initialSelected={participantsTree.all}
      onConfirm={(selected) => onConfirm(selected)}
      onCancel={onCancel}
      canSelectAnyLevel
      singleSelect={false}
      dialogClassName="select-insight-dialog"
      resetWhenOpen={false}
      bottomMessage={
        <div style={{ padding: "15px" }}>
          Apenas os participantes do Perfil "{`${profileName}`}" e que não foram
          vinculados ao Quadro estão sendo mostrados.
        </div>
      }
    />
  );
};

NewInsight.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  participants: PropTypes.array.isRequired,
};

NewInsight.defaultProps = {
  open: false,
};

export default NewInsight;
