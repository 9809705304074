import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import FormDialog from "../../../../../../Common/FormDialog";
import MaterialTextField from "../../../../../../Common/MaterialTextField";
import MaterialSingleSelect from "../../../../../../Common/MaterialSingleSelect";
import { AlertStyle } from "../../css/OkrCss";
import { postKey, putKey } from "../../../../../../../actions/okrActions";
import { configKeysBody } from "../../../../../../../utils/okr/entity";
import utils from "../../../../../../../utils/toolUtils";
import { orderArrayByObjAttr } from "../../../../../../../utils/ArrayUtils";

const { iterationSearch } = utils;
const intialConfigKeyBody = configKeysBody({});

const KeyForm = ({
  open,
  onCancel,
  isEditing,
  postKey,
  getAllOptionsObjective,
  objectives,
  insightQuestionId,
  saveInsightKeyResult,
  intl,
}) => {
  const [keyResult, setKeyResult] = useState(intialConfigKeyBody);
  const [objectiveId, setObjectiveId] = useState("");

  useEffect(() => {
    if (Object.keys(isEditing.params).length > 0) {
      setKeyResult(isEditing.params.keyResult);
      setObjectiveId(isEditing.params.objectiveId);
    }
  }, [isEditing]);

  function validForm() {
    let titleError = "";
    let objectiveError = "";
    const keyTitles = [];
    let filterkeyById = [];

    if (keyResult.title === "" || keyResult.title.length < 3) {
      titleError = intl.formatMessage({ id: "min_caractere" });
    }

    if (objectives.length) {
      objectives.forEach((objective) => {
        objective.keys.map((key) =>
          keyTitles.push(
            key.title.toString().toUpperCase().replace(/\s/g, "").trim(),
          ),
        );
      });
    }

    const isEqualKeyTitle = iterationSearch(keyResult.title, keyTitles);

    objectives.forEach((objectiveInfo) => {
      filterkeyById = objectiveInfo.keys.filter((keyInfo) => {
        return keyResult.id !== keyInfo.id;
      });
    });

    const keyTitleEquals = filterkeyById.filter((keyInfo) => {
      return (
        keyInfo.title.toString().toUpperCase().replace(/\s/g, "").trim() ===
        keyResult.title.toString().toUpperCase().replace(/\s/g, "").trim()
      );
    });

    if (isEqualKeyTitle && !keyResult.id) {
      titleError = intl.formatMessage({ id: "key_result_repeat" });
    }

    if (keyResult.id && keyTitleEquals.length >= 1) {
      titleError = intl.formatMessage({ id: "key_result_repeat" });
    }

    if (
      (objectiveId === "" || objectiveId === "empty" || !objectiveId) &&
      !saveInsightKeyResult
    ) {
      objectiveError = intl.formatMessage({ id: "select_objective" });
    }

    if (titleError || objectiveError) {
      setKeyResult({ ...keyResult, titleError, objectiveError });

      return false;
    }

    return true;
  }

  function resetCloseModal() {
    setKeyResult(intialConfigKeyBody);
    setObjectiveId("");

    onCancel("keyModal", false);
  }

  function handleConfirm() {
    if (validForm()) {
      const questionIdOrObjectiveId = insightQuestionId || objectiveId;

      if (keyResult.id) {
        putKey(objectiveId, { ...keyResult, objectiveId });
      } else if (!keyResult.id) {
        postKey(questionIdOrObjectiveId, keyResult, saveInsightKeyResult);
      }

      resetCloseModal();
    }
  }

  return (
    <FormDialog
      open={open}
      title={intl.formatMessage({ id: "add_key_result" })}
      onCancel={() => resetCloseModal()}
      onConfirm={() => handleConfirm()}
    >
      {keyResult && (keyResult.titleError || keyResult.objectiveError) && (
        <AlertStyle severity="error">
          <span>{keyResult.titleError}</span>
          <span>{keyResult.objectiveError}</span>
        </AlertStyle>
      )}
      {!saveInsightKeyResult && (
        <MaterialSingleSelect
          id="select-objective"
          options={orderArrayByObjAttr(getAllOptionsObjective, "label")}
          value={objectiveId || ""}
          label={intl.formatMessage({ id: "select_objective" })}
          onChange={(e) => setObjectiveId(e.target.value)}
        />
      )}
      <MaterialTextField
        id="key-name"
        value={(keyResult && keyResult.title) || ""}
        onChange={(e) => setKeyResult({ ...keyResult, title: e.target.value })}
        label={intl.formatMessage({ id: "name_key_result" })}
      />
    </FormDialog>
  );
};

const mapDispatchToProps = {
  postKey,
};

export default connect(null, mapDispatchToProps)(injectIntl(KeyForm));

KeyForm.propTypes = {
  isEditing: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  postKey: PropTypes.func.isRequired,
  getAllOptionsObjective: PropTypes.array.isRequired,
  saveInsightKeyResult: PropTypes.func,
  intl: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  objectives: PropTypes.array.isRequired,
  insightQuestionId: PropTypes.number,
};

KeyForm.defaultProps = {
  insightQuestionId: null,
  saveInsightKeyResult: null,
};
