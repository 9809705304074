import { SELECT_CENARY } from "../actions/types";

const INITIAL_STATE = null;

const selectedCenaryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_CENARY:
      return action.payload;
    default:
      return state;
  }
};

export default selectedCenaryReducer;
