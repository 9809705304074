import { checkWorkspaceToolIdInState } from "../../../utils/entity_validations";
import { simpleNotifier } from "../../../../notifierActions";

export const checkPostAdministrationSelectedTool = (selectedToolId) => {
  if (selectedToolId)
    return checkWorkspaceToolIdInState({ id: selectedToolId });

  return false;
};

export const toolUpdateNotifier = () =>
  simpleNotifier("Ferramenta atualizada", "success", null, "kpisUpdate");
