import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 15px;
`;

export const SimpleTableCustom = styled.table`
  & > tbody > tr > td {
    border: 6px solid #fff !important;
    background-color: #efefef !important;
    padding: 13px 7px !important;
  }
  & > thead > tr > td {
    border-left: 6px solid #fff !important;
    border-right: 6px solid #fff !important;
    border-bottom: 6px solid #fff !important;
    background-color: #dedede !important;
    padding: 13px 7px !important;
  }
`;

export const TdStatus = styled.td`
  border-top: 6px solid ${(props) => props.color || "#ccc"} !important;
  font-size: 12px;
`;

export const RowTable = styled.td`
  width: 85px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;
