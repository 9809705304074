import React from "react";
import SimpleCheckbox from "../../../../../../Common/SimpleCheckbox";
import { getCompetenceCompositionOptions } from "../../../../../../../utils/skillsAssessment/constants";

const TableEvaluationComposition = ({ composition, updateComposition }) => {
  const compositionOptions = getCompetenceCompositionOptions();

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="100">COMPOSIÇÃO DA AVALIAÇÃO</td>
        </tr>
      </thead>
      <tbody>
        {compositionOptions?.map(({ value, label }) => {
          return (
            <tr key={value}>
              <td width="35">
                <SimpleCheckbox
                  checked={value === composition}
                  onChange={() => updateComposition(value)}
                />
              </td>
              <td>{label}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableEvaluationComposition;
