import React, { useState, useEffect } from "react";
import styled from "styled-components";
import NewPeriod from "./NewPeriod";
import PeriodCard from "./PeriodCard";
import LoadingFallback from "../../../Common/LoadingFallback";
import {
  getCustomPeriodWorkspaceTools,
  getCloneServiceStatus,
} from "../../../../actions/customPeriodActions";
import { orderArrayByObjAttr } from "../../../../utils/ArrayUtils";

const Wrapper = styled.div`
  padding: 10px;
`;

const BoxPeriods = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const Actions = styled.div`
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  gap: 15px;
`;

const AddNew = styled.button`
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #222;
  border: 1px solid transparent;
  color: #fff;

  &:hover {
    background-color: #fff;
    color: #222;
    border: 1px solid #222;
  }

  i {
    padding: 0px 5px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: "not-allowed";
    pointer-events: none;
  }
`;

const PeriodsConfig = ({
  selectedWorkspaceID,
  selectedDomainId,
  workspaceTools,
  toolTypeId,
  selectedTool,
  loggedUser,
}) => {
  const [newPeriodOpen, setNewPeriodOpen] = useState(false);
  const [customPeriods, setCustomPeriods] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cloneServiceStatus, setCloneServiceStatus] = useState("DISABLED");

  async function fetchPeriods() {
    setIsLoading(true);
    try {
      const addedCustomPeriods = await getCustomPeriodWorkspaceTools(
        workspaceTools,
      );
      const { data: statusData } = await getCloneServiceStatus(toolTypeId);

      setCloneServiceStatus(statusData.status || "DISABLED");
      setCustomPeriods(addedCustomPeriods || []);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchPeriods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTool]);

  function handleNewPeriod() {
    if (cloneServiceStatus === "ENABLED") setNewPeriodOpen(true);
  }

  function onClose() {
    setNewPeriodOpen(false);
  }

  const showPeriods = !isLoading && customPeriods?.length > 0;
  const notEnabled = cloneServiceStatus !== "ENABLED";

  return (
    <Wrapper>
      <LoadingFallback open={isLoading} showCircularProgress />
      <div>
        <Actions>
          <AddNew
            onClick={handleNewPeriod}
            disabled={notEnabled}
            title={
              notEnabled
                ? "Serviço desabilitado temporariamente para manutenção"
                : ""
            }
          >
            <i className="fas fa-plus" />
            {showPeriods ? "Novo período" : "Configurar período atual"}
          </AddNew>
        </Actions>
        <BoxPeriods>
          {orderArrayByObjAttr(customPeriods, "title")?.map(
            (customPeriodInfo) => {
              return (
                <PeriodCard
                  key={customPeriodInfo.id}
                  customPeriod={customPeriodInfo}
                  fetchPeriods={fetchPeriods}
                />
              );
            },
          )}
        </BoxPeriods>
        {newPeriodOpen && (
          <NewPeriod
            open
            onClose={onClose}
            selectedWorkspaceID={selectedWorkspaceID}
            selectedDomainId={selectedDomainId}
            selectedTool={selectedTool}
            toolTypeId={toolTypeId}
            isFirstPeriod={!showPeriods}
            fetchPeriods={fetchPeriods}
            loggedUser={loggedUser}
            customPeriods={customPeriods}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default PeriodsConfig;
