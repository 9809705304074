import React from "react";
import PropTypes from "prop-types";
import TableComplementaryEvaluations from "./TableComplementaryEvaluations";
import TableComplementaryScale from "./TableComplementaryScale";
import { getComplementaryEvaluationsBySubtype } from "../../../../../../../utils/skillsAssessment/filter";
import { getStyledErrorMessage } from "../../../../../../../utils/formValidation";
import {
  keyseatEvaluationsErrorSlugs,
  keySeatScaleErrorSlugs,
} from "../../../../../../../utils/skillsAssessment/errorConstants";

const TableKeySeatEvaluation = ({
  toolAdministration,
  updateAdministration,
  addComplementaryEvaluation,
  addScale,
  openDeleteScale,
  openDeleteEvaluation,
  errors,
}) => {
  const { complementaryEvaluations } = toolAdministration || {};
  const criticality = getComplementaryEvaluationsBySubtype(
    complementaryEvaluations,
    "KEY_SEAT_CRITICALITY",
  );
  const risk = getComplementaryEvaluationsBySubtype(
    complementaryEvaluations,
    "KEY_SEAT_LOSING_RISK",
  );
  const gravity = getComplementaryEvaluationsBySubtype(
    complementaryEvaluations,
    "KEY_SEAT_LOSING_GRAVITY",
  );

  return (
    <div className="row">
      <div className="col-xs-12 col-lg-8">
        <TableComplementaryEvaluations
          title="CRITICIDADE DAS FUNÇÕES"
          evaluations={criticality}
          addNew={() =>
            addComplementaryEvaluation("KEY_SEAT", "KEY_SEAT_CRITICALITY")
          }
          openDeleteEvaluation={openDeleteEvaluation}
          toolAdministration={toolAdministration}
          updateAdministration={updateAdministration}
        />
        {getStyledErrorMessage(keyseatEvaluationsErrorSlugs(), errors)}
        <TableComplementaryEvaluations
          title="RISCO DE PERDER POSIÇÃO"
          evaluations={risk}
          addNew={() =>
            addComplementaryEvaluation("KEY_SEAT", "KEY_SEAT_LOSING_RISK")
          }
          openDeleteEvaluation={openDeleteEvaluation}
          toolAdministration={toolAdministration}
          updateAdministration={updateAdministration}
        />
        {getStyledErrorMessage(keyseatEvaluationsErrorSlugs(), errors)}
        <TableComplementaryEvaluations
          title="GRAVIDADE DE PERDER"
          evaluations={gravity}
          addNew={() =>
            addComplementaryEvaluation("KEY_SEAT", "KEY_SEAT_LOSING_GRAVITY")
          }
          openDeleteEvaluation={openDeleteEvaluation}
          toolAdministration={toolAdministration}
          updateAdministration={updateAdministration}
        />
        {getStyledErrorMessage(keyseatEvaluationsErrorSlugs(), errors)}
      </div>
      <div className="col-xs-12 col-lg-4">
        <TableComplementaryScale
          toolAdministration={toolAdministration}
          updateAdministration={updateAdministration}
          filterType="KEY_SEAT"
          handleAdd={() => addScale("KEY_SEAT")}
          openDeleteScale={openDeleteScale}
        />
        {getStyledErrorMessage(keySeatScaleErrorSlugs(), errors)}
      </div>
    </div>
  );
};

TableKeySeatEvaluation.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
  updateAdministration: PropTypes.func.isRequired,
  addComplementaryEvaluation: PropTypes.func.isRequired,
  addScale: PropTypes.func.isRequired,
  openDeleteScale: PropTypes.func.isRequired,
  openDeleteEvaluation: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
};

export default TableKeySeatEvaluation;
