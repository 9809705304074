import React, { Component } from "react";
import { injectIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";

import TableEvaluation from "../../../../Common/TableEvaluation";
import MaterialSingleSelect from "../../../../Common/MaterialSingleSelect";
import ActionsPlanOverview from "../../../../Common/ActionPlan/ActionsPlanOverview";
import ButtonGeneratePdf from "../../../../Common/ButtonGeneratePdf";
import ButtonExportExcel from "../../../../Common/ButtonExportExcel";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import MaturityMap from "./components/MaturityMap";

import Avatar from "../../../../Common/Avatar";
import {
  CardContainer,
  CardHeader,
  CardList,
  ListContainer,
  ListContent,
  AnswerText,
} from "../../../../Common/TemplateKanban/kanbanStyle";

import { getTableHeadValues } from "../../../../../constants/insightMaturity";

import {
  getSelectedToolStates,
  getBigProcessesStates,
  getActionsPlanStates,
  getCompanyMembersAndParticipants,
  getUserProfileStates,
} from "../../../../customMapStates";
import utils from "../../../../../utils/toolUtils";
import { getOnlySublevelArrays } from "../../../../../utils/ArrayUtils";
import { searchAllInsightActivities } from "../../../../../actions/entity-documents/rookau_tool_processes_activity";
import {
  formatFrequency,
  formatPeriodDemandGrowth,
  formatPeriodicity,
} from "../../../../../utils/rookauToolProcesses/activities/format";
import { exportProcessesReport } from "../../../../../utils/rookauToolProcesses/activities/excel";

const {
  getAverageEvaluation,
  getMaturityArrayEvaluation,
  getEvaluationLabel,
  buildMaturityBody,
  getInsightMaturityById,
  finalMaturityAverage,
} = utils;

const messages = defineMessages({
  maturityMap: { id: "tool.bigPictureProcesses.maturityMap" },
  processesAndCriteriaTables: {
    id: "tool.bigPictureProcesses.processesAndCriteriaTables",
  },
  activitiesInformation: {
    id: "tool.bigPictureProcesses.activitiesInformation",
  },
  selectMaturityCriterion: {
    id: "tool.bigPictureProcesses.selectMaturityCriterion",
  },
  activityDeliveryDeadline: {
    id: "tool.bigPictureProcesses.activityDeliveryDeadline",
  },
  automationLevel: { id: "tool.bigPictureProcesses.automationLevel" },
  knowledgeLevel: { id: "tool.bigPictureProcesses.knowledgeLevel" },
  levelOfManagementAndControl: {
    id: "tool.bigPictureProcesses.levelOfManagementAndControl",
  },
  qualityLevel: { id: "tool.bigPictureProcesses.qualityLevel" },
  maturityCriteria: { id: "tool.bigPictureProcesses.maturityCriteria" },
  nActivities: { id: "tool.bigPictureProcesses.nActivities" },
  nCriteria: { id: "tool.bigPictureProcesses.nCriteria" },
  processes: { id: "tool.bigPictureProcesses.processes" },
  headTotal: { id: "tool.bigPictureProcesses.table.total" },
  headActivities: { id: "tool.bigPictureProcesses.table.activities" },
  headAverage: { id: "tool.bigPictureProcesses.table.average" },
  activityInformation: {
    activityType: {
      id: "tool.bigPictureProcesses.questions.activityType",
    },
    peopleInvolved: {
      id: "tool.bigPictureProcesses.questions.peopleInvolved",
    },
    runtime: { id: "tool.bigPictureProcesses.questions.executionTime" },
  },
  globalActivities: { id: "global.activities" },
  acp_main_title: { id: "acp_main_title" },
  acp_bp_tool_table_title: { id: "acp_bp_tool_table_title" },
  acp_bp_tool_table_head_insight_label: {
    id: "acp_bp_tool_table_head_insight_label",
  },
});

class ProcessesBigPicture extends Component {
  state = {
    activeTab: "tables",
    maturityLabelsBody: {},
    selectedMaturityAttribute: "",
    maturityTableValues: [],
    name: [],
    newMaturityBody: [],
    insightDocuments: {},
    isLoadingActivities: false,
  };

  constructor(props) {
    super(props);
    const maturityLabels = buildMaturityBody();
    let count = 0;

    this.state.maturityLabelsBody = maturityLabels;
    Object.keys(maturityLabels).forEach((labelSlug) => {
      if (count === 0) {
        count++;
        this.state.selectedMaturityAttribute = labelSlug;
      }
    });

    this.state.maturityTableValues = getTableHeadValues(props.intl);
  }

  UNSAFE_componentWillReceiveProps({ intl }) {
    if (intl.locale !== this.props.intl.locale) {
      this.setState({ maturityTableValues: getTableHeadValues(intl) });
    }
  }

  getSingleSceneryInfo = (sceneryID) => {
    const { allSceneries } = this.props;
    let sceneryInfo = {};

    allSceneries.forEach((info) => {
      if (info.id === sceneryID) sceneryInfo = info;
    });

    return sceneryInfo;
  };

  getSingleThemeProcesses = (themeID) => {
    const { allQuestions } = this.props;
    let processes = [];
    if (allQuestions && allQuestions[themeID]) {
      allQuestions[themeID].forEach((questionBody) => {
        let haveInsights = false;
        const questionAnswers = this.getQuestionInsights(questionBody.id);
        if (questionAnswers.length > 0) {
          for (const index in questionAnswers) {
            if (questionAnswers[index].type === "insight") {
              haveInsights = true;
              break;
            }
          }
        }
        if (haveInsights) processes = [...processes, questionBody];
      });
    }

    return processes;
  };

  getFilteredThemesProcesses = () => {
    const { allQuestions, filterData } = this.props;
    let finalProcesses = [];
    if (filterData.themes) {
      Object.keys(allQuestions).forEach((themeID) => {
        themeID = parseInt(themeID, 10);
        const inArray = filterData.themes.indexOf(themeID) > -1;
        if (inArray) {
          const themeProcesses = this.getSingleThemeProcesses(themeID);
          finalProcesses = [...finalProcesses, ...themeProcesses];
        }
      });
    }

    return finalProcesses;
  };

  getFilteredQuestionsAndInsights = () => {
    const { allQuestions, filterData } = this.props;

    const filteredQuestions = [];
    const filteredInsights = [];

    if (allQuestions && filterData.themes) {
      Object.keys(allQuestions).forEach((themeId) => {
        const themeQuestions = allQuestions[themeId];

        if (
          filterData.themes.indexOf(Number(themeId)) > -1 &&
          Array.isArray(themeQuestions)
        ) {
          themeQuestions.forEach((questionInfo) => {
            const questionInsights = this.getQuestionInsights(questionInfo.id);

            if (filterData.questions?.includes(questionInfo.id)) {
              filteredQuestions.push(questionInfo);
              if (Array.isArray(questionInsights))
                questionInsights.forEach((insightInfo) =>
                  filteredInsights.push(insightInfo),
                );
            }
          });
        }
      });
    }

    return { filteredQuestions, filteredInsights };
  };

  getQuestionInsights = (questionID) => {
    const { allAnswers, filterData } = this.props;

    if (filterData.questions?.includes(questionID) && allAnswers[questionID]) {
      return allAnswers[questionID];
    }

    return [];
  };

  getAllQuestionsInsights = (questions) => {
    let insights = [];

    questions.forEach((qBody) => {
      const questionInsights = this.getQuestionInsights(qBody.id);

      insights = [
        ...insights,
        { questionID: qBody.id, insights: questionInsights },
      ];
    });

    return insights;
  };

  displayActivitiesView = () => {
    const { intl, allMaturityData } = this.props;
    const { maturityTableValues } = this.state;
    let tableOptions = [];
    const themeProcesses = this.getFilteredThemesProcesses();

    Object.keys(themeProcesses).forEach((index) => {
      const questionBody = themeProcesses[index];
      const questionID = questionBody.id;
      const questionInsights = this.getQuestionInsights(questionID);
      let tableBodyOptions = [];
      let totalCount = 0;
      let maturityArray = [];

      questionInsights.forEach(({ id }) => {
        const maturityEvaluation = getInsightMaturityById(id, allMaturityData);
        const questionAverage = getAverageEvaluation(maturityEvaluation);
        const averageLabel = getEvaluationLabel(questionAverage);
        maturityArray = [...maturityArray, maturityEvaluation];
        totalCount++;

        let actualLabelCount = 0;
        tableBodyOptions.forEach(({ slug, displayValue }) => {
          if (slug === averageLabel) actualLabelCount = displayValue;
        });

        tableBodyOptions = [
          ...tableBodyOptions,
          { slug: averageLabel, displayValue: actualLabelCount + 1 },
        ];
      });

      tableBodyOptions = [
        ...tableBodyOptions,
        { slug: "ACTIVITIES", displayValue: totalCount },
        {
          slug: "AVERAGE",
          displayValue: getMaturityArrayEvaluation(maturityArray),
        },
      ];

      tableOptions = [
        ...tableOptions,
        {
          id: questionBody.id,
          label: questionBody.text,
          value: tableBodyOptions,
        },
      ];
    });

    const additionalHeadValues = [
      {
        value: "ACTIVITIES",
        bottomLabel: intl.formatMessage(messages.headActivities),
      },
      {
        value: "AVERAGE",
        bottomLabel: intl.formatMessage(messages.headAverage),
      },
    ];

    return (
      <div className="row" style={{ marginBottom: "30px" }}>
        <div className="col-xs-12" style={{ padding: "0px" }}>
          <TableEvaluation
            title={intl.formatMessage(messages.processes)}
            values={[...maturityTableValues, ...additionalHeadValues]}
            options={tableOptions}
            bottomDescription={intl.formatMessage(messages.nActivities)}
            hideTopNumbers
            fullWidth
            readOnly
            headTitleWidth="290px"
          />
        </div>
      </div>
    );
  };

  displayAttributesView = () => {
    const { intl, allMaturityData } = this.props;
    const { maturityTableValues } = this.state;
    const themeProcesses = this.getFilteredThemesProcesses();
    const questionsInsights = this.getAllQuestionsInsights(themeProcesses);
    let tableOptions = [];
    let attributesCount = {};

    questionsInsights.forEach(({ insights }) => {
      insights.forEach(({ id }) => {
        const maturityEvaluation = getInsightMaturityById(id, allMaturityData);
        const maturityBody = buildMaturityBody(maturityEvaluation);

        Object.keys(maturityBody).forEach((label) => {
          const actualLabelCount = attributesCount[label]
            ? attributesCount[label]
            : [];

          attributesCount = {
            ...attributesCount,
            [label]: [...actualLabelCount, maturityBody[label]],
          };
        });
      });
    });

    Object.keys(attributesCount).forEach((lineAttribute) => {
      let slugCount = {};
      let tableBodyOptions = [];
      let totalCount = 0;
      let attributesMaturity = [];

      attributesCount[lineAttribute].forEach((tableHeadValue) => {
        if (tableHeadValue) {
          if (tableHeadValue !== "NOT_APPLIABLE") {
            attributesMaturity = [
              ...attributesMaturity,
              { [lineAttribute]: tableHeadValue },
            ];
          }

          const actualSlugCount = slugCount[tableHeadValue]
            ? slugCount[tableHeadValue]
            : 0;

          slugCount = {
            ...slugCount,
            [tableHeadValue]: actualSlugCount + 1,
          };
        }
      });

      Object.keys(slugCount).forEach((labelVal) => {
        let alreadySet = false;

        tableBodyOptions.forEach(({ slug }) => {
          if (labelVal === slug) alreadySet = true;
        });

        if (!alreadySet && labelVal) {
          totalCount += slugCount[labelVal];

          tableBodyOptions = [
            ...tableBodyOptions,
            { slug: labelVal, displayValue: slugCount[labelVal] },
          ];
        }
      });

      tableBodyOptions = [
        ...tableBodyOptions,
        { slug: "ACTIVITIES", displayValue: totalCount },
        {
          slug: "AVERAGE",
          displayValue: finalMaturityAverage(attributesMaturity, true),
        },
      ];

      tableOptions = [
        ...tableOptions,
        {
          id: lineAttribute,
          label: intl.formatMessage(messages[lineAttribute]),
          value: tableBodyOptions,
        },
      ];
    });

    const additionalHeadValues = [
      {
        value: "ACTIVITIES",
        bottomLabel: intl.formatMessage(messages.headActivities),
      },
      {
        value: "AVERAGE",
        bottomLabel: intl.formatMessage(messages.headAverage),
      },
    ];

    return (
      <div className="row" style={{ marginBottom: "30px" }}>
        <div className="col-xs-12" style={{ padding: "0px" }}>
          <TableEvaluation
            title={intl.formatMessage(messages.maturityCriteria)}
            values={[...maturityTableValues, ...additionalHeadValues]}
            options={tableOptions}
            bottomDescription={intl.formatMessage(messages.nCriteria)}
            hideTopNumbers
            fullWidth
            readOnly
            headTitleWidth="290px"
          />
        </div>
      </div>
    );
  };

  displayActivitiesByAttribute = () => {
    const { allMaturityData } = this.props;
    const { selectedMaturityAttribute, maturityTableValues } = this.state;

    const themeProcesses = this.getFilteredThemesProcesses();
    const allThemeInsights = this.getAllQuestionsInsights(themeProcesses);

    const newMaturityBody = {
      VERY_LOW: null,
      LOW: null,
      PARTIAL: null,
      HIGH: null,
      VERY_HIGH: null,
    };

    maturityTableValues.forEach(({ value, color, constrast, bottomLabel }) => {
      allThemeInsights.forEach(({ insights }) => {
        if (!newMaturityBody[value]) {
          newMaturityBody[value] = {
            color,
            constrast,
            title: bottomLabel,
            insights: [],
          };
        }

        insights.forEach((insight) => {
          const maturityEvaluation = getInsightMaturityById(
            insight.id,
            allMaturityData,
          );

          const maturityBody = buildMaturityBody(maturityEvaluation);

          Object.keys(maturityBody).forEach((attrSlug) => {
            if (attrSlug === selectedMaturityAttribute) {
              if (maturityBody[attrSlug] === value) {
                if (newMaturityBody[value]) {
                  newMaturityBody[value] = {
                    ...newMaturityBody[value],
                    insights: [...newMaturityBody[value].insights, insight],
                  };
                }
              }
            }
          });
        });
      });
    });

    delete newMaturityBody.NOT_APPLICABLE;

    return newMaturityBody;
  };

  displayMapView = () => {
    const { intl } = this.props;
    const {
      maturityLabelsBody,
      selectedMaturityAttribute,
      maturityTableValues,
    } = this.state;
    const themeProcesses = this.getFilteredThemesProcesses();
    const allThemeInsights = this.getAllQuestionsInsights(themeProcesses);

    const buildOptions = (list) => {
      let finalOptions = [];

      Object.keys(list).forEach((slug) => {
        finalOptions = [
          ...finalOptions,
          { label: intl.formatMessage(messages[slug]), value: slug },
        ];
      });

      return finalOptions;
    };

    const maturityCriteriaOptions = buildOptions(maturityLabelsBody);

    return (
      <div className="row">
        <div className="col-xs-4 hidden-print">
          <MaterialSingleSelect
            id="selectCriteria"
            label={intl.formatMessage(messages.selectMaturityCriterion)}
            value={selectedMaturityAttribute}
            options={maturityCriteriaOptions}
            onChange={(e) => this.handleMaturityAttributeSelect(e)}
          />
        </div>
        <div className="col-xs-12 flex-cards" style={{ padding: "0px" }} />
      </div>
    );
  };

  getInsightActivityInformation = (insightID) => {
    const { allActivityInformation } = this.props;
    let finalInformation = {};

    if (allActivityInformation && allActivityInformation.length > 0) {
      for (const index in allActivityInformation) {
        if (allActivityInformation[index].insightId === insightID) {
          finalInformation = allActivityInformation[index].activityInformation;
          break;
        }
      }
    }

    return finalInformation;
  };

  getActivityTranslation = (activityValue) => {
    const { intl } = this.props;
    let finalTraslation = "...";
    let finalMessageObj = {};

    switch (activityValue) {
      case "ONE":
        finalMessageObj = {
          id: "tool.bigPictureProcesses.questions.peopleInvolved.onePerson",
        };
        break;
      case "TWO":
        finalMessageObj = {
          id: "tool.bigPictureProcesses.questions.peopleInvolved.twoPeople",
        };
        break;
      case "MORE_THAN_TWO":
        finalMessageObj = {
          id: "tool.bigPictureProcesses.questions.peopleInvolved.morePeople",
        };
        break;
      case "UP_TO_ONE_HOUR":
        finalMessageObj = {
          id: "tool.bigPictureProcesses.questions.executionTime.upOneHour",
        };
        break;
      case "UP_TO_FOUR_HOURS":
        finalMessageObj = {
          id: "tool.bigPictureProcesses.questions.executionTime.upFourHour",
        };
        break;
      case "ONE_DAY":
        finalMessageObj = {
          id: "tool.bigPictureProcesses.questions.executionTime.oneDay",
        };
        break;
      case "MORE_THAN_ONE_DAY":
        finalMessageObj = {
          id: "tool.bigPictureProcesses.questions.executionTime.moreOneDay",
        };
        break;
      case "DAILY":
        finalMessageObj = {
          id: "tool.bigPictureProcesses.questions.activityType.daily",
        };
        break;
      case "WEEKLY":
        finalMessageObj = {
          id: "tool.bigPictureProcesses.questions.activityType.weekly",
        };
        break;
      case "FIFHLY":
        finalMessageObj = {
          id: "tool.bigPictureProcesses.questions.activityType.biweekly",
        };
        break;
      case "MONTHLY":
        finalMessageObj = {
          id: "tool.bigPictureProcesses.questions.activityType.monthly",
        };
        break;
      case "EVENTUAL":
        finalMessageObj = {
          id: "tool.bigPictureProcesses.questions.activityType.eventual",
        };
        break;
      default:
        finalMessageObj = {};
    }

    if (finalMessageObj.id) {
      finalTraslation = intl.formatMessage(finalMessageObj);
    }

    return finalTraslation;
  };

  displayActivitiesInformation = () => {
    const { allThemes, filterData, intl, selectAndOpenInsight } = this.props;

    const displayActivitiesTable = (questions) => {
      return (
        questions &&
        questions.map((questionInfo, index) => {
          const questionInsights = this.getQuestionInsights(questionInfo.id);

          let totalExtimatedTime = 0;
          let totalDemand = 0;

          const insightsWithDocuments = questionInsights.reduce(
            (acc, insight) => {
              const insightDocuments =
                this.state.insightDocuments?.length > 0
                  ? this.state.insightDocuments?.find(
                      ({ entity_id }) => entity_id === insight.id,
                    )
                  : [];

              if (insightDocuments?.result?.length > 0) {
                insightDocuments.result.forEach((document) => {
                  const { document_data: activity = {} } = document;

                  ["min", "med", "max"].forEach((key) => {
                    const time = activity?.extimatedTime?.[key];
                    if (time) {
                      const [hours, minutes] = time.split(":").map(Number);
                      totalExtimatedTime += hours * 60 + minutes;
                    }
                  });
                  if (
                    activity?.demand?.min &&
                    activity?.demand?.med &&
                    activity?.demand?.max
                  ) {
                    totalDemand +=
                      parseInt(activity?.demand?.min) +
                      parseInt(activity?.demand?.med) +
                      parseInt(activity?.demand?.max);
                  }

                  acc.push({ insight, document });
                });

                return acc;
              }

              return [...acc, { insight, document: {} }];
            },
            [],
          );

          const formatTime= (timeInMinutes) => {
            const hours = Math.floor(timeInMinutes / 60);
            const minutes = totalExtimatedTime % 60;
            const time = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
            return time
          }

          return (
            <div key={index}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 className="table-title">{questionInfo.text}</h5>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <h5 style={{ textAlign: "center" }}>
                      Tempo Estimado Total
                    </h5>
                    <p style={{ textAlign: "center" }}>{formatTime(totalExtimatedTime)}</p>
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <h5 style={{ textAlign: "center" }}>Demanda Total</h5>
                    <p style={{ textAlign: "center" }}>{totalDemand}</p>
                  </div>
                </div>
              </div>
              <table className="simple-table">
                <tbody>
                  <tr className="thead" style={{ fontSize: "10px" }}>
                    <td style={{ width: "200px", border: "1px solid #dedede" }}>
                      Processo
                    </td>
                    <td style={{ border: "1px solid #dedede" }}>Atividade</td>
                    <td
                      style={{
                        width: "100px",
                        border: "1px solid #dedede",
                        width: "200px",
                      }}
                    >
                      Responsável
                    </td>
                    <td style={{ border: "1px solid #dedede", width: "50px" }}>
                      Relevância
                    </td>
                    <td style={{ border: "1px solid #dedede", width: "50px" }}>
                      Complexidade
                    </td>
                    <td style={{ border: "1px solid #dedede", width: "50px" }}>
                      Frequência
                    </td>
                    <td style={{ border: "1px solid #dedede", width: "50px" }}>
                      Periodicidade
                    </td>
                    <td style={{ border: "1px solid #dedede", width: "175px" }}>
                      Tempo
                    </td>
                    <td style={{ border: "1px solid #dedede", width: "175px" }}>
                      Demanda
                    </td>
                    <td style={{ border: "1px solid #dedede", width: "50px" }}>
                      Crescimento
                    </td>
                  </tr>
                  {insightsWithDocuments.length > 0 &&
                    insightsWithDocuments.map(({ insight, document }, index) => {
                      const { id, text, owner, responsibleMember } = insight;
                      const { document_data: activity = {} } = document;

                      const responsible =
                        this.props.companyMembersWithProfile.find(
                          (member) => member.id === activity?.responsibleId,
                        );

                      const { checklist = [] } = activity;
                      const completedChecklist = checklist.filter(
                        ({ completed }) => completed === true,
                      );

                      const timeKeys = ["min", "med", "max"];
                      let totalExtimatedTime = 0;

                      timeKeys.forEach((key) => {
                        const time = activity?.extimatedTime?.[key];
                        if (time) {
                          const [hours, minutes] = time.split(":").map(Number);
                          totalExtimatedTime += hours * 60 + minutes;
                        }
                      });

                      const hours = Math.floor(totalExtimatedTime / 60);
                      const minutes = totalExtimatedTime % 60;

                      const formattedTime = totalExtimatedTime
                        ? `${hours.toString().padStart(2, "0")}h ${minutes
                            .toString()
                            .padStart(2, "0")}m`
                        : " - ";

                      const totalDemand =
                        parseInt(activity?.demand?.min) +
                        parseInt(activity?.demand?.med) +
                        parseInt(activity?.demand?.max);

                      const checklistIndicator = `(${checklist.length}/${completedChecklist.length})`;

                      return (
                        <tr
                          key={index}
                          className="block-hover"
                          onClick={() => selectAndOpenInsight(id)}
                          style={{ cursor: "pointer" }}
                        >
                          <td>
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span>{text}</span>
                            </div>
                          </td>
                          {!document?.id && !this.state.isLoadingActivities && (
                            <td
                              colSpan="100"
                              className="text-center"
                              onClick={() => selectAndOpenInsight(id)}
                              style={{ cursor: "pointer" }}
                            >
                              Nenhuma atividade
                            </td>
                          )}
                          {document?.id && (
                            <>
                              <td style={{ fontWeight: "bold" }}>
                                {activity.title}
                              </td>
                              <td>
                                {" "}
                                <div>
                                  <div style={{ fontWeight: "bold" }}>
                                    {responsible?.name}
                                  </div>
                                  <div style={{ fontSize: "10px" }}>
                                    {
                                      responsible?.profile?.companyPosition
                                        ?.title
                                    }
                                  </div>
                                </div>
                              </td>
                              <td className="text-right">
                                {activity.relevance}
                              </td>
                              <td className="text-right">
                                {activity.complexity}
                              </td>
                              <td className="text-right">
                                {formatFrequency(activity?.frequency)}
                              </td>
                              <td className="text-right">
                                {formatPeriodicity(activity?.periodicity)}
                              </td>
                              <td
                                style={{ padding: 0, border: "none" }}
                                width="100"
                              >
                                <table
                                  className="simple-table"
                                  style={{ margin: 0, height: "90px" }}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        className="text-center"
                                        style={{ padding: 5, fontSize: "10px" }}
                                      >
                                        Min.
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ padding: 5, fontSize: "10px" }}
                                      >
                                        Med.
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ padding: 5, fontSize: "10px" }}
                                      >
                                        Max.
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{
                                          padding: 5,
                                          fontSize: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Total.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-right">
                                        {activity.extimatedTime?.min || "-"}
                                      </td>
                                      <td className="text-right">
                                        {activity.extimatedTime?.med || "-"}
                                      </td>
                                      <td className="text-right">
                                        {activity.extimatedTime?.max || "-"}
                                      </td>
                                      <td className="text-right">
                                        {formattedTime || "-"}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td
                                style={{ padding: 0, border: "none" }}
                                width="100"
                              >
                                <table
                                  className="simple-table"
                                  style={{ margin: 0, height: "90px" }}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        className="text-center"
                                        style={{ padding: 5, fontSize: "10px" }}
                                      >
                                        Min.
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ padding: 5, fontSize: "10px" }}
                                      >
                                        Med.
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ padding: 5, fontSize: "10px" }}
                                      >
                                        Max.
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{
                                          padding: 5,
                                          fontSize: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Total.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-right">
                                        {activity.demand?.min || "-"}
                                      </td>
                                      <td className="text-right">
                                        {activity.demand?.med || "-"}
                                      </td>
                                      <td className="text-right">
                                        {activity.demand?.max || "-"}
                                      </td>
                                      <td className="text-right">
                                        {totalDemand || "-"}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="text-right">
                                {formatPeriodDemandGrowth(
                                  activity?.periodDemandGrowth,
                                )}
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          );
        })
      );
    };

    return (
      <div className="row">
        {Object.keys(allThemes).map((sceneryID, index) => {
          sceneryID = parseInt(sceneryID, 10);
          if (
            filterData.sceneries &&
            filterData.sceneries.indexOf(sceneryID) > -1
          ) {
            const sceneryInfo = this.getSingleSceneryInfo(sceneryID);
            const sceneryThemes = allThemes[sceneryID];
            return (
              <div className="col-xs-12" key={index}>
                <div className="card-container">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<i className="fas fa-chevron-down" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h3 className="card-title">{sceneryInfo.name}</h3>
                    </AccordionSummary>
                    <div className="card-body">
                      {sceneryThemes &&
                        sceneryThemes.map((themeInfo, index) => {
                          if (
                            filterData &&
                            filterData.themes &&
                            filterData.themes.indexOf(themeInfo.id) > -1
                          ) {
                            const themeProcesses = this.getSingleThemeProcesses(
                              themeInfo.id,
                            );

                            return (
                              <div key={index} className="card-row">
                                <h4 className="row-title">{themeInfo.name}</h4>
                                <AccordionDetails>
                                  <div className="row-table">
                                    {displayActivitiesTable(themeProcesses)}
                                  </div>
                                </AccordionDetails>
                              </div>
                            );
                          }
                          return null;
                        })}
                    </div>
                  </Accordion>
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  };

  handleTabChange = async (key) => {
    this.setState({ activeTab: key });

    if (key === "activitiesInfo") {
      const { allAnswers } = this.props;
      const insight_ids = getOnlySublevelArrays(allAnswers).map(({ id }) => id);

      this.setState({ isLoadingActivities: true });

      const { data: all } = await searchAllInsightActivities({ insight_ids });

      this.setState({
        insightDocuments: all,
        isLoadingActivities: false,
      });
      this.setState({ isLoadingActivities: false });
    }
  };

  handleMaturityAttributeSelect = (event) => {
    this.setState({
      selectedMaturityAttribute: event.target.value,
    });
  };

  handleExcelExport = async () => {
    this.setState({ isLoadingActivities: true });

    const { filteredInsights = [] } = this.getFilteredQuestionsAndInsights();

    const data = await exportProcessesReport({
      list: filteredInsights.map((insight) => {
        return {
          ...insight,
          cenaryName: insight.question.theme.cenary.name,
          themeName: insight.question.theme.name,
          questionName: insight.question.text,
        };
      }),
      companyMembersWithProfile: this.props.companyMembersWithProfile,
    });

    this.setState({ isLoadingActivities: false });
  };

  render() {
    const {
      intl,
      selectedTool = {},
      allActionsPlans,
      selectAndOpenInsight,
    } = this.props;
    const { activeTab } = this.state;

    const { filteredInsights = [] } = this.getFilteredQuestionsAndInsights();

    const tablesTabTitle = (
      <div>
        <i className="fas fa-table" />
        &nbsp;&nbsp;
        {intl.formatMessage(messages.processesAndCriteriaTables)}
      </div>
    );

    const mapTabTitle = (
      <div>
        <i className="fas fa-sitemap" />
        &nbsp;&nbsp;
        {intl.formatMessage(messages.maturityMap)}
      </div>
    );

    const activitiesInformationTitle = (
      <div>
        <i className="fas fa-clipboard-list" />
        &nbsp;&nbsp;
        {intl.formatMessage(messages.activitiesInformation)}
      </div>
    );

    const actionsPlanOverviewTitle = (
      <div>
        <i className="fas fa-list-ol" />
        &nbsp;&nbsp;
        {intl.formatMessage(messages.acp_main_title)}
      </div>
    );

    const maturityBodyObject = this.displayActivitiesByAttribute();

    return (
      <div className="custom-primary-tabs" style={{ position: "relative" }}>
        <div style={{ position: "relative", top: "30px" }}>
          <ButtonGeneratePdf />
          <ButtonExportExcel
            doExport={this.handleExcelExport}
            disabled={this.isLoadingActivities}
          />
        </div>
        <Tabs
          id="processManagement"
          activeKey={activeTab}
          onSelect={(key) => this.handleTabChange(key)}
          style={{ marginTop: "15px" }}
        >
          <Tab eventKey="tables" title={tablesTabTitle}>
            <div className="name-tab-pdf">
              <i className="fas fa-table" />
              &nbsp;&nbsp;
              {intl.formatMessage(messages.processesAndCriteriaTables)}
            </div>
            <div className="tab-content" style={{ marginTop: "10px" }}>
              {this.displayActivitiesView()}
              {this.displayAttributesView()}
            </div>
          </Tab>
          <Tab eventKey="map" title={mapTabTitle}>
            <div className="name-tab-pdf">
              <i className="fas fa-sitemap" />
              &nbsp;&nbsp;
              {intl.formatMessage(messages.maturityMap)}
            </div>
            {this.displayMapView()}
            <div
              className="tab-content"
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              {Object.keys(maturityBodyObject).map((status) => {
                const maturity = maturityBodyObject[status];

                return (
                  <>
                    {maturity && (
                      <MaturityMap
                        color={maturity.color}
                        title={maturity.title}
                        selectAndOpenInsight={selectAndOpenInsight}
                        insights={maturity.insights}
                      />
                    )}
                  </>
                );
              })}
            </div>
          </Tab>
          <Tab eventKey="activitiesInfo" title={activitiesInformationTitle}>
            <div className="name-tab-pdf">
              <i className="fas fa-clipboard-list" />
              &nbsp;&nbsp;
              {intl.formatMessage(messages.activitiesInformation)}
            </div>
            <div className="tab-content" style={{ marginTop: "10px" }}>
              {this.displayActivitiesInformation()}
            </div>
          </Tab>
          <Tab eventKey="actionsPlanOverview" title={actionsPlanOverviewTitle}>
            <div className="name-tab-pdf">
              <i className="fas fa-list-ol" />
              &nbsp;&nbsp;
              {intl.formatMessage(messages.acp_main_title)}
            </div>
            <div className="tab-content" style={{ marginTop: "10px" }}>
              <ActionsPlanOverview
                allActionsPlans={allActionsPlans}
                filteredInsights={filteredInsights}
                overviewTableTitle={intl
                  .formatMessage(messages.acp_main_title)
                  .toUpperCase()}
                overviewTableInsightsLabel={intl
                  .formatMessage(messages.processes)
                  .toUpperCase()}
                insightsTableTitle={intl
                  .formatMessage(messages.acp_bp_tool_table_title)
                  .toUpperCase()}
                insightsTableInsightsLabel={intl
                  .formatMessage(messages.acp_bp_tool_table_head_insight_label)
                  .toUpperCase()}
                selectedTool={selectedTool}
                selectAndOpenInsight={selectAndOpenInsight}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { toolsConfigurations } = state;

  const showHiddenThemes = toolsConfigurations
    ? toolsConfigurations.showHiddenThemes
    : false;

  const showHiddenQuestions = toolsConfigurations
    ? toolsConfigurations.showHiddenQuestions
    : false;

  const showHiddenAnswers = toolsConfigurations
    ? toolsConfigurations.showHiddenAnswers
    : false;

  const showHiddenCenarys = toolsConfigurations
    ? toolsConfigurations.showHiddenCenarys
    : false;

  const { selectedTool = {} } = getSelectedToolStates(state, {
    sceneries: showHiddenCenarys,
    themes: showHiddenThemes,
    questions: showHiddenQuestions,
    answers: showHiddenAnswers,
  });

  const { allMaturityData, allActivityInformation } =
    getBigProcessesStates(state);

  const { allActionsPlans = [] } = getActionsPlanStates(state);
  const { companyMembers } = getCompanyMembersAndParticipants(state);

  const { allCompanyHierarchy = {} } = getUserProfileStates(state);

  const companyMembersWithProfile = companyMembers.map((companyMember) => {
    const { profile = {} } =
      allCompanyHierarchy?.find(
        ({ member }) => companyMember.id === member?.id,
      ) || {};

    return {
      ...companyMember,
      profile,
    };
  });

  return {
    selectedTool,
    allActivityInformation,
    allMaturityData,
    allActionsPlans,
    companyMembersWithProfile,
  };
};

export default injectIntl(connect(mapStateToProps)(ProcessesBigPicture));
