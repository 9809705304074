import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import _ from "lodash";
import { List, ListItem, ListItemText } from "@material-ui/core";
import FormDialog from "./FormDialog";
import MaterialTextField from "./MaterialTextField";
import utils from "../../utils/toolUtils";
import { globalMessages } from "../../utils/global";

const { getComparableQuery } = utils;

const messages = defineMessages({
  gb_options_rt_shared: { id: "gb_options_rt_shared" },
  gb_options_rt_individual: { id: "gb_options_rt_individual" },
  gb_options_rt_corporate: { id: "gb_options_rt_corporate" },
  global_search: { id: "global.search" },
  global_noData: { id: "global.noData" },
  global_selectedOnes: { id: "global.selectedOnes" },
});

const SelectItemModal = (props) => {
  const [displayItems, setDisplayItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filter, setFilter] = useState({
    string: "",
  });
  const [initialPreset, setInitialPreset] = useState(false);

  const {
    open,
    title,
    description,
    itemsList,
    toggleOpen,
    onConfirm,
    onCancel,
    singleSelect,
    initialSelected,
    replaceSelected,
    replaceCallback,
    blockList,
    intl,
  } = props;

  const confirmText =
    !props.confirmText || props.confirmText === "defaultSelect"
      ? intl.formatMessage(globalMessages.select)
      : props.confirmText;
  const cancelText =
    !props.cancelText || props.cancelText === "defaultCancel"
      ? intl.formatMessage(globalMessages.cancel)
      : props.cancelText;

  useEffect(() => {
    if (!open) {
      setSelectedItems([]);
    } else {
      setInitialPreset(false);
    }
  }, [open]);

  useEffect(() => {
    if (!_.isEqual(itemsList, displayItems)) setDisplayItems(itemsList);
  }, [itemsList, displayItems]);

  useEffect(() => {
    if (
      initialSelected &&
      initialSelected.length > 0 &&
      (!initialPreset || replaceSelected)
    ) {
      setSelectedItems(initialSelected);
      setInitialPreset(true);

      if (typeof replaceCallback === "function") replaceCallback();
    }
  }, [initialPreset, initialSelected, replaceSelected, replaceCallback]);

  const handleTypeSearch = (e) => {
    setFilter({ ...filter, string: e.target.value });
  };

  const getFilteredBySearch = (items) => {
    let filtered = [];

    const compareString = getComparableQuery(filter.string || "");
    const match = (text) => getComparableQuery(text || "").match(compareString);

    items.forEach((itemInfo) => {
      const titleFound = !!match(itemInfo.title);
      const descriptionFound = !!match(itemInfo.description);

      if (itemInfo.show && !titleFound && !descriptionFound)
        itemInfo.show = false;

      filtered = [...filtered, itemInfo];
    });

    return filtered;
  };

  const getFilteredDisplay = () => {
    const toFilter = [];

    displayItems.forEach((itemInfo) => {
      toFilter.push({ ...itemInfo, show: true });
    });

    return getFilteredBySearch(toFilter);
  };

  const handleToggleSelect = (goalID) => {
    const cloned = [...selectedItems];
    let filtered = selectedItems;

    if (isSelected(goalID)) {
      cloned.splice(cloned.indexOf(goalID), 1);
      filtered = cloned;
    } else {
      if (singleSelect) filtered = [goalID];
      if (!singleSelect) filtered = [...selectedItems, goalID];
    }

    setSelectedItems(filtered);
  };

  const getTotalDisplayed = (list) => {
    let total = 0;

    if (list && list.length > 0) {
      list.forEach((itemInfo) => (total = itemInfo.show ? total + 1 : total));
    }

    return total;
  };

  const getSelecteditemsList = (items) => {
    const selected = [];

    if (items && selectedItems.length > 0) {
      items.forEach((itemInfo) => {
        if (selectedItems.indexOf(itemInfo.id) > -1) {
          selected.push(itemInfo);
        }
      });
    }

    return selected;
  };

  const isSelected = (goalID) => {
    return selectedItems.indexOf(goalID) > -1;
  };

  const displayTopFilters = (show = true) => {
    return show ? (
      <div className="row" style={{ borderBottom: "1px solid #ccc" }}>
        <div className="col-xs-12">
          <MaterialTextField
            id="itemselect"
            label={intl.formatMessage(messages.global_search)}
            value={filter.string}
            onChange={(e) => handleTypeSearch(e)}
          />
        </div>
      </div>
    ) : null;
  };

  const handleDisplay = (list) => {
    const reorderedList = [...list].sort((a, b) =>
      a.title > b.title ? 1 : -1,
    );

    const oveflowTextStyles = {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "480px",
    };

    return getTotalDisplayed(reorderedList) > 0 ? (
      <List>
        {reorderedList.map((itemInfo, index) => {
          const borderColor = isSelected(itemInfo.id) ? "#333" : "transparent";

          const displayStyle = itemInfo.show
            ? {
                opacity: "1",
                height: "50px",
                padding: "11px 16px",
              }
            : {
                opacity: "0",
                height: "0px",
                padding: "0px 16px",
                pointerEvents: "none",
              };

          const blockStyles =
            blockList.indexOf(itemInfo.id) > -1
              ? { opacity: "0.4", pointerEvents: "none" }
              : {};

          return (
            <ListItem
              key={index}
              className="list-item-selection"
              style={{
                borderLeftColor: borderColor,
                ...displayStyle,
                ...blockStyles,
              }}
              onClick={() =>
                itemInfo.show ? handleToggleSelect(itemInfo.id) : null
              }
            >
              <ListItemText
                primary={
                  <div title={itemInfo.title} style={oveflowTextStyles}>
                    {itemInfo.title}
                  </div>
                }
                secondary={itemInfo.secondaryText}
                primaryTypographyProps={{
                  style: {
                    fontSize: "16px",
                  },
                }}
                secondaryTypographyProps={{
                  style: {
                    fontSize: "14px",
                  },
                }}
              />
            </ListItem>
          );
        })}
      </List>
    ) : (
      <h5 align="center" style={{ color: "#666" }}>
        {intl.formatMessage(messages.global_noData)}
      </h5>
    );
  };

  const filteredList = getFilteredDisplay();
  return (
    <>
      <FormDialog
        open={open}
        title={title}
        description={description}
        toggleOpen={toggleOpen}
        onConfirm={() =>
          onConfirm(selectedItems, getSelecteditemsList(itemsList))
        }
        onCancel={onCancel || toggleOpen}
        confirmText={confirmText}
        cancelText={cancelText}
        blockConfirm={selectedItems.length === 0}
        bodyStyle={{
          padding: "0px",
        }}
      >
        <div className="row">
          {displayTopFilters()}
          <div className="row" style={{ maxHeight: "335px", overflow: "auto" }}>
            {handleDisplay(filteredList)}
          </div>
          <div className="row" style={{ clear: "both" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "15px",
                paddingBottom: "15px",
                paddingLeft: "25px",
                borderTop: "1px solid #ccc",
              }}
              className="col-xs-12"
            >
              <div className="col" align="center">
                {selectedItems.length > 0 && !singleSelect && (
                  <h5
                    align="center"
                    style={{
                      color: "#666",
                      margin: "5px 0 0 0",
                    }}
                  >
                    {selectedItems.length}{" "}
                    {intl.formatMessage(messages.global_selectedOnes)}
                  </h5>
                )}
              </div>
            </div>
          </div>
        </div>
      </FormDialog>
    </>
  );
};

SelectItemModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      secondaryText: PropTypes.string,
    }),
  ),
  toggleOpen: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  singleSelect: PropTypes.bool,
  initialSelected: PropTypes.arrayOf(PropTypes.number),
  replaceSelected: PropTypes.bool,
  replaceCallback: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  blockList: PropTypes.array,
};

SelectItemModal.defaultProps = {
  open: false,
  singleSelect: false,
  replaceSelected: false,
  itemsList: [],
  initialSelected: [],
  blockList: [],
  confirmText: "defaultSelect",
  cancelText: "defaultCancel",
};

export default injectIntl(SelectItemModal);
