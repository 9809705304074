import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { defineMessages, injectIntl } from "react-intl";

import Avatar from "../../../../../../Common/Avatar";
import MaterialSingleSelect from "../../../../../../Common/MaterialSingleSelect";
import SimplePercentageLine from "../../../../../../Common/SimplePercentageLine";
import Registration from "../../../../../../Common/ActionPlan/Registration";
import { simpleSaveAsExcel } from "../../../../../../Common/Export/ExportCSV";

import utils from "../../../../../../../utils/toolUtils";
import { ruleOfThree } from "../../../../../../../utils/MathUtils";
import actionsPlanUtils from "../../../../../../../utils/actionsPlan";
import {
  getEvaluationStatusOptions,
  getActionStatusOptions,
} from "../../../../../../../constants/actionsPlan";

import {
  Container,
  BoxResponsibleInfo,
  Table,
  TdStyled,
  TdTracebility,
  TdTool,
  Icon,
  BoxSelect,
} from "../styles";
import {
  customCenaryName,
  translateQuestionName,
} from "../../../../../../../utils/cpAnalysis/display";
import { getToolCustomLabels } from "../../../../../../../utils/actionsPlan/display";
import ButtonExportExcel from "../../../../../../Common/ButtonExportExcel";

const { getObjectInfoById } = utils;
const { getActionsByStatus, getActionPerformance } = actionsPlanUtils;

const defaultDialogOptions = {
  open: false,
  actionPlan: {},
  action: {},
};

const messages = defineMessages({
  tool: {
    id: "global.tool",
  },
  actionPlan: {
    id: "acp_main_title",
  },
  responsible: {
    id: "global.responsible",
  },
  traceability: {
    id: "acp_table_text_traceability",
  },
  startDate: {
    id: "acp_table_text_start",
  },
  endDate: {
    id: "acp_table_text_end",
  },
  conclusion: {
    id: "acp_table_text_conclusion",
  },
  newDate: {
    id: "acp_table_text_new_date",
  },
  status: {
    id: "global.status",
  },
  timeLimit: {
    id: "acp_table_text_time_limit",
  },
  out: {
    id: "acp_options_long_out",
  },
  within: {
    id: "acp_options_long_within",
  },
  canceled: {
    id: "acp_options_canceled",
  },
});

const ToolView = ({
  actionPlans,
  updateOnlyAction,
  companyMembersAndParticipants,
  refetchData,
  intl,
}) => {
  const [dialogOptions, setDialogOptions] = useState(defaultDialogOptions);
  const [orderedActionPlans, setOrderedActionPlans] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [selectedId, setSelectedId] = useState(true);
  const [filteredActions, setFilteredActions] = useState("ALL");
  const [hiddenBoxes, setHiddenBoxes] = useState(() => {
    const getHiddenBoxes = JSON.parse(localStorage.getItem("@hidden_boxes"));

    if (getHiddenBoxes) {
      return getHiddenBoxes;
    }

    return [];
  });

  const transformActions = useCallback((actions) => {
    return actions.map((action) => ({
      ...action,
      avatarBlobId: action.responsible?.avatarBlobId,
      responsibleName: action.responsible?.name,
      insightId: action.insight?.id,
      insightName: action.insight?.name,
      endDate: action.dates?.end,
      actionName: action.action,
      dateId: action.dates?.id,
      startDate: action.dates?.start,
      conclusion: action.dates.conclusion,
      rescheduledDate: action.dates?.rescheduled,
    }));
  }, []);

  useEffect(() => {
    const newActionPlans = actionPlans.map((actionPlan) => ({
      ...actionPlan,
      name: actionPlan.description,
      icon: actionPlan.icon,
      allActions: actionPlan.actions,
      actions: transformActions(actionPlan.actions),
    }));

    const ord1 = order === "asc" ? 1 : -1;
    const ord2 = order === "asc" ? -1 : 1;

    const orderedActionsPlans = newActionPlans.map((actionPlan) => {
      return {
        ...actionPlan,
        actions: actionPlan.actions.sort((a, b) => {
          if (orderBy && actionPlan.id === selectedId) {
            return a[orderBy] > b[orderBy] ? ord1 : ord2;
          }

          return 0;
        }),
      };
    });

    const filteredActionPlans = orderedActionsPlans.map((actionPlan) => {
      if (filteredActions && filteredActions !== "ALL") {
        return {
          ...actionPlan,
          actions: actionPlan.actions.filter(
            (action) => getActionPerformance(action).status === filteredActions,
          ),
        };
      }
      return { ...actionPlan };
    });

    setOrderedActionPlans(filteredActionPlans);
  }, [
    actionPlans,
    orderBy,
    order,
    selectedId,
    filteredActions,
    transformActions,
  ]);

  const transformDate = useCallback((date) => {
    return date ? moment(date).format("DD/MM/YYYY") : "-";
  }, []);

  const handleChangeFiltered = useCallback((event) => {
    const { value } = event.target;

    setFilteredActions(value);
  }, []);

  const handleChangeOrderBy = useCallback(
    (name, id) => {
      const isAsc = orderBy === name && order === "asc";

      setOrder(isAsc ? "desc" : "asc");
      setSelectedId(id);
      setOrderBy(name);
    },
    [order, orderBy],
  );

  const handleHiddenBox = (index) => {
    if (!hiddenBoxes.includes(index)) {
      setHiddenBoxes((state) => [...state, index]);
    } else if (hiddenBoxes.includes(index)) {
      setHiddenBoxes(hiddenBoxes.filter((hiddenBox) => hiddenBox !== index));
    }
  };

  const openEditDialog = (action, actionPlan, insightId, toolTypeId) => {
    setDialogOptions({
      open: !dialogOptions.open,
      action,
      actionPlan,
      insightId,
      toolCustomLabels: getToolCustomLabels(toolTypeId),
    });
  };

  const updateActions = async (actions, files, actionId, insightId) => {
    if (insightId) {
      const [updatedAction] = actions.filter(({ id }) => actionId === id);

      if (updatedAction?.id)
        await updateOnlyAction(
          "WORKSPACE_TOOLS",
          insightId,
          updatedAction,
          files,
        );
    }

    setDialogOptions(defaultDialogOptions);
    refetchData();
  };

  const evaluationStatusOptions = getEvaluationStatusOptions();
  const actionStatusOptions = getActionStatusOptions();

  const formatLimitStatus = (timeLimitStatus) => {
    switch (timeLimitStatus) {
      case "OUT":
        return intl.formatMessage(messages.out);
      case "WITHIN_DEADLINE":
        return intl.formatMessage(messages.within);
      case "CANCELED":
        return intl.formatMessage(messages.canceled);
      default:
        return "";
    }
  };

  const handleExport = () => {
    const headers = [
      {
        header: intl.formatMessage(messages.tool),
        key: "tool",
        width: 30,
      },
      {
        header: "Insight",
        key: "insight",
        width: 40,
      },
      {
        header: intl.formatMessage(messages.timeLimit),
        key: "timeLimit",
        width: 30,
      },
      {
        header: intl.formatMessage(messages.actionPlan),
        key: "actionPlan",
        width: 60,
      },
      {
        header: intl.formatMessage(messages.responsible),
        key: "responsible",
        width: 30,
      },
      {
        header: "Email",
        key: "email",
        width: 40,
      },
      {
        header: "CPF",
        key: "cpf",
        width: 30,
      },
      {
        header: "ID",
        key: "id",
        width: 15,
      },
      {
        header: intl.formatMessage(messages.traceability),
        key: "traceability",
        width: 60,
      },
      {
        header: intl.formatMessage(messages.startDate),
        key: "startDate",
        width: 15,
      },
      {
        header: intl.formatMessage(messages.endDate),
        key: "endDate",
        width: 15,
      },
      {
        header: intl.formatMessage(messages.conclusion),
        key: "conclusion",
        width: 15,
      },
      {
        header: intl.formatMessage(messages.newDate),
        key: "newDate",
        width: 15,
      },
      {
        header: intl.formatMessage(messages.status),
        key: "status",
        width: 15,
      },
    ];

    const data = () => {
      const actions = orderedActionPlans.map((tool) => {
        return tool.actions.map((insight) => {
          const { traceability } = insight || {};
          const { cenary = {}, question = {} } = traceability || {};
          const { name: scenerieName = "" } = cenary || {};
          const { name: questionName = "" } = question || {};

          const translatedScenerieName = customCenaryName(
            scenerieName,
            tool.id,
          );

          const translatedQuestionName = translateQuestionName(
            questionName,
            tool.id,
          );

          const status = getObjectInfoById(
            insight.status,
            actionStatusOptions,
            "value",
          ).label;

          const startDate = transformDate(insight.startDate);
          const endDate = transformDate(insight.endDate);
          const newDate = transformDate(insight.rescheduledDate);
          const conclusion = transformDate(insight.conclusion);
          const timeLimit = getActionPerformance(insight);
          const timeLimitStatus = formatLimitStatus(timeLimit.status);

          return {
            tool: tool.name,
            insight: insight.insightName,
            timeLimit: timeLimitStatus,
            actionPlan: insight.action,
            responsible: insight.responsible.name,
            email: insight.responsible.email,
            cpf: insight.responsible.cpf,
            id: insight.responsible.id,
            traceability: `${translatedScenerieName} > ${translatedQuestionName} > ${insight.traceability.theme?.name}`,
            startDate,
            endDate,
            conclusion,
            newDate,
            status,
          };
        });
      });
      const data = actions.reduce((acc, item) => {
        return acc.concat(item);
      }, []);
      return data;
    };

    const fakeHeader = {
      tool: intl.formatMessage(messages.tool),
      insight: "Insight",
      timeLimit: intl.formatMessage(messages.timeLimit),
      actionPlan: intl.formatMessage(messages.actionPlan),
      responsible: intl.formatMessage(messages.responsible),
      email: "Email",
      cpf: "CPF",
      id: "ID",
      traceability: intl.formatMessage(messages.traceability),
      startDate: intl.formatMessage(messages.startDate),
      endDate: intl.formatMessage(messages.endDate),
      conclusion: intl.formatMessage(messages.conclusion),
      newDate: intl.formatMessage(messages.newDate),
      status: intl.formatMessage(messages.status),
    };
    const timeStampString = moment().toJSON();

    simpleSaveAsExcel(
      data(),
      headers,
      fakeHeader,
      `action-plan-${timeStampString}`,
      [{ line: 2, fill: "D3D3D3" }],
    );
  };

  return (
    <>
      <BoxSelect>
        <MaterialSingleSelect
          id="filterActions"
          label="Selecione o prazo"
          value={filteredActions}
          defaultOption={false}
          options={[
            { label: "Todos", value: "ALL" },
            { label: "Dentro", value: "WITHIN_DEADLINE" },
            { label: "Fora", value: "OUT" },
          ]}
          onChange={handleChangeFiltered}
        />
      </BoxSelect>

      <div
        style={{
          position: "relative",
          marginTop: "70px",
          bottom: "20px",
        }}
      >
        <ButtonExportExcel doExport={handleExport} styleRight="0px" />
      </div>
      <Registration
        open={dialogOptions.open}
        companyMembersAndParticipants={companyMembersAndParticipants}
        cancelRegistration={openEditDialog}
        actions={dialogOptions.actionPlan?.allActions}
        insightId={dialogOptions.insightId}
        toolCustomLabels={dialogOptions.toolCustomLabels}
        updateActions={updateActions}
        editingForm={{
          isEditing: true,
          params: { actionId: dialogOptions.action?.id },
        }}
      />
      {orderedActionPlans.map((actionPlan, index) => {
        const { icon, name, actions, id: toolTypeId } = actionPlan;

        const { COMPLETE = [] } = getActionsByStatus(actions);

        const calcPercentageActions = ruleOfThree(
          actions.length,
          COMPLETE.length,
        );

        return (
          <Container key={toolTypeId}>
            <BoxResponsibleInfo>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h3 style={{ margin: 0 }}>
                    <i
                      className={`fas ${icon}`}
                      style={{ marginRight: "5px" }}
                    />
                    {name}
                  </h3>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "250px" }}>
                    <SimplePercentageLine
                      percentage={calcPercentageActions}
                      backgroundColor="#797789"
                      overrideColor="#6a68d5"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "11px",
                        fontWeight: 500,
                        color: "#888",
                      }}
                    >
                      N° Insights
                    </span>
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: "16px",
                        marginLeft: "5px",
                      }}
                    >
                      {` ${actions.length}`}
                    </span>
                  </div>
                </div>
              </div>
              <i
                className="fas fa-angle-up"
                onClick={() => handleHiddenBox(index)}
                style={
                  hiddenBoxes.includes(index)
                    ? {
                        transform: "rotate(180deg)",
                      }
                    : {}
                }
              />
            </BoxResponsibleInfo>
            {!hiddenBoxes.includes(index) && (
              <Table className="simple-table">
                <thead>
                  <tr>
                    <td width="280">
                      <Icon
                        className="fas fa-arrow-up"
                        onClick={() =>
                          handleChangeOrderBy("insightName", toolTypeId)
                        }
                        isDesc={
                          order === "desc" &&
                          orderBy === "insightName" &&
                          selectedId === toolTypeId
                        }
                      />
                      Insight
                    </td>
                    <td width="280">
                      <Icon
                        className="fas fa-arrow-up"
                        onClick={() =>
                          handleChangeOrderBy("actionName", toolTypeId)
                        }
                        isDesc={
                          order === "desc" &&
                          orderBy === "actionName" &&
                          selectedId === toolTypeId
                        }
                      />
                      Plano de ação
                    </td>
                    <td>Responsável</td>
                    <td width="260">Rastreabilidade</td>
                    <td width="100">Ínicio</td>
                    <td width="100">Fim</td>
                    <td width="100">Conclusão</td>
                    <td width="120">
                      <Icon
                        className="fas fa-arrow-up"
                        onClick={() =>
                          handleChangeOrderBy("rescheduledDate", toolTypeId)
                        }
                        isDesc={
                          order === "desc" &&
                          orderBy === "rescheduledDate" &&
                          selectedId === toolTypeId
                        }
                      />
                      Nova Data
                    </td>
                    <td width="125">
                      <Icon
                        className="fas fa-arrow-up"
                        onClick={() =>
                          handleChangeOrderBy("status", toolTypeId)
                        }
                        isDesc={
                          order === "desc" &&
                          orderBy === "status" &&
                          selectedId === toolTypeId
                        }
                      />
                      Status
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {actions.map((action) => {
                    const { insightId } = action;

                    if (!insightId) return null;
                    const actionStartDate = transformDate(action.startDate);
                    const actionEnDate = transformDate(action.endDate);
                    const actionRescheduled = transformDate(
                      action.rescheduledDate,
                    );
                    const actionConclusion = transformDate(action.conclusion);

                    const actionPerformance = getActionPerformance(action);

                    const colorStatusActions = getObjectInfoById(
                      actionPerformance.status,
                      evaluationStatusOptions,
                      "value",
                    ).color;

                    const { traceability } = action || {};
                    const { cenary = {}, question = {} } = traceability || {};
                    const { name: scenerieName = "" } = cenary || {};
                    const { name: questionName = "" } = question || {};

                    const translatedScenerieName = customCenaryName(
                      scenerieName,
                      toolTypeId,
                    );

                    const translatedQuestionName = translateQuestionName(
                      questionName,
                      toolTypeId,
                    );

                    return (
                      <tr
                        key={action.id}
                        onClick={() =>
                          openEditDialog(
                            action,
                            actionPlan,
                            insightId,
                            toolTypeId,
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <td>{action.insightName}</td>
                        <td
                          style={{
                            borderLeft: `4px solid ${colorStatusActions}`,
                          }}
                        >
                          {action.actionName}
                        </td>
                        <TdTool>
                          <div>
                            <Avatar
                              src={action.avatarBlobId}
                              style={{ width: "25px", height: "25px" }}
                            />
                            <span>{action.responsibleName}</span>
                          </div>
                        </TdTool>
                        <TdTracebility>{`${translatedScenerieName} > ${translatedQuestionName} > ${action.traceability.theme?.name}`}</TdTracebility>
                        <TdStyled>{actionStartDate}</TdStyled>
                        <TdStyled>{actionEnDate}</TdStyled>
                        <TdStyled>{actionConclusion}</TdStyled>
                        <TdStyled>{actionRescheduled}</TdStyled>
                        <td align="center">
                          {
                            getObjectInfoById(
                              action.status,
                              actionStatusOptions,
                              "value",
                            ).label
                          }
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Container>
        );
      })}
    </>
  );
};

export default injectIntl(ToolView);

ToolView.propTypes = {
  actionPlans: PropTypes.array.isRequired,
};
