import React, { useState } from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import utils from "../../../../../../../utils/toolUtils";
import kpiUtils from "../../../../../../../utils/kpis";
import { deleteKpiIndicator } from "../../../../../../../actions/kpiActions";
import { archiveInsightsList } from "../../../../../../../actions/insightActions";
import {
  getEmptyTableRow,
  getDefaultAddTableLine,
  getDefaultEditColumn,
} from "../../../../../../../utils/tableUtils";
import { getDefaultRemoveColumn } from "../../../../../../../utils/okrUtils";
import MaterialSingleSelect from "../../../../../../Common/MaterialSingleSelect";

const {
  getUpdatedDeleteArrayByIndex,
  orderArrayByObjAttr,
  getObjectInfoById,
  getOnlySublevelArrays,
  getOnlySlugValues,
} = utils;

const { displayTransformValue } = kpiUtils;

const messages = defineMessages({
  kpi_table_text_goal_upper: { id: "kpi_table_text_goal_upper" },
  kpi_table_text_no_goal: { id: "kpi_table_text_no_goal" },
  kpi_table_text_relationship: { id: "kpi_table_text_relationship" },
  kpi_table_text_status: { id: "kpi_table_text_status" },
  kpi_confirm_delete_goal: { id: "kpi_confirm_delete_goal" },
  kpi_warning_connected_goal_insight: {
    id: "kpi_warning_connected_goal_insight",
  },
  kpi_warning_insights_will_delete: { id: "kpi_warning_insights_will_delete" },
  kpi_confirm_archive_all: { id: "kpi_confirm_archive_all" },
});

const defaultFilter = {
  relationshipType: false,
};

const IndicatorsTable = (props) => {
  const [filterBy, setFilterBy] = useState(defaultFilter);

  const {
    kpiAdministration,
    setKpiAdministration,
    getDefaultTargetCols,
    toggleOpenFormDialogs,
    setConfirmationDialog,
    resetConfirmDialog,
    handleSetEditingForm,
    relationshipTypeOptions,
    allAnswers = {},
    deleteKpiIndicator,
    archiveInsightsList,
    intl,
  } = props;

  const updateFilter = (filterSlug, newValue) => {
    setFilterBy({ ...filterBy, [filterSlug]: newValue });
  };

  const handleArchiveInsights = (allRelatedInsights) => {
    const archiveIdsList = getOnlySlugValues(allRelatedInsights, "id");

    archiveInsightsList(archiveIdsList);

    resetConfirmDialog();
  };

  const handleDeleteIndicator = (index) => {
    const deletingIndicatorInfo = kpiAdministration.goals[index];

    if (deletingIndicatorInfo.id) {
      deleteKpiIndicator(deletingIndicatorInfo.id, kpiAdministration.id);

      const updatedGoals = getUpdatedDeleteArrayByIndex(
        kpiAdministration.goals,
        index,
      );

      setKpiAdministration({
        ...kpiAdministration,
        goals: updatedGoals,
      });
    }

    resetConfirmDialog();
  };

  const openDeleteIndicator = (index) => {
    const deletingIndicator = kpiAdministration.goals[index] || {};

    function getRelatedInsights(kpiConfigurationGoalId, source = []) {
      if (source && source.length > 0) {
        return source.filter(
          (insightInfo) =>
            insightInfo.kpiConfigurationGoalId === kpiConfigurationGoalId,
        );
      }

      return [];
    }

    if (deletingIndicator.id) {
      const onlyInsights = getOnlySublevelArrays(allAnswers);

      let allowedToDelete = true;
      let allRelatedInsights = getRelatedInsights(
        deletingIndicator.id,
        onlyInsights,
      );

      allowedToDelete = allRelatedInsights.length > 0 ? false : true;

      const confirmationFunction = allowedToDelete
        ? () => handleDeleteIndicator(index)
        : () => handleArchiveInsights(allRelatedInsights);

      setConfirmationDialog({
        title: allowedToDelete
          ? intl.formatMessage(messages.kpi_confirm_delete_goal)
          : intl.formatMessage(messages.kpi_warning_connected_goal_insight),
        description: allowedToDelete ? null : (
          <span>
            {intl.formatMessage(messages.kpi_warning_insights_will_delete)}:
            {allRelatedInsights.map((insightInfo) => {
              const { question = {} } = insightInfo;

              return (
                <div style={{ paddingTop: "10px" }}>
                  {question.id && `${question.text} -> `}
                  <b>{insightInfo.text}</b>
                </div>
              );
            })}
          </span>
        ),
        onConfirm: confirmationFunction,
        confirmText: !allowedToDelete
          ? intl.formatMessage(messages.kpi_confirm_archive_all)
          : null,
        onCancel: resetConfirmDialog,
        open: true,
      });
    }
  };

  const filterDisabled = !filterBy.relationshipType;

  const getFilterCleaner = () => {
    return (
      !filterDisabled && (
        <div className="col-xs-6">
          <a
            onClick={() => setFilterBy(defaultFilter)}
            style={{ cursor: "pointer" }}
          >
            Limpar filtro
          </a>
        </div>
      )
    );
  };

  const { goals = [] } = kpiAdministration;
  const filtered = !filterDisabled
    ? [...goals].filter(
        ({ relationshipType }) =>
          relationshipType === filterBy.relationshipType,
      )
    : goals;
  const reorderedIndicators = orderArrayByObjAttr(filtered, "title");

  return (
    <div>
      <div className="row" style={{ display: "flex", alignItems: "center" }}>
        <div
          className="col-xs-2"
          style={{ padding: "0px 15px 0px 0px", backgroundColor: "#fff" }}
        >
          <MaterialSingleSelect
            id="filterRelationshipType"
            label="Filtro por Relacionamento"
            options={[
              { value: false, label: "Todos" },
              ...relationshipTypeOptions,
            ]}
            value={filterBy.relationshipType}
            onChange={(e) => updateFilter("relationshipType", e.target.value)}
          />
        </div>
        {getFilterCleaner()}
      </div>
      <table
        className={`simple-table table-striped ${
          props.applyScroll && "horizontal-scroll"
        }`}
      >
        <thead>
          {getDefaultAddTableLine(() => toggleOpenFormDialogs("newIndicator"))}
        </thead>
        <tbody>
          <tr className="thead">
            <td width="30" />
            <td>{intl.formatMessage(messages.kpi_table_text_goal_upper)}</td>
            <td width="150">
              {intl.formatMessage(messages.kpi_table_text_relationship)}
            </td>
            {getDefaultTargetCols()}
            <td colSpan="2" />
          </tr>
          {reorderedIndicators?.map((indicatorInfo, index) => {
            return (
              <tr className="block-hover text-bold" key={indicatorInfo.id}>
                <td>{index + 1}</td>
                <td>{indicatorInfo.title}</td>
                <td>
                  {
                    getObjectInfoById(
                      indicatorInfo.relationshipType,
                      relationshipTypeOptions,
                      "value",
                    ).label
                  }
                </td>
                <td align="right" width="140">
                  {displayTransformValue(
                    indicatorInfo.target.gate,
                    indicatorInfo.measurementUnity,
                    2,
                  )}
                </td>
                <td align="right" width="140">
                  {displayTransformValue(
                    indicatorInfo.target.appropriated,
                    indicatorInfo.measurementUnity,
                    2,
                  )}
                </td>
                <td align="right" width="140">
                  {displayTransformValue(
                    indicatorInfo.target.exceeded,
                    indicatorInfo.measurementUnity,
                    2,
                  )}
                </td>
                {getDefaultEditColumn(() =>
                  handleSetEditingForm("newIndicator", {
                    goalId: indicatorInfo.id,
                  }),
                )}
                {getDefaultRemoveColumn(() => openDeleteIndicator(index))}
              </tr>
            );
          })}
          {getEmptyTableRow(
            reorderedIndicators,
            filterDisabled
              ? intl.formatMessage(messages.kpi_table_text_no_goal)
              : "Nenhum encontrado",
          )}
        </tbody>
      </table>
    </div>
  );
};

export default injectIntl(
  connect(null, { deleteKpiIndicator, archiveInsightsList })(IndicatorsTable),
);
