import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";
import { Tabs, Tab } from "react-bootstrap";
import _ from "lodash";
import utils from "../../../../../../utils/toolUtils";
import gbUtils from "../../../../../../utils/goalsBonus";
import {
  updateGoalBonusAdministration,
  addWorkflowComment,
} from "../../../../../../actions/goalsAndBonusActions";
import {
  getGoalValidationStatusOptions,
  getRelationshipTypeOptions,
  getMeasurementUnityOptions,
} from "../../../../../../constants/goalsAndBonus";
import {
  getSelectedToolStates,
  getGoalsBonusStates,
  getCompanyMembersAndParticipants,
} from "../../../../../customMapStates";
import WorkflowTable from "./fields/WorkflowTable";
import ChangeWorkflowDialog from "../../../../../Common/GoalsAndBonus/forms/ChangeWorkflowDialog";
import { simpleSaveAsExcel } from "../../../../../Common/Export/ExportCSV";

const { getObjectInfoById, getGrantedAccessLevels, getFilteredLevelsByAccess } =
  utils;

const {
  getFilteredGoals,
  getFilteredGoalsRelationTree,
  getGoalsRelationTree,
  buildFormAdmConfigBody,
  getGoalsBonusBySelectedToolId,
  buildRestAdmConfigBody,
  getTopLevelUserAccess,
  getApproversEmails,
  getGoalWorkflowParticipantEmails,
  checkDatesPeriodBetween,
} = gbUtils;

const messages = defineMessages({
  gb_workflow_page_title: { id: "gb_workflow_page_title" },
  gb_workflow_no_goal_for_status: { id: "gb_workflow_no_goal_for_status" },
});

const defaultGoalBonusAdmBody = buildFormAdmConfigBody({});

const Workflow = (props) => {
  const [activeTab, setActiveTab] = useState("APPROVAL");
  const [goalBonusAdministration, setGoalBonusAdministration] = useState(
    defaultGoalBonusAdmBody,
  );
  const [selectedParticipantGoals, setSelectedParticipantGoals] =
    useState(null);
  const [goalsRelationTree, setGoalsRelationTree] = useState({});
  const [workflowController, setWorkflowController] = useState({
    changeModal: false,
    params: {},
  });

  const {
    selectedTool = {},
    allGoalBonusAdministration,
    isLoading,
    addWorkflowComment,
    allComments,
    allMembers,
    intl,
  } = props;

  const { dates } = goalBonusAdministration;

  const toolId =
    selectedTool.tool && selectedTool.tool.id ? selectedTool.tool.id : null;

  const relationshipTypeOptions = getRelationshipTypeOptions();
  const measurementUnityOptions = getMeasurementUnityOptions();

  const userAccess = getTopLevelUserAccess();

  const isAdmin = userAccess.accesses.indexOf("ADMIN") > -1;
  const isAuditor = userAccess.accesses.indexOf("AUDITOR") > -1;

  const haveWorkflowFullAccess = isAdmin;
  const haveAuditorAcess = isAuditor;

  const approvalAllowed = checkDatesPeriodBetween(
    dates.startApproval,
    dates.endApproval,
  );

  const auditAllowed = checkDatesPeriodBetween(
    dates.startAudit,
    dates.endAudit,
  );

  const grantedLevels = getGrantedAccessLevels(
    toolId,
    props.allSceneries,
    props.allThemes,
    props.allQuestions,
    props.allAnswers,
  );

  const workflowAccessEntities = getFilteredLevelsByAccess(
    props.allSceneries,
    props.allThemes,
    props.allQuestions,
    props.allAnswers,
    grantedLevels.workflowAccess,
  );

  const { allSceneries, allThemes, allQuestions, allAnswers } =
    workflowAccessEntities;

  useEffect(() => {
    if (allGoalBonusAdministration) {
      const toolGoalsBonusAdm = getGoalsBonusBySelectedToolId(
        selectedTool.id,
        allGoalBonusAdministration,
      );

      if (!_.isEqual(toolGoalsBonusAdm, goalBonusAdministration)) {
        setGoalBonusAdministration(toolGoalsBonusAdm);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGoalBonusAdministration, isLoading, selectedTool.id]);

  useEffect(() => {
    const newGoalsRelationTree = getGoalsRelationTree(
      selectedTool.id,
      allSceneries,
      allThemes,
      allQuestions,
      allAnswers,
    );

    if (!_.isEqual(newGoalsRelationTree, goalsRelationTree))
      setGoalsRelationTree(newGoalsRelationTree);
  }, [
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
    selectedTool.id,
    goalsRelationTree,
    goalBonusAdministration,
  ]);

  const goalValidationStatusOptions = getGoalValidationStatusOptions();

  const filterByQuestionId = (questionId, goals = []) => {
    const questionGoals = [];

    if (questionId && goals && goals.length > 0) {
      goals.forEach((goalInfo) => {
        if (parseInt(goalInfo.questionId, 10) === parseInt(questionId, 10))
          questionGoals.push(goalInfo);
      });
    }

    return questionGoals;
  };

  const filterByQuestionIdAndFilter = (
    questionId,
    statusFilter,
    goals = [],
  ) => {
    const questionGoals = filterByQuestionId(questionId, goals);
    const filteredByStatus = getFilteredGoals(statusFilter, questionGoals).map(
      (goalInfo) => goalInfo,
    );

    return filteredByStatus;
  };

  const saveGoalBonusAdministration = async (newGoalBonusAdministration) => {
    const restBody = buildRestAdmConfigBody(newGoalBonusAdministration);

    props.updateGoalBonusAdministration(selectedTool.id, restBody);
  };

  const resetWorkflowController = () => {
    setWorkflowController({ changeModal: false, params: {} });
  };

  const getUpdateConfig = (goalId, newStatus) => {
    const { goals } = goalBonusAdministration;
    const editingGoalInfo = getObjectInfoById(goalId, goals, "id");

    let relatedInsights = [];

    if (editingGoalInfo) {
      const newUpdatedGoals = [];

      if (Array.isArray(goals)) {
        goals.forEach((goalInfo) => {
          let updatedGoal = goalInfo;

          if (goalInfo.id === editingGoalInfo.id) {
            updatedGoal = { ...goalInfo, workflowStatus: newStatus };
            relatedInsights = goalInfo.relatedInsights;
          }

          newUpdatedGoals.push(updatedGoal);
        });
      }

      const updatedAdministration = {
        ...goalBonusAdministration,
        goals: newUpdatedGoals,
      };

      const updatedGoal = getObjectInfoById(goalId, newUpdatedGoals, "id");

      return { updatedAdministration, updatedGoal, relatedInsights };
    }

    return {
      updatedAdministration: goalBonusAdministration,
      updatedGoal: null,
    };
  };

  const handleUpdateTab = (tabSlug) => {
    if (tabSlug) {
      setSelectedParticipantGoals(null);

      setActiveTab(tabSlug);
    }
  };

  const saveWorkflowChanges = (
    comment,
    sendEmails = false,
    sendToParticipants = false,
  ) => {
    const { params = {} } = workflowController;
    const { goalId = {}, newStatus, prevStatus, questionId } = params;
    const participantUserId = props.userId;
    const participantUserName = props.userName;

    const { updatedGoal, relatedInsights, updatedAdministration } =
      getUpdateConfig(goalId, newStatus);

    let approversEmails = [];

    const participantEmails = sendToParticipants
      ? getGoalWorkflowParticipantEmails(
          goalBonusAdministration,
          goalsRelationTree,
          updatedGoal.id,
        )
      : [];

    if (sendEmails) {
      approversEmails = getApproversEmails(
        updatedGoal,
        goalBonusAdministration,
        goalsRelationTree,
        allMembers,
      );
    }

    if (
      updatedGoal.id &&
      updatedAdministration &&
      comment &&
      relatedInsights &&
      participantUserId
    ) {
      saveGoalBonusAdministration(updatedAdministration);

      addWorkflowComment(
        [updatedGoal],
        comment,
        participantUserId,
        newStatus,
        { approvers: approversEmails, participants: participantEmails },
        participantUserName,
      );
    }

    const leftQuestionGoals = filterByQuestionIdAndFilter(
      questionId,
      prevStatus,
      updatedAdministration.goals,
    );

    if (leftQuestionGoals.length === 0) {
      handleUpdateTab(prevStatus);
    }

    resetWorkflowController();
  };

  const handleChangeWorkflowStatus = (
    goalId,
    newStatus,
    questionId,
    statusFilter,
  ) => {
    setWorkflowController({
      ...workflowController,
      changeModal: true,
      params: {
        questionId,
        prevStatus: statusFilter,
        newStatus,
        goalId,
      },
    });
  };

  const getOrderedGoals = (displayGoals) => {
    const filtered = [];

    displayGoals.forEach(({ id }) => {
      const goalInfo = getObjectInfoById(
        id,
        goalBonusAdministration.goals,
        "id",
      );

      filtered.push(goalInfo);
    });

    return filtered;
  };

  const displayGoalsWorkflow = (statusFilter = "APPROVAL") => {
    const finalFilteredWorkflow = getFilteredGoalsRelationTree(
      goalBonusAdministration,
      goalsRelationTree,
      {},
      statusFilter,
    );

    const activeFilterTab = statusFilter === activeTab;

    if (activeFilterTab) {
      if (finalFilteredWorkflow[0] && !selectedParticipantGoals) {
        // Primeira Questão da Fila será definida como a selecionada
        setSelectedParticipantGoals(finalFilteredWorkflow[0].questionId);
      }

      const currentSelectedIndex = parseInt(
        getObjectInfoById(
          selectedParticipantGoals,
          finalFilteredWorkflow,
          "questionId",
          true,
        ),
        10,
      );
      const displayParticipantGoals =
        finalFilteredWorkflow[currentSelectedIndex] || {};

      const prevIndex =
        currentSelectedIndex - 1 >= 0 ? currentSelectedIndex - 1 : -1;

      const nextIndex =
        currentSelectedIndex + 1 <= finalFilteredWorkflow.length - 1
          ? currentSelectedIndex + 1
          : -1;

      const slide = {
        previous: finalFilteredWorkflow[prevIndex]
          ? finalFilteredWorkflow[prevIndex].questionId
          : -1,
        next: finalFilteredWorkflow[nextIndex]
          ? finalFilteredWorkflow[nextIndex].questionId
          : -1,
      };

      const statusInfo = getObjectInfoById(
        statusFilter,
        goalValidationStatusOptions,
        "value",
      );

      const emptyMessage = intl.formatMessage(
        messages.gb_workflow_no_goal_for_status,
        { status: statusInfo.statusLabel },
      );

      const participantGoals = displayParticipantGoals.participantGoals || [];
      const originalGoals = getOrderedGoals(participantGoals);

      return (
        activeFilterTab && (
          <WorkflowTable
            show
            goals={originalGoals}
            statusFilter={statusFilter}
            metaInfo={{
              questionId: displayParticipantGoals.questionId,
              participantInfo: displayParticipantGoals.participantInfo,
              roleInfo: displayParticipantGoals.roleInfo,
              teamInfo: displayParticipantGoals.teamInfo,
            }}
            goToPrevious={
              slide.previous > -1
                ? () => setSelectedParticipantGoals(slide.previous)
                : null
            }
            goToNext={
              slide.next > -1
                ? () => setSelectedParticipantGoals(slide.next)
                : null
            }
            handleChangeWorkflowStatus={handleChangeWorkflowStatus}
            goalValidationStatusOptions={goalValidationStatusOptions}
            relationshipTypeOptions={relationshipTypeOptions}
            measurementUnityOptions={measurementUnityOptions}
            emptyMessage={emptyMessage}
            isLoading={isLoading}
            allComments={allComments}
            userId={props.userId}
            haveWorkflowFullAccess={haveWorkflowFullAccess}
            haveAuditorAcess={haveAuditorAcess}
            approvalAllowed={approvalAllowed}
            auditAllowed={auditAllowed}
            administrationDates={goalBonusAdministration.dates}
          />
        )
      );
    }

    return null;
  };

  const dataBuild = (workflowTree) => {
    const data = [];

    if (Array.isArray(workflowTree)) {
      workflowTree.forEach((participantTreeInfo) => {
        const {
          participantInfo = {},
          participantGoals = [],
          roleInfo = {},
          teamInfo = {},
        } = participantTreeInfo;
        const { memberParticipant = {} } = participantInfo;

        if (Array.isArray(participantGoals)) {
          participantGoals.forEach((goalInfo) => {
            const goalStatus = getObjectInfoById(
              goalInfo.workflowStatus,
              goalValidationStatusOptions,
              "value",
            );
            const { relationshipType } = goalInfo;

            const type =
              getObjectInfoById(
                relationshipType,
                relationshipTypeOptions,
                "value",
              ) || "";

            data.push({
              participant: memberParticipant.name,
              email: memberParticipant.email,
              role: roleInfo.role,
              team: teamInfo.name,
              goal: goalInfo.title,
              workflowStatus: goalStatus.statusLabel,
              type: type.value,
            });
          });
        }
      });
    }

    return data;
  };

  const buildReportData = () => {
    const workflowTree = getFilteredGoalsRelationTree(
      goalBonusAdministration,
      goalsRelationTree,
    );

    return dataBuild(workflowTree);
  };

  const handleReportExport = () => {
    const reportData = buildReportData();

    const headers = [
      {
        header: "Participante",
        key: "participant",
        width: 35,
      },
      { header: "Email", key: "email", width: 35 },
      {
        header: "Cargo",
        key: "role",
        width: 25,
      },
      {
        header: "Time",
        key: "team",
        width: 25,
      },
      { header: "Meta", key: "goal", width: 45 },
      { header: "Tipo", key: "type", width: 15 },
      { header: "Workflow", key: "workflowStatus", width: 15 },
    ];

    const timeStampString = moment().toJSON();

    simpleSaveAsExcel(
      reportData,
      headers,
      null,
      `workflow-report-${timeStampString}`,
      [{ line: 1, fill: "eeeeee" }],
    );
  };

  return (
    <div className="row">
      <ChangeWorkflowDialog
        open={workflowController.changeModal}
        onConfirm={({ message, sendEmails, sendToParticipants }) =>
          saveWorkflowChanges(message, sendEmails, sendToParticipants)
        }
        onCancel={() => resetWorkflowController()}
      />
      <div className="row" style={{ display: "flex", alignItems: "center" }}>
        <div className="col-xs-10">
          <h2 style={{ color: "#6b42a9", paddingBottom: "10px" }}>
            {intl.formatMessage(messages.gb_workflow_page_title)}
          </h2>
        </div>
        <div
          className="col-xs-2"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleReportExport}
          >
            <i className="fas fa-file-csv" /> Relatório de Workflow
          </button>
        </div>
      </div>
      <div className="custom-primary-tabs-adm">
        <Tabs
          id="workflowTabs"
          activeKey={activeTab}
          onSelect={(key) => handleUpdateTab(key)}
          animation={false}
        >
          {goalValidationStatusOptions.map(({ value, label, icon }, index) => {
            const { viewAccess = {} } = grantedLevels;

            const title = (
              <>
                {icon} {label} (
                {
                  getFilteredGoals(
                    value,
                    goalBonusAdministration.goals,
                    viewAccess.insights,
                  ).length
                }
                )
              </>
            );

            const isActiveTabFilter = value === activeTab;

            return (
              <Tab eventKey={value} title={title} key={index}>
                <div className="col-xs-12">
                  {isActiveTabFilter && displayGoalsWorkflow(value)}
                </div>
              </Tab>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { selectedTool, allSceneries, allThemes, allQuestions, allAnswers } =
    getSelectedToolStates(state);
  const { allGoalBonusAdministration, isLoading } = getGoalsBonusStates(state);
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);

  const { insightsTimeline = {}, user = {} } = state;
  const { allComments } = insightsTimeline;

  return {
    allGoalBonusAdministration,
    selectedTool,
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
    isLoading,
    allComments,
    userId: user.id || null,
    userName: `${user.firstName} ${user.lastName}`,
    allMembers: companyMembersAndParticipants,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    updateGoalBonusAdministration,
    addWorkflowComment,
  })(Workflow),
);
