import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import React, { useEffect, useState } from "react";

import {
  setAuthInfo,
  logout as stateLogout,
} from "../../../actions/authActions";
import DomainConfigurator from "../utils/DomainConfigurator";
import setAuthorizationToken from "../../../utils/setAuthorizationToken";
import {
  fetchUserByMail,
  fetchUser,
  fetchUserByCpf,
} from "../../../actions/userActions";
import { SELECTED_COMPANY } from "../../../actions/types";

const resetAuth = {
  email: "",
  token: "",
  isAuthenticated: false,
};

const storageNotNull = (value) => {
  return value?.length > 0 && value !== "undefined";
};

const AuthRookau = ({
  user,
  auth = {},
  stateLogout,
  setAuthInfo,
  fetchUserByMail,
  fetchUserByCpf,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { email, token, isAuthenticated, authProvider, cpf } = auth || {};

  const logout = () => {
    stateLogout();
    setAuthInfo(resetAuth);

    const fullHostUrl = window.location.host;
    window.location.replace(`http://${fullHostUrl}/#/logout`);
  };

  const loginWithRedirect = () => {
    setAuthInfo(resetAuth);

    const fullHostUrl = window.location.host;
    window.location.replace(`http://${fullHostUrl}/#/login`);
  };

  useEffect(() => {
    if (authProvider === "ROOKAU") return;

    setAuthInfo({
      authProvider: "ROOKAU",
      logout,
      loginWithRedirect,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout, loginWithRedirect]);

  useEffect(() => {
    const email = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const cpf = localStorage.getItem("cpf");

    if (storageNotNull(token) && storageNotNull(email)) {
      setAuthInfo({
        email,
        token,
        isAuthenticated: false,
      });
    }

    if (storageNotNull(token) && storageNotNull(cpf)) {
      setAuthInfo({
        cpf,
        token,
        isAuthenticated: false,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem("email", email);
    localStorage.setItem("token", token);
    localStorage.setItem("cpf", cpf);
  }, [email, token]);

  useEffect(() => {
    const doAuthenticationFlux = () => {
      setIsLoading(true);

      setAuthorizationToken(token).then(() => {
        if (cpf) {
          fetchUserByCpf(cpf);
        }

        if (email) {
          fetchUserByMail(email);
        }

        const selectedCompanyId = localStorage.getItem(SELECTED_COMPANY);

        const body = {
          activeInterceptor: true,
          redirectToHome: true,
          isAuthenticated: true,
        };

        if (selectedCompanyId?.length > 0) body.redirectToHome = false;
        if (cpf) {
          setAuthInfo({ ...body, cpf });
        }
        if (email) {
          setAuthInfo({ ...body, email });
        }
      });

      setIsLoading(false);
    };

    if (
      token?.length > 0 &&
      !isAuthenticated &&
      (email?.length > 0 || cpf.length > 0)
    )
      doAuthenticationFlux();
  }, [
    token,
    email,
    cpf,
    setAuthInfo,
    isAuthenticated,
    fetchUserByMail,
    user,
    fetchUserByCpf,
  ]);

  return <DomainConfigurator isLoading={isLoading} />;
};

const mapStateToProps = (state) => {
  const { auth = {}, user = {} } = state;

  return {
    auth,
    user,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    setAuthInfo,
    fetchUser,
    fetchUserByMail,
    fetchUserByCpf,
    stateLogout,
  })(AuthRookau),
);
