import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import { globalMessages } from "../../utils/global";
import SimpleLoader from "../SimpleLoader";

const FormDialog = ({
  open,
  title,
  description,
  toggleOpen,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  blockConfirm,
  blockCancel,
  children,
  bodyStyle,
  dialogClassName,
  hideCancel,
  hideConfirm,
  intl,
  targetRef,
  backdrop = false,
  bottomLeft,
  isLoading,
  hideFooter,
}) => {
  const displayCancelText =
    cancelText === "defaultCancel"
      ? intl.formatMessage(globalMessages.cancel)
      : cancelText;
  const displayConfirmText =
    confirmText === "defaultConfirm"
      ? intl.formatMessage(globalMessages.confirm)
      : confirmText;

  const borderStyles = { borderColor: "#ccc" };

  return (
    <div className="modal-container" ref={targetRef}>
      <Modal
        onHide={blockCancel ? () => {} : onCancel || toggleOpen}
        show={open}
        enforceFocus={false}
        backdrop={backdrop}
        dialogClassName={dialogClassName}
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header closeButton style={borderStyles}>
          <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
            <Modal.Title>{title}</Modal.Title>
            {isLoading && <SimpleLoader color="#6b42a9" />}
          </div>
        </Modal.Header>

        <Modal.Body style={bodyStyle || {}}>
          <div>
            {description && description}
            {children}
          </div>
        </Modal.Body>

        {!hideFooter && (
          <Modal.Footer
            style={{
              ...borderStyles,
            }}
          >
            <div className="row" style={{ display: "flex", gap: 10 }}>
              <div className="col-xs-6" style={{ padding: 0 }}>
                {bottomLeft ? bottomLeft : ""}
              </div>
              <div
                className="col-xs-6"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 10,
                  width: "100%",
                  padding: 0,
                }}
              >
                {!hideCancel && (
                  <Button
                    variant="secondary"
                    onClick={blockCancel ? () => {} : onCancel || toggleOpen}
                    disabled={blockCancel}
                  >
                    {displayCancelText}
                  </Button>
                )}
                {!hideConfirm && (
                  <Button
                    variant={blockConfirm ? "secondary" : "primary"}
                    className={blockConfirm ? "btn-disabled" : "btn-purple"}
                    onClick={blockConfirm ? () => null : onConfirm}
                    disabled={blockConfirm}
                  >
                    {displayConfirmText}
                  </Button>
                )}
              </div>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
  children: PropTypes.node,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  toggleOpen: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  blockConfirm: PropTypes.bool,
  bodyStyle: PropTypes.object,
  dialogClassName: PropTypes.string,
  hideCancel: PropTypes.bool,
  hideConfirm: PropTypes.bool,
};

FormDialog.defaultProps = {
  open: false,
  description: "",
  children: "",
  confirmText: "defaultConfirm",
  cancelText: "defaultCancel",
  blockConfirm: false,
  dialogClassName: null,
  hideCancel: false,
  hideConfirm: false,
  bodyStyle: {},
};

export default injectIntl(FormDialog);
