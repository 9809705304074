import { checkToolIdInState } from "../../../utils/entity_validations";

export const checkScenerySelectedTool = (scenery = {}) => {
  return checkToolIdInState(scenery.selectedTool);
};

export const checkThemeSelectedTool = (theme = {}) => {
  if (theme && theme.cenary) return checkScenerySelectedTool(theme.cenary);

  return false;
};

export const checkQuestionSelectedTool = (question = {}) => {
  if (question.theme && question.theme.cenary)
    return checkScenerySelectedTool(question.theme.cenary);

  return false;
};

export const checkInsightSelectedTool = (insight = {}) => {
  if (insight && insight.question) {
    return checkQuestionSelectedTool(insight.question);
  }

  return false;
};
