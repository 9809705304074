import _ from "lodash";
import {
  FETCH_ALL_PEOPLE_EVALUATION,
  UPDATE_PEOPLE_EVALUATION,
  UPDATE_PROFESSIONAL_PROFILE,
  PEOPLE_MASS_UPDATE,
} from "../actions/types";
import { updateAllList } from "../utils/reducerUtils";
import { getInsightPeopleEvaluation } from "../utils/peopleEvaluationUtils";

const updateEvaluation = (newBody, currentAllEvaluation) => {
  let finalEvaluation = [];
  let didUpdate = false;

  if (currentAllEvaluation) {
    currentAllEvaluation.forEach((itemBody) => {
      if (itemBody.insightId === newBody.insightId) {
        didUpdate = true;
        finalEvaluation.push(newBody);
      } else {
        finalEvaluation.push(itemBody);
      }
    });
  }

  if (didUpdate === false) {
    finalEvaluation = [...finalEvaluation, newBody];
  }

  return finalEvaluation;
};

const peopleEvaluationReducer = (state = {}, action) => {
  const currentAllEvaluation = state.allPeopleEvaluation
    ? state.allPeopleEvaluation
    : [];
  const payload = action.payload;

  switch (action.type) {
    case FETCH_ALL_PEOPLE_EVALUATION:
      return {
        ...state,
        allPeopleEvaluation: updateAllList(
          currentAllEvaluation,
          payload,
          "insightId",
        ),
      };
    case UPDATE_PEOPLE_EVALUATION:
      return {
        ...state,
        allPeopleEvaluation: updateEvaluation(payload, currentAllEvaluation),
      };
    case UPDATE_PROFESSIONAL_PROFILE:
      const { insightId, professionalProfile } = payload;

      const insightEvaluation = getInsightPeopleEvaluation(
        insightId,
        currentAllEvaluation,
      );

      insightEvaluation.peopleEvaluation.professionalProfile =
        professionalProfile;

      return {
        ...state,
        allPeopleEvaluation: updateEvaluation(insightEvaluation),
      };
    case PEOPLE_MASS_UPDATE:
      const updatedAllPeopleEvaluation = _.cloneDeep(action.payload);

      return { ...state, allPeopleEvaluation: [...updatedAllPeopleEvaluation] };

    case "ONE_PAGE_LOADING":
      return { ...state, isLoading: payload.loading };
    default:
      return state;
  }
};

export default peopleEvaluationReducer;
