import axios from "axios";
import { SELECT_TOOLS_TIMELINE, ADD_TOOL_TIMELINE } from "./types";

export const selectToolsTimeline = (toolId) => {
  return (dispatch) => {
    return axios
      .get(`/api/selected-tools/${toolId}/insights-timelines-dto`)
      .then((res) => {
        dispatch({ type: SELECT_TOOLS_TIMELINE, payload: res.data });
      });
  };
};

export const addToolTimeline = (toolId, comment, user, date) => {
  return (dispatch) => {
    let tlItem = {
      type: "timeline_item",
      date,
      owner: {
        id: user,
      },
      text: comment,
    };
    return axios
      .post(`/api/selected-tools/${toolId}/timeline`, tlItem)
      .then((res) => {
        dispatch({ type: ADD_TOOL_TIMELINE, payload: res.data });
      });
  };
};
