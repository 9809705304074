export const getToFixed = (val, fix = 1) => {
  return val === 0 || !isNaN(val)
    ? parseFloat(val).toFixed(fix)
    : (0).toFixed(fix);
};

export const round = (num, fix = 1) => {
  const number = Math.round(num * Math.pow(10, fix)) / Math.pow(10, fix);
  return number;
};

export const getPercentage = (newVal, oldVal) => {
  const realDiff = newVal - oldVal;
  const absDiff = Math.abs(newVal - oldVal);
  const diffOverNew = absDiff / oldVal;
  let finalPercentage = diffOverNew * 100;
  finalPercentage = realDiff < 0 ? -finalPercentage : finalPercentage;
  return finalPercentage;
};

export const getRelativePercentage = (val, total, fix = 2) => {
  const validValue = val > 0 ? val : 0;
  const percentage = (validValue * 100) / total;
  return round(percentage, fix);
};

export const getProjectRelativePercentage = (val, total) => {
  const validValue = val > 0 ? val : 0;
  const percentage = (validValue * 100) / total;
  return Math.round(percentage);
};

export const ruleOfThree = (
  base,
  compareValue,
  basePercetage = 100,
  patternNull = 0,
  invertedBase = false,
) => {
  const realBase = !invertedBase ? base : compareValue;
  const realCompare = !invertedBase ? compareValue : base;

  const multiply = parseFloat(realCompare) * parseFloat(basePercetage);

  let x = multiply / parseFloat(realBase);

  x = isFinite(x) ? x : patternNull;

  return x;
};

export const nFormatter = (num, digits = 0) => {
  if (num < 1) return num;

  const si = [
    { value: 0, symbol: "" },
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "Mi" },
    { value: 1e9, symbol: "Bi" },
    { value: 1e12, symbol: "Tri" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  let i = 0;

  for (i = si.length - 1; i > 0; i--) if (num >= si[i].value) break;

  if (num)
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;

  return num;
};

export const floatNumber = (value, defaultStringValue = "") => {
  const validValue = Number(value);

  if (validValue === 0 || !isNaN(validValue)) {
    return parseFloat(validValue, 10);
  }

  return defaultStringValue;
};

export const simpleSumArray = (arrayOfValues = []) => {
  let totalSum = 0;

  if (Array.isArray(arrayOfValues)) {
    arrayOfValues.forEach((value) => {
      totalSum += parseFloat(value);
    });
  }

  return totalSum;
};

export const sumArrays = (arrayOfArrays) => {
  const totalSum = [];

  if (Array.isArray(arrayOfArrays)) {
    arrayOfArrays.forEach((evaluation) => {
      evaluation.forEach((value, index) => {
        totalSum[index] = totalSum[index] || 0;
        totalSum[index] = parseFloat(totalSum[index]) + parseFloat(value);
      });
    });
  }

  return totalSum;
};

export const simpleSum = (v1, v2) => {
  let sum = 0;

  // NAO ALTERAR A ORDEM DAS OPERACOES
  // PODE OCASIONAR EFEITOS COLATERAIS
  if (!isNaN(v1) && !isNaN(v2)) {
    sum = parseFloat(Number(v2)) + parseFloat(Number(v1));
  }

  return sum;
};

export const simpleSubtraction = (v1, v2) => {
  let subtracted = 0;

  // NAO ALTERAR A ORDEM DAS OPERACOES
  // PODE OCASIONAR EFEITOS COLATERAIS
  if (!isNaN(v1) && !isNaN(v2)) {
    subtracted = parseFloat(v1) - parseFloat(v2);
  }

  return subtracted;
};

export const getNumber = (value) => {
  const validValue =
    typeof value === "number" || typeof value === "string" ? value : 0;

  return Number(validValue.toString());
};

export const checkIsNumber = (value) => {
  const numberValue = getNumber(value);

  if (
    value === false ||
    value === null ||
    typeof value === "undefined" ||
    typeof value === "object" ||
    isNaN(numberValue) ||
    value === ""
  )
    return false;

  return true;
};

export const compareValues = (value, compareTo = 0, checkFor = "greater") => {
  if (!checkIsNumber(value)) return false;

  const numberValue = getNumber(value);
  const compareValue = getNumber(compareTo);

  const isGreater = numberValue > compareValue;
  const isEqual = numberValue === compareValue;
  const isLower = numberValue < compareValue;

  if (checkFor === "equal") return isEqual;
  if (checkFor === "greater") return isGreater;
  if (checkFor === "greaterOrEqual") return isEqual || isGreater;
  if (checkFor === "lower") return isLower;
  if (checkFor === "lowerOrEqual") return isEqual || isLower;

  return isGreater;
};

export const checkGreaterThan = (
  value,
  compareTo = 0,
  equalAllowerd = false,
) => {
  const comparison = equalAllowerd ? "greaterOrEqual" : "greater";
  return compareValues(value, compareTo, comparison);
};

export const checkLowerThan = (value, compareTo = 0, equalAllowerd = false) => {
  const comparison = equalAllowerd ? "lowerOrEqual" : "lower";
  return compareValues(value, compareTo, comparison);
};

export const splitNumber = (value, slot = 0) => {
  return value.toString().split(".")[slot];
};

export const getDecimals = (value) => {
  return splitNumber(value, 1);
};

export const removeUnecessaryDecimals = (value) => {
  if (getDecimals(value) <= 0) return getToFixed(value, 0);
  return value;
};

export const countDecimals = (value) => {
  if (value && Math.floor(value) !== value)
    return getDecimals(value)?.length || 0;

  return 0;
};

export const format = (num) => {
  if (num > 999 && num < 1000000) {
    return `${parseFloat((num / 1000).toFixed(1))}K`; // convert to K for number from > 1000 < 1 million
  }

  if (num > 1000000) {
    return `${parseFloat((num / 1000000).toFixed(1))}M`; // convert to M for number from > 1 million
  }

  return num;
};
