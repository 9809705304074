import axios from "axios";
import { apiNode, apiJava } from "../api";

const setAuthorizationToken = async (token) => {
  if (token) {
    localStorage.setItem("splace-authenticationToken", token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    apiNode.defaults.headers.common.Authorization = `Bearer ${token}`;
    apiJava.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
    delete apiNode.defaults.headers.common.Authorization;
    delete apiJava.defaults.headers.common.Authorization;
  }
};

export default setAuthorizationToken;
