import React, { useState } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { getOnlySublevelArrays } from "../../../../../../../utils/ArrayUtils";
import { getConcurrenceCriteriaTranslation } from "../../../../../../../utils/cpAnalysis/display";
import { defineMessages } from "react-intl";

const messages = defineMessages({
  global_noData: { id: "global.noData" },
});

const ConcurrenceManagement = ({
  concurrenceData = {},
  selectAndOpenInsight,
  intl = {},
}) => {
  const [expanded, setExpanded] = useState(true);

  const handleEmpty = (data = []) => {
    if (data.length > 0) return;

    return (
      <div style={{ padding: "15px 0px 0px 0px", textAlign: "center" }}>
        {intl.formatMessage(messages.global_noData)}
      </div>
    );
  };

  const listEvaluationHeaders = () => {
    const { evaluationConfig: headers = [] } = concurrenceData;

    return headers.map(({ type }, index) => {
      const translated = getConcurrenceCriteriaTranslation(type);

      const subTitle = translated.substring(0, 11);

      return (
        <div
          className="comp-evaluation-header-cell"
          title={translated}
          key={index}
        >
          <div className="comp-evaluation-cell-rotation">{subTitle} </div>
        </div>
      );
    });
  };

  const renderHeaders = () => {
    return (
      <>
        <div className="comp-first-header-cell" />
        <div className="comp-header-cell">Logo</div>
        <div className="comp-long-header-cell">Nome</div>
        <div className="comp-number-header-cell">Ano</div>
        <div className="comp-header-cell">Tempo Mercado</div>
        <div className="comp-header-cell">Funcionários</div>
        {listEvaluationHeaders()}
        <div className="comp-header-cell">Nivel Geral</div>
      </>
    );
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const getScaleColorClass = (value = "") => {
    const scaleColor = {
      1: "concurrenceLightRed",
      2: "concurrenceDarkRed",
      3: "concurrenceOrange",
      4: "concurrenceYellow",
      5: "concurrenceLightGreen",
      6: "concurrenceDarkGreen",
    };

    return scaleColor[value] || "";
  };

  const getAllBiggerValues = (data) => {
    let employeesBValue = 0;
    let generalPointsBValue = 0;

    data.forEach((evaluation) => {
      const { companyDetails = [], evaluations = [] } = evaluation || {};

      const { employees = 0 } = companyDetails || {};

      const generalPoints = getGeneralPoints(evaluations);

      if (employees > employeesBValue) employeesBValue = employees;
      if (generalPoints > generalPointsBValue)
        generalPointsBValue = generalPoints;
    });

    return { employeesBValue, generalPointsBValue };
  };

  const getGeneralPoints = (evaluations = []) => {
    let generalPoints = 0;

    evaluations.forEach(({ value }) => {
      generalPoints += value;
    });

    return generalPoints;
  };

  const calcFieldPercentage = (biggerValue = 0, actualValue = 0) => {
    return (actualValue * 100) / biggerValue;
  };

  const getEvaluationsObj = (evaluations = []) => {
    let evaluationsObj = {};

    evaluations.forEach(({ evaluationConfigId = 0, value = 0 }) => {
      evaluationsObj = { ...evaluationsObj, [evaluationConfigId]: { value } };
    });

    return evaluationsObj;
  };

  const renderEvaluationFields = (headers = [], evaluationsObj = {}) => {
    return headers.map((header, index) => {
      const { id = false } = header;

      const { value = false } = evaluationsObj[id] || {};

      const scaleColorClass = getScaleColorClass(value);

      if (!value) return emptyField(index);

      return (
        <div
          key={index}
          className={`comp-evaluation-header-cell ${scaleColorClass}`}
        >
          <div>{value}</div>
        </div>
      );
    });
  };

  const emptyField = (index = 0) => {
    return (
      <div key={index} className="comp-evaluation-header-cell emptyGrey">
        <div> </div>
      </div>
    );
  };

  const generateLine = ({
    evaluation = {},
    index = 0,
    allBiggerValues = {},
    headers = [],
  }) => {
    const { evaluations = [], companyDetails = {}, insightId = "" } =
      evaluation || {};

    let evaluationsObj = getEvaluationsObj(evaluations);

    const { employeesBValue = 0, generalPointsBValue = 0 } = allBiggerValues;

    const {
      employees = "",
      logo,
      name = "",
      site = "",
      specializations = [],
      foundationYear = false,
    } = companyDetails || {};

    const generalPoints = getGeneralPoints(evaluations);
    const employeesPercentage = calcFieldPercentage(employeesBValue, employees);
    const generalPointsPercentage = calcFieldPercentage(
      generalPointsBValue,
      generalPoints,
    );

    const tooltipSiteSpecializations = (
      <Tooltip id="tooltip_siteSpecializations">
        <div className="row">Nome : {name}</div>
        <div className="row">Site : {site}</div>
        <div className="row">
          Especializações:
          {specializations.map(({ title }, index) => {
            let specialChar = `${title},`;
            if (index === specializations.length - 1) specialChar = title;
            return <span key={index}> {specialChar}</span>;
          })}
        </div>
      </Tooltip>
    );

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    let marketTime = foundationYear ? currentYear - foundationYear : 1;

    if (marketTime <= 0) marketTime = 1;

    return (
      <div
        className="row concurrenceTableLine"
        key={index}
        onClick={() => selectAndOpenInsight(insightId)}
      >
        <div className="comp-first-header-cell" style={{ fontSize: "14px" }}>
          {index + 1}
        </div>
        <div className="comp-header-cell">
          <img
            src={logo}
            alt=""
            style={{
              width: "60px",
              height: "60px",
              padding: "3px",
              borderRadius: "50%",
              border: "3px solid white",
            }}
          />
        </div>
        <OverlayTrigger placement="bottom" overlay={tooltipSiteSpecializations}>
          <div
            className="comp-long-header-cell"
            style={{ textAlign: "left", whiteSpace: "nowrap" }}
          >
            {name}
          </div>
        </OverlayTrigger>
        <div className="comp-number-header-cell">{foundationYear}</div>
        <div className="comp-header-cell">
          <div
            className="progress-management progress"
            style={{
              width: "85%",
              height: "20px",
              textAlign: "center",
              position: "relative",
            }}
          >
            <div
              role="progressbar"
              className="progress-bar emptyGrey"
              style={{ width: `${marketTime}%` }}
            />
            <div align="right" className="insideDivProgress fix-13pxFont">
              <strong>{marketTime}</strong>
            </div>
          </div>
        </div>
        <div className="comp-header-cell">
          <div
            className="progress-management progress"
            style={{
              width: "85%",
              height: "20px",
              textAlign: "center",
              position: "relative",
            }}
          >
            <div
              role="progressbar"
              className="progress-bar emptyGrey"
              style={{ width: `${employeesPercentage}%` }}
            />
            <div align="right" className="insideDivProgress fix-13pxFont">
              <strong>{employees}</strong>
            </div>
          </div>
        </div>
        {renderEvaluationFields(headers, evaluationsObj)}
        <div className="comp-header-cell" style={{ paddingLeft: "7px" }}>
          <div
            className="progress-management progress"
            style={{
              width: "90%",
              height: "20px",
              textAlign: "center",
              position: "relative",
            }}
          >
            <div
              role="progressbar"
              className="progress-bar emptyGrey"
              style={{ width: `${generalPointsPercentage}%` }}
            />
            <div align="right" className="insideDivProgress fix-13pxFont">
              <strong>{generalPoints}</strong>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const { evaluations = [], evaluationConfig: headers = [] } = concurrenceData;

  const data = getOnlySublevelArrays(evaluations);

  const essentialInfo = {
    headers,
    allBiggerValues: getAllBiggerValues(data),
  };

  return (
    <div className="evaluation-card">
      <div
        className="card-header card-header-hover row concurrenceManagementTable"
        onClick={toggleExpanded}
      >
        <div className="card-title concurrenceTable-title-wrapper">
          <h5>Dados Gerais</h5>
        </div>
        <div className="card-title">
          <h5>Escala de Competitividade</h5>
        </div>
      </div>
      <div
        className="card-body concurrenceTable-body"
        style={{ display: expanded ? "block" : "none" }}
      >
        <div className="header">{renderHeaders()}</div>
        <div style={{ minWidth: "255px !important" }}>
          {data.map((evaluation, index) =>
            generateLine({ ...essentialInfo, index, evaluation }),
          )}
          {handleEmpty(data)}
        </div>
      </div>
    </div>
  );
};

export default ConcurrenceManagement;
