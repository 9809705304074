import {
  CHANGE_SHOW_HIDDEN_ANSWERS,
  CHANGE_SHOW_HIDDEN_THEMES,
  CHANGE_SHOW_HIDDEN_CENARYS,
  CHANGE_SHOW_HIDDEN_QUESTIONS,
  CHANGE_SHOW_INSIGHT_RESUME,
  CHANGE_STATUS_TEMPLATE,
  REAL_INSIGHT_SCORE,
  CLEAN_ALL_LOCAL_STORAGE,
  CHANGE_STATUS_INSIGHT,
  CLEAN_HIDDEN_STORAGE,
} from "./types";

export const changeShowHiddenCenarys = (value) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_SHOW_HIDDEN_CENARYS, payload: value });
  };
};

export const changeShowHiddenThemes = (value) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_SHOW_HIDDEN_THEMES, payload: value });
  };
};

export const changeShowHiddenQuestions = (value) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_SHOW_HIDDEN_QUESTIONS, payload: value });
  };
};

export const changeShowHiddenAnswers = (value) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_SHOW_HIDDEN_ANSWERS, payload: value });
  };
};

export const changeShowInsightResume = (value) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_SHOW_INSIGHT_RESUME, payload: value });
  };
};

export const realInsightScore = (value) => {
  return (dispatch) => {
    dispatch({ type: REAL_INSIGHT_SCORE, payload: value });
  };
};

export const changeStatusTemplate = (value) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_STATUS_TEMPLATE, payload: value });
  };
};

export const cleanAllLocalStorageData = () => {
  return (dispatch) => {
    dispatch({ type: CLEAN_ALL_LOCAL_STORAGE });
  };
};

export const cleanHiddenStorageData = () => {
  return (dispatch) => {
    dispatch({ type: CLEAN_HIDDEN_STORAGE });
  };
};
export const changeStatusInsight = (value) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_STATUS_INSIGHT, payload: value });
  };
};
