import React, { useState, useContext, useEffect } from "react";
import { defineMessages } from "react-intl";
import { Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormDialog from "../../Common/FormDialog";
import PreviewTable from "../PreviewTable";
import ValidationInfo from "./ValidationInfo";

import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";

import StepLabel from "@material-ui/core/StepLabel";
import SettingsIcon from "@material-ui/icons/Settings";
import CheckIcon from "@material-ui/icons/Check";
import GetAppIcon from "@material-ui/icons/GetApp";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MeasurementContext from "../MeasurementContext";
import _ from "lodash";
import { translatedText } from "../../../utils/translationUtils";
import { getOnlySublevelArrays } from "../../../utils/ArrayUtils";

const translation = (id, values) => translatedText(id, messages, values);

const messages = defineMessages({
  measu_update_database: {
    id: "measu_update_database",
  },
  measu_something_went_wrong: {
    id: "measu_something_went_wrong",
  },
  measu_model_confirmation: {
    id: "measu_model_confirmation",
  },
  measu_model_validation: {
    id: "measu_model_validation",
  },
  measu_update: {
    id: "measu_update",
  },
  measu_is_this_the_correct_model: {
    id: "measu_is_this_the_correct_model",
  },
  measu_import_validated_data: {
    id: "measu_import_validated_data",
  },
  measu_unknown_step: {
    id: "measu_unknown_step",
  },
  measu_back: {
    id: "measu_back",
  },
  measu_importing_document: {
    id: "measu_importing_document",
  },
  measu_yes: {
    id: "measu_yes",
  },
});

const ImportController = () => {
  const {
    callImportController = false,
    setCallImportController,
    fileInfo,
    handleValidation,
    handleMassUpdate,
    additionalData,
    massUpdateAction,
    resetFileInfo,
    handleErrorMessage,
    dataToExport,
    intl,
  } = useContext(MeasurementContext);

  const [activeStep, setActiveStep] = useState(0);
  const [updateData, setUpdateData] = useState({});
  const [nonValidatedState, setNonValidatedState] = useState({});

  useEffect(() => {
    if (activeStep === 1) {
      trackValidations();
    }
  }, [activeStep]);

  const { file = {}, fileData = {} } = fileInfo;

  const resetAllStates = () => {
    setActiveStep(0);
    setUpdateData([]);
    setNonValidatedState([]);
    resetFileInfo();
  };

  const handleImportConfirmation = () => {
    setCallImportController(false);
    modelUpdate();
    resetAllStates();
  };

  const handleImportCancelation = () => {
    setCallImportController(false);
    resetAllStates();
  };

  const modelPreviewConfirmation = () => {
    const { data = {} } = fileData;

    let appendProps = {
      handleErrorMessage,
      intl,
    };

    return (
      <>
        {Object.keys(data).map((sheetName = "", index) => {
          const { fakeHeader = {}, title = "" } = dataToExport[sheetName] || {};
          const { data: selectedData = [] } = data[sheetName] || {};

          appendProps = {
            ...appendProps,
            data: selectedData,
            headers: fakeHeader,
          };

          return (
            <div className="previewTableWrapper" key={index}>
              <h3>{title}</h3>
              <PreviewTable {...appendProps} />
            </div>
          );
        })}
      </>
    );
  };

  const trackValidations = () => {
    const { validated = {}, nonValidated = {} } = handleValidation(
      fileData,
      additionalData,
    );

    if (Object.keys(validated).length > 0) setUpdateData(validated);

    if (Object.keys(nonValidated).length > 0 && nonValidatedState.length === 0)
      setNonValidatedState(nonValidated);
  };

  const modelValidation = () => {
    return (
      <>
        {Object.keys(updateData).map((sheet, index) => {
          const { fakeHeader = {}, title = "" } = dataToExport[sheet] || {};
          const validatedReference = updateData[sheet] || false;
          const nonValidatedReference = nonValidatedState[sheet] || [];

          return (
            <div className="validationTableWrapper" key={index}>
              <h3 className="textAlignCenter">{title}</h3>
              <ValidationInfo
                validatedData={validatedReference}
                nonValidatedData={nonValidatedReference}
                headers={fakeHeader}
              />
            </div>
          );
        })}
      </>
    );
  };

  const checkBlockConditions = () => {
    const updateDataArray = getUpdateDataArray();

    if (activeStep === 2 && updateDataArray.length > 0) {
      return false;
    }

    return true;
  };

  const modelUpdate = () => {
    const updateDataArray = getUpdateDataArray();

    if (updateDataArray.length === 0) return;

    const { selectedToolId = "" } = additionalData;

    const restData = handleMassUpdate(updateData, additionalData);
    if (restData) massUpdateAction(restData, selectedToolId);
  };

  const displayOkMessage = () => {
    return (
      <div
        style={{
          backgroundColor: "#d4edda",
          color: "#477e53",
          textAlign: "center",
          fontWeight: "bold",
          padding: "10px",
          borderRadius: "4px",
          width: "80%",
          margin: "0 auto",
        }}
      >
        {translation("measu_update_database")}
      </div>
    );
  };

  const displayErrorMessage = () => {
    return (
      <div
        style={{
          backgroundColor: "#f8d7da",
          color: "#893c43",
          textAlign: "center",
          fontWeight: "bold",
          padding: "10px",
          borderRadius: "4px",
          width: "80%",
          margin: "0 auto",
        }}
      >
        {translation("measu_something_went_wrong")}
      </div>
    );
  };

  const getUpdateDataArray = () => {
    const dataArray = getOnlySublevelArrays(updateData);

    return dataArray;
  };

  const modelReadyToUpdate = () => {
    const updateDataArray = getUpdateDataArray();

    if (updateDataArray.length > 0) return displayOkMessage();

    return displayErrorMessage();
  };

  const handleContent = () => {
    const content = [
      modelPreviewConfirmation,
      modelValidation,
      modelReadyToUpdate,
    ];

    if (callImportController) {
      return content[activeStep]();
    }

    return <div></div>;
  };

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      "& $line": {
        backgroundColor: "#5d8457",
      },
    },
    completed: {
      "& $line": {
        backgroundColor: "#5d8457",
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
    },
  })(StepConnector);

  const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: "#ccc",
      zIndex: 1,
      color: "#fff",
      width: 50,
      height: 50,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    active: {
      backgroundColor: "#5d8457",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
      backgroundColor: "#5d8457",
    },
  });

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
      1: <SettingsIcon fontSize="large" />,
      2: <CheckIcon fontSize="large" />,
      3: <GetAppIcon fontSize="large" />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: "18px",
    },
  }));

  function getSteps() {
    return [
      translation("measu_model_confirmation"),
      translation("measu_model_validation"),
      translation("measu_update"),
    ];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return translation("measu_is_this_the_correct_model");
      case 1:
        return translation("measu_import_validated_data");
      case 2:
        return "";
      default:
        return translation("measu_unknown_step");
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const StepsToImport = () => {
    const classes = useStyles();
    const steps = getSteps();

    return (
      <div className={classes.root}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <h5>{label}</h5>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Row>{handleContent()}</Row>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
              <div align="center">
                <Button
                  variant="contained"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  style={{ color: "#333", backgroundColor: "#ddd" }}
                  className={classes.button}
                >
                  {translation("measu_back")}
                </Button>
                {activeStep !== steps.length - 1 && (
                  <Button
                    variant="contained"
                    style={{ color: "#fff", backgroundColor: "#5d8457" }}
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {translation("measu_yes")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <FormDialog
        open={callImportController}
        title={translation("measu_importing_document") + file.name}
        onConfirm={handleImportConfirmation}
        onCancel={handleImportCancelation}
        bodyStyle={{ padding: "0 15px 15px 15px" }}
        dialogClassName="widthController"
        blockConfirm={checkBlockConditions()}
      >
        {StepsToImport()}
      </FormDialog>
    </div>
  );
};

export default ImportController;
