import axios from "axios";

export const uploadFile = (file, s3bucket) => {
  return axios
    .post(`/api/s3/upload-file/?s3bucket=${s3bucket}`, file)
    .then((res) => {
      return res.data;
    });
};

export const deleteFile = async (fileName, s3bucket) => {
  const response = await axios.delete(
    `/api/s3/delete-file/?s3bucket=${s3bucket}&fileName=${fileName}`,
  );

  return response.data;
};

export const downloadFile = (fileName, s3bucket) => {
  return axios
    .get(`/api/s3/get-file/?s3bucket=${s3bucket}&fileName=${fileName}`)
    .then((res) => {
      return res.data;
    });
};
