import { defineMessages } from "react-intl";
import { translatedText } from "../../utils/translationUtils";

const messages = defineMessages({
  global_commented: { id: "global.commented" },
  forMoreInfo: { id: "global.forMoreInfo" },
});

const translation = (id) => translatedText(id, messages);

export const workflowUpdateTemplate = (
  title,
  description,
  statusMessage,
  userName,
  comment = "",
  goals = [],
  status,
  statusColor = "#91cf50",
  { customUrlLink = "", domainUrl = "" },
) => {
  let goalsList = "";
  let descriptionField = description
    ? `<div style="padding-bottom: 15px; color: #666;">${description}</div>`
    : "";

  const for_more_info = translation("forMoreInfo");

  if (Array.isArray(goals)) {
    goals.forEach(
      (goalTitle) =>
        (goalsList += `<li style="padding: 5px; background-color: #f9f9f9;">${goalTitle}</li>`),
    );
  }

  const forMoreInformation = `<p">${for_more_info}</p>`;
  const appRookauCom = `<p"><a href=${customUrlLink}>${domainUrl}</p>`;

  return (
    '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">' +
    "<html>" +
    "  <head>" +
    '    <meta charset="UTF-8" />' +
    '    <meta content="width=device-width, initial-scale=1" name="viewport" />' +
    '    <meta name="x-apple-disable-message-reformatting" />' +
    '    <meta http-equiv="X-UA-Compatible" content="IE=edge" />' +
    '    <meta content="telephone=no" name="format-detection" />' +
    "    <title></title>" +
    '    <style type="text/css">' +
    "      @import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');" +
    "      body {" +
    "        font-family: 'Montserrat', sans-serif !important;" +
    "      }" +
    "      d, span, a, div, p, font {" +
    "        font-family: 'Montserrat', sans-serif !important;" +
    "      }" +
    "    </style>" +
    "  </head>" +
    "" +
    '  <body style="margin: 20px;">' +
    '    <div class="es-wrapper-color">' +
    "      <!--[if gte mso 9]>" +
    '        <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">' +
    '          <v:fill type="tile" color="#f6f6f6"></v:fill>' +
    "        </v:background>" +
    "      <![endif]-->" +
    "      <div" +
    '        style="width: 460px; margin: 0 auto; background-color: #fcfcfc; border-radius: 4px; border: 1px solid #eee;"' +
    "      >" +
    "        <table" +
    '          cellpadding="0"' +
    '          cellspacing="0"' +
    '          width="100%"' +
    '          style="background-position: left bottom;"' +
    "        >" +
    "          <tbody>" +
    "            <tr>" +
    "              <td" +
    '                align="center"' +
    '                class="esd-block-text"' +
    '                style="background-color: #f6f6f6; padding: 20px;"' +
    "              >" +
    '                <h1 style="margin: 10px 0px;">' +
    title +
    "</h1>" +
    descriptionField +
    "              </td>" +
    "            </tr>" +
    "            <tr>" +
    "              <td" +
    '                align="left"' +
    '                class="esd-block-text es-p20t"' +
    '                style="padding: 20px;"' +
    "              >" +
    "                <p>" +
    statusMessage +
    "                  <font" +
    '                    style="line-height: 36px; color: #fff; background-color: ' +
    statusColor +
    '; padding: 4px 6px; font-size: 14px; border-radius: 4px; white-space: nowrap;"' +
    "                    >" +
    status +
    "</font>" +
    "                </p>" +
    '                <ul style="padding: 0px 20px;">' +
    goalsList +
    "                </ul>" +
    "                <p>" +
    `${userName} ${translation("global_commented")}: ${comment}` +
    "                </p>" +
    `${forMoreInformation}` +
    `${appRookauCom}` +
    "              </td>" +
    "            </tr>" +
    "          </tbody>" +
    "          <tfooter>" +
    "            <tr>" +
    "              <td" +
    '                style="background-color: #111;"' +
    '                align="center"' +
    "              >" +
    '                <a href="https://www.rookau.com" target="_blank"' +
    "                  ><img" +
    '                    src="https://splace-images.s3-sa-east-1.amazonaws.com/cabecalho-Rookau.png"' +
    '                    width="100%"' +
    "                /></a>" +
    "              </td>" +
    "            </tr>" +
    "          </tfooter>" +
    "        </table>" +
    "      </div>" +
    "    </div>" +
    "  </body>" +
    "</html>"
  );
};
