import {
  ADD_INSIGHT_GOAL,
  UPDATE_INSIGHT_GOAL,
  DELETE_INSIGHT_GOAL,
  FETCH_ALL_INSIGHT_GOALS,
  FETCH_ALL_TOOLS_INSIGHT_GOALS,
} from "../actions/types";

import { updateAllList, makeObjLevelAnArray } from "../utils/reducerUtils";

const INITIAL_STATE = {
  allInsightGoals: [],
};

const insightGoalReducer = (state = INITIAL_STATE, action) => {
  const currentAllGoals = state.allInsightGoals || [];

  const updateGoals = (insightId, newInsightGoal) => {
    let finalEvaluation = [];
    let didUpdate = false;

    currentAllGoals.forEach((itemBody) => {
      if (itemBody.insightId === insightId) {
        itemBody.insightGoal = newInsightGoal;
        didUpdate = true;
      }

      finalEvaluation = [...finalEvaluation, itemBody];
    });

    if (!didUpdate)
      finalEvaluation = [
        ...finalEvaluation,
        { insightId, insightGoal: newInsightGoal },
      ];

    return finalEvaluation;
  };
  switch (action.type) {
    case ADD_INSIGHT_GOAL:
    case UPDATE_INSIGHT_GOAL:
      return {
        ...state,
        allInsightGoals: updateGoals(
          action.payload.insightId,
          action.payload.insightGoal,
        ),
      };
    case DELETE_INSIGHT_GOAL:
      return INITIAL_STATE;
    case FETCH_ALL_INSIGHT_GOALS:
      return {
        ...state,
        allInsightGoals: updateAllList(
          currentAllGoals,
          action.payload,
          "insightId",
        ),
      };
    case FETCH_ALL_TOOLS_INSIGHT_GOALS:
      const { payload: newInsightGoals = {} } = action || {};

      return {
        ...state,
        allInsightGoals: updateAllList(
          currentAllGoals,
          makeObjLevelAnArray(newInsightGoals),
          "insightId",
        ),
      };
    default:
      return state;
  }
};

export default insightGoalReducer;
