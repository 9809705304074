/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  getSkillAssessmentStates,
  getSelectedToolStates,
  getActionsPlanStates,
} from "../../../customMapStates";
import EvaluationLine from "../../ToolTabs/CustomFields/SkillAssessment/overview/includes/EvaluationLine";
import ActionsCounterBadge from "../../../Common/ActionPlan/evaluations/ActionsCounterBadge";
import actionsPlanUtils from "../../../../utils/actionsPlan";
import { getOnlySlugValues } from "../../../../utils/ArrayUtils";
import {
  getMaximumScale,
  getSkillsWorkflowAverage,
  getTotalWorkflowAverage,
} from "../../../../utils/skillsAssessment/calc";
import {
  useInsightParticipants,
  useSimpleToolAdministration,
} from "../../../../utils/skillsAssessment/hooks/administration";
import { evaluationColors } from "../../../../utils/skillsAssessment/constants";

const { getTotalActionsByInsights } = actionsPlanUtils;

const SkillAssessment = ({
  insightsToCalc,
  allSkillAssessment,
  allActionsPlans,
  selectedTool,
  from,
  loggedUser,
}) => {
  const { toolAdministration } = useSimpleToolAdministration({
    selectedTool,
    allSkillAssessment,
  });
  const { participants: filteredParticipants } = useInsightParticipants({
    insights: insightsToCalc,
    participants: toolAdministration.participants,
    loggedUser,
  });
  const [displayAverage, setDisplayAverage] = useState({});

  const maxScale = getMaximumScale(toolAdministration.evaluationScale);

  useEffect(() => {
    const { workflowBySkills } = getSkillsWorkflowAverage({
      ...toolAdministration,
      participants: filteredParticipants,
    });

    const { average: totalAverage } = getTotalWorkflowAverage(
      workflowBySkills,
      toolAdministration.roleSkillRelation,
      toolAdministration.evaluationType,
      toolAdministration.evaluationWeights,
    );

    if (!_.isEqual(totalAverage, displayAverage))
      setDisplayAverage(totalAverage);
  }, [toolAdministration, filteredParticipants]);

  const AgrupedEvaluation = ({ hideTitle = false }) => {
    const onlyInsightIds = getOnlySlugValues(insightsToCalc, "id");

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            marginLeft: "15px",
            marginRight: "10px",
            textAlign: "right",
            lineHeight: "18px",
          }}
        >
          {!hideTitle && <font style={{ fontSize: "12px" }}>Avaliação:</font>}
          <div
            style={{
              display: "flex",
              width: "130px",
              height: "24px",
              alignItems: "center",
            }}
          >
            <div title="Avaliação" style={{ width: "130px" }}>
              <EvaluationLine
                evaluation={displayAverage.averageEvaluation}
                maxValue={maxScale}
                toFixed={1}
                colorInfo={evaluationColors.averageEvaluation}
                lineHeight="20px"
              />
            </div>
          </div>
        </div>
        <ActionsCounterBadge
          actions={
            getTotalActionsByInsights(onlyInsightIds, allActionsPlans).length
          }
          hideTitle={hideTitle}
        />
      </div>
    );
  };

  const handleDisplayEvaluation = (from) => {
    const hideWhen = ["cenary"];
    const hideTitleFrom = ["question", "answer", "score", "overview"];
    const hideTitle = hideTitleFrom.indexOf(from) > -1;

    return (
      hideWhen.indexOf(from) === -1 && (
        <AgrupedEvaluation hideTitle={hideTitle} />
      )
    );
  };

  return (
    <div style={{ fontFamily: "montserrat" }}>
      {handleDisplayEvaluation(from)}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { selectedTool = {} } = getSelectedToolStates(state);
  const { allSkillAssessment = [] } = getSkillAssessmentStates(state);

  const { allActionsPlans = [] } = getActionsPlanStates(state);

  return {
    allSkillAssessment,
    allActionsPlans,
    selectedTool,
    loggedUser: state.user || {},
  };
};

SkillAssessment.propTypes = {
  insightsToCalc: PropTypes.array.isRequired,
  allSkillAssessment: PropTypes.array.isRequired,
  allActionsPlans: PropTypes.array.isRequired,
  selectedTool: PropTypes.object.isRequired,
  from: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(SkillAssessment);
