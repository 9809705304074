import React from "react";
import PropTypes from "prop-types";
import SelectTreeModal from "../../../../../../Common/SelectTreeModal";

const SelectCompetenceArea = ({
  open,
  competenceAreas,
  initialSelected,
  onConfirm,
  onCancel,
  singleSelect,
}) => {
  const treeList = {
    competenceAreas: {
      label: "Áreas de Competência",
      list: [
        ...competenceAreas.map(({ id, title }) => {
          return { id, title, parentId: null };
        }),
      ],
      countText: "áreas de competencia",
      color: "#333",
      icon: <i className="fas fa-receipt" />,
    },
  };

  return (
    <>
      <SelectTreeModal
        open={open}
        title="Selecione as Áreas de Competência"
        treeList={treeList}
        treeOrder={["competenceAreas"]}
        initialSelected={initialSelected}
        onConfirm={(selected) => onConfirm(selected)}
        onCancel={onCancel}
        singleSelect={singleSelect}
        canSelectAnyLevel
      />
    </>
  );
};

SelectCompetenceArea.propTypes = {
  open: PropTypes.bool,
  competenceAreas: PropTypes.array.isRequired,
  initialSelected: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  singleSelect: PropTypes.bool,
};

SelectCompetenceArea.defaultProps = {
  open: false,
  singleSelect: false,
};

export default SelectCompetenceArea;
