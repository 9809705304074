import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import InsightTraceabilitiesView from "./InsightTraceabilitiesView";
import { searchInsights } from "../../actions/insightActions";
import { getSelectedToolStates, insightsGoalState } from "../customMapStates";

const INITIAL_STATE = {
  searching: false,
  insightSearchTerm: "",
  searchInsightsResult: [],
  addTraceability: false,
  showDeleteDialog: false,
};

class InsightTraceabilitiesContainer extends Component {
  state = INITIAL_STATE;

  onClickAddNewTraceability() {
    this.setState({
      addTraceability: true,
    });
  }

  searchInsights(value) {
    const { selectedWorkspace } = this.props;
    if (value && value !== "") {
      this.setState({
        insightSearchTerm: value,
        searching: true,
      });
      searchInsights(selectedWorkspace.id, value).then((result) => {
        this.setState({
          searchInsightsResult: result,
          searching: false,
        });
      });
    }
  }

  doCancelSearch() {
    this.setState(INITIAL_STATE);
  }

  doRemoveTraceability() {
    this.props.onDeleteTraceability(
      this.state.deletingTraceabilityId,
      this.props.insightId,
    );
    this.setState({
      showDeleteDialog: false,
    });
  }

  doShowDeleteDialog(traceabilityId) {
    this.setState({
      showDeleteDialog: true,
      deletingTraceabilityId: traceabilityId,
    });
  }

  render() {
    const {
      traceabilities,
      insightId,
      selectedTool,
      allInsightGoals,
      selectedWorkspace = {},
    } = this.props;
    const {
      addTraceability,
      insightSearchTerm,
      searchInsightsResult,
      searching,
      showDeleteDialog,
    } = this.state;

    const internalConnections = [];
    const externalConnections = [];

    traceabilities.forEach((connectionInfo) => {
      const connectionInsight = connectionInfo.insight || {};
      const { question = {} } = connectionInsight;
      const { theme = {} } = question;
      const { cenary = {} } = theme;
      const insightSelectedTool = cenary.selectedTool || {};

      if (insightSelectedTool.id === selectedTool.id) {
        internalConnections.push(connectionInfo);
      } else {
        externalConnections.push(connectionInfo);
      }
    });

    return (
      <div>
        <div className="row">
          <h4 style={{ margin: "5px" }}>Conexões internas</h4>
          <div className="col-xs-12">
            <InsightTraceabilitiesView
              internalConnections
              allInsightGoals={allInsightGoals}
              selectedWorkspace={selectedWorkspace}
              showDeleteDialog={showDeleteDialog}
              insightId={insightId}
              selectedTool={selectedTool}
              traceabilities={internalConnections}
              addTraceability={addTraceability}
              insightSearchTerm={insightSearchTerm}
              searchInsightsResult={searchInsightsResult}
              searchInsights={this.searchInsights.bind(this)}
              searching={searching}
              onCancelSearch={this.doCancelSearch.bind(this)}
              onShowDelete={this.doShowDeleteDialog.bind(this)}
              onConfirmDelete={this.doRemoveTraceability.bind(this)}
              onCancelDelete={() => {
                this.setState({ showDeleteDialog: false });
              }}
            />
          </div>
        </div>
        <div className="row" style={{ paddingTop: "15px" }}>
          <h4 style={{ margin: "5px" }}>Conexões externas</h4>
          <div className="col-xs-12">
            <InsightTraceabilitiesView
              allInsightGoals={allInsightGoals}
              selectedWorkspace={selectedWorkspace}
              showDeleteDialog={showDeleteDialog}
              insightId={insightId}
              selectedTool={selectedTool}
              traceabilities={externalConnections}
              addTraceability={addTraceability}
              insightSearchTerm={insightSearchTerm}
              searchInsightsResult={searchInsightsResult}
              searchInsights={this.searchInsights.bind(this)}
              searching={searching}
              onCancelSearch={this.doCancelSearch.bind(this)}
              onShowDelete={this.doShowDeleteDialog.bind(this)}
              onConfirmDelete={this.doRemoveTraceability.bind(this)}
              onCancelDelete={() => {
                this.setState({ showDeleteDialog: false });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

InsightTraceabilitiesContainer.propTypes = {
  /** Lista das rastreabilidades a serem renderizadas */
  traceabilities: PropTypes.array.isRequired,

  /** ID do Insight que vai receber as rastreabilidades */
  insightId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const { allInsightGoals } = insightsGoalState(state);
  const { selectedTool } = getSelectedToolStates(state);
  const { workspacesData = {} } = state;
  const { selectedWorkspace = {} } = workspacesData;

  return {
    selectedWorkspace,
    selectedTool,
    allInsightGoals,
  };
};

export default connect(mapStateToProps, null)(InsightTraceabilitiesContainer);
