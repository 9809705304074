import { applyMiddleware, combineReducers, compose, createStore } from "redux";

import { reducer as toastrReducer } from "react-redux-toastr";
import { reducer as reduxFormReducer } from "redux-form";
import ReduxPromise from "redux-promise";
import { thunk as ReduxThunk } from "redux-thunk";

import WebsocketMiddleware from "./actions/websockets/websockets-middleware";

import { USER_LOGOUT } from "./actions/types";
import accessLevelsReducer from "./reducers/accessLevelsReducer";
import actionsPlanReducer from "./reducers/actionsPlanReducer";
import activeRouteReducer from "./reducers/activeRouteReducer";
import activityInformationReducer from "./reducers/activityInformationReducer";
import answersReducer from "./reducers/answersReducer";
import appActionsReducer from "./reducers/appActionsReducer";
import authReducer from "./reducers/auth";
import avatarsReducer from "./reducers/avatarsReducer";
import boardReducer from "./reducers/board";
import cenaryReducer from "./reducers/cenaryReducer";
import cenaryThemesReducer from "./reducers/cenaryThemesReducer";
import companyGuidelineReducer from "./reducers/companyGuidelineReducer";
import companyPositionsReducer from "./reducers/companyPositionsReducer";
import companyReducer from "./reducers/companyReducer";
import companyStructureReducer from "./reducers/companyStructureReducer";
import cpAnalysisReducer from "./reducers/cpAnalysisReducer";
import domainReducer from "./reducers/domainReducer";
import goalsAndBonusReducer from "./reducers/goalsAndBonusReducer";
import insightAttachmentsReducer from "./reducers/insightAttachmentsReducer";
import insightFollowUpReducer from "./reducers/insightFollowUpReducer";
import insightGoalReducer from "./reducers/insightGoalReducer";
import insightMaturityReducer from "./reducers/insightMaturityReducer";
import insightTagsReducer from "./reducers/insightTagsReducer";
import insightTimelineReducer from "./reducers/insightTimelineReducer";
import insightTraceabilitiesReducer from "./reducers/insightTraceabilitiesReducer";
import kpiReducer from "./reducers/kpiReducer";
import languageReducer from "./reducers/languageReducer";
import loadingReducer from "./reducers/loadingReducer";
import membersReducer from "./reducers/membersReducer";
import messagesReducer from "./reducers/messagesReducer";
import notificationsReducer from "./reducers/notificationsReducer";
import notifierReducer from "./reducers/notifierReducer";
import okrReducer from "./reducers/okrReducer";
import participantReducer from "./reducers/participantReducer";
import peopleEvaluationReducer from "./reducers/peopleEvaluationReducer";
import profileReducer from "./reducers/profileReducer";
import projectEvaluationReducer from "./reducers/projectEvaluationReducer";
import projectPrioritizationReducer from "./reducers/projectPrioritizationReducer";
import questionsReducer from "./reducers/questionsReducer";
import redirect from "./reducers/redirect";
import reportOkrFollowupReducer from "./reducers/reportOkrFollowupReducer";
import selectedCenaryReducer from "./reducers/selectedCenaryReducer";
import selectedToolReducer from "./reducers/selectedToolReducer";
import skillAssessmentReducer from "./reducers/skillAssessmentReducer";
import structureReducer from "./reducers/structureReducer";
import swotEvaluationReducer from "./reducers/swotEvaluationReducer";
import teamsReducer from "./reducers/teamsReducer";
import toolActionPlansReducer from "./reducers/toolActionPlansReducer";
import toolPointsReducer from "./reducers/toolPointsReducer";
import toolTimelineReducer from "./reducers/toolTimelineReducer";
import toolsConfigurationsReducer from "./reducers/toolsConfigurationsReducer";
import toolsReducer from "./reducers/toolsReducer";
import translationsReducer from "./reducers/translationsReducer";
import userReducer from "./reducers/userReducer";
import workspaceToolsReducer from "./reducers/workspaceToolsReducer";
import workspacesReducer from "./reducers/workspacesReducer";
import toolProcessConfigurationsSlice from "components/Tool/ToolTabs/ProcessTool/store/toolProcessConfigurationsSlice";

const INITIAL_STATE = {};

const appReducers = combineReducers({
  auth: authReducer,
  toastr: toastrReducer,
  appActions: appActionsReducer,
  messages: messagesReducer,
  notifications: notificationsReducer,
  user: userReducer,
  profile: profileReducer,
  companyData: companyReducer,
  companyStructure: companyStructureReducer,
  companyPositions: companyPositionsReducer,
  companyGuidelines: companyGuidelineReducer,
  language: languageReducer,
  loading: loadingReducer,
  translations: translationsReducer,
  workspacesData: workspacesReducer,
  avatars: avatarsReducer,
  userTeams: teamsReducer,
  membersReducer,
  activeRoute: activeRouteReducer,
  tools: toolsReducer,
  board: boardReducer,
  workspaceSelectedTools: workspaceToolsReducer,
  selectedTool: selectedToolReducer,
  cenaryThemes: cenaryThemesReducer,
  cenaries: cenaryReducer,
  questions: questionsReducer,
  redirect,
  questionsAnswers: answersReducer,
  users: userReducer,
  selectedInsight: answersReducer,
  insightsTimeline: insightTimelineReducer,
  toolsTimeline: toolTimelineReducer,
  toolsConfigurations: toolsConfigurationsReducer,
  toolPoints: toolPointsReducer,
  insightData: answersReducer,
  selectedCenary: selectedCenaryReducer,
  insightTraceabilities: insightTraceabilitiesReducer,
  insightAttachments: insightAttachmentsReducer,
  insightFollowUps: insightFollowUpReducer,
  insightFollowUpReducer,
  insightGoal: insightGoalReducer,
  insightTags: insightTagsReducer,
  insightMaturity: insightMaturityReducer,
  participantData: participantReducer,
  activityInformation: activityInformationReducer,
  peopleEvaluation: peopleEvaluationReducer,
  reportFollowupOkr: reportOkrFollowupReducer,
  swotEvaluation: swotEvaluationReducer,
  projectEvaluation: projectEvaluationReducer,
  access: accessLevelsReducer,
  form: reduxFormReducer,
  structure: structureReducer,
  goalsBonus: goalsAndBonusReducer,
  projectPrioritization: projectPrioritizationReducer,
  kpi: kpiReducer,
  skillAssessment: skillAssessmentReducer,
  okr: okrReducer,
  actionsPlan: actionsPlanReducer,
  notifier: notifierReducer,
  competitiveAnalysis: cpAnalysisReducer,
  allActionPlans: toolActionPlansReducer,
  domain: domainReducer,
  toolProcessConfigurations: toolProcessConfigurationsSlice,
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return INITIAL_STATE;
    default:
      return appReducers(state, action);
  }
};

const composeMiddlewares = compose(
  applyMiddleware(ReduxPromise, ReduxThunk, WebsocketMiddleware),
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f
);

const createStoreWithMiddleware = composeMiddlewares(createStore);

const store = createStoreWithMiddleware(rootReducer);

export default store;
