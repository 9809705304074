import React from "react";
import PropTypes from "prop-types";
import { getSimpleProfilesTree } from "../../../utils/skillsAssessment/filter";
import SelectTreeModal from "../SelectTreeModal";

const NewQuestion = ({ open, onConfirm, onCancel, profiles, areaName }) => {
  const profilesTree = getSimpleProfilesTree(profiles);

  return (
    <SelectTreeModal
      open={open}
      title="Selecione os Perfis"
      treeList={profilesTree.treeList}
      treeOrder={profilesTree.treeOrder}
      initialSelected={profilesTree.all}
      onConfirm={(selected) => onConfirm(selected)}
      onCancel={onCancel}
      canSelectAnyLevel
      singleSelect={false}
      dialogClassName="select-question-dialog"
      bottomMessage={
        <div style={{ padding: "15px" }}>
          Apenas perfis da Área "{`${areaName}`}" e que não foram vinculados ao
          Quadro estão sendo mostrados.
        </div>
      }
    />
  );
};

NewQuestion.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  profiles: PropTypes.array.isRequired,
};

NewQuestion.defaultProps = {
  open: false,
};

export default NewQuestion;
