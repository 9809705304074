export const HOME = "HOME";
export const COMPANIES = "COMPANIES";
export const COMPANY = "COMPANIES";
export const WORKSPACES = "WORKSPACES";
export const WORKSPACE = "WORKSPACE";
export const TOOLS = "TOOLS";
export const TOOL = "TOOL";
export const FAVORITES = "FAVORITES";
export const PROFILE = "PROFILE";
export const INSIGHT = "INSIGHT";
export const OVERVIEW = "OVERVIEW";
export const REPORTS = "REPORTS";
