import {
  PROJECT_MASS_UPDATE,
  GET_PROJECT_EVALUATION,
  UPDATE_PROJECT_EVALUATION,
  FETCH_ALL_PROJECT_EVALUATION,
  UPDATE_ALL_PROJECT_CONFIGURATIONS,
  UPDATE_PROJECT_STAGE,
  DELETE_PROJECT_STAGE,
} from "../actions/types";

import { updateAllList } from "../utils/reducerUtils";
import {
  deleteProjectStage,
  updateProjectStage,
} from "../utils/projectConfiguration/entity";
import {
  updateEvaluation,
  getPhysicalProgress,
} from "../utils/projectEvaluation";

const INITIAL_STATE = {
  allProjectEvaluation: [],
  isLoading: false,
};

const projectEvaluationReducer = (state = INITIAL_STATE, action) => {
  const payload = action.payload;

  const {
    allProjectEvaluation: currentAllProjectEvaluation = [],
    allProjectConfigurations = [],
  } = state || {};

  switch (action.type) {
    case "LOADING_PROJECT_EVALUATION": {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case FETCH_ALL_PROJECT_EVALUATION:
      const updated = updateAllList(
        currentAllProjectEvaluation,
        payload,
        "insightId",
      );

      updated.forEach((evaluation) => {
        if (evaluation.projectEvaluation) {
          evaluation.projectEvaluation.physicalProgress = getPhysicalProgress(
            evaluation?.projectEvaluation?.activities,
          );
        }
      });

      return {
        ...state,
        allProjectEvaluation: updated,
      };
    case UPDATE_PROJECT_EVALUATION:
      return {
        ...state,
        allProjectEvaluation: [
          ...updateEvaluation(currentAllProjectEvaluation, payload),
        ],
        projectEvaluation: payload.projectEvaluation,
      };
    case GET_PROJECT_EVALUATION:
      return {
        ...state,
        projectEvaluation: payload,
      };
    case PROJECT_MASS_UPDATE:
      let updatedAllProjectEvaluation = [];

      payload.forEach((evaluation) => {
        updatedAllProjectEvaluation = updateEvaluation(
          currentAllProjectEvaluation,
          evaluation,
        );
      });

      return {
        ...state,
        allProjectEvaluation: [...updatedAllProjectEvaluation],
      };
    case UPDATE_ALL_PROJECT_CONFIGURATIONS:
      return {
        ...state,
        allProjectConfigurations: updateAllList(
          allProjectConfigurations,
          [payload],
          "selectedToolId",
        ),
      };
    case UPDATE_PROJECT_STAGE:
      return {
        ...state,
        allProjectConfigurations: updateProjectStage(
          allProjectConfigurations,
          payload,
        ),
      };
    case DELETE_PROJECT_STAGE:
      return {
        ...state,
        allProjectConfigurations: deleteProjectStage(
          allProjectConfigurations,
          payload,
        ),
      };
    default:
      return state;
  }
};

export default projectEvaluationReducer;
