import React, { useState, useCallback, useContext } from "react";
import PropTypes from "prop-types";

import { PopoverContext } from "../../../../Common/SimpleMenu";

import {
  ContentPopover,
  HeaderPopover,
  InputCard,
  DescriptionCard,
} from "./styles";

const CopyBoardCard = ({ title, onSave, description }) => {
  const [name, setName] = useState("");

  const { closePopover } = useContext(PopoverContext);

  const handleChange = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const getAndSaveName = useCallback(
    (save, name) => {
      save(name);

      closePopover();
    },
    [closePopover],
  );

  return (
    <ContentPopover>
      <HeaderPopover hasTitle={!!title}>
        <span>{title}</span>
      </HeaderPopover>
      <InputCard>
        <span>Titulo</span>
        <input type="text" onChange={handleChange} value={name} />
      </InputCard>
      <DescriptionCard>
        <span>{description}</span>
      </DescriptionCard>
      <button onClick={() => getAndSaveName(onSave, name)} type="button">
        Salvar
      </button>
    </ContentPopover>
  );
};

export default CopyBoardCard;

CopyBoardCard.propTypes = {
  title: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  description: PropTypes.string,
};

CopyBoardCard.defaultProps = {
  title: "",
  description: "",
};
