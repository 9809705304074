import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import { globalMessages } from "../../utils/global";
import ConfirmationDialog from "./ConfirmationDialog";

const UarchiveDialog = (props) => {
  const {
    essencialProps = {},
    entityName,
    intl,
    onCancel,
    onConfirm,
    show,
    action,
  } = props;

  return (
    <ConfirmationDialog
      open={show}
      title={
        essencialProps.title
          ? essencialProps.title
          : intl.formatMessage(globalMessages.entityConfirmationAction, {
              action,
              entity: entityName,
            })
      }
      description={
        essencialProps.description
          ? essencialProps.description
          : intl.formatMessage({ id: "global.unArchiveConfirmation" })
      }
      onConfirm={
        essencialProps.onConfirm ? essencialProps.onConfirm : onConfirm
      }
      onCancel={onCancel}
      cancelText={intl.formatMessage(globalMessages.cancel)}
    />
  );
};

UarchiveDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default injectIntl(UarchiveDialog);
