/* eslint-disable react/prop-types */
import React from "react";
import { defineMessages } from "react-intl";
import { getDisplayTargets } from "../../../../../utils/kpis/calc";
import {
  getPeriodsDistributionInfo,
  getPeriodTargetsDivision,
  getSingleIndicatorResults,
} from "../../../../../utils/kpis/calc2";
import { translatedText } from "../../../../../utils/translationUtils";
import DisplayTotalLine from "./DisplayTotalLine";
import { DisplayPeriodEvaluations } from "./DisplayPeriodEvaluations";
import { checkError } from "../../../../../utils/formValidation";
import { getObjectInfoById } from "../../../../../utils/ArrayUtils";

const messages = defineMessages({
  kpi_table_text_actual: { id: "kpi_table_text_actual" },
  kpi_table_text_period: { id: "kpi_table_text_period" },
  kpi_table_text_prev_period: { id: "kpi_table_text_prev_period" },
  kpi_table_text_lp_deviation_expn: {
    id: "kpi_table_text_lp_deviation_expn",
  },
  kpi_table_text_current_deviation_expn: {
    id: "kpi_table_text_current_deviation_expn",
  },
  kpi_table_text_value: { id: "kpi_table_text_value" },
  kpi_table_text_current_period: { id: "kpi_table_text_current_period" },
  kpi_table_text_performance_scale_period: {
    id: "kpi_table_text_performance_scale_period",
  },
  kpi_table_text_gate: { id: "kpi_table_text_gate" },
  kpi_table_text_appropriated: { id: "kpi_table_text_appropriated" },
  kpi_table_text_exceeded: { id: "kpi_table_text_exceeded" },
  kpi_table_text_total_acum: { id: "kpi_table_text_total_acum" },
  kpi_table_text_total_period: { id: "kpi_table_text_total_period" },
  kpi_table_text_score: { id: "kpi_table_text_score" },
  global_comments: { id: "global.comments" },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

const IndicatorDisplayTable = ({
  insight,
  goalDescriptions,
  setGoalDescriptions,
  targetsConfig,
  blockAllChanges,
  formErrors,
  hideLastPeriod,
  zeroWhenNegative,
  IS_CALCULATED,
}) => {
  const {
    goalPercentage: periodPercentage,
    notStarted,
    courseTargetsDivision,
    goalPeriodTotalEvaluation,
    lastYearTotalEvaluation,
    lastYearCourseEvaluation,
    accumulated,
    courseDistributionPercentage,
  } = getSingleIndicatorResults(
    goalDescriptions,
    targetsConfig,
    zeroWhenNegative,
  );

  const insightId = insight?.id || 0;

  const { displayTotal, displayCourseTargets } = getDisplayTargets(
    goalDescriptions,
    goalPeriodTotalEvaluation,
    courseTargetsDivision,
  );
  const { displayTotal: lastYearCourseTotal } = getDisplayTargets(
    goalDescriptions,
    lastYearCourseEvaluation,
  );
  const { displayTotal: lastYearTotal } = getDisplayTargets(
    goalDescriptions,
    lastYearTotalEvaluation,
  );

  const getUpdatedPeriod = (periodId, periodIndex, value, source) => {
    const newUpdatedPeriod = Array.isArray(source) ? [...source] : [];

    const indexOf = getObjectInfoById(
      periodIndex,
      newUpdatedPeriod,
      "index",
      true,
    );

    if (indexOf > -1) {
      newUpdatedPeriod[indexOf] = {
        id: periodId,
        value,
        index: periodIndex,
      };
    }

    return newUpdatedPeriod;
  };

  const handleUpdatePeriodTarget = (actualId, value, periodIndex) => {
    const { actual = [] } = goalDescriptions?.target;

    setGoalDescriptions({
      ...goalDescriptions,
      target: {
        ...goalDescriptions.target,
        actual: getUpdatedPeriod(actualId, periodIndex, value, actual),
      },
    });
  };

  const handleUpdateDistribution = (distId, value, periodIndex) => {
    const { distribution = [] } = goalDescriptions?.target;

    setGoalDescriptions({
      ...goalDescriptions,
      target: {
        ...goalDescriptions.target,
        distribution: getUpdatedPeriod(
          distId,
          periodIndex,
          value,
          distribution,
        ),
      },
    });
  };

  const handleUpdateLastPeriod = (lastPeriodId, value, periodIndex) => {
    const { lastPeriod = [] } = goalDescriptions?.target;

    setGoalDescriptions({
      ...goalDescriptions,
      target: {
        ...goalDescriptions.target,
        lastPeriod: getUpdatedPeriod(
          lastPeriodId,
          periodIndex,
          value,
          lastPeriod,
        ),
      },
    });
  };

  const headTitleTdStyles = {
    fontSize: "10px",
    textAlign: "center",
    padding: "10px",
  };

  const isDateMeasurement = goalDescriptions.measurementUnity === "DATE";

  const hideDistribution =
    isDateMeasurement === "DATE" || goalDescriptions.frequency === "YEARLY";
  const hideLP = isDateMeasurement || hideLastPeriod;
  const hideCurrentDevation = isDateMeasurement;

  const { total: totalDistributionSum } =
    getPeriodsDistributionInfo(goalDescriptions);

  const targetsDivision = getPeriodTargetsDivision(
    goalDescriptions,
    totalDistributionSum,
  );

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td />
          {!hideLP && (
            <td colSpan="2" style={{ borderRight: "1px solid #ccc" }}>
              {translation("kpi_table_text_prev_period")}
            </td>
          )}
          <td colSpan="3">
            {translation("kpi_table_text_performance_scale_period")}
          </td>
          <td colSpan={!hideCurrentDevation ? "6" : "5"}>
            {translation("kpi_table_text_current_period")}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr className="block-white-space border-bottom-division">
          <td style={headTitleTdStyles}>
            {translation("kpi_table_text_period")}
          </td>
          {!hideLP && (
            <>
              <td style={headTitleTdStyles}>
                {translation("kpi_table_text_value")}
              </td>
              <td
                style={headTitleTdStyles}
                title={translation("kpi_table_text_lp_deviation_expn")}
                width="40px"
              >
                Δ
              </td>
            </>
          )}
          <td style={headTitleTdStyles}>
            {translation("kpi_table_text_gate")}
          </td>
          <td style={headTitleTdStyles}>
            {`* ${translation("kpi_table_text_appropriated")}`}
          </td>
          <td
            style={{
              ...headTitleTdStyles,
              borderRight: "1px solid #ccc",
            }}
          >
            {translation("kpi_table_text_exceeded")}
          </td>
          {!hideDistribution && (
            <td style={{ ...headTitleTdStyles, width: "80px" }}>
              % Distribuição
            </td>
          )}
          <td style={{ ...headTitleTdStyles, width: "150px" }}>
            {translation("kpi_table_text_actual")}
          </td>
          {!hideCurrentDevation && (
            <td
              style={headTitleTdStyles}
              title={translation("kpi_table_text_current_deviation_expn")}
              width="40px"
            >
              Δ
            </td>
          )}
          <td style={{ ...headTitleTdStyles, width: "100px" }}>
            {translation("kpi_table_text_score")}
          </td>
          <td
            style={{
              ...headTitleTdStyles,
              paddingLeft: "0px",
              paddingRight: "0px",
              width: "15px",
            }}
          >
            <i
              className="fas fa-bullseye"
              style={{ fontSize: "10px", color: "#888" }}
            />
          </td>
          {insightId > 0 && (
            <td style={{ textAlign: "center", width: "25px" }}>
              <i
                className="far fa-comments"
                title={translation("global_comments")}
              />
            </td>
          )}
        </tr>
        <DisplayPeriodEvaluations
          goalDescriptions={goalDescriptions}
          goalPercentage={periodPercentage}
          targetsConfig={targetsConfig}
          handleUpdatePeriodTarget={handleUpdatePeriodTarget}
          handleUpdateDistribution={handleUpdateDistribution}
          handleUpdateLastPeriod={handleUpdateLastPeriod}
          blockAllChanges={blockAllChanges}
          hideDistribution={hideDistribution}
          hideLastPeriod={hideLP}
          insight={insight}
          hideCurrentDevation={hideCurrentDevation}
          IS_CALCULATED={IS_CALCULATED}
        />
        {!isDateMeasurement && (
          <DisplayTotalLine
            goalDescriptions={goalDescriptions}
            targetsConfig={targetsConfig}
            displayTargets={displayCourseTargets}
            distribution={courseDistributionPercentage}
            totalTarget={displayTotal}
            lastYearTotal={lastYearCourseEvaluation}
            lastYearTotalDisplay={lastYearCourseTotal}
            totalPercentage={periodPercentage}
            notStarted={notStarted}
            hideDistribution={hideDistribution}
            hideLastPeriod={hideLP}
            totalLabel={translation("kpi_table_text_total_period")}
            showCommentCol={insightId > 0}
            hideCurrentDevation={hideCurrentDevation}
          />
        )}
        <DisplayTotalLine
          goalDescriptions={goalDescriptions}
          targetsConfig={targetsConfig}
          displayTargets={targetsDivision}
          distribution={totalDistributionSum}
          totalTarget={displayTotal}
          lastYearTotal={lastYearTotalEvaluation}
          lastYearTotalDisplay={lastYearTotal}
          totalPercentage={accumulated && accumulated.goalPercentage}
          notStarted={notStarted}
          hideDistribution={hideDistribution}
          hideLastPeriod={hideLP}
          totalLabel={translation("kpi_table_text_total_acum")}
          invalidDistribution={checkError(["distributionError"], formErrors)}
          showCommentCol={insightId > 0}
          hideCurrentDevation={hideCurrentDevation}
        />
      </tbody>
    </table>
  );
};

export default IndicatorDisplayTable;
