import React from "react";
import styled from "styled-components";
import LinkedIcon from "./LinkedIcon";

const Message = styled.div`
  text-align: center;
  margin: 15px 0px;

  div.box,
  span {
    background-color: #f1f1f1;
    padding: 5px;
    margin: 15px 0px;
    border: 1px solid #ccc;
  }

  span {
    background-color: #c4e16c;
  }
`;

const LinkedMessage = ({ cantEdit = false }) => {
  return (
    <Message>
      <div className="box">
        <LinkedIcon
          text="Meta conectada com indicadores. Para alterar os valores navegue até a
        ferramenta Indicadores neste mesmo workspace."
        />
      </div>
      {!cantEdit && (
        <span>
          Aqui você pode alterar o{" "}
          <b>Peso, Relacionamento e adicionar Participantes</b>.
        </span>
      )}
    </Message>
  );
};

export default LinkedMessage;
