const en = {
  kpi_main_title: "Performance",
  kpi_add_new_scenario: "Add scenario",
  kpi_add_new_theme: "Add theme",
  kpi_add_new_question: "Add subtheme",
  kpi_add_new_insight: "Add indicator",
  kpi_count_goals: "N° Indicators",
  kpi_tab_administration: "Administration",
  kpi_tab_administration_title: "PROGRAM ADMINISTRATION",
  kpi_tab_administration_config: "Settings",
  kpi_tab_administration_planning: "Planning",
  kpi_tab_administration_indicators: "Indicators",
  kpi_tab_administration_metrics: "Metrics",
  kpi_tab_administration_subthemes: "Subthemes",
  kpi_tab_management_details: "Details",
  kpi_sub_title_config: "PERFORMANCE CONFIGURATIONS",
  kpi_sub_title_period_config: "PERIOD SETTING",
  kpi_sub_title_guardians: "PROCESS GUARDIANS",
  kpi_sub_title_themes: "REGISTRATION OF THEMES",
  kpi_sub_title_subthemes: "REGISTRATION OF SUBTHEMES",
  kpi_sub_title_scenario: "REGISTRATION OF SCENARIO",
  kpi_sub_title_indicators: "INDICATORS",
  kpi_sub_title_metrics: "METRICS",
  kpi_subtitle_goal_description: "Indicator Description",
  kpi_subtitle_goal_measurement: "Indicator Measurement",
  kpi_table_text_goals: "Indicators",
  kpi_table_text_actions: "Actions",
  kpi_table_text_themes: "Themes",
  kpi_table_text_themes_upper: "THEMES",
  kpi_table_text_subthemes: "Subthemes",
  kpi_table_text_subthemes_upper: "SUBTHEMES",
  kpi_table_text_indicators: "Indicators",
  kpi_table_text_indicators_upper: "INDICATORS",
  kpi_table_text_target: "Target",
  kpi_table_text_target_upper: "TARGET",
  kpi_table_text_results_upper: "RESULTS",
  kpi_table_text_performance_scale: "Performance Scale (accumulated goal)",
  kpi_table_text_performance_scale_period: "Performance Scale (goal)",
  kpi_table_text_gate: "Gate",
  kpi_table_text_appropriated: "Planned",
  kpi_table_text_exceeded: "Exceeded",
  kpi_table_text_actual: "Current",
  kpi_table_text_description: "Description",
  kpi_table_text_main_roles: "MAIN ROLES",
  kpi_table_text_role: "FUNCTION",
  kpi_table_text_no_role: "No functions registered",
  kpi_table_text_involved: "INVOLVED",
  kpi_table_text_accesses: "ACCESS",
  kpi_table_text_can_view: "CAN VIEW",
  kpi_table_text_no_subthemes: "No registered subthemes",
  kpi_table_text_period: "Period",
  kpi_table_text_period_upper: "PERIOD",
  kpi_table_text_prev_period: "Last Period",
  kpi_table_text_value: "Value",
  kpi_table_text_current_period: "Current Period",
  kpi_table_text_current_deviation_expn:
    "Percentage of Variation in comparison with the appropriate and the reached value.",
  kpi_table_text_lp_deviation_expn:
    "Percentage of Change compared to the current period for the previous period.",
  kpi_table_text_scenarios: "Scenarios",
  kpi_table_text_scenarios_upper: "SCENARIOS",
  kpi_table_text_goal: "Indicator",
  kpi_table_text_goal_upper: "INDICATOR",
  kpi_table_text_score: "Score",
  kpi_table_text_no_goal: "No indicator entered",
  kpi_table_text_no_goal_found: "No indicators found",
  kpi_table_text_no_goals_evaluation: "INDICATOR EVALUATION",
  kpi_table_text_relationship: "Relationship",
  kpi_table_text_status: "Status",
  kpi_table_text_date: "Date",
  kpi_table_text_dates_period: "Period",
  kpi_table_text_year: "Year",
  kpi_table_text_total_acum: "Accumulated total",
  kpi_table_text_total_period: "Period total",
  kpi_table_text_chart: "Chart",
  kpi_form_add_role: "Add new role",
  kpi_form_change_role: "Change role",
  kpi_form_today: "Today",
  kpi_form_year: "Year",
  kpi_form_end_year: "End year",
  kpi_form_initial_month: "Start Month",
  kpi_form_last_month: "End Month",
  kpi_form_data_source: "Data Source",
  kpi_form_general_observations: "General Remarks",
  kpi_form_unity: "Un",
  kpi_form_unity_ext: "Unit of Measure",
  kpi_form_type: "Type",
  kpi_form_frequency: "Frequency",
  kpi_form_measurement: "Measurement",
  kpi_form_validation: "Validation",
  kpi_form_title: "Title",
  kpi_form_description: "Description",
  kpi_form_purpose: "Purpose",
  kpi_form_leader: "Leader",
  kpi_form_message: "Message",
  kpi_form_role: "Function",
  kpi_form_involved: "Involved",
  kpi_form_indicator_already_connected: "Active Indicator",
  kpi_form_accesses: "Accesses",
  kpi_form_member: "Member",
  kpi_form_select_scenario: "Select a Cenary",
  kpi_form_select_theme: "Select a Theme",
  kpi_form_select_subtheme: "Select a Subtheme",
  kpi_form_select_goal: "Select a Indicator",
  kpi_form_select_goals: "Select Indicators",
  kpi_message_goal_starting: "Goal will start {date}",
  kpi_message_goal_started: "Goal started {date}",
  kpi_message_goal_ending: "Goal Closes {date}",
  kpi_message_goal_ended: "Goal has been closed {date}",
  kpi_message_select_theme: "Select a Theme to continue",
  kpi_dialog_title_adm_roles: "Add new role",
  kpi_dialog_title_new_scenario: "Add new Scenario and Purpose",
  kpi_dialog_title_new_theme: "Add new Theme",
  kpi_dialog_title_new_subtheme: "Add new Subtheme",
  kpi_dialog_title_new_goal: "Add new Indicator",
  kpi_dialog_title_new_metric: "Add new Metric",
  kpi_dialog_title_edit_metric: "Edit Metric",
  kpi_dialog_title_edit_scenario: "Change Scenario",
  kpi_dialog_title_edit_theme: "Change Theme",
  kpi_dialog_title_edit_subtheme: "Change Subtheme",
  kpi_dialog_title_edit_goal: "Change Indicator",
  kpi_dialog_title_select_involved: "Select those involved in the role",
  kpi_dialog_title_select_member: "Select a member",
  kpi_error_small_title: "Title field must contain more characters",
  kpi_error_repeated_title: "There is already an indicator with this title",
  kpi_error_years_limit:
    "The maximum limit of difference between the Start Year and End Year is 5 years",
  kpi_error_years_invalid:
    "Enter valid values for the Start Year and End Year fields",
  kpi_error_small_description: "Description field must contain more characters",
  kpi_error_block_relationship:
    "It will not be possible to change the relationship of this indicator, there are {length} subthemes connected with this goal.",
  kpi_error_repeated_target: "The target field cannot have repeated values.",
  kpi_error_invalid_target: "Target field contains inverted/incongruent values",
  kpi_error_invalid_date: "Start date must be earlier than end date.",
  kpi_error_invalid_same_month:
    "The maximum number of months must be equal to 12.",
  kpi_error_invalid_distribution: "The target distribution should total 100%",
  kpi_error_scenario_already_added:
    "This Scenario has already been registered on the Board",
  kpi_error_theme_already_added:
    "The Theme ''{group}'' has already been registered on the Board",
  kpi_error_goal_already_added:
    "Indicator ''{title}'' has already been added to this subtheme",
  kpi_error_individual_goal_added:
    "Indicator ''{title}'' has already been added to another subtheme",
  kpi_error_subtheme_already_added:
    "The subtheme ''{process}'' has already been added",
  kpi_confirm_delete_role: "Are you sure you want to delete this function?",
  kpi_confirm_delete_goal: "Are you sure you want to delete this goal?",
  kpi_confirm_delete_subtheme: "Are you sure you want to delete this Subtheme?",
  kpi_confirm_delete_scenario: "Are you sure you want to delete this Scenario?",
  kpi_confirm_delete_theme: "Are you sure you want to delete this Theme?",
  kpi_confirm_archive_all: "Archive all",
  kpi_warning_remove_subtheme_title:
    "There are subthemes related to this theme",
  kpi_warning_connected_scenario_cenary:
    "This scenario is registered on the Board.",
  kpi_warning_connected_goal_insight:
    "There is an insight connected with this goal.",
  kpi_warning_connected_subtheme_question:
    "This subtheme is registered on the Board.",
  kpi_warning_connected_theme_theme: "This Theme is registered on the Board.",
  kpi_warning_insights_will_delete:
    "Upon confirmation, all insights listed below will be archived",
  kpi_warning_need_arquive_cenary:
    "You need to archive the scenario ''{cenaryName}'' registered on the Board to continue deleting.",
  kpi_warning_need_arquive_theme:
    "You need to archive the theme ''{themeName}'' registered on the Board to continue deleting.",
  kpi_warning_need_arquive_question:
    "You must file the subtheme ''{questionName}'' registered on the Board to continue deleting.",
  kpi_warning_need_min_administrator:
    "At least 1 administrator must be registered.",
  kpi_options_period_desc_quarterly: "Quarter",
  kpi_options_period_desc_four_months: "Four months",
  kpi_options_period_desc_semiannual: "Semester",
  kpi_options_period_desc_yearly: "Year",
  kpi_options_rt_shared: "Aggregated",
  kpi_options_rt_individual: "Individual",
  kpi_options_rt_calculated: "Calculated",
  kpi_options_mu_monetary_value: "Amount ($)",
  kpi_options_mu_thousand_monetary_value: "Amount x 1000 ($ thousand)",
  kpi_options_mu_million_monetary_value: "Value x 1M ($ mi)",
  kpi_options_mu_quantity: "Quantity",
  kpi_options_mu_percentage: "Percent (%)",
  kpi_options_mu_date: "Date",
  kpi_options_mu_index: "Index",
  kpi_options_gt_indicator: "Indicator",
  kpi_options_gt_project: "Project",
  kpi_options_gt_option: "Option",
  kpi_options_fq_monthly: "Monthly",
  kpi_options_fq_quarterly: "Quarterly",
  kpi_options_fq_four_months: "Four months",
  kpi_options_fq_semiannual: "Semiannual",
  kpi_options_fq_yearly: "Yearly",
  kpi_options_mr_sum: "Sum",
  kpi_options_mr_average: "Average",
  kpi_options_mr_balance: "Balance",
  kpi_options_gt_not_hit: "Not reached",
  kpi_options_gt_gate: "Gate",
  kpi_options_gt_appropriated: "Planned",
  kpi_options_gt_exceeded: "Exceeded",
  kpi_options_pa_admin: "Administrator",
  kpi_options_pa_auditor: "Auditor",
  kpi_options_pa_registrations: "Registrations",
  kpi_options_pa_analytics: "Analysis",
  kpi_acp_table_indicators: "Actions by indicator",
  kpi_filter_opt1: "Mínimo, Planejado, Superado e Atual de cada Meta.",
  kpi_filter_opt2: "% de Score atingido.",
  kpi_filter_opt3: "% de Variação (Δ).",
  kpi_filter_opt4: "Valores do ano anterior.",
};

const es = {
  kpi_main_title: "Performance",
  kpi_add_new_scenario: "Agregar escenario",
  kpi_add_new_theme: "Agregar tema",
  kpi_add_new_question: "Agregar subtema",
  kpi_add_new_insight: "Agregar indicador",
  kpi_count_goals: "N° Indicadores",
  kpi_tab_administration: "Administración",
  kpi_tab_administration_title: "ADMINISTRACIÓN DEL PROGRAMA",
  kpi_tab_administration_config: "Configuración",
  kpi_tab_administration_planning: "Planificación",
  kpi_tab_administration_indicators: "Indicadores",
  kpi_tab_administration_metrics: "Métrica",
  kpi_tab_administration_subthemes: "Subtemas",
  kpi_tab_management_details: "Detalles",
  kpi_sub_title_config: "CONFIGURACIÓN DE DESEMPEÑO",
  kpi_sub_title_period_config: "AJUSTE DEL PERÍODO",
  kpi_sub_title_guardians: "TUTORES DE PROCESO",
  kpi_sub_title_themes: "REGISTRO DE TEMAS",
  kpi_sub_title_subthemes: "REGISTRO DE SUBTEMAS",
  kpi_sub_title_scenario: "REGISTRO DE ESCENARIOS",
  kpi_sub_title_indicators: "INDICADORES",
  kpi_sub_title_metrics: "MÉTRICA",
  kpi_subtitle_goal_description: "Descripción del indicador",
  kpi_subtitle_goal_measurement: "Medición del indicador",
  kpi_table_text_goals: "Indicadores",
  kpi_table_text_actions: "Acciones",
  kpi_table_text_themes: "Temas",
  kpi_table_text_themes_upper: "TEMAS",
  kpi_table_text_subthemes: "Subtemas",
  kpi_table_text_subthemes_upper: "SUBTEMAS",
  kpi_table_text_indicators: "Indicadores",
  kpi_table_text_indicators_upper: "INDICADORES",
  kpi_table_text_target: "Target",
  kpi_table_text_target_upper: "TARGET",
  kpi_table_text_results_upper: "RESULTADOS",
  kpi_table_text_performance_scale: "Escala de rendimiento (meta acumulada)",
  kpi_table_text_performance_scale_period: "Escala de rendimiento (meta)",
  kpi_table_text_gate: "Puerta",
  kpi_table_text_appropriated: "Planificado",
  kpi_table_text_exceeded: "Excedido",
  kpi_table_text_actual: "Actual",
  kpi_table_text_description: "Descripción",
  kpi_table_text_main_roles: "PRINCIPALES FUNCIONES",
  kpi_table_text_role: "FUNCTION",
  kpi_table_text_no_role: "No hay funciones registradas",
  kpi_table_text_involved: "INVOLVED",
  kpi_table_text_accesses: "ACCESOS",
  kpi_table_text_can_view: "PUEDE VER",
  kpi_table_text_no_subthemes: "No hay subtemas registrados",
  kpi_table_text_period: "Periodo",
  kpi_table_text_period_upper: "PERIODO",
  kpi_table_text_prev_period: "Período Anterior",
  kpi_table_text_value: "Valor",
  kpi_table_text_current_period: "Período Actual",
  kpi_table_text_current_deviation_expn:
    "Porcentaje de variación en comparación con la cantidad adecuada alcanzada.",
  kpi_table_text_lp_deviation_expn:
    "Porcentaje de cambio en comparación con el período actual para el período anterior.",
  kpi_table_text_scenarios: "Escenarios",
  kpi_table_text_scenarios_upper: "ESCENARIOS",
  kpi_table_text_goal: "Indicadores",
  kpi_table_text_goal_upper: "INDICADORES",
  kpi_table_text_score: "Score",
  kpi_table_text_no_goal: "Ningún indicador registrado",
  kpi_table_text_no_goal_found: "No se encontró ningún indicador",
  kpi_table_text_no_goals_evaluation: "EVALUACIÓN DE INDICADORES",
  kpi_table_text_relationship: "Relación",
  kpi_table_text_status: "Estado",
  kpi_table_text_date: "Fecha",
  kpi_table_text_dates_period: "Período",
  kpi_table_text_year: "Año",
  kpi_table_text_total_acum: "Total acumulado",
  kpi_table_text_total_period: "Total periodo",
  kpi_table_text_chart: "Gráfico",
  kpi_form_add_role: "Agregar nueva función",
  kpi_form_change_role: "Cambiar rol",
  kpi_form_today: "Hoy",
  kpi_form_year: "Año",
  kpi_form_end_year: "Año final",
  kpi_form_initial_month: "Mes de inicio",
  kpi_form_last_month: "Mes final",
  kpi_form_data_source: "Fuente de datos",
  kpi_form_general_observations: "Observaciones generales",
  kpi_form_unity: "Un",
  kpi_form_unity_ext: "Unidad de medida",
  kpi_form_type: "Tipo",
  kpi_form_frequency: "Frecuencia",
  kpi_form_measurement: "Medición",
  kpi_form_validation: "Validación",
  kpi_form_title: "Titulo",
  kpi_form_description: "Descripción",
  kpi_form_purpose: "Propósito",
  kpi_form_leader: "Líder",
  kpi_form_message: "Mensaje",
  kpi_form_role: "Función",
  kpi_form_involved: "Involucrado",
  kpi_form_indicator_already_connected: "Indicador Activo",
  kpi_form_accesses: "Accesos",
  kpi_form_member: "Miembro",
  kpi_form_select_scenario: "Seleccione un Escenario",
  kpi_form_select_theme: "Seleccione un Tema",
  kpi_form_select_subtheme: "Seleccione un Subtema",
  kpi_form_select_goal: "Seleccione un Indicador",
  kpi_form_select_goals: "Seleccionar Indicadores",
  kpi_message_goal_starting: "El objetivo comenzará {date}",
  kpi_message_goal_started: "El objetivo ha comenzado {date}",
  kpi_message_goal_ending: "Objetivo de cierre {date}",
  kpi_message_goal_ended: "El objetivo se ha cerrado {date}",
  kpi_message_select_theme: "Seleccione un Tema para continuar",
  kpi_dialog_title_adm_roles: "Agregar nueva función",
  kpi_dialog_title_new_scenario: "Agregar nuevo Escenario y Propósito",
  kpi_dialog_title_new_theme: "Agregar nuevo Tema",
  kpi_dialog_title_new_subtheme: "Agregar nuevo Subtema",
  kpi_dialog_title_new_goal: "Registrar nuevo Indicador",
  kpi_dialog_title_new_metric: "Registrar nueva Métrica",
  kpi_dialog_title_edit_metric: "Cambiar Métrica",
  kpi_dialog_title_edit_scenario: "Cambiar Escenario",
  kpi_dialog_title_edit_theme: "Cambiar Tema",
  kpi_dialog_title_edit_subtheme: "Cambiar Subtema",
  kpi_dialog_title_edit_goal: "Cambiar Indicador",
  kpi_dialog_title_select_involved: "Seleccione los involucrados en el rol",
  kpi_dialog_title_select_member: "Seleccione un miembro",
  kpi_error_small_title: "El campo del título debe contener más caracteres",
  kpi_error_repeated_title: "Ya existe un indicador con ese título",
  kpi_error_years_limit:
    "El límite máximo de diferencia entre el año de inicio y el año final es de 5 años.",
  kpi_error_years_invalid:
    "Ingrese valores válidos para los campos Año de inicio y Año final",
  kpi_error_small_description:
    "El campo de descripción debe contener más caracteres",
  kpi_error_block_relationship:
    "No será posible cambiar la relación de este indicador, ya que hay {length} subtemas conectados a este objetivo",
  kpi_error_repeated_target:
    "El campo de target no puede tener valores repetidos",
  kpi_error_invalid_target:
    "El campo de target contiene valores invertidos/incongruentes",
  kpi_error_invalid_date:
    "La fecha de inicio debe ser menor que la fecha de finalización.",
  kpi_error_invalid_same_month:
    "El número máximo de meses debe ser igual a 12.",
  kpi_error_invalid_distribution:
    "La distribución de target debe totalizar 100%",
  kpi_error_scenario_already_added:
    "Este Escenario ya se ha colocado en el Tablero",
  kpi_error_theme_already_added:
    "O Tema ''{group}'' ya se ha colocado en el Tablero",
  kpi_error_goal_already_added:
    "El indicador ''{title}'' ya se ha agregado a este subtema",
  kpi_error_individual_goal_added:
    "El indicador ''{title}'' ya se ha agregado a otro subtema",
  kpi_error_subtheme_already_added:
    "O subtema ''{process}'' ya ha sido agregado",
  kpi_confirm_delete_role: "¿Está seguro de que desea eliminar esta función?",
  kpi_confirm_delete_goal: "¿Está seguro de que desea eliminar este objetivo?",
  kpi_confirm_delete_subtheme:
    "¿Estás seguro de que deseas eliminar a este Subtema?",
  kpi_confirm_delete_scenario:
    "¿Está seguro de que desea eliminar este Escenario?",
  kpi_confirm_delete_theme: "¿Está seguro de que desea eliminar este Tema?",
  kpi_confirm_archive_all: "Archivar todo",
  kpi_warning_remove_subtheme_title: "Hay subtemas relacionados con este tema",
  kpi_warning_connected_scenario_cenary:
    "Este Escenario se registra en el Tablero.",
  kpi_warning_connected_goal_insight:
    "Hay una insight relacionada con este objetivo",
  kpi_warning_connected_subtheme_question:
    "Este Subtema se registra en el Tablero.",
  kpi_warning_connected_theme_theme: "Este Tema se registra en el Tablero",
  kpi_warning_insights_will_delete:
    "Tras la confirmación, se archivarán todas las insights enumeradas a continuación",
  kpi_warning_need_arquive_cenary:
    "Debe archivar el escenario ''{cenaryName}'' en el Tablero para continuar eliminando.",
  kpi_warning_need_arquive_theme:
    "Debe archivar el tema ''{themeName}'' en el Tablero para continuar eliminando.",
  kpi_warning_need_arquive_question:
    "Debe presentar lo subtema ''{questionName}'' en el Tablero para continuar eliminando.",
  kpi_warning_need_min_administrator:
    "Es necesario que al menos 1 administrador esté registrado",
  kpi_options_period_desc_quarterly: "Quarter",
  kpi_options_period_desc_four_months: "Cuatro meses",
  kpi_options_period_desc_semiannual: "Semestre",
  kpi_options_period_desc_yearly: "Año",
  kpi_options_rt_shared: "Agregado",
  kpi_options_rt_individual: "Individual",
  kpi_options_rt_calculated: "Calculado",
  kpi_options_mu_monetary_value: "Valor ($)",
  kpi_options_mu_thousand_monetary_value: "Valor x 1000 ($ mil)",
  kpi_options_mu_million_monetary_value: "Valor x 1M ($ mi)",
  kpi_options_mu_quantity: "Cantidad",
  kpi_options_mu_percentage: "Porcentaje (%)",
  kpi_options_mu_date: "Fecha",
  kpi_options_mu_index: "Tasa",
  kpi_options_gt_indicator: "Indicador",
  kpi_options_gt_project: "Proyecto",
  kpi_options_gt_option: "Opción",
  kpi_options_fq_monthly: "Mensual",
  kpi_options_fq_quarterly: "Trimestralmente",
  kpi_options_fq_four_months: "Cuatro meses",
  kpi_options_fq_semiannual: "Semestral",
  kpi_options_fq_yearly: "Anual",
  kpi_options_mr_sum: "Suma",
  kpi_options_mr_average: "Promedio",
  kpi_options_mr_balance: "Balance",
  kpi_options_gt_not_hit: "No golpeado",
  kpi_options_gt_gate: "Puerta",
  kpi_options_gt_appropriated: "Planificado",
  kpi_options_gt_exceeded: "Excedido",
  kpi_options_pa_admin: "Administrador",
  kpi_options_pa_auditor: "Auditor",
  kpi_options_pa_registrations: "Registros",
  kpi_options_pa_analytics: "Analytics",
  kpi_acp_table_indicators: "Acciones por indicador",
  kpi_filter_opt1: "Mínimo, Planificado, Superado e Atual de cada Meta.",
  kpi_filter_opt2: "% de Score atingido.",
  kpi_filter_opt3: "% de Variação (Δ).",
  kpi_filter_opt4: "Valores do ano anterior.",
};

const pt = {
  kpi_main_title: "Performance",
  kpi_add_new_scenario: "Adicionar cenário",
  kpi_add_new_theme: "Adicionar tema",
  kpi_add_new_question: "Adicionar subtema",
  kpi_add_new_insight: "Adicionar indicador",
  kpi_count_goals: "N° Indicadores",
  kpi_tab_administration: "Administração",
  kpi_tab_administration_title: "ADMINISTRAÇÃO DO PROGRAMA",
  kpi_tab_administration_config: "Configurações",
  kpi_tab_administration_planning: "Planejamento",
  kpi_tab_administration_indicators: "Indicadores",
  kpi_tab_administration_metrics: "Métricas",
  kpi_tab_administration_subthemes: "Subtemas",
  kpi_tab_management_details: "Detalhes",
  kpi_sub_title_config: "CONFIGURAÇÕES DE DESEMPENHO",
  kpi_sub_title_period_config: "CONFIGURAÇÃO DO PERÍODO",
  kpi_sub_title_guardians: "GUARDIÕES DO PROCESSO",
  kpi_sub_title_themes: "CADASTRO DOS TEMAS",
  kpi_sub_title_subthemes: "CADASTRO DOS SUBTEMAS",
  kpi_sub_title_scenario: "CADASTRO DE CENÁRIOS",
  kpi_sub_title_indicators: "INDICADORES",
  kpi_sub_title_metrics: "MÉTRICAS",
  kpi_subtitle_goal_description: "Descrição do Indicador",
  kpi_subtitle_goal_measurement: "Medição do Indicador",
  kpi_table_text_goals: "Indicadores",
  kpi_table_text_actions: "Ações",
  kpi_table_text_themes: "Temas",
  kpi_table_text_themes_upper: "TEMAS",
  kpi_table_text_subthemes: "Subtemas",
  kpi_table_text_subthemes_upper: "SUBTEMAS",
  kpi_table_text_indicators: "Indicadores",
  kpi_table_text_indicators_upper: "INDICADORES",
  kpi_table_text_target: "Alvo",
  kpi_table_text_target_upper: "ALVO",
  kpi_table_text_results_upper: "RESULTADOS",
  kpi_table_text_performance_scale: "Escala de Desempenho (meta acumulada)",
  kpi_table_text_performance_scale_period: "Escala de Desempenho (meta)",
  kpi_table_text_gate: "Mínimo",
  kpi_table_text_appropriated: "Planejado",
  kpi_table_text_exceeded: "Superado",
  kpi_table_text_actual: "Atual",
  kpi_table_text_description: "Descrição",
  kpi_table_text_main_roles: "PRINCIPAIS PAPÉIS",
  kpi_table_text_role: "FUNÇÃO",
  kpi_table_text_no_role: "Nenhuma função cadastrada",
  kpi_table_text_involved: "ENVOLVIDOS",
  kpi_table_text_accesses: "ACESSOS",
  kpi_table_text_can_view: "PODE VISUALIZAR",
  kpi_table_text_no_subthemes: "Nenhum subtema cadastrado",
  kpi_table_text_period: "Período",
  kpi_table_text_period_upper: "PERÍODO",
  kpi_table_text_prev_period: "Período Anterior",
  kpi_table_text_value: "Valor",
  kpi_table_text_current_period: "Período Atual",
  kpi_table_text_current_deviation_expn:
    "Percentual de Variação em comparação com o planejado pelo valor atingido.",
  kpi_table_text_lp_deviation_expn:
    "Percentual de Variação em comparação com o período atual pelo período anterior.",
  kpi_table_text_scenarios: "Cenários",
  kpi_table_text_scenarios_upper: "CENÁRIOS",
  kpi_table_text_goal: "Indicador",
  kpi_table_text_goal_upper: "INDICADOR",
  kpi_table_text_score: "Score",
  kpi_table_text_no_goal: "Nenhum indicador cadastrado",
  kpi_table_text_no_goal_found: "Nenhum indicador encontrado",
  kpi_table_text_no_goals_evaluation: "AVALIAÇÃO INDICADORES",
  kpi_table_text_relationship: "Relacionamento",
  kpi_table_text_status: "Status",
  kpi_table_text_date: "Data",
  kpi_table_text_dates_period: "Período",
  kpi_table_text_year: "Ano",
  kpi_table_text_total_acum: "Total acumulado",
  kpi_table_text_total_period: "Total período",
  kpi_table_text_chart: "Gráfico",
  kpi_form_add_role: "Adicionar nova função",
  kpi_form_change_role: "Alterar função",
  kpi_form_today: "Hoje",
  kpi_form_year: "Ano",
  kpi_form_end_year: "Ano de fim",
  kpi_form_initial_month: "Mês inicial",
  kpi_form_last_month: "Mês final",
  kpi_form_data_source: "Fonte de dados",
  kpi_form_general_observations: "Observações gerais",
  kpi_form_unity: "Un",
  kpi_form_unity_ext: "Unidade de medida",
  kpi_form_type: "Tipo",
  kpi_form_frequency: "Periodicidade",
  kpi_form_measurement: "Medição",
  kpi_form_validation: "Validação",
  kpi_form_title: "Título",
  kpi_form_description: "Descrição",
  kpi_form_purpose: "Propósito",
  kpi_form_leader: "Líder",
  kpi_form_message: "Mensagem",
  kpi_form_role: "Função",
  kpi_form_involved: "Envolvidos",
  kpi_form_indicator_already_connected: "Indicador Ativo",
  kpi_form_accesses: "Acessos",
  kpi_form_member: "Membro",
  kpi_form_select_scenario: "Selecione um Cenário",
  kpi_form_select_theme: "Selecione um Tema",
  kpi_form_select_subtheme: "Selecione um Subtema",
  kpi_form_select_goal: "Selecione um Indicador",
  kpi_form_select_goals: "Selecione os Indicadores",
  kpi_message_goal_starting: "A meta iniciará {date}",
  kpi_message_goal_started: "A meta foi iniciada {date}",
  kpi_message_goal_ending: "Fechamento da meta {date}",
  kpi_message_goal_ended: "A meta foi fechada {date}",
  kpi_message_select_theme: "Selecione um Tema para continuar",
  kpi_dialog_title_adm_roles: "Adicionar nova função",
  kpi_dialog_title_new_scenario: "Adicionar novo Cenário e Propósito",
  kpi_dialog_title_new_theme: "Adicionar novo Tema",
  kpi_dialog_title_new_subtheme: "Adicionar novo Subtema",
  kpi_dialog_title_new_goal: "Cadastrar novo Indicador",
  kpi_dialog_title_new_metric: "Cadastrar nova Métrica",
  kpi_dialog_title_edit_metric: "Alterar Métrica",
  kpi_dialog_title_edit_scenario: "Alterar Cenário",
  kpi_dialog_title_edit_theme: "Alterar Tema",
  kpi_dialog_title_edit_subtheme: "Alterar Subtema",
  kpi_dialog_title_edit_goal: "Alterar Indicador",
  kpi_dialog_title_select_involved: "Selecione os involvidos na função",
  kpi_dialog_title_select_member: "Selecione um membro",
  kpi_error_small_title: "O campo título deve conter mais caracteres",
  kpi_error_repeated_title: "Já existe um indicator com este título",
  kpi_error_years_limit:
    "O limite máximo de diferença entre o Ano de Início e Ano de Fim é de 5 anos",
  kpi_error_years_invalid:
    "Digite valores validos para os campos de Ano de Início e Ano de Fim",
  kpi_error_small_description: "O campo descrição deve conter mais caracteres",
  kpi_error_block_relationship:
    "Não será possível alterar o relacionamento desse indicador, pois existem {length} subtemas conectados com esta meta.",
  kpi_error_repeated_target: "O campo target não pode ter valores repetidos.",
  kpi_error_invalid_target:
    "O campo target contém valores invertidos/incongruentes",
  kpi_error_invalid_date: "A data inicial deve ser menor que a data final.",
  kpi_error_invalid_same_month: "O número máximo de meses deve ser igual à 12.",
  kpi_error_invalid_distribution:
    "A distribuição de targets deve totalizar 100%",
  kpi_error_scenario_already_added: "Esse Cenário já foi inserido no Quadro",
  kpi_error_theme_already_added: "O Tema ''{group}'' já foi inserido no Quadro",
  kpi_error_goal_already_added:
    "O indicador ''{title}'' já foi adicionada à este subtema",
  kpi_error_individual_goal_added:
    "O indicador ''{title}'' já foi adicionada à outro subtema",
  kpi_error_subtheme_already_added: "O subtema ''{process}'' já foi adicionado",
  kpi_confirm_delete_role: "Tem certeza que deseja excluir essa função?",
  kpi_confirm_delete_goal: "Tem certeza que deseja excluir essa meta?",
  kpi_confirm_delete_subtheme: "Tem certeza que deseja excluir esse Subtema?",
  kpi_confirm_delete_scenario: "Tem certeza que deseja excluir esse Cenário?",
  kpi_confirm_delete_theme: "Tem certeza que deseja excluir esse Tema?",
  kpi_confirm_archive_all: "Arquivar tudo",
  kpi_warning_remove_subtheme_title:
    "Existem subtemas relacionados com esse tema",
  kpi_warning_connected_scenario_cenary:
    "Este Cenário está registrado no Quadro.",
  kpi_warning_connected_goal_insight:
    "Existe um insight conectado com esta meta.",
  kpi_warning_connected_subtheme_question:
    "Este Subtema está registrado no Quadro.",
  kpi_warning_connected_theme_theme: "Este Tema está registrado no Quadro.",
  kpi_warning_insights_will_delete:
    "Ao confirmar, todos os insights listados abaixo serão arquivados",
  kpi_warning_need_arquive_cenary:
    "Você precisa arquivar o cenário ''{cenaryName}'' registrado no Quadro para continuar com a exclusão.",
  kpi_warning_need_arquive_theme:
    "Você precisa arquivar o tema ''{themeName}'' registrado no Quadro para continuar com a exclusão.",
  kpi_warning_need_arquive_question:
    "Você precisa arquivar o subtema ''{questionName}'' registrado no Quadro para continuar com a exclusão.",
  kpi_warning_need_min_administrator:
    "É necessário que ao menos 1 administrador esteja cadastrado.",
  kpi_options_period_desc_quarterly: "Trimestre",
  kpi_options_period_desc_four_months: "Quadrimestre",
  kpi_options_period_desc_semiannual: "Semestre",
  kpi_options_period_desc_yearly: "Ano",
  kpi_options_rt_shared: "Agregado",
  kpi_options_rt_individual: "Individual",
  kpi_options_rt_calculated: "Calculado",
  kpi_options_mu_monetary_value: "Valor ($)",
  kpi_options_mu_thousand_monetary_value: "Valor x 1000 ($ mil)",
  kpi_options_mu_million_monetary_value: "Valor x 1M ($ mi)",
  kpi_options_mu_quantity: "Quantidade",
  kpi_options_mu_percentage: "Porcentagem (%)",
  kpi_options_mu_date: "Data",
  kpi_options_mu_index: "Índice",
  kpi_options_gt_indicator: "Indicador",
  kpi_options_gt_project: "Projeto",
  kpi_options_gt_option: "Opção",
  kpi_options_fq_monthly: "Mensal",
  kpi_options_fq_quarterly: "Trimestral",
  kpi_options_fq_four_months: "Quadrimestre",
  kpi_options_fq_semiannual: "Semestral",
  kpi_options_fq_yearly: "Anual",
  kpi_options_mr_sum: "Soma",
  kpi_options_mr_average: "Média",
  kpi_options_mr_balance: "Saldo",
  kpi_options_gt_not_hit: "Não atingido",
  kpi_options_gt_gate: "Mínimo",
  kpi_options_gt_appropriated: "Planejado",
  kpi_options_gt_exceeded: "Superado",
  kpi_options_pa_admin: "Administrador",
  kpi_options_pa_auditor: "Auditor",
  kpi_options_pa_registrations: "Cadastros",
  kpi_options_pa_analytics: "Análises",
  kpi_acp_table_indicators: "Ações por indicador",
  kpi_filter_opt1: "Mínimo, Planejado, Superado e Atual de cada Meta.",
  kpi_filter_opt2: "% de Score atingido.",
  kpi_filter_opt3: "% de Variação (Δ).",
  kpi_filter_opt4: "Valores do ano anterior.",
};

const translations = { en, es, pt };

export default translations;
