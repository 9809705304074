import React from "react";
import PropTypes from "prop-types";
import {
  getSkillsWorkflowAverage,
  getMaximumScale,
  getCompetencesWorkflowAverage,
  getProfilesWorkflowAverage,
  getTotalWorkflowAverage,
} from "../../../../../../utils/skillsAssessment/calc";
import GeneralEvaluationCharts from "./includes/GeneralEvaluationCharts";
import EvaluationWorkflowTable from "./includes/EvaluationWorkflowTable";
import { validateRoleSkillRelation } from "../../../../../../utils/skillsAssessment/validation";
import { getErrorMessage } from "../../../../../../utils/formValidation";
import { getCompetenceConfigByComposition } from "../../../../../../utils/skillsAssessment/constants";

const CompetenceOverview = ({ toolAdministration }) => {
  const {
    evaluationScale,
    potentialScale,
    roleSkillRelation,
    composition,
    evaluationType,
    evaluationWeights,
    generalOptions,
  } = toolAdministration;

  const { skillsAverage, workflowBySkills } =
    getSkillsWorkflowAverage(toolAdministration);

  const { average: totalAverage } = getTotalWorkflowAverage(
    workflowBySkills,
    roleSkillRelation,
    evaluationType,
    evaluationWeights,
  );

  const { competencesAverage } =
    getCompetencesWorkflowAverage(toolAdministration);

  const { profilesAverage } = getProfilesWorkflowAverage(toolAdministration);

  const maxScale = getMaximumScale(evaluationScale);
  const maxPotential = getMaximumScale(potentialScale);

  const { enableDesiredEvaluation } =
    getCompetenceConfigByComposition(composition);
  const getRoleSkillConsistencyValidationRow = () => {
    const errors = validateRoleSkillRelation(roleSkillRelation);
    const showErrors = errors.length > 0 && enableDesiredEvaluation;

    return (
      showErrors && (
        <div
          className="row"
          style={{
            border: "1px solid #ccc",
            marginBottom: "20px",
            padding: "15px",
          }}
          align="center"
        >
          {getErrorMessage(
            ["relation_inconsistency", "relation_inconsistency_complement"],
            errors,
            "center",
          )}
        </div>
      )
    );
  };

  return (
    <div className="competence-overview-container">
      <div>
        <GeneralEvaluationCharts
          maxScale={maxScale}
          maxPotential={maxPotential}
          composition={composition}
          totalAverage={totalAverage}
          generalOptions={generalOptions}
        />
        {enableDesiredEvaluation && getRoleSkillConsistencyValidationRow()}
      </div>
      <div style={{ padding: "5px 0px 5px 0px" }}>
        <EvaluationWorkflowTable
          listTitle="ÁREA DE COMPETÊNCIA"
          workflowAverage={competencesAverage}
          maxScale={maxScale}
          maxPotential={maxPotential}
          composition={composition}
          generalOptions={generalOptions}
        />
      </div>
      <div style={{ padding: "5px 0px 5px 0px" }}>
        <EvaluationWorkflowTable
          listTitle="COMPETÊNCIA"
          workflowAverage={skillsAverage}
          maxScale={maxScale}
          maxPotential={maxPotential}
          composition={composition}
          generalOptions={generalOptions}
        />
      </div>
      <div style={{ padding: "5px 0px 5px 0px" }}>
        <EvaluationWorkflowTable
          listTitle="PERFIL"
          workflowAverage={profilesAverage}
          maxScale={maxScale}
          maxPotential={maxPotential}
          composition={composition}
          generalOptions={generalOptions}
        />
      </div>
    </div>
  );
};

CompetenceOverview.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
};

export default CompetenceOverview;
