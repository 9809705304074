/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import _ from "lodash";
import { List, ListItem, ListItemText, Avatar } from "@material-ui/core";
import FormDialog from "./FormDialog";
import {
  getObjectInfoById,
  getFilteredObjectsList,
  getAllExcept,
  compareStrings,
  getOnlySlugValues,
} from "../../utils/ArrayUtils";
import MaterialTextField from "./MaterialTextField";
import { stringShorter } from "../../utils/StringUtils";
import SimpleCheckbox from "./SimpleCheckbox";
import SimpleManagementList from "./SimpleManagementList";
import { Button } from "react-bootstrap";

const messages = defineMessages({
  noDataToDisplay: {
    id: "global.noDataTable",
  },
  globalSearch: {
    id: "global.search",
  },
  globalAdd: {
    id: "global.add",
  },
});

const defaultBreadcrumb = [{ levelIndex: 0, parentId: null }];
const defaultFilter = {
  string: "",
};

const SelectTreeModal = (props) => {
  const [displayTree, setDisplayTree] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [initialPreset, setInitialPreset] = useState(false);
  const [selectedTree, setSelectedTree] = useState({
    level: null,
    parentId: null,
  });
  const [parentBreadcrumb, setParentBreadcrumb] = useState(defaultBreadcrumb);
  const [filter, setFilter] = useState(defaultFilter);
  const [showSelectedDetails, setShowSelectedDetails] = useState(true);

  const {
    open,
    title,
    description,
    treeList,
    treeOrder,
    onConfirm,
    onCancel,
    singleSelect,
    initialSelected,
    resetWhenOpen,
    intl,
    modalTextCancel,
    modalTextConfirm,
    dialogClassName,
    canSelectAnyLevel,
    bottomSelectionLabel,
    bottomNoSelectedItems,
    createIfNotPresented = false,
    bottomMessage,
  } = props;

  const lastTreeLevelOrder = treeOrder[treeOrder.length - 1];
  const lastDisplayTree = displayTree[lastTreeLevelOrder];

  const showSingleSelectedItem = canSelectAnyLevel && singleSelect;
  const showOnlyLastLevelSelection = !canSelectAnyLevel && !singleSelect;
  const showMultipleSelectionLevels = canSelectAnyLevel && !singleSelect;

  useEffect(() => {
    if (open) {
      const treeFirstLevel = treeOrder[0];

      if (treeOrder && treeList) {
        setSelectedTree({
          level: treeFirstLevel,
          parentId: null,
        });
        setParentBreadcrumb(defaultBreadcrumb);
      }

      if (resetWhenOpen) setSelectedItems([]);
    } else {
      setInitialPreset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, resetWhenOpen, treeOrder]);

  useEffect(() => {
    if (!_.isEqual(treeList, displayTree)) setDisplayTree(treeList);
  }, [treeList, displayTree]);

  useEffect(() => {
    if (initialSelected && initialSelected.length > 0 && !initialPreset) {
      setSelectedItems(initialSelected);
      setInitialPreset(true);
    }
  }, [initialSelected, initialPreset]);

  useEffect(() => {
    if (filter.string && filter.string !== "") {
      setFilter(defaultFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTree]);

  const getTreeByIndex = (treeIndex) => {
    if (treeOrder[treeIndex] && displayTree[treeOrder[treeIndex]]) {
      return displayTree[treeOrder[treeIndex]];
    }

    return {};
  };

  const getTreeParentIndex = (childTreeIndex) => {
    return childTreeIndex > 0 ? childTreeIndex - 1 : null;
  };

  const getCurrentTreeIndex = () => {
    const treeIndex = treeOrder.indexOf(selectedTree.level);
    return { treeIndex, parentIndex: getTreeParentIndex(treeIndex) };
  };

  const getSelectedTree = () => {
    const { treeIndex } = getCurrentTreeIndex();

    return getTreeByIndex(treeIndex);
  };

  const getCurrentTreeParentTree = () => {
    const { parentIndex } = getCurrentTreeIndex();

    return getTreeByIndex(parentIndex);
  };

  const getItemOnSelectedTree = (itemId) => {
    const selectedTreeData = getSelectedTree();

    return getObjectInfoById(itemId, selectedTreeData.list, "id");
  };

  const getCurrentSelectedParentItem = () => {
    let selectedParentItem = {};

    if (selectedTree.parentId) {
      const parentTree = getCurrentTreeParentTree();

      if (parentTree.list) {
        selectedParentItem = getObjectInfoById(
          selectedTree.parentId,
          parentTree.list,
          "id",
        );
      }
    }

    return selectedParentItem;
  };

  const getFilteredTreeBySelectedList = (treeLevel, selectedItems) => {
    let finalItems = [];

    if (displayTree[treeLevel] && displayTree[treeLevel].list) {
      const treeListClone = _.cloneDeep(displayTree[treeLevel].list);
      treeListClone.forEach((treeItem) => {
        treeItem.show = selectedItems.indexOf(treeItem.id) > -1;

        finalItems = [...finalItems, treeItem];
      });
    }

    return finalItems;
  };

  const getSelectedTreeItems = () => {
    return getFilteredTreeBySelectedList(lastTreeLevelOrder, selectedItems);
  };

  const getHigherLevelExeptions = (lowerLevel) => {
    const higherLevels = [];

    if (lowerLevel >= 0) {
      for (let i = lowerLevel; i >= 0; i--) {
        higherLevels.push(i);
      }
    }

    return higherLevels;
  };

  const getFilteredChildsByParentId = (treeParentId, list) => {
    const filtered = [];

    if (Array.isArray(list)) {
      list.forEach((childItemInfo) => {
        if (childItemInfo.parentId === treeParentId)
          filtered.push(childItemInfo);
      });
    }

    return filtered;
  };

  const getFilteredDisplayTree = (searchFilter = true) => {
    const currentSelectedTree = getSelectedTree();
    const parentFilteredList = filterLevel(
      selectedTree.parentId,
      currentSelectedTree.list,
    );
    const filteredTree = searchFilter
      ? filterBySearch(parentFilteredList)
      : parentFilteredList;

    return filteredTree || [];
  };

  const isLastLevelSelected = (orderLevel) => {
    return lastTreeLevelOrder === orderLevel;
  };

  const simpleCheckIsSelectedById = (itemId) => {
    const onlySelectedIds = getOnlySlugValues(selectedItems, "id");

    return onlySelectedIds.indexOf(itemId) > -1;
  };

  const handleUpdateFilter = (field, newValue) => {
    if (field) {
      setFilter({ ...filter, [field]: newValue });
    }
  };

  const handleToggleSelect = (treeItemID) => {
    let finalList = selectedItems;
    const appendItems = !singleSelect ? [...selectedItems] : [];
    const appendNewItemValue = getItemOnSelectedTree(treeItemID);

    if (checkIsItemSelected(treeItemID, lastTreeLevelOrder)) {
      finalList = !singleSelect
        ? getAllExcept([treeItemID], [...selectedItems], "id")
        : [];
    } else if (appendNewItemValue) {
      finalList = [...appendItems, getItemOnSelectedTree(treeItemID)];
    }

    setSelectedItems(finalList);
  };

  const handleBreadcrumbClick = (slug, parentId) => {
    const newLevelIndex = treeOrder.indexOf(slug);

    if (newLevelIndex > -1) {
      const keepLevels = getHigherLevelExeptions(newLevelIndex);

      const updatedBreadcrumb = getFilteredObjectsList(
        keepLevels,
        [...parentBreadcrumb],
        "levelIndex",
      );

      setParentBreadcrumb(updatedBreadcrumb);
      setSelectedTree({ level: slug, parentId });

      if (singleSelect) setSelectedItems([]);
    }
  };

  const handleToggleAddToList = (newItem, onlyAdd = false) => {
    if (newItem && newItem.id) {
      if (simpleCheckIsSelectedById(newItem.id) === false) {
        setSelectedItems([...selectedItems, newItem]);
      } else if (!onlyAdd) {
        const newSelectedItems = getAllExcept(
          [newItem.id],
          [...selectedItems],
          "id",
        );

        setSelectedItems(newSelectedItems);
      }
    }
  };

  const handleToggleSelectAll = (checked) => {
    const filteredTree = getFilteredDisplayTree(false);

    if (checked) {
      const alreadySelectedItens = getOnlySlugValues(selectedItems, "id");
      const onlyNewItens = getAllExcept(
        alreadySelectedItens,
        filteredTree,
        "id",
      );

      setSelectedItems([...selectedItems, ...onlyNewItens]);
    } else {
      const removeItemIds = getOnlySlugValues(filteredTree, "id");
      const newSelectedListRemovedItems = getAllExcept(
        removeItemIds,
        selectedItems,
        "id",
      );

      setSelectedItems(newSelectedListRemovedItems);
    }
  };

  const filterLevel = (parentId = null, list) => {
    if (Array.isArray(list))
      list.forEach((info, index) => {
        list[index].show = true;
      });

    return getFilteredChildsByParentId(parentId, list);
  };

  const filterBySearch = (currentTree) => {
    let filteredList = [];

    if (filter.string && filter.string !== "" && currentTree) {
      currentTree.forEach((itemInfo) => {
        const show = compareStrings(filter.string, itemInfo.title);
        filteredList.push({ ...itemInfo, show });
      });
    } else {
      filteredList = currentTree || [];
    }

    return filteredList;
  };

  const selectNextTreeLevel = (selectedItemInfo = {}) => {
    const nextLevelParentId = selectedItemInfo.id;
    const currentLevelIndexOf = treeOrder.indexOf(selectedTree.level);
    const nextLevel = currentLevelIndexOf + 1;

    if (treeOrder[nextLevel]) {
      setSelectedTree({
        level: treeOrder[nextLevel],
        parentId: nextLevelParentId,
      });

      setParentBreadcrumb([
        ...parentBreadcrumb,
        { levelIndex: nextLevel, parentId: nextLevelParentId },
      ]);
    }
  };

  const checkIsItemSelected = (checkID, treeLevel) => {
    return (
      [...selectedItems.map(({ id }) => id)].indexOf(checkID) > -1 &&
      treeLevel === lastTreeLevelOrder
    );
  };

  const calcTotalDisplayed = (list) => {
    let total = 0;

    list.forEach((treeItemInfo) => {
      total = treeItemInfo.show ? total + 1 : total;
    });

    return total;
  };

  const getItemTrackingBreadcrumb = (parentItemId, treeLevel) => {
    const currentItemIndex = treeOrder.indexOf(treeLevel);

    const finalBreadcrumbs = [];
    let ctrlBuildBreadcrumb = 0;
    const childsParentIds = [];
    let finalPathString = "";

    if (currentItemIndex > 0) {
      childsParentIds.push(parentItemId);
      for (let i = currentItemIndex - 1; i >= 0; i--) {
        const parentLevelList = displayTree[treeOrder[i]].list;

        for (const index in parentLevelList) {
          const parentList = parentLevelList[index];
          if (parentList.id === childsParentIds[ctrlBuildBreadcrumb]) {
            finalBreadcrumbs.push(parentList.title);
            childsParentIds.push(parentList.parentId);
            ctrlBuildBreadcrumb++;
            break;
          }
        }
      }
    }

    finalBreadcrumbs.reverse();

    finalBreadcrumbs.forEach((path, index) => {
      finalPathString += path;

      if (finalBreadcrumbs.length !== index + 1) finalPathString += " > ";
    });

    return finalPathString;
  };

  const getBreadcrumbsByCurrentTree = () => {
    const breadcrumbs = [];

    parentBreadcrumb.forEach((selectedLevelInfo) => {
      const { levelIndex, parentId } = selectedLevelInfo;
      const orderSlug = treeOrder[levelIndex];
      const parentOrderSlug = treeOrder[levelIndex - 1];
      const treeLevelInfo = displayTree[orderSlug];
      const parentTreeInfo = displayTree[parentOrderSlug] || {};

      if (treeLevelInfo) {
        const treeParentInfo = getObjectInfoById(
          parentId,
          parentTreeInfo.list,
          "id",
        );

        breadcrumbs.push({
          label: parentId === null ? treeLevelInfo.label : treeParentInfo.title,
          color: treeLevelInfo.color,
          slug: orderSlug,
          parentId,
        });
      }
    });

    return breadcrumbs;
  };

  const displayBreadcrumbs = () => {
    const breadcrumbs = getBreadcrumbsByCurrentTree();
    const finalBreadcrumbs = [];

    breadcrumbs.forEach((info, index) => {
      const isLastItem = breadcrumbs.length === index + 1;

      finalBreadcrumbs.push(
        <>
          <a
            onClick={() =>
              isLastItem
                ? null
                : handleBreadcrumbClick(info.slug, info.parentId)
            }
            className="breadcrumb-button"
            style={{
              color: isLastItem ? "#fff" : info.color,
              cursor: isLastItem ? "default" : "pointer",
              pointerEvents: isLastItem ? "none" : "all",
              backgroundColor: isLastItem ? info.color : "#f9f9f9",
            }}
          >
            {info.label}
          </a>
          {!isLastItem ? (
            <span style={{ padding: "0px 10px" }}>
              <i className="fas fa-chevron-right" />
            </span>
          ) : null}
        </>,
      );
    });

    return finalBreadcrumbs.map((item, index) => (
      <React.Fragment key={index}>{item}</React.Fragment>
    ));
  };

  const displaySelecionItem = (treeLevel, treeItemInfo, showBorder = true) => {
    const currentSelectedTree = displayTree[treeLevel];
    const checkLastLevelSelected = isLastLevelSelected(treeLevel);
    const alreadySelected =
      simpleCheckIsSelectedById(treeItemInfo.id) ||
      checkIsItemSelected(treeItemInfo.id, treeLevel);

    const avatarInfo = {
      title: currentSelectedTree.label,
      icon: currentSelectedTree.icon,
      color: currentSelectedTree.color,
    };

    const borderColor =
      alreadySelected && showBorder ? avatarInfo.color : "transparent";

    const displayStyle = treeItemInfo.show
      ? {
          opacity: "1",
          height: "62px",
          padding: "0px",
        }
      : {
          opacity: "0",
          height: "0px",
          padding: "0px",
          pointerEvents: "none",
        };

    const onClickListItem = checkLastLevelSelected
      ? () => handleToggleSelect(treeItemInfo.id)
      : () => selectNextTreeLevel(treeItemInfo);
    const shortedTitle = stringShorter(treeItemInfo.title, 47);
    const itemBreadcrumb = getItemTrackingBreadcrumb(
      treeItemInfo.parentId,
      treeLevel,
    );

    return (
      <ListItem
        className="list-item-selection"
        style={{
          width: "100%",
          borderColor,
          cursor: "default",
          backgroundColor: "#fff",
          ...displayStyle,
        }}
      >
        <div style={{ display: "flex", width: "100%", height: "100%" }}>
          {showMultipleSelectionLevels && (
            <SimpleCheckbox
              checked={alreadySelected}
              onChange={() => handleToggleAddToList(treeItemInfo)}
              size="medium"
              styles={{ margin: "0px 0px 0px 10px" }}
            />
          )}
          <div
            onClick={() => (treeItemInfo.show ? onClickListItem() : null)}
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              padding: "5px 15px",
              cursor: "pointer",
            }}
            className="simple-hover"
          >
            <Avatar
              style={{
                backgroundColor: avatarInfo.color,
                marginRight: "5px",
                fontSize: "18px",
              }}
              title={avatarInfo.title}
            >
              {avatarInfo.icon}
            </Avatar>
            <ListItemText
              primary={shortedTitle}
              secondary={itemBreadcrumb}
              primaryTypographyProps={{
                style: {
                  fontSize: "16px",
                },
                title: treeItemInfo.title,
              }}
              secondaryTypographyProps={{
                style: {
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                },
                title: itemBreadcrumb,
              }}
            />
          </div>
        </div>
      </ListItem>
    );
  };

  const displaySelectedItems = () => {
    const filteredSelectedTree = getSelectedTreeItems();

    return filteredSelectedTree.map((treeItemInfo, index) => {
      return (
        <React.Fragment key={index}>
          {displaySelecionItem(
            lastTreeLevelOrder,
            treeItemInfo,
            showMultipleSelectionLevels,
          )}
        </React.Fragment>
      );
    });
  };

  const displayCheckAll = () => {
    const filteredTree = getFilteredDisplayTree(false);
    const filteredIds = getOnlySlugValues(filteredTree, "id");
    const allSelected = getFilteredObjectsList(
      filteredIds,
      selectedItems,
      "id",
    );

    const isAllSelected =
      allSelected.length === filteredIds.length && filteredTree.length > 0;

    return (
      <div
        className="col-xs-12"
        style={{ borderBottom: "1px solid #ccc", paddingLeft: "18px" }}
      >
        <SimpleCheckbox
          label="Selecionar todos"
          checked={isAllSelected}
          onChange={({ checked }) => handleToggleSelectAll(!checked)}
          disabled={filteredTree.length === 0}
        />
      </div>
    );
  };

  const getRealSelectedItems = () => {
    if (singleSelect && canSelectAnyLevel) {
      const selectedParent = getCurrentSelectedParentItem();
      const selectedParentReturn = selectedParent.id ? [selectedParent] : [];

      return selectedItems.length > 0 ? selectedItems : selectedParentReturn;
    }

    return selectedItems;
  };

  const displayBottomSelectedItems = (showLabel = true) => {
    const selectedParent = getCurrentSelectedParentItem();
    const lastChildItems = getSelectedTreeItems();
    const selectedItemID = selectedItems[0] ? selectedItems[0].id : null;
    const childItemInfo = getObjectInfoById(
      selectedItemID,
      lastChildItems,
      "id",
    );

    return selectedParent.id ? (
      <font>
        {showLabel && `${bottomSelectionLabel}: `}
        <b>
          {childItemInfo.id && !showMultipleSelectionLevels
            ? childItemInfo.title
            : selectedParent.title}
        </b>
      </font>
    ) : (
      <font>{bottomNoSelectedItems}</font>
    );
  };

  const displaySelectedItens = () => {
    if (selectedItems.length > 0 && showSelectedDetails) {
      return (
        <div
          style={{
            border: "1px solid #ccc",
            margin: "10px 0px",
            width: "100%",
          }}
        >
          <SimpleManagementList
            items={selectedItems}
            handleRemove={(itemInfo) => handleToggleAddToList(itemInfo)}
          />
        </div>
      );
    }

    return null;
  };

  const getAddedItemsListBottom = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <a
          style={{ display: "block", marginTop: "10px", cursor: "pointer" }}
          onClick={() => setShowSelectedDetails(!showSelectedDetails)}
        >{`${selectedItems.length} itens adicionados à lista`}</a>
        {displaySelectedItens()}
      </div>
    );
  };

  const showCreateButton = () => {
    return (
      <div align="center" style={{ padding: "7px" }}>
        <Button onClick={() => onConfirm(filter.string)}>
          {intl.formatMessage(messages.globalAdd)}
        </Button>
      </div>
    );
  };

  const getOverflowByLength = (itensLength) => {
    const itemHeight = 65;
    const maxHeight = 325;

    return itemHeight * itensLength >= maxHeight ? "auto" : "hidden";
  };

  const filteredList = getFilteredDisplayTree();
  const totalFiltered = calcTotalDisplayed(filteredList);
  const realSelectedItems = getRealSelectedItems();

  return (
    <FormDialog
      open={open}
      title={title}
      description={description}
      toggleOpen={onCancel}
      onConfirm={() => onConfirm(realSelectedItems)}
      onCancel={onCancel}
      confirmText={modalTextConfirm}
      cancelText={modalTextCancel}
      blockConfirm={realSelectedItems.length === 0}
      bodyStyle={{
        padding: "0px",
      }}
      dialogClassName={`select-tree-dialog${dialogClassName}`}
    >
      <div className="row" style={{ borderBottom: "1px solid #ccc" }}>
        {props.enableSearch && (
          <div className="col-xs-12">
            <MaterialTextField
              id="searchTree"
              label={intl.formatMessage(messages.globalSearch)}
              value={filter.string}
              onChange={(e) => handleUpdateFilter("string", e.target.value)}
            />
          </div>
        )}
        <div
          className="col-xs-12"
          style={{
            margin: "0px",
            borderBottom: "1px solid #ccc",
            padding: "10px 15px",
          }}
        >
          {displayBreadcrumbs()}
        </div>
        {showMultipleSelectionLevels && displayCheckAll()}
        <div
          className={!showOnlyLastLevelSelection ? "col-xs-12" : "col-xs-6"}
          style={{
            maxHeight: "335px",
            overflow: getOverflowByLength(totalFiltered),
            borderRight: "2px solid #eee",
            padding: "0px",
          }}
        >
          {filteredList.length > 0 && (
            <List>
              {filteredList.map((treeItemInfo, index) => {
                return (
                  <React.Fragment key={index}>
                    {displaySelecionItem(selectedTree.level, treeItemInfo)}
                  </React.Fragment>
                );
              })}
            </List>
          )}
          {totalFiltered === 0 && (
            <>
              <h5 align="center" style={{ color: "#666" }}>
                {intl.formatMessage(messages.noDataToDisplay)}
              </h5>
              {createIfNotPresented && showCreateButton()}
            </>
          )}
        </div>
        {showOnlyLastLevelSelection && (
          <div className="col-xs-6" style={{ padding: "0px" }}>
            {lastDisplayTree && (
              <h5
                style={{
                  color: "#666",
                  borderBottom: "1px solid #ccc",
                  padding: "0px 8px",
                  margin: "0px",
                  height: "30px",
                  lineHeight: "30px",
                }}
              >
                {`${selectedItems.length} ${lastDisplayTree.countText}`}
              </h5>
            )}
            <div
              style={{
                maxHeight: "304px",
                overflow: getOverflowByLength(selectedItems.length),
              }}
            >
              <List disablePadding>{displaySelectedItems()}</List>
            </div>
          </div>
        )}
      </div>
      {showSingleSelectedItem && (
        <div className="row">
          <div
            style={{
              textAlign: "center",
              padding: "6px 0px",
              borderBottom: "1px solid #ccc",
            }}
          >
            {displayBottomSelectedItems()}
          </div>
        </div>
      )}
      {showMultipleSelectionLevels && (
        <div className="row" style={{ padding: "10px 0px" }}>
          <div
            className="col-xs-12"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {getAddedItemsListBottom()}
          </div>
        </div>
      )}
      {bottomMessage}
    </FormDialog>
  );
};

SelectTreeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
  treeList: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      list: PropTypes.array.isRequired,
      color: PropTypes.string.isRequired,
      icon: PropTypes.any.isRequired,
      countText: PropTypes.string.isRequired,
    }),
  ).isRequired,
  treeOrder: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  singleSelect: PropTypes.bool,
  initialSelected: PropTypes.arrayOf(PropTypes.object),
  resetWhenOpen: PropTypes.bool,
  dialogClassName: PropTypes.string,
  canSelectAnyLevel: PropTypes.bool,
  enableSearch: PropTypes.bool,
  bottomSelectionLabel: PropTypes.string,
  bottomNoSelectedItems: PropTypes.string,
  modalTextConfirm: PropTypes.node,
  modalTextCancel: PropTypes.node,
  bottomMessage: PropTypes.node,
};

SelectTreeModal.defaultProps = {
  initialSelected: [],
  treeOrder: [],
  treeList: {},
  onCancel: () => {},
  singleSelect: false,
  resetWhenOpen: true,
  dialogClassName: "",
  canSelectAnyLevel: false,
  enableSearch: true,
  bottomSelectionLabel: "Itens selecionados",
  bottomNoSelectedItems: "Nenhum item selecionado",
  modalTextConfirm: "defaultConfirm",
  modalTextCancel: "defaultCancel",
  bottomMessage: null,
};

export default injectIntl(SelectTreeModal);
