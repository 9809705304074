import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import moment from "moment";
import FormDialog from "../../FormDialog";
import MaterialTextField from "../../MaterialTextField";
import GoalDescription from "../insight/GoalDescription";
import GoalEvaluation from "../insight/GoalEvaluation";

import { saveOrUpdateGoal } from "../../../../actions/goalsAndBonusActions";
import { getValidFunc } from "../../../../utils/validations";
import gbUtils from "../../../../utils/goalsBonus";
import { getSelectedToolStates } from "../../../customMapStates";
import LinkedMessage from "../includes/LinkedMessage";

const {
  getGoalInfoById,
  getNewGoalDefaultOptions,
  getNewGoalErrors,
  checkIsGoalEditable,
  getTopLevelUserAccess,
  checkDatesPeriodBetween,
} = gbUtils;

const messages = defineMessages({
  gb_dialog_title_new_goal: { id: "gb_dialog_title_new_goal" },
  gb_dialog_title_edit_goal: { id: "gb_dialog_title_edit_goal" },
  gb_form_title: { id: "gb_form_title" },
  gb_table_text_registrations: { id: "gb_table_text_registrations" },
  gb_warning_period_between_blocked: {
    id: "gb_warning_period_between_blocked",
  },
  global_register: { id: "global.register" },
  global_update: { id: "global.update" },
  global_goBack: { id: "global.goBack" },
});

const NewGoalDialog = (props) => {
  const {
    open = false,
    goalBonusAdministration,
    editingForm = {},
    intl,
    allAnswers,
    allQuestions,
    selectedTool,
    enableNewParticipants = false,
    getToolConfig,
  } = props;

  const { periodCycle } = goalBonusAdministration;

  const defaultNewGoalForm = getNewGoalDefaultOptions(periodCycle);
  const [form, setForm] = useState(defaultNewGoalForm);
  const [addToQuestions, setAddToQuestions] = useState([]);

  const afterCancel = getValidFunc(props.afterCancel);
  const userAccess = getTopLevelUserAccess();

  const onlyParticipantAccess =
    userAccess.accesses.indexOf("OWN_GOALS") > -1 &&
    userAccess.accesses.indexOf("ADMIN") === -1;

  useEffect(() => {
    if (
      editingForm &&
      editingForm.type === "newGoal" &&
      goalBonusAdministration
    ) {
      const goalIndex = getGoalInfoById(
        editingForm.params.goalId,
        goalBonusAdministration.goals,
        true,
      );
      const goalInfo = goalBonusAdministration.goals[goalIndex];

      if (goalInfo) {
        setForm({ ...goalInfo });
      }
    }
  }, [editingForm, goalBonusAdministration]);

  const resetForm = () => {
    setForm(defaultNewGoalForm);
  };

  const saveAndSetConfig = (newConfig) => {
    if (newConfig && selectedTool?.id)
      props.saveOrUpdateGoal(selectedTool.id, newConfig, form, addToQuestions);

    afterCancel();
  };

  const handleConfirm = async () => {
    const data = await getToolConfig();

    if (data && data.id) {
      if (editingForm.type !== "newGoal") {
        saveAndSetConfig({
          ...data,
          goals: [...data.goals, { ...form }],
        });
      } else {
        const goalIndex = getGoalInfoById(
          editingForm.params.goalId,
          data.goals,
          true,
        );
        const newUpdatedGoals = [...data.goals];
        newUpdatedGoals[goalIndex] = { ...form };

        saveAndSetConfig({
          ...data,
          goals: newUpdatedGoals,
        });
      }

      resetForm();
      afterCancel();
    }
  };

  const handleCancel = () => {
    resetForm();
    afterCancel();
  };

  const handleUpdateForm = (fieldSlug, newValue) => {
    setForm({ ...form, [fieldSlug]: newValue });
  };

  const handleAddToQuestions = (selectedParticipantQuestions) => {
    const haveRelatedInsights = form.relatedInsights.length > 0;
    const individualGoal = form.relationshipType === "INDIVIDUAL";

    if (!haveRelatedInsights || (haveRelatedInsights && !individualGoal)) {
      setAddToQuestions(selectedParticipantQuestions);
    }
  };

  const { dates = {} } = goalBonusAdministration;

  const isAdmin = !onlyParticipantAccess;
  const registrationsAllowed = checkDatesPeriodBetween(
    dates.startRegistrations,
    dates.endRegistrations,
  );

  const haveLinkedIndicator = form?.linkedIndicator?.id > 0;

  const isEditingAllowed =
    checkIsGoalEditable(
      form.id,
      form.workflowStatus,
      form.relationshipType,
      isAdmin,
    ) && !haveLinkedIndicator;

  const isEditingGoal = editingForm.type === "newGoal";

  const formErrors = getNewGoalErrors(form, isEditingGoal);

  const appendProps = {
    goalBonusAdministration,
    goalDescriptions: form,
    setGoalDescriptions: setForm,
    formErrors,
    goalTargetConfigurations: goalBonusAdministration.goalTargets,
    zeroWhenNegative: goalBonusAdministration.zeroWhenNegative,
    isAdmin,
    registrationsAllowed,
    onlyForm: true,
    allQuestions,
    allAnswers,
    haveLinkedIndicator,
  };

  return (
    <FormDialog
      open={open}
      title={
        !isEditingGoal
          ? intl.formatMessage(messages.gb_dialog_title_new_goal)
          : intl.formatMessage(messages.gb_dialog_title_edit_goal)
      }
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmText={
        isEditingGoal
          ? intl.formatMessage(messages.global_update)
          : intl.formatMessage(messages.global_register)
      }
      cancelText={intl.formatMessage(messages.global_goBack)}
      blockConfirm={formErrors.length > 0 || !registrationsAllowed}
      dialogClassName="new-goal-dialog"
    >
      <div className="row">
        {!registrationsAllowed && (
          <h5 className="col-xs-12 text-warning">
            <i className="fas fa-exclamation-triangle" />{" "}
            {intl.formatMessage(messages.gb_warning_period_between_blocked, {
              startDate: moment(dates.startRegistrations).format("L"),
              endDate: moment(dates.endRegistrations).format("L"),
              period: intl.formatMessage(messages.gb_table_text_registrations),
            })}
          </h5>
        )}
        {registrationsAllowed && (
          <>
            <div className="col-xs-12">
              {haveLinkedIndicator && <LinkedMessage />}
              <MaterialTextField
                id="goalTitle"
                label={intl.formatMessage(messages.gb_form_title)}
                value={form.title}
                onChange={
                  isEditingAllowed
                    ? (e) => handleUpdateForm("title", e.target.value)
                    : () => {}
                }
                inputStyle={!isEditingAllowed ? { cursor: "not-allowed" } : {}}
                primaryInputProps={{ maxLength: 255 }}
              />
            </div>
            <div className="row">
              <GoalDescription
                addToQuestions={addToQuestions}
                handleAddToQuestions={handleAddToQuestions}
                enableNewParticipants={enableNewParticipants}
                {...appendProps}
              />
              <br />
              <GoalEvaluation {...appendProps} />
            </div>
          </>
        )}
      </div>
    </FormDialog>
  );
};

NewGoalDialog.propTypes = {
  afterCancel: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

NewGoalDialog.defaultProps = {
  open: false,
};

const mapStateToProps = (state) => {
  const { selectedTool = {} } = getSelectedToolStates(state);

  return {
    selectedTool,
  };
};

export default injectIntl(
  connect(mapStateToProps, { saveOrUpdateGoal })(NewGoalDialog),
);
