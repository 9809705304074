export const rangeScaleOptions = [
  { value: null, label: null, string: "" },
  { value: 1, label: "1 (muito baixa)", string: "1" },
  { value: 2, label: "2 (baixa)", string: "2" },
  { value: 3, label: "3 (média)", string: "3" },
  { value: 4, label: "4 (alta)", string: "4" },
  { value: 5, label: "5 (muito alta)", string: "5" },
];

// diário, semanal, mensal, bimestral, trimestral, semestral, anual
export const periodicityOptions = [
  { value: null, label: null, string: "" },
  { value: "DAILY", label: "Diario" },
  { value: "WEEKLY", label: "Semanal" },
  { value: "MONTHLY", label: "Mensal" },
  { value: "BIMONTHLY", label: "Bimestral" },
  { value: "QUARTERLY", label: "Trimestral" },
  { value: "SEMESTERLY", label: "Semestral" },
  { value: "YEARLY", label: "Anual" },
];
