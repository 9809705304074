import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import FormDialog from "../../FormDialog";
import MaterialTextField from "../../MaterialTextField";
import SelectItemModal from "../../SelectItemModal";
import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import utils from "../../../../utils/toolUtils";
import { getAnalysisToolDialogs } from "../../../../utils/cpAnalysis/display";

const { getErrorMessage, getValidFunc, getObjectInfoById } = utils;

const useStyles = makeStyles(() =>
  createStyles({
    addButton: {
      fontSize: "12px",
    },
  }),
);

const messages = defineMessages({
  kpi_message_select_theme: { id: "kpi_message_select_theme" },
  kpi_error_theme_already_added: { id: "kpi_error_theme_already_added" },
  kpi_add_new_theme: { id: "kpi_add_new_theme" },
  kpi_form_select_theme: { id: "kpi_form_select_theme" },
  btn_modal_register_new: { id: "btn_modal_register_new" },
  global_select: { id: "global.select" },
});

const initialForm = {
  analysisAmbient: [],
};

const ThemeCreationAndLinkDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const [registerModal, setRegisterModal] = useState(false);
  const [selectAmbientModal, setSelectAmbientModal] = useState(false);

  const {
    open = false,
    afterCancel,
    afterConfirm,
    currentToolType,
    cpAnalysisAdministration,
    setCpAnalysisAdministration,
    addedAmbients,
    intl,
  } = props;

  const alternativeSaveCallback = getValidFunc(props.alternativeSaveCallback);

  const { NewAnalysisAmbientDialog } = getAnalysisToolDialogs(currentToolType);

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleConfirm = () => {
    if (typeof afterConfirm === "function") afterConfirm(form);
  };

  const handleCancel = () => {
    if (typeof afterCancel === "function") afterCancel();
  };

  const handleUpdateForm = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const buildAmbientOptions = (ambients) => {
    let finalOptions = [];

    if (ambients && ambients.length > 0) {
      ambients.forEach(
        ({ id, title }) =>
          addedAmbients.indexOf(id) === -1 && finalOptions.push({ id, title }),
      );
    }

    return finalOptions;
  };

  const updateSelected = (selected) => {
    handleUpdateForm("analysisAmbient", selected);

    toggleSelectAmbientModal(false);
  };

  const alternativeSave = (submitKpiConfig) => {
    toggleRegisterModal(false);
    alternativeSaveCallback(submitKpiConfig);
  };

  const toggleRegisterModal = (toggleTo = true) => {
    setRegisterModal(toggleTo);
  };

  const toggleSelectAmbientModal = (toggleTo = true) => {
    setSelectAmbientModal(toggleTo);
  };

  const getSelectedDisplay = (list) => {
    let displayTitles = [];

    const { analysisAmbient = [] } =
      cpAnalysisAdministration[currentToolType] || {};

    analysisAmbient.forEach((ambientInfo) => {
      if (list.indexOf(ambientInfo.id) > -1)
        displayTitles.push(ambientInfo.title);
    });

    return displayTitles.length > 0
      ? displayTitles.join(", ")
      : intl.formatMessage(messages.global_select);
  };

  const { analysisAmbient = [] } =
    cpAnalysisAdministration[currentToolType] || {};

  const ambientOptions = buildAmbientOptions(analysisAmbient);

  const classes = useStyles();

  return (
    <>
      <NewAnalysisAmbientDialog
        open={registerModal}
        cpAnalysisAdministration={cpAnalysisAdministration}
        setCpAnalysisAdministration={setCpAnalysisAdministration}
        afterConfirm={(submitKpiConfig) => alternativeSave(submitKpiConfig)}
        afterCancel={() => toggleRegisterModal(false)}
      />
      <SelectItemModal
        open={selectAmbientModal}
        itemsList={ambientOptions || []}
        onCancel={() => toggleSelectAmbientModal(false)}
        onConfirm={(selected) => {
          updateSelected(selected);
        }}
        title={intl.formatMessage(messages.kpi_form_select_theme)}
        initialSelected={form.analysisAmbient}
        replaceSelected
      />
      <FormDialog
        open={open}
        title={intl.formatMessage(messages.kpi_add_new_theme)}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialTextField
              id="ambientsSelect"
              label={intl.formatMessage(messages.kpi_form_select_theme)}
              value={getSelectedDisplay(form.analysisAmbient)}
              onClick={() => toggleSelectAmbientModal(true)}
              inputStyle={{ cursor: "pointer" }}
            />
            <Button
              variant="outlined"
              className={classes.addButton}
              onClick={() => toggleRegisterModal(!registerModal.open)}
            >
              <AddIcon /> {intl.formatMessage(messages.btn_modal_register_new)}
            </Button>
          </div>
        </div>
      </FormDialog>
    </>
  );
};

export default injectIntl(ThemeCreationAndLinkDialog);
