const en = {
  form_required_field: "This field is required",
  form_min_length: "Fill in the field with more characters",
  form_date_valid: "End date cannot be less than or equal to the start date",
  form_date_original_valid:
    "Start date must be greater than or equal to original",
};

const es = {
  form_required_field: "Este campo es requerido",
  form_min_length: "Rellena el campo con más caracteres",
  form_date_original_valid:
    "La fecha de inicio debe ser mayor o igual que la original",
  form_date_valid:
    "La fecha de finalización no puede ser menor o igual que la fecha de inicio",
};

const pt = {
  form_required_field: "Este campo é obrigatório",
  form_min_length: "Preecha o campo com mais caracteres",
  form_date_valid: "Data final não pode ser menor ou igual a data inicial",
  form_date_original_valid:
    "Data inicial tem que ser maior ou igual a original",
};

const translations = { en, es, pt };

export default translations;
