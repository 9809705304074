const en = {
  cpystr_company_area: "Occupation area",
  cpystr_company_areas_structure: "Structure of Company Areas",
  cpystr_company_areas_add: "Add area",
  cpystr_company_subareas_add: "Add subareas",
  cpystr_company_position: "Position",
  cpystr_company_positions_structure: "Company Position Structure",
  cpystr_company_positions_add: "Add position",
  cpystr_form_area_title: "Area Name",
  cpystr_form_position_title: "Position Title",
  cpystr_form_simple_title: "Title",
  cpystr_form_simple_description: "Description",
  cpystr_form_simple_active: "Active",
  cpystr_form_editing_area_info_title: "Edit area information",
  cpystr_form_editing_position_info_title: "Edit position information",
  cpystr_text_aditional_info: "Additional information",
  cpystr_dialog_position_delete_title:
    "Are you sure you want to remove this Position?",
  cpystr_dialog_position_delete_title_warning:
    "You need to remove all Positions below to remove this Position.",
  cpystr_dialog_position_delete_description:
    "The link between this Position, Related Users and Tool Settings will be removed.",
  cpystr_dialog_area_delete_title: "Are you sure you want to remove this Area?",
  cpystr_dialog_area_delete_title_warning:
    "You need to remove all Subareas below to remove this Area.",
  cpystr_dialog_area_delete_description:
    "The link between this Area, Related Users and Tool Settings will be removed.",
  cpystr_text_areas_title: "Areas",
  cpystr_text_positions_count: "Positions",
  cpystr_text_no_area: "No areas have been registered",
  cpystr_text_no_subarea: "No sub areas have been registered",
  cpystr_text_no_position: "No position has been registered",
  cpystr_text_no_position_bellow: "No positions bellow have been registered",
  cpystr_text_no_item_selected: "No items selected",
  cpystr_text_selected_item: "Selecte item",
  cpystr_text_select_some_area: "Select an Area",
  cpystr_text_select_the_area: "Select an Area",
  cpystr_text_select_the_position: "Select the Position",
  cpystr_option_active_on: "Active",
  cpystr_option_active_off: "Inactive",
  cpystr_error_area_exists:
    "There is already an area with that title registered",
  cpystr_error_subarea_exists:
    "There is already an sub area with that title registered",
  cpystr_error_position_exists:
    "There is already a position with that title registered",
  cpystr_confirmation_delete_user_area:
    "Are you sure you want to remove this user's Occupation Area?",
  cpystr_confirmation_delete_user_position:
    "Are you sure you want to remove this user's Position?",
  cpystr_error_invalid_title: "Invalid title",
};

const es = {
  cpystr_company_area: "Área de actuación",
  cpystr_company_areas_structure: "Estructura de las Áreas de la Empresa",
  cpystr_company_areas_add: "Agregar área",
  cpystr_company_subareas_add: "Agregar subáreas",
  cpystr_company_position: "Puesto",
  cpystr_company_positions_structure: "Estructura de Puestos de la Empresa",
  cpystr_company_positions_add: "Agregar puesto",
  cpystr_form_area_title: "Nombre del área",
  cpystr_form_position_title: "Título del puesto",
  cpystr_form_simple_title: "Título",
  cpystr_form_simple_description: "Descripción",
  cpystr_form_simple_active: "Activo",
  cpystr_form_editing_area_info_title: "Editar información del área",
  cpystr_form_editing_position_info_title: "Editar información del puesto",
  cpystr_text_aditional_info: "Información adicional",
  cpystr_dialog_position_delete_title:
    "¿Está seguro de que desea eliminar esta Posición?",
  cpystr_dialog_position_delete_title_warning:
    "Debe eliminar todas las posiciones a continuación para eliminar esta Posición.",
  cpystr_dialog_position_delete_description:
    "El vínculo entre este Puesto, los Usuarios Relacionados y la Configuración de las Herramientas se romperá.",
  cpystr_dialog_area_delete_title:
    "¿Está seguro de que desea eliminar esta Área?",
  cpystr_dialog_area_delete_title_warning:
    "Debe eliminar todas las subáreas a continuación para eliminar esta Área.",
  cpystr_dialog_area_delete_description:
    "El vínculo entre esta Área, los Usuarios Relacionados y la Configuración de las Herramientas se romperá.",
  cpystr_text_areas_title: "Áreas",
  cpystr_text_positions_count: "Puestos",
  cpystr_text_no_area: "No se han registrado areas",
  cpystr_text_no_subarea: "No se han registrado subáreas",
  cpystr_text_no_position: "No se agregaron puestos de actuación",
  cpystr_text_no_position_bellow: "No se han registrado posiciones abajo",
  cpystr_text_no_item_selected: "No hay elementos seleccionados",
  cpystr_text_selected_item: "Elemento seleccionado",
  cpystr_text_select_some_area: "Seleccione un Area",
  cpystr_text_select_the_area: "Seleccione el Area",
  cpystr_text_select_the_position: "Seleccione la Posición",
  cpystr_option_active_on: "Activo",
  cpystr_option_active_off: "Inactivo",
  cpystr_error_area_exists: "Ya hay un área con ese título registrado",
  cpystr_error_subarea_exists: "Ya hay un subárea con ese título registrado",
  cpystr_error_position_exists: "Ya hay un puesto con ese título registrado",
  cpystr_confirmation_delete_user_area:
    "¿Está seguro de que desea eliminar el Área de Actuación de este usuario?",
  cpystr_confirmation_delete_user_position:
    "¿Está seguro de que desea eliminar el Puesto de este usuario?",
  cpystr_error_invalid_title: "Título inválido",
};

const pt = {
  cpystr_company_area: "Área de atuação",
  cpystr_company_areas_structure: "Estrutura de Áreas da Empresa",
  cpystr_company_areas_add: "Adicionar área",
  cpystr_company_subareas_add: "Adicionar subareas",
  cpystr_company_position: "Cargo",
  cpystr_company_positions_structure: "Estrutura de Cargos da Empresa",
  cpystr_company_positions_add: "Adicionar cargo",
  cpystr_form_area_title: "Nome da Área",
  cpystr_form_position_title: "Título do Cargo",
  cpystr_form_simple_title: "Título",
  cpystr_form_simple_description: "Descrição",
  cpystr_form_simple_active: "Ativo",
  cpystr_form_editing_area_info_title: "Editar informações da área",
  cpystr_form_editing_position_info_title: "Editar informações do cargo",
  cpystr_text_aditional_info: "Informações adicionais",
  cpystr_dialog_position_delete_title:
    "Você tem certeza que deseja remover este Cargo?",
  cpystr_dialog_position_delete_title_warning:
    "Você precisa remover todos os Cargos abaixo para remover este Cargo.",
  cpystr_dialog_position_delete_description:
    "O vínculo entre este Cargo, Usuários Relacionados e Configurações de Ferramentas será desfeito.",
  cpystr_dialog_area_delete_title:
    "Você tem certeza que deseja remover esta Área?",
  cpystr_dialog_area_delete_title_warning:
    "Você precisa remover todas as Subáreas abaixo para remover esta Área.",
  cpystr_dialog_area_delete_description:
    "O vínculo entre esta Área, Usuários Relacionados e Configurações de Ferramentas será desfeito.",
  cpystr_text_areas_title: "Áreas",
  cpystr_text_positions_count: "Cargos",
  cpystr_text_no_area: "Nenhuma área foi registrada",
  cpystr_text_no_subarea: "Nenhuma sub área foi registrada",
  cpystr_text_no_position: "Nenhum cargo foi registrado",
  cpystr_text_no_position_bellow: "Nenhum cargo abaixo foi registrado",
  cpystr_text_no_item_selected: "Nenhum ítem selecionado",
  cpystr_text_selected_item: "Item selecionado",
  cpystr_text_select_some_area: "Selecione uma área",
  cpystr_text_select_the_area: "Selecione a área",
  cpystr_text_select_the_position: "Selecione o Cargo",
  cpystr_option_active_on: "Ativo",
  cpystr_option_active_off: "Inativo",
  cpystr_error_area_exists: "Já existe uma área com esse título cadastrada",
  cpystr_error_subarea_exists:
    "Já existe uma sub área com esse título cadastrada",
  cpystr_error_position_exists: "Já existe um cargo com esse título cadastrada",
  cpystr_confirmation_delete_user_area:
    "Tem certeza que deseja remover a Área de Atuação deste usuário?",
  cpystr_confirmation_delete_user_position:
    "Tem certeza que deseja remover o Cargo deste usuário?",
  cpystr_error_invalid_title: "Título invalido",
};

const translations = { en, es, pt };

export default translations;
