import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { injectIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";
import {
  getAccessFilteredStates,
  getCompAnalysisStates,
  getActionsPlanStates,
  getSelectedToolStates,
} from "../../../../../customMapStates/index";

import PestalManagement from "./custom/PestalManagement";
import SwotManagement from "./custom/SwotManagement";
import PrioritizationManagement from "./custom/PrioritizationManagement";
import ConcurrenceManagement from "./custom/ConcurrenceManagement";
import {
  fetchCompAnalysisManagementData,
  setCurrentAnalysisToolType,
} from "../../../../../../actions/compAnalysisActions";
import ActionsPlanOverview from "../../../../../Common/ActionPlan/ActionsPlanOverview";
import ButtonGeneratePdf from "../../../../../Common/ButtonGeneratePdf";
import { getOnlySublevelArrays } from "../../../../../../utils/ArrayUtils";

const CompAnalysisManagement = (props) => {
  const {
    filterData,
    intl,
    managementData,
    selectAndOpenInsight,
    fetchCompAnalysisManagementData,
    selectedToolId,
    setCurrentAnalysisToolType,
    openedInsight,
    allActionsPlans,
    selectedTool,
    allAnswers,
    allThemes,
  } = props;

  const [tabs, setTab] = useState({
    activeTab: "pestal",
  });

  const sameTabThemes = (themes = null) => {
    let configOk = true;

    if (!themes) return false;

    const arrayThemes = getOnlySublevelArrays(allThemes);

    const currentThemes = arrayThemes.filter(
      ({ id: themeId }) => themes.indexOf(themeId) > -1,
    );

    const { activeTab = "" } = tabs || {};

    currentThemes.forEach((themeObj) => {
      const { cenary = {} } = themeObj || {};
      const { name: cenaryName = "" } = cenary || {};

      if (cenaryName.toLowerCase() !== activeTab) configOk = false;
    });

    return configOk;
  };

  useEffect(() => {
    const { themes = false } = filterData;

    const sameTab = sameTabThemes(themes);
    const fetch = fetchCompAnalysisManagementData;

    if (!openedInsight && sameTab && themes.length > 0)
      fetch(selectedToolId, themes, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedInsight, filterData]);

  const handleTabChange = (key) => {
    setTab({ activeTab: key });
  };

  useEffect(() => {
    const { activeTab = "" } = tabs;

    setCurrentAnalysisToolType(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  const messages = defineMessages({
    noDataToDisplay: {
      id: "global.empty",
    },
    acp_main_title: { id: "acp_main_title" },
    acp_swot_insight_label: { id: "acp_swot_insight_label" },
    acp_swot_tool_table_title: { id: "acp_swot_tool_table_title" },
    acp_swot_tool_table_head_insight_label: {
      id: "acp_swot_tool_table_head_insight_label",
    },
  });

  const pestalTitle = (
    <div>
      <i className="fas fa-chart-line" /> Pestal
    </div>
  );

  const swotTitle = (
    <div>
      <i className="far fa-chart-bar" /> SWOT
    </div>
  );

  const prioritizationTitle = (
    <div>
      <i className="fas fa-align-left" /> Priorização
    </div>
  );

  const concurrenceTitle = (
    <div>
      <i className="fas fa-list-ul" /> Concorrentes
    </div>
  );

  const {
    pestal = {},
    swot = {},
    prioritization = {},
    concurrence = {},
  } = managementData || {};

  const essentialProps = {
    intl,
    selectedToolId,
    fetchCompAnalysisManagementData,
    pestalData: pestal,
    swotData: swot,
    prioritizationData: prioritization,
    concurrenceData: concurrence,
    selectAndOpenInsight,
  };

  const getFilteredInsights = () => {
    const allInsights = getOnlySublevelArrays(allAnswers);

    const allowedNames = ["PESTAL", "SWOT", "PRIORITIZATION", "CONCURRENCE"];

    return allInsights.filter((insight) => {
      const { question = {} } = insight;
      const { theme = {} } = question;
      const { cenary = {} } = theme;
      const { name = {} } = cenary;

      if (allowedNames.indexOf(name) !== -1) return true;

      return false;
    });
  };

  const filteredOnlyInsights = getFilteredInsights();

  const { activeTab = "" } = tabs;

  const handleTab = (key, component) => {
    if (activeTab === key) return component;
    return null;
  };

  return (
    <div className="custom-primary-tabs">
      <ButtonGeneratePdf />
      <Tabs
        defaultActiveKey="pestal"
        id="compAnalysis"
        activeKey={tabs.activeTab}
        style={{ marginTop: "15px" }}
        onSelect={(key) => handleTabChange(key)}
      >
        <Tab eventKey="pestal" title={pestalTitle}>
          {handleTab("pestal", <PestalManagement {...essentialProps} />)}
        </Tab>
        <Tab eventKey="swot" title={swotTitle}>
          {handleTab("swot", <SwotManagement {...essentialProps} />)}
        </Tab>
        <Tab eventKey="prioritization" title={prioritizationTitle}>
          {handleTab(
            "prioritization",
            <PrioritizationManagement {...essentialProps} />,
          )}
        </Tab>
        <Tab eventKey="concurrence" title={concurrenceTitle}>
          {handleTab(
            "concurrence",
            <ConcurrenceManagement {...essentialProps} />,
          )}
        </Tab>
        <Tab eventKey="actionPlans" title="Plano de Ações">
          {handleTab(
            "actionPlans",
            <ActionsPlanOverview
              allActionsPlans={allActionsPlans}
              filteredInsights={filteredOnlyInsights}
              overviewTableTitle={intl
                .formatMessage(messages.acp_main_title)
                .toUpperCase()}
              overviewTableInsightsLabel={intl
                .formatMessage(messages.acp_swot_insight_label)
                .toUpperCase()}
              insightsTableTitle={intl
                .formatMessage(messages.acp_swot_tool_table_title)
                .toUpperCase()}
              insightsTableInsightsLabel={intl
                .formatMessage(messages.acp_swot_tool_table_head_insight_label)
                .toUpperCase()}
              selectedTool={selectedTool}
              selectAndOpenInsight={selectAndOpenInsight}
            />,
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { selectedInsight = {} } = state;
  const { selectedInsight: openedInsight = {} } = selectedInsight;
  const { managementData = {} } = getCompAnalysisStates(state);
  const { selectedTool } = getSelectedToolStates(state);
  const { allActionsPlans = [] } = getActionsPlanStates(state);
  const {
    filteredQuestions = {},
    filteredAnswers = {},
    filteredThemes = {},
  } = getAccessFilteredStates(state);
  const { id: selectedToolId } = selectedTool || {};

  return {
    managementData,
    allAnswers: filteredAnswers,
    allQuestions: filteredQuestions,
    allThemes: filteredThemes,
    selectedToolId,
    selectedTool,
    openedInsight,
    allActionsPlans,
  };
};

export default connect(mapStateToProps, {
  fetchCompAnalysisManagementData,
  setCurrentAnalysisToolType,
})(injectIntl(CompAnalysisManagement));
