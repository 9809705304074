import React from "react";
import ColumnsEvaluations from "./includes/ColumnsEvaluations";
import IndicatorsEvaluations from "./includes/IndicatorsEvaluations";
import ProcessEvaluations from "./includes/ProcessEvaluations";
import ThemeIndicatorsEvaluations from "./includes/ThemeIndicatorsEvaluations";
import { getIndicatorTargetOptions } from "../../../constants/kpis";

const ConsolidationOverview = (props) => {
  const {
    kpiAdministration,
    displayProcesses,
    filteredGoals,
    allQuestions,
    selectAndOpenInsight,
    isPeriodFilterActive,
  } = props;

  const goalTargetOptions = getIndicatorTargetOptions();

  const appendProps = {
    kpiAdministration,
    displayProcesses,
    goals: filteredGoals,
    goalTargetOptions,
    allQuestions,
    selectAndOpenInsight,
    isPeriodFilterActive,
  };

  return (
    <div className="row">
      <div className="col-xs-12 col-md-6 col-xl-3">
        <div className="row">
          <ColumnsEvaluations {...appendProps} />
        </div>
        <div className="row">
          <IndicatorsEvaluations {...appendProps} />
        </div>
      </div>
      <div className="col-xs-12 col-md-6 col-xl-3">
        <div className="row">
          <ThemeIndicatorsEvaluations {...appendProps} />
        </div>
      </div>
      <div className="col-xs-12 col-md-6 col-xl-3">
        <div className="row">
          <ProcessEvaluations {...appendProps} />
        </div>
      </div>
    </div>
  );
};

export default ConsolidationOverview;
