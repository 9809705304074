import React from "react";
import { connect } from "react-redux";
import FormDialog from "../../../Common/FormDialog";
import MaterialSingleSelect from "../../../Common/MaterialSingleSelect";
import { useSimpleToolAdministration } from "../../../../utils/skillsAssessment/hooks/administration";
import { moveInsightToCenary } from "../../../../actions/skillAssessmentActions";
import { updateClientLoading } from "../../../../actions/loadingActions";
import {
  getSelectedToolStates,
  getSkillAssessmentStates,
} from "../../../customMapStates";

const MoveInsightDialog = ({
  insight,
  closeDialog,
  selectedTool,
  allSkillAssessment,
  allSceneries,
  allThemes,
  allQuestions,
  allAnswers,
  isLoading,
  moveInsightToCenary,
  updateClientLoading,
}) => {
  const [selectedCenaryId, setSelectedCenaryId] = React.useState(null);
  const { toolAdministration } = useSimpleToolAdministration({
    selectedTool,
    allSkillAssessment,
    isLoading,
  });

  if (!toolAdministration?.id || !insight?.id) return null;

  const currentCenaryId = insight?.question?.theme?.cenary?.id;
  const currentCenaryThemes = allThemes[currentCenaryId];
  const currentThemeQuestions = allQuestions[insight?.question?.theme?.id];
  const currentQuestionInsights = allAnswers[insight?.question?.id];

  const archiveQuestion = currentQuestionInsights.length === 1;
  const archiveTheme = currentThemeQuestions.length === 1 && archiveQuestion;
  const archiveCenary = currentCenaryThemes.length === 1 && archiveTheme;

  const cenaryOptions = allSceneries
    .filter(({ id }) => currentCenaryId !== id)
    .map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  function getUpdatedParticipantRelations() {
    const participant = toolAdministration.participants.find(
      (participant) => participant.id === insight.skillsAssessmentParticipantId,
    );

    const updatedParticipant = {
      ...participant,
    };

    const selectedTheme = allThemes[selectedCenaryId]?.find(
      (theme) => theme.companyAreaId === updatedParticipant.companyAreaId,
    );

    const selectedQuestion = allQuestions[selectedTheme?.id]?.find(
      (question) =>
        question.skillsAssessmentProfileId === updatedParticipant.profileId,
    );

    return {
      participant: updatedParticipant,
      cenary: allSceneries.find((c) => c.id === selectedCenaryId),
      cenaryId: currentCenaryId,
      theme: selectedTheme || null,
      question: selectedQuestion || null,
      currentRelation: {
        cenary: insight?.question?.theme?.cenary,
        theme: insight?.question?.theme,
        question: insight?.question,
        insight,
      },
      validation: {
        archiveCenary,
        archiveTheme,
        archiveQuestion,
      },
    };
  }

  async function onConfirm() {
    updateClientLoading({
      loading: true,
      message: "Aguarde enquanto as atualizações são carregadas",
    });
    await moveInsightToCenary({
      toolAdministration,
      insightId: insight.id,
      updatedRelation: getUpdatedParticipantRelations(),
      selectedCenaryId,
    });
    updateClientLoading({ loading: false });
    closeDialog();
  }

  function onCancel() {
    closeDialog();
  }

  return (
    <FormDialog
      open
      title="Selecione um novo cenário"
      onConfirm={onConfirm}
      onCancel={onCancel}
      blockConfirm={selectedCenaryId === "empty" || !selectedCenaryId}
    >
      <MaterialSingleSelect
        label="Cenário selecionado"
        options={cenaryOptions}
        value={selectedCenaryId || "empty"}
        onChange={(e) => setSelectedCenaryId(e.target.value)}
      />
    </FormDialog>
  );
};

function mapStateToProps(state) {
  const { selectedTool, allSceneries, allThemes, allQuestions, allAnswers } =
    getSelectedToolStates(state);
  const { allSkillAssessment, isLoading } = getSkillAssessmentStates(state);
  return {
    selectedTool,
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
    allSkillAssessment,
    isLoading,
  };
}

export default connect(mapStateToProps, {
  moveInsightToCenary,
  updateClientLoading,
})(MoveInsightDialog);
