import React from "react";
import { defineMessages } from "react-intl";

export const swotEvaluationTotalScore = 125;

const messages = defineMessages({
  strengths: { id: "tool.swot.strengths" },
  weakness: { id: "tool.swot.weakness" },
  opportunities: { id: "tool.swot.opportunities" },
  threats: { id: "tool.swot.threats" },
});
const swotMessages = defineMessages({
  NOT_IMPORTANT: {
    id: "tool.swot.not_important",
  },
  LITTLE_IMPORTANT: {
    id: "tool.swot.little_important",
  },
  IMPORTANT: {
    id: "tool.swot.important",
  },
  VERY_IMPORTANT: {
    id: "tool.swot.very_important",
  },
  TOTALLY_IMPORTANT: {
    id: "tool.swot.totally_important",
  },
  NOT_IMPACT: {
    id: "tool.swot.not_impact",
  },
  LITTLE_IMPACT: {
    id: "tool.swot.little_impact",
  },
  LONG_IMPACT: {
    id: "tool.swot.long_impact",
  },
  MID_IMPACT: {
    id: "tool.swot.mid_impact",
  },
  SHORT_IMPACT: {
    id: "tool.swot.short_impact",
  },
  NO_RELIABILITY: {
    id: "global.reliability.NO_RELIABILITY",
  },
  UNRELIABLE_SOURCE: {
    id: "global.reliability.UNRELIABLE_SOURCE",
  },
  MEDIUM: {
    id: "global.reliability.MEDIUM",
  },
  HIGH: {
    id: "global.reliability.HIGH",
  },
  VERY_HIGH: {
    id: "global.reliability.VERY_HIGH",
  },
});

export const getTitleByIntl = (title, intl) => {
  if (title === "PONTOS FORTES") {
    return intl.formatMessage(messages.strengths);
  } else if (title === "PONTOS FRACOS") {
    return intl.formatMessage(messages.weakness);
  } else if (title === "OPORTUNIDADES") {
    return intl.formatMessage(messages.opportunities);
  } else if (title === "AMEAÇAS") {
    return intl.formatMessage(messages.threats);
  }
  return "";
};

export const generatePoints = (score) => {
  let pipe = "";
  for (let i = 0; i < score; i++) {
    pipe = pipe + "|";
  }
  return <div>{pipe}</div>;
};
export const generateImportancePoints = (score) => {
  if (score === "NOT_IMPORTANT") {
    return 1;
  } else if (score === "LITTLE_IMPORTANT") {
    return 2;
  } else if (score === "IMPORTANT") {
    return 3;
  } else if (score === "VERY_IMPORTANT") {
    return 4;
  } else if (score === "TOTALLY_IMPORTANT") {
    return 5;
  } else {
    return 0;
  }
};
export const getCustomIconClass = (title) => {
  let customIcon = {};
  if (title === "PONTOS FORTES") {
    customIcon = "fas fa-fist-raised";
  } else if (title === "OPORTUNIDADES") {
    customIcon = "fas fa-location-arrow";
  } else if (title === "PONTOS FRACOS") {
    customIcon = "fas fa-thumbs-down";
  } else if (title === "AMEAÇAS") {
    customIcon = "fas fa-chess";
  }
  return customIcon;
};
export const getCustomColorClass = (theme) => {
  let customColorClass = {};
  if (theme === "PONTOS FORTES" || theme === "OPORTUNIDADES") {
    customColorClass = {
      class: "pipe_tool_green progress-bar",
      color: "#00b050",
      customIcon: getCustomIconClass(theme),
    };
  } else if (theme === "PONTOS FRACOS" || theme === "AMEAÇAS") {
    customColorClass = {
      class: "pipe_tool_red progress-bar",
      color: "tomato",
      customIcon: getCustomIconClass(theme),
    };
  }
  return customColorClass;
};
export const generateImpactPoints = (score) => {
  if (score === "NOT_IMPACT") {
    return 1;
  } else if (score === "LITTLE_IMPACT_ON_RESULT") {
    return 2;
  } else if (score === "IMPACT_ON_RESULT_LONG_TERM") {
    return 3;
  } else if (score === "IMPACT_ON_RESULT_MID_TERM") {
    return 4;
  } else if (score === "IMMEDIATE_IMPACT") {
    return 5;
  } else {
    return 0;
  }
};
export const getImportance = (intl) => {
  return [
    {
      key: "NOT_IMPORTANT",
      value: 0,
      label: `1 - ${intl.formatMessage(swotMessages.NOT_IMPORTANT)}`,
    },
    {
      key: "LITTLE_IMPORTANT",
      value: 1,
      label: `2 - ${intl.formatMessage(swotMessages.LITTLE_IMPORTANT)}`,
    },
    {
      key: "IMPORTANT",
      value: 2,
      label: `3 - ${intl.formatMessage(swotMessages.IMPORTANT)}`,
    },
    {
      key: "VERY_IMPORTANT",
      value: 3,
      label: `4 - ${intl.formatMessage(swotMessages.VERY_IMPORTANT)}`,
    },
    {
      key: "TOTALLY_IMPORTANT",
      value: 4,
      label: `5 - ${intl.formatMessage(swotMessages.TOTALLY_IMPORTANT)}`,
    },
  ];
};
export const getImpact = (intl) => {
  return [
    {
      key: "NOT_IMPACT",
      value: 0,
      label: `1 - ${intl.formatMessage(swotMessages.NOT_IMPACT)}`,
    },
    {
      key: "LITTLE_IMPACT_ON_RESULT",
      value: 1,
      label: `2 - ${intl.formatMessage(swotMessages.LITTLE_IMPACT)}`,
    },
    {
      key: "IMPACT_ON_RESULT_LONG_TERM",
      value: 2,
      label: `3 - ${intl.formatMessage(swotMessages.LONG_IMPACT)}`,
    },
    {
      key: "IMPACT_ON_RESULT_MID_TERM",
      value: 3,
      label: `4 - ${intl.formatMessage(swotMessages.MID_IMPACT)}`,
    },
    {
      key: "IMMEDIATE_IMPACT",
      value: 4,
      label: `5 - ${intl.formatMessage(swotMessages.SHORT_IMPACT)}`,
    },
  ];
};
export const generateReliabilityPoints = (score) => {
  if (score === "NO_RELIABILITY") {
    return 1;
  } else if (score === "UNRELIABLE_SOURCE") {
    return 2;
  } else if (score === "MEDIUM") {
    return 3;
  } else if (score === "HIGH") {
    return 4;
  } else if (score === "VERY_HIGH") {
    return 5;
  } else {
    return 0;
  }
};
export const getReliability = (intl) => {
  return [
    {
      key: "NO_RELIABILITY",
      value: 0,
      label: `1 - ${intl.formatMessage(swotMessages.NO_RELIABILITY)}`,
    },
    {
      key: "UNRELIABLE_SOURCE",
      value: 1,
      label: `2 - ${intl.formatMessage(swotMessages.UNRELIABLE_SOURCE)}`,
    },
    {
      key: "MEDIUM",
      value: 2,
      label: `3 - ${intl.formatMessage(swotMessages.MEDIUM)}`,
    },
    {
      key: "HIGH",
      value: 3,
      label: `4 - ${intl.formatMessage(swotMessages.HIGH)}`,
    },
    {
      key: "VERY_HIGH",
      value: 4,
      label: `5 - ${intl.formatMessage(swotMessages.VERY_HIGH)}`,
    },
  ];
};
