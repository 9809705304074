import moment from "moment";

export const getMonthOptions = () => {
  const months = 12;
  const finalOptions = [];

  for (let i = 0; i < months; i++) {
    finalOptions.push({
      value: i,
      label: moment().month(i).format("MMMM"),
    });
  }

  return finalOptions;
};
