/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import _ from "lodash";
import { buildFormAdmConfigBody } from "./entity";
import { getGoalInfoById, getGoalsBonusBySelectedToolId } from "./filter";
import { getKpiIndicatorChanges } from "../kpis/validation";

const defaultAdministration = buildFormAdmConfigBody({});
const defaultGoalsBody = buildFormAdmConfigBody({ goals: [{ id: null }] })
  .goals[0];

export function useSimpleToolAdministration({
  selectedTool = {},
  allGoalBonusAdministration = [],
  isLoading = false,
}) {
  const [goalBonusAdministration, setGoalBonusAdministration] = useState(
    defaultAdministration,
  );

  useEffect(() => {
    if (allGoalBonusAdministration) {
      const restConfig = getGoalsBonusBySelectedToolId(
        selectedTool.id,
        allGoalBonusAdministration,
      );

      const formConfig = buildFormAdmConfigBody(restConfig);

      if (!isLoading && !_.isEqual(formConfig, goalBonusAdministration)) {
        setGoalBonusAdministration(formConfig);
      }
    }
  }, [allGoalBonusAdministration, isLoading, selectedTool.id]);

  return { goalBonusAdministration, setGoalBonusAdministration };
}

export function useHistoryToolAdministration({
  selectedTool = {},
  allGoalBonusAdministration = [],
  isLoading = false,
}) {
  const [goalBonusAdministration, setGoalBonusAdministration] = useState(
    defaultAdministration,
  );
  const [toolHistory, setToolHistory] = useState(defaultAdministration);

  useEffect(() => {
    if (allGoalBonusAdministration) {
      const restConfig = getGoalsBonusBySelectedToolId(
        selectedTool.id,
        allGoalBonusAdministration,
      );

      const formConfig = buildFormAdmConfigBody(restConfig);

      if (formConfig && !_.isEqual(formConfig, toolHistory)) {
        setToolHistory(formConfig);

        if (!isLoading && !_.isEqual(formConfig, goalBonusAdministration)) {
          setGoalBonusAdministration(formConfig);
        }
      }
    }
  }, [allGoalBonusAdministration, isLoading, selectedTool.id]);

  return {
    goalBonusAdministration,
    setGoalBonusAdministration,
    toolHistory,
    setToolHistory,
  };
}

export function useSelectedGoal({
  goals: originalGoals,
  goalsBonusProgramGoalId,
  isLoading,
}) {
  const [goalDescriptions, setGoalDescriptions] = useState(defaultGoalsBody);
  const [controller, setController] = useState({
    firstMount: false,
    changes: [],
  });

  useEffect(() => {
    const newGoalDescriptions = getGoalInfoById(
      goalsBonusProgramGoalId,
      originalGoals,
    );

    if (
      newGoalDescriptions.id &&
      !controller.firstMount &&
      !isLoading &&
      !_.isEqual(newGoalDescriptions, goalDescriptions)
    ) {
      setGoalDescriptions({
        id: goalsBonusProgramGoalId,
        ...newGoalDescriptions,
      });
      setController({
        ...controller,
        firstMount: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalsBonusProgramGoalId, originalGoals]);

  useEffect(() => {
    const restGoalDescriptions = getGoalInfoById(
      goalsBonusProgramGoalId,
      originalGoals,
    );

    const newChanges = getKpiIndicatorChanges(
      restGoalDescriptions,
      goalDescriptions,
    );

    if (!_.isEqual(newChanges, controller.changes))
      setController({ ...controller, changes: newChanges });
  }, [goalDescriptions, goalsBonusProgramGoalId, originalGoals]);

  return {
    goalDescriptions,
    setGoalDescriptions,
    changes: controller.changes,
    setController,
  };
}
