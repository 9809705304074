/* eslint-disable react/prop-types */
import React from "react";
import { defineMessages } from "react-intl";
import moment from "moment";
import IndicatorDisplayTable from "../../Kpi/insight/includes/IndicatorDisplayTable";
import MaterialTextField from "../../MaterialTextField";
import MaterialSingleSelect from "../../MaterialSingleSelect";
import SimpleLegend from "../../SimpleLegend";
import kpiUtils from "../../../../utils/kpis";
import utils from "../../../../utils/toolUtils";
import { getIndicatorTargetOptions } from "../../../../constants/kpis";
import { getMonthOptions } from "../../../../constants/monthsConstants";
import { checkIsGoalEditable } from "../../../../utils/goalsBonus/validation";

const { getErrorMessage, translatedText } = utils;
const { getRelativeDateGoalMessage, getGoalDistributionByPeriods } = kpiUtils;

const messages = defineMessages({
  kpi_subtitle_goal_measurement: { id: "kpi_subtitle_goal_measurement" },
  kpi_form_data_source: { id: "kpi_form_data_source" },
  kpi_form_general_observations: { id: "kpi_form_general_observations" },
  kpi_form_today: { id: "kpi_form_today" },
  kpi_form_year: { id: "kpi_form_year" },
  kpi_form_initial_month: { id: "kpi_form_initial_month" },
  kpi_form_last_month: { id: "kpi_form_last_month" },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

const Form = (props) => {
  const {
    insight = {},
    goalDescriptions,
    setGoalDescriptions,
    goalTargetConfigurations: targetsConfig,
    SaveButton,
    formErrors = [],
    isAdmin = false,
    blockAllChanges = false,
    haveLinkedIndicator,
    zeroWhenNegative,
  } = props;

  const today = new Date();

  const goalTargetOptions = getIndicatorTargetOptions();
  const monthOptions = getMonthOptions();

  const handleUpdatedGoalPeriod = (value, periodSlug = "start") => {
    const tempUpdatedGoal = {
      ...goalDescriptions,
      period: {
        ...goalDescriptions.period,
        [periodSlug]: value,
      },
    };

    const { goalDistribution } = getGoalDistributionByPeriods(tempUpdatedGoal);

    setGoalDescriptions({
      ...tempUpdatedGoal,
      target: {
        ...goalDescriptions.target,
        distribution: goalDistribution,
      },
    });
  };

  const handleUpdateYear = (newYear) => {
    const newPeriod = {
      ...goalDescriptions.period,
      year: Number(newYear),
    };

    setGoalDescriptions({
      ...goalDescriptions,
      period: newPeriod,
    });
  };

  const isEditingAllowed =
    checkIsGoalEditable(
      goalDescriptions.id,
      goalDescriptions.workflowStatus,
      goalDescriptions.relationshipType,
      isAdmin,
    ) &&
    !blockAllChanges &&
    !haveLinkedIndicator;

  const blockAllOrLinked = blockAllChanges || haveLinkedIndicator;

  const errorStyle = {
    backgroundColor: "#ff6347",
    color: "#fff",
    padding: "5px",
    height: "24px",
  };

  return (
    <>
      <div className="row">
        <div className="col-xs-12">
          <h6 style={{ marginTop: "0px" }}>
            {translation("kpi_form_today")}: {moment(today).format("LL")}
          </h6>
          {getRelativeDateGoalMessage(
            goalDescriptions.period.year,
            goalDescriptions.period.start,
            goalDescriptions.period.end,
          )}
        </div>
        <div className="col-xs-3">
          <MaterialTextField
            type="number"
            id="yearPeriod"
            value={goalDescriptions.period.year}
            label={translation("kpi_form_year")}
            onChange={(e) =>
              isEditingAllowed
                ? handleUpdateYear(e.target.value, "year")
                : () => {}
            }
            inputStyle={!isEditingAllowed ? { cursor: "not-allowed" } : {}}
          />
        </div>
        <div className="col-xs-3">
          <MaterialSingleSelect
            id="startMonth"
            value={goalDescriptions.period.start}
            label={translation("kpi_form_initial_month")}
            options={monthOptions}
            onChange={(e) =>
              isEditingAllowed
                ? handleUpdatedGoalPeriod(e.target.value, "start")
                : () => {}
            }
            defaultOption={false}
            notAllowed={!isEditingAllowed}
          />
        </div>
        <div className="col-xs-3">
          <MaterialSingleSelect
            id="endMonth"
            value={goalDescriptions.period.end}
            label={translation("kpi_form_last_month")}
            options={monthOptions}
            onChange={(e) =>
              isEditingAllowed
                ? handleUpdatedGoalPeriod(e.target.value, "end")
                : () => {}
            }
            defaultOption={false}
            notAllowed={!isEditingAllowed}
          />
        </div>
        <div className="col-xs-12" style={{ paddingTop: "0px" }}>
          {getErrorMessage(
            ["startDateError", "sameMonthError"],
            formErrors,
            "right",
          )}
        </div>
        <div className="col-xs-12" style={{ paddingTop: "0px" }}>
          {getErrorMessage(
            ["distributionError"],
            formErrors,
            "center",
            errorStyle,
            true,
          )}
        </div>
      </div>
      <div className="col-xs-12">
        <IndicatorDisplayTable
          goalDescriptions={goalDescriptions}
          setGoalDescriptions={setGoalDescriptions}
          zeroWhenNegative={zeroWhenNegative}
          targetsConfig={targetsConfig}
          blockAllChanges={blockAllChanges || blockAllOrLinked}
          formErrors={formErrors}
          insight={insight}
          hideLastPeriod
        />
      </div>
      <div className="col-xs-12" style={{ paddingTop: "0px" }}>
        {getErrorMessage(
          ["distributionError"],
          formErrors,
          "center",
          errorStyle,
          true,
        )}
      </div>
      <div className="col-xs-12">
        {goalTargetOptions.map((targetOptionInfo) => {
          return (
            <SimpleLegend
              key={targetOptionInfo.label}
              label={targetOptionInfo.label}
              fillColor={targetOptionInfo.color}
            />
          );
        })}
      </div>
      <div className="col-xs-12" style={{ paddingTop: "15px" }}>
        <MaterialTextField
          id="sourceDescription"
          multiline
          label={translation("kpi_form_data_source")}
          value={goalDescriptions.sourceDescription}
          onChange={(e) =>
            !blockAllOrLinked
              ? setGoalDescriptions({
                  ...goalDescriptions,
                  sourceDescription: e.target.value,
                })
              : () => {}
          }
          inputStyle={{ cursor: blockAllOrLinked ? "not-allowed" : "auto" }}
          primaryInputProps={{ maxLength: 255 }}
        />
      </div>
      <div className="col-xs-12" style={{ paddingTop: "15px" }}>
        <MaterialTextField
          id="generalDetails"
          multiline
          rows={6}
          label={translation("kpi_form_general_observations")}
          value={goalDescriptions.generalDetails}
          onChange={(e) =>
            !blockAllOrLinked
              ? setGoalDescriptions({
                  ...goalDescriptions,
                  generalDetails: e.target.value,
                })
              : () => {}
          }
          inputStyle={{ cursor: blockAllOrLinked ? "not-allowed" : "auto" }}
          primaryInputProps={{ maxLength: 1000 }}
        />
      </div>
      <div className="col-xs-12" style={{ paddingTop: "20px" }}>
        {SaveButton && SaveButton}
      </div>
    </>
  );
};

const GoalEvaluation = (props) => {
  const { onlyForm = false, SaveButton = false } = props;

  return !onlyForm ? (
    <div className="box box-primary">
      <div className="box-header with-border">
        <h3 className="box-title">
          <i className="fas fa-chart-area" />
          {translation("kpi_subtitle_goal_measurement")}
        </h3>
        {SaveButton && SaveButton}
      </div>
      <div className="box-body">
        <Form {...props} />
      </div>
    </div>
  ) : (
    <Form {...props} />
  );
};

export default GoalEvaluation;
