import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Badge = styled.div`
  @media print {
    background-color: ${(props) =>
      props.background ? props.background : ""} !important;
    color: ${(props) => (props.color ? props.color : "")} !important;
  }
`;

const AverageBadge = ({
  average,
  label,
  borderedBadge,
  maxValue,
  customColor,
  fontSize,
  fontColor = "#333",
  padding,
  fontWeight,
  hideColor,
  defaultColor,
  width,
  customBorderColor,
  displayMaxValue,
  multiplier = 5,
}) => {
  let styles = {};
  let unityValue = maxValue / multiplier;

  if (average > 0 && average <= unityValue * 1.5) {
    styles = {
      color: "#fff",
      backgroundColor: "#ff0000",
    };
  } else if (average > unityValue * 1.5 && average <= unityValue * 2.5) {
    styles = {
      color: "#fff",
      backgroundColor: "#fd5d5d",
    };
  } else if (average > unityValue * 2.5 && average <= unityValue * 3.5) {
    styles = {
      color: "#fff",
      backgroundColor: "#ffc000",
    };
  } else if (average > unityValue * 3.5 && average <= unityValue * 4.5) {
    styles = {
      color: "#fff",
      backgroundColor: "#44cf76",
    };
  } else if (average > unityValue * 4.5) {
    styles = {
      color: "#fff",
      backgroundColor: "#00b050",
    };
  } else {
    styles = {
      color: fontColor,
      backgroundColor: "#ccc",
    };
  }

  if (borderedBadge) {
    styles.border = "1px solid #ccc";
    if (!hideColor) {
      const borderColor = customBorderColor
        ? customBorderColor
        : styles.backgroundColor;
      styles.borderRight = `8px solid ${borderColor}`;
    }
    styles.color = "#444";
    styles.fontWeight = "normal";
    styles.backgroundColor = "rgba(250, 250, 250, 0.6)";
    styles.minWidth = "43px";
  } else if (hideColor) {
    styles.backgroundColor = defaultColor;
  }

  if (customColor) {
    styles.backgroundColor = customColor;
  }

  styles.fontSize = fontSize || "14px";
  styles.padding = padding || "5px";

  if (fontWeight) {
    styles.fontWeight = fontWeight;
  }

  if (width) {
    styles.width = width;
  }

  return (
    <>
      {average >= 0 && (
        <Badge
          background={styles.backgroundColor}
          color={borderedBadge ? "#444" : "#fff"}
          style={{
            minWidth: "25px",
            height: "25px",
            textAlign: "center",
            fontFamily: "Montserrat",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...styles,
          }}
        >
          {`${label ? label : ""} ${average}`}
          <span style={{ fontSize: "14px", fontWeight: "normal" }}>
            {displayMaxValue && maxValue ? "/" + maxValue : null}
          </span>
        </Badge>
      )}
    </>
  );
};

AverageBadge.propTypes = {
  average: PropTypes.node.isRequired,
  label: PropTypes.node,
  maxValue: PropTypes.number,
  borderedBadge: PropTypes.bool,
  width: PropTypes.string,
  fontWeight: PropTypes.string,
  fontSize: PropTypes.string,
  customColor: PropTypes.string,
  displayMaxValue: PropTypes.bool,
};

AverageBadge.defaultProps = {
  maxValue: 5,
  borderedBadge: false,
  defaultColor: "#ccc",
  hideColor: false,
  width: null,
  fontWeight: null,
  fontSize: null,
  customColor: null,
  displayMaxValue: false,
};

export default AverageBadge;
