import React, { useState, useEffect } from "react";
import FormDialog from "../../../../../../Common/FormDialog";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import SimpleSelectExcelCollumn from "../../../../../../Common/SimpleSelectExcelCollumn";
import MaterialTextField from "../../../../../../Common/MaterialTextField";
import SimplePagination from "../../../../../../SimplePagination";
import { usePaginationList } from "../../../../../../../utils/hooks/usePaginationList";
import { orderArrayByObjAttr } from "../../../../../../../utils/ArrayUtils";
import { filterParticipantsByNameOrEmail } from "../../../../../../../utils/skillsAssessment/filter";
import { useWindowSize } from "../../../../../../../utils/customHooks";

const ParticipantBoardRelation = ({
  open,
  onConfirm,
  onCancel,
  participants,
  allSceneries,
  openSelectArea,
  openSelectRole,
  profileOptions,
  handleUpdateParticipantProfile,
}) => {
  const [cenaryOptions, setCenaryOptions] = useState([]);
  const [selectedRelations, setSelectedRelations] = useState([]);
  const [hideInvalid, setHideInvalid] = useState(false);

  const [filter, setFilter] = useState("");
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [page, setPage] = useState(1);
  const { pageItems: pageParticipants, totalPages } = usePaginationList({
    list: filteredParticipants,
    page,
    pageSize: 25,
  });
  const windowSize = useWindowSize();
  const maxDialogSizeToScroll = windowSize[1] - 320;

  useEffect(() => {
    const filtered = filter
      ? filterParticipantsByNameOrEmail(filter, participants)
      : participants;

    const ordered = orderArrayByObjAttr(
      filtered.map((p) => {
        return {
          ...p,
          name: p?.memberParticipant?.name,
        };
      }),
      "name",
    );
    setFilteredParticipants(ordered);
  }, [filter, participants]);

  useEffect(() => {
    setCenaryOptions(
      allSceneries.map((cenary) => ({ label: cenary.name, value: cenary.id })),
    );
  }, [allSceneries]);

  function updateFilter(newValue) {
    if (page !== 1) setPage(1);
    setFilter(newValue);
  }

  function getRelationByParticipantId(participantId) {
    return selectedRelations.find(
      (relation) => relation.participantId === participantId,
    );
  }

  function handleRemoveRelation(participantId) {
    const newRelations = [...selectedRelations];
    const index = newRelations.findIndex(
      (relation) => relation.participantId === participantId,
    );

    if (index > -1) {
      newRelations.splice(index, 1);
      setSelectedRelations(newRelations);
    }
  }

  function handleSetRelation(participantId, cenaryId) {
    handleRemoveRelation(participantId);

    setSelectedRelations((current) => {
      const newRelation = {
        participantId,
        cenaryId,
      };

      return [...current, newRelation];
    });
  }

  function handleConfirm() {
    onConfirm(selectedRelations);
  }

  const listParticipants = pageParticipants
    .map((participant) => {
      const hasRequiredMissing =
        !participant?.companyArea?.id ||
        !participant?.companyPosition?.id ||
        !participant?.profile?.id;

      const selected = getRelationByParticipantId(participant.id);
      const haveSelected = selected?.cenaryId;

      return {
        participant,
        hasRequiredMissing,
        selected,
        haveSelected,
      };
    })
    .filter(({ hasRequiredMissing }) => !hideInvalid || !hasRequiredMissing);

  return (
    <FormDialog
      title="Adicione os participantes ao quadro"
      dialogClassName="participant-workflow-1100"
      open={open}
      onConfirm={handleConfirm}
      onCancel={onCancel}
    >
      <div className="row">
        <div className="row" style={{ display: "flex", alignItems: "center" }}>
          <div
            className="col-xs-4"
            style={{ display: "flex", alignItems: "center" }}
          >
            <MaterialTextField
              label="Pesquisar Participante"
              value={filter}
              onChange={(e) => updateFilter(e.target.value)}
            />
            <a
              onClick={() => updateFilter("")}
              style={{
                cursor: "pointer",
                display: "block",
                padding: "5px",
                width: "100px",
              }}
            >
              Limpar <i className="fas fa-times" />
            </a>
          </div>
          <div
            className="col-xs-8"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <h5 style={{ padding: "0px 15px" }}>
              Para adicionar participantes ao quadro: Área, Cargo e Perfil devem
              ser preenchidos.
            </h5>
            <button
              className="btn btn-primary"
              onClick={() => setHideInvalid(!hideInvalid)}
            >
              {hideInvalid ? "Mostrar todos" : "Mostrar apenas válidos"}
            </button>
          </div>
        </div>
        <div className="col-xs-12">
          <SimplePagination
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
          <div style={{ maxHeight: maxDialogSizeToScroll, overflowX: "auto" }}>
            <table className="simple-table">
              <thead>
                <tr>
                  <td>Participante</td>
                  <td>Área</td>
                  <td>Cargo</td>
                  <td>Perfil</td>
                  <td>Validação</td>
                  <td>Reverter</td>
                  <td width="250">Selecionar Cenário</td>
                </tr>
              </thead>
              <tbody>
                {listParticipants?.length === 0 && (
                  <td colSpan="100" align="center">
                    Nenhum resultado
                  </td>
                )}
                {listParticipants.map(
                  ({
                    participant,
                    hasRequiredMissing,
                    selected,
                    haveSelected,
                  }) => {
                    return (
                      <tr key={participant.id}>
                        <td>{participant?.memberParticipant?.name}</td>
                        <SimpleExcelCollumn
                          id={`participantArea${participant.id}`}
                          label="Área"
                          type="text"
                          value={
                            participant?.companyArea?.title || "- Selecione -"
                          }
                          onClick={() => openSelectArea(participant)}
                          inputStyle={{ fontSize: "10px" }}
                        />
                        <SimpleExcelCollumn
                          id={`participantRole${participant.id}`}
                          label="Cargo"
                          type="text"
                          value={
                            participant?.companyPosition?.title ||
                            "- Selecione -"
                          }
                          onClick={() => openSelectRole(participant)}
                          inputStyle={{ fontSize: "10px" }}
                        />
                        {profileOptions.length > 0 && (
                          <SimpleSelectExcelCollumn
                            id={`participantProfile${participant.id}`}
                            label="Perfil"
                            value={participant?.profile?.id || ""}
                            options={profileOptions}
                            onChange={(e) =>
                              handleUpdateParticipantProfile(
                                participant,
                                e.target.value,
                              )
                            }
                            customStyles={{
                              backgroundColor: "#ddd",
                              fontSize: "10px",
                            }}
                            defaultOption={false}
                          />
                        )}
                        {profileOptions.length === 0 && (
                          <td>Nenhum cadastrado</td>
                        )}
                        <td
                          align="center"
                          title={
                            hasRequiredMissing ? "Campos não preenchidos" : ""
                          }
                          width="30"
                        >
                          {hasRequiredMissing ? (
                            <i className="fas fa-times text-danger text-bold" />
                          ) : (
                            <i className="fas fa-check text-success text-bold" />
                          )}
                        </td>
                        <td
                          align="center"
                          width="30"
                          onClick={
                            haveSelected
                              ? () => handleRemoveRelation(participant.id)
                              : () => {}
                          }
                          style={haveSelected ? { cursor: "pointer" } : {}}
                        >
                          {haveSelected && (
                            <i className="fas fa-history text-danger" />
                          )}
                        </td>
                        <SimpleSelectExcelCollumn
                          id={`participantCenary${participant.id}`}
                          label="Cenário"
                          value={selected?.cenaryId || ""}
                          options={cenaryOptions}
                          onChange={(e) =>
                            handleSetRelation(participant.id, e.target.value)
                          }
                          customStyles={{
                            backgroundColor: "#ddd",
                            fontSize: "10px",
                          }}
                          defaultOption={false}
                          disabled={hasRequiredMissing}
                          notAllowed={hasRequiredMissing}
                        />
                      </tr>
                    );
                  },
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </FormDialog>
  );
};

export default ParticipantBoardRelation;
