import { OverlayTrigger, Popover, Row } from "react-bootstrap";
import { defineMessages } from "react-intl";

const messages = defineMessages({
  notAppliable: { id: "evaluation.notAppliable" },
  veryLow: { id: "evaluation.veryLow" },
  low: { id: "evaluation.low" },
  partial: { id: "evaluation.partial" },
  high: { id: "evaluation.high" },
  veryHigh: { id: "evaluation.veryHigh" },
});

const popoverStyle = { zIndex: "99999" };
const popoverRowStyle = { marginLeft: "5px" };

const getLevelPopover = (toolProcessConfigurations, desiredLevel) => {
  const { maturityLevels } = toolProcessConfigurations;
  const { levels } = maturityLevels;
  const level = levels.find((item) => item.level === desiredLevel);

  return (
    <Popover id={`popover_level_${desiredLevel}`} style={popoverStyle}>
      <Row style={{ marginLeft: "5px" }}>{level.description}</Row>
    </Popover>
  );
};

const getLevelsTitlePopover = (toolProcessConfigurations) => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Popover id="popoverLevelsDescription" style={popoverStyle}>
          <Row style={popoverRowStyle}>
            {toolProcessConfigurations?.maturityLevels?.description}
          </Row>
        </Popover>
      }
    >
      <span>
        <i className={`fas fa-info-circle`}></i>
      </span>
    </OverlayTrigger>
  );
};

export const getTableHeadValues = (intl, toolProcessConfigurations) => {
  return [
    {
      value: "NOT_APPLICABLE",
      valueNumber: 0,
      topLabel: toolProcessConfigurations
        ? getLevelsTitlePopover(toolProcessConfigurations)
        : null,
      bottomLabel: intl ? intl.formatMessage(messages.notAppliable) : null,
      color: "#ccc",
      constrast: "#222",
    },
    {
      value: "VERY_LOW",
      valueNumber: 1,
      topLabel: "0 > 1,5",
      bottomLabel: intl ? intl.formatMessage(messages.veryLow) : null,
      ...(toolProcessConfigurations && {
        bottomLabelPopover: getLevelPopover(
          toolProcessConfigurations,
          "LEVEL_1"
        ),
      }),
      color: "#cd1616",
      constrast: "#fff",
    },
    {
      value: "LOW",
      valueNumber: 2,
      topLabel: "1,5 - 2,5",
      bottomLabel: intl ? intl.formatMessage(messages.low) : null,
      ...(toolProcessConfigurations && {
        bottomLabelPopover: getLevelPopover(
          toolProcessConfigurations,
          "LEVEL_2"
        ),
      }),
      color: "#f54e4e",
      constrast: "#fff",
    },
    {
      value: "PARTIAL",
      valueNumber: 3,
      topLabel: "2,5 - 3,5",
      bottomLabel: intl ? intl.formatMessage(messages.partial) : null,
      ...(toolProcessConfigurations && {
        bottomLabelPopover: getLevelPopover(
          toolProcessConfigurations,
          "LEVEL_3"
        ),
      }),
      color: "#ffc107",
      constrast: "#222",
    },
    {
      value: "HIGH",
      valueNumber: 4,
      topLabel: "3,5 - 4,5",
      bottomLabel: intl ? intl.formatMessage(messages.high) : null,
      ...(toolProcessConfigurations && {
        bottomLabelPopover: getLevelPopover(
          toolProcessConfigurations,
          "LEVEL_4"
        ),
      }),
      color: "#0c9247",
      constrast: "#222",
    },
    {
      value: "VERY_HIGH",
      valueNumber: 5,
      topLabel: "4,5 - 5",
      bottomLabel: intl ? intl.formatMessage(messages.veryHigh) : null,
      ...(toolProcessConfigurations && {
        bottomLabelPopover: getLevelPopover(
          toolProcessConfigurations,
          "LEVEL_5"
        ),
      }),
      color: "#2f7236",
      constrast: "#222",
    },
  ];
};
