import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import kpiUtils from "../../../../utils/kpis";
import GoalListItem from "../../GoalListItem";

const {
  exceededTargetMaxValue,
  getHitTargetInfo,
  filterIndicatorsByThemes,
  getThemePopover,
  getIndividualIndicatorsResult,
} = kpiUtils;

const messages = defineMessages({
  noDataToDisplay: {
    id: "global.noDataTable",
  },
  kpi_table_text_subthemes: {
    id: "kpi_table_text_subthemes",
  },
  kpi_table_text_scenarios_upper: {
    id: "kpi_table_text_scenarios_upper",
  },
});

const ThemeIndicatorsEvaluations = (props) => {
  const {
    kpiAdministration = {},
    displayProcesses = [],
    intl = {},
    isPeriodFilterActive: filter,
  } = props;
  const { goalTargets = {}, zeroWhenNegative } = kpiAdministration;

  let { themes, info } = filterIndicatorsByThemes(displayProcesses);
  const filteredThemes = [
    ...Object.keys(themes).map((themeId) => parseInt(themeId, 10)),
  ];

  let reorderedThemes = kpiAdministration.themes;

  return (
    <div className="evaluation-card">
      <div className="card-header">
        <div className="card-title">
          <h4>{intl.formatMessage(messages.kpi_table_text_scenarios_upper)}</h4>
        </div>
      </div>
      <div style={{ padding: "20px 0px 20px 15px" }}>
        <div className="card-body max-height-800" style={{ padding: "0px" }}>
          <div className="right-spacing">
            {reorderedThemes.map((themeInfo, index) => {
              if (filteredThemes.indexOf(themeInfo.id) > -1) {
                const themeGoals = themes[themeInfo.id] || [];

                if (themeGoals.length === 0) return null;

                const calculateAll =
                  themeGoals.filter(
                    ({ relationshipType }) => relationshipType === "INDIVIDUAL",
                  ).length === 0;

                const { realGoalsReachSum, evaluatedGoals } =
                  getIndividualIndicatorsResult(
                    themeGoals,
                    goalTargets,
                    calculateAll,
                    filter,
                    zeroWhenNegative,
                  );

                const hitDisplay = getHitTargetInfo(
                  realGoalsReachSum,
                  goalTargets,
                  evaluatedGoals.length === 0,
                );

                const themeRelationInfo = info[themeInfo.id] || {};
                const themeProcesses = themeRelationInfo.processes || [];
                const themesWithGoals = themeProcesses.filter(
                  (processRelation) => processRelation.processGoals?.length > 0,
                );

                return (
                  <GoalListItem
                    title={themeInfo.title}
                    description={`${
                      themesWithGoals.length
                    } ${intl.formatMessage(messages.kpi_table_text_subthemes)}`}
                    currentPercentage={realGoalsReachSum}
                    maxPercentage={exceededTargetMaxValue(goalTargets.exceeded)}
                    progressColor={hitDisplay.color}
                    barTotalWidth={100}
                    popover={getThemePopover(
                      themeInfo,
                      themesWithGoals,
                      goalTargets,
                      filter,
                      zeroWhenNegative,
                      calculateAll,
                    )}
                    key={index}
                  />
                );
              } else {
                return null;
              }
            })}
            {filteredThemes.length === 0 && (
              <h5 align="center">
                {intl.formatMessage(messages.noDataToDisplay)}
              </h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ThemeIndicatorsEvaluations);
