import _ from "lodash";
import { getDialog } from "../actions/notifications/dialogs";
import {
  INSIGHT_RESPONSIBLE_CHANGE,
  NEW_INSIGHT_COMMENT,
  INSIGHT_PRIORITIZATION_CHANGE,
  INSIGHT_CONNECTION_CHANGE,
  INSIGHT_ATTACHMENT_CHANGE,
  COMPANY_NEW_ADMIN,
  INSIGHT_NEW_MENTION,
  COMPANY_NEW_WORKSPACE,
  WORKSPACE_NEW_MODERATOR,
  WORKSPACE_NEW_TOOL,
  TOOL_NEW_MANAGER,
  TOOL_NEW_VIEWER,
  SCENERY_NEW_LEADER,
  SCENERY_NEW_VIEWER,
} from "../actions/notifications/types";
import store from "../Store";
import { getNotificationReceiversInfo } from "./accessLevels";

export const entityTypes = {
  INSIGHT: true,
  CENARY: true,
  SELECTED_TOOL: true,
  WORKSPACE: true,
  COMPANY: true,
  DOMAIN: true,
};

export const entityRoles = {
  OWNER: true,
  ADMIN: true,
  MODERATOR: true,
  MANAGER: true,
  LEADER: true,
  CREATOR: true,
  RESPONSIBLE: true,
  COLLABORATOR: true,
  TOOLVIEWER: true,
  SCENERYVIEWER: true,
};

export const bodyInfo = [
  {
    type: INSIGHT_RESPONSIBLE_CHANGE,
    getInfo: (data) => getResponsibleChangeInfo(data),
  },
  {
    type: NEW_INSIGHT_COMMENT,
    getInfo: (data) => getInsightCommentInfo(data),
  },
  {
    type: INSIGHT_PRIORITIZATION_CHANGE,
    getInfo: (data) => getPrioritizationChangeInfo(data),
  },
  {
    type: INSIGHT_CONNECTION_CHANGE,
    getInfo: (data) => getConnectionChangeInfo(data),
  },
  {
    type: INSIGHT_ATTACHMENT_CHANGE,
    getInfo: (data) => getAttachmentChangeInfo(data),
  },
  {
    type: COMPANY_NEW_ADMIN,
    getInfo: (data) => getNewAdminInfo(data),
  },
  {
    type: COMPANY_NEW_WORKSPACE,
    getInfo: (data) => getNewWorkspaceInfo(data),
  },
  {
    type: WORKSPACE_NEW_MODERATOR,
    getInfo: (data) => getNewModeratorInfo(data),
  },
  {
    type: WORKSPACE_NEW_TOOL,
    getInfo: (data) => getNewToolInfo(data),
  },
  {
    type: TOOL_NEW_MANAGER,
    getInfo: (data) => getNewManagerInfo(data),
  },
  {
    type: TOOL_NEW_VIEWER,
    getInfo: (data) => getNewToolViewerInfo(data),
  },
  {
    type: SCENERY_NEW_LEADER,
    getInfo: (data) => getNewLeaderInfo(data),
  },
  {
    type: SCENERY_NEW_VIEWER,
    getInfo: (data) => getNewSceneryViewerInfo(data),
  },
  {
    type: INSIGHT_NEW_MENTION,
    getInfo: (data) => getNewInsightMentionInfo(data),
  },
];

export const getBodyInfo = async (description = "", params = {}) => {
  const filteredInfo = bodyInfo.filter((info) => info.type === description);

  if (filteredInfo.length === 0) return null;

  return await filteredInfo[0].getInfo(params);
};

const getUserById = (userId = "") => {
  const state = store.getState();

  const { access = {} } = state || {};

  const { domainUsers = [] } = access || {};

  return domainUsers.filter((user) => user.id === userId)[0] || {};
};

const getEntityInfo = (parentDetails = [], entityType = "") => {
  const defaultBody = {
    entityName: "",
  };

  return (
    parentDetails.filter(
      (detail) => detail.entityType.toUpperCase() === entityType.toUpperCase(),
    )[0] || defaultBody
  );
};

const getNewAdminInfo = async ({
  senderId = "",
  entityType = "",
  parentDetails = [],
}) => {
  const { name: senderName = "" } = getUserById(senderId);
  const { entityName: companyName } = getEntityInfo(parentDetails, entityType);

  return { senderName: senderName + "</>", companyName: "</>" + companyName };
};

const getNewWorkspaceInfo = async ({
  senderId = "",
  entityType = "",
  parentDetails = [],
}) => {
  const { name: senderName = "" } = getUserById(senderId);
  const { entityName: companyName } = getEntityInfo(parentDetails, entityType);

  return { senderName: senderName + "</>", companyName: "</>" + companyName };
};

const getNewModeratorInfo = async ({
  senderId = "",
  entityType = "",
  parentDetails = [],
}) => {
  const { name: senderName = "" } = getUserById(senderId);
  const { entityName: workspaceName } = getEntityInfo(
    parentDetails,
    entityType,
  );

  return {
    senderName: senderName + "</>",
    workspaceName: "</>" + workspaceName,
  };
};

const getNewToolInfo = async ({
  senderId = "",
  entityType = "",
  parentDetails = [],
}) => {
  const { name: senderName = "" } = getUserById(senderId);
  const { entityName: workspaceName } = getEntityInfo(
    parentDetails,
    entityType,
  );

  return {
    senderName: senderName + "</>",
    workspaceName: "</>" + workspaceName,
  };
};

const getNewManagerInfo = async ({
  senderId = "",
  entityType = "",
  parentDetails = [],
}) => {
  const { name: senderName = "" } = getUserById(senderId);
  const { entityName: toolName } = getEntityInfo(parentDetails, entityType);

  return { senderName: senderName + "</>", toolName: "</>" + toolName };
};

const getNewToolViewerInfo = async ({
  senderId = "",
  entityType = "",
  parentDetails = [],
}) => {
  const { name: senderName = "" } = getUserById(senderId);
  const { entityName: toolName } = getEntityInfo(parentDetails, entityType);

  return { senderName: senderName + "</>", toolName: "</>" + toolName };
};

const getNewLeaderInfo = async ({
  senderId = "",
  entityType = "",
  parentDetails = [],
}) => {
  const { name: senderName = "" } = getUserById(senderId);
  const { entityName: sceneryName } = getEntityInfo(parentDetails, entityType);

  return { senderName: senderName + "</>", sceneryName: "</>" + sceneryName };
};

const getNewSceneryViewerInfo = async ({
  senderId = "",
  entityType = "",
  parentDetails = [],
}) => {
  const { name: senderName = "" } = getUserById(senderId);
  const { entityName: sceneryName } = getEntityInfo(parentDetails, entityType);

  return { senderName: senderName + "</>", sceneryName: "</>" + sceneryName };
};

const getNewInsightMentionInfo = async ({
  senderId = "",
  entityType = "",
  parentDetails = [],
}) => {
  const { name: senderName = "" } = getUserById(senderId);

  const { entityName: insightName } = getEntityInfo(parentDetails, entityType);

  return { senderName: senderName + "</>", insightName: "</>" + insightName };
};

const getResponsibleChangeInfo = async ({
  senderId = "",
  entityType = "",
  parentDetails = [],
}) => {
  const { name: senderName = "" } = getUserById(senderId);
  const { entityName: insightName } = getEntityInfo(parentDetails, entityType);

  return { senderName: senderName + "</>", insightName: "</>" + insightName };
};

const getInsightCommentInfo = async ({
  senderId = "",
  entityType = "",
  parentDetails = [],
}) => {
  const { name: senderName = "" } = getUserById(senderId);
  const { entityName: insightName } = getEntityInfo(parentDetails, entityType);

  return { senderName: senderName + "</>", insightName: "</>" + insightName };
};

const getPrioritizationChangeInfo = async ({
  senderId = "",
  entityType = "",
  parentDetails = [],
}) => {
  const { name: senderName = "" } = getUserById(senderId);
  const { entityName: insightName } = getEntityInfo(parentDetails, entityType);

  return { senderName: senderName + "</>", insightName: "</>" + insightName };
};

const getConnectionChangeInfo = async ({
  senderId = "",
  entityType = "",
  parentDetails = [],
}) => {
  const { name: senderName = "" } = getUserById(senderId);
  const { entityName: insightName } = getEntityInfo(parentDetails, entityType);

  return { senderName: senderName + "</>", insightName: "</>" + insightName };
};

const getAttachmentChangeInfo = async ({
  senderId = "",
  entityType = "",
  parentDetails = [],
}) => {
  const { name: senderName = "" } = getUserById(senderId);
  const { entityName: insightName } = getEntityInfo(parentDetails, entityType);

  return { senderName: senderName + "</>", insightName: "</>" + insightName };
};

export const checkParamValidations = async ({
  senderId = "",
  entityType = "",
  entityId = "",
  description = "",
}) => {
  const validateEntityType = entityTypes[entityType] || false;

  const validateEntityId = !isNaN(entityId) ? true : false;

  const validateSenderId = !isNaN(senderId) ? true : false;

  const types = await import("../actions/notifications/types");

  const validateDescription = types[description] ? true : false;

  if (
    validateEntityType &&
    validateEntityId &&
    validateDescription &&
    validateSenderId
  )
    return true;

  return false;
};

export const getValidReceivers = (receivers = []) => {
  let validReceivers = [];

  const addReceiver = (receiver = "") => {
    validReceivers = [...validReceivers, receiver];
  };

  receivers.forEach((receiver) => {
    if (Number.isInteger(Number(receiver)) || entityRoles[receiver]) {
      addReceiver(receiver);
    }
  });

  return validReceivers;
};

export const getNotificationReceivers = (notificationInfo) => {
  const { whoReceives = [] } = notificationInfo || {};

  let validReceivers = [];

  const everyoneReceives = ([...whoReceives] || []).length === 0;

  if (!everyoneReceives) validReceivers = getValidReceivers(whoReceives);

  return getNotificationReceiversInfo({
    everyoneReceives,
    receivers: validReceivers,
    ...notificationInfo,
  });
};

export const getDescriptionByType = async (info = {}) => {
  const { description = "" } = info || {};

  const bodyInfo = await getBodyInfo(description, info);

  if (!bodyInfo) return "";

  const dialog = getDialog(description, bodyInfo);

  return dialog;
};

export const getOrderedNotifications = (notifications = [], orderBy = "up") => {
  let numbers = [-1, 1];

  if (orderBy === "down") numbers = [1, -1];

  return notifications.sort((a, b) => {
    const a_attr = a.id;
    const b_attr = b.id;

    return a_attr > b_attr ? numbers[0] : b_attr > a_attr ? numbers[1] : 0;
  });
};

export const getNotificationWithAvatars = (notifications = []) => {
  let updatedNotifications = [...(notifications || [])];

  const allAvatarsCache = JSON.parse(localStorage.getItem("allAvatars")) || [];

  notifications.forEach((notification, index) => {
    const { senderId = "" } = notification || {};

    const { base64 = false } =
      allAvatarsCache.filter((avatar) => avatar.userId === senderId)[0] || {};

    updatedNotifications[index] = { ...notification, base64 };
  });

  return updatedNotifications;
};

export const getNonCachedIds = (notifications = []) => {
  let nonCachedIds = [];
  let ids = getSendersIds(notifications);

  const allAvatarsCache = JSON.parse(localStorage.getItem("allAvatars")) || [];
  const onlyUserIds = allAvatarsCache.map(({ userId }) => userId);

  ids.forEach((id) => {
    if (onlyUserIds.indexOf(id) === -1 && !nonCachedIds.includes(id))
      nonCachedIds.push(id);
  });

  return nonCachedIds;
};

const orderAndLimitNotifications = (notifications, buildLength) =>
  getOrderedNotifications(notifications).filter(
    (notification, index) => index < buildLength,
  );

const getSendersIds = (orderedNotifications) =>
  orderedNotifications.map(({ senderId }) => senderId);

export const getBuildedNotifications = async (notifications = [], size) => {
  let orderedNotifications = orderAndLimitNotifications(notifications, size);

  const updatedNotifications = getNotificationWithAvatars(orderedNotifications);

  return Promise.all(
    updatedNotifications.map(async (notification) => {
      return {
        ...notification,
        description: await getDescriptionByType(notification),
        builded: true,
      };
    }),
  );
};

export const buildLevelDetails = (notification) => {
  const { entityType = "" } = notification;
  const { insightData = {} } = store.getState();
  const { selectedInsight = {} } = insightData || {};
  const { id: selectedInsightId = "", name: insightName = "" } =
    selectedInsight || {};

  const details = {
    INSIGHT: {
      entityId: selectedInsightId,
      entityType: "INSIGHT",
      entityName: insightName,
    },
  };

  return details[entityType] || {};
};

export const checkEmailSender = async (notification = {}) => {
  let levelDetails = buildLevelDetails(notification);

  const { sendEmail = false } = notification || {};

  if (!sendEmail) return false;

  return await getDescriptionByType({
    ...notification,
    parentDetails: [{ ...levelDetails }],
  });
};
