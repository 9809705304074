import {
  checkIsNumber,
  getToFixed,
  ruleOfThree,
  simpleSumArray,
} from "../MathUtils";
import {
  getIndicatorValidPeriods,
  getPeriodTargetsByDistribution,
  getPolarity,
  getSingleIndicatorResults,
  handleGoalPercentage,
  multipleIndicatorsResult,
} from "./calc2";
import { getDeviationColor, getHitTargetInfo } from "./display";

export const getIndicatorTotalValidPeriods = (goalInfo) => {
  const indicatorValidPeriods = getIndicatorValidPeriods(goalInfo, false);

  return indicatorValidPeriods.length;
};

export const getEvaluationDivision = (
  targetValue = 0,
  goalDescriptions = {}
) => {
  const { measurement, measurementUnity = "MONETARY_VALUE" } = goalDescriptions;

  let finalResult = targetValue;

  if (measurementUnity === "DATE") {
    return targetValue;
  }

  const divider = getIndicatorTotalValidPeriods(goalDescriptions);

  if (divider) {
    const vTargetValue = parseFloat(targetValue);
    if (measurement === "AVERAGE" || measurement === "BALANCE")
      return vTargetValue;

    finalResult = vTargetValue / divider;
  }

  return finalResult;
};

export const getPeriodGoalDistributionPercentage = (
  goalInfo = {},
  totalPercentage = 0,
  periodInfo = {},
  targetsConfig = {}
) => {
  const periodTargets = getPeriodTargetsByDistribution(
    periodInfo.index,
    goalInfo
  );

  if (goalInfo.measurementUnity === "DATE") return totalPercentage;
  return handleGoalPercentage(periodInfo.value, periodTargets, targetsConfig);
};

export const getHitTarget = (real = null, targets = {}) => {
  if ((real === 0 || real) && !isNaN(real)) {
    const vReal = parseFloat(real);
    const vTargets = {
      gate: parseFloat(targets.gate),
      appropriated: parseFloat(targets.appropriated),
      exceeded: parseFloat(targets.exceeded),
    };

    const polarity = getPolarity(vTargets);

    if (polarity === "GREATER") {
      // Maior melhor
      if (vTargets.gate > vReal) return 0;
      if (vTargets.gate <= vReal && vTargets.appropriated > vReal) return 1;
      if (vTargets.appropriated <= vReal && vTargets.exceeded > vReal) return 2;
      if (vTargets.exceeded <= vReal) return 3;
    }

    if (polarity === "SMALLER") {
      // Menor melhor
      if (vTargets.gate < vReal) return 0;
      if (vTargets.gate >= vReal && vTargets.appropriated < vReal) return 1;
      if (vTargets.appropriated >= vReal && vTargets.exceeded < vReal) return 2;
      if (vTargets.exceeded >= vReal) return 3;
    }
  }

  return 0;
};

export const getBarTargetPixel = (
  pixelMultiplier = 0,
  reach,
  smaller_delimiter,
  greater_delimiter,
  barDivPixels = 80
) => {
  const pixelSum = pixelMultiplier * barDivPixels;

  const vReach = parseFloat(reach);
  const vSmaller_delimiter = parseFloat(smaller_delimiter);
  const vGreater_delimiter = parseFloat(greater_delimiter);

  if (vSmaller_delimiter < vReach && vGreater_delimiter > vReach) {
    const delimiterDiff = vGreater_delimiter - vSmaller_delimiter;
    const reachDelimiterDiff = vReach - vSmaller_delimiter;
    const reachDiffMultiply = reachDelimiterDiff * 100;
    const barDivisionPercentage = Math.abs(reachDiffMultiply / delimiterDiff);

    const finalBarPixels =
      (barDivisionPercentage * barDivPixels) / 100 + pixelSum;

    return getToFixed(finalBarPixels, 2);
  }

  return vReach <= vSmaller_delimiter ? pixelSum : pixelSum + barDivPixels;
};

export const getBarBonusMeasurement = (
  realGoalsReachSum,
  goalTargets,
  barDivPixels = 80
) => {
  const hitTargetLevel = getHitTargetInfo(realGoalsReachSum, goalTargets);
  const delimiter_zero = parseFloat(goalTargets.gate) * 0.7;
  const delimiter_one = parseFloat(goalTargets.gate);
  const delimiter_two = parseFloat(goalTargets.appropriated);
  const delimiter_three = parseFloat(goalTargets.exceeded);
  const delimiter_four = parseFloat(goalTargets.exceeded) * 1.3;

  const getPixels = (dA, dB) => {
    return getBarTargetPixel(
      hitTargetLevel.hitTarget,
      realGoalsReachSum,
      dA,
      dB,
      barDivPixels
    );
  };

  if (hitTargetLevel.hitTarget === 1) {
    return getPixels(delimiter_one, delimiter_two);
  }

  if (hitTargetLevel.hitTarget === 2) {
    return getPixels(delimiter_two, delimiter_three);
  }

  if (hitTargetLevel.hitTarget === 3) {
    return getPixels(delimiter_three, delimiter_four);
  }

  return getPixels(delimiter_zero, delimiter_one);
};

export const getIndividualIndicatorsResult = (
  goals = [],
  goalTargets = {},
  calculateAll = false,
  filterActive = false,
  zeroWhenNegative
) => {
  const individualGoals = [];

  if (Array.isArray(goals)) {
    goals.forEach((goalInfo) => {
      if (calculateAll || goalInfo.relationshipType === "INDIVIDUAL") {
        individualGoals.push(goalInfo);
      }
    });
  }

  return multipleIndicatorsResult(
    individualGoals,
    goalTargets,
    filterActive,
    zeroWhenNegative
  );
};

export const getProcessGoalsReachInfo = (
  processGoals = [],
  goalTargets = {},
  onlyAccumulated = false,
  zeroWhenNegative,
  calculateAll = false
) => {
  const { realGoalsReachSum } = getIndividualIndicatorsResult(
    processGoals,
    goalTargets,
    calculateAll,
    onlyAccumulated,
    zeroWhenNegative
  );

  const hitGoalsLevel = getHitTargetInfo(realGoalsReachSum, goalTargets);
  const barMeasurement = getBarBonusMeasurement(realGoalsReachSum, goalTargets);

  return {
    realGoalsReachSum,
    barMeasurement,
    hitGoalsLevel,
  };
};

export const getCountGoalStatus = (
  goals = [],
  goalTargets = {},
  onlyAccumulated = false,
  zeroWhenNegative
) => {
  const evaluations = {
    NOT_HIT: [],
    GATE: [],
    APPROPRIATED: [],
    EXCEEDED: [],
  };

  if (goals && goals.length > 0) {
    goals.forEach((goalInfo) => {
      const { goalPercentage, notStarted, accumulated } =
        getSingleIndicatorResults(goalInfo, goalTargets, zeroWhenNegative);

      const displayPercentage = onlyAccumulated
        ? accumulated.goalPercentage
        : goalPercentage;

      const hitTargetInfo = getHitTargetInfo(
        displayPercentage,
        goalTargets,
        notStarted
      );

      if (hitTargetInfo.hitTarget === 0) evaluations.NOT_HIT.push(goalInfo);
      if (hitTargetInfo.hitTarget === 1) evaluations.GATE.push(goalInfo);
      if (hitTargetInfo.hitTarget === 2)
        evaluations.APPROPRIATED.push(goalInfo);
      if (hitTargetInfo.hitTarget === 3) evaluations.EXCEEDED.push(goalInfo);
    });
  }

  return evaluations;
};

export const getCountProcessesGoalStatus = (
  goalsRelationTree = [],
  goalTargets = {},
  onlyAccumulated = false,
  zeroWhenNegative
) => {
  const evaluations = {
    NOT_HIT: [],
    GATE: [],
    APPROPRIATED: [],
    EXCEEDED: [],
  };

  goalsRelationTree.forEach((relationInfo) => {
    const { processGoals } = relationInfo;
    if (processGoals && processGoals.length > 0) {
      const calculateAll =
        processGoals.filter(
          ({ relationshipType }) => relationshipType === "INDIVIDUAL"
        ).length === 0;

      const { hitGoalsLevel } = getProcessGoalsReachInfo(
        processGoals,
        goalTargets,
        zeroWhenNegative,
        onlyAccumulated,
        calculateAll
      );

      const { hitTarget } = hitGoalsLevel;

      if (hitTarget === 0) evaluations.NOT_HIT.push(relationInfo);
      if (hitTarget === 1) evaluations.GATE.push(relationInfo);
      if (hitTarget === 2) evaluations.APPROPRIATED.push(relationInfo);
      if (hitTarget === 3) evaluations.EXCEEDED.push(relationInfo);
    }
  });

  return evaluations;
};

export const exceededTargetMaxValue = (exceeded) => {
  const vExceeded = Number(exceeded) > 0 ? Number(exceeded) : 0;
  return vExceeded * 1.3;
};

export const getIndicatorDeviation = (
  appropriated,
  acumulatedValue,
  fix = 1
) => {
  return getToFixed(ruleOfThree(appropriated, acumulatedValue) - 100, fix);
};

export const getComposedToolsApproximatedAverage = (
  toolPercentageEvaluations = []
) => {
  const finalRealApproximatedAverage =
    simpleSumArray(toolPercentageEvaluations) /
    toolPercentageEvaluations.length;

  return !isNaN(finalRealApproximatedAverage)
    ? finalRealApproximatedAverage
    : 0;
};

export const getDisplayTargets = (
  goalInfo,
  totalGoalMeasurement,
  courseTargetsDivision
) => {
  if (goalInfo && goalInfo.target) {
    const { target } = goalInfo;
    const { actual = {} } = target;

    return {
      displayCourseTargets: goalInfo.target,
      displayTotal:
        goalInfo.measurementUnity === "DATE"
          ? actual[0].value
          : totalGoalMeasurement,
    };
  }

  return {
    displayCourseTargets: courseTargetsDivision,
    displayTotal: totalGoalMeasurement,
  };
};

export const getLastPeriodDeviation = (
  lastPeriodValue,
  currentPeriodValue,
  target = {},
  notStarted = false
) => {
  const invertedPole = parseFloat(target.gate) > parseFloat(target.exceeded);
  const isCurrentBigger = currentPeriodValue > lastPeriodValue;
  const isPositiveByPole =
    (invertedPole && !isCurrentBigger) || (!invertedPole && isCurrentBigger);

  const valid =
    !notStarted &&
    checkIsNumber(lastPeriodValue) &&
    checkIsNumber(currentPeriodValue);
  const deviation = getIndicatorDeviation(lastPeriodValue, currentPeriodValue);

  const formattedDeviation = isPositiveByPole ? Math.abs(deviation) : deviation;

  return {
    deviation: formattedDeviation,
    displayDeviation: valid ? `${formattedDeviation}%` : "N/A",
    color: getDeviationColor(isPositiveByPole, valid),
    isPositiveByPole,
  };
};
