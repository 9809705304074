import React from "react";
import PropTypes from "prop-types";
import TableKeySeatEvaluation from "./TableKeySeatEvaluation";
import TablePerformanceEvaluation from "./TablePerformanceEvaluation";
import TableSuccessionEvaluation from "./TableSuccessionEvaluation";

const TableComplementaryEvaluationsRegister = ({
  open,
  title,
  type,
  enableImport,
  importScalesAndEvaluations,
  toolAdministration,
  updateAdministration,
  addComplementaryEvaluation,
  addComplementaryScale,
  openDeleteScale,
  openDeleteEvaluation,
  errors,
}) => {
  const DisabledMessage = () => (
    <div className="col-xs-12">
      <h5 style={{ color: "#9f9f9f", marginTop: "0px" }}>
        Desativada (para ativar atualize as Configurações Complementares da
        Avaliação)
      </h5>
    </div>
  );

  return open ? (
    <div className="row">
      <div className="col-xs-12">
        <h4 style={{ margin: "20px 0px 0px 0px" }}>{title}</h4>
      </div>
      <div className="row">
        {enableImport && (
          <div className="col-xs-12 col-lg-8">
            <div
              style={{
                backgroundColor: "#fffbd3",
                color: "#000",
                padding: "10px",
                lineHeight: "30px",
              }}
              className="col-xs-12"
            >
              <div className="col-xs-2">
                <button
                  className="btn btn-primary"
                  onClick={importScalesAndEvaluations}
                >
                  Importar
                </button>
              </div>
              <div className="col-xs-10">
                Parece que nenhuma avaliação foi cadastrada. Quer importar a
                configuração padrão?
              </div>
            </div>
          </div>
        )}
        {type === "KEY_SEAT" && (
          <TableKeySeatEvaluation
            toolAdministration={toolAdministration}
            updateAdministration={updateAdministration}
            addComplementaryEvaluation={addComplementaryEvaluation}
            addScale={addComplementaryScale}
            openDeleteScale={openDeleteScale}
            openDeleteEvaluation={openDeleteEvaluation}
            errors={errors}
          />
        )}
        {type === "PERFORMANCE" && (
          <TablePerformanceEvaluation
            toolAdministration={toolAdministration}
            updateAdministration={updateAdministration}
            addComplementaryEvaluation={addComplementaryEvaluation}
            addScale={addComplementaryScale}
            openDeleteScale={openDeleteScale}
            openDeleteEvaluation={openDeleteEvaluation}
            errors={errors}
          />
        )}
        {type === "SUCCESSION" && (
          <TableSuccessionEvaluation toolAdministration={toolAdministration} />
        )}
      </div>
    </div>
  ) : (
    <DisabledMessage />
  );
};

TableComplementaryEvaluationsRegister.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  toolAdministration: PropTypes.object.isRequired,
  updateAdministration: PropTypes.func.isRequired,
  addComplementaryEvaluation: PropTypes.func.isRequired,
  addComplementaryScale: PropTypes.func.isRequired,
  openDeleteScale: PropTypes.func.isRequired,
  openDeleteEvaluation: PropTypes.func.isRequired,
  enableImport: PropTypes.bool.isRequired,
  importScalesAndEvaluations: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
};

export default TableComplementaryEvaluationsRegister;
