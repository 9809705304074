import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { saveLinkBoardTheme } from "../../../../actions/kpiActions";
import {
  getSelectedToolStates,
  getCompAnalysisStates,
} from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";
import ThemeCreationAndLinkDialog from "../../../Common/CpAnalysis/forms/ThemeCreationAndLinkDialog";
import cpAnalysisUtils from "../../../../utils/cpAnalysis";
import {
  createThemeProcess,
  addThemeAndCreateLink,
} from "../../../../actions/compAnalysisActions";

const { getOnlySublevelArrays } = utils;

const { buildFormAdmConfigBody, buildRestAdmConfigBody } = cpAnalysisUtils;

const defaultCpConfig = buildFormAdmConfigBody({});

const CompetitiveAnalysis = (props) => {
  const [cpAnalysisAdministration, setCpAnalysisAdministration] = useState(
    defaultCpConfig,
  );
  const [addedAmbients, setAddedAmbients] = useState([]);

  const {
    type,
    clearViewCallback,
    selectedTool = {},
    isLoading = false,
    allKpiAdministration = [],
    saveLinkBoardTheme,
    createKpiThemeGroup,
    params,
    allThemes,
    currentToolType,
    currentAdministration,
    createThemeProcess,
    addThemeAndCreateLink,
  } = props;

  let onlyThemes = getOnlySublevelArrays(allThemes);

  useEffect(() => {
    if (!_.isEqual(currentAdministration, cpAnalysisAdministration)) {
      setCpAnalysisAdministration(currentAdministration);
    }
  }, [currentAdministration, cpAnalysisAdministration, selectedTool.id]);

  useEffect(() => {
    let newAdded = [];

    onlyThemes.forEach(({ competitiveAnalysisAmbientId }) => {
      if (competitiveAnalysisAmbientId)
        newAdded.push(competitiveAnalysisAmbientId);
    });

    if (!_.isEqual(newAdded, addedAmbients)) setAddedAmbients(newAdded);
  }, [addedAmbients, onlyThemes]);

  const getAnalysisAmbientInfo = (analysisTool, ambientId) => {
    const { analysisAmbient = [] } = analysisTool;

    return (
      analysisAmbient.filter((ambient) => {
        if (ambient.id === ambientId) return true;
      })[0] || {}
    );
  };

  const handleLinkTheme = ({ analysisAmbient = [] }) => {
    if (
      selectedTool.id &&
      Array.isArray(analysisAmbient) &&
      params.themeScenerie
    ) {
      analysisAmbient.forEach((ambientId) => {
        const ambientInfo = getAnalysisAmbientInfo(
          cpAnalysisAdministration[currentToolType],
          ambientId,
        );

        createThemeProcess(
          ambientInfo,
          params.themeScenerie,
          cpAnalysisAdministration[currentToolType],
          params.order,
        );
      });
    }

    clearDialogControll();
  };

  const handleCreateAndLinkTheme = (newCpAdministration) => {
    if (!_.isEqual(currentAdministration, newCpAdministration)) {
      const restBody = buildRestAdmConfigBody(newCpAdministration);

      addThemeAndCreateLink(
        selectedTool,
        params.themeScenerie,
        restBody,
        cpAnalysisAdministration[currentToolType],
        params.order,
      );
    }
    clearDialogControll();
  };

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
  };

  return (
    <ThemeCreationAndLinkDialog
      open={true}
      afterConfirm={handleLinkTheme}
      afterCancel={clearDialogControll}
      currentToolType={currentToolType}
      cpAnalysisAdministration={cpAnalysisAdministration}
      setCpAnalysisAdministration={setCpAnalysisAdministration}
      alternativeSaveCallback={handleCreateAndLinkTheme}
      addedAmbients={addedAmbients}
    />
  );
};

const mapStateToProps = (state) => {
  const { selectedTool, allThemes } = getSelectedToolStates(state);
  const {
    currentAdministration = {},
    currentToolType = {},
  } = getCompAnalysisStates(state);

  const { user } = state;

  return {
    userProfile: user,
    selectedTool,
    currentToolType,
    currentAdministration,
    allThemes,
  };
};

export default connect(mapStateToProps, {
  saveLinkBoardTheme,
  createThemeProcess,
  addThemeAndCreateLink,
})(CompetitiveAnalysis);
