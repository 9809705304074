import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup as BsButtonGroup } from 'react-bootstrap';

class ActionButtons extends Component {
    render() {
        return (
            <BsButtonGroup>
                {this.props.children}
            </BsButtonGroup>
        );
    }
}

ActionButtons.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

export default ActionButtons;