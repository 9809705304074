import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { createProjectPrioritizationGroupTheme } from "../../../../actions/projectPrioritizationActions";
import ThemeProjectSelectDialog from "../../../Common/ProjectPrioritization/forms/ThemeProjectSelectDialog";
import { getSelectedToolStates } from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";

const {
  getGoalsBonusBySelectedToolId,
  buildFormPrioritizationBody,
  getObjectInfoById,
} = utils;

const defaultInitialForm = buildFormPrioritizationBody({});

const ProjectPrioritization = (props) => {
  const [projectPrioritizationAdmin, setProjectPrioritizationAdmin] = useState(
    defaultInitialForm,
  );

  const {
    type,
    clearViewCallback,
    selectedTool = {},
    allProjectPrioritization = [],
    createProjectPrioritizationGroupTheme,
    params,
  } = props;

  useEffect(() => {
    let transformedProjectPrioritization = [];

    if (allProjectPrioritization) {
      transformedProjectPrioritization = getGoalsBonusBySelectedToolId(
        selectedTool.id,
        allProjectPrioritization,
      );

      transformedProjectPrioritization = buildFormPrioritizationBody(
        transformedProjectPrioritization,
      );
    }

    if (
      !_.isEqual(transformedProjectPrioritization, projectPrioritizationAdmin)
    ) {
      setProjectPrioritizationAdmin(transformedProjectPrioritization);
    }
  }, [allProjectPrioritization, projectPrioritizationAdmin, selectedTool.id]);

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
  };

  const handleSaveTheme = ({ groupId }) => {
    if (selectedTool.id && groupId && params.themeScenerie) {
      const projectGroupInfo = getObjectInfoById(
        groupId,
        projectPrioritizationAdmin.projectGroups,
        "id",
      );

      createProjectPrioritizationGroupTheme(
        params.themeScenerie,
        projectGroupInfo,
      );
    }

    clearDialogControll();
  };

  return (
    <ThemeProjectSelectDialog
      open
      afterConfirm={handleSaveTheme}
      afterCancel={clearDialogControll}
      projectPrioritizationAdmin={projectPrioritizationAdmin}
    />
  );
};

const mapStateToProps = (state) => {
  const { selectedTool } = getSelectedToolStates(state);

  const { user } = state;

  return {
    userProfile: user,
    selectedTool,
    allProjectPrioritization: state.projectPrioritization.all,
  };
};

export default connect(mapStateToProps, {
  createProjectPrioritizationGroupTheme,
})(ProjectPrioritization);
