import axios from "axios";
import {
  FETCH_THEME_QUESTIONS,
  UPDATE_THEME_QUESTION,
  DELETE_THEME_QUESTION,
  FETCH_ALL_THEMES_QUESTIONS,
} from "./types";

import { appRefreshSelectedTool } from "./appActions";

const getThemeQuestions = (themeId) => {
  return axios.get(`/api/questions/theme/${themeId}`).then((res) => res);
};

export const fetchThemeQuestions = (themeId) => {
  return (dispatch) => {
    getThemeQuestions(themeId).then((res) => {
      const data = {
        themeId,
        questions: res.data,
      };

      dispatch({ type: FETCH_THEME_QUESTIONS, payload: data });
    });
  };
};

export const fetchAllThemesQuestions = (themesList) => {
  return async (dispatch) => {
    const sendDispatch = (themesQuestions) => {
      dispatch({ type: FETCH_ALL_THEMES_QUESTIONS, payload: themesQuestions });
    };

    const allQuestions = {};

    const { data: questionsArray } =
      (await axios.post("api/questions/themes", themesList)) || {};
    questionsArray?.forEach((questionInfo) => {
      const { theme = {} } = questionInfo;

      if (theme?.id) {
        if (!allQuestions[theme.id]) allQuestions[theme.id] = [];
        allQuestions[theme.id].push(questionInfo);
      }
    });

    sendDispatch(allQuestions);

    return allQuestions;
  };
};

export const fetchQuestionsWithReturn = (themeId) => {
  return () => {
    return axios.get(`/api/questions/theme/${themeId}`).then((res) => {
      return res.data;
    });
  };
};

export const putQuestion = (questionBody) => {
  return axios.put("/api/questions", questionBody).then((res) => res);
};

export const updateQuestion = (questionBody, themeId) => {
  const isNew = !questionBody.id;

  return async (dispatch) => {
    const updatedQuestion = await putQuestion(questionBody);

    dispatch({
      type: UPDATE_THEME_QUESTION,
      payload: {
        question: {
          ...updatedQuestion.data,
          isNew,
        },
        themeId,
      },
    });
  };
};

export const deleteQuestion = (question) => {
  return (dispatch) => {
    return axios.delete(`/api/questions/${question.id}`).then((res) => {
      const { content: deletedQuestion } = res.data;

      if (deletedQuestion && deletedQuestion.theme) {
        dispatch({
          type: DELETE_THEME_QUESTION,
          payload: {
            themeId: deletedQuestion.theme.id,
            question: deletedQuestion,
          },
        });
      }
    });
  };
};

export const archiveQuestion = (question) => {
  return (dispatch) => {
    return axios.post("/api/archiveUnarchive", question).then((res) => {
      dispatch({ type: UPDATE_THEME_QUESTION, payload: res.data });
      dispatch(appRefreshSelectedTool(false));
    });
  };
};

export const changeQuestionName = (questionId = "", name = "") => {
  return axios
    .put(`/api/questions/${questionId}/${name}`)
    .then(({ data = {} }) => data);
};

export const doChangeQuestionName = (questionId = "", name = "") => {
  return async (dispatch) => {
    const updatedQuestion = await changeQuestionName(questionId, name);

    const { theme = {} } = updatedQuestion;
    const { id: themeId = 0 } = theme;

    const payload = {
      themeId,
      question: {
        ...updatedQuestion,
        isNew: false,
      },
    };

    dispatch({
      type: UPDATE_THEME_QUESTION,
      payload,
    });
  };
};
