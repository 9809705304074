import { FETCH_TOOL_POINTS, FETCH_TOOL_POINTS_COUNTER } from "../actions/types";

const INITIAL_STATE = {};

const toolPointsReducer = (state = INITIAL_STATE, action) => {
  const selectedToolId =
    action.payload && action.payload.selectedToolId
      ? action.payload.selectedToolId
      : null;
  const currentSelectedToolPoints = state[selectedToolId]
    ? state[selectedToolId]
    : {};

  switch (action.type) {
    case FETCH_TOOL_POINTS:
      return {
        ...state,
        [selectedToolId]: {
          ...currentSelectedToolPoints,
          points: action.payload.points,
        },
      };
    case FETCH_TOOL_POINTS_COUNTER:
      return {
        ...state,
        [selectedToolId]: {
          ...currentSelectedToolPoints,
          score: action.payload.score,
        },
      };
    default:
      return state;
  }
};

export default toolPointsReducer;
