import React from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import utils from "../../../../../../../utils/toolUtils";
import { deleteKpiGroup } from "../../../../../../../actions/kpiActions";
import {
  getEmptyTableRow,
  getDefaultAddTableLine,
  getDefaultEditColumn,
} from "../../../../../../../utils/tableUtils";
import { getDefaultRemoveColumn } from "../../../../../../../utils/okrUtils";

const {
  getUpdatedDeleteArrayByIndex,
  orderArrayByObjAttr,
  getObjectInfoById,
  getOnlySublevelArrays,
} = utils;

const messages = defineMessages({
  kpi_table_text_themes_upper: { id: "kpi_table_text_themes_upper" },
  kpi_warning_need_arquive_theme: { id: "kpi_warning_need_arquive_theme" },
  kpi_warning_connected_theme_theme: {
    id: "kpi_warning_connected_theme_theme",
  },
  kpi_confirm_delete_theme: { id: "kpi_confirm_delete_theme" },
  global_noneRegistered: { id: "global.noneRegistered" },
});

const GroupsConfigTable = (props) => {
  const {
    kpiAdministration,
    setKpiAdministration,
    setConfirmationDialog,
    resetConfirmDialog,
    handleSetEditingForm,
    toggleOpenFormDialogs,
    allThemes,
    deleteKpiGroup,
    intl,
  } = props;

  const handleDeleteGroup = (index) => {
    const deletingObjInfo = kpiAdministration.groups[index];

    if (deletingObjInfo.id) {
      deleteKpiGroup(deletingObjInfo.id, kpiAdministration.id);

      const updatedGroups = getUpdatedDeleteArrayByIndex(
        kpiAdministration.groups,
        index,
      );

      setKpiAdministration({
        ...kpiAdministration,
        groups: updatedGroups,
      });
    }

    resetConfirmDialog();
  };

  const openDeleteGroupDialog = (index) => {
    const deletingGroupInfo = kpiAdministration.groups[index] || {};
    let allowedToDelete = true;

    const onlyThemes = getOnlySublevelArrays(allThemes);

    if (deletingGroupInfo.id) {
      const relatedTheme = getObjectInfoById(
        deletingGroupInfo.id,
        onlyThemes,
        "kpiConfigurationGroupId",
      );

      allowedToDelete = relatedTheme && relatedTheme.id ? false : true;

      setConfirmationDialog({
        title: allowedToDelete
          ? intl.formatMessage(messages.kpi_confirm_delete_theme)
          : intl.formatMessage(messages.kpi_warning_connected_theme_theme),
        description: allowedToDelete
          ? null
          : intl.formatMessage(messages.kpi_warning_need_arquive_theme, {
              themeName: relatedTheme.name,
            }),
        onConfirm: allowedToDelete
          ? () => handleDeleteGroup(index)
          : resetConfirmDialog,
        confirmText: !allowedToDelete ? "OK" : null,
        hideCancel: !allowedToDelete ? true : false,
        onCancel: resetConfirmDialog,
        open: true,
      });
    }
  };

  let reoderedGroups = orderArrayByObjAttr(kpiAdministration.groups, "title");

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="100">
            {intl.formatMessage(messages.kpi_table_text_themes_upper)}
          </td>
        </tr>
      </thead>
      <tbody>
        {reoderedGroups.map((groupInfo, index) => {
          return (
            <tr key={`group_${index}`} className="block-hover">
              <td align="center" style={{ width: "40px" }}>
                {index + 1}
              </td>
              <td>{groupInfo.title}</td>
              {getDefaultEditColumn(() =>
                handleSetEditingForm("newGroup", { groupId: groupInfo.id }),
              )}
              {getDefaultRemoveColumn(() => openDeleteGroupDialog(index))}
            </tr>
          );
        })}
        {getEmptyTableRow(
          kpiAdministration.groups,
          intl.formatMessage(messages.global_noneRegistered),
        )}
        {getDefaultAddTableLine(() => toggleOpenFormDialogs("newGroup"))}
      </tbody>
    </table>
  );
};

export default injectIntl(connect(null, { deleteKpiGroup })(GroupsConfigTable));
