import React from "react";
import { connect } from "react-redux";
// constants
import {
  PROCESSES_BIG_PICTURE,
  SWOT,
  PEOPLE_BIG_PICTURE,
  PROJECT_PORTFOLIO,
  PROJECT_PRIORITIZATION,
  GOALS_AND_BONUS,
  OKR_ID,
  KPIS,
  COMPETITIVE_ANALYSIS,
  SKILLS_ASSESSMENT,
} from "../../../constants/tools.constants";
// customizations
import ProcessesBigPicture from "./customizations/ProcessesBigPicture";
import SwotTheme from "./customizations/SwotCustomization";
import PeopleBigPicture from "./customizations/PeopleBigPicture";
import ProjectCustomization from "./customizations/ProjectCustomization";
import ProjectPrioritization from "./customizations/ProjectPrioritization";
import GoalsAndBonus from "./customizations/GoalsAndBonus";
import Kpi from "./customizations/Kpi";
import OkrCustomization from "./customizations/OkrCustomization";
import CompetitiveAnalysis from "./customizations/CompetitiveAnalysis";
import SkillAssessment from "./customizations/SkillAssessment";

import {
  insightsGoalState,
  getSelectedToolStates,
  getAccessFilteredStates,
} from "../../customMapStates";

const BadgeToDisplay = (props) => {
  const { allAnswers, allQuestions, from, configs, info } = props;

  const toolID = props.info.tool.tool ? props.info.tool.tool.id : 0;
  const questionID = props.info.questionID || 0;

  const getQuestions = () => {
    return info.questions || [];
  };

  const getThemes = () => {
    return info.themes || [];
  };

  const getInsightID = () => {
    const { answer } = info;
    let insightID;

    if (answer && answer.type === "insight") {
      insightID = answer.id;
    }

    return insightID;
  };

  const getQuestionInsights = (questionID, onlyInsights = true) => {
    let questionInsights = [];
    const vQuestionID = parseInt(questionID, 10);

    if (allAnswers) {
      Object.keys(allAnswers).forEach((qID) => {
        const vqID = parseInt(qID, 10);
        if (vQuestionID === vqID) {
          const questionAnswers = allAnswers[vqID];

          questionAnswers.forEach((answerBody) => {
            if (answerBody.type === "insight" || !onlyInsights)
              questionInsights = [...questionInsights, answerBody];
          });
        }
      });
    }

    return questionInsights;
  };

  const getInsightsToCalc = () => {
    const questions = getQuestions();
    const themes = getThemes();
    let finalInsights = [];

    if (from === "cenary" && themes && themes.length > 0) {
      themes.forEach((themeInfo) => {
        const themeQuestions = allQuestions[themeInfo.id] || [];

        themeQuestions.forEach((questionInfo) => {
          const questionInsights = getQuestionInsights(questionInfo.id);
          finalInsights = [...finalInsights, ...questionInsights];
        });
      });
    }

    if (from === "theme" && questions && questions.length) {
      questions.forEach((questionInfo) => {
        const questionInsights = getQuestionInsights(questionInfo.id);
        finalInsights = [...finalInsights, ...questionInsights];
      });
    }

    if (from === "question" && questionID) {
      finalInsights = getQuestionInsights(questionID);
    }

    if (from === "answer" && allAnswers) {
      const insightID = getInsightID();

      Object.keys(allAnswers).forEach((qID) => {
        const questionAnswers = getQuestionInsights(qID);

        questionAnswers.forEach((answerBody) => {
          if (parseInt(answerBody.id, 10) === parseInt(insightID, 10)) {
            finalInsights = [...finalInsights, answerBody];
          }
        });
      });
    }

    return finalInsights;
  };

  const handleCustomization = () => {
    const appendProps = {
      insightsToCalc: getInsightsToCalc(),
      from,
      configs,
    };

    switch (toolID) {
      case PROCESSES_BIG_PICTURE:
        return <ProcessesBigPicture {...appendProps} />;
      case PEOPLE_BIG_PICTURE:
        return <PeopleBigPicture {...appendProps} />;
      case SWOT:
        return <SwotTheme {...appendProps} />;
      case PROJECT_PORTFOLIO:
        return <ProjectCustomization {...appendProps} />;
      case PROJECT_PRIORITIZATION:
        return <ProjectPrioritization {...appendProps} />;
      case GOALS_AND_BONUS:
        return <GoalsAndBonus {...appendProps} />;
      case KPIS:
        return <Kpi {...appendProps} />;
      case OKR_ID:
        return <OkrCustomization {...appendProps} />;
      case COMPETITIVE_ANALYSIS:
        return <CompetitiveAnalysis {...appendProps} />;
      case SKILLS_ASSESSMENT:
        return <SkillAssessment {...appendProps} />;
      default:
        return null;
    }
  };

  return <div style={{ width: "100%" }}>{handleCustomization()}</div>;
};

const EvaluationBadge = (props) => {
  return <BadgeToDisplay {...props} />;
};

const mapStateToProps = (state) => {
  const { toolsConfigurations = {} } = state;

  const { selectedTool = {} } = getSelectedToolStates(state);
  const { allInsightGoals } = insightsGoalState(state);

  const isGoalsAndBonus = !(selectedTool?.tool?.id === GOALS_AND_BONUS);

  const {
    filteredQuestions: allQuestions = {},
    filteredAnswers: allAnswers = {},
  } = getAccessFilteredStates(
    state,
    {
      sceneries: toolsConfigurations.showHiddenCenarys || false,
      themes: toolsConfigurations.showHiddenThemes || false,
      questions: toolsConfigurations.showHiddenQuestions || false,
      answers: toolsConfigurations.showHiddenAnswers || false,
    },
    isGoalsAndBonus,
  );

  return {
    allAnswers,
    allQuestions,
    allInsightGoals,
  };
};

export default connect(mapStateToProps)(EvaluationBadge);
