import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { complementaryWorkflowCommentTags } from "../../../../../../../utils/skillsAssessment/constants";
import { orderArrayByObjAttr } from "../../../../../../../utils/ArrayUtils";
import { checkGreaterThan } from "../../../../../../../utils/MathUtils";
import {
  buildComplementaryScales,
  getGroupedSubtypesWorkflow,
} from "../../../../../../../utils/skillsAssessment/filter";
import {
  getComplementaryWorkflowValue,
  getMaximumComplementaryScale,
  getMinimumComplementaryScale,
} from "../../../../../../../utils/skillsAssessment/calc";
import { getComplementaryTopicByTypes } from "../../../../../../../utils/skillsAssessment/display";
import WorkflowSuccessionEvaluation from "./WorkflowSuccessionEvaluation";
import { defaultSubtypesOrder } from "../../../../../../../utils/skillsAssessment/defaults";
import WorkflowComments from "./WorkflowComments";

const WorkflowComplementary = ({
  selectedToolId,
  toolAdministration,
  filterType,
  complementaryWorkflow,
  handleUpdate,
  openSelectSuccessor,
  insight,
  loggedUser,
  isRequired = true,
  isInsight = false,
}) => {
  const [showComments, setShowComments] = useState(null);

  const { complementaryScales } = toolAdministration;
  const typeScaleOptions = buildComplementaryScales(
    toolAdministration,
    filterType,
  );

  const toggleComments = (workflowId) => {
    const newShowComments = showComments === workflowId ? null : workflowId;
    setShowComments(newShowComments);
  };

  const evaluationCols = (workflowInfo, currentValue) => {
    const max = getMaximumComplementaryScale(complementaryScales, filterType);
    const min = getMinimumComplementaryScale(complementaryScales, filterType);

    const updateValue = (newValue) => {
      const validMinimum = checkGreaterThan(newValue, min) ? newValue : min;
      const validNewValue = checkGreaterThan(validMinimum, max)
        ? max
        : validMinimum;

      handleUpdate(workflowInfo, "value", validNewValue);
    };

    const selectValue =
      !currentValue && currentValue !== 0 ? "empty" : currentValue;

    const options = [
      {
        value: "empty",
        label: `${isRequired ? "* " : ""}- Selecione uma opção -`,
      },
      ...typeScaleOptions,
    ];
    const currentOption = options.find((option) => {
      return option.value === selectValue;
    });

    if (isInsight) {
      return (
        <td className="input-td" style={{ textAlign: 'center' }}>
          {currentOption.label}
        </td>
      );
    } else {
      return (
        <td className="input-td">
          <Select
            defaultValue={currentOption}
            value={currentOption}
            onChange={(option) => updateValue(option.value)}
            options={options}
            menuShouldScrollIntoView={false}
          />
        </td>
      );
    }
  };

  const commentsCol = (workflowId) => {
    const open = showComments === workflowId;
    const iconClass = open ? "fas fa-caret-down" : "fas fa-caret-right";

    return (
      <td
        onClick={() => toggleComments(workflowId)}
        align="center"
        style={{ cursor: "pointer" }}
        className="input-td"
        width="30"
      >
        <i className={iconClass} />
      </td>
    );
  };

  const renderComments = (workflowInfo) => {
    return (
      <tr className="block-hover">
        <td colSpan="100">
          <div style={{ padding: "15px" }}>
            <h4>Comentários e Observações</h4>
            <WorkflowComments
              selectedToolId={selectedToolId}
              insight={insight}
              tags={complementaryWorkflowCommentTags(workflowInfo).all}
              filter={(newComments) => newComments}
              loggedUser={loggedUser}
            />
          </div>
        </td>
      </tr>
    );
  };

  const groupedSubtypes = getGroupedSubtypesWorkflow(
    complementaryWorkflow,
    filterType,
  );

  const successionEvaluation = filterType === "SUCCESSION";
  const { title } = getComplementaryTopicByTypes(filterType);

  return (
    <div style={{ paddingBottom: "15px" }}>
      <h3 style={{ textDecoration: "underline", fontWeight: "normal" }}>
        {title}
      </h3>
      {defaultSubtypesOrder.map((subType) => {
        if (!groupedSubtypes[subType]) return null;

        const orderedWorkflow = orderArrayByObjAttr(
          groupedSubtypes[subType],
          "complementaryEvaluation",
          "title",
        );

        const { subtitle } = getComplementaryTopicByTypes(filterType, subType);

        return (
          <div key={`${filterType}${subType}`}>
            <h4>{subtitle}</h4>
            <table className="simple-table workflow-table">
              <thead>
                <tr className="small-font" style={{ fontWeight: "bold" }}>
                  <td title="Comentários/Observações">
                    <i className="far fa-comments" />
                  </td>
                  <td
                    style={{ fontSize: "12px" }}
                    colSpan={successionEvaluation ? "2" : "1"}
                  >
                    TEMA
                  </td>
                  {!successionEvaluation && (
                    <td style={{ fontSize: "12px" }}>OBSERVAÇÃO</td>
                  )}
                  <td width="220" style={{ fontSize: "12px" }}>
                    AVALIAÇÃO
                  </td>
                </tr>
              </thead>
              <tbody>
                {!successionEvaluation &&
                  orderedWorkflow?.map((workflowInfo) => {
                    const { complementaryEvaluation } = workflowInfo;
                    const value = getComplementaryWorkflowValue(workflowInfo);
                    const displayComments = showComments === workflowInfo.id;

                    return (
                      <React.Fragment key={workflowInfo.id}>
                        <tr>
                          {commentsCol(workflowInfo.id)}
                          <td>{complementaryEvaluation?.title}</td>
                          <td>{complementaryEvaluation?.description}</td>
                          {evaluationCols(workflowInfo, value)}
                        </tr>
                        {displayComments && renderComments(workflowInfo)}
                      </React.Fragment>
                    );
                  })}
                {successionEvaluation && (
                  <WorkflowSuccessionEvaluation
                    workflow={orderedWorkflow}
                    handleUpdate={handleUpdate}
                    openSelectSuccessor={openSelectSuccessor}
                    renderComments={renderComments}
                    workflowOpenComments={showComments}
                    commentsCol={commentsCol}
                    isInsight={isInsight}
                  />
                )}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};

WorkflowComplementary.propTypes = {
  filterType: PropTypes.string.isRequired,
  complementaryWorkflow: PropTypes.array.isRequired,
  toolAdministration: PropTypes.object.isRequired,
  insight: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func,
  openSelectSuccessor: PropTypes.func,
};

WorkflowComplementary.defaultProps = {
  handleUpdate: () => { },
  openSelectSuccessor: () => { },
};

export default WorkflowComplementary;
