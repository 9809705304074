import React from "react";
import { v4 as uuidv4 } from "uuid";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import moment from "moment";
import SelectExcelCollumn from "../../../../../Common/SelectExcelCollumn";
import SimpleExcelCollumn from "../../../../../Common/SimpleExcelCollumn";
import { TrEdit } from "../css/OkrCss";
import utils from "../../../../../../utils/toolUtils";

import { getMonthOptions } from "../../../../../../constants/monthsConstants";

const { getOnlySublevelArrays, getObjectInfoById } = utils;

const CicleRegister = (props) => {
  const {
    addCicle,
    removeCollumn = () => null,
    addTableLine = () => null,
    cycles,
    handleCicle,
    openFormDelete,
    handleDeleteCicle,
    onClick,
    editCicle,
    intl,
    allThemes,
  } = props;

  const getDialogDeleteCicle = (cicleId) => {
    const onlyTheme = getOnlySublevelArrays(allThemes);

    const relatedTheme = getObjectInfoById(
      cicleId,
      onlyTheme,
      "okrConfigurationCycleId",
    );

    const setDeleteCicle = {
      handleDelete(index) {
        if (!relatedTheme.id || !cicleId) {
          return handleDeleteCicle(index, relatedTheme, cicleId);
        }

        return null;
      },

      getOpen() {
        return true;
      },

      getDescription() {
        return relatedTheme.id && cicleId
          ? intl.formatMessage(
              { id: "okr_warning_need_arquive_cycle" },
              { cycleName: relatedTheme.name },
            )
          : "";
      },

      getTitle() {
        return intl.formatMessage({ id: "okr_confirm_delete_cycle" });
      },

      getConfirmText() {
        return relatedTheme.id ? "OK" : "defaultConfirm";
      },
    };

    return setDeleteCicle;
  };

  Date.prototype.isDate = function () {
    return !!(this !== "Invalid Date" && !isNaN(this));
  };

  function monthCycles(date = 0, cycleId) {
    const newDate = new Date(date);
    const month = newDate.getMonth();

    const incrementMonthIfEditCicle = !cycleId ? month + 1 : month;

    const monthFormat = moment()
      .month(incrementMonthIfEditCicle)
      .format("MMMM");

    if (newDate.isDate()) return monthFormat.toString();
    return date.toString();
  }

  const options = getMonthOptions();

  const defaultSelect = {
    options,
    type: "select",
  };

  return (
    <div>
      <table className="simple-table">
        <thead>
          <tr onClick={onClick}>
            <td>{intl.formatMessage({ id: "cycle_name" })}</td>
            <td>{intl.formatMessage({ id: "start_date" })}</td>
            <td>{intl.formatMessage({ id: "end_date" })}</td>
            <td>{intl.formatMessage({ id: "check_date" })}</td>
            {removeCollumn && <td />}
          </tr>
        </thead>
        <tbody>
          {cycles &&
            cycles.map((cycle, index) => {
              return (
                <TrEdit key={cycle.id}>
                  <SimpleExcelCollumn
                    id={`nameCicle-${uuidv4()}`}
                    label="name"
                    value={cycle.title}
                    onChange={(e) =>
                      handleCicle(index, "title", e.target.value, cycle.id)
                    }
                    onClick={onClick}
                    editCicle={editCicle}
                  />
                  <SelectExcelCollumn
                    fieldName="startDate"
                    value={monthCycles(cycle.startDate, cycle.id)}
                    index={index}
                    idCycle={cycle.id}
                    handleChange={handleCicle}
                    {...defaultSelect}
                    onClick={onClick}
                    editCicle={editCicle}
                  />
                  <SelectExcelCollumn
                    fieldName="endDate"
                    value={monthCycles(cycle.endDate, cycle.id)}
                    index={index}
                    idCycle={cycle.id}
                    handleChange={handleCicle}
                    {...defaultSelect}
                    onClick={onClick}
                    editCicle={editCicle}
                  />
                  <SelectExcelCollumn
                    fieldName="checkDate"
                    value={monthCycles(cycle.checkDate, cycle.id)}
                    index={index}
                    idCycle={cycle.id}
                    handleChange={handleCicle}
                    {...defaultSelect}
                    editCicle={editCicle}
                    onClick={onClick}
                  />
                  {removeCollumn &&
                    removeCollumn(() =>
                      openFormDelete(
                        cycle.index,
                        getDialogDeleteCicle(cycle.id),
                      ),
                    )}
                </TrEdit>
              );
            })}
          {addTableLine(() => addCicle(cycles.length))}
        </tbody>
      </table>
    </div>
  );
};

export default injectIntl(CicleRegister);

CicleRegister.propTypes = {
  addCicle: PropTypes.func,
  removeCollumn: PropTypes.func,
  addTableLine: PropTypes.func,
  cycles: PropTypes.array.isRequired,
  handleCicle: PropTypes.func,
  openFormDelete: PropTypes.func.isRequired,
  handleDeleteCicle: PropTypes.func,
  onClick: PropTypes.func,
  editCicle: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  allThemes: PropTypes.object,
};

CicleRegister.defaultProps = {
  removeCollumn: null,
  addCicle: () => null,
  onClick: () => null,
  addTableLine: () => null,
  allThemes: {},
  handleDeleteCicle: () => null,
  handleCicle: () => null,
};
