const en = {
  okr_main_tile: "OKRs",
  insight_add: "Add key result",
  key_results: "Key Results",
  add_key_result: "Add key result",
  question_add: "Add objective",
  theme_add: "Add cycles",
  edit_cycle: "Edit Cycle",
  dialog_title_new_scenerie: "Add new Area",
  error_exist_area: "This area has already been created",
  dialog_title_new_question: "Add new Cycle",
  dialog_title_new_theme: "Add new Objective",
  dialog_title_new_insight: "Add new Key Result",
  dialog_error_question_repeat: "This Objective has already entered on a Cycle",
  dialog_error_cycle_repeat: "This Cycle has already entered on a Scenerie",
  dialog_error_insight_repeat: "This Key has already entered on a Objective",
  view_key_results: "View by Key Result",
  title_okr_deliveries: "Cycle View",
  title_objectives_deliveries: "Vision by Objective",
  title_teams_monitoring: "View by Scenario",
  title_responsible_okr: "Responsible for okr",
  td_responsible: "Responsible",
  scenerie_name: "Scenerie Name",
  info_key_results:
    "Register key results on the board or link from the administration to view",
  info_objectives:
    "Register goals on the board or link from management to view",
  info_insights_responsibles:
    "Register on the board insights to demonstrate those responsible for viewing",
  info_plans:
    "Register teams / areas on the board or link from the administration to view",
  info_cycles: "No Key Results have been evaluated",
  teams_areas: "Teams / areas",
  team_table_title: "SCENARIO REGISTRATION",
  overallperformance: "Overall Performance",
  performance_responsable: "Performance by Responsible",
  scenerie_description: "Scenerie description",
  cycle_group: "Cycle groups",
  register_group: "Register a Group",
  group_year: "Group Year",
  no_cycle_group: "No cycle group registered",
  add_cycle: "Add cycles",
  add_objective: "Add objective",
  title_error: "The name field is required",
  min_caractere: "The name field needs more characters",
  cycle_year: "Year of cycles",
  cycle_name: "Cycle name",
  cycle_title: "Cycles",
  objective_name: "Objective name",
  start_date: "Start",
  end_date: "End",
  check_date: "Check",
  all_inputs_alert: "Fill in all fields correctly",
  fill_date_alert: "Fill in a date",
  no_cycle: "No cycles registered",
  select_scenerie: "Select a scenario or register a cycle",
  objectives_achieved: "OBJECTIVES TO BE ACHIEVED",
  objective_title: "Objectives",
  objective_repeat: "There is already a objective with this name",
  key_results_delivered: "KEY RESULTS TO BE DELIVERED",
  key_result_repeat: "There is already a key result with this name",
  select_objective: "Select an objective",
  name_key_result: "Key Result Name",
  scenerie_cycle: "Scenario and Cycle",
  objective_key_result: "Objective and Key Result",
  okr_confirm_delete_cenary: "Are you sure you want to delete this Scenario?",
  okr_warning_need_arquive_cenary:
    "You need to archive the scenario ''{cenaryName}'' to continue deleting this Scenario.",
  okr_warning_need_arquive_cycle:
    "You need to archive the cycle ''{cenaryName}'' to continue deleting this Cycle.",
  okr_confirm_delete_question:
    "Are you sure you want to delete this objective?",
  okr_warning_need_arquive_question:
    "You need to archive the objective ''{objectiveName}'' to continue deleting this objective.",
  okr_confirm_delete_key: "Are you sure you want to delete this key result?",
  okr_confirm_delete_cycle: "Are you sure you want to delete this cycle?",
  okr_warning_need_arquive_key:
    "You need to archive the scenario ''{keyName}'' to continue deleting this key result.",
  tracking_keyResults: "TRACKING BY KEY RESULTS",
  tracking: "Tracking",
  search_okr: "Search for objectives or key results",
  delivery_performance_okr: "Achievement",
  reach_performance_okr: "Reach",
  key_results_counter: "key results",
  pattern_of_cycles: "Pattern of Cycles",
  pattern_name: "Pattern Name",
  no_cycle_added: "Add at least one cycle to record the cycle pattern",
  delete_pattern_cycle: "Deseja realmente excluir este padrão de ciclos?",
  select_cycles: "Select cycles",
  select_pattern_cycles: "Select a Cycle Pattern",
  not_possible_add_pattern: "Could not add Cycle Pattern",
  small_number_cycles:
    "The new number of Cycles is less than or the same as the current one, please remove the previous cycles to continue.",
  already_cycles_registered: "There are already cycles registered",
  number_of_cycles_registered:
    "There are already '' {cycleLength} '' cycles registered, only the cycles below will be added.",
};

const es = {
  okr_main_tile: "OKRs",
  insight_add: "Agregar resultado clave",
  key_results: "Resultados Clave",
  add_key_result: "Adicionar resultados clave",
  theme_add: "Agregar ciclos",
  edit_cycle: "Ciclo de Edición",
  question_add: "Agregar objetivo",
  dialog_title_new_scenerie: "Agregar nueva Zona",
  error_exist_area: "Esta área ya ha sido creada",
  dialog_title_new_question: "Agregar nuevo Ciclo",
  dialog_title_new_insight: "Agregar nuevo Resultado clave",
  dialog_title_new_theme: "Agregar nuevo Objetivo",
  dialog_error_insight_repeat: "Esta Clave ya ha ingresado en un Objetivo",
  dialog_error_question_repeat: "Este Objetivo ya ha ingresado en un Ciclo",
  dialog_error_cycle_repeat: "Este Ciclo ya ha ingresado en un Escenario",
  title_okr_deliveries: "Vista de ciclo",
  title_objectives_deliveries: "Visión por objetivo",
  title_teams_monitoring: "Ver por escenario",
  title_responsible_okr: "Responsable de okr",
  td_responsible: "Responsable",
  info_key_results:
    "Registre los resultados clave en el tablero o enlace de la administración para ver",
  info_objectives:
    "Registre objetivos en el tablero o enlace de la gerencia para ver",
  info_insights_responsibles:
    "Regístrese en los consejos para demostrar a los responsables de la visualización",
  info_plans:
    "Registre equipos / áreas en el tablero o enlace de la administración para ver",
  info_cycles: "No se han evaluado Resultados Clave",
  teams_areas: "Equipos / áreas",
  team_table_title: "REGISTRO DE ESCENARIO",
  overallperformance: "Rendimiento global",
  performance_responsable: "Actuación de Responsável",
  scenerie_name: "Nombre del escenario",
  scenerie_description: "Descripción del escenario",
  cycle_group: "Grupo de Ciclos",
  register_group: "Registrar un grupo",
  group_year: "Año grupal",
  no_cycle_group: "Sin grupo de ciclo registrado",
  add_cycle: "Agregar ciclos",
  add_objective: "Agregar objetivos",
  title_error: "El campo de nombre es obligatorio",
  min_caractere: "El campo de nombre necesita más caracteres",
  cycle_year: "Año de ciclos",
  cycle_name: "Nombre del ciclo",
  cycle_title: "Ciclos",
  objective_name: "Nombre del objetivo",
  fecha_inicio: "Inicio",
  end_date: "Fin",
  check_date: "Verificar",
  all_inputs_alert: "Rellene todos los campos correctamente",
  fill_date_alert: "Complete una fecha",
  no_cycle: "No hay ciclos registrados",
  select_scenerie: "Seleccione un escenario o registre un ciclo",
  objectives_achieved: "OBJETIVOS A LOGRAR",
  objective_title: "Objetivos",
  objective_repeat: "Ya hay un objetivo con este nombre",
  key_results_delivered: "RESULTADOS CLAVE A ENTREGAR",
  key_result_repeat: "Ya hay un resultado clave con este nombre",
  select_objective: "Selecciona un objetivo",
  name_key_result: "Nombre del resultado clave",
  scenerie_cycle: "Escenario y Ciclo",
  objective_key_result: "Objetivo y resultado clave",
  okr_confirm_delete_cenary:
    "¿Está seguro de que desea eliminar este escenario?",
  okr_warning_need_arquive_cenary:
    "Debe archivar el escenario '' {cenaryName} '' para continuar eliminando este escenario",
  okr_warning_need_arquive_cycle:
    "Debe archivar el ciclo '' {cycleName} '' para continuar eliminando este ciclo",
  okr_confirm_delete_question:
    "¿Está seguro de que desea eliminar este objetivo?",
  okr_warning_need_arquive_question:
    "Debe archivar el objetivo '' {objectiveName} '' para continuar eliminando este objetivo.",
  okr_confirm_delete_key:
    "¿Está seguro de que desea eliminar este resultado clave?",
  okr_confirm_delete_cycle: "¿Está seguro de que desea eliminar este ciclo?",
  okr_warning_need_arquive_key:
    "Debe archivar el escenario '' {keyName} '' para continuar eliminando este resultado clave.",
  tracking_keyResults: "SEGUIMIENTO POR RESULTADOS CLAVE",
  tracking: "Seguimiento",
  search_okr: "Busque objetivos o resultados clave",
  delivery_performance_okr: "Logro",
  reach_performance_okr: "Alcanze",
  key_results_counter: "resultados clave",
  pattern_of_cycles: "Patrón de Ciclos",
  pattern_name: "Nombre del Patrón",
  no_cycle_added:
    "Agregue al menos un ciclo para registrar el patrón del ciclo",
  delete_pattern_cycle: "¿Realmente quieres eliminar este patrón de ciclos?",
  select_cycles: "Seleccionar ciclos",
  select_pattern_cycles: "Seleccionar un patrón de ciclo",
  not_possible_add_pattern: "No se pudo agregar el patrón de ciclo",
  small_number_cycles:
    "El nuevo número de ciclos es menor o igual que el actual, elimine los ciclos anteriores para continuar",
  already_cycles_registered: "Ya hay ciclos registrados",
  number_of_cycles_registered:
    "Ya hay ciclos '' {cycleLength} '' registrados, solo se agregarán los siguientes ciclos",
};

const pt = {
  scenerie_description: "Descrição do cenário",
  okr_main_tile: "OKRs",
  insight_add: "Adicionar resultado chave",
  key_results: "Resultados Chaves",
  add_key_result: "Adicionar Resultado Chave",
  view_key_results: "Visão por Resultado Chave",
  title_okr_deliveries: "Visão por Ciclo",
  title_objectives_deliveries: "Visão por Objetivo",
  title_teams_monitoring: "Visão por Cenário",
  title_responsible_okr: "Responsáveis por okr",
  td_responsible: "Responsáveis",
  info_key_results:
    "Cadastre resultados chaves no quadro ou vincule da administração para visualizar",
  info_objectives:
    "Cadastre objetivos no quadro ou vincule da administração para visualizar",
  info_insights_responsibles:
    "Cadastre no quadro insights para demonstrar os responsáveis para visualizar",
  info_plans:
    "Cadastre times / áreas no quadro ou vincule da administração para visualizar",
  info_cycles: "Nenhum resultado chave foi avaliado",
  theme_add: "Adicionar ciclos",
  edit_cycle: "Editar Ciclo",
  question_add: "Adicionar objetivo",
  dialog_title_new_scenerie: "Adicionar nova Área",
  error_exist_area: "This area has already been created",
  dialog_title_new_question: "Adicionar novo Ciclo",
  dialog_title_new_insight: "Adicionar novo Resultado Chave",
  dialog_title_new_theme: "Adicionar novo Objetivo",
  dialog_error_insight_repeat:
    "Este Resultado Chave já está cadastrado em um Objetivo",
  dialog_error_question_repeat: "Este Objetivo já está cadastrado em um Ciclo",
  dialog_error_cycle_repeat: "Este Ciclo já está cadastrado em um Cenário",
  teams_areas: "Times / áreas",
  team_table_title: "CADASTRO DE CENÁRIOS",
  overallperformance: "Performance Geral",
  performance_responsable: "Performance por Responsável",
  scenerie_name: "Nome do cenário",
  cycle_group: "Grupo de Ciclos",
  register_group: "Cadastrar um grupo",
  group_year: "Ano do grupo",
  no_cycle_group: "Nenhum grupo de ciclo cadastrado",
  add_cycle: "Adicionar ciclos",
  add_objective: "Adicionar objetivos",
  title_error: "O campo nome é requerido",
  min_caractere: "O campo nome precisa de mais caracteres",
  cycle_year: "Ano dos ciclos",
  cycle_name: "Nome do ciclo",
  cycle_title: "Ciclos",
  start_date: "Início",
  end_date: "Término",
  check_date: "Check",
  all_inputs_alert: "Preencha todos os campos corretamente",
  fill_date_alert: "Preencha uma data",
  no_cycle: "Nenhum ciclo cadastrado",
  select_scenerie: "Selecione um cenário ou cadastre um ciclo",
  objectives_achieved: "OBJETIVOS A SEREM ATINGIDOS",
  objective_title: "Objetivos",
  objective_repeat: "Já existe um objetivo com este nome",
  objective_name: "Nome do objetivo",
  key_results_delivered: "RESULTADOS CHAVES A SEREM ENTREGUES",
  key_result_repeat: "Já existe um resultado chave com este nome",
  select_objective: "Selecione um objetivo",
  name_key_result: "Nome do resultado chave",
  scenerie_cycle: "Cenário e Ciclo",
  objective_key_result: "Objetivo e Resultado Chave",
  okr_confirm_delete_cenary: "Tem certeza de que deseja excluir este cenário?",
  okr_confirm_delete_question:
    "Tem certeza de que deseja excluir este objetivo?",
  okr_warning_need_arquive_question:
    "Você precisa arquivar o objetivo '' {objectiveName} '' para continuar excluindo este objetivo.",
  okr_warning_need_arquive_cenary:
    "Você precisa arquivar o cenário '' {cenaryName} '' para continuar excluindo este cenário.",
  okr_warning_need_arquive_cycle:
    "Você precisa arquivar o ciclo '' {cycleName} '' para continuar excluindo este ciclo.",
  okr_confirm_delete_key:
    "Tem certeza de que deseja excluir este resultado chave?",
  okr_confirm_delete_cycle: "Tem certeza de que deseja excluir este ciclo?",
  okr_warning_need_arquive_key:
    "Você precisa arquivar o cenário '' {keyName} '' para continuar excluindo este resultado chave.",
  tracking_keyResults: "RASTREAMENTO POR RESULTADOS CHAVES",
  tracking: "Rastreamento",
  search_okr: "Buscar por objetivos ou resultados chaves",
  delivery_performance_okr: "Realização",
  reach_performance_okr: "Alcance",
  key_results_counter: "resultados chave",
  pattern_of_cycles: "Padrão de Ciclos",
  pattern_name: "Nome do Padrão",
  no_cycle_added:
    "Adicione pelo menos um ciclo para cadastrar o padrão de ciclos",
  delete_pattern_cycle: "Deseja realmente excluir este padrão de ciclos?",
  select_cycles: "Selecionar ciclos",
  select_pattern_cycles: "Selecione um Padrão de Ciclos",
  not_possible_add_pattern: "Não foi possível adicionar o Padrão de Ciclos",
  small_number_cycles:
    "A nova quantidade de Ciclos é menor ou a mesma que a atual, remova os ciclos anteriores para continuar.",
  already_cycles_registered: "Já existem ciclos cadastrados",
  number_of_cycles_registered:
    "Já existem '' {cycleLength} '' ciclos cadastrados, apenas os ciclos abaixo serão adicionados.",
};

const translations = { en, es, pt };

export default translations;
