import store from "../../../../../../Store";
import { getObjectInfoById } from "../../../../../../utils/ArrayUtils";
import { checkIsAdmin } from "../../../../../../utils/skillsAssessment/filter";
import {
  getSelectedToolStates,
  getSkillAssessmentStates,
} from "../../../../../customMapStates";

const defaultTabs = {
  management: true,
  tool: true,
  score: false,
  timeline: false,
};

export const getAdministration = () => {
  const state = store.getState();
  const { selectedToolID } = getSelectedToolStates(state);
  const { allSkillAssessment } = getSkillAssessmentStates(state);

  if (Array.isArray(allSkillAssessment)) {
    const filtered = getObjectInfoById(
      selectedToolID,
      allSkillAssessment,
      "selectedToolId",
    );

    return filtered;
  }

  return null;
};

export const haveClientFullAccess = (administration) => {
  const { generalOptions } = administration;
  const { restrictViewAccess } = generalOptions || {};

  return restrictViewAccess === false || checkIsAdmin();
};

export const getSkillsAssessmentTabsConfig = () => {
  const administration = getAdministration();
  if (administration?.selectedToolId) {
    if (haveClientFullAccess(administration))
      return {
        management: true,
        tool: true,
        score: true,
        timeline: true,
      };
  }

  return defaultTabs;
};

export const getSkillsAssessmentInsightAccess = () => {
  const administration = getAdministration();

  if (administration?.selectedToolId) {
    if (haveClientFullAccess(administration))
      return { allowedToOpenInsight: true };
  }

  return { allowedToOpenInsight: false };
};
