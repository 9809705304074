const INITIAL_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  participants: [],
  type: "",
};

const participantReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CREATE_NEW_PARTICIPANT":
      return { ...state, participants: action.payload };
    case "GET_ALL_THE_PARTICIPANTS":
      return {
        ...state,
        participants: action.payload.data,
        type: "Participant",
      };
    default:
      return state;
  }
};
export default participantReducer;
