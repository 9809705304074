import React, { Component } from "react";
import { injectIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import MaterialSingleSelect from "../../../Common/MaterialSingleSelect";
import { globalMessages } from "../../../../utils/global";
import { getSwotEvaluationByID } from "../../../../utils/swotUtils";
import {
  formatDate,
  EXTENDED_DATE_TIME_FORMAT,
} from "../../../../utils/DateUtils";
import {
  generateImportancePoints,
  generateImpactPoints,
  generateReliabilityPoints,
  getImportance,
  getImpact,
  getReliability,
} from "../../../../constants/swotCustom";

import { addSwotEvaluation } from "../../../../actions/swotEvaluationActions";
import { checkAccess } from "../../../../utils/accessLevels";
import InsightActionPlan from "../../../Common/ActionPlan/InsightActionPlan";
import { getSelectedToolStates } from "../../../customMapStates";

class SW_Class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      impactOnResults: "",
      importanceLevel: "",
      reliabilityLevel: "",
      totalScore: 0,
      scoreDate: "",
      updateValues: false,
      allSwotEvaluation: [],
    };

    this.generateScore = this.generateScore.bind(this);
  }
  insightMessages = defineMessages({
    paramLevel: {
      id: "swot.paramLevel",
    },
    searchResponsible: {
      id: "cenaryResumeDetails.label.searchResponsible",
    },
  });

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      !_.isEqual(this.state.allSwotEvaluation, newProps.allSwotEvaluation) ||
      !_.isEqual(this.props.insight, newProps.insight)
    ) {
      this.setState({
        allSwotEvaluation: newProps.allSwotEvaluation,
        insight: newProps.insight,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { allSwotEvaluation, insight } = this.state;
    if (
      prevState.importanceLevel !== this.state.importanceLevel ||
      prevState.impactOnResults !== this.state.impactOnResults ||
      prevState.reliabilityLevel !== this.state.reliabilityLevel
    ) {
      if (this.state.updateValues) {
        this.doEvaluationsUpdate();
      }
    }

    if (
      !_.isEqual(prevState.allSwotEvaluation, allSwotEvaluation) ||
      !_.isEqual(prevState.insight, insight)
    ) {
      this.setCurrentEvaluation();
    }
  }

  setCurrentEvaluation() {
    const { allSwotEvaluation, insight } = this.state;
    const currentEvaluation = getSwotEvaluationByID(
      insight.id,
      allSwotEvaluation,
    );

    if (currentEvaluation) {
      const {
        id,
        importanceLevel,
        impactOnResults,
        reliabilityLevel,
        score,
      } = currentEvaluation.swotAnalysis;
      this.setState({
        id,
        importanceLevel: importanceLevel === null ? "" : importanceLevel,
        impactOnResults: impactOnResults === null ? "" : impactOnResults,
        reliabilityLevel: reliabilityLevel === null ? "" : reliabilityLevel,
        totalScore: score !== null ? score : 0,
        updateValues: false,
      });
    }
  }

  buildOptions = (list) => {
    let finalOptions = [];

    list.forEach(({ label, key }) => {
      finalOptions = [...finalOptions, { label, value: key }];
    });

    return finalOptions;
  };

  generateScore() {
    const { importanceLevel, impactOnResults, reliabilityLevel } = this.state;
    const importancePoints = generateImportancePoints(importanceLevel);
    const impactPoints = generateImpactPoints(impactOnResults);
    const reliabilityPoints = generateReliabilityPoints(reliabilityLevel);

    const finalScore =
      (importancePoints !== 0 ? importancePoints : 1) *
      (impactPoints !== 0 ? impactPoints : 1) *
      (reliabilityPoints !== 0 ? reliabilityPoints : 1);
    this.setState({
      totalScore: finalScore,
    });
    return finalScore;
  }

  doEvaluationsUpdate() {
    const {
      id,
      importanceLevel,
      impactOnResults,
      reliabilityLevel,
    } = this.state;

    const { insight } = this.props;

    const swotAnalysis = {
      id,
      impactOnResults: impactOnResults === "" ? null : impactOnResults,
      importanceLevel: importanceLevel === "" ? null : importanceLevel,
      reliabilityLevel: reliabilityLevel === "" ? null : reliabilityLevel,
      score: this.generateScore(),
    };

    this.props.addSwotEvaluation(insight.id, swotAnalysis);
  }

  handleChangeImpact = (e) => {
    const targetValue = e.target.value;
    const finalValue =
      targetValue && targetValue !== "empty" ? targetValue : undefined;
    this.setState({
      impactOnResults: finalValue,
      updateValues: true,
    });
  };

  handleChangeImportance = (e) => {
    const targetValue = e.target.value;
    const finalValue =
      targetValue && targetValue !== "empty" ? targetValue : undefined;
    this.setState({
      importanceLevel: finalValue,
      updateValues: true,
    });
  };
  handleChangeReliability = (e) => {
    const targetValue = e.target.value;
    const finalValue =
      targetValue && targetValue !== "empty" ? targetValue : undefined;
    this.setState({
      reliabilityLevel: finalValue,
      updateValues: true,
    });
  };
  render() {
    const { intl, insight, selectedTool = {} } = this.props;

    const { totalScore, scoreDate } = this.state;
    let impactOptions = this.buildOptions(getImpact(intl));
    let importanceOptions = this.buildOptions(getImportance(intl));
    let reliabilityOptions = this.buildOptions(getReliability(intl));

    return (
      <div className="row">
        <div className="box box-primary">
          <div
            id="header-insight-evaluation"
            className="box-header with-border"
          >
            <i className="fas fa-tasks" />
            &nbsp;&nbsp;
            <h3 className="box-title">
              {intl.formatMessage(globalMessages.swotEvaluation)}
            </h3>
            &nbsp;&nbsp;
            <div className="box-tools pull-right">
              <span className="badge bg-purple">
                {totalScore && totalScore.toString()}
              </span>
            </div>
            <br />
            {scoreDate && (
              <small>{formatDate(scoreDate, EXTENDED_DATE_TIME_FORMAT)}</small>
            )}
          </div>
          <div className="box-body">
            <div className="col-xs-12 col-sm-4">
              <MaterialSingleSelect
                id="selectImpact"
                label={intl.formatMessage(
                  { id: this.insightMessages.paramLevel.id },
                  { param: intl.formatMessage(globalMessages.impact) },
                )}
                value={this.state.impactOnResults}
                options={impactOptions}
                onChange={(e) =>
                  checkAccess([
                    "CREATOR",
                    "RESPONSIBLE",
                    "COLLABORATOR",
                    "MANAGER",
                    "LEADER",
                    "MODERATOR",
                    "ADMIN",
                    "OWNER",
                  ]) && this.handleChangeImpact(e)
                }
              />
            </div>
            <div className="col-xs-12 col-sm-4">
              <MaterialSingleSelect
                id="selectImportance"
                label={intl.formatMessage(
                  { id: this.insightMessages.paramLevel.id },
                  { param: intl.formatMessage(globalMessages.importance) },
                )}
                value={this.state.importanceLevel}
                options={importanceOptions}
                onChange={(e) =>
                  checkAccess([
                    "CREATOR",
                    "RESPONSIBLE",
                    "COLLABORATOR",
                    "MANAGER",
                    "LEADER",
                    "MODERATOR",
                    "ADMIN",
                    "OWNER",
                  ]) && this.handleChangeImportance(e)
                }
              />
            </div>
            <div className="col-xs-12 col-sm-4">
              <MaterialSingleSelect
                id="selectConfiability"
                label={intl.formatMessage(
                  { id: this.insightMessages.paramLevel.id },
                  { param: intl.formatMessage(globalMessages.reliability) },
                )}
                value={this.state.reliabilityLevel}
                options={reliabilityOptions}
                onChange={(e) =>
                  checkAccess([
                    "CREATOR",
                    "RESPONSIBLE",
                    "COLLABORATOR",
                    "MANAGER",
                    "LEADER",
                    "MODERATOR",
                    "ADMIN",
                    "OWNER",
                  ]) && this.handleChangeReliability(e)
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <InsightActionPlan
            insight={insight}
            selectedTool={selectedTool}
            dragabble
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { swotEvaluation } = state;
  const allSwotEvaluation =
    swotEvaluation && swotEvaluation.allSwotEvaluation
      ? swotEvaluation.allSwotEvaluation
      : [];
  const { selectedTool = {} } = getSelectedToolStates(state);

  return {
    allSwotEvaluation,
    selectedTool,
  };
};

const SwotEvaluation = injectIntl(
  withRouter(
    connect(mapStateToProps, {
      addSwotEvaluation,
    })(SW_Class),
  ),
);

export { SwotEvaluation };
