import React from "react";
import kpiUtils from "../../../utils/kpis";
import IndicatorsTable from "./includes/IndicatorsTable";
import ProjectsOptionsTable from "./includes/ProjectsOptionsTable";

const { getFilteredGoals } = kpiUtils;

const PerformanceEvaluations = (props) => {
  const {
    kpiAdministration,
    filteredGoals,
    isPeriodFilterActive,
    allAnswers,
    actionsPlan = [],
    goalTypeOptions,
    measurementUnityOptions,
    selectAndOpenInsight,
  } = props;

  const onlyIndicatorGoals = getFilteredGoals(
    "INDICATOR",
    filteredGoals,
    "type",
  );

  const onlyProjectGoals = getFilteredGoals("PROJECT", filteredGoals, "type");
  const onlyOptionGoals = getFilteredGoals("OPTION", filteredGoals, "type");

  const appendProps = {
    kpiAdministration,
    measurementUnityOptions,
    goalTypeOptions,
    indicators: onlyIndicatorGoals,
    optionsAndProjectGoals: [...onlyProjectGoals, ...onlyOptionGoals],
    actionsPlan,
    allAnswers,
    selectAndOpenInsight,
    isPeriodFilterActive,
  };

  return (
    <div className="row">
      <div className="row">
        <IndicatorsTable {...appendProps} />
      </div>
      <div className="row">
        <ProjectsOptionsTable {...appendProps} />
      </div>
    </div>
  );
};

export default PerformanceEvaluations;
