import React from "react";
import { Button } from "@material-ui/core";
import { defineMessages } from "react-intl";
import { Form, ErrorText } from "./styles";
import MaterialTextField from "../Common/MaterialTextField";
import SimpleLoader from "../SimpleLoader";
import { translatedText } from "../../utils/translationUtils";

const messages = defineMessages({
  otpCodeIncorrect: {
    id: "login.otpCodeIncorrect",
  },
});

const translation = (id) => translatedText(id, messages);

const LoginOTPConfirmation = ({
  otpCode,
  email,
  isLoading,
  onConfirm,
  error,
}) => {
  const handleKeyPress = (e) => {
    if (e.key === "Enter") onConfirm();
  };

  return (
    <div>
      <Form>
        <MaterialTextField
          id="email"
          label="Email"
          value={email}
          onChange={() => {}}
          type="email"
          autoComp="on"
          autoFocus
          onKeyDown={handleKeyPress}
        />
        {error && <ErrorText>{translation("otpCodeIncorrect")}</ErrorText>}
      </Form>
      <h5 align="center">Este login é valido apenas uma vez.</h5>
      <p align="center">
        Caso você precise de um novo link, entre em contato com algum gestor ou
        administrador da ferramenta.
      </p>
      <Button
        color="primary"
        variant="contained"
        onClick={onConfirm}
        style={{ width: "100%", fontSize: "13px" }}
        disabled={email.length === 0 || otpCode.length === 0 || isLoading}
      >
        {!isLoading ? "Confirmar" : <SimpleLoader color="#fff" />}
      </Button>
    </div>
  );
};

export default LoginOTPConfirmation;
