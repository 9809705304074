import {
  importDefaultKeySeatEvaluation,
  importDefaultPerformanceEvaluation,
  importDefaultSuccessionEvaluation,
  getComplementaryEvaluationsMigrationConfig,
} from "./import-defaults";
import {
  get_participants_new_workflows,
  get_participants_new_complementary_workflows,
  get_new_role_relations,
  get_new_roles_to_relation,
} from "./entityAutomationFilters";
import { getObjectChangedKeys } from "../ArrayUtils";

export const persistParticipantsWorkflow = (newAdministration) => {
  const { workflow } = newAdministration;

  const addNewWorkflows = get_participants_new_workflows(newAdministration);

  return {
    ...newAdministration,
    workflow: [...workflow, ...addNewWorkflows],
  };
};

export const persistParticipantsComplementaryWorkflow = (newAdministration) => {
  const { complementaryWorkflow } = newAdministration;

  const currentWorkflow = Array.isArray(complementaryWorkflow)
    ? complementaryWorkflow
    : [];

  const addNewWorkflows =
    get_participants_new_complementary_workflows(newAdministration);

  return {
    ...newAdministration,
    complementaryWorkflow: [...currentWorkflow, ...addNewWorkflows],
  };
};

export const persistParticipantsRoleSkillRelation = (newAdministration) => {
  const { roleSkillRelation } = newAdministration;

  const newAddRolesToRelation = get_new_role_relations(newAdministration);
  const newRelation = get_new_roles_to_relation(
    newAddRolesToRelation,
    roleSkillRelation,
  );

  return {
    ...newAdministration,
    roleSkillRelation: [...roleSkillRelation, ...newRelation],
  };
};

export const persistParticipantRelations = (updatedAdministration) => {
  let newAdministration = { ...updatedAdministration };

  newAdministration = persistParticipantsWorkflow(newAdministration);
  newAdministration = persistParticipantsRoleSkillRelation(newAdministration);
  newAdministration =
    persistParticipantsComplementaryWorkflow(newAdministration);

  return newAdministration;
};

export const deleteAttributesFromItems = (list, attributes) => {
  return list?.map((item) => {
    const updatedItem = { ...item };

    attributes.forEach((key) => {
      delete updatedItem[key];
    });

    return updatedItem;
  });
};

export const deleteAttributesFromParticipants = (participants) => {
  const updatedParticipants = [...participants];

  return deleteAttributesFromItems(updatedParticipants, [
    "memberParticipant",
    "companyArea",
    "companyPosition",
    "manager",
    "secondEvaluator",
    "thirdEvaluator",
  ]);
};

export const filterOutNestedEntities = (updatedAdministration) => {
  const newAdministration = { ...updatedAdministration };
  newAdministration.participants = deleteAttributesFromParticipants(
    updatedAdministration.participants,
  );

  newAdministration.roleSkillRelation = deleteAttributesFromItems(
    updatedAdministration.roleSkillRelation,
    ["companyRole", "skill"],
  );

  newAdministration.workflow = deleteAttributesFromItems(
    updatedAdministration.workflow,
    ["skill", "participant"],
  );

  return newAdministration;
};

export function getOrderedById(array) {
  // keep null ids for last
  return array.sort((a, b) => {
    if (a.id === null) return 1;
    if (b.id === null) return -1;
    return a.id - b.id;
  });
}

export const removedDuplicatedObjsFromArray = (array, attr1, attr2) => {
  if (!Array.isArray(array)) return array;
  const orderedArray = getOrderedById([...array]);
  const newArrayWithoutDuplicates = orderedArray.filter((obj, index, self) => {
    return (
      self.findIndex((t) => {
        return t[attr1] === obj[attr1] && t[attr2] === obj[attr2];
      }) === index
    );
  });
  return newArrayWithoutDuplicates;
};

export const persistOrderedListByIds = (updatedAdministration) => {
  const newAdministration = { ...updatedAdministration };
  newAdministration.roleSkillRelation = getOrderedById(
    newAdministration.roleSkillRelation,
  );
  newAdministration.workflow = getOrderedById(newAdministration.workflow);
  newAdministration.participants = getOrderedById(
    newAdministration.participants,
  );

  return newAdministration;
};

export const removeDuplicatedRelations = (updatedAdministration) => {
  const newAdministration = { ...updatedAdministration };
  newAdministration.roleSkillRelation = removedDuplicatedObjsFromArray(
    newAdministration.roleSkillRelation,
    "companyRoleId",
    "skillId",
  );

  newAdministration.workflow = removedDuplicatedObjsFromArray(
    newAdministration.workflow,
    "participantId",
    "skillId",
  );

  return newAdministration;
};

export const persistToolAdministration = (updatedAdministration) => {
  let newAdministration = persistParticipantRelations(updatedAdministration);
  newAdministration = persistOrderedListByIds(newAdministration);
  newAdministration = removeDuplicatedRelations(newAdministration);
  return filterOutNestedEntities(newAdministration);
};

export const preparePersistedAdministration = (updatedAdministration) => {
  let newAdministration = persistOrderedListByIds(updatedAdministration);
  newAdministration = removeDuplicatedRelations(newAdministration);
  return filterOutNestedEntities(newAdministration);
};

export const persistMigrateComplementaryEvaluation = (toolAdministration) => {
  let updatedAdministration = { ...toolAdministration };

  const {
    enableImportKeySeat,
    enableImportPerformance,
    enableImportSuccession,
  } = getComplementaryEvaluationsMigrationConfig(toolAdministration);

  if (enableImportKeySeat)
    updatedAdministration = importDefaultKeySeatEvaluation(
      updatedAdministration,
    );
  if (enableImportPerformance)
    updatedAdministration = importDefaultPerformanceEvaluation(
      updatedAdministration,
    );
  if (enableImportSuccession)
    updatedAdministration = importDefaultSuccessionEvaluation(
      updatedAdministration,
    );

  return updatedAdministration;
};

export const persistMigrateV2ConfigEntity = (toolAdministration) => {
  const withComplementaryEvaluations =
    persistMigrateComplementaryEvaluation(toolAdministration);
  return withComplementaryEvaluations;
};

export function addRemovedIdsToChanges(changedKeys, saveRestAdministration) {
  const updatedChangedKeys = { ...changedKeys };

  if (updatedChangedKeys.competenceAreas) {
    const { competenceAreas } = saveRestAdministration;
    const { base, changes } = updatedChangedKeys.competenceAreas;

    base.forEach(({ id: competenceAreaId, profiles }) => {
      if (changes.find((item) => item?.base?.id === competenceAreaId)) {
        const savingItem = competenceAreas.find(
          ({ id }) => id === competenceAreaId,
        );
        const removedProfiles = profiles.filter(
          ({ id }) =>
            savingItem.profiles.findIndex((saving) => saving.id === id) === -1,
        );
        const currentRemovedIds =
          updatedChangedKeys.competenceAreas.removedIds || {};
        const currentRemovedIdsForCompetence =
          currentRemovedIds[competenceAreaId] || {};

        updatedChangedKeys.competenceAreas.removedIds = {
          ...currentRemovedIds,
          [competenceAreaId]: {
            ...currentRemovedIdsForCompetence,
            profiles: removedProfiles.map(({ id }) => id),
          },
        };
      }
    });
  }

  return updatedChangedKeys;
}

export function getAdministrationChangedKeys(
  saveRestAdministration,
  toolHistory,
) {
  const orderedCompare = persistOrderedListByIds(saveRestAdministration);
  const orderedHistory = persistOrderedListByIds(toolHistory);
  const changedKeys = getObjectChangedKeys(orderedCompare, orderedHistory);

  return addRemovedIdsToChanges(changedKeys, saveRestAdministration);
}
