import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Tooltip } from "@material-ui/core";
import utils from "../../../../../../utils/toolUtils";
import { TdRegister } from "../css/OkrCss";

const defaultFilter = {
  attr: "title",
  order: "ASC",
};

const { getMaxStringLength } = utils;

const ObjectiveRegister = (props) => {
  const [orderedObjectives, setOrderedObjectives] = useState([]);
  const [filter, setFilter] = useState(defaultFilter);

  const {
    getDefaultAddTableLine,
    getDefaultRemoveColumn,
    handleOpenModal,
    openFormDelete,
    objectives,
    getDialogDeleteObjective,
    intl,
  } = props;

  useEffect(() => {
    const ord1 = filter.order === "ASC" ? 1 : -1;
    const ord2 = filter.order === "ASC" ? -1 : 1;

    let newOrdered = objectives;

    if (filter.attr === "title") {
      // FILTER BY TITLE
      newOrdered = [...objectives].sort((a, b) =>
        a.title > b.title ? ord1 : ord2,
      );
    }

    setOrderedObjectives(newOrdered);
  }, [objectives, filter]);

  const getNewOrder = (currentOrder) => {
    if (currentOrder === "ASC") return "DESC";
    return "ASC";
  };

  const handleFilter = (attr) => {
    let updatedFilter = defaultFilter;

    if (attr === filter.attr) {
      updatedFilter = {
        attr,
        order: getNewOrder(filter.order),
      };
    } else {
      updatedFilter = { ...defaultFilter, attr };
    }

    setFilter(updatedFilter);
  };

  const orderButton = ({ attr }) => {
    return (
      <button
        className="btn btn-data-sort grow"
        onClick={() => handleFilter(attr)}
        style={{ padding: "0px 5px", margin: "0px 0px 0px 10px" }}
      >
        <i className="fas fa-caret-up" />
        <i className="fas fa-caret-down" />
      </button>
    );
  };

  return (
    <div>
      <h4>{intl.formatMessage({ id: "objectives_achieved" })}</h4>
      <table className="simple-table" style={{ margin: "15px 0 0 0" }}>
        <thead>
          <tr>
            <td colSpan={2}>
              {intl.formatMessage({ id: "objective_title" })}{" "}
              {orderButton({ attr: "title" })}
            </td>
          </tr>
        </thead>
      </table>
      <div style={{ overflow: "auto", maxHeight: "441px" }}>
        <table className="simple-table" style={{ margin: "0" }}>
          <tbody>
            {orderedObjectives && orderedObjectives.length ? (
              orderedObjectives.map((objective) => {
                const paramsFormatText = {
                  string: objective.title,
                  beginIndex: 0,
                  endIndex: 95,
                  additional: "...",
                };

                const keyObjective = getMaxStringLength(paramsFormatText);

                return (
                  <tr key={objective.id} style={{ cursor: "pointer" }}>
                    <TdRegister
                      onClick={() =>
                        handleOpenModal("objectModal", true, {
                          type: "objectModal",
                          params: objective,
                        })
                      }
                    >
                      <Tooltip title={objective.title} placement="bottom-start">
                        <span>{keyObjective}</span>
                      </Tooltip>
                    </TdRegister>
                    {getDefaultRemoveColumn(() =>
                      openFormDelete(
                        objective.id,
                        getDialogDeleteObjective(objective.id),
                      ),
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <TdRegister style={{ textAlign: "center" }}>
                  {intl.formatMessage({ id: "text_results_found" })}
                </TdRegister>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <table className="simple-table" style={{ marginTop: "0" }}>
        <tbody>
          {getDefaultAddTableLine(() => handleOpenModal("objectModal", true))}
        </tbody>
      </table>
    </div>
  );
};

export default injectIntl(ObjectiveRegister);

ObjectiveRegister.propTypes = {
  getDefaultAddTableLine: PropTypes.func.isRequired,
  getDefaultRemoveColumn: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  openFormDelete: PropTypes.func.isRequired,
  getDialogDeleteObjective: PropTypes.func.isRequired,
  objectives: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
};
