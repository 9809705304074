import axios from "axios";

import {
  FETCH_USER_TEAMS,
  CREATE_TEAM,
  UPDATE_TEAM,
  FETCH_TEAM_MEMBERS,
  UPDATE_COMPANY_TEAMS,
} from "./types";

import { addMembersToTeam, addParticipantsToTeam } from "./memberActions";

export const fetchUserTeams = (userId, companyId) => {
  return (dispatch) => {
    return axios
      .get("/api/teams/member/" + userId + "/company/" + companyId)
      .then((res) => {
        const teams = res.data;
        dispatch({ type: FETCH_USER_TEAMS, payload: teams });
      });
  };
};

export const fetchTeamMembers = (teamId) => {
  return (dispatch) => {
    return axios.get(`/api/teams/${teamId}/members`).then((res) => {
      dispatch({ type: FETCH_TEAM_MEMBERS, payload: res.data });
    });
  };
};

export const totalTeamMembers = (teamId) => {
  return (dispatch) => {
    return axios.get(`/api/teams/${teamId}/members`).then((res) => {
      const teamMembers = res.data;
      return teamMembers;
    });
  };
};

//fetchTeamParticipants
export const fetchTeamParticipants = (teamId) => {
  return (dispatch) => {
    return axios.get(`/api/teams/${teamId}/participants`).then((res) => {
      dispatch({ type: "FETCH_TEAM_PARTICIPANTS", payload: res.data });
    });
  };
};

export const fetchTeamWorkspaces = (teamId) => {
  return (dispatch) => {
    return axios.get(`/api/workspaces/team/${teamId}/count`).then((res) => {
      const teamsWorkspaces = res.data;
      return teamsWorkspaces;
    });
  };
};

export const handleSaveNewTeam = (
  newTeam,
  teamMembers = [],
  teamParticipants = [],
) => {
  return async (dispatch) => {
    const newTeamData = await postTeam(newTeam);

    dispatch({ type: CREATE_TEAM, payload: newTeamData.data });
    dispatch({ type: UPDATE_COMPANY_TEAMS, payload: newTeamData.data });

    const teamID =
      newTeamData.data && newTeamData.data.id ? newTeamData.data.id : null;

    if (teamMembers.length > 0) dispatch(addMembersToTeam(teamMembers, teamID));
    if (teamParticipants.length > 0)
      dispatch(addParticipantsToTeam(teamParticipants, teamID));
  };
};

export const postTeam = (newTeam) => {
  return axios.post("/api/teams", newTeam).then((res) => res);
};

export const updateTeam = (updatedTeam) => {
  return (dispatch) => {
    return axios.put("/api/teams", updatedTeam).then((res) => {
      dispatch({ type: UPDATE_TEAM, payload: res.data });
      dispatch({ type: UPDATE_COMPANY_TEAMS, payload: res.data });
    });
  };
};

export const deleteTeam = async (teamToDelete) => {
  await axios.delete(`/api/teams/${teamToDelete}`);
};
