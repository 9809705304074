import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";

import FormDialog from "../../../../../../Common/FormDialog";
import MaterialTextField from "../../../../../../Common/MaterialTextField";
import CicleRegister from "../CicleRegister";
import CicleForm from "./CicleForm";
import { AlertStyle } from "../../css/OkrCss";
import utils from "../../../../../../../utils/toolUtils";

import { configPlansBody } from "../../../../../../../utils/okr/entity";

const { iterationSearch } = utils;

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "10px",
    "& > button": {
      fontSize: "12px",
    },
  },

  buttonMargin: {
    marginLeft: "10px",
  },

  buttonCustom: {
    background: "#1976d2",
    color: "#fff",
    "&:hover": {
      background: "rgb(17, 82, 147)",
    },
  },

  containerEdit: {
    margin: "15px 0px 0px 0px",
  },

  alertCicles: {
    textAlign: "center",
    background: "#f1f1f1",
    marginTop: "15px",
    padding: "10px",
    borderRadius: "3px",
  },
}));

const intialPlanBody = configPlansBody();

const TeamForm = ({
  open,
  onCancel,
  openCicleForm,
  openFormDelete,
  essencialProps,
  createOrUpdatePlan,
  isEditing,
  plans,
  intl,
  afterConfirm,
}) => {
  const [plan, setPlan] = useState(intialPlanBody);
  const [cycleGroups, setCycleGroups] = useState([]);

  const { modalSlug } = essencialProps;

  const classes = useStyles();

  useEffect(() => {
    if (isEditing && isEditing.params && isEditing.params.plan) {
      setPlan(isEditing.params.plan);
      setCycleGroups(isEditing.params.plan.cycleGroups);
    }
  }, [isEditing]);

  function closeReset() {
    onCancel("planModal", false);

    setPlan(intialPlanBody);
    setCycleGroups([]);
  }

  function validForm() {
    let titleError = "";

    function validPlanTitle(planTitle) {
      return planTitle || "";
    }

    const organizationTitle = validPlanTitle(plan.organization.title);

    if (organizationTitle === "") {
      titleError = intl.formatMessage({ id: "title_error" });
    }

    if (organizationTitle.length < 3) {
      titleError = intl.formatMessage({ id: "min_caractere" });
    }

    const planTitles =
      plans &&
      plans.length &&
      plans.map((plan) =>
        validPlanTitle(plan.organization.title)
          .toString()
          .toUpperCase()
          .replace(/\s/g, "")
          .trim(),
      );

    const isEqualPlanTitle = iterationSearch(organizationTitle, planTitles);

    const filterPlanById =
      plans &&
      plans.length &&
      plans.filter((planInfo) => {
        return planInfo.id !== plan.id;
      });

    const planTitleEquals =
      plans &&
      plans.length &&
      filterPlanById.filter((planInfo) => {
        return (
          organizationTitle
            .toString()
            .toUpperCase()
            .replace(/\s/g, "")
            .trim() ===
          validPlanTitle(planInfo.organization.title)
            .toString()
            .toUpperCase()
            .replace(/\s/g, "")
            .trim()
        );
      });

    if (isEqualPlanTitle && !plan.id) {
      titleError = intl.formatMessage({ id: "equal_scenario_name_alert" });
    }

    if (plan.id && planTitleEquals.length >= 1) {
      titleError = intl.formatMessage({ id: "equal_scenario_name_alert" });
    }

    if (titleError) {
      setPlan({ ...plan, titleError });

      return false;
    }

    return true;
  }

  function createPlans() {
    if (validForm()) {
      createOrUpdatePlan(plan, cycleGroups);
      closeReset();

      if (afterConfirm) {
        afterConfirm();
      }
    }
  }

  function updateGroups(newCycleGroup) {
    const newCycleGroups = cycleGroups.map((cycleGroup) =>
      cycleGroup.id === newCycleGroup.id
        ? { ...cycleGroup, ...newCycleGroup }
        : cycleGroup,
    );

    setCycleGroups(newCycleGroups);
  }

  function createGroups(newCycleGroup) {
    if (newCycleGroup.id !== null || newCycleGroup.index) {
      updateGroups(newCycleGroup);
    } else {
      const newCycleGroups = [...cycleGroups];
      newCycleGroups.push({ index: cycleGroups.length + 1, ...newCycleGroup });

      setCycleGroups(newCycleGroups);
    }
  }

  const nextProps = {
    ...essencialProps,
    openFormDelete,
    createGroups,
  };

  const organizationTitle =
    plan && plan.organization && plan.organization.title
      ? plan.organization.title
      : "";

  return (
    <>
      <FormDialog
        title={intl.formatMessage({ id: "add_new_scenario" })}
        open={open}
        onCancel={closeReset}
        onConfirm={createPlans}
      >
        <div>
          {plan.titleError && (
            <AlertStyle severity="error">
              <span>{plan.titleError}</span>
            </AlertStyle>
          )}
          <MaterialTextField
            id="program"
            name="organization.title"
            label={intl.formatMessage({ id: "scenerie_name" })}
            value={organizationTitle}
            onChange={(e) =>
              setPlan({
                ...plan,
                organization: {
                  ...plan.organization,
                  title: e.target.value,
                },
              })
            }
          />

          <MaterialTextField
            id="proposal"
            label={intl.formatMessage({ id: "scenerie_description" })}
            value={plan.proposal || ""}
            multiline
            onChange={(e) => setPlan({ ...plan, proposal: e.target.value })}
          />
          <Grid
            container
            className={classes.root}
            style={{ paddingTop: "15px" }}
          >
            <h4>{intl.formatMessage({ id: "cycle_group" })}</h4>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              style={{ fontSize: "12px", marginLeft: "15px" }}
              onClick={() => openCicleForm("cicleModal", true)}
            >
              {intl.formatMessage({ id: "register_group" })}
            </Button>
          </Grid>
        </div>
        <div style={{ width: "100%" }}>
          {cycleGroups && cycleGroups.length ? (
            cycleGroups.map((cycleGroup, index) => {
              const groupCycles = cycleGroup.cycles;
              const reorderedCycles = [...groupCycles].sort((a, b) =>
                a.title > b.title ? 1 : -1,
              );

              const updatedCycleGroup = {
                ...cycleGroup,
                cycles: reorderedCycles,
              };

              const openEditCycleGroup = () =>
                openCicleForm("cicleModal", true, {
                  type: "cicleModal",
                  params: {
                    index,
                    cycleGroup: updatedCycleGroup,
                  },
                });

              return (
                <div key={uuidv4()}>
                  <Grid container className={classes.containerEdit}>
                    <Grid container item>
                      <h5 style={{ marginBottom: "0px" }}>
                        {intl.formatMessage({ id: "group_year" })}{" "}
                        {updatedCycleGroup.year}
                        <span
                          style={{
                            fontWeight: "normal",
                            cursor: "pointer",
                            paddingLeft: "8px",
                          }}
                        >
                          <a onClick={openEditCycleGroup}>Editar</a>
                        </span>
                      </h5>
                    </Grid>
                  </Grid>
                  <CicleRegister
                    onClick={openEditCycleGroup}
                    editCicle={false}
                    cycles={updatedCycleGroup.cycles}
                    openFormDelete={openFormDelete}
                  />
                </div>
              );
            })
          ) : (
            <h5 className={classes.alertCicles}>
              {intl.formatMessage({ id: "no_cycle_group" })}
            </h5>
          )}
        </div>
      </FormDialog>
      <CicleForm
        open={modalSlug.indexOf("cicleModal") > -1}
        onCancel={() => openCicleForm("cicleModal", false)}
        isEditing={isEditing}
        currentCycleGroups={cycleGroups}
        {...nextProps}
      />
    </>
  );
};

export default injectIntl(TeamForm);
