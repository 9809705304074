const pt = {
  text_update_team: "Editar time",
};

const en = {
  text_update_team: "Edit team",
};

const es = {
  text_update_team: "Editar equipo",
};

const translations = { pt, en, es };

export default translations;
