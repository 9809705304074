import React from "react";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import { getUpdatedObjectInArray } from "../../../../../../../utils/ArrayUtils";
import { getConcurrenceScaleTranslation } from "../../../../../../../utils/cpAnalysis/display";

const Scales = (props) => {
  const { cpAnalysisAdministration = {}, setCpAnalysisAdministration } = props;

  const { concurrence = [] } = cpAnalysisAdministration || {};
  const { scales = [] } = concurrence || {};

  const updateConcurrenceField = (field = false, value = "") => {
    if (!field) return;

    const concurrenceConfig = cpAnalysisAdministration.concurrence || {};

    setCpAnalysisAdministration({
      ...cpAnalysisAdministration,
      concurrence: {
        ...concurrenceConfig,
        [field]: [...value],
      },
    });
  };

  const handleUpdateScale = (value = "", field = "", index = null) => {
    const updated = getUpdatedObjectInArray(value, scales, field, index);

    updateConcurrenceField("scales", updated);
  };

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="3">Título</td>
        </tr>
      </thead>
      <tbody>
        {scales.map((scaleInfo, index) => {
          const title = getConcurrenceScaleTranslation(scaleInfo.title || "");

          return (
            <tr className="block-hover" key={index}>
              <td align="center" style={{ width: "40px" }}>
                {index + 1}
              </td>
              <SimpleExcelCollumn
                id="scaleTitle"
                label="Titulo"
                type="text"
                value={title}
                onChange={(e) =>
                  handleUpdateScale(e.target.value, "title", index)
                }
              />
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Scales;
