import {
  checkPostAdministrationSelectedTool,
  toolUpdateNotifier,
} from "./_validations";

import {
  checkScenerySelectedTool,
  checkThemeSelectedTool,
  checkQuestionSelectedTool,
  checkInsightSelectedTool,
} from "../board/_validations";

import {
  postScenery,
  postTheme,
  postQuestion,
  postInsight,
} from "../board/_actions";

import {
  getFromDetails,
  checkToolIdInState,
} from "../../../utils/entity_validations";

import { UPDATE_KPI_CONFIGURATION } from "../../../../types";

import { buildKpiAdministrationFinalBody } from "../../../../../utils/kpis/entity";

const DEBUG = false;
function debug(arg1, ...optional) {
  if (DEBUG) console.log("WebSockets: ", arg1, ...optional);
}

export const putKpiConfiguration = (newAdministrationConfig = {}) => {
  const { content: administrationData, details = {} } = newAdministrationConfig;
  const selectedToolId = getFromDetails(details, "selectedToolId", true);

  debug({ administrationData, details, selectedToolId });

  if (
    administrationData &&
    selectedToolId &&
    checkPostAdministrationSelectedTool(selectedToolId)
  ) {
    return async (dispatch) => {
      const payload = { ...administrationData, selectedToolId };
      const updatedConfiguration = await buildKpiAdministrationFinalBody(
        payload,
      );
      debug("UPDATE_KPI_CONFIGURATION", updatedConfiguration);

      if (checkToolIdInState({ id: selectedToolId }))
        dispatch(toolUpdateNotifier());

      dispatch({
        type: UPDATE_KPI_CONFIGURATION,
        payload: updatedConfiguration,
        loading: false,
      });
    };
  }

  return null;
};

export const postBoardCenaryLink = (cenaryLinkData) => {
  const { content: updatedCenary = {}, details = {} } = cenaryLinkData;
  debug({ updatedCenary });

  if (updatedCenary && updatedCenary.id) {
    return (dispatch) => {
      if (checkScenerySelectedTool(updatedCenary))
        dispatch(toolUpdateNotifier());

      dispatch(postScenery(updatedCenary, details.userId));
    };
  }

  return null;
};

export const postBoardThemeLink = (themeLinkData) => {
  const { content: updatedTheme = {}, details = {} } = themeLinkData;

  if (updatedTheme && updatedTheme.id) {
    return (dispatch) => {
      if (checkThemeSelectedTool(updatedTheme)) dispatch(toolUpdateNotifier());

      return dispatch(postTheme(updatedTheme, details.userId));
    };
  }

  return null;
};

export const postBoardQuestionLink = (questionLinkData) => {
  const { content: updatedQuestion = {}, details = {} } = questionLinkData;

  if (updatedQuestion && updatedQuestion.id) {
    return (dispatch) => {
      if (checkQuestionSelectedTool(updatedQuestion))
        dispatch(toolUpdateNotifier());

      return dispatch(postQuestion(updatedQuestion, details.userId));
    };
  }

  return null;
};

export const postBoardInsightLink = (insightLinkData) => {
  const { content: updatedInsight = {}, details = {} } = insightLinkData;
  const kpiConfigurationGoalId = getFromDetails(
    details,
    "kpiConfigurationGoalId",
    true,
  );

  if (updatedInsight && updatedInsight.id && kpiConfigurationGoalId) {
    return (dispatch) => {
      if (checkInsightSelectedTool(updatedInsight))
        dispatch(toolUpdateNotifier());

      return dispatch(
        postInsight(
          { ...updatedInsight, kpiConfigurationGoalId },
          details.userId,
        ),
      );
    };
  }

  return null;
};
