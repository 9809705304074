import axios from "axios";
import _ from "lodash";
import {
  FETCH_ALL_PEOPLE_EVALUATION,
  UPDATE_PEOPLE_EVALUATION,
  UPDATE_PROFESSIONAL_PROFILE,
  PEOPLE_MASS_UPDATE,
  UPDATE_QUESTION_ANSWER,
} from "./types";

import { postInsight, updateInsightResponsible } from "./insightActions";

import { buildRestEvaluationBody } from "../utils/peopleEvaluationUtils";
import { checkToolSwitchedAccesses } from "./accessControllerActions";
import { makeObjLevelAnArray } from "../utils/reducerUtils";

export const getPeopleEvaluation = (insightID) => {
  return axios
    .get(`/api/insight/${insightID}/peopleEvaluation`)
    .then((res) => res);
};

const postEvaluation = (insightID, body) => {
  return axios
    .post(`/api/insights/${insightID}/peopleEvaluation`, body)
    .then((res) => res);
};

const postDefineProfessinalLeader = async (
  professionalAscentionID,
  professionalMemberInfo,
) => {
  return await axios
    .post(
      `/api/professional-ascentions/${professionalAscentionID}/professionalLeader/`,
      { ...professionalMemberInfo },
    )
    .then((res) => res);
};

const updateEvaluation = async (body) => {
  return await axios.put(`/api/people-evaluations`, body).then((res) => res);
};

const handlePostEvaluation = async (insightID, newEvaluation) => {
  const savedEvaluation = await postEvaluation(insightID, newEvaluation);
  let newEvaluationInfo = {};

  if (savedEvaluation.data) {
    newEvaluationInfo = savedEvaluation.data;

    const bodySalaryTrajectory = newEvaluation.salaryTrajectory || {};
    const bodyAscentions = bodySalaryTrajectory.professionalAscentions || [];

    const updatedPeopleEvaluation =
      savedEvaluation.data && savedEvaluation.data.peopleEvaluation
        ? savedEvaluation.data.peopleEvaluation
        : {};

    const { salaryTrajectory = {} } = updatedPeopleEvaluation;
    const { professionalAscentions = [] } = salaryTrajectory;

    if (
      updatedPeopleEvaluation &&
      professionalAscentions &&
      bodyAscentions &&
      (professionalAscentions.length > 0 || bodyAscentions.length > 0)
    ) {
      const updatedAscentions = await updateAscentionsByLeaders(
        bodyAscentions,
        professionalAscentions,
      );

      newEvaluationInfo = await replaceProfessionalAscentions(
        savedEvaluation.data,
        updatedAscentions,
      );
    }
  }

  return newEvaluationInfo;
};

const updateAscentionsByLeaders = async (newAscentions, currentAscentions) => {
  const sortHistoryByDate = (history, objSlug) => {
    history.sort((a, b) => {
      const dA = new Date(a[objSlug]);
      const dB = new Date(b[objSlug]);

      return dA > dB ? -1 : 1;
    });
    return history;
  };

  let finalAscentions = [];
  newAscentions = sortHistoryByDate(newAscentions, "fromDate");
  currentAscentions = sortHistoryByDate(currentAscentions, "fromDate");

  if (newAscentions.length > 0) {
    await Promise.all(
      newAscentions.map(async (newAscentionInfo, controlIndex) => {
        const currentAscentionInfo = currentAscentions[controlIndex];

        const newProfessionalLeaderID = newAscentionInfo.professionalLeader
          ? newAscentionInfo.professionalLeader.id
          : 0;

        if (
          newProfessionalLeaderID &&
          newProfessionalLeaderID !== currentAscentionInfo.professionalLeader.id
        ) {
          await postDefineProfessinalLeader(
            currentAscentionInfo.id,
            newAscentionInfo.professionalLeader,
          ).then((res) => {
            finalAscentions = [...finalAscentions, newAscentionInfo];
          });
        } else {
          finalAscentions = [...finalAscentions, newAscentionInfo];
        }
      }),
    );
  }

  return finalAscentions;
};

const replaceProfessionalAscentions = (
  currentEvaluation,
  replaceAscentions,
) => {
  return {
    ...currentEvaluation,
    peopleEvaluation: {
      ...currentEvaluation.peopleEvaluation,
      salaryTrajectory: {
        ...currentEvaluation.peopleEvaluation.salaryTrajectory,
        professionalAscentions: replaceAscentions,
      },
    },
  };
};

export const addPeopleEvaluation = (insightID, body, setToDefault = false) => {
  return async (dispatch) => {
    const sendUpdateDispatch = async (evaluationBody) => {
      await dispatch({
        type: UPDATE_PEOPLE_EVALUATION,
        payload: evaluationBody,
      });

      await dispatch({ type: "ONE_PAGE_LOADING", payload: { loading: false } });
    };

    await dispatch({ type: "ONE_PAGE_LOADING", payload: { loading: true } });

    const checkEvaluation = await getPeopleEvaluation(insightID);

    if (!checkEvaluation.data) {
      const newEvaluationInfo = await handlePostEvaluation(insightID, body);

      await sendUpdateDispatch(newEvaluationInfo);
    } else if (!setToDefault) {
      const peopleEvaluationID = checkEvaluation.data.id;
      const professionalProfileId = checkEvaluation.data.professionalProfile.id;
      const salaryTrajectoryID = checkEvaluation.data.salaryTrajectory.id;
      const evaluationTargetID = checkEvaluation.data.evaluationTarget.id;

      body = {
        ...body,
        id: peopleEvaluationID,
        professionalProfile: {
          ...body.professionalProfile,
          id: professionalProfileId,
        },
        salaryTrajectory: {
          ...body.salaryTrajectory,
          id: salaryTrajectoryID,
        },
        evaluationTarget: {
          ...body.evaluationTarget,
          id: evaluationTargetID,
        },
      };

      await updateEvaluation(body).then(async (updateRes) => {
        const ascentions = updateRes.data.peopleEvaluation.salaryTrajectory
          .professionalAscentions
          ? updateRes.data.peopleEvaluation.salaryTrajectory
              .professionalAscentions
          : [];

        const currentProfessionalAscentions = _.map(ascentions, _.clone);

        if (ascentions.length > 0) {
          const updatedAscentions = await updateAscentionsByLeaders(
            body.salaryTrajectory.professionalAscentions,
            currentProfessionalAscentions,
          ).then((res) => res);

          const newEvaluationInfo = await replaceProfessionalAscentions(
            updateRes.data,
            updatedAscentions,
          );

          await sendUpdateDispatch(newEvaluationInfo);
        } else if (updateRes.data) {
          await sendUpdateDispatch(updateRes.data);
        }
      });
    }

    await dispatch(checkToolSwitchedAccesses());
  };
};

export const fetchAllPeopleEvaluations = (selectedToolIds = []) => {
  return async (dispatch) => {
    const peopleEvaluations = await axios
      .get(`/api/selected-tools/allPeopleEvaluations/${selectedToolIds}`)
      .then((res) => res.data);

    const payload = makeObjLevelAnArray(peopleEvaluations);

    dispatch({
      type: FETCH_ALL_PEOPLE_EVALUATION,
      payload,
    });

    return payload;
  };
};

export const fetchAllPeopleEvaluation = (selectedToolID) => {
  return async (dispatch) => {
    const { data } = await axios.get(
      `/api/selected-tools/${selectedToolID}/peopleEvaluations`,
    );

    dispatch({
      type: FETCH_ALL_PEOPLE_EVALUATION,
      payload: data,
    });

    return data;
  };
};

export const updateMemberId = (
  insightId,
  professionalProfileId,
  memberInfo,
) => {
  return async (dispatch) => {
    dispatch({ type: "ONE_PAGE_LOADING", payload: { loading: true } });

    const trustyBody = await axios.post(
      `/api/professional-profiles/${professionalProfileId}/defineMember/`,
      memberInfo,
    );

    if (trustyBody.data) {
      await dispatch({
        type: UPDATE_PROFESSIONAL_PROFILE,
        payload: {
          insightId,
          professionalProfile: { ...trustyBody.data },
        },
      });
    }

    await dispatch({ type: "ONE_PAGE_LOADING", payload: { loading: false } });

    await dispatch(checkToolSwitchedAccesses());
  };
};

export const createPeopleInsight = async (userName, questionId, order) => {
  return await postInsight(
    {
      id: null,
      text: userName,
      name: userName,
      type: "insight",
      order,
    },
    questionId,
  );
};

export const dispatchInsight = (questionId, newInsight) => {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_QUESTION_ANSWER,
      payload: {
        questionId,
        answer: { ...newInsight, isNew: true },
      },
    });
  };
};

export const addNewPeopleInsights = (memberInfo, questionId, order) => {
  return async (dispatch) => {
    const saveRestEvaluation = buildRestEvaluationBody({});

    const sendUpdateDispatch = async (evaluationBody) => {
      await dispatch({
        type: UPDATE_PEOPLE_EVALUATION,
        payload: evaluationBody,
      });
      await dispatch({ type: "ONE_PAGE_LOADING", payload: { loading: false } });
    };

    if (memberInfo.name) {
      const createdInsight = await createPeopleInsight(
        memberInfo.name,
        questionId,
        order,
      );

      if (createdInsight.data && createdInsight.data.id) {
        await dispatch(dispatchInsight(questionId, createdInsight.data));

        if (memberInfo && memberInfo.id)
          dispatch(
            updateInsightResponsible(createdInsight.data, memberInfo.id),
          );

        const createdEvaluation = await handlePostEvaluation(
          createdInsight.data.id,
          saveRestEvaluation,
        );

        await sendUpdateDispatch(createdEvaluation);

        if (createdEvaluation && createdEvaluation.peopleEvaluation) {
          const { peopleEvaluation = {} } = createdEvaluation;
          const { professionalProfile = {} } = peopleEvaluation;

          if (professionalProfile && professionalProfile.id) {
            await dispatch(
              updateMemberId(
                createdInsight.data.id,
                professionalProfile.id,
                memberInfo,
              ),
            );
          }
        }
      }
    }
  };
};

export const peopleSheetsMassUpdate = (updateObject = {}) => {
  const { evaluationInfo: sheet0 = [] } = updateObject;

  return async (dispatch) => {
    sheet0.length > 0 && (await dispatch(peopleMassUpdate(sheet0)));
  };
};

export const peopleMassUpdate = (updatePayloadInfo) => {
  return async (dispatch) => {
    const updatedAllPeopleEvaluation = await axios
      .post("/api/insights/addPeopleEvaluations", updatePayloadInfo)
      .then((res) => {
        return res.data;
      });

    dispatch({ type: PEOPLE_MASS_UPDATE, payload: updatedAllPeopleEvaluation });
  };
};
