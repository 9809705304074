import React from "react";
import Select from "react-select";

const SimplePagination = ({
  page,
  setPage,
  totalPages,
  menuPlacement = "auto",
}) => {
  if (totalPages <= 1) return null;

  const options = [...Array(totalPages).keys()].map((index) => {
    const value = index + 1;
    return { value, label: value };
  });
  const currentOption = options.find((option) => {
    return option.value === page;
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        gap: 5,
        position: "relative",
        height: "40px",
      }}
    >
      <button
        className="btn btn-gray"
        onClick={() => setPage(page - 1)}
        disabled={page <= 1}
      >
        {"<"}
      </button>
      <Select
        defaultValue={currentOption}
        value={currentOption}
        onChange={(option) => setPage(option.value)}
        isDisabled={totalPages.length <= 1}
        options={options}
        menuPlacement={menuPlacement}
        styles={{
          control: (styles) => ({
            ...styles,
            height: "40px",
          }),
        }}
      />
      <button
        className="btn btn-gray"
        onClick={() => setPage(page + 1)}
        disabled={page + 1 > totalPages}
      >
        {">"}
      </button>
    </div>
  );
};

export default SimplePagination;
