import $ from "jquery";

export default function fixLayout() {
  const Selector = {
    wrapper: ".wrapper",
    contentWrapper: ".content-wrapper",
    layoutBoxed: ".layout-boxed",
    mainFooter: ".main-footer",
    mainHeader: ".main-header",
    sidebar: ".sidebar",
    controlSidebar: ".control-sidebar",
    fixed: ".fixed",
    sidebarMenu: ".sidebar-menu",
    logo: ".main-header .logo",
    content: ".content",
    contentHeader: ".content-header",
  };

  const ClassName = {
    fixed: "fixed",
    holdTransition: "hold-transition",
  };

  // Remove overflow from .wrapper if layout-boxed exists
  $(`${Selector.layoutBoxed} > ${Selector.wrapper}`).css("overflow", "hidden");

  // Get window height and the wrapper height
  const footerHeight = $(Selector.mainFooter).outerHeight() || 0;
  const neg = $(Selector.mainHeader).outerHeight() + footerHeight;
  const windowHeight = $(window).height();
  const sidebarHeight = $(Selector.sidebar).height() || 0;
  const contentHeaderHeight = $(Selector.contentHeader).outerHeight() || 0;
  const mainHeaderHeight = $(Selector.mainHeader).outerHeight() || 0;

  // Set the min-height of the content and sidebar based on the height of the
  // document.
  if ($("body").hasClass(ClassName.fixed)) {
    const contentWrapperHeight = windowHeight - footerHeight;
    const contentHeight =
      contentWrapperHeight - mainHeaderHeight - contentHeaderHeight;
    $(Selector.contentWrapper).css("min-height", contentWrapperHeight);
    $(Selector.content).css("min-height", contentHeight);
  } else {
    let postSetHeight;

    if (windowHeight >= sidebarHeight) {
      $(Selector.contentWrapper).css("min-height", windowHeight - neg);
      postSetHeight = windowHeight - neg;
    } else {
      $(Selector.contentWrapper).css("min-height", sidebarHeight);
      postSetHeight = sidebarHeight;
    }

    // Fix for the control sidebar height
    const $controlSidebar = $(Selector.controlSidebar);
    if (typeof $controlSidebar !== "undefined") {
      if ($controlSidebar.height() > postSetHeight)
        $(Selector.contentWrapper).css("min-height", $controlSidebar.height());
    }
  }

  // Make sure the body tag has the .fixed class
  if (!$("body").hasClass(ClassName.fixed)) {
    if (typeof $.fn.slimScroll !== "undefined") {
      $(Selector.sidebar).slimScroll({ destroy: true }).height("auto");
    }
    return;
  }

  // Enable slimscroll for fixed layout
  if (typeof $.fn.slimScroll !== "undefined") {
    // Destroy if it exists
    $(Selector.sidebar).slimScroll({ destroy: true }).height("auto");

    // Add slimscroll
    $(Selector.sidebar).slimScroll({
      height: `${$(window).height() - $(Selector.mainHeader).height()}px`,
      color: "rgba(0,0,0,0.2)",
      size: "3px",
    });
  }
}
