import React from "react";
import { simpleArrayUnNester } from "../ArrayUtils";

export const buildSelectPositions = (companyPositions, rolesFilter = null) => {
  const treeList = {};
  const treeOrder = [];
  let all = [];

  const enableFilter = rolesFilter && Array.isArray(rolesFilter);

  if (Array.isArray(companyPositions)) {
    const unNestedPositions = simpleArrayUnNester(
      companyPositions,
      "childPositions",
    );

    if (Array.isArray(unNestedPositions)) {
      unNestedPositions.forEach((levelItems, levelIndex) => {
        const levelOrderName = `positionLevel${levelIndex}`;
        const addList = [];

        treeOrder.push(levelOrderName);

        if (Array.isArray(levelItems)) {
          levelItems.forEach((itemInfo) => {
            if (
              !enableFilter ||
              (enableFilter && rolesFilter.indexOf(itemInfo.id) === -1)
            ) {
              addList.push({
                id: itemInfo.id,
                title: itemInfo.title,
                parentId: itemInfo.parentPositionId,
              });
            }
          });
        }

        treeList[levelOrderName] = {
          label: "Posições",
          list: addList,
          countText: "posições",
          color: "#333",
          icon: <i className="fas fa-id-badge" />,
        };

        all = [...all, ...addList];
      });
    }
  }

  return { treeList, treeOrder, all };
};
