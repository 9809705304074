import * as calc from "./calc";
import * as config from "./config";
import * as display from "./display";
import * as entity from "./entity";
import * as filter from "./filter";
import * as validation from "./validation";

const gbUtils = {
  ...calc,
  ...config,
  ...display,
  ...entity,
  ...filter,
  ...validation
};

export default gbUtils;
