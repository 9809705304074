const en = {
  button_archive: "See { customLabel } filed ",
  button_enabled: "See { customLabel } enabled ",
};

const es = {
  button_archive: "Ver { customLabel } archivad{ao}s",
  button_enabled: "Ver { customLabel } activ{ao}s",
};

const pt = {
  button_archive: "Ver { customLabel } arquivad{ao}s ",
  button_enabled: "Ver { customLabel } ativ{ao}s ",
};

const translations = { en, es, pt };

export default translations;
