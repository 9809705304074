import { objSimplify } from "../ArrayUtils";

const getSimplifiedData = (nesterConfigs) => {
  const readyToNest = [];

  if (Array.isArray(nesterConfigs)) {
    // Normalização dos valores para cruzamento de dados
    nesterConfigs.forEach((nesterCandidateConfig) => {
      const { data, selfIdPath, parentIdPath } = nesterCandidateConfig;

      if (Array.isArray(data) && selfIdPath) {
        const simplifiedData = [];

        data.forEach((dataInfo) => {
          let simplified = objSimplify(dataInfo, {
            path: selfIdPath,
            newName: "selfIdPath",
          });

          if (simplified.selfIdPath) {
            if (parentIdPath)
              simplified = objSimplify(simplified, {
                path: parentIdPath,
                newName: "parentIdPath",
              });

            simplifiedData.push({ ...simplified, originalData: dataInfo });
          }
        });

        readyToNest.push({ ...nesterCandidateConfig, data: simplifiedData });
      }
    });
  }

  return readyToNest;
};

const getOrderedParentNester = (readyToNest) => {
  // Revertendo a ordem para identificar o pai de cada filho
  return [...readyToNest].sort((a, b) => {
    return Number(a.level) > Number(b.level) ? -1 : 1;
  });
};

const relateChildsToParents = (childNesterCandidate) => {
  const parentChilds = {};
  let childNestedName = null;

  if (childNesterCandidate) {
    // Filtrando os filhos
    const { data, nestedName } = childNesterCandidate;
    const onlyOriginalData = childNesterCandidate.onlyOriginalData !== false;

    if (data && nestedName) {
      childNestedName = nestedName;
      // Identificando os filhos
      data.forEach((childItemData) => {
        const { parentIdPath, originalData } = childItemData;
        const appendData = onlyOriginalData ? originalData : childItemData;

        const currentParentChilds = parentChilds[parentIdPath] || [];
        parentChilds[parentIdPath] = [...currentParentChilds, appendData];
      });
    }
  }

  return { parentChilds, childNestedName };
};

const parentsGetTheirChildren = (
  parentNesterCandidate,
  childNestedName,
  childParents,
) => {
  if (parentNesterCandidate && parentNesterCandidate.data) {
    // Relacionando cada filho com o pai
    const { data } = parentNesterCandidate;
    const updatedData = [];

    if (data && childNestedName) {
      data.forEach((parentItemData) => {
        const { selfIdPath, originalData } = parentItemData;

        const appendChilds = {
          [childNestedName]: childParents[selfIdPath] || null,
        };

        updatedData.push({
          ...parentItemData,
          ...appendChilds,
          originalData: {
            ...originalData,
            ...appendChilds,
          },
        });
      });

      return updatedData;
    }
  }

  return [];
};

export const arraysNester = (
  nesterConfigs = [
    {
      level: 0,
      data: [{ level1: { id: 0 } }],
      selfIdPath: "level1.id",
      onlyOriginalData: true,
    },
  ],
) => {
  const readyToNest = getSimplifiedData(nesterConfigs);
  const finalNested = [];

  const orderedNesterConfigs = getOrderedParentNester(readyToNest);

  // Cruzamento de dados e construção do Array Aninhado
  // Necessário começar debaixo pra cima, pois é uma função dinâmica,
  // que pode ter vários níveis abaixo [child3, child2, child1, parent0]
  // Exemplo de resultado esperado:
  // { parent0: { id: 0, child1 : { id: 0, child2 : { id: 0, child3 } } } }
  orderedNesterConfigs.forEach((parentNesterCandidate, parentNesterIndex) => {
    const childNesterCandidate = orderedNesterConfigs[parentNesterIndex - 1];
    const { parentChilds, childNestedName } = relateChildsToParents(
      childNesterCandidate,
    );

    // Pais felizes já estão com todos os seus filhos
    orderedNesterConfigs[parentNesterIndex].data = parentsGetTheirChildren(
      parentNesterCandidate,
      childNestedName,
      parentChilds,
    );

    // Se for o último grupo de pais, finalizar o aninhamento
    if (parentNesterIndex + 1 === orderedNesterConfigs.length) {
      const lastParent = orderedNesterConfigs[parentNesterIndex];

      lastParent.data.forEach((finalParentDataInfo) => {
        const appendParent =
          lastParent.onlyOriginalData === false
            ? finalParentDataInfo
            : finalParentDataInfo.originalData;

        finalNested.push(appendParent);
      });
    }
  });

  return finalNested;
};
