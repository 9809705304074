import React from "react";
import styled from "styled-components";

import { getToFixed } from "../../utils/MathUtils";

const MainPercentage = styled.div`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? `${backgroundColor} !important` : ""};
  @media print {
    background-color: ${({ backgroundColor }) =>
      backgroundColor ? `${backgroundColor} !important` : ""};
    div {
      color: #fff !important;
    }
  }
`;

const ChildPercentage = styled.div`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? `${backgroundColor} !important` : ""};
  @media print {
    background-color: ${({ backgroundColor }) =>
      backgroundColor ? `${backgroundColor} !important` : ""};
    div {
      color: #fff !important;
    }
  }
`;

const SimplePercentageLine = (props) => {
  const {
    overrideColor = "#ccc",
    backgroundColor = "#333",
    percentage = 50,
    height = "18px",
    defaultColor = "#eee",
    isNULL = false,
    hidePercentages = false,
    primaryStyles = {},
    secondaryStyles = {},
  } = props;

  const cssPercentage = `${percentage}%`;
  const primaryPercentage = 100 - percentage;

  const bgColor = isNULL ? defaultColor : backgroundColor;

  const mainStyles = {
    width: "100%",
    position: "relative",
    height,
    textAlign: "right",
    fontSize: "12px",
    lineHeight: height,
    overflow: "hidden",
    ...primaryStyles,
  };

  const childStyles = {
    width: isNULL ? "0%" : cssPercentage,
    position: "absolute",
    top: 0,
    left: 0,
    height,
    textAlign: "right",
    fontSize: "12px",
    lineHeight: height,
    transition: ".3s",
    ...secondaryStyles,
  };

  const fontStyles = {
    color: "#fff",
    fontWeight: "bold",
    padding: "0px 4px",
  };

  return (
    <MainPercentage style={mainStyles} backgroundColor={bgColor}>
      {!hidePercentages && (
        <div style={fontStyles}>
          {!isNULL && `${getToFixed(primaryPercentage, 1)}%`}
        </div>
      )}
      <ChildPercentage style={childStyles} backgroundColor={overrideColor}>
        {!hidePercentages && (
          <div style={fontStyles}>
            {!isNULL && `${getToFixed(percentage, 1)}%`}
          </div>
        )}
      </ChildPercentage>
    </MainPercentage>
  );
};

export default SimplePercentageLine;
