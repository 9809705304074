import React, { useState } from "react";
import PropTypes from "prop-types";
import FormDialog from "../../../../../../Common/FormDialog";
import MaterialTextField from "../../../../../../Common/MaterialTextField";
import { getSkillsEntity } from "../../../../../../../utils/skillsAssessment/entity";
import { addNewSkill } from "../../../../../../../utils/skillsAssessment/manipulation";
import { getDisplayCompetenceAreas } from "../../../../../../../utils/skillsAssessment/display";
import SelectCompetenceArea from "./SelectCompetenceArea";
import {
  validateSkills,
  validateNewSkill,
} from "../../../../../../../utils/skillsAssessment/validation";
import { getStyledErrorMessage } from "../../../../../../../utils/formValidation";
import { Errors } from "../../../../../../../utils/validations";

const defaultForm = getSkillsEntity([{}], { canInsertNewId: true })[0];

const NewSkill = ({ open, afterConfirm, afterCancel, toolAdministration }) => {
  const [selectModal, setSelectModal] = useState({
    type: null,
    onConfirm: () => { },
    onCancel: () => { },
  });
  const [form, setForm] = useState(defaultForm);

  const { competenceAreas, skills } = toolAdministration;

  const handleConfirm = () => {
    const newAdministration = addNewSkill(toolAdministration, form);

    afterConfirm(newAdministration);
  };

  const handleCancel = () => {
    afterCancel();
  };

  const closeSelectModal = () => {
    setSelectModal({ type: null, onConfirm: () => { }, onCancel: () => { } });
  };

  const updateField = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const selectCompetenceAreas = (selected) => {
    setForm({ ...form, competenceArea: selected });
    closeSelectModal();
  };

  const openSelectCompetenceArea = () => {
    setSelectModal({
      type: "select_competence_area",
      onConfirm: (selected) => selectCompetenceAreas(selected[0]),
      onCancel: closeSelectModal,
    });
  };

  const validateForm = () => {
    const errors = new Errors();

    errors.addMultiple(validateNewSkill(form, skills));
    errors.addMultiple(validateSkills([form]));

    return { isValid: !errors.hasErrors(), errors: errors.getErrors() };
  };

  const formTitle = "Adicionar Habilidade/Competência";
  const { isValid, errors } = validateForm();

  const displayAreas =
    form.competenceArea && form.competenceArea.id ? [form.competenceArea] : [];

  const displayCompetenceAreas = getDisplayCompetenceAreas(
    competenceAreas,
    displayAreas,
  );

  return (
    <FormDialog
      open={open}
      title={formTitle}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      blockConfirm={!isValid}
    >
      <SelectCompetenceArea
        open={selectModal.type === "select_competence_area"}
        competenceAreas={competenceAreas}
        onConfirm={selectModal.onConfirm}
        onCancel={selectModal.onCancel}
        singleSelect
      />
      <div className="row">
        <div className="col-xs-12">
          <MaterialTextField
            id="skillTitle"
            label="Título"
            value={form.title}
            onChange={(e) => updateField("title", e.target.value)}
          />
        </div>
        <div className="col-xs-12">
          <MaterialTextField
            id="skillDescription"
            label="Descrição"
            value={form.description}
            onChange={(e) => updateField("description", e.target.value)}
            multiline
            rows={4}
            primaryInputProps={{ maxLength: 1500 }}
          />
        </div>
        <div className="col-xs-12">
          <MaterialTextField
            id="skillCompetenceArea"
            label="Áreas de Competência"
            value={displayCompetenceAreas}
            onClick={openSelectCompetenceArea}
          />
        </div>
        <div className="col-xs-12">
          {getStyledErrorMessage(["duplicated_title"], errors)}
        </div>
      </div>
    </FormDialog>
  );
};

NewSkill.propTypes = {
  open: PropTypes.bool,
  afterConfirm: PropTypes.func.isRequired,
  afterCancel: PropTypes.func.isRequired,
  toolAdministration: PropTypes.object.isRequired,
};

NewSkill.defaultProps = {
  open: false,
};

export default NewSkill;
