/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  getSkillAssessmentStates,
  getSelectedToolStates,
} from "../../../customMapStates";
import { addParticipantsToBoard } from "../../../../actions/skillAssessmentActions";
import { useSimpleToolAdministration } from "../../../../utils/skillsAssessment/hooks/administration";
import {
  filterParticipantsToBoard,
  getParticipantsByProfiles,
  getParticipantsByQuestions,
  getParticipantsBySelectModal,
} from "../../../../utils/skillsAssessment/filter";
import { useFilteredInsights } from "../../../../utils/hooks/insightHooks";
import NewInsight from "../../../Common/SkillAssessment/NewInsight";
import { getObjectInfoById } from "../../../../utils/ArrayUtils";

const SkillAssessment = ({
  type,
  clearViewCallback,
  cancelCallback,
  selectedTool,
  allAnswers,
  allSkillAssessment,
  isLoading,
  params,
  addParticipantsToBoard,
}) => {
  const { toolAdministration } = useSimpleToolAdministration({
    selectedTool,
    allSkillAssessment,
    isLoading,
  });
  const { filteredInsights } = useFilteredInsights({ allAnswers });
  const [filteredParticipants, setFilteredParticipants] = useState([]);

  const { insightQuestion = {} } = params;
  const { theme = {} } = insightQuestion;
  const { participants = [] } = toolAdministration;

  const profile = getObjectInfoById(
    insightQuestion.skillsAssessmentProfileId,
    toolAdministration.profiles,
    "id",
  );

  const { notAdded } = filterParticipantsToBoard(
    filteredInsights,
    toolAdministration.participants,
    theme.companyAreaId,
  );

  useEffect(() => {
    const { skillsAssessmentProfileId } = insightQuestion;

    if (skillsAssessmentProfileId) {
      const newFiltered = getParticipantsByQuestions(
        [insightQuestion],
        toolAdministration.participants,
      );

      if (!_.isEqual(newFiltered, filteredParticipants))
        setFilteredParticipants(newFiltered);
    } else if (filteredParticipants.length > 0) {
      setFilteredParticipants([]);
    }
  }, [insightQuestion, participants]);

  function clearDialogControll() {
    clearViewCallback(type);
    cancelCallback();
  }

  function addParticipants(selected) {
    const selectedParticipants = getParticipantsBySelectModal(
      selected,
      toolAdministration.participants,
    );

    addParticipantsToBoard(selectedParticipants, insightQuestion, params.order);
    clearDialogControll();
  }

  const displayParticipants = getParticipantsByProfiles(notAdded, [profile]);

  return (
    <>
      <NewInsight
        open
        onConfirm={addParticipants}
        onCancel={clearDialogControll}
        participants={displayParticipants}
        profileName={insightQuestion.text}
      />
    </>
  );
};

SkillAssessment.propTypes = {
  type: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  selectedTool: PropTypes.object.isRequired,
  allAnswers: PropTypes.object.isRequired,
  allSkillAssessment: PropTypes.array.isRequired,
  addParticipantsToBoard: PropTypes.func.isRequired,
  clearViewCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func,
  isLoading: PropTypes.bool,
};

SkillAssessment.defaultProps = {
  isLoading: false,
  cancelCallback: () => {},
};

const mapStateToProps = (state) => {
  const { selectedTool = {}, allAnswers } = getSelectedToolStates(state);
  const { allSkillAssessment = [], isLoading } =
    getSkillAssessmentStates(state);

  return {
    selectedTool,
    allAnswers,
    allSkillAssessment,
    isLoading,
  };
};

export default connect(mapStateToProps, { addParticipantsToBoard })(
  SkillAssessment,
);
