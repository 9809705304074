import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import NewRoleBonusDialog from "./NewRoleBonusDialog";
import FormDialog from "../../FormDialog";
import SelectItemModal from "../../SelectItemModal";
import MaterialTextField from "../../MaterialTextField";
import { getSelectedToolStates } from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";
import { getGoalBonusConfiguration } from "../../../../actions/goalsAndBonusActions";
import { buildGoalsAdministrationFinalBody } from "../../../../utils/goalsBonus/entity";

const { getErrorMessage, getObjectInfoById } = utils;

const useStyles = makeStyles(() =>
  createStyles({
    addButton: {
      fontSize: "12px",
    },
  }),
);

const messages = defineMessages({
  gb_message_select_job_role: { id: "gb_message_select_job_role" },
  gb_error_role_already_added: { id: "gb_error_role_already_added" },
  gb_add_new_theme: { id: "gb_add_new_theme" },
  gb_form_select_job_role: { id: "gb_form_select_job_role" },
  global_select: { id: "global.select" },
});

const initialForm = {
  roles: [],
};

const ThemeRoleSelectDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const [newRoleModal, setNewRoleModal] = useState(false);
  const [selectRoleModal, setSelectRoleModal] = useState(false);
  const {
    open = false,
    afterCancel,
    afterConfirm,
    goalBonusAdministration,
    setGoalBonusAdministration,
    addedRoles,
    selectedToolID,
    intl,
  } = props;

  let alternativeSaveRoleCallback =
    typeof props.alternativeSaveRoleCallback === "function"
      ? props.alternativeSaveRoleCallback
      : () => {};

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  const getToolConfig = async () => {
    if (selectedToolID) {
      const { data } = await getGoalBonusConfiguration(selectedToolID);

      if (data && data.id) {
        const fetchedConfiguration = await buildGoalsAdministrationFinalBody(
          data,
        );

        return fetchedConfiguration;
      }
    }
  };

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleConfirm = () => {
    if (typeof afterConfirm === "function") afterConfirm(form);
  };

  const handleCancel = () => {
    if (typeof afterCancel === "function") afterCancel();
  };

  const handleUpdateForm = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const getRoleDisplayErrors = (slug = "repeatedRole", errors = []) => {
    return form.roles.map((roleId, index) => {
      let haveError = null;

      errors.forEach((iteratingErr) => {
        if (iteratingErr.slug === `${slug}${roleId}`) haveError = true;
      });

      return haveError ? (
        <div style={{ height: "40px" }} key={index}>
          {getErrorMessage([`${slug}${roleId}`], errors, "left", {
            height: "0px",
          })}
        </div>
      ) : null;
    });
  };

  const getFormErrors = () => {
    let errors = [];

    if (form.roles.length === 0) {
      errors.push({
        slug: "roleNotSelected",
        message: intl.formatMessage(messages.gb_message_select_job_role),
      });
    }

    form.roles.forEach((roleId) => {
      const roleInfo = getObjectInfoById(
        roleId,
        goalBonusAdministration.roleBonuses,
        "id",
      );

      if (addedRoles.indexOf(roleId) > -1) {
        errors.push({
          slug: `repeatedRole${roleId}`,
          message: intl.formatMessage(messages.gb_error_role_already_added, {
            role: roleInfo.role,
          }),
        });
      }
    });

    return errors;
  };

  const buildRoleOptions = (roles) => {
    let finalOptions = [];

    if (roles && roles.length > 0) {
      roles.forEach(({ id, role }) => finalOptions.push({ id, title: role }));
    }

    return finalOptions;
  };

  const updateSelectedRoles = (selectedRoles) => {
    handleUpdateForm("roles", selectedRoles);

    toggleSelectRoleModal(false);
  };

  const alternativeSave = (submitGoalsBonusAdm) => {
    toggleNewRoleModal(false);
    alternativeSaveRoleCallback(submitGoalsBonusAdm);
  };

  const toggleNewRoleModal = (toggleTo = true) => {
    setNewRoleModal(toggleTo);
  };

  const toggleSelectRoleModal = (toggleTo = true) => {
    setSelectRoleModal(toggleTo);
  };

  const getSelectRolesDisplay = (list) => {
    let displayTitles = [];

    goalBonusAdministration.roleBonuses.forEach((roleInfo) => {
      if (list.indexOf(roleInfo.id) > -1) displayTitles.push(roleInfo.role);
    });

    return displayTitles.length > 0
      ? displayTitles.join(", ")
      : intl.formatMessage(messages.global_select);
  };

  const roleOptions = buildRoleOptions(goalBonusAdministration.roleBonuses);
  const classes = useStyles();
  const formErrors = getFormErrors();

  return (
    <>
      <NewRoleBonusDialog
        open={newRoleModal}
        goalBonusAdministration={goalBonusAdministration}
        setGoalBonusAdministration={setGoalBonusAdministration}
        afterConfirm={(submitGoalsBonusAdm) =>
          alternativeSave(submitGoalsBonusAdm)
        }
        afterCancel={() => toggleNewRoleModal(false)}
        getToolConfig={getToolConfig}
      />
      <SelectItemModal
        open={selectRoleModal}
        itemsList={roleOptions || []}
        onCancel={() => toggleSelectRoleModal(false)}
        onConfirm={(selectedRoles) => {
          updateSelectedRoles(selectedRoles);
        }}
        title={intl.formatMessage(messages.gb_form_select_job_role)}
        initialSelected={form.roles}
        replaceSelected
      />
      <FormDialog
        open={open}
        title={intl.formatMessage(messages.gb_add_new_theme)}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        blockConfirm={formErrors.length > 0}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialTextField
              id="roleSelect"
              label={intl.formatMessage(messages.gb_form_select_job_role)}
              value={getSelectRolesDisplay(form.roles)}
              onClick={() => toggleSelectRoleModal(true)}
              inputStyle={{ cursor: "pointer" }}
            />
            {getRoleDisplayErrors("repeatedRole", formErrors)}
            <Button
              variant="outlined"
              className={classes.addButton}
              onClick={() => toggleNewRoleModal(!newRoleModal.open)}
            >
              <AddIcon /> Cadastrar nova
            </Button>
          </div>
        </div>
      </FormDialog>
    </>
  );
};

export default injectIntl(
  connect((state) => {
    const { selectedToolID } = getSelectedToolStates(state);
    return { selectedToolID };
  })(ThemeRoleSelectDialog),
);
