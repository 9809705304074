import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { injectIntl, defineMessages } from "react-intl";
import _ from "lodash";
import {
  getTeamFieldTitleByIntl,
  getMemberbyId,
} from "../../../../utils/projectEvaluation";
import SelectMemberModal from "../../../Common/SelectMemberModal";
import FormDialog from "../../../Common/FormDialog";
import { getCompanyMembersAndParticipants } from "../../../customMapStates";

const messages = defineMessages({
  involved: {
    id: "tool.project.people_involved",
  },
  new_member: {
    id: "global.new_member",
  },
  physicalProgress: {
    id: "tool.project.physical_progress",
  },
  members: {
    id: "global.members",
  },
  executant: {
    id: "tool.project.performer",
  },
  responsible: {
    id: "global.responsable",
  },
  choose_responsible: {
    id: "tool.project.choose_the_resposible",
  },
  choose_executant: {
    id: "tool.project.choose_the_executant",
  },
  chooseTeam: {
    id: "pport_ph_team_table_choose",
  },
  selectedTeam: {
    id: "pport_ph_team_table_selected",
  },
  choose_member: {
    id: "tool.project.choose_the_new_member",
  },
  confirmInformation: {
    id: "tool.project.confirmInformation",
  },
  areYouSure: {
    id: "tool.project.areYouSure",
  },
  willBeExcluded: {
    id: "tool.project.willExcluded",
  },
  cancelText: {
    id: "btn_modal_cancel",
  },
  confirmText: {
    id: "btn_modal_confirm",
  },
});

const TeamTable = ({
  projectTeam,
  setProjectTeam,
  companyMembersAndParticipants,
  intl,
  saveButton,
  saveButtonJSX,
  updateResponsible,
}) => {
  const [memberModalOpen, setMemberModalOpen] = useState(false);

  const [memberModalConfig, setMemberModalConfig] = useState({
    title: null,
    value: null,
    singleSelect: false,
  });

  const [deleteMemberOpen, setDeleteMemberOpen] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState({
    member: null,
    index: null,
    userType: null,
  });

  useEffect(() => {
    if (memberToDelete.member) {
      setDeleteMemberOpen(true);
    }
  }, [memberToDelete]);

  const displayMembersTitle = () => {
    return (
      <tbody>
        <tr className="teamInvolved_cellSize membersTitle">
          <td align="left">
            <strong>
              <i className="fas fa-users" />{" "}
              {intl.formatMessage(messages.members)}
            </strong>
          </td>
          <td align="center" className="membersTitleTd">
            <i className="fas fa-plus" />
          </td>
        </tr>
      </tbody>
    );
  };

  const handleDeleteMember = (memberToDelete = {}) => {
    let updatedMembers = [];

    updatedMembers = projectTeam.involved.filter((member) =>
      member.memberParticipant
        ? member.memberParticipant.id !== memberToDelete.id
        : member.memberMember.id !== memberToDelete.id,
    );

    setProjectTeam({
      ...projectTeam,
      involved: [...updatedMembers],
    });
  };

  const handleDeleteConfirmation = () => {
    if (memberToDelete && memberToDelete.member) {
      if (memberToDelete.userType) {
        const { userType, member } = memberToDelete;
        if (userType !== "memberParticipant" && userType !== "memberMember") {
          setProjectTeam({
            ...projectTeam,
            [userType]: null,
          });
        } else {
          handleDeleteMember(member);
        }
      }
    }

    setMemberToDelete({
      ...memberToDelete,
      member: null,
      index: null,
      userType: null,
    });

    setDeleteMemberOpen(false);
  };

  const displayConfirmDeleteMember = () => {
    const modalTitle = intl.formatMessage(messages.areYouSure);
    const memberName =
      memberToDelete && memberToDelete.member ? memberToDelete.member.name : "";

    return (
      deleteMemberOpen && (
        <FormDialog
          open
          title={modalTitle}
          onConfirm={handleDeleteConfirmation}
          onCancel={() => setDeleteMemberOpen(false)}
          bodyStyle={{ padding: "15px 15px 15px 15px" }}
        >
          <div>
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Col md={10}>
                <strong>{memberName}</strong>{" "}
                {intl.formatMessage(messages.willBeExcluded)}
              </Col>
            </Row>
          </div>
        </FormDialog>
      )
    );
  };

  const getUserType = (type, member) => {
    const currentType = type || null;
    let userType = "";

    if (currentType && currentType === "executant") {
      if (member) {
        const currentMember = member || {};
        userType =
          currentMember.type === "MEMBER"
            ? "executantMember"
            : currentMember.type === "PARTICIPANT"
            ? "executantParticipant"
            : "";
      } else {
        userType =
          projectTeam && projectTeam.executantMember
            ? "executantMember"
            : projectTeam.executantParticipant
            ? "executantParticipant"
            : "";
      }
    } else if (currentType && currentType === "responsible") {
      if (member) {
        const currentMember = member || {};
        userType =
          currentMember.type === "MEMBER"
            ? "responsibleMember"
            : currentMember.type === "PARTICIPANT"
            ? "responsibleParticipant"
            : "";
      } else {
        userType =
          projectTeam && projectTeam.responsibleMember
            ? "responsibleMember"
            : projectTeam.responsibleParticipant
            ? "responsibleParticipant"
            : "";
      }
    } else if (currentType && currentType === "member") {
      if (member) {
        const currentMember = member || {};

        userType =
          currentMember.type === "MEMBER"
            ? "memberMember"
            : currentMember.type === "PARTICIPANT"
            ? "memberParticipant"
            : "";
      }
    }

    return userType;
  };

  const handleSelectMember = (selectedMemberId) => {
    const selectedMember = getMemberbyId(
      companyMembersAndParticipants,
      selectedMemberId,
    );
    let slug = null;

    if (memberModalConfig.value === "Responsible") {
      slug = getUserType("responsible", selectedMember);

      updateResponsible(selectedMember.id);

      setProjectTeam({
        ...projectTeam,
        [slug]: selectedMember,
      });
    } else if (memberModalConfig.value === "Executant") {
      slug = getUserType("executant", selectedMember);

      setProjectTeam({
        ...projectTeam,
        [slug]: selectedMember,
      });
    } else if (memberModalConfig.value === "Member") {
      slug = "memberParticipant";
      setProjectTeam({
        ...projectTeam,
        involved: [...projectTeam.involved, { [slug]: selectedMember }],
      });
    }

    setMemberModalOpen(false);
  };

  const handleNewEntry = (type) => {
    const { title, value, singleSelect } = getTeamFieldTitleByIntl(type, intl);

    setMemberModalConfig({
      ...memberModalConfig,
      title,
      value,
      singleSelect,
    });

    setMemberModalOpen(true);
  };

  const getProjectTopPeople = () => {
    const currentProjectTeam = projectTeam || [];

    const responsible = currentProjectTeam.responsibleMember
      ? currentProjectTeam.responsibleMember
      : currentProjectTeam.responsibleParticipant
      ? currentProjectTeam.responsibleParticipant
      : currentProjectTeam.responsible;

    const executant = currentProjectTeam.executantMember
      ? currentProjectTeam.executantMember
      : currentProjectTeam.executantParticipant
      ? currentProjectTeam.executantParticipant
      : currentProjectTeam.executant;

    return { responsible, executant };
  };

  const handleDeleteTeamMember = (member, slug = "") => {
    const { responsible, executant } = getProjectTopPeople();

    if (executant && executant.id === member.id && slug === "executant") {
      setMemberToDelete({
        ...memberToDelete,
        member,
        userType: getUserType("executant"),
      });
    } else if (
      responsible &&
      responsible.id === member.id &&
      slug === "responsible"
    ) {
      setMemberToDelete({
        ...memberToDelete,
        member,
        userType: getUserType("responsible"),
      });
    } else if (member && member.id) {
      setMemberToDelete({
        ...memberToDelete,
        member,
        userType: "memberParticipant",
      });
    }
  };

  const displayHeaderTable = () => {
    const { responsible, executant } = getProjectTopPeople();

    return (
      <tbody>
        <tr className="teamInvolved_cellSize">
          <td align="left" className="titleTD">
            <div>{intl.formatMessage(messages.responsible)}</div>
          </td>
          {responsible ? (
            <>
              <td align="center">{responsible.name}</td>
              <td
                align="center"
                onClick={() =>
                  handleDeleteTeamMember(responsible, "responsible")
                }
              >
                <i className="far fa-trash-alt" />
              </td>
            </>
          ) : (
            <td
              align="center"
              colSpan="2"
              onClick={() => handleNewEntry("responsible")}
            >
              <i className="fas fa-plus" />
            </td>
          )}
        </tr>
        <tr className="teamInvolved_cellSize">
          <td align="left" className="titleTD">
            <div>{intl.formatMessage(messages.executant)}</div>
          </td>
          {executant ? (
            <>
              <td align="center">{executant.name}</td>
              <td
                align="center"
                onClick={() => handleDeleteTeamMember(executant, "executant")}
              >
                <i className="far fa-trash-alt" />
              </td>
            </>
          ) : (
            <td
              align="center"
              colSpan="2"
              onClick={() => handleNewEntry("executant")}
            >
              <i className="fas fa-plus" />
            </td>
          )}
        </tr>
      </tbody>
    );
  };

  const generateDefaultTableFields = () => {
    const teamLength = projectTeam.involved.length;
    const defaultFields = 8 - teamLength;
    const defaultMembersLine = [];

    for (let i = 0; i < defaultFields; i++) {
      defaultMembersLine.push(
        <tr key={i}>
          <td align="left">...</td>
          <td align="center">
            <i className="fas fa-user-plus" style={{ color: "#CCC" }} />
          </td>
        </tr>,
      );
    }
    return defaultMembersLine;
  };

  const displayTableBody = () => {
    const teamLength = projectTeam.involved.length;

    return (
      <tbody>
        {projectTeam.involved.map((member, index) => {
          const person = member.memberParticipant || null;

          return (
            <tr key={index}>
              <td align="left">{person ? person.name : " "}</td>
              <td align="center" onClick={() => handleDeleteTeamMember(person)}>
                <i className="far fa-trash-alt" />.
              </td>
            </tr>
          );
        })}
        <tr>
          <td align="left"> {intl.formatMessage(messages.new_member)} </td>
          <td align="center">
            <div onClick={() => handleNewEntry("member")}>
              <i className="fas fa-user-plus" />
            </div>
          </td>
        </tr>
        {teamLength < 9 && generateDefaultTableFields()}
      </tbody>
    );
  };

  const tableTitle = intl.formatMessage(messages.involved);

  return (
    <div className="box box-primary">
      <div id="header-insight-evaluation" className="box-header with-border">
        <i className="fas fa-users" />
        <h3 className="box-title"> {tableTitle}</h3>
        {saveButton && saveButtonJSX()}
        <br />
      </div>
      <div className="box-body teamTableBox">
        <Row style={{ width: "100%" }}>
          <Col md={12}>
            <table className="simple-table" style={{ marginBottom: "0px" }}>
              {displayHeaderTable()}
            </table>
            <table
              className="simple-table"
              style={{ marginTop: "0px", marginBottom: "0px" }}
            >
              {displayMembersTitle()}
            </table>
            <div className="fixedScroll teamFixedScroll">
              <table className="simple-table" style={{ marginTop: "0px" }}>
                {displayTableBody()}
              </table>
            </div>
          </Col>
        </Row>

        {memberModalOpen && (
          <SelectMemberModal
            open
            title={memberModalConfig.title ? memberModalConfig.title : " "}
            toggleOpen={() => setMemberModalOpen(!memberModalOpen)}
            onConfirm={(selected) => handleSelectMember(selected[0])}
            membersList={companyMembersAndParticipants}
            initialSelected={[]}
            confirmText={intl.formatMessage(messages.confirmText)}
            cancelText={intl.formatMessage(messages.cancelText)}
            singleSelect
          />
        )}
      </div>
      {displayConfirmDeleteMember()}
    </div>
  );
};
const mapStateToProps = (state) => {
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);

  return {
    companyMembersAndParticipants,
  };
};

function areEqual(prevProps, nextProps) {
  if (!_.isEqual(prevProps.projectTeam, nextProps.projectTeam)) return false;
  if (!_.isEqual(prevProps.saveButton, nextProps.saveButton)) return false;
  if (!_.isEqual(prevProps.saveButtonJSX, nextProps.saveButtonJSX))
    return false;
  return true;
}

export default connect(mapStateToProps)(
  React.memo(injectIntl(TeamTable), areEqual),
);
