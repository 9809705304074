const ambientes = {
  desenvolvimento: "desenvolvimento",
  homologacao: "homologacao",
  producao: "producao",
};

const ambiente = process.env.REACT_APP_AMBIENTE;
const nodeApi = process.env.REACT_APP_API_NODE;
const javaApi = process.env.REACT_APP_API_JAVA;

const devEnv = ambiente === ambientes.desenvolvimento;
const hmlEnv = ambiente === ambientes.homologacao;
const prdEnv = ambiente === ambientes.producao;

const env = {
  isDev: () => devEnv,
  isHml: () => hmlEnv,
  isPrd: () => prdEnv,
  isPrdOrHml: () => prdEnv || hmlEnv,
  isDevOrHml: () => devEnv || hmlEnv,
  getAmbiente: () => ambiente,
  getBaseURLNodeApi: () => nodeApi,
  getBaseURLJavaApi: () => javaApi,
};

export default env;
