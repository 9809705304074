import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import _ from "lodash";
import { List, ListItem, ListItemText, Avatar } from "@material-ui/core";
import FormDialog from "./FormDialog";
import utils from "../../utils/toolUtils";
import { getAllCompanyWorkspaces } from "../../actions/workspaceActions";
import { translatedText } from "../../utils/translationUtils";

const { getFilteredTreeList } = utils;

const messages = defineMessages({
  noDataToDisplay: {
    id: "global.noDataTable",
  },
  workspacesTitle: {
    id: "workspaces.title",
  },
  globalTools: {
    id: "global.tools",
  },
  globalSceneries: {
    id: "global.sceneries",
  },
  globalThemes: {
    id: "global.themes",
  },
  globalSubThemes: {
    id: "global.subThemes",
  },
  globalInsights: {
    id: "global.insights",
  },
  globalSelectedConnections: {
    id: "global.selectedConnections",
  },
});

const translation = (id) => translatedText(id, messages);

const SelectTraceabilityModal = ({
  open,
  title,
  description,
  treeOrder,
  onConfirm,
  onCancel,
  singleSelect,
  initialSelected,
  resetWhenOpen,
  intl,
  modalTextCancel,
  modalTextConfirm,
  selectedCompany,
  treeFilter,
}) => {
  const [workspacesTree, setWorkspacesTree] = useState([]);
  const [displayTree, setDisplayTree] = useState([]);
  const [selectedTraceabilities, setSelectedTraceabilities] = useState([]);
  const [initialPreset, setInitialPreset] = useState(false);
  const [selectedTree, setSelectedTree] = useState({
    level: null,
    parentId: null,
  });

  const filteredTree = getFilteredTreeList(workspacesTree, treeFilter);
  const treeList = useMemo(() => {
    return {
      workspaces: {
        label: translation("workspacesTitle"),
        color: "#6b42a9",
        countText: translation("globalTools").toLowerCase(),
        icon: <i className="fas fa-briefcase" />,
        list: filteredTree.workspaces,
      },
      tools: {
        label: translation("globalTools"),
        color: "#fd4a67",
        countText: translation("globalSceneries").toLowerCase(),
        icon: <i className="fas fa-tools" />,
        list: filteredTree.tools,
      },
      sceneries: {
        label: translation("globalSceneries"),
        color: "#6eac16",
        countText: translation("globalThemes").toLowerCase(),
        icon: <i className="fab fa-buffer" />,
        list: filteredTree.sceneries,
      },
      themes: {
        label: translation("globalThemes"),
        color: "#b69072",
        countText: translation("globalSubThemes").toLowerCase(),
        icon: <i className="fas fa-palette" />,
        list: filteredTree.themes,
      },
      questions: {
        label: translation("globalSubThemes"),
        color: "#4394d5",
        countText: translation("globalInsights").toLowerCase(),
        icon: <i className="fas fa-comment" />,
        list: filteredTree.questions,
      },
      insights: {
        label: translation("globalInsights"),
        color: "#6bd33a",
        countText: translation("globalSelectedConnections"),
        icon: <i className="fas fa-lightbulb" />,
        list: filteredTree.insights,
      },
    };
  }, [filteredTree]);

  useEffect(() => {
    async function fetchConnectionsTree() {
      const fetchedWorkspacesTree = await getAllCompanyWorkspaces(
        selectedCompany.id,
      );

      if (
        fetchedWorkspacesTree.data &&
        !_.isEqual(workspacesTree, fetchedWorkspacesTree.data)
      ) {
        setWorkspacesTree(fetchedWorkspacesTree.data);
      }
    }

    if (selectedCompany && selectedCompany.id) {
      fetchConnectionsTree();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  useEffect(() => {
    if (open) {
      if (resetWhenOpen) setSelectedTraceabilities([]);

      setSelectedTree({
        level: treeOrder[0],
        parentId: null,
      });
    } else {
      setInitialPreset(false);
    }
  }, [open, resetWhenOpen, treeOrder]);

  useEffect(() => {
    if (!_.isEqual(treeList, displayTree)) setDisplayTree(treeList);
  }, [treeList, displayTree]);

  useEffect(() => {
    if (initialSelected && initialSelected.length > 0 && !initialPreset) {
      setSelectedTraceabilities(initialSelected);
      setInitialPreset(true);
    }
  }, [initialSelected, initialPreset]);

  const getSelectedTree = () => {
    return displayTree[selectedTree.level]
      ? displayTree[selectedTree.level]
      : {};
  };

  const getLastOrderTree = () => {
    return treeOrder[treeOrder.length - 1];
  };

  const getFilteredTreeBySelectedList = (treeLevel, selectedItems) => {
    let finalItems = [];

    if (displayTree[treeLevel] && displayTree[treeLevel].list) {
      const treeListClone = _.cloneDeep(displayTree[treeLevel].list);
      treeListClone.forEach((treeItem) => {
        treeItem.show = selectedItems.indexOf(treeItem.id) > -1 ? true : false;

        finalItems = [...finalItems, treeItem];
      });
    }

    return finalItems;
  };

  const isLastLevelSelected = (orderLevel) => {
    return getLastOrderTree() === orderLevel;
  };

  const lastTreeLevelOrder = getLastOrderTree();

  const isSelected = (checkID, treeLevel) => {
    return (
      selectedTraceabilities.indexOf(checkID) > -1 &&
      treeLevel === lastTreeLevelOrder
    );
  };

  const handleToggleSelect = (treeItemID) => {
    const selectedListClone = _.cloneDeep(selectedTraceabilities);
    let finalList = selectedTraceabilities;

    if (isSelected(treeItemID, lastTreeLevelOrder)) {
      selectedListClone.splice(selectedListClone.indexOf(treeItemID), 1);
      finalList = singleSelect ? [] : selectedListClone;
    } else {
      finalList = singleSelect
        ? [treeItemID]
        : [...selectedTraceabilities, treeItemID];
    }

    setSelectedTraceabilities(finalList);
  };

  const handleBreadcrumbClick = (slug, parentId) => {
    if (treeOrder.indexOf(slug) > -1)
      setSelectedTree({ level: slug, parentId });
  };

  const filterListByParentId = (list, compareParentId = null) => {
    let finalFiltered = [];

    list.forEach((itemInfo) => {
      if (itemInfo.parentId === compareParentId)
        finalFiltered = [...finalFiltered, itemInfo];
    });

    if (!compareParentId) finalFiltered = list;

    return finalFiltered;
  };

  const filterLevel = (list, parentId = null) => {
    if (list && list.length > 0) {
      list.forEach((info, index) => {
        list[index].show = true;
      });
      list = filterListByParentId(list, parentId);
    }

    return list;
  };

  const selectNextLevel = (parentId) => {
    const currentLevelIndexOf = treeOrder.indexOf(selectedTree.level);
    const nextLevel = currentLevelIndexOf + 1;

    if (treeOrder[nextLevel])
      setSelectedTree({ level: treeOrder[nextLevel], parentId });
  };

  const getFilteredDisplayTree = () => {
    const currentSelectedTree = getSelectedTree();

    return currentSelectedTree.list
      ? filterLevel(currentSelectedTree.list, selectedTree.parentId)
      : [];
  };

  const calcTotalDisplayed = (list) => {
    let total = 0;

    list.forEach(
      (treeItemInfo) => (total = treeItemInfo.show ? total + 1 : total),
    );

    return total;
  };

  const getBreadcrumbParentId = (breadcrumbIndex) => {
    const currentLevelIndexOf = treeOrder.indexOf(selectedTree.level);
    let replaceParentIndex = 0;
    let childsParentIds = [];

    if (
      parseInt(breadcrumbIndex, 10) > 0 &&
      parseInt(currentLevelIndexOf, 10) > 0
    ) {
      childsParentIds.push(selectedTree.parentId);
      for (let i = currentLevelIndexOf; i >= breadcrumbIndex; i--) {
        const parentLoopSlug = treeOrder[i];

        if (displayTree[parentLoopSlug] && displayTree[parentLoopSlug].list) {
          for (const index in displayTree[parentLoopSlug].list) {
            const itemInfo = displayTree[parentLoopSlug].list[index];

            if (itemInfo.id === childsParentIds[replaceParentIndex]) {
              if (itemInfo.parentId) {
                childsParentIds.push(itemInfo.parentId);
                replaceParentIndex++;
              }
              break;
            }
          }
        }
      }
    }

    const finalParentId = childsParentIds[childsParentIds.length - 1]
      ? childsParentIds[childsParentIds.length - 1]
      : null;

    return finalParentId;
  };

  const getItemBreadcrumb = (parentItemId, treeLevel) => {
    const currentItemIndex = treeOrder.indexOf(treeLevel);
    let finalBreadcrumbs = [];
    let ctrlBuildBreadcrumb = 0;
    let childsParentIds = [];

    if (currentItemIndex > 0) {
      childsParentIds.push(parentItemId);
      for (let i = currentItemIndex - 1; i >= 0; i--) {
        const parentLevelList = displayTree[treeOrder[i]].list;

        for (const index in parentLevelList) {
          const parentList = parentLevelList[index];
          if (parentList.id === childsParentIds[ctrlBuildBreadcrumb]) {
            finalBreadcrumbs.push(parentList.title);
            childsParentIds.push(parentList.parentId);
            ctrlBuildBreadcrumb++;
            break;
          }
        }
      }
    }
    finalBreadcrumbs.reverse();

    let finalPathString = "";

    finalBreadcrumbs.forEach((path, index) => {
      finalPathString = finalPathString + path;
      if (finalBreadcrumbs.length !== index + 1)
        finalPathString = finalPathString + " > ";
    });

    return finalPathString;
  };

  const displayBreadcrumbs = () => {
    let breadcrumbs = [];

    if (Object.keys(displayTree).length > 0) {
      for (const index in treeOrder) {
        const orderSlug = treeOrder[index];
        const treeLevelInfo = displayTree[orderSlug];

        breadcrumbs.push({
          label: treeLevelInfo.label,
          color: treeLevelInfo.color,
          slug: orderSlug,
          parentId: getBreadcrumbParentId(index),
        });

        if (orderSlug === selectedTree.level) break;
      }
    }

    let finalBreadcrumbs = [];
    breadcrumbs.forEach((info, index) => {
      const isLastItem = breadcrumbs.length === index + 1 ? true : false;

      finalBreadcrumbs = [
        ...finalBreadcrumbs,
        <>
          <a
            onClick={() =>
              isLastItem
                ? null
                : handleBreadcrumbClick(info.slug, info.parentId)
            }
            className="breadcrumb-button"
            style={{
              color: isLastItem ? "#fff" : info.color,
              cursor: isLastItem ? "default" : "pointer",
              pointerEvents: isLastItem ? "none" : "all",
              backgroundColor: isLastItem ? info.color : "#f9f9f9",
            }}
          >
            {info.label}
          </a>
          {!isLastItem ? (
            <span style={{ padding: "0px 10px" }}>
              <i className="fas fa-chevron-right" />
            </span>
          ) : null}
        </>,
      ];
    });

    return finalBreadcrumbs.map((item, index) => (
      <React.Fragment key={index}>{item}</React.Fragment>
    ));
  };

  const displaySelecionItem = (treeLevel, treeItemInfo, showBorder = true) => {
    const currentSelectedTree = displayTree[treeLevel];
    const currentLevelIndexOf = treeOrder.indexOf(treeLevel);
    const childrensLevelIndex = currentLevelIndexOf + 1;
    const childrenTree = displayTree[treeOrder[childrensLevelIndex]] || {
      list: [],
    };
    const filteredChildrens = filterLevel(
      childrenTree.list,
      treeItemInfo.id,
      childrenTree.hideOnList,
    );
    const checkLastLevelSelected = isLastLevelSelected(treeLevel);

    const avatarInfo = {
      title: currentSelectedTree.label,
      icon: currentSelectedTree.icon,
      color: currentSelectedTree.color,
    };

    const secondaryCountText = `${filteredChildrens.length} ${currentSelectedTree.countText}`;

    const borderColor =
      isSelected(treeItemInfo.id, treeLevel) && showBorder
        ? avatarInfo.color
        : "transparent";

    const displayStyle = treeItemInfo.show
      ? {
          opacity: "1",
          height: "62px",
          padding: "11px 16px",
          backgroundColor: "#fff",
        }
      : {
          left: "-100%",
          opacity: "0",
          height: "0px",
          padding: "0px 16px",
          pointerEvents: "none",
          backgroundColor: "#fff",
        };

    const onClickListItem = checkLastLevelSelected
      ? () => handleToggleSelect(treeItemInfo.id)
      : () => selectNextLevel(treeItemInfo.id);
    const shortedTitle =
      treeItemInfo.title.length > 47
        ? treeItemInfo.title.substr(0, 47) + "..."
        : treeItemInfo.title;
    const itemBreadcrumb = getItemBreadcrumb(treeItemInfo.parentId, treeLevel);

    return (
      <ListItem
        className="list-item-selection"
        style={{
          borderColor,
          ...displayStyle,
        }}
        onClick={() => (treeItemInfo.show ? onClickListItem() : null)}
      >
        <Avatar
          style={{ backgroundColor: avatarInfo.color, marginRight: "5px" }}
          title={avatarInfo.title}
        >
          {avatarInfo.icon}
        </Avatar>
        <ListItemText
          primary={shortedTitle}
          secondary={
            checkLastLevelSelected ? itemBreadcrumb : secondaryCountText
          }
          primaryTypographyProps={{
            style: {
              fontSize: "16px",
            },
            title: treeItemInfo.title,
          }}
          secondaryTypographyProps={{
            style: {
              fontSize: "14px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            },
            title: itemBreadcrumb,
          }}
        />
      </ListItem>
    );
  };

  const displaySelectedItems = () => {
    const filteredSelectedTree = getFilteredTreeBySelectedList(
      lastTreeLevelOrder,
      selectedTraceabilities,
    );

    return filteredSelectedTree.map((treeItemInfo, index) => {
      return (
        <React.Fragment key={index}>
          {displaySelecionItem(lastTreeLevelOrder, treeItemInfo, false)}
        </React.Fragment>
      );
    });
  };

  const getOverflowByLength = (itensLength) => {
    const itemHeight = 65;
    const maxHeight = 325;

    return itemHeight * itensLength >= maxHeight ? "auto" : "hidden";
  };

  const lastDisplayTree = displayTree[lastTreeLevelOrder];
  const filteredList = getFilteredDisplayTree();
  const totalFiltered = calcTotalDisplayed(filteredList);

  return (
    <FormDialog
      open={open}
      title={title}
      description={description}
      toggleOpen={onCancel}
      onConfirm={() => onConfirm(selectedTraceabilities)}
      onCancel={onCancel}
      confirmText={modalTextConfirm}
      cancelText={modalTextCancel}
      blockConfirm={selectedTraceabilities.length === 0}
      bodyStyle={{
        padding: "0px",
      }}
      dialogClassName="select-tree-dialog"
    >
      <div className="row">
        <div
          className="col-xs-12"
          style={{
            margin: "0px",
            borderBottom: "1px solid #ccc",
            padding: "10px 25px",
          }}
        >
          {displayBreadcrumbs()}
        </div>
        <div
          className="col-xs-6"
          style={{
            maxHeight: "335px",
            overflow: getOverflowByLength(totalFiltered),
            borderRight: "2px solid #eee",
            padding: "0px",
          }}
        >
          <List>
            {filteredList.map((treeItemInfo, index) => {
              return (
                <React.Fragment key={index}>
                  {displaySelecionItem(selectedTree.level, treeItemInfo)}
                </React.Fragment>
              );
            })}
          </List>
          {totalFiltered === 0 && (
            <h5 align="center" style={{ color: "#666" }}>
              {intl.formatMessage(messages.noDataToDisplay)}
            </h5>
          )}
        </div>
        {!singleSelect && (
          <div className="col-xs-6" style={{ padding: "0px" }}>
            {lastDisplayTree && (
              <h5
                style={{
                  color: "#666",
                  borderBottom: "1px solid #ccc",
                  padding: "0px 8px",
                  margin: "0px",
                  height: "30px",
                  lineHeight: "30px",
                }}
              >
                {`${selectedTraceabilities.length} ${lastDisplayTree.countText}`}
              </h5>
            )}
            <div
              style={{
                maxHeight: "304px",
                overflow: getOverflowByLength(selectedTraceabilities.length),
              }}
            >
              <List>{displaySelectedItems()}</List>
            </div>
          </div>
        )}
      </div>
    </FormDialog>
  );
};

SelectTraceabilityModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
  treeOrder: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  singleSelect: PropTypes.bool,
  initialSelected: PropTypes.arrayOf(PropTypes.number),
  resetWhenOpen: PropTypes.bool,
};

SelectTraceabilityModal.defaultProps = {
  open: false,
  treeOrder: [],
  singleSelect: false,
  initialSelected: [],
  resetWhenOpen: true,
};

export default injectIntl(SelectTraceabilityModal);
