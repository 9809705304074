import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import ToolView from "./ToolView";
import StructureWatcher from "./StructureWatcher";
import { TOOL } from "../../actions/routes";
import { addTag, deleteTag, getSelectedTool } from "../../actions/toolActions";
import { unselectInsight } from "../../actions/insightActions";

const ToolContainer = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [showInsightModal, setShowInsightModal] = useState(false);
  const history = useHistory();
  const { selectedTool = {}, isCollapsed } = props;

  useEffect(() => {
    const toolId = Number(history.location.pathname.replace("/tool/", ""));
    if (toolId !== selectedTool.id) props.getSelectedTool(toolId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClickMenu() {
    setShowMenu(!showMenu);
  }

  function handleSelectTab(tab) {
    handleClickMenu();
    setSelectedTab(tab);
  }

  function toggleInsightModal(bool) {
    setShowInsightModal(bool);
    if (!bool) props.unselectInsight();
  }

  const route = selectedTool.id ? TOOL + selectedTool.id : TOOL;

  return (
    <>
      <StructureWatcher />
      {selectedTool?.id && (
        <ToolView
          route={route}
          isCollapsed={isCollapsed}
          onSelectTab={handleSelectTab}
          selectedTabTools={selectedTab}
          toolPoints={props.toolPoints}
          onClickMenu={handleClickMenu}
          showMenu={showMenu}
          showInsightModal={showInsightModal}
          toggleInsightModal={toggleInsightModal}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { selectedTool = {}, toolPoints = [] } = state;

  return {
    selectedTool,
    toolPoints,
  };
}

export default connect(mapStateToProps, {
  getSelectedTool,
  addTag,
  deleteTag,
  unselectInsight,
})(ToolContainer);
