import React from "react";
import PropTypes from "prop-types";
import TableSuccessionScale from "./TableSuccessionScale";
import { getComplementaryEvaluationsByType } from "../../../../../../../utils/skillsAssessment/filter";
import { intlSuccessionQuestions } from "../../../../../../../utils/skillsAssessment/translations";

const TableSuccessionEvaluation = ({ toolAdministration }) => {
  const { complementaryEvaluations } = toolAdministration || {};
  const successionEvaluations = getComplementaryEvaluationsByType(
    complementaryEvaluations,
    "SUCCESSION",
  );
  const translated = intlSuccessionQuestions(successionEvaluations);

  return (
    <div className="row">
      <div className="col-xs-12 col-lg-8">
        <table className="simple-table">
          <thead>
            <tr>
              <td width="30" />
              <td>
                <b>SUCESSÃO</b>
              </td>
            </tr>
          </thead>
          <tbody>
            {translated?.map((evaluationInfo, index) => {
              return (
                <tr key={evaluationInfo.id}>
                  <td align="center">{index + 1}</td>
                  <td>{evaluationInfo.displayTitle}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="col-xs-12 col-lg-4">
        <TableSuccessionScale successionEvaluations={translated} />
      </div>
    </div>
  );
};

TableSuccessionEvaluation.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
};

export default TableSuccessionEvaluation;
