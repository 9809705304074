import { Popover } from "react-bootstrap";
import { defineMessages } from "react-intl";

import {
  getFrequencyOptions,
  getMeasurementOptions,
  getMeasurementUnityOptions,
  getRelationshipTypeOptions,
} from "../../constants/goalsAndBonus";
import {
  getObjectInfoById,
  getOnlySublevelArrays,
  orderArrayByObjAttr,
} from "../ArrayUtils";
import { multipleIndicatorsResult } from "../kpis/calc2";
import { getPeriodDivisionTitle } from "../kpis/display";
import { getToFixed, ruleOfThree } from "../MathUtils";
import { monetaryValue } from "../StringUtils";
import { translatedText } from "../translationUtils";
import {
  getBarBonusMeasurement,
  getCountGoalStatus,
  getCountParticipantsGoalStatus,
  multipleIndicatorsWithBonus,
} from "./calc";
import { getFilteredGoalsRelationTree, getGoalsRelationTree } from "./filter";

const messages = defineMessages({
  gb_table_text_goals: { id: "gb_table_text_goals" },
  gb_table_text_participants: { id: "gb_table_text_participants" },
  gb_message_goal_starting: { id: "gb_message_goal_starting" },
  gb_message_goal_started: { id: "gb_message_goal_started" },
  gb_message_goal_ending: { id: "gb_message_goal_ending" },
  gb_message_goal_ended: { id: "gb_message_goal_ended" },
  gb_options_period_desc_quarterly: { id: "gb_options_period_desc_quarterly" },
  gb_options_period_desc_four_months: {
    id: "gb_options_period_desc_four_months",
  },
  gb_options_period_desc_semiannual: {
    id: "gb_options_period_desc_semiannual",
  },
  gb_options_period_desc_yearly: { id: "gb_options_period_desc_yearly" },
  gb_form_involved: { id: "gb_form_involved" },
  global_upTo: { id: "global.upTo" },
});

const translation = (id, values) => translatedText(id, messages, values);

export const getSelectGoalSecondaryText = (goalInfo, hideRelated = false) => {
  const { relatedInsights = [], period = {} } = goalInfo;

  const frequencyOptions = getFrequencyOptions();

  const relatedInsightsMsg = !hideRelated
    ? `${relatedInsights.length} ${translation("gb_form_involved")} |`
    : "";
  const indicatorFrequency = getObjectInfoById(
    goalInfo.frequency,
    frequencyOptions,
    "value"
  ).label;

  const startPeriod = getPeriodDivisionTitle(period.start, period.start, {
    frequency: "MONTHLY",
  });
  const endPeriod = getPeriodDivisionTitle(period.end, period.end, {
    frequency: "MONTHLY",
  });

  const final = `${relatedInsightsMsg} ${indicatorFrequency} - ${startPeriod} ${translation(
    "global_upTo"
  )} ${endPeriod}`;

  return goalInfo ? final : null;
};

export const getParticipantPopover = (participantRelation) => {
  const names = [];
  const { participantGoals = [], memberParticipant = {} } = participantRelation;

  if (Array.isArray(participantGoals)) {
    participantGoals.forEach(({ title }, index) => {
      const append = index + 1 < participantGoals.length ? ", " : "";
      names.push(`${title}${append}`);
    });
  }

  return (
    <Popover style={{ maxWidth: "800px" }} id="participantPopover">
      {memberParticipant.name && (
        <h4 style={{ marginBottom: "0px" }}>{memberParticipant.name}</h4>
      )}
      <h5 style={{ fontWeight: "normal", marginTop: "5px" }}>
        {`${participantGoals.length} ${translation("gb_table_text_goals")}`}
      </h5>
      {names}
    </Popover>
  );
};

export const getTeamPopover = (teamInfo = {}, teamParticipants = []) => {
  const names = [];

  if (Array.isArray(teamParticipants)) {
    teamParticipants.forEach(({ participantInfo }, index) => {
      const { memberParticipant = {} } = participantInfo;
      const append = index + 1 < teamParticipants.length ? ", " : "";
      names.push(`${memberParticipant.name}${append}`);
    });
  }

  return (
    <Popover style={{ maxWidth: "800px" }} id="teamPopover">
      <h4 style={{ marginBottom: "0px" }}>{teamInfo.name}</h4>
      <h5 style={{ fontWeight: "normal", marginTop: "5px" }}>
        {`${teamParticipants.length} ${translation("gb_table_text_goals")}`}
      </h5>
      {names}
    </Popover>
  );
};

export const getGoalPopover = (goalInfo = {}, goalParticipants = []) => {
  const names = [];

  if (Array.isArray(goalParticipants)) {
    goalParticipants.forEach(({ memberParticipant }, index) => {
      const append = index + 1 < goalParticipants.length ? ", " : "";
      names.push(`${memberParticipant.name}${append}`);
    });
  }

  return (
    <Popover style={{ maxWidth: "800px" }} id="goalPopover">
      <h4 style={{ marginBottom: "0px" }}>{goalInfo.title}</h4>
      <h5 style={{ fontWeight: "normal", marginTop: "5px" }}>
        {`${goalParticipants.length} ${translation(
          "gb_table_text_participants"
        )}`}
      </h5>
      {names}
    </Popover>
  );
};

export const getGoalsBonusCardData = (
  allGoalBonusAdministration = [],
  allSceneries = [],
  allThemes = {},
  allQuestions = {},
  allAnswers = {}
) => {
  const goalsWithRelatedInsights = [];
  const onlyGoals = [];
  const toolsGoalsStatus = {
    APPROPRIATED: [],
    EXCEEDED: [],
    GATE: [],
    NOT_HIT: [],
  };
  const toolsParticipantsStatus = {
    APPROPRIATED: [],
    EXCEEDED: [],
    GATE: [],
    NOT_HIT: [],
  };

  const toolGoalTargets = {
    gate: [],
    appropriated: [],
    exceeded: [],
  };

  function getAverageTargets(targetsArray) {
    function getArrayAverage(arry) {
      let sum = 0;
      const vArray = Array.isArray(arry) ? arry : [];

      vArray.forEach((val) => {
        sum += Number(val);
      });

      return sum / vArray.length;
    }

    return {
      gate: getArrayAverage(targetsArray.gate),
      appropriated: getArrayAverage(targetsArray.appropriated),
      exceeded: getArrayAverage(targetsArray.exceeded),
    };
  }

  function pushGoalStatus(slug, addList = []) {
    addList.forEach((itemInfo) => {
      if (toolsGoalsStatus[slug]) toolsGoalsStatus[slug].push(itemInfo);
    });
  }

  function pushParticipantStatus(slug, addList = []) {
    addList.forEach((itemInfo) => {
      if (toolsParticipantsStatus[slug])
        toolsParticipantsStatus[slug].push(itemInfo);
    });
  }

  function pushTargets(slug, addVal = 0) {
    if (toolGoalTargets[slug]) toolGoalTargets[slug].push(addVal);
  }

  allGoalBonusAdministration.forEach((toolGoalBonusAdm) => {
    const goalsRelationTree = getGoalsRelationTree(
      toolGoalBonusAdm.selectedToolId,
      allSceneries,
      allThemes,
      allQuestions,
      allAnswers
    );

    const filteredRelationTree = getFilteredGoalsRelationTree(
      toolGoalBonusAdm,
      goalsRelationTree,
      {},
      "ANY"
    );

    if (toolGoalBonusAdm.goals) {
      toolGoalBonusAdm.goals.forEach((goalInfo) => onlyGoals.push(goalInfo));
      const goalStatus = getCountGoalStatus(
        toolGoalBonusAdm.goals,
        toolGoalBonusAdm.goalTargets
      );

      pushGoalStatus("APPROPRIATED", goalStatus.APPROPRIATED);
      pushGoalStatus("EXCEEDED", goalStatus.EXCEEDED);
      pushGoalStatus("GATE", goalStatus.GATE);
      pushGoalStatus("NOT_HIT", goalStatus.NOT_HIT);
    }

    if (toolGoalBonusAdm.participants && goalsRelationTree) {
      const participantGoalStatus = getCountParticipantsGoalStatus(
        filteredRelationTree,
        toolGoalBonusAdm.goalTargets,
        toolGoalBonusAdm.clearBonusGateMin,
        "bonus"
      );

      pushParticipantStatus("APPROPRIATED", participantGoalStatus.APPROPRIATED);
      pushParticipantStatus("EXCEEDED", participantGoalStatus.EXCEEDED);
      pushParticipantStatus("GATE", participantGoalStatus.GATE);
      pushParticipantStatus("NOT_HIT", participantGoalStatus.NOT_HIT);
    }

    if (toolGoalBonusAdm.goalTargets) {
      pushTargets("gate", toolGoalBonusAdm.goalTargets.gate);
      pushTargets("appropriated", toolGoalBonusAdm.goalTargets.appropriated);
      pushTargets("exceeded", toolGoalBonusAdm.goalTargets.exceeded);
    }
  });

  onlyGoals.forEach((goalInfo) => {
    if (goalInfo.relatedInsights.length > 0)
      goalsWithRelatedInsights.push(goalInfo);
  });

  const toolAverageTargets = getAverageTargets(toolGoalTargets);

  const { realGoalsReachSum } = multipleIndicatorsResult(
    onlyGoals,
    toolAverageTargets
  );

  let finalRealApproximatedAverage =
    realGoalsReachSum / allGoalBonusAdministration.length;
  finalRealApproximatedAverage = !isNaN(finalRealApproximatedAverage)
    ? finalRealApproximatedAverage
    : 0;

  let barMeasurement = getBarBonusMeasurement(
    finalRealApproximatedAverage,
    toolAverageTargets,
    35
  );
  barMeasurement = finalRealApproximatedAverage <= 0 ? 0 : barMeasurement;

  return {
    goalsWithRelatedInsights,
    barMeasurement,
    toolsGoalsStatus,
    toolsParticipantsStatus,
    finalRealApproximatedAverage,
  };
};

export const formatBRL = (value) =>
  monetaryValue(value, "decimal", "pt-br", "BRL", 2, 2);

export const formatPercentage = (value) => {
  return parseFloat(value) * 0.01;
};

export const excelValueFormatter = (value, measurementUnity) => {
  if (measurementUnity === "PERCENTAGE") return value * 0.01;
  return value;
};

export const getDetailedBonusGoalsExportData = ({
  allThemes,
  participants,
  goalBonusAdministration,
  periodFilterActive,
}) => {
  const { goalTargets, clearBonusGateMin } = goalBonusAdministration;
  const exportData = [];

  const headers = [
    {
      header: "Participante",
      key: "participant",
      width: 35,
    },
    { header: "Email", key: "email", width: 35 },
    {
      header: "Área",
      key: "area",
      width: 20,
    },
    {
      header: "Cargo",
      key: "role",
      width: 20,
    },
    {
      header: "Meta",
      key: "goal",
      width: 35,
    },
    {
      header: "Relacionamento",
      key: "relationshipType",
      width: 15,
    },
    {
      header: "Un",
      key: "measurementUnity",
      width: 15,
    },
    {
      header: "Medição",
      key: "measurement",
      width: 15,
    },
    {
      header: "% Peso",
      key: "weight",
      style: { numFmt: "0.00%" },
      width: 15,
    },
    {
      header: "Mínimo",
      key: "gate",
      width: 20,
    },
    {
      header: "Planejado",
      key: "appropriated",
      width: 20,
    },
    {
      header: "Superado",
      key: "exceeded",
      width: 20,
    },
    {
      header: "Atual meta",
      key: "actual",
      width: 20,
    },
    {
      header: "% Score",
      key: "goalReach",
      style: { numFmt: "0.00%" },
      width: 20,
    },
    {
      header: "% Bônus",
      key: "bonusPercentage",
      width: 20,
      style: { numFmt: "0.00%" },
    },
    { header: "Qtd. Salários", key: "salaries", width: 20 },
    {
      header: "Bônus total",
      key: "totalBonus",
      width: 20,
      style: { numFmt: "$ #,##0.00" },
    },
    {
      header: "Bônus limite",
      key: "limitBonus",
      width: 20,
      style: { numFmt: "$ #,##0.00" },
    },
  ];

  const onlyThemes = getOnlySublevelArrays(allThemes);

  participants.forEach(({ participantInfo, bonusInfo, participantGoals }) => {
    const {
      totalBonus,
      limitBonus,
      reachBonusWeightSum: totalWeightReach,
    } = bonusInfo;
    const { memberParticipant = {}, roleBonus } = participantInfo;

    const ordered = orderArrayByObjAttr(participantGoals, "title");

    ordered.forEach((goalInfo) => {
      const { reachBonusWeightSum, realGoalsReachSum, evaluatedGoals } =
        multipleIndicatorsWithBonus(
          [goalInfo],
          goalTargets,
          clearBonusGateMin,
          periodFilterActive
        );
      const evaluatedGoal =
        evaluatedGoals && evaluatedGoals[0] ? evaluatedGoals[0] : {};
      const { goalPeriodTotalEvaluation, courseTargetsDivision } =
        evaluatedGoal;

      const goalBonus = ruleOfThree(
        totalWeightReach,
        reachBonusWeightSum,
        totalBonus
      );

      const goalLimitBonus = ruleOfThree(
        totalWeightReach,
        reachBonusWeightSum,
        limitBonus
      );

      const themeInfo = getObjectInfoById(
        roleBonus.id,
        onlyThemes,
        "goalsBonusProgramRoleBonusId"
      );

      const { cenary = {} } = themeInfo;

      exportData.push({
        participant: memberParticipant.name,
        email: memberParticipant.email,
        area: cenary.name,
        role: roleBonus.role,
        goal: goalInfo.title,
        relationshipType: getObjectInfoById(
          goalInfo.relationshipType,
          getRelationshipTypeOptions(),
          "value"
        ).label,
        measurementUnity: getObjectInfoById(
          goalInfo.measurementUnity,
          getMeasurementUnityOptions(),
          "value"
        ).label,
        measurement: getObjectInfoById(
          goalInfo.measurement,
          getMeasurementOptions(),
          "value"
        ).label,
        weight: formatPercentage(goalInfo.weight),
        gate: courseTargetsDivision?.gate
          ? excelValueFormatter(
              courseTargetsDivision?.gate,
              goalInfo.measurementUnity
            )
          : excelValueFormatter(
              goalInfo.target?.gate,
              goalInfo.measurementUnity
            ),
        appropriated: courseTargetsDivision?.appropriated
          ? excelValueFormatter(
              courseTargetsDivision?.appropriated,
              goalInfo.measurementUnity
            )
          : excelValueFormatter(
              goalInfo.target?.appropriated,
              goalInfo.measurementUnity
            ),
        exceeded: courseTargetsDivision?.exceeded
          ? excelValueFormatter(
              courseTargetsDivision?.exceeded,
              goalInfo.measurementUnity
            )
          : excelValueFormatter(
              goalInfo.target?.exceeded,
              goalInfo.measurementUnity
            ),
        actual: excelValueFormatter(
          goalPeriodTotalEvaluation,
          goalInfo.measurementUnity
        ),
        goalReach: formatPercentage(realGoalsReachSum),
        salaries: parseFloat(
          getToFixed(
            ruleOfThree(
              totalWeightReach,
              reachBonusWeightSum,
              bonusInfo.aditionalSalaries
            ),
            2
          )
        ),
        bonusPercentage: formatPercentage(reachBonusWeightSum),
        totalBonus: parseFloat(goalBonus),
        limitBonus: parseFloat(goalLimitBonus),
      });
    });
  });

  return { headers, data: exportData };
};

export const getBonusGoalsExportData = ({ allThemes, participants }) => {
  const exportData = [];

  const headers = [
    {
      header: "Participante",
      key: "participant",
      width: 35,
    },
    { header: "Email", key: "email", width: 35 },
    {
      header: "Área",
      key: "area",
      width: 20,
    },
    {
      header: "Cargo",
      key: "role",
      width: 20,
    },
    {
      header: "% Bônus",
      key: "bonusPercentage",
      width: 15,
      style: { numFmt: "0.00%" },
    },
    { header: "Qtd. Salários", key: "salaries", width: 20 },
    {
      header: "Bônus total",
      key: "totalBonus",
      width: 15,
      style: { numFmt: "#,##0.00;[Red]-#,##0.00" },
    },
    {
      header: "Bônus limite",
      key: "limitBonus",
      width: 15,
      style: { numFmt: "$ #,##0.00" },
    },
  ];

  participants.forEach(({ participantInfo, bonusInfo }) => {
    const { reachBonusWeightSum, totalBonus, limitBonus } = bonusInfo;

    const { memberParticipant = {}, roleBonus } = participantInfo;
    const onlyThemes = getOnlySublevelArrays(allThemes);

    const themeInfo = getObjectInfoById(
      roleBonus.id,
      onlyThemes,
      "goalsBonusProgramRoleBonusId"
    );

    const { cenary = {} } = themeInfo;

    exportData.push({
      participant: memberParticipant.name,
      email: memberParticipant.email,
      area: cenary.name,
      role: roleBonus.role,
      salaries: bonusInfo.aditionalSalaries,
      bonusPercentage: reachBonusWeightSum / 100,
      totalBonus: parseFloat(totalBonus),
      limitBonus: parseFloat(limitBonus),
    });
  });

  return { headers, data: exportData };
};
