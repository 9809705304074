import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import _ from "lodash";
import FormDialog from "../../FormDialog";
import MaterialTextField from "../../MaterialTextField";
import kpiUtils from "../../../../utils/kpis";

const { getProcessInfoById } = kpiUtils;

const initialForm = {
  id: null,
  title: "",
  description: "",
};

const messages = defineMessages({
  kpi_dialog_title_new_subtheme: { id: "kpi_dialog_title_new_subtheme" },
  kpi_dialog_title_edit_subtheme: { id: "kpi_dialog_title_edit_subtheme" },
  kpi_form_title: { id: "kpi_form_title" },
  kpi_form_description: { id: "kpi_form_description" },
});

const NewProcessDialog = (props) => {
  const [form, setForm] = useState(initialForm);

  const {
    open = false,
    afterCancel,
    afterConfirm,
    kpiAdministration,
    editingForm = {},
    getKpiConfig,
    intl,
  } = props;

  const isEditing = editingForm.type === "newProcess";

  useEffect(() => {
    if (editingForm && isEditing && kpiAdministration) {
      const editingProcessIndex = getEditingIndex(kpiAdministration.processes);
      const processInfo = kpiAdministration.processes[editingProcessIndex];

      if (processInfo) {
        setForm({ ...processInfo });
      }
    }
  }, [editingForm, kpiAdministration, isEditing]);

  const updateFormField = (field, newVal) => {
    setForm({ ...form, [field]: newVal });
  };

  const resetForm = () => {
    setForm(initialForm);
  };

  const getEditingIndex = (list) => {
    return getProcessInfoById(editingForm.params.processId, list, true);
  };

  const saveAdministration = (newAdministration) => {
    if (typeof afterConfirm === "function") afterConfirm(newAdministration);
  };

  const saveNewProcess = async () => {
    const data = await getKpiConfig();

    if (data && data.id)
      saveAdministration({
        ...data,
        processes: [...data.processes, { ...form }],
      });

    resetForm();
  };

  const editSaveProcess = async () => {
    const data = await getKpiConfig();

    if (data && data.id) {
      const editingProcessIndex = getEditingIndex(data.processes);

      const updatedProcesses = [...data.processes];
      updatedProcesses[editingProcessIndex] = form;

      saveAdministration({
        ...data,
        processes: updatedProcesses,
      });
    }

    resetForm();
  };

  const handleConfirm = () => {
    if (!isEditing) saveNewProcess();
    if (isEditing) editSaveProcess();
  };

  const handleCancel = () => {
    resetForm();

    if (typeof afterCancel === "function") afterCancel();
  };

  const isValidForm = () => {
    if (form.title < 2) return false;

    return true;
  };

  const formTitle = !isEditing
    ? intl.formatMessage(messages.kpi_dialog_title_new_subtheme)
    : intl.formatMessage(messages.kpi_dialog_title_edit_subtheme);

  return (
    <FormDialog
      open={open}
      title={formTitle}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      blockConfirm={!isValidForm()}
    >
      <div className="row">
        <div className="col-xs-12">
          <MaterialTextField
            id="title"
            label={intl.formatMessage(messages.kpi_form_title)}
            value={form.title}
            onChange={(e) => updateFormField("title", e.target.value)}
          />
        </div>
        <div className="col-xs-12">
          <MaterialTextField
            id="description"
            label={intl.formatMessage(messages.kpi_form_description)}
            value={form.description}
            onChange={(e) => updateFormField("description", e.target.value)}
            multiline
          />
        </div>
      </div>
    </FormDialog>
  );
};

export default injectIntl(NewProcessDialog);
