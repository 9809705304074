import { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getKpiStates } from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";
import kpiUtils from "../../../../utils/kpis";
import { updateQuestion } from "../../../../actions/questionsActions";

const {
  getObjectDifference,
  getObjectInfoById,
  getOnlySlugValues,
  removeObjSlug,
} = utils;

const { buildFormAdmConfigBody, getKpiConfigBySelectedToolId } = kpiUtils;

const defaultForm = buildFormAdmConfigBody({});

const KpiWatcher = (props) => {
  const [kpiAdministration, setKpiAdministration] = useState(defaultForm);
  const [firstValidation, setFirstValidation] = useState(false);

  const {
    allKpiAdministration = [],
    selectedTool = {},
    allQuestions = {},
    updateQuestion,
  } = props;

  useEffect(() => {
    let toolAdministration = getKpiConfigBySelectedToolId(
      selectedTool.id,
      allKpiAdministration,
    );
    toolAdministration = buildFormAdmConfigBody(toolAdministration);

    const isAdmObjDiff = !_.isEqual(toolAdministration, kpiAdministration);

    if (
      toolAdministration &&
      toolAdministration.processes &&
      Object.keys(allQuestions).length > 0
    ) {
      if (isAdmObjDiff || !firstValidation) {
        const compareBase = removeObjSlug(kpiAdministration.processes, "id");

        const diff = getObjectDifference(
          toolAdministration.processes,
          compareBase,
        );

        if (diff) {
          if (!firstValidation) setFirstValidation(true);

          const watchProcess = getOnlySlugValues(diff, "id");

          if (watchProcess.length > 0) {
            Object.keys(allQuestions).forEach((themeId) => {
              const themeQuestions = allQuestions[themeId];

              themeQuestions.forEach((questionInfo) => {
                const { kpiConfigurationProcessId } = questionInfo;
                const relatedProcessId =
                  watchProcess[
                    watchProcess.indexOf(kpiConfigurationProcessId)
                  ] || null;

                if (relatedProcessId && questionInfo.text) {
                  const processInfo = getObjectInfoById(
                    relatedProcessId,
                    toolAdministration.processes,
                    "id",
                  );

                  if (processInfo.title !== questionInfo.text) {
                    updateQuestion(
                      { ...questionInfo, text: processInfo.title },
                      questionInfo.theme.id,
                    );
                  }
                }
              });
            });
          }
        }

        if (isAdmObjDiff) setKpiAdministration(toolAdministration);
      }
    }
  }, [
    allKpiAdministration,
    allQuestions,
    firstValidation,
    kpiAdministration,
    selectedTool.id,
    updateQuestion,
  ]);

  return null;
};

const mapStateToProps = (state) => {
  const { allKpiAdministration } = getKpiStates(state);

  return { allKpiAdministration };
};

export default connect(mapStateToProps, { updateQuestion })(KpiWatcher);
