import { defineMessages } from "react-intl";
import { translatedText } from "../utils/translationUtils";

const messages = defineMessages({
  acp_options_not_started: {
    id: "acp_options_not_started",
  },
  acp_options_in_progress: {
    id: "acp_options_in_progress",
  },
  acp_options_complete: {
    id: "acp_options_complete",
  },
  acp_options_canceled: {
    id: "acp_options_canceled",
  },
  acp_options_short_out: {
    id: "acp_options_short_out",
  },
  acp_options_long_out: {
    id: "acp_options_long_out",
  },
  acp_options_short_within: {
    id: "acp_options_short_within",
  },
  acp_options_long_within: {
    id: "acp_options_long_within",
  },
});

const translation = (id) => translatedText(id, messages);

export const DEFAULT_ACTION_STATUS = "IN_PROGRESS";

export const getActionStatusOptions = () => {
  return [
    { value: "NOT_STARTED", label: translation("acp_options_not_started") },
    {
      value: DEFAULT_ACTION_STATUS,
      label: translation("acp_options_in_progress"),
    },
    { value: "COMPLETE", label: translation("acp_options_complete") },
    { value: "CANCELED", label: translation("acp_options_canceled") },
  ];
};

export const getEvaluationStatusOptions = (completeLabel = false) => {
  return [
    {
      value: "OUT",
      color: "#ff6347",
      label: !completeLabel
        ? translation("acp_options_short_out").toUpperCase()
        : translation("acp_options_long_out"),
    },
    {
      value: "WITHIN_DEADLINE",
      color: "#44cf76",
      label: !completeLabel
        ? translation("acp_options_short_within").toUpperCase()
        : translation("acp_options_long_within"),
    },
    { value: "NOT_STARTED", color: "#eee", label: "---" },
    { value: "CANCELED", color: "#111", label: "---" },
  ];
};
