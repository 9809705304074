import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  const isNumericString = props.isNumericString === false ? false : true;
  const allowLeadingZeros = props.allowLeadingZeros === false ? false : true;

  const thousandSeparator = !props.thousandSeparator
    ? "."
    : props.thousandSeparator;
  const decimalSeparator = !props.decimalSeparator
    ? ","
    : props.decimalSeparator;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      isNumericString={isNumericString}
      allowLeadingZeros={allowLeadingZeros}
      allowedDecimalSeparators={[","]}
      prefix={props.prefix}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default NumberFormatCustom;
