import { getState } from "./store";
import {
  getSelectedToolStates,
  getWorkspacesStates,
} from "../../../components/customMapStates";
import { getObjectInfoById } from "../../../utils/ArrayUtils";

export const checkToolIdInState = (checkSelectedTool = {}) => {
  if (checkSelectedTool && checkSelectedTool.id) {
    const state = getState();
    const { selectedTool = {} } = getSelectedToolStates(state);
    /* ADICIONAR CUSTOMIZAÇÃO ADICIONAL */
    /* Se o usuário estiver com o Overview Inciail aberto */
    /* Checar o state.companyData.allCompanyToolsType */

    return selectedTool && selectedTool.id === checkSelectedTool.id;
  }

  return false;
};

export const checkWorkspaceToolIdInState = (checkSelectedTool) => {
  if (checkSelectedTool.id) {
    const state = getState();
    const { workspaceTools = [] } = getWorkspacesStates(state);

    const toolInfo = getObjectInfoById(
      checkSelectedTool.id,
      workspaceTools,
      "id",
    );

    return toolInfo && toolInfo.id ? true : false;
  }

  return false;
};

export const checkWorkspaceInCompany = (checkWorkspaceId) => {
  if (checkWorkspaceId) {
    const state = getState();
    const { minifiedCompanyWorkspaces = [] } = getWorkspacesStates(state);

    const workspaceInfo = getObjectInfoById(
      checkWorkspaceId,
      minifiedCompanyWorkspaces,
      "id",
    );

    return workspaceInfo && workspaceInfo.id ? true : false;
  }

  return false;
};

export const checkSameUser = (userId) => {
  const { user } = getState();

  if (!userId) return false;
  return user?.id === Number(userId);
};

export const getFromDetails = (
  details = {},
  findSlug = "selectedToolId",
  isNumber,
) => {
  if (details[findSlug])
    return isNumber ? Number(details[findSlug]) : details[findSlug];

  return null;
};
