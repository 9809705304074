import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { apiNode } from "api";
import { IToolProcessConfigurations } from "interfaces/IToolProcessConfigurations";

export const PATH = "/api/v4/tool-process-configs";

export interface ToolProcessState {
  configurations?: IToolProcessConfigurations;
  loading: boolean;
}

export const fetchToolProcessConfigurations = createAsyncThunk(
  "toolProcess/fetchConfigurations",
  async (selectedToolId: number) => {
    const response = await apiNode.get(
      `${PATH}/selected-tool/${selectedToolId}`
    );
    return response.data as IToolProcessConfigurations;
  }
);

export const updateToolProcessConfigurations = createAsyncThunk(
  "toolProcess/updateConfigurations",
  async ({ id, data }: { id: number; data: IToolProcessConfigurations }) => {
    const response = await apiNode.put(`${PATH}/${id}`, data);
    return response.data as IToolProcessConfigurations;
  }
);

export const createToolProcessConfigurations = createAsyncThunk(
  "toolProcess/createConfigurations",
  async ({
    data,
    selectedToolId,
  }: {
    data: IToolProcessConfigurations;
    selectedToolId: number;
  }) => {
    const response = await apiNode.post(
      `${PATH}/selected-tool/${selectedToolId}`,
      data
    );
    return response.data as IToolProcessConfigurations;
  }
);

const toolProcessConfigurationsAdapter = createEntityAdapter();

const initialState: ToolProcessState =
  toolProcessConfigurationsAdapter.getInitialState({
    loading: false,
  });

const toolProcessSlice = createSlice({
  name: "toolProcess",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchToolProcessConfigurations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchToolProcessConfigurations.fulfilled, (state, action) => {
        state.configurations = action.payload;
        state.loading = false;
      })
      .addCase(fetchToolProcessConfigurations.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateToolProcessConfigurations.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateToolProcessConfigurations.fulfilled, (state, action) => {
        state.configurations = action.payload;
        state.loading = false;
      })
      .addCase(updateToolProcessConfigurations.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createToolProcessConfigurations.pending, (state) => {
        state.loading = true;
      })
      .addCase(createToolProcessConfigurations.fulfilled, (state, action) => {
        state.configurations = action.payload;
        state.loading = false;
      })
      .addCase(createToolProcessConfigurations.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default toolProcessSlice.reducer;
