import React from "react";
import styled from "styled-components";

import utils from "../../../../../../utils/toolUtils";

const { getShortReadinessValues, getLabelBySlug } = utils;

const getPercentage = (val, total) => {
  const validVal = val > 0 ? val : 0;
  const calc = ((validVal * 100) / total).toFixed(1);

  return !isNaN(calc) ? calc : 0;
};

const CardValue = styled.div`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : ""} !important;

  @media print {
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : ""} !important;
  }
`;

const ReadinessField = (props) => {
  const { filteredEvaluations, readinessOptions, messages, intl } = props;

  const displayReadinessTable = () => {
    let evaluations = [];

    filteredEvaluations.forEach(({ peopleEvaluation }) => {
      const { evaluationTarget } = peopleEvaluation;
      const { carrearPath } = evaluationTarget;
      const { mainTrail } = carrearPath;

      if (mainTrail) evaluations = [...evaluations, mainTrail.readiness];
    });

    const { READY, DEVELOP, KEEP, REASSESS } = getShortReadinessValues(
      evaluations,
    );

    return (
      <div className="simple-card-table">
        <div className="col-xs-6">
          <h5 align="center" className="card-title">
            {getLabelBySlug("READY", readinessOptions)}
          </h5>
          <CardValue className="card-value" backgroundColor="#0b8b34">
            {READY}
            <div className="label label-bottom">
              {getPercentage(READY, evaluations.length)}%
            </div>
          </CardValue>
        </div>
        <div className="col-xs-6">
          <h5 align="center" className="card-title">
            {getLabelBySlug("DEVELOP", readinessOptions)}
          </h5>
          <CardValue className="card-value" backgroundColor="#3ab361">
            {DEVELOP}
            <div className="label label-bottom">
              {getPercentage(DEVELOP, evaluations.length)}%
            </div>
          </CardValue>
        </div>
        <div className="col-xs-6">
          <h5 align="center" className="card-title">
            {getLabelBySlug("KEEP", readinessOptions)}
          </h5>
          <CardValue className="card-value" backgroundColor="#f0f579">
            {KEEP}
            <div className="label label-bottom">
              {getPercentage(KEEP, evaluations.length)}%
            </div>
          </CardValue>
        </div>
        <div className="col-xs-6">
          <h5 align="center" className="card-title">
            {getLabelBySlug("REASSESS", readinessOptions)}
          </h5>
          <CardValue className="card-value" backgroundColor="#ffc736">
            {REASSESS}
            <div className="label label-bottom">
              {getPercentage(REASSESS, evaluations.length)}%
            </div>
          </CardValue>
        </div>
      </div>
    );
  };

  return (
    <div className="evaluation-card">
      <div className="card-header">
        <div className="card-title">
          <h4>{intl.formatMessage(messages.titles_cards_readiness)}</h4>
        </div>
      </div>
      <div className="card-body" style={{ height: "315px" }}>
        <div className="row">{displayReadinessTable()}</div>
      </div>
    </div>
  );
};

export default ReadinessField;
