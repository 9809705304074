import React, { useState, useEffect } from "react";
import FormDialog from "../../FormDialog";
import MaterialSingleSelect from "../../MaterialSingleSelect";

const initialForm = {
  projectTypeId: "",
};

const QuestionProjectTypeSelectDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const { open = false, afterCancel, afterConfirm, onlyProjectTypes } = props;

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleConfirm = () => {
    if (typeof afterConfirm === "function") afterConfirm(form);
  };

  const handleCancel = () => {
    if (typeof afterCancel === "function") afterCancel();
  };

  const handleUpdateForm = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const getFormErrors = () => {
    let errors = [];

    if (
      (!form.projectTypeId || form.projectTypeId === "empty") &&
      form.projectTypeId !== 0
    ) {
      errors.push({
        slug: "typeNotSelected",
        message: "Selecione um tipo para continuar",
      });
    }

    return errors;
  };

  const buildProjectTypeOptions = (groups) => {
    let finalOptions = [];

    if (groups && groups.length > 0) {
      groups.forEach(({ id, title }) =>
        finalOptions.push({ value: id, label: title }),
      );
    }

    return finalOptions;
  };

  const projectTypeOptions = buildProjectTypeOptions(onlyProjectTypes);
  const formErrors = getFormErrors();

  return (
    <>
      <FormDialog
        open={open}
        title="Adicionar tipo de projeto"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        blockConfirm={formErrors.length > 0}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialSingleSelect
              id="projectTypeSelect"
              label="Selecione um tipo de projeto"
              value={form.projectTypeId}
              options={projectTypeOptions}
              onChange={(e) =>
                handleUpdateForm("projectTypeId", e.target.value)
              }
            />
          </div>
        </div>
      </FormDialog>
    </>
  );
};

export default QuestionProjectTypeSelectDialog;
