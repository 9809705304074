import React, { useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";

import { GridContainer } from "../css/OkrCss";

import { getSelectedToolStates } from "../../../../../customMapStates";
import MaterialTextField from "../../../../../Common/MaterialTextField";
import { getObjectInfoById } from "../../../../../../utils/ArrayUtils";
import utils from "../../../../../../utils/toolUtils";
import {
  postObjective,
  deleteObjective,
  putObjective,
  deleteKey,
} from "../../../../../../actions/okrActions";

import DisplayObjKeyForm from "./DisplayObjKeyForm";
import ObjectiveRegister from "./ObjectiveRegister";
import KeysRegister from "./KeysRegister";

const { getOnlySublevelArrays, getComparableQuery } = utils;

const ContainerObjKey = (props) => {
  const [filter, setFilter] = useState("");

  const {
    okr,
    postObjective,
    deleteObjective,
    putObjective,
    allQuestions,
    intl,
    allAnswers,
    deleteKey,
  } = props;
  const { id: idConfig, objectives } = okr;

  async function createOrUpdateObjective(newObjective) {
    if (newObjective.id) {
      putObjective(newObjective);
    } else {
      await postObjective(idConfig, newObjective);
    }
  }

  const getDialogDeleteObjective = (objectiveId) => {
    const onlyThemes = getOnlySublevelArrays(allQuestions);

    const relatedTheme = getObjectInfoById(
      objectiveId,
      onlyThemes,
      "okrConfigurationObjectiveId",
    );

    const setDeleteObj = {
      getOpen() {
        return true;
      },

      getTitle() {
        return intl.formatMessage({ id: "okr_confirm_delete_question" });
      },

      getDescription() {
        return relatedTheme.id
          ? intl.formatMessage(
              { id: "okr_warning_need_arquive_question" },
              { objectiveName: relatedTheme.text },
            )
          : "";
      },

      handleDelete(id) {
        return !relatedTheme.id ? deleteObjective(id) : null;
      },
    };

    return setDeleteObj;
  };

  function getAllOptionsObjective() {
    const optionsObjective = [];

    objectives.forEach(({ id, title }) => {
      optionsObjective.push({ value: id, label: title });
    });

    return optionsObjective;
  }

  const getDialogDeleteKey = (keyId, objectiveId) => {
    const onlyInsights = getOnlySublevelArrays(allAnswers);

    const relatedInsight = getObjectInfoById(
      keyId,
      onlyInsights,
      "okrConfigurationKeyId",
    );

    const setDeleteKey = {
      getOpen() {
        return true;
      },

      getTitle() {
        return intl.formatMessage({ id: "okr_confirm_delete_key" });
      },

      getDescription() {
        return relatedInsight.id
          ? intl.formatMessage(
              { id: "okr_warning_need_arquive_key" },
              { keyName: relatedInsight.name },
            )
          : "";
      },

      handleDelete(id) {
        return relatedInsight.id ? () => null : deleteKey(id, objectiveId);
      },
    };

    return setDeleteKey;
  };

  function filterObjectivesByString() {
    const compareString = getComparableQuery(filter);
    const keys = [];
    let filteredObjectives = [];

    objectives.forEach((objective) => {
      if (objective.keys) {
        objective.keys.forEach((key) => {
          if (key && getComparableQuery(key.title).match(compareString)) {
            keys.push({ objectiveId: objective.id, key });
          }
        });
      }
    });

    filteredObjectives = objectives.filter((objective) => {
      return getComparableQuery(objective.title).match(compareString);
    });

    return { keys, filteredObjectives };
  }

  function handleChangeFilter(e) {
    setFilter(e.target.value);
  }

  const newProps = {
    ...props,
    createOrUpdateObjective,
    objectives: filterObjectivesByString().filteredObjectives,
    filteredkeyResults: filterObjectivesByString().keys,
    getAllOptionsObjective,
  };

  return (
    <>
      <DisplayObjKeyForm {...newProps} />
      <Grid item md={4} style={{ marginLeft: "15px" }}>
        <MaterialTextField
          id="filter"
          label={intl.formatMessage({ id: "search_okr" })}
          name="filter"
          margin="normal"
          value={filter}
          onChange={handleChangeFilter}
        />
      </Grid>
      <GridContainer container spacing={4}>
        <Grid item md={12} lg={6}>
          <ObjectiveRegister
            getDialogDeleteObjective={getDialogDeleteObjective}
            {...newProps}
          />
        </Grid>
        <Grid item md={12} lg={6}>
          <KeysRegister getDialogDeleteKey={getDialogDeleteKey} {...newProps} />
        </Grid>
      </GridContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  const { allQuestions, allAnswers } = getSelectedToolStates(state);

  return {
    allQuestions,
    allAnswers,
    okr: state.okr,
  };
};

const mapDispatchToProps = {
  postObjective,
  deleteObjective,
  putObjective,
  deleteKey,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ContainerObjKey));

ContainerObjKey.propTypes = {
  okr: PropTypes.object.isRequired,
  postObjective: PropTypes.func.isRequired,
  deleteObjective: PropTypes.func.isRequired,
  putObjective: PropTypes.func.isRequired,
  deleteKey: PropTypes.func.isRequired,
  allQuestions: PropTypes.object.isRequired,
  allAnswers: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};
