import {
  postScenery,
  postTheme,
  postQuestion,
  postInsight,
} from "../board/_actions";

import { toolUpdateNotifier } from "./_validations";
import { checkToolIdInState } from "../../../utils/entity_validations";
import {
  UPDATE_COMPANY_DETAILS,
  POST_COMPETITIVE_ANALYSIS_EVALUATION,
  UPDATE_COMPETITIVE_ANALYSIS_EVALUATION,
  UPDATE_ALL_CP_ANALYSIS_CONFIGURATION,
} from "../../../../types";

export const postToolEvaluation = (info = {}) => {
  const { content = {}, details = {} } = info;
  const { selectedToolId = 0 } = details;

  return async (dispatch) => {
    if (checkToolIdInState({ id: selectedToolId }))
      dispatch(toolUpdateNotifier());

    dispatch({
      type: POST_COMPETITIVE_ANALYSIS_EVALUATION,
      payload: { ...content, selectedToolId: Number(selectedToolId) },
    });
  };
};

export const putToolEvaluation = (info = {}) => {
  const { content = {}, details = {} } = info;
  const { selectedToolId = 0, insightId = 0 } = details;

  return async (dispatch) => {
    if (checkToolIdInState({ id: selectedToolId }))
      dispatch(toolUpdateNotifier());

    dispatch({
      type: UPDATE_COMPETITIVE_ANALYSIS_EVALUATION,
      payload: {
        type: content.type,
        evaluations: [{ ...content }],
        selectedToolId,
        insightId,
      },
    });
  };
};

export const postCompanyDetails = (info = {}) => {
  const { content = {}, details = {} } = info;
  const { selectedToolId = 0 } = details;

  return async (dispatch) => {
    if (checkToolIdInState({ id: selectedToolId }))
      dispatch(toolUpdateNotifier());

    dispatch({
      type: POST_COMPETITIVE_ANALYSIS_EVALUATION,
      payload: { ...content },
    });
  };
};

export const putCompanyDetails = (info = {}) => {
  const { content = {}, details = {} } = info;
  const { insightId = {}, selectedToolId = 0 } = details;

  return async (dispatch) => {
    if (checkToolIdInState({ id: selectedToolId }))
      dispatch(toolUpdateNotifier());

    dispatch({
      type: UPDATE_COMPANY_DETAILS,
      payload: {
        companyDetailsInfo: { ...content },
        insightId: Number(insightId),
      },
    });
  };
};

export const postBoardCenaryLink = (info = {}) => {
  const { content = {}, details = {} } = info;
  const { competitiveAnalysisId = {}, selectedToolId = 0 } = details;

  let updatedScenerie = {
    ...content,
    competitiveAnalysisId: Number(competitiveAnalysisId),
  };

  return async (dispatch) => {
    if (checkToolIdInState({ id: selectedToolId }))
      dispatch(toolUpdateNotifier());

    dispatch(postScenery(updatedScenerie, details.id));
  };
};

export const postBoardThemeLink = (info = {}) => {
  const { content = {}, details = {} } = info;
  const { competitiveAnalysisAmbientId = {}, selectedToolId = 0 } = details;

  let updatedTheme = {
    ...content,
    competitiveAnalysisAmbientId: Number(competitiveAnalysisAmbientId),
  };

  return async (dispatch) => {
    if (checkToolIdInState({ id: selectedToolId }))
      dispatch(toolUpdateNotifier());

    dispatch(postTheme(updatedTheme, details.userId));
  };
};

export const postBoardQuestionLink = (info = {}) => {
  const { content = {}, details = {} } = info;
  const { competitiveAnalysisAreaId = {}, selectedToolId = 0 } = details;

  let updatedQuestion = {
    ...content,
    competitiveAnalysisAreaId: Number(competitiveAnalysisAreaId),
  };

  return async (dispatch) => {
    if (checkToolIdInState({ id: selectedToolId }))
      dispatch(toolUpdateNotifier());

    dispatch(postQuestion(updatedQuestion, details.userId));
  };
};

export const postToolConfiguration = (info = {}) => {
  const { content = [] } = info;
  const { selectedToolId = 0 } = content;

  return async (dispatch) => {
    if (checkToolIdInState({ id: selectedToolId }))
      dispatch(toolUpdateNotifier());

    dispatch({
      type: UPDATE_ALL_CP_ANALYSIS_CONFIGURATION,
      payload: [{ ...content }],
    });
  };
};

export const putToolConfiguration = (info = {}) => {
  const { content = [] } = info;
  const { selectedToolId = 0 } = content;

  return async (dispatch) => {
    if (checkToolIdInState({ id: selectedToolId }))
      dispatch(toolUpdateNotifier());

    dispatch({
      type: UPDATE_ALL_CP_ANALYSIS_CONFIGURATION,
      payload: [{ ...content }],
    });
  };
};

export const createFactorsQuestions = (info = {}) => {
  const { content = [], details = {} } = info;
  const { selectedToolId = 0 } = details;

  return async (dispatch) => {
    if (checkToolIdInState({ id: selectedToolId }))
      dispatch(toolUpdateNotifier());

    content.forEach((question) => {
      dispatch(postQuestion(question, details.userId));
    });
  };
};

export const postInsightGroupLink = (info = {}) => {
  const { content = [], details = {} } = info;
  const { selectedToolId = 0, competitiveAnalysisGroupId = 0 } = details;

  const updatedBody = {
    ...content,
    competitiveAnalysisGroupId: Number(competitiveAnalysisGroupId),
  };

  return async (dispatch) => {
    if (checkToolIdInState({ id: selectedToolId }))
      dispatch(toolUpdateNotifier());

    dispatch(postInsight(updatedBody, details.userId, false));
  };
};
