export const INSIGHT_RESPONSIBLE_CHANGE = "INSIGHT_RESPONSIBLE_CHANGE";
export const NEW_INSIGHT_COMMENT = "NEW_INSIGHT_COMMENT";
export const INSIGHT_PRIORITIZATION_CHANGE = "INSIGHT_PRIORITIZATION_CHANGE";
export const INSIGHT_CONNECTION_CHANGE = "INSIGHT_CONNECTION_CHANGE";
export const INSIGHT_ATTACHMENT_CHANGE = "INSIGHT_ATTACHMENT_CHANGE";
export const COMPANY_NEW_ADMIN = "COMPANY_NEW_ADMIN";
export const COMPANY_NEW_WORKSPACE = "COMPANY_NEW_WORKSPACE";
export const WORKSPACE_NEW_MODERATOR = "WORKSPACE_NEW_MODERATOR";
export const WORKSPACE_NEW_TOOL = "WORKSPACE_NEW_TOOL";
export const TOOL_NEW_MANAGER = "TOOL_NEW_MANAGER";
export const TOOL_NEW_VIEWER = "TOOL_NEW_VIEWER";
export const SCENERY_NEW_LEADER = "SCENERY_NEW_LEADER";
export const SCENERY_NEW_VIEWER = "SCENERY_NEW_VIEWER";
export const INSIGHT_NEW_MENTION = "INSIGHT_NEW_MENTION";
