import { makeStyles } from "@material-ui/core/styles";

export const useMainStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#fff",
  },
  appBar: {
    color: "#333",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  addButton: {
    fontSize: "12px",
    textTransform: "none",
  },
}));
