import { getSalariesConfig } from "./config";
import {
  ruleOfThree,
  getToFixed,
  simpleSum,
  simpleSubtraction,
} from "../MathUtils";
import {
  multipleIndicatorsResult,
  getPeriodTargetsDivision,
  getCustomPeriodsDistribution,
  getNormalizedMonthIndex,
  filterGoalByPeriod,
  getTargetsByIndex,
} from "../kpis/calc2";
import { getHitTargetInfo } from "../kpis/display";
import { getIndicatorValidPeriods } from "../kpis/calc2";

export const getDefaultCounts = () => {
  return { NOT_HIT: [], GATE: [], APPROPRIATED: [], EXCEEDED: [] };
};

export const getParticipantsLimitBonus = (
  configLimitBonus,
  participantsTotalBonus,
  participantsRelation,
) => {
  const finalRelation = [];
  let totalLimitBonus = 0;

  participantsRelation.forEach((relationInfo) => {
    const { bonusInfo } = relationInfo;

    const participantBonusPercentage = ruleOfThree(
      participantsTotalBonus,
      bonusInfo.totalBonus,
    );

    const limitBonus = configLimitBonus * (participantBonusPercentage / 100);

    finalRelation.push({
      ...relationInfo,
      bonusInfo: { ...relationInfo.bonusInfo, limitBonus },
    });

    totalLimitBonus = simpleSum(limitBonus, totalLimitBonus);
  });

  return { participants: finalRelation, totalLimitBonus };
};

export const getParticipantGoalBonusReach = (
  goalInfo = {},
  goalPercentage = 0,
  goalTargets = {},
  clearBonusGateMin = "DONT_RESET",
) => {
  let goalReach = 0;

  let displayPercentage = goalPercentage;

  if (displayPercentage >= 0 && goalInfo.weight) {
    displayPercentage =
      displayPercentage > goalTargets.exceeded
        ? goalTargets.exceeded
        : displayPercentage;

    const weightMultiply = goalInfo.weight / 100;

    goalReach = displayPercentage * weightMultiply;
  }

  const hitTargetInfo = getHitTargetInfo(displayPercentage, goalTargets);

  if (hitTargetInfo.hitTarget === 0 && clearBonusGateMin === "RESET")
    goalReach = 0;

  return goalReach;
};

export const getIndicatorWithBonus = (
  goalInfo,
  goalTargets,
  clearBonusGateMin,
  periodFilterActive = false,
) => {
  const { realGoalsReachSum: goalPercentage } = multipleIndicatorsResult(
    [goalInfo],
    goalTargets,
    periodFilterActive,
  );

  const bonusReach = getParticipantGoalBonusReach(
    goalInfo,
    goalPercentage,
    goalTargets,
    clearBonusGateMin,
  );

  return { goalPercentage, bonusReach };
};

export const multipleIndicatorsWithBonus = (
  goals = [],
  goalTargets = {},
  clearBonusGateMin = "DONT_RESET",
  periodFilterActive = false,
) => {
  const {
    realGoalsReachSum,
    evaluatedGoals: originalEvaluatedGoals,
  } = multipleIndicatorsResult(goals, goalTargets, periodFilterActive);

  let reachBonusWeightSum = 0;
  const evaluatedGoals = [];

  if (Array.isArray(originalEvaluatedGoals)) {
    originalEvaluatedGoals.forEach((goalInfo) => {
      const { goalPercentage, bonusReach } = getIndicatorWithBonus(
        goalInfo,
        goalTargets,
        clearBonusGateMin,
        periodFilterActive,
      );

      reachBonusWeightSum += bonusReach;

      evaluatedGoals.push({
        ...goalInfo,
        bonusReach,
        goalPercentage,
      });
    });
  }

  return {
    realGoalsReachSum,
    reachBonusWeightSum,
    evaluatedGoals,
  };
};

export const getFilteredDistribution = (goalInfo, filteredPeriod = {}) => {
  const filteredDistributionValues = [];

  const { target: goalTargets } = goalInfo || {};
  const { actual: goalTargetValues } = goalTargets;

  if (Array.isArray(goalTargetValues)) {
    goalTargetValues.forEach((value, index) => {
      const normalizedMonthIndex = getNormalizedMonthIndex(
        index,
        goalInfo.frequency,
      );

      const { distributionValue } = getTargetsByIndex(goalInfo);

      if (
        normalizedMonthIndex >= filteredPeriod.start &&
        normalizedMonthIndex <= filteredPeriod.end
      ) {
        filteredDistributionValues.push(distributionValue.value);
      }
    });
  }

  return { filteredDistributionValues };
};

export const getFilteredPeriodWithDistribution = (
  goalInfo = {},
  filteredPeriod = {},
) => {
  let filteredTargets = {
    gate: 0,
    appropriated: 0,
    exceeded: 0,
  };

  const { filteredDistributionValues } = getFilteredDistribution(
    goalInfo,
    filteredPeriod,
  );

  const validPeriods = getIndicatorValidPeriods(goalInfo);

  const { total } = getCustomPeriodsDistribution(
    goalInfo,
    filteredDistributionValues,
    validPeriods,
  );

  filteredTargets = getPeriodTargetsDivision(goalInfo, total);

  return { filteredTargets };
};

export const getGoalInfoFilteredByPeriod = (
  goalInfo,
  periodCycle,
  secondaryFilter,
) => {
  const periodFilter = secondaryFilter || periodCycle;
  return filterGoalByPeriod(goalInfo, periodFilter);
};

export const calculateBonus = (
  totalGoalReach = 0,
  bonusTarget = { smaller: 0, greater: 0 },
  salariesTarget = { smaller: 0, greater: 0 },
) => {
  const bonusDiff = simpleSubtraction(bonusTarget.greater, bonusTarget.smaller);
  const bonusReachDeviation = simpleSubtraction(
    totalGoalReach,
    bonusTarget.smaller,
  );
  const salariesDiff = simpleSubtraction(
    salariesTarget.greater,
    salariesTarget.smaller,
  );

  const bonusDeviationMultiplied = bonusReachDeviation * salariesDiff;
  const deviationDivision = bonusDeviationMultiplied / bonusDiff;

  const finalResult = simpleSum(deviationDivision, salariesTarget.smaller);

  return typeof finalResult === "number" ? getToFixed(finalResult, 2) : 0.0;
};

export const getBarTargetPixel = (
  pixelMultiplier = 0,
  reach,
  smaller_delimiter,
  greater_delimiter,
  barDivPixels = 80,
) => {
  const pixelSum = pixelMultiplier * barDivPixels;

  const vReach = parseFloat(reach);
  const vSmaller_delimiter = parseFloat(smaller_delimiter);
  const vGreater_delimiter = parseFloat(greater_delimiter);

  if (vSmaller_delimiter < vReach && vGreater_delimiter > vReach) {
    const delimiterDiff = simpleSubtraction(
      vGreater_delimiter,
      vSmaller_delimiter,
    );
    const reachDelimiterDiff = simpleSubtraction(vReach, vSmaller_delimiter);
    const reachDiffMultiply = reachDelimiterDiff * 100;
    const barDivisionPercentage = Math.abs(reachDiffMultiply / delimiterDiff);

    const finalBarPixels =
      (barDivisionPercentage * barDivPixels) / 100 + pixelSum;

    return getToFixed(finalBarPixels, 2);
  }

  return vReach <= vSmaller_delimiter ? pixelSum : pixelSum + barDivPixels;
};

export const getBarBonusMeasurement = (
  realGoalsReachSum,
  goalTargets,
  barDivPixels = 80,
) => {
  const hitSalaryLevelInfo = getHitTargetInfo(realGoalsReachSum, goalTargets);
  const delimiter_zero = parseFloat(goalTargets.gate) * 0.7;
  const delimiter_one = parseFloat(goalTargets.gate);
  const delimiter_two = parseFloat(goalTargets.appropriated);
  const delimiter_three = parseFloat(goalTargets.exceeded);
  const delimiter_four = parseFloat(goalTargets.exceeded) * 1.3;

  const getPixels = (dA, dB) => {
    return getBarTargetPixel(
      hitSalaryLevelInfo.hitTarget,
      realGoalsReachSum,
      dA,
      dB,
      barDivPixels,
    );
  };

  if (hitSalaryLevelInfo.hitTarget === 1) {
    return getPixels(delimiter_one, delimiter_two);
  }

  if (hitSalaryLevelInfo.hitTarget === 2) {
    return getPixels(delimiter_two, delimiter_three);
  }

  if (hitSalaryLevelInfo.hitTarget === 3) {
    return getPixels(delimiter_three, delimiter_four);
  }

  return getPixels(delimiter_zero, delimiter_one);
};

export const getBonusAditionalSalaries = (
  bonusWeightReach = 0,
  roleBonus = {},
  goalTargets = {},
  workingTime = "MONTH_PERIOD_12",
  clearBonusGateMin = "DONT_RESET",
) => {
  let aditionalSalaries = 0;
  const salariesConfig = getSalariesConfig(roleBonus, workingTime);

  const hitSalaryLevel = getHitTargetInfo(bonusWeightReach, goalTargets);

  if (hitSalaryLevel.hitTarget === 0 && clearBonusGateMin === "DONT_RESET") {
    aditionalSalaries = calculateBonus(
      bonusWeightReach,
      { smaller: 0, greater: goalTargets.gate },
      { smaller: 0, greater: salariesConfig.gate },
    );
  }

  if (hitSalaryLevel.hitTarget === 3)
    aditionalSalaries = salariesConfig.exceeded;

  if (hitSalaryLevel.hitTarget === 1 && bonusWeightReach > goalTargets.gate) {
    aditionalSalaries = calculateBonus(
      bonusWeightReach,
      { smaller: goalTargets.gate, greater: goalTargets.appropriated },
      { smaller: salariesConfig.gate, greater: salariesConfig.appropriated },
    );
  } else if (bonusWeightReach === goalTargets.gate) {
    aditionalSalaries = salariesConfig.gate;
  }

  if (
    hitSalaryLevel.hitTarget === 2 &&
    bonusWeightReach > goalTargets.appropriated
  ) {
    aditionalSalaries = calculateBonus(
      bonusWeightReach,
      { smaller: goalTargets.appropriated, greater: goalTargets.exceeded },
      {
        smaller: salariesConfig.appropriated,
        greater: salariesConfig.exceeded,
      },
    );
  } else if (bonusWeightReach === goalTargets.appropriated) {
    aditionalSalaries = salariesConfig.appropriated;
  }

  return { hitSalaryLevel, aditionalSalaries };
};

export const calculateTotalSalaryBonus = (
  salaryMultiplier,
  salaryBaseValue,
) => {
  const totalBonus = parseFloat(salaryMultiplier) * parseFloat(salaryBaseValue);
  return typeof totalBonus === "number" ? getToFixed(totalBonus, 2) : 0.0;
};

export const getTotalParticipantBonusInfo = (
  participantInfo = {},
  participantGoals = [],
  goalTargets = {},
  clearBonusGateMin = "DONT_RESET",
  periodFilterActive,
) => {
  const { roleBonus } = participantInfo;
  const {
    reachBonusWeightSum,
    realGoalsReachSum,
  } = multipleIndicatorsWithBonus(
    participantGoals,
    goalTargets,
    clearBonusGateMin,
    periodFilterActive,
  );

  const { aditionalSalaries, hitSalaryLevel } = getBonusAditionalSalaries(
    reachBonusWeightSum,
    roleBonus,
    goalTargets,
    participantInfo.period,
    clearBonusGateMin,
  );

  const hitGoalsLevel = getHitTargetInfo(realGoalsReachSum, goalTargets);

  const totalBonus = calculateTotalSalaryBonus(
    aditionalSalaries,
    participantInfo.base,
  );

  const barMeasurement = getBarBonusMeasurement(realGoalsReachSum, goalTargets);
  const barBonusMeasurement = getBarBonusMeasurement(
    reachBonusWeightSum,
    goalTargets,
  );

  const hits = {
    target: {
      hitLevel: hitGoalsLevel,
      percentage: realGoalsReachSum,
    },
    bonus: {
      hitLevel: hitSalaryLevel,
      percentage: reachBonusWeightSum,
    },
  };

  const getHitInfoByCalc = (calcType = "target") => {
    if (calcType === "bonus") return hits.bonus;
    return hits.target;
  };

  return {
    reachBonusWeightSum,
    realGoalsReachSum,
    aditionalSalaries,
    totalBonus,
    barMeasurement,
    barBonusMeasurement,
    hits,
    hitSalaryLevel,
    hitGoalsLevel,
    getHitInfoByCalc,
  };
};

export const getSimpleParticipantGoalReachs = (
  participantInfo = {},
  participantGoals = [],
  goalTargets = {},
  clearBonusGateMin = "DONT_RESET",
  periodFilterActive,
) => {
  const { roleBonus } = participantInfo;
  const {
    reachBonusWeightSum,
    realGoalsReachSum,
  } = multipleIndicatorsWithBonus(
    participantGoals,
    goalTargets,
    clearBonusGateMin,
    periodFilterActive,
  );

  const { hitSalaryLevel } = getBonusAditionalSalaries(
    reachBonusWeightSum,
    roleBonus,
    goalTargets,
    participantInfo.period,
    clearBonusGateMin,
  );
  const hitGoalsLevel = getHitTargetInfo(realGoalsReachSum, goalTargets);

  return {
    hitGoalsLevel,
    hitSalaryLevel,
  };
};

export const getCountGoalStatus = (
  goals = [],
  goalTargets = {},
  periodFilterActive,
) => {
  const evaluations = getDefaultCounts();

  if (goals && goals.length > 0) {
    goals.forEach((goalInfo) => {
      const finalGoalInfo = goalInfo;

      const { realGoalsReachSum, evaluatedGoals } = multipleIndicatorsResult(
        [goalInfo],
        goalTargets,
        periodFilterActive,
      );

      const notStarted = evaluatedGoals.length === 0;

      const totalHitTargetInfo = getHitTargetInfo(
        realGoalsReachSum,
        goalTargets,
        notStarted,
      );

      if (totalHitTargetInfo.hitTarget === 0)
        evaluations.NOT_HIT.push(finalGoalInfo);
      if (totalHitTargetInfo.hitTarget === 1)
        evaluations.GATE.push(finalGoalInfo);
      if (totalHitTargetInfo.hitTarget === 2)
        evaluations.APPROPRIATED.push(finalGoalInfo);
      if (totalHitTargetInfo.hitTarget === 3)
        evaluations.EXCEEDED.push(finalGoalInfo);
    });
  }

  return evaluations;
};

export const getCountParticipantsGoalStatus = (
  goalsRelationTree = [],
  goalTargets = {},
  clearBonusGateMin = "DONT_RESET",
  calcType = "bonus",
  periodFilterActive,
) => {
  const evaluations = getDefaultCounts();

  goalsRelationTree.forEach((relationInfo) => {
    const { participantInfo, participantGoals } = relationInfo;
    if (Array.isArray(participantGoals)) {
      const { hitGoalsLevel, hitSalaryLevel } = getSimpleParticipantGoalReachs(
        participantInfo,
        participantGoals,
        goalTargets,
        clearBonusGateMin,
        periodFilterActive,
      );

      const hitTarget =
        calcType === "bonus"
          ? hitSalaryLevel.hitTarget
          : hitGoalsLevel.hitTarget;

      if (hitTarget === 0) evaluations.NOT_HIT.push(relationInfo);
      if (hitTarget === 1) evaluations.GATE.push(relationInfo);
      if (hitTarget === 2) evaluations.APPROPRIATED.push(relationInfo);
      if (hitTarget === 3) evaluations.EXCEEDED.push(relationInfo);
    }
  });

  return evaluations;
};

export const exceededTargetMaxValue = (exceeded) => {
  const vExceeded = Number(exceeded) > 0 ? Number(exceeded) : 0;
  return vExceeded * 1.3;
};
