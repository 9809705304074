import { makeStyles } from "@material-ui/core/styles";

export const useThemeStyles = makeStyles(() => ({
  addButton: {
    fontSize: "12px",
    backgroundColor: "#f4ffba",
    color: "#333",
    borderColor: "#f4ffba",
    "&:hover": {
      backgroundColor: "#edfd98",
    },
    textTransform: "none",
    "& > span > svg": {
      position: "relative",
      top: "-1px",
    },
  },
}));
