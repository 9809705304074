import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import {
  Display,
  Title,
  AlertStyle,
  ObjectiveContainer,
  DisplayEvaluation,
  ProgressBar,
} from "./OkrStyle";

import Avatar from "../../../Common/Avatar";

import {
  getColorGoalByStatus,
  getOkrTotalEvaluation,
} from "../../../../utils/okrUtils";

function KeyResults({
  intl,
  options,
  selectAndOpenInsight,
  questionAnswers,
  showHiddenExpectation,
}) {
  function listKeyResults() {
    if (questionAnswers.length) {
      return (
        <>
          {questionAnswers.map((questionAnswer) => {
            return (
              <ObjectiveContainer key={`keyId_${questionAnswer.questionId}`}>
                <div className="title-objective">
                  <span>OBJETIVO</span>
                  <p>{questionAnswer.questionText}</p>
                </div>
                <ul>
                  {questionAnswer.answers.map((answer) => {
                    const colorStatus =
                      answer.goal &&
                      getColorGoalByStatus(answer.goal[options.status]);

                    const currentPercentage =
                      answer.goal &&
                      getOkrTotalEvaluation(
                        answer.goal.value,
                        answer.goal[options.okrValue],
                        answer.goal.polarity,
                      );

                    const formatReached =
                      currentPercentage > 100 ? 100 : currentPercentage;

                    return (
                      <li key={`keyId_${answer.id}`}>
                        <div className="answer-info">
                          <Avatar
                            src={
                              answer.responsibleMember
                                ? answer.responsibleMember.userData.avatarBlobId
                                : ""
                            }
                            name={
                              answer.responsibleMember
                                ? answer.responsibleMember.name
                                : ""
                            }
                            style={{
                              width: "30px",
                              height: "30px",
                              margin: "0 0 0 10px",
                            }}
                          />
                          <p onClick={() => selectAndOpenInsight(answer.id)}>
                            {answer.text}
                          </p>
                        </div>
                        <DisplayEvaluation>
                          <div>
                            <p>Plano</p>
                            <span>{answer.goal.value}</span>
                          </div>
                          <div>
                            <p>
                              {!showHiddenExpectation
                                ? "Atingido"
                                : "Expectativa"}
                            </p>
                            <span>{answer.goal[options.okrValue]}</span>
                          </div>
                          <div
                            className="goal-list-item"
                            style={{ alignItems: "flex-end" }}
                          >
                            <div
                              style={{
                                color: "#333 !important",
                                marginRight: "5px",
                              }}
                            >
                              <span>{currentPercentage.toFixed()}%</span>
                            </div>
                            <ProgressBar
                              className="bar"
                              colorStatus={colorStatus}
                              widthProgress={formatReached}
                              style={{ marginBottom: "3px" }}
                            >
                              <div className="progress" />
                            </ProgressBar>
                          </div>
                        </DisplayEvaluation>
                      </li>
                    );
                  })}
                </ul>
              </ObjectiveContainer>
            );
          })}
        </>
      );
    }
    return (
      <AlertStyle severity="info">
        {intl.formatMessage({ id: "info_key_results" })}
      </AlertStyle>
    );
  }

  return (
    <Display>
      <Title>{intl.formatMessage({ id: "view_key_results" })}</Title>
      {listKeyResults()}
    </Display>
  );
}

const mapStateToProps = (state) => {
  const { toolsConfigurations } = state;

  return {
    showHiddenExpectation: toolsConfigurations.showHiddenExpectation,
  };
};

export default connect(mapStateToProps)(injectIntl(KeyResults));
