import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { clearViewCallback } from "../actions/structureActions";
import { viewController } from "./TopLevel/structureMiddleware";
import utils from "../utils/toolUtils";

const { usePrevious } = utils;

const StructureMiddleware = (props) => {
  const prevProps = usePrevious(props);

  const isDiffProps = useCallback(
    (slug) => {
      if ((!prevProps && props) || (prevProps && !props)) return true;
      if (
        !prevProps[slug] ||
        !props[slug] ||
        !_.isEqual(prevProps[slug], props[slug])
      ) {
        return true;
      }

      return false;
    },
    [props, prevProps],
  );

  const [activeViews, setActiveViews] = useState({});
  const { action, clearViewCallback } = props;

  useEffect(() => {
    if (action.type && isDiffProps("action") && !action.remove) {
      const newView = viewController(
        action.type,
        action.params,
        clearViewCallback,
      );

      if (newView) setActiveViews({ ...activeViews, [action.type]: newView });
    } else if (action.type && action.remove) {
      const newActiveViews = { ...activeViews };

      if (activeViews[action.type]) {
        delete newActiveViews[action.type];
      }

      if (!_.isEqual(newActiveViews, activeViews)) {
        setActiveViews(newActiveViews);
      }
    }
  }, [action, activeViews, clearViewCallback, isDiffProps]);

  const DisplayViews = () => {
    return Object.keys(activeViews).map((keySlug, index) => (
      <React.Fragment key={index}>{activeViews[keySlug]}</React.Fragment>
    ));
  };

  return (
    <div className="top-level-middleware">
      <DisplayViews />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { structure } = state;

  return {
    action: structure && structure.action ? structure.action : {},
  };
};

export default connect(mapStateToProps, { clearViewCallback })(
  StructureMiddleware,
);
