import update from "immutability-helper";

import { SET_SELECTED_TOOL, UPDATE_SELECTED_TOOL } from "../actions/types";

const INITIAL_STATE = {};

const toolsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_TOOL:
      return action.payload;
    case UPDATE_SELECTED_TOOL:
      return update(state, {
        $merge: {
          ...action.payload,
        },
      });
    default:
      return state;
  }
};

export default toolsReducer;
