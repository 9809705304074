/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import { useState, useEffect } from "react";
import { getObjectDifference } from "../ArrayUtils";

export function useCompareChanges({
  toolAdministration = {},
  toolHistory = {},
  isLoading = false,
}) {
  const [changes, setChanges] = useState([]);

  useEffect(() => {
    const restAndFormDiff = getObjectDifference(
      toolAdministration,
      toolHistory,
    );

    const anyChanges = [];

    Object.keys(restAndFormDiff).forEach((objKey) => {
      anyChanges.push(objKey);
    });

    const newChanges = !isLoading ? anyChanges : [];

    if (!_.isEqual(newChanges, changes)) setChanges(newChanges);
  }, [toolAdministration, toolHistory, isLoading]);

  return { changes, haveChanges: changes.length > 0 };
}

export function useErrors({ toolAdministration, validate }) {
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (toolAdministration && typeof validate === "function") {
      const newErrors = validate(toolAdministration);

      if (!_.isEqual(newErrors, errors)) setErrors(newErrors);
    }
  }, [toolAdministration]);

  return { errors, haveErrors: errors.length > 0 };
}
