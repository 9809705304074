import { defineMessages } from "react-intl";
import moment from "moment";
import {
  getParticipantInfoByMemberId,
  getQuestionByParticipantId,
  getParticipantsByRoleId,
  getAdministrativeRoleInfoById,
} from "./filter";
import { getCurrentToolConfig } from "./entity";
import { getEntityReplaceConfig } from "../kpis/entity";
import { translatedText } from "../translationUtils";
import { getDefaultFullAccess } from "../entityUtils";
import { getUpdatedDeleteArrayByIndex, getObjectInfoById } from "../ArrayUtils";
import { floatNumber } from "../MathUtils";
import {
  getIndicatorValidPeriods,
  getPeriodsDistributionInfo,
} from "../kpis/calc2";
import {
  allowedEditingStatus,
  allowedEditingRelationType,
} from "../../constants/goalsAndBonus";

const currentTodayDate = new Date();
const formatedTodayDate = moment(currentTodayDate).format("YYYY-MM-DD"); // Não mudar formatação

const messages = defineMessages({
  gb_error_small_title: { id: "gb_error_small_title" },
  gb_error_small_description: { id: "gb_error_small_description" },
  gb_error_block_relationship: { id: "gb_error_block_relationship" },
  gb_error_repeated_target: { id: "gb_error_repeated_target" },
  gb_error_invalid_target: { id: "gb_error_invalid_target" },
  gb_error_invalid_weight: { id: "gb_error_invalid_weight" },
  gb_error_invalid_distribution: { id: "gb_error_invalid_distribution" },
  gb_warning_need_min_administrator: {
    id: "gb_warning_need_min_administrator",
  },
  kpi_error_invalid_date: { id: "kpi_error_invalid_date" },
  kpi_error_invalid_same_month: { id: "kpi_error_invalid_same_month" },
});

const translation = (id, values) => translatedText(id, messages, values);

export const checkAdmRoleAdmins = (editingIndex, administrativeRoles = []) => {
  const leftAdmRoles = getUpdatedDeleteArrayByIndex(
    [...administrativeRoles],
    editingIndex,
  );

  let admFound = false;

  for (const admRole of leftAdmRoles) {
    if (admRole.access && admRole.access.indexOf("ADMIN") > -1) admFound = true;
  }

  return admFound;
};

export const getPolarity = (targets = {}) => {
  return targets.gate < targets.appropriated ? "GREATER" : "SMALLER";
};

export const getNewGoalErrors = (form, isEditing = false) => {
  let errors = [];

  const compareEqualList = [
    form.target.gate,
    form.target.appropriated,
    form.target.exceeded,
  ];

  const addError = (slug, message) => {
    errors.push({ slug, message });
  };

  if (form.title.length < 2) {
    addError("invalidTitle", translation("gb_error_small_title"));
  }

  if (
    isEditing &&
    form.relatedInsights.length > 1 &&
    form.relationshipType === "INDIVIDUAL"
  ) {
    addError(
      "individualNotAllowed",
      translation("gb_error_block_relationship", {
        length: form.relatedInsights.length,
      }),
    );
  }

  for (let v1 of compareEqualList) {
    let repeated = 0;

    compareEqualList.forEach((val) => {
      if (Number(val) === Number(v1)) repeated++;
    });

    if (repeated > 1) {
      addError("repeatedTarget", translation("gb_error_repeated_target"));
      break;
    }
  }

  let targets = {
    gate: Number(form.target.gate),
    appropriated: Number(form.target.appropriated),
    exceeded: Number(form.target.exceeded),
  };

  const isGreaterSeqRight =
    targets.gate < targets.appropriated &&
    targets.appropriated < targets.exceeded;

  const isSmallerSeqRight =
    targets.gate > targets.appropriated &&
    targets.appropriated > targets.exceeded;

  if (!isGreaterSeqRight && !isSmallerSeqRight) {
    addError("invalidTarget", translation("gb_error_invalid_target"));
  }

  if (Number(form.weight) <= 0 || Number(form.weight) > 100) {
    addError("invalidWeight", translation("gb_error_invalid_weight"));
  }

  const { period = {} } = form;
  const haveValidMonths = period.start >= 0 && period.end >= 0;

  if (!haveValidMonths) {
    addError("startDateError", translation("kpi_error_invalid_date"));
  }

  if (period.start === period.end) {
    addError("sameMonthError", translation("kpi_error_invalid_same_month"));
  }

  const { isValid: isDistValid } = getPeriodsDistributionInfo(form);

  if (!isDistValid) {
    addError("distributionError", translation("gb_error_invalid_distribution"));
  }

  return errors;
};

export const checkIsGoalEditable = (
  goalId,
  workflowStatus = "",
  relationshipType = "",
  admin = false,
) => {
  const haveEditableStatus = allowedEditingStatus.indexOf(workflowStatus) > -1;
  const haveEditableRelationship =
    allowedEditingRelationType.indexOf(relationshipType) > -1;

  return (haveEditableStatus || !goalId) && (haveEditableRelationship || admin);
};

export const checkDatesPeriodBetween = (startDate, endDate) => {
  const momentStart = startDate ? moment(startDate) : false;
  const momentEnd = endDate ? moment(endDate) : false;
  const haveDates = momentStart && momentEnd;
  const validDates = haveDates && momentStart.isValid() && momentEnd.isValid();

  if (validDates) {
    return moment(formatedTodayDate).isBetween(
      momentStart,
      momentEnd,
      null,
      "[]",
    );
  }

  return false;
};

export const getUserAdministrativeAccess = (memberId, administrativeRoles) => {
  let accesses = [];
  memberId = Number(memberId);

  if (administrativeRoles && administrativeRoles.length > 0) {
    administrativeRoles.forEach((admRoleInfo) => {
      const { members = [] } = admRoleInfo;

      if (members.indexOf(memberId) > -1 && admRoleInfo.access) {
        if (admRoleInfo.access)
          admRoleInfo.access.forEach((access) => {
            accesses.push(access);
          });
      }
    });
  }

  return accesses;
};

export const getUserToolAccess = (
  memberId,
  participants,
  administrativeRoles,
) => {
  let accesses = [];
  let hierarchy = [];
  let workflow = [];

  const pushAccess = (newAccess) => {
    if (accesses.indexOf(newAccess) === -1) accesses.push(newAccess);
  };

  const participantInfo = getParticipantInfoByMemberId(memberId, participants);
  const administrativeAccess = getUserAdministrativeAccess(
    memberId,
    administrativeRoles,
  );

  if (participantInfo) {
    const { roleBonus = {} } = participantInfo || {};
    const { roleViewAccess = [], roleAccess = [] } = roleBonus || {};

    pushAccess("OWN_GOALS");

    if (Array.isArray(roleViewAccess) && roleViewAccess.length > 0) {
      hierarchy = roleBonus.roleViewAccess;
    }

    if (Array.isArray(roleAccess) && roleAccess.length > 0) {
      workflow = roleBonus.roleAccess;
    }
  }

  if (administrativeAccess.length > 0) {
    administrativeAccess.forEach((admAccess) => {
      pushAccess(admAccess);
    });
  }

  return { accesses, hierarchy, workflow, participantInfo };
};

export const handleCheckAccess = (checkAccess = "", accesses = []) => {
  return accesses.indexOf(checkAccess) > -1;
};

export const getTopLevelUserAccess = () => {
  const { toolGoalsBonusAdm, user = {} } = getCurrentToolConfig();

  const { participants = [], administrativeRoles = [] } = toolGoalsBonusAdm;

  return getUserToolAccess(user.id, participants, administrativeRoles);
};

export const getGoalsBonusAccessLevels = (
  allSceneries = [],
  allThemes = {},
  allQuestions = {},
  allAnswers = {},
  additionalConfig = {},
) => {
  const { toolGoalsBonusAdm } = getCurrentToolConfig();

  const { participants = [] } = toolGoalsBonusAdm;

  let viewAccess = {
    scenaries: [],
    themes: [],
    questions: [],
    insights: [],
  };

  let workflowAccess = {
    scenaries: [],
    themes: [],
    questions: [],
    insights: [],
  };

  let participantInsights = [];

  function pushLevel(id = null, type = "cenary", appendTo = "VIEW") {
    const appendToDefault = appendTo === "VIEW" || appendTo === "ALL";
    const appendToWorkflow = appendTo === "WORKFLOW" || appendTo === "ALL";

    if (
      appendToDefault &&
      viewAccess[type] &&
      viewAccess[type].indexOf(id) === -1
    )
      viewAccess[type].push(id);

    if (
      appendToWorkflow &&
      workflowAccess[type] &&
      workflowAccess[type].indexOf(id) === -1
    )
      workflowAccess[type].push(id);
  }

  function pushByParticipant(participant_info, appendTo = "VIEW") {
    const question = getQuestionByParticipantId(
      participant_info.id,
      allQuestions,
    );

    if (question && question.id) {
      pushLevel(question.id, "questions", appendTo);

      const questionTheme = question.theme || {};

      if (questionTheme.id) {
        pushLevel(questionTheme.id, "themes", appendTo);
        const themeScenarie = questionTheme.cenary || {};

        if (themeScenarie.id)
          pushLevel(themeScenarie.id, "scenaries", appendTo);
      }
    }
  }

  function pushByHierarchy(list, appendTo = "VIEW") {
    if (list && Array.isArray(list)) {
      list.forEach((roleId) => {
        const roleParticipants = getParticipantsByRoleId(roleId, participants);

        roleParticipants.forEach((pInfo) => {
          pushByParticipant(pInfo, appendTo);
        });
      });
    }
  }

  function pushByQuestionInsights(questions, appendTo = "VIEW") {
    if (questions && Array.isArray(questions)) {
      questions.forEach((qId) => {
        const questionInsights = allAnswers[qId];

        if (questionInsights) {
          questionInsights.forEach(({ id }) => {
            pushLevel(id, "insights", appendTo);
          });
        }
      });
    }
  }

  function checkAccess(check = "") {
    return handleCheckAccess(check, userAccess.accesses);
  }

  const userAccess = getTopLevelUserAccess();
  const { participantInfo = null } = userAccess;

  const isAdmin = checkAccess("ADMIN");
  const registrationAccess = isAdmin || checkAccess("REGISTRATIONS");
  const isAuditor = checkAccess("AUDITOR");
  const isProgramParticipant = checkAccess("OWN_GOALS");

  const participantQuestion =
    isProgramParticipant && participantInfo
      ? getQuestionByParticipantId(participantInfo.id, allQuestions)
      : null;

  const participantTheme =
    (participantQuestion && participantQuestion.theme) || {};

  const participantCenary = (participantTheme && participantTheme.cenary) || {};

  const { goalsBonusProgramTeamId = null } = participantCenary;

  if (registrationAccess || isAuditor) {
    viewAccess = getDefaultFullAccess(
      allSceneries,
      allThemes,
      allQuestions,
      allAnswers,
    ).viewAccess;

    workflowAccess = viewAccess;
  }

  if (
    isProgramParticipant &&
    goalsBonusProgramTeamId &&
    additionalConfig.showUserTeam
  ) {
    participants.forEach((checkParticipantInfo) => {
      const checkPartQuestion = getQuestionByParticipantId(
        checkParticipantInfo.id,
        allQuestions,
      );
      const checkPartTheme =
        (checkPartQuestion && checkPartQuestion.theme) || {};
      const checkPartCenary = (checkPartTheme && checkPartTheme.cenary) || {};

      if (
        checkPartCenary.goalsBonusProgramTeamId &&
        checkPartCenary.goalsBonusProgramTeamId === goalsBonusProgramTeamId
      ) {
        pushByParticipant(checkParticipantInfo, "VIEW");
      }
    });
  }

  if (!registrationAccess && isProgramParticipant && participantInfo) {
    pushByParticipant(participantInfo, "ALL");
  }

  if (!registrationAccess) {
    pushByHierarchy(userAccess.hierarchy, "VIEW");
    pushByHierarchy(userAccess.workflow, "WORKFLOW");
  }

  pushByQuestionInsights(viewAccess.questions, "VIEW");
  pushByQuestionInsights(workflowAccess.questions, "WORKFLOW");

  if (isProgramParticipant && participantQuestion && participantQuestion.id) {
    const questionInsights = allAnswers[participantQuestion.id];

    if (questionInsights) {
      questionInsights.forEach(({ id }) => {
        participantInsights.push(id);
      });
    }
  }

  const limitedQuestionAccesses =
    isProgramParticipant && participantQuestion && participantQuestion.id
      ? [participantQuestion.id]
      : [];

  return {
    viewAccess,
    workflowAccess,
    replaceConfig: getEntityReplaceConfig(
      registrationAccess,
      isProgramParticipant || registrationAccess,
      registrationAccess,
    ),
    userAccess,
    registrationAccess,
    participantQuestion,
    participantInsights,
    questionRegistrations: registrationAccess
      ? viewAccess.questions
      : limitedQuestionAccesses,
  };
};

export const getAdmRolesErrors = (
  form = {},
  isEditing = false,
  administrativeRoles = [],
  adminRoleId = null,
) => {
  let errors = [];

  function addError(slug, message) {
    errors.push({ slug, message });
  }

  const isLastAdmRole = administrativeRoles.length === 1;

  const adminRoleIndex = getAdministrativeRoleInfoById(
    adminRoleId,
    administrativeRoles,
    true,
  );

  if (
    isEditing &&
    form.access.indexOf("ADMIN") === -1 &&
    (isLastAdmRole || !checkAdmRoleAdmins(adminRoleIndex, administrativeRoles))
  ) {
    addError(
      "noAdminSelected",
      translation("gb_warning_need_min_administrator"),
    );
  }
  if (form.role.length < 2) addError("noRole");
  if (form.members.length === 0) addError("noMember");
  if (form.access.length === 0) addError("noAccess");

  return errors;
};

export const getValidPeriodValue = (goalPeriodValue) => {
  return goalPeriodValue !== 0 && !goalPeriodValue
    ? null
    : Number(goalPeriodValue);
};

export const getGoalsTargetsCorrection = (target = {}) => {
  return {
    ...target,
    actual: target.actual,
    distribution: target.distribution,
    gate: floatNumber(target.gate),
    appropriated: floatNumber(target.appropriated),
    exceeded: floatNumber(target.exceeded),
  };
};

export const getCountAdminRolesOnAdministration = (
  administrativeRoles = [],
) => {
  let rolesCount = 0;

  if (Array.isArray(administrativeRoles)) {
    administrativeRoles.forEach((adminRoleInfo) => {
      const { access = [] } = adminRoleInfo;

      if (access && access.length > 0 && access.indexOf("ADMIN") > -1)
        rolesCount++;
    });
  }

  return rolesCount;
};
