const en = {
  connect_main_title: "Select a connection",
};

const es = {
  connect_main_title: "Selecciona una conexión",
};

const pt = {
  connect_main_title: "Selecione uma conexão",
};

const translations = { en, es, pt };

export default translations;
