import PropTypes from "prop-types";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import { injectIntl, defineMessages } from "react-intl";
import { Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import React, { useState, useEffect, useCallback } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import FormDialog from "../../../Common/FormDialog";
import ConfirmationDialog from "../../../Common/ConfirmationDialog";

import {
  defaultActivityData,
  getActivityValidInfo,
  getRestAcitivityInfo,
  getActivityStage,
} from "../../../../utils/projectEvaluation";
import {
  reorder,
  formatDate,
  getItemStyle,
  getListStyle,
  defaultModalError,
  defaultAutomations,
  defaultConfirmDialog,
} from "../../../../utils/projectUtils";
import { activitiesTable } from "./translations";

import SimpleCheckbox from "../../../Common/SimpleCheckbox";

import SelectExcelCollumn from "../../../Common/SelectExcelCollumn";
import MaterialCustomMenu from "../../../Common/MaterialCustomMenu";
import { updateChecklist } from "../../../../actions/projectEvaluationActions";
import { getProjectChecklist } from "../../../../actions/projectEvaluationActions";

import ModalActivity from "./components/ModalActivity";
import { getOverallWeight, verifyDate } from "./utils";
import { getCompanyMembersAndParticipants } from "../../../customMapStates";

const messages = defineMessages(activitiesTable);

const DescriptionActivity = styled.div`
  cursor: pointer;
  padding: 10px 10px 10px 0px;

  &:hover span {
    text-decoration: underline;
  }
`;

const ActivitiesTable = ({
  intl,
  activities,
  setActivities,
  postChecklist,
  weightAutomation,
  effortAutomation,
  activitiesType,
  selectedInsightID,
  setProjectEvaluation,
  companyMembersAndParticipants,
  handleUpdateActivitiesTypeDefault,
  onClickSelectTemplate,
  isSelectTemplate,
  onConfirmEdit,
  onConfirmSave,
  onConfirmDelete,
  onChangeStatus,
  blockEdit,
  isTemplate,
  domainId,
}) => {
  const [calcAutomaticValues, setCalcAutomaticValues] =
    useState(defaultAutomations);
  const [displayModal, setDisplayModal] = useState({
    displayNewActivity: false,
    displayEditActivity: false,
    displayDeleteActivity: false,
    displayCancelActivity: false,
  });

  const [modalError, setModalError] = useState(defaultModalError);
  const [validations, setValidations] = useState({
    didWeightUpdate: false,
    wrongWeights: false,
  });
  const [action, setAction] = useState({
    index: null,
    description: null,
    action: null,
  });
  const [selectedActivity, setSelectedActivity] = useState(defaultActivityData);
  const [confirmationDialog, setConfirmationDialog] = useState({
    ...defaultConfirmDialog,
  });

  // Temporário
  const activitiesTypeDefault = true;

  const errorValidations = useCallback(() => {
    const weights = getOverallWeight(activities, false);
    const {
      description = "",
      progress = "",
      deliveryDate = null,
      expectedDate = null,
    } = selectedActivity;

    if (weights < 0 && !validations.wrongWeights) {
      setValidations({
        ...validations,
        wrongWeights: true,
      });
    } else if (weights >= 0 && validations.wrongWeights) {
      setValidations({
        ...validations,
        wrongWeights: false,
      });
    }

    if (description.length > 255) {
      return setModalError({
        ...modalError,
        error: true,
        errorType: "description",
      });
    }

    if (progress === "100" && deliveryDate === null) {
      return setModalError({
        ...modalError,
        error: true,
        errorType: "progress_delivery_date",
      });
    }

    if (!verifyDate(deliveryDate)) {
      return setModalError({
        ...modalError,
        error: true,
        errorType: "invalidDeliveryDate",
      });
    }
    if (!verifyDate(expectedDate)) {
      return setModalError({
        ...modalError,
        error: true,
        errorType: "invalidExpectedDate",
      });
    }

    setModalError(defaultModalError);
  }, [getOverallWeight, validations, selectedActivity]);

  const getAutomaticWeight = useCallback(
    (currentActivities = []) => {
      const newActivities = [];

      const activitiesLength =
        currentActivities?.filter(({ active }) => active !== false)?.length ||
        0;

      const newWeight = 100 / activitiesLength || 0;

      currentActivities.forEach((activity) => {
        const { active = null } = activity;

        if (active === false) {
          newActivities.push({ ...activity, weight: 0 });
          return;
        }

        newActivities.push({ ...activity, weight: Number(newWeight) });
      });

      if (calcAutomaticValues.weight) {
        return newActivities;
      }

      return currentActivities;
    },
    [calcAutomaticValues],
  );

  const getActivitiesAutomaticEffort = (checkLists = [], activities) => {
    if (checkLists.length === 0) return activities;

    const newChecklist = {};

    checkLists.forEach((checkList) => {
      const { id_activity = "" } = checkList;

      newChecklist[id_activity] = { ...checkList };
    });

    const newActivities = activities.map((activity) => {
      const { idChecklist = "", active = null } = activity || {};

      const { effort = {} } = newChecklist[idChecklist] || {};
      const { progress = 0, foreseen = 0, achieved = 0 } = effort || {};

      if (active === false) return { ...activity };

      return {
        ...activity,
        progress,
        effort: {
          foreseen,
          achieved,
        },
      };
    });

    return newActivities;
  };

  const getProjectChecklistBy = useCallback(
    async (id, activities) => {
      const { effort } = calcAutomaticValues;

      if (effort) {
        const newActivities = await getProjectChecklist(id).then(
          (checkLists = []) => {
            const newActivities = getActivitiesAutomaticEffort(
              checkLists,
              activities,
            );

            return newActivities;
          },
        );

        return newActivities;
      }

      return activities;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [calcAutomaticValues],
  );

  useEffect(() => {
    if (selectedInsightID) {
      let transformedActivities = activities;

      transformedActivities = getAutomaticWeight(activities);
      getProjectChecklistBy(domainId, transformedActivities).then(
        (newActivities) => {
          transformedActivities = newActivities;
        },
      );

      setActivities(transformedActivities);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjectChecklistBy, getAutomaticWeight]);

  useEffect(() => {
    if (isTemplate) {
      let transformedActivities = activities;

      transformedActivities = getAutomaticWeight(activities);
      getProjectChecklistBy(domainId, transformedActivities);

      setActivities(transformedActivities);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjectChecklistBy, getAutomaticWeight]);

  useEffect(() => {
    errorValidations();
  }, [activities, errorValidations, selectedActivity]);

  useEffect(() => {
    if (displayModal.displayNewActivity === false) {
      setSelectedActivity(defaultActivityData);
      setModalError(defaultModalError);
    }
  }, [displayModal.displayNewActivity]);

  const resetConfirmDialog = () => {
    setConfirmationDialog({ ...defaultConfirmDialog });
  };

  const saveUpdateActivitiesType = (newActivitiesType) => {
    handleUpdateActivitiesTypeDefault(newActivitiesType);
    resetConfirmDialog();
  };

  const changeActivitiesType = (newActivitiesType) => {
    if (newActivitiesType !== activitiesTypeDefault) {
      if (newActivitiesType === true) {
        setConfirmationDialog({
          open: true,
          title: "Você tem certeza que deseja alterar para Tradicional?",
          description:
            "Após confirmado, não será possível atualizar o campo esforço (hr), e também será necessário fazer os cálculos de Peso e Avanço Físico manualmente.",
          onConfirm: () => saveUpdateActivitiesType(newActivitiesType),
        });
      } else {
        setConfirmationDialog({
          open: true,
          title: "Você tem certeza que deseja alterar para Automático?",
          description:
            "Após confirmado, o peso das atividades será divido igualmente pela quantidade registrada e também o Avanço Físico será recalculado à partir dos valores de Atividades Planejadas e Atividades Realizadas.",
          onConfirm: () => saveUpdateActivitiesType(newActivitiesType),
        });
      }
    }
  };

  const handleEditActivity = (activityInfo = {}, option) => {
    setSelectedActivity({
      ...activityInfo,
      isEditing: true,
    });

    setAction({
      action: "edit",
    });

    setDisplayModal({
      ...displayModal,
      displayNewActivity: option,
    });
  };

  const handleCancelActivity = ({ index, id, description }) => {
    setAction({
      index,
      id,
      description,
      action: "cancel",
    });

    setDisplayModal({
      displayCancelActivity: true,
    });
  };

  const handleReactivateActivity = ({ index, id, description }) => {
    setAction({
      index,
      id,
      description,
      action: "reactivate",
    });

    setDisplayModal({
      ...displayModal,
      displayReactivateActivity: true,
    });
  };

  const checkAutomations = () => {
    if (!weightAutomation && !effortAutomation) return;

    setCalcAutomaticValues({
      weight: weightAutomation,
      effort: effortAutomation,
    });
  };

  const openModalByType = (type, option) => {
    setDisplayModal({
      ...displayModal,
      [type]: option,
    });
  };

  const closeModalAndClearState = () => {
    setSelectedActivity(defaultActivityData);
    openModalByType("displayNewActivity", false);
  };

  const handleAddActivity = async (
    activityModalData,
    activityChecklistData,
  ) => {
    const generateUuid = uuidv4();
    const { index: indexOrder, weight = "" } = activityModalData || {};

    const updatedActivitiy = getRestAcitivityInfo(
      { ...activityModalData },
      activities.length,
    );

    updatedActivitiy.weight = weight
      ? activityModalData.weight.toString()
      : getOverallWeight(activities);

    if (!activityModalData.isEditing) {
      updatedActivitiy.idChecklist = generateUuid;

      onConfirmEdit(updatedActivitiy);

      const checklist = {
        activity_id: generateUuid,
        insight_id: selectedInsightID || domainId,
        items: activityChecklistData,
      };

      await postChecklist(checklist, selectedInsightID);
    } else {
      const existIdChecklist = !!updatedActivitiy.idChecklist;

      updatedActivitiy.idChecklist =
        updatedActivitiy.idChecklist ?? generateUuid;

      const updatedActivities = activities.map((activity) => {
        if (activity.indexOrder === activityModalData.indexOrder) {
          return activityModalData;
        }

        return activity;
      });

      if (!existIdChecklist) {
        const newChecklist = {
          activity_id: generateUuid,
          insight_id: selectedInsightID || domainId,
          items: activityChecklistData,
        };

        await postChecklist(newChecklist, selectedInsightID);
      } else {
        const updatedChecklist = {
          activity_id: updatedActivitiy.idChecklist,
          items: activityChecklistData,
        };

        await updateChecklist(updatedChecklist, selectedInsightID);
      }

      onConfirmSave(updatedActivities, indexOrder);
    }

    setValidations({
      ...validations,
      didWeightUpdate: false,
    });

    closeModalAndClearState();
    checkAutomations();
  };

  const deleteActivityAndCheckAutomations = () => {
    onConfirmDelete(action.index);
    checkAutomations();
    openModalByType("displayDeleteActivity", false);
  };

  const handleConfirmCancel = () => {
    const canceled = false;

    onChangeStatus(action.index, canceled);
    openModalByType("displayCancelActivity", false);

    checkAutomations();
  };

  const handleConfirmReactivate = () => {
    const reactivate = null;

    onChangeStatus(action.index, reactivate);
    openModalByType("displayReactivateActivity", false);

    checkAutomations();
  };

  const handleDeleteActivity = (index, id, description, option) => {
    setAction({
      index,
      id,
      description,
      action: "delete",
    });

    setDisplayModal({
      displayDeleteActivity: option,
    });
  };

  const getMemberDisplayName = (memberId) => {
    let displayName = intl.formatMessage(messages.global_selectSome);

    if (memberId) {
      companyMembersAndParticipants.forEach((memberInfo) => {
        if (memberId === memberInfo.id) displayName = memberInfo.name;
      });
    }

    return displayName;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = reorder(
      activities,
      result.source.index,
      result.destination.index,
    );

    setActivities(items);
  };

  const displayErrorFooter = () => {
    return (
      <Row style={{ backgroundColor: "#eee" }}>
        <Col md={4} align="center">
          <strong>ERRO!</strong>
        </Col>
        <Col md={4} align="center">
          {intl.formatMessage(messages.weightExceeded)}
        </Col>
        <Col md={4} align="center" style={{ color: "red" }}>
          {getOverallWeight(activities, true)}%
        </Col>
      </Row>
    );
  };

  const displayConfirmDeleteModal = () => {
    const formTitle = intl.formatMessage(messages.areYouSure);

    return (
      <FormDialog
        open={displayModal.displayDeleteActivity}
        title={formTitle}
        onConfirm={deleteActivityAndCheckAutomations}
        onCancel={() => openModalByType("displayDeleteActivity", false)}
        bodyStyle={{ padding: "15px 15px 15px 15px" }}
      >
        <div>
          {intl.formatMessage(messages.deleteActivityConfirmation)}{" "}
          <strong>{action.description}</strong>?
        </div>
      </FormDialog>
    );
  };

  const displayConfirmCancelModal = () => {
    const formTitle = intl.formatMessage(messages.areYouSure);

    return (
      <FormDialog
        open={displayModal.displayCancelActivity}
        title={formTitle}
        onConfirm={handleConfirmCancel}
        onCancel={() => openModalByType("displayCancelActivity", false)}
        bodyStyle={{ padding: "15px 15px 15px 15px" }}
      >
        <div>
          {intl.formatMessage(messages.cancel_activity)}{" "}
          <strong>{action.description}</strong>?
        </div>
      </FormDialog>
    );
  };

  const displayConfirmReactivateModal = () => {
    const formTitle = intl.formatMessage(messages.areYouSure);

    return (
      <FormDialog
        open={displayModal.displayReactivateActivity}
        title={formTitle}
        onConfirm={handleConfirmReactivate}
        onCancel={() => openModalByType("displayReactivateActivity", false)}
        bodyStyle={{ padding: "15px 15px 15px 15px" }}
      >
        <div>
          Reativar atividade <strong>{action.description}</strong>?
        </div>
      </FormDialog>
    );
  };

  const handleCancelInterations = (activityInfo) => {
    const { index, active } = activityInfo;

    if (active === false) {
      return {
        icon: <i className="fas fa-exclamation" />,
        text: intl.formatMessage(messages.reactivate),
        action: () =>
          handleReactivateActivity({
            index,
            ...activityInfo,
          }),
      };
    }

    return {
      icon: <i className="fas fa-times-circle" />,
      text: intl.formatMessage(messages.cancel),
      action: () =>
        handleCancelActivity({
          index,
          ...activityInfo,
        }),
    };
  };

  const handleMenuActions = (activityInfo = {}) => {
    const { id, description, index } = activityInfo;

    const cancelConfig = handleCancelInterations(activityInfo);

    return [
      {
        icon: <i className="fas fa-pencil-alt" />,
        text: intl.formatMessage(messages.edit),
        action: () => handleEditActivity({ ...activityInfo }, true),
      },
      {
        ...cancelConfig,
      },
      {
        icon: <i className="fas fa-trash-alt" />,
        text: intl.formatMessage(messages.delete),
        action: () => handleDeleteActivity(index, id, description, true),
      },
    ];
  };

  const displayTableBody = () => {
    let tableIndex = 0;

    const orderedActivities = activities.length > 0 ? [...activities] : [];

    orderedActivities.sort((a, b) => {
      const a_attr = a.indexOrder;
      const b_attr = b.indexOrder;

      return a_attr > b_attr ? 1 : b_attr > a_attr ? -1 : 0;
    });

    return (
      <tbody>
        {orderedActivities.map((activityInfo, index) => {
          const { description, progress = 0, weight, note } = activityInfo;

          const {
            expectedActivities,
            achievedActivities,
            foreseenEffort,
            achievedEffort,
            activityDeliveryDate,
            activityExpectedDate,
            activityResponsible,
          } = getActivityValidInfo(activityInfo);

          tableIndex = index + 1;

          const { translation: activityStage = "" } = getActivityStage({
            ...activityInfo,
            intl,
          });

          const displayProgress = (progress = 0) => {
            if (progress === "" || progress === null) return 0;

            return progress;
          };

          return (
            <Draggable key={tableIndex} draggableId={tableIndex} index={index}>
              {(provided, snapshot) => (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Popover
                      id="popoverInformation"
                      style={{ zIndex: "99999" }}
                      title={intl.formatMessage(messages.note)}
                    >
                      <Row style={{ marginLeft: "5px" }}>{note}</Row>
                    </Popover>
                  }
                >
                  <tr
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}
                    key={index}
                  >
                    <td
                      className="fontSize12"
                      align="center"
                      style={{ padding: "0px", width: "37px" }}
                    >
                      <div style={{ width: "100%" }}>{index + 1}</div>
                    </td>
                    <td className="fontSize12" align="left">
                      <DescriptionActivity
                        title={description}
                        className="descriptionEllipsis"
                        onClick={() =>
                          !blockEdit &&
                          handleEditActivity({ ...activityInfo, index }, true)
                        }
                      >
                        <span>{description}</span>
                      </DescriptionActivity>
                    </td>
                    <td className="fontSize12" align="center" width="70px">
                      {Number(weight).toFixed(0)}%
                    </td>
                    <td className="fontSize12" align="center" width="70px">
                      {Number(displayProgress(progress)).toFixed(0)}%
                    </td>
                    {!activitiesTypeDefault && (
                      <>
                        <td width="80px" align="center">
                          {expectedActivities}
                        </td>
                        <td width="80px" align="center">
                          {achievedActivities}
                        </td>
                      </>
                    )}
                    <td className="fontSize12" align="center" width="70px">
                      {foreseenEffort}
                    </td>
                    <td className="fontSize12" align="center" width="70px">
                      {achievedEffort}
                    </td>
                    <td className="fontSize12" align="center" width="100px">
                      {formatDate(activityExpectedDate)}
                    </td>
                    <td className="fontSize12" align="center" width="100px">
                      {formatDate(activityDeliveryDate)}
                    </td>
                    <td className="fontSize12" align="center" width="100px">
                      {activityStage}
                    </td>
                    <td className="fontSize12" align="center" width="100px">
                      {getMemberDisplayName(activityResponsible.id)}
                    </td>

                    <td
                      align="center"
                      className="fontSize12"
                      style={{ padding: "0px", width: "28px" }}
                    >
                      <div
                        className="activityOptions"
                        style={{
                          width: "27px",
                          height: "65px",
                          lineHeight: "65px",
                        }}
                      >
                        {!blockEdit && (
                          <MaterialCustomMenu
                            menuActions={handleMenuActions({
                              ...activityInfo,
                              index,
                            })}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                </OverlayTrigger>
              )}
            </Draggable>
          );
        })}
      </tbody>
    );
  };

  const isTradClass = activitiesTypeDefault === true ? "active" : "";
  const isAutoClass = activitiesTypeDefault === false ? "active" : "";

  const effortTypeOptions = [
    {
      value: "Tarefas",
      label: intl.formatMessage(messages.tasks),
    },
    {
      value: "Horas",
      label: intl.formatMessage(messages.hours),
    },
  ];

  const handleAutomaticEffort = () => {
    setCalcAutomaticValues({ ...calcAutomaticValues, effort: true });
  };

  const doUpdateAutomaticWeight = ({ name = "", checked = false }) => {
    const newChecked = !checked;

    setProjectEvaluation((current) => {
      return {
        ...current,
        [name]: newChecked,
      };
    });

    if (!newChecked) return;

    setCalcAutomaticValues({ ...calcAutomaticValues, weight: true });
    const finalActivities = getAutomaticWeight(activities, true);
    setActivities(finalActivities);

    resetConfirmDialog();
  };

  const updateAutomaticWeight = ({ name = "", checked = false }) => {
    if (!checked === false) return doUpdateAutomaticWeight({ name, checked });

    setConfirmationDialog({
      open: true,
      title: "Você tem certeza que deseja alterar para Pesos Automáticos?",
      description: "Após confirmado, os valores atuais serão perdidos",
      onConfirm: () => doUpdateAutomaticWeight({ name, checked }),
      onCancel: () => resetConfirmDialog(),
    });
  };

  const doUpdateAutomaticEffort = ({ name = "", checked = false }) => {
    const newChecked = !checked;

    setProjectEvaluation((current) => {
      return {
        ...current,
        [name]: newChecked,
      };
    });

    if (!newChecked) return;

    handleAutomaticEffort();

    const currentId = isTemplate ? domainId : selectedInsightID;

    getProjectChecklist(currentId).then((checkLists = []) => {
      const newActivities = getActivitiesAutomaticEffort(
        checkLists,
        activities,
      );

      setActivities([...(newActivities || [])]);
    });

    resetConfirmDialog();
  };

  const updateAutomaticEffort = ({ name = "", checked = false }) => {
    if (!checked === false) return doUpdateAutomaticEffort({ name, checked });

    setConfirmationDialog({
      open: true,
      title: "Você tem certeza que deseja alterar para Esforço Automático?",
      description:
        "Após confirmado, os valores Previsto, Realizado e Avanço Físico dos milestones que tem uma Lista de Tarefas serão perdidos",
      onConfirm: () => doUpdateAutomaticEffort({ name, checked }),
      onCancel: () => resetConfirmDialog(),
    });
  };

  const handleEffortTypeChange = (index, fieldName, value) => {
    setProjectEvaluation((current) => {
      return {
        ...current,
        activitiesType: value,
      };
    });
  };

  const displayNoData = () => {
    return (
      <tbody>
        <tr>
          <td colSpan="100">
            <h5 align="center" style={{ fontWeight: "normal" }}>
              {!isSelectTemplate ? (
                intl.formatMessage(messages.global_noData)
              ) : (
                <button
                  className="btn btn-purple"
                  onClick={onClickSelectTemplate}
                >
                  Selecionar um template
                </button>
              )}
            </h5>
          </td>
        </tr>
      </tbody>
    );
  };

  const displayActivitiesModal =
    displayModal?.displayNewActivity || selectedActivity?.isEditing;

  return (
    <>
      {confirmationDialog.open && confirmationDialog.title && (
        <ConfirmationDialog
          open={confirmationDialog.open}
          title={confirmationDialog.title}
          description={confirmationDialog.description}
          onConfirm={confirmationDialog.onConfirm}
          confirmText={confirmationDialog.confirmText}
          onCancel={confirmationDialog.onCancel}
          hideCancel={confirmationDialog.hideCancel}
          confirmColor="#ff3151"
        />
      )}
      <div>
        <table className="simple-table zeroMargin">
          <thead>
            <tr className="block-hover">
              <td colSpan="2" align="left">
                {!true && (
                  <div className="activities-table-actions">
                    <button
                      className={`btn ${isTradClass}`}
                      onClick={() => changeActivitiesType(true)}
                    >
                      Tradicional
                    </button>
                    <button
                      className={`btn ${isAutoClass}`}
                      onClick={() => changeActivitiesType(false)}
                    >
                      Automático
                    </button>
                  </div>
                )}
              </td>
              {!activitiesTypeDefault && (
                <td colSpan="2" align="center">
                  {intl.formatMessage(messages.pport_form_activities)}
                </td>
              )}
              <td>{getOverallWeight(activities, true)}%</td>
              <td colSpan="1">{intl.formatMessage(messages.effortType)}</td>
              <td colSpan="2">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <SelectExcelCollumn
                    fieldName="effortType"
                    value={activitiesType || ""}
                    index={1}
                    idCycle={1}
                    handleChange={handleEffortTypeChange}
                    options={effortTypeOptions}
                    editCicle
                    label={intl.formatMessage(messages.effortType)}
                  />
                </div>
              </td>
              <td colSpan="2">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SimpleCheckbox
                    name="weightAutomation"
                    checked={weightAutomation === "" ? false : weightAutomation}
                    onChange={!blockEdit ? updateAutomaticWeight : () => {}}
                  />
                  Peso Automático
                </div>
              </td>
              <td colSpan="2">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SimpleCheckbox
                    name="effortAutomation"
                    checked={effortAutomation === "" ? false : effortAutomation}
                    onChange={!blockEdit && updateAutomaticEffort}
                  />
                  Esforço Automático
                </div>
              </td>
              <td colSpan="1" />
            </tr>
            <tr>
              {blockEdit ? (
                <td style={{ width: "37px" }} />
              ) : (
                <td
                  className="fontSize12 cursorPointer"
                  onClick={() => openModalByType("displayNewActivity", true)}
                  style={{ width: "37px" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      padding: "0px 5px",
                    }}
                  >
                    <i className="fas fa-plus" />
                  </div>
                </td>
              )}

              <td className="fontSize12" align="center">
                {intl.formatMessage(messages.pport_form_milestone)}
              </td>
              <td
                className="fontSize12"
                align="center"
                width="70px"
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                {intl.formatMessage(messages.weight)}
              </td>
              <td
                className="fontSize12"
                align="center"
                width="70px"
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                {intl.formatMessage(messages.physicalProgress)}
              </td>
              {!activitiesTypeDefault && (
                <>
                  <td width="80px">
                    {intl.formatMessage(
                      messages.pport_form_activities_expected,
                    )}
                  </td>
                  <td width="80px">
                    {intl.formatMessage(
                      messages.pport_form_activities_achieved,
                    )}
                  </td>
                </>
              )}
              <td
                className="fontSize12"
                align="center"
                width="70px"
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                {intl.formatMessage(messages.pport_form_effort_foreseen)}
              </td>
              <td
                className="fontSize12"
                align="center"
                width="70px"
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                {intl.formatMessage(messages.pport_form_effort_achieved)}
              </td>
              <td className="fontSize12" align="center" width="100px">
                {intl.formatMessage(messages.expectedDelivery)}
              </td>
              <td className="fontSize12" align="center" width="100px">
                {intl.formatMessage(messages.pport_form_delivery_date)}
              </td>
              <td className="fontSize12" align="center" width="100px">
                <div style={{ width: "60px" }}>
                  {intl.formatMessage(messages.stage)}
                </div>
              </td>
              <td className="fontSize12" align="center" width="100px">
                {intl.formatMessage(messages.pport_form_responsible)}
              </td>
              <td width="28px" />
            </tr>
          </thead>
        </table>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <table
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                className="simple-table zeroMargin"
              >
                {activities.length > 0 ? displayTableBody() : displayNoData()}
              </table>
            )}
          </Droppable>
        </DragDropContext>
        {validations.wrongWeights && displayErrorFooter()}
      </div>
      {displayActivitiesModal && (
        <ModalActivity
          onSaveActivity={handleAddActivity}
          onCancel={() => openModalByType("displayNewActivity", false)}
          activities={activities}
          selectedActivity={selectedActivity}
          weightAutomation={weightAutomation}
          effortAutomation={effortAutomation}
          activitiesType={activitiesType}
          activitiesTypeDefault={activitiesTypeDefault}
          displayModal={displayModal}
          selectedInsightID={selectedInsightID}
        />
      )}
      {displayConfirmDeleteModal()}
      {displayConfirmCancelModal()}
      {displayConfirmReactivateModal()}
    </>
  );
};

ActivitiesTable.propTypes = {
  intl: PropTypes.object,
  activities: PropTypes.array,
  postChecklist: PropTypes.func,
  setActivities: PropTypes.func,
  projectEvaluation: PropTypes.object,
  selectedInsightID: PropTypes.number,
  setProjectEvaluation: PropTypes.func,
  onClickSelectTemplate: PropTypes.func,
  isSelectTemplate: PropTypes.bool,
  companyMembersAndParticipants: PropTypes.array,
  handleUpdateActivitiesTypeDefault: PropTypes.func,
  onConfirmEdit: PropTypes.func,
  onConfirmSave: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onChangeStatus: PropTypes.func,
  blockEdit: PropTypes.bool,
  isTemplate: PropTypes.bool,
  domainId: PropTypes.number.isRequired,
};

ActivitiesTable.defaultProps = {
  blockEdit: false,
  intl: {},
  activities: [],
  isTemplate: false,
  selectedInsightID: 0,
  projectEvaluation: {},
  postChecklist: () => {},
  setActivities: () => {},
  setProjectEvaluation: () => {},
  isSelectTemplate: false,
  onClickSelectTemplate: () => {},
  companyMembersAndParticipants: [],
  handleUpdateActivitiesTypeDefault: () => {},
  onConfirmEdit: () => {},
  onConfirmSave: () => {},
  onConfirmDelete: () => {},
  onChangeStatus: () => {},
};

const mapStateToProps = (state) => {
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);
  const { access } = state;

  return {
    domainId: access.currentDomain?.id,
    companyMembersAndParticipants,
  };
};

export default connect(mapStateToProps)(injectIntl(ActivitiesTable));
