import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import utils from "../../../../utils/toolUtils";
import kpiUtils from "../../../../utils/kpis";
import GoalListItem from "../../GoalListItem";

const { orderArrayByObjAttr } = utils;

const { exceededTargetMaxValue, getProcessGoalsReachInfo, getProcessPopover } =
  kpiUtils;

const messages = defineMessages({
  noDataToDisplay: {
    id: "global.noDataTable",
  },
  kpi_table_text_subthemes_upper: {
    id: "kpi_table_text_subthemes_upper",
  },
});

const ProcessEvaluations = (props) => {
  const {
    kpiAdministration = {},
    displayProcesses = [],
    isPeriodFilterActive: filter,
    intl = {},
  } = props;

  const reorderedProcesses = orderArrayByObjAttr(displayProcesses, "title");

  return (
    <div className="evaluation-card">
      <div className="card-header">
        <div className="card-title">
          <h4>{intl.formatMessage(messages.kpi_table_text_subthemes_upper)}</h4>
        </div>
      </div>
      <div style={{ padding: "20px 0px 20px 15px" }}>
        <div className="card-body max-height-800" style={{ padding: "0px" }}>
          <div className="right-spacing">
            {reorderedProcesses.map((processRelation = {}) => {
              const { processGoals = [] } = processRelation;

              if (processGoals?.length === 0) return null;

              const { processInfo = {}, groupInfo = {} } = processRelation;
              const { goalTargets = {}, zeroWhenNegative } = kpiAdministration;

              const calculateAll =
                processGoals.filter(
                  ({ relationshipType }) => relationshipType === "INDIVIDUAL",
                ).length === 0;

              const { realGoalsReachSum, hitGoalsLevel } =
                getProcessGoalsReachInfo(
                  processGoals,
                  goalTargets,
                  filter,
                  zeroWhenNegative,
                  calculateAll,
                );

              return (
                <GoalListItem
                  key={processInfo.id}
                  title={processInfo.title}
                  description={groupInfo.title}
                  currentPercentage={realGoalsReachSum}
                  maxPercentage={exceededTargetMaxValue(goalTargets.exceeded)}
                  progressColor={hitGoalsLevel.color}
                  barTotalWidth={100}
                  popover={getProcessPopover(
                    processRelation,
                    goalTargets,
                    kpiAdministration.zeroWhenNegative,
                  )}
                />
              );
            })}
            {reorderedProcesses.length === 0 && (
              <h5 align="center">
                {intl.formatMessage(messages.noDataToDisplay)}
              </h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ProcessEvaluations);
