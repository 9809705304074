import axios from "axios";

import { SWAP_ORDER } from "./types";
import { reorder as reorderList, clone } from "../utils/ArrayUtils";
import { appRefreshSelectedTool } from "./appActions";

export const ENTITY_TYPE = {
  cenary: "cenary",
  theme: "theme",
  question: "question",
  insight: "insight",
  selectedTool: "selected_tool",
};

export const swapOrder = (
  list,
  sourceIndex,
  destinationIndex,
  entityType,
  parentId,
) => {
  return (dispatch) => {
    const items = reorderList(list, sourceIndex, destinationIndex);
    const data = {
      id: parentId,
      entityType,
      list: items,
    };
    dispatch({ type: SWAP_ORDER, payload: data });
    reorder(items, parentId);
  };
};

export const swapList = (
  sourceList,
  destList,
  sourceIndexToRemove,
  destIndexToPush,
  entityType,
  sourceId,
  destId,
) => {
  return (dispatch) => {
    let sList = clone(sourceList);
    let dList = clone(destList);
    const removedItens = sList.splice(sourceIndexToRemove, 1);
    dList.push(removedItens[0]);

    const data = {
      id: sourceId,
      entityType,
      list: sList,
    };
    reorder(sList, 0);
    dispatch({ type: SWAP_ORDER, payload: data });

    const dataDest = {
      id: destId,
      entityType,
      list: dList,
    };
    reorder(dList, destId);
    dispatch({ type: SWAP_ORDER, payload: dataDest });
  };
};

export const reorder = (list, fatherId) => {
  return axios.post(`/api/reorder/${fatherId}`, list).then((res) => res);
};

export const simpleEntityReorder = async (reorderedList, parentId) => {
  if (Array.isArray(reorderedList) && parentId) {
    const response = await reorder(reorderedList, parentId);
    const reorderedData = response.data || [];

    return reorderedData;
  }

  return false;
};

export const entityReducerReorder = (
  reorderedList,
  parentId,
  refresh = true,
) => {
  return async (dispatch) => {
    await simpleEntityReorder(reorderedList, parentId);
    if (refresh) dispatch(appRefreshSelectedTool(false));
  };
};
