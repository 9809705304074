export const updateAllList = (
  currentList = [],
  newList = [],
  identifierSlug = "insightId",
) => {
  const finalUpdatedList = [...currentList];
  const searchList = finalUpdatedList.map(
    (currentItemInfo) => currentItemInfo[identifierSlug],
  );

  if (Array.isArray(newList)) {
    newList.forEach((itemBody) => {
      const searchIndex = searchList.indexOf(itemBody[identifierSlug]);
      if (searchIndex > -1) {
        // Replace item in finalUpdatedList
        finalUpdatedList[searchIndex] = itemBody;
      } else {
        finalUpdatedList.push(itemBody);
      }
    });
  }

  return finalUpdatedList;
};

export const updateAllObjList = (currentList = [], newList = []) => {
  let finalUpdatedList = { ...currentList };
  const searchList = Object.keys(finalUpdatedList).map((currentItemInfo) =>
    Number(currentItemInfo),
  );

  Object.keys(newList).forEach((identifier) => {
    const searchIndex = searchList.indexOf(Number(identifier));

    if (searchIndex > -1) {
      finalUpdatedList[identifier] = newList[identifier];

      return;
    }

    finalUpdatedList = {
      ...finalUpdatedList,
      [identifier]: [...newList[identifier]] || [],
    };
  });

  return finalUpdatedList;
};

export const makeObjLevelAnArray = (obj = {}) => {
  let array = [];

  Object.keys(obj).forEach((identifier) => {
    array = [...array, ...(obj[identifier] || [])];
  });

  return array;
};
