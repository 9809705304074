import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "@material-ui/core";

const SimpleCheckbox = (props) => {
  const { checked, label, name, onChange, disabled, size, styles } = props;
  const verifiedCheck = !!checked;

  return (
    <div
      className="checkbox-wrap"
      onClick={() => onChange({ name, checked: verifiedCheck })}
      style={disabled ? { pointerEvents: "none", ...styles } : { ...styles }}
    >
      <Checkbox
        checked={verifiedCheck}
        value={name}
        disabled={disabled}
        size={size}
      />
      {label && `${label} `}
    </div>
  );
};

SimpleCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  styles: PropTypes.object,
  size: PropTypes.oneOf(["small", "medium"]),
};

SimpleCheckbox.defaultProps = {
  checked: false,
  name: "",
  label: "",
  disabled: false,
  styles: {},
  size: "medium",
};

export default SimpleCheckbox;
