import React from "react";
import { defineMessages } from "react-intl";
import Administration from "./Administration";
import Workflow from "./Workflow";

import { translatedText } from "../../../../../../utils/translationUtils";
import { checkAccess } from "../../../../../../utils/accessLevels";

const messages = defineMessages({
  global_administration: { id: "global.administration" },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

const getTabs = (appendProps = {}) => {
  const administrativeAccess = checkAccess(["MANAGER"]);

  let filteredTabs = [];

  const addTab = (id, label, Component) => {
    filteredTabs = [
      ...filteredTabs,
      {
        id,
        label,
        component: <Component {...appendProps} />,
      },
    ];
  };

  if (administrativeAccess)
    addTab(
      "administration",
      translation("global_administration"),
      Administration,
    );

  addTab("workflow", "Workflow", Workflow);

  return filteredTabs;
};

export default getTabs;
