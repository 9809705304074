import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TableProfiles from "../tables/TableProfiles";
import TableEvaluatedPeople from "../tables/TableEvaluatedPeople";
import { getCompanyMembersAndParticipants } from "../../../../../../customMapStates";
import {
  deleteProfile,
  deleteParticipant,
} from "../../../../../../../actions/skillAssessmentActions";
import { archiveInsights } from "../../../../../../../actions/insightActions";
import { deleteAnswer } from "../../../../../../../actions/answersActions";
import { getStyledErrorMessage } from "../../../../../../../utils/formValidation";
import {
  profileErrorSlugs,
  participantErrorSlugs,
} from "../../../../../../../utils/skillsAssessment/errorConstants";
import {
  getParticipantsDeleteDialogInfo,
  getParticipantsArchiveDialogInfo,
  getParticipantsUnarchiveDialogInfo,
  getProfilesDeleteDialogInfo,
} from "../../../../../../../utils/skillsAssessment/deletes";

const TabPublic = (props) => {
  const {
    toolAdministration = {},
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
    toggleOpenForms,
    updateAdministration,
    updateAdministrationSingleField,
    setConfirmationDialog,
    resetConfirmDialog,
    errors,
    deleteProfile,
    deleteParticipant,
    selectedTool,
    companyMembersAndParticipants,
    isLoading,
    setSaveLoading,
    archiveInsights,
    deleteAnswer,
  } = props;

  const handleDeleteProfile = (profileId) => {
    deleteProfile(selectedTool.id, profileId);
  };

  const handleDeleteParticipant = (participantId, callbackConfig) => {
    deleteParticipant(selectedTool.id, participantId, callbackConfig);
  };

  const dialogConfirmation = (
    title,
    description,
    deleteCallback = () => {},
    confirmText,
    hideCancel,
  ) => {
    setConfirmationDialog({
      open: true,
      title,
      description,
      onConfirm: () => {
        deleteCallback();
        resetConfirmDialog();
      },
      confirmText,
      hideCancel,
      onCancel: resetConfirmDialog,
    });
  };

  const openDeleteProfile = (profileId) => {
    const { title, description, confirmText, hideCancel, disableDelete } =
      getProfilesDeleteDialogInfo(profileId, allQuestions);

    dialogConfirmation(
      title,
      description,
      !disableDelete ? () => handleDeleteProfile(profileId) : () => {},
      confirmText,
      hideCancel,
    );
  };

  const openDeleteParticipant = (participantId) => {
    const { title, description, confirmText, hideCancel, linkedInsights } =
      getParticipantsDeleteDialogInfo(participantId, allAnswers);

    const stopLoadingConfig = {
      onSuccess: () => {
        setSaveLoading(false);
      },
      onError: () => {
        setSaveLoading(false);
      },
    };

    dialogConfirmation(
      title,
      description,
      async () => {
        setSaveLoading(true);

        if (linkedInsights?.length > 0) {
          const [insight] = linkedInsights;

          await deleteAnswer(insight, {
            onSuccess: () => {
              handleDeleteParticipant(participantId, stopLoadingConfig);
            },
            onError: () => {
              setSaveLoading(false);
            },
          });
        } else {
          handleDeleteParticipant(participantId, stopLoadingConfig);
        }
      },
      confirmText,
      hideCancel,
    );
  };

  const openArchiveParticipant = (participantId) => {
    const { title, description, confirmText, hideCancel, linkedInsights } =
      getParticipantsArchiveDialogInfo(participantId, allAnswers);

    const stopLoadingConfig = {
      onSuccess: () => {
        setSaveLoading(false);
      },
      onError: () => {
        setSaveLoading(false);
      },
    };

    dialogConfirmation(
      title,
      description,
      async () => {
        setSaveLoading(true);
        if (linkedInsights?.length > 0) {
          await archiveInsights(
            linkedInsights.map(({ id }) => id),
            stopLoadingConfig,
          );
        } else {
          setSaveLoading(false);
        }
      },
      confirmText,
      hideCancel,
    );
  };

  const openUnarchiveParticipant = (participantId) => {
    const { title, description, confirmText, hideCancel, linkedInsights } =
      getParticipantsUnarchiveDialogInfo(participantId, allAnswers);

    const stopLoadingConfig = {
      onSuccess: () => {
        setSaveLoading(false);
      },
      onError: () => {
        setSaveLoading(false);
      },
    };

    dialogConfirmation(
      title,
      description,
      async () => {
        setSaveLoading(true);

        if (linkedInsights?.length > 0) {
          await archiveInsights(
            linkedInsights.map(({ id }) => id),
            stopLoadingConfig,
          );
        }
      },
      confirmText,
      hideCancel,
    );
  };

  return (
    <div className="tab-content" style={{ margin: "0px" }}>
      <div className="row">
        <div className="col-xs-12">
          <h4>Público da Avaliação</h4>
        </div>
        <div className="col-xs-6">
          <div className="row">
            <TableProfiles
              toolAdministration={toolAdministration}
              updateAdministration={updateAdministration}
              handleAdd={() => toggleOpenForms("newProfile")}
              openDeleteProfile={openDeleteProfile}
            />
            {getStyledErrorMessage(profileErrorSlugs(), errors)}
          </div>
        </div>
        <div className="col-xs-12">
          <div className="row">
            {getStyledErrorMessage(participantErrorSlugs(), errors)}
            <TableEvaluatedPeople
              toolAdministration={toolAdministration}
              updateAdministrationSingleField={updateAdministrationSingleField}
              handleAdd={() => toggleOpenForms("newParticipant")}
              openDeleteParticipant={openDeleteParticipant}
              openArchiveParticipant={openArchiveParticipant}
              openUnarchiveParticipant={openUnarchiveParticipant}
              companyMembersAndParticipants={companyMembersAndParticipants}
              selectedTool={selectedTool}
              allSceneries={allSceneries}
              allThemes={allThemes}
              allQuestions={allQuestions}
              allAnswers={allAnswers}
              isLoading={isLoading}
              setSaveLoading={setSaveLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);

  return {
    companyMembersAndParticipants,
  };
};

TabPublic.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
  selectedTool: PropTypes.object.isRequired,
  allQuestions: PropTypes.object.isRequired,
  allAnswers: PropTypes.object.isRequired,
  toggleOpenForms: PropTypes.func.isRequired,
  updateAdministration: PropTypes.func.isRequired,
  updateAdministrationSingleField: PropTypes.func.isRequired,
  setConfirmationDialog: PropTypes.func.isRequired,
  resetConfirmDialog: PropTypes.func.isRequired,
  deleteProfile: PropTypes.func.isRequired,
  deleteParticipant: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, {
  deleteProfile,
  deleteParticipant,
  archiveInsights,
  deleteAnswer,
})(TabPublic);
