import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import _ from "lodash";

import { selectToolsTimeline } from "../../../actions/toolTimelineActions";
import { fetchGroupedComments } from "../../../actions/insightTimelineActions";
import utils from "../../../utils/toolUtils";

import ToolTimelineView from "./ToolTimelineView";

import { OKR_ID } from "../../../constants/tools.constants";
import { buildCommentsBreadcrumb } from "../../../utils/commentUtils";

const {
  usePrevious,
  getSelectsList,
  getGroupedComments,
  getTimelineRelationDisplay,
} = utils;

const defaultSelectList = {
  sceneries: [],
  themes: [],
};

const defaultArray = [];

function ToolTimelineContainer(props) {
  const {
    open = false,
    intl,
    toolsTimeline,
    selectToolsTimeline,
    selectedTool = {},
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
    allComments,
    fetchGroupedComments,
    userId,
  } = props;

  const [comments, setComments] = useState(defaultArray);
  const [lastFetchedComments, setLastFetchedComments] = useState([]);
  const [listFilter, setListFilter] = useState(defaultSelectList);

  useEffect(() => {
    if (selectedTool.id && open) {
      selectToolsTimeline(selectedTool.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTool, open]);

  const getFilteredInsights = (filteredThemes) => {
    let filteredInsights = [];

    if (filteredThemes.length > 0) {
      filteredThemes.forEach((themeId) => {
        const themeQuestions = allQuestions[themeId];

        if (themeQuestions) {
          themeQuestions.forEach(({ id }) => {
            const questionInsights = allAnswers[id];

            if (questionInsights) {
              questionInsights.forEach((insightInfo) => {
                filteredInsights.push(insightInfo);
              });
            }
          });
        }
      });
    }

    return filteredInsights;
  };

  const filteredInsights = getFilteredInsights(listFilter.themes);

  const prevFilteredInsights = usePrevious(filteredInsights);

  useEffect(() => {
    const newCommentsToFetch = [...filteredInsights.map(({ id }) => id)];

    if (!_.isEqual(newCommentsToFetch, lastFetchedComments) && open) {
      setLastFetchedComments(newCommentsToFetch);

      fetchGroupedComments(newCommentsToFetch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, filteredInsights, prevFilteredInsights]);

  useEffect(() => {
    if (allComments && open) {
      let groupedComments = getGroupedComments(
        [...filteredInsights.map(({ id }) => id)],
        allComments,
      );

      groupedComments = buildCommentsBreadcrumb(
        groupedComments,
        filteredInsights,
      );

      const timelineRelationDisplay = getTimelineRelationDisplay(
        userId,
        groupedComments,
      );

      if (
        !_.isEqual(comments, timelineRelationDisplay) ||
        !_.isEqual(filteredInsights, prevFilteredInsights)
      ) {
        setComments(timelineRelationDisplay);
      }
    }
  }, [
    open,
    allComments,
    allQuestions,
    allSceneries,
    allThemes,
    comments,
    filteredInsights,
    prevFilteredInsights,
    userId,
  ]);

  const captureSelectFilter = (values = [], fromRef = "") => {
    setListFilter({ ...listFilter, [fromRef]: values });
  };

  const customConfig = {
    isOkr: selectedTool?.tool?.id === OKR_ID,
  };

  const selectList = getSelectsList(
    allSceneries,
    allThemes,
    listFilter,
    {},
    customConfig,
    allAnswers,
    allQuestions,
  );

  return (
    <ToolTimelineView
      intl={intl}
      content={toolsTimeline}
      selectList={selectList}
      captureSelectFilter={captureSelectFilter}
      comments={comments}
    />
  );
}

function mapStateToProps(state) {
  const { insightsTimeline = {}, user = {} } = state;
  const { allComments } = insightsTimeline;

  return {
    allComments,
    user: state.user,
    selectedTool: state.selectedTool,
    toolsTimeline: state.toolsTimeline,
    userId: user.id || null,
  };
}

export default injectIntl(
  connect(mapStateToProps, { selectToolsTimeline, fetchGroupedComments })(
    ToolTimelineContainer,
  ),
);
