import { useEffect, useCallback } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import _ from "lodash";

import axios from "axios";
import {
  FETCH_WORKSPACES_TOOL_REQUESTS,
  FETCH_HOME_REQUESTS,
  REFRESH_SELECTED_TOOL,
} from "./appControllerTypes";

import { getSelectedCompanyStates } from "./customMapStates";

import {
  fetchCompanyMembers,
  fetchCompanyParticipants,
  fetchAllCompanyWorkspaces,
  fetchMinifiedCompanyWorkspaces,
  fetchAllCompanyToolsByType,
  fetchCompanieTeams,
} from "../actions/companyActions";

// Custom tools data
import {
  fetchAllActivityInformation,
  fetchAllActivityInformations,
} from "../actions/activityInformationActions";
import {
  fetchAllMaturityData,
  fetchAllToolsMaturityData,
} from "../actions/insightMaturityActions";
import {
  fetchAllPeopleEvaluation,
  fetchAllPeopleEvaluations,
} from "../actions/peopleEvaluationActions";
import {
  fetchAllSwotEvaluation,
  fetchAllSwotEvaluations,
} from "../actions/swotEvaluationActions";
import {
  fetchAllProjectEvaluations,
  fetchProjectConfiguration,
  fetchAllToolsProjectEvaluations,
} from "../actions/projectEvaluationActions";
import {
  fetchProjectPrioritizations,
  fetchAllInsightPrioritizations,
} from "../actions/projectPrioritizationActions";
import {
  fetchToolGoalBonusConfiguration,
  fetchToolsGoalBonusConfigurations,
} from "../actions/goalsAndBonusActions";
import {
  fetchToolKpiConfiguration,
  fetchToolKpiConfigurations,
} from "../actions/kpiActions";
import { fetchSkillAssessmentConfig } from "../actions/skillAssessmentActions";
import { fetchCompanyNestedAreas } from "../actions/companyStructureActions";
import { fetchCompanyNestedPositions } from "../actions/companyPositionActions";
import {
  fetchSelectedToolActionsPlans,
  fetchAllInsightsActionsPlans,
} from "../actions/actionsPlan";
import {
  fetchOkrConfiguration,
  fetchOkrConfigurations,
} from "../actions/okrActions";
import { fetchToolPoints, fetchToolTree } from "../actions/toolActions";

import {
  PROCESSES_BIG_PICTURE,
  PEOPLE_BIG_PICTURE,
  SWOT,
  OKR_ID,
  PROJECT_PORTFOLIO,
  PROJECT_PRIORITIZATION,
  GOALS_AND_BONUS,
  KPIS,
  COMPETITIVE_ANALYSIS,
  SKILLS_ASSESSMENT,
} from "../constants/tools.constants";

import {
  fetchAllInsightsGoals,
  fetchToolsInsightsGoals,
} from "../actions/insightActions";

import utils from "../utils/toolUtils";
import { fetchEntityUsersWithRole } from "../actions/accessLevelsActions";
import { accessLevelsEssentials } from "../actions/accessControllerActions";

import { fetchUserProfile } from "../actions/profileActions";
import { fetchAllUsersCompanyHierarchy } from "../actions/userActions";
import {
  fetchCompAnalysisAdministration,
  fetchCompAnalysisManagementDataOverview,
  killManagementProcess,
  fetchAllCompetitiveEvaluations,
  fetchCompetitiveAnalysisPoints,
} from "../actions/compAnalysisActions";
import { setInterceptorInInstanceAxios } from "../api/interceptor";
import { apiNode } from "../api";
import { appRoutes } from "./routes";
import {
  setAuthInfo,
  logout,
  checkIsAuthenticated,
} from "../actions/authActions";

const {
  usePrevious,
  checkRoute,
  checkMultipleRoutes,
  getOnlyObjKeys,
  getRoutesOverview,
} = utils;

const getDashRoutes = (toolIds) => {
  let validRoutes = [];

  validRoutes = getRoutesOverview(toolIds);
  validRoutes.push("/");

  return validRoutes;
};

const AppController = (props) => {
  const prevProps = usePrevious(props);
  const isDiffProps = useCallback(
    (slug) => {
      if ((!prevProps && props) || (prevProps && !props)) return true;
      if (
        !prevProps[slug] ||
        !props[slug] ||
        !_.isEqual(prevProps[slug], props[slug])
      ) {
        return true;
      }

      return false;
    },
    [props, prevProps],
  );

  const {
    appActions = {},
    fetchCompanyMembers,
    fetchCompanyParticipants,
    fetchAllCompanyWorkspaces,
    fetchMinifiedCompanyWorkspaces,
    fetchAllCompanyToolsByType,
    fetchAllActivityInformation,
    fetchAllMaturityData,
    fetchAllPeopleEvaluation,
    fetchAllSwotEvaluation,
    fetchAllInsightsGoals,
    fetchAllProjectEvaluations,
    fetchProjectConfiguration,
    fetchProjectPrioritizations,
    fetchAllInsightPrioritizations,
    fetchToolGoalBonusConfiguration,
    fetchToolKpiConfiguration,
    fetchCompAnalysisAdministration,
    fetchCompAnalysisManagementDataOverview,
    fetchSkillAssessmentConfig,
    fetchSelectedToolActionsPlans,
    fetchToolPoints,
    selectedCompany = {},
    user,
    location,
    homeRequestsLoadingInfo,
    fetchOkrConfiguration,
    fetchAllPeopleEvaluations,
    fetchAllToolsMaturityData,
    fetchAllActivityInformations,
    fetchAllUsersCompanyHierarchy,
    fetchAllSwotEvaluations,
    fetchAllToolsProjectEvaluations,
    fetchToolsGoalBonusConfigurations,
    fetchToolKpiConfigurations,
    fetchAllCompetitiveEvaluations,
    fetchToolsInsightsGoals,
    selectedTool,
    fetchEntityUsersWithRole,
    fetchUserProfile,
    killManagementProcess,
    isAuthenticated,
    authEssentials,
    setAuthInfo,
    logout,
    fetchCompanyNestedAreas,
    fetchCompanyNestedPositions,
  } = props;

  const history = useHistory();

  const {
    loginWithRedirect,
    redirectToHome = false,
    activeInterceptor = false,
  } = authEssentials || {};

  async function authValidation() {
    const checkAuthenticated = await checkIsAuthenticated();
    if (!checkAuthenticated) logout();
  }

  useEffect(() => {
    if (!isAuthenticated || !activeInterceptor) return;

    const instances = [axios, apiNode];
    setInterceptorInInstanceAxios(instances);
    authValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    const smartRedirect = localStorage.getItem("smartRedirect");
    const redirectTo = localStorage.getItem("redirectTo");

    if (isAuthenticated && smartRedirect) {
      localStorage.removeItem("smartRedirect");
      localStorage.removeItem("redirectTo");

      if (redirectTo === "login") return history.push("/");
      if (redirectTo) return history.push(`/${redirectTo}`);
    }

    if (!isAuthenticated && redirectTo) {
      const host = window.location.href;
      const redirectTo = host.split("/#/")[1];
      const route = host.split("/#").pop().split("?")[0];

      const routes = appRoutes();

      if (redirectTo?.length > 0 && redirectTo !== "logout") {
        const findRoute =
          routes.filter(({ path }) => {
            if (path === route) return true;

            return false;
          })[0] || false;

        if (!findRoute) return;

        const { requireLogin } = findRoute || {};

        if (requireLogin) {
          localStorage.setItem("redirectTo", redirectTo);
          localStorage.setItem("smartRedirect", true);
          loginWithRedirect({});
        }
      }
    }
  }, [history, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    if (redirectToHome) {
      setAuthInfo({ redirectToHome: false });
      history.push("/");
    }
  }, [history, redirectToHome, setAuthInfo]);
  // <TRIGGER_CONTROLLER>
  const triggerController = useCallback(
    (triggerType, payload) => {
      // MIDDLEWARE entre funções de: Essencial Requests, useEffect Triggers e App Actions
      // <TRIGGER_FUNCTIONS>

      const trigger_fetchWorkspacesToolRequests = async (
        companyId = null,
        toolTypes = [],
      ) => {
        if (companyId) {
          fetchAllCompanyToolsByType(companyId, toolTypes);
        }
      };

      const trigger_fetchCustomWorkspaceTools = async (
        toolTypeId,
        selectedToolIds = [],
      ) => {
        if (toolTypeId === PROCESSES_BIG_PICTURE) {
          await fetchAllToolsMaturityData(selectedToolIds);
          await fetchAllActivityInformations(selectedToolIds);

          const selectedCompanyId = localStorage.getItem(
            "SPLACE_SELECTED_COMPANY",
          );
          fetchAllUsersCompanyHierarchy(selectedCompanyId);
        }

        if (toolTypeId === PEOPLE_BIG_PICTURE) {
          await fetchAllPeopleEvaluations(selectedToolIds);
        }

        if (toolTypeId === SWOT) {
          await fetchAllSwotEvaluations(selectedToolIds);
        }

        if (toolTypeId === OKR_ID) {
          await fetchToolsInsightsGoals(selectedToolIds);
        }

        if (toolTypeId === PROJECT_PORTFOLIO) {
          await fetchAllToolsProjectEvaluations(selectedToolIds);
        }

        if (toolTypeId === GOALS_AND_BONUS) {
          await fetchToolsGoalBonusConfigurations(selectedToolIds);
        }

        if (toolTypeId === KPIS) {
          await fetchToolKpiConfigurations(selectedToolIds);
        }

        if (toolTypeId === COMPETITIVE_ANALYSIS) {
          await fetchAllCompetitiveEvaluations(selectedToolIds);
          fetchCompAnalysisManagementDataOverview(selectedToolIds);
        }
      };

      const trigger_fetchAllToolCustomInfo = async (workspacesTools = {}) => {
        await Promise.all(
          Object.keys(workspacesTools).map(async (toolTypeId) => {
            toolTypeId = parseInt(toolTypeId, 10);

            const selectedToolIds = [
              ...workspacesTools[toolTypeId].map(
                ({ selectedToolId }) => selectedToolId,
              ),
            ];

            await trigger_fetchCustomWorkspaceTools(
              toolTypeId,
              selectedToolIds,
            );
          }),
        );
      };

      const trigger_fetchCustomWorkspaceTool = async (
        toolTypeId,
        selectedToolId,
      ) => {
        if (!checkMultipleRoutes([location.pathname], ["/"])) {
          fetchToolPoints(selectedToolId);
        }

        if (toolTypeId === PROCESSES_BIG_PICTURE) {
          fetchAllMaturityData(selectedToolId);
          fetchAllActivityInformation(selectedToolId);
          fetchSelectedToolActionsPlans(selectedToolId);
          const selectedCompanyId = localStorage.getItem(
            "SPLACE_SELECTED_COMPANY",
          );
          fetchAllUsersCompanyHierarchy(selectedCompanyId);
        }

        if (toolTypeId === PEOPLE_BIG_PICTURE) {
          fetchAllPeopleEvaluation(selectedToolId);
          fetchSelectedToolActionsPlans(selectedToolId);
        }

        if (toolTypeId === SWOT) {
          fetchAllSwotEvaluation(selectedToolId);
          fetchSelectedToolActionsPlans(selectedToolId);
        }

        if (toolTypeId === OKR_ID) {
          fetchAllInsightsGoals(selectedToolId);
          fetchOkrConfiguration(selectedToolId);
          fetchSelectedToolActionsPlans(selectedToolId);
        }

        if (toolTypeId === PROJECT_PORTFOLIO) {
          fetchAllProjectEvaluations(selectedToolId);
          fetchProjectConfiguration(selectedToolId);
        }

        if (toolTypeId === PROJECT_PRIORITIZATION) {
          fetchProjectPrioritizations(selectedToolId);
          fetchAllInsightPrioritizations(selectedToolId);
        }

        if (toolTypeId === GOALS_AND_BONUS && user.id) {
          fetchToolGoalBonusConfiguration(selectedToolId, true, user.id);
          fetchSelectedToolActionsPlans(selectedToolId);
        }

        if (toolTypeId === KPIS && user.id) {
          fetchToolKpiConfiguration(selectedToolId, true, user.id);
          fetchSelectedToolActionsPlans(selectedToolId);
        }

        if (toolTypeId === COMPETITIVE_ANALYSIS) {
          fetchCompAnalysisAdministration(selectedToolId);
          fetchSelectedToolActionsPlans(selectedToolId);
          killManagementProcess();
        }
        if (toolTypeId === SKILLS_ASSESSMENT) {
          const selectedCompanyId = localStorage.getItem(
            "SPLACE_SELECTED_COMPANY",
          );
          await fetchCompanyNestedAreas(selectedCompanyId);
          await fetchCompanyNestedPositions(selectedCompanyId);
          await fetchSkillAssessmentConfig(selectedToolId, true);
        }
      };

      const trigger_selectedCompanyEssencial = async (companyId = null) => {
        if (companyId) {
          fetchCompanyMembers(companyId);
          fetchEntityUsersWithRole(companyId, "COMPANY");
          fetchAllCompanyWorkspaces(companyId);
          fetchMinifiedCompanyWorkspaces(companyId);
          fetchCompanyParticipants(companyId);
        }
      };

      const trigger_fetchHomeRequests = async (companyId = null) => {
        if (companyId) {
          const userLogin_ = localStorage.getItem("USER_LOGIN");
          await fetchUserProfile(userLogin_);
          await fetchAllCompanyWorkspaces(companyId);
          await fetchMinifiedCompanyWorkspaces(companyId);
        }
      };

      const trigger_structureRefresh = async (
        selectedToolId,
        toolTypeId,
        resetAll,
      ) => {
        if (selectedToolId)
          props.fetchToolTree(selectedToolId, toolTypeId, resetAll);
      };
      // </TRIGGER_FUNCTIONS>

      if (
        triggerType === "FETCH_WORKSPACE_TOOL_REQUESTS" &&
        checkMultipleRoutes([location.pathname], payload.validRoutes) &&
        !homeRequestsLoadingInfo.isLoading
      ) {
        trigger_fetchWorkspacesToolRequests(
          payload.companyId,
          payload.toolTypes,
        );
      }

      if (
        triggerType === "FETCH_ALL_TOOL_CUSTOM_INFO" &&
        checkMultipleRoutes([location.pathname], payload.validRoutes)
      ) {
        trigger_fetchAllToolCustomInfo(payload.tools);
      }

      if (triggerType === "SELECTED_COMPANY_ESSENCIAL") {
        trigger_selectedCompanyEssencial(payload.id);
      }

      if (triggerType === "CUSTOM_WORKSPACE_TOOL") {
        trigger_fetchCustomWorkspaceTool(payload.tool.id, payload.id);
      }

      if (triggerType === "REFRESH_TOOL") {
        const { selectedTool = {}, resetAll } = payload;

        trigger_fetchCustomWorkspaceTool(
          selectedTool?.tool?.id,
          selectedTool.id,
        );
        trigger_structureRefresh(
          selectedTool.id,
          selectedTool?.tool?.id,
          resetAll,
        );
      }

      if (
        triggerType === "FETCH_HOME_REQUESTS" &&
        checkRoute(location.pathname, "/")
      ) {
        trigger_fetchHomeRequests(payload.companyId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname, user.id, selectedTool],
  );
  // </TRIGGER_CONTROLLER>

  /*

  DOCS: single_nomeDaFuncao => Funções executadas por actions via Outside Components
  DOCS: trigger_nomeDaFuncao => Funções executadas por triggers

 */

  // <HANDLE_ACTIONS/>

  useEffect(() => {
    // Dentro do SWITCH deve ser executada apenas UMA ÚNICA função
    if (appActions.type) {
      switch (appActions.type) {
        case FETCH_WORKSPACES_TOOL_REQUESTS: {
          triggerController("FETCH_WORKSPACE_TOOL_REQUESTS", {
            companyId: appActions?.payload?.companyId,
            toolTypes: appActions?.payload?.toolTypes,
            validRoutes: getDashRoutes(appActions.payload.toolTypes),
          });
          break;
        }
        case FETCH_HOME_REQUESTS: {
          triggerController("FETCH_HOME_REQUESTS", {
            companyId: appActions?.payload?.companyId,
          });
          break;
        }
        case REFRESH_SELECTED_TOOL: {
          if (
            selectedTool.tool &&
            checkRoute(location.pathname, `/tool/${selectedTool.id}`)
          ) {
            triggerController("REFRESH_TOOL", {
              selectedTool,
              resetAll: appActions?.resetAll,
            });
          }
          break;
        }
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appActions]);
  // </HANDLE_ACTIONS>

  // <MAP_STATE_TRIGGERS>

  useEffect(() => {
    // Apenas funções de Trigger a partir de PROPS ATUALIZADAS via mapStateToProps
    const { allCompanyToolsType } = props;
    const toolIds = getOnlyObjKeys(allCompanyToolsType);

    triggerController("FETCH_ALL_TOOL_CUSTOM_INFO", {
      tools: allCompanyToolsType,
      validRoutes: getDashRoutes(toolIds),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allCompanyToolsType]);

  useEffect(() => {
    if (
      (isDiffProps("selectedTool") ||
        isDiffProps("location") ||
        isDiffProps("user")) &&
      selectedTool.tool &&
      checkRoute(location.pathname, `/tool/${selectedTool.id}`)
    ) {
      triggerController("CUSTOM_WORKSPACE_TOOL", selectedTool);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTool, location, triggerController, user]);

  useEffect(() => {
    if (isDiffProps("selectedCompany") && selectedCompany.id) {
      triggerController("SELECTED_COMPANY_ESSENCIAL", selectedCompany);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany, triggerController]);
  // </MAP_STATE_TRIGGERS>

  return null;
};

const mapStateToProps = (state) => {
  const {
    appActions = {},
    selectedTool,
    user,
    tools,
    access = {},
    auth: authEssentials = {},
  } = state;

  const { selectedDomainId = null, userAccess = {} } = access || {};

  const {
    allCompanyToolsType,
    selectedCompany,
    loadingInfo = {},
  } = getSelectedCompanyStates(state);

  return {
    authEssentials,
    access,
    selectedDomainId,
    appActions,
    allCompanyToolsType,
    homeRequestsLoadingInfo: loadingInfo,
    selectedTool,
    selectedCompany,
    user,
    onlyToolTypes:
      Array.isArray(tools) && tools?.length > 0
        ? tools.map(({ id }) => id)
        : [],
    userAccess,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchCompanyMembers,
    fetchCompanyParticipants,
    fetchAllCompanyWorkspaces,
    fetchMinifiedCompanyWorkspaces,
    fetchAllCompanyToolsByType,
    fetchAllMaturityData,
    fetchAllActivityInformation,
    fetchAllPeopleEvaluation,
    fetchAllSwotEvaluation,
    fetchAllProjectEvaluations,
    fetchProjectConfiguration,
    fetchProjectPrioritizations,
    fetchAllInsightPrioritizations,
    fetchToolGoalBonusConfiguration,
    fetchToolKpiConfiguration,
    fetchCompAnalysisAdministration,
    fetchCompAnalysisManagementDataOverview,
    fetchSkillAssessmentConfig,
    fetchSelectedToolActionsPlans,
    fetchCompanyNestedAreas,
    fetchCompanyNestedPositions,
    fetchAllInsightsGoals,
    fetchToolPoints,
    fetchCompanieTeams,
    fetchOkrConfiguration,
    fetchOkrConfigurations,
    fetchAllInsightsActionsPlans,
    fetchAllPeopleEvaluations,
    fetchAllToolsMaturityData,
    fetchAllActivityInformations,
    fetchAllUsersCompanyHierarchy,
    fetchAllSwotEvaluations,
    fetchAllToolsProjectEvaluations,
    fetchToolsGoalBonusConfigurations,
    fetchToolKpiConfigurations,
    fetchAllCompetitiveEvaluations,
    fetchToolsInsightsGoals,
    fetchCompetitiveAnalysisPoints,
    fetchEntityUsersWithRole,
    accessLevelsEssentials,
    setAuthInfo,
    logout,
    fetchUserProfile,
    killManagementProcess,
    fetchToolTree,
  }),
)(AppController);
