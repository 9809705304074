import PropTypes from "prop-types";
import { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { defineMessages, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Avatar from "../../Common/Avatar";

import { globalMessages } from "../../../utils/global";

import { fetchUserProfile } from "../../../actions/profileActions";

const userLoginInfo = localStorage.getItem("USER_LOGIN");

const UserMenu = (props) => {
  const { intl, profile } = props;

  useEffect(() => {
    props.fetchUserProfile(userLoginInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoginInfo]);

  function logout(e) {
    e.preventDefault();
    props.doLogout();
  }

  const messages = defineMessages({
    profile: {
      id: "user.profile",
    },
    logout: {
      id: "user.logout",
    },
  });

  const tooltipProfile = (
    <Tooltip id="tooltip_profile">
      {intl.formatMessage(globalMessages.profile)}
    </Tooltip>
  );

  const user = props.user;

  if (user) {
    const { firstName, lastName, email } = user;
    return (
      <li id="HeaderUserMenu" className="dropdown user user-menu">
        <OverlayTrigger placement="bottom" overlay={tooltipProfile}>
          <a
            className="dropdown-toggle"
            data-toggle="dropdown"
            style={{ display: "flex" }}
          >
            <Avatar
              src={profile?.avatarBlobId}
              style={{ width: "24px", height: "24px", margin: "0px" }}
            />
            &nbsp;<span className="hidden-xs">{firstName}</span>
          </a>
        </OverlayTrigger>
        <ul className="dropdown-menu">
          <li className="user-header">
            <Avatar
              src={profile?.avatarBlobId}
              style={{ width: "80px", height: "80px", margin: "0px auto" }}
            />
            <p>
              {`${firstName} ${lastName}`}
              <small>{email}</small>
            </p>
          </li>
          <li className="user-footer">
            <div className="pull-left">
              <Link className="btn btn-default btn-flat" to={props.profilePath}>
                <i className="fas fa-id-card-alt" aria-hidden="true" />
                &nbsp; {intl.formatMessage(messages.profile)}
              </Link>
            </div>
            <div className="pull-right">
              <Link className="btn btn-default btn-flat" to="" onClick={logout}>
                <i className="fas fa-sign-out-alt" aria-hidden="true" />
                &nbsp; {intl.formatMessage(messages.logout)}
              </Link>
            </div>
          </li>
        </ul>
      </li>
    );
  } else {
    return null;
  }
};

UserMenu.propTypes = {
  doLogout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  profilePath: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    avatars: state.avatars,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, { fetchUserProfile })(
  injectIntl(withRouter(UserMenu))
);
