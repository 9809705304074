import React from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import utils from "../../../../utils/toolUtils";
import { getOnlySlugValues } from "../../../../utils/ArrayUtils";
import actionsPlanUtils from "../../../../utils/actionsPlan";
import {
  getActionsPlanStates,
  insightsGoalState,
} from "../../../customMapStates";

import ActionsCounterBadge from "../../../Common/ActionPlan/evaluations/ActionsCounterBadge";

const {
  getTotalActionsByInsights,
  getDefaultEvaluationBadgeConfig,
} = actionsPlanUtils;

const {
  getToFixed,
  getOkrTotalEvaluation,
  getGoalEvaluationByID,
  getQuestionOkrTotal,
} = utils;

const messages = defineMessages({
  circleProgressGoalPercent0: {
    id: "circleProgress.goal.percent0",
  },
  circleProgressGoalPercent25: {
    id: "circleProgress.goal.percent25",
  },
  circleProgressGoalPercent50: {
    id: "circleProgress.goal.percent50",
  },
  circleProgressGoalPercent75: {
    id: "circleProgress.goal.percent75",
  },
  circleProgressGoalPercent100: {
    id: "circleProgress.goal.percent100",
  },
  circleProgressGoalCanceled: {
    id: "circleProgress.goal.canceled",
  },
});

const OkrCustomization = (props) => {
  const {
    intl,
    insightsToCalc,
    allInsightGoals,
    from,
    allActionsPlans,
    showHiddenExpectation,
    textMode = false,
  } = props;

  const getInsight = () => {
    return insightsToCalc[0] || {};
  };

  let badgeInfo = {
    className: "bg-nao-iniciada",
    label: "Não iniciada",
  };

  const singleInsightInfo = getInsight();
  const insightEvaluation = getGoalEvaluationByID(
    singleInsightInfo.id,
    allInsightGoals,
  );

  const { insightGoal = {} } = insightEvaluation;
  const { status, polarity, value, expectationStatus } = insightGoal;

  const okrValue = !showHiddenExpectation ? "reached" : "expectationValue";

  let displayPercentage = getToFixed(
    getOkrTotalEvaluation(value, insightGoal[okrValue], polarity),
  );

  if (from === "question" || from === "theme" || from === "cenary") {
    const evaluations = [];

    insightsToCalc.forEach((insightInfo) => {
      const goalEvaluation = getGoalEvaluationByID(
        insightInfo.id,
        allInsightGoals,
      );

      if (goalEvaluation && goalEvaluation.insightGoal)
        evaluations.push(goalEvaluation.insightGoal);
    });

    displayPercentage = getQuestionOkrTotal(evaluations, okrValue);
  }

  function getStatusOkr(status) {
    let newBadgeInfo = { className: "bg-nao-iniciada", label: "Não iniciada" };

    if (status === "CANCELADA")
      newBadgeInfo = {
        className: "bg-cancelada",
        label: intl.formatMessage(messages.circleProgressGoalCanceled),
      };

    if (status === "NAO_ATINGIDA")
      newBadgeInfo = {
        className: "bg-nao-atingida",
        label: intl.formatMessage(messages.circleProgressGoalPercent25),
      };

    if (status === "PARCIALMENTE_ATINGIDA")
      newBadgeInfo = {
        className: "bg-parcialmente-atingida",
        label: intl.formatMessage(messages.circleProgressGoalPercent50),
      };

    if (status === "ATINGIDA")
      newBadgeInfo = {
        className: "bg-atingida",
        label: intl.formatMessage(messages.circleProgressGoalPercent75),
      };

    if (status === "SUPERADA")
      newBadgeInfo = {
        className: "bg-superada",
        label: intl.formatMessage(messages.circleProgressGoalPercent100),
      };

    return newBadgeInfo;
  }

  badgeInfo = showHiddenExpectation
    ? getStatusOkr(expectationStatus)
    : getStatusOkr(status);

  const {
    hideBadge: hideActionsCounter,
    hideTitle: hideActionsCounterTitle,
  } = getDefaultEvaluationBadgeConfig(from);

  const onlyInsightIds = getOnlySlugValues(insightsToCalc, "id");

  if (textMode === true) {
    return {
      reached: displayPercentage,
      statusClass: badgeInfo.className,
      status: badgeInfo.label,
      ...{
        actions:
          !hideActionsCounter &&
          getTotalActionsByInsights(onlyInsightIds, allActionsPlans).length,
      },
    };
  }

  return (
    <div
      className="okr-evaluatiion-container"
      style={{ display: "flex", alignItems: "center" }}
    >
      <div
        className="badge answer bg-purple"
        style={{ height: "20px", marginRight: "10px" }}
      >{`${displayPercentage}%`}</div>
      {from !== "cenary" && from !== "theme" && from !== "question" && (
        <div
          className={`badge badge-status answer ${badgeInfo.className}`}
          style={{ padding: "0px !important", marginRight: "10px" }}
        >
          {badgeInfo.label}
        </div>
      )}
      {!hideActionsCounter && (
        <ActionsCounterBadge
          actions={
            getTotalActionsByInsights(onlyInsightIds, allActionsPlans).length
          }
          hideTitle={hideActionsCounterTitle}
        />
      )}
    </div>
  );
};

export { OkrCustomization as TextOkrCustomization };

const mapStateToProps = (state) => {
  const { toolsConfigurations } = state;

  const { allInsightGoals } = insightsGoalState(state);
  const { allActionsPlans = [] } = getActionsPlanStates(state);

  return {
    allInsightGoals,
    allActionsPlans,
    showHiddenExpectation: toolsConfigurations.showHiddenExpectation,
  };
};

export default connect(mapStateToProps, {})(injectIntl(OkrCustomization));
