import axios from "axios";
import {
  UPDATE_PROJECT_STAGE,
  PROJECT_MASS_UPDATE,
  DELETE_PROJECT_STAGE,
  GET_PROJECT_EVALUATION,
  UPDATE_PROJECT_EVALUATION,
  FETCH_ALL_PROJECT_EVALUATION,
  UPDATE_ALL_PROJECT_CONFIGURATIONS,
} from "./types";
import { fetchAllQuestionAnswers } from "./answersActions";
import { checkToolSwitchedAccesses } from "./accessControllerActions";
import { makeObjLevelAnArray } from "../utils/reducerUtils";

import { apiNode } from "../api";

export const getProjectChecklist = async (insight_id = "") => {
  const { data = [] } = await apiNode.get(`/api/v2/checklists/${insight_id}`);

  return data;
};

export const getProjectEvaluation = (insightID) => {
  return axios
    .get(`/api/insight/${insightID}/projectEvaluation`)
    .then((res) => res);
};

export const getProjectConfiguration = (selectedToolId = "") => {
  return axios
    .get(`/api/selected-tools/${selectedToolId}/projectConfiguration`)
    .then((res) => res.data);
};

export const getEvaluation = (insightId, callbacks) => {
  return async (dispatch) => {
    const { onSuccess = () => {}, onError = () => {} } = callbacks || {};

    try {
      const { data = {} } = await getProjectEvaluation(insightId);

      onSuccess(data);

      dispatch({
        type: GET_PROJECT_EVALUATION,
        payload: data,
      });
    } catch (err) {
      onError(err);
    }
  };
};

const addProjectConfiguration = (selectedToolId = "", stages = []) => {
  return axios
    .post(`/api/selected-tools/${selectedToolId}/projectConfiguration`, stages)
    .then((res) => res.data);
};

const addEvaluation = (insightId, body, callBack) => {
  const restBody =
    body && body.projectEvaluation ? body.projectEvaluation : body;

  return axios
    .post(`/api/insights/${insightId}/projectEvaluation`, restBody)
    .then((res) => {
      callBack(res);
      return res;
    });
};

export const addProjectEvaluation = (insightId, questionId, body, callBack) => {
  return async (dispatch) => {
    await getProjectEvaluation(insightId).then(async (res) => {
      const { projectEvaluation = {} } = body || {};

      if (!res.data) {
        await addEvaluation(insightId, body, callBack).then(async (res) => {
          await dispatch({
            type: UPDATE_PROJECT_EVALUATION,
            payload: res.data,
          });
          await dispatch(fetchAllQuestionAnswers([questionId]));
        });
      } else {
        await updateEvaluation(projectEvaluation, callBack).then(
          async (res) => {
            const { data = {} } = res || {};

            await dispatch({
              type: UPDATE_PROJECT_EVALUATION,
              payload: data,
            });
          },
        );
      }

      await dispatch(checkToolSwitchedAccesses());
    });
  };
};

const updateEvaluation = (body, callBack) => {
  return axios.put("/api/project-evaluations", body).then((res) => {
    callBack(res);
    return res;
  });
};

export const fetchAllToolsProjectEvaluations = (selectedToolIds = []) => {
  return async (dispatch) => {
    const toolsProjectEvaluations = await axios
      .get(`/api/selected-tools/allProjectEvaluations/${selectedToolIds}`)
      .then((res) => res.data);

    return dispatch({
      type: FETCH_ALL_PROJECT_EVALUATION,
      payload: makeObjLevelAnArray(toolsProjectEvaluations),
    });
  };
};

export const fetchAllProjectEvaluations = (selectedToolId) => {
  return async (dispatch) => {
    dispatch({
      type: "LOADING_PROJECT_EVALUATION",
      payload: { isLoading: true },
    });
    const fetchedRes = await axios.get(
      `/api/selected-tools/${selectedToolId}/projectEvaluations`,
    );

    dispatch({
      type: FETCH_ALL_PROJECT_EVALUATION,
      payload: fetchedRes.data,
    });
    dispatch({
      type: "LOADING_PROJECT_EVALUATION",
      payload: { isLoading: false },
    });
  };
};

export const sendProjectStage = (body = {}) => {
  const { id = false } = body || {};

  const stageBehavior = !id ? addProjectStage : updateProjectStage;

  return async (dispatch) => {
    const projectStageInfo = await stageBehavior(body);

    dispatch({ type: UPDATE_PROJECT_STAGE, payload: projectStageInfo });
  };
};

export const addProjectStage = (body = {}) => {
  return axios.post("/api/project-stages", body).then((res) => res.data);
};

export const updateProjectStage = (body = {}) => {
  return axios.put("/api/project-stages", body).then((res) => res.data);
};

export const getInitialStages = () => {
  return {
    stages: [
      {
        description: "PLANEJAMENTO",
        parentId: null,
      },
      {
        description: "ANDAMENTO",
        parentId: null,
      },
      {
        description: "CONCLUIDO",
        parentId: null,
      },
      {
        description: "STANDBY",
        parentId: null,
      },
      {
        description: "CANCELADO",
        parentId: null,
      },
    ],
  };
};

export const projectConfigurationBuilder = async (selectedToolId = "") => {
  return async (dispatch) => {
    try {
      return await getProjectConfiguration(selectedToolId);
    } catch (err) {
      const initialStages = getInitialStages();

      return await addProjectConfiguration(selectedToolId, initialStages);
    }
  };
};

export const deleteStage = (projectStageId = "") => {
  return axios.delete(`api/project-stages/${projectStageId}`).then((res) => {
    return res;
  });
};

export const dispatchDeleteStage = (
  projectStageId = "",
  projectConfigurationId = "",
) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_PROJECT_STAGE,
      payload: { projectStageId, projectConfigurationId },
    });
  };
};

export const deleteProjectStage = (
  projectStageId = "",
  projectConfigurationId = "",
) => {
  return async (dispatch) => {
    await deleteStage(projectStageId);

    dispatch({
      type: DELETE_PROJECT_STAGE,
      payload: { projectStageId, projectConfigurationId },
    });
  };
};

export const fetchProjectConfiguration = (selectedToolId = "") => {
  return async (dispatch) => {
    const projectConfiguration = await dispatch(
      projectConfigurationBuilder(selectedToolId),
    );

    dispatch({
      type: UPDATE_ALL_PROJECT_CONFIGURATIONS,
      payload: projectConfiguration,
    });
  };
};

export const projectSheetsMassUpdate = (
  updateObject = {},
  selectedToolId = "",
) => {
  const { evaluationInfo: sheet0 = [], milestoneInfo: sheet1 = [] } =
    updateObject;

  return async (dispatch) => {
    sheet0.length > 0 && (await dispatch(projectsMassUpdate(sheet0)));
    sheet1.length > 0 && (await dispatch(milestonesMassUpdate(sheet1)));
    await dispatch(fetchAllProjectEvaluations(selectedToolId));
  };
};

export const projectsMassUpdate = (updatePayloadInfo = []) => {
  return async (dispatch) => {
    const updatedProject = await axios
      .post("/api/insights/addProjectEvaluations", updatePayloadInfo)
      .then((res) => {
        return res.data;
      });

    await dispatch({ type: PROJECT_MASS_UPDATE, payload: [...updatedProject] });
  };
};

export const milestonesMassUpdate = (updatePayloadInfo = []) => {
  return async (dispatch) => {
    await axios
      .post("/api/project-activities/milestonesMassUpdate", updatePayloadInfo)
      .then((res) => {
        return res.data;
      });
  };
};

export const getChecklist = async (activity_id, insight_id) => {
  const response = await apiNode.get(
    `/api/v2/checklist/${activity_id}/${insight_id}`,
  );

  return response.data;
};

export const getChecklistByInsightId = async (insightId) => {
  const response = await apiNode.get(`/api/v2/checklist/insight/${insightId}`);

  return response.data;
};

export const postChecklist = async (checklist, insight_id) => {
  const response = await apiNode.post("/api/v2/checklist", {
    ...checklist,
    insight_id,
  });

  return response.data;
};

export const cloneChecklist = async (checklist) => {
  const response = await apiNode.post("/api/v2/checklist/clone", checklist);

  return response.data;
};

export const deleteChecklistTask = async (taskId) => {
  if (taskId) {
    await apiNode.delete(`/api/v2/checklist-task/${taskId}`);
  }
};

export const updateChecklist = async (task, insight_id) => {
  const response = await apiNode.put("/api/v2/checklist/", {
    ...task,
    insight_id,
  });

  return response.data;
};

export const deleteUnusedChecklist = async ({ activityIds }) => {
  if (activityIds.length) {
    const parsedActivities = JSON.stringify(activityIds);

    await apiNode.delete(`/api/v2/checklist/${parsedActivities}`);
  }
};

export const saveTemplateMilestone = async (body) => {
  const response = await axios.post("/api/project-milestone-templates", body);

  return response.data;
};

export const fetchTemplatesMilestones = async ({ domainId, workspaceId }) => {
  const response = await axios.get(
    `/api/project-milestone-templates?workspaceId=${workspaceId}&domainId=${domainId}`,
  );

  return response.data;
};

export const deleteTemplate = (templateId) => {
  axios.delete(`/api/project-milestone-templates/${templateId}`);
};

export const updateTemplate = async (updatedTemplate) => {
  await axios.put("/api/project-milestone-templates", updatedTemplate);
};
