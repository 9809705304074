import axios from "axios";

const getReportFollowup = (idTools) => {
  return axios
    .get(`/api/selected-tools/${idTools}/insightFollowups`)
    .then((res) => res);
};

export const fetchFollowupOkr = (idTools) => {
  return (dispatch) => {
    async function getReportFollowupData() {
      let followupData = {};

      if (idTools && idTools.length) {
        await Promise.all(
          idTools.map(async (id) => {
            const reportFollowup = await getReportFollowup(id);

            if (reportFollowup && reportFollowup.data) {
              followupData = {
                ...followupData,
                [id]: reportFollowup.data,
              };
            }
          }),
        );

        dispatch({
          type: "FETCH_REPORT_OKR_FOLLOWUP",
          payload: followupData,
        });
      }
    }
    getReportFollowupData();
  };
};
