import axios from "axios";
import { apiNode } from "../api";
import { buildRestAreaEntity } from "../utils/companyStructure/entity";
import { fetchUserCompanyProfile } from "./userActions";

export const toggleLoading =
  () =>
  (dispatch, isLoading = true) =>
    dispatch({
      type: "COMPANY_STRUCTURE_LOADING",
      payload: { isLoading },
    });

export const getCompanyArea = (areaId) => {
  return axios.get(`/api/company-areas/${areaId}`).then((res) => {
    return res;
  });
};

export const getCheckAreaExists = (areaId) => {
  return axios.get(`/api/company-areas/${areaId}/exists`).then((res) => {
    return res;
  });
};

export const getCompanyNestedAreas = (companyId) => {
  return axios
    .get(`/api/companies/${companyId}/areasWithSubAreas`)
    .then((res) => {
      return res;
    });
};

export const getAreaSubareas = (areaId) => {
  return axios.get(`/api/company-areas/${areaId}/sub-areas`).then((res) => {
    return res;
  });
};

export const postCompanyAreaStructure = (companyId, newAreaStructure) => {
  return axios
    .post(`/api/companies/${companyId}/area`, newAreaStructure)
    .then((res) => {
      return res;
    });
};

export const postCompanySubArea = (companyAreaId, newSubArea) => {
  return axios
    .post(`/api/company-areas/${companyAreaId}/sub-area`, newSubArea)
    .then((res) => {
      return res;
    });
};

export const putCompanyAreaStructure = (newAreaStructure) => {
  return axios.put("/api/company-areas", newAreaStructure).then((res) => {
    return res;
  });
};

export const fetchCompanyNestedAreas = (companyId) => {
  return async (dispatch) => {
    if (companyId) {
      dispatch(toggleLoading(true));

      const companyNestedAreas = await getCompanyNestedAreas(companyId);

      const companyNestedAreasData = companyNestedAreas.data;

      if (companyNestedAreasData) {
        dispatch({
          type: "FETCHED_COMPANY_AREAS",
          payload: { structure: companyNestedAreasData, companyId },
        });
      }

      dispatch(toggleLoading(false));
    }
  };
};

export const saveUpdateCompanyAreaStructure = (
  companyId,
  newAreaStructure = {},
) => {
  return async (dispatch) => {
    dispatch(toggleLoading(true));

    const restSaveAreaStructure = buildRestAreaEntity(newAreaStructure);

    if (companyId && restSaveAreaStructure.id) {
      const requestAreaStructureExists = await getCheckAreaExists(
        restSaveAreaStructure.id,
      );

      if (requestAreaStructureExists.data) {
        const updatedAreaStructure = await putCompanyAreaStructure(
          restSaveAreaStructure,
        );

        if (updatedAreaStructure.data)
          dispatch({
            type: "UPDATE_COMPANY_AREAS_STRUCTURE",
            payload: { structure: updatedAreaStructure.data, companyId },
          });
      }
    } else if (companyId) {
      const savedAreaStructure = await postCompanyAreaStructure(
        companyId,
        restSaveAreaStructure,
      );

      if (savedAreaStructure && savedAreaStructure.data)
        dispatch({
          type: "UPDATE_COMPANY_AREAS_STRUCTURE",
          payload: { structure: savedAreaStructure.data, companyId },
        });
    }
  };
};

export const saveUpdateSubArea = (
  companyId,
  companyAreaId,
  newSubArea = {},
) => {
  return async (dispatch) => {
    dispatch(toggleLoading(true));

    const restSaveSubArea = buildRestAreaEntity(newSubArea);

    if (companyId && companyAreaId && restSaveSubArea.id) {
      const requestSubAreaExists = await getCheckAreaExists(restSaveSubArea.id);

      if (requestSubAreaExists.data === true) {
        const updatedSubAreaStructure = await putCompanyAreaStructure(
          restSaveSubArea,
        );

        if (updatedSubAreaStructure.data)
          dispatch(fetchCompanyNestedAreas(companyId));
      }
    } else if (companyId && companyAreaId) {
      const savedSubArea = await postCompanySubArea(
        companyAreaId,
        restSaveSubArea,
      );

      if (savedSubArea && savedSubArea.data) {
        dispatch(fetchCompanyNestedAreas(companyId));
      }
    }
  };
};

export const postLinkUserToCompanyArea = (companyId, areaId, jhiUserId) => {
  return axios
    .post(`/api/companies/${companyId}/user/${jhiUserId}/area/${areaId}`)
    .then((res) => {
      return res;
    });
};

export const addUserToCompanyArea = (companyId, areaId, jhiUserId) => {
  return async (dispatch) => {
    if (companyId && areaId && jhiUserId) {
      const linkedUserToArea = await postLinkUserToCompanyArea(
        companyId,
        areaId,
        jhiUserId,
      );

      if (linkedUserToArea.data && linkedUserToArea.data.id) {
        dispatch(fetchUserCompanyProfile(jhiUserId, companyId));
      }
    }
  };
};

export const removeLinkUserOfCompanyArea = (companyId, areaId, jhiUserId) => {
  return axios
    .delete(`/api/companies/${companyId}/user/${jhiUserId}/area/${areaId}`)
    .then((res) => {
      return res;
    });
};

export const removeUserOfCompanyArea = (companyId, areaId, jhiUserId) => {
  return async (dispatch) => {
    if (companyId && areaId && jhiUserId) {
      const removedAreaLink = await removeLinkUserOfCompanyArea(
        companyId,
        areaId,
        jhiUserId,
      );

      if (removedAreaLink.data) {
        dispatch(fetchUserCompanyProfile(jhiUserId, companyId));
      }
    }
  };
};

export const deleteCompanyArea = (companyAreaId) => {
  return axios.delete(`/api/company-areas/${companyAreaId}`).then((res) => res);
};

export const fetchDeleteCompanyArea = (companyId, companyAreaId) => {
  return async (dispatch) => {
    if (companyId && companyAreaId) {
      const deletedArea = await deleteCompanyArea(companyAreaId);
      if (
        deletedArea &&
        (deletedArea.status === 200 || deletedArea.status === 204)
      )
        dispatch(fetchCompanyNestedAreas(companyId));
    }
  };
};

export const areasMassUpdate = (data = [], companyId = null) => {
  if (!companyId) return () => {};

  return async (dispatch) => {
    return axios
      .post(`/api/company-areas/${companyId}/updateAreas`, data)
      .then((res) => {
        const { data: structure = [] } = res;

        dispatch({
          type: "COMPANY_AREAS_MASS_UPDATE",
          payload: { structure, companyId },
        });
        dispatch(fetchCompanyNestedAreas(companyId));
      });
  };
};

export const saveMemberInArea = ({
  areaId,
  responsibleId,
  selectedCompanyId,
}) => {
  return async (dispatch) => {
    try {
      if (!areaId && !responsibleId) {
        throw new Error("Area/responsible does not exist");
      }

      await axios.post(
        `/api/company-areas/${areaId}/responsible/${responsibleId}`,
      );

      dispatch(fetchCompanyNestedAreas(selectedCompanyId));
    } catch (err) {
      console.log(err.message);
    }
  };
};

export async function getCompanyMembersHierarchy(companyId) {
  return apiNode.get(
    `/api/v2/company-structure/members-hierarchy/${companyId}`,
  );
}

export async function validateCompanyMembersHierarchy(
  companyId,
  imported_table,
) {
  return apiNode.post(
    `/api/v2/company-structure/members-hierarchy/validate/${companyId}`,
    { imported_table },
  );
}

export async function updateCompanyMembersHierarchy(companyId, imported_table) {
  return apiNode.post(
    `/api/v2/company-structure/members-hierarchy/update/${companyId}`,
    { imported_table },
  );
}
