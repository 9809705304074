const en = {
  swot_main_tile: "SWOT",
};

const es = {
  swot_main_tile: "SWOT",
};

const pt = {
  swot_main_tile: "SWOT",
};

const translations = { en, es, pt };

export default translations;
