import { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getCompAnalysisStates } from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";
import { doChangeQuestionName } from "../../../../actions/questionsActions";
import { buildFormAdmConfigBody } from "../../../../utils/cpAnalysis/entity";

const {
  getObjectDifference,
  getObjectInfoById,
  getOnlySlugValues,
  removeObjSlug,
} = utils;

const defaultForm = buildFormAdmConfigBody({});

const CompetitiveAnalysisWatcher = (props) => {
  const [cpAnalysisAdministration, setCpAnalysisAdministration] = useState(
    defaultForm,
  );
  const [firstValidation, setFirstValidation] = useState(false);

  const {
    currentAdministration = {},
    currentToolType = "",
    selectedTool = {},
    allQuestions = {},
    doChangeQuestionName,
  } = props;

  useEffect(() => {
    const toolTypeAdministration = currentAdministration[currentToolType];

    const toolTypeCpAnalysis = cpAnalysisAdministration[currentToolType];

    const isAdmObjDiff = !_.isEqual(toolTypeAdministration, toolTypeCpAnalysis);

    if (
      toolTypeAdministration &&
      toolTypeAdministration.analysisAreas &&
      Object.keys(allQuestions).length > 0
    ) {
      if (isAdmObjDiff || !firstValidation) {
        const compareBase = removeObjSlug(
          toolTypeCpAnalysis.analysisAreas,
          "id",
        );

        const diff = getObjectDifference(
          toolTypeAdministration.analysisAreas,
          compareBase,
        );

        if (diff) {
          if (!firstValidation) setFirstValidation(true);

          const watchGroups = getOnlySlugValues(diff, "id");

          if (watchGroups.length > 0) {
            Object.keys(allQuestions).forEach((themeId) => {
              const themeQuestions = allQuestions[themeId];

              themeQuestions.forEach((questionInfo) => {
                const {
                  competitiveAnalysisAreaId,
                  id: questionId = "",
                } = questionInfo;
                const relatedGroupId =
                  watchGroups[watchGroups.indexOf(competitiveAnalysisAreaId)] ||
                  null;

                if (relatedGroupId && questionInfo.text) {
                  const areaInfo = getObjectInfoById(
                    relatedGroupId,
                    toolTypeAdministration.analysisAreas,
                    "id",
                  );

                  if (areaInfo.title !== questionInfo.text) {
                    doChangeQuestionName(questionId, areaInfo.title);
                  }
                }
              });
            });
          }
        }

        if (isAdmObjDiff) setCpAnalysisAdministration(currentAdministration);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allQuestions,
    selectedTool.id,
    doChangeQuestionName,
    currentAdministration,
    cpAnalysisAdministration,
  ]);

  return null;
};

const mapStateToProps = (state) => {
  const {
    currentAdministration = {},
    currentToolType = "",
  } = getCompAnalysisStates(state);

  return { currentAdministration, currentToolType };
};

export default connect(mapStateToProps, { doChangeQuestionName })(
  CompetitiveAnalysisWatcher,
);
