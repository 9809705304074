import {
  INSIGHT_RESPONSIBLE_CHANGE,
  NEW_INSIGHT_COMMENT,
  INSIGHT_PRIORITIZATION_CHANGE,
  INSIGHT_CONNECTION_CHANGE,
  INSIGHT_ATTACHMENT_CHANGE,
  COMPANY_NEW_ADMIN,
  INSIGHT_NEW_MENTION,
  COMPANY_NEW_WORKSPACE,
  WORKSPACE_NEW_MODERATOR,
  WORKSPACE_NEW_TOOL,
  TOOL_NEW_MANAGER,
  TOOL_NEW_VIEWER,
  SCENERY_NEW_LEADER,
  SCENERY_NEW_VIEWER,
} from "./types";

import { defineMessages } from "react-intl";
import { translatedText } from "../../utils/translationUtils";

const translation = (id, values) => translatedText(id, messages, values);

const messages = defineMessages({
  insight_responsible_change: {
    id: "insight_responsible_change",
  },
  new_insight_comment: {
    id: "new_insight_comment",
  },
  insight_prioritization_change: {
    id: "insight_prioritization_change",
  },
  insight_connection_change: {
    id: "insight_connection_change",
  },
  insight_attachment_change: {
    id: "insight_attachment_change",
  },
  company_new_admin: {
    id: "company_new_admin",
  },
  insight_new_mention: {
    id: "insight_new_mention",
  },
  company_new_workspace: {
    id: "company_new_workspace",
  },
  workspace_new_moderator: {
    id: "workspace_new_moderator",
  },
  workspace_new_tool: {
    id: "workspace_new_tool",
  },
  tool_new_manager: {
    id: "tool_new_manager",
  },
  tool_new_viewer: {
    id: "tool_new_viewer",
  },
  scenery_new_leader: {
    id: "scenery_new_leader",
  },
  scenery_new_viewer: {
    id: "scenery_new_viewer",
  },
});

const dialogs = [
  {
    type: INSIGHT_RESPONSIBLE_CHANGE,
    translation: (values) => translation("insight_responsible_change", values),
  },
  {
    type: NEW_INSIGHT_COMMENT,
    translation: (values) => translation("new_insight_comment", values),
  },
  {
    type: INSIGHT_PRIORITIZATION_CHANGE,
    translation: (values) =>
      translation("insight_prioritization_change", values),
  },
  {
    type: INSIGHT_CONNECTION_CHANGE,
    translation: (values) => translation("insight_connection_change", values),
  },
  {
    type: INSIGHT_ATTACHMENT_CHANGE,
    translation: (values) => translation("insight_attachment_change", values),
  },
  {
    type: COMPANY_NEW_ADMIN,
    translation: (values) => translation("company_new_admin", values),
  },
  {
    type: COMPANY_NEW_WORKSPACE,
    translation: (values) => translation("company_new_workspace", values),
  },
  {
    type: WORKSPACE_NEW_MODERATOR,
    translation: (values) => translation("workspace_new_moderator", values),
  },
  {
    type: WORKSPACE_NEW_TOOL,
    translation: (values) => translation("workspace_new_tool", values),
  },
  {
    type: TOOL_NEW_MANAGER,
    translation: (values) => translation("tool_new_manager", values),
  },
  {
    type: TOOL_NEW_VIEWER,
    translation: (values) => translation("tool_new_viewer", values),
  },
  {
    type: SCENERY_NEW_LEADER,
    translation: (values) => translation("scenery_new_leader", values),
  },
  {
    type: SCENERY_NEW_VIEWER,
    translation: (values) => translation("scenery_new_viewer", values),
  },
  {
    type: INSIGHT_NEW_MENTION,
    translation: (values) => translation("insight_new_mention", values),
  },
];

export const getDialog = (type = "", values = {}) => {
  return (
    dialogs
      .filter((dialog) => dialog.type === type)[0]
      .translation(values)
      .replace(/'/g, "") || ""
  );
};
