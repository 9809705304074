import React, { useState } from "react";
import { defineMessages } from "react-intl";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import { Row } from "react-bootstrap";
import PropTypes from "prop-types";
import clsx from "clsx";

import StepLabel from "@material-ui/core/StepLabel";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import CheckIcon from "@material-ui/icons/Check";
import GetAppIcon from "@material-ui/icons/GetApp";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import FormDialog from "../../../../Common/FormDialog";
import PreviewTable from "../../../../Measurement/PreviewTable";
import { translatedText } from "../../../../../utils/translationUtils";
import ValidationInfo from "../../../../Measurement/ImportController/ValidationInfo";

const messages = defineMessages({
  measu_update_database: {
    id: "measu_update_database",
  },
  measu_something_went_wrong: {
    id: "measu_something_went_wrong",
  },
  measu_model_confirmation: {
    id: "measu_model_confirmation",
  },
  measu_model_validation: {
    id: "measu_model_validation",
  },
  measu_update: {
    id: "measu_update",
  },
  measu_is_this_the_correct_model: {
    id: "measu_is_this_the_correct_model",
  },
  measu_import_validated_data: {
    id: "measu_import_validated_data",
  },
  measu_unknown_step: {
    id: "measu_unknown_step",
  },
  measu_back: {
    id: "measu_back",
  },
  measu_importing_document: {
    id: "measu_importing_document",
  },
  measu_yes: {
    id: "measu_yes",
  },
});

const translation = (id, values) => translatedText(id, messages, values);

const initialValidation = {
  validatedData: [],
  nonValidatedData: [],
  loading: true,
};

const ImportController = ({
  callImportController = false,
  setCallImportController,
  resetFileInfo,
  fileInfo,
  fakeHeader,
  handleValidation,
  additionalData,
  handleMassUpdate,
  massUpdateAction,
  title,
  canImportWithErrors = false,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [validation, setValidation] = useState(initialValidation);
  const { fileData = {} } = fileInfo;
  const validationStep = 1;
  const isValidationStep = activeStep === validationStep;

  const displayOkMessage = () => {
    return (
      <div
        style={{
          backgroundColor: "#d4edda",
          color: "#477e53",
          textAlign: "center",
          fontWeight: "bold",
          padding: "10px",
          borderRadius: "4px",
          width: "80%",
          margin: "0 auto",
        }}
      >
        {translation("measu_update_database")}
      </div>
    );
  };

  const displayErrorMessage = () => {
    return (
      <div
        style={{
          backgroundColor: "#f8d7da",
          color: "#893c43",
          textAlign: "center",
          fontWeight: "bold",
          padding: "10px",
          borderRadius: "4px",
          width: "80%",
          margin: "0 auto",
        }}
      >
        {translation("measu_something_went_wrong")}
      </div>
    );
  };

  const modelPreviewConfirmation = () => {
    const { data = [] } = fileData;

    return <PreviewTable headers={fakeHeader} data={data} />;
  };

  const modelValidation = () => {
    return (
      <ValidationInfo
        validatedData={validation.validatedData}
        nonValidatedData={validation.nonValidatedData}
        loading={validation.loading}
        headers={fakeHeader}
      />
    );
  };

  const modelReadyToUpdate = () => {
    if (validation.validatedData.length > 0) return displayOkMessage();

    return displayErrorMessage();
  };

  const modelUpdate = async () => {
    if (validation.validatedData.length > 0) {
      const { selectedCompanyId = null } = additionalData || {};

      const restData = await handleMassUpdate(
        validation.validatedData,
        additionalData,
      );
      if (restData) massUpdateAction(restData, selectedCompanyId);
    }
  };

  const handleContent = () => {
    const content = [
      modelPreviewConfirmation,
      modelValidation,
      modelReadyToUpdate,
    ];

    if (callImportController) {
      return content[activeStep]();
    }

    return null;
  };

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      "& $line": {
        backgroundColor: "#5d8457",
      },
    },
    completed: {
      "& $line": {
        backgroundColor: "#5d8457",
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
    },
  })(StepConnector);

  const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: "#ccc",
      zIndex: 1,
      color: "#fff",
      width: 50,
      height: 50,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    active: {
      backgroundColor: "#5d8457",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
      backgroundColor: "#5d8457",
    },
  });

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
      1: <SettingsIcon fontSize="large" />,
      2: <CheckIcon fontSize="large" />,
      3: <GetAppIcon fontSize="large" />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: "18px",
    },
  }));

  function getSteps() {
    return [
      translation("measu_model_confirmation"),
      translation("measu_model_validation"),
      translation("measu_update"),
    ];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return translation("measu_is_this_the_correct_model");
      case 1:
        return translation("measu_import_validated_data");
      case 2:
        return "";
      default:
        return translation("measu_unknown_step");
    }
  }

  const onValidation = async () => {
    setValidation(initialValidation);

    const { data = [] } = fileData;
    const { validatedData, nonValidatedData } = await handleValidation(
      data,
      additionalData,
    );

    setValidation({
      validatedData,
      nonValidatedData,
      loading: false,
    });
  };

  const handleNext = async () => {
    const nextStep = activeStep + 1;
    setActiveStep(nextStep);

    if (nextStep === validationStep)
      setTimeout(() => {
        onValidation();
      }, 200);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const resetAllStates = () => {
    setActiveStep(0);
    setValidation(initialValidation);
    resetFileInfo();
  };

  const handleImportConfirmation = () => {
    setCallImportController(false);
    modelUpdate();
    resetAllStates();
  };

  const handleImportCancelation = () => {
    setCallImportController(false);
    resetAllStates();
  };

  const StepsToImport = () => {
    const classes = useStyles();
    const steps = getSteps();
    const disableNext =
      (isValidationStep && validation.loading) ||
      (!canImportWithErrors && validation.nonValidatedData.length > 0);

    return (
      <div className={classes.root}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <h5>{label}</h5>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Row>{handleContent()}</Row>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
              <div align="center">
                <Button
                  variant="contained"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  style={{ color: "#333", backgroundColor: "#ddd" }}
                  className={classes.button}
                >
                  {translation("measu_back")}
                </Button>
                {activeStep !== steps.length - 1 && (
                  <Button
                    variant="contained"
                    style={{
                      color: "#fff",
                      backgroundColor: !disableNext ? "#5d8457" : "#ccc",
                    }}
                    onClick={handleNext}
                    className={classes.button}
                    disabled={disableNext}
                  >
                    {translation("measu_yes")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const blockConfirm = () => {
    if (activeStep === 0 || isValidationStep) return true;
    return false;
  };

  return (
    <div>
      <FormDialog
        open={callImportController}
        title={title}
        onConfirm={handleImportConfirmation}
        onCancel={handleImportCancelation}
        bodyStyle={{ padding: "0 15px 15px 15px" }}
        dialogClassName="widthController"
        blockConfirm={blockConfirm()}
      >
        {StepsToImport()}
      </FormDialog>
    </div>
  );
};

export default ImportController;
