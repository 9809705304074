import isEmpty from "lodash/isEmpty";
import { SET_CURRENT_USER, SET_AUTH_INFO } from "../actions/types";

const initialState = {
  user: {},
  logout: () => null,
  isAuthenticated: false,
  loginWithRedirect: () => null,
  invitedUser: {},
  hideDomainNavigation: false,
};

const authReducer = (state = initialState, action) => {
  const { payload = {} } = action;

  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case SET_AUTH_INFO:
      return {
        ...state,
        ...(payload || {}),
      };
    case "HIDE_DOMAIN_NAVIGATION":
      return {
        ...state,
        hideDomainNavigation: payload?.hideDomainNavigation || false,
      };
    case "INVITED_USER":
      return {
        ...state,
        invitedUser: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
