import axios from "axios";
import { apiNode } from "../api";
import {
  insertCompanyMember,
  insertCompanyMemberForTeamMember,
} from "./memberActions";
import { getCompanyMembersHierarchy } from "./companyStructureActions";
import {
  FETCH_USER,
  FETCH_USER_BY_EMAIL,
  FETCH_USER_BY_CPF,
  USER_ID,
  USER_LOGIN,
  CHANGE_USER_PRIVACY_POLICY,
} from "./types";

export const sendAcceptance = (userId = "", body = {}) => {
  return axios
    .post(`/api/users/${userId}/addPrivacyPolicyData`, body)
    .then((res) => {
      return res.data;
    });
};

export const sendPrivacyPolicyAcceptance = (userId = "", body = {}) => {
  return async (dispatch) => {
    await sendAcceptance(userId, body);

    dispatch({
      type: CHANGE_USER_PRIVACY_POLICY,
      payload: true,
    });
  };
};

export const getUserEmailStatus = async (userId) => {
  return await axios
    .get(`/api/users/${userId}/acceptReceiveEmails`)
    .then((res) => res.data);
};
export const receiveEmails = (user = 0, receive = false) => {
  const { id = 0, login = "" } = user;

  return async (dispatch) => {
    return axios
      .post(`/api/users/${id}/acceptReceiveEmails/${receive}`)
      .then((res) => dispatch(fetchUser(login)));
  };
};

export const getUserCompanyProfiles = (userId, companyId) => {
  return axios
    .get(`/api/users/${userId}/company/${companyId}/profiles`)
    .then((res) => res);
};

export const getAllUsersCompanyProfiles = (companyId) => {
  return axios
    .get(`/api/companies/${companyId}/user-profiles`)
    .then((res) => res);
};

export const filterSelectedCompanyProfiles = (companyId) => {
  return {
    type: "FILTER_SELECT_COMAPY_PROFESSIONAL_PROFILES",
    companyId,
  };
};

export const fetchUserCompanyProfile = (userId, companyId) => {
  return async (dispatch) => {
    dispatch(filterSelectedCompanyProfiles(companyId));

    const userProfiles = await getUserCompanyProfiles(userId, companyId);

    if (userProfiles?.data) {
      dispatch({
        type: "USER_COMPANY_PROFESSIONAL_PROFILE",
        payload: { profile: userProfiles.data, companyId },
      });
    }
  };
};

export const fetchAllUsersCompanyProfiles = (companyId) => {
  return async (dispatch) => {
    const allUsersProfiles = await getAllUsersCompanyProfiles(companyId);

    dispatch(filterSelectedCompanyProfiles(companyId));

    if (allUsersProfiles.data && Array.isArray(allUsersProfiles.data)) {
      dispatch({
        type: "ALL_USER_COMPANY_PROFESSIONAL_PROFILE",
        payload: { allCompanyProfiles: allUsersProfiles.data },
      });
    }
  };
};

export const fetchAllUsersCompanyHierarchy = (companyId) => {
  return async (dispatch) => {
    const { data: allCompanyHierarchy } = await getCompanyMembersHierarchy(
      companyId,
    );

    dispatch({
      type: "ALL_COMPANY_HIERARCHY",
      payload: { allCompanyHierarchy },
    });
  };
};

export const fetchUser = (username) => {
  const ecodedUsername = encodeURIComponent(username);

  return (dispatch) => {
    return axios.get(`/api/users/${ecodedUsername}/`).then((res) => {
      const user = res.data;

      dispatch(setCurrentUser(user));
      localStorage.setItem(USER_ID, user.id);
      localStorage.setItem(USER_LOGIN, user.login);
    });
  };
};

export const getUserByEmail = (email) => {
  const encodedEmail = encodeURIComponent(email);
  return axios.get(`/api/users/email/${encodedEmail}/`).then((res) => res);
};

export const fetchUserByMail = (userEmail) => {
  return (dispatch) => {
    return getUserByEmail(userEmail).then((res) => {
      const { data: user = {} } = res || {};
      const { id: userId = "", login = "" } = user || {};

      dispatch({ type: FETCH_USER_BY_EMAIL, payload: res.data });
      localStorage.setItem(USER_ID, userId);
      localStorage.setItem(USER_LOGIN, login);
    });
  };
};

export const getUserByCpf = (cpf) => {
  const encodedCpf = encodeURIComponent(cpf);
  return axios.get(`/api/users/cpf/${encodedCpf}/`).then((res) => res);
};

export const fetchUserByCpf = (cpf) => {
  return async (dispatch) => {
    return getUserByCpf(cpf).then((res) => {
      const { data: user = {} } = res || {};
      const { id: userId = "", login = "" } = user || {};

      dispatch({ type: FETCH_USER_BY_CPF, payload: res.data });
      localStorage.setItem(USER_ID, userId);
      localStorage.setItem(USER_LOGIN, login);
    });
  };
};

export const searchUsers = (term) => {
  return axios.post(`/api/searchMembersParticipants/${term}`).then((res) => {
    return res.data;
  });
};

export const addMemberToCompany = (company_id, user_email) => {
  const encodedEmail = encodeURIComponent(user_email);
  let member_ID = null;
  return (dispatch) => {
    return axios.get(`/api/users/email/${encodedEmail}/`).then(async (res) => {
      const { grantCompanyRestrictAccess } = await import(
        "./accessControllerActions"
      );

      member_ID = res.data.id;
      dispatch(insertCompanyMember(company_id, [member_ID]));
      dispatch(grantCompanyRestrictAccess(company_id, member_ID));
    });
  };
};

export const insertCompanyAndTeamMember = (
  memberToAddToCompany,
  selectedTeam,
  selectedCompany,
) => {
  let member_ID = null;
  const encodedEmail = encodeURIComponent(memberToAddToCompany.email);
  return (dispatch) => {
    return axios.get(`/api/users/email/${encodedEmail}/`).then((res) => {
      member_ID = res.data.id;
      dispatch(
        insertCompanyMemberForTeamMember(
          member_ID,
          selectedTeam,
          selectedCompany,
        ),
      );
    });
  };
};

export const activateAccount = (key) => {
  return axios.get(`/api/activate/${key}`).then((res) => {
    return res.data;
  });
};

export const reactivateAccount = (email) => {
  const encodedEmail = encodeURIComponent(email);
  return axios.post(`/api/account/reactivate/${encodedEmail}/`).then((res) => {
    return res.data;
  });
};

export const setCurrentUser = (user) => {
  return { type: FETCH_USER, payload: user };
};

export const resetPassword = (email) => {
  const encodedEmail = encodeURIComponent(email);
  return axios
    .post(`/api/account/reset-password/init/${encodedEmail}/`)
    .then((res) => {
      return res.data;
    });
};

export const finishPasswordReset = (keyAndPassswordVM) => {
  return axios
    .post("/api/account/reset-password/finish", keyAndPassswordVM)
    .then((res) => {
      return res.data;
    });
};

export const resetPasswordWithCpf = (cpf) => {
  return apiNode.post("/api/v4/auth/cpf/requestCode", cpf).then((res) => {
    return res.data;
  });
};

export const userCanAccessCompany = async (userId, companyId) => {
  return axios
    .get(`/api/users/${userId}/companies/${companyId}/userCanAccessCompany`)
    .then((res) => {
      return res.data;
    });
};
