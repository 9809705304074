import React from "react";
import PropTypes from "prop-types";
import SelectTreeModal from "../../../../../../Common/SelectTreeModal";

const SelectProfile = ({
  open,
  profiles,
  initialSelected,
  onConfirm,
  onCancel,
  singleSelect,
}) => {
  const treeList = {
    profiles: {
      label: "Perfis",
      list: [
        ...profiles.map(({ id, title }) => {
          return { id, title, parentId: null };
        }),
      ],
      countText: "perfis",
      color: "#333",
      icon: <i className="fas fa-address-card" />,
    },
  };

  return (
    <>
      {open && (
        <SelectTreeModal
          open={open}
          title="Selecione os Perfis"
          treeList={treeList}
          treeOrder={["profiles"]}
          initialSelected={initialSelected}
          onConfirm={(selected) => onConfirm(selected)}
          onCancel={onCancel}
          singleSelect={singleSelect}
          canSelectAnyLevel
        />
      )}
    </>
  );
};

SelectProfile.propTypes = {
  open: PropTypes.bool,
  profiles: PropTypes.array.isRequired,
  initialSelected: PropTypes.array,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  singleSelect: PropTypes.bool,
};

SelectProfile.defaultProps = {
  open: false,
  singleSelect: false,
  initialSelected: [],
};

export default SelectProfile;
