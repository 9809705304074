import { defineMessages } from "react-intl";

const messages = defineMessages({
  gender_male: {
    id: "pb_gender_male",
  },
  gender_female: {
    id: "pb_gender_female",
  },
  area_financial: {
    id: "pb_area_financial",
  },
  area_administrative: {
    id: "pb_area_administrative",
  },
  area_human_resources: {
    id: "pb_area_human_resources",
  },
  area_information_technology: {
    id: "pb_area_information_technology",
  },
  area_planning: {
    id: "pb_area_planning",
  },
  area_marketing: {
    id: "pb_area_marketing",
  },
  area_commercial: {
    id: "pb_area_commercial",
  },
  area_support: {
    id: "pb_area_support",
  },
  area_operational: {
    id: "pb_area_operational",
  },
  area_production: {
    id: "pb_area_production",
  },
  area_services: {
    id: "pb_area_services",
  },
  role_trainee: {
    id: "pb_role_trainee",
  },
  role_assistant: {
    id: "pb_role_assistant",
  },
  role_analyst: {
    id: "pb_role_analyst",
  },
  role_technician: {
    id: "pb_role_technician",
  },
  role_coordination: {
    id: "pb_role_coordination",
  },
  role_management: {
    id: "pb_role_management",
  },
  role_board_of_directors: {
    id: "pb_role_board_of_directors",
  },
  role_executive: {
    id: "pb_role_executive",
  },
  market_evaluation_below: {
    id: "pb_market_evaluation_below",
  },
  market_evaluation_according: {
    id: "pb_market_evaluation_according",
  },
  market_evaluation_above: {
    id: "pb_market_evaluation_above",
  },
  salary_up_3k: {
    id: "pb_salary_up_3k",
  },
  salary_up_4k: {
    id: "pb_salary_up_4k",
  },
  salary_up_5k: {
    id: "pb_salary_up_5k",
  },
  salary_up_10k: {
    id: "pb_salary_up_10k",
  },
  salary_more_10k: {
    id: "pb_salary_more_10k",
  },
  education_elementary: {
    id: "pb_education_elementary",
  },
  education_technical: {
    id: "pb_education_technical",
  },
  education_graduation: {
    id: "pb_education_graduation",
  },
  education_postgraduate: {
    id: "pb_education_postgraduate",
  },
  education_masters: {
    id: "pb_education_masters",
  },
  education_doctorate: {
    id: "pb_education_doctorate",
  },
  languages_portuguese: {
    id: "pb_languages_portuguese",
  },
  languages_english: {
    id: "pb_languages_english",
  },
  languages_spanish: {
    id: "pb_languages_spanish",
  },
  languages_others: {
    id: "pb_languages_others",
  },
  salary_one_year: {
    id: "pb_salary_one_year",
  },
  salary_two_years: {
    id: "pb_salary_two_years",
  },
  salary_three_years: {
    id: "pb_salary_three_years",
  },
  salary_more_three_years: {
    id: "pb_salary_more_three_years",
  },
  performance_above: {
    id: "pb_performance_above",
  },
  performance_according: {
    id: "pb_performance_according",
  },
  performance_below: {
    id: "pb_performance_below",
  },
  leading_1: {
    id: "pb_leading_1",
  },
  leading_2: {
    id: "pb_leading_2",
  },
  leading_3: {
    id: "pb_leading_3",
  },
  leading_4: {
    id: "pb_leading_4",
  },
  leading_5: {
    id: "pb_leading_5",
  },
  leading_6: {
    id: "pb_leading_6",
  },
  leading_7: {
    id: "pb_leading_7",
  },
  leading_8: {
    id: "pb_leading_8",
  },
  leading_9: {
    id: "pb_leading_9",
  },
  interest_change_area_project: {
    id: "pb_interest_change_area_project",
  },
  interest_short_change_area_project: {
    id: "pb_interest_short_change_area_project",
  },
  interest_develop_more_in_area: {
    id: "pb_interest_develop_more_in_area",
  },
  interest_short_develop_more_in_area: {
    id: "pb_interest_short_develop_more_in_area",
  },
  interest_expand_in_area: {
    id: "pb_interest_expand_in_area",
  },
  interest_short_expand_in_area: {
    id: "pb_interest_short_expand_in_area",
  },
  readiness_ready: {
    id: "pb_readiness_ready",
  },
  readiness_short_term_ready: {
    id: "pb_readiness_short_term_ready",
  },
  readiness_medium_term_ready: {
    id: "pb_readiness_medium_term_ready",
  },
  readiness_develop: {
    id: "pb_readiness_develop",
  },
  readiness_keep: {
    id: "pb_readiness_keep",
  },
  readiness_reassess: {
    id: "pb_readiness_reassess",
  },
  career_trail: {
    id: "pb_career_trail",
  },
  career_job: {
    id: "pb_career_job",
  },
  evaluations_immediate: {
    id: "pb_evaluations_immediate",
  },
  evaluations_short_term: {
    id: "pb_evaluations_short_term",
  },
  evaluations_long_term: {
    id: "pb_evaluations_long_term",
  },
  evaluations_keep: {
    id: "pb_evaluations_keep",
  },
  table_lb_evaluations_average: {
    id: "pb_table_lb_evaluations_average",
  },
  table_lb_evaluations_performance: {
    id: "pb_table_lb_evaluations_performance",
  },
  table_lb_evaluations_leading: {
    id: "pb_table_lb_evaluations_leading",
  },
  table_lb_evaluations_interest: {
    id: "pb_table_lb_evaluations_interest",
  },
  career_main: {
    id: "pb_career_main",
  },
  career_option: {
    id: "pb_career_option",
  },
});

const getGenderOptions = (intl) => {
  return [
    { value: "MALE", label: intl && intl.formatMessage(messages.gender_male) },
    {
      value: "FEMALE",
      label: intl && intl.formatMessage(messages.gender_female),
    },
  ];
};

const getAreaOptions = (intl) => {
  return [
    {
      value: "FINANCIAL",
      label: intl && intl.formatMessage(messages.area_financial),
    },
    {
      value: "ADMINISTRATIVE",
      label: intl && intl.formatMessage(messages.area_administrative),
    },
    {
      value: "HUMAN_RESOURCES",
      label: intl && intl.formatMessage(messages.area_human_resources),
    },
    {
      value: "INFORMATION_TECHNOLOGY",
      label: intl && intl.formatMessage(messages.area_information_technology),
    },
    {
      value: "PLANNING",
      label: intl && intl.formatMessage(messages.area_planning),
    },
    {
      value: "MARKETING",
      label: intl && intl.formatMessage(messages.area_marketing),
    },
    {
      value: "COMMERCIAL",
      label: intl && intl.formatMessage(messages.area_commercial),
    },
    {
      value: "SUPPORT",
      label: intl && intl.formatMessage(messages.area_support),
    },
    {
      value: "OPERATIONAL",
      label: intl && intl.formatMessage(messages.area_operational),
    },
    {
      value: "PRODUCTION",
      label: intl && intl.formatMessage(messages.area_production),
    },
    {
      value: "SERVICES",
      label: intl && intl.formatMessage(messages.area_services),
    },
  ];
};

const getRoleOptions = (intl) => {
  return [
    { value: "AUX", label: intl && intl.formatMessage(messages.role_trainee) },
    {
      value: "ASSISTANT",
      label: intl && intl.formatMessage(messages.role_assistant),
    },
    {
      value: "ANALYST",
      label: intl && intl.formatMessage(messages.role_analyst),
    },
    {
      value: "TECHNICIAN",
      label: intl && intl.formatMessage(messages.role_technician),
    },
    {
      value: "COORDINATION",
      label: intl && intl.formatMessage(messages.role_coordination),
    },
    {
      value: "MANAGEMENT",
      label: intl && intl.formatMessage(messages.role_management),
    },
    {
      value: "BOARD_OF_DIRECTORS",
      label: intl && intl.formatMessage(messages.role_board_of_directors),
    },
    {
      value: "EXECUTIVE",
      label: intl && intl.formatMessage(messages.role_executive),
    },
  ];
};

const getMarketEvaluationOptions = (intl) => {
  return [
    {
      value: "BELOW",
      label: intl.formatMessage(messages.market_evaluation_below),
    },
    {
      value: "ACCORDING",
      label: intl.formatMessage(messages.market_evaluation_according),
    },
    {
      value: "ABOVE",
      label: intl.formatMessage(messages.market_evaluation_above),
    },
  ];
};

const getSalaryRange = (intl) => {
  return [
    { value: "UP_3K", label: intl.formatMessage(messages.salary_up_3k) },
    { value: "UP_4K", label: intl.formatMessage(messages.salary_up_4k) },
    { value: "UP_5K", label: intl.formatMessage(messages.salary_up_5k) },
    { value: "UP_10K", label: intl.formatMessage(messages.salary_up_10k) },
    {
      value: "MORE_THAN_10K",
      label: intl.formatMessage(messages.salary_more_10k),
    },
  ];
};

const getEducationOptions = (intl) => {
  return [
    {
      value: "ELEMENTARY",
      label: intl && intl.formatMessage(messages.education_elementary),
    },
    {
      value: "TECHNICAL",
      label: intl && intl.formatMessage(messages.education_technical),
    },
    {
      value: "GRADUATION",
      label: intl && intl.formatMessage(messages.education_graduation),
    },
    {
      value: "POSTGRADUATE",
      label: intl && intl.formatMessage(messages.education_postgraduate),
    },
    {
      value: "MASTERS",
      label: intl && intl.formatMessage(messages.education_masters),
    },
    {
      value: "DOCTORATE",
      label: intl && intl.formatMessage(messages.education_doctorate),
    },
  ];
};

const getLanguageOptions = (intl) => {
  return [
    {
      value: "PORTUGUESE",
      label: intl && intl.formatMessage(messages.languages_portuguese),
    },
    {
      value: "ENGLISH",
      label: intl && intl.formatMessage(messages.languages_english),
    },
    {
      value: "SPANISH",
      label: intl && intl.formatMessage(messages.languages_spanish),
    },
    {
      value: "OTHERS",
      label: intl && intl.formatMessage(messages.languages_others),
    },
  ];
};

const getSalaryModificationOptions = (intl) => {
  return [
    { value: "ONE_YEAR", label: intl.formatMessage(messages.salary_one_year) },
    {
      value: "TWO_YEARS",
      label: intl.formatMessage(messages.salary_two_years),
    },
    {
      value: "THREE_YEARS",
      label: intl.formatMessage(messages.salary_three_years),
    },
    {
      value: "MORE_THAN_3_YEARS",
      label: intl.formatMessage(messages.salary_more_three_years),
    },
  ];
};

const getPerfomanceEvaluationOptions = (intl) => {
  return [
    {
      value: "BELOW_EXPECTED",
      label: intl && intl.formatMessage(messages.performance_below),
    },
    {
      value: "AS_EXPECTED",
      label: intl && intl.formatMessage(messages.performance_according),
    },
    {
      value: "ABOVE_EXPECTED",
      label: intl && intl.formatMessage(messages.performance_above),
    },
  ];
};

const getCompanyPositionOptions = (allPositions) => {
  function mountPositionsOptions(positions) {
    if (positions) {
      return positions.reduce((accumulator, position) => {
        const positionChild = mountPositionsOptions(position.childPositions);

        return accumulator.concat(positionChild, [
          { id: position.id, title: position.title },
        ]);
      }, []);
    }

    return [];
  }

  return mountPositionsOptions(allPositions);
};

const getLeadingOptions = (intl) => {
  return [
    {
      value: "OUTPUT_POSITION",
      label: intl && intl.formatMessage(messages.leading_9),
    },
    {
      value: "LOW_PERFORMANCE",
      label: intl && intl.formatMessage(messages.leading_8),
    },
    {
      value: "EFFECTIVE",
      label: intl && intl.formatMessage(messages.leading_6),
    },
    { value: "NEW", label: intl && intl.formatMessage(messages.leading_7) },
    { value: "GROWING", label: intl && intl.formatMessage(messages.leading_5) },
    {
      value: "GOOD_POTENTIAL",
      label: intl && intl.formatMessage(messages.leading_3),
    },
    {
      value: "MEDIUM_PERFORMANCE",
      label: intl && intl.formatMessage(messages.leading_4),
    },

    {
      value: "HIGH_PERFORMANCE",
      label: intl && intl.formatMessage(messages.leading_2),
    },
    {
      value: "HIGH_POTENTIAL",
      label: intl && intl.formatMessage(messages.leading_1),
    },
  ];
};

const getInterestOptions = (intl, short = false) => {
  return [
    {
      value: "CHANGE_AREA_PROJECT",
      label: intl.formatMessage(
        !short
          ? messages.interest_change_area_project
          : messages.interest_short_change_area_project,
      ),
    },
    {
      value: "DEVELOP_MORE_IN_AREA",
      label: intl.formatMessage(
        !short
          ? messages.interest_develop_more_in_area
          : messages.interest_short_develop_more_in_area,
      ),
    },
    {
      value: "EXPAND_IN_AREA",
      label: intl.formatMessage(
        !short
          ? messages.interest_expand_in_area
          : messages.interest_short_expand_in_area,
      ),
    },
  ];
};

const getReadinessOptions = (intl) => {
  return [
    {
      value: "READY",
      label: intl.formatMessage(messages.readiness_ready),
      color: "#00b050",
    },
    {
      value: "SHORT_TERM_READY",
      label: intl.formatMessage(messages.readiness_short_term_ready),
      color: "#93d155",
    },
    {
      value: "MEDIUM_TERM_READY",
      label: intl.formatMessage(messages.readiness_medium_term_ready),
      color: "#d9f36e",
    },
    {
      value: "DEVELOP",
      label: intl.formatMessage(messages.readiness_develop),
      color: "#b2cbdb",
    },
    {
      value: "KEEP",
      label: intl.formatMessage(messages.readiness_keep),
      color: "#eb9932",
    },
    {
      value: "REASSESS",
      label: intl.formatMessage(messages.readiness_reassess),
      color: "#f56160",
    },
  ];
};

const getSubtitleColorBySlug = (slug, intl) => {
  let finalColor = null;
  const readinessOptions = getReadinessOptions(intl);

  readinessOptions.forEach((option) => {
    if (option.value === slug) finalColor = option.color;
  });

  return finalColor;
};

const getCareerPathOptions = (intl) => {
  return [
    { value: "TRAIL", label: intl.formatMessage(messages.career_trail) },
    { value: "JOB", label: intl.formatMessage(messages.career_job) },
  ];
};

const getRoleEvaluationOptions = (intl) => {
  return [
    {
      value: "IMMEDIATE",
      label: intl && intl.formatMessage(messages.evaluations_immediate),
    },
    {
      value: "SHORT_TERM",
      label: intl && intl.formatMessage(messages.evaluations_short_term),
    },
    {
      value: "LONG_TERM",
      label: intl && intl.formatMessage(messages.evaluations_long_term),
    },
    {
      value: "KEEP",
      label: intl && intl.formatMessage(messages.evaluations_keep),
    },
  ];
};

const getSalaryEvaluationOptions = (intl) => {
  return [
    {
      value: "IMMEDIATE",
      label: intl.formatMessage(messages.evaluations_immediate),
    },
    {
      value: "SHORT_TERM",
      label: intl.formatMessage(messages.evaluations_short_term),
    },
    {
      value: "LONG_TERM",
      label: intl.formatMessage(messages.evaluations_long_term),
    },
    { value: "KEEP", label: intl.formatMessage(messages.evaluations_keep) },
  ];
};

const getTableLastEvaluationLabels = (intl) => {
  return {
    average: intl.formatMessage(messages.table_lb_evaluations_average),
    performance: intl.formatMessage(messages.table_lb_evaluations_performance),
    leading: intl.formatMessage(messages.table_lb_evaluations_leading),
    interest: intl.formatMessage(messages.table_lb_evaluations_interest),
  };
};

const getTableCarrearPathLabels = (intl) => {
  return {
    mainTrail: intl.formatMessage(messages.career_main),
    secondTrail: `${intl.formatMessage(messages.career_option)} 2`,
    thirdTrail: `${intl.formatMessage(messages.career_option)} 3`,
    fourthTrail: `${intl.formatMessage(messages.career_option)} 4`,
    fifthTrail: `${intl.formatMessage(messages.career_option)} 5`,
  };
};

const getInitialStates = (intl) => {
  const professionalProfile = {
    name: "",
    birthDate: "",
    area: { value: "", details: "" },
    gender: "",
    role: { value: "", details: "" },
    entryDate: "",
    education: { level: "", details: "" },
    fluentLanguages: [],
  };

  const salaryTrajectory = {
    remuneration: "",
    marketEvaluation: "",
    averageMarketEvaluation: "",
    remunerationHistories: [],
    professionalAscentions: [],
  };

  const evaluationTarget = {
    lastEvaluations: {
      average: "",
      performance: "",
      loading: "",
      interest: "",
    },
    carrearPath: {
      mainTrail: "",
      secondTrail: "",
      thirdTrail: "",
      fourthTrail: "",
      fifthTrail: "",
    },
    salaryEvaluation: "",
    roleEvaluation: "",
  };

  return { professionalProfile, salaryTrajectory, evaluationTarget };
};

const defaultProfessionalAscentionForm = {
  fromDate: "",
  toDate: "",
  fromRole: "",
  toRole: "",
  fromArea: "",
  toArea: "",
  currentRole: false,
  changedArea: false,
  professionalLeader: null,
  fromPositionText: "",
  toPositionText: "",
  fromAreaText: "",
  toAreaText: "",
};

const defaultSalaryHistoryForm = {
  date: "",
  adjustedValue: "",
  reason: "",
};

const defaultDeletingAction = {
  type: null,
  title: "",
  onDelete: () => null,
};

const defaultEditingModal = {
  type: null,
  confirmFunction: () => null,
};

export {
  getGenderOptions,
  getRoleOptions,
  getAreaOptions,
  getMarketEvaluationOptions,
  getSalaryRange,
  getEducationOptions,
  getLanguageOptions,
  getSalaryModificationOptions,
  getPerfomanceEvaluationOptions,
  getLeadingOptions,
  getInterestOptions,
  getReadinessOptions,
  getSubtitleColorBySlug,
  getCareerPathOptions,
  getRoleEvaluationOptions,
  getSalaryEvaluationOptions,
  getTableCarrearPathLabels,
  getTableLastEvaluationLabels,
  getInitialStates,
  defaultProfessionalAscentionForm,
  defaultSalaryHistoryForm,
  defaultDeletingAction,
  defaultEditingModal,
  getCompanyPositionOptions,
};
