export const getMemberInfoById = (memberId, allMembers = []) => {
  let finalInfo = {};

  for (const memberInfo of allMembers) {
    if (memberId === memberInfo.id) {
      finalInfo = memberInfo;
      break;
    }
  }

  return finalInfo;
};

export const getFilteredMembers = (memberIds, allMembers = []) => {
  let finalMembers = [];

  allMembers.forEach(memberInfo => {
    if (memberIds.indexOf(memberInfo.id) > -1) finalMembers.push(memberInfo);
  });

  return finalMembers;
};
