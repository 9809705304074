import { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getGoalsBonusStates } from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";
import gbUtils from "../../../../utils/goalsBonus";
import { updateCenary } from "../../../../actions/cenaryActions";

const {
  getObjectDifference,
  getObjectInfoById,
  getOnlySlugValues,
  removeObjSlug,
} = utils;

const { buildFormAdmConfigBody, getGoalsBonusBySelectedToolId } = gbUtils;

const defaultFormInitialGoalBonus = buildFormAdmConfigBody({});

const GoalsBonusWatcher = (props) => {
  const [goalBonusAdministration, setGoalBonusAdministration] = useState(
    defaultFormInitialGoalBonus,
  );
  const [firstValidation, setFirstValidation] = useState(false);

  const {
    allGoalBonusAdministration = [],
    selectedTool = {},
    allSceneries = [],
    updateCenary,
  } = props;

  useEffect(() => {
    let toolGoalsBonusAdm = getGoalsBonusBySelectedToolId(
      selectedTool.id,
      allGoalBonusAdministration,
    );
    toolGoalsBonusAdm = buildFormAdmConfigBody(toolGoalsBonusAdm);

    const isAdmObjDiff = !_.isEqual(toolGoalsBonusAdm, goalBonusAdministration);

    if (
      toolGoalsBonusAdm &&
      toolGoalsBonusAdm.teams &&
      allSceneries.length > 0
    ) {
      if (isAdmObjDiff || !firstValidation) {
        const compareBase = removeObjSlug(goalBonusAdministration.teams, "id");

        const diff = getObjectDifference(toolGoalsBonusAdm.teams, compareBase);

        if (diff) {
          if (!firstValidation) setFirstValidation(true);

          const watchTeams = getOnlySlugValues(diff, "id");

          if (watchTeams.length > 0) {
            allSceneries.forEach((scenaryInfo) => {
              const { goalsBonusProgramTeamId } = scenaryInfo;
              const relatedTeamId =
                watchTeams[watchTeams.indexOf(goalsBonusProgramTeamId)] || null;

              if (relatedTeamId && scenaryInfo.name) {
                const teamInfo = getObjectInfoById(
                  relatedTeamId,
                  toolGoalsBonusAdm.teams,
                  "id",
                );

                if (teamInfo.name !== scenaryInfo.name) {
                  updateCenary(
                    { ...scenaryInfo, name: teamInfo.name },
                    selectedTool.id,
                  );
                }
              }
            });
          }
        }

        if (isAdmObjDiff) setGoalBonusAdministration(toolGoalsBonusAdm);
      }
    }
  }, [
    allGoalBonusAdministration,
    allSceneries,
    firstValidation,
    goalBonusAdministration,
    selectedTool.id,
    updateCenary,
  ]);

  return null;
};

const mapStateToProps = (state) => {
  const { allGoalBonusAdministration } = getGoalsBonusStates(state);

  return { allGoalBonusAdministration };
};

export default connect(mapStateToProps, { updateCenary })(GoalsBonusWatcher);
