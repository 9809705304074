import React from "react";
import ActionPlanTable from "../../../Common/ActionPlan/Table";
import { defineMessages } from "react-intl";
import { translatedText } from "../../../../utils/translationUtils";

const translation = (id, values) => translatedText(id, messages, values);

const messages = defineMessages({
  acp_main_title: { id: "acp_main_title" }
});

const ActionPlan = props => {
  const { onlyForm = false, SaveButton = false, dragabble = true } = props;

  return !onlyForm ? (
    <div className="box box-primary">
      <div className="box-header with-border">
        <h3 className="box-title">
          <i className="fas fa-list-ol" />
          {translation("acp_main_title")}
        </h3>
        {SaveButton && SaveButton}
      </div>
      <div className="box-body">
        <ActionPlanTable {...props} dragabble={dragabble} />
      </div>
    </div>
  ) : (
    <ActionPlanTable {...props} dragabble={dragabble} />
  );
};

export default ActionPlan;
