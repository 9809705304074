export const defaultConfirmDialog = {
  title: "",
  onConfirm: () => {},
  onCancel: () => {},
  open: false,
  params: {},
};

export const defaultEditingForm = {
  type: "",
  params: {},
};
