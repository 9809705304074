import React, { useMemo } from "react";
/* eslint-disable react/jsx-props-no-spreading */
import {
  GOALS_AND_BONUS,
  KPIS,
  OKR_ID,
  PEOPLE_BIG_PICTURE,
  COMPETITIVE_ANALYSIS,
  SKILLS_ASSESSMENT,
} from "../../../../constants/tools.constants";
import GoalsAndBonus from "./GoalsAndBonus";
import Kpi from "./Kpi";
import Okr from "./Okr";
import OnePage from "./OnePage";
import CompetitiveAnalysis from "./CompetitiveAnalysis";
import SkillAssessment from "./SkillAssessment";

const NewInsightDialog = (props) => {
  return useMemo(() => {
    const { params = {} } = props;
    const { toolId } = params;

    switch (toolId) {
      case GOALS_AND_BONUS:
        return <GoalsAndBonus {...props} />;
      case KPIS:
        return <Kpi {...props} />;
      case OKR_ID:
        return <Okr {...props} />;
      case PEOPLE_BIG_PICTURE:
        return <OnePage {...props} />;
      case COMPETITIVE_ANALYSIS:
        return <CompetitiveAnalysis {...props} />;
      case SKILLS_ASSESSMENT:
        return <SkillAssessment {...props} />;
      default:
        return null;
    }
  }, [props]);
};

export default NewInsightDialog;
