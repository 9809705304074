import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import FormDialog from "../../../Common/FormDialog";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import { Errors } from "../../../../utils/validations";
import { getStyledErrorMessage } from "../../../../utils/formValidation";
import {
  postCustomPeriod,
  postNewToolAndPeriod,
} from "../../../../actions/customPeriodActions";
import { appRefreshSelectedTool } from "../../../../actions/appActions";
import { setSelectedTool } from "../../../../actions/toolActions";

const NewPeriod = ({
  open,
  selectedWorkspaceID,
  selectedDomainId,
  selectedTool,
  toolTypeId,
  isFirstPeriod,
  fetchPeriods,
  onClose,
  postNewToolAndPeriod,
  loggedUser,
  appRefreshSelectedTool,
  setSelectedTool,
  customPeriods,
}) => {
  const [step, setStep] = useState("start");
  const [configuration, setConfiguration] = useState({
    start_date: "",
    end_date: "",
    title: "",
    is_default: true,
  });
  const [importConfig, setImportConfig] = useState({});
  const [importLoading, setImportLoading] = useState(false);
  const history = useHistory();

  function closeAndRefresh() {
    fetchPeriods();
    onClose();
  }

  function updateSelectedTool(newSelectedTool) {
    closeAndRefresh();
    setSelectedTool(newSelectedTool);
    history.push(`/tool/${newSelectedTool.id}`);
    appRefreshSelectedTool();
  }

  function getCustomPeriodConfiguration() {
    return {
      ...configuration,
      tool_type_id: selectedTool.tool.id,
      workspace_id: selectedWorkspaceID,
    };
  }

  async function saveCustomPeriod(tool_id) {
    await postCustomPeriod({
      ...getCustomPeriodConfiguration(),
      tool_id,
    });
    closeAndRefresh();
  }

  function getTemplateToolConfig() {
    const filteredPeriod = customPeriods.find(
      ({ id }) => id === importConfig.custom_period_id,
    );

    return {
      tool_type_id: selectedTool?.tool.id,
      tool_id: filteredPeriod?.tool_id,
      custom_period_id: filteredPeriod?.id,
      domain_id: selectedDomainId,
    };
  }

  async function saveToolAndPeriod(importPreferences = null, callback) {
    postNewToolAndPeriod({
      customPeriod: getCustomPeriodConfiguration(),
      workspaceId: selectedWorkspaceID,
      loggedUser,
      importConfig: importPreferences,
      templateConfig: getTemplateToolConfig(),
      callback,
    });
  }

  async function handleImportTemplate() {
    setImportLoading(true);
    saveToolAndPeriod(
      { ...importConfig, doImport: true },
      (newSelectedTool) => {
        updateSelectedTool(newSelectedTool);
      },
    );
  }

  function updateSingleConfig(field, newValue) {
    setConfiguration((current) => {
      return { ...current, [field]: newValue };
    });
  }

  function initImportConfig(initConfig) {
    if (Object.keys(importConfig).length === 0) {
      setImportConfig(initConfig);
    }
  }

  function updateSingleImportConfig(field, newValue) {
    setImportConfig((current) => {
      return { ...current, [field]: newValue };
    });
  }

  async function nextStep(nextStep) {
    if (nextStep === "importPreference" && isFirstPeriod) {
      saveCustomPeriod(selectedTool.id);
    } else if (nextStep === "blank") {
      saveToolAndPeriod(null, (newSelectedTool) =>
        updateSelectedTool(newSelectedTool),
      );
    } else {
      setStep(nextStep);
    }
  }

  function getFormErrors() {
    const errors = new Errors();
    if (!configuration.title)
      errors.add("invalid_title", "Preencha o título período");
    if (!configuration.start_date || !configuration.end_date)
      errors.add("invalid_dates", "Preencha as datas do período");

    return errors.getErrors();
  }

  const formErrors = getFormErrors();
  const append = {
    blockConfirm: formErrors.length > 0,
  };

  return (
    <FormDialog
      open={open}
      title="Adicionar novo período"
      hideConfirm
      onCancel={importLoading ? () => {} : onClose}
      hideCancel={importLoading}
    >
      {step === "start" && (
        <Step1
          configuration={configuration}
          update={updateSingleConfig}
          nextStep={nextStep}
          {...append}
        />
      )}

      {step === "importPreference" && <Step2 nextStep={nextStep} {...append} />}

      {step === "import" && (
        <Step3
          configuration={configuration}
          toolTypeId={toolTypeId}
          importConfig={importConfig}
          initImportConfig={initImportConfig}
          updateSingleImportConfig={updateSingleImportConfig}
          importLoading={importLoading}
          setImportLoading={setImportLoading}
          handleImportTemplate={handleImportTemplate}
          customPeriods={customPeriods}
          {...append}
        />
      )}
      {getStyledErrorMessage(["invalid_title", "invalid_dates"], formErrors)}
    </FormDialog>
  );
};

export default connect(null, {
  postNewToolAndPeriod,
  appRefreshSelectedTool,
  setSelectedTool,
})(NewPeriod);
