import * as actions from "./_actions";
import send from "../../dispatcher";

function observeComments({ stompClient, dispatch }) {
  stompClient.subscribe("/insights/timeline", response => {
    send(actions.postAddComment, response, dispatch);
  });

  stompClient.subscribe("/insights/editTimeline", response => {
    send(actions.putEditComment, response, dispatch);
  });
}

export default observeComments;
