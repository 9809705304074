/* eslint-disable react/require-default-props */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  Sector,
} from "recharts";

const messages = defineMessages({
  globalTotal: {
    id: "global.total",
  },
  globalNoData: {
    id: "global.noData",
  },
});

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
    value,
    showTotalMessage,
    intl,
  } = props;
  const totalMessage = showTotalMessage
    ? intl.formatMessage(messages.globalTotal) + ":"
    : "";

  return (
    <g>
      <text x={cx} y={cy - 22} dy={8} textAnchor="middle" fill="#222">
        {`${(percent * 100).toFixed(2)}%`}
      </text>
      <text
        x={cx}
        y={cy + 22}
        dy={8}
        textAnchor="middle"
        fill="#111"
        fontSize="18px"
      >
        {`${totalMessage} ${value}`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

const SimplePieChart = ({
  data,
  containerWidth,
  containerHeight,
  colors,
  defaultFillColor,
  showActiveShape,
  showTotalMessage,
  showLegend,
  intl,
}) => {
  const [activeIndex, setActiveIndex] = useState(1);

  const onPieEnter = (data, index) => {
    setActiveIndex(index);
  };

  let dataTotal = 0;

  if (Array.isArray(data))
    data.forEach((item) => {
      dataTotal += item.value;
    });

  const noDataMessage = intl.formatMessage(messages.globalNoData);
  let graphData =
    data && dataTotal > 0
      ? data
      : [{ name: noDataMessage, value: 1, color: "#ccc" }];

  return (
    <div
      style={{
        width: containerWidth,
        height: containerHeight,
      }}
      className="pie-chart"
    >
      <ResponsiveContainer width={"100%"} height={containerHeight}>
        <PieChart>
          <Pie
            data={graphData}
            dataKey="value"
            fill={defaultFillColor}
            cx="50%"
            cy="50%"
            innerRadius="75%"
            outerRadius="95%"
            activeIndex={activeIndex}
            activeShape={
              showActiveShape && dataTotal > 0
                ? (props) =>
                    renderActiveShape({ ...props, intl, showTotalMessage })
                : null
            }
            onMouseEnter={onPieEnter}
            legendType="circle"
          >
            {graphData.map((entry, index) => {
              return (
                <Cell
                  key={index}
                  fill={
                    entry.color ? entry.color : colors[index % colors.length]
                  }
                />
              );
            })}
          </Pie>
          {showLegend && <Legend />}
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

SimplePieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.any,
      hoverLabel: PropTypes.any,
      color: PropTypes.string,
    }),
  ).isRequired,
  containerWidth: PropTypes.any,
  containerHeight: PropTypes.any,
  colors: PropTypes.arrayOf(PropTypes.string),
  showActiveShape: PropTypes.bool,
  showTotalMessage: PropTypes.bool,
  defaultFillColor: PropTypes.string,
  showLegend: PropTypes.bool,
};

SimplePieChart.defaultProps = {
  data: [],
  containerWidth: "100%",
  containerHeight: 300,
  colors: ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"],
  showActiveShape: true,
  showTotalMessage: false,
  showLegend: false,
  defaultFillColor: "#8884d8",
};

export default injectIntl(SimplePieChart);
