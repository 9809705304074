import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  FilledInput,
  MenuItem,
} from "@material-ui/core";

const messages = defineMessages({
  globalSelect: { id: "global.selectOption" },
});

const styles = {
  optionsMenu: {
    "& > div > ul > li": { fontSize: "14px" },
  },
  notAllowed: {
    cursor: "not-allowed !important",
  },
};

const MaterialSingleSelect = (props) => {
  const [labelWidth, setLabelWidth] = useState(40);

  let { value, options, defaultOption, intl } = props;
  const {
    classes,
    variant,
    fullWidth,
    onChange,
    hideLabel,
    disabled,
    required,
    customStyles,
    margin,
    notAllowed,
    size,
    formControlStyles,
    labelProps,
    isRequired = false,
  } = props;

  if (defaultOption) {
    value = value === 0 && options.length === 0 ? "empty" : value;
    value = !value && value !== 0 && value !== false ? "empty" : value;
    options = [
      {
        value: "empty",
        label: `${isRequired ? "* " : ""}${intl.formatMessage(
          messages.globalSelect,
        )}`,
      },
      ...options,
    ];
  }

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      const { offsetWidth } = node;
      setLabelWidth(offsetWidth);
    }
  }, []);

  const inputStyles = {
    fontSize: "16px",
    ...customStyles,
  };

  const essentialInputProps = {
    id: `singleSelect${props.id}`,
    name: props.id,
    style: inputStyles,
    className: notAllowed ? classes.notAllowed : "",
  };

  let customInputProps = {};

  switch (variant) {
    case "standard":
      break;
    case "filled":
      customInputProps = {
        input: <FilledInput {...essentialInputProps} />,
      };
      break;
    default:
      // OUTLINED
      customInputProps = {
        input: (
          <OutlinedInput labelWidth={labelWidth} {...essentialInputProps} />
        ),
      };
      break;
  }

  return (
    <FormControl
      variant={variant}
      fullWidth={fullWidth}
      margin={margin}
      disabled={disabled}
      required={required}
      size={size}
      style={{ backgroundColor: "inherit", ...formControlStyles }}
      className="material-single-select"
    >
      {!hideLabel && (
        <InputLabel
          htmlFor={`singleSelect${props.id}`}
          style={{
            fontSize: "16px",
            backgroundColor: "inherit",
            ...labelProps,
          }}
          id={`singleSelectLabel${props.id}`}
          ref={measuredRef}
        >
          {props.label}
        </InputLabel>
      )}
      <Select
        value={value}
        onChange={onChange}
        inputProps={essentialInputProps}
        MenuProps={{
          disableEnforceFocus: true,
          className: classes.optionsMenu,
        }}
        {...customInputProps}
        style={essentialInputProps.style}
      >
        {options &&
          options.length &&
          options.map(({ label, value }, index) => (
            <MenuItem value={value} key={index}>
              {label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

MaterialSingleSelect.propTypes = {
  id: PropTypes.any.isRequired,
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.any, value: PropTypes.any }),
  ).isRequired,
  label: PropTypes.any,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  defaultOption: PropTypes.bool,
  variant: PropTypes.string,
  hideLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  customStyles: PropTypes.object,
  margin: PropTypes.oneOf(["none", "dense", "normal"]),
  size: PropTypes.oneOf(["small", "medium"]),
  notAllowed: PropTypes.bool,
  formControlStyles: PropTypes.object,
};

MaterialSingleSelect.defaultProps = {
  fullWidth: true,
  options: [],
  onChange: () => {},
  value: "",
  defaultOption: true,
  variant: "outlined",
  label: null,
  hideLabel: false,
  disabled: false,
  required: false,
  customStyles: null,
  margin: "normal",
  notAllowed: false,
  size: "small",
  formControlStyles: {},
};

export default injectIntl(withStyles(styles)(MaterialSingleSelect));
