import {
  UPDATE_COMPANY,
  CREATE_COMPANY,
  SET_SELECTED_COMPANY,
  FETCH_USER_OWNER_COMPANIES,
  FETCH_USER_MEMBER_COMPANIES,
  FETCH_COMPANY_MEMBERS,
  FETCH_MEMBER_WITH_AREA,
  FETCH_COMPANY_TEAMS,
  FETCH_COMPANY_PARTICIPANTS,
  FETCH_ALL_COMPANY_WORKSPACES,
  AVAILABLE_NAMESPACE,
  FETCH_ALL_COMPANY_TOOLS_TYPE,
  UPDATE_COMPANY_TEAMS,
  FETCH_MINIFIED_COMPANY_WORKSPACES,
} from "../actions/types";
import { findIndexById, getFilteredObjectsList } from "../utils/ArrayUtils";
import { updateAllList } from "../utils/reducerUtils";

import logo from "../assets/img/logo-rookau.png";

const INITIAL_STATE = {
  selectedCompany: null,
  userOwnerCompanies: [],
  userMemberCompanies: [],
  selectedCompanyProfessionalProfiles: [],
  members: [],
  participants: [],
  teams: [],
  loadingInfo: {
    isLoading: false,
    time: new Date(),
    firstLoad: false,
  },
  companyLogo: logo,
  allCompanyHierarchy: [],
};

const companyReducer = (state = INITIAL_STATE, action) => {
  const { teams } = state;

  const currentAllEvaluation = teams || [];

  const { payload = {} } = action;
  const companyId = payload && payload.companyId ? payload.companyId : null;

  const updateEvaluation = (newBody) => {
    let finalEvaluation = [];
    let didUpdate = false;

    currentAllEvaluation.forEach((itemBody) => {
      if (itemBody.id === newBody.id) {
        itemBody = newBody;
        didUpdate = true;
      }

      finalEvaluation = [...finalEvaluation, itemBody];
    });

    if (!didUpdate) finalEvaluation = [...finalEvaluation, newBody];

    return finalEvaluation;
  };

  switch (action.type) {
    case SET_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case FETCH_USER_OWNER_COMPANIES:
      return {
        ...state,
        userOwnerCompanies: action.payload,
      };
    case FETCH_USER_MEMBER_COMPANIES:
      return {
        ...state,
        userMemberCompanies: action.payload,
      };
    case FETCH_COMPANY_PARTICIPANTS:
      return {
        ...state,
        participants: action.payload,
      };
    case AVAILABLE_NAMESPACE:
      return {
        ...state,
        isAvailableNamespace: action.payload,
      };
    case CREATE_COMPANY:
      return {
        ...state,
        userOwnerCompanies: [...state.userOwnerCompanies, action.payload],
        isAvailableNamespace: "isfetching",
      };
    case UPDATE_COMPANY:
      const { userMemberCompanies, userOwnerCompanies } = state;
      const company = action.payload;
      let auxState = state;

      const userMemberCompaniesIx = findIndexById(userMemberCompanies, company);
      if (userMemberCompaniesIx !== -1) {
        userMemberCompanies[userMemberCompaniesIx] = company;
        auxState = {
          ...auxState,
          userMemberCompanies,
        };
      }

      const userOwnerCompaniesIx = findIndexById(userOwnerCompanies, company);
      if (userOwnerCompaniesIx !== -1) {
        userOwnerCompanies[userOwnerCompaniesIx] = company;
        auxState = {
          ...auxState,
          userOwnerCompanies,
        };
      }
      return auxState;
    case FETCH_COMPANY_MEMBERS:
      return {
        ...state,
        members: action.payload,
      };
    case FETCH_MEMBER_WITH_AREA:
      return {
        ...state,
        members: action.payload,
      };
    case "SET_COMPANY_LOGO":
      return {
        ...state,
        companyLogo: action.payload,
      };
    case FETCH_COMPANY_TEAMS:
      return {
        ...state,
        teams: action.payload,
      };
    case UPDATE_COMPANY_TEAMS:
      return {
        ...state,
        teams: updateEvaluation(payload),
      };
    case FETCH_ALL_COMPANY_WORKSPACES:
      return {
        ...state,
        allCompanyWorkspaces: action.payload,
      };
    case FETCH_MINIFIED_COMPANY_WORKSPACES:
      return {
        ...state,
        minifiedCompanyWorkspaces: action.payload,
      };
    case FETCH_ALL_COMPANY_TOOLS_TYPE:
      const currentToolsType = state.allCompanyToolsType || {};

      return {
        ...state,
        allCompanyToolsType: { ...currentToolsType, ...action.payload },
      };
    case "DASH_REQUESTS_LOADING":
      const { loading = false, time = new Date() } = action.payload;

      return {
        ...state,
        loadingInfo: { isLoading: loading, time, firstLoad: true },
      };
    case "USER_COMPANY_PROFESSIONAL_PROFILE":
      const profile = action.payload.profile ? action.payload.profile : {};

      const onlyFromCurrentCompanyProfiles = getFilteredObjectsList(
        [companyId],
        state.selectedCompanyProfessionalProfiles,
        "companyId",
      );

      return {
        ...state,
        selectedCompanyProfessionalProfiles: updateAllList(
          onlyFromCurrentCompanyProfiles,
          [profile],
          "jhiUserId",
        ),
      };
    case "ALL_USER_COMPANY_PROFESSIONAL_PROFILE":
      const allCompanyProfiles = action.payload.allCompanyProfiles
        ? action.payload.allCompanyProfiles
        : {};

      return {
        ...state,
        selectedCompanyProfessionalProfiles: allCompanyProfiles,
      };
    case "FILTER_SELECT_COMAPY_PROFESSIONAL_PROFILES":
      if (companyId && state.selectedCompanyProfessionalProfiles.length > 0) {
        return {
          ...state,
          selectedCompanyProfessionalProfiles: getFilteredObjectsList(
            [companyId],
            state.selectedCompanyProfessionalProfiles,
            "companyId",
          ),
        };
      }

      return state;
    case "ALL_COMPANY_HIERARCHY":
      return {
        ...state,
        allCompanyHierarchy: action.payload.allCompanyHierarchy,
      };
    default:
      return state;
  }
};

export default companyReducer;
