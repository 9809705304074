export const FETCH_OKR_CONFIG = "FETCH_OKR_CONFIG";
export const FETCH_ALL_OKR_CONFIGS = "FETCH_ALL_OKR_CONFIGS";
export const GET_ALL_CYCLES_BASES = "GET_ALL_CYCLES_BASES";
export const POST_PLAN = "POST_PLAN";
export const POST_OBJECTIVE = "POST_OBJECTIVE";
export const RESET_OKR = "RESET_OKR";
export const DELETE_OBJECTIVE = "DELETE_OBJECTIVE";
export const PUT_OBJECTIVE = "PUT_OBJECTIVE";
export const PUT_PLAN = "PUT_PLAN";
export const POST_KEY = "POST_KEY";
export const DELETE_PLAN = "DELETE_PLAN";
export const DELETE_KEY = "DELETE_KEY";
export const PUT_KEY = "PUT_KEY";
export const POST_CYCLE_BASE = "POST_CYCLE_BASE";
export const GET_CYCLE_BASE = "GET_CYCLE_BASE";
export const POST_PATTERN_BASE = "POST_PATTERN_BASE";
export const PUT_PATTERN_BASE = "PUT_PATTERN_BASE";
export const DELETE_BASE_PATTERN = "DELETE_BASE_PATTERN";
