import {
  selectedCompanyConditions,
  roleConditions,
  domainConditions,
} from "./entity";
import {
  DefaultLoginComponent,
  DefaultNotAuthorized,
  InvalidDomainRoute,
} from "../../components/routes";

export const getRenderComponent = ({
  routeInfo,
  isAuthenticated,
  selectedCompany,
  access,
  currentDomainUser,
  isExpired,
}) => {
  const { active = false } = currentDomainUser || {};

  const checkDomain = domainConditions(routeInfo, access);
  const checkRole = roleConditions(routeInfo);
  const checkSelectedCompany = selectedCompanyConditions(
    routeInfo,
    selectedCompany,
  );

  if (!checkRole || !checkSelectedCompany) {
    if (!isAuthenticated) return DefaultLoginComponent;

    return DefaultNotAuthorized;
  }

  if (
    (!checkDomain && access?.buildedAccess === true) ||
    (currentDomainUser?.id && active === false)
  )
    return InvalidDomainRoute;

  if (isExpired) return InvalidDomainRoute;

  return routeInfo.Component;
};
