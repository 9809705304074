import React from "react";
import PropTypes from "prop-types";
import TableComplementaryEvaluations from "./TableComplementaryEvaluations";
import TableComplementaryScale from "./TableComplementaryScale";
import { getComplementaryEvaluationsByType } from "../../../../../../../utils/skillsAssessment/filter";
import { getStyledErrorMessage } from "../../../../../../../utils/formValidation";
import {
  performanceEvaluationsErrorSlugs,
  performanceScaleErrorSlugs,
} from "../../../../../../../utils/skillsAssessment/errorConstants";

const TablePerformanceEvaluation = ({
  toolAdministration,
  updateAdministration,
  addComplementaryEvaluation,
  addScale,
  openDeleteScale,
  openDeleteEvaluation,
  errors,
}) => {
  const { complementaryEvaluations } = toolAdministration || {};
  const performanceEvaluations = getComplementaryEvaluationsByType(
    complementaryEvaluations,
    "PERFORMANCE",
  );

  return (
    <div className="row">
      <div className="col-xs-12 col-lg-8">
        <TableComplementaryEvaluations
          title="DESEMPENHO"
          evaluations={performanceEvaluations}
          addNew={() => addComplementaryEvaluation("PERFORMANCE", "NONE")}
          openDeleteEvaluation={openDeleteEvaluation}
          toolAdministration={toolAdministration}
          updateAdministration={updateAdministration}
        />
        {getStyledErrorMessage(performanceEvaluationsErrorSlugs(), errors)}
      </div>
      <div className="col-xs-12 col-lg-4">
        <TableComplementaryScale
          toolAdministration={toolAdministration}
          updateAdministration={updateAdministration}
          filterType="PERFORMANCE"
          handleAdd={() => addScale("PERFORMANCE")}
          openDeleteScale={openDeleteScale}
        />
        {getStyledErrorMessage(performanceScaleErrorSlugs(), errors)}
      </div>
    </div>
  );
};

TablePerformanceEvaluation.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
  updateAdministration: PropTypes.func.isRequired,
  addComplementaryEvaluation: PropTypes.func.isRequired,
  addScale: PropTypes.func.isRequired,
  openDeleteScale: PropTypes.func.isRequired,
  openDeleteEvaluation: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
};

export default TablePerformanceEvaluation;
