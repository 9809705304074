import { simpleNotifier } from "../../../../actions/notifierActions";

import {
  ADD_ACCESS,
  ADD_ACCESSES,
  DELETE_ACCESS,
  DELETE_ACCESSES,
} from "../../../types";

import {
  getUserAddAcessMessage,
  getUserAddingMultipleAccessMessage,
  getUserRemoveAcessMessage,
  getUserRemoveMultipleAcessMessage,
} from "./_validations";
import {
  getUserAccess,
  transformUserEntityAccessIds,
} from "../../../accessLevelsActions";

export const checkUpdateUserAccess = (sameUser, domainUserId) => {
  if (sameUser && domainUserId) {
    return (dispatch) => dispatch(getUserAccess(domainUserId));
  }
};

export const displayMessage = (message, variant = "success") => {
  if (message.show && message.text) {
    return (dispatch) =>
      dispatch(simpleNotifier(message.text, variant, null, message.id));
  }
};

export const addAccess = async (body) => {
  const {
    message,
    relatedAccess,
    sameUser,
    domainUserId,
  } = await getUserAddAcessMessage(body);

  if (relatedAccess || sameUser) {
    return (dispatch) => {
      dispatch(displayMessage(message, "success"));

      dispatch(checkUpdateUserAccess(sameUser, domainUserId));
      dispatch({
        type: ADD_ACCESS,
        payload: body,
      });
    };
  }
};

export const addAccesses = async (body) => {
  const {
    message,
    relatedAccess,
    sameUser,
    domainUserId,
  } = await getUserAddingMultipleAccessMessage(body);

  if (relatedAccess || sameUser) {
    return (dispatch) => {
      dispatch(displayMessage(message, "success"));

      dispatch(checkUpdateUserAccess(sameUser, domainUserId));
      const data = transformUserEntityAccessIds(body);

      dispatch({
        type: ADD_ACCESSES,
        payload: data,
      });
    };
  }
};

export const deleteAccess = async (body) => {
  const {
    message,
    relatedAccess,
    sameUser,
    domainUserId,
  } = await getUserRemoveAcessMessage(body);

  if (relatedAccess || sameUser) {
    return (dispatch) => {
      dispatch(displayMessage(message, "warning"));

      dispatch(checkUpdateUserAccess(sameUser, domainUserId));
      dispatch({ type: DELETE_ACCESS, payload: body });
    };
  }
};

export const deleteAccesses = async (body) => {
  const {
    message,
    relatedAccess,
    sameUser,
    domainUserId,
  } = await getUserRemoveMultipleAcessMessage(body);

  if (relatedAccess || sameUser) {
    return (dispatch) => {
      dispatch(displayMessage(message, "warning"));

      dispatch(checkUpdateUserAccess(sameUser, domainUserId));
      dispatch({ type: DELETE_ACCESSES, payload: body });
    };
  }
};
