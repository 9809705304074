import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import { Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import _ from "lodash";

import { defaultActivityData } from "../../../../../../utils/projectEvaluation";
import { getOverallWeight, verifyDate } from "../../utils";
import { activitiesTable } from "../../translations";
import { ruleOfThree } from "../../../../../../utils/MathUtils";
import { useWindowSize } from "../../../../../../utils/customHooks";

import { getChecklist } from "../../../../../../actions/projectEvaluationActions";
import { getCompanyMembersAndParticipants } from "../../../../../customMapStates";

import MaterialTextField from "../../../../../Common/MaterialTextField";
import SelectMemberModal from "../../../../../Common/SelectMemberModal";
import FormDialog from "../../../../../Common/FormDialog";
import Checklist from "../Checklist";

const messages = defineMessages(activitiesTable);

export const defaultModalError = {
  errorMessage: "",
  errorType: "",
  error: false,
};

const DisplayNewActivityModal = ({
  selectedActivity,
  intl,
  weightAutomation,
  effortAutomation,
  activitiesType,
  activitiesTypeDefault,
  activities,
  companyMembersAndParticipants,
  displayModal,
  onSaveActivity,
  onCancel,
  selectedInsightID,
}) => {
  const [modalError, setModalError] = useState(defaultModalError);
  const [activityModalData, setActivityModalData] =
    useState(defaultActivityData);
  const [validations, setValidations] = useState({
    didWeightUpdate: false,
    wrongWeights: false,
  });
  const [activityChecklistData, setActivityChecklistData] = useState([]);
  const [memberModalOpen, setMemberModalOpen] = useState(false);
  const [replaceSelectedMembers, setReplaceSelectedMembers] = useState(false);
  const windowSize = useWindowSize();
  const modalHeight = windowSize[1] - 180;

  const getChecklistInfo = (checklistData = []) => {
    const listLength = checklistData.length;
    let done = 0;

    const doneTypes = ["checked", "canceled"];

    checklistData.forEach((line) => {
      const { type = "" } = line;

      if (doneTypes.indexOf(type) > -1) done++;
    });

    const progress = String(ruleOfThree(listLength, done));

    return {
      foreseen: listLength,
      achieved: done,
      progress,
    };
  };

  const getActivityCheckListData = (checklistData) => {
    if (effortAutomation) {
      const {
        progress = "0",
        foreseen = 0,
        achieved = 0,
      } = getChecklistInfo(checklistData);

      setActivityModalData((current) => {
        return {
          ...current,
          progress,
          effort: {
            foreseen,
            achieved,
          },
        };
      });
    }

    setActivityChecklistData(checklistData);
  };

  useEffect(() => {
    setActivityModalData(selectedActivity);

    return () => {
      setActivityModalData({});
    };
  }, [selectedActivity]);

  useEffect(() => {
    if (selectedActivity.idChecklist && selectedInsightID) {
      getChecklist(selectedActivity.idChecklist, selectedInsightID).then(
        (data) => {
          if (data?.items) {
            getActivityCheckListData(data.items);
          }
        },
      );
    }
  }, [selectedActivity.idChecklist, selectedInsightID]);

  const formTitle = !activityModalData.isEditing
    ? intl.formatMessage(messages.pport_form_new_milestone)
    : intl.formatMessage(messages.pport_form_edit_milestone);

  const notAllowedChangeStyles = !activitiesTypeDefault
    ? { cursor: "not-allowed" }
    : {};

  const getWeight = (weight) => {
    const availableWeight = getOverallWeight(activities);

    if (!selectedActivity.isEditing && !validations.didWeightUpdate)
      return availableWeight > 0 ? availableWeight : 0;

    return weight;
  };

  const handlePercentagesValidations = (e) => {
    if (activitiesTypeDefault) {
      const target = e.target.value;

      const errorMessage = intl.formatMessage(messages.maxValueError);

      if (e.target.id)
        if (target < 0 || target > 100) {
          if (!modalError.error) {
            setModalError({
              error: true,
              errorType: "wrong",
              errorMessage,
            });
          }
        } else {
          setActivityModalData((current) => {
            return {
              ...current,
              [e.target.id]: target,
            };
          });

          if (e.target.id === "weight") {
            setValidations({
              ...validations,
              didWeightUpdate: true,
            });
          }

          if (modalError.error) setModalError(defaultModalError);
        }
    }
  };

  const updateSelectedMember = (selectedList) => {
    let newSelectedMember = null;

    if (selectedList && selectedList[0])
      newSelectedMember = { ...selectedList[0] };

    setActivityModalData((current) => {
      return {
        ...current,
        responsible: {
          id: newSelectedMember.id,
          type: newSelectedMember.type,
        },
      };
    });

    setMemberModalOpen(false);
  };

  const clearState = () => {
    setActivityChecklistData([]);
    setActivityModalData(defaultActivityData);
  };

  const closeModalAndClearState = () => {
    clearState();
    onCancel();
  };

  const saveAndClearState = () => {
    onSaveActivity(activityModalData, activityChecklistData);
    clearState();
  };

  const handleActivityConfirm = () => {
    const {
      description = "",
      progress = "",
      deliveryDate = null,
      expectedDate = null,
    } = activityModalData;

    if (description.length > 255 || description.length === 0) return true;

    if (progress === "100" && !deliveryDate) return true;

    const isDeliveryDateValid = verifyDate(deliveryDate);
    const isExpectedDateValid = verifyDate(expectedDate);

    if (!isDeliveryDateValid) return true;
    if (!isExpectedDateValid) return true;

    if (modalError.error) setModalError(defaultModalError);

    return false;
  };

  const getMemberDisplayName = (memberId) => {
    let displayName = intl.formatMessage(messages.global_selectSome);

    if (memberId) {
      companyMembersAndParticipants.forEach((memberInfo) => {
        if (memberId === memberInfo.id) displayName = memberInfo.name;
      });
    }

    return displayName;
  };

  const handleCustomEffortType = () => {
    const options = {
      Tarefas: intl.formatMessage(messages.pport_form_effort_tasks),
      Horas: intl.formatMessage(messages.pport_form_effort_hours),
      Dias: intl.formatMessage(messages.pport_form_effort_days),
    };

    return options[activitiesType];
  };

  const updateSubField = (
    parentField = null,
    childField = null,
    newValue,
    appendData,
  ) => {
    if (parentField && childField) {
      setActivityModalData((current) => {
        return {
          ...current,
          [parentField]: {
            ...appendData,
            [childField]: newValue,
          },
        };
      });
    }
  };

  const updateActiviesData = (type = "expected", newValue) => {
    updateSubField("activities", type, newValue, activityModalData.activities);
  };

  const updateEffortData = (type = "foreseen", newValue) => {
    updateSubField("effort", type, newValue, activityModalData.effort);
  };

  const weightTextField = () => {
    return (
      <Col md={3}>
        <MaterialTextField
          disabled={weightAutomation === "" ? false : weightAutomation}
          id="weight"
          label={intl.formatMessage(messages.weight)}
          type="number"
          value={Number(getWeight(activityModalData.weight) || 0).toFixed(0)}
          textAlign="center"
          margin="normal"
          variant="outlined"
          onChange={(e) => handlePercentagesValidations(e)}
          endAdorment={{ text: "%", position: "end", fontSize: "15px" }}
          inputStyle={notAllowedChangeStyles}
        />
      </Col>
    );
  };

  const displayWeightField = () => {
    if (weightAutomation) {
      return (
        <OverlayTrigger
          placement="left"
          overlay={
            <Popover
              id="information"
              style={{ zIndex: "99999" }}
              title="Peso Automático Habilitado"
            >
              <Row style={{ marginLeft: "5px" }}>
                Peso: 100 / Número de Milestones.
              </Row>
              <Row style={{ marginLeft: "5px" }}>
                Desabilite o peso automático para editar!
              </Row>
            </Popover>
          }
        >
          {weightTextField()}
        </OverlayTrigger>
      );
    }

    return weightTextField();
  };

  const effortFields = () => {
    return (
      <>
        <Col md={3}>
          <MaterialTextField
            disabled={effortAutomation === "" ? false : effortAutomation}
            id="effortForeseen"
            type="number"
            label={intl.formatMessage(messages.pport_form_effort_foreseen)}
            value={activityModalData.effort.foreseen || ""}
            onChange={(e) => updateEffortData("foreseen", e.target.value)}
          />
        </Col>
        <Col md={3}>
          <MaterialTextField
            disabled={effortAutomation === "" ? false : effortAutomation}
            id="effortAchieved"
            type="number"
            label={intl.formatMessage(messages.pport_form_effort_achieved)}
            value={activityModalData.effort.achieved || ""}
            onChange={(e) => updateEffortData("achieved", e.target.value)}
          />
        </Col>
        <Col md={4}>
          <MaterialTextField
            disabled={effortAutomation === "" ? false : effortAutomation}
            id="progress"
            label={intl.formatMessage(messages.physicalProgress)}
            type="number"
            value={(Number(activityModalData.progress) || 0).toFixed(0)}
            textAlign="right"
            onChange={(e) => handlePercentagesValidations(e)}
            endAdorment={{ text: "%", position: "end", fontSize: "15px" }}
            inputStyle={notAllowedChangeStyles}
          />
        </Col>
      </>
    );
  };
  const displayEffortField = () => {
    if (effortAutomation) {
      return (
        <OverlayTrigger
          placement="right"
          overlay={
            <Popover
              id="information"
              style={{ zIndex: "99999" }}
              title="Esforço Automático Habilitado"
            >
              <Row style={{ marginLeft: "5px" }}>
                Previsto: Número de Atividades.
              </Row>
              <Row style={{ marginLeft: "5px" }}>
                Realizado: Tarefas Concluidas e/ou Canceladas.
              </Row>
              <Row style={{ marginLeft: "5px" }}>
                Avanço Físico: Atividades realizadas sobre atividades Previstas.
              </Row>
              <Row style={{ marginLeft: "5px" }}>
                Desabilite o esforço automático para editar!
              </Row>
            </Popover>
          }
        >
          <Row>
            {!activitiesTypeDefault && (
              <>
                <Col md={3}>
                  <MaterialTextField
                    id="expectedActivities"
                    type="number"
                    label={intl.formatMessage(
                      messages.pport_form_activities_expected,
                    )}
                    value={activityModalData.expectedActivities || ""}
                    onChange={(e) =>
                      updateActiviesData("expectedActivities", e.target.value)
                    }
                  />
                </Col>
                <Col md={3}>
                  <MaterialTextField
                    id="achievedActivities"
                    type="number"
                    label={intl.formatMessage(
                      messages.pport_form_activities_achieved,
                    )}
                    value={activityModalData.achievedActivities || ""}
                    onChange={(e) =>
                      updateActiviesData("achievedActivities", e.target.value)
                    }
                  />
                </Col>
              </>
            )}
            {effortFields()}
          </Row>
        </OverlayTrigger>
      );
    }

    return <Row>{effortFields()}</Row>;
  };

  return (
    <>
      {memberModalOpen && (
        <SelectMemberModal
          title={intl.formatMessage(messages.pport_form_responsible)}
          membersList={companyMembersAndParticipants || []}
          onConfirm={(selected, selectedList) =>
            updateSelectedMember(selectedList)
          }
          onCancel={() => setMemberModalOpen(false)}
          open={memberModalOpen}
          cancelText={intl.formatMessage(messages.global_goBack)}
          replaceSelected={replaceSelectedMembers}
          initialSelected={
            activityModalData.responsible?.id
              ? [activityModalData.responsible?.id]
              : []
          }
          replaceCallback={() => setReplaceSelectedMembers(false)}
          singleSelect
        />
      )}
      <FormDialog
        open={displayModal.displayNewActivity || activityModalData.isEditing}
        title={formTitle}
        onConfirm={saveAndClearState}
        onCancel={closeModalAndClearState}
        bodyStyle={{ padding: "0 15px 15px 15px" }}
        blockConfirm={handleActivityConfirm()}
      >
        <div style={{ maxHeight: modalHeight, overflowX: "auto" }}>
          <Row className="centralized">
            <Col md={12}>
              <MaterialTextField
                id="activity"
                label={intl.formatMessage(messages.pport_form_milestone)}
                value={activityModalData.description || ""}
                multiline
                rows={5}
                primaryInputProps={{ maxLength: 255 }}
                margin="normal"
                variant="outlined"
                textAlign="left"
                onChange={(e) =>
                  setActivityModalData((current) => {
                    return {
                      ...current,
                      description: e.target.value,
                    };
                  })
                }
              />
            </Col>
          </Row>
          <Row className="centralized">
            <Col md={12}>
              <MaterialTextField
                id="activitiesResponsible"
                label={intl.formatMessage(messages.pport_form_responsible)}
                value={getMemberDisplayName(activityModalData.responsible?.id)}
                onClick={() => setMemberModalOpen(true)}
              />
            </Col>
          </Row>
          <Row>
            {!activitiesTypeDefault && (
              <Col md={6} style={{ marginBottom: "0px" }}>
                <h5 style={{ marginBottom: "0px" }}>
                  {intl.formatMessage(messages.pport_form_activities)}
                </h5>
              </Col>
            )}
            <Col md={6} style={{ marginBottom: "0px" }}>
              <h5 style={{ marginBottom: "0px" }}>
                {handleCustomEffortType()}
              </h5>
            </Col>
          </Row>
          {displayEffortField()}
          <Row>
            <Col md={4}>
              <MaterialTextField
                id="milestoneExpectedDate"
                label={intl.formatMessage(messages.expectedDelivery)}
                type="date"
                value={activityModalData.expectedDate || ""}
                onChange={(e) =>
                  setActivityModalData((current) => {
                    return {
                      ...current,
                      expectedDate: e.target.value,
                    };
                  })
                }
              />
            </Col>
            <Col md={4}>
              <MaterialTextField
                id="milestoneDeliveryDate"
                label={intl.formatMessage(messages.pport_form_delivery_date)}
                type="date"
                value={activityModalData.deliveryDate || ""}
                onChange={(e) =>
                  setActivityModalData((current) => {
                    return {
                      ...current,
                      deliveryDate: e.target.value,
                    };
                  })
                }
              />
            </Col>
            {displayWeightField()}
          </Row>
          <Row>
            <Col md={12}>
              {modalError.error &&
                modalError.errorType === "wrong" &&
                intl.formatMessage(messages.maxValueIs100)}
            </Col>
          </Row>
          <Row style={{ color: "red" }}>
            <Col md={12}>
              {modalError.error &&
                modalError.errorType === "invalidDeliveryDate" &&
                intl.formatMessage(messages.invalidDeliveryDate)}
            </Col>
            <Col md={12}>
              {modalError.error &&
                modalError.errorType === "invalidExpectedDate" &&
                intl.formatMessage(messages.invalidExpectedDate)}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {modalError.error &&
                modalError.errorType === "description" &&
                intl.formatMessage(messages.maxDescriptionLength)}
              {modalError.error &&
                modalError.errorType === "progress_delivery_date" &&
                intl.formatMessage(messages.deliveryDateMustBeInputed)}
            </Col>
          </Row>
          <Row className="centralized">
            <Col md={12}>
              <MaterialTextField
                id="note"
                label={intl.formatMessage(messages.note)}
                primaryInputProps={{ maxLength: 400 }}
                value={activityModalData.note || ""}
                multiline
                rows={5}
                margin="normal"
                variant="outlined"
                textAlign="left"
                onChange={(e) =>
                  setActivityModalData((current) => {
                    return {
                      ...current,
                      note: e.target.value,
                    };
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Checklist
                onChangeChecklist={getActivityCheckListData}
                items={activityChecklistData}
              />
            </Col>
          </Row>
        </div>
      </FormDialog>
    </>
  );
};

const mapStateToProps = (state) => {
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);

  return {
    companyMembersAndParticipants,
  };
};

function areEqual(prevProps, nextProps) {
  if (!_.isEqual(prevProps.displayModal, nextProps.displayModal)) return false;
  if (!_.isEqual(prevProps.selectedActivity, nextProps.selectedActivity))
    return false;
  if (!_.isEqual(prevProps.activities, nextProps.activities)) return false;

  return true;
}

export default connect(
  mapStateToProps,
  null,
)(React.memo(injectIntl(DisplayNewActivityModal), areEqual));

DisplayNewActivityModal.propTypes = {
  selectedActivity: PropTypes.object,
  activitiesTypeDefault: PropTypes.bool.isRequired,
  displayModal: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  activities: PropTypes.array.isRequired,
  companyMembersAndParticipants: PropTypes.array.isRequired,
  onSaveActivity: PropTypes.func,
  onCancel: PropTypes.func,
};

DisplayNewActivityModal.defaultProps = {
  selectedActivity: {},
  onSaveActivity: () => {},
  onCancel: () => {},
};
