import React, { useState } from "react";
import { injectIntl, defineMessages } from "react-intl";
import CheckIcon from "@material-ui/icons/Check";
import utils from "../../../utils/toolUtils";
import kpiUtils from "../../../utils/kpis";
import { getSingleIndicatorResults } from "../../../utils/kpis/calc2";
import actionsPlanUtils from "../../../utils/actionsPlan";
import TargetColorBadge from "../TargetColorBadgeKpi";
import MeasurementLevelsBar from "../MeasurementLevelsBar";
import Avatar from "../Avatar";
import { defaultGoalsDisplayOrder } from "../../../constants/kpis";
import { getDisplayTargets } from "../../../utils/kpis/calc";
import ComposedPeriodsChart from "./includes/ComposedPeriodsChart";
import { getPeriodTargetsDivision } from "../../../utils/kpis/calc2";
import { getOrderedTargets } from "../../../utils/kpis/calc2";
import { connect } from "react-redux";
import { getSelectedToolStates } from "components/customMapStates";
import { GOALS_AND_BONUS } from "constants/tools.constants";
import { getAccessFilteredStates } from "components/customMapStates";

const {
  getObjectInfoById,
  getToFixed,
  reorderObjectBySlugs,
  getOnlySublevelArrays,
  getSublevelsValuesByKey,
} = utils;

const {
  getHitTargetInfo,
  displayTransformValue,
  getBarBonusMeasurement,
  getIndividualIndicatorsResult,
} = kpiUtils;

const {
  getFilteredActionsPlanByInsights,
  getActionsGroupedByStatus,
  getTotalActionsByInsights,
} = actionsPlanUtils;

const messages = defineMessages({
  kpi_table_text_subthemes: { id: "kpi_table_text_subthemes" },
  kpi_table_text_goal_upper: { id: "kpi_table_text_goal_upper" },
  kpi_table_text_indicators_upper: { id: "kpi_table_text_indicators_upper" },
  kpi_table_text_period_upper: { id: "kpi_table_text_period_upper" },
  kpi_form_unity: { id: "kpi_form_unity" },
  kpi_options_rt_shared: { id: "kpi_options_rt_shared" },
  global_export: { id: "global.export" },
  kpi_table_text_relationship: { id: "kpi_table_text_relationship" },
  kpi_form_description: { id: "kpi_form_description" },
  kpi_table_text_gate: { id: "kpi_table_text_gate" },
  kpi_table_text_appropriated: { id: "kpi_table_text_appropriated" },
  kpi_table_text_exceeded: { id: "kpi_table_text_exceeded" },
  kpi_table_text_actual: { id: "kpi_table_text_actual" },
  kpi_table_text_goals: { id: "kpi_table_text_goals" },
  kpi_table_text_goals_bonus: { id: "kpi_table_text_goals_bonus" },
  kpi_table_text_goals_bonus_upper: { id: "kpi_table_text_goals_bonus_upper" },
  kpi_table_text_no_goal_found: { id: "kpi_table_text_no_goal_found" },
  kpi_table_text_limit_bonus: { id: "kpi_table_text_limit_bonus" },
  kpi_table_text_limit_bonus_total: { id: "kpi_table_text_limit_bonus_total" },
  kpi_table_text_chart: { id: "kpi_table_text_chart" },
  kpi_table_text_no_subthemes: {
    id: "kpi_table_text_no_subthemes",
  },
  kpi_table_text_salaries_quantity_short: {
    id: "kpi_table_text_salaries_quantity_short",
  },
  kpi_table_text_no_goals_evaluation: {
    id: "kpi_table_text_no_goals_evaluation",
  },
  kpi_table_text_salary_base_ext_upper: {
    id: "kpi_table_text_salary_base_ext_upper",
  },
});

const ProcessKpisDetailsTable = (props) => {
  const [showGoalDetails, setShowGoalDetails] = useState([]);

  const {
    relationshipTypeOptions,
    measurementUnityOptions,
    intl,
    kpiAdministration,
    displayProcesses,
    actionsPlan,
    selectAndOpenInsight,
    isPeriodFilterActive: filter,
    allAnswers
  } = props;

  const toggleShowDetails = (goalId) => {
    const updatedGoalDetails = [];

    if (showGoalDetails.indexOf(goalId) === -1) updatedGoalDetails.push(goalId);

    setShowGoalDetails(updatedGoalDetails);
  };

  const tableHeadNames = [
    {
      label: intl.formatMessage(messages.kpi_table_text_subthemes),
      colspan: 9,
      width: "550px",
    },
    {
      label: intl.formatMessage(messages.kpi_table_text_no_goals_evaluation),
      colspan: 1,
      width: "320px",
    },
    {
      label: `% ${intl.formatMessage(
        messages.kpi_table_text_indicators_upper,
      )}`,
      colspan: 1,
      width: "120px",
    },
    {
      label: "Indicadores",
      colspan: 1,
      width: "120px",
    },
    {
      label: "Plano de ações",
      colspan: 1,
      width: "120px",
    },
  ];

  const filteredCollumns = [];

  tableHeadNames.forEach((headInfo) => {
    if (!headInfo.block) filteredCollumns.push(headInfo);
  });

  const oveflowTextStyles = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "450px",
    cursor: "pointer",
  };

  let totalProcessGoals = 0;

  const orderedByTitle = displayProcesses
    .map((item) => {
      return {
        ...item,
        title: item?.processInfo?.title,
      };
    })
    .sort((a, b) => {
      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;
      return 0;
    });

  return (
    <div>
      <table className="simple-table horizontal-scroll">
        <thead style={{ fontSize: "12px" }}>
          <tr className="small-font">
            {filteredCollumns.map((tableHead, index) => (
              <td
                colSpan={tableHead.colspan}
                key={index}
                style={{
                  width: tableHead.width || "auto",
                  textAlign: tableHead.textAlign,
                }}
              >
                {tableHead.label}
              </td>
            ))}
          </tr>
        </thead>
        <tbody style={{ fontSize: "16px", fontWeight: "500" }}>
          {orderedByTitle.map(({ processInfo, processGoals }, index) => {
            const showprocessGoals =
              showGoalDetails.indexOf(processInfo.id) > -1;

            const relatedInsights = getSublevelsValuesByKey(
              processGoals,
              "relatedInsights",
            );

            const totalActions = getTotalActionsByInsights(
              relatedInsights,
              actionsPlan,
            ).length;

            const calculateAll =
              processGoals.filter(
                ({ relationshipType }) => relationshipType === "INDIVIDUAL",
              ).length === 0;

            const { realGoalsReachSum } = getIndividualIndicatorsResult(
              processGoals,
              kpiAdministration.goalTargets,
              calculateAll,
              filter,
              kpiAdministration.zeroWhenNegative,
            );

            const barMeasurement = getBarBonusMeasurement(
              realGoalsReachSum,
              kpiAdministration.goalTargets,
            );

            const orderedGoals = reorderObjectBySlugs(
              defaultGoalsDisplayOrder,
              processGoals,
              "relationshipType",
            );

            if (orderedGoals?.length === 0) return null;

            totalProcessGoals += processGoals.length;

            return (
              <React.Fragment key={index}>
                <tr
                  style={{
                    cursor: "pointer",
                    userSelect: "none",
                    transition: "0.3s",
                    opacity:
                      showGoalDetails.indexOf(processInfo.id) === -1 &&
                        showGoalDetails.length > 0
                        ? "0.5"
                        : "1",
                  }}
                  onClick={() => toggleShowDetails(processInfo.id)}
                  className="small-font"
                >
                  <td
                    colSpan="9"
                    style={{
                      borderLeft: showprocessGoals
                        ? "3px solid #6b42a9"
                        : "1px solid #ccc",
                    }}
                  >
                    <div className="row">
                      <div
                        className="col-xs-12"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div className="col" style={{ paddingLeft: "25px" }}>
                          <h5
                            style={{
                              marginTop: "8px",
                            }}
                          >
                            {processInfo.title}
                          </h5>
                          <h6>{processInfo.description}</h6>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <MeasurementLevelsBar width={`${barMeasurement}px`} />
                  </td>

                  <td align="right">{getToFixed(realGoalsReachSum, 1)}%</td>
                  <td align="center">{processGoals.length}</td>
                  <td align="center">{totalActions}</td>
                </tr>
                <tr
                  style={
                    showprocessGoals
                      ? {
                        display: "table-row",
                        borderLeft: "5px solid #6b42a9",
                      }
                      : { display: "none" }
                  }
                  className="block-hover"
                >
                  <td style={{ padding: "15px" }} colSpan="100">
                    <div
                      className="custom-transition"
                      style={{
                        opacity: showprocessGoals ? "1" : "0",
                      }}
                    >
                      <table className="simple-table" style={{ margin: "0px" }}>
                        <thead style={{ fontSize: "12px" }}>
                          <tr className="small-font">
                            <td align="left">
                              {intl.formatMessage(
                                messages.kpi_table_text_goal_upper,
                              )}
                            </td>
                            <td>
                              {intl.formatMessage(
                                messages.kpi_table_text_relationship,
                              )}
                            </td>
                            <td>
                              {intl.formatMessage(messages.kpi_form_unity)}
                            </td>
                            <td width="140">
                              {intl.formatMessage(messages.kpi_table_text_gate)}
                            </td>
                            <td width="140">
                              {intl.formatMessage(
                                messages.kpi_table_text_appropriated,
                              )}
                            </td>
                            <td width="140">
                              {intl.formatMessage(
                                messages.kpi_table_text_exceeded,
                              )}
                            </td>
                            <td width="140">
                              {intl.formatMessage(
                                messages.kpi_table_text_actual,
                              )}
                            </td>
                            <td>
                              %{" "}
                              {intl.formatMessage(
                                messages.kpi_table_text_goals,
                              )}
                            </td>
                            <td>{intl.formatMessage(messages.kpi_table_text_chart)}</td>
                            <td>Plano</td>
                            <td>Andamento</td>
                            <td>Concluído</td>
                            <td>Cancelado</td>
                          </tr>
                        </thead>
                        <tbody>
                          {orderedGoals?.map((goalInfo, index) => {
                            const relatedInsights =
                              goalInfo.relatedInsights || [];

                            const mainRelatedInsightId =
                              relatedInsights[0] || null;

                            const {
                              goalPeriodTotalEvaluation,
                              goalPercentage,
                              courseTargetsDivision,
                              notStarted,
                              accumulated,
                            } = getSingleIndicatorResults(
                              goalInfo,
                              kpiAdministration.goalTargets,
                              kpiAdministration.zeroWhenNegative,
                            );

                            const displayPercentage = filter
                              ? accumulated.goalPercentage
                              : goalPercentage;
                            const displayTargets = filter
                              ? accumulated.target
                              : courseTargetsDivision;

                            const { displayTotal, displayCourseTargets } =
                              getDisplayTargets(
                                goalInfo,
                                goalPeriodTotalEvaluation,
                                displayTargets,
                              );

                            const hitTargetInfo = getHitTargetInfo(
                              displayPercentage,
                              kpiAdministration.goalTargets,
                              notStarted,
                            );

                            const filteredInsightActionsPlan =
                              getFilteredActionsPlanByInsights(
                                [mainRelatedInsightId],
                                actionsPlan,
                              );
                            const groupedByStatus = getActionsGroupedByStatus(
                              filteredInsightActionsPlan,
                            );
                            const onlyActions =
                              getOnlySublevelArrays(groupedByStatus);

                            const isConsolidated =
                              goalInfo.relationshipType === "SHARED";

                            const periodTargetsDivision = getPeriodTargetsDivision(goalInfo);

                            const { actual: actualTargetValues } = getOrderedTargets(goalInfo);

                            return (
                              <tr
                                key={index}
                                className="small-font block-white-space"
                              >
                                <td title={goalInfo.title}>
                                  <div
                                    onClick={() =>
                                      selectAndOpenInsight(goalInfo)
                                    }
                                    style={{
                                      ...oveflowTextStyles,
                                      display: "flex",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Avatar
                                      src={goalInfo.avatarId}
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        marginRight: "5px",
                                      }}
                                    />
                                    <span
                                      style={
                                        isConsolidated
                                          ? { fontWeight: "bold" }
                                          : {}
                                      }
                                    >
                                      {isConsolidated && (
                                        <i
                                          className="fas fa-stream"
                                          style={{
                                            color: "#ffc875",
                                            fontSize: "17px",
                                            paddingRight: "7px",
                                            position: "relative",
                                            top: "1px",
                                          }}
                                          title={intl.formatMessage(
                                            messages.kpi_options_rt_shared,
                                          )}
                                        />
                                      )}
                                      {goalInfo.title}
                                    </span>
                                  </div>
                                </td>
                                <td align="center">
                                  {
                                    getObjectInfoById(
                                      goalInfo.relationshipType,
                                      relationshipTypeOptions,
                                      "value",
                                    ).label
                                  }
                                </td>
                                <td align="center">
                                  {
                                    getObjectInfoById(
                                      goalInfo.measurementUnity,
                                      measurementUnityOptions,
                                      "value",
                                    ).label
                                  }
                                  {goalInfo.measurementUnity === "OTHER" &&
                                    ` (${goalInfo.otherMeasurement})`}
                                </td>
                                <td align="right">
                                  {displayTransformValue(
                                    displayCourseTargets?.gate,
                                    goalInfo.measurementUnity,
                                    2,
                                  )}
                                </td>
                                <td align="right">
                                  {displayTransformValue(
                                    displayCourseTargets?.appropriated,
                                    goalInfo.measurementUnity,
                                    2,
                                  )}
                                </td>
                                <td align="right">
                                  {displayTransformValue(
                                    displayCourseTargets?.exceeded,
                                    goalInfo.measurementUnity,
                                    2,
                                  )}
                                </td>
                                <td align="right">
                                  <b>
                                    {displayTransformValue(
                                      displayTotal,
                                      goalInfo.measurementUnity,
                                      2,
                                    )}
                                  </b>
                                </td>
                                <td width="80" align="right">
                                  <TargetColorBadge
                                    hitTargetInfo={hitTargetInfo}
                                    goalPercentage={displayPercentage}
                                  />
                                </td>
                                <td>
                                  <ComposedPeriodsChart
                                    goalInfo={goalInfo}
                                    periodTargetsDivision={periodTargetsDivision}
                                    actualTargetValues={actualTargetValues}
                                    goalTargetConfigurations={kpiAdministration.goalTargets}
                                    zeroWhenNegative={kpiAdministration.zeroWhenNegative}
                                    allAnswers={allAnswers}
                                    kpiAdministration={kpiAdministration}
                                  />
                                </td>
                                <td
                                  align="center"
                                  style={{ backgroundColor: "#f6f6f6" }}
                                >
                                  {onlyActions.length > 0 && <CheckIcon />}
                                </td>
                                <td
                                  align="center"
                                  style={{ backgroundColor: "#f6f6f6" }}
                                >
                                  {groupedByStatus.IN_PROGRESS.length}
                                </td>
                                <td
                                  align="center"
                                  style={{ backgroundColor: "#f6f6f6" }}
                                >
                                  {groupedByStatus.COMPLETE.length}
                                </td>
                                <td
                                  align="center"
                                  style={{ backgroundColor: "#f6f6f6" }}
                                >
                                  {groupedByStatus.CANCELED.length}
                                </td>
                              </tr>
                            );
                          })}
                          {!processGoals ||
                            (processGoals.length === 0 && (
                              <tr>
                                <td colSpan="100">
                                  {intl.formatMessage(
                                    messages.kpi_table_text_no_goal_found,
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
          {totalProcessGoals === 0 && (
            <tr>
              <td colSpan="100">
                {intl.formatMessage(messages.kpi_table_text_no_subthemes)}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};


const mapStateToProps = (state) => {
  const { toolsConfigurations } = state;
  const { selectedTool = {} } = getSelectedToolStates(state);
  const isGoalsAndBonus = selectedTool?.tool?.id === GOALS_AND_BONUS;

  const {
    filteredAnswers: allAnswers = {},
  } = getAccessFilteredStates(
    state,
    {
      sceneries: toolsConfigurations.showHiddenCenarys || false,
      themes: toolsConfigurations.showHiddenThemes || false,
      questions: toolsConfigurations.showHiddenQuestions || false,
      answers: toolsConfigurations.showHiddenAnswers || false,
    },
    !isGoalsAndBonus,
  );

  return {
    allAnswers
  };
};

export default connect(mapStateToProps)(injectIntl(ProcessKpisDetailsTable))


