import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { createSceneriePlan, postPlan } from "../../../../actions/okrActions";
import { addNewCycleGroupToCenary } from "../../../../actions/tempActions";
import ScenerieSelectDialog from "../../../Common/Okr/forms/ScenerieSelectDialog";
import { getSelectedToolStates, getKpiStates } from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";
import { configPlansBody } from "../../../../utils/okr/entity";
import { formatDateCycles } from "../../../../utils/okr/valid";

const { getValidFunc, getDefaultAddTableLine, getDefaultRemoveColumn } = utils;

const Okr = (props) => {
  const [addedSceneries, setAddedSceneries] = useState([]);

  const {
    type,
    clearViewCallback,
    createSceneriePlan,
    postPlan,
    selectedTool = {},
    userProfile,
    params = {},
    allSceneries = [],
    allThemes,
    okrPlans,
    cycleBase,
    okrConfigurationId,
    addNewCycleGroupToCenary,
  } = props;

  useEffect(() => {
    const toolSceneries = [];

    if (allSceneries && allSceneries.length) {
      allSceneries.forEach((scenerieInfo) => {
        if (
          scenerieInfo.selectedTool.id === selectedTool.id &&
          scenerieInfo.okrConfigurationPlanId
        )
          toolSceneries.push(scenerieInfo.okrConfigurationPlanId);
      });
    }

    if (!_.isEqual(toolSceneries, addedSceneries))
      setAddedSceneries(toolSceneries);
  }, [addedSceneries, allSceneries, selectedTool.id]);

  const cancelCallback = getValidFunc(params.cancelCallback);

  function addPlanToBoard(updatedPlan, callback) {
    const newScenerieOrder = allSceneries.length || 0;

    createSceneriePlan(
      selectedTool,
      updatedPlan,
      userProfile,
      newScenerieOrder,
      callback,
    );
  }

  const finishSaveCyclesAndThemes = (
    newUpdatedPlan,
    relatedScenerie,
    doUpdatePlan = true,
  ) => {
    const scenerieThemes = allThemes[relatedScenerie.id];

    addNewCycleGroupToCenary(
      newUpdatedPlan,
      relatedScenerie,
      scenerieThemes,
      doUpdatePlan,
    );
  };

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
    cancelCallback();
  };

  const addPlanCyclesAndThemesToBoard = (okrPlan) => {
    addPlanToBoard(okrPlan, (savedScenerieData) => {
      if (savedScenerieData && savedScenerieData.data) {
        finishSaveCyclesAndThemes(okrPlan, savedScenerieData.data, false);
      }
    });
  };

  async function createOrUpdatePlan(newSavePlan, cycles) {
    const updatedCycles = formatDateCycles(cycles);

    const newPlan = {
      ...newSavePlan,
      cycleGroups: updatedCycles,
    };

    const newUpdatedPlan = configPlansBody(newPlan);

    if (okrConfigurationId && selectedTool.id) {
      await postPlan(okrConfigurationId, newUpdatedPlan, (restSavedPlan) => {
        addPlanCyclesAndThemesToBoard(restSavedPlan);
      });
    }
  }

  const appendProps = {
    getDefaultAddTableLine,
    getDefaultRemoveColumn,
  };

  return (
    <ScenerieSelectDialog
      open
      createOrUpdatePlan={createOrUpdatePlan}
      afterCancel={clearDialogControll}
      okrPlans={okrPlans}
      cycleBase={cycleBase}
      okrConfigurationId={okrConfigurationId}
      addPlanCyclesAndThemesToBoard={addPlanCyclesAndThemesToBoard}
      allThemes={allThemes}
      allSceneries={allSceneries}
      addedSceneries={addedSceneries}
      {...appendProps}
    />
  );
};

const mapStateToProps = (state) => {
  const { selectedTool, allSceneries, allThemes } = getSelectedToolStates(
    state,
  );
  const { isLoading } = getKpiStates(state);

  const { okr, user } = state;

  return {
    userProfile: user,
    selectedTool,
    isLoading,
    allSceneries,
    allThemes,
    okrConfigurationId: okr.id,
    okrPlans: okr.okrPlans,
    cycleBase: okr.cycleBase,
  };
};

export default connect(mapStateToProps, {
  createSceneriePlan,
  postPlan,
  addNewCycleGroupToCenary,
})(Okr);

Okr.propTypes = {
  okrPlans: PropTypes.array.isRequired,
};
