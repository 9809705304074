import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import _ from "lodash";
import FormDialog from "../../FormDialog";
import MaterialTextField from "../../MaterialTextField";
import gbUtils from "../../../../utils/goalsBonus";
import { getAllExcept, getOnlySlugValues } from "../../../../utils/ArrayUtils";
import { Errors } from "../../../../utils/validations";
import { getComparableQuery } from "../../../../utils/StringUtils";
import { getStyledErrorMessage } from "../../../../utils/formValidation";

const { getTeamInfoById } = gbUtils;

const initialForm = {
  id: null,
  name: "",
};

const messages = defineMessages({
  gb_dialog_title_add_team: { id: "gb_dialog_title_add_team" },
  gb_dialog_title_edit_team: { id: "gb_dialog_title_edit_team" },
  gb_form_title: { id: "gb_form_title" },
});

const NewGoalsBonusTeamDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const {
    open = false,
    afterCancel,
    afterConfirm,
    goalBonusAdministration,
    setGoalBonusAdministration,
    editingForm = {},
    getToolConfig,
    intl,
  } = props;

  const isEditing = editingForm.type === "newTeam";

  useEffect(() => {
    if (editingForm && isEditing && goalBonusAdministration) {
      const editingTeamIndex = getEditingIndex(goalBonusAdministration.teams);
      const teamInfo = goalBonusAdministration.teams[editingTeamIndex];

      if (teamInfo) {
        setForm({ ...teamInfo });
      }
    }
  }, [editingForm, goalBonusAdministration, isEditing]);

  const updateFormField = (field, newVal) => {
    setForm({ ...form, [field]: newVal });
  };

  const resetForm = () => {
    setForm(initialForm);
  };

  const getEditingIndex = (list) => {
    return getTeamInfoById(editingForm.params.teamId, list, true);
  };

  const saveAdministration = (newAdministration) => {
    if (!_.isEqual(newAdministration, goalBonusAdministration))
      setGoalBonusAdministration(newAdministration);

    if (typeof afterConfirm === "function") afterConfirm(newAdministration);
  };

  const saveNewTeam = async () => {
    const data = await getToolConfig();

    if (data && data.id) {
      saveAdministration({
        ...data,
        teams: [...data.teams, { ...form }],
      });
    }

    resetForm();
  };

  const editSaveTeam = async () => {
    const data = await getToolConfig();

    if (data && data.id) {
      const editingTeamIndex = getEditingIndex(data.teams);

      const updatedTeams = [...data.teams];
      updatedTeams[editingTeamIndex] = form;

      saveAdministration({
        ...data,
        teams: updatedTeams,
      });
    }
  };

  const handleConfirm = () => {
    if (!isEditing) saveNewTeam();
    if (isEditing) editSaveTeam();
  };

  const handleCancel = () => {
    resetForm();

    if (typeof afterCancel === "function") afterCancel();
  };

  const getFormErrors = () => {
    const errors = new Errors();

    if (form.name < 2) errors.add("invalid_title", "Título inválido.");

    const currentAreas = getAllExcept(
      [form.id],
      goalBonusAdministration.teams,
      "id",
    );
    const queryNames = getOnlySlugValues(currentAreas, "name").map((name) =>
      getComparableQuery(name),
    );

    if (queryNames.indexOf(getComparableQuery(form.name)) > -1) {
      errors.add("duplicated_title", "Já existe uma Área/Time com este nome.");
    }

    return errors.getErrors();
  };

  const formTitle = !isEditing
    ? intl.formatMessage(messages.gb_dialog_title_add_team)
    : intl.formatMessage(messages.gb_dialog_title_edit_team);

  const formErrors = getFormErrors();

  return (
    <FormDialog
      open={open}
      title={formTitle}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      blockConfirm={formErrors.length > 0}
    >
      <div className="row">
        <div className="col-xs-12">
          <MaterialTextField
            id="name"
            label={intl.formatMessage(messages.gb_form_title)}
            value={form.name}
            onChange={(e) => updateFormField("name", e.target.value)}
          />
          {getStyledErrorMessage(["duplicated_title"], formErrors)}
        </div>
      </div>
    </FormDialog>
  );
};

export default injectIntl(NewGoalsBonusTeamDialog);
