import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/styles";
import { Select, MenuItem } from "@material-ui/core";

const useStyles = makeStyles({
  textField: {
    padding: "0px !important",
    backgroundColor: "#f6f6f6 !important",
    "& > div > div": {
      width: "100%",
      height: "40px",
      fontSize: "14px !important",
    },
    "& > div > div:before": {
      borderBottom: "none !important",
    },
    "& > div > div > div": {
      paddingLeft: "10px !important",
    },
    "& > div > div > div.MuiInputAdornment-positionEnd": {
      position: "relative",
      left: "-12px",
    },
  },
  displayFields: {
    display: "flex",
  },
  menuItem: {
    fontSize: "14px !important",
  },
});

const SelectExcelCollumn = ({
  value,
  variant,
  handleChange,
  options,
  index,
  fieldName,
  idCycle,
  onClick,
  editCicle,
  label,
}) => {
  const classes = useStyles();

  function handleAction(index, fieldName, value, idCycle) {
    if (editCicle) {
      handleChange(index, fieldName, value, idCycle);
    }
  }

  return (
    <td className={classes.textField}>
      <div className={classes.displayFields}>
        <Select
          displayEmpty
          variant={variant}
          value={value || ""}
          MenuProps={{
            disableEnforceFocus: true,
          }}
          inputProps={{ readOnly: !editCicle }}
          onClick={onClick}
          onChange={(event, child) =>
            handleAction(index, fieldName, event.target.value, idCycle)
          }
        >
          <MenuItem disabled>
            <em>{label ? label : "Month"}</em>
          </MenuItem>
          {options &&
            options.map((item) => {
              return (
                <MenuItem
                  className={classes.menuItem}
                  value={item.label}
                  key={item.value}
                >
                  {item.label}
                </MenuItem>
              );
            })}
        </Select>
      </div>
    </td>
  );
};

SelectExcelCollumn.propTypes = {
  variant: PropTypes.string,
};

SelectExcelCollumn.defaultProps = {
  variant: "standard",
};

export default SelectExcelCollumn;
