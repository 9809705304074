import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { Button } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    minHeight: "auto",
    height: "35px",
  },
}))(MenuItem);

const StyledListIcon = withStyles((theme) => ({
  root: {
    minWidth: "auto",
    marginRight: "15px",
  },
}))(ListItemIcon);

const MaterialCustomMenu = ({ menuLabel, menuIcon, menuActions }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {menuIcon ? (
        <span onClick={handleClick} className="hover-menu-dots">
          {menuIcon}
        </span>
      ) : (
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          onClick={handleClick}
          className="fontSize20"
        >
          {menuLabel}
        </Button>
      )}

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableAutoFocusItem
        disableEnforceFocus={true}
        autoFocus={false}
      >
        {menuActions.map((actionCell, index) => {
          const actionText =
            actionCell && actionCell.text ? actionCell.text : "";
          const actionIcon =
            actionCell && actionCell.icon ? actionCell.icon : "";

          function callback() {
            const actionClick =
              typeof actionCell.action === "function"
                ? actionCell.action
                : () => {};

            handleClose();
            actionClick();
          }

          return (
            <StyledMenuItem onClick={() => callback()} key={index}>
              <StyledListIcon>{actionIcon}</StyledListIcon>
              <ListItemText primary={actionText} />
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
};

MaterialCustomMenu.propTypes = {
  menuLabel: PropTypes.string,
  menuIcon: PropTypes.node,
  menuActions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      text: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

MaterialCustomMenu.defaultProps = {
  menuLabel: "Open",
  menuIcon: <i className="fas fa-ellipsis-v"></i>,
  menuActions: [],
};

export default MaterialCustomMenu;
