import React from "react";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import DeleteColumn from "../../../../../../Common/Tables/DeleteColumn";
import {
  getEmptyTableRow,
  getDefaultAddTableLine,
} from "../../../../../../../utils/tableUtils";
import { translatedText } from "../../../../../../../utils/translationUtils";
import { simpleEditArrayOnAdministration } from "../../../../../../../utils/skillsAssessment/manipulation";

const messages = defineMessages({
  global_noneRegistered: { id: "global.noneRegistered" },
});

const translation = (id) => translatedText(id, messages);

const TableProfiles = ({
  toolAdministration = {},
  updateAdministration,
  handleAdd,
  openDeleteProfile,
}) => {
  const { profiles = [] } = toolAdministration;

  const handleEditProfile = (profileInfo, editingField = "title", newValue) => {
    if (profileInfo.id) {
      const updatedProfiles = {
        ...profileInfo,
        [editingField]: newValue,
      };

      const newAdministration = simpleEditArrayOnAdministration(
        toolAdministration,
        "profiles",
        profileInfo.id,
        updatedProfiles,
        "id",
      );

      updateAdministration(newAdministration);
    }
  };

  return (
    <table className="simple-table">
      <thead>
        {getDefaultAddTableLine(handleAdd, {}, "PERFIL DE AVALIAÇÃO")}
      </thead>
      <tbody>
        {profiles?.map((profileInfo, index) => {
          return (
            <tr key={profileInfo.id}>
              <td width="30" align="center">
                {index + 1}
              </td>
              <SimpleExcelCollumn
                id={`profileTitle${index}`}
                label={profileInfo.title}
                type="text"
                value={profileInfo.title}
                onChange={(e) =>
                  handleEditProfile(profileInfo, "title", e.target.value)
                }
              />
              <DeleteColumn
                handleDelete={() => openDeleteProfile(profileInfo.id)}
              />
            </tr>
          );
        })}
        {getEmptyTableRow(profiles, translation("global_noneRegistered"))}
      </tbody>
    </table>
  );
};

TableProfiles.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
  updateAdministration: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  openDeleteProfile: PropTypes.func.isRequired,
};

export default TableProfiles;
