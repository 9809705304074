import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const LoadingFallback = (props) => {
  const { showCircularProgress, open } = props;
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={open}>
      {showCircularProgress && <CircularProgress color="inherit" />}
    </Backdrop>
  );
};

LoadingFallback.propTypes = {
  showCircularProgress: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
};

LoadingFallback.defaultProps = {
  showCircularProgress: false,
  open: true,
};

export default LoadingFallback;
