import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Popover, Row } from "react-bootstrap";
import { defineMessages, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { fetchToolProcessConfigurations } from "components/Tool/ToolTabs/ProcessTool/store/toolProcessConfigurationsSlice";
import { PROCESSES_BIG_PICTURE } from "constants/tools.constants";
import { updateActivityInformation } from "../../../../actions/activityInformationActions";
import { updateInsightMaturity } from "../../../../actions/insightMaturityActions";
import { getTableHeadValues } from "../../../../constants/insightMaturity";
import { checkAccess } from "../../../../utils/accessLevels";
import utils from "../../../../utils/toolUtils";
import AverageBadge from "../../../Common/AverageBadge";
import TableEvaluation from "../../../Common/TableEvaluation";
import {
  getCompanyMembersAndParticipants,
  getSelectedToolStates,
  getUserProfileStates,
} from "../../../customMapStates";
import ActivitiesTable from "./ActivitiesTable";

const {
  getAverageEvaluation,
  getInsightMaturityById,
  getInsightInformationById,
} = utils;

const messages = defineMessages({
  maturityCriteria: {
    id: "tool.bigPictureProcesses.maturityCriteria",
  },
  activityDeliveryDeadline: {
    id: "tool.bigPictureProcesses.activityDeliveryDeadline",
  },
  automationLevel: { id: "tool.bigPictureProcesses.automationLevel" },
  knowledgeLevel: { id: "tool.bigPictureProcesses.knowledgeLevel" },
  levelOfManagementAndControl: {
    id: "tool.bigPictureProcesses.levelOfManagementAndControl",
  },
  qualityLevel: { id: "tool.bigPictureProcesses.qualityLevel" },
  insightMaturity: { id: "tool.bigPictureProcesses.insightMaturity" },
  activityInformation: { id: "tool.bigPictureProcesses.activityInformation" },
  peopleInvolved: { id: "tool.bigPictureProcesses.questions.peopleInvolved" },
  onePerson: {
    id: "tool.bigPictureProcesses.questions.peopleInvolved.onePerson",
  },
  twoPeople: {
    id: "tool.bigPictureProcesses.questions.peopleInvolved.twoPeople",
  },
  morePeople: {
    id: "tool.bigPictureProcesses.questions.peopleInvolved.morePeople",
  },
  executionTime: { id: "tool.bigPictureProcesses.questions.executionTime" },
  upOneHour: {
    id: "tool.bigPictureProcesses.questions.executionTime.upOneHour",
  },
  upFourHour: {
    id: "tool.bigPictureProcesses.questions.executionTime.upFourHour",
  },
  oneDay: { id: "tool.bigPictureProcesses.questions.executionTime.oneDay" },
  moreOneDay: {
    id: "tool.bigPictureProcesses.questions.executionTime.moreOneDay",
  },
  activityType: { id: "tool.bigPictureProcesses.questions.activityType" },
  daily: { id: "tool.bigPictureProcesses.questions.activityType.daily" },
  weekly: { id: "tool.bigPictureProcesses.questions.activityType.weekly" },
  biweekly: { id: "tool.bigPictureProcesses.questions.activityType.biweekly" },
  monthly: { id: "tool.bigPictureProcesses.questions.activityType.monthly" },
  eventual: { id: "tool.bigPictureProcesses.questions.activityType.eventual" },
  average: { id: "global.average" },
  globalSelect: { id: "global.select" },
  activityDetails: { id: "tool.bigPictureProcesses.activityDetails" },
  save: { id: "global.save" },
});

const popoverRowStyle = { marginLeft: "5px" };
const popoverStyle = { zIndex: "99999" };

const getCriteriaPopover = (toolProcessConfigurations, type) => {
  const { maturityCriteria } = toolProcessConfigurations;
  const { criteria } = maturityCriteria;
  const item = criteria.find((item) => item.type === type);

  return (
    <Popover id={`popover_criteria_${type}`} style={popoverStyle}>
      <Row style={popoverRowStyle}>{item.description}</Row>
    </Popover>
  );
};

const getTitlePopover = (toolProcessConfigurations) => (
  <Popover id="popoverCriteriaDescription" style={popoverStyle}>
    <Row style={popoverRowStyle}>
      {toolProcessConfigurations.maturityCriteria.description}
    </Row>
  </Popover>
);

let InsightMaturity = (props) => {
  const [firstMount, setFirstMount] = useState(true);
  const [intl, setIntl] = useState(props.intl);
  const [allMaturityData, setAllMaturityData] = useState(props.allMaturityData);
  const [allActivityInformation, setAllActivityInformation] = useState(
    props.allActivityInformation
  );
  const [peopleInvolved, setPeopleInvolved] = useState("");
  const [executionTime, setExecutionTime] = useState("");
  const [activityType, setActivityType] = useState("");
  const [activityDetails, setActivityDetails] = useState("");
  const [save, setSave] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const insightID = props.insight && props.insight.id ? props.insight.id : null;

  const {
    selectedTool = {},
    updateActivityInformation,
    companyMembers,
    allCompanyHierarchy,
    onAddCollaborator,
    toolProcessConfigurations,
    fetchToolProcessConfigurations: fetchToolProcessConfigurationsFunc,
  } = props;

  const hookUpdateActivityInformation = useCallback(() => {
    const updateInsightActivityInformation = () => {
      let currentActivityInfo = getInsightInformationById(
        insightID,
        allActivityInformation
      );

      currentActivityInfo = {
        peopleInvolved: currentActivityInfo.peopleInvolved
          ? currentActivityInfo.peopleInvolved
          : null,
        runtime: currentActivityInfo.runtime
          ? currentActivityInfo.runtime
          : null,
        activityType: currentActivityInfo.activityType
          ? currentActivityInfo.activityType
          : null,
        activityDetails: currentActivityInfo.activityDetails || null,
      };

      if (firstMount) {
        setPeopleInvolved(
          currentActivityInfo.peopleInvolved
            ? currentActivityInfo.peopleInvolved
            : ""
        );
        setExecutionTime(
          currentActivityInfo.runtime ? currentActivityInfo.runtime : ""
        );
        setActivityType(
          currentActivityInfo.activityType
            ? currentActivityInfo.activityType
            : ""
        );
        setActivityDetails(
          currentActivityInfo.activityDetails
            ? currentActivityInfo.activityDetails
            : ""
        );
      } else {
        const newActivityInfo = {
          peopleInvolved:
            peopleInvolved && peopleInvolved !== "empty"
              ? peopleInvolved
              : null,
          runtime:
            executionTime && executionTime !== "empty" ? executionTime : null,
          activityType:
            activityType && activityType !== "empty" ? activityType : null,
          activityDetails: activityDetails || null,
        };

        if (!_.isEqual(currentActivityInfo, newActivityInfo))
          updateActivityInformation(insightID, newActivityInfo);
      }

      setFirstMount(false);
      setShowSave(false);
      setSave(false);
    };

    updateInsightActivityInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activityType,
    allActivityInformation,
    executionTime,
    firstMount,
    insightID,
    peopleInvolved,
    save,
  ]);

  useEffect(() => {
    if (allActivityInformation) hookUpdateActivityInformation();
  }, [allActivityInformation, hookUpdateActivityInformation]);

  useEffect(() => {
    if (!showSave) setShowSave(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityDetails]);

  useEffect(() => {
    if (
      selectedTool &&
      selectedTool.id &&
      selectedTool.tool.id === PROCESSES_BIG_PICTURE
    ) {
      fetchToolProcessConfigurationsFunc(selectedTool.id);
    }
  }, []);

  useEffect(() => {
    hookUpdateActivityInformation();
  }, [
    peopleInvolved,
    executionTime,
    activityType,
    hookUpdateActivityInformation,
  ]);

  useEffect(() => {
    if (props.intl.locale !== intl.locale) {
      setIntl(props.intl);
    }
  }, [intl.locale, props.intl]);

  useEffect(() => {
    if (
      !_.isEqual(props.allActivityInformation, allActivityInformation) &&
      insightID
    ) {
      setAllActivityInformation(props.allActivityInformation);
      hookUpdateActivityInformation();
    }
  }, [
    allActivityInformation,
    insightID,
    props.allActivityInformation,
    hookUpdateActivityInformation,
  ]);

  useEffect(() => {
    if (!_.isEqual(props.allMaturityData, allMaturityData) && insightID) {
      setAllMaturityData(props.allMaturityData);
    }
  }, [allMaturityData, insightID, props.allMaturityData]);

  const getMaturityData = () => {
    const defautlValue = "NOT_APPLICABLE";

    const insightMaturity = getInsightMaturityById(insightID, allMaturityData);
    let tableOptions = [];

    tableOptions = [
      {
        id: "automationLevel",
        label: `A) ${intl.formatMessage(messages.automationLevel)}`,
        value:
          insightMaturity && insightMaturity.automationLevel
            ? insightMaturity.automationLevel
            : defautlValue,
        ...(toolProcessConfigurations && {
          popover: getCriteriaPopover(toolProcessConfigurations, "AUTOMATION"),
        }),
      },
      {
        id: "qualityLevel",
        label: `B) ${intl.formatMessage(messages.qualityLevel)}`,
        value:
          insightMaturity && insightMaturity.qualityLevel
            ? insightMaturity.qualityLevel
            : defautlValue,
        ...(toolProcessConfigurations && {
          popover: getCriteriaPopover(toolProcessConfigurations, "QUALITY"),
        }),
      },
      {
        id: "knowledgeLevel",
        label: `C) ${intl.formatMessage(messages.knowledgeLevel)}`,
        value:
          insightMaturity && insightMaturity.knowledgeLevel
            ? insightMaturity.knowledgeLevel
            : defautlValue,
        ...(toolProcessConfigurations && {
          popover: getCriteriaPopover(toolProcessConfigurations, "KNOWLEDGE"),
        }),
      },
      {
        id: "activityDeliveryDeadline",
        label: `D) ${intl.formatMessage(messages.activityDeliveryDeadline)}`,
        value:
          insightMaturity && insightMaturity.activityDeliveryDeadline
            ? insightMaturity.activityDeliveryDeadline
            : defautlValue,
        ...(toolProcessConfigurations && {
          popover: getCriteriaPopover(
            toolProcessConfigurations,
            "ACTIVITY_DELIVERY_DEADLINE"
          ),
        }),
      },
      {
        id: "levelOfManagementAndControl",
        label: `E) ${intl.formatMessage(messages.levelOfManagementAndControl)}`,
        value:
          insightMaturity && insightMaturity.levelOfManagementAndControl
            ? insightMaturity.levelOfManagementAndControl
            : defautlValue,
        ...(toolProcessConfigurations && {
          popover: getCriteriaPopover(
            toolProcessConfigurations,
            "MANAGEMENT_AND_CONTROL_LEVEL"
          ),
        }),
      },
    ];

    return tableOptions;
  };

  const tableOptionsToMaturityBody = (options) => {
    const maturityBody = {
      activityDeliveryDeadline: "",
      automationLevel: "",
      knowledgeLevel: "",
      levelOfManagementAndControl: "",
      qualityLevel: "",
    };

    options.forEach(({ id, value }) => {
      Object.keys(maturityBody).forEach((key) => {
        if (id === key) {
          maturityBody[key] = value;
        }
      });
    });

    return maturityBody;
  };

  const handleSave = (tableData) => {
    const body = tableOptionsToMaturityBody(tableData);

    if (insightID)
      props.updateInsightMaturity(insightID, body, selectedTool.id);
  };

  const tableOptions = getMaturityData();
  const maturityBody = tableOptionsToMaturityBody(tableOptions);
  const finalAverage = getAverageEvaluation(maturityBody);

  const buttonSave = (
    <Button className="btn btn-purple pull-right" onClick={() => setSave(true)}>
      {intl.formatMessage(messages.save)}
    </Button>
  );

  const companyMembersWithProfile = useMemo(() => {
    return companyMembers.map((companyMember) => {
      const { profile = {} } =
        allCompanyHierarchy?.find(
          ({ member }) => companyMember.id === member?.id
        ) || {};

      return {
        ...companyMember,
        profile,
      };
    });
  }, [companyMembers, allCompanyHierarchy]);

  return (
    <>
      <div className="box box-primary">
        <div className="box-header with-border">
          <h3 className="box-title">
            <i className="fas fa-balance-scale" />
            &nbsp;&nbsp;{intl.formatMessage(messages.insightMaturity)}
          </h3>
          <div className="box-tools pull-right">
            <div style={{ padding: "5px 0" }}>
              <AverageBadge
                average={finalAverage}
                label={intl.formatMessage(messages.average)}
              />
            </div>
          </div>
        </div>
        <div className="box-body">
          <div className="col-xs-12 col-md-12" style={{ overflowX: "auto" }}>
            <TableEvaluation
              title={intl.formatMessage(messages.maturityCriteria)}
              {...(toolProcessConfigurations && {
                titlePopover: getTitlePopover(toolProcessConfigurations),
              })}
              values={getTableHeadValues(intl, toolProcessConfigurations)}
              options={tableOptions}
              fullWidth
              readOnly={
                !checkAccess([
                  "CREATOR",
                  "RESPONSIBLE",
                  "COLLABORATOR",
                  "MANAGER",
                  "LEADER",
                  "MODERATOR",
                  "ADMIN",
                  "OWNER",
                ])
              }
              onSave={(tableData) => handleSave(tableData)}
              headTitleWidth="25%"
            />
          </div>
        </div>
      </div>
      <div className="box box-primary">
        <div className="box-header with-border">
          <h3 className="box-title">
            <i className="fas fa-clipboard-list" />
            &nbsp;&nbsp;{intl.formatMessage(messages.activityInformation)}
          </h3>
          {checkAccess([
            "CREATOR",
            "RESPONSIBLE",
            "COLLABORATOR",
            "MANAGER",
            "LEADER",
            "MODERATOR",
            "ADMIN",
            "OWNER",
          ]) &&
            showSave &&
            buttonSave}
        </div>
        <div className="box-body" style={{ width: "100%" }}>
          <ActivitiesTable
            insightId={insightID}
            companyMembers={companyMembersWithProfile}
            onAddCollaborator={onAddCollaborator}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { insightMaturity, activityInformation } = state;
  const allMaturityData =
    insightMaturity && insightMaturity.all ? insightMaturity.all : [];
  const allActivityInformation = activityInformation
    ? activityInformation.all
    : {};
  const { companyMembers } = getCompanyMembersAndParticipants(state);
  const { allCompanyHierarchy = {} } = getUserProfileStates(state);

  const { selectedTool } = getSelectedToolStates(state);

  const toolProcessConfigurations =
    state.toolProcessConfigurations.configurations;

  return {
    allMaturityData,
    allActivityInformation,
    selectedTool,
    companyMembers,
    allCompanyHierarchy,
    toolProcessConfigurations,
  };
};

export default InsightMaturity = injectIntl(
  connect(mapStateToProps, {
    updateInsightMaturity,
    updateActivityInformation,
    fetchToolProcessConfigurations,
  })(InsightMaturity)
);
