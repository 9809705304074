import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import {
  getSelectedToolStates,
  getCompAnalysisStates,
} from "../../customMapStates";
import { useThemeStyles } from "../../../jcss/themeStyles";
import { customAddTheme } from "../../../actions/structureActions";
import {
  updateTheme,
  archiveTheme,
  deleteTheme,
} from "../../../actions/themesActions";
import ThemeView from "./ThemeView";
import ArchiveDialog from "../../Common/ArchiveDialog";
import UnarchiveDialog from "../../Common/UnarchiveDialog";
import DeleteDialog from "../../Common/DeleteDialog";
import DragnDropContainer from "../../Common/DragnDropContainer";
import MaterialTextField from "../../Common/MaterialTextField";
import SimplePagination from "../../SimplePagination";
import { checkAccess } from "../../../utils/accessLevels";
import { toggleBoardEditMode } from "../../../actions/boardActions";
import { COMPETITIVE_ANALYSIS } from "../../../constants/tools.constants";
import { changeDateToWordMonth } from "../../../utils/DateUtils";
import {
  getAddEntityLabel,
  getCompetitiveAnalysisConfig,
  getEntityConfigurations,
} from "../../../utils/entityUtils";
import {
  getOnlySlugValues,
  iterationSearch,
  getGenericSimpleStringSearch,
} from "../../../utils/ArrayUtils";

const messages = defineMessages({
  theme_main_title: {
    id: "theme_main_title",
  },
  alertMessage: {
    id: "equal_theme_name_alert",
  },
  minLength: {
    id: "form_min_length",
  },
});

const defaultThemeForm = {
  id: null,
  type: "theme",
  cenary: {},
  name: "",
};

const defaultThemeDialog = {
  type: "",
  onConfirm: () => {},
};

const THEMES_PER_PAGE = 10;

function getPageThemes(themes, page) {
  const start = (page - 1) * THEMES_PER_PAGE;
  const end = page * THEMES_PER_PAGE;
  return themes.slice(start, end);
}

const ThemeContainer = (props) => {
  const [expanded, setExpanded] = useState([]);
  const [themeForm, setThemeForm] = useState(defaultThemeForm);
  const [themeDialog, setThemeDialog] = useState(defaultThemeDialog);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageThemes, setPageThemes] = useState([]);
  const [filter, setFilter] = useState("");
  const [goToLastPage, setGoToLastPage] = useState(false);

  const classes = useThemeStyles();

  const {
    themes = [],
    allQuestions,
    allAnswers,
    sceneryInfo = {},
    toggleInsightModal,
    toolsConfigurations,
    showHiddenQuestions,
    showHiddenAnswers,
    workspacesData,
    selectedTool,
    intl,
    selectedCustomTool,
    replaceConfig = {},
    questionRegistrations = [],
    okrPlans,
    currentToolType,
    editModeOn,
    toggleBoardEditMode,
    reorderEntity,
  } = props;

  function handlePageThemes(selectedPage, themesList) {
    setPageThemes(getPageThemes(themesList, selectedPage));
  }

  function handleFilter(newFilter) {
    const filteredThemes = newFilter
      ? getGenericSimpleStringSearch(themes, "name", newFilter)
      : themes;
    handlePageThemes(1, filteredThemes);
    setFilter(newFilter);
  }

  useEffect(() => {
    setTotalPages(Math.ceil(themes.length / THEMES_PER_PAGE));
    let newSelectedPage = page;

    if (newSelectedPage > totalPages) setPage(1);
    if (goToLastPage) {
      newSelectedPage = totalPages;
      setPage(newSelectedPage);
      setGoToLastPage(false);
    }

    if (!filter) {
      handlePageThemes(newSelectedPage, themes);
    } else {
      handleFilter(filter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themes]);

  function handlePageChange(newPage) {
    setPage(newPage);
    handlePageThemes(newPage, themes);
  }

  const toolId =
    selectedTool.tool && selectedTool.tool.id ? selectedTool.tool.id : null;
  const themesConfig = getEntityConfigurations("theme", toolId, replaceConfig);
  const competitiveAnalysisConfig =
    toolId === COMPETITIVE_ANALYSIS
      ? getCompetitiveAnalysisConfig("theme", currentToolType)
      : themesConfig;

  const handleReorder = (reorderedItems) => {
    if (Array.isArray(reorderedItems)) {
      if (!editModeOn) toggleBoardEditMode();

      const reordered = [];
      reorderedItems.forEach(({ content, order }) => {
        if (content.props && content.props.theme)
          reordered.push({
            ...content.props.theme,
            order,
          });
      });

      reorderEntity("themes", reordered, sceneryInfo.id);
    }
  };

  const handleExpand = (panelSlug) => {
    const updatedExpanded = [...expanded];

    const panelIndex = expanded.indexOf(panelSlug);

    if (panelIndex > -1) {
      updatedExpanded.splice(panelIndex, 1);
    } else {
      updatedExpanded.push(panelSlug);
    }

    setExpanded(updatedExpanded);
  };

  function getCustomLabel(themeInfo) {
    let label = "";

    if (selectedCustomTool.isOkr) {
      okrPlans.forEach((planInfo) => {
        planInfo.cycleGroups.forEach((cycleGroupInfo) => {
          cycleGroupInfo.cycles.forEach((cycleInfo) => {
            if (cycleInfo.id === themeInfo.okrConfigurationCycleId) {
              label = `${changeDateToWordMonth(
                cycleInfo.startDate,
              )} - ${changeDateToWordMonth(cycleInfo.endDate)}`;
            }
          });
        });
      });
    }

    return <span style={{ flex: "1" }}>{label}</span>;
  }

  function handleMiddlewareCustomization() {
    let custom = false;

    const paramProps = {
      themeScenerie: sceneryInfo,
      order: themes.length + 1,
    };

    if (themesConfig.registrationMiddleware) {
      custom = () => {
        props.customAddTheme(toolId, paramProps);
        setGoToLastPage(true);
      };
    }

    return custom;
  }

  const resetThemeForm = () => {
    setThemeForm(defaultThemeForm);
  };

  const resetThemeDialog = () => {
    setThemeDialog(defaultThemeDialog);
  };

  const validDuplicatedTitles = async (themeInfo) => {
    const themesValidation = [...themes].filter(
      (them) => them.id !== themeInfo.id,
    );
    const themesIterations = getOnlySlugValues(themesValidation, "name");

    const isDuplicated = await iterationSearch(
      themeInfo.name,
      themesIterations,
    );

    return isDuplicated;
  };

  const handleAddNewTheme = async () => {
    const middlewareCustomization = handleMiddlewareCustomization();
    const isDuplicated = await validDuplicatedTitles(themeForm);

    if (typeof middlewareCustomization === "function") {
      middlewareCustomization();
    } else if (!isDuplicated) {
      setThemeForm({
        ...defaultThemeForm,
        editing: true,
        new: true,
        cenary: sceneryInfo,
      });
    } else if (
      isDuplicated &&
      themeForm.name.trim().replace(/\s/g, "").length > 3
    ) {
      alert(intl.formatMessage(messages.alertMessage));
    } else {
      alert(intl.formatMessage(messages.minLength));
    }
  };

  const updateSaveTheme = async (newTheme) => {
    const isDuplicated = await validDuplicatedTitles(newTheme);

    if (!isDuplicated && newTheme.name.trim().replace(/\s/g, "").length > 3) {
      props.updateTheme(
        { ...themeForm, name: newTheme.name, order: themes.length },
        sceneryInfo.id,
      );
      setGoToLastPage(true);
    } else if (
      isDuplicated &&
      newTheme.name.trim().replace(/\s/g, "").length > 3
    ) {
      alert(intl.formatMessage(messages.alertMessage));
    } else {
      alert(intl.formatMessage(messages.minLength));
    }

    resetThemeForm();
  };

  function handleEditTheme(themeInfo = {}) {
    if (themeInfo.id) setThemeForm({ ...themeInfo, editing: true });
  }

  function handleArchiveTheme(themeInfo) {
    if (themeInfo && themeInfo.id) props.archiveTheme(themeInfo);
  }

  function handleDeleteTheme(themeInfo) {
    if (themeInfo && themeInfo.id)
      props.deleteTheme(themeInfo.id, sceneryInfo.id);
  }

  function openArchiveDialog(themeInfo) {
    setThemeDialog({
      type: "ARCHIVE_THEME",
      onConfirm: () => handleArchiveTheme(themeInfo),
    });
  }

  function openDeleteDialog(themeInfo) {
    setThemeDialog({
      type: "DELETE_THEME",
      onConfirm: () => handleDeleteTheme(themeInfo),
    });
  }

  function openUnarchiveDialog(themeInfo) {
    setThemeDialog({
      type: "UNARCHIVE_THEME",
      theme: themeInfo,
      onConfirm: () => handleArchiveTheme(themeInfo),
    });
  }

  function closeDialogAndConfirm() {
    if (themeDialog.onConfirm) themeDialog.onConfirm();

    resetThemeDialog();
  }

  const isAddingNewTheme = themeForm.editing && themeForm.new;

  const appendProps = {
    showHiddenQuestions,
    showHiddenAnswers,
    toggleInsightModal,
    workspacesData,
    toolsConfigurations,
    selectedTool,
    allQuestions,
    allAnswers,
    themesConfig,
    replaceConfig,
    questionRegistrations,
    reorderEntity,
    editModeOn,
    toggleBoardEditMode,
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="theme-container">
        <div style={{ paddingBottom: "15px" }}>
          <SimplePagination
            page={page}
            setPage={handlePageChange}
            totalPages={totalPages}
          />
          <MaterialTextField
            label="Pesquisar temas"
            value={filter}
            onChange={(e) => handleFilter(e.target.value)}
          />
        </div>
        <DragnDropContainer
          droppableId={`themesDropabbleListCID-${sceneryInfo.id}`}
          handleReorder={handleReorder}
          isDropDisabled={selectedCustomTool.isOkr}
          items={pageThemes.map((themeInfo) => {
            const themeQuestions = allQuestions[themeInfo.id] || [];
            const isEditingTheme =
              themeForm.editing && themeForm.id === themeInfo.id;

            return {
              id: themeInfo.id,
              order: themeInfo.order || 0,
              content: (
                <ThemeView
                  key={themeInfo.id}
                  show
                  expanded={expanded.indexOf(themeInfo.id) > -1}
                  editing={isEditingTheme}
                  handleExpand={handleExpand}
                  theme={themeInfo}
                  themeQuestions={themeQuestions}
                  saveUpdateTheme={({ name }) =>
                    updateSaveTheme({ ...themeInfo, name })
                  }
                  customLabel={() => getCustomLabel(themeInfo)}
                  cancelUpdate={() => resetThemeForm()}
                  handleEditTheme={() => handleEditTheme(themeInfo)}
                  openArchiveDialog={() => openArchiveDialog(themeInfo)}
                  openDeleteDialog={() => openDeleteDialog(themeInfo)}
                  openUnarchiveDialog={() => openUnarchiveDialog(themeInfo)}
                  {...appendProps}
                />
              ),
            };
          })}
        />
      </div>
      <ThemeView
        show={isAddingNewTheme}
        theme={{ name: themeForm.name }}
        isNew
        editing={isAddingNewTheme}
        saveUpdateTheme={(newTheme) => updateSaveTheme(newTheme)}
        cancelUpdate={() => resetThemeForm()}
      />
      {themesConfig.registrations &&
        checkAccess(["MANAGER", "LEADER", "MODERATOR", "ADMIN", "OWNER"]) &&
        competitiveAnalysisConfig.registrations &&
        !editModeOn && (
          <div style={{ padding: "15px 0px 0px 0px" }}>
            <Button
              size="small"
              color="primary"
              variant="contained"
              className={classes.addButton}
              onClick={handleAddNewTheme}
            >
              <AddIcon /> {getAddEntityLabel("theme", toolId, intl)}
            </Button>
          </div>
        )}
      <ArchiveDialog
        entityName={intl.formatMessage(messages.theme_main_title)}
        show={themeDialog.type === "ARCHIVE_THEME"}
        onConfirm={closeDialogAndConfirm}
        onCancel={resetThemeDialog}
      />
      <UnarchiveDialog
        action={intl.formatMessage({ id: "global.unArchive" })}
        entityName={intl.formatMessage(messages.theme_main_title)}
        show={themeDialog.type === "UNARCHIVE_THEME"}
        onConfirm={closeDialogAndConfirm}
        onCancel={resetThemeDialog}
      />
      <DeleteDialog
        entityName={intl.formatMessage(messages.theme_main_title)}
        show={themeDialog.type === "DELETE_THEME"}
        onConfirm={closeDialogAndConfirm}
        onCancel={resetThemeDialog}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { workspacesData } = state;
  const { selectedTool } = getSelectedToolStates(state);

  const { currentToolType = "" } = getCompAnalysisStates(state);

  return {
    selectedTool,
    workspacesData,
    currentToolType,
    okrPlans: state.okr.okrPlans,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    customAddTheme,
    updateTheme,
    archiveTheme,
    deleteTheme,
    toggleBoardEditMode,
  })(ThemeContainer),
);
