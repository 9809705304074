/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
import moment from "moment";
import { orderArrayByObjAttr } from "../ArrayUtils";

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

const defaultTargetsAndEvaluation = {
  goalPeriodTotalEvaluation: 0,
  courseTargetsDivision: {
    gate: 0,
    appropriated: 0,
    exceeded: 0,
  },
};

const defaultNotStartedGoal = {
  goalPercentage: 0,
  ...defaultTargetsAndEvaluation,
  accumulated: {
    goalPercentage: 0,
    ...defaultTargetsAndEvaluation,
  },
  notStarted: true,
};

const DATE_OFFSET = 100;

export function getSingleIndicatorResults(
  goalInfo = {},
  goalTargets = { gate: 70, appropriated: 100, exceeded: 130 },
  zeroWhenNegative = false
) {
  const isDate = goalInfo.measurementUnity === "DATE";
  const {
    goalPeriodTotalEvaluation,
    lastYearTotalEvaluation,
    lastYearCourseEvaluation,
    courseTargetsDivision,
    courseDistributionPercentage,
    periodsOnCourse,
    invalidDate,
    dateTargets,
  } = getTargetsAndEvaluation(goalInfo);

  if (isNaN(goalPeriodTotalEvaluation) || (isDate && invalidDate))
    return {
      ...defaultNotStartedGoal,
      lastYearTotalEvaluation,
      lastYearCourseEvaluation,
    };

  const { goalPercentage, hitTarget } = getGoalPercentageWithHit(
    goalPeriodTotalEvaluation,
    courseTargetsDivision,
    goalTargets,
    zeroWhenNegative
  );

  const acumulatedTarget = isDate ? courseTargetsDivision : goalInfo.target;
  const accumulated = getGoalPercentageWithHit(
    goalPeriodTotalEvaluation,
    acumulatedTarget,
    goalTargets,
    zeroWhenNegative
  );

  return {
    goalPercentage,
    hitTarget,
    accumulated: {
      ...accumulated,
      target: acumulatedTarget,
    },
    goalPeriodTotalEvaluation,
    lastYearTotalEvaluation,
    lastYearCourseEvaluation,
    courseTargetsDivision,
    courseDistributionPercentage,
    periodsOnCourse,
    dateTargets,
  };
}

export const multipleIndicatorsResult = (
  goals = [],
  goalTargets = {},
  filterActive = false,
  zeroWhenNegative
) => {
  let realGoalsReachSum = 0;
  const evaluatedGoals = [];

  if (goals.length > 0) {
    goals.forEach((goalInfo) => {
      const {
        goalPercentage,
        goalPeriodTotalEvaluation,
        courseTargetsDivision,
        hitTarget,
        notStarted,
        accumulated,
      } = getSingleIndicatorResults(goalInfo, goalTargets, zeroWhenNegative);

      if (!notStarted) {
        const displayPercentage = filterActive
          ? accumulated.goalPercentage
          : goalPercentage;
        const displayTargets = filterActive
          ? accumulated.target
          : courseTargetsDivision;

        realGoalsReachSum += parseFloat(displayPercentage);

        evaluatedGoals.push({
          ...goalInfo,
          goalPercentage: displayPercentage,
          goalPeriodTotalEvaluation,
          courseTargetsDivision: displayTargets,
          hitTarget,
        });
      }
    });
  }

  realGoalsReachSum =
    evaluatedGoals.length > 0 ? realGoalsReachSum / evaluatedGoals.length : 0;

  return {
    realGoalsReachSum,
    evaluatedGoals,
  };
};

export const getGoalPercentageWithHit = (
  actual = 0,
  courseTargetsDivision = {},
  goalTargetsConfig = {},
  zeroWhenNegative
) => {
  const vActual = parseFloat(actual);
  const vCourseTargetsDivision = {
    gate: parseFloat(Number(courseTargetsDivision.gate).toFixed(3)),
    appropriated: parseFloat(
      Number(courseTargetsDivision.appropriated).toFixed(3)
    ),
    exceeded: parseFloat(Number(courseTargetsDivision.exceeded).toFixed(3)),
  };
  const hitTarget = getHitTarget(vActual, vCourseTargetsDivision);

  let finalResult = 0;

  if (hitTarget === 0 || hitTarget === 3) {
    const compareBy =
      hitTarget === 0
        ? vCourseTargetsDivision.gate
        : vCourseTargetsDivision.exceeded;
    const basePercentage =
      hitTarget === 0 ? goalTargetsConfig.gate : goalTargetsConfig.exceeded;

    finalResult = getGateAndExceededGoalPercentage(
      vActual,
      compareBy,
      basePercentage,
      hitTarget === 0 ? "GATE" : "EXCEEDED"
    );
  }

  if (hitTarget === 1 || hitTarget === 2) {
    const goalTargets = {
      greater:
        hitTarget === 1
          ? goalTargetsConfig.appropriated
          : goalTargetsConfig.exceeded,
      smaller:
        hitTarget === 1
          ? goalTargetsConfig.gate
          : goalTargetsConfig.appropriated,
    };

    const measurementConfig = {
      greater:
        hitTarget === 1
          ? vCourseTargetsDivision.appropriated
          : vCourseTargetsDivision.exceeded,
      smaller:
        hitTarget === 1
          ? vCourseTargetsDivision.gate
          : vCourseTargetsDivision.appropriated,
    };

    finalResult = getGoalPercentage(goalTargets, measurementConfig, vActual);
  }

  finalResult = !isNaN(finalResult) ? finalResult : 0;
  finalResult = Math.round(finalResult * 100) / 100;

  return {
    goalPercentage: zeroWhenNegative && finalResult < 0 ? 0 : finalResult,
    hitTarget,
  };
};

export const handleGoalPercentage = (
  actual = 0,
  courseTargetsDivision = {},
  goalTargetsConfig = {},
  zeroWhenNegative
) => {
  const { goalPercentage } = getGoalPercentageWithHit(
    actual,
    courseTargetsDivision,
    goalTargetsConfig,
    zeroWhenNegative
  );

  return goalPercentage;
};

export const getGateAndExceededGoalPercentage = (
  actualVal,
  comparison,
  basePercentage = 100,
  target = "GATE"
) => {
  const zeroInput = Number(comparison) === 0;
  const compare = zeroInput ? comparison + 0.1 : comparison;
  const actual = zeroInput ? actualVal + 0.1 : actualVal;

  const absVariation = Math.abs(simpleSubtraction(actual, compare));

  if (target === "GATE") {
    const operationResult = absVariation * Math.abs(basePercentage / compare);
    return simpleSubtraction(basePercentage, operationResult);
  }

  if (target === "EXCEEDED") {
    if (actual === compare) return basePercentage;

    const operationResult = (absVariation / Math.abs(compare)) * basePercentage;

    return simpleSum(basePercentage, operationResult);
  }

  return 0;
};

export const simpleSum = (v1, v2) => {
  let sum = 0;

  // NAO ALTERAR A ORDEM DAS OPERACOES
  // PODE OCASIONAR EFEITOS COLATERAIS
  if (!isNaN(v1) && !isNaN(v2)) {
    sum = parseFloat(Number(v2)) + parseFloat(Number(v1));
  }

  return sum;
};

export const simpleSubtraction = (v1, v2) => {
  let subtracted = 0;

  // NAO ALTERAR A ORDEM DAS OPERACOES
  // PODE OCASIONAR EFEITOS COLATERAIS
  if (!isNaN(v1) && !isNaN(v2)) {
    subtracted = parseFloat(v1) - parseFloat(v2);
  }

  return subtracted;
};

export function getGoalPercentage(goalTargets, measurementConfig, actualVal) {
  const bonusGap =
    parseFloat(goalTargets.smaller) - parseFloat(goalTargets.greater);
  const measurementGap =
    parseFloat(measurementConfig.greater) -
    parseFloat(measurementConfig.smaller);
  const realGap = measurementConfig.greater - actualVal;
  const divisionGap = (realGap * bonusGap) / measurementGap;

  return parseFloat(goalTargets.greater) + parseFloat(divisionGap);
}

export function getTargetsAndEvaluation(goalInfo = {}) {
  if (goalInfo?.measurementUnity === "DATE")
    return getDateTargetsAndEvaluation(goalInfo);

  if (goalInfo?.measurementUnity)
    return getStandardMeasurementTargetsAndEvaluation(goalInfo);

  return defaultTargetsAndEvaluation;
}

export function getDateTargetsAndEvaluation(goalInfo = {}) {
  const dateTotalMeasurement = getDatePeriodTotalMeasurement(goalInfo, true);
  const refDate = getValidDateRef(dateTotalMeasurement, goalInfo.target);
  const lastPeriod =
    goalInfo.target?.lastPeriod?.length > 0 ? goalInfo.target?.lastPeriod : {};

  return {
    goalPeriodTotalEvaluation: getValidContableDate(
      dateTotalMeasurement,
      refDate
    ),
    courseTargetsDivision: getDataValidTargets(goalInfo.target, refDate),
    courseDistributionPercentage: 100,
    invalidDate: Number(dateTotalMeasurement) <= 0,
    lastYearCourseEvaluation: lastPeriod?.value || "",
    lastYearTotalEvaluation: lastPeriod?.value || "",
    dateTargets: {
      actual: goalInfo.target.actual[0],
      lastPeriod,
    },
  };
}

export const getDateTransformation = (dateValue, calculable = false) => {
  const vDateValue =
    typeof dateValue === "string" || typeof dateValue === "number"
      ? dateValue.toString()
      : "";

  return calculable
    ? vDateValue.replace(/-/g, "")
    : moment(vDateValue).format("YYYY-MM-DD");
};

const getValidDateRef = (actualDate, targets = { gate: 0, exceeded: 0 }) => {
  const polarity = getPolarity(targets);
  const smallerTargetDate =
    polarity === "GREATER" ? targets.gate : targets.exceeded;

  return transformToDays(actualDate) < transformToDays(smallerTargetDate)
    ? actualDate
    : smallerTargetDate;
};

export function getDatePeriodTotalMeasurement(
  goalInfo = {},
  calculable = true
) {
  const { value } = goalInfo?.target?.actual[0] || {};
  if (value) return getDateTransformation(value, calculable);

  return 0;
}

export function getPolarity(targets = {}) {
  return targets.gate < targets.appropriated ? "GREATER" : "SMALLER";
}

export function transformToDays(dateValue, refDate = null) {
  const vDateValue = dateValue ? dateValue.toString() : null;
  const vRefDate = refDate ? refDate.toString() : null;

  function short(ms) {
    return ms / (1000 * 60 * 60 * 24);
  }

  const hours = Number(moment(vDateValue).format("x"));
  const refHours =
    vRefDate && moment(vRefDate).isValid()
      ? Number(moment(vRefDate).format("x"))
      : 0;

  return vDateValue
    ? short(hours) - short(refHours) + DATE_OFFSET
    : 0 + DATE_OFFSET;
}

export function getValidContableDate(actualDate, refDate) {
  return !isNaN(actualDate) ? transformToDays(actualDate, refDate) : NaN;
}

export function getDataValidTargets(targets, refDate) {
  return {
    gate: getValidContableDate(targets.gate, refDate),
    appropriated: getValidContableDate(targets.appropriated, refDate),
    exceeded: getValidContableDate(targets.exceeded, refDate),
  };
}

export const getMomentDate = (year, monthIndex) => {
  if (monthIndex > -1 && year?.toString().length > 3) {
    const formattedMonth = moment().month(monthIndex).format("MM");
    const yearAndMonthString = `${year}-${formattedMonth}`;

    const startMoment = moment(`${yearAndMonthString}-01`);
    const daysInMonth = startMoment.daysInMonth();
    const endMoment = moment(`${yearAndMonthString}-${daysInMonth}`);

    return { start: startMoment, end: endMoment };
  }

  return { start: null, end: null };
};

export function isAverageOrBalance(goalInfo = {}) {
  if (goalInfo)
    return (
      goalInfo.measurement === "AVERAGE" || goalInfo.measurement === "BALANCE"
    );

  return false;
}

export function getStandardMeasurementTargetsAndEvaluation(
  goalInfo = {},
  isCurrentDate
) {
  if (goalInfo && goalInfo.measurement === "SUM")
    return getEvaluationAndTargetsBySum(goalInfo, isCurrentDate);

  if (goalInfo && isAverageOrBalance(goalInfo)) {
    return getEvaluationAndTargetsByAverageAndBalance(goalInfo, isCurrentDate);
  }

  return defaultTargetsAndEvaluation;
}

export const getStandardTargetsDivision = (goalInfo = {}, targetValue = 0) => {
  const divider = getIndicatorValidPeriods(goalInfo, false);
  const dividerCount = divider.length;

  if (dividerCount > 0) {
    if (isAverageOrBalance(goalInfo)) return parseFloat(targetValue);

    return parseFloat(targetValue) / dividerCount;
  }

  return targetValue;
};

export const getPeriodTargetsDivision = (
  goalInfo = {},
  distributionPercentage = 100
) => {
  const goalTarget = goalInfo.target || {};
  const multiplier = distributionPercentage * 0.01;

  return {
    gate: parseFloat(goalTarget.gate * multiplier),
    appropriated: parseFloat(goalTarget.appropriated * multiplier),
    exceeded: parseFloat(goalTarget.exceeded * multiplier),
  };
};

export const getLastYearValues = (goalInfo, periods) => {
  const lastYearValues = [];

  periods.forEach(({ index }) => {
    const { lastPeriodValue } = getTargetsByIndex(goalInfo, index);
    const { id, value } = lastPeriodValue;
    const validatedValue = getValidPeriodValue(value);

    if (Number.isFinite(validatedValue)) {
      lastYearValues.push({ id, index, value: validatedValue });
    }
  });

  return lastYearValues;
};

export const getTargetsByDistribution = (goalInfo, periodsOnCourse = []) => {
  const goalPeriodTotalEvaluation = getStandardPeriodTotalEvaluation(
    goalInfo,
    periodsOnCourse
  );

  const { courseDistributionPercentage } = getPeriodDistributions(
    goalInfo,
    periodsOnCourse
  );

  const targetsDivision = getPeriodTargetsDivision(
    goalInfo,
    courseDistributionPercentage
  );

  return {
    goalPeriodTotalEvaluation,
    courseDistributionPercentage,
    targetsDivision,
  };
};

export function getStandardMeasurementMathConfig(
  goalInfo = {},
  isCurrentDate = true
) {
  const periodsOnCourse = getActualOnCoursePeriods(goalInfo, isCurrentDate);
  const allPeriods = getIndicatorValidPeriods(goalInfo, false);

  const {
    goalPeriodTotalEvaluation,
    courseDistributionPercentage,
    targetsDivision,
  } = getTargetsByDistribution(goalInfo, periodsOnCourse);

  const lastYearCourseEvaluation =
    getStandardPeriodTotalEvaluation(
      goalInfo,
      getLastYearValues(goalInfo, periodsOnCourse)
    ) || "";

  const lastYearTotalEvaluation =
    getStandardPeriodTotalEvaluation(
      goalInfo,
      getLastYearValues(goalInfo, allPeriods)
    ) || "";

  return {
    goalPeriodTotalEvaluation,
    lastYearTotalEvaluation,
    lastYearCourseEvaluation,
    targetsDivision,
    periodsOnCourse,
    courseDistributionPercentage,
  };
}

export function sumObjectArray(a, objSlug) {
  let sum = 0;

  a.forEach((obj) => {
    if (obj[objSlug]) sum = simpleSum(sum, obj[objSlug]);
  });

  return sum;
}

export function getActualBalanceValue(goalInfo = {}, periodsOnCourse) {
  let finalValue = 0;

  const periods = [...periodsOnCourse].map(({ index }) => index);
  const { actual } = getOrderedTargets(goalInfo);

  actual.forEach(({ index }) => {
    const indexOf = periods.indexOf(index);

    if (indexOf > -1) {
      const periodInfo = periodsOnCourse[indexOf];
      const { value } = periodInfo;

      if (value !== "" && (value >= 0 || value < 0)) finalValue = value;
    }
  });

  return finalValue;
}

export function getStandardPeriodTotalEvaluation(
  goalInfo,
  periodsOnCourse = []
) {
  let finalReturn = 0;

  if (goalInfo.measurement === "SUM")
    finalReturn = sumObjectArray(periodsOnCourse, "value");

  if (goalInfo.measurement === "AVERAGE")
    finalReturn =
      sumObjectArray(periodsOnCourse, "value") / periodsOnCourse.length;

  if (goalInfo.measurement === "BALANCE") {
    finalReturn = getActualBalanceValue(goalInfo, periodsOnCourse);
  }

  return periodsOnCourse.length > 0 ? parseFloat(finalReturn) : NaN;
}

export function getEvaluationAndTargetsBySum(
  goalInfo = {},
  isCurrentDate = true
) {
  const {
    goalPeriodTotalEvaluation,
    lastYearTotalEvaluation,
    lastYearCourseEvaluation,
    targetsDivision,
    courseDistributionPercentage,
    periodsOnCourse,
  } = getStandardMeasurementMathConfig(goalInfo, isCurrentDate);

  return {
    goalPeriodTotalEvaluation,
    lastYearTotalEvaluation,
    lastYearCourseEvaluation,
    courseTargetsDivision: targetsDivision,
    courseDistributionPercentage,
    periodsOnCourse,
  };
}

export function getEvaluationAndTargetsByAverageAndBalance(
  goalInfo = {},
  isCurrentDate = true
) {
  const {
    goalPeriodTotalEvaluation,
    lastYearTotalEvaluation,
    lastYearCourseEvaluation,
    targetsDivision,
    courseDistributionPercentage,
    periodsOnCourse,
  } = getStandardMeasurementMathConfig(goalInfo, isCurrentDate);

  return {
    goalPeriodTotalEvaluation,
    lastYearTotalEvaluation,
    lastYearCourseEvaluation,
    courseTargetsDivision: targetsDivision,
    courseDistributionPercentage,
    periodsOnCourse,
  };
}

export function getIndicatorValidPeriods(
  goalInfo = {},
  relativeToCurrentDate = false
) {
  const validPeriods = [];

  if (goalInfo && goalInfo.target && Array.isArray(goalInfo.target.actual)) {
    const actual = goalInfo?.target?.actual || [];

    [...actual].forEach(({ id, value, index }) => {
      if (checkGoalPeriodInCourse(index, goalInfo, relativeToCurrentDate))
        validPeriods.push({ id, value, index });
    });
  }

  return validPeriods;
}

export function getFrequencyPeriodsInfo(frequency = "MONTHLY") {
  const defaultMonthly = { monthsPerPeriod: 1, periods: 12 };

  if (frequency === "MONTHLY") return defaultMonthly;

  if (frequency === "QUARTERLY") return { monthsPerPeriod: 3, periods: 4 };

  if (frequency === "FOUR_MONTHS") return { monthsPerPeriod: 4, periods: 3 };

  if (frequency === "SEMIANNUAL") return { monthsPerPeriod: 6, periods: 2 };

  if (frequency === "YEARLY") return { monthsPerPeriod: 12, periods: 1 };

  return defaultMonthly;
}

export function getMonthYear(monthIndex, period = {}) {
  const { year, start, end } = period;

  if (!year) return Number(currentYear);
  if (end > start) return Number(year);
  if (monthIndex >= start) return Number(year);
  if (monthIndex < start && monthIndex > end) return Number(year);

  return Number(year) + 1;
}

export function checkGoalPeriodInCourse(index, goalInfo, isCurrentDate) {
  const { period = {} } = goalInfo;

  if (!period?.year) return false;

  const normalizedMonthIndex = getNormalizedMonthIndex(index, goalInfo);
  const course = getTotalGoalCourseIndexByFrequency(period, isCurrentDate);

  const startMonth =
    course.start !== null && course.start >= 0 ? course.start : 0;
  const endMonth = course.end !== null && course.end >= 0 ? course.end : 0;
  const currentYearMonth = moment(currentDate).month();

  const monthYear = getMonthYear(normalizedMonthIndex, period);
  const monthOnCurrentYear = monthYear === currentYear;

  const pastYearAndMonthYear =
    monthYear === period.year && period.year < currentYear;

  const defaultCheck =
    normalizedMonthIndex >= period.start && normalizedMonthIndex <= period.end;

  if (pastYearAndMonthYear) {
    // Ano configurado (passado), ex: 2019
    if (period.start > period.end) return normalizedMonthIndex >= period.start;

    return defaultCheck;
  }

  if (period.year < currentYear && monthYear !== currentYear) {
    // Próximo ano (passado), ex: 2020
    if (period.start > period.end) {
      if (monthYear > period.year) return normalizedMonthIndex <= period.end;

      return normalizedMonthIndex >= period.start;
    }

    return defaultCheck;
  }

  if (period.year === currentYear || monthOnCurrentYear) {
    if (period.start > period.end) {
      // Ano atual (presente), ex: 2021
      if (isCurrentDate) {
        if (period.year !== currentYear) {
          return (
            normalizedMonthIndex <= currentYearMonth &&
            normalizedMonthIndex <= period.end
          );
        }

        return (
          normalizedMonthIndex >= startMonth &&
          normalizedMonthIndex <= currentYearMonth
        );
      }

      if (monthYear > period.year) return normalizedMonthIndex <= period.end;

      return normalizedMonthIndex >= period.start;
    }

    if (isCurrentDate)
      return (
        normalizedMonthIndex >= startMonth && normalizedMonthIndex <= endMonth
      );

    return defaultCheck;
  }

  if (period.year > currentYear) {
    // Próximo ano (futuro), ex: 2022+
    if (isCurrentDate) return false;
    return defaultCheck;
  }

  return false;
}

export function getOrdenationMonthIndex(
  realPeriodIndex = 0,
  frequency = "MONTHLY"
) {
  const transformedIndex = getTransformedPeriodRelativeToMonth(
    realPeriodIndex,
    frequency
  );

  const BASE = 12;
  const INITIAL_MONTH = 0;

  const restOfDivision = transformedIndex % BASE;

  return restOfDivision === 0 ? INITIAL_MONTH : restOfDivision;
}

export function getNormalizedMonthIndex(realPeriodIndex = 0, goalInfo = {}) {
  const { frequency = "MONTHLY", period } = goalInfo;
  const { monthsPerPeriod, periods } = getFrequencyPeriodsInfo(frequency);

  if (frequency === "MONTHLY")
    return getOrdenationMonthIndex(realPeriodIndex, "MONTHLY");

  let periodInfo = {};

  const { actual: actualTargetValues } = getOrderedTargets(goalInfo);

  actualTargetValues.forEach(({ value, index }, orderIndex) => {
    if (index === realPeriodIndex) periodInfo = { value, index, orderIndex };
  });

  const { index, orderIndex } = periodInfo;

  if (orderIndex === 0) return period.start;
  if (orderIndex === periods - 1) {
    const operation = period.end - monthsPerPeriod + 1;

    return operation < 0 ? 13 + period.end - monthsPerPeriod : operation;
  }

  const operation = index * monthsPerPeriod + period.start;
  return operation > 11
    ? 12 - period.start - index * monthsPerPeriod
    : operation;
}

export const getTransformedPeriodRelativeToMonth = (
  realPeriodIndex = 0,
  frequency = "MONTHLY"
) => {
  const { monthsPerPeriod } = getFrequencyPeriodsInfo(frequency);

  return realPeriodIndex * monthsPerPeriod;
};

export function getActualOnCoursePeriods(goalInfo = {}, isCurrentDate = true) {
  const indicatorValidPeriods = getIndicatorValidPeriods(
    goalInfo,
    isCurrentDate
  );
  const { registered } = getOnlyRegisteredValidPeriods(
    goalInfo,
    indicatorValidPeriods
  );

  return registered;
}

export function getValidPeriodValue(goalPeriodValue) {
  return goalPeriodValue !== 0 && !goalPeriodValue
    ? null
    : Number(goalPeriodValue);
}

export function getOnlyRegisteredValidPeriods(
  goalInfo = {},
  filteredPeriods = []
) {
  const registered = [];
  const notRegistered = [];

  if (Array.isArray(filteredPeriods)) {
    filteredPeriods.forEach(({ id, index, value }) => {
      const validatedValue = getValidPeriodValue(value);

      if (
        checkGoalPeriodInCourse(index, goalInfo, true) &&
        Number.isFinite(validatedValue)
      ) {
        registered.push({ id, index, value });
        return;
      }
      notRegistered.push({ id, index, value });
    });
  }

  return { registered, notRegistered };
}

export function getFlagedRegisteredActual(goalInfo, isCurrentDate = true) {
  const filteredResult = [];

  if (goalInfo?.id) {
    const indicatorValidPeriods = getIndicatorValidPeriods(
      goalInfo,
      isCurrentDate
    );

    const { registered } = getOnlyRegisteredValidPeriods(
      goalInfo,
      indicatorValidPeriods
    );

    const { actual } = getOrderedTargets(goalInfo);

    actual.forEach((periodInfo) => {
      const registeredFilter = registered.filter(
        ({ id }) => id === periodInfo.id
      );
      const isRegistered = registeredFilter[0]?.id > 0;

      filteredResult.push({ ...periodInfo, registered: isRegistered });
    });
  }

  return { actual: filteredResult };
}

export const getTotalGoalCourseIndexByFrequency = (
  dates = { year: null, start: null, end: null },
  relativeOfToday = true
) => {
  let traveled = { start: null, end: null };

  const year = dates.year || currentYear;

  const momentStart = getMomentDate(year, dates.start).start;
  let momentEnd = getMomentDate(year, dates.end).end;

  const diffOfTodayAndStartDate = moment(momentStart).diff(currentDate, "days");
  const diffOfTodayAndEndDate = moment(momentEnd).diff(currentDate, "days");

  const haveGoalStarted = diffOfTodayAndStartDate < 0;
  const haveGoalFinished = diffOfTodayAndEndDate < 0;
  const isGoalOnCourse = haveGoalStarted && !haveGoalFinished;

  if (relativeOfToday && !haveGoalStarted) return traveled;
  if (relativeOfToday && isGoalOnCourse) momentEnd = moment(currentDate);

  if (
    momentStart &&
    momentEnd &&
    momentStart.isValid() &&
    momentEnd.isValid()
  ) {
    const startDateMonthIndex = momentStart.month();
    const endDateMonthIndex = momentEnd.month();

    traveled = {
      start: startDateMonthIndex,
      end: endDateMonthIndex,
    };
  }

  return traveled;
};

export function getHitTarget(real = null, targets = {}) {
  if ((real === 0 || real) && !isNaN(real)) {
    const vReal = parseFloat(real);
    const vTargets = {
      gate: parseFloat(targets.gate),
      appropriated: parseFloat(targets.appropriated),
      exceeded: parseFloat(targets.exceeded),
    };

    const polarity = getPolarity(vTargets);

    if (polarity === "GREATER") {
      // Maior melhor
      if (vTargets.gate > vReal) return 0;
      if (vTargets.gate <= vReal && vTargets.appropriated > vReal) return 1;
      if (vTargets.appropriated <= vReal && vTargets.exceeded > vReal) return 2;
      if (vTargets.exceeded <= vReal) return 3;
    }

    if (polarity === "SMALLER") {
      // Menor melhor
      if (vTargets.gate < vReal) return 0;
      if (vTargets.gate >= vReal && vTargets.appropriated < vReal) return 1;
      if (vTargets.appropriated >= vReal && vTargets.exceeded < vReal) return 2;
      if (vTargets.exceeded >= vReal) return 3;
    }
  }

  return 0;
}

export const updateGoalActualArray = (goalInfo, validPeriods) => {
  if (Array.isArray(validPeriods))
    return {
      ...goalInfo,
      target: {
        ...goalInfo.target,
        actual: validPeriods.map((periodInfo) => periodInfo),
      },
    };

  return goalInfo;
};

export const getCustomPeriodsDistribution = (
  goalInfo,
  distributionArray,
  validPeriods = []
) => {
  const { actual: goalTargets } = getOrderedTargets(
    updateGoalActualArray(goalInfo, validPeriods)
  );
  const lastActual =
    goalTargets.length > 0 ? goalTargets[goalTargets.length - 1] : { index: 0 };
  const validPeriodsLength = validPeriods.length;

  function simpleRound(num) {
    return Number(Number(num).toFixed(2));
  }

  const distValues = [];

  if (Array.isArray(distributionArray))
    distributionArray.forEach((distInfo) => {
      if (distInfo) distValues.push(distInfo.value);
    });

  if (goalInfo && goalInfo.target) {
    const totalDistSum = distValues.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );

    if (goalInfo.measurement === "BALANCE") {
      const { distributionValue = {} } = getTargetsByIndex(
        goalInfo,
        lastActual.index
      );
      const { value: lastPeriodDist = 0 } = distributionValue;

      return {
        total: lastPeriodDist,
        isValid: simpleRound(lastPeriodDist) === 100,
        realDiff: lastPeriodDist - 100,
      };
    }

    if (goalInfo.measurement === "AVERAGE") {
      const average = totalDistSum / validPeriodsLength;

      return {
        total: average,
        isValid: simpleRound(average) === 100,
        realDiff: average - 100,
      };
    }

    return {
      total: totalDistSum,
      isValid: simpleRound(totalDistSum) === 100,
      realDiff: totalDistSum - 100,
    };
  }

  return { total: 0, isValid: false, realDiff: 100 };
};

export const getPeriodsDistributionInfo = (goalInfo = {}) => {
  const indicatorValidPeriods = getIndicatorValidPeriods(goalInfo, false);

  const { distribution } = getOrderedTargets(goalInfo);

  return getCustomPeriodsDistribution(
    goalInfo,
    distribution,
    indicatorValidPeriods
  );
};

export const getPeriodDistributions = (goalInfo, periodsOnCourse) => {
  const courseDistribution = [];
  const courseDistributionValues = [];

  if (Array.isArray(periodsOnCourse)) {
    periodsOnCourse.forEach(({ index }) => {
      const { distributionValue } = getTargetsByIndex(goalInfo, index);
      const { value: distValue = "" } = distributionValue;
      courseDistribution.push({ index, value: distValue });
      courseDistributionValues.push(distValue);
    });
  }

  const { total: courseDistributionPercentage } = getCustomPeriodsDistribution(
    goalInfo,
    courseDistribution,
    periodsOnCourse
  );

  return {
    courseDistribution,
    courseDistributionValues,
    courseDistributionPercentage,
  };
};

export const getActualPeriodTargets = (goalInfo) => {
  const start = [];
  const end = [];

  const { period = {}, target = {} } = goalInfo;
  const { actual = [] } = target;

  if (Array.isArray(actual)) {
    const ordered = orderArrayByObjAttr(actual, "index", false, true);

    ordered.forEach(({ id, value, index }, fakeIndex) => {
      const normalizedMonthIndex = getOrdenationMonthIndex(index, goalInfo);

      if (period.end < period.start) {
        if (normalizedMonthIndex >= period.start) {
          start.push({ id, value, index, fakeIndex });
        } else if (normalizedMonthIndex <= period.end) {
          end.push({ id, value, index, fakeIndex });
        } else if (
          normalizedMonthIndex < period.start &&
          normalizedMonthIndex > period.end
        ) {
          start.push({ id, value, index, fakeIndex });
        }
      } else {
        start.push({ id, value, index, fakeIndex });
      }
    });
  }

  return [...start, ...end];
};

export const getTargetsByIndex = (goalInfo, filterIndex) => {
  const { lastPeriod = [], distribution = [], actual = [] } = goalInfo?.target;

  const filteredActual = actual.filter(({ index }) => filterIndex === index);
  const filteredLastP = lastPeriod.filter(({ index }) => filterIndex === index);
  const filteredDist = distribution.filter(
    ({ index }) => filterIndex === index
  );

  return {
    actualValue: filteredActual[0] || { index: filterIndex, value: "" },
    lastPeriodValue: filteredLastP[0] || { index: filterIndex, value: "" },
    distributionValue: filteredDist[0] || { index: filterIndex, value: "" },
  };
};

export const getOrderedTargets = (goalInfo) => {
  const orderedActual = getActualPeriodTargets(goalInfo);

  const newLastPeriod = [];
  const newDistribution = [];

  const { lastPeriod = [], distribution = [] } = goalInfo?.target || {};
  if (Array.isArray(orderedActual))
    orderedActual.forEach(({ index }) => {
      const lastP = [...lastPeriod].filter(
        ({ index: fIndex }) => fIndex === index
      );
      const dist = [...distribution].filter(
        ({ index: fIndex }) => fIndex === index
      );

      if (lastP[0]) newLastPeriod.push(lastP[0]);
      if (dist[0]) newDistribution.push(dist[0]);
    });

  return {
    lastPeriod: newLastPeriod,
    distribution: newDistribution,
    actual: orderedActual,
  };
};

export const checkMonthIndexOnFilter = (goalInfo, realPeriodIndex, period) => {
  let onFilter = false;

  const mIndex = getNormalizedMonthIndex(realPeriodIndex, goalInfo);

  if (period.start > period.end) {
    if (mIndex >= period.start && mIndex <= 11) {
      onFilter = true;
    } else if (mIndex <= period.end) {
      onFilter = true;
    }
  } else if (mIndex >= period.start && mIndex <= period.end) {
    onFilter = true;
  }

  return onFilter;
};

export const readjustGoalByPeriod = (goalInfo, periodFilter) => {
  const newActual = [];
  const newDist = [];
  const newLastPeriod = [];
  const onCourse = [];

  const { actual, distribution, lastPeriod } = getOrderedTargets(goalInfo);

  let newTarget = { gate: 0, appropriated: 0, exceeded: 0 };

  if (Array.isArray(actual))
    actual.forEach(({ id, value, index }, orderIndex) => {
      const add = checkMonthIndexOnFilter(goalInfo, index, periodFilter);

      if (add) {
        newActual.push({ id, index, value });
        onCourse.push({ id, index, value });
        newDist.push(distribution[orderIndex]);
        newLastPeriod.push(lastPeriod[orderIndex]);
      } else {
        newActual.push({ ...id, index, value: "" });
        newDist.push({ ...distribution[orderIndex], value: 0 });
        newLastPeriod.push({ ...lastPeriod[orderIndex], value: "" });
      }
    });

  const { courseDistributionPercentage } = getPeriodDistributions(
    goalInfo,
    onCourse
  );
  newTarget = getPeriodTargetsDivision(goalInfo, courseDistributionPercentage);

  return {
    ...goalInfo,
    target: {
      ...goalInfo.target,
      ...newTarget,
      actual: newActual,
      distribution: newDist,
      lastPeriod: newLastPeriod,
    },
  };
};

export const filterGoalByPeriod = (goalInfo, periodFilter) => {
  const { start, end } = periodFilter;

  if (start >= 0 && end >= 0)
    return readjustGoalByPeriod(goalInfo, periodFilter);

  return goalInfo;
};

export const getPeriodTargetsByDistribution = (
  periodIndex = 0,
  goalInfo = []
) => {
  const { distributionValue = {} } = getTargetsByIndex(goalInfo, periodIndex);
  const { target: goalTargets = {}, measurementUnity } = goalInfo || {};
  const periodDistPercentage = distributionValue.value;
  const multiplier = periodDistPercentage * 0.01;
  const isDate = measurementUnity === "DATE";

  if (!periodDistPercentage) return { gate: 0, appropriated: 0, exceeded: 0 };

  return {
    gate: isDate
      ? parseFloat(goalTargets.gate).toFixed(0)
      : parseFloat(goalTargets.gate * multiplier).toFixed(2),
    appropriated: isDate
      ? parseFloat(goalTargets.appropriated).toFixed(0)
      : parseFloat(goalTargets.appropriated * multiplier).toFixed(2),
    exceeded: isDate
      ? parseFloat(goalTargets.exceeded).toFixed(0)
      : parseFloat(goalTargets.exceeded * multiplier).toFixed(2),
  };
};
