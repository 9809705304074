import { saveAs } from "file-saver";
import { getObjectInfoById } from "../../../../../utils/ArrayUtils";

export const addLines = (ws, data) => {
  if (ws && data) {
    data.forEach((line) => {
      line && ws.addRow(line);
    });
  }
};

export const addFakeHeader = (ws, fakeHeader) => {
  if (ws && fakeHeader && Object.keys(fakeHeader).length > 0) {
    if (ws.lastRow) ws.lastRow.hidden = true;

    ws.addRow({
      ...fakeHeader,
    });
  }
};

export const applyRowsConfig = (ws, rowsConfig) => {
  if (ws) {
    rowsConfig = Array.isArray(rowsConfig) ? rowsConfig : [];

    rowsConfig.forEach(({ line, fill }) => {
      applyRowFill(ws, line, fill);
    });
  }
};

export const applyRowFill = (ws, line, color) => {
  if (ws && ws.getRow && color) {
    ws.getRow(line).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: color },
    };
  }
};

export const createBuffer = (wb) => {
  if (wb && wb.xlsx) return wb.xlsx.writeBuffer();

  return () => {};
};

export const exportSaveFile = (
  buffer,
  filename = "default_file_name",
  format = ".xlsx",
) => {
  if (buffer) saveAs(new Blob([buffer]), filename + format);
};

export const getCellNumber = (headers, searchKey, searchFor = "key") => {
  if (headers && searchKey) {
    const index = getObjectInfoById(searchKey, headers, searchFor, true);
    const cellNumber = index + 1;

    if (cellNumber > 0) return cellNumber;
  }

  return false;
};
