import {
  getFilteredObjectsList,
  getOnlySlugValues,
  getAllExcept,
} from "../ArrayUtils";
import {
  getProfileSkills,
  getParticipantsRoles,
  checkHaveRoleSkillRelation,
  getComplementaryEvaluationsByType,
  getParticipantComplementaryEvaluations,
} from "./filter";
import {
  getWorkflowEntity,
  getComplementaryWorkflowEntity,
  getRoleSkillRelationEntity,
} from "./entity";

export const only_valid_ids = (arry, slug = "id") => {
  const filtered = [];

  if (Array.isArray(arry)) {
    arry.forEach((itemInfo) => {
      if (itemInfo[slug]) filtered.push(itemInfo[slug]);
    });
  }

  return filtered;
};

export const get_new_participant_skills = (participant, newAdministration) => {
  const {
    skills = [],
    workflow = [],
    competenceAreas = [],
  } = newAdministration;

  const { profile } = participant;

  const originalWorkflow = getFilteredObjectsList(
    [participant.id],
    workflow,
    "participantId",
  );

  const oldSkills = getOnlySlugValues(originalWorkflow, "skill", true);
  const oldSkillId = only_valid_ids(oldSkills, "id");

  const participantSkills = getProfileSkills(profile, competenceAreas, skills);

  return getAllExcept(oldSkillId, participantSkills, "id");
};

export const get_new_role_relations = (newAdministration) => {
  const { companyPositionsWithSkills } =
    getParticipantsRoles(newAdministration);

  return companyPositionsWithSkills;
};

export const get_participants_new_workflows = (newAdministration) => {
  const newWorkflows = [];

  const { participants, workflow } = newAdministration;
  const defaultWorkflow = getWorkflowEntity([{}], {
    canInsertNewId: true,
  })[0];

  participants.forEach((participant) => {
    const { id: participantId } = participant;

    const newParticipantSkills = get_new_participant_skills(
      participant,
      newAdministration,
    );

    newParticipantSkills.forEach((skill) => {
      if (
        skill?.id &&
        !workflow.find(
          (item) =>
            item.skillId === skill.id && item.participantId === participantId,
        )
      ) {
        newWorkflows.push({
          ...defaultWorkflow,
          participantId,
          skillId: skill.id,
        });
      }
    });
  });

  return newWorkflows;
};

export const get_new_complementary_evaluations = (newAdministration) => {
  let filtered = [];

  const { complementaryEvaluations, evaluationConfig } = newAdministration;

  if (evaluationConfig.keySeat) {
    filtered = [
      ...filtered,
      ...getComplementaryEvaluationsByType(
        complementaryEvaluations,
        "KEY_SEAT",
      ),
    ];
  }

  if (evaluationConfig.performance) {
    filtered = [
      ...filtered,
      ...getComplementaryEvaluationsByType(
        complementaryEvaluations,
        "PERFORMANCE",
      ),
    ];
  }

  if (evaluationConfig.succession) {
    filtered = [
      ...filtered,
      ...getComplementaryEvaluationsByType(
        complementaryEvaluations,
        "SUCCESSION",
      ),
    ];
  }

  return filtered;
};

export const get_participants_new_complementary_workflows = (
  newAdministration,
) => {
  const newComplementaryWorkflow = [];

  const { participants, complementaryWorkflow } = newAdministration;
  const defaultComplementaryWorkflow = getComplementaryWorkflowEntity([{}], {
    canInsertNewId: true,
  })[0];

  const newComplementaryEvaluations =
    get_new_complementary_evaluations(newAdministration);

  participants.forEach((participant) => {
    const { id: participantId } = participant;
    const participantComplementaryEvaluations =
      getParticipantComplementaryEvaluations(
        complementaryWorkflow,
        participant,
      );
    const evaluations = participantComplementaryEvaluations.map(({ id }) => id);

    newComplementaryEvaluations.forEach((complementaryEvaluation) => {
      const cwID = complementaryEvaluation?.id;
      if (cwID && participantId && evaluations.indexOf(cwID) === -1) {
        newComplementaryWorkflow.push({
          ...defaultComplementaryWorkflow,
          participant,
          participantId,
          complementaryEvaluation,
          complementaryEvaluationId: complementaryEvaluation.id,
        });
      }
    });
  });

  return newComplementaryWorkflow;
};

export const get_new_roles_to_relation = (
  newAddRolesToRelation,
  originalRelation,
) => {
  const newRelation = [];
  const defaultRoleSkillRelation = getRoleSkillRelationEntity([{}], {
    canInsertNewId: true,
  })[0];

  if (Array.isArray(newAddRolesToRelation)) {
    newAddRolesToRelation.forEach(
      ({ companyPosition: companyRole, skills }) => {
        skills.forEach((skill) => {
          if (
            companyRole &&
            skill &&
            skill.id &&
            !checkHaveRoleSkillRelation(
              companyRole.id,
              skill.id,
              originalRelation,
            )
          ) {
            newRelation.push({
              ...defaultRoleSkillRelation,
              companyRole,
              skill,
              skillId: skill.id,
              companyRoleId: companyRole.id
            });
          }
        });
      },
    );
  }

  return newRelation;
};
