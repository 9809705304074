import React, { useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Tab } from "react-bootstrap";

import ContainerTeamCicle from "./tabs/ContainerTeamCicle";
import ContainerObjKey from "./tabs/ContainerObjKey";

import { H2, TabsStyle } from "./css/OkrCss";

import utils from "../../../../../utils/toolUtils";
import { getDefaultRemoveColumn } from "../../../../../utils/okrUtils";
import DisplayTracking from "./tabs/DisplayTracking";
import ConfirmationDialog from "../../../../Common/ConfirmationDialog";

const { getDefaultAddTableLine } = utils;

const defaultConfirmDialog = {
  title: "",
  onConfirm: null,
  onCancel: null,
  open: false,
  params: {},
};

const defaultParams = {
  type: "",
  params: {},
};

const Administration = (props) => {
  const [confirmDialog, setConfirmDialog] = useState(defaultConfirmDialog);
  const [modalSlug, setModalSlug] = useState([]);
  const [editingForm, setEditingForm] = useState(defaultParams);
  const [activeTab, setActiveTab] = useState("teamCicle");

  const { intl } = props;

  function resetEditingForm() {
    setEditingForm(defaultParams);
  }

  function handleOpenModal(slug, open = false, params = {}) {
    const newSlugs = [...modalSlug];
    const indexOfSlug = newSlugs.indexOf(slug);

    if (!open && indexOfSlug > -1) {
      newSlugs.splice(newSlugs.indexOf(slug), 1);
      resetEditingForm();
    } else if (open && indexOfSlug === -1) {
      newSlugs.push(slug);
    }

    if (Object.keys(params).length > 0) {
      setEditingForm(params);
    }

    setModalSlug(newSlugs);
  }

  function resetDialog() {
    setConfirmDialog(defaultConfirmDialog);
  }

  function mountDialog(index, params) {
    const {
      handleDelete,
      getOpen,
      getTitle,
      getDescription,
      getConfirmText,
    } = params;

    function resetDelete(index) {
      handleDelete(index);
      resetDialog();
    }

    const append = getConfirmText ? { confirmText: getConfirmText() } : {};

    return {
      title: getTitle(),
      description: getDescription(),
      open: getOpen(),
      onCancel: () => resetDialog(),
      onConfirm: () => resetDelete(index),
      ...append,
    };
  }

  function openFormDelete(index, getParams) {
    setConfirmDialog(mountDialog(index, getParams));
  }

  const displayTabContent = (contentTab, TabContent) => {
    return (
      activeTab === contentTab && (
        <div className="tab-content" style={{ marginTop: "10px" }}>
          <div className="col-xs-12">{TabContent}</div>
        </div>
      )
    );
  };

  const defaultProps = {
    getDefaultAddTableLine,
    modalSlug,
    handleOpenModal,
    getDefaultRemoveColumn,
    resetDialog,
    openFormDelete,
    editingForm,
  };

  return (
    <>
      <ConfirmationDialog
        open={confirmDialog.open}
        title={confirmDialog.title}
        description={confirmDialog.description}
        onConfirm={confirmDialog.onConfirm || (() => {})}
        confirmText={confirmDialog.confirmText}
        onCancel={confirmDialog.onCancel}
        hideCancel={confirmDialog.hideCancel}
        confirmColor="#ff3151"
      />
      <H2 style={{ textTransform: "uppercase" }}>
        {intl.formatMessage({ id: "global.administration" })}
      </H2>
      <div className="custom-primary-tabs-adm">
        <TabsStyle
          id="okrAdministration"
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(key)}
        >
          <Tab
            eventKey="teamCicle"
            title={intl.formatMessage({ id: "scenerie_cycle" })}
          >
            {displayTabContent(
              "teamCicle",
              <ContainerTeamCicle {...defaultProps} />,
            )}
          </Tab>
          <Tab
            eventKey="objKey"
            title={intl.formatMessage({ id: "objective_key_result" })}
          >
            {displayTabContent("objKey", <ContainerObjKey {...defaultProps} />)}
          </Tab>
          <Tab
            eventKey="tracking"
            title={intl.formatMessage({ id: "tracking" })}
          >
            {displayTabContent("tracking", <DisplayTracking />)}
          </Tab>
        </TabsStyle>
      </div>
    </>
  );
};

export default injectIntl(Administration);

Administration.propTypes = {
  intl: PropTypes.object.isRequired,
};
