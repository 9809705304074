import { OKR_ID } from "../../constants/tools.constants";
import { getThemesWithGroupYears } from "../okr/filter";
import { orderArrayByObjAttr } from "../ArrayUtils";

export const getThemesOrderByTool = ({ toolId, originalAllThemes }) => {
  if (typeof originalAllThemes === "object") {
    if (toolId === OKR_ID) {
      return getThemesWithGroupYears(originalAllThemes);
    }

    const finalOrder = {};
    Object.keys(originalAllThemes).forEach((cenaryId) => {
      const ordered = orderArrayByObjAttr(
        originalAllThemes[cenaryId],
        "order",
        null,
        true,
      );
      finalOrder[cenaryId] = ordered;
    });

    return finalOrder;
  }

  return {};
};
