import React, { useState, useEffect } from "react";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import { getUpdatedObjectInArray } from "../../../../../../../utils/ArrayUtils";
import { getPestalScaleTranslation } from "../../../../../../../utils/cpAnalysis/display";
import _ from "lodash";

const Scales = (props) => {
  const {
    cpAnalysisAdministration = {},
    setCpAnalysisAdministration,
    intl,
  } = props;

  const { pestal = [] } = cpAnalysisAdministration || {};
  const { scales = [] } = pestal || {};

  const updatePestalField = (field = false, updated = "") => {
    if (!field) return;

    const pestalConfig = cpAnalysisAdministration.pestal || {};

    setCpAnalysisAdministration({
      ...cpAnalysisAdministration,
      pestal: {
        ...pestalConfig,
        [field]: [...updated],
      },
    });
  };

  const handleUpdateScale = (value = "", field = "", index = null) => {
    const updated = getUpdatedObjectInArray(value, scales, field, index);

    updatePestalField("scales", updated);
  };

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="2">Título</td>
        </tr>
      </thead>
      <tbody>
        {scales.map((scaleInfo, index) => {
          const title = getPestalScaleTranslation(scaleInfo.title || "");

          return (
            <tr className="block-hover" key={index}>
              <td align="center" style={{ width: "40px" }}>
                {index + 1}
              </td>
              <SimpleExcelCollumn
                id="scaleTitle"
                label="Titulo"
                type="text"
                value={title}
                onChange={(e) =>
                  handleUpdateScale(e.target.value, "title", index)
                }
              />
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Scales;
