import React from "react";
import _ from "lodash";
import SimpleBarChart from "../../../../../Common/SimpleBarChart";
import utils from "../../../../../../utils/toolUtils";

const { getLabelBySlug } = utils;

const InterestField = (props) => {
  const {
    filteredEvaluations,
    interestOptions,
    genderOptions,
    messages,
    intl,
  } = props;

  const displayInterestGraph = () => {
    const defaultVal = {
      CHANGE_AREA_PROJECT: 0,
      DEVELOP_MORE_IN_AREA: 0,
      EXPAND_IN_AREA: 0,
    };

    const filteredInterests = {
      MALE: _.cloneDeep(defaultVal),
      FEMALE: _.cloneDeep(defaultVal),
    };

    filteredEvaluations.forEach(({ peopleEvaluation }) => {
      const { evaluationTarget, professionalProfile } = peopleEvaluation;
      const { lastEvaluations } = evaluationTarget;
      const { interest } = lastEvaluations;
      const { current } = interest;
      const { gender } = professionalProfile;

      if (current && gender) {
        filteredInterests[gender][current] += 1;
      }
    });

    const finalData = [
      {
        name: getLabelBySlug("EXPAND_IN_AREA", interestOptions),
        value: filteredInterests.MALE.EXPAND_IN_AREA,
        secondaryValue: filteredInterests.FEMALE.EXPAND_IN_AREA,
      },
      {
        name: getLabelBySlug("DEVELOP_MORE_IN_AREA", interestOptions),
        value: filteredInterests.MALE.DEVELOP_MORE_IN_AREA,
        secondaryValue: filteredInterests.FEMALE.DEVELOP_MORE_IN_AREA,
      },
      {
        name: getLabelBySlug("CHANGE_AREA_PROJECT", interestOptions),
        value: filteredInterests.MALE.CHANGE_AREA_PROJECT,
        secondaryValue: filteredInterests.FEMALE.CHANGE_AREA_PROJECT,
      },
    ];

    return (
      <SimpleBarChart
        data={finalData}
        displaySecondaryValues
        secondaryFillColor="#fc7e7e"
        showLegend
        legendLabel={getLabelBySlug("MALE", genderOptions)}
        secondaryLegendLabel={getLabelBySlug("FEMALE", genderOptions)}
      />
    );
  };

  return (
    <div className="evaluation-card">
      <div className="card-header">
        <div className="card-title">
          <h4>{intl.formatMessage(messages.titles_cards_interests)}</h4>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-xs-12">{displayInterestGraph()}</div>
        </div>
      </div>
    </div>
  );
};

export default InterestField;
