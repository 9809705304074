const en = {
  skas_main_title: "Avaliação de Pessoas",
  skas_add_new_theme: "Adicionar Área",
  skas_add_new_question: "Adicionar Perfil",
  skas_add_new_insight: "Adicionar Participante",
  skas_assessment: "Assessment",
};

const es = {
  skas_main_title: "Avaliação de Pessoas",
  skas_add_new_theme: "Adicionar Área",
  skas_add_new_question: "Adicionar Perfil",
  skas_add_new_insight: "Adicionar Participante",
  skas_assessment: "Evaluación",
};

const pt = {
  skas_main_title: "Avaliação de Pessoas",
  skas_add_new_theme: "Adicionar Área",
  skas_add_new_question: "Adicionar Perfil",
  skas_add_new_insight: "Adicionar Participante",
  skas_assessment: "Avaliações",
};

const translations = { en, es, pt };

export default translations;
