import React from "react";

const CustomGraphTooltip = ({
  active,
  payload,
  label,
  customLabel,
  customFillColor,
  hideTooltipLabel,
  hideTooltipLabelName,
}) => {
  if (active) {
    return (
      <div
        style={{
          padding: "6px 10px",
          backgroundColor: "rgba(241,241,241, 0.9)",
          border: "1px solid #ccc",
          textAlign: payload.length > 1 ? "right" : "center",
          fontSize: "18px",
          color: "#333",
        }}
      >
        {!hideTooltipLabel && <div>{customLabel ? customLabel : label}</div>}
        {payload &&
          payload.map((entry, index) => {
            return (
              <div style={{ paddingTop: "10px" }} key={index}>
                {entry.name && !hideTooltipLabelName && (
                  <span
                    style={{
                      color: customFillColor ? customFillColor : entry.fill,
                      fontSize: "14px",
                      padding: "5px 0",
                    }}
                  >
                    {`${entry.name}: `}
                  </span>
                )}
                {entry.value}
              </div>
            );
          })}
      </div>
    );
  }

  return null;
};

export default CustomGraphTooltip;
