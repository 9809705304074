import React from "react";
import Select from "react-select";
import {
  getDefaultSuccessionBoolOptions,
  getDefaultReadinessOptions,
  workflowRequiredExceptions,
} from "../../../../../../../utils/skillsAssessment/defaults";
import { getComplementaryWorkflowValue } from "../../../../../../../utils/skillsAssessment/calc";
import { getSuccessionEvaluationInfo } from "../../../../../../../utils/skillsAssessment/constants";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";

const selectStyles = {
  container: (styles) => {
    return {
      ...styles,
      height: "100%",
    };
  },
  control: (styles) => {
    return {
      ...styles,
      height: "100%",
    };
  },
};

const WorkflowSuccessionEvaluation = ({
  workflow,
  handleUpdate,
  openSelectSuccessor,
  workflowOpenComments,
  renderComments,
  commentsCol,
  requiredExeptions = workflowRequiredExceptions,
  isInsight = false,
}) => {
  const boolOptions = getDefaultSuccessionBoolOptions();
  const readinessOptions = getDefaultReadinessOptions();

  function getOptions(workflowValueSlug, isRequired) {
    const selectOptions =
      workflowValueSlug === "alternativeValueBool"
        ? boolOptions
        : readinessOptions;

    return [
      { value: 0, label: `${isRequired ? "* " : ""}- Selecione uma opção -` },
      ...selectOptions,
    ];
  }

  return workflow?.map((workflowInfo) => {
    const { complementaryEvaluation } = workflowInfo;
    const { displayTitle, workflowValueSlug } = getSuccessionEvaluationInfo(
      complementaryEvaluation,
    );

    const displayComments = workflowOpenComments === workflowInfo.id;

    const value = getComplementaryWorkflowValue(workflowInfo);
    const { fullName: successorMember } =
      workflowInfo.alternativeValueUser || {};

    const isRequired = !requiredExeptions.includes(
      complementaryEvaluation.title,
    );

    const options = getOptions(workflowValueSlug, isRequired);
    const currentOption = options.find(
      (option) =>
        option.value === value ||
        (!value && option.value === 0 && value !== false),
    );

    return (
      <React.Fragment key={workflowInfo.id}>
        <tr>
          {commentsCol(workflowInfo.id)}
          <td colSpan="2">{displayTitle}</td>
          {["alternativeValueBool", "alternativeValueString"].includes(
            workflowValueSlug,
          ) && (
              <td className="input-td">
                {isInsight ? (
                  <div style={{ textAlign: 'center' }}>{currentOption.label}</div>
                ) : (
                  <Select
                    defaultValue={currentOption}
                    value={currentOption}
                    onChange={(option) =>
                      handleUpdate(workflowInfo, workflowValueSlug, option.value)
                    }
                    options={options}
                    styles={selectStyles}
                    menuPlacement="top"
                  />
                )}
              </td>
            )}
          {workflowValueSlug === "alternativeValueUser" && (
            <SimpleExcelCollumn
              id={`successionUser${workflowInfo.id}`}
              value={
                isInsight && !successorMember ? "Não selecionado" : (successorMember || `${isRequired ? "* " : ""}- Selecione -`)
              }
              onClick={() => openSelectSuccessor(workflowInfo)}
              inputStyle={{ cursor: "pointer" }}
              tdStyles={{ width: "300px" }}
              handleOnRemove={() =>
                handleUpdate(workflowInfo, "alternativeValueUser", null)
              }
            />
          )}
        </tr>
        {displayComments && renderComments(workflowInfo)}
      </React.Fragment>
    );
  });
};

export default WorkflowSuccessionEvaluation;
