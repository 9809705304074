import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  overflow-x: auto;
`;

export const CardContainer = styled.div`
  width: 290px;
  border-radius: 4px;
  margin: 0 10px;
  background: #f4f5f7;

  @media print {
    background: #f4f5f7 !important;
    -webkit-print-color-adjust: exact;
  }
`;

export const ListHeader = styled.div`
  display: flex;
  margin: 5px 5px 0 5px;
  font-size: 13px;
`;

export const ListObjectiveTitle = styled.span`
  color: #172b4d;
  font-weight: 400;
`;

export const ListTextStatic = styled.span`
  color: #626f87;
  margin-right: 5px;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-left: ${(props) => (props.color ? `3px solid ${props.color}` : "")};
  border-radius: 4px;

  div {
    font-weight: 500;
    color: #172b4d;
  }
`;

export const Bar = styled.div`
  @media print {
    background-color: #dedede !important;
  }
`;

export const Progress = styled.div`
  @media print {
    background: ${(props) => (props.color ? props.color : "")} !important;
  }
`;

export const CardList = styled.div`
  width: 290px;
  height: 550px;
  overflow: auto;
  padding: 5px 5px 0 5px;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 0.5em !important;
    scroll-behavior: smooth !important;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dfe1e6;
    border-radius: 10px !important;
  }
`;

export const List = styled.div`
  border-radius: 4px;
  background-color: #dfe1e6;
  margin-bottom: 5px;

  @media print {
    background: #dfe1e6 !important;
    -webkit-print-color-adjust: exact;
  }
`;

export const ListContainer = styled.div`
  margin: 5px;
  padding: 10px 15px;
  border-radius: 4px;
  background: #fff;
  position: relative;

  .status-bar {
    position: absolute;
    width: 3px;
    height: auto;
    left: 5px;
    top: 5px;
    bottom: 5px;
    background: ${(props) => (props.color ? props.color : "")} !important;
  }

  @media print {
    background: #fff !important;
    -webkit-print-color-adjust: exact;

    .status-bar {
      background-color: ${(props) =>
        props.color ? props.color : ""} !important;
      -webkit-print-color-adjust: exact;
    }
  }
`;

export const ListContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const AnswerText = styled.span`
  font-size: 14px;
  color: #172b4d;
  font-weight: 500;
`;

export const ListFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FooterProgress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const EvaluationText = styled.div`
  font-size: 13px;
  font-weight: 500;
`;

export const FooterBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  text-align: center;

  p {
    color: #626f87;
    font-size: 11px;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
`;
