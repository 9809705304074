import { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getCompAnalysisStates } from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";
import { doChangeThemeName } from "../../../../actions/themesActions";
import { buildFormAdmConfigBody } from "../../../../utils/cpAnalysis/entity";

const {
  getObjectDifference,
  getObjectInfoById,
  getOnlySlugValues,
  removeObjSlug,
} = utils;

const defaultForm = buildFormAdmConfigBody({});

const CompetitiveAnalysisWatcher = (props) => {
  const [cpAnalysisAdministration, setCpAnalysisAdministration] = useState(
    defaultForm,
  );
  const [firstValidation, setFirstValidation] = useState(false);

  const {
    currentAdministration = {},
    currentToolType = "",
    selectedTool = {},
    allThemes = {},
    doChangeThemeName,
  } = props;

  useEffect(() => {
    const toolTypeAdministration = currentAdministration[currentToolType];

    const toolTypeCpAnalysis = cpAnalysisAdministration[currentToolType];

    const isAdmObjDiff = !_.isEqual(toolTypeAdministration, toolTypeCpAnalysis);

    if (
      toolTypeAdministration &&
      toolTypeAdministration.analysisAmbient &&
      Object.keys(allThemes).length > 0
    ) {
      if (isAdmObjDiff || !firstValidation) {
        const compareBase = removeObjSlug(
          toolTypeCpAnalysis.analysisAmbient,
          "id",
        );

        const diff = getObjectDifference(
          toolTypeAdministration.analysisAmbient,
          compareBase,
        );

        if (diff) {
          if (!firstValidation) setFirstValidation(true);

          const watchGroups = getOnlySlugValues(diff, "id");

          if (watchGroups.length > 0) {
            Object.keys(allThemes).forEach((scenaryId) => {
              const cenaryThemes = allThemes[scenaryId];

              cenaryThemes.forEach((themeInfo) => {
                const {
                  competitiveAnalysisAmbientId,
                  id: themeId = "",
                } = themeInfo;
                const relatedGroupId =
                  watchGroups[
                    watchGroups.indexOf(competitiveAnalysisAmbientId)
                  ] || null;

                if (relatedGroupId && themeInfo.name) {
                  const groupInfo = getObjectInfoById(
                    relatedGroupId,
                    toolTypeAdministration.analysisAmbient,
                    "id",
                  );

                  if (groupInfo.title !== themeInfo.name) {
                    doChangeThemeName(themeId, groupInfo.title);
                  }
                }
              });
            });
          }
        }

        if (isAdmObjDiff) setCpAnalysisAdministration(currentAdministration);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allThemes,
    selectedTool.id,
    doChangeThemeName,
    currentAdministration,
    cpAnalysisAdministration,
  ]);

  return null;
};

const mapStateToProps = (state) => {
  const {
    currentAdministration = {},
    currentToolType = "",
  } = getCompAnalysisStates(state);

  return { currentAdministration, currentToolType };
};

export default connect(mapStateToProps, { doChangeThemeName })(
  CompetitiveAnalysisWatcher,
);
