import * as actions from "./_actions";
import send from "../dispatcher";

function observeCompanyEmitters({ stompClient, dispatch }) {
  stompClient.subscribe("/company/update", (response) => {
    send(actions.update, response, dispatch);
  });

  return stompClient;
}

export default observeCompanyEmitters;
