import React from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import utils from "../../../../../../../utils/toolUtils";
import gbUtils from "../../../../../../../utils/goalsBonus";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import { deleteRoleBonus } from "../../../../../../../actions/goalsAndBonusActions";
import {
  getEmptyTableRow,
  getDefaultAddTableLine,
} from "../../../../../../../utils/tableUtils";
import { getDefaultRemoveColumn } from "../../../../../../../utils/okrUtils";

const {
  getUpdatedDeleteArrayByIndex,
  getUpdatedObjectInArray,
  orderArrayByObjAttr,
  getOnlySublevelArrays,
  getObjectInfoById,
} = utils;

const { getParticipantsByRoleId } = gbUtils;

const messages = defineMessages({
  gb_table_text_accesses: { id: "gb_table_text_accesses" },
  gb_table_text_job_roles: { id: "gb_table_text_job_roles" },
  gb_table_text_salaries_quantity: { id: "gb_table_text_salaries_quantity" },
  gb_table_text_can_view: { id: "gb_table_text_can_view" },
  gb_table_text_workflow: { id: "gb_table_text_workflow" },
  gb_table_text_no_config: { id: "gb_table_text_no_config" },
  gb_form_job_role: { id: "gb_form_job_role" },
  gb_form_gate_salary: { id: "gb_form_gate_salary" },
  gb_form_appropriated_salary: { id: "gb_form_appropriated_salary" },
  gb_form_exceeded_salary: { id: "gb_form_exceeded_salary" },
  gb_confirm_delete_job_role: { id: "gb_confirm_delete_job_role" },
  gb_warning_connected_role_theme: { id: "gb_warning_connected_role_theme" },
  gb_warning_need_arquive_theme: { id: "gb_warning_need_arquive_theme" },
  gb_warning_remove_participant_title: {
    id: "gb_warning_remove_participant_title",
  },
  gb_warning_remove_participant_description: {
    id: "gb_warning_remove_participant_description",
  },
  global_selectedOnes: { id: "global.selectedOnes" },
});

const RoleBonusesTable = (props) => {
  const {
    setConfirmationDialog,
    goalBonusAdministration,
    setGoalBonusAdministration,
    getDefaultTargetCols,
    resetConfirmDialog,
    toggleOpenFormDialogs,
    handleSetEditingForm,
    allThemes = {},
    intl,
    deleteRoleBonus,
  } = props;

  const handleChangeRoleBonus = (
    newTarget,
    fieldSlug = "gate",
    compareIndex,
  ) => {
    const newUpdatedRoleBonuses = getUpdatedObjectInArray(
      newTarget,
      goalBonusAdministration.roleBonuses,
      fieldSlug,
      compareIndex,
    );

    setGoalBonusAdministration({
      ...goalBonusAdministration,
      roleBonuses: newUpdatedRoleBonuses,
    });
  };

  const handleDeleteRoleBonus = (index) => {
    const deletingObjInfo = goalBonusAdministration.roleBonuses[index];

    if (deletingObjInfo.id) {
      deleteRoleBonus(deletingObjInfo.id, goalBonusAdministration.id);

      const updatedRoleBonuses = getUpdatedDeleteArrayByIndex(
        goalBonusAdministration.roleBonuses,
        index,
      );

      setGoalBonusAdministration({
        ...goalBonusAdministration,
        roleBonuses: updatedRoleBonuses,
      });
    }

    resetConfirmDialog();
  };

  const getDeleteError = (roleInfo = {}) => {
    let error = {};

    const onlyThemes = getOnlySublevelArrays(allThemes);

    const relatedTheme = getObjectInfoById(
      roleInfo.id,
      onlyThemes,
      "goalsBonusProgramRoleBonusId",
    );

    const roleParticipants = getParticipantsByRoleId(
      roleInfo.id,
      goalBonusAdministration.participants,
    );

    const blockByTheme = relatedTheme && relatedTheme.id;
    const blockByParticipants = roleParticipants.length > 0;

    if (blockByTheme)
      error = {
        slug: "relatedTheme",
        title: intl.formatMessage(messages.gb_warning_connected_role_theme),
        description: intl.formatMessage(
          messages.gb_warning_need_arquive_theme,
          {
            themeName: relatedTheme.name,
          },
        ),
      };

    if (blockByParticipants)
      error = {
        slug: "relatedParticipants",
        title: intl.formatMessage(messages.gb_warning_remove_participant_title),
        description: (
          <span>
            {intl.formatMessage(
              messages.gb_warning_remove_participant_description,
            )}
            :{" "}
            <div>
              {roleParticipants.map((participantInfo) => {
                const { memberParticipant = {} } = participantInfo;

                return (
                  <h5>
                    {memberParticipant.name} -{" "}
                    <font style={{ fontWeight: "normal" }}>
                      {roleInfo.role}
                    </font>
                  </h5>
                );
              })}
            </div>
          </span>
        ),
      };

    return error;
  };

  const openDeleteRoleConfigDialog = (index) => {
    const deletingRoleBonus = goalBonusAdministration.roleBonuses[index] || {};

    if (deletingRoleBonus.id) {
      const deletingError = getDeleteError(deletingRoleBonus);
      const allowedToDelete = deletingError.slug ? false : true;

      setConfirmationDialog({
        title: allowedToDelete
          ? intl.formatMessage(messages.gb_confirm_delete_job_role)
          : deletingError.title,
        description: allowedToDelete ? null : deletingError.description,
        onConfirm: allowedToDelete
          ? () => handleDeleteRoleBonus(index)
          : resetConfirmDialog,
        confirmText: !allowedToDelete ? "OK" : null,
        hideCancel: !allowedToDelete ? true : false,
        onCancel: resetConfirmDialog,
        open: true,
      });
    }
  };

  let reorderedRoles = orderArrayByObjAttr(
    goalBonusAdministration.roleBonuses,
    "role",
  );

  return (
    <table className="simple-table">
      <thead>
        {getDefaultAddTableLine(() => toggleOpenFormDialogs("newRoleBonus"))}
      </thead>
      <tbody>
        <tr className="thead block-white-space">
          <td colSpan="2" />
          {getDefaultTargetCols()}
          <td colSpan="2">
            {intl.formatMessage(messages.gb_table_text_accesses)}
          </td>
          <td style={{ width: "40px" }} />
        </tr>
        <tr className="block-white-space border-bottom-division">
          <td colSpan="2" align="center">
            {intl.formatMessage(messages.gb_table_text_job_roles)}
          </td>
          <td colSpan="3" align="center">
            {intl.formatMessage(messages.gb_table_text_salaries_quantity)}
          </td>
          <td align="center">
            {intl.formatMessage(messages.gb_table_text_can_view)}
          </td>
          <td align="center">
            {intl.formatMessage(messages.gb_table_text_workflow)}
          </td>
          <td />
        </tr>
        {reorderedRoles.map((salarieBonusInfo, index) => {
          return (
            <tr className="block-hover" key={index}>
              <td align="center" style={{ width: "40px" }}>
                {index + 1}
              </td>
              <SimpleExcelCollumn
                id="participantRole"
                label={intl.formatMessage(messages.gb_form_job_role)}
                type="text"
                value={salarieBonusInfo.role}
                onClick={() =>
                  handleSetEditingForm("editRoleBonus", {
                    goalBonusAdministration,
                    roleBonusId: salarieBonusInfo.id,
                  })
                }
                inputStyle={{ cursor: "pointer" }}
              />
              <SimpleExcelCollumn
                id="gateSalariesConfig"
                label={intl.formatMessage(messages.gb_form_gate_salary)}
                type="number"
                value={salarieBonusInfo.gate}
                align="right"
                onChange={(e) =>
                  handleChangeRoleBonus(e.target.value, "gate", index)
                }
              />
              <SimpleExcelCollumn
                id="appropriatedSalariesConfig"
                label={intl.formatMessage(messages.gb_form_appropriated_salary)}
                type="number"
                value={salarieBonusInfo.appropriated}
                align="right"
                onChange={(e) =>
                  handleChangeRoleBonus(e.target.value, "appropriated", index)
                }
              />
              <SimpleExcelCollumn
                id="exceededSalariesConfig"
                label={intl.formatMessage(messages.gb_form_exceeded_salary)}
                type="number"
                value={salarieBonusInfo.exceeded}
                align="right"
                onChange={(e) =>
                  handleChangeRoleBonus(e.target.value, "exceeded", index)
                }
              />
              <SimpleExcelCollumn
                id="newRoleBonusSelect"
                label={intl.formatMessage(messages.gb_table_text_can_view)}
                value={`${
                  salarieBonusInfo.roleViewAccess.length
                } ${intl.formatMessage(messages.global_selectedOnes)}`}
                onClick={() =>
                  handleSetEditingForm("editRoleBonus", {
                    goalBonusAdministration,
                    roleBonusId: salarieBonusInfo.id,
                  })
                }
                inputStyle={{ cursor: "pointer" }}
              />
              <SimpleExcelCollumn
                id="newRoleBonusSelect2"
                label={intl.formatMessage(messages.gb_table_text_workflow)}
                value={`${
                  salarieBonusInfo.roleAccess.length
                } ${intl.formatMessage(messages.global_selectedOnes)}`}
                onClick={() =>
                  handleSetEditingForm("editRoleBonus", {
                    goalBonusAdministration,
                    roleBonusId: salarieBonusInfo.id,
                  })
                }
                inputStyle={{ cursor: "pointer" }}
              />
              {getDefaultRemoveColumn(() => openDeleteRoleConfigDialog(index))}
            </tr>
          );
        })}
        {getEmptyTableRow(
          goalBonusAdministration.roleBonuses,
          intl.formatMessage(messages.gb_table_text_no_config),
        )}
      </tbody>
    </table>
  );
};

export default injectIntl(connect(null, { deleteRoleBonus })(RoleBonusesTable));
