import React from "react";
import { defineMessages } from "react-intl";
import moment from "moment";
import { Avatar } from "@material-ui/core";
import { Popover } from "react-bootstrap";
import { getMomentDate } from "../DateUtils";
import { getObjectInfoById } from "../ArrayUtils";
import { translatedText } from "../translationUtils";
import {
  getCalculatedIndicators,
  getMathOperationsAsStringPreview,
} from "../selected-tool-metrics/indicator-metrics";
import { formatGoalValueByMeasurement } from "./formatters";

import {
  getHitTarget,
  exceededTargetMaxValue,
  getProcessGoalsReachInfo,
  getPeriodGoalDistributionPercentage,
  getCountGoalStatus,
  getCountProcessesGoalStatus,
  getBarBonusMeasurement,
  getComposedToolsApproximatedAverage,
  getIndividualIndicatorsResult,
} from "./calc";

import {
  getSingleIndicatorResults,
  checkGoalPeriodInCourse,
  getValidPeriodValue,
  getNormalizedMonthIndex,
  getMonthYear,
  getOrderedTargets,
  getPeriodTargetsByDistribution,
} from "./calc2";

import {
  getProcessRelationById,
  getIndicatorsRelationTree,
  getFilteredOverviewTree,
} from "./filter";
import { goalEntityBuild } from "./entity";

import {
  getIndicatorTargetOptions,
  getKpisFrequencyOptions,
  notStatedGoalReachDefault,
} from "../../constants/kpis";

import SimpleLegend from "../../components/Common/SimpleLegend";
import GoalListItem from "../../components/Common/GoalListItem";

const messages = defineMessages({
  kpi_table_text_scenarios: { id: "kpi_table_text_scenarios" },
  kpi_table_text_themes: { id: "kpi_table_text_themes" },
  kpi_table_text_subthemes: { id: "kpi_table_text_subthemes" },
  kpi_table_text_goals: { id: "kpi_table_text_goals" },
  kpi_message_goal_starting: { id: "kpi_message_goal_starting" },
  kpi_message_goal_started: { id: "kpi_message_goal_started" },
  kpi_message_goal_ending: { id: "kpi_message_goal_ending" },
  kpi_message_goal_ended: { id: "kpi_message_goal_ended" },
  kpi_options_rt_shared: { id: "kpi_options_rt_shared" },
  kpi_options_period_desc_quarterly: {
    id: "kpi_options_period_desc_quarterly",
  },
  kpi_options_period_desc_four_months: {
    id: "kpi_options_period_desc_four_months",
  },
  kpi_options_period_desc_semiannual: {
    id: "kpi_options_period_desc_semiannual",
  },
  kpi_options_period_desc_yearly: { id: "kpi_options_period_desc_yearly" },
  kpi_form_indicator_already_connected: {
    id: "kpi_form_indicator_already_connected",
  },
  global_upTo: { id: "global.upTo" },
  kpi_tab_administration_metrics: { id: "kpi_tab_administration_metrics" },
});

const translation = (id, values) => translatedText(id, messages, values);

export const getAdormentByMeasurement = (unitySlug, other = "") => {
  if (unitySlug === "PERCENTAGE") return "%";
  if (unitySlug === "MONETARY_VALUE") return "$";
  if (unitySlug === "THOUSAND_MONETARY_VALUE") return "$ mil";
  if (unitySlug === "MILLION_MONETARY_VALUE") return "$ mi";
  if (unitySlug === "QUANTITY") return "qtd";
  if (unitySlug === "INDEX") return "Ind";
  if (unitySlug === "TONNE") return "t";
  if (unitySlug === "KG") return "Kg";
  if (unitySlug === "KM") return "Km";
  if (unitySlug === "METERS") return "m";
  if (unitySlug === "CMETERS") return "m³";
  if (unitySlug === "LITERS") return "L";
  if (unitySlug === "OTHER") return other;

  return "";
};

export const getTargetInfoByHit = (hit = 0) => {
  const indicatorTargetOptions = getIndicatorTargetOptions();

  return getObjectInfoById(hit, indicatorTargetOptions, "hit");
};

export const getHitTargetInfo = (real, targets = {}, returnDefault = false) => {
  if (returnDefault === true)
    return notStatedGoalReachDefault.totalHitTargetInfo;

  const hitTarget = getHitTarget(real, targets);
  const targetInfo = getTargetInfoByHit(hitTarget);

  return { hitTarget, color: targetInfo.color, targetInfo };
};

export const getPeriodHitTargetInfo = (
  periodValue = 0,
  targets = {},
  measurementUnity = "MONETARY_VALUE",
) => {
  if (measurementUnity === "DATE" && periodValue === 0) {
    return getHitTargetInfo(0, { gate: 1.1, appropriated: 1.2, exceeded: 1.3 });
  }

  return getHitTargetInfo(periodValue, targets);
};

export const getDeviationColor = (positive, valid) => {
  if (valid) return positive ? "#31b41f" : "#ff6347";

  return "#ccc";
};

export const getPeriodFullInfo = (
  periodIndex = 0,
  periodValue = 0,
  goalDescriptions = {},
) => {
  const periodTargetsDistribution = getPeriodTargetsByDistribution(
    periodIndex,
    goalDescriptions,
  );

  const { measurementUnity } = goalDescriptions;
  const validatedTarget = getValidPeriodValue(periodValue);

  const isPeriodOnFullCourse = checkGoalPeriodInCourse(
    periodIndex,
    goalDescriptions,
    false,
  );
  const isPeriodOnTraveledCourse = checkGoalPeriodInCourse(
    periodIndex,
    goalDescriptions,
    true,
  );
  const isTraveledAndValid =
    isPeriodOnTraveledCourse && Number.isFinite(validatedTarget);

  const hitTargetInfo = getPeriodHitTargetInfo(
    periodValue,
    periodTargetsDistribution,
    measurementUnity,
  );

  return {
    isPeriodOnFullCourse,
    isPeriodOnTraveledCourse,
    isTraveledAndValid,
    hitTargetInfo,
    periodTargetsDistribution,
  };
};

export const getLabelPeriodIndex = (listIndex = 1, base = 12) => {
  const restOfDivision = listIndex % base;

  return restOfDivision === 0 ? base : restOfDivision;
};

export const getDisplayTitleIndex = (realIndex, fakeIndex, frequency) => {
  if (frequency === "MONTHLY") return Number(realIndex);

  return Number(fakeIndex);
};

export const getPeriodDivisionTitle = (
  periodIndex,
  fakeIndex,
  goalInfo = {},
  showYear = false,
) => {
  const { frequency, period = {} } = goalInfo;
  const displayIndex = getDisplayTitleIndex(periodIndex, fakeIndex, frequency);

  const incrementedIndex = displayIndex + 1;

  const normalizedMonthIndex = getNormalizedMonthIndex(periodIndex, goalInfo);
  const addYear = period.end < period.start || (showYear && period.year);
  const monthYear = getMonthYear(normalizedMonthIndex, period);
  const yearAppend = addYear ? ` (${monthYear})` : "";

  if (frequency === "QUARTERLY") {
    return `${incrementedIndex}º ${translation(
      "kpi_options_period_desc_quarterly",
    )}${yearAppend}`;
  }

  if (frequency === "FOUR_MONTHS") {
    return `${incrementedIndex}º ${translation(
      "kpi_options_period_desc_four_months",
    )}${yearAppend}`;
  }

  if (frequency === "SEMIANNUAL") {
    return `${incrementedIndex}º ${translation(
      "kpi_options_period_desc_semiannual",
    )}${yearAppend}`;
  }

  if (frequency === "YEARLY") {
    return `${incrementedIndex}º ${translation(
      "kpi_options_period_desc_yearly",
    )}${yearAppend}`;
  }

  return moment().month(periodIndex).format("MMM").toUpperCase() + yearAppend;
};

export const getRelativeDateGoalMessage = (
  yearPeriod,
  startMonthIndex,
  endMonthIndex,
) => {
  let finalMessage = "";
  const today = new Date();

  const startDate = getMomentDate(yearPeriod, startMonthIndex).start;
  const endDate = getMomentDate(yearPeriod, endMonthIndex).end;

  const startDateDiff = moment(startDate).diff(today, "days");
  const endDateDiff = moment(endDate).diff(today, "days");

  if (startDateDiff >= 0) {
    const date = moment(startDate, "YYYYMMDD").fromNow();
    finalMessage = translation("kpi_message_goal_starting", {
      date,
    });
  }

  if (startDateDiff < 0 && endDateDiff > 0) {
    const date = moment(startDate, "YYYYMMDD").fromNow();

    finalMessage = translation("kpi_message_goal_started", {
      date,
    });
  }

  if (endDateDiff >= 0 && Math.abs(startDateDiff) > Math.abs(endDateDiff)) {
    const date = moment(endDate, "YYYYMMDD").fromNow();

    finalMessage = translation("kpi_message_goal_ending", {
      date,
    });
  }

  if (endDateDiff < 0) {
    const date = moment(endDate, "YYYYMMDD").fromNow();

    finalMessage = translation("kpi_message_goal_ended", {
      date,
    });
  }

  return <h6 style={{ marginTop: "0px" }}>{finalMessage}</h6>;
};

export const getDefaultTargetCols = (
  style = { width: "140px" },
  removeFilterByValue = ["NOT_HIT"],
) => {
  const renderCols = [];

  const goalTargetOptions = getIndicatorTargetOptions();

  goalTargetOptions.forEach(({ value, label, color }) => {
    if (removeFilterByValue.indexOf(value) === -1) {
      renderCols.push({ label, color });
    }
  });

  return renderCols.map((colInfo) => (
    <td key={colInfo.label} align="center">
      <div style={style}>
        {colInfo.label}&nbsp;&nbsp;&nbsp;
        <SimpleLegend
          label=""
          fillColor={colInfo.color}
          styles={{ height: "0px" }}
        />
      </div>
    </td>
  ));
};

export const getSelectGoalSecondaryText = (goalInfo) => {
  const { relatedInsights = [], period = {} } = goalInfo;

  const frequencyOptions = getKpisFrequencyOptions();

  const haveConnectedInsights = relatedInsights.length > 0;
  const activeIndicatorMessage = haveConnectedInsights
    ? `${translation("kpi_form_indicator_already_connected")} |`
    : "";
  const indicatorFrequency = getObjectInfoById(
    goalInfo.frequency,
    frequencyOptions,
    "value",
  ).label;

  const startPeriod = getPeriodDivisionTitle(period.start, period.start, {
    frequency: "MONTHLY",
  });
  const endPeriod = getPeriodDivisionTitle(period.end, period.end, {
    frequency: "MONTHLY",
  });

  const final = `${activeIndicatorMessage} ${indicatorFrequency} - ${startPeriod} ${translation(
    "global_upTo",
  )} ${endPeriod} (${period.year})`;

  return goalInfo ? final : null;
};

export const displayTransformValue = (formValue, measurementUnity) => {
  return formatGoalValueByMeasurement(formValue, measurementUnity);
};

export const displayTargets = (
  formatedTarget,
  measurementUnity = "MONETARY_VALUE",
  displayDefault = false,
  defaultValue,
) => {
  let defaultVal = defaultValue || "";
  if (measurementUnity === "DATE") defaultVal = "MM-DD-YYYY";

  return !displayDefault
    ? displayTransformValue(formatedTarget, measurementUnity)
    : defaultVal;
};

export const getProcessPopover = (
  processRelation,
  goalTargets = {},
  zeroWhenNegative,
) => {
  const { processGoals = [], processInfo = {} } = processRelation;

  if (processGoals?.length === 0) return null;

  return (
    <Popover style={{ maxWidth: "800px" }} id="processPopover">
      {processInfo.title && (
        <h4 style={{ marginBottom: "0px" }}>{processInfo.title}</h4>
      )}
      <h5 style={{ fontWeight: "normal", marginTop: "5px" }}>
        {processGoals.length} {translation("kpi_table_text_goals")}
      </h5>
      {processGoals.map((goalInfo) => {
        const { goalPercentage, notStarted } = getSingleIndicatorResults(
          goalInfo,
          goalTargets,
          zeroWhenNegative,
        );

        const hitTargetInfo = getHitTargetInfo(
          goalPercentage,
          goalTargets,
          notStarted,
        );

        const isConsolidated = goalInfo.relationshipType === "SHARED";

        const goalTitle = (
          <span style={isConsolidated ? { fontWeight: "500" } : {}}>
            {isConsolidated && (
              <i
                className="fas fa-stream"
                style={{
                  color: "#ffc875",
                  fontSize: "17px",
                  paddingRight: "7px",
                  position: "relative",
                  top: "1px",
                }}
                title={translation("kpi_options_rt_shared")}
              />
            )}
            {goalInfo.title}
          </span>
        );

        return (
          <GoalListItem
            title={goalTitle}
            currentPercentage={goalPercentage}
            maxPercentage={exceededTargetMaxValue(goalTargets.exceeded)}
            progressColor={hitTargetInfo.color}
            barTotalWidth={100}
            key={goalInfo.id}
            avatar={
              <Avatar
                src={
                  goalInfo.avatarId
                    ? `https://splace-profile-images.s3-sa-east-1.amazonaws.com/${goalInfo.avatarId}`
                    : ""
                }
                style={{ width: "30px", height: "30px", marginLeft: "10px" }}
              />
            }
          />
        );
      })}
    </Popover>
  );
};

export const getThemePopover = (
  themeInfo = {},
  themeProcesses = [],
  goalTargets = {},
  onlyAccumulated = false,
  zeroWhenNegative,
  calculateAll = true,
) => {
  return (
    <Popover style={{ maxWidth: "800px" }} id="themePopover">
      <h4 style={{ marginBottom: "0px" }}>{themeInfo.name}</h4>
      <h5 style={{ fontWeight: "normal", marginTop: "5px" }}>
        {themeProcesses.length} {translation("kpi_table_text_subthemes")}
      </h5>
      {themeProcesses.map((processRelation) => {
        const {
          groupInfo = {},
          processGoals = [],
          processInfo,
        } = processRelation;

        if (processGoals.length === 0) return null;

        const { realGoalsReachSum, hitGoalsLevel } = getProcessGoalsReachInfo(
          processGoals,
          goalTargets,
          onlyAccumulated,
          zeroWhenNegative,
          calculateAll,
        );

        return (
          <GoalListItem
            title={processInfo.title}
            description={groupInfo.title}
            currentPercentage={realGoalsReachSum}
            maxPercentage={exceededTargetMaxValue(goalTargets.exceeded)}
            progressColor={hitGoalsLevel.color}
            barTotalWidth={100}
            key={processInfo.id}
          />
        );
      })}
    </Popover>
  );
};

export const getIndicatorPopover = (
  goalInfo = {},
  goalProcesses = [],
  displayProcesses = {},
  goalTargets = {},
  zeroWhenNegative,
) => {
  return (
    <Popover style={{ maxWidth: "800px" }} id="indicatorPopover">
      <h4 style={{ marginBottom: "0px" }}>{goalInfo.title}</h4>
      <h5 style={{ fontWeight: "normal", marginTop: "5px" }}>
        {goalProcesses.length} {translation("kpi_table_text_subthemes")}
      </h5>
      {goalProcesses.map((processInfo) => {
        const processRelation = getProcessRelationById(
          processInfo.id,
          displayProcesses,
        );

        if (processRelation) {
          const { processInfo = {}, processGoals = [] } = processRelation;

          const { realGoalsReachSum, hitGoalsLevel } = getProcessGoalsReachInfo(
            processGoals,
            goalTargets,
            false,
            zeroWhenNegative,
          );

          return (
            <GoalListItem
              title={processInfo.title}
              description={processInfo.description}
              currentPercentage={realGoalsReachSum}
              maxPercentage={exceededTargetMaxValue(goalTargets.exceeded)}
              progressColor={hitGoalsLevel.color}
              barTotalWidth={100}
              key={processInfo.id}
            />
          );
        }
        return null;
      })}
    </Popover>
  );
};

export const getPeriodPopover = (
  goalInfo,
  targetInfo,
  periodInfo = {},
  targetsConfig,
  isTraveledAndValid,
  zeroWhenNegative,
) => {
  const { goalPercentage } = getSingleIndicatorResults(goalInfo, targetsConfig);
  const monthName = getPeriodDivisionTitle(
    periodInfo.index,
    periodInfo.fakeIndex,
    goalInfo,
    zeroWhenNegative,
  );

  const periodPercentage = isTraveledAndValid
    ? getPeriodGoalDistributionPercentage(
        goalInfo,
        goalPercentage,
        periodInfo,
        targetsConfig,
      )
    : 0.0;

  return (
    <Popover id="periodPopover" style={{ maxWidth: "40px" }}>
      <div align="center">
        <h5>{`${targetInfo.label} - ${monthName}`}</h5>
        <h4 style={{ fontWeight: "normal" }}>
          <b>
            {`${displayTransformValue(
              periodInfo.value,
              goalInfo.measurementUnity,
            )}: `}
          </b>
          {`${periodPercentage}%`}
        </h4>
      </div>
    </Popover>
  );
};

export const getMetricDetailsPopover = ({
  tool_metric,
  kpiAdministration,
  filter,
  periodFilter,
}) => {
  const calculatedIndicators = getCalculatedIndicators(kpiAdministration, {
    filter,
    periodFilter,
  });
  const { indicatorMetrics, target } = tool_metric;

  return (
    <Popover style={{ maxWidth: "800px" }} id="metricsPopover">
      <h5 style={{ marginBottom: "15px" }}>{tool_metric.title}</h5>
      {tool_metric.description ? <p>{tool_metric.description}</p> : null}
      <center>
        <div style={{ margin: 0, fontWeight: "500", fontSize: "16px" }}>
          {getMathOperationsAsStringPreview({
            calculatedIndicators,
            indicatorMetrics,
          }).map((line, index) => {
            return <div key={index}>{line}</div>;
          })}
        </div>
        <h3
          style={{
            padding: "10px",
            margin: "0px",
            marginTop: "5px",
            borderTop: "1px solid #ccc",
          }}
        >
          {target?.actual?.formatted}
        </h3>
      </center>
    </Popover>
  );
};

export const renderKpiLabels = () => {
  return (
    <>
      <th style={{ textAlign: "left" }}>
        {translation("kpi_table_text_scenarios")}
      </th>
      <th style={{ textAlign: "left" }}>
        {translation("kpi_table_text_themes")}
      </th>
      <th style={{ textAlign: "left" }}>
        {translation("kpi_table_text_subthemes")}
      </th>
      <th style={{ textAlign: "left" }}>
        {translation("kpi_table_text_goals")}
      </th>
    </>
  );
};

export const getKpisCardData = (
  allKpiConfiguration = [],
  allSceneries = [],
  allThemes = {},
  allQuestions = {},
  allAnswers = {},
) => {
  const finalToolIndicatorPercentages = [];
  const indicatorWithRelatedInsights = [];
  const onlyIndicators = [];
  const indicatorStatusByTools = {
    APPROPRIATED: [],
    EXCEEDED: [],
    GATE: [],
    NOT_HIT: [],
  };
  const processStatusByTools = {
    APPROPRIATED: [],
    EXCEEDED: [],
    GATE: [],
    NOT_HIT: [],
  };
  const goalTargetsByTools = {
    gate: [],
    appropriated: [],
    exceeded: [],
  };

  function getAverageTargets(targetsArray) {
    function getArrayAverage(arry) {
      let sum = 0;
      const vArry = Array.isArray(arry) ? arry : [];

      vArry.forEach((val) => {
        sum += Number(val);
      });

      return sum / vArry.length;
    }

    return {
      gate: getArrayAverage(targetsArray.gate),
      appropriated: getArrayAverage(targetsArray.appropriated),
      exceeded: getArrayAverage(targetsArray.exceeded),
    };
  }

  function pushGoalStatus(slug, addList = [], selectedToolId) {
    addList.forEach((itemInfo) => {
      if (indicatorStatusByTools[slug])
        indicatorStatusByTools[slug].push({ selectedToolId, ...itemInfo });
    });
  }

  function pushProcessStatus(slug, addList = [], selectedToolId) {
    addList.forEach((itemInfo) => {
      if (processStatusByTools[slug])
        processStatusByTools[slug].push({ selectedToolId, ...itemInfo });
    });
  }

  function pushTargets(slug, addVal = 0) {
    if (goalTargetsByTools[slug]) goalTargetsByTools[slug].push(addVal);
  }

  allKpiConfiguration.forEach((kpiConfiguration) => {
    const { selectedToolId } = kpiConfiguration;

    const { tree: indicatorsRelationTree } = getIndicatorsRelationTree(
      selectedToolId,
      allSceneries,
      allThemes,
      allQuestions,
      allAnswers,
    );

    const filteredRelationTree = getFilteredOverviewTree(
      kpiConfiguration,
      indicatorsRelationTree,
      {},
    );

    if (kpiConfiguration.goals) {
      kpiConfiguration.goals.forEach((goalInfo) =>
        onlyIndicators.push(goalInfo),
      );
      const goalStatus = getCountGoalStatus(
        kpiConfiguration.goals,
        kpiConfiguration.goalTargets,
        false,
        kpiConfiguration.zeroWhenNegative,
      );

      pushGoalStatus("APPROPRIATED", goalStatus.APPROPRIATED, selectedToolId);
      pushGoalStatus("EXCEEDED", goalStatus.EXCEEDED, selectedToolId);
      pushGoalStatus("GATE", goalStatus.GATE, selectedToolId);
      pushGoalStatus("NOT_HIT", goalStatus.NOT_HIT, selectedToolId);
    }

    if (kpiConfiguration.processes && indicatorsRelationTree) {
      const processGoalStatus = getCountProcessesGoalStatus(
        filteredRelationTree,
        kpiConfiguration.goalTargets,
        false,
        kpiConfiguration.zeroWhenNegative,
      );

      pushProcessStatus(
        "APPROPRIATED",
        processGoalStatus.APPROPRIATED,
        selectedToolId,
      );
      pushProcessStatus("EXCEEDED", processGoalStatus.EXCEEDED, selectedToolId);
      pushProcessStatus("GATE", processGoalStatus.GATE, selectedToolId);
      pushProcessStatus("NOT_HIT", processGoalStatus.NOT_HIT, selectedToolId);
    }

    if (kpiConfiguration.goalTargets) {
      pushTargets("gate", kpiConfiguration.goalTargets.gate);
      pushTargets("appropriated", kpiConfiguration.goalTargets.appropriated);
      pushTargets("exceeded", kpiConfiguration.goalTargets.exceeded);
    }

    const { realGoalsReachSum } = getIndividualIndicatorsResult(
      kpiConfiguration.goals,
      kpiConfiguration.goalTargets,
      false,
      false,
      kpiConfiguration.zeroWhenNegative,
    );

    finalToolIndicatorPercentages.push(realGoalsReachSum);
  });

  onlyIndicators.forEach((indicatorInfo) => {
    if (indicatorInfo.relatedInsights.length > 0)
      indicatorWithRelatedInsights.push(indicatorInfo);
  });

  const toolAverageTargets = getAverageTargets(goalTargetsByTools);

  const finalRealApproximatedAverage = getComposedToolsApproximatedAverage(
    finalToolIndicatorPercentages,
  );

  let barMeasurement = getBarBonusMeasurement(
    finalRealApproximatedAverage,
    toolAverageTargets,
    35,
  );
  barMeasurement = finalRealApproximatedAverage <= 0 ? 0 : barMeasurement;

  return {
    indicatorWithRelatedInsights,
    barMeasurement,
    indicatorStatusByTools,
    processStatusByTools,
    finalRealApproximatedAverage,
    onlyIndicators,
  };
};

export const PeriodColumns = ({ periodCycle }) => {
  const goalDefault = goalEntityBuild({
    frequency: "MONTHLY",
    period: periodCycle,
  });

  const { actual: actualTargetValues } = getOrderedTargets(goalDefault);

  return actualTargetValues.map(({ index }) => {
    const shortMonth = moment().month(index).format("MMMM");
    const monthLetter = shortMonth.slice(0, 1).toUpperCase();

    return (
      <td colSpan="1" title={shortMonth} key={monthLetter + index}>
        {monthLetter}
      </td>
    );
  });
};
