import { FETCH_TRANSLATIONS } from "../actions/types";
import { readTranslations } from "../actions/translationsActions";
import { getLanguage } from "../actions/languageActions";

const INITIAL_STATE = readTranslations(getLanguage());

const translationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TRANSLATIONS:
      return action.payload;
    default:
      return state;
  }
};

export default translationsReducer;
