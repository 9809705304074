import { orderArrayByObjAttr } from "../ArrayUtils";
import { getSuccessionEvaluationInfo } from "./constants";

export const intlSuccessionQuestions = (successionEvaluations) => {
  const translated = [];

  if (Array.isArray(successionEvaluations)) {
    const ordered = orderArrayByObjAttr(successionEvaluations, "title");

    ordered.forEach((evaluationInfo) => {
      const { displayTitle } = getSuccessionEvaluationInfo(evaluationInfo);
      translated.push({ ...evaluationInfo, displayTitle });
    });
  }

  return translated;
};
