import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import NewGoalsBonusTeamDialog from "./NewGoalsBonusTeamDialog";
import FormDialog from "../../FormDialog";
import MaterialSingleSelect from "../../MaterialSingleSelect";
import { getSelectedToolStates } from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";
import { getGoalBonusConfiguration } from "../../../../actions/goalsAndBonusActions";
import { buildGoalsAdministrationFinalBody } from "../../../../utils/goalsBonus/entity";

const { getErrorMessage } = utils;

const useStyles = makeStyles(() =>
  createStyles({
    addButton: {
      fontSize: "12px",
    },
  }),
);

const messages = defineMessages({
  teamAlreadyAdded: { id: "global.teamAlreadyAdded" },
  gb_add_new_scenario: { id: "gb_add_new_scenario" },
  gb_form_select_team_area: { id: "gb_form_select_team_area" },
  btn_modal_register_new: { id: "btn_modal_register_new" },
});

const initialForm = {
  teamId: "",
};

const ScenerieTeamSelectDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const [newTeamModal, setNewTeamModal] = useState(false);

  const {
    open = false,
    afterCancel,
    afterConfirm,
    goalBonusAdministration,
    setGoalBonusAdministration,
    addedTeams = [],
    selectedToolID,
    intl,
  } = props;

  let { alternativeSaveTeamCallback } = props;
  alternativeSaveTeamCallback =
    typeof alternativeSaveTeamCallback === "function"
      ? alternativeSaveTeamCallback
      : () => {};

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  const getToolConfig = async () => {
    if (selectedToolID) {
      const { data } = await getGoalBonusConfiguration(selectedToolID);

      if (data && data.id) {
        const fetchedConfiguration = await buildGoalsAdministrationFinalBody(
          data,
        );

        return fetchedConfiguration;
      }
    }
  };

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleConfirm = () => {
    if (typeof afterConfirm === "function") afterConfirm(form);
  };

  const handleCancel = () => {
    if (typeof afterCancel === "function") afterCancel();
  };

  const handleUpdateForm = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const getFormErrors = () => {
    let errors = [];

    if ((!form.teamId || form.teamId === "empty") && form.teamId !== 0) {
      errors.push({ slug: "teamNotSelected" });
    }

    if (addedTeams.indexOf(form.teamId) > -1) {
      errors.push({
        slug: "duplicatedTeam",
        message: intl.formatMessage(messages.teamAlreadyAdded),
      });
    }

    return errors;
  };

  const buildTeamOptions = (teams) => {
    let finalOptions = [];

    if (teams && teams.length > 0) {
      teams.forEach(({ id, name }) =>
        finalOptions.push({ value: id, label: name }),
      );
    }

    return finalOptions;
  };

  const alternativeSave = (submitGoalsBonusAdm) => {
    toggleNewTeamModal(false);
    alternativeSaveTeamCallback(submitGoalsBonusAdm);
  };

  const toggleNewTeamModal = (toggleTo = true) => {
    setNewTeamModal(toggleTo);
  };

  const teamOptions = buildTeamOptions(goalBonusAdministration.teams);
  const classes = useStyles();
  const formErrors = getFormErrors();

  return (
    <>
      <NewGoalsBonusTeamDialog
        open={newTeamModal}
        goalBonusAdministration={goalBonusAdministration}
        setGoalBonusAdministration={setGoalBonusAdministration}
        afterConfirm={(submitGoalsBonusAdm) =>
          alternativeSave(submitGoalsBonusAdm)
        }
        afterCancel={() => toggleNewTeamModal(false)}
        getToolConfig={getToolConfig}
      />
      <FormDialog
        open={open}
        title={intl.formatMessage(messages.gb_add_new_scenario)}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        blockConfirm={formErrors.length > 0}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialSingleSelect
              id="teamSelect"
              label={intl.formatMessage(messages.gb_form_select_team_area)}
              value={form.teamId}
              options={teamOptions}
              onChange={(e) => handleUpdateForm("teamId", e.target.value)}
            />
            {getErrorMessage(["duplicatedTeam"], formErrors, "left", {
              height: "22px",
            })}
            <Button
              variant="outlined"
              className={classes.addButton}
              onClick={() => toggleNewTeamModal(!newTeamModal.open)}
            >
              <AddIcon /> {intl.formatMessage(messages.btn_modal_register_new)}
            </Button>
          </div>
        </div>
      </FormDialog>
    </>
  );
};

export default injectIntl(
  connect((state) => {
    const { selectedToolID } = getSelectedToolStates(state);
    return { selectedToolID };
  })(ScenerieTeamSelectDialog),
);
