import { CREATE_SELECTED_WORKSPACE_TOOL } from "../../../types";
import { checkToolWorkspaceInState } from "./_validations";
import { simpleNotifier } from "../../../notifierActions";

const DEBUG = false;
function debug(arg1, ...optional) {
  if (DEBUG) console.log("WebSockets: ", arg1, ...optional);
}

export const postNewTool = (newToolData) => {
  const { content: addedTool, details = {} } = newToolData;
  let { workspaceId = null } = details;
  workspaceId = workspaceId ? Number(workspaceId) : null;

  debug({ newToolData, addedTool, workspaceId });

  if (addedTool && addedTool.id && workspaceId) {
    if (!checkToolWorkspaceInState(workspaceId)) return;

    return (dispatch) => {
      debug("CREATE_SELECTED_WORKSPACE_TOOL", { addedTool });

      dispatch(
        simpleNotifier("Nova ferramenta adicionada ao Workspace", "success"),
      );
      dispatch({
        type: CREATE_SELECTED_WORKSPACE_TOOL,
        payload: addedTool,
      });
    };
  }
};
