export const FETCH_USER_TEAMS = "FETCH_USER_TEAMS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const FETCH_USER = "FETCH_USER";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const FETCH_TRANSLATIONS = "FETCH_TRANSLATIONS";
export const FETCH_USER_OWNER_COMPANIES = "FETCH_USER_OWNER_COMPANIES";
export const FETCH_USER_MEMBER_COMPANIES = "FETCH_USER_MEMBER_COMPANIES";
export const FETCH_ALL_COMPANY_WORKSPACES = "FETCH_ALL_COMPANY_WORKSPACES";
export const SET_SELECTED_COMPANY = "SET_SELECTED_COMPANY";
export const AVAILABLE_NAMESPACE = "AVAILABLE_NAMESPACE";
export const CREATE_COMPANY = "CREATE_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const USER_LOGOUT = "USER_LOGOUT";
export const CLOSE_ACCOUNT = "CLOSE_ACCOUNT";
export const SELECTED_COMPANY = "SPLACE_SELECTED_COMPANY";
export const SELECTED_LANGUAGE = "SPLACE_SELECTED_LANGUAGE";
export const USER_ID = "SPLACE_USER_ID";
export const FETCH_WORKSPACE_OVERVIEW = "FETCH_WORKSPACE_OVERVIEW";
export const FETCH_WORKSPACE_OVERVIEW_INSIGHT_COUNTER =
  "FETCH_WORKSPACE_OVERVIEW_INSIGHT_COUNTER";
export const FETCH_USER_WORKSPACES = "FETCH_USER_WORKSPACES";
export const FETCH_USER_OWNER_COMPANY_WORKSPACES =
  "FETCH_USER_OWNER_COMPANY_WORKSPACES";
export const FETCH_USER_MEMBER_COMPANY_WORKSPACES =
  "FETCH_USER_MEMBER_COMPANY_WORKSPACES";
export const FETCH_USER_MEMBER_COMPANY_TEAM_WORKSPACES =
  "FETCH_USER_MEMBER_COMPANY_TEAM_WORKSPACES";
export const GET_WORKSPACE_TEAM = "GET_WORKSPACE_TEAM";
export const CREATE_WORKSPACE = "CREATE_WORKSPACE";
export const SET_SELECTED_WORKSPACE = "SET_SELECTED_WORKSPACE";
export const SELECTED_WORKSPACE = "SET_SELECTED_WORKSPACE";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
export const USER_LOGIN = "USER_LOGIN";
export const SET_ACTIVE_ROUTE = "SET_ACTIVE_ROUTE";
export const FETCH_TOOLS = "FETCH_TOOLS";
export const FETCH_TOOL_POINTS = "FETCH_TOOL_POINTS";
export const FETCH_TOOL_POINTS_COUNTER = "FETCH_TOOL_POINTS_COUNTER";
export const FETCH_WORKSPACE_SELECTED_TOOLS = "FETCH_WORKSPACE_SELECTED_TOOLS";
export const CREATE_SELECTED_WORKSPACE_TOOL = "CREATE_SELECTED_WORKSPACE_TOOL";
export const SET_SELECTED_TOOL = "SET_SELECTED_TOOL";
export const UPDATE_SELECTED_TOOL = "UPDATE_SELECTED_TOOL";
export const SELECTED_TOOL_ID = "SELECTED_TOOL_ID";
export const DELETE_MEMBER = "DELETE_MEMBER";
export const FETCH_COMPANY_MEMBERS = "FETCH_COMPANY_MEMBERS";
export const FETCH_MEMBER_WITH_AREA = "FETCH_MEMBER_WITH_AREA";
export const FETCH_COMPANY_TEAMS = "FETCH_COMPANY_TEAMS";
export const CREATE_TEAM = "CREATE_TEAM";
export const CREATE_TEAM_MEMBER = "CREATE_TEAM_MEMBER";
export const FETCH_MEMBERS = "FETCH_MEMBERS";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const FETCH_USER_BY_EMAIL = "FETCH_USER_BY_EMAIL";
export const FETCH_USER_BY_CPF = "FETCH_USER_BY_CPF";
export const FETCH_USER_BY_DATA = "FETCH_USER_BY_DATA";
export const FETCH_TEAM_MEMBERS = "FETCH_TEAM_MEMBERS";
export const DELETE_TEAM_MEMBER = "DELETE_TEAM_MEMBER";
export const FETCH_CENARY_THEMES = "FETCH_CENARY_THEMES";
export const FETCH_WORKSPACE_MEMBERS = "FETCH_WORKSPACE_MEMBERS";
export const UPDATE_CENARY = "UPDATE_CENARY";
export const FETCH_CENARIES = "FETCH_CENARIES";
export const DELETE_CENARIE = "DELETE_CENARIE";
export const CLEAR_CENARIES = "CLEAR_CENARIES";
export const SELECT_CENARY = "SELECT_CENARY";
export const UPDATE_WORKSPACE_SELECTED_TOOLS =
  "UPDATE_WORKSPACE_SELECTED_TOOLS";
export const UPDATE_CENARY_THEME = "UPDATE_CENARY_THEME";
export const UPDATE_CENARY_RESPONSIBLE = "UPDATE_CENARY_RESPONSIBLE";
export const FETCH_QUESTION_ANSWERS = "FETCH_QUESTION_ANSWERS";
export const UPDATE_QUESTION_ANSWER = "UPDATE_QUESTION_ANSWER";
export const DELETE_QUESTION_ANSWER = "DELETE_QUESTION_ANSWER";
export const CHANGE_STATUS_ANSWER = "CHANGE_STATUS_ANSWER";
export const FETCH_THEME_QUESTIONS = "FETCH_QUESTIONS_THEMES";
export const FETCH_ALL_THEMES_QUESTIONS = "FETCH_ALL_THEMES_QUESTIONS";
export const UPDATE_THEME_QUESTION = "UPDATE_THEME_QUESTION";
export const SELECT_INSIGHT = "SELECT_INSIGHT";
export const UNSELECT_INSIGHT = "UNSELECT_INSIGHT";
export const UPDATE_INSIGHT_RESPONSIBLE = "UPDATE_INSIGHT_RESPONSIBLE";
export const SWAP_ORDER = "SWAP_ORDER";
export const DELETE_THEME_QUESTION = "DELETE_THEME_QUESTION";
export const DELETE_THEME = "DELETE_THEME";
export const FETCH_INSIGHT_TIMELINE = "FETCH_INSIGHT_TIMELINE";
export const FETCH_INSIGHTS_TIMELINE = "FETCH_INSIGHTS_TIMELINE";
export const ADD_INSIGHT_TIMELINE = "ADD_INSIGHT_TIMELINE";
export const SELECT_TOOLS_TIMELINE = "SELECT_TOOLS_TIMELINE";
export const ADD_INSIGHT_COMMENT = "ADD_INSIGHT_COMMENT";
export const EDIT_INSIGHT_COMMENT = "EDIT_INSIGHT_COMMENT";
export const ADD_TOOL_TIMELINE = "ADD_TOOL_TIMELINE";
export const CHANGE_SHOW_HIDDEN_ANSWERS = "CHANGE_SHOW_HIDDEN_ANSWERS";
export const CHANGE_SHOW_HIDDEN_THEMES = "CHANGE_SHOW_HIDDEN_THEMES";
export const CHANGE_SHOW_HIDDEN_CENARYS = "CHANGE_SHOW_HIDDEN_CENARYS";
export const CHANGE_SHOW_HIDDEN_QUESTIONS = "CHANGE_SHOW_HIDDEN_QUESTIONS";
export const REAL_INSIGHT_SCORE = "REAL_INSIGHT_SCORE";
export const UPDATE_INSIGHT_DATA = "UPDATE_INSIGHT_DATA";
export const FETCH_INSIGHT_TRACEABILITIES = "FETCH_INSIGHT_TRACEABILITIES";
export const FETCH_INSIGHTS_TRACEABILITIES = "FETCH_INSIGHTS_TRACEABILITIES";
export const FETCH_ALL_INSIGHT_TRACEABILITIES =
  "FETCH_ALL_INSIGHT_TRACEABILITIES";
export const UPDATE_ALL_INSIGHT_TRACEABILITIES =
  "UPDATE_ALL_INSIGHT_TRACEABILITIES";
export const CHANGE_SHOW_INSIGHT_RESUME = "CHANGE_SHOW_INSIGHT_RESUME";
export const FETCH_CENARY_MEMBERS = "FETCH_CENARY_MEMBERS";
export const CREATE_CENARY_MEMBER = "CREATE_CENARY_MEMBER";
export const DELETE_CENARY_MEMBER = "DELETE_CENARY_MEMBER";
export const FETCH_INSIGHT_RESPONSABLE = "FETCH_INSIGHT_RESPONSABLE";
export const ADD_INSIGHT_ATTACHMENT = "ADD_INSIGHT_ATTACHMENT";
export const FETCH_INSIGHT_ATTACHMENTS = "FETCH_INSIGHT_ATTACHMENTS";
export const DELETE_INSIGHT_ATTACHMENT = "DELETE_INSIGHT_ATTACHMENT";
export const FETCH_INSIGHT_FOLLOWUP = "FETCH_INSIGHT_FOLLOWUP";
export const ADD_INSIGHT_FOLLOWUP = "ADD_INSIGHT_FOLLOWUP";
export const UPDATE_INSIGHT_FOLLOWUP = "UPDATE_INSIGHT_FOLLOWUP";
export const DELETE_INSIGHT_FOLLOWUP = "DELETE_INSIGHT_FOLLOWUP";
export const FETCH_INSIGHT_GOAL = "FETCH_INSIGHT_GOAL";
export const ADD_INSIGHT_GOAL = "ADD_INSIGHT_GOALS";
export const UPDATE_INSIGHT_GOAL = "UPDATE_INSIGHT_GOALS";
export const DELETE_INSIGHT_GOAL = "DELETE_INSIGHT_GOALS";
export const ADD_INSIGHT_TAG = "ADD_INSIGHT_TAG";
export const FETCH_INSIGHT_TAGS = "FETCH_INSIGHT_TAGS";
export const REMOVE_INSIGHT_TAG = "REMOVE_INSIGHT_TAG";
export const CHANGE_STATUS_TEMPLATE = "CHANGE_STATUS_TEMPLATE";
export const FETCH_COMPANY_PARTICIPANTS = "FETCH_COMPANY_PARTICIPANTS";
export const FETCH_WORKSPACE_PARTICIPANTS = "FETCH_WORKSPACE_PARTICIPANTS";
export const UPDATE_INSIGHT_MATURITY = "UPDATE_INSIGHT_MATURITY";
export const FETCH_ALL_MATURITY_DATA = "FETCH_ALL_MATURITY_DATA";
export const FETCH_ALL_SCENERIES_THEMES = "FETCH_ALL_SCENERIES_THEMES";
export const FETCH_ALL_QUESTIONS_ANSWERS = "FETCH_ALL_QUESTIONS_ANSWERS";
export const UPLOAD_USER_AVATAR = "UPLOAD_USER_AVATAR";
export const GET_USER_AVATAR = "GET_USER_AVATAR";
export const FETCH_CENARY_PARTICIPANTS = "FETCH_CENARY_PARTICIPANTS";
export const FETCH_ALL_ACTIVITY_INFORMATION = "FETCH_ALL_ACTIVITY_INFORMATION";
export const FETCH_ACTIVITY_INFORMATION = "FETCH_ACTIVITY_INFORMATION";
export const UPDATE_ACTIVITY_INFORMATION = "UPDATE_ACTIVITY_INFORMATION";
export const DELETE_ACTIVITY_INFORMATION = "DELETE_ACTIVITY_INFORMATION";
export const FETCH_ALL_PEOPLE_EVALUATION = "FETCH_ALL_PEOPLE_EVALUATION";
export const UPDATE_PEOPLE_EVALUATION = "UPDATE_PEOPLE_EVALUATION";
export const UPDATE_PROFESSIONAL_PROFILE = "UPDATE_PROFESSIONAL_PROFILE";
export const UPDATE_WORKSPACES = "UPDATE_WORKSPACES";
export const DELETE_SWOT_QUESTION = "DELETE_SWOT_QUESTION";
export const UPDATE_SWOT_EVALUATION = "UPDATE_SWOT_EVALUATION";
export const FETCH_ALL_SWOT_EVALUATION = "FETCH_ALL_SWOT_EVALUATION";
export const FETCH_ALL_INSIGHT_GOALS = "FETCH_ALL_INSIGHT_GOALS";
export const FETCH_ALL_TOOLS_INSIGHT_GOALS = "FETCH_ALL_TOOLS_INSIGHT_GOALS";
export const CLEAN_ALL_LOCAL_STORAGE = "CLEAN_ALL_LOCAL_STORAGE";
export const CLEAN_HIDDEN_STORAGE = "CLEAN_HIDDEN_STORAGE";
export const FETCH_ALL_COMPANY_TOOLS_TYPE = "FETCH_ALL_COMPANY_TOOLS_TYPE";
export const UPDATE_PROJECT_EVALUATION = "UPDATE_PROJECT_EVALUATION";
export const FETCH_ALL_PROJECT_EVALUATION = "FETCH_ALL_PROJECT_EVALUATION";
export const CUSTOM_ADD_SCENERY = "CUSTOM_ADD_SCENERY";
export const CUSTOM_ADD_THEME = "CUSTOM_ADD_THEME";
export const CUSTOM_ADD_QUESTION = "CUSTOM_ADD_QUESTION";
export const CUSTOM_ADD_INSIGHT = "CUSTOM_ADD_INSIGHT";
export const PARTICIPANT_WORKFLOW_UPDATE = "PARTICIPANT_WORKFLOW_UPDATE";
export const GET_PROJECT_EVALUATION = "GET_PROJECT_EVALUATION";
export const UPDATE_GOALS_BONUS_CONFIGURATION =
  "UPDATE_GOALS_BONUS_CONFIGURATION";
export const UPDATE_ALL_GOALS_BONUS_CONFIGURATIONS =
  "UPDATE_ALL_GOALS_BONUS_CONFIGURATIONS";
export const UPDATE_COMPANY_TEAMS = "UPDATE_COMPANY_TEAMS";
export const UPDATE_ALL_PROJECT_PRIORITIZATION =
  "UPDATE_ALL_PROJECT_PRIORITIZATION";
export const DELETE_CRITERIA = "DELETE_CRITERIA";
export const DELETE_PROJECT_GROUP = "DELETE_PROJECT_GROUP";
export const DELETE_CRITERIA_GROUP = "DELETE_CRITERIA_GROUP";
export const DELETE_PROJECT_TYPE = "DELETE_PROJECT_TYPE";
export const DELETE_GOAL = "DELETE_GOAL";
export const DELETE_TRIGGER = "DELETE_TRIGGER";
export const DELETE_ADMINISTRATIVE_ROLE = "DELETE_ADMINISTRATIVE_ROLE";
export const DELETE_ROLE_BONUS = "DELETE_ROLE_BONUS";
export const DELETE_PARTICIPANT = "DELETE_PARTICIPANT";
export const DELETE_TEAM = "DELETE_TEAM";
export const KPI_MASS_UPDATE = "KPI_MASS_UPDATE";
export const PEOPLE_MASS_UPDATE = "PEOPLE_MASS_UPDATE";
export const PROJECT_MASS_UPDATE = "PROJECT_MASS_UPDATE";
export const UPDATE_KPI_CONFIGURATION = "UPDATE_KPI_CONFIGURATION";
export const UPDATE_ALL_KPI_CONFIGURATIONS = "UPDATE_ALL_KPI_CONFIGURATIONS";
export const GET_USER_ACCESS = "GET_USER_ACCESS";
export const FETCH_DOMAIN_COMPANIES = "FETCH_DOMAIN_COMPANIES";
export const FETCH_DOMAIN_USERS = "FETCH_DOMAIN_USERS";
export const FETCH_USER_DOMAINS = "FETCH_USER_DOMAINS";
export const ADD_ACCESS = "ADD_ACCESS";
export const ADD_ACCESSES = "ADD_ACCESSES";
export const DELETE_ACCESS = "DELETE_ACCESS";
export const DELETE_ACCESSES = "DELETE_ACCESSES";
export const SET_CURRENT_DOMAIN = "SET_CURRENT_DOMAIN";
export const BUILD_ACCESS = "BUILD_ACCESS";
export const UPDATE_SINGLE_ACTION_PLAN = "UPDATE_SINGLE_ACTION_PLAN";
export const UPDATE_ACTIONS_PLANS = "UPDATE_ACTIONS_PLANS";
export const DELETE_ACTION_FROM_PLAN = "DELETE_ACTION_FROM_PLAN";
export const USER_ROLE_ACCESS = "USER_ROLE_ACCESS";
export const LOCKDOWN = "LOCKDOWN";
export const KPI_DELETE_SCENARIO = "KPI_DELETE_SCENARIO";
export const KPI_DELETE_GROUP = "KPI_DELETE_GROUP";
export const KPI_DELETE_PROCESS = "KPI_DELETE_PROCESS";
export const KPI_DELETE_GOAL = "KPI_DELETE_GOAL";
export const KPI_SET_SELECTED_GOAL = "SET_SELECTED_GOAL";
export const SET_SELECTED_DOMAIN_ID = "SET_SELECTED_DOMAIN_ID";
export const FETCH_MINIFIED_COMPANY_WORKSPACES =
  "FETCH_MINIFIED_COMPANY_WORKSPACES";
export const CHANGE_STATUS_INSIGHT = "CHANGE_STATUS_INSIGHT";
export const CHANGE_USER_PRIVACY_POLICY = "CHANGE_USER_PRIVACY_POLICY";
export const UPDATE_ALL_CP_ANALYSIS_CONFIGURATIONS =
  "UPDATE_CP_ANALYSIS_CONFIGURATIONS";
export const UPDATE_ALL_CP_ANALYSIS_CONFIGURATION =
  "UPDATE_CP_ANALYSIS_CONFIGURATION";
export const GET_CP_ANALYSIS_MANAGEMENT_DATA =
  "GET_CP_ANALYSIS_MANAGEMENT_DATA";
export const GET_CP_OVERVIEW_MANAGEMENT_DATA =
  "GET_CP_OVERVIEW_MANAGEMENT_DATA";
export const SET_ANALYSIS_TOOL_TYPE = "SET_ANALYSIS_TOOL_TYPE";
export const POST_COMPETITIVE_ANALYSIS_EVALUATION =
  "POST_COMPETITIVE_ANALYSIS_EVALUATION";
export const POST_COMPETITVE_ANALYSIS_EVALUATION =
  "POST_COMPETITVE_ANALYSIS_EVALUATION";
export const UPDATE_COMPANY_DETAILS = "UPDATE_COMPANY_DETAILS";
export const DELETE_ANALYSIS_GROUP = "DELETE_ANALYSIS_GROUP";
export const DELETE_ANALYSIS_AMBIENT = "DELETE_ANALYSIS_AMBIENT";
export const DELETE_ANALYSIS_AREA = "DELETE_ANALYSIS_AREA";
export const DELETE_EVALUATION_CRITERIA = "DELETE_EVALUATION_CRITERIA";
export const GET_COMPETITIVE_ANALYSIS_POINTS =
  "GET_COMPETITIVE_ANALYSIS_POINTS";
export const UPDATE_COMPETITIVE_ANALYSIS_EVALUATION =
  "UPDATE_COMPETITIVE_ANALYSIS_EVALUATION";
export const UPDATE_ALL_PROJECT_CONFIGURATIONS =
  "UPDATE_ALL_PROJECT_CONFIGURATIONS";
export const UPDATE_PROJECT_STAGE = "UPDATE_PROJECT_STAGE";
export const DELETE_PROJECT_STAGE = "DELETE_PROJECT_STAGE";
export const GET_ALL_COMPETITIVE_ANALYSIS_EVALUATIONS =
  "GET_ALL_COMPETITIVE_ANALYSIS_EVALUATIONS";
export const GOOGLE = "GOOGLE";
export const ADFS = "ADFS";
export const SET_AUTH_INFO = "SET_AUTH_INFO";
export const OPEN_INSIGHT_MODAL = "OPEN_INSIGHT_MODAL";
export const SMART_LINK_LOADING = "SMART_LINK_LOADING";
export const DOMAIN = "DOMAIN_CONFIG";
export const COMPANY = "COMPANY";
export const WORKSPACE = "WORKSPACE";
export const TOOL = "TOOL";
export const INSIGHT = "INSIGHT";
export const USER_PROFILE = "USER_PROFILE";
export const TOOL_WORKFLOW = "TOOL_WORKFLOW";
export const ROOKAU = "ROOKAU";
export const TOGGLE_EDIT_MODE = "TOGGLE_EDIT_MODE";
export const SET_DOMAIN_LOGO = "SET_DOMAIN_LOGO";
export const UPDATE_SINGLE_INSIGHT = "UPDATE_SINGLE_INSIGHT";
