import { CircularProgress } from "@material-ui/core";
import { defineMessages, injectIntl } from "react-intl";

const messages = defineMessages({
  globalSave: {
    id: "global.save",
  },
});

const SaveButton = (props) => {
  const {
    onSave = () => {},
    show = false,
    intl,
    loading = false,
    disabled = false,
  } = props;

  return show ? (
    <div className="pull-right">
      {loading && <CircularProgress />}
      {!loading && (
        <button
          {...(disabled === true ? { disabled: true } : {})}
          className="btn btn-purple"
          onClick={(e) => onSave(e)}
        >
          {intl.formatMessage(messages.globalSave)}
        </button>
      )}
    </div>
  ) : null;
};

export default injectIntl(SaveButton);
