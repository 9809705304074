import React from "react";
import { defineMessages } from "react-intl";
import { translatedText } from "../utils/translationUtils";

const messages = defineMessages({
  global_month: { id: "global.month" },
  global_months: { id: "global.months" },
  kpi_options_rt_shared: { id: "kpi_options_rt_shared" },
  kpi_options_rt_calculated: { id: "kpi_options_rt_calculated" },
  kpi_options_rt_individual: { id: "kpi_options_rt_individual" },
  kpi_options_mu_monetary_value: { id: "kpi_options_mu_monetary_value" },
  kpi_options_mu_thousand_monetary_value: {
    id: "kpi_options_mu_thousand_monetary_value",
  },
  kpi_options_mu_million_monetary_value: {
    id: "kpi_options_mu_million_monetary_value",
  },
  kpi_options_mu_quantity: { id: "kpi_options_mu_quantity" },
  kpi_options_mu_percentage: { id: "kpi_options_mu_percentage" },
  kpi_options_mu_date: { id: "kpi_options_mu_date" },
  kpi_options_mu_index: { id: "kpi_options_mu_index" },
  gb_options_mu_kg: { id: "gb_options_mu_kg" },
  gb_options_mu_tonne: { id: "gb_options_mu_tonne" },
  gb_options_mu_km: { id: "gb_options_mu_km" },
  gb_options_mu_meters: { id: "gb_options_mu_meters" },
  gb_options_mu_cmeters: { id: "gb_options_mu_cmeters" },
  gb_options_mu_liters: { id: "gb_options_mu_liters" },
  gb_options_mu_other: { id: "gb_options_mu_other" },
  kpi_options_gt_indicator: { id: "kpi_options_gt_indicator" },
  kpi_options_gt_project: { id: "kpi_options_gt_project" },
  kpi_options_gt_option: { id: "kpi_options_gt_option" },
  kpi_options_fq_monthly: { id: "kpi_options_fq_monthly" },
  kpi_options_fq_quarterly: { id: "kpi_options_fq_quarterly" },
  kpi_options_fq_four_months: { id: "kpi_options_fq_four_months" },
  kpi_options_fq_semiannual: { id: "kpi_options_fq_semiannual" },
  kpi_options_fq_yearly: { id: "kpi_options_fq_yearly" },
  kpi_options_mr_sum: { id: "kpi_options_mr_sum" },
  kpi_options_mr_average: { id: "kpi_options_mr_average" },
  kpi_options_mr_balance: { id: "kpi_options_mr_balance" },
  kpi_options_gt_not_hit: { id: "kpi_options_gt_not_hit" },
  kpi_options_gt_gate: { id: "kpi_options_gt_gate" },
  kpi_options_gt_appropriated: { id: "kpi_options_gt_appropriated" },
  kpi_options_gt_exceeded: { id: "kpi_options_gt_exceeded" },
  kpi_options_pa_admin: { id: "kpi_options_pa_admin" },
  active: { id: "global.active" },
  not_active: { id: "global.notActive" },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

export const getRelationshipTypeOptions = () => {
  return [
    {
      value: "INDIVIDUAL",
      label: translation("kpi_options_rt_individual"),
      icon: <i className="fas fa-percentage" />,
      color: "#6b42a9",
    },
    {
      value: "SHARED",
      label: translation("kpi_options_rt_shared"),
      icon: <i className="fas fa-stream" />,
      color: "#ffc875",
    },
    {
      value: "CALCULATED",
      label: translation("kpi_options_rt_calculated"),
      icon: <i className="fas fa-balance-scale" />,
      color: "#ffc875",
    },
  ];
};

export const getKpi_MU_options = () => {
  return [
    {
      value: "MONETARY_VALUE",
      label: translation("kpi_options_mu_monetary_value"),
    },
    {
      value: "THOUSAND_MONETARY_VALUE",
      label: translation("kpi_options_mu_thousand_monetary_value"),
    },
    {
      value: "MILLION_MONETARY_VALUE",
      label: translation("kpi_options_mu_million_monetary_value"),
    },
    { value: "QUANTITY", label: translation("kpi_options_mu_quantity") },
    {
      value: "PERCENTAGE",
      label: translation("kpi_options_mu_percentage"),
    },
    { value: "DATE", label: translation("kpi_options_mu_date") },
    { value: "INDEX", label: translation("kpi_options_mu_index") },
    { value: "KG", label: translation("gb_options_mu_kg") },
    { value: "TONNE", label: translation("gb_options_mu_tonne") },
    { value: "KM", label: translation("gb_options_mu_km") },
    { value: "METERS", label: translation("gb_options_mu_meters") },
    { value: "CMETERS", label: translation("gb_options_mu_cmeters") },
    { value: "LITERS", label: translation("gb_options_mu_liters") },
    { value: "OTHER", label: translation("gb_options_mu_other") },
  ];
};

export const getGoalTypeOptions = () => {
  return [
    { value: "INDICATOR", label: translation("kpi_options_gt_indicator") },
    { value: "PROJECT", label: translation("kpi_options_gt_project") },
    { value: "OPTION", label: translation("kpi_options_gt_option") },
  ];
};

export const getKpisFrequencyOptions = () => {
  return [
    { value: "MONTHLY", label: translation("kpi_options_fq_monthly") },
    { value: "QUARTERLY", label: translation("kpi_options_fq_quarterly") },
    {
      value: "FOUR_MONTHS",
      label: translation("kpi_options_fq_four_months"),
    },
    {
      value: "SEMIANNUAL",
      label: translation("kpi_options_fq_semiannual"),
    },
    { value: "YEARLY", label: translation("kpi_options_fq_yearly") },
  ];
};

export const getMeasurementOptions = () => {
  return [
    { value: "SUM", label: translation("kpi_options_mr_sum") },
    { value: "AVERAGE", label: translation("kpi_options_mr_average") },
    { value: "BALANCE", label: translation("kpi_options_mr_balance") },
  ];
};

export const getIndicatorTargetOptions = () => {
  return [
    {
      value: "NOT_HIT",
      label: translation("kpi_options_gt_not_hit"),
      color: "#ff6347",
      hit: 0,
    },
    {
      value: "GATE",
      label: translation("kpi_options_gt_gate"),
      color: "#ffc000",
      hit: 1,
    },
    {
      value: "APPROPRIATED",
      label: translation("kpi_options_gt_appropriated"),
      color: "#44cf76",
      hit: 2,
    },
    {
      value: "EXCEEDED",
      label: translation("kpi_options_gt_exceeded"),
      color: "#5487e4",
      hit: 3,
    },
  ];
};

export const getZeroWhenNegativeOptions = () => {
  return [
    { value: "ACTIVE", label: translation("active") },
    { value: "NOT_ACTIVE", label: translation("not_active") },
  ];
};

export const getPageAccessRulesOptions = () => {
  return [{ value: "ADMIN", label: translation("kpi_options_pa_admin") }];
};
export const allowedEditingRelationType = ["SHARED", "INDIVIDUAL"];
export const defaultGoalsDisplayOrder = ["SHARED", "INDIVIDUAL"];

export const notStatedGoalReachDefault = {
  goalPercentage: 0,
  totalHitTargetInfo: {
    hitTarget: 0,
    color: "#eee",
  },
  notStarted: true,
};
