import React, { useState } from "react";

import FormDialog from "../Common/FormDialog";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const PrivacyPolicy = ({
  showPrivacyPolicy = false,
  handleCancelPrivacyPolicy,
  handlePolicyAcceptance,
}) => {
  const [readAndAgree, setReadAndAgree] = useState(false);

  const handleReadAndAgree = () => {
    setReadAndAgree(!readAndAgree);
  };

  const PrivacyPolicyModal = () => {
    return (
      <FormDialog
        open={showPrivacyPolicy}
        title={"Política de Privacidade"}
        onConfirm={() => handlePolicyAcceptance()}
        confirmText={"Aceitar"}
        bodyStyle={{
          padding: "15px 15px 15px 15px",
        }}
        onCancel={() => handleCancelPrivacyPolicy()}
        hideCancel={true}
        blockConfirm={!readAndAgree}
      >
        <div className="privacy-policy-div">
          <div className="privacy-policy-rules fixedScroll">
            <div className="row"> 1. PRIVACIDADE E SEGURANÇA </div>
            <span>
              A Rookau se compromete em assegurar a proteção de sua privacidade,
              não armazenando ou utilizando informações pessoais para fins
              diversos daqueles estabelecidos nesta política.
            </span>
            <span>
              Toda informação pessoal e profissional coletada e armazenada é
              destinada especificamente para possibilitar a melhor experiência
              de uso e funcionamento da plataforma.
            </span>
            <span>
              Os dados pessoais do usuário não serão comercializados ou
              fornecidos a terceiros em nenhuma hipótese, salvo em estrito
              cumprimento de ordens judiciais, ou quando autorizados.
            </span>
            <div className="row">1.1 DADOS COLETADOS</div>
            <span>
              Para uma experiência completa e melhor usabilidade da plataforma,
              a Rookau coleta e armazena dados pessoais e profissionais dos
              usuários, empresa e colaborador os quais consentem expressamente
              com o fornecimento desses, para inclusão e utilização restrita na
              plataforma.
            </span>
            <span>
              Empresa: Estrutura de área e cargos da empresa contratante
            </span>
            <span>
              Colaboradores e usuários: Nome, foto, email, data de início na
              empresa, data de nascimento, cargo, área, salário.
            </span>
            <span>
              Esses dados devem ser inseridos e mantidos atualizados pelo
              proprietário do domínio e administrador da plataforma, com exceção
              da foto, que deve ser realizada e concebida pelo usuário, ao fazer
              um upload de uma foto pessoal, o qual vai ajudar na interatividade
              e conhecimento visual mais rápido das responsabilidades e
              envolvimento dentro da plataforma. A não utilização da foto não
              impede o funcionamento e utilização da plataforma. É uma escolha e
              decisão do usuário e da empresa contratante.
            </span>
            <span>
              Para acessar a plataforma, o usuário será informado para realizar
              o login e registrar sua senha.
            </span>
            <span>
              Recomendamos que você mantenha sua senha de acesso a Plataforma
              sob total sigilo e nunca a compartilhe com terceiros. Não
              solicitamos ao usuário que nos informe sua senha fora do site, por
              telefone, e-mail ou qualquer outro meio de comunicação.
            </span>
            <span>
              Nosso servidor não coleta automaticamente nenhuma informação sobre
              o domínio ou endereço de e-mail de nossos visitantes.
            </span>
            <div className="row">1.2 FINALIDADE DOS DADOS COLETADOS</div>
            <span>
              Estruturação e correlação dos dados para melhor experiência da
              plataforma, estatística, uso e melhoria dos Serviços:
            </span>
            <span>
              A Rookau não vende, compartilha ou divulga informações a qualquer
              terceiro que não seja parte de seu grupo corporativo.
            </span>
            <div>Finalidade:</div>
            <span>
              • Ajudar no suporte técnico, com informações pertinentes, para
              manter e melhorar os Serviços;
            </span>
            <span>• Realizar análises de negócios e de uso da plataforma;</span>
            <span>• Acompanhar e analisar tendências de uso.</span>
            <div className="row">1.3 COMUNICAÇÃO</div>
            <span>
              A Rookau The Strategy Place poderá entrar em contato com o Usuário
              através de comunicados eletrônicos enviados por e-mail ao endereço
              eletrônico informado pelo Usuário no momento de seu
              credenciamento. Abaixo os temas que poderão ser comunicados com os
              usuários:
            </span>
            <span>
              • Manter os usuários atualizados de mudanças e melhorias úteis e
              novas funcionalidades que possibilitam maior eficiência no uso da
              Plataforma;
            </span>
            <span>
              • Responder aos comentários, dúvidas e solicitações de Usuários;
            </span>
            <span> • Responder a solicitações de serviço ao cliente;</span>
            <span>
              • Enviar boletins informativos e comunicações de marketing;
            </span>
            <span>• Realizar pesquisas e análises.</span>
            <div className="row">
              1.4 ARMAZENAMENTO DOS DADOS E BACKUPS DOS DADOS
            </div>
            <span>
              Google Cloud e Amazon AWS para as instâncias da plataforma e banco
              de dados Postgresql. A quantidade de informações armazenadas nas
              nuvens é ilimitada.
            </span>
            <span>
              Os dados transmitidos por meio eletrônico, coletados e armazenados
              – tais como senhas, nome de Usuário, nome, endereço, numero de
              telefone etc. – são criptografados pelo sistema.
            </span>
            <div>1.5 POLÍTICA GERAL DE SEGURANÇA DOS DADOS</div>
            <span>
              A Rookau se compromete a tomar todas as medidas que estiverem a
              seu alcance para oferecer um ambiente seguro durante a utilização
              e acesso de sua Plataforma.
            </span>
            <span>
              Em caso de atividade suspeita ou não autorizada na conta do
              Usuário ou em caso de perda de login e senha, deverá ser
              imediatamente comunicada a empresa contratada por e-mail, enviado
              ao seguinte endereço eletrônico: adm@rookau.com
            </span>
            <span>
              A Rookau observa todas as normas aplicáveis às medidas de
              segurança da informação pessoal e emprega os padrões mais
              avançados em matéria de proteção da confidencialidade dessas
              informações. Ainda assim, é necessário considerar que a segurança
              perfeita não existe na Internet. Portanto, a Rookau não será
              responsável por interceptações ilegais ou violação de seus
              sistemas ou bases de dados por parte de pessoas não-autorizadas e
              tampouco se responsabiliza pela indevida utilização da informação
              obtida por esses meios.
            </span>
            <span>
              Todos os dados pessoais informados à web-app são armazenados em um
              banco de dados reservado e com acesso restrito às pessoas
              habilitadas, que são obrigadas, por contrato, a manter a
              confidencialidade das informações e não utilizá-las
              inadequadamente.
            </span>
            <div className="row">1.6 INFRAESTRUTURA E SISTEMA DE REDE </div>
            <span>
              Em relação a infraestrutura de dados, a Rookau, conta com recursos
              de alta tecnologia, escalabilidade e segurança. Abaixo listamos os
              principais componentes que é utilizada pela Rookau, que asseguram
              um melhor nível de serviço prestado:
            </span>
            <div>Autenticação </div>
            <span>
              Autenticação é uma solução flexível para adicionar serviços
              de autenticação e autorização para aplicativos. Com MFA, todos os
              APIs são protegidos com OAuth 2.0, exigindo que o usuário forneça
              duas credenciais para autenticar sua identidade.
            </span>
            <div>Escalabilidade </div>
            <span>
              Frontend – AWS Amplify e AW S3 - O AWS Amplify é uma plataforma de
              desenvolvimento para a criação de aplicativos móveis e da Web
              seguros e escaláveis. O Amplify abrange todo o fluxo de trabalho
              de desenvolvimento de aplicativos móveis, desde controle de
              versão, teste de código até implantação de produção.
            </span>
            <span>
              O Amazon Simple Storage Service (Amazon S3) é um serviço de
              armazenamento de objetos que oferece escalabilidade,
              disponibilidade de dados, segurança e performance. O Amazon S3 foi
              projetado para 99,999999999% de SLA. Cópias de todos os dados
              carregados no Amazon S3 e são criadas e armazenadas em pelo menos
              três dispositivos em uma única região da AWS.
            </span>
            <div>Provisionamento</div>
            <span>
              NLB e Provisionamento - O AWS Elastic Beanstalk é um serviço de
              fácil utilização para implantação e escalabilidade de aplicações e
              serviços da web desenvolvidos com Java, que se encarrega
              automaticamente da implementação (desde o provisionamento de
              capacidade, o balanceamento de carga e a escalabilidade
              automática) até o monitoramento da saúde do aplicativo.
            </span>
            <div>Expansão de Requisitos</div>
            <span>
              Backend - AWS EC2 - Amazon Elastic Compute Cloud (Amazon EC2)
              oferece uma capacidade de computação dimensionável para executar o
              número de servidores virtuais que precisar, permitindo a expansão
              ou a redução para gerenciar as alterações de requisitos ou picos
              de popularidade.
            </span>
            <div>Base de Dados</div>
            <span>
              Base de dados – AWS RDS (PostgreSQL) - Amazon RDS for PostgreSQL é
              compatível com muitos padrões de indústria (HIPAA, BAA, FedRAMP,
              etc). O uso do Amazon Virtual Private Cloud (VPC) permite isolar
              as instâncias de banco de dados na sua própria rede virtual.
            </span>
            <div className="row">1.7 RECURSOS DE SEGURANÇA DA PLATAFORMA</div>
            <div>Dados em backups</div>
            <span>
              Segurança de Dados: backups realizados diariamente em instância
              redundante. Para garantir a recuperação dos nossos serviços,
              contamos com um sistema automático de backups do banco de dados.
              Esses arquivos são armazenados com encriptação e são guardados por
              6 meses. A encriptação garante que, mesmo que um terceiro
              conseguisse ter acesso aos arquivos, não conseguiria realizar a
              leitura dos dados sem decifrar a criptografia.
            </span>
            <div>Criptografia de dados</div>
            <span>
              Segurança da Plataforma: Comunicação via HTTPS com certificado de
              segurança SSL assinado com PKCS #1 SHA-256 e criptografia RSA.
            </span>
            <div className="row">
              1.8 CONTROLE DE ACESSO PARA INGRESSAR NA PLATAFORMA
            </div>
            <div>Autenticação por múltiplos fatores</div>
            <span>
              Acesso dos usuários através de Multi-Factor Authentication (MFA),
              que é uma camada extra de proteção ao nome de usuário e senha.
              Para acessar a plataforma Rookau, um novo dispositivo é
              necessário. Um código gerado pelo sistema é enviado por SMS para o
              celular do usuário. Isso significa que, para que um terceiro não
              autorizado acesse uma conta, além de usuário e senha é necessário
              que ele esteja de posse do telefone celular registrado no portal.
            </span>
            <div className="row">
              1.9 REGRAS DE ACESSO PARA UTILIZAÇÃO DA PLATAFORMA
            </div>
            <span>
              Os gestores da plataforma executarão todas as configurações de
              regra de acessos e funções na área de configurações incluídas nas
              camadas da estrutura da plataforma. Poderá ser personalizado esses
              itens a qualquer momento na área de configurações. A regra de
              acesso se aplica a todos os níveis da plataforma, sendo
              administrada pelos gestores da plataforma, os quais podem nomear e
              escolher o tipo de permissão.
            </span>
            <span>
              O acesso no nível de dados/informação é uma camada adicional de
              segurança sobre as funções de acesso, que está prevista no
              elemento principal da ferramenta (Insight). A ativação dessa
              configuração restringirá o acesso ao elemento individual, e poderá
              ser feita pelo gestor da ferramenta ou responsável por esse
              insight. Ao designar um membro como envolvido, poderá escolher as
              funções: Colaborador ou Visualizador.
            </span>
            <div className="row">1.10 ALTERAÇÕES </div>
            <span>
              O Rookau poderá modificar esta Política de Privacidade a qualquer
              momento. As alterações estarão sempre presentes neste espaço, de
              modo que os usuários tenham integral conhecimento. As modificações
              poderão ser feitas conforme a finalidade ou necessidade, tal qual
              para adequação e conformidade legal de disposição de lei ou norma
              que tenha força jurídica equivalente, sendo a versão em vigor
              sempre a mais recente.
            </span>
          </div>
        </div>
        <div className="privacy-policy-accept">
          <FormControlLabel
            control={
              <Checkbox
                checked={readAndAgree}
                onChange={handleReadAndAgree}
                name="readAndAgree"
                color="primary"
              />
            }
            label="Declaro que li e concordo com a Política de Privacidade da Rookau."
          />
        </div>
      </FormDialog>
    );
  };

  return PrivacyPolicyModal();
};

export default PrivacyPolicy;
