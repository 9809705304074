const en = {
  em_main_title: "Excellence in management",
};

const es = {
  em_main_title: "Excelencia en gestion",
};

const pt = {
  em_main_title: "Excelência em Gestão",
};

const translations = { en, es, pt };

export default translations;
