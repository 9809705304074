import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import _ from "lodash";

import {
  getSelectedToolStates,
  getCompAnalysisStates,
  getInsightStates,
} from "../../../customMapStates";

import PestalEvaluation from "./PestalEvaluation";
import SwotEvaluation from "./SwotEvaluation";
import PrioritizationEvaluation from "./PrioritizationEvaluation";
import ConcurrenceEvaluation from "./ConcurrenceEvaluation";
import { buildFormAdmConfigBody } from "../../../../utils/cpAnalysis/entity";
import {
  getCompetitiveAnalysisEvaluation,
  sendCompanyDetails,
  uploadLogo,
  sendCompetitiveAnalysisEvaluation,
  linkAnalysisGroup,
} from "../../../../actions/compAnalysisActions";
import InsightActionPlan from "../../../Common/ActionPlan/InsightActionPlan";

const defaultFormInitialCompAnalysis = buildFormAdmConfigBody({});

let CompetitiveAnalysis = (props) => {
  const {
    selectedTool = {},
    selectedInsight = {},
    currentToolType = "",
    currentInsightEvaluation,
    selectedInsightID = false,
    currentAdministration = {},
    getCompetitiveAnalysisEvaluation: getEvaluation,
  } = props;

  const [cpAnalysisAdministration, setCpAnalysisAdministration] = useState(
    defaultFormInitialCompAnalysis,
  );

  useEffect(() => {
    if (!_.isEqual(currentAdministration, cpAnalysisAdministration))
      setCpAnalysisAdministration(currentAdministration);
  }, [currentAdministration, selectedTool.id]);

  useEffect(() => {
    if (!selectedInsightID) return;

    getEvaluation(selectedInsightID, selectedTool.id);
  }, []);

  const CustomizationHandler = () => {
    const formToolType = currentToolType.toUpperCase();
    const { id: selectedToolId = "" } = selectedTool;

    const essentialProps = {
      ...props,
      uploadLogo,
      selectedToolId,
      linkAnalysisGroup,
    };

    const customizations = {
      PESTAL: <PestalEvaluation {...essentialProps} />,
      SWOT: <SwotEvaluation {...essentialProps} />,
      PRIORITIZATION: <PrioritizationEvaluation {...essentialProps} />,
      CONCURRENCE: <ConcurrenceEvaluation {...essentialProps} />,
    };

    return customizations[formToolType] || <></>;
  };

  const MemoizedEvaluation = () => {
    return useMemo(() => {
      return <CustomizationHandler />;
    }, [currentToolType, cpAnalysisAdministration, currentInsightEvaluation]);
  };

  return (
    <>
      {MemoizedEvaluation()}
      <InsightActionPlan
        insight={selectedInsight}
        selectedTool={selectedTool}
        dragabble
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { profile } = state;

  const { selectedTool = {} } = getSelectedToolStates(state);
  const { selectedInsightID = false, selectedInsight = {} } = getInsightStates(
    state,
  );
  const {
    currentToolType = "",
    currentAdministration = {},
    currentInsightEvaluation = {},
  } = getCompAnalysisStates(state);

  return {
    profile,
    selectedTool,
    currentToolType,
    selectedInsight,
    selectedInsightID,
    currentAdministration,
    currentInsightEvaluation,
  };
};

CompetitiveAnalysis = injectIntl(
  connect(mapStateToProps, {
    getCompetitiveAnalysisEvaluation,
    sendCompetitiveAnalysisEvaluation,
    sendCompanyDetails,
  })(CompetitiveAnalysis),
);

export { CompetitiveAnalysis };
