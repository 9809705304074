const en = {
  gb_main_title: "Goals",
  gb_add_new_scenario: "Add team",
  gb_add_new_theme: "Add role",
  gb_add_new_question: "Add participant",
  gb_add_new_insight: "Add goal",
  gb_count_goals: "N° Goals",
  gb_tab_administration: "Administration",
  gb_tab_administration_title: "PROGRAM ADMINISTRATION",
  gb_tab_administration_config: "Settings",
  gb_tab_administration_involved: "Involved",
  gb_tab_administration_goals: "Goals",
  gb_tab_management_details: "Details",
  gb_sub_title_config: "BONUS CONFIGURATIONS",
  gb_sub_title_triggers: "TRIGGERS | CONDITIONS",
  gb_sub_title_guardians: "PROCESS GUARDIANS",
  gb_sub_title_hierarchy: "CHARGES AND ACCESS HIERARCHY",
  gb_sub_title_participants: "REGISTRATION OF PARTICIPANTS",
  gb_sub_title_teams: "REGISTRATION OF AREAS OR TIMES",
  gb_sub_title_goals: "GOALS",
  gb_sub_title_dates: "PROGRAM DATES",
  gb_subtitle_goal_description: "Goal Description",
  gb_subtitle_goal_measurement: "Goal Measurement",
  gb_table_text_goals: "Goals",
  gb_table_text_goals_upper: "GOALS",
  gb_table_text_goals_bonus: "Bonus",
  gb_table_text_goals_bonus_upper: "BONUS",
  gb_table_text_participants: "Participants",
  gb_table_text_participants_upper: "PARTICIPANTS",
  gb_table_text_target: "Target",
  gb_table_text_target_upper: "TARGET",
  gb_table_text_results_upper: "RESULTS",
  gb_table_text_performance_scale: "Performance Scale (accumulated goal)",
  gb_table_text_performance_scale_period: "Performance Scale (goal)",
  gb_table_text_gate: "Gate",
  gb_table_text_appropriated: "Planned",
  gb_table_text_exceeded: "Exceeded",
  gb_table_text_actual: "Current",
  gb_table_text_goal_targets: "Performance Scale",
  gb_table_text_trigger: "Trigger",
  gb_table_text_no_trigger: "No triggers registered",
  gb_table_text_description: "Description",
  gb_table_text_main_roles: "MAIN ROLES",
  gb_table_text_role: "FUNCTION",
  gb_table_text_no_role: "No functions registered",
  gb_table_text_involved: "INVOLVED",
  gb_table_text_accesses: "ACCESS",
  gb_table_text_job_roles: "ROLE",
  gb_table_text_salaries_quantity: "AMOUNT OF SALARY",
  gb_table_text_salaries_quantity_short: "QTY. SALARY",
  gb_table_text_salaries_quantity_short_lower: "Qty. Salary",
  gb_table_text_can_view: "CAN VIEW",
  gb_table_text_workflow: "WORKFLOW",
  gb_table_text_no_config: "No settings registered",
  gb_table_text_participant: "PARTICIPANT",
  gb_table_text_no_participant: "No registered participants",
  gb_table_text_no_participant_show: "No participants to show",
  gb_table_text_job_role: "ROLE",
  gb_table_text_salary_base: "BASE ($)",
  gb_table_text_salary_base_ext_upper: "SALARY BASE",
  gb_table_text_total_bonus: "TOTAL BONUS",
  gb_table_text_period: "Period",
  gb_table_text_period_upper: "PERIOD",
  gb_table_text_prev_period: "Last Year",
  gb_table_text_areas_teams: "AREAS | TEAMS",
  gb_table_text_no_team: "No teams registered",
  gb_table_text_goal: "Goal",
  gb_table_text_goal_upper: "GOAL",
  gb_table_text_no_goal: "No goal entered",
  gb_table_text_no_goal_found: "No goals found",
  gb_table_text_no_goals_evaluation: "GOALS EVALUATION",
  gb_table_text_relationship: "Relationship",
  gb_table_text_status: "Status",
  gb_table_text_weight: "Weight",
  gb_table_text_date: "Date",
  gb_table_text_dates_period: "Period",
  gb_table_text_year: "Year",
  gb_table_text_start_registrations: "Start of Goal Registration",
  gb_table_text_start_approval: "Start of approvals",
  gb_table_text_start_audit: "Start of audit",
  gb_table_text_registrations: "Goal Registration",
  gb_table_text_approval: "Approvals",
  gb_table_text_audit: "Audit",
  gb_table_text_end_registrations: "End of Goal Registration",
  gb_table_text_end_approval: "End of approvals",
  gb_table_text_end_audit: "End of audit",
  gb_table_text_limit_bonus: "LIMIT BONUS",
  gb_table_text_limit_bonus_total: "TOTAL LIMIT BONUS",
  gb_table_text_total_limit_bonus: "Total limit bonus amount",
  gb_table_text_reach: "Reach",
  gb_table_text_reach_upper: "REACH",
  gb_table_text_reset_bonus:
    "Reset goal bonus if target reached is below {percentage}%",
  gb_table_text_total_acum: "Accumulated total",
  gb_table_text_total_period: "Period total",
  gb_form_salary_base: "Salary Base",
  gb_form_add_role: "Add new role",
  gb_form_change_role: "Change role",
  gb_form_today: "Today",
  gb_form_year: "Year",
  gb_form_initial_month: "Start Month",
  gb_form_last_month: "End Month",
  gb_form_data_source: "Data Source",
  gb_form_general_observations: "General Remarks",
  gb_form_gate_salary: "Gate Salary",
  gb_form_appropriated_salary: "Planned Salary",
  gb_form_exceeded_salary: "Exceeded Salary",
  gb_form_unity: "Un",
  gb_form_unity_ext: "Unit of Measure",
  gb_form_type: "Type",
  gb_form_frequency: "Frequency",
  gb_form_measurement: "Measurement",
  gb_form_validation: "Validation",
  gb_form_job_role: "Title",
  gb_form_job_team: "Team",
  gb_form_title: "Title",
  gb_form_description: "Description",
  gb_form_message: "Message",
  gb_form_role: "Function",
  gb_form_involved: "Involved",
  gb_form_accesses: "Accesses",
  gb_form_comments: "Comments",
  gb_form_workflow_status: "Workflow status",
  gb_form_member: "Member",
  gb_form_select_team_area: "Select a team/area",
  gb_form_select_job_role: "Select a position",
  gb_form_select_participant: "Select a participant",
  gb_form_select_goal: "Select a goal",
  gb_form_select_goals: "Select goals",
  gb_form_update_comment: "Update Comment",
  gb_form_no_comments: "No comments",
  gb_form_send_email_approvers: "Send email to approvers",
  gb_form_send_email_participants: "Send email to participants",
  gb_form_can_view: "This role can view",
  gb_form_can_workflow: "Workflow Hits",
  gb_form_access_hierarchy: "Access Hierarchy",
  gb_form_warning_cant_change_approved:
    "It will not be possible to change the goal once approved or in approval",
  gb_message_goal_starting: "Goal will start {date}",
  gb_message_goal_started: "Goal started {date}",
  gb_message_goal_ending: "Goal Closes {date}",
  gb_message_goal_ended: "Goal has been closed {date}",
  gb_message_goals_already_approval:
    "All goals have already been submitted for approval",
  gb_message_write_message: "Write an update message",
  gb_message_select_job_role: "Select a role to continue",
  gb_message_show_more_participants: "See participants related to the Goal",
  gb_message_show_less_participants: "Hide participants",
  gb_workflow_page_title: "GOAL WORKFLOW",
  gb_workflow_no_goal_for_status: "No goal for {status} was found",
  gb_workflow_next: "Next",
  gb_workflow_prev: "Previous",
  gb_workflow_change_to: "Change goal status to {status}",
  gb_workflow_warning_weight:
    "The total weight of this participant's goals is {order} 100%",
  gb_workflow_warning_own_goals:
    "You can't change the status of your own goals. Just send them to 'APPROVAL'",
  gb_workflow_warning_status_change:
    "You cannot change the status of corporate or shared goals. Only individual goals.",
  gb_dialog_title_trigger: "Add new trigger",
  gb_dialog_title_adm_roles: "Add new role",
  gb_dialog_title_new_role_bonus: "Add new role",
  gb_dialog_title_approval: "Submit Goals for Approval",
  gb_dialog_title_new_participant: "Add new participant",
  gb_dialog_title_new_goal: "Register new goal",
  gb_dialog_title_edit_goal: "Edit goal",
  gb_dialog_title_add_team: "Add team / area",
  gb_dialog_title_edit_team: "Edit team / area",
  gb_dialog_title_edit_role_bonus: "Add new role",
  gb_dialog_title_select_involved: "Select those involved in the role",
  gb_dialog_title_select_member: "Select a member",
  gb_dialog_title_select_team: "Select a team",
  gb_dialog_title_change_workflow:
    "Are you sure you want to change the status of this goal?",
  gb_error_small_title: "Title field must contain more characters",
  gb_error_small_description: "Description field must contain more characters",
  gb_error_small_message: "Message field must contain more characters",
  gb_error_block_relationship:
    "The goal relationship cannot be INDIVIDUAL as there are {length} participants connected with this goal.",
  gb_error_repeated_target: "The target field cannot have repeated values.",
  gb_error_invalid_target: "Target field contains inverted/incongruent values",
  gb_error_invalid_weight: "The weight field must be between 1% and 100%",
  gb_error_invalid_date: "Start date must be earlier than end date",
  gb_error_invalid_distribution: "The target distribution should total 100%",
  gb_error_invalid_period: "Invalid period",
  gb_error_invalid_salary_base: "Set salary base",
  gb_error_invalid_comment: "Comment field must contain more characters",
  gb_error_role_already_added:
    "This position {role} has already been entered on a team",
  gb_error_goal_already_added:
    "Goal {title} has already been added to this participant",
  gb_error_individual_goal_added:
    "Goal (individual) {title} has already been added to another participant",
  gb_error_participant_already_added: "This participant has already been added",
  gb_error_select_role: "Select position",
  gb_error_duplicated_role: "There is already another position with this title",
  gb_error_invalid_year: "Enter a valid value for the year field",
  gb_error_invalid_period_date: "The {period} date must be informed",
  gb_error_dates_conflict: "Conflict between the dates of {date1} and {date2}",
  gb_error_no_goal_selected: "No goal has been selected",
  gb_error_no_role_selected: "Select the Position of all Participants",
  gb_confirm_delete_role: "Are you sure you want to delete this function?",
  gb_confirm_delete_job_role: "Are you sure you want to delete this role?",
  gb_confirm_delete_goal: "Are you sure you want to delete this goal?",
  gb_confirm_delete_participant:
    "Are you sure you want to delete this participant?",
  gb_confirm_delete_trigger: "Are you sure you want to delete this trigger?",
  gb_confirm_delete_team: "Are you sure you want to delete this team?",
  gb_confirm_archive_all: "Archive all",
  gb_warning_not_approved_to_audit: "This goal was not approved for audit",
  gb_warning_goal_not_audited: "This goal was not audited",
  gb_warning_remove_participant_title:
    "There are participants related to this position",
  gb_warning_remove_participant_description:
    "You need to change the position of all participants below",
  gb_warning_period_between_blocked:
    "The {period} period starts at {startDate} and ends at {endDate}. Outside these dates, changes will no longer be possible.",
  gb_warning_cant_change_role:
    "The role of this participant cannot be changed.",
  gb_warning_connected_team_cenary:
    "There is a scenario connected with this team.",
  gb_warning_connected_goal_insight:
    "There is an insight connected with this goal.",
  gb_warning_connected_participant_question:
    "There is a question connected with this participant.",
  gb_warning_connected_role_theme: "There is a theme connected with this role.",
  gb_warning_insights_will_delete:
    "Upon confirmation, all insights listed below will be archived",
  gb_warning_need_arquive_cenary:
    "You need to archive the scenario ''{cenaryName}'' to continue deleting this team.",
  gb_warning_need_arquive_theme:
    "You need to archive the theme ''{themeName}'' to continue deleting this role.",
  gb_warning_need_arquive_question:
    "You must file question ''{questionName}'' to continue deleting this participant.",
  gb_warning_need_min_administrator:
    "At least 1 administrator must be registered.",
  gb_warning_invalid_goals_total_weight: "Goal weight should add up to 100%",
  gb_options_period_desc_quarterly: "Quarter",
  gb_options_period_desc_four_months: "Four months",
  gb_options_period_desc_semiannual: "Semester",
  gb_options_period_desc_yearly: "Year",
  gb_options_rt_shared: "Shared",
  gb_options_rt_individual: "Individual",
  gb_options_rt_corporate: "Corporate",
  gb_options_mu_monetary_value: "Amount ($)",
  gb_options_mu_thousand_monetary_value: "Amount x 1000 ($ thousand)",
  gb_options_mu_million_monetary_value: "Value x 1M ($ mi)",
  gb_options_mu_quantity: "Quantity",
  gb_options_mu_percentage: "Percent (%)",
  gb_options_mu_date: "Date",
  gb_options_mu_index: "Index",
  gb_options_mu_kg: "Kg",
  gb_options_mu_tonne: "Tonne",
  gb_options_mu_km: "Km",
  gb_options_mu_meters: "Meters",
  gb_options_mu_cmeters: "Cubic Meters",
  gb_options_mu_liters: "Liters",
  gb_options_mu_other: "Other",
  gb_options_gt_indicator: "Indicator",
  gb_options_gt_project: "Project",
  gb_options_gt_option: "Option",
  gb_options_fq_monthly: "Monthly",
  gb_options_fq_quarterly: "Quarterly",
  gb_options_fq_four_months: "Four months",
  gb_options_fq_semiannual: "Semiannual",
  gb_options_fq_yearly: "Yearly",
  gb_options_mr_sum: "Sum",
  gb_options_mr_average: "Average",
  gb_options_mr_balance: "Balance",
  gb_options_gt_not_hit: "Not reached",
  gb_options_gt_gate: "Gate",
  gb_options_gt_appropriated: "Planned",
  gb_options_gt_exceeded: "Exceeded",
  gb_options_pa_admin: "Administrator",
  gb_options_pa_auditor: "Auditor",
  gb_options_pa_registrations: "Registrations",
  gb_options_pa_analytics: "Analysis",
  gb_options_gvs_creating: "In Creation",
  gb_options_gvs_creating_status: "creation",
  gb_options_gvs_approval: "In Approval",
  gb_options_gvs_approval_status: "approval",
  gb_options_gvs_not_approved: "Unapproved",
  gb_options_gvs_not_approved_status: "unapproved",
  gb_options_gvs_approved: "Approved",
  gb_options_gvs_approved_status: "approved",
  gb_options_gvs_audit: "Audit",
  gb_options_gvs_audit_status: "Audit",
  gb_options_gvs_validated: "Validated",
  gb_options_gvs_validated_status: "validated",
  gb_options_cb_reset: "Reset the bonus",
  gb_options_cb_dont_reset: "Don't reset the bonus",
  gb_email_workflow_update_title: "[ROOKAU - Goals] Workflow Update",
  gb_email_sended_goals_description:
    "{name} updated the status of the goals below to",
  gb_filters_title:
    "With the filter applied all the following values will be changed",
  gb_filters_tab_1_value_1: "% GOALS accumulated",
  gb_filters_tab_1_value_2: "% REACH accumulated",
  gb_filters_tab_1_value_3: "QTY. SALARY",
  gb_filters_tab_1_value_4: "TOTAL REACH per participant",
  gb_filters_tab_1_value_5: "Gate, Planned, Exceeded and Actual for each Goal",
  gb_filters_tab_1_value_6: "% Target for each Goal",
  gb_filters_tab_1_value_7: "% Reach for each Goal",
  gb_filters_tab_1_value_8: "Total Reach accumulated",
  gb_filters_tab_2_value_1:
    "TARGET - Grouping of Participants by (% Achievement or % Reach)",
  gb_filters_tab_2_value_2: "TARGET - Grouping of Goals by % Achievement",
  gb_filters_tab_2_value_3:
    "PARTICIPANTS - Accumulated % of Achievement of the Goals of each participant",
  gb_filters_tab_2_value_4:
    "AREAS - Accumulated % of Achievement of the Goals of each area",
  gb_filters_tab_2_value_5:
    "GOALS - Accumulated % of Achievement for each Goal",
};

const es = {
  gb_main_title: "Objetivos",
  gb_add_new_scenario: "Agregar equipo",
  gb_add_new_theme: "Agregar ocupación",
  gb_add_new_question: "Agregar participante",
  gb_add_new_insight: "Agregar objetivo",
  gb_count_goals: "N° Metas",
  gb_tab_administration: "Administración",
  gb_tab_administration_title: "ADMINISTRACIÓN DEL PROGRAMA",
  gb_tab_administration_config: "Configuración",
  gb_tab_administration_involved: "Involved",
  gb_tab_administration_goals: "Objetivos",
  gb_tab_management_details: "Detalles",
  gb_sub_title_config: "CONFIGURACIÓN DE BONIFICACIÓN",
  gb_sub_title_triggers: "DISPARADOR | CONDICIONES",
  gb_sub_title_guardians: "TUTORES DE PROCESO",
  gb_sub_title_hierarchy: "CARGOS Y JERARQUÍA DE ACCESO",
  gb_sub_title_participants: "REGISTRO DE PARTICIPANTES",
  gb_sub_title_teams: "REGISTRO DE ÁREAS O EQUIPOS",
  gb_sub_title_goals: "OBJETIVO",
  gb_sub_title_dates: "FECHAS DEL PROGRAMA",
  gb_subtitle_goal_description: "Descripción del objetivo",
  gb_subtitle_goal_measurement: "Medición de objetivos",
  gb_table_text_goals: "Objetivos",
  gb_table_text_goals_upper: "OBJETIVOS",
  gb_table_text_goals_bonus: "Bonus",
  gb_table_text_goals_bonus_upper: "BONUS",
  gb_table_text_participants: "Participantes",
  gb_table_text_participants_upper: "PARTICIPANTES",
  gb_table_text_target: "Target",
  gb_table_text_target_upper: "TARGET",
  gb_table_text_results_upper: "RESULTADOS",
  gb_table_text_performance_scale: "Escala de rendimiento (meta acumulada)",
  gb_table_text_performance_scale_period: "Escala de rendimiento (meta)",
  gb_table_text_gate: "Puerta",
  gb_table_text_appropriated: "Planificado",
  gb_table_text_exceeded: "Excedido",
  gb_table_text_actual: "Actual",
  gb_table_text_goal_targets: "Escala de rendimiento",
  gb_table_text_trigger: "Disparador",
  gb_table_text_no_trigger: "No hay disparadores registrados",
  gb_table_text_description: "Descripción",
  gb_table_text_main_roles: "PRINCIPALES FUNCIONES",
  gb_table_text_role: "FUNCTION",
  gb_table_text_no_role: "No hay funciones registradas",
  gb_table_text_involved: "INVOLVED",
  gb_table_text_accesses: "ACCESOS",
  gb_table_text_job_roles: "CARGO",
  gb_table_text_salaries_quantity: "CANTIDAD DE SALARIO",
  gb_table_text_salaries_quantity_short: "SALARIOS",
  gb_table_text_salaries_quantity_short_lower: "Salarios",
  gb_table_text_can_view: "PUEDE VER",
  gb_table_text_workflow: "WORKFLOW",
  gb_table_text_no_config: "No hay configuraciones registradas",
  gb_table_text_participant: "PARTICIPANTE",
  gb_table_text_no_participant: "No hay participantes registrados",
  gb_table_text_no_participant_show: "No hay participantes para mostrar",
  gb_table_text_job_role: "CARGO",
  gb_table_text_salary_base: "BASE ($)",
  gb_table_text_salary_base_ext_upper: "BASE SALARIAL",
  gb_table_text_total_bonus: "BONIFICACIÓN TOTAL",
  gb_table_text_period: "Periodo",
  gb_table_text_period_upper: "PERIODO",
  gb_table_text_prev_period: "Año Pasado",
  gb_table_text_areas_teams: "AREAS | EQUIPOS",
  gb_table_text_no_team: "No hay equipos registrados",
  gb_table_text_goal: "Objetivos",
  gb_table_text_goal_upper: "OBJETIVOS",
  gb_table_text_no_goal: "Ningún objetivo registrado",
  gb_table_text_no_goal_found: "No se encontró ningún objetivo",
  gb_table_text_no_goals_evaluation: "EVALUACIÓN DE OBJETIVOS",
  gb_table_text_relationship: "Relación",
  gb_table_text_status: "Estado",
  gb_table_text_weight: "Peso",
  gb_table_text_date: "Fecha",
  gb_table_text_dates_period: "Período",
  gb_table_text_year: "Año",
  gb_table_text_start_registrations: "Inicio del registro de objetivos",
  gb_table_text_start_approval: "Inicio de aprobaciones",
  gb_table_text_start_audit: "Inicio de auditoría",
  gb_table_text_registrations: "Registro de objetivos",
  gb_table_text_approval: "Aprobaciones",
  gb_table_text_audit: "Auditoría",
  gb_table_text_end_registrations: "Fin del registro de objetivos",
  gb_table_text_end_approval: "Fin de aprobaciones",
  gb_table_text_end_audit: "Fin de la auditoría",
  gb_table_text_limit_bonus: "BONIFICACIÓN LÍMITE",
  gb_table_text_limit_bonus_total: "BONIFICACIÓN LÍMITE TOTAL",
  gb_table_text_total_limit_bonus: "Límite total de bonificación",
  gb_table_text_reach: "Alcanze",
  gb_table_text_reach_upper: "ALCANZE",
  gb_table_text_reset_bonus:
    "Redefina o bônus de meta se a meta alcançada estiver abaixo de {percentage}%",
  gb_table_text_total_acum: "Total acumulado",
  gb_table_text_total_period: "Total periodo",
  gb_form_salary_base: "Base salarial",
  gb_form_add_role: "Agregar nueva función",
  gb_form_change_role: "Cambiar rol",
  gb_form_today: "Hoy",
  gb_form_year: "Año",
  gb_form_initial_month: "Mes de inicio",
  gb_form_last_month: "Mes final",
  gb_form_data_source: "Fuente de datos",
  gb_form_general_observations: "Observaciones generales",
  gb_form_gate_salary: "Gate puerta",
  gb_form_appropriated_salary: "Salario planificado",
  gb_form_exceeded_salary: "Salario excedido",
  gb_form_unity: "Un",
  gb_form_unity_ext: "Unidad de medida",
  gb_form_type: "Tipo",
  gb_form_frequency: "Frecuencia",
  gb_form_measurement: "Medición",
  gb_form_validation: "Validación",
  gb_form_job_role: "Puesto",
  gb_form_job_team: "Equipo",
  gb_form_title: "Titulo",
  gb_form_description: "Descripción",
  gb_form_message: "Mensaje",
  gb_form_role: "Función",
  gb_form_involved: "Involucrado",
  gb_form_accesses: "Accesos",
  gb_form_comments: "Comentarios",
  gb_form_workflow_status: "Estado del flujo de trabajo",
  gb_form_member: "Miembro",
  gb_form_select_team_area: "Seleccione un equipo / área",
  gb_form_select_job_role: "Seleccione una posición",
  gb_form_select_participant: "Seleccione un participante",
  gb_form_select_goal: "Seleccione un objetivo",
  gb_form_select_goals: "Seleccionar objetivos",
  gb_form_update_comment: "Actualizar comentario",
  gb_form_no_comments: "Sin comentarios",
  gb_form_send_email_approvers: "Enviar correo electrónico a los aprobadores",
  gb_form_send_email_participants:
    "Enviar correo electrónico a los participantes",
  gb_form_can_view: "Esta posición puede ver",
  gb_form_can_workflow: "Acceso al flujo de trabajo",
  gb_form_access_hierarchy: "Jerarquía de acceso",
  gb_form_warning_cant_change_approved:
    "No será posible cambiar el objetivo una vez aprobado o aprobación",
  gb_message_goal_starting: "El objetivo comenzará {date}",
  gb_message_goal_started: "El objetivo ha comenzado {date}",
  gb_message_goal_ending: "Objetivo de cierre {date}",
  gb_message_goal_ended: "El objetivo se ha cerrado {date}",
  gb_message_goals_already_approval:
    "Todos los objetivos ya se han enviado para su aprobación",
  gb_message_write_message: "Escribir un mensaje de actualización",
  gb_message_select_job_role: "Seleccione una posición para continuar",
  gb_message_show_more_participants:
    "Ver participantes relacionados con el objetivo",
  gb_message_show_less_participants: "Ocultar participantes",
  gb_workflow_page_title: "WORKFLOW DE OBJETIVOS",
  gb_workflow_no_goal_for_status: "No se encontró un objetivo para {status}",
  gb_workflow_next: "Siguiente",
  gb_workflow_prev: "Anterior",
  gb_workflow_change_to: "Cambiar el estado del objetivo a {status}",
  gb_workflow_warning_weight:
    "El peso total de los objetivos de este participante es {order} que 100%",
  gb_workflow_warning_own_goals:
    "No se puede cambiar el estado de sus propios objetivos. Sólo hay que enviar a 'APROBACIÓN'",
  gb_workflow_warning_status_change:
    "No se puede cambiar el estado de los objetivos corporativos o compartidos. Solo objetivos individuales",
  gb_dialog_title_trigger: "Agregar nuevo disparador",
  gb_dialog_title_adm_roles: "Agregar nueva función",
  gb_dialog_title_new_role_bonus: "Agregar nueva función",
  gb_dialog_title_approval: "Enviar objetivos para aprobación",
  gb_dialog_title_new_participant: "Agregar nuevo participante",
  gb_dialog_title_new_goal: "Registrar nuevo objetivo",
  gb_dialog_title_edit_goal: "Cambiar objetivo",
  gb_dialog_title_add_team: "Agregar equipo / area",
  gb_dialog_title_edit_team: "Cambiar equipo / area",
  gb_dialog_title_edit_role_bonus: "Agregar nueva función",
  gb_dialog_title_select_involved: "Seleccione los involucrados en el rol",
  gb_dialog_title_select_member: "Seleccione un miembro",
  gb_dialog_title_select_team: "Seleccione un equipo",
  gb_dialog_title_change_workflow:
    "¿Estás seguro de que deseas cambiar el estado de este objetivo?",
  gb_error_small_title: "El campo del título debe contener más caracteres",
  gb_error_small_description:
    "El campo de descripción debe contener más caracteres",
  gb_error_small_message: "El campo del mensaje debe contener más caracteres",
  gb_error_block_relationship:
    "La relación del objetivo no puede ser INDIVIDUAL, ya que hay {length} participantes conectados a este objetivo",
  gb_error_repeated_target:
    "El campo de destino no puede tener valores repetidos",
  gb_error_invalid_target:
    "El campo de destino contiene valores invertidos/incongruentes",
  gb_error_invalid_weight: "El campo de peso debe estar entre 1% y 100%",
  gb_error_invalid_date:
    "La fecha de inicio debe ser menor que la fecha de finalización",
  gb_error_invalid_distribution:
    "La distribución de target debe totalizar 100%",
  gb_error_invalid_period: "Período no válido",
  gb_error_invalid_salary_base: "Establecer la base salarial",
  gb_error_invalid_comment:
    "El campo de comentario debe contener más caracteres",
  gb_error_role_already_added:
    "Esta posición {role} ya se ha colocado en un equipo",
  gb_error_goal_already_added:
    "El objetivo {title} ya se ha agregado a este participante",
  gb_error_individual_goal_added:
    "El objetivo (individual) {title} ya se ha agregado a otro participante",
  gb_error_participant_already_added: "Este participante ya ha sido agregado",
  gb_error_select_role: "Seleccione la posición",
  gb_error_duplicated_role: "Ya hay otra posición con ese título",
  gb_error_invalid_year: "Ingrese un valor válido para el campo del año",
  gb_error_invalid_period_date: "La fecha {period} debe ser informada",
  gb_error_dates_conflict: "Conflicto entre las fechas de {date1} y {date2}",
  gb_error_no_goal_selected: "No se seleccionaron objetivos",
  gb_error_no_role_selected:
    "Seleccione la Posición de todos los Participantes",
  gb_confirm_delete_role: "¿Está seguro de que desea eliminar esta función?",
  gb_confirm_delete_job_role:
    "¿Está seguro de que desea eliminar esta posición?",
  gb_confirm_delete_goal: "¿Está seguro de que desea eliminar este objetivo?",
  gb_confirm_delete_participant:
    "¿Estás seguro de que deseas eliminar a este participante?",
  gb_confirm_delete_trigger:
    "¿Está seguro de que desea eliminar este disparador?",
  gb_confirm_delete_team: "¿Está seguro de que desea eliminar este equipo?",
  gb_confirm_archive_all: "Archivar todo",
  gb_warning_not_approved_to_audit:
    "Este objetivo no ha sido aprobado para auditoría",
  gb_warning_goal_not_audited: "Este objetivo no ha sido auditado",
  gb_warning_remove_participant_title:
    "Hay participantes relacionados con este puesto",
  gb_warning_remove_participant_description:
    "Debe cambiar la posición de todos los participantes a continuación",
  gb_warning_period_between_blocked:
    "El período {período} comienza en {startDate} y termina en {endDate}. Fuera de estas fechas, los cambios ya no serán posibles.",
  gb_warning_cant_change_role:
    "El papel de ese participante no se puede cambiar",
  gb_warning_connected_team_cenary:
    "Hay un escenario relacionado con este equipo",
  gb_warning_connected_goal_insight:
    "Hay una insight relacionada con este objetivo",
  gb_warning_connected_participant_question:
    "Hay una pregunta relacionada con este participante",
  gb_warning_connected_role_theme: "Hay un tema relacionado con esta posición",

  gb_warning_insights_will_delete:
    "Tras la confirmación, se archivarán todas las insights enumeradas a continuación",
  gb_warning_need_arquive_cenary:
    "Debe archivar el escenario ''{cenaryName}'' para continuar eliminando ese equipo",
  gb_warning_need_arquive_theme:
    "Debe archivar el tema ''{themeName}'' para continuar eliminando esta posición",
  gb_warning_need_arquive_question:
    "Debe presentar la pregunta ''{questionName}'' para continuar eliminando a este participante",
  gb_warning_need_min_administrator:
    "Es necesario que al menos 1 administrador esté registrado",
  gb_warning_invalid_goals_total_weight:
    "El peso de lo objetivo debe sumar hasta el 100%",
  gb_options_period_desc_quarterly: "Quarter",
  gb_options_period_desc_four_months: "Cuatro meses",
  gb_options_period_desc_semiannual: "Semestre",
  gb_options_period_desc_yearly: "Año",
  gb_options_rt_shared: "Compartido",
  gb_options_rt_individual: "Individual",
  gb_options_rt_corporate: "Corporativo",
  gb_options_mu_monetary_value: "Valor ($)",
  gb_options_mu_thousand_monetary_value: "Valor x 1000 ($ mil)",
  gb_options_mu_million_monetary_value: "Valor x 1M ($ mi)",
  gb_options_mu_quantity: "Cantidad",
  gb_options_mu_percentage: "Porcentaje (%)",
  gb_options_mu_date: "Fecha",
  gb_options_mu_index: "Índice",
  gb_options_mu_kg: "Kg",
  gb_options_mu_tonne: "Toneladas",
  gb_options_mu_km: "Km",
  gb_options_mu_meters: "Metros",
  gb_options_mu_cmeters: "Metros cúbicos",
  gb_options_mu_liters: "Litros",
  gb_options_mu_other: "Otra",
  gb_options_gt_indicator: "Indicador",
  gb_options_gt_project: "Proyecto",
  gb_options_gt_option: "Opción",
  gb_options_fq_monthly: "Mensual",
  gb_options_fq_quarterly: "Trimestralmente",
  gb_options_fq_four_months: "Cuatro meses",
  gb_options_fq_semiannual: "Semestral",
  gb_options_fq_yearly: "Anual",
  gb_options_mr_sum: "Suma",
  gb_options_mr_average: "Promedio",
  gb_options_mr_balance: "Balance",
  gb_options_gt_not_hit: "No golpeado",
  gb_options_gt_gate: "Puerta",
  gb_options_gt_appropriated: "Planificado",
  gb_options_gt_exceeded: "Excedido",
  gb_options_pa_admin: "Administrador",
  gb_options_pa_auditor: "Auditor",
  gb_options_pa_registrations: "Registros",
  gb_options_pa_analytics: "Analytics",
  gb_options_gvs_creating: "En creación",
  gb_options_gvs_creating_status: "creación",
  gb_options_gvs_approval: "En aprobación",
  gb_options_gvs_approval_status: "aprobación",
  gb_options_gvs_not_approved: "No aprobado",
  gb_options_gvs_not_approved_status: "no aprobado",
  gb_options_gvs_approved: "Aprobado",
  gb_options_gvs_approved_status: "aprobado",
  gb_options_gvs_audit: "Auditoría",
  gb_options_gvs_audit_status: "auditoría",
  gb_options_gvs_validated: "Validado",
  gb_options_gvs_validated_status: "validado",
  gb_options_cb_reset: "Reinicie el bonifición",
  gb_options_cb_dont_reset: "No reinicie el bonifición",
  gb_email_workflow_update_title:
    "[ROOKAU - Objetivos] Actualización de Workflow de Objetivos",
  gb_email_sended_goals_description:
    "{name} actualizó el estado de los objetivos a continuación a",
  gb_filters_title:
    "Con el filtro aplicado, todos los siguientes valores cambiarán",
  gb_filters_tab_1_value_1: "% OBJETIVOS acumulados",
  gb_filters_tab_1_value_2: "% ALCANZE acumulado",
  gb_filters_tab_1_value_3: "SALARIOS",
  gb_filters_tab_1_value_4: "ALCANZE TOTAL	por participante",
  gb_filters_tab_1_value_5:
    "Puerta, Planificado, Excedido y Actual por cada objetivo",
  gb_filters_tab_1_value_6: "% Logro para cada Objetivos",
  gb_filters_tab_1_value_7: "% Ancanze por cada objetivo",
  gb_filters_tab_1_value_8: "ALCANZE TOTAL acumuladO",
  gb_filters_tab_2_value_1:
    "TARGET - Agrupación de participantes por (% de Logro o % de Alcanze)",
  gb_filters_tab_2_value_2: "TARGET - Agrupación de objetivos por % de Logro",
  gb_filters_tab_2_value_3:
    "PARTICIPANTES - % Acumulado del Logro de los objetivos de cada participante",
  gb_filters_tab_2_value_4:
    "AREAS - % Acumulado del Logro de los objetivos de cada area",
  gb_filters_tab_2_value_5:
    "OBJETIVO - % Acumulado del Logro para cada objetivo",
};

const pt = {
  gb_main_title: "Metas",
  gb_add_new_scenario: "Adicionar time",
  gb_add_new_theme: "Adicionar cargo",
  gb_add_new_question: "Adicionar participante",
  gb_add_new_insight: "Adicionar meta",
  gb_count_goals: "N° Metas",
  gb_tab_administration: "Administração",
  gb_tab_administration_title: "ADMINISTRAÇÃO DO PROGRAMA",
  gb_tab_administration_config: "Configurações",
  gb_tab_administration_involved: "Envolvidos",
  gb_tab_administration_goals: "Metas",
  gb_tab_management_details: "Detalhes",
  gb_sub_title_config: "CONFIGURAÇÕES DOS BÔNUS",
  gb_sub_title_triggers: "GATILHOS | CONDIÇÕES",
  gb_sub_title_guardians: "GUARDIÕES DO PROCESSO",
  gb_sub_title_hierarchy: "CARGOS E HIERARQUIA DE ACESSOS",
  gb_sub_title_participants: "CADASTRO DOS PARTICIPANTES",
  gb_sub_title_teams: "CADASTRO DAS ÁREAS OU TIMES",
  gb_sub_title_goals: "METAS",
  gb_sub_title_dates: "DATAS DO PROGRAMA",
  gb_subtitle_goal_description: "Descrição da Meta",
  gb_subtitle_goal_measurement: "Medição da Meta",
  gb_table_text_goals: "Metas",
  gb_table_text_goals_upper: "METAS",
  gb_table_text_goals_bonus: "Bônus",
  gb_table_text_goals_bonus_upper: "BÔNUS",
  gb_table_text_participants: "Participantes",
  gb_table_text_participants_upper: "PARTICIPANTES",
  gb_table_text_target: "Alvo",
  gb_table_text_target_upper: "ALVO",
  gb_table_text_results_upper: "RESULTADOS",
  gb_table_text_performance_scale: "Escala de Desempenho (meta acumulada)",
  gb_table_text_performance_scale_period: "Escala de Desempenho (meta)",
  gb_table_text_gate: "Mínimo",
  gb_table_text_appropriated: "Planejado",
  gb_table_text_exceeded: "Superado",
  gb_table_text_actual: "Atual",
  gb_table_text_goal_targets: "Escala de Desempenho",
  gb_table_text_trigger: "Gatilho",
  gb_table_text_no_trigger: "Nenhum gatilho cadastrado",
  gb_table_text_description: "Descrição",
  gb_table_text_main_roles: "PRINCIPAIS PAPÉIS",
  gb_table_text_role: "FUNÇÃO",
  gb_table_text_no_role: "Nenhuma função cadastrada",
  gb_table_text_involved: "ENVOLVIDOS",
  gb_table_text_accesses: "ACESSOS",
  gb_table_text_job_roles: "CARGOS",
  gb_table_text_salaries_quantity: "QUANTIDADE DE SALÁRIOS",
  gb_table_text_salaries_quantity_short: "QTD. SALÁRIOS",
  gb_table_text_salaries_quantity_short_lower: "Qtd. Salários",
  gb_table_text_can_view: "PODE VISUALIZAR",
  gb_table_text_workflow: "WORKFLOW",
  gb_table_text_no_config: "Nenhuma configuração cadastrada",
  gb_table_text_participant: "PARTICIPANTE",
  gb_table_text_no_participant: "Nenhum participante cadastrado",
  gb_table_text_no_participant_show: "Nenhum participante para mostrar",
  gb_table_text_job_role: "CARGO",
  gb_table_text_salary_base: "BASE ($)",
  gb_table_text_salary_base_ext_upper: "BASE SALARIAL",
  gb_table_text_total_bonus: "BÔNUS TOTAL",
  gb_table_text_period: "Período",
  gb_table_text_period_upper: "PERÍODO",
  gb_table_text_prev_period: "Último Ano",
  gb_table_text_areas_teams: "ÁREAS | TIMES",
  gb_table_text_no_team: "Nenhum time cadastrado",
  gb_table_text_goal: "Meta",
  gb_table_text_goal_upper: "META",
  gb_table_text_no_goal: "Nenhuma meta cadastrada",
  gb_table_text_no_goal_found: "Nenhuma meta encontrada",
  gb_table_text_no_goals_evaluation: "AVALIAÇÃO METAS",
  gb_table_text_relationship: "Relacionamento",
  gb_table_text_status: "Status",
  gb_table_text_weight: "Peso",
  gb_table_text_date: "Data",
  gb_table_text_dates_period: "Período",
  gb_table_text_year: "Ano",
  gb_table_text_start_registrations: "Início do Cadastro de Metas",
  gb_table_text_start_approval: "Início das aprovações",
  gb_table_text_start_audit: "Início da auditoria",
  gb_table_text_registrations: "Cadastro de Metas",
  gb_table_text_approval: "Aprovações",
  gb_table_text_audit: "Auditoria",
  gb_table_text_end_registrations: "Fim do Cadastro de Metas",
  gb_table_text_end_approval: "Fim das aprovações",
  gb_table_text_end_audit: "Fim da auditoria",
  gb_table_text_limit_bonus: "BÔNUS LIMITE",
  gb_table_text_limit_bonus_total: "BÔNUS LIMITE TOTAL",
  gb_table_text_total_limit_bonus: "Valor do Bônus Limite total",
  gb_table_text_reach: "Alcance",
  gb_table_text_reach_upper: "ALCANCE",
  gb_table_text_reset_bonus:
    "Zerar bônus da meta se target atingido for abaixo de {percentage}%",
  gb_table_text_total_acum: "Total acumulado",
  gb_table_text_total_period: "Total período",
  gb_form_salary_base: "Base Salarial",
  gb_form_add_role: "Adicionar nova função",
  gb_form_change_role: "Alterar função",
  gb_form_today: "Hoje",
  gb_form_year: "Ano",
  gb_form_initial_month: "Mês inicial",
  gb_form_last_month: "Mês final",
  gb_form_data_source: "Fonte de dados",
  gb_form_general_observations: "Observações gerais",
  gb_form_gate_salary: "Salário Mínimo",
  gb_form_appropriated_salary: "Salário Planejado",
  gb_form_exceeded_salary: "Salário Superado",
  gb_form_unity: "Un",
  gb_form_unity_ext: "Unidade de medida",
  gb_form_type: "Tipo",
  gb_form_frequency: "Periodicidade",
  gb_form_measurement: "Medição",
  gb_form_validation: "Validação",
  gb_form_job_role: "Cargo",
  gb_form_job_team: "Time",
  gb_form_title: "Título",
  gb_form_description: "Descrição",
  gb_form_message: "Mensagem",
  gb_form_role: "Função",
  gb_form_involved: "Envolvidos",
  gb_form_accesses: "Acessos",
  gb_form_comments: "Comentários",
  gb_form_workflow_status: "Status do Workflow",
  gb_form_member: "Membro",
  gb_form_select_team_area: "Selecione um time/área",
  gb_form_select_job_role: "Selecione um cargo",
  gb_form_select_participant: "Selecione um participante",
  gb_form_select_goal: "Selecione uma meta",
  gb_form_select_goals: "Selecione as metas",
  gb_form_update_comment: "Comentário de atualização",
  gb_form_no_comments: "Nenhum comentário",
  gb_form_send_email_approvers: "Enviar e-mail para os aprovadores",
  gb_form_send_email_participants: "Enviar e-mail para os participantes",
  gb_form_can_view: "Esse cargo pode visualizar",
  gb_form_can_workflow: "Acessos Workflow",
  gb_form_access_hierarchy: "Hierarquia de Acessos",
  gb_form_warning_cant_change_approved:
    "Não será possível alterar a meta após aprovada ou em aprovação",
  gb_message_goal_starting: "A meta iniciará {date}",
  gb_message_goal_started: "A meta foi iniciada {date}",
  gb_message_goal_ending: "Fechamento da meta {date}",
  gb_message_goal_ended: "A meta foi fechada {date}",
  gb_message_goals_already_approval:
    "Todas as metas já foram enviadas para aprovação",
  gb_message_write_message: "Escreva uma mensagem de atualização",
  gb_message_select_job_role: "Selecione um cargo para continuar",
  gb_message_show_more_participants: "Ver participantes relacionados à Meta",
  gb_message_show_less_participants: "Esconder participantes",
  gb_workflow_page_title: "WORKFLOW DE METAS",
  gb_workflow_no_goal_for_status: "Nenhuma meta para {status} foi encontrada",
  gb_workflow_next: "Pŕoximo",
  gb_workflow_prev: "Anterior",
  gb_workflow_change_to: "Mudar o status da meta para {status}",
  gb_workflow_warning_weight:
    "O peso total das metas deste participante é {order} que 100%",
  gb_workflow_warning_own_goals:
    "Você não pode mudar o status de suas próprias metas. Apenas enviá-las para 'APROVAÇÃO'",
  gb_workflow_warning_status_change:
    "Você não pode mudar o status de metas corporativas ou compartilhadas. Apenas as metas individuais.",
  gb_dialog_title_trigger: "Adicionar novo gatilho",
  gb_dialog_title_adm_roles: "Adicionar nova função",
  gb_dialog_title_new_role_bonus: "Adicionar nova função",
  gb_dialog_title_approval: "Enviar metas para aprovação",
  gb_dialog_title_new_participant: "Adicionar novo participante",
  gb_dialog_title_new_goal: "Cadastrar nova meta",
  gb_dialog_title_edit_goal: "Alterar meta",
  gb_dialog_title_add_team: "Adicionar time / área",
  gb_dialog_title_edit_team: "Alterar time / área",
  gb_dialog_title_edit_role_bonus: "Adicionar nova função",
  gb_dialog_title_select_involved: "Selecione os involvidos na função",
  gb_dialog_title_select_member: "Selecione um membro",
  gb_dialog_title_select_team: "Selecione um time",
  gb_dialog_title_change_workflow:
    "Tem certeza que deseja alterar o status dessa meta?",
  gb_error_small_title: "O campo título deve conter mais caracteres",
  gb_error_small_description: "O campo descrição deve conter mais caracteres",
  gb_error_small_message: "O campo mensagem deve conter mais caracteres",
  gb_error_block_relationship:
    "O relacionamento da meta não pode ser INDIVIDUAL, pois existem {length} participantes conectados com esta meta.",
  gb_error_repeated_target: "O campo target não pode ter valores repetidos.",
  gb_error_invalid_target:
    "O campo target contém valores invertidos/incongruentes",
  gb_error_invalid_weight: "O campo peso deve ter entre 1% e 100%",
  gb_error_invalid_date: "A data inicial deve ser menor que a data final",
  gb_error_invalid_distribution:
    "A distribuição de targets deve totalizar 100%",
  gb_error_invalid_period: "Período inválido",
  gb_error_invalid_salary_base: "Defina a base salarial",
  gb_error_invalid_comment: "O campo comentário deve conter mais caracteres",
  gb_error_role_already_added: "Esse cargo {role} já foi inserido em um time",
  gb_error_goal_already_added:
    "A meta {title} já foi adicionada à este participante",
  gb_error_individual_goal_added:
    "A meta (individual) {title} já foi adicionada à outro participante",
  gb_error_participant_already_added: "Esse participante já foi adicionado",
  gb_error_select_role: "Selecione o cargo",
  gb_error_duplicated_role: "Já existe um outro cargo com esse título",
  gb_error_invalid_year: "Digite um valor válido para o campo ano",
  gb_error_invalid_period_date: "A data de {period} deve ser informada",
  gb_error_dates_conflict: "Conflito entre as datas de {date1} e {date2}",
  gb_error_no_goal_selected: "Nenhuma meta foi selecionada",
  gb_error_no_role_selected: "Selecione o Cargo de todos os Participantes",
  gb_confirm_delete_role: "Tem certeza que deseja excluir essa função?",
  gb_confirm_delete_job_role: "Tem certeza que deseja excluir esse cargo?",
  gb_confirm_delete_goal: "Tem certeza que deseja excluir essa meta?",
  gb_confirm_delete_participant:
    "Tem certeza que deseja excluir esse participante?",
  gb_confirm_delete_trigger: "Tem certeza que deseja excluir esse gatilho?",
  gb_confirm_delete_team: "Tem certeza que deseja excluir esse time?",
  gb_confirm_archive_all: "Arquivar tudo",
  gb_warning_not_approved_to_audit: "Esta meta não foi aprovada para auditoria",
  gb_warning_goal_not_audited: "Esta meta não foi auditada",
  gb_warning_remove_participant_title:
    "Existem participantes relacionados com esse cargo",
  gb_warning_remove_participant_description:
    "Você precisa alterar o cargo de todos os participantes abaixo",
  gb_warning_period_between_blocked:
    "O período de {period} começa em {startDate} e termina em {endDate}. Fora destas datas não será mais possível fazer alterações.",
  gb_warning_cant_change_role:
    "A função desse participante não pode ser alterada.",
  gb_warning_connected_team_cenary:
    "Existe um cenário conectado com este time.",
  gb_warning_connected_goal_insight:
    "Existe um insight conectado com esta meta.",
  gb_warning_connected_participant_question:
    "Existe uma questão conectada com este participante.",
  gb_warning_connected_role_theme: "Existe um tema conectado com este cargo.",
  gb_warning_insights_will_delete:
    "Ao confirmar, todos os insights listados abaixo serão arquivados",
  gb_warning_need_arquive_cenary:
    "Você precisa arquivar o cenário ''{cenaryName}'' para continuar com a exclusão desse time.",
  gb_warning_need_arquive_theme:
    "Você precisa arquivar o tema ''{themeName}'' para continuar com a exclusão desse cargo.",
  gb_warning_need_arquive_question:
    "Você precisa arquivar a questão ''{questionName}'' para continuar com a exclusão desse participante.",
  gb_warning_need_min_administrator:
    "É necessário que ao menos 1 administrador esteja cadastrado.",
  gb_warning_invalid_goals_total_weight: " O peso das metas deve somar 100%",
  gb_options_period_desc_quarterly: "Trimestre",
  gb_options_period_desc_four_months: "Quadrimestre",
  gb_options_period_desc_semiannual: "Semestre",
  gb_options_period_desc_yearly: "Ano",
  gb_options_rt_shared: "Compartilhada",
  gb_options_rt_individual: "Individual",
  gb_options_rt_corporate: "Corporativa",
  gb_options_mu_monetary_value: "Valor ($)",
  gb_options_mu_thousand_monetary_value: "Valor x 1000 ($ mil)",
  gb_options_mu_million_monetary_value: "Valor x 1M ($ mi)",
  gb_options_mu_quantity: "Quantidade",
  gb_options_mu_percentage: "Porcentagem (%)",
  gb_options_mu_date: "Data",
  gb_options_mu_index: "Índice",
  gb_options_mu_kg: "Kg",
  gb_options_mu_tonne: "Toneladas",
  gb_options_mu_km: "Km",
  gb_options_mu_meters: "Metros",
  gb_options_mu_cmeters: "Metros cúbicos",
  gb_options_mu_liters: "Litros",
  gb_options_mu_other: "Outra",
  gb_options_gt_indicator: "Indicador",
  gb_options_gt_project: "Projeto",
  gb_options_gt_option: "Opção",
  gb_options_fq_monthly: "Mensal",
  gb_options_fq_quarterly: "Trimestral",
  gb_options_fq_four_months: "Quadrimestre",
  gb_options_fq_semiannual: "Semestral",
  gb_options_fq_yearly: "Anual",
  gb_options_mr_sum: "Soma",
  gb_options_mr_average: "Média",
  gb_options_mr_balance: "Saldo",
  gb_options_gt_not_hit: "Não atingido",
  gb_options_gt_gate: "Mínimo",
  gb_options_gt_appropriated: "Planejado",
  gb_options_gt_exceeded: "Superado",
  gb_options_pa_admin: "Administrador",
  gb_options_pa_auditor: "Auditor",
  gb_options_pa_registrations: "Cadastros",
  gb_options_pa_analytics: "Análises",
  gb_options_gvs_creating: "Em criação",
  gb_options_gvs_creating_status: "Em criação",
  gb_options_gvs_approval: "Em aprovação",
  gb_options_gvs_approval_status: "aprovação",
  gb_options_gvs_not_approved: "Não aprovado",
  gb_options_gvs_not_approved_status: "não aprovado",
  gb_options_gvs_approved: "Aprovado",
  gb_options_gvs_approved_status: "aprovado",
  gb_options_gvs_audit: "Auditoria",
  gb_options_gvs_audit_status: "auditoria",
  gb_options_gvs_validated: "Validado",
  gb_options_gvs_validated_status: "validado",
  gb_options_cb_reset: "Zerar bônus",
  gb_options_cb_dont_reset: "Não zerar bônus",
  gb_email_workflow_update_title: "[ROOKAU - Metas] Atualização de Workflow",
  gb_email_sended_goals_description:
    "{name} atualizou o status das metas abaixo para",
  gb_filters_title:
    "Com o filtro aplicado todos os seguintes valores serão alterados",
  gb_filters_tab_1_value_1: "% METAS acumulada",
  gb_filters_tab_1_value_2: "% ALCANCE acumulada",
  gb_filters_tab_1_value_3: "QTD. SALÁRIOS",
  gb_filters_tab_1_value_4: "ALCANCE TOTAL por participante",
  gb_filters_tab_1_value_5: "Mínimo, Planejado, Superado e Atual de cada Meta",
  gb_filters_tab_1_value_6: "% Atingimento de cada Meta",
  gb_filters_tab_1_value_7: "% Alcance de cada Meta",
  gb_filters_tab_1_value_8: "ALCANCE TOTAL acumulado",
  gb_filters_tab_2_value_1:
    "ALVO - Agrupamento de Participantes por (% Atingimento ou % Alcance)",
  gb_filters_tab_2_value_2: "ALVO - Agrupamento de Metas por % Atingimento",
  gb_filters_tab_2_value_3:
    "PARTICIPANTES - % Acumulada de Atingimento das Metas de cada participante",
  gb_filters_tab_2_value_4:
    "ÁREAS - % Acumulada de Atingimento das Metas de cada área",
  gb_filters_tab_2_value_5: "METAS - % Acumulada de Atingimento para cada meta",
};

const translations = { en, es, pt };

export default translations;
