import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getSceneryStates,
  getCompAnalysisStates,
} from "../../../customMapStates";
import _ from "lodash";
import utils from "../../../../utils/toolUtils";
import kpiUtils from "../../../../utils/kpis";
import { getCurrentAnalysisTool } from "../../../../utils/cpAnalysis/entity";
import { setCurrentAnalysisToolType } from "../../../../actions/compAnalysisActions";

const {
  getObjectDifference,
  getObjectInfoById,
  getOnlySlugValues,
  removeObjSlug,
} = utils;

const CompetitiveAnalysisWatcher = (props) => {
  const {
    selectedCenary = {},
    currentAdministration = {},
    setCurrentAnalysisToolType,
  } = props;

  useEffect(() => {
    const { competitiveAnalysisId = false } = selectedCenary || {};

    if (competitiveAnalysisId) {
      const currentAnalysisTool = getCurrentAnalysisTool(
        currentAdministration,
        competitiveAnalysisId,
      );

      setCurrentAnalysisToolType(currentAnalysisTool.structure.type);
    }
  }, [selectedCenary]);

  return null;
};

const mapStateToProps = (state) => {
  const { currentAdministration = {} } = getCompAnalysisStates(state);
  const { selectedCenary } = getSceneryStates(state);

  return { selectedCenary, currentAdministration };
};

export default connect(mapStateToProps, { setCurrentAnalysisToolType })(
  CompetitiveAnalysisWatcher,
);
