import React from "react";
import PropTypes from "prop-types";
import SimplePercentageLine from "../../../../../../Common/SimplePercentageLine";
import {
  ruleOfThree,
  checkGreaterThan,
  getToFixed,
} from "../../../../../../../utils/MathUtils";

const EvaluationLine = ({
  evaluation,
  colorInfo,
  maxValue,
  append,
  fontSize,
  lineHeight,
  toFixed,
}) => {
  const absEval = Math.abs(evaluation);
  const fixedEvaluation =
    toFixed === 0 || checkGreaterThan(toFixed, 0, false)
      ? getToFixed(evaluation, toFixed)
      : evaluation;
  const displayEvaluation = append
    ? `${fixedEvaluation}${append}`
    : fixedEvaluation;

  const percentage = ruleOfThree(maxValue, absEval);

  const isNull =
    !checkGreaterThan(evaluation, -100, true) || !isFinite(evaluation);

  return (
    <div className="evaluation-line">
      <div className="number">
        {evaluation === "N/A" ? (
          <font style={{ fontSize: "14px", color: "#ccc" }}>N/A</font>
        ) : (
          !isNull && (
            <font style={lineHeight ? { fontSize, lineHeight } : { fontSize }}>
              {displayEvaluation}
            </font>
          )
        )}
      </div>
      <div className="percentage">
        <SimplePercentageLine
          overrideColor={colorInfo.color}
          backgroundColor={colorInfo.background}
          percentage={percentage}
          isNull={isNull}
          height="18px"
          hidePercentages
          primaryStyles={{ borderRadius: "6px" }}
          secondaryStyles={{ borderRadius: "6px" }}
        />
      </div>
    </div>
  );
};

EvaluationLine.propTypes = {
  evaluation: PropTypes.any,
  colorInfo: PropTypes.object,
  maxValue: PropTypes.number,
  append: PropTypes.any,
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  toFixed: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

EvaluationLine.defaultProps = {
  evaluation: null,
  colorInfo: { gradient: "#eee", background: "#eee" },
  maxValue: 0,
  append: null,
  fontSize: "16px",
  lineHeight: null,
  toFixed: false,
};

export default EvaluationLine;
