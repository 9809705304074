export const en = {
  description: "Description",
  maturityCriteria: "Maturity criteria",
  maturityLevels: "Maturity levels",
  automation: "Automation",
  quality: "Quality",
  knowledge: "Knowledge",
  activityDeliveryDeadline: "Activity delivery deadline",
  managementAndControlLevel: "Management and control level",
  level1: "Level 1",
  level2: "Level 2",
  level3: "Level 3",
  level4: "Level 4",
  level5: "Level 5",
};
