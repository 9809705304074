import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  saveLinkBoardSubtheme,
  createKpiQuestionProcess,
} from "../../../../actions/kpiActions";
import QuestionProcessSelectDialog from "../../../Common/Kpi/forms/QuestionProcessSelectDialog";
import { getSelectedToolStates, getKpiStates } from "../../../customMapStates";
import kpiUtils from "../../../../utils/kpis";

const {
  getKpiConfigBySelectedToolId,
  buildFormAdmConfigBody,
  buildRestAdmConfigBody,
  getProcessInfoById,
} = kpiUtils;

const defaultKpiConfig = buildFormAdmConfigBody({});

const Kpi = (props) => {
  const [kpiAdministration, setKpiAdministration] = useState(defaultKpiConfig);
  const [addedProcesses, setAddedProcesses] = useState([]);

  const {
    type,
    clearViewCallback,
    selectedTool = {},
    isLoading = false,
    allKpiAdministration = [],
    saveLinkBoardSubtheme,
    createKpiQuestionProcess,
    allSceneries = [],
    allThemes = [],
    allQuestions = [],
    params = {},
  } = props;

  const { questionTheme = {} } = params;

  useEffect(() => {
    let toolProcesses = [];

    allSceneries.forEach((scenerieInfo) => {
      if (scenerieInfo.selectedTool.id === selectedTool.id) {
        const scenerieThemes = allThemes[scenerieInfo.id] || [];

        scenerieThemes.forEach((themeInfo) => {
          const themeQuestions = allQuestions[themeInfo.id] || [];

          themeQuestions.forEach(({ kpiConfigurationProcessId }) => {
            toolProcesses.push(kpiConfigurationProcessId);
          });
        });
      }
    });

    if (!_.isEqual(toolProcesses, addedProcesses))
      setAddedProcesses(toolProcesses);
  }, [allSceneries, allThemes, allQuestions, selectedTool.id, addedProcesses]);

  useEffect(() => {
    if (allKpiAdministration) {
      let toolKpiConfig = getKpiConfigBySelectedToolId(
        selectedTool.id,
        allKpiAdministration,
      );

      toolKpiConfig = buildFormAdmConfigBody(toolKpiConfig);

      if (!isLoading && !_.isEqual(toolKpiConfig, kpiAdministration)) {
        setKpiAdministration(toolKpiConfig);
      }
    }
  }, [allKpiAdministration, kpiAdministration, isLoading, selectedTool.id]);

  const handleUpdateKpiConfig = (newKpiConfig) => {
    if (!_.isEqual(kpiAdministration, newKpiConfig)) {
      const restBody = buildRestAdmConfigBody(newKpiConfig);

      saveLinkBoardSubtheme(
        selectedTool,
        questionTheme,
        restBody,
        params.order,
      );
    }

    clearDialogControll();
  };

  const handleSaveQuestion = ({ processes }) => {
    if (questionTheme && Array.isArray(processes)) {
      processes.forEach((processId) => {
        const processInfo = getProcessInfoById(
          processId,
          kpiAdministration.processes,
        );

        createKpiQuestionProcess(processInfo, questionTheme, params.order);
      });
    }
    clearDialogControll();
  };

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
  };

  return (
    <QuestionProcessSelectDialog
      open={true}
      afterConfirm={handleSaveQuestion}
      afterCancel={clearDialogControll}
      kpiAdministration={kpiAdministration}
      setKpiAdministration={setKpiAdministration}
      alternativeSaveCallback={handleUpdateKpiConfig}
      addedProcesses={addedProcesses}
    />
  );
};

const mapStateToProps = (state) => {
  const {
    selectedTool,
    allSceneries,
    allThemes,
    allQuestions,
  } = getSelectedToolStates(state);
  const { allKpiAdministration, isLoading } = getKpiStates(state);

  const { user } = state;

  return {
    userProfile: user,
    selectedTool,
    isLoading,
    allKpiAdministration,
    allSceneries,
    allThemes,
    allQuestions,
  };
};

export default connect(mapStateToProps, {
  saveLinkBoardSubtheme,
  createKpiQuestionProcess,
})(Kpi);
