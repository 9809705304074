import styled, { css } from "styled-components";

export const Container = styled.section`
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;

  overflow-x: auto;
`;

export const BoxSelect = styled.div`
  width: 300px;
  position: absolute;
  top: -66px;
  right: 0px;

  @media (max-width: 768px) {
    position: relative;
    top: 0px;
    right: 0;
  }
`;

export const BoxResponsibleInfo = styled.header`
  position: relative;
  width: 100%;
  height: 60px;
  background: #fff;

  display: flex;

  & > span {
    position: absolute;
    width: 25px;
    line-height: 25px;
    height: 25px;
    color: #fff;
    background: #4f4d4d;
    border-radius: 50%;
    font-weight: 600;
    font-size: 15px;
    left: -5px;
    top: -3px;
    margin-bottom: 10px;
    z-index: 1000;
    text-align: center;
  }

  & > i {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.2s;
    font-size: 23px;
    width: 30px;
    height: 30px;

    &:hover {
      border-radius: 50%;
      background: #ccc;
    }
  }
`;

export const Table = styled.table`
  tbody td {
    font-weight: 500;
  }
`;

export const TdStyled = styled.td`
  cursor: pointer;
  text-align: center;
`;

export const Icon = styled.i`
  transition: 0.2s;
  margin-right: 5px;
  font-size: 13px;

  ${(props) =>
    props.isDesc &&
    css`
      transform: rotate(180deg);
    `}
`;

export const TdTool = styled.td`
  color: #38c;

  i {
    margin-right: 5px;
  }

  & > div {
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
    }
  }
`;

export const TdTracebility = styled.td`
  font-size: 12px;
  text-align: center;
`;

export const ContentReponsibleInfo = styled.div`
  width: 250px;
  margin-left: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
