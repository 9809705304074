import React from "react";
import SingleTable from "../SingleTable";
import OverallTable from "../OverallTable";
import { getPrioritizationStructure } from "../../../../../../../utils/cpAnalysis/entity";

const PrioritizationManagement = ({
  prioritizationData = {},
  intl,
  selectAndOpenInsight,
}) => {
  const {
    headers = [],
    areasStructure = [],
    analysisArea = [],
  } = getPrioritizationStructure(prioritizationData);

  const essentialProps = { intl, headers, selectAndOpenInsight };

  return (
    <div>
      <div className="row overallFactors">
        <div className="col-xs-12 col-lg-12" style={{ padding: "0px" }}>
          <OverallTable {...essentialProps} data={areasStructure} />
        </div>
      </div>
      {analysisArea.map((area, index) => {
        const { title = "", evaluations = [], result = 0 } = area || {};

        return (
          <div key={index} className="row">
            <div className="col-xs-12 col-lg-12" style={{ padding: "0px" }}>
              <SingleTable
                title={title}
                {...essentialProps}
                data={evaluations}
                result={result}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PrioritizationManagement;
