import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Popover } from "react-bootstrap";
import { injectIntl, defineMessages } from "react-intl";
import TableEvaluation from "../../../Common/TableEvaluation";
import AverageBadge from "../../../Common/AverageBadge";
import utils from "../../../../utils/toolUtils";
import { getSelectedToolStates } from "../../../customMapStates";
import { getProjectHeadValues } from "../../../../constants/projectPrioritization";
import { updateInsightProjectPrioritization } from "../../../../actions/projectPrioritizationActions";

const {
  getAllInsightEvaluationInfo,
  getObjectInfoById,
  orderArrayByObjAttr,
} = utils;

let ProjectPrioritization = (props) => {
  const [intl, setIntl] = useState(props.intl);

  const {
    allProjectPrioritization,
    allInsightPrioritizations,
    insight = {},
    updateInsightProjectPrioritization,
    selectedTool,
  } = props;
  const insightId = insight.id ? insight.id : null;

  const {
    onlyCriterias,
    projectPriorizationEvaluation,
    maxCriteriaSum,
    sumEvaluation,
    sumPercentage,
    pathway,
  } = getAllInsightEvaluationInfo(
    insight,
    selectedTool.id,
    allProjectPrioritization,
    allInsightPrioritizations,
  );

  const orderedOnlyCriterias = orderArrayByObjAttr(onlyCriterias, "title");

  useEffect(() => {
    if (props.intl.locale !== intl.locale) {
      setIntl(props.intl);
    }
  }, [intl.locale, props.intl]);

  const messages = defineMessages({
    mainTitle: { id: "title_group_priorization" },
    selectCriteria: { id: "text_select_criteria" },
    textSum: { id: "global.sum" },
  });

  const getPrioritizationData = () => {
    const tableHeadVals = getProjectHeadValues();
    const DEFAULT_VALUE = getObjectInfoById(0, tableHeadVals, "valueNumber")
      .value;

    let DEFAULT_LABEL = null;

    let tableOptions = [];

    let selectedLabel = null;
    tableOptions = [
      ...orderedOnlyCriterias.map(({ id, title, labels = [] }, index) => {
        const valInfo = getObjectInfoById(
          projectPriorizationEvaluation[id],
          tableHeadVals,
          "valueNumber",
        );

        selectedLabel = labels[projectPriorizationEvaluation[id]];

        DEFAULT_LABEL = labels && labels.length > 0 ? labels[0].label : null;

        const labelTitle =
          selectedLabel && selectedLabel.label ? selectedLabel.label : null;

        const orederedLabels = orderArrayByObjAttr(
          labels,
          "index",
          false,
          true,
        );

        const popover =
          orederedLabels.length > 0 ? (
            <Popover
              id={`criteriaLabels${index}`}
              style={{ zIndex: "9999" }}
              title="Subtitles"
            >
              <ul>
                {orederedLabels.map(({ label }, index) => (
                  <li
                    key={label}
                    style={{ textAlign: "left", display: "flex" }}
                  >
                    <span style={{ width: "16px" }}>
                      <b>{index}:</b>
                    </span>
                    {label}
                  </li>
                ))}
              </ul>
            </Popover>
          ) : (
            <span />
          );

        return {
          id,
          label: title,
          value: valInfo && valInfo.value ? valInfo.value : DEFAULT_VALUE,
          popover,
          selectedLabel: valInfo && valInfo.value ? labelTitle : DEFAULT_LABEL,
          labels,
        };
      }),
    ];

    return tableOptions;
  };

  const handleSave = (tableData) => {
    const tableHeadVals = getProjectHeadValues();

    const finalProjectPrioritization = [
      ...tableData.map(({ id, value }) => {
        const valInfo = getObjectInfoById(value, tableHeadVals, "value");

        return {
          criteriaId: id,
          value: valInfo.valueNumber || 0,
        };
      }),
    ];

    updateInsightProjectPrioritization(
      insightId,
      finalProjectPrioritization,
      selectedTool.id,
    );

    //handleCriteriaSubtitle();
  };

  const tableOptions = getPrioritizationData();

  return (
    <div className="box box-primary">
      <div className="box-header with-border">
        <h3 className="box-title">
          <i className="fas fa-tachometer-alt" />
          {intl.formatMessage(messages.mainTitle)}
        </h3>
      </div>
      <div className="box-body">
        <div className="row">
          <div className="col-xs-12">
            <div className="row">
              <TableEvaluation
                title={intl.formatMessage(messages.selectCriteria)}
                values={getProjectHeadValues()}
                options={tableOptions}
                fullWidth
                onSave={(tableData) => handleSave(tableData)}
                hideTopLabels
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  padding: "5px",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <AverageBadge
                  average={sumEvaluation}
                  label={intl.formatMessage(messages.textSum)}
                  maxValue={maxCriteriaSum}
                  displayMaxValue
                  multiplier={5}
                />
                <h5
                  style={{
                    margin: "0px",
                    padding: "0px 5px",
                    height: "25px",
                    lineHeight: "25px",
                    border: "1px solid #ddd",
                    backgroundColor: "#eee",
                  }}
                >
                  {sumPercentage}%
                </h5>
              </div>
              <div style={{ padding: "0px 0px 0px 15px" }}>
                <h6 style={{ fontWeight: "normal" }}>Pathway</h6>
                <h5>{pathway}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { projectPrioritization } = state;
  const { selectedTool } = getSelectedToolStates(state);

  const allProjectPrioritization = projectPrioritization.all
    ? projectPrioritization.all
    : [];
  const allInsightPrioritizations = projectPrioritization.allInsightPrioritizations
    ? projectPrioritization.allInsightPrioritizations
    : [];

  return {
    allProjectPrioritization,
    allInsightPrioritizations,
    selectedTool,
  };
};

ProjectPrioritization = injectIntl(
  connect(mapStateToProps, { updateInsightProjectPrioritization })(
    ProjectPrioritization,
  ),
);

export { ProjectPrioritization };
