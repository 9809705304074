import {
  UPDATE_INSIGHT_MATURITY,
  FETCH_ALL_MATURITY_DATA,
} from "../actions/types";

import { updateAllList } from "../utils/reducerUtils";

const insightMaturityReducer = (state = {}, action) => {
  const { payload } = action;
  const currentAllEvaluation = state.all ? state.all : [];

  const updateEvaluation = (newBody) => {
    let finalEvaluation = [];
    let didUpdate = false;

    currentAllEvaluation.forEach((itemBody) => {
      if (itemBody.insightId === newBody.insightId) {
        didUpdate = true;
      }

      finalEvaluation = [
        ...finalEvaluation,
        { ...itemBody, maturityEvaluation: newBody.maturityEvaluation },
      ];
    });

    if (!didUpdate) finalEvaluation = [...finalEvaluation, newBody];

    return finalEvaluation;
  };

  switch (action.type) {
    case UPDATE_INSIGHT_MATURITY:
      return {
        ...state,
        all: updateEvaluation(payload),
      };
    case FETCH_ALL_MATURITY_DATA:
      return {
        ...state,
        all: updateAllList(currentAllEvaluation, payload, "insightId"),
      };
    default:
      return state;
  }
};

export default insightMaturityReducer;
