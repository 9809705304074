import React from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import utils from "../../../../../../../utils/toolUtils";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import { deleteTrigger } from "../../../../../../../actions/goalsAndBonusActions";
import {
  getEmptyTableRow,
  getDefaultAddTableLine,
} from "../../../../../../../utils/tableUtils";
import { getDefaultRemoveColumn } from "../../../../../../../utils/okrUtils";

const { getUpdatedObjectInArray, getUpdatedDeleteArrayByIndex } = utils;

const messages = defineMessages({
  gb_table_text_trigger: { id: "gb_table_text_trigger" },
  gb_table_text_no_trigger: { id: "gb_table_text_no_trigger" },
  gb_table_text_description: { id: "gb_table_text_description" },
  gb_confirm_delete_trigger: { id: "gb_confirm_delete_trigger" },
});

const ProgramTriggersTable = (props) => {
  const {
    setConfirmationDialog,
    goalBonusAdministration,
    setGoalBonusAdministration,
    resetConfirmDialog,
    toggleOpenFormDialogs,
    intl,
    deleteTrigger,
  } = props;

  const handleUpdateTriggers = (newInfo, fieldSlug = "title", compareIndex) => {
    const newUpdatedTriggers = getUpdatedObjectInArray(
      newInfo,
      goalBonusAdministration.triggers,
      fieldSlug,
      compareIndex,
    );

    setGoalBonusAdministration({
      ...goalBonusAdministration,
      triggers: newUpdatedTriggers,
    });
  };

  const handleDeleteTrigger = (index) => {
    const deletingObjInfo = goalBonusAdministration.triggers[index];

    if (deletingObjInfo.id) {
      deleteTrigger(deletingObjInfo.id, goalBonusAdministration.id);

      const updatedTriggers = getUpdatedDeleteArrayByIndex(
        goalBonusAdministration.triggers,
        index,
      );

      setGoalBonusAdministration({
        ...goalBonusAdministration,
        triggers: updatedTriggers,
      });
    }

    resetConfirmDialog();
  };

  const openDeleteProgramTriggers = (index) => {
    setConfirmationDialog({
      title: intl.formatMessage(messages.gb_confirm_delete_trigger),
      onConfirm: () => handleDeleteTrigger(index),
      onCancel: () => resetConfirmDialog(),
      open: true,
    });
  };

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="2">
            {intl.formatMessage(messages.gb_table_text_trigger)}
          </td>
          <td colSpan="3">
            {intl.formatMessage(messages.gb_table_text_description)}
          </td>
          <td />
        </tr>
      </thead>
      <tbody>
        {goalBonusAdministration.triggers.map((triggerInfo, index) => {
          return (
            <tr className="block-hover" key={index}>
              <td align="center" style={{ width: "40px" }}>
                {index + 1}
              </td>
              <SimpleExcelCollumn
                id="triggerRuleTitle"
                label="Título"
                type="text"
                value={triggerInfo.title}
                onChange={(e) =>
                  handleUpdateTriggers(e.target.value, "title", index)
                }
              />
              <SimpleExcelCollumn
                id="triggerRuleDescription"
                label={intl.formatMessage(messages.gb_table_text_description)}
                type="text"
                value={triggerInfo.description}
                onChange={(e) =>
                  handleUpdateTriggers(e.target.value, "description", index)
                }
                colSpan="3"
              />
              {getDefaultRemoveColumn(() => openDeleteProgramTriggers(index))}
            </tr>
          );
        })}
        {getEmptyTableRow(
          goalBonusAdministration.triggers,
          intl.formatMessage(messages.gb_table_text_no_trigger),
        )}
        {getDefaultAddTableLine(() => toggleOpenFormDialogs("newTrigger"))}
      </tbody>
    </table>
  );
};

export default injectIntl(
  connect(null, { deleteTrigger })(ProgramTriggersTable),
);
