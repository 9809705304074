import React from "react";
import PropTypes from "prop-types";

const SimpleLegend = (props) => {
  const {
    size,
    label,
    fillColor,
    styles,
    fontSize,
    onClick = () => {},
  } = props;
  // USE ESTE COMPONENTE DENTRO DE UMA "<ul></ul>"
  return (
    <li
      className="recharts-legend-item legend-item-0"
      style={{
        display: "inline-block",
        marginRight: "10px",
        userSelect: "none",
        ...styles,
      }}
      onClick={onClick}
    >
      <svg
        className="recharts-surface"
        width={size}
        height={size}
        viewBox="0 0 32 32"
        version="1.1"
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          marginRight: "4px",
        }}
      >
        <path
          fill={fillColor}
          className="recharts-symbols"
          transform="translate(16, 16)"
          d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"
        />
      </svg>
      <span className="recharts-legend-item-text" style={{ fontSize }}>
        {label}
      </span>
    </li>
  );
};

SimpleLegend.propTypes = {
  label: PropTypes.string.isRequired,
  fillColor: PropTypes.string,
  size: PropTypes.number,
  fontSize: PropTypes.string,
  styles: PropTypes.object,
};

SimpleLegend.defaultProps = {
  fillColor: "#8884d8",
  size: 14,
  fontSize: "14px",
  styles: {},
};

export default SimpleLegend;
