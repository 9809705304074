import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getGoalsBonusStates } from "../../../customMapStates";
import _ from "lodash";
import utils from "../../../../utils/toolUtils";
import gbUtils from "../../../../utils/goalsBonus";
import { updateInsightName } from "../../../../actions/insightActions";

const {
  getObjectDifference,
  getObjectInfoById,
  getOnlySlugValues,
  removeObjSlug
} = utils;

const { buildFormAdmConfigBody, getGoalsBonusBySelectedToolId } = gbUtils;

const defaultFormInitialGoalBonus = buildFormAdmConfigBody({});

const GoalsBonusWatcher = props => {
  const [goalBonusAdministration, setGoalBonusAdministration] = useState(
    defaultFormInitialGoalBonus
  );
  const [firstValidation, setFirstValidation] = useState(false);

  const {
    allGoalBonusAdministration = [],
    selectedTool = {},
    allAnswers = {},
    updateInsightName
  } = props;

  useEffect(() => {
    let toolGoalsBonusAdm = getGoalsBonusBySelectedToolId(
      selectedTool.id,
      allGoalBonusAdministration
    );
    toolGoalsBonusAdm = buildFormAdmConfigBody(toolGoalsBonusAdm);

    const isAdmObjDiff = !_.isEqual(toolGoalsBonusAdm, goalBonusAdministration);

    if (
      toolGoalsBonusAdm &&
      toolGoalsBonusAdm.goals &&
      Object.keys(allAnswers).length > 0
    ) {
      if (isAdmObjDiff || !firstValidation) {
        const compareBase = removeObjSlug(goalBonusAdministration.goals, "id");

        const diff = getObjectDifference(toolGoalsBonusAdm.goals, compareBase);

        if (diff) {
          if (!firstValidation) setFirstValidation(true);

          const watchGoals = getOnlySlugValues(diff, "id");

          if (watchGoals.length > 0) {
            Object.keys(allAnswers).forEach(questionId => {
              const questionAnswers = allAnswers[questionId];

              questionAnswers.forEach(insightInfo => {
                const { goalsBonusProgramGoalId } = insightInfo;
                const relatedGoalId =
                  watchGoals[watchGoals.indexOf(goalsBonusProgramGoalId)] ||
                  null;

                if (relatedGoalId && insightInfo.text) {
                  const goalInfo = getObjectInfoById(
                    relatedGoalId,
                    toolGoalsBonusAdm.goals,
                    "id"
                  );

                  if (goalInfo && goalInfo.title !== insightInfo.text) {
                    updateInsightName(
                      questionId,
                      insightInfo.id,
                      goalInfo.title
                    );
                  }
                }
              });
            });
          }
        }

        if (isAdmObjDiff) setGoalBonusAdministration(toolGoalsBonusAdm);
      }
    }
  }, [
    allGoalBonusAdministration,
    allAnswers,
    firstValidation,
    goalBonusAdministration,
    selectedTool.id,
    updateInsightName
  ]);

  return null;
};

const mapStateToProps = state => {
  const { allGoalBonusAdministration } = getGoalsBonusStates(state);

  return { allGoalBonusAdministration };
};

export default connect(mapStateToProps, { updateInsightName })(
  GoalsBonusWatcher
);
