export const es = {
  description: "Descripción",
  maturityCriteria: "Criterios de maturidad",
  maturityLevels: "Niveles de maturidad",
  automation: "Automatización",
  quality: "Calidad",
  knowledge: "Conocimiento",
  activityDeliveryDeadline: "Fecha de entrega de la actividad",
  managementAndControlLevel: "Nivel de gestión y control",
  level1: "Nivel 1",
  level2: "Nivel 2",
  level3: "Nivel 3",
  level4: "Nivel 4",
  level5: "Nivel 5",
};
