import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { createProjectTypeQuestion } from "../../../../actions/projectPrioritizationActions";
import QuestionProjectTypeSelectDialog from "../../../Common/ProjectPrioritization/forms/QuestionProjectTypeSelectDialog";
import { getSelectedToolStates } from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";

const {
  getGoalsBonusBySelectedToolId,
  buildFormPrioritizationBody,
  getObjectInfoById,
} = utils;

const defaultInitialForm = buildFormPrioritizationBody({});

const ProjectPrioritization = (props) => {
  const [projectPrioritizationAdmin, setProjectPrioritizationAdmin] = useState(
    defaultInitialForm,
  );

  const {
    type,
    clearViewCallback,
    selectedTool = {},
    allProjectPrioritization = [],
    createProjectTypeQuestion,
    params,
  } = props;

  const { questionTheme = {} } = params;
  const { projectPriorizationConfigurationGroupId } = questionTheme;

  useEffect(() => {
    let transformedProjectPrioritization = [];

    if (allProjectPrioritization) {
      transformedProjectPrioritization = getGoalsBonusBySelectedToolId(
        selectedTool.id,
        allProjectPrioritization,
      );

      transformedProjectPrioritization = buildFormPrioritizationBody(
        transformedProjectPrioritization,
      );
    }

    if (
      !_.isEqual(transformedProjectPrioritization, projectPrioritizationAdmin)
    ) {
      setProjectPrioritizationAdmin(transformedProjectPrioritization);
    }
  }, [allProjectPrioritization, projectPrioritizationAdmin, selectedTool.id]);

  const getOnlyProjectTypes = () => {
    const onlyProjectTypes = [];

    if (projectPrioritizationAdmin) {
      projectPrioritizationAdmin.projectGroups.forEach(
        ({ projectTypes, id }) => {
          if (id === projectPriorizationConfigurationGroupId) {
            projectTypes.forEach((pTypeInfo) =>
              onlyProjectTypes.push({ ...pTypeInfo }),
            );
          }
        },
      );
    }

    return onlyProjectTypes;
  };

  const handleSaveQuestion = ({ projectTypeId }) => {
    if (selectedTool.id && projectTypeId && questionTheme) {
      const onlyProjectTypes = [];
      projectPrioritizationAdmin.projectGroups.forEach(({ projectTypes }) => {
        projectTypes.forEach((pTypeInfo) => onlyProjectTypes.push(pTypeInfo));
      });

      const projectTypeInfo = getObjectInfoById(
        projectTypeId,
        onlyProjectTypes,
        "id",
      );

      createProjectTypeQuestion(questionTheme, projectTypeInfo);
    }

    clearDialogControll();
  };

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
  };

  return (
    <QuestionProjectTypeSelectDialog
      open
      afterConfirm={handleSaveQuestion}
      afterCancel={clearDialogControll}
      onlyProjectTypes={getOnlyProjectTypes()}
    />
  );
};

const mapStateToProps = (state) => {
  const { selectedTool } = getSelectedToolStates(state);

  const { user } = state;

  return {
    userProfile: user,
    selectedTool,
    allProjectPrioritization: state.projectPrioritization.all,
  };
};

export default connect(mapStateToProps, { createProjectTypeQuestion })(
  ProjectPrioritization,
);
