import React from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import utils from "../../../../../../../utils/toolUtils";
import { deleteKpiProcess } from "../../../../../../../actions/kpiActions";
import {
  getEmptyTableRow,
  getDefaultAddTableLine,
  getDefaultEditColumn,
} from "../../../../../../../utils/tableUtils";
import { getDefaultRemoveColumn } from "../../../../../../../utils/okrUtils";

const {
  getUpdatedDeleteArrayByIndex,
  orderArrayByObjAttr,
  getObjectInfoById,
  getOnlySublevelArrays,
} = utils;

const messages = defineMessages({
  kpi_table_text_subthemes_upper: { id: "kpi_table_text_subthemes_upper" },
  kpi_warning_need_arquive_question: {
    id: "kpi_warning_need_arquive_question",
  },
  kpi_warning_connected_subtheme_question: {
    id: "kpi_warning_connected_subtheme_question",
  },
  kpi_confirm_delete_subtheme: { id: "kpi_confirm_delete_subtheme" },
  global_noneRegistered: { id: "global.noneRegistered" },
});

const ProcessConfigTable = (props) => {
  const {
    kpiAdministration,
    setKpiAdministration,
    setConfirmationDialog,
    resetConfirmDialog,
    handleSetEditingForm,
    toggleOpenFormDialogs,
    allQuestions,
    deleteKpiProcess,
    intl,
  } = props;

  const handleDeleteProcess = (index) => {
    const deletingObjInfo = kpiAdministration.processes[index];

    if (deletingObjInfo.id) {
      deleteKpiProcess(deletingObjInfo.id, kpiAdministration.id);

      const updatedProcesses = getUpdatedDeleteArrayByIndex(
        kpiAdministration.processes,
        index,
      );

      setKpiAdministration({
        ...kpiAdministration,
        processes: updatedProcesses,
      });
    }

    resetConfirmDialog();
  };

  const openDeleteProcessDialog = (index) => {
    const deletingProcessInfo = kpiAdministration.processes[index] || {};
    let allowedToDelete = true;

    const onlyQuestions = getOnlySublevelArrays(allQuestions);

    if (deletingProcessInfo.id) {
      const relatedQuestion = getObjectInfoById(
        deletingProcessInfo.id,
        onlyQuestions,
        "kpiConfigurationProcessId",
      );

      allowedToDelete = relatedQuestion && relatedQuestion.id ? false : true;

      setConfirmationDialog({
        title: allowedToDelete
          ? intl.formatMessage(messages.kpi_confirm_delete_subtheme)
          : intl.formatMessage(
              messages.kpi_warning_connected_subtheme_question,
            ),
        description: allowedToDelete
          ? null
          : intl.formatMessage(messages.kpi_warning_need_arquive_question, {
              questionName: relatedQuestion.text,
            }),
        onConfirm: allowedToDelete
          ? () => handleDeleteProcess(index)
          : resetConfirmDialog,
        confirmText: !allowedToDelete ? "OK" : null,
        hideCancel: !allowedToDelete ? true : false,
        onCancel: resetConfirmDialog,
        open: true,
      });
    }
  };

  let reoderedProcesses = orderArrayByObjAttr(
    kpiAdministration.processes,
    "title",
  );

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="100">
            {intl.formatMessage(messages.kpi_table_text_subthemes_upper)}
          </td>
        </tr>
      </thead>
      <tbody>
        {reoderedProcesses.map((processInfo, index) => {
          return (
            <tr key={`process_${index}`} className="block-hover">
              <td align="center" style={{ width: "40px" }}>
                {index + 1}
              </td>
              <td>{processInfo.title}</td>
              {getDefaultEditColumn(() =>
                handleSetEditingForm("newProcess", {
                  processId: processInfo.id,
                }),
              )}
              {getDefaultRemoveColumn(() => openDeleteProcessDialog(index))}
            </tr>
          );
        })}
        {getEmptyTableRow(
          kpiAdministration.processes,
          intl.formatMessage(messages.global_noneRegistered),
        )}
        {getDefaultAddTableLine(() => toggleOpenFormDialogs("newProcess"))}
      </tbody>
    </table>
  );
};

export default injectIntl(
  connect(null, { deleteKpiProcess })(ProcessConfigTable),
);
