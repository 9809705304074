import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Tabs } from "@material-ui/core";

const MaterialScrollTabs = withStyles({
  root: {
    width: "100%",
    border: "none",
    height: "50px"
  },
  flexContainer: {
    height: "50px"
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "3px !important",
    "& > div": {
      width: "100%",
      backgroundColor: "#6b42a9"
    }
  },
  scrollButtons: {
    width: "25px",
    border: "1px solid #ddd",
    order: "9000",
    backgroundColor: "#eee",
    "& > svg": {
      fontSize: "18px"
    },
    "&:hover": {
      borderColor: "#666",
      "& > svg": {
        color: "#000"
      }
    }
  }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

export default MaterialScrollTabs;
