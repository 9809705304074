import { TOGGLE_EDIT_MODE } from "../actions/types";

const initialState = {
  editModeOn: false,
};

const boardReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_MODE:
      return {
        ...state,
        editModeOn: !state.editModeOn,
      };
    default:
      return state;
  }
};

export default boardReducer;
