import React, { useState, useEffect } from "react";
import { defineMessages } from "react-intl";
import _ from "lodash";
import TargetColorBadge from "../../TargetColorBadge";
import {
  defaultGoalsDisplayOrder,
  getRelationshipTypeOptions,
  getMeasurementOptions,
  getMeasurementUnityOptions,
} from "../../../../constants/goalsAndBonus";
import {
  getActualOnCoursePeriods,
  getStandardPeriodTotalEvaluation,
} from "../../../../utils/kpis/calc2";
import {
  displayTransformValue,
  getHitTargetInfo,
} from "../../../../utils/kpis/display";
import { translatedText } from "../../../../utils/translationUtils";
import {
  getObjectInfoById,
  reorderObjectBySlugs,
} from "../../../../utils/ArrayUtils";
import { multipleIndicatorsWithBonus } from "../../../../utils/goalsBonus/calc";
import { getToFixed } from "../../../../utils/MathUtils";
import { getRelatedInsightByParticipant } from "../../../../utils/goalsBonus/filter";

const messages = defineMessages({
  gb_table_text_goal_upper: { id: "gb_table_text_goal_upper" },
  gb_table_text_weight: { id: "gb_table_text_weight" },
  gb_form_unity: { id: "gb_form_unity" },
  gb_form_measurement: { id: "gb_form_measurement" },
  gb_table_text_relationship: { id: "gb_table_text_relationship" },
  gb_table_text_gate: { id: "gb_table_text_gate" },
  gb_table_text_appropriated: { id: "gb_table_text_appropriated" },
  gb_table_text_exceeded: { id: "gb_table_text_exceeded" },
  gb_table_text_actual: { id: "gb_table_text_actual" },
  gb_table_text_goals: { id: "gb_table_text_goals" },
  gb_table_text_reach: { id: "gb_table_text_reach" },
  gb_table_text_no_goal_found: { id: "gb_table_text_no_goal_found" },
});

const defaultFilter = {
  attr: "title",
  order: "ASC",
};

const translation = (id) => translatedText(id, messages);

const GoalsTableList = ({
  goalBonusAdministration,
  displayGoals = [],
  selectAndOpenInsight,
  periodFilterActive,
  participantInfo,
}) => {
  const [orderedGoals, setOrderedGoals] = useState([]);
  const [filter, setFilter] = useState(defaultFilter);

  const relationshipTypeOptions = getRelationshipTypeOptions();
  const measurementOptions = getMeasurementOptions();
  const measurementUnityOptions = getMeasurementUnityOptions();

  useEffect(() => {
    const ord1 = filter.order === "ASC" ? 1 : -1;
    const ord2 = filter.order === "ASC" ? -1 : 1;

    let newOrdered = displayGoals;

    if (filter.attr === "title") {
      // FILTER BY TITLE
      newOrdered = [...displayGoals].sort((a, b) =>
        a.title > b.title ? ord1 : ord2,
      );
    }

    if (filter.attr === "relationshipType") {
      const order =
        filter.order === "ASC"
          ? defaultGoalsDisplayOrder
          : [...defaultGoalsDisplayOrder].reverse();

      newOrdered = reorderObjectBySlugs(
        order,
        displayGoals,
        "relationshipType",
      );
    }

    setOrderedGoals(
      newOrdered.map((goalInfo) => {
        return {
          ...goalInfo,
          participantInsightId: getRelatedInsightByParticipant(
            goalInfo,
            participantInfo,
          ),
        };
      }),
    );
  }, [displayGoals, participantInfo, filter]);

  const getNewOrder = (currentOrder) => {
    if (currentOrder === "ASC") return "DESC";
    return "ASC";
  };

  const handleFilter = (attr) => {
    let updatedFilter = defaultFilter;

    if (attr === filter.attr) {
      updatedFilter = {
        attr,
        order: getNewOrder(filter.order),
      };
    } else {
      updatedFilter = { ...defaultFilter, attr };
    }

    setFilter(updatedFilter);
  };

  const oveflowTextStyles = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "450px",
    cursor: "pointer",
  };

  const { clearBonusGateMin, goalTargets = {} } = goalBonusAdministration;

  const OrderButton = ({ attr }) => {
    return (
      <button
        className="btn btn-data-sort grow"
        onClick={() => handleFilter(attr)}
        style={{ padding: "0px 5px", margin: "0px 0px 0px 10px" }}
      >
        <i className="fas fa-caret-up" />
        <i className="fas fa-caret-down" />
      </button>
    );
  };

  return (
    <table className="simple-table" style={{ margin: "0px" }}>
      <thead style={{ fontSize: "12px" }}>
        <tr className="small-font block-white-space">
          <td align="left">
            {`${translation("gb_table_text_goal_upper")} `}
            <OrderButton attr="title" />
          </td>
          <td>
            {`${translation("gb_table_text_relationship")} `}
            <OrderButton attr="relationshipType" />
          </td>
          <td>{translation("gb_table_text_weight")}</td>
          <td>{translation("gb_form_unity")}</td>
          <td>{translation("gb_form_measurement")}</td>
          <td width="140">{translation("gb_table_text_gate")}</td>
          <td width="140">{translation("gb_table_text_appropriated")}</td>
          <td width="140">{translation("gb_table_text_exceeded")}</td>
          <td width="140">{translation("gb_table_text_actual")}</td>
          <td>{`% ${translation("gb_table_text_goals")} `}</td>
          <td>{`% ${translation("gb_table_text_reach")} `}</td>
        </tr>
      </thead>
      <tbody>
        {orderedGoals?.map((goalInfo) => {
          const {
            realGoalsReachSum,
            reachBonusWeightSum,
            evaluatedGoals,
          } = multipleIndicatorsWithBonus(
            [goalInfo],
            goalTargets,
            clearBonusGateMin,
            periodFilterActive,
          );

          const filteredTarget = goalInfo.target || {};

          const hitTargetInfo = getHitTargetInfo(
            realGoalsReachSum,
            goalTargets,
            evaluatedGoals.length === 0,
          );

          const periodsOnCourse = getActualOnCoursePeriods(goalInfo, true);
          const filteredGoalActualValue = getStandardPeriodTotalEvaluation(
            goalInfo,
            periodsOnCourse,
          );

          const { participantInsightId } = goalInfo;

          return (
            <tr key={`${goalInfo.id}`} className="small-font block-white-space">
              <td title={goalInfo.title}>
                <div
                  style={oveflowTextStyles}
                  onClick={() => selectAndOpenInsight(participantInsightId)}
                >
                  {goalInfo.title}
                </div>
              </td>
              <td align="center">
                {
                  getObjectInfoById(
                    goalInfo.relationshipType,
                    relationshipTypeOptions,
                    "value",
                  ).label
                }
              </td>
              <td align="right">{`${goalInfo.weight}%`}</td>
              <td align="center">
                {
                  getObjectInfoById(
                    goalInfo.measurementUnity,
                    measurementUnityOptions,
                    "value",
                  ).label
                }
                {goalInfo.measurementUnity === "OTHER" &&
                  ` (${goalInfo.otherMeasurement})`}
              </td>
              <td align="center">
                {
                  getObjectInfoById(
                    goalInfo.measurement,
                    measurementOptions,
                    "value",
                  ).label
                }
              </td>
              <td align="right">
                {displayTransformValue(
                  filteredTarget?.gate,
                  goalInfo.measurementUnity,
                )}
              </td>
              <td align="right">
                {displayTransformValue(
                  filteredTarget?.appropriated,
                  goalInfo.measurementUnity,
                )}
              </td>
              <td align="right">
                {displayTransformValue(
                  filteredTarget?.exceeded,
                  goalInfo.measurementUnity,
                )}
              </td>
              <td align="right">
                <b>
                  {displayTransformValue(
                    filteredGoalActualValue,
                    goalInfo.measurementUnity,
                  )}
                </b>
              </td>
              <td width="80" align="right">
                <TargetColorBadge
                  hitTargetInfo={hitTargetInfo}
                  goalPercentage={realGoalsReachSum}
                />
              </td>
              <td width="80" align="right">
                <b>{`${getToFixed(reachBonusWeightSum, 1)}%`}</b>
              </td>
            </tr>
          );
        })}
        {orderedGoals?.length === 0 && (
          <tr>
            <td colSpan="100">{translation("gb_table_text_no_goal_found")}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

function areEqual(prev, next) {
  if (!_.isEqual(prev.periodFilter, next.periodFilter)) return false;
  if (!_.isEqual(prev.displayGoals, next.displayGoals)) return false;
  if (!_.isEqual(prev.goalBonusAdministration, next.goalBonusAdministration))
    return false;
  if (!_.isEqual(prev.participantInfo, next.participantInfo)) return false;

  return true;
}

export default React.memo(GoalsTableList, areEqual);
