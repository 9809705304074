import styled from "styled-components";

import "react-circular-progressbar/dist/styles.css";

export const Container = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, 399px);
  grid-gap: 15px;
  padding: 0;
  margin-top: 15px;

  li {
    display: flex;
    border: 1px solid #dedede;
    padding: 5px 10px;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    color: #444;

    & > div {
      display: flex;
    }
  }
`;

export const FileInfo = styled.div`
  display: flex;

  div {
    display: flex;
    flex-direction: column;

    span {
      font-size: 14px;
      color: #999;
      margin-top: 5px;

      button {
        border: 0;
        background: transparent;
        color: #e57878;
        margin-left: 5px;
        outline: none;
        cursor: pointer;
      }
    }

    strong {
      cursor: pointer;
    }

    strong:hover {
      text-decoration: underline;
    }
  }
`;

export const Preview = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 5px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 5px;
`;

export const IconPreview = styled.i`
  font-size: 36px;
  margin-right: 5px;
  margin-top: 3px;
`;

export const IconCheck = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #78e5d5;
  color: #fff;
`;

export const IconAlert = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #e57878;
  color: #fff;
`;

export const IconDownload = styled.i`
  color: #222;
  margin-right: 8px;
  font-size: 24px;
  cursor: pointer;
`;
