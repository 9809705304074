import { useEffect, useState } from "react";
import {
  getToolParticipantWorkflow,
  putParticipantSelfEvaluation,
} from "../../../../../actions/skillAssessmentActions";
import ConfirmationDialog from "../../../../../components/Common/ConfirmationDialog";
import SimpleLoader from "../../../../../components/SimpleLoader";
import WorkflowHeader from "../../../../../components/Tool/ToolTabs/CustomFields/SkillAssessment/tabs/workflow/WorkflowHeader";
import WorkflowReplyTable from "../../../../../components/Tool/ToolTabs/CustomFields/SkillAssessment/tabs/workflow/WorkflowReplyTable";
import { useWindowSize } from "../../../../../utils/customHooks";
import { getEvaluationStagesOptions } from "../../../../../utils/skillsAssessment/constants";

const ParticipantWorkflowTable = ({
  toolId: selected_tool_id,
  hideAreaAndPosition = true,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [savingStatus, setSavingStatus] = useState({
    isLoading: false,
    saved: false,
  });
  const [workflow, setWorkflow] = useState({
    participant: {},
    participantWorkflow: [],
    scaleOptions: [],
    companyEvaluatorOptions: [],
  });
  const [insight, setInsight] = useState({});
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
  });
  const windowSize = useWindowSize();

  const headerHeight = 250;
  const footerHeight = 50;
  const tableHeight = windowSize[1] - headerHeight - footerHeight - 20;

  const currentStage = getEvaluationStagesOptions().find(
    ({ value }) => value === workflow.participant.evaluationStage
  );

  async function getData() {
    setIsLoading(true);

    try {
      const { data } = await getToolParticipantWorkflow({ selected_tool_id });
      const { participant, evaluationScale, insight } = data;

      if (participant?.id) {
        setInsight(insight);
        setWorkflow({
          ...data,
          scaleOptions: evaluationScale
            .map(({ title, value }) => {
              return { label: `${value} - ${title}`, value };
            })
            .reverse(),
        });
      }
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleWorkflowUpdate(workflowItem, fieldSlug, fieldValue) {
    const workflowItemIndex = workflow.participantWorkflow?.findIndex(
      ({ id }) => id === workflowItem.id
    );

    if (workflowItemIndex > -1) {
      const updatedWorkflow = [...workflow.participantWorkflow];
      updatedWorkflow[workflowItemIndex] = {
        ...updatedWorkflow[workflowItemIndex],
        [fieldSlug]: fieldValue,
      };

      setWorkflow((current) => {
        return {
          ...current,
          participantWorkflow: updatedWorkflow,
        };
      });
    }
  }

  function checkErrors() {
    const errors = [];
    const notTypedValues = workflow.participantWorkflow.filter(
      ({ selfEvaluation }) => selfEvaluation !== 0 && !selfEvaluation
    );

    if (workflow.participant?.evaluationStage !== "SELF_EVALUATION")
      errors.push({
        text: `Sua avaliação está na etapa: ${currentStage?.label}`,
      });
    if (!workflow.participant?.manager?.id)
      errors.push({ text: "Selecione um Gestor Imediato" });
    if (notTypedValues.length > 0)
      errors.push({ text: "Preencha todos os campos da avaliação" });

    return { errors, haveErrors: errors.length > 0 };
  }

  async function handleSubmit() {
    if (!checkErrors().length > 0) {
      setSavingStatus({ isLoading: true, saved: false });
      const { data: response } = await putParticipantSelfEvaluation({
        selected_tool_id,
        participantWorkflow: workflow.participantWorkflow,
      });
      const { updated: hasUpdated } = response;

      setSavingStatus({
        isLoading: false,
        saved: hasUpdated || false,
        error: !hasUpdated,
      });
    }
  }

  function callConfirmationDialog() {
    setConfirmationDialog({
      open: true,
      onConfirm: () => {
        setConfirmationDialog((current) => {
          return {
            ...current,
            open: false,
          };
        });
        handleSubmit();
      },
      onCancel: () =>
        setConfirmationDialog((current) => {
          return {
            ...current,
            open: false,
          };
        }),
    });
  }

  const { haveErrors, errors } = checkErrors();
  const showLoading = isLoading || savingStatus.isLoading;
  const noParticipantFound = !workflow?.participant?.id && !isLoading;
  const disableSubmit = isLoading || haveErrors || savingStatus.isLoading;

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {showLoading && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
            backgroundColor: "rgba(255,255,255, 0.6)",
            zIndex: 10,
          }}
        >
          <SimpleLoader color="#333" />
        </div>
      )}
      {noParticipantFound && (
        <h3>Não foi possível encontrar a sua autoavaliação</h3>
      )}

      {workflow?.participant?.id && (
        <div className="col-xs-12">
          <h3>Avaliação de Pessoas</h3>
          <div style={{ paddingTop: "30px" }}>
            <div>
              <WorkflowHeader
                showManager={true}
                participant={workflow.participant}
                workflowErrors={[]}
                enableManagerUpdate={false}
                enableSecondEvaluator={false}
                enableThirdEvaluator={false}
                enableSecEvaluatorUpdate={false}
                enableTrdEvaluatorUpdate={false}
                managerConfig={{}}
                loggedUserRole="PARTICIPANT"
                hideAreaAndPosition={hideAreaAndPosition}
              />
              {confirmationDialog.open && (
                <ConfirmationDialog
                  open={confirmationDialog.open}
                  title="Você tem certeza que deseja fazer o envio da sua autoavaliação?"
                  description="Sua avaliação será enviada para o próximo estágio. Não será possível atualizar os valores depois disso, somente com permissão de um Gestor ou Administrador da ferramenta."
                  onConfirm={confirmationDialog.onConfirm}
                  onCancel={confirmationDialog.onCancel}
                />
              )}
            </div>
            {savingStatus.saved && (
              <div className="text-center col-xs-12" style={{}}>
                <h3 className="text-success">
                  Sua avaliação foi enviada com sucesso!
                </h3>
                <p>
                  Em alguns instantes, você receberá uma confirmação de envio no
                  seu email{" "}
                  <b>{workflow.participant.memberParticipant.email}</b>
                  <br />
                  junto com uma cópia da sua autoavaliação.
                </p>
                <p>Você já pode fechar esta página.</p>
              </div>
            )}

            {!savingStatus.saved && (
              <div>
                <div
                  className="col-xs-12"
                  style={{ height: tableHeight, overflowX: "auto" }}
                >
                  <WorkflowReplyTable
                    selectedToolId={selected_tool_id}
                    insight={insight}
                    workflow={workflow.participantWorkflow || []}
                    loggedUser={workflow.participant.memberParticipant}
                    enabledFields={{
                      selfEvaluation: { hide: false, disabled: false },
                    }}
                    handleWorkflowUpdate={handleWorkflowUpdate}
                    scaleOptions={workflow.scaleOptions}
                    blockUpdates={isLoading}
                  />
                </div>
                <div
                  className="col-xs-12"
                  style={{
                    position: "fixed",
                    width: "100%",
                    height: footerHeight,
                    bottom: "30px",
                    left: 0,
                    backgroundColor: "#e9e9e9",
                    borderTop: "1px solid #ccc",
                    borderBottom: "1px solid #ccc",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="col-xs-10"
                    style={{
                      display: "flex",
                      gap: "15px",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      {haveErrors &&
                        `${errors
                          .map(({ text }, index) => `${index + 1}) ${text}`)
                          .join(". ")}`}
                    </div>
                    {savingStatus.error && (
                      <div className="text-danger">
                        Error trying to update the evaluation
                      </div>
                    )}
                  </div>
                  <div
                    className="col-xs-2"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      height: footerHeight,
                    }}
                  >
                    <button
                      className="btn btn-success"
                      disabled={disableSubmit}
                      onClick={callConfirmationDialog}
                    >
                      {!savingStatus.isLoading ? "Enviar" : "Salvando..."}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ParticipantWorkflowTable;
