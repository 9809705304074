import React from "react";
import { connect } from "react-redux";
import InsightMaturity from "./InsightMaturity";
import InsightActionPlan from "../../../Common/ActionPlan/InsightActionPlan";
import { getSelectedToolStates } from "../../../customMapStates";

let ProcessBigPicture = (props) => {
  const { insight = {}, selectedTool = {}, onAddCollaborator } = props;

  return (
    <div className="row">
      <div className="row">
        <InsightMaturity
          insight={insight}
          onAddCollaborator={onAddCollaborator}
        />
      </div>
      <div className="row">
        <InsightActionPlan
          insight={insight}
          selectedTool={selectedTool}
          dragabble
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { selectedTool = {} } = getSelectedToolStates(state);

  return {
    selectedTool,
  };
};

ProcessBigPicture = connect(mapStateToProps)(ProcessBigPicture);

export { ProcessBigPicture };
