import React, { useState } from "react";
import { defineMessages } from "react-intl";
import utils from "../../../../utils/toolUtils";
import ActionsTableLines from "./ActionsTableLines";
import MaterialSingleSelect from "../../MaterialSingleSelect";
import { buildActionsInsightInfo } from "../../../../utils/actionsPlan/filter";
import { getToolCustomLabels } from "../../../../utils/actionsPlan/display";
import { getActionStatusOptions } from "../../../../constants/actionsPlan";
import { useWindowSize } from "../../../../utils/customHooks";

const { translatedText } = utils;

const messages = defineMessages({
  noDataToDisplay: {
    id: "global.noDataTable",
  },
  acp_filter_status_all: {
    id: "acp_filter_status_all",
  },
  acp_filter_status_within_deadline: {
    id: "acp_filter_status_within_deadline",
  },
  acp_filter_status_out: {
    id: "acp_filter_status_out",
  },
});

const translation = (id) => translatedText(id, messages);

const defaultFilter = {
  deadline: "ALL",
  status: "ALL",
};

const ActionPlanInsightsGroup = (props) => {
  const [filter, setFilter] = useState(defaultFilter);
  const windowSize = useWindowSize();
  const screenWidth = windowSize[0];
  const smallScreen = screenWidth <= 1200;

  const {
    actionsPlan = [],
    insights = [],
    insightsTableTitle,
    insightsTableInsightsLabel,
    selectedTool = {},
    selectAndOpenInsight,
  } = props;

  const actionStatusOptions = getActionStatusOptions();

  const { tool = {} } = selectedTool;
  const toolTypeId = tool && tool.id ? tool.id : null;
  const toolCustomLabels = getToolCustomLabels(toolTypeId);

  const handleFilter = ({ name, value }) => {
    if (filter[name] && value) {
      setFilter({
        ...filter,
        [name]: value,
      });
    }
  };

  const filteredActions = buildActionsInsightInfo(
    actionsPlan,
    insights,
    filter,
  );

  return (
    <div className="evaluation-card">
      <div
        className="card-header"
        style={{
          display: "flex",
          height: "40px",
          justifyContent: "space-between",
        }}
      >
        <div className="card-title">
          <h4>{insightsTableTitle}</h4>
        </div>
        <div
          className="card-filter"
          style={{
            width: "300px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label
              style={{
                fontSize: "10px",
                fontWeight: "normal",
                marginRight: "5px",
                whiteSpace: "nowrap",
              }}
            >
              {toolCustomLabels.acp_table_text_time_limit.toUpperCase()}
            </label>
            <MaterialSingleSelect
              id="deadline"
              value={filter.deadline}
              options={[
                {
                  label: translation("acp_filter_status_all"),
                  value: "ALL",
                },
                {
                  label: translation("acp_filter_status_within_deadline"),
                  value: "WITHIN_DEADLINE",
                },
                { label: translation("acp_filter_status_out"), value: "OUT" },
              ]}
              onChange={(e) => handleFilter(e.target)}
              customStyles={{
                height: "20px",
                fontSize: "10px",
                margin: "10px",
              }}
              margin="none"
              defaultOption={false}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label
              style={{
                fontSize: "10px",
                fontWeight: "normal",
                marginRight: "2px",
                marginLeft: "15px",
                whiteSpace: "nowrap",
              }}
            >
              {toolCustomLabels.acp_table_text_status.toUpperCase()}
            </label>
            <MaterialSingleSelect
              id="status"
              value={filter.status}
              options={[
                { label: translation("acp_filter_status_all"), value: "ALL" },
                ...actionStatusOptions,
              ]}
              onChange={(e) => handleFilter(e.target)}
              customStyles={{
                height: "20px",
                fontSize: "10px",
                margin: "10px",
              }}
              margin="none"
              defaultOption={false}
            />
          </div>
        </div>
      </div>
      <div style={{ padding: "20px 15px 20px 15px" }} className="row">
        <div style={{ display: "flex" }} className="col-xs-12">
          <div style={{ width: "100%" }}>
            <table
              className={`simple-table actions-plan-evaluation ${
                smallScreen && "horizontal-scroll"
              }`}
              style={{ marginTop: "0px" }}
            >
              <tbody style={{ backgroundColor: "#eee" }}>
                <tr
                  className="block-hover"
                  style={{
                    backgroundColor: "#eee",
                    fontWeight: "bold",
                    color: "#444",
                  }}
                >
                  <td>{insightsTableInsightsLabel}</td>
                  <td>
                    {toolCustomLabels.acp_table_text_period.toUpperCase()}
                  </td>
                  <td>
                    {toolCustomLabels.acp_table_text_deviation.toUpperCase()}
                  </td>
                  <td>{toolCustomLabels.acp_table_text_cause.toUpperCase()}</td>
                  <td>
                    {toolCustomLabels.acp_table_text_action.toUpperCase()}
                  </td>
                  <td>
                    {toolCustomLabels.acp_table_text_time_limit.toUpperCase()}
                  </td>
                  <td>
                    {toolCustomLabels.acp_table_text_status.toUpperCase()}
                  </td>
                </tr>
                <ActionsTableLines
                  displayActions={filteredActions}
                  hideActions
                  hideIndex
                  showInsight
                  showEmptyCols={false}
                  dragabble={false}
                  selectAndOpenInsight={selectAndOpenInsight}
                />
                {filteredActions.length === 0 && (
                  <tr>
                    <td
                      colSpan="100"
                      style={{
                        backgroundColor: "#fff",
                      }}
                    >
                      <h5 align="center">{translation("noDataToDisplay")}</h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionPlanInsightsGroup;
