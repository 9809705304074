const addError = (errorArray = [], errorType = "", errorData = {}) => {
  let array = [];

  array = [...errorArray, { errorType, ...errorData }];

  return array;
};

const isHeadersValidated = (data = [], staticHeader = {}) => {
  let arrayHeaders = [];
  let validated = true;

  delete staticHeader.custom;

  Object.keys(staticHeader).forEach((header) => {
    arrayHeaders = [...arrayHeaders, header];
  });

  Object.keys(data[0] || []).forEach((header) => {
    if (arrayHeaders.indexOf(header) === -1) validated = false;
  });

  return validated;
};

const customFilter = (array = [], filter = "", compareValue = "") => {
  let indexToUpdate = "";

  let filteredObject =
    array.filter((field, index) => {
      if (field[filter] === compareValue) {
        indexToUpdate = index;

        return true;
      }
    })[0] || {};

  return { filteredObject, indexToUpdate };
};

const getLabeledInfo = (evaluations = [], selectedToolStates = {}) => {
  let labeledSceneries = {};
  let labeledThemes = {};
  let labeledQuestions = {};
  let trackedSceneries = [];

  const {
    allSceneries = {},
    allThemes = {},
    allQuestions = {},
    workspaceName = "",
    companyName = "",
  } = selectedToolStates;

  evaluations.forEach(({ cenaryId }) => {
    const currentScenery =
      allSceneries.filter((scenery) => {
        return scenery.id === cenaryId;
      })[0] || {};

    if (trackedSceneries.indexOf(cenaryId) === -1) {
      labeledSceneries[cenaryId] = currentScenery.name;
      const { newThemes, newQuestions } = getLabeledThemesQuestions(
        allThemes[cenaryId],
        allQuestions,
      );
      labeledThemes = { ...labeledThemes, ...newThemes };
      labeledQuestions = { ...labeledQuestions, ...newQuestions };
      trackedSceneries = [...trackedSceneries, cenaryId];
    }
  });

  return {
    labeledSceneries,
    labeledThemes,
    labeledQuestions,
    workspaceName,
    companyName,
  };
};

const getLabeledThemesQuestions = (allThemes = [], allQuestions = {}) => {
  let newThemes = {};
  let newQuestions = {};

  allThemes.forEach(({ id: themeId, name }) => {
    newThemes[themeId] = name;

    const labeledQuestions = getLabeledQuestions(allQuestions[themeId]);

    newQuestions = { ...newQuestions, ...labeledQuestions };
  });

  return { newThemes, newQuestions };
};

const getLabeledQuestions = (allQuestions = []) => {
  let newQuestions = {};

  allQuestions.forEach(({ id: questionId, text }) => {
    newQuestions[questionId] = text;
  });

  return newQuestions;
};

const getOnlyLines = (data = {}) => {
  let lines = {};

  Object.keys(data).forEach((slug) => {
    lines = data[slug];
  });

  return lines;
};

export {
  addError,
  isHeadersValidated,
  customFilter,
  getLabeledInfo,
  getLabeledThemesQuestions,
  getLabeledQuestions,
  getOnlyLines,
};
