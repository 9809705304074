import axios from "axios";
import { biuldAdmRestBody } from "../utils/okr/entity";
import { putScenery, selectCenary } from "./cenaryActions";
import { putTheme, fetchAllSceneryThemes } from "./themesActions";
import { putQuestion } from "./questionsActions";
import { postInsight } from "./insightActions";
import { fetchAllQuestionAnswers } from "./answersActions";
import {
  UPDATE_CENARY,
  UPDATE_CENARY_THEME,
  UPDATE_THEME_QUESTION,
  UPDATE_QUESTION_ANSWER,
} from "./types";

import {
  FETCH_OKR_CONFIG,
  FETCH_ALL_OKR_CONFIGS,
  GET_ALL_CYCLES_BASES,
  DELETE_OBJECTIVE,
  RESET_OKR,
  PUT_OBJECTIVE,
  PUT_PLAN,
  DELETE_PLAN,
  DELETE_KEY,
  POST_CYCLE_BASE,
  PUT_PATTERN_BASE,
  GET_CYCLE_BASE,
  DELETE_BASE_PATTERN,
} from "../constants/okr";
import {
  grantSceneryAccess,
  grantCreatorAccess,
  grantResponsibleAccess,
} from "./accessControllerActions";

const handleError = (error) => {
  // Error 😨
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    console.log(error.request);
  } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
  }
  console.log(error);
};

const admConfigBody = biuldAdmRestBody({});

export const postCycleBase = (okrConfigurationId, body) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `/api/okr-configurations/${okrConfigurationId}/cyclesBase`,
        body,
      );

      dispatch({ type: POST_CYCLE_BASE, payload: response.data });
    } catch (e) {
      handleError(e);
    }
  };
};

export const postPatterns = async (cyclesBaseId, body) => {
  try {
    await axios.post(
      `/api/cycles-base-patterns/${cyclesBaseId}/addPattern`,
      body,
    );
  } catch (e) {
    handleError(e);
  }
};

export const createPatternCycle = async (cycleBody, patternId) => {
  const response = await axios.post(
    `/api/cycles-base-patterns-cycles/${patternId}/addCycle`,
    cycleBody,
  );

  return response.data;
};

export const deleteBasePattern = (patternId) => {
  return async (dispatch) => {
    try {
      await axios.delete(`/api/cycles-base-patterns/${patternId}`);

      dispatch({ type: DELETE_BASE_PATTERN, payload: patternId });
    } catch (e) {
      handleError(e);
    }
  };
};

export const putPatterns = (body, patternBody) => {
  return async (dispatch) => {
    try {
      const resPattern = await axios.put(
        "/api/cycles-base-patterns",
        patternBody,
      );

      const resPatternCyles = await axios.put(
        `/api/cycles-base-pattern-cycles/${patternBody.id}/updateListOfCycles`,
        body,
      );

      dispatch({
        type: PUT_PATTERN_BASE,
        payload: {
          patternCycles: resPatternCyles.data,
          pattern: resPattern.data,
          patternId: patternBody.id,
        },
      });
    } catch (e) {
      handleError(e);
    }
  };
};

export const getCyclesBase = (okrConfigurationId) => {
  return async (dispatch) => {
    try {
      if (okrConfigurationId) {
        const response = await axios.get(
          `/api/okr-configurations/${okrConfigurationId}/cyclesBase`,
        );

        dispatch({ type: GET_CYCLE_BASE, payload: response.data });
      }
    } catch (e) {
      handleError(e);
    }
  };
};

export const getAllCyclesBases = (okrConfigurationIds) => {
  const send = (payload = {}) => {
    return { type: GET_ALL_CYCLES_BASES, payload };
  };

  return async (dispatch) => {
    await axios
      .get(`/api/okr-configurations/cyclesBases/${okrConfigurationIds}`)
      .then((res) => dispatch(send(res.data)));
  };
};

export const deleteCycleBasePatternCycle = (patternCycleId) => {
  try {
    if (patternCycleId) {
      axios.delete(`/api/cycles-base-pattern-cycles/${patternCycleId}`);
    }
  } catch (e) {
    handleError(e);
  }
};

export const getOkrConfiguration = async (selectedToolId) => {
  return axios
    .get(`/api/selected-tools/${selectedToolId}/okrConfiguration`)
    .then((res) => res.data)
    .catch((e) => handleError(e));
};

export const getOkrConfigurations = async (selectedToolIds = []) => {
  return axios
    .get(`/api/selected-tools/okrConfigurations/${selectedToolIds}`)
    .then((res) => res.data)
    .catch((e) => handleError(e));
};

export const postOkrConfiguration = (selectedToolId, body) => {
  return axios
    .post(`/api/selected-tools/${selectedToolId}/okrConfiguration`, body)
    .then(() => getOkrConfiguration(selectedToolId))
    .catch((e) => handleError(e));
};

export const fetchOkrConfigurations = async (selectedToolIds = []) => {
  const getOkrConfigurationIds = (okrConfigurations = {}) => {
    const okrConfigIds = [];

    Object.keys(okrConfigurations).forEach((selectedToolId) => {
      const { id: currentConfigId = null } = okrConfigurations[selectedToolId];

      if (!currentConfigId) return;

      okrConfigIds.push(currentConfigId);
    });

    return okrConfigIds;
  };

  return async (dispatch) => {
    const resOkrConfig = await getOkrConfigurations(selectedToolIds);

    const okrConfigurationIds = getOkrConfigurationIds(resOkrConfig);

    dispatch({ type: FETCH_ALL_OKR_CONFIGS, payload: resOkrConfig });
    dispatch(getAllCyclesBases(okrConfigurationIds));
  };
};

export const fetchOkrConfiguration = async (selectedToolId) => {
  return async (dispatch) => {
    dispatch({ type: RESET_OKR, payload: {} });

    if (selectedToolId) {
      const resOkrConfig = await getOkrConfiguration(selectedToolId);

      if (resOkrConfig && resOkrConfig.id) {
        if (resOkrConfig.id) dispatch(getCyclesBase(resOkrConfig.id));
        dispatch({ type: FETCH_OKR_CONFIG, payload: resOkrConfig });
      } else {
        const postOkrConfig = await postOkrConfiguration(
          selectedToolId,
          admConfigBody,
        );

        if (postOkrConfig) {
          dispatch({ type: FETCH_OKR_CONFIG, payload: postOkrConfig });
          if (postOkrConfig.id) dispatch(getCyclesBase(postOkrConfig.id));
        }
      }
    }
  };
};

export const postPlan = (okrConfigurationId, body, next) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `/api/okr-configurations/${okrConfigurationId}/addPlan`,
        body,
      );

      if (next) {
        next(response.data);
      }
    } catch (error) {
      handleError(error);
    }
  };
};

export const deletePlan = (planId) => {
  return (dispatch) => {
    axios.delete(`/api/okr-configuration-plans/${planId}`);

    dispatch({ type: DELETE_PLAN, payload: planId });
  };
};

export const deleteCycle = async (cicleId) => {
  await axios.delete(`/api/okr-configuration-cycle/${cicleId}`);
};

export const putPlan = async (body) => {
  return async (dispatch) => {
    try {
      const response = await axios.put("/api/okr-configuration-plans", body);

      dispatch({ type: PUT_PLAN, payload: response.data });
    } catch (e) {
      handleError(e);
    }
  };
};

export const postObjective = async (okrConfigurationId, body, next) => {
  try {
    const response = await axios.post(
      `/api/okr-configurations/${okrConfigurationId}/addObjective`,
      body,
    );

    if (next) {
      next(response.data);
    }
  } catch (e) {
    handleError(e);
  }
};

export const deleteObjective = (objectiveId) => {
  return async (dispatch) => {
    try {
      await axios.delete(`/api/okr-configuration-objectives/${objectiveId}`);

      dispatch({ type: DELETE_OBJECTIVE, payload: objectiveId });
    } catch (e) {
      handleError(e);
    }
  };
};

export const putObjective = async (body) => {
  try {
    if (body && body.id) {
      await axios.put("/api/okr-configuration-objectives", body);
    }
  } catch (e) {
    handleError(e);
  }

  return (dispatch) => {
    dispatch({ type: PUT_OBJECTIVE, payload: body });
  };
};

export const postKey = async (okrConfigurationObjetiveId, body, next) => {
  if (body && okrConfigurationObjetiveId) {
    try {
      const response = await axios.post(
        `/api/okr-configuration-objectives/${okrConfigurationObjetiveId}/addKey`,
        body,
      );

      if (next) {
        next(response.data);
      }
    } catch (e) {
      handleError(e);
    }
  }
};

export const putKey = async (objectiveId, bodyKey) => {
  await axios.post(
    `/api/okr-configuration-key/changeObjective/${objectiveId}`,
    bodyKey,
  );
};

export const deleteKey = (keyId, objectiveId) => {
  return async (dispatch) => {
    try {
      await axios.delete(`/api/okr-configuration-key/${keyId}`);

      dispatch({ type: DELETE_KEY, payload: { keyId, objectiveId } });
    } catch (e) {
      handleError(e);
    }
  };
};

export const createPlanCenary = (
  selectedTool,
  planTitle,
  owner = {},
  order = 0,
) => {
  return (dispatch) =>
    dispatch(
      putScenery(
        {
          id: null,
          name: planTitle,
          fixed: false,
          selectedTool,
          editing: true,
          type: "cenary",
          owner,
          order,
        },
        selectedTool.id,
      ),
    );
};

export const postSceneryPlan = (sceneryId, planId) => {
  return axios
    .post(`/api/okr-configurations/cenary/${sceneryId}/plan/${planId}`)
    .then((res) => res);
};

export const dispatchCenary = (newScenery, next) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CENARY,
      payload: { ...newScenery, isNew: true },
    });

    if (next) next(newScenery);
  };
};

export const createSceneriePlan = (
  selectedTool = {},
  planInfo = {},
  userProfile,
  order = 0,
  next,
) => {
  return async (dispatch) => {
    if (selectedTool.id && planInfo.id) {
      try {
        const newScenery = await dispatch(
          createPlanCenary(
            selectedTool,
            planInfo.organization.title,
            userProfile,
            order,
          ),
        );

        if (newScenery.data.id) {
          const postedLink = await postSceneryPlan(
            newScenery.data.id,
            planInfo.id,
          );

          if (next) {
            next(newScenery);
          }

          if (postedLink.status === 204 || postedLink.status === 200) {
            dispatch(selectCenary(newScenery.data.id));
            dispatch(grantSceneryAccess(null, "LEADER", "EDIT"));
            dispatchCenary(newScenery.data);
          }
        }
      } catch (err) {
        //console.log("err", err);
      }
    }
  };
};

export const postThemeCycle = (themeId, cycleId) => {
  return axios
    .post(`/api/okr-configurations/theme/${themeId}/cycle/${cycleId}`)
    .then((res) => res);
};

export const createTheme = (cenaryBody, cycleTitle) => {
  return putTheme({
    id: null,
    type: "theme",
    cenary: cenaryBody,
    name: cycleTitle,
  });
};

export const dispatchTheme = (cenaryId, newTheme) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CENARY_THEME,
      payload: {
        cenaryId,
        theme: {
          ...newTheme,
          isNew: true,
        },
      },
    });
  };
};

export const createCycleTheme = (cenaryBody = {}, cycleInfo = {}) => {
  return async (dispatch) => {
    if (cenaryBody.id && cycleInfo.id) {
      try {
        const newTheme = await createTheme(cenaryBody, cycleInfo.title);

        if (newTheme.data.id && newTheme.status === 204) {
          await postThemeCycle(newTheme.data.id, cycleInfo.id);

          if (cenaryBody.id) {
            dispatch(dispatchTheme(cenaryBody.id, newTheme.data));
            dispatch(fetchAllSceneryThemes([cenaryBody.id]));
          }
        }
      } catch (err) {
        //console.log("err", err);
      }
    }
  };
};

export const postQuestionObjective = (questionId, objectiveId) => {
  return axios
    .post(
      `/api/okr-configurations/question/${questionId}/objective/${objectiveId}`,
    )
    .then((res) => res);
};

export const createQuestion = (themeBody, objectiveTitle, order) => {
  return putQuestion({
    id: null,
    type: "question",
    theme: themeBody,
    text: objectiveTitle,
    order,
  });
};

export const dispatchQuestion = (themeId, newQuestion) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_THEME_QUESTION,
      payload: {
        themeId,
        question: {
          ...newQuestion,
          isNew: true,
        },
      },
    });
  };
};

export const createObjectiveQuestion = (
  objectiveInfo = {},
  themeBody = {},
  order = 0,
) => {
  return async (dispatch) => {
    if (themeBody.id && objectiveInfo.id) {
      try {
        const newQuestion = await createQuestion(
          themeBody,
          objectiveInfo.title,
          order,
        );
        const newQuestionData = newQuestion.data;

        if (newQuestionData.id) {
          const postedLink = await postQuestionObjective(
            newQuestionData.id,
            objectiveInfo.id,
          );

          if (postedLink.status === 204 || postedLink.status === 200) {
            const updatedQuestionData = {
              ...newQuestionData,
              okrConfigurationObjectiveId: objectiveInfo.id,
            };

            dispatch(dispatchQuestion(themeBody.id, updatedQuestionData));
          }
        }
      } catch (err) {
        //console.log("err", err);
      }
    }
  };
};

export const postInsightKey = (insightId, keyId) => {
  return axios
    .post(`/api/okr-configurations/insight/${insightId}/key/${keyId}`)
    .then((res) => res);
};

export const createInsight = (keyTitle, questionId, order) => {
  return postInsight(
    {
      id: null,
      text: keyTitle,
      name: keyTitle,
      type: "insight",
      order,
    },
    questionId,
  );
};

export const dispatchInsight = (questionId, newInsight) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_QUESTION_ANSWER,
      payload: {
        questionId,
        answer: { ...newInsight, isNew: true },
      },
    });
  };
};

export const createKeyInsight = (keyInfo, questionId, order) => {
  return async (dispatch) => {
    if (questionId && keyInfo.id) {
      const copyKeyInfo = { ...keyInfo };

      const newInsight = await createInsight(keyInfo.title, questionId, order);

      if (newInsight.data.id) {
        await postInsightKey(newInsight.data.id, copyKeyInfo.id);
        if (questionId) await dispatch(fetchAllQuestionAnswers([questionId]));

        await dispatch(dispatchInsight(questionId, newInsight.data));
        await dispatch(grantCreatorAccess(null, newInsight.data.id));
        await dispatch(grantResponsibleAccess(null, newInsight.data.id));
      }
    }
  };
};
