import moment from "moment";
import React from "react";
import _ from "lodash";
import { Popover, Row } from "react-bootstrap";

import { defineMessages } from "react-intl";
import { getSlugifyArray } from "./ArrayUtils";
import {
  getProjectActivities,
  getPlanTotalValues,
  compareDates,
  getOverallTotalValues,
  getProjectStatus,
  getProjectData,
  getScopeStatus,
  getFinancialStatus,
  getFinancialExceeded,
  getFinancialBalance,
  translateProjectStage,
  getSchedulePercentage,
} from "./projectEvaluation";
import MultipleLineChart from "../components/Tool/ToolTabs/CustomFields/ProjectManagement/tabs/ReportMap/Utils/MultipleLineChart";
import BarChart from "../components/Tool/ToolTabs/CustomFields/ProjectManagement/tabs/ReportMap/Utils/BarChart";
import { ruleOfThree } from "./MathUtils";

const messages = defineMessages({
  deliveryDate: {
    id: "tool.project.deliveryDate",
  },
  realizedPlannedActivities: {
    id: "tool.project.plannedRealizedActivities",
  },
  budget: {
    id: "tool.project.budget",
  },
  spent: {
    id: "tool.project.spent",
  },
  physicalAdvance: {
    id: "tool.project.physical_progress",
  },
  financialAdvance: {
    id: "tool.project.financial_progress",
  },
  effortAdvance: {
    id: "tool.project.effort_progress",
  },
  replannedProject: {
    id: "tool.project.replannedProject",
  },
  newBudget: {
    id: "tool.project.new_budget",
  },
  projectStatus: {
    id: "global.projectStatus",
  },
  in: {
    id: "tool.project.in",
  },
  out: {
    id: "tool.project.out",
  },
  next: {
    id: "tool.project.next",
  },
  scopeView: {
    id: "tool.project.scopeView",
  },
  informations: {
    id: "global.informations",
  },
  statusReport: {
    id: "global.statusReport",
  },
  noData: {
    id: "global.noData",
  },
  scope: {
    id: "tool.project.scope",
  },
  delivery: {
    id: "tool.project.delivery",
  },
  cost: {
    id: "global.cost",
  },
  status: {
    id: "global.status",
  },
});

const checkPeriodIndexInCourse = (
  filterMonth,
  filterYear,
  period = { start: null, end: null }
) => {
  const course = getTotalGoalCourseIndexByFrequency(period);

  if (
    filterMonth !== null &&
    course.start.year !== null &&
    course.end.year !== null
  ) {
    if (
      filterMonth >= 0 &&
      course.start.year >= 0 &&
      course.end.year >= 0 &&
      course.start.month >= 0 &&
      course.end.month >= 0
    ) {
      if (filterYear >= course.start.year && filterYear <= course.end.year) {
        if (
          filterYear === course.start.year &&
          filterYear === course.end.year
        ) {
          if (course.start.month === course.end.month) {
            if (filterMonth === course.start.month) {
              return true;
            }
          } else if (
            filterMonth >= course.start.month &&
            filterMonth <= course.end.month
          ) {
            return true;
          }
        } else if (filterYear === course.start.year) {
          if (filterMonth >= course.start.month && filterMonth <= 12) {
            return true;
          }
        } else if (filterYear === course.end.year) {
          if (filterMonth <= course.end.month) {
            return true;
          }
        } else {
          return true;
        }
      }
    }
  }

  return false;
};

const getTotalGoalCourseIndexByFrequency = (
  dates = { year: null, start: null, end: null }
) => {
  const momentStart = moment(dates.start);
  const momentEnd = moment(dates.end);

  if (momentStart.isValid() && momentEnd.isValid()) {
    return {
      start: { year: momentStart.year(), month: momentStart.month() },
      end: { year: momentEnd.year(), month: momentEnd.month() },
    };
  }

  return { start: null, end: null };
};

export { checkPeriodIndexInCourse };

export const getProjectEvaluationsByTheme = (
  allProjectEvaluation = [],
  themeIDs = [],
  allAnswers = {},
  questionsIDs = []
) => {
  const themeEvaluations = [];

  if (!allProjectEvaluation || allProjectEvaluation.length === 0) return [];

  allProjectEvaluation.forEach((evaluation) => {
    const inside = themeIDs.indexOf(evaluation.themeId) > -1;

    if (inside) {
      const questionAnswers = allAnswers[evaluation.questionId] || [];

      const answer =
        questionAnswers.filter(
          (answer) =>
            Number(answer.id) === Number(evaluation.insightId) &&
            questionsIDs?.includes(evaluation.questionId)
        )[0] || null;

      if (!answer) return;

      themeEvaluations.push({
        ...evaluation,
        projectEvaluation: {
          ...evaluation.projectEvaluation,
          projectName: answer.text,
          avatar: answer.responsibleMember?.userData.avatarBlobId,
          responsibleName: answer.responsibleMember?.userData.fullName,
          answer,
        },
      });
    }
  });

  return themeEvaluations;
};

export const getManagementData = (filteredEvaluations = [], intl) => {
  let kanbanMap = {};
  let reportMap = [];
  let ganttMap = [];
  const buildedEvaluations = filteredEvaluations;

  const transformedEvaluations = getSlugifyArray(
    [
      ...(filteredEvaluations || []).map(
        (evaluationInfo) => evaluationInfo.projectEvaluation
      ),
    ],
    "projectName",
    "(",
    ")",
    false
  );

  transformedEvaluations.forEach((projectEvaluation, index) => {
    ganttMap = [...ganttMap, ...ganttMapData(projectEvaluation)];
    reportMap = [...reportMap, reportMapData(projectEvaluation, index, intl)];
  });

  kanbanMap = kanbanMapData(filteredEvaluations, intl);

  return { kanbanMap, reportMap, ganttMap, buildedEvaluations };
};

export const getPopoverLineChart = (projectEvaluation, intl) => {
  const { financialAdvance = [] } = projectEvaluation || {};

  return (
    <Popover
      id="popoverInformation"
      title={intl.formatMessage(messages.financialAdvance)}
    >
      <div className="chartDiv">
        <MultipleLineChart
          modalDisplay={false}
          lineChartData={financialAdvance}
        />
      </div>
    </Popover>
  );
};

export const getPopoverBarChart = (projectEvaluation, intl) => {
  const { physicalAdvance = [] } = projectEvaluation || {};

  return (
    <Popover
      id="popoverInformation"
      title={intl.formatMessage(messages.physicalAdvance)}
    >
      <div className="chartDiv">
        <BarChart barChartData={physicalAdvance} />
      </div>
    </Popover>
  );
};

export const getStatusQuoPopover = (intl) => {
  return (
    <Popover
      id="popoverInformation"
      title={intl && intl.formatMessage(messages.status)}
    >
      <div>
        <div>
          <i className="fas fa-list" />{" "}
          {intl && intl.formatMessage(messages.scope)}
        </div>
        <div>
          <i className="fas fa-clock" />{" "}
          {intl && intl.formatMessage(messages.delivery)}
        </div>
        <div>
          <i className="fas fa-dollar-sign" style={{ fontSize: "17px" }} />{" "}
          {intl && intl.formatMessage(messages.cost)}
        </div>
      </div>
    </Popover>
  );
};

export const getStatusReportPopover = (statusReport = "", intl) => {
  const noData = intl.formatMessage(messages.noData);
  let report = statusReport || noData;

  if (report.length === 0) report = noData;

  return (
    <Popover
      id="popoverInformation"
      title={intl && intl.formatMessage(messages.statusReport)}
    >
      <div>{report}</div>
    </Popover>
  );
};

export const getPopoverByProject = (evaluation, project, intl) => {
  const {
    deadline,
    baseline,
    conclusionDate,
    physicalProgress,
    financialBudget,
    newFinancialBudget,
    financialSpent,
    scopeView,
  } = evaluation ? mountObj(evaluation, intl) : project;

  return (
    <Popover
      id="popoverInformation"
      title={intl.formatMessage(messages.informations)}
    >
      <div className="scheduleInformation" style={{ marginBottom: "20px" }}>
        <Row style={{ marginBottom: "10px" }}>
          <div>
            <span style={{ fontWeight: "500" }}>Baseline: </span>
            {baseline || "-"}
          </div>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <div>
            <span style={{ fontWeight: "500" }}>Deadline:</span>{" "}
            {deadline || "-"}
          </div>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <div>
            <span style={{ fontWeight: "500" }}>
              {intl.formatMessage(messages.deliveryDate)}:
            </span>{" "}
            {conclusionDate || "-"}
          </div>
        </Row>
      </div>
      <div className="financialInformation" style={{ marginBottom: "20px" }}>
        <Row style={{ marginBottom: "10px" }}>
          <div>
            <span style={{ fontWeight: "500" }}>
              {intl.formatMessage(messages.budget)}:
            </span>{" "}
            {financialBudget
              ? `$${financialBudget
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`
              : "-"}
          </div>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <div>
            <span style={{ fontWeight: "500" }}>
              {intl.formatMessage(messages.newBudget)}:
            </span>{" "}
            {newFinancialBudget
              ? `$${newFinancialBudget
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`
              : "-"}
          </div>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <div>
            <span style={{ fontWeight: "500" }}>
              {intl.formatMessage(messages.spent)}:
            </span>{" "}
            {financialSpent
              ? `$${financialSpent
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`
              : "-"}
          </div>
        </Row>
      </div>
      <div className="physicalAdvance">
        <Row style={{ marginBottom: "10px" }}>
          <div>
            <span style={{ fontWeight: "500" }}>
              {intl.formatMessage(messages.physicalAdvance)}:
            </span>{" "}
            {physicalProgress ? `${physicalProgress}%` : "-"}
          </div>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <div>
            <span style={{ fontWeight: "500" }}>
              {intl.formatMessage(messages.scopeView)}:
            </span>{" "}
            {scopeView}
          </div>
        </Row>
      </div>
    </Popover>
  );
};

export const getFinancialPopover = (intl = {}) => {
  return (
    <Popover id="popoverInformation" title="">
      <Row>{intl.formatMessage(messages.financialAdvance)}</Row>
    </Popover>
  );
};

export const getPhysicalPopover = (intl = {}) => {
  return (
    <Popover id="popoverInformation" title="">
      <Row>{intl.formatMessage(messages.physicalAdvance)}</Row>
    </Popover>
  );
};

export const getStatusPopover = (intl = {}) => {
  return (
    <Popover
      id="popoverInformation"
      title={intl.formatMessage(messages.projectStatus)}
    >
      <Row>
        <span className="dot greenDot" /> {intl.formatMessage(messages.in)}
        <span className="dot redDot" style={{ marginLeft: "10px" }} />{" "}
        {intl.formatMessage(messages.out)}
        <span className="dot yellowDot" style={{ marginLeft: "10px" }} />{" "}
        {intl.formatMessage(messages.next)}
      </Row>
    </Popover>
  );
};

export const getPhysicalPercentagePopover = (intl = {}, value = 0) => {
  const actualValue = value || 0;

  return (
    <Popover id="popoverInformation" title="">
      <Row>
        {intl.formatMessage(messages.physicalAdvance)}{" "}
        <span>{`${actualValue}%`}</span>
      </Row>
    </Popover>
  );
};

const customToolTip = (physicalProgress) => {
  return `<div class = "speech-bubble"">${physicalProgress}%</div>`;
};

export const ganttMapData = (projectEvaluation) => {
  const projectName =
    projectEvaluation && projectEvaluation.slug
      ? projectEvaluation.slug.toUpperCase()
      : "";

  const initialDate = projectEvaluation.initialDate
    ? projectEvaluation.initialDate
    : null;

  const reschedules = projectEvaluation.reschedules
    ? projectEvaluation.reschedules
    : [];

  const baselineObj = reschedules ? getFirstReschedule(reschedules) : null;

  const baseline =
    baselineObj && baselineObj.deliveryDate ? baselineObj.deliveryDate : null;

  if (initialDate && baseline) {
    const finalDate =
      projectEvaluation && projectEvaluation.finalDate
        ? projectEvaluation.finalDate
        : null;

    const deadline = finalDate || baseline;

    const initialMonth = moment(initialDate).format("M") - 1;
    const baselineMonth = moment(baseline).format("M") - 1;
    const deadlineMonth = moment(deadline).format("M") - 1;

    const formattedDate = new Date(
      moment(initialDate).format("YYYY"),
      initialMonth,
      moment(initialDate).format("DD")
    );

    let formattedBaseline = new Date(
      moment(baseline).format("YYYY"),
      baselineMonth,
      moment(baseline).format("DD")
    );

    let formattedDeadline = new Date(
      moment(deadline).format("YYYY"),
      deadlineMonth,
      moment(deadline).format("DD")
    );

    let formattedPhyisicalDeadline = formattedDate;

    if (formattedDate > formattedBaseline) {
      formattedBaseline = formattedDate;
    }

    if (formattedBaseline > formattedDeadline) {
      formattedDeadline = formattedBaseline;
    }

    const physicalProgress = projectEvaluation.physicalProgress
      ? projectEvaluation.physicalProgress
      : null;

    if (physicalProgress) {
      const daysDiff = moment(deadline).diff(initialDate, "days");
      const physicalMonthsDiff = Number(daysDiff * (physicalProgress / 100));

      const physicalDeadline = moment(initialDate)
        .add(physicalMonthsDiff, "day")
        .format("YYYY-MM-DD");

      const physicalDeadlineMonth = moment(physicalDeadline).format("M") - 1;

      formattedPhyisicalDeadline = new Date(
        moment(physicalDeadline).format("YYYY"),
        physicalDeadlineMonth,
        moment(physicalDeadline).format("DD")
      );

      return [
        [projectName, " ", null, formattedDate, formattedBaseline],
        [projectName, "  ", null, formattedBaseline, formattedDeadline],
        [
          projectName,
          "   ",
          customToolTip(physicalProgress),
          formattedDate,
          formattedPhyisicalDeadline,
        ],
      ];
    }
    return [
      [projectName, " ", null, formattedDate, formattedBaseline],
      [projectName, "  ", null, formattedBaseline, formattedDeadline],
    ];
  }
  return [];
};

const getFirstReplan = (replannings) => {
  const currentReplannings = replannings || [];
  const shortestDate = getCustomDateTime(replannings, false);

  const firstPlan = currentReplannings.filter(
    (replan) => replan.dateTime === shortestDate
  );

  return firstPlan;
};

export const getPatternDateFormat = (date = null, format = "ll") => {
  if (date && moment(date).isValid()) return moment(date).format(format);

  return false;
};

export const getRealDeadline = (reschedules = []) => {
  const currentReschedules = Array.isArray(reschedules)
    ? reschedules.filter(({ inTrash }) => inTrash !== true)
    : [];

  const realDeadline = getCustomDeliveryDate(currentReschedules, true);

  return realDeadline;
};

const mountObj = (evaluation, intl) => {
  const { projectEvaluation = null } = evaluation || {};
  const {
    id = "",
    projectStageRelation = {},
    projectStage = false,
    answer = {},
  } = projectEvaluation || {};

  const { responsibleMember = {} } = answer || {};

  const replannings =
    projectEvaluation &&
    projectEvaluation.projectInvestment &&
    projectEvaluation.projectInvestment.replannings &&
    projectEvaluation.projectInvestment.replannings.length > 0
      ? projectEvaluation.projectInvestment.replannings
      : null;

  const replannedProject = !!(replannings && replannings.length > 1);

  const currentPlannings =
    projectEvaluation &&
    projectEvaluation.projectInvestment &&
    projectEvaluation.projectInvestment.plannings &&
    projectEvaluation.projectInvestment.plannings.length > 0
      ? projectEvaluation.projectInvestment.plannings
      : null;

  let financialBudget = null;
  let firstReplan = null;
  let newFinancialBudget = null;
  let currentTotalInvestments = null;
  let firstTotalInvestments = null;

  if (replannedProject) {
    firstReplan = replannings ? getFirstReplan(replannings) : null;

    currentTotalInvestments = currentPlannings
      ? getOverallTotalValues(currentPlannings)
      : null;

    newFinancialBudget =
      currentTotalInvestments && currentTotalInvestments.totalBudget
        ? currentTotalInvestments.totalBudget
        : null;

    firstTotalInvestments = firstReplan
      ? getOverallTotalValues(firstReplan)
      : null;
  } else {
    firstTotalInvestments = currentPlannings
      ? getOverallTotalValues(currentPlannings)
      : null;
  }

  financialBudget =
    firstTotalInvestments && firstTotalInvestments.totalBudget
      ? firstTotalInvestments.totalBudget
      : null;

  const financialSpent = replannedProject
    ? currentTotalInvestments &&
      currentTotalInvestments.totalSpent &&
      currentTotalInvestments.totalSpent
    : !replannedProject
    ? firstTotalInvestments &&
      firstTotalInvestments.totalSpent &&
      firstTotalInvestments.totalSpent
    : null;

  const physicalProgress =
    projectEvaluation && projectEvaluation.physicalProgress
      ? projectEvaluation.physicalProgress
      : 0;

  const reschedules =
    projectEvaluation &&
    projectEvaluation.reschedules &&
    projectEvaluation.reschedules.length > 0
      ? projectEvaluation.reschedules
      : null;

  const replanned = reschedules
    ? reschedules.filter(({ inTrash }) => inTrash !== true).length > 1
    : null;

  const currentReschedules = Array.isArray(reschedules)
    ? reschedules.filter(({ inTrash }) => inTrash !== true)
    : [];

  const baselineObj = reschedules
    ? getFirstReschedule(currentReschedules)
    : null;

  const baseline =
    baselineObj && baselineObj.deliveryDate ? baselineObj.deliveryDate : null;

  const finalDate =
    projectEvaluation && projectEvaluation.finalDate
      ? projectEvaluation.finalDate
      : null;

  const customDeadline = getRealDeadline(reschedules);
  const deadLine = customDeadline || finalDate;

  const conclusionDate =
    projectEvaluation && projectEvaluation.projectConclusion
      ? projectEvaluation.projectConclusion
      : null;

  const formattedBaselineDate = getPatternDateFormat(baseline);
  let formattedDeadlineDate = getPatternDateFormat(deadLine);
  const formattedConclusionDate = getPatternDateFormat(conclusionDate);

  if (formattedBaselineDate === formattedDeadlineDate) {
    formattedDeadlineDate = null;
  }

  if (newFinancialBudget === financialSpent) {
    newFinancialBudget = null;
  }

  const { label: scopeView = null } = getScopeStatus(projectEvaluation) || {};

  return {
    avatar: projectEvaluation.avatar,
    responsibleName: projectEvaluation.responsibleName,
    responsible: responsibleMember,
    projectName:
      projectEvaluation && projectEvaluation.projectName
        ? projectEvaluation.projectName
        : "",
    activities: getProjectActivities(projectEvaluation.activities),
    projectStatus: getProjectStatus(projectEvaluation),
    deadline: formattedDeadlineDate || null,
    baseline: formattedBaselineDate || null,
    conclusionDate: formattedConclusionDate || null,
    physicalProgress,
    financialBudget,
    newFinancialBudget,
    financialSpent,
    replanned,
    scopeView,
    projectStage,
    projectStageRelation,
    id,
  };
};

export const getStageParentInfoById = (
  projectStageId = false,
  projectConfiguration = {}
) => {
  if (!projectStageId) return {};

  const { stages = [] } = projectConfiguration;

  const projectStageRelation =
    stages.filter((stage) => stage.id === projectStageId)[0] || {};

  const { parent: dad = false } = projectStageRelation || false;

  const son = dad ? projectStageRelation : false;
  const parent = son ? dad : projectStageRelation;

  const { description: parentDescription = false } = parent || {};
  const { description: sonDescription = false } = son || {};

  return {
    son,
    parent,
    sonDescription,
    parentDescription,
  };
};

export const getStageParentInfo = (projectStageRelation = false) => {
  const { parent: dad = false } = projectStageRelation || false;

  const son = dad ? projectStageRelation : false;
  const parent = son ? dad : projectStageRelation;

  const { description: parentDescription = false } = parent || {};
  const { description: sonDescription = false } = son || {};

  return {
    son,
    parent,
    sonDescription,
    parentDescription,
  };
};

export const kanbanMapData = (filteredEvaluations, intl) => {
  const kanbanData = {
    PLANEJAMENTO: [],
    ANDAMENTO: [],
    CONCLUIDO: [],
    STANDBY: [],
    CANCELADO: [],
  };

  function pushEvaluation(slug, goalInfo) {
    kanbanData[slug].push(goalInfo);
  }

  function findQuestionEqual(slug, questionId) {
    return kanbanData[slug].some(
      (evaluation) => evaluation.questionId === questionId
    );
  }

  function includeInsightInQuestion(slug, answer, data) {
    const { question } = answer;
    const selectedEvaluation = kanbanData[slug];

    kanbanData[slug] = selectedEvaluation.map((evaluation) => {
      if (evaluation.questionId === question.id) {
        return {
          ...evaluation,
          evaluations: [
            ...evaluation.evaluations,
            { ...data, answerId: answer.id },
          ],
        };
      }
      return { ...evaluation, answerId: answer.id };
    });
  }

  function mountEvaluationByStatus(status, data, answer) {
    const { question = {} } = answer || {};
    const { id: questionId = 0 } = question || {};

    if (findQuestionEqual(status, questionId)) {
      includeInsightInQuestion(status, answer, data);
    } else {
      pushEvaluation(status, {
        questionId,
        questionText: answer.question.text,
        evaluations: [{ ...data, answerId: answer.id }],
      });
    }
  }

  filteredEvaluations.forEach((evaluation) => {
    const { projectEvaluation } = evaluation;
    const {
      projectStage,
      answer,
      projectStageRelation = {},
    } = projectEvaluation;

    const validProjectEvaluation =
      evaluation && projectEvaluation ? { ...projectEvaluation } : {};

    const { parentDescription = false, sonDescription = false } =
      getStageParentInfo(projectStageRelation);

    const stageRelation = projectStage || parentDescription || sonDescription;

    const currentStage =
      validProjectEvaluation && stageRelation ? { ...stageRelation } : null;

    const cardData = currentStage ? mountObj(evaluation, intl) : null;

    if (stageRelation) {
      switch (stageRelation) {
        case "CONCLUIDO":
          mountEvaluationByStatus("CONCLUIDO", cardData, answer);
          break;
        case "PLANEJAMENTO":
          mountEvaluationByStatus("PLANEJAMENTO", cardData, answer);
          break;
        case "ANDAMENTO":
          mountEvaluationByStatus("ANDAMENTO", cardData, answer);
          break;
        case "STANDBY":
          mountEvaluationByStatus("STANDBY", cardData, answer);
          break;
        case "CANCELADO":
          mountEvaluationByStatus("CANCELADO", cardData, answer);
          break;
        default:
      }
    }
  });

  return kanbanData;
};

const getCustomDateTime = (replannings = null, getLast = true) => {
  const currentReplannings = replannings || [];
  let biggestDate = currentReplannings[0]
    ? currentReplannings[0].dateTime
    : null;

  currentReplannings.forEach((plan) => {
    const date = plan.dateTime ? plan.dateTime : null;
    const isValid = date ? moment(date).isValid() : null;
    const isAfter = isValid ? compareDates(date, biggestDate) : null;
    biggestDate =
      getLast && isAfter ? date : !getLast && isAfter ? biggestDate : date;
  });

  return biggestDate;
};

export const getCustomDeliveryDate = (reschedules = null, getLast = true) => {
  const currentReschedules = reschedules || [];
  let biggestDate = currentReschedules[0]
    ? currentReschedules[0].deliveryDate
    : null;

  const filteredByCurrentDeadline = currentReschedules.filter(
    ({ currentDeadline }) => currentDeadline
  );

  const list =
    filteredByCurrentDeadline?.length > 0
      ? filteredByCurrentDeadline
      : currentReschedules;

  list.forEach((plan) => {
    const date = plan.deliveryDate ? plan.deliveryDate : null;
    const isValid = date ? moment(date).isValid() : null;
    const isAfter = isValid ? compareDates(date, biggestDate) : null;

    biggestDate =
      getLast && isAfter ? date : !getLast && !isAfter ? date : biggestDate;
  });

  return biggestDate;
};

// const getLastReschedule = (reschedules = []) => {
//   const biggestDate = getCustomDeliveryDate(reschedules, true);

//   const lastReschedule = reschedules.filter(
//     reschedule => reschedule.deliveryDate === biggestDate
//   );

//   return lastReschedule[0];
// };

const getFirstReschedule = (reschedules = []) => {
  const shortestDate = getCustomDeliveryDate(reschedules, false);

  const firstReschedule = reschedules.filter(
    (reschedule) => reschedule.deliveryDate === shortestDate
  );

  return firstReschedule[0];
};

export const getLabeledSchedule = (projectEvaluation, deadline) => {
  const reschedules = projectEvaluation.reschedules
    ? projectEvaluation.reschedules
    : [];

  const initialDate =
    projectEvaluation && projectEvaluation.initialDate
      ? projectEvaluation.initialDate
      : null;

  let baselineDate = reschedules
    ? getFirstReschedule(reschedules.filter(({ inTrash }) => inTrash !== true))
    : null;

  baselineDate =
    baselineDate && baselineDate.deliveryDate
      ? baselineDate.deliveryDate
      : null;

  const baseline = baselineDate
    ? getPatternDateFormat(baselineDate, "L")
    : null;

  let formattedDeadline = deadline ? getPatternDateFormat(deadline, "L") : null;

  const conclusionDate =
    projectEvaluation && projectEvaluation.projectConclusion
      ? projectEvaluation.projectConclusion
      : null;

  const realized = conclusionDate
    ? getPatternDateFormat(conclusionDate, "L")
    : null;

  if (deadline === baselineDate) {
    formattedDeadline = null;
  }

  return {
    baseline,
    deadline: formattedDeadline,
    unformattedBaseline: baselineDate,
    unformattedDeadline: deadline,
    unformattedRealized: conclusionDate,
    initialDate,
    realized,
  };
};

export const getTotalProgress = (object, isBaseline) => {
  let planned = 0;
  let realized = 0;

  const replannings = object && object.replannings ? object.replannings : [];
  const deadlinePlans = object && object.plannings ? object.plannings : null;

  const plans =
    isBaseline && replannings.length > 0
      ? getFirstReplan(replannings)
      : deadlinePlans;

  if (plans) {
    plans.forEach((plan) => {
      const totalValues = getPlanTotalValues(plan);

      const currentTotalSpent =
        totalValues && totalValues.totalSpentHours
          ? totalValues.totalSpentHours
          : totalValues.totalSpent
          ? totalValues.totalSpent
          : 0;

      planned += Number(totalValues.totalBudget);
      realized += Number(currentTotalSpent);
    });
  }

  return { planned, realized };
};

const getReplannedInvestment = (investment = {}, lastReplan = "") => {
  const { planned: deadlineTotal } = getTotalProgress(investment, false);
  const { planned: baselineTotal } = getTotalProgress(investment, true);

  const replanned = deadlineTotal - baselineTotal;

  return replanned;
};

const getLabeledEffort = (effort) => {
  const { planned, realized } = getTotalProgress(effort, false);

  return { planned, realized };
};

const getLabeledInvestment = (investment) => {
  const { planned, realized } = getTotalProgress(investment, false);

  const replanned = getReplannedInvestment(investment, planned);

  return { planned, realized, replanned };
};

export const getDotClassByStatus = (projectStatus = "") => {
  const upper = projectStatus?.toUpperCase();
  if (upper === "DENTRO") return "greenDot";
  if (upper === "FORA") return "redDot";
  if (upper === "PROXIMO") return "yellowDot";

  return "greyDot";
};

export const getColorClassByStatus = (projectStatus = "") => {
  if (projectStatus === "DENTRO") return "greenbackground";
  if (projectStatus === "FORA") return "redbackground";
  if (projectStatus === "PROXIMO") return "yellowbackground";

  return "";
};

const mountFirstLevel = (
  projectEvaluation = {},
  index = "",
  deadline = "",
  intl
) => {
  const { projectStatus = "" } = getProjectData({ projectEvaluation });
  const statusClass = getDotClassByStatus(projectStatus) || "greyDot";

  const reschedules = projectEvaluation.reschedules
    ? projectEvaluation.reschedules
    : [];

  const replanned =
    reschedules.filter(({ inTrash }) => inTrash !== true).length > 1;

  const { dot: scopeDotClass = "greyDot" } =
    getScopeStatus(projectEvaluation) || {};

  const {
    projectInvestment = {},
    statusReport = "",
    projectStage: stage = "",
  } = projectEvaluation || {};

  const { dot: financialStatusDot = "greyDot" } =
    getFinancialStatus(projectInvestment) || {};

  const exceeded = getFinancialExceeded(projectInvestment);
  const balance = getFinancialBalance(projectInvestment);

  return {
    scopeDotClass,
    projectId: index,
    projectName:
      projectEvaluation && projectEvaluation.slug
        ? projectEvaluation.slug
        : projectEvaluation.projectName,
    physicalProgress: projectEvaluation.physicalProgress,
    replanned,
    activities: getProjectActivities(projectEvaluation.activities),
    schedule: getLabeledSchedule(projectEvaluation, deadline),
    effort: getLabeledEffort(projectEvaluation.projectEffort),
    investment: getLabeledInvestment(projectEvaluation.projectInvestment),
    statusClass,
    financialStatusDot,
    exceeded,
    balance,
    statusReport,
    projectStage: translateProjectStage(stage, intl),
  };
};

const getPlannings = (projectEvaluation) => {
  const plannings =
    projectEvaluation &&
    projectEvaluation.projectInvestment &&
    projectEvaluation.projectInvestment.plannings
      ? projectEvaluation.projectInvestment.plannings
      : null;

  return plannings;
};

const getPlanningsByRealDeadline = (plannings = [], realDeadline = "") => {
  let updatedPlannings = _.cloneDeep(plannings);
  const realDeadlineYear = moment(realDeadline).format("YYYY");
  const monthIndex = moment(realDeadline).format("M") - 1;
  let sameYear = null;

  updatedPlannings = updatedPlannings.filter(
    (plan) => plan.year <= realDeadlineYear
  );

  updatedPlannings.forEach((plan) => {
    sameYear = false;
    const monthEvaluations =
      plan && plan.monthEvaluations ? plan.monthEvaluations : {};

    delete monthEvaluations.id;
    if (Number(plan.year) === Number(realDeadlineYear)) sameYear = true;

    Object.keys(monthEvaluations).forEach((month, index) => {
      if (sameYear && index > monthIndex) {
        delete monthEvaluations[month];
      }
    });
  });

  return updatedPlannings;
};
const mountFinancialAdvance = (projectEvaluation, deadline) => {
  let financialAdvance = [[new Date(2019, 1), 0, 0]];

  const plannings = getPlannings(projectEvaluation);

  plannings.sort((a, b) => {
    const a_attr = a.year;
    const b_attr = b.year;

    return a_attr > b_attr ? 1 : b_attr > a_attr ? -1 : 0;
  });

  const initialDate = projectEvaluation.initialDate
    ? projectEvaluation.initialDate
    : null;

  const monthsDiff =
    initialDate && deadline
      ? moment(deadline).diff(initialDate, "months")
      : null;

  const chartDisplayPlannings = monthsDiff
    ? getPlanningsByRealDeadline(plannings, deadline)
    : [];

  let accumulatedSpent = 0;
  let accumulatedBudget = 0;

  let currentIndex = 0;
  financialAdvance = [];

  chartDisplayPlannings.forEach((plan) => {
    const monthEvaluations =
      plan && plan.monthEvaluations ? plan.monthEvaluations : {};

    delete monthEvaluations.id;

    Object.keys(monthEvaluations).forEach((month) => {
      if (monthEvaluations[month]) {
        const budget = monthEvaluations[month].budget
          ? monthEvaluations[month].budget
          : 0;

        const spent = monthEvaluations[month].spent
          ? monthEvaluations[month].spent
          : 0;

        const axisLegend = moment(initialDate)
          .add(currentIndex, "month")
          .format("YYYY-MM-DD");

        const monthValue = moment(axisLegend).format("M") - 1;
        const yearValue = moment(axisLegend).format("YYYY");

        const formattedAxis = new Date(yearValue, monthValue);

        accumulatedSpent += spent;
        accumulatedBudget += budget;
        financialAdvance = [
          ...financialAdvance,
          [formattedAxis, accumulatedSpent, accumulatedBudget],
        ];

        currentIndex++;
      }
    });
  });

  if (financialAdvance.length === 0) {
    financialAdvance = [[new Date(2019, 1), 0, 0]];
  }

  return financialAdvance;
};

const getAdvanceByRealDeadline = (physicalAdvance, deadline) => {
  const updatedPhysicalAdvance = _.cloneDeep(physicalAdvance);
  const realDeadlineYear = moment(deadline).format("YYYY");
  const monthIndex = moment(deadline).format("M");
  let sameYear = null;
  let deleteIndex = false;

  updatedPhysicalAdvance.forEach((advance, index) => {
    sameYear = false;
    const year = advance.year || 0;
    const months = advance && advance.months ? advance.months : [];
    if (Number(year) === Number(realDeadlineYear)) sameYear = true;
    else if (Number(year) > Number(realDeadlineYear)) deleteIndex = true;

    if (sameYear) {
      months.forEach((monthObj, index) => {
        const currentMonth = monthObj && monthObj.month ? monthObj.month : 1;
        if (sameYear && currentMonth > monthIndex) {
          delete months[index];
        }
      });
      months.sort((a, b) => {
        const a_attr = a.month;
        const b_attr = b.month;

        return a_attr > b_attr ? 1 : b_attr > a_attr ? -1 : 0;
      });
    } else if (deleteIndex) {
      delete updatedPhysicalAdvance[index];
    }
  });

  updatedPhysicalAdvance.sort((a, b) => {
    const a_attr = a.year;
    const b_attr = b.year;

    return a_attr > b_attr ? 1 : b_attr > a_attr ? -1 : 0;
  });

  return updatedPhysicalAdvance;
};

const mountPhysicalAdvance = (projectEvaluation, deadline) => {
  let projectPhysicalAdvance = [];

  const physicalAdvance = projectEvaluation.physicalProgressEvolutions || [];

  const updatedPhysicalAdvance = getAdvanceByRealDeadline(
    physicalAdvance,
    deadline
  );

  updatedPhysicalAdvance.forEach((advance) => {
    const year = advance.year || 0;
    const months = advance && advance.months ? advance.months : [];

    months.forEach((monthObj) => {
      const currentMonth = monthObj && monthObj.month ? monthObj.month - 1 : 1;
      const currentValue = monthObj && monthObj.value ? monthObj.value : 0;
      const leftToThousand = 100 - Number(currentValue);
      projectPhysicalAdvance = [
        ...projectPhysicalAdvance,
        [new Date(year, currentMonth), currentValue, leftToThousand],
      ];
    });
  });

  return projectPhysicalAdvance;
};

export const reportMapData = (projectEvaluation, index, intl) => {
  const reschedules =
    projectEvaluation &&
    projectEvaluation.reschedules &&
    projectEvaluation.reschedules.length > 0
      ? projectEvaluation.reschedules
      : null;

  const finalDate = projectEvaluation.finalDate
    ? projectEvaluation.finalDate
    : null;

  const customDeadline = getRealDeadline(reschedules);
  const deadline = customDeadline || finalDate;

  return {
    ...mountFirstLevel(projectEvaluation, index, deadline, intl),
    financialAdvance: [
      ["x", "Spent", "Budget"],
      ...mountFinancialAdvance(projectEvaluation, finalDate),
    ],
    physicalAdvance: [
      [" ", "Advance", "Left"],
      ...mountPhysicalAdvance(projectEvaluation, finalDate),
    ],
    evaluation: { projectEvaluation },
  };
};

const getPeopleInvolved = (projectEvaluation = {}) => {
  const { peopleInvolved = {} } = projectEvaluation || {};

  const {
    responsibleMember = {},
    executantMember = {},
    involved = [],
  } = peopleInvolved || {};

  const { id: responsibleId = null } = responsibleMember || {};
  const { id: executantId = null } = executantMember || {};

  const involvedIds = involved.map((data) => {
    const { memberParticipant = null } = data;
    if (memberParticipant) return memberParticipant.id;
    return null;
  });

  return { responsibleId, executantId, involvedIds };
};

export const getMilestonesResponsiblesIds = (projectEvaluation = {}) => {
  const { activities = [] } = projectEvaluation || {};

  return activities.map((activity) => {
    const { responsible = {} } = activity || {};
    const { id: responsibleId = null } = responsible || {};

    return responsibleId;
  });
};

export const projectToolUsers = (newBody = {}) => {
  const addLabeledUser = (id = null, role = "") => {
    if (id) labeledUsers = [...labeledUsers, { id, role }];
  };

  let labeledUsers = [];

  const activitiesResponsiblesIds = getMilestonesResponsiblesIds(newBody);

  activitiesResponsiblesIds.forEach((id) => {
    if (!id) return;

    addLabeledUser(id, "COLLABORATOR");
  });

  const {
    responsibleId = null,
    executantId = null,
    involvedIds = [],
  } = getPeopleInvolved(newBody);

  addLabeledUser(responsibleId, "RESPONSIBLE");
  addLabeledUser(executantId, "RESPONSIBLE");

  involvedIds.forEach((id) => {
    addLabeledUser(id, "COLLABORATOR");
  });

  return labeledUsers;
};

export const alreadyAdded = (substages = [], name = "") => {
  return (
    substages.filter(({ substageName }) => substageName === name.toUpperCase())
      .length > 0
  );
};

export const getKanbanSubstages = (kanbanMap = {}) => {
  const substages = [];

  Object.keys(kanbanMap).forEach((stage) => {
    kanbanMap[stage].forEach(({ evaluations = [] }) => {
      evaluations.forEach((evaluation) => {
        const { id = "", name = false } = essentialInfo(evaluation);

        if (!name || alreadyAdded(substages, name)) return;

        substages.push({
          id,
          substageName: name.toUpperCase(),
        });
      });
    });
  });

  return substages;
};

export const essentialInfo = ({
  projectStageRelation = {},
  projectStage = false,
}) => {
  const { description = false, parent = {} } = projectStageRelation || {};

  const { description: parentName = false } = parent || {};

  const name = description || projectStage || false;

  if (!name) return { name, id: "" };

  const upperParentName = parentName ? parentName.toUpperCase() : "";

  const id = `${name.toUpperCase()}[separator]${upperParentName}`;

  return { name, id };
};

export const getReportResponsibles = (reportMap = []) => {
  const responsibles = [];

  reportMap.forEach(({ evaluation = {} }) => {
    const { projectEvaluation = {} } = evaluation || {};
    const { answer = {} } = projectEvaluation || {};
    const { responsibleMember = {} } = answer || {};
    const { id = false, name = "" } = responsibleMember || {};

    if (responsibles.filter((resp) => resp.id === id).length > 0 || !id) return;

    responsibles.push({ id, responsibleName: name.toUpperCase() });
  });

  return responsibles;
};

export const getActivitiesResponsibles = (reportMap = []) => {
  const responsibles = [];

  reportMap.forEach(({ evaluation = {} }) => {
    const { projectEvaluation = {} } = evaluation || {};
    const { answer = {} } = projectEvaluation || {};
    const { responsibleMember = {} } = answer || {};
    const { id = false, name = "" } = responsibleMember || {};

    if (responsibles.filter((resp) => resp.id === id).length > 0 || !id) return;

    responsibles.push({ id, responsibleName: name.toUpperCase() });
  });

  return responsibles;
};

export const getSubstages = (reportMap = []) => {
  const substages = [];

  reportMap.forEach(({ evaluation = {} }) => {
    const { projectEvaluation = {} } = evaluation || {};
    const { id = "", name = false } = essentialInfo(projectEvaluation);

    if (!name || alreadyAdded(substages, name)) return;

    substages.push({
      id,
      substageName: name.toUpperCase(),
    });
  });

  return substages;
};

export const getKanbanResponsibles = (kanbanMap = {}) => {
  const responsibles = [];

  Object.keys(kanbanMap).forEach((stage) => {
    kanbanMap[stage].forEach((line) => {
      const { evaluations = [] } = line;

      evaluations.forEach((evaluation) => {
        const { responsible = {} } = evaluation || {};
        const { id = false, name = "" } = responsible || {};

        if (responsibles.filter((resp) => resp.id === id).length > 0 || !id)
          return;

        responsibles.push({ id, responsibleName: name.toUpperCase() });
      });
    });
  });

  return responsibles;
};

export const getDefaultScopes = () => {
  return ["according", "no_impact", "impact", "no_scope"];
};

export const getDefaultPercentages = (rate = 1) => {
  let value = 0;
  const valueArray = [];
  const numberRate = Number(rate);

  while (value <= 100) {
    valueArray.push(`${value}%`);
    value += numberRate;
  }

  return valueArray;
};

export const fakeMacroOverviewData = () => {
  return {
    totalProjects: 18,
    general: [
      { name: "PLANEJAMENTO", value: 2, width: "10px" },
      { name: "ANDAMENTO", value: 35, width: "100px" }, //MAX 100px calcular equivalentes,
      { name: "CONCLUIDO", value: 4, width: "20px" },
      { name: "STANDBY", value: 2, width: "10px" },
      { name: "CANCELADO", value: 1, width: "5px" },
    ],
    responsible: [
      {
        name: "Roberto Galvão Pereira Da Silva Filho",
        projects: 2,
        percentage: 11,
        width: "10px",
      },
      { name: "Maria Braga", projects: 4, percentage: 22, width: "20px" },
      { name: "Luis Eduardo", projects: 5, percentage: 28, width: "25px" },
      { name: "Carlos Alberto", projects: 2, percentage: 11, width: "10px" },
      { name: "Silvia Canela", projects: 9, percentage: 17, width: "80px" },
      { name: "Ana Clara", projects: 2, percentage: 11, width: "10px" },
      { name: "Roberto Galvão", projects: 2, percentage: 11, width: "10px" },
      { name: "Maria Braga", projects: 4, percentage: 22, width: "20px" },
      { name: "Luis Eduardo", projects: 5, percentage: 28, width: "25px" },
      { name: "Carlos Alberto", projects: 2, percentage: 11, width: "10px" },
      { name: "Silvia Canela", projects: 9, percentage: 17, width: "80px" },
      { name: "Ana Clara", projects: 2, percentage: 11, width: "10px" },
    ],
    physicalSchedule: [
      {
        name: "Projeto de Industrialização da passagem da ponte por baixo do trem",
        physical: 120,
        schedule: 110,
        physicalWidth: "100px",
        scheduleWidth: "100px",
      },
      {
        name: "Projeto 2",
        physical: 20,
        schedule: 30,
        physicalWidth: "20px",
        scheduleWidth: "30px",
      },
      {
        name: "Projeto 4",
        physical: 10,
        schedule: 90,
        physicalWidth: "10px",
        scheduleWidth: "90px",
      },
      {
        name: "Projeto 5",
        physical: 25,
        schedule: 65,
        physicalWidth: "25px",
        scheduleWidth: "65px",
      },
      {
        name: "Projeto 6",
        physical: 80,
        schedule: 50,
        physicalWidth: "80px",
        scheduleWidth: "50px",
      },
      {
        name: "Projeto 7",
        physical: 10,
        schedule: 10,
        physicalWidth: "10px",
        scheduleWidth: "10px",
      },
      {
        name: "Projeto 8",
        physical: 40,
        schedule: 40,
        physicalWidth: "40px",
        scheduleWidth: "40px",
      },
      {
        name: "Projeto 8",
        physical: 40,
        schedule: 40,
        physicalWidth: "40px",
        scheduleWidth: "40px",
      },
      {
        name: "Projeto 8",
        physical: 40,
        schedule: 40,
        physicalWidth: "40px",
        scheduleWidth: "40px",
      },
      {
        name: "Projeto 8",
        physical: 40,
        schedule: 40,
        physicalWidth: "40px",
        scheduleWidth: "40px",
      },
      {
        name: "Projeto 8",
        physical: 40,
        schedule: 40,
        physicalWidth: "40px",
        scheduleWidth: "40px",
      },
    ],
    quality: {
      replannedProjects: 112,
      impactReplanned: "60%",
      accordingReplanned: "40%",
      scopeAdherence: {
        impact: { color: "#f54e4e", value: 15 },
        no_scope: { color: "grey", value: 3 },
        according: { color: "#0c9247", value: 6 },
        no_impact: { color: "#ffc107", value: 4 },
      },
    },
    deadline: {
      ongoing: {
        value: 9,
        in: 4,
        inPercentage: "40%",
        outPercentage: "60%",
        out: 5,
      },
      concluded: {
        value: 4,
        inPercentage: "20%",
        outPercentage: "80%",
        in: 1,
        out: 3,
      },
    },
    cost: {
      totalPlanned: 800000,
      totalSpent: 1200000,
      totalPercentage: "250%",
      aboveCost: 5,
      aboveCostPercentage: "80%",
      belowCost: 13,
      belowCostPercentage: "20%",
    },
  };
};

export const getEvaluationStageParentInfo = (projectEvaluation = {}) => {
  const { projectStageRelation = {}, projectStage = false } = projectEvaluation;

  const { parentDescription = false, sonDescription = false } =
    getStageParentInfo(projectStageRelation);

  const stage = projectStage || parentDescription || sonDescription;

  return {
    stage,
    son: sonDescription,
    parent: parentDescription,
  };
};

export const widthGenerator = (value, multiplier = 2, max = 100) => {
  if (value > max || value * multiplier > max) return `${max}px`;

  return `${value * multiplier}px`;
};

const getEnTranslated = (value = "") => {
  if (typeof value !== "string" || !value) return "";

  if (value.toLowerCase() === "andamento") return "ongoing";
  if (value.toLowerCase() === "concluido") return "concluded";
  if (value.toLowerCase() === "fora") return "out";
  if (value.toLowerCase() === "dentro") return "in";

  return "";
};

const getTotalInvestment = (projectEvaluation = {}) => {
  let totalSpent = 0;
  let totalBudget = 0;

  const { projectInvestment = {} } = projectEvaluation;
  const { plannings = [] } = projectInvestment;

  plannings.forEach(({ monthEvaluations = [] }) => {
    Object.keys(monthEvaluations).forEach((month) => {
      const { spent = 0, budget = 0 } = monthEvaluations[month] || {};

      totalSpent += spent;
      totalBudget += budget;
    });
  });

  return { totalSpent, totalBudget };
};

export const handleCostData = ({ cost = {}, evaluation = {} }) => {
  const { projectEvaluation = {} } = evaluation;

  const { totalSpent = 0, totalBudget = 0 } =
    getTotalInvestment(projectEvaluation);

  cost.totalPlanned += totalBudget;
  cost.totalSpent += totalSpent;

  if (totalSpent > totalBudget) return (cost.aboveCost += 1);

  cost.belowCost += 1;
};
export const handleDeadlineData = ({ deadline = {}, evaluation = {} }) => {
  const { projectEvaluation = {} } = evaluation;
  const { stage, parent } = getEvaluationStageParentInfo(projectEvaluation);

  const parentStage = parent || stage;

  if (!parentStage) return;

  const realStage = getEnTranslated(parent || stage);

  const defaultStages = ["ongoing", "concluded"];

  const status = getProjectStatus(projectEvaluation) || "";

  const projectStatus = getEnTranslated(status);

  const defaultStatus = ["in", "out"];

  if (
    defaultStages.indexOf(realStage) === -1 ||
    defaultStatus.indexOf(projectStatus) === -1
  )
    return;

  const currentValue = deadline[realStage].value;

  deadline[realStage] = {
    ...deadline[realStage],
    value: currentValue + 1,
  };

  const currentCount = deadline[realStage][projectStatus] || 0;

  deadline[realStage][projectStatus] = currentCount + 1;
};

export const handleQualityData = ({
  quality = {},
  evaluation = {},
  trackingStates = {},
}) => {
  const { projectEvaluation = {} } = evaluation;
  const { reschedules = {}, scopeView = false } = projectEvaluation;

  const isReplanned = reschedules.length > 1;

  if (isReplanned) quality.replannedProjects += 1;
  if (!isReplanned) trackingStates.notReplanned += 1;

  const scope = scopeView ? scopeView.toLowerCase() : "no_scope";

  const { value: currentValue = 0 } = quality.scopeAdherence[scope];

  quality.scopeAdherence[scope] = {
    ...quality.scopeAdherence[scope],
    value: currentValue + 1,
  };
};

export const handlePSData = ({ evaluation = {} }) => {
  const { projectEvaluation = {}, insightId = false } = evaluation;
  const { answer = {} } = projectEvaluation;
  const { responsibleMember = {} } = answer;
  const {
    finalDate = [],
    reschedules = [],
    projectName: name = "",
    physicalProgress: physical = 0,
  } = projectEvaluation;

  const reschedulesArray = reschedules.length > 0 ? reschedules : null;
  const customDeadline = getRealDeadline(reschedulesArray);
  const deadline = customDeadline || finalDate;
  const scheduleInfo = getLabeledSchedule(projectEvaluation, deadline);

  const schedule = getSchedulePercentage(scheduleInfo);

  const scheduleValidator = (schedule = 0) => {
    if (schedule < 0 || schedule === null) return 0;

    return schedule;
  };
  const physicalValidator = (physical = 0) => {
    if (physical < 0 || physical === null) return 0;

    return physical;
  };

  const realSchedule = scheduleValidator(schedule);
  const realPhysical = physicalValidator(physical);

  return {
    responsibleId: responsibleMember?.id,
    name,
    insightId,
    physical: realPhysical,
    schedule: realSchedule,
    physicalWidth: widthGenerator(realPhysical, 1, 100),
    scheduleWidth: widthGenerator(realSchedule, 1, 100),
  };
};

export const handleResponsibleData = ({
  evaluation = {},
  responsible = {},
  trackingStates = {},
}) => {
  const { projectEvaluation = {} } = evaluation || {};
  const { answer = {} } = projectEvaluation || {};
  const { responsibleMember = {} } = answer || {};
  const { id: responsibleId = false } = responsibleMember || {};

  if (!responsibleId) return;
  const { id = "", name = "" } = responsibleMember;

  const currentResponsible = responsible[id] || false;

  trackingStates.responsiblesBiggerValue += 1;

  if (!currentResponsible) {
    responsible[id] = {
      name,
      projects: 1,
    };

    return;
  }

  const { projects = 0 } = currentResponsible;

  const newValue = projects + 1;

  responsible[id] = {
    ...currentResponsible,
    projects: newValue,
  };
};

export const handleGeneralData = ({
  general = [],
  evaluation = {},
  trackingStates = {},
}) => {
  const { projectEvaluation = {} } = evaluation;
  const { stage, parent } = getEvaluationStageParentInfo(projectEvaluation);

  const realStage = parent || stage;

  const generalUpdate = (obj = {}) => {
    obj.value += 1;
    trackingStates.projectsTotal += 1;
  };

  const updateTree = {
    STANDBY: general[3],
    ANDAMENTO: general[1],
    CONCLUIDO: general[2],
    CANCELADO: general[4],
    PLANEJAMENTO: general[0],
  };

  if (!updateTree[realStage]) return;

  generalUpdate(updateTree[realStage]);
};

export const roundRuleOfThree = (base = 0, compareValue = 0) => {
  return Math.round(ruleOfThree(base, compareValue));
};

export const handleGeneralWidths = ({ general = [], trackingStates = {} }) => {
  const { projectsTotal = 0 } = trackingStates;

  const generalCopy = general;

  generalCopy.forEach((line, index) => {
    general[index] = {
      ...line,
      width: `${roundRuleOfThree(projectsTotal, line.value)}%`,
    };
  });
};

export const handleCostPercentages = ({ cost = {} }) => {
  const {
    aboveCost = 0,
    belowCost = 0,
    totalPlanned = 0,
    totalSpent = 0,
  } = cost;

  const maxCost = aboveCost + belowCost;

  cost.aboveCostPercentage = `${roundRuleOfThree(maxCost, aboveCost)}%`;
  cost.belowCostPercentage = `${roundRuleOfThree(maxCost, belowCost)}%`;

  cost.totalPercentage = roundRuleOfThree(totalPlanned, totalSpent);
};

export const handleQualityPercentages = ({
  quality = {},
  trackingStates = {},
}) => {
  const { notReplanned = 0, projectsTotal = 0 } = trackingStates;
  const { replannedProjects = 0 } = quality;

  quality.replanned = `${roundRuleOfThree(projectsTotal, replannedProjects)}%`;
  quality.notReplanned = `${roundRuleOfThree(projectsTotal, notReplanned)}%`;
};

export const handleDeadlinePercentages = ({ deadline = {} }) => {
  const { ongoing = {}, concluded = {} } = deadline;
  const {
    in: ongoingIn = 0,
    out: ongoingOut = 0,
    value: ongoingMax = 0,
  } = ongoing;
  const {
    in: concludedIn = 0,
    out: concludedOut = 0,
    value: concludedMax = 0,
  } = concluded;

  deadline.ongoing.inPercentage = `${roundRuleOfThree(ongoingMax, ongoingIn)}%`;
  deadline.ongoing.outPercentage = `${roundRuleOfThree(
    ongoingMax,
    ongoingOut
  )}%`;
  deadline.concluded.inPercentage = `${roundRuleOfThree(
    concludedMax,
    concludedIn
  )}%`;
  deadline.concluded.outPercentage = `${roundRuleOfThree(
    concludedMax,
    concludedOut
  )}%`;
};

export const handleResponsiblePercentages = ({
  responsible = {},
  totalProjectsUnfiltered,
  physicalSchedule = [],
}) => {
  const responsiblesCopy = responsible;
  const sumScheduleByOwnerId = {};

  physicalSchedule.forEach(({ responsibleId, schedule }) => {
    if (sumScheduleByOwnerId[responsibleId]) {
      sumScheduleByOwnerId[responsibleId] += schedule;
    } else {
      sumScheduleByOwnerId[responsibleId] = schedule;
    }
  });

  Object.keys(responsiblesCopy).forEach((name) => {
    const { projects = 0 } = responsiblesCopy[name];
    if (sumScheduleByOwnerId[name]) {
      sumScheduleByOwnerId[name] /= projects;
    }
  });

  return Object.keys(responsiblesCopy).map((name) => {
    const { projects = 0 } = responsiblesCopy[name];
    const physicalAdvance = projects / totalProjectsUnfiltered;
    const physicalAdvancePercent = Math.round(physicalAdvance * 100);
    const scheduleAdvance = sumScheduleByOwnerId[name] / projects;
    const scheduleAdvancePercent = Math.round(sumScheduleByOwnerId[name]) || 0;

    return {
      ...(responsiblesCopy[name] || {}),
      physicalAdvance,
      width: `${physicalAdvancePercent}%`,
      totalSchedule: scheduleAdvance || 0,
      scheduleAdvance: scheduleAdvancePercent || 0,
    };
  });
};

export const getMacroOverviewData = (allProjectEvaluation = [], builded) => {
  const totalProjects = allProjectEvaluation.length;
  const totalProjectsUnfiltered = builded.length;

  const general = [
    { name: "PLANEJAMENTO", value: 0, width: "0px" },
    { name: "ANDAMENTO", value: 0, width: "0px" }, //MAX 100px calcular equivalentes,
    { name: "CONCLUIDO", value: 0, width: "0px" },
    { name: "STANDBY", value: 0, width: "0px" },
    { name: "CANCELADO", value: 0, width: "0px" },
  ];
  const responsible = {};
  const physicalSchedule = [];
  const quality = {
    replannedProjects: 0,
    impactReplanned: "0%",
    accordingReplanned: "0%",
    scopeAdherence: {
      impact: { color: "#f54e4e", value: 0 },
      no_scope: { color: "grey", value: 0 },
      according: { color: "#0c9247", value: 0 },
      no_impact: { color: "#ffc107", value: 0 },
    },
  };

  const deadline = {
    ongoing: {
      in: 0,
      out: 0,
      value: 0,
      inPercentage: "0%",
      outPercentage: "0%",
    },
    concluded: {
      in: 0,
      out: 0,
      value: 0,
      inPercentage: "0%",
      outPercentage: "0%",
    },
  };

  const cost = {
    totalPlanned: 0,
    totalSpent: 0,
    totalPercentage: "0%",
    aboveCost: 0,
    aboveCostPercentage: "0%",
    belowCost: 0,
    belowCostPercentage: "0%",
  };

  // Tracking States
  const trackingStates = {
    notReplanned: 0,
    projectsTotal: 0,
    responsiblesBiggerValue: 0,
  };

  const props = {
    cost,
    general,
    quality,
    deadline,
    responsible,
    trackingStates,
    physicalSchedule,
    totalProjects,
    totalProjectsUnfiltered,
  };

  allProjectEvaluation.forEach((evaluation) => {
    handleCostData({ ...props, evaluation });
    handleQualityData({ ...props, evaluation });
    handleGeneralData({ ...props, evaluation });
    handleDeadlineData({ ...props, evaluation });
    handleResponsibleData({ ...props, evaluation });
    physicalSchedule.push({ ...handlePSData({ ...props, evaluation }) });
  });

  handleGeneralWidths(props);
  handleCostPercentages(props);
  handleQualityPercentages(props);
  handleDeadlinePercentages(props);
  const responsibleArray = handleResponsiblePercentages(props);

  return {
    cost,
    quality,
    general,
    deadline,
    responsible: responsibleArray,
    totalProjects,
    physicalSchedule,
  };
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  result.forEach((activity, index) => (activity.indexOrder = index));

  return result;
};

export const getItemStyle = (isDragging, draggableStyle) => {
  delete draggableStyle.position;

  return {
    userSelect: "none",
    height: "65px",
    background: isDragging ? "#eeeeee" : "white",
    ...draggableStyle,
  };
};

export const getListStyle = (isDraggingOver) => ({
  background: "white",
  padding: 8,
});

export const formatDate = (date) => {
  if (date && moment(date).isValid()) return moment(date).format("L");

  return "-";
};

export const defaultConfirmDialog = {
  title: "",
  onConfirm: null,
  onCancel: null,
  open: false,
  params: {},
};

export const defaultModalError = {
  errorMessage: "",
  errorType: "",
  error: false,
};

export const defaultAutomations = {
  effort: false,
  weight: false,
};
