const pt = {
  no_comments: "Sem comentários para mostrar",
  bread_comment: "Comentou em",
};

const en = {
  no_comments: "No comments to show",
  bread_comment: "Commented on",
};

const es = {
  no_comments: "No hay comentarios para mostrar",
  bread_comment: "Comentado",
};

const translations = { pt, en, es };

export default translations;
