import { getObjectInfoById } from "../ArrayUtils";
import { getIndicatorInfoByInsightId } from "../kpis/filter";
import { getActionPerformance } from "./calc";

export const getActionsPlanById = (actionPlanId = 0, allActionsPlans = []) => {
  const searchActionPlan = getObjectInfoById(
    actionPlanId,
    allActionsPlans,
    "actionPlanId",
  );

  return searchActionPlan.actionPlanId ? searchActionPlan.actionPlan : {};
};

export const getFilteredActionsPlanByInsights = (
  insightIds = [],
  allActionsPlans = [],
) => {
  const finalActionsPlan = [];

  if (Array.isArray(allActionsPlans)) {
    allActionsPlans.forEach((actionPlanInfo) => {
      if (insightIds.indexOf(actionPlanInfo.insightId) > -1)
        finalActionsPlan.push(actionPlanInfo);
    });
  }

  return finalActionsPlan;
};

export const getTotalActionsByInsights = (
  insightIds = [],
  allActionsPlans = [],
) => {
  const filtered = [];
  const ids = [];

  const filteredActionsPlan = getFilteredActionsPlanByInsights(
    insightIds,
    allActionsPlans,
  );

  function add(action) {
    if (action && ids.indexOf(action.id) === -1) {
      filtered.push(action);
      ids.push(action.id);
    }
  }

  if (Array.isArray(filteredActionsPlan)) {
    filteredActionsPlan.forEach((actionPlanInfo) => {
      const { actionPlan = {} } = actionPlanInfo;
      const { actions = [], relatedActionPlans } = actionPlan || {};

      if (Array.isArray(actions))
        actions.forEach((actionInfo) => {
          add(actionInfo);
        });

      if (Array.isArray(relatedActionPlans))
        relatedActionPlans.forEach((info) => {
          const { actions } = info || {};

          if (Array.isArray(actions))
            actions.forEach((actionInfo) => {
              add(actionInfo);
            });
        });
    });
  }

  return filtered;
};

export const getDefaultEvaluationBadgeConfig = (from) => {
  return {
    hideBadge: ["cenary"].indexOf(from) > -1,
    hideTitle: ["question", "answer", "score", "overview"].indexOf(from) > -1,
  };
};

export const getAllKpiIndicatorsActions = (
  actionsPlan,
  indicators = {},
  filter = { status: "ALL", deadline: "ALL" },
) => {
  const allActions = [];

  actionsPlan.forEach((actionPlanInfo) => {
    const { insightId, actionPlan = {} } = actionPlanInfo;
    const actions = actionPlan?.actions || [];

    const indicatorInfo = getIndicatorInfoByInsightId(insightId, indicators);

    actions.forEach((actionInfo) => {
      const actionPerformance = getActionPerformance(actionInfo);

      const bothAll = filter.deadline === "ALL" && filter.status === "ALL";
      const onDeadline =
        filter.deadline === "ALL" ||
        filter.deadline === actionPerformance.status;
      const onStatus =
        filter.status === "ALL" || filter.status === actionInfo.status;

      const isOnFilter = bothAll || (onDeadline && onStatus);

      if (isOnFilter)
        allActions.push({ insightId, indicatorInfo, ...actionInfo });
    });
  });

  return allActions;
};

export const buildActionsInsightInfo = (
  actionsPlan,
  insights = [],
  filter = { status: "ALL", deadline: "ALL" },
) => {
  const allActions = [];

  actionsPlan.forEach((actionPlanInfo) => {
    const { insightId, actionPlan = {} } = actionPlanInfo;
    const actions = actionPlan?.actions || [];

    const insightInfo = getObjectInfoById(insightId, insights, "id");

    actions.forEach((actionInfo) => {
      const actionPerformance = getActionPerformance(actionInfo);

      const bothAll = filter.deadline === "ALL" && filter.status === "ALL";
      const onDeadline =
        filter.deadline === "ALL" ||
        filter.deadline === actionPerformance.status;
      const onStatus =
        filter.status === "ALL" || filter.status === actionInfo.status;

      const isOnFilter = bothAll || (onDeadline && onStatus);

      if (isOnFilter)
        allActions.push({ insightId, insightInfo, ...actionInfo });
    });
  });

  return allActions;
};

export const getInsightActionsPlanRelatedUsers = (
  insightId,
  allActionsPlans,
) => {
  const collaborators = [];

  const insightActionsPlan = getFilteredActionsPlanByInsights(
    [insightId],
    allActionsPlans,
  );

  if (insightActionsPlan[0]) {
    insightActionsPlan.forEach((actionPlanInfo) => {
      const { actionPlan = {} } = actionPlanInfo;
      const actions = actionPlan?.actions || [];

      if (Array.isArray(actions)) {
        actions.forEach(({ responsible }) => {
          if (responsible?.id)
            collaborators.push({ id: responsible.id, role: "COLLABORATOR" });
        });
      }
    });
  }

  return collaborators;
};

export const getQuantityAllActionPlans = (allActionPlans) => {
  const getQuantityWithSom = (accumulator, currentValue) =>
    accumulator + currentValue?.actions?.lenght || 0;

  return allActionPlans.reduce(getQuantityWithSom, [0]);
};

export const getGroupedRelatedActionPlans = (actions, relatedActions) => {
  const filtered = [];
  const ids = [];

  function add(action) {
    if (action && ids.indexOf(action.id) === -1) {
      filtered.push(action);
      ids.push(action.id);
    }
  }

  if (Array.isArray(actions))
    actions.forEach((actionInfo) => {
      add(actionInfo);
    });

  if (Array.isArray(relatedActions))
    relatedActions.forEach((info) => {
      const { actions: relatedAction } = info || {};

      if (Array.isArray(relatedAction))
        relatedAction.forEach((actionInfo) => {
          add(actionInfo);
        });
    });

  return filtered;
};

export const groupAllActionsByRelated = (allActionPlans) => {
  const filtered = [];

  if (Array.isArray(allActionPlans)) {
    allActionPlans.forEach((actionPlanInfo) => {
      const { actionPlan, actionPlanId } = actionPlanInfo;
      const { actions, relatedActions } = actionPlan;

      if (actionPlanId)
        filtered.push({
          ...actionPlanInfo,
          actions: getGroupedRelatedActionPlans(actions, relatedActions),
        });
    });
  }

  return filtered;
};
