import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useCompanyPositionsTree } from "../../../../../../../utils/customHooks";
import SelectTreeModal from "../../../../../../Common/SelectTreeModal";
import {
  getSelectedCompanyStates,
  getCompanyStructureStates,
} from "../../../../../../customMapStates";

const SelectParticipantRole = ({
  open,
  onConfirm,
  onCancel,
  selectedCompany,
  allPositions,
}) => {
  const { companyPositionsTree } = useCompanyPositionsTree({
    selectedCompany,
    allPositions,
  });

  return (
    <>
      <SelectTreeModal
        open={open}
        title="Selecione o Cargo"
        treeList={companyPositionsTree.treeList}
        treeOrder={companyPositionsTree.treeOrder}
        onConfirm={(selected) => onConfirm(selected[0])}
        onCancel={onCancel}
        singleSelect
        canSelectAnyLevel
        dialogClassName="select-role-dialog"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { selectedCompany } = getSelectedCompanyStates(state);
  const { allPositions } = getCompanyStructureStates(state);

  return { selectedCompany, allPositions };
};

SelectParticipantRole.propTypes = {
  open: PropTypes.bool,
  selectedCompany: PropTypes.object.isRequired,
  allPositions: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

SelectParticipantRole.defaultProps = {
  open: false,
};

export default connect(mapStateToProps)(SelectParticipantRole);
