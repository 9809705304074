import {
  FETCH_USER_TEAMS,
  CREATE_TEAM,
  UPDATE_TEAM,
  FETCH_TEAM_MEMBERS,
} from "../actions/types";

const INITIAL_STATE = {
  teams: [],
  teamMembers: [],
  teamParticipants: [],
};

const teamsReducer = (state = INITIAL_STATE, action) => {
  const { companyData } = state;

  const currentAllEvaluation =
    companyData && companyData.teams ? companyData.teams : [];

  const { payload = {} } = action;

  const updateEvaluation = (newBody) => {
    let finalEvaluation = [];
    let didUpdate = false;

    currentAllEvaluation.forEach((itemBody) => {
      if (itemBody.id === newBody.id) {
        didUpdate = true;
      }

      finalEvaluation = [...finalEvaluation, newBody];
    });

    if (!didUpdate) finalEvaluation = [...finalEvaluation, newBody];

    return finalEvaluation;
  };

  switch (action.type) {
    case FETCH_USER_TEAMS:
      return {
        ...state,
        teams: action.payload,
      };
    case CREATE_TEAM:
      return {
        ...state,
        teams: updateEvaluation(payload),
        lastCreatedTeamInfo: payload,
      };
    case UPDATE_TEAM:
      return {
        ...state,
        team: updateEvaluation(payload),
      };
    case FETCH_TEAM_MEMBERS:
      return {
        ...state,
        teamMembers: action.payload,
      };
    case "FETCH_TEAM_PARTICIPANTS":
      return {
        ...state,
        teamParticipants: action.payload,
      };
    default:
      return state;
  }
};

export default teamsReducer;
