import moment from "moment";
import { getValidDate } from "../DateUtils";

const isOutTime = (compareDate, baseDate) => {
  return moment(compareDate).isAfter(baseDate);
};

const inProgressEvaluations = (currentDate, endDate) => {
  return isOutTime(currentDate, endDate) ? "OUT" : "WITHIN_DEADLINE";
};

const completeEvaluations = (completedDate, endDate) => {
  return isOutTime(completedDate, endDate) ? "OUT" : "WITHIN_DEADLINE";
};

export const getCalcDates = (refDate) => {
  const dates = refDate || {};
  const startDate = getValidDate(dates.start);
  const endDate = getValidDate(dates.end);
  const rescheduledDate = getValidDate(dates.end);
  const conclusionDate = getValidDate(dates.conclusion);
  const currentTodayDate = getValidDate(new Date());
  const realEndDate = rescheduledDate || endDate;

  return {
    startDate,
    endDate,
    rescheduledDate,
    conclusionDate,
    realEndDate,
    currentTodayDate,
  };
};

export const getActionPerformance = (actionInfo = {}) => {
  const { realEndDate, conclusionDate, currentTodayDate } = getCalcDates(
    actionInfo.dates,
  );

  if (actionInfo.status === "IN_PROGRESS")
    return {
      status: inProgressEvaluations(currentTodayDate, realEndDate),
      label: moment(currentTodayDate).format("L"),
    };
  if (actionInfo.status === "COMPLETE")
    return {
      status: completeEvaluations(conclusionDate, realEndDate),
      label: moment(conclusionDate).format("L"),
    };

  if (actionInfo.status === "CANCELED")
    return { status: "CANCELED", label: null };

  return { status: "NOT_STARTED", label: null };
};

export const getActionsGroupedByStatus = (actionsPlan = []) => {
  const grouped = {
    NOT_STARTED: [],
    IN_PROGRESS: [],
    COMPLETE: [],
    CANCELED: [],
  };

  if (Array.isArray(actionsPlan)) {
    actionsPlan.forEach((actionPlanInfo) => {
      const { actionPlan = {} } = actionPlanInfo;

      if (actionPlan && actionPlan.id) {
        const { actions = [], relatedActions = [] } = actionPlan;

        [...actions, ...relatedActions].forEach((actionInfo) => {
          const { status = "NOT_STARTED" } = actionInfo;

          grouped[status].push({
            actionPlanId: actionPlan.id,
            action: actionInfo,
          });
        });
      }
    });
  }

  return grouped;
};

export const getActionsByStatus = (actionsPlan = []) => {
  const grouped = {
    NOT_STARTED: [],
    IN_PROGRESS: [],
    COMPLETE: [],
    CANCELED: [],
  };

  actionsPlan.forEach((actionInfo) => {
    const { status = "NOT_STARTED" } = actionInfo;

    grouped[status].push({
      actionPlanId: actionInfo.id,
      action: actionInfo,
    });
  });

  return grouped;
};

export const getActionsGroupedByPerformance = (filteredActions = []) => {
  const grouped = {
    OUT: [],
    WITHIN_DEADLINE: [],
    NOT_STARTED: [],
    CANCELED: [],
  };

  filteredActions.forEach((actionInfo) => {
    const actionPerformance = getActionPerformance(actionInfo);
    const performanceStatus = actionPerformance.status;

    grouped[performanceStatus].push(actionInfo);
  });

  return grouped;
};
