import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Col,
  Row,
  Button,
} from "react-bootstrap";
import _ from "lodash";

import { Paper, makeStyles, Grid, Typography } from "@material-ui/core";
import { globalMessages } from "../../utils/global";
import { nFormatter } from "../../utils/MathUtils";
import Polarity from "../Common/Polarity";

import {
  addInsightGoal,
  updateInsightGoal,
  fetchAllInsightsGoals,
  deleteInsightGoal,
} from "../../actions/insightActions";
import { fetchFollowupOkr } from "../../actions/reportOkrFollowupAction";

import { insightsGoalState, getSelectedToolStates } from "../customMapStates";
import { fetchQuestionAnswers } from "../../actions/answersActions";
import utils from "../../utils/toolUtils";

const { getGoalEvaluationByID, getToFixed, getOkrTotalEvaluation } = utils;

const RenderGoal = (props) => {
  const { labelsMessages, selectedInsightGoal } = props;

  const { insightGoal = {} } = selectedInsightGoal;

  const expectationPercentage = getOkrTotalEvaluation(
    insightGoal.value,
    insightGoal.expectationValue,
    insightGoal.polarity,
  );

  const reachedPercentage = getOkrTotalEvaluation(
    insightGoal.value,
    insightGoal.reached,
    insightGoal.polarity,
  );

  function getDeviation(value) {
    return getToFixed(value - 100);
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <b>{insightGoal.description}</b>
        </div>
      </div>
      <br />
      <div
        className="box-card-goal"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* Predicted Value Box */}
        <CardGoal
          {...props}
          predictedValue
          messagePercentage={labelsMessages.valorPrevisto}
          messageDescription={labelsMessages.Polarity}
          displayPercentage={insightGoal.value}
          messageScore={labelsMessages.score}
          insightGoal={insightGoal}
          hideScore
        />
        {/* Reached Value Box */}
        <CardGoal
          {...props}
          insightGoal={insightGoal}
          displayPercentage={reachedPercentage}
          messagePercentage={labelsMessages.labelReached}
          formatFieldsValue={insightGoal.reached}
          formatDeviation={getDeviation(reachedPercentage)}
          badgeStatus={insightGoal.status}
          messageScore={labelsMessages.score}
        />
        {/* Expected Value Box */}
        <CardGoal
          {...props}
          insightGoal={insightGoal}
          displayPercentage={expectationPercentage}
          messagePercentage={labelsMessages.labelExpectativa}
          formatFieldsValue={insightGoal.expectationValue}
          formatDeviation={getDeviation(expectationPercentage)}
          badgeStatus={insightGoal.expectationStatus}
          messageScore={labelsMessages.score}
        />
      </div>
    </>
  );
};

const defaultGoalFields = {
  type: "PERCENTAGE",
  description: "",
  polarity: null,
  value: 0,
  reached: 0,
  expectationValue: 0,
  expectationStatus: "NAO_INICIADA",
  status: "NAO_INICIADA",
};

const InsightGoalContainer = (props) => {
  const [goalFields, setGoalFields] = useState(defaultGoalFields);

  const { allInsightGoals, insightId, isEditingGoal, intl } = props;

  const getInsightGoal = () => {
    const selectedInsightGoal = getGoalEvaluationByID(
      insightId,
      allInsightGoals,
    );

    return { selectedInsightGoal };
  };

  const { selectedInsightGoal = {} } = getInsightGoal();
  const { insightGoal = defaultGoalFields } = selectedInsightGoal;

  useEffect(() => {
    if (isEditingGoal || insightGoal.id) {
      if (selectedInsightGoal)
        handleSetGoalFields(selectedInsightGoal.insightGoal);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingGoal, insightGoal, insightGoal.polarity]);

  function handleResetGoalHistory() {
    setGoalFields(defaultGoalFields);
  }

  function handleCancelEditingGoal() {
    handleResetGoalHistory();
    props.handleHideEditingGoal(false);
  }

  const handleSetGoalFields = (newGoalFields) => {
    if (newGoalFields && !_.isEqual(newGoalFields, goalFields)) {
      setGoalFields(newGoalFields);
    }
  };

  async function handleSubmitUpdateGoal(
    newPolarity = "none",
    closeForm = true,
  ) {
    const { insightId } = props;
    newPolarity = newPolarity !== "none" ? newPolarity : insightGoal.polarity;

    const selectedToolId =
      props.selectedTool && props.selectedTool.id
        ? props.selectedTool.id
        : null;

    if (insightGoal && insightGoal.id) {
      await props.updateInsightGoal(insightId, goalFields, selectedToolId);
      if (selectedToolId) {
        props.fetchAllInsightsGoals(selectedToolId);
      }
    } else {
      await props.addInsightGoal(insightId, goalFields, selectedToolId);
      if (selectedToolId) {
        props.fetchAllInsightsGoals(selectedToolId);
      }
    }

    if (closeForm) {
      props.handleHideEditingGoal(false);
      handleResetGoalHistory();
    }
  }

  const handleChangePolarity = (newPolarity, closeForm = false) => {
    setGoalFields({
      ...goalFields,
      polarity: newPolarity,
    });
  };

  const badgesMessages = defineMessages({
    NAO_INICIADA: {
      id: "goalsBadges.NAO_INICIADA",
    },
    CANCELADA: {
      id: "goalsBadges.CANCELADA",
    },
    SUPERADA: {
      id: "goalsBadges.SUPERADA",
    },
    NAO_ATINGIDA: {
      id: "goalsBadges.NAO_ATINGIDA",
    },
    ATINGIDA: {
      id: "goalsBadges.ATINGIDA",
    },
    PARCIALMENTE_ATINGIDA: {
      id: "goalsBadges.PARCIALMENTE_ATINGIDA",
    },
  });

  const labelsMessages = defineMessages({
    description: {
      id: "insight.goals.label.description",
    },
    Polarity: {
      id: "goalsLabel.Polarity",
    },
    type: {
      id: "insight.goals.label.type",
    },
    valorPrevisto: {
      id: "goalsLabel.valorPrevisto",
    },
    variacao: {
      id: "goalsLabel.variacao",
    },
    score: {
      id: "goalsLabel.score",
    },
    valorAtingido: {
      id: "goalsLabel.valorAtingido",
    },
    scoreAtingido: {
      id: "goalsLabel.scoreAtingido",
    },
    statusAtingido: {
      id: "goalsLabel.statusAtingido",
    },
    labelExpectativa: {
      id: "goalsLabel.expectativa",
    },
    valorExpectativa: {
      id: "goalsLabel.valorExpectativa",
    },
    scoreExpectativa: {
      id: "goalsLabel.scoreExpectativa",
    },
    statusExpectativa: {
      id: "goalsLabel.statusExpectativa",
    },
    labelDescription: {
      id: "insight.goals.label.description",
    },
    labelType: {
      id: "insight.goals.label.type",
    },
    labelValue: {
      id: "insight.goals.label.value",
    },
    labelReached: {
      id: "insight.goals.label.reached",
    },
    labelStatus: {
      id: "insight.goals.label.status",
    },
    typePercentage: {
      id: "insight.goals.type.percentage",
    },
    typeAmount: {
      id: "insight.goals.type.amount",
    },
    statusNotStarted: {
      id: "insight.goals.status.notStarted",
    },
    statusCanceled: {
      id: "insight.goals.status.canceled",
    },
    statusExceeded: {
      id: "insight.goals.status.exceeded",
    },
    statusPartiallyAchieved: {
      id: "insight.goals.status.partiallyAchieved",
    },
    statusAchieved: {
      id: "insight.goals.status.achieved",
    },
    statusNotAchieved: {
      id: "insight.goals.status.notAchieved",
    },
  });

  const createBadge = (text, _class) => {
    return (
      <span
        style={{ width: "100%" }}
        className={classnames("badge", `bg-${_class}`)}
      >
        {text}
      </span>
    );
  };

  const createStatusBadge = (status) => {
    switch (status) {
      case "NAO_INICIADA":
        return createBadge(
          intl.formatMessage(badgesMessages.NAO_INICIADA),
          "nao-iniciada",
        );
      case "CANCELADA":
        return createBadge(
          intl.formatMessage(badgesMessages.CANCELADA),
          "cancelada",
        );
      case "NAO_ATINGIDA":
        return createBadge(
          intl.formatMessage(badgesMessages.NAO_ATINGIDA),
          "nao-atingida",
        );
      case "PARCIALMENTE_ATINGIDA":
        return createBadge(
          intl.formatMessage(badgesMessages.PARCIALMENTE_ATINGIDA),
          "parcialmente-atingida",
        );
      case "ATINGIDA":
        return createBadge(
          intl.formatMessage(badgesMessages.ATINGIDA),
          "atingida",
        );
      case "SUPERADA":
        return createBadge(
          intl.formatMessage(badgesMessages.SUPERADA),
          "superada",
        );
      default:
        return createBadge(
          intl.formatMessage(badgesMessages.NAO_INICIADA),
          "nao-iniciada",
        );
    }
  };

  const formatValue = (value, type) => {
    return type === "PERCENTAGE" ? `${value}%` : nFormatter(value, 2);
  };

  const renderEmptyMessage = () => {
    return (
      <div className="col-sm-12 animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div>{intl.formatMessage(globalMessages.empty)}</div>
          </div>
        </div>
      </div>
    );
  };

  const appendProps = {
    goalFields,
    formatValue,
    intl,
    handleChangePolarity,
    createStatusBadge,
    labelsMessages,
    handleSetGoalFields,
    handleSubmitUpdateGoal,
    selectedInsightGoal,
    handleResetGoalHistory,
    handleCancelEditingGoal,
  };

  return (
    <>
      {isEditingGoal && (
        <FormEditingGoal
          {...appendProps}
          labelDescription={labelsMessages.labelDescription}
          show={isEditingGoal}
          insightGoal={insightGoal}
        />
      )}
      {!insightGoal.id && !isEditingGoal && renderEmptyMessage()}
      {!isEditingGoal && insightGoal.id && <RenderGoal {...appendProps} />}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    display: "flex",
    flex: 1,
    width: 390,
    padding: theme.spacing(1),
    margin: "10px",
  },
  percentage: {
    fontSize: "25px",
  },
  gridPercentage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRight: "1px solid #dbdbdb",
    padding: "0 10px 0 08px",
  },
  gridItem: {
    padding: theme.spacing(1),
  },
  padding: {
    padding: "8px",
  },
  margin: {
    margin: "5px 0",
  },
  polarity: {
    fontSize: "12px",
    marginRight: "10px",
  },
}));

const CardGoal = (props) => {
  const classes = useStyles();
  const {
    intl,
    formatValue,
    messageDescription,
    messagePercentage,
    displayPercentage,
    predictedValue,
    formatFieldsValue,
    formatDeviation,
    createStatusBadge,
    badgeStatus,
    labelsMessages,
    messageScore,
    insightGoal,
    hideScore,
  } = props;

  return (
    <Paper className={`${classes.paper} paper-goal`}>
      <Grid container wrap="nowrap">
        <Grid item className={`${classes.gridPercentage} grid-percentage`}>
          <Typography>{intl.formatMessage(messagePercentage)}</Typography>
          <Typography
            variant="subtitle2"
            className={`${classes.percentage} goal-percentage`}
          >
            {formatFieldsValue || formatFieldsValue === 0
              ? formatValue(formatFieldsValue, insightGoal && insightGoal.type)
              : ""}
          </Typography>
          {predictedValue && (
            <Typography
              variant="subtitle2"
              className={`${classes.percentage} goal-percentage`}
            >
              {`${formatValue(
                displayPercentage,
                insightGoal && insightGoal.type,
              )}`}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          container
          direction="column"
          justify="space-between"
          xs={12}
          sm
          className={classes.gridItem}
        >
          {!hideScore && (
            <Grid item className={classes.padding}>
              <span style={{ fontSize: "12px" }}>
                {props.intl.formatMessage(messageScore)}
                <b>: {getToFixed(displayPercentage)}%</b>
              </span>
            </Grid>
          )}

          <Grid item className={classes.padding}>
            <span className="progress-description header-faq">
              {!predictedValue && createStatusBadge(badgeStatus)}
              {predictedValue && (
                <>
                  <span className={classes.polarity}>
                    {props.intl.formatMessage(messageDescription)}:
                  </span>
                  <Polarity
                    value={(insightGoal && insightGoal.polarity) || ""}
                    pointer={false}
                    cardPolarity
                  />
                </>
              )}
            </span>
          </Grid>

          <Grid item className={classes.padding}>
            <span style={{ height: "15px", fontSize: "12px" }}>
              {!predictedValue && intl.formatMessage(labelsMessages.variacao)}
              <b>
                {formatDeviation && formatDeviation.length
                  ? ` : ${formatDeviation}%`
                  : ""}
              </b>
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const FormEditingGoal = (props) => {
  const [touched, setTouched] = useState([]);
  const {
    goalFields,
    intl,
    handleChangePolarity,
    labelsMessages,
    handleSubmitUpdateGoal,
    labelDescription,
    show,
    handleSetGoalFields,
    handleCancelEditingGoal,
  } = props;

  useEffect(() => {
    if (show && goalFields) {
      const newTouched = [];

      Object.keys(goalFields).forEach((fieldName) => {
        const fieldValue = goalFields[fieldName];
        if (
          fieldValue &&
          fieldValue.length > 0 &&
          touched.indexOf(fieldName) === -1
        ) {
          newTouched.push(fieldName);
        }
      });

      const finalTouched = [...touched, ...newTouched];

      if (!_.isEqual(touched, finalTouched)) {
        setTouched(finalTouched);
      }
    }
  }, [goalFields, show, touched]);

  const formMessages = defineMessages({
    require_field: {
      id: "form_required_field",
    },
    min_length: {
      id: "form_min_length",
    },
  });

  function validateForm() {
    const requiredFields = [
      "type",
      "description",
      "value",
      "reached",
      "expectationValue",
    ];
    const errors = {
      required: [],
      validation: {},
    };

    const addError = (slug, message) => {
      if (touched.indexOf(slug) > -1) {
        errors.validation = {
          ...errors.validation,
          [slug]: message,
        };
      }
    };

    requiredFields.forEach((slugName) => {
      if (goalFields[slugName]) {
        if (goalFields[slugName] === "" || goalFields[slugName].length <= 0)
          errors.required.push(slugName);
      }
    });

    const requiredFieldMsg = intl.formatMessage(formMessages.require_field);

    if (!goalFields.description) {
      addError("description", requiredFieldMsg);
    } else if (!goalFields.description || goalFields.description.length <= 3) {
      addError("description", intl.formatMessage(formMessages.min_length));
    }

    if (!goalFields.value) {
      addError("value", requiredFieldMsg);
    }

    if (!goalFields.reached) {
      addError("reached", requiredFieldMsg);
    }

    if (!goalFields.expectationValue) {
      addError("expectationValue", requiredFieldMsg);
    }

    return errors;
  }

  function warningFiled(errorMessage) {
    return errorMessage && errorMessage.length > 0 ? (
      <span className="text-danger">{errorMessage}</span>
    ) : "" && errorMessage && errorMessage.length < 0 ? null : (
      <span className="text-danger">{errorMessage}</span>
    );
  }

  function createStatusField(name, value) {
    return (
      <select
        name={name}
        className="form-control"
        onChange={(e) =>
          handleSetGoalFields({
            ...goalFields,
            [name]: e.target.value,
          })
        }
        value={value || ""}
      >
        <option value="NAO_INICIADA">
          {intl.formatMessage(labelsMessages.statusNotStarted)}
        </option>
        <option value="NAO_ATINGIDA">
          {intl.formatMessage(labelsMessages.statusNotAchieved)}
        </option>
        <option value="PARCIALMENTE_ATINGIDA">
          {intl.formatMessage(labelsMessages.statusPartiallyAchieved)}
        </option>
        <option value="ATINGIDA">
          {intl.formatMessage(labelsMessages.statusAchieved)}
        </option>
        <option value="SUPERADA">
          {intl.formatMessage(labelsMessages.statusExceeded)}
        </option>
        <option value="CANCELADA">
          {intl.formatMessage(labelsMessages.statusCanceled)}
        </option>
      </select>
    );
  }

  const validationErrors = validateForm();
  return (
    <div style={show ? { display: "block" } : { display: "none" }}>
      <Form>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <FormGroup controlId="description">
              <ControlLabel>
                {intl.formatMessage(labelDescription)}
              </ControlLabel>
              <FormControl
                type="string"
                id="description"
                defaultValue={goalFields.description}
                onChange={(e) => {
                  handleSetGoalFields({
                    ...goalFields,
                    description: e.target.value,
                  });
                }}
              />
              {warningFiled(validationErrors.validation.description)}
            </FormGroup>
            <span
              style={{ position: "absolute", bottom: "-8px", color: "red" }}
            >
              {" "}
            </span>
          </Col>
          <Col xs={12} sm={12} md={2} lg={2}>
            <FormGroup controlId="type">
              <ControlLabel>
                {intl.formatMessage(labelsMessages.labelType)}
              </ControlLabel>
              <select
                name="type"
                className="form-control"
                style={{ padding: 0 }}
                value={goalFields.type}
                onChange={(e) =>
                  handleSetGoalFields({
                    ...goalFields,
                    type: e.target.value,
                  })
                }
              >
                <option value="PERCENTAGE">
                  {intl.formatMessage(labelsMessages.typePercentage)}
                </option>
                <option value="AMOUNT">
                  {intl.formatMessage(labelsMessages.typeAmount)}
                </option>
              </select>
            </FormGroup>
            <span
              style={{ position: "absolute", bottom: "-8px", color: "red" }}
            />
          </Col>
          <Col className="text-center" xs={12} sm={12} md={2} lg={2}>
            <FormGroup controlId="polarity">
              <ControlLabel>
                {intl.formatMessage(labelsMessages.Polarity)}
              </ControlLabel>
              <br />
              <Polarity
                value={goalFields.polarity || ""}
                onChangeValue={(newPolarity) =>
                  handleChangePolarity(newPolarity, false)
                }
                pointer
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={4} lg={4}>
            <FormGroup controlId="value">
              <ControlLabel>
                {intl.formatMessage(labelsMessages.valorPrevisto)}
              </ControlLabel>
              <FormControl
                type="number"
                id="value"
                defaultValue={goalFields.value}
                onChange={(e) =>
                  handleSetGoalFields({
                    ...goalFields,
                    value: Number(e.target.value),
                  })
                }
              />
              {warningFiled(validationErrors.validation.value)}
            </FormGroup>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            <FormGroup controlId="reached">
              <ControlLabel>
                {intl.formatMessage(labelsMessages.valorAtingido)}
              </ControlLabel>
              <FormControl
                type="number"
                id="reached"
                defaultValue={goalFields.reached}
                onChange={(e) =>
                  handleSetGoalFields({
                    ...goalFields,
                    reached: Number(e.target.value),
                  })
                }
              />
              {warningFiled(validationErrors.validation.reached)}
            </FormGroup>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            <FormGroup controlId="expectationValue">
              <ControlLabel>
                {intl.formatMessage(labelsMessages.valorExpectativa)}
              </ControlLabel>
              <FormControl
                type="number"
                id="expectationValue"
                defaultValue={goalFields.expectationValue}
                onChange={(e) =>
                  handleSetGoalFields({
                    ...goalFields,
                    expectationValue: Number(e.target.value),
                  })
                }
              />
              {warningFiled(validationErrors.validation.expectationValue)}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={4} lg={4} />
          <Col xs={12} sm={12} md={4} lg={4}>
            <FormGroup controlId="status">
              <ControlLabel>
                {intl.formatMessage(labelsMessages.statusAtingido)}
              </ControlLabel>
              {createStatusField("status", goalFields.status)}
            </FormGroup>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            <FormGroup controlId="expectationStatus">
              <ControlLabel>
                {intl.formatMessage(labelsMessages.statusExpectativa)}
              </ControlLabel>
              {createStatusField(
                "expectationStatus",
                goalFields.expectationStatus,
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Button
              className="pull-right"
              bsStyle="primary"
              disabled={
                !!(validationErrors && validationErrors.required.length > 0)
              }
              onClick={() => handleSubmitUpdateGoal()}
            >
              {intl.formatMessage(globalMessages.confirm)}
            </Button>
            <Button
              className="pull-right"
              bsStyle="danger"
              onClick={() => handleCancelEditingGoal()}
            >
              {intl.formatMessage(globalMessages.cancel)}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

InsightGoalContainer.propTypes = {
  insightId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const { allInsightGoals } = insightsGoalState(state);
  const { selectedTool } = getSelectedToolStates(state);

  return {
    selectedTool,
    allInsightGoals,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    fetchQuestionAnswers,
    addInsightGoal,
    updateInsightGoal,
    deleteInsightGoal,
    fetchFollowupOkr,
    fetchAllInsightsGoals,
  })(InsightGoalContainer, CardGoal),
);
