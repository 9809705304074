import _ from "lodash";
import moment from "moment";
import { defineMessages } from "react-intl";
import { getObjectDifference } from "./ArrayUtils";
import { ruleOfThree } from "./MathUtils";
import {
  checkPeriodIndexInCourse,
  getCustomDeliveryDate,
  getLabeledSchedule,
  getPatternDateFormat,
  getRealDeadline,
  getStageParentInfo,
  getTotalProgress,
} from "./projectUtils";
import { translatedText } from "./translationUtils";

const messages = defineMessages({
  according: {
    id: "global.according_to_plan",
  },
  with_impact: {
    id: "global.change_with_impact",
  },
  without_impact: {
    id: "global.change_without_impact",
  },
  not_concluded: {
    id: "tool.project.not_concluded",
  },
  planning: {
    id: "tool.project.phase.planning",
  },
  ongoing: {
    id: "tool.project.phase.ongoing",
  },
  done: {
    id: "tool.project.phase.done",
  },
  notStarted: {
    id: "insight.goals.status.notStarted",
  },
  standby: {
    id: "tool.project.phase.standby",
  },
  canceled: {
    id: "tool.project.phase.canceled",
  },
  executant: {
    id: "tool.project.performer",
  },
  responsible: {
    id: "global.responsable",
  },
  addMember: {
    id: "global.addMember",
  },
  total: {
    id: "global.total2",
  },
  member: {
    id: "global.member",
  },
  members: {
    id: "global.members",
  },
  choose_responsible: {
    id: "tool.project.choose_the_resposible",
  },
  choose_executant: {
    id: "tool.project.choose_the_executant",
  },
  choose_member: {
    id: "tool.project.choose_the_new_member",
  },
  beginDate: {
    id: "insight.followUp.label.beginDate",
  },
  endDate: {
    id: "insight.followUp.label.endDate",
  },
  reason: {
    id: "tool.project.reason",
  },
  newDate: {
    id: "tool.project.new_delivery_date",
  },
  schedule: {
    id: "tool.project.date_schedule",
  },
  effort: {
    id: "tool.project.effort",
  },
  investment: {
    id: "tool.project.investment",
  },
  budgetHours: {
    id: "tool.project.budget_hours",
  },
  newSpentTime: {
    id: "tool.project.new_spent_time",
  },
  newSpent: {
    id: "tool.project.new_spent",
  },
  newBalanceTime: {
    id: "tool.project.new_balance_time",
  },
  newBudget: {
    id: "tool.project.new_budget",
  },
  spentHours: {
    id: "tool.project.spent_hours",
  },
  spentMinutes: {
    id: "tool.project.spent_minutes",
  },
  spentTime: {
    id: "tool.project.spent_time",
  },
  balanceHours: {
    id: "tool.project.balance_hours",
  },
  replannings: {
    id: "tool.project.replannings",
  },
  spent: {
    id: "tool.project.spent",
  },
  balance: {
    id: "tool.project.balance",
  },
  budget: {
    id: "tool.project.budget",
  },
  stage: {
    id: "tool.project.phase",
  },
  year: {
    id: "global.year",
  },
  newYear: {
    id: "global.newYear",
  },
  month: {
    id: "global.month",
  },
  filterBy: {
    id: "tool.project.filterBy",
  },
  newBalance: {
    id: "tool.project.newBalance",
  },
  in: {
    id: "tool.project.in",
  },
  out: {
    id: "tool.project.out",
  },
  next: {
    id: "tool.project.next",
  },
  theme: {
    id: "global.theme",
  },
  group: {
    id: "tool.project.group",
  },
  scenerie: {
    id: "global.cenary",
  },
  project: {
    id: "global.project",
  },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

const getFilterByOptions = () => {
  return [
    { value: "total", label: translation("total") },
    { value: "year", label: translation("year") },
    {
      value: "replannings",
      label: translation("replannings"),
    },
  ];
};

export const getCustomFilterByOptions = () => {
  return [
    { value: "total", label: translation("total") },
    { value: "year", label: translation("year") },
  ];
};

const getLabels = () => {
  return {
    budget: "budget",
    spentHours: "spentHours",
    spentMinutes: "spentMinutes",
    spent: "spent",
    balance: "balance",
  };
};

const getMemberbyId = (companyMembersAndParticipants, selectedMemberId) => {
  let selectedMember = null;
  companyMembersAndParticipants.forEach((item) => {
    if (item.id === selectedMemberId) {
      selectedMember = item;
    }
  });
  return selectedMember;
};

const getYearsDiff = (initialYear, finalYear) => {
  let yearsList = [Number(initialYear)];
  let yearsOptions = [{ value: initialYear, label: initialYear }];
  const dif = finalYear - initialYear;
  let newYear = null;
  let newOption = null;

  if (dif !== 0) {
    yearsOptions = [];
    for (let i = 1; i <= dif; i++) {
      newYear = Number(initialYear) + i;
      yearsList = [...yearsList, newYear];
    }
    yearsList.forEach((item) => {
      newOption = { value: Number(item), label: Number(item) };
      yearsOptions = [...yearsOptions, newOption];
    });
  }

  const availableYears = _.uniq(yearsList);
  yearsOptions = _.uniqBy(yearsOptions, "label");

  return { availableYears, yearsOptions };
};

const getSomethingSplited = (something, splitRule) => {
  const somethingAux =
    typeof something === "string" ? something : something.toString();
  const splitArray = somethingAux.split(splitRule);

  return splitArray;
};

const getSplitDates = (initialDate, finalDate) => {
  const initial = initialDate.split("-");
  const initialDay = Number(initial[2]);
  const initialMonth = Number(initial[1]);
  const initialYear = Number(initial[0]);

  const final = finalDate.split("-");
  const finalDay = Number(final[2]);
  const finalMonth = Number(final[1]);
  const finalYear = Number(final[0]);

  return {
    initialDay,
    initialMonth,
    initialYear,
    finalDay,
    finalMonth,
    finalYear,
  };
};
const getAvailableSchedule = (schedule) => {
  const { initialDate, finalDate } = schedule;

  const a = moment(initialDate);
  const b = moment(finalDate);

  if (!b.isValid() || !a.isValid())
    return {
      availableYears: [],
      yearsOptions: [],
      monthsDiff: 0,
      initialMonth: 0,
    };

  const monthsDiff = b.diff(a, "months");

  const { initialYear, finalYear, initialMonth } = getSplitDates(
    initialDate,
    finalDate
  );
  const { availableYears, yearsOptions } = getYearsDiff(initialYear, finalYear);
  return { availableYears, yearsOptions, monthsDiff, initialMonth };
};

const getAvailableMonths = (year, period, months, valueType) => {
  const updatedMonths = { ...months };

  Object.keys(months).forEach((monthName, index) => {
    const monthValidation = checkPeriodIndexInCourse(index, year, period);

    if (!monthValidation) {
      updatedMonths[monthName] = null;
    } else if (!updatedMonths[monthName]) {
      updatedMonths[monthName] = {
        budget: "",
        spent: "",
        valueType,
      };
    }
  });

  return updatedMonths;
};

export const getPhysicalAvailableMonths = (year, period, months) => {
  const updatedMonths = [...months];

  updatedMonths.forEach((month, index) => {
    const monthValidation = checkPeriodIndexInCourse(index, year, period);

    if (!monthValidation) {
      updatedMonths[index] = -1;
    } else if (updatedMonths[index] === -1) {
      updatedMonths[index] = 0;
    }
  });

  return updatedMonths;
};

export const buildDefaultPhysicalEvaluations = (
  availableYears = "",
  plannings = [],
  period = null
) => {
  if (!plannings) {
    plannings = [...defaultPhysicalPlanning()];
  }

  let alreadyFound = false;
  let availableMonths = [];
  let evaluationsBuild = [];

  availableYears.forEach((year) => {
    alreadyFound = false;

    plannings.forEach((plan) => {
      if (Number(plan.year) === Number(year)) {
        alreadyFound = true;
        availableMonths = getPhysicalAvailableMonths(
          year,
          period,
          plan.monthProgress
        );

        plan.monthProgress = [...availableMonths];
        evaluationsBuild = [...evaluationsBuild, plan];
      }
    });

    if (!alreadyFound) {
      const months = defaultPhysicalPlanning().monthProgress;
      availableMonths = getPhysicalAvailableMonths(year, period, months);

      evaluationsBuild = [
        ...evaluationsBuild,
        {
          monthProgress: [...availableMonths],
          year,
        },
      ];
    }
  });

  return evaluationsBuild;
};

const buildDefaultYearsEvaluations = (
  availableYears = "",
  plannings = [],
  period = null,
  type
) => {
  const valueType =
    type === "Efforts" ? "HOURS" : type === "Investment" ? "MONEY" : null;

  if (!plannings) {
    plannings = [...defaultPlanning(valueType)];
  }

  let availableMonths = [];
  let evaluationsBuild = [];

  plannings.forEach((plan) => {
    delete plan.monthEvaluations.id;
    if (plan.year && plan.year !== "") {
      evaluationsBuild = [...evaluationsBuild, plan];
    }
  });

  let alreadyFound = false;

  if (availableYears.length === 0) return [];

  availableYears.forEach((year) => {
    alreadyFound = false;

    plannings.forEach((plan) => {
      if (Number(plan.year) === Number(year)) {
        alreadyFound = true;
        availableMonths = getAvailableMonths(
          year,
          period,
          plan.monthEvaluations,
          valueType
        );

        plan.monthEvaluations = { ...availableMonths };
      }
    });

    if (!alreadyFound) {
      const months = defaultPlanning(valueType).monthEvaluations;
      availableMonths = getAvailableMonths(year, period, months);

      evaluationsBuild = [
        ...evaluationsBuild,
        {
          id: "",
          monthEvaluations: { ...availableMonths },
          firstPlan: true,
          year,
        },
      ];
    }
  });

  return evaluationsBuild;
};

export const mountPlannings = (plannings) => {
  let updatedPlannings = [];
  let month_aux = null;

  if (plannings && plannings.length > 0) {
    plannings.forEach((plan) => {
      if (plan.year !== "") {
        delete plan.firstPlan;
        delete plan.projectInvestment;
        delete plan.projectEffort;

        const { monthEvaluations } = plan;
        const newMonthEvaluations = _.cloneDeep(monthEvaluations);

        Object.keys(newMonthEvaluations).forEach((month) => {
          if (newMonthEvaluations[month] && month !== "id") {
            const valueType = newMonthEvaluations[month].valueType || "";
            const isInvestment = valueType === "MONEY";
            if (isInvestment) {
              month_aux = newMonthEvaluations[month].spent;
              if (typeof month_aux === "string") {
                newMonthEvaluations[month].spent = Number(
                  month_aux.replace(/,/g, "")
                );
              }

              month_aux = newMonthEvaluations[month].budget;
              if (typeof month_aux === "string") {
                newMonthEvaluations[month].budget = Number(
                  month_aux.replace(/,/g, "")
                );
              }
            } else {
              month_aux = Number(newMonthEvaluations[month].budget);
              newMonthEvaluations[month].budget = month_aux;

              if (
                newMonthEvaluations[month].spentMinutes ||
                newMonthEvaluations[month].spentHours
              ) {
                const spentHours =
                  newMonthEvaluations[month].spentHours &&
                  newMonthEvaluations[month].spentHours !== ""
                    ? Number(newMonthEvaluations[month].spentHours)
                    : 0;

                const spentMinutes =
                  newMonthEvaluations[month].spentMinutes &&
                  newMonthEvaluations[month].spentMinutes !== ""
                    ? Number(newMonthEvaluations[month].spentMinutes)
                    : 0;

                const newSpentHours = spentHours * 60;

                newMonthEvaluations[month].spent = newSpentHours + spentMinutes;
              } else {
                month_aux = Number(newMonthEvaluations[month].spent);
                newMonthEvaluations[month].spent = month_aux;
              }

              delete monthEvaluations[month].spentHours;
              delete monthEvaluations[month].spentMinutes;
            }
          }
        });
        plan.monthEvaluations = { ...newMonthEvaluations };
        updatedPlannings = [...updatedPlannings, plan];
      }
    });
  }

  return updatedPlannings;
};

const getSlugByType = (selectedMember, type) => {
  const currentSelectedMember = selectedMember || {};

  let finalType = "";

  const slugType = currentSelectedMember.type
    ? currentSelectedMember.type
    : currentSelectedMember.userData
    ? "MEMBER"
    : "PARTICIPANT";

  if (slugType === "PARTICIPANT") {
    finalType = "Participant";
  } else if (slugType === "MEMBER") {
    finalType = "Member";
  }

  return type + finalType;
};

export const mountPeopleInvolved = (projectTeam) => {
  const currentProjectTeam = projectTeam || {};

  const responsible = currentProjectTeam.responsibleMember
    ? currentProjectTeam.responsibleMember
    : currentProjectTeam.responsibleParticipant
    ? currentProjectTeam.responsibleParticipant
    : null;

  const executant = currentProjectTeam.executantMember
    ? currentProjectTeam.executantMember
    : currentProjectTeam.executantParticipant
    ? currentProjectTeam.executantParticipant
    : null;

  const responsibleSlug = getSlugByType(responsible, "responsible");
  const executantSlug = getSlugByType(executant, "executant");

  const peopleInvolvedID =
    projectTeam && projectTeam.id ? projectTeam.id : null;

  return {
    id: peopleInvolvedID,
    responsibleMember: "",
    executantMember: "",
    responsibleParticipant: "",
    executantParticipant: "",
    [responsibleSlug]: responsible && responsible.id ? { ...responsible } : "",
    [executantSlug]: executant && executant.id ? { ...executant } : "",
    involved: [...currentProjectTeam.involved],
  };
};

export const mountPhysicalAdvance = (physicalAdvance) => {
  const currentPhysicalAdvance =
    (physicalAdvance && physicalAdvance.plannings) || [];
  let restPhysicalAdvance = [];
  let monthIndex = 1;
  let currentYearMonthValues = [];

  currentPhysicalAdvance.forEach((yearAdvance) => {
    const year = yearAdvance.year || 0;
    const monthProgress = yearAdvance.monthProgress || [];

    monthProgress.forEach((progress) => {
      if (progress !== -1) {
        currentYearMonthValues = [
          ...currentYearMonthValues,
          { month: monthIndex, value: Number(progress) },
        ];
      }

      monthIndex++;
    });

    restPhysicalAdvance = [
      ...restPhysicalAdvance,
      { year, months: [...currentYearMonthValues] },
    ];

    currentYearMonthValues = [];
    monthIndex = 1;
  });

  return restPhysicalAdvance;
};

export const sortArrayByAttribute = (array = [], sortBy = null) => {
  const orderedArray = array.sort((a, b) => {
    const a_attr = a[sortBy];
    const b_attr = b[sortBy];

    return a_attr > b_attr ? 1 : b_attr > a_attr ? -1 : 0;
  });

  return orderedArray;
};

const cleanAndBuildStateEvaluation = (
  onStateEvaluation,
  projectConfiguration = false
) => {
  const buildedStateEvaluation = onStateEvaluation
    ? buildStateEvaluation(onStateEvaluation, true, projectConfiguration)
        .projectEvaluation
    : null;

  if (!buildedStateEvaluation) return null;

  const { activities = [], reschedules = [] } = buildedStateEvaluation || {};

  buildedStateEvaluation.activities = sortArrayByAttribute(
    activities,
    "indexOrder"
  );
  buildedStateEvaluation.reschedules = sortArrayByAttribute(reschedules, "id");

  const cleanStateEvaluation = removeIds(_.cloneDeep(buildedStateEvaluation));

  return cleanStateEvaluation;
};

const cleanAndBuildCurrentEvaluation = (currentEvaluation) => {
  if (!currentEvaluation) return false;

  const { activities: projectActivities = [] } = currentEvaluation || {};

  const projectEvaluation = {
    ...currentEvaluation,
    activities: sortArrayByAttribute(projectActivities, "indexOrder"),
  };

  return removeIds(projectEvaluation);
};

const removeRestDefaultFields = (plannings = []) => {
  const currentPlannings = _.cloneDeep(plannings);

  currentPlannings.forEach((plan) => {
    if (plan.year !== "") {
      const { monthEvaluations } = plan;
      const newMonthEvaluations = _.cloneDeep(monthEvaluations);
      delete newMonthEvaluations.id;

      Object.keys(newMonthEvaluations).forEach((month) => {
        if (newMonthEvaluations[month]) {
          newMonthEvaluations[month].budget = Number(
            newMonthEvaluations[month].budget
          );

          const { spentMinutes = null, spentHours = null } =
            newMonthEvaluations[month] || {};

          if (!spentMinutes && !spentHours) {
            newMonthEvaluations[month].spent = Number(
              newMonthEvaluations[month].spent
            );
          } else {
            newMonthEvaluations[month].spent =
              Number(spentMinutes) + Number(spentHours) * 60;
          }

          delete newMonthEvaluations[month].spentHours;
          delete newMonthEvaluations[month].spentMinutes;
        }
      });

      plan.monthEvaluations = { ...newMonthEvaluations };
    }
  });

  return currentPlannings;
};

export const handleStageStructureChange = (
  projectEvaluation = {},
  projectConfiguration = {}
) => {
  const { projectStageId = null, projectStage = null } = projectEvaluation;
  const { stages = [] } = projectConfiguration || {};

  if (projectStage?.length > 0) {
    const equivalentStage =
      stages.filter((config) => config.description === projectStage)[0] || {};

    if (!equivalentStage) return;

    const { id = "" } = equivalentStage || {};

    return {
      projectStageId: id,
      projectStage: null,
    };
  }

  return { projectStageId, projectStage };
};

const cleanActivities = (activities = []) => {
  if (activities?.length === 0) return [];

  const newActivities = [];

  activities.forEach((activity, index) => {
    const { description = "" } = activity || {};

    if (description?.length > 0)
      newActivities.push({ ...activity, indexOrder: index });
  });

  return newActivities;
};

const mountCurrentEvaluation = (
  projectEvaluation,
  schedule,
  investment,
  activities,
  effort,
  projectTeam,
  physicalAdvance,
  evaluationID,
  investmentID,
  effortID,
  customMount = false
) => {
  const physicalProgress = getPhysicalProgress(activities);

  const {
    scopeView = null,
    statusReport = "",
    activitiesType = "",
    weightAutomation = false,
    effortAutomation = false,
  } = projectEvaluation || {};

  const { finalDate = "", initialDate = "" } = schedule || {};

  const newActivities = cleanActivities(activities);

  const evaluation = {
    projectName: projectEvaluation.projectName,
    finalDate,
    initialDate,
    justification: projectEvaluation.justification,
    scope: projectEvaluation.scope,
    status: projectEvaluation.status,
    id: evaluationID || "",
    projectStage: projectEvaluation.projectStage,
    projectStageId: projectEvaluation.projectStageId || null,
    physicalProgress: physicalProgress === 0 ? "" : physicalProgress,
    reschedules: schedule.reschedules,
    activities: newActivities,
    activitiesTypeDefault: !!projectEvaluation.activitiesTypeDefault,
    physicalProgressEvolutions: [...mountPhysicalAdvance(physicalAdvance)],
    projectInvestment: {
      plannings: [...mountPlannings(investment.plannings)],
      replannings:
        investment && investment.replannings ? [...investment.replannings] : [],
    },
    projectEffort: {
      plannings: !customMount
        ? [...mountPlannings(effort.plannings)]
        : [...removeRestDefaultFields(effort.plannings)],
      replannings: effort && effort.replannings ? [...effort.replannings] : [],
    },
    peopleInvolved: { ...mountPeopleInvolved(projectTeam) },
    projectConclusion: projectEvaluation.projectConclusion
      ? projectEvaluation.projectConclusion
      : "",
    scopeView,
    statusReport,
    activitiesType,
    weightAutomation,
    effortAutomation,
  };
  // go to rest custom need to be done

  return evaluation;
};

const updatedFirstPlan = (plannings = [], selectedYear = "") => {
  const updatedPlannings = plannings ? [...plannings] : [];
  updatedPlannings.forEach((plan, index) => {
    if (Number(plan.year) === Number(selectedYear)) {
      const updatedPlan = { ...plan, firstPlan: false };
      updatedPlannings[index] = updatedPlan;
    }
  });
  return updatedPlannings;
};

const changeNewPlanReason = (reasons, type, selectedYear, value) => {
  const updatedReasons = reasons && reasons.length > 0 ? [...reasons] : [];
  const currentYear = selectedYear || null;

  updatedReasons.forEach((reason, index) => {
    const year =
      reason && reason.plan && reason.plan.year ? reason.plan.year : 0;

    if (
      type === reason.type &&
      currentYear &&
      year.toString() === currentYear.toString()
    ) {
      updatedReasons[index].reason = value;
    }
  });

  return updatedReasons;
};

const confirmPlan = (reasons, activeStep, boolAction) => {
  const updatedReasons = reasons ? [...reasons] : [];

  if (updatedReasons[activeStep]) {
    updatedReasons[activeStep].confirmPlan = boolAction;
  }

  return updatedReasons;
};

const updateReplannings = (replannings, newReplannings = []) => {
  const updatedReplannings = replannings ? { ...replannings } : {};

  newReplannings.forEach((newReplanning) => {
    const year =
      newReplanning.totalValues && newReplanning.totalValues.year
        ? newReplanning.totalValues.year
        : null;
    if (updatedReplannings[year]) {
      updatedReplannings[year] = [...updatedReplannings[year], newReplanning];
    }
  });
  return updatedReplannings;
};

const mountPotentialPlans = (pendingPlans) => {
  let updatedReasons = [];

  const plansToAccept = pendingPlans || [];

  plansToAccept.forEach((planToAccept) => {
    updatedReasons = [
      ...updatedReasons,
      {
        reason: "",
        confirmPlan: false,
        plan: planToAccept?.plan || null,
        type: planToAccept?.type || null,
        oldPlan: planToAccept?.oldPlan || null,
      },
    ];
  });

  return updatedReasons;
};

const updatePlanningByYear = (
  plannings = [],
  selectedYear = "",
  month = "",
  evaluation = {},
  renderType = ""
) => {
  const updatedPlannings = [...plannings];

  updatedPlannings.forEach((plan, index) => {
    if (plan.year === selectedYear) {
      if (month) {
        updatedPlannings[index].monthEvaluations = {
          ...updatedPlannings[index].monthEvaluations,
          [month]: {
            ...updatedPlannings[index].monthEvaluations[month],
            [evaluation.name]: evaluation.value,
          },
        };
      } else if (renderType === "Investment") {
        updatedPlannings[index].projectInvestment = {
          ...updatedPlannings[index].projectInvestment,
          [evaluation.name]: evaluation.value,
        };
      } else if (renderType === "Efforts") {
        updatedPlannings[index].projectEffort = {
          ...updatedPlannings[index].projectEffort,
          [evaluation.name]: evaluation.value,
        };
      }
    }
  });

  return updatedPlannings;
};

export const isLastSchedulePlan = (reschedules = [], item = {}) => {
  const { deliveryDate = "" } = item || {};

  const lastDate = getCustomDeliveryDate(reschedules, true);

  if (deliveryDate === lastDate) return true;

  return false;
};

export const changeSchedulePlanTrashStatus = (reschedules = [], item = {}) => {
  const { id: itemId = null } = item || {};
  const updatedReschedules = [...reschedules];

  if (!itemId)
    return updatedReschedules.filter((schedule) => !isNaN(schedule.id));

  const changeStatus = (schedule = {}, index = null) => {
    if (schedule.id === itemId)
      updatedReschedules[index] = {
        ...item,
        inTrash: !item.inTrash,
        currentDeadline: false,
      };
  };

  reschedules.forEach((schedule, index) => changeStatus(schedule, index));

  return updatedReschedules;
};

const getScopeViewOptions = () => {
  return [
    {
      value: "ACCORDING",
      label: translation("according"),
      backgroundColor: "green",
      dot: "greenDot",
    },
    {
      value: "IMPACT",
      label: translation("with_impact"),
      backgroundColor: "red",
      dot: "redDot",
    },
    {
      value: "NO_IMPACT",
      label: translation("without_impact"),
      backgroundColor: "yellow",
      dot: "yellowDot",
    },
  ];
};

const getProjectPhaseOptions = () => {
  return [
    {
      value: "PLANEJAMENTO",
      label: `   ${translation("planning")}`,
      backgroundColor: "rgb(255, 192, 0)",
      textColor: "white",
    },
    {
      value: "ANDAMENTO",
      label: translation("ongoing"),
      backgroundColor: "rgb(146, 208, 80)",
      textColor: "white",
    },
    {
      value: "CONCLUIDO",
      label: translation("done"),
      backgroundColor: "rgb(0, 176, 80)",
      textColor: "white",
    },
    {
      value: "STANDBY",
      label: translation("standby"),
      backgroundColor: "#ff4040",
      textColor: "white",
    },
    {
      value: "CANCELADO",
      label: translation("canceled"),
      backgroundColor: "grey",
      textColor: "white",
    },
  ];
};

export const defaultPhysicalPlanning = () => {
  return {
    year: "",
    monthProgress: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  };
};
const defaultPlanning = (valueType = "MONEY") => {
  return {
    year: "",
    monthEvaluations: {
      january: {
        budget: "",
        spentHours: "",
        spentMinutes: "",
        spent: "",
        valueType,
      },
      february: {
        budget: "",
        spentHours: "",
        spentMinutes: "",
        spent: "",
        valueType,
      },
      march: {
        budget: "",
        spentHours: "",
        spentMinutes: "",
        spent: "",
        valueType,
      },
      april: {
        budget: "",
        spentHours: "",
        spentMinutes: "",
        spent: "",
        valueType,
      },
      may: {
        budget: "",
        spentHours: "",
        spentMinutes: "",
        spent: "",
        valueType,
      },
      june: {
        budget: "",
        spentHours: "",
        spentMinutes: "",
        spent: "",
        valueType,
      },
      july: {
        budget: "",
        spentHours: "",
        spentMinutes: "",
        spent: "",
        valueType,
      },
      august: {
        budget: "",
        spentHours: "",
        spentMinutes: "",
        spent: "",
        valueType,
      },
      september: {
        budget: "",
        spentHours: "",
        spentMinutes: "",
        spent: "",
        valueType,
      },
      october: {
        budget: "",
        spentHours: "",
        spentMinutes: "",
        spent: "",
        valueType,
      },
      november: {
        budget: "",
        spentHours: "",
        spentMinutes: "",
        spent: "",
        valueType,
      },
      december: {
        budget: "",
        spentHours: "",
        spentMinutes: "",
        spent: "",
        valueType,
      },
    },
  };
};

const getTeamFieldTitleByIntl = (type) => {
  let title = null;
  const singleSelect = true;
  let value = null;
  if (type === "responsible") {
    value = "Responsible";
    title = translation("choose_responsible");
  } else if (type === "executant") {
    value = "Executant";
    title = translation("choose_executant");
  } else if (type === "member") {
    value = "Member";
    title = translation("choose_member");
  }

  return {
    title,
    value,
    singleSelect,
  };
};

const getDateValidation = (
  initialDate = "0000-00-00",
  finalDate = "0000-00-00"
) => {
  const initial = initialDate.split("-");
  const initialMonth = initial[1];
  const initialDay = initial[2];
  const initialYear = initial[0];

  const final = finalDate.split("-");
  const finalMonth = final[1];
  const finalDay = final[2];
  const finalYear = final[0];

  let validation = true;

  if (initialYear > finalYear) {
    validation = false;
  } else if (initialYear === finalYear) {
    if (initialMonth >= finalMonth) {
      validation = false;
    } else if (initialMonth === finalMonth) {
      if (initialDay > finalDay) {
        validation = false;
      }
    }
  }
  return validation;
};

const getLabelByType = (type) => {
  let iconClass = null;
  let titleLabel = null;
  let firstBox = null;
  let newFirstBox = null;
  let secondBox = null;
  const secondBox2 = null;
  let thirdBox = null;
  const thirdBox2 = null;
  let newThirdBox = null;
  let newSecondBox = null;
  let newFourthBox = null;
  let fourthBox = null;
  let fifthBox = null;
  let sixthBox = null;
  let seventhBox = null;

  if (type === "Schedule") {
    iconClass = "far fa-calendar-alt";
    titleLabel = translation("schedule");
    firstBox = translation("beginDate");
    secondBox = translation("endDate");
    thirdBox = translation("newDate");
    newFirstBox = translation("newDate");
    fourthBox = translation("stage");
  } else if (type === "Efforts") {
    iconClass = "fas fa-hourglass-half";
    titleLabel = translation("effort");
    firstBox = translation("budgetHours");
    newFirstBox = translation("newBudget");
    secondBox = translation("spentTime");
    newSecondBox = translation("newSpentTime");
    thirdBox = translation("balanceHours");
    fourthBox = translation("year");
    newFourthBox = translation("total");
    newThirdBox = translation("newBalance");
    fifthBox = translation("newYear");
    sixthBox = translation("month");
    seventhBox = translation("filterBy");
  } else if (type === "Investment") {
    iconClass = "fas fa-dollar-sign";
    titleLabel = translation("investment");
    firstBox = translation("budget");
    newFirstBox = translation("newBudget");
    secondBox = translation("spent");
    newSecondBox = translation("newSpent");
    thirdBox = translation("balance");
    newFourthBox = translation("total");
    newThirdBox = translation("newBalance");
    fourthBox = translation("year");
    fifthBox = translation("newYear");
    sixthBox = translation("month");
    seventhBox = translation("filterBy");
  }
  return {
    iconClass,
    titleLabel,
    firstBox,
    newFirstBox,
    secondBox,
    newSecondBox,
    secondBox2,
    thirdBox,
    thirdBox2,
    newThirdBox,
    fourthBox,
    fifthBox,
    sixthBox,
    newFourthBox,
    seventhBox,
  };
};

const buildProjectEvaluationRest = (
  projectEvaluation,
  schedule,
  investment,
  activities,
  effort,
  projectTeam,
  physicalAdvance,
  evaluationID = null,
  investmentID = null,
  effortID = null
) => {
  const currentEvaluation = mountCurrentEvaluation(
    projectEvaluation,
    schedule,
    investment,
    activities,
    effort,
    projectTeam,
    physicalAdvance,
    evaluationID,
    investmentID,
    effortID
  );

  const buildedCurrentEvaluation = buildCurrentEvaluation(currentEvaluation);

  return buildedCurrentEvaluation;
};

const getProjectTime = (projectEvaluation) => {
  let planned = 0;
  let realized = 0;

  const {
    initialDate = false,
    finalDate = false,
    projectConclusion = false,
  } = projectEvaluation || {};

  if (!initialDate || !finalDate) return { planned, realized };

  const fInitialDate = moment(initialDate).format("YYYY-MM-DD");

  const currentDate = projectConclusion
    ? moment(projectConclusion).format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD");

  const fFinalDate = moment(finalDate).format("YYYY-MM-DD");

  planned = moment(fFinalDate).diff(fInitialDate, "days");

  realized = moment(currentDate).diff(fInitialDate, "days");

  realized = realized < 0 ? 0 : realized;

  return { planned, realized };
};

const getRealizedActivities = (activities) => {
  let realizedCount = 0;
  activities.forEach((activity) => {
    if (Number(activity.progress) === 100) {
      realizedCount++;
    }
  });
  return realizedCount;
};

export const getProjectActivities = (activities) => {
  let totalPlannedActivities = 0;
  let totalRealizedActivities = 0;
  if (activities && activities.length > 0) {
    totalPlannedActivities = activities.length;
    totalRealizedActivities = getRealizedActivities(activities);
  }

  return { planned: totalPlannedActivities, realized: totalRealizedActivities };
};

export const getPhysicalProgress = (activities = {}) => {
  let physicalAdvance = 0;
  let totalWeight = 0;
  let physicalProgress = 0;
  let weight = 0;
  if (activities && activities.length > 0) {
    activities.forEach((activity) => {
      physicalProgress =
        activity.progress && activity.progress !== ""
          ? Number(activity.progress)
          : null;
      weight =
        activity.weight && activity.weight !== ""
          ? Number(activity.weight)
          : null;

      if (physicalProgress && weight && totalWeight < 100) {
        totalWeight += weight;
        physicalAdvance += physicalProgress * (weight / 100);
      }
    });
  }

  return Number(parseFloat(physicalAdvance).toFixed(0));
};

const getPercentageFinancialProgress = (investment) => {
  let baselineSpent = 0;
  let deadlineSpent = 0;

  const baselineTotal = getTotalProgress(investment, true);
  const deadlineTotal = getTotalProgress(investment, false);

  baselineSpent = Math.round(
    ruleOfThree(baselineTotal.planned, deadlineTotal.realized)
  );
  deadlineSpent = Math.round(
    ruleOfThree(deadlineTotal.planned, deadlineTotal.realized)
  );

  return { baselineSpent, deadlineSpent };
};

export const getFinancialStatus = (investment = {}) => {
  const { baselineSpent } = getPercentageFinancialProgress(investment);

  return getFinancialStatusBySpentPercentage(baselineSpent);
};

const getFinancialStatusBySpentPercentage = (
  baselineSpentPercentage = "",
  intl
) => {
  const translatedStatus = {
    FORA: { status: translation("out"), dot: "redDot" },
    DENTRO: {
      status: translation("in"),
      dot: "greenDot",
    },
    PROXIMO: {
      status: translation("next"),
      dot: "yellowDot",
    },
  };

  let financialStatus = "";

  if (baselineSpentPercentage > 0 && baselineSpentPercentage < 101)
    financialStatus = "DENTRO";
  if (baselineSpentPercentage > 100 && baselineSpentPercentage < 106)
    financialStatus = "PRÓXIMO";
  if (baselineSpentPercentage > 106) financialStatus = "FORA";

  return translatedStatus[financialStatus] || {};
};

export const getFinancialExceeded = (investment) => {
  const { planned: deadlineBudget = 0 } = getTotalProgress(investment, false);
  const { planned: baselineBudget = 0 } = getTotalProgress(investment, true);

  const exceeded = Number(deadlineBudget) - Number(baselineBudget);

  return exceeded;
};

export const getFinancialBalance = (investment) => {
  const { planned: deadlineBudget = 0, realized: deadlineSpent = 0 } =
    getTotalProgress(investment, false);

  const exceeded = getFinancialExceeded(investment);

  const balance =
    Number(deadlineSpent) - (Number(deadlineBudget) + Number(exceeded));

  return balance;
};

const getFinancialProgress = (investment, intl) => {
  let replannedProject =
    investment && investment.replannings
      ? investment.replannings.filter((replan) => replan.reason !== "Baseline")
      : null;

  replannedProject = !!(replannedProject && replannedProject.length > 0);

  const { planned, realized } = getTotalProgress(investment, false);

  const { baselineSpent, deadlineSpent } =
    getPercentageFinancialProgress(investment);

  const deadlineBudget = planned;

  const { planned: baselineBudget } = getTotalProgress(investment, true);

  let exceeded = 0;

  if (replannedProject) {
    exceeded = Number(deadlineBudget) - Number(baselineBudget);
  }

  const { status: financialStatus } = getFinancialStatusBySpentPercentage(
    baselineSpent,
    intl
  );

  return {
    value: {
      planned,
      realized,
      exceeded,
    },
    percentage: {
      baselineSpent,
      deadlineSpent: replannedProject ? deadlineSpent : 0,
    },
    financialStatus,
  };
};

const getActivitiesEffortInfo = (activities = []) => {
  let planned = 0;
  let realized = 0;

  activities.forEach((activity) => {
    const { effort = {} } = activity || {};
    const { foreseen = 0, achieved = 0 } = effort || {};

    if (!isNaN(foreseen)) planned += Number(foreseen);
    if (!isNaN(achieved)) realized += Number(achieved);
  });

  return { planned, realized };
};

const getEffortProgress = (activities) => {
  const { planned, realized } = getActivitiesEffortInfo(activities);

  const effortPercentage = Math.round(ruleOfThree(planned, realized));

  return {
    value: {
      planned,
      realized,
    },
    percentage: { result: effortPercentage },
  };
};

const getScheduleProgress = (projectEvaluation = {}) => {
  const { reschedules = null, finalDate = null } = projectEvaluation || {};

  const customDeadline = getRealDeadline(reschedules);
  const deadline = customDeadline || finalDate;

  const labeledSchedule = getLabeledSchedule(projectEvaluation, deadline);

  const schedulePercentage = getSchedulePercentage(labeledSchedule);

  return schedulePercentage;
};

const getProjectAdvance = (projectEvaluation, intl) => {
  return {
    physical: getPhysicalProgress(projectEvaluation.activities),
    financial: getFinancialProgress(projectEvaluation.projectInvestment, intl),
    effort: getEffortProgress(projectEvaluation.activities),
    schedule: getScheduleProgress(projectEvaluation),
  };
};

export const translateProjectStatus = (projectStatus) => {
  const currentProjectStatus = projectStatus?.toUpperCase() || "";

  if (currentProjectStatus === "FORA") {
    return translation("out");
  }
  if (currentProjectStatus === "PROXIMO") {
    return translation("next");
  }
  if (currentProjectStatus === "DENTRO") {
    return translation("in");
  }
};

export const renderProjectPortfolioLabel = () => {
  return (
    <>
      <th style={{ textAlign: "left" }}>{translation("scenerie")}</th>
      <th style={{ textAlign: "left" }}>{translation("theme")}</th>
      <th style={{ textAlign: "left" }}>{translation("group")}</th>
      <th style={{ textAlign: "left" }}>{translation("project")}</th>
    </>
  );
};

export const translateProjectStage = (stage) => {
  const currentStage = stage;

  if (!currentStage || currentStage === null) return "";

  if (currentStage.toUpperCase() === "CONCLUIDO") {
    return translation("done");
  }
  if (currentStage.toUpperCase() === "PLANEJAMENTO") {
    return translation("planning");
  }
  if (currentStage.toUpperCase() === "ANDAMENTO") {
    return translation("ongoing");
  }
  if (currentStage.toUpperCase() === "STANDBY") {
    return translation("standby");
  }
  if (currentStage.toUpperCase() === "CANCELADO") {
    return translation("canceled");
  }

  return stage;
};

export const getProjectStatus = (currentProjectEvaluation = {}) => {
  let projectStatus = null;

  const { projectConclusion = null, reschedules = null } =
    currentProjectEvaluation;

  const realDeadline = getRealDeadline(reschedules);

  const finalDate = currentProjectEvaluation.finalDate || null;

  const deadline = realDeadline || finalDate;

  if (deadline) {
    const endDate = moment(deadline).format("YYYY-MM-DD");

    const currentDate = projectConclusion || moment().format("YYYY-MM-DD");

    const statusCalc =
      currentDate && endDate ? moment(endDate).diff(currentDate, "days") : "";

    if (statusCalc < 0) {
      projectStatus = "FORA";
    } else if (statusCalc >= 0 && statusCalc <= 16) {
      projectStatus = projectConclusion ? "DENTRO" : "PROXIMO";
    } else if (statusCalc > 16) {
      projectStatus = "DENTRO";
    }

    return translateProjectStatus(projectStatus).toUpperCase();
  }
};

export const getScopeStatus = (projectEvaluation) => {
  const scopeOptions = getScopeViewOptions();

  return (
    scopeOptions.find(({ value }) => value === projectEvaluation?.scopeView) ||
    {}
  );
};

const getFinalProjectConclusion = (projectConclusion = null) => {
  if (projectConclusion) return getPatternDateFormat(projectConclusion);

  return translation("not_concluded");
};

const calcIndicators = (projectEvaluation = null) => {
  const {
    activities: currentProjectActivities = [],
    projectStage = false,
    projectStageRelation = false,
  } = projectEvaluation || [];

  const realProjectStatus = getProjectStatus(projectEvaluation);
  const { label: scope = "" } = getScopeStatus(projectEvaluation);
  const financial = "";

  const { sonDescription = false, parentDescription = false } =
    getStageParentInfo(projectStageRelation);

  const stage = parentDescription || sonDescription || projectStage;

  return {
    projectTime: getProjectTime(projectEvaluation && projectEvaluation),
    projectActivities: getProjectActivities(currentProjectActivities),
    projectAdvance: getProjectAdvance(projectEvaluation),
    projectStage: translateProjectStage(stage),
    projectStatus: realProjectStatus,
    projectConclusion: getFinalProjectConclusion(
      projectEvaluation.projectConclusion
    ),
    scope,
    financial,
  };
};

export const getProjectData = (evaluation = {}) => {
  const { projectEvaluation = {} } = evaluation || {};

  const projectStatus = getProjectStatus(projectEvaluation, null);

  const {
    finalDate = null,
    reschedules = [],
    physicalProgress = 0,
  } = projectEvaluation || {};
  const projectConclusion = getRealDeadline(projectEvaluation.reschedules);

  /*
  const customDeadline = projectConclusion
  ? projectConclusion
  : getRealDeadline(reschedules);
  */

  const deadline = projectConclusion || finalDate;

  const deliveryDate = getPatternDateFormat(deadline);

  const replanned =
    reschedules.filter(({ inTrash }) => inTrash !== true).length > 1;

  return {
    physicalProgress: physicalProgress || 0,
    deliveryDate,
    replanned,
    projectStatus,
  };
};

const getInsightProjectEvaluation = (insightID, allProjectEvaluation) => {
  const allEvaluationCopy = _.cloneDeep(allProjectEvaluation);
  let evaluation = null;

  if (!_.isEmpty(allEvaluationCopy)) {
    allEvaluationCopy.forEach((evalInfo) => {
      if (parseInt(evalInfo.insightId, 10) === parseInt(insightID, 10))
        evaluation = evalInfo;
    });
  }

  return evaluation;
};

const getInitialIndicators = () => {
  const projectIndicators = {
    projectTime: {
      planned: "",
      realized: "",
    },
    projectActivities: {
      planned: "",
      realized: "",
    },
    projectAdvance: {
      physical: "",
      financial: {
        value: { planned: "", realized: "" },
        percentage: { planned: "", realized: "" },
      },
      effort: {
        value: { planned: "", realized: "" },
        percentage: { planned: "", realized: "" },
      },
    },
    projectStage: "",
    projectStatus: "",
    projectConclusion: translation("not_concluded"),
    scope: "",
    financial: "",
  };

  return projectIndicators;
};

const getSelectedPlan = (plannings = [], selectedYear = 0) => {
  let defaultPlanning = null;

  plannings.forEach((plan) => {
    if (Number(plan.year) === Number(selectedYear)) {
      defaultPlanning = plan;
    }
  });

  return defaultPlanning;
};

const getTotalValues = (
  plannings = [],
  selectedYear = null,
  isReplannings = false
) => {
  let totalValues = null;

  if (selectedYear) {
    const selectedPlan = !isReplannings
      ? getSelectedPlan(plannings, selectedYear)
      : getLastReplanningByYear(plannings, selectedYear);

    totalValues = getPlanTotalValues(selectedPlan);
    return totalValues;
  }

  return getOverallTotalValues(plannings);
};

const getPlanTotalValues = (selectedPlan) => {
  let totalBudget = null;
  let totalSpent = null;
  let totalSpentHours = null;
  let totalSpentMinutes = null;
  let currentSpentHours = null;
  let currentSpentMinutes = null;
  let isInvestment = null;

  const monthEvaluations =
    selectedPlan && selectedPlan.monthEvaluations
      ? selectedPlan.monthEvaluations
      : {};

  Object.keys(monthEvaluations).forEach((month) => {
    if (monthEvaluations[month]) {
      const valueType = monthEvaluations[month].valueType || "";
      isInvestment = valueType === "MONEY";

      let currentBudget =
        monthEvaluations[month].budget && monthEvaluations[month].budget !== ""
          ? monthEvaluations[month].budget
          : 0;

      let currentSpent =
        monthEvaluations[month].spent && monthEvaluations[month].spent !== ""
          ? monthEvaluations[month].spent
          : 0;

      if (typeof currentBudget === "string") {
        currentBudget = Number(currentBudget.replace(/,/g, ""));
      }
      if (typeof currentSpent === "string") {
        currentSpent = Number(currentSpent.replace(/,/g, ""));
      }
      if (!isInvestment) {
        if (
          monthEvaluations[month].spentHours ||
          monthEvaluations[month].spentMinutes
        ) {
          currentSpentHours =
            monthEvaluations[month].spentHours &&
            monthEvaluations[month].spentHours !== ""
              ? monthEvaluations[month].spentHours
              : null;

          currentSpentMinutes =
            monthEvaluations[month].spentMinutes &&
            monthEvaluations[month].spentMinutes !== ""
              ? monthEvaluations[month].spentMinutes
              : null;
        } else {
          const minutesInfo = getAdditionalSpentInfo(currentSpent);
          currentSpentHours =
            minutesInfo && minutesInfo.extraHours
              ? minutesInfo.extraHours
              : null;
          currentSpentMinutes =
            minutesInfo && minutesInfo.leftMinutes
              ? minutesInfo.leftMinutes
              : null;
        }
      }

      totalBudget += Number(currentBudget);
      totalSpent += Number(currentSpent);
      totalSpentHours += currentSpentHours ? Number(currentSpentHours) : 0;
      totalSpentMinutes += currentSpentMinutes
        ? Number(currentSpentMinutes)
        : 0;
    }
  });
  // const { extraHours, leftMinutes } = getAdditionalSpentInfo(totalSpentMinutes);;
  const minutesInfo =
    totalSpentMinutes && totalSpentMinutes > 0
      ? getAdditionalSpentInfo(totalSpentMinutes)
      : null;
  const extraHours =
    minutesInfo && minutesInfo.extraHours ? minutesInfo.extraHours : null;
  const leftMinutes =
    minutesInfo && minutesInfo.leftMinutes ? minutesInfo.leftMinutes : null;
  const newSpentHours = extraHours
    ? totalSpentHours + extraHours
    : totalSpentHours;
  let newSpentMinutes = leftMinutes || totalSpentMinutes;

  newSpentMinutes = newSpentMinutes === 0 ? "00" : newSpentMinutes;

  return {
    totalBudget,
    totalSpent,
    totalSpentHours: !isInvestment ? newSpentHours : null,
    totalSpentMinutes: !isInvestment ? newSpentMinutes : null,
    totalSpentTime: !isInvestment
      ? `${newSpentHours}:${newSpentMinutes}`
      : null,
  };
};

const getOverallTotalValues = (plannings) => {
  let totalOverallBudget = null;
  let totalOverallSpent = null;
  let totalOverallSpentMinutes = null;
  let totalOverallSpentTime = null;
  let newSpentHours = null;
  let overallEffort = false;

  const currentPlannings = plannings && plannings.length > 0 ? plannings : [];

  currentPlannings.forEach((plan) => {
    const totalValues = getPlanTotalValues(plan);

    totalOverallBudget +=
      totalValues && totalValues.totalBudget ? totalValues.totalBudget : 0;

    totalOverallSpent +=
      totalValues && totalValues.totalSpentHours
        ? Number(totalValues.totalSpentHours)
        : totalValues.totalSpent
        ? Number(totalValues.totalSpent)
        : Number(0);

    totalOverallSpentMinutes +=
      totalValues &&
      totalValues.totalSpentMinutes &&
      totalValues.totalSpentMinutes !== null
        ? Number(totalValues.totalSpentMinutes)
        : Number(0);

    if (totalValues && totalValues.totalSpentHours) {
      overallEffort = true;
    }
  });

  if (overallEffort) {
    const minutesInfo = getAdditionalSpentInfo(totalOverallSpentMinutes);

    const extraHours =
      minutesInfo && minutesInfo.extraHours ? minutesInfo.extraHours : null;

    const leftMinutes =
      minutesInfo && minutesInfo.leftMinutes ? minutesInfo.leftMinutes : null;

    newSpentHours = extraHours
      ? totalOverallSpent + extraHours
      : totalOverallSpent;

    let newSpentMinutes = leftMinutes || totalOverallSpentMinutes;
    newSpentMinutes = newSpentMinutes === 0 ? "00" : newSpentMinutes;
    totalOverallSpentTime = `${newSpentHours}:${newSpentMinutes}`;
  }
  return {
    totalBudget: totalOverallBudget,
    totalSpent: totalOverallSpent,
    totalSpentTime: totalOverallSpentTime,
  };
};

const getInitialStates = () => {
  const projectEvaluation = {
    finalDate: "",
    id: "",
    initialDate: "",
    justification: "",
    falseFinalDate: "",
    scope: "",
    status: "",
    projectStage: "",
    physicalProgress: "",
    reschedules: [],
    activities: [],
    projectInvestment: {
      plannings: [
        {
          monthEvaluations: {
            id: "",
            january: { budget: "", spent: "", valueType: "MONEY" },
            february: { budget: "", spent: "", valueType: "MONEY" },
            march: { budget: "", spent: "", valueType: "MONEY" },
            april: { budget: "", spent: "", valueType: "MONEY" },
            may: { budget: "", spent: "", valueType: "MONEY" },
            june: { budget: "", spent: "", valueType: "MONEY" },
            july: { budget: "", spent: "", valueType: "MONEY" },
            august: { budget: "", spent: "", valueType: "MONEY" },
            september: { budget: "", spent: "", valueType: "MONEY" },
            october: { budget: "", spent: "", valueType: "MONEY" },
            november: { budget: "", spent: "", valueType: "MONEY" },
            december: { budget: "", spent: "", valueType: "MONEY" },
          },
          projectInvestment: { budget: "", spent: "" },
          year: "",
        },
      ],
      replannings: [],
    },
    projectEffort: {
      plannings: [
        {
          monthEvaluations: {
            id: "",
            january: { budget: "", spent: "", valueType: "HOURS" },
            february: { budget: "", spent: "", valueType: "HOURS" },
            march: { budget: "", spent: "", valueType: "HOURS" },
            april: { budget: "", spent: "", valueType: "HOURS" },
            may: { budget: "", spent: "", valueType: "HOURS" },
            june: { budget: "", spent: "", valueType: "HOURS" },
            july: { budget: "", spent: "", valueType: "HOURS" },
            august: { budget: "", spent: "", valueType: "HOURS" },
            september: { budget: "", spent: "", valueType: "HOURS" },
            october: { budget: "", spent: "", valueType: "HOURS" },
            november: { budget: "", spent: "", valueType: "HOURS" },
            december: { budget: "", spent: "", valueType: "HOURS" },
          },
          year: "",
        },
      ],
      replannings: [],
    },
    physicalAdvance: {
      plannings: [
        {
          year: "",
          monthProgress: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
    },

    peopleInvolved: {
      responsibleMember: "",
      responsibleParticipant: "",
      executantMember: "",
      executantParticipant: "",
      involved: [],
    },
  };
  return {
    projectEvaluation,
  };
};

const getEffortTotalValues = (effort, selectedYear) => {
  let totalBalance = null;
  const totalValues =
    effort && effort.plannings
      ? getTotalValues(effort.plannings, selectedYear)
      : null;

  const totalBudget =
    totalValues && totalValues.totalBudget ? totalValues.totalBudget : null;
  const totalSpentHours =
    totalValues && totalValues.totalSpentHours
      ? totalValues.totalSpentHours
      : 0;

  const totalSpentTime =
    totalValues && totalValues.totalSpentTime ? totalValues.totalSpentTime : "";

  totalBalance = totalBudget - totalSpentHours;

  return { totalBudget, totalSpent: totalSpentTime, totalBalance };
};

const findBackupMonthEvaluation = (currentPlannings = [], currentYear = "") => {
  let backupEvaluation = {};

  currentPlannings.forEach(({ year = "", monthEvaluations = "" }) => {
    if (year === currentYear) {
      backupEvaluation = { ...monthEvaluations };
    }
  });

  return backupEvaluation;
};

const updateAlteredPlans = (currentInvestment, alteredReplannings) => {
  const updatedInvestment = { ...currentInvestment };

  alteredReplannings.forEach((plan) => {
    const currentPlan = plan && plan.plan ? plan.plan : null;
    delete currentPlan.monthEvaluations.id;

    if (Array.isArray(updatedInvestment.replannings)) {
      const dateTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");

      delete currentPlan.monthEvaluations.id;

      const object = {
        id: null,
        monthEvaluations: currentPlan.monthEvaluations,
        year: currentPlan.year,
        reason: plan.reason,
        dateTime,
      };

      const [updatedReplaningLine] = mountPlannings([object]);
      updatedInvestment.replannings = [
        ...updatedInvestment.replannings,
        updatedReplaningLine,
      ];
    }
  });

  return { updatedInvestment };
};

export const handleAlteredPlans = (currentInvestment, newReplannings) => {
  const alteredReplannings =
    newReplannings && newReplannings.length > 0 ? newReplannings : null;

  const updatedPlans = alteredReplannings
    ? updateAlteredPlans(currentInvestment, alteredReplannings)
    : null;

  const updatedInvestment =
    updatedPlans && updatedPlans.updatedInvestment
      ? updatedPlans.updatedInvestment
      : [];

  return { updatedInvestment };
};

const mountReplanBaselineObject = (plannings) => {
  let replannings = [];
  const currentTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");

  let allBudgets = 0;

  const totalInvestments = getTotalInvestments(plannings);

  totalInvestments.forEach((totalInvestment) => {
    const { totalBudget = 0 } = totalInvestment || {};

    allBudgets += totalBudget;
  });

  if (allBudgets > 0) {
    plannings.forEach((plan) => {
      const planMonthEvaluations =
        plan && plan.monthEvaluations ? plan.monthEvaluations : {};
      const planYear = plan && plan.year ? plan.year : 0;

      delete planMonthEvaluations.id;

      replannings = [
        ...replannings,
        {
          id: null,
          monthEvaluations: { ...planMonthEvaluations },
          reason: "Baseline",
          year: planYear,
          dateTime: currentTime,
        },
      ];
    });
  }

  replannings = mountPlannings(replannings);
  return replannings;
};
export const handleFirstPlan = (currentInvestment) => {
  let updatedInvestment = null;

  const investments = currentInvestment ? _.cloneDeep(currentInvestment) : null;

  const investmentPlannings =
    investments && investments.plannings
      ? _.cloneDeep(investments.plannings)
      : [];

  const investmentReplannings = mountReplanBaselineObject(investmentPlannings);

  updatedInvestment = {
    plannings: investmentPlannings,
    replannings: [...investmentReplannings],
  };

  return { updatedInvestment };
};

const getStatusProjectByClassName = (className) => {
  let status = "";

  if (className === "redDot") {
    status = "Fora";
  }

  if (className === "greyDot") {
    status = "Não programado";
  }

  if (className === "greenDot") {
    status = "Dentro";
  }

  if (className === "yellowDot") {
    status = "Próximo";
  }

  return status;
};

const getStatus = (plannings) => {
  let changed = false;

  plannings.forEach((plan) => {
    const monthEvaluation =
      plan && plan.monthEvaluations ? plan.monthEvaluations : {};
    Object.keys(monthEvaluation).forEach((monthName) => {
      if (monthEvaluation[monthName]) {
        if (
          (monthEvaluation[monthName].budget !== null &&
            monthEvaluation[monthName].budget !== "" &&
            monthEvaluation[monthName].budget !== 0) ||
          (monthEvaluation[monthName].spent !== null &&
            monthEvaluation[monthName].spent !== "" &&
            monthEvaluation[monthName].spent !== 0)
        ) {
          changed = true;
        }
      }
    });
  });

  return changed;
};
const whoHaveValues = (investment = null) => {
  const currentInvestment = investment?.plannings || null;

  return currentInvestment ? getStatus(currentInvestment) : false;
};

export const compareDates = (firstDate = null, secondDate = null) => {
  const dateOne = firstDate || null;
  const dateTwo = secondDate && dateOne ? secondDate : null;

  const isAfter = dateTwo ? moment(firstDate).isAfter(secondDate) : true;

  return isAfter;
};

const getBiggestDate = (replannings = null, year = null) => {
  const currentReplannings = replannings || [];
  let biggestDate = null;
  const currentYear = year || null;

  currentReplannings.forEach((plan) => {
    if (currentYear === plan.year) {
      const date = plan.dateTime ? plan.dateTime : null;
      const formattedDate = date
        ? moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null;

      const isValid = formattedDate ? moment(formattedDate).isValid() : null;
      const isAfter = isValid
        ? !biggestDate
          ? true
          : compareDates(formattedDate, biggestDate)
        : null;
      biggestDate = isAfter ? formattedDate : biggestDate;
    }
  });

  return moment(biggestDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
};

export const getLastReplanningByYear = (replannings = null, year = null) => {
  const currentReplannings = replannings || [];
  let biggestDate = null;
  let lastReplanning = null;

  biggestDate = getBiggestDate(replannings, year);

  lastReplanning = currentReplannings.filter(
    (plan) =>
      plan.year === year &&
      moment(plan.dateTime).format("YYYY-MM-DDTHH:mm:ss.SSS") === biggestDate
  );

  return lastReplanning[0];
};

export const removeEffortExtraFields = (monthEvaluations) => {
  const currentMonthEvaluations = monthEvaluations || {};

  Object.keys(currentMonthEvaluations).forEach((month) => {
    if (monthEvaluations[month]) {
      delete currentMonthEvaluations[month].spentHours;
      delete currentMonthEvaluations[month].spentMinutes;
    }
  });

  return currentMonthEvaluations;
};

const cleanCurrentDiff = (diff = {}) => {
  const currentDiff = diff || {};

  Object.keys(currentDiff).forEach((month) => {
    if (currentDiff[month]) {
      if (currentDiff[month] && !currentDiff[month].budget) {
        delete currentDiff[month];
      } else if (
        currentDiff[month].budget === "" ||
        currentDiff[month].budget === 0
      ) {
        delete currentDiff[month];
      } else {
        delete currentDiff[month].spent;
      }
    }
  });

  return currentDiff;
};

const getDiff = (plannings, replannings, type) => {
  let currentDiff = [];

  plannings.forEach((plan) => {
    const planYear = plan && plan.year ? plan.year : null;

    const lastYearReplanning = planYear
      ? getLastReplanningByYear(replannings, planYear)
      : null;

    const newMonthEvaluations = removeEffortExtraFields(plan.monthEvaluations);

    const lastReplanningMonthEvaluations =
      lastYearReplanning && lastYearReplanning.monthEvaluations
        ? lastYearReplanning.monthEvaluations
        : {};

    let diff = getObjectDifference(
      newMonthEvaluations,
      lastReplanningMonthEvaluations
    );

    if (diff) delete diff.id;

    let size = diff ? Object.keys(diff).length : 0;
    diff = size > 0 ? cleanCurrentDiff(diff) : diff;
    size = diff ? Object.keys(diff).length : 0;

    if (size > 0) {
      const oldPlan = lastYearReplanning;
      currentDiff = [...currentDiff, { diff, plan, oldPlan, type }];
    }

    /*
      let dateTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
      const currentPlan = _.cloneDeep(plan);

      delete currentPlan.monthEvaluations.id;

      const object = {
        id: null,
        monthEvaluations: currentPlan.monthEvaluations,
        year: currentPlan.year,
        reason: "Baseline",
        dateTime
      };

      const newObject = mountPlannings([object]);
      replannings = [
        ...replannings,
        newObject[0]
      ];
      */
  });

  return currentDiff;
};
const getDifferences = (
  currentIPlannings = null,
  currentIReplannings = null
) => {
  const currentIPlans = currentIPlannings || null;
  const currentIReplans = currentIReplannings || null;

  const investments =
    currentIPlans && currentIReplans
      ? getDiff(currentIPlans, currentIReplans, "Investment")
      : null;

  return { investments };
};

const mountFirstPlan = (investment = null) => {
  if (!investment) return {};

  const investmentChanges = whoHaveValues(investment);

  const investmentInfo = {
    isFirstPlan: true,
    changed: investmentChanges,
    alteredPlans: [],
  };

  if (!investmentChanges) delete investmentInfo.isFirstPlan;

  return investmentInfo;
};

const transformCurrentPlannings = (plannings) => {
  const updatedPlannings = _.cloneDeep(plannings) || [];

  updatedPlannings.forEach((plan) => {
    const { monthEvaluations } = plan;
    const newMonthEvaluations = _.cloneDeep(monthEvaluations);
    let month_aux = null;

    Object.keys(newMonthEvaluations).forEach((monthName, index) => {
      if (newMonthEvaluations[monthName]) {
        const valueType = newMonthEvaluations[monthName].valueType || "";
        const isInvestment = valueType === "MONEY";
        if (newMonthEvaluations[monthName].budget) {
          if (isInvestment) {
            month_aux = newMonthEvaluations[monthName].budget;
            if (typeof month_aux === "string") {
              newMonthEvaluations[monthName].budget = Number(
                month_aux.replace(/,/g, "")
              );
            }
          } else {
            month_aux = Number(newMonthEvaluations[monthName].budget);
            newMonthEvaluations[monthName].budget = month_aux;
          }
        }

        if (newMonthEvaluations[monthName].spent) {
          if (isInvestment) {
            month_aux = newMonthEvaluations[monthName].spent;
            if (typeof month_aux === "string") {
              newMonthEvaluations[monthName].spent = Number(
                month_aux.replace(/,/g, "")
              );
            }
          } else {
            month_aux = Number(newMonthEvaluations[monthName].spent);
            newMonthEvaluations[monthName].spent = month_aux;
          }
        }
      }
    });

    plan.monthEvaluations = { ...newMonthEvaluations };
  });

  return updatedPlannings;
};

const mountAlteredPlans = (currentInvestment) => {
  if (!currentInvestment) return null;

  const { plannings = [], replannings: currentReplannings = null } =
    currentInvestment || {};

  const currentPlannings = transformCurrentPlannings(plannings);

  const changes = getDifferences(currentPlannings, currentReplannings);

  const investmentChanged = changes?.investments?.length > 0;

  const investmentInfo = {
    changed: investmentChanged,
    alteredPlans: changes.investments,
  };

  if (!investmentChanged) delete investmentInfo.alteredPlans;

  return investmentInfo;
};

const whoChanged = (currentInvestment) => {
  const investmentReplannings =
    currentInvestment?.replannings?.length > 0 && currentInvestment.replannings;

  const investmentInfo = investmentReplannings
    ? mountAlteredPlans(currentInvestment)
    : mountFirstPlan(currentInvestment);

  return {
    investmentInfo,
  };
};

const getTotalInvestments = (plannings) => {
  let totalBudget = 0;
  let totalSpent = null;
  let totalBalance = null;
  let totalValues = [];

  plannings.forEach((plan) => {
    if (plan.year !== "" && plan.monthEvaluations) {
      totalBalance = 0;
      totalSpent = 0;
      totalBudget = 0;

      const { monthEvaluations } = plan;

      Object.keys(monthEvaluations).forEach((monthName, index) => {
        if (monthEvaluations[monthName]) {
          if (
            monthEvaluations[monthName].budget &&
            monthEvaluations[monthName].budget !== ""
          ) {
            let evaluation = null;
            const aux = _.cloneDeep(monthEvaluations[monthName].budget);

            if (typeof aux === "string") {
              evaluation = Number(aux.replace(/,/g, ""));
            }

            totalBudget += evaluation || aux;
          }
          if (
            monthEvaluations[monthName].spent &&
            monthEvaluations[monthName].spent !== ""
          ) {
            let evaluation = null;

            if (typeof monthEvaluations[monthName].spent === "string") {
              evaluation = Number(
                monthEvaluations[monthName].spent.replace(/,/g, "")
              );
            }

            totalSpent += evaluation || monthEvaluations[monthName].spent;
          }
        }
      });
      totalBalance = totalBudget - totalSpent;

      totalValues = [
        ...totalValues,
        {
          totalBudget,
          totalSpent,
          totalBalance,
          year: plan.year,
        },
      ];
    }
  });
  return totalValues;
};

export const getAdditionalSpentInfo = (totalSpentMinutes) => {
  let extraHours = 0;
  let leftMinutes = 0;
  const getAdditionalHours = (totalSpentMinutes / 60).toString();
  const minutesAux = getAdditionalHours.split(".");
  extraHours = Number(minutesAux[0]);
  const minutesLeft = 60 * extraHours;
  leftMinutes = totalSpentMinutes - minutesLeft;

  return {
    extraHours,
    leftMinutes,
  };
};

const getTotalEfforts = (plannings) => {
  let totalBudget = 0;
  let totalSpentHours = null;
  let totalSpentMinutes = null;
  let totalBalanceHours = null;
  let totalBalanceMinutes = 0;
  let totalSpentTime = 0;
  let totalValues = [];
  let totalBalanceTime = 0;

  plannings.forEach((plan) => {
    if (plan.year !== "" && plan.monthEvaluations) {
      totalBudget = 0;
      totalSpentHours = 0;
      totalSpentMinutes = 0;
      totalSpentTime = 0;
      totalBalanceHours = 0;
      totalBalanceMinutes = 0;
      totalBalanceTime = 0;

      const { monthEvaluations } = plan;
      Object.keys(monthEvaluations).forEach((monthName, index) => {
        if (monthEvaluations[monthName]) {
          if (
            monthEvaluations[monthName].budget &&
            monthEvaluations[monthName].budget !== ""
          ) {
            const evaluation = monthEvaluations[monthName].budget;

            totalBudget += Number(evaluation);
          }
          if (
            monthEvaluations[monthName].spentHours &&
            monthEvaluations[monthName].spentHours !== ""
          ) {
            const evaluation = monthEvaluations[monthName].spentHours;
            totalSpentHours += Number(evaluation);
          }
          if (
            monthEvaluations[monthName].spentMinutes &&
            monthEvaluations[monthName].spentMinutes !== ""
          ) {
            const evaluation = monthEvaluations[monthName].spentMinutes;
            totalSpentMinutes += Number(evaluation);
          }
        }
      });
      const { extraHours, leftMinutes } =
        getAdditionalSpentInfo(totalSpentMinutes);

      totalSpentHours += Number(extraHours);
      totalSpentMinutes = Number(leftMinutes);

      totalBalanceHours = totalBudget - totalSpentHours;
      if (totalSpentMinutes === 0 || totalSpentMinutes === "0") {
        totalSpentMinutes = "00";
      }
      totalSpentTime = `${totalSpentHours}:${totalSpentMinutes}`;
      totalBalanceMinutes = totalSpentMinutes;
      totalBalanceTime = `${totalBalanceHours}:${totalBalanceMinutes}`;
      totalValues = [
        ...totalValues,
        {
          totalBudget,
          totalSpentHours,
          totalSpentMinutes,
          totalSpentTime,
          totalBalanceHours,
          totalBalanceMinutes,
          totalBalanceTime,
          year: plan.year,
        },
      ];
    }
  });
  return totalValues;
};

const defaultActivityData = () => {
  return {
    id: "",
    description: "",
    progress: "",
    weight: "",
    idChecklist: null,
    activities: {
      expected: 0,
      achieved: 0,
    },
    effort: {
      foreseen: 0,
      achieved: 0,
    },
    deliveryDate: null,
    expectedDate: null,
    responsible: {
      id: 0,
      type: null,
    },
    note: "",
  };
};

export const getRestAcitivityInfo = (activityInfo, newIndexOrder = 0) => {
  const {
    id,
    description,
    progress,
    active,
    weight,
    expectedActivities,
    achievedActivities,
    foreseenEffort,
    achievedEffort,
    activityDeliveryDate,
    activityExpectedDate,
    activityResponsible,
    note,
    idChecklist,
  } = getActivityValidInfo(activityInfo);

  return {
    id,
    description,
    progress,
    weight,
    idChecklist,
    activities: {
      expected: expectedActivities,
      achieved: achievedActivities,
    },
    effort: {
      foreseen: foreseenEffort,
      achieved: achievedEffort,
    },
    deliveryDate: activityDeliveryDate,
    expectedDate: activityExpectedDate,
    responsible: activityResponsible,
    indexOrder: newIndexOrder,
    active,
    note,
  };
};

export const getRestActivities = (activities = []) => {
  const restActivities = [];

  activities.forEach((activityInfo) => {
    const {
      id,
      description,
      progress,
      weight,
      activities,
      effort,
      deliveryDate,
      responsible,
      indexOrder,
      expectedDate,
      active,
      note,
      idChecklist,
    } = getRestAcitivityInfo(activityInfo, activityInfo.indexOrder);

    restActivities.push({
      id,
      description,
      progress,
      weight,
      activities,
      effort,
      deliveryDate: deliveryDate || null,
      expectedDate: expectedDate || null,
      responsible: responsible && responsible.id ? responsible : null,
      indexOrder,
      active,
      note,
      idChecklist: idChecklist || null,
    });
  });

  return restActivities;
};

export const getActivityValidInfo = (activityInfo = {}) => {
  const activities = activityInfo.activities || {};
  const expectedActivities = activities.expected || "";
  const achievedActivities = activities.achieved || "";

  const effort = activityInfo.effort || {};
  const foreseenEffort = effort.foreseen || "";
  const achievedEffort = effort.achieved || "";
  const idChecklist = activityInfo.idChecklist || null;

  const activityDeliveryDate = activityInfo.deliveryDate || null;
  const activityExpectedDate = activityInfo.expectedDate || null;
  const activityResponsible = activityInfo.responsible || {};
  const indexOrder = activityInfo.indexOrder || 0;
  const { active } = activityInfo;

  return {
    ...activityInfo,
    activities,
    expectedActivities,
    achievedActivities,
    effort,
    foreseenEffort,
    achievedEffort,
    activityDeliveryDate,
    activityResponsible,
    activityExpectedDate,
    indexOrder,
    active,
    idChecklist,
  };
};

const mountSchedule = (schedule) => {
  const { reschedules = [] } = schedule || {};

  if (reschedules.filter(({ inTrash }) => inTrash !== true).length > 0) {
    return { ...schedule, deliveryDateSet: true };
  }

  return schedule;
};

const getGenericMonthProgress = () => {
  return [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1];
};

const unmountPhysicalAdvance = (physicalAdvance) => {
  const currentPhysicalAdvance = physicalAdvance || [];
  let newPhysicalAdvance = [];
  let genericMonthProgress = [...getGenericMonthProgress()];

  currentPhysicalAdvance.forEach((yearAdvance) => {
    const year = yearAdvance.year || 0;
    const monthEvaluations = (yearAdvance && yearAdvance.months) || [];

    monthEvaluations.forEach((monthAdvance) => {
      const currentMonth = monthAdvance.month;
      const currentValue = monthAdvance.value;

      genericMonthProgress[currentMonth - 1] = currentValue;
    });

    newPhysicalAdvance = [
      ...newPhysicalAdvance,
      { year, monthProgress: [...genericMonthProgress] },
    ];
    genericMonthProgress = [...getGenericMonthProgress()];
  });

  return newPhysicalAdvance;
};

export const transformEffortMonthEvaluations = (monthEvaluations) => {
  const currentMonthEvaluations = { ...monthEvaluations } || {};

  Object.keys(currentMonthEvaluations).forEach((month) => {
    if (currentMonthEvaluations[month] && month !== "id") {
      if (
        currentMonthEvaluations[month].spent &&
        currentMonthEvaluations[month].spentHours === null &&
        currentMonthEvaluations[month].spentMinutes === null
      ) {
        const currentMonthSpent = currentMonthEvaluations[month].spent;
        const { extraHours, leftMinutes } =
          getAdditionalSpentInfo(currentMonthSpent);

        currentMonthEvaluations[month].spentHours = extraHours;
        currentMonthEvaluations[month].spentMinutes = leftMinutes;
      }
    }
  });

  return currentMonthEvaluations;
};

const mountProjectEffort = (projectEffort) => {
  const currentProjectEffort = projectEffort || {};

  const plannings =
    currentProjectEffort && currentProjectEffort.plannings
      ? currentProjectEffort.plannings
      : [];

  let updatedPlannings = [];

  if (plannings && plannings.length > 0) {
    plannings.forEach((plan) => {
      if (plan.year !== "") {
        const { monthEvaluations } = plan;

        Object.keys(monthEvaluations).forEach((month) => {
          if (monthEvaluations[month] && month !== "id") {
            if (monthEvaluations[month].spent) {
              const currentMonthSpent = monthEvaluations[month].spent;
              const { extraHours, leftMinutes } =
                getAdditionalSpentInfo(currentMonthSpent);

              monthEvaluations[month].spentHours = extraHours;

              monthEvaluations[month].spentMinutes = leftMinutes;
            }
          }
        });
        updatedPlannings = [...updatedPlannings, plan];
      }
    });
  }
  return { ...projectEffort, plannings: updatedPlannings };
};

const mountLoadEvaluation = (onStateEvaluation = null) => {
  let projectTeam = null;
  let projectEvaluation = null;
  let schedule = null;
  let projectInvestment = null;
  let activities = null;
  let projectEffort = null;
  let physicalAdvance = null;

  if (onStateEvaluation) {
    const { physicalProgressEvolutions, peopleInvolved } =
      onStateEvaluation.projectEvaluation;

    projectTeam = peopleInvolved;
    projectEvaluation = onStateEvaluation.projectEvaluation;
    schedule = { ...mountSchedule(onStateEvaluation.projectEvaluation) };
    projectInvestment = onStateEvaluation.projectEvaluation.projectInvestment;
    projectEffort = {
      ...mountProjectEffort(onStateEvaluation.projectEvaluation.projectEffort),
    };
    activities = onStateEvaluation.projectEvaluation.activities;
    physicalAdvance = {
      plannings: [...unmountPhysicalAdvance(physicalProgressEvolutions)],
    };
  }

  return {
    projectEvaluation,
    projectTeam,
    schedule,
    projectInvestment,
    activities,
    projectEffort,
    physicalAdvance,
  };
};

const transformFormObject = (
  object = {},
  currentValue = null,
  newValue = ""
) => {
  const newObject = object.projectEvaluation
    ? { ...object }
    : { projectEvaluation: { ...object } };

  const updatedObject = object.projectEvaluation
    ? { ...object }
    : { projectEvaluation: { ...object } };

  if (updatedObject && updatedObject.projectEvaluation) {
    updatedObject.projectEvaluation.activitiesTypeDefault =
      newObject.projectEvaluation.activitiesTypeDefault === true;

    updatedObject.projectEvaluation.projectName =
      newObject.projectEvaluation &&
      newObject.projectEvaluation.projectName &&
      newObject.projectEvaluation.projectName !== currentValue
        ? newObject.projectEvaluation.projectName
        : newValue;

    updatedObject.projectEvaluation.finalDate =
      newObject.projectEvaluation &&
      newObject.projectEvaluation.finalDate &&
      newObject.projectEvaluation.finalDate !== currentValue
        ? newObject.projectEvaluation.finalDate
        : newValue;

    updatedObject.projectEvaluation.initialDate =
      newObject.projectEvaluation.initialDate &&
      newObject.projectEvaluation.initialDate !== currentValue
        ? newObject.projectEvaluation.initialDate
        : newValue;

    updatedObject.projectEvaluation.scope =
      newObject.projectEvaluation.scope &&
      newObject.projectEvaluation.scope !== currentValue
        ? newObject.projectEvaluation.scope
        : newValue;

    updatedObject.projectEvaluation.justification =
      newObject.projectEvaluation.justification &&
      newObject.projectEvaluation.justification !== currentValue
        ? newObject.projectEvaluation.justification
        : newValue;

    updatedObject.projectEvaluation.weightAutomation =
      newObject.projectEvaluation.weightAutomation &&
      newObject.projectEvaluation.weightAutomation !== currentValue
        ? newObject.projectEvaluation.weightAutomation
        : newValue;

    updatedObject.projectEvaluation.effortAutomation =
      newObject.projectEvaluation.effortAutomation &&
      newObject.projectEvaluation.effortAutomation !== currentValue
        ? newObject.projectEvaluation.effortAutomation
        : newValue;

    updatedObject.projectEvaluation.projectConclusion =
      newObject.projectEvaluation.projectConclusion &&
      newObject.projectEvaluation.projectConclusion !== currentValue
        ? newObject.projectEvaluation.projectConclusion
        : newValue;

    updatedObject.projectEvaluation.status =
      newObject.projectEvaluation.status &&
      newObject.projectEvaluation.status !== currentValue
        ? newObject.projectEvaluation.status
        : newValue;

    updatedObject.projectEvaluation.projectStage =
      newObject.projectEvaluation.projectStage &&
      newObject.projectEvaluation.projectStage !== currentValue
        ? newObject.projectEvaluation.projectStage
        : newValue;

    updatedObject.projectEvaluation.physicalProgress =
      newObject.projectEvaluation.physicalProgress &&
      newObject.projectEvaluation.physicalProgress !== currentValue
        ? newObject.projectEvaluation.physicalProgress
        : newValue;

    updatedObject.projectEvaluation.physicalProgressEvolutions =
      newObject.projectEvaluation.physicalProgressEvolutions &&
      Object.keys(newObject.projectEvaluation.physicalProgressEvolutions)
        .length > 0
        ? newObject.projectEvaluation.physicalProgressEvolutions
        : newValue;

    updatedObject.projectEvaluation.reschedules =
      newObject.projectEvaluation.reschedules &&
      newObject.projectEvaluation.reschedules.length > 0
        ? newObject.projectEvaluation.reschedules
        : [];

    updatedObject.projectEvaluation.activities = getRestActivities(
      newObject.projectEvaluation.activities
    );

    // SECOND LEVEL

    if (updatedObject.projectEvaluation.projectInvestment) {
      updatedObject.projectEvaluation.projectInvestment.id =
        newObject.projectEvaluation &&
        newObject.projectEvaluation.projectInvestment &&
        newObject.projectEvaluation.projectInvestment.id
          ? newObject.projectEvaluation.projectInvestment.id
          : newValue;

      updatedObject.projectEvaluation.projectInvestment.plannings =
        newObject.projectEvaluation &&
        newObject.projectEvaluation.projectInvestment &&
        newObject.projectEvaluation.projectInvestment.plannings &&
        newObject.projectEvaluation.projectInvestment.plannings.length > 0
          ? newObject.projectEvaluation.projectInvestment.plannings
          : [];

      updatedObject.projectEvaluation.projectInvestment.replannings =
        newObject.projectEvaluation &&
        newObject.projectEvaluation.projectInvestment &&
        newObject.projectEvaluation.projectInvestment.replannings &&
        newObject.projectEvaluation.projectInvestment.replannings.length > 0
          ? newObject.projectEvaluation.projectInvestment.replannings
          : [];
    }

    if (updatedObject.projectEvaluation.projectEffort) {
      updatedObject.projectEvaluation.projectEffort.id =
        newObject.projectEvaluation &&
        newObject.projectEvaluation.projectEffort &&
        newObject.projectEvaluation.projectEffort.id
          ? newObject.projectEvaluation.projectEffort.id
          : newValue;

      updatedObject.projectEvaluation.projectEffort.plannings =
        newObject.projectEvaluation &&
        newObject.projectEvaluation.projectEffort &&
        newObject.projectEvaluation.projectEffort.plannings &&
        newObject.projectEvaluation.projectEffort.plannings.length > 0
          ? newObject.projectEvaluation.projectEffort.plannings
          : [];

      updatedObject.projectEvaluation.projectEffort.replannings =
        newObject.projectEvaluation &&
        newObject.projectEvaluation.projectEffort &&
        newObject.projectEvaluation.projectEffort.replannings &&
        newObject.projectEvaluation.projectEffort.replannings.length > 0
          ? newObject.projectEvaluation.projectEffort.replannings
          : [];
    }

    if (updatedObject.projectEvaluation.peopleInvolved) {
      updatedObject.projectEvaluation.peopleInvolved.responsibleMember =
        newObject.projectEvaluation.peopleInvolved &&
        newObject.projectEvaluation.peopleInvolved.responsibleMember !==
          currentValue
          ? newObject.projectEvaluation.peopleInvolved.responsibleMember
          : newValue;

      updatedObject.projectEvaluation.peopleInvolved.responsibleParticipant =
        newObject.projectEvaluation.peopleInvolved &&
        newObject.projectEvaluation.peopleInvolved.responsibleParticipant !==
          currentValue
          ? newObject.projectEvaluation.peopleInvolved.responsibleParticipant
          : newValue;

      updatedObject.projectEvaluation.peopleInvolved.executantMember =
        newObject.projectEvaluation.peopleInvolved &&
        newObject.projectEvaluation.peopleInvolved.executantMember !==
          currentValue
          ? newObject.projectEvaluation.peopleInvolved.executantMember
          : newValue;

      updatedObject.projectEvaluation.peopleInvolved.executantParticipant =
        newObject.projectEvaluation.peopleInvolved &&
        newObject.projectEvaluation.peopleInvolved.executantParticipant !==
          currentValue
          ? newObject.projectEvaluation.peopleInvolved.executantParticipant
          : newValue;
    }
  }

  return updatedObject;
};

const orderPhysicalEvolutions = (physicalEvolutions = []) => {
  const currentEvolutions = [...physicalEvolutions];

  currentEvolutions.sort((a, b) => {
    const a_attr = a.year;
    const b_attr = b.year;

    return a_attr > b_attr ? 1 : b_attr > a_attr ? -1 : 0;
  });

  currentEvolutions.forEach((evolution) => {
    evolution.months.sort((a, b) => {
      const a_attr = a.month;
      const b_attr = b.month;

      return a_attr > b_attr ? 1 : b_attr > a_attr ? -1 : 0;
    });
  });

  return currentEvolutions;
};

const orderActivities = (activities = []) => {
  const currentActivities = [...activities];

  currentActivities.sort((a, b) => {
    const a_attr = a?.indexOrder;
    const b_attr = b?.indexOrder;

    return a_attr > b_attr ? 1 : b_attr > a_attr ? -1 : 0;
  });

  return currentActivities;
};

const buildStateEvaluation = (
  onStateEvaluation,
  customConfig = false,
  projectConfiguration = false
) => {
  const newBuildedStateEvaluation = transformFormObject(
    onStateEvaluation,
    null,
    ""
  );
  const { projectEvaluation = null } = newBuildedStateEvaluation || {};

  if (!newBuildedStateEvaluation || !projectEvaluation) return null;

  const {
    physicalProgressEvolutions = [],
    activities = [],
    reschedules = [],
  } = projectEvaluation || {};

  if (customConfig) {
    newBuildedStateEvaluation.projectEvaluation.physicalProgressEvolutions = [
      ...orderPhysicalEvolutions(physicalProgressEvolutions),
    ];
  }

  newBuildedStateEvaluation.projectEvaluation.activities = [
    ...orderActivities(activities),
  ];

  newBuildedStateEvaluation.projectEvaluation.reschedules =
    sortArrayByAttribute(reschedules, "id");

  if (projectConfiguration) {
    newBuildedStateEvaluation.projectEvaluation = {
      ...projectEvaluation,
      ...handleStageStructureChange(projectEvaluation, projectConfiguration),
    };
  }

  return newBuildedStateEvaluation;
};

export const getProjectEvaluationById = (insightId, allProjectEvaluation) => {
  let evaluation = null;
  const currentAllProjectEvaluation = allProjectEvaluation || [];

  currentAllProjectEvaluation.forEach((projectEvaluation) => {
    if (projectEvaluation.insightId === insightId) {
      evaluation = projectEvaluation;
    }
  });

  return evaluation;
};

const removeIds = (buildedStateEvaluation) => {
  const projectEvaluation =
    buildedStateEvaluation.projectEvaluation || buildedStateEvaluation;

  const updatedObject = { ...projectEvaluation };

  if (updatedObject) {
    delete updatedObject.id;

    updatedObject.peopleInvolved && delete updatedObject.peopleInvolved.id;

    updatedObject.projectEffort && delete updatedObject.projectEffort.id;

    updatedObject.projectInvestment &&
      delete updatedObject.projectInvestment.id;

    updatedObject.projectInvestment.plannings.forEach((plan) => {
      delete plan.monthEvaluations.id;
    });

    updatedObject.projectEffort.plannings.forEach((plan) => {
      delete plan.monthEvaluations.id;
    });

    updatedObject.physicalProgressEvolutions.forEach((evolution) => {
      delete evolution.id;

      evolution.months.forEach((month) => {
        delete month.id;
      });
    });
  }

  return updatedObject;
};

const buildCurrentEvaluation = (currentEvaluation, reverse = false) => {
  const firstParam = reverse ? null : "";
  const secondParam = reverse ? "" : null;

  const newBuildedCurrentEvaluation = transformFormObject(
    currentEvaluation,
    firstParam,
    secondParam
  );

  return newBuildedCurrentEvaluation;
};

const removeCurrentEvaluationIds = (buildedCurrentEvaluation) => {
  if (buildedCurrentEvaluation) {
    delete buildedCurrentEvaluation.id;
  }

  return buildedCurrentEvaluation;
};

export const getSchedulePercentage = (schedule) => {
  const {
    initialDate = false,
    unformattedBaseline: baseline = false,
    unformattedDeadline: deadline = false,
    unformattedRealized: realized = false,
  } = schedule || {};

  const currentDate = moment().format("YYYY-MM-DD");
  const plannedDiff = moment(deadline || baseline).diff(initialDate, "days");
  const realDiff = moment(realized || currentDate).diff(initialDate, "days");

  return Math.round(ruleOfThree(plannedDiff, realDiff));
};

export const getActivityStage = ({ progress = 0, active }) => {
  const formProgress = Number(progress);

  const canceled = {
    translation: translation("canceled"),
    value: "CANCELED",
  };

  if (active === false) return canceled;

  const ongoing = {
    translation: translation("ongoing"),
    value: "ONGOING",
  };

  if (formProgress > 0 && formProgress < 100) return ongoing;

  const done = {
    translation: translation("done"),
    value: "DONE",
  };
  if (formProgress === 100) return done;

  const notStarted = {
    translation: translation("notStarted"),
    value: "NOT_STARTED",
  };

  return notStarted;
};

const getActivityStatus = ({
  deliveryDate = null,
  expectedDate = null,
  progress = null,
  active = null,
  stage = null,
  intl = null,
}) => {
  const currentStage =
    stage || getActivityStage({ progress, active, intl }).value || "";

  const default_status = {
    translation: "",
    value: "",
    color: "",
  };

  const canceled_status = {
    translation: translation("canceled"),
    value: "CANCELED",
    color: "grey",
  };

  const in_status = {
    translation: translation("in"),
    value: "IN",
    color: "green",
  };

  const out_status = {
    translation: translation("out"),
    value: "OUT",
    color: "red",
  };

  const isBefore = (firstDate = "", secondDate = "") => {
    return moment(firstDate).isSameOrBefore(moment(secondDate));
  };

  if (currentStage === "CANCELED") return canceled_status;

  if (deliveryDate && expectedDate) {
    if (isBefore(deliveryDate, expectedDate)) return in_status;
    if (moment(deliveryDate).isSame(expectedDate)) return in_status;
    if (moment(deliveryDate).isAfter(expectedDate)) return out_status;

    return out_status;
  }

  if (expectedDate) {
    const currentDate = moment().format("YYYY-MM-DD");

    if (moment(currentDate).isAfter(expectedDate)) return out_status;
    if (isBefore(currentDate, expectedDate)) return in_status;

    return out_status;
  }

  return default_status;
};

export const updateEvaluation = (
  currentAllProjectEvaluation = {},
  newBody = ""
) => {
  let finalEvaluation = [];
  let didUpdate = false;

  currentAllProjectEvaluation.forEach((itemBody) => {
    if (Number(itemBody.insightId) === Number(newBody.insightId)) {
      itemBody.projectEvaluation = newBody.projectEvaluation;
      didUpdate = true;
    }

    finalEvaluation = [...finalEvaluation, itemBody];
  });

  if (!didUpdate) finalEvaluation = [...finalEvaluation, newBody];

  return finalEvaluation;
};

export {
  buildCurrentEvaluation,
  buildDefaultYearsEvaluations,
  buildProjectEvaluationRest,
  buildStateEvaluation,
  calcIndicators,
  changeNewPlanReason,
  cleanAndBuildCurrentEvaluation,
  cleanAndBuildStateEvaluation,
  confirmPlan,
  defaultActivityData,
  getActivityStatus,
  getAvailableSchedule,
  getDateValidation,
  getEffortTotalValues,
  getFilterByOptions,
  getInitialIndicators,
  getInitialStates,
  getInsightProjectEvaluation,
  getLabelByType,
  getLabels,
  getMemberbyId,
  getOverallTotalValues,
  getPlanTotalValues,
  getProjectPhaseOptions,
  getScopeViewOptions,
  getSelectedPlan,
  getSomethingSplited,
  getStatusProjectByClassName,
  getTeamFieldTitleByIntl,
  getTotalEfforts,
  getTotalInvestments,
  getTotalValues,
  mountCurrentEvaluation,
  mountLoadEvaluation,
  mountPotentialPlans,
  orderPhysicalEvolutions,
  removeCurrentEvaluationIds,
  removeIds,
  updatedFirstPlan,
  updatePlanningByYear,
  updateReplannings,
  whoChanged,
};
