import { checkAccess } from "../../../../../../utils/accessLevels";

export const getOnePageTabsConfig = () => {
  return {
    management: true,
    tool: checkAccess(["MANAGER"]),
    score: true,
    timeline: true,
  };
};
