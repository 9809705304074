import { updateAllList } from "../utils/reducerUtils";
import { simpleArrayUnNester } from "../utils/ArrayUtils";
import { removeDuplicatedRelations } from "../utils/skillsAssessment/entityAutomation";

const INITIAL_STATE = {
  all: [],
  loading: false,
  lastFetch: null,
};

export function getAllChilds(list, childKey) {
  const positions = [];
  const unNestedPositions = simpleArrayUnNester(list, childKey);

  unNestedPositions.forEach((group) => {
    group?.forEach((item) => {
      positions.push(item);
    });
  });

  return positions;
}

export function findOneById(list, id) {
  if (!id) return null;
  return list.find((item) => item.id === id);
}

export function joinEntityById({ payload, storeState }) {
  const completeAdministration = removeDuplicatedRelations({ ...payload });
  const { companyData, companyStructure, companyPositions } = storeState;
  const { members } = companyData;
  const allAreas = getAllChilds(
    companyStructure.allAreaStructures[companyData?.selectedCompany?.id],
    "subAreas",
  );
  const allPositions = getAllChilds(
    companyPositions.allPositions[companyData?.selectedCompany?.id],
    "childPositions",
  );

  completeAdministration.participants = payload.participants
    .filter(({ memberParticipantId }) => memberParticipantId)
    .map((participant) => {
      const memberParticipant = findOneById(
        members,
        participant.memberParticipantId,
      );
      const updatedMemberParticipant = {
        ...memberParticipant,
        userData: {
          avatarBlobId: participant.avatarBlobId,
        },
      };

      return {
        ...participant,
        memberParticipant: updatedMemberParticipant,
        companyArea: findOneById(allAreas, participant.companyAreaId),
        companyPosition: findOneById(
          allPositions,
          participant.companyPositionId,
        ),
        manager: findOneById(members, participant.managerId),
        secondEvaluator: findOneById(members, participant.secondEvaluatorId),
        thirdEvaluator: findOneById(members, participant.thirdEvaluatorId),
      };
    });

  completeAdministration.roleSkillRelation = payload.roleSkillRelation.map(
    (item) => {
      return {
        ...item,
        companyRole: findOneById(allPositions, item.companyRoleId),
        skill: findOneById(completeAdministration.skills, item.skillId),
      };
    },
  );

  completeAdministration.workflow = payload.workflow.map((item) => {
    return {
      ...item,
      skill: findOneById(completeAdministration.skills, item.skillId),
      participant: findOneById(
        completeAdministration.participants,
        item.participantId,
      ),
    };
  });

  return { completeAdministration };
}

const skillAssessmentReducer = (state = INITIAL_STATE, action) => {
  const isLoading =
    action.loading === true || action.loading === false
      ? action.loading
      : state.loading;
  const lastFetch = action.lastFetch ? action.lastFetch : state.lastFetch;

  function updateAdministration(newPayload) {
    if (newPayload?.selectedToolId) {
      return updateAllList(state.all, [newPayload], "selectedToolId");
    }

    return state.all;
  }

  switch (action.type) {
    case "UPDATE_SKILL_ASSESSMENT":
      if (action.payload) {
        const { completeAdministration } = joinEntityById({
          payload: action.payload,
          storeState: action.storeState,
        });
        const updatedAll = updateAdministration(completeAdministration);
        return {
          ...state,
          all: updatedAll,
          loading: isLoading,
          lastFetch,
        };
      }

      return {
        ...state,
        loading: isLoading,
        lastFetch,
      };
    default:
      return state;
  }
};

export default skillAssessmentReducer;
