import * as actions from "./_actions";
import send from "../../dispatcher";

function observeAttachments({ stompClient, dispatch }) {
  stompClient.subscribe("/insights/addAttachment", response => {
    send(actions.postAddAttachment, response, dispatch);
  });

  stompClient.subscribe("/insights/deleteAttachment", response => {
    send(actions.deleteAttachment, response, dispatch);
  });
}

export default observeAttachments;
