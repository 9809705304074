import axios from "axios";
import {
  UPDATE_KPI_CONFIGURATION,
  UPDATE_ALL_KPI_CONFIGURATIONS,
  UPDATE_CENARY,
  UPDATE_CENARY_THEME,
  UPDATE_THEME_QUESTION,
  UPDATE_QUESTION_ANSWER,
  KPI_DELETE_GOAL,
  KPI_DELETE_SCENARIO,
  KPI_DELETE_GROUP,
  KPI_DELETE_PROCESS,
  KPI_SET_SELECTED_GOAL,
} from "./types";
import kpiUtils from "../utils/kpis";
import { putScenery, selectCenary } from "./cenaryActions";
import { putTheme } from "./themesActions";
import { putQuestion } from "./questionsActions";
import { postInsight, convertAnswerInsight } from "./insightActions";
import { grantSceneryAccess } from "./accessControllerActions";
import { appRefreshSelectedTool } from "./appActions";
import { apiNode } from "../api";

const {
  buildKpiAdministrationDefaultSave,
  inputAdminUserOnKpiAdministration,
  buildKpiAdministrationFinalBody,
} = kpiUtils;

let defaultInitialRestSave = buildKpiAdministrationDefaultSave();

const DEBUG = false;

function debug(arg1, ...optionals) {
  if (DEBUG) console.log("KPIs: ", arg1, ...optionals);
}

export const getKpiConfiguration = (selectedToolId) => {
  return apiNode
    .get(`/api/v2/tool-administration/indicators/${selectedToolId}`)
    .then((res) => res);
};

export const getKpisConfigurations = (selectedToolIds = []) => {
  return axios
    .get(`/api/selected-tools/kpiConfigurations/${selectedToolIds}`)
    .then((res) => res.data);
};

export const postKpiConfiguration = (selectedToolId, body) => {
  return axios
    .post(`/api/selected-tools/${selectedToolId}/kpiConfiguration`, body)
    .then((res) => res);
};

export const putKpiConfiguration = (body) => {
  return axios.put("/api/kpi-configuration", body).then((res) => res);
};

export const fetchToolKpiConfigurations = (selectedToolIds = []) => {
  return async (dispatch) => {
    const kpiConfigurations = await getKpisConfigurations(selectedToolIds);

    const getFormattedKpiConfig = () => {
      return Object.keys(kpiConfigurations).map((selectedToolId) => {
        return {
          ...kpiConfigurations[selectedToolId],
          selectedToolId: Number(selectedToolId),
        };
      });
    };

    dispatch({
      type: UPDATE_ALL_KPI_CONFIGURATIONS,
      payload: getFormattedKpiConfig(),
      loading: false,
    });
  };
};

export const updateKpiAdministration = (selectedToolId, body, config) => {
  const { onlyPost = false, onSuccess, onError } = config || {};

  return async (dispatch) => {
    const saveBody = buildKpiAdministrationFinalBody(body);

    const sendDispatch = (type, payload, loading = false, error) => {
      dispatch({ type, payload, loading, error });
      if (payload?.id && onSuccess) onSuccess(payload);
    };

    sendDispatch(UPDATE_KPI_CONFIGURATION, null, true);

    async function saveNewConfiguration() {
      try {
        const newKpiConfig = await postKpiConfiguration(
          selectedToolId,
          saveBody
        );

        const fetchedConfiguration = buildKpiAdministrationFinalBody(
          newKpiConfig.data
        );

        debug({ newKpiConfig });

        sendDispatch(UPDATE_KPI_CONFIGURATION, {
          ...fetchedConfiguration,
          selectedToolId,
        });
      } catch (err) {
        sendDispatch(UPDATE_KPI_CONFIGURATION, null, false, err);
      }
    }

    const updateAdministration = async () => {
      try {
        const request = await getKpiConfiguration(selectedToolId);
        debug("updateKpiAdministration => Received Body", saveBody);

        if (request.data && !onlyPost) {
          try {
            debug({ request });
            await putKpiConfiguration(saveBody);
            const updatedKpiConfig = await getKpiConfiguration(selectedToolId);
            const fetchedConfiguration = buildKpiAdministrationFinalBody(
              updatedKpiConfig.data
            );

            debug({ updatedKpiConfig });

            sendDispatch(
              UPDATE_KPI_CONFIGURATION,
              {
                ...fetchedConfiguration,
                selectedToolId,
              },
              false
            );
          } catch (err) {
            sendDispatch(UPDATE_KPI_CONFIGURATION, null, false, err);
          }
        } else {
          saveNewConfiguration();
        }
      } catch (err) {
        sendDispatch(UPDATE_KPI_CONFIGURATION, null, false, err);
      }
    };

    try {
      updateAdministration();
    } catch (err) {
      console.error(err);
      if (onError) onError(err);
    }
  };
};

export const setSelectedGoal = (payload) => {
  return async (dispatch) => {
    dispatch({ type: KPI_SET_SELECTED_GOAL, payload });
  };
};

export const fetchToolKpiConfiguration = (
  selectedToolId,
  saveDefault = false,
  loggedUserId
) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_KPI_CONFIGURATION, loading: true });

    async function postNewDefaultConfig() {
      debug("postNewDefaultConfig", {
        saveDefault,
        selectedToolId,
        loggedUserId,
      });
      if (saveDefault && selectedToolId && loggedUserId) {
        defaultInitialRestSave = inputAdminUserOnKpiAdministration(
          defaultInitialRestSave,
          loggedUserId
        );

        await dispatch(
          updateKpiAdministration(selectedToolId, defaultInitialRestSave, {
            onlyPost: true,
          })
        );
      }
    }

    try {
      const kpiConfiguration = await getKpiConfiguration(selectedToolId);

      debug({ kpiConfiguration });

      if (kpiConfiguration?.data?.id) {
        const fetchedConfiguration = buildKpiAdministrationFinalBody(
          kpiConfiguration.data
        );
        debug({ fetchedConfiguration });

        dispatch({
          type: UPDATE_KPI_CONFIGURATION,
          payload: { ...fetchedConfiguration, selectedToolId },
          loading: false,
        });
      } else {
        await postNewDefaultConfig();
      }
    } catch (err) {
      debug("Request error fetchToolKpiConfiguration", err);
    }
  };
};

export const postKpiTheme = (sceneryId, themeId) => {
  return axios
    .post(
      `/api/kpi-configuration/cenary/${sceneryId}/KPIConfigurationTheme/${themeId}`
    )
    .then((res) => res);
};

export const createThemeCenary = (
  selectedTool,
  teamName,
  owner = {},
  order = 0
) => {
  return (dispatch) =>
    dispatch(
      putScenery(
        {
          id: null,
          name: teamName,
          fixed: false,
          selectedTool,
          editing: true,
          type: "cenary",
          owner,
          order,
        },
        selectedTool.id
      )
    );
};

export const dispatchCenary = (newScenery) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_CENARY,
      payload: { ...newScenery, isNew: true },
    });
    await dispatch(selectCenary(newScenery.id));
    dispatch(grantSceneryAccess(null, "LEADER", "EDIT"));
  };
};

export const createKpiScenerieTheme = (
  selectedTool = {},
  themeInfo = {},
  userProfile,
  order = 0
) => {
  return async (dispatch) => {
    if (selectedTool.id && themeInfo.id) {
      try {
        const newScenery = await dispatch(
          createThemeCenary(selectedTool, themeInfo.title, userProfile, order)
        );
        const newScenaryData = newScenery.data;

        if (newScenaryData.id) {
          const postedLink = await postKpiTheme(
            newScenaryData.id,
            themeInfo.id
          );

          if (postedLink.status === 204 || postedLink.status === 200) {
            const updatedScenaryData = {
              ...newScenaryData,
              kpiConfigurationThemeId: themeInfo.id,
            };

            dispatch(dispatchCenary(updatedScenaryData));
          }
        }
      } catch (err) {
        debug({ err });
      }

      dispatch(appRefreshSelectedTool(false));
    }
  };
};

export const postKpiGroup = (themeId, groupId) => {
  return axios
    .post(
      `/api/kpi-configuration/theme/${themeId}/KPIConfigurationGroup/${groupId}`
    )
    .then((res) => res);
};

export const createGroupTheme = (cenaryBody, groupTitle, order) => {
  return putTheme({
    id: null,
    type: "theme",
    cenary: cenaryBody,
    name: groupTitle,
    order,
  });
};

export const dispatchTheme = (cenaryId, newTheme) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CENARY_THEME,
      payload: {
        cenaryId,
        theme: {
          ...newTheme,
          isNew: true,
        },
      },
    });
  };
};

export const createKpiThemeGroup = (
  groupInfo = {},
  cenaryBody = {},
  order = 0
) => {
  return async (dispatch) => {
    if (cenaryBody.id && groupInfo.id) {
      try {
        const newTheme = await createGroupTheme(
          cenaryBody,
          groupInfo.title,
          order
        );
        const newThemeData = newTheme.data;

        if (newTheme.data.id) {
          const postedLink = await postKpiGroup(newTheme.data.id, groupInfo.id);

          if (postedLink.status === 204 || postedLink.status === 200) {
            const updatedThemeData = {
              ...newThemeData,
              kpiConfigurationGroupId: groupInfo.id,
            };

            dispatch(dispatchTheme(cenaryBody.id, updatedThemeData));
          }
        }
      } catch (err) {
        console.log("err", err);
      }

      dispatch(appRefreshSelectedTool(false));
    }
  };
};

export const postKpiProcess = (questionId, processId) => {
  return axios
    .post(
      `/api/kpi-configuration/question/${questionId}/KPIConfigurationProcess/${processId}`
    )
    .then((res) => res);
};

export const createProcessQuestion = (themeBody, processTitle, order) => {
  return putQuestion({
    id: null,
    type: "question",
    theme: themeBody,
    text: processTitle,
    order,
  });
};

export const dispatchQuestion = (themeId, newQuestion) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_THEME_QUESTION,
      payload: {
        themeId,
        question: {
          ...newQuestion,
          isNew: true,
        },
      },
    });
  };
};

export const createKpiQuestionProcess = (
  processInfo = {},
  themeBody = {},
  order = 0
) => {
  return async (dispatch) => {
    if (themeBody.id && processInfo.id) {
      try {
        const newQuestion = await createProcessQuestion(
          themeBody,
          processInfo.title,
          order
        );
        const newQuestionData = newQuestion.data;

        if (newQuestionData.id) {
          const postedLink = await postKpiProcess(
            newQuestionData.id,
            processInfo.id
          );

          if (postedLink.status === 204 || postedLink.status === 200) {
            const updatedQuestionData = {
              ...newQuestionData,
              kpiConfigurationProcessId: processInfo.id,
            };

            dispatch(dispatchQuestion(themeBody.id, updatedQuestionData));
          }
        }
      } catch (err) {
        debug({ err });
      }

      dispatch(appRefreshSelectedTool(false));
    }
  };
};

export const postKpiIndicator = (insightId, goalIndicatorId) => {
  debug("postKpiIndicator LINK: ", { insightId, goalIndicatorId });

  return axios
    .post(
      `/api/kpi-configuration/insight/${insightId}/kpiGoal/${goalIndicatorId}`
    )
    .then((res) => res);
};

export const createIndicatorInsight = (goalTitle, questionId, order) => {
  return postInsight(
    {
      id: null,
      text: goalTitle,
      name: goalTitle,
      type: "insight",
      order,
    },
    questionId
  );
};

export const dispatchInsight = (questionId, newInsight) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_QUESTION_ANSWER,
      payload: {
        questionId,
        answer: { ...newInsight, isNew: true },
      },
    });
  };
};

export const createKpiInsightIndicator = (
  selectedToolId,
  questionId,
  goalIndicatorInfo = {},
  order = 0
) => {
  return async (dispatch) => {
    debug({ goalIndicatorInfo }, { selectedToolId, questionId });

    if (questionId && goalIndicatorInfo.id) {
      try {
        const newInsight = await createIndicatorInsight(
          goalIndicatorInfo.title,
          questionId,
          order
        );
        const newInsightData = newInsight.data;
        debug({ newInsight });

        dispatch(
          convertAnswerInsight(newInsightData, newInsightData.question, false)
        );

        if (newInsightData.id) {
          const postedLink = await postKpiIndicator(
            newInsightData.id,
            goalIndicatorInfo.id
          );

          debug({ postedLink });

          if (postedLink.status === 204 || postedLink.status === 200) {
            const updatedInsightData = {
              ...newInsightData,
              kpiConfigurationGoalId: goalIndicatorInfo.id,
            };

            dispatch(dispatchInsight(questionId, updatedInsightData));

            if (selectedToolId)
              dispatch(fetchToolKpiConfiguration(selectedToolId));
          }
        }
      } catch (err) {
        debug({ err });
      }

      dispatch(appRefreshSelectedTool(false));
    }
  };
};

export const deleteKpiTheme = (themeId, administrationId) => {
  return async (dispatch) => {
    debug("deleteKpiTheme:", { themeId, administrationId });
    await axios.delete(`/api/kpi-configuration-theme/${themeId}`);

    dispatch({
      type: KPI_DELETE_SCENARIO,
      payload: { themeId, administrationId },
    });
    await dispatch(appRefreshSelectedTool(false));
  };
};

export const deleteKpiGroup = (groupId, administrationId) => {
  return async (dispatch) => {
    debug("deleteKpiGroup:", { groupId, administrationId });
    await axios.delete(`/api/kpi-configuration-group/${groupId}`);

    dispatch({
      type: KPI_DELETE_GROUP,
      payload: { groupId, administrationId },
    });
    await dispatch(appRefreshSelectedTool(false));
  };
};

export const deleteKpiProcess = (processId, administrationId) => {
  return async (dispatch) => {
    debug("deleteKpiProcess:", { processId, administrationId });
    await axios.delete(`/api/kpi-configuration-process/${processId}`);

    dispatch({
      type: KPI_DELETE_PROCESS,
      payload: { processId, administrationId },
    });
    await dispatch(appRefreshSelectedTool(false));
  };
};

export const deleteKpiIndicator = (goalId, administrationId) => {
  return async (dispatch) => {
    await axios.delete(`/api/kpi-configuration-goals/${goalId}`);

    dispatch({ type: KPI_DELETE_GOAL, payload: { goalId, administrationId } });
    await dispatch(appRefreshSelectedTool(false));
  };
};

export const addScenariosToAdminitration = async (
  selectedToolId,
  newKpiAdministration = {},
  dispatch
) => {
  const newKpiConfig = buildKpiAdministrationFinalBody(newKpiAdministration);
  const { themes = [] } = newKpiConfig;

  const currentScenarioIds = [];
  const newAddedScenarios = [];

  themes.forEach((scenarioInfo) => {
    if (scenarioInfo.id > -1) currentScenarioIds.push(scenarioInfo.id);
  });

  const updatedAdministration = await putKpiConfiguration(newKpiConfig);
  const fetchedConfiguration = buildKpiAdministrationFinalBody(
    updatedAdministration.data
  );

  dispatch({
    type: UPDATE_KPI_CONFIGURATION,
    payload: {
      ...fetchedConfiguration,
      selectedToolId,
    },
  });

  debug({ updatedAdministration });

  if (updatedAdministration.data) {
    const updatedScenarios = updatedAdministration.data.themes;

    updatedScenarios.forEach((scenarioInfo) => {
      if (currentScenarioIds.indexOf(scenarioInfo.id) === -1)
        newAddedScenarios.push(scenarioInfo);
    });
  }

  debug({ newAddedScenarios });

  return newAddedScenarios;
};

export const saveLinkBoardScenario = (
  selectedTool,
  newKpiAdministration = {},
  userProfile = {},
  nextScenerieOrder = 0
) => {
  return async (dispatch) => {
    const newAddedScenarios = await addScenariosToAdminitration(
      selectedTool.id,
      newKpiAdministration,
      dispatch
    );

    if (newAddedScenarios.length > 0) {
      newAddedScenarios.forEach((scenarioInfo) => {
        dispatch(
          createKpiScenerieTheme(
            selectedTool,
            scenarioInfo,
            userProfile,
            nextScenerieOrder
          )
        );
      });
    }
  };
};

export const addThemesToAdminitration = async (
  selectedToolId,
  newKpiAdministration = {},
  dispatch
) => {
  const newKpiConfig = buildKpiAdministrationFinalBody(newKpiAdministration);
  const { groups = [] } = newKpiConfig;

  const currentThemeIds = [];
  const newAddedThemes = [];

  groups.forEach((themeInfo) => {
    if (themeInfo.id > -1) currentThemeIds.push(themeInfo.id);
  });

  const updatedAdministration = await putKpiConfiguration(newKpiConfig);
  const fetchedConfiguration = buildKpiAdministrationFinalBody(
    updatedAdministration.data
  );

  dispatch({
    type: UPDATE_KPI_CONFIGURATION,
    payload: {
      ...fetchedConfiguration,
      selectedToolId,
    },
  });

  debug({ updatedAdministration });

  if (updatedAdministration.data) {
    const updatedThemes = updatedAdministration.data.groups;

    updatedThemes.forEach((themeInfo) => {
      if (currentThemeIds.indexOf(themeInfo.id) === -1)
        newAddedThemes.push(themeInfo);
    });
  }

  debug({ newAddedThemes });

  return newAddedThemes;
};

export const saveLinkBoardTheme = (
  selectedTool,
  themeScenerie,
  newKpiAdministration = {},
  order
) => {
  return async (dispatch) => {
    const newAddedThemes = await addThemesToAdminitration(
      selectedTool.id,
      newKpiAdministration,
      dispatch
    );

    let nextOrder = order;

    if (newAddedThemes.length > 0) {
      newAddedThemes.forEach((themeInfo) => {
        dispatch(createKpiThemeGroup(themeInfo, themeScenerie, nextOrder));
        nextOrder++;
      });
    }
  };
};

export const addSubthemesToAdminitration = async (
  selectedToolId,
  newKpiAdministration = {},
  dispatch
) => {
  const newKpiConfig = buildKpiAdministrationFinalBody(newKpiAdministration);
  const { processes = [] } = newKpiConfig;

  const currentSubthemeIds = [];
  const newAddedSubthemes = [];

  processes.forEach((subthemeInfo) => {
    if (subthemeInfo.id > -1) currentSubthemeIds.push(subthemeInfo.id);
  });

  const updatedAdministration = await putKpiConfiguration(newKpiConfig);
  const fetchedConfiguration = buildKpiAdministrationFinalBody(
    updatedAdministration.data
  );

  dispatch({
    type: UPDATE_KPI_CONFIGURATION,
    payload: {
      ...fetchedConfiguration,
      selectedToolId,
    },
  });

  debug({ updatedAdministration });

  if (updatedAdministration.data) {
    const updatedSubthemes = updatedAdministration.data.processes;

    updatedSubthemes.forEach((subthemeInfo) => {
      if (currentSubthemeIds.indexOf(subthemeInfo.id) === -1)
        newAddedSubthemes.push(subthemeInfo);
    });
  }

  debug({ newAddedSubthemes });

  return newAddedSubthemes;
};

export const saveLinkBoardSubtheme = (
  selectedTool,
  questionTheme,
  newKpiAdministration = {},
  order = 0
) => {
  return async (dispatch) => {
    const newAddedSubthemes = await addSubthemesToAdminitration(
      selectedTool.id,
      newKpiAdministration,
      dispatch
    );

    let nextOrder = order;

    if (newAddedSubthemes.length > 0) {
      newAddedSubthemes.forEach((subthemeInfo) => {
        dispatch(
          createKpiQuestionProcess(subthemeInfo, questionTheme, nextOrder)
        );
        nextOrder++;
      });
    }
  };
};

export const addIndicatorsToAdminitration = async (
  selectedToolId,
  newKpiAdministration = {},
  dispatch
) => {
  const newKpiConfig = buildKpiAdministrationFinalBody(newKpiAdministration);
  const { goals = [] } = newKpiConfig;

  const currentIndicatorIds = [];
  const newAddedIndicators = [];

  goals.forEach((indicatorInfo) => {
    if (indicatorInfo.id > -1) currentIndicatorIds.push(indicatorInfo.id);
  });

  const updatedAdministration = await putKpiConfiguration(newKpiConfig);
  const fetchedConfiguration = buildKpiAdministrationFinalBody(
    updatedAdministration.data
  );

  dispatch({
    type: UPDATE_KPI_CONFIGURATION,
    payload: {
      ...fetchedConfiguration,
      selectedToolId,
    },
  });

  debug({ updatedAdministration });

  if (updatedAdministration.data) {
    const updatedIndicators = updatedAdministration.data.goals;

    updatedIndicators.forEach((indicatorInfo) => {
      if (currentIndicatorIds.indexOf(indicatorInfo.id) === -1)
        newAddedIndicators.push(indicatorInfo);
    });
  }

  debug({ newAddedIndicators });

  return newAddedIndicators;
};

export const saveLinkBoardIndicator = (
  selectedTool,
  insightQuestion,
  newKpiAdministration = {},
  nextOrder = 0
) => {
  return async (dispatch) => {
    const newAddedIndicators = await addIndicatorsToAdminitration(
      selectedTool.id,
      newKpiAdministration,
      dispatch
    );

    let newIndicadorOrder = nextOrder;

    if (newAddedIndicators.length > 0) {
      newAddedIndicators.forEach((indicatorInfo) => {
        dispatch(
          createKpiInsightIndicator(
            selectedTool.id,
            insightQuestion.id,
            indicatorInfo,
            newIndicadorOrder
          )
        );
        newIndicadorOrder++;
      });
    }
  };
};

export const kpisMassUpdate = ({
  administrationId = 0,
  goals = [],
  selectedToolId,
}) => {
  return async (dispatch) => {
    debug("kpisMassUpdate: ", { goals });
    if (administrationId && selectedToolId) {
      const { data } = await axios.post(
        `/api/kpi-configuration/${administrationId}/updateGoals`,
        goals
      );

      debug("kpisMassUpdate: ", { data });

      dispatch(fetchToolKpiConfiguration(selectedToolId));
    }
  };
};
