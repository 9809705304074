import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import FormDialog from "../../FormDialog";
import MaterialTextField from "../../MaterialTextField";
import SelectItemModal from "../../SelectItemModal";
import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import NewProcessDialog from "./NewProcessDialog";
import utils from "../../../../utils/toolUtils";
import { getKpiConfiguration } from "../../../../actions/kpiActions";
import { buildKpiAdministrationFinalBody } from "../../../../utils/kpis/entity";
import { getSelectedToolStates } from "../../../customMapStates";

const { getErrorMessage, getValidFunc, getObjectInfoById } = utils;

const useStyles = makeStyles(() =>
  createStyles({
    addButton: {
      fontSize: "12px",
    },
  }),
);

const messages = defineMessages({
  kpi_add_new_question: { id: "kpi_add_new_question" },
  btn_modal_register_new: { id: "btn_modal_register_new" },
  kpi_error_subtheme_already_added: {
    id: "kpi_error_subtheme_already_added",
  },
  kpi_form_select_subtheme: { id: "kpi_form_select_subtheme" },
  kpi_table_text_no_subthemes: { id: "kpi_table_text_no_subthemes" },
  global_select: { id: "global.select" },
});

const initialForm = {
  processes: [],
};

const QuestionProcessSelectDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const [registerDialog, setRegisterDialog] = useState(false);
  const [selectProcessModal, setSelectProcessModal] = useState(false);

  const {
    open = false,
    afterCancel,
    afterConfirm,
    kpiAdministration,
    setKpiAdministration,
    addedProcesses = [],
    selectedToolID,
    intl,
  } = props;

  const alternativeSaveCallback = getValidFunc(props.alternativeSaveCallback);

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  const getKpiConfig = async () => {
    if (selectedToolID) {
      const { data } = await getKpiConfiguration(selectedToolID);
      return buildKpiAdministrationFinalBody(data);
    }
  };

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleConfirm = () => {
    if (typeof afterConfirm === "function") afterConfirm(form);
  };

  const handleCancel = () => {
    if (typeof afterCancel === "function") afterCancel();
  };

  const handleUpdateForm = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const getDisplayErrors = (slug = "repeated", errors = []) => {
    return form.processes.map((processId, index) => {
      let haveError = null;

      errors.forEach((iteratingErr) => {
        if (iteratingErr.slug === `${slug}${processId}`) haveError = true;
      });

      return haveError ? (
        <div style={{ height: "40px" }} key={index}>
          {getErrorMessage([`${slug}${processId}`], errors, "left", {
            height: "0px",
          })}
        </div>
      ) : null;
    });
  };

  const getFormErrors = () => {
    let errors = [];

    if (form.processes.length === 0) {
      errors.push({
        slug: "notSelected",
      });
    }

    form.processes.forEach((groupId) => {
      const processInfo = getObjectInfoById(
        groupId,
        kpiAdministration.processes,
        "id",
      );

      if (addedProcesses.indexOf(groupId) > -1) {
        errors.push({
          slug: `repeated${groupId}`,
          message: intl.formatMessage(
            messages.kpi_error_subtheme_already_added,
            {
              process: processInfo.title,
            },
          ),
        });
      }
    });

    return errors;
  };

  const buildProcessOptions = (processes) => {
    let finalOptions = [];

    if (processes && processes.length > 0) {
      processes.forEach(({ id, title }) => finalOptions.push({ id, title }));
    }

    return finalOptions;
  };

  const updateSelected = (selected) => {
    handleUpdateForm("processes", selected);

    toggleSelectProcessModal(false);
  };

  const alternativeSave = (submitKpiConfig) => {
    alternativeSaveCallback(submitKpiConfig);
    toggleRegisterDialog(false);
  };

  const toggleRegisterDialog = (toggleTo = true) => {
    setRegisterDialog(toggleTo);
  };

  const toggleSelectProcessModal = (toggleTo = true) => {
    setSelectProcessModal(toggleTo);
  };

  const getSelectedDisplay = (list) => {
    let displayTitles = [];

    kpiAdministration.processes.forEach((processInfo) => {
      if (list.indexOf(processInfo.id) > -1)
        displayTitles.push(processInfo.title);
    });

    return displayTitles.length > 0
      ? displayTitles.join(", ")
      : intl.formatMessage(messages.global_select);
  };

  const processesOptions = buildProcessOptions(kpiAdministration.processes);

  const classes = useStyles();
  const formErrors = getFormErrors();

  return (
    <>
      <NewProcessDialog
        open={registerDialog}
        kpiAdministration={kpiAdministration}
        setKpiAdministration={setKpiAdministration}
        getKpiConfig={getKpiConfig}
        afterConfirm={(submitKpiConfig) => alternativeSave(submitKpiConfig)}
        afterCancel={() => toggleRegisterDialog(false)}
      />
      <SelectItemModal
        open={selectProcessModal}
        itemsList={processesOptions || []}
        onCancel={() => toggleSelectProcessModal(false)}
        onConfirm={(selected) => {
          updateSelected(selected);
        }}
        title={intl.formatMessage(messages.kpi_form_select_subtheme)}
        initialSelected={form.processes}
        replaceSelected
      />
      <FormDialog
        open={open}
        title={intl.formatMessage(messages.kpi_add_new_question)}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        blockConfirm={formErrors.length > 0}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialTextField
              id="processSelect"
              label={intl.formatMessage(messages.kpi_form_select_subtheme)}
              value={getSelectedDisplay(form.processes)}
              onClick={() => toggleSelectProcessModal(true)}
              inputStyle={{ cursor: "pointer" }}
            />
            {getDisplayErrors("repeated", formErrors)}
            <Button
              variant="outlined"
              className={classes.addButton}
              onClick={() => toggleRegisterDialog(!registerDialog.open)}
            >
              <AddIcon /> {intl.formatMessage(messages.btn_modal_register_new)}
            </Button>
          </div>
        </div>
      </FormDialog>
    </>
  );
};

export default injectIntl(
  connect((state) => {
    const { selectedToolID } = getSelectedToolStates(state);
    return { selectedToolID };
  })(QuestionProcessSelectDialog),
);
