import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { createObjectiveQuestion } from "../../../../actions/okrActions";
import QuestionProcessSelectDialog from "../../../Common/Okr/forms/QuestionProcessSelectDialog";
import { getSelectedToolStates } from "../../../customMapStates";
import { getQuestionInfoById } from "../../../../utils/okr/filter";
import { orderArrayByObjAttr } from "../../../../utils/ArrayUtils";

const Okr = (props) => {
  const [addedObjectives, setAddedObjectives] = useState([]);

  const {
    type,
    clearViewCallback,
    selectedTool = {},
    createObjectiveQuestion,
    allSceneries = [],
    allThemes = [],
    allQuestions = [],
    params = {},
    okr,
  } = props;

  const { questionTheme = {} } = params;

  useEffect(() => {
    const objectives = [];

    allSceneries.forEach((scenerieInfo) => {
      if (scenerieInfo.selectedTool.id === selectedTool.id) {
        const scenerieThemes = allThemes[scenerieInfo.id] || [];

        scenerieThemes.forEach((themeInfo) => {
          const themeQuestions = allQuestions[themeInfo.id] || [];

          themeQuestions.forEach(({ okrConfigurationObjectiveId }) => {
            objectives.push({
              okrConfigurationObjectiveId,
              themeId: themeInfo.id,
            });
          });
        });
      }
    });

    if (!_.isEqual(objectives, addedObjectives)) setAddedObjectives(objectives);
  }, [allSceneries, allThemes, allQuestions, selectedTool.id, addedObjectives]);

  const handleSaveQuestion = ({ id }) => {
    if (questionTheme && id) {
      const objectiveInfo = getQuestionInfoById(id, okr.objectives);

      createObjectiveQuestion(objectiveInfo, questionTheme, params.order);
    }

    clearDialogControll();
  };

  const handleSaveQuestionObjective = (objective) => {
    if (questionTheme && objective.id) {
      createObjectiveQuestion(objective, questionTheme, params.order);
    }

    clearDialogControll();
  };

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
  };

  return (
    <QuestionProcessSelectDialog
      open
      afterConfirm={handleSaveQuestion}
      handleSaveQuestionObjective={handleSaveQuestionObjective}
      afterCancel={clearDialogControll}
      okrObjectives={orderArrayByObjAttr(okr.objectives, "title")}
      okrConfigurationId={okr.id}
      addedObjectives={addedObjectives}
      questionTheme={questionTheme}
    />
  );
};

const mapStateToProps = (state) => {
  const {
    selectedTool,
    allSceneries,
    allThemes,
    allQuestions,
  } = getSelectedToolStates(state);

  const { user, okr } = state;

  return {
    userProfile: user,
    selectedTool,
    allSceneries,
    allThemes,
    allQuestions,
    okr,
  };
};

export default connect(mapStateToProps, {
  createObjectiveQuestion,
})(Okr);
