/* eslint-disable react-hooks/exhaustive-deps */
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { defineMessages, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, Route } from "react-router-dom";

import { appHomeRequests } from "../../../actions/appActions";
import { logout as localLogout } from "../../../actions/authActions";
import { setLanguage } from "../../../actions/languageActions";
import { fetchTranslations } from "../../../actions/translationsActions";
import { getSelectedWorkspace } from "../../../actions/workspaceActions";

import { getToolIntlSlug } from "../../../utils/translationUtils";
import BreadcrumbMenu from "../../BreadcrumbMenu/BreadcrumbMenu";
import { appRoutes } from "../../routes";
import NotificationsMenu from "./NotificationsMenu";
import UserMenu from "./UserMenu";

import { changeLockdown } from "../../../actions/accessLevelsActions";
import { checkAccess, getNamespaceConfig } from "../../../utils/accessLevels";
import { getValidFunc } from "../../../utils/validations";
import DomainSelection from "../../Domain/DomainSelection";
import { getAccessStates, getAuthStates } from "../../customMapStates";

import logo from "../../../assets/img/logo_novo_alt.svg";

import { fetchUserNotifications } from "../../../actions/notifications/actions";
import setAuthorizationToken from "../../../utils/setAuthorizationToken";
import { BoxZoom, CustomPopover, IconMinus } from "./styles";

const Header = (props) => {
  const {
    intl,
    selectedCompany,
    user,
    selectedWorkspace,
    selectedTool,
    setLanguage,
    fetchTranslations,
    currentDomain,
    allUserDomains,
    companyLogoOptions,
    authEssentials,
    collapsedToggle,
    fetchUserNotifications,
    companyId,
    domainLogo,
  } = props;

  const {
    logout = () => null,
    authContext = false,
    isAuthenticated = {},
    loginWithRedirect = () => null,
  } = authEssentials || {};

  const onlyOneDomain = !(allUserDomains && allUserDomains.length > 1);

  const namespaceConfig = getNamespaceConfig();

  const { isAppHmlorDev = false } = namespaceConfig;

  const toggleCollapsed = getValidFunc(collapsedToggle);

  const doLogout = () => {
    logout();
  };

  const [domainSelectionModal, setDomainSelectionModal] = useState(false);

  function getLanguageFullName(intlLang) {
    if (intlLang === "en") return "EN";
    if (intlLang === "es") return "ES";
    if (intlLang === "pt") return "PT-BR";
  }

  const changeLanguage = (lang) => {
    window.localStorage.setItem("lang", lang);

    setLanguage(lang);
    fetchTranslations(lang);
  };

  useEffect(() => {
    const localLang = window.localStorage.getItem("lang") || "pt";

    if (intl.locale !== localLang) changeLanguage(localLang);
  }, [isAuthenticated]);

  useEffect(() => {
    const { id: userId } = user || {};

    if (companyId && userId) fetchUserNotifications(userId);
  }, [companyId]);

  const messages = defineMessages({
    language: {
      id: "header.language",
    },
    english: {
      id: "header.english",
    },
    spanish: {
      id: "header.spanish",
    },
    portuguese: {
      id: "header.portuguese",
    },
    register: {
      id: "header.register",
    },
    login: {
      id: "header.login",
    },
    selectOneCompany: {
      id: "companyselector.selectOneCompany",
    },
    helpTitle: {
      id: "help.title",
    },
    configTitle: {
      id: "config.title",
    },
    selectCompany: {
      id: "companyselector.selectCompany",
    },
    favorites: {
      id: "favorites.title",
    },
    changeCompany: {
      id: "companyselector.changeCompany",
    },
    seeCompanyDetails: {
      id: "companyselector.seeDetails",
    },
    guideTour: {
      id: "header.guideTour",
    },
    domain: {
      id: "global.domain",
    },
    selectedDomain: {
      id: "global.selectedDomain",
    },
    selectedCompany: {
      id: "global.selectedCompany",
    },
    changeDomain: {
      id: "global.changeDomain",
    },
  });

  const tooltipChangeLanguageMenu = (
    <Tooltip id="tooltip_delete">
      {intl.formatMessage(messages.language)}
    </Tooltip>
  );

  const tooltipCompany = (
    <Tooltip id="tooltip_company">
      {intl.formatMessage(messages.selectedCompany)}
    </Tooltip>
  );

  const tooltipDomain = (
    <Tooltip id="tooltip_domain">
      {intl.formatMessage(messages.selectedDomain)}
    </Tooltip>
  );

  const tooltipSelectCompanyLink = (
    <Tooltip id="tooltip_selectCompany">
      {intl.formatMessage(messages.selectOneCompany)}
    </Tooltip>
  );

  let selectCompanyLink = "";

  if (selectedCompany) {
    selectCompanyLink = (
      <li id="selected-company">
        <OverlayTrigger placement="bottom" overlay={tooltipCompany}>
          <a className="dropdown-toggle" data-toggle="dropdown">
            <i className="fas fa-industry" aria-hidden="true" />
            <span className="hidden-xs">{`  ${selectedCompany.name}`}</span>
          </a>
        </OverlayTrigger>
        <ul className="dropdown-menu">
          <li>
            <Link to={{ pathname: "/companies" }} replace>
              {intl.formatMessage(messages.changeCompany)}
            </Link>
          </li>
          {checkAccess(
            ["OWNER", "ADMIN"],
            <li>
              <Link
                to={(location) => ({
                  ...location,
                  pathname: "/company",
                })}
                replace
              >
                {intl.formatMessage(messages.seeCompanyDetails)}
              </Link>
            </li>
          )}
        </ul>
      </li>
    );
  } else {
    selectCompanyLink = (
      <li id="selectCompanyUserMenu">
        <OverlayTrigger placement="bottom" overlay={tooltipSelectCompanyLink}>
          <Link to="/companies">
            <i className="fas fa-industry" aria-hidden="true" />
            <span className="hidden-xs">
              {`   ${intl.formatMessage(messages.selectCompany)}`}
            </span>
          </Link>
        </OverlayTrigger>
      </li>
    );
  }

  const selectedDomainLink = () => {
    const { namespace = "" } = currentDomain || {};

    return (
      <li id="selected-domain">
        <OverlayTrigger placement="bottom" overlay={tooltipDomain}>
          <a className="dropdown-toggle" data-toggle="dropdown">
            <i className="fas fa-dice-d6" aria-hidden="true" />
            <span className="hidden-xs">{`  ${namespace}`}</span>
          </a>
        </OverlayTrigger>
        <ul className="dropdown-menu">
          <li>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => setDomainSelectionModal(true)}
            >
              {intl.formatMessage(messages.changeDomain)}
            </div>
          </li>
          {checkAccess(
            ["OWNER"],
            <li>
              <Link to={{ pathname: "/domainconfig" }} replace>
                {intl.formatMessage(messages.seeCompanyDetails)}
              </Link>
            </li>
          )}
        </ul>
      </li>
    );
  };

  const tooltipConfigurationsMenu = <Tooltip id="tooltip_config" />;

  const getLanguageIcon = () => {
    const { locale = "" } = intl || {};
    if (locale === "pt") return "brazil-flag-background";
    if (locale === "en") return "en-flag-background";
    if (locale === "es") return "es-flag-background";
  };

  const changeLanguageMenu = (
    <li
      id="languageMenu"
      className="dropdown languageHeaderLi"
      title={intl.formatMessage(messages.language)}
      style={{ width: "100px" }}
    >
      <OverlayTrigger placement="bottom" overlay={tooltipChangeLanguageMenu}>
        <a
          className="dropdown-toggle"
          data-toggle="dropdown"
          id="linkLanguageMenu"
          style={{ whiteSpace: "nowrap", display: "flex" }}
        >
          <div className={`flag-background ${getLanguageIcon()}`} />
          {intl && intl.locale ? getLanguageFullName(intl.locale) : ""}
        </a>
      </OverlayTrigger>
      <ul id="languageMenuDropdown" className="dropdown-menu teste">
        <li>
          <a onClick={() => changeLanguage("pt")}>
            {intl.formatMessage(messages.portuguese)}
          </a>
        </li>
        <li>
          <a onClick={() => changeLanguage("en")}>
            {intl.formatMessage(messages.english)}
          </a>
        </li>
        <li>
          <a onClick={() => changeLanguage("es")}>
            {intl.formatMessage(messages.spanish)}
          </a>
        </li>
      </ul>
    </li>
  );

  const domainOption = (
    <Link to="/domainconfig">
      <div>
        <i className="fas fa-dice-d6" /> {intl.formatMessage(messages.domain)}
      </div>
    </Link>
  );

  const logOptions = (
    <Link to="/logs">
      <div>
        <i className="fas fa-archive" /> Logs
      </div>
    </Link>
  );

  const configurationsMenu = (
    <li id="configurationsMenu" className="dropdown" style={{ width: "50px" }}>
      <OverlayTrigger placement="right" overlay={tooltipConfigurationsMenu}>
        <a
          className="dropdown-toggle"
          data-toggle="dropdown"
          id="linkConfigurationsMenu"
        >
          <i className="fas fa-cog" style={{ fontSize: "20px" }} />
        </a>
      </OverlayTrigger>
      <ul id="configurationsMenuDropdown" className="dropdown-menu teste">
        <li>{domainOption}</li>
        <li>{logOptions}</li>
      </ul>
    </li>
  );

  const userLinks = (
    <ul className="nav navbar-nav">
      <BoxZoom>
        <IconMinus className="fas fa-search-minus" />
        <CustomPopover>
          Para uma melhor visualização reduza o zoom do navegador
        </CustomPopover>
      </BoxZoom>
      {changeLanguageMenu}
      {!onlyOneDomain && isAppHmlorDev && selectedDomainLink()}
      {selectCompanyLink}
      <NotificationsMenu />
      <UserMenu doLogout={doLogout} user={user} profilePath="/profile" />
      {checkAccess(["OWNER"], configurationsMenu)}
    </ul>
  );

  const guestLinks = (
    <ul className="nav navbar-nav">
      {changeLanguageMenu}
      <li id="registerIcon">
        <a
          title={intl.formatMessage(messages.register)}
          onClick={() => loginWithRedirect({})}
        >
          <i className="fas fa-user-plus" aria-hidden="true" />
        </a>
      </li>
      <li id="loginIcon">
        <a
          title={intl.formatMessage(messages.login)}
          onClick={() => loginWithRedirect({})}
        >
          <i className="fas fa-sign-in-alt" aria-hidden="true" />
        </a>
      </li>
    </ul>
  );

  const selectedCompanyId = selectedCompany && selectedCompany.id;

  const homeLocation = {
    pathname: "/",
    state: { fromDashboard: true },
  };

  const getHashOverview = () => {
    const { hash } = window.location;
    const replaceHash = hash.replace("#/analytics/", "");

    return replaceHash;
  };

  const getOverviewRoute = () => {
    const routes = [];

    function addRoute(title, path, condition) {
      routes.push({
        title,
        path,
        condition,
      });
    }

    const numberId = parseInt(getHashOverview(), 10);

    if (numberId) {
      addRoute(
        `${intl.formatMessage({ id: getToolIntlSlug(numberId) })} Analytics`,
        `/analytics/${getHashOverview()}`
      );
    }

    return routes;
  };

  const getDynamicRoutes = () => {
    const routes = [];

    function addRoute(title, path, condition) {
      routes.push({
        title,
        path,
        condition,
      });
    }

    if (selectedWorkspace) {
      addRoute(selectedWorkspace.name, "/workspace");
    }

    if (selectedTool && selectedTool.tool) {
      addRoute(
        intl.formatMessage({ id: getToolIntlSlug(selectedTool.tool.id) }),
        `/tool/${selectedTool.id}`,
        true
      );
    }

    return routes;
  };

  const createRoutes = () => {
    function getRouteTool() {
      let arrayRoute = [];

      getDynamicRoutes().forEach((route) => {
        if (route.condition) {
          arrayRoute = getDynamicRoutes();
        }
      });

      return arrayRoute;
    }

    function getRouteWorkspace() {
      const arrayRoute = [];

      getDynamicRoutes().forEach((route) => {
        if (!route.condition) {
          arrayRoute.push(route);
        }
      });

      return arrayRoute;
    }

    const routes = {
      routeTool: () => getRouteTool(),
      routeWorkspace: () => getRouteWorkspace(),
      routeOverview: () => getOverviewRoute(),
    };

    return routes;
  };

  if (authContext) {
    authContext.acquireToken("rookau-local", (error, tkn) => {
      if (error || !tkn) {
        console.log(`ERROR:\n\n${error}`);
        return;
      }
      setAuthorizationToken(tkn);
    });
  }

  return (
    <header className="main-header">
      <nav className="navbar navbar-static-top">
        {isAuthenticated && (
          <a
            className="sidebar-toggle"
            data-toggle="push-menu"
            onClick={toggleCollapsed}
          >
            <span className="sr-only">Toggle navigation</span>
            <i className="fas fa-bars" />
          </a>
        )}
        <Link
          to={homeLocation}
          replace
          className={classnames("logos", {
            "logo-logged": isAuthenticated === true,
          })}
          onClick={() => appHomeRequests(selectedCompanyId)}
        >
          <div
            style={{
              width: "100%",
              height: "auto",
              padding: "2px 20px 0px 0px",
            }}
          >
            {companyLogoOptions?.logo &&
            !companyLogoOptions?.default &&
            isAuthenticated ? (
              <img
                src={`https://rookau-company-logos.s3-sa-east-1.amazonaws.com/${companyLogoOptions?.logo}`}
                alt="Logo Rookau"
                className="img-logo"
              />
            ) : (
              <>
                {domainLogo ? (
                  <img
                    src={`https://rookau-company-logos.s3-sa-east-1.amazonaws.com/${domainLogo}`}
                    alt="Logo Rookau"
                    className="img-logo"
                  />
                ) : (
                  <img
                    src={logo}
                    alt="Logo Rookau"
                    className="img-logo"
                    style={{ width: 119, height: 30 }}
                  />
                )}
              </>
            )}
          </div>
        </Link>

        {isAuthenticated && (
          <BreadcrumbMenu>
            {appRoutes(createRoutes()).map((routesInfo, index) => (
              <React.Fragment key={index}>
                <Route
                  path={routesInfo.path}
                  component={routesInfo.breadcrumb}
                  exact={false}
                />
              </React.Fragment>
            ))}
          </BreadcrumbMenu>
        )}
        <div className="navbar-custom-menu">
          {isAuthenticated ? userLinks : guestLinks}
        </div>
      </nav>

      <DomainSelection
        showModal={domainSelectionModal}
        setShowModal={setDomainSelectionModal}
      />
    </header>
  );
};

Header.propTypes = {
  authEssentials: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  selectedCompany: PropTypes.object,
  selectedWorkspace: PropTypes.object,
  selectedTool: PropTypes.object,
};

function mapStateToProps(state) {
  const { companyData } = state;
  const { access = {}, lockdown = false } = getAccessStates(state);

  const authEssentials = getAuthStates(state);

  return {
    companyId: companyData?.selectedCompany?.id,
    user: state.auth?.user,
    language: state.language,
    access,
    currentDomain: access.currentDomain,
    allUserDomains: access.userDomains,
    lockdown,
    selectedCompany: state.companyData?.selectedCompany,
    companyLogoOptions: state.companyData?.selectedCompany?.logoOptions,
    selectedWorkspace: state.workspacesData?.selectedWorkspace,
    selectedTool: state.selectedTool,
    authEssentials,
    domainLogo: state.domain?.logo,
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    changeLockdown,
    localLogout,
    setLanguage,
    fetchUserNotifications,
    fetchTranslations,
    getSelectedWorkspace,
    appHomeRequests,
  })(Header)
);
