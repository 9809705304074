import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { injectIntl, defineMessages } from "react-intl";
import _ from "lodash";
import moment from "moment";
import { makeStyles, createStyles } from "@material-ui/styles";
import MaterialSingleSelect from "../../../Common/MaterialSingleSelect";
import {
  getLabelByType,
  getAvailableSchedule,
  buildDefaultPhysicalEvaluations,
  getCustomFilterByOptions,
} from "../../../../utils/projectEvaluation";
import MaterialTextField from "../../../Common/MaterialTextField";
import { getObjectDifference } from "../../../../utils/ArrayUtils";

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      "& > div:before": {
        borderBottom: "none !important",
      },
      "& > div > div.MuiInputAdornment-positionEnd": {
        position: "relative",
        left: "-12px",
      },
    },
  }),
);

const messages = defineMessages({
  physicalProgress: {
    id: "tool.project.physical_progress",
  },
  accumulatedPhysicalProgress: {
    id: "tool.project.accumulated_physical_progress",
  },
  advance: {
    id: "tool.project.advance",
  },
});

const PhysicalProgressTable = ({
  physicalAdvancePlannings,
  setPhysicalAdvance,
  schedule,
  intl,
  saveButton,
  saveButtonJSX,
  getInitialYear,
}) => {
  const classes = useStyles();
  const [selectedFilterBy, setSelectedFilterBy] = useState({
    filter: null,
  });
  const [selectedYear, setSelectedYear] = useState(null);
  const [firstMount, setFirstMount] = useState(true);
  const [datesInfo, setDatesInfo] = useState({
    yearsOptions: null,
  });

  const buildDefaultPlannings = () => {
    if (
      schedule &&
      schedule.initialDate &&
      schedule.finalDate &&
      schedule.finalDate !== null
    ) {
      const { availableYears, yearsOptions } = getAvailableSchedule(schedule);

      setDatesInfo({
        ...schedule,
        yearsOptions,
      });

      setPhysicalAdvance((current) => {
        const period = { start: schedule.initialDate, end: schedule.finalDate };
        const buildEvaluations = buildDefaultPhysicalEvaluations(
          availableYears,
          current?.plannings,
          period,
        );

        return {
          ...current,
          plannings: buildEvaluations,
        };
      });
    }
  };

  useEffect(() => {
    if (
      schedule &&
      schedule.deliveryDateSet &&
      (schedule.didUpdate || !schedule.firstDateSet) &&
      physicalAdvancePlannings
    ) {
      buildDefaultPlannings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule, physicalAdvancePlannings, firstMount]);

  const handleChangeFilter = (e) => {
    const value = e && e.target && e.target.value ? e.target.value : "year";

    setFirstMount(false);
    setSelectedYear(null);
    setSelectedFilterBy({
      filter: value,
    });
  };

  const changeSelectedYear = (option) => {
    if (firstMount) {
      setFirstMount(false);
    }

    setSelectedYear(option);
  };
  const getSelectedPhysicalAdvance = (physicalAdvance, selectedYear = 0) => {
    const currentPhysicalAdvance =
      physicalAdvance && physicalAdvance.length > 0 ? physicalAdvance : [];
    let evaluation = {};

    currentPhysicalAdvance.forEach((plan) => {
      if (Number(plan.year) === Number(selectedYear)) {
        evaluation = plan;
      }
    });

    return evaluation;
  };

  const updatePlanningByYear = (plannings, year, month, value) => {
    const updated = [];

    plannings.forEach((plan) => {
      const updatedPlan = { ...plan };
      if (Number(plan.year) === Number(year))
        updatedPlan.monthProgress[month] = value;
      updated.push(updatedPlan);
    });

    return updated;
  };

  const updateEvaluation = (value, year, index) => {
    setPhysicalAdvance((current) => {
      return {
        ...current,
        plannings: updatePlanningByYear(current.plannings, year, index, value),
      };
    });
  };

  const displayBodyByMonths = () => {
    const currentPhysicalAdvance = getSelectedPhysicalAdvance(
      physicalAdvancePlannings,
      firstMount ? getInitialYear().defaultAvailableYear : selectedYear,
    );

    const currentMonthProgress =
      currentPhysicalAdvance && currentPhysicalAdvance.monthProgress
        ? currentPhysicalAdvance.monthProgress
        : [];

    const allMonths = [];
    currentMonthProgress.forEach((advance, index) => {
      if (currentMonthProgress[index] !== -1) {
        const abbreviatedMonthName = moment().month(index).format("MMM");

        allMonths.push(
          <tr key={index}>
            <td className="monthLabel" align="center">
              {abbreviatedMonthName}
            </td>
            <td align="center" className="monthValue formattedTD">
              <MaterialTextField
                variant="standard"
                id={`${abbreviatedMonthName}Advance`}
                label={abbreviatedMonthName}
                textAlign="center"
                hideLabel
                value={advance || ""}
                className={classes.textField}
                inputStyle={{
                  textAlign: "center",
                  padding: "0px 12px",
                  height: "40px",
                }}
                onChange={(e) =>
                  updateEvaluation(
                    e.target.value,
                    currentPhysicalAdvance.year,
                    index,
                  )
                }
                endAdorment={{
                  text: "% ",
                  position: "end",
                  fontSize: "16px",
                }}
              />
            </td>
          </tr>,
        );
      }
    });

    return allMonths;
  };

  const getTotalAdvance = (currentAdvance) => {
    const currentMonthProgress =
      currentAdvance && currentAdvance.monthProgress
        ? currentAdvance.monthProgress
        : [];

    let progressSum = 0;

    const filteredData = currentMonthProgress.filter((advance) => advance > 0);

    if (filteredData.length > 0) {
      const filteredLength =
        filteredData.length - 1 > 0 ? filteredData.length - 1 : 0;
      const lastValue = Number(filteredData[filteredLength]);
      const firstValue = filteredLength > 0 ? Number(filteredData[0]) : 0;

      progressSum = lastValue - firstValue;
    }

    return progressSum >= 0 ? progressSum : 0;
  };

  const displayBodyByYears = () => {
    const allYears = [];

    physicalAdvancePlannings?.forEach((currentAdvance, index) => {
      const currentYear =
        currentAdvance && currentAdvance.year ? currentAdvance.year : 0;
      const currentYearTotalAdvance = getTotalAdvance(currentAdvance, index);

      allYears.push(
        <tr key={index}>
          <td className="monthLabel" align="center">
            {currentYear}
          </td>
          <td align="center" className="monthValue formattedTD">
            <MaterialTextField
              variant="standard"
              id={`${currentYear}Advance`}
              label={currentYear}
              textAlign="center"
              hideLabel
              value={currentYearTotalAdvance || ""}
              className={classes.textField}
              inputStyle={{
                textAlign: "center",
                padding: "0px 12px",
                height: "40px",
              }}
              disabled
              endAdorment={{
                text: "% ",
                position: "end",
                fontSize: "16px",
              }}
            />
          </td>
        </tr>,
      );
    });

    return allYears;
  };

  const displayBody = () => {
    const tableBody =
      (selectedYear && selectedYear !== "empty") || firstMount
        ? displayBodyByMonths()
        : selectedFilterBy && selectedFilterBy.filter === "total"
        ? displayBodyByYears()
        : null;

    if (tableBody) {
      return tableBody.map((monthLineJSX) => {
        return monthLineJSX;
      });
    }

    return null;
  };

  const displayFilters = () => {
    const labels = getLabelByType("Investment", intl);
    const filterByOptions = schedule.deliveryDateSet
      ? getCustomFilterByOptions(intl)
      : [{ value: "", label: "" }];

    return (
      <Row className="topBox">
        <Col md={5} align="center">
          <MaterialSingleSelect
            id="fourthBox"
            label={labels.seventhBox}
            value={selectedFilterBy.filter ? selectedFilterBy.filter : ""}
            //fourthboxyear
            options={filterByOptions}
            variant="standard"
            onChange={(e) => handleChangeFilter(e)}
            disabled={!schedule.deliveryDateSet}
          />
        </Col>
        {schedule &&
          schedule.deliveryDateSet &&
          selectedFilterBy.filter !== "total" && (
            <Col md={5} align="center">
              <MaterialSingleSelect
                id="fourthBox"
                label={labels.fourthBox}
                value={
                  selectedYear ||
                  (firstMount ? getInitialYear().defaultAvailableYear : "")
                }
                options={
                  datesInfo && datesInfo.yearsOptions
                    ? datesInfo.yearsOptions
                    : getInitialYear().defaultYearOptions
                }
                variant="standard"
                onChange={(e) => changeSelectedYear(e.target.value)}
              />
            </Col>
          )}
        <Col md={2} />
      </Row>
    );
  };

  const displayAdvanceTable = () => {
    return (
      <Row>
        <Col md={12}>
          <table className="simple-month-table ">
            <thead>
              <tr>
                <td className="defaultTd" />
                <td align="center" className="tabletitleTd">
                  {intl.formatMessage(messages.advance)}
                </td>
              </tr>
            </thead>
          </table>

          <div className="fixedScroll progressTableFixedScroll">
            <table className="vertical-simple-table">
              <tbody>{displayBody()}</tbody>
            </table>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <div className="box box-primary">
      <div
        id="header-insight-evaluation"
        className="box-header with-border"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>
          <i className="fas fa-chess-knight" />
          <h3 className="box-title">
            {intl.formatMessage(messages.accumulatedPhysicalProgress)}
          </h3>
        </div>
        {saveButton && saveButtonJSX()}
        <br />
      </div>
      <div className="box-body progressTableBody">
        {displayFilters()}
        {schedule && schedule.deliveryDateSet && displayAdvanceTable()}
      </div>
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  const physicalAdvanceDiff = getObjectDifference(
    prevProps.physicalAdvancePlannings,
    nextProps.physicalAdvancePlannings,
  );
  const scheduleDiff = getObjectDifference(
    prevProps.schedule,
    nextProps.schedule,
  );
  if (Object.keys(physicalAdvanceDiff)?.length > 0) return false;
  if (Object.keys(scheduleDiff)?.length > 0) return false;
  if (!_.isEqual(prevProps.saveButton, nextProps.saveButton)) return false;
  if (!_.isEqual(prevProps.saveButtonJSX, nextProps.saveButtonJSX))
    return false;
  return true;
}

export default React.memo(injectIntl(PhysicalProgressTable), areEqual);
