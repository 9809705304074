import styled from "styled-components";

/**
 * LoginForm
 */

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ErrorText = styled.p`
  color: red;
`;

export const ForgotPassLink = styled.a`
  cursor: pointer;
  display: inline;

  :hover {
    text-decoration: none;
    color: #337ab7;
  }
`;
