import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";

import CycleBaseTable from "./CycleBaseTable";
import TeamTable from "./TeamTable";
import DisplayTeamCicleForms from "./DisplayTeamCicleForms";
import { GridContainer } from "../css/OkrCss";

import { getSelectedToolStates } from "../../../../../customMapStates";
import { getMonthOptions } from "../../../../../../constants/monthsConstants";
import {
  configPlansBody,
  removeIdsFromNewCycleGroup,
} from "../../../../../../utils/okr/entity";
import {
  postPlan,
  deletePlan,
  createSceneriePlan,
} from "../../../../../../actions/okrActions";
import { addNewCycleGroupToCenary } from "../../../../../../actions/tempActions";
import { formatDateCycles } from "../../../../../../utils/okr/valid";
import { getObjectInfoById } from "../../../../../../utils/ArrayUtils";

const Container = (props) => {
  const {
    selectedTool,
    postPlan,
    deletePlan,
    okr,
    allSceneries,
    allThemes,
    userProfile,
    createSceneriePlan,
    addNewCycleGroupToCenary,
  } = props;

  const { id: administrationId, okrPlans, cycleBase } = okr;

  const monthOptions = getMonthOptions();

  function addPlanToBoard(updatedPlan, callback) {
    const newScenerieOrder = allSceneries.length || 0;

    createSceneriePlan(
      selectedTool,
      updatedPlan,
      userProfile,
      newScenerieOrder,
      callback,
    );
  }

  const finishSaveCyclesAndThemes = (
    newUpdatedPlan,
    relatedScenerie,
    doUpdatePlan = true,
  ) => {
    const scenerieThemes = allThemes[relatedScenerie.id];

    addNewCycleGroupToCenary(
      newUpdatedPlan,
      relatedScenerie,
      scenerieThemes,
      doUpdatePlan,
    );
  };

  async function createOrUpdatePlan(plan, cycles) {
    const updatedCycles = formatDateCycles(cycles);

    const newPlan = {
      ...plan,
      cycleGroups: updatedCycles,
    };

    const newUpdatedPlan = configPlansBody(newPlan);

    if (newUpdatedPlan.id !== null) {
      const relatedScenerie = getObjectInfoById(
        newUpdatedPlan.id,
        allSceneries,
        "okrConfigurationPlanId",
      );

      if (relatedScenerie.id) {
        finishSaveCyclesAndThemes(newUpdatedPlan, relatedScenerie, true);
      } else {
        addPlanToBoard(newUpdatedPlan, (savedScenerieData) =>
          finishSaveCyclesAndThemes(
            newUpdatedPlan,
            savedScenerieData.data,
            true,
          ),
        );
      }
    } else if (administrationId && selectedTool.id) {
      await postPlan(administrationId, newUpdatedPlan, (restSavedPlan) => {
        addPlanToBoard(restSavedPlan, (savedScenerieData) => {
          if (savedScenerieData && savedScenerieData.data) {
            const updatedPlanCycleGroups = [];

            if (restSavedPlan && restSavedPlan.cycleGroups) {
              restSavedPlan.cycleGroups.forEach((addedCycleGroupInfo) => {
                updatedPlanCycleGroups.push(
                  removeIdsFromNewCycleGroup(addedCycleGroupInfo),
                );
              });
            }

            const readyToSaveOnTheBoardPlan = {
              ...restSavedPlan,
              cycleGroups: {
                ...updatedPlanCycleGroups,
              },
            };

            finishSaveCyclesAndThemes(
              readyToSaveOnTheBoardPlan,
              savedScenerieData.data,
              false,
            );
          }
        });
      });
    }
  }

  const appendProps = {
    ...props,
    okrPlans,
    createOrUpdatePlan,
    deletePlan,
    monthOptions,
    allThemes,
    idConfig: administrationId,
    allSceneries,
    cycleBase,
    formatDateCycles,
  };

  const defaultPropsCycleBase = {
    getDefaultAddTableLine: props.getDefaultAddTableLine,
    handleOpenModal: props.handleOpenModal,
    getDefaultRemoveColumn: props.getDefaultRemoveColumn,
    openFormDelete: props.openFormDelete,
    cycleBase,
    idConfig: administrationId,
  };

  return (
    <>
      <DisplayTeamCicleForms essencialProps={appendProps} />
      <GridContainer container spacing={4}>
        <Grid item sm={12} md={12} lg={6}>
          <TeamTable {...appendProps} />
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <CycleBaseTable {...defaultPropsCycleBase} />
        </Grid>
      </GridContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  const { allSceneries, allThemes, selectedTool } = getSelectedToolStates(
    state,
  );

  return {
    selectedTool,
    allSceneries,
    allThemes,
    okr: state.okr,
    userProfile: state.user,
  };
};

const mapDispatchToProps = {
  postPlan,
  deletePlan,
  addNewCycleGroupToCenary,
  createSceneriePlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);

Container.propTypes = {
  selectedTool: PropTypes.object.isRequired,
  okr: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
  allThemes: PropTypes.object.isRequired,
  postPlan: PropTypes.func.isRequired,
  getDefaultAddTableLine: PropTypes.func.isRequired,
  getDefaultRemoveColumn: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  openFormDelete: PropTypes.func.isRequired,
  deletePlan: PropTypes.func.isRequired,
  addNewCycleGroupToCenary: PropTypes.func.isRequired,
  createSceneriePlan: PropTypes.func.isRequired,
  allSceneries: PropTypes.array.isRequired,
};
