import React from "react";
import PropTypes from "prop-types";
import SelectItemModal from "../../../../../../Common/SelectItemModal";
import { getValidFunc } from "../../../../../../../utils/validations";

function getCyclePatternsOptions(cyclePatterns) {
  const options = [];

  if (cyclePatterns && Array.isArray(cyclePatterns)) {
    cyclePatterns.forEach((patternInfo) => {
      options.push({
        id: patternInfo.id,
        title: patternInfo.title,
        secondaryText: `${patternInfo.cycles.length} ciclos`,
      });
    });
  }

  return options;
}

const SelectCycleBaseDialog = (props) => {
  const { open, cyclePatterns, intl } = props;

  const onConfirm = getValidFunc(props.onConfirm);
  const onCancel = getValidFunc(props.onCancel);

  const cyclesBaseList = getCyclePatternsOptions(cyclePatterns);

  return (
    <SelectItemModal
      open={open}
      title={intl.formatMessage({ id: "select_pattern_cycles" })}
      itemsList={cyclesBaseList}
      onConfirm={onConfirm}
      onCancel={onCancel}
      singleSelect
    />
  );
};

export default SelectCycleBaseDialog;

SelectCycleBaseDialog.propTypes = {
  open: PropTypes.bool,
  cyclePatterns: PropTypes.array,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

SelectCycleBaseDialog.defaultProps = {
  open: false,
  cyclePatterns: [],
};
