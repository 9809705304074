import React from "react";
import { connect } from "react-redux";
import { Row, Col, OverlayTrigger } from "react-bootstrap";
import { injectIntl, defineMessages } from "react-intl";
import { getProjectStates } from "../../../customMapStates";
import {
  getInsightProjectEvaluation,
  getProjectData,
} from "../../../../utils/projectEvaluation";
import {
  getPopoverByProject,
  getColorClassByStatus,
} from "../../../../utils/projectUtils";
import { getToFixed } from "../../../../utils/MathUtils";

const ProjectCustomization = ({
  allProjectEvaluation,
  insightsToCalc,
  from,
  intl,
}) => {
  const currentInsight = insightsToCalc[0] || {};

  const currentEvaluation = getInsightProjectEvaluation(
    currentInsight.id,
    allProjectEvaluation,
  );

  const projectData = getProjectData(currentEvaluation);

  const {
    deliveryDate = null,
    replanned = null,
    physicalProgress = "",
    projectStatus = "",
  } = projectData;

  const messages = defineMessages({
    noStatus: { id: "no_status" },
  });

  function getGroupedEvaluation() {
    const evaluations = [];

    insightsToCalc?.forEach((insight) => {
      const currentEvaluation = getInsightProjectEvaluation(
        insight.id,
        allProjectEvaluation,
      );
      const projectData = getProjectData(currentEvaluation);
      evaluations.push(projectData);
    });

    return evaluations;
  }

  function getAverageProgress() {
    let sum = 0;
    const evaluations = getGroupedEvaluation().filter(
      ({ deliveryDate }) => deliveryDate !== false,
    );
    evaluations?.forEach(({ physicalProgress }) => {
      sum += physicalProgress;
    });
    const average = sum / evaluations.length;

    return getToFixed(average, 1).replace(".0", "");
  }

  const emptyProject = () => {
    return (
      <div style={{ margin: "0 40px" }}>
        {intl.formatMessage(messages.noStatus)}
      </div>
    );
  };

  const showFlagAndDate = () => {
    return (
      <Col
        style={{
          display: "flex",
        }}
      >
        {replanned ? (
          <OverlayTrigger
            placement="left"
            overlay={getPopoverByProject(currentEvaluation, null, intl)}
          >
            <div style={{ marginRight: "10px" }}>
              <i className="fas fa-flag" />
            </div>
          </OverlayTrigger>
        ) : (
          <div style={{ width: "12px", marginRight: "10px" }} />
        )}
        {deliveryDate ? (
          <OverlayTrigger
            placement="left"
            overlay={getPopoverByProject(currentEvaluation, null, intl)}
          >
            <div
              align="left"
              style={{
                whiteSpace: "nowrap",
                marginRight: "25px",
                width: "120px",
              }}
            >
              <i className="far fa-calendar-check" /> {deliveryDate}
            </div>
          </OverlayTrigger>
        ) : (
          <div
            style={{
              marginRight: "25px",
              width: "120px",
            }}
          />
        )}
      </Col>
    );
  };

  const showPhysicalProgress = (showProgressBar = true) => {
    const customColorClass = {
      class:
        "physicalAdvanceColor progress-bar progress-bar-striped progress-bar-animated",
      color: "#499df5",
    };

    const displayProgress =
      from !== "answer"
        ? getAverageProgress()
        : getToFixed(physicalProgress, 1).replace(".0", "");
    const percentProgress = isNaN(displayProgress) ? "" : `${displayProgress}%`;

    return (
      <Col
        style={{ display: "flex", alignItems: "center" }}
        className="physicalProgressCol"
      >
        {showProgressBar && (
          <div
            style={{ width: "75px", margin: "0 !important" }}
            className="progress progressProject"
          >
            <div
              className={customColorClass.class}
              style={{
                width: `${displayProgress.toString()}%`,
              }}
              aria-valuenow={displayProgress}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        )}
        <div
          align="right"
          style={{ marginLeft: "5px", fontSize: "15px", width: "40px" }}
        >
          {percentProgress}
        </div>
      </Col>
    );
  };

  const showStatusQuo = () => {
    const currentColor = getColorClassByStatus(projectStatus);

    return (
      <Col className="statusQuoCol">
        <div className={`statusQuo ${currentColor}`} />
      </Col>
    );
  };

  const showAll = () => {
    return (
      <Row
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {showFlagAndDate()}
        {showPhysicalProgress()}
        {showStatusQuo()}
      </Row>
    );
  };

  const handleDisplay = () => {
    if (from === "question" || from === "theme")
      return showPhysicalProgress(false);
    if (from !== "answer" && from !== "score") return null;
    if (!projectStatus && physicalProgress === 0) return emptyProject();

    return showAll();
  };

  return handleDisplay();
};

const mapStateToProps = (state) => {
  const { allProjectEvaluation } = getProjectStates(state);

  return {
    allProjectEvaluation,
  };
};
export default injectIntl(connect(mapStateToProps)(ProjectCustomization));
