import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { postLogError } from "../../actions/appActions";

import ErrorSvg from "../../assets/img/error.svg";

import { ErrorAlert } from "./styles";

class ErrorBoundaries extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    this.refreshPage = this.refreshPage.bind(this);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const {
      domainId,
      userId,
      companyId,
      workspaceId,
      selectedToolId,
    } = this.props;

    const token = localStorage.getItem("splace-authenticationToken");

    if (token) {
      postLogError({
        stackTrace: `${error.toString()} - ${error.stack} - ${
          errorInfo.componentStack
        }`,
        domainId,
        userId,
        companyId,
        workspaceId,
        selectedToolId,
        dateTime: moment(),
      });
    }
  }

  refreshPage() {
    this.props.history.push("/");
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorAlert>
          <img src={ErrorSvg} alt="Alert Error" />
          <button className="btn btn-purple" onClick={this.refreshPage}>
            Atualizar página
          </button>
        </ErrorAlert>
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  const { user, access, companyData, workspacesData, selectedTool } = state;

  return {
    userId: user?.id,
    domainId: access?.currentDomain?.id,
    companyId: companyData?.selectedCompany?.id,
    workspaceId: workspacesData?.selectedWorkspace?.id,
    selectedToolId: selectedTool?.id,
  };
};

export default connect(mapStateToProps, {})(ErrorBoundaries);

ErrorBoundaries.propTypes = {
  userId: PropTypes.number,
  domainId: PropTypes.number,
  companyId: PropTypes.number,
  workspaceId: PropTypes.number,
  selectedToolId: PropTypes.number,
  children: PropTypes.node.isRequired,
};

ErrorBoundaries.defaultProps = {
  userId: null,
  domainId: null,
  companyId: null,
  workspaceId: null,
  selectedToolId: null,
};
