import React from "react";
import {
  CUSTOM_ADD_SCENERY,
  CUSTOM_ADD_THEME,
  CUSTOM_ADD_QUESTION,
  CUSTOM_ADD_INSIGHT,
  PARTICIPANT_WORKFLOW_UPDATE,
} from "../../actions/types";
import NewSceneryDialog from "./dialogs/NewSceneryDialog";
import NewThemeDialog from "./dialogs/NewThemeDialog";
import NewQuestionDialog from "./dialogs/NewQuestionDialog";
import NewInsightDialog from "./dialogs/NewInsightDialog";
import UpdateParticipantWorkflow from "./dialogs/UpdateParticipantWorkflow";

export const viewController = (type, params, clearViewCallback) => {
  let displayView = null;
  const appendProps = { type, params, clearViewCallback };

  if (type === CUSTOM_ADD_SCENERY) {
    displayView = <NewSceneryDialog {...appendProps} />;
  }

  if (type === CUSTOM_ADD_THEME) {
    displayView = <NewThemeDialog {...appendProps} />;
  }

  if (type === CUSTOM_ADD_QUESTION) {
    displayView = <NewQuestionDialog {...appendProps} />;
  }

  if (type === CUSTOM_ADD_INSIGHT) {
    displayView = <NewInsightDialog {...appendProps} />;
  }

  if (type === PARTICIPANT_WORKFLOW_UPDATE) {
    displayView = <UpdateParticipantWorkflow {...appendProps} />;
  }

  return displayView;
};
