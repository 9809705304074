import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import FormDialog from "../../FormDialog";
import MaterialTextField from "../../MaterialTextField";
import SelectMemberModal from "../../SelectMemberModal";
import kpiUtils from "../../../../utils/kpis";

const { getThemeInfoById } = kpiUtils;

const initialForm = {
  id: null,
  title: "",
  description: "",
  manager: {
    id: null,
  },
};

const messages = defineMessages({
  kpi_dialog_title_new_scenario: { id: "kpi_dialog_title_new_scenario" },
  kpi_dialog_title_edit_scenario: { id: "kpi_dialog_title_edit_scenario" },
  kpi_form_title: { id: "kpi_form_title" },
  kpi_form_purpose: { id: "kpi_form_purpose" },
  kpi_form_leader: { id: "kpi_form_leader" },
  kpi_dialog_title_select_member: { id: "kpi_dialog_title_select_member" },
  global_goBack: { id: "global.goBack" },
  global_selectSome: { id: "global.selectSome" },
});

const NewScenarioDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const [memberModalOpen, setMemberModalOpen] = useState(false);
  const [replaceSelectedMembers, setReplaceSelectedMembers] = useState(false);

  const {
    open = false,
    afterCancel,
    afterConfirm,
    kpiAdministration,
    editingForm = {},
    companyMembersAndParticipants = [],
    getKpiConfig,
    intl,
  } = props;

  const isEditing = editingForm.type === "newTheme";

  useEffect(() => {
    if (editingForm && isEditing && kpiAdministration) {
      const editingThemeIndex = getEditingIndex(kpiAdministration.themes);
      const themeInfo = kpiAdministration.themes[editingThemeIndex];

      if (themeInfo) {
        setForm({ ...themeInfo });
        setReplaceSelectedMembers(true);
      }
    }
  }, [editingForm, kpiAdministration, isEditing]);

  const updateFormField = (field, newVal) => {
    setForm({ ...form, [field]: newVal });
  };

  const resetForm = () => {
    setForm(initialForm);
  };

  const getEditingIndex = (list) => {
    return getThemeInfoById(editingForm.params.themeId, list, true);
  };

  const saveAdministration = (newAdministration) => {
    resetForm();

    if (typeof afterConfirm === "function") afterConfirm(newAdministration);
  };

  const saveNewTheme = async () => {
    const data = await getKpiConfig();

    saveAdministration({
      ...data,
      themes: [...data.themes, { ...form }],
    });
  };

  const editSaveTheme = async () => {
    const data = await getKpiConfig();

    if (data && data.id) {
      const editingThemeIndex = getEditingIndex(data.themes);

      const updatedThemes = [...data.themes];
      updatedThemes[editingThemeIndex] = form;

      saveAdministration({
        ...data,
        themes: updatedThemes,
      });
    }

    resetForm();
  };

  const handleConfirm = () => {
    if (!isEditing) saveNewTheme();
    if (isEditing) editSaveTheme();
  };

  const handleCancel = () => {
    resetForm();

    if (typeof afterCancel === "function") afterCancel();
  };

  const updateSelectedMember = (selectedList) => {
    let newSelectedMember = null;

    if (selectedList && selectedList[0]) newSelectedMember = selectedList[0];

    setForm({
      ...form,
      manager: {
        id: newSelectedMember.id,
        type: newSelectedMember.type,
      },
    });
    setMemberModalOpen(false);
  };

  const isValidForm = () => {
    if (form.title < 2) return false;

    return true;
  };

  const getMemberDisplayName = (memberId) => {
    let displayName = intl.formatMessage(messages.global_selectSome);

    if (memberId) {
      companyMembersAndParticipants.forEach((memberInfo) => {
        if (memberId === memberInfo.id) displayName = memberInfo.name;
      });
    }

    return displayName;
  };

  const formManagerId = form.manager && form.manager.id ? form.manager.id : 0;
  const currentSelectedMember = formManagerId ? [formManagerId] : [];

  const formTitle = !isEditing
    ? intl.formatMessage(messages.kpi_dialog_title_new_scenario)
    : intl.formatMessage(messages.kpi_dialog_title_edit_scenario);

  return (
    <>
      {memberModalOpen && (
        <SelectMemberModal
          title={intl.formatMessage(messages.kpi_dialog_title_select_member)}
          membersList={companyMembersAndParticipants || []}
          onConfirm={(selected, selectedList) =>
            updateSelectedMember(selectedList)
          }
          onCancel={() => setMemberModalOpen(false)}
          open={memberModalOpen}
          cancelText={intl.formatMessage(messages.global_goBack)}
          replaceSelected={replaceSelectedMembers}
          initialSelected={currentSelectedMember}
          replaceCallback={() => setReplaceSelectedMembers(false)}
          singleSelect
        />
      )}
      <FormDialog
        open={open}
        title={formTitle}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        blockConfirm={!isValidForm()}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialTextField
              id="title"
              label={intl.formatMessage(messages.kpi_form_title)}
              value={form.title}
              onChange={(e) => updateFormField("title", e.target.value)}
            />
          </div>
          <div className="col-xs-12">
            <MaterialTextField
              id="description"
              label={intl.formatMessage(messages.kpi_form_purpose)}
              value={form.description}
              onChange={(e) => updateFormField("description", e.target.value)}
              multiline
            />
          </div>
          {!true && (
            <div className="col-xs-12">
              <MaterialTextField
                id="memberLeader"
                label={intl.formatMessage(messages.kpi_form_leader)}
                value={getMemberDisplayName(formManagerId)}
                inputStyle={{ cursor: "pointer" }}
                onClick={() => setMemberModalOpen(true)}
              />
            </div>
          )}
        </div>
      </FormDialog>
    </>
  );
};

export default injectIntl(NewScenarioDialog);
