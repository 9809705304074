import env from "../../../utils/environmentUtils";

const handleConnectionHost = () => {
  if (env.isPrd()) return "api.rookau.com";
  return env.isHml() ? "api-hml.rookau.com" : window.location.host;
};

const handleAuthToken = () => {
  return localStorage.getItem("splace-authenticationToken");
};

const handleConnectionUrl = (connectionHOST) => {
  const connURL = `//${connectionHOST}/websocket`;
  const authToken = handleAuthToken();
  if (authToken) return `${connURL}?access_token=${authToken}`;
  return connURL;
};

const handleDebug = (debug, connectionHOST, connectionURL) => {
  if (debug) {
    const privateURL =
      process.env.NODE_ENV === "development" ? connectionURL : "PRIVATE";

    console.log("Websocket Config:", {
      connectionHOST,
      connectionURL: privateURL,
    });
  }
};

const connectionConfigs = (params = {}) => {
  const { debug = true } = params;
  const connectionHOST = handleConnectionHost();
  const connectionURL = handleConnectionUrl(connectionHOST);
  handleDebug(debug, connectionHOST, connectionURL);

  console.log("connectionHOST=", connectionHOST);

  return { connectionHOST, connectionURL, connectionHeaders: {} };
};

export default connectionConfigs;
