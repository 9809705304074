import styled from "styled-components";

export const IconMinus = styled.i`
  color: #fff;
  padding: 5px;
`;

export const BoxZoom = styled.li`
  padding-top: 12px;
  position: relative;
  cursor: pointer;
`;

export const CustomPopover = styled.div`
  position: absolute;
  width: 240px;
  border-radius: 4px;
  text-align: center;

  display: ${(props) => (props.open ? "block" : "none")};

  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  padding: 5px;
  background-color: #fff;
  top: 40px;
  font-weight: 400;
  color: #555;
  left: -106px;
  z-index: 999;

  &::before {
    border: 6px solid transparent;
    border-bottom-color: #fff;
    content: "";
    top: -12px;
    right: 114px;
    position: absolute;
  }
`;
