import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import {
  addInsightTimeline,
  editCommentaryOnInsightTimeline,
} from "../../../actions/insightTimelineActions";

import InsightTimelineView from "./InsightTimelineView";
import { grantInsightAccess } from "../../../actions/accessControllerActions";
import { sendInsightMentionNotification } from "../../../actions/notifications/controllerActions";
import { useConnectedComments } from "../../../utils/comments/hooks";

const InsightTimelineContainer = ({
  selectedInsight = {},
  user = {},
  useBox,
  allComments,
  connectedComments = [],
  sendInsightMentionNotification,
  allMembersCompany = [],
  domainUsers = [],
  grantInsightAccess,
  entityAccess,
  ...props
}) => {
  const { comments } = useConnectedComments({
    allComments,
    connectedInsights: connectedComments,
  });
  const [newComment, setNewComment] = useState("");
  const [mentionConfig, setMentionConfig] = useState({
    sendNotification: false,
    userIds: [],
  });

  useEffect(() => {
    const { sendNotification = false, userIds = [] } = mentionConfig || {};

    if (sendNotification && userIds.length > 0) {
      setMentionConfig({ sentNotification: false });
      const { id: selectedInsightId = 0 } = selectedInsight || {};

      userIds.forEach((userId) =>
        sendInsightMentionNotification(selectedInsightId, userId),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityAccess]);

  function handleAddComment(newComments, files, allMentioned) {
    const { id: selectedInsightId = 0 } = selectedInsight || {};

    props.addInsightTimeline(
      selectedInsightId,
      newComments,
      user.id,
      [],
      files,
    );

    const users = domainUsers.filter(({ id = 0, email = "" }) => {
      if (allMentioned.indexOf(email) > -1) {
        grantInsightAccess({
          id,
          entityId: selectedInsightId,
          role: "COLLABORATOR",
        });

        return true;
      }

      return false;
    });

    const notificationObj = {
      sendNotification: true,
      userIds: users.map((user) => user.id),
    };

    if (users.length > 0) setMentionConfig(notificationObj);

    setNewComment("");
  }

  const getAllUsersByInsight = useMemo(() => {
    const formatedUser = allMembersCompany?.map((user) => ({
      id: user.id,
      value: user.name,
      email: user.email,
    }));

    const filteredUsers = formatedUser?.filter((user) => user.id !== null);

    return filteredUsers;
  }, [allMembersCompany]);

  function handleUpdateInput({ target = {} }) {
    const { value = "" } = target;

    setNewComment(value);
  }

  if (selectedInsight) {
    return (
      <InsightTimelineView
        newComment={newComment}
        content={[
          ...comments.map((comment) => {
            return { ...comment };
          }),
        ]}
        addComment={handleAddComment}
        onUpdateInput={handleUpdateInput}
        editCommentaryOnInsightTimeline={props.editCommentaryOnInsightTimeline}
        useBox={useBox}
        selectedInsight={selectedInsight}
        mentionedPeople={getAllUsersByInsight}
      />
    );
  }
  return null;
};

const mapStateToProps = (state) => {
  const {
    user,
    insightsTimeline,
    access,
    companyData,
    selectedTool,
    workspacesData,
  } = state;

  return {
    user,
    allComments: insightsTimeline.allComments,
    allMembersCompany: companyData?.members,
    selectedCompanyData: companyData.selectedCompany,
    selectedWorkspaceData: workspacesData.selectedWorkspace,
    selectedTool: selectedTool.tool,
    domainUsers: access.domainUsers,
    entityAccess: access.entityAccess,
  };
};

export default connect(mapStateToProps, {
  addInsightTimeline,
  editCommentaryOnInsightTimeline,
  grantInsightAccess,
  sendInsightMentionNotification,
})(InsightTimelineContainer);
