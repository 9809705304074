import React from "react";
import { Row, Col } from "react-bootstrap";
import { injectIntl, defineMessages } from "react-intl";
import _ from "lodash";
import { getObjectDifference } from "../../../../utils/ArrayUtils";

const messages = defineMessages({
  indicators: {
    id: "tool.project.indicators",
  },
  planned: {
    id: "tool.project.reportPlanned",
  },
  realized: {
    id: "global.realized",
  },
  activities: {
    id: "cenaryResume.label.activities",
  },
  financial: {
    id: "tool.project.financial",
  },
  effort: {
    id: "tool.project.effort",
  },
  status: {
    id: "global.status",
  },
  stage: {
    id: "tool.project.stage",
  },
  conclusionDate: {
    id: "tool.project.conclusionDate",
  },
  keyProjectIndicators: {
    id: "tool.project.KeyProjectIndicators",
  },
  projectTimeDays: {
    id: "tool.project.projectTimeDays",
  },
  performance: {
    id: "tool.bigPicturePeople.perfomance",
  },
  overallStatus: {
    id: "tool.project.overallStatus",
  },
  physical: {
    id: "tool.project.physical_progress",
  },
  schedule: {
    id: "tool.project.date_schedule",
  },
  scope: {
    id: "tool.project.scope",
  },
  exceeded: {
    id: "tool.project.exceeded",
  },
});

const IndicatorsTable = ({ indicators, saveButton, saveButtonJSX, intl }) => {
  const displayTableHead = () => {
    return (
      <thead className="indicators">
        <tr className="indicatorsHeaderColumn">
          <td align="center" className="titleTD indicatorsColumn">
            {intl.formatMessage(messages.indicators)}
          </td>
          <td
            align="center"
            className="titleTD indicatorsHeaderColumn"
            style={{ width: "100px" }}
          >
            {intl.formatMessage(messages.planned)}
          </td>
          <td
            align="center"
            className="titleTD indicatorsHeaderColumn"
            style={{ width: "100px" }}
          >
            {intl.formatMessage(messages.realized)}
          </td>
        </tr>
      </thead>
    );
  };

  const displayTableBody = () => {
    const {
      projectTime = {},
      projectActivities = {},
      projectAdvance = {},
      projectStage = null,
      projectConclusion = "",
      projectStatus = null,
      scope = null,
    } = indicators || {};

    const { effort = {}, financial = {}, schedule = 0 } = projectAdvance || {};
    const { financialStatus = "" } = financial || {};

    const statusPercentage = projectStatus
      ? `${projectStatus} - ${schedule}%`
      : `${schedule}%`;

    return (
      <>
        <tr>
          <td align="left" className="indicatorsColumn">
            <i className="far fa-calendar-alt" />{" "}
            {intl.formatMessage(messages.projectTimeDays)}
          </td>
          <td align="center">{projectTime && projectTime.planned} </td>
          <td align="center">{projectTime && projectTime.realized}</td>
        </tr>
        <tr>
          <td align="left" className="indicatorsColumn">
            <i className="fas fa-chess-knight" />{" "}
            {intl.formatMessage(messages.activities)}
          </td>
          <td align="center">
            {projectActivities && projectActivities.planned}
          </td>
          <td align="center">
            {projectActivities && projectActivities.realized}
          </td>
        </tr>
        <tr>
          <td align="left" className="indicatorsColumn">
            <i className="fas fa-dollar-sign" />{" "}
            {intl.formatMessage(messages.financial)}
          </td>
          <td align="center" style={{ paddingLeft: "0px" }}>
            <div>
              {projectAdvance && projectAdvance.financial?.value.planned
                ? projectAdvance.financial.value.planned
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                : 0}
            </div>
          </td>
          <td align="center" style={{ paddingLeft: "0px" }}>
            {projectAdvance && projectAdvance.financial?.value.realized
              ? projectAdvance.financial.value.realized
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
              : 0}
          </td>
        </tr>
        <tr>
          <td align="left" className="indicatorsColumn">
            <i className="fas fa-clock" /> {intl.formatMessage(messages.effort)}
          </td>
          <td align="center" style={{ paddingLeft: "0px" }}>
            {projectAdvance && projectAdvance.effort?.value.planned
              ? projectAdvance.effort.value.planned
              : 0}
          </td>
          <td align="center" style={{ paddingLeft: "0px" }}>
            {projectAdvance && projectAdvance.effort?.value.realized
              ? projectAdvance.effort.value.realized
              : 0}
          </td>
        </tr>
        <tr style={{ backgroundColor: "#efefef" }}>
          <td align="center" className="indicatorsColumn" colSpan="1">
            {intl.formatMessage(messages.performance)}
          </td>
          <td align="center" className="indicatorsColumn" colSpan="1">
            Baseline
          </td>
          <td align="center" className="indicatorsColumn" colSpan="1">
            Deadline
          </td>
        </tr>
        <tr>
          <td align="left" className="indicatorsColumn">
            <i className="fas fa-percentage" />{" "}
            {intl.formatMessage(messages.financial)}
          </td>
          <td align="center" style={{ paddingLeft: "0px" }}>
            {projectAdvance &&
            projectAdvance.financial?.percentage.baselineSpent
              ? projectAdvance.financial.percentage.baselineSpent
              : 0}
            %
          </td>
          <td align="center" style={{ paddingLeft: "0px" }}>
            {projectAdvance &&
            projectAdvance.financial?.percentage.deadlineSpent
              ? projectAdvance.financial.percentage.deadlineSpent
              : 0}
            %
          </td>
        </tr>
        <tr style={{ backgroundColor: "#efefef" }}>
          <td colSpan="1" />
          <td align="center" className="indicatorsColumn" colSpan="2">
            {intl.formatMessage(messages.overallStatus)}
          </td>
        </tr>
        <tr>
          <td align="left" className="indicatorsColumn">
            {intl.formatMessage(messages.physical)}
          </td>
          <td colSpan="2" align="center">
            {projectAdvance && projectAdvance.physical}%
          </td>
        </tr>
        <tr>
          <td align="left" className="indicatorsColumn">
            {intl.formatMessage(messages.effort)}
          </td>
          <td colSpan="2" align="center">
            {effort.percentage?.result}%
          </td>
        </tr>
        <tr>
          <td align="left" className="indicatorsColumn">
            {intl.formatMessage(messages.stage)}
          </td>
          <td colSpan="2" align="center">
            {projectStage && projectStage}
          </td>
        </tr>
        <tr>
          <td align="left" className="indicatorsColumn">
            {intl.formatMessage(messages.schedule)}
          </td>
          <td colSpan="2" align="center">
            {statusPercentage}
          </td>
        </tr>
        <tr>
          <td align="left" className="indicatorsColumn">
            {intl.formatMessage(messages.scope)}
          </td>
          <td colSpan="2" align="center">
            {scope}
          </td>
        </tr>
        <tr>
          <td align="left" className="indicatorsColumn">
            {intl.formatMessage(messages.financial)}
          </td>
          <td colSpan="2" align="center">
            {financialStatus}
          </td>
        </tr>
        <tr>
          <td align="left" className="indicatorsColumn">
            {`${intl.formatMessage(messages.exceeded)} ($)`}
          </td>
          <td colSpan="2" align="center">
            {projectAdvance && projectAdvance.financial?.value.exceeded
              ? projectAdvance.financial.value.exceeded
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
              : 0}
          </td>
        </tr>
        <tr>
          <td align="left" className="indicatorsColumn">
            {intl.formatMessage(messages.conclusionDate)}
          </td>
          <td colSpan="2" align="center">
            {projectConclusion && projectConclusion}
          </td>
        </tr>
      </>
    );
  };

  return (
    <div className="box box-primary">
      <div id="header-insight-evaluation" className="box-header with-border">
        <div>
          <i className="fas fa-arrows-alt" />
          <h3 className="box-title">
            {intl.formatMessage(messages.keyProjectIndicators)}
          </h3>
        </div>
        {saveButton && saveButtonJSX()}
      </div>
      <div className="box-body indicatorStyle">
        <Row style={{ width: "100%" }}>
          <Col md={12} style={{ padding: "0px" }}>
            <table className="simple-table" style={{ marginTop: "0px" }}>
              {displayTableHead()}
              <tbody className="indicators">{displayTableBody()}</tbody>
            </table>
          </Col>
        </Row>
      </div>
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  const indicatorsDiff = getObjectDifference(
    prevProps.indicators,
    nextProps.indicators,
  );
  if (Object.keys(indicatorsDiff)?.length > 0) return false;
  if (!_.isEqual(prevProps.saveButton, nextProps.saveButton)) return false;
  if (!_.isEqual(prevProps.saveButtonJSX, nextProps.saveButtonJSX))
    return false;
  return true;
}

export default injectIntl(React.memo(IndicatorsTable, areEqual));
