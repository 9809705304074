import React from "react";
import PropTypes from "prop-types";
import { getDefaultAddTableLine } from "../../../../../../../utils/tableUtils";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import DeleteColumn from "../../../../../../Common/Tables/DeleteColumn";
import { simpleEditArrayOnAdministration } from "../../../../../../../utils/skillsAssessment/manipulation";
import { orderArrayByObjAttr } from "../../../../../../../utils/ArrayUtils";

const TableComplementaryEvaluations = ({
  toolAdministration,
  updateAdministration,
  title,
  evaluations = [],
  addNew,
  openDeleteEvaluation,
}) => {
  const handleEditEvaluation = (
    evaluationInfo,
    editingField = "title",
    newValue,
  ) => {
    if (evaluationInfo.id) {
      const updated = {
        ...evaluationInfo,
        [editingField]: newValue,
      };

      const newAdministration = simpleEditArrayOnAdministration(
        toolAdministration,
        "complementaryEvaluations",
        evaluationInfo.id,
        updated,
        "id",
      );

      updateAdministration(newAdministration);
    }
  };

  const ordered = orderArrayByObjAttr(evaluations, "title");

  return (
    <table className="simple-table">
      <thead>{getDefaultAddTableLine(addNew)}</thead>
      <tbody>
        <tr className="thead">
          <td width="30" />
          <td width="500">
            <b>{title}</b>
          </td>
          <td>DESCRIÇÃO</td>
          <td width="30" />
        </tr>
        {ordered?.map((evaluationInfo, index) => {
          return (
            <tr key={evaluationInfo.id}>
              <td align="center">{index + 1}</td>
              <SimpleExcelCollumn
                id={`evalTitle${evaluationInfo.id}`}
                value={evaluationInfo.title}
                title={evaluationInfo.title}
                onChange={(e) =>
                  handleEditEvaluation(evaluationInfo, "title", e.target.value)
                }
              />
              <SimpleExcelCollumn
                id={`evalDescription${evaluationInfo.id}`}
                value={evaluationInfo.description}
                title={evaluationInfo.description}
                onChange={(e) =>
                  handleEditEvaluation(
                    evaluationInfo,
                    "description",
                    e.target.value,
                  )
                }
              />
              <DeleteColumn
                handleDelete={() => openDeleteEvaluation(evaluationInfo.id)}
              />
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

TableComplementaryEvaluations.propTypes = {
  title: PropTypes.any.isRequired,
  evaluations: PropTypes.array.isRequired,
  addNew: PropTypes.func.isRequired,
  openDeleteEvaluation: PropTypes.func.isRequired,
  toolAdministration: PropTypes.object.isRequired,
  updateAdministration: PropTypes.func.isRequired,
};

export default TableComplementaryEvaluations;
