const en = {
  measu_editable: "Editable",
  measu_no: "No",
  measu_yes: "Yes",
  measu_example_format: "Example format",
  measu_invalid_doc: "Document violated, export another.",
  measu_invalid_performance_note: "Performance note invalid.",
  measu_invalid_genre: "Invalid gender.",
  measu_invalid_area: "Current area invalid.",
  measu_invalid_leading: "Leadership invalid.",
  measu_invalid_current_position: "Current position invalid.",
  measu_invalid_formation: "Invalid training.",
  measu_invalid_performance_status: "Invalid performance status.",
  measu_invalid_language: "Invalid language (s)",
  measu_limit_value: "Limit Value",
  measu_invalid_year: "Invalid year.",
  measu_invalid_date: "Invalid Date.",
  measu_invalid_month:
    "Month does not exist in the annual planning of the Project.",
  measu_invalid_physical_progress: "Physical progress invalid.",
  measu_invalid_investment: "Invalid investment amount.",
  measu_invalid_effort: "Invalid number of hours.",
  measu_import_done_in: "Data import done in ",
  measu_invalid_frequency: "Invalid frequency.",
  measu_invalid_actual_value: "Invalid current value.",
  measu_invalid_last_period_value: "Invalid last period value.",
  measu_update_database: "All Right! Click on confirm to update the database",
  measu_something_went_wrong: "Something went wrong! File not validated",
  measu_model_confirmation: "Model Confirmation",
  measu_model_validation: "Model Validation",
  measu_update: "Update",
  measu_is_this_the_correct_model: "Is this the correct model?",
  measu_import_validated_data: "Import validated data",
  measu_unknown_step: "Unknown step",
  measu_back: "Back",
  measu_importing_document: "Importing Document",
  measu_error_description: "Error Description",
  measu_validated_data: "Validated Data",
  measu_non_validated_data: "Non Validated Data",
  measu_lines: " line(s)",
  measu_values: "Values ",
  measu_option: "Option ",
  measu_see_options: "See Options",
  measu_import: "Import",
  measu_export: "Export",
  measu_pre_requirements: "Requirements for export:",
  measu_responsible_project: "Projects that you are responsible for.",
  measu_planned_projects: "Projects that contain start date and deadline.",
};

const es = {
  measu_editable: "Editable",
  measu_no: "No",
  measu_yes: "Si",
  measu_example_format: "Formato de Ejemplo",
  measu_invalid_doc: "Document violated, export another.",
  measu_invalid_performance_note: "Performance note invalid.",
  measu_invalid_genre: "Invalid gender.",
  measu_invalid_area: "Current area invalid.",
  measu_invalid_leading: "Leadership invalid.",
  measu_invalid_current_position: "Current position invalid.",
  measu_invalid_formation: "Invalid training.",
  measu_invalid_performance_status: "Invalid performance status.",
  measu_invalid_language: "Invalid language (s)",
  measu_limit_value: "Valor límite",
  measu_invalid_year: "Año inválido",
  measu_invalid_date: "Fecha Invalida.",
  measu_invalid_month:
    "El mes no existe en la planificación anual del Proyecto",
  measu_invalid_physical_progress: "El progreso físico no es válido",
  measu_invalid_investment: "Monto de inversión no válido",
  measu_invalid_effort: "Número de horas inválido",
  measu_import_done_in: "Importación de datos realizada en ",
  measu_invalid_frequency: "Frecuencia inválida",
  measu_invalid_actual_value: "Valor actual no válido",
  measu_invalid_last_period_value: "Valor del Período Anterior no válido",
  measu_update_database:
    "¡Muy bien! Haga clic en confirmar para actualizar la base de datos",
  measu_something_went_wrong: "¡Algo salió mal! Archivo no validado",
  measu_model_confirmation: "Confirmación del modelo",
  measu_model_validation: "Validación del modelo",
  measu_update: "Actualizar",
  measu_is_this_the_correct_model: "¿Es este el modelo correcto?",
  measu_import_validated_data: "Importar datos validados",
  measu_unknown_step: "Paso desconocido",
  measu_back: "Atrás",
  measu_importing_document: "Importando documento",
  measu_error_description: "Descripción del error",
  measu_validated_data: "Datos validados",
  measu_non_validated_data: "Datos no validados",
  measu_lines: "línea(s)",
  measu_values: "Valores",
  measu_option: "Opción ",
  measu_see_options: "Ver Opciones",
  measu_import: "Importar",
  measu_export: "Exportar",
  measu_pre_requirements: "Requisitos para la exportación:",
  measu_responsible_project: "Proyectos de los que eres responsable.",
  measu_planned_projects:
    "Proyectos que contienen fecha de inicio y fecha límite.",
};

const pt = {
  measu_editable: "Editável",
  measu_no: "Não",
  measu_yes: "Sim",
  measu_example_format: "Formato Exemplo",
  measu_invalid_doc: "Documento violado, exporte outro.",
  measu_invalid_performance_note: "Nota de desempenho invalida.",
  measu_invalid_genre: "Gênero invalido.",
  measu_invalid_area: "Área atual invalida.",
  measu_invalid_leading: "Liderança invalida.",
  measu_invalid_current_position: "Posição atual invalida.",
  measu_invalid_formation: "Formação invalida.",
  measu_invalid_performance_status: "Status de desempenho invalido.",
  measu_invalid_language: "Idioma(s) invalido(s)",
  measu_limit_value: "Valor Limite",
  measu_invalid_year: "Ano invalido.",
  measu_invalid_date: "Data Invalida.",
  measu_invalid_month: "Mês inexistente no planejamento anual do Projeto.",
  measu_invalid_physical_progress: "Avanço Físico invalido.",
  measu_invalid_investment: "Valor de investimento invalido.",
  measu_invalid_effort: "Quantidade de horas inválida.",
  measu_import_done_in: "Importação de dados realizada em ",
  measu_invalid_frequency: "Frequência invalida.",
  measu_invalid_actual_value: "Valor atual invalido.",
  measu_invalid_last_period_value: "Valor do Período Anterior invalido.",
  measu_update_database:
    "Tudo Certo! Clique em confirmar para atualizar a base de dados",
  measu_something_went_wrong: "Algo deu errado! Arquivo não validado",
  measu_model_confirmation: "Confirmação do Modelo",
  measu_model_validation: "Validação do Modelo",
  measu_update: "Atualização",
  measu_is_this_the_correct_model: "Esse é o modelo correto?",
  measu_import_validated_data: "Importar dados validados",
  measu_unknown_step: "Passo desconhecido",
  measu_back: "Back",
  measu_importing_document: "Importando Documento ",
  measu_error_description: "Descrição do Erro",
  measu_validated_data: "Dados validados",
  measu_non_validated_data: "Dados não validados",
  measu_lines: " linha(s)",
  measu_values: "Valores ",
  measu_option: "Opção ",
  measu_see_options: "Ver Opções",
  measu_import: "Importar",
  measu_export: "Exportar",
  measu_pre_requirements: "Pré Requisitos para exportação:",
  measu_responsible_project: "Projetos onde você é o responsável.",
  measu_planned_projects: "Projetos que contém data de início e data limite.",
};

const translations = { en, es, pt };

export default translations;
