import React from "react";
import { getToFixed } from "../../utils/MathUtils";

const TargetColorBadge = (props) => {
  const { hitTargetInfo = {}, goalPercentage = 0 } = props;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "65px",
          height: "20px",
          lineHeight: "20px",
          textAlign: "center",
        }}
      >
        <b>{getToFixed(goalPercentage, 1)}%</b>
      </div>
      <div
        style={{
          width: "18px",
          height: "18px",
          border: "1px solid #ccc",
          backgroundColor: hitTargetInfo.color,
        }}
      />
    </div>
  );
};

export default TargetColorBadge;
