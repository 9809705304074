import { Switch } from "@material-ui/core";
import styled from "styled-components";

export const BoxTemplate = styled.div`
  width: 230px;
  padding: 4px;
  margin: 5px 19px 19px 0px;
  background-color: #dfe1e6;
  border-radius: 3px;

  .header-template {
    display: flex;
    justify-content: space-between;
    height: 20px;
    align-items: center;

    margin-bottom: 4px;

    i {
      display: none;
      padding: 4px 5px;
      font-size: 12px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #fff;
      }
    }
  }

  &:hover i {
    display: block;
  }
`;

export const ContainerTemplates = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ContentTemplate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-top: 2px;
  min-height: 100px;
  padding: 4px;
  background-color: #fff;
  border-radius: 3px;
`;

export const TitleTemplate = styled.span`
  font-weight: 500;
  color: #172b4d;
`;

export const DescriptionTemplate = styled.span`
  font-weight: 400;
  font-size: 12px;
`;

export const ResponsibleTemplate = styled.div`
  font-size: 12px;

  span {
    font-weight: 500;
  }
`;

export const NumberOfActivities = styled.div`
  margin-top: 5px;
  font-size: 12px;

  span {
    font-weight: 500;
  }
`;

export const ButtonDelete = styled.button`
  background: #f54e4e;
  font-weight: 500;
  color: #fff;
  transition: 0.2s;

  &:hover {
    color: #fff;
    background: #f74343;
  }
`;

export const SwithCustom = styled(Switch)`
  .Mui-checked {
    color: #6b42a9 !important;
  }

  .MuiSwitch-track {
    background-color: #6b42a9 !important;
  }
`;
