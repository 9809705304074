import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";
import isEmail from "validator/lib/isEmail";
import FormDialog from "./FormDialog";
import MaterialTextField from "./MaterialTextField";
import utils from "../../utils/toolUtils";
import {
  createNewParticipant,
  isEmailInUse,
  insertParticipantToTeams,
} from "../../actions/participantActions";
import { getSelectedCompanyStates } from "../customMapStates";

const { getErrorMessage } = utils;

const defaultNewMemberForm = {
  firstName: "",
  lastName: "",
  email: "",
};

const NewMemberModal = (props) => {
  const [form, setForm] = useState(defaultNewMemberForm);
  const [emailNotAvailable, setEmailNotAvailable] = useState(false);

  let {
    open = false,
    onConfirm,
    onCancel,
    intl,
    teamID = null,
    insertParticipantToTeams,
    isEmailInUse,
  } = props;

  useEffect(() => {
    if (!open) setForm(defaultNewMemberForm);
  }, [open]);

  onConfirm = typeof onConfirm === "function" ? onConfirm : () => {};
  onCancel = typeof onCancel === "function" ? onCancel : () => {};

  const handleUpdateForm = (fieldSlug, newValue) => {
    setForm({ ...form, [fieldSlug]: newValue });
  };

  const getFormErrors = () => {
    let errors = [];

    if (isEmail(form.email)) emailInUse(form.email);

    if (form.firstName.length < 2) {
      errors.push({
        slug: "invalidFirstName",
        message: "O campo nome deve conter mais caracteres",
      });
    }

    if (form.lastName.length < 2) {
      errors.push({
        slug: "invalidLastName",
        message: "O campo sobrenome deve conter mais caracteres",
      });
    }

    if (form.email && !isEmail(form.email)) {
      errors.push({
        slug: "invalidEmail",
        message: "Preencha o campo e-mail corretamente",
      });
    }

    if (form.email && emailNotAvailable) {
      errors.push({
        slug: "emailInUse",
        message: "E-mail em uso",
      });
    }

    if (!form.email) {
      errors.push({ slug: "emailNotCompleted" });
    }

    return errors;
  };

  async function emailInUse(email = "") {
    const inUse = await isEmailInUse(email);

    if (inUse) {
      setEmailNotAvailable(true);
    } else {
      setEmailNotAvailable(false);
    }
  }

  const messages = defineMessages({
    btnComeBack: { id: "btn_modal_back" },
    btnRegister: { id: "btn_modal_register" },
    registerName: { id: "global.name" },
    registerLastName: { id: "global.lastName" },
    textRegisterParticipant: { id: "text_register_participant" },
    textInfoAccessLink: { id: "text_info_access_link" },
  });

  const handleSubmitForm = () => {
    const selectedCompanyId = props.selectedCompany
      ? props.selectedCompany.id
      : null;

    props
      .createNewParticipant(form, selectedCompanyId)
      .then((participantRes) => {
        const id =
          participantRes && participantRes.id ? participantRes.id : null;
        if (id && teamID) insertParticipantToTeams(teamID, [participantRes.id]);
        onConfirm();
      });
  };

  const formErrors = getFormErrors();

  return (
    <FormDialog
      open={open}
      title={intl.formatMessage(messages.textRegisterParticipant)}
      description={
        <div className="col-xs-12">
          {intl.formatMessage(messages.textInfoAccessLink)}
        </div>
      }
      onConfirm={() => handleSubmitForm()}
      onCancel={() => onCancel()}
      confirmText={intl.formatMessage(messages.btnRegister)}
      cancelText={intl.formatMessage(messages.btnComeBack)}
      blockConfirm={formErrors.length > 0}
    >
      <div className="row">
        <div className="col-xs-6">
          <MaterialTextField
            id="firstName"
            label={intl.formatMessage(messages.registerName)}
            value={form.firstName}
            onChange={(e) => handleUpdateForm("firstName", e.target.value)}
          />
        </div>
        <div className="col-xs-6">
          <MaterialTextField
            id="lastName"
            label={intl.formatMessage(messages.registerLastName)}
            value={form.lastName}
            onChange={(e) => handleUpdateForm("lastName", e.target.value)}
          />
        </div>
        <div className="col-xs-12">
          <MaterialTextField
            id="email"
            label="E-mail"
            value={form.email}
            onChange={(e) => handleUpdateForm("email", e.target.value)}
          />
          {getErrorMessage(["invalidEmail", "emailInUse"], formErrors)}
        </div>
      </div>
    </FormDialog>
  );
};

NewMemberModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { selectedCompany } = getSelectedCompanyStates(state);

  return {
    selectedCompany,
  };
};

export default connect(mapStateToProps, {
  createNewParticipant,
  insertParticipantToTeams,
  isEmailInUse,
})(injectIntl(NewMemberModal));
