/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import _ from "lodash";
import { buildFormAdmConfigBody } from "./entity";
import {
  getKpiConfigBySelectedToolId,
  getGoalIndicatorInfoById,
} from "./filter";
import { getKpiIndicatorChanges } from "./validation";
import { getKpiConfiguration } from "../../actions/kpiActions";
import { KPIS } from "../../constants/tools.constants";

const defaultAdministration = buildFormAdmConfigBody({});
const defaultGoalsBody = buildFormAdmConfigBody({ goals: [{ id: null }] })
  .goals[0];

export function useSimpleToolAdministration({
  selectedTool = {},
  allKpiAdministration = [],
  isLoading = false,
}) {
  const [toolAdministration, setAdministration] = useState(
    defaultAdministration,
  );

  useEffect(() => {
    if (allKpiAdministration) {
      const restConfig = getKpiConfigBySelectedToolId(
        selectedTool.id,
        allKpiAdministration,
      );

      if (!isLoading && !_.isEqual(restConfig, toolAdministration)) {
        setAdministration(restConfig);
      }
    }
  }, [allKpiAdministration, isLoading, selectedTool.id]);

  return { kpiAdministration: toolAdministration };
}

export function useHistoryToolAdministration({
  selectedTool = {},
  allKpiAdministration = [],
  isLoading = false,
}) {
  const [administration, setAdministration] = useState(defaultAdministration);
  const [toolHistory, setToolHistory] = useState(defaultAdministration);

  useEffect(() => {
    if (allKpiAdministration) {
      const restConfig = getKpiConfigBySelectedToolId(
        selectedTool.id,
        allKpiAdministration,
      );

      const formConfig = buildFormAdmConfigBody(restConfig);

      if (formConfig && !_.isEqual(formConfig, toolHistory)) {
        setToolHistory(formConfig);

        if (!isLoading && !_.isEqual(formConfig, administration)) {
          setAdministration(formConfig);
        }
      }
    }
  }, [allKpiAdministration, isLoading, selectedTool.id]);

  return {
    kpiAdministration: administration,
    setAdministration,
    toolHistory,
    setToolHistory,
  };
}

export function useSelectedIndicator({
  goals: originalGoals,
  kpiConfigurationGoalId,
  isLoading,
}) {
  const [goalDescriptions, setGoalDescriptions] = useState(defaultGoalsBody);
  const [controller, setController] = useState({
    firstMount: false,
    changes: [],
  });

  useEffect(() => {
    const newGoalDescriptions = getGoalIndicatorInfoById(
      kpiConfigurationGoalId,
      originalGoals,
    );

    if (
      newGoalDescriptions.id &&
      !controller.firstMount &&
      !isLoading &&
      !_.isEqual(newGoalDescriptions, goalDescriptions)
    ) {
      setGoalDescriptions({
        id: kpiConfigurationGoalId,
        ...newGoalDescriptions,
      });
      setController({
        ...controller,
        firstMount: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpiConfigurationGoalId, originalGoals]);

  useEffect(() => {
    const restGoalDescriptions = getGoalIndicatorInfoById(
      kpiConfigurationGoalId,
      originalGoals,
    );

    const newChanges = getKpiIndicatorChanges(
      restGoalDescriptions,
      goalDescriptions,
    );

    if (!_.isEqual(newChanges, controller.changes))
      setController({ ...controller, changes: newChanges });
  }, [goalDescriptions, kpiConfigurationGoalId, originalGoals]);

  return {
    goalDescriptions,
    setGoalDescriptions,
    changes: controller.changes,
    setController,
  };
}

async function fetchConfig(toolInfo) {
  if (toolInfo?.id) {
    const { data: config } = await getKpiConfiguration(toolInfo?.id);

    const toolConfig = buildFormAdmConfigBody(config);

    return {
      id: toolConfig?.id,
      indicators: toolConfig.goals,
    };
  }

  return null;
}

export function useWorkspaceIndicators({ workspaceTools }) {
  const [toolDataConfig, setToolDataConfig] = useState({
    toolId: null,
    indicators: [],
  });

  useEffect(() => {
    async function get() {
      if (workspaceTools) {
        const filterIndicatorTool = workspaceTools.filter(
          ({ tool }) => tool.id === KPIS,
        );

        const newConfig = await fetchConfig(filterIndicatorTool[0]);
        if (newConfig?.id) setToolDataConfig(newConfig);
      }
    }

    get();
  }, [workspaceTools]);

  return toolDataConfig;
}
