import axios from "axios";

import { apiNode } from "../api";

export const getDomainLogs = async (
  selectedPage,
  selectedFilters,
  size = 14,
) => {
  const { companyIds, userIds, workspaceIds, startDate, endDate } =
    selectedFilters;

  const response = await axios.get("/api/audit-logs", {
    params: {
      page: !Object.keys(selectedFilters).length ? selectedPage : 0,
      size,
      companyIds,
      userIds,
      workspaceIds,
      startDate,
      endDate,
    },
  });

  return response.data;
};

export const getFilters = async () => {
  const response = await axios.get("/api/domains/availableOwnerFilters");

  return response.data;
};

export const getExpiredContract = async (domainId) => {
  const response = await apiNode.get(
    `api/v2/domain/${domainId}/expired-contract`,
  );

  return response.data;
};

export const getDomainInfo = async (domainId) => {
  if (domainId) {
    const response = await axios.get(`/api/domains/${domainId}`);

    return response;
  }

  return null;
};
