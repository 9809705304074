import axios from "axios";
import {
  FETCH_CENARY_THEMES,
  FETCH_ALL_SCENERIES_THEMES,
  UPDATE_CENARY_THEME,
  DELETE_THEME,
} from "./types";

import { appRefreshSelectedTool } from "./appActions";
import { fetchThemeQuestions } from "./questionsActions";

const getReportFollowupCenariesThemes = (idCenaries) => {
  return axios.get(`/api/themes/cenary/${idCenaries}`).then((res) => res);
};

export const fetchReportOkrFollowupCenariesThemes = (idTools) => {
  return (dispatch) => {
    async function getReportFollowupCenariesThemesData() {
      let FollowupCenariesThemesData = {};

      if (idTools && idTools.length) {
        await Promise.all(
          idTools.map(async (id) => {
            const ReportFollowupCenariesThemesData =
              await getReportFollowupCenariesThemes(id);

            if (
              ReportFollowupCenariesThemesData &&
              ReportFollowupCenariesThemesData.data
            ) {
              FollowupCenariesThemesData = {
                ...FollowupCenariesThemesData,
                [id]: ReportFollowupCenariesThemesData.data,
              };
            }
          }),
        );

        dispatch({
          type: "FETCH_REPORT_OKR_FOLLOWUP_CENARIES_THEMES",
          payload: FollowupCenariesThemesData,
        });
      }
    }
    getReportFollowupCenariesThemesData();
  };
};

export const fetchCenaryThemes = (cenaryId) => {
  return (dispatch) => {
    return axios.get(`/api/themes/cenary/${cenaryId}`).then((res) => {
      const data = {
        cenaryId,
        themes: res.data,
      };

      dispatch({ type: FETCH_CENARY_THEMES, payload: data });
    });
  };
};

export const fetchAllSceneryThemes = (sceneriesIdList) => {
  return async (dispatch) => {
    const allThemes = {};

    sceneriesIdList.forEach((id) => (allThemes[id] = []));

    await axios.post("/api/themes/cenaries", sceneriesIdList).then((res) => {
      const { data: themesArray = [] } = res;

      themesArray.forEach((themeInfo) => {
        const { cenary = {} } = themeInfo;
        const { id: sceneryId = null } = cenary;

        sceneryId && allThemes[sceneryId].push(themeInfo);
      });
    });

    dispatch({ type: FETCH_ALL_SCENERIES_THEMES, payload: allThemes });

    return allThemes;
  };
};

export const fetchThemesWithReturn = (cenaryId) => {
  return (dispatch) => {
    return axios.get(`/api/themes/cenary/${cenaryId}`).then((res) => {
      return res.data;
    });
  };
};

export const putTheme = (themeBody) => {
  return axios.put("/api/themes", themeBody).then((res) => res);
};

export const updateTheme = (theme, cenaryId) => {
  const isNew = !theme.id;

  return async (dispatch) => {
    try {
      const updatedTheme = await putTheme(theme);

      dispatch({
        type: UPDATE_CENARY_THEME,
        payload: {
          cenaryId,
          theme: {
            ...updatedTheme.data,
            isNew,
          },
        },
      });
    } catch (err) {
      //console.log("Erro ao criar o tema: ", err);
    }
  };
};

export const deleteTheme = (themeId) => {
  return (dispatch) => {
    return axios.delete(`/api/themes/${themeId}`).then((res) => {
      if (res?.data && res.data?.content) {
        const theme = res.data.content;

        dispatch({
          type: DELETE_THEME,
          payload: { theme, cenaryId: theme.cenary.id },
        });
      }
    });
  };
};

//  POST /api/themes/copy
export const copyTheme = (theme) => {
  return (dispatch) => {
    return axios.post("/api/themes/copy", theme).then((res) => {
      return res.data;
      // dispatch({ type: UPDATE_CENARY_THEME, payload: data });
    });
  };
};

export const archiveTheme = (theme) => {
  return (dispatch) => {
    return axios.post("/api/archiveUnarchive", theme).then((res) => {
      dispatch(appRefreshSelectedTool(false));
    });
  };
};

export const changeThemeName = (themeId = "", name = "") => {
  return axios.put(`/api/themes/${themeId}/${name}`).then((res) => res.data);
};

export const doChangeThemeName = (themeId = "", name = "") => {
  return async (dispatch) => {
    const updatedTheme = await changeThemeName(themeId, name);

    const { cenary = {} } = updatedTheme;
    const { id: cenaryId = 0 } = cenary;

    const payload = {
      cenaryId,
      theme: {
        ...updatedTheme,
        isNew: false,
      },
    };

    dispatch({
      type: UPDATE_CENARY_THEME,
      payload,
    });
  };
};
