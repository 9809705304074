const en = {
  pbp_main_title: "Processes",
  pbp_count_process: "Nº Processes",
};

const es = {
  pbp_main_title: "Processes",
  pbp_count_process: "Nº Processes",
};

const pt = {
  pbp_main_title: "Processos",
  pbp_count_process: "Nº Processos",
};

const translations = { en, es, pt };

export default translations;
