import React from "react";
import { Row, Col } from "react-bootstrap";
import { patternStyles, generalCardStyles } from "../jcss";
import BadgeProgressBar from "../../../../../../../Common/ReportCards/components/BadgeProgressBar";
import {
  getDefaultPercentages,
  getDefaultScopes,
} from "../../../../../../../../utils/projectUtils";

const defaultScopes = getDefaultScopes();
const values = getDefaultPercentages(20);

const overallRowStyle = { margin: "20px" };
const adherenceRowStyle = { marginBottom: "5px" };
const badgeRowStyle = { marginLeft: "25px", display: "flex", width: "99%" };

const QualityChart = ({ macroOverviewData = {} }) => {
  const {
    fullWidth = "",
    centerAlign = "",
    qualityStyle = "",
    cardHeaderStyle = "",
  } = patternStyles();

  const { boxInfoHeader = "", boxInfoContent = "" } = generalCardStyles();

  const { quality = {} } = macroOverviewData;
  const {
    scopeAdherence = [],
    replanned = "0%",
    notReplanned = "0%",
    replannedProjects = "",
  } = quality;

  const getValueLine = (value = "", index = 0) => {
    return (
      <Col md={2} key={index} style={{ fontSize: "10px" }}>
        {value}
      </Col>
    );
  };

  const getScopeCard = (scope = "", index = 0) => {
    const { value, color } = scopeAdherence[scope] || {};

    const boxStyle = {
      fontSize: "35px",
      borderTop: `4px solid ${color}`,
    };

    return (
      <Col md={3} key={index}>
        <div style={boxStyle} className={boxInfoContent}>
          {value}
        </div>
      </Col>
    );
  };

  const singleGreyBar = () => {
    return (
      <BadgeProgressBar
        widthBar="100%"
        heightBar="18px"
        leftText={100}
        barColor="grey"
        borderRadius="5px"
      />
    );
  };

  const getBadges = () => {
    let greenConfig = { leftBorderRadius: "5px" };
    let redConfig = { rightBorderRadius: "5px" };

    if (replanned === "0%" && notReplanned === "0%") return singleGreyBar();
    if (replanned === "0%") greenConfig = { borderRadius: "5px" };
    if (notReplanned === "0%") redConfig = { borderRadius: "5px" };

    return (
      <div
        title={`Dentro: ${notReplanned} | Fora: ${replanned}`}
        style={{ width: "100%", display: "flex" }}
      >
        <BadgeProgressBar
          widthBar={notReplanned}
          heightBar="18px"
          leftText={100}
          barColor="kanbanGreen"
          {...greenConfig}
        />
        <BadgeProgressBar
          widthBar={replanned}
          heightBar="18px"
          leftText={100}
          barColor="kanbanRed"
          {...redConfig}
        />
      </div>
    );
  };

  return (
    <Col md={4} sm={12} className={qualityStyle}>
      <Row className={cardHeaderStyle}>
        <strong>Qualidade</strong>
      </Row>
      <Row>
        <Row style={overallRowStyle} className={centerAlign}>
          <Col md={2} className={centerAlign}>
            <div>
              <div
                className={boxInfoHeader}
                style={{ justifyContent: "center", marginLeft: "14px" }}
              >
                Replanejamentos
              </div>
              <div
                className={boxInfoContent}
                style={{
                  marginLeft: "14px",
                }}
              >
                {replannedProjects}
              </div>
            </div>
          </Col>
          <Col md={10}>
            <Row style={{ marginLeft: "7px" }}>
              {values.map((value, index) => getValueLine(value, index))}
            </Row>
            <Row style={badgeRowStyle}>{getBadges()}</Row>
          </Col>
        </Row>
        <Row className={fullWidth}>
          <Col md={12} style={{ padding: "0px" }}>
            <Row style={adherenceRowStyle} className={centerAlign}>
              Aderencia em Relação ao Escopo
            </Row>
            <Row>
              {defaultScopes.map((scope, index) => getScopeCard(scope, index))}
            </Row>
          </Col>
        </Row>
      </Row>
    </Col>
  );
};

export default QualityChart;
