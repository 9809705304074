import styled from "styled-components";

export const Ullist = styled.ul`
  padding: 0px;
  list-style: none;
  margin: 0;
`;
export const ListMenu = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: rgb(156 141 141 / 30%);
  }

  i {
    margin-right: 5px;
  }

  li {
    font-weight: 500;
  }
`;
