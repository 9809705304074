/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";

const SimpleBreadcrumb = ({ items, color }) => {
  if (Array.isArray(items)) {
    return items.map((jsx, index) => {
      const isLast = index === items.length - 1;

      return (
        <React.Fragment key={index}>
          {jsx}{" "}
          {!isLast && <i className="fas fa-angle-right" style={{ color }} />}{" "}
        </React.Fragment>
      );
    });
  }

  return null;
};

SimpleBreadcrumb.propTypes = {
  items: PropTypes.array,
  color: PropTypes.string,
};

SimpleBreadcrumb.defaultProps = {
  items: [],
  color: "#999",
};

export default SimpleBreadcrumb;
