import {
  POST_PLAN,
  PUT_PLAN,
  DELETE_PLAN,
  POST_OBJECTIVE,
  PUT_OBJECTIVE,
  DELETE_OBJECTIVE,
  POST_KEY,
  PUT_KEY,
  DELETE_KEY,
  POST_CYCLE_BASE,
  POST_PATTERN_BASE,
  PUT_PATTERN_BASE,
  DELETE_BASE_PATTERN,
} from "../../../../../constants/okr";

import {
  getFromDetails,
  checkToolIdInState,
} from "../../../utils/entity_validations";

import {
  postScenery,
  postTheme,
  postQuestion,
  postInsight,
} from "../board/_actions";

import {
  checkScenerySelectedTool,
  checkThemeSelectedTool,
  checkQuestionSelectedTool,
  checkInsightSelectedTool,
} from "../board/_validations";

import {
  checkPostAdministrationSelectedTool,
  toolUpdateNotifier,
} from "./_validations";

/**
 *
 * START Plan Actions
 *
 */

export const postPlan = (planDataInfo) => {
  const { content: planData, details = {} } = planDataInfo;
  const selectedToolId = getFromDetails(details, "selectedToolId", true);

  if (planData.id && checkPostAdministrationSelectedTool(selectedToolId)) {
    return async (dispatch) => {
      if (checkToolIdInState({ id: selectedToolId }))
        dispatch(toolUpdateNotifier());

      dispatch({ type: POST_PLAN, payload: planData });
    };
  }

  return null;
};

export const putPlan = (planDataInfo) => {
  const { content: planData, details = {} } = planDataInfo;
  const selectedToolId = getFromDetails(details, "selectedToolId", true);

  if (planData.id && checkPostAdministrationSelectedTool(selectedToolId)) {
    return async (dispatch) => {
      if (checkToolIdInState({ id: selectedToolId }))
        dispatch(toolUpdateNotifier());

      dispatch({ type: PUT_PLAN, payload: planData });
    };
  }
};

export const deletePlan = (planId) => {
  if (planId) {
    return (dispatch) => {
      dispatch({ type: DELETE_PLAN, payload: planId });
    };
  }
};

/**
 *
 * END Plan Actions
 *
 * START Cycle Base Actions
 *
 */

export const postCycleBase = (cycleBase) => {
  if (cycleBase.id) {
    return async (dispatch) => {
      dispatch({ type: POST_CYCLE_BASE, payload: cycleBase });
    };
  }
};

/**
 *
 * END Cycle Base Actions
 *
 * START Objectives Actions
 *
 */

export const postObjective = (objectiveDataInfo) => {
  const { content: objective, details = {} } = objectiveDataInfo;
  const selectedToolId = getFromDetails(details, "selectedToolId", true);

  if (objective.id && checkPostAdministrationSelectedTool(selectedToolId)) {
    return async (dispatch) => {
      if (checkToolIdInState({ id: selectedToolId }))
        dispatch(toolUpdateNotifier());

      dispatch({ type: POST_OBJECTIVE, payload: objective });
    };
  }
};

export const putObjective = (objectiveDataInfo) => {
  const { content: objective, details = {} } = objectiveDataInfo;
  const selectedToolId = getFromDetails(details, "selectedToolId", true);

  if (objective.id && checkPostAdministrationSelectedTool(selectedToolId)) {
    return (dispatch) => {
      if (checkToolIdInState({ id: selectedToolId }))
        dispatch(toolUpdateNotifier());

      dispatch({
        type: PUT_OBJECTIVE,
        payload: objective,
      });
    };
  }
};

export const deleteObjective = (objectiveId) => {
  if (objectiveId) {
    return async (dispatch) => {
      dispatch({ type: DELETE_OBJECTIVE, payload: objectiveId });
    };
  }
};

/**
 *
 * END Objectives Actions
 *
 * START key Actions
 *
 */

export const postKey = (keyDataInfo) => {
  const { content: keyResult, details = {} } = keyDataInfo;

  return async (dispatch) => {
    if (keyResult.id) {
      dispatch(toolUpdateNotifier());

      dispatch({
        type: POST_KEY,
        payload: {
          key: {
            ...keyResult,
            objectiveId: Number(details.okrConfigurationObjetiveId),
          },
          objectiveId: Number(details.okrConfigurationObjetiveId),
        },
      });
    }
  };
};

export const putKey = (keyDataInfo) => {
  const { content: keyResult, details = {} } = keyDataInfo;

  const newObjectiveIdFormated = Number(details.newObjetiveId);

  return async (dispatch) => {
    const { id, objectiveId } = keyResult;

    if (!!id && !!objectiveId) {
      dispatch({
        type: PUT_KEY,
        payload: {
          newObjectiveId: newObjectiveIdFormated,
          objectiveId,
          key: { ...keyResult, objectiveId },
        },
      });
    }
  };
};

export const deleteKey = (key) => {
  return async (dispatch) => {
    if (key.content && Number(key.details.objectiveId)) {
      dispatch({
        type: DELETE_KEY,
        payload: {
          keyId: key.content,
          objectiveId: Number(key.details.objectiveId),
        },
      });
    }
  };
};

/**
 *
 * END key Actions
 *
 * START PATTERN BASE
 *
 */

export const postPatterns = (patternDataInfo) => {
  const { content: pattern, details = {} } = patternDataInfo;
  const selectedToolId = getFromDetails(details, "selectedToolId", true);

  if (pattern.id && checkPostAdministrationSelectedTool(selectedToolId)) {
    return async (dispatch) => {
      dispatch({ type: POST_PATTERN_BASE, payload: pattern });
    };
  }
};

export const putPatterns = (patternDataInfo) => {
  return async (dispatch) => {
    const { content, details = {} } = patternDataInfo;

    const selectedToolId = getFromDetails(details, "selectedToolId", true);

    if (content.cycles && checkPostAdministrationSelectedTool(selectedToolId)) {
      if (checkToolIdInState({ id: selectedToolId }))
        dispatch(toolUpdateNotifier());

      dispatch({
        type: PUT_PATTERN_BASE,
        payload: {
          patternCycles: content.cycles,
          pattern: content.pattern,
          patternId: Number(details.patternId),
        },
      });
    }
  };
};

export const deleteBasePattern = (patternId) => {
  return async (dispatch) => {
    if (patternId) {
      dispatch({ type: DELETE_BASE_PATTERN, payload: patternId });
    }
  };
};

export const postBoardCenaryLink = (cenaryLinkData) => {
  const { content: updatedCenary = {}, details = {} } = cenaryLinkData;

  if (updatedCenary && updatedCenary.id) {
    return (dispatch) => {
      if (checkScenerySelectedTool(updatedCenary))
        dispatch(toolUpdateNotifier());

      dispatch(postScenery(updatedCenary, details.userId));
    };
  }

  return null;
};

export const postBoardThemeLink = (themeLinkData) => {
  const { content: updatedTheme = {}, details = {} } = themeLinkData;

  if (updatedTheme && updatedTheme.id) {
    return (dispatch) => {
      if (checkThemeSelectedTool(updatedTheme)) dispatch(toolUpdateNotifier());

      return dispatch(postTheme(updatedTheme, details.userId));
    };
  }

  return null;
};

export const postBoardQuestionLink = (questionLinkData) => {
  const { content: updatedQuestion = {}, details = {} } = questionLinkData;

  if (updatedQuestion && updatedQuestion.id) {
    return (dispatch) => {
      if (checkQuestionSelectedTool(updatedQuestion))
        dispatch(toolUpdateNotifier());

      return dispatch(postQuestion(updatedQuestion, details.id));
    };
  }

  return null;
};

export const postBoardInsightLink = (insightLinkData) => {
  const { content: updatedInsight = {}, details = {} } = insightLinkData;
  const kpiConfigurationGoalId = getFromDetails(
    details,
    "kpiConfigurationGoalId",
    true,
  );

  if (updatedInsight && updatedInsight.id && kpiConfigurationGoalId) {
    return (dispatch) => {
      if (checkInsightSelectedTool(updatedInsight))
        dispatch(toolUpdateNotifier());

      return dispatch(
        postInsight(
          { ...updatedInsight, kpiConfigurationGoalId },
          details.userId,
        ),
      );
    };
  }

  return null;
};
