import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import FormDialog from "../../FormDialog";
import MaterialTextField from "../../MaterialTextField";
import SelectMemberModal from "../../SelectMemberModal";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import utils from "../../../../utils/toolUtils";
import {
  orderArrayByObjAttr,
  getOnlySlugValues,
} from "../../../../utils/ArrayUtils";

const { getErrorMessage, getValidFunc } = utils;

const messages = defineMessages({
  pb_select_member_participant: { id: "pb_select_member_participant" },
  pb_error_duplicated_member: { id: "pb_error_duplicated_member" },
  btn_modal_register_new: { id: "btn_modal_register_new" },
  global_select: { id: "global.select" },
});

const initialForm = {
  members: [],
};

const InsightSelectPeople = (props) => {
  const [form, setForm] = useState(initialForm);
  const [isOpenMemberModal, setIsOpenMemberModal] = useState(false);
  const [replaceSelectedMembers, setReplaceSelectedMembers] = useState(false);

  const {
    open = false,
    afterCancel,
    afterConfirm,
    companyMembersAndParticipants,
    addedUsers = [],
    intl,
  } = props;

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleConfirm = () => {
    if (typeof afterConfirm === "function") afterConfirm(form);
  };

  const handleCancel = () => {
    if (typeof afterCancel === "function") afterCancel();
  };

  const handleUpdateForm = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const updateSelectedMembers = (selectedMembers) => {
    handleUpdateForm("members", selectedMembers);

    toggleSelectMemberModal(false);
  };

  const getFormErrors = () => {
    let errors = [];

    if (form.members.length > 0 && addedUsers.length > 0) {
      form.members.forEach((memberInfo) => {
        if (addedUsers.indexOf(memberInfo.id) > -1) {
          errors.push({
            slug: `duplicatedMember${memberInfo.id}`,
            message: intl.formatMessage(messages.pb_error_duplicated_member, {
              name: memberInfo.name,
            }),
          });
        }
      });
    }

    return errors;
  };

  const toggleSelectMemberModal = (toggleTo = true) => {
    setIsOpenMemberModal(toggleTo);

    if (form.members.length > 0) {
      setReplaceSelectedMembers(true);
    }
  };

  const getSelectedMembersDisplay = (list) => {
    let displayTitles = [];

    list.forEach((userInfo) => {
      if (userInfo.name) displayTitles.push(userInfo.name);
    });

    return displayTitles.length > 0
      ? displayTitles.join(", ")
      : intl.formatMessage(messages.global_select);
  };

  const getDisplayErrors = (slug = "duplicatedMember", errors = []) => {
    return form.members.map((memberInfo, index) => {
      let haveError = null;

      errors.forEach((iteratingErr) => {
        if (iteratingErr.slug === `${slug}${memberInfo.id}`) haveError = true;
      });

      return haveError ? (
        <div style={{ height: "40px" }} key={index}>
          {getErrorMessage([`${slug}${memberInfo.id}`], errors, "left", {
            height: "0px",
          })}
        </div>
      ) : null;
    });
  };

  const formErrors = getFormErrors();

  return (
    <>
      {isOpenMemberModal && (
        <SelectMemberModal
          open={isOpenMemberModal}
          title={intl.formatMessage(messages.pb_select_member_participant)}
          toggleOpen={() => toggleSelectMemberModal(false)}
          onConfirm={(selectedIds, selectedMembers) =>
            updateSelectedMembers(selectedMembers)
          }
          membersList={companyMembersAndParticipants}
          initialSelected={getOnlySlugValues(form.members, "id")}
          replaceSelected={replaceSelectedMembers}
          replaceCallback={() => setReplaceSelectedMembers(false)}
        />
      )}
      <FormDialog
        open={open}
        title={intl.formatMessage(messages.pb_select_member_participant)}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        blockConfirm={formErrors.length > 0}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialTextField
              id="roleSelect"
              label={intl.formatMessage(messages.pb_select_member_participant)}
              value={getSelectedMembersDisplay(form.members)}
              onClick={() => toggleSelectMemberModal(true)}
              inputStyle={{ cursor: "pointer" }}
            />
            {getDisplayErrors("duplicatedMember", formErrors)}
          </div>
        </div>
      </FormDialog>
    </>
  );
};

export default injectIntl(InsightSelectPeople);
