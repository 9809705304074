import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

import connectionConfigs from "./config/connection";
import { SET_CURRENT_USER, USER_LOGOUT } from "../types";

import { subscribeController } from "./subscribe-controller";

let stompClient = null;
let connectedPromise = null;
let alreadyConnectedOnce = false;

const LOG_SUBSCRIPTIONS = false;
const LOCAL_STORED_WS_URL_KEY = "localWsURLKey";

const createConnection = () =>
  new Promise((resolve) => (connectedPromise = resolve));

const connect = (store) => {
  if (connectedPromise !== null || alreadyConnectedOnce) {
    return;
  }
  const connection = createConnection();
  const { connectionURL, connectionHeaders } = connectionConfigs();

  const socket = new SockJS(connectionURL);
  stompClient = Stomp.over(socket);
  stompClient.debug = (str) => (LOG_SUBSCRIPTIONS ? console.log(str) : {});

  stompClient.connect(connectionHeaders, () => {
    connectedPromise("success");
    subscribeController(store, connection, stompClient);

    if (!alreadyConnectedOnce) {
      window.onhashchange = () => {};
      alreadyConnectedOnce = true;
    }

    connectedPromise = null;
  });
};

const disconnect = () => {
  if (stompClient !== null) {
    stompClient.disconnect();
    stompClient = null;
  }
  window.onhashchange = () => {};
  alreadyConnectedOnce = false;
};

export default (store) => (next) => (action) => {
  if (action) {
    if (action.type === SET_CURRENT_USER) {
      connect(store);
    } else if (action.type === USER_LOGOUT) {
      disconnect();
    }

    if (next && action.type) return next(action);
  }
};
