import { Button } from "@material-ui/core";
import { EventAvailable } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import DuplicateColumn from "components/Common/Tables/DuplicateColumn";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";
import styled from "styled-components";
import MaterialCustomMenu from "../components/Common/MaterialCustomMenu";
import DeleteColumn from "../components/Common/Tables/DeleteColumn";
import EditColumn from "../components/Common/Tables/EditColumn";
import { getToFixed } from "./MathUtils";
import { translatedText } from "./translationUtils";
import { getValidFunc } from "./validations";

const messages = defineMessages({
  global_add: { id: "global.add" },
});

const translation = (id) => translatedText(id, messages);

export const getDefaultAddTableLine = (
  onClick,
  lineStyles = {},
  title = "",
  aditionalJsx
) => {
  const handleOnClick = getValidFunc(onClick);

  const DefaultAddTableLine = () => {
    return (
      <tr className="block-hover" style={lineStyles}>
        <td colSpan="100" align="right">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              lineHeight: "29px",
            }}
          >
            <span>{title}</span>
            <div style={{ display: "flex", gap: "10px" }}>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                style={{ fontSize: "12px" }}
                onClick={handleOnClick}
              >
                <AddIcon /> {translation("global_add")}
              </Button>
              {aditionalJsx}
            </div>
          </div>
        </td>
      </tr>
    );
  };

  return <DefaultAddTableLine />;
};

export const getWidthTd = ({
  text,
  width = "auto",
  colSpan,
  title,
  styles = {},
}) => (
  <td colSpan={colSpan}>
    <div style={{ width, ...styles }} title={title}>
      {text}
    </div>
  </td>
);

const StatusBar = styled.div`
  background-color: ${({ background }) => background || ""};

  @media print {
    background-color: ${({ background }) => background || ""} !important;
    -webkit-print-color-adjust: exact;
  }
`;

export const GetTargetCol = ({ text, options }) => {
  const color = options?.color || "#ccc";
  const bgColor = options?.bgColor || "#f6f6f6";
  const padding = options?.padding || "15px";
  const lineHeight = options?.lineHeight || "50px";
  const notStarted = options?.notStarted || false;

  return (
    <td
      align="right"
      style={{
        padding: "0px",
        backgroundColor: bgColor,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          padding,
          textAlign: "right",
          position: "relative",
          lineHeight,
        }}
      >
        <b style={notStarted ? { fontWeight: "normal", color: "#ddd" } : {}}>
          {text}
        </b>
        <StatusBar
          background={color}
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            width: "5px",
            height: "100%",
          }}
        />
      </div>
    </td>
  );
};

GetTargetCol.propTypes = {
  options: PropTypes.object.isRequired,
  text: PropTypes.string,
};

GetTargetCol.defaultProps = {
  text: "",
};

export const getPerformanceCol = (
  percentage,
  options = { color: null, bgColor: null, padding: null },
  fix = 1,
  notStarted = false
) => {
  const fixPercentage = `${getToFixed(percentage, fix)}%`;

  return (
    <GetTargetCol
      text={fixPercentage}
      options={{
        ...options,
        color: notStarted ? "rgb(204,204,204)" : options.color,
      }}
    />
  );
};

export const getDefaultEditColumn = (onEdit) => {
  const handleEdit = getValidFunc(onEdit);

  return <EditColumn handleEdit={handleEdit} />;
};

export const getDefaultDuplicateColumn = (onDuplicate) => {
  const handleDuplicate = getValidFunc(onDuplicate);

  return <DuplicateColumn handleDuplicate={handleDuplicate} />;
};

export const getDefaultRemoveColumn = (onDelete) => {
  const handleDelete = getValidFunc(onDelete);

  return <DeleteColumn handleDelete={handleDelete} />;
};

export const getEmptyTableRow = (array, message = null) => {
  if (!array || array.length <= 0)
    return (
      <tr>
        <td colSpan="100">{message}</td>
      </tr>
    );

  return null;
};

export function SortMenu({
  onSortAlphabeticallyAsc,
  onSortAlphabeticallyDesc,
  onSortByCreatedDate,
  onSortByOrder,
  activities,
}) {
  return (
    <div>
      <MaterialCustomMenu
        menuActions={[
          {
            icon: <EventAvailable />,
            text: "Ordenar por data de criação",
            action: () => onSortByCreatedDate(activities),
          },
          {
            icon: <i className="fa fa-sort-alpha-down" />,
            text: "Ordenar por ordem alfabética ascendente",
            action: () => onSortAlphabeticallyAsc(activities),
          },
          {
            icon: <i className="fa fa-sort-alpha-up" />,
            text: "Ordenar por ordem alfabética descendente",
            action: () => onSortAlphabeticallyDesc(activities),
          },
          {
            icon: <i className="fa fa-sort-numeric-down" />,
            text: "Ordenar por ordem crescente",
            action: () => onSortByOrder(activities, true),
          },
          {
            icon: <i className="fa fa-sort-numeric-up" />,
            text: "Ordenar por ordem decrescente",
            action: () => onSortByOrder(activities, false),
          },
        ]}
      />
    </div>
  );
}
