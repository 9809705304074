import formTranslations from "./forms";
import dialogTranslations from "../dialogs";
import goalsAndBonus from "./goalsAndBonus";
import okr from "./okr";
import peopleBigPicture from "./peopleBigPicture";
import processesBigPicture from "./processesBigPicture";
import projectPrioritization from "./projectPrioritization";
import projectPortfolio from "./projectPortfolio";
import swot from "./swot";
import competitiveAnalysisTranslations from "./competitiveAnalysis";
import valueCurveAnalysisTranslations from "./valueCurveAnalysis";
import strategicFundamentalsTranslations from "./strategicFundamentals";
import portfolioAnalysisTranslations from "./portfolioAnalysis";
import businessModelTranslations from "./businessModel";
import balancedScorecardTranslations from "./balancedScorecard";
import excellenceManagementTranslations from "./excellenceManagement";
import projectsInitiativesTranslations from "./projectsInitiatives";
import meetingManagementTranslations from "./meetingManagement";
import marketAnalysisTranslations from "./marketAnalysis";
import kpisTranslations from "./kpis";
import toolCategories from "./toolCategories";
import actionsPlan from "./actionsPlan";
import skillAssessment from "./skillAssessment";
import processConfigurations from "./processConfigurations";

const additionalTranslations = [
  ...formTranslations,
  ...dialogTranslations,
  actionsPlan,
];

const toolTranslations = [
  goalsAndBonus,
  okr,
  peopleBigPicture,
  processesBigPicture,
  projectPrioritization,
  swot,
  projectPortfolio,
  competitiveAnalysisTranslations,
  valueCurveAnalysisTranslations,
  strategicFundamentalsTranslations,
  portfolioAnalysisTranslations,
  businessModelTranslations,
  balancedScorecardTranslations,
  excellenceManagementTranslations,
  projectsInitiativesTranslations,
  meetingManagementTranslations,
  marketAnalysisTranslations,
  kpisTranslations,
  skillAssessment,
  toolCategories,
  processConfigurations,
];

const finalTranslations = [...additionalTranslations, ...toolTranslations];

export default finalTranslations;
