/* eslint-disable react/no-array-index-key */
import React from "react";
import {
  getQuestionsByProfile,
  getInsightsByParticipant,
  getSkillsByCompetenceAreas,
  getParticipantsBySkill,
} from "./filter";

export const getScalesDeleteDialogInfo = (scaleSlug) => {
  if (scaleSlug === "evaluationScale")
    return {
      title: "Tem certeza que deseja remover essa Escala de Avaliação?",
      description:
        "OBS: O ideal é apenas editar a escala, pois algumas avaliações podem apresentar inconsistências e que necessitem correção manual por participantes e líderes.",
    };

  if (scaleSlug === "qualificationScale")
    return {
      title: "Tem certeza que deseja remover essa Escala de Qualificação?",
      description:
        "OBS: Esta escala deve corresponder com os valores da Escala de Avaliação para que as informações na tela sejam mostradas corretamente.",
    };

  if (scaleSlug === "potentialScale")
    return {
      title: "Tem certeza que deseja remover essa Escala de Potencial?",
      description:
        "OBS: O ideal é apenas editar a escala, pois algumas avaliações de potencial podem apresentar inconsistências e que necessitem correção manual por participantes e líderes.",
    };

  if (scaleSlug === "complementaryScales")
    return {
      title: "Tem certeza que deseja remover essa Escala Complementar?",
      description:
        "OBS: O ideal é apenas editar a escala, pois algumas avaliações complementares podem apresentar inconsistências e que necessitem correção manual dos líderes e avaliadores.",
    };

  return { title: "", description: "" };
};

export const getProfilesDeleteDialogInfo = (profileId, allQuestions = []) => {
  const linkedQuestions = getQuestionsByProfile(profileId, allQuestions);

  if (linkedQuestions && linkedQuestions.length > 0) {
    return {
      title: "Este Perfil está relacionado ao Quadro.",
      description: (
        <div>
          Vocẽ precisa remover todos os Subtemas do Quadro que estão
          relacionados à esse perfil:
          <div style={{ padding: "15px 0px" }}>
            {linkedQuestions.map(({ displayPath }, index) => {
              return <div key={index}>{displayPath}</div>;
            })}
          </div>
        </div>
      ),
      hideCancel: true,
      confirmText: "OK!",
      disableDelete: true,
    };
  }

  return {
    title: "Tem certeza que deseja excluir este Perfil?",
    description: "",
  };
};

export const getParticipantsDeleteDialogInfo = (participantId, allInsights) => {
  const linkedInsights = getInsightsByParticipant(participantId, allInsights);

  if (linkedInsights && linkedInsights.length > 0) {
    return {
      title: "Tem certeza que deseja remover este participante?",
      description: (
        <div>
          O participante será removido do workflow, dashboard e do quadro da
          ferramenta.
          <div style={{ padding: "15px 0px" }}>
            {linkedInsights.map(({ displayPath }, index) => {
              return <div key={index}>{displayPath}</div>;
            })}
          </div>
        </div>
      ),
      confirmText: "Excluir",
      linkedInsights,
    };
  }

  return {
    title: "Tem certeza que deseja excluir este Participante?",
    description: "",
  };
};

export const getParticipantsArchiveDialogInfo = (
  participantId,
  allInsights,
) => {
  const linkedInsights = getInsightsByParticipant(participantId, allInsights);

  if (linkedInsights && linkedInsights.length > 0) {
    return {
      title: "Tem certeza que deseja arquivar?",
      description: (
        <div>
          O participante será arquivado do workflow, dashboard e do quadro da
          ferramenta.
        </div>
      ),
      confirmText: "Arquivar",
      linkedInsights,
      hideCancel: false,
    };
  }

  return {
    title: "Não é possível arquivar esse participante!",
    description: "O participante não foi adicionado ao quadro!",
    confirmText: "Fechar",
    hideCancel: true,
  };
};

export const getParticipantsUnarchiveDialogInfo = (
  participantId,
  allInsights,
) => {
  const linkedInsights = getInsightsByParticipant(participantId, allInsights);

  if (linkedInsights && linkedInsights.length > 0) {
    return {
      title: "Tem certeza que deseja desarquivar?",
      description: <div>O participante será desarquivado.</div>,
      confirmText: "Desarquivar",
      linkedInsights,
    };
  }

  return {
    title: "Tem certeza?",
    description: "",
  };
};

export const getCompetenceAreasDeleteDialogInfo = (
  toolAdministration,
  competenceAreaId,
) => {
  const { skills = [] } = toolAdministration;

  const competenceAreaSkills = getSkillsByCompetenceAreas(
    [{ id: competenceAreaId }],
    skills,
  );

  if (competenceAreaSkills && competenceAreaSkills.length > 0) {
    return {
      title:
        "Esta Área de Competência está relacionada à uma Habilidade/Competência.",
      description: (
        <div>
          Vocẽ precisa remover todos essas competência para continuar a
          exclusão:
          <ul style={{ padding: "15px" }}>
            {competenceAreaSkills.map(({ title }, index) => {
              return (
                <li key={index}>
                  <b>{title}</b>
                </li>
              );
            })}
          </ul>
          <div>
            O ideal é apenas atualizar essa Área de Competência e as suas
            Competências/Habilidades relacionadas. Desta forma é possível manter
            os vínculos com o Participante, Workflow e outras relações.
          </div>
        </div>
      ),
      hideCancel: true,
      confirmText: "OK!",
      disableDelete: true,
    };
  }

  return {
    title: "Tem certeza que deseja excluir esta Área de Competência?",
    description: "",
  };
};

export const getSkillsDeleteDialogInfo = (toolAdministration, skillId) => {
  const { skills = [], participants = [] } = toolAdministration;

  const skillParticipants = getParticipantsBySkill(
    skillId,
    skills,
    participants,
  );

  if (skillParticipants && skillParticipants.length > 0) {
    return {
      title: "Tem certeza que deseja excluir esta Competência/Habilidade?",
      description: (
        <div>
          <b>Antes de excluir</b>: Tenha certeza que essa Competência não será
          mais utilizada.
          <div style={{ padding: "10px 0px" }}>
            Os Participantes abaixo estão relacionados com essa
            Competência/Habilidade:
          </div>
          <ul>
            {skillParticipants.map(({ name }, index) => {
              return (
                <li key={index}>
                  <b>{name}</b>
                </li>
              );
            })}
          </ul>
        </div>
      ),
    };
  }

  return {
    title: "Tem certeza que deseja excluir esta Competência/Habilidade?",
    description: "",
  };
};
