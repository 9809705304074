import React from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import utils from "../../../../../../../utils/toolUtils";
import { deleteProgramTeam } from "../../../../../../../actions/goalsAndBonusActions";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import {
  getEmptyTableRow,
  getDefaultAddTableLine,
} from "../../../../../../../utils/tableUtils";
import { getDefaultRemoveColumn } from "../../../../../../../utils/okrUtils";

const {
  getUpdatedDeleteArrayByIndex,
  orderArrayByObjAttr,
  getObjectInfoById,
} = utils;

const messages = defineMessages({
  gb_table_text_areas_teams: { id: "gb_table_text_areas_teams" },
  gb_table_text_no_team: { id: "gb_table_text_no_team" },
  gb_warning_need_arquive_cenary: { id: "gb_warning_need_arquive_cenary" },
  gb_warning_connected_team_cenary: { id: "gb_warning_connected_team_cenary" },
  gb_confirm_delete_team: { id: "gb_confirm_delete_team" },
});

const TeamsConfigTable = (props) => {
  const {
    goalBonusAdministration,
    setGoalBonusAdministration,
    setConfirmationDialog,
    resetConfirmDialog,
    toggleOpenFormDialogs,
    allSceneries,
    intl,
    deleteProgramTeam,
    handleSetEditingForm,
  } = props;

  const handleDeleteTeam = (index) => {
    const deletingObjInfo = goalBonusAdministration.teams[index];

    if (deletingObjInfo.id) {
      deleteProgramTeam(deletingObjInfo.id, goalBonusAdministration.id);

      const updatedTeams = getUpdatedDeleteArrayByIndex(
        goalBonusAdministration.teams,
        index,
      );

      setGoalBonusAdministration({
        ...goalBonusAdministration,
        teams: updatedTeams,
      });
    }

    resetConfirmDialog();
  };

  const openDeleteTeamConfig = (index) => {
    const deletingTeamInfo = goalBonusAdministration.teams[index] || {};
    let allowedToDelete = true;

    if (deletingTeamInfo.id) {
      const relatedScenerie = getObjectInfoById(
        deletingTeamInfo.id,
        allSceneries,
        "goalsBonusProgramTeamId",
      );

      allowedToDelete = relatedScenerie && relatedScenerie.id ? false : true;

      setConfirmationDialog({
        title: allowedToDelete
          ? intl.formatMessage(messages.gb_confirm_delete_team)
          : intl.formatMessage(messages.gb_warning_connected_team_cenary),
        description: allowedToDelete
          ? null
          : intl.formatMessage(messages.gb_warning_need_arquive_cenary, {
              cenaryName: relatedScenerie.name,
            }),
        onConfirm: allowedToDelete
          ? () => handleDeleteTeam(index)
          : resetConfirmDialog,
        confirmText: !allowedToDelete ? "OK" : null,
        hideCancel: !allowedToDelete ? true : false,
        onCancel: resetConfirmDialog,
        open: true,
      });
    }
  };

  let reorderedTeams = orderArrayByObjAttr(
    goalBonusAdministration.teams,
    "name",
  );

  return (
    <table className="simple-table">
      <thead>
        {getDefaultAddTableLine(
          () => toggleOpenFormDialogs("newTeam"),
          {},
          intl.formatMessage(messages.gb_table_text_areas_teams),
        )}
      </thead>
      <tbody>
        {reorderedTeams.map((teamInfo, index) => {
          return (
            <tr key={`teams_${index}`} className="block-hover">
              <td align="center" style={{ width: "40px" }}>
                {index + 1}
              </td>
              <SimpleExcelCollumn
                id={"teamTitle" + teamInfo.id}
                label={intl.formatMessage(messages.gb_table_text_areas_teams)}
                type="text"
                value={teamInfo.name}
                onClick={() =>
                  handleSetEditingForm("newTeam", {
                    goalBonusAdministration,
                    teamId: teamInfo.id,
                  })
                }
                inputStyle={{ cursor: "pointer" }}
              />
              {getDefaultRemoveColumn(() => openDeleteTeamConfig(index))}
            </tr>
          );
        })}
        {getEmptyTableRow(
          goalBonusAdministration.teams,
          intl.formatMessage(messages.gb_table_text_no_team),
        )}
      </tbody>
    </table>
  );
};

export default injectIntl(
  connect(null, { deleteProgramTeam })(TeamsConfigTable),
);
