import { saveAs } from "file-saver";
import mime from "mime-types";

import { downloadFile } from "../actions/s3FilesActions";
import { base64ToBlob } from "./Base64Utils";
import { S3_BUCKETS } from "./global";

export const doDownload = (attachment) => {
  const { fileName } = attachment;
  const mimeType = mime.contentType(fileName);

  downloadFile(fileName, S3_BUCKETS.ATTACHMENTS).then((fileBytes) => {
    const blob = base64ToBlob(fileBytes, mimeType);
    saveAs(blob, fileName);
  });
};
