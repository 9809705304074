import moment from "moment";
import { checkGreaterThan, floatNumber, getToFixed, round } from "../MathUtils";
import { getComparableQuery } from "../StringUtils";
import { Errors } from "../validations";
import { getRoleSkillMinimumRelations } from "./calc";
import {
  getAccessRelation,
  getCompetenceConfigByComposition,
  getSuccessionEvaluationInfo,
} from "./constants";
import { workflowRequiredExceptions } from "./defaults";
import { getTabErrorSlugs } from "./errorConstants";
import {
  checkUserRoleForParticipant,
  getCompetenceAreaSplitedItems,
  getComplementaryEvaluationsByType,
  getComplementaryScalesByType,
  getProfileSplitedItems,
  getScaleSplitedItems,
  getSkillSplitedItems,
  mountDatesGroup,
} from "./filter";

export const genericEmpty = (checkEntity, slug, message, isArray = false) => {
  const errors = new Errors();

  if (
    !checkEntity ||
    (isArray && (!Array.isArray(checkEntity) || checkEntity.length === 0))
  )
    errors.add(slug, message);

  return errors.getErrors();
};

const validateArray = (checkArray, slug, message) => {
  return genericEmpty(checkArray, slug, message, true);
};

export const validateGenericScale = (
  scaleInfo = {},
  scaleSlug = "evaluation"
) => {
  const errors = new Errors();

  const { value, title } = scaleInfo;

  if (!checkGreaterThan(value, 0, true))
    errors.add(
      `${scaleSlug}_scale_invalid_value`,
      "O valor da escala deve ser maior ou igual à 0"
    );

  if (!title || title.length < 1)
    errors.add(
      `${scaleSlug}_scale_invalid_title`,
      "Preencha o título da escala."
    );

  return errors.getErrors();
};

export const validateNewGenericScale = (newScale, originScale) => {
  const errors = new Errors();

  const { values, queryTitles } = getScaleSplitedItems(originScale);

  if (
    checkGreaterThan(newScale.value, -1) &&
    values.indexOf(Number(newScale.value)) > -1
  ) {
    errors.add("duplicated_value", "Já existe uma escala com este valor.");
  }

  if (queryTitles.indexOf(getComparableQuery(newScale.title)) > -1) {
    errors.add("duplicated_title", "Já existe uma escala com este título.");
  }

  return errors.getErrors();
};

export const validateEvaluationScale = (evaluationScale) => {
  const errors = new Errors();

  errors.addMultiple(
    validateArray(
      evaluationScale,
      "invalid_evaluation_scale",
      "A Escala de Avaliação precisa ser preenchida."
    )
  );

  if (errors.hasErrors()) return [];

  evaluationScale.forEach((scaleInfo) => {
    errors.addMultiple(validateGenericScale(scaleInfo, "evaluation"));
  });

  return errors.getErrors();
};

export const validateQualificationScale = (qualificationScale) => {
  const errors = new Errors();

  errors.addMultiple(
    validateArray(
      qualificationScale,
      "invalid_qualification_scale",
      "A Escala de Critério de Qualificação precisa ser preenchida."
    )
  );

  if (errors.hasErrors()) return [];

  qualificationScale.forEach((scaleInfo) => {
    errors.addMultiple(validateGenericScale(scaleInfo, "qualification"));
  });

  return errors.getErrors();
};

export const validatePotentialScale = (potentialScale) => {
  const errors = new Errors();

  errors.addMultiple(
    validateArray(
      potentialScale,
      "invalid_potential_scale",
      "A Escala Potencial de Evolução precisa ser preenchida."
    )
  );

  if (errors.hasErrors()) return [];

  potentialScale.forEach((scaleInfo) => {
    errors.addMultiple(validateGenericScale(scaleInfo, "potential"));
  });

  return errors.getErrors();
};

export const validateComplementaryScale = (complementaryScales, type) => {
  const errors = new Errors();

  const filtered = getComplementaryScalesByType(complementaryScales, type);

  errors.addMultiple(
    validateArray(
      filtered,
      "invalid_keyseat_scale",
      "A Escala Complementar precisa ser preenchida."
    )
  );

  if (errors.hasErrors()) return [];

  filtered.forEach((scaleInfo) => {
    errors.addMultiple(validateGenericScale(scaleInfo, type.toLowerCase()));
  });

  return errors.getErrors();
};

export const validateDates = (dates) => {
  const errors = new Errors();

  const datesGroup = mountDatesGroup(dates);

  datesGroup.forEach(({ slug, label, start, end }) => {
    if (!moment(start).isValid() || !moment(end).isValid())
      errors.add(
        `invalid_dates_${slug}`,
        `${label} - Preencha as datas corretamente.`
      );

    if (!moment(start).isBefore(end))
      errors.add(
        `invalid_dates_start_end_${slug}`,
        `${label} - A data INÍCIO deve ser menor que a data FIM.`
      );
  });

  return errors.getErrors();
};

export const validateEvaluationWeights = (
  evaluationType,
  evaluationWeights
) => {
  const errors = new Errors();

  const weights = {
    selfEvaluation: floatNumber(evaluationWeights.selfEvaluation) || 0,
    manager: floatNumber(evaluationWeights.manager) || 0,
    secondEvaluator: floatNumber(evaluationWeights.secondEvaluator) || 0,
    thirdEvaluator: floatNumber(evaluationWeights.thirdEvaluator) || 0,
  };

  const totalSum = round(
    weights.selfEvaluation +
      weights.manager +
      weights.secondEvaluator +
      weights.thirdEvaluator
  );

  if (
    evaluationType === "TYPE_360" &&
    (totalSum > 100 || totalSum < 100 || isNaN(totalSum))
  ) {
    errors.add(
      "invalid_weights",
      `A soma dos Pesos de ser igual à 100%. Total atingido: ${totalSum}%`
    );
  }

  return errors.getErrors();
};

export const validateNewProfile = (newProfile, originalProfiles) => {
  const errors = new Errors();

  const { queryTitles } = getProfileSplitedItems(originalProfiles);

  if (queryTitles.indexOf(getComparableQuery(newProfile.title)) > -1) {
    errors.add("duplicated_title", "Já existe outro perfil com este título.");
  }

  return errors.getErrors();
};

export const validateSingleProfile = (profileInfo) => {
  const errors = new Errors();

  if (!profileInfo || profileInfo.title.length < 1)
    errors.add("invalid_profile_title", "Preencha o título do Perfil.");

  return errors.getErrors();
};

export const validateProfiles = (profiles) => {
  const errors = new Errors();

  errors.addMultiple(
    validateArray(profiles, "invalid_profile", "Nenhum perfil encontrado.")
  );

  if (errors.hasErrors()) return [];

  profiles.forEach((profileInfo) => {
    errors.addMultiple(validateSingleProfile(profileInfo));
  });

  return errors.getErrors();
};

export const validateSingleParticipant = (participantInfo) => {
  const errors = new Errors();

  errors.addMultiple(
    genericEmpty(
      participantInfo,
      "invalid_participant",
      "Participante inválido."
    )
  );

  if (errors.hasErrors()) return [];

  const { memberParticipantId, name } = participantInfo;

  if (!memberParticipantId)
    errors.add("invalid_participant_member", "Selecione o Participante.");

  if (!participantInfo.companyAreaId)
    errors.add("invalid_participant_area", `${name} - Selecione a Área.`);
  if (!participantInfo.companyPositionId)
    errors.add("invalid_participant_role", `${name} - Selecione o Cargo.`);

  if (!participantInfo.profile || !participantInfo.profile.id)
    errors.add("invalid_participant_profile", `${name} - Selecione o Perfil.`);

  if (!participantInfo.manager || !participantInfo.manager.id)
    errors.add(
      "invalid_participant_manager",
      `${name} - Selecione o Gestor Imediato.`
    );

  return errors.getErrors();
};

export const validateParticipants = (participants, evaluationType) => {
  const errors = new Errors();

  errors.addMultiple(
    validateArray(
      participants,
      "invalid_participant",
      "Nenhum Participante foi registrado.."
    )
  );

  if (errors.hasErrors()) return [];

  participants.forEach((participantInfo) => {
    errors.addMultiple(
      validateSingleParticipant(participantInfo, evaluationType)
    );
  });

  return errors.getErrors();
};

export const validateNewCompetenceArea = (
  newCompetenceArea,
  originalCompAreas
) => {
  const errors = new Errors();

  const { queryTitles } = getCompetenceAreaSplitedItems(originalCompAreas);

  if (queryTitles.indexOf(getComparableQuery(newCompetenceArea.title)) > -1) {
    errors.add(
      "duplicated_title",
      "Já existe outra Área de Competência com este título."
    );
  }

  return errors.getErrors();
};

export const validateSingleCompetenceArea = (competenceArea) => {
  const errors = new Errors();

  errors.addMultiple(
    genericEmpty(
      competenceArea,
      "invalid_competence_area",
      "Área de competência inválida."
    )
  );

  if (errors.hasErrors()) return [];

  if (!competenceArea.title || competenceArea.title.length < 1)
    errors.add(
      "invalid_competence_area_title",
      "Preencha o título da Área de Competência."
    );

  errors.addMultiple(
    validateArray(
      competenceArea.profiles,
      "invalid_competence_area_profiles",
      "Selecione um Perfil."
    )
  );

  return errors.getErrors();
};

export const validateCompetenceAreas = (competenceAreas) => {
  const errors = new Errors();

  errors.addMultiple(
    validateArray(
      competenceAreas,
      "invalid_competence_areas",
      "Nenhuma Área de Competência registrada."
    )
  );

  if (errors.hasErrors()) return [];

  competenceAreas.forEach((competenceAreaInfo) => {
    errors.addMultiple(validateSingleCompetenceArea(competenceAreaInfo));
  });

  return errors.getErrors();
};

export const validateNewSkill = (newSkill, originalSkills) => {
  const errors = new Errors();

  const { queryTitles } = getSkillSplitedItems(originalSkills);

  if (queryTitles.indexOf(getComparableQuery(newSkill.title)) > -1) {
    errors.add(
      "duplicated_title",
      "Já existe outra Competência com este título."
    );
  }

  return errors.getErrors();
};

export const validateSingleSkill = (skill) => {
  const errors = new Errors();

  errors.addMultiple(
    genericEmpty(skill, "invalid_skill", "Competência inválida.")
  );

  if (errors.hasErrors()) return [];

  if (!skill.title || skill.title.length < 1)
    errors.add("invalid_skill_title", "Preencha o título da Competência.");

  if (!skill.competenceArea || !skill.competenceArea.id)
    errors.add(
      "invalid_skill_competenceArea",
      "Selecione a Área de Competência."
    );

  return errors.getErrors();
};

export const validateSkills = (skills) => {
  const errors = new Errors();

  errors.addMultiple(
    validateArray(skills, "invalid_skills", "Nenhuma Competência registrada.")
  );

  if (errors.hasErrors()) return [];

  skills.forEach((skillInfo) => {
    errors.addMultiple(validateSingleSkill(skillInfo));
  });

  return errors.getErrors();
};

export const validateSingleComplementaryEvaluation = (
  complementaryEvaluation,
  filterType
) => {
  const errors = new Errors();

  const lowercaseType = filterType.toLowerCase();

  errors.addMultiple(
    genericEmpty(
      complementaryEvaluation,
      `invalid_${lowercaseType}_complementary_evaluation`,
      "Avaliação inválida."
    )
  );

  if (errors.hasErrors()) return [];

  if (
    !complementaryEvaluation.title ||
    complementaryEvaluation.title.length < 1
  )
    errors.add(
      `invalid_${lowercaseType}_complementary_evaluation_title`,
      "Preencha o título da Avaliação."
    );

  return errors.getErrors();
};

export const validateComplementaryEvaluations = (
  complementaryEvaluations,
  filterType
) => {
  const errors = new Errors();

  const filtered = getComplementaryEvaluationsByType(
    complementaryEvaluations,
    filterType
  );

  filtered.forEach((evaluationInfo) => {
    errors.addMultiple(
      validateSingleComplementaryEvaluation(evaluationInfo, filterType)
    );
  });

  return errors.getErrors();
};

export const getGeneralValidations = (toolAdministration) => {
  const errors = new Errors();

  const {
    composition,
    evaluationType,
    evaluationWeights,
    evaluationScale,
    qualificationScale,
    potentialScale,
    dates,
    profiles,
    participants,
    competenceAreas,
    skills,
    complementaryScales,
    complementaryEvaluations,
    evaluationConfig,
  } = toolAdministration;

  const { enableQualification: eQualif, enablePotential: ePoten } =
    getCompetenceConfigByComposition(composition);

  if (eQualif)
    errors.addMultiple(validateQualificationScale(qualificationScale));
  if (ePoten) errors.addMultiple(validatePotentialScale(potentialScale));
  errors.addMultiple(validateEvaluationScale(evaluationScale));
  errors.addMultiple(
    validateEvaluationWeights(evaluationType, evaluationWeights)
  );
  errors.addMultiple(validateDates(dates));
  errors.addMultiple(validateProfiles(profiles));
  errors.addMultiple(validateParticipants(participants, evaluationType));
  errors.addMultiple(validateCompetenceAreas(competenceAreas, evaluationType));
  errors.addMultiple(validateSkills(skills, evaluationType));
  if (evaluationConfig.keySeat) {
    errors.addMultiple(
      validateComplementaryEvaluations(complementaryEvaluations, "KEY_SEAT")
    );
    errors.addMultiple(
      validateComplementaryScale(complementaryScales, "KEY_SEAT")
    );
  }
  if (evaluationConfig.performance) {
    errors.addMultiple(
      validateComplementaryEvaluations(complementaryEvaluations, "PERFORMANCE")
    );
    errors.addMultiple(
      validateComplementaryScale(complementaryScales, "PERFORMANCE")
    );
  }

  return errors.getErrors();
};

export const workflowBasicFields = (
  participantInfo,
  enableSecondEvaluator,
  enableThirdEvaluator
) => {
  const errors = new Errors();

  const { secondEvaluator, thirdEvaluator } = participantInfo;

  if (
    (enableSecondEvaluator && (!secondEvaluator || !secondEvaluator.id)) ||
    (enableThirdEvaluator && (!thirdEvaluator || !thirdEvaluator.id))
  )
    errors.add("no_second_evaluator", "Selecione todos os Avaliadores.");

  return errors.getErrors();
};

export const checkEvaluationValues = (participantWorkflow, checkFor) => {
  const errors = new Errors();

  if (Array.isArray(participantWorkflow) && Array.isArray(checkFor)) {
    const invalidSlugs = [];

    participantWorkflow.forEach((workflowInfo) => {
      checkFor.forEach((slug) => {
        const isEmptyValue = !checkGreaterThan(workflowInfo[slug], 0, true);
        if (isEmptyValue && invalidSlugs.indexOf(slug) === -1)
          invalidSlugs.push(slug);
      });
    });

    invalidSlugs.forEach((slug) => {
      errors.add(
        `invalid_workflow_values_${slug}`,
        "É preciso preencher todos os campos da Avaliação."
      );
    });
  } else {
    errors.add(
      "no_participant_workflow",
      "Nenhum Workflow registrado para o Participante."
    );
  }

  return errors.getErrors();
};

export const checkComplementaryEvaluationValues = (
  evaluationConfig,
  participantComplementaryEvaluation
) => {
  const errors = new Errors();

  if (!evaluationConfig)
    errors.add(
      "invalid_evaluation_config",
      "A configuração da avaliação é obrigatória"
    );

  const { keySeat, succession, performance } = evaluationConfig || {};
  const haveComplementaryStages = keySeat || succession || performance;

  if (!participantComplementaryEvaluation && haveComplementaryStages)
    errors.add(
      "invalid_complementary_workflow",
      "A avaliação complementar é obrigatória"
    );

  const emptyFields = participantComplementaryEvaluation?.filter(
    (complementaryLine) => {
      const { complementaryEvaluation } = complementaryLine;

      const { workflowValueSlug } = getSuccessionEvaluationInfo(
        complementaryEvaluation
      );
      const ignoreKeySeat =
        complementaryEvaluation.type === "KEY_SEAT" && !keySeat;
      const ignoreSuccession =
        complementaryEvaluation.type === "SUCCESSION" && !succession;
      const ignorePerformance =
        complementaryEvaluation.type === "PERFORMANCE" && !performance;

      if (ignoreKeySeat || ignoreSuccession || ignorePerformance) return false;

      if (workflowValueSlug) {
        if (
          workflowValueSlug === "alternativeValueUser" ||
          workflowRequiredExceptions.includes(complementaryEvaluation.title)
        )
          return false;
        return complementaryLine[workflowValueSlug] === "";
      }

      return complementaryLine.value === "";
    }
  );

  if (emptyFields?.length > 0)
    errors.add(
      "invalid_workflow_values_complementary",
      "Preencha todos os campos da avaliação complementar."
    );

  return errors.getErrors();
};

export const workflowEvaluatorDates = (dates) => {
  const errors = new Errors();
  const currentDate = moment();

  const { startEvaluators, endEvaluators } = dates;

  if (moment(currentDate).isBefore(startEvaluators))
    errors.add(
      "validation_not_started",
      "O período de Avaliadores ainda nao foi iniciado."
    );
  if (!moment(currentDate).isBefore(endEvaluators))
    errors.add(
      "validationEnded",
      "O período de Avaliadores já foi finalizado. Peça à um Administrador para extender o tempo de Avaliadores."
    );

  return errors.getErrors();
};

export const managerEvaluationErrors = (
  participantWorkflow,
  enablePotential,
  evaluationConfig,
  participantComplementaryWorkflow
) => {
  const errors = new Errors();

  const fields = ["managerEvaluation"];
  if (enablePotential) fields.push("potential");

  const managerEvaluationValues = checkEvaluationValues(
    participantWorkflow,
    fields
  );
  const complementaryEvaluationValues = checkComplementaryEvaluationValues(
    evaluationConfig,
    participantComplementaryWorkflow
  );
  errors.addMultiple(managerEvaluationValues);
  errors.addMultiple(complementaryEvaluationValues);

  return errors.getErrors();
};

export const sendWorkflowToSelfEvaluation = (
  participantInfo,
  dates,
  enableSecondEvaluator,
  enableThirdEvaluator
) => {
  const errors = new Errors();
  const currentDate = moment();

  const { manager } = participantInfo;
  const { startValidation, endValidation } = dates;

  if (moment(currentDate).isBefore(startValidation))
    errors.add(
      "validation_not_started",
      "O período de Validação ainda nao foi iniciado."
    );
  if (!moment(currentDate).isBefore(endValidation))
    errors.add(
      "validationEnded",
      "O período de Validação já foi finalizado. Peça à um Administrador para extender o tempo de Validação."
    );
  if (!manager || !manager.id)
    errors.add("no_manager", "Selecione o Gestor Imediato.");

  const basicErrors = workflowBasicFields(
    participantInfo,
    enableSecondEvaluator,
    enableThirdEvaluator
  );

  errors.addMultiple(basicErrors);

  return errors.getErrors();
};

export const sendWorkflowToManager = (
  participantInfo,
  participantWorkflow,
  dates,
  enableSecondEvaluator,
  enableThirdEvaluator
) => {
  const errors = new Errors();
  const currentDate = moment();

  const { manager } = participantInfo;
  const { startSelfEvaluation, endSelfEvaluation } = dates;

  if (moment(currentDate).isBefore(startSelfEvaluation))
    errors.add(
      "validation_not_started",
      "O período de Autoavaliação ainda nao foi iniciado."
    );
  if (!moment(currentDate).isBefore(endSelfEvaluation))
    errors.add(
      "validationEnded",
      "O período de Autoavaliação já foi finalizado. Peça à um Administrador para extender o tempo de Autoavaliação."
    );
  if (!manager || !manager.id)
    errors.add("no_manager", "Selecione o Gestor Imediato.");

  const selfEvaluationValues = checkEvaluationValues(participantWorkflow, [
    "selfEvaluation",
  ]);
  const basicErrors = workflowBasicFields(
    participantInfo,
    enableSecondEvaluator,
    enableThirdEvaluator
  );

  errors.addMultiple(basicErrors);
  errors.addMultiple(selfEvaluationValues);

  return errors.getErrors();
};

export const sendWorkflowToEvaluators = (
  participantInfo,
  participantWorkflow,
  dates,
  enableSecondEvaluator,
  enableThirdEvaluator,
  enablePotential,
  evaluationConfig,
  participantComplementaryWorkflow
) => {
  const errors = new Errors();

  const evaluatorDates = workflowEvaluatorDates(dates);
  const managerEvaluationValues = managerEvaluationErrors(
    participantWorkflow,
    enablePotential,
    evaluationConfig,
    participantComplementaryWorkflow
  );
  const basicErrors = workflowBasicFields(
    participantInfo,
    enableSecondEvaluator,
    enableThirdEvaluator
  );

  errors.addMultiple(evaluatorDates);
  errors.addMultiple(managerEvaluationValues);
  errors.addMultiple(basicErrors);

  return errors.getErrors();
};

export const sendWorkflowToApproval = (
  participantInfo,
  participantWorkflow,
  dates,
  enableSecondEvaluator,
  enableThirdEvaluator,
  enablePotential,
  evaluationConfig,
  participantComplementaryWorkflow,
  loggedUser
) => {
  const errors = new Errors();

  const roleRelation = checkUserRoleForParticipant(loggedUser, participantInfo);

  const { isSecEval, isThrEval } = getAccessRelation(
    roleRelation,
    loggedUser,
    participantInfo
  );

  const evaluatorDates = workflowEvaluatorDates(dates);

  const secondEvaluatorValues =
    enableSecondEvaluator && isSecEval
      ? checkEvaluationValues(participantWorkflow, ["secondEvaluator"])
      : [];

  const thirdEvaluatorValues =
    enableThirdEvaluator && isThrEval
      ? checkEvaluationValues(participantWorkflow, ["thirdEvaluator"])
      : [];

  const managerEvaluationValues = managerEvaluationErrors(
    participantWorkflow,
    enablePotential,
    evaluationConfig,
    participantComplementaryWorkflow
  );

  const basicErrors = workflowBasicFields(
    participantInfo,
    enableSecondEvaluator,
    enableThirdEvaluator
  );

  errors.addMultiple(evaluatorDates);
  errors.addMultiple(secondEvaluatorValues);
  errors.addMultiple(thirdEvaluatorValues);
  errors.addMultiple(managerEvaluationValues);
  errors.addMultiple(basicErrors);

  return errors.getErrors();
};

export const sendWorkflowToConcluded = (dates) => {
  const errors = new Errors();
  const currentDate = moment();

  const { startClosure, endClosure } = dates;

  if (moment(currentDate).isBefore(startClosure))
    errors.add(
      "validation_not_started",
      "O período de Fechamento ainda nao foi iniciado."
    );
  if (!moment(currentDate).isBefore(endClosure))
    errors.add(
      "validationEnded",
      "O período de Fechamento já foi finalizado. Peça à um Administrador para extender o tempo de Fechamento."
    );

  return errors.getErrors();
};

export const validateWorkflowActions = (
  participantInfo,
  participantWorkflow,
  dates,
  enableSecondEvaluator,
  enableThirdEvaluator,
  composition,
  evaluationConfig,
  participantComplementaryWorkflow,
  loggedUserRole,
  loggedUser
) => {
  const { enablePotential } = getCompetenceConfigByComposition(composition);
  const { evaluationStage } = participantInfo;

  if (evaluationStage === "SELECTION_EVALUATORS") {
    return sendWorkflowToSelfEvaluation(
      participantInfo,
      dates,
      enableSecondEvaluator,
      enableThirdEvaluator
    );
  }

  if (evaluationStage === "VALIDATION") {
    return sendWorkflowToSelfEvaluation(
      participantInfo,
      dates,
      enableSecondEvaluator,
      enableThirdEvaluator,
      enablePotential
    );
  }

  if (evaluationStage === "SELF_EVALUATION") {
    if (loggedUserRole === "MANAGER") {
      // MANAGER fields validation
      return sendWorkflowToEvaluators(
        participantInfo,
        participantWorkflow,
        dates,
        enableSecondEvaluator,
        enableThirdEvaluator,
        enablePotential,
        evaluationConfig,
        participantComplementaryWorkflow
      );
    }

    // SELF_EVALUATION fields validation
    return sendWorkflowToManager(
      participantInfo,
      participantWorkflow,
      dates,
      enableSecondEvaluator,
      enableThirdEvaluator
    );
  }

  if (
    evaluationStage === "MANAGERS" &&
    (enableSecondEvaluator || enableThirdEvaluator)
  ) {
    return sendWorkflowToEvaluators(
      participantInfo,
      participantWorkflow,
      dates,
      enableSecondEvaluator,
      enableThirdEvaluator,
      enablePotential,
      evaluationConfig,
      participantComplementaryWorkflow
    );
  }

  if (evaluationStage === "MANAGERS") {
    return sendWorkflowToApproval(
      participantInfo,
      participantWorkflow,
      dates,
      enableSecondEvaluator,
      enableThirdEvaluator,
      enablePotential,
      evaluationConfig,
      participantComplementaryWorkflow,
      loggedUser
    );
  }

  if (evaluationStage === "EVALUATORS") {
    return sendWorkflowToApproval(
      participantInfo,
      participantWorkflow,
      dates,
      enableSecondEvaluator,
      enableThirdEvaluator,
      enablePotential,
      evaluationStage,
      participantComplementaryWorkflow,
      loggedUser
    );
  }

  if (evaluationStage === "APPROVAL") {
    return sendWorkflowToConcluded(dates);
  }

  return [];
};

export const validateRoleSkillRelation = (roleSkillRelation) => {
  const errors = new Errors();

  const { isValid, percentage } =
    getRoleSkillMinimumRelations(roleSkillRelation);

  if (!isValid) {
    errors.add(
      "relation_inconsistency",
      `Apenas ${getToFixed(
        percentage
      )}% das Relações (Cargo x Competência) foram configuradas.`
    );

    errors.add(
      "relation_inconsistency_complement",
      "Para obter resultados com precisão, preencha o máximo possível."
    );
  }

  return errors.getErrors();
};

export const getAdministrationTabErrors = (tabKey, administrationErrors) => {
  const errors = new Errors();

  errors.addMultiple(administrationErrors);

  return errors.filterBySlugs(getTabErrorSlugs(tabKey));
};
