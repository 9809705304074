import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";
import _ from "lodash";
import SelectCompetenceArea from "../forms/SelectCompetenceArea";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import DeleteColumn from "../../../../../../Common/Tables/DeleteColumn";
import {
  getEmptyTableRow,
  getDefaultAddTableLine,
} from "../../../../../../../utils/tableUtils";
import { translatedText } from "../../../../../../../utils/translationUtils";
import { simpleEditArrayOnAdministration } from "../../../../../../../utils/skillsAssessment/manipulation";
import { orderSkillsByCompetenceArea } from "../../../../../../../utils/skillsAssessment/filter";
import EditTextModal from "../../../../../../Common/EditTextModal";

const messages = defineMessages({
  global_noneRegistered: { id: "global.noneRegistered" },
});

const translation = (id) => translatedText(id, messages);

const TableSkills = ({
  toolAdministration,
  updateAdministration,
  handleAutoSave,
  handleAdd,
  openDeleteSkill,
  orderedCompetenceAreas,
}) => {
  const [selectModal, setSelectModal] = useState({
    type: null,
    onConfirm: () => { },
    onCancel: () => { },
  });
  const [displaySkills, setDisplaySkills] = useState([]);

  const { skills = [] } = toolAdministration;

  useEffect(() => {
    const newSkills = orderSkillsByCompetenceArea(
      skills,
      orderedCompetenceAreas,
    );
    if (!_.isEqual(newSkills, displaySkills)) setDisplaySkills(newSkills);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skills, orderedCompetenceAreas]);

  const closeSelectModal = () => {
    setSelectModal({ type: null, onConfirm: () => { }, onCancel: () => { } });
  };

  function saveUpdatedSkill(updatedSkill) {
    const newAdministration = simpleEditArrayOnAdministration(
      toolAdministration,
      "skills",
      updatedSkill.id,
      updatedSkill,
      "id",
    );

    updateAdministration(newAdministration);
    handleAutoSave(newAdministration);
  }

  const handleUpdateSkill = (skillInfo, editingField = "", newValue) => {
    if (skillInfo && editingField) {
      const updatedSkill = {
        ...skillInfo,
        [editingField]: newValue,
      };

      saveUpdatedSkill(updatedSkill);
    }
  };

  const handleUpdateCompetenceArea = (skillInfo, selected) => {
    const updatedSkill = {
      ...skillInfo,
      competenceArea: selected,
      competenceAreaId: selected.id,
    };
    saveUpdatedSkill(updatedSkill);
    closeSelectModal();
  };

  const openSelectCompetenceArea = (skillInfo) => {
    setSelectModal({
      type: "select_competence_area",
      onConfirm: (selected) =>
        handleUpdateCompetenceArea(skillInfo, selected[0]),
      onCancel: closeSelectModal,
      initialSelected: [skillInfo.competenceArea],
    });
  };

  const openEditText = (field, skillInfo) => {
    if (typeof skillInfo[field] !== "undefined") {
      setSelectModal({
        type: "edit_text",
        onConfirm: (newText) => {
          handleUpdateSkill(skillInfo, field, newText);
          closeSelectModal();
        },
        onCancel: closeSelectModal,
        title: "Editando competência/habilidade",
        text: skillInfo[field],
        label: field === "title" ? "Título" : "Descrição",
      });
    }
  };

  const handleTruncateDescription = (value) => {
    const truncated = `${value.substring(0, 290)}...`;
    return truncated;
  };

  return (
    <>
      {selectModal.type === "select_competence_area" && (
        <SelectCompetenceArea
          open
          competenceAreas={orderedCompetenceAreas}
          initialSelected={selectModal.initialSelected}
          onConfirm={selectModal.onConfirm}
          onCancel={selectModal.onCancel}
          singleSelect
        />
      )}

      {selectModal.type === "edit_text" && (
        <EditTextModal
          open
          title={selectModal.title}
          text={selectModal.text}
          label={selectModal.label}
          onConfirm={selectModal.onConfirm}
          onCancel={selectModal.onCancel}
          maxCharacters={1500}
        />
      )}
      <table className="simple-table">
        <thead>
          {getDefaultAddTableLine(
            handleAdd,
            {},
            "COMPETÊNCIAS, HABILIDADES E ATITUDES",
          )}
        </thead>
        <tbody>
          <tr className="block-white-space border-bottom-division">
            <td width="25%">Competência/Habilidade/Atitude</td>
            <td width="25%">Área de Competência</td>
            <td>Descrição</td>
            <td />
          </tr>
          {displaySkills?.map((skillInfo, index) => {
            const { competenceArea } = skillInfo;

            return (
              <tr key={skillInfo.id}>
                <SimpleExcelCollumn
                  id={`skillTitle${index}`}
                  label={skillInfo.title}
                  type="text"
                  value={skillInfo.title}
                  title={skillInfo.title}
                  onClick={() => openEditText("title", skillInfo)}
                  inputStyle={{ height: "50px" }}
                />
                <SimpleExcelCollumn
                  id={`skillCompetenceAreaTitle${index}`}
                  label={competenceArea.title}
                  type="text"
                  value={competenceArea.title}
                  title={competenceArea.title}
                  onClick={() => openSelectCompetenceArea(skillInfo)}
                  inputStyle={{ height: "50px" }}
                />
                <SimpleExcelCollumn
                  id={`skillDescription${index}`}
                  label={skillInfo.description}
                  type="text"
                  value={handleTruncateDescription(skillInfo.description)}
                  title={skillInfo.description}
                  onClick={() => openEditText("description", skillInfo)}
                  inputStyle={{ fontSize: "11px", maxHeight: "50px" }}
                  primaryInputProps={{ maxLength: 1500 }}
                  multiline
                />
                <DeleteColumn
                  handleDelete={() => openDeleteSkill(skillInfo.id)}
                />
              </tr>
            );
          })}
          {getEmptyTableRow(skills, translation("global_noneRegistered"))}
        </tbody>
      </table>
    </>
  );
};

TableSkills.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
  updateAdministration: PropTypes.func.isRequired,
  handleAutoSave: PropTypes.func.isRequired,
  orderedCompetenceAreas: PropTypes.array.isRequired,
  handleAdd: PropTypes.func.isRequired,
  openDeleteSkill: PropTypes.func.isRequired,
};

TableSkills.propTypes = {
  toolAdministration: PropTypes.object.isRequired,
  updateAdministration: PropTypes.func.isRequired,
  handleAutoSave: PropTypes.func.isRequired,
  orderedCompetenceAreas: PropTypes.array.isRequired,
  handleAdd: PropTypes.func.isRequired,
  openDeleteSkill: PropTypes.func.isRequired,
};

export default TableSkills;
