import React, { useEffect } from "react";
import styled from "styled-components";
import SimpleCheckbox from "../../../../../../Common/SimpleCheckbox";

const Wrapper = styled.div`
  padding: 15px;
`;

const Customization = ({
  importConfig,
  initImportConfig,
  updateSingleImportConfig,
}) => {
  useEffect(() => {
    initImportConfig({
      cenaryCycles: true,
      objectiveKeyResults: true,
      board: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CheckBoxLine = ({ field, label }) => {
    return (
      <tr>
        <td width="35">
          <SimpleCheckbox
            checked={importConfig[field]}
            onChange={() =>
              updateSingleImportConfig(field, !importConfig[field])
            }
          />
        </td>
        <td>{label}</td>
      </tr>
    );
  };

  return (
    <Wrapper>
      <table className="simple-table" style={{ marginBottom: "5px" }}>
        <thead>
          <tr>
            <td colSpan="100">ADMINISTRAÇÃO</td>
          </tr>
        </thead>
        <tbody>
          <CheckBoxLine
            field="cenaryCycles"
            label="Importar Cenários e Ciclos"
          />
          <CheckBoxLine
            field="objectiveKeyResults"
            label="Importar Objetivos e Resultados-chave"
          />
        </tbody>
      </table>
      <div style={{ padding: "5px 0px" }}>
        <b>OBS:</b> Os valores de atingimento de meta dos resultados-chave não
        serão copiados.
      </div>
      <table className="simple-table" style={{ marginBottom: "5px" }}>
        <thead>
          <tr>
            <td colSpan="100">QUADRO</td>
          </tr>
        </thead>
        <tbody>
          <CheckBoxLine field="board" label="Importar estrutura do quadro" />
        </tbody>
      </table>
      <div style={{ padding: "5px 0px" }}>
        <b>OBS:</b> Você poderá remover ou editar a estrutura do quadro depois
        de importar.
      </div>
    </Wrapper>
  );
};

export default Customization;
