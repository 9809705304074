import React, { useState } from "react";
import PropTypes from "prop-types";
import FormDialog from "../../../../../../Common/FormDialog";
import MaterialTextField from "../../../../../../Common/MaterialTextField";
import { getComplementaryEvaluationsEntity } from "../../../../../../../utils/skillsAssessment/entity";
import { addNewComplementaryEvaluation } from "../../../../../../../utils/skillsAssessment/manipulation";
import { getStyledErrorMessage } from "../../../../../../../utils/formValidation";
import { Errors } from "../../../../../../../utils/validations";
import { getScaleRelativeValues } from "../../../../../../../utils/skillsAssessment/filter";

const defaultForm = getComplementaryEvaluationsEntity([{}], {
  canInsertNewId: true,
})[0];

const NewComplementaryEvaluation = ({
  open,
  afterConfirm,
  afterCancel,
  toolAdministration,
  params = {},
}) => {
  const { complementaryScales = [] } = toolAdministration;
  const { nextValue } = getScaleRelativeValues(complementaryScales);

  const [form, setForm] = useState({
    ...defaultForm,
    value: nextValue,
  });

  const handleConfirm = () => {
    if (params?.type) {
      const newSaveEvaluation = {
        ...form,
        type: params.type,
        subType: params?.subType || "NONE",
      };

      const newAdministration = addNewComplementaryEvaluation(
        toolAdministration,
        newSaveEvaluation,
      );

      afterConfirm(newAdministration);
    }

    afterCancel();
  };

  const handleCancel = () => {
    afterCancel();
  };

  const validateForm = () => {
    const errors = new Errors();

    return { isValid: !errors.hasErrors(), errors: errors.getErrors() };
  };

  const updateField = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const formTitle = "Adicionar Avaliação Complementar";
  const { isValid, errors } = validateForm();

  return (
    <FormDialog
      open={open}
      title={formTitle}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      blockConfirm={!isValid}
    >
      <div className="row">
        <div className="col-xs-12">
          <MaterialTextField
            id="evaluationTitle"
            label="Título"
            value={form.title}
            onChange={(e) => updateField("title", e.target.value)}
          />
        </div>
        <div className="col-xs-12">
          <MaterialTextField
            id="evaluationDescription"
            label="Descrição"
            value={form.description}
            onChange={(e) => updateField("description", e.target.value)}
          />
        </div>
        <div className="col-xs-12">
          {getStyledErrorMessage(["duplicated_title"], errors)}
        </div>
      </div>
    </FormDialog>
  );
};

NewComplementaryEvaluation.propTypes = {
  open: PropTypes.bool,
  afterConfirm: PropTypes.func.isRequired,
  afterCancel: PropTypes.func.isRequired,
  toolAdministration: PropTypes.object.isRequired,
  params: PropTypes.any.isRequired,
};

NewComplementaryEvaluation.defaultProps = {
  open: false,
};

export default NewComplementaryEvaluation;
