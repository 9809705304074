import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import { S3_BUCKETS, globalMessages } from "../../../utils/global";
import { doDownload } from "../../../utils/attachmentsUtils";

import { deleteFile } from "../../../actions/s3FilesActions";

import DeleteDialog from "../DeleteDialog";
import FileList from "../FileList";

import { ContainerUpload, UploadMessage } from "./styles";

const Upload = ({
  onAdd,
  onDelete,
  intl,
  attachments,
  addNewAttachment,
  title,
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [files, setFiles] = useState([]);

  const entityName = intl.formatMessage(globalMessages.file);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const bodyAttachments = acceptedFiles.map((file, index) => {
        return Object.assign(file, {
          id: `${file.name}-${index}`,
          fileName: file.name,
          fileSize: file.size,
        });
      });

      if (onAdd) {
        onAdd(bodyAttachments, acceptedFiles);
      }

      setFiles([...files, ...bodyAttachments]);
    },
    [files, onAdd],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    open,
  } = useDropzone({
    onDrop,
    maxSize: 1e7,
  });

  useEffect(() => {
    if (attachments) {
      setFiles(attachments);
    }
  }, [attachments]);

  useEffect(() => {
    if (addNewAttachment && open) {
      open();
    }
  }, [addNewAttachment, open]);

  const handleShowDeleteDialog = useCallback(() => {
    setShowDeleteDialog(!showDeleteDialog);
  }, [showDeleteDialog]);

  const openModalAndSelectFile = useCallback(
    (file) => {
      setSelectedFile(file);
      handleShowDeleteDialog();
    },
    [handleShowDeleteDialog],
  );

  const renderDragMessage = (isDragActive, isDragReject) => {
    if (!isDragActive) {
      return (
        <UploadMessage>
          <span>
            Arraste e solte um arquivo aqui ou clique para selecionar um
            arquivo...
          </span>
        </UploadMessage>
      );
    }

    if (isDragReject) {
      return (
        <UploadMessage>
          <span>Arquivo não suportado.</span>
        </UploadMessage>
      );
    }

    return (
      <UploadMessage>
        <span>Solte o arquivo aqui...</span>
      </UploadMessage>
    );
  };

  const onConfirmDeleteFile = useCallback(async () => {
    handleShowDeleteDialog();

    onDelete(selectedFile.id);
    deleteFile(selectedFile.fileName, S3_BUCKETS.ATTACHMENTS);
    setFiles(files.filter((file) => file.id !== selectedFile.id));
  }, [handleShowDeleteDialog, selectedFile, onDelete, files]);

  return (
    <>
      <DeleteDialog
        onConfirm={onConfirmDeleteFile}
        entityName={entityName}
        show={showDeleteDialog}
        onCancel={handleShowDeleteDialog}
      />
      <ContainerUpload>
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          {renderDragMessage(isDragActive, isDragReject)}
        </div>
        {!!files.length && (
          <>
            <h4>{title}</h4>
            <FileList
              files={files}
              onDelete={openModalAndSelectFile}
              onDownload={doDownload}
            />
          </>
        )}
      </ContainerUpload>
    </>
  );
};

export default injectIntl(Upload);

Upload.propTypes = {
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  intl: PropTypes.object.isRequired,
  addNewAttachment: PropTypes.bool,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fileName: PropTypes.string.isRequired,
      fileType: PropTypes.string,
      date: PropTypes.string,
    }),
  ),
  title: PropTypes.string,
};

Upload.defaultProps = {
  onAdd: null,
  onDelete: null,
  addNewAttachment: false,
  attachments: [],
  title: "",
};
