import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import { defineMessages, injectIntl } from "react-intl";
import { connect } from "react-redux";

import WorkflowComplementaryGroup from "components/Tool/ToolTabs/CustomFields/SkillAssessment/tabs/workflow/WorkflowComplementaryGroup";
import { useSelectedWorkflowParticipant } from "components/Tool/ToolTabs/CustomFields/SkillAssessment/tabs/workflow/customHooks/useSelectedWorkflowParticipant";
import { getScaleToolTip } from "utils/skillsAssessment/display";
import { updateSingleInsight } from "../../../../actions/answersActions";
import { saveEvaluations } from "../../../../actions/insightActions";
import {
  getLeadingOptions,
  getPerfomanceEvaluationOptions,
} from "../../../../constants/peopleEvaluation";
import MaterialSingleSelect from "../../../Common/MaterialSingleSelect";
import MaterialTextField from "../../../Common/MaterialTextField";

const messages = defineMessages({
  globalSave: {
    id: "global.save",
  },
  evaluation_targeting: {
    id: "skas_assessment",
  },
  table_last_evaluations: {
    id: "pb_table_last_evaluations",
  },
  table_evaluation: {
    id: "pb_table_evaluation",
  },
  table_current: {
    id: "pb_table_current",
  },
  table_previous: {
    id: "pb_table_previous",
  },
  table_details: {
    id: "pb_table_details",
  },
});

const EvaluationTargetField = ({
  insight,
  evaluationTarget,
  setEvaluationTarget,
  evaluationPerformanceTarget,
  setEvaluationPerformanceTarget,
  evaluationTargetDetailsTarget,
  setEvaluationTargetDetailsTarget,
  evaluationGapDetailsTarget,
  setEvaluationGapDetailsTarget,
  updateSingleInsight: _updateSingleInsight,
  competenceAverage,
  toolAdministration,
  selectedToolId,
  loggedUser,
  selectedParticipantId,
  gapAverage,
  managerId,
  intl,
  average,
}) => {
  const skillsAssessmentEvaluationLeading =
    evaluationTarget.skillsAssessmentEvaluationLeading;

  const skillsAssessmentEvaluationPerformance =
    evaluationPerformanceTarget.skillsAssessmentEvaluationPerformance;

  const skillsAssessmentEvaluationDetails =
    evaluationTargetDetailsTarget.skillsAssessmentEvaluationDetails;

  const skillsAssessmentGapDetails =
    evaluationGapDetailsTarget.skillsAssessmentGapDetails;

  const leadingOptions = getLeadingOptions(intl);
  const performanceOptions = getPerfomanceEvaluationOptions(intl);

  const filteredAdministration = {
    ...toolAdministration,
  };
  const {
    changeStage,
    haveChanges,
    selectedParticipant,
    participantWorkflow,
    participantComplementaryWorkflow,
    setParticipantComplementaryWorkflow,
    handleSelectParticipant,
    setParticipantWorkflow,
    syncHistory,
  } = useSelectedWorkflowParticipant({
    toolAdministration: filteredAdministration,
    selectedParticipantId,
  });

  const getPerformanceAverage = () => {
    const performanceEvaluations = participantComplementaryWorkflow.filter(
      (item) => item.complementaryEvaluation.type === "PERFORMANCE"
    );
    const evaluationsLength = performanceEvaluations.length;
    if (evaluationsLength === 0) {
      return 0;
    }
    const totalValue = performanceEvaluations.reduce(
      (acc, { value }) => acc + value,
      0
    );
    const average = totalValue / evaluationsLength;

    return average.toFixed(1);
  };

  const handleUpdateEvaluation = () => {
    saveEvaluations(
      insight.id,
      skillsAssessmentEvaluationLeading,
      skillsAssessmentEvaluationPerformance,
      skillsAssessmentEvaluationDetails,
      skillsAssessmentGapDetails
    ).then((response) => {
      _updateSingleInsight(response.data);
    });
  };

  const setGapDetails = (e) => {
    setEvaluationGapDetailsTarget({
      ...evaluationGapDetailsTarget,
      skillsAssessmentGapDetails: e.target.value,
    });
  };

  const setTargetDetails = (e) => {
    setEvaluationTargetDetailsTarget({
      ...evaluationTargetDetailsTarget,
      skillsAssessmentEvaluationDetails: e.target.value,
    });
  };

  const setEvaluationsPerformance = (e) => {
    setEvaluationPerformanceTarget({
      ...evaluationPerformanceTarget,
      skillsAssessmentEvaluationPerformance: {
        ...skillsAssessmentEvaluationPerformance,
        current: e.target.value,
      },
    });
  };

  const setEvaluationPerformanceDetails = (e) => {
    setEvaluationPerformanceTarget({
      ...evaluationPerformanceTarget,
      skillsAssessmentEvaluationPerformance: {
        ...skillsAssessmentEvaluationPerformance,
        details: e.target.value,
      },
    });
  };

  const setEvaluationsTarget = (e) => {
    setEvaluationTarget({
      ...evaluationTarget,
      skillsAssessmentEvaluationLeading: {
        ...skillsAssessmentEvaluationLeading,
        current: e.target.value,
      },
    });
  };

  const setEvaluationLeadingDetails = (e) => {
    setEvaluationTarget({
      ...evaluationTarget,
      skillsAssessmentEvaluationLeading: {
        ...skillsAssessmentEvaluationLeading,
        details: e.target.value,
      },
    });
  };

  const formatEmptyValue = (value) => {
    if (value === " ") {
      return "empty";
    }
    return value;
  };

  const displayTableEvaluations = () => {
    const {
      managerEvaluation,
      selfEvaluation,
      secondEvaluator,
      thirdEvaluator,
    } = average || {};

    const options = [];

    if (selfEvaluation) {
      options.push({
        label: "Auto Avaliação: " + selfEvaluation,
      });
    }

    if (managerEvaluation) {
      options.push({
        label: "Gestor: " + managerEvaluation,
      });
    }

    if (secondEvaluator) {
      options.push({
        label: "Cliente: " + secondEvaluator,
      });
    }

    if (thirdEvaluator) {
      options.push({
        label: "Fornecedor: " + thirdEvaluator,
      });
    }

    const averageField = (
      <tr key="average">
        <td>Competência</td>
        <td align="center">
          <OverlayTrigger placement="top" overlay={getScaleToolTip(options)}>
            <h4>{competenceAverage}</h4>
          </OverlayTrigger>
        </td>
        <td>
          <MaterialTextField
            id="averageDetails"
            label="Details"
            value={skillsAssessmentEvaluationDetails}
            variant="standard"
            hideLabel
            onChange={(e) => setTargetDetails(e)}
            multiline
          />
        </td>
      </tr>
    );

    const performanceField = (
      <tr key="performance">
        <td>Desempenho</td>
        <td align="center">
          <h4>{getPerformanceAverage()}</h4>
        </td>
        <td>
          <MaterialTextField
            id="perfomanceDetails"
            label="Details"
            value={skillsAssessmentEvaluationPerformance?.details}
            variant="standard"
            hideLabel
            onChange={(e) => setEvaluationPerformanceDetails(e)}
            multiline
          />
        </td>
      </tr>
    );

    const leadingField = (
      <tr key="leading">
        <td>Liderança</td>
        <td align="center">
          <MaterialSingleSelect
            variant="standard"
            id="currentLeading"
            hideLabel
            label="Current"
            value={formatEmptyValue(skillsAssessmentEvaluationLeading.current)}
            onChange={(e) => setEvaluationsTarget(e)}
            options={leadingOptions}
          />
        </td>
        <td>
          <MaterialTextField
            id="leadingDetails"
            label="Details"
            value={skillsAssessmentEvaluationLeading?.details}
            variant="standard"
            hideLabel
            onChange={(e) => setEvaluationLeadingDetails(e)}
            multiline
          />
        </td>
      </tr>
    );

    const gapField = (
      <tr key="gap">
        <td>Gap</td>
        <td align="center">
          <h4>{gapAverage}</h4>
        </td>
        <td>
          <MaterialTextField
            id="gapDetails"
            label="Details"
            value={skillsAssessmentGapDetails}
            variant="standard"
            hideLabel
            onChange={(e) => setGapDetails(e)}
            multiline
          />
        </td>
      </tr>
    );

    const renderLines = [
      averageField,
      performanceField,
      leadingField,
      gapField,
    ];

    return (
      <tbody>
        {renderLines.map((element) => (
          <React.Fragment key={element.key}>{element}</React.Fragment>
        ))}
      </tbody>
    );
  };

  return (
    <div className="box box-primary">
      <div className="box-header with-border">
        <h3 className="box-title">
          <i className="far fa-dot-circle" />
          {` ${intl.formatMessage(messages.evaluation_targeting)}`}
        </h3>
        <div className="pull-right">
          <button
            className="btn btn-purple"
            onClick={() => handleUpdateEvaluation()}
          >
            {intl.formatMessage(messages.globalSave)}
          </button>
        </div>
      </div>
      <div className="box-body">
        <div className="col-xs-12">
          <h5 style={{ marginBottom: "-10px" }}>
            {intl.formatMessage(messages.table_last_evaluations)}
          </h5>
          <table className="simple-table">
            <thead>
              <tr>
                <td align="left">
                  {intl.formatMessage(messages.table_evaluation)}
                </td>
                <td style={{ minWidth: "200px" }}>
                  {intl.formatMessage(messages.table_current)}
                </td>
                <td align="left">
                  {intl.formatMessage(messages.table_details)}
                </td>
              </tr>
            </thead>
            {displayTableEvaluations()}
          </table>
        </div>
        <WorkflowComplementaryGroup
          selectedToolId={selectedToolId}
          toolAdministration={toolAdministration}
          loggedUser={loggedUser}
          filteredAdministration={filteredAdministration}
          insight={insight}
          complementaryWorkflow={participantComplementaryWorkflow}
          isInsight={true}
          managerId={managerId}
        />
      </div>
    </div>
  );
};

const mapDispatchtoProps = {
  updateSingleInsight,
};

export default connect(
  null,
  mapDispatchtoProps
)(injectIntl(EvaluationTargetField));
