export function checkRoute(pathname = "/", comparePath = "/") {
  return pathname === comparePath;
}

export function checkMultipleRoutes(findRoutes = [], validRoutes = []) {
  let isValid = false;

  for (const route of findRoutes) {
    if (validRoutes.indexOf(route) > -1) {
      isValid = true;

      break;
    }
  }

  return isValid;
}

export function getRoutesOverview(toolsId) {
  const overviewRoutes = [];

  toolsId.forEach(id => overviewRoutes.push(`/analytics/${id}`));

  return overviewRoutes;
}
