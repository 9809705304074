export const getPeriodTags = (
  index,
  target,
  percentage,
  goalId,
  frequency,
  period,
) => {
  const query = "pindex:";
  const indexTag = `${query}${index}`;

  return {
    all: [
      indexTag,
      `ptarget:${target}`,
      `ppercent:${percentage}`,
      `indicatorGoalId:${goalId}`,
      `indicatorFrequency:${frequency}`,
      `indicatorStart:${period?.start}`,
      `indicatorEnd:${period?.end}`,
      `indicatorYear:${period?.year}`,
    ],
    indexTag,
    query,
  };
};
