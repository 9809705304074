import React from "react";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";
import {
  getUpdatedObjectInArray,
  getOnlySublevelArrays,
} from "../../../../../../../utils/ArrayUtils";

const AnalysisGroups = (props) => {
  const {
    cpAnalysisAdministration = {},
    setCpAnalysisAdministration,
    intl,
    getEmptyTableRow,
    getDefaultRemoveColumn,
    getDefaultAddTableLine,
    setConfirmationDialog,
    resetConfirmDialog,
    toggleOpenFormDialogs,
    deleteAnalysisGroup,
    currentToolType = "",
    allAnswers,
    selectedTool,
  } = props;

  const { swot = [] } = cpAnalysisAdministration || {};
  const { analysisGroups = [] } = swot || {};

  const updateSwotField = (field = false, value = "") => {
    if (!field) return;

    const swotConfig = cpAnalysisAdministration.swot || {};

    setCpAnalysisAdministration({
      ...cpAnalysisAdministration,
      swot: {
        ...swotConfig,
        [field]: [...value],
      },
    });
  };

  const handleUpdateAnalysisGroup = (value = "", field = "", index = null) => {
    const updated = getUpdatedObjectInArray(
      value,
      analysisGroups,
      field,
      index,
    );

    updateSwotField("analysisGroups", updated);
  };

  const getBoardConnection = (referedGroup = {}) => {
    const onlyAnswers = getOnlySublevelArrays(allAnswers);

    const filtered =
      onlyAnswers.filter(
        (answer) => answer.competitiveAnalysisGroupId === referedGroup.id,
      )[0] || false;

    return filtered ? true : filtered;
  };

  const handleDeleteGroup = (index) => {
    const { id } = analysisGroups[index];

    deleteAnalysisGroup(id, "swot", selectedTool.id);

    resetConfirmDialog();
  };

  const openDeleteAnalysisGroup = (index) => {
    const referedGroup = analysisGroups[index];

    const checkBoardConnection = getBoardConnection(referedGroup);

    if (checkBoardConnection)
      return setConfirmationDialog({
        title:
          "Você precisa apagar todos os insights do Cenário SWOT para continuar com a exclusão.",
        onConfirm: () => resetConfirmDialog(),
        onCancel: () => resetConfirmDialog(),
        open: true,
      });

    setConfirmationDialog({
      title: "Você tem certeza?",
      onConfirm: () => handleDeleteGroup(index),
      onCancel: () => resetConfirmDialog(),
      open: true,
    });
  };

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="2">Título</td>
          <td />
        </tr>
      </thead>
      <tbody>
        {analysisGroups.map((ambientInfo, index) => {
          const { title } = ambientInfo || {};

          return (
            <tr className="block-hover" key={index}>
              <td align="center" style={{ width: "40px" }}>
                {index + 1}
              </td>
              <SimpleExcelCollumn
                id="ambientName"
                label="Titulo"
                type="text"
                value={title}
                onChange={(e) =>
                  handleUpdateAnalysisGroup(e.target.value, "title", index)
                }
              />
              {getDefaultRemoveColumn(() => openDeleteAnalysisGroup(index))}
            </tr>
          );
        })}
        {getEmptyTableRow(analysisGroups, "Nada para mostrar!")}
        {getDefaultAddTableLine(() =>
          toggleOpenFormDialogs("newSwotAnalysisGroup"),
        )}
      </tbody>
    </table>
  );
};

export default AnalysisGroups;
