import * as calc from "./calc";
import * as display from "./display";
import * as entity from "./entity";
import * as filter from "./filter";
import * as validation from "./validation";

const kpiUtils = {
  ...calc,
  ...display,
  ...entity,
  ...filter,
  ...validation,
};

export default kpiUtils;
