import { useEffect, useState } from "react";
import _ from "lodash";
import { getTimelineRelationDisplay } from "../commentUtils";

export const useConnectedComments = ({
  allComments,
  loggedUser,
  connectedInsights,
}) => {
  const [comments, setComments] = useState([]);
  const [timelineComments, setTimelineComments] = useState([]);

  useEffect(() => {
    if (allComments && connectedInsights) {
      const newComments = [];

      if (connectedInsights.length > 0) {
        connectedInsights.forEach((insightId) => {
          const insightComments = allComments[insightId] || [];

          insightComments.forEach((comment) =>
            newComments.push({ ...comment, insightId }),
          );
        });
      }

      if (!_.isEqual(newComments, comments)) {
        setComments(newComments);
        setTimelineComments(
          getTimelineRelationDisplay(loggedUser?.id, newComments),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedInsights, allComments]);

  return { comments, timelineComments };
};
