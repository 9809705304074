import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";

import MaterialTextField from "../../../Common/MaterialTextField";
import MaterialSingleSelect from "../../../Common/MaterialSingleSelect";
import SelectItemModal from "../../../Common/SelectItemModal";
import SimpleExcelCollumn from "../../../Common/SimpleExcelCollumn";

import {
  getLeadingOptions,
  getInterestOptions,
  getReadinessOptions,
  getCareerPathOptions,
  getTableCarrearPathLabels,
  getTableLastEvaluationLabels,
  getPerfomanceEvaluationOptions,
  getSalaryEvaluationOptions,
  getRoleEvaluationOptions,
  getCompanyPositionOptions,
} from "../../../../constants/peopleEvaluation";

const messages = defineMessages({
  globalSave: {
    id: "global.save",
  },
  evaluation_targeting: {
    id: "pb_evaluation_targeting",
  },
  table_last_evaluations: {
    id: "pb_table_last_evaluations",
  },
  table_evaluation: {
    id: "pb_table_evaluation",
  },
  table_current: {
    id: "pb_table_current",
  },
  table_previous: {
    id: "pb_table_previous",
  },
  table_details: {
    id: "pb_table_details",
  },
  table_career_map: {
    id: "pb_table_career_map",
  },
  table_career: {
    id: "pb_table_career",
  },
  table_readiness: {
    id: "pb_table_readiness",
  },
  table_path: {
    id: "pb_table_path",
  },
  salary_evaluation: {
    id: "pb_salary_evaluation",
  },
  role_evaluation: {
    id: "pb_role_evaluation",
  },
});

const EvaluationTargetField = ({
  evaluationTarget,
  setEvaluationTarget,
  saveButton,
  onSave,
  intl,
  allPositions = [],
}) => {
  const [selectedDetails, setSelectedDetails] = useState("");
  const [openModal, setOpenModal] = useState(false);

  let { lastEvaluations, carrearPath, salaryEvaluation, roleEvaluation } =
    evaluationTarget;

  salaryEvaluation = salaryEvaluation || {};
  roleEvaluation = roleEvaluation || {};

  // Select options constants
  const leadingOptions = getLeadingOptions(intl);
  const interestOptions = getInterestOptions(intl);
  const readinessOptions = getReadinessOptions(intl);
  const carrearPathOptions = getCareerPathOptions(intl);
  const tableCarrearPathLabels = getTableCarrearPathLabels(intl);
  const tableLastEvalutionLabels = getTableLastEvaluationLabels(intl);
  const performanceOptions = getPerfomanceEvaluationOptions(intl);
  const salaryEvaluationOptions = getSalaryEvaluationOptions(intl);
  const roleEvaluationOptions = getRoleEvaluationOptions(intl);
  const positionsOptions = getCompanyPositionOptions(allPositions);
  // End Select options contants

  const getSelectedDetails = useCallback((key) => {
    setSelectedDetails(key);
    setOpenModal(true);
  }, []);

  const handleChangeDetails = useCallback(
    (e) => {
      const findedPosition = positionsOptions.find(
        (position) => position.id === e[0],
      );

      setEvaluationTarget({
        ...evaluationTarget,
        carrearPath: {
          ...carrearPath,
          [selectedDetails]: {
            ...carrearPath[selectedDetails],
            details: findedPosition.title ?? "",
          },
        },
      });

      setOpenModal(false);
    },
    [
      selectedDetails,
      carrearPath,
      evaluationTarget,
      setEvaluationTarget,
      positionsOptions,
    ],
  );

  const displayTableEvaluations = () => {
    let { average, performance, leading, interest } = lastEvaluations;
    average = average || {};
    performance = performance || {};
    leading = leading || {};
    interest = interest || {};

    const averageField = (
      <tr>
        <td>{tableLastEvalutionLabels.average}</td>
        <td align="center">
          <MaterialTextField
            id="currentAverage"
            label="Current"
            type="number"
            value={average.current ? average.current : ""}
            variant="standard"
            hideLabel
            textAlign="center"
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                lastEvaluations: {
                  ...lastEvaluations,
                  average: {
                    ...average,
                    current: e.target.value,
                  },
                },
              })
            }
          />
        </td>
        <td align="center">
          <MaterialTextField
            id="previousAverage"
            label="Previous"
            type="number"
            value={average.previous ? average.previous : ""}
            variant="standard"
            hideLabel
            textAlign="center"
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                lastEvaluations: {
                  ...lastEvaluations,
                  average: {
                    ...average,
                    previous: e.target.value,
                  },
                },
              })
            }
          />
        </td>
        <td>
          <MaterialTextField
            id="averageDetails"
            label="Details"
            value={average.details ? average.details : ""}
            variant="standard"
            hideLabel
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                lastEvaluations: {
                  ...lastEvaluations,
                  average: {
                    ...average,
                    details: e.target.value,
                  },
                },
              })
            }
            multiline
          />
        </td>
      </tr>
    );

    const performanceField = (
      <tr>
        <td>{tableLastEvalutionLabels.performance}</td>
        <td align="center">
          <MaterialSingleSelect
            variant="standard"
            id="currentPerformance"
            hideLabel
            label="Current"
            value={performance.current ? performance.current : ""}
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                lastEvaluations: {
                  ...lastEvaluations,
                  performance: {
                    ...performance,
                    current: e.target.value,
                  },
                },
              })
            }
            options={performanceOptions}
          />
        </td>
        <td align="center">
          <MaterialSingleSelect
            variant="standard"
            id="previousPerformance"
            hideLabel
            label="Previous"
            value={performance.previous ? performance.previous : ""}
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                lastEvaluations: {
                  ...lastEvaluations,
                  performance: {
                    ...performance,
                    previous: e.target.value,
                  },
                },
              })
            }
            options={performanceOptions}
          />
        </td>
        <td>
          <MaterialTextField
            id="perfomanceDetails"
            label="Details"
            value={performance.details ? performance.details : ""}
            variant="standard"
            hideLabel
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                lastEvaluations: {
                  ...lastEvaluations,
                  performance: {
                    ...performance,
                    details: e.target.value,
                  },
                },
              })
            }
            multiline
          />
        </td>
      </tr>
    );

    const leadingField = (
      <tr>
        <td>{tableLastEvalutionLabels.leading}</td>
        <td align="center">
          <MaterialSingleSelect
            variant="standard"
            id="currentLeading"
            hideLabel
            label="Current"
            value={leading.current ? leading.current : ""}
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                lastEvaluations: {
                  ...lastEvaluations,
                  leading: {
                    ...leading,
                    current: e.target.value,
                  },
                },
              })
            }
            options={leadingOptions}
          />
        </td>
        <td align="center">
          <MaterialSingleSelect
            variant="standard"
            id="previousLeading"
            hideLabel
            label="Previous"
            value={leading.previous ? leading.previous : ""}
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                lastEvaluations: {
                  ...lastEvaluations,
                  leading: {
                    ...leading,
                    previous: e.target.value,
                  },
                },
              })
            }
            options={leadingOptions}
          />
        </td>
        <td>
          <MaterialTextField
            id="leadingDetails"
            label="Details"
            value={leading.details ? leading.details : ""}
            variant="standard"
            hideLabel
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                lastEvaluations: {
                  ...lastEvaluations,
                  leading: {
                    ...leading,
                    details: e.target.value,
                  },
                },
              })
            }
            multiline
          />
        </td>
      </tr>
    );

    const interestField = (
      <tr>
        <td>{tableLastEvalutionLabels.interest}</td>
        <td align="center">
          <MaterialSingleSelect
            variant="standard"
            id="currentInterest"
            hideLabel
            label="Current"
            value={interest.current ? interest.current : ""}
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                lastEvaluations: {
                  ...lastEvaluations,
                  interest: {
                    ...interest,
                    current: e.target.value,
                  },
                },
              })
            }
            options={interestOptions}
          />
        </td>
        <td align="center">
          <MaterialSingleSelect
            variant="standard"
            id="previousInterest"
            label="Previous"
            hideLabel
            value={interest.previous ? interest.previous : ""}
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                lastEvaluations: {
                  ...lastEvaluations,
                  interest: {
                    ...interest,
                    previous: e.target.value,
                  },
                },
              })
            }
            options={interestOptions}
          />
        </td>
        <td>
          <MaterialTextField
            id="interestDetails"
            label="Details"
            value={interest.details ? interest.details : ""}
            variant="standard"
            hideLabel
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                lastEvaluations: {
                  ...lastEvaluations,
                  interest: {
                    ...interest,
                    details: e.target.value,
                  },
                },
              })
            }
            multiline
          />
        </td>
      </tr>
    );

    const renderLines = [
      averageField,
      performanceField,
      leadingField,
      interestField,
    ];

    return (
      <tbody>
        {renderLines.map((element, index) => (
          <React.Fragment key={index}>{element}</React.Fragment>
        ))}
      </tbody>
    );
  };

  const displayTableCarreerMap = () => {
    let { mainTrail, secondTrail, thirdTrail, fourthTrail, fifthTrail } =
      carrearPath;

    mainTrail = mainTrail || {};
    secondTrail = secondTrail || {};
    thirdTrail = thirdTrail || {};
    fourthTrail = fourthTrail || {};
    fifthTrail = fifthTrail || {};

    const mainTrailField = (
      <tr>
        <td>{tableCarrearPathLabels.mainTrail}</td>
        <td>
          <MaterialSingleSelect
            variant="standard"
            id="mainTrailReadiness"
            label="Readiness"
            hideLabel
            value={mainTrail.readiness ? mainTrail.readiness : ""}
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                carrearPath: {
                  ...carrearPath,
                  mainTrail: {
                    ...mainTrail,
                    readiness: e.target.value,
                  },
                },
              })
            }
            options={readinessOptions}
          />
        </td>
        <td>
          <MaterialSingleSelect
            variant="standard"
            id="mainTrailPath"
            label="Path"
            hideLabel
            value={mainTrail.path ? mainTrail.path : ""}
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                carrearPath: {
                  ...carrearPath,
                  mainTrail: {
                    ...mainTrail,
                    path: e.target.value,
                  },
                },
              })
            }
            options={carrearPathOptions}
          />
        </td>
        <SimpleExcelCollumn
          onClick={() => getSelectedDetails("mainTrail")}
          value={mainTrail.details ?? "Selecione um cargo"}
        />
      </tr>
    );

    const secondTrailField = (
      <tr>
        <td>{tableCarrearPathLabels.secondTrail}</td>
        <td>
          <MaterialSingleSelect
            variant="standard"
            id="secondTrailReadiness"
            label="Readiness"
            hideLabel
            value={secondTrail.readiness ? secondTrail.readiness : ""}
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                carrearPath: {
                  ...carrearPath,
                  secondTrail: {
                    ...secondTrail,
                    readiness: e.target.value,
                  },
                },
              })
            }
            options={readinessOptions}
          />
        </td>
        <td>
          <MaterialSingleSelect
            variant="standard"
            id="secondTrailPath"
            label="Path"
            hideLabel
            value={secondTrail.path ? secondTrail.path : ""}
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                carrearPath: {
                  ...carrearPath,
                  secondTrail: {
                    ...secondTrail,
                    path: e.target.value,
                  },
                },
              })
            }
            options={carrearPathOptions}
          />
        </td>
        <SimpleExcelCollumn
          onClick={() => getSelectedDetails("secondTrail")}
          value={secondTrail.details ?? "Selecione um cargo"}
        />
      </tr>
    );

    const thirdTrailField = (
      <tr>
        <td>{tableCarrearPathLabels.thirdTrail}</td>
        <td>
          <MaterialSingleSelect
            variant="standard"
            id="thirdTrailReadiness"
            label="Readiness"
            hideLabel
            value={thirdTrail.readiness ? thirdTrail.readiness : ""}
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                carrearPath: {
                  ...carrearPath,
                  thirdTrail: {
                    ...thirdTrail,
                    readiness: e.target.value,
                  },
                },
              })
            }
            options={readinessOptions}
          />
        </td>
        <td>
          <MaterialSingleSelect
            variant="standard"
            id="thirdTrailPath"
            label="Path"
            hideLabel
            value={thirdTrail.path ? thirdTrail.path : ""}
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                carrearPath: {
                  ...carrearPath,
                  thirdTrail: {
                    ...thirdTrail,
                    path: e.target.value,
                  },
                },
              })
            }
            options={carrearPathOptions}
          />
        </td>
        <SimpleExcelCollumn
          onClick={() => getSelectedDetails("thirdTrail")}
          value={thirdTrail.details ?? "Selecione um cargo"}
        />
      </tr>
    );

    const fourthTrailField = (
      <tr>
        <td>{tableCarrearPathLabels.fourthTrail}</td>
        <td>
          <MaterialSingleSelect
            variant="standard"
            id="fourthTrailReadiness"
            label="Readiness"
            hideLabel
            value={fourthTrail.readiness ? fourthTrail.readiness : ""}
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                carrearPath: {
                  ...carrearPath,
                  fourthTrail: {
                    ...fourthTrail,
                    readiness: e.target.value,
                  },
                },
              })
            }
            options={readinessOptions}
          />
        </td>
        <td>
          <MaterialSingleSelect
            variant="standard"
            id="fourthTrailPath"
            label="Path"
            hideLabel
            value={fourthTrail.path ? fourthTrail.path : ""}
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                carrearPath: {
                  ...carrearPath,
                  fourthTrail: {
                    ...fourthTrail,
                    path: e.target.value,
                  },
                },
              })
            }
            options={carrearPathOptions}
          />
        </td>
        <SimpleExcelCollumn
          onClick={() => getSelectedDetails("fourthTrail")}
          value={fourthTrail.details ?? "Selecione um cargo"}
        />
      </tr>
    );

    const fifthTrailField = (
      <tr>
        <td>{tableCarrearPathLabels.fifthTrail}</td>
        <td>
          <MaterialSingleSelect
            variant="standard"
            id="fifthTrailReadiness"
            label="Readiness"
            hideLabel
            value={fifthTrail.readiness ? fifthTrail.readiness : ""}
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                carrearPath: {
                  ...carrearPath,
                  fifthTrail: {
                    ...fifthTrail,
                    readiness: e.target.value,
                  },
                },
              })
            }
            options={readinessOptions}
          />
        </td>
        <td>
          <MaterialSingleSelect
            variant="standard"
            id="fifthTrailPath"
            label="Path"
            hideLabel
            value={fifthTrail.path ? fifthTrail.path : ""}
            onChange={(e) =>
              setEvaluationTarget({
                ...evaluationTarget,
                carrearPath: {
                  ...carrearPath,
                  fifthTrail: {
                    ...fifthTrail,
                    path: e.target.value,
                  },
                },
              })
            }
            options={carrearPathOptions}
          />
        </td>
        <SimpleExcelCollumn
          onClick={() => getSelectedDetails("fifthTrail")}
          value={fifthTrail.details ?? "Selecione um cargo"}
        />
      </tr>
    );

    const renderLines = [
      mainTrailField,
      secondTrailField,
      thirdTrailField,
      fourthTrailField,
      fifthTrailField,
    ];

    return (
      <tbody>
        {renderLines.map((element, index) => (
          <React.Fragment key={index}>{element}</React.Fragment>
        ))}
      </tbody>
    );
  };

  return (
    <>
      <SelectItemModal
        open={openModal}
        title="Selecione o cargo"
        itemsList={positionsOptions}
        initialSelect={[]}
        onConfirm={handleChangeDetails}
        onCancel={() => setOpenModal(false)}
        singleSelect
      />
      <div className="box box-primary">
        <div className="box-header with-border">
          <h3 className="box-title">
            <i className="far fa-dot-circle" />
            {` ${intl.formatMessage(messages.evaluation_targeting)}`}
          </h3>
          {saveButton && (
            <div className="pull-right">
              <button className="btn btn-purple" onClick={(e) => onSave(e)}>
                {intl.formatMessage(messages.globalSave)}
              </button>
            </div>
          )}
        </div>
        <div className="box-body">
          <div className="col-xs-12">
            <h5 style={{ marginBottom: "-10px" }}>
              {intl.formatMessage(messages.table_last_evaluations)}
            </h5>
            <table className="simple-table">
              <thead>
                <tr>
                  <td align="left">
                    {intl.formatMessage(messages.table_evaluation)}
                  </td>
                  <td>{intl.formatMessage(messages.table_current)}</td>
                  <td>{intl.formatMessage(messages.table_previous)}</td>
                  <td align="left">
                    {intl.formatMessage(messages.table_details)}
                  </td>
                </tr>
              </thead>
              {lastEvaluations && displayTableEvaluations()}
            </table>
          </div>
          <div className="col-xs-12">
            <h5 style={{ marginBottom: "-10px" }}>
              {intl.formatMessage(messages.table_career_map)}
            </h5>
            <table className="simple-table">
              <thead>
                <tr>
                  <td align="left">
                    {intl.formatMessage(messages.table_career)}
                  </td>
                  <td>{intl.formatMessage(messages.table_readiness)}</td>
                  <td>{intl.formatMessage(messages.table_path)}</td>
                  <td align="left">Cargos</td>
                </tr>
              </thead>
              {carrearPath && displayTableCarreerMap()}
            </table>
          </div>
          <div style={{ clear: "both" }}>
            <div className="col-xs-3">
              <MaterialSingleSelect
                id="salaryEvaluation"
                label={intl.formatMessage(messages.salary_evaluation)}
                value={salaryEvaluation.value ? salaryEvaluation.value : ""}
                onChange={(e) =>
                  setEvaluationTarget({
                    ...evaluationTarget,
                    salaryEvaluation: {
                      ...salaryEvaluation,
                      value: e.target.value,
                    },
                  })
                }
                options={salaryEvaluationOptions}
              />
            </div>
            <div className="col-xs-9">
              <MaterialTextField
                id="salaryEvaluationDetails"
                label={intl.formatMessage(messages.table_details)}
                multiline
                value={salaryEvaluation.details ? salaryEvaluation.details : ""}
                onChange={(e) =>
                  setEvaluationTarget({
                    ...evaluationTarget,
                    salaryEvaluation: {
                      ...salaryEvaluation,
                      details: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
          <div style={{ clear: "both" }}>
            <div className="col-xs-3">
              <MaterialSingleSelect
                id="roleEvaluation"
                label={intl.formatMessage(messages.role_evaluation)}
                value={roleEvaluation.value ? roleEvaluation.value : ""}
                onChange={(e) =>
                  setEvaluationTarget({
                    ...evaluationTarget,
                    roleEvaluation: {
                      ...roleEvaluation,
                      value: e.target.value,
                    },
                  })
                }
                options={roleEvaluationOptions}
              />
            </div>
            <div className="col-xs-9">
              <MaterialTextField
                id="roleEvaluationDetails"
                label={intl.formatMessage(messages.table_details)}
                multiline
                value={roleEvaluation.details ? roleEvaluation.details : ""}
                onChange={(e) =>
                  setEvaluationTarget({
                    ...evaluationTarget,
                    roleEvaluation: {
                      ...roleEvaluation,
                      details: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
          <div
            align="right"
            style={{ padding: "15px 0px 10px 0px", clear: "both" }}
          >
            {saveButton && (
              <div className="pull-right">
                <button className="btn btn-purple" onClick={(e) => onSave(e)}>
                  {intl.formatMessage(messages.globalSave)}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

EvaluationTargetField.propTypes = {
  saveButton: PropTypes.bool,
  onSave: PropTypes.func,
};

export default injectIntl(EvaluationTargetField);
