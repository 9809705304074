import { ADD_INSIGHT_COMMENT, EDIT_INSIGHT_COMMENT } from "../../../../types";
import { selectedInsightVerification } from "./_validations";

export const postAddComment = ({ details = {}, content = {} }) => {
  const { insightId = "" } = details;

  if (!selectedInsightVerification(insightId)) return;

  return dispatch => {
    dispatch({
      type: ADD_INSIGHT_COMMENT,
      payload: { ...details, ...content }
    });
  };
};

export const putEditComment = ({ details = {}, content = {} }) => {
  const { insightId = "" } = details;

  if (!selectedInsightVerification(insightId)) return;

  return dispatch => {
    dispatch({
      type: EDIT_INSIGHT_COMMENT,
      payload: { ...details, ...content }
    });
  };
};
