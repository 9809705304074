import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { injectIntl, defineMessages } from "react-intl";
import NumberFormat from "react-number-format";
import _ from "lodash";
import MaterialTextField from "../../../Common/MaterialTextField";
import MaterialSingleSelect from "../../../Common/MaterialSingleSelect";
import DetailsTableEvaluation from "./DetailsTableEvaluation";

import {
  getAvailableSchedule,
  getLabelByType,
  getSelectedPlan,
  getPlanTotalValues,
  getFilterByOptions,
  buildDefaultYearsEvaluations,
  getTotalValues,
} from "../../../../utils/projectEvaluation";
import { getObjectDifference } from "../../../../utils/ArrayUtils";

const messages = defineMessages({
  reason: {
    id: "tool.project.reason",
  },
  year: {
    id: "global.year",
  },
});

const ProjectTable = ({
  investment,
  setInvestment,
  effort,
  setEffort,
  schedule,
  intl,
  saveButtonJSX,
  saveButton,
  getInitialYear,
}) => {
  const [render, setRender] = useState({
    typeToDisplay: null,
    typeFirstMount: true,
  });
  const [datesInfo, setDatesInfo] = useState({
    yearsOptions: null,
  });
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedFilterBy, setSelectedFilterBy] = useState({
    filter: null,
  });
  const [firstMount, setFirstMount] = useState(true);

  const labels = getLabelByType("Investment", intl);
  const reasonTitle = intl.formatMessage(messages.reason);

  const buildReplanningsByYears = (availableYears = [], replannings = []) => {
    return replannings.filter(
      (plan) => availableYears.indexOf(Number(plan.year)) !== -1,
    );
  };

  const displayBalanceTableBody = () => {
    let replannings = {};
    const displayType = render.typeToDisplay ? render.typeToDisplay : null;
    let isInvestment = false;

    if (displayType && displayType === "Efforts") {
      replannings = effort && effort.replannings ? effort.replannings : [];
    } else if (displayType && displayType === "Investment") {
      replannings =
        investment && investment.replannings ? investment.replannings : [];
      isInvestment = true;
    }

    replannings.sort((a, b) => {
      const a_attr = a.year;
      const b_attr = b.year;
      const secondResult = b_attr > a_attr ? -1 : 0;

      return a_attr > b_attr ? 1 : secondResult;
    });

    replannings.sort((a, b) => {
      const a_attr = a.year;
      const b_attr = b.year;
      const secondResult = b_attr > a_attr ? -1 : 0;

      return a_attr > b_attr ? 1 : secondResult;
    });

    return replannings.map((plan, index) => {
      const totalValues = getPlanTotalValues(plan);

      return (
        <tr key={index}>
          <td align="center" className="yearCell">
            {plan && plan.year && plan.year}
          </td>
          <td align="center" className="forecastCell">
            {totalValues && totalValues.totalBudget && isInvestment
              ? totalValues.totalBudget
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
              : totalValues.totalBudget}
          </td>
          <td align="center">{plan.reason}</td>
        </tr>
      );
    });
  };

  const buildDefaultPlannings = () => {
    const { initialDate = "", finalDate = "" } = schedule;

    let availableSchedule = {
      availableYears: [],
      yearsOptions: [],
      monthsDiff: 0,
      initialMonth: 0,
    };

    if (finalDate && finalDate !== null)
      availableSchedule = getAvailableSchedule(schedule);

    const { availableYears = [], yearsOptions = [] } = availableSchedule || {};

    setDatesInfo({
      ...schedule,
      yearsOptions,
    });

    const { plannings = [], replannings = [] } = investment || {};

    const period = {
      start: initialDate,
      end: finalDate,
    };

    const buildEvaluations = buildDefaultYearsEvaluations(
      availableYears,
      plannings,
      period,
      "Investment",
    );

    const buildReplannings = buildReplanningsByYears(
      availableYears,
      replannings,
    );

    setInvestment({
      ...investment,
      plannings: buildEvaluations,
      replannings: buildReplannings,
      ignoreDiff: replannings?.length !== buildReplannings?.length,
    });
  };

  useEffect(() => {
    if (
      schedule?.deliveryDateSet &&
      schedule?.initialDate &&
      (schedule?.didUpdate || !schedule?.firstDateSet)
    ) {
      buildDefaultPlannings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule]);

  const getCustomReplanningsScrollStyle = (type) => {
    if (type === "Efforts") {
      return "effortFixedScroll";
    }
    return "deliveryDateFixedScroll";
  };

  const renderHistoryTable = () => {
    return (
      <>
        <table className="simple-table" style={{ marginBottom: "0px" }}>
          <thead>
            <tr>
              <td className="yearCell">{intl.formatMessage(messages.year)}</td>
              <td className="forecastCell">{labels.newFirstBox}</td>
              <td className="reasonCell">{reasonTitle}</td>
            </tr>
          </thead>
        </table>

        <div
          className={`fixedScroll ${getCustomReplanningsScrollStyle(
            "Investment",
          )}`}
        >
          <table className="simple-table" style={{ marginTop: "0px" }}>
            <tbody>{displayBalanceTableBody()}</tbody>
          </table>
        </div>
      </>
    );
  };

  function currencyFormatter(value) {
    const newValue = parseInt(value, 10);

    if (!Number(newValue)) return "";

    const amount = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 0,
    }).format(newValue);

    return `${amount}`;
  }

  const displayDetails = (displayType) => {
    const currentSelectedYear =
      selectedYear || getInitialYear().defaultAvailableYear;

    const selectedPlan = getSelectedPlan(
      investment.plannings,
      currentSelectedYear,
    );

    const renderType = render.typeToDisplay ? render.typeToDisplay : null;

    if ((displayType === "year" && selectedPlan) || displayType === "total") {
      return (
        <Row>
          <DetailsTableEvaluation
            selectedPlan={selectedPlan}
            investment={investment}
            setInvestment={setInvestment}
            effort={effort}
            setEffort={setEffort}
            type={displayType}
            renderType={renderType}
            intl={intl}
          />
        </Row>
      );
    }

    return null;
  };

  const renderFilters = () => {
    if (selectedFilterBy.filter) {
      if (selectedFilterBy.filter === "total") {
        return displayDetails("total");
      }
      if (selectedFilterBy.filter === "year") {
        if (selectedYear || getInitialYear().defaultYearOptions) {
          return displayDetails("year");
        }
      } else if (selectedFilterBy.filter === "replannings") {
        return <Col md={12}>{renderHistoryTable()}</Col>;
      }
    }

    return displayDetails("year");
  };

  const displayTotalEvaluations = () => {
    let currentSelectedYear =
      selectedYear ||
      (firstMount || selectedFilterBy.filter === "total"
        ? getInitialYear().defaultAvailableYear
        : null);

    if (currentSelectedYear) {
      if (selectedFilterBy.filter === "total") {
        currentSelectedYear = null;
      }
      const summary = getTotalValues(investment.plannings, currentSelectedYear);

      const totalBudget =
        summary && summary.totalBudget ? summary.totalBudget : null;
      const totalSpent =
        summary && summary.totalSpent ? summary.totalSpent : null;
      const totalBalance =
        totalBudget && totalSpent
          ? totalBudget - totalSpent
          : !totalSpent && totalBudget;

      return (
        <Row>
          <Col md={4} align="center">
            <NumberFormat
              format={currencyFormatter}
              align="center"
              customInput={MaterialTextField}
              id="firstBox"
              label={labels.firstBox}
              value={totalBudget || ""}
              variant="standard"
              disabled
            />
          </Col>
          <Col md={4} align="center">
            <NumberFormat
              format={currencyFormatter}
              thousandSeparator
              align="center"
              customInput={MaterialTextField}
              id="secondBox"
              label={labels.secondBox}
              value={totalSpent || ""}
              variant="standard"
              disabled
            />
          </Col>
          <Col md={4} align="center">
            <NumberFormat
              format={currencyFormatter}
              thousandSeparator
              align="center"
              customInput={MaterialTextField}
              id="thirdBox"
              label={labels.thirdBox}
              value={totalBalance || ""}
              variant="standard"
              disabled
            />
          </Col>
        </Row>
      );
    }

    return null;
  };

  const handleChangeFilter = (e) => {
    const value = e && e.target && e.target.value ? e.target.value : "year";

    setFirstMount(false);
    setSelectedFilterBy({
      filter: value,
    });
  };

  const displaySelectYear = () => {
    if (
      selectedFilterBy.filter !== "total" &&
      selectedFilterBy.filter !== "replannings"
    ) {
      return (
        <Col md={4} align="center">
          <MaterialSingleSelect
            id="fourthBox"
            label={labels.fourthBox}
            value={
              selectedYear ||
              (firstMount ? getInitialYear().defaultAvailableYear : "")
            }
            options={
              datesInfo && datesInfo.yearsOptions
                ? datesInfo.yearsOptions
                : getInitialYear().defaultYearOptions
            }
            variant="standard"
            onChange={(e) => setSelectedYear(e.target.value)}
          />
        </Col>
      );
    }

    return null;
  };

  const displayAlternateEvaluations = () => {
    if (render.typeFirstMount) {
      setRender({
        ...render,
        typeToDisplay: "Investment",
        typeFirstMount: false,
      });
    }
    // retorna filter by por ano e month
    const filterByOptions = schedule.deliveryDateSet
      ? getFilterByOptions(intl)
      : [{ value: "", label: "" }];
    // retorna o total dos campos Planned, Real e Forecast

    return (
      <div>
        <Row className="topBox">
          <Col md={4} align="center">
            <MaterialSingleSelect
              id="fourthBox"
              label={labels.seventhBox}
              value={selectedFilterBy.filter ? selectedFilterBy.filter : ""}
              // fourthboxyear
              options={filterByOptions}
              variant="standard"
              onChange={(e) => handleChangeFilter(e)}
              disabled={!schedule.deliveryDateSet}
            />
          </Col>

          {schedule.deliveryDateSet && displaySelectYear()}
        </Row>
        {schedule.deliveryDateSet &&
          selectedFilterBy.filter !== "replannings" &&
          displayTotalEvaluations()}
        {schedule.deliveryDateSet && renderFilters()}
      </div>
    );
  };

  return (
    <div className="box box-primary">
      <div id="header-insight-evaluation" className="box-header with-border">
        <i className={labels.iconClass} />
        <h3 className="box-title"> {labels.titleLabel}</h3>
        {saveButton && saveButtonJSX()}
        <br />
      </div>
      <div className="box-body simpleTableEffort">
        {displayAlternateEvaluations()}
      </div>
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  const investmentDiff = getObjectDifference(
    prevProps.investment,
    nextProps.investment,
  );
  const effortDiff = getObjectDifference(prevProps.effort, nextProps.effort);
  const scheduleDiff = getObjectDifference(
    prevProps.schedule,
    nextProps.schedule,
  );
  if (Object.keys(investmentDiff)?.length > 0) return false;
  if (Object.keys(effortDiff)?.length > 0) return false;
  if (Object.keys(scheduleDiff)?.length > 0) return false;
  if (!_.isEqual(prevProps.saveButton, nextProps.saveButton)) return false;
  if (!_.isEqual(prevProps.saveButtonJSX, nextProps.saveButtonJSX))
    return false;
  return true;
}

export default React.memo(injectIntl(ProjectTable), areEqual);
