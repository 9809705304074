import React from "react";

import { defineMessages } from "react-intl";
import { translatedText } from "../translationUtils";

import PestalAnalysisAmbientDialog from "../../components/Common/CpAnalysis/forms/Pestal/NewAnalysisAmbientDialog";
import SwotAnalysisAmbientDialog from "../../components/Common/CpAnalysis/forms/Swot/NewAnalysisAmbientDialog";

import PrioriAnalysisAmbientDialog from "../../components/Common/CpAnalysis/forms/Prioritization/NewAnalysisAmbientDialog";
import PrioriAnalysisAreaDialog from "../../components/Common/CpAnalysis/forms/Prioritization/NewAnalysisAreaDialog";
import ConcAnalysisAmbientDialog from "../../components/Common/CpAnalysis/forms/Concurrence/NewAnalysisAmbientDialog";
import ConcAnalysisAreaDialog from "../../components/Common/CpAnalysis/forms/Concurrence/NewAnalysisAreaDialog";
import {
  COMPETITIVE_ANALYSIS,
  ACTION_PLAN_ID,
} from "../../constants/tools.constants";

const messages = defineMessages({
  cpAnalysis_pestal_political: {
    id: "cpAnalysis_pestal_political",
  },
  cpAnalysis_pestal_economical: {
    id: "cpAnalysis_pestal_economical",
  },
  cpAnalysis_pestal_social: {
    id: "cpAnalysis_pestal_social",
  },
  cpAnalysis_pestal_technological: {
    id: "cpAnalysis_pestal_technological",
  },
  cpAnalysis_pestal_ambiental: {
    id: "cpAnalysis_pestal_ambiental",
  },
  cpAnalysis_pestal_legal: {
    id: "cpAnalysis_pestal_legal",
  },
  cpAnalysis_swot_strong_points: {
    id: "tool.swot.strengths",
  },
  cpAnalysis_swot_weak_points: {
    id: "tool.swot.weakness",
  },
  cpAnalysis_swot_opportunities: {
    id: "tool.swot.opportunities",
  },
  cpAnalysis_swot_threats: {
    id: "tool.swot.threats",
  },
  cpAnalysis_prioritization: {
    id: "cpAnalysis_prioritization",
  },
  cpAnalysis_concurrence: {
    id: "cpAnalysis_concurrence",
  },
  impact: {
    id: "global.impact",
  },
  importance: {
    id: "global.importance",
  },
  reliability: {
    id: "global.reliability",
  },
  gravity: {
    id: "gravity",
  },
  urgency: {
    id: "urgency",
  },
  trend: {
    id: "trend",
  },
  price: {
    id: "price",
  },
  services: {
    id: "services",
  },
  coverage: {
    id: "coverage",
  },
  technology: {
    id: "technology",
  },
  investment: {
    id: "tool.project.investment",
  },
  very_low: {
    id: "evaluation.veryLow",
  },
  low: {
    id: "evaluation.low",
  },
  medium: {
    id: "global.reliability.MEDIUM",
  },
  high: {
    id: "evaluation.high",
  },
  very_high: {
    id: "evaluation.veryHigh",
  },
  no_competitive: {
    id: "no_competitive",
  },
  low_competitiveness: {
    id: "low_competitiveness",
  },
  medium_competitiveness: { id: "medium_competitiveness" },
  competitive: { id: "competitive" },
  differentiated_and_competitive: { id: "differentiated_and_competitive" },
  leader: { id: "global.leader" },
  points: { id: "global.points" },
});

const translation = (id, values) => translatedText(id, messages, values);

export const handleEmptyConfiguration = (emptyConfiguration) => {
  if (!emptyConfiguration) return null;

  return (
    <div className="cpAnalysisRequires">
      <strong>
        Configure os critérios de Avaliação e Escalas na aba Administração
      </strong>
    </div>
  );
};

export const handleEmptyGroupConfiguration = (emptyGroupConfiguration) => {
  if (!emptyGroupConfiguration) return null;

  return (
    <div className="cpAnalysisRequires">
      <strong>Configure os Grupos de Análise na aba Administração</strong>
    </div>
  );
};

export const getCompetitiveAnalysisTranslations = () => {
  return {
    pestal: "Pestal",
    swot: "SWOT",
    prioritization: translation("cpAnalysis_prioritization"),
    concurrence: translation("cpAnalysis_concurrence"),
  };
};

export const getConcurrenceScalesTranslations = () => {
  return {
    no_competitive: translation("no_competitive"),
    low_competitiveness: translation("low_competitiveness"),
    medium_competitiveness: translation("medium_competitiveness"),
    competitive: translation("competitive"),
    differentiated_and_competitive: translation(
      "differentiated_and_competitive",
    ),
    leader: translation("leader"),
  };
};
export const getSwotPatternScalesTranslations = () => {
  return {
    very_low: translation("very_low"),
    low: translation("low"),
    medium: translation("medium"),
    high: translation("high"),
    very_high: translation("very_high"),
  };
};

export const getPatternCriteriasTranslations = () => {
  return {
    impact: translation("impact"),
    importance: translation("importance"),
    reliability: translation("reliability"),
  };
};

export const getGutCriteriasTranslations = () => {
  return {
    gravity: translation("gravity"),
    urgency: translation("urgency"),
    trend: translation("trend"),
  };
};

export const getConcurrenceCriteriasTranslations = () => {
  return {
    price: translation("price"),
    services: translation("services"),
    coverage: translation("coverage"),
    technology: translation("technology"),
    investment: translation("investment"),
  };
};

export const getPestalFactorsTranslations = () => {
  return {
    political: translation("cpAnalysis_pestal_political"),
    economical: translation("cpAnalysis_pestal_economical"),
    social: translation("cpAnalysis_pestal_social"),
    technological: translation("cpAnalysis_pestal_technological"),
    ambiental: translation("cpAnalysis_pestal_ambiental"),
    legal: translation("cpAnalysis_pestal_legal"),
  };
};

export const getSwotFactorsTranslations = () => {
  return {
    strong_points: translation("cpAnalysis_swot_strong_points"),
    weak_points: translation("cpAnalysis_swot_weak_points"),
    opportunities: translation("cpAnalysis_swot_opportunities"),
    threats: translation("cpAnalysis_swot_threats"),
  };
};

export const getPestalPatternScalesTranslations = () => {
  return { ...getSwotPatternScalesTranslations() };
};

export const getPrioritizationPatternScalesTranslations = () => {
  return { ...getSwotPatternScalesTranslations() };
};

export const getAllCriteriaTranslation = (slug = "") => {
  const translated = {
    ...getConcurrenceCriteriasTranslations(),
    ...getGutCriteriasTranslations(),
    ...getPatternCriteriasTranslations(),
  };

  if (!slug) return "";

  return translated[slug.toLowerCase()] || slug;
};
export const getPestalTranslations = () => {
  return {
    ...getPestalFactorsTranslations(),
    ...getPatternCriteriasTranslations(),
  };
};

export const getSwotTranslations = () => {
  return {
    ...getSwotFactorsTranslations(),
    ...getPatternCriteriasTranslations(),
  };
};

export const getPestalScaleTranslation = (slug = "") => {
  const translated = { ...getPestalPatternScalesTranslations() };

  return translated[slug.toLowerCase()] || slug;
};

export const getConcurrenceScaleTranslation = (slug = "") => {
  const translated = { ...getConcurrenceScalesTranslations() };

  return translated[slug.toLowerCase()] || slug;
};

export const getSwotScaleTranslation = (slug = "") => {
  const translated = { ...getSwotPatternScalesTranslations() };

  return translated[slug.toLowerCase()] || slug;
};

export const getPrioritizationScaleTranslation = (slug = "") => {
  const translated = { ...getPrioritizationPatternScalesTranslations() };

  return translated[slug.toLowerCase()] || slug;
};

export const getPestalCriteriaTranslation = (slug = "") => {
  const translated = { ...getPatternCriteriasTranslations() };

  if (!slug) return "";

  return translated[slug.toLowerCase()] || slug;
};

export const getSwotCriteriaTranslation = (slug = "") => {
  const translated = { ...getPatternCriteriasTranslations() };

  if (!slug) return "";

  return translated[slug.toLowerCase()] || slug;
};

export const getPrioritizationCriteriaTranslation = (slug = "") => {
  const translated = { ...getGutCriteriasTranslations() };

  if (!slug) return "";

  return translated[slug.toLowerCase()] || slug;
};

export const getConcurrenceCriteriaTranslation = (slug = "") => {
  const translated = { ...getConcurrenceCriteriasTranslations() };

  if (!slug) return "";

  return translated[slug.toLowerCase()] || slug;
};

export const getPestalFactorTranslation = (slug = "") => {
  const translated = { ...getPestalFactorsTranslations() };

  return translated[slug.toLowerCase()] || slug;
};

export const getSwotFactorTranslation = (slug = "") => {
  const translated = { ...getSwotFactorsTranslations() };

  return translated[slug.toLowerCase()] || slug;
};

export const getCustomTranslation = (translationObj = {}, slug = "") => {
  return translationObj[slug.toLowerCase()] || slug;
};

export const renderCpAnalysisLabels = () => {
  return (
    <>
      <th style={{ textAlign: "left" }}>{translation("points")}</th>
    </>
  );
};

export const customCenaryName = (scenerieName = "", toolId = "") => {
  if (toolId !== COMPETITIVE_ANALYSIS && toolId !== ACTION_PLAN_ID)
    return scenerieName;

  const cpAnalysisTranslations = getCompetitiveAnalysisTranslations();

  return getCustomTranslation(
    cpAnalysisTranslations,
    scenerieName.toLowerCase(),
  );
};

export const justTranslateCenaryName = (scenerieName = "") => {
  const cpAnalysisTranslations = getCompetitiveAnalysisTranslations();

  return getCustomTranslation(
    cpAnalysisTranslations,
    scenerieName.toLowerCase(),
  );
};

export const justTranslateQuestionName = (type = "") => {
  const allTranslations = {
    ...getPestalFactorsTranslations(),
    ...getSwotFactorsTranslations(),
  };

  return getCustomTranslation(allTranslations, type);
};

export const translateQuestionName = (type = "", toolId = "") => {
  if (toolId !== COMPETITIVE_ANALYSIS && toolId !== ACTION_PLAN_ID) return type;

  const allTranslations = {
    ...getPestalFactorsTranslations(),
    ...getSwotFactorsTranslations(),
  };

  return getCustomTranslation(allTranslations, type);
};

export const getCustomQuestionNames = (questions = [], toolId = "") => {
  if (toolId !== COMPETITIVE_ANALYSIS) return questions;

  return questions.map((question) => {
    const { text = "" } = question;

    const allTranslations = {
      ...getPestalFactorsTranslations(),
      ...getSwotFactorsTranslations(),
    };
    const translated = getCustomTranslation(allTranslations, text);

    if (translated) return { ...question, text: translated };

    return { ...question, text };
  });
};

export const getAnalysisToolDialogs = (type = "") => {
  const types = {
    pestal: { NewAnalysisAmbientDialog: PestalAnalysisAmbientDialog },
    swot: { NewAnalysisAmbientDialog: SwotAnalysisAmbientDialog },
    prioritization: {
      NewAnalysisAmbientDialog: PrioriAnalysisAmbientDialog,
      NewAnalysisAreaDialog: PrioriAnalysisAreaDialog,
    },
    concurrence: {
      NewAnalysisAmbientDialog: ConcAnalysisAmbientDialog,
      NewAnalysisAreaDialog: ConcAnalysisAreaDialog,
    },
  };

  return types[type] || {};
};

const getCpAnalysisFieldLegends = () => {
  return {
    editable_no_max_length: "Campo editável, Sem tamanho máximo",
    not_editable: "Não editável",
    editable_with_max_length: "Campo editável, Com tamanho máximo",
    editable_with_pre_length: "Campo editável, Tamanho predefinido",
  };
};

export const getPestalFieldDescription = () => {
  return { ...getCpAnalysisFieldLegends() };
};

export const getSwotFieldDescription = () => {
  return { ...getCpAnalysisFieldLegends() };
};

export const getPrioritizationFieldDescription = () => {
  return { ...getCpAnalysisFieldLegends() };
};

export const getConcurrenceFieldDescription = () => {
  return { ...getCpAnalysisFieldLegends() };
};

export const showFieldCaption = (
  open = true,
  length = 0,
  finiteField = true,
) => {
  const openConfig = {
    true: "fas fa-lock-open",
    false: "fas fa-lock",
  };

  const maxConfig = {
    true: `Max. ${length}`,
    false: length,
  };

  return (
    <>
      <i className={openConfig[open]} />
      <strong> {maxConfig[finiteField]} </strong>
    </>
  );
};
