import {
  checkPostAdministrationSelectedTool,
  toolUpdateNotifier,
} from "./_validations";

import {
  postScenery,
  postTheme,
  postQuestion,
  postInsight,
} from "../board/_actions";

import {
  checkScenerySelectedTool,
  checkThemeSelectedTool,
  checkQuestionSelectedTool,
  checkInsightSelectedTool,
} from "../board/_validations";

import { checkToolIdInState } from "../../../utils/entity_validations";
import { fetchToolGoalBonusConfiguration } from "../../../../goalsAndBonusActions";

const DEBUG = false;
function debug(arg1, ...optional) {
  if (DEBUG) console.log("WebSockets: ", arg1, ...optional);
}

export const putGoalsBonusConfiguration = (newAdministrationConfig = {}) => {
  const { content: administrationData, details = {} } = newAdministrationConfig;
  let { selectedToolId = null } = details;
  selectedToolId = selectedToolId ? Number(selectedToolId) : null;

  debug({ administrationData, details, selectedToolId });

  if (
    administrationData &&
    selectedToolId &&
    checkPostAdministrationSelectedTool(selectedToolId)
  ) {
    return async (dispatch) => {
      if (checkToolIdInState({ id: selectedToolId })) {
        dispatch(fetchToolGoalBonusConfiguration(selectedToolId));
        dispatch(toolUpdateNotifier());
      }
    };
  }

  return null;
};

export const postBoardCenaryLink = (cenaryLinkData) => {
  const { content: updatedCenary = {}, details = {} } = cenaryLinkData;

  if (updatedCenary && updatedCenary.id) {
    return (dispatch) => {
      if (checkScenerySelectedTool(updatedCenary))
        dispatch(toolUpdateNotifier());

      dispatch(postScenery(updatedCenary, details.userId));
    };
  }

  return null;
};

export const postBoardThemeLink = (themeLinkData) => {
  const { content: updatedTheme = {}, details = {} } = themeLinkData;

  if (updatedTheme && updatedTheme.id) {
    return (dispatch) => {
      if (checkThemeSelectedTool(updatedTheme)) dispatch(toolUpdateNotifier());

      dispatch(postTheme(updatedTheme, details.userId));
    };
  }

  return null;
};

export const postBoardQuestionLink = (questionLinkData) => {
  const { content: updatedQuestion = {}, details = {} } = questionLinkData;

  if (updatedQuestion && updatedQuestion.id) {
    return (dispatch) => {
      if (checkQuestionSelectedTool(updatedQuestion))
        dispatch(toolUpdateNotifier());

      dispatch(postQuestion(updatedQuestion, details.userId));
    };
  }

  return null;
};

export const postBoardInsightLink = (insightLinkData) => {
  const { content: updatedInsight = {}, details = {} } = insightLinkData;

  if (updatedInsight && updatedInsight.id) {
    return (dispatch) => {
      if (checkInsightSelectedTool(updatedInsight))
        dispatch(toolUpdateNotifier());

      if (
        updatedInsight.goalsBonusProgramGoal &&
        updatedInsight.goalsBonusProgramGoal.id
      ) {
        dispatch(
          postInsight(
            {
              ...updatedInsight,
              goalsBonusProgramGoalId: updatedInsight.goalsBonusProgramGoal.id,
            },
            details.userId,
          ),
        );
      }
    };
  }

  return null;
};
