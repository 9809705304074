import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { defineMessages } from "react-intl";
import moment from "moment";
import { Draggable } from "react-beautiful-dnd";
import Avatar from "@material-ui/core/Avatar";
import { getValidFunc } from "../../../../utils/validations";
import {
  getObjectInfoById,
  orderArrayByObjAttr,
} from "../../../../utils/ArrayUtils";
import {
  getActionStatusOptions,
  getEvaluationStatusOptions,
} from "../../../../constants/actionsPlan";
import actionsPlanUtils from "../../../../utils/actionsPlan";
import { useWindowSize } from "../../../../utils/customHooks";

import EditColumn from "../../Tables/EditColumn";
import DeleteColumn from "../../Tables/DeleteColumn";
import { customHideActions } from "../../../../utils/accessLevels";
import { translatedText } from "../../../../utils/translationUtils";
import { getAvatar } from "../../../../actions/profileActions";

const { getActionPerformance } = actionsPlanUtils;

const messages = defineMessages({
  acp_table_text_start: {
    id: "acp_table_text_start",
  },
  acp_table_text_end: {
    id: "acp_table_text_end",
  },
  acp_table_text_new_date: {
    id: "acp_table_text_new_date",
  },
  acp_table_text_conclusion: {
    id: "acp_table_text_conclusion",
  },
  acp_table_text_responsible: {
    id: "acp_table_text_responsible",
  },
  acp_table_text_monitoring: {
    id: "acp_table_text_monitoring",
  },
  acp_text_show_details: {
    id: "acp_text_show_details",
  },
  acp_text_hide_details: {
    id: "acp_text_hide_details",
  },
});

const translation = (id) => translatedText(id, messages);

const getItemStyle = (isDragging, draggableStyle) => {
  const itemStyle = draggableStyle;
  delete itemStyle.position;

  return {
    userSelect: "none",
    background: isDragging ? "#eeeeee" : "white",
    ...itemStyle,
  };
};

const LineDisplay = (props) => {
  const [showDetails, setShowDetails] = useState(false);
  const [responsibleAvatar, setResponsibleAvatar] = useState("");

  const windowSize = useWindowSize();
  const screenWidth = windowSize[0];

  const {
    actionInfo = {},
    insightInfo = {},
    draggableSnapshot = {},
    draggableProvided = {},
    actionIndex = 0,
    hideIndex,
    showInsight,
    showEmptyCols,
    dragabble,
    handleEditAction,
    handleDeleteAction,
    selectAndOpenInsight,
    getAvatar,
    avatars,
  } = props;

  const actionResponsible = actionInfo.responsible || {};
  const { userData } = actionResponsible;

  useEffect(() => {
    async function getUserAvatar() {
      if (userData?.avatarBlobId) {
        await getAvatar(userData.avatarBlobId);

        avatars.forEach((avatar) => {
          if (avatar.blobID === userData.avatarBlobId) {
            setResponsibleAvatar(`data:image/png;base64,${avatar.base64}`);
          }
        });
      }
    }

    if (showDetails && !responsibleAvatar) getUserAvatar();
  }, [showDetails, userData, responsibleAvatar, avatars, getAvatar]);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const formatDate = (date) => {
    if (date && moment(date).isValid()) return moment(date).format("L");

    return "-";
  };

  const actionStatusOptions = getActionStatusOptions();
  const evaluationStatusOptions = getEvaluationStatusOptions();

  const borderStyle = showDetails
    ? {
        borderLeft: "4px solid #6b42a9",
      }
    : {};
  const secondaryBorderStyle = showInsight ? borderStyle : {};
  const borderStyleChild = showDetails
    ? {
        borderLeft: "2px solid #6b42a9",
      }
    : {};

  const tdOnClick = {
    onClick: () => toggleDetails(actionInfo.id),
  };

  const actionPerformance = getActionPerformance(actionInfo);
  const isActionCanceled = actionPerformance.status === "CANCELED";

  const canceledStyles = {
    backgroundColor: isActionCanceled ? "#f9f9f9" : "#fff",
  };

  const { isDragging } = draggableSnapshot;
  const {
    innerRef = null,
    draggableProps = {},
    dragHandleProps,
  } = draggableProvided;
  const dragabbleStyle = draggableProps.style || {};

  const maxTitleWidth = screenWidth < 1400 ? "291px" : "461px";
  const maxTitleWidthDiv = screenWidth < 1400 ? "275px" : "436px";

  const oveflowTextStyles = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: maxTitleWidthDiv,
  };

  const actionDates = actionInfo.dates || {};

  const hideManagementActions =
    props.hideActions === true ? true : customHideActions(actionInfo);

  const defaultEmptyColumn = showEmptyCols ? (
    <td
      style={{ width: "40px", textAlign: "center", cursor: "pointer" }}
      className="action-column"
    />
  ) : null;

  const secondaryHeadLabels = [
    { label: translation("acp_table_text_start").toUpperCase(), colSpan: 1 },
    { label: translation("acp_table_text_end").toUpperCase(), colSpan: 1 },
    { label: translation("acp_table_text_new_date").toUpperCase(), colSpan: 1 },
    {
      label: translation("acp_table_text_conclusion").toUpperCase(),
      colSpan: 1,
    },
    {
      label: translation("acp_table_text_responsible").toUpperCase(),
      colSpan: 1,
      align: "left",
    },
    {
      label: translation("acp_table_text_monitoring").toUpperCase(),
      colSpan: 1,
    },
  ];

  const getUserInitials = (fullName) => {
    if (fullName) {
      const splitedName = fullName.split(" ");
      return splitedName[0][0];
    }

    return "";
  };

  return (
    <>
      <tr
        ref={innerRef}
        {...draggableProps}
        {...dragHandleProps}
        style={{
          ...getItemStyle(isDragging, dragabbleStyle, dragabble),
          backgroundColor: showDetails ? "#fcfcfc" : "auto",
          cursor: "cursor",
        }}
        className={showDetails ? "darker-border-top darker-border-bottom" : ""}
      >
        {!hideIndex && (
          <td
            className="titled hover-titled"
            title={translation("acp_text_show_details")}
            style={{ ...borderStyle, cursor: "pointer" }}
            {...tdOnClick}
            width="40px"
          >
            {actionIndex + 1}
          </td>
        )}
        {showInsight && insightInfo && insightInfo.id && (
          <td
            align="left"
            style={{ ...secondaryBorderStyle, cursor: "pointer" }}
            {...tdOnClick}
            title={insightInfo.text}
            width={maxTitleWidth}
          >
            <div style={oveflowTextStyles}>{insightInfo.text}</div>
          </td>
        )}
        <td {...tdOnClick} width="120px" style={{ height: "60px" }}>
          {actionInfo.period}
        </td>
        <td {...tdOnClick} width="120px">
          {actionInfo.deviation}
        </td>
        <td {...tdOnClick}>{actionInfo.cause}</td>
        <td {...tdOnClick}>{actionInfo.action}</td>
        <td
          align="center"
          {...tdOnClick}
          title={actionPerformance.label}
          style={canceledStyles}
          width="120px"
        >
          {
            getObjectInfoById(
              actionPerformance.status,
              evaluationStatusOptions,
              "value",
            ).label
          }
        </td>
        <td align="center" {...tdOnClick} style={canceledStyles} width="120px">
          {
            getObjectInfoById(actionInfo.status, actionStatusOptions, "value")
              .label
          }
        </td>
        {!hideManagementActions ? (
          <EditColumn handleEdit={() => handleEditAction(actionInfo.id)} />
        ) : (
          defaultEmptyColumn
        )}
        {!hideManagementActions ? (
          <DeleteColumn
            handleDelete={() => handleDeleteAction(actionInfo.id)}
          />
        ) : (
          defaultEmptyColumn
        )}
      </tr>
      <tr
        className="block-hover"
        style={{
          display: showDetails ? "table-row" : "none",
        }}
      >
        <td
          colSpan="100"
          style={{
            ...borderStyleChild,
            padding: "0px 20px 15px 20px",
            backgroundColor: "#fff",
          }}
        >
          {selectAndOpenInsight && (
            <div align="left" style={{ marginTop: "5px" }}>
              <a
                onClick={() => selectAndOpenInsight(insightInfo.id)}
                style={{ cursor: "pointer" }}
              >
                Abrir insight
              </a>
            </div>
          )}
          <table
            className="simple-table"
            style={{ marginTop: "8px", borderBottom: "2px solid #ccc" }}
          >
            <thead>
              <tr>
                {secondaryHeadLabels.map(
                  ({ label, colSpan, align = "center" }) => (
                    <td key={label} colSpan={colSpan} align={align}>
                      {label}
                    </td>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              <tr className="block-hover">
                <td align="center" width="110px">
                  {formatDate(actionDates.start)}
                </td>
                <td align="center" width="110px">
                  {formatDate(actionDates.end)}
                </td>
                <td align="center" width="110px">
                  {formatDate(actionDates.rescheduled)}
                </td>
                <td align="center" width="110px">
                  {formatDate(actionDates.conclusion)}
                </td>
                <td width="240px" align="left">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      src={responsibleAvatar}
                      style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        marginRight: "5px",
                        border: "1px solid #ccc",
                      }}
                    >
                      {getUserInitials(actionResponsible.name)}
                    </Avatar>
                    {actionResponsible.name}
                  </div>
                </td>
                <td>{actionInfo.followUp}</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </>
  );
};

const ActionsTableLine = (props) => {
  const {
    dragabble,
    hideIndex,
    hideActions,
    showInsight,
    showEmptyCols = true,
    getAvatar,
    avatars,
    selectAndOpenInsight,
    displayActions,
  } = props;

  const handleEditAction = getValidFunc(props.handleEditAction);
  const handleDeleteAction = getValidFunc(props.handleDeleteAction);

  return orderArrayByObjAttr(displayActions, "action").map(
    (actionInfo, index) => {
      const { insightInfo = {} } = actionInfo;

      const defaultAppend = {
        actionInfo,
        hideIndex,
        hideActions,
        showInsight,
        insightInfo,
        dragabble,
        handleEditAction,
        handleDeleteAction,
        showEmptyCols,
        actionIndex: index,
        getAvatar,
        avatars,
        selectAndOpenInsight,
      };

      if (dragabble) {
        return (
          <Draggable
            key={actionInfo.id}
            index={actionInfo.order}
            draggableId={`draggableId-${actionInfo.id}`}
            display="table"
          >
            {(draggableProvided, draggableSnapshot) => (
              <LineDisplay
                {...defaultAppend}
                draggableProvided={draggableProvided}
                draggableSnapshot={draggableSnapshot}
                dragabble
              />
            )}
          </Draggable>
        );
      }
      return <LineDisplay key={actionInfo.id} {...defaultAppend} />;
    },
  );
};

const mapStateToProps = (state) => {
  return {
    avatars: state.avatars,
  };
};

export default connect(mapStateToProps, { getAvatar })(ActionsTableLine);
