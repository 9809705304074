import React from "react";
import _ from "lodash";
import { getPeriodTags } from "./defaults";
import { filterGoalByPeriod, getSingleIndicatorResults } from "./calc2";
import { getObjectInfoById, orderArrayByObjAttr } from "../ArrayUtils";
import { getCustomInsightsOrder } from "../entityUtils";
import { ClassificationBadge } from "../commentUtils";
import { getPeriodDivisionTitle } from "./display";
import { getIndicatorTargetOptions } from "../../constants/kpis";

export const getGroupInfoById = (roleId, roleBonuses, onlyIndex = false) => {
  const vRoleId = Number(roleId);

  return getObjectInfoById(vRoleId, roleBonuses, "id", onlyIndex);
};

export const getThemeInfoById = (themeId, themes, onlyIndex = false) => {
  const vThemeId = Number(themeId);

  return getObjectInfoById(vThemeId, themes, "id", onlyIndex);
};

export const getAdministrativeRoleInfoById = (
  administrativeRoleId,
  administrativeRoles,
  onlyIndex = false,
) => {
  const vAdministrativeRoleId = Number(administrativeRoleId);

  return getObjectInfoById(
    vAdministrativeRoleId,
    administrativeRoles,
    "id",
    onlyIndex,
  );
};

export const getParticipantInfoByMemberId = (
  memberId = null,
  participantsInfo = [],
) => {
  // TALVEZ SERÁ USADA
  const vMemberId = Number(memberId);
  let processInfo = null;

  if (vMemberId && participantsInfo.length > 0) {
    participantsInfo.forEach((pInfo) => {
      const { memberParticipant } = pInfo;

      if (memberParticipant.id === vMemberId) {
        processInfo = pInfo;
      }
    });
  }

  return processInfo;
};

export const getIndicatorInfoByInsightId = (insightId, indicators = []) => {
  let finalReturn = {};

  indicators.forEach((indicatorInfo) => {
    if (
      indicatorInfo &&
      indicatorInfo.relatedInsights &&
      indicatorInfo.relatedInsights.indexOf(insightId) > -1
    ) {
      finalReturn = indicatorInfo;
    }
  });

  return finalReturn;
};

export const getProcessInfoById = (processId, processes, onlyIndex = false) => {
  const vProcessId = Number(processId);

  return getObjectInfoById(vProcessId, processes, "id", onlyIndex);
};

export const getGoalIndicatorInfoById = (goalId, goals, onlyIndex = false) => {
  const vGoalId = Number(goalId);

  return getObjectInfoById(vGoalId, goals, "id", onlyIndex);
};

export const getParticipantsByIndicator = (indicatorId, participants = []) => {
  // VALIDAR UTILIDADE
  const finalParticipants = [];

  if (participants.length > 0) {
    participants.forEach((participantRelation) => {
      const { relatedIndicators = [] } = participantRelation;

      if (relatedIndicators.indexOf(indicatorId) > -1)
        finalParticipants.push(participantRelation);
    });
  }

  return finalParticipants;
};

export const getFilteredGoals = (
  filterValue = "INDIVIDUAL",
  goals = [],
  filterBy = "relationshipType",
  insightsFilter = null,
) => {
  const finalFiltered = [];

  const filterByInsights = (goalInfo) => {
    let display = true;

    if (insightsFilter && Array.isArray(insightsFilter)) {
      display = false;

      if (goalInfo.relatedInsights) {
        goalInfo.relatedInsights.forEach((compareInsightId) => {
          if (insightsFilter.indexOf(compareInsightId) > -1) display = true;
        });
      }
    }

    return display;
  };

  if (goals && goals.length > 0) {
    goals.forEach((goalInfo) => {
      if (
        goalInfo[filterBy] &&
        goalInfo[filterBy] === filterValue &&
        filterByInsights(goalInfo)
      )
        finalFiltered.push(goalInfo);
    });
  }

  return finalFiltered;
};

export const getKpiConfigBySelectedToolId = (
  selectedToolId,
  allKpiConfiguration = [],
) => {
  let finalReturn = null;

  if (Array.isArray(allKpiConfiguration)) {
    allKpiConfiguration.forEach((administration) => {
      if (administration.selectedToolId === selectedToolId)
        finalReturn = administration;
    });
  }

  return finalReturn;
};

export const getIndicatorsRelationTree = (
  selectedToolId,
  allSceneries = [],
  allThemes = {},
  allQuestions = {},
  allInsights = {},
) => {
  const buildTree = {};
  const goals = [];

  const addGoal = (goal) => {
    if (
      goals.find(
        ({ kpiConfigurationGoalId }) =>
          goal.kpiConfigurationGoalId === kpiConfigurationGoalId,
      )
    )
      return;
    goals.push(goal);
  };

  if (
    selectedToolId &&
    allSceneries.length > 0 &&
    Object.keys(allThemes).length > 0 &&
    Object.keys(allQuestions).length > 0 &&
    Object.keys(allInsights).length > 0
  ) {
    allSceneries.forEach((scenerieInfo) => {
      if (scenerieInfo.selectedTool.id === selectedToolId) {
        const { kpiConfigurationThemeId } = scenerieInfo;
        const scenerieThemes = allThemes[scenerieInfo.id];
        if (
          scenerieThemes &&
          scenerieThemes.length > 0 &&
          kpiConfigurationThemeId
        ) {
          scenerieThemes.forEach((themeInfo) => {
            const { kpiConfigurationGroupId } = themeInfo;
            const themeQuestions = allQuestions[themeInfo.id];

            if (
              themeQuestions &&
              themeQuestions.length > 0 &&
              kpiConfigurationGroupId
            ) {
              themeQuestions.forEach((questionInfo) => {
                const { kpiConfigurationProcessId } = questionInfo;
                const questionInsights = allInsights[questionInfo.id];

                if (
                  questionInsights &&
                  questionInsights.length > 0 &&
                  kpiConfigurationProcessId
                ) {
                  const orderedQuestionInsights =
                    getCustomInsightsOrder(questionInsights);

                  orderedQuestionInsights.forEach((insightInfo) => {
                    const { kpiConfigurationGoalId } = insightInfo;

                    if (kpiConfigurationGoalId) {
                      const currentScenerieBuild =
                        buildTree[kpiConfigurationThemeId] || {};
                      const currentGroups = currentScenerieBuild?.groups || {};
                      const currentThemeGroupRelation =
                        currentGroups[kpiConfigurationGroupId] || {};
                      const currentProcesses =
                        currentThemeGroupRelation?.processes || {};
                      const currentParticipantGoalRelation =
                        currentProcesses[kpiConfigurationProcessId] || {};
                      const currentGoals =
                        currentParticipantGoalRelation?.goals || [];

                      const newGoal = {
                        responsibleName: insightInfo.responsibleMember
                          ? insightInfo.responsibleMember.name
                          : "",
                        responsibleAvatar:
                          insightInfo.responsibleMember &&
                          insightInfo.responsibleMember.userData.avatarBlobId,
                        insightId: insightInfo.id,
                        kpiConfigurationGoalId,
                      };

                      addGoal(newGoal);

                      buildTree[kpiConfigurationThemeId] = {
                        sceneryId: scenerieInfo.id,
                        groups: {
                          ...currentGroups,
                          [kpiConfigurationGroupId]: {
                            themeId: themeInfo.id,
                            processes: {
                              ...currentProcesses,
                              [kpiConfigurationProcessId]: {
                                questionId: questionInfo.id,
                                goals: [...currentGoals, newGoal],
                              },
                            },
                          },
                        },
                      };
                    }
                  });
                }
              });
            }
          });
        }
      }
    });
  }

  return { tree: buildTree, goals };
};

export const getFilteredOverviewTree = (
  kpiAdministration = {},
  indicatorsRelationTree = {},
  filters = {},
  periodFilter = {},
) => {
  const finalFilteredRelationTree = [];

  const { periodCycle } = kpiAdministration || {};
  const filterActive = !_.isEqual(periodCycle, periodFilter);

  const isOnFilter = (slug, compareId) => {
    return (
      !filters[slug] ||
      filters[slug].length === 0 ||
      filters[slug].indexOf(compareId) > -1
    );
  };

  if (Object.keys(indicatorsRelationTree).length > 0 && kpiAdministration) {
    Object.keys(indicatorsRelationTree).forEach((kpiConfigurationThemeId) => {
      const themeRelation = indicatorsRelationTree[kpiConfigurationThemeId];

      if (isOnFilter("sceneries", themeRelation.sceneryId)) {
        const themeInfo = getThemeInfoById(
          kpiConfigurationThemeId,
          kpiAdministration.themes,
        );

        Object.keys(themeRelation.groups).forEach((kpiConfigurationGroupId) => {
          const roleRelation = themeRelation.groups[kpiConfigurationGroupId];
          const groupInfo = getGroupInfoById(
            kpiConfigurationGroupId,
            kpiAdministration.groups,
          );

          if (isOnFilter("themes", roleRelation.themeId)) {
            Object.keys(roleRelation.processes).forEach(
              (kpiConfigurationProcessId) => {
                const processRelation =
                  roleRelation.processes[kpiConfigurationProcessId];
                const processInfo = getProcessInfoById(
                  kpiConfigurationProcessId,
                  kpiAdministration.processes,
                );

                if (isOnFilter("questions", processRelation.questionId)) {
                  const processGoals = [];

                  processRelation?.goals?.forEach(
                    ({
                      insightId,
                      kpiConfigurationGoalId,
                      responsibleAvatar,
                      responsibleName,
                    }) => {
                      if (!isOnFilter("insights", insightId)) return;

                      const goalInfo = getGoalIndicatorInfoById(
                        kpiConfigurationGoalId,
                        kpiAdministration.goals,
                      );
                      const filteredGoal = filterActive
                        ? filterGoalByPeriod(goalInfo, periodFilter)
                        : goalInfo;

                      processGoals.push({
                        ...filteredGoal,
                        original: goalInfo,
                        avatarId: responsibleAvatar,
                        responsibleName,
                      });
                    },
                  );

                  if (processGoals.length > 0) {
                    finalFilteredRelationTree.push({
                      themeInfo,
                      groupInfo,
                      processInfo,
                      processGoals,
                      questionId: processRelation.questionId,
                    });
                  }
                }
              },
            );
          }
        });
      }
    });
  }

  return finalFilteredRelationTree;
};

export const filterOnlyIndicatorsOnTree = (indicatorsRelationTree) => {
  const finalFilteredGoals = [];

  indicatorsRelationTree.forEach(({ processGoals = [], processInfo = {} }) => {
    if (processGoals && processGoals.length > 0) {
      processGoals.forEach((goalInfo) => {
        const addedGoals = [...finalFilteredGoals.map(({ id }) => id)];
        const addedGoalIndex = addedGoals.indexOf(goalInfo.id);

        if (addedGoalIndex === -1) {
          finalFilteredGoals.push({
            ...goalInfo,
            processes: [processInfo],
          });
        } else {
          const currentGoal = finalFilteredGoals[addedGoalIndex];
          const currentGoalProcesses = currentGoal.processes || [];
          const processesId = [...currentGoalProcesses.map(({ id }) => id)];

          if (processesId.indexOf(processInfo.id) === -1) {
            finalFilteredGoals[addedGoalIndex].processes = [
              ...currentGoal.processes,
              processInfo,
            ];
          }
        }
      });
    }
  });

  return finalFilteredGoals;
};

export const filterIndicatorsByThemes = (processesRelation = []) => {
  const themes = {};
  const info = {};

  const processAdded = (themeId, processId) => {
    let found = false;

    if (
      info[themeId] &&
      info[themeId].processes &&
      Array.isArray(info[themeId].processes)
    ) {
      info[themeId].processes.forEach(({ id }) => {
        if (processId === id) found = true;
      });
    }

    return found;
  };

  processesRelation.forEach((processRelation) => {
    const {
      themeInfo = {},
      processGoals = [],
      processInfo = {},
    } = processRelation;

    if (themeInfo.id) {
      const currentThemeInfo = info[themeInfo.id] || [];

      if (!processAdded(themeInfo.id, processInfo.id)) {
        const currentProcesses = currentThemeInfo.processes || [];

        info[themeInfo.id] = {
          ...currentThemeInfo,
          processes: [...currentProcesses, processRelation],
        };
      }

      if (processGoals && processGoals.length > 0) {
        processGoals.forEach((goalInfo) => {
          const currentTeamGoals = themes[themeInfo.id] || [];
          const onlyTeamGoalsId = [...currentTeamGoals.map(({ id }) => id)];

          if (onlyTeamGoalsId.indexOf(goalInfo.id) === -1)
            themes[themeInfo.id] = [...currentTeamGoals, goalInfo];
        });
      }
    }
  });

  return { themes, info };
};

export const getQuestionByParticipantId = (
  participantId,
  allQuestions = {},
) => {
  // VALIDAR UTILIDADE
  let questionInfo = null;

  if (Object.keys(allQuestions).length > 0) {
    Object.keys(allQuestions).forEach((themeId) => {
      const themeQuestions = allQuestions[themeId];

      themeQuestions.forEach((qInfo) => {
        const { kpiConfigurationProcessId } = qInfo;

        if (kpiConfigurationProcessId === participantId) questionInfo = qInfo;
      });
    });
  }

  return questionInfo;
};

export const getProcessRelationById = (processId, processRelation = []) => {
  let finalPartRelation = null;

  if (Array.isArray(processRelation)) {
    processRelation.forEach((pRelation) => {
      const { processInfo = {} } = pRelation;

      if (processInfo.id === processId) finalPartRelation = pRelation;
    });
  }

  return finalPartRelation;
};

export const getIndicatorSingleConnectedInsightId = (goalInfo) => {
  if (goalInfo.relatedInsights && goalInfo.relatedInsights[0])
    return goalInfo.relatedInsights[0];

  return null;
};

export const getSelectedKpiIndicatorsPercentages = (
  selectedToolId,
  allKpiConfiguration,
) => {
  const foundIndicators = [];

  const selectedKpiTool = getKpiConfigBySelectedToolId(
    selectedToolId,
    allKpiConfiguration,
  );

  if (selectedKpiTool && selectedKpiTool.goals) {
    const { goalTargets, goals } = selectedKpiTool;

    goals.forEach((goalInfo) => {
      const { goalPercentage } = getSingleIndicatorResults(
        goalInfo,
        goalTargets,
        selectedKpiTool.zeroWhenNegative,
      );

      foundIndicators.push({
        ...goalInfo,
        percentage: Number(goalPercentage),
      });
    });
  }

  return foundIndicators;
};

export const getCommentsByPeriod = (insightComments, periodIndex) => {
  const commentsGroup = [];

  if (Array.isArray(insightComments) && periodIndex >= 0) {
    insightComments.forEach((userComments) => {
      const { comments } = userComments;

      const filtered = [];

      if (Array.isArray(comments)) {
        comments.forEach((commentInfo) => {
          const { classifications = [] } = commentInfo;
          const { indexTag } = getPeriodTags(periodIndex);

          if (classifications.indexOf(indexTag) > -1)
            filtered.push(commentInfo);
        });
      }

      if (filtered.length > 0)
        commentsGroup.push({ ...userComments, comments: filtered });
    });
  }

  return commentsGroup;
};

export const indicatorCommentTag = (tag) => {
  const { query } = getPeriodTags();

  const split = tag.split(":");

  if (query === `${split[0]}:`) return true;
  return false;
};

export const buildCommentTags = (tags) => {
  const result = {
    index: -1,
    target: "GATE",
    percentage: "0%",
    frequency: "MONTHLY",
    color: "#333",
  };

  tags.forEach((classification) => {
    const tagSplit = classification.split(":");
    const tag = tagSplit[0];
    const value = tagSplit[1];

    if (tag === "pindex") result.index = value;
    if (tag === "pfindex") result.fakeIndex = value;
    if (tag === "ptarget") result.target = value;
    if (tag === "ppercent") result.percentage = value;
    if (tag === "indicatorGoalId") result.goalId = Number(value);
    if (tag === "indicatorFrequency") result.frequency = value;
    if (tag === "indicatorStart") result.start = value;
    if (tag === "indicatorEnd") result.end = value;
    if (tag === "indicatorYear") result.year = value;
  });

  const { index, fakeIndex = result.index, frequency, target } = result;
  const targetOptions = getIndicatorTargetOptions();

  result.period = { start: result.start, end: result.end, year: result.year };
  result.periodTitle = getPeriodDivisionTitle(
    index,
    fakeIndex,
    {
      period: result.period,
      frequency,
    },
    true,
  );
  result.hitInfo = getObjectInfoById(target, targetOptions, "value");

  result.text = result.periodTitle;
  result.backgroundColor = "#ddd";
  result.color = "#333";

  return result;
};

export const IndicatorCommentBadge = ({ tag, classifications }) => {
  if (indicatorCommentTag(tag)) {
    const { text, backgroundColor, color } = buildCommentTags(classifications);

    return (
      <ClassificationBadge
        text={text}
        backgroundColor={backgroundColor}
        textColor={color}
      />
    );
  }

  return null;
};

export const orderByIndexes = (goals) => {
  const finalGoals = [];

  if (Array.isArray(goals)) {
    goals.forEach((goalInfo) => {
      const { target } = goalInfo;
      const { lastPeriod, distribution, actual } = target;

      const orderedLastPeriod = orderArrayByObjAttr(
        lastPeriod,
        "index",
        false,
        true,
      );
      const orderedDist = orderArrayByObjAttr(
        distribution,
        "index",
        false,
        true,
      );
      const orderedActual = orderArrayByObjAttr(actual, "index", false, true);

      finalGoals.push({
        ...goalInfo,
        target: {
          ...target,
          lastPeriod: orderedLastPeriod,
          distribution: orderedDist,
          actual: orderedActual,
        },
      });
    });
  }

  return finalGoals;
};
