import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  updateGoalBonusAdministration,
  createGoalsBonusThemeRoleBonus,
} from "../../../../actions/goalsAndBonusActions";
import ThemeRoleSelectDialog from "../../../Common/GoalsAndBonus/forms/ThemeRoleSelectDialog";
import {
  getSelectedToolStates,
  getGoalsBonusStates,
  getSelectedCompanyStates,
} from "../../../customMapStates";
import utils from "../../../../utils/toolUtils";
import gbUtils from "../../../../utils/goalsBonus";
import { fetchCompanyNestedPositions } from "../../../../actions/companyPositionActions";

const { getOnlySublevelArrays } = utils;

const {
  getGoalsBonusBySelectedToolId,
  buildFormAdmConfigBody,
  buildRestAdmConfigBody,
  getRoleInfoById,
} = gbUtils;

const defaultFormInitialGoalBonus = buildFormAdmConfigBody({});

const GoalsAndBonus = ({
  type,
  clearViewCallback,
  selectedTool = {},
  isLoading = false,
  allGoalBonusAdministration = [],
  updateGoalBonusAdministration,
  createGoalsBonusThemeRoleBonus,
  params,
  allThemes,
  selectedCompanyId,
  fetchCompanyNestedPositions,
}) => {
  const [goalBonusAdministration, setGoalBonusAdministration] = useState(
    defaultFormInitialGoalBonus,
  );
  const [addedRoles, setAddedRoles] = useState([]);

  const onlyThemes = getOnlySublevelArrays(allThemes);

  useEffect(() => {
    if (selectedCompanyId) fetchCompanyNestedPositions(selectedCompanyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newAddedRoles = [];

    onlyThemes.forEach(({ goalsBonusProgramRoleBonusId }) => {
      if (goalsBonusProgramRoleBonusId)
        newAddedRoles.push(goalsBonusProgramRoleBonusId);
    });

    if (!_.isEqual(newAddedRoles, addedRoles)) setAddedRoles(newAddedRoles);
  }, [addedRoles, onlyThemes]);

  useEffect(() => {
    let toolGoalsBonusAdm = [];

    if (allGoalBonusAdministration) {
      toolGoalsBonusAdm = getGoalsBonusBySelectedToolId(
        selectedTool.id,
        allGoalBonusAdministration,
      );

      toolGoalsBonusAdm = buildFormAdmConfigBody(toolGoalsBonusAdm);
    }

    if (!isLoading && !_.isEqual(toolGoalsBonusAdm, goalBonusAdministration)) {
      setGoalBonusAdministration(toolGoalsBonusAdm);
    }
  }, [
    allGoalBonusAdministration,
    goalBonusAdministration,
    isLoading,
    selectedTool.id,
  ]);

  const handleUpdateGoalsBonus = (newGoalsBonusAdm) => {
    if (!_.isEqual(goalBonusAdministration, newGoalsBonusAdm)) {
      const restBody = buildRestAdmConfigBody(newGoalsBonusAdm);
      updateGoalBonusAdministration(selectedTool.id, restBody);
    }
  };

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
  };

  const handleSaveTheme = ({ roles }) => {
    if (selectedTool.id && Array.isArray(roles)) {
      roles.forEach((roleId) => {
        const roleInfo = getRoleInfoById(
          roleId,
          goalBonusAdministration.roleBonuses,
        );

        createGoalsBonusThemeRoleBonus(
          roleInfo,
          params.themeScenerie,
          params.order,
        );
      });
    }

    clearDialogControll();
  };

  return (
    <ThemeRoleSelectDialog
      open
      afterConfirm={handleSaveTheme}
      afterCancel={clearDialogControll}
      goalBonusAdministration={goalBonusAdministration}
      setGoalBonusAdministration={setGoalBonusAdministration}
      alternativeSaveRoleCallback={handleUpdateGoalsBonus}
      addedRoles={addedRoles}
    />
  );
};

const mapStateToProps = (state) => {
  const { selectedTool, allThemes } = getSelectedToolStates(state);
  const { allGoalBonusAdministration, isLoading } = getGoalsBonusStates(state);
  const { selectedCompanyId } = getSelectedCompanyStates(state);

  const { user } = state;

  return {
    userProfile: user,
    selectedCompanyId,
    selectedTool,
    allGoalBonusAdministration,
    allThemes,
    isLoading,
  };
};

export default connect(mapStateToProps, {
  updateGoalBonusAdministration,
  createGoalsBonusThemeRoleBonus,
  fetchCompanyNestedPositions,
})(GoalsAndBonus);
