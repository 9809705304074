import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import _ from "lodash";
import MaterialMultiSelect from "./MaterialMultiSelect";

const messages = defineMessages({
  selectWorkspaceLabel: {
    id: "manage.selectWorkspace.label",
  },
  selectToolLabel: {
    id: "manage.selectTool.label",
  },
  selectCenaryLabel: {
    id: "manage.selectCenary.label",
  },
  selectThemeLabel: {
    id: "manage.selectTheme.label",
  },
  memberOkr: {
    id: "manage.memberOkr",
  },
  globalColumns: {
    id: "global.columns",
  },
});

const SelectFilter = (props) => {
  const {
    intl,
    selectList,
    captureSelectsData,
    showWorkspaceSelect,
    showToolSelect,
    showSceneriesSelect,
    showThemesSelect,
    showResponsibleSelect,
    showColumnsSelect,
    showSubthemeSelect,
    colSize,
    getDefaultWorkspace,
    hiddenFilter,
    filterData,
  } = props;

  function callbackFilter(data, ref) {
    captureSelectsData(data, ref);
  }

  const displayWorkspacesSelect = () => {
    return (
      <MaterialMultiSelect
        options={selectList.workspaces}
        label={intl.formatMessage(messages.selectWorkspaceLabel)}
        getData={(data, ref) => callbackFilter(data, ref, "workspace")}
        fromRef="workspaces"
        id="workspaceSelector"
        allButton
        getDefaultWorkspace={getDefaultWorkspace}
        setDefaultValue
        selectAll={false}
      />
    );
  };

  const displayToolsSelect = () => {
    return (
      <MaterialMultiSelect
        options={selectList.tools}
        label={intl.formatMessage(messages.selectToolLabel)}
        getData={(data, ref) => callbackFilter(data, ref, "tools")}
        fromRef="tools"
        id="toolSelector"
        getDefaultWorkspace
        allButton
        setDefaultValue
        selectAll
      />
    );
  };

  const displayScenerieSelect = () => {
    return (
      <MaterialMultiSelect
        options={selectList.sceneries}
        label={intl.formatMessage(messages.selectCenaryLabel)}
        getData={(data, ref) => callbackFilter(data, ref, "sceneries")}
        fromRef="sceneries"
        id="scenarySelector"
        allButton
        setDefaultValue
        selectAll
        initialOptions={filterData?.sceneries}
      />
    );
  };

  const displayThemesList = () => {
    return (
      <MaterialMultiSelect
        options={selectList && selectList.themes ? selectList.themes : []}
        label={intl.formatMessage(messages.selectThemeLabel)}
        getData={(data, ref) => callbackFilter(data, ref, "themes")}
        fromRef="themes"
        id="themeSelector"
        allButton
        setDefaultValue
        selectAll
        initialOptions={filterData?.themes}
      />
    );
  };

  const displayQuestionList = () => {
    return (
      <MaterialMultiSelect
        options={selectList.questions ?? selectList.questions}
        label="Selecione o Subtema"
        getData={(data, ref) => callbackFilter(data, ref, "questions")}
        fromRef="questions"
        id="questionSelector"
        allButton
        setDefaultValue
        selectAll
        initialOptions={filterData?.questions}
      />
    );
  };

  const displayResponsibleSelect = () => {
    return (
      <MaterialMultiSelect
        options={
          selectList && selectList.responsibles ? selectList.responsibles : []
        }
        label={intl.formatMessage(messages.memberOkr)}
        getData={(data, ref) => callbackFilter(data, ref, "responsible")}
        fromRef="responsible"
        id="responsibleSelector"
        allButton
        setDefaultValue
      />
    );
  };

  const displayColumnsSelect = () => {
    selectList.columns = selectList.columns || [];

    return (
      <MaterialMultiSelect
        options={selectList.columns}
        label={intl.formatMessage(messages.globalColumns)}
        getData={(data, ref) => callbackFilter(data, ref, "columns")}
        fromRef="columns"
        id="columnsSelector"
        allButton
        setDefaultValue
        selectAll
        initialOptions={[...selectList.columns.map(({ value }) => value)]}
      />
    );
  };

  return (
    <>
      {!hiddenFilter && (
        <div className="row hidden-print" style={{ paddingBottom: "20px" }}>
          {showWorkspaceSelect && (
            <div className={colSize} style={{ padding: "0 15px 0 0" }}>
              {displayWorkspacesSelect()}
            </div>
          )}
          {showToolSelect && (
            <div className={colSize} style={{ padding: "0 15px 0 0" }}>
              {displayToolsSelect()}
            </div>
          )}
          {showSceneriesSelect && (
            <div className={colSize} style={{ padding: "0 15px 0 0" }}>
              {displayScenerieSelect()}
            </div>
          )}
          {showThemesSelect && (
            <div className={colSize} style={{ padding: "0 15px 0 0" }}>
              {displayThemesList()}
            </div>
          )}
          {showResponsibleSelect && (
            <div className={colSize} style={{ padding: "0 15px 0 0" }}>
              {displayResponsibleSelect()}
            </div>
          )}
          {showColumnsSelect && (
            <div className={colSize} style={{ padding: "0 15px 0 0" }}>
              {displayColumnsSelect()}
            </div>
          )}
          {showSubthemeSelect && (
            <div className={colSize} style={{ padding: "0px" }}>
              {displayQuestionList()}
            </div>
          )}
        </div>
      )}
    </>
  );
};

SelectFilter.propTypes = {
  selectList: PropTypes.object,
  hiddenFilter: PropTypes.bool,
  captureSelectsData: PropTypes.func.isRequired,
  showWorkspaceSelect: PropTypes.bool,
  showSceneriesSelect: PropTypes.bool,
  showThemesSelect: PropTypes.bool,
  showResponsibleSelect: PropTypes.bool,
  colSize: PropTypes.string,
};

SelectFilter.defaultProps = {
  hiddenFilter: false,
  showWorkspaceSelect: false,
  showToolSelect: false,
  showSceneriesSelect: true,
  showThemesSelect: true,
  showResponsibleSelect: false,
  showColumnsSelect: false,
  selectList: [],
  colSize: "col-xs-4",
};

function areEqual(prevProps, nextProps) {
  if (!_.isEqual(prevProps.selectList, nextProps.selectList)) return false;
  if (!_.isEqual(prevProps.filterData, nextProps.filterData)) return false;
  return true;
}

export default injectIntl(React.memo(SelectFilter, areEqual));
