import { GOALS_AND_BONUS } from "../constants/tools.constants";

export const filterMembersAndParticipants = (membersParticipants) => {
  const onlyMembers = [];
  const onlyParticipants = [];

  membersParticipants.forEach((info) => {
    if (info.type === "MEMBER") {
      onlyMembers.push(info);
    } else {
      onlyParticipants.push(info);
    }
  });

  return {
    members: onlyMembers,
    participants: onlyParticipants,
  };
};

export const middlewareQuestionStructure = (
  allGoalsBonus,
  questionInfo,
  toolId,
) => {
  if (questionInfo.id && toolId === GOALS_AND_BONUS) {
    const { goalsBonusProgramParticipantId } = questionInfo;
    let question = {};

    allGoalsBonus.forEach(({ participants }) => {
      const participant = participants.find(
        (participant) => participant.id === goalsBonusProgramParticipantId,
      );

      const memberParticipant =
        participant && participant.memberParticipant
          ? participant.memberParticipant
          : {};

      question = {
        ...questionInfo,
        avatar: memberParticipant.avatarBlobId,
      };
    });

    return question;
  }
  return questionInfo;
};
