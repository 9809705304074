const en = {
  tcategory_strategy: "Strategy",
  tcategory_processes: "Processes",
  tcategory_projects: "Projects",
  tcategory_people: "People",
  tcategory_dev: "DEV",
};

const es = {
  tcategory_strategy: "Estrategia",
  tcategory_processes: "Procesos",
  tcategory_projects: "Proyectos",
  tcategory_people: "Personas",
  tcategory_dev: "DEV",
};

const pt = {
  tcategory_strategy: "Estratégia",
  tcategory_processes: "Processos",
  tcategory_projects: "Projetos",
  tcategory_people: "Pessoas",
  tcategory_dev: "DEV",
};

const translations = { en, es, pt };

export default translations;
