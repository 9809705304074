import React from "react";
import { getObjectInfoById } from "../../../../../../../utils/ArrayUtils";
import {
  getEvaluationConfigByType,
  getEvaluationStagesOptions,
} from "../../../../../../../utils/skillsAssessment/constants";

const ParticipantsTableStatus = ({
  title,
  participants,
  evaluationType,
  evaluationConfig,
}) => {
  const {
    enableThirdEvaluator,
    enableSecondEvaluator,
  } = getEvaluationConfigByType(evaluationType);
  const stageOptions = getEvaluationStagesOptions(
    evaluationType,
    evaluationConfig,
  );

  function getStageLabel(stage) {
    return getObjectInfoById(stage, stageOptions, "value")?.label;
  }

  return (
    <table className="simple-table">
      <thead>
        <tr style={{ textAlign: "left" }}>
          <td colSpan="100">{title}</td>
        </tr>
      </thead>
      <tbody>
        <tr className="border-bottom-division" style={{ fontWeight: "bold" }}>
          <td>Participante</td>
          <td>Cargo</td>
          <td>Área</td>
          <td>Perfil</td>
          <td>Gestor</td>
          {enableSecondEvaluator && <td>Cliente</td>}
          {enableThirdEvaluator && <td>Fornecedor</td>}
          <td>Estágio</td>
        </tr>
        {participants?.map((participant) => {
          return (
            <tr>
              <td>{participant?.memberParticipant?.name}</td>
              <td>{participant?.companyPosition?.title}</td>
              <td>{participant?.companyArea?.title}</td>
              <td>{participant?.profile?.title}</td>
              <td>{participant?.manager?.name}</td>
              {enableSecondEvaluator && (
                <td>{participant?.secondEvaluator?.name}</td>
              )}
              {enableThirdEvaluator && (
                <td>{participant?.thirdEvaluator?.name}</td>
              )}
              <td>{getStageLabel(participant?.evaluationStage)}</td>
            </tr>
          );
        })}
        {!participants?.length && (
          <tr>
            <td colSpan="100" align="center">
              Nenhum para mostrar
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default ParticipantsTableStatus;
