import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchAllActivityInformation } from "../../../../actions/activityInformationActions";
import AverageBadge from "../../../Common/AverageBadge";
import utils from "../../../../utils/toolUtils";
import { getActionsPlanStates } from "../../../customMapStates";
import actionsPlanUtils from "../../../../utils/actionsPlan";
import ActionsCounterBadge from "../../../Common/ActionPlan/evaluations/ActionsCounterBadge";
import { getOnlySlugValues } from "../../../../utils/ArrayUtils";

const {
  getTotalActionsByInsights,
  getDefaultEvaluationBadgeConfig,
} = actionsPlanUtils;

const { getMaturityArrayEvaluation, getInsightMaturityById } = utils;

function getFinalAverage(insightsToCalc, allMaturityData) {
  let filteredMaturityList = [];
  let finalAverage = 0;

  insightsToCalc.forEach(({ id }) => {
    const iMaturity = getInsightMaturityById(id, allMaturityData);
    filteredMaturityList = [...filteredMaturityList, iMaturity];
  });

  finalAverage = getMaturityArrayEvaluation(filteredMaturityList);

  return finalAverage;
}

class ProcessesBigPicture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      insightsToCalc: [],
      allMaturityData: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const insightsUpdated =
      prevState.insightsToCalc.length !== nextProps.insightsToCalc.length;
    const maturityUpdated =
      Object.keys(prevState.allMaturityData).length !==
      Object.keys(nextProps.allMaturityData).length;

    if (insightsUpdated || maturityUpdated) {
      return {
        insightsToCalc: nextProps.insightsToCalc,
        allMaturityData: nextProps.allMaturityData,
      };
    }

    return null;
  }

  render() {
    const {
      insightsToCalc,
      allMaturityData,
      pullClass,
      from,
      allActionsPlans,
    } = this.props;
    const finalAverage = getFinalAverage(insightsToCalc, allMaturityData);

    const {
      hideBadge: hideActionsCounter,
      hideTitle: hideActionsCounterTitle,
    } = getDefaultEvaluationBadgeConfig(from);

    const onlyInsightIds = getOnlySlugValues(insightsToCalc, "id");

    return (
      <div
        className="process-big-picture-evaluation-container"
        style={{ display: "flex", alignItems: "center" }}
      >
        {insightsToCalc && insightsToCalc.length > 0 && (
          <div
            className={`average_tool ${pullClass}`}
            style={{ marginRight: "10px" }}
          >
            <AverageBadge average={finalAverage} borderedBadge />
          </div>
        )}
        {!hideActionsCounter && (
          <ActionsCounterBadge
            actions={
              getTotalActionsByInsights(onlyInsightIds, allActionsPlans).length
            }
            hideTitle={hideActionsCounterTitle}
          />
        )}
      </div>
    );
  }
}

ProcessesBigPicture.propTypes = {
  from: PropTypes.any.isRequired,
  insightsToCalc: PropTypes.array.isRequired,
  pullClass: PropTypes.string,
};

ProcessesBigPicture.defaultProps = {
  pullClass: "pull-right",
};

const mapStateToProps = (state) => {
  const { insightMaturity } = state;
  const allMaturityData =
    insightMaturity && insightMaturity.all ? insightMaturity.all : [];

  const { allActionsPlans = [] } = getActionsPlanStates(state);

  return {
    allMaturityData,
    allActionsPlans,
  };
};

export default connect(mapStateToProps, { fetchAllActivityInformation })(
  ProcessesBigPicture,
);
