import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import utils from "../../../../utils/toolUtils";
import { getInitialStates } from "../../../../constants/peopleEvaluation";

import ProfessionalProfileField from "./ProfessionalProfileField";
import SalaryTrajectoryField from "./SalaryTrajectoryField";
import EvaluationTargetField from "./EvaluationTargetField";
import InsightActionPlan from "../../../Common/ActionPlan/InsightActionPlan";

import {
  addPeopleEvaluation,
  updateMemberId,
} from "../../../../actions/peopleEvaluationActions";
import { updateInsightName } from "../../../../actions/insightActions";
import {
  getBigPeopleStates,
  getSelectedCompanyStates,
  getCompanyStructureStates,
  getSelectedToolStates,
  getCompanyMembersAndParticipants,
} from "../../../customMapStates";
import { checkAccess } from "../../../../utils/accessLevels";
import {
  useCompanyAreasTree,
  useCompanyPositionsTree,
} from "../../../../utils/customHooks";
import { fetchCompanyNestedAreas } from "../../../../actions/companyStructureActions";
import { fetchCompanyNestedPositions } from "../../../../actions/companyPositionActions";

const {
  buildFormEvaluationBody,
  buildRestEvaluationBody,
  removeAllBodyIds,
  getInsightPeopleEvaluation,
  getMemberInfoById,
} = utils;

const initialStates = getInitialStates();

const PeopleEvaluation = (props) => {
  const [profHistoryModalConfig, setProfHistoryModalConfig] = useState(false);
  const [firstMount, setFirstMount] = useState(false);
  const [fetchWatcher, setFetchWatcher] = useState(true);
  const [professionalProfile, setProfessionalProfile] = useState(
    initialStates.professionalProfile,
  );
  const [salaryTrajectory, setSalaryTrajectory] = useState(
    initialStates.salaryTrajectory,
  );
  const [evaluationTarget, setEvaluationTarget] = useState(
    initialStates.evaluationTarget,
  );
  const [userProfile, setUserProfile] = useState({});

  const [selectAreaModal, setSelectAreaModal] = useState(false);
  const [selectPositionModal, setSelectPositionModal] = useState(false);

  const { companyAreasTree } = useCompanyAreasTree({
    selectedCompany: props.selectedCompany,
    allAreaStructures: props.allAreaStructures,
  });
  const { companyPositionsTree } = useCompanyPositionsTree({
    selectedCompany: props.selectedCompany,
    allPositions: props.allPositions,
  });

  const {
    isLoading = false,
    selectedCompany,
    selectedTool = {},
    insight = {},
    allPositions,
  } = props;

  const selectedCompanyId =
    selectedCompany && selectedCompany.id ? selectedCompany.id : null;

  const insightID = insight.id || 0;
  const questionID = insight.question ? props.insight.question.id : 0;
  let onStateEvaluationInfo = getInsightPeopleEvaluation(
    insightID,
    props.allPeopleEvaluation,
  );
  onStateEvaluationInfo = onStateEvaluationInfo || {};

  const onStatePeopleEvaluation =
    onStateEvaluationInfo && onStateEvaluationInfo.peopleEvaluation
      ? onStateEvaluationInfo.peopleEvaluation
      : {};

  const savePeopleEvaluation = useCallback(
    (setToDefault) => {
      if (props.addPeopleEvaluation && !isLoading) {
        const saveRestEvaluation = buildRestEvaluationBody({
          id: onStatePeopleEvaluation.id,
          professionalProfile,
          salaryTrajectory,
          evaluationTarget,
        });

        props.addPeopleEvaluation(
          insightID,
          saveRestEvaluation,
          setToDefault === true,
        );

        setFetchWatcher(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [evaluationTarget, insightID, professionalProfile, salaryTrajectory],
  );

  useEffect(() => {
    if (selectedCompanyId) {
      props.fetchCompanyNestedAreas(selectedCompanyId);
      props.fetchCompanyNestedPositions(selectedCompanyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanyId]);

  const buildCurrentEvaluation = ({
    professionalProfile = {},
    salaryTrajectory = {},
    evaluationTarget = {},
  }) => {
    return buildFormEvaluationBody({
      professionalProfile,
      salaryTrajectory,
      evaluationTarget,
    });
  };

  const compareStateAndForm = () => {
    let isEqual = false;
    let currentEvaluation = buildCurrentEvaluation({
      professionalProfile,
      salaryTrajectory,
      evaluationTarget,
    });

    if (onStatePeopleEvaluation) {
      let comparableEvaluation = buildFormEvaluationBody(
        onStatePeopleEvaluation,
      );

      comparableEvaluation = removeAllBodyIds(comparableEvaluation);
      currentEvaluation = removeAllBodyIds(currentEvaluation);

      // After updating state
      if (_.isEqual(currentEvaluation, comparableEvaluation)) {
        isEqual = true;
      }
    }

    return isEqual;
  };

  useEffect(() => {
    if (fetchWatcher && isLoading && firstMount) {
      setFirstMount(false);
    }
  }, [fetchWatcher, isLoading, firstMount]);

  useEffect(() => {
    function finishMount() {
      setFirstMount(true);
      setFetchWatcher(false);
    }

    if (!isLoading && !firstMount) {
      if (!onStatePeopleEvaluation.id) {
        setProfessionalProfile(initialStates.professionalProfile);
        setSalaryTrajectory(initialStates.salaryTrajectory);
        setEvaluationTarget(initialStates.evaluationTarget);
        savePeopleEvaluation(true);

        if (!fetchWatcher) {
          finishMount();
        }
      }

      if (onStatePeopleEvaluation.id && fetchWatcher) {
        const stateFormComparisom = compareStateAndForm();
        const peopleEvaluationBody = buildFormEvaluationBody(
          onStatePeopleEvaluation,
        );

        if (!stateFormComparisom) {
          setProfessionalProfile(peopleEvaluationBody.professionalProfile);

          setSalaryTrajectory(peopleEvaluationBody.salaryTrajectory);

          setEvaluationTarget(peopleEvaluationBody.evaluationTarget);
        }

        finishMount();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onStatePeopleEvaluation, isLoading, fetchWatcher, firstMount]);

  const handleChangeMemberID = (mID) => {
    if (professionalProfile.memberId !== mID) {
      const memberInfo = getMemberInfoById(
        mID,
        props.companyMembersAndParticipants,
      );

      const defineMemberInfo = {
        id: mID,
        type: memberInfo.type,
      };

      props.updateInsightName(questionID, props.insight.id, memberInfo.name);

      if (
        onStatePeopleEvaluation.professionalProfile &&
        onStatePeopleEvaluation.professionalProfile.id
      ) {
        props.updateMemberId(
          insightID,
          onStatePeopleEvaluation.professionalProfile.id,
          defineMemberInfo,
        );
        setFetchWatcher(true);
      }
    }
  };

  function toggleSelectAreaModal() {
    setSelectAreaModal(!selectAreaModal);
  }

  function toggleSelectPositionModal() {
    setSelectPositionModal(!selectPositionModal);
  }

  const getAllCompanyPositions = useCallback((allPositions, companyId) => {
    if (allPositions[companyId]) {
      const companyPositions = allPositions[companyId].map(
        (positions) => positions,
      );
      return companyPositions;
    }

    return [];
  }, []);

  const displaySaveButton =
    checkAccess([
      "OWNER",
      "ADMIN",
      "MODERATOR",
      "MANAGER",
      "CREATOR",
      "RESPONSIBLE",
      "COLLABORATOR",
    ]) && compareStateAndForm() === false;

  const defaultAppendProps = {
    professionalProfile,
    setProfessionalProfile,
    salaryTrajectory,
    setSalaryTrajectory,
    setMemberId: (mID) => handleChangeMemberID(mID),
    evaluationTarget,
    setEvaluationTarget,
    saveButton: displaySaveButton,
    companyMembersAndParticipants: props.companyMembersAndParticipants,
    companyMembers: props.companyMembers,
    setProfHistoryModalConfig,
    profHistoryModalConfig,
    selectedCompany,
    onSave: () => savePeopleEvaluation(),
    companyAreasTree,
    companyPositionsTree,
    selectAreaModal,
    selectPositionModal,
    toggleSelectAreaModal,
    toggleSelectPositionModal,
    userProfile,
    setUserProfile,
    allPositions: getAllCompanyPositions(allPositions, selectedCompany?.id),
  };

  return (
    <div>
      <ProfessionalProfileField {...defaultAppendProps} />
      <SalaryTrajectoryField {...defaultAppendProps} />
      <EvaluationTargetField {...defaultAppendProps} />
      <div className="row">
        <InsightActionPlan
          insight={insight}
          selectedTool={selectedTool}
          dragabble
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { allPeopleEvaluation, isLoading } = getBigPeopleStates(state);
  const { allAreaStructures = {}, allPositions } =
    getCompanyStructureStates(state);
  const { selectedCompany } = getSelectedCompanyStates(state);
  const { companyMembersAndParticipants, companyMembers } =
    getCompanyMembersAndParticipants(state);
  const { selectedTool = {} } = getSelectedToolStates(state);

  return {
    selectedTool,
    allPeopleEvaluation,
    allAreaStructures,
    allPositions,
    selectedCompany,
    companyMembersAndParticipants,
    companyMembers,
    isLoading,
  };
};

export default connect(mapStateToProps, {
  addPeopleEvaluation,
  updateMemberId,
  updateInsightName,
  fetchCompanyNestedAreas,
  fetchCompanyNestedPositions,
})(PeopleEvaluation);
