/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {} from "../../../../../../../../utils/skillsAssessment/defaults";
import {
  getParticipantComplementaryWorkflow,
  getUserPeopleToEvaluateByStage,
} from "../../../../../../../../utils/skillsAssessment/filter";
import { getParticipantCalculatedWorkflow } from "../../../../../../../../utils/skillsAssessment/calc";

export function useParticipantsWorkflowPage({
  toolAdministration = {},
  filterStage = "SELECTION_EVALUATORS",
  loggedUser = {},
  isAdmin,
}) {
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [participantsWorkflow, setParticipantsWorkflow] = useState({});
  const [
    participantsComplementaryWorkflow,
    setParticipantsComplementaryWorkflow,
  ] = useState({});

  const { complementaryWorkflow } = toolAdministration;

  useEffect(() => {
    if (toolAdministration.participants.length > 0) {
      const { filtered } = updateFilteredParticipants(
        toolAdministration.participants,
        filterStage,
      );

      setFilteredParticipants(
        getUserPeopleToEvaluateByStage(
          loggedUser,
          filtered,
          filterStage,
          isAdmin,
        ),
      );
    }
  }, [
    filterStage,
    toolAdministration.participants,
    toolAdministration.workflow,
    toolAdministration.complementaryWorkflow,
  ]);

  const updateFilteredParticipants = (filterParticipants, stage) => {
    const filtered = getUserPeopleToEvaluateByStage(
      loggedUser,
      filterParticipants,
      stage,
      isAdmin,
    );

    setFilteredParticipants(filtered);

    const worflows = {};
    const complementaryWorkflows = {};

    filtered.forEach((participant) => {
      worflows[participant.id] = getParticipantCalculatedWorkflow(
        toolAdministration,
        participant,
      );
      complementaryWorkflows[participant.id] =
        getParticipantComplementaryWorkflow(complementaryWorkflow, participant);
    });

    setParticipantsWorkflow(worflows);
    setParticipantsComplementaryWorkflow(complementaryWorkflows);

    return { filtered };
  };

  return {
    filteredParticipants,
    participantsWorkflow,
    setParticipantsWorkflow,
    participantsComplementaryWorkflow,
    setParticipantsComplementaryWorkflow,
  };
}
