import store from "../../Store";
import { getObjectInfoById } from "../ArrayUtils";

export const getGroupInfoById = (roleId, roleBonuses, onlyIndex = false) => {
  return getObjectInfoById(Number(roleId), roleBonuses, "id", onlyIndex);
};

export const getScenerieInfoById = (scenerieId, plans, onlyIndex = false) => {
  return getObjectInfoById(Number(scenerieId), plans, "id", onlyIndex);
};

export const getThemeInfoById = (themeId, cycles, onlyIndex = false) => {
  return getObjectInfoById(Number(themeId), cycles, "id", onlyIndex);
};

export const getProcessInfoById = (processId, processes, onlyIndex = false) => {
  return getObjectInfoById(Number(processId), processes, "id", onlyIndex);
};

export const getGoalIndicatorInfoById = (goalId, goals, onlyIndex = false) => {
  return getObjectInfoById(Number(goalId), goals, "id", onlyIndex);
};

export const getQuestionInfoById = (
  questionId,
  objectives,
  onlyIndex = false,
) => {
  return getObjectInfoById(Number(questionId), objectives, "id", onlyIndex);
};

export const getIndicatorsRelationTree = (
  selectedToolId,
  allSceneries = [],
  allThemes = {},
  allQuestions = {},
  allInsights = {},
) => {
  const buildTree = {};

  if (
    selectedToolId &&
    allSceneries.length > 0 &&
    Object.keys(allThemes).length > 0 &&
    Object.keys(allQuestions).length > 0 &&
    Object.keys(allInsights).length > 0
  ) {
    allSceneries.forEach((scenerieInfo) => {
      if (scenerieInfo.selectedTool.id === selectedToolId) {
        const { okrConfigurationPlanId } = scenerieInfo;
        const scenerieThemes = allThemes[scenerieInfo.id];
        if (
          scenerieThemes &&
          scenerieThemes.length > 0 &&
          okrConfigurationPlanId
        ) {
          scenerieThemes.forEach((themeInfo) => {
            const { okrConfigurationCycleId } = themeInfo;
            const themeQuestions = allQuestions[themeInfo.id];

            if (
              themeQuestions &&
              themeQuestions.length > 0 &&
              okrConfigurationCycleId
            ) {
              themeQuestions.forEach((questionInfo) => {
                const { okrConfigurationObjectiveId } = questionInfo;
                const questionInsights = allInsights[questionInfo.id];

                if (
                  questionInsights &&
                  questionInsights.length > 0 &&
                  okrConfigurationObjectiveId
                ) {
                  questionInsights.forEach((insightInfo) => {
                    const { okrConfigurationKeyId } = insightInfo;

                    if (okrConfigurationKeyId) {
                      const currentScenerieBuild =
                        buildTree[okrConfigurationPlanId] || {};
                      const currentPlans = currentScenerieBuild.plans || {};
                      const currentThemeCycle =
                        currentPlans[okrConfigurationCycleId] || {};
                      const currentCycles = currentThemeCycle.cycles || {};
                      const currentObjectives =
                        currentCycles[okrConfigurationObjectiveId] || {};
                      const currentKeys = currentObjectives.keyResults || [];

                      buildTree[okrConfigurationPlanId] = {
                        sceneryId: scenerieInfo.id,
                        groups: {
                          ...currentPlans,
                          [okrConfigurationCycleId]: {
                            themeId: themeInfo.id,
                            cycles: {
                              ...currentCycles,
                              [okrConfigurationObjectiveId]: {
                                questionId: questionInfo.id,
                                keyResults: [
                                  ...currentKeys,
                                  {
                                    insightId: insightInfo.id,
                                    okrConfigurationKeyId,
                                  },
                                ],
                              },
                            },
                          },
                        },
                      };
                    }
                  });
                }
              });
            }
          });
        }
      }
    });
  }

  return buildTree;
};

export const getFilteredIndicatorsRelationTree = (
  kpiAdministration,
  indicatorsRelationTree,
  filters = {},
) => {
  const finalFilteredRelationTree = [];

  const isOnFilter = (slug, compareId) => {
    return (
      !filters[slug] ||
      filters[slug].length === 0 ||
      filters[slug].indexOf(compareId) > -1
    );
  };

  if (Object.keys(indicatorsRelationTree).length > 0 && kpiAdministration) {
    Object.keys(indicatorsRelationTree).forEach((kpiConfigurationThemeId) => {
      const themeRelation = indicatorsRelationTree[kpiConfigurationThemeId];

      if (isOnFilter("sceneries", themeRelation.sceneryId)) {
        const themeInfo = getThemeInfoById(
          kpiConfigurationThemeId,
          kpiAdministration.themes,
        );

        Object.keys(themeRelation.groups).forEach((kpiConfigurationGroupId) => {
          const roleRelation = themeRelation.groups[kpiConfigurationGroupId];
          const groupInfo = getGroupInfoById(
            kpiConfigurationGroupId,
            kpiAdministration.groups,
          );

          if (isOnFilter("themes", roleRelation.themeId)) {
            Object.keys(roleRelation.processes).forEach(
              (kpiConfigurationProcessId) => {
                const processRelation =
                  roleRelation.processes[kpiConfigurationProcessId];
                const processInfo = getProcessInfoById(
                  kpiConfigurationProcessId,
                  kpiAdministration.processes,
                );

                if (isOnFilter("questions", processRelation.questionId)) {
                  const processGoals = [];

                  if (processRelation.goals) {
                    processRelation.goals.forEach(
                      ({ kpiConfigurationGoalId }) => {
                        const goalInfo = getGoalIndicatorInfoById(
                          kpiConfigurationGoalId,
                          kpiAdministration.goals,
                        );
                        processGoals.push(goalInfo);
                      },
                    );
                  }

                  finalFilteredRelationTree.push({
                    themeInfo,
                    groupInfo,
                    processInfo,
                    processGoals,
                    questionId: processRelation.questionId,
                  });
                }
              },
            );
          }
        });
      }
    });
  }

  return finalFilteredRelationTree;
};

export const getAllCyclesFromPlans = (plans = []) => {
  const allCycles = [];

  if (Array.isArray(plans)) {
    plans.forEach((planInfo) => {
      const { cycleGroups = [] } = planInfo;

      if (cycleGroups.length > 0) {
        cycleGroups.forEach((cycleGroupInfo) => {
          const { cycles = [] } = cycleGroupInfo;

          cycles.forEach((cicle) => allCycles.push(cicle));
        });
      }
    });
  }

  return allCycles;
};

export const getAllKeysFromObjectives = (objectives = []) => {
  const allKeys = [];

  if (Array.isArray(objectives)) {
    objectives.forEach((objectiveInfo) => {
      const { keys = [] } = objectiveInfo;

      if (keys.length > 0) {
        keys.forEach((keyInfo) => allKeys.push(keyInfo));
      }
    });
  }

  return allKeys;
};

export const getAlreadyAddedCycleGroupsIds = (planCycleGroups) => {
  const alreadyAddedGroupsIds = [];

  if (planCycleGroups && Array.isArray(planCycleGroups)) {
    planCycleGroups.forEach((cycleGroupInfo) => {
      if (cycleGroupInfo.id) alreadyAddedGroupsIds.push(cycleGroupInfo.id);
    });
  }

  return alreadyAddedGroupsIds;
};

export const getAlreadyAddedCyclesIds = (planCycleGroups) => {
  const alreadyAddedCyclesIds = [];

  if (planCycleGroups && Array.isArray(planCycleGroups)) {
    planCycleGroups.forEach((cycleGroupInfo) => {
      const groupCycles = cycleGroupInfo.cycles;

      if (cycleGroupInfo.id && groupCycles && Array.isArray(groupCycles)) {
        groupCycles.forEach((cycleInfo) => {
          if (cycleInfo.id) alreadyAddedCyclesIds.push(cycleInfo.id);
        });
      }
    });
  }

  return alreadyAddedCyclesIds;
};

export const getFilteredCycleGroupFromArray = (
  planCycleGroups,
  excludeIds = [],
) => {
  const filteredCycleGroups = [];

  if (planCycleGroups && Array.isArray(planCycleGroups)) {
    planCycleGroups.forEach((cycleGroupInfo) => {
      if (excludeIds.indexOf(cycleGroupInfo.id) === -1)
        filteredCycleGroups.push(cycleGroupInfo);
    });
  }

  return filteredCycleGroups;
};

export const getFilteredNewCyclesOnGroup = (groupCycles, excludeIds = []) => {
  const filteredOnlyNewOnes = [];

  if (groupCycles && Array.isArray(groupCycles)) {
    groupCycles.forEach((cycleInfo) => {
      if (excludeIds.indexOf(cycleInfo.id) === -1)
        filteredOnlyNewOnes.push(cycleInfo);
    });
  }

  return filteredOnlyNewOnes;
};

export const getThemesWithGroupYears = (cenaryThemes = {}) => {
  const { okr = {} } = store.getState();
  const { okrPlans = [] } = okr;
  let transformedThemes = [];
  const finalAllThemes = {};
  const groupedThemes = {};

  function getGroupYear(cycleGroups, themeInfo) {
    let year = "";

    if (cycleGroups) {
      cycleGroups.forEach((cycleGroup) => {
        if (cycleGroup.cycles) {
          const findedIndex = cycleGroup.cycles.findIndex((cycle) => {
            return cycle.id === themeInfo.okrConfigurationCycleId;
          });
          if (findedIndex > -1) {
            year = cycleGroup.year;
          }
        }
      });
    }

    return year;
  }

  Object.keys(cenaryThemes).forEach((cenaryId) => {
    if (Array.isArray(cenaryThemes[cenaryId])) {
      cenaryThemes[cenaryId]?.forEach((theme) => {
        const { cenary } = theme;
        const planInfo = getObjectInfoById(
          cenary.okrConfigurationPlanId,
          okrPlans,
          "id",
        );
        if (planInfo && theme) {
          const planCycleGroups = planInfo.cycleGroups;
          const newTheme = {
            ...theme,
            name: `(${getGroupYear(planCycleGroups, theme)}) ${theme.name}`,
            orderNumber: theme.name.replace(/\D/g, ""),
          };
          transformedThemes.push(newTheme);
        }
      });
    }
  });

  const getValueInsideParentheses = (string) => {
    const valueInsideParentheses = string.match(/\(([^()]*)\)/g, "");
    if (valueInsideParentheses) {
      return valueInsideParentheses[0];
    }
    return string;
  };

  const getValueWithoutParentheses = (string) => {
    const replacedString = string.replace(/ *\([^)]*\) */g, "");
    const findMatch = replacedString.match(/\d+/);
    if (findMatch) {
      return findMatch[0];
    }
    return replacedString;
  };

  const groupedThemesByYear = () => {
    const orderedByYear = transformedThemes.sort((a, b) => {
      const aThemeYear = getValueInsideParentheses(a.name);
      const bThemeYear = getValueInsideParentheses(b.name);
      return bThemeYear - aThemeYear;
    });

    orderedByYear.forEach((theme) => {
      const year = getValueInsideParentheses(theme.name);
      if (!groupedThemes[year]) {
        Object.assign(groupedThemes, {
          [year]: [theme],
        });
      } else {
        Object.assign(groupedThemes, {
          [year]: [...groupedThemes[year], theme],
        });
      }
    });
  };

  groupedThemesByYear();

  const orderedGroupedThemesByCycle = () => {
    let finalOrderByCycle = [];
    Object.keys(groupedThemes).forEach((year) => {
      const orderByCycle = groupedThemes[year]?.sort((a, b) => {
        const aThemeName = getValueWithoutParentheses(a.name);
        const bThemeName = getValueWithoutParentheses(b.name);

        if (!isNaN(aThemeName) && !isNaN(bThemeName)) {
          return aThemeName - bThemeName;
        }

        return aThemeName > bThemeName ? -1 : 1;
      });
      if (Array.isArray(orderByCycle)) {
        finalOrderByCycle = [...finalOrderByCycle, ...orderByCycle];
      }
    });
    transformedThemes = finalOrderByCycle;
  };

  orderedGroupedThemesByCycle();

  transformedThemes.forEach((theme) => {
    const { cenary } = theme;
    const cenaryId = cenary?.id;
    const cenaryTheme = finalAllThemes[cenaryId];
    if (cenaryId && !cenaryTheme) {
      Object.assign(finalAllThemes, { [cenaryId]: [theme] });
    } else if (cenaryId) {
      Object.assign(finalAllThemes, {
        [cenaryId]: [...cenaryTheme, theme],
      });
    }
  });

  return finalAllThemes;
};
