const en = {
  bm_main_title: "Business Model",
};

const es = {
  bm_main_title: "Modelo de Negocio",
};

const pt = {
  bm_main_title: "Modelo de Negócios",
};

const translations = { en, es, pt };

export default translations;
