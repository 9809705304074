import styled from "styled-components";
import Alert from "@material-ui/lab/Alert";

export const AlertError = styled(Alert)`
  display: flex;
  align-items: center;

  font-size: 12px;

  .MuiAlert-message {
    padding: 0;
  }
`;
