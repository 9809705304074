import { UPDATE_COMPANY, CREATE_COMPANY } from "../../../types";

export const update = (company) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_DOMAIN_COMPANY", payload: company });
    dispatch({ type: UPDATE_COMPANY, payload: company });
  };
};

export const post = (company) => {
  return (dispatch) => {
    dispatch({ type: CREATE_COMPANY, payload: company });
  };
};
