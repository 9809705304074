import React, { useState, useEffect } from "react";
import { defineMessages } from "react-intl";
import { translatedText } from "../../utils/translationUtils";

const messages = defineMessages({
  app_lost_connection: {
    id: "app_lost_connection",
  },
  app_reconnect_button: {
    id: "app_reconnect_button",
  },
});

const translation = (id) => translatedText(id, messages);

const NoInternetConnection = () => {
  const [internetConnection, setInternetConnection] = useState(
    navigator.onLine ? "online" : "offline"
  );
  const [reconnectButton, setReconnectButton] = useState(true);

  useEffect(() => {
    window.addEventListener("online", () => {
      if (internetConnection === "offline") setInternetConnection("online");
    });
    window.addEventListener("offline", () => {
      if (internetConnection === "online") setInternetConnection("offline");
    });
  }, []);

  function checkReconnection() {
    if (navigator.onLine) {
      setInternetConnection("online");
    } else {
      setReconnectButton(false);

      setTimeout(() => {
        setReconnectButton(true);
      }, [3000]);
    }
  }

  return (
    <div
      className={`no-internet-connection-bar ${
        internetConnection === "offline" && "active"
      }`}
    >
      <div className="icon-div">
        <i className="fas fa-wifi wifi"></i>
        <i className="fas fa-slash slash"></i>
        <i className="fas fa-slash slash2"></i>
      </div>
      {translation("app_lost_connection")}
      {reconnectButton && (
        <a className="reconnect" onClick={checkReconnection}>
          {reconnectButton ? translation("app_reconnect_button") : "        "}
        </a>
      )}
    </div>
  );
};

export default NoInternetConnection;
