import React from "react";
import { connect } from "react-redux";
import { getSelectedToolStates } from "../../../customMapStates";
import {
  GOALS_AND_BONUS,
  KPIS,
  PROJECT_PRIORITIZATION,
  OKR_ID,
  COMPETITIVE_ANALYSIS,
} from "../../../../constants/tools.constants";
import GoalsBonusWatcher from "./GoalsBonusWatcher";
import KpiWatcher from "./KpiWatcher";
import ProjectPrioritization from "./ProjectPrioritization";
import OkrWatcher from "./OkrWatcher";
import CompetitiveAnalysisWatcher from "./CompetitiveAnalysisWatcher";

const QuestionWatcher = (props) => {
  const { selectedTool = {}, allQuestions = {} } = props;

  const appendProps = {
    selectedTool,
    allQuestions,
  };

  function getToolWatcher() {
    if (selectedTool.tool && selectedTool.tool.id) {
      switch (selectedTool.tool.id) {
        case GOALS_AND_BONUS:
          return <GoalsBonusWatcher {...appendProps} />;
        case KPIS:
          return <KpiWatcher {...appendProps} />;
        case PROJECT_PRIORITIZATION:
          return <ProjectPrioritization {...appendProps} />;
        case OKR_ID:
          return <OkrWatcher {...appendProps} />;
        case COMPETITIVE_ANALYSIS:
          return <CompetitiveAnalysisWatcher {...appendProps} />;
        default:
          return null;
      }
    }

    return null;
  }

  return getToolWatcher();
};

const mapStateToProps = (state) => {
  const { selectedTool, allQuestions } = getSelectedToolStates(state);

  return {
    selectedTool,
    allQuestions,
  };
};

export default connect(mapStateToProps)(QuestionWatcher);
