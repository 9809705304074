import { SET_ACTIVE_ROUTE } from "../actions/types";
import { HOME } from "../actions/routes";

const INITIAL_STATE = HOME;

const activeRouteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVE_ROUTE:
      return action.payload;
    default:
      return state;
  }
};

export default activeRouteReducer;
