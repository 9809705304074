const en = {
  ADD_ACCESS: "Access added",
  ADD: "Added",
  ADD_TO: "Added",
  REMOVE_ACCESS: "Access removed",
  REMOVE: "Removed",
  CHANGE: "Change",
  DOMAIN_USER: "Domain",
  DOMAIN: "Domain",
  WORKSPACE: "Workspace",
  INSIGHT: "Insight",
  CENARY: "Cenary",
  SELECTED_TOOL: "Tool",
  COMPANY: "Company",
  ARCHIVE: "Archive",
  UNARCHIVE: "Unarchive",
  CHANGE_RESPONSIBLE: "Responsible changed",
  THEME: "Theme",
  QUESTION: "Question",
};

const es = {
  ADD_ACCESS: "Acceso agregado",
  ADD_TO: "Agregado",
  ADD: "Agregado",
  REMOVE_ACCESS: "Acceso eliminado",
  REMOVE: "Eliminado",
  CHANGE: "Cambio",
  DOMAIN_USER: "Dominio",
  DOMAIN: "Domain",
  SELECTED_TOOL: "Herramienta",
  WORKSPACE: "Workspace",
  CENARY: "Cenary",
  COMPANY: "Empresa",
  INSIGHT: "Insight",
  ARCHIVE: "Archivado",
  UNARCHIVE: "Desarchivo",
  CHANGE_RESPONSIBLE: "Responsable cambiado",
  THEME: "Theme",
  QUESTION: "Questão",
};

const pt = {
  ADD_ACCESS: "Acesso adicionado",
  ADD: "Adicionado",
  ADD_TO: "Adicionado",
  REMOVE: "Removido",
  REMOVE_ACCESS: "Acesso removido",
  CHANGE: "Alteração",
  DOMAIN_USER: "Domínio",
  SELECTED_TOOL: "Ferramenta",
  DOMAIN: "Domain",
  WORKSPACE: "Workspace",
  CENARY: "Cenário",
  COMPANY: "Empresa",
  INSIGHT: "Insight",
  ARCHIVE: "Arquivado",
  UNARCHIVE: "Desarquivado",
  CHANGE_RESPONSIBLE: "Responsável alterado",
  THEME: "Tema",
  QUESTION: "Questão",
};

const translations = [{ en, es, pt }];

export default translations;
