import {
  FETCH_USER,
  FETCH_USER_BY_EMAIL,
  FETCH_USER_BY_CPF,
  CHANGE_USER_PRIVACY_POLICY,
} from "../actions/types";

const INITIAL_STATE = {};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USER:
      return action.payload;
    case FETCH_USER_BY_EMAIL:
      return action.payload;
    case FETCH_USER_BY_CPF:
      return action.payload;
    case CHANGE_USER_PRIVACY_POLICY:
      const { payload = false } = action;

      return { ...state, acceptedPrivacyPolicy: payload };
    default:
      return state;
  }
};

export default userReducer;
