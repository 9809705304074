import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";
import MaterialTextField from "../../MaterialTextField";
import MaterialSingleSelect from "../../MaterialSingleSelect";
import utils from "../../../../utils/toolUtils";
import kpiUtils from "../../../../utils/kpis";
import {
  createSelectedToolMetric,
  postLinkSelectedToolMetricToKpiGoal,
} from "../../../../actions/selected-tool/metrics";
import { fetchToolKpiConfiguration } from "../../../../actions/kpiActions";

import {
  getKpi_MU_options,
  getGoalTypeOptions,
  getKpisFrequencyOptions,
  getMeasurementOptions,
  getRelationshipTypeOptions,
} from "../../../../constants/kpis";
import SimpleExcelCollumn from "../../SimpleExcelCollumn";
import { getTargetInputProps } from "./includes/displayUtils";

const { getErrorMessage } = utils;

const {
  getAdormentByMeasurement,
  getDefaultFrequencyArray,
  getDefaultFrequencyDistribution,
  getGoalDistributionByPeriods,
  checkIsIndicatorEditable,
  formTransformValue,
} = kpiUtils;

const messages = defineMessages({
  kpi_table_text_description: { id: "kpi_table_text_description" },
  kpi_table_text_relationship: { id: "kpi_table_text_relationship" },
  kpi_table_text_gate: { id: "kpi_table_text_gate" },
  kpi_table_text_appropriated: { id: "kpi_table_text_appropriated" },
  kpi_table_text_exceeded: { id: "kpi_table_text_exceeded" },
  kpi_subtitle_goal_description: { id: "kpi_subtitle_goal_description" },
  gb_form_unity_ext: { id: "gb_form_unity_ext" },
  gb_options_mu_other: { id: "gb_options_mu_other" },
  kpi_form_type: { id: "kpi_form_type" },
  kpi_form_frequency: { id: "kpi_form_frequency" },
  kpi_form_description: { id: "kpi_form_description" },
  kpi_form_measurement: { id: "kpi_form_measurement" },
  kpi_table_text_performance_scale: { id: "kpi_table_text_performance_scale" },
});

const Form = (props) => {
  const {
    goalDescriptions,
    setGoalDescriptions,
    setGoalHistory,
    formErrors = [],
    isAdmin = false,
    blockAllChanges = false,
    intl,
    selectedToolId,
    fetchToolKpiConfiguration,
    handleSaveGoal,
    setIsLoading,
  } = props;

  const isDateMeasurement = goalDescriptions.measurementUnity === "DATE";
  const targetInputType = isDateMeasurement ? "date" : "text";

  const measurementUnityOptions = getKpi_MU_options();
  const goalTypeOptions = getGoalTypeOptions();
  const frequencyOptions = getKpisFrequencyOptions();
  const measurementOptions = getMeasurementOptions();
  const relationshipTypeOptions = getRelationshipTypeOptions();

  const saveNewSelectedToolMetric = async (updatedGoal) => {
    setIsLoading(true);

    const newSelectedToolMetric = {
      id: null,
      selectedToolId,
    };

    const { data: createdMetric } = await createSelectedToolMetric(
      newSelectedToolMetric,
    );

    if (createdMetric?.id) {
      await postLinkSelectedToolMetricToKpiGoal(
        createdMetric.id,
        goalDescriptions.id,
      );
      const linkedGoal = {
        ...updatedGoal,
        selectedToolMetricId: createdMetric.id,
      };
      setGoalDescriptions(linkedGoal);
      if (setGoalHistory) setGoalHistory(linkedGoal);
      fetchToolKpiConfiguration(selectedToolId);
    }

    setIsLoading(false);
  };

  const handleUpdateGoalDescriptions = (fieldSlug, newValue) => {
    setGoalDescriptions({
      ...goalDescriptions,
      [fieldSlug]: newValue,
    });
  };

  const handleUpdateRelationshipType = (newRelationship) => {
    const updatedGoal = {
      ...goalDescriptions,
      relationshipType: newRelationship,
    };

    if (newRelationship === "CALCULATED") {
      setIsLoading(true);

      handleSaveGoal(updatedGoal, {
        onSuccess: () => {
          saveNewSelectedToolMetric(updatedGoal);
        },
        onError: () => {
          setIsLoading(false);
        },
      });
    } else {
      setGoalDescriptions(updatedGoal);
    }
  };

  const handleUpdateTarget = (targetSlug, value) => {
    setGoalDescriptions({
      ...goalDescriptions,
      target: {
        ...goalDescriptions.target,
        [targetSlug]: formTransformValue(
          value,
          goalDescriptions.measurementUnity,
          true,
        ),
      },
    });
  };

  const handleUpdateFrequency = (newFrequency) => {
    if (!isDateMeasurement) {
      const newActualTargets = getDefaultFrequencyArray(newFrequency);
      const tempUpdatedGoal = {
        ...goalDescriptions,
        frequency: newFrequency,
        target: {
          ...goalDescriptions.target,
          actual: newActualTargets,
          lastPeriod: newActualTargets,
        },
      };

      const { goalDistribution } =
        getGoalDistributionByPeriods(tempUpdatedGoal);

      setGoalDescriptions({
        ...tempUpdatedGoal,
        target: {
          ...tempUpdatedGoal.target,
          distribution: goalDistribution,
        },
      });
    }
  };

  const handleUpdateMeasurementType = (newMeasurementRule) => {
    const tempUpdatedGoal = {
      ...goalDescriptions,
      measurement: newMeasurementRule,
    };

    const { goalDistribution } = getGoalDistributionByPeriods(tempUpdatedGoal);

    setGoalDescriptions({
      ...tempUpdatedGoal,
      target: {
        ...goalDescriptions.target,
        distribution: goalDistribution,
      },
    });
  };

  const handleUpdateMeasurementUnity = (newUnity) => {
    const tempUpdatedGoal = {
      ...goalDescriptions,
      measurementUnity: newUnity,
    };

    if (newUnity === "DATE") {
      const goalTarget =
        newUnity === "DATE"
          ? { gate: 0, appropriated: 0, exceeded: 0 }
          : goalDescriptions.target;

      setGoalDescriptions({
        ...tempUpdatedGoal,
        frequency: "YEARLY",
        target: {
          ...goalTarget,
          actual: getDefaultFrequencyArray("YEARLY"),
          distribution: getDefaultFrequencyDistribution("YEARLY", "SUM"),
        },
      });
    } else {
      setGoalDescriptions(tempUpdatedGoal);
    }
  };

  const adormentObjInfo = {
    text: getAdormentByMeasurement(
      goalDescriptions.measurementUnity,
      goalDescriptions.otherMeasurement,
    ),
    position: "end",
    fontSize: "12px",
  };

  const { target = {} } = goalDescriptions;

  const isEditingAllowed =
    checkIsIndicatorEditable(
      goalDescriptions.id,
      goalDescriptions.relationshipType,
      isAdmin,
    ) && !blockAllChanges;
  const notAllowedCss = !isEditingAllowed ? { cursor: "not-allowed" } : {};
  const notAllowedCssAdmin =
    !isAdmin || !isEditingAllowed ? { cursor: "not-allowed" } : {};
  const cantEditRelationshipType =
    !isAdmin || goalDescriptions.relationshipType === "CALCULATED";

  return (
    <div>
      <div className="col-xs-6">
        <MaterialTextField
          id="description"
          label={intl.formatMessage(messages.kpi_form_description)}
          value={goalDescriptions.description}
          primaryInputProps={{ maxLength: 255 }}
          onChange={(e) =>
            isEditingAllowed
              ? handleUpdateGoalDescriptions("description", e.target.value)
              : () => {}
          }
          multiline
          rows={5}
          inputStyle={!isEditingAllowed ? { cursor: "not-allowed" } : {}}
        />
      </div>
      <div className="col-xs-6">
        <table className="simple-table">
          <tbody>
            <tr style={{ ...notAllowedCss }} className="small-font">
              <td className="titled">
                {intl.formatMessage(messages.gb_form_unity_ext)}
              </td>
              <td style={{ padding: "0px" }}>
                <MaterialSingleSelect
                  id="unityOfMesurement"
                  value={goalDescriptions.measurementUnity}
                  variant="standard"
                  label={intl.formatMessage(messages.gb_form_unity_ext)}
                  options={measurementUnityOptions}
                  margin="none"
                  hideLabel
                  onChange={(e) =>
                    isEditingAllowed
                      ? handleUpdateMeasurementUnity(e.target.value)
                      : () => {}
                  }
                  customStyles={{
                    padding: "0px 10px",
                    height: "40px",
                    fontSize: "14px",
                  }}
                  defaultOption={false}
                  notAllowed={!isEditingAllowed}
                />
              </td>
            </tr>
            {goalDescriptions.measurementUnity === "OTHER" && (
              <tr style={{ ...notAllowedCss }} className="small-font">
                <td className="titled">
                  {`${intl.formatMessage(messages.gb_options_mu_other)}: `}
                  {intl.formatMessage(messages.gb_form_unity_ext)}
                </td>
                <SimpleExcelCollumn
                  id="otherMeasurement"
                  value={goalDescriptions.otherMeasurement}
                  tdStyle={{
                    padding: "0px 10px",
                    borderBottom: "1px solid #ccc",
                  }}
                  inputStyle={{
                    height: "40px",
                    padding: "0px",
                    cursor: !isEditingAllowed ? "not-allowed" : "auto",
                  }}
                  onChange={(e) =>
                    isEditingAllowed
                      ? handleUpdateGoalDescriptions(
                          "otherMeasurement",
                          e.target.value,
                        )
                      : () => {}
                  }
                />
              </tr>
            )}
            <tr style={{ ...notAllowedCss }} className="small-font">
              <td className="titled">
                {intl.formatMessage(messages.kpi_form_type)}
              </td>
              <td style={{ padding: "0px" }}>
                <MaterialSingleSelect
                  id="goalType"
                  value={goalDescriptions.type}
                  variant="standard"
                  label={intl.formatMessage(messages.kpi_form_type)}
                  options={goalTypeOptions}
                  margin="none"
                  hideLabel
                  onChange={(e) =>
                    isEditingAllowed
                      ? handleUpdateGoalDescriptions("type", e.target.value)
                      : () => {}
                  }
                  customStyles={{
                    padding: "0px 10px",
                    height: "40px",
                    fontSize: "14px",
                  }}
                  defaultOption={false}
                  notAllowed={!isEditingAllowed}
                />
              </td>
            </tr>
            <tr style={{ ...notAllowedCss }} className="small-font">
              <td className="titled">
                {intl.formatMessage(messages.kpi_form_frequency)}
              </td>
              <td style={{ padding: "0px" }}>
                <MaterialSingleSelect
                  id="goalFrequency"
                  value={goalDescriptions.frequency}
                  variant="standard"
                  label={intl.formatMessage(messages.kpi_form_frequency)}
                  options={frequencyOptions}
                  margin="none"
                  hideLabel
                  onChange={(e) =>
                    isEditingAllowed && !isDateMeasurement
                      ? handleUpdateFrequency(e.target.value)
                      : () => {}
                  }
                  customStyles={{
                    padding: "0px 10px",
                    height: "40px",
                    fontSize: "14px",
                  }}
                  defaultOption={false}
                  notAllowed={!isEditingAllowed || isDateMeasurement}
                />
              </td>
            </tr>
            <tr style={{ ...notAllowedCss }} className="small-font">
              <td className="titled">
                {intl.formatMessage(messages.kpi_form_measurement)}
              </td>
              <td style={{ padding: "0px" }}>
                <MaterialSingleSelect
                  id="goalMeasument"
                  value={goalDescriptions.measurement}
                  variant="standard"
                  label={intl.formatMessage(messages.kpi_form_measurement)}
                  options={measurementOptions}
                  margin="none"
                  hideLabel
                  onChange={(e) =>
                    isEditingAllowed && !isDateMeasurement
                      ? handleUpdateMeasurementType(e.target.value)
                      : () => {}
                  }
                  customStyles={{
                    padding: "0px 10px",
                    height: "40px",
                    fontSize: "14px",
                  }}
                  defaultOption={false}
                  notAllowed={!isEditingAllowed && isDateMeasurement}
                />
              </td>
            </tr>
            <tr style={{ ...notAllowedCss }} className="small-font">
              <td className="titled">
                {intl.formatMessage(messages.kpi_table_text_relationship)}
              </td>
              <td style={{ padding: "0px" }}>
                <MaterialSingleSelect
                  id="relationshipType"
                  value={goalDescriptions.relationshipType}
                  variant="standard"
                  label={intl.formatMessage(
                    messages.kpi_table_text_relationship,
                  )}
                  options={relationshipTypeOptions}
                  margin="none"
                  hideLabel
                  onChange={(e) =>
                    cantEditRelationshipType
                      ? () => {}
                      : handleUpdateRelationshipType(e.target.value)
                  }
                  customStyles={{
                    padding: "0px 10px",
                    height: "40px",
                    fontSize: "14px",
                    ...notAllowedCssAdmin,
                  }}
                  notAllowed={cantEditRelationshipType}
                  defaultOption={false}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-xs-12">
        {getErrorMessage(
          ["individualNotAllowed"],
          formErrors,
          "left",
          {
            height: "30px",
          },
          true,
        )}
      </div>
      <div className="row">
        <div className="col-xs-12" style={{ paddingBottom: "0px" }}>
          <h4 style={{ marginBottom: "0px" }}>
            {intl.formatMessage(messages.kpi_table_text_performance_scale)}
          </h4>
        </div>
        <div className="col-xs-3" style={notAllowedCss}>
          <MaterialTextField
            {...getTargetInputProps(targetInputType, "101")}
            id="gate"
            type={targetInputType}
            label={intl.formatMessage(messages.kpi_table_text_gate)}
            inputStyle={{
              fontSize: "14px",
              padding: "15px 0px",
              textAlign: "right",
              ...notAllowedCss,
            }}
            value={formTransformValue(
              target.gate,
              goalDescriptions.measurementUnity,
              false,
            )}
            endAdorment={isDateMeasurement ? {} : adormentObjInfo}
            onChange={(e) =>
              isEditingAllowed
                ? handleUpdateTarget("gate", e.target.value)
                : () => {}
            }
          />
        </div>
        <div className="col-xs-3" style={notAllowedCss}>
          <MaterialTextField
            {...getTargetInputProps(targetInputType, "102")}
            id="appropriated"
            type={targetInputType}
            label={intl.formatMessage(messages.kpi_table_text_appropriated)}
            inputStyle={{
              fontSize: "14px",
              padding: "15px 0px",
              textAlign: "right",
              ...notAllowedCss,
            }}
            value={formTransformValue(
              target.appropriated,
              goalDescriptions.measurementUnity,
              false,
            )}
            endAdorment={isDateMeasurement ? {} : adormentObjInfo}
            onChange={(e) =>
              isEditingAllowed
                ? handleUpdateTarget("appropriated", e.target.value)
                : () => {}
            }
          />
        </div>
        <div className="col-xs-3" style={notAllowedCss}>
          <MaterialTextField
            {...getTargetInputProps(targetInputType, "103")}
            id="exceeded"
            type={targetInputType}
            label={intl.formatMessage(messages.kpi_table_text_exceeded)}
            inputStyle={{
              fontSize: "14px",
              padding: "15px 0px",
              textAlign: "right",
              ...notAllowedCss,
            }}
            value={formTransformValue(
              target.exceeded,
              goalDescriptions.measurementUnity,
              false,
            )}
            endAdorment={isDateMeasurement ? {} : adormentObjInfo}
            onChange={(e) =>
              isEditingAllowed
                ? handleUpdateTarget("exceeded", e.target.value)
                : () => {}
            }
          />
        </div>
        <div className="col-xs-12" style={{ paddingTop: "15px" }}>
          {getErrorMessage(["repeatedTarget", "invalidTarget"], formErrors)}
        </div>
      </div>
    </div>
  );
};

const GoalDescription = (props) => {
  const { onlyForm = false, SaveButton = false, intl } = props;

  return !onlyForm ? (
    <div className="box box-primary">
      <div className="box-header with-border">
        <h3 className="box-title">
          <i className="fas fa-align-center" />
          {intl.formatMessage(messages.kpi_subtitle_goal_description)}
        </h3>
        {SaveButton && SaveButton}
      </div>
      <div className="box-body">
        <Form {...props} />
      </div>
    </div>
  ) : (
    <Form {...props} />
  );
};

export default connect(null, { fetchToolKpiConfiguration })(
  injectIntl(GoalDescription),
);
