import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import { globalMessages } from "../../utils/global";

const ConfirmationDialog = ({
  open,
  title,
  description,
  toggleOpen,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  blockConfirm,
  confirmColor,
  hideCancel,
  optionalButton,
  intl,
}) => {
  cancelText =
    !cancelText || cancelText === "defaultCancel"
      ? intl.formatMessage(globalMessages.cancel)
      : cancelText;
  confirmText =
    !confirmText || confirmText === "defaultConfirm"
      ? intl.formatMessage(globalMessages.confirm)
      : confirmText;

  function handleOverFlow() {
    const body = document.querySelectorAll("body");
    body[0].style.overflow = "visible";
  }

  function toggleClose() {
    onCancel();
    setInterval(() => {
      handleOverFlow();
    }, 1000);
  }

  function toggleConfirm() {
    onConfirm();
    setInterval(() => {
      handleOverFlow();
    }, 1000);
  }

  return (
    <Modal
      onHide={onCancel ? toggleClose : toggleOpen}
      show={open}
      backdrop={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      {description && (
        <Modal.Body>
          <div style={{ padding: "10px 15px" }}>{description}</div>
        </Modal.Body>
      )}

      <Modal.Footer>
        {!hideCancel && (
          <Button
            variant="secondary"
            onClick={onCancel ? toggleClose : toggleOpen}
          >
            {cancelText}
          </Button>
        )}
        {optionalButton && optionalButton.text && optionalButton.onClick && (
          <Button variant="secondary" onClick={optionalButton.onClick}>
            {optionalButton.text}
          </Button>
        )}
        <Button
          variant={blockConfirm ? "secondary" : "primary"}
          className={blockConfirm ? "btn-disabled" : "btn-purple"}
          onClick={blockConfirm ? () => null : toggleConfirm}
          style={confirmColor ? { backgroundColor: confirmColor } : {}}
        >
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  toggleOpen: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  confirmColor: PropTypes.string,
  blockConfirm: PropTypes.bool,
  hideCancel: PropTypes.bool,
};

ConfirmationDialog.defaultProps = {
  open: false,
  onConfirm: () => {},
  confirmText: "defaultConfirm",
  cancelText: "defaultCancel",
  blockConfirm: false,
  hideCancel: false,
};

export default injectIntl(ConfirmationDialog);
