import { defineMessages } from "react-intl";

export const globalMessages = defineMessages({
  id: {
    id: "global.id",
  },
  space: {
    id: "global.space",
  },
  name: {
    id: "global.name",
  },
  lastName: {
    id: "global.lastName",
  },
  new: {
    id: "global.new",
  },
  delete: {
    id: "global.delete",
  },
  activities: {
    id: "global.activities",
  },
  search: {
    id: "global.search",
  },
  clear: {
    id: "global.clear",
  },
  empty: {
    id: "global.empty",
  },
  select: {
    id: "global.select",
  },
  deleteConfirmation: {
    id: "global.deleteConfirmation",
  },
  archiveConfirmation: {
    id: "global.archiveConfirmation",
  },
  unArchiveConfirmation: {
    id: "global.unArchiveConfirmation",
  },
  or: {
    id: "global.or",
  },
  all: {
    id: "global.all",
  },
  requiredField: {
    id: "global.requiredField",
  },
  invalidField: {
    id: "global.invalidField",
  },
  fieldMustMatch: {
    id: "global.fieldMustMatch",
  },
  rememberme: {
    id: "global.rememberme",
  },
  serverError: {
    id: "global.serverError",
  },
  unauthorized: {
    id: "global.unauthorized",
  },
  error: {
    id: "global.error",
  },
  unknownError: {
    id: "global.unknowError",
  },
  success: {
    id: "global.success",
  },
  paramWasUsed: {
    id: "global.paramWasUsed",
  },
  actions: {
    id: "global.actions",
  },
  confirm: {
    id: "global.confirm",
  },
  cancel: {
    id: "global.cancel",
  },
  close: {
    id: "global.close",
  },
  change: {
    id: "global.change",
  },
  private: {
    id: "global.private",
  },
  domain: {
    id: "global.domain",
  },
  owner: {
    id: "global.owner",
  },
  administrator: {
    id: "global.administrator",
  },
  moderator: {
    id: "global.moderator",
  },
  manager: {
    id: "global.manager",
  },
  viewer: {
    id: "global.viewer",
  },
  leader: {
    id: "global.leader",
  },
  lastUpdate: {
    id: "global.lastUpdate",
  },
  team: {
    id: "global.team",
  },
  teams: {
    id: "global.teams",
  },
  company: {
    id: "global.company",
  },
  unit: {
    id: "global.unit",
  },
  description: {
    id: "global.description",
  },
  metrics: {
    id: "global.metrics",
  },
  share: {
    id: "global.share",
  },
  exclude: {
    id: "global.exclude",
  },
  members: {
    id: "global.members",
  },
  member: {
    id: "global.member",
  },
  participant: {
    id: "global.participant",
  },
  participants: {
    id: "global.participants",
  },
  configurations: {
    id: "global.configurations",
  },
  properties: {
    id: "global.properties",
  },
  site: {
    id: "global.site",
  },
  administration: {
    id: "global.administration",
  },
  addSome: {
    id: "global.addSome",
  },
  manageSome: {
    id: "global.manageSome",
  },
  add: {
    id: "global.add",
  },
  workspace: {
    id: "global.workspace",
  },
  tools: {
    id: "global.tools",
  },
  tool: {
    id: "global.tool",
  },
  learnMore: {
    id: "global.learnMore",
  },
  profile: {
    id: "global.profile",
  },
  informations: {
    id: "global.informations",
  },
  insights: {
    id: "global.insights",
  },
  traceability: {
    id: "global.traceability",
  },
  addTag: {
    id: "global.addTag",
  },
  email: {
    id: "profile.email",
  },
  favorite: {
    id: "global.favorite",
  },
  entityConfirmationAction: {
    id: "global.entityConfirmationAction",
  },
  addMember: {
    id: "global.addMember",
  },
  filed: {
    id: "global.filed",
  },
  analysis: {
    id: "global.analysis",
  },
  permissions: {
    id: "global.permissions",
  },
  management: {
    id: "global.management",
  },
  drawing: {
    id: "global.drawing",
  },
  timeline: {
    id: "global.timeline",
  },
  versions: {
    id: "global.versions",
  },
  closedAccount: {
    id: "global.closedAccount",
  },
  priority: {
    id: "global.priority",
    UNDEFINED: {
      id: "global.priority.UNDEFINED",
    },
    LOW: {
      id: "global.priority.LOW",
    },
    MEDIUM: {
      id: "global.priority.MEDIUM",
    },
    HIGH: {
      id: "global.priority.HIGH",
    },
    URGENT: {
      id: "global.priority.URGENT",
    },
  },
  importance: {
    id: "global.importance",
  },
  evaluation: {
    id: "global.evaluation",
  },
  swotEvaluation: {
    id: "global.swotEvaluation",
  },
  attachments: {
    id: "global.attachments",
  },
  collaborator: {
    id: "global.collaborator",
  },
  collaborators: {
    id: "global.collaborators",
  },
  collaboration: {
    id: "global.collaboration",
  },
  newTeam: {
    id: "global.newTeam",
  },
  addInsight: {
    id: "global.addInsight",
  },
  viewInsight: {
    id: "global.viewInsight",
  },
  edit: {
    id: "global.edit",
  },
  editedOn: {
    id: "global.editedOn",
  },
  messages: {
    id: "global.messages",
  },
  notifications: {
    id: "global.notifications",
  },
  question: {
    id: "global.question",
  },
  answer: {
    id: "global.answer",
  },
  theme: {
    id: "global.theme",
  },
  cenary: {
    id: "global.cenary",
  },
  selectCenary: {
    id: "global.selectCenary",
  },
  archiveCenary: {
    id: "global.archiveCenary",
  },
  deleteCenary: {
    id: "global.deleteCenary",
  },
  menuDropdownTitleCenary: {
    id: "global.cenary.dropdown.title",
  },
  insight: {
    id: "global.insight",
  },
  information: {
    id: "global.information",
  },
  period: {
    id: "global.period",
  },
  objective: {
    id: "global.objective",
  },
  more: {
    id: "global.more",
  },
  popoverMore: {
    id: "global.popoverMore",
  },
  positive: {
    id: "global.positive",
  },
  negative: {
    id: "global.negative",
  },
  selectOption: {
    id: "global.selectOption",
  },
  copyTo: {
    id: "global.copyTo",
  },
  commentary: {
    id: "global.commentary",
  },
  weight: {
    id: "global.weight",
  },
  date: {
    id: "global.date",
  },
  duplicatedTool: {
    id: "global.duplicatedTool",
  },
  result: {
    id: "global.result",
  },
  archive: {
    id: "global.archive",
  },
  unArchive: {
    id: "global.unArchive",
  },
  showArchivedCenaries: {
    id: "global.showArchivedCenaries",
  },
  showArchivedThemes: {
    id: "global.showArchivedThemes",
  },
  showArchivedAnswers: {
    id: "global.showArchivedAnswers",
  },
  showArchivedQuestions: {
    id: "global.showArchivedQuestions",
  },
  whichInsightScore: {
    id: "global.whichInsightScore",
  },
  realInsightScore: {
    id: "global.realInsightScore",
  },
  expectedInsightScore: {
    id: "global.expectedInsightScore",
  },
  segment: {
    id: "global.segment",
  },
  toolConfigurations: {
    id: "global.toolConfigurations",
  },
  propierty: {
    id: "global.propierty",
  },
  responsable: {
    id: "global.responsable",
  },
  responsible: {
    id: "global.responsible",
  },
  careerMap: {
    id: "global.careerMap",
  },
  maturity: {
    id: "global.maturity",
  },
  score: {
    id: "global.score",
  },
  status: {
    id: "global.status",
  },
  projectStatus: {
    id: "global.projectStatus",
  },
  insightPoints: {
    id: "global.insightPoints",
  },
  traceabilityConnections: {
    id: "global.traceabilityConnections",
  },
  traceabilityPoints: {
    id: "global.traceabilityPoints",
  },
  totalPoints: {
    id: "global.totalPoints",
  },
  noData: {
    id: "global.noData",
  },
  consistency: {
    id: "global.consistency",
  },
  relevance: {
    id: "global.relevance",
  },
  insightName: {
    id: "global.insightName",
  },
  showInsightResume: {
    id: "global.showInsightResume",
  },
  createdBy: {
    id: "global.createdBy",
  },
  level: {
    id: "global.level",
  },
  okr: {
    id: "global.okr",
  },
  keyResult: {
    id: "global.keyResult",
  },
  area: {
    id: "global.area",
  },
  subArea: {
    id: "global.subArea",
  },
  professional: {
    id: "global.professional",
  },
  environment: {
    id: "global.environment",
  },
  swot: {
    id: "global.swot",
  },
  macroProcess: {
    id: "global.macroProcess",
  },
  process: {
    id: "global.process",
  },
  activity: {
    id: "global.activity",
  },
  points: {
    id: "global.points",
  },
  impact: {
    id: "global.impact",
    NO_SIGNFICANT_IMPACT: {
      id: "global.impact.NO_SIGNFICANT_IMPACT",
    },
    IMPACT_LITTLE_ON_RESULTS: {
      id: "global.impact.IMPACT_LITTLE_ON_RESULTS",
    },
    IMPACT_ON_RESULTS_LONG_TERM: {
      id: "global.impact.IMPACT_ON_RESULTS_LONG_TERM",
    },
    IMPACT_ON_RESULTS_MEDIUM_TERM: {
      id: "global.impact.IMPACT_ON_RESULTS_MEDIUM_TERM",
    },
    IMPACT_ON_RESULTS_SHORT_TERM: {
      id: "global.impact.IMPACT_ON_RESULTS_SHORT_TERM",
    },
  },
  reliability: {
    id: "global.reliability",
    NO_RELIABILITY: {
      id: "global.reliability.NO_RELIABILITY",
    },
    UNRELIABLE_SOURCE: {
      id: "global.reliability.UNRELIABLE_SOURCE",
    },
    MEDIUM: {
      id: "global.reliability.MEDIUM",
    },
    HIGH: {
      id: "global.reliability.HIGH",
    },
    VERY_HIGH: {
      id: "global.reliability.VERY_HIGH",
    },
  },
  followup: {
    id: "global.followup",
  },
  file: {
    id: "global.file",
  },
  questionCounter: {
    id: "global.questionCounter",
  },
  comments: {
    id: "global.comments",
  },
  goals: {
    id: "global.goals",
  },
  noDataTable: {
    id: "global.noDataTable",
  },
  haveCommented: {
    id: "global.haveCommented",
  },
  overview: {
    id: "global.overview",
  },
  type: {
    id: "global.type",
  },
  running: {
    id: "global.running",
  },
  noWhiteSpaces: {
    id: "global.noWhiteSpaces",
  },
  filter: {
    id: "global.filter",
  },
});

export const ALIGNMENTS = [
  {
    LEFT: "left",
    TOP: "top",
    RIGHT: "right",
    BOTTOM: "bottom",
    CENTER: "center",
  },
];

export const S3_BUCKETS = {
  ATTACHMENTS: "ATTACHMENTS",
  IMAGES: "IMAGES",
  PROFILE_IMAGES: "PROFILE_IMAGES",
};
